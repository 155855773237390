import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import SearchAnchorAgreementModal from 'components/stateless/Modal/financier/SearchAnchorAgreementModal';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import useExtraInformationViewModel from '../../models/extraInformation/useExtraInformationViewModel';
import useAnchorAgreementInfoViewModel from '../../models/financingOption/useAnchorAgreementInfoViewModel';

const useRegistrationAssociatedAnchorMasterAgreementController = () => {
  const { show: showModal, id: modalId } = useModal();
  const { t } = useTranslation(['format']);

  const { anchorAgreementInfo, updateAnchorAgreementInfo } = useAnchorAgreementInfoViewModel();

  const { supportedCollateralType } = useExtraInformationViewModel();

  const isSearchAnchorAgreementButtonVisible = true;

  const {
    methods: { setValue },
  } = useFormContext();

  const handleSearchAnchorAgreementClick = () => {
    const setSelectedData = async (data: AnchorAgreementVOModel) => {
      setValue('anchorAgreementId', data.anchorAgreementId);
      updateAnchorAgreementInfo(data);
    };

    showModal(
      <SearchAnchorAgreementModal
        modalId={modalId}
        getSelectedData={setSelectedData}
        supportedCollateralType={supportedCollateralType}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        title: t('text:Search_Anchor_Master_Agreement'),
        closeBtnText: t('text:Close'),
      },
    );
  };

  return {
    anchorAgreementInfo,
    handleSearchAnchorAgreementClick,
    isSearchAnchorAgreementButtonVisible,
  };
};

export default useRegistrationAssociatedAnchorMasterAgreementController;
