import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import type { CURRENCY_TYPE } from 'enums';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorClientLimitViewModel from '../../../models/anchorClientLimit/useAnchorClientLimitViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useSummaryOfAssociatedAnchorController = () => {
  const { t } = useTranslation(['format']);

  const { agreement } = useAgreementViewModel();
  const { dealerAgreementId } = useExtraInformationViewModel();
  const { anchorClientLimitInfo, fetchAnchorClientLimitInfo } = useAnchorClientLimitViewModel();

  const formattedAmountWithCurrency = (amount?: string, currency?: CURRENCY_TYPE): string => {
    if (isNil(amount)) return '-';

    const formattedAmount = t('format:number', { value: amount });

    return `${formattedAmount} ${currency && currency}`;
  };

  const resetSection = useCallback(async () => {
    if (isNil(dealerAgreementId)) return;

    await fetchAnchorClientLimitInfo(dealerAgreementId);
  }, [dealerAgreementId]);

  useEffect(() => {
    resetSection();
  }, [resetSection]);

  return {
    ...anchorClientLimitInfo,
    dealerAgreementCurrencyType: agreement.currencyType,
    formattedAmountWithCurrency,
  };
};

export default useSummaryOfAssociatedAnchorController;
