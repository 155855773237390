import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { REQUEST_RESULT } from 'enums';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import { setFormValues } from 'utils/form/setFormValues';
import {
  requestFinancierAnchorDealerAssign,
  requestFinancierAnchorDealerList,
} from 'utils/http/api/financier/anchor-dealers';
import type { FinancierAnchorDealerListRequest } from 'utils/http/api/financier/anchor-dealers/request';
import useModal from 'utils/modal/useModal';
import { addSearchParams, getParsedSearchParams, makeSearchParamsPattern } from 'utils/searchParams';

const FI_DEALER_ASSIGNMENT_WAITING_LIST_QS_KEY = 'fi-dealer-assign-waiting-list';
const FI_DEALER_ASSIGNMENT_ASSIGNED_LIST_QS_KEY = 'fi-dealer-assign-list';

function useFinancierAnchorDealerAssignmentListStateAdmin() {
  const modal = useModal();

  const assignedTabSearchForm = useForm<FinancierAnchorDealerListRequest>();
  const waitingTabSearchForm = useForm<FinancierAnchorDealerListRequest>();

  const [waitingForAssignAnchorDealerData, setWaitingForAssignAnchorDealerData] =
    useState<Pageable<AnchorDealerVOModel[]>>();
  const [assignedAnchorDealerData, setAssignedAnchorDealerData] = useState<Pageable<AnchorDealerVOModel[]>>();

  const { pageable: waitingTabPageable, setPageable: setWaitingTabPageable } = usePageable(
    FI_DEALER_ASSIGNMENT_WAITING_LIST_QS_KEY,
  );
  const { pageable: assignedTabPageable, setPageable: setAssignedTabPageable } = usePageable(
    FI_DEALER_ASSIGNMENT_ASSIGNED_LIST_QS_KEY,
  );

  const fetchAll = async (): Promise<void> => {
    setFormValues<FinancierAnchorDealerListRequest>(
      assignedTabSearchForm.setValue,
      getParsedSearchParams(FI_DEALER_ASSIGNMENT_WAITING_LIST_QS_KEY).formSearchData,
    );
    setFormValues<FinancierAnchorDealerListRequest>(
      waitingTabSearchForm.setValue,
      getParsedSearchParams(FI_DEALER_ASSIGNMENT_ASSIGNED_LIST_QS_KEY).formSearchData,
    );

    const addFiAnchorDealerAssignmentListQs = () => {
      const waitingQs = makeSearchParamsPattern(
        {
          ...assignedTabSearchForm.getValues(),
          pageNumber: waitingTabPageable.currentPage,
          rowCount: waitingTabPageable.sizePerPage,
        },
        FI_DEALER_ASSIGNMENT_WAITING_LIST_QS_KEY,
      );
      const completedQs = makeSearchParamsPattern(
        {
          ...waitingTabSearchForm.getValues(),
          pageNumber: assignedTabPageable.currentPage,
          rowCount: assignedTabPageable.sizePerPage,
        },
        FI_DEALER_ASSIGNMENT_ASSIGNED_LIST_QS_KEY,
      );
      addSearchParams(waitingQs + completedQs);
    };
    addFiAnchorDealerAssignmentListQs();

    try {
      const [waitingForAssignAnchorDealerResponse, assignedAnchorDealerResponse] = await Promise.all([
        requestFinancierAnchorDealerList(waitingTabPageable.currentPage, waitingTabPageable.sizePerPage, {
          assigned: false,
        }),
        requestFinancierAnchorDealerList(assignedTabPageable.currentPage, assignedTabPageable.sizePerPage, {
          assigned: true,
        }),
      ]);

      setWaitingForAssignAnchorDealerData(waitingForAssignAnchorDealerResponse);
      setWaitingTabPageable(waitingForAssignAnchorDealerResponse);

      setAssignedAnchorDealerData(assignedAnchorDealerResponse);
      setAssignedTabPageable(assignedAnchorDealerResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchFiAnchorDealerAssignedList = async (
    pageNumber: number,
    rowCount: number,
    data: FinancierAnchorDealerListRequest,
  ) => {
    try {
      const assignedAnchorDealerResponse = await requestFinancierAnchorDealerList(pageNumber, rowCount, {
        ...data,
        assigned: true,
      });

      setAssignedAnchorDealerData(assignedAnchorDealerResponse);
      setAssignedTabPageable(assignedAnchorDealerResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const handleClickSearchAssignedList = async () => {
    await fetchFiAnchorDealerAssignedList(0, waitingTabPageable.sizePerPage, assignedTabSearchForm.getValues());
  };

  const paginateAssignedList = async (pageNumber: number, rowCount: number) => {
    await fetchFiAnchorDealerAssignedList(
      pageNumber,
      rowCount,
      getParsedSearchParams(FI_DEALER_ASSIGNMENT_WAITING_LIST_QS_KEY).formSearchData,
    );
  };

  const fetchFiAnchorDealerWaitingForAssignList = async (
    pageNumber: number,
    rowCount: number,
    data: FinancierAnchorDealerListRequest,
  ) => {
    try {
      const waitingForAssignAnchorDealerResponse = await requestFinancierAnchorDealerList(pageNumber, rowCount, {
        ...data,
        assigned: false,
      });

      setWaitingForAssignAnchorDealerData(waitingForAssignAnchorDealerResponse);
      setWaitingTabPageable(waitingForAssignAnchorDealerResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const handleClickSearchWaitingForAssignList = async () => {
    await fetchFiAnchorDealerWaitingForAssignList(0, assignedTabPageable.sizePerPage, waitingTabSearchForm.getValues());
  };

  const paginateWaitingForAssignList = async (pageNumber: number, rowCount: number) => {
    await fetchFiAnchorDealerWaitingForAssignList(
      pageNumber,
      rowCount,
      getParsedSearchParams(FI_DEALER_ASSIGNMENT_ASSIGNED_LIST_QS_KEY).formSearchData,
    );
  };

  const assignRequest = async (branchId: number, anchorDealerIds: number[]): Promise<REQUEST_RESULT> => {
    try {
      await requestFinancierAnchorDealerAssign({ branchId, anchorDealerIds });
      fetchAll();

      return REQUEST_RESULT.SUCCESS;
    } catch (error) {
      modal.show(error);

      return REQUEST_RESULT.FAIL;
    }
  };

  return {
    fetchAll,
    waitingTabUtils: {
      waitingForAssignAnchorDealerData,
      waitingTabPageable,
      waitingTabSearchForm,
      handleClickSearchWaitingForAssignList,
      paginateWaitingForAssignList,
      assignRequest,
    },
    assignedTabUtils: {
      assignedAnchorDealerData,
      assignedTabPageable,
      assignedTabSearchForm,
      handleClickSearchAssignedList,
      paginateAssignedList,
    },
  };
}
export default useFinancierAnchorDealerAssignmentListStateAdmin;
