import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import { LOAN_TERM_TYPE } from 'enums';

import useFinancingOptionViewModel from '../../models/financingOption/useFinancingOptionViewModel';

const useFinancingDurationController = () => {
  const { t } = useTranslation('format');
  const { anchorFinancingOption } = useFinancingOptionViewModel();

  const { loanTermType } = anchorFinancingOption;

  const {
    methods: { setValue, register, errors },
    isEditable,
  } = useFormContext();

  useEffect(() => {
    setValue('loanTermType', LOAN_TERM_TYPE.RANGE);
  }, [isEditable, loanTermType, setValue]);

  return {
    register,
    errors,
    isEditable,
  };
};

export default useFinancingDurationController;
