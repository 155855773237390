import { useState } from 'react';

import usePageable from 'hooks/usePageable';
import type { PlatformTransactionVOModel } from 'models/vo/PlatformTransactionVO';
import {
  requestSystemPlatformTransactionExecutionList,
  requestSystemPlatformTransactionFeeList,
} from 'utils/http/api/system/platform-transaction';
import type { SystemPlatformTransactionListRequest } from 'utils/http/api/system/platform-transaction/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

interface SystemTransactionPerformanceListStateType {
  performanceList: PlatformTransactionVOModel[];
  feeList: PlatformTransactionVOModel[];
}

export const SY_PERFORMANCE_LIST_QS_KEY = 'sy-performance-list';
export const SY_FEE_LIST_QS_KEY = 'sy-fee-list';

export function useSystemTransactionPerformanceListState() {
  const modal = useModal();

  const [dataState, setDataState] = useState<SystemTransactionPerformanceListStateType>({
    performanceList: [] as PlatformTransactionVOModel[],
    feeList: [] as PlatformTransactionVOModel[],
  });

  const { pageable: performanceListPageable, setPageable: setPerformanceListPageable } =
    usePageable(SY_PERFORMANCE_LIST_QS_KEY);
  const { pageable: transactionFeeListPageable, setPageable: setTransactionFeeListPageable } =
    usePageable(SY_FEE_LIST_QS_KEY);

  async function getSystemPerformanceList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemPlatformTransactionListRequest,
  ) {
    try {
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_PERFORMANCE_LIST_QS_KEY,
      );
      const response = await requestSystemPlatformTransactionExecutionList(pageNumber, rowCount, data);

      setPerformanceListPageable(response);

      setDataState({
        ...dataState,
        performanceList: [...response.content],
      });
    } catch (e: any) {
      modal.show(e);
    }
  }

  async function getSystemPerformanceLisPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestSystemPlatformTransactionExecutionList(
        pageNumber,
        rowCount,
        getParsedSearchParams(SY_PERFORMANCE_LIST_QS_KEY).formSearchData,
      );

      setPerformanceListPageable(response);

      setDataState({
        ...dataState,
        performanceList: [...response.content],
      });
    } catch (e: any) {
      modal.show(e);
    }
  }

  async function getSystemFeeList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemPlatformTransactionListRequest,
  ): Promise<void> {
    try {
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_FEE_LIST_QS_KEY,
      );
      const response = await requestSystemPlatformTransactionFeeList(pageNumber, rowCount, data);

      setTransactionFeeListPageable(response);

      setDataState({
        ...dataState,
        feeList: [...response.content],
      });
    } catch (error) {
      modal.show(error);
    }
  }

  async function getSystemFeeListPaginate(pageNumber: number = 1, rowCount: number = 10): Promise<void> {
    try {
      const response = await requestSystemPlatformTransactionFeeList(
        pageNumber,
        rowCount,
        getParsedSearchParams(SY_FEE_LIST_QS_KEY).formSearchData,
      );

      setTransactionFeeListPageable(response);

      setDataState({
        ...dataState,
        feeList: [...response.content],
      });
    } catch (error) {
      modal.show(error);
    }
  }

  return {
    state: dataState,
    getSystemPerformanceList,
    getSystemPerformanceLisPaginate,
    performanceListPageable,
    getSystemFeeList,
    getSystemFeeListPaginate,
    transactionFeeListPageable,
  };
}
