import type { MutableRefObject } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { CheckPluginValid } from 'digital-signatures/bkav/CheckPluginValid';
import { CheckPluginGetCert } from 'digital-signatures/utils/checkPluginGetCert';
import { isNil } from 'lodash-es';

import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import ExpectedFinancingRepaymentModal from 'components/stateless/Modal/common/loan/ExpectedFinancingRepaymentModal';
import { COLLATERAL_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import type { ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import type { LoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestFinancierEarlyRepaymentList } from 'utils/http/api/financier/early-repayment-requests';
import { requestFinancierExtensionList } from 'utils/http/api/financier/extension-requests';
import { requestFinancierCalendarList } from 'utils/http/api/financier/financier-calendar';
import {
  requestApproveFinancierLoanDisbursement,
  requestFinancierApproveCancelApprovedLoan,
  requestFinancierApproveCancelDisbursement,
  requestFinancierApproveLoanExpiration,
  requestFinancierApproveLoanOverdue,
  requestFinancierApproveLoanOverdueRelease,
  requestFinancierApproveLoanRepayment,
  requestFinancierCalculateExpectedAmount,
  requestFinancierCancelLoan,
  requestFinancierDownloadFactoringDocsForEsign,
  requestFinancierExecuteLoanDisbursement,
  requestFinancierLoanAdditionalDocumentAttachments,
  requestFinancierLoanDetail,
  requestFinancierLoanDisbursement,
  requestFinancierLoanExpiration,
  requestFinancierLoanOverdue,
  requestFinancierLoanOverdueRelease,
  requestFinancierLoanRepayment,
  requestFinancierLoanTransaction,
  requestFinancierLoanTransactionDelete,
  requestFinancierLoanTransactionRenew,
  requestFinancierRenewLoanByFinancierInterface,
  requestFinancierRequestApprovedLoanRejection,
  requestFinancierRequestCancelDisbursement,
  requestFinancierReturnLoan,
  requestFinancierUpdateRejectLoanDisbursement,
} from 'utils/http/api/financier/loans';
import type {
  FinancierExecuteLoanDisbursementRequest,
  FinancierLoanExpirationRequest,
  FinancierLoanRepaymentRequest,
  FinancierLoanTransactionRequest,
} from 'utils/http/api/financier/loans/request';
import { requestFinancierSuccessArsDetail } from 'utils/http/api/financier/success-ars';
import { requestFinancierSuccessInvoice } from 'utils/http/api/financier/success-invoices';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';
import { checkInterfaceType } from 'utils/storage/LocalStorage';

import FinancierFinancingDetailExecuteLoanDisbursementModal from './modals/FinancierFinancingDetailExecuteLoanDisbursementModal';
import FinancierFinancingDetailExpiredStatusRequestModal from './modals/FinancierFinancingDetailExpiredStatusRequestModal';
import FinancierFinancingDetailFactoringExecuteLoanDisbursementModal from './modals/FinancierFinancingDetailFactoringExecuteLoanDisbursementModal';
import FinancierFinancingDetailFactoringRequestRepaymentModal from './modals/FinancierFinancingDetailFactoringRequestRepaymentModal';
import FinancierFinancingDetailLoanTransactionRenewModal from './modals/FinancierFinancingDetailLoanTransactionRenewModal';
import FinancierFinancingDetailRequestRepaymentModal from './modals/FinancierFinancingDetailRequestRepaymentModal';
import FinancierFinancingDetailViewRepaymentHistoryModal from './modals/FinancierFinancingDetailViewRepaymentHistoryModal';

export type UseFinancierFinancingDetailStateReturnType = ReturnType<typeof useFinancierFinancingDetailState>;

type LoanRequestType = 'repayment' | 'expiredStatus' | 'executeDisbursement' | 'transaction';

type RequestRefTypes = {
  repayment: FinancierLoanRepaymentRequest;
  expiredStatus: FinancierLoanExpirationRequest;
  executeDisbursement: FinancierExecuteLoanDisbursementRequest;
  transaction: FinancierLoanTransactionRequest;
};

export function useFinancierFinancingDetailState() {
  const { id: modalId, show: showModal, disableConfirmBtn } = useModal();
  const { t } = useTranslation(['format']);
  const { loanId } = useParams<any>();
  const modal = useModal();

  const [dataState, setDataState] = useState({
    fiLoanDetail: {} as LoanDetailVOModel,
    fiInvoiceDetail: {} as SuccessInvoiceDetailVOModel,
    fiArDetail: {} as SuccessArDetailVOModel,
    fiEarlyRepaymentList: [] as EarlyRepaymentRequestVOModel[],
    fiExtensionList: [] as ExtensionRequestVOModel[],
    fiLoanTransactionHistoryPage: [] as LoanTransactionVOModel[],
    isNilInvoiceIdAndSuccessArId: undefined as boolean | undefined,
    useInterface: false as boolean,
    requiredAdditionalDocuments: [] as LoanAdditionalDocumentAttachmentVOModel[],
  });

  const repaymentRequestRef = useRef<FinancierLoanRepaymentRequest | null>(null);
  const expiredStatusRequestRef = useRef<FinancierLoanExpirationRequest | null>(null);
  const executeDisbursementRequestRef = useRef<FinancierExecuteLoanDisbursementRequest | null>(null);
  const transactionRequestRef = useRef<FinancierLoanTransactionRequest | null>(null);

  const { pageable: earlyRepaymentPageable, setPageable: setEarlyRepaymentPageable } = usePageable();
  const { pageable: extensionPageable, setPageable: setExtensionPageable } = usePageable();
  const { pageable: loanTransactionHistoryPageable, setPageable: setLoanTransactionHistoryPageable } = usePageable();

  const setLoanModalRequestData = useCallback(
    (type: LoanRequestType) => (data: RequestRefTypes[LoanRequestType]) => {
      const requestRefs: Record<LoanRequestType, MutableRefObject<RequestRefTypes[LoanRequestType] | null>> = {
        repayment: repaymentRequestRef,
        expiredStatus: expiredStatusRequestRef,
        executeDisbursement: executeDisbursementRequestRef,
        transaction: transactionRequestRef,
      };

      if (requestRefs[type]) {
        requestRefs[type].current = data;
      }
    },
    [],
  );

  const fetchAll = async () => {
    let collateralInformationData: any;
    let isNilInvoiceIdAndSuccessArId: boolean | undefined;

    try {
      const [
        fetchedLoanDetail,
        fetchedLoanTransactionHistoryPage,
        fetchedEarlyRepaymentList,
        fetchedExtensionList,
        fetchAdditionalDocumentAttachments,
      ] = await Promise.all([
        requestFinancierLoanDetail(loanId),
        requestFinancierLoanTransaction(loanId, 0, 10),
        requestFinancierEarlyRepaymentList(0, 10, {
          loanId,
        }),
        requestFinancierExtensionList(0, 10, { loanId }),
        requestFinancierLoanAdditionalDocumentAttachments(loanId, 0, 1000),
      ]);

      isNilInvoiceIdAndSuccessArId = isNil(fetchedLoanDetail.invoiceId) && isNil(fetchedLoanDetail.successArId);

      setDataState(prevState => ({
        ...prevState,
        fiLoanDetail: fetchedLoanDetail,
        fiLoanTransactionHistoryPage: [...fetchedLoanTransactionHistoryPage.content],
        fiEarlyRepaymentList: [...fetchedEarlyRepaymentList.content],
        fiExtensionList: [...fetchedExtensionList.content],
        isNilInvoiceIdAndSuccessArId: isNilInvoiceIdAndSuccessArId,
        requiredAdditionalDocuments: [...fetchAdditionalDocumentAttachments.content],
        useInterface:
          fetchedLoanDetail.collateralType === COLLATERAL_TYPE.AR
            ? checkInterfaceType(FINANCIER_INTERFACE_TYPE.LOAN_RESULT_AR)
            : checkInterfaceType(FINANCIER_INTERFACE_TYPE.LOAN_RESULT),
      }));

      if (!isNilInvoiceIdAndSuccessArId) {
        if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.INVOICE) {
          collateralInformationData = await requestFinancierSuccessInvoice(fetchedLoanDetail.invoiceId);
          setDataState(prevState => ({
            ...prevState,
            fiInvoiceDetail: collateralInformationData,
          }));
        }

        if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.AR) {
          collateralInformationData = await requestFinancierSuccessArsDetail(fetchedLoanDetail.successArId);
          setDataState(prevState => ({
            ...prevState,
            fiArDetail: collateralInformationData,
          }));
        }
      }

      setEarlyRepaymentPageable(fetchedEarlyRepaymentList);
      setLoanTransactionHistoryPageable(fetchedLoanTransactionHistoryPage);
      setExtensionPageable(fetchedExtensionList);
    } catch (e: any) {
      showModal(e);
    }
  };

  async function getFiLoanDetail() {
    try {
      const response = await requestFinancierLoanDetail(loanId);

      setDataState(prevState => ({
        ...prevState,
        fiLoanDetail: response,
      }));
    } catch (e: any) {
      showModal(e);
    }
  }

  async function getLoanTransactionHistory() {
    try {
      const response = await requestFinancierLoanTransaction(loanId, 0, 10);

      setDataState(prevState => ({
        ...prevState,
        fiLoanTransactionHistoryPage: [...response.content],
      }));
    } catch (e: any) {
      showModal(e);
    }
  }

  async function getLoanTransactionHistoryPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestFinancierLoanTransaction(loanId, pageNumber, rowCount);
      setLoanTransactionHistoryPageable(response);
      setDataState(prevState => ({
        ...prevState,
        fiLoanTransactionHistoryPage: [...response.content],
      }));
    } catch (e: any) {
      showModal(e);
    }
  }

  async function getEarlyRepaymentListPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestFinancierEarlyRepaymentList(pageNumber, rowCount, { loanId });
      setEarlyRepaymentPageable(response);
      setDataState(prevState => ({
        ...prevState,
        fiEarlyRepaymentList: [...response.content],
      }));
    } catch (e: any) {
      showModal(e);
    }
  }

  async function getExtensionListPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestFinancierExtensionList(pageNumber, rowCount, { loanId });
      setExtensionPageable(response);
      setDataState(prevState => ({
        ...prevState,
        fiExtensionList: [...response.content],
      }));
    } catch (e: any) {
      showModal(e);
    }
  }

  // return 반려 - 반려 관련된 기능은 동일한 api 사용,
  // return Type 을 지정해, 성공적으로 반려 시 그에 해당하는 모달 내용 보여주기
  const submitChangeReturn = async (
    reason: string,
    returnType: 'delinquent' | 'expired' | 'repayment' | 'financing' | 'delinquentRelease',
  ) => {
    try {
      await requestFinancierReturnLoan(loanId, reason);
      const modalContent = () => {
        switch (returnType) {
          case 'delinquent':
            return t('text:The_delinquent_status_request_has_been_reverted');
          case 'expired':
            return t('text:The_expired_status_request_has_been_reverted');
          case 'repayment':
            return t('text:The_repaid_status_request_has_been_reverted');
          case 'financing':
            return t('text:The_financing_approval_request_has_been_reverted');
          case 'delinquentRelease':
            return t('text:The_delinquent_status_removal_request_has_been_reverted');
          default:
            return '';
        }
      };

      showModal(<h6>{modalContent()}</h6>, {
        closeBtnText: t('text:OK'),
      });
    } catch (e) {
      showModal(e);
    }
  };

  // reason 모달 - 공통
  const showCommonReasonModal = (
    message: string[],
    submitConfirmFn: (reason: string) => void,
    reasonRequired?: boolean,
    title: string = 'text:Notice',
  ) => {
    let reason = '';

    const reasonModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t(title),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitConfirmFn(reason);
      },
    };

    showModal(
      <>
        <h6>
          {message.map((item, i) => (
            <React.StrictMode key={i}>
              {item}
              {i !== item.length - 1 && <br />}
            </React.StrictMode>
          ))}
        </h6>
        <ReasonModal
          modalId={modalId}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
          required={reasonRequired}
        />
      </>,
      reasonModalOptions,
    );
  };

  // 현재 신청한 대출 처리에 대한 취소 요청
  const submitChangeLoanCancelRequest = async () => {
    try {
      await requestFinancierCancelLoan(loanId);
      getFiLoanDetail();
    } catch (e) {
      showModal(e);
    }
  };

  const onClickCancelConfirmRequest = (e: any) => {
    e.preventDefault();

    const cancelConfirmRequestModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Cancel_Confirm_Request'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    };
    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_approval_request?')}
        <br />
        {t('text:Cancelled_requests_cannot_be_reversed_and_must_be_requested_again')}
      </h6>,
      cancelConfirmRequestModalOptions,
    );
  };

  // 상환처리 버튼
  const onClickRequestFinancierLoanRepayment = (e: any) => {
    e.preventDefault();

    const submitChangeRepayment = async (data: FinancierLoanRepaymentRequest | null) => {
      if (!data) return;

      try {
        await requestFinancierLoanRepayment(loanId, data);
        getFiLoanDetail();
      } catch (e) {
        showModal(e);
      }
    };

    const requestFinancierLoanRepaymentModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Repayment_Status_Request'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeRepayment(repaymentRequestRef.current);
      },
    };

    showModal(
      dataState.fiLoanDetail.factoringEnable ? (
        <FinancierFinancingDetailFactoringRequestRepaymentModal
          repaidInterestAmount={dataState.fiLoanDetail.repaidInterestAmount}
          disbursedDate={dataState.fiLoanDetail.disbursedDate}
          getSubmitData={setLoanModalRequestData('repayment')}
          modalInfo={{ id: modalId, disableConfirmBtn }}
          repaidDelayAmount={dataState.fiLoanDetail.repaidDelayAmount}
          loanId={loanId}
        />
      ) : (
        <FinancierFinancingDetailRequestRepaymentModal
          repaidInterestAmount={dataState.fiLoanDetail.repaidInterestAmount}
          repaidDelayAmount={dataState.fiLoanDetail.repaidDelayAmount}
          disbursedDate={dataState.fiLoanDetail.disbursedDate}
          getSubmitData={setLoanModalRequestData('repayment')}
          modalInfo={{ id: modalId, disableConfirmBtn }}
          loanId={loanId}
        />
      ),

      requestFinancierLoanRepaymentModalOptions,
    );
  };

  // 연체처리 버튼
  const onClickRequestFinancierLoanOverdue = (e: any) => {
    e.preventDefault();
    const submitChangeOverdue = async () => {
      try {
        await requestFinancierLoanOverdue(loanId);
        getFiLoanDetail();
      } catch (e) {
        showModal(e);
      }
    };

    const requestFinancierLoanOverdueModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Delinquent_Status_Request'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeOverdue,
    };

    showModal(
      <h6>
        {t('text:Would_you_like_to_request_the_Authorizer_s_approval_to_change_the_financing_status_to_Delinquent?')}
      </h6>,
      requestFinancierLoanOverdueModalOptions,
    );
  };

  // 상환 처리 내역 modal
  const onClickViewRepaymentHistory = (e: any) => {
    e.preventDefault();

    showModal(<FinancierFinancingDetailViewRepaymentHistoryModal data={dataState.fiLoanDetail} />, {
      modalType: ModalType.ALERT,
      title: t('text:View_Repayment_History'),
      closeBtnText: t('text:Close'),
    });
  };

  // 상환처리 요청 취소
  const onClickCancelRepayment = (e: any) => {
    e.preventDefault();
    const cancelRepaymentModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Close'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    };
    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_request_to_change_the_financing_status_to_Repaid?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      cancelRepaymentModalOptions,
    );
  };

  // 상환 처리 승인
  const onClickConfirmRepaidStatus = (e: any) => {
    e.preventDefault();
    showModal(<h6>{t('text:Would_you_like_to_approve_the_request_to_change_the_financing_status_to_Repaid?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestFinancierApproveLoanRepayment(loanId);
          showModal(
            <h6>{t('text:The_approval_was_completed_successfully_and_the_financing_status_has_changed_to_Repaid')}</h6>,
            {
              modalType: ModalType.ALERT,
              closeBtnText: t('text:OK'),
              closeBtnCb: () => {
                getFiLoanDetail();
                getLoanTransactionHistory();
              },
            },
          );
        } catch (e) {
          showModal(e);
        }
      },
    });
  };

  // 상환 처리 반려
  const onClickRevertRepaidStatusRequest = (e: any) => {
    e.preventDefault();

    const submitRevertRepaidStatusRequest = async (reason: string) => {
      submitChangeReturn(reason, 'repayment').then(() => {
        getFiLoanDetail();
      });
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_request_to_change_the_financing_status_to_Repaid?'),
        t('text:Please_enter_the_reason_for_the_revert_below'),
      ],
      submitRevertRepaidStatusRequest,
      true,
    );
  };

  // 연체 처리 요청 취소
  const onClickCancelOverdue = (e: any) => {
    e.preventDefault();

    showModal(<h6>{t('text:Would_you_like_to_cancel_the_request_to_change_the_financing_status_to_Delinquent?')}</h6>, {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      confirmBtnCb: async () => {
        try {
          await requestFinancierCancelLoan(loanId);
          showConfirmModal();
        } catch (e) {
          showModal(e);
        }
      },
      closeBtnText: t('text:Close'),
    });

    const showConfirmModal = () => {
      showModal(<h6>{t('text:The_delinquent_status_request_has_been_cancelled_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: getFiLoanDetail,
      });
    };
  };

  // 연체 처리 요청 승인
  const onClickConfirmDelinquentStatus = (e: any) => {
    e.preventDefault();

    showModal(
      <h6>{t('text:Would_you_like_to_approve_the_request_to_change_the_financing_status_to_Delinquent?')}</h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        confirmBtnCb: async () => {
          try {
            await requestFinancierApproveLoanOverdue(loanId);
            showConfirmModal();
          } catch (e) {
            showModal(e);
          }
        },
        closeBtnText: t('text:Cancel'),
      },
    );

    const showConfirmModal = () => {
      showModal(
        <h6>{t('text:The_approval_was_completed_successfully_and_the_financing_status_has_changed_to_Delinquent')}</h6>,
        {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: getFiLoanDetail,
        },
      );
    };
  };

  // 연체 처리 요청 반려
  const onClickRevertDelinquentStatusRequest = (e: any) => {
    e.preventDefault();

    const submitRevertDelinquentStatusRequest = (reason: string) => {
      submitChangeReturn(reason, 'delinquent').then(() => {
        getFiLoanDetail();
      });
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_request_to_change_the_financing_status_to_Delinquent?'),
        t('text:Please_enter_the_reason_below'),
      ],
      submitRevertDelinquentStatusRequest,
      true,
    );
  };

  // 연체 해제 요청
  const onClickRemoveDelinquentStatus = (e: any) => {
    e.preventDefault();
    const submitChangeOverdueRelease = async () => {
      try {
        await requestFinancierLoanOverdueRelease(loanId);
        showOverdueReleaseOKModal();
      } catch (e) {
        showModal(e);
      }
    };

    const requestOverdueReleaseModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeOverdueRelease();
      },
    };

    const showOverdueReleaseOKModal = () => {
      const requestOverdueReleaseOKModalOptions: ModalOptions = {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: getFiLoanDetail,
      };
      showModal(
        <h6>{t('text:The_request_to_remove_the_Delinquent_status_has_been_submitted_to_the_Authorizer')}</h6>,
        requestOverdueReleaseOKModalOptions,
      );
    };
    showModal(
      <h6>
        {t('text:Would_you_like_to_request_the_Authorizer_s_approval_to_remove_the_Delinquent_financing_status?')}
        <br />
        {t('text:If_approved_by_the_Authorizer_the_financing_status_will_change_to_the_Disbursed_status')}
      </h6>,
      requestOverdueReleaseModalOptions,
    );
  };

  // 연체 해제 요청 취소
  const onClickCancelOverdueRelease = (e: any) => {
    e.preventDefault();
    const cancelOverdueReleaseModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    };
    showModal(
      <h6>{t('text:Would_you_like_to_cancel_the_request_to_remove_the_Delinquent_financing_status?')}</h6>,
      cancelOverdueReleaseModalOptions,
    );
  };

  // 연체 해제 요청 승인
  const onClickConfirmDelinquentStatusRemovalRequest = (e: any) => {
    e.preventDefault();
    const submitApproveOverdueRelease = async () => {
      try {
        await requestFinancierApproveLoanOverdueRelease(loanId);
        showApproveOverdueReleaseOKModal();
      } catch (e) {
        showModal(e);
      }
    };

    const approveOverdueReleaseModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => {
        submitApproveOverdueRelease();
      },
    };
    const showApproveOverdueReleaseOKModal = () => {
      const approveOverdueReleaseOKModalOptions: ModalOptions = {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: getFiLoanDetail,
      };
      showModal(
        <h6>{t('text:The_Delinquent_financing_status_has_been_removed_successfully')}</h6>,
        approveOverdueReleaseOKModalOptions,
      );
    };
    showModal(
      <h6>
        {t('text:Would_you_like_to_approve_the_request_to_remove_the_Delinquent_financing_status?')}
        <br />
        {t('text:If_approved_the_financing_status_will_change_to_the_Disbursed_status')}
      </h6>,
      approveOverdueReleaseModalOptions,
    );
  };

  //  연체 해제 요청 반려
  const onClickRevertDelinquentStatusRemovalRequest = (e: any) => {
    e.preventDefault();

    const submitRevertDelinquentStatusRemovalRequest = (reason: string) => {
      submitChangeReturn(reason, 'delinquentRelease').then(() => {
        getFiLoanDetail();
      });
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_request_to_remove_the_Delinquent_financing_status?'),
        t('text:Please_enter_the_reason_for_the_revert_below'),
      ],
      submitRevertDelinquentStatusRemovalRequest,
      true,
    );
  };

  // 기타 만료 요청
  const onClickExpiredStatusRequest = (e: any) => {
    e.preventDefault();

    const showConfirmModal = () => {
      showModal(
        <h6>{t('text:The_request_to_change_the_financing_status_to_Expired_has_been_submitted_to_the_Authorizer')}</h6>,
        {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: getFiLoanDetail,
        },
      );
    };

    const submitChangeRequestExpiration = async (data: FinancierLoanExpirationRequest | null) => {
      if (!data) return;

      try {
        await requestFinancierLoanExpiration(loanId, data);
        showConfirmModal();
      } catch (e) {
        showModal(e);
      }
    };

    showModal(
      <FinancierFinancingDetailExpiredStatusRequestModal
        getSubmitData={setLoanModalRequestData('expiredStatus')}
        modalInfo={{ id: modalId, disableConfirmBtn }}
      />,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => submitChangeRequestExpiration(expiredStatusRequestRef.current),
      },
    );
  };

  // 기타만료 취소
  const onClickCancelExpiredStatusRequest = (e: any) => {
    e.preventDefault();
    const returnModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    };
    showModal(
      <h6>{t('text:Would_you_like_to_cancel_the_request_to_change_the_financing_status_to_Expired?')}</h6>,
      returnModalOptions,
    );
  };

  // 기타만료 승인
  const onClickConfirmExpiredStatusRequest = (e: any) => {
    e.preventDefault();

    showModal(<h6>{t('text:Would_you_like_to_approve_the_request_to_change_the_financing_status_to_Expired?')}</h6>, {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestFinancierApproveLoanExpiration(loanId);
          showConfirmModal();
        } catch (e) {
          showModal(e);
        }
      },
    });
    const showConfirmModal = () => {
      showModal(<h6>{t('text:The_financing_expiration_has_been_completed_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: getFiLoanDetail,
      });
    };
  };

  // 기타만료 반려
  const onClickRevertExpiredStatusRequest = (e: any) => {
    e.preventDefault();

    const submitRevertExpiredStatusRequest = (reason: string) => {
      submitChangeReturn(reason, 'expired').then(() => {
        getFiLoanDetail();
      });
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_request_to_change_the_financing_status_to_Expired?'),
        t('text:Please_enter_the_reason_below'),
      ],
      submitRevertExpiredStatusRequest,
      true,
    );
  };

  // 지급확인 버튼
  const onClickExecuteLoanDisbursement = (e: any) => {
    e.preventDefault();
    const isLoanExecutionImpossibleToday = new Date(dataState.fiLoanDetail.desiredDisburseDate) > new Date();
    // 수기
    const submitChangeExecuteLoanDisbursement = async (data: FinancierExecuteLoanDisbursementRequest | null) => {
      if (!data) return;

      try {
        await requestFinancierExecuteLoanDisbursement(loanId, data);
        showModal(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
          closeBtnCb: async () => {
            await getFiLoanDetail();
            await getLoanTransactionHistory();
          },
        });
      } catch (e) {
        showModal(e);
      }
    };

    // 전문
    const submitChangeExecuteLoanDisbursementInterface = async () => {
      try {
        await requestFinancierRenewLoanByFinancierInterface(loanId);
        showModal(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
          closeBtnCb: getFiLoanDetail,
        });
      } catch (e) {
        showModal(e);
      }
    };

    if (dataState.useInterface) {
      submitChangeExecuteLoanDisbursementInterface();
    } else {
      // 수기 모달 옵션
      const executeLoanDisbursementModalOptions: ModalOptions = {
        modalType: ModalType.CONFIRM,
        title: t('text:Enter_Disbursement_Information'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => submitChangeExecuteLoanDisbursement(executeDisbursementRequestRef.current),
        confirmBtnDisabled: dataState.fiLoanDetail.factoringEnable && isLoanExecutionImpossibleToday,
      };

      const repaymentDatePrev = dayjs(new Date(dataState.fiLoanDetail.repaymentDate)).subtract(1, 'days').toDate();

      showModal(
        dataState.fiLoanDetail.factoringEnable ? (
          <FinancierFinancingDetailFactoringExecuteLoanDisbursementModal
            loanDetailData={dataState.fiLoanDetail}
            isLoanExecutionImpossibleToday={isLoanExecutionImpossibleToday}
            repaymentDatePrev={repaymentDatePrev}
            getSubmitData={setLoanModalRequestData('executeDisbursement')}
            modalInfo={{
              id: modalId,
              disableConfirmBtn,
            }}
          />
        ) : (
          <FinancierFinancingDetailExecuteLoanDisbursementModal
            principalAmount={dataState.fiLoanDetail.principalAmount}
            repaymentDatePrev={repaymentDatePrev}
            requestedDateTime={dataState.fiLoanDetail.requestedDateTime}
            getSubmitData={setLoanModalRequestData('executeDisbursement')}
            modalInfo={{
              id: modalId,
              disableConfirmBtn,
            }}
            loanId={loanId}
            showNetDisbursementAmount={dataState.fiLoanDetail.showNetDisbursementAmount}
          />
        ),
        executeLoanDisbursementModalOptions,
      );
    }
  };

  // 지급 취소 요청 (OP)
  const onClickRequestCancelDisbursement = (e: any) => {
    e.preventDefault();

    const showConfirmModal = () => {
      modal.show(<h6>{t('text:The_disbursement_cancellation_request_has_been_requested')}</h6>, {
        closeBtnCb: async () => await getFiLoanDetail(),
      });
    };

    const submitRequestCancelDisbursementRequest = async (reason: string) => {
      try {
        await requestFinancierRequestCancelDisbursement(loanId, reason);
        showConfirmModal();
      } catch (e) {
        modal.show(e);
      }
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_request_the_Authorizers_confirmation_to_cancel_the_financing_disbursement?'),
        t('text:By_cancelling_the_disbursement_financing_status_will_change_to_Approved'),
        t('text:Please_enter_the_reason_below'),
      ],
      submitRequestCancelDisbursementRequest,
      true,
      t('text:Disbursement_Cancellation_Request'),
    );
  };

  // 지급 취소 요청한 건에 대해 취소 (OP)
  const onClickCancelDisbursementCancellationRequest = (e: any) => {
    e.preventDefault();

    showModal(<h6>{t('text:Would_you_like_to_cancel_your_financing_disbursement_status_cancellation_request?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    });
  };

  // 지급 취소 요청 승인 (AU)

  const onClickApproveDisbursementCancellation = (e: any) => {
    e.preventDefault();

    const submitApproveDisbursementCancellation = async () => {
      try {
        await requestFinancierApproveCancelDisbursement(loanId);
        getFiLoanDetail();
      } catch (e) {
        showModal(e);
      }
    };

    showModal(
      <h6>
        {t('text:Would_you_like_to_confirm_the_cancellation_request?')}
        <br />
        {t('text:If_confirmed_the_cancellation_cannot_be_reverted')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: submitApproveDisbursementCancellation,
      },
    );
  };

  // 지급 취소 요청 거절  (AU)
  const onClickRevertDisbursementCancellation = () => {
    const showConfirmModal = () => {
      modal.show(<h6>{t('text:The_disbursement_cancellation_request_has_been_reverted')}</h6>, {
        closeBtnCb: async () => {
          await getFiLoanDetail();
          scrollToTopOfElement();
        },
      });
    };
    const submitRevert = async (reason: string) => {
      try {
        await requestFinancierReturnLoan(loanId, reason);
        showConfirmModal();
      } catch (e) {
        showModal(e);
      }
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_disbursement_cancellation_request?'),
        t('text:Please_enter_the_reason_for_the_revert_below'),
      ],
      submitRevert,
      true,
      t('text:Revert_Cancellation_Request'),
    );
  };

  // 거래 갱신
  const onClickRenewLoanTransactions = (e: any) => {
    e.preventDefault();

    const { factoringEnable } = dataState.fiLoanDetail;

    const renewLoanTransactionSuccessModal = () => {
      showModal(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          getFiLoanDetail();
          getLoanTransactionHistory();
        },
      });
    };

    // 전문 방식
    const submitChangeRenewLoanByFinancierInterface = async () => {
      try {
        await requestFinancierRenewLoanByFinancierInterface(loanId);
        renewLoanTransactionSuccessModal();
      } catch (e) {
        showModal(e);
      }
    };

    // 수기 방식
    const submitChangeRenewLoanTransactions = async (data: FinancierLoanTransactionRequest | null) => {
      if (!data) return;

      try {
        await requestFinancierLoanTransactionRenew(loanId, data);
        renewLoanTransactionSuccessModal();
      } catch (e) {
        showModal(e);
      }
    };

    if (dataState.useInterface) {
      submitChangeRenewLoanByFinancierInterface();
    } else {
      const renewLoanTransactionsModalOptions: ModalOptions = {
        modalType: ModalType.CONFIRM,
        title: t('text:Update_Repayment_Transaction'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => submitChangeRenewLoanTransactions(transactionRequestRef.current),
      };

      showModal(
        <FinancierFinancingDetailLoanTransactionRenewModal
          disbursedDate={dataState.fiLoanDetail.disbursedDate}
          getSubmitData={setLoanModalRequestData('transaction')}
          factoringEnable={factoringEnable}
          modalInfo={{
            id: modalId,
            disableConfirmBtn,
          }}
        />,
        renewLoanTransactionsModalOptions,
      );
    }
  };

  const onClickDeleteLoanTransactions = async (e: any) => {
    e.preventDefault();
    // 전문 방식

    try {
      await requestFinancierLoanTransactionDelete(loanId);
      getFiLoanDetail();
      getLoanTransactionHistory();
    } catch (e: any) {
      showModal(e);
    }
  };

  // page button
  const onClickRevertRequest = (e: any) => {
    e.preventDefault();

    const showReturnModal = () => {
      const submitRevert = async (reason: string) => {
        await submitChangeReturn(reason, 'financing');
        await getFiLoanDetail();
        scrollToTopOfElement();
      };

      showCommonReasonModal(
        [
          t('text:Would_you_like_to_revert_the_financing_application_approval_request?'),
          t('text:Please_enter_the_reason_for_the_revert_below'),
        ],
        submitRevert,
        true,
      );
    };

    showReturnModal();
  };

  const onClickConfirmRequest = (e: any) => {
    e.preventDefault();

    const submitChangeConfirm = async () => {
      try {
        await requestFinancierLoanDisbursement(loanId);
        showConfirmRequestOKModal();
      } catch (e) {
        showModal(e);
      }
    };

    const showConfirmRequestOKModal = () => {
      const confirmRequestOKModalOptions: ModalOptions = {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await getFiLoanDetail();
          scrollToTopOfElement();
        },
      };
      showModal(
        <h6>{t('text:The_financing_application_approval_has_been_completed')}</h6>,
        confirmRequestOKModalOptions,
      );
    };

    const confirmModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeConfirm();
      },
    };
    showModal(
      <h6>
        {t('text:Would_you_like_to_approve_the_financing_application?')}
        <br />
        {t('text:The_financing_approval_will_be_completed_after_being_reviewed_by_the_authorizer')}
      </h6>,
      confirmModalOptions,
    );
  };

  const onClickApproveLoanDisbursement = async (e: any) => {
    e.preventDefault();

    const { eSignatureEnable, factoringEnable } = dataState.fiLoanDetail;

    const executeDigitalSignature = async () => {
      showLoadingUI();

      try {
        const factoringDocs = await requestFinancierDownloadFactoringDocsForEsign(loanId);

        CheckPluginValid.CheckPluginValid((data: any) =>
          CheckPluginGetCert({
            data,
            modal,
            PDFInfos: factoringDocs,
            pdfSignCompletedCb: async (
              loanAgreementFileList: FileList,
              loanFactoringNotificationFileList: FileList,
            ) => {
              await requestApproveFinancierLoanDisbursement(loanId, {
                loanAgreementAttachment: loanAgreementFileList,
                loanFactoringNotificationAttachment: loanFactoringNotificationFileList,
              });
              modal.show(<h6>{t('text:The_financing_application_approval_has_been_completed')}</h6>, {
                modalType: ModalType.ALERT,
                title: t('text:Notice'),
                closeBtnText: t('text:OK'),
                closeBtnCb: async () => {
                  await getFiLoanDetail();
                  scrollToTopOfElement();
                },
              });
            },
          }),
        );
      } catch (e) {
        unShowLoadingUI();
        modal.show(e);
      }
    };

    const submitChangeConfirm = async () => {
      try {
        await requestApproveFinancierLoanDisbursement(loanId);
        showConfirmRequestOKModal();
      } catch (e) {
        showModal(e);
      }
    };

    const showConfirmRequestOKModal = () => {
      const confirmRequestOKModalOptions: ModalOptions = {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await getFiLoanDetail();
          scrollToTopOfElement();
        },
      };
      showModal(
        <h6>
          {t('text:The_financing_application_approval_has_been_completed')}
          {factoringEnable && (
            <>
              <br />
              {t('text:Debt_selling_Notification_email_has_been_sent_to_Anchor')}
            </>
          )}
        </h6>,
        confirmRequestOKModalOptions,
      );
    };

    const confirmModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeConfirm();
      },
    };

    if (eSignatureEnable) {
      await executeDigitalSignature();

      return;
    } else {
      showModal(<h6>{t('text:Would_you_like_to_approve_the_financing_application?')}</h6>, confirmModalOptions);
    }
  };

  const onClickRejectRequest = (e: any) => {
    e.preventDefault();

    const submitChangeReject = async (reason: string) => {
      try {
        await requestFinancierUpdateRejectLoanDisbursement(loanId, reason);
        showModal(<h6>{t('text:The_financing_application_rejection_has_been_completed')}</h6>, {
          modalType: ModalType.ALERT,
          title: t('text:Financing_Rejection'),
          closeBtnText: t('text:OK'),
          closeBtnCb: async () => {
            await getFiLoanDetail();
            scrollToTopOfElement();
          },
        });
      } catch (e) {
        showModal(e);
      }
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_reject_the_financing_application?'),
        t(
          'text:If_you_refuse,_the_previous_status_cannot_be_reversed_and_the_financing_process_must_be_resumed_from_the_beginning',
        ),
        t('text:Please_enter_the_reason_for_cancelling_the_financing_application_below'),
      ],
      submitChangeReject,
      true,
    );
  };

  const onClickCancelFinancingApproval = (e: any) => {
    e.preventDefault();
    const showConfirmModal = () => {
      showModal(<h6>{t('text:The_financing_rejection_request_has_been_submitted_to_the_Authorizer')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: getFiLoanDetail,
      });
    };

    const submitApprovedLoanReject = async (reason: string) => {
      try {
        await requestFinancierRequestApprovedLoanRejection(loanId, reason);
        showConfirmModal();
      } catch (e) {
        showModal(e);
      }
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_request_the_Authorizers_confirmation_to_cancel_the_financing_approval?'),
        t(
          'text:By_cancelling_the_approval_the_financing_status_will_change_to_Rejected_and_the_financing_application_will_have_to_be_resubmitted',
        ),
        t('text:Please_enter_the_reason_for_the_rejection_below'),
      ],
      submitApprovedLoanReject,
      true,
      'text:Financing_Rejection_Request',
    );
  };

  const onClickCancelRejectionRequest = (e: any) => {
    e.preventDefault();

    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_rejection_request?')}
        <br />
        {t('text:Cancelled_requests_cannot_be_reversed_and_must_be_requested_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Cancel_Rejection_Request'),
        confirmBtnCb: async () => {
          try {
            await requestFinancierCancelLoan(loanId);
            showConfirmModal();
          } catch (e) {
            showModal(e);
          }
        },
        closeBtnText: t('text:Close'),
      },
    );

    const showConfirmModal = () => {
      showModal(<h6>{t('text:The_request_to_reject_the_financing_has_been_cancelled')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: getFiLoanDetail,
      });
    };
  };

  const onClickConfirmRejectionRequest = (e: any) => {
    e.preventDefault();

    showModal(
      <h6>
        {t('text:Would_you_like_to_confirm_the_rejection_request?')}
        <br />
        {t('text:The_rejection_cannot_be_reverted_and_the_financing_application_will_have_to_be_resubmitted')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Confirm_Rejection_Request'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestFinancierApproveCancelApprovedLoan(loanId);
            showConfirmModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
    const showConfirmModal = () => {
      showModal(<h6>{t('text:The_financing_rejection_has_been_completed')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: getFiLoanDetail,
      });
    };
  };

  const onClickRevertRejectionRequest = (e: any) => {
    e.preventDefault();
    const submitRevertRejectionRequest = async (reason: string) => {
      try {
        await requestFinancierReturnLoan(loanId, reason);
        showRevertModal();
      } catch (e) {
        showModal(e);
      }
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_financing_rejection_request?'),
        t('text:Please_enter_the_reason_for_the_revert_below'),
      ],
      submitRevertRejectionRequest,
      true,
      'text:Revert_Rejection_Request',
    );

    const showRevertModal = () => {
      showModal(<h6>{t('text:The_financing_rejection_request_has_been_reverted')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await getFiLoanDetail();
          scrollToTopOfElement();
        },
      });
    };
  };

  const onClickViewExpectedRepaymentAmount = async (e: any) => {
    e.preventDefault();
    const { repaymentDate, disbursedDate } = dataState.fiLoanDetail;

    const expectedRepaymentDate = new Date(repaymentDate);
    const expectedRepaymentDateList: number[] = [
      expectedRepaymentDate.getFullYear(),
      expectedRepaymentDate.getMonth(),
      expectedRepaymentDate.getDate(),
    ];
    const toDate = new Date(
      expectedRepaymentDateList[0],
      expectedRepaymentDateList[1],
      expectedRepaymentDateList[2] + 180,
    );

    async function fetchAll() {
      const [expectedFinancingRepaymentDataResponse, financierHolidayResponse] = await Promise.all([
        requestFinancierCalculateExpectedAmount(loanId, repaymentDate),
        requestFinancierCalendarList(
          0,
          Number(getDayTerm(convertToServerDateFormat(new Date(disbursedDate)), convertToServerDateFormat(toDate))) + 1,
          {
            fromDate: convertToServerDateFormat(new Date(disbursedDate)),
            toDate: convertToServerDateFormat(toDate),
            holiday: true,
          },
        ),
      ]);

      return { expectedFinancingRepaymentDataResponse, financierHolidayResponse };
    }

    showModal(
      <ExpectedFinancingRepaymentModal
        loanId={loanId}
        repaymentDate={repaymentDate}
        fetchAll={fetchAll}
        disbursedDate={disbursedDate}
        maxDate={toDate}
        requestSearch={requestFinancierCalculateExpectedAmount}
        factoringEnable={dataState.fiLoanDetail.factoringEnable}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.L,
        title: t('text:View_Expected_Repayment_Amount'),
      },
    );
  };

  return {
    state: dataState,
    fetchAll,
    pageables: { earlyRepaymentPageable, extensionPageable, loanTransactionHistoryPageable },
    paginates: {
      getLoanTransactionHistoryPaginate,
      getEarlyRepaymentListPaginate,
      getExtensionListPaginate,
    },
    updateFinancingStatusActions: {
      onClickCancelConfirmRequest,
      onClickRequestFinancierLoanRepayment,
      onClickRequestFinancierLoanOverdue,
      onClickViewRepaymentHistory,
      onClickCancelRepayment,
      onClickConfirmRepaidStatus,
      onClickRevertRepaidStatusRequest,
      onClickCancelOverdue,
      onClickConfirmDelinquentStatus,
      onClickRevertDelinquentStatusRequest,
      onClickRemoveDelinquentStatus,
      onClickCancelOverdueRelease,
      onClickConfirmDelinquentStatusRemovalRequest,
      onClickRevertDelinquentStatusRemovalRequest,
      onClickExpiredStatusRequest,
      onClickCancelExpiredStatusRequest,
      onClickConfirmExpiredStatusRequest,
      onClickRevertExpiredStatusRequest,
      onClickExecuteLoanDisbursement,
      onClickRenewLoanTransactions,
      onClickDeleteLoanTransactions,
      onClickConfirmRequest,
      onClickApproveLoanDisbursement,
      onClickRevertRequest,
      onClickRejectRequest,
      onClickCancelFinancingApproval,
      onClickCancelRejectionRequest,
      onClickConfirmRejectionRequest,
      onClickRevertRejectionRequest,
      onClickViewExpectedRepaymentAmount,
      onClickRequestCancelDisbursement,
      onClickCancelDisbursementCancellationRequest,
      onClickApproveDisbursementCancellation,
      onClickRevertDisbursementCancellation,
    },
  };
}
