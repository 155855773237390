import type { ChangeEvent, MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AnchorDealerRegistrationConfirmModal from 'components/stateless/Modal/common/dealer/AnchorDealerRegistrationConfirmModal';
import AnchorDealerRegistrationResultModal from 'components/stateless/Modal/common/dealer/AnchorDealerRegistrationResultModal';
import { ROUTES_FI } from 'constants/routes/financier';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { requestFinancierWaitingAnchorDealerRegister } from 'utils/http/api/financier/waiting-anchor-dealers';
import type {
  FinancierWaitingAnchorDealerRequest,
  WaitingAnchorDealers,
} from 'utils/http/api/financier/waiting-anchor-dealers/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const useFinancierAnchorDealerRegistrationStep2Page = () => {
  const [locationState] = useLocationState<FinancierClientVOModel>([
    'financierClientName',
    'enterpriseId',
    'financierClientTaxCode',
  ]);

  const { t } = useTranslation();
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();

  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);

  const formMethods = useForm<{
    waitingAnchorDealers: WaitingAnchorDealers[];
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { reset, control, getValues, trigger } = formMethods;

  const useFieldArrayMethods = useFieldArray<WaitingAnchorDealers>({
    control,
    name: 'waitingAnchorDealers',
  });

  const { fields, append, remove } = useFieldArrayMethods;

  useEffect(() => {
    if (mounted) {
      reset({
        waitingAnchorDealers: [{}],
      });
    }
  }, [mounted, reset]);

  const validateDuplicateTaxCode = (taxCode: string, index: number): boolean => {
    const { waitingAnchorDealers } = getValues();
    const lowercasedTaxCodes = waitingAnchorDealers.map(({ taxCode }) => taxCode.toLowerCase());
    lowercasedTaxCodes.splice(index, 1);
    const filteredTaxCodes = lowercasedTaxCodes.filter(taxCode => taxCode);
    const isPassedDuplicateValidity = !filteredTaxCodes.includes(taxCode.toLowerCase());

    return isPassedDuplicateValidity;
  };

  const updateCheckedValidation = () => {
    let i = 0;
    const temp = [...checkedValidation];

    for (const index of checkedRows) {
      temp.splice(index - i, 1);
      i++;
    }

    return temp;
  };

  const removeRow = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (checkedRows.length === fields.length) {
      reset({
        waitingAnchorDealers: [{}],
      });
      setCheckedValidation([]);
    } else {
      remove(checkedRows);
      setCheckedValidation(updateCheckedValidation());
    }
    setCheckedRows([]);
  };

  const appendRow = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    append({});
  };

  const handleCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const checkedRows = isChecked ? fields.map((_, index) => index) : [];
    setCheckedRows(checkedRows);
  };

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    if (event.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const handleClickCancelButton = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_FI.MANAGE_DEALER.REGISTRATION_STEP1),
      },
    );
  };

  const handleClickRegisterButton = async () => {
    const { waitingAnchorDealers } = getValues();
    const isPassedFrontValidate = await trigger();

    if (!isPassedFrontValidate) {
      setCheckedValidation(waitingAnchorDealers.map(() => true));

      return;
    }

    const registrationRequestSuccessModal = (requestResult: WaitingAnchorDealerVOModel[]) => {
      modal.show(<AnchorDealerRegistrationResultModal requestResult={requestResult} isFinancier />, {
        modalSize: ModalSize.XL,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => history.push(ROUTES_FI.MANAGE_DEALER.REGISTRATION_LIST),
      });
    };

    const requestDealerRegistration = async () => {
      const data: FinancierWaitingAnchorDealerRequest = {
        anchorId: locationState.enterpriseId,
        waitingAnchorDealers: getValues().waitingAnchorDealers,
      };

      try {
        const response = await requestFinancierWaitingAnchorDealerRegister(data);

        registrationRequestSuccessModal(response);
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(<AnchorDealerRegistrationConfirmModal waitingAnchorDealers={waitingAnchorDealers} isFinancier />, {
      modalSize: ModalSize.XL,
      modalType: ModalType.CONFIRM,
      confirmBtnCb: () => requestDealerRegistration(),
    });
  };

  return {
    formMethods,
    useFieldArrayMethods,
    appendRow,
    removeRow,
    handleCheckAll,
    handleCheckChange,
    checkedRows,
    validateDuplicateTaxCode,
    handleClickCancelButton,
    handleClickRegisterButton,
    checkedValidation,
  };
};

export default useFinancierAnchorDealerRegistrationStep2Page;
