import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import useValidation from 'utils/validation/useValidation';

interface AdditionalColumnNameListType {
  columns: string[];
  visibleAppendColumnButton: () => void;
  invisibleAppendColumnButton: () => void;
  removeColumn: (index: number) => void;
}

const AdditionalColumnNameListCopy = ({
  columns,
  visibleAppendColumnButton,
  invisibleAppendColumnButton,
  removeColumn,
}: AdditionalColumnNameListType) => {
  const { register, errors } = useFormContext();
  const { t } = useTranslation(['format']);

  const { getValidationClassName } = useValidation({
    errorFields: errors.additionalColumnNameList,
  });

  return (
    <>
      {columns.map((_, additionalColumnNameIndex, additionalColumnNameList) => {
        const lastAdditionalColumnName = additionalColumnNameIndex + 1 === additionalColumnNameList.length;
        const emptyFunc = () => {};

        return (
          <th
            key={additionalColumnNameIndex}
            onMouseEnter={lastAdditionalColumnName ? visibleAppendColumnButton : emptyFunc}
            onMouseLeave={lastAdditionalColumnName ? invisibleAppendColumnButton : emptyFunc}
            className={getValidationClassName('name', 'th', additionalColumnNameIndex)}
          >
            <div className="flex-center">
              <input
                className={`column-input ${getValidationClassName('name', 'input', additionalColumnNameIndex)}`}
                name={`additionalColumnNameList[${additionalColumnNameIndex}].name`}
                type="text"
                ref={register({
                  required: true,
                })}
                placeholder={t('text:Enter_Column_Name')}
              />
              <Button
                onClick={() => removeColumn(additionalColumnNameIndex)}
                style={{ width: '20px', height: '20px' }}
                className="flex-center ms-1"
                color={ButtonColorEnum.SECONDARY}
                variant={ButtonVariantEnum.OUTLINED}
                tabIndex={-1}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </div>
          </th>
        );
      })}
    </>
  );
};

export default React.memo(AdditionalColumnNameListCopy);
