import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { UserEmailSettingGroupedMap } from 'components/email-notification-setting/types';
import type { UpdateUserEmailSettingListType } from 'components/email-notification-setting/types';
import { classifyUserEmailSettingAlertOptions } from 'components/email-notification-setting/utils';
import { AUTHORITY_TYPE } from 'enums';
import { ResourceNotFoundExceptionCode } from 'enums/exception';
import useMounted from 'hooks/useMounted';
import { HttpError } from 'utils/error/HttpError';
import {
  requestAnchorEmailSettingList,
  requestAnchorEmailSettingListUpdate,
} from 'utils/http/api/anchor/user-email-setting';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

function useAnchorMyAccountEmailNotificationSettingController() {
  const { financierId } = useParams() as any;
  const mounted = useMounted();
  const modal = useModal();
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const { t } = useTranslation();

  const [selectedCommonAlertSetting, setSelectedCommonAlertSetting] = useState<UserEmailSettingGroupedMap | null>();
  const [selectedVendorAlertSetting, setSelectedVendorAlertSetting] = useState<UserEmailSettingGroupedMap | null>();
  const [selectedDealerAlertSetting, setSelectedDealerAlertSetting] = useState<UserEmailSettingGroupedMap | null>();
  const [factoringEnable, setFactoringEnable] = useState<boolean>(false);

  useEffect(() => {
    if (mounted) {
      fetchEmailSetting();
    }
  }, [mounted]);

  const fetchEmailSetting = async (): Promise<void> => {
    try {
      const [alertSettingResponse, financierCommonSettingResponse] = await Promise.all([
        requestAnchorEmailSettingList({
          financierId,
        }),
        requestFinancierSettingData(financierId),
      ]);

      const { factoringEnable } = financierCommonSettingResponse;

      const { commonFormList, vendorFormList, dealerFormList } = classifyUserEmailSettingAlertOptions(
        alertSettingResponse,
        factoringEnable,
      );

      setFactoringEnable(factoringEnable);
      setSelectedCommonAlertSetting(commonFormList);
      setSelectedVendorAlertSetting(vendorFormList);
      setSelectedDealerAlertSetting(dealerFormList);
    } catch (error) {
      if (error instanceof HttpError && error.code === ResourceNotFoundExceptionCode.ANCHOR_AGREEMENT_NOT_FOUND) {
        modal.show(
          <h6>
            {t('text:Email_notification_settings_cannot_be_set_as_there_is_no_agreement_registered_by_the_financier')}
            <br />
            {t('text:Returns_to_the_previous_page')}
          </h6>,
          {
            closeBtnCb: () => history.back(),
          },
        );
      } else modal.show(error);
    }
  };

  const updateEmailSettingHandler = async (updateUserEmailSettingList: UpdateUserEmailSettingListType[]) => {
    try {
      const updatedAlertSettingResponse = await requestAnchorEmailSettingListUpdate({
        financierId,
        updateUserEmailSettingList,
      });
      const { commonFormList, vendorFormList, dealerFormList } = classifyUserEmailSettingAlertOptions(
        updatedAlertSettingResponse,
        factoringEnable!,
      );

      setSelectedCommonAlertSetting(commonFormList);
      setSelectedVendorAlertSetting(vendorFormList);
      setSelectedDealerAlertSetting(dealerFormList);
    } catch (error) {
      modal.show(error);
    }
  };

  return {
    isAdmin,
    factoringEnable,
    selectedCommonAlertSetting,
    selectedVendorAlertSetting,
    selectedDealerAlertSetting,
    updateEmailSettingHandler,
  };
}

export default useAnchorMyAccountEmailNotificationSettingController;
