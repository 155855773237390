import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import QuestionButton from 'components/stateless/QuestionButton';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { COLLATERAL_TYPE, EARLY_REPAYMENT_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { requestDealerEarlyRepaymentDetail } from 'utils/http/api/dealer/early-repayment-requests';
import { requestDealerLoanDetail } from 'utils/http/api/dealer/loans';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

function DealerEarlyRepaymentDetail() {
  const mounted = useMounted();
  const history = useHistory();
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { earlyRepaymentRequestId } = useParams() as any;
  const [earlyRepaymentRequestData, setEarlyRepaymentRequestData] = useState<EarlyRepaymentRequestVOModel>(
    {} as EarlyRepaymentRequestVOModel,
  );
  const [loanDetail, setLoanDetail] = useState<LoanDetailVOModel>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const earlyRepaymentRequestResponse = await requestDealerEarlyRepaymentDetail(earlyRepaymentRequestId);
      const loanDetailResponse = await requestDealerLoanDetail(earlyRepaymentRequestResponse.loanId);

      ReactDOM.unstable_batchedUpdates(() => {
        setEarlyRepaymentRequestData(earlyRepaymentRequestResponse);
        setLoanDetail(loanDetailResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getStatusText = (earlyRepaymentRequestStatus: EARLY_REPAYMENT_REQUEST_STATUS | string): string => {
    switch (earlyRepaymentRequestStatus) {
      case EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED:
        return t('text:Requested');
      case EARLY_REPAYMENT_REQUEST_STATUS.COMPLETED:
        return t('text:Completed');
      case EARLY_REPAYMENT_REQUEST_STATUS.EXPIRED:
        return t('text:Rejected');
      default:
        return '';
    }
  };

  const onClickEarlyRepaymentRequest = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EARLY_REPAYMENT_REQUEST_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="EARLY_REPAYMENT_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderAgreementType = (collateralType: COLLATERAL_TYPE | undefined) => {
    if (collateralType === COLLATERAL_TYPE.AR) {
      return t('text:Vendor_Finance');
    } else if (collateralType === COLLATERAL_TYPE.INVOICE) {
      return t('text:Dealer_Finance');
    } else {
      return null;
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Early_Repayment_Request_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Request_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <div className="col-6">
                <div className="information-form__label">{t('text:Requested_Date')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(
                    earlyRepaymentRequestData.createdDateTime,
                    t('format:datetime', { value: earlyRepaymentRequestData.createdDateTime, key: 'datetime' }),
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="information-form__label">
                  {t('text:Status')}
                  <QuestionButton onClick={onClickEarlyRepaymentRequest} />
                </div>
                <div className="information-form__input border-none status-container">
                  <div
                    className={getStatusBadgeClass(
                      'EARLY_REPAYMENT_REQUEST_STATUS',
                      earlyRepaymentRequestData.earlyRepaymentRequestStatus,
                    )}
                  >
                    {getStatusText(earlyRepaymentRequestData.earlyRepaymentRequestStatus)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="information-form__label">{t('text:Requested_Repayment_Date')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(
                    earlyRepaymentRequestData.intendedRepaymentDate,
                    t('format:date', { value: earlyRepaymentRequestData.intendedRepaymentDate, key: 'date' }),
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="information-form__label">{t('text:Original_Repayment_Date')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(
                    earlyRepaymentRequestData.repaymentDate,
                    t('format:date', { value: earlyRepaymentRequestData.repaymentDate, key: 'date' }),
                  )}
                </div>
              </div>
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Financing_Information')}>
            <div className="flex-end align-items-center me-3">
              <span className="me-1">{t('text:Go_to_Financing_Details')}</span>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                className="flex-column-center"
                onClick={() => {
                  history.push(ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(loanDetail?.loanId!), {
                    successInvoiceId: loanDetail?.invoiceId,
                    successArId: loanDetail?.successArId,
                    financierId: loanDetail?.financierId,
                    collateralType: loanDetail?.collateralType,
                  });
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </FormSubtitle>
          <FormContents>
            <div className="row">
              <div className="col-3">
                <div className="information-form__label">{t('text:Program_Type')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(renderAgreementType(loanDetail?.collateralType))}
                </div>
              </div>
              <div className="col-3">
                <div className="information-form__label">{t('text:Financier_Name')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(earlyRepaymentRequestData.financierName)}
                </div>
              </div>
              <div className="col-3">
                <div className="information-form__label">{t('text:Financier_Financing_ID')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(earlyRepaymentRequestData.financierLoanId)}
                </div>
              </div>
              <div className="col-3">
                <div className="information-form__label">{t('text:Platform_Financing_ID')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(earlyRepaymentRequestData.loanId)}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="information-form__label">{t('text:Financing_Amount')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(
                    earlyRepaymentRequestData.principalAmount,
                    t('format:number', { value: earlyRepaymentRequestData.principalAmount }),
                  )}
                </div>
              </div>
              <div className="col-3">
                <div className="information-form__label">{t('text:Disbursed_Date')}</div>
                <div className="information-form__input border-none">
                  {tableValueManage(
                    earlyRepaymentRequestData.disbursedDate,
                    t('format:date', { value: earlyRepaymentRequestData.disbursedDate, key: 'datetime' }),
                  )}
                </div>
              </div>
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default DealerEarlyRepaymentDetail;
