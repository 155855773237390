import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormErrorMessage } from 'components/stateless/CommonForm/FormErrorMessage';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import Icon from 'components/stateless/Icons';
import { ROUTES_CM } from 'constants/routes/common';
import { InvalidInputValueExceptionCode } from 'enums/exception';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import { HttpError } from 'utils/error/HttpError';
import { formErrorHandler } from 'utils/error/manager';
import { requestAnEmailFindTypeAndCode } from 'utils/http/api/anonymous/email';
import type { AnEmailFindTypeAndCodeRequest } from 'utils/http/api/anonymous/email/request';
import { requestUserFindPassword } from 'utils/http/api/anonymous/users';
import type { FindPasswordRequest } from 'utils/http/api/anonymous/users/requests';
import useModal from 'utils/modal/useModal';
import { homePathByRole } from 'utils/route';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function FindPasswordEmailSent() {
  const { t } = useTranslation();
  const history = useHistory();
  const mounted = useMounted();
  const modal = useModal();
  const [locationState] = useLocationState<FindPasswordRequest>(['email', 'loginId']);
  const [isAccessCodeError, setAccessCodeError] = useState<boolean>(false);

  const { register, getValues, setValue, errors, setError, clearErrors } = useForm<AnEmailFindTypeAndCodeRequest>();

  useEffect(() => {
    if (mounted) {
      if (Object.keys(locationState).length !== 2) {
        const signInModel = getSignIn();
        signInModel
          ? history.push(homePathByRole(signInModel.authorities[0]?.authority))
          : history.push(ROUTES_CM.FIND_PASSWORD);
      }
    }

    return () => {};
  }, [history, locationState, mounted]);

  const onClickResendEmail = async (e: any) => {
    e.preventDefault();

    try {
      await requestUserFindPassword(locationState);
      modal.show(t('text:The_password_reset_email_has_been_sent_again'), {
        closeBtnCb: () => {
          setAccessCodeError(false);
          setValue('accessCode', '');
          clearErrors();
        },
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickConfirm = async (e: any) => {
    e.preventDefault();

    const data = getValues();
    data.email = locationState?.email;

    setAccessCodeError(false);
    try {
      requestDTOParser(data);
      const response = await requestAnEmailFindTypeAndCode(data);
      if (response) history.push(`${ROUTES_CM.RESET_PASSWORD}?code=${response.code}`);
    } catch (e: any) {
      modal.show(e);

      formErrorHandler<AnEmailFindTypeAndCodeRequest>(e, setError, clearErrors);

      if (e instanceof HttpError && e.code !== InvalidInputValueExceptionCode.INVALID_INPUT_VALUE)
        setAccessCodeError(true);
    }
  };

  return (
    <>
      <PublicHeaderBar />
      <div className="align-middle row">
        <div className="email-sent-form">
          <div className="icon-form">
            <Icon.PaperPlane />
          </div>
          <h2 className="common-content__title">{t('text:Password_Reset_Email_Sent')}</h2>
          <div className="border-bottom-light-gray mb-0" />
          <div className="common-content__message">
            {t('text:We_ve_sent_a_new_password_reset_message_to_the_following_email_address')}
            <br />
            <span className="bold-font">{locationState?.email}</span>
            <br />
            {t(
              'text:Click_on_the_link_in_the_email_to_reset_your_password,_or_enter_the_password_reset_code_in_the_email_to_the_section_below',
            )}
          </div>
          <form>
            <div className="login-form__input">
              <label className="information-form__label">{t('text:Password_Reset_Code')}</label>
              <input
                className={`information-form__input ${
                  (errors.accessCode || isAccessCodeError) && 'error-input-border'
                }`}
                type="text"
                name="accessCode"
                placeholder={t('text:Please_type_here')}
                ref={register}
                autoComplete="off"
              />
              <FormErrorMessage error={errors.accessCode} />
            </div>
            {isAccessCodeError && (
              <div className="text-brick-red m-1">
                {t('text:The_reset_code_is_incorrect')} {t('text:Please_try_again')}
              </div>
            )}
            <div className="py-3" />
            {isAccessCodeError ? (
              <div className="d-flex">
                <Button
                  className="me-2 w-50"
                  variant={ButtonVariantEnum.OUTLINED}
                  size={ButtonSizeEnum.LG}
                  onClick={onClickResendEmail}
                >
                  {t('text:Resend_Email')}
                </Button>
                <Button className=" w-50" size={ButtonSizeEnum.LG} onClick={onClickConfirm}>
                  {t('text:Confirm')}
                </Button>
              </div>
            ) : (
              <Button className=" w-100" size={ButtonSizeEnum.LG} onClick={onClickConfirm}>
                {t('text:Confirm')}
              </Button>
            )}
          </form>

          <Link to="/">
            <div className="sign-in-link mt-4">{t('text:Back_to_Sign_in')}</div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default FindPasswordEmailSent;
