import { isNil } from 'lodash-es';

export const isNilOrEmptyString = (value: any) => {
  return isNil(value) || value === '';
};

export const convertToUppercase = (value: any) => {
  if (!value || typeof value !== 'string') return value;

  return value.toUpperCase();
};
