import type Pageable from 'models/Pageable';
import type { UsageSummaryVO, UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SearchSyBillingDTO } from './requests';

export const requestSyBillingList = async (
  pageNumber: number,
  rowCount: number,
  searchValue: SearchSyBillingDTO,
): Promise<Pageable<UsageSummaryVOModel[]>> => {
  return await http.get<Pageable<UsageSummaryVO[]>>({
    url: API_SY.BILLING.BILLING_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
};

export const requestSyBillingTotalAmount = async (
  pageNumber: number,
  rowCount: number,
  searchValue: SearchSyBillingDTO,
): Promise<UsageSummaryVOModel | null> => {
  return await http.get<UsageSummaryVO>({
    url: API_SY.BILLING.BILLING_TOTAL_AMOUNT,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
};
