import { ANCHOR_AGREEMENT_STATUS, COLLATERAL_TYPE, CURRENCY_TYPE, REPORT_TYPE, stringToEnum } from 'enums';

export interface AnchorReportReceiverVO {
  anchorReportReceiverId: number;
  name: string;
  financierName: string;
  email: string;
  anchorClientName: string;
  anchorAgreementContractNo: string;
  currencyType: string;
  collateralType: string;
  reportType: string;
  activated: boolean;
  affiliation: string;
  anchorClientCode: string;
  anchorAgreementStatus: string;
  agreementStartDate: string;
  agreementExpiryDate: string;
}

export interface AnchorReportReceiverVOModel extends AnchorReportReceiverVO {
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  reportType: REPORT_TYPE;
  anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS;
}

export function formattingToAnchorReportReceiverVOModel(data: AnchorReportReceiverVO): AnchorReportReceiverVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    reportType: stringToEnum(REPORT_TYPE, data.reportType),
    anchorAgreementStatus: stringToEnum(ANCHOR_AGREEMENT_STATUS, data.anchorAgreementStatus),
  };
}
