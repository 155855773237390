import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BASIS_INTEREST_TYPE, type COLLATERAL_TYPE } from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import { requestFinancierDealerAgreementDetailByCodeInterface } from 'utils/http/api/financier/dealer-agreements';
import { getHttpErrorExceptionMessage } from 'utils/text';

const useSearchInterfaceByContactNoModalController = (collateralType: COLLATERAL_TYPE) => {
  const { t } = useTranslation(['format']);

  const [contractNo, setContractNo] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [searchedInterfaceData, setSearchedInterfaceData] = useState<DealerAgreementDetailVOModel>(
    {} as DealerAgreementDetailVOModel,
  );
  const { basisInterestType, basisInterestRate, basisInterestTerm } = searchedInterfaceData;

  const isEmptyContractNo = !contractNo || contractNo.trim() === '';

  const contractNoLengthText = !isEmptyContractNo ? `${t('text:Current_text_length_is_')} ${contractNo.length}` : '';

  const basisInterestTypeText = `${basisInterestType} +${
    basisInterestType === BASIS_INTEREST_TYPE.FIXED ? basisInterestRate : basisInterestTerm || '-'
  }`;

  const handleContactNoInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setContractNo(e.target.value);
  };

  const handleSearchButtonClick = async () => {
    if (isEmptyContractNo) return setErrorMessage(t('text:Please_enter_the_agreement_number'));

    setErrorMessage('');

    try {
      const response = await requestFinancierDealerAgreementDetailByCodeInterface(contractNo, collateralType);
      setSearchedInterfaceData(response);
    } catch (e: any) {
      setErrorMessage(getHttpErrorExceptionMessage(e));
      setSearchedInterfaceData({} as DealerAgreementDetailVOModel);
    }
  };

  return {
    searchedInterfaceData,
    errorMessage,
    handleSearchButtonClick,
    handleContactNoInputChange,
    contractNo,
    contractNoLengthText,
    basisInterestTypeText,
  };
};

export default useSearchInterfaceByContactNoModalController;
