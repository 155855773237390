import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { AUTHORIZER_STATUS } from 'enums';
import { convertToDealerAgreementCommonVOModel } from 'models/convertor/dealerAgreement';
import useAgreementViewModel from 'pages/financier/manage-partner/agreement/models/agreement/useAgreementViewModel';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierDealerAuthorizerInviteUrl } from 'utils/http/api/financier/dealer-agreements';
import { requestInviteAuthorizer } from 'utils/http/api/financier/users';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const useRegistrationDetailPartnerCompanyController = () => {
  const { show: showModal } = useModal();
  const { t } = useTranslation();

  const [inviteUrl, setInviteUrl] = useState<string | null>('');

  const { agreement, fetchAgreementDetail, updateAllAgreement } = useAgreementViewModel();

  const {
    methods: { reset },
    isEditable,
  } = useFormContext();

  const isSendEmailVisible = agreement.authorizerStatus !== AUTHORIZER_STATUS.ACTIVATED;

  const isUserAccountStatusVisible = !isEditable;

  const isClipboardWriteButtonVisible = !isEditable && agreement.authorizerStatus === AUTHORIZER_STATUS.MAIL_SENT;

  const renderAuthorizerStatus = (authorizerStatus?: AUTHORIZER_STATUS) => {
    switch (authorizerStatus) {
      case AUTHORIZER_STATUS.ACTIVATED:
        return (
          <span className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.ACTIVATED)}>
            {t('text:Active')}
          </span>
        );
      case AUTHORIZER_STATUS.MAIL_SENT:
        return (
          <span className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.MAIL_SENT)}>
            {t('text:Invited')}
          </span>
        );
      case AUTHORIZER_STATUS.NONE:
        return (
          <span className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.NONE)}>{t('text:Input')}</span>
        );
      default:
        return <span>{authorizerStatus}</span>;
    }
  };

  const showInviteLinkMissingModal = () => {
    showModal(t('text:Click_on_the_Send_button_before_copying_the_invitation_email_link'));
  };

  const handleClipboardButtonClick = async () => {
    if (inviteUrl === null) {
      showInviteLinkMissingModal();

      return null;
    }

    try {
      const updatedInviteUrl = await requestFinancierDealerAuthorizerInviteUrl(agreement.dealerAgreementId);
      if (updatedInviteUrl === null) showInviteLinkMissingModal();
      setInviteUrl(updatedInviteUrl);

      return updatedInviteUrl;
    } catch (e) {
      showModal(e);

      return null;
    }
  };

  const handleInviteAuthorizerButtonClick = () => {
    showModal(<h6>{t('text:Would_you_like_to_send_an_invitation_email')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestInviteAuthorizer({
            agreementId: agreement.dealerAgreementId,
          });

          showModal(<h6>{t('text:An_invitation_Email_is_successfully_sent')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: async () => {
              const dealerAgreementDetail = await fetchAgreementDetail(agreement.dealerAgreementId);
              if (dealerAgreementDetail) {
                reset(convertToDealerAgreementCommonVOModel(dealerAgreementDetail));
                updateAllAgreement(dealerAgreementDetail);
              }
            },
          });

          setInviteUrl('');
        } catch (e: any) {
          showModal(e);
        }
      },
    });
  };

  const handleUserAccountStatusClick = () => {
    showModal(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(AUTHORIZER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="AUTHORIZER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  return {
    agreement,
    inviteUrl,
    isUserAccountStatusVisible,
    isSendEmailVisible,
    isClipboardWriteButtonVisible,
    renderAuthorizerStatus,
    handleClipboardButtonClick,
    handleInviteAuthorizerButtonClick,
    handleUserAccountStatusClick,
  };
};

export default useRegistrationDetailPartnerCompanyController;
