import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ExpectedFinancingRepaymentModal from 'components/stateless/Modal/common/loan/ExpectedFinancingRepaymentModal';
import { COLLATERAL_TYPE } from 'enums';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestAcAnchorLoanDetail } from 'utils/http/api/anchor/anchor-loans';
import { requestAnchorFinancierCalendar } from 'utils/http/api/anchor/financier-calendar';
import { requestAnchorSuccessArsDetail } from 'utils/http/api/anchor/success-ars';
import { requestAnchorSuccessInvoiceDetail } from 'utils/http/api/anchor/success-invoices';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

export function useAcAnchorLoanDetailState() {
  const modal = useModal();
  const { anchorLoanId } = useParams<any>();

  const [dataState, setDataState] = useState({
    loanDetail: {} as AnchorLoanDetailVOModel,
    invoiceDetail: {} as SuccessInvoiceDetailVOModel,
    arDetail: {} as SuccessArDetailVOModel,
  });

  const fetchAll = async () => {
    try {
      const fetchedLoanDetail = await requestAcAnchorLoanDetail(anchorLoanId);

      setDataState(prevState => ({
        ...prevState,
        loanDetail: fetchedLoanDetail,
      }));

      if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.INVOICE) {
        const successInvoiceInformationResponse = await requestAnchorSuccessInvoiceDetail(
          fetchedLoanDetail.successInvoiceId,
        );

        setDataState(prevState => ({
          ...prevState,
          invoiceDetail: successInvoiceInformationResponse,
        }));
      }

      if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.AR) {
        const successArInformationResponse = await requestAnchorSuccessArsDetail(fetchedLoanDetail.successArId);

        setDataState(prevState => ({
          ...prevState,
          arDetail: successArInformationResponse,
        }));
      }
    } catch (e: any) {
      modal.show(e);
    }
  };

  const onClickViewExpectedRepaymentAmount = async (e: any) => {
    // phase 2
  };

  return {
    state: dataState,
    fetchAll,
    onClickViewExpectedRepaymentAmount,
  };
}
