import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';
import { isNull } from 'lodash-es';

import Form, { SizeType } from 'components/stateless/Form';
import { FormErrorMessage } from 'components/stateless/Form/ErrorMessage';
import { RadioGroup } from 'components/stateless/Form/RadioComponents';
import Radio from 'components/stateless/Form/RadioComponents/Radio';
import Select from 'components/stateless/Form/Select/Select';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';
import { LOAN_TERM_TYPE } from 'enums';

import useFinancingDurationController from './useFinancingDurationController';
import '../../sections.scss';

interface FinancingDurationPropsType {
  isVendorFactoring?: boolean;
}

const FinancingDuration = ({ isVendorFactoring }: FinancingDurationPropsType) => {
  const { t } = useTranslation();

  const {
    register,
    errors,
    isEditable,
    checkedLoanTermType,
    isMaturityExtension,
    selectedLoanTermType,
    loanTermTypeSelectOptions,
    isFixedDurationEditable,
    getReadOnlyValue,
    getIsMaxExtensibleLoanCountAllowed,
    handleCheckedLoanTermTypeChange,
    handleIsMaturityExtensionChange,
    handleSelectedLoanTermTypeChange,
  } = useFinancingDurationController(isVendorFactoring);

  return (
    <>
      <SubTitle>{t('text:FINANCING_DURATION')}</SubTitle>
      <Content>
        <input type="hidden" name="loanTermType" ref={register} />
        <Row>
          <Form.Control name="loanTermType" required>
            <Cell>
              <Form.Label position="top">{t('text:Is_the_financing_duration_flexible_or_fixed?')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_the_financing_duration_is_fixed_or_it_can_be_flexibly_adjusted_during_financing_application',
                )}
              </Form.Description>
            </Cell>
            <RadioGroup
              name="checkedLoanTermType"
              stateValue={checkedLoanTermType}
              onChange={handleCheckedLoanTermTypeChange}
              isEditable={isEditable}
              fetchedReadOnly={getReadOnlyValue('loanTermType')}
              error={errors?.loanTermType}
            >
              <Radio
                label={t('text:Flexible')}
                value={LOAN_TERM_TYPE.RANGE}
                render={(isParentReadOnly, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Form.Control name="minimumLoanRange" required>
                          <Form.Label>{t('text:Minimum_Days')}</Form.Label>
                          <Cell>
                            <Form.NumberInput
                              className={clsx({ 'error-input-border': errors?.minimumLoanRange })}
                              numberType="integer"
                              fieldSize={SizeType.SM}
                              textAlign="text-right"
                              readOnly={isParentRadioUnChecked}
                              parentReadOnly={isParentReadOnly}
                              showError={false}
                            />
                            {errors?.minimumLoanRange && <FormErrorMessage error={errors.minimumLoanRange} />}
                          </Cell>
                        </Form.Control>
                      </Row>
                      <Row>
                        <Form.Control name="maximumLoanRange" required>
                          <Form.Label>{t('text:Maximum_Days')}</Form.Label>
                          <Cell>
                            <Form.NumberInput
                              className={clsx({ 'error-input-border': errors?.maximumLoanRange })}
                              numberType="integer"
                              fieldSize={SizeType.SM}
                              textAlign="text-right"
                              readOnly={isParentRadioUnChecked}
                              parentReadOnly={isParentReadOnly}
                              showError={false}
                            />
                            {errors?.maximumLoanRange && <FormErrorMessage error={errors.maximumLoanRange} />}
                          </Cell>
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
              <Radio
                disabled={isVendorFactoring}
                label={t('text:Fixed')}
                value={selectedLoanTermType}
                render={(isParentReadOnly, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Cell required={isFixedDurationEditable}>
                          <Form.Label>{t('text:Fixed_Duration')}</Form.Label>
                        </Cell>
                        <Cell>
                          <Cell className="half-field">
                            <Form.NumberInput
                              className={clsx({ 'error-input-border': errors?.loanTermUnit })}
                              numberType="integer"
                              name="loanTermUnit"
                              fieldSize={SizeType.SM}
                              textAlign="text-right"
                              readOnly={isParentRadioUnChecked}
                              parentReadOnly={isParentReadOnly}
                              showError={false}
                            />
                            {isFixedDurationEditable && <span className="vertical-divider" />}
                            <Select
                              name="loanTermType"
                              // selectOptions={getSelectOptions<LOAN_TERM_TYPE>('LOAN_TERM_TYPE')} // TODO :: 기존 약정 삭제 시 getSelectOptions 내에서 용어 수정
                              selectOptions={loanTermTypeSelectOptions}
                              value={selectedLoanTermType}
                              onChange={handleSelectedLoanTermTypeChange}
                              isEditable={isEditable}
                              fetchedReadOnly={getReadOnlyValue('loanTermType')}
                              readOnly={isParentRadioUnChecked}
                              parentReadOnly={isParentReadOnly}
                            />
                          </Cell>
                          {errors?.loanTermUnit && <FormErrorMessage error={errors.loanTermUnit} />}
                        </Cell>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
            </RadioGroup>
          </Form.Control>
        </Row>
        {!isVendorFactoring && (
          <>
            <Row>
              <Form.Control required>
                <Cell>
                  <Form.Label position="top" name="maxExtensibleLoanCount">
                    {t('text:Maturity_Extension')}
                  </Form.Label>
                  <Form.Description>
                    {t(
                      'text:Select_whether_the_Partner_can_extend_their_financing_maturity_beyond_the_original_repayment_date',
                    )}
                  </Form.Description>
                </Cell>
                <RadioGroup
                  name="isMaturityExtension"
                  stateValue={isMaturityExtension}
                  onChange={handleIsMaturityExtensionChange}
                  isEditable={isEditable}
                  fetchedReadOnly={getReadOnlyValue('maxExtensibleLoanCount')}
                  error={errors?.maxExtensibleLoanCount}
                  showError={isNull(isMaturityExtension)}
                >
                  <Radio
                    label={t('text:Allowed')}
                    value="true"
                    render={(isParentReadOnly, isParentRadioUnChecked) => (
                      <Border>
                        <Content isInnerStyle>
                          <Row>
                            <Form.Control name="maxExtensibleLoanCount" required>
                              <Form.Label>{t('text:Maximum_Extension_Times')}</Form.Label>
                              <Form.NumberInput
                                rightUnit={t('text:Times')}
                                fieldSize={SizeType.SM}
                                textAlign="text-right"
                                numberType="integer"
                                useIsAllowed
                                isAllowed={getIsMaxExtensibleLoanCountAllowed}
                                readOnly={isParentRadioUnChecked}
                                parentReadOnly={isParentReadOnly}
                                showError={isMaturityExtension === 'true'}
                              />
                            </Form.Control>
                          </Row>
                        </Content>
                      </Border>
                    )}
                  />
                  <Radio label={t('text:Not_Allowed')} value="false" />
                </RadioGroup>
              </Form.Control>
            </Row>
            <Row>
              <Form.Control name="earlyRepaymentAllowable" required>
                <Cell>
                  <Form.Label position="top">{t('text:Early_Repayment')}</Form.Label>
                  <Form.Description>
                    {t(
                      'text:Select_whether_the_Partner_can_repay_their_financing_ahead_of_the_original_repayment_date',
                    )}
                  </Form.Description>
                </Cell>
                <Form.Radio>
                  <Form.Option label={t('text:Allowed')} value="true" />
                  <Form.Option label={t('text:Not_Allowed')} value="false" />
                </Form.Radio>
              </Form.Control>
            </Row>
          </>
        )}
        <Row>
          <Form.Control name="repaymentDateOverAllowable" required>
            <Cell>
              <Form.Label position="top">{t('text:Financing_Repayment_Date')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_the_Partner_can_apply_for_financing_that_will_be_repaid_after_the_master_agreement_expires',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio>
              <Form.Option label={t('text:Can_exceed_agreement_expiration_date')} value="true" />
              <Form.Option label={t('text:Must_be_before_agreement_expiration')} value="false" />
            </Form.Radio>
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default FinancingDuration;
