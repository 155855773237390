import type Pageable from 'models/Pageable';
import type {
  AnchorPartnerAccountDetailVO,
  AnchorPartnerAccountDetailVOModel,
} from 'models/vo/AnchorPartnerAccountDetailVO';
import { formattingToAnchorPartnerAccountDetailVOModel } from 'models/vo/AnchorPartnerAccountDetailVO';
import type { AnchorPartnerAccountVO, AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import { formattingToAnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  AnchorPartnerAccountAssignBranchRequest,
  AnchorPartnerAccountDeclineRequest,
  AnchorPartnerAccountListRequest,
} from './requests';

export async function requestFiAnchorPartnerAccountList(
  pageNumber: number,
  rowCount: number,
  data: AnchorPartnerAccountListRequest,
): Promise<Pageable<AnchorPartnerAccountVOModel[]>> {
  const response = await http.get<Pageable<AnchorPartnerAccountVO[]>>({
    url: API_FI.ANCHOR_PARTNER_ACCOUNTS.PARTNER_BANK_ACCOUNT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorPartnerAccountVOModel(data)),
  };
}

export async function requestFiAnchorPartnerAccountData(
  anchorPartnerAccountId: number,
): Promise<AnchorPartnerAccountDetailVOModel> {
  const response = await http.get<AnchorPartnerAccountDetailVO>({
    url: API_FI.ANCHOR_PARTNER_ACCOUNTS.PARTNER_BANK_ACCOUNT_DETAIL(anchorPartnerAccountId),
  });

  return formattingToAnchorPartnerAccountDetailVOModel(response);
}

export async function requestFiAnchorPartnerAccountAssignBranch(
  data: AnchorPartnerAccountAssignBranchRequest,
): Promise<void> {
  const response = await http.post<void>({
    url: API_FI.ANCHOR_PARTNER_ACCOUNTS.ASSIGN_BRANCH,
    data,
  });

  return response;
}

export async function requestFiAnchorPartnerAccountReinstate(
  anchorPartnerAccountId: number,
): Promise<AnchorPartnerAccountDetailVOModel> {
  const response = await http.put<AnchorPartnerAccountDetailVO>({
    url: API_FI.ANCHOR_PARTNER_ACCOUNTS.REINSTATE_PARTNER_ACCOUNT(anchorPartnerAccountId),
  });

  return formattingToAnchorPartnerAccountDetailVOModel(response);
}

export async function requestFiAnchorPartnerAccountDecline(
  anchorPartnerAccountId: number,
  data: AnchorPartnerAccountDeclineRequest,
): Promise<AnchorPartnerAccountDetailVOModel> {
  const response = await http.put<AnchorPartnerAccountDetailVO>({
    url: API_FI.ANCHOR_PARTNER_ACCOUNTS.DECLINE_PARTNER_ACCOUNT(anchorPartnerAccountId),
    data,
  });

  return formattingToAnchorPartnerAccountDetailVOModel(response);
}
