import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { ANCHOR_AGREEMENT_STATUS, COLLATERAL_TYPE, CURRENCY_TYPE, REPORT_TYPE } from 'enums';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorReportSettingVOModel } from 'models/vo/AnchorReportSettingVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFiAnchorReportSettingList } from 'utils/http/api/financier/anchor-report-settings';
import type { FiAnchorReportSettingListRequest } from 'utils/http/api/financier/anchor-report-settings/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const getConstants = () => {
  const { t } = useTranslation(['format']);
  const FI_ANCHOR_REPORT_LIST_QS_KEY = 'fi-anchor-report-list';

  const REPORT_SEARCH_RESULT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Master_Agreement_Number'),
      colWidths: 140,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Agreement_Status'),
      colWidths: 100,
    },
    {
      headerText: t('text:Report_Type'),
      colWidths: 80,
    },
    {
      headerText: t('text:Report_Status'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return {
    t,
    REPORT_SEARCH_RESULT_TABLE_HEADERS,
    FI_ANCHOR_REPORT_LIST_QS_KEY,
  };
};

function FinancierAnchorReportList(): JSX.Element {
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t, REPORT_SEARCH_RESULT_TABLE_HEADERS, FI_ANCHOR_REPORT_LIST_QS_KEY } = getConstants();

  const [anchorReportPageData, setAnchorReportPageData] = useState<Pageable<AnchorReportSettingVOModel[]>>();
  const { pageable: anchorReportPageable, setPageable: setAnchorReportPageable } =
    usePageable(FI_ANCHOR_REPORT_LIST_QS_KEY);

  const {
    register: anchorReportSearchFormRegister,
    getValues: getAnchorReportSearchFormValues,
    setValue: setAnchorReportSearchFormValue,
    reset: resetAnchorReportSearchForm,
    handleSubmit: handleAnchorReportSearchFormSubmit,
  } = useForm<FiAnchorReportSettingListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FiAnchorReportSettingListRequest>(
        setAnchorReportSearchFormValue,
        getParsedSearchParams(FI_ANCHOR_REPORT_LIST_QS_KEY).formSearchData,
      );
      fetchFiAnchorReportSettingList(
        anchorReportPageable.currentPage,
        anchorReportPageable.sizePerPage,
        getAnchorReportSearchFormValues(),
      );
    }

    return () => {};
  }, [mounted]);

  const fetchFiAnchorReportSettingList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
    searchedData: FiAnchorReportSettingListRequest,
  ): Promise<void> => {
    try {
      const anchorReportPageResponse = await requestFiAnchorReportSettingList(
        selectedPageNumber,
        selectedRowCount,
        searchedData,
      );
      updateSearchParams(
        {
          ...searchedData,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowCount,
        },
        FI_ANCHOR_REPORT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorReportPageData(anchorReportPageResponse);
        setAnchorReportPageable(anchorReportPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRegister = (e: any): void => {
    e.preventDefault();

    history.push(ROUTES_FI.MANAGE_ANCHOR.REPORT_REGISTER);
  };

  const onClickRemoveFilter = (e: any): void => {
    e.preventDefault();

    resetAnchorReportSearchForm();
  };

  const onClickSearch = async (): Promise<void> => {
    await fetchFiAnchorReportSettingList(1, anchorReportPageable.sizePerPage, getAnchorReportSearchFormValues());
  };

  const paginate = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    await fetchFiAnchorReportSettingList(
      pageNumber,
      sizePerPage,
      getParsedSearchParams(FI_ANCHOR_REPORT_LIST_QS_KEY).formSearchData,
    );
  };

  const renderReportSearchResultTable = (): JSX.Element[] | undefined => {
    const getSendReportText = (data?: boolean): string => {
      switch (data) {
        case true:
          return t('text:Active');
        case false:
          return t('text:Deactivated');
        default:
          return '';
      }
    };

    const getReportStatusClassName = (isActivated: boolean): string => {
      switch (isActivated) {
        case true:
          return 'text-bold-blue-main600';
        case false:
          return 'text-bold-brick-red';
        default:
          return '';
      }
    };

    return anchorReportPageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.anchorName} />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
        <Td data={t(`code:anchor-agreement-status.${item.agreementStatus}`)} format="code" />
        <Td data={t(`code:report-type.${item.reportType}`)} format="code" />
        <Td className={getReportStatusClassName(item.activated)} data={getSendReportText(item.activated)} />
        <TdLink
          path={ROUTES_FI.MANAGE_ANCHOR.REPORT_DETAIL_BUILD_PATH(
            item.anchorReportSettingId,
            item.anchorAgreementId,
            item.reportType,
          )}
        />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Anchor_Report')} />
      <GuideMessage
        message={[
          `${t('text:Select_the_client_who_will_receive_the_send_report')} ${t(
            'text:The_report_will_be_sent_to_the_clients_registered_email',
          )}`,
          t('text:If_the_Anchor_Master_Agreement_is_invalid_the_report_wont_be_sent'),
          t('text:Download_the_reports_on_the_report_setting_details_pages'),
        ]}
      >
        {{
          button: isAdmin ? <Button onClick={onClickRegister}>{t('text:Register')}</Button> : undefined,
        }}
      </GuideMessage>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Master_Agreement_Number')} />
              <SearchInput name="contractNo" ref={anchorReportSearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorName" ref={anchorReportSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                ref={anchorReportSearchFormRegister}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                ref={anchorReportSearchFormRegister}
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Report_Type')} />
              <SearchSelect
                name="reportType"
                ref={anchorReportSearchFormRegister}
                selectOptions={getSelectOptions<REPORT_TYPE>('REPORT_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Agreement_Status')} />
              <SearchSelect
                name="agreementStatus"
                ref={anchorReportSearchFormRegister}
                selectOptions={getSelectOptions<ANCHOR_AGREEMENT_STATUS>('ANCHOR_AGREEMENT_STATUS', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Report_Status')} />
              <SearchSelect
                name="activated"
                ref={anchorReportSearchFormRegister}
                selectOptions={getSelectOptions<string>('SEND_REPORT', 'ALL', true)}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleAnchorReportSearchFormSubmit(onClickSearch)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {anchorReportPageData?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={REPORT_SEARCH_RESULT_TABLE_HEADERS} />
          <TableBody numOfCol={REPORT_SEARCH_RESULT_TABLE_HEADERS.length}>{renderReportSearchResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={anchorReportPageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierAnchorReportList;
