import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { BranchVOModel } from 'models/vo/BranchVO';
import { requestFiAnchorPartnerAccountAssignBranch } from 'utils/http/api/financier/anchor-partner-accounts';
import { requestBranchList } from 'utils/http/api/financier/branches';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

interface AssignBranchModalPropsType {
  currentModalId: number;
  anchorPartnerAccountIds: number[];
  refreshFunction?: Function;
}

function AssignBranchModal({ currentModalId, anchorPartnerAccountIds, refreshFunction }: AssignBranchModalPropsType) {
  const mounted = useMounted();
  const { t } = useTranslation();
  const [branchPageData, setBranchPageData] = useState<Pageable<BranchVOModel[]>>();
  const { pageable, setPageable } = usePageable();
  const modal = useModal();

  const BRANCH_ASSIGNMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Branch_Code'),
    },
    {
      headerText: t('text:Branch_Name'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Mobile'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: '',
      colWidths: 100,
    },
  ];

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (pageNumber: number = 1, rowCount: number = 10): Promise<void> => {
    try {
      const branchPageData = await requestBranchList(pageNumber, rowCount, {});

      ReactDOM.unstable_batchedUpdates(() => {
        setBranchPageData(branchPageData);
        setPageable(branchPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSelect = (e: any, item: BranchVOModel) => {
    e.preventDefault();

    modal.close(currentModalId);

    const onClickConfirm = async (): Promise<void> => {
      try {
        item &&
          (await requestFiAnchorPartnerAccountAssignBranch({
            branchId: item.branchId,
            anchorPartnerAccountIds: anchorPartnerAccountIds,
          }));

        modal.show(
          <h6>
            [{anchorPartnerAccountIds.length}]{' '}
            {t('text:Supplier(s)_is(are)_assigned_to_the_branchName_Branch', {
              branchName: item.branchName,
            })}
          </h6>,
          {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => {
              refreshFunction && refreshFunction();
            },
          },
        );
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_assign_the_supplier(s)_to_the_following_branch?')}

        <div className="mt-3">
          <div>
            {t('text:Branch_Name')}: {tableValueManage(item.branchName)}
          </div>
          <div>
            {t('text:Branch_Legal_Representative_Name')}: {tableValueManage(item.managerName)}
          </div>
          <div>
            {t('text:Branch_Registered_Office')}: {tableValueManage(item.address)}
          </div>
        </div>
      </h6>,
      {
        title: t('text:Select_Branch'),
        modalType: ModalType.CONFIRM,
        closeBtnText: t(`text:Cancel`),
        closeBtnCb: () => {},
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      },
    );
  };

  const renderBranchListTable = (): JSX.Element => {
    const paginate = (selectedPageNumber: number, selectedRowCount: number): void => {
      fetchAll(selectedPageNumber, selectedRowCount);
    };

    const renderResultTable = (): JSX.Element[] | undefined => {
      return branchPageData?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td data={item.telephone} />
          <Td data={item.managerName} />
          <Td data={item.managerMobile} />
          <Td data={item.managerEmail} />
          <Td className="information-table-more">
            <Button onClick={e => onClickSelect(e, item)}>{t('text:Select')}</Button>
          </Td>
        </Tr>
      ));
    };

    return (
      <>
        <TableBorder>
          <TableHeader header={BRANCH_ASSIGNMENT_TABLE_HEADERS} />
          <TableBody numOfCol={BRANCH_ASSIGNMENT_TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </>
    );
  };

  return (
    <div className="content-area">
      <GuideMessage
        message={[
          t(
            'text:Please_click_the_Select_button_after_choosing_the_branch_that_will_be_responsible_for_management_and_operation_of_the_selected_supplier',
          ),
          t(
            'text:The_responsible_Financier_branch_can_process_the_supplier_and_the_loan_agreement_after_the_assignment',
          ),
        ]}
        isImportContentArea
      />
      {renderBranchListTable()}
    </div>
  );
}

export default AssignBranchModal;
