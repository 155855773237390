import type { FINANCIER_INTERFACE_TYPE } from 'enums';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';

export async function requestFinancierInterfaceSetting(
  financierEnterpriseId: number,
  financierInterfaceType: FINANCIER_INTERFACE_TYPE,
): Promise<boolean> {
  // boolean return
  return await http.get({
    url: API_CM.FINANCIER_INTERFACE_SETTING.FINANCIER_INTERFACE_SETTING,
    data: {
      financierEnterpriseId,
      financierInterfaceType,
    },
  });
}
