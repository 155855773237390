import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import type { FINANCIAL_SETTING_TYPE } from 'enums';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { ArCommissionSettingVOModel } from 'models/vo/ArCommissionSettingVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFiArCommissionSettings } from 'utils/http/api/financier/ar-commission-settings';
import type { SearchFiArCommissionSettingDTO } from 'utils/http/api/financier/ar-commission-settings/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const getConstant = () => {
  const FI_PROGRAM_CHARGE_LIST_QS_KEY = 'fi-program-charge-list';
  const CURRENT_USER_AUTHORITY: AUTHORITY_TYPE | undefined = getSignIn()?.authorityType;
  const isAdmin = CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.ADMIN;

  const modal = useModal();
  const mounted = useMounted();
  const history = useHistory();
  const { t } = useTranslation(['format']);

  const TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Type'),
    },
    {
      headerText: t('text:Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Company_Name'),
    },
    {
      headerText: t('text:Company_Client_Code'),
    },
    {
      headerText: t('text:Activate_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return {
    t,
    modal,
    mounted,
    history,
    FI_PROGRAM_CHARGE_LIST_QS_KEY,
    TABLE_HEADERS,
    isAdmin,
  };
};

function FinancierProgramChargeListPage() {
  const { t, modal, mounted, history, FI_PROGRAM_CHARGE_LIST_QS_KEY, TABLE_HEADERS, isAdmin } = getConstant();

  const searchForm = useForm();
  const { pageable, setPageable } = usePageable(FI_PROGRAM_CHARGE_LIST_QS_KEY);
  const [chargeList, setChargeList] = useState<Pageable<ArCommissionSettingVOModel[]>>();

  useEffect(() => {
    if (mounted) {
      setFormValues<SearchFiArCommissionSettingDTO>(
        searchForm.setValue,
        getParsedSearchParams(FI_PROGRAM_CHARGE_LIST_QS_KEY).formSearchData,
      );
      fetchChargeList(pageable.currentPage, pageable.sizePerPage, searchForm.getValues());
    }
  }, [mounted]);

  async function fetchChargeList(
    pageNumber: number = 1,
    sizePerPage: number = 10,
    searchData: SearchFiArCommissionSettingDTO,
  ) {
    try {
      const response = await requestFiArCommissionSettings(pageNumber, sizePerPage, searchData);

      updateSearchParams(
        {
          ...searchData,
          pageNumber: pageNumber,
          rowCount: sizePerPage,
        },
        FI_PROGRAM_CHARGE_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setChargeList(response);
        setPageable(response);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const handleClickRemoveFilterButton = () => {
    searchForm.reset({});
  };

  const handleClickSearchButton = async () => {
    await fetchChargeList(1, pageable.sizePerPage, searchForm.getValues());
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchChargeList(page, sizePerPage, getParsedSearchParams(FI_PROGRAM_CHARGE_LIST_QS_KEY).formSearchData);
  };

  const renderProgramChargeSettingListTable = () => {
    return chargeList?.content.map((item, index) => {
      return (
        <Tr key={index}>
          <Td data={t(`code:financier-setting-type.${item.arCommissionSettingType}`)} />
          <Td data={item.agreementContractNo} />
          <Td data={item.currencyType} />
          <Td data={item.clientName} />
          <Td data={item.clientCode} />
          <Td
            className={item.activated ? 'text-bold-blue-main600' : 'text-bold-brick-red'}
            data={item.activated ? t('text:On') : t('text:Off')}
          />
          <TdLink path={ROUTES_FI.PROGRAM_SETTINGS.CHARGE_DETAIL_BUILD_PATH(item.arCommissionSettingId)} />
        </Tr>
      );
    });
  };

  const handleClickExport = async () => {
    const ExcelColumns: ColumnOption<any>[] = [
      {
        header: t('text:Type'),
        key: 'arCommissionSettingType',
      },
      {
        header: t('text:Master_Agreement_Number'),
        key: 'agreementContractNo',
      },
      {
        header: t('text:Currency'),
        key: 'currencyType',
      },
      {
        header: t('text:Company_Name'),
        key: 'clientName',
      },
      {
        header: t('text:Company_Client_Code'),
        key: 'clientCode',
      },
      {
        header: t('text:Activate_Status'),
        key: 'activated',
      },
    ];

    const PDFColumns: HeaderType[] = [
      {
        headerText: t('text:Type'),
      },
      {
        headerText: t('text:Master_Agreement_Number'),
      },
      {
        headerText: t('text:Currency'),
      },
      {
        headerText: t('text:Company_Name'),
      },
      {
        headerText: t('text:Company_Client_Code'),
      },
      {
        headerText: t('text:Active_Status'),
      },
    ];

    try {
      const searchData = getParsedSearchParams(FI_PROGRAM_CHARGE_LIST_QS_KEY).formSearchData;
      const programChargeExcel = await requestFiArCommissionSettings(0, EXCEL_EXPORT_MAX_ROW_COUNT, searchData);
      const programChargePdf = programChargeExcel.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: any[] = programChargeExcel.content.map(data => ({
        arCommissionSettingType: tableValueManage(t(`code:financier-setting-type.${data.arCommissionSettingType}`)),
        agreementContractNo: tableValueManage(data.agreementContractNo),
        currencyType: tableValueManage(data.currencyType),
        clientName: tableValueManage(data.clientName),
        clientCode: tableValueManage(data.clientCode),
        activated: tableValueManage(data.activated ? t('text:On') : t('text:Off')),
      }));

      const renderPDFTableBodyResult = () => {
        return programChargePdf?.map((item, i) => {
          return (
            <Tr key={i} className="virtual-table-row">
              <Td data={t(`code:financier-setting-type.${item.arCommissionSettingType}`)} />
              <Td data={item.agreementContractNo} />
              <Td data={item.currencyType} />
              <Td data={item.clientName} />
              <Td data={item.clientCode} />
              <Td
                className={item.activated ? 'text-bold-blue-main600' : 'text-bold-brick-red'}
                data={item.activated ? t('text:On') : t('text:Off')}
              />
            </Tr>
          );
        });
      };

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        columns: ExcelColumns,
        jsonArrayData: jsonArrayData,
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: PDFColumns,
        tableBody: renderPDFTableBodyResult(),
      };
      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:Transaction_Fee_Setting')} />
      <GuideMessage
        message={[
          t('text:You_can_register_and_check_Transaction_Fee_on_this_page'),
          t('text:Transaction_Fee_set_in_Default_will_be_applied_to_all_companies_by_default'),
          t(
            'text:Transaction_Fee_set_By_Anchor_Master_Agreement_will_be_applied_to_all_Partners_associated_with_that_Anchor_Master_Agreement_and_this_takes_precedence_over_the_Default_setting',
          ),
          t(
            'text:Transaction_Fee_set_By_Partner_Master_Agreement_will_be_applied_only_to_Partner_which_is_related_to_that_Partner_Master_Agreement_and_this_takes_precedence_over_the_setting_in_both_By_Anchor_Master_Agreement_and_Default',
          ),
        ]}
      >
        {{
          button: (
            <>
              {isAdmin && (
                <Button fullWidth onClick={() => history.push(ROUTES_FI.PROGRAM_SETTINGS.CHARGE_REGISTER)}>
                  {t('text:Register_Transaction_Fee_Setting')}
                </Button>
              )}
            </>
          ),
        }}
      </GuideMessage>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={handleClickRemoveFilterButton}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Type')} />
              <SearchSelect
                name="arCommissionSettingType"
                selectOptions={getSelectOptions<FINANCIAL_SETTING_TYPE>('FINANCIAL_SETTING_TYPE', 'ALL', true)}
                ref={searchForm.register}
              />
              <SearchLabel label={t('text:Master_Agreement_Number')} />
              <SearchInput name="agreementContractNo" ref={searchForm.register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={searchForm.register}
              />
              <SearchLabel label={t('text:Company_Name')} />
              <SearchInput name="clientName" ref={searchForm.register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Company_Client_Code')} />
              <SearchInput name="clientCode" ref={searchForm.register} />
              <SearchLabel label={t('text:Activate_Status')} />
              <SearchSelect
                name="activated"
                selectOptions={[
                  { label: t('text:All'), value: '' },
                  { label: t('text:On'), value: 'true' },
                  { label: t('text:Off'), value: 'false' },
                ]}
                ref={searchForm.register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearchButton}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2 justify-content-between">
          <p className="total-data">
            {t('text:Total')} {chargeList?.totalElements ?? 0}
          </p>
          <Button onClick={handleClickExport} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={TABLE_HEADERS} />
          <TableBody numOfCol={TABLE_HEADERS.length}>{renderProgramChargeSettingListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierProgramChargeListPage;
