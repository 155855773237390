import { useEffect } from 'react';

import { useFormContext } from 'components/stateless/Form';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useFinancingLimitController = () => {
  const {
    methods: { setValue, watch },
    isEditable,
  } = useFormContext();

  const {
    agreement: { adhocLimitAllowable },
  } = useAgreementViewModel();

  const { pageType } = useExtraInformationViewModel();

  const { currencyType } = watch();

  const handleAdhocLimitAllowableChange = () => {
    setValue('adhocLimitAmount', null);
    setValue('adhocLimitStartDate', null);
    setValue('adhocLimitEndDate', null);
  };

  useEffect(() => {
    if (!adhocLimitAllowable) {
      setValue('adhocLimitAmount', null);
      setValue('adhocLimitStartDate', null);
      setValue('adhocLimitEndDate', null);
    }
  }, [isEditable, adhocLimitAllowable, setValue]);

  return { currencyType, handleAdhocLimitAllowableChange, pageType };
};

export default useFinancingLimitController;
