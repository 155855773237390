import type {
  PotentialPartnerFinancingApplicationDetailVO,
  PotentialPartnerFinancingApplicationDetailVOModel,
} from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import { formattingToPotentialPartnerFinancingApplicationDetailVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import type { PotentialPartnerVO, PotentialPartnerVOModel } from 'models/vo/PotentialPartnerVO';
import { formattingToPotentialPartnerVOModel } from 'models/vo/PotentialPartnerVO';
import http, { ContentType } from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

import type { AnPotentialPartnerFinancingApplicationSubmitRequest } from './request';

/**
 * desc :: 잠재 파트너 application submit
 * params :: AnPotentialPartnerFinancingApplicationSubmitRequest
 * **/
export async function requestAnPotentialPartnerFinancingApplicationSubmit(
  data: AnPotentialPartnerFinancingApplicationSubmitRequest,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.post<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.FINANCING_APPLICATIONS,
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}

/**
 * desc :: 약정 심사 신청 기제출 건 확인
 * params :: emailCode / anchorPartnerAccountId
 * 기제출 건이 없으면 potentialPartnerFinancingApplicationId == null
 * **/
export async function requestAnPotentialPartnerFinancingApplicationsActivatedApplication(
  code: string,
  anchorPartnerAccountId: number,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.get<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.ACTIVATED_APPLICATION,
    data: { code, 'anchor-partner-account-id': anchorPartnerAccountId },
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}

/**
 * desc :: 제출(신청) 건 상세 조회
 * params :: emailCode / potentialPartnerFinancingApplicationId
 * **/
export async function requestAnPotentialPartnerFinancingApplicationsDetail(
  potentialPartnerFinancingApplicationId: number,
  code: string,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.get<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.FINANCING_APPLICATION_DETAIL(
      potentialPartnerFinancingApplicationId,
    ),
    data: { code },
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}

/**
 * desc :: 약정 심사 신청 건 관련 잠재 파트너 정보 조회
 * params :: emailCode / potentialPartnerFinancingApplicationId
 * **/
export async function requestAnPotentialPartnerFinancingApplicationPartner(
  potentialPartnerFinancingApplicationId: number,
  code: string,
): Promise<PotentialPartnerVOModel> {
  const response = await http.get<PotentialPartnerVO>({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.RELATED_POTENTIAL_PARTNER(
      potentialPartnerFinancingApplicationId,
    ),
    data: { code },
  });

  return formattingToPotentialPartnerVOModel(response);
}

/**
 * desc :: 제출(신청) 건 취소
 * params :: emailCode / potentialPartnerFinancingApplicationId
 * **/
export async function requestAnPotentialPartnerFinancingApplicationsCancelSubmitted(
  potentialPartnerFinancingApplicationId: number,
  code: string,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.put<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.CANCEL_SUBMITTED_APPLICATION(
      potentialPartnerFinancingApplicationId,
    ),
    data: { code },
    contentType: ContentType.URLENCODED,
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}
