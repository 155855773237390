const SY_MANAGEMENT_PATH = `/system/management`;
const SY_MANAGEMENT_FINANCIER_PATH = `${SY_MANAGEMENT_PATH}/financier`;
const SY_MANAGEMENT_FINANCIER_ADMIN_PATH = `${SY_MANAGEMENT_PATH}/financier-admin`;
const SY_MANAGEMENT_BANK_CODE_PATH = `${SY_MANAGEMENT_PATH}/bank-code`;
const SY_BILLING = `${SY_MANAGEMENT_PATH}/billing`;
const TRANSACTION_PERFORMANCE_PATH = `${SY_MANAGEMENT_PATH}/transaction-performance`;
const SY_TOU_PATH = `${SY_MANAGEMENT_PATH}/tou`;

const SY_MONITOR_PLATFORM_PATH = `/system/monitor-platform`;
const SY_MONITOR_PLATFORM_ENTERPRISE_PATH = `${SY_MONITOR_PLATFORM_PATH}/enterprise`;
const SY_MONITOR_PLATFORM_USER_PATH = `${SY_MONITOR_PLATFORM_PATH}/user`;
const SY_MONITOR_PLATFORM_UPLOADED_PARTNER_PATH = `${SY_MONITOR_PLATFORM_PATH}/uploaded-partner`;
const SY_MONITOR_PLATFORM_LOGIN_HISTORY_PATH = `${SY_MONITOR_PLATFORM_PATH}/login-history`;

const SY_MONITOR_ANCHOR_PATH = `/system/monitor-anchor`;
const SY_MONITOR_ANCHOR_COMPANY_PATH = `${SY_MONITOR_ANCHOR_PATH}/company`;
const SY_MONITOR_ANCHOR_AGREEMENT_PATH = `${SY_MONITOR_ANCHOR_PATH}/agreement`;
const SY_MONITOR_ANCHOR_USER_PATH = `${SY_MONITOR_ANCHOR_PATH}/user`;
const SY_MONITOR_ANCHOR_REPORT_PATH = `${SY_MONITOR_ANCHOR_PATH}/report`;

const SY_MONITOR_PARTNER_PATH = `/system/monitor-partner`;
const SY_MONITOR_PARTNER_COMPANY_PATH = `${SY_MONITOR_PARTNER_PATH}/company`;
const SY_MONITOR_PARTNER_AGREEMENT_PATH = `${SY_MONITOR_PARTNER_PATH}/agreement`;
const SY_MONITOR_PARTNER_REPORT_PATH = `${SY_MONITOR_PARTNER_PATH}/report`;

const SY_REGISTER_INVOICE_PATH = `/system/register-invoice`;
const SY_REGISTER_INVOICE_CONFIRMATION_PATH = `${SY_REGISTER_INVOICE_PATH}/confirmation`;

const SY_REGISTER_AR_PATH = `/system/register-ar`;
const SY_REGISTER_AR_CONFIRMATION_PATH = `${SY_REGISTER_AR_PATH}/confirmation`;

const SY_MONITOR_FINANCING_PATH = `/system/monitor-financing`;
const SY_MONITOR_FINANCING_EARLY_REPAYMENT_PATH = `${SY_MONITOR_FINANCING_PATH}/early-repayment`;
const SY_MONITOR_FINANCING_EXTENSION_PATH = `${SY_MONITOR_FINANCING_PATH}/extension`;
const SY_MONITOR_FINANCING_SECURED_INVENTORY_PATH = `${SY_MONITOR_FINANCING_PATH}/secured-inventory`;

const SY_VIEW_TRANSACTION_PATH = `/system/view-transaction`;
const SY_VIEW_TRANSACTION_AR_PATH = `${SY_VIEW_TRANSACTION_PATH}/ar`;
const SY_VIEW_TRANSACTION_INVOICE_PATH = `${SY_VIEW_TRANSACTION_PATH}/invoice`;
const SY_VIEW_TRANSACTION_INVOICE_PAYMENT_PATH = `${SY_VIEW_TRANSACTION_PATH}/invoice-payment`;
const SY_VIEW_TRANSACTION_FINANCING_PATH = `${SY_VIEW_TRANSACTION_PATH}/financing`;
const SY_VIEW_TRANSACTION_FINANCING_OVERVIEW_BY_ANCHOR_PATH = `${SY_VIEW_TRANSACTION_PATH}/overview-by-anchor`;
const SY_VIEW_TRANSACTION_FINANCING_OVERVIEW_BY_PARTNER_PATH = `${SY_VIEW_TRANSACTION_PATH}/overview-by-partner`;

const SY_MY_ACCOUNT_PATH = `/system/my-account`;

export const ROUTES_SY = {
  MANAGEMENT: {
    FINANCIER_LIST: SY_MANAGEMENT_FINANCIER_PATH,
    FINANCIER_REGISTER: `${SY_MANAGEMENT_FINANCIER_PATH}/register`,
    FINANCIER_DETAIL: `${SY_MANAGEMENT_FINANCIER_PATH}/:id`,
    FINANCIER_DETAIL_BUILD_PATH: (id: number) => `${SY_MANAGEMENT_FINANCIER_PATH}/${id}`,

    FINANCIER_ADMIN_LIST: SY_MANAGEMENT_FINANCIER_ADMIN_PATH,
    FINANCIER_ADMIN_REGISTER: `${SY_MANAGEMENT_FINANCIER_ADMIN_PATH}/register`,
    FINANCIER_ADMIN_DETAIL: `${SY_MANAGEMENT_FINANCIER_ADMIN_PATH}/:id`,
    FINANCIER_ADMIN_DETAIL_BUILD_PATH: (id: number) => `${SY_MANAGEMENT_FINANCIER_ADMIN_PATH}/${id}`,

    BANK_CODE_LIST: SY_MANAGEMENT_BANK_CODE_PATH,
    BANK_CODE_REGISTER: `${SY_MANAGEMENT_BANK_CODE_PATH}/register`,
    BANK_CODE_CONFIRMED_DETAIL: `${SY_MANAGEMENT_BANK_CODE_PATH}/confirmed-detail/:bankCodeId`,
    BANK_CODE_CONFIRMED_DETAIL_BUILD_PATH: (bankCodeId: number) =>
      `${SY_MANAGEMENT_BANK_CODE_PATH}/confirmed-detail/${bankCodeId}`,
    BANK_CODE_WAITING_DETAIL: `${SY_MANAGEMENT_BANK_CODE_PATH}/waiting-detail/:waitingBankCodeId`,
    BANK_CODE_WAITING_DETAIL_BUILD_PATH: (waitingBankCodeId: number) =>
      `${SY_MANAGEMENT_BANK_CODE_PATH}/waiting-detail/${waitingBankCodeId}`,

    BILLING: SY_BILLING,

    TRANSACTION_PERFORMANCE_LIST: TRANSACTION_PERFORMANCE_PATH,

    TOU_LIST: SY_TOU_PATH,
    TOU_REGISTER: `${SY_TOU_PATH}/register`,
    TOU_DETAIL: `${SY_TOU_PATH}/:termsOfUseId`,
    TOU_DETAIL_BUILD_PATH: (termsOfUseId: number) => `${SY_TOU_PATH}/${termsOfUseId}`,
  },

  MONITOR_PLATFORM: {
    ENTERPRISE_LIST: SY_MONITOR_PLATFORM_ENTERPRISE_PATH,
    ENTERPRISE_DETAIL: `${SY_MONITOR_PLATFORM_ENTERPRISE_PATH}/:enterpriseId`,
    ENTERPRISE_DETAIL_BUILD_PATH: (enterpriseId: number) => `${SY_MONITOR_PLATFORM_ENTERPRISE_PATH}/${enterpriseId}`,

    USER_LIST: SY_MONITOR_PLATFORM_USER_PATH,
    USER_DETAIL: `${SY_MONITOR_PLATFORM_USER_PATH}/:userId`,
    USER_DETAIL_BUILD_PATH: (userId: number) => `${SY_MONITOR_PLATFORM_USER_PATH}/${userId}`,

    UPLOADED_PARTNER_LIST: SY_MONITOR_PLATFORM_UPLOADED_PARTNER_PATH,
    UPLOADED_PARTNER_DETAIL: `${SY_MONITOR_PLATFORM_UPLOADED_PARTNER_PATH}/:uploadedPartnerId`,
    UPLOADED_PARTNER_DETAIL_BUILD_PATH: (uploadedPartnerId: number) =>
      `${SY_MONITOR_PLATFORM_UPLOADED_PARTNER_PATH}/${uploadedPartnerId}`,

    LOGIN_HISTORY_LIST: SY_MONITOR_PLATFORM_LOGIN_HISTORY_PATH,
  },

  MONITOR_ANCHOR: {
    COMPANY_LIST: SY_MONITOR_ANCHOR_COMPANY_PATH,
    COMPANY_DETAIL: `${SY_MONITOR_ANCHOR_COMPANY_PATH}/:financierClientId`,
    COMPANY_DETAIL_BUILD_PATH: (financierClientId: number) => `${SY_MONITOR_ANCHOR_COMPANY_PATH}/${financierClientId}`,

    AGREEMENT_LIST: SY_MONITOR_ANCHOR_AGREEMENT_PATH,
    AGREEMENT_WAITING_DETAIL: `${SY_MONITOR_ANCHOR_AGREEMENT_PATH}/waiting-detail/:waitingAnchorAgreementId`,
    AGREEMENT_WAITING_DETAIL_BUILD_PATH: (waitingAnchorAgreementId: number) =>
      `${SY_MONITOR_ANCHOR_AGREEMENT_PATH}/waiting-detail/${waitingAnchorAgreementId}`,
    AGREEMENT_REGISTERED_DETAIL: `${SY_MONITOR_ANCHOR_AGREEMENT_PATH}/registered-detail/:anchorAgreementId`,
    AGREEMENT_REGISTERED_DETAIL_BUILD_PATH: (anchorAgreementId: number) =>
      `${SY_MONITOR_ANCHOR_AGREEMENT_PATH}/registered-detail/${anchorAgreementId}`,

    USER_LIST: SY_MONITOR_ANCHOR_USER_PATH,
    USER_WAITING_DETAIL: `${SY_MONITOR_ANCHOR_USER_PATH}/waiting-detail/:waitingAnchorUserId`,
    USER_WAITING_DETAIL_BUILD_PATH: (waitingAnchorUserId: number) =>
      `${SY_MONITOR_ANCHOR_USER_PATH}/waiting-detail/${waitingAnchorUserId}`,
    USER_REGISTERED_DETAIL: `${SY_MONITOR_ANCHOR_USER_PATH}/registered-detail/:anchorUserId`,
    USER_REGISTERED_DETAIL_BUILD_PATH: (anchorUserId: number) =>
      `${SY_MONITOR_ANCHOR_USER_PATH}/registered-detail/${anchorUserId}`,

    REPORT_LIST: SY_MONITOR_ANCHOR_REPORT_PATH,
    REPORT_DETAIL: `${SY_MONITOR_ANCHOR_REPORT_PATH}/:anchorReportSettingId`,
    REPORT_DETAIL_BUILD_PATH: (anchorReportSettingId: number) =>
      `${SY_MONITOR_ANCHOR_REPORT_PATH}/${anchorReportSettingId}`,
  },

  MONITOR_PARTNER: {
    COMPANY_LIST: SY_MONITOR_PARTNER_COMPANY_PATH,
    COMPANY_DETAIL: `${SY_MONITOR_PARTNER_COMPANY_PATH}/:id`,
    COMPANY_DETAIL_BUILD_PATH: (id: number) => `${SY_MONITOR_PARTNER_COMPANY_PATH}/${id}`,

    AGREEMENT_LIST: SY_MONITOR_PARTNER_AGREEMENT_PATH,
    AGREEMENT_WAITING_DETAIL: `${SY_MONITOR_PARTNER_AGREEMENT_PATH}/waiting-detail/:id`,
    AGREEMENT_WAITING_DETAIL_BUILD_PATH: (id: number) => `${SY_MONITOR_PARTNER_AGREEMENT_PATH}/waiting-detail/${id}`,
    AGREEMENT_REGISTERED_DETAIL: `${SY_MONITOR_PARTNER_AGREEMENT_PATH}/registered-detail/:id`,
    AGREEMENT_REGISTERED_DETAIL_BUILD_PATH: (id: number) =>
      `${SY_MONITOR_PARTNER_AGREEMENT_PATH}/registered-detail/${id}`,

    REPORT_LIST: SY_MONITOR_PARTNER_REPORT_PATH,
    REPORT_DETAIL: `${SY_MONITOR_PARTNER_REPORT_PATH}/:id`,
    REPORT_DETAIL_BUILD_PATH: (id: number) => `${SY_MONITOR_PARTNER_REPORT_PATH}/${id}`,
  },

  REGISTER_INVOICE: {
    CONFIRMATION_LIST: SY_REGISTER_INVOICE_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${SY_REGISTER_INVOICE_CONFIRMATION_PATH}/:invoiceSummaryId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (invoiceSummaryId: number) =>
      `${SY_REGISTER_INVOICE_CONFIRMATION_PATH}/${invoiceSummaryId}`,
  },

  REGISTER_AR: {
    CONFIRMATION_LIST: SY_REGISTER_AR_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${SY_REGISTER_AR_CONFIRMATION_PATH}/:arSummaryId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (arSummaryId: number) => `${SY_REGISTER_AR_CONFIRMATION_PATH}/${arSummaryId}`,
  },

  MONITOR_FINANCING: {
    EARLY_REPAYMENT_LIST: SY_MONITOR_FINANCING_EARLY_REPAYMENT_PATH,
    EARLY_REPAYMENT_DETAIL: `${SY_MONITOR_FINANCING_EARLY_REPAYMENT_PATH}/:id`,
    EARLY_REPAYMENT_DETAIL_BUILD_PATH: (id: number) => `${SY_MONITOR_FINANCING_EARLY_REPAYMENT_PATH}/${id}`,

    EXTENSION_LIST: SY_MONITOR_FINANCING_EXTENSION_PATH,
    EXTENSION_DETAIL: `${SY_MONITOR_FINANCING_EXTENSION_PATH}/:id`,
    EXTENSION_DETAIL_BUILD_PATH: (id: number) => `${SY_MONITOR_FINANCING_EXTENSION_PATH}/${id}`,

    SECURED_INVENTORY_LIST: SY_MONITOR_FINANCING_SECURED_INVENTORY_PATH,
    SECURED_INVENTORY_DETAIL: `${SY_MONITOR_FINANCING_SECURED_INVENTORY_PATH}/:id`,
    SECURED_INVENTORY_DETAIL_BUILD_PATH: (id: number) => `${SY_MONITOR_FINANCING_SECURED_INVENTORY_PATH}/${id}`,
  },

  VIEW_TRANSACTION: {
    AR_LIST: SY_VIEW_TRANSACTION_AR_PATH,
    AR_DETAIL: `${SY_VIEW_TRANSACTION_AR_PATH}/:successArId`,
    AR_DETAIL_BUILD_PATH: (successArId: number) => `${SY_VIEW_TRANSACTION_AR_PATH}/${successArId}`,

    INVOICE_LIST: SY_VIEW_TRANSACTION_INVOICE_PATH,
    INVOICE_DETAIL: `${SY_VIEW_TRANSACTION_INVOICE_PATH}/:successInvoiceId`,
    INVOICE_DETAIL_BUILD_PATH: (successInvoiceId: number) => `${SY_VIEW_TRANSACTION_INVOICE_PATH}/${successInvoiceId}`,

    INVOICE_PAYMENT_LIST: SY_VIEW_TRANSACTION_INVOICE_PAYMENT_PATH,

    FINANCING_LIST: SY_VIEW_TRANSACTION_FINANCING_PATH,
    FINANCING_DETAIL: `${SY_VIEW_TRANSACTION_FINANCING_PATH}/:loanId`,
    FINANCING_DETAIL_BUILD_PATH: (loanId: number) => `${SY_VIEW_TRANSACTION_FINANCING_PATH}/${loanId}`,

    OVERVIEW_BY_ANCHOR_LIST: SY_VIEW_TRANSACTION_FINANCING_OVERVIEW_BY_ANCHOR_PATH,

    OVERVIEW_BY_PARTNER_LIST: SY_VIEW_TRANSACTION_FINANCING_OVERVIEW_BY_PARTNER_PATH,
  },

  MY_ACCOUNT: SY_MY_ACCOUNT_PATH,
};
