import type { SelectOptionType } from 'components/stateless/Select/Select';
import type { CURRENCY_TYPE } from 'enums';
import { ROLE } from 'enums';
import i18n from 'locales/i18n';
import { getCurrencyType, getSignIn } from 'utils/storage/LocalStorage';

import { getSelectOptionList } from './options';

import type { OptionKeyTypes } from './options';

const getSupportedCurrencyTypes = (
  currencyOptions: SelectOptionType[],
  defaultSelectOptions: SelectOptionType[],
  currencyConditions: 'ALL' | CURRENCY_TYPE[],
) => {
  const supportedCurrencyTypes: CURRENCY_TYPE[] = getCurrencyType();
  const supportedCurrencyTypeList =
    supportedCurrencyTypes?.length === 0
      ? currencyOptions
      : currencyOptions.filter(item => supportedCurrencyTypes?.includes(item.value));

  if (currencyConditions === 'ALL') return [...defaultSelectOptions, ...supportedCurrencyTypeList];
  else {
    // 서버에서 온 응답값이 일시적으로 undefined일 경우, 모든 목록을 보여주도록
    if (currencyConditions[0] === undefined) return [...defaultSelectOptions, ...currencyOptions];

    const conditionCurrencyTypeList = currencyOptions.filter(item =>
      (currencyConditions as unknown as CURRENCY_TYPE[]).includes(item.value),
    );

    const mergeAllList = [...defaultSelectOptions, ...supportedCurrencyTypeList, ...conditionCurrencyTypeList];
    const removeDuplicateList = mergeAllList.reduce(
      (acc: SelectOptionType[], cur: SelectOptionType) => (acc.includes(cur) ? acc : [...acc, cur]),
      [],
    );

    return removeDuplicateList;
  }
};

export default function getSelectOptions<T>(
  selectOptionListKey: OptionKeyTypes,
  condition: 'ALL' | T[] = 'ALL',
  isNeedSelectAll: boolean = false,
): SelectOptionType[] {
  const ALL_SELECT_OPTION: SelectOptionType[] = [
    {
      label: i18n.t('text:All'),
      value: '',
    },
  ];

  let defaultSelectOptions: SelectOptionType[] = [];

  if (isNeedSelectAll) defaultSelectOptions = [...ALL_SELECT_OPTION];

  const isFinancier = getSignIn()?.authorities[0].authority === ROLE.ROLE_FINANCIER;

  if (isFinancier && selectOptionListKey === 'CURRENCY_TYPE') {
    return getSupportedCurrencyTypes(
      getSelectOptionList('CURRENCY_TYPE'),
      defaultSelectOptions,
      condition as 'ALL' | CURRENCY_TYPE[],
    );
  }

  if (condition === 'ALL') return [...defaultSelectOptions, ...getSelectOptionList(selectOptionListKey)];
  else
    return [
      ...defaultSelectOptions,
      ...getSelectOptionList(selectOptionListKey).filter((item: SelectOptionType) => condition.includes(item.value)),
    ];
}
