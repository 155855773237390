export enum ColorMap {
  blue_main800 = '#1f749e',
  blue_main700 = '#2384b4',
  blue_main600 = '#2996cc',
  blue_main500 = '#74b9dd',
  blue_main400 = '#cfecfb',
  blue_main300 = '#e2f3fe',
  blue_main200 = '#e7f5fd',
  blue_main100 = '#f3faff',

  // _orange.scss
  orange_main800 = '#ff9900',
  orange_main700 = '#ffa800',
  orange_main600 = '#ffbc00',
  orange_main500 = '#ffcc00',
  orange_main400 = '#ffea95',
  orange_main300 = '#fff7ad',
  orange_main200 = '#fff8bd',
  orange_main100 = '#fffcde',

  // _gray.scss
  sub1000 = '#161616',
  sub900 = '#262626',
  sub800 = '#393939',
  sub700 = '#525252',
  sub600 = '#6f6f6f',
  sub500 = '#8d8d8d',
  sub400 = '#a8a8a8',
  sub300 = '#c6c6c6',
  sub200 = '#e0e0e0',
  sub100 = '#f5f5f5',
  warmsub100 = '#f7f3f2',
  warmsub200 = '#e5e0df',
  warmsub300 = '#cac5c4',
  warmsub400 = '#ada8a8',
  warmsub500 = '#8f8b8b',
  warmsub700 = '#565151',
  warmsub800 = '#3c3838',
  warmsub900 = '#272525',
  warmsub1000 = '#171414',
  coldsub500 = '#878d96',
  coldsub600 = '#697077',
  coldsub700 = '#4d5358',
  coldsub800 = '#343a3f',

  // color.scss
  white = '#ffffff',
  black = '#000000',
  catskill_white = '#f2f4f8',
  barley_white = '#fff2d1',
  coral = '#f97f50',
  brick_red = '#c72a32',
  hint_of_red = '#f7f3f2',
  cosmos = '#ffd9d9',
  forest_green = '#297e2e',
  honey_flower = '#5a1f6f',
  potters_clay = '#84612e',
  derby = '#ffecda',
  french_lilac = '#f0e1f5',
  blue_dianne = '#235f62',
}
