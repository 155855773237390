import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { DEALER_AGREEMENT_STATUS, LOAN_LIMIT_CHECK_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { requestDealerAgreementsList } from 'utils/http/api/dealer/dealer-agreements';
import useModal from 'utils/modal/useModal';
import { getFormSearchParamValue, updateSearchParams } from 'utils/searchParams';

const DE_ANCHOR_AGREEMENT_LIST_QS_KEY = 'de-anchor-agreement-list';

function DealerAgreementList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { pageable, setPageable } = usePageable(DE_ANCHOR_AGREEMENT_LIST_QS_KEY);
  const [dealerAgreementList, setDealerAgreementList] = useState<Pageable<DealerAgreementVOModel[]>>();
  const [searchAgreementNumber, setSearchAgreementNumber] = useState<string>('');
  const calculatorBigNumber = new CalculatorBigNumber();

  useEffect(() => {
    if (mounted) {
      const searchContractNo = getFormSearchParamValue('contractNo');
      setSearchAgreementNumber(searchContractNo);
      fetchDeAgreementList(pageable.currentPage, pageable.sizePerPage, searchContractNo);
    }
  }, [mounted]);

  async function fetchDeAgreementList(pageNumber: number = 1, sizePerPage: number = 10, contractNo: string) {
    try {
      const dealerAgreementPage = await requestDealerAgreementsList(pageNumber, sizePerPage, {
        contractNo,
      });
      updateSearchParams({ contractNo, pageNumber, rowCount: sizePerPage }, DE_ANCHOR_AGREEMENT_LIST_QS_KEY);

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementList(dealerAgreementPage);
        setPageable(dealerAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchDeAgreementList(page, sizePerPage, getFormSearchParamValue('contractNo'));
  };

  const onBlurUpdateAgreementNumber = (e: any) => {
    setSearchAgreementNumber(e.target.value);
  };

  const onClickSearch = async (e: any) => {
    e.preventDefault();
    fetchDeAgreementList(0, pageable.sizePerPage, searchAgreementNumber);
  };

  const tableWithAdHocHeaders: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 150,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Associated_Anchor_Company_Registration_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Total_Limit'),
      colWidths: 120,
    },
    {
      headerText: t('text:Ad_hoc_Limit'),
      colWidths: 120,
    },
    {
      headerText: t('text:Remaining_Limit'),
      colWidths: 120,
    },
    {
      headerText: t('text:Responsible_Bank_Branch'),
      colWidths: 180,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expired_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 150,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    const getDealerAgreementStatusClassName = (dealerAgreementStatus: DEALER_AGREEMENT_STATUS) => {
      switch (dealerAgreementStatus) {
        case DEALER_AGREEMENT_STATUS.ACTIVATED:
          return 'text-bold-dark-green';
        case DEALER_AGREEMENT_STATUS.SUSPENDED:
          return 'text-bold-brick-red';
        default:
          return '';
      }
    };

    const isNoneLoanLimitCheckType = (loanLimitCheckType: keyof typeof LOAN_LIMIT_CHECK_TYPE): boolean => {
      return loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE;
    };

    return dealerAgreementList?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
          <Td data={item.financierName} />
          <Td data={item.contractNo} />
          <Td data={item.anchorFinancierClientName} />
          <Td data={item.anchorFinancierClientCode} />
          <Td data={item.currencyType} />
          <Td data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.loanLimitAmount} format="number" />
          {item.adhocLimitAllowable &&
          item.adhocLimitAmount !== '0' &&
          !isNoneLoanLimitCheckType(item.loanLimitCheckType) ? (
            <Td data={item.adhocLimitAmount} format="number" />
          ) : (
            <Td data="-" className="text-end" />
          )}
          <Td
            data={
              isNoneLoanLimitCheckType(item.loanLimitCheckType)
                ? '-'
                : calculatorBigNumber.add(item.loanLimitAmount).minus(item.requestedLoanAmount).get()
            }
            format="number"
          />
          <Td data={item.branchName} />
          <Td data={item.startDate} format="date" />
          <Td data={item.expiryDate} format="date" />
          <Td
            data={t(`code:anchor-agreement-status.${item?.dealerAgreementStatus}`)}
            className={getDealerAgreementStatusClassName(item.dealerAgreementStatus)}
            format="code"
          />
          <TdLink
            path={ROUTES_DE.COMPANY.AGREEMENT_DETAIL_BUILD_PATH(item.dealerAgreementId)}
            state={{
              dealerFinancierClientId: item.dealerFinancierClientId,
              supportedCollateralType: item.collateralType,
            }}
          />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Master_Agreement')} />

      <div className="content-area">
        <SectionTitle title={t('text:Agreement_List')} />
        <form>
          <div className="d-flex information-filter mt-0">
            <input
              className="only-input"
              type="text"
              placeholder={t('text:Partner_Master_Agreement_Number')}
              onBlur={onBlurUpdateAgreementNumber}
              style={{ width: '220px' }}
              defaultValue={searchAgreementNumber}
            />
            <Button size={ButtonSizeEnum.XS} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
        <TableBorder>
          <TableHeader header={tableWithAdHocHeaders} />
          <TableBody numOfCol={tableWithAdHocHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerAgreementList;
