import { formattingToLoanAdditionalDocumentConditionVOModel } from './LoanAdditionalDocumentConditionVO';

import type {
  LoanAdditionalDocumentConditionVO,
  LoanAdditionalDocumentConditionVOModel,
} from './LoanAdditionalDocumentConditionVO';

export interface LoanAdditionalDocumentAttachmentVO {
  loanAdditionalDocumentAttachmentId: number;
  documentAttachmentDescription: string;
  documentFormName: string;
  documentAttachmentId: number;
  documentFileName: string;
  documentPath: string;
  documentFileSize: number;
  documentFileContentType: string;
  mandatoryCondition: LoanAdditionalDocumentConditionVO;
  hardCopyCondition: LoanAdditionalDocumentConditionVO;
}

export interface LoanAdditionalDocumentAttachmentVOModel extends LoanAdditionalDocumentAttachmentVO {
  mandatoryCondition: LoanAdditionalDocumentConditionVOModel;
  hardCopyCondition: LoanAdditionalDocumentConditionVOModel;
}

export function formattingToLoanAdditionalDocumentAttachmentVOModel(
  data: LoanAdditionalDocumentAttachmentVO,
): LoanAdditionalDocumentAttachmentVOModel {
  return {
    ...data,
    mandatoryCondition: formattingToLoanAdditionalDocumentConditionVOModel(data.mandatoryCondition),
    hardCopyCondition: formattingToLoanAdditionalDocumentConditionVOModel(data.hardCopyCondition),
  };
}
