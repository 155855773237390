import type { ReactNode } from 'react';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import QuestionButton from 'components/stateless/QuestionButton';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import type { TFunctionResult } from 'i18next';

type FormatType = 'number' | 'date' | 'datetime' | 'percent' | 'code';

interface FormValuePropsType {
  className?: string;
  label: string;
  subLabel?: string;
  value?: string | number | TFunctionResult | boolean;
  subValue?: string | number | TFunctionResult | boolean;
  hidden?: boolean;
  col?: number;
  children?: ReactNode;
  format?: FormatType;
  hasStatusDescription?: boolean;
  showStatusDescriptionFunc?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  wrapperStyle?: React.CSSProperties;
  labelChildren?: React.ReactNode;
}

export const FormValue = ({
  children,
  className = 'information-form__input border-none',
  label,
  subLabel = '',
  value,
  subValue,
  hidden = false,
  col = 6,
  format,
  hasStatusDescription,
  showStatusDescriptionFunc,
  wrapperStyle,
  labelChildren,
}: FormValuePropsType) => {
  const { t } = useTranslation(['format']);
  const formatData = (value: any, format?: FormatType) => {
    switch (format) {
      case 'date':
        return t('format:date', {
          value: value,
          key: 'date',
        });
      case 'datetime':
        return t('format:datetime', {
          value: value,
          key: 'datetime',
        });
      case 'number':
        if (typeof value === 'number' || typeof value === 'string') return t('format:number', { value: value });
        else return value;
      case 'percent':
        if (typeof value === 'number') {
          return `${t('format:number', { value: value })}%`;
        } else {
          return value;
        }
      case 'code':
        const status = value.split('.');

        return status[1] === 'undefined' || status[1] === 'null' ? '-' : value;
      default:
        return value;
    }
  };

  return (
    <div className={`col-${col} ${hidden ? 'd-none' : ''}`} style={wrapperStyle}>
      <div className="information-form__label">
        {`${label} ${subLabel}`}
        {hasStatusDescription && <QuestionButton onClick={showStatusDescriptionFunc} />}
        {labelChildren}
      </div>
      <div className="d-flex">
        <div className={className}>
          {value?.toString() ? formatData(value, format) : tableValueManage(value)}
          {subLabel && <>{` (${subValue?.toString() ? formatData(subValue, format) : tableValueManage(subValue)})`}</>}
        </div>
        {children}
      </div>
    </div>
  );
};
