import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import NavigationGuard from 'components/stateless/NavigationGuard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import WithProvider from 'utils/render';

import useRegistrationController from './useRegistrationController';
import AgreementProvider from '../models/agreement/Provider';
import DivisionsProvider from '../models/divisions/Context';
import ExtraInformationProvider from '../models/extraInformation/Context';
import AnchorCompany from '../sections/common/AnchorCompany';
import Financier from '../sections/common/Financier';
import FinancingLimitOfPartners from '../sections/common/FinancingLimitOfPartners';
import GeneralInformation from '../sections/common/GeneralInformation';
import LoadAgreement from '../sections/common/LoadAgreement';
import AutomaticFinancing from '../sections/dealer/AutomaticFinancing';
import InvoicePayment from '../sections/dealer/InvoicePayment';
import InvoiceRegistration from '../sections/dealer/InvoiceRegistration';
import ARRegistration from '../sections/vendor/ARRegistration';
import ARSettlement from '../sections/vendor/ARSettlement';

const AnchorAgreementRegistration = () => {
  const { t } = useTranslation(['format']);

  const {
    isInvoice,
    useAgreementInterface,
    isRegistrationRequestButtonDisabled,
    isEditableStyle,
    ignoreNavigationGuard,
    activatedNavigationGuard,
    GuideMessageText,
    methods,
    getReadOnlyValue,
    handleSaveButtonClick,
    handleRegisterButtonClick,
    handleCancelButtonClick,
  } = useRegistrationController();

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Master_Agreement_Registration')} />

      <GuideMessage message={GuideMessageText} />

      <Form methods={methods} isEditable={true} isEditableStyle={isEditableStyle} getReadOnlyValue={getReadOnlyValue}>
        {useAgreementInterface && (
          <div className="content-area">
            <SectionTitle title={t('text:Load_Agreement_from_Financier_System')} />
            <LoadAgreement />
          </div>
        )}

        <div className="content-area">
          <SectionTitle title={t('text:Contracting_Parties')} />
          <Border isEditable={isEditableStyle}>
            <Financier />
            <AnchorCompany />
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle title={t('text:General_Information')} />
          <Border isEditable={isEditableStyle}>
            <GeneralInformation />
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle
            title={isInvoice ? t('text:Invoice_Registration_and_Payment') : t('text:AR_Registration_and_Settlement')}
          />
          <Border isEditable={isEditableStyle}>
            {isInvoice ? (
              <>
                <InvoiceRegistration />
                <InvoicePayment />
              </>
            ) : (
              <>
                <ARRegistration />
                <ARSettlement />
              </>
            )}
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle title={t('text:Financing_Terms_and_Conditions_for_Partners')} />
          <Border isEditable={isEditableStyle}>
            <FinancingLimitOfPartners />
            {isInvoice && <AutomaticFinancing />}
          </Border>
        </div>
      </Form>

      <div className={`content-area ${useAgreementInterface ? 'flex-end' : 'd-flex'}`}>
        <Button
          className={useAgreementInterface ? 'me-2' : 'me-auto'}
          size={ButtonSizeEnum.LG}
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
          onClick={handleCancelButtonClick}
        >
          {t('text:Cancel')}
        </Button>
        {!useAgreementInterface && (
          <Button
            size={ButtonSizeEnum.LG}
            onClick={handleSaveButtonClick}
            variant={ButtonVariantEnum.OUTLINED}
            className="me-2"
          >
            {t('text:Save')}
          </Button>
        )}
        <Button
          size={ButtonSizeEnum.LG}
          onClick={handleRegisterButtonClick}
          disabled={isRegistrationRequestButtonDisabled}
        >
          {t('text:Registration_Request')}
        </Button>
      </div>
      <NavigationGuard
        when={activatedNavigationGuard}
        ignore={ignoreNavigationGuard}
        useTwoButtons
        confirmBtnText={t('text:OK')}
        cancelBtnText={t('text:Close')}
        buttonSize={ButtonSizeEnum.MD}
      >
        <h6>
          {t('text:Are_you_sure_you_want_to_leave_this_page?')}
          <br />
          {t('text:Please_note_that_all_unsaved_changes_will_be_lost_if_you_leave_this_page_without_saving')}
        </h6>
      </NavigationGuard>
    </>
  );
};

export default WithProvider({
  Component: AnchorAgreementRegistration,
  Provider: [AgreementProvider, DivisionsProvider, ExtraInformationProvider],
});
