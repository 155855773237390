import { useTranslation } from 'react-i18next';

function ShowPaymentDateGuideModal() {
  const { t } = useTranslation();

  return (
    <h6>
      {t('text:Only_the_invoices_that_are_paid_on_the_current_date_can_be_registered_to_the_platform')}
      <br />
      {t(
        'text:The_invoice_payment_date_that_is_registered_will_be_the_current_local_date_of_the_platforms_region_at_the_time_of_the_invoice_registration_confirmation',
      )}
    </h6>
  );
}

export default ShowPaymentDateGuideModal;
