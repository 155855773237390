import React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { PurposeOfLoanVOModel } from 'models/vo/PurposeOfLoanVO';

import { useFinancierFinancingDetailPurposeOfFinancingListLogics } from './logics';

interface FinancierFinancingDetailPurposeOfFinancingListProps {
  purposeListData: PurposeOfLoanVOModel[];
  totalPurposeOfLoanAmount: number;
}

function FinancierFinancingDetailPurposeOfFinancingList({
  purposeListData,
  totalPurposeOfLoanAmount,
}: FinancierFinancingDetailPurposeOfFinancingListProps) {
  const { t } = useTranslation(['format']);
  const { onClickDownloadAccountFile, onClickDownloadPurposeFile } =
    useFinancierFinancingDetailPurposeOfFinancingListLogics();

  const PURPOSE_TABLE_HEADERS: HeaderType[] = [
    { headerText: t('text:Purpose'), colWidths: 150 },
    { headerText: t('text:Amount'), colWidths: 120 },
    { headerText: t('text:Disbursement_Account'), colWidths: 210 },
    { headerText: t('text:Bank_Code'), colWidths: 120 },
    { headerText: t('text:Bank_Name'), colWidths: 120 },
    { headerText: t('text:Bank_Account_Number'), colWidths: 120 },
    { headerText: t('text:Bank_Account_Holder'), colWidths: 120 },
  ];

  const renderTableBody = () => {
    return purposeListData?.map(item => {
      return (
        <React.Fragment key={item.purposeOfLoanId}>
          <Tr>
            <Td data={t(`code:purpose-type.${item.purposeType}`)} format="code" />
            <Td data={item.purposeAmount} format="number" />
            <Td data={t(`code:disbursement-account-type.${item.disbursementAccountType}`)} format="code" />
            <Td data={item.bankCode} />
            <Td data={item.bankName} />
            <Td data={item.account} />
            <Td data={item.accountOwner} />
          </Tr>
          <Tr>
            <td colSpan={7}>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div className="attach-file-link-button-disable me-3">{t('text:Account_Doc')}</div>
                  <label htmlFor="purposeOfLoanListAccountAttachment" className="attach-file-link-button-disable">
                    {t('text:Attached_File')}
                  </label>
                  <div className="align-self-center ms-3">
                    {item.accountAttachmentName ? item.accountAttachmentName : t('text:No_file_attached')}
                  </div>
                </div>
                <DownloadButton
                  disabled={!item.accountAttachmentId}
                  onClick={e => onClickDownloadAccountFile(e, item.purposeOfLoanId)}
                />
              </div>
            </td>
          </Tr>
          <Tr>
            <td colSpan={7}>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div className="attach-file-link-button-disable me-3">{t('text:Purpose_Doc')}</div>
                  <label htmlFor="purposeOfLoanListAccountAttachment" className="attach-file-link-button-disable">
                    {t('text:Attached_File')}
                  </label>
                  <div className="align-self-center ms-3">
                    {item.purposeAttachmentName ? item.purposeAttachmentName : t('text:No_file_attached')}
                  </div>
                </div>
                <DownloadButton
                  disabled={!item.purposeAttachmentId}
                  onClick={e => onClickDownloadPurposeFile(e, item.purposeOfLoanId)}
                />
              </div>
            </td>
          </Tr>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Purpose_of_Financing')} />
      <TableBorder>
        <TableHeader header={PURPOSE_TABLE_HEADERS} />
        <TableBody numOfCol={PURPOSE_TABLE_HEADERS.length}>{renderTableBody()}</TableBody>
      </TableBorder>
      {purposeListData?.length !== 0 && (
        <div className="grid-total">
          {t('text:Total')} : {t('format:number', { value: totalPurposeOfLoanAmount })}
        </div>
      )}
    </div>
  );
}

export default FinancierFinancingDetailPurposeOfFinancingList;
