import type { ExcelJsStyleKey, ExcelStylePropertyType, MergedCellTypes } from '../types';
import type { Borders, Style as ExcelJsStyle, Worksheet } from 'exceljs';

type BorderKey = 'top' | 'left' | 'bottom' | 'right';

const DEFAULT_BG_COLOR = 'FFF5F5F5';

export const borderStyle = (color: string, omitSides?: BorderKey[]): Partial<Borders> => {
  const thinBorderStyle = { style: 'thin', color: { argb: color } };

  const border = {
    top: thinBorderStyle,
    left: thinBorderStyle,
    bottom: thinBorderStyle,
    right: thinBorderStyle,
  };

  if (omitSides) {
    omitSides.forEach(side => {
      if (border.hasOwnProperty(side)) {
        delete border[side];
      }
    });
  }

  return border as Partial<Borders>;
};

export const excelDefaultHeaderStyle = (isBulkImport: boolean = false): Partial<ExcelJsStyle> => ({
  fill: {
    gradient: undefined,
    pattern: 'solid',
    type: 'pattern',
    fgColor: { argb: isBulkImport ? 'E2EFDA' : DEFAULT_BG_COLOR },
    center: { left: 0, top: 0 },
  },
  font: {
    bold: true,
    size: 11,
  },
  border: borderStyle('000000'),
  alignment: { vertical: 'middle' },
});

export const excelBulkImportErrorStyle = (isHeader: boolean = false): Partial<ExcelJsStyle> => ({
  fill: {
    gradient: undefined,
    pattern: 'solid',
    type: 'pattern',
    fgColor: { argb: 'FCE4D6' },
  },
  font: {
    bold: isHeader,
    size: 11,
  },
  border: borderStyle('000000'),
  alignment: isHeader ? { vertical: 'middle' } : {},
});

export const updateStyleProperties = (values: ExcelStylePropertyType): Partial<ExcelJsStyle> => {
  const baseStyle = excelDefaultHeaderStyle();
  const updatedStyle: Partial<ExcelJsStyle> = { ...baseStyle };

  for (const propertyToUpdate of Object.keys(values) as ExcelJsStyleKey[]) {
    updatedStyle[propertyToUpdate] = Object.assign({}, baseStyle[propertyToUpdate], values[propertyToUpdate]);
  }

  return updatedStyle;
};

export const setEtcInsertStyle = (style?: Partial<ExcelJsStyle>, applyDefaultStyle = true) => {
  if (applyDefaultStyle) {
    return !style ? excelDefaultHeaderStyle() : updateStyleProperties(style);
  } else {
    return style;
  }
};

const createCellAddresses = (target: string, rowIndex: number) => {
  const [startCol, endCol] = target.split(':');

  return [`${startCol}${rowIndex}`, `${endCol}${rowIndex}`];
};

const isLessOrEqual = (currentCol: string, endCol: string) => {
  if (currentCol.length === 1 && endCol.length === 2) {
    return true; // 한 글자는 두 글자보다 항상 앞섭니다 (예: 'A' < 'AA').
  }

  return currentCol <= endCol; // 같거나 알파벳 순으로 이전인 경우
};

const nextColumn = (col: string) => {
  //알파벳 순으로 for문을 순회하기 위한 함수
  //'Z'다음부터 앞에 'A'를 붙여서 'A'+문자열로 넘어가도록 처리
  if (col === 'Z') {
    return 'AA'; // 'Z' 다음은 'AA'
  } else if (col.length === 2) {
    return 'A' + String.fromCharCode(col.charCodeAt(1) + 1); // 두 번째 문자 증가 ('AA' 다음은 'AB')
  } else {
    return String.fromCharCode(col.charCodeAt(0) + 1); // 한 글자일 때 문자 증가 ('A' 다음은 'B')
  }
};

const applyStyleToRow = (worksheet: Worksheet, target: string, rowIndex: number, style: Partial<ExcelJsStyle>) => {
  const [startCol, endCol] = target.split(':');
  for (let col = startCol; isLessOrEqual(col, endCol); col = nextColumn(col)) {
    const cellAddress = `${col}${rowIndex}`;
    const cell = worksheet.getCell(cellAddress);
    cell.style = style;
  }
};

export const applyMergedCellStyles = (worksheet: Worksheet, mergedCells: MergedCellTypes[]) => {
  mergedCells.forEach(cell => {
    const cellStyle = cell.style ? updateStyleProperties(cell.style) : excelDefaultHeaderStyle();
    const [startCell, endCell] = createCellAddresses(cell.target, cell.rowIndex);

    worksheet.mergeCells(`${startCell}:${endCell}`);
    worksheet.getCell(startCell).value = cell.value;

    applyStyleToRow(worksheet, cell.target, cell.rowIndex, cellStyle);
    applyStyleToRow(worksheet, cell.target, cell.rowIndex + 1, cellStyle);
  });
};
