import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import ChangePasswordModal from 'components/stateless/Modal/common/ChangePasswordModal';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestCurrentUser,
  requestCurrentUserChangeInfo,
  requestCurrentUserResetPassword,
} from 'utils/http/api/common/users';
import type { CurrentUserChangeInfoRequest, CurrentUserChangePassword } from 'utils/http/api/common/users/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function SystemMyAccount() {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const [currentUserInfo, setCurrentUserInfo] = useState<UserVOModel>();
  const getProperty = useProperty<CurrentUserChangeInfoRequest>();
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    if (mounted) {
      fetchCurrentUser();
    }
  }, [mounted]);

  const { register, reset, getValues, errors, setError, clearErrors } = useForm<CurrentUserChangeInfoRequest>({
    mode: 'onSubmit',
    shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
  });

  async function fetchCurrentUser() {
    try {
      const currentUserInfo = await requestCurrentUser();
      ReactDOM.unstable_batchedUpdates(() => {
        setIsEditable(false);
        setCurrentUserInfo(currentUserInfo);
        resetCurrentUserInfoData(currentUserInfo);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const resetCurrentUserInfoData = (data: UserVOModel | undefined) => {
    reset({
      userName: data?.userName,
      telephone: data?.telephone,
      email: data?.email,
    });
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setIsEditable(false);
    resetCurrentUserInfoData(currentUserInfo);
  };

  const onSubmitUserChangeForm = async (e: any) => {
    e.preventDefault();
    const data = getValues();
    try {
      requestDTOParser(data);
      await requestCurrentUserChangeInfo(data);
      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        closeBtnCb: () => fetchCurrentUser(),
      });
    } catch (e: any) {
      modal.show(e);
      formErrorHandler(e, setError, clearErrors);
    }
  };

  const onSubmitChangePasswordForm = async (data: CurrentUserChangePassword) => {
    try {
      await requestCurrentUserResetPassword(data);
      modal.show(<h6>{t('text:Password_successfully_changed')}</h6>, {
        closeBtnCb: () => fetchCurrentUser(),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const showChangePasswordModal = () => {
    modal.show(<ChangePasswordModal state={{} as CurrentUserChangePassword} modalId={modal.id} />, {
      modalType: ModalType.CONFIRM,
      title: t('text:Change_password'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: (data: { state: CurrentUserChangePassword }) => {
        onSubmitChangePasswordForm(data.state);
      },
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:My_Account')} />
      <div className="content-area">
        <SectionTitle title={t('text:User_Information')}>
          {isEditable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={handleCancel}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={onSubmitUserChangeForm} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <Button size={ButtonSizeEnum.SM} onClick={handleEdit} style={{ width: '60px' }}>
              {t('text:Edit')}
            </Button>
          )}
        </SectionTitle>
        <FormBorder editable={isEditable}>
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:User_Name')}
                name={getProperty('userName')}
                ref={register}
                disabled={!isEditable}
                error={errors.userName}
                requiredOptions={{ required: true }}
              />
              <FormInput
                label={t('text:Telephone')}
                name={getProperty('telephone')}
                ref={register}
                disabled={!isEditable}
                error={errors.telephone}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Email')}
                name={getProperty('email')}
                ref={register}
                disabled={!isEditable}
                error={errors.email}
                requiredOptions={{ required: true }}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Account_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue className="information-form__input" label={t('text:ID')} value={currentUserInfo?.loginId} />
              <FormValue className="information-form__input" label={t('text:Password')} value="●●●●●●●●">
                <Button onClick={showChangePasswordModal} bold>
                  {t('text:Change_password')}
                </Button>
              </FormValue>
            </div>
            <div className="row">
              <FormValue
                className={`information-form__input ${getStatusTextClass('USER_STATUS', currentUserInfo?.userStatus)}`}
                label={t('text:Account_Status')}
                value={t(`code:user-status.${currentUserInfo?.userStatus}`)}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default SystemMyAccount;
