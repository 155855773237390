import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVO, AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { formattingToAnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { DealerAnchorAgreementListForArsRequest } from './request';

export async function requestDealerAnchorAgreementListForArs(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerAnchorAgreementListForArsRequest,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_DE.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_LIST_FOR_ARS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  const anchorAgreementListForArs = response.content.map(data => formattingToAnchorAgreementVOModel(data));

  return {
    ...response,
    content: anchorAgreementListForArs,
  };
}

// 앵커 기업 약정 단일 조회
export async function requestDealerAnchorAgreementDetail(
  anchorAgreementId: number,
): Promise<AnchorAgreementDetailVOModel> {
  const response = await http.get<AnchorAgreementDetailVO>({
    url: API_DE.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_DETAIL(anchorAgreementId),
  });

  return formattingToAnchorAgreementDetailVOModel(response);
}
