import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import type { Dayjs } from 'dayjs';

export enum TimeUnit {
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
}

interface PropsType {
  timeValue: number;
  isRun: boolean;
  timeUnit?: TimeUnit;
  onFinish?: Function;
}

interface TimerState {
  currentTime: Dayjs;
  targetTime: Dayjs;
}

function Timer({ timeValue, isRun, timeUnit = TimeUnit.MINUTE, onFinish = () => {} }: PropsType): JSX.Element {
  const [timerState, setTimerState] = useState<TimerState>({
    currentTime: dayjs(),
    targetTime: dayjs().add(timeValue, timeUnit),
  });
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const timeFormat = (time: number) => {
    const sideHour = time % 3600;
    let m = Math.floor(sideHour / 60).toString();
    const sideMinute = sideHour % 60;
    let s = (sideMinute % 60).toString();
    if (m.length === 1) m = `0${m}`;
    if (s.length === 1) s = `0${s}`;

    return `${m}:${s}`;
  };

  useEffect(() => {
    if (isRun) {
      setTimerState({
        currentTime: dayjs(),
        targetTime: dayjs().add(timeValue, timeUnit),
      });

      const timer = setInterval(() => {
        setTimerState(prevState => {
          return {
            ...prevState,
            currentTime: dayjs(),
          };
        });
      }, 100);

      setTimer(timer);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isRun]);

  useEffect(() => {
    if (timer && timerState.currentTime.isAfter(timerState.targetTime)) {
      clearInterval(timer);
      onFinish();
    }
  }, [timerState]);

  return (
    <span
      style={{
        position: 'absolute',
        right: '9px',
        top: '8px',
        color: '#ff0000',
        fontSize: '10px',
      }}
    >
      {timeFormat(timerState.targetTime.diff(timerState.currentTime, 'second'))}
    </span>
  );
}

export default Timer;
