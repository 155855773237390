import type Pageable from 'models/Pageable';
import { formattingToDeletedArVOModel } from 'models/vo/DeletedArVO';
import type { DeletedArVO, DeletedArVOModel } from 'models/vo/DeletedArVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierDeletedARsListRequest } from './requests';

export async function requestFinancierDeletedArsList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierDeletedARsListRequest,
): Promise<Pageable<DeletedArVOModel[]>> {
  const response = await http.get<Pageable<DeletedArVO[]>>({
    url: API_FI.DELETED_ARS.DELETED_ARS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return { ...response, content: response.content.map((data: DeletedArVO) => formattingToDeletedArVOModel(data)) };
}
