import { LOAN_TRANSACTION_TYPE, stringToEnum } from 'enums';

export interface LoanTransactionVO {
  loanId: number;
  loanTransactionId: number;
  financierTransactionId: string;
  transactionDateTime: string;
  loanTransactionType: string;
  transactionAmount: number;
  remainingPrincipalAmount: number;
}

export interface LoanTransactionVOModel extends LoanTransactionVO {
  loanTransactionType: LOAN_TRANSACTION_TYPE;
}

export function formattingToLoanTransactionVOModel(data: LoanTransactionVO): LoanTransactionVOModel {
  return {
    ...data,
    loanTransactionType: stringToEnum(LOAN_TRANSACTION_TYPE, data.loanTransactionType),
  };
}
