import type React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash-es';

import Button from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import { FormSubtitle } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { TableBody, TableBorder, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import type {
  AnchorMultipleLoanCalculateSuccessArsRequest,
  AnchorMultipleLoanSuccessArsRequest,
} from 'utils/http/api/anchor/multiple-request-anchor-loans/requests';
import useModal from 'utils/modal/useModal';

import { useFinancingRequest } from './logics';
import QuestionTooltip from '../../../../../../../components/stateless/Tooltip/QuestionTooltip';

import type { AnchorArBulkFinancingApplicationStep2State } from '../../useAnchorArBulkFinancingApplicationStep2State';

interface FinancingRequestProps {
  anchorArBulkApplicationStep2State: AnchorArBulkFinancingApplicationStep2State;
  fetchAnchorArBulkCalculateLoanRequest: (data: AnchorMultipleLoanCalculateSuccessArsRequest) => Promise<void>;
  totalRequestedAmount?: BigNumber;
  arListTableRef: React.RefObject<HTMLDivElement>;
  disbursementDateRef: React.RefObject<HTMLDivElement>;
  handleCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  getCheckedLoanByArRequestList: () => {
    index: number;
    successArId: number;
  }[];
  estimateButtonRef: React.RefObject<HTMLDivElement>;
  initializeFinancingConditionInfo: () => void;
}

function FinancingRequest({
  anchorArBulkApplicationStep2State,
  totalRequestedAmount = '0',
  fetchAnchorArBulkCalculateLoanRequest,
  disbursementDateRef,
  arListTableRef,
  handleCheckAll,
  handleCheckChange,
  getCheckedLoanByArRequestList,
  estimateButtonRef,
  initializeFinancingConditionInfo,
}: FinancingRequestProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { control, register, watch } = useFormContext<AnchorMultipleLoanSuccessArsRequest>();
  const { repaymentDate } = watch();

  const {
    anchorFinancingOptionDetail,
    calculateLoanRequest,
    minDate,
    maxDate,
    checkedArList,
    successArList,
    totalSuccessArListCount,
  } = anchorArBulkApplicationStep2State;

  const {
    disabledRanges,
    disabledEstimateButton,
    returnAmountWithCurrency,
    onChangeApplicationFileName,
    resetApplicationFileName,
    applicationFileName,
    agreementFileName,
    onChangeAgreementFileName,
    resetAgreementFileName,
  } = useFinancingRequest({
    anchorArBulkApplicationStep2State,
    repaymentDate,
    totalRequestedAmount,
  });

  const handleClickEstimateButton = async () => {
    const checkedLoanByArRequestList = getCheckedLoanByArRequestList();

    try {
      await fetchAnchorArBulkCalculateLoanRequest({
        anchorAgreementId: anchorFinancingOptionDetail.anchorAgreementId,
        requestedArs: checkedLoanByArRequestList.map(item => ({
          successArId: Number(item.successArId),
        })),
        repaymentDate,
      });

      const checkedArInfoList = successArList?.filter((_: any, rowIndex: number) => checkedArList.includes(rowIndex));
      const calculatorBigNumber = new CalculatorBigNumber();
      let arAmountSum: BigNumber = '0';

      if (checkedArInfoList?.length !== 0) {
        checkedArInfoList.forEach(arInfo => {
          arAmountSum = calculatorBigNumber.add(arAmountSum).add(arInfo.arAmount).get();
        });
      }
    } catch (e) {
      modal.show(e);
    }
  };

  const renderArTable = (() => {
    const tableHeaders: HeaderType[] = [
      {
        hasCheckBox: true,
        handleClickAllCheckBox: handleCheckAll,
        isAllChecked: successArList?.length !== 0 && checkedArList.length === successArList?.length,
        colWidths: 70,
      },
      {
        headerText: t('text:AP_Number'),
      },
      {
        headerText: t('text:Partner_Name'),
      },
      {
        headerText: t('text:Partner_Tax_Code'),
      },
      {
        headerText: t('text:AP_Amount'),
      },
      {
        headerText: t('text:Issued_Date'),
      },
      {
        headerText: t('text:Settlement_Date'),
      },
    ];

    const renderResultTable = () => {
      return successArList?.map((ar, index) => {
        return (
          <Tr key={index}>
            <td>
              <div className="text-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  onChange={e => handleCheckChange(e, index)}
                  checked={checkedArList.includes(index)}
                />
                <input type="hidden" name={`requestedArs.${index}.successArId`} ref={register} />
              </div>
            </td>
            <Td data={ar.arNumber} />
            <Td data={ar.anchorPartnerName} />
            <Td data={ar.anchorPartnerTaxCode} />
            <Td data={ar.arAmount} format="number" />
            <Td data={ar.arIssuedDate} format="date" />
            <Td data={ar.arSettlementDate} format="date" />
          </Tr>
        );
      });
    };

    return (
      <TableBorder scrollYMaxHeight={570}>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
      </TableBorder>
    );
  })();

  const ExceedsArLimitMessage = () => {
    return (
      <GuideMessage
        className="side-border-none"
        message={[t('text:3000_APs_are_displayed_in_order_of_earliest_Settlement_Date')]}
        useContentAreaClassName={false}
      />
    );
  };

  const FinancingConditionSection = () => {
    return (
      <>
        <div className="detail-title bg-sub100">
          <div className="d-flex w-50 justify-content-between">
            <div className="me-auto">{t('text:Financing_Condition')}</div>
            <div className="align-self-center" ref={estimateButtonRef}>
              <Button
                onClick={handleClickEstimateButton}
                className="flex-column-center text-bold"
                disabled={disabledEstimateButton()}
              >
                {t('text:Estimate')}
              </Button>
            </div>
          </div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Requested_Financing_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(totalRequestedAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Selected_AP_Amount')}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(totalRequestedAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Expected_Interest_Rate') + ' ' + '(%)'}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {calculateLoanRequest?.totalInterestRate} %
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Expected_Interest_Amount')}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(calculateLoanRequest?.expectedTotalInterestAmount)}
              </div>
            </div>
          </div>
          {anchorFinancingOptionDetail.showNetDisbursementAmount && (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="border-bottom-light-gray my-3" />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="information-form__label flex-label">
                    {t('text:Advance_Disbursement')}
                    <QuestionTooltip
                      id="netDisburseAmountTooltip"
                      place="top"
                      contentText={
                        <>
                          <div>{t('text:Principal_Amount')} ⓐ</div>
                          <div>{t('text:Prepayment_Interest')} ⓑ</div>
                          <div>--------------------------------------------------------</div>
                          <div>{t('text:Advance_Disbursement')} ⓒ = ⓐ - ⓑ</div>
                        </>
                      }
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="information-form__input flex-input gray-bold-font">
                    {returnAmountWithCurrency(calculateLoanRequest?.totalNetDisbursementAmount)}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const FinancingAgreementDocumentForm = () => (
    <>
      <FormSubtitle backGroundType={BackGroundType.Gray} title={t('text:Financing_Agreement_Document')} />
      <div className="detail-in-file-upload-form">
        <div className="file-upload">
          <div className="d-flex justify-content-between">
            <input
              type="file"
              id="agreementAttachmentFileId"
              className="d-none"
              name="agreementAttachment"
              ref={register}
              onChange={onChangeAgreementFileName}
            />
            <label htmlFor="agreementAttachmentFileId" className="attach-file-link-button">
              {t('text:Attach_File')}
            </label>
            <div id="agreementFileName" className="upload-file-input">
              {agreementFileName ? agreementFileName : t('text:No_file_attached')}
            </div>
            <IconButton
              onClick={resetAgreementFileName}
              className={`delete-uploaded-excel-button ${!agreementFileName && 'd-none'}`}
              type="button"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );

  const FinancingApplicationDocumentForm = () => (
    <>
      <FormSubtitle backGroundType={BackGroundType.Gray} title={t('text:Financing_Application_Document')} />
      <div className="detail-in-file-upload-form">
        <div className="file-upload">
          <div className="d-flex justify-content-between">
            <input
              type="file"
              id="requestAttachmentFileId"
              className="d-none"
              name="requestAttachment"
              ref={register}
              onChange={onChangeApplicationFileName}
            />
            <label htmlFor="requestAttachmentFileId" className="attach-file-link-button">
              {t('text:Attach_File')}
            </label>
            <div id="applicationFileName" className="upload-file-input">
              {applicationFileName ? applicationFileName : t('text:No_file_attached')}
            </div>
            <IconButton
              onClick={resetApplicationFileName}
              className={`delete-uploaded-excel-button ${!applicationFileName && 'd-none'}`}
              type="button"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <SectionTitle title={t('text:Financing_Request')} />
      <GuideMessage
        isImportContentArea={true}
        message={[
          t('text:Please_select_your_desired_repayment_date'),
          t('text:Choose_the_AP_for_which_you_are_applying_for_financing_and_verify_that_the_information_is_correct'),
          t(
            'text:Once_you_have_selected_the_AP_and_entered_the_Requested_Financing_Amount_press_the_estimate_button_to_check_the_estimated_financing_terms',
          ),
        ]}
      />
      <div className="information-form__detail-form border-bott-none mt-4">
        <div className="detail-title bg-sub100 border-bottom-2px-light-gray">
          <div className="d-flex justify-content-between w-50">
            <div ref={disbursementDateRef}>{t('text:Requested_Repayment_Date')}</div>
            <div className="d-flex align-items-center">
              <Controller
                control={control}
                name="repaymentDate"
                defaultValue={null}
                render={field => (
                  <ReactDatePicker
                    field={field}
                    className="detail-title-input"
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={disabledRanges}
                    onChange={() => initializeFinancingConditionInfo()}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
        </div>
        <div className="detail-title bg-sub100 border-bottom-2px-light-gray">
          <div className="d-flex w-50 justify-content-between">
            <div>{t('text:Total_Requested_Financing_Amount')}</div>
            <div className="d-flex align-items-center">
              {t('format:number', { value: totalRequestedAmount })} {anchorFinancingOptionDetail?.currencyType}
            </div>
          </div>
          {!isNil(anchorFinancingOptionDetail?.maxAgreementLoanCapacityAmount) && (
            <div className="d-flex w-50 justify-content-between">
              <div className="d-flex gap-3">
                <span>≤</span>
                <div>{t('text:Anchor_Financing_Limit')}</div>
                <div>{returnAmountWithCurrency(anchorFinancingOptionDetail?.maxAgreementLoanCapacityAmount)}</div>
              </div>
            </div>
          )}
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="col-3 information-form__label">{t('text:Select_AP_for_Financing_Application')}</div>
          <div ref={arListTableRef} className="mt-4">
            {renderArTable}
          </div>
        </div>
        {totalSuccessArListCount > 500 && <ExceedsArLimitMessage />}
        <FinancingConditionSection />
        {FinancingApplicationDocumentForm()}
        {FinancingAgreementDocumentForm()}
      </div>
    </>
  );
}

export default FinancingRequest;
