import type {
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  CURRENCY_TYPE,
  INTEREST_REPAYMENT_TYPE,
  MULTIPLE_LOAN_APPROVAL_TYPE,
  MULTIPLE_LOAN_REQUEST_STATUS,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';

import type { AnchorLoanEtcAttachmentVO, AnchorLoanEtcAttachmentVOModel } from './AnchorLoanEtcAttachmentVO';

export interface MultipleAnchorLoanRequestDetailVO {
  multipleAnchorLoanRequestId: number;
  anchorAgreementId: number;
  anchorFinancingOptionId: number;
  collateralType: COLLATERAL_TYPE;
  financierId: number;
  financierName: string;
  anchorClientId: number;
  anchorClientName: string;
  currencyType: CURRENCY_TYPE;
  requestedDateTime: string;
  desiredDisburseDate: string;
  originalRepaymentDate: string;
  totalRequestedAmount: string;
  totalCollateralCount: number;
  multipleLoanRequestStatus: MULTIPLE_LOAN_REQUEST_STATUS;
  approvalType: MULTIPLE_LOAN_APPROVAL_TYPE;
  cancelDateTime: string;
  cancelReason: string;
  returnDateTime: string;
  returnReason: string;
  rejectDateTime: string;
  rejectReason: string;
  approvedDateTime: string;
  totalCollateralAmount: string;
  totalInterestAmount: string;
  maxLtvRatio: number;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  showNetDisbursementAmount: boolean;
  totalNetDisbursementAmount: string;
  disbursementAccountFinancierCode: string;
  disbursementAccountFinancierName: string;
  disbursementAccountBranchCode: string;
  disbursementAccountBranchName: string;
  disbursementAccount: string;
  disbursementAccountOwner: string;
  loanRequestAttachmentId: number;
  loanRequestAttachmentName: string;
  loanRequestAttachmentPath: string;
  loanAgreementAttachmentId: number;
  loanAgreementAttachmentName: string;
  loanAgreementAttachmentPath: string;
  createdAnchorUserId: number;
  createdAnchorUserLoginId: string;
  createdAnchorUserAuthorityType: AUTHORITY_TYPE;
  requestedAnchorUserId: number;
  requestedAnchorUserLoginId: string;
  requestedAnchorUserAuthorityType: AUTHORITY_TYPE;
  financierUserId: number;
  financierUserLoginId: string;
  financierUserAuthorityType: AUTHORITY_TYPE;
  anchorLoanEtcAttachment: AnchorLoanEtcAttachmentVO[];
  averageInterestRate: number;
  averageLoanTerm: number;
}

export interface MultipleAnchorLoanRequestDetailVOModel extends MultipleAnchorLoanRequestDetailVO {
  totalRequestedAmount: BigNumber;
  totalCollateralAmount: BigNumber;
  totalInterestAmount: BigNumber;
  totalNetDisbursementAmount: BigNumber;
  anchorLoanEtcAttachment: AnchorLoanEtcAttachmentVOModel[];
}

export function formattingToMultipleAnchorLoanRequestDetailVOModel(
  data: MultipleAnchorLoanRequestDetailVO,
): MultipleAnchorLoanRequestDetailVOModel {
  return {
    ...data,
  };
}
