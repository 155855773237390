import { createContext, useContext } from 'react';

import type { AnchorAgreementDetailVO } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorAgreementVO } from 'models/vo/AnchorAgreementVO';

export type AnchorAgreementInfoType = Pick<
  AnchorAgreementVO,
  | 'financierClientName'
  | 'contractNo'
  | 'startDate'
  | 'expiryDate'
  | 'division'
  | 'divisionName'
  | 'branchCode'
  | 'branchName'
  | 'branchTelephone'
  | 'branchAddress'
  | 'branchFax'
  | 'currencyType'
  | 'collateralIssuedLimitAmount'
  | 'collateralIssuedLimitCheck'
  | 'loanLimitCheck'
  | 'loanLimitAmount'
>;

type AnchorAgreementInfoStateType = {
  anchorAgreementInfo: AnchorAgreementInfoType;
  updateAnchorAgreementInfo: (anchorAgreementInfo?: AnchorAgreementInfoType) => void;
  fetchAnchorAgreementDetail: (anchorAgreementId: number) => Promise<AnchorAgreementDetailVO | undefined>;
  updateAnchorAgreementDetail: (agreement: AnchorAgreementDetailVO) => void;
};

export const AnchorAgreementInfoContext = createContext<AnchorAgreementInfoStateType | null>(null);

export const initialAnchorAgreementInfoState: Partial<AnchorAgreementInfoType> = {
  financierClientName: '',
  contractNo: '',
  startDate: '',
  expiryDate: '',
  division: '',
  divisionName: '',
  branchCode: '',
  branchName: '',
  branchTelephone: '',
  branchAddress: '',
  branchFax: '',
  currencyType: undefined!,
  collateralIssuedLimitAmount: '',
  collateralIssuedLimitCheck: undefined!,
  loanLimitCheck: undefined!,
  loanLimitAmount: '',
};

export const useAnchorAgreementInfoContext = () => {
  const context = useContext(AnchorAgreementInfoContext);
  if (!context) {
    throw Error('useAnchorAgreementInfoContext should be used within AnchorAgreementInfoContext.Provider');
  }

  return context;
};
