import { useTranslation } from 'react-i18next';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';

import useAssociatedAnchorMasterAgreementController from './useAssociatedAnchorMasterAgreementController';

const AssociatedAnchorMasterAgreement = () => {
  const { t } = useTranslation();

  const {
    register,
    agreement: { division, divisionName },
    anchorAgreementInfo: { anchorAgreementNo, anchorClientName, anchorAgreementStartDate, anchorAgreementExpiryDate },
    divisionRegistrable,
    isSearchUploadedPartnerVisible,
    isSearchAnchorAgreementButtonVisible,
    isSearchUploadedPartnerButtonDisabled,
    isMatchedWithUploadedPartner,
    handleSearchUploadedPartnerClick,
    handleSearchAnchorAgreementClick,
  } = useAssociatedAnchorMasterAgreementController();

  return (
    <>
      <SubTitle>{t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT')}</SubTitle>
      <Content>
        <input type="hidden" name="dealerAgreementType" ref={register} />
        <Row singleColumnPosition={isSearchAnchorAgreementButtonVisible ? 'bottom' : 'all'}>
          <Form.Label required={isSearchAnchorAgreementButtonVisible}>{t('text:Anchor_Master_Agreement')}</Form.Label>
          {isSearchAnchorAgreementButtonVisible && (
            <Button size={ButtonSizeEnum.SM} bold onClick={handleSearchAnchorAgreementClick}>
              {t('text:Search_Agreement')}
            </Button>
          )}
          <Border>
            <Content isInnerStyle>
              <input type="hidden" name="anchorAgreementId" ref={register} />
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Anchor_Master_Agreement_Number')}</Form.Label>
                  <Form.Value value={anchorAgreementNo} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Anchor_Name')}</Form.Label>
                  <Form.Value value={anchorClientName} />
                </Form.Control>
              </Row>
              {divisionRegistrable && (
                <Row>
                  <Form.Control>
                    <Form.Label>{t('text:Division')}</Form.Label>
                    <Form.Value value={division ? [division, divisionName].join(' / ') : ''} />
                  </Form.Control>
                </Row>
              )}
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Effective_Date')}</Form.Label>
                  <Form.Value value={anchorAgreementStartDate} format="date" />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Expiration_Date')}</Form.Label>
                  <Form.Value value={anchorAgreementExpiryDate} format="date" />
                </Form.Control>
              </Row>
            </Content>
          </Border>
        </Row>
        {isSearchUploadedPartnerVisible && (
          <Row>
            <Cell>
              <Form.Label required>{t('text:Search_Uploaded_Partner')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Click_on_the_button_to_confirm_that_the_selected_Partner_company_matches_the_Uploaded_Partner_information_registered_by_the_Anchor',
                )}
              </Form.Description>
            </Cell>
            <div>
              <input type="hidden" name="anchorPartnerAccountId" ref={register} />
              <Button
                size={ButtonSizeEnum.SM}
                bold
                onClick={handleSearchUploadedPartnerClick}
                disabled={isSearchUploadedPartnerButtonDisabled}
              >
                {t('text:Search_Uploaded_Partner')}
              </Button>
              {isMatchedWithUploadedPartner && (
                <>
                  <FontAwesomeIcon icon={faCheckCircle} className="checked-icon-green ms-2" />
                  <span className="checked-icon-green ms-2">{t('text:Checked')}</span>
                </>
              )}
            </div>
          </Row>
        )}
      </Content>
    </>
  );
};

export default AssociatedAnchorMasterAgreement;
