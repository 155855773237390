import { FINANCIER_INTERFACE_TYPE, stringToEnum } from 'enums';

export interface FinancierInterfaceSettingVO {
  financierInterfaceType: string;
  enabled: boolean;
}

export interface FinancierInterfaceSettingVOModel extends FinancierInterfaceSettingVO {
  financierInterfaceType: FINANCIER_INTERFACE_TYPE;
}

export function formattingToFinancierInterfaceSettingVOModel(
  data: FinancierInterfaceSettingVO,
): FinancierInterfaceSettingVOModel {
  return {
    ...data,
    financierInterfaceType: stringToEnum(FINANCIER_INTERFACE_TYPE, data.financierInterfaceType),
  };
}
