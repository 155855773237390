import type Pageable from 'models/Pageable';
import type {
  WaitingAnchorAgreementDetailVO,
  WaitingAnchorAgreementDetailVOModel,
} from 'models/vo/WaitingAnchorAgreementDetailVO';
import { formattingToWaitingAnchorAgreementDetailVOModel } from 'models/vo/WaitingAnchorAgreementDetailVO';
import type { WaitingAnchorAgreementVO, WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import { formattingToWaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemWaitingAnchorAgreementListRequest } from './requests';

export async function requestSystemWaitingAnchorAgreementList(
  pageNumber: number,
  rowCount: number,
  data: SystemWaitingAnchorAgreementListRequest,
): Promise<Pageable<WaitingAnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorAgreementVO[]>>({
    url: API_SY.WAITING_ANCHOR_AGREEMENTS.WAITING_ANCHOR_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToWaitingAnchorAgreementVOModel(item)),
  };
}

export async function requestSystemWaitingAnchorAgreementData(
  waitingAnchorAgreementId: number,
): Promise<WaitingAnchorAgreementDetailVOModel> {
  const response = await http.get<WaitingAnchorAgreementDetailVO>({
    url: API_SY.WAITING_ANCHOR_AGREEMENTS.WAITING_ANCHOR_AGREEMENT_DETAIL(waitingAnchorAgreementId),
  });

  return formattingToWaitingAnchorAgreementDetailVOModel(response);
}
