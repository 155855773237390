import { useTranslation } from 'react-i18next';

import './CustomTooltip.scss';

import type { TooltipProps } from 'recharts';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

export default function CustomTooltip(
  props: TooltipProps<ValueType, NameType> & { mainLabelKey: string | number } & {
    additionalPayLoad: Array<{ name: string | number; key: string | number }> | undefined;
  },
) {
  const { t } = useTranslation(['format']);
  const { active, payload, mainLabelKey, additionalPayLoad } = props;

  const formatValue = (value: ValueType | undefined) => {
    if (typeof value === 'boolean') {
      return '';
    }

    const datePattern = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;

    if (datePattern.test(value as string)) {
      return t('format:date', {
        value,
        key: 'date',
      });
    }

    if (typeof Number(value) === 'number' && !isNaN(Number(value))) {
      return t('format:number', { value: value });
    }

    return value;
  };

  if (active && payload && payload.length) {
    let tooltipPayload = payload;

    if (additionalPayLoad) {
      const mappingPayload = additionalPayLoad.map(item => ({ ...item, value: payload[0].payload[item.key] }));

      tooltipPayload = [...payload, ...mappingPayload];
    }

    return (
      <div className="custom-tooltip">
        {mainLabelKey && <p className="main-label">{`${formatValue(payload[0].payload[mainLabelKey])}`}</p>}
        {tooltipPayload.map((item, index) => {
          return (
            <div className="label-wrapper" key={`${item.name}-${index}`}>
              <p className="label me-4">{`${item.name}`}</p>
              <p className="label">{`${formatValue(item.value)}`}</p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}
