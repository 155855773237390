import { BASIS_INTEREST_TYPE, CURRENCY_TYPE, stringToEnum } from 'enums';

export interface BasisInterestVO {
  basisInterestId: number;
  basisInterestType: string;
  basisInterestBankCode: string;
  basisInterestTerm: string;
  basisInterestRate: number;
  basisInterestDate: string;
  financierEntId: number;
  createdDateTime: string;
  currencyType: string;
}

export interface BasisInterestVOModel extends BasisInterestVO {
  basisInterestType: BASIS_INTEREST_TYPE;
  currencyType: CURRENCY_TYPE;
}

export function formattingToBasisInterestVOModel(data: BasisInterestVO): BasisInterestVOModel {
  return {
    ...data,
    basisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.basisInterestType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
