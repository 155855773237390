import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { ANCHOR_DEALER_STATUS, AUTHORITY_TYPE } from 'enums';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { getSignIn } from 'utils/storage/LocalStorage';

type AnchorDealerRegistrationResultModalPropsType = {
  requestResult: WaitingAnchorDealerVOModel[];
  isFinancier?: boolean; // anchor: dealer, financier: uploaded dealer -> 용어 차이 때문에 prop 추가
};

function AnchorDealerRegistrationResultModal({
  requestResult,
  isFinancier = false,
}: AnchorDealerRegistrationResultModalPropsType): JSX.Element {
  const { t } = useTranslation(['format']);
  const currentAuthorityType = getSignIn()?.authorityType;
  const tableHeaders: HeaderType[] = [
    {
      headerText: isFinancier ? t('text:Uploaded_Dealer_Name') : t('text:Dealer_Name'),
    },
    {
      headerText: isFinancier ? t('text:Uploaded_Dealer_Tax_Code') : t('text:Dealer_Tax_Code'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Registration_Result'),
    },
  ];

  const renderMessage = (): ReactNode | null => {
    if (isFinancier) {
      return (
        <h6>
          {t('text:The_registration_request_has_been_submitted_for_confirmation')}
          <br />
          {t('text:Please_check_the_result_of_the_Uploaded_Dealer_registration_below')}
          <br />
          {t(
            'text:Only_successfully_requested_Uploaded_Dealer_Information_will_be_submitted_to_the_Authorizer_for_registration_confirmation',
          )}
        </h6>
      );
    } else {
      if (currentAuthorityType === AUTHORITY_TYPE.ADMIN || currentAuthorityType === AUTHORITY_TYPE.AUTHORIZER) {
        return (
          <h6>
            {t('text:The_registration_has_been_completed')}
            <br />
            {t('text:Please_check_the_result_of_the_Dealer_registration_below')}
          </h6>
        );
      } else if (
        currentAuthorityType === AUTHORITY_TYPE.HQ_OPERATOR ||
        currentAuthorityType === AUTHORITY_TYPE.OPERATOR
      ) {
        return (
          <h6>
            {t('text:The_registration_request_has_been_submitted_for_confirmation')}
            <br />
            {t('text:Please_check_the_result_of_the_Dealer_registration_below')}
            <br />
            {t(
              'text:Only_successfully_requested_Dealer_information_will_be_submitted_to_the_Admin_or_Authorizer_for_registration_confirmation',
            )}
          </h6>
        );
      } else {
        return null;
      }
    }
  };

  const getAnchorDealerStatusText = (status: ANCHOR_DEALER_STATUS) => {
    switch (status) {
      case ANCHOR_DEALER_STATUS.REGISTERED:
        return <td className="text-bold-blue-main600">{t('text:Pass')}</td>;
      case ANCHOR_DEALER_STATUS.ALREADY_REGISTERED_ANCHOR_DEALER:
        return <td className="text-red">{t('text:Fail_The_tax_code_already_exists')}</td>;
      case ANCHOR_DEALER_STATUS.ON_APPROVAL_PROGRESS_ANCHOR_DEALER:
        return (
          <td className="text-red">{t('text:Fail_The_tax_code_has_been_registered_and_is_waiting_to_be_approved')}</td>
        );

      default:
        return <td>{status}</td>;
    }
  };

  const totalNumberOfSuccessfullyRegisteredCompanies = (): number => {
    return requestResult.filter(({ anchorDealerStatus }) => anchorDealerStatus === ANCHOR_DEALER_STATUS.REGISTERED)
      .length;
  };

  return (
    <>
      {renderMessage()}
      <br />
      <ul className="invoice-registration-sum">
        <li>
          • {t('text:Total_Number_of_Registration_Requested')} :{' '}
          <strong>{t('format:number', { value: totalNumberOfSuccessfullyRegisteredCompanies() })}</strong>
        </li>
      </ul>
      <br />
      <TableBorder>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>
          {requestResult.map((item, index) => (
            <Tr key={index}>
              <Td data={item.name} />
              <Td data={item.taxCode} />
              <Td data={item.representativeName} />
              <Td data={item.representativeEmail} />
              <Td data={item.telephone} />
              <Td data={item.address} />
              {getAnchorDealerStatusText(item.anchorDealerStatus)}
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
    </>
  );
}

export default AnchorDealerRegistrationResultModal;
