import type {
  FinancierClientAuthSettingVO,
  FinancierClientAuthSettingVOModel,
} from 'models/vo/FinancierClientAuthSettingVO';
import { formattingToFinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

// 인증 설정 정보 조회
export async function requestDealerClientAuthByDealerAgreementId(
  dealerAgreementId: number,
): Promise<FinancierClientAuthSettingVOModel> {
  const response = await http.get<FinancierClientAuthSettingVO>({
    url: API_DE.CLIENT_AUTH_SETTING.CLIENT_AUTH_BY_DEALER_AGREEMENT_ID,
    data: {
      'dealer-agreement-id': dealerAgreementId,
    },
  });

  return formattingToFinancierClientAuthSettingVOModel(response);
}

export async function requestDealerClientAuthByAnchorAgreementId(
  anchorAgreementId: number,
): Promise<FinancierClientAuthSettingVOModel> {
  const response = await http.get<FinancierClientAuthSettingVO>({
    url: API_DE.CLIENT_AUTH_SETTING.CLIENT_AUTH_BY_ANCHOR_AGREEMENT_ID,
    data: {
      'anchor-agreement-id': anchorAgreementId,
    },
  });

  return formattingToFinancierClientAuthSettingVOModel(response);
}
