import type { EnterpriseVO, EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { formattingToEnterpriseVOModel } from 'models/vo/EnterpriseVO';
import http from 'utils/http';

import API_AN from '..';

// 가입 대상 기업 조회
export async function requestInvitedEnterprise(code: string): Promise<EnterpriseVOModel> {
  const response = await http.get<EnterpriseVO>({ url: API_AN.ENTERPRISES.INVITED_ENTERPRISE, data: { code } });

  return formattingToEnterpriseVOModel(response);
}
