import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isNil } from 'lodash-es';

import { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import NavigationGuard from 'components/stateless/NavigationGuard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import VerticalStepWizard from 'components/stateless/VerticalStepWizard/VerticalStepWizard';
import { ROUTES_FI } from 'constants/routes/financier';
import { FINANCIER_INTERFACE_TYPE } from 'enums';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType } from 'utils/storage/LocalStorage';
import { getSignIn } from 'utils/storage/LocalStorage';

import RegisterAnchor from './sections/register-anchor';
import RegisterDivision from './sections/register-division';

export type ActiveStepType = 'anchor' | 'division';

function FinancierManageAnchorRegister() {
  const { show: showModal } = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);

  const [isInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.ENTERPRISE));
  const [activeStep, setActiveStep] = useState<ActiveStepType>('anchor');
  const [financierClientId, setFinancierClientId] = useState(NaN);
  const [divisionRegistrable, setDivisionRegistrable] = useState<boolean>();
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);
  const [isSuccessRegisterDivision, setIsSuccessRegisterDivision] = useState(false);
  const activeNavigationGuard = activeStep === 'division';
  const ignoreNavigationGuard = isShowCancelModal || isSuccessRegisterDivision;

  useBeforeUnload(activeStep === 'division', [activeStep]);

  const anchorRegistrationGuideMessage: (string | JSX.Element)[] = [
    t('text:Click_on_the_Register_button_to_complete_the_registration'),
  ];

  if (isInterface) {
    anchorRegistrationGuideMessage.unshift(
      t('text:The_information_that_wasnt_retrieved_can_be_entered_manually'),
      t('text:The_information_from_the_financier_system_cannot_be_changed'),
      t('text:Click_on_the_Search_Anchor_button_to_retrieve_information_from_the_financier_system'),
    );
  } else {
    anchorRegistrationGuideMessage.unshift(
      <Trans
        key="key"
        i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
        components={{ 1: <span className="asterisk" /> }}
      />,
      t('text:Enter_the_Anchor_information_to_register_the_company_on_the_platform'),
    );
  }

  const cancelModalText = useMemo(
    () => (
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t(`text:The_information_will_not_be_saved_if_registration_is_cancelled`)}
      </h6>
    ),
    [t],
  );

  const showCancelModal = () => {
    setIsShowCancelModal(true);

    showModal(cancelModalText, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t(`text:Close`),
      confirmBtnText: t(`text:Confirm`),
      closeBtnCb: () => setIsShowCancelModal(false),
      confirmBtnCb: () => history.push(ROUTES_FI.MANAGE_ANCHOR.COMPANY_LIST),
    });
  };

  const fetchFinancierSettingData = useCallback(async () => {
    try {
      const { divisionRegistrable } = await requestFinancierSettingData(getSignIn()?.enterpriseId as number);
      setDivisionRegistrable(divisionRegistrable);
    } catch (error) {
      showModal(error);
    }
  }, [showModal]);

  useEffect(() => {
    fetchFinancierSettingData();
  }, [fetchFinancierSettingData]);

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Registration')} />
      {!isNil(divisionRegistrable) && !divisionRegistrable && (
        <>
          <GuideMessage message={anchorRegistrationGuideMessage} />
          <div className="content-area">
            <RegisterAnchor
              showCancelModal={showCancelModal}
              isInterface={isInterface}
              divisionRegistrable={divisionRegistrable}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setFinancierClientId={setFinancierClientId}
            />
          </div>
        </>
      )}
      {!isNil(divisionRegistrable) && divisionRegistrable && (
        <VerticalStepWizard.Wrapper>
          <VerticalStepWizard<ActiveStepType>
            title={t('text:Fill_out_Anchor_Information')}
            label="1"
            step="anchor"
            activeStep={activeStep}
          >
            {activeStep === 'anchor' && (
              <GuideMessage
                className="mb-4"
                message={anchorRegistrationGuideMessage}
                isImportContentArea
                useContentAreaClassName={false}
              />
            )}
            <RegisterAnchor
              showCancelModal={showCancelModal}
              isInterface={isInterface}
              divisionRegistrable={divisionRegistrable}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setFinancierClientId={setFinancierClientId}
            />
          </VerticalStepWizard>
          <VerticalStepWizard
            title={t('text:Add_Company_Division_(Optional)')}
            label="2"
            step="division"
            activeStep={activeStep}
          >
            {activeStep === 'division' && (
              <RegisterDivision
                showCancelModal={showCancelModal}
                financierClientId={financierClientId}
                setIsSuccessRegisterDivision={setIsSuccessRegisterDivision}
              />
            )}
          </VerticalStepWizard>
        </VerticalStepWizard.Wrapper>
      )}
      <NavigationGuard
        when={activeNavigationGuard}
        ignore={ignoreNavigationGuard}
        useTwoButtons
        buttonSize={ButtonSizeEnum.MD}
        confirmBtnText={t('text:Confirm')}
        cancelBtnText={t('text:Close')}
      >
        {cancelModalText}
      </NavigationGuard>
    </>
  );
}

export default FinancierManageAnchorRegister;
