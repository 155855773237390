import { useTranslation } from 'react-i18next';

import Form, { SizeType } from 'components/stateless/Form';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';
import { LOAN_LIMIT_CHECK_TYPE } from 'enums';

import useFinancingLimitController from './useFinancingLimitController';
import { PAGE_TYPE } from '../../../models/extraInformation/Context';

const FinancingLimit = () => {
  const { t } = useTranslation();

  const { currencyType, handleAdhocLimitAllowableChange, pageType } = useFinancingLimitController();

  return (
    <>
      <SubTitle>{t('text:FINANCING_LIMIT')}</SubTitle>
      <Content>
        <Row>
          <Form.Control name="loanLimitAmount" required>
            <Form.Label>{t('text:Financing_Limit_Amount')}</Form.Label>
            <Form.NumberInput
              currencyType={currencyType}
              leftUnit={currencyType}
              textAlign="text-right"
              fieldSize={SizeType.MD}
              numberType="bigNumber"
            />
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="loanLimitCheckType" required>
            <Cell>
              <Form.Label position="top">{t('text:Financing_Limit_Checkpoint')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_when_the_platform_will_check_that_the_Partner_s_remaining_financing_limit_is_sufficient',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio>
              <Form.Option label={t('text:Both')} value={LOAN_LIMIT_CHECK_TYPE.BOTH} />
              <Form.Option label={t('text:Invoice_Registration')} value={LOAN_LIMIT_CHECK_TYPE.INVOICE} />
              <Form.Option label={t('text:Loan_Application')} value={LOAN_LIMIT_CHECK_TYPE.LOAN} />
            </Form.Radio>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="adhocLimitAllowable" required>
            <Cell>
              <Form.Label position="top">{t('text:Apply_Ad_Hoc_Limit')}</Form.Label>
              <Form.Description>
                {t('text:Increase_the_Partner_s_financing_limit_temporarily_by_providing_the_Ad_hoc_limit')}
              </Form.Description>
            </Cell>
            <Form.Radio
              onChange={handleAdhocLimitAllowableChange}
              defaultValue={pageType === PAGE_TYPE.REGISTER ? 'false' : undefined}
            >
              <Form.Option
                label={t('text:Applied')}
                value="true"
                render={(isParentReadOnly, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Form.Control name="adhocLimitAmount" required>
                          <Form.Label>{t('text:Ad_Hoc_Limit_Amount')}</Form.Label>
                          <Form.NumberInput
                            currencyType={currencyType}
                            leftUnit={currencyType}
                            textAlign="text-right"
                            fieldSize={SizeType.MD}
                            numberType="bigNumber"
                            readOnly={isParentRadioUnChecked}
                            parentReadOnly={isParentReadOnly}
                          />
                        </Form.Control>
                      </Row>
                      <Row>
                        <Form.Control name="adhocLimitStartDate" required>
                          <Form.Label>{t('text:Start_Date')}</Form.Label>
                          <Form.DatePickerInput readOnly={isParentRadioUnChecked} parentReadOnly={isParentReadOnly} />
                        </Form.Control>
                      </Row>
                      <Row>
                        <Form.Control name="adhocLimitEndDate" required>
                          <Form.Label>{t('text:End_Date')}</Form.Label>
                          <Form.DatePickerInput readOnly={isParentRadioUnChecked} parentReadOnly={isParentReadOnly} />
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
              <Form.Option label={t('text:Not_Applied')} value="false" />
            </Form.Radio>
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default FinancingLimit;
