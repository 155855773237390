import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import type { CURRENCY_TYPE } from 'enums';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import useAnchorAgreementInfoViewModel from 'pages/financier/manage-partner/agreement/models/agreement/useAnchorAgreementInfoViewModel';
import { requestFinancierAnchorAgreementData } from 'utils/http/api/financier/anchor-agreements';
import { requestFiAnchorPartnerAccountList } from 'utils/http/api/financier/anchor-partner-accounts';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import SearchAnchorAgreementModal from '../../../../../../../components/stateless/Modal/financier/SearchAnchorAgreementModal';
import SearchUploadedPartnerModal from '../../../modals/SearchUploadedPartnerModal';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';
import useFinancierSettingViewModel from '../../../models/financierSetting/useFinancierSettingViewModel';

const useWaitingDetailAssociatedAnchorMasterAgreementController = () => {
  const { show: showModal, id: modalId } = useModal();
  const { t } = useTranslation(['format']);

  const {
    agreement,
    dealerTaxCode,
    isFirstRegisteredWaitingAgreement,
    updateAssociatedAnchorAgreementInfo,
    updateDivisionInfo,
    isSearchedAgreement,
  } = useAgreementViewModel();
  const { anchorAgreementInfo, updateAnchorAgreementInfo, convertToAnchorAgreementInfoType } =
    useAnchorAgreementInfoViewModel();
  const { potentialPartnerRegistrable, divisionRegistrable } = useFinancierSettingViewModel();
  const {
    isMatchedWithUploadedPartner,
    useAgreementInterface,
    supportedCollateralType,
    updateIsMatchedWithUploadedPartner,
  } = useExtraInformationViewModel();

  const {
    methods: { setValue },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();

  const updateDivisionIfNeeded = async (data: AnchorAgreementVOModel) => {
    if (!divisionRegistrable) return;

    try {
      const anchorAgreement = await requestFinancierAnchorAgreementData(data.anchorAgreementId);
      updateDivisionInfo(anchorAgreement);
    } catch (error) {
      showModal(error);
    }
  };

  const handleSearchAnchorAgreementClick = () => {
    const setSelectedData = async (data: AnchorAgreementVOModel) => {
      setValue('anchorAgreementId', data.anchorAgreementId);
      setValue('currencyType', data.currencyType);

      await updateDivisionIfNeeded(data);
      updateAssociatedAnchorAgreementInfo(data);
      updateAnchorAgreementInfo(convertToAnchorAgreementInfoType(data));
      updateIsMatchedWithUploadedPartner(false);
    };

    showModal(
      <SearchAnchorAgreementModal
        modalId={modalId}
        getSelectedData={setSelectedData}
        supportedCollateralType={supportedCollateralType}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        title: t('text:Search_Anchor_Master_Agreement'),
        closeBtnText: t('text:Close'),
      },
    );
  };

  const fetchAnchorPartnerAccountList = async (
    anchorPartnerTaxCode: string,
    anchorAgreementNo: string,
    currencyType: CURRENCY_TYPE,
  ) => {
    try {
      const anchorPartnerAccountList = await requestFiAnchorPartnerAccountList(0, 1, {
        anchorPartnerTaxCode,
        anchorAgreementNo,
        currencyType,
      });

      return anchorPartnerAccountList.content[0];
    } catch (e: any) {
      showModal(e);
    }
  };

  const handleSearchUploadedPartnerClick = async () => {
    if (!dealerTaxCode || !agreement.currencyType || !anchorAgreementInfo.anchorAgreementNo) return;

    const uploadedPartner = await fetchAnchorPartnerAccountList(
      dealerTaxCode,
      anchorAgreementInfo.anchorAgreementNo,
      agreement.currencyType,
    );

    if (uploadedPartner) {
      showModal(<SearchUploadedPartnerModal uploadedPartner={uploadedPartner} />, {
        title: t('text:Search_Uploaded_Partner'),
        modalType: ModalType.CONFIRM,
        modalSize: ModalSize.L,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => {
          updateIsMatchedWithUploadedPartner(true);
          setValue('anchorPartnerAccountId', uploadedPartner.anchorPartnerAccountId);
        },
      });
    } else {
      showModal(
        <h6>
          {t('text:No_uploaded_partner_information_exists_that_matches_the_partners_information')}
          <br />
          {t(
            'text:Please_check_your_partners_tax_code_and_uploaded_partners_tax_code,_modify_or_re-register_the_information_before_proceeding',
          )}
        </h6>,
      );
    }
  };

  const isSearchAnchorAgreementButtonVisible = useAgreementInterface
    ? isSearchedAgreement && !getReadOnlyValue('anchorAgreementId')
    : isEditable && isFirstRegisteredWaitingAgreement;
  const isSearchUploadedPartnerVisible =
    isEditable &&
    isFirstRegisteredWaitingAgreement &&
    potentialPartnerRegistrable &&
    (!useAgreementInterface || isSearchedAgreement);

  return {
    agreement,
    anchorAgreementInfo,
    divisionRegistrable,
    potentialPartnerRegistrable,
    isSearchAnchorAgreementButtonVisible,
    isSearchUploadedPartnerVisible,
    isMatchedWithUploadedPartner,
    handleSearchAnchorAgreementClick,
    handleSearchUploadedPartnerClick,
  };
};

export default useWaitingDetailAssociatedAnchorMasterAgreementController;
