import Big from 'big.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

import i18n from 'locales/i18n';
import type { BigNumber } from 'utils/bigNumber';

const convertToString = (value: string | number | boolean): string => {
  return isNil(value) ? value : String(value);
};

const convertNegativeValueToZero = (value: BigNumber | number | undefined): BigNumber | number => {
  return value === undefined || (value && Big(value).lt(0)) ? 0 : value;
};

const sliceZeroDecimal = (value: string | number): string => {
  if (isNil(value)) return value;
  value = value.toString();

  const dotIdx = value.indexOf('.');

  if (dotIdx !== -1) {
    value = value.replace(/0+$/, '');
    if (value.slice(-1) === '.') value = value.slice(0, -1);
  }

  return value;
};

const formatDateColumn = (value: string | Date) => {
  let dateToFormat: string | Date;
  let key: string;
  const formatNoLineDate = dayjs(String(value), i18n.t(`format:no-line-date`), true);
  const formatOriginalDate = dayjs(String(value), i18n.t(`format:original-date`), true);
  const formatDottedDate = dayjs(String(value), i18n.t(`format:dotted-date`), true);
  if (formatNoLineDate.isValid()) {
    dateToFormat = value;
    key = 'no-line-date';
  } else if (formatOriginalDate.isValid()) {
    dateToFormat = value;
    key = 'original-date';
  } else if (formatDottedDate.isValid()) {
    dateToFormat = value;
    key = 'dotted-date';
  } else if (value instanceof Date) {
    dateToFormat = value;
    key = 'original-date';
  } else {
    dateToFormat = '';
    key = '-';
  }

  return i18n.t('format:original-date', {
    value: String(dateToFormat),
    key,
  });
};

const formatStringWithEllipsis = (str: string) => {
  if (str?.length > 12) {
    return str.substring(0, 10) + '...' + str.substring(str.length - 5);
  } else {
    return str;
  }
};

export { convertToString, sliceZeroDecimal, convertNegativeValueToZero, formatDateColumn, formatStringWithEllipsis };
