import { AUTHORITY_TYPE, AWS_EMAIL_EVENT_TYPE, EMAIL_SENT_STATUS, stringToEnum } from 'enums';

export interface FactoringNoticeReceiverVO {
  factoringNoticeReceiverId: number;
  recentSentEmailAddress: string;
  sentDateTime: string;
  userId: number;
  userName: string;
  currentUserEmailAddress: string;
  authorityType: string;
  emailSentStatus: string;
  awsEmailEventType: string;
  eventDateTime: string;
  success: boolean;
}

export interface FactoringNoticeReceiverVOModel extends FactoringNoticeReceiverVO {
  authorityType: AUTHORITY_TYPE;
  emailSentStatus: EMAIL_SENT_STATUS;
  awsEmailEventType: AWS_EMAIL_EVENT_TYPE;
}

export function formattingToFactoringNoticeReceiverVOModel(
  data: FactoringNoticeReceiverVO,
): FactoringNoticeReceiverVOModel {
  return {
    ...data,
    authorityType: stringToEnum(AUTHORITY_TYPE, data.authorityType),
    emailSentStatus:
      data.emailSentStatus === null ? EMAIL_SENT_STATUS.FAIL : stringToEnum(EMAIL_SENT_STATUS, data.emailSentStatus),
    awsEmailEventType:
      data.emailSentStatus === null
        ? AWS_EMAIL_EVENT_TYPE.NETWORK_ERROR
        : stringToEnum(AWS_EMAIL_EVENT_TYPE, data.awsEmailEventType),
  };
}
