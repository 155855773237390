import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { COLLATERAL_TYPE, MULTIPLE_LOAN_APPROVAL_TYPE } from 'enums';

import FinancierBulkAnchorFinancingDetailAdditionalDocuments from './sections/additional-documents';
import FinancierBulkAnchorFinancingCompanyInformation from './sections/bulk-financing-information';
import FinancierBulkAnchorFinancingDetailDesignatedBankAccountInformation from './sections/designated-bank-account-information';
import FinancierBulkAnchorFinancingDetailFinancingList from './sections/financing-list';
import FinancierBulkAnchorFinancingDetailRequestInformation from './sections/financing-request-information';
import FinancierBulkAnchorFinancingDetailGuideMessage from './sections/guide-message';
import useFinancierAnchorBulkFinancingConfirmationDetailController from './useFinancierAnchorBulkFinancingConfirmationDetailController';

function FinancierBulkAnchorFinancingConfirmationDetail() {
  const { t } = useTranslation();

  const {
    state,
    paginateRelatedFinancingList,
    relatedFinancingPageable,
    financingInfoCheckBoxState,
    bankAccountInfoCheckBoxState,
    buttonClickHandlers,
    isOperatorRequest,
    isAuthorizerRequest,
  } = useFinancierAnchorBulkFinancingConfirmationDetailController();

  const {
    handleOperatorBulkFinancingApproveRequestButtonClick,
    handleOperatorBulkFinancingRejectButtonClick,
    handleAuthorizerBulkFinancingApproveButtonClick,
    handleAuthorizerBulkFinancingRevertButtonClick,
    submitBulkFinancingCancel,
    submitFinancingRejectionRequest,
    submitFinancingRejectionApprove,
    submitBulkFinancingReturn,
  } = buttonClickHandlers;

  const { approvalType, collateralType, multipleAnchorLoanRequestId, anchorLoanEtcAttachment } =
    state.bulkFinancingDetail;

  return (
    <>
      <BackHeaderTitle title={t('text:Bulk_Financing_Approval_Details')} />
      <FinancierBulkAnchorFinancingDetailGuideMessage
        bulkFinancingDetailInfo={state.bulkFinancingDetail}
        submitBulkFinancingCancel={submitBulkFinancingCancel}
        submitFinancingRejectionRequest={submitFinancingRejectionRequest}
        submitFinancingRejectionApprove={submitFinancingRejectionApprove}
        submitBulkFinancingReturn={submitBulkFinancingReturn}
      />

      <FinancierBulkAnchorFinancingCompanyInformation bulkFinancingDetailInfo={state.bulkFinancingDetail} />
      <FinancierBulkAnchorFinancingDetailRequestInformation
        bulkFinancingDetailInfo={state.bulkFinancingDetail}
        arList={state.bulkFinancingArList}
        invoiceList={state.bulkFinancingInvoiceList}
        isOperatorRequest={isOperatorRequest}
        financingInfoCheckBoxState={financingInfoCheckBoxState}
      />

      <FinancierBulkAnchorFinancingDetailDesignatedBankAccountInformation
        bulkFinancingDetailInfo={state.bulkFinancingDetail}
        isOperatorRequest={isOperatorRequest}
        bankAccountInfoCheckBoxState={bankAccountInfoCheckBoxState}
        showDisbursementAccountInfo={state.showDisbursementAccountInfo}
      />
      <FinancierBulkAnchorFinancingDetailAdditionalDocuments
        multipleAnchorLoanRequestId={multipleAnchorLoanRequestId}
        loanEtcAttachmentListData={anchorLoanEtcAttachment}
      />

      <FinancierBulkAnchorFinancingDetailFinancingList
        financingList={state.bulkFinancingRelatedFinancingList}
        paginate={paginateRelatedFinancingList}
        pageable={relatedFinancingPageable}
        isAr={collateralType === COLLATERAL_TYPE.AR}
      />

      <div className="content-area flex-end mt-3">
        {isAuthorizerRequest && (
          <>
            <Button
              size={ButtonSizeEnum.LG}
              onClick={handleAuthorizerBulkFinancingRevertButtonClick}
              className="me-2"
              color={ButtonColorEnum.RED}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Revert')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={handleAuthorizerBulkFinancingApproveButtonClick}>
              {t('text:Confirm')}
            </Button>
          </>
        )}
        {isOperatorRequest && (
          <>
            <Button
              size={ButtonSizeEnum.LG}
              onClick={handleOperatorBulkFinancingRejectButtonClick}
              className="me-2"
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Reject')}
            </Button>
            <Button
              size={ButtonSizeEnum.LG}
              onClick={handleOperatorBulkFinancingApproveRequestButtonClick}
              disabled={approvalType === MULTIPLE_LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED}
            >
              {t('text:Confirm')}
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default FinancierBulkAnchorFinancingConfirmationDetail;
