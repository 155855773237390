import type React from 'react';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faDownload, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { TempLoanVOModel } from 'models/vo/TempLoanVO';
import { requestDealerTempEtcAttachmentDownload } from 'utils/http/api/dealer/temp-loans';
import useModal from 'utils/modal/useModal';

import type { EtcFileRowTypes } from '../../DealerInvoiceFinancingApplicationStep2';

interface AdditionalDocumentsProps {
  dealerTempLoanByInvoice: TempLoanVOModel;
  etcFileRowsState: {
    etcFileRows: EtcFileRowTypes[];
    setEtcFileRows: React.Dispatch<React.SetStateAction<EtcFileRowTypes[]>>;
  };
}

function AdditionalDocuments({ dealerTempLoanByInvoice, etcFileRowsState }: AdditionalDocumentsProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { register, setValue } = useFormContext();
  const { etcFileRows, setEtcFileRows } = etcFileRowsState;
  const etcFileRowId = useRef(0);

  useEffect(() => {
    if (!dealerTempLoanByInvoice.tempLoanEtcAttachmentList) return;
    if (dealerTempLoanByInvoice.tempLoanEtcAttachmentList.length === 0) return;

    const tempLoanEtcAttachmentList: EtcFileRowTypes[] = dealerTempLoanByInvoice?.tempLoanEtcAttachmentList?.map(
      item => ({
        id: etcFileRowId.current++,
        tempLoanEtcAttachmentId: item.tempLoanEtcAttachmentId,
        fileName: item.tempLoanEtcAttachmentName,
        changed: false,
      }),
    );
    setEtcFileRows(tempLoanEtcAttachmentList);
  }, [dealerTempLoanByInvoice, setEtcFileRows]);

  const etcAddRow = () => {
    etcFileRowId.current++;
    setEtcFileRows([
      ...etcFileRows,
      {
        id: etcFileRowId.current,
        tempLoanEtcAttachmentId: undefined,
        fileName: undefined,
        changed: false,
      },
    ]);
  };

  const etcRemoveRow = (id: number) => {
    const newState = etcFileRows.filter(item => {
      return item.id !== id;
    });
    setEtcFileRows(newState);
  };

  const setEtcFileName = (e: any, id: number) => {
    setEtcFileRows(prev =>
      prev?.map(item => {
        return item.id === id ? { ...item, fileName: e.target.files[0].name, changed: true } : item;
      }),
    );
  };

  const resetAndSetRowWhenOneRowLeft = (id: number) => {
    setEtcFileRows(prev =>
      prev?.map(item => {
        return { ...item, fileName: undefined };
      }),
    );
    setValue(`loanEtcAttachments[${id}]`, undefined);
    setValue(`loanEtcAttachmentDescriptions[${id}]`, undefined);
    setValue(`tempLoanEtcAttachmentId[${id}]`, undefined);
  };

  const onClickEtcFileDownLoad = async (tempLoanEtcAttachmentId: number | undefined) => {
    try {
      const { tempLoanId } = dealerTempLoanByInvoice;
      await requestDealerTempEtcAttachmentDownload(tempLoanId, tempLoanEtcAttachmentId as number);
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <SectionTitle title={t('text:Additional_Documents')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="d-flex mb-4">
            <label className="me-auto">{t('text:Upload_any_required_additional_documents_below')}</label>
          </div>
          <>
            {etcFileRows.map(item => {
              return (
                <div className="row" key={item.id}>
                  <div className="col-6 align-self-center">
                    <div className="d-flex align-items-center">
                      <input type="hidden" name={`tempLoanEtcAttachmentId[${item.id}]`} ref={register} />
                      <input
                        type="file"
                        name={`loanEtcAttachments[${item.id}]`}
                        ref={register}
                        id={`FileUpload${item.id}`}
                        className="d-none"
                        onChange={(e: any) => {
                          if (e.target?.files[0]?.name) setEtcFileName(e, item.id);
                        }}
                      />
                      <label htmlFor={`FileUpload${item.id}`} className="attach-file-link-button me-3">
                        {t('text:Attach_File')}
                      </label>
                      <div id={`fileName${item.id}`} className="upload-file-input">
                        {item.fileName ? item.fileName : t('text:No_file_attached')}
                        {item.fileName && !item.changed && (
                          <IconButton
                            onClick={() => onClickEtcFileDownLoad(item.tempLoanEtcAttachmentId)}
                            type="button"
                          >
                            <FontAwesomeIcon icon={faDownload} />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex justify-content-between">
                      <input
                        type="text"
                        name={`loanEtcAttachmentDescriptions[${item.id}]`}
                        placeholder={t('text:Please_note_here')}
                        className="bg-sub100 p-2 w-100 me-3"
                        ref={register}
                      />
                      {etcFileRows.length !== 1 ? (
                        <Button
                          size={ButtonSizeEnum.SM}
                          variant={ButtonVariantEnum.OUTLINED}
                          color={ButtonColorEnum.SECONDARY}
                          onClick={e => {
                            e.preventDefault();
                            if (etcFileRows.length === 1) {
                              resetAndSetRowWhenOneRowLeft(item.id);
                            } else {
                              etcRemoveRow(item.id);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </Button>
                      ) : (
                        <Button
                          size={ButtonSizeEnum.SM}
                          onClick={e => {
                            e.preventDefault();
                            etcAddRow();
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
          {etcFileRows?.length !== 1 && (
            <div className="row">
              <div className="flex-end mb-4">
                <Button
                  size={ButtonSizeEnum.SM}
                  onClick={e => {
                    e.preventDefault();
                    etcAddRow();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </div>
          )}
        </FormContents>
      </FormBorder>
    </>
  );
}

export default AdditionalDocuments;
