import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, OTP_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { requestAnchorAgreementsList } from 'utils/http/api/anchor/anchor-agreements';
import { requestAnchorDealerDetail, requestAnchorDealerRegister } from 'utils/http/api/anchor/anchor-dealers';
import type { AnchorDealerRequest } from 'utils/http/api/anchor/anchor-dealers/request';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestAnchorWaitingAnchorDealerList } from 'utils/http/api/anchor/waiting-anchor-dealers';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

const useAnchorDealerCompanyInfoConfirmedDetailPageState = () => {
  const { anchorDealerId } = useParams() as any;
  const { t } = useTranslation();
  const userAuthorityType = getSignIn()?.authorityType;
  const modal = useModal();
  const { pageable: agreementPageable, setPageable: setAgreementPageable } = usePageable();
  const { pageable: historyPageable, setPageable: setHistoryPageable } = usePageable();
  const formMethods = useForm<AnchorDealerRequest>();

  const [anchorDealerInfo, setAnchorDealerInfo] = useState<AnchorDealerVOModel>({} as AnchorDealerVOModel);
  const [anchorDealerHistoryList, setAnchorDealerHistoryList] = useState<WaitingAnchorDealerVOModel[]>([]);
  const [relatedAnchorAgreement, setRelatedAnchorAgreement] = useState<AnchorAgreementVOModel[]>();
  const [anchorClientAuthInfo, setAnchorClientAuthInfo] = useState<FinancierClientAuthSettingVOModel>();

  async function fetchAll() {
    try {
      const [anchorDealerResponse, anchorDealerHistoryResponse] = await Promise.all([
        requestAnchorDealerDetail(anchorDealerId),
        requestAnchorWaitingAnchorDealerList(historyPageable.currentPage, historyPageable.sizePerPage, {
          hasTargetAnchorDealer: true,
          targetAnchorDealerId: anchorDealerId,
          approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
        }),
      ]);

      const [relatedAnchorAgreementResponse, anchorClientAuthResponse] = await Promise.all([
        requestAnchorAgreementsList(1, 10, {
          financierName: anchorDealerResponse.financierName,
        }),
        requestAnchorClientAuthByFinancierId(anchorDealerResponse.financierId),
      ]);

      setAnchorDealerInfo(anchorDealerResponse);
      setAnchorDealerHistoryList(anchorDealerHistoryResponse.content);
      setHistoryPageable(anchorDealerHistoryResponse);
      setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
      setAgreementPageable(relatedAnchorAgreementResponse);
      setAnchorClientAuthInfo(anchorClientAuthResponse);

      resetFormData(anchorDealerResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  const agreementPaginate = async (pageNumber: number, sizePerPage: number) => {
    const relatedAnchorAgreementResponse = await requestAnchorAgreementsList(pageNumber, sizePerPage, {
      financierName: anchorDealerInfo?.financierName,
    });

    setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
    setAgreementPageable(relatedAnchorAgreementResponse);
  };

  const historyPaginate = async (pageNumber: number, sizePerPage: number) => {
    const historyResponse = await requestAnchorWaitingAnchorDealerList(pageNumber, sizePerPage, {
      hasTargetAnchorDealer: true,
      targetAnchorDealerId: anchorDealerId,
      approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
    });

    setAnchorDealerHistoryList(historyResponse.content);
    setHistoryPageable(historyResponse);
  };

  const resetFormData = (data: AnchorDealerVOModel) => {
    formMethods.reset({
      waitingAnchorDealer: {
        name: data.name,
        taxCode: data.taxCode,
        businessCode: data.businessCode,
        fax: data.fax,
        representativeName: data.representativeName,
        representativePosition: data.representativePosition,
        representativeEmail: data.representativeEmail,
        telephone: data.telephone,
        address: data.address,
      },
    });
  };

  const showVerificationOtpModal = () => {
    const verificationCode: UserVerificationCodeRequest = {};

    modal.show(
      <UserVerificationModal
        modalId={modal.id}
        verificationCode={verificationCode}
        requestIdType="financierId"
        requestId={anchorDealerInfo?.financierId!}
        clientAuthSetting={anchorClientAuthInfo!}
      />,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:User_Verification'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => requestSave(verificationCode),
      },
    );
  };

  const checkOtpAuth = async () =>
    (userAuthorityType === AUTHORITY_TYPE.ADMIN || userAuthorityType === AUTHORITY_TYPE.AUTHORIZER) &&
    anchorClientAuthInfo?.otpType !== OTP_TYPE.NONE
      ? showVerificationOtpModal()
      : await requestSave();

  const requestSave = async (verificationCode?: UserVerificationCodeRequest) => {
    try {
      await requestAnchorDealerRegister(anchorDealerId, {
        ...formMethods.getValues(),
        financierId: anchorDealerInfo.financierId,
        otpCode: verificationCode?.otpCode,
        queryValue: verificationCode?.queryValue,
      });

      return true;
    } catch (e) {
      modal.show(e);

      return false;
    }
  };

  return {
    state: {
      anchorDealerInfo,
      anchorDealerHistoryList,
      relatedAnchorAgreement,
      anchorClientAuthInfo,
    },
    fetchAll,
    formMethods,
    historyPageable,
    historyPaginate,
    agreementPageable,
    agreementPaginate,
    resetFormData,
    checkOtpAuth,
  };
};
export default useAnchorDealerCompanyInfoConfirmedDetailPageState;
