const AC_DASHBOARD_PATH = '/anchor/dashboard';

const AC_MANAGE_DEALER_PATH = '/anchor/manage-dealer';
const AC_MANAGE_DEALER_REGISTRATION_PATH = `${AC_MANAGE_DEALER_PATH}/registration`;
const AC_MANAGE_DEALER_COMPANY_INFO_PATH = `${AC_MANAGE_DEALER_PATH}/company-info`;

const AC_MANAGE_PARTNER_PATH = '/anchor/manage-partner';
const AC_MANAGE_PARTNER_REGISTRATION_PATH = `${AC_MANAGE_PARTNER_PATH}/registration`;
const AC_MANAGE_PARTNER_BANK_ACCOUNT_PATH = `${AC_MANAGE_PARTNER_PATH}/bank-account`;
const AC_MANAGE_PARTNER_COMPANY_INFO_PATH = `${AC_MANAGE_PARTNER_PATH}/company-info`;

const AC_REGISTER_INVOICE_PATH = '/anchor/register-invoice';
const AC_REGISTER_INVOICE_REGISTRATION_PATH = `${AC_REGISTER_INVOICE_PATH}/registration`;
const AC_REGISTER_INVOICE_REGISTRATION_BY_PARTNER_PATH = `${AC_REGISTER_INVOICE_PATH}/registration-by-partner`;
const AC_REGISTER_INVOICE_CONFIRMATION_PATH = `${AC_REGISTER_INVOICE_PATH}/confirmation`;

const AC_REGISTER_AP_PATH = '/anchor/register-ap';
const AC_REGISTER_AP_REGISTRATION_PATH = `${AC_REGISTER_AP_PATH}/registration`;
const AC_REGISTER_AP_CONFIRMATION_PATH = `${AC_REGISTER_AP_PATH}/confirmation`;

const AC_MANAGE_ANCHOR_FINANCING_PATH = '/anchor/manage-anchor-financing';
const AC_MANAGE_ANCHOR_FINANCING_APPLICATION_PATH = `${AC_MANAGE_ANCHOR_FINANCING_PATH}/application`;
const AC_MANAGE_ANCHOR_FINANCING_BULK_FINANCING_PATH = `${AC_MANAGE_ANCHOR_FINANCING_PATH}/bulk-financing`;

const AC_VIEW_TRANSACTION_PATH = '/anchor/view-transaction';
const AC_VIEW_TRANSACTION_INVOICE_PATH = `${AC_VIEW_TRANSACTION_PATH}/invoice`;
const AC_VIEW_TRANSACTION_INVOICE_PAYMENT_PATH = `${AC_VIEW_TRANSACTION_PATH}/invoice-payment`;
const AC_VIEW_TRANSACTION_AP_PATH = `${AC_VIEW_TRANSACTION_PATH}/ap`;
const AC_VIEW_TRANSACTION_AP_SETTLEMENT_PATH = `${AC_VIEW_TRANSACTION_PATH}/ap-settlement`;
const AC_VIEW_TRANSACTION_FINANCING_PATH = `${AC_VIEW_TRANSACTION_PATH}/financing`;
const AC_VIEW_TRANSACTION_ANCHOR_FINANCING_PATH = `${AC_VIEW_TRANSACTION_PATH}/anchor-financing`;
const AC_VIEW_TRANSACTION_INTEREST_PAYMENT_PATH = `${AC_VIEW_TRANSACTION_PATH}/interest-payment`;
const AC_VIEW_TRANSACTION_OVERVIEW_BY_PARTNER_PATH = `${AC_VIEW_TRANSACTION_PATH}/overview-by-partner`;
const AC_VIEW_TRANSACTION_DEPT_SELLING_NOTICE_PATH = `${AC_VIEW_TRANSACTION_PATH}/dept-selling-notice`;

const AC_COMPANY_PATH = '/anchor/company';
const AC_COMPANY_USER_PATH = `${AC_COMPANY_PATH}/user`;
const AC_COMPANY_AGREEMENT_PATH = `${AC_COMPANY_PATH}/agreement`;

const AC_MY_ACCOUNT_PATH = `/anchor/my-account`;
const AC_MY_ACCOUNT_EMAIL_NOTIFICATION_SETTING_PATH = `${AC_MY_ACCOUNT_PATH}/email-notification-setting`;

export const ROUTES_AC = {
  DASHBOARD: AC_DASHBOARD_PATH,

  MANAGE_PARTNER: {
    REGISTRATION_STEP1: AC_MANAGE_PARTNER_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${AC_MANAGE_PARTNER_REGISTRATION_PATH}/:financierId/:anchorAgreementId`,
    REGISTRATION_STEP2_BUILD_PATH: (financierId: number, anchorAgreementId: number) =>
      `${AC_MANAGE_PARTNER_REGISTRATION_PATH}/${financierId}/${anchorAgreementId}`,
    REGISTRATION_LIST: `${AC_MANAGE_PARTNER_REGISTRATION_PATH}/list`,
    REGISTRATION_WAITING_DETAIL: `${AC_MANAGE_PARTNER_REGISTRATION_PATH}/waiting-detail/:waitingAnchorPartnerId/:financierId`,
    REGISTRATION_WAITING_DETAIL_BUILD_PATH: (waitingAnchorPartnerId: number, financierId: number) =>
      `${AC_MANAGE_PARTNER_REGISTRATION_PATH}/waiting-detail/${waitingAnchorPartnerId}/${financierId}`,
    REGISTRATION_CONFIRMED_DETAIL: `${AC_MANAGE_PARTNER_REGISTRATION_PATH}/confirmed-detail/:anchorPartnerId`,
    REGISTRATION_CONFIRMED_DETAIL_BUILD_PATH: (anchorPartnerId: number) =>
      `${AC_MANAGE_PARTNER_REGISTRATION_PATH}/confirmed-detail/${anchorPartnerId}`,

    BANK_ACCOUNT_LIST: AC_MANAGE_PARTNER_BANK_ACCOUNT_PATH,
    BANK_ACCOUNT_WAITING_DETAIL: `${AC_MANAGE_PARTNER_BANK_ACCOUNT_PATH}/waiting-detail/:waitingAnchorPartnerAccountId`,
    BANK_ACCOUNT_WAITING_DETAIL_BUILD_PATH: (waitingAnchorPartnerAccountId: number) =>
      `${AC_MANAGE_PARTNER_BANK_ACCOUNT_PATH}/waiting-detail/${waitingAnchorPartnerAccountId}`,
    BANK_ACCOUNT_CONFIRMED_DETAIL: `${AC_MANAGE_PARTNER_BANK_ACCOUNT_PATH}/confirmed-detail/:anchorPartnerAccountId`,
    BANK_ACCOUNT_CONFIRMED_DETAIL_BUILD_PATH: (anchorPartnerAccountId: number) =>
      `${AC_MANAGE_PARTNER_BANK_ACCOUNT_PATH}/confirmed-detail/${anchorPartnerAccountId}`,

    COMPANY_INFO_LIST: AC_MANAGE_PARTNER_COMPANY_INFO_PATH,
    COMPANY_INFO_WAITING_DETAIL: `${AC_MANAGE_PARTNER_COMPANY_INFO_PATH}/waiting-detail/:waitingAnchorPartnerId`,
    COMPANY_INFO_WAITING_DETAIL_BUILD_PATH: (waitingAnchorPartnerId: number) =>
      `${AC_MANAGE_PARTNER_COMPANY_INFO_PATH}/waiting-detail/${waitingAnchorPartnerId}`,
    COMPANY_INFO_CONFIRMED_DETAIL: `${AC_MANAGE_PARTNER_COMPANY_INFO_PATH}/confirmed-detail/:anchorPartnerId`,
    COMPANY_INFO_CONFIRMED_DETAIL_BUILD_PATH: (anchorPartnerId: number) =>
      `${AC_MANAGE_PARTNER_COMPANY_INFO_PATH}/confirmed-detail/${anchorPartnerId}`,
  },

  MANAGE_DEALER: {
    REGISTRATION_STEP1: AC_MANAGE_DEALER_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${AC_MANAGE_DEALER_REGISTRATION_PATH}/step2`,
    REGISTRATION_LIST: `${AC_MANAGE_DEALER_REGISTRATION_PATH}/list`,
    REGISTRATION_DETAIL: `${AC_MANAGE_DEALER_REGISTRATION_PATH}/:anchorDealerId`,
    REGISTRATION_DETAIL_BUILD_PATH: (anchorDealerId: number) =>
      `${AC_MANAGE_DEALER_REGISTRATION_PATH}/${anchorDealerId}`,
    REGISTRATION_WAITING_DETAIL: `${AC_MANAGE_DEALER_REGISTRATION_PATH}/waiting/:waitingAnchorDealerId`,
    REGISTRATION_WAITING_DETAIL_BUILD_PATH: (waitingAnchorDealerId: number) =>
      `${AC_MANAGE_DEALER_REGISTRATION_PATH}/waiting/${waitingAnchorDealerId}`,
    COMPANY_INFO_LIST: `${AC_MANAGE_DEALER_COMPANY_INFO_PATH}/list`,
    COMPANY_INFO_DETAIL: `${AC_MANAGE_DEALER_COMPANY_INFO_PATH}/:anchorDealerId`,
    COMPANY_INFO_DETAIL_BUILD_PATH: (anchorDealerId: number) =>
      `${AC_MANAGE_DEALER_COMPANY_INFO_PATH}/${anchorDealerId}`,
    COMPANY_INFO_WAITING_DETAIL: `${AC_MANAGE_DEALER_COMPANY_INFO_PATH}/waiting/:waitingAnchorDealerId`,
    COMPANY_INFO_WAITING_DETAIL_BUILD_PATH: (waitingAnchorDealerId: number) =>
      `${AC_MANAGE_DEALER_COMPANY_INFO_PATH}/waiting/${waitingAnchorDealerId}`,
  },

  REGISTER_INVOICE: {
    REGISTRATION_STEP1: AC_REGISTER_INVOICE_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${AC_REGISTER_INVOICE_REGISTRATION_PATH}/:anchorAgreementId/:financierId`,
    REGISTRATION_STEP2_BUILD_PATH: (anchorAgreementId: number, financierId: number) =>
      `${AC_REGISTER_INVOICE_REGISTRATION_PATH}/${anchorAgreementId}/${financierId}`,

    REGISTRATION_BY_PARTNER_STEP1: AC_REGISTER_INVOICE_REGISTRATION_BY_PARTNER_PATH,
    REGISTRATION_BY_PARTNER_STEP2: `${AC_REGISTER_INVOICE_REGISTRATION_BY_PARTNER_PATH}/:dealerAgreementId/:financierId`,
    REGISTRATION_BY_PARTNER_STEP2_BUILD_PATH: (dealerAgreementId: number, financierId: number) =>
      `${AC_REGISTER_INVOICE_REGISTRATION_BY_PARTNER_PATH}/${dealerAgreementId}/${financierId}`,

    CONFIRMATION_LIST: AC_REGISTER_INVOICE_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${AC_REGISTER_INVOICE_CONFIRMATION_PATH}/:invoiceSummaryId/:financierEnterpriseId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (invoiceSummaryId: number, financierEnterpriseId: number) =>
      `${AC_REGISTER_INVOICE_CONFIRMATION_PATH}/${invoiceSummaryId}/${financierEnterpriseId}`,
  },

  REGISTER_AP: {
    REGISTRATION_STEP1: AC_REGISTER_AP_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${AC_REGISTER_AP_REGISTRATION_PATH}/:anchorAgreementId`,
    REGISTRATION_STEP2_BUILD_PATH: (anchorAgreementId: number) =>
      `${AC_REGISTER_AP_REGISTRATION_PATH}/${anchorAgreementId}`,

    CONFIRMATION_LIST: AC_REGISTER_AP_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${AC_REGISTER_AP_CONFIRMATION_PATH}/:apSummaryId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (apSummaryId: number) => `${AC_REGISTER_AP_CONFIRMATION_PATH}/${apSummaryId}`,
  },

  MANAGE_ANCHOR_FINANCING: {
    APPLICATION_LIST_STEP1: AC_MANAGE_ANCHOR_FINANCING_APPLICATION_PATH,
    APPLICATION_INVOICE_STEP2: `${AC_MANAGE_ANCHOR_FINANCING_APPLICATION_PATH}/invoice/:anchorFinancingOptionId`,
    APPLICATION_INVOICE_STEP2_BUILD_PATH: (anchorFinancingOptionId: number) =>
      `${AC_MANAGE_ANCHOR_FINANCING_APPLICATION_PATH}/invoice/${anchorFinancingOptionId}`,
    APPLICATION_AR_STEP2: `${AC_MANAGE_ANCHOR_FINANCING_APPLICATION_PATH}/ar/:anchorFinancingOptionId`,
    APPLICATION_AR_STEP2_BUILD_PATH: (anchorFinancingOptionId: number) =>
      `${AC_MANAGE_ANCHOR_FINANCING_APPLICATION_PATH}/ar/${anchorFinancingOptionId}`,
    BULK_FINANCING_LIST: AC_MANAGE_ANCHOR_FINANCING_BULK_FINANCING_PATH,
    BULK_FINANCING_DETAIL: `${AC_MANAGE_ANCHOR_FINANCING_BULK_FINANCING_PATH}/:multipleRequestAnchorLoanId`,
    BULK_FINANCING_DETAIL_BUILD_PATH: (multipleRequestAnchorLoanId: number) =>
      `${AC_MANAGE_ANCHOR_FINANCING_BULK_FINANCING_PATH}/${multipleRequestAnchorLoanId}`,
  },

  VIEW_TRANSACTION: {
    INVOICE_LIST: AC_VIEW_TRANSACTION_INVOICE_PATH,
    INVOICE_DETAIL: `${AC_VIEW_TRANSACTION_INVOICE_PATH}/:successInvoiceId`,
    INVOICE_DETAIL_BUILD_PATH: (successInvoiceId: number) => `${AC_VIEW_TRANSACTION_INVOICE_PATH}/${successInvoiceId}`,

    INVOICE_PAYMENT_LIST: AC_VIEW_TRANSACTION_INVOICE_PAYMENT_PATH,

    AP_LIST: AC_VIEW_TRANSACTION_AP_PATH,
    AP_DETAIL: `${AC_VIEW_TRANSACTION_AP_PATH}/:successArId`,
    AP_DETAIL_BUILD_PATH: (successArId: number) => `${AC_VIEW_TRANSACTION_AP_PATH}/${successArId}`,

    AP_SETTLEMENT_LIST: AC_VIEW_TRANSACTION_AP_SETTLEMENT_PATH,
    AP_SETTLEMENT_DETAIL: `${AC_VIEW_TRANSACTION_AP_SETTLEMENT_PATH}/:anchorAgreementId`,
    AP_SETTLEMENT_DETAIL_BUILD_PATH: (anchorAgreementId: number) =>
      `${AC_VIEW_TRANSACTION_AP_SETTLEMENT_PATH}/${anchorAgreementId}`,

    FINANCING_LIST: AC_VIEW_TRANSACTION_FINANCING_PATH,
    ANCHOR_FINANCING_LIST: AC_VIEW_TRANSACTION_ANCHOR_FINANCING_PATH,
    ANCHOR_FINANCING_DETAIL: `${AC_VIEW_TRANSACTION_ANCHOR_FINANCING_PATH}/:anchorLoanId`,
    ANCHOR_FINANCING_DETAIL_BUILD_PATH: (anchorLoanId: number) =>
      `${AC_VIEW_TRANSACTION_ANCHOR_FINANCING_PATH}/${anchorLoanId}`,

    INTEREST_PAYMENT_LIST: AC_VIEW_TRANSACTION_INTEREST_PAYMENT_PATH,

    OVERVIEW_BY_PARTNER_LIST: AC_VIEW_TRANSACTION_OVERVIEW_BY_PARTNER_PATH,

    DEPT_SELLING_NOTICE_LIST: AC_VIEW_TRANSACTION_DEPT_SELLING_NOTICE_PATH,
    DEPT_SELLING_NOTICE_DETAIL: `${AC_VIEW_TRANSACTION_DEPT_SELLING_NOTICE_PATH}/:factoringNoticeId`,
    DEPT_SELLING_NOTICE_BUILD_PATH: (factoringNoticeId: number) =>
      `${AC_VIEW_TRANSACTION_DEPT_SELLING_NOTICE_PATH}/${factoringNoticeId}`,
  },

  COMPANY: {
    USER_LIST: AC_COMPANY_USER_PATH,
    USER_REGISTER: `${AC_COMPANY_USER_PATH}/register`,
    USER_DETAIL: `${AC_COMPANY_USER_PATH}/:userId`,
    USER_DETAIL_BUILD_PATH: (userId: number) => `${AC_COMPANY_USER_PATH}/${userId}`,

    AGREEMENT_LIST: AC_COMPANY_AGREEMENT_PATH,
    AGREEMENT_DETAIL: `${AC_COMPANY_AGREEMENT_PATH}/agreement/:anchorAgreementId`,
    AGREEMENT_DETAIL_BUILD_PATH: (anchorAgreementId: number) =>
      `${AC_COMPANY_AGREEMENT_PATH}/agreement/${anchorAgreementId}`,
    FINANCING_OPTION_DETAIL: `${AC_COMPANY_AGREEMENT_PATH}/financing-option/:anchorFinancingOptionId`,
    FINANCING_OPTION_DETAIL_BUILD_PATH: (anchorFinancingOptionId: number) =>
      `${AC_COMPANY_AGREEMENT_PATH}/financing-option/${anchorFinancingOptionId}`,
  },

  MY_ACCOUNT: {
    ACCOUNT_SETTINGS: AC_MY_ACCOUNT_PATH,
    EMAIL_NOTIFICATION_SETTING: `${AC_MY_ACCOUNT_EMAIL_NOTIFICATION_SETTING_PATH}/:financierId`,
    EMAIL_NOTIFICATION_SETTING_BUILD_PATH: (financierId: number) =>
      `${AC_MY_ACCOUNT_EMAIL_NOTIFICATION_SETTING_PATH}/${financierId}`,
  },
};
