import { useEffect } from 'react';

import useMounted from 'hooks/useMounted';

import PotentialPartnerHomeArListTable from './sections/ar-list-table/PotentialPartnerHomeArListTable';
import PotentialPartnerHomeBanner from './sections/banner/PotentialPartnerHomeBanner';
import PotentialPartnerHomeSteps from './sections/steps/PotentialPartnerHomeSteps';
import { usePotentialPartnerHomeState } from './usePotentialPartnerHomeState';
import PPSection from '../../../components/potential-partner/PPSection/PPSection';

function PotentialPartnerHomePage() {
  const mounted = useMounted();

  const { state, fetchAll, fetchAcquisitionSetting } = usePotentialPartnerHomeState();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  return (
    <div className="pp-home">
      <PPSection className="bg-warm-gray-30">
        <PotentialPartnerHomeBanner partnerName={state.partnerName} data={state.apTotalAmountByCurrency} />
      </PPSection>
      <PPSection className="bg-yellow-95">
        <PotentialPartnerHomeSteps />
      </PPSection>
      <PPSection className="pp-buyer-lists__wrapper">
        <PotentialPartnerHomeArListTable
          apAmountListByAnchorPartnerAccount={state.apAmountListByAnchorPartnerAccount}
          fetchAcquisitionSetting={fetchAcquisitionSetting}
        />
      </PPSection>
    </div>
  );
}

export default PotentialPartnerHomePage;
