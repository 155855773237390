import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface SupportedCurrencyTypeVO {
  id: number;
  currencyType: string;
  financierId: number;
  financierName: string;
  createdDateTime: string;
  updatedDateTime: string;
}

export interface SupportedCurrencyTypeVOModel extends SupportedCurrencyTypeVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToSupportedCurrencyTypeVOModel(data: SupportedCurrencyTypeVO): SupportedCurrencyTypeVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
