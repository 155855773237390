export const scrollIntoErrorElement = <T extends string>(errorFields: T[]): void => {
  if (errorFields === null) return;
  if (errorFields?.length === 0) return;

  const errorElements: HTMLElement[] = errorFields
    .map((name: T) => document.getElementsByName(name)[0])
    .filter((el: HTMLElement) => !!el);

  if (errorElements.length === 0) return;

  errorElements.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);

  if (window.getComputedStyle(errorElements[0]).display === 'none') {
    document.body.scrollTo(0, 0);
  }

  errorElements[0].scrollIntoView({ block: 'center' });
};

export const handleScrollLeft = (ref: React.RefObject<HTMLDivElement>, scrollLeftValue: number = 0) => {
  setTimeout(() => {
    if (ref.current) {
      ref.current.scrollLeft = scrollLeftValue;
    }
  }, 0);
};

export const scrollToTopOfElement = (elementClass: string = 'page-content') => {
  const containerDOM = document?.querySelector(`.${elementClass}`);
  if (containerDOM) containerDOM.scrollTo({ top: 0 });
};
