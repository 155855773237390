import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faMinus, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import type { CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE, OTP_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import DealerAPRequestModal from 'pages/dealer/register-ar/modals/DealerAPRequestModal';
import DealerArRegisterValidationErrorModal from 'pages/dealer/register-ar/modals/DealerArRegisterValidationErrorModal';
import type { BigNumber } from 'utils/bigNumber';
import { getSum } from 'utils/calculate';
import {
  convertToServerDateFormat,
  getAvailableSettlementDateRangeForRegistrationArOrAp,
  getDayTerm,
  removeRangeFromHoliday,
  verifySettlementDateForRegistrationArOrAp,
} from 'utils/date/date';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestTempArData, requestTempArRegister } from 'utils/http/api/common/temp-ar-summaries';
import { requestDealerAnchorAgreementDetail } from 'utils/http/api/dealer/anchor-agreements';
import { requestDealerArRegister } from 'utils/http/api/dealer/ar-summaries';
import type { DealerArRegisterRequest } from 'utils/http/api/dealer/ar-summaries/request';
import { requestDealerClientAuthByAnchorAgreementId } from 'utils/http/api/dealer/client-auth-setting';
import { requestDealerFinancierCalendar } from 'utils/http/api/dealer/financier-calendar';
import { initializeRefValue } from 'utils/initialize';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { invoiceOrArAmountInputValidate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';
import { getSignIn } from 'utils/storage/LocalStorage';
import useValidation from 'utils/validation/useValidation';

import type { TFunction } from 'i18next';

dayjs.extend(customParseFormat);

const MAX_ROW_LENGTH = 100;

const getFieldNames = (t: TFunction, options?: { currencyType?: CURRENCY_TYPE }) => {
  return {
    arNumber: {
      name: t('text:AR_Number_(Tax_Invoice_Number)'),
    },
    arIssuedDate: {
      name: t('text:AR_Issued_Date'),
    },
    settlementDate: {
      name: t('text:Settlement_Date'),
    },
    arAmount: {
      name: `${t('text:AR_Amount')} (${t('text:unit')}: ${options?.currencyType ?? ''})`,
    },
  };
};

function DealerApRegisterStep2() {
  const { t } = useTranslation(['format']);
  const [locationState, errorHandlerOfLocationState] = useLocationState<{
    financierId: number;
    anchorAgreementId: number;
  }>(['financierId', 'anchorAgreementId']);
  const { financierId, anchorAgreementId } = locationState;

  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();
  const userAuthorityType = getSignIn()?.authorityType;
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회
  const [isDirectInput, setIsDirectInput] = useState<boolean>(true); // direct input or excel upload state
  const [anchorAgreementDetail, setAnchorAgreementDetail] = useState<AnchorAgreementDetailVOModel>();
  const [file, setFile] = useState<File>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [isSavedFields, setIsSavedFields] = useState<boolean>(false); // register, save 유효성 검사 구분 짓기 위한 state
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [totalApAmount, setTotalApAmount] = useState<BigNumber>('0');
  const [availableSettlementDateRange, setAvailableSettlementDateRange] = useState<string[]>();
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();
  const [settlementDatePeriod, setSettlementDatePeriod] = useState({
    minimumDate: new Date(),
    maximumDate: new Date(),
  });

  const { register, control, errors, getValues, reset, trigger } = useForm<DealerArRegisterRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'arList',
  });

  const fieldNames = getFieldNames(t, { currencyType: anchorAgreementDetail?.currencyType });

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: errors.arList,
    rule: 'arList',
    accountType: 'AR',
    isSavedFields,
  });

  useEffect(() => {
    if (mounted) {
      // if temp data 가 있으면 data set 하는 함수 호출
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const updateTotalApAmount = useCallback(() => {
    const sum = getSum(getValues().arList, 'arAmount');
    setTotalApAmount(sum);
  }, [getValues]);

  useEffect(() => {
    updateTotalApAmount();
  }, [fields, updateTotalApAmount]);

  const fetchAll = async () => {
    try {
      const financierCommonSettingResponse = await requestFinancierSettingData(financierId);

      const date = new Date();
      const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];
      const { minimumPeriodForRegisterAr, maximumPeriodForRegisterAr } = financierCommonSettingResponse;
      const minimumDate = new Date(today[0], today[1], today[2] + minimumPeriodForRegisterAr);
      const maximumDate = new Date(today[0], today[1], today[2] + maximumPeriodForRegisterAr);

      const [anchorAgreementDetailData, tempArListResponse, fetchFinancierHoliday] = await Promise.all([
        requestDealerAnchorAgreementDetail(anchorAgreementId),
        requestTempArData(anchorAgreementId),
        requestDealerFinancierCalendar(financierId, {
          pageNumber: 0,
          rowCount:
            Number(getDayTerm(convertToServerDateFormat(minimumDate), convertToServerDateFormat(maximumDate))) + 1,
          fromDate: convertToServerDateFormat(minimumDate),
          toDate: convertToServerDateFormat(maximumDate),
          holiday: true,
        }),
      ]);
      const getHolidayArrays = fetchFinancierHoliday.content.map(item => item.solarDate);
      const availableDateRange = getAvailableSettlementDateRangeForRegistrationArOrAp(
        getHolidayArrays,
        minimumDate,
        maximumDate,
      );
      const [localInitialSettlementDate] = availableDateRange;

      ReactDOM.unstable_batchedUpdates(() => {
        setInitialSettlementDate(localInitialSettlementDate);
        setFinancierHoliday(fetchFinancierHoliday);
        setAnchorAgreementDetail(anchorAgreementDetailData);
        setAvailableSettlementDateRange(availableDateRange);
        setSettlementDatePeriod({ maximumDate, minimumDate });

        if (tempArListResponse.tempArList.content.length !== 0) {
          reset({
            arList: tempArListResponse.tempArList.content.map(item => {
              return {
                arNumber: item.arNumber,
                arAmount: item.arAmount,
                arIssuedDate: item.arIssuedDate,
                settlementDate: verifySettlementDateForRegistrationArOrAp(
                  item.settlementDate,
                  localInitialSettlementDate,
                  availableDateRange,
                ),
              };
            }),
          });
        } else {
          reset({
            arList: [
              {
                arNumber: undefined,
                arAmount: undefined,
                arIssuedDate: undefined,
                settlementDate: localInitialSettlementDate,
              },
            ],
          });
        }
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const apNumberDuplicateValidator = (value: string, index: number) => {
    const data = getValues();
    if (data.arList) {
      const getArNumbers = data.arList.map(item => item.arNumber);
      getArNumbers.splice(index, 1);
      const arNumbers = getArNumbers.filter(item => {
        if (item) return item;
      });

      if (arNumbers.includes(value)) {
        return false;
      }
    }

    return true;
  };

  const onClickRegister = async (e: any) => {
    e.preventDefault();
    setIsSavedFields(false);

    const verificationCode: UserVerificationCodeRequest = {};

    const data = getValues();

    const executeRegister = () => {
      return new Promise<void>(async resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1);
      }).then(async () => {
        await trigger().then(result => {
          setCheckedValidation(data.arList.map(() => true));
          unShowLoadingUI();
          if (!result) {
            modal.show(<DealerArRegisterValidationErrorModal />, {
              modalSize: ModalSize.NONE,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:Close'),
            });

            return; // FE validation error 있으면 return
          } else {
            // FRONT Validation 통과
            if (data) {
              modal.show(
                <DealerAPRequestModal
                  dataList={data.arList}
                  totalCount={fields.length}
                  totalAmount={totalApAmount}
                  currencyType={anchorAgreementDetail?.currencyType}
                />,
                {
                  modalSize: ModalSize.XL,
                  modalType: ModalType.CONFIRM,
                  closeBtnText: t('text:Cancel'),
                  confirmBtnCb: () => {
                    showUserVerificationModal();
                  },
                },
              );
            }
          }
        });
      });
    };

    await executeRegister();
    const onClickConfirm = async (): Promise<void> => {
      const requestedData = {
        ...getValues(),
        anchorAgreementId: Number(anchorAgreementId),
        otpCode: verificationCode.otpCode,
        queryValue: verificationCode.queryValue,
      };

      try {
        await requestDealerArRegister(requestedData);

        modal.show(
          <>
            <h6>{t('text:The_AR_registration_request_has_been_completed')}</h6>
            <h6>{t('text:You_can_check_the_registration_result_on_the_AR_confirmation_menu')}</h6>
          </>,
          {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => history.push(ROUTES_DE.REGISTER_AR.CONFIRMATION_LIST),
          },
        );
      } catch (error) {
        modal.show(error);
      }
    };

    const showUserVerificationModal = async () => {
      try {
        if (userAuthorityType === AUTHORITY_TYPE.AUTHORIZER) {
          const clientAuthTypeData = await requestDealerClientAuthByAnchorAgreementId(anchorAgreementId);
          if (clientAuthTypeData.otpType !== OTP_TYPE.NONE) {
            modal.show(
              <UserVerificationModal
                modalId={modal.id}
                verificationCode={verificationCode}
                requestIdType="anchorAgreementId"
                requestId={anchorAgreementId}
                clientAuthSetting={clientAuthTypeData}
              />,
              {
                modalType: ModalType.CONFIRM,
                title: t('text:User_Verification'),
                closeBtnText: t('text:Cancel'),
                confirmBtnCb: () => onClickConfirm(),
              },
            );
          } else {
            onClickConfirm();
          }
        } else {
          onClickConfirm();
        }
      } catch (e) {
        modal.show(e);
      }
    };
  };

  const onClickCancel = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_AR_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
        <br />
        {t('text:Click_the_Save_button_to_save_temporarily')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_DE.REGISTER_AR.REGISTRATION_STEP1),
      },
    );
  };

  const onClickSave = async () => {
    setIsSavedFields(prev => (prev ? prev : !prev));
    setCheckedValidation([]);

    // isSaveValidator = true 로 변경 후, ref 에 걸린 조건이 늦게 반영되므로 setTimeout 함수 추가
    new Promise<void>(async resolve => {
      showLoadingUI();
      setTimeout(() => {
        resolve();
      }, 1);
    }).then(async () => {
      unShowLoadingUI();

      const data = getValues();
      let isPass = true;

      for (let i = 0; i < data.arList?.length; i++) {
        const arAmountResult = await trigger(`arList.${i}.arAmount`);
        if (!arAmountResult) {
          isPass = false;
          // save 유효성 검사 실패 시 update해서 Validation Result 보여줌 (유효성 통과 시 Validation Result 숨김)
          setCheckedValidation(data.arList?.map(() => true));
        }
      }

      if (isPass) {
        modal.show(
          <h6>
            {t('text:Would_you_like_to_temporarily_save_the_entered_AR_information?')}
            <br />
            {t('text:Please_note_that_AR_will_not_be_registered_until_you_click_the_Register_button')}
          </h6>,
          {
            modalType: ModalType.CONFIRM,
            closeBtnText: t('text:Cancel'),
            confirmBtnCb: async () => {
              try {
                const requestedData = getValues();
                requestedData.anchorAgreementId = Number(anchorAgreementId);
                await requestTempArRegister(requestedData); // save temp data api
                savedCompleteModal();
              } catch (e) {
                modal.show(e);
              }
            },
          },
        );
      }
    });

    const savedCompleteModal = () => {
      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => history.push(ROUTES_DE.REGISTER_AR.REGISTRATION_STEP1),
      });
    };
  };

  const onClickRegistrationMethodRadioButton = (value: boolean) => {
    if (value !== isDirectInput) {
      setIsDirectInput(value);
    }
  };

  const renderDirectInput = () => {
    const appendRow = (e: any) => {
      e.preventDefault();
      if (fields.length >= MAX_ROW_LENGTH) {
        modal.show(
          <h6>
            {t('text:Allowed_N_rows_at_a_time', { number: MAX_ROW_LENGTH })} {t('text:Please_check_the_data_again')}
          </h6>,
        );
      } else {
        append({
          arNumber: undefined,
          arAmount: undefined,
          arIssuedDate: undefined,
          settlementDate: initialSettlementDate,
        });
      }
    };

    const removeRow = (e: any) => {
      const updateInValidState = () => {
        let i = 0;
        const temp = [...checkedValidation];

        for (const index of checkedRows) {
          temp.splice(index - i, 1);
          i++;
        }

        return temp;
      };

      e.preventDefault();
      if (checkedRows.length === fields.length) {
        reset({
          arList: [
            {
              arNumber: undefined,
              arAmount: undefined,
              arIssuedDate: undefined,
              settlementDate: initialSettlementDate,
            },
          ],
        });
        setCheckedValidation([]);
      } else {
        setCheckedValidation(updateInValidState());

        remove(checkedRows);
      }

      setCheckedRows([]);
    };

    const handleCheckAll = (e: any) => {
      if (e.target.checked) {
        const arr: number[] = [];
        fields.forEach((el, index) => arr.push(index));
        setCheckedRows(arr);
      } else {
        setCheckedRows([]);
      }
    };

    const handleCheckChange = (e: any, index: number) => {
      if (e.target.checked) {
        setCheckedRows([...checkedRows, index]);
      } else {
        setCheckedRows(checkedRows.filter(el => el !== index));
      }
    };

    return (
      <div className="content-area" data-testid="content-area">
        <div className="clearfix">
          <div className="flex-end mb-3">
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={removeRow}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <table className="table-border">
          <colgroup>
            <col style={{ width: '70px' }} />
            {values(fieldNames).map((_, index) => (
              <col key={index} />
            ))}
            {checkedValidation.length > 0 && <col />}
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <div className="text-center">
                  <input
                    className="form-check-input m-0"
                    type="checkbox"
                    value=""
                    id="allCheck2"
                    onChange={handleCheckAll}
                    checked={checkedRows.length === fields.length}
                  />
                </div>
              </th>
              {values(fieldNames).map(({ name }, index) => (
                <th key={index} scope="col">
                  {name}
                </th>
              ))}
              {checkedValidation.length > 0 && <th scope="col">{t('text:Validation_Result')}</th>}
            </tr>
          </thead>
          <tbody className="bg-white">
            {fields.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td key={index} className="bg-sub100">
                    <div className="text-center">
                      <input
                        className="form-check-input m-0"
                        type="checkbox"
                        onChange={e => handleCheckChange(e, index)}
                        checked={checkedRows.includes(index)}
                      />
                    </div>
                  </td>
                  <td className={getValidationClassName('arNumber', 'td', index)}>
                    <input
                      className={getValidationClassName('arNumber', 'input', index)}
                      name={`arList.${index}.arNumber`}
                      defaultValue={item.arNumber}
                      ref={register({
                        required: true,
                        validate: value => apNumberDuplicateValidator(value, index),
                      })}
                    />
                  </td>
                  <td className={getValidationClassName('arIssuedDate', 'td', index)}>
                    <div className="position-parent">
                      <Controller
                        control={control}
                        defaultValue={item.arIssuedDate}
                        name={`arList.${index}.arIssuedDate`}
                        render={field => (
                          <ReactDatePicker
                            field={field}
                            className={getValidationClassName('arIssuedDate', 'input', index)}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </td>
                  <td className={getValidationClassName('settlementDate', 'td', index)}>
                    <div className="position-parent">
                      <Controller
                        control={control}
                        defaultValue={item.settlementDate}
                        name={`arList.${index}.settlementDate`}
                        render={field => (
                          <ReactDatePicker
                            field={field}
                            className={getValidationClassName('settlementDate', 'input', index)}
                            minDate={settlementDatePeriod.minimumDate}
                            maxDate={settlementDatePeriod.maximumDate}
                            excludeDates={removeRangeFromHoliday(financierHoliday?.content)}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </td>
                  <td className={getValidationClassName('arAmount', 'td', index)}>
                    <input
                      type="text"
                      name={`arList.${index}.arAmount`}
                      defaultValue={item.arAmount}
                      className={getValidationClassName('arAmount', 'input', index) + ' text-end'}
                      ref={register({
                        validate: value =>
                          invoiceOrArAmountInputValidate(value, anchorAgreementDetail?.currencyType, 'AR'),
                        required: !isSavedFields,
                        min: !isSavedFields ? 0.01 : undefined,
                      })}
                      onChange={updateTotalApAmount}
                    />
                  </td>
                  {checkedValidation[index] ? getValidationResult(index) : checkedValidation.length > 0 && <td />}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="grid-total">
          {t('text:Total')} : {t('format:number', { value: totalApAmount })}
        </div>
        <div className="d-flex mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancel}
            className="me-auto"
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>

          <Button size={ButtonSizeEnum.LG} onClick={onClickSave} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Save')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onClickRegister} className="ms-2">
            {t('text:Register')}
          </Button>
        </div>
      </div>
    );
  };

  const renderExcelUpload = () => {
    const onChangeUploadExcel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const formatedData = (data: any) => {
        const formattedSettlementDate = t('format:original-date', {
          value: String(data[ExcelTemplatesHeader.SETTLEMENT_DATE]),
          key: 'no-line-date',
        });
        const formattedIssuedDate = t('format:original-date', {
          value: String(data[ExcelTemplatesHeader.AR_ISSUED_DATE]),
          key: 'no-line-date',
        });

        return {
          arNumber: data[ExcelTemplatesHeader.AR_NUMBER],
          arAmount: data[ExcelTemplatesHeader.AR_AMOUNT],
          // TODO: date valid check 를 datePicker component 에서 가능하도록..
          arIssuedDate: dayjs(formattedIssuedDate, 'YYYY-MM-DD', true).isValid() ? formattedIssuedDate : undefined,
          settlementDate: verifySettlementDateForRegistrationArOrAp(
            formattedSettlementDate,
            initialSettlementDate,
            availableSettlementDateRange,
          ),
        };
      };

      if (e.target.files && e.target.files[0]) {
        const isCsvFile = e.target.files[0].type === 'text/csv';

        try {
          showLoadingUI();
          const jsonArrayData = isCsvFile
            ? await csvToJson(e.target.files[0])
            : await excelToJson(e.target.files[0], MAX_ROW_LENGTH);

          setCheckedValidation([]);
          setFile(e.target.files[0]);

          if (isCsvFile && jsonArrayData && jsonArrayData.length !== 0) {
            const changedData = jsonArrayData.map((data: any) => formatedData(data));

            reset({
              arList: changedData,
            });
            updateTotalApAmount();

            return;
          }

          if (
            !isCsvFile &&
            jsonArrayData &&
            Array.isArray(jsonArrayData) &&
            jsonArrayData[0] &&
            Array.isArray(jsonArrayData[0]) &&
            jsonArrayData[0].length !== 0
          ) {
            const changedData = jsonArrayData[0].map((data: any) => formatedData(data));

            reset({
              arList: changedData,
            });
            updateTotalApAmount();

            return;
          }

          modal.show(
            <h6>
              {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
              <br />
              {t('text:Please_check_and_upload_it_again')}
            </h6>,
          );
        } catch (error) {
          modal.show(error);
          initializeRefValue(fileRef);
        } finally {
          unShowLoadingUI();
        }
      }
    };

    const onClickRemoveExcel = (e: any): void => {
      e.preventDefault();
      initializeRefValue(fileRef);

      if (file) {
        setFile(undefined);
      }
    };

    return (
      <div className="excel-form">
        <div className="excel-download-form d-flex">
          <label className="me-3">
            {t(
              'text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it_After_uploading_you_can_check_the_uploaded_list_below',
            )}
          </label>
          <ExcelTemplateDownloadButton
            templateFileAddress="/templates/ARRegistrationTemplate.xlsx"
            style={{ marginRight: '4px' }}
          />
          <ExcelTemplateDownloadButton
            downloadAnnounceText={t('text:CSV_Template')}
            templateFileAddress="/templates/ARRegistrationTemplate.csv"
          />
        </div>
        <div className="detail-in-file-upload-form bg-sub100">
          <div className="d-flex justify-content-between">
            <input
              ref={fileRef}
              onChange={onChangeUploadExcel}
              type="file"
              name="file"
              id="FileUpload"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: 'none' }}
            />
            <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
              {t('text:Attach_File')}
            </label>
            <div id="fileName" className="upload-file-input">
              {file ? file.name : t('text:No_file_attached')}
            </div>
            <IconButton onClick={onClickRemoveExcel} className={`delete-uploaded-excel-button ${!file && 'd-none'}`}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AR_Registration_Request')} onClick={onClickCancel} />
      <div className="content-area">
        <StepWizard nth={2} title={[t('text:Select_Anchor_Master_Agreement'), t('text:Enter_AR_Information')]} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Anchor_Master_Agreement_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} col={3} value={anchorAgreementDetail?.financierName} />
              <FormValue label={t('text:Anchor_Name')} col={3} value={anchorAgreementDetail?.financierClientName} />
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                col={3}
                value={anchorAgreementDetail?.contractNo}
              />
              <FormValue label={t('text:Currency')} col={3} value={anchorAgreementDetail?.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {anchorAgreementDetail?.collateralIssuedLimitCheck && (
        <div className="content-area">
          <SectionTitle title={t('text:AR_Registration_Limit')} />
          <div className="information-form__corporation">
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:AR_Issuance_Limit')} ①</div>
                <div className="col-6">
                  {t('format:number', { value: anchorAgreementDetail?.collateralIssuedLimitAmount })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Registration_Confirmed_AR')} ②</div>
                <div className="col-6">
                  {t('format:number', { value: anchorAgreementDetail?.settlementWaitingInvoiceAmount })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Registration_Requested_AR')} ③</div>
                <div className="col-6">
                  {t('format:number', { value: anchorAgreementDetail?.registeredWaitingArAmount })}
                </div>
              </div>
            </div>
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:Issuance_Limit_Available_for_Registration')} ④ = ① - ② - ③</div>
                <div className="col-6 blackfont">
                  {t('format:number', {
                    value: anchorAgreementDetail?.remainingRegistrationLimit,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-area">
        <h3>{t('text:Select_AR_Registration_method')}</h3>
        <div className="invoice-radio-group">
          <RadioButton
            id="direct-input"
            name="registration-method"
            checked={isDirectInput}
            onChange={() => onClickRegistrationMethodRadioButton(true)}
          >
            {t('text:Direct_Input')}
          </RadioButton>
          <RadioButton
            id="file-upload"
            name="registration-method"
            checked={!isDirectInput}
            onChange={() => onClickRegistrationMethodRadioButton(false)}
          >
            {t('text:File_Upload')}
          </RadioButton>
        </div>
        {!isDirectInput && renderExcelUpload()}
      </div>
      {renderDirectInput()}
    </>
  );
}

export default DealerApRegisterStep2;
