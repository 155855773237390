import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from 'components/stateless/Button/IconButton';
import type { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import QuestionButton from 'components/stateless/QuestionButton';
import type { HeaderType } from 'components/stateless/Table/TableHeader';

interface TableHeaderProps {
  header: HeaderType[];
  backGroundType?: BackGroundType;
}

const TableHeader = ({ header, backGroundType }: TableHeaderProps) => {
  return (
    <>
      <colgroup>
        {header.map((value, index) => (
          <col key={index} style={{ width: `${value.colWidths}px` }} />
        ))}
      </colgroup>
      <thead>
        <tr>
          {header.map((value, index) => (
            <th scope="col" key={index} className={`${value.className} ${backGroundType}`}>
              {value.headerText}
              {value.hasStatusDescription && <QuestionButton onClick={value.showStatusDescriptionFunc} />}
              {value.hasSort && (
                <IconButton className="sm-sort-button" onClick={() => value.sortFunc && value.sortFunc()}>
                  <FontAwesomeIcon icon={faSort} />
                </IconButton>
              )}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
};

export default TableHeader;
