import type { FormEvent } from 'react';
import { useCallback } from 'react';

export const useUppercaseInput = () => {
  const handleOnInput = useCallback((event: FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget;
    const cursorPosition = input.selectionStart;

    input.value = input.value.toUpperCase();
    input.setSelectionRange(cursorPosition, cursorPosition);
  }, []);

  return handleOnInput;
};
