import { isNil } from 'lodash-es';

import { BASIS_INTEREST_TYPE, INTEREST_REPAYMENT_TYPE } from 'enums';
import { convertToString } from 'utils/formatter';

import type { AnchorFinancingOptionDetailVOModel } from '../vo/AnchorFinancingOptionDetailVO';
import type { WaitingAnchorFinancingOptionDetailVOModel } from '../vo/WaitingAnchorFinancingOptionDetailVO';

export type AnchorFinancingOptionCommonVOModel = AnchorFinancingOptionDetailVOModel &
  WaitingAnchorFinancingOptionDetailVOModel;

type CommonOptionFields = Pick<
  AnchorFinancingOptionDetailVOModel,
  keyof AnchorFinancingOptionDetailVOModel & keyof WaitingAnchorFinancingOptionDetailVOModel
>;

type CombinedAgreementFields = CommonOptionFields & Partial<AnchorFinancingOptionCommonVOModel>;

// 약정 정보를 공통으로 관리하는 데이터 변환 함수
export const convertToAnchorFinancingOptionCommonVOModel = (
  data: CombinedAgreementFields,
): AnchorFinancingOptionCommonVOModel => {
  const agreementData = data as AnchorFinancingOptionCommonVOModel;

  return agreementData;
};

export const convertToAnchorFinancingOptionResetData = (data: AnchorFinancingOptionCommonVOModel) => {
  return {
    ...data,
    currencyType: data.currencyType || undefined,
    termSpreadList: data.termSpreadList || undefined,
    disbursementDateOverAllowable: convertToString(data.disbursementDateOverAllowable),
    repaymentDateOverAllowable: convertToString(data.repaymentDateOverAllowable),
    loanLimitCheck: convertToString(data.loanLimitCheck),
    anchorAccount: convertToString(data.anchorAccount),
    basisInterestType:
      data.basisInterestType === BASIS_INTEREST_TYPE.FIXED || isNil(data.basisInterestType)
        ? data.basisInterestType
        : BASIS_INTEREST_TYPE.CUSTOM,
    showNetDisbursementAmount:
      data.interestRepaymentType === INTEREST_REPAYMENT_TYPE.EARLIER
        ? convertToString(data.showNetDisbursementAmount)
        : undefined,
  };
};
