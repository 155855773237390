import { useCallback, useState } from 'react';

import useNewNotification from 'hooks/useNewNotification';
import { getSignIn } from 'utils/storage/LocalStorage';

export function useNotificationButtonController() {
  const role = getSignIn()?.authorities[0].authority;

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const { hasNewNotification, updateHasNewNotification } = useNewNotification();

  const handleNotificationButtonClick = () => setIsNotificationOpen(true);

  const closeNotification = useCallback(() => {
    setIsNotificationOpen(false);
    updateHasNewNotification();
  }, [updateHasNewNotification]);

  return {
    role,
    isNotificationOpen,
    hasNewNotification,
    handleNotificationButtonClick,
    closeNotification,
    updateHasNewNotification,
  };
}
