import { useTranslation } from 'react-i18next';

import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import type { StatisticsOfAnchorAgreementVOModel } from 'models/vo/StatisticsOfAnchorAgreementVO';
import type { ColumnOption, MergedCellTypes } from 'utils/spreadSheet/types';

export function useFiOverviewByAnchorListChannelTabConstants() {
  const { t } = useTranslation(['format']);

  const INVOICE_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Client_Code'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Outstanding_Financing_Balance_/_Count'),
      className: 'main100',
    },
    {
      headerText: t('text:Pending_Invoice_Amount_/_Count'),
      className: 'main100',
    },
    {
      headerText: t('text:Total_Registered_Invoice_Amount_/_Count'),
      className: 'main200',
    },
    {
      headerText: t('text:Total_Financing_Amount_/_Count'),
      className: 'main200',
    },
    {
      headerText: t('text:Total_Nonproceeded_Invoice_Amount_/_Count'),
      className: 'main200',
    },
  ];
  const INVOICE_BIG_TABLE_HEADER: BigHeaderType[] = [
    {
      headerText: t('text:Anchor_Information'),
      colSpan: 4,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 2,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 3,
      className: 'main200',
    },
  ];

  const excelColumnsInvoice: ColumnOption<StatisticsOfAnchorAgreementVOModel>[] = [
    {
      header: t('text:Anchor_Name'),
      key: 'anchorFinancierClientName',
    },
    {
      header: t('text:Anchor_Client_Code'),
      key: 'anchorFinancierClientCode',
    },
    {
      header: t('text:Anchor_Master_Agreement_Number'),
      key: 'contractNo',
    },
    {
      header: t('text:Currency'),
      key: 'currencyType',
    },
    {
      header: t('text:Outstanding_Financing_Balance'),
      key: 'currentLoanBalanceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Outstanding_Financing'),
      key: 'currentDisbursedLoanCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Pending_Invoice_Amount'),
      key: 'currentSettlementWaitingInvoiceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Pending_Invoices'),
      key: 'currentSettlementWaitingInvoiceCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Registered_Invoice_Amount'),
      key: 'registeredInvoiceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Registered_Invoices'),
      key: 'registeredInvoiceCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Financing_Amount'),
      key: 'totalDisbursedLoanAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Disbursed_Financing'),
      key: 'totalDisbursedLoanCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Amount_of_Not_Proceeded_Invoices'),
      key: 'totalUnsettledInvoiceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Not_Proceeded_Invoices'),
      key: 'totalUnsettledInvoiceCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
  ];

  const invoiceExcelMergedCells: MergedCellTypes[] = [
    {
      target: 'A:D',
      rowIndex: 1,
      value: t('text:Anchor_Information'),
    },
    {
      target: 'E:H',
      rowIndex: 1,
      value: t('text:Current_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFFCDE' },
        },
      },
    },
    {
      target: 'I:N',
      rowIndex: 1,
      value: t('text:Accumulated_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFF8BD' },
        },
      },
    },
  ];
  const invoicePdfMergedTableHeader: BigHeaderType[] = [
    {
      headerText: t('text:Anchor_Information'),
      colSpan: 4,
      className: 'main100',
    },
    {
      headerText: t('text:Associated_Partner_Current_Usage_Information'),
      colSpan: 4,
      className: 'main200',
    },
    {
      headerText: t('text:Associated_Partner_Accumulated_Usage_Information'),
      colSpan: 6,
      className: 'main300',
    },
  ];

  const invoicePdfTableHeader: HeaderType[] = [
    {
      headerText: t('text:Anchor_Name'),
      className: 'main100',
    },
    {
      headerText: t('text:Anchor_Client_Code'),
      className: 'main100',
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      className: 'main100',
    },
    {
      headerText: t('text:Currency'),
      className: 'main100',
    },
    {
      headerText: t('text:Outstanding_Financing_Balance'),
      className: 'main200',
    },
    {
      headerText: t('text:Number_of_Outstanding_Financing'),
      className: 'main200',
    },
    {
      headerText: t('text:Invoice_Amount_to_be_paid'),
      className: 'main200',
    },
    {
      headerText: t('text:Number_of_Invoices_to_be_paid'),
      className: 'main200',
    },
    {
      headerText: t('text:Registered_Invoice_Amount'),
      className: 'main300',
    },
    {
      headerText: t('text:Number_of_Registered_Invoices'),
      className: 'main300',
    },
    {
      headerText: t('text:Total_Financing_Amount'),
      className: 'main300',
    },
    {
      headerText: t('text:Total_Number_of_Financing'),
      className: 'main300',
    },
    {
      headerText: t('text:Total_Amount_of_Invoices_Not_Proceeded'),
      className: 'main300',
    },
    {
      headerText: t('text:Total_Number_of_Invoices_Not_Proceeded'),
      className: 'main300',
    },
  ];

  return {
    INVOICE_TABLE_HEADER,
    INVOICE_BIG_TABLE_HEADER,
    excelColumnsInvoice,
    invoiceExcelMergedCells,
    invoicePdfMergedTableHeader,
    invoicePdfTableHeader,
  };
}
