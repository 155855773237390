import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { CostOfFundVOModel } from 'models/vo/CostOfFundVO';
import type { OverdueInterestRateSettingChangeHistoryVOModel } from 'models/vo/OverdueInterestRateSettingChangeHistoryVO';
import type { OverdueInterestRateSettingDetailVOModel } from 'models/vo/OverdueInterestRateSettingDetailVO';
import type { OverdueInterestRateTermSpreadVOModel } from 'models/vo/OverdueInterestRateTermSpreadVO';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFinancierOverdueInterestRateSettingsDetail,
  requestFinancierOverdueInterestRateSettingsHistories,
  requestUpdateFinancierOverdueInterestRateSettingsCostOfFund,
  requestUpdateFinancierOverdueInterestRateSettingsDetail,
  requestUpdateFinancierOverdueInterestRateSettingsTermSpread,
} from 'utils/http/api/financier/overdue-interest-rate-settings';
import type {
  OverdueInterestRateTermSpreadRequest,
  UpdateFiOverdueInterestRateSettingTermSpreadRequest,
} from 'utils/http/api/financier/overdue-interest-rate-settings/request';
import type { UpdateFiOverdueInterestRateSettingCostOfFundRequest } from 'utils/http/api/financier/overdue-interest-rate-settings/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import { DELINQUENT_INTEREST_RATE_FIELD_NAME } from '../sections/delinquent-interest-rate-term-spread-form';

const useFinancierDelinquentInterestRateSettingDetail = () => {
  const { overdueInterestRateSettingId } = useParams<any>();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();

  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const termSpreadFormMethods = useForm<{
    [DELINQUENT_INTEREST_RATE_FIELD_NAME]: OverdueInterestRateTermSpreadRequest[];
  }>();
  const costOfFundFormMethods = useForm<UpdateFiOverdueInterestRateSettingCostOfFundRequest>();

  const [delinquentInterestRateSettingDetail, setDelinquentInterestRateSettingDetail] =
    useState<OverdueInterestRateSettingDetailVOModel>();
  const [delinquentInterestRateSettingHistories, setDelinquentInterestRateSettingHistories] =
    useState<OverdueInterestRateSettingChangeHistoryVOModel[]>();

  const [costOfFundEditable, setCostOfFundEditable] = useState<boolean>(false);
  const [termSpreadEditable, setTermSpreadEditable] = useState<boolean>(false);
  const [detailHistories, setDetailHistories] = useState<{
    [id: number]: {
      termSpreadHistories: OverdueInterestRateTermSpreadVOModel[];
      costOfFundHistories: CostOfFundVOModel;
    };
  }>([]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const resetTermSpreadData = (data: OverdueInterestRateSettingDetailVOModel) => {
    const termSpreadsFormData = data.termSpreads.map(item => ({
      minimumDateRange: item.minimumDateRange,
      termSpreadRate: item.termSpreadRate,
    }));
    termSpreadFormMethods.reset({
      [DELINQUENT_INTEREST_RATE_FIELD_NAME]: termSpreadsFormData,
    });
  };
  const resetCostOfFundData = (data: OverdueInterestRateSettingDetailVOModel) => {
    isNil(data.currentCostOfFundId)
      ? costOfFundFormMethods.reset({
          useCostOfFund: 'false',
        })
      : costOfFundFormMethods.reset({
          useCostOfFund: 'true',
          costOfFund: data.costOfFund!,
        });
  };

  const { pageable, setPageable } = usePageable();

  const paginate = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    await fetchAll(pageNumber, sizePerPage);
  };

  const fetchAll = async (pageNumber: number = 1, sizePerPage: number = 10) => {
    try {
      const [fetchedDelinquentInterestRateSettingDetail, fetchedDelinquentInterestRateSettingHistories] =
        await Promise.all([
          requestFinancierOverdueInterestRateSettingsDetail(overdueInterestRateSettingId),
          requestFinancierOverdueInterestRateSettingsHistories(pageNumber, sizePerPage, overdueInterestRateSettingId),
        ]);

      setDelinquentInterestRateSettingDetail(fetchedDelinquentInterestRateSettingDetail);
      setDelinquentInterestRateSettingHistories(fetchedDelinquentInterestRateSettingHistories.content);
      setPageable(fetchedDelinquentInterestRateSettingHistories);
      resetTermSpreadData(fetchedDelinquentInterestRateSettingDetail);
      resetCostOfFundData(fetchedDelinquentInterestRateSettingDetail);
      setTermSpreadEditable(false);
      setCostOfFundEditable(false);
      setDetailHistories({});
    } catch (error) {
      modal.show(error);
    }
  };

  const handleClickActivateButton = () => {
    const requestActivate = async () => {
      try {
        await requestUpdateFinancierOverdueInterestRateSettingsDetail(overdueInterestRateSettingId, {
          activated: true,
        });

        modal.show(<h6>{t('text:The_delinquent_interest_rate_has_been_activated')}</h6>, {
          closeBtnCb: () => {
            fetchAll();
          },
        });
      } catch (e) {
        modal.show(e);
      }
    };

    modal.show(t('text:Would_you_like_to_activate_the_delinquent_interest_rate?'), {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: async () => {
        setDetailHistories({});
        await requestActivate();
      },
    });
  };

  const handleClickDeactivateButton = () => {
    const requestDeactivate = async () => {
      try {
        await requestUpdateFinancierOverdueInterestRateSettingsDetail(overdueInterestRateSettingId, {
          activated: false,
        });

        modal.show(
          <h6>
            {t('text:The_delinquent_interest_rate_has_been_deactivated')} <br />
            {t('text:To_reactivate_click_the_Activate_button')}
          </h6>,
          {
            closeBtnCb: () => {
              fetchAll();
            },
          },
        );
      } catch (e) {
        modal.show(e);
      }
    };

    modal.show(t('text:Would_you_like_to_deactivate_the_delinquent_interest_rate?'), {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: async () => {
        setDetailHistories({});
        await requestDeactivate();
      },
    });
  };

  const TermSpreadEditButtons = () => {
    const handleClickEditButton = () => {
      setTermSpreadEditable(true);
    };

    const handleClickCancelButton = (e: any) => {
      e.preventDefault();

      modal.show(<h6>{t('text:Would_you_like_to_cancel_this_process?')}</h6>, {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => {
          setTermSpreadEditable(false);
          setDetailHistories({});
          resetTermSpreadData(delinquentInterestRateSettingDetail!);
        },
      });
    };

    const handleClickSaveButton = async (e: any) => {
      e.preventDefault();
      const data = termSpreadFormMethods.getValues();

      try {
        await requestUpdateFinancierOverdueInterestRateSettingsTermSpread(overdueInterestRateSettingId, {
          termSpreads: data.termSpreads,
        });

        modal.show(t('text:The_delinquent_interest_rate_has_been_edited_successfully'), {
          closeBtnCb: () => {
            fetchAll();
          },
        });
      } catch (e) {
        modal.show(e);
        formErrorHandler<UpdateFiOverdueInterestRateSettingTermSpreadRequest>(
          e,
          termSpreadFormMethods.setError,
          termSpreadFormMethods.clearErrors,
        );
      }
    };

    return (
      <>
        {termSpreadEditable ? (
          <>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={handleClickCancelButton}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={handleClickSaveButton}>
              {t('text:Save')}
            </Button>
          </>
        ) : (
          <Button size={ButtonSizeEnum.SM} onClick={handleClickEditButton}>
            {t('text:Edit')}
          </Button>
        )}
      </>
    );
  };

  const CostOfFundEditButtons = () => {
    const handleClickEditButton = () => {
      setCostOfFundEditable(true);
    };

    const handleClickCancelButton = (e: any) => {
      e.preventDefault();

      modal.show(<h6>{t('text:Would_you_like_to_cancel_this_process?')}</h6>, {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => {
          setCostOfFundEditable(false);
          setDetailHistories({});
          resetCostOfFundData(delinquentInterestRateSettingDetail!);
        },
      });
    };

    const handleClickSaveButton = async (e: any) => {
      e.preventDefault();
      const formData = costOfFundFormMethods.getValues();

      try {
        await requestUpdateFinancierOverdueInterestRateSettingsCostOfFund(overdueInterestRateSettingId, formData);
        modal.show(t('text:The_delinquent_interest_rate_has_been_edited_successfully'), {
          closeBtnCb: async () => {
            fetchAll();
          },
        });
      } catch (e) {
        modal.show(e);
        formErrorHandler<UpdateFiOverdueInterestRateSettingCostOfFundRequest>(
          e,
          costOfFundFormMethods.setError,
          costOfFundFormMethods.clearErrors,
        );
      }
    };

    return (
      <>
        {costOfFundEditable ? (
          <>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={handleClickCancelButton}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={handleClickSaveButton}>
              {t('text:Save')}
            </Button>
          </>
        ) : (
          <Button size={ButtonSizeEnum.SM} onClick={handleClickEditButton}>
            {t('text:Edit')}
          </Button>
        )}
      </>
    );
  };

  const ActiveButton = () => {
    return (
      <Button
        size={ButtonSizeEnum.SM}
        variant={ButtonVariantEnum.OUTLINED}
        color={ButtonColorEnum.BLUE}
        onClick={handleClickActivateButton}
      >
        {t('text:Activate')}
      </Button>
    );
  };

  const DeActiveButton = () => {
    return (
      <Button
        size={ButtonSizeEnum.SM}
        variant={ButtonVariantEnum.OUTLINED}
        color={ButtonColorEnum.RED}
        onClick={handleClickDeactivateButton}
      >
        {t('text:Deactivate')}
      </Button>
    );
  };

  return {
    delinquentInterestRateSettingDetail,
    delinquentInterestRateSettingHistories,
    detailHistories,
    setDetailHistories,
    isAdmin,
    termSpreadEditable,
    costOfFundEditable,
    termSpreadFormMethods,
    costOfFundFormMethods,
    pageable,
    paginate,
    CostOfFundEditButtons,
    TermSpreadEditButtons,
    ActiveButton,
    DeActiveButton,
  };
};

export default useFinancierDelinquentInterestRateSettingDetail;
