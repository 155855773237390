export interface TermSpreadVO {
  termSpreadId: number;
  minimumDateRange: number;
  termSpreadRate: number;
}

export interface TermSpreadVOModel extends TermSpreadVO {}

export function formattingToTermSpreadVOModel(data: TermSpreadVO): TermSpreadVOModel {
  return {
    ...data,
  };
}
