import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import RadioButton from 'components/stateless/Button/RadioButton';
import {
  FormBorder,
  FormContents,
  FormInput,
  FormRadio,
  FormSelect,
  FormSubtitle,
  FormValue,
  NumericFormatInput,
} from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import SupportedCurrencySettingModal from 'components/stateless/Modal/common/SupportedCurrencySettingModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import type { OTP_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import { AUTHORITY_TYPE, CURRENCY_TYPE, FINANCIER_INTERFACE_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SupportedCurrencyTypeVOModel } from 'models/vo/SupportedCurrencyTypeVO';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestSystemEnterpriseDetail,
  requestSystemEnterpriseFinancierAuthSetting,
  requestSystemEnterpriseFinancierAuthSettingUpdate,
  requestSystemEnterpriseFinancierInterface,
  requestSystemEnterpriseFinancierInterfaceUpdate,
  requestSystemEnterpriseUpdate,
} from 'utils/http/api/system/enterprises';
import type {
  SystemEnterpriseFinancierAuthSettingUpdateRequest,
  SystemEnterpriseUpdateRequest,
} from 'utils/http/api/system/enterprises/requests';
import {
  requestFinancierCommonSetting,
  requestFinancierCommonSettingLogoDownload,
  requestFinancierCommonSettingLogoUpdate,
} from 'utils/http/api/system/financier-common-setting';
import type { UpdateFinancierCommonSettingLogoRequest } from 'utils/http/api/system/financier-common-setting/requests';
import {
  requestSystemDeleteSupportedCurrencyType,
  requestSystemRegisterSupportedCurrencyType,
  requestSystemSupportedCurrencyTypeList,
} from 'utils/http/api/system/supported-currency-types';
import { requestSystemUserList } from 'utils/http/api/system/users';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

interface PlatFormSetting {
  keyName: FINANCIER_INTERFACE_TYPE;
  value: string;
}

function SystemFinancierDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const enterpriseId = (useParams() as any).id;
  const [attachFileName, setAttachFileName] = useState<string>('');
  const [financierType, setFinancierType] = useState<string>('');
  const [financierSubType, setFinancierSubType] = useState<string>('');
  const [hasSubDomain, setHasSubDomain] = useState<boolean>(false);
  const [isSubDomainDisabled, setSubDomainDisabled] = useState<boolean>(true);

  const {
    register: updateFormRegister,
    getValues: getUpdateFormValues,
    errors: updateFormErrors,
    setError: setUpdateFormErrors,
    clearErrors: setUpdateFormClear,
    reset: updateFormReset,
  } = useForm<SystemEnterpriseUpdateRequest>();
  const {
    register: updateFinancierLogoFileRegister,
    getValues: getUpdateFinancierLogoFileValues,
    errors: updateFinancierLogoFileErrors,
    setError: setUpdateFinancierLogoFileErrors,
    clearErrors: setUpdateFinancierLogoFileClear,
    reset: updateFinancierLogoFileReset,
    setValue: updateFinancierLogoFileSetValue,
    watch,
    control,
  } = useForm<UpdateFinancierCommonSettingLogoRequest>();
  const { settlementDateIsTodayInvoiceUploadable, supportedCollateralType } = watch();
  const {
    register: updateOtpRegister,
    getValues: getUpdateOtpValues,
    reset: updateOtpValuesReset,
    errors: updateOtpErrors,
    setError: setUpdateOtpErrors,
    clearErrors: setUpdateOtpClear,
  } = useForm<SystemEnterpriseFinancierAuthSettingUpdateRequest>();

  const { pageable: supportedCurrencyTypesPageable, setPageable: setSupportedCurrencyTypesPageable } = usePageable();

  const [isFinancierInformationEditable, setIsFinancierInformationEditable] = useState<boolean>(false);
  const [isCommonSettingEditable, setIsCommonSettingEditable] = useState<boolean>(false);
  const [isPlatformSettingsEditable, setIsPlatformSettingsEditable] = useState<boolean>(false);
  const [isClientAuthSettingEditable, setIsClientAuthSettingEditable] = useState<boolean>(false);
  const [platformSettingList, setPlatformSettingList] = useState<PlatFormSetting[]>([]);
  const [supportedCurrencyTypeList, setSupportedCurrencyTypeList] =
    useState<Pageable<SupportedCurrencyTypeVOModel[]>>();
  const [financierCommonSettingId, setFinancierCommonSettingId] = useState<number>(-1);

  const SUPPORTED_CURRENCY_TYPES_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Currency'),
      colWidths: 200,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const onChangePlatformSetting = (e: any) => {
    const platformSettingIndex = platformSettingList.map(item => item.keyName).indexOf(e.target.name);

    platformSettingIndex >= 0
      ? setPlatformSettingList(prev => {
          const dummy = [...prev];
          dummy[platformSettingIndex].value = e.target.value;

          return dummy;
        })
      : setPlatformSettingList(prev => [...prev, { keyName: e.target.name, value: e.target.value }]);
  };

  const isPlatformSettingOptionChecked = (settingName: FINANCIER_INTERFACE_TYPE, value: boolean) => {
    const indexOfSettingName = platformSettingList.map(item => item.keyName).indexOf(settingName);

    return indexOfSettingName >= 0 && platformSettingList[indexOfSettingName].value === String(value);
  };

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    if (!isCommonSettingEditable) setSubDomainDisabled(true);
  }, [isCommonSettingEditable]);

  useEffect(() => {
    if (supportedCollateralType === 'INVOICE') {
      updateFinancierLogoFileSetValue('factoringEnable', 'false');
    }
  }, [supportedCollateralType, updateFinancierLogoFileSetValue]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [
        financierInformationData,
        financierLogoFileData,
        otpSettingData,
        supportedCurrencyTypes,
        platformSettingData,
      ] = await Promise.all([
        requestSystemEnterpriseDetail(enterpriseId),
        requestFinancierCommonSetting(enterpriseId),
        requestSystemEnterpriseFinancierAuthSetting(enterpriseId),
        requestSystemSupportedCurrencyTypeList(
          supportedCurrencyTypesPageable.currentPage,
          supportedCurrencyTypesPageable.sizePerPage,
          { financierId: enterpriseId },
        ),
        requestSystemEnterpriseFinancierInterface(enterpriseId),
      ]);

      updateFormReset({
        enterpriseName: financierInformationData.enterpriseName,
        businessCode: financierInformationData.businessCode,
        address: financierInformationData.address,
        telephone: financierInformationData.telephone,
        fax: financierInformationData.fax,
        taxCode: financierInformationData.taxCode,
        representativeName: financierInformationData.representativeName,
        representativeEmployeeCode: financierInformationData.representativeEmployeeCode,
        representativeEmail: financierInformationData.representativeEmail,
        representativeMobile: financierInformationData.representativeMobile,
        representativePosition: financierInformationData.representativePosition,
      });

      updateFinancierLogoFileReset({
        financierId: enterpriseId,
        supportedCollateralType: financierLogoFileData.supportedCollateralType,
        settlementDateIsTodayInvoiceUploadable: String(financierLogoFileData.settlementDateIsTodayInvoiceUploadable),
        potentialPartnerRegistrable: String(financierLogoFileData.potentialPartnerRegistrable),
        communicatorRootPath: financierLogoFileData.communicatorRootPath,
        subDomain: financierLogoFileData.subDomain,
        disburseDateBusinessDayConvention: financierLogoFileData.disburseDateBusinessDayConvention,
        repaymentDateBusinessDayConvention: financierLogoFileData.repaymentDateBusinessDayConvention,
        invoiceWillBeSettledOnUploadDate: String(financierLogoFileData.invoiceWillBeSettledOnUploadDate),
        minimumPeriodForRegisterAr: financierLogoFileData.minimumPeriodForRegisterAr,
        maximumPeriodForRegisterAr: financierLogoFileData.maximumPeriodForRegisterAr,
        showOnlyAllowedBranchData: String(financierLogoFileData.showOnlyAllowedBranchData),
        divisionRegistrable: String(financierLogoFileData.divisionRegistrable),
        factoringEnable: String(financierLogoFileData.factoringEnable),
        eSignatureEnable: String(financierLogoFileData.eSignatureEnable),
        arCommissionEnable: String(financierLogoFileData.arCommissionEnable),
        anchorLoan: String(financierLogoFileData.anchorLoan),
      });

      updateOtpValuesReset({
        otpType: otpSettingData.otpType,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setAttachFileName(financierLogoFileData.attachmentFileName);
        setPlatformSettingList(
          platformSettingData.map(item => {
            return {
              keyName: item.financierInterfaceType,
              value: String(item.enabled),
            };
          }),
        );
        setFinancierCommonSettingId(financierLogoFileData.financierCommonSettingId);
        setFinancierType(financierLogoFileData.financierType);
        setFinancierSubType(financierLogoFileData.financierSubType);
        setHasSubDomain(Boolean(financierLogoFileData.subDomain));
        setSupportedCurrencyTypeList(supportedCurrencyTypes);
        setSupportedCurrencyTypesPageable(supportedCurrencyTypes);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const supportedCurrencyTypesPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const supportedCurrencyTypes = await requestSystemSupportedCurrencyTypeList(pageNumber, rowCount, {
        financierId: enterpriseId,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setSupportedCurrencyTypeList(supportedCurrencyTypes);
        setSupportedCurrencyTypesPageable(supportedCurrencyTypes);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const refreshFinancierInformationForm = async () => {
    setIsFinancierInformationEditable(false);

    const financierInformationData = await requestSystemEnterpriseDetail(enterpriseId);

    updateFormReset({
      enterpriseName: financierInformationData.enterpriseName,
      businessCode: financierInformationData.businessCode,
      address: financierInformationData.address,
      telephone: financierInformationData.telephone,
      fax: financierInformationData.fax,
      taxCode: financierInformationData.taxCode,
      representativeName: financierInformationData.representativeName,
      representativeEmployeeCode: financierInformationData.representativeEmployeeCode,
      representativeEmail: financierInformationData.representativeEmail,
      representativeMobile: financierInformationData.representativeMobile,
      representativePosition: financierInformationData.representativePosition,
    });
  };

  const refreshFinancierLogoFileForm = async () => {
    setIsCommonSettingEditable(false);
    try {
      const financierLogoFileData = await requestFinancierCommonSetting(enterpriseId);

      updateFinancierLogoFileReset({
        financierId: enterpriseId,
        supportedCollateralType: financierLogoFileData.supportedCollateralType,
        settlementDateIsTodayInvoiceUploadable: String(financierLogoFileData.settlementDateIsTodayInvoiceUploadable),
        potentialPartnerRegistrable: String(financierLogoFileData.potentialPartnerRegistrable),
        communicatorRootPath: financierLogoFileData.communicatorRootPath,
        subDomain: financierLogoFileData.subDomain,
        disburseDateBusinessDayConvention: financierLogoFileData.disburseDateBusinessDayConvention,
        repaymentDateBusinessDayConvention: financierLogoFileData.repaymentDateBusinessDayConvention,
        invoiceWillBeSettledOnUploadDate: String(financierLogoFileData.invoiceWillBeSettledOnUploadDate),
        minimumPeriodForRegisterAr: financierLogoFileData.minimumPeriodForRegisterAr,
        maximumPeriodForRegisterAr: financierLogoFileData.maximumPeriodForRegisterAr,
        showOnlyAllowedBranchData: String(financierLogoFileData.showOnlyAllowedBranchData),
        divisionRegistrable: String(financierLogoFileData.divisionRegistrable),
        factoringEnable: String(financierLogoFileData.factoringEnable),
        eSignatureEnable: String(financierLogoFileData.eSignatureEnable),
        arCommissionEnable: String(financierLogoFileData.arCommissionEnable),
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierCommonSettingId(financierLogoFileData.financierCommonSettingId);
        setAttachFileName(financierLogoFileData.attachmentFileName);
        setHasSubDomain(Boolean(financierLogoFileData.subDomain));
      });
    } catch (e: any) {
      modal.show(e);
    }
  };

  const refreshOtpSettingForm = async () => {
    setIsClientAuthSettingEditable(false);

    const otpSettingData = await requestSystemEnterpriseFinancierAuthSetting(enterpriseId);

    updateOtpValuesReset({
      otpType: otpSettingData.otpType,
    });
  };

  const refreshPlatformSettingForm = async () => {
    setIsPlatformSettingsEditable(false);

    const platformSettingData = await requestSystemEnterpriseFinancierInterface(enterpriseId);

    setPlatformSettingList(
      platformSettingData.map(item => {
        return {
          keyName: item.financierInterfaceType,
          value: String(item.enabled),
        };
      }),
    );
  };

  const onClickFinancierInformationEdit = (e: any) => {
    e.preventDefault();
    setIsFinancierInformationEditable(true);
  };

  const onClickFinancierInformationSave = async (e: any) => {
    e.preventDefault();

    const data = getUpdateFormValues();

    try {
      requestDTOParser(data);
      await requestSystemEnterpriseUpdate(enterpriseId, data);

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => refreshFinancierInformationForm(),
      });
    } catch (error: any) {
      modal.show(error);
      formErrorHandler<SystemEnterpriseUpdateRequest>(error, setUpdateFormErrors, setUpdateFormClear);
    }
  };

  const onClickFinancierInformationCancel = (e: any) => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information?')}
        <br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => refreshFinancierInformationForm(),
      },
    );
  };

  const onClickCommonSettingEdit = async (e: any) => {
    e.preventDefault();

    setIsCommonSettingEditable(true);
    // 저장된 subdomain 있는 경우
    if (hasSubDomain) {
      const syUserData = await requestSystemUserList(1, 10, {
        enterpriseId,
        authorityType: AUTHORITY_TYPE.ADMIN,
      });
      // userStatus가 INVITED, ACTIVATED, SUSPENDED 상태인 user가 한 명이라도 존재하면 수정불가
      setSubDomainDisabled(syUserData?.content?.some(item => item.userStatus !== USER_STATUS.REGISTERED));
    } else setSubDomainDisabled(false);
  };

  const onClickCommonSettingSave = async (e: any) => {
    e.preventDefault();

    try {
      const data = getUpdateFinancierLogoFileValues();
      requestDTOParser(data);
      await requestFinancierCommonSettingLogoUpdate({
        ...data,
        financierId: enterpriseId,
        logo: data.logo,
      });

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => refreshFinancierLogoFileForm(),
      });
    } catch (error) {
      modal.show(error);
      formErrorHandler<UpdateFinancierCommonSettingLogoRequest>(
        error,
        setUpdateFinancierLogoFileErrors,
        setUpdateFinancierLogoFileClear,
      );
    }
  };

  const onClickCommonSettingCancel = (e: any) => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information?')}
        <br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => refreshFinancierLogoFileForm(),
      },
    );
  };

  const onClickDownload = async (e: any) => {
    e.preventDefault();

    try {
      await requestFinancierCommonSettingLogoDownload(financierCommonSettingId);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickPlatformSettingEdit = (e: any) => {
    e.preventDefault();
    setIsPlatformSettingsEditable(true);
  };

  const onClickPlatformSettingSave = async (e: any) => {
    e.preventDefault();
    try {
      await requestSystemEnterpriseFinancierInterfaceUpdate(enterpriseId, {
        interfaceList: platformSettingList.map(item => item.keyName),
        interfaceEnabledList: platformSettingList.map(item => item.value === 'true'),
      });

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => refreshPlatformSettingForm(),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickPlatformSettingCancel = (e: any) => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information?')}
        <br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => refreshPlatformSettingForm(),
      },
    );
  };

  const onClickClientAuthSettingEdit = (e: any) => {
    e.preventDefault();
    setIsClientAuthSettingEditable(true);
  };

  const onClickClientAuthSettingSave = async (e: any) => {
    e.preventDefault();

    try {
      const data = getUpdateOtpValues();

      await requestSystemEnterpriseFinancierAuthSettingUpdate(enterpriseId, data);

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => refreshOtpSettingForm(),
      });
    } catch (error) {
      modal.show(error);
      formErrorHandler<SystemEnterpriseFinancierAuthSettingUpdateRequest>(error, setUpdateOtpErrors, setUpdateOtpClear);
    }
  };

  const onClickClientAuthSettingCancel = (e: any) => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information?')}
        <br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => refreshOtpSettingForm(),
      },
    );
  };

  const onClickAddSupportedCurrencyType = () => {
    let currencyType: CURRENCY_TYPE;

    const supportedCurrencySettingHandler = (selectedCurrencyType: CURRENCY_TYPE): void => {
      if (selectedCurrencyType) {
        currencyType = selectedCurrencyType;
      }
    };

    const onClickConfirm = async () => {
      try {
        await requestSystemRegisterSupportedCurrencyType({
          financierId: enterpriseId,
          supportedCurrencyType: currencyType,
        });
        supportedCurrencyTypesPaginate(
          supportedCurrencyTypesPageable.currentPage,
          supportedCurrencyTypesPageable.sizePerPage,
        );
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <SupportedCurrencySettingModal
        supportedCurrencyTypeList={supportedCurrencyTypeList?.content.map(item => item.currencyType)}
        dataHandler={supportedCurrencySettingHandler}
        modalId={modal.id}
      />,
      {
        title: t('text:Add_Currency_Type'),
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Save'),
        confirmBtnCb: onClickConfirm,
      },
    );
  };

  const onClickDeleteSupportedCurrencyType = async (supportedCurrencyTypeid: number) => {
    try {
      await requestSystemDeleteSupportedCurrencyType(supportedCurrencyTypeid);
      modal.show(<h6>{t('text:The_currency_type_has_been_deleted_successfully')}</h6>, {
        closeBtnCb: () =>
          supportedCurrencyTypesPaginate(
            supportedCurrencyTypesPageable.currentPage,
            supportedCurrencyTypesPageable.sizePerPage,
          ),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderPlatformSettingBox = () => {
    const PlatFormSettingOptions: { label: string; name: FINANCIER_INTERFACE_TYPE }[] = [
      {
        label: t('text:Branch'),
        name: FINANCIER_INTERFACE_TYPE.BRANCH,
      },
      {
        label: t('text:User'),
        name: FINANCIER_INTERFACE_TYPE.USER,
      },
      {
        label: t('text:SMS'),
        name: FINANCIER_INTERFACE_TYPE.SMS,
      },
      {
        label: t('text:OTP_Query'),
        name: FINANCIER_INTERFACE_TYPE.OTP_QUERY,
      },
      {
        label: t('text:OTP_Validation'),
        name: FINANCIER_INTERFACE_TYPE.OTP_VALIDATION,
      },
      {
        label: t('text:Business_Date'),
        name: FINANCIER_INTERFACE_TYPE.BUSINESS_DATE,
      },
      {
        label: t('text:Deposit_Transaction'),
        name: FINANCIER_INTERFACE_TYPE.DEPOSIT_TRANSACTION,
      },
      {
        label: t('text:Enterprise'),
        name: FINANCIER_INTERFACE_TYPE.ENTERPRISE,
      },
      {
        label: t('text:Enterprise_Loan_Limit'),
        name: FINANCIER_INTERFACE_TYPE.ENTERPRISE_TOTAL_LIMIT,
      },
      {
        label: t('text:Anchor_Agreement_By_Contract_Number'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_AGREEMENT_BY_CONTRACT_NO,
      },
      {
        label: t('text:Anchor_Agreement_By_Key_Union'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_AGREEMENT_BY_KEY_UNION,
      },
      {
        label: t('text:Buyer_Agreement_By_Contract_Number'),
        name: FINANCIER_INTERFACE_TYPE.BUYER_AGREEMENT_BY_CONTRACT_NO,
      },

      {
        label: t('text:Buyer_Agreement_By_Key_Union'),
        name: FINANCIER_INTERFACE_TYPE.BUYER_AGREEMENT_BY_KEY_UNION,
      },

      {
        label: t('text:Dealer_Agreement_By_Contract_Number'),
        name: FINANCIER_INTERFACE_TYPE.DEALER_AGREEMENT_BY_CONTRACT_NO,
      },
      {
        label: t('text:Dealer_Agreement_By_Key_Union'),
        name: FINANCIER_INTERFACE_TYPE.DEALER_AGREEMENT_BY_KEY_UNION,
      },
      {
        label: t('text:Supplier_Agreement_By_Contract_Number'),
        name: FINANCIER_INTERFACE_TYPE.SUPPLIER_AGREEMENT_BY_CONTRACT_NO,
      },
      {
        label: t('text:Supplier_Agreement_By_Key_Union'),
        name: FINANCIER_INTERFACE_TYPE.SUPPLIER_AGREEMENT_BY_KEY_UNION,
      },
      {
        label: t('text:Anchor_User_of_Anchor_Agreement'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_ANCHOR_AGREEMENT,
      },
      {
        label: t('text:Anchor_User_of_Buyer_Agreement'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_BUYER_AGREEMENT,
      },
      {
        label: t('text:Anchor_User_of_Dealer_Agreement'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_DEALER_AGREEMENT,
      },
      {
        label: t('text:Anchor_User_of_Supplier_Agreement'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_SUPPLIER_AGREEMENT,
      },
      {
        label: t('text:Loan_Approval'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_APPROVAL,
      },
      {
        label: t('text:Loan_Approval_By_AR'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_APPROVAL_BY_AR,
      },
      {
        label: t('text:Loan_Result'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_RESULT,
      },
      {
        label: t('text:Loan_Result_AR'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_RESULT_AR,
      },
      {
        label: t('text:Loan_Expectation'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EXPECTATION,
      },
      {
        label: t('text:Loan_Expectation_AR'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EXPECTATION_AR,
      },
      {
        label: t('text:Loan_Transaction'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_TRANSACTION,
      },
      {
        label: t('text:Inventory_Request'),
        name: FINANCIER_INTERFACE_TYPE.INVENTORY_REQUEST,
      },
      {
        label: t('text:Loan_Extension_Approval'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EXTENSION_APPROVAL,
      },
      {
        label: t('text:Loan_Extension_Result'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EXTENSION_RESULT,
      },
      {
        label: t('text:Approve_AR_Payment_Request'),
        name: FINANCIER_INTERFACE_TYPE.APPROVE_AR_PAYMENT_REQUEST,
      },
      {
        label: t('text:AR_Payment_Result'),
        name: FINANCIER_INTERFACE_TYPE.AR_PAYMENT_RESULT,
      },
      {
        label: t('text:Transfer_Request'),
        name: FINANCIER_INTERFACE_TYPE.TRANSFER_REQUEST,
      },
      {
        label: t('text:Transfer_Result'),
        name: FINANCIER_INTERFACE_TYPE.TRANSFER_RESULT,
      },
      {
        label: t('text:Supplier_Account_Registration'),
        name: FINANCIER_INTERFACE_TYPE.SUPPLIER_ACCOUNT_REGISTRATION,
      },
      {
        label: t('text:Receive_Loan_Change'),
        name: FINANCIER_INTERFACE_TYPE.RECEIVE_LOAN_CHANGE,
      },
      {
        label: t('text:Receive_AR_Payment_Change'),
        name: FINANCIER_INTERFACE_TYPE.RECEIVE_AR_PAYMENT_CHANGE,
      },
      {
        label: t('text:Loan_Repayment_Aggregation'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_REPAYMENT_AGGREGATION,
      },
      {
        label: t('text:Early_Repayment_Request_-_Invoice'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EARLY_REPAYMENT_REQUEST,
      },
      {
        label: t('text:Early_Repayment_Request_-_AR'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EARLY_REPAYMENT_REQUEST_BY_AR,
      },
      {
        label: t('text:Basis_Interest_Rate'),
        name: FINANCIER_INTERFACE_TYPE.BASIS_INTEREST_RATE,
      },
      {
        label: t('text:Exchange_Rate'),
        name: FINANCIER_INTERFACE_TYPE.CURRENCY_EXCHANGE_RATE,
      },
      {
        label: t('text:Health_Check'),
        name: FINANCIER_INTERFACE_TYPE.HEALTH_CHECK,
      },
    ];

    return PlatFormSettingOptions.map((item, index) => {
      return (
        <div className="row" key={index}>
          <div className="col-4 d-flex align-self-center">
            <div className="information-form__label m-0">{item.label}</div>
          </div>
          <div className="col-4">
            <div className="large-radio-btn">
              <RadioButton
                id={`${item.name}-manual`}
                name={item.name}
                value="false"
                onChange={event => onChangePlatformSetting(event)}
                disabled={!isPlatformSettingsEditable}
                checked={isPlatformSettingOptionChecked(item.name, false)}
              >
                {t('text:Manual')}
              </RadioButton>
            </div>
          </div>
          <div className="col-4">
            <div className="large-radio-btn">
              <RadioButton
                id={`${item.name}-api`}
                name={item.name}
                value="true"
                onChange={event => onChangePlatformSetting(event)}
                disabled={!isPlatformSettingsEditable}
                checked={isPlatformSettingOptionChecked(item.name, true)}
              >
                {t('text:API')}
              </RadioButton>
            </div>
          </div>
        </div>
      );
    });
  };

  const setInvoiceWillBeSettledOnUploadDate = () => {
    updateFinancierLogoFileSetValue('invoiceWillBeSettledOnUploadDate', 'false');
  };

  const disableAddSupportedCurrencyTypeButton = (): boolean => {
    const supportedCurrencyTypes = supportedCurrencyTypeList?.content.map(({ currencyType }) => currencyType);
    const allCurrencyTypesWithOutAll = Object.values(CURRENCY_TYPE).filter(value => value !== CURRENCY_TYPE.ALL);
    const registrableCurrencyTypes = allCurrencyTypesWithOutAll.filter(
      currencyType => !supportedCurrencyTypes?.includes(currencyType),
    );

    return registrableCurrencyTypes.length === 0;
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financier_Detail')} />
      <div className="content-area">
        <SectionTitle title={t('text:Financier_Information')}>
          {isFinancierInformationEditable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickFinancierInformationCancel}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={onClickFinancierInformationSave} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <Button size={ButtonSizeEnum.SM} onClick={onClickFinancierInformationEdit} style={{ width: '60px' }}>
              {t('text:Edit')}
            </Button>
          )}
        </SectionTitle>
        <FormBorder editable={isFinancierInformationEditable}>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:General_Information')} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Financier_Name')}
                name="enterpriseName"
                ref={updateFormRegister}
                error={updateFormErrors.enterpriseName}
                disabled
              />
              <FormInput
                label={t('text:Tax_Code')}
                name="taxCode"
                ref={updateFormRegister}
                error={updateFormErrors.taxCode}
                disabled
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Company_Registration_Number')}
                name="businessCode"
                ref={updateFormRegister}
                disabled={!isFinancierInformationEditable}
                error={updateFormErrors.businessCode}
              />
              <FormInput
                label={t('text:Telephone')}
                name="telephone"
                ref={updateFormRegister}
                requiredOptions={{ required: isFinancierInformationEditable }}
                disabled={!isFinancierInformationEditable}
                error={updateFormErrors.telephone}
                col={3}
              />
              <FormInput
                label={t('text:Fax')}
                name="fax"
                ref={updateFormRegister}
                disabled={!isFinancierInformationEditable}
                error={updateFormErrors.fax}
                col={3}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Registered_Office_Address')}
                name="address"
                ref={updateFormRegister}
                requiredOptions={{ required: isFinancierInformationEditable }}
                disabled={!isFinancierInformationEditable}
                col={12}
                error={updateFormErrors.address}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Legal_Representative_Information')} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Name')}
                name="representativeName"
                ref={updateFormRegister}
                requiredOptions={{ required: isFinancierInformationEditable }}
                disabled={!isFinancierInformationEditable}
                error={updateFormErrors.representativeName}
              />
              <FormInput
                label={t('text:Telephone')}
                name="representativeMobile"
                ref={updateFormRegister}
                disabled={!isFinancierInformationEditable}
                error={updateFormErrors.representativeMobile}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Email')}
                name="representativeEmail"
                ref={updateFormRegister}
                disabled={!isFinancierInformationEditable}
                error={updateFormErrors.representativeEmail}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Common_Settings')}>
          {isCommonSettingEditable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickCommonSettingCancel}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={onClickCommonSettingSave} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <Button size={ButtonSizeEnum.SM} onClick={onClickCommonSettingEdit} style={{ width: '60px' }}>
              {t('text:Edit')}
            </Button>
          )}
        </SectionTitle>
        <FormBorder editable={isCommonSettingEditable}>
          <FormSubtitle title={t('text:Access_Settings')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <div className="information-form__label">
                {t(
                  'text:Unless_the_Sub-Code_was_provided_in_advance,_the_Financier_Code_and_Sub-Code_must_be_identical',
                )}
              </div>
            </div>
            <div className="row">
              <FormValue className="information-form__input" label={t('text:Financier_Code')} value={financierType} />
              <FormValue
                className="information-form__input"
                label={t('text:Financier_Sub-Code')}
                value={financierSubType}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Communicator_Root')}
                name="communicatorRootPath"
                disabled={!isCommonSettingEditable}
                ref={updateFinancierLogoFileRegister}
                error={updateFinancierLogoFileErrors.communicatorRootPath}
              />
              <FormInput
                label={t('text:Financier_Subdomain')}
                name="subDomain"
                disabled={isSubDomainDisabled}
                ref={updateFinancierLogoFileRegister}
                error={updateFinancierLogoFileErrors.subDomain}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:General_Settings')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_branches_to_see_all_of_the_financiers_data?')}
                error={updateFinancierLogoFileErrors.showOnlyAllowedBranchData}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="showOnlyAllowedBranchData"
                  value="false"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
                <FormRadio
                  label={t('text:No')}
                  name="showOnlyAllowedBranchData"
                  value="true"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
              </FormRadioWrap>
              <div className="col-6">
                <div className="d-flex">
                  <label className="information-form__label">{t('text:Financier_Logo_File')}</label>
                </div>
                <div className="align-self-center mt-2">
                  <div className="d-flex align-items-center">
                    <input
                      type="file"
                      id="loanRequestAttachmentFile"
                      name="logo"
                      style={{ display: 'none' }}
                      ref={updateFinancierLogoFileRegister}
                      onChange={e => {
                        if (e.target.files) {
                          setAttachFileName((e.target.files as FileList)[0].name);
                        }
                      }}
                      disabled={!isCommonSettingEditable}
                    />
                    <label
                      htmlFor="loanRequestAttachmentFile"
                      className={`me-3 ${
                        isCommonSettingEditable ? 'attach-file-link-button' : 'attach-file-link-button-disable'
                      }`}
                    >
                      {!isCommonSettingEditable ? t('text:Attached_File') : t('text:Attach_File')}
                    </label>
                    <div id="fileName" className="upload-file-input me-auto">
                      {attachFileName ? attachFileName : t('text:No_file_attached')}
                    </div>
                    {attachFileName && <DownloadButton onClick={onClickDownload} />}
                  </div>
                </div>
              </div>
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Collateral_Settings')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormSelect
                label={t('text:Collateral_Type')}
                selectOptions={getSelectOptions<SUPPORTED_COLLATERAL_TYPE>('SUPPORTED_COLLATERAL_TYPE')}
                name="supportedCollateralType"
                ref={updateFinancierLogoFileRegister}
                error={updateFinancierLogoFileErrors.supportedCollateralType}
                disabled={!isCommonSettingEditable}
                required
                col={3}
              />
            </div>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_registration_of_invoices_that_mature_today?')}
                error={updateFinancierLogoFileErrors.settlementDateIsTodayInvoiceUploadable}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="settlementDateIsTodayInvoiceUploadable"
                  value="true"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
                <FormRadio
                  label={t('text:No')}
                  name="settlementDateIsTodayInvoiceUploadable"
                  value="false"
                  ref={updateFinancierLogoFileRegister}
                  onChange={setInvoiceWillBeSettledOnUploadDate}
                  disabled={!isCommonSettingEditable}
                />
              </FormRadioWrap>
              <FormRadioWrap
                label={t('text:Allow_to_fix_the_payment_date_of_invoice_to_the_registration_date?')}
                error={updateFinancierLogoFileErrors.invoiceWillBeSettledOnUploadDate}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="invoiceWillBeSettledOnUploadDate"
                  value="true"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable || settlementDateIsTodayInvoiceUploadable !== 'true'}
                />
                <FormRadio
                  label={t('text:No')}
                  name="invoiceWillBeSettledOnUploadDate"
                  value="false"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable || settlementDateIsTodayInvoiceUploadable !== 'true'}
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_anchor_to_register_potential_partners?')}
                error={updateFinancierLogoFileErrors.potentialPartnerRegistrable}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="potentialPartnerRegistrable"
                  value="true"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
                <FormRadio
                  label={t('text:No')}
                  name="potentialPartnerRegistrable"
                  value="false"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
              </FormRadioWrap>
              <div className="col-6">
                <label className={isCommonSettingEditable ? 'information-form__label star' : 'information-form__label'}>
                  {t('text:How_many_days_until_settlement_must_be_remaining_when_registering_AR?')}
                </label>
                <div className="d-flex mt-3">
                  <NumericFormatInput
                    control={control}
                    name="minimumPeriodForRegisterAr"
                    numberType="integer"
                    className="information-form__input"
                    col={5}
                    placeholder={t('text:Minimum_required_days')}
                    disabled={!isCommonSettingEditable}
                    error={updateFinancierLogoFileErrors.minimumPeriodForRegisterAr}
                  />
                  <div className="me-3" />
                  <NumericFormatInput
                    control={control}
                    name="maximumPeriodForRegisterAr"
                    numberType="integer"
                    className="information-form__input"
                    col={5}
                    placeholder={t('text:Maximum_required_days')}
                    disabled={!isCommonSettingEditable}
                    error={updateFinancierLogoFileErrors.maximumPeriodForRegisterAr}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_the_financier_to_register_business_divisions_on_the_platform?')}
                error={updateFinancierLogoFileErrors.divisionRegistrable}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="divisionRegistrable"
                  value="true"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
                <FormRadio
                  label={t('text:No')}
                  name="divisionRegistrable"
                  value="false"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
              </FormRadioWrap>
              <FormRadioWrap
                label={t('text:Select_Vendor_Financing_Method')}
                error={updateFinancierLogoFileErrors.factoringEnable}
                required
              >
                <FormRadio
                  label={t('text:Loan')}
                  name="factoringEnable"
                  value="false"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                  defaultChecked
                />
                <FormRadio
                  label={t('text:Factoring')}
                  name="factoringEnable"
                  value="true"
                  ref={updateFinancierLogoFileRegister}
                  disabled={supportedCollateralType === 'INVOICE' || !isCommonSettingEditable}
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap
                label={t('text:Digital_Signature')}
                error={updateFinancierLogoFileErrors.eSignatureEnable}
                required
              >
                <FormRadio
                  label={t('text:Applied')}
                  name="eSignatureEnable"
                  value="true"
                  disabled={!isCommonSettingEditable}
                  ref={updateFinancierLogoFileRegister}
                />
                <FormRadio
                  label={t('text:Not_Applied')}
                  name="eSignatureEnable"
                  value="false"
                  disabled={!isCommonSettingEditable}
                  ref={updateFinancierLogoFileRegister}
                  defaultChecked
                />
              </FormRadioWrap>
              <FormRadioWrap
                label={t('text:Fees_are_charged_to_AR ')}
                error={updateFinancierLogoFileErrors.eSignatureEnable}
                required
              >
                <FormRadio
                  label={t('text:Applied')}
                  name="arCommissionEnable"
                  value="true"
                  disabled={!isCommonSettingEditable}
                  ref={updateFinancierLogoFileRegister}
                />
                <FormRadio
                  label={t('text:Not_Applied')}
                  name="arCommissionEnable"
                  value="false"
                  disabled={!isCommonSettingEditable}
                  ref={updateFinancierLogoFileRegister}
                  defaultChecked
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_anchor_to_financing?')}
                error={updateFinancierLogoFileErrors.anchorLoan}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="anchorLoan"
                  value="true"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
                <FormRadio
                  label={t('text:No')}
                  name="anchorLoan"
                  value="false"
                  ref={updateFinancierLogoFileRegister}
                  disabled={!isCommonSettingEditable}
                />
              </FormRadioWrap>
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Business_Day_Settings')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <div className="col-12">
                <div className="information-form__label">
                  {t('text:Option_to_change_the_base_date_to_a_business_day_if_is_a_holiday')}
                </div>
              </div>
            </div>
            <div className="row">
              <FormSelect
                label={t('text:Disbursement_Date')}
                selectOptions={getSelectOptions<string>('DATE_BUSINESS_DAY_CONVENTION')}
                name="disburseDateBusinessDayConvention"
                ref={updateFinancierLogoFileRegister}
                error={updateFinancierLogoFileErrors.disburseDateBusinessDayConvention}
                disabled={!isCommonSettingEditable}
              />
              <FormSelect
                label={t('text:Repayment_Date')}
                selectOptions={getSelectOptions<string>('DATE_BUSINESS_DAY_CONVENTION')}
                name="repaymentDateBusinessDayConvention"
                ref={updateFinancierLogoFileRegister}
                error={updateFinancierLogoFileErrors.repaymentDateBusinessDayConvention}
                disabled={!isCommonSettingEditable}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:OTP_Settings')}>
          {isClientAuthSettingEditable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickClientAuthSettingCancel}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={onClickClientAuthSettingSave} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <Button size={ButtonSizeEnum.SM} onClick={onClickClientAuthSettingEdit} style={{ width: '60px' }}>
              {t('text:Edit')}
            </Button>
          )}
        </SectionTitle>
        <FormBorder editable={isClientAuthSettingEditable}>
          <FormContents>
            <div className="row">
              <FormSelect
                label={t('text:OTP_Type')}
                selectOptions={getSelectOptions<OTP_TYPE>('OTP_TYPE')}
                name="otpType"
                ref={updateOtpRegister}
                error={updateOtpErrors.otpType}
                disabled={!isClientAuthSettingEditable}
                required={isClientAuthSettingEditable}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Currency_Settings')}>
          <Button
            disabled={disableAddSupportedCurrencyTypeButton()}
            size={ButtonSizeEnum.SM}
            onClick={onClickAddSupportedCurrencyType}
            style={{ width: '60px' }}
          >
            {t('text:Add')}
          </Button>
        </SectionTitle>
        <TableBorder>
          <TableHeader header={SUPPORTED_CURRENCY_TYPES_HEADERS} />
          <TableBody numOfCol={SUPPORTED_CURRENCY_TYPES_HEADERS.length}>
            {supportedCurrencyTypeList?.content.map((item, index: number) => {
              return (
                <Tr key={index}>
                  <Td data={item.currencyType} />
                  <Td className="information-table-more">
                    <Button
                      onClick={() => onClickDeleteSupportedCurrencyType(item.id)}
                      style={{ width: '60px' }}
                      color={ButtonColorEnum.SECONDARY}
                    >
                      {t('text:Delete')}
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={supportedCurrencyTypesPageable} paginate={supportedCurrencyTypesPaginate} />
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Platform_Settings')}>
          {isPlatformSettingsEditable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickPlatformSettingCancel}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={onClickPlatformSettingSave} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <Button size={ButtonSizeEnum.SM} onClick={onClickPlatformSettingEdit} style={{ width: '60px' }}>
              {t('text:Edit')}
            </Button>
          )}
        </SectionTitle>
        <FormBorder editable={isPlatformSettingsEditable}>
          <FormContents>{renderPlatformSettingBox()}</FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default SystemFinancierDetail;
