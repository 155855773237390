import type Pageable from 'models/Pageable';
import type { PartialCollateralVO, PartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import { formattingToPartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import type { SuccessArDetailVO, SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import { formattingToSuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessArVO, SuccessArVOModel } from 'models/vo/SuccessArVO';
import { formattingToSuccessArVOModel } from 'models/vo/SuccessArVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierSuccessArsListRequest, FinancierUpdateSuccessArStatusRequest } from './requests';

// FI - 등록 완료된 AR 목록 조회
export async function requestFinancierSuccessArsList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierSuccessArsListRequest,
): Promise<Pageable<SuccessArVOModel[]>> {
  const response = await http.get<Pageable<SuccessArVO[]>>({
    url: API_FI.SUCCESS_ARS.SUCCESS_AR,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const successArList: SuccessArVOModel[] = response.content.map((data: SuccessArVO) =>
    formattingToSuccessArVOModel(data),
  );
  const successArPage: Pageable<SuccessArVOModel[]> = { ...response, content: successArList };

  return successArPage;
}

// FI - 등록 완료된 AR 상세 조회
export async function requestFinancierSuccessArsDetail(successArId: number): Promise<SuccessArDetailVOModel> {
  const response = await http.get<SuccessArDetailVO>({
    url: API_FI.SUCCESS_ARS.SUCCESS_AR_DETAIL(successArId),
  });

  return formattingToSuccessArDetailVOModel(response);
}

// FI - AP Block
export async function requestFinancierSuccessArsBlock(successArId: number, reason?: string) {
  const response = await http.put({
    url: API_FI.SUCCESS_ARS.BLOCK_APPLYING_FOR_LOAN(successArId),
    data: { reason },
  });

  return response;
}

export async function requestFinancierSuccessArsActive(successArId: number) {
  const response = await http.put({
    url: API_FI.SUCCESS_ARS.UNBLOCK_APPLYING_FOR_LOAN(successArId),
  });

  return response;
}

export async function requestFinancierRenewArByFinancierInterface(
  successArId: number,
): Promise<SuccessArDetailVOModel> {
  const response = await http.put<SuccessArDetailVOModel>({
    url: API_FI.SUCCESS_ARS.RENEW_AR_BY_FINANCIER_INTERFACE(successArId),
  });

  return response;
}

export async function requestFinancierUpdateStatus(
  successArId: number,
  payload: FinancierUpdateSuccessArStatusRequest,
): Promise<void> {
  http.put({
    url: API_FI.SUCCESS_ARS.UPDATE_STATUS(successArId),
    data: { ...payload },
  });
}

export async function requestFinancierFetchPartialCollaterals(
  pageNumber: number,
  rowCount: number,
  successArId: number,
): Promise<Pageable<PartialCollateralVOModel[]>> {
  const response = await http.get<Pageable<PartialCollateralVO[]>>({
    url: API_FI.SUCCESS_ARS.PARTIAL_COLLATERALS(successArId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToPartialCollateralVOModel(item)),
  };
}

export async function requestFinancierSuccessArsDelete(successArId: number) {
  return await http.delete({
    url: API_FI.SUCCESS_ARS.SUCCESS_AR,
    data: { successArId },
  });
}
