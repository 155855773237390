export interface OverdueInterestRateTermSpreadVO {
  id:number;
  minimumDateRange:number;
  termSpreadRate:number;
  overdueInterestRateSettingId:number;
  overdueInterestRateTermSpreadGroupId: number
}

export interface OverdueInterestRateTermSpreadVOModel extends OverdueInterestRateTermSpreadVO {}

export function formattingToOverdueInterestRateTermSpreadVOModel (data : OverdueInterestRateTermSpreadVO):OverdueInterestRateTermSpreadVOModel{
  return {
    ...data
  }
}