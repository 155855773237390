import { useTranslation } from 'react-i18next';

import { WAITING_AR_STATUS } from 'enums';

type WaitingArResultTextPropsType = {
  status: WAITING_AR_STATUS;
  exceptionMessage?: string;
};
const WaitingArResultText = ({ status, exceptionMessage }: WaitingArResultTextPropsType) => {
  const { t } = useTranslation(['format']);

  const message = exceptionMessage && exceptionMessage.split(':').at(-1)?.trim();

  switch (status) {
    case WAITING_AR_STATUS.REGISTERED:
      return <td className="text-bold-blue-main600">{t('text:Pass')}</td>;
    case WAITING_AR_STATUS.INVALID_SETTLEMENT_DATE:
      return <td className="text-red">{t('text:Fail_The_settlement_date_entered_cannot_be_registered')}</td>;
    case WAITING_AR_STATUS.DUPLICATE_AR:
      return <td className="text-red">{t('text:Fail_The_list_contains_duplicate_AR_Numbers')}</td>;
    case WAITING_AR_STATUS.ALREADY_CONFIRMED_AR:
      return <td className="text-red">{t('text:Fail_This_AR_is_already_registered_with_the_platform')}</td>;
    case WAITING_AR_STATUS.INVALID_ANCHOR_PARTNER:
      return <td className="text-red">{t('text:Fail_This_Partner_has_not_been_registered_on_the_platform')}</td>;
    case WAITING_AR_STATUS.INVALID_ANCHOR_PARTNER_ACCOUNT:
      return (
        <td className="text-red">{t('text:Fail_Account_Information_of_Partner_associated_with_AR_is_invalid')}</td>
      );
    case WAITING_AR_STATUS.SETTLEMENT_DATE_EXCEED_ANCHOR_AGREEMENT_EXPIRY_DATE:
      return (
        <td className="text-red">
          {t('text:Fail_The_AR_settlement_date_must_not_exceed_the_Anchor_Master_Agreement_expiration_date')}
        </td>
      );
    case WAITING_AR_STATUS.SETTLEMENT_REQUEST_FAILED:
      return (
        <td className="text-red">
          {t('text:Fail_The_financier_has_rejected_the_AR_registration')} {message}
        </td>
      );
    case WAITING_AR_STATUS.AR_REGISTER_FAILED:
      return <td className="text-red">{t('text:Fail_AR_registration_failed')}</td>;
    case WAITING_AR_STATUS.INVALID_DEALER_AGREEMENT_WITH_POTENTIAL_PARTNER_OPTION_OFF:
      return <td className="text-red">{t('text:Fail_This_partner_is_not_registered')}</td>;
    case WAITING_AR_STATUS.INVALID_DEALER_AGREEMENT_SETTLEMENT_ACCOUNT:
      return (
        <td className="text-red">
          {t(
            'text:Fail_The_partner_s_account_information_and_the_account_information_designated_in_the_partner_s_agreement_do_not_match',
          )}
        </td>
      );
    case WAITING_AR_STATUS.SETTLEMENT_DATE_EXCEED_MAXIMUM_SETTLEMENT_DATE:
      return <td className="text-red">{t('text:Fail_The_settlement_date_entered_cannot_be_registered')}</td>;
    case WAITING_AR_STATUS.SETTLEMENT_DATE_IS_HOLIDAY:
      return <td className="text-red">{t('text:Fail_The_AP_settlement_date_must_not_be_a_holiday')}</td>;
    case WAITING_AR_STATUS.IN_PROGRESS:
      return <td className="text-yellow">{t('text:The_registration_is_in_progress')}</td>;
    default:
      return <td>{status}</td>;
  }
};

export default WaitingArResultText;
