import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { COMMON_APPROVAL_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { WaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface SyPartnerAgreementRegisteredDetailInfoEditHistoryProps {
  data: WaitingDealerAgreementVOModel[];
  pageable: PageableType;
  paginate: (pageNumber: number, sizePerPage: number) => Promise<void>;
}

function SyPartnerAgreementRegisteredDetailInfoEditHistory({
  data,
  pageable,
  paginate,
}: SyPartnerAgreementRegisteredDetailInfoEditHistoryProps): JSX.Element {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const onClickCommonApprovalType = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t(`text:Edited_Time`),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Branch_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Authorizer_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Authorizer_Mobile'),
      colWidths: 120,
    },
    {
      headerText: t('text:Authorizer_Email'),
      colWidths: 150,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickCommonApprovalType,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 150,
    },
  ];

  const renderEditHistoryListTable = (): ReactNode => {
    const getApprovalTypeText = (approvalType: COMMON_APPROVAL_TYPE): JSX.Element => {
      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Request_Cancelled');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Registered');
        default:
          return approvalType;
      }
    };

    return data?.map((item, index) => (
      <Tr key={index}>
        <Td data={item.updatedDateTime} format="datetime" />
        <Td data={item.contractNo} />
        <Td data={item.expiryDate} format="date" />
        <Td data={item.branchCode} />
        <Td data={item.authorizerName} />
        <Td data={item.authorizerMobile} />
        <Td data={item.authorizerEmail} />
        <Td className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}>
          {getApprovalTypeText(item.approvalType)}
        </Td>
        <Td data={`${item.operatorUserLoginId} / ${item.operatorUserName}`} />
        <Td data={`${item.authorizerUserLoginId} / ${item.authorizerUserName}`} />
      </Tr>
    ));
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Information_Edit_History')} />
      <TableBorder>
        <TableHeader header={EDIT_HISTORY_TABLE_HEADERS} />
        <TableBody numOfCol={EDIT_HISTORY_TABLE_HEADERS.length}>{renderEditHistoryListTable()}</TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default SyPartnerAgreementRegisteredDetailInfoEditHistory;
