import type { ReactNode } from 'react';

type SubTitlePropsType = {
  children: ReactNode;
};

const SubTitle = ({ children }: SubTitlePropsType) => {
  return <h3 className="section__subtitle">{children}</h3>;
};

export default SubTitle;
