import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import TextArea from 'components/stateless/TextArea/TextArea';
import type { FinancierLoanExpirationRequest } from 'utils/http/api/financier/loans/request';

interface FinancierFinancingDetailExpiredStatusRequestModalProps {
  getSubmitData(data: FinancierLoanExpirationRequest): void;
  modalInfo: {
    id: number;
    disableConfirmBtn: (id: number, disabled: boolean) => void;
  };
}

function FinancierFinancingDetailExpiredStatusRequestModal({
  getSubmitData,
  modalInfo,
}: FinancierFinancingDetailExpiredStatusRequestModalProps) {
  const { t } = useTranslation(['format']);

  const { register, watch, getValues, control } = useForm<FinancierLoanExpirationRequest>();

  const { reason, expiredDate } = watch();
  const { id: modalId, disableConfirmBtn } = modalInfo;

  const canSubmit = useCallback(() => {
    return !isEmpty(reason) && !isEmpty(expiredDate);
  }, [expiredDate, reason]);

  useEffect(() => {
    if (canSubmit()) getSubmitData(getValues());
  }, [canSubmit, getSubmitData, getValues]);

  useEffect(() => {
    disableConfirmBtn(modalId, !canSubmit());
  }, [canSubmit, disableConfirmBtn, modalId]);

  return (
    <>
      <h6>
        {t('text:Would_you_like_to_request_the_Authorizer_s_approval_to_change_the_financing_status_to_Expired?')}
        <br />
        {t('text:Please_enter_the_reason_for_the_request_below')}
      </h6>
      <div className="modal-input-form mt-3">
        <label className="information-form__label">{t('text:Expired_Date')}</label>
        <div className="position-parent">
          <Controller
            control={control}
            name="expiredDate"
            defaultValue={null}
            render={field => <ReactDatePicker field={field} className="information-form__input bg-sub100 w-100" />}
          />
        </div>
      </div>
      <div className="mt-3" />
      <TextArea label={t('text:Reason')} name="reason" maxLength={1000} ref={register} />
    </>
  );
}

export default FinancierFinancingDetailExpiredStatusRequestModal;
