import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormSelect } from 'components/stateless/CommonForm';
import FormInput from 'components/stateless/CommonForm/FormInput';
import SearchAnchorAgreementModal from 'components/stateless/Modal/financier/SearchAnchorAgreementModal';
import SearchPartnerAgreementModal from 'components/stateless/Modal/financier/SearchPartnerAgreementModal';
import getSelectOptions from 'constants/selectOptions';
import { FINANCIAL_SETTING_TYPE } from 'enums';
import type { CURRENCY_TYPE } from 'enums';
import type { COLLATERAL_TYPE } from 'enums';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { ModalSize } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

function DelinquentInterestRateTypeForm() {
  const { t } = useTranslation(['format']);
  const [anchorAgreementInfo, setAnchorAgreementInfo] = useState<AnchorAgreementVOModel>();
  const [dealerAgreementInfo, setDealerAgreementInfo] = useState<DealerAgreementVOModel>();
  const {
    contractNo: anchorContractNo,
    financierClientName,
    financierClientCode,
  } = anchorAgreementInfo || {
    contractNo: '',
    financierClientName: '',
    financierClientCode: '',
  }; // controlled Component 유지하기 위해 사용
  const {
    contractNo: dealerContractNo,
    dealerFinancierClientName,
    dealerFinancierClientCode,
    anchorFinancierClientName,
  } = dealerAgreementInfo || {
    contractNo: '',
    dealerFinancierClientName: '',
    dealerFinancierClientCode: '',
    anchorFinancierClientName: '',
  };

  const { register, setValue, watch, errors, clearErrors } = useFormContext<any>();
  const modal = useModal();
  const { settingType, currencyType, collateralType } = watch();

  const showAnchorMasterAgreementModal = () => {
    const setAnchorAgreementData = (data: AnchorAgreementVOModel) => {
      setValue('anchorAgreementId', data.anchorAgreementId);
      setValue('currencyType', data.currencyType);
      setAnchorAgreementInfo(data);
    };

    modal.show(
      <SearchAnchorAgreementModal
        supportedCollateralType={collateralType}
        modalId={modal.id}
        getSelectedData={setAnchorAgreementData}
      />,
      {
        modalSize: ModalSize.XL,
      },
    );
  };

  const showPartnerMasterAgreementModal = () => {
    const setPartnerAgreementData = (data: DealerAgreementVOModel) => {
      setValue('dealerAgreementId', data.dealerAgreementId);
      setValue('currencyType', data.currencyType);
      setDealerAgreementInfo(data);
    };

    modal.show(
      <SearchPartnerAgreementModal
        supportedCollateralType={collateralType}
        modalId={modal.id}
        getSelectedData={setPartnerAgreementData}
      />,
      {
        modalSize: ModalSize.XL,
      },
    );
  };

  const isAnchorType = settingType === FINANCIAL_SETTING_TYPE.ANCHOR;
  const isPartnerType = settingType === FINANCIAL_SETTING_TYPE.PARTNER;
  const isNotDefaultType = isAnchorType || isPartnerType;

  const handleChangeSelectType = () => {
    setValue('anchorAgreementId', null);
    setValue('dealerAgreementId', null);
    setValue('currencyType', null);
    setAnchorAgreementInfo({
      ...anchorAgreementInfo,
      contractNo: '',
      financierClientName: '',
      financierClientCode: '',
    } as AnchorAgreementVOModel);
    setDealerAgreementInfo({
      ...dealerAgreementInfo,
      contractNo: '',
      dealerFinancierClientName: '',
      dealerFinancierClientCode: '',
      anchorFinancierClientName: '',
    } as DealerAgreementVOModel);
    clearErrors(['anchorAgreementId', 'dealerAgreementId', 'currencyType']);
  };

  const handleClickSearchButton = () => {
    isAnchorType ? showAnchorMasterAgreementModal() : showPartnerMasterAgreementModal();
  };

  return (
    <FormBorder editable>
      <FormContents>
        <div className="row">
          <input type="hidden" name="anchorAgreementId" ref={register} />
          <input type="hidden" name="dealerAgreementId" ref={register} />
          <FormSelect
            label={t('text:Program_Type')}
            name="collateralType"
            required
            ref={register()}
            error={errors.collateralType}
            placeholderOptions={{ show: true }}
            selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE')}
            onChange={handleChangeSelectType}
          />
          <FormSelect
            label={t('text:Type')}
            name="settingType"
            required
            ref={register()}
            error={errors.settingType}
            placeholderOptions={{ show: true }}
            selectOptions={getSelectOptions<FINANCIAL_SETTING_TYPE>('FINANCIAL_SETTING_TYPE')}
            onChange={handleChangeSelectType}
          />
        </div>
        <div className="row">
          <FormInput
            label={t('text:Master_Agreement_Number')}
            isShouldShowDash
            disabled
            value={isAnchorType ? anchorContractNo : dealerContractNo}
          >
            {isNotDefaultType && collateralType && (
              <Button size={ButtonSizeEnum.XS} onClick={handleClickSearchButton}>
                {t('text:Search')}
              </Button>
            )}
          </FormInput>
          <FormSelect
            label={t('text:Currency')}
            name="currencyType"
            required
            ref={register()}
            error={errors.currencyType}
            selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
            placeholderOptions={{ show: true }}
            disabled={isNotDefaultType}
            isShouldShowDash={!currencyType}
          />
        </div>
        <div className="row">
          <FormInput
            label={t('text:Company_Name')}
            disabled
            isShouldShowDash
            value={isAnchorType ? financierClientName : dealerFinancierClientName}
          />
          <FormInput
            label={t('text:Company_Client_Code')}
            disabled
            isShouldShowDash
            value={isAnchorType ? financierClientCode : dealerFinancierClientCode}
          />
        </div>
        {isPartnerType && (
          <div className="row">
            <FormInput
              label={t('text:Associated_Anchor_Name')}
              disabled
              isShouldShowDash
              col={12}
              value={anchorFinancierClientName}
            />
          </div>
        )}
      </FormContents>
    </FormBorder>
  );
}

export default DelinquentInterestRateTypeForm;
