import './PPSubTitle.scss';

interface PPSubTitleProps {
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
  title: string;
}

function PPSubTitle({ title, children }: PPSubTitleProps) {
  return (
    <div className="pp-sub-title">
      <div>{title}</div>
      {children}
    </div>
  );
}

export default PPSubTitle;
