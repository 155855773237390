export interface CalculateForLoanRequestVO {
  maxLoanableAmount: number;
  minLoanableAmount: number;
  termSpreadRate: number;
  basisInterestRate: number;
  basisInterestBankCode: string;
  showExpectedTotalInterestRate: boolean;
  hasBasisInterest: boolean;
  requestedAmount: number;
  expectedInterestAmount: number;
  netDisbursementAmount: number;
  remainingCollateralAmount: number;
  arCommissionAmount: number;
}

export interface CalculateForLoanRequestVOModel extends CalculateForLoanRequestVO {}

export function formattingToCalculateForLoanRequestVOModel(
  data: CalculateForLoanRequestVO,
): CalculateForLoanRequestVOModel {
  return {
    ...data,
  };
}
