import { useMemo } from 'react';
import type { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { PlatformTransactionVOModel } from 'models/vo/PlatformTransactionVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { SystemPlatformTransactionListRequest } from 'utils/http/api/system/platform-transaction/requests';
import { getProgramTypeText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import type {
  SystemTransactionPerformanceListSearchModalType,
  SystemTransactionPerformanceListSearchedModalNameInfoDataType,
} from '../features/types';

const getConstants = () => {
  const { t } = useTranslation(['format']);

  const TABLE_BIG_HEADERS: BigHeaderType[] = [
    {
      headerText: t('text:Client_Information'),
      colSpan: 4,
    },
    {
      headerText: t('text:Financing_Information'),
      colSpan: 10,
    },
    {
      headerText: t('text:Transaction_Fee_Information'),
      colSpan: 4,
    },
  ];

  const TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Collateral_Number'),
      colWidths: 130,
    },
    {
      headerText: t('text:Reference_Number'),
      colWidths: 130,
    },
    {
      headerText: t('text:Collateral_Amount'),
      colWidths: 130,
    },
    {
      headerText: t('text:Disbursed_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Repaid_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expired_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 90,
    },
    {
      headerText: t('text:Start_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:End_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financing_Term'),
      colWidths: 100,
    },
    {
      headerText: t('text:Fee'),
      colWidths: 90,
    },
  ];

  const SearchModal = ({
    col = 4,
    onClick,
    value,
    disabled = false,
  }: SystemTransactionPerformanceListSearchModalType) => {
    return (
      <div className={`search-form__input-wrap col-${col}`}>
        <input type="text" value={value ?? (!disabled ? t('text:Search') : '')} disabled />
        {!disabled && <FontAwesomeIcon icon={faSearch} className="search-modal-icon" onClick={onClick} />}
      </div>
    );
  };

  return {
    t,
    TABLE_HEADERS,
    TABLE_BIG_HEADERS,
    SearchModal,
  };
};
interface SystemTransactionPerformanceListTabViewProps {
  useForm: UseFormMethods<SystemPlatformTransactionListRequest>;
  data: PlatformTransactionVOModel[];
  searchedModalNameInfo: SystemTransactionPerformanceListSearchedModalNameInfoDataType | undefined;
  searchedResultInfo: SystemPlatformTransactionListRequest;
  searchedFinancierName: string | undefined;
  onClickSearch: () => void;
  onClickExport: (e: any) => void;
  onClickReset: (useForm: UseFormMethods<SystemPlatformTransactionListRequest>) => void;
  showSearchFinancierNameModal: (useForm: UseFormMethods<SystemPlatformTransactionListRequest>) => void;
  showSearchAnchorNameModal: (useForm: UseFormMethods<SystemPlatformTransactionListRequest>) => void;
  showSearchPartnerNameModal: (useForm: UseFormMethods<SystemPlatformTransactionListRequest>) => void;
  pageable: PageableType;
  paginate(pageNumber: number, rowCount: number): Promise<void>;
}

function SystemTransactionPerformanceListTabView({
  useForm,
  searchedModalNameInfo,
  searchedResultInfo,
  searchedFinancierName,
  showSearchFinancierNameModal,
  showSearchAnchorNameModal,
  showSearchPartnerNameModal,
  onClickSearch,
  data,
  onClickReset,
  onClickExport,
  pageable,
  paginate,
}: SystemTransactionPerformanceListTabViewProps) {
  const { t, TABLE_HEADERS, TABLE_BIG_HEADERS, SearchModal } = getConstants();

  const { register, watch, control } = useForm;

  const isEditableSearchBtn = watch('financierId') && watch('fee') && watch('fromDate') && watch('toDate');
  const toDate = watch('toDate');
  const fromDate = useForm.watch('fromDate');

  const getEndDate = useMemo((): Date => {
    const today = new Date();
    const oneYearAfterFromDate = dayjs(fromDate).add(365, 'days').toDate();

    let endDate: Date;

    if (fromDate) {
      // fromDate 선택되었으면 fromDate로부터 최대 90일 후까지 -> 오늘 날짜를 넘길 수 없음
      endDate = oneYearAfterFromDate > today ? today : oneYearAfterFromDate;
    } else endDate = new Date(); // default : 제한없음 ~ 오늘

    return endDate;
  }, [fromDate]);

  const renderListTableBody = () => {
    return data?.map((item, index) => (
      <Tr key={index}>
        <Td data={getProgramTypeText(item.collateralType)} />
        <Td data={item.anchorClientName} />
        <Td data={item.dealerClientName} />
        <Td className="text-center" data={item.currencyType} />
        <Td data={item.loanId} />
        <Td data={item.collateralNumber} />
        <Td data={item.collateralReferenceNumber} />
        <Td data={item.collateralAmount} format="number" />
        <Td data={item.disbursedAmount} format="number" />
        <Td data={item.disbursedDate} format="date" />
        <Td data={item.repaymentDate} format="date" />
        <Td data={item.repaidDate} format="date" />
        <Td data={item.expiredDate} format="date" />
        <Td
          data={t(`code:financing-status.${item.loanStatus}`)}
          className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
          format="code"
        />
        <Td data={item.startDate} format="date" />
        <Td data={item.endDate} format="date" />
        <Td data={`${item.term} ${t('text:Days')}`} />
        <Td data={item.performanceFee} format="number" />
      </Tr>
    ));
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={(e: any) => {
                e.preventDefault();
                onClickReset(useForm);
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchModal onClick={showSearchFinancierNameModal} value={searchedModalNameInfo?.financierName} />
              <input type="hidden" name="financierId" ref={register} />
              <SearchEmpty />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchModal
                onClick={showSearchAnchorNameModal}
                disabled={!searchedModalNameInfo?.financierName}
                value={searchedModalNameInfo?.anchorName}
              />
              <input type="hidden" name="anchorClientId" ref={register} />
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchModal
                onClick={showSearchPartnerNameModal}
                disabled={!searchedModalNameInfo?.financierName}
                value={searchedModalNameInfo?.dealerName}
              />
              <input type="hidden" name="dealerClientId" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Fee_Rate(APR,%)')} />
              <SearchInput name="fee" type="number" ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="fromDate"
                control={control}
                minDate={toDate ? dayjs(toDate).subtract(365, 'days').toDate() : new Date('1970-01-01')}
                maxDate={toDate ? dayjs(toDate).toDate() : new Date()}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name="toDate"
                control={control}
                minDate={fromDate ? new Date(fromDate) : new Date('1970-01-01')}
                maxDate={getEndDate}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                ref={register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch} disabled={!isEditableSearchBtn}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>

      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="flex-end align-items-center mb-2">
          <div className="me-auto">
            <p>
              {t('text:Financier')}: {searchedFinancierName}
            </p>
            <p>
              {t('text:Fee_Rate(APR,%)')}:{' '}
              {tableValueManage(searchedResultInfo?.fee, t('format:number', { value: searchedResultInfo?.fee }))}
            </p>
            <p>
              {t('text:Date')}:{' '}
              {`${t('format:date', { value: searchedResultInfo?.fromDate, key: 'date' })} ~ ${t('format:date', {
                value: searchedResultInfo?.toDate,
                key: 'date',
              })}`}
            </p>
          </div>
          <Button onClick={onClickExport} variant={ButtonVariantEnum.OUTLINED} size={ButtonSizeEnum.SM}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader bigHeader={TABLE_BIG_HEADERS} header={TABLE_HEADERS} />
          <TableBody numOfCol={TABLE_HEADERS.length}>{renderListTableBody()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}
export default SystemTransactionPerformanceListTabView;
