import type { DOCUMENT_FORM_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { FinancierDocumentFormVO, FinancierDocumentFormVOModel } from 'models/vo/FinancierDocumentFormVO';
import { formattingToFinancierDocumentFormVOModel } from 'models/vo/FinancierDocumentFormVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

export async function requestDealerFinancierDocumentFormsAttachmentDownload(
  financierId: number,
  documentFormType: DOCUMENT_FORM_TYPE,
) {
  await http.download(API_DE.FINANCIER_DOCUMENT_FORMS.DOWNLOAD_ATTACHMENT, { financierId, documentFormType });
}

export async function requestDealerFinancierDocumentList(
  financierId: number,
): Promise<Pageable<FinancierDocumentFormVOModel[]>> {
  const response = await http.get<Pageable<FinancierDocumentFormVO[]>>({
    url: API_DE.FINANCIER_DOCUMENT_FORMS.DOCUMENT_LIST(financierId),
  });
  const dealerFinancierDocumentList: FinancierDocumentFormVOModel[] = response.content.map(
    (data: FinancierDocumentFormVO) => formattingToFinancierDocumentFormVOModel(data),
  );
  const dealerFinancierDocumentPage: Pageable<FinancierDocumentFormVOModel[]> = {
    ...response,
    content: dealerFinancierDocumentList,
  };

  return dealerFinancierDocumentPage;
}
