import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { EXTENSION_REQUEST_STATUS, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { ExtensionRequestDetailVOModel } from 'models/vo/ExtensionRequestDetailVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { getDayTerm } from 'utils/date/date';
import { requestDealerExtensionDetail } from 'utils/http/api/dealer/extension-requests';
import { requestDealerLoanDetail } from 'utils/http/api/dealer/loans';
import { getTotalInterestRate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableNumberValueIsZero, tableValueManage } from 'utils/valueManager/ValueManager';

function DealerExtensionDetail() {
  // 기본 state
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { extensionRequestId } = useParams<any>();
  const modal = useModal();
  const history = useHistory();

  // 화면 state
  const [extensionDetail, setExtensionDetail] = useState<ExtensionRequestDetailVOModel>();
  const [loanDetail, setLoanDetail] = useState<LoanDetailVOModel>();

  useEffect(() => {
    if (mounted) {
      fetchData();
    }
  }, [mounted]);

  async function fetchData() {
    try {
      const fetchExtensionDetail = await requestDealerExtensionDetail(extensionRequestId);
      const fetchLoanDetail = await requestDealerLoanDetail(fetchExtensionDetail.loanId);

      ReactDOM.unstable_batchedUpdates(() => {
        setExtensionDetail(fetchExtensionDetail);
        setLoanDetail(fetchLoanDetail);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onClickExtensionRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EXTENSION_REQUEST_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="EXTENSION_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderGuideMessage = () => {
    if (extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.REJECTED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_extension_request_has_been_rejected'),
            t('text:Please_check_the_reason_for_the_rejection_below'),
          ]}
          reason={extensionDetail?.rejectReason}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
        />
      );
    }
  };

  const renderExtensionRequestInfo = () => {
    return (
      <div className="content-area">
        <SectionTitle title={t('text:Request_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={6}
                label={t('text:Requested_Date')}
                value={t('format:datetime', {
                  value: extensionDetail?.createdDateTime,
                  key: 'datetime',
                })}
              />
              <FormValue
                col={6}
                className={getStatusBadgeClass('EXTENSION_REQUEST_STATUS', extensionDetail?.extensionRequestStatus)}
                label={t('text:Status')}
                value={t(`code:extension-request-status.${extensionDetail?.extensionRequestStatus}`)}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickExtensionRequestStatus}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Financing_Information')}>
            <div className="flex-end align-items-center me-3">
              <span className="me-1">{t('text:Go_to_Financing_Details')}</span>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                className="flex-column-center"
                onClick={() => {
                  history.push(ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(loanDetail?.loanId!), {
                    successInvoiceId: loanDetail?.invoiceId,
                    successArId: loanDetail?.successArId,
                    financierId: loanDetail?.financierId,
                    collateralType: loanDetail?.collateralType,
                  });
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </FormSubtitle>
          <FormContents backGroundType={BackGroundType.Gray}>
            <div className="row">
              <FormValue col={3} label={t('text:Financier_Financing_ID')} value={loanDetail?.financierLoanId} />
              <FormValue col={3} label={t('text:Platform_Financing_ID')} value={loanDetail?.loanId} />
              <FormValue
                col={3}
                label={t('text:Financing_Amount')}
                value={tableValueManage(
                  loanDetail?.principalAmount,
                  t('format:number', {
                    value: loanDetail?.principalAmount,
                  }),
                )}
              />
              <FormValue
                col={3}
                label={t('text:Total_Interest_Rate_(APR)')}
                value={getTotalInterestRate(
                  loanDetail?.disbursedBasisInterestRate!,
                  loanDetail?.disbursedTotalInterestRateWithoutBasis!,
                )}
              />
            </div>
            <div className="row">
              <FormValue
                col={6}
                label={t('text:Initial_Repayment_Date')}
                value={tableValueManage(
                  loanDetail?.originalRepaymentDate,
                  t('format:date', {
                    value: loanDetail?.originalRepaymentDate,
                    key: 'date',
                  }),
                )}
              />
              <FormValue
                col={3}
                label={t('text:Remaining_Requests')}
                value={t('format:number', {
                  value: Number(loanDetail?.maxExtensibleLoanCount) - Number(loanDetail?.extendedLoanCount),
                })}
              />
              <FormValue
                col={3}
                className={getStatusBadgeClass('LOAN_STATUS', loanDetail?.loanStatus)}
                label={t('text:Financing_Status')}
                value={t(`code:financing-status.${loanDetail?.loanStatus}`)}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickLoanStatus}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    );
  };
  const renderExtensionConfirmationInfo = () => {
    const isOperatorAction = () => {
      return (
        extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.PREAPPROVED ||
        extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.REJECTED
      );
    };
    const renderConfirmationPersonInfo = () => {
      return (
        <>
          <FormSubtitle title={t('text:Authorization')} backGroundType={BackGroundType.DarkGray} />

          <FormContents backGroundType={BackGroundType.Gray}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Name')}
                value={isOperatorAction() ? extensionDetail?.preApprovedName : extensionDetail?.approvedName}
              />
              <FormValue
                col={3}
                label={t('text:Authority')}
                value={
                  isOperatorAction()
                    ? t(`code:authority-type.${extensionDetail?.preApprovedAuthorityType}`)
                    : t(`code:authority-type.${extensionDetail?.approvedAuthorityType}`)
                }
              />
              <FormValue col={3} label={t('text:Affiliation')} value={extensionDetail?.financierName} />
              <FormValue
                col={3}
                label={t('text:Approved_Date')}
                value={
                  isOperatorAction()
                    ? t('format:datetime', {
                        value: extensionDetail?.preApprovedDateTime,
                        key: 'datetime',
                      })
                    : t('format:datetime', {
                        value: extensionDetail?.approvedDateTime,
                        key: 'datetime',
                      })
                }
              />
            </div>
          </FormContents>
        </>
      );
    };

    return (
      <div className="content-area">
        <SectionTitle title={t('text:Approved_Extension_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Modified_Repayment_Date')}
                value={tableValueManage(
                  extensionDetail?.extendedRepaymentDate,
                  t('format:date', {
                    value: extensionDetail?.extendedRepaymentDate,
                    key: 'date',
                  }),
                )}
              />
              <FormValue
                col={3}
                label={t('text:Start_Date_of_Financing_Extension')}
                value={tableValueManage(
                  extensionDetail?.extensionStartDate,
                  t('format:date', {
                    value: extensionDetail?.extensionStartDate,
                    key: 'date',
                  }),
                )}
              />
              <FormValue
                col={3}
                label={t('text:Financing_Extension_Period')}
                value={getDayTerm(extensionDetail?.extensionStartDate, extensionDetail?.extendedRepaymentDate, {
                  includeStartDate: true,
                  withText: true,
                })}
              />
              <FormValue
                col={3}
                label={t('text:Added_Spread_(APR)')}
                value={tableNumberValueIsZero(extensionDetail?.additionalRate)}
              />
            </div>
          </FormContents>
          {renderConfirmationPersonInfo()}
        </FormBorder>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Extension_Request_Details')} />

      {renderGuideMessage()}
      {renderExtensionRequestInfo()}

      {extensionDetail?.extensionRequestStatus !== EXTENSION_REQUEST_STATUS.REQUESTED &&
        renderExtensionConfirmationInfo()}
    </>
  );
}

export default DealerExtensionDetail;
