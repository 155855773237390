import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import { ROUTES_CM } from 'constants/routes/common';
import { ROUTES_PP } from 'constants/routes/potentialPartner';
import { EMAIL_TYPE } from 'enums';
import { requestAnEmailFindTypeAndCode } from 'utils/http/api/anonymous/email';
import type { AnEmailFindTypeAndCodeRequest } from 'utils/http/api/anonymous/email/request';
import useModal from 'utils/modal/useModal';

import '../common/CommonPage.scss';

function AccessPage() {
  const { t } = useTranslation(['format']);
  const history = useHistory();
  const modal = useModal();

  const { register, getValues } = useForm<AnEmailFindTypeAndCodeRequest>();

  const onClickConfirm = async (e: any) => {
    e.preventDefault();

    const data = getValues();

    try {
      const response = await requestAnEmailFindTypeAndCode(data);
      if (response) {
        const url = getEmailTypeUrl(response.emailType);
        history.push(`${url}?code=${response.code}`);
      }
    } catch (e: any) {
      modal.show(e);
    }
  };

  const getEmailTypeUrl = (emailType: EMAIL_TYPE): string => {
    switch (emailType) {
      case EMAIL_TYPE.SY_INVITE_FI_AD:
      case EMAIL_TYPE.FI_INVITE_FI_AU:
      case EMAIL_TYPE.FI_INVITE_FI_OP:
        return ROUTES_CM.FI_SIGN_UP;

      case EMAIL_TYPE.FI_INVITE_AC_AD:
      case EMAIL_TYPE.FI_INVITE_AC_AU:
      case EMAIL_TYPE.FI_INVITE_AC_OP:
        return ROUTES_CM.AC_SIGN_UP;

      case EMAIL_TYPE.FI_INVITE_DE_AU:
        return ROUTES_CM.DE_AUTHORIZER_SIGN_UP;

      case EMAIL_TYPE.AC_INVITE_AC_AO:
      case EMAIL_TYPE.DE_INVITE_DE_OP:
        return ROUTES_CM.AC_HQ_OPERATOR_AND_DE_OPERATOR_SIGN_UP;

      case EMAIL_TYPE.FIND_PWD:
        return ROUTES_CM.RESET_PASSWORD;

      case EMAIL_TYPE.APPROVE_AR_TO_REPRESENTATIVE:
        return ROUTES_PP.HOME;

      default:
        throw new Error('email type is invalid.');
    }
  };

  return (
    <>
      <PublicHeaderBar />
      <div className="align-middle row">
        <div className="find-form">
          <h2 className="login-form__title">{t('text:Enter_the_temporary_access_code_that_was_sent_to_your_email')}</h2>
          <div className="border-bottom-light-gray mb-4" />
          <form>
            <div className="login-form__input">
              <div className="col-12">
                <label className="information-form__label">{t('text:Email')}</label>
                <input
                  className="information-form__input"
                  type="text"
                  name="email"
                  ref={register}
                  placeholder={t('text:Please_type_here')}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="login-form__input mt-3">
              <div className="col-12">
                <label className="information-form__label">{t('text:Access_Code')}</label>
                <input
                  className="information-form__input"
                  type="text"
                  name="accessCode"
                  ref={register}
                  placeholder={t('text:Please_type_here')}
                  autoComplete="off"
                />
              </div>
            </div>
            <Button size={ButtonSizeEnum.LG} onClick={onClickConfirm}>
              {t('text:Confirm')}
            </Button>
            <Link to="/">
              <div className="sign-in-link mt-4">{t('text:Back_to_Sign_in')}</div>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
}

export default AccessPage;
