import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import { COMMON_APPROVAL_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { WaitingAnchorFinancingOptionVOModel } from 'models/vo/WaitingAnchorFinancingOptionVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { SearchFiWaitingAnchorFinancingOptionDTO } from 'utils/http/api/financier/waiting-anchor-financing-options/requests';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface FinancierAnchorFinancingOptionListWaitingTabProps {
  waitingAnchorFinancingOptionPage: Pageable<WaitingAnchorFinancingOptionVOModel[]> | undefined;
  waitingAnchorFinancingOptionPageable: PageableType;
  handleClickSearchWaitingFinancingOptionTab(e: any): Promise<void>;
  paginateWaitingFinancingOptionTab(pageNumber: number, sizePerPage: number): Promise<void>;
}

function FinancierAnchorFinancingOptionListWaitingTab({
  waitingAnchorFinancingOptionPage,
  waitingAnchorFinancingOptionPageable,
  handleClickSearchWaitingFinancingOptionTab,
  paginateWaitingFinancingOptionTab,
}: FinancierAnchorFinancingOptionListWaitingTabProps) {
  const { t } = useTranslation();
  const modal = useModal();

  const { register, reset, control } = useFormContext<SearchFiWaitingAnchorFinancingOptionDTO>();

  const onClickRemoveFilter = (e: any): void => {
    reset({});

    e.preventDefault();
  };

  const onClickConfirmationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderWaitingAnchorAgreementTable = (): JSX.Element[] | JSX.Element | undefined => {
    const getConfirmationStatusText = (approvalType: COMMON_APPROVAL_TYPE): string => {
      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Confirmed');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Request_Cancelled');
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested');
        default:
          return '';
      }
    };

    return waitingAnchorFinancingOptionPage?.content.map(
      (item, index: number): JSX.Element => (
        <Tr key={index}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.anchorClientName} />
          <Td data={item.anchorClientCode} />
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td data={item.anchorAgreementStartDate} format="date" />
          <Td data={item.anchorAgreementExpiryDate} format="date" />
          <Td
            data={getConfirmationStatusText(item.approvalType)}
            className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
          />
          <TdLink
            path={ROUTES_FI.MANAGE_ANCHOR.FINANCING_OPTION_WAITING_DETAIL_BUILD_PATH(
              item.waitingAnchorFinancingOptionId,
            )}
            state={{ supportedCollateralType: item.collateralType }}
          />
        </Tr>
      ),
    );
  };

  const WAITING_ANCHOR_AGREEMENT_LIST_HEADER: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Sub-Contract_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 96,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Anchor_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Responsible_Bank_Branch_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Responsible_Bank_Branch_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickConfirmationStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Sub-Contract_Number')} />
              <SearchInput name="contractNo" ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name="anchorClientCode" ref={register} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Responsible_Bank_Branch_Code')} />
              <SearchInput name="assignedBranchCode" ref={register} />
              <SearchLabel label={t('text:Responsible_Bank_Branch_Name')} />
              <SearchInput name="assignedBranchName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Effective_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="fromStartDate" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="toStartDate" control={control} />
              <SearchLabel label={t('text:Expiration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="fromExpiryDate" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="toExpiryDate" control={control} />
            </div>
            <div className="row">
              <SearchLabel
                label={t('text:Approval_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickConfirmationStatus}
              />
              <SearchSelect
                name="approvalTypes"
                selectOptions={[
                  {
                    label: t('text:All'),
                    value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                  },
                  {
                    label: t('text:Requested'),
                    value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                  },
                  {
                    label: t('text:Reverted'),
                    value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  },
                  {
                    label: t('text:Cancelled'),
                    value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                  },
                ]}
                ref={register}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                ref={register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearchWaitingFinancingOptionTab}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {waitingAnchorFinancingOptionPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={WAITING_ANCHOR_AGREEMENT_LIST_HEADER} />
          <TableBody numOfCol={WAITING_ANCHOR_AGREEMENT_LIST_HEADER.length}>
            {renderWaitingAnchorAgreementTable()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={waitingAnchorFinancingOptionPageable} paginate={paginateWaitingFinancingOptionTab} />
      </div>
    </>
  );
}

export default FinancierAnchorFinancingOptionListWaitingTab;
