export interface TermsOfUseVO {
  termsOfUseId: number;
  enterpriseId: number;
  enterpriseName: string;
  activatedTouFileName: string;
}

export interface TermsOfUseVOModel extends TermsOfUseVO {}

export function formattingToTermsOfUseVOModel(data: TermsOfUseVO): TermsOfUseVOModel {
  return {
    ...data,
  };
}
