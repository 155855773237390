import type { ReactNode } from 'react';
import type { FieldError } from 'react-hook-form';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FormRadioWrapProps {
  label: string;
  col?: number;
  className?: string;
  required?: boolean;
  editable?: boolean;
  children?: ReactNode;
  error?: FieldError;
}

export const FormRadioWrap = ({
  col = 6,
  label = '',
  className,
  editable = true,
  required = false,
  children,
  error,
}: FormRadioWrapProps) => {
  return (
    <div className={`col-${col}`}>
      <div className="d-flex">
        <label
          className={`information-form__label ${editable && required ? 'star' : ''} ${className ? className : ''} ${
            error ? 'w-auto' : ''
          }`}
        >
          {label}
        </label>
        {error && <FontAwesomeIcon icon={faCheckCircle} className="check-icon ms-2" />}
      </div>

      {children}
    </div>
  );
};
