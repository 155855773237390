import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormSubtitle, FormValue } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, AUTHORIZER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { SignInModel } from 'models/SignInModel';
import type { DealerAuthorizerDetailVOModel } from 'models/vo/DealerAuthorizerDetailVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierPartnerUserDetail } from 'utils/http/api/financier/partner-users';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

const FinancierManageDealerUserDetail = () => {
  const signInModel: SignInModel | null = getSignIn();
  const history = useHistory();
  const { t } = useTranslation();
  const mounted = useMounted();
  const modal = useModal();

  const { dealerAgreementId } = useParams<any>();
  const [dealerUserDetail, setDealerUserDetail] = useState<DealerAuthorizerDetailVOModel>();

  useEffect(() => {
    if (mounted) {
      fetchPartnerUserDetail();
    }
  }, [mounted]);

  const fetchPartnerUserDetail = async () => {
    try {
      const response = await requestFinancierPartnerUserDetail(dealerAgreementId);
      setDealerUserDetail(response);
    } catch (e) {
      modal.show(e);
    }
  };

  const handleClickEmailNotificationSetting = () => {
    history.push(ROUTES_FI.MANAGE_PARTNER.USER_DETAIL_EMAIL_SETTING_BUILD_PATH(dealerUserDetail?.userId!));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Partner_User_Information_Details')} />
      <GuideMessage
        message={[
          t('text:You_can_check_the_user_s_details_on_this_page'),
          t('text:You_can_change_a_user_s_email_subscription_settings_with_the_Email_Notification_Setting_button'),
        ]}
      />
      <div className="content-area">
        <FormBorder>
          <FormSubtitle title={t('text:PARTNER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Client_Code')}
                value={dealerUserDetail?.dealerClientCode}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Partner_Name')}
                value={dealerUserDetail?.dealerClientName}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:PARTNER_USER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:User_Code')}
                value={dealerUserDetail?.authorizerEmployeeCode}
              />
              <FormValue
                className="information-form__input"
                label={t('text:User_Name')}
                value={dealerUserDetail?.authorizerName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Mobile')}
                value={dealerUserDetail?.authorizerMobile}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Email')}
                value={dealerUserDetail?.authorizerEmail}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:User_Account_Status')}
                className={
                  'information-form__input ' +
                  getStatusTextClass('AUTHORIZER_STATUS', dealerUserDetail?.authorizerStatus)
                }
                value={t(`code:authorizer-status.${dealerUserDetail?.authorizerStatus}`)}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Partner_User_ID')}
                value={dealerUserDetail?.userLoginId}
              />
            </div>
            {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && (
              <div className="row">
                <FormValue
                  className="information-form__input"
                  label={t('text:Account_Email')}
                  value={dealerUserDetail?.userEmail}
                  labelChildren={
                    <QuestionTooltip
                      id="account-email-tip"
                      place="top"
                      contentText={<>{t('text:This_is_the_email_where_user_will_receive_notifications')}</>}
                    />
                  }
                />
                <div className="col-6">
                  <Button
                    style={{ height: '40px' }}
                    disabled={dealerUserDetail?.authorizerStatus !== AUTHORIZER_STATUS.ACTIVATED}
                    onClick={handleClickEmailNotificationSetting}
                  >
                    {t('text:Email_Notification_Setting')}
                  </Button>
                </div>
              </div>
            )}
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
};

export default FinancierManageDealerUserDetail;
