import { useTranslation } from 'react-i18next';

import { isNumber } from 'lodash-es';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';

interface FinancierFinancingDetailViewRepaymentHistoryModalProps {
  data: LoanDetailVOModel;
}

function FinancierFinancingDetailViewRepaymentHistoryModal({
  data,
}: FinancierFinancingDetailViewRepaymentHistoryModalProps) {
  const { t } = useTranslation(['format']);

  return (
    <FormBorder>
      <FormContents>
        <div className="row">
          <FormValue
            className="information-form__input"
            col={12}
            label={t('text:Transaction_Date')}
            value={data.tempTransactionDate}
            format="date"
          />
        </div>
        <div className="row">
          <FormValue
            className="information-form__input text-end"
            col={12}
            label={t('text:Total_Interest_Amount_Repaid')}
            value={data.tempTotalInterestAmount}
            format="number"
          />
        </div>
        <div className="row">
          <FormValue
            className="information-form__input text-end"
            col={12}
            label={t('text:Interest_Amount_Repaid_at_this_time')}
            value={
              isNumber(data.tempTotalInterestAmount) &&
              isNumber(data.repaidInterestAmount) &&
              data.tempTotalInterestAmount - data.repaidInterestAmount
            }
            format="number"
          />
        </div>
        <div className="row">
          <FormValue
            className="information-form__input text-end"
            col={12}
            label={t('text:Total_Delinquent_Interest_Amount_Repaid')}
            value={data.tempTotalDelayAmount}
            format="number"
          />
        </div>
        <div className="row">
          <FormValue
            className="information-form__input text-end"
            col={12}
            label={t('text:Delinquent_Interest_Amount_Repaid_at_this_time')}
            value={
              isNumber(data.tempTotalDelayAmount) &&
              isNumber(data.repaidInterestAmount) &&
              data.tempTotalDelayAmount - data.repaidDelayAmount
            }
            format="number"
          />
        </div>
      </FormContents>
    </FormBorder>
  );
}

export default FinancierFinancingDetailViewRepaymentHistoryModal;
