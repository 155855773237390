import type { FieldError } from 'react-hook-form';
import type { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';

import type { NewObject } from 'types';
import validationRules from 'utils/validation/validationRules';

type UseValidationPropsType = {
  errorFields?: FieldErrors<NewObject>;
  isSavedFields?: boolean;
  accountType?: 'AR' | 'AP';
  rule?: keyof typeof validationRules;
  minDate?: Date;
  maxDate?: Date;
};

const useValidation = ({
  errorFields,
  isSavedFields = false,
  accountType,
  rule,
  minDate,
  maxDate,
}: UseValidationPropsType) => {
  const { t } = useTranslation();
  const getValidationClassName = (
    fieldName: keyof NewObject,
    tagName: 'th' | 'td' | 'input' | 'select',
    index: number,
  ) => {
    const getDefaultClassName = () => {
      switch (tagName) {
        case 'input':
          return 'grid-input';
        case 'select':
          return 'grid-select';
        default:
          return '';
      }
    };

    if (!(errorFields && errorFields[index])) {
      return getDefaultClassName();
    }

    const getClassName = (errorField?: FieldError) => {
      const className = getDefaultClassName();

      if (!errorField) return className;

      if (tagName === 'td' || tagName === 'th') return `${className} grid-error-background`;
      else return `${className} error-input-border`;
    };

    if (isSavedFields) {
      if (errorFields[index]?.arAmount?.type === 'validate' && fieldName === 'arAmount')
        return getClassName(errorFields[index]?.arAmount);
      else if (errorFields[index]?.invoiceAmount?.type === 'validate' && fieldName === 'invoiceAmount')
        return getClassName(errorFields[index]?.invoiceAmount);
      else return getDefaultClassName();
    }

    return getClassName(errorFields[index]?.[fieldName]);
  };
  const getValidationResult = (index: number, isExceeded = false) => {
    if (!rule) return;

    const errorRow = errorFields?.[index];

    if (isExceeded) {
      return (
        <td className="text-red">{t('text:Fail_The_total_sum_of_invoices_must_not_exceed_the_remaining_limit')}</td>
      );
    }

    if (!errorRow) {
      if (isSavedFields) return <td />;

      return <td className="text-bold-blue-main600">{t('text:Pass')}</td>;
    }

    if (isSavedFields) {
      if (errorRow.arAmount && errorRow.arAmount?.type === 'validate')
        return <td className="text-red">{t(errorRow.arAmount?.message)}</td>;
      else if (errorRow.invoiceAmount && errorRow.invoiceAmount?.type === 'validate')
        return <td className="text-red">{t(errorRow.invoiceAmount?.message)}</td>;
      else return <td />;
    }

    const result = validationRules[rule]({ errorRow, t, accountType, minDate, maxDate }).find(
      ({ condition }) => condition,
    );
    if (!result) return <td />;

    const { message } = result;

    return <td className="text-red">{message}</td>;
  };

  return {
    getValidationClassName,
    getValidationResult,
  };
};

export default useValidation;
