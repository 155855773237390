// 재고담보 목록

import type Pageable from 'models/Pageable';
import type { InventorySummaryDetailVO, InventorySummaryDetailVOModel } from 'models/vo/InventorySummaryDetailVO';
import { formattingToInventorySummaryDetailVOModel } from 'models/vo/InventorySummaryDetailVO';
import type { InventorySummaryVO, InventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import { formattingToInventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierInventorySummariesListRequest } from './request';

export async function requestFinancierInventoryList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierInventorySummariesListRequest,
): Promise<Pageable<InventorySummaryVOModel[]>> {
  const response = await http.get<Pageable<InventorySummaryVO[]>>({
    url: API_FI.INVENTORY_SUMMARIES.INVENTORY_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const inventorySummaryList: InventorySummaryVOModel[] = response.content.map((data: InventorySummaryVO) =>
    formattingToInventorySummaryVOModel(data),
  );
  const inventorySummaryPage: Pageable<InventorySummaryVOModel[]> = {
    ...response,
    content: inventorySummaryList,
  };

  return inventorySummaryPage;
}

// 재고담보 상세조회
export async function requestFinancierInventoryDetail(
  inventorySummaryId: number,
): Promise<InventorySummaryDetailVOModel> {
  const response = await http.get<InventorySummaryDetailVO>({
    url: API_FI.INVENTORY_SUMMARIES.INVENTORY_DETAIL(inventorySummaryId),
  });

  return formattingToInventorySummaryDetailVOModel(response);
}

// 재고담보 첨부파일 다운로드
export async function requestFinancierInventoryAttachmentDownload(inventorySummaryId: number) {
  await http.download(API_FI.INVENTORY_SUMMARIES.DOWNLOAD_ATTACHMENT(inventorySummaryId));
}
