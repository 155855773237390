import type Pageable from 'models/Pageable';
import type { InvoicePaymentVO, InvoicePaymentVOModel } from 'models/vo/InvoicePaymentVO';
import { formattingToInvoicePaymentVOModel } from 'models/vo/InvoicePaymentVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorInvoicePaymentListRequest } from './requests';

export async function requestAnchorInvoicePaymentList(
  pageNumber: number,
  rowCount: number,
  data: AnchorInvoicePaymentListRequest,
): Promise<Pageable<InvoicePaymentVOModel[]>> {
  const response = await http.get<Pageable<InvoicePaymentVO[]>>({
    url: API_AC.INVOICE_PAYMENTS.INVOICE_PAYMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToInvoicePaymentVOModel(data)),
  };
}
