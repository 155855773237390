import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import { OTP_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { requestAnchorAgreementsList } from 'utils/http/api/anchor/anchor-agreements';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestAnchorWaitingAnchorDealerApprove,
  requestAnchorWaitingAnchorDealerCancel,
  requestAnchorWaitingAnchorDealerDetail,
  requestAnchorWaitingAnchorDealerReject,
} from 'utils/http/api/anchor/waiting-anchor-dealers';
import type { AnchorWaitingAnchorDealerApproveRequest } from 'utils/http/api/anchor/waiting-anchor-dealers/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const useAnchorDealerRegistrationWaitingDetailPageState = () => {
  const modal = useModal();
  const { t } = useTranslation();
  const [waitingAnchorDealerInfo, setWaitingAnchorDealerInfo] = useState<WaitingAnchorDealerVOModel>();
  const [relatedAnchorAgreement, setRelatedAnchorAgreement] = useState<AnchorAgreementVOModel[]>();
  const [anchorClientAuthInfo, setAnchorClientAuthInfo] = useState<FinancierClientAuthSettingVOModel>();
  const { pageable, setPageable } = usePageable();
  const { waitingAnchorDealerId } = useParams() as any;
  async function fetchAll() {
    try {
      const anchorDealerResponse = await requestAnchorWaitingAnchorDealerDetail(waitingAnchorDealerId);

      const [relatedAnchorAgreementResponse, anchorClientAuthResponse] = await Promise.all([
        requestAnchorAgreementsList(1, 10, {
          financierName: anchorDealerResponse.financierName,
        }),
        requestAnchorClientAuthByFinancierId(anchorDealerResponse.financierId),
      ]);

      setWaitingAnchorDealerInfo(anchorDealerResponse);
      setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
      setPageable(relatedAnchorAgreementResponse);
      setAnchorClientAuthInfo(anchorClientAuthResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchAnchorDealer = async () => {
    try {
      const anchorDealerResponse = await requestAnchorWaitingAnchorDealerDetail(waitingAnchorDealerId);

      setWaitingAnchorDealerInfo(anchorDealerResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    const relatedAnchorAgreementResponse = await requestAnchorAgreementsList(pageNumber, sizePerPage, {
      financierName: waitingAnchorDealerInfo?.financierName,
    });

    setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
    setPageable(relatedAnchorAgreementResponse);
  };
  const cancelRequest = async () => {
    try {
      await requestAnchorWaitingAnchorDealerCancel([waitingAnchorDealerId]);
      fetchAnchorDealer();
    } catch (error) {
      modal.show(error);
    }
  };

  const revertRequest = async (reason: string) => {
    try {
      await requestAnchorWaitingAnchorDealerReject([waitingAnchorDealerId], reason);
      modal.show(<h6>{t('text:The_Dealer_registration_request_has_been_reverted')}</h6>, {
        closeBtnCb: () => fetchAnchorDealer(),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const approveRequest = async () => {
    const showVerificationOtpModal = () => {
      const verificationCode: UserVerificationCodeRequest = {};

      modal.show(
        <UserVerificationModal
          modalId={modal.id}
          verificationCode={verificationCode}
          requestIdType="financierId"
          requestId={waitingAnchorDealerInfo?.financierId!}
          clientAuthSetting={anchorClientAuthInfo!}
        />,
        {
          modalType: ModalType.CONFIRM,
          title: t('text:User_Verification'),
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: () => onClickApproveConfirm(verificationCode),
        },
      );
    };

    const onClickApproveConfirm = async (verificationCode?: UserVerificationCodeRequest) => {
      const formData: AnchorWaitingAnchorDealerApproveRequest = {
        financierId: waitingAnchorDealerInfo?.financierId!,
        otpCode: verificationCode?.otpCode,
        queryValue: verificationCode?.queryValue,
        waitingAnchorDealerIds: [waitingAnchorDealerId],
      };
      try {
        await requestAnchorWaitingAnchorDealerApprove(formData);
        modal.show(<h6>{t('text:The_dealer_has_been_registered_successfully')}</h6>, {
          closeBtnCb: () => fetchAnchorDealer(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    anchorClientAuthInfo?.otpType !== OTP_TYPE.NONE ? showVerificationOtpModal() : await onClickApproveConfirm();
  };

  return {
    fetchAll,
    paginate,
    pageable,
    relatedAnchorAgreement,
    waitingAnchorDealerInfo,
    cancelRequest,
    approveRequest,
    revertRequest,
  };
};

export default useAnchorDealerRegistrationWaitingDetailPageState;
