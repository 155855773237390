import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';

type CreateFinancierValue = {
  activeStep: number;
};

type CreateFinancierActions = {
  onStepTransition: (step: number) => void;
  onCancelClick: () => void;
};

const CreateFinancierValueContext = createContext<CreateFinancierValue>({
  activeStep: 1,
});

const CreateFinancierActionsContext = createContext<CreateFinancierActions>({
  onStepTransition: () => {},
  onCancelClick: () => {},
});

const CreateFinancierProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { t } = useTranslation();
  const { show: showModal } = useModal();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState<number>(1);

  const handleStepTransition = useCallback(step => {
    scrollToTopOfElement('main-container');
    setActiveStep(currentStep => currentStep + step);
  }, []);

  const handleCancelClick = useCallback(() => {
    const showConfirmCancelModal = () => {
      showModal(<h6>{t('text:The_registration_has_been_cancelled_successfully')}</h6>, {
        title: t('text:Cancel_Registration'),
        closeBtnCb: () => history.push('/'),
      });
    };

    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Cancel_Registration'),
        confirmBtnText: t('text:Cancel'),
        confirmBtnCb: showConfirmCancelModal,
      },
    );
  }, [history, showModal, t]);

  const actions: CreateFinancierActions = useMemo(() => {
    return {
      onStepTransition: handleStepTransition,
      onCancelClick: handleCancelClick,
    };
  }, [handleStepTransition, handleCancelClick]);

  return (
    <CreateFinancierActionsContext.Provider value={actions}>
      <CreateFinancierValueContext.Provider
        value={{
          activeStep,
        }}
      >
        {children}
      </CreateFinancierValueContext.Provider>
    </CreateFinancierActionsContext.Provider>
  );
};

export default CreateFinancierProvider;

export const useCreateFinancierValue = () => useContext(CreateFinancierValueContext);

export const useCreateFinancierActions = () => useContext(CreateFinancierActionsContext);
