import type Pageable from 'models/Pageable';
import type { BranchHistoryVO, BranchHistoryVOModel } from 'models/vo/BranchHistoryVO';
import { formattingToBranchHistoryVOModel } from 'models/vo/BranchHistoryVO';
import type { BranchVO, BranchVOModel } from 'models/vo/BranchVO';
import { formattingToBranchVOModel } from 'models/vo/BranchVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';
import type {
  FinancierBranchListRequest,
  FinancierBranchRegisterRequest,
  FinancierBranchUpdateRequest,
} from 'utils/http/api/financier/branches/request';

// 지점 목록 조회
export async function requestBranchList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierBranchListRequest,
): Promise<Pageable<BranchVOModel[]>> {
  const response = await http.get<Pageable<BranchVO[]>>({
    url: API_FI.BRANCHES.BRANCHES,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const FinancierBranchList: BranchVOModel[] = response.content.map((data: BranchVO) =>
    formattingToBranchVOModel(data),
  );
  const BranchPage: Pageable<BranchVOModel[]> = {
    ...response,
    content: FinancierBranchList,
  };

  return BranchPage;
}

// 지점 등록
export async function requestBranchRegister(data: FinancierBranchRegisterRequest): Promise<BranchVOModel> {
  const response = await http.post<BranchVO>({
    url: API_FI.BRANCHES.BRANCHES,
    data,
  });

  return formattingToBranchVOModel(response);
}

// 지점 전문연동
export async function requestBranchInterface(branchCode: string): Promise<BranchVOModel> {
  const response = await http.get<BranchVO>({
    url: API_FI.BRANCHES.BRANCH_BY_FINANCIER_INTERFACE(branchCode),
  });

  return formattingToBranchVOModel(response);
}

// 지점 상세 조회
export async function requestBranchDetail(branchId: number): Promise<BranchVOModel> {
  const response = await http.get<BranchVO>({
    url: API_FI.BRANCHES.BRANCH_DETAIL(branchId),
  });

  return formattingToBranchVOModel(response);
}

// 지점 수정
export async function requestBranchEdit(branchId: number, data: FinancierBranchUpdateRequest): Promise<BranchVOModel> {
  const response = await http.put<BranchVO>({
    url: API_FI.BRANCHES.BRANCH_DETAIL(branchId),
    data,
  });

  return formattingToBranchVOModel(response);
}

// 지점 히스토리 조회
export async function requestBranchHistoryList(
  branchId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<BranchHistoryVOModel[]>> {
  const response = await http.get<Pageable<BranchHistoryVO[]>>({
    url: API_FI.BRANCHES.BRANCH_HISTORY_LIST(branchId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const FinancierBranchHistoryList: BranchHistoryVOModel[] = response.content.map((data: BranchHistoryVO) =>
    formattingToBranchHistoryVOModel(data),
  );
  const BranchHistoryPage: Pageable<BranchHistoryVOModel[]> = {
    ...response,
    content: FinancierBranchHistoryList,
  };

  return BranchHistoryPage;
}
