export interface AnchorDealerVO {
  id: number;
  name: string;
  taxCode: string;
  businessCode: string;
  telephone: string;
  address: string;
  fax: string;
  representativeName: string;
  representativeEmail: string;
  representativePosition: string;
  declined: boolean;
  declinedReason: string;
  financierId: number;
  financierName: string;
  anchorId: number;
  anchorName: string;
  anchorClientId: number;
  anchorClientName: string;
  assignedBranchId: number;
  assignedBranchName: string;
  assignedBranchCode: string;
  assignedBranchAddress: string;
  createdDateTime: string;
  updatedDateTime: string;
}

export interface AnchorDealerVOModel extends AnchorDealerVO {}

export function formattingToAnchorDealerVOModel(data: AnchorDealerVO): AnchorDealerVOModel {
  return {
    ...data,
  };
}
