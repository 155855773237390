import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { ANCHOR_AGREEMENT_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';

interface RelatedAnchorAgreementListProps {
  relatedAnchorAgreement: AnchorAgreementVOModel[] | undefined;
  pageable: PageableType;
  paginate(pageNumber: number, sizePerPage: number): Promise<void>;
}

function RelatedAnchorAgreementList({ relatedAnchorAgreement, pageable, paginate }: RelatedAnchorAgreementListProps) {
  const { t } = useTranslation();

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 150,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Responsible_Bank_Branch'),
      colWidths: 180,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expired_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Agreement_Suspension'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const getAnchorAgreementStatusClassName = (anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS) => {
    switch (anchorAgreementStatus) {
      case ANCHOR_AGREEMENT_STATUS.ACTIVATED:
        return 'text-bold-dark-green';
      case ANCHOR_AGREEMENT_STATUS.SUSPENDED:
        return 'text-bold-brick-red';
      default:
        return '';
    }
  };

  return (
    <div className="content-area">
      <SectionTitle title={t(`text:Associated_Anchor_Information`)} />
      <TableBorder>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>
          {relatedAnchorAgreement?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
                <Td data={item.contractNo} />
                <Td data={item.currencyType} />
                <Td data={item.financierClientName} />
                <Td data={item.branchName} />
                <Td data={item.startDate} format="date" />
                <Td data={item.expiryDate} format="date" />
                <Td
                  data={t(`code:anchor-agreement-status.${item?.anchorAgreementStatus}`)}
                  className={getAnchorAgreementStatusClassName(item.anchorAgreementStatus)}
                  format="code"
                />
                <TdLink
                  path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.anchorAgreementId)}
                  state={{
                    supportedCollateralType: item.collateralType,
                  }}
                />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default RelatedAnchorAgreementList;
