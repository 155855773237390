import type Pageable from 'models/Pageable';
import { formattingToWaitingAnchorFinancingOptionVOModel } from 'models/vo/WaitingAnchorFinancingOptionVO';
import type {
  WaitingAnchorFinancingOptionVO,
  WaitingAnchorFinancingOptionVOModel,
} from 'models/vo/WaitingAnchorFinancingOptionVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { SearchAcWaitingAnchorFinancingOptionDTO } from './requests';

export async function requestAnchorWaitingAnchorFinancingOptionList(
  pageNumber: number,
  rowCount: number,
  SearchAcWaitingAnchorFinancingOptionDTO: SearchAcWaitingAnchorFinancingOptionDTO,
): Promise<Pageable<WaitingAnchorFinancingOptionVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorFinancingOptionVO[]>>({
    url: API_AC.WAITING_ANCHOR_FINANCING_OPTIONS.WAITING_ANCHOR_FINANCING_OPTIONS,
    data: {
      pageNumber,
      rowCount,
      ...SearchAcWaitingAnchorFinancingOptionDTO,
    },
  });

  const waitingAnchorAgreementList = response.content.map(data =>
    formattingToWaitingAnchorFinancingOptionVOModel(data),
  );

  return {
    ...response,
    content: waitingAnchorAgreementList,
  };
}
