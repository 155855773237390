import type Pageable from 'models/Pageable';
import type { WaitingArVO, WaitingArVOModel } from 'models/vo/WaitingArVO';
import { formattingToWaitingArVOModel } from 'models/vo/WaitingArVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { DealerWaitingArRequest } from './request';

export async function requestDealerWaitingArList(
  searchValue?: DealerWaitingArRequest,
): Promise<Pageable<WaitingArVOModel[]>> {
  const response = await http.get<Pageable<WaitingArVO[]>>({
    url: API_DE.WAITING_ARS.WAITING_AR_LIST,
    data: searchValue,
  });
  const waitingArList: WaitingArVOModel[] = response.content.map((data: WaitingArVO) =>
    formattingToWaitingArVOModel(data),
  );
  const waitingArPage: Pageable<WaitingArVOModel[]> = {
    ...response,
    content: waitingArList,
  };

  return waitingArPage;
}
