import { Utils } from 'digital-signatures/bkav/Utils';
import { FILE_TYPE } from 'digital-signatures/enums';
import DigitalSignatureAuthModal from 'digital-signatures/modals/DigitalSignatureAuthModal';
import DigitalSignaturePluginDownloadModal from 'digital-signatures/modals/DigitalSignaturePluginDownloadModal';
import { SignBase64PDF } from 'digital-signatures/utils/signBase64PDF';
import { SignTrxAppCallback } from 'digital-signatures/utils/signTrxAppCallback';
import i18next from 'i18next';

import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import type { ModalContextProps } from 'utils/modal/ModalContext';
import { ModalSize } from 'utils/modal/ModalWrapper';
import { ModalType } from 'utils/modal/ModalWrapper';

type GetVersionAPPCallBackProps = {
  data: any;
  modal: ModalContextProps;
  PDFInfos: { loanAgreementAttachment: string; loanFactoringNotificationAttachment: string };
  pdfSignCompletedCb: (loanAgreementFileList: FileList, loanFactoringNotificationFileList: FileList) => void;
};
export function GetVersionAPPCallBack({ data, modal, PDFInfos, pdfSignCompletedCb }: GetVersionAPPCallBackProps) {
  const { loanAgreementAttachment, loanFactoringNotificationAttachment } = PDFInfos;
  unShowLoadingUI();
  if (data) {
    const version = data.split('.');
    const basisVersion = ['2', '1', '6'];
    if (
      version[0] < basisVersion[0] ||
      (version[0] === basisVersion[0] && version[1] < basisVersion[1]) ||
      (version[0] === basisVersion[0] && version[1] === basisVersion[1] && version[2] < basisVersion[2])
    ) {
      modal.show(<DigitalSignaturePluginDownloadModal />, {
        title: i18next.t('text:Digital_Signature'),
        modalType: ModalType.ALERT,
        closeBtnText: i18next.t('text:Close'),
      });
    } else {
      modal.show(
        <DigitalSignatureAuthModal
          loanAgreementAttachment={loanAgreementAttachment}
          loanFactoringNotificationAttachment={loanFactoringNotificationAttachment}
        />,
        {
          modalSize: ModalSize.DIGITAL_SIGNATURE,
          modalType: ModalType.CONFIRM,
          title: i18next.t('text:Digital_Signature'),
          isModalClosesOnConfirm: false,
          confirmBtnCb: () => {
            showLoadingUI();
            SignBase64PDF(loanAgreementAttachment, FILE_TYPE.APPLICATION_FORM, modal, (data: any) =>
              SignTrxAppCallback({
                data,
                modal,
                trxNoticePDFInfo: loanFactoringNotificationAttachment,

                pdfSignCompletedCb,
              }),
            );
          },
        },
      );
      Utils.GetCertListByFilter(process.env.REACT_APP_DIGITAL_SIGNATURE_LICENSE_KEY, Utils.GetCertResult);
    }
  } else {
    modal.show(<DigitalSignaturePluginDownloadModal />, {
      title: i18next.t('text:Digital_Signature'),
      modalType: ModalType.ALERT,
      closeBtnText: i18next.t('text:Close'),
    });
  }
}
