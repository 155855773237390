import type React from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, OTP_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { AnchorFinancingOptionDetailVOModel } from 'models/vo/AnchorFinancingOptionDetailVO';
import type { CalculateForMultipleAnchorLoanRequestVOModel } from 'models/vo/CalculateForMultipleAnchorLoanRequestVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { SuccessInvoiceForRequestMultipleAnchorLoanVOModel } from 'models/vo/SuccessInvoiceForRequestMultipleAnchorLoanVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestAnchorFinancingOptionDetail } from 'utils/http/api/anchor/anchor-financing-opitons';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestAnchorFinancierCalendar } from 'utils/http/api/anchor/financier-calendar';
import {
  requestAnchorMultipleRequestLoanRequestCalculateSuccessInvoices,
  requestAnchorMultipleRequestLoanRequestSuccessInvoices,
} from 'utils/http/api/anchor/multiple-request-anchor-loans';
import type { AnchorMultipleLoanSuccessInvoicesRequest } from 'utils/http/api/anchor/multiple-request-anchor-loans/requests';
import type { AnchorMultipleLoanCalculateSuccessInvoicesRequest } from 'utils/http/api/anchor/multiple-request-anchor-loans/requests';
import { requestAnchorSuccessInvoiceListForMultipleRequestLoans } from 'utils/http/api/anchor/success-invoices';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import type { EtcFileRowTypes } from '../section/additional-documents';

export interface AnchorInvoiceBulkFinancingApplicationStep2State {
  anchorFinancingOptionDetail: AnchorFinancingOptionDetailVOModel;
  financierHoliday: Pageable<FinancierCalenderVOModel[]>;
  calculateLoanRequest?: CalculateForMultipleAnchorLoanRequestVOModel;
  successInvoiceList: SuccessInvoiceForRequestMultipleAnchorLoanVOModel[];
  checkedInvoiceList: number[];
  maxDate?: Date;
  minDate?: Date;
  totalSuccessInvoiceListCount: number;
}

export function useAnchorInvoiceBulkFinancingApplicationStep2State() {
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation();
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const { anchorFinancingOptionId } = useParams() as any;

  const [etcFileRows, setEtcFileRows] = useState<EtcFileRowTypes[]>([
    {
      id: 0,
      fileName: undefined,
    },
  ]);

  const [dataState, setDataState] = useState<AnchorInvoiceBulkFinancingApplicationStep2State>({
    anchorFinancingOptionDetail: {} as AnchorFinancingOptionDetailVOModel,
    calculateLoanRequest: {} as CalculateForMultipleAnchorLoanRequestVOModel,
    financierHoliday: {} as Pageable<FinancierCalenderVOModel[]>,
    successInvoiceList: [],
    checkedInvoiceList: [],
    totalSuccessInvoiceListCount: 0,
  });

  const [showedTotalRequestedAmount, setShowedTotalRequestedAmount] = useState<BigNumber>();
  const [selectedTotalInvoiceAmount, setSelectedTotalInvoiceAmount] = useState<BigNumber>();
  const [totalRequestedAmount, setTotalRequestedAmount] = useState<BigNumber>();

  const anchorInvoiceBulkFinancingUseForm = useForm<AnchorMultipleLoanSuccessInvoicesRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { reset, getValues } = anchorInvoiceBulkFinancingUseForm;

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allCheckedInvoiceList = dataState.successInvoiceList?.map((_: any, index: any) => index);
      setDataState({ ...dataState, checkedInvoiceList: allCheckedInvoiceList ?? [] });
    } else {
      setDataState({ ...dataState, checkedInvoiceList: [] });
      setTotalRequestedAmount('0');
    }
    initializeFinancingConditionInfo();
  };

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.checked) {
      const target = [...dataState.checkedInvoiceList, index];
      setDataState({ ...dataState, checkedInvoiceList: target });
    } else {
      const target = dataState.checkedInvoiceList.filter(arIndex => arIndex !== index);
      setDataState({ ...dataState, checkedInvoiceList: target });
    }
    initializeFinancingConditionInfo();
  };

  const fetchFinancingHoliday = async (financierId: number, minDate: Date, maxDate: Date) => {
    return await requestAnchorFinancierCalendar(financierId, {
      pageNumber: 0,
      rowCount: Number(getDayTerm(convertToServerDateFormat(minDate), convertToServerDateFormat(maxDate))) + 1,
      fromDate: convertToServerDateFormat(minDate),
      toDate: convertToServerDateFormat(maxDate),
      holiday: true,
    });
  };

  const getMaxDate = (
    expiryDate: Date,
    settlementDate: Date,
    disbursementDateOverAllowable: boolean,
    minimumLoanRange: number,
  ): Date => {
    const endDate = dayjs(settlementDate).subtract(minimumLoanRange, 'day').toDate();

    let finalDate = endDate;
    if (!disbursementDateOverAllowable) {
      if (expiryDate < endDate) {
        finalDate = expiryDate;
      }
    }

    return finalDate;
  };

  const getMinDate = (settlementDate: Date, maximumLoanRange: number): Date => {
    const today = new Date();

    const endDate = dayjs(settlementDate).subtract(maximumLoanRange, 'days').toDate();

    let finalDate = endDate;
    if (today > endDate) {
      finalDate = today;
    }

    return finalDate;
  };

  const fetchAll = async () => {
    try {
      showLoadingUI();
      const fetchedAnchorFinancingOptionDetail = await requestAnchorFinancingOptionDetail(anchorFinancingOptionId);

      const fetchedSuccessInvoiceList = await requestAnchorSuccessInvoiceListForMultipleRequestLoans(
        0,
        3000,
        fetchedAnchorFinancingOptionDetail.anchorAgreementId,
      );

      const requestMultipleLoanByInvoiceList = fetchedSuccessInvoiceList.content.map(invoiceInfo => ({
        successInvoiceId: invoiceInfo.successInvoiceId,
        requestedLoanAmount: String(invoiceInfo.maxLtvAmount),
      }));

      const minSettlementDate = new Date(
        Math.min(
          ...fetchedSuccessInvoiceList.content.map(invoiceInfo =>
            new Date(invoiceInfo.desiredDisburseDateByPartner).getTime(),
          ),
        ),
      );

      const maxSettlementDate = new Date(
        Math.max(
          ...fetchedSuccessInvoiceList.content.map(invoiceInfo =>
            new Date(invoiceInfo.desiredDisburseDateByPartner).getTime(),
          ),
        ),
      );

      const minDate = getMinDate(minSettlementDate, fetchedAnchorFinancingOptionDetail.maximumLoanRange);

      const maxDate = getMaxDate(
        new Date(fetchedAnchorFinancingOptionDetail.anchorAgreementExpiryDate),
        maxSettlementDate,
        fetchedAnchorFinancingOptionDetail.disbursementDateOverAllowable,
        fetchedAnchorFinancingOptionDetail.minimumLoanRange,
      );

      const holidayList = await fetchFinancingHoliday(
        fetchedAnchorFinancingOptionDetail.financierEntId,
        minDate,
        maxDate,
      );

      unShowLoadingUI();

      setDataState(prevState => ({
        ...prevState,
        anchorFinancingOptionDetail: fetchedAnchorFinancingOptionDetail,
        financierHoliday: holidayList,
        minDate,
        maxDate,
        successInvoiceList: fetchedSuccessInvoiceList.content,
        totalSuccessInvoiceListCount: fetchedSuccessInvoiceList.totalElements,
      }));

      reset({
        ...getValues(),
        requestedInvoices: requestMultipleLoanByInvoiceList,
      });
    } catch (e) {
      unShowLoadingUI();
      modal.show(e);
    }
  };

  const fetchAnchorInvoiceBulkCalculateLoanRequest = async (
    data: AnchorMultipleLoanCalculateSuccessInvoicesRequest,
  ) => {
    try {
      const fetchedCalculateMultipleLoanRequest = await requestAnchorMultipleRequestLoanRequestCalculateSuccessInvoices(
        data,
      );

      setDataState(prevState => ({
        ...prevState,
        calculateLoanRequest: fetchedCalculateMultipleLoanRequest,
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const calculateRequestedAmount = useCallback(
    (checkedInvoiceList: number[]) => {
      const invoiceInfoList = getValues().requestedInvoices;
      const checkedInvoiceInfoList = invoiceInfoList?.filter((_: any, rowIndex: number) =>
        checkedInvoiceList.includes(rowIndex),
      );
      const calculatorBigNumber = new CalculatorBigNumber();
      let sum: BigNumber = '0';
      if (checkedInvoiceInfoList?.length !== 0) {
        checkedInvoiceInfoList?.forEach(invoiceInfo => {
          if (invoiceInfo && invoiceInfo.requestedLoanAmount) {
            sum = calculatorBigNumber.add(sum).add(invoiceInfo.requestedLoanAmount).get();
          }
        });
      }
      setTotalRequestedAmount(sum);
    },
    [getValues, setDataState],
  );

  const getCheckedLoanByInvoiceRequestList = () => {
    const checkedLoanByInvoiceRequestList = getValues()
      .requestedInvoices.map((invoiceInfo, index) => ({ ...invoiceInfo, index }))
      .filter((_, rowIndex) => dataState.checkedInvoiceList.includes(rowIndex));

    return checkedLoanByInvoiceRequestList;
  };

  const manipulateLoanEtcAttachmentRequestData = (data: AnchorMultipleLoanSuccessInvoicesRequest) => {
    const etcAttachmentsArr: FileList[] = [];
    const etcAttachmentDescriptionsArr: string[] = [];

    const emptyFile = (id: number, fileName: string | undefined) =>
      data.etcAttachments?.[id]?.length === 0 && !fileName;
    const emptyDescription = (id: number) => !data?.etcAttachmentDescriptions?.[id];

    etcFileRows?.forEach(item => {
      if (emptyFile(item.id, item.fileName) && emptyDescription(item.id)) return;
      etcAttachmentsArr.push((data.etcAttachments as FileList[])?.[item.id]);
      etcAttachmentDescriptionsArr.push(data?.etcAttachmentDescriptions?.[item.id] as string);
    });

    data.etcAttachments = etcAttachmentsArr;
    data.etcAttachmentDescriptions = etcAttachmentDescriptionsArr;
  };

  const manipulateRegistrationRequestData = (): AnchorMultipleLoanSuccessInvoicesRequest => {
    const { anchorAgreementId } = dataState.anchorFinancingOptionDetail;
    const data = getValues();
    const checkedLoanByInvoiceRequestList = getCheckedLoanByInvoiceRequestList();
    manipulateLoanEtcAttachmentRequestData(data);

    data.requestedInvoices = checkedLoanByInvoiceRequestList.map(requestedInvoice => ({
      successInvoiceId: requestedInvoice.successInvoiceId,
      requestedLoanAmount: requestedInvoice.requestedLoanAmount,
    }));
    data.anchorAgreementId = anchorAgreementId;

    return data;
  };

  const requestRegisterInvoiceLoan = async (verificationCode?: UserVerificationCodeRequest) => {
    const formData = manipulateRegistrationRequestData();

    if (verificationCode) {
      formData.otpCode = verificationCode.otpCode;
      formData.queryValue = verificationCode.queryValue;
    }

    try {
      requestDTOParser(formData);
      await requestAnchorMultipleRequestLoanRequestSuccessInvoices(formData);
      showFinancingRequestCompletedModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const showFinancingRequestCompletedModal = () => {
    modal.show(<h6>{t('text:The_financing_application_has_been_submitted')}</h6>, {
      modalType: ModalType.ALERT,
      title: t('text:Notice'),
      closeBtnText: t('text:OK'),
      closeBtnCb: () => history.push(ROUTES_AC.MANAGE_ANCHOR_FINANCING.APPLICATION_LIST_STEP1),
    });
  };

  const showUserVerificationModal = (clientAuthTypeData: FinancierClientAuthSettingVOModel) => {
    const { financierEntId } = dataState.anchorFinancingOptionDetail;
    const verificationCode: UserVerificationCodeRequest = {};

    modal.show(
      <UserVerificationModal
        modalId={modal.id}
        verificationCode={verificationCode}
        requestIdType="financierId"
        requestId={financierEntId}
        clientAuthSetting={clientAuthTypeData}
      />,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          if (!verificationCode) {
            modal.close(modal.id);
            modal.show(t('text:Please_check_the_verification_code_and_enter_it_below'), {
              closeBtnCb: () => {
                showUserVerificationModal(clientAuthTypeData);
              },
            });
          } else {
            await requestRegisterInvoiceLoan(verificationCode);
          }
        },
      },
    );
  };

  const showLoanRequestModal = () => {
    modal.show(<h6>{t('text:Would_you_like_to_submit_the_financing_application?')}</h6>, {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      confirmBtnCb: async () => {
        const clientAuthTypeData = await requestAnchorClientAuthByFinancierId(
          dataState.anchorFinancingOptionDetail.financierEntId,
        );

        clientAuthTypeData.otpType !== OTP_TYPE.NONE && isAdmin
          ? showUserVerificationModal(clientAuthTypeData)
          : await requestRegisterInvoiceLoan();
      },
      closeBtnText: t('text:Cancel'),
    });
  };

  const showCancelFinancingModal = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_financing_application?')}
        <br />
        {t(
          'text:If_you_cancel_the_financing_application_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        title: t('text:Cancel_Financing_Request'),
        confirmBtnCb: () => history.push(ROUTES_AC.MANAGE_ANCHOR_FINANCING.APPLICATION_LIST_STEP1),
      },
    );
  };

  const initializeFinancingConditionInfo = () => {
    setDataState(prevState => ({
      ...prevState,
      calculateLoanRequest: undefined,
    }));
    setShowedTotalRequestedAmount(undefined);
    setSelectedTotalInvoiceAmount(undefined);
  };

  return {
    state: dataState,
    anchorInvoiceBulkFinancingUseForm,
    fetchAnchorInvoiceBulkCalculateLoanRequest,
    fetchAll,
    handleCheckAll,
    handleCheckChange,
    calculateRequestedAmount,
    totalRequestedAmount,
    etcFileRowsState: {
      etcFileRows,
      setEtcFileRows,
    },
    showCancelFinancingModal,
    showLoanRequestModal,
    getCheckedLoanByInvoiceRequestList,
    initializeFinancingConditionInfo,
    showedTotalRequestedAmountState: {
      showedTotalRequestedAmount,
      setShowedTotalRequestedAmount,
    },
    selectedTotalInvoiceAmountState: {
      selectedTotalInvoiceAmount,
      setSelectedTotalInvoiceAmount,
    },
  };
}
