import { isEmpty, isNil } from 'lodash-es';

import { BASIS_INTEREST_TYPE, INTEREST_REPAYMENT_TYPE } from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { TempWaitingDealerAgreementVOModel } from 'models/vo/TempWaitingDealerAgreementVO';
import type { WaitingDealerAgreementDetailVOModel } from 'models/vo/WaitingDealerAgreementDetailVO';
import type { AnchorAgreementInfoType } from 'pages/financier/manage-partner/agreement/models/agreement/AnchorAgreementInfoContext';
import { convertToString } from 'utils/formatter';

// DealerAgreementDetailVOModel 중 제거될 필드
type FieldsToReplace =
  | 'dealerFinancierClientId'
  | 'dealerFinancierClientCode'
  | 'dealerFinancierClientName'
  | 'dealerFinancierClientTaxCode'
  | 'anchorFinancierClientId'
  | 'anchorFinancierClientName'
  | 'anchorAgreementContractNo';

type ReplacementFields = keyof (WaitingDealerAgreementDetailVOModel | TempWaitingDealerAgreementVOModel);

type FieldMapping = {
  [key in FieldsToReplace]: ReplacementFields;
};

// 모든 VO 모델에서 공통으로 사용되는 필드
type CommonAgreementFields = Pick<
  DealerAgreementDetailVOModel,
  keyof DealerAgreementDetailVOModel &
    keyof WaitingDealerAgreementDetailVOModel &
    keyof TempWaitingDealerAgreementVOModel
>;

type DealerAgreementDetails = DealerAgreementDetailVOModel &
  WaitingDealerAgreementDetailVOModel &
  TempWaitingDealerAgreementVOModel;

// 모든 VO 모델의 필드를 병합 (세 VO 모델 타입 모두 허용하도록)
type CombinedAgreementFields = CommonAgreementFields & Partial<DealerAgreementDetails>;

// 공통으로 관리할 파트너 약정 타입
export type DealerAgreementCommonVOModel = CommonAgreementFields &
  Partial<
    Omit<DealerAgreementDetailVOModel, FieldsToReplace> &
      WaitingDealerAgreementDetailVOModel &
      TempWaitingDealerAgreementVOModel
  >;

// DealerVOModel -> WaitingDealerVOModel
const FIELD_MAPPING_CONFIG: FieldMapping = {
  anchorAgreementContractNo: 'mainContractNo',
  anchorFinancierClientId: 'anchorClientId',
  anchorFinancierClientName: 'anchorClientName',
  dealerFinancierClientCode: 'dealerClientCode',
  dealerFinancierClientId: 'dealerClientId',
  dealerFinancierClientName: 'dealerClientName',
  dealerFinancierClientTaxCode: 'dealerClientTaxCode',
};

// 필드를 매핑된 필드로 대체하는 함수
const replaceFieldsWithMapping = (originalFields: CombinedAgreementFields, fieldMapping: FieldMapping) => {
  const replacedFields = Object.fromEntries(
    Object.entries(originalFields).map(([key, value]) => [fieldMapping[key as FieldsToReplace] || key, value]),
  ) as DealerAgreementCommonVOModel;

  return replacedFields;
};

// 약정 정보를 공통으로 관리하는 데이터 변환 함수
export const convertToDealerAgreementCommonVOModel = (data: CombinedAgreementFields): DealerAgreementCommonVOModel => {
  if (!data || isEmpty(data)) return {} as DealerAgreementCommonVOModel;

  const isWaitingType = 'waitingDealerAgreementId' in data;

  const formattedAgreementFields: CombinedAgreementFields = {
    ...data,
    anchorUserList: data.anchorUserList ?? [],
    basisInterestType:
      data.basisInterestType === BASIS_INTEREST_TYPE.FIXED || isNil(data.basisInterestType)
        ? data.basisInterestType
        : BASIS_INTEREST_TYPE.CUSTOM,
  };

  if (isWaitingType) return formattedAgreementFields;

  return replaceFieldsWithMapping(formattedAgreementFields, FIELD_MAPPING_CONFIG);
};

// 전문 약정 데이터 불러올 때
export const convertToInterfaceDealerAgreementData = ({
  prevAnchorAgreementData,
  interfaceData,
  useAnchorUserInterface,
}: {
  prevAnchorAgreementData: AnchorAgreementInfoType;
  interfaceData?: DealerAgreementDetailVOModel;
  useAnchorUserInterface: boolean;
}) => {
  if (!interfaceData || isEmpty(interfaceData)) return;

  const newData = convertToDealerAgreementCommonVOModel(interfaceData);
  const isAnchorUserListChanged =
    prevAnchorAgreementData?.anchorClientId !== newData.anchorClientId || useAnchorUserInterface;

  return {
    ...newData,
    anchorUserList: isAnchorUserListChanged ? newData.anchorUserList : prevAnchorAgreementData?.anchorUserList,
  };
};

// reset
export const convertToDealerAgreementResetData = (data: DealerAgreementCommonVOModel) => {
  return {
    ...data,
    currencyType: data.currencyType || undefined, // currencyType, dealerClientCode 모두 전문, 임시저장으로 가져온 값이 null이면 ''으로 덮어져서 defaultValue가 제대로 보여지지 않는 이슈가
    dealerClientCode: data.dealerClientCode || undefined, // 있어서 undefined로 변환하도록 처리
    termSpreadList: data.termSpreadList || undefined,
    bankUserIdList: data.anchorUserList?.map(anchorUser => anchorUser.bankUserId),
    maxExtensibleLoanCount: data.maxExtensibleLoanCount < 0 ? 0 : data.maxExtensibleLoanCount,
    dealerUploadInvoiceAllowable: convertToString(data.dealerUploadInvoiceAllowable),
    earlyRepaymentAllowable: convertToString(data.earlyRepaymentAllowable),
    repaymentDateOverAllowable: convertToString(data.repaymentDateOverAllowable),
    adhocLimitAllowable: convertToString(data.adhocLimitAllowable),
    showExpectedTotalInterestRate: convertToString(data.showExpectedTotalInterestRate ?? true),
    showNetDisbursementAmount:
      data.interestRepaymentType === INTEREST_REPAYMENT_TYPE.EARLIER
        ? convertToString(data.showNetDisbursementAmount)
        : undefined,
  };
};
