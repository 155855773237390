import { formattingToTempLoanEtcAttachmentVOModel } from './TempLoanEtcAttachmentVO';
import { formattingToTempPurposeOfLoanVOModel } from './TempPurposeOfLoanVO';

import type { TempLoanEtcAttachmentVO, TempLoanEtcAttachmentVOModel } from './TempLoanEtcAttachmentVO';
import type { TempPurposeOfLoanVO, TempPurposeOfLoanVOModel } from './TempPurposeOfLoanVO';

export interface TempLoanVO {
  tempLoanId: number;
  disbursementDate: string;
  requestedLoanAmount: number;
  repaymentDate: string;
  userId: number;
  successInvoiceId: number;
  successArId: number;
  invoiceAttachmentId: number;
  invoiceScannedAttachmentId: number;
  invoiceScannedAttachmentName: string;
  invoiceScannedAttachmentPath: string;
  loanAgreementAttachmentId: number;
  loanAgreementAttachmentName: string;
  loanAgreementAttachmentPath: string;
  loanRequestAttachmentId: number;
  loanRequestAttachmentName: string;
  loanRequestAttachmentPath: string;
  tempLoanEtcAttachmentList: TempLoanEtcAttachmentVO[];
  tempPurposeOfLoanList: TempPurposeOfLoanVO[];
}

export interface TempLoanVOModel extends TempLoanVO {
  tempLoanEtcAttachmentList: TempLoanEtcAttachmentVOModel[];
  tempPurposeOfLoanList: TempPurposeOfLoanVOModel[];
}

export function formattingToTempLoanVOModel(data: TempLoanVO): TempLoanVOModel {
  return {
    ...data,
    tempLoanEtcAttachmentList: data.tempLoanEtcAttachmentList.map(etcAttachment =>
      formattingToTempLoanEtcAttachmentVOModel(etcAttachment),
    ),
    tempPurposeOfLoanList: data.tempPurposeOfLoanList.map(tempPurposeOfLoan =>
      formattingToTempPurposeOfLoanVOModel(tempPurposeOfLoan),
    ),
  };
}
