import type { FinancierCommonSettingVO, FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import { formattingToFinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { FinancierSupportVO, FinancierSupportVOModel } from 'models/vo/FinancierSupportVO';
import { formattingToFinancierSupportVOModel } from 'models/vo/FinancierSupportVO';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';

export async function requestFinancierSettingData(financierId: number): Promise<FinancierCommonSettingVOModel> {
  const response = await http.get<FinancierCommonSettingVO>({
    url: API_CM.FINANCIER_COMMON_SETTING.SETTING_DETAIL(financierId),
  });

  return formattingToFinancierCommonSettingVOModel(response);
}

export async function requestFinancierSupportData(): Promise<FinancierSupportVOModel> {
  const response = await http.get<FinancierSupportVO>({
    url: API_CM.FINANCIER_COMMON_SETTING.FINANCIER_SUPPORT,
  });

  return formattingToFinancierSupportVOModel(response);
}
