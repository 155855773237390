import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import { requestSyAnchorPartnerAccountsList } from 'utils/http/api/system/anchor-partner-accounts';
import { requestSystemAnchorPartnerData } from 'utils/http/api/system/anchor-partners';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);

  const BANK_ACCOUNT_INFORMATION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Assigned_Branch'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Bank_Code'),
    },
    {
      headerText: t('text:Bank_Name'),
    },
    {
      headerText: t('text:Branch_Name'),
    },
    {
      headerText: t('text:Account_Number'),
    },
    {
      headerText: t('text:Account_Holder'),
    },
    {
      headerText: t('text:Requested_Bank'),
    },
    {
      headerText: t('text:Requested_Branch'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return {
    mounted,
    modal,
    history,
    t,
    BANK_ACCOUNT_INFORMATION_TABLE_HEADERS,
  };
};

function SystemMonitorPlatformUploadedPartnerDetail(): JSX.Element {
  const { mounted, modal, history, t, BANK_ACCOUNT_INFORMATION_TABLE_HEADERS } = getConstants();

  const { uploadedPartnerId } = useParams() as any;

  const [systemMonitorPlatformUploadedPartnerData, setSystemMonitorPlatformUploadedPartnerData] =
    useState<AnchorPartnerVOModel>();
  const [bankAccountInformationPage, setBankAccountInformationPage] =
    useState<Pageable<AnchorPartnerAccountVOModel[]>>();

  const { pageable: bankAccountInformationPageable, setPageable: setBankAccountInformationPageable } = usePageable();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [systemMonitorPlatformUploadedPartnerData, bankAccountInformationPage] = await Promise.all([
        requestSystemAnchorPartnerData(uploadedPartnerId),
        requestSyAnchorPartnerAccountsList(
          bankAccountInformationPageable.currentPage,
          bankAccountInformationPageable.sizePerPage,
          {
            anchorPartnerId: uploadedPartnerId,
          },
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemMonitorPlatformUploadedPartnerData(systemMonitorPlatformUploadedPartnerData);
        setBankAccountInformationPage(bankAccountInformationPage);
        setBankAccountInformationPageable(bankAccountInformationPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const bankAccountInformationPaginate = async (
    selectedPageNumber: number,
    selectedRowCount: number,
  ): Promise<void> => {
    try {
      const bankAccountInformationPage = await requestSyAnchorPartnerAccountsList(
        selectedPageNumber,
        selectedRowCount,
        {
          anchorPartnerId: uploadedPartnerId,
        },
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setBankAccountInformationPage(bankAccountInformationPage);
        setBankAccountInformationPageable(bankAccountInformationPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderBankAccountInformationPageTableBody = (): JSX.Element[] | undefined => {
    return bankAccountInformationPage?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.responsibleFinancierName} />
        <Td data={data.assignedBranchName} />
        <Td data={data.anchorAgreementContractNo} />
        <Td data={data.currencyType} />
        <Td data={data.accountBankCode} />
        <Td data={data.accountBankName} />
        <Td data={data.accountBranchName} />
        <Td data={data.account} />
        <Td data={data.accountOwner} />
        <Td data={data.requestedAccountBankName} />
        <Td data={data.requestedAccountBranchName} />

        <Td className="information-table-more">
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            disabled={isNil(data.dealerAgreementId)}
            onClick={() =>
              history.push(ROUTES_SY.MONITOR_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(data.dealerAgreementId))
            }
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={systemMonitorPlatformUploadedPartnerData?.name} />
      <div className="content-area">
        <SectionTitle title={t('text:Uploaded_Partner_Information')} />
        <FormBorder>
          <FormSubtitle title={t('text:ASSOCIATED_ANCHOR_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Anchor_Enterprise_ID')}
                value={systemMonitorPlatformUploadedPartnerData?.anchorId}
              />
              <FormValue label={t('text:Anchor_Name')} value={systemMonitorPlatformUploadedPartnerData?.anchorName} />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:UPLOADED_PARTNER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Uploaded_Partner_ID')}
                value={systemMonitorPlatformUploadedPartnerData?.anchorPartnerId}
              />
              <FormValue
                label={t('text:Uploaded_Partner_Name')}
                value={systemMonitorPlatformUploadedPartnerData?.name}
              />
            </div>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Tax_Code')}
                value={systemMonitorPlatformUploadedPartnerData?.partnerTaxCode}
              />
              <FormValue
                col={3}
                label={t('text:Company_Registration_Number')}
                value={systemMonitorPlatformUploadedPartnerData?.partnerBusinessCode}
              />
              <FormValue
                col={3}
                label={t('text:Telephone')}
                value={systemMonitorPlatformUploadedPartnerData?.telephone}
              />
              <FormValue col={3} label={t('text:Fax')} value={systemMonitorPlatformUploadedPartnerData?.fax} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Legal_Representative_Name')}
                value={systemMonitorPlatformUploadedPartnerData?.representativeName}
              />
              <FormValue
                col={3}
                label={t('text:Legal_Representative_Title')}
                value={systemMonitorPlatformUploadedPartnerData?.representativePosition}
              />
              <FormValue
                col={3}
                label={t('text:Legal_Representative_Email')}
                value={systemMonitorPlatformUploadedPartnerData?.representativeEmail}
              />
            </div>
            <div className="row">
              <FormValue
                col={12}
                label={t('text:Registered_Office_Address')}
                value={systemMonitorPlatformUploadedPartnerData?.address}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Bank_Account_Information')} />
        <TableBorder>
          <TableHeader header={BANK_ACCOUNT_INFORMATION_TABLE_HEADERS} />
          <TableBody numOfCol={BANK_ACCOUNT_INFORMATION_TABLE_HEADERS.length}>
            {renderBankAccountInformationPageTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={bankAccountInformationPageable} paginate={bankAccountInformationPaginate} />
      </div>
    </>
  );
}

export default SystemMonitorPlatformUploadedPartnerDetail;
