import type Pageable from 'models/Pageable';
import type { UserVO, UserVOModel } from 'models/vo/UserVO';
import { formattingToUserVOModel } from 'models/vo/UserVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';
import type {
  DealerUserListRequest,
  DealerUserRegisterRequest,
  DealerUserUpdateRequest,
} from 'utils/http/api/dealer/users/request';

// dealer 사용자 목록
export async function requestDealerUserList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerUserListRequest,
): Promise<Pageable<UserVOModel[]>> {
  const response = await http.get<Pageable<UserVO[]>>({
    url: API_DE.USERS.USER,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const userList: UserVOModel[] = response.content.map((data: UserVO) => formattingToUserVOModel(data));
  const userPage: Pageable<UserVOModel[]> = {
    ...response,
    content: userList,
  };

  return userPage;
}

// dealer 사용자 상세
export async function requestDealerUserDetail(userId: number): Promise<UserVOModel> {
  const response = await http.get<UserVO>({
    url: API_DE.USERS.USER_DETAIL(userId),
  });

  return formattingToUserVOModel(response);
}

// dealer 사용자 등록
export async function requestDealerUserRegister(data: DealerUserRegisterRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_DE.USERS.USER,
    data,
  });

  return formattingToUserVOModel(response);
}

// dealer 사용자 수정
export async function requestDealerUserEdit(userId: number, data: DealerUserUpdateRequest): Promise<UserVOModel> {
  const response = await http.put<UserVO>({
    url: API_DE.USERS.USER_DETAIL(userId),
    data,
  });

  return formattingToUserVOModel(response);
}

// dealer 사용자 이용중지
export async function requestDealerSuspend(userId: number, suspended: boolean): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_DE.USERS.SUSPEND_OPERATOR(userId),
    data: { suspended },
  });

  return formattingToUserVOModel(response);
}

// dealer 사용자 초대메일 발송
export async function requestDealerEmailSend(userId: number) {
  const response = await http.post({
    url: API_DE.USERS.INVITE_OPERATOR(userId),
  });

  return response;
}

export async function requestDealerOperatorInviteUrl(userId: number): Promise<string | null> {
  const response = await http.get<string | null>({
    url: API_DE.USERS.OPERATOR_INVITE_URL(userId),
  });

  return response;
}
