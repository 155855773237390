import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import { TableBody, TableBorder, TableHeader, Td, TdLink, Tr } from 'components/stateless/Table';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import WithProvider from 'utils/render';
import { isLoanDisbursed } from 'utils/status';

import useFinancingListController from './useFinancingListController';
import FinancingListProvider from '../../../models/financingList/Context';

const FinancingList = () => {
  const { t } = useTranslation(['format']);
  const { FINANCING_LIST_TABLE_HEADERS, financingList, financingListPageable, financingListPaginate } =
    useFinancingListController();

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_List')} />
      <p className="total-data">
        {t('text:Total')} {financingListPageable.totalRows}
      </p>
      <TableBorder>
        <TableHeader header={FINANCING_LIST_TABLE_HEADERS} />
        <TableBody numOfCol={FINANCING_LIST_TABLE_HEADERS.length}>
          {financingList?.map((financing, index) => {
            const {
              loanId,
              financierLoanId,
              invoiceNumber,
              referenceNumber,
              arNumber,
              loanStatus,
              disbursedAmount,
              disbursedDate,
              repaymentDate,
            } = financing;

            return (
              <Tr key={index}>
                <Td data={loanId} />
                <Td data={financierLoanId} />
                <Td data={invoiceNumber} />
                <Td data={referenceNumber} />
                <Td data={arNumber} />
                <Td data={isLoanDisbursed(loanStatus) ? disbursedAmount : '-'} format="number" />
                <Td data={disbursedDate} format="date" />
                <Td data={repaymentDate} format="date" />
                <Td
                  className={getStatusTextClass('LOAN_STATUS', loanStatus)}
                  data={t(`code:financing-status.${loanStatus}`)}
                  format="code"
                />
                <TdLink path={ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(loanId)} />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
      <Pagination pageable={financingListPageable} paginate={financingListPaginate} />
    </div>
  );
};

export default WithProvider({
  Component: FinancingList,
  Provider: FinancingListProvider,
});
