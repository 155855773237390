import type Pageable from 'models/Pageable';
import type { AnchorArPartnerNameVO, AnchorPartnerVO, AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import { formattingToAnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import type { WaitingAnchorPartnerVO, WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import { formattingToWaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorPartnersPageRequest, UpdateAcAnchorPartnerRequest } from './requests';

export async function requestAnchorPartnersPage(
  pageNumber: number,
  rowCount: number,
  data: AnchorPartnersPageRequest,
): Promise<Pageable<AnchorPartnerVOModel[]>> {
  const response = await http.get<Pageable<AnchorPartnerVO[]>>({
    url: API_AC.ANCHOR_PARTNERS.PARTNERS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorPartnerVOModel(data)),
  };
}

export async function requestAnchorPartnersData(anchorPartnerId: number): Promise<AnchorPartnerVOModel> {
  const response = await http.get<AnchorPartnerVO>({
    url: API_AC.ANCHOR_PARTNERS.PARTNER_DATA(anchorPartnerId),
  });

  return formattingToAnchorPartnerVOModel(response);
}

export async function requestAnchorPartnerModification(
  anchorPartnerId: number,
  data: UpdateAcAnchorPartnerRequest,
): Promise<WaitingAnchorPartnerVOModel> {
  const response = await http.post<WaitingAnchorPartnerVO>({
    url: API_AC.ANCHOR_PARTNERS.PARTNER_MODIFICATION(anchorPartnerId),
    data,
  });

  return formattingToWaitingAnchorPartnerVOModel(response);
}

// ar partnerTaxCode로 partnerName 조회
export async function requestAnchorArPartnerNames(partnerTaxCodes: string[]): Promise<AnchorArPartnerNameVO[]> {
  const response = await http.get<AnchorArPartnerNameVO[]>({
    url: API_AC.ANCHOR_PARTNERS.PARTNER_NAMES,
    data: { partnerTaxCodes },
  });

  return response;
}
