import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import qs from 'qs';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormErrorMessage } from 'components/stateless/CommonForm/FormErrorMessage';
import FormInput from 'components/stateless/CommonForm/FormInput';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import Timer from 'components/stateless/Timer/Timer';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_CM } from 'constants/routes/common';
import { AUTHORITY_TYPE, ENTERPRISE_TYPE, OTP_TYPE } from 'enums';
import { InvalidInputValueExceptionCode } from 'enums/exception';
import useMounted from 'hooks/useMounted';
import type { DealerAuthorizerVOModel } from 'models/vo/DealerAuthorizerVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { TermsOfUseVersionVOModel } from 'models/vo/TermsOfUseVersionVO';
import { HttpError } from 'utils/error/HttpError';
import { formErrorHandler } from 'utils/error/manager';
import API_AN from 'utils/http/api/anonymous';
import { requestInvitedDealerAuthorizer } from 'utils/http/api/anonymous/agreements';
import { requestClientAuth } from 'utils/http/api/anonymous/client-auth-setting';
import { requestOtpSend } from 'utils/http/api/anonymous/otp';
import { requestTermsOfUseVersions } from 'utils/http/api/anonymous/terms-of-use-versions';
import { requestCertifyDealerAuthorizer } from 'utils/http/api/anonymous/users';
import type { CertifyDealerAuthorizerRequest } from 'utils/http/api/anonymous/users/requests';
import useModal from 'utils/modal/useModal';

function DealerAuthorizerCertify() {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const emailCode = query.code as string;

  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const history = useHistory();

  const [clientAuthSetting, setClientAuthSetting] = useState<FinancierClientAuthSettingVOModel>();
  const [dealerAuthorizer, setDealerAuthorizer] = useState<DealerAuthorizerVOModel>();
  const [isSendCode, setSendCode] = useState<boolean>(false);
  const [mobileOne, setMobileOne] = useState<string>('');
  const [mobileTwo, setMobileTwo] = useState<string>('');
  const [mobileThree, setMobileThree] = useState<string>('');
  const [mobileFour, setMobileFour] = useState<string>('');
  const [verificationRequestCode, setVerificationRequestCode] = useState<string>();
  const [termsOfUseVersions, setTermsOfUseVersions] = useState<TermsOfUseVersionVOModel[]>([]);
  const [checkedTOUs, setCheckedTOUs] = useState<number[]>([]);

  // error state
  const [mobileInvalid, setMobileInvalid] = useState<boolean>(false);
  const [taxCodeInvalid, setTaxCodeInvalid] = useState<boolean>(false);
  const [otpCodeInvalid, setOtpCodeInvalid] = useState<boolean>(false);

  // Request UseForm
  const { register, getValues, setError, clearErrors, errors } = useForm<CertifyDealerAuthorizerRequest>({});

  const emailType = clientAuthSetting?.otpType === OTP_TYPE.EMAIL;
  const aotpType = clientAuthSetting?.otpType === OTP_TYPE.AOTP;

  const mobileCode = mobileOne + mobileTwo + mobileThree + mobileFour;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const getMobileCodeClassName = (nthMobile: string) => {
    return `information-form__input spread-xs-input me-1 ${
      errors.mobileCode || (mobileInvalid && !nthMobile) ? 'error-input-border' : ''
    }`;
  };

  const onChangeMobileCode = (e: any, setMobile: React.Dispatch<React.SetStateAction<string>>) => {
    const value = e.target.value.replace(/[^\d]+/g, '');
    setMobile(value);
    if (errors.mobileCode) clearErrors('mobileCode');
  };

  const userVerificationRender = (otpType: OTP_TYPE) => {
    switch (otpType) {
      case OTP_TYPE.EMAIL:
      case OTP_TYPE.SMS:
        return emailSmsTypeForm();
      case OTP_TYPE.OTP:
      case OTP_TYPE.AOTP:
        return otpTypeForm();
      default:
        return null;
    }
  };

  const emailSmsTypeForm = () => {
    const renderMobileForm = () => {
      return (
        <div className="row">
          <div className="col-12">
            <label className="information-form__label">{t('text:Mobile')}</label>
            <div className="d-flex">
              <input
                className="information-form__input bg-sub200 me-2"
                type="text"
                defaultValue={clientAuthSetting?.mobile}
                disabled
              />
              <Button bold onClick={sendVerificationCode}>
                {t('text:Get_OTP_Verification_Code')}
              </Button>
            </div>
          </div>
        </div>
      );
    };

    const renderEmailForm = () => {
      return (
        <>
          <div className="row" data-testid="mobileCodeSectionWhenEmail">
            <div className="col-12">
              <label className="information-form__label star">{t('text:Mobile')}</label>
              <div className="d-flex">
                <input
                  className="information-form__input bg-sub200 me-2"
                  type="number"
                  defaultValue={clientAuthSetting?.mobile}
                  disabled
                />
                <input
                  className={getMobileCodeClassName(mobileOne)}
                  type="text"
                  maxLength={1}
                  onChange={e => onChangeMobileCode(e, setMobileOne)}
                  autoComplete="off"
                  value={mobileOne}
                />
                <input
                  className={getMobileCodeClassName(mobileTwo)}
                  type="text"
                  maxLength={1}
                  onChange={e => onChangeMobileCode(e, setMobileTwo)}
                  autoComplete="off"
                  value={mobileTwo}
                />
                <input
                  className={getMobileCodeClassName(mobileThree)}
                  type="text"
                  maxLength={1}
                  onChange={e => onChangeMobileCode(e, setMobileThree)}
                  autoComplete="off"
                  value={mobileThree}
                />
                <input
                  className={getMobileCodeClassName(mobileFour)}
                  type="text"
                  maxLength={1}
                  onChange={e => onChangeMobileCode(e, setMobileFour)}
                  autoComplete="off"
                  value={mobileFour}
                />
              </div>
              {(errors.mobileCode || (mobileInvalid && mobileCode.length !== 4)) && (
                <div className="text-brick-red m-1">
                  {t('text:The_mobile_number_is_invalid')} {t('text:Please_try_again')}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label className="information-form__label">{t('text:Email')}</label>
              <div className="d-flex">
                <input
                  className="information-form__input bg-sub200 me-2"
                  type="text"
                  defaultValue={clientAuthSetting?.email}
                  disabled
                />
                <Button bold onClick={sendVerificationCode}>
                  {t('text:Get_OTP_Verification_Code')}
                </Button>
              </div>
            </div>
          </div>
        </>
      );
    };

    return emailType ? renderEmailForm() : renderMobileForm();
  };

  const otpTypeForm = () => {
    return (
      <div className="row">
        <div className="col-12">
          <label className="information-form__label">{t('text:Bank_Name')}</label>
          <div className="d-flex">
            <input
              className="information-form__input bg-sub200"
              type="text"
              defaultValue={clientAuthSetting?.financierName}
              disabled
            />
            {aotpType && (
              <Button bold onClick={sendVerificationCode}>
                {t('text:Get_OTP_Verification_Code')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  //Methods
  const submitCertify = async () => {
    // reset error
    setMobileInvalid(false);
    setTaxCodeInvalid(false);
    setOtpCodeInvalid(false);

    const data = getValues();

    if (!mobileCode || mobileCode.length !== 4) setMobileInvalid(true);

    if (!data.clientTaxCode) setTaxCodeInvalid(true);

    if (!data.otpCode) setOtpCodeInvalid(true);

    try {
      if (aotpType) {
        data.queryValue = verificationRequestCode;
      }
      data.mobileCode = mobileCode;
      data.code = emailCode;
      data.userTouDTO = {
        // 해당 페이지에서는 회원가입 시 TOU 무조건 한 개만 내려옴
        touVersionId: termsOfUseVersions[0].termsOfUseVersionId,
        financierId: termsOfUseVersions[0].relatedFinancierId,
      };

      await requestCertifyDealerAuthorizer(data);
      history.push(ROUTES_CM.SIGN_UP_COMPLETE, {
        userId: data.loginId,
        userName: dealerAuthorizer?.authorizerName,
        userAuthority: AUTHORITY_TYPE.AUTHORIZER,
      });
    } catch (e) {
      modal.show(e);

      if (e instanceof HttpError) {
        if (e.code === InvalidInputValueExceptionCode.MISMATCHED_TAX_CODE) setError('clientTaxCode', {});
        else if (e.code === InvalidInputValueExceptionCode.MISMATCHED_MOBILE_CODE) setError('mobileCode', {});
        else formErrorHandler<CertifyDealerAuthorizerRequest>(e, setError, clearErrors);
      }
    }
  };

  const fetchAll = async () => {
    try {
      const [fetchedDealerAuthorizer, fetchedClientAuthSetting, fetchedTermsOfUseVersions] = await Promise.all([
        requestInvitedDealerAuthorizer(emailCode),
        requestClientAuth(emailCode),
        requestTermsOfUseVersions(emailCode),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setClientAuthSetting(fetchedClientAuthSetting);
        setDealerAuthorizer(fetchedDealerAuthorizer);
        setTermsOfUseVersions(fetchedTermsOfUseVersions);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const sendVerificationCode = async (e: any) => {
    e.preventDefault();
    try {
      setSendCode(false);
      const response = await requestOtpSend(emailCode);
      setVerificationRequestCode(response);

      if (aotpType) {
        const verificationRequestCode: string = response;

        modal.show(
          <div className="verification-modal-form">
            <div className="verification-request-code-form">
              <p>{t('text:Verification_Request_Code')}</p>
              <div className="code-num">{verificationRequestCode}</div>
            </div>
            <h6>
              {t('text:Please_type_the_request_code_above_into_your_A-OTP_device')}
              <br />
              {t('text:A_Verification_code_will_be_issued_from_the_device')}
              <br />
              {t('text:Enter_the_verification_code_into_the_requested_field_on_the_previous_web_page')}
            </h6>
          </div>,
        );
      } else {
        modal.show(
          <h6>
            {t('text:The_OTP_verification_code_has_been_sent')}
            <br />
            {t('text:Please_check_the_verification_code_and_enter_it_below')}
          </h6>,
        );
      }
      setSendCode(true);
    } catch (error) {
      modal.show(error);
    }
  };

  const handleTOUCheckBox = (e: any, termsOfUseVersionId: number) => {
    if (e.target.checked) {
      setCheckedTOUs([...checkedTOUs, termsOfUseVersionId]);
    } else {
      setCheckedTOUs(checkedTOUs.filter(item => item !== termsOfUseVersionId));
    }
  };

  return (
    <>
      <PublicHeaderBar />
      <div className="common-content pb-5" data-testid="signUpContent">
        <h1 className="signup-title">{t('text:Sign_up')}</h1>
        <GuideMessage
          message={[
            t('text:User_assigned_an_agreement_may_sign_up_on_this_page'),
            t('text:Please_proceed_with_the_verification_process_in_order_to_sign_up'),
            t('text:Please_contact_the_responsible_financier_branch_if_the_information_below_is_incorrect'),
          ]}
          isImportContentArea
        />
        <div className="account-form mt-3">
          <div className="account-balance w-100 justify-content-around">
            <div className="account-balance-label">{t('text:Invitation_Email_Sent_by')}</div>
            <div className="account-balance-value pointfont">{clientAuthSetting?.financierName}</div>
          </div>
        </div>
        <div className="signup-radio-group">
          <RadioButton
            id="new-member"
            name="member-signup"
            checked={false}
            onChange={() => history.push({ pathname: ROUTES_CM.DE_AUTHORIZER_SIGN_UP, search: `?code=${emailCode}` })}
          >
            <p className="radio-title">{t('text:New_Member')}</p>
            <p className="radio-sub-title">{t('text:Create_an_Account')}</p>
          </RadioButton>
          <RadioButton id="existing-member" name="member-signup" checked={true} readOnly>
            <p className="radio-title">{t('text:Existing_Member')}</p>
            <p className="radio-sub-title">{t('text:Verify_Account')}</p>
          </RadioButton>
        </div>
        <SectionTitle title={t('text:User_Verification')} className="mt-5" />
        <FormBorder editable>
          <FormContents>
            <form>
              <div className="d-flex justify-content-center">
                <div className="w-50">
                  <div className="row">
                    <FormInput
                      col={12}
                      label={t('text:ID')}
                      requiredOptions={{ required: true }}
                      name="loginId"
                      ref={register}
                      error={errors.loginId}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="information-form__label star">{t('text:Password')}</label>
                      <input
                        className={`information-form__input ${errors.password ? 'error-input-border' : ''}`}
                        type="password"
                        name="password"
                        placeholder={t('text:Please_type_here')}
                        ref={register}
                        autoComplete="new-password"
                      />
                      <FormErrorMessage error={errors.password} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label id="clientTaxCode" className="information-form__label star">
                        {t('text:Tax_Code')}
                      </label>
                      <input
                        aria-labelledby="clientTaxCode"
                        className={`information-form__input ${
                          errors.clientTaxCode || taxCodeInvalid ? 'error-input-border' : ''
                        }`}
                        type="text"
                        name="clientTaxCode"
                        placeholder={t('text:Please_type_here')}
                        ref={register}
                        autoComplete="off"
                        onChange={() => {
                          setTaxCodeInvalid(false);
                        }}
                      />
                      {(errors.clientTaxCode || taxCodeInvalid) && (
                        <div className="text-brick-red mt-1">
                          {t('text:The_tax_code_is_invalid')}. {t('text:Please_try_again')}
                        </div>
                      )}
                    </div>
                  </div>
                  {userVerificationRender(clientAuthSetting?.otpType!)}
                  {clientAuthSetting?.otpType !== OTP_TYPE.NONE && (
                    <div className="row">
                      <div className="col-12">
                        <label id="otpCode" className="information-form__label star">
                          {t('text:OTP_Verification_Code')}
                        </label>
                        <div className="position-parent">
                          <input
                            aria-labelledby="otpCode"
                            className={`information-form__input ${
                              errors.otpCode || otpCodeInvalid ? 'error-input-border' : ''
                            }`}
                            type="text"
                            name="otpCode"
                            ref={register}
                            autoComplete="off"
                            onChange={() => {
                              setOtpCodeInvalid(false);
                            }}
                          />
                          <Timer
                            timeValue={5}
                            isRun={isSendCode}
                            onFinish={() => {
                              setSendCode(false);
                            }}
                          />
                        </div>
                        {(errors.otpCode || otpCodeInvalid) && (
                          <div className="text-brick-red mt-1">
                            {t('text:The_verification_code_is_invalid')} {t('text:Please_try_again')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </FormContents>
        </FormBorder>
        {termsOfUseVersions?.map(termsOfUseVersion => (
          <div key={termsOfUseVersion.termsOfUseVersionId} className="TOU-check-wrap">
            <input
              id={String(termsOfUseVersion.termsOfUseVersionId)}
              className="form-check-input"
              type="checkbox"
              checked={checkedTOUs.includes(termsOfUseVersion.termsOfUseVersionId)}
              onChange={e => handleTOUCheckBox(e, termsOfUseVersion.termsOfUseVersionId)}
            />
            <Trans
              i18nKey={
                termsOfUseVersion.enterpriseType === ENTERPRISE_TYPE.SY
                  ? 'text:I_have_read_and_agree_to_the_Fin2B_Supply_Chain_Financing_Platform_s_Terms_and_Conditions_of_Use'
                  : 'text:I_have_read_and_agree_to_financierName_s_Terms_and_Conditions_of_Use'
              }
              components={{
                1: <label className="me-1" htmlFor={String(termsOfUseVersion.termsOfUseVersionId)} />,
                2: (
                  <a
                    className="link-text-color"
                    href={`${process.env.REACT_APP_BASE_URL}${API_AN.TERMS_OF_USE_VERSIONS.DOWNLOAD(
                      termsOfUseVersion.termsOfUseVersionId,
                    )}?type=INLINE`}
                    target={`tou-${termsOfUseVersion.termsOfUseVersionId}`}
                  />
                ),
              }}
              values={
                termsOfUseVersion.enterpriseType === ENTERPRISE_TYPE.SY
                  ? undefined
                  : { financierName: termsOfUseVersion.relatedFinancierName }
              }
            />
          </div>
        ))}
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={submitCertify}
            disabled={checkedTOUs.length !== termsOfUseVersions?.length}
          >
            {t('text:Confirm')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default DealerAuthorizerCertify;
