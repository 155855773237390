import { useTranslation } from 'react-i18next';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { COLLATERAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { MultipleAnchorLoanRequestVOModel } from 'models/vo/MultipleAnchorLoanRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface DealerBulkFinancingConfirmationListTableProps {
  bulkFinancingConfirmationListData: MultipleAnchorLoanRequestVOModel[] | undefined;
  pageable: PageableType;
  paginate: (pageNumber: number, sizePerPage: number) => Promise<void>;
}

function AnchorBulkFinancingConfirmationListTable({
  bulkFinancingConfirmationListData,
  pageable,
  paginate,
}: DealerBulkFinancingConfirmationListTableProps) {
  const { t } = useTranslation(['format']);

  const modal = useModal();

  const onClickFinancingApprovalStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(MULTIPLE_LOAN_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="MULTIPLE_ANCHOR_LOAN_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const BULK_CONFIRMATION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Applied_Date'),
    },
    {
      headerText: t('text:Requested_Disbursement_Date'),
    },
    {
      headerText: t('text:Requested_Repayment_Date'),
    },
    {
      headerText: t('text:Total_Requested_Financing_Amount'),
    },
    {
      headerText: t('text:Total_Number_of_Requested_AP/Invoice'),
    },
    {
      headerText: t('text:Financing_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickFinancingApprovalStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Select_Financing')} />
      <TableBorder>
        <TableHeader header={BULK_CONFIRMATION_TABLE_HEADERS} />
        <TableBody numOfCol={BULK_CONFIRMATION_TABLE_HEADERS.length}>
          {bulkFinancingConfirmationListData?.map((item, index: number) => (
            <Tr key={index}>
              <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
              <Td data={item.financierName} />
              <Td data={item.currencyType} />
              <Td data={item.requestedDateTime} format="date" />
              <Td data={item.desiredDisburseDate} format="date" />
              <Td data={item.originalRepaymentDate} format="date" />
              <Td data={item.totalRequestedAmount} format="number" />
              <Td data={item.totalCollateralCount} format="number" />
              <Td
                data={t(`code:multiple-loan-request-status.${item.multipleLoanRequestStatus}`)}
                className={getStatusTextClass('MULTIPLE_LOAN_REQUEST_STATUS', item.multipleLoanRequestStatus)}
                format="code"
              />
              <TdLink
                path={ROUTES_AC.MANAGE_ANCHOR_FINANCING.BULK_FINANCING_DETAIL_BUILD_PATH(
                  item.multipleAnchorLoanRequestId,
                )}
              />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default AnchorBulkFinancingConfirmationListTable;
