import type { NumericType } from 'components/stateless/CommonForm/NumericFormatInput';
import type { CURRENCY_TYPE } from 'enums';
import { isDecimalCurrencyType } from 'utils/logic';
/*
 * 정규식 테스트 사이트
 * https://regexr.com/
 * */
export const REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING = /^[A-Za-z0-9]*$/; // 문자, 대문자, 소문자, empty string 허용
export const REG_ALL_CHARACTER_AND_NUMBER = /^[A-Za-z0-9]+$/;
export const REG_LOWERCASE_AND_NUMBER = /^[a-z0-9+]*$/;
export const REG_POSITIVE_NUMBER = /^[0-9]*$/;
export const REG_PHONE = /^\+?[0-9]+$/;
export const REG_EMAIL =
  /^([A-Za-z0-9]+([-_.]?[A-Za-z0-9])*)@([A-Za-z0-9]+([-]?[A-Za-z0-9])*)(\.([A-Za-z0-9]+([-]?[A-Za-z0-9])*))?(\.([A-Za-z0-9]([-]?[A-Za-z0-9])*))?((\.[A-Za-z]{2,63})$)/;
export const REG_NUMBER = /^[-]?(\d+)(\.?\d*)?$/; // 양수, 음수, 소수점 숫자만 허용
export const REG_EXPONENTIAL_NOTATION = /^[+-]?\d+(\.\d+)?e[+-]?\d+$/; // 지수 표기

export const getRegNumberPattern = (numberType: NumericType = 'bigNumber', currencyType?: CURRENCY_TYPE | null) => {
  const maxIntegerDigitsLength = numberType === 'integer' ? 9 : numberType === 'bigNumber' ? 19 : 11; // 최대 정수자리 길이
  const maxDecimalPointLength = currencyType && isDecimalCurrencyType(currencyType) ? 2 : 4; // 최대 소수점이하자리 길이

  const REG_COMMON_INTEGER_MAX_LENGTH = `^(\\-|\\+)?(\\d{0,${maxIntegerDigitsLength}})`;

  // all - 정수자리만 체크 (value is too large)
  const REG_MAX_INTEGER_LENGTH_PATTERN = new RegExp('' + REG_COMMON_INTEGER_MAX_LENGTH + '(\\.\\d*)?$');
  // <maxIntegerDigitsLength 자리> ex) integer
  const REG_INTEGER_PATTERN = new RegExp('' + REG_COMMON_INTEGER_MAX_LENGTH + '?$');
  // <maxIntegerDigitsLength 자리>.<maxDecimalPointLength 자리> ex) bigNumber, float, currencyType == USD
  const REG_DECIMAL_PATTERN = new RegExp(
    '' + REG_COMMON_INTEGER_MAX_LENGTH + '(\\.\\d{0,' + maxDecimalPointLength + '})?$',
  );

  // 패턴이 틀리더라도 일치하는 부분만 있으면 변환하는 regexp
  const REG_CONVERT_TO_INTEGER_PATTERN = new RegExp(REG_COMMON_INTEGER_MAX_LENGTH); // 123.5 -> 123
  const REG_CONVERT_TO_DECIMAL_PATTERN = new RegExp(
    '' + REG_COMMON_INTEGER_MAX_LENGTH + '(\\.\\d{0,' + maxDecimalPointLength + '})?',
  ); // 123.45678 -> 123.4567

  // 정수자리 or 정수자리와 소수점이하 자리 길이가 최대일 때
  const REG_ONLY_MAX_LENGTH_PATTERN = new RegExp(
    '^(\\-|\\+)?((\\d{' +
      maxIntegerDigitsLength +
      '}$)|(\\d{' +
      maxIntegerDigitsLength +
      '}\\.\\d{' +
      maxDecimalPointLength +
      '}$))$',
  );

  return {
    REG_MAX_INTEGER_LENGTH_PATTERN,
    REG_INTEGER_PATTERN,
    REG_DECIMAL_PATTERN,
    REG_CONVERT_TO_INTEGER_PATTERN,
    REG_CONVERT_TO_DECIMAL_PATTERN,
    REG_ONLY_MAX_LENGTH_PATTERN,
  };
};
