import type { BigNumber } from 'utils/bigNumber/index';
import { sliceZeroDecimal } from 'utils/formatter';

import { formattingToAnchorUserVOModel } from './AnchorUserVO';

import type { AnchorUserVO, AnchorUserVOModel } from './AnchorUserVO';
import type { WaitingAnchorAgreementVO } from './WaitingAnchorAgreementVO';

export interface WaitingAnchorAgreementDetailVO extends WaitingAnchorAgreementVO {
  loanLimitCheck: boolean;
  collateralIssuedLimitCheck: boolean;
  collateralIssuedLimitAmount: string;
  division: string;
  divisionName: string;
  anchorUserList: AnchorUserVO[];
}

export interface WaitingAnchorAgreementDetailVOModel extends WaitingAnchorAgreementDetailVO {
  loanLimitAmount: BigNumber;
  collateralIssuedLimitAmount: BigNumber;
  anchorUserList: AnchorUserVOModel[];
}

export function formattingToWaitingAnchorAgreementDetailVOModel(
  data: WaitingAnchorAgreementDetailVO,
): WaitingAnchorAgreementDetailVOModel {
  const slicedZeroDecimalData = {
    loanLimitAmount: sliceZeroDecimal(data.loanLimitAmount),
    collateralIssuedLimitAmount: sliceZeroDecimal(data.collateralIssuedLimitAmount),
  };
  const { loanLimitAmount, collateralIssuedLimitAmount } = slicedZeroDecimalData;

  const { anchorUserList } = data;

  return {
    ...data,
    loanLimitAmount: sliceZeroDecimal(loanLimitAmount),
    collateralIssuedLimitAmount: sliceZeroDecimal(collateralIssuedLimitAmount),
    anchorUserList: anchorUserList
      ? anchorUserList.map(anchorUser => formattingToAnchorUserVOModel(anchorUser))
      : anchorUserList,
  };
}
