import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { intersectionBy } from 'lodash-es';

import AccordionTr from 'components/stateless/Accordion/AccordionTr';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import {
  AUTHORITY_TYPE,
  ENTERPRISE_TYPE,
  EXECUTION_STATUS,
  OTP_TYPE,
  WAITING_INVOICE_APPROVAL_PHASE_STATUS,
} from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import type { WaitingInvoiceVOModel } from 'models/vo/WaitingInvoiceVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestAnchorInvoiceSummaryLastPhase,
  requestAnchorInvoiceSummaryList,
  requestApproveInvoiceBulk,
} from 'utils/http/api/anchor/invoice-summaries';
import type { AnchorInvoiceSummariesListRequest } from 'utils/http/api/anchor/invoice-summaries/requests';
import { requestRelatedAnchorUsers } from 'utils/http/api/anchor/users';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

interface CheckBoxInvoiceSummaryModel extends InvoiceSummaryVOModel {
  disabled?: boolean;
}

const AC_INVOICE_CONFIRMATION_LIST_QS_KEY = 'ac-invoice-confirmation-list';

function AnchorInvoiceConfirmationList() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const signInInfo = getSignIn();
  const { pageable, setPageable } = usePageable(AC_INVOICE_CONFIRMATION_LIST_QS_KEY);
  const [invoiceSummariesPage, setInvoiceSummariesPage] = useState<Pageable<CheckBoxInvoiceSummaryModel[]>>();
  const [anchorUsers, setAnchorUsers] = useState<AnchorUserVOModel[]>([]);
  const [checkedInvoices, setCheckedInvoices] = useState<CheckBoxInvoiceSummaryModel[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [accordionContent, setAccordionContent] = useState<Pageable<WaitingInvoiceVOModel[][]>>(
    {} as Pageable<WaitingInvoiceVOModel[][]>,
  );
  const getProperty = useProperty<AnchorInvoiceSummariesListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<AnchorInvoiceSummariesListRequest>(
        setValue,
        getParsedSearchParams(AC_INVOICE_CONFIRMATION_LIST_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    setCheckedInvoices([]);
  }, [pageable.currentPage]);

  useEffect(() => {
    // 최초 선택된 송장 기준으로 checkbox의 렌더링 및 disabled 상태 변화
    if (invoiceSummariesPage?.content === undefined) return;

    const [firstSelectedCheckBoxItem] = checkedInvoices;

    // 최초 선택된 송장이 없을 시 disabled reset
    if (firstSelectedCheckBoxItem === undefined) {
      const resetDisabled = invoiceSummariesPage.content.map(item => ({
        ...item,
        disabled: false,
      }));
      setInvoiceSummariesPage({ ...invoiceSummariesPage, content: resetDisabled });

      return;
    }

    // 최초 선택된 송장과 같은 금융사인지 확인하여 disabled 상태 부여
    const setDisabled = invoiceSummariesPage.content.map((item: CheckBoxInvoiceSummaryModel) => {
      if (item.financierEnterpriseId !== firstSelectedCheckBoxItem.financierEnterpriseId) {
        return { ...item, disabled: true };
      } else {
        return { ...item, disabled: false };
      }
    });

    setInvoiceSummariesPage({ ...invoiceSummariesPage, content: setDisabled });
  }, [checkedInvoices[0], pageable.currentPage, pageable.sizePerPage]);

  useEffect(() => {
    const updatedCheckedList = intersectionBy(availAbleCheckedList, checkedInvoices, 'invoiceSummaryId');
    setCheckedInvoices(updatedCheckedList);
  }, [pageable.sizePerPage]);

  useEffect(() => {
    if (availAbleCheckedList?.length === 0 || availAbleCheckedList?.length !== checkedInvoices.length) {
      setIsAllChecked(false);
    } else {
      setIsAllChecked(true);
    }
  }, [checkedInvoices]);

  async function fetchAll() {
    const searchData = getValues();
    try {
      const [anchorUserResponse, invoiceSummariesPage] = await Promise.all([
        requestRelatedAnchorUsers(),
        requestAnchorInvoiceSummaryList(pageable.currentPage, pageable.sizePerPage, searchData),
      ]);

      updateSearchParams(
        {
          ...searchData,
          pageNumber: pageable.currentPage,
          rowCount: pageable.sizePerPage,
        },
        AC_INVOICE_CONFIRMATION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorUsers(anchorUserResponse);
        setInvoiceSummariesPage({
          ...invoiceSummariesPage,
          content: invoiceSummariesPage.content,
        });
        setPageable(invoiceSummariesPage);
        setAccordionContent({
          ...invoiceSummariesPage,
          content: Array(invoiceSummariesPage.numberOfElements).fill(undefined),
        });
      });
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchInvoiceSummaryList(
    pageNumber: number = 1,
    sizePerPage: number = 10,
    searchData: AnchorInvoiceSummariesListRequest,
  ) {
    // 페이지 이동, 검색할 시 아코디언 메뉴 닫기 위한 코드
    try {
      const invoiceSummariesPage = await requestAnchorInvoiceSummaryList(pageNumber, sizePerPage, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount: sizePerPage,
        },
        AC_INVOICE_CONFIRMATION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setInvoiceSummariesPage({
          ...invoiceSummariesPage,
          content: invoiceSummariesPage.content,
        });
        setPageable(invoiceSummariesPage);
        setAccordionContent({
          ...invoiceSummariesPage,
          content: Array(invoiceSummariesPage.numberOfElements).fill(undefined),
        });
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async () => {
    setCheckedInvoices([]);
    setIsAllChecked(false);

    showLoadingUI();
    await fetchInvoiceSummaryList(1, pageable.sizePerPage, getValues());
    unShowLoadingUI();
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchInvoiceSummaryList(
      page,
      sizePerPage,
      getParsedSearchParams(AC_INVOICE_CONFIRMATION_LIST_QS_KEY).formSearchData,
    );
  };

  const setProgressStatusClassName = (code: WAITING_INVOICE_APPROVAL_PHASE_STATUS, isInProcessing?: boolean) => {
    if (isInProcessing) return getStatusTextClass('WAITING_INVOICE_APPROVAL_PHASE_STATUS', 'IN_PROGRESS');

    return getStatusTextClass('WAITING_INVOICE_APPROVAL_PHASE_STATUS', code);
  };

  const setConfirmationStatusClassName = (code?: EXECUTION_STATUS): string => {
    return getStatusTextClass('EXECUTION_STATUS', code);
  };

  const { register, reset, getValues, setValue, control } = useForm<AnchorInvoiceSummariesListRequest>({
    mode: 'onSubmit',
  });

  const changeWaitingInvoiceSelectBox = () => {
    reset({
      ...getValues(),
      currentConfirmDateFrom: '',
      currentConfirmDateTo: '',
    });
  };

  const onClickInvoiceConfirmationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(WAITING_INVOICE_APPROVAL_PHASE_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="WAITING_INVOICE_APPROVAL_PHASE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickInvoiceConfirmationStatusWithInProcessing = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(WAITING_INVOICE_APPROVAL_PHASE_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="WAITING_INVOICE_APPROVAL_PHASE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickInvoiceConfirmationProgressStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={[
          EXECUTION_STATUS.NONE,
          EXECUTION_STATUS.REGISTERED,
          EXECUTION_STATUS.RUNNING,
          EXECUTION_STATUS.FAILED,
        ]}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EXECUTION_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const invoiceApproveResultConfirmationModal = () => {
    modal.show(
      <>
        <h6>{t('text:The_invoice_confirmation_is_in_progress_and_may_take_a_few_minutes_to_be_completed')}</h6>
        <h6>{t('text:Check_the_invoice_confirmation_result_in_the_invoice_confirmation_page')}</h6>
      </>,
      {
        closeBtnCb: fetchAll,
      },
    );
  };

  const postApproveInvoiceBulk = async (verificationCode?: UserVerificationCodeRequest) => {
    try {
      await requestApproveInvoiceBulk({
        invoiceSummaryIds: checkedInvoices.map(item => item.invoiceSummaryId),
        financierId: checkedInvoices[0].financierEnterpriseId,
        otpCode: verificationCode?.otpCode,
        queryValue: verificationCode?.queryValue,
      });

      setCheckedInvoices([]);
      invoiceApproveResultConfirmationModal();
    } catch (error) {
      modal.show(error);
    }
  };

  const showOtpModal = async () => {
    try {
      const financierClientAuthSettingInfo = await requestAnchorClientAuthByFinancierId(
        checkedInvoices[0].financierEnterpriseId,
      );

      if (financierClientAuthSettingInfo?.otpType !== OTP_TYPE.NONE) {
        const verificationCode: UserVerificationCodeRequest = {};
        modal.show(
          <UserVerificationModal
            modalId={modal.id}
            verificationCode={verificationCode}
            requestIdType="financierId"
            requestId={checkedInvoices[0].financierEnterpriseId}
            clientAuthSetting={financierClientAuthSettingInfo as FinancierClientAuthSettingVOModel}
          />,
          {
            modalType: ModalType.CONFIRM,
            title: t('text:User_Verification'),
            closeBtnText: t('text:Cancel'),
            confirmBtnCb: () => postApproveInvoiceBulk(verificationCode),
          },
        );
      } else {
        postApproveInvoiceBulk();
      }
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickApproveInvoiceBulk = async () => {
    const invoiceApproveRequestConfirmationModal = () => {
      type Total = { totalNumberOfInvoice: number; totalAmountOfInvoice: BigNumber };
      const calculatorBigNumber = new CalculatorBigNumber();

      const { totalNumberOfInvoice, totalAmountOfInvoice } = checkedInvoices.reduce(
        (acc: Total, cur: CheckBoxInvoiceSummaryModel) => {
          return {
            totalNumberOfInvoice: Number(acc.totalNumberOfInvoice) + cur.currentApprovalCount,
            totalAmountOfInvoice: calculatorBigNumber
              .add(acc.totalAmountOfInvoice)
              .add(cur.currentApprovalAmount)
              .get(),
          };
        },
        { totalNumberOfInvoice: 0, totalAmountOfInvoice: '0' },
      );

      const modalText: JSX.Element = (
        <>
          <h6>{t('text:Would_you_like_to_confirm_the_selected_invoices?')}</h6>
          <h6>{t('text:Check_the_total_number_and_total_amount_of_invoices_below')}</h6>
          <br />
          <ul className="invoice-registration-sum">
            <li>
              • {`${t('text:Total_Number_of_Invoices')}: `}
              <strong>{totalNumberOfInvoice}</strong>
            </li>
            <li>
              • {`${t('text:Total_Amount_of_Invoices')}: `}
              <strong>{t('format:number', { value: totalAmountOfInvoice })}</strong>
            </li>
          </ul>
        </>
      );

      modal.show(modalText, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => {
          const matchedAnchorUser = anchorUsers.filter(
            item => item.financierId === checkedInvoices[0].financierEnterpriseId,
          );
          const anchorUserAuthorityType = matchedAnchorUser[0].authorityType;

          if (
            anchorUserAuthorityType === AUTHORITY_TYPE.ADMIN ||
            anchorUserAuthorityType === AUTHORITY_TYPE.AUTHORIZER
          ) {
            showOtpModal();
          } else {
            postApproveInvoiceBulk();
          }
        },
      });
    };

    invoiceApproveRequestConfirmationModal();
  };

  const isShowCheckBox = (item: CheckBoxInvoiceSummaryModel): boolean => {
    // 송장 일괄 승인 기획 문서 확인
    if (item.rejected) return false;
    if (item.currentInvoiceApprovalPhaseStatus === WAITING_INVOICE_APPROVAL_PHASE_STATUS.CONFIRMED) return false;
    if (item.currentConfirmEnterpriseType === ENTERPRISE_TYPE.FI) return false;

    if (item.currentInvoiceApprovalPhaseStatus === WAITING_INVOICE_APPROVAL_PHASE_STATUS.REGISTERED) {
      const notShowedAnchorUsers = anchorUsers.filter(
        (anchorUser: AnchorUserVOModel) =>
          anchorUser.authorityType === AUTHORITY_TYPE.OPERATOR ||
          anchorUser.authorityType === AUTHORITY_TYPE.HQ_OPERATOR,
      );

      const notShowedCheckBox = notShowedAnchorUsers.find(
        (anchorUser: AnchorUserVOModel) => anchorUser.financierId === item.financierEnterpriseId,
      );

      const authorizerAnchorUser = anchorUsers.filter(
        (anchorUser: AnchorUserVOModel) =>
          anchorUser.authorityType === AUTHORITY_TYPE.AUTHORIZER &&
          anchorUser.financierId === item.financierEnterpriseId,
      );

      if (authorizerAnchorUser) {
        if (signInInfo?.userId === item.currentConfirmUserId) return false;
      }
      if (notShowedCheckBox) return false;
    }

    return true;
  };

  const isDisabledCheckBox = (item: CheckBoxInvoiceSummaryModel): boolean => {
    // 송장 일괄 승인 기획 문서 확인
    if (item.disabled === true) return true;
    if (item.executionStatus === EXECUTION_STATUS.REGISTERED || item.executionStatus === EXECUTION_STATUS.RUNNING) {
      return true;
    } else if (
      item.executionStatus === EXECUTION_STATUS.FINISHED &&
      item.currentInvoiceApprovalPhaseStatus !== WAITING_INVOICE_APPROVAL_PHASE_STATUS.REQUESTED
    ) {
      return true;
    }

    return false;
  };

  const availAbleCheckedList = invoiceSummariesPage?.content.filter(item => {
    return isShowCheckBox(item) && !isDisabledCheckBox(item);
  });

  const onClickAllCheckbox = (e: any): void => {
    if (invoiceSummariesPage?.content === undefined) return;

    if (e.target.checked) {
      let availAbleCheckedList = invoiceSummariesPage.content.filter(item => {
        return isShowCheckBox(item) && !isDisabledCheckBox(item);
      });

      if (checkedInvoices[0] === undefined) {
        const filteredSameFinancier = availAbleCheckedList.filter(
          item => item.financierEnterpriseId === availAbleCheckedList[0].financierEnterpriseId,
        );

        availAbleCheckedList = filteredSameFinancier;
      }

      setCheckedInvoices(prev => {
        const mergePrevAndMappedData = [...prev, ...availAbleCheckedList];
        const removeDuplicateCheckedList = mergePrevAndMappedData.reduce(
          (uniqueArr: CheckBoxInvoiceSummaryModel[], current: CheckBoxInvoiceSummaryModel) => {
            const noFindDuplicateCheckedList =
              uniqueArr.findIndex(({ invoiceSummaryId }) => invoiceSummaryId === current.invoiceSummaryId) === -1;

            if (noFindDuplicateCheckedList) {
              return [...uniqueArr, current];
            }

            return uniqueArr;
          },
          [],
        );

        return removeDuplicateCheckedList;
      });
    } else {
      setCheckedInvoices([]);
    }
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: '',
      className: 'text-center',
      colWidths: 50,
      hasCheckBox: true,
      handleClickAllCheckBox: onClickAllCheckbox,
      checkBoxId: String(pageable.currentPage),
      isAllChecked,
    },
    {
      headerText: t('text:Registration_Initiated_Date'),
      colWidths: 135,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 135,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 135,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 200,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 150,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:BOE_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Number_of_Valid_Invoices'),
      colWidths: 130,
    },
    {
      headerText: t('text:Total_Amount_of_Valid_Invoices'),
      colWidths: 130,
    },
    {
      headerText: t('text:Registration_Rejection'),
      colWidths: 140,
    },
    {
      headerText: t('text:Registration_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickInvoiceConfirmationStatusWithInProcessing,
    },
    {
      headerText: t('text:Confirmation_Progress_Status'),
      colWidths: 135,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickInvoiceConfirmationProgressStatus,
    },
    {
      headerText: t('text:Validated_By'),
      colWidths: 90,
    },
    {
      headerText: t('text:Validation_Date'),
      colWidths: 135,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, item: CheckBoxInvoiceSummaryModel) => {
      if (e.target.checked) {
        setCheckedInvoices([...checkedInvoices, item]);
      } else {
        setCheckedInvoices(
          checkedInvoices.filter(({ invoiceSummaryId }) => invoiceSummaryId !== item.invoiceSummaryId),
        );
      }
    };

    const onClickAccordionTr = async (invoiceSummaryId: number, index: number) => {
      if (accordionContent?.content[index]) return;

      try {
        const accordionDomId = `accordion-invoice-container-${index}`;
        showLoadingUI(accordionDomId);
        const response = await requestAnchorInvoiceSummaryLastPhase(invoiceSummaryId);
        unShowLoadingUI(accordionDomId);

        setAccordionContent(prev => {
          const prevContent = prev ? [...prev.content] : [];
          prevContent[index] = response.invoiceList.content;

          const result: Pageable<WaitingInvoiceVOModel[][]> = {
            ...prev,
            content: prevContent,
          };

          return result;
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const renderAccordionContent = (index: number): JSX.Element => {
      const headers: HeaderType[] = [
        {
          headerText: t('text:Invoice_Number'),
        },
        {
          headerText: t('text:Reference_Number'),
        },
        {
          headerText: t('text:Invoice_Issued_Date'),
        },
        {
          headerText: t('text:Payment_Date'),
        },
        {
          headerText: t('text:Invoice_Amount'),
        },
        {
          headerText: t('text:Invoice_Details'),
        },
      ];

      return (
        <div
          className={`accordion-invoice-container ${
            accordionContent.content[index] && 'accordion-invoice-container-color'
          }`}
        >
          {accordionContent?.content[index] ? (
            <TableBorder scrollYMaxHeight={294}>
              <TableHeader header={headers} />
              <TableBody numOfCol={headers.length}>
                {accordionContent?.content[index]?.map((item, index) => {
                  const additionalDataTextList = item.invoiceAdditionalDataList?.map(
                    ({ name, value }) => `${name}: ${tableValueManage(value)} \u00A0`,
                  );

                  return (
                    <Tr key={`accordion-content-${index}`}>
                      <Td data={item.invoiceNumber} />
                      <Td data={item.referenceNumber} />
                      <Td data={item.invoiceIssuedDate} format="date" />
                      <Td data={item.settlementDate} format="date" />
                      <Td data={item.invoiceAmount} format="number" />
                      <Td className="text-truncate" data={additionalDataTextList} />
                    </Tr>
                  );
                })}
              </TableBody>
            </TableBorder>
          ) : (
            <div id={`accordion-invoice-container-${index}`} />
          )}
        </div>
      );
    };

    return invoiceSummariesPage?.content.map((item, i) => {
      return (
        <AccordionTr key={i} id={`invoice-summary-${i}`} defaultExpanded={!!accordionContent.content[i]}>
          <AccordionTr.Trigger className="clickable-tr" onClick={() => onClickAccordionTr(item.invoiceSummaryId, i)}>
            <Td>
              {isShowCheckBox(item) ? (
                <div className="text-center">
                  <input
                    type="checkbox"
                    name={`checkbox-${item.invoiceSummaryId}`}
                    className="form-check-input m-0"
                    onChange={e => onChangeCheckbox(e, item)}
                    checked={checkedInvoices
                      .map(({ invoiceSummaryId }) => invoiceSummaryId)
                      .includes(item.invoiceSummaryId)}
                    disabled={isDisabledCheckBox(item)}
                    onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </Td>
            <Td data={item.createDateTime} format="datetime" />
            <Td data={item.financierName} />
            <Td data={item.anchorContractNo} />
            <Td data={item.dealerClientName} />
            <Td data={item.dealerContractNo} />
            <Td data={item.currencyType} className="text-center" />
            <Td data={item.billOfExchangeNo} />
            <Td data={item.rejected ? '-' : item.currentApprovalCount} format="number" />
            <Td data={item.rejected ? '-' : item.currentApprovalAmount} format="number" />
            <Td className="text-center">
              <>
                {item.rejected ? 'Y' : 'N'}
                <br />
                {item.rejected && `(${item.rejectUsername})`}
              </>
            </Td>
            <Td
              className={
                item.rejected ? '' : setProgressStatusClassName(item.currentInvoiceApprovalPhaseStatus, item.inProgress)
              }
              data={
                item.rejected
                  ? '-'
                  : item.inProgress
                  ? t('text:In_Progress')
                  : t(`code:current-invoice-approval-phase-status.${item.currentInvoiceApprovalPhaseStatus}`)
              }
            />
            <Td
              className={setConfirmationStatusClassName(item.executionStatus)}
              data={t(`code:execution-status.${item.executionStatus}`)}
            />
            <Td data={t(`code:enterprise-type.${item.currentConfirmEnterpriseType}`)} />
            <Td data={item.currentInvoiceApprovalDateTime} format="datetime" />
            <TdLink
              path={ROUTES_AC.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(
                item.invoiceSummaryId,
                item.financierEnterpriseId,
              )}
              state={{ dealerAgreementId: item.dealerAgreementId }}
            />
          </AccordionTr.Trigger>
          <AccordionTr.Content>{renderAccordionContent(i)}</AccordionTr.Content>
        </AccordionTr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Invoice_Confirmation')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Initiated_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name={getProperty('createdDateFrom')} control={control} />
              <SearchDatePicker placeholder={t('text:to')} name={getProperty('createdDateTo')} control={control} />
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name={getProperty('financierName')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name={getProperty('anchorAgreementContractNo')} ref={register} />
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name={getProperty('dealerClientName')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
              <SearchInput name={getProperty('dealerContractNo')} ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name={getProperty('currencyType')}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:BOE_Number')} />
              <SearchInput name={getProperty('billOfExchangeNo')} ref={register} />
              <SearchLabel label={t('text:Number_of_Valid_Invoices')} />
              <SearchInput
                name={getProperty('minimumCurrentApprovalCount')}
                ref={register}
                col={2}
                placeholder={t('text:from')}
                type="number"
              />
              <SearchInput
                name={getProperty('maximumCurrentApprovalCount')}
                ref={register}
                col={2}
                placeholder={t('text:to')}
                type="number"
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Total_Amount_of_Valid_Invoices')} />
              <SearchInput
                name={getProperty('minimumCurrentApprovalAmount')}
                ref={register}
                col={2}
                placeholder={t('text:from')}
                type="number"
              />
              <SearchInput
                name={getProperty('maximumCurrentApprovalAmount')}
                ref={register}
                col={2}
                placeholder={t('text:to')}
                type="number"
              />
              <SearchLabel label={t('text:Registration_Rejection')} />
              <SearchSelect
                selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                name={getProperty('rejected')}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel
                label={t('text:Registration_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickInvoiceConfirmationStatus}
              />
              <SearchSelect
                name={getProperty('currentInvoiceApprovalPhaseStatus')}
                selectOptions={getSelectOptions<WAITING_INVOICE_APPROVAL_PHASE_STATUS>(
                  'WAITING_INVOICE_APPROVAL_PHASE_STATUS',
                  'ALL',
                  true,
                ).filter(item => item.value !== WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED)}
                ref={register}
                onChange={() => changeWaitingInvoiceSelectBox()}
              />
              <SearchLabel
                label={t('text:Confirmation_Progress_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickInvoiceConfirmationProgressStatus}
              />
              <SearchSelect
                name={getProperty('executionStatus')}
                selectOptions={getSelectOptions<EXECUTION_STATUS>('EXECUTION_STATUS', 'ALL', true)}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Validated_By')} />
              <SearchSelect
                selectOptions={getSelectOptions<ENTERPRISE_TYPE>('ENTERPRISE_TYPE', 'ALL', true)}
                name={getProperty('currentConfirmEnterpriseType')}
                ref={register}
              />
              <SearchLabel label={t('text:Validation_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getProperty('currentConfirmDateFrom')}
                control={control}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getProperty('currentConfirmDateTo')}
                control={control}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />

      <div className="content-area">
        <div className="d-flex">
          <h2>{t('text:Results')}</h2>
        </div>
        <p className="total-data">
          {t('text:Total')}: {invoiceSummariesPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
        <div className="flex-end mt-4">
          <Button size={ButtonSizeEnum.LG} onClick={onClickApproveInvoiceBulk} disabled={checkedInvoices.length === 0}>
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AnchorInvoiceConfirmationList;
