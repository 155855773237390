import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderBarContainerPropsType {
  children?: ReactNode;
}

const HeaderBarContainer = ({ children }: HeaderBarContainerPropsType) => {
  return <div className="header-bar">{children}</div>;
};

const HeaderBarTitle = () => {
  const { t } = useTranslation();

  return <h2 className="header-bar__title">{t('text:Supply_Chain_Financing_Platform')}</h2>;
};

const HeaderBarList = ({ children }: HeaderBarContainerPropsType) => {
  return <ul className="header-bar__list">{children}</ul>;
};

const HeaderBarListItem = ({ children }: HeaderBarContainerPropsType) => {
  return <li className="header-bar__list-item">{children}</li>;
};
export { HeaderBarContainer, HeaderBarTitle, HeaderBarList, HeaderBarListItem };
