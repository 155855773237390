import type { CURRENCY_TYPE } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface DeletedArVO {
  deletedArId: number;
  createdDateTime: string;
  anchorName: string;
  anchorTaxCode: string;
  anchorPartnerName: string;
  anchorPartnerTaxCode: string;
  arNumber: string;
  arAmount: BigNumber;
  arIssuedDate: string;
  settlementDate: string;
  maturityDate: string;
  currencyType: CURRENCY_TYPE;
  financierId: number;
  financierName: string;
}

export interface DeletedArVOModel extends DeletedArVO {}

export function formattingToDeletedArVOModel(data: DeletedArVO): DeletedArVOModel {
  return {
    ...data,
  };
}
