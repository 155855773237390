import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import { EXTENSION_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemExtensionList } from 'utils/http/api/system/extension-requests';
import type { SystemExtensionListRequest } from 'utils/http/api/system/extension-requests/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const onClickExtensionRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EXTENSION_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EXTENSION_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const SEARCHED_SYSTEM_EXTENSION_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 100,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Original_Repayment_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickExtensionRequestStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_EXTENSION_LIST_QS_KEY = 'sy-extension-list';

  return {
    mounted,
    modal,
    t,
    onClickExtensionRequestStatus,
    SEARCHED_SYSTEM_EXTENSION_LIST_TABLE_HEADERS,
    SY_EXTENSION_LIST_QS_KEY,
  };
};

function SystemExtensionList() {
  const {
    mounted,
    modal,
    t,
    onClickExtensionRequestStatus,
    SEARCHED_SYSTEM_EXTENSION_LIST_TABLE_HEADERS,
    SY_EXTENSION_LIST_QS_KEY,
  } = getConstants();

  const {
    register: systemExtensionSearchFormRegister,
    getValues: getSystemExtensionSearchFormValues,
    reset: resetSystemExtensionSearchFormValue,
    setValue: setSystemExtensionSearchFormValue,
    control: systemExtensionSearchControl,
  } = useForm<SystemExtensionListRequest>();

  const getSystemExtensionProperty = useProperty<SystemExtensionListRequest>();
  const [systemExtensionPage, setSystemExtensionPage] = useState<Pageable<ExtensionRequestVOModel[]>>();
  const { pageable: systemExtensionPageable, setPageable: setSystemExtensionPageable } =
    usePageable(SY_EXTENSION_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemExtensionListRequest>(
        setSystemExtensionSearchFormValue,
        getParsedSearchParams(SY_EXTENSION_LIST_QS_KEY).formSearchData,
      );
      fetchSyExtensionList(
        systemExtensionPageable.currentPage,
        systemExtensionPageable.sizePerPage,
        getSystemExtensionSearchFormValues(),
      );
    }
  }, [mounted]);

  const fetchSyExtensionList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemExtensionListRequest,
  ): Promise<void> => {
    try {
      const systemExtensionPageData = await requestSystemExtensionList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_EXTENSION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemExtensionPage(systemExtensionPageData);
        setSystemExtensionPageable(systemExtensionPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    resetSystemExtensionSearchFormValue();
  };

  const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    fetchSyExtensionList(1, systemExtensionPageable.sizePerPage, getSystemExtensionSearchFormValues());
  };

  const systemExtensionPaginate = (pageNumber: number, rowCount: number): void => {
    fetchSyExtensionList(pageNumber, rowCount, getParsedSearchParams(SY_EXTENSION_LIST_QS_KEY).formSearchData);
  };

  const renderExtensionRequestList = () => {
    return systemExtensionPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.financierName} />
          <Td data={item.dealerClientName} />
          <Td data={item.dealerClientCode} />
          <Td data={item.financierLoanId} />
          <Td data={item.loanId} />
          <Td data={item.disbursedAmount} format="number" />
          <Td data={item.disbursedDate} format="date" />
          <Td
            data={item.extensionStartDate && dayjs(item.extensionStartDate, 'YYYY-MM-DD').subtract(1, 'days')}
            format="date"
          />
          <Td data={item.extendedRepaymentDate} format="date" />
          <Td
            className={getStatusTextClass('EXTENSION_REQUEST_STATUS', item.extensionRequestStatus)}
            data={t(`code:extension-request-status.${item.extensionRequestStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_SY.MONITOR_FINANCING.EXTENSION_DETAIL_BUILD_PATH(item.extensionRequestId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing_Extension')} />

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Requested_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getSystemExtensionProperty('createdDateFrom')}
                control={systemExtensionSearchControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getSystemExtensionProperty('createdDateTo')}
                control={systemExtensionSearchControl}
              />
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name={getSystemExtensionProperty('financierName')} ref={systemExtensionSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput
                name={getSystemExtensionProperty('dealerClientName')}
                ref={systemExtensionSearchFormRegister}
              />
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput
                name={getSystemExtensionProperty('dealerClientCode')}
                ref={systemExtensionSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financier_Financing_ID')} />
              <SearchInput
                name={getSystemExtensionProperty('financierLoanId')}
                ref={systemExtensionSearchFormRegister}
              />
              <SearchLabel label={t('text:Platform_Financing_ID')} />
              <SearchInput name={getSystemExtensionProperty('loanId')} ref={systemExtensionSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financing_Amount')} />
              <SearchInput
                name={getSystemExtensionProperty('disbursedAmountFrom')}
                ref={systemExtensionSearchFormRegister}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name={getSystemExtensionProperty('disbursedAmountTo')}
                ref={systemExtensionSearchFormRegister}
                col={2}
                placeholder={t('text:to')}
              />
              <SearchLabel label={t('text:Disbursed_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getSystemExtensionProperty('disbursedDateFrom')}
                control={systemExtensionSearchControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getSystemExtensionProperty('disbursedDateTo')}
                control={systemExtensionSearchControl}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Requested_Repayment_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getSystemExtensionProperty('extendedRepaymentDateFrom')}
                control={systemExtensionSearchControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getSystemExtensionProperty('extendedRepaymentDateTo')}
                control={systemExtensionSearchControl}
              />
              <SearchLabel
                label={t('text:Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickExtensionRequestStatus}
              />
              <SearchSelect
                name={getSystemExtensionProperty('extensionRequestStatus')}
                selectOptions={getSelectOptions<EXTENSION_REQUEST_STATUS>('EXTENSION_REQUEST_STATUS', 'ALL', true)}
                ref={systemExtensionSearchFormRegister}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {systemExtensionPageable.totalRows}
        </p>

        <TableBorder>
          <TableHeader header={SEARCHED_SYSTEM_EXTENSION_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_SYSTEM_EXTENSION_LIST_TABLE_HEADERS.length}>
            {renderExtensionRequestList()}
          </TableBody>
        </TableBorder>

        <Pagination pageable={systemExtensionPageable} paginate={systemExtensionPaginate} />
      </div>
    </>
  );
}

export default SystemExtensionList;
