import type { ChangeEvent } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import { FormBorder, FormContents, FormSelect } from 'components/stateless/CommonForm';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import useModal from 'utils/modal/useModal';

interface SupportedCurrencySettingModalProps {
  supportedCurrencyTypeList?: CURRENCY_TYPE[];
  supportedCurrencyType?: CURRENCY_TYPE;
  dataHandler: Function;
  modalId: number;
}

function SupportedCurrencySettingModal({
  supportedCurrencyTypeList,
  dataHandler,
  modalId,
}: SupportedCurrencySettingModalProps): JSX.Element {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const { register, watch, errors } = useForm<{ supportedCurrencyType: CURRENCY_TYPE }>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const { supportedCurrencyType } = watch();

  useEffect(() => {
    modal.disableConfirmBtn(modalId, isEmpty(supportedCurrencyType));
  }, [supportedCurrencyType]);

  const onChangeCurrencyType = (e: ChangeEvent<HTMLSelectElement>) => {
    dataHandler(e.target.value);
  };

  const supportedCurrencyTypeSelectOptions =
    supportedCurrencyTypeList?.length === 0
      ? getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')
      : getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE').filter(
          item => !supportedCurrencyTypeList?.includes(item.value),
        );

  return (
    <div className="modal-container">
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormSelect
              name="supportedCurrencyType"
              col={12}
              label={t('text:Currency')}
              selectOptions={supportedCurrencyTypeSelectOptions}
              ref={register}
              onChange={onChangeCurrencyType}
              required
              placeholderOptions={{ show: true }}
              error={errors.supportedCurrencyType}
            />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default SupportedCurrencySettingModal;
