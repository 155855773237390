import { useTranslation } from 'react-i18next';

import { isNull } from 'lodash-es';

import Form, { SizeType } from 'components/stateless/Form';
import NumberInput from 'components/stateless/Form/NumberInput/NumberInput';
import { RadioGroup } from 'components/stateless/Form/RadioComponents';
import Radio from 'components/stateless/Form/RadioComponents/Radio';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';
import { COLLATERAL_TYPE } from 'enums';

import useFinancingLimitOfPartnersController from './useFinancingLimitOfPartnersController';

const FinancingLimitOfPartners = () => {
  const { t } = useTranslation(['format']);

  const {
    errors,
    register,
    isEditableField,
    ltvState: { isLtvHundred, ltvValue },
    supportedCollateralType,
    currencyType,
    loanLimitCheck,
    handleFinancingLimitCheckPointChange,
    handleIsLtvHundredChange,
    handleEnterLtvChange,
    getReadOnlyValue,
  } = useFinancingLimitOfPartnersController();

  const MaxLtvRatioLabel = (() => {
    switch (supportedCollateralType) {
      case COLLATERAL_TYPE.AR:
        return `${t('text:Financing_to_AR_Ratio')} (%)`;
      case COLLATERAL_TYPE.INVOICE:
        return `${t('text:Financing_to_Invoice_Ratio')} (%)`;
      default:
        return null;
    }
  })();

  const MaxLtvRatioDescription = (() => {
    switch (supportedCollateralType) {
      case COLLATERAL_TYPE.AR:
        return t('text:Set_the_maximum_ratio_of_the_AR_the_partners_can_receive_in_financing');
      case COLLATERAL_TYPE.INVOICE:
        return t('text:Set_the_maximum_ratio_of_the_invoice_the_partners_can_receive_in_financing');
      default:
        return null;
    }
  })();

  return (
    <>
      <SubTitle>{t('text:FINANCING_LIMIT_OF_PARTNERS')}</SubTitle>
      <Content>
        {supportedCollateralType === COLLATERAL_TYPE.AR && (
          <Row>
            <Form.Control required name="loanLimitCheck">
              <Cell>
                <Form.Label position="top" required>
                  {t('text:Financing_Limit_of_Anchor')}
                </Form.Label>
                <Form.Description>
                  {t('text:The_Anchor_s_financing_limit_is_applied_to_all_of_its_partners_when_they_request_financing')}
                </Form.Description>
              </Cell>
              <Form.Radio onChange={handleFinancingLimitCheckPointChange}>
                <Form.Option
                  label={t('text:Applied')}
                  value="true"
                  tooltipOptions={{
                    id: 'loanLimitCheck-true',
                    content: t('text:The_limit_will_be_recovered_when_the_financing_is_repaid'),
                  }}
                  render={(isParentReadOnly, isParentRadioUnChecked) => (
                    <Border>
                      <Content isInnerStyle={true}>
                        <Row>
                          <Form.Control required name="loanLimitAmount">
                            <Form.Label>{t('text:Financing_Limit_Amount')}</Form.Label>
                            <Form.NumberInput
                              currencyType={currencyType}
                              fieldSize={SizeType.MD}
                              leftUnit={currencyType}
                              numberType="bigNumber"
                              textAlign="text-right"
                              readOnly={isParentRadioUnChecked}
                              parentReadOnly={isParentReadOnly}
                              showError={loanLimitCheck === 'true'}
                            />
                          </Form.Control>
                        </Row>
                      </Content>
                    </Border>
                  )}
                />
                <Form.Option label={t('text:Not_Applied')} value="false" />
              </Form.Radio>
            </Form.Control>
          </Row>
        )}

        {/* maxLtvRatio :: Dealer Financing, Vendor Financing 에 따라 UI가 다르기 때문에 분리해서 작성
        Vendor - 비제어, Dealer- 제어 */}
        {supportedCollateralType === COLLATERAL_TYPE.AR && (
          <Row>
            <Form.Control required name="maxLtvRatio">
              <Cell>
                <Form.Label position="top" required={supportedCollateralType === COLLATERAL_TYPE.AR}>
                  {MaxLtvRatioLabel}
                </Form.Label>
                <Form.Description>{MaxLtvRatioDescription}</Form.Description>
              </Cell>
              <Form.NumberInput
                fieldSize={SizeType.SM}
                useUpToOneHundredPercent
                rightUnit="%"
                textAlign="text-right"
                numberType="integer"
              />
            </Form.Control>
          </Row>
        )}
        {supportedCollateralType === COLLATERAL_TYPE.INVOICE && (
          <>
            <input name="maxLtvRatio" type="hidden" ref={register} />
            <Row>
              <Form.Control required>
                <Cell>
                  <Form.Label position="top" required>
                    {MaxLtvRatioLabel}
                  </Form.Label>
                  <Form.Description>{MaxLtvRatioDescription}</Form.Description>
                </Cell>
                <RadioGroup
                  name="isLtvHundred"
                  stateValue={String(isLtvHundred)}
                  onChange={handleIsLtvHundredChange}
                  isEditable={isEditableField}
                  fetchedReadOnly={getReadOnlyValue('maxLtvRatio')}
                  error={errors?.maxLtvRatio}
                  showError={isNull(isLtvHundred)}
                >
                  <Radio label="100%" value="true" />
                  <Radio
                    label={t('text:Enter_the_Ratio')}
                    value="false"
                    render={(_, isParentRadioUnChecked) => (
                      <Cell>
                        <NumberInput
                          fieldSize={SizeType.SM}
                          useUpToOneHundredPercent
                          rightUnit="%"
                          textAlign="text-right"
                          readOnly={isParentRadioUnChecked}
                          parentReadOnly={getReadOnlyValue('maxLtvRatio')}
                          onChange={handleEnterLtvChange}
                          value={ltvValue}
                          isEditable={isEditableField}
                          numberType="integer"
                        />
                      </Cell>
                    )}
                  />
                </RadioGroup>
              </Form.Control>
            </Row>
          </>
        )}
      </Content>
    </>
  );
};

export default FinancingLimitOfPartners;
