import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FactoringNoticeDetailVOModel } from 'models/vo/FactoringNoticeDetailVO';
import type { FactoringNoticeReceiverVOModel } from 'models/vo/FactoringNoticeReceiverVO';
import type { FactoringNoticeRelatedSuccessArVOModel } from 'models/vo/FactoringNoticeRelatedSuccessArVO';
import {
  requestAnchorFactoringNoticeConfirm,
  requestAnchorFactoringNoticeDetail,
  requestAnchorFactoringNoticeReceiverList,
  requestAnchorFactoringNoticeRelatedArsList,
} from 'utils/http/api/anchor/factoring-notices';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const useAnchorDeptSellingNoticeDetailController = () => {
  const { factoringNoticeId } = useParams<any>();
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation();

  const [deptSellingDetail, setDeptSellingDetail] = useState<FactoringNoticeDetailVOModel>();
  const [recipientList, setRecipientList] = useState<Pageable<FactoringNoticeReceiverVOModel[]>>();
  const [relatedArList, setRelatedArList] = useState<Pageable<FactoringNoticeRelatedSuccessArVOModel[]>>();

  const { pageable: recipientListPageable, setPageable: setRecipientListPageable } = usePageable();
  const { pageable: relatedArListPageable, setPageable: setRelatedArListPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [fetchDeptSellingNoticeDetailInfo, fetchDeptSellingNoticeReceiptList, fetchDeptSellingNoticeRelatedArList] =
        await Promise.all([
          requestAnchorFactoringNoticeDetail(factoringNoticeId),
          requestAnchorFactoringNoticeReceiverList(
            factoringNoticeId,
            recipientListPageable.currentPage,
            recipientListPageable.sizePerPage,
          ),
          requestAnchorFactoringNoticeRelatedArsList(
            factoringNoticeId,
            relatedArListPageable.currentPage,
            relatedArListPageable.sizePerPage,
          ),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setDeptSellingDetail(fetchDeptSellingNoticeDetailInfo);
        setRecipientList(fetchDeptSellingNoticeReceiptList);
        setRelatedArList(fetchDeptSellingNoticeRelatedArList);

        setRecipientListPageable(fetchDeptSellingNoticeReceiptList);
        setRelatedArListPageable(fetchDeptSellingNoticeRelatedArList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginateReceiptList = async (pageNumber: number, rowCount: number) => {
    const response = await requestAnchorFactoringNoticeReceiverList(factoringNoticeId, pageNumber, rowCount);
    setRecipientList(response);
    setRecipientListPageable(response);
  };

  const paginateArList = async (pageNumber: number, rowCount: number) => {
    const response = await requestAnchorFactoringNoticeRelatedArsList(factoringNoticeId, pageNumber, rowCount);
    setRelatedArList(response);
    setRelatedArListPageable(response);
  };

  const fetchDeptSellingNoticeDetail = async () => {
    const response = await requestAnchorFactoringNoticeDetail(factoringNoticeId);
    setDeptSellingDetail(response);
  };

  const handleClickConfirmNotificationButton = async () => {
    modal.show(<h6>{t('text:Would_you_like_to_confirm_receipt_of_the_email?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async () => {
        try {
          await requestAnchorFactoringNoticeConfirm([factoringNoticeId]);

          modal.show(<h6>{t('text:Reception_of_the_mail_has_been_confirmed_Successfully')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => {
              fetchDeptSellingNoticeDetail();
            },
          });
        } catch (e) {
          modal.show(e);
        }
      },
    });
  };

  return {
    state: {
      deptSellingDetail,
      recipientList,
      relatedArList,
    },
    recipientListPageable,
    relatedArListPageable,
    paginateReceiptList,
    paginateArList,
    handleClickConfirmNotificationButton,
  };
};

export default useAnchorDeptSellingNoticeDetailController;
