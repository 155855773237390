import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';
export interface AccountTransactionVO {
  withdrawalAmount: string;
  depositAmount: string;
  memo: string;
  transactionDateTime: string;
}

export interface AccountTransactionVOModel extends AccountTransactionVO {
  withdrawalAmount: BigNumber;
  depositAmount: BigNumber;
}

export function formattingToAccountTransactionVOModel(data: AccountTransactionVO): AccountTransactionVOModel {
  const slicedZeroDecimalData = {
    withdrawalAmount: sliceZeroDecimal(data.withdrawalAmount),
    depositAmount: sliceZeroDecimal(data.depositAmount),
  };

  const { withdrawalAmount, depositAmount } = slicedZeroDecimalData;

  return {
    ...data,
    withdrawalAmount,
    depositAmount,
  };
}
