import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { SignInModel } from 'models/SignInModel';
import { getWaitingForApprovalCount } from 'utils/logic';
import {
  getAnchorLoanEnable,
  getCollateralType as getCollateralTypeFromLocalStorage,
  getSignIn,
} from 'utils/storage/LocalStorage';

import FinancierAnchorFinancingOptionListRegisteredTab from './tabs/FinancierAnchorFinancingOptionListRegisteredTab';
import FinancierAnchorFinancingOptionListWaitingTab from './tabs/FinancierAnchorFinancingOptionListWaitingTab';
import useFinancierAnchorFinancingOptionListController from './useFinancierAnchorFinancingOptionListController';

function FinancierAnchorFinancingOptionList() {
  const signInModel: SignInModel | null = getSignIn();
  const supportedCollateralType = getCollateralTypeFromLocalStorage();
  const anchorLoanEnable = getAnchorLoanEnable();
  const history = useHistory();
  const mounted = useMounted();
  const { t } = useTranslation(['format']);

  const { fetchAll, financingOptionCompletedTabUtils, financingOptionWaitingTabUtils } =
    useFinancierAnchorFinancingOptionListController();
  const { completedAnchorFinancingOptionListSearchForm, anchorFinancingOptionCompletedPage } =
    financingOptionCompletedTabUtils;
  const { waitingAnchorFinancingOptionListSearchForm, notCancelledWaitingAnchorFinancingOptionPage } =
    financingOptionWaitingTabUtils;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  const onClickRegisterFinancingOption = (e: any, type: COLLATERAL_TYPE): void => {
    e.preventDefault();
    history.push(ROUTES_FI.MANAGE_ANCHOR.FINANCING_OPTION_REGISTER, {
      supportedCollateralType: type === COLLATERAL_TYPE.INVOICE ? COLLATERAL_TYPE.INVOICE : COLLATERAL_TYPE.AR,
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Anchor_Financing_Option')} />
      {signInModel?.authorityType !== AUTHORITY_TYPE.OPERATOR ? (
        <GuideMessage
          message={[
            t('text:On_this_page,_you_can_view_the_list_of_Anchor_Financing_Options_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Anchor_Financing_Option'),
          ]}
        />
      ) : (
        <GuideMessage
          message={[
            t(
              'text:Click_the_corresponding_button_on_the_right_to_register_an_Anchor_Financing_Option_for_the_financing_program',
            ),
            t('text:On_this_page,_you_can_view_the_list_of_Anchor_Financing_Options_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Anchor_Financing_Option'),
          ]}
        >
          {{
            button: (
              <>
                {(supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.AR ||
                  supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) &&
                  anchorLoanEnable && (
                    <Button fullWidth onClick={e => onClickRegisterFinancingOption(e, COLLATERAL_TYPE.AR)}>
                      {t('text:Register_Financing_Option_of_Vendor_Finance')}
                    </Button>
                  )}
                {(supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE ||
                  supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) &&
                  anchorLoanEnable && (
                    <Button fullWidth onClick={e => onClickRegisterFinancingOption(e, COLLATERAL_TYPE.INVOICE)}>
                      {t('text:Register_Financing_Option_of_Dealer_Finance')}
                    </Button>
                  )}
              </>
            ),
          }}
        </GuideMessage>
      )}

      <Tab
        initialActiveTabIndex={1}
        tabType="approvalStatus"
        tabList={[
          {
            tabName: t('text:Waiting_for_Approval'),
            tabItemCount: getWaitingForApprovalCount(
              waitingAnchorFinancingOptionListSearchForm.getValues('approvalTypes'),
              notCancelledWaitingAnchorFinancingOptionPage?.totalElements,
            ),
          },
          {
            tabName: t('text:Registration_Completed'),
            tabItemCount: anchorFinancingOptionCompletedPage?.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...waitingAnchorFinancingOptionListSearchForm} key="waiting-financing">
            <FinancierAnchorFinancingOptionListWaitingTab {...financingOptionWaitingTabUtils} />
          </FormProvider>,
          <FormProvider {...completedAnchorFinancingOptionListSearchForm} key="completed-financing">
            <FinancierAnchorFinancingOptionListRegisteredTab {...financingOptionCompletedTabUtils} />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default FinancierAnchorFinancingOptionList;
