import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { COLLATERAL_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { LoanVOModel } from 'models/vo/LoanVO';
import type { RelatedAnchorClientLimitInfoVOModel } from 'models/vo/RelatedAnchorClientLimitInfoVO';
import type { WaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import {
  requestSyAnchorAgreementLimitInfoRelatedDealerAgreement,
  requestSystemPartnerAgreementDetail,
} from 'utils/http/api/system/dealer-agreements';
import { requestSystemLoanList } from 'utils/http/api/system/loans';
import { requestSystemWaitingPartnerAgreementList } from 'utils/http/api/system/waiting-dealer-agreements';
import useModal from 'utils/modal/useModal';

export function useSyPartnerAgreementRegisteredDetailState() {
  const modal = useModal();

  const dealerAgreementId = (useParams() as any).id;

  const [dataState, setDataState] = useState({
    syPartnerAgreementDetail: {} as DealerAgreementDetailVOModel,
    syAnchorAgreementLimitInfo: {} as RelatedAnchorClientLimitInfoVOModel,
    syLoanList: [] as LoanVOModel[],
    syEditHistoryList: [] as WaitingDealerAgreementVOModel[],
  });

  const { pageable: financingPageable, setPageable: setFinancingPageable } = usePageable();
  const { pageable: editHistoryPageable, setPageable: setEditHistoryPageable } = usePageable();

  let fetchedAnchorAgreementLimitInfo: RelatedAnchorClientLimitInfoVOModel;

  const fetchAll = async () => {
    try {
      const [fetchedDealerAgreementDetail, fetchedLoanList, fetchedEditHistoryList] = await Promise.all([
        requestSystemPartnerAgreementDetail(dealerAgreementId),
        requestSystemLoanList(financingPageable.currentPage, financingPageable.sizePerPage, { dealerAgreementId }),
        requestSystemWaitingPartnerAgreementList(editHistoryPageable.currentPage, editHistoryPageable.sizePerPage, {
          dealerAgreementId,
        }),
      ]);

      if (fetchedDealerAgreementDetail.collateralType === COLLATERAL_TYPE.AR)
        fetchedAnchorAgreementLimitInfo = await requestSyAnchorAgreementLimitInfoRelatedDealerAgreement(
          dealerAgreementId,
        );

      setFinancingPageable(fetchedLoanList);
      setEditHistoryPageable(fetchedEditHistoryList);

      setDataState(prevState => ({
        ...prevState,
        syPartnerAgreementDetail: fetchedDealerAgreementDetail,
        syAnchorAgreementLimitInfo: fetchedAnchorAgreementLimitInfo,
        syLoanList: [...fetchedLoanList.content],
        syEditHistoryList: [...fetchedEditHistoryList.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  };

  async function getSyLoanListPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestSystemLoanList(pageNumber, rowCount, {
        dealerAgreementId,
      });
      setFinancingPageable(response);
      setDataState(prevState => ({
        ...prevState,
        syLoanList: [...response.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  }

  async function getSyEditHistoryListPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestSystemWaitingPartnerAgreementList(pageNumber, rowCount, { dealerAgreementId });
      setEditHistoryPageable(response);
      setDataState(prevState => ({
        ...prevState,
        syEditHistoryList: [...response.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  }

  return {
    state: dataState,
    fetchAll,
    getSyLoanListPaginate,
    getSyEditHistoryListPaginate,
    financingPageable,
    editHistoryPageable,
  };
}
