import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import { FormBorder, FormContents, FormSelect, NumericFormatInput } from 'components/stateless/CommonForm';
import type { SelectOptionType } from 'components/stateless/Select/Select';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { FinancierFinancierCurrencyCommonSettingRequest } from 'utils/http/api/financier/financier-currency-common-setting/requests';
import useModal from 'utils/modal/useModal';

interface LoanAmountSettingModalProps {
  financierCurrencyCommonSettingId?: number;
  currencyType?: CURRENCY_TYPE;
  registeredCurrencyTypes: CURRENCY_TYPE[];
  minimumLoanAmount?: number;
  dataHandler: Function;
  modalId: number;
}

function LoanAmountSettingModal({
  financierCurrencyCommonSettingId,
  minimumLoanAmount,
  modalId,
  registeredCurrencyTypes,
  currencyType,
  dataHandler,
}: LoanAmountSettingModalProps): JSX.Element {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { disableConfirmBtn } = useModal();

  const { register, setValue, getValues, watch, errors, control } =
    useForm<FinancierFinancierCurrencyCommonSettingRequest>({
      mode: 'onSubmit',
      shouldFocusError: true,
    });

  const { currencyType: watchedCurrencyType, minimumLoanAmount: watchedMinimumLoanAmount } = watch();

  useEffect(() => {
    if (mounted) {
      if (financierCurrencyCommonSettingId) {
        setValue('currencyType', currencyType);
        setValue('minimumLoanAmount', minimumLoanAmount);
      }
    }
  }, [mounted]);

  useEffect(() => {
    disableConfirmBtn(modalId, isEmpty(watchedCurrencyType) || isEmpty(watchedMinimumLoanAmount));
  }, [disableConfirmBtn, modalId, watchedCurrencyType, watchedMinimumLoanAmount]);

  const onChangeCurrencyType = (e: any) => {
    dataHandler(e.target.value, getValues().minimumLoanAmount);
  };

  const onUpdateMinimumLoanAmount = () => {
    dataHandler(undefined, getValues().minimumLoanAmount);
  };

  const getCurrencyOptions = () => {
    const supportedCurrencyTypes = getSelectOptions<CURRENCY_TYPE>(
      'CURRENCY_TYPE',
      currencyType ? [currencyType] : 'ALL',
    );

    const selectableCurrencyTypes = supportedCurrencyTypes.reduce(
      (currencyOptions: SelectOptionType[], currencyOption: SelectOptionType) => {
        if (currencyType === currencyOption.value) return [...currencyOptions, currencyOption];
        if (!registeredCurrencyTypes.includes(currencyOption.value)) return [...currencyOptions, currencyOption];
        else return currencyOptions;
      },
      [],
    );

    return selectableCurrencyTypes;
  };

  return (
    <div className="modal-container">
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormSelect
              name="currencyType"
              label={t('text:Currency')}
              selectOptions={getCurrencyOptions()}
              ref={register}
              onChange={onChangeCurrencyType}
              placeholderOptions={{ show: true }}
              required
              col={12}
              error={errors.currencyType}
            />
          </div>
          <div className="row">
            <NumericFormatInput
              control={control}
              name="minimumLoanAmount"
              label={t('text:Minimum_Amount')}
              col={12}
              onChange={onUpdateMinimumLoanAmount}
              onPaste={onUpdateMinimumLoanAmount}
              currencyType={watchedCurrencyType}
              numberType="bigNumber"
              required
              disabled={!watchedCurrencyType}
              error={errors.minimumLoanAmount}
            />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default LoanAmountSettingModal;
