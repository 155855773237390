import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { CURRENCY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestFinancierCalendarList } from 'utils/http/api/financier/financier-calendar';
import useModal from 'utils/modal/useModal';

import { getSum } from '../calculate';
import {
  convertToServerDateFormat,
  getAvailableSettlementDateRangeForRegistrationArOrAp,
  getDayTerm,
  verifySettlementDateForRegistrationArOrAp,
} from '../date/date';

import type { LastArPhaseVOModel } from '../../models/vo/LastArPhaseVO';
import type { BigNumber } from '../bigNumber';
import type { FinancierArPhaseApproveRequest } from '../http/api/financier/ar-summaries/request';
import type { TFunction } from 'i18next';

const MAX_ROW_LENGTH = 500;

const calculateDateRange = (minimumPeriod: number, maximumPeriod: number) => {
  const date = new Date();
  const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];
  const minimumDate = new Date(today[0], today[1], today[2] + minimumPeriod);
  const maximumDate = new Date(today[0], today[1], today[2] + maximumPeriod);

  return { minimumDate, maximumDate };
};

const useARInputTableController = (financierId: number, arSummaryLastPhaseData: LastArPhaseVOModel | undefined) => {
  const { t } = useTranslation();

  const apRegistrationUseForm = useForm<FinancierArPhaseApproveRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { getValues, reset, control } = apRegistrationUseForm;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'arList',
  });

  const modal = useModal();
  const mounted = useMounted();

  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [totalApAmount, setTotalApAmount] = useState<BigNumber>('0');
  const [holidays, setHolidays] = useState<string[]>(['']);
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();
  const [settlementDatePeriod, setSettlementDatePeriod] = useState({
    minimumDate: new Date(),
    maximumDate: new Date(),
  });

  const appendRow = (e: any) => {
    e.preventDefault();
    if (fields.length >= MAX_ROW_LENGTH) {
      modal.show(
        <h6>
          {t('text:Allowed_N_rows_at_a_time', { number: MAX_ROW_LENGTH })} {t('text:Please_check_the_data_again')}
        </h6>,
      );
    } else {
      append({
        arNumber: undefined,
        arAmount: undefined,
        arIssuedDate: '',
        settlementDate: initialSettlementDate,
      });
    }
  };

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const updateTotalApAmount = useCallback(() => {
    let sum = '0';
    sum = getSum(getValues().arList, 'arAmount');
    setTotalApAmount(sum);
  }, [getValues]);

  useEffect(() => {
    updateTotalApAmount();
  }, [updateTotalApAmount]);

  const fetchAll = async () => {
    try {
      // Fetch common settings data

      const { minimumPeriodForRegisterAr, maximumPeriodForRegisterAr } = await requestFinancierSettingData(financierId);

      // Fetch holidays within the date range and calculate available settlement dates
      const { minimumDate, maximumDate } = calculateDateRange(minimumPeriodForRegisterAr, maximumPeriodForRegisterAr);

      const fetchFinancierHoliday = await requestFinancierCalendarList(
        0,
        Number(getDayTerm(convertToServerDateFormat(minimumDate), convertToServerDateFormat(maximumDate))) + 1,
        {
          fromDate: convertToServerDateFormat(minimumDate),
          toDate: convertToServerDateFormat(maximumDate),
          holiday: true,
        },
      );

      const holidayDates = fetchFinancierHoliday.content.map(item => item.solarDate);

      const availableSettlementDateRange = getAvailableSettlementDateRangeForRegistrationArOrAp(
        holidayDates,
        minimumDate,
        maximumDate,
      );
      const [initialSettlementDate] = availableSettlementDateRange;

      setHolidays(fetchFinancierHoliday.content.map(item => item.solarDate));
      setInitialSettlementDate(initialSettlementDate);
      setSettlementDatePeriod({ maximumDate, minimumDate });

      const editableArLists = arSummaryLastPhaseData?.arList.content;
      // reset data
      if (editableArLists && editableArLists.length !== 0) {
        reset({
          arList: editableArLists.map(item => {
            return {
              arNumber: item.arNumber,
              arAmount: item.arAmount,
              arIssuedDate: item.arIssuedDate,
              settlementDate: verifySettlementDateForRegistrationArOrAp(
                item.settlementDate,
                initialSettlementDate,
                availableSettlementDateRange,
              ),
            };
          }),
        });
        updateTotalApAmount();
      } else {
        reset({
          arList: [
            {
              arNumber: undefined,
              arAmount: undefined,
              arIssuedDate: undefined,
              settlementDate: initialSettlementDate,
            },
          ],
        });
      }
    } catch (error: any) {
      modal.show(`Failed to fetch data: ${error.message}`);
    }
  };

  const apNumberDuplicateValidator = (value: string, index: number) => {
    const data = getValues();
    if (data.arList) {
      const getArNumbers = data.arList.map(item => item.arNumber);
      getArNumbers.splice(index, 1);
      const arNumbers = getArNumbers.filter(item => {
        if (item) return item;
      });

      if (arNumbers.includes(value)) {
        return false;
      }
    }

    return true;
  };

  const getFieldNames = (t: TFunction, options?: { currencyType?: CURRENCY_TYPE }) => {
    return {
      partnerName: {
        name: t('text:Partner_Name'),
      },
      partnerTaxCode: {
        name: t('text:Partner_Tax_Code'),
      },
      arNumber: {
        name: t('text:AR_Number'),
      },
      arIssuedDate: {
        name: t('text:AR_Issued_Date'),
      },
      settlementDate: {
        name: t('text:Settlement_Date'),
      },
      arAmount: {
        name: `${t('text:AR_Amount')} (${t('text:unit')}: ${options?.currencyType ?? ''})`,
      },
    };
  };

  const removeRow = (e: any) => {
    const upDateInValidState = () => {
      let i = 0;
      const temp = [...checkedValidation];

      for (const index of checkedRows) {
        temp.splice(index - i, 1);
        i++;
      }

      return temp;
    };
    e.preventDefault();
    if (checkedRows.length === fields.length) {
      reset({
        arList: [
          {
            arNumber: undefined,
            arAmount: undefined,
            arIssuedDate: undefined,
            settlementDate: initialSettlementDate,
          },
        ],
      });
      setCheckedValidation([]);
    } else {
      setCheckedValidation(upDateInValidState());
      remove(checkedRows);
    }

    setCheckedRows([]);
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const arr: number[] = [];
      fields.forEach((el, index) => arr.push(index));
      setCheckedRows(arr);
    } else {
      setCheckedRows([]);
    }
  };

  const handleCheckChange = (e: any, index: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  return {
    apRegistrationUseForm,
    apNumberDuplicateValidator,
    getFieldNames,
    removeRow,
    appendRow,
    handleCheckAll,
    handleCheckChange,
    checkedRows,
    checkedValidation,
    setCheckedValidation,
    totalApAmount,
    updateTotalApAmount,
    settlementDatePeriod,
    holidays,
    fields,
  };
};

export default useARInputTableController;
