import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormContents } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { ROUTES_AC } from 'constants/routes/anchor';
import { COLLATERAL_TYPE } from 'enums';
import type { MultipleAnchorLoanRequestDetailVOModel } from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import type { MultipleAnchorLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessArVO';
import type { MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessInvoiceVO';
import type { BigNumber } from 'utils/bigNumber';

import { useAnchorBulkFinancingDetailRequestInformationController } from './logics';

interface AnchorBulkFinancingDetailRequestInformationProps {
  bulkFinancingDetailInfo: MultipleAnchorLoanRequestDetailVOModel;
  arList: MultipleAnchorLoanRequestRelatedSuccessArVOModel[];
  invoiceList: MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel[];
  isAuthorizerRequest: boolean;
  financingInfoCheckBoxState: { financingInfoCheck: boolean; setFinancingInfoCheck: Dispatch<SetStateAction<boolean>> };
}

function AnchorBulkFinancingDetailRequestInformation({
  bulkFinancingDetailInfo,
  arList,
  invoiceList,
  isAuthorizerRequest,
  financingInfoCheckBoxState,
}: AnchorBulkFinancingDetailRequestInformationProps) {
  const { t } = useTranslation(['format']);
  const isInvoice = bulkFinancingDetailInfo.collateralType === COLLATERAL_TYPE.INVOICE;
  const { financingInfoCheck, setFinancingInfoCheck } = financingInfoCheckBoxState;

  const { handleDownloadLoanAgreementClick, handleDownloadLoanRequestAttachmentClick } =
    useAnchorBulkFinancingDetailRequestInformationController(bulkFinancingDetailInfo.multipleAnchorLoanRequestId);

  const AR_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:AP_Number'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Partner_Tax_Code'),
    },
    {
      headerText: t('text:AP_Amount'),
    },
    {
      headerText: t('text:Issued_Date'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const INVOICE_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Invoice_Number'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Partner_Tax_Code'),
    },
    {
      headerText: t('text:Invoice_Amount'),
    },
    {
      headerText: t('text:Issued_Date'),
    },
    {
      headerText: t('text:Payment_Date'),
    },
    {
      headerText: t('text:Expected_Disbursement_Date_of_Partner'),
    },
    {
      headerText: t('text:Financed_Invoice_Amount_by_Partner'),
    },
    {
      headerText: t('text:Requested_Financing_Amount'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const returnAmountWithCurrency = (amount: number | BigNumber | undefined) => {
    return `${t('format:number', {
      value: amount,
    })} ${bulkFinancingDetailInfo.currencyType}`;
  };

  const ArListTable = () => (
    <>
      <TableHeader header={AR_TABLE_HEADERS} />
      <TableBody numOfCol={AR_TABLE_HEADERS.length}>
        {arList?.map((item, index: number) => (
          <Tr key={index}>
            <Td data={item.arNumber} />
            <Td data={item.anchorPartnerName} />
            <Td data={item.anchorPartnerTaxCode} />
            <Td data={item.arAmount} format="number" />
            <Td data={item.arIssuedDate} format="date" />
            <TdLink path={ROUTES_AC.VIEW_TRANSACTION.AP_DETAIL_BUILD_PATH(item.successArId)} />
          </Tr>
        ))}
      </TableBody>
    </>
  );

  const InvoiceListTable = () => (
    <>
      <TableHeader header={INVOICE_TABLE_HEADERS} />
      <TableBody numOfCol={INVOICE_TABLE_HEADERS.length}>
        {invoiceList?.map((item, index: number) => (
          <Tr key={index}>
            <Td data={item.invoiceNumber} />
            <Td data={item.dealerName} />
            <Td data={item.dealerTaxCode} />
            <Td data={item.invoiceAmount} format="number" />
            <Td data={item.invoiceIssuedDate} format="date" />
            <Td data={item.invoiceSettlementDate} format="date" />
            <Td data={item.desiredDisburseDateByPartner} format="date" />
            <Td data={item.collateralizedAmountByPartner} format="number" />
            <Td data={item.requestedAmount} format="number" />
            <TdLink path={ROUTES_AC.VIEW_TRANSACTION.INVOICE_DETAIL_BUILD_PATH(item.successInvoiceId)} />
          </Tr>
        ))}
      </TableBody>
    </>
  );

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_Request_Information')} />
      <div className="information-form__detail-form border-bott-none mt-4">
        <div className="detail-title bg-sub100 border-bottom-2px-light-gray">
          <div className="d-flex justify-content-between align-items-center w-50">
            <div>{isInvoice ? t('text:Requested_Disbursement_Date') : t('text:Requested_Repayment_Date')}</div>
            <div className="gray-bold-font">
              {t('format:date', {
                value: isInvoice
                  ? bulkFinancingDetailInfo.desiredDisburseDate
                  : bulkFinancingDetailInfo.originalRepaymentDate,
                key: 'date',
              })}
            </div>
          </div>
        </div>
        <div className="detail-title bg-sub100">
          <div className="d-flex justify-content-between w-50">{t('text:Financing_Conditions')}</div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Requested_Financing_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalRequestedAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {isInvoice ? t('text:Selected_Invoice_Amount') : t('text:Selected_AP_Amount')}
              </div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalCollateralAmount)}
              </div>
            </div>
          </div>
          {isInvoice && (
            <div className="row">
              <div className="col-3">
                <div className="information-form__label flex-label">{t('text:Expected_Disbursement_Ratio')} (%)</div>
              </div>
              <div className="col-3">
                <div className="information-form__input flex-input gray-bold-font">
                  {bulkFinancingDetailInfo.maxLtvRatio} %
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Expected_Interest_Rate')} (%)</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {bulkFinancingDetailInfo.averageInterestRate} %
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Expected_Interest_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalInterestAmount)}
              </div>
            </div>
          </div>
          {isInvoice && bulkFinancingDetailInfo.showNetDisbursementAmount && (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="border-bottom-light-gray my-3" />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="information-form__label flex-label">
                    {t('text:Advance_Disbursement')}
                    <QuestionTooltip
                      id="netDisburseAmountTooltip"
                      place="top"
                      contentText={
                        <>
                          <div>{t('text:Requested_Financing_Amount')}ⓐ</div>
                          <div>{t('text:Prepayment_Interest')} ⓑ</div>
                          <div>--------------------------------------------------------</div>
                          <div>{t('text:Advance_Disbursement')} ⓒ = ⓐ - ⓑ</div>
                        </>
                      }
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="information-form__input flex-input gray-bold-font">
                    {returnAmountWithCurrency(bulkFinancingDetailInfo.totalNetDisbursementAmount)}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <FormBorder hideBorderBottom={isAuthorizerRequest}>
        <FormSubtitle
          title={
            isInvoice
              ? t('text:Selected_Invoices_for_Financing_Application')
              : t('text:Selected_AP_for_Financing_Application')
          }
        />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <TableBorder scrollYMaxHeight={570}>{isInvoice ? <InvoiceListTable /> : <ArListTable />}</TableBorder>
        </FormContents>
        <FormSubtitle title={t('text:Financing_Application_Document')} />
        <div className="detail-in-file-download-form">
          <div className="d-flex justify-content-between">
            <label className="information-form__label">{t('text:Attached_File')}</label>
            <input
              type="text"
              placeholder={
                bulkFinancingDetailInfo.loanRequestAttachmentName
                  ? bulkFinancingDetailInfo.loanRequestAttachmentName
                  : t('text:no_file_attached')
              }
              className="upload-file-input"
              readOnly
            />
            <DownloadButton
              onClick={handleDownloadLoanRequestAttachmentClick}
              disabled={!bulkFinancingDetailInfo.loanRequestAttachmentId}
            />
          </div>
        </div>
        <FormSubtitle title={t('text:Financing_Agreement_Document')} />
        <div className="detail-in-file-download-form">
          <div className="d-flex justify-content-between">
            <label className="information-form__label">{t('text:Attached_File')}</label>
            <input
              type="text"
              placeholder={
                bulkFinancingDetailInfo.loanAgreementAttachmentName
                  ? bulkFinancingDetailInfo.loanAgreementAttachmentName
                  : t('text:no_file_attached')
              }
              className="upload-file-input"
              readOnly
            />
            <DownloadButton
              onClick={handleDownloadLoanAgreementClick}
              disabled={!bulkFinancingDetailInfo.loanAgreementAttachmentId}
            />
          </div>
        </div>
      </FormBorder>
      {isAuthorizerRequest && (
        <ConfirmCheckBox
          checked={financingInfoCheck}
          id="financing-info-check"
          labelText={
            isInvoice
              ? t('text:I_have_checked_the_invoice_information_disbursement_date_payment_date_and_financing_conditions')
              : t('text:I_have_checked_that_the_AP_information_settlement_date_and_financing_conditions')
          }
          onChangeCheckBox={() => {
            setFinancingInfoCheck(prevState => !prevState);
          }}
        />
      )}
    </div>
  );
}

export default AnchorBulkFinancingDetailRequestInformation;
