import type { BigNumber } from 'utils/bigNumber';

export interface CalculateForMultipleLoanRequestVO {
  maxLtvRatio: number;
  totalInterestRate: number;
  totalRequestedAmount: string;
  expectedTotalInterestAmount: string;
  totalNetDisbursementAmount: string;
  totalRemainingCollateralAmount: string;
  totalArCommissionAmount: string;
}

export interface CalculateForMultipleLoanRequestVOModel extends CalculateForMultipleLoanRequestVO {
  totalRequestedAmount: BigNumber;
  expectedTotalInterestAmount: BigNumber;
  totalNetDisbursementAmount: BigNumber;
  totalRemainingCollateralAmount: BigNumber;
  totalArCommissionAmount: BigNumber;
}

export function formattingToCalculateForMultipleLoanRequestVOModel(
  data: CalculateForMultipleLoanRequestVO,
): CalculateForMultipleLoanRequestVOModel {
  return {
    ...data,
  };
}
