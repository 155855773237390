import Input from './Input';
import { useFormContext } from '..';

import type { FormInputProps } from './Input';

const UncontrolledInput = (props: FormInputProps) => {
  const { name } = props;
  const {
    methods: { register, errors, watch },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();

  const currentValue = watch(name);

  const fetchedReadOnly = getReadOnlyValue(name);

  return (
    <Input
      {...props}
      ref={register}
      isEditable={isEditable}
      errors={errors}
      fetchedReadOnly={fetchedReadOnly}
      currentValue={currentValue}
    />
  );
};

export default UncontrolledInput;
