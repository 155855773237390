import { useState } from 'react';

import { useArrayState } from 'hooks/useArrayState';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import type { ClearErrors, SetError } from 'utils/error/manager';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFinancierPotentialPartnerAcquisitionDocumentDelete,
  requestFinancierPotentialPartnerAcquisitionDocumentRegister,
} from 'utils/http/api/financier/potential-partner-acquisition-documents';
import type { FinancierPotentialPartnerAcquisitionDocumentRegisterRequest } from 'utils/http/api/financier/potential-partner-acquisition-documents/request';
import useModal from 'utils/modal/useModal';
import { hasValue, requestDTOParser } from 'utils/valueManager/ValueManager';

import type { PPAcquisitionDocumentType } from '../../useFinancierSCPartnerAcquisitionSettingState';

export function useDocumentForm() {
  const modal = useModal();

  const {
    append: activatedRowAppend,
    remove: activatedRowRemove,
    initialize: activatedRowInitialize,
    update: activatedRowUpdate,
    arr: activatedRowState,
  } = useArrayState<PPAcquisitionDocumentType>();

  const {
    append: deactivatedRowAppend,
    remove: deactivatedRowRemove,
    initialize: deactivatedRowInitialize,
    update: deactivatedRowUpdate,
    arr: deactivatedRowState,
  } = useArrayState<PPAcquisitionDocumentType>();

  const [isSortActivatedRow, setActivatedRow] = useState(false);
  const [isSortDeactivatedRow, setDeactivatedRow] = useState(false);

  const appendEmptyActivatedRow = () => {
    activatedRowAppend({
      data: {
        activate: 'true',
      },
      isEditable: true,
    });
  };

  const appendEmptyDeactivatedRow = () => {
    deactivatedRowAppend({
      data: {
        activate: 'false',
      },
      isEditable: true,
    });
  };

  const deleteActivatedRow = async (
    index: number,
    potentialPartnerAcquisitionDocumentSettingId?: number | undefined,
  ): Promise<void> => {
    try {
      if (hasValue(potentialPartnerAcquisitionDocumentSettingId))
        await requestFinancierPotentialPartnerAcquisitionDocumentDelete(
          potentialPartnerAcquisitionDocumentSettingId as number,
        );
      activatedRowRemove(index);
      // row 개수가 하나일 때 삭제 시, 삭제 후에 새로운 행 추가
      if (activatedRowState.length === 1) {
        appendEmptyActivatedRow();
      }
    } catch (e) {
      modal.show(e);
    }
  };

  const saveActivatedRow = async (
    index: number,
    formData: FinancierPotentialPartnerAcquisitionDocumentRegisterRequest,
    setError: SetError<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>,
    clearErrors: ClearErrors<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>,
  ): Promise<PotentialPartnerAcquisitionDocumentVOModel | void> => {
    const handleActivatedFormRowRemove = (index: number) => {
      activatedRowRemove(index);
      if (activatedRowState?.length - 1 === 0) appendEmptyActivatedRow();
    };

    setDeactivatedRow(false);
    try {
      requestDTOParser(formData);
      const response = await requestFinancierPotentialPartnerAcquisitionDocumentRegister(formData);
      if (response) {
        // 200 oK 시 document status 에 따라 row 위치 변경
        if (formData.activate === 'false') {
          handleActivatedFormRowRemove(index);
          deactivatedRowAppend({
            data: response,
            isEditable: false,
          });
          setDeactivatedRow(true);
        } else if (formData.activate === 'true') {
          activatedRowUpdate(index, {
            data: response,
            isEditable: false,
          });
        }

        return response;
      }
    } catch (e) {
      modal.show(e);
      formErrorHandler<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>(e, setError, clearErrors, false);
    }
  };

  const deleteDeactivatedRow = async (
    index: number,
    potentialPartnerAcquisitionDocumentSettingId: number | undefined,
  ): Promise<void> => {
    try {
      if (hasValue(potentialPartnerAcquisitionDocumentSettingId))
        await requestFinancierPotentialPartnerAcquisitionDocumentDelete(
          potentialPartnerAcquisitionDocumentSettingId as number,
        );
      deactivatedRowRemove(index);
      // row 개수가 하나일 때 삭제 시, 삭제 후에 새로운 행 추가
      if (deactivatedRowState.length === 1) {
        appendEmptyDeactivatedRow();
      }
    } catch (e) {
      modal.show(e);
    }
  };

  const saveDeactivatedRow = async (
    index: number,
    formData: FinancierPotentialPartnerAcquisitionDocumentRegisterRequest,
    setError: SetError<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>,
    clearErrors: ClearErrors<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>,
  ): Promise<PotentialPartnerAcquisitionDocumentVOModel | void> => {
    const handleDeactivatedFormRowRemove = (index: number) => {
      deactivatedRowRemove(index);
      if (deactivatedRowState?.length - 1 === 0) appendEmptyDeactivatedRow();
    };

    setActivatedRow(false);
    try {
      requestDTOParser(formData);
      const response = await requestFinancierPotentialPartnerAcquisitionDocumentRegister(formData);
      if (response) {
        // 200 oK 시 document status 에 따라 row 위치 변경
        if (formData.activate === 'true') {
          handleDeactivatedFormRowRemove(index);
          activatedRowAppend({
            data: response,
            isEditable: false,
          });
          setActivatedRow(true);
        } else if (formData.activate === 'false') {
          deactivatedRowUpdate(index, {
            data: response,
            isEditable: false,
          });
        }

        return response;
      }
    } catch (e) {
      modal.show(e);
      formErrorHandler<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>(e, setError, clearErrors, false);
    }
  };

  const sortFormRowByDocumentSettingId = (documentRowList: PPAcquisitionDocumentType[]) => {
    documentRowList.sort((a, b) =>
      'potentialPartnerAcquisitionDocumentSettingId' in a.data
        ? 'potentialPartnerAcquisitionDocumentSettingId' in b.data
          ? a.data.potentialPartnerAcquisitionDocumentSettingId - b.data.potentialPartnerAcquisitionDocumentSettingId
          : -1
        : 1,
    );
  };

  const updateEditable = (isActivatedList: boolean, index: number, isEditable: boolean) => {
    if (isActivatedList) {
      activatedRowUpdate(index, {
        ...activatedRowState[index],
        isEditable: isEditable,
      });
    } else {
      deactivatedRowUpdate(index, {
        ...deactivatedRowState[index],
        isEditable: isEditable,
      });
    }
  };

  return {
    activatedFormUtils: {
      activatedRowState,
      activatedRowInitialize,
      activatedRowRemove,
      appendEmptyActivatedRow,
      deleteActivatedRow,
      saveActivatedRow,
      isSortActivatedRow,
    },
    deactivatedFormUtils: {
      deactivatedRowState,
      deactivatedRowInitialize,
      deactivatedRowRemove,
      appendEmptyDeactivatedRow,
      deleteDeactivatedRow,
      saveDeactivatedRow,
      isSortDeactivatedRow,
    },
    sortFormRowByDocumentSettingId,
    updateEditable,
  };
}
