import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ANCHOR_DEALER_STATUS } from 'enums';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorDealerList } from 'utils/http/api/anchor/anchor-dealers';
import type { AnchorDealerListRequest } from 'utils/http/api/anchor/anchor-dealers/request';
import { requestAnchorWaitingAnchorDealerList } from 'utils/http/api/anchor/waiting-anchor-dealers';
import type { AnchorWaitingAnchorDealerListRequest } from 'utils/http/api/anchor/waiting-anchor-dealers/request';
import { setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

interface AnchorDealerCompanyInfoListPageStateType {
  waitingListResult: Pageable<WaitingAnchorDealerVOModel[]>;
  waitingListResultForTabCount: Pageable<WaitingAnchorDealerVOModel[]>;
  completedListResult: Pageable<AnchorDealerVOModel[]>;
}

const WAITING_LIST_QS_KEY = 'ac-dealer-company-waiting-list';
const COMPLETED_LIST_QS_KEY = 'ac-dealer-company-completed-list';

export function useAnchorDealerCompanyInfoListPageState() {
  const modal = useModal();

  const { pageable: waitingListPageable, setPageable: setWaitingListPageable } = usePageable(WAITING_LIST_QS_KEY);
  const { pageable: completedListPageable, setPageable: setCompletedListPageable } = usePageable(COMPLETED_LIST_QS_KEY);

  const waitingListSearchForm = useForm<AnchorWaitingAnchorDealerListRequest>();
  const completedListSearchForm = useForm<AnchorDealerListRequest>();

  const setWaitingTabSearchValue = (formSearchData?: AnchorWaitingAnchorDealerListRequest) => {
    const getSearchFormData: AnchorWaitingAnchorDealerListRequest = formSearchData
      ? formSearchData
      : waitingListSearchForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData: AnchorWaitingAnchorDealerListRequest = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
      hasTargetAnchorDealer: true,
      anchorDealerStatuses: [ANCHOR_DEALER_STATUS.REGISTERED],
    };

    const notCancelledWaitingForApprovalSearchData: AnchorWaitingAnchorDealerListRequest = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
      hasTargetAnchorDealer: true,
      anchorDealerStatuses: [ANCHOR_DEALER_STATUS.REGISTERED],
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const [dataState, setDataState] = useState<AnchorDealerCompanyInfoListPageStateType>({
    waitingListResult: {} as Pageable<WaitingAnchorDealerVOModel[]>,
    completedListResult: {} as Pageable<AnchorDealerVOModel[]>,
    waitingListResultForTabCount: {} as Pageable<WaitingAnchorDealerVOModel[]>,
  });

  const constructAndAddSearchParams = () => {
    const { waitingForApprovalSearchData } = setWaitingTabSearchValue();

    const waitingTabQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingListPageable.currentPage,
        rowCount: waitingListPageable.sizePerPage,
      },
      WAITING_LIST_QS_KEY,
    );

    const completedTabQs = makeSearchParamsPattern(
      {
        pageNumber: completedListPageable.currentPage,
        rowCount: completedListPageable.sizePerPage,
      },
      COMPLETED_LIST_QS_KEY,
    );

    addSearchParams(waitingTabQs + completedTabQs);
  };
  const fetchAll = async (): Promise<void> => {
    setFormValues<AnchorWaitingAnchorDealerListRequest>(
      waitingListSearchForm.setValue,
      getParsedSearchParams(WAITING_LIST_QS_KEY).formSearchData,
    );
    setFormValues<AnchorWaitingAnchorDealerListRequest>(
      completedListSearchForm.setValue,
      getParsedSearchParams(COMPLETED_LIST_QS_KEY).formSearchData,
    );

    constructAndAddSearchParams();
    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    try {
      const [waitingList, waitingListForTabCount, completedList] = await Promise.all([
        requestAnchorWaitingAnchorDealerList(
          waitingListPageable.currentPage,
          waitingListPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestAnchorWaitingAnchorDealerList(0, 1, notCancelledWaitingForApprovalSearchData),

        requestAnchorDealerList(
          completedListPageable.currentPage,
          completedListPageable.sizePerPage,
          completedListSearchForm.getValues(),
        ),
      ]);

      setDataState(prevState => ({
        ...prevState,
        waitingListResult: waitingList,
        completedListResult: completedList,
        waitingListResultForTabCount: waitingListForTabCount,
      }));

      setWaitingListPageable(waitingList);
      setCompletedListPageable(completedList);
    } catch (error) {
      modal.show(error);
    }
  };

  const waitingListFetchUtils = () => {
    const fetchWaitingList = async (
      pageNumber: number,
      rowCount: number,
      data: AnchorWaitingAnchorDealerListRequest,
    ) => {
      const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue(data);

      try {
        const [waitingList, waitingListForTabCount] = await Promise.all([
          requestAnchorWaitingAnchorDealerList(
            waitingListPageable.currentPage,
            waitingListPageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestAnchorWaitingAnchorDealerList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);
        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          WAITING_LIST_QS_KEY,
        );

        setDataState(prevState => ({
          ...prevState,
          waitingListResult: waitingList,
          waitingListResultForTabCount: waitingListForTabCount,
        }));
        setWaitingListPageable(waitingList);
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchWaitingList = async () => {
      await fetchWaitingList(0, waitingListPageable.sizePerPage, waitingListSearchForm.getValues());
    };

    const paginateWaitingList = async (pageNumber: number, rowCount: number) => {
      await fetchWaitingList(pageNumber, rowCount, getParsedSearchParams(WAITING_LIST_QS_KEY).formSearchData);
    };

    return {
      handleClickSearch: onClickSearchWaitingList,
      paginate: paginateWaitingList,
    };
  };

  const completedListFetchUtils = () => {
    const fetchCompletedList = async (pageNumber: number, rowCount: number, data: any) => {
      try {
        const completedList = await requestAnchorDealerList(pageNumber, rowCount, data);

        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          COMPLETED_LIST_QS_KEY,
        );

        setDataState(prevState => ({
          ...prevState,
          completedListResult: completedList,
        }));

        setCompletedListPageable(completedList);
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchCompletedList = async () => {
      await fetchCompletedList(0, completedListPageable.sizePerPage, completedListSearchForm.getValues());
    };

    const paginateCompletedList = async (pageNumber: number, rowCount: number) => {
      await fetchCompletedList(pageNumber, rowCount, getParsedSearchParams(COMPLETED_LIST_QS_KEY).formSearchData);
    };

    return {
      handleClickSearch: onClickSearchCompletedList,
      paginate: paginateCompletedList,
    };
  };

  return {
    fetchAll,
    state: dataState,
    useForms: {
      waitingListSearchForm,
      completedListSearchForm,
    },
    fetchUtils: {
      completedListFetchUtils,
      waitingListFetchUtils,
    },
    pageableObjects: {
      waitingListPageable,
      completedListPageable,
    },
  };
}
