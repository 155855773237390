const showLoadingUI = (targetDomID?: string): void => {
  const container = targetDomID ? document.getElementById(targetDomID) : document.getElementById('__block-ui__');

  if (container !== null) {
    container.innerHTML = targetDomID
      ? '<div class="api-loading-customize"><img src="/static/image/loader_01.gif"/></div>'
      : '<div class="api-loading"><img src="/static/image/loader_01.gif"/></div>';
  }
};

const unShowLoadingUI = (targetDomID?: string): void => {
  const container = targetDomID ? document.getElementById(targetDomID) : document.getElementById('__block-ui__');

  if (container !== null) {
    container.innerHTML = '';
  }
};

const preventPageRedirectOnLoading = (): void => {
  window.addEventListener('popstate', function (event) {
    const container = document.getElementById('__block-ui__');

    if (container && container.innerHTML !== '') {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  });
};

export { showLoadingUI, unShowLoadingUI, preventPageRedirectOnLoading };
