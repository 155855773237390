import Label from './Label';
import { useFormContext } from '..';

import type { LabelPropsType } from './Label';

const UncontrolledLabel = (props: LabelPropsType) => {
  const { name } = props;
  const { isEditable, isEditableStyle, getReadOnlyValue } = useFormContext();

  const fetchedReadOnly = getReadOnlyValue(name);

  return <Label {...props} isEditable={isEditable} isEditableLabel={isEditableStyle && !fetchedReadOnly} />;
};

export default UncontrolledLabel;
