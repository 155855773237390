import type Pageable from 'models/Pageable';
import type { ExtensionRequestDetailVO, ExtensionRequestDetailVOModel } from 'models/vo/ExtensionRequestDetailVO';
import { formattingToExtensionRequestDetailVOModel } from 'models/vo/ExtensionRequestDetailVO';
import type { ExtensionRequestVO, ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import { formattingToExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';
import type { DealerExtensionListRequest } from 'utils/http/api/dealer/extension-requests/request';

// 만기 연장 목록 조회
export async function requestDealerExtensionList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerExtensionListRequest,
): Promise<Pageable<ExtensionRequestVOModel[]>> {
  const response = await http.get<Pageable<ExtensionRequestVO[]>>({
    url: API_DE.EXTENSION_REQUESTS.EXTENSION_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const DealerExtensionList: ExtensionRequestVOModel[] = response.content.map((data: ExtensionRequestVO) =>
    formattingToExtensionRequestVOModel(data),
  );
  const DealerExtensionPage: Pageable<ExtensionRequestVOModel[]> = {
    ...response,
    content: DealerExtensionList,
  };

  return DealerExtensionPage;
}

// 만기 연장 상세 조회
export async function requestDealerExtensionDetail(extensionRequestId: number): Promise<ExtensionRequestDetailVOModel> {
  const response = await http.get<ExtensionRequestDetailVO>({
    url: API_DE.EXTENSION_REQUESTS.EXTENSION_DETAIL(extensionRequestId),
  });

  return formattingToExtensionRequestDetailVOModel(response);
}
