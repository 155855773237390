import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import { AUTHORITY_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { requestFinancierAnchorUsers } from 'utils/http/api/financier/anchor-users/index';

interface PropsType {
  anchorClientId: number;
  selectedAnchorUserListManager: (anchorUser: AnchorUserVOModel, isAdd: boolean) => void;
}

export function SearchAnchorUserModal({ anchorClientId, selectedAnchorUserListManager }: PropsType): JSX.Element {
  const [anchorUserPageData, setAnchorUserPageData] = useState<Pageable<AnchorUserVOModel[]>>();
  const { pageable: anchorUserPageable, setPageable: setAnchorUserPageable } = usePageable();
  const { t } = useTranslation();

  const [selectedAnchorUsers, setSelectedAnchorUsers] = useState<AnchorUserVOModel[]>([]);

  const fetchAnchorUsers = async (pageNumber: number = 1, sizePerPage: number = 10): Promise<void> => {
    const anchorUserPageData = await requestFinancierAnchorUsers(pageNumber, sizePerPage, {
      anchorClientId,
      authorityTypes: [AUTHORITY_TYPE.AUTHORIZER, AUTHORITY_TYPE.OPERATOR],
    });

    ReactDOM.unstable_batchedUpdates(() => {
      setAnchorUserPageData(anchorUserPageData);
      setAnchorUserPageable(anchorUserPageData);
    });
  };

  const paginate = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    fetchAnchorUsers(pageNumber, sizePerPage);
  };

  const onClickCheckBox = (e: any, anchorUser: AnchorUserVOModel) => {
    if (e.target.checked) {
      setSelectedAnchorUsers(prevAnchorUser => [...prevAnchorUser, anchorUser]);
      selectedAnchorUserListManager(anchorUser, true);
    } else {
      setSelectedAnchorUsers(prev => prev.filter(prevAnchorUser => prevAnchorUser.id !== anchorUser.id));
      selectedAnchorUserListManager(anchorUser, false);
    }
  };

  const isChecked = (anchorUser: AnchorUserVOModel): boolean => {
    return selectedAnchorUsers.map(selectedAnchorUser => selectedAnchorUser.id).includes(anchorUser.id);
  };

  useEffect(() => {
    fetchAnchorUsers();
  }, []);

  return (
    <>
      <table className="table-border">
        <colgroup>
          <col style={{ width: '50px' }} />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col" />
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:User_Code')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:Name')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:Mobile')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:Email')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:OTP_Number')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:Authority')}
            </th>
          </tr>
        </thead>
        <tbody>
          {anchorUserPageData?.content.length !== 0 ? (
            anchorUserPageData?.content.map(anchorUser => (
              <tr key={anchorUser.id}>
                <td className="text-center" style={{ backgroundColor: '#F5F5F5' }}>
                  <input
                    className="form-check-input m-0"
                    type="checkbox"
                    onChange={e => onClickCheckBox(e, anchorUser)}
                    checked={isChecked(anchorUser)}
                  />
                </td>
                <td>{anchorUser.bankUserId}</td>
                <td>{anchorUser.name}</td>
                <td>{anchorUser.mobile}</td>
                <td>{anchorUser.email}</td>
                <td>{anchorUser.anchorUserOtpSerialNo ?? '-'}</td>
                <td>{t(`code:authority-type.${anchorUser.authorityType}`)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} className="text-center">
                {t('text:No_user_has_been_registered_for_this_Anchor_company_yet')}{' '}
                {t('text:Please_register_the_anchor_user_first_before_proceeding')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination pageable={anchorUserPageable} paginate={paginate} />
    </>
  );
}

export default SearchAnchorUserModal;
