import { AUTHORITY_TYPE, USER_STATUS, stringToEnum } from 'enums';

export interface AnchorUserVO {
  id: number;
  name: string;
  employeeCode: string;
  email: string;
  mobile: string;
  position: string;
  userStatus: string;
  authorityType: string;
  autoGenerated: boolean;
  bankUserId: string;
  anchorClientAutoSignUpForAnchorAdminOfVendorFinance: boolean;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  financierId: number;
  financierName: string;
  userLoginId: string;
  anchorUserOtpSerialNo: string;
  userEmail: string;
}

export interface AnchorUserVOModel extends AnchorUserVO {
  userStatus: USER_STATUS;
  authorityType: AUTHORITY_TYPE;
}

export function formattingToAnchorUserVOModel(data: AnchorUserVO): AnchorUserVOModel {
  return {
    ...data,
    userStatus: stringToEnum(USER_STATUS, data.userStatus),
    authorityType: stringToEnum(AUTHORITY_TYPE, data.authorityType),
  };
}
