import { AUTHORIZER_STATUS, stringToEnum } from 'enums';

export interface DealerAuthorizerDetailVO {
  dealerAgreementId: number;
  dealerClientName: string;
  authorizerName: string;
  authorizerEmployeeCode: string;
  authorizerEmail: string;
  authorizerMobile: string;
  authorizerPosition: string;
  authorizerStatus: string;
  dealerClientId: number;
  dealerClientCode: string;
  userId: number;
  userLoginId: string;
  userEmail: string;
}

export interface DealerAuthorizerDetailVOModel extends DealerAuthorizerDetailVO {
  authorizerStatus: AUTHORIZER_STATUS;
}

export function formattingToDealerAuthorizerDetailVOModel(
  data: DealerAuthorizerDetailVO,
): DealerAuthorizerDetailVOModel {
  return {
    ...data,
    authorizerStatus: stringToEnum(AUTHORIZER_STATUS, data.authorizerStatus),
  };
}
