export interface FinancierOperatorTimeVO {
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}

export interface FinancierOperatorTimeVOModel extends FinancierOperatorTimeVO {}

export function formattingToFinancierOperatorTimeVOModel(data: FinancierOperatorTimeVO): FinancierOperatorTimeVOModel {
  return {
    ...data,
  };
}
