import { REPORT_TYPE, stringToEnum } from 'enums';

export interface AnchorReportVO {
  anchorReportId: number;
  sentEmail: string;
  reportType: string;
  createdDateTime: string;
  updatedDateTime: string;
  sent: boolean;
}

export interface AnchorReportVOModel extends AnchorReportVO {
  reportType: REPORT_TYPE;
}

export function formattingToAnchorReportVOModel(data: AnchorReportVO): AnchorReportVOModel {
  return {
    ...data,
    reportType: stringToEnum(REPORT_TYPE, data.reportType),
  };
}
