import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Accordion from 'components/stateless/Accordion/Accordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormSelect, FormSubtitle } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import { AnchorUserExceptionCode } from 'enums/exception';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { SignInModel } from 'models/SignInModel';
import type { WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestWaitingAnchorUserApprove,
  requestWaitingAnchorUserCancel,
  requestWaitingAnchorUserDetail,
  requestWaitingAnchorUserReturn,
  requestWaitingAnchorUserReturnModification,
} from 'utils/http/api/financier/waiting-anchor-users';
import type { FinancierWaitingAnchorUserReturnModificationRequest } from 'utils/http/api/financier/waiting-anchor-users/request';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function FinancierManageAnchorUserWaitingDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const signInModel: SignInModel | null = getSignIn();
  const [anchorUserWaitingDetail, setAnchorUserWaitingDetail] = useState<WaitingAnchorUserVOModel>();
  const { waitingAnchorUserId } = useParams<any>();

  const modal = useModal();
  const errorAlert = useModal();
  const returnedConfirmRequestModal = useModal();

  const getProperty = useProperty<FinancierWaitingAnchorUserReturnModificationRequest>();
  const { register, reset, getValues, errors, setError, clearErrors } =
    useForm<FinancierWaitingAnchorUserReturnModificationRequest>({
      mode: 'onSubmit',
      shouldFocusError: true,
    });

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  // Confirm Modal
  const showConfirmModal = (e: any) => {
    e.preventDefault();
    const submitChangeConfirm = async () => {
      await requestWaitingAnchorUserApprove(waitingAnchorUserId);
    };

    const confirmModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeConfirm()
          .then(() => {
            confirmOKModal();
          })
          .catch(e => {
            errorAlert.show(e);
          });
      },
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_approve_the_registration_request?')}
        <br />
        {t('text:The_anchor_user_registration_will_be_completed_after_the_request_is_approved')}
      </h6>,
      confirmModalOptions,
    );
  };

  const confirmOKModal = () => {
    const confirmOKModalOptions: ModalOptions = {
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        fetchAll();
      },
    };

    modal.show(<h6>{t('text:Registration_has_been_completed_successfully')}</h6>, confirmOKModalOptions);
  };

  // Return Modal
  const showReturnModal = () => {
    let reason = '';

    const submitChangeReturn = async (returnReason: string) => {
      await requestWaitingAnchorUserReturn(waitingAnchorUserId, returnReason);
    };
    const returnModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeReturn(reason)
          .then(() => {
            returnOKModal();
          })
          .catch(e => {
            errorAlert.show(e, {
              closeBtnCb: () => showReturnModal(),
            });
          });
      },
    };
    modal.show(
      <>
        <h6>
          {t('text:Would_you_like_to_revert_the_registration_request?')}
          <br />
          {t('text:Please_enter_the_reason_for_the_revert_below')}
        </h6>
        <ReasonModal
          modalId={modal.id}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
          required
        />
      </>,
      returnModalOptions,
    );
  };

  const returnOKModal = () => {
    const confirmOKModalOptions: ModalOptions = {
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        fetchAll();
      },
    };

    modal.show(<h6>{t('text:The_approval_request_has_been_reverted')}</h6>, confirmOKModalOptions);
  };

  // Cancel Modal
  const showCancelRequestModal = (e: any) => {
    e.preventDefault();
    const submitChangeCancel = async () => {
      await requestWaitingAnchorUserCancel(waitingAnchorUserId);
    };
    const returnModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Close'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeCancel()
          .then(() => {
            fetchAll();
          })
          .catch(e => {
            errorAlert.show(e);
          });
      },
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      returnModalOptions,
    );
  };

  // Return 수정
  const showReturnModificationModal = (e: any) => {
    e.preventDefault();

    const submitChangeReturnModification = async () => {
      const data = getValues();
      try {
        requestDTOParser(data);

        await requestWaitingAnchorUserReturnModification(waitingAnchorUserId, data);

        showReturnModificationOKModal();
      } catch (error: any) {
        modal.show(error);

        formErrorHandler<FinancierWaitingAnchorUserReturnModificationRequest>(error, setError, clearErrors);

        if (error.code === AnchorUserExceptionCode.INVALID_TARGET_AUTHORITY) {
          setError('authorityType', {});
        }
      }
    };

    const returnModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async () => {
        returnedConfirmRequestModal.close(returnedConfirmRequestModal.id);
        submitChangeReturnModification();
      },
    };
    returnedConfirmRequestModal.show(
      <h6>{t('text:Would_you_like_to_request_approval_for_registration?')}</h6>,
      returnModalOptions,
    );
  };

  const showReturnModificationOKModal = () => {
    const returnModalOptions: ModalOptions = {
      modalType: ModalType.ALERT,
      title: t('text:Notice'),
      confirmBtnText: t('text:OK'),
      closeBtnCb: async () => {
        try {
          fetchAll();
        } catch (e) {
          errorAlert.show(e);
        }
      },
    };
    modal.show(
      <h6>
        {t('text:Request_for_registration_approval_has_been_completed')}
        <br />
        {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
      </h6>,
      returnModalOptions,
    );
  };

  async function fetchAll() {
    try {
      const fetchedAnchorUserWaitingDetail = await requestWaitingAnchorUserDetail(waitingAnchorUserId);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorUserWaitingDetail(fetchedAnchorUserWaitingDetail);
        // input 값 설정
        reset({
          bankUserId: fetchedAnchorUserWaitingDetail?.bankUserId,
          name: fetchedAnchorUserWaitingDetail?.name,
          mobile: fetchedAnchorUserWaitingDetail?.mobile,
          email: fetchedAnchorUserWaitingDetail?.email,
          authorityType: fetchedAnchorUserWaitingDetail?.authorityType,
          anchorUserOtpSerialNo: fetchedAnchorUserWaitingDetail.anchorUserOtpSerialNo,
        });
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const conditionChecker = (
    conditionAuthority?: AUTHORITY_TYPE,
    conditionApprovalType?: COMMON_APPROVAL_TYPE,
  ): boolean => {
    if (conditionAuthority && signInModel?.authorityType !== conditionAuthority) {
      return false;
    }

    return !(conditionApprovalType && anchorUserWaitingDetail?.approvalType !== conditionApprovalType);
  };

  const isOperatorAndReturned = conditionChecker(AUTHORITY_TYPE.OPERATOR, COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED);

  const getStatusUserInfo = (): string => {
    switch (anchorUserWaitingDetail?.approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return `${t('text:Operator')} (${anchorUserWaitingDetail?.operatorUserName} / ${
          anchorUserWaitingDetail?.operatorUserLoginId
        })`;
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
      case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
        return `${t('text:Authorizer')} (${anchorUserWaitingDetail?.authorizerUserName} / ${
          anchorUserWaitingDetail?.authorizerUserLoginId
        })`;
      default:
        return '';
    }
  };

  const getStatusDateText = () => {
    switch (anchorUserWaitingDetail?.approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return t('text:Requested_Date');
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return t('text:Reverted_Date');
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return t('text:Cancelled_Date');
      case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
        return t('text:Registered_Date');
      default:
        return '';
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_User_Information_Details')} />

      {conditionChecker(AUTHORITY_TYPE.AUTHORIZER, COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) && (
        <GuideMessage
          message={[
            t('text:Please_check_the_anchor_user_information_requested_for_approval'),
            t('text:Check_the_anchor_user_information_and_proceed_with_the_approval'),
          ]}
        />
      )}
      {conditionChecker(AUTHORITY_TYPE.OPERATOR, COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) && (
        <GuideMessage
          message={[
            t('text:Check_the_anchor_user_information_that_has_been_requested_for_approval'),
            t('text:Click_on_the_Cancel_Request_button_at_the_bottom_to_cancel_the_request'),
          ]}
        />
      )}
      {conditionChecker(undefined, COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED) && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_approval_request_has_been_reverted'),
            t('text:Please_check_the_reasons_below_for_the_details'),
          ]}
          reasonTitle={t('text:REASON_FOR_REVERT')}
          reason={anchorUserWaitingDetail?.returnReason}
        />
      )}
      <div className="content-area">
        <Accordion
          id="waiting-user-detail"
          className={`accordion accordion-information-form ${
            isOperatorAndReturned && 'accordion-information-form__editable-border'
          }`}
          defaultExpanded={true}
        >
          <Accordion.Header id="waiting-user-detail-header">
            <Accordion.Trigger>
              <div className="accordion-information-form__head">
                <div className={getStatusBadgeClass('COMMON_APPROVAL_TYPE', anchorUserWaitingDetail?.approvalType)}>
                  {t(`code:common-approval-type.${anchorUserWaitingDetail?.approvalType}`)}
                </div>
                <div className="accordion-information-form__head--text">
                  <div className="fw-bold">{getStatusUserInfo()}</div>
                  {anchorUserWaitingDetail?.financierName}
                </div>
                <div className="accordion-information-form__head--text">
                  <div className="fw-bold">{getStatusDateText()}</div>
                  {t('format:datetime', { value: anchorUserWaitingDetail?.updatedDateTime, key: 'date' })}
                </div>
              </div>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <FormBorder>
              <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_INFORMATION')} />
              <FormContents>
                <div className="row">
                  <FormValue
                    label={t('text:Client_Code')}
                    className="information-form__input"
                    value={anchorUserWaitingDetail?.anchorClientCode}
                  />
                  <FormValue
                    label={t('text:Anchor_Name')}
                    className="information-form__input"
                    value={anchorUserWaitingDetail?.anchorClientName}
                  />
                </div>
              </FormContents>
              <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_USER_INFORMATION')} />
              <FormContents>
                <div className="row">
                  <FormInput
                    label={t('text:User_Code')}
                    name={getProperty('bankUserId')}
                    ref={register}
                    requiredOptions={{ required: true }}
                    error={errors.bankUserId}
                    disabled={!isOperatorAndReturned}
                  />
                  <FormInput
                    label={t('text:User_Name')}
                    name={getProperty('name')}
                    ref={register}
                    requiredOptions={{ required: true }}
                    error={errors.name}
                    disabled={!isOperatorAndReturned}
                  />
                </div>
                <div className="row">
                  <FormInput
                    label={t('text:Mobile')}
                    name={getProperty('mobile')}
                    ref={register}
                    requiredOptions={{ required: true }}
                    error={errors.mobile}
                    disabled={!isOperatorAndReturned}
                  />
                  <FormInput
                    label={t('text:Email')}
                    name={getProperty('email')}
                    ref={register}
                    requiredOptions={{ required: true }}
                    error={errors.email}
                    disabled={!isOperatorAndReturned}
                  />
                </div>
                <div className="row">
                  <FormInput
                    label={t('text:OTP_Number')}
                    name={getProperty('anchorUserOtpSerialNo')}
                    ref={register}
                    error={errors.anchorUserOtpSerialNo}
                    disabled={!isOperatorAndReturned}
                  />
                  <FormSelect
                    label={t('text:Authority')}
                    name={getProperty('authorityType')}
                    ref={register}
                    required
                    selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', [
                      AUTHORITY_TYPE.ADMIN,
                      AUTHORITY_TYPE.AUTHORIZER,
                      AUTHORITY_TYPE.OPERATOR,
                    ])}
                    error={errors.authorityType}
                    disabled={!isOperatorAndReturned}
                  />
                </div>
              </FormContents>
            </FormBorder>
          </Accordion.Content>
        </Accordion>
        <div className="flex-end mt-4">
          {conditionChecker(AUTHORITY_TYPE.AUTHORIZER, COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) && (
            <>
              <Button
                size={ButtonSizeEnum.LG}
                onClick={showReturnModal}
                color={ButtonColorEnum.RED}
                variant={ButtonVariantEnum.OUTLINED}
              >
                {t('text:Revert')}
              </Button>
              <Button size={ButtonSizeEnum.LG} onClick={showConfirmModal} className="ms-2">
                {t('text:Confirm')}
              </Button>
            </>
          )}
          {conditionChecker(AUTHORITY_TYPE.OPERATOR, COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) && (
            <Button
              size={ButtonSizeEnum.LG}
              onClick={showCancelRequestModal}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel_Request')}
            </Button>
          )}
          {isOperatorAndReturned && (
            <>
              <Button
                size={ButtonSizeEnum.LG}
                onClick={showCancelRequestModal}
                color={ButtonColorEnum.SECONDARY}
                variant={ButtonVariantEnum.OUTLINED}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.LG} onClick={showReturnModificationModal} className="ms-2">
                {t('text:Confirm_Request')}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FinancierManageAnchorUserWaitingDetail;
