import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { ANCHOR_AGREEMENT_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { AnchorFinancingOptionVOModel } from 'models/vo/AnchorFinancingOptionVO';

interface AnchorFinancingOptionListTabProps {
  anchorFinancingOptionList: AnchorFinancingOptionVOModel[] | undefined;
  searchFinancingOptionNumber: string;
  onBlurUpdateFinancingOptionNumber(e: any): void;
  handleClickAnchorFinancingOptionSearch(e: any): void;
  pageable: PageableType;
  paginateFinancingOptionList(page: number, sizePerPage: number): void;
}

function AnchorFinancingOptionListTab({
  anchorFinancingOptionList,
  searchFinancingOptionNumber,
  onBlurUpdateFinancingOptionNumber,
  handleClickAnchorFinancingOptionSearch,
  pageable,
  paginateFinancingOptionList,
}: AnchorFinancingOptionListTabProps) {
  const { t } = useTranslation();

  const ANCHOR_FINANCING_OPTION_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Sub-Contract_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Responsible_Bank_Branch'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Expired_Date'),
    },
    {
      headerText: t('text:Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    const getAnchorAgreementStatusClassName = (anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS) => {
      switch (anchorAgreementStatus) {
        case ANCHOR_AGREEMENT_STATUS.ACTIVATED:
          return 'text-bold-dark-green';
        case ANCHOR_AGREEMENT_STATUS.SUSPENDED:
          return 'text-bold-brick-red';
        default:
          return '';
      }
    };

    return anchorFinancingOptionList?.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.financierName} />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.branchName} />
          <Td data={item.anchorAgreementStartDate} format="date" />
          <Td data={item.anchorAgreementExpiryDate} format="date" />
          <Td
            data={t(`code:anchor-agreement-status.${item?.anchorAgreementStatus}`)}
            className={getAnchorAgreementStatusClassName(item.anchorAgreementStatus)}
            format="code"
          />
          <TdLink
            path={ROUTES_AC.COMPANY.FINANCING_OPTION_DETAIL_BUILD_PATH(item.anchorFinancingOptionId)}
            state={{
              supportedCollateralType: item.collateralType,
            }}
          />
        </Tr>
      );
    });
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Anchor_Financing_Option_List')} />
      <form>
        <div className="d-flex information-filter mt-0">
          <input
            className="only-input"
            type="text"
            placeholder={t('text:Sub-Contract_Number')}
            onBlur={onBlurUpdateFinancingOptionNumber}
            style={{ width: '220px' }}
            defaultValue={searchFinancingOptionNumber}
          />
          <Button onClick={handleClickAnchorFinancingOptionSearch}>{t('text:Search')}</Button>
        </div>
      </form>

      <TableBorder>
        <TableHeader header={ANCHOR_FINANCING_OPTION_TABLE_HEADER} />
        <TableBody numOfCol={ANCHOR_FINANCING_OPTION_TABLE_HEADER.length}>{renderResultTable()}</TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginateFinancingOptionList} />
    </div>
  );
}

export default AnchorFinancingOptionListTab;
