import type { DOCUMENT_CONDITION_AMOUNT_RANGE, DOCUMENT_CONDITION_TYPE } from 'enums';
import { CURRENCY_TYPE, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface LoanAdditionalDocumentConditionVO {
  conditionId: number;
  required: boolean;
  useSpecificCondition: boolean;
  documentConditionType: DOCUMENT_CONDITION_TYPE;
  documentConditionAmountRange: DOCUMENT_CONDITION_AMOUNT_RANGE;
  amount: string;
  currencyType: string;
  achieved: boolean;
}

export interface LoanAdditionalDocumentConditionVOModel extends LoanAdditionalDocumentConditionVO {
  amount: BigNumber;
  currencyType: CURRENCY_TYPE;
}

export function formattingToLoanAdditionalDocumentConditionVOModel(
  data: LoanAdditionalDocumentConditionVO,
): LoanAdditionalDocumentConditionVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
