import { useEffect } from 'react';
import type { FieldError } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import { FormErrorMessage } from 'components/stateless/CommonForm/FormErrorMessage';
import FormInput from 'components/stateless/CommonForm/FormInput';
import useFinancierHolidays from 'hooks/useFinancierHolidays';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import type { FinancierExecuteLoanDisbursementRequest } from 'utils/http/api/financier/loans/request';
import { getExpectedInterestRate } from 'utils/logic';

interface FinancierFinancingDetailFactoringExecuteLoanDisbursementModalProps {
  loanDetailData: LoanDetailVOModel;
  getSubmitData(data: FinancierExecuteLoanDisbursementRequest): void;
  modalInfo: {
    id: number;
    disableConfirmBtn: (id: number, disabled: boolean) => void;
  };
  isLoanExecutionImpossibleToday: boolean;
  repaymentDatePrev: Date;
}

function FinancierFinancingDetailFactoringExecuteLoanDisbursementModal({
  loanDetailData,
  getSubmitData,
  isLoanExecutionImpossibleToday,
  repaymentDatePrev,
}: FinancierFinancingDetailFactoringExecuteLoanDisbursementModalProps) {
  const { register, watch, getValues, errors, control, reset } = useForm<FinancierExecuteLoanDisbursementRequest>();

  const {
    desiredDisburseDate,
    principalAmount,
    requestedDateTime,
    createdBasisInterestRate: basisInterestRate,
    createdCreditSpreadInterestRate: creditInterestRate,
    createdTermSpreadInterestRate: termSpreadRate,
    createdPreferentialInterestRate: preferentialInterestRate,
  } = loanDetailData;

  const { t } = useTranslation(['format']);

  const { financierLoanId } = watch();

  useEffect(() => {
    reset({
      disbursedDate: desiredDisburseDate,
      disbursedTotalInterestRate: Number(
        getExpectedInterestRate({ basisInterestRate, creditInterestRate, termSpreadRate, preferentialInterestRate }) ??
          0,
      ),
      disbursedAmount: principalAmount,
    });
  }, [loanDetailData, reset]);

  useEffect(() => {
    getSubmitData(getValues());
  }, [financierLoanId, getSubmitData, getValues]);

  const minDate = new Date(requestedDateTime) > new Date() ? new Date() : new Date(requestedDateTime);
  const maxDate = repaymentDatePrev < new Date() ? repaymentDatePrev : new Date();

  const { financierHolidays } = useFinancierHolidays(
    Number(getDayTerm(convertToServerDateFormat(minDate), convertToServerDateFormat(maxDate))) + 1,
    dayjs(minDate).format('YYYY-MM-DD'),
    dayjs(maxDate).format('YYYY-MM-DD'),
  );

  return (
    <FormBorder editable={true}>
      <FormContents>
        <div className="row">
          <FormInput
            col={12}
            placeholder=""
            label={t('text:Financier_Financing_ID')}
            name="financierLoanId"
            ref={register}
            error={errors.financierLoanId}
          />
        </div>
        <div className="row">
          <FormDatePickerInput
            col={12}
            label={t('text:Disbursed_Date')}
            name="disbursedDate"
            control={control}
            minDate={minDate}
            maxDate={maxDate}
            excludeDates={financierHolidays}
            error={errors.disbursedDate}
            disabled={true}
          />
          {isLoanExecutionImpossibleToday && (
            <FormErrorMessage
              error={
                {
                  message: `${t('text:Today_is_not_available_for_disbursement')} ${t(
                    'text:Please_process_the_disbursement_on_the_scheduled_disbursement_date',
                  )}`,
                } as FieldError
              }
            />
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <label className="information-form__label">{t('text:Discount_Rate_APR')}</label>
            <div className="d-flex">
              <NumericFormatInput
                control={control}
                numberType="float"
                name="disbursedTotalInterestRate"
                className="information-form__input text-end"
                disabled={true}
              />
              <div className="ms-2 mt-1">%</div>
            </div>
          </div>
        </div>

        <div className="row">
          <NumericFormatInput
            control={control}
            name="disbursedAmount"
            numberType="bigNumber"
            label={t('text:Approved_Financing_Amount')}
            col={12}
            placeholder=""
            textAlign="text-end"
            error={errors.disbursedAmount}
            disabled={true}
          />
        </div>
      </FormContents>
    </FormBorder>
  );
}

export default FinancierFinancingDetailFactoringExecuteLoanDisbursementModal;
