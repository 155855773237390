import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Accordion from 'components/stateless/Accordion/Accordion';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ANCHOR_AGREEMENT_STATUS, AUTHORITY_TYPE, COLLATERAL_TYPE, COMMON_APPROVAL_TYPE, LOAN_OPTION } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import type { WaitingAnchorAgreementDetailVOModel } from 'models/vo/WaitingAnchorAgreementDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { requestSystemWaitingAnchorAgreementData } from 'utils/http/api/system/waiting-anchor-agreements';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const ANCHOR_USER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
    },
    {
      headerText: t('text:Name'),
    },
    {
      headerText: t('text:Mobile'),
    },
    {
      headerText: t('text:Email'),
    },
    {
      headerText: t('text:Authority'),
    },
  ];

  return {
    mounted,
    modal,
    t,
    ANCHOR_USER_TABLE_HEADERS,
  };
};

function SystemMonitorAnchorAgreementWaitingDetail(): JSX.Element {
  const { mounted, modal, t, ANCHOR_USER_TABLE_HEADERS } = getConstants();
  const { waitingAnchorAgreementId } = useParams() as any;
  const [locationState, errorHandlerOfLocationState] = useLocationState<{ supportedCollateralType: COLLATERAL_TYPE }>([
    'supportedCollateralType',
  ]);
  const { supportedCollateralType } = locationState;

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const [isRegister, setIsRegister] = useState<boolean>(false);

  const [waitingAnchorAgreementData, setWaitingAnchorAgreementData] = useState<WaitingAnchorAgreementDetailVOModel>();
  const [divisionName, setDivisionName] = useState('');

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const waitingAnchorAgreementData = await requestSystemWaitingAnchorAgreementData(waitingAnchorAgreementId);
      const { anchorAgreementId, divisionName } = waitingAnchorAgreementData;

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorAgreementData(waitingAnchorAgreementData);
        setIsRegister(anchorAgreementId === null || anchorAgreementId === undefined);
        setDivisionName(divisionName);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getApprovalTypeText = (approvalType?: COMMON_APPROVAL_TYPE): string => {
    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return t('text:Requested');
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return t('text:Request_Reverted');
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return t('text:Request_Cancelled');
      case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
        return t('text:Approved');
      default:
        return '';
    }
  };

  const getApprovalTypeTimeText = (approvalType?: COMMON_APPROVAL_TYPE): string => {
    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return t('text:Requested_Time');
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return t('text:Request_Reverted_Time');
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return t('text:Request_cancelled_time');
      case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
        return t('text:Registered_Time');
      default:
        return '';
    }
  };

  const getCurrentActorInfoText = (approvalType: COMMON_APPROVAL_TYPE): string => {
    const defaultUserName = t('text:Username');
    const defaultUserID = t('text:User_ID');

    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return `${t('text:Authorizer')} (${
          waitingAnchorAgreementData?.authorizerUserName
            ? waitingAnchorAgreementData?.authorizerUserName
            : defaultUserName
        } / ${
          waitingAnchorAgreementData?.authorizerUserLoginId
            ? waitingAnchorAgreementData?.authorizerUserLoginId
            : defaultUserID
        })`;
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return `${t('text:Operator')} (${
          waitingAnchorAgreementData?.operatorUserName ? waitingAnchorAgreementData?.operatorUserName : defaultUserName
        } / ${
          waitingAnchorAgreementData?.operatorUserLoginId
            ? waitingAnchorAgreementData?.operatorUserLoginId
            : defaultUserID
        })`;
    }
  };

  const getProgramTypeText = (collateralType: COLLATERAL_TYPE): string => {
    switch (collateralType) {
      case COLLATERAL_TYPE.AR:
        return t('text:Vendor_Finance');
      case COLLATERAL_TYPE.INVOICE:
        return t('text:Dealer_Finance');
    }
  };

  const renderAdminInformationTable = (): JSX.Element[] | undefined => {
    const getAuthorityTypeText = (authorityType: AUTHORITY_TYPE): string => {
      switch (authorityType) {
        case AUTHORITY_TYPE.ADMIN:
          return t('text:Admin');
        case AUTHORITY_TYPE.AUTHORIZER:
          return t('text:Authorizer');
        case AUTHORITY_TYPE.OPERATOR:
          return t('text:Operator');
        default:
          return '';
      }
    };

    return waitingAnchorAgreementData?.anchorUserList.map((item, index) => (
      <Tr key={index}>
        <Td data={item.bankUserId} />
        <Td data={item.name} />
        <Td data={item.mobile} />
        <Td data={item.email} />
        <Td data={getAuthorityTypeText(item.authorityType)} />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Master_Agreement_Details')} />
      <div className="content-area">
        {waitingAnchorAgreementData && (
          <Accordion
            id="waiting-agreement-detail"
            className="accordion accordion-information-form"
            defaultExpanded={true}
          >
            <Accordion.Header id="waiting-agreement-detail-header">
              <Accordion.Trigger>
                <div className="accordion-information-form__head">
                  <div
                    className={getStatusBadgeClass('COMMON_APPROVAL_TYPE', waitingAnchorAgreementData?.approvalType)}
                  >
                    {getApprovalTypeText(waitingAnchorAgreementData?.approvalType)}
                  </div>
                  <div className="accordion-information-form__head--text">
                    <div className="fw-bold">{getCurrentActorInfoText(waitingAnchorAgreementData?.approvalType)}</div>
                    {waitingAnchorAgreementData?.financierName
                      ? waitingAnchorAgreementData.financierName
                      : 'financierName'}
                  </div>
                  <div className="accordion-information-form__head--text">
                    <div className="fw-bold">{getApprovalTypeTimeText(waitingAnchorAgreementData.approvalType)}</div>
                    {t('format:datetime', {
                      value: waitingAnchorAgreementData?.updatedDateTime,
                      key: 'datetime',
                    })}
                  </div>
                </div>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="accordion-body p-0">
              <FormBorder>
                <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_INFORMATION')} />
                <FormContents>
                  <div className="row">
                    <FormValue label={t('text:Client_Code')} value={waitingAnchorAgreementData.anchorClientCode} />
                    <FormValue label={t('text:Anchor_Name')} value={waitingAnchorAgreementData.anchorClientName} />
                  </div>
                  <div className="row">
                    <FormValue label={t('text:Division_Code')} value={waitingAnchorAgreementData.division} />
                    <FormValue label={t('text:Division_Name')} value={divisionName} />
                  </div>
                </FormContents>
                <FormSubtitle
                  backGroundType={BackGroundType.DarkGray}
                  title={t('text:ANCHOR_MASTER_AGREEMENT_INFORMATION')}
                />
                <FormContents>
                  <div className="row">
                    <FormValue
                      label={t('text:Anchor_Master_Agreement_Number')}
                      value={waitingAnchorAgreementData.contractNo}
                    />
                    <FormValue col={3} label={t('text:Currency')} value={waitingAnchorAgreementData.currencyType} />
                    <FormValue
                      col={3}
                      label={t('text:Program_Type')}
                      value={getProgramTypeText(waitingAnchorAgreementData.collateralType)}
                    />
                  </div>
                  <div className="row">
                    <FormValue
                      label={isInvoice ? `${t('text:Financing_to_Invoice_Ratio')} (%)` : t('text:Financing_LTV(%)')}
                      value={waitingAnchorAgreementData.maxLtvRatio}
                    />
                    <FormValue
                      label={t('text:Effective_Date')}
                      col={3}
                      value={waitingAnchorAgreementData.startDate}
                      format="date"
                    />
                    <FormValue
                      label={t('text:Expiration_Date')}
                      col={3}
                      value={waitingAnchorAgreementData.expiryDate}
                      format="date"
                    />
                  </div>
                  <div className="row">
                    <FormRadioWrap label={t('text:Allow_the_partners_automatic_financing_option?')}>
                      <FormRadio
                        label={t('text:Yes')}
                        defaultChecked={waitingAnchorAgreementData.loanOption !== LOAN_OPTION.NONE}
                        disabled
                      >
                        {waitingAnchorAgreementData.loanOption !== LOAN_OPTION.NONE && (
                          <>
                            <span style={{ color: '#ada8a8', fontWeight: 'normal' }} className="me-1">
                              {t('text:Allow_Financiers_Automatic_Approval?')}
                            </span>
                            {`( ${t(`code:loan-option.${waitingAnchorAgreementData.loanOption}`)} )`}
                          </>
                        )}
                      </FormRadio>
                      <FormRadio
                        label={t('text:No')}
                        defaultChecked={waitingAnchorAgreementData.loanOption === LOAN_OPTION.NONE}
                        disabled
                      />
                    </FormRadioWrap>
                    <FormRadioWrap label={t('text:Allow_the_payment_date_to_exceed_the_agreement_expiration_date?')}>
                      <FormRadio
                        label={t('text:Yes')}
                        defaultChecked={waitingAnchorAgreementData.settlementDateOverAllowable}
                        disabled
                      />
                      <FormRadio
                        label={t('text:No')}
                        defaultChecked={!waitingAnchorAgreementData.settlementDateOverAllowable}
                        disabled
                      />
                    </FormRadioWrap>
                  </div>

                  {isInvoice && (
                    <div className="row">
                      <FormRadioWrap label={t('text:Allow_registration_of_duplicate_reference_numbers?')}>
                        <FormRadio
                          label={t('text:Yes')}
                          defaultChecked={!waitingAnchorAgreementData.referenceNumberDuplicationCheck}
                          disabled
                        />
                        <FormRadio
                          label={t('text:No')}
                          defaultChecked={waitingAnchorAgreementData.referenceNumberDuplicationCheck}
                          disabled
                        />
                      </FormRadioWrap>
                      <FormRadioWrap label={t('text:Require_BOE_number_when_registering_invoices?')}>
                        <FormRadio
                          label={t('text:Yes')}
                          defaultChecked={waitingAnchorAgreementData?.billOfExchangeNoRequired}
                          disabled
                        />
                        <FormRadio
                          label={t('text:No')}
                          defaultChecked={!waitingAnchorAgreementData?.billOfExchangeNoRequired}
                          disabled
                        />
                      </FormRadioWrap>
                    </div>
                  )}
                  <div className="row">
                    {!isRegister && (
                      <FormRadioWrap label={t('text:Agreement_Suspension')}>
                        <FormRadio
                          label={t('text:Not_Suspended')}
                          defaultChecked={
                            waitingAnchorAgreementData.anchorAgreementStatus === ANCHOR_AGREEMENT_STATUS.ACTIVATED
                          }
                          disabled
                        />
                        <FormRadio
                          label={t('text:Suspended')}
                          defaultChecked={
                            waitingAnchorAgreementData.anchorAgreementStatus === ANCHOR_AGREEMENT_STATUS.SUSPENDED
                          }
                          disabled
                        />
                      </FormRadioWrap>
                    )}
                  </div>
                  <div className="row">
                    <FormValue
                      label={t('text:Partner_Identification_Type')}
                      value={t(`code:dealer-identifier-type.${waitingAnchorAgreementData.dealerIdentifierType}`)}
                      format="code"
                    />
                  </div>
                </FormContents>
                {isAr && (
                  <>
                    <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:AR_FINANCING_INFORMATION')} />
                    <FormContents>
                      <div className="row">
                        <FormRadioWrap label={t('text:Automatic_Settlement')}>
                          <FormRadio
                            label={t('text:Yes_(Co-operation_&_Payment)')}
                            defaultChecked={waitingAnchorAgreementData.paymentSupport}
                            disabled
                          />
                          <FormRadio
                            label={t('text:No_(Co-operation)')}
                            defaultChecked={!waitingAnchorAgreementData.paymentSupport}
                            disabled
                          />
                        </FormRadioWrap>
                      </div>
                      <div className="row">
                        <FormRadioWrap label={t('text:Limit_of_AR_Issuance')}>
                          <FormRadio
                            label={t('text:Applied')}
                            defaultChecked={waitingAnchorAgreementData.collateralIssuedLimitCheck}
                            disabled
                          >
                            {waitingAnchorAgreementData.collateralIssuedLimitCheck &&
                              `( ${t('format:number', {
                                value: waitingAnchorAgreementData.collateralIssuedLimitAmount,
                              })} )`}
                          </FormRadio>
                          <FormRadio
                            label={t('text:Not_Applied')}
                            defaultChecked={!waitingAnchorAgreementData.collateralIssuedLimitCheck}
                            disabled
                          />
                        </FormRadioWrap>
                        <FormRadioWrap label={t('text:Financing_Limit_of_Anchor')}>
                          <FormRadio
                            label={t('text:Applied')}
                            defaultChecked={waitingAnchorAgreementData.loanLimitCheck}
                            disabled
                          >
                            {waitingAnchorAgreementData.loanLimitCheck &&
                              `( ${t('format:number', { value: waitingAnchorAgreementData.loanLimitAmount })} )`}
                          </FormRadio>
                          <FormRadio
                            label={t('text:Not_Applied')}
                            defaultChecked={!waitingAnchorAgreementData.loanLimitCheck}
                            disabled
                          />
                        </FormRadioWrap>
                      </div>
                    </FormContents>
                  </>
                )}
                <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:DESIGNATED_ACCOUNT')} />
                <FormContents>
                  <div className="row">
                    <FormValue
                      label={t('text:Bank_Name')}
                      value={waitingAnchorAgreementData.settlementAccountFinancierName}
                    />
                    <FormValue
                      label={t('text:Branch_Name')}
                      value={waitingAnchorAgreementData.settlementAccountBranchName}
                    />
                  </div>
                  <div className="row">
                    <FormValue
                      label={t('text:Bank_Account_Number')}
                      value={waitingAnchorAgreementData.settlementAccount}
                    />
                    <FormValue
                      label={t('text:Bank_Account_Holder')}
                      value={waitingAnchorAgreementData.settlementAccountOwner}
                    />
                  </div>
                </FormContents>
                <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:RESPONSIBLE_BRANCH')} />
                <FormContents>
                  <div className="row">
                    <FormValue label={t('text:Branch_Code')} value={waitingAnchorAgreementData.branchCode} />
                    <FormValue label={t('text:Branch_Name')} value={waitingAnchorAgreementData.branchName} />
                  </div>
                  <div className="row">
                    <FormValue label={t('text:Telephone')} value={waitingAnchorAgreementData.branchTelephone} />
                    <FormValue label={t('text:Fax')} value={waitingAnchorAgreementData.branchFax} />
                  </div>
                  <div className="row">
                    <FormValue
                      col={12}
                      label={t('text:Registered_Office_Address')}
                      value={waitingAnchorAgreementData.branchAddress}
                    />
                  </div>
                </FormContents>
                <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ADMIN_INFORMATION')} />
                <FormContents>
                  <div className="row">
                    <div className="col-12">
                      <TableBorder>
                        <TableHeader header={ANCHOR_USER_TABLE_HEADERS} />
                        <TableBody numOfCol={ANCHOR_USER_TABLE_HEADERS.length}>
                          {renderAdminInformationTable()}
                        </TableBody>
                      </TableBorder>
                    </div>
                  </div>
                </FormContents>
              </FormBorder>
            </Accordion.Content>
          </Accordion>
        )}
      </div>
    </>
  );
}

export default SystemMonitorAnchorAgreementWaitingDetail;
