import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'utils/modal/useModal';

export default function useErrorHandler() {
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const errorHandler = useCallback((o: object) => {
    for (const value of Object.values(o)) {
      if (value.type && value.type === 'required') {
        modal.show(t('text:Please_enter_the_user_ID_or_Password_again'));
        break;
      }
    }
  }, []);

  return errorHandler;
}
