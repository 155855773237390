import { Route, Switch } from 'react-router-dom';

import PPFooter from 'components/potential-partner/PPFooter/PPFooter';
import PPHeader from 'components/potential-partner/PPHeader/PPHeader';
import { ROUTES_PP } from 'constants/routes/potentialPartner';
import PotentialPartnerApplicationPage from 'pages/potential-partner/application/PotentialPartnerApplicationPage';
import PotentialPartnerApplicationCompletePage from 'pages/potential-partner/application/steps/complete/PotentialPartnerApplicationCompletePage';
import PotentialPartnerArDetailPage from 'pages/potential-partner/ar-detail/PotentialPartnerArDetailPage';
import PotentialPartnerFinancierDetailPage from 'pages/potential-partner/financier-detail/PotentialPartnerFinancierDetailPage';
import PotentialPartnerHomePage from 'pages/potential-partner/home/PotentialPartnerHomePage';
import PotentialPartnerSubmissionDetailPage from 'pages/potential-partner/submission-detail/PotentialPartnerSubmissionDetailPage';
import { ScrollToTop } from 'utils/scrollToTop';

import 'pages/potential-partner/PotentialPartnerPage.scss';

function PotentialPartnerAdminRouter() {
  return (
    <div className="pp-wrapper">
      <PPHeader />
      <ScrollToTop container="pp-wrapper" />
      <Switch>
        <Route exact path={ROUTES_PP.HOME} component={PotentialPartnerHomePage} />
        <Route path={ROUTES_PP.AR_DETAIL} component={PotentialPartnerArDetailPage} />
        <Route exact path={ROUTES_PP.APPLICATION.RESULT} component={PotentialPartnerApplicationCompletePage} />
        <Route exact path={ROUTES_PP.APPLICATION.STEP} component={PotentialPartnerApplicationPage} />
        <Route exact path={ROUTES_PP.MY_INFO.SUBMISSION_DETAIL} component={PotentialPartnerSubmissionDetailPage} />
        <Route exact path={ROUTES_PP.MY_INFO.FINANCIER_DETAIL} component={PotentialPartnerFinancierDetailPage} />
      </Switch>
      <PPFooter />
    </div>
  );
}

export default PotentialPartnerAdminRouter;
