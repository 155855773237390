export interface FactoringAttachmentVO {
  transactionNo?: string;
  loanAgreementAttachment: string;
  loanFactoringNotificationAttachment: string;
}

export interface FactoringAttachmentVOModel extends FactoringAttachmentVO {}

export function formattingToFactoringAttachmentVOModel(data: FactoringAttachmentVO): FactoringAttachmentVOModel {
  return {
    ...data,
  };
}
