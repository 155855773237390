import type { FINANCIER_CLIENT_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { FinancierClientVO, FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { formattingToFinancierClientVOModel } from 'models/vo/FinancierClientVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';
import type {
  FinancierClientListRequest,
  FinancierClientRegisterRequest,
  FinancierClientUpdateRequest,
} from 'utils/http/api/financier/financier-clients/requests';

/*
  /api/v1/fi/financier-clients :: GET :: 등록 완료 목록(완료 목록)
*/
export async function requestFinancierClientList(
  pageNumber: number,
  rowCount: number,
  financierClientListRequest: FinancierClientListRequest,
): Promise<Pageable<FinancierClientVOModel[]>> {
  const response = await http.get<Pageable<FinancierClientVO[]>>({
    url: API_FI.FINANCIER_CLIENTS.CLIENTS,
    data: {
      pageNumber,
      rowCount,
      ...financierClientListRequest,
    },
  });

  const financierManageAnchorList: FinancierClientVOModel[] = response.content.map((data: FinancierClientVO) =>
    formattingToFinancierClientVOModel(data),
  );

  const financierManageAnchorPage: Pageable<FinancierClientVOModel[]> = {
    ...response,
    content: financierManageAnchorList,
  };

  return financierManageAnchorPage;
}

/*
  /api/v1/fi/financier-clients/{financier-client-id} :: GET :: 등록 완료 상세(완료 상세), 등록 완료 수정(완료 수정), 등록 대기 상세(대기 상세)
*/
export async function requestFinancierClientDetail(financierClientId: number) {
  const response = await http.get<FinancierClientVO>({
    url: API_FI.FINANCIER_CLIENTS.CLIENT_DETAIL(financierClientId),
  });

  const financierClientData: FinancierClientVOModel = formattingToFinancierClientVOModel(response);

  return financierClientData;
}

/*
  /api/v1/fi/financier-clients/{financier-client-id} :: PUT :: 수정
*/
export async function requestFinancierClientUpdate(
  financierClientId: number,
  financierClientUpdateRequest: FinancierClientUpdateRequest,
) {
  const response = await http.put<FinancierClientVO>({
    url: API_FI.FINANCIER_CLIENTS.CLIENT_DETAIL(financierClientId),
    data: financierClientUpdateRequest,
  });

  const financierClientData: FinancierClientVOModel = formattingToFinancierClientVOModel(response);

  return financierClientData;
}

/*
  /api/v1/fi/financier-clients :: POST :: 등록
*/
export async function requestFinancierClientRegister(financierClientRegisterRequest: FinancierClientRegisterRequest) {
  const response = await http.post<FinancierClientVO>({
    url: API_FI.FINANCIER_CLIENTS.CLIENTS,
    data: financierClientRegisterRequest,
  });

  const financierClientData: FinancierClientVOModel = formattingToFinancierClientVOModel(response);

  return financierClientData;
}

/*
  /api/v1/fi/financier-clients/{financier-client-code}/client-by-financier-interface:code
  :: GET :: 기 등록된 전문(등록), 전문 조회(완료 수정), 대기 상세(전문 조회)
  고객 코드 조회
*/
export async function requestFinancierClientDetailByClientCode(
  financierClientCode: string,
  clientType: FINANCIER_CLIENT_TYPE,
) {
  const response = await http.get<FinancierClientVO>({
    url: API_FI.FINANCIER_CLIENTS.CLIENT_BY_FINANCIER_INTERFACE_CODE(financierClientCode),
    data: {
      financierClientType: clientType,
    },
  });

  const financierClientUpdatedData: FinancierClientVOModel = formattingToFinancierClientVOModel(response);

  return financierClientUpdatedData;
}

/*
  /api/v1/fi/financier-clients/{financier-client-code}/client-by-financier-interface:id
  :: GET :: 전문 - ID로 조회
*/
export async function requestFinancierClientDetailByClientId(financierClientId: number) {
  const response = await http.get<FinancierClientVO>({
    url: API_FI.FINANCIER_CLIENTS.CLIENT_BY_FINANCIER_INTERFACE_ID(financierClientId),
  });

  const financierClientUpdatedData: FinancierClientVOModel = formattingToFinancierClientVOModel(response);

  return financierClientUpdatedData;
}
