import type Pageable from 'models/Pageable';
import type { EarlyRepaymentRequestVO, EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import { formattingToEarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemEarlyRepaymentListRequest } from './requests';

export async function requestSystemEarlyRepaymentList(
  pageNumber: number,
  rowCount: number,
  data: SystemEarlyRepaymentListRequest,
): Promise<Pageable<EarlyRepaymentRequestVOModel[]>> {
  const response = await http.get<Pageable<EarlyRepaymentRequestVO[]>>({
    url: API_SY.EARLY_REPAYMENT_REQUESTS.EARLY_REPAYMENT_REQUESTS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToEarlyRepaymentRequestVOModel(item)),
  };
}

export async function requestSystemEarlyRepaymentDetail(
  earlyRepaymentRequestId: number,
): Promise<EarlyRepaymentRequestVOModel> {
  const response = await http.get<EarlyRepaymentRequestVO>({
    url: API_SY.EARLY_REPAYMENT_REQUESTS.EARLY_REPAYMENT_DETAIL(earlyRepaymentRequestId),
  });

  return formattingToEarlyRepaymentRequestVOModel(response);
}
