import { FINANCIER_CLIENT_STATUS, FINANCIER_CLIENT_TYPE, stringToEnum } from 'enums';

export interface FinancierClientVO {
  financierClientId: number;
  financierClientBusinessCode: string;
  financierClientTaxCode: string;
  financierClientCode: string;
  financierClientName: string;
  financierClientAddress: string;
  financierClientTelephone: string;
  financierClientFax: string;
  representativeName: string;
  representativeEmail: string;
  representativePosition: string;
  financierClientStatus: string;
  financierClientType: string;
  updatedDateTime: string;
  createdDateTime: string;
  enterpriseId: number;
  financierId: number;
  financierName: string;
  otpBypassed: boolean;
  autoSignUpForAnchorAdminOfVendorFinance: boolean;
}

export interface FinancierClientVOModel extends FinancierClientVO {
  financierClientStatus: FINANCIER_CLIENT_STATUS;
  financierClientType: FINANCIER_CLIENT_TYPE;
}

export function formattingToFinancierClientVOModel(data: FinancierClientVO): FinancierClientVOModel {
  return {
    ...data,
    financierClientStatus: stringToEnum(FINANCIER_CLIENT_STATUS, data.financierClientStatus),
    financierClientType: stringToEnum(FINANCIER_CLIENT_TYPE, data.financierClientType),
  };
}
