import { CURRENCY_TYPE } from 'enums';

export function getCurrencyByLocale() {
  const envLocale = process.env.REACT_APP_LOCALE;

  switch (envLocale) {
    case 'INDIA':
      return CURRENCY_TYPE.INR;
    case 'VIETNAM':
      return CURRENCY_TYPE.VND;
    case 'INDONESIA':
      return CURRENCY_TYPE.IDR;
    default:
      return CURRENCY_TYPE.USD;
  }
}
