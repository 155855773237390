import type Pageable from 'models/Pageable';
import type { InvoiceSummaryVO, InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import { formattingToInvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import type { LastInvoicePhaseVO, LastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import { formattingToLastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';
import type {
  AnchorInvoiceBulkRegisterRequest,
  AnchorInvoicePhaseApproveRequest,
  AnchorInvoiceRegisterRequest,
  AnchorInvoiceSummariesListRequest,
  AnchorRejectInvoiceRegistrationRequest,
  ApproveInvoiceBulkRequest,
} from 'utils/http/api/anchor/invoice-summaries/requests';

export async function requestAnchorInvoiceSummaryList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorInvoiceSummariesListRequest,
): Promise<Pageable<InvoiceSummaryVOModel[]>> {
  const response = await http.get<Pageable<InvoiceSummaryVO[]>>({
    url: API_AC.INVOICE_SUMMARIES.INVOICE_SUMMARY,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const invoiceSummaryList: InvoiceSummaryVOModel[] = response.content.map((data: InvoiceSummaryVO) =>
    formattingToInvoiceSummaryVOModel(data),
  );
  const invoiceSummaryPage: Pageable<InvoiceSummaryVOModel[]> = {
    ...response,
    content: invoiceSummaryList,
  };

  return invoiceSummaryPage;
}

// 수기 송장 등록
export async function requestAnchorInvoiceRegister(
  data: AnchorInvoiceRegisterRequest,
): Promise<LastInvoicePhaseVOModel[]> {
  const response = await http.post<LastInvoicePhaseVO[]>({
    url: API_AC.INVOICE_SUMMARIES.INVOICE_SUMMARY,
    data,
  });

  return response.map(item => formattingToLastInvoicePhaseVOModel(item));
}

// 송장 결재 요약 단건 조회
export async function requestAnchorInvoiceSummaryDetail(invoiceSummaryId: number): Promise<InvoiceSummaryVOModel> {
  const response = await http.get<InvoiceSummaryVO>({
    url: API_AC.INVOICE_SUMMARIES.INVOICE_SUMMARY_DETAIL(invoiceSummaryId),
  });

  return formattingToInvoiceSummaryVOModel(response);
}

// 마지막 단계 송장 결재 조회
export async function requestAnchorInvoiceSummaryLastPhase(invoiceSummaryId: number): Promise<LastInvoicePhaseVOModel> {
  const response = await http.get<LastInvoicePhaseVO>({
    url: API_AC.INVOICE_SUMMARIES.INVOICE_SUMMARY_LAST_PHASE(invoiceSummaryId),
  });

  return formattingToLastInvoicePhaseVOModel(response);
}

// 송장 결재 승인
export async function requestAnchorApproveInvoicePhase(
  data: AnchorInvoicePhaseApproveRequest,
): Promise<LastInvoicePhaseVOModel> {
  const response = await http.post<LastInvoicePhaseVO>({
    url: API_AC.INVOICE_SUMMARIES.APPROVE_INVOICE_PHASE,
    data,
  });

  return formattingToLastInvoicePhaseVOModel(response);
}

// 송장 거절
export async function requestAnchorRejectInvoiceRegistration(data: AnchorRejectInvoiceRegistrationRequest) {
  const response = await http.put({
    url: API_AC.INVOICE_SUMMARIES.REJECT_INVOICE_REGISTRATION,
    data,
  });

  return response;
}

export async function requestAnchorInvoiceBulkRegister(data: AnchorInvoiceBulkRegisterRequest) {
  const response = await http.post({
    url: API_AC.INVOICE_SUMMARIES.INVOICE_BULK_UPLOAD,
    data,
  });

  return response;
}

export async function requestApproveInvoiceBulk(data: ApproveInvoiceBulkRequest): Promise<void> {
  await http.post({
    url: API_AC.INVOICE_SUMMARIES.APPROVE_BULK_INVOICE,
    data,
  });

  return;
}
