import type React from 'react';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Big from 'big.js';
import dayjs from 'dayjs';

import Button from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import { FormSubtitle, NumericFormatInput } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { BASIS_INTEREST_TYPE, DOCUMENT_FORM_TYPE, LOAN_TERM_TYPE } from 'enums';
import useModal from 'utils/modal/useModal';

import { useFinancingRequest } from './logics';

import type { DealerArApplicationStep2State } from '../../useDealerArFinancingApplicationStep2State';

interface FinancingRequestProps {
  showDocumentTypeBtn: (documentType: DOCUMENT_FORM_TYPE) => boolean;
  fetchDealerArCalculateLoanRequest: () => Promise<void>;
  dealerArApplicationStep2State: DealerArApplicationStep2State;
  loanAgreementAttachmentChangedState: {
    loanAgreementAttachmentChanged: boolean;
    setLoanAgreementAttachmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  };
  loanRequestAttachmentChangedState: {
    loanRequestAttachmentChanged: boolean;
    setLoanRequestAttachmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  };
  estimateButtonRef: React.RefObject<HTMLDivElement>;
  disbursementDateRef: React.RefObject<HTMLDivElement>;
  initializeExpectedInterestRate: () => void;
}

function FinancingRequest({
  showDocumentTypeBtn,
  dealerArApplicationStep2State,
  loanAgreementAttachmentChangedState,
  loanRequestAttachmentChangedState,
  disbursementDateRef,
  estimateButtonRef,
  initializeExpectedInterestRate,
  fetchDealerArCalculateLoanRequest,
}: FinancingRequestProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { register, setValue, errors, control, setError, clearErrors, watch } = useFormContext();
  const {
    dealerAgreementDetail,
    dealerArDetail,
    dealerArCalculateLoanRequest,
    factoringEnable,
    minDate,
    maxDate,
    disbursementDateDisabledRanges,
    maxLoanableAmount,
    expectedInterestRate,
  } = dealerArApplicationStep2State;
  const { loanAgreementAttachmentChanged } = loanAgreementAttachmentChangedState;
  const { loanRequestAttachmentChanged } = loanRequestAttachmentChangedState;

  const { disbursementDate, requestedLoanAmount } = watch();

  const {
    applicationFileName,
    agreementFileName,
    disabledEstimateButton,
    ltvCalculate,
    financingPeriodCalculate,
    loanTermTypeText,
    onClickApplicationDownloadForm,
    onClickAgreementDownloadForm,
    returnTermSpreadRate,
    onChangeApplicationFileName,
    resetApplicationFileName,
    onChangeAgreementFileName,
    resetAgreementFileName,
    onClickApplicationFileDownLoad,
    onClickAgreementFileDownLoad,
    returnAmountWithCurrency,
  } = useFinancingRequest({
    dealerArApplicationStep2State,
    setValue,
    disbursementDate,
    requestedLoanAmount,
    loanAgreementAttachmentChangedState,
    loanRequestAttachmentChangedState,
  });

  const defaultDate = useMemo(() => {
    const isToday = dealerArDetail?.scheduledDisbursementDate === dayjs().format('YYYY-MM-DD');
    const scheduledDisbursementDate = dealerArDetail?.scheduledDisbursementDate;

    return factoringEnable && !isToday ? scheduledDisbursementDate : null;
  }, [factoringEnable, dealerArDetail?.scheduledDisbursementDate]);

  const handleClickEstimateButton = async () => {
    clearErrors();

    if (requestedLoanAmount && requestedLoanAmount > maxLoanableAmount!) {
      setError(`requestedLoanAmount`, { type: 'max', shouldFocus: true });
      modal.show(<h6>{t('text:The_requested_financing_amount_cannot_exceed_the_maximum_applicable_amount')}</h6>);

      return;
    }
    try {
      await fetchDealerArCalculateLoanRequest();
    } catch (e) {
      modal.show(e);
    }
  };

  const NetDisbursementAmount = () => {
    return (
      <>
        {dealerAgreementDetail.showExpectedTotalInterestRate && dealerAgreementDetail.showNetDisbursementAmount && (
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {t('text:Advance_Disbursement')}
                <QuestionTooltip
                  id="netDisburseAmountTooltip"
                  place="top"
                  contentText={
                    <>
                      <div>{factoringEnable ? t('text:Requested_Financing_Amount') : t('text:Principal_Amount')} ⓐ</div>
                      <div>{factoringEnable ? t('text:Discount_Charge') : t('text:Prepayment_Interest')} ⓑ</div>
                      <div>--------------------------------------------------------</div>
                      <div>{t('text:Advance_Disbursement')} ⓒ = ⓐ - ⓑ</div>
                    </>
                  }
                />
              </div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(dealerArCalculateLoanRequest?.netDisbursementAmount)}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const FactoringAPRSection = () => {
    return (
      <>
        <div className="detail-title bg-sub100">
          <div className="d-flex w-50 justify-content-between">
            <div className="me-auto">{t('text:Discount_Rate_APR')}</div>
            <div className="align-self-center pe-3" ref={estimateButtonRef}>
              <Button
                onClick={handleClickEstimateButton}
                className="flex-column-center text-bold"
                disabled={disabledEstimateButton()}
              >
                {t('text:Estimate')}
              </Button>
            </div>
            <div>{expectedInterestRate}</div>
          </div>
        </div>

        <div className="information-form information-flex__form bg-white">
          {dealerAgreementDetail.showExpectedTotalInterestRate && (
            <>
              <div className="row">
                <div className="col-3">
                  <div className="information-form__label flex-label">{t('text:Discount_Charge')}</div>
                </div>
                <div className="col-3">
                  <div className="information-form__input flex-input gray-bold-font">
                    {returnAmountWithCurrency(dealerArCalculateLoanRequest?.expectedInterestAmount)}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="border-bottom-light-gray my-3" />
                </div>
              </div>
            </>
          )}
          <NetDisbursementAmount />

          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Remaining_AR_Balance')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(dealerArCalculateLoanRequest?.remainingCollateralAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Transaction_Fee')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(dealerArCalculateLoanRequest?.arCommissionAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {t('text:Final_Disbursement_Amount')}
                <QuestionTooltip
                  id="finalDisburseAmountTooltip"
                  place="top"
                  contentText={
                    <>
                      <div>{t('text:Remaining_AR_Balance')} ⓐ</div>
                      <div>{t('text:Transaction_Fee')} ⓑ</div>
                      <div>--------------------------------------------------------</div>
                      <div>{t('text:Final_Disbursement_Amount')} ⓒ = ⓐ - ⓑ</div>
                    </>
                  }
                />
              </div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(
                  Number(dealerArCalculateLoanRequest?.remainingCollateralAmount) -
                    Number(dealerArCalculateLoanRequest?.arCommissionAmount),
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const FinancingAPRSection = () => {
    return (
      <>
        <div className="detail-title bg-sub100">
          <div className="d-flex w-50 justify-content-between">
            <div className="me-auto">{t('text:Expected_Interest(APR)')}</div>
            <div className="align-self-center pe-3" ref={estimateButtonRef}>
              <Button
                onClick={handleClickEstimateButton}
                className="flex-column-center text-bold"
                disabled={disabledEstimateButton()}
              >
                {t('text:Estimate')}
              </Button>
            </div>
            <div>{expectedInterestRate}</div>
          </div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Base_Interest_Type')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {dealerAgreementDetail?.basisInterestType}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {dealerAgreementDetail?.basisInterestType === BASIS_INTEREST_TYPE.FIXED
                  ? t('text:Basis_Interest_Rate(APR)')
                  : t('text:Base_Interest_Term')}
              </div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {dealerAgreementDetail?.basisInterestType === BASIS_INTEREST_TYPE.FIXED
                  ? t('format:number', {
                      value: dealerAgreementDetail?.basisInterestRate,
                    }) + '%'
                  : dealerAgreementDetail?.basisInterestTerm
                  ? `${dealerAgreementDetail.basisInterestTerm}`
                  : undefined}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Credit_Spread_(%)')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {t('format:number', {
                  value: `${dealerAgreementDetail?.creditInterestRate}`,
                })}
                %
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Interest_Rate_Adjustment_(%)')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {dealerAgreementDetail?.preferentialInterestRate
                  ? (dealerAgreementDetail?.preferentialInterestRate >= 0 ? '+' : '') +
                    t('format:number', {
                      value: `${dealerAgreementDetail?.preferentialInterestRate}`,
                    })
                  : '-'}
                %
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Term_Spread')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">{returnTermSpreadRate()}%</div>
            </div>
          </div>

          {dealerAgreementDetail.showExpectedTotalInterestRate && (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="border-bottom-light-gray my-3" />
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  <div className="information-form__label flex-label">{t('text:Expected_Interest_Amount')}</div>
                </div>
                <div className="col-3">
                  <div className="information-form__input flex-input gray-bold-font">
                    {returnAmountWithCurrency(dealerArCalculateLoanRequest?.expectedInterestAmount)}
                  </div>
                </div>
              </div>
            </>
          )}
          <NetDisbursementAmount />
        </div>
      </>
    );
  };

  const FinancingApplicationDocumentForm = () => (
    <>
      <FormSubtitle backGroundType={BackGroundType.Gray} title={t('text:Financing_Application_Document')}>
        {showDocumentTypeBtn(DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_REQUEST) && (
          <div className="flex-end align-items-center me-3">
            <Button
              style={{ height: '25px' }}
              className="flex-column-center text-bold"
              onClick={onClickApplicationDownloadForm}
            >
              {t('text:Download_Form')}
            </Button>
          </div>
        )}
      </FormSubtitle>
      <div className="detail-in-file-upload-form">
        <div className="file-upload">
          <div className="d-flex justify-content-between">
            <input
              type="file"
              id="loanRequestAttachmentFile"
              className="d-none"
              name="loanRequestAttachment"
              ref={register}
              onChange={onChangeApplicationFileName}
            />
            <label htmlFor="loanRequestAttachmentFile" className="attach-file-link-button">
              {t('text:Attach_File')}
            </label>
            <div id="fileName2" className="upload-file-input">
              {applicationFileName ? applicationFileName : t('text:No_file_attached')}
              {applicationFileName && !loanRequestAttachmentChanged && (
                <IconButton onClick={onClickApplicationFileDownLoad} type="button">
                  <FontAwesomeIcon icon={faDownload} />
                </IconButton>
              )}
            </div>
            <IconButton
              onClick={resetApplicationFileName}
              className={`delete-uploaded-excel-button ${!applicationFileName && 'd-none'}`}
              type="button"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );

  const FinancingAgreementDocumentForm = () => (
    <>
      <FormSubtitle backGroundType={BackGroundType.Gray} title={t('text:Financing_Agreement_Document')}>
        {showDocumentTypeBtn(DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_AGREEMENT) && (
          <div className="flex-end align-items-center me-3">
            <Button
              style={{ height: '25px' }}
              className="flex-column-center text-bold"
              onClick={onClickAgreementDownloadForm}
            >
              {t('text:Download_Form')}
            </Button>
          </div>
        )}
      </FormSubtitle>
      <div className="detail-in-file-upload-form">
        <div className="file-upload">
          <div className="d-flex justify-content-between">
            <input
              type="file"
              id="loanAgreementAttachmentFile"
              className="d-none"
              name="loanAgreementAttachment"
              ref={register}
              onChange={onChangeAgreementFileName}
            />
            <label htmlFor="loanAgreementAttachmentFile" className="attach-file-link-button">
              {t('text:Attach_File')}
            </label>
            <div id="fileName" className="upload-file-input">
              {agreementFileName ? agreementFileName : t('text:No_file_attached')}
              {agreementFileName && !loanAgreementAttachmentChanged && (
                <IconButton onClick={onClickAgreementFileDownLoad} type="button">
                  <FontAwesomeIcon icon={faDownload} />
                </IconButton>
              )}
            </div>
            <IconButton
              onClick={resetAgreementFileName}
              className={`delete-uploaded-excel-button ${!agreementFileName && 'd-none'}`}
              type="button"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <SectionTitle title={t('text:Financing_Request')} />
      <GuideMessage
        isImportContentArea={true}
        message={[
          t('text:Fill_out_the_necessary_information_and_check_the_financing_condition_details'),
          t('text:Upload_the_Financing_Application_Document_and_the_Financing_Agreement_Document'),
          t('text:Click_on_the_checkbox_to_confirm_the_financing_request_information_is_correct'),
        ]}
      />
      <div className="information-form__detail-form border-bott-none mt-4">
        <div className="detail-title bg-sub100">
          <div className="d-flex justify-content-between w-50">
            {/* TODO:
                고정기간: 임시 저장 데이터 무시
                변동기간: 임시 저장 데이터가 유효할 때에만 표시 (isValidTempDisbursementDate 활용)
            */}
            {dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE ? (
              <>
                <div ref={disbursementDateRef}>{t('text:Requested_Disbursement_Date')}</div>
                <div className="d-flex align-items-center">
                  <Controller
                    control={control}
                    name="disbursementDate"
                    defaultValue={defaultDate}
                    render={field => (
                      <ReactDatePicker
                        field={field}
                        className="detail-title-input"
                        minDate={minDate}
                        maxDate={maxDate}
                        excludeDates={disbursementDateDisabledRanges}
                        onChange={() => initializeExpectedInterestRate()}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </>
            ) : (
              <>
                <div>{t('text:Requested_Disbursement_Date')}</div>
                <div>
                  {t('format:date', {
                    value: dealerArDetail?.scheduledDisbursementDate,
                    key: 'date',
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Scheduled_Repayment_Date')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {t('format:date', {
                  value: dealerArDetail?.maturityDate,
                  key: 'date',
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Financing_Term')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE
                  ? financingPeriodCalculate(disbursementDate, dealerArDetail?.maturityDate)
                  : loanTermTypeText(dealerAgreementDetail?.loanTermUnit, dealerAgreementDetail?.loanTermType)}
              </div>
            </div>
          </div>
        </div>
        <div className="detail-title bg-sub100">
          <div className="d-flex w-50 justify-content-between">
            <div>{t('text:Requested_Financing_Amount')}</div>
            <div className="d-flex align-items-center">
              <NumericFormatInput
                control={control}
                name="requestedLoanAmount"
                className="detail-title-input"
                numberType="bigNumber"
                currencyType={dealerArDetail?.currencyType}
                error={errors.requestedLoanAmount}
                onChange={() => initializeExpectedInterestRate()}
              />
            </div>
          </div>
          <div className="d-flex w-50 justify-content-between">
            <div className="d-flex gap-3">
              <span>≤</span>
              <div>{t('text:Maximum_Applicable_Amount')}</div>
              <div>{returnAmountWithCurrency(maxLoanableAmount)}</div>
            </div>
          </div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Requested_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(requestedLoanAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:AR_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(dealerArDetail?.arAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:LTV(%)')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">{ltvCalculate()}</div>
            </div>
          </div>
          {dealerAgreementDetail?.useTotalLimit && (
            <div className="row">
              <div className="col-3">
                <div className="information-form__label flex-label">{t('text:Remaining_Applicable_Amount')}</div>
              </div>
              <div className="col-3">
                <div className="information-form__input flex-input gray-bold-font">
                  {t('format:number', {
                    value: Big(dealerAgreementDetail?.agreementCurrencyTotalBalance).lt(0)
                      ? 0
                      : dealerAgreementDetail?.agreementCurrencyTotalBalance,
                  })}{' '}
                  {dealerAgreementDetail?.currencyType}
                </div>
              </div>
            </div>
          )}
        </div>
        {factoringEnable ? <FactoringAPRSection /> : <FinancingAPRSection />}
        {!factoringEnable && (
          <>
            {FinancingApplicationDocumentForm()}
            {FinancingAgreementDocumentForm()}
          </>
        )}
      </div>
    </>
  );
}

export default FinancingRequest;
