import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  FormBorder,
  FormContents,
  FormInput,
  FormRadio,
  FormRadioWrap,
  FormSelect,
  FormSubtitle,
  NumericFormatInput,
} from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import type { SUPPORTED_COLLATERAL_TYPE } from 'enums';
import type { CreateFinancierAndAdminUserDTO } from 'utils/http/api/anonymous/on-demand/requests';

import { useCreateFinancierStep1Actions, useCreateFinancierStep1Value } from '../CreateFinancierStep1Provider';

type CommonSettingsFormType = Pick<
  CreateFinancierAndAdminUserDTO,
  | 'financierSubType'
  | 'showOnlyAllowedBranchData'
  | 'logo'
  | 'supportedCollateralType'
  | 'settlementDateIsTodayInvoiceUploadable'
  | 'invoiceWillBeSettledOnUploadDate'
  | 'potentialPartnerRegistrable'
  | 'minimumPeriodForRegisterAr'
  | 'maximumPeriodForRegisterAr'
  | 'disburseDateBusinessDayConvention'
  | 'repaymentDateBusinessDayConvention'
  | 'divisionRegistrable'
>;

const CommonSettings = () => {
  const { t } = useTranslation();

  const { updateLogoAttachFileName } = useCreateFinancierStep1Actions();
  const { logoAttachFileName } = useCreateFinancierStep1Value();

  const { register, errors, control, setValue, watch } = useFormContext<CommonSettingsFormType>();
  const { settlementDateIsTodayInvoiceUploadable } = watch();

  const renderAccessSettingsForm = (
    <>
      <FormSubtitle title={t('text:Access_Settings')} backGroundType={BackGroundType.DarkGray} />
      <FormContents>
        <div className="row">
          <FormInput
            label={t('text:Financier_Sub-Code')}
            name="financierSubType"
            requiredOptions={{ required: true }}
            ref={register}
            error={errors.financierSubType}
          />
        </div>
      </FormContents>
    </>
  );

  const renderGeneralSettingsForm = (
    <>
      <FormSubtitle title={t('text:General_Settings')} backGroundType={BackGroundType.DarkGray} />
      <FormContents>
        <div className="row">
          <FormRadioWrap
            label={t('text:Allow_branches_to_see_all_of_the_financiers_data?')}
            required
            error={errors.showOnlyAllowedBranchData}
          >
            <FormRadio label={t('text:Yes')} name="showOnlyAllowedBranchData" value="false" ref={register} />
            <FormRadio label={t('text:No')} name="showOnlyAllowedBranchData" value="true" ref={register} />
          </FormRadioWrap>
          <div className="col-6">
            <div className="d-flex">
              <label className="information-form__label star w-auto">{t('text:Financier_Logo_File')}</label>
              {errors.logo && <FontAwesomeIcon icon={faCheckCircle} className="check-icon ms-2" />}
            </div>
            <div className="align-self-center mt-2">
              <div className="d-flex align-items-center">
                <input
                  className="d-none"
                  type="file"
                  name="logo"
                  id="logoAttachmentFile"
                  onChange={e => {
                    updateLogoAttachFileName(e.target.files?.[0]?.name);
                  }}
                  ref={register}
                />
                <label htmlFor="logoAttachmentFile" className="attach-file-link-button me-3">
                  {t('text:Attach_File')}
                </label>
                <div className="upload-file-input me-auto">{logoAttachFileName || t('text:No_file_attached')}</div>
              </div>
            </div>
          </div>
        </div>
      </FormContents>
    </>
  );

  const renderCollateralSettingsForm = (
    <>
      <FormSubtitle title={t('text:Collateral_Settings')} backGroundType={BackGroundType.DarkGray} />
      <FormContents>
        <div className="row">
          <FormSelect
            col={3}
            className="information-form__select"
            label={t('text:Collateral_Type')}
            selectOptions={getSelectOptions<SUPPORTED_COLLATERAL_TYPE>('SUPPORTED_COLLATERAL_TYPE')}
            name="supportedCollateralType"
            required
            ref={register}
            error={errors.supportedCollateralType}
          />
        </div>
        <div className="row">
          <FormRadioWrap
            label={t('text:Allow_registration_of_invoices_that_mature_today?')}
            required
            error={errors.settlementDateIsTodayInvoiceUploadable}
          >
            <FormRadio
              label={t('text:Yes')}
              name="settlementDateIsTodayInvoiceUploadable"
              value="true"
              ref={register}
            />
            <FormRadio
              label={t('text:No')}
              name="settlementDateIsTodayInvoiceUploadable"
              value="false"
              onChange={() => setValue('invoiceWillBeSettledOnUploadDate', 'false')}
              ref={register}
            />
          </FormRadioWrap>
          <FormRadioWrap
            label={t('text:Allow_to_fix_the_payment_date_of_invoice_to_the_registration_date?')}
            required
            error={errors.invoiceWillBeSettledOnUploadDate}
          >
            <FormRadio
              label={t('text:Yes')}
              name="invoiceWillBeSettledOnUploadDate"
              value="true"
              disabled={settlementDateIsTodayInvoiceUploadable !== 'true'}
              ref={register}
            />
            <FormRadio
              label={t('text:No')}
              name="invoiceWillBeSettledOnUploadDate"
              value="false"
              disabled={settlementDateIsTodayInvoiceUploadable !== 'true'}
              ref={register}
            />
          </FormRadioWrap>
        </div>
        <div className="row">
          <FormRadioWrap
            label={t('text:Allow_anchor_to_register_potential_partners?')}
            required
            error={errors.potentialPartnerRegistrable}
          >
            <FormRadio label={t('text:Yes')} name="potentialPartnerRegistrable" value="true" ref={register} />
            <FormRadio label={t('text:No')} name="potentialPartnerRegistrable" value="false" ref={register} />
          </FormRadioWrap>
          <div className="col-6">
            <label className="information-form__label star">
              {t('text:How_many_days_until_settlement_must_be_remaining_when_registering_AR?')}
            </label>
            <div className="d-flex">
              <NumericFormatInput
                control={control}
                name="minimumPeriodForRegisterAr"
                numberType="integer"
                className="information-form__input"
                col={5}
                placeholder={t('text:Minimum_required_days')}
                ref={register}
                error={errors.minimumPeriodForRegisterAr}
              />
              <div className="me-3" />
              <NumericFormatInput
                control={control}
                name="maximumPeriodForRegisterAr"
                numberType="integer"
                className="information-form__input"
                col={5}
                placeholder={t('text:Maximum_required_days')}
                ref={register}
                error={errors.maximumPeriodForRegisterAr}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <FormRadioWrap
            label={t('text:Allow_the_financier_to_register_business_divisions_on_the_platform?')}
            required
            error={errors.divisionRegistrable}
          >
            <FormRadio label={t('text:Yes')} name="divisionRegistrable" value="true" ref={register} />
            <FormRadio label={t('text:No')} name="divisionRegistrable" value="false" defaultChecked ref={register} />
          </FormRadioWrap>
        </div>
      </FormContents>
    </>
  );

  const renderBusinessDaySettingsForm = (
    <>
      <FormSubtitle title={t('text:Business_Day_Settings')} backGroundType={BackGroundType.DarkGray} />
      <FormContents>
        <div className="row">
          <div className="col-12">
            <div className="information-form__label">
              {t('text:Option_to_change_the_base_date_to_a_business_day_if_is_a_holiday')}
            </div>
          </div>
        </div>
        <div className="row">
          <FormSelect
            label={t('text:Disbursement_Date')}
            selectOptions={getSelectOptions<string>('DATE_BUSINESS_DAY_CONVENTION')}
            name="disburseDateBusinessDayConvention"
            required
            ref={register}
            error={errors.disburseDateBusinessDayConvention}
          />
          <FormSelect
            label={t('text:Repayment_Date')}
            selectOptions={getSelectOptions<string>('DATE_BUSINESS_DAY_CONVENTION')}
            name="repaymentDateBusinessDayConvention"
            required
            ref={register}
            error={errors.repaymentDateBusinessDayConvention}
          />
        </div>
      </FormContents>
    </>
  );

  return (
    <>
      <SectionTitle title={t('text:Common_Settings')} />
      <FormBorder editable>
        {renderAccessSettingsForm}
        {renderGeneralSettingsForm}
        {renderCollateralSettingsForm}
        {renderBusinessDaySettingsForm}
      </FormBorder>
    </>
  );
};

export default CommonSettings;
