import type { HTMLAttributes } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ColorMap } from 'enums/colorMap';
import { clipboardWriteText } from 'utils/clipboard';

import IconButton from './IconButton';

interface ClipboardWriteButtonProps extends HTMLAttributes<HTMLButtonElement> {
  id?: string | undefined;
  onClickClipboardWriteButton: () => Promise<string | null>;
  disabled?: boolean;
  disabledText?: string;
  successText?: string;
  toolTipText?: string;
  style?: React.CSSProperties;
  toolTipBgColor?: ColorMap;
  toolTipTextColor?: ColorMap;
}

const defaultStyle = { backgroundColor: ColorMap.sub100, fontSize: '18px' };

function ClipboardWriteButton({
  id = '',
  onClickClipboardWriteButton = () => Promise.resolve(null),
  disabled = false,
  disabledText,
  successText,
  toolTipText,
  toolTipBgColor = ColorMap.warmsub400,
  toolTipTextColor = ColorMap.white,
  style,
  ...props
}: ClipboardWriteButtonProps) {
  const { t } = useTranslation();
  const clipboardWriteBtnRef = React.useRef<HTMLButtonElement>(null);
  const timer = React.useRef<NodeJS.Timer | null>(null);
  const copied = React.useRef(false);

  const initialText = () => {
    if (!successText) {
      successText = t('text:Copied')!;
    }

    if (!disabledText) {
      disabledText = t('text:Send_a_new_email');
    }

    if (!toolTipText) {
      toolTipText = t('text:Copy_mail_link');
    }
  };
  initialText();

  const styles = {
    ...defaultStyle,
    ...style,
  };

  const handleClick = React.useCallback(async () => {
    if (timer.current) clearTimeout(timer.current);

    const clipboardWriteBtn = clipboardWriteBtnRef.current as HTMLButtonElement;

    const textToCopy = await onClickClipboardWriteButton();
    if (textToCopy === null) return;
    const response = await clipboardWriteText(textToCopy);
    if (response !== 'success') return;

    copied.current = true;
    ReactTooltip.show(clipboardWriteBtn);

    timer.current = setTimeout(() => {
      copied.current = false;
      ReactTooltip.hide(clipboardWriteBtn);
    }, 1000);
  }, [onClickClipboardWriteButton]);

  const getContent = (): string => {
    if (disabled) return disabledText as string;

    return (copied.current ? successText : toolTipText) as string;
  };

  return (
    <>
      <IconButton
        className={`clipboard-write-btn ${disabled ? 'disabled' : ''}`}
        type="button"
        ref={clipboardWriteBtnRef}
        onClick={handleClick}
        style={styles}
        data-tip=""
        data-for={`clipboardWrite-${id}`}
        {...props}
      >
        <FontAwesomeIcon icon={faCopy} />
      </IconButton>

      <ReactTooltip
        id={`clipboardWrite-${id}`}
        effect="solid"
        backgroundColor={toolTipBgColor}
        textColor={toolTipTextColor}
        getContent={getContent}
      />
    </>
  );
}

export default ClipboardWriteButton;
