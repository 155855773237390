import { createContext, useContext } from 'react';

import type { COLLATERAL_TYPE } from 'enums';
import type { AnchorAgreementCommonVOModel } from 'models/convertor/anchorAgreement';

export type AgreementType = Omit<
  AnchorAgreementCommonVOModel,
  'anchorClientId' | 'anchorClientCode' | 'anchorClientName' | 'anchorUserList'
>;

type AgreementStateType = {
  agreement: AgreementType;
  isSearchedAgreement: boolean;
  isFirstRegisteredWaitingAgreement: boolean;
  updateAgreement: (agreement: Partial<AgreementType>) => void;
  updateIsSearchedAgreement: (isSearchedAgreement: boolean) => void;
  updateAllAgreement: (agreement: AnchorAgreementCommonVOModel) => void;
  fetchSavedAgreement: (collateralType: COLLATERAL_TYPE) => Promise<AnchorAgreementCommonVOModel>;
  fetchWaitingAgreement: (waitingAnchorAgreementId: number) => Promise<AnchorAgreementCommonVOModel | undefined>;
  fetchAgreementDetail: (anchorAgreementId: number) => Promise<AnchorAgreementCommonVOModel | undefined>;
};

export const AgreementContext = createContext<AgreementStateType | null>(null);

export const useAgreementContext = () => {
  const context = useContext(AgreementContext);
  if (!context) {
    throw Error('useAgreementContext should be used within AgreementContext.Provider');
  }

  return context;
};
