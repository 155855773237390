import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { isNil } from 'lodash-es';

import { BASIS_INTEREST_TYPE } from 'enums';
import { convertToAnchorFinancingOptionResetData } from 'models/convertor/anchorFinancingOption';
import useModal from 'utils/modal/useModal';

import useExtraInformationViewModel from './extraInformation/useExtraInformationViewModel';
import useAnchorAgreementInfoViewModel from './models/useAnchorAgreementInfoViewModel';
import useFinancingOptionViewModel from './models/useFinancingOptionViewModel';

const useRegistrationDetailController = () => {
  const { show: showModal } = useModal();
  const methods = useForm();
  const { errorHandlerOfLocationState, anchorFinancingOptionId } = useExtraInformationViewModel();
  const { fetchFinancingOptionDetail, updateAllFinancingOption } = useFinancingOptionViewModel();
  const { fetchAnchorAgreementDetail, updateAnchorAgreementInfo } = useAnchorAgreementInfoViewModel();

  const [, setIsAgreementTab] = useState<boolean>(true);

  const { reset } = methods;
  const resetPage = async () => {
    try {
      if (isNil(anchorFinancingOptionId) || errorHandlerOfLocationState()) return;

      const financingOptionDetail = await fetchFinancingOptionDetail(anchorFinancingOptionId);
      const anchorAgreementDetail = await fetchAnchorAgreementDetail(financingOptionDetail?.anchorAgreementId!);

      if (financingOptionDetail) {
        if (financingOptionDetail.basisInterestBankCode === BASIS_INTEREST_TYPE.FIXED)
          financingOptionDetail.basisInterestBankCode = '';
        updateAllFinancingOption(financingOptionDetail);
        reset(convertToAnchorFinancingOptionResetData(financingOptionDetail));
      }
      if (anchorAgreementDetail) updateAnchorAgreementInfo(anchorAgreementDetail);
    } catch (error) {
      showModal(error);
    }
  };

  const handleRevisionHistoryTabClick = () => setIsAgreementTab(false);
  const handleAgreementTabClick = () => setIsAgreementTab(true);

  useEffect(() => {
    resetPage();
  }, []);

  return {
    methods,
    handleRevisionHistoryTabClick,
    handleAgreementTabClick,
  };
};

export default useRegistrationDetailController;
