import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import useProperty from 'hooks/useProperty';
import type { BranchVOModel } from 'models/vo/BranchVO';
import { requestBranchInterface } from 'utils/http/api/financier/branches';
import type { FinancierBranchRegisterRequest } from 'utils/http/api/financier/branches/request';
import { getHttpErrorExceptionMessage } from 'utils/text';

function SearchBranchModal({ state }: { state: BranchVOModel }) {
  const { t } = useTranslation();
  const getProperty = useProperty<FinancierBranchRegisterRequest>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  // branch code
  const {
    register: branchCodeRegister,
    getValues: getBranchCodeValue,
    handleSubmit: branchCodeHandleSubmit,
  } = useForm({
    mode: 'onSubmit',
  });

  // branch register request
  const {
    register: branchModelRegister,
    getValues: getBranchRegisterValues,
    setValue: setBranchRegisterValue,
  } = useForm<FinancierBranchRegisterRequest>({
    mode: 'onSubmit',
  });

  const searchBranchHandler = async () => {
    setErrorMessage('');
    const branchCode = getBranchCodeValue('branchCodeSearch');
    try {
      const financierBranchInfo = await requestBranchInterface(branchCode);

      setBranchRegisterValue(getProperty('branchCode'), financierBranchInfo.branchCode);
      setBranchRegisterValue(getProperty('branchName'), financierBranchInfo.branchName);
      setBranchRegisterValue(getProperty('branchBusinessCode'), financierBranchInfo.branchBusinessCode);
      setBranchRegisterValue(getProperty('address'), financierBranchInfo.address);
      setBranchRegisterValue(getProperty('telephone'), financierBranchInfo.telephone);
      setBranchRegisterValue(getProperty('fax'), financierBranchInfo.fax);
      setBranchRegisterValue(getProperty('managerName'), financierBranchInfo.managerName);
      setBranchRegisterValue(getProperty('managerEmail'), financierBranchInfo.managerEmail);
      setBranchRegisterValue(getProperty('managerPosition'), financierBranchInfo.managerPosition);

      Object.assign(state, getBranchRegisterValues());
    } catch (e: any) {
      setErrorMessage(getHttpErrorExceptionMessage(e));
    }
  };

  return (
    <>
      <div className="modal-input-form mb-3">
        <form>
          <label className="information-form__label">{t('text:Branch_Code')}</label>
          <div className="d-flex">
            <input
              className="information-form__input bg-sub100"
              type="text"
              name="branchCodeSearch"
              ref={branchCodeRegister}
              onChange={() => {
                setErrorMessage('');
              }}
            />
            <Button onClick={branchCodeHandleSubmit(searchBranchHandler)}>{t('text:Search')}</Button>
          </div>
          {errorMessage !== '' && <div className="text-brick-red mt-1">{t(`exception:${errorMessage}`)}</div>}
        </form>
      </div>
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormInput
              label={t('text:Branch_Code')}
              name={getProperty('branchCode')}
              ref={branchModelRegister}
              disabled
            />
            <FormInput
              label={t('text:Branch_Name')}
              name={getProperty('branchName')}
              ref={branchModelRegister}
              disabled
            />
          </div>
          <div className="row">
            <FormInput
              label={t('text:Business_Registration_Number')}
              name={getProperty('branchBusinessCode')}
              ref={branchModelRegister}
              disabled
            />
            <FormInput
              col={3}
              label={t('text:Telephone')}
              name={getProperty('telephone')}
              ref={branchModelRegister}
              disabled
            />
            <FormInput col={3} label={t('text:Fax')} name={getProperty('fax')} ref={branchModelRegister} disabled />
          </div>
          <div className="row">
            <FormInput
              label={t('text:Legal_Representative_Name')}
              name={getProperty('managerName')}
              ref={branchModelRegister}
              disabled
            />
            <FormInput
              col={3}
              label={t('text:Legal_Representative_Title')}
              name={getProperty('managerPosition')}
              ref={branchModelRegister}
              disabled
            />
            <FormInput
              col={3}
              label={t('text:Legal_Representative_Email')}
              name={getProperty('managerEmail')}
              ref={branchModelRegister}
              disabled
            />
          </div>
          <div className="row">
            <FormInput
              col={12}
              label={t('text:Registered_Office')}
              name={getProperty('address')}
              ref={branchModelRegister}
              disabled
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default SearchBranchModal;
