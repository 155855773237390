import type {
  FinancierClientAuthSettingVO,
  FinancierClientAuthSettingVOModel,
} from 'models/vo/FinancierClientAuthSettingVO';
import { formattingToFinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

// 인증 정보 조회
export async function requestClientAuth(code: string): Promise<FinancierClientAuthSettingVOModel> {
  const response = await http.get<FinancierClientAuthSettingVO>({ url: API_AN.CLIENT_AUTH_SETTING, data: { code } });

  return formattingToFinancierClientAuthSettingVOModel(response);
}
