import { useTranslation } from 'react-i18next';

import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { MULTIPLE_LOAN_APPROVAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import type { MultipleAnchorLoanRequestDetailVOModel } from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

interface FinancierBulkAnchorFinancingCompanyInformationProps {
  bulkFinancingDetailInfo: MultipleAnchorLoanRequestDetailVOModel;
}

function FinancierBulkAnchorFinancingCompanyInformation({
  bulkFinancingDetailInfo,
}: FinancierBulkAnchorFinancingCompanyInformationProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const handleClickMultipleLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(MULTIPLE_LOAN_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="MULTIPLE_ANCHOR_LOAN_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const handleApprovalStatusQuestionButtonClick = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(MULTIPLE_LOAN_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="MULTIPLE_ANCHOR_LOAN_APPROVAL_TYPE"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Company_Information')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue col={3} label={t('text:Anchor_Name')} value={bulkFinancingDetailInfo.anchorClientName} />
            <FormValue col={3} label={t('text:Currency')} value={bulkFinancingDetailInfo.currencyType} />
            <FormValue
              col={3}
              className={getStatusBadgeClass(
                'MULTIPLE_LOAN_REQUEST_STATUS',
                bulkFinancingDetailInfo.multipleLoanRequestStatus,
              )}
              label={t('text:Financing_Status')}
              value={tableValueManage(
                t(`code:multiple-loan-request-status.${bulkFinancingDetailInfo.multipleLoanRequestStatus}`),
              )}
              hasStatusDescription
              showStatusDescriptionFunc={handleClickMultipleLoanStatus}
            />
            <FormValue
              col={3}
              className={getStatusBadgeClass(
                'MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE',
                bulkFinancingDetailInfo.approvalType,
              )}
              label={t('text:Bulk_Approval_Status')}
              value={t(`code:multiple-loan-approval-type.${bulkFinancingDetailInfo.approvalType}`)}
              format="code"
              hasStatusDescription
              showStatusDescriptionFunc={handleApprovalStatusQuestionButtonClick}
            />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default FinancierBulkAnchorFinancingCompanyInformation;
