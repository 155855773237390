import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import QuestionButton from 'components/stateless/QuestionButton';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE, FINANCIER_ALERT_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import {
  requestFinancierAlertSettingUpdate,
  requestFinancierAlertSettingUpdateRelatedUsers,
} from 'utils/http/api/financier/financier-alert-setting';
import type { FinancierAlertSetting } from 'utils/http/api/financier/financier-alert-setting/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getCollateralType, getSignIn } from 'utils/storage/LocalStorage';

import { useFinancierEmailNotificationSetting } from './useFinancierEmailNotificationSetting';

function FinancierEmailNotificationSetting(): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation();

  const signInData = getSignIn();
  const isAdmin = signInData?.authorityType === AUTHORITY_TYPE.ADMIN;
  const collateralType = getCollateralType();

  const {
    fetchFinancierAlertSettingList,
    commonAlertSettingState,
    partnerManagementAlertState,
    arRegistrationAlertState,
    vendorFinancingAlertState,
    invoiceRegistrationAlertState,
    dealerFinancingAlertState,
    RECIPIENT_AUTHORITIES,
  } = useFinancierEmailNotificationSetting();

  const [isCommonFinancierAlertSettingFormEditable, setIsCommonFinancierAlertSettingFormEditable] = useState(false);
  const [
    isVendorFinanceProgramFinancierAlertSettingFormEditable,
    setIsVendorFinanceProgramFinancierAlertSettingFormEditable,
  ] = useState(false);
  const [
    isDealerFinanceProgramFinancierAlertSettingFormEditable,
    setIsDealerFinanceProgramFinancierAlertSettingFormEditable,
  ] = useState(false);

  useEffect(() => {
    if (mounted) {
      fetchFinancierAlertSettingList();
    }
  }, [mounted]);

  const requestEmailSync = async (alertType: FINANCIER_ALERT_TYPE) => {
    try {
      await requestFinancierAlertSettingUpdateRelatedUsers(alertType);
      modal.show(<h6>{t('text:Sync_has_been_completed_for_all_accounts')}</h6>);
    } catch (e) {
      modal.show(e);
    }
  };

  const handleClickSync = (item: FinancierAlertSetting, isSpecific: boolean = false) => {
    const CommonMessage = () => (
      <h6>
        {t('text:Sync_your_settings_across_all_company_accounts')}
        <br />
        {t(
          'text:Regardless_of_whether_or_not_you_have_personalized_email_settings_all_settings_will_be_changed_to_the_option_you_selected',
        )}
      </h6>
    );

    modal.show(
      <>
        {isSpecific ? (
          <>
            <CommonMessage />
            <h6>
              {t('text:Only_financier_can_change_the_receiving_setting_for_this_email')}
              <br />
              {t('text:Be_careful')}
            </h6>
          </>
        ) : (
          <CommonMessage />
        )}

        <h6 className="p-3">
          <li>· {`${t('text:Item')} : ${t(`code:financier-alert-type.${item.alertType}`)}`}</li>
          <li>· {`${t('text:option')} : ${item.emailEnabled ? t('text:Email') : t('text:None')}`}</li>
        </h6>
      </>,
      {
        confirmBtnCb: async () => await requestEmailSync(item.alertType),
        modalType: ModalType.CONFIRM,
      },
    );
  };

  const FinancierAlertSettingRadioForm = ({
    financierAlertSettingList,
    setFinancierAlertSettingList,
    currentFormEditable,
  }: {
    financierAlertSettingList: FinancierAlertSetting[];
    setFinancierAlertSettingList: React.Dispatch<React.SetStateAction<FinancierAlertSetting[]>>;
    currentFormEditable: boolean;
  }) => {
    const { t } = useTranslation();
    const onClickFinancierAlertSettingEmailRadio = (itemIndex: number, newValue: boolean): void => {
      if (currentFormEditable) {
        setFinancierAlertSettingList(prev => {
          const oldValue = [...prev];
          oldValue[itemIndex].emailEnabled = newValue;

          return oldValue;
        });
      }

      return;
    };

    const handleClickRecipientListModal = (alertType: FINANCIER_ALERT_TYPE) => {
      const recipientAuthoritiesList = RECIPIENT_AUTHORITIES[alertType];

      modal.show(
        <>
          <h6>
            {t('text:This_is_the_list_of_recipients_of_financierAlertType_emails', {
              financierAlertType: t(`code:financier-alert-type.${alertType}`),
            })}
          </h6>
          <h6 className="p-3">
            {recipientAuthoritiesList.map((authority, index) => (
              <li key={index}>
                ·{' '}
                {`${t(`code:enterprise-type.${authority.enterpriseType}`)} ${t(
                  `code:authority-type.${authority.authorityType}`,
                )}`}
              </li>
            ))}
          </h6>
        </>,
        {
          title: t('text:Recipient_List'),
        },
      );
    };

    const showSyncAllButton = (alertType: FINANCIER_ALERT_TYPE) =>
      isAdmin && !(alertType === FINANCIER_ALERT_TYPE.AR_REGISTERED_TO_PP);

    const isSpecificType = (alertType: FINANCIER_ALERT_TYPE) =>
      alertType === FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_REGISTRATION ||
      alertType === FINANCIER_ALERT_TYPE.AR_LOAN_APPROVED_BY_FI_AU;

    return (
      <>
        {financierAlertSettingList?.map((item, index) => {
          return (
            <div className="row" key={index}>
              <div className={`col-${isAdmin ? 4 : 6} d-flex align-self-center`}>
                <div className="information-form__label m-0">
                  {t(`code:financier-alert-type.${item.alertType}`)}
                  {showSyncAllButton(item.alertType) && (
                    <QuestionButton onClick={() => handleClickRecipientListModal(item.alertType)} />
                  )}
                </div>
              </div>
              <div className="col-3">
                <div className="large-radio-btn">
                  <RadioButton
                    id={`${item.alertType}-none`}
                    name={item.alertType}
                    value="false"
                    onChange={() => onClickFinancierAlertSettingEmailRadio(index, false)}
                    disabled={!currentFormEditable}
                    checked={!item.emailEnabled}
                  >
                    {t('text:None')}
                  </RadioButton>
                </div>
              </div>
              <div className="col-3">
                <div className="large-radio-btn">
                  <RadioButton
                    id={`${item.alertType}-email`}
                    name={item.alertType}
                    value="true"
                    onChange={() => onClickFinancierAlertSettingEmailRadio(index, true)}
                    disabled={!currentFormEditable}
                    checked={item.emailEnabled}
                  >
                    {t('text:Email')}
                  </RadioButton>
                </div>
              </div>
              {showSyncAllButton(item.alertType) && (
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <Button
                    size={ButtonSizeEnum.SM}
                    onClick={() => handleClickSync(item, isSpecificType(item.alertType))}
                    bold
                    disabled={currentFormEditable}
                  >
                    {t('text:Sync_to_all_users')}
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const ManageButton = ({
    editableState,
    setEditableState,
    confirmParameterState,
  }: {
    editableState: boolean;
    setEditableState: React.Dispatch<React.SetStateAction<boolean>>;
    confirmParameterState: FinancierAlertSetting[];
  }) => {
    const onClickEdit = (e: any) => {
      e.preventDefault();

      setEditableState(true);
    };

    const onClickCancel = (e: any) => {
      e.preventDefault();

      modal.show(<h6>{t('text:Would_you_like_to_cancel_the_modification')}</h6>, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => {
          setEditableState(false);
          fetchFinancierAlertSettingList();
        },
      });
    };

    const onClickConfirm = async (e: any): Promise<void> => {
      e.preventDefault();

      try {
        await requestFinancierAlertSettingUpdate({
          updateAlertSettingList: confirmParameterState,
        });

        modal.show(<h6>{t('text:The_settings_have_been_saved_successfully')}</h6>, {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => {
            setEditableState(false);
            fetchFinancierAlertSettingList();
          },
        });
      } catch (error) {
        modal.show(error);
      }
    };

    return (
      <>
        {editableState ? (
          <>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickCancel}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={onClickConfirm} style={{ width: '60px' }}>
              {t('text:Save')}
            </Button>
          </>
        ) : (
          <Button size={ButtonSizeEnum.SM} onClick={onClickEdit} style={{ width: '60px' }}>
            {t('text:Edit')}
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Email_Notification_Settings')} />
      <GuideMessage
        message={[
          t('text:View_the_notification_settings_for_your_Financier_on_this_page'),
          t('text:The_notification_settings_can_be_changed_by_the_Financier_Admin'),
          isAdmin &&
            `${
              t('text:Press_the_Sync_to_all_users_button_to_synchronize_settings_to_all_accounts') +
              ' ' +
              t('text:This_option_only_applies_to_that_message')
            }`,
        ]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Common')}>
          <ManageButton
            editableState={isCommonFinancierAlertSettingFormEditable}
            setEditableState={setIsCommonFinancierAlertSettingFormEditable}
            confirmParameterState={commonAlertSettingState.selectedCommonFinancierAlertSetting}
          />
        </SectionTitle>
        <FormBorder editable={isCommonFinancierAlertSettingFormEditable}>
          <FormSubtitle title={t('text:Master_Agreement_Management')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <FinancierAlertSettingRadioForm
              financierAlertSettingList={commonAlertSettingState.selectedCommonFinancierAlertSetting}
              setFinancierAlertSettingList={commonAlertSettingState.setSelectedCommonFinancierAlertSetting}
              currentFormEditable={isCommonFinancierAlertSettingFormEditable}
            />
          </FormContents>
        </FormBorder>
      </div>
      {(collateralType === SUPPORTED_COLLATERAL_TYPE.AR || collateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) && (
        <div className="content-area">
          <SectionTitle title={t('text:Vendor_Finance_Program')}>
            <ManageButton
              editableState={isVendorFinanceProgramFinancierAlertSettingFormEditable}
              setEditableState={setIsVendorFinanceProgramFinancierAlertSettingFormEditable}
              confirmParameterState={[
                ...partnerManagementAlertState.selectedVendorFinanceProgramPartnerManagementFinancierAlertSetting,
                ...arRegistrationAlertState.selectedVendorFinanceProgramARRegistrationFinancierAlertSetting,
                ...vendorFinancingAlertState.selectedVendorFinanceProgramFinancingFinancierAlertSetting,
              ]}
            />
          </SectionTitle>
          <FormBorder editable={isVendorFinanceProgramFinancierAlertSettingFormEditable}>
            <FormSubtitle title={t('text:Partner_Management')} backGroundType={BackGroundType.DarkGray} />
            <FormContents>
              <FinancierAlertSettingRadioForm
                financierAlertSettingList={
                  partnerManagementAlertState.selectedVendorFinanceProgramPartnerManagementFinancierAlertSetting
                }
                setFinancierAlertSettingList={
                  partnerManagementAlertState.setSelectedVendorFinanceProgramPartnerManagementFinancierAlertSetting
                }
                currentFormEditable={isVendorFinanceProgramFinancierAlertSettingFormEditable}
              />
            </FormContents>
            <FormSubtitle title={t('text:AR_Registration')} backGroundType={BackGroundType.DarkGray} />
            <FormContents>
              <FinancierAlertSettingRadioForm
                financierAlertSettingList={
                  arRegistrationAlertState.selectedVendorFinanceProgramARRegistrationFinancierAlertSetting
                }
                setFinancierAlertSettingList={
                  arRegistrationAlertState.setSelectedVendorFinanceProgramARRegistrationFinancierAlertSetting
                }
                currentFormEditable={isVendorFinanceProgramFinancierAlertSettingFormEditable}
              />
            </FormContents>
            <FormSubtitle title={t('text:Financing')} backGroundType={BackGroundType.DarkGray} />
            <FormContents>
              <FinancierAlertSettingRadioForm
                financierAlertSettingList={
                  vendorFinancingAlertState.selectedVendorFinanceProgramFinancingFinancierAlertSetting
                }
                setFinancierAlertSettingList={
                  vendorFinancingAlertState.setSelectedVendorFinanceProgramFinancingFinancierAlertSetting
                }
                currentFormEditable={isVendorFinanceProgramFinancierAlertSettingFormEditable}
              />
            </FormContents>
          </FormBorder>
        </div>
      )}
      {(collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE || collateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) && (
        <div className="content-area">
          <SectionTitle title={t('text:Dealer_Finance_Program')}>
            <ManageButton
              editableState={isDealerFinanceProgramFinancierAlertSettingFormEditable}
              setEditableState={setIsDealerFinanceProgramFinancierAlertSettingFormEditable}
              confirmParameterState={[
                ...invoiceRegistrationAlertState.selectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting,
                ...dealerFinancingAlertState.selectedDealerFinanceProgramFinancingFinancierAlertSetting,
              ]}
            />
          </SectionTitle>
          <FormBorder editable={isDealerFinanceProgramFinancierAlertSettingFormEditable}>
            <FormSubtitle title={t('text:Invoice_Registration')} backGroundType={BackGroundType.DarkGray} />
            <FormContents>
              <FinancierAlertSettingRadioForm
                financierAlertSettingList={
                  invoiceRegistrationAlertState.selectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting
                }
                setFinancierAlertSettingList={
                  invoiceRegistrationAlertState.setSelectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting
                }
                currentFormEditable={isDealerFinanceProgramFinancierAlertSettingFormEditable}
              />
            </FormContents>
            <FormSubtitle title={t('text:Financing')} backGroundType={BackGroundType.DarkGray} />
            <FormContents>
              <FinancierAlertSettingRadioForm
                financierAlertSettingList={
                  dealerFinancingAlertState.selectedDealerFinanceProgramFinancingFinancierAlertSetting
                }
                setFinancierAlertSettingList={
                  dealerFinancingAlertState.setSelectedDealerFinanceProgramFinancingFinancierAlertSetting
                }
                currentFormEditable={isDealerFinanceProgramFinancierAlertSettingFormEditable}
              />
            </FormContents>
          </FormBorder>
        </div>
      )}
    </>
  );
}

export default FinancierEmailNotificationSetting;
