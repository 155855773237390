import type React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from 'components/stateless/Button/IconButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ATTACHMENT_TYPE } from 'enums';
import type { InvoiceAttachmentVOModel } from 'models/vo/InvoiceAttachmentVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { TempLoanVOModel } from 'models/vo/TempLoanVO';
import { requestDownloadInvoiceAttachment } from 'utils/http/api/dealer/invoice-attachments';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import AttachFileModal from '../../../modal/AttachFileModal';

interface ArInformationProps {
  dealerArDetail: SuccessArDetailVOModel;
  dealerTempLoanByAr: TempLoanVOModel;
  onChangeArInfoCheckBoxToggle?: (checked: boolean) => void;
  factoringEnable: boolean;
}

function ArInformation({
  dealerArDetail,
  dealerTempLoanByAr,
  onChangeArInfoCheckBoxToggle,
  factoringEnable,
}: ArInformationProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const { register, setValue } = useFormContext();
  const [arFileName, setArFileName] = useState<string | undefined>(undefined);
  const [arFileChanged, setArFileChanged] = useState(false);

  useEffect(() => {
    setArFileName(dealerTempLoanByAr.invoiceScannedAttachmentName);
  }, [dealerTempLoanByAr]);

  const setSelectedFileInfo = (data: InvoiceAttachmentVOModel) => {
    setArFileChanged(true);
    setArFileName(data.fileName);
    setValue('invoiceAttachmentId', data.invoiceAttachmentId, { shouldDirty: true });
  };

  const resetSelectedFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setArFileChanged(true);
    setArFileName(undefined);
    onChangeArInfoCheckBoxToggle?.(false);
    setValue('invoiceAttachmentId', null);
  };

  const showArUploadModal = () => {
    modal.show(
      <AttachFileModal
        getSelectedData={setSelectedFileInfo}
        modalId={modal.id}
        attachmentType={ATTACHMENT_TYPE.SCANNED_AR}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        title: t('text:Attach_File'),
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickArFileDownLoad = async () => {
    try {
      await requestDownloadInvoiceAttachment(dealerTempLoanByAr.invoiceAttachmentId);
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <SectionTitle title={t('text:AR_Information')} />

      <GuideMessage
        isImportContentArea={true}
        message={[
          t(
            'text:Check_the_information_of_the_AR_the_financing_application_is_based_on_and_upload_the_scanned_copy_of_the_tax_invoice_related_to_the_AR',
          ),
          t('text:Click_on_the_checkbox_to_confirm_the_AR_information_is_correct'),
        ]}
      />

      <FormBorder hideBorderBottom>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue col={3} label={t('text:AR_Number')} value={dealerArDetail?.arNumber} />
            <FormValue col={3} label={t('text:AR_Amount')} value={dealerArDetail?.arAmount} format="number" />
            <FormValue col={3} label={t('text:AR_Issued_Date')} value={dealerArDetail?.arIssuedDate} format="date" />
            <FormValue col={3} label={t('text:Settlement_Date')} value={dealerArDetail?.settlementDate} format="date" />
          </div>
          <div className="row">
            <FormValue label={t('text:Anchor_Name')} value={dealerArDetail?.anchorName} />
            <FormValue col={3} label={t('text:Partner_Name')} value={dealerArDetail?.anchorPartnerName} />
            <FormValue col={3} label={t('text:Currency')} value={dealerArDetail?.currencyType} />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:Scanned_Tax_Invoice_File')} isRequired={factoringEnable} />
        <div className="detail-in-file-upload-form">
          <div className="file-upload">
            <div className="d-flex justify-content-between">
              <label htmlFor="invoiceAttachmentFile" className="attach-file-link-button" onClick={showArUploadModal}>
                {t('text:Attach_File')}
              </label>
              <div id="fileName" className="upload-file-input">
                {arFileName ? arFileName : t('text:No_file_attached')}
                {arFileName && !arFileChanged && (
                  <IconButton className="download-button" onClick={onClickArFileDownLoad} type="button">
                    <FontAwesomeIcon icon={faDownload} />
                  </IconButton>
                )}
              </div>
              <IconButton
                onClick={resetSelectedFile}
                className={`delete-uploaded-excel-button ${!arFileName && 'd-none'}`}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
              <input type="hidden" name="invoiceAttachmentId" ref={register} />
            </div>
          </div>
        </div>
      </FormBorder>
    </>
  );
}

export default ArInformation;
