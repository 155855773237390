import type Pageable from 'models/Pageable';
import type { AnchorReportReceiverVO, AnchorReportReceiverVOModel } from 'models/vo/AnchorReportReceiverVO';
import { formattingToAnchorReportReceiverVOModel } from 'models/vo/AnchorReportReceiverVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type {
  SystemAnchorReportReceiverListRequest,
  SystemAnchorReportReceiverRelatedAgreementListRequest,
} from './requests';

export async function requestSystemAnchorReportReceiverList(
  pageNumber: number,
  rowCount: number,
  data: SystemAnchorReportReceiverListRequest,
): Promise<Pageable<AnchorReportReceiverVOModel[]>> {
  const response = await http.get<Pageable<AnchorReportReceiverVO[]>>({
    url: API_SY.ANCHOR_REPORT_RECEIVERS.ANCHOR_REPORT_RECEIVER_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorReportReceiverVOModel(data)),
  };
}

export async function requestSystemAnchorReportReceiverDetail(
  anchorReportReceiverId: number,
): Promise<AnchorReportReceiverVOModel> {
  const response = await http.get<AnchorReportReceiverVO>({
    url: API_SY.ANCHOR_REPORT_RECEIVERS.ANCHOR_REPORT_RECEIVER_DETAIL(anchorReportReceiverId),
  });

  return formattingToAnchorReportReceiverVOModel(response);
}

export async function requestSystemAnchorReportReceiverRelatedAgreementList(
  pageNumber: number,
  rowCount: number,
  anchorReportReceiverId: number,
  data?: SystemAnchorReportReceiverRelatedAgreementListRequest,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_SY.ANCHOR_REPORT_RECEIVERS.DEALER_AGREEMENT_LIST_RELATED_ANCHOR_REPORT_RECEIVER(anchorReportReceiverId),
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDealerAgreementVOModel(item)),
  };
}
