import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PPTableBorder from 'components/potential-partner/PPTable/PPTableBorder';
import type { PPHeaderType } from 'components/potential-partner/PPTable/PPTableHeader';
import PPTableHeader from 'components/potential-partner/PPTable/PPTableHeader';
import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import TableBody from 'components/stateless/Table/TableBody';
import TableFooter from 'components/stateless/Table/TableFooter';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import type { CURRENCY_TYPE } from 'enums';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';

import './PotentialPartnerArDetailTable.scss';

const BREAKPOINT_MEDIUM = 768;

interface PotentialPartnerArDetailTableProps {
  data: SuccessArVOModel[];
  arSummaryInfo: {
    currencyType: CURRENCY_TYPE;
    totalArCount: number;
    ltvTotalArAmount: number;
    totalArAmount: number;
  };
}

function PotentialPartnerArDetailTable({ data, arSummaryInfo }: PotentialPartnerArDetailTableProps) {
  const { t } = useTranslation(['format']);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [displayCount, setDisplayCount] = useState<number>(3);
  const [displayData, setDisplayData] = useState<SuccessArVOModel[]>();

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < BREAKPOINT_MEDIUM) {
      setDisplayData(data.slice(0, displayCount));
    } else {
      setDisplayData(data);
    }
  }, [data, screenWidth]);

  const handleClickMore = () => {
    if (displayCount + 3 > arSummaryInfo.totalArCount) {
      setDisplayCount(arSummaryInfo.totalArCount);
      setDisplayData(data.slice(0, displayCount + 3));
    } else {
      setDisplayCount(displayCount + 3);
      setDisplayData(data.slice(0, displayCount + 3));
    }
  };

  const AR_TABLE_LIST: PPHeaderType[] = [
    {
      headerText: t('text:AR_Number'),
    },
    {
      headerText: t('text:Issued_Date'),
    },
    {
      headerText: t('text:Settlement_Date'),
    },
    {
      headerText: t('text:AR_Amount'),
      textAlign: 'right',
    },
    {
      headerText: t('text:Financing_Available_Amount'),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <div className="pp-ar-lists__top__content">
        <div className="pp-ar-lists__title">
          {t('text:You_can_receive_financing_for_the_TotalArCount_AR(s)_below', {
            totalArCount: arSummaryInfo.totalArCount,
          })}
        </div>
        <div className="pp-ar-lists__currency">
          <div className="pp-ar-lists__currency__label">Currency: </div>
          <div className="pp-ar-lists__currency__value">{arSummaryInfo.currencyType}</div>
        </div>
      </div>
      <div className="pp-ar-lists__total">
        <div className="pp-ar-lists__total__item">
          <div className="pp-ar-lists__total__label">{t('text:Total_AR_Amount')}</div>
          <div className="pp-ar-lists__total__value">{t('format:number', { value: arSummaryInfo.totalArAmount })}</div>
        </div>
        <div className="pp-ar-lists__total__item">
          <div className="pp-ar-lists__total__label">{t('text:Total_Financing_Available_Amount')}</div>
          <div className="pp-ar-lists__total__value">
            {t('format:number', { value: arSummaryInfo.ltvTotalArAmount })}
          </div>
        </div>
      </div>
      <div className="pp-ar-list-table pp-table-overflow-scroll">
        <PPTableBorder>
          <PPTableHeader headers={AR_TABLE_LIST} />
          <TableBody numOfCol={AR_TABLE_LIST.length}>
            {displayData?.map((item, index) => (
              <Tr key={index}>
                <Td>
                  <div className="pp-table-td__label">{AR_TABLE_LIST[0].headerText}</div>
                  <div className="pp-table-td__data">{item.arNumber}</div>
                </Td>
                <Td>
                  <div className="pp-table-td__label">{AR_TABLE_LIST[1].headerText}</div>
                  <div className="pp-table-td__data">{t('format:date', { value: item.arIssuedDate, key: 'date' })}</div>
                </Td>
                <Td>
                  <div className="pp-table-td__label">{AR_TABLE_LIST[2].headerText}</div>
                  <div className="pp-table-td__data">
                    {t('format:date', { value: item.settlementDate, key: 'date' })}
                  </div>
                </Td>
                <Td>
                  <div className="pp-table-td__label">{AR_TABLE_LIST[3].headerText}</div>
                  <div className="pp-table-td__data text-right">{t('format:number', { value: item.arAmount })}</div>
                </Td>
                <Td>
                  <div className="pp-table-td__label">{AR_TABLE_LIST[4].headerText}</div>
                  <div className="pp-table-td__data text-right">{t('format:number', { value: item.ltvArAmount })}</div>
                </Td>
              </Tr>
            ))}
          </TableBody>
          <TableFooter>
            <Tr>
              <Td colSpan={3}>
                <span className="pp-table-td__total__label">Total</span>
              </Td>
              <Td data={arSummaryInfo.totalArAmount} format="number" />
              <Td data={arSummaryInfo.ltvTotalArAmount} format="number" />
            </Tr>
          </TableFooter>
        </PPTableBorder>
      </div>
      {displayCount < arSummaryInfo.totalArCount && (
        <div className="pp-show-more-btn-wrapper">
          <Button2
            size={Button2SizeEnum.LG}
            onClick={handleClickMore}
            icon={<FontAwesomeIcon icon={faArrowDown} fontSize={22} />}
            color={Button2ColorEnum.TERTIARY}
          >
            {t('text:See_More_AR(s)')}
          </Button2>
        </div>
      )}
    </>
  );
}

export default PotentialPartnerArDetailTable;
