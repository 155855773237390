import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE, REPORT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorReportReceiverVOModel } from 'models/vo/AnchorReportReceiverVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemAnchorReportReceiverList } from 'utils/http/api/system/anchor-report-receivers';
import type { SystemAnchorReportReceiverListRequest } from 'utils/http/api/system/anchor-report-receivers/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstant = () => {
  const { t } = useTranslation(['format']);

  const TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Recipient_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Email'),
      colWidths: 150,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Report_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Recipient_Status'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_PARTNER_REPORT_LIST_QS_KEY = 'sy-partner-report-list';

  return {
    t,
    TABLE_HEADERS,
    SY_PARTNER_REPORT_LIST_QS_KEY,
  };
};

function SystemMonitorPartnerReportList(): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();

  const { t, TABLE_HEADERS, SY_PARTNER_REPORT_LIST_QS_KEY } = getConstant();

  const { pageable, setPageable } = usePageable(SY_PARTNER_REPORT_LIST_QS_KEY);

  const [partnerReportPage, setPartnerReportPage] = useState<Pageable<AnchorReportReceiverVOModel[]>>();

  const { register, reset, getValues, setValue } = useForm<SystemAnchorReportReceiverListRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const getPartnerReportProperty = useProperty<SystemAnchorReportReceiverListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemAnchorReportReceiverListRequest>(
        setValue,
        getParsedSearchParams(SY_PARTNER_REPORT_LIST_QS_KEY).formSearchData,
      );
      fetchPartnerReportList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  const fetchPartnerReportList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
    searchParameters: SystemAnchorReportReceiverListRequest,
  ): Promise<void> => {
    try {
      const partnerReportList = await requestSystemAnchorReportReceiverList(
        selectedPageNumber,
        selectedRowCount,
        searchParameters,
      );
      updateSearchParams(
        {
          ...searchParameters,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowCount,
        },
        SY_PARTNER_REPORT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerReportPage(partnerReportList);
        setPageable(partnerReportList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await fetchPartnerReportList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    await fetchPartnerReportList(
      pageNumber,
      rowCount,
      getParsedSearchParams(SY_PARTNER_REPORT_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    reset();
  };

  const renderPartnerReportListTable = (): JSX.Element[] | JSX.Element | undefined => {
    const getSendReportText = (data?: boolean): string => {
      switch (data) {
        case true:
          return t('text:Active');
        case false:
          return t('text:Deactivated');
        default:
          return '';
      }
    };

    return partnerReportPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.financierName} />
        <Td data={item.name} />
        <Td data={item.email} />
        <Td data={item.anchorClientName} />
        <Td data={item.anchorAgreementContractNo} />
        <Td data={item.currencyType} />
        <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
        <Td data={t(`code:report-type.${item.reportType}`)} format="code" />
        <Td
          className={item.activated ? 'text-bold-blue-main600' : 'text-bold-brick-red'}
          data={getSendReportText(item.activated)}
        />
        <TdLink path={ROUTES_SY.MONITOR_PARTNER.REPORT_DETAIL_BUILD_PATH(item.anchorReportReceiverId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_Report_Recipient')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name={getPartnerReportProperty('financierName')} ref={register} />
              <SearchEmpty />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Recipient_Name')} />
              <SearchInput name={getPartnerReportProperty('name')} ref={register} />
              <SearchLabel label={t('text:Email')} />
              <SearchInput name={getPartnerReportProperty('email')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name={getPartnerReportProperty('anchorClientName')} ref={register} />
              <SearchLabel label={t('text:Associated_Anchor_Master_Agreement_Number')} />
              <SearchInput name={getPartnerReportProperty('anchorAgreementContractNo')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name={getPartnerReportProperty('currencyType')}
                ref={register}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name={getPartnerReportProperty('collateralType')}
                ref={register}
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Report_Type')} />
              <SearchSelect
                name={getPartnerReportProperty('reportType')}
                ref={register}
                selectOptions={getSelectOptions<REPORT_TYPE>('REPORT_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Recipient_Status')} />
              <SearchSelect
                name={getPartnerReportProperty('activated')}
                ref={register}
                selectOptions={getSelectOptions<string>('SEND_REPORT', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {partnerReportPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={TABLE_HEADERS} />
          <TableBody numOfCol={TABLE_HEADERS.length}>{renderPartnerReportListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemMonitorPartnerReportList;
