import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import useIntersectionObserver from 'hooks/useIntersectionObserver';

import Spinner from '../Spinner/Spinner';

interface InfiniteScrollPropsType {
  children: ReactNode;
  trigger: () => void;
  pageNumber: number;
  totalPageCount: number;
  isLoaded: boolean;
  colSpan?: number;
}

export const InfiniteScroll = ({
  pageNumber,
  totalPageCount,
  trigger,
  isLoaded,
  colSpan = 3,
  children,
}: InfiniteScrollPropsType) => {
  const needMoreList = totalPageCount > pageNumber;

  const target = useRef<HTMLTableRowElement>(null);

  const [observe, disconnect] = useIntersectionObserver<HTMLTableRowElement>({
    target,
    callback: () => needMoreList && trigger(),
    customOptions: { threshold: 1 },
  });

  useEffect(() => {
    observe();

    return () => disconnect();
  }, [children]);

  return (
    <>
      {children}
      {!isLoaded && (
        <tr>
          <td colSpan={colSpan}>
            <Spinner />
          </td>
        </tr>
      )}
      {isLoaded && needMoreList && <tr style={{ height: '1px' }} ref={target} />}
    </>
  );
};
