import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ENTERPRISE_TYPE, WAITING_INVOICE_APPROVAL_PHASE_STATUS } from 'enums';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';

type UseProgressStatusReturnType = {
  progressStatusClassName: string;
  progressStatusText: string;
  renderProgressStatus: () => ReactNode;
  progressReviewedTimeText: string;
};

const useProgressStatus = (
  summary: any,
  summaryPhase?: any,
  invoiceOrAp: 'Invoice' | 'Ar' = 'Invoice',
): UseProgressStatusReturnType => {
  const { t } = useTranslation(['format']);

  const progressStatus = (() => {
    if (summaryPhase?.inProgress) return WAITING_INVOICE_APPROVAL_PHASE_STATUS.IN_PROGRESS;
    if (summaryPhase)
      return invoiceOrAp === 'Invoice' ? summaryPhase.invoiceApprovalPhaseStatus : summaryPhase.arApprovalPhaseStatus;
    if (summary?.rejected) return WAITING_INVOICE_APPROVAL_PHASE_STATUS.REJECTED;
    if (summary?.inProgress) return WAITING_INVOICE_APPROVAL_PHASE_STATUS.IN_PROGRESS;

    return invoiceOrAp === 'Invoice'
      ? summary?.currentInvoiceApprovalPhaseStatus
      : summary?.currentArApprovalPhaseStatus;
  })();

  const progressStatusClassName = getStatusBadgeClass('WAITING_INVOICE_APPROVAL_PHASE_STATUS', progressStatus);

  const progressStatusText = (() => {
    switch (progressStatus) {
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED:
        return t('text:Input');
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REQUESTED:
        return t('text:Registration_Request_by_Partner');
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REGISTERED:
        return summary?.currentConfirmEnterpriseType === ENTERPRISE_TYPE.FI
          ? t('text:Reviewed_by_Financier_Operator')
          : t('text:Reviewed_by_Anchor');
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.CONFIRMED:
        return summary?.currentConfirmEnterpriseType === ENTERPRISE_TYPE.FI
          ? t('text:Registered_by_Financier')
          : t('text:Registered_by_Anchor');
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REJECTED:
        return t('text:Rejected_by') + ' ' + t(`code:enterprise-type.${summary?.rejectEnterpriseType}`);
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.IN_PROGRESS:
        return t('text:In_Progress');
      default:
        return '';
    }
  })();

  const renderProgressStatus = () => (
    <>
      <div className={`${progressStatusClassName}`}>{progressStatusText}</div>
      {summary?.rejected && (
        <div className="invoice-rejected-time">
          ( {t('format:datetime', { value: summary?.rejectedDateTime, key: 'datetime' })} )
        </div>
      )}
    </>
  );

  const progressReviewedTimeText = (() => {
    if (summaryPhase?.inProgress) return t('text:Registered_Date');

    switch (progressStatus) {
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED:
        return t('text:Input_Date');
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REQUESTED:
        return t('text:Requested_Date');
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.CONFIRMED:
        return t('text:Registered_Date');
      case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REGISTERED:
        return t('text:Reviewed_Date');
      default:
        return '';
    }
  })();

  return {
    progressStatusClassName,
    progressStatusText,
    renderProgressStatus,
    progressReviewedTimeText,
  };
};

export default useProgressStatus;
