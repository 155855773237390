import useAnchorAgreementInfoViewModel from '../../models/useAnchorAgreementInfoViewModel';
import useFinancingOptionViewModel from '../../models/useFinancingOptionViewModel';

const useFinancierController = () => {
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();

  const { anchorFinancingOption } = useFinancingOptionViewModel();

  return {
    data: {
      financierName: anchorFinancingOption.financierName,
      branchCode: anchorAgreementInfo.branchCode,
      branchName: anchorAgreementInfo.branchName,
      branchTelephone: anchorAgreementInfo.branchTelephone,
      branchFax: anchorAgreementInfo.branchFax,
      branchAddress: anchorAgreementInfo.branchAddress,
    },
  };
};

export default useFinancierController;
