import { formattingToTermsOfUseVersionVOModel } from './TermsOfUseVersionVO';
import { ENTERPRISE_TYPE, stringToEnum } from '../../enums';

import type { TermsOfUseVersionVO, TermsOfUseVersionVOModel } from './TermsOfUseVersionVO';

export interface TermsOfUseDetailVO {
  termsOfUseId: number;
  enterpriseId: number;
  enterpriseName: string;
  enterpriseType: string;
  termsOfUseVersionList: TermsOfUseVersionVO[];
}

export interface TermsOfUseDetailVOModel extends TermsOfUseDetailVO {
  enterpriseType: ENTERPRISE_TYPE;
  termsOfUseVersionList: TermsOfUseVersionVOModel[];
}

export function formattingToTermsOfUseDetailVOModel(data: TermsOfUseDetailVO): TermsOfUseDetailVOModel {
  return {
    ...data,
    enterpriseType: stringToEnum(ENTERPRISE_TYPE, data.enterpriseType),
    termsOfUseVersionList: data.termsOfUseVersionList.map(item => formattingToTermsOfUseVersionVOModel(item)),
  };
}
