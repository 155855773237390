export interface TempLoanEtcAttachmentVO {
  tempLoanEtcAttachmentId: number;
  tempLoanEtcAttachmentName: string;
  tempLoanEtcAttachmentPath: string;
  description: string;
}

export interface TempLoanEtcAttachmentVOModel extends TempLoanEtcAttachmentVO {}

export function formattingToTempLoanEtcAttachmentVOModel(data: TempLoanEtcAttachmentVO): TempLoanEtcAttachmentVOModel {
  return {
    ...data,
  };
}
