import type Pageable from 'models/Pageable';
import type { SuccessInvoiceDetailVO, SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { formattingToSuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import type { SuccessInvoiceVO, SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import { formattingToSuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierSuccessInvoiceBlockRequest, FinancierSuccessInvoiceListRequest } from './request';

// 성공 송장 리스트 가져오기
export async function requestFinancierSuccessInvoiceList(
  pageNumber: number,
  rowCount: number,
  financierSuccessInvoiceListRequest: FinancierSuccessInvoiceListRequest,
): Promise<Pageable<SuccessInvoiceVOModel[]>> {
  const response = await http.get<Pageable<SuccessInvoiceVO[]>>({
    url: API_FI.SUCCESS_INVOICES.SUCCESS_INVOICE,
    data: {
      pageNumber,
      rowCount,
      ...financierSuccessInvoiceListRequest,
    },
  });

  const successInvoiceListData = response.content.map(successInvoice =>
    formattingToSuccessInvoiceVOModel(successInvoice),
  );

  return {
    ...response,
    content: successInvoiceListData,
  };
}

// 상세 조회
export async function requestFinancierSuccessInvoice(successInvoiceId: number): Promise<SuccessInvoiceDetailVOModel> {
  const response = await http.get<SuccessInvoiceDetailVO>({
    url: API_FI.SUCCESS_INVOICES.SUCCESS_INVOICE_DETAIL(successInvoiceId),
  });

  return formattingToSuccessInvoiceDetailVOModel(response);
}

// 파일 다운로드
export async function requestFinancierDownloadScannedInvoice(successInvoiceId: number) {
  await http.download(API_FI.SUCCESS_INVOICES.DOWNLOAD_SUCCESS_INVOICE(successInvoiceId));
}

// 송장 블락
export async function requestFinancierBlockSuccessInvoice(
  successInvoiceId: number,
  financierSuccessInvoiceBlockRequest: FinancierSuccessInvoiceBlockRequest,
): Promise<{}> {
  const response = await http.post<{}>({
    url: API_FI.SUCCESS_INVOICES.BLOCK_APPLYING_FOR_LOAN(successInvoiceId),
    data: financierSuccessInvoiceBlockRequest,
  });

  return response;
}

// 송장 언블락
export async function requestFinancierUnblockSuccessInvoice(successInvoiceId: number): Promise<{}> {
  const response = await http.post<{}>({
    url: API_FI.SUCCESS_INVOICES.UNBLOCK_APPLYING_FOR_LOAN(successInvoiceId),
  });

  return response;
}
