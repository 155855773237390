import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { FINANCIER_INTERFACE_TYPE, SUCCESS_AR_STATUS } from 'enums';
import { LOAN_STATUS } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { PartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierInterfaceSetting } from 'utils/http/api/common/financier-interface-setting';
import { requestDealerDownloadScannedAr } from 'utils/http/api/dealer/loans';
import {
  requestDealerPartialCollaterals,
  requestDealerRenewArByFinancierInterface,
  requestDealerSuccessArsDetail,
} from 'utils/http/api/dealer/success-ars';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getFilteredDisbursedAmount } from 'utils/valueManager/ValueManager';

function DealerArDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { successArId } = useParams<any>();
  const { pageable: financingInfoPageable, setPageable: setFinancingInfoPageable } = usePageable();
  const [locationState, errorHandlerOfLocationState] = useLocationState<{ financierId: number }>(['financierId']);
  const { financierId } = locationState;
  const [dealerArDetail, setDealerArDetail] = useState<SuccessArDetailVOModel>();
  const [arRepaymentInterface, setArRepaymentInterface] = useState<boolean>();
  const [financingInfo, setFinancingInfo] = useState<Pageable<PartialCollateralVOModel[]>>();

  const fetchAll = async () => {
    try {
      const [fetchedDealerArDetailPage, arRepaymentInterfaceType, financingInfoResponse] = await Promise.all([
        requestDealerSuccessArsDetail(successArId),
        requestFinancierInterfaceSetting(financierId, FINANCIER_INTERFACE_TYPE.AR_PAYMENT_RESULT),
        requestDealerPartialCollaterals(
          financingInfoPageable.currentPage,
          financingInfoPageable.sizePerPage,
          successArId,
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerArDetail(fetchedDealerArDetailPage);
        setArRepaymentInterface(arRepaymentInterfaceType);
        setFinancingInfo(financingInfoResponse);
        setFinancingInfoPageable(financingInfoResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const FINANCING_INFORMATION_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 150,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 150,
    },
    {
      headerText: t('text:Disbursement_Amount'),
      colWidths: 150,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Repaid_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Tax_Invoice_File'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickLoanStatus,
    },
    {
      headerText: '',
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderRenewTransactionButton = () => {
    const fetchRenewArByFinancierInterface = async () => {
      try {
        await requestDealerRenewArByFinancierInterface(dealerArDetail?.successArId!);
      } catch (e) {
        modal.show(e);
      }
    };

    const onClickRenewTransaction = async (e: any) => {
      e.preventDefault();

      fetchRenewArByFinancierInterface().then(() => fetchAll());
    };

    if (
      arRepaymentInterface &&
      dealerArDetail?.paymentSupport &&
      (dealerArDetail?.successArStatus === SUCCESS_AR_STATUS.REGISTERED ||
        dealerArDetail?.successArStatus === SUCCESS_AR_STATUS.FAILED)
    ) {
      return (
        <GuideMessage
          message={[t('text:Please_click_Renew_Transaction_button_to_update_transaction_status')]}
          isImportContentArea={true}
        >
          {{
            button: <Button onClick={onClickRenewTransaction}>{t('text:Renew_Transaction')}</Button>,
          }}
        </GuideMessage>
      );
    } else {
      return null;
    }
  };

  const paginateFinancingInfo = async (pageNumber: number, rowCount: number) => {
    try {
      const financingInfoResponse = await requestDealerPartialCollaterals(pageNumber, rowCount, successArId);

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancingInfo(financingInfoResponse);
        setFinancingInfoPageable(financingInfoResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickDownloadScannedAr = async (loanId: number) => {
    try {
      await requestDealerDownloadScannedAr(loanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const showSettlementStatusDescription = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_AR_STATUS)}
        statusDescriptionEnumType="SUCCESS_AR_STATUS"
        statusDescriptionModalType="TEXT"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
      },
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AR_Details')} />
      {dealerArDetail?.blockedByFinancier && (
        <GuideMessage
          message={[t('text:The_AR_has_been_blocked'), t('text:Please_check_the_reason_for_block_below')]}
          messageType={MessageType.ALERT}
          reasonTitle={t('text:REASON_FOR_BLOCK')}
          reason={dealerArDetail.blockedReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:AR_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:AR_Amount')}
                className="information-form__input border-none bold-font"
                col={3}
                value={dealerArDetail?.arAmount}
                format="number"
              />
              <FormValue
                label={t('text:Currency')}
                className="information-form__input border-none bold-font"
                col={3}
                value={dealerArDetail?.currencyType}
              />
              <FormValue
                label={t('text:Financing_Eligibility')}
                className={`information-form__input border-none ${getBlockedStatusClassName(
                  dealerArDetail?.blockedByFinancier,
                )}`}
                col={3}
                value={dealerArDetail?.blockedByFinancier ? t('text:Ineligible') : t('text:Eligible')}
              />
              <FormValue
                label={t('text:Settlement_Status')}
                className={
                  'information-form__input border-none ' +
                  getStatusTextClass('SUCCESS_AR_STATUS', dealerArDetail?.successArStatus)
                }
                col={3}
                value={t(`code:success-ar-status.${dealerArDetail?.successArStatus}`)}
                format="code"
                hasStatusDescription
                showStatusDescriptionFunc={showSettlementStatusDescription}
              />
            </div>
          </FormContents>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:AR_Number')} col={3} value={dealerArDetail?.arNumber} />
              <FormValue label={t('text:AR_Issued_Date')} col={3} value={dealerArDetail?.arIssuedDate} format="date" />
              <FormValue
                label={t('text:Original_Settlement_Date')}
                col={3}
                value={dealerArDetail?.maturityDate}
                format="date"
              />
              <FormValue
                label={t('text:Modified_Settlement_Date')}
                col={3}
                value={dealerArDetail?.settlementDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={dealerArDetail?.anchorName} />
              <FormValue label={t('text:Partner_Name')} value={dealerArDetail?.anchorPartnerName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Financing_Information')} />
        <TableBorder>
          <TableHeader header={FINANCING_INFORMATION_HEADERS} />
          <TableBody numOfCol={FINANCING_INFORMATION_HEADERS.length}>
            {financingInfo?.content?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.loanId} />
                <Td data={item.netDisbursementAmount} format="number" />
                <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
                <Td data={item.disbursedDate} format="date" />
                <Td data={item.repaidDate} format="date" />
                <Td data={item.attachmentName} />
                <Td
                  data={t(`code:financing-status.${item.loanStatus}`)}
                  className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
                  format="code"
                />
                <Td className="text-center">
                  <DownloadButton
                    onClick={() => onClickDownloadScannedAr(item.loanId)}
                    disabled={!item.attachmentId && !item.attachmentName}
                  />
                </Td>
                <TdLink path={ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(item.loanId)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={financingInfoPageable} paginate={paginateFinancingInfo} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Scheduled_AR_Settlement_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Scheduled_Settlement_Amount')}
                value={dealerArDetail?.arAmount}
                format="number"
              />
              <FormValue
                label={t('text:Scheduled_Settlement_Date')}
                value={dealerArDetail?.settlementDate}
                format="date"
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Withdrawal_Bank_Account_Information_Anchor')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={dealerArDetail?.anchorName} />
              <FormValue label={t('text:Agreement_Number')} value={dealerArDetail?.anchorContractNo} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Automatic_Settlement')}
                value={
                  dealerArDetail?.paymentSupport
                    ? `${t('text:Yes')} (${t('text:Co_operation&Payment')})`
                    : `${t('text:No')} (${t('text:Co_operation')})`
                }
              />
              <FormValue label={t('text:Bank_Name')} value={dealerArDetail?.anchorSettlementBankName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} value={dealerArDetail?.anchorSettlementAccount} />
              <FormValue label={t('text:Account_Holder')} value={dealerArDetail?.anchorSettlementAccountOwner} />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Deposit_Bank_Account_Information_Partner')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Partner_Name')} value={dealerArDetail?.anchorPartnerName} />
              <FormValue label={t('text:Bank_Name')} value={dealerArDetail?.partnerRequestedBankName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} value={dealerArDetail?.partnerRequestedAccount} />
              <FormValue label={t('text:Account_Holder')} value={dealerArDetail?.partnerRequestedAccountOwner} />
            </div>
          </FormContents>
          {/* 파트너 약정 존재할 경우 해당 섹션 표시 */}
          {dealerArDetail?.dealerContractNo ? (
            <FormContents>
              <div className="row text-bold-brick-red">
                {t(
                  'text:*_If_the_Partner_has_a_master_agreement_the_settlement_will_be_made_to_the_bank_account_below',
                )}
              </div>
              <div className="row">
                <FormValue col={6} label={t('text:Agreement_Number')} value={dealerArDetail?.dealerContractNo} />
                <FormValue col={6} label={t('text:Bank_Name')} value={dealerArDetail?.principalRepaymentBankName} />
              </div>
              <div className="row">
                <FormValue col={6} label={t('text:Account_Number')} value={dealerArDetail?.principalRepaymentAccount} />
                <FormValue
                  col={6}
                  label={t('text:Account_Holder')}
                  value={dealerArDetail?.principalRepaymentAccountOwner}
                />
              </div>
              <div className="row" />
            </FormContents>
          ) : null}
        </FormBorder>
      </div>
      {dealerArDetail?.paymentSupport ? (
        <div className="content-area">
          <SectionTitle title={t('text:AR_Settlement_Result')} />
          {renderRenewTransactionButton()}
          <FormBorder>
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue
                  label={t('text:Settlement_Status')}
                  col={6}
                  value={t(`code:success-ar-status.${dealerArDetail?.successArStatus}`)}
                  className={
                    'information-form__input border-none ' +
                    getStatusTextClass('SUCCESS_AR_STATUS', dealerArDetail.successArStatus)
                  }
                  format="code"
                />
              </div>
            </FormContents>
            <FormSubtitle
              title={t('text:Bank_Information_of_AR_Settlement')}
              backGroundType={BackGroundType.DarkGray}
            />
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue label={t('text:Anchor_Name')} value={dealerArDetail?.settledAnchorEnterpriseName} />
                <FormValue label={t('text:Bank_Name')} value={dealerArDetail?.settledAnchorBankName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={dealerArDetail?.settledAnchorAccount} />
                <FormValue label={t('text:Account_Holder')} value={dealerArDetail?.settledAnchorAccountOwner} />
              </div>
            </FormContents>
            <FormContents backGroundType={BackGroundType.Gray}>
              <div className="row">
                <FormValue label={t('text:Partner_Name')} value={dealerArDetail?.settledPartnerName} />
                <FormValue label={t('text:Bank_Name')} value={dealerArDetail.settledPartnerBankName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={dealerArDetail?.settledPartnerAccount} />
                <FormValue label={t('text:Account_Holder')} value={dealerArDetail?.settledPartnerAccountOwner} />
              </div>
            </FormContents>
          </FormBorder>
        </div>
      ) : null}
    </>
  );
}

export default DealerArDetail;
