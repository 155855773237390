import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface AnchorFinancingOptionForRequestMultipleAnchorLoanVO {
  anchorAgreementId: number;
  anchorFinancingOptionId: number;
  financierId: number;
  financierName: string;
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
  totalAmount: string;
  totalCount: number;
  maxLtvRatio: number;
}

export interface AnchorFinancingOptionForRequestMultipleAnchorLoanVOModel
  extends AnchorFinancingOptionForRequestMultipleAnchorLoanVO {
  totalAmount: BigNumber;
}

export function formattingToAnchorFinancingOptionForRequestMultipleAnchorLoanVOModel(
  data: AnchorFinancingOptionForRequestMultipleAnchorLoanVO,
): AnchorFinancingOptionForRequestMultipleAnchorLoanVOModel {
  return {
    ...data,
  };
}
