import type { ReactElement, ReactNode } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';

import { HttpError } from 'utils/error/HttpError';
import { InvalidError } from 'utils/error/InvalidError';
import { getHttpErrorExceptionMessage } from 'utils/text';

import './ModalWrapper.scss';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from '../../components/stateless/Button/Button';
import { browserHistory } from '../../router/browserHistory';
import { homePathByRole } from '../route';
import { getSignIn } from '../storage/LocalStorage';

export enum ModalType {
  CONFIRM,
  ALERT,
}

export enum ModalSize {
  NONE = '',
  XL = 'modal-xl',
  L = 'modal-lg',
  S = 'modal-sm',
  DIGITAL_SIGNATURE = 'modal-xl digital-signature',
}

export interface ModalOptions {
  modalType?: ModalType;
  modalSize?: ModalSize;
  dataOfContent?: object;
  title?: string;
  confirmBtnText?: string;
  closeBtnText?: string;
  confirmBtnCb?: Function;
  closeBtnCb?: Function;
  confirmBtnDisabled?: boolean;
  isModalClosesOnConfirm?: boolean;
}

interface ModalWrapperProps {
  modalId: number;
  children: ReactNode;
  close: Function;
  visible: boolean;
  options: ModalOptions | null;
}

export const getOptions = (options: ModalOptions | null): ModalOptions => {
  const initialOptions = {
    modalType: ModalType.ALERT,
    modalSize: ModalSize.NONE,
    title: 'Notice',
    confirmBtnText: 'Confirm',
    closeBtnText: options?.modalType === ModalType.CONFIRM ? 'Close' : 'OK',
    confirmBtnDisabled: false,
    isModalClosesOnConfirm: true,
  };

  return { ...initialOptions, ...options };
};

const ModalWrapper = ({ modalId, children, close, visible, options }: ModalWrapperProps) => {
  const { t } = useTranslation();
  const {
    closeBtnCb,
    confirmBtnCb,
    isModalClosesOnConfirm,
    closeBtnText,
    confirmBtnText,
    confirmBtnDisabled,
    title,
    modalSize,
    modalType,
  } = useMemo(() => getOptions(options), [options]);

  const isRouteToLoginPageExceptions: string[] = ['ATN-001', 'ATN-003'];
  const modalContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    browserHistory.listen(() => {
      close(modalId);
    });
  }, []);

  const onClose = () => {
    close(modalId);

    if (children instanceof HttpError && isRouteToLoginPageExceptions.includes(children.code)) {
      browserHistory.push('/');
    } else if (children instanceof HttpError && children.code === 'IIV-016') {
      const signInModel = getSignIn();
      signInModel
        ? browserHistory.push(homePathByRole(signInModel.authorities[0].authority))
        : browserHistory.push('/');
    } else if (closeBtnCb) {
      closeBtnCb();
    }
  };

  const onConfirm = () => {
    if (isModalClosesOnConfirm) close(modalId);

    if (confirmBtnCb) {
      const reactElement = children as ReactElement;
      const props = reactElement?.props;
      confirmBtnCb(props);
    }
  };

  const content =
    children instanceof InvalidError ? (
      <h6 dangerouslySetInnerHTML={{ __html: t(`validation:${children.message}`) }} />
    ) : children instanceof HttpError ? (
      <h6 dangerouslySetInnerHTML={{ __html: getHttpErrorExceptionMessage(children) }} />
    ) : children instanceof Error ? (
      children.message
    ) : (
      children
    );

  const buttonType1 = (
    <Button size={ButtonSizeEnum.MD} onClick={onClose} className="text-bold">
      {closeBtnText}
    </Button>
  );
  const buttonType2 = (
    <>
      <Button
        size={ButtonSizeEnum.MD}
        onClick={onClose}
        variant={ButtonVariantEnum.OUTLINED}
        color={ButtonColorEnum.SECONDARY}
        id="close"
        className="text-bold"
      >
        {closeBtnText}
      </Button>
      <Button
        size={ButtonSizeEnum.MD}
        onClick={onConfirm}
        id="confirm"
        className="text-bold"
        disabled={confirmBtnDisabled}
      >
        {confirmBtnText}
      </Button>
    </>
  );
  let buttonType = buttonType1;
  if (modalType === ModalType.CONFIRM) {
    buttonType = buttonType2;
  }

  useEffect(() => {
    const getHtmlElement = (selectors: string) => {
      return modalContentRef?.current?.querySelector<HTMLElement>(selectors);
    };

    const toBeFocusedHtmlElement =
      (getHtmlElement('input[type=text]') && getHtmlElement('input[type="text"]:not([name*="Date"])')) ??
      getHtmlElement('textarea') ??
      getHtmlElement('button#confirm') ??
      getHtmlElement('button#close') ??
      (getHtmlElement('button') as HTMLButtonElement);
    toBeFocusedHtmlElement.focus();
  }, []);

  return (
    <div
      className={clsx('modal fade modal-background', {
        'show d-block': visible,
        'd-none': !visible,
      })}
      id={`Modal-${modalId}`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className={clsx('modal-dialog modal-dialog-scrollable', [modalSize])}>
        <div className="modal-content" ref={modalContentRef}>
          <div className="modal-header">
            <h5 className="modal-title" id="ConfirmLabel">
              {title}
            </h5>
          </div>
          <div className="modal-body scrollbar">
            <div className="modal-container" data-testid="modalContainerId">
              {content}
            </div>
          </div>
          <div className="modal-footer">{buttonType}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
