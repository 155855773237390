import { formattingToInventoryItemVOModel } from './InventoryItemVO';

import type { InventoryItemVO, InventoryItemVOModel } from './InventoryItemVO';

export interface InventorySummaryDetailVO {
  inventorySummaryId: number;
  totalCount: number;
  totalAmount: number;
  attachmentId: number;
  fileName: string;
  financierId: number;
  financierName: string;
  uploadUserId: number;
  uploadUserName: string;
  inventoryItems: InventoryItemVO[];
  createdDateTime: string;
  attachmentDescription: string;
  dealerClientName: string;
  dealerClientCode: string;
}

export interface InventorySummaryDetailVOModel extends InventorySummaryDetailVO {
  inventoryItems: InventoryItemVOModel[];
}

export function formattingToInventorySummaryDetailVOModel(
  data: InventorySummaryDetailVO,
): InventorySummaryDetailVOModel {
  const inventoryItemList: InventoryItemVOModel[] = [];
  for (const obj of data.inventoryItems) {
    inventoryItemList.push(formattingToInventoryItemVOModel(obj));
  }

  return {
    ...data,
    inventoryItems: inventoryItemList,
  };
}
