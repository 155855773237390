import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import { FormErrorMessage } from 'components/stateless/Form/ErrorMessage';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';

import useFinancierController from './useFinancierController';

const Financier = () => {
  const { t } = useTranslation();
  const { register, errors, handleSearchBranchClick, data, isShowSearchBranchButton } = useFinancierController();
  const { financierName, branchCode, branchName, branchTelephone, branchFax, branchAddress } = data;

  return (
    <>
      <SubTitle>{t('text:FINANCIER')}</SubTitle>
      <Content>
        <Row>
          <Form.Label>{t('text:Financier_Name')}</Form.Label>
          <Form.Value value={financierName} />
        </Row>
        <Row singleColumnPosition={isShowSearchBranchButton ? 'bottom' : 'all'}>
          <Cell>
            <Form.Label required={isShowSearchBranchButton}>{t('text:Responsible_Branch')}</Form.Label>
            <Form.Description>
              {t('text:This_branch_manges_the_Partner_Master_Agreement_and_associated_transactions')}
            </Form.Description>
          </Cell>
          {isShowSearchBranchButton && (
            <div className="flex-align-center">
              <Button className="me-2" size={ButtonSizeEnum.MD} onClick={handleSearchBranchClick}>
                {t('text:Search_Branch')}
              </Button>
              {errors?.branchId && <FormErrorMessage error={errors.branchId} />}
            </div>
          )}
          <Border>
            <Content isInnerStyle={true}>
              <input type="hidden" name="branchId" ref={register} />
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Branch_Code')}</Form.Label>
                  <Form.Value value={branchCode} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Branch_Name')}</Form.Label>
                  <Form.Value value={branchName} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Telephone')}</Form.Label>
                  <Form.Value value={branchTelephone} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Fax')}</Form.Label>
                  <Form.Value value={branchFax} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Registered_Office_Address')}</Form.Label>
                  <Form.Value value={branchAddress} />
                </Form.Control>
              </Row>
            </Content>
          </Border>
        </Row>
      </Content>
    </>
  );
};

export default Financier;
