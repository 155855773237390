import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

export async function requestDealerTempAccountAttachmentDownload(tempPurposeOfLoanId: number) {
  await http.download(API_DE.TEMP_PURPOSE_OF_LOANS.DOWNLOAD_ACCOUNT_ATTACHMENT(tempPurposeOfLoanId));
}

export async function requestDealerTempPurposeAttachmentDownload(tempPurposeOfLoanId: number) {
  await http.download(API_DE.TEMP_PURPOSE_OF_LOANS.DOWNLOAD_PURPOSE_ATTACHMENT(tempPurposeOfLoanId));
}
