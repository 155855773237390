import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM, { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import type { REPORT_TYPE } from 'enums';
import { AUTHORITY_TYPE, REPORT_RECEIVER_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorReportSettingVOModel } from 'models/vo/AnchorReportSettingVO';
import type { AnchorReportVOModel } from 'models/vo/AnchorReportVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { AnchorReportListRequest } from 'utils/http/api/financier/anchor-reports/requests';
import { requestSystemAnchorAgreementData } from 'utils/http/api/system/anchor-agreements';
import { requestSyAnchorReportDownload, requestSyAnchorReportList } from 'utils/http/api/system/anchor-report';
import { requestSystemAnchorReportSettingData } from 'utils/http/api/system/anchor-report-settings';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);
  const RELATED_ANCHOR_USER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:User_Name'),
      colWidths: 140,
    },
    {
      headerText: t('text:Email'),
      colWidths: 120,
    },
    {
      headerText: t('text:Authority'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registration_Status'),
      colWidths: 100,
    },
  ];
  const ANCHOR_REPORT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Created_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Sent_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Email'),
    },
    {
      headerText: '',
      colWidths: 140,
    },
  ];

  return {
    mounted,
    modal,
    history,
    t,
    RELATED_ANCHOR_USER_TABLE_HEADERS,
    ANCHOR_REPORT_TABLE_HEADERS,
  };
};

function SystemMonitorAnchorReportDetail(): JSX.Element {
  const { mounted, modal, history, t, RELATED_ANCHOR_USER_TABLE_HEADERS, ANCHOR_REPORT_TABLE_HEADERS } = getConstants();
  const { anchorReportSettingId } = useParams() as any;
  const [locationState, errorHandlerOfLocationState] = useLocationState<{
    anchorAgreementId: number;
    reportType: REPORT_TYPE;
  }>(['anchorAgreementId', 'reportType']);
  const { anchorAgreementId, reportType } = locationState;

  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementDetailVOModel>();
  const [anchorReportSettingData, setAnchorReportSettingData] = useState<AnchorReportSettingVOModel>();
  const [anchorReportPage, setAnchorReportPage] = useState<Pageable<AnchorReportVOModel[]>>();

  const { pageable: anchorReportPageable, setPageable: setAnchorReportPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [anchorReportSettingData, anchorAgreementData, anchorReportPage] = await Promise.all([
        requestSystemAnchorReportSettingData(anchorReportSettingId),
        requestSystemAnchorAgreementData(anchorAgreementId),
        requestSyAnchorReportList<AnchorReportListRequest>(
          anchorReportPageable.currentPage,
          anchorReportPageable.sizePerPage,
          {
            anchorAgreementId,
            reportType,
            reportReceiverType: REPORT_RECEIVER_TYPE.ANCHOR_USER,
          },
        ),
      ]);

      unstable_batchedUpdates(() => {
        setAnchorReportSettingData(anchorReportSettingData);
        setAnchorAgreementData(anchorAgreementData);
        setAnchorReportPage(anchorReportPage);
        setAnchorReportPageable(anchorReportPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getReportStatusText = (isActivated?: boolean): string => {
    switch (isActivated) {
      case true:
        return t('text:Active');
      case false:
        return t('text:Deactivated');
      default:
        return '';
    }
  };

  const getReportStatusClassName = (isActivated?: boolean): string => {
    switch (isActivated) {
      case true:
        return 'text-bold-blue-main600';
      case false:
        return 'text-bold-brick-red';
      default:
        return '';
    }
  };

  const onClickGoToAgreementDetails = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    history.push(ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(anchorAgreementId), {
      supportedCollateralType: anchorAgreementData?.collateralType,
    });
  };

  const anchorReportPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const anchorReportPage = await requestSyAnchorReportList<AnchorReportListRequest>(pageNumber, rowCount, {
        anchorAgreementId,
        reportType,
        reportReceiverType: REPORT_RECEIVER_TYPE.ANCHOR_USER,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorReportPage(anchorReportPage);
        setAnchorReportPageable(anchorReportPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderAnchorUserInformationTableBody = (): JSX.Element[] | undefined => {
    const getAuthorityTypeText = (data?: AUTHORITY_TYPE): string => {
      switch (data) {
        case AUTHORITY_TYPE.OPERATOR:
          return t('text:Operator');
        case AUTHORITY_TYPE.AUTHORIZER:
          return t('text:Authorizer');
        case AUTHORITY_TYPE.HQ_OPERATOR:
          return t('text:Admin_Operator');
        case AUTHORITY_TYPE.ADMIN:
          return t('text:Admin');
        default:
          return '';
      }
    };

    return anchorAgreementData?.anchorUserList.map((item, index) => (
      <Tr key={index}>
        <Td data={item.anchorClientCode} />
        <Td data={item.anchorClientName} />
        <Td data={item.email} />
        <Td data={getAuthorityTypeText(item.authorityType)} />
        <Td
          className={getStatusTextClass('USER_STATUS', item.userStatus)}
          data={t(`code:user-status.${item.userStatus}`)}
        />
      </Tr>
    ));
  };

  const renderAnchorReportPageTableBody = (): JSX.Element[] | undefined => {
    const onClickDownload = async (e: any, item: AnchorReportVOModel): Promise<void> => {
      e.preventDefault();

      try {
        showLoadingUI();
        await requestSyAnchorReportDownload(item.anchorReportId);
        unShowLoadingUI();
      } catch (error) {
        modal.show(error);
      }
    };

    return anchorReportPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.sent ? item.updatedDateTime : ''} format="datetime" />
        <Td data={item.sentEmail} />
        <Td className="text-center">
          <DownloadButton onClick={e => onClickDownload(e, item)} disabled={!item.sent} />
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Report')} />
      <div className="content-area">
        <SectionTitle title={t('text:Report_Setting')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Report_Type')}
                value={t(`code:report-type.${anchorReportSettingData?.reportType}`)}
                format="code"
              />
              <FormValue
                label={t('text:Report_Status')}
                value={getReportStatusText(anchorReportSettingData?.activated)}
                className={getReportStatusClassName(anchorReportSettingData?.activated)}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Report_Receiver')}>
          <div className="flex-end align-items-center">
            <span className="me-1">{t('text:Go_to_Agreement_Details')}</span>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              className="flex-column-center"
              onClick={onClickGoToAgreementDetails}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </SectionTitle>
        <FormBorder>
          <FormSubtitle title={t('text:Anchor_Agreement_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Master_Agreement_Number')} value={anchorAgreementData?.contractNo} />
              <FormValue label={t('text:Client_Code')} value={anchorAgreementData?.financierClientCode} />
            </div>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={anchorAgreementData?.financierClientName} />
              <FormValue col={3} label={t('text:Currency')} value={anchorAgreementData?.currencyType} />
              <FormValue
                col={3}
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${anchorAgreementData?.collateralType}`)}
                format="code"
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Agreement_Status')}
                value={t(`code:anchor-agreement-status.${anchorAgreementData?.anchorAgreementStatus}`)}
                format="code"
              />
              <FormValue
                col={3}
                label={t('text:Effective_Date')}
                format="date"
                value={anchorAgreementData?.startDate}
              />
              <FormValue
                col={3}
                label={t('text:Expiration_Date')}
                format="date"
                value={anchorAgreementData?.expiryDate}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Anchor_User_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <TableBorder>
              <TableHeader header={RELATED_ANCHOR_USER_TABLE_HEADERS} />
              <TableBody numOfCol={RELATED_ANCHOR_USER_TABLE_HEADERS.length}>
                {renderAnchorUserInformationTableBody()}
              </TableBody>
            </TableBorder>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Report')} />
        <TableBorder>
          <TableHeader header={ANCHOR_REPORT_TABLE_HEADERS} />
          <TableBody numOfCol={ANCHOR_REPORT_TABLE_HEADERS.length}>{renderAnchorReportPageTableBody()}</TableBody>
        </TableBorder>
        <Pagination pageable={anchorReportPageable} paginate={anchorReportPaginate} />
      </div>
    </>
  );
}

export default SystemMonitorAnchorReportDetail;
