import { useTranslation } from 'react-i18next';

import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormErrorMessage } from 'components/stateless/CommonForm/FormErrorMessage';
import NumericFormatInput from 'components/stateless/CommonForm/NumericFormatInput';
import Label from 'components/stateless/Form/Label/Label';
import { Border } from 'components/templates/section';
import { isNilOrEmptyString } from 'utils/helpers';

import { useDelinquentInterestRateTermSpreadFormController } from './useDelinquentInterestRateTermSpreadFormController';

interface DelinquentInterestRateFormPropsType {
  editable?: boolean;
}

export const DELINQUENT_INTEREST_RATE_FIELD_NAME = 'termSpreads';

function DelinquentInterestRateTermSpreadForm({ editable = true }: DelinquentInterestRateFormPropsType) {
  const { t } = useTranslation(['format']);
  const { methods, fields, handleAddRowClick, handleDeleteLastRowClick } =
    useDelinquentInterestRateTermSpreadFormController();

  const { register, watch, control, errors } = methods;

  const watchDelinquentInterestRateList = watch(DELINQUENT_INTEREST_RATE_FIELD_NAME);

  return (
    <Border isEditable={editable}>
      <table className="term-spread__table">
        <colgroup>
          <col />
          <col style={{ width: '30%' }} />
          <col style={{ width: '56px' }} />
        </colgroup>
        <thead className="term-spread__head">
          <tr>
            <th scope="col">
              <Label name={DELINQUENT_INTEREST_RATE_FIELD_NAME} isEditable isEditableLabel required>
                {t('text:Terms_Days')}
              </Label>
            </th>
            <th scope="col">
              <Label name={DELINQUENT_INTEREST_RATE_FIELD_NAME} isEditable isEditableLabel required className="pe-2">
                {t('text:Term_Spread')} (%)
              </Label>
            </th>
            <th scope="col" className={clsx({ 'term-spread__no-left-border': !editable })} />
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const minimumDateRange = watchDelinquentInterestRateList?.[index]?.minimumDateRange;
            const isEdgeRow = (index: number) => index === 0 || index === fields.length - 1;

            return (
              <tr
                key={`term-spread-row-${item.id}`}
                className={clsx({ 'term-spread__tr--background-gray': isEdgeRow(index) })}
              >
                <td>
                  <div className="term-spread__term-box">
                    <div>
                      {index === 0 && (
                        <input
                          type="hidden"
                          defaultValue={0}
                          name={`${DELINQUENT_INTEREST_RATE_FIELD_NAME}.0.minimumDateRange`}
                          ref={register()}
                        />
                      )}
                      <div className="term-spread__value">
                        {isNilOrEmptyString(minimumDateRange)
                          ? '-'
                          : t('format:number', {
                              value: index === 0 ? 0 : minimumDateRange,
                            })}
                      </div>
                    </div>
                    <div className="term-spread__angle-sign">&lt;</div>
                    <div>T</div>
                    {fields.length !== index + 1 && (
                      <>
                        <div className="term-spread__angle-sign">≤</div>
                        <div>
                          {/*flex 속성 걸려있음 */}
                          <div>
                            <NumericFormatInput
                              name={`${DELINQUENT_INTEREST_RATE_FIELD_NAME}.${index + 1}.minimumDateRange`}
                              disabled={!editable}
                              defaultValue={fields?.[index + 1]?.minimumDateRange ?? null}
                              placeholder="e.g. 30"
                              control={control}
                              numberType="integer"
                              rules={{
                                required: t('text:This_information_is_required'),
                                min: { value: 0.001, message: t('text:This_information_must_exceed_0') },
                              }}
                              error={
                                errors[DELINQUENT_INTEREST_RATE_FIELD_NAME] &&
                                errors[DELINQUENT_INTEREST_RATE_FIELD_NAME][index + 1]?.minimumDateRange
                              }
                              className="form__input text-center"
                            />
                            {errors[DELINQUENT_INTEREST_RATE_FIELD_NAME] && (
                              <FormErrorMessage
                                error={errors[DELINQUENT_INTEREST_RATE_FIELD_NAME][index + 1]?.minimumDateRange}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <div className="flex-end">
                    <div>
                      <NumericFormatInput
                        name={`${DELINQUENT_INTEREST_RATE_FIELD_NAME}.${index}.termSpreadRate`}
                        defaultValue={item.termSpreadRate ? `${item.termSpreadRate}` : null}
                        disabled={!editable}
                        className="form__input text-right"
                        control={control}
                        rules={{
                          required: t('text:This_information_is_required'),
                          min: { value: 0.001, message: t('text:This_information_must_exceed_0') },
                        }}
                        error={
                          errors[DELINQUENT_INTEREST_RATE_FIELD_NAME] &&
                          errors[DELINQUENT_INTEREST_RATE_FIELD_NAME][index]?.termSpreadRate
                        }
                        placeholder={t('text:Please_type_here')}
                      />
                      {errors[DELINQUENT_INTEREST_RATE_FIELD_NAME] && (
                        <FormErrorMessage error={errors[DELINQUENT_INTEREST_RATE_FIELD_NAME][index]?.termSpreadRate} />
                      )}
                    </div>
                    <span className="unit">%</span>
                  </div>
                </td>
                <td style={{ maxWidth: '56px' }} className={clsx({ 'term-spread__no-left-border': !editable })}>
                  {editable && fields.length === index + 1 && fields.length !== 2 && (
                    <Button
                      className="term-spread__minus-button"
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={handleDeleteLastRowClick}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
          {editable && (
            <tr className="term-spread__tr--background-gray">
              <td colSpan={3}>
                <Button
                  onClick={handleAddRowClick}
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  fullWidth
                  bold
                >
                  + {t('text:Add_New_Term')}
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Border>
  );
}

export default DelinquentInterestRateTermSpreadForm;
