import type { FinancierCommonSettingVO, FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import { formattingToFinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierCommonSettingUpdateRequest } from './requests';

export async function requestFinancierFinancierCommonSettingList(): Promise<FinancierCommonSettingVOModel> {
  const response = await http.get<FinancierCommonSettingVO>({
    url: API_FI.FINANCIER_COMMON_SETTING.COMMON_SETTING_LIST,
  });

  return formattingToFinancierCommonSettingVOModel(response);
}

export async function requestFinancierFinancierCommonSettingUpdate(
  financierCommonSettingId: number,
  data: FinancierCommonSettingUpdateRequest,
): Promise<FinancierCommonSettingVOModel> {
  const response = await http.put<FinancierCommonSettingVO>({
    url: API_FI.FINANCIER_COMMON_SETTING.COMMON_SETTING_DETAIL(financierCommonSettingId),
    data,
  });

  return formattingToFinancierCommonSettingVOModel(response);
}
