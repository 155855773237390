import { useTranslation } from 'react-i18next';

import Big from 'big.js';

import { CalculatorBigNumber } from 'utils/bigNumber';
import { getFinancingLimitValue, getRemainingApplicableLimitValue } from 'utils/logic';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import useAgreementViewModel from '../../models/agreement/useAgreementViewModel';

const SummaryOfPartner = () => {
  const { t } = useTranslation(['format']);
  const calculatorBigNumber = new CalculatorBigNumber();

  const { agreement } = useAgreementViewModel();

  const {
    loanLimitCheckType,
    loanLimitAmount,
    partiallyRepaidPrincipalAmount,
    requestedLoanAmount,
    currencyType,
    totalLimitAmount,
    totalLimitCurrencyType,
    useTotalLimit,
    originCurrencyTotalBalance,
    disbursedLoanAmount,
    settlementWaitingInvoiceAmount,
    settlementWaitingInvoiceCount,
    disbursedLoanCount,
  } = agreement;

  return (
    <div className="information-grid-form">
      {useTotalLimit && (
        <div className="row">
          <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit_of_Partner')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {t('format:number', {
                value: totalLimitAmount,
              })}{' '}
              {tableValueManage(totalLimitCurrencyType)}
            </div>
          </div>
          <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit_of_Partner')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {t('format:number', {
                value: Big(originCurrencyTotalBalance ?? 0).lt(0) ? 0 : originCurrencyTotalBalance,
              })}{' '}
              {tableValueManage(totalLimitCurrencyType)}
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">
            {getFinancingLimitValue(loanLimitCheckType, loanLimitAmount, currencyType)}
          </div>
        </div>
        <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">
            {getRemainingApplicableLimitValue(
              loanLimitCheckType,
              {
                loanLimitAmount,
                partiallyRepaidPrincipalAmount,
                requestedLoanAmount,
              },
              currencyType,
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Balance')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">
            {t('format:number', {
              value: calculatorBigNumber.add(disbursedLoanAmount).minus(partiallyRepaidPrincipalAmount).get(),
            })}{' '}
            {tableValueManage(currencyType)}
          </div>
        </div>
        <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Count')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">{t('format:number', { value: disbursedLoanCount })}</div>
        </div>
      </div>
      <div className="row">
        <div className="grid-form__item__label-wrap col-2">{t('text:Pending_AR_Amount')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">
            {`${t('format:number', { value: settlementWaitingInvoiceAmount })} ${tableValueManage(currencyType)}`}
          </div>
        </div>
        <div className="grid-form__item__label-wrap col-2">{t('text:Number_of_Pending_AR')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">{t('format:number', { value: settlementWaitingInvoiceCount })}</div>
        </div>
      </div>
    </div>
  );
};

export default SummaryOfPartner;
