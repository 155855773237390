import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import { BANK_CODE_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { WaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestCmBankCodeList } from 'utils/http/api/common/bank-code';
import type { CommonBankCodeListRequest } from 'utils/http/api/common/bank-code/request';
import { requestSyWaitingBankCodeList } from 'utils/http/api/system/waiting-bank-code';
import type { SyWaitingBankCodeListRequest } from 'utils/http/api/system/waiting-bank-code/request';
import { getWaitingForApprovalCount } from 'utils/logic';
import {
  WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES,
  setBankCodeApprovalTypesValue,
} from 'utils/logic/setApprovalTypesValue';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

const SY_BANK_CODE_LIST_QS_KEY = 'sy-bank-code-list';
const SY_WAITING_BANK_CODE_LIST_QS_KEY = 'sy-waiting-bank-code-list';

function SystemBankCodeList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();
  const bankCodePageable = usePageable(SY_BANK_CODE_LIST_QS_KEY);
  const waitingBankCodePageable = usePageable(SY_WAITING_BANK_CODE_LIST_QS_KEY);

  // state
  const [bankCodeList, setBankCodeList] = useState<Pageable<BankCodeVOModel[]>>();
  const [waitingBankCodeList, setWaitingBankCodeList] = useState<Pageable<WaitingBankCodeVOModel[]>>();
  const [notCancelledWaitingBankCodeList, setNotCancelledWaitingBankCodeList] =
    useState<Pageable<WaitingBankCodeVOModel[]>>();

  // useForm
  const bankCodeSearchForm = useForm<CommonBankCodeListRequest>();
  const waitingBankCodeSearchForm = useForm<SyWaitingBankCodeListRequest>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const setWaitingTabSearchValue = (formSearchData?: SyWaitingBankCodeListRequest) => {
    const getSearchFormData = formSearchData ? formSearchData : waitingBankCodeSearchForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setBankCodeApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchAll = async () => {
    setFormValues<CommonBankCodeListRequest>(
      bankCodeSearchForm.setValue,
      getParsedSearchParams(SY_BANK_CODE_LIST_QS_KEY).formSearchData,
    );

    setFormValues<SyWaitingBankCodeListRequest>(
      waitingBankCodeSearchForm.setValue,
      getParsedSearchParams(SY_WAITING_BANK_CODE_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    const bankCodeListSearchData = bankCodeSearchForm.getValues();

    const waitingQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingBankCodePageable.pageable.currentPage,
        rowCount: waitingBankCodePageable.pageable.sizePerPage,
      },
      SY_WAITING_BANK_CODE_LIST_QS_KEY,
    );
    const completedQs = makeSearchParamsPattern(
      {
        ...bankCodeListSearchData,
        pageNumber: bankCodePageable.pageable.currentPage,
        rowCount: bankCodePageable.pageable.sizePerPage,
      },
      SY_BANK_CODE_LIST_QS_KEY,
    );
    addSearchParams(waitingQs + completedQs);

    try {
      const [fetchBankCodeResponse, fetchWaitingBankCodeResponse, fetchNotCancelledWaitingBankCodeResponse] =
        await Promise.all([
          requestCmBankCodeList(
            bankCodePageable.pageable.currentPage,
            bankCodePageable.pageable.sizePerPage,
            bankCodeListSearchData,
          ),
          requestSyWaitingBankCodeList(
            waitingBankCodePageable.pageable.currentPage,
            waitingBankCodePageable.pageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestSyWaitingBankCodeList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setBankCodeList(fetchBankCodeResponse);
        setWaitingBankCodeList(fetchWaitingBankCodeResponse);
        setNotCancelledWaitingBankCodeList(fetchNotCancelledWaitingBankCodeResponse);

        bankCodePageable.setPageable(fetchBankCodeResponse);
        waitingBankCodePageable.setPageable(fetchWaitingBankCodeResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchBankCodeList = async (pageNumber: number = 0, rowCount: number = 10, data: CommonBankCodeListRequest) => {
    try {
      const fetchBankCodeResponse = await requestCmBankCodeList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_BANK_CODE_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setBankCodeList(fetchBankCodeResponse);
        bankCodePageable.setPageable(fetchBankCodeResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onBankCodeRemoveFilter = () => {
    bankCodeSearchForm.reset({});
  };

  const onClickBankCodeSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await fetchBankCodeList(0, bankCodePageable.pageable.sizePerPage, bankCodeSearchForm.getValues());
  };

  const bankCodeListPaginate = async (pageNumber: number, sizePerPage: number) => {
    await fetchBankCodeList(pageNumber, sizePerPage, getParsedSearchParams(SY_BANK_CODE_LIST_QS_KEY).formSearchData);
  };

  const onWaitingBankCodeRemoveFilter = () => {
    waitingBankCodeSearchForm.reset({});
  };

  const onClickWaitingBankCodeSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    try {
      const [fetchWaitingBankCodeResponse, fetchNotCancelledWaitingBankCodeResponse] = await Promise.all([
        requestSyWaitingBankCodeList(0, waitingBankCodePageable.pageable.sizePerPage, waitingForApprovalSearchData),
        requestSyWaitingBankCodeList(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber: 1,
          rowCount: waitingBankCodePageable.pageable.sizePerPage,
        },
        SY_WAITING_BANK_CODE_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingBankCodeList(fetchWaitingBankCodeResponse);
        waitingBankCodePageable.setPageable(fetchWaitingBankCodeResponse);
        setNotCancelledWaitingBankCodeList(fetchNotCancelledWaitingBankCodeResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const waitingBankCodePaginate = async (pageNumber: number, sizePerPage: number) => {
    const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
      getParsedSearchParams(SY_WAITING_BANK_CODE_LIST_QS_KEY).formSearchData,
    );

    try {
      const fetchWaitingBankCodeResponse = await requestSyWaitingBankCodeList(
        pageNumber,
        sizePerPage,
        waitingForApprovalSearchData,
      );
      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber,
          rowCount: sizePerPage,
        },
        SY_WAITING_BANK_CODE_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        waitingBankCodePageable.setPageable(fetchWaitingBankCodeResponse);
        setWaitingBankCodeList(fetchWaitingBankCodeResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const renderBankCodeListTab = () => {
    const confirmedTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Bank_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Bank_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Branch_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Branch_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Latest_Update'),
        colWidths: 120,
      },
      {
        headerText: t('text:Active_Status'),
        colWidths: 120,
      },
      {
        headerText: '',
        colWidths: 80,
      },
    ];

    return (
      <>
        <div className="content-area">
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onBankCodeRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <form>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Financier_Name')} />
                <SearchInput name="financierName" ref={bankCodeSearchForm.register} />
                <SearchLabel label={t('text:Bank_Code')} />
                <SearchInput name="bankCode" ref={bankCodeSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Bank_Name')} />
                <SearchInput name="bankName" ref={bankCodeSearchForm.register} />
                <SearchLabel label={t('text:Branch_Code')} />
                <SearchInput name="branchCode" ref={bankCodeSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Branch_Name')} />
                <SearchInput name="branchName" ref={bankCodeSearchForm.register} />
                <SearchLabel label={t('text:Active_Status')} />
                <SearchSelect
                  name="deleted"
                  selectOptions={getSelectOptions<string>('DELETE_ACTIVATION_STATUS', 'ALL', true)}
                  ref={bankCodeSearchForm.register}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickBankCodeSearch}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <div className="d-flex">
            <p className="total-data me-auto">
              {t('text:Total')} {bankCodeList?.totalElements}
            </p>
          </div>
          <TableBorder>
            <TableHeader header={confirmedTableHeaders} />
            <TableBody numOfCol={confirmedTableHeaders.length}>
              {bankCodeList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.financierName} />
                    <Td data={item.bankCode} />
                    <Td data={item.bankName} />
                    <Td data={item.branchCode} />
                    <Td data={item.branchName} />
                    <Td data={item.updatedDateTime} format="datetime" />
                    <Td
                      className={getBlockedStatusClassName(item.deleted)}
                      data={item.deleted ? t('text:Deactivated') : t('text:Active')}
                    />
                    <TdLink path={ROUTES_SY.MANAGEMENT.BANK_CODE_CONFIRMED_DETAIL_BUILD_PATH(item.id)} />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={bankCodePageable.pageable} paginate={bankCodeListPaginate} />
        </div>
      </>
    );
  };

  const renderWaitingBankCodeListTab = () => {
    const waitingTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Bank_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Bank_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Branch_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Branch_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Active_Status'),
        colWidths: 120,
      },
      {
        headerText: t('text:Request_Bank'),
        colWidths: 120,
      },
      {
        headerText: t('text:Request_Status'),
        colWidths: 120,
      },
      {
        headerText: '',
        colWidths: 80,
      },
    ];

    const approvalTypesSelectOptions = [
      {
        label: t('text:All'),
        value: WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES,
      },
      {
        label: t('text:Requested'),
        value: BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST,
      },
      {
        label: t('text:Cancelled'),
        value: BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL,
      },
      {
        label: t('text:Rejected'),
        value: BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED,
      },
    ];

    return (
      <>
        <div className="content-area">
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onWaitingBankCodeRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <form>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Financier_Name')} />
                <SearchInput name="financierName" ref={waitingBankCodeSearchForm.register} />
                <SearchLabel label={t('text:Bank_Code')} />
                <SearchInput name="bankCode" ref={waitingBankCodeSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Bank_Name')} />
                <SearchInput name="bankName" ref={waitingBankCodeSearchForm.register} />
                <SearchLabel label={t('text:Branch_Code')} />
                <SearchInput name="branchCode" ref={waitingBankCodeSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Branch_Name')} />
                <SearchInput name="branchName" ref={waitingBankCodeSearchForm.register} />
                <SearchLabel label={t('text:Request_Status')} />
                <SearchSelect
                  name="approvalTypes"
                  selectOptions={approvalTypesSelectOptions}
                  ref={waitingBankCodeSearchForm.register}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickWaitingBankCodeSearch}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />

          <p className="total-data me-auto">
            {t('text:Total')} {waitingBankCodeList?.totalElements}
          </p>

          <TableBorder>
            <TableHeader header={waitingTableHeaders} />
            <TableBody numOfCol={waitingTableHeaders?.length}>
              {waitingBankCodeList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.financierName} />
                    <Td data={item.bankCode} />
                    <Td data={item.bankName} />
                    <Td data={item.branchCode} />
                    <Td data={item.branchName} />
                    <Td
                      className={getBlockedStatusClassName(item.deleted)}
                      data={item.deleted ? t('text:Deactivated') : t('text:Active')}
                    />
                    <Td data={item.operatorEnterpriseName} />
                    <Td
                      className={getStatusTextClass('BANK_CODE_APPROVAL_TYPE', item.approvalType)}
                      data={t(`code:bank-code-approval-type.${item.approvalType}`)}
                    />
                    <TdLink
                      path={ROUTES_SY.MANAGEMENT.BANK_CODE_WAITING_DETAIL_BUILD_PATH(item.id)}
                      state={{ targetBankCodeId: item.targetBankCodeId }}
                    />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingBankCodePageable.pageable} paginate={waitingBankCodePaginate} />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Bank_Code_Management')} />
      <GuideMessage
        message={[
          t('text:Financiers_have_requested_the_revision_of_bank_codes_in_the_Waiting_for_Confirmation_tab'),
          t('text:Click_on_the_arrow_button_to_see_more_detailed_information,_and_confirm_or_reject_the_request'),
          t('text:Click_on_the_Register_button_to_request_registration_of_a_new_bank_code'),
        ]}
      >
        {{
          button: (
            <Button
              onClick={() => {
                history.push(ROUTES_SY.MANAGEMENT.BANK_CODE_REGISTER);
              }}
            >
              {t('text:Register')}
            </Button>
          ),
        }}
      </GuideMessage>
      <Tab
        initialActiveTabIndex={1}
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              waitingBankCodeSearchForm.getValues('approvalTypes'),
              notCancelledWaitingBankCodeList?.totalElements,
            ),
          },
          {
            tabItemCount: bankCodeList?.totalElements ?? 0,
          },
        ]}
        tabViewList={[renderWaitingBankCodeListTab(), renderBankCodeListTab()]}
      />
    </>
  );
}

export default SystemBankCodeList;
