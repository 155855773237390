import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ATTACHMENT_TYPE } from 'enums';
import type { AnchorLoanEtcAttachmentVOModel } from 'models/vo/AnchorLoanEtcAttachmentVO';
import { requestFinancierMultipleAnchorLoanRequestDownloadAttachment } from 'utils/http/api/financier/multiple-request-anchor-loans';
import useModal from 'utils/modal/useModal';

interface FinancierBulkAnchorFinancingDetailAdditionalDocumentsProps {
  multipleAnchorLoanRequestId: number;
  loanEtcAttachmentListData: AnchorLoanEtcAttachmentVOModel[];
}

function FinancierBulkAnchorFinancingDetailAdditionalDocuments({
  multipleAnchorLoanRequestId,
  loanEtcAttachmentListData,
}: FinancierBulkAnchorFinancingDetailAdditionalDocumentsProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const handleLoanEtcFileClick = async (e: any, loanEtcAttachmentId: number) => {
    e.preventDefault();
    try {
      await requestFinancierMultipleAnchorLoanRequestDownloadAttachment(
        multipleAnchorLoanRequestId,
        ATTACHMENT_TYPE.LOAN_ETC,
        loanEtcAttachmentId,
      );
    } catch (e: any) {
      modal.show(e);
    }
  };
  const ADDITIONAL_DOCUMENT_TABLE_HEADERS: HeaderType[] = [
    { headerText: '', colWidths: 70 },
    { headerText: t('text:File_Name'), colWidths: 180 },
    { headerText: t('text:Note'), colWidths: 180 },
    { headerText: '', colWidths: 120 },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Additional_Documents')} />
      <TableBorder>
        <TableHeader header={ADDITIONAL_DOCUMENT_TABLE_HEADERS} />
        <TableBody numOfCol={ADDITIONAL_DOCUMENT_TABLE_HEADERS.length}>
          {loanEtcAttachmentListData?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td className="text-center" data={i + 1} />
                <Td data={item.anchorLoanEtcAttachmentName} />
                <Td data={item.description} />
                <Td className="information-table-more">
                  <DownloadButton
                    onClick={e => handleLoanEtcFileClick(e, item.anchorLoanEtcAttachmentId)}
                    disabled={!item.anchorLoanEtcAttachmentId}
                  />
                </Td>
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
    </div>
  );
}
export default FinancierBulkAnchorFinancingDetailAdditionalDocuments;
