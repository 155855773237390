import type React from 'react';
import { useCallback, useEffect } from 'react';

export default function useOutsideClick(callback: (event: MouseEvent) => any, ref: React.RefObject<any>) {
  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) callback(e);
    },
    [callback, ref],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);
}
