import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormSelect } from 'components/stateless/CommonForm';
import FormInput from 'components/stateless/CommonForm/FormInput';
import SearchAnchorAgreementModal from 'components/stateless/Modal/financier/SearchAnchorAgreementModal';
import SearchPartnerAgreementModal from 'components/stateless/Modal/financier/SearchPartnerAgreementModal';
import getSelectOptions from 'constants/selectOptions';
import type { AR_COMMISSION_CREATE_PHASE, CURRENCY_TYPE } from 'enums';
import { FINANCIAL_SETTING_TYPE } from 'enums';
import { COLLATERAL_TYPE } from 'enums';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { ModalSize } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import type { FiProgramChargeRegisterFormType } from '../../register';

function ProgramChargeTypeForm() {
  const { t } = useTranslation(['format']);

  const { register, setValue, watch, errors } = useFormContext<FiProgramChargeRegisterFormType>();
  const modal = useModal();

  const showAnchorMasterAgreementModal = () => {
    const setAnchorAgreementData = (data: AnchorAgreementVOModel) => {
      setValue('anchorAgreementId', data.anchorAgreementId);
      setValue('currencyType', data.currencyType);
      setValue('contractNo', data.contractNo);
      setValue('clientName', data.financierClientName);
      setValue('clientCode', data.financierClientCode);
    };

    modal.show(
      <SearchAnchorAgreementModal
        supportedCollateralType={COLLATERAL_TYPE.AR}
        modalId={modal.id}
        getSelectedData={setAnchorAgreementData}
      />,
      {
        modalSize: ModalSize.XL,
      },
    );
  };

  const showPartnerMasterAgreementModal = () => {
    const setPartnerAgreementData = (data: DealerAgreementVOModel) => {
      setValue('dealerAgreementId', data.dealerAgreementId);
      setValue('currencyType', data.currencyType);
      setValue('contractNo', data.contractNo);
      setValue('clientName', data.dealerFinancierClientName);
      setValue('clientCode', data.dealerFinancierClientCode);
    };

    modal.show(
      <SearchPartnerAgreementModal
        supportedCollateralType={COLLATERAL_TYPE.AR}
        modalId={modal.id}
        getSelectedData={setPartnerAgreementData}
      />,
      {
        modalSize: ModalSize.XL,
      },
    );
  };

  const isNotDefaultType =
    watch('arCommissionSettingType') === FINANCIAL_SETTING_TYPE.PARTNER ||
    watch('arCommissionSettingType') === FINANCIAL_SETTING_TYPE.ANCHOR;

  const handleChangeSelectType = () => {
    setValue('anchorAgreementId', null);
    setValue('dealerAgreementId', null);
    setValue('currencyType', null);
    setValue('contractNo', null);
    setValue('clientName', null);
    setValue('clientCode', null);
  };

  const handleClickSearchButton = () => {
    watch('arCommissionSettingType') === FINANCIAL_SETTING_TYPE.ANCHOR
      ? showAnchorMasterAgreementModal()
      : showPartnerMasterAgreementModal();
  };

  return (
    <FormBorder editable>
      <FormContents>
        <div className="row">
          <input type="hidden" name="anchorAgreementId" ref={register} />
          <input type="hidden" name="dealerAgreementId" ref={register} />
          <FormSelect
            label={t('text:Type')}
            name="arCommissionSettingType"
            required
            ref={register}
            error={errors.arCommissionSettingType}
            placeholderOptions={{ show: true }}
            selectOptions={getSelectOptions<FINANCIAL_SETTING_TYPE>('FINANCIAL_SETTING_TYPE')}
            onChange={handleChangeSelectType}
          />
          <FormInput
            requiredOptions={{
              fixedRequired: isNotDefaultType,
            }}
            label={t('text:Master_Agreement_Number')}
            disabled={true}
            name="contractNo"
            ref={register}
          >
            {isNotDefaultType && (
              <Button size={ButtonSizeEnum.XS} onClick={handleClickSearchButton}>
                {t('text:Search')}
              </Button>
            )}
          </FormInput>
        </div>
        <div className="row">
          <FormSelect
            label={t('text:Currency')}
            name="currencyType"
            required
            ref={register}
            error={errors.currencyType}
            selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
            placeholderOptions={{ show: true }}
            disabled={isNotDefaultType}
          />
          <FormInput label={t('text:Company_Name')} name="clientName" disabled={true} ref={register} />
        </div>
        <div className="row">
          <FormInput label={t('text:Company_Client_Code')} name="clientCode" disabled={true} ref={register} />
          <FormSelect
            label={t('text:Timing_of_Transaction_Fee_being_charged')}
            name="commissionCreatePhase"
            required
            ref={register}
            error={errors.commissionCreatePhase}
            selectOptions={getSelectOptions<AR_COMMISSION_CREATE_PHASE>('AR_COMMISSION_CREATE_PHASE')}
            placeholderOptions={{ show: true }}
          />
        </div>
      </FormContents>
    </FormBorder>
  );
}

export default ProgramChargeTypeForm;
