import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE, LOAN_STATUS, LOAN_TRANSACTION_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { SignInModel } from 'models/SignInModel';
import type { LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import { getSignIn } from 'utils/storage/LocalStorage';

interface FinancierFinancingDetailRepaymentTransactionHistoryProps {
  useInterface: boolean;
  loanStatus: LOAN_STATUS;
  loanTransactionHistoryData: LoanTransactionVOModel[];
  onClickDeleteLoanTransactions: (e: any) => void;
  onClickRenewLoanTransactions: (e: any) => void;
  factoringEnable: boolean;
  pageable: PageableType;
  paginate(pageNumber: number, rowCount: number): Promise<void>;
}
function FinancierFinancingDetailRepaymentTransactionHistory({
  useInterface,
  loanStatus,
  loanTransactionHistoryData,
  onClickDeleteLoanTransactions,
  onClickRenewLoanTransactions,
  factoringEnable,
  pageable,
  paginate,
}: FinancierFinancingDetailRepaymentTransactionHistoryProps) {
  const { t } = useTranslation(['format']);
  const signInModel: SignInModel | null = getSignIn();
  const isShowRenewButton =
    (loanStatus === LOAN_STATUS.OVERDUE || loanStatus === LOAN_STATUS.DISBURSED) &&
    signInModel?.authorityType !== AUTHORITY_TYPE.ADMIN;

  const loanTransactionType = (loanTransactionType: LOAN_TRANSACTION_TYPE, code: string) =>
    factoringEnable && loanTransactionType === LOAN_TRANSACTION_TYPE.INTEREST
      ? t('text:Discount_Charge')
      : t(`code:${code}.${loanTransactionType}`);

  const TRANSACTION_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Transaction_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Repayment_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Interest_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Principal_Repayment'),
      colWidths: 120,
    },
    {
      headerText: factoringEnable ? t('text:Discount_Charge') : t('text:Interest_Repayment'),
      colWidths: 120,
    },
    {
      headerText: t('text:Principal_Balance'),
      colWidths: 120,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Repayment_Transaction_History')}>
        <Button
          style={{
            display: useInterface || signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ? 'none' : 'block',
          }}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.RED}
          size={ButtonSizeEnum.SM}
          onClick={onClickDeleteLoanTransactions}
          bold
        >
          {t('text:Delete_Last_Transaction')}
        </Button>
        {isShowRenewButton && (
          <Button size={ButtonSizeEnum.SM} className="ms-1" onClick={onClickRenewLoanTransactions} bold>
            {t('text:Update_Repayment_Transaction')}
          </Button>
        )}
      </SectionTitle>
      <TableBorder>
        <TableHeader header={TRANSACTION_TABLE_HEADER} />
        <TableBody numOfCol={TRANSACTION_TABLE_HEADER.length}>
          {loanTransactionHistoryData?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td data={item.transactionDateTime} format="date" />
                <Td data={loanTransactionType(item.loanTransactionType, 'loan-transaction-type')} format="code" />
                <Td data={loanTransactionType(item.loanTransactionType, 'interest-type')} format="code" />
                <Td
                  data={item.loanTransactionType === LOAN_TRANSACTION_TYPE.PRINCIPAL ? item.transactionAmount : '-'}
                  format="number"
                />
                <Td
                  data={
                    item.loanTransactionType === LOAN_TRANSACTION_TYPE.INTEREST ||
                    item.loanTransactionType === LOAN_TRANSACTION_TYPE.DELAY
                      ? item.transactionAmount
                      : '-'
                  }
                  format="number"
                />
                <Td
                  data={
                    item.loanTransactionType !== LOAN_TRANSACTION_TYPE.DISBURSED ? item.remainingPrincipalAmount : '-'
                  }
                  format="number"
                />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>

      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default FinancierFinancingDetailRepaymentTransactionHistory;
