import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface ArSettlementVO {
  anchorClientCode: string;
  anchorName: string;
  financierName: string;
  anchorAgreementId: number;
  settlementDate: string;
  currencyType: string;
  totalArCount: number;
  totalArAmount: number;
  daysToMaturity: number;
}

export interface ArSettlementVOModel extends ArSettlementVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToArSettlementVOModel(data: ArSettlementVO): ArSettlementVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
