import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { TableBody, TableBorder, TableHeader } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import useMounted from 'hooks/useMounted';
import type { InventorySummaryDetailVOModel } from 'models/vo/InventorySummaryDetailVO';
import {
  requestFinancierInventoryAttachmentDownload,
  requestFinancierInventoryDetail,
} from 'utils/http/api/financier/inventory-summaries';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const getConstant = () => {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { inventorySummaryId } = useParams<any>();

  const SECURED_INVENTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      colWidths: 50,
    },
    {
      headerText: t('text:Invoice_Number'),
    },
    {
      headerText: t('text:Item_Number'),
    },
    {
      headerText: t('text:Items'),
    },
    {
      headerText: t('text:Unit_Price'),
    },
    {
      headerText: t('text:Quantity'),
    },
    {
      headerText: t('text:Amount_(Unit_Price*Quantity)'),
    },
  ];

  return {
    t,
    mounted,
    modal,
    inventorySummaryId,
    SECURED_INVENTORY_TABLE_HEADERS,
  };
};

function FinancierSecuredInventoryDetail() {
  const { t, mounted, modal, inventorySummaryId, SECURED_INVENTORY_TABLE_HEADERS } = getConstant();
  // 화면 State
  const [inventoryDetail, setInventoryDetail] = useState<InventorySummaryDetailVOModel>();

  // onMounted
  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const fetchInventoryDetail = await requestFinancierInventoryDetail(inventorySummaryId);

      ReactDOM.unstable_batchedUpdates(() => {
        setInventoryDetail(fetchInventoryDetail);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const downloadInventoryFile = async (e: any) => {
    e.preventDefault();

    try {
      await requestFinancierInventoryAttachmentDownload(inventorySummaryId);
    } catch (error) {
      modal.show(error);
    }
  };

  const renderInventoryList = () => {
    return inventoryDetail?.inventoryItems.map((item, i) => {
      return (
        <tr key={i}>
          <td className="text-center">{i + 1}</td>
          <td>{tableValueManage(item.invoiceNumber)}</td>
          <td>{tableValueManage(item.itemNumber)}</td>
          <td>{tableValueManage(item.itemDescription)}</td>
          <td className="text-end">
            {tableValueManage(item.unitPrice, t('format:number', { value: item.unitPrice }))}
          </td>
          <td className="text-end">{tableValueManage(item.quantity, t('format:number', { value: item.quantity }))}</td>
          <td className="text-end">
            {tableValueManage(item.itemAmount, t('format:number', { value: item.itemAmount }))}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Secured_Inventory_Details')} />
      {/*start summary -->*/}
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Secured_Creditor')} value={inventoryDetail?.financierName} />
              <FormValue
                label={t('text:Registration_Date')}
                value={t('format:datetime', {
                  value: inventoryDetail?.createdDateTime,
                  key: 'datetime',
                })}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {/*end summary -->*/}
      <div className="content-area">
        <SectionTitle title={t('text:Secured_Inventory_List')} />
        <TableBorder scrollYMaxHeight={140}>
          <TableHeader header={SECURED_INVENTORY_TABLE_HEADERS} backGroundType={BackGroundType.DarkGray} />
          <TableBody>{renderInventoryList()}</TableBody>
        </TableBorder>
        <div className="grid-total">
          {t('text:Total_Quantity')}:{' '}
          {t('format:number', {
            value: inventoryDetail?.inventoryItems.reduce((acc, cur) => {
              return acc + cur.quantity;
            }, 0),
          })}
          <span className="me-5" />
          {t('text:Total_Amount')} :{' '}
          {tableValueManage(inventoryDetail?.totalAmount, t('format:number', { value: inventoryDetail?.totalAmount }))}
        </div>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Attached_Documents')} />
        <FormBorder>
          <div className="detail-in-file-download-form">
            <div className="d-flex justify-content-between">
              <label className="information-form__label">{t('text:Attached_Documents')}</label>
              <input
                type="text"
                placeholder={inventoryDetail?.fileName ? inventoryDetail?.fileName : t('text:No_file_attached')}
                className="upload-file-input no-cursor bg-transparent"
                readOnly
              />
              <label className="information-form__label">{t('text:Note')}</label>
              <input
                type="text"
                placeholder={inventoryDetail?.attachmentDescription ? inventoryDetail?.attachmentDescription : ''}
                className="upload-file-input"
                readOnly
              />
              <DownloadButton onClick={downloadInventoryFile} disabled={!inventoryDetail?.fileName} />
            </div>
          </div>
        </FormBorder>
      </div>
    </>
  );
}

export default FinancierSecuredInventoryDetail;
