import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormContents } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { ROUTES_DE } from 'constants/routes/dealer';
import type { MultipleLoanRequestDetailVOModel } from 'models/vo/MultipleLoanRequestDetailVO';
import type { MultipleLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleLoanRequestRelatedSuccessArVO';
import type { BigNumber } from 'utils/bigNumber';
import { formatStringWithEllipsis } from 'utils/formatter';

import { useDealerFinancingDetailFinancingRequestInformationController } from './logics';

interface DealerBulkFinancingDetailFinancingRequestInformationProps {
  bulkFinancingDetailInfo: MultipleLoanRequestDetailVOModel;
  arList: MultipleLoanRequestRelatedSuccessArVOModel[];
  isAuthorizerRequest: boolean;
  financingInfoCheckBoxState: { financingInfoCheck: boolean; setFinancingInfoCheck: Dispatch<SetStateAction<boolean>> };
}

function DealerBulkFinancingDetailFinancingRequestInformation({
  bulkFinancingDetailInfo,
  arList,
  isAuthorizerRequest,
  financingInfoCheckBoxState,
}: DealerBulkFinancingDetailFinancingRequestInformationProps) {
  const { t } = useTranslation(['format']);
  const { financingInfoCheck, setFinancingInfoCheck } = financingInfoCheckBoxState;

  const {
    handleDownloadLoanAgreementClick,
    handleDownloadDebtSellingNotificationClick,
    handleDownloadScannedInvoiceFileClick,
  } = useDealerFinancingDetailFinancingRequestInformationController(bulkFinancingDetailInfo.multipleLoanRequestId);

  const AR_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:AR_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Issued_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:AR_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Settlement_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 120,
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Final_Disbursement_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Scanned_Tax_Invoice_File'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const returnAmountWithCurrency = (amount: number | BigNumber | undefined) => {
    return `${t('format:number', {
      value: amount,
    })} ${bulkFinancingDetailInfo.currencyType}`;
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_Request_Information')} />
      <div className="information-form__detail-form border-bott-none mt-4">
        <div className="detail-title bg-sub100 border-bottom-2px-light-gray">
          <div className="d-flex justify-content-between align-items-center w-50">
            <div>{t('text:Requested_Disbursement_Date')}</div>
            <div className="gray-bold-font">
              {t('format:date', {
                value: bulkFinancingDetailInfo.desiredDisburseDate,
                key: bulkFinancingDetailInfo.desiredDisburseDate,
              })}
            </div>
          </div>
        </div>
        <div className="detail-title bg-sub100">
          <div className="d-flex justify-content-between w-50">{t('text:Financing_Conditions')}</div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Requested_Financing_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalRequestAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Selected_AR_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalArAmount)}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Discount_Charge')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalInterestAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="border-bottom-light-gray my-3" />
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {t('text:Advance_Disbursement')}
                <QuestionTooltip
                  id="netDisburseAmountTooltip"
                  place="top"
                  contentText={
                    <>
                      <div>{t('text:Requested_Financing_Amount')}ⓐ</div>
                      <div>{t('text:Discount_Charge')} ⓑ</div>
                      <div>--------------------------------------------------------</div>
                      <div>{t('text:Advance_Disbursement')} ⓒ = ⓐ - ⓑ</div>
                    </>
                  }
                />
              </div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalNetDisbursementAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Remaining_of_Selected_AR_Balance')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalRemainingCollateralAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Transaction_Fee')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.totalArCommissionAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {t('text:Final_Disbursement_Amount')}
                <QuestionTooltip
                  id="finalDisburseAmountTooltip"
                  place="top"
                  contentText={
                    <>
                      <div>{t('text:Remaining_AR_Balance')} ⓐ</div>
                      <div>{t('text:Transaction_Fee')} ⓑ</div>
                      <div>--------------------------------------------------------</div>
                      <div>{t('text:Final_Disbursement_Amount')} ⓒ = ⓐ - ⓑ</div>
                    </>
                  }
                />
              </div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(bulkFinancingDetailInfo.finalDisbursementAmount)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormBorder hideBorderBottom={isAuthorizerRequest}>
        <FormSubtitle title={t('text:Selected_AR_for_Financing_Application')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <TableBorder scrollYMaxHeight={570}>
            <TableHeader header={AR_TABLE_HEADERS} />
            <TableBody numOfCol={AR_TABLE_HEADERS.length}>
              {arList?.map((item: MultipleLoanRequestRelatedSuccessArVOModel, index: number) => (
                <Tr key={index}>
                  <Td data={item.arNumber} />
                  <Td data={item.arIssuedDate} format="date" />
                  <Td data={item.arAmount} format="number" />
                  <Td data={item.settlementDate} format="date" />
                  <Td data={item.requestedAmount} format="number" />
                  <Td data={item.netDisbursementAmount} format="number" />
                  <Td data={item.settlementDate} format="date" />
                  <Td data={item.finalDisbursementAmount} format="number" />
                  <Td>
                    <div
                      className="attach-file-link-button"
                      onClick={() => handleDownloadScannedInvoiceFileClick(item.invoiceAttachmentId)}
                    >
                      {formatStringWithEllipsis(item.invoiceAttachmentName)}
                    </div>
                  </Td>
                  <TdLink
                    path={ROUTES_DE.VIEW_TRANSACTION.AR_DETAIL_BUILD_PATH(item.successArId)}
                    state={{ financierId: bulkFinancingDetailInfo.financierId }}
                    sticky={false}
                  />
                </Tr>
              ))}
            </TableBody>
          </TableBorder>
        </FormContents>
        <FormSubtitle title={t('text:DEBT_SALE_AND_PURCHASE_AGREEMENT')} />
        <div className="detail-in-file-download-form">
          <div className="d-flex justify-content-between">
            <label className="information-form__label">{t('text:Download')}</label>
            <input
              type="text"
              placeholder={
                bulkFinancingDetailInfo.agreementAttachmentName
                  ? bulkFinancingDetailInfo.agreementAttachmentName
                  : t('text:no_file_attached')
              }
              className="upload-file-input"
              readOnly
            />
            <DownloadButton
              onClick={handleDownloadLoanAgreementClick}
              disabled={!bulkFinancingDetailInfo.agreementAttachmentName}
            />
          </div>
        </div>
        <FormSubtitle title={t('text:DEBT_SELLING_NOTIFICATION')} />
        <div className="detail-in-file-download-form">
          <div className="d-flex justify-content-between">
            <label className="information-form__label">{t('text:Download')}</label>
            <input
              type="text"
              placeholder={
                bulkFinancingDetailInfo.factoringNotificationAttachmentName
                  ? bulkFinancingDetailInfo.factoringNotificationAttachmentName
                  : t('text:no_file_attached')
              }
              className="upload-file-input"
              readOnly
            />
            <DownloadButton
              onClick={handleDownloadDebtSellingNotificationClick}
              disabled={!bulkFinancingDetailInfo.factoringNotificationAttachmentName}
            />
          </div>
        </div>
      </FormBorder>
      {isAuthorizerRequest && (
        <ConfirmCheckBox
          checked={financingInfoCheck}
          id="financing-info-check"
          labelText={t(
            'text:I_have_checked_that_the_AR_information_and_the_financing_request_information_above_are_correct_match_the_original_tax_invoice_copy_and_the_attached_files',
          )}
          onChangeCheckBox={() => {
            setFinancingInfoCheck(prevState => !prevState);
          }}
        />
      )}
    </div>
  );
}

export default DealerBulkFinancingDetailFinancingRequestInformation;
