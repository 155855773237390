import type { ReactNode } from 'react';

import { clsx } from 'clsx';
import { isNil } from 'lodash-es';

import QuestionButton from 'components/stateless/QuestionButton';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';

import type { TooltipOptionType } from '../index';

export type LabelPropsType = {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  tooltipOptions?: TooltipOptionType;
  handleQuestionButtonClick?: () => void;
  position?: 'top' | 'middle';
  isEditable?: boolean;
  isEditableLabel?: boolean;
};

const Label = ({
  name,
  className = '',
  children,
  required,
  tooltipOptions,
  handleQuestionButtonClick,
  position = 'middle',
  disabled,
  isEditable,
  isEditableLabel,
}: LabelPropsType) => {
  const classes = clsx('form__label', {
    'form__label--middle': position === 'middle',
    [className]: className,
  });

  return (
    <div className={classes}>
      <label htmlFor={name}>
        {children}
        {handleQuestionButtonClick && <QuestionButton onClick={handleQuestionButtonClick} />}
        {!isNil(tooltipOptions) && (
          <QuestionTooltip
            id={tooltipOptions.id}
            contentText={tooltipOptions.content}
            place={tooltipOptions.place ?? 'top'}
          />
        )}
        {!disabled && isEditable && required && isEditableLabel && <span className="star">*</span>}
      </label>
    </div>
  );
};

export default Label;
