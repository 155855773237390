import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import QuestionButton from 'components/stateless/QuestionButton';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ANCHOR_AGREEMENT_STATUS, COLLATERAL_TYPE, COMMON_APPROVAL_TYPE, LOAN_OPTION, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestAnchorAgreementDetail } from 'utils/http/api/anchor/anchor-agreements';
import { requestWaitingAnchorAgreementList } from 'utils/http/api/anchor/waiting-anchor-agreements';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

function AnchorAgreementDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { anchorAgreementId } = useParams<any>();
  const editHistoryPageable = usePageable();
  const [editHistoryList, setEditHistoryList] = useState<Pageable<WaitingAnchorAgreementVOModel[]>>();
  const [anchorAgreementDetail, setAnchorAgreementDetail] = useState<AnchorAgreementDetailVOModel>(
    {} as AnchorAgreementDetailVOModel,
  );

  const isInvoice = anchorAgreementDetail?.collateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = anchorAgreementDetail?.collateralType === COLLATERAL_TYPE.AR;

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (
    historyPageNumber: number = editHistoryPageable.pageable.currentPage,
    historyRowCount: number = editHistoryPageable.pageable.sizePerPage,
  ) => {
    try {
      const fetchedAnchorAgreementDetail = await requestAnchorAgreementDetail(anchorAgreementId);
      const fetchedWaitingDealerAgreementList = await requestWaitingAnchorAgreementList(
        historyPageNumber,
        historyRowCount,
        { anchorAgreementId },
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementDetail(fetchedAnchorAgreementDetail);
        setEditHistoryList(fetchedWaitingDealerAgreementList);
        editHistoryPageable.setPageable(fetchedWaitingDealerAgreementList);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchHistory = async (pageNumber: number, rowCount: number) => {
    try {
      const waitingAnchorAgreementList = await requestWaitingAnchorAgreementList(pageNumber, rowCount, {
        anchorAgreementId,
      });
      ReactDOM.unstable_batchedUpdates(() => {
        setEditHistoryList(waitingAnchorAgreementList);
        editHistoryPageable.setPageable(waitingAnchorAgreementList);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const historyPaginate = async (pageNumber: number, rowCount: number) => {
    await fetchHistory(pageNumber, rowCount);
  };

  // render
  const renderAdminInformationTable = (): JSX.Element[] | JSX.Element | undefined => {
    return anchorAgreementDetail.anchorUserList ? (
      anchorAgreementDetail.anchorUserList.map((anchorUser, index: number) => {
        return (
          <tr key={index}>
            <td>{tableValueManage(anchorUser.bankUserId)}</td>
            <td>{tableValueManage(anchorUser.name)}</td>
            <td>{tableValueManage(anchorUser.mobile)}</td>
            <td>{tableValueManage(anchorUser.email)}</td>
            <td>{tableValueManage(anchorUser.anchorUserOtpSerialNo)}</td>
            <td>{t(`code:authority-type.${anchorUser.authorityType}`)}</td>
            <td className={getStatusTextClass('USER_STATUS', anchorUser?.userStatus)}>
              {tableValueManage(anchorUser.userStatus, t(`code:user-status.${anchorUser.userStatus}`))}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={7} className="text-center">
          {t('text:No_data_available')}
        </td>
      </tr>
    );
  };

  const onClickUserRegistrationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickCommonApprovalType = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderHistoryListTable = (): JSX.Element[] | JSX.Element | undefined => {
    return editHistoryList?.content.length !== 0 ? (
      editHistoryList?.content.map((item, index: number) => {
        return (
          <tr key={index}>
            <td>
              {tableValueManage(
                item.updatedDateTime,
                t('format:datetime', { value: item.updatedDateTime, key: 'datetime' }),
              )}
            </td>
            <td>{tableValueManage(item.contractNo)}</td>
            <td>{tableValueManage(item.expiryDate, t('format:date', { value: item.expiryDate, key: 'date' }))}</td>
            <td>{tableValueManage(item.branchCode)}</td>
            <td className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}>
              {t(`code:common-approval-type.${item.approvalType}`)}
            </td>
            <td>
              {tableValueManage(item.operatorUserLoginId)} / {tableValueManage(item.operatorUserName)}
            </td>
            <td>
              {tableValueManage(item.authorizerUserLoginId)} / {tableValueManage(item.authorizerUserName)}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={7} className="text-center">
          {t('text:No_data_available')}
        </td>
      </tr>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Agreement_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Agreement_Information')} />
        <FormBorder>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_INFORMATION')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Client_Code')}
                value={anchorAgreementDetail?.financierClientCode}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Anchor_Name')}
                value={anchorAgreementDetail?.financierClientName}
              />
            </div>
          </FormContents>
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:ANCHOR_MASTER_AGREEMENT_INFORMATION')}
          />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Anchor_Master_Agreement_Number')}
                value={anchorAgreementDetail?.contractNo}
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Currency')}
                value={anchorAgreementDetail?.currencyType}
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${anchorAgreementDetail?.collateralType}`)}
              />
            </div>
            <div className="row">
              <FormValue
                col={6}
                className="information-form__input"
                label={isInvoice ? `${t('text:Financing_to_Invoice_Ratio')} (%)` : t('text:Financing_LTV(%)')}
                value={anchorAgreementDetail?.maxLtvRatio}
                format="number"
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Effective_Date')}
                value={anchorAgreementDetail?.startDate}
                format="date"
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Expiration_Date')}
                value={anchorAgreementDetail?.expiryDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_the_partners_automatic_financing_option?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={anchorAgreementDetail.loanOption !== LOAN_OPTION.NONE}
                  disabled
                >
                  {anchorAgreementDetail.loanOption !== LOAN_OPTION.NONE && (
                    <>
                      <span style={{ color: '#ada8a8', fontWeight: 'normal' }} className="me-1">
                        {t('text:Allow_Financiers_Automatic_Approval?')}
                      </span>
                      {`( ${t(`code:loan-option.${anchorAgreementDetail.loanOption}`)} )`}
                    </>
                  )}
                </FormRadio>
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={anchorAgreementDetail.loanOption === LOAN_OPTION.NONE}
                  disabled
                />
              </FormRadioWrap>
              <FormRadioWrap label={t('text:Allow_the_payment_date_to_exceed_the_agreement_expiration_date?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={anchorAgreementDetail.settlementDateOverAllowable}
                  disabled
                />
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={!anchorAgreementDetail.settlementDateOverAllowable}
                  disabled
                />
              </FormRadioWrap>
            </div>
            {isInvoice && (
              <div className="row">
                <FormRadioWrap label={t('text:Allow_registration_of_duplicate_reference_numbers?')}>
                  <FormRadio
                    label={t('text:Yes')}
                    defaultChecked={!anchorAgreementDetail.referenceNumberDuplicationCheck}
                    disabled
                  />
                  <FormRadio
                    label={t('text:No')}
                    defaultChecked={anchorAgreementDetail.referenceNumberDuplicationCheck}
                    disabled
                  />
                </FormRadioWrap>
                <FormRadioWrap label={t('text:Require_BOE_number_when_registering_invoices?')}>
                  <FormRadio
                    label={t('text:Yes')}
                    defaultChecked={anchorAgreementDetail.billOfExchangeNoRequired}
                    disabled
                  />
                  <FormRadio
                    label={t('text:No')}
                    defaultChecked={!anchorAgreementDetail.billOfExchangeNoRequired}
                    disabled
                  />
                </FormRadioWrap>
              </div>
            )}
            <div className="row">
              <FormRadioWrap label={t('text:Agreement_Suspension')}>
                <FormRadio
                  label={t('text:Not_Suspended')}
                  defaultChecked={anchorAgreementDetail.anchorAgreementStatus === ANCHOR_AGREEMENT_STATUS.ACTIVATED}
                  disabled
                />
                <FormRadio
                  label={t('text:Suspended')}
                  defaultChecked={anchorAgreementDetail.anchorAgreementStatus === ANCHOR_AGREEMENT_STATUS.SUSPENDED}
                  disabled
                />
              </FormRadioWrap>
            </div>
            {isInvoice && (
              <div className="row">
                <FormValue
                  className="information-form__input"
                  label={t('text:Partner_Identification_Type')}
                  value={t(`code:dealer-identifier-type.${anchorAgreementDetail?.dealerIdentifierType}`)}
                  format="code"
                />
              </div>
            )}
          </FormContents>
          {isAr && (
            <>
              <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:AP_FINANCING_INFORMATION')} />
              <FormContents>
                <div className="row">
                  <FormRadioWrap label={t('text:Automatic_Settlement')}>
                    <FormRadio
                      label={t('text:Yes_(Co-operation_&_Payment)')}
                      defaultChecked={anchorAgreementDetail.paymentSupport}
                      disabled
                    />
                    <FormRadio
                      label={t('text:No_(Co-operation)')}
                      defaultChecked={!anchorAgreementDetail.paymentSupport}
                      disabled
                    />
                  </FormRadioWrap>
                </div>
                <div className="row">
                  <FormRadioWrap label={t('text:Limit_of_AP_Issuance')}>
                    <FormRadio
                      label={t('text:Applied')}
                      defaultChecked={anchorAgreementDetail.collateralIssuedLimitCheck}
                      disabled
                    >
                      {anchorAgreementDetail.collateralIssuedLimitCheck &&
                        `( ${t('format:number', { value: anchorAgreementDetail.collateralIssuedLimitAmount })} )`}
                    </FormRadio>
                    <FormRadio
                      label={t('text:Not_Applied')}
                      defaultChecked={!anchorAgreementDetail.collateralIssuedLimitCheck}
                      disabled
                    />
                  </FormRadioWrap>
                  <FormRadioWrap label={t('text:Financing_Limit_of_Anchor')}>
                    <FormRadio label={t('text:Applied')} defaultChecked={anchorAgreementDetail.loanLimitCheck} disabled>
                      {anchorAgreementDetail.loanLimitCheck &&
                        `( ${t('format:number', { value: anchorAgreementDetail.loanLimitAmount })} )`}
                    </FormRadio>
                    <FormRadio
                      label={t('text:Not_Applied')}
                      defaultChecked={!anchorAgreementDetail.loanLimitCheck}
                      disabled
                    />
                  </FormRadioWrap>
                </div>
              </FormContents>
            </>
          )}
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:DESIGNATED_ACCOUNT')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Name')}
                value={anchorAgreementDetail?.settlementAccountFinancierName}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Name')}
                value={anchorAgreementDetail?.settlementAccountBranchName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Number')}
                value={anchorAgreementDetail?.settlementAccount}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Holder')}
                value={anchorAgreementDetail?.settlementAccountOwner}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:RESPONSIBLE_BRANCH')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Code')}
                value={anchorAgreementDetail?.branchCode}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Name')}
                value={anchorAgreementDetail?.branchName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Telephone')}
                value={anchorAgreementDetail?.branchTelephone}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Fax')}
                value={anchorAgreementDetail?.branchFax}
              />
            </div>
            <div className="row">
              <FormValue
                col={12}
                className="information-form__input"
                label={t('text:Registered_Office_Address')}
                value={anchorAgreementDetail?.branchAddress}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ADMIN_INFORMATION')} />
          <FormContents>
            <div className="row">
              <div className="col-12">
                <table className="table-border">
                  <thead>
                    <tr>
                      <th scope="col">{t('text:User_Code')}</th>
                      <th scope="col">{t('text:Name')}</th>
                      <th scope="col">{t('text:Mobile')}</th>
                      <th scope="col">{t('text:Email')}</th>
                      <th scope="col">{t('text:OTP_Number')}</th>
                      <th scope="col">{t('text:Authority')}</th>
                      <th scope="col">
                        {t('text:User_Account_Status')}
                        <QuestionButton onClick={onClickUserRegistrationStatus} />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">{renderAdminInformationTable()}</tbody>
                </table>
              </div>
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {/* history 작성 */}
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '120px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '200px' }} />
              <col style={{ width: '200px' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t('text:Edited_Time')}</th>
                <th scope="col">{t('text:Anchor_Master_Agreement_Number')}</th>
                <th scope="col">{t('text:Expiration_Date')}</th>
                <th scope="col">{t('text:Branch_Code')}</th>
                <th scope="col">
                  {t('text:Approval_Status')}
                  <QuestionButton onClick={onClickCommonApprovalType} />
                </th>
                <th scope="col">{t('text:Edit_requested_by_User_ID/Name')}</th>
                <th scope="col">{t('text:Edit_approved_by_User_ID/Name')}</th>
              </tr>
            </thead>
            <tbody className="bg-white">{renderHistoryListTable()}</tbody>
          </table>
        </div>
        <Pagination pageable={editHistoryPageable.pageable} paginate={historyPaginate} />
      </div>
    </>
  );
}

export default AnchorAgreementDetail;
