import { EMAIL_TYPE, stringToEnum } from 'enums';

export interface EmailVO {
  emailType: string;
  code: string;
}

export interface EmailVOModel extends EmailVO {
  emailType: EMAIL_TYPE;
}

export function formattingToEmailVOModel(data: EmailVO): EmailVOModel {
  return {
    ...data,
    emailType: stringToEnum(EMAIL_TYPE, data.emailType),
  };
}
