import { useTranslation } from 'react-i18next';

import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { COLLATERAL_TYPE } from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { RelatedAnchorClientLimitInfoVOModel } from 'models/vo/RelatedAnchorClientLimitInfoVO';

import SyPartnerAgreementRegisteredDetailSummaryOfAssociatedAnchor from './summary-of-associated-anchor';
import SyPartnerAgreementRegisteredDetailSummaryOfPartner from './summary-of-partner';

interface SyPartnerAgreementRegisteredDetailPlatformUsageHistoryProps {
  partnerAgreementData: DealerAgreementDetailVOModel;
  anchorAgreementLimitData: RelatedAnchorClientLimitInfoVOModel;
}

function SyPartnerAgreementRegisteredDetailPlatformUsageHistory({
  partnerAgreementData,
  anchorAgreementLimitData,
}: SyPartnerAgreementRegisteredDetailPlatformUsageHistoryProps) {
  const { t } = useTranslation(['format']);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Platform_Usage_History')} />
      {partnerAgreementData.collateralType === COLLATERAL_TYPE.AR && (
        <>
          <div className="account-form mb-3">
            <div className="account-balance">
              <div className="account-balance-label">{t('text:Maximum_Applicable_Amount')}</div>
              <div className="account-balance-value">
                {t('format:number', {
                  value: anchorAgreementLimitData?.maximumBalanceAmount,
                })}{' '}
                {partnerAgreementData?.currencyType}
              </div>
            </div>
            <div className="bg-pattern" />
          </div>
          <SyPartnerAgreementRegisteredDetailSummaryOfAssociatedAnchor data={anchorAgreementLimitData} />
          <div className="mb-3" />
        </>
      )}
      <SyPartnerAgreementRegisteredDetailSummaryOfPartner data={partnerAgreementData} />
    </div>
  );
}

export default SyPartnerAgreementRegisteredDetailPlatformUsageHistory;
