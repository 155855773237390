import type Pageable from 'models/Pageable';
import type { ExtensionRequestDetailVO, ExtensionRequestDetailVOModel } from 'models/vo/ExtensionRequestDetailVO';
import { formattingToExtensionRequestDetailVOModel } from 'models/vo/ExtensionRequestDetailVO';
import type { ExtensionRequestVO, ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import { formattingToExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemExtensionListRequest } from './requests';

export async function requestSystemExtensionList(
  pageNumber: number,
  rowCount: number,
  data: SystemExtensionListRequest,
): Promise<Pageable<ExtensionRequestVOModel[]>> {
  const response = await http.get<Pageable<ExtensionRequestVO[]>>({
    url: API_SY.EXTENSION_REQUESTS.EXTENSION_REQUESTS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });
  const systemExtensionList: ExtensionRequestVOModel[] = response.content.map((data: ExtensionRequestVO) =>
    formattingToExtensionRequestVOModel(data),
  );

  return {
    ...response,
    content: systemExtensionList,
  };
}

export async function requestSystemExtensionDetail(extensionRequestId: number): Promise<ExtensionRequestDetailVOModel> {
  const response = await http.get<ExtensionRequestDetailVO>({
    url: API_SY.EXTENSION_REQUESTS.EXTENSION_REQUEST_DETAIL(extensionRequestId),
  });

  return formattingToExtensionRequestDetailVOModel(response);
}
