import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty, isNil } from 'lodash-es';

import { FormBorder, FormContents, FormInput, FormSelect } from 'components/stateless/CommonForm';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { FormErrorCodeType, formErrorHandler } from 'utils/error/manager';
import { requestFiDivisionsRegister } from 'utils/http/api/financier/divisions';
import type { CreateFiDivisionDTO, DivisionDTO } from 'utils/http/api/financier/divisions/request';
import useModal from 'utils/modal/useModal';
import { requestDTOParserForArrayObj } from 'utils/valueManager/ValueManager';

interface RegisterDivisionModalProps {
  anchorClientId: number;
  modalId: number;
  fetchDivisionList: () => Promise<void>;
}

export interface RegisterDivisionRefHandle {
  handleRegisterDivision: () => Promise<void>;
}

const RegisterDivisionModal = forwardRef<RegisterDivisionRefHandle, RegisterDivisionModalProps>(
  ({ anchorClientId, modalId, fetchDivisionList }, ref) => {
    const { t } = useTranslation();
    const { show: showModal, close: closeModal, disableConfirmBtn } = useModal();

    const { control, register, getValues, setValue, errors, setError, clearErrors, watch } =
      useForm<CreateFiDivisionDTO>({
        mode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: {
          divisions: [
            {
              code: '',
              name: '',
              limitAllowable: false,
              limitCurrencyType: '' as CURRENCY_TYPE,
              limitAmount: 0,
            },
          ],
        },
      });

    const { fields } = useFieldArray<DivisionDTO>({ control, name: 'divisions' });

    const divisionCode = watch('divisions[0].code');
    const limitAllowable = watch('divisions[0].limitAllowable');
    const limitCurrencyType = watch('divisions[0].limitCurrencyType');
    const limitAmount = watch('divisions[0].limitAmount');

    const booleanLimitAllowable = isNil(limitAllowable) ? false : JSON.parse(String(limitAllowable));

    useImperativeHandle(ref, () => ({
      handleRegisterDivision: async () => {
        const { divisions } = getValues();
        requestDTOParserForArrayObj(divisions);
        const requestData: CreateFiDivisionDTO = { anchorClientId, divisions };

        try {
          await requestFiDivisionsRegister(requestData);
          closeModal(modalId);
          showModal(<h6>{t('text:The_company_division_information_has_been_registered_successfully')}</h6>, {
            closeBtnCb: fetchDivisionList,
          });
        } catch (error) {
          showModal(error);
          formErrorHandler<DivisionDTO>(error, setError, clearErrors, true, () => ({
            [FormErrorCodeType.Duplication]: {
              text: 'This_Division_Code_is_already_being_used',
              priority: 10,
            },
          }));
        }
      },
    }));

    useEffect(() => {
      if (!booleanLimitAllowable) {
        setValue('divisions[0].limitCurrencyType', '');
        setValue('divisions[0].limitAmount', '');
      }
    }, [booleanLimitAllowable, setValue]);

    useEffect(() => {
      disableConfirmBtn(
        modalId,
        !divisionCode || (booleanLimitAllowable && (isEmpty(limitCurrencyType) || isEmpty(limitAmount))),
      );
    }, [booleanLimitAllowable, disableConfirmBtn, divisionCode, limitAmount, limitCurrencyType, modalId]);

    useEffect(() => {
      if (!booleanLimitAllowable && (errors.divisions?.[0]?.limitAmount || errors.divisions?.[0]?.limitCurrencyType)) {
        clearErrors(['divisions[0].limitCurrencyType', 'divisions[0].limitAmount']);
      }
    }, [booleanLimitAllowable, clearErrors, errors, setValue]);

    return (
      <div className="modal-container">
        <form>
          <FormBorder editable>
            <FormContents>
              {fields.map((item, index) => (
                <div key={item.id}>
                  <div className="row">
                    <FormInput
                      label={t('text:Division_Code')}
                      name={`divisions[${index}].code`}
                      requiredOptions={{ required: true }}
                      ref={register}
                      error={errors?.divisions?.[index]?.code}
                    />
                    <FormInput
                      label={t('text:Division_Name')}
                      name={`divisions[${index}].name`}
                      ref={register}
                      error={errors?.divisions?.[index]?.name}
                    />
                  </div>
                  <div className="row">
                    <FormSelect
                      name={`divisions[${index}].limitAllowable`}
                      label={t('text:Financing Limit')}
                      ref={register}
                      placeholderOptions={{ show: true }}
                      selectOptions={[
                        {
                          label: t('text:Applied'),
                          value: true,
                        },
                        {
                          label: t('text:Not_Applied'),
                          value: false,
                        },
                      ]}
                      defaultValue="false"
                      error={errors?.divisions?.[index]?.limitAllowable}
                    />
                    <FormSelect
                      label={t('text:Currency')}
                      name={`divisions[${index}].limitCurrencyType`}
                      selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
                      ref={register}
                      placeholderOptions={{ show: true }}
                      disabled={!booleanLimitAllowable}
                      error={errors?.divisions?.[index]?.limitCurrencyType}
                    />
                  </div>
                  <div className="row">
                    <FormInput
                      label={t('text:Financing_Limit_Amount')}
                      name={`divisions[${index}].limitAmount`}
                      ref={register}
                      disabled={!booleanLimitAllowable}
                      error={errors?.divisions?.[index]?.limitAmount}
                    />
                  </div>
                </div>
              ))}
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  },
);

RegisterDivisionModal.displayName = 'RegisterDivisionModal';
export default RegisterDivisionModal;
