import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface SuccessArGroupCurrencyTypeVO {
  totalArCount: number;
  ltvTotalArAmount: number;
  totalArAmount: number;
  currencyType: string;
  maxLtvRatio: number;
}

export interface SuccessArGroupCurrencyTypeVOModel extends SuccessArGroupCurrencyTypeVO {
  currencyType: CURRENCY_TYPE;
}
export function formattingToSuccessGroupCurrencyTypeVOModel(
  data: SuccessArGroupCurrencyTypeVO,
): SuccessArGroupCurrencyTypeVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
