import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import SearchFinancierModal from 'components/stateless/Modal/system/SearchFinancierModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestSyWaitingBankCodeRegister } from 'utils/http/api/system/waiting-bank-code';
import type { SyWaitingBankCodeRegisterRequest } from 'utils/http/api/system/waiting-bank-code/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function SystemBankCodeRegister() {
  const { t } = useTranslation(['format']);
  const history = useHistory();
  const modal = useModal();
  const [financierName, setFinancierName] = useState<string>('');

  // useForm
  const bankCodeRegisterForm = useForm<SyWaitingBankCodeRegisterRequest>({
    mode: 'onSubmit',
  });

  const onClickSearchFinancier = () => {
    const setSelectedFinancierInfo = (data: EnterpriseVOModel) => {
      bankCodeRegisterForm.setValue('financierId', data.enterpriseId);
      setFinancierName(data.enterpriseName ?? '');
    };

    modal.show(<SearchFinancierModal modalId={modal.id} getSelectedData={setSelectedFinancierInfo} />, {
      modalSize: ModalSize.XL,
      title: t('text:Search_Financier'),
      closeBtnText: t('text:Close'),
    });
  };

  const onClickCancel = (e: any) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_SY.MANAGEMENT.BANK_CODE_LIST),
      },
    );
  };

  const onClickRegister = (e: any) => {
    e.preventDefault();
    const showSubmitSuccessModal = () => {
      modal.show(
        <h6>
          {t('text:The_request_has_been_submitted_successfully')}
          <br />
          {t('text:See_the_request_in_the_Waiting_for_Confirmation_tab')}
        </h6>,
        {
          closeBtnCb: () => history.push(ROUTES_SY.MANAGEMENT.BANK_CODE_LIST),
        },
      );
    };

    modal.show(<h6>{t('text:Would_you_like_to_request_the_registration_of_the_bank_code_information?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => {
        requestRegister();
      },
    });

    const requestRegister = async () => {
      const data = bankCodeRegisterForm.getValues();
      try {
        requestDTOParser(data);
        await requestSyWaitingBankCodeRegister(data);
        showSubmitSuccessModal();
      } catch (e) {
        modal.show(e);

        formErrorHandler<SyWaitingBankCodeRegisterRequest>(
          e,
          bankCodeRegisterForm.setError,
          bankCodeRegisterForm.clearErrors,
        );
      }
    };
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Bank_Code_Registration')} />
      <GuideMessage
        message={[
          t(
            'text:Enter_the_information_of_the_bank_codes_you_would_like_to_register_to_the_platform_All_areas_are_required',
          ),
          t(
            'text:If_the_bank_or_branch_code_have_already_been_registered_on_the_platform,_revise_the_existing_bank_code_information_in_tge_bank_code_details_page',
          ),
          t('text:Click_on_the_Register_button_at_the_bottom_of_the_page_to_submit_the_registration_request'),
        ]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Bank_Code_Information')} />
        <form>
          <FormBorder editable>
            <FormContents>
              <div className="row">
                <input type="text" name="financierId" hidden ref={bankCodeRegisterForm.register} />
                <FormInput
                  label={t('text:Financier_Name')}
                  disabled
                  requiredOptions={{ fixedRequired: true }}
                  value={financierName}
                  error={bankCodeRegisterForm.errors.financierId}
                >
                  <Button onClick={onClickSearchFinancier} bold className="ms-2">
                    {t('text:Search_Financier')}
                  </Button>
                </FormInput>
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Bank_Code')}
                  name="bankCode"
                  ref={bankCodeRegisterForm.register}
                  requiredOptions={{ required: true }}
                  error={bankCodeRegisterForm.errors.bankCode}
                />
                <FormInput
                  label={t('text:Bank_Name')}
                  name="bankName"
                  ref={bankCodeRegisterForm.register}
                  requiredOptions={{ required: true }}
                  error={bankCodeRegisterForm.errors.bankName}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Branch_Code')}
                  name="branchCode"
                  ref={bankCodeRegisterForm.register}
                  error={bankCodeRegisterForm.errors.branchCode}
                />
                <FormInput
                  label={t('text:Branch_Name')}
                  name="branchName"
                  ref={bankCodeRegisterForm.register}
                  error={bankCodeRegisterForm.errors.branchName}
                />
              </div>
            </FormContents>
          </FormBorder>
          <div className="flex-end mt-4">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={onClickCancel}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onClickRegister} className="ms-2">
              {t('text:Register')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default SystemBankCodeRegister;
