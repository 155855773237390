import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE, REPORT_TYPE } from 'enums';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorReportReceiverVOModel } from 'models/vo/AnchorReportReceiverVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFiAnchorReportReceiverList } from 'utils/http/api/financier/anchor-report-receivers';
import type { FiAnchorReportReceiverListRequest } from 'utils/http/api/financier/anchor-report-receivers/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const getConstants = () => {
  const { t } = useTranslation(['format']);
  const FI_DEALER_REPORT_LIST_QS_KEY = 'fi-dealer-report-list';

  const REPORT_SEARCH_RESULT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Recipient_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Email'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Program_Type'),
      colWidths: 80,
    },
    {
      headerText: t('text:Report_Type'),
      colWidths: 80,
    },
    {
      headerText: t('text:Recipient_Status'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return {
    t,
    REPORT_SEARCH_RESULT_TABLE_HEADERS,
    FI_DEALER_REPORT_LIST_QS_KEY,
  };
};

function FinancierDealerReportList(): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const { t, REPORT_SEARCH_RESULT_TABLE_HEADERS, FI_DEALER_REPORT_LIST_QS_KEY } = getConstants();

  const [partnerReportPageData, setPartnerReportPageData] = useState<Pageable<AnchorReportReceiverVOModel[]>>();
  const { pageable: partnerReportPageable, setPageable: setPartnerReportPageable } =
    usePageable(FI_DEALER_REPORT_LIST_QS_KEY);

  const getPartnerReportReceiverProperty = useProperty<FiAnchorReportReceiverListRequest>();

  const {
    register: partnerReportSearchFormRegister,
    getValues: getPartnerReportSearchFormValues,
    setValue: setPartnerReportSearchFormValue,
    reset: resetPartnerReportSearchForm,
    handleSubmit: handlePartnerReportSearchFormSubmit,
  } = useForm<FiAnchorReportReceiverListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FiAnchorReportReceiverListRequest>(
        setPartnerReportSearchFormValue,
        getParsedSearchParams(FI_DEALER_REPORT_LIST_QS_KEY).formSearchData,
      );
      fetchFiAnchorReportReceiverList(
        partnerReportPageable.currentPage,
        partnerReportPageable.sizePerPage,
        getPartnerReportSearchFormValues(),
      );
    }

    return () => {};
  }, [mounted]);

  const fetchFiAnchorReportReceiverList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
    searchData: FiAnchorReportReceiverListRequest,
  ): Promise<void> => {
    try {
      const partnerReportPageResponse = await requestFiAnchorReportReceiverList(
        selectedPageNumber,
        selectedRowCount,
        searchData,
      );
      updateSearchParams(
        {
          ...searchData,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowCount,
        },
        FI_DEALER_REPORT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerReportPageData(partnerReportPageResponse);
        setPartnerReportPageable(partnerReportPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRegister = (e: any): void => {
    e.preventDefault();

    history.push(ROUTES_FI.MANAGE_PARTNER.REPORT_REGISTER);
  };

  const onClickRemoveFilter = (e: any): void => {
    e.preventDefault();

    resetPartnerReportSearchForm();
  };

  const onClickSearch = async (searchParameters: FiAnchorReportReceiverListRequest, e: any): Promise<void> => {
    e.preventDefault();
    await fetchFiAnchorReportReceiverList(1, partnerReportPageable.sizePerPage, searchParameters);
  };

  const paginate = async (selectedPageNumber: number = 1, selectedRowCount: number = 10): Promise<void> => {
    await fetchFiAnchorReportReceiverList(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(FI_DEALER_REPORT_LIST_QS_KEY).formSearchData,
    );
  };

  const renderReportSearchResultTable = (): JSX.Element[] | undefined => {
    const getSendReportText = (data?: boolean): string => {
      switch (data) {
        case true:
          return t('text:Active');
        case false:
          return t('text:Deactivated');
        default:
          return '';
      }
    };

    return partnerReportPageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.name} />
        <Td data={item.email} />
        <Td data={item.anchorClientName} />
        <Td data={item.anchorAgreementContractNo} />
        <Td data={item.currencyType} />
        <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
        <Td data={t(`code:report-type.${item.reportType}`)} format="code" />
        <Td
          className={item.activated ? 'text-bold-blue-main600' : 'text-bold-brick-red'}
          data={getSendReportText(item.activated)}
        />
        <TdLink path={ROUTES_FI.MANAGE_PARTNER.REPORT_DETAIL_BUILD_PATH(item.anchorReportReceiverId)} />
      </Tr>
    ));
  };

  const guideMessages = (): string[] => {
    const commonGuideMessages = [
      t('text:The_reports_are_sent_to_the_recipients_registered_emails') +
        ' ' +
        t('text:The_report_will_not_be_sent_if_the_Partner_Master_Agreement_is_suspended'),
      t('text:Click_on_the_arrow_button_to_view_the_details_of_each_report_recipient'),
    ];

    return isAdmin
      ? [
          t(
            'text:Click_on_the_Register_button_to_recipients_who_will_receive_the_email_report_for_the_Partner_Master_Agreements',
          ),
          ...commonGuideMessages,
        ]
      : [
          t(
            'text:Below_is_the_list_of_people_who_recieve_the_utilization_or_repayment_reports_of_Partner_Master_Agreements',
          ),
          ...commonGuideMessages,
        ];
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_Report_Recipient')} />
      <GuideMessage message={guideMessages()}>
        {{
          button: isAdmin ? <Button onClick={onClickRegister}>{t('text:Register')}</Button> : null,
        }}
      </GuideMessage>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Recipient_Name')} />
              <SearchInput name={getPartnerReportReceiverProperty('name')} ref={partnerReportSearchFormRegister} />
              <SearchLabel label={t('text:Email')} />
              <SearchInput name={getPartnerReportReceiverProperty('email')} ref={partnerReportSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput
                name={getPartnerReportReceiverProperty('anchorClientName')}
                ref={partnerReportSearchFormRegister}
              />
              <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
              <SearchInput
                name={getPartnerReportReceiverProperty('anchorAgreementContractNo')}
                ref={partnerReportSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name={getPartnerReportReceiverProperty('currencyType')}
                ref={partnerReportSearchFormRegister}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name={getPartnerReportReceiverProperty('collateralType')}
                ref={partnerReportSearchFormRegister}
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Report_Type')} />
              <SearchSelect
                name={getPartnerReportReceiverProperty('reportType')}
                ref={partnerReportSearchFormRegister}
                selectOptions={getSelectOptions<REPORT_TYPE>('REPORT_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Recipient_Status')} />
              <SearchSelect
                name={getPartnerReportReceiverProperty('activated')}
                ref={partnerReportSearchFormRegister}
                selectOptions={getSelectOptions<string>('SEND_REPORT', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handlePartnerReportSearchFormSubmit(onClickSearch)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {partnerReportPageData?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={REPORT_SEARCH_RESULT_TABLE_HEADERS} />
          <TableBody numOfCol={REPORT_SEARCH_RESULT_TABLE_HEADERS.length}>{renderReportSearchResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={partnerReportPageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierDealerReportList;
