import type { FiscalYearSettingVO, FiscalYearSettingVOModel } from 'models/vo/FiscalYearSettingVO';
import { formattingToFiscalYearSettingVOModel } from 'models/vo/FiscalYearSettingVO';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';

export async function requestFiscalYearSettingCurrentUser(): Promise<FiscalYearSettingVOModel | null> {
  const response = await http.get<FiscalYearSettingVO | null>({
    url: API_CM.FISCAL_YEAR_SETTING.CURRENT_USER,
  });
  if (response === null) return response;

  return formattingToFiscalYearSettingVOModel(response);
}

export async function requestFiscalYearSettingByDealerAgreementId(
  dealerAgreementId: number,
): Promise<FiscalYearSettingVOModel | null> {
  const response = await http.get<FiscalYearSettingVO | null>({
    url: API_CM.FISCAL_YEAR_SETTING.SETTING_BY_DEALER_AGREEMENT_ID(dealerAgreementId),
  });
  if (response === null) return response;

  return formattingToFiscalYearSettingVOModel(response);
}
