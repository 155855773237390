import { useTranslation } from 'react-i18next';

import { getCss, setCss } from 'utils/storage/LocalStorage';
import { ThemeEnum } from 'utils/theme/ThemeProvider';

export function useThemeSetterController() {
  const { t } = useTranslation();

  const getThemeText = (color: ThemeEnum | string) => {
    switch (color) {
      case ThemeEnum.BLUE:
        return t('text:Blue');
      case ThemeEnum.ORANGE:
        return t('text:Yellow');
      default:
        return '';
    }
  };

  const handleThemeChange = (color: ThemeEnum) => {
    const getThemeWrapperID = document.getElementById('theme-wrapper');
    getThemeWrapperID?.setAttribute('class', `theme-${color}`);

    setCss(color);
  };

  const THEME_OPTIONS = [
    {
      text: t('text:Yellow_Theme'),
      value: getThemeText(ThemeEnum.ORANGE),
      onClick: () => handleThemeChange(ThemeEnum.ORANGE),
    },
    {
      text: t('text:Blue_Theme'),
      value: getThemeText(ThemeEnum.BLUE),
      onClick: () => handleThemeChange(ThemeEnum.BLUE),
    },
  ];

  return {
    defaultTheme: getThemeText(getCss() || ThemeEnum.ORANGE),
    THEME_OPTIONS,
  };
}
