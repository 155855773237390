import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import WithProvider from 'utils/render';

import useRegistrationDetailController from './useRegistrationDetailController';
import AgreementProvider from '../models/agreement/Provider';
import DivisionsProvider from '../models/divisions/Context';
import ExtraInformationProvider from '../models/extraInformation/Context';
import AgreementRevisionHistory from '../sections/common/AgreementRevisionHistory';
import AnchorCompany from '../sections/common/AnchorCompany';
import DesignatedAccountTransactionHistory from '../sections/common/DesignatedAccountTransactionHistory';
import Financier from '../sections/common/Financier';
import FinancingLimitOfPartners from '../sections/common/FinancingLimitOfPartners';
import GeneralInformation from '../sections/common/GeneralInformation';
import LoadAgreement from '../sections/common/LoadAgreement';
import AutomaticFinancing from '../sections/dealer/AutomaticFinancing';
import InvoicePayment from '../sections/dealer/InvoicePayment';
import InvoiceRegistration from '../sections/dealer/InvoiceRegistration';
import ARRegistration from '../sections/vendor/ARRegistration';
import ARSettlement from '../sections/vendor/ARSettlement';

const AnchorAgreementRegistrationDetail = () => {
  const { t } = useTranslation(['format']);

  const {
    isInvoice,
    isEditable,
    isEditButtonVisible,
    useAgreementInterface,
    isDesignatedAccountTransactionHistoryRender,
    methods,
    isRequestChangeButtonDisabled,
    isEditableStyle,
    GuideMessageText,
    getReadOnlyValue,
    handleRevisionHistoryTabClick,
    handleAgreementTabClick,
    handleEditButtonClick,
    handleCancelButtonClick,
    handleRequestChangeButtonClick,
  } = useRegistrationDetailController();

  return (
    <>
      <BackHeaderTitle
        title={t('text:Anchor_Master_Agreement_Details')}
        right={
          isEditButtonVisible && (
            <>
              <div className="button-separator" />
              {isEditable ? (
                <>
                  <Button
                    size={ButtonSizeEnum.LG}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleCancelButtonClick}
                  >
                    {t('text:Cancel')}
                  </Button>
                  <Button
                    size={ButtonSizeEnum.LG}
                    onClick={handleRequestChangeButtonClick}
                    disabled={isRequestChangeButtonDisabled}
                  >
                    {t('text:Request_Change')}
                  </Button>
                </>
              ) : (
                <Button size={ButtonSizeEnum.LG} onClick={handleEditButtonClick}>
                  {t('text:Edit')}
                </Button>
              )}
            </>
          )
        }
      />

      {!isEmpty(GuideMessageText) && <GuideMessage message={GuideMessageText} messageType={MessageType.ALERT} />}

      <Tab
        tabList={[
          {
            tabName: t('text:Agreement'),
            onClickTab: handleAgreementTabClick,
          },
          {
            tabName: t('text:Revision_History'),
            onClickTab: handleRevisionHistoryTabClick,
          },
        ]}
        disabled={isEditable}
        tabViewList={[
          // Agreement
          <Fragment key="agreement">
            <Form
              methods={methods}
              isEditable={isEditable}
              isEditableStyle={isEditableStyle}
              getReadOnlyValue={getReadOnlyValue}
            >
              {useAgreementInterface && isEditable && (
                <div className="content-area">
                  <SectionTitle title={t('text:Load_Agreement_from_Financier_System')} />
                  <LoadAgreement />
                </div>
              )}

              <div className="content-area">
                <SectionTitle title={t('text:Contracting_Parties')} />
                <Border isEditable={isEditableStyle}>
                  <Financier />
                  <AnchorCompany />
                </Border>
              </div>

              <div className="content-area">
                <SectionTitle title={t('text:General_Information')} />
                <Border isEditable={isEditableStyle}>
                  <GeneralInformation />
                </Border>
              </div>

              <div className="content-area">
                <SectionTitle
                  title={
                    isInvoice ? t('text:Invoice_Registration_and_Payment') : t('text:AR_Registration_and_Settlement')
                  }
                />
                <Border isEditable={isEditableStyle}>
                  {isInvoice ? (
                    <>
                      <InvoiceRegistration />
                      <InvoicePayment />
                    </>
                  ) : (
                    <>
                      <ARRegistration />
                      <ARSettlement />
                    </>
                  )}
                </Border>
              </div>

              <div className="content-area">
                <SectionTitle title={t('text:Financing_Terms_and_Conditions_for_Partners')} />
                <Border isEditable={isEditableStyle}>
                  <FinancingLimitOfPartners />
                  {isInvoice && <AutomaticFinancing />}
                </Border>
              </div>
            </Form>

            {isDesignatedAccountTransactionHistoryRender && <DesignatedAccountTransactionHistory />}
          </Fragment>,
          // Revision History
          <AgreementRevisionHistory key="history" />,
        ]}
      />
    </>
  );
};

export default WithProvider({
  Component: AnchorAgreementRegistrationDetail,
  Provider: [AgreementProvider, DivisionsProvider, ExtraInformationProvider],
});
