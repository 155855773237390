import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';

import FinancierARListDeletedTab from './tabs/FinancierARListDeletedTab';
import FinancierARListRegisteredTab from './tabs/FinancierARListRegisteredTab';
import useFinancierARListController from './useFinancierARListController';

function FinancierArList() {
  const { t } = useTranslation();

  const {
    registeredARPage,
    deletedARPage,
    registeredARSearchForm,
    deletedARSearchForm,
    registeredListPageable,
    deletedListPageable,
    handleClickRegisteredAPSearch,
    paginateRegisteredAPList,
    handleClickDeletedAPSearch,
    paginateDeletedAPList,
  } = useFinancierARListController();

  return (
    <>
      <HeaderTitle title={t('text:AR')} />
      <GuideMessage
        message={[
          t('text:ARs_that_wait_for_approval_or_are_registered_can_be_found_on_the_Registered_tab'),
          t('text:ARs_deleted_by_admin_after_approval_is_completed_are_available_on_the_Deleted_tab'),
        ]}
      />
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:Registered'),
            tabItemCount: registeredARPage?.totalElements ?? 0,
          },
          {
            tabName: t('text:Deleted'),
            tabItemCount: deletedARPage?.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...registeredARSearchForm} key="registered">
            <FinancierARListRegisteredTab
              data={registeredARPage}
              pageable={registeredListPageable}
              paginate={paginateRegisteredAPList}
              handleClickSearch={handleClickRegisteredAPSearch}
            />
          </FormProvider>,
          <FormProvider {...deletedARSearchForm} key="deleted">
            <FinancierARListDeletedTab
              data={deletedARPage}
              pageable={deletedListPageable}
              paginate={paginateDeletedAPList}
              handleClickSearch={handleClickDeletedAPSearch}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default FinancierArList;
