import { ROUTES_AC } from 'constants/routes/anchor';
import { ROUTES_DE } from 'constants/routes/dealer';
import { ROUTES_FI } from 'constants/routes/financier';
import { NOTIFICATION_TYPE, ROLE } from 'enums';
import type { UserNotification } from 'models/vo/UserNotificationVO';
import type { NewObject } from 'types';

const getPath = (role: ROLE, item: UserNotification): { route: string; state?: NewObject } | undefined => {
  const getAnchorPath = (item: UserNotification) => {
    const { eventId, notificationType, relatedFinancierId } = item;

    switch (notificationType) {
      case NOTIFICATION_TYPE.AR_REGISTRATION_REQUEST:
      case NOTIFICATION_TYPE.AR_REGISTRATION_REJECT:
      case NOTIFICATION_TYPE.AR_CONFIRMATION_REQUEST:
      case NOTIFICATION_TYPE.AR_CONFIRMATION_REJECT:
      case NOTIFICATION_TYPE.AR_CONFIRMED:
        return {
          route: ROUTES_AC.REGISTER_AP.CONFIRMATION_DETAIL_BUILD_PATH(eventId),
          state: { financierEnterpriseId: relatedFinancierId },
        };
      case NOTIFICATION_TYPE.INVOICE_REGISTRATION_REQUEST:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_AC:
      case NOTIFICATION_TYPE.INVOICE_REGISTRATION_REJECT_BY_AC:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REJECT_BY_AC:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMATION_CANCEL_BY_AC:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMED_BY_AC:
        return {
          route: ROUTES_AC.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(eventId, relatedFinancierId),
        };
      default:
        return;
    }
  };

  const getFinancierPath = (item: UserNotification) => {
    const { eventId, notificationType, collateralType, relatedFinancierId } = item;

    switch (notificationType) {
      case NOTIFICATION_TYPE.LOAN_DEALER_REQUESTED:
      case NOTIFICATION_TYPE.LOAN_REJECTED:
      case NOTIFICATION_TYPE.AUTO_LOAN_REQUESTED:
        return {
          route: ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(eventId),
        };
      case NOTIFICATION_TYPE.LOAN_APPROVAL_REQUEST:
      case NOTIFICATION_TYPE.LOAN_APPROVAL_REQUEST_CANCEL:
      case NOTIFICATION_TYPE.LOAN_APPROVAL_REQUEST_RETURN:
      case NOTIFICATION_TYPE.LOAN_APPROVED:
      case NOTIFICATION_TYPE.AUTO_LOAN_APPROVED:
      case NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST_RETURNED:
      case NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST:
      case NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST_CANCEL:
        return {
          route: ROUTES_FI.MANAGE_FINANCING.APPROVAL_DETAIL_BUILD_PATH(eventId),
        };
      case NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST_CONFIRM:
        return { route: ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(eventId) };
      case NOTIFICATION_TYPE.EARLY_PAYMENT_REQUESTED:
      case NOTIFICATION_TYPE.EARLY_PAYMENT_COMPLETED:
      case NOTIFICATION_TYPE.EARLY_PAYMENT_EXPIRED:
        return { route: ROUTES_FI.MANAGE_FINANCING.EARLY_REPAYMENT_DETAIL_BUILD_PATH(eventId) };
      case NOTIFICATION_TYPE.LOAN_EXTENSION_REQUESTED:
      case NOTIFICATION_TYPE.LOAN_EXTENSION_REJECTED:
      case NOTIFICATION_TYPE.LOAN_EXTENSION_PREAPPROVED:
      case NOTIFICATION_TYPE.LOAN_EXTENSION_RETURNED:
      case NOTIFICATION_TYPE.LOAN_EXTENSION_APPROVED:
        return { route: ROUTES_FI.MANAGE_FINANCING.EXTENSION_DETAIL_BUILD_PATH(eventId) };
      case NOTIFICATION_TYPE.INVOICE_REGISTRATION_REQUEST:
      case NOTIFICATION_TYPE.INVOICE_REGISTRATION_REJECT_BY_FI:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_FI:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REJECT_BY_FI:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMED_BY_FI:
        return { route: ROUTES_FI.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(eventId, relatedFinancierId) };
      case NOTIFICATION_TYPE.ANCHOR_AGREEMENT_REQUEST:
      case NOTIFICATION_TYPE.ANCHOR_AGREEMENT_REQUEST_CANCEL:
      case NOTIFICATION_TYPE.ANCHOR_AGREEMENT_REQUEST_RETURNED:
      case NOTIFICATION_TYPE.ANCHOR_AGREEMENT_REQUEST_APPROVED:
        return {
          route: ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_WAITING_DETAIL_BUILD_PATH(eventId),
          state: { supportedCollateralType: collateralType },
        };
      case NOTIFICATION_TYPE.PARTNER_AGREEMENT_REQUEST:
      case NOTIFICATION_TYPE.PARTNER_AGREEMENT_REQUEST_CANCEL:
      case NOTIFICATION_TYPE.PARTNER_AGREEMENT_REQUEST_RETURNED:
      case NOTIFICATION_TYPE.PARTNER_AGREEMENT_REQUEST_APPROVED:
        return {
          route: ROUTES_FI.MANAGE_PARTNER.AGREEMENT_WAITING_DETAIL_BUILD_PATH(eventId),
          state: { supportedCollateralType: collateralType },
        };
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_DEALER_REQUESTED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST_CANCEL:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST_CANCEL:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST_RETURNED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST_CONFIRM:
        return {
          route: ROUTES_FI.MANAGE_FINANCING.BULK_APPROVAL_DETAIL_BUILD_PATH(eventId),
        };
      case NOTIFICATION_TYPE.AR_REGISTRATION_REQUEST_TO_FI:
      case NOTIFICATION_TYPE.AR_CONFIRMATION_REQUEST_BY_FI:
      case NOTIFICATION_TYPE.AR_REGISTRATION_REJECT_BY_FI:
      case NOTIFICATION_TYPE.AR_CONFIRMED_BY_FI:
      case NOTIFICATION_TYPE.AR_CONFIRMATION_REJECT_BY_FI:
        return {
          route: ROUTES_FI.REGISTER_AR.CONFIRMATION_DETAIL_BUILD_PATH(eventId),
        };
      default:
        return;
    }
  };

  const getPartnerPath = (item: UserNotification) => {
    const { eventId, notificationType, relatedFinancierId, relatedAnchorAgreementId } = item;

    switch (notificationType) {
      case NOTIFICATION_TYPE.LOAN_DEALER_CREATED:
      case NOTIFICATION_TYPE.LOAN_DEALER_CANCELLED:
      case NOTIFICATION_TYPE.LOAN_DEALER_REQUESTED:
      case NOTIFICATION_TYPE.AUTO_LOAN_REQUESTED:
        return { route: ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(eventId) };
      case NOTIFICATION_TYPE.LOAN_REJECTED:
      case NOTIFICATION_TYPE.LOAN_APPROVED:
      case NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST_CONFIRM:
      case NOTIFICATION_TYPE.AUTO_LOAN_APPROVED:
        return { route: ROUTES_DE.MANAGE_FINANCING.CONFIRMATION_DETAIL_BUILD_PATH(eventId) };
      case NOTIFICATION_TYPE.EARLY_PAYMENT_COMPLETED:
      case NOTIFICATION_TYPE.EARLY_PAYMENT_EXPIRED:
        return { route: ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EARLY_REPAYMENT_DETAIL_BUILD_PATH(eventId) };
      case NOTIFICATION_TYPE.LOAN_EXTENSION_REJECTED:
      case NOTIFICATION_TYPE.LOAN_EXTENSION_APPROVED:
        return { route: ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EXTENSION_DETAIL_BUILD_PATH(eventId) };
      case NOTIFICATION_TYPE.AR_APPROVAL_REQUEST:
      case NOTIFICATION_TYPE.AR_APPROVAL_REJECT:
      case NOTIFICATION_TYPE.AR_REGISTRATION_REQUEST:
      case NOTIFICATION_TYPE.AR_REGISTRATION_REJECT:
      case NOTIFICATION_TYPE.AR_CONFIRMATION_REJECT:
      case NOTIFICATION_TYPE.AR_CONFIRMED:
        return {
          route: ROUTES_DE.REGISTER_AR.CONFIRMATION_DETAIL_BUILD_PATH(eventId),
          state: {
            financierEnterpriseId: relatedFinancierId,
            anchorAgreementId: relatedAnchorAgreementId,
          },
        };
      case NOTIFICATION_TYPE.INVOICE_APPROVAL_REQUEST:
      case NOTIFICATION_TYPE.INVOICE_APPROVAL_REJECT:
      case NOTIFICATION_TYPE.INVOICE_REGISTRATION_REQUEST:
      case NOTIFICATION_TYPE.INVOICE_REGISTRATION_REJECT_BY_AC:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REJECT_BY_AC:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMATION_CANCEL_BY_AC:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMED_BY_AC:
      case NOTIFICATION_TYPE.INVOICE_REGISTRATION_REJECT_BY_FI:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REJECT_BY_FI:
      case NOTIFICATION_TYPE.INVOICE_CONFIRMED_BY_FI:
        return { route: ROUTES_DE.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(eventId, relatedFinancierId) };
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_DEALER_CREATED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_DEALER_REQUESTED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_DEALER_CANCELLED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVED:
      case NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST_CONFIRM:
        return {
          route: ROUTES_DE.MANAGE_FINANCING.BULK_CONFIRMATION_DETAIL_BUILD_PATH(eventId),
        };
      case NOTIFICATION_TYPE.AR_REGISTRATION_REQUEST_TO_FI:
      case NOTIFICATION_TYPE.AR_REGISTRATION_REJECT_BY_FI:
      case NOTIFICATION_TYPE.AR_CONFIRMED_BY_FI:
      case NOTIFICATION_TYPE.AR_CONFIRMATION_REJECT_BY_FI:
        return {
          route: ROUTES_DE.REGISTER_AR.CONFIRMATION_DETAIL_BUILD_PATH(eventId),
        };
      default:
        return;
    }
  };

  if (role === ROLE.ROLE_FINANCIER) return getFinancierPath(item);
  else if (role === ROLE.ROLE_ANCHOR) return getAnchorPath(item);
  else if (role === ROLE.ROLE_DEALER) return getPartnerPath(item);
};

export default getPath;
