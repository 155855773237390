import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import Select from 'components/stateless/Select/Select';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { BranchVOModel } from 'models/vo/BranchVO';
import { requestBranchList } from 'utils/http/api/financier/branches';
import type { FinancierBranchListRequest } from 'utils/http/api/financier/branches/request';
import useModal from 'utils/modal/useModal';

import Button from '../../Button/Button';

interface selectedDataProps {
  modalId: number;
  getSelectedData(data: BranchVOModel): void;
}

function SearchBranchModal({ modalId, getSelectedData }: selectedDataProps) {
  const mounted = useMounted();
  const getProperty = useProperty<FinancierBranchListRequest>();
  const [branchPage, setBranchPage] = useState<Pageable<BranchVOModel[]>>();
  const { pageable, setPageable } = usePageable();
  const modal = useModal();
  const { t } = useTranslation();

  const onBranchSearchSubmit = async () => {
    await fetchBranchInfo();
  };

  // branch search request ( branch code | branch name )
  const {
    register: branchSearchRegister,
    getValues: getBranchSearchValues,
    handleSubmit: branchSearchHandleSubmit,
  } = useForm<FinancierBranchListRequest>({
    mode: 'onSubmit',
  });

  const fetchBranchInfo = async (pageNumber: number = 1, rowCount: number = 10) => {
    const selectOption: string = getBranchSearchValues('searchBranchSelectOption'); // select option value
    const searchInputValue: string = getBranchSearchValues('searchInputValue'); // input value
    const searchObj: { [index: string]: string } = {
      [selectOption]: searchInputValue,
    };
    const branchList = await requestBranchList(pageNumber, rowCount, searchObj);
    setBranchPage(branchList);
    setPageable(branchList);
  };

  const paginate = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    await fetchBranchInfo(pageNumber, sizePerPage);
  };

  useEffect(() => {
    if (mounted) {
      fetchBranchInfo();
    }
  }, [mounted]);

  const onBranchSelect = (e: any, item: BranchVOModel) => {
    e.preventDefault();
    getSelectedData({ ...item });
    modal.close(modalId);
  };

  const BRANCH_SELECT_OPTIONS = [
    { label: t('text:Branch_Code'), value: getProperty('branchCode') },
    { label: t('text:Branch_Name'), value: getProperty('branchName') },
  ];

  return (
    <form>
      <div className="d-flex information-filter mt-0">
        <Select
          className="information-form__select"
          selectOptions={BRANCH_SELECT_OPTIONS}
          name="searchBranchSelectOption"
          ref={branchSearchRegister}
        />
        <input type="text" name="searchInputValue" ref={branchSearchRegister} placeholder={`${t('text:Search')}...`} />
        <Button type="submit" onClick={branchSearchHandleSubmit(onBranchSearchSubmit)}>
          {t('text:Search')}
        </Button>
      </div>
      <table className="table-border">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col style={{ width: '265px' }} />
          <col style={{ width: '70px' }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col"> {t('text:Branch_Code')}</th>
            <th scope="col"> {t('text:Branch_Name')}</th>
            <th scope="col">{t('text:Legal_Representative_Name')}</th>
            <th scope="col">{t('text:Telephone')}</th>
            <th scope="col">{t('text:Registered_Office_Address')}</th>
            <th scope="col" className="table-column-right-fixed" />
          </tr>
        </thead>
        <tbody>
          {branchPage?.content.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center">
                {t('text:No_data_available')}
              </td>
            </tr>
          ) : (
            branchPage?.content.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.branchCode}</td>
                  <td>{item.branchName}</td>
                  <td>{item.managerName}</td>
                  <td>{item.telephone}</td>
                  <td>{item.address}</td>
                  <td className="information-table-more">
                    <Button onClick={e => onBranchSelect(e, { ...item })}>{t('text:Select')}</Button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      <Pagination pageable={pageable} paginate={paginate} />
    </form>
  );
}

export default SearchBranchModal;
