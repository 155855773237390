import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TdLink from 'components/stateless/Table/TdLink';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { AUTHORITY_TYPE, ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemEnterpriseList } from 'utils/http/api/system/enterprises';
import type { SystemEnterpriseListRequest } from 'utils/http/api/system/enterprises/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const SY_FINANCIER_LIST_QS_KEY = 'sy-financier-list';

function SystemFinancierList() {
  const useAuthorityType = getSignIn()?.authorityType;

  const history = useHistory();
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const { pageable, setPageable } = usePageable(SY_FINANCIER_LIST_QS_KEY);
  const [enterprisePageData, setEnterprisePageData] = useState<Pageable<EnterpriseVOModel[]>>();

  const {
    register: searchFormRegister,
    reset: searchFormReset,
    getValues: getSearchFormValues,
    setValue: searchFormSetValue,
  } = useForm<SystemEnterpriseListRequest>();

  const guideMessageList: string[] = [
    t('text:Click_the_Register_Financier_button_on_the_right_to_register_the_Financier'),
    t('text:Registered_financiers_can_be_found_in_the_list_below'),
  ];

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemEnterpriseListRequest>(
        searchFormSetValue,
        getParsedSearchParams(SY_FINANCIER_LIST_QS_KEY).formSearchData,
      );
      fetchFinancierLists(pageable.currentPage, pageable.sizePerPage, getSearchFormValues());
    }
  }, [mounted]);

  const fetchFinancierLists = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemEnterpriseListRequest = {},
  ): Promise<void> => {
    try {
      data.enterpriseType = ENTERPRISE_TYPE.FI;
      const enterprisePageResponse = await requestSystemEnterpriseList(pageNumber, rowCount, data);

      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_FINANCIER_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setEnterprisePageData(enterprisePageResponse);
        setPageable(enterprisePageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();

    searchFormReset({
      businessCode: '',
      enterpriseName: '',
      managerName: '',
      telephone: '',
      address: '',
      enterpriseType: ENTERPRISE_TYPE.FI,
    });
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();

    fetchFinancierLists(0, pageable.sizePerPage, getSearchFormValues());
  };

  const paginate = (selectedPageNumber: number, selectedRowNumber: number) => {
    fetchFinancierLists(
      selectedPageNumber,
      selectedRowNumber,
      getParsedSearchParams(SY_FINANCIER_LIST_QS_KEY).formSearchData,
    );
  };

  const renderRegisterFinancierButton = (): JSX.Element => {
    const onClickRegisterFinancier = (e: any): void => {
      e.preventDefault();

      history.push(ROUTES_SY.MANAGEMENT.FINANCIER_REGISTER);
    };

    return <Button onClick={onClickRegisterFinancier}>{t('text:Register_Financier')}</Button>;
  };

  const renderResultTable = (): JSX.Element[] | JSX.Element | undefined => {
    return enterprisePageData && enterprisePageData.content ? (
      enterprisePageData.content.length !== 0 ? (
        enterprisePageData.content.map((item, index: number) => (
          <tr key={index}>
            <td>{tableValueManage(item.enterpriseName)}</td>
            <td>{tableValueManage(item.businessCode)}</td>
            <td>{tableValueManage(item.representativeName)}</td>
            <td>{tableValueManage(item.telephone)}</td>
            <td>{tableValueManage(item.address)}</td>
            <TdLink path={ROUTES_SY.MANAGEMENT.FINANCIER_DETAIL_BUILD_PATH(item.enterpriseId)} />
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={6} className="text-center">
            {t('text:No_data_available')}
          </td>
        </tr>
      )
    ) : (
      <tr>
        <td colSpan={6} className="text-center">
          {t('text:No_data_available')}
        </td>
      </tr>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Financier')} />
      <GuideMessage message={guideMessageList} messageType={MessageType.BASIC}>
        {{
          button: useAuthorityType === AUTHORITY_TYPE.ADMIN ? renderRegisterFinancierButton() : undefined,
        }}
      </GuideMessage>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="enterpriseName" ref={searchFormRegister} />
              <SearchLabel label={t('text:Company_Registration_Number')} />
              <SearchInput name="businessCode" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Legal_Representative_Name')} />
              <SearchInput name="managerName" ref={searchFormRegister} />
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput name="telephone" ref={searchFormRegister} />
              <input type="hidden" name="enterpriseType" ref={searchFormRegister} value={ENTERPRISE_TYPE.FI} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {enterprisePageData?.totalElements}
        </p>
        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '100px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '50px' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t('text:Financier_Name')}</th>
                <th scope="col">{t('text:Company_Registration_Number')}</th>
                <th scope="col">{t('text:Legal_Representative_Name')}</th>
                <th scope="col">{t('text:Telephone')}</th>
                <th scope="col">{t('text:Registered_Office_Address')}</th>
                <th scope="col" className="table-column-right-fixed" />
              </tr>
            </thead>
            <tbody className="bg-white">{renderResultTable()}</tbody>
          </table>
        </div>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemFinancierList;
