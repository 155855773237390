import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Pagination from 'components/stateless/Pagination/Pagination';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import usePageable from 'hooks/usePageable';
import type { UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import { requestSyUsageList } from 'utils/http/api/system/usage';
import type { SearchSyUsageDTO } from 'utils/http/api/system/usage/requests';
import useModal from 'utils/modal/useModal';

import UsageTable from '../../components/UsageTable';

function MonthSummary() {
  const { t } = useTranslation();
  const { show: showModal } = useModal();
  const [usageList, setUsageList] = useState<UsageSummaryVOModel[]>([]);
  const { pageable, setPageable } = usePageable();

  const fetchUsageList = async (page = 1, sizePerPage = 10) => {
    const firstDateOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const todayDate = dayjs().format('YYYY-MM-DD');
    const searchValue: SearchSyUsageDTO = { fromDate: firstDateOfMonth, toDate: todayDate };

    try {
      const response = await requestSyUsageList(page, sizePerPage, searchValue);
      setUsageList(response.content);
      setPageable(response);
    } catch (error) {
      showModal(error);
    }
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchUsageList(page, sizePerPage);
  };

  useEffect(() => {
    fetchUsageList();
  }, []);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:This_Month_Summary')} />
      <UsageTable usageDataList={usageList} isUsageHistory={false} />
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default MonthSummary;
