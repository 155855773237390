import type { UsageSummaryVO, UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { SearchFiUsageDTO } from './requests';

export async function requestFiUsage(searchValue: SearchFiUsageDTO): Promise<UsageSummaryVOModel> {
  return await http.get<UsageSummaryVO>({
    url: API_FI.USAGE.USAGE,
    data: searchValue,
  });
}
