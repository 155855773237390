import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import Select from 'components/stateless/Select/Select';
import getSelectOptions from 'constants/selectOptions';
import type { FINANCIER_CLIENT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { requestSystemFinancierClientList } from 'utils/http/api/system/financier-clients';
import useModal from 'utils/modal/useModal';

interface selectedDataProps {
  modalId: number;
  clientType: FINANCIER_CLIENT_TYPE;
  getSelectedData(data: FinancierClientVOModel): void;
  financierId: number;
}

export default function SystemSearchFinancierClientModal({
  modalId,
  financierId,
  clientType,
  getSelectedData,
}: selectedDataProps) {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation();

  const [financierClient, setFinancierClient] = useState<Pageable<FinancierClientVOModel[]>>();
  const { register, getValues } = useForm<{ selectedKey: string; selectedValue: string }>({
    mode: 'onSubmit',
  });
  const { pageable, setPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const financierClientList = await requestSystemFinancierClientList(1, 10, {
        [getValues().selectedKey]: getValues().selectedValue,
        financierId,
        financierClientType: clientType,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierClient(financierClientList);
        setPageable(financierClientList);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const requestFinancierClientSearch = async () => {
    await fetchAll();
  };

  const onFinancierClientSelect = (e: any, item: FinancierClientVOModel) => {
    e.preventDefault();
    getSelectedData({ ...item });
    modal.close(modalId);
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    const financierClientList = await requestSystemFinancierClientList(pageNumber, sizePerPage, {
      [getValues().selectedKey]: getValues().selectedValue,
      financierId,
      financierClientType: clientType,
    });

    ReactDOM.unstable_batchedUpdates(() => {
      setFinancierClient(financierClientList);
      setPageable(financierClientList);
    });
  };

  const renderFinancierClientTable = () => {
    if (financierClient && financierClient.content.length > 0) {
      return financierClient?.content.map((value, index) => {
        return (
          <tr key={index}>
            <td>{value.financierClientCode}</td>
            <td>{value.financierClientName}</td>
            <td>{value.representativeName}</td>
            <td>{value.financierClientAddress}</td>
            <td className="information-table-more">
              <Button
                onClick={e => {
                  onFinancierClientSelect(e, value);
                }}
              >
                {t('text:Select')}
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr key={0}>
          <td colSpan={5} className="text-center">
            {t('text:No_data_available')}
          </td>
        </tr>
      );
    }
  };

  return (
    <form data-testid="formId">
      <div className="d-flex information-filter mt-0">
        <Select
          name="selectedKey"
          className="information-form__select"
          selectOptions={getSelectOptions<string>('FINANCIER_CLIENT_LIST_REQUEST', [
            'financierClientCode',
            'financierClientName',
          ])}
          ref={register}
        />
        <input type="text" placeholder="Search..." name="selectedValue" ref={register} />
        <Button onClick={requestFinancierClientSearch}>{t('text:Search')}</Button>
      </div>
      {/* TODO - use component */}
      <table className="table-border">
        <colgroup>
          <col />
          <col />
          <col />
          <col style={{ width: '265px' }} />
          <col style={{ width: '70px' }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">{t('text:Client_Code')}</th>
            <th scope="col">{t('text:Company_Name')}</th>
            <th scope="col">{t('text:Legal_Representative_Name')}</th>
            <th scope="col">{t('text:Registered_Office_Address')}</th>
            <th scope="col" className="table-column-right-fixed" />
          </tr>
        </thead>
        <tbody>{renderFinancierClientTable()}</tbody>
      </table>
      <Pagination pageable={pageable} paginate={paginate} />
    </form>
  );
}
