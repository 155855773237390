import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import Label from 'components/stateless/Form/Label/Label';
import Value from 'components/stateless/Form/Value/Value';
import { TableBorder } from 'components/stateless/Table';
import type { OverdueInterestRateTermSpreadVOModel } from 'models/vo/OverdueInterestRateTermSpreadVO';
import { isNilOrEmptyString } from 'utils/helpers';

interface DelinquentInterestModalProps {
  termSpreads?: OverdueInterestRateTermSpreadVOModel[];
}

const DelinquentInterestModal = ({ termSpreads }: DelinquentInterestModalProps) => {
  const { t } = useTranslation();

  return (
    <div className="modal-container">
      <div className="p-3">
        <TableBorder className="term-spread__table">
          <colgroup>
            <col />
            <col style={{ width: '30%' }} />
          </colgroup>
          <thead className="term-spread__head">
            <tr>
              <th scope="col">
                <Label>{t('text:Terms_Days')}</Label>
              </th>
              <th scope="col">
                <Label className="pe-5">{t('text:Term_Spread')} (%)</Label>
              </th>
            </tr>
          </thead>
          <tbody>
            {termSpreads?.map((item, index) => {
              const { minimumDateRange } = item;
              const isEdgeRow = (index: number) => index === 0 || index === termSpreads?.length - 1;

              return (
                <tr
                  key={`term-spread-row-${index}`}
                  className={clsx({ 'term-spread__tr--background-gray': isEdgeRow(index) })}
                >
                  <td>
                    <div className="term-spread__term-box">
                      <div>
                        <div className="form__input text-center border-none">
                          {isNilOrEmptyString(minimumDateRange)
                            ? '-'
                            : t('format:number', {
                                value: index === 0 ? 0 : minimumDateRange,
                              })}
                        </div>
                      </div>
                      <div className="term-spread__angle-sign">&lt;</div>
                      <div className="form__input text-center border-none">T</div>
                      {termSpreads?.length !== index + 1 && (
                        <>
                          <div className="term-spread__angle-sign">≤</div>
                          <div>
                            {/*flex 속성 걸려있음 */}
                            <div>
                              <Value
                                className="form__input text-center"
                                value={termSpreads[index + 1].minimumDateRange}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="flex-end pe-5">
                      <Value className="form__input text-right" value={item.termSpreadRate} />
                      <span className="unit">%</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableBorder>
      </div>
    </div>
  );
};

export default DelinquentInterestModal;
