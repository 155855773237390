import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import ClipboardWriteButton from 'components/stateless/Button/ClipboardWriteButton';
import Form from 'components/stateless/Form';
import { FormErrorMessage } from 'components/stateless/Form/ErrorMessage';
import { TableBody, TableBorder, Td, Tr } from 'components/stateless/Table';
import TableHeader from 'components/stateless/Table/TableHeader';
import { Border, Content, Row, SubTitle } from 'components/templates/section';
import { ColorMap } from 'enums/colorMap';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';

import useAnchorCompanyController from './useAnchorCompanyController';

const AnchorCompany = () => {
  const { t } = useTranslation(['format']);

  const {
    register,
    errors,
    isAr,
    isOnlySupportVendorFinancing,
    isSearchAnchorButtonVisible,
    isMoreAnchorUserInfoVisible,
    isSearchAnchorUserButtonVisible,
    isClipboardWriteButtonVisible,
    isClipboardWriteButtonDisabled,
    isSendButtonDisabled,
    useAgreementInterface,
    canChangeDivision,
    anchorClientInfo: {
      anchorClientCode,
      anchorClientName,
      anchorUserList,
      anchorClientAutoSignUpForAnchorAdminOfVendorFinance,
    },
    divisionRegistrable,
    divisionName,
    divisionSelectOptions,
    ANCHOR_ADMIN_TABLE_HEADERS,
    handleSearchAnchorButtonClick,
    handleSearchAnchorUserClick,
    handleDivisionSelectChange,
    handleClipboardButtonClick,
    handleAnchorUserInviteButtonClick,
  } = useAnchorCompanyController();

  return (
    <>
      <SubTitle>{t('text:ANCHOR_COMPANY')}</SubTitle>
      <Content>
        <Row singleColumnPosition={isSearchAnchorButtonVisible ? 'bottom' : 'all'}>
          <Form.Label required={isSearchAnchorButtonVisible}>{t('text:Anchor_Client')}</Form.Label>
          {isSearchAnchorButtonVisible && (
            <div className="flex-align-center">
              <Button className="me-2" size={ButtonSizeEnum.SM} bold onClick={handleSearchAnchorButtonClick}>
                {t('text:Search_Anchor')}
              </Button>
              {errors?.anchorClientId && <FormErrorMessage error={errors.anchorClientId} />}
            </div>
          )}

          <Border>
            <Content isInnerStyle>
              <input name="anchorClientId" type="hidden" ref={register} />
              {/* divisionRegistrable이 false인 경우에도 division이 있는 경우가 있음, 그 경우 division 값을 보내지 않으면 수정 요청으로 간주되어 에러가 발생함.  */}
              {!divisionRegistrable && <input type="hidden" name="division" ref={register} />}
              <Row>
                <Form.Control name="anchorClientCode">
                  <Form.Label>{t('text:Client_Code')}</Form.Label>
                  <Form.Value value={anchorClientCode} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control name="anchorClientName">
                  <Form.Label>{t('text:Anchor_Name')}</Form.Label>
                  <Form.Value value={anchorClientName} />
                </Form.Control>
              </Row>
              {divisionRegistrable && (
                <Row>
                  <Form.Control name="division">
                    <Form.Label>{t('text:Division_Code')}</Form.Label>
                    {canChangeDivision ? (
                      <Form.Select
                        selectOptions={divisionSelectOptions}
                        onChange={handleDivisionSelectChange}
                        placeholderOptions={{ show: true }}
                      />
                    ) : (
                      <Form.Input readOnly />
                    )}
                  </Form.Control>
                </Row>
              )}
              {divisionRegistrable && (
                <Row>
                  <Form.Control>
                    <Form.Label>{t('text:Division_Name')}</Form.Label>
                    <Form.Value value={divisionName} />
                  </Form.Control>
                </Row>
              )}
              {isAr && isOnlySupportVendorFinancing && !useAgreementInterface && (
                <Row>
                  <Form.Control>
                    <Form.Label>{t('text:VFS_without_Anchor_User_Invitation')}</Form.Label>
                    <Form.Value
                      value={
                        isNil(anchorClientAutoSignUpForAnchorAdminOfVendorFinance)
                          ? '-'
                          : anchorClientAutoSignUpForAnchorAdminOfVendorFinance
                          ? t('text:Yes')
                          : t('text:No')
                      }
                    />
                  </Form.Control>
                </Row>
              )}
            </Content>
          </Border>
        </Row>
        <Content>
          <input name="bankUserIdList" type="hidden" ref={register} />
          <Row singleColumnPosition={isSearchAnchorUserButtonVisible ? 'bottom' : 'all'}>
            <Form.Label required={isSearchAnchorUserButtonVisible}>{t('text:Anchor_Admin_User')}</Form.Label>
            {isSearchAnchorUserButtonVisible && (
              <div>
                <Button size={ButtonSizeEnum.SM} bold onClick={handleSearchAnchorUserClick}>
                  {t('text:Search_Anchor_User')}
                </Button>
              </div>
            )}

            <TableBorder>
              <TableHeader header={ANCHOR_ADMIN_TABLE_HEADERS} />
              <TableBody numOfCol={ANCHOR_ADMIN_TABLE_HEADERS.length}>
                {anchorUserList?.map(anchorUser => {
                  return (
                    <Tr key={anchorUser.bankUserId}>
                      <Td data={anchorUser.bankUserId} />
                      <Td data={anchorUser.name} />
                      <Td data={anchorUser.mobile} />
                      <Td data={anchorUser.email} />
                      <Td data={anchorUser.anchorUserOtpSerialNo} />
                      <Td data={t(`code:authority-type.${anchorUser.authorityType}`)} />
                      {isMoreAnchorUserInfoVisible && (
                        <>
                          <Td
                            className={getStatusTextClass('USER_STATUS', anchorUser?.userStatus)}
                            data={t(`code:user-status.${anchorUser.userStatus}`)}
                            format="code"
                          />
                          <Td className="information-table-more">
                            {!anchorClientAutoSignUpForAnchorAdminOfVendorFinance && (
                              <>
                                {isClipboardWriteButtonVisible(anchorUser.userStatus) && (
                                  <ClipboardWriteButton
                                    id={String(anchorUser.id)}
                                    onClickClipboardWriteButton={() => handleClipboardButtonClick(anchorUser.id)}
                                    toolTipBgColor={ColorMap.black}
                                    style={{ backgroundColor: ColorMap.white }}
                                    disabled={isClipboardWriteButtonDisabled(anchorUser.id)}
                                    toolTipText={t('text:Copy_Sign_up_link')}
                                  />
                                )}
                                <Button
                                  bold
                                  onClick={() => handleAnchorUserInviteButtonClick(anchorUser.id)}
                                  disabled={isSendButtonDisabled(anchorUser.userStatus)}
                                >
                                  {t('text:Send')}
                                </Button>
                              </>
                            )}
                          </Td>
                        </>
                      )}
                    </Tr>
                  );
                })}
              </TableBody>
            </TableBorder>
          </Row>
        </Content>
      </Content>
    </>
  );
};

export default AnchorCompany;
