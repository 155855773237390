import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface ArSettlementDetailVO {
  financierName: string;
  settlementDate: string;
  daysToMaturity: number;
  currencyType: string;
  anchorName: string;
  totalArCount: number;
  totalArAmount: number;
  arRequestedCount: number;
  arRequestedAmount: number;
  arFailedCount: number;
  arFailedAmount: number;
  arSettledCount: number;
  arSettledAmount: number;
  arUnSettledCount: number;
  arUnSettledAmount: number;
  financierId: number;
}

export interface ArSettlementDetailVOModel extends ArSettlementDetailVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToArSettlementDetailVOModel(data: ArSettlementDetailVO): ArSettlementDetailVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
