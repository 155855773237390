import type Pageable from 'models/Pageable';
import type { LoginHistoryVO, LoginHistoryVOModel } from 'models/vo/LoginHistoryVO';
import { formattingToLoginHistoryVOModel } from 'models/vo/LoginHistoryVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SyLoginHistoryListRequest } from './request';

export async function requestSystemLoginHistoryList(
  pageNumber: number,
  rowCount: number,
  data?: SyLoginHistoryListRequest,
): Promise<Pageable<LoginHistoryVOModel[]>> {
  const response = await http.get<Pageable<LoginHistoryVO[]>>({
    url: API_SY.LOGIN_HISTORY.LOGIN_HISTORY_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return { ...response, content: response.content.map(data => formattingToLoginHistoryVOModel(data)) };
}
