import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty, isNil } from 'lodash-es';

import { ANCHOR_AGREEMENT_STATUS, AUTHORITY_TYPE, BASIS_INTEREST_TYPE, COLLATERAL_TYPE } from 'enums';
import { AgreementExceptionCode } from 'enums/exception';
import type { SignInModel } from 'models/SignInModel';
import { convertToAnchorFinancingOptionResetData } from 'models/convertor/anchorFinancingOption';
import { formErrorHandler } from 'utils/error/manager';
import { requestRegisterFinancierWaitingAnchorFinancingOption } from 'utils/http/api/financier/waiting-anchor-financing-options';
import type { CreateFiWaitingAnchorFinancingOptionDTO } from 'utils/http/api/financier/waiting-anchor-financing-options/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser, requestDTOParserForArrayObj } from 'utils/valueManager/ValueManager';

import useExtraInformationViewModel from '../models/extraInformation/useExtraInformationViewModel';
import useAnchorAgreementInfoViewModel from '../models/financingOption/useAnchorAgreementInfoViewModel';
import useFinancingOptionViewModel from '../models/financingOption/useFinancingOptionViewModel';
import useTermSpreadViewModel from '../models/financingOption/useTermSpreadViewModel';

const useRegistrationDetailController = () => {
  const [isEditable, setIsEditable] = useState(false);
  const { termSpreadList } = useTermSpreadViewModel();
  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();

  const { supportedCollateralType, errorHandlerOfLocationState, anchorFinancingOptionId } =
    useExtraInformationViewModel();
  const { anchorFinancingOption, fetchFinancingOptionDetail, updateAllFinancingOption } = useFinancingOptionViewModel();
  const { fetchAnchorAgreementDetail, updateAnchorAgreementDetail } = useAnchorAgreementInfoViewModel();

  const [isAgreementTab, setIsAgreementTab] = useState<boolean>(true);

  const methods = useForm<CreateFiWaitingAnchorFinancingOptionDTO>();
  const { reset, getValues, setError, clearErrors } = methods;

  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const isUserEditable =
    signInModel?.branchId === anchorFinancingOption?.branchId && signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR;

  const isEditButtonVisible = isUserEditable && isAgreementTab;

  const resetPage = async () => {
    try {
      if (isNil(anchorFinancingOptionId) || errorHandlerOfLocationState()) return;

      const financingOptionDetail = await fetchFinancingOptionDetail(anchorFinancingOptionId);
      const anchorAgreementDetail = await fetchAnchorAgreementDetail(financingOptionDetail?.anchorAgreementId!);

      if (financingOptionDetail) {
        if (financingOptionDetail.basisInterestBankCode === BASIS_INTEREST_TYPE.FIXED)
          financingOptionDetail.basisInterestBankCode = '';
        reset(convertToAnchorFinancingOptionResetData(financingOptionDetail));
        setIsEditable(false);
        updateAllFinancingOption(financingOptionDetail);
      }
      if (anchorAgreementDetail) updateAnchorAgreementDetail(anchorAgreementDetail);
    } catch (error) {
      showModal(error);
    }
  };

  // Tab Button
  const handleRevisionHistoryTabClick = () => setIsAgreementTab(false);
  const handleAgreementTabClick = () => setIsAgreementTab(true);

  // Cancel Button
  const handleCancelButtonClick = () => {
    showModal(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_agreement?')}
        <br />
        {t('text:If_the_modification_is_stopped_the_entered_content_will_not_be_saved')}.
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: resetPage,
      },
    );
  };

  // TODO Error 처리, BE와 논의
  const handleUpdateAgreementError = (error: any) => {
    showModal(error);

    const isInvalidError = !isEmpty(
      formErrorHandler<CreateFiWaitingAnchorFinancingOptionDTO>(error, setError, clearErrors),
    );

    if (isInvalidError) return;

    const getMonthlyInterestRepaymentDateErrorMessage = () => {
      const monthlyInterestRepaymentDate = getValues('monthlyInterestRepaymentDate');
      if (isNil(monthlyInterestRepaymentDate) || Number(monthlyInterestRepaymentDate) === 0)
        return t('text:0_and_blank_are_not_allowed');
      else return t('text:Date_must_be_between_1_and_31');
    };

    switch (error.code) {
      case AgreementExceptionCode.INVALID_BASIS_INTEREST_RATE:
        return setError('basisInterestRate', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_LOAN_TERM:
        return setError('loanTermUnit', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.OMITTED_ANCHOR_AGREEMENT_INFO:
        return setError('anchorAgreementId', { shouldFocus: true });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_INTEREST_REPAYMENT_TYPE:
        return setError('monthlyInterestRepaymentDate', {
          shouldFocus: true,
          message: getMonthlyInterestRepaymentDateErrorMessage(),
        });
      default:
        return;
    }
  };

  const updateAgreement = async () => {
    const data = getValues();

    data.collateralType = supportedCollateralType;
    data.termSpreadList = data.termSpreadList ?? termSpreadList;
    data.targetAnchorFinancingOptionId = anchorFinancingOptionId;

    if (data.basisInterestType === BASIS_INTEREST_TYPE.FIXED) data.basisInterestBankCode = BASIS_INTEREST_TYPE.FIXED;
    if (supportedCollateralType === COLLATERAL_TYPE.INVOICE) data.anchorAccount = 'true';

    requestDTOParser(data);
    requestDTOParserForArrayObj(data.termSpreadList);
    await requestRegisterFinancierWaitingAnchorFinancingOption(data);
  };

  const showRequestChangeCompletedModal = () => {
    showModal(
      <h6>
        {t('text:Request_for_modification_approval_has_been_completed')}
        <br />
        {t('text:Modification_will_be_completed_after_approval_by_the_Authorizer')}
      </h6>,
      {
        closeBtnText: t('text:OK'),
        closeBtnCb: resetPage,
      },
    );
  };

  const handleRequestChangeButtonClick = () => {
    showModal(<h6>{t('text:Would_you_like_to_request_approval_for_modification?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnText: t('text:Confirm'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await updateAgreement();
          showRequestChangeCompletedModal();
        } catch (e) {
          handleUpdateAgreementError(e);
        }
      },
    });
  };

  // Edit Button
  const handleEditButtonClick = () => setIsEditable(true);

  useEffect(() => {
    resetPage();
  }, []);

  const GuideMessageText = (() => {
    const isShowGuideMessage = anchorFinancingOption.anchorAgreementStatus === ANCHOR_AGREEMENT_STATUS.SUSPENDED;

    if (isShowGuideMessage) {
      const fixedMessage = t('text:The_financing_option_and_all_subsequent_transactions_have_been_suspended');

      return isUserEditable
        ? [
            fixedMessage,
            t(
              'text:To_allow_transactions_to_resume_modify_the_Option_Activation_field_in_the_General_Information_section',
            ),
          ]
        : [fixedMessage];
    }

    return [];
  })();

  return {
    supportedCollateralType,
    isAr,
    isEditable,
    isEditButtonVisible,
    methods,
    handleCancelButtonClick,
    handleRequestChangeButtonClick,
    handleEditButtonClick,
    handleRevisionHistoryTabClick,
    handleAgreementTabClick,
    GuideMessageText,
  };
};

export default useRegistrationDetailController;
