import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { faArrowRight, faDisplay, faFileArrowDown, faFileCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNull } from 'lodash-es';

import PPCard from 'components/potential-partner/PPCard/PPCard';
import {
  PPTextFieldItem,
  PPTextFieldLabel,
  PPTextFieldValue,
  PPTextStatusField,
} from 'components/potential-partner/PPTextField/PPTextField';
import Accordion from 'components/stateless/Accordion/Accordion';
import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { ROUTES_PP } from 'constants/routes/potentialPartner';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import type { SuccessArGroupAnchorPartnerAccountVOModel } from 'models/vo/SuccessArGroupAnchorPartnerAccountVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestAnFinancierPotentialPartnerAcquisitionDocumentDownload } from 'utils/http/api/anonymous/potential-partner-acquisition-documents';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';

import './PotentialPartnerHomeArListTable.scss';

import type { apAmountListByAnchorPartnerAccountTypes } from '../../usePotentialPartnerHomeState';

interface PotentialPartnerHomeArListTableProps {
  apAmountListByAnchorPartnerAccount: apAmountListByAnchorPartnerAccountTypes[];
  fetchAcquisitionSetting(financierId: number, anchorPartnerAccountId: number): Promise<void>;
}

function PotentialPartnerHomeArListTable({
  apAmountListByAnchorPartnerAccount,
  fetchAcquisitionSetting,
}: PotentialPartnerHomeArListTableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const emailCode = getPotentialPartnerEmailCode();
  const modal = useModal();

  const [indexOfFetchedAcquisitionSetting, setIndexOfFetchedAcquisitionSetting] = useState<number[]>([0]);

  const onClickMoveButton = (successArList: SuccessArGroupAnchorPartnerAccountVOModel) => {
    history.push(ROUTES_PP.AR_DETAIL_BUILD_PATH(successArList.anchorPartnerAccountId), {
      potentialPartnerFinancingApplicationId: successArList.potentialPartnerFinancingApplicationId,
      potentialPartnerFinancingApplicationStatus: successArList.potentialPartnerFinancingApplicationStatus,
    });
  };

  const goToFinancierDetail = (financierId: number) => {
    history.push(ROUTES_PP.MY_INFO.FINANCIER_DETAIL_BUILD_PATH(financierId));
  };

  const renderPPFinancingApplicationStatusText = (status: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS | null) => {
    switch (status) {
      case null:
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL:
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED:
        return t('code:potential-partner-financing-application-status.REJECTED');
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED:
        return t('code:potential-partner-financing-application-status.SUBMITTED');
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS:
        return t('code:potential-partner-financing-application-status.IN_PROGRESS');
    }
  };

  const DocumentAccordion = ({
    index,
    financierId,
    documentList,
    anchorPartnerAccountId,
  }: {
    index: number;
    financierId: number;
    documentList: PotentialPartnerAcquisitionDocumentVOModel[] | undefined;
    anchorPartnerAccountId: number;
  }) => {
    const onClickAccordionSection = async (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
      const foundIsAlreadyInArray: boolean = indexOfFetchedAcquisitionSetting.includes(index);

      if (foundIsAlreadyInArray) {
        return;
      } else {
        await fetchAcquisitionSetting(financierId, anchorPartnerAccountId);
        setIndexOfFetchedAcquisitionSetting(prev => [...prev, index]);
      }
    };

    const onClickDownloadTemplates = async (potentialPartnerAcquisitionDocumentSettingId: number) => {
      if (!emailCode) return modal.show('not found email code');
      try {
        await requestAnFinancierPotentialPartnerAcquisitionDocumentDownload(
          potentialPartnerAcquisitionDocumentSettingId,
          emailCode,
        );
      } catch (e) {
        modal.show(e);
      }
    };

    return (
      <React.Fragment key={index}>
        <Accordion
          id={`template-accordion-${index}`}
          className="pp-buyer-lists__accordion accordion"
          defaultExpanded={indexOfFetchedAcquisitionSetting.includes(index)}
        >
          <Accordion.Header id={`template-heading-${index}`}>
            <Accordion.Trigger
              onClick={e => onClickAccordionSection(e, index)}
              className={`accordion-button ${indexOfFetchedAcquisitionSetting.includes(index) ? '' : 'collapsed'}`}
            >
              {t('text:What_should_I_prepare_in_advance?')}
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <div className="pp-accordion__content">
              {documentList?.length && documentList?.length > 0 ? (
                <>
                  <SectionMessage
                    message={[
                      t('text:Please_prepare_these_documents_and_attach_them_to_your_application_when_submitting'),
                    ]}
                  />
                  <div className="pp-accordion__card">
                    {documentList?.map((documentItem, documentItemIdx) => {
                      return (
                        <div className="pp-accordion__card__item" key={documentItemIdx}>
                          <div>
                            <div className="pp-accordion__card__label">{documentItem.documentName}</div>
                            <div className="pp-accordion__card__value">{documentItem.description}</div>
                          </div>
                          {!isNull(documentItem.attachmentId) && (
                            <div className="pp-accordion__card__download-btn">
                              <Button2
                                size={Button2SizeEnum.SM}
                                onClick={() =>
                                  onClickDownloadTemplates(documentItem.potentialPartnerAcquisitionDocumentSettingId)
                                }
                                color={Button2ColorEnum.TERTIARY}
                                icon={<FontAwesomeIcon icon={faFileArrowDown} fontSize={20} />}
                              >
                                {t('text:Download_Template')}
                              </Button2>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="pp-accordion__no-data">
                  <FontAwesomeIcon icon={faFileCircleExclamation} />
                  <div className="pp-accordion__no-data__msg">
                    {t('text:There_are_no_documents_required_by_the_financier')}
                  </div>
                </div>
              )}
            </div>
          </Accordion.Content>
        </Accordion>
      </React.Fragment>
    );
  };

  const PPBuyerListRow = ({
    anchorPartnerAccountInfo,
  }: {
    anchorPartnerAccountInfo: SuccessArGroupAnchorPartnerAccountVOModel;
  }) => {
    const ApplicationStatusButton = () => {
      return (
        <div className="pp-buyer-lists__item__application-status-btn-wrapper">
          {anchorPartnerAccountInfo.potentialPartnerFinancingApplicationStatus ===
            POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED ||
          anchorPartnerAccountInfo.potentialPartnerFinancingApplicationStatus ===
            POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS ? (
            <Button2
              size={Button2SizeEnum.LG}
              onClick={() =>
                history.push(
                  ROUTES_PP.MY_INFO.SUBMISSION_DETAIL_BUILD_PATH(
                    anchorPartnerAccountInfo.potentialPartnerFinancingApplicationId,
                  ),
                )
              }
              icon={<FontAwesomeIcon icon={faArrowRight} fontSize={22} />}
              color={Button2ColorEnum.TERTIARY}
            >
              {t('text:Monitor_Progress')}
            </Button2>
          ) : (
            <>
              {/* Submit Application:: PC 버전에서만 보여주기 */}
              <Button2
                size={Button2SizeEnum.LG}
                className="hide-on-mobile"
                onClick={() =>
                  history.push(ROUTES_PP.APPLICATION.STEP, {
                    anchorClientName: anchorPartnerAccountInfo.anchorClientName,
                    financierName: anchorPartnerAccountInfo.financierName,
                    financierId: anchorPartnerAccountInfo.financierId,
                    anchorPartnerId: anchorPartnerAccountInfo.anchorPartnerId,
                    anchorPartnerAccountId: anchorPartnerAccountInfo.anchorPartnerAccountId,
                    currencyType: anchorPartnerAccountInfo.currencyType,
                  })
                }
                icon={<FontAwesomeIcon icon={faArrowRight} fontSize={22} />}
              >
                {t('text:Submit_Application')}
              </Button2>
              <Button2
                size={Button2SizeEnum.LG}
                className="show-on-mobile"
                icon={<FontAwesomeIcon icon={faDisplay} fontSize={20} />}
                color={Button2ColorEnum.PRIMARY}
                disabled
              >
                {t('text:Submit_an_application_from_a_desktop')}
              </Button2>
            </>
          )}
        </div>
      );
    };

    return (
      <div className="pp-buyer-lists__row">
        <PPCard>
          <div className="pp-row">
            <PPTextFieldItem>
              <PPTextFieldLabel label={t('text:Buyer_Name')} />
              <PPTextFieldValue value={anchorPartnerAccountInfo.anchorClientName} />
            </PPTextFieldItem>
            <PPTextFieldItem>
              <PPTextFieldLabel label={t('text:Financier_Name')} />
              <PPTextFieldValue
                value={anchorPartnerAccountInfo.financierName}
                className="text-underline cursor-pointer"
                onClick={() => goToFinancierDetail(anchorPartnerAccountInfo.financierId)}
                role="button"
              />
            </PPTextFieldItem>
            <PPTextFieldItem>
              <div className="pp-buyer-lists__item--financing-available">
                <PPTextFieldLabel label={t('text:Financing_Available_for_AR(s)')} />
                <PPTextStatusField>
                  <div className="pp-buyer-lists__item--financing-available-amount">
                    <span className="pp-buyer-lists__item--currency">{anchorPartnerAccountInfo.currencyType}</span>
                    <span className="pp-buyer-lists__item--total-amount">
                      {t('format:number', { value: anchorPartnerAccountInfo.ltvTotalArAmount })}
                    </span>
                  </div>
                </PPTextStatusField>
                <div className="pp-buyer-lists__item__ltv-button__wrapper">
                  <div className="pp-buyer-lists__item--ltv">
                    <div>
                      <span className="text-bold">{anchorPartnerAccountInfo.totalArCount}</span>
                      <span> {t('text:AR(s)_included')} </span>
                      <span className="hide-on-mobile">&nbsp;|&nbsp;</span>
                    </div>
                    <div>
                      <span className="text-bold">{anchorPartnerAccountInfo.maxLtvRatio}%</span> LTV
                    </div>
                  </div>

                  <div className="pp-buyer-lists__item__view-ar-btn-wrapper">
                    <Button2
                      size={Button2SizeEnum.LG}
                      color={Button2ColorEnum.TERTIARY}
                      onClick={() => onClickMoveButton(anchorPartnerAccountInfo)}
                    >
                      {t('text:View_AR(s)_Details')}
                    </Button2>
                  </div>
                </div>
              </div>
            </PPTextFieldItem>
            <PPTextFieldItem>
              <PPTextFieldLabel label={t('text:Application_Status')} />
              <PPTextStatusField>
                <div
                  className={
                    'pp-buyer-lists__item--status ' +
                    getStatusTextClass(
                      'POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS',
                      anchorPartnerAccountInfo.potentialPartnerFinancingApplicationStatus,
                    )
                  }
                >
                  {renderPPFinancingApplicationStatusText(
                    anchorPartnerAccountInfo.potentialPartnerFinancingApplicationStatus,
                  )}
                </div>
              </PPTextStatusField>

              <ApplicationStatusButton />
            </PPTextFieldItem>
          </div>
        </PPCard>
      </div>
    );
  };

  return (
    <div className="pp-buyer-lists">
      <div className="pp-title">{t('text:Review_your_AR_(s)_and_financing_opportunities_by_buyer')}</div>
      <div>
        {apAmountListByAnchorPartnerAccount.map((item, index) => {
          return (
            <div key={index} className="pp-buyer-lists__row-wrapper">
              <PPBuyerListRow anchorPartnerAccountInfo={item.anchorPartnerAccountInfo} />
              <DocumentAccordion
                index={index}
                financierId={item.anchorPartnerAccountInfo.financierId}
                documentList={item.acquisitionDocumentsList}
                anchorPartnerAccountId={item.anchorPartnerAccountInfo.anchorPartnerAccountId}
              />
            </div>
          );
        })}
      </div>
      <div>
        <p className="pp-platform-link__title">{t('text:Are_you_already_an_active_user_on_the_Fin2B_SCF_Platform?')}</p>
        <p className="pp-platform-link__desc">
          {t('text:If_your_company_has_an_agreement_already,')}{' '}
          <a className="show-on-mobile" href="#">
            {t('text:go_to_the_Fin2B_SCF_Platform_to_receive_financing')}
          </a>
          <Link className="hide-on-mobile" to={{ pathname: process.env.REACT_APP_URL }} target="_blank">
            {t('text:go_to_the_Fin2B_SCF_Platform_to_receive_financing')}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default PotentialPartnerHomeArListTable;
