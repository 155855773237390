import Big from 'big.js';
import i18n from 'i18next';
import { isNil } from 'lodash-es';

import { languageType } from 'enums';
import { REG_EXPONENTIAL_NOTATION, REG_NUMBER } from 'utils/validation/regExp';

import { formattingENLanguage } from './en';
import { formattingENIndiaLanguage } from './en-india';
import { formattingVNLanguage } from './vn';

export const localeNumberFormatter = (value: number | string | undefined) => {
  if (isNil(value)) return '-';

  if (typeof value === 'number') {
    if (isNaN(value)) return '-';
    else value = value?.toString();
  }

  if (REG_EXPONENTIAL_NOTATION.test(value)) return value;
  if (!REG_NUMBER.test(value)) return '-';

  const dotIdx = value.indexOf('.');

  value = dotIdx === -1 ? value : Big(value)?.toFixed(4, 0).replace(/0+$/, '');
  if (value.slice(-1) === '.') value = value.slice(0, -1);

  let returnValue: string = '';

  switch (i18n.language) {
    case languageType.EN_USA:
      returnValue = formattingENLanguage(value);
      break;
    case languageType.EN_INDIA:
      returnValue = formattingENIndiaLanguage(value);
      break;
    case languageType.VN:
      returnValue = formattingVNLanguage(value);
      break;
    default:
      returnValue = value;
  }

  return returnValue;
};
