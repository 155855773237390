import { useTranslation } from 'react-i18next';

import type { SidebarMenuInfoType } from 'components/stateless/Navigation/Sidebar/useSidebarController';
import { ROUTES_DE } from 'constants/routes/dealer';
import { SUPPORTED_COLLATERAL_TYPE } from 'enums';
import {
  getCollateralType as getCollateralTypeFromSessionStorage,
  getFactoringEnable,
} from 'utils/storage/LocalStorage';

export function deMenuInfo() {
  const { t } = useTranslation(['format']);
  const collateralType = getCollateralTypeFromSessionStorage();

  const isInvoice = collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE;
  const isAr = collateralType === SUPPORTED_COLLATERAL_TYPE.AR;
  const isFactoringEnable = getFactoringEnable();

  const menuInfo: Array<SidebarMenuInfoType> = [
    {
      mainMenu: { title: t('text:Home'), path: ROUTES_DE.DASHBOARD },
    },
    {
      mainMenu: {
        title: t('text:Register_Invoices'),
        hidden: isAr,
      },
      subMenu: [
        { title: t('text:Registration'), path: ROUTES_DE.REGISTER_INVOICE.REGISTRATION_STEP1 },
        { title: t('text:Confirmation'), path: ROUTES_DE.REGISTER_INVOICE.CONFIRMATION_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Register_AR'),
        hidden: isInvoice,
      },
      subMenu: [
        { title: t('text:Registration'), path: ROUTES_DE.REGISTER_AR.REGISTRATION_STEP1 },
        { title: t('text:Confirmation'), path: ROUTES_DE.REGISTER_AR.CONFIRMATION_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Manage_Financing'),
      },
      subMenu: [
        { title: t('text:Application'), path: ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1 },
        { title: t('text:Confirmation'), path: ROUTES_DE.MANAGE_FINANCING.CONFIRMATION_LIST },
        {
          title: t('text:Bulk_Confirmation'),
          path: ROUTES_DE.MANAGE_FINANCING.BULK_CONFIRMATION_LIST,
          hidden: !isFactoringEnable,
        },
        {
          title: t('text:Request_for_Early_Repayment'),
          path: ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EARLY_REPAYMENT_LIST,
        },
        {
          title: t('text:Request_for_Extension'),
          path: ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EXTENSION_LIST,
          hidden: isAr,
        },
        {
          title: t('text:Secured_Inventory_Update'),
          path: ROUTES_DE.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_LIST,
          hidden: isAr,
        },
      ],
    },
    {
      mainMenu: {
        title: t('text:View_Transactions'),
      },
      subMenu: [
        { title: t('text:Invoice'), path: ROUTES_DE.VIEW_TRANSACTION.INVOICE_LIST, hidden: isAr },
        { title: t('text:AR'), path: ROUTES_DE.VIEW_TRANSACTION.AR_LIST, hidden: isInvoice },
        {
          title: t('text:Financing'),
          path: ROUTES_DE.VIEW_TRANSACTION.FINANCING_LIST,
        },
      ],
    },
    {
      mainMenu: {
        title: t('text:Company'),
      },
      subMenu: [
        { title: t('text:User'), path: ROUTES_DE.COMPANY.USER_LIST },
        { title: t('text:Agreement'), path: ROUTES_DE.COMPANY.AGREEMENT_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:My_Account'),
        path: ROUTES_DE.MY_ACCOUNT.ACCOUNT_SETTINGS,
      },
    },
  ];

  return { menuInfo };
}
