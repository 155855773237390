import type { COLLATERAL_TYPE } from 'enums';
import type {
  TempWaitingAnchorAgreementVO,
  TempWaitingAnchorAgreementVOModel,
} from 'models/vo/TempWaitingAnchorAgreementVO';
import { formattingToTempWaitingAnchorAgreementVOModel } from 'models/vo/TempWaitingAnchorAgreementVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { CreateFiTempWaitingAnchorAgreementDTO } from './request';

export const requestFiTempWaitingAnchorAgreementRegister = async (
  data: CreateFiTempWaitingAnchorAgreementDTO,
): Promise<TempWaitingAnchorAgreementVOModel> => {
  const response = await http.post<TempWaitingAnchorAgreementVO>({
    url: API_FI.TEMP_WAITING_ANCHOR_AGREEMENTS.TEMP_WAITING_ANCHOR_AGREEMENTS,
    data,
  });

  return formattingToTempWaitingAnchorAgreementVOModel(response);
};

export const requestFiTempWaitingAnchorAgreementDetail = async (
  collateralType: COLLATERAL_TYPE,
): Promise<TempWaitingAnchorAgreementVOModel> => {
  const response = await http.get<TempWaitingAnchorAgreementVO>({
    url: API_FI.TEMP_WAITING_ANCHOR_AGREEMENTS.TEMP_WAITING_ANCHOR_AGREEMENTS,
    data: { collateralType },
  });

  return response ? formattingToTempWaitingAnchorAgreementVOModel(response) : response;
};
