export const getBlockedStatusClassName = (blocked: boolean | undefined) => {
  switch (blocked) {
    case true: {
      return 'text-bold-brick-red';
    }
    case false: {
      return 'text-bold-blue-main600';
    }
  }
};
