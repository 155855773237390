import { formattingToArCommissionSpreadVOModel } from './ArCommissionSpreadVO';

import type { ArCommissionSettingVO, ArCommissionSettingVOModel } from './ArCommissionSettingVO';
import type { ArCommissionSpreadVO, ArCommissionSpreadVOModel } from './ArCommissionSpreadVO';

export interface ArCommissionSettingDetailVO extends ArCommissionSettingVO {
  commissionSpreads: ArCommissionSpreadVO[];
}

export interface ArCommissionSettingDetailVOModel extends ArCommissionSettingVOModel {
  commissionSpreads: ArCommissionSpreadVOModel[];
}

export function formattingToArCommissionSettingDetailVOModel(
  data: ArCommissionSettingDetailVO,
): ArCommissionSettingDetailVOModel {
  const { commissionSpreads } = data;

  return {
    ...data,
    commissionSpreads: commissionSpreads
      ? commissionSpreads.map(commissionSpread => formattingToArCommissionSpreadVOModel(commissionSpread))
      : commissionSpreads,
  };
}
