import useAnchorAgreementInfoViewModel from 'pages/financier/manage-partner/agreement/models/agreement/useAnchorAgreementInfoViewModel';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useFinancierSettingViewModel from '../../../models/financierSetting/useFinancierSettingViewModel';

const useRegistrationDetailAssociatedAnchorMasterAgreementController = () => {
  const { agreement } = useAgreementViewModel();
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();
  const { divisionRegistrable, potentialPartnerRegistrable } = useFinancierSettingViewModel();

  return {
    agreement,
    anchorAgreementInfo,
    divisionRegistrable,
    potentialPartnerRegistrable,
  };
};

export default useRegistrationDetailAssociatedAnchorMasterAgreementController;
