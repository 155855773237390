import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { uniqBy } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import { requestFinancierAnchorUserInviteUrl, requestInviteAnchorUser } from 'utils/http/api/financier/anchor-users';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorClientInfoViewModel from '../../../models/agreement/useAnchorClientInfoViewModel';
import useDivisionsViewModel from '../../../models/divisions/useDivisionsViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

type InviteUrlType = {
  id: number;
  inviteUrl: string | null;
};

const useRegistrationDetailAnchorCompanyController = () => {
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();
  const authorityType = getSignIn()?.authorityType;

  const {
    agreement: { anchorAgreementId },
    fetchAgreementDetail,
  } = useAgreementViewModel();
  const { anchorClientInfo, updateAnchorUserList } = useAnchorClientInfoViewModel();
  const { useAnchorUserInterface } = useExtraInformationViewModel();
  const { divisionRegistrable, divisionName } = useDivisionsViewModel();

  const { isEditable } = useFormContext();

  const [inviteUrlList, setInviteUrlList] = useState<InviteUrlType[]>([]);

  const isMoreAnchorUserInfoVisible = !isEditable && authorityType !== AUTHORITY_TYPE.ADMIN;
  const isSearchAnchorUserButtonVisible = isEditable && !useAnchorUserInterface;

  const isClipboardWriteButtonVisible = (anchorUserStatus: keyof typeof USER_STATUS) => {
    return anchorUserStatus === USER_STATUS.REGISTERED || anchorUserStatus === USER_STATUS.INVITED;
  };

  const isClipboardWriteButtonDisabled = (anchorUserId: number) => {
    return inviteUrlList?.find(inviteUrl => inviteUrl.id === anchorUserId)?.inviteUrl === null;
  };

  const isSendButtonDisabled = (anchorUserStatus: keyof typeof USER_STATUS) => {
    return anchorUserStatus === USER_STATUS.ACTIVATED || anchorUserStatus === USER_STATUS.SUSPENDED;
  };

  const handleClipboardButtonClick = async (anchorUserId: number) => {
    if (isClipboardWriteButtonDisabled(anchorUserId)) return null;

    try {
      const inviteUrl = await requestFinancierAnchorUserInviteUrl(anchorUserId);
      if (inviteUrl === null) {
        showModal(t('text:Click_on_the_Send_button_before_copying_the_invitation_email_link'));

        return null;
      }

      const updatedInviteUrlList = uniqBy([{ id: anchorUserId, inviteUrl }, ...inviteUrlList], 'id');
      setInviteUrlList(updatedInviteUrlList);

      return inviteUrl;
    } catch (error) {
      showModal(error);

      return null;
    }
  };

  const handleAnchorUserInviteButtonClick = async (anchorUserId: number) => {
    const updateInviteUrlList = (anchorUserId: number) => {
      const updatedInviteUrlList = inviteUrlList?.map(inviteUrlItem => {
        if (inviteUrlItem.id !== anchorUserId) return inviteUrlItem;
        else
          return {
            ...inviteUrlItem,
            inviteUrl: '',
          };
      });

      setInviteUrlList(updatedInviteUrlList);
    };

    showModal(<h6>{t('text:Would_you_like_to_send_an_invitation_email')}</h6>, {
      modalSize: ModalSize.NONE,
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestInviteAnchorUser(anchorUserId);

          showModal(<h6>{t('text:An_invitation_Email_is_successfully_sent')}</h6>, {
            modalSize: ModalSize.NONE,
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: async () => {
              const dealerAgreementDetail = await fetchAgreementDetail(anchorAgreementId);
              if (dealerAgreementDetail) {
                updateAnchorUserList(dealerAgreementDetail.anchorUserList);
              }
            },
          });

          updateInviteUrlList(anchorUserId);
        } catch (error: any) {
          showModal(error);
        }
      },
    });
  };

  const ANCHOR_ADMIN_TABLE_HEADERS: HeaderType[] = useMemo(() => {
    const header: HeaderType[] = [
      { headerText: t('text:User_Code') },
      { headerText: t('text:Name') },
      { headerText: t('text:Mobile') },
      { headerText: t('text:Email') },
      { headerText: t('text:OTP_Number') },
      { headerText: t('text:Authority') },
    ];

    if (!isMoreAnchorUserInfoVisible) return header;
    else
      return header.concat([
        {
          headerText: t('text:User_Account_Status'),
          hasStatusDescription: true,
          showStatusDescriptionFunc: () => {
            showModal(
              <StatusDescriptionModal
                statusDescriptionEnum={Object.values(USER_STATUS)}
                statusDescriptionModalType="TEXT"
                statusDescriptionEnumType="USER_STATUS"
              />,
              {
                modalType: ModalType.ALERT,
                modalSize: ModalSize.XL,
                closeBtnText: t('text:Close'),
              },
            );
          },
        },
        { headerText: t('text:Invitation_Email') },
      ]);
  }, [isMoreAnchorUserInfoVisible, showModal, t]);

  return {
    isMoreAnchorUserInfoVisible,
    isSearchAnchorUserButtonVisible,
    isClipboardWriteButtonVisible,
    isClipboardWriteButtonDisabled,
    isSendButtonDisabled,
    anchorClientInfo,
    divisionRegistrable,
    divisionName,
    ANCHOR_ADMIN_TABLE_HEADERS,
    handleClipboardButtonClick,
    handleAnchorUserInviteButtonClick,
  };
};

export default useRegistrationDetailAnchorCompanyController;
