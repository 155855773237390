import type Pageable from 'models/Pageable';
import type { SupportedCurrencyTypeVO, SupportedCurrencyTypeVOModel } from 'models/vo/SupportedCurrencyTypeVO';
import { formattingToSupportedCurrencyTypeVOModel } from 'models/vo/SupportedCurrencyTypeVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { CreateSySupportedCurrencyTypeRequest, SearchSySupportedCurrencyTypeRequest } from './requests';

export async function requestSystemSupportedCurrencyTypeList(
  pageNumber: number,
  rowCount: number,
  data: SearchSySupportedCurrencyTypeRequest,
): Promise<Pageable<SupportedCurrencyTypeVOModel[]>> {
  const response = await http.get<Pageable<SupportedCurrencyTypeVO[]>>({
    url: API_SY.SUPPORTED_CURRENCY_TYPES.SUPPORTED_CURRENCY_TYPES,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToSupportedCurrencyTypeVOModel(item)),
  };
}

export async function requestSystemRegisterSupportedCurrencyType(
  data: CreateSySupportedCurrencyTypeRequest,
): Promise<void> {
  await http.post({
    url: API_SY.SUPPORTED_CURRENCY_TYPES.SUPPORTED_CURRENCY_TYPES,
    data,
  });
}

export async function requestSystemDeleteSupportedCurrencyType(supportedCurrencyTypeId: number): Promise<void> {
  await http.delete({
    url: API_SY.SUPPORTED_CURRENCY_TYPES.SUPPORTED_CURRENCY_TYPE_DETAIL(supportedCurrencyTypeId),
  });
}
