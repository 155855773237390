import type React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PPSubTitle from 'components/potential-partner/PPSubTitle/PPSubTitle';
import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import useMounted from 'hooks/useMounted';
import { REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING, REG_EMAIL, REG_PHONE } from 'utils/validation/regExp';

import './PotentialPartnerApplicationStep1MyInfoForm.scss';
import {
  usePotentialPartnerApplicationActions,
  usePotentialPartnerApplicationValue,
} from '../../../../PotentialPartnerApplicationProvider';

import type { PotentialPartnerApplicationStep1AnchorPartnerInfoType } from '../../../logics/types';
import type { PotentialPartnerApplicationStep1FormDataType } from '../../PotentialPartnerApplicationStep1';

interface UploadedPartnerApplicationStep1MyInfoFormProps {
  anchorPartnerInfo: PotentialPartnerApplicationStep1AnchorPartnerInfoType;
  confirmCheckboxState: {
    confirmedCheckbox: boolean;
    setConfirmedCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  };
  editableState: {
    editable: boolean;
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

function PotentialPartnerApplicationStep1MyInfoForm({
  editableState,
  confirmCheckboxState,
  anchorPartnerInfo,
}: UploadedPartnerApplicationStep1MyInfoFormProps) {
  const { t } = useTranslation();
  const mounted = useMounted();

  const { confirmedCheckbox, setConfirmedCheckbox } = confirmCheckboxState;
  const { editable, setEditable } = editableState;
  const { register, reset, errors, setError, getValues, clearErrors } =
    useFormContext<PotentialPartnerApplicationStep1FormDataType>();

  const { formData } = usePotentialPartnerApplicationValue();
  const { setFormValues } = usePotentialPartnerApplicationActions();

  useEffect(() => {
    if (mounted) {
      reset({
        ...anchorPartnerInfo,
        partnerName: anchorPartnerInfo.name,
      });
      setFormValues({
        ...anchorPartnerInfo,
        partnerName: anchorPartnerInfo.name,
      });
    }

    return () => {};
  }, [anchorPartnerInfo]);

  const validateMyInfoForm = () => {
    clearErrors();
    let isValid = true;

    const validatePartnerName = () => {
      const { partnerName } = getValues();
      const maxSize = 150;

      if (!partnerName) {
        setError('partnerName', {
          message: 'This_information_is_required',
        });
        isValid = false;
      } else if (partnerName?.length > maxSize) {
        setError('partnerName', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 1, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    const validateTaxCode = () => {
      const { partnerTaxCode } = getValues();
      const maxSize = 20;

      if (!REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING.test(partnerTaxCode)) {
        setError('partnerTaxCode', {
          message: 'The_input_format_is_invalid',
        });
        isValid = false;
      } else if (partnerTaxCode?.length > maxSize) {
        setError('partnerTaxCode', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 0, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    const validateTelephone = () => {
      const { telephone } = getValues();
      const maxSize = 20;

      if (telephone && !REG_PHONE.test(telephone)) {
        setError('telephone', {
          message: 'This_information_must_consist_only_of_numbers_without_any_spaces',
        });
        isValid = false;
      } else if (telephone?.length > maxSize) {
        setError('telephone', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 0, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    const validateEmail = () => {
      const { representativeEmail } = getValues();
      const maxSize = 40;
      if (representativeEmail && !REG_EMAIL.test(representativeEmail)) {
        setError('representativeEmail', {
          message: 'Provide_an_email_in_this_format_example@email_com',
        });
        isValid = false;
      } else if (representativeEmail?.length > maxSize) {
        setError('representativeEmail', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 0, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    const validatePartnerBusinessCode = () => {
      const { partnerBusinessCode } = getValues();
      const maxSize = 20;

      if (partnerBusinessCode?.length > maxSize) {
        setError('partnerBusinessCode', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 0, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    const validateFax = () => {
      const { fax } = getValues();
      const maxSize = 20;

      if (fax?.length > maxSize) {
        setError('fax', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 0, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    const validateRepresentativeTitle = () => {
      const { representativeTitle } = getValues();
      const maxSize = 150;

      if (representativeTitle?.length > maxSize) {
        setError('representativeTitle', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 0, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    const validateRepresentativeName = () => {
      const { representativeName } = getValues();
      const maxSize = 150;

      if (representativeName?.length > maxSize) {
        setError('representativeName', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 0, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    const validateAddress = () => {
      const { address } = getValues();
      const maxSize = 1000;

      if (address?.length > maxSize) {
        setError('address', {
          message: [
            'The_text_should_be_between_MinSize_to_MaxSize_characters',
            { minSize: 0, maxSize },
          ] as unknown as string,
        });
        isValid = false;
      }
    };

    validatePartnerName();
    validateTaxCode();
    validateTelephone();
    validateEmail();
    validatePartnerBusinessCode();
    validateFax();
    validateRepresentativeTitle();
    validateRepresentativeName();
    validateAddress();

    return isValid;
  };

  const onClickSave = (e: any) => {
    e.preventDefault();
    if (!validateMyInfoForm()) return;

    setFormValues(getValues());
    setEditable(false);
  };

  const onClickCancel = () => {
    setEditable(false);
    reset(formData);
  };

  return (
    <div>
      <PPSubTitle
        title={t('text:First_check_your_information_that_AnchorName_has_registered_on_the_Fin2B_Platform', {
          anchorName: anchorPartnerInfo.anchorName,
        })}
      >
        <div className="d-flex justify-content-end">
          {editable ? (
            <>
              <Button2
                size={Button2SizeEnum.MD}
                color={Button2ColorEnum.TERTIARY}
                onClick={onClickCancel}
                className="pp-mr-16"
              >
                {t('text:Cancel')}
              </Button2>
              <Button2 size={Button2SizeEnum.MD} onClick={onClickSave}>
                {t('text:Save')}
              </Button2>
            </>
          ) : (
            <Button2
              size={Button2SizeEnum.MD}
              color={Button2ColorEnum.TERTIARY}
              onClick={() => {
                setEditable(true);
              }}
              disabled={confirmedCheckbox}
            >
              {t('text:Edit')}
            </Button2>
          )}
        </div>
      </PPSubTitle>

      <FormBorder editable={editable}>
        <FormContents>
          <div className="row">
            <input name="anchorPartnerAccountId" ref={register} hidden />
            <FormInput
              label={t(`text:Company_Name`)}
              name="partnerName"
              disabled={!editable}
              ref={register}
              error={errors.partnerName}
            />
            <FormInput
              label={t(`text:Tax_Code`)}
              name="partnerTaxCode"
              disabled={!editable}
              ref={register}
              error={errors.partnerTaxCode}
            />
          </div>
          <div className="row">
            <FormInput
              label={t(`text:Company_Registration_Number`)}
              name="partnerBusinessCode"
              disabled={!editable}
              ref={register}
              error={errors.partnerBusinessCode}
            />
            <FormInput
              col={3}
              label={t(`text:Telephone`)}
              name="telephone"
              disabled={!editable}
              ref={register}
              error={errors.telephone}
            />
            <FormInput
              col={3}
              label={t(`text:Fax`)}
              name="fax"
              disabled={!editable}
              ref={register}
              error={errors.fax}
            />
          </div>
          <div className="row">
            <FormInput
              label={t(`text:Legal_Representative_Name`)}
              name="representativeName"
              disabled={!editable}
              ref={register}
              error={errors.representativeName}
            />
            <FormInput
              col={3}
              label={t(`text:Legal_Representative_Title`)}
              name="representativeTitle"
              disabled={!editable}
              ref={register}
              error={errors.representativeTitle}
            />
            <FormInput
              col={3}
              label={t(`text:Legal_Representative_Email`)}
              name="representativeEmail"
              disabled={!editable}
              ref={register}
              error={errors.representativeEmail}
            />
          </div>
          <div className="row">
            <FormInput
              col={12}
              label={t(`text:Registered_Office_Address`)}
              name="address"
              disabled={!editable}
              ref={register}
              error={errors.address}
            />
          </div>
        </FormContents>
      </FormBorder>

      <div className="pp-checkbox-form">
        <input
          type="checkbox"
          id="check-company-info"
          checked={confirmedCheckbox}
          onChange={() => setConfirmedCheckbox(prevState => !prevState)}
          disabled={editable}
        />
        {confirmedCheckbox && (
          <FontAwesomeIcon
            icon={faCheck}
            fontSize={17.5}
            className="pp-checkbox-icon"
            onClick={() => {
              setConfirmedCheckbox(prevState => !prevState);
            }}
          />
        )}
        <label className="pp-checkbox-label" htmlFor="check-company-info">
          {t('text:I_have_checked_that_my_company_information_is_correct')}
        </label>
      </div>
    </div>
  );
}

export default PotentialPartnerApplicationStep1MyInfoForm;
