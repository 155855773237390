import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { FINANCIER_INTERFACE_TYPE, LOAN_STATUS, SUCCESS_AR_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { PartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierInterfaceSetting } from 'utils/http/api/common/financier-interface-setting';
import { requestSystemDownloadScannedAr } from 'utils/http/api/system/loans';
import {
  requestSystemFetchPartialCollaterals,
  requestSystemRenewArByFinancierInterface,
  requestSystemSuccessArsDetail,
} from 'utils/http/api/system/success-ars';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getFilteredDisbursedAmount } from 'utils/valueManager/ValueManager';

function SystemArDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { successArId } = useParams<any>();
  const { pageable: financingInfoPageable, setPageable: setFinancingInfoPageable } = usePageable();

  const [systemArDetail, setSystemArDetail] = useState<SuccessArDetailVOModel>();
  const [financingInfo, setFinancingInfo] = useState<Pageable<PartialCollateralVOModel[]>>();
  const [interfaceEnabled, setInterfaceEnabled] = useState<boolean>();

  const fetchAll = async () => {
    try {
      const arDetailResponse = await requestSystemSuccessArsDetail(successArId);

      const [financingInfoResponse, interfaceEnabledResponse] = await Promise.all([
        requestSystemFetchPartialCollaterals(
          financingInfoPageable.currentPage,
          financingInfoPageable.sizePerPage,
          successArId,
        ),
        requestFinancierInterfaceSetting(arDetailResponse.financierId, FINANCIER_INTERFACE_TYPE.AR_PAYMENT_RESULT),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemArDetail(arDetailResponse);
        setInterfaceEnabled(interfaceEnabledResponse);
        setFinancingInfo(financingInfoResponse);
        setFinancingInfoPageable(financingInfoResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const FINANCING_INFORMATION_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 150,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 150,
    },
    {
      headerText: t('text:Disbursement_Amount'),
      colWidths: 150,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Repaid_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Tax_Invoice_File'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickLoanStatus,
    },
    {
      headerText: '',
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderUpdateTransaction = () => {
    if (!interfaceEnabled) return;

    const successArStatus = systemArDetail?.successArStatus;

    if (successArStatus === SUCCESS_AR_STATUS.FAILED || successArStatus === SUCCESS_AR_STATUS.REGISTERED) {
      const onClickUpdateTransaction = async () => {
        try {
          await requestSystemRenewArByFinancierInterface(successArId);
          modal.show(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
            closeBtnCb: () => fetchAll(),
          });
        } catch (e) {
          modal.show(e);
        }
      };

      return (
        <GuideMessage
          message={[t('text:Click_on_the_Update_Transaction_button_to_update_the_AR_Settlement_information')]}
          isImportContentArea={true}
        >
          {{
            button: <Button onClick={onClickUpdateTransaction}>{t('text:Update_Transaction')}</Button>,
          }}
        </GuideMessage>
      );
    }
  };

  const paginateFinancingInfo = async (pageNumber: number, rowCount: number) => {
    try {
      const financingInfoResponse = await requestSystemFetchPartialCollaterals(pageNumber, rowCount, successArId);

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancingInfo(financingInfoResponse);
        setFinancingInfoPageable(financingInfoResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickDownloadScannedAr = async (loanId: number) => {
    try {
      await requestSystemDownloadScannedAr(loanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const showSettlementStatusDescription = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_AR_STATUS)}
        statusDescriptionEnumType="SUCCESS_AR_STATUS"
        statusDescriptionModalType="TEXT"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
      },
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AR_Details')} />
      {systemArDetail?.blockedByFinancier && (
        <GuideMessage
          message={[t('text:The_AR_has_been_blocked'), t('text:Please_check_the_reason_for_block_below')]}
          messageType={MessageType.ALERT}
          reasonTitle={t('text:REASON_FOR_BLOCK')}
          reason={systemArDetail.blockedReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:AR_Information')} />

        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:AR_Amount')}
                className="information-form__input border-none bold-font"
                col={3}
                value={systemArDetail?.arAmount}
                format="number"
              />
              <FormValue
                label={t('text:Currency')}
                className="information-form__input border-none bold-font"
                col={3}
                value={systemArDetail?.currencyType}
              />
              <FormValue
                label={t('text:Active_Status')}
                className={`information-form__input border-none ${getBlockedStatusClassName(
                  systemArDetail?.blockedByFinancier,
                )}`}
                col={3}
                value={systemArDetail?.blockedByFinancier ? t('text:Blocked') : t('text:Active')}
              />
              <FormValue
                label={t('text:Settlement_Status')}
                className={
                  'information-form__input border-none ' +
                  getStatusTextClass('SUCCESS_AR_STATUS', systemArDetail?.successArStatus)
                }
                col={3}
                value={t(`code:success-ar-status.${systemArDetail?.successArStatus}`)}
                format="code"
                hasStatusDescription
                showStatusDescriptionFunc={showSettlementStatusDescription}
              />
            </div>
          </FormContents>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:AR_Number')} col={3} value={systemArDetail?.arNumber} />
              <FormValue label={t('text:AR_Issued_Date')} col={3} value={systemArDetail?.arIssuedDate} format="date" />
              <FormValue label={t('text:Payment_Date')} col={3} value={systemArDetail?.maturityDate} format="date" />
              <FormValue
                label={t('text:Scheduled_Settlement_Date')}
                col={3}
                value={systemArDetail?.settlementDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormValue col={3} label={t('text:Financier_Name')} value={systemArDetail?.financierName} />
              <FormValue col={3} label={t('text:Anchor_Name')} value={systemArDetail?.anchorName} />
              <FormValue col={3} label={t('text:Uploaded_Partner_Name')} value={systemArDetail?.anchorPartnerName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Financing_Information')} />
        <TableBorder>
          <TableHeader header={FINANCING_INFORMATION_HEADERS} />
          <TableBody numOfCol={FINANCING_INFORMATION_HEADERS.length}>
            {financingInfo?.content?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.loanId} />
                <Td data={item.netDisbursementAmount} format="number" />
                <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
                <Td data={item.disbursedDate} format="date" />
                <Td data={item.repaidDate} format="date" />
                <Td data={item.attachmentName} />
                <Td
                  data={t(`code:financing-status.${item.loanStatus}`)}
                  className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
                  format="code"
                />
                <Td className="text-center">
                  <DownloadButton
                    onClick={() => onClickDownloadScannedAr(item.loanId)}
                    disabled={!item.attachmentId && !item.attachmentName}
                  />
                </Td>
                <TdLink path={ROUTES_SY.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(item.loanId)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={financingInfoPageable} paginate={paginateFinancingInfo} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Scheduled_AR_Settlement_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Scheduled_Settlement_Amount')}
                value={systemArDetail?.arAmount}
                format="number"
              />
              <FormValue
                label={t('text:Scheduled_Settlement_Date')}
                value={systemArDetail?.settlementDate}
                format="date"
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Bank_Account_Information-Anchor')} backGroundType={BackGroundType.DarkGray} />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Anchor')} value={systemArDetail?.anchorName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Agreement_Number')} value={systemArDetail?.anchorContractNo} />
              <FormValue
                label={t('text:Payment_Support')}
                value={
                  systemArDetail?.paymentSupport
                    ? `${t('text:Yes')} (${t('text:Co_operation&Payment')})`
                    : `${t('text:No')} (${t('text:Co_operation')})`
                }
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} value={systemArDetail?.anchorSettlementAccount} />
              <FormValue label={t('text:Account_Holder')} value={systemArDetail?.anchorSettlementAccountOwner} />
            </div>
            <div className="row">
              <FormValue label={t('text:Payment_Processing_Bank')} value={systemArDetail?.anchorSettlementBankName} />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Bank_Account_Information-Partner')} backGroundType={BackGroundType.DarkGray} />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Partner_Name')} value={systemArDetail?.anchorPartnerName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} value={systemArDetail?.partnerRequestedAccount} />
              <FormValue label={t('text:Account_Holder')} value={systemArDetail?.partnerRequestedAccountOwner} />
            </div>
            <div className="row">
              <FormValue label={t('text:Payment_Processing_Bank')} value={systemArDetail?.partnerRequestedBankName} />
            </div>
          </FormContents>
          {/* 파트너 약정 존재할 경우 해당 섹션 표시 */}
          {systemArDetail?.dealerContractNo ? (
            <FormContents>
              <div className="row text-bold-brick-red">
                {t(
                  'text:*_If_the_Partner_has_the_master_agreement_the_transfer_will_be_made_according_to_the_information_below',
                )}
              </div>
              <div className="row">
                <FormValue label={t('text:Agreement_Number')} col={3} value={systemArDetail?.dealerContractNo} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} col={6} value={systemArDetail?.principalRepaymentAccount} />
                <FormValue
                  label={t('text:Account_Holder')}
                  col={3}
                  value={systemArDetail?.principalRepaymentAccountOwner}
                />
              </div>
              <div className="row">
                <FormValue
                  label={t('text:Payment_Processing_Bank')}
                  col={6}
                  value={systemArDetail?.principalRepaymentBankName}
                />
              </div>
            </FormContents>
          ) : null}
        </FormBorder>
      </div>
      {systemArDetail?.paymentSupport ? (
        <div className="content-area">
          <SectionTitle title={t('text:AR_Settlement_Result')} />
          {renderUpdateTransaction()}
          <FormBorder>
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue
                  label={t('text:AP_Settlement_Status')}
                  col={6}
                  value={t(`code:success-ar-status.${systemArDetail?.successArStatus}`)}
                  className={
                    'information-form__input border-none ' +
                    getStatusTextClass('SUCCESS_AR_STATUS', systemArDetail.successArStatus)
                  }
                  format="code"
                />
              </div>
            </FormContents>
            <FormSubtitle
              title={t('text:Bank_Information_of_AR_Settlement')}
              backGroundType={BackGroundType.DarkGray}
            />
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue label={t('text:Anchor')} value={systemArDetail?.settledAnchorEnterpriseName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={systemArDetail?.settledAnchorAccount} />
                <FormValue label={t('text:Account_Holder')} value={systemArDetail?.settledAnchorAccountOwner} />
              </div>
            </FormContents>
            <FormContents backGroundType={BackGroundType.Gray}>
              <div className="row">
                <FormValue label={t('text:Partner_Name')} value={systemArDetail?.settledPartnerName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={systemArDetail?.settledPartnerAccount} />
                <FormValue label={t('text:Account_Holder')} value={systemArDetail?.settledPartnerAccountOwner} />
              </div>
            </FormContents>
          </FormBorder>
        </div>
      ) : null}
    </>
  );
}

export default SystemArDetail;
