import type { AR_COMMISSION_STATUS } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface ArCommissionVO {
  successArId: number;
  createdDateTime: string; // successAr.createdDateTime
  arNumber: string;
  arAmount: BigNumber;
  arCommissionAmount: BigNumber;
  arCommissionStatus: AR_COMMISSION_STATUS;
  arCommissionId: number;
}

export interface ArCommissionVOModel extends ArCommissionVO {}

export function formattingToArCommissionVOModel(data: ArCommissionVO): ArCommissionVOModel {
  return {
    ...data,
  };
}
