import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormSelect, FormValue } from 'components/stateless/CommonForm';
import FormInput from 'components/stateless/CommonForm/FormInput';
import SearchFinancierModal from 'components/stateless/Modal/system/SearchFinancierModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { requestSystemTOURegister } from 'utils/http/api/system/terms-of-uses';
import type { SystemTermsOfUsesRequest } from 'utils/http/api/system/terms-of-uses/requests';
import { ModalSize } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

type TouTypeFE = 'default' | 'financier'; // use only FE

function SystemTouRegister() {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();
  const { register, getValues, setValue } = useForm<SystemTermsOfUsesRequest>();

  const [touType, setTouType] = useState<TouTypeFE>();
  const [selectedFinancierName, setSelectedFinancierName] = useState<string | null>();
  const [touFileName, setTouFileName] = useState<string>();

  const setSelectedFinancierInfo = (data: EnterpriseVOModel) => {
    setSelectedFinancierName(data.enterpriseName);
    setValue('financierId', data.enterpriseId);
  };

  const handleClickSearchFinancier = () => {
    modal.show(<SearchFinancierModal modalId={modal.id} getSelectedData={setSelectedFinancierInfo} />, {
      modalSize: ModalSize.XL,
      title: t('text:Search_Financier'),
      closeBtnText: t('text:Close'),
    });
  };

  const validateHasFinancierId = () => {
    let isValid = true;
    if (touType === 'financier' && !selectedFinancierName) {
      isValid = false;
      modal.show(t('text:Financier_must_be_selected'));
    }

    return isValid;
  };

  const validateUploadFile = () => {
    let isValid = true;
    if (getValues().touFile.length === 0) {
      isValid = false;
      modal.show(t('text:The_File_must_be_attached'));
    }

    return isValid;
  };

  const validateVersion = () => {
    let isValid = true;
    if (!getValues().version) {
      isValid = false;
      modal.show(t('text:The_TOU_version_must_be_filled_out'));
    }

    return isValid;
  };

  const handleClickRegisterButton = async () => {
    if (!validateHasFinancierId()) return;
    if (!validateUploadFile()) return;
    if (!validateVersion()) return;

    try {
      const formData = getValues();
      await requestSystemTOURegister(formData);
      history.push(ROUTES_SY.MANAGEMENT.TOU_LIST);
    } catch (e) {
      modal.show(e);
    }
  };

  const handleChangeTouType = (e: ChangeEvent<HTMLSelectElement>) => {
    setTouType(e.target.value as TouTypeFE);
    setSelectedFinancierName('');
    setValue('financierId', null);
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Register_TOU')} />
      <div className="content-area">
        <SectionTitle title={t('text:TOU_Settings')} />
        <FormBorder editable>
          <FormContents>
            <div className="row">
              <input name="financierId" ref={register} hidden />
              <FormSelect
                required
                label={t('text:Type')}
                placeholderOptions={{ show: true }}
                onChange={e => handleChangeTouType(e)}
                selectOptions={[
                  {
                    label: t('text:Default'),
                    value: 'default',
                  },
                  {
                    label: t('text:Financier'),
                    value: 'financier',
                  },
                ]}
              />

              <FormValue label={t('text:Financier_Name')} value={selectedFinancierName}>
                {touType === 'financier' && (
                  <Button size={ButtonSizeEnum.SM} onClick={handleClickSearchFinancier}>
                    {t('text:Search_Financier')}
                  </Button>
                )}
              </FormValue>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="d-flex">
                  <label className="information-form__label star w-auto">{t('text:TOU_file')}</label>
                </div>
                <div className="align-self-center mt-2">
                  <div className="d-flex align-items-center">
                    <input
                      type="file"
                      id="touFile"
                      name="touFile"
                      className="d-none"
                      ref={register}
                      onChange={e => {
                        if (e.target.files) {
                          setTouFileName((e.target.files as FileList)[0]?.name);
                        }
                      }}
                    />
                    <label htmlFor="touFile" className="attach-file-link-button me-3">
                      {t('text:Attach_File')}
                    </label>
                    <div id="fileName" className="upload-file-input me-auto">
                      {touFileName ? touFileName : t('text:No_file_attached')}
                    </div>
                  </div>
                </div>
              </div>
              <FormInput label={t('text:Version')} name="version" ref={register} requiredOptions={{ required: true }} />
            </div>
            <div className="row">
              <FormInput col={12} label={t('text:Description')} name="description" ref={register} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area flex-end">
        <Button size={ButtonSizeEnum.LG} onClick={handleClickRegisterButton}>
          {t('text:Register')}
        </Button>
      </div>
    </>
  );
}

export default SystemTouRegister;
