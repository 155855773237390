import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, DOCUMENT_FORM_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { FinancierDocumentFormVOModel } from 'models/vo/FinancierDocumentFormVO';
import { setFormValues } from 'utils/form/setFormValues';
import {
  requestFinancierDocumentList,
  requestFinancierDownloadDocument,
} from 'utils/http/api/financier/financier-document-forms';
import type { FinancierDocumentListRequest } from 'utils/http/api/financier/financier-document-forms/request';
import { requestFinancierDownloadAdditionalDocument } from 'utils/http/api/financier/loan-additional-document-attachments';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const getConstant = () => {
  const { t } = useTranslation();

  const DOCUMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Registered_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Template_Name'),
      colWidths: 200,
    },
    {
      headerText: t('text:Template_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Download'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const FI_DOCUMENT_LIST_QS_KEY = 'fi-document-list';

  return {
    DOCUMENT_TABLE_HEADERS,
    FI_DOCUMENT_LIST_QS_KEY,
  };
};

function FinancierDocumentList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const getDocumentProperty = useProperty<FinancierDocumentListRequest>();
  const [documentList, setDocumentList] = useState<Pageable<FinancierDocumentFormVOModel[]>>();
  const documentSearchForm = useForm<FinancierDocumentListRequest>();
  const signInInfo = getSignIn();
  const { DOCUMENT_TABLE_HEADERS, FI_DOCUMENT_LIST_QS_KEY } = getConstant();
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;
  const { pageable, setPageable } = usePageable(FI_DOCUMENT_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierDocumentListRequest>(
        documentSearchForm.setValue,
        getParsedSearchParams(FI_DOCUMENT_LIST_QS_KEY).formSearchData,
      );
      fetchDocumentList(pageable.currentPage, pageable.sizePerPage, documentSearchForm.getValues());
    }
  }, [mounted]);

  async function fetchDocumentList(pageNumber = 1, rowCount = 10, searchValue: FinancierDocumentListRequest) {
    try {
      const documentList = await requestFinancierDocumentList(pageNumber, rowCount, searchValue);
      updateSearchParams(
        {
          ...searchValue,
          pageNumber,
          rowCount,
        },
        FI_DOCUMENT_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setDocumentList(documentList);
        setPageable(documentList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const renderDocumentSearchTable = (): JSX.Element => {
    const onClickRemoveFilter = (e: any): void => {
      e.preventDefault();
      documentSearchForm.reset();
    };

    const handleDocumentSearchFormSubmit = async () => {
      await fetchDocumentList(1, pageable.sizePerPage, documentSearchForm.getValues());
    };

    return (
      <form onSubmit={handleDocumentSearchFormSubmit}>
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <SearchBorder>
          <div className="row">
            <SearchLabel label={t('text:Registration_Date')} />
            <SearchDatePicker
              name={getDocumentProperty('updatedDateFrom')}
              placeholder={t('text:from')}
              control={documentSearchForm.control}
            />
            <SearchDatePicker
              name={getDocumentProperty('updatedDateTo')}
              placeholder={t('text:to')}
              control={documentSearchForm.control}
            />
            <SearchEmpty />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Template_Name')} />
            <SearchInput name={getDocumentProperty('documentFormName')} ref={documentSearchForm.register} />
            <SearchLabel label={t('text:Template_Type')} />
            <SearchSelect
              selectOptions={getSelectOptions<DOCUMENT_FORM_TYPE>('DOCUMENT_FORM_TYPE', 'ALL', true)}
              name={getDocumentProperty('documentFormType')}
              ref={documentSearchForm.register}
            />
          </div>
        </SearchBorder>
        <div className="flex-center mt-4">
          <Button size={ButtonSizeEnum.LG} onClick={handleDocumentSearchFormSubmit}>
            {t('text:Search')}
          </Button>
        </div>
      </form>
    );
  };

  const renderDocumentResultTable = (): JSX.Element => {
    const paginate = async (page: number, sizePerPage: number) => {
      await fetchDocumentList(page, sizePerPage, getParsedSearchParams(FI_DOCUMENT_LIST_QS_KEY).formSearchData);
    };

    const financierDocumentFormDownload = (financierDocumentFormId: number) => {
      try {
        requestFinancierDownloadDocument(financierDocumentFormId);
      } catch (error) {
        modal.show(error);
      }
    };

    const loanAdditionalDocumentDownload = (loanAdditionalDocumentId: number) => {
      try {
        requestFinancierDownloadAdditionalDocument(loanAdditionalDocumentId);
      } catch (error) {
        modal.show(error);
      }
    };

    const translateDocumentFormTypeToText = (documentFormType: DOCUMENT_FORM_TYPE) => {
      switch (documentFormType) {
        case DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_REQUEST:
          return t('text:Dealer_Finance_-_Financing_Application');
        case DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_AGREEMENT:
          return t('text:Dealer_Finance_Financing_Agreement');
        case DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_REQUEST:
          return t('text:Vendor_Finance_-_Financing_Application');
        case DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_AGREEMENT:
          return t('text:Vendor_Finance_Financing_Agreement');
        case DOCUMENT_FORM_TYPE.ADDITIONAL_DOCUMENT:
          return t('text:Additional_Document');
        case DOCUMENT_FORM_TYPE.BULK_TRANSFER:
          return t('text:Bulk_Transfer_Application');
        case DOCUMENT_FORM_TYPE.REMITTANCE:
          return t('text:Remittance_Application');
        default:
          return documentFormType;
      }
    };

    return (
      <>
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {documentList?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={DOCUMENT_TABLE_HEADERS} />
          <TableBody numOfCol={DOCUMENT_TABLE_HEADERS.length}>
            {documentList?.content.map((item, index) => (
              <Tr key={index}>
                <Td data={item.updatedDateTime} format="datetime" />
                <Td data={item.documentFormName} />
                <Td data={translateDocumentFormTypeToText(item.documentFormType)} />
                <Td className="text-center">
                  <DownloadButton
                    onClick={() =>
                      item.documentFormType === DOCUMENT_FORM_TYPE.ADDITIONAL_DOCUMENT
                        ? loanAdditionalDocumentDownload(item.financierDocumentFormId)
                        : financierDocumentFormDownload(item.financierDocumentFormId)
                    }
                    disabled={isNil(item.financierDocumentFormId)}
                  />
                </Td>
                <TdLink
                  path={
                    item.documentFormType === DOCUMENT_FORM_TYPE.ADDITIONAL_DOCUMENT
                      ? ROUTES_FI.PROGRAM_SETTINGS.ADDITIONAL_DOCUMENT_DETAIL_BUILD_PATH(item.financierDocumentFormId)
                      : ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_DETAIL_BUILD_PATH(item.financierDocumentFormId)
                  }
                />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Document_Templates')} />
      {isAdmin && (
        <GuideMessage
          message={[
            t('text:Click_the_button_on_the_right_to_register_a_new_document_template'),
            t('text:Document_templates_can_be_found_in_the_list_below'),
          ]}
        >
          {{
            button: (
              <>
                <Button to={ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_REGISTER}>{t('text:New_Template')}</Button>
                <Button to={ROUTES_FI.PROGRAM_SETTINGS.ADDITIONAL_DOCUMENT_REGISTER}>
                  {t('text:New_Additional_Document')}
                </Button>
              </>
            ),
          }}
        </GuideMessage>
      )}
      <div className="content-area">{renderDocumentSearchTable()}</div>
      <div className="division-border" />
      <div className="content-area">{renderDocumentResultTable()}</div>
    </>
  );
}

export default FinancierDocumentList;
