import type Pageable from 'models/Pageable';
import type { ArPhaseVO, ArPhaseVOModel } from 'models/vo/ArPhaseVO';
import { formattingToArPhaseVOModel } from 'models/vo/ArPhaseVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

export async function requestSystemArPhaseList(
  pageNumber: number,
  rowCount: number,
  arSummaryId: number,
): Promise<Pageable<ArPhaseVOModel[]>> {
  const response = await http.get<Pageable<ArPhaseVO[]>>({
    url: API_SY.AR_APPROVAL_PHASES.AR_APPROVAL_PHASE_LIST,
    data: {
      pageNumber,
      rowCount,
      arSummaryId,
    },
  });

  const arSummaryList: ArPhaseVOModel[] = response.content.map((data: ArPhaseVO) => formattingToArPhaseVOModel(data));

  return {
    ...response,
    content: arSummaryList,
  };
}
