import { BASIS_INTEREST_TYPE, CURRENCY_TYPE, SUCCESS_AR_STATUS, stringToEnum } from 'enums';

export interface SuccessArForRequestLoanVO {
  successArId: number;
  createdDateTime: string;
  arNumber: string;
  arAmount: number;
  arIssuedDate: string;
  maturityDate: string;
  settlementDate: string;
  anchorName: string;
  anchorTaxCode: string;
  anchorPartnerName: string;
  anchorPartnerTaxCode: string;
  financierName: string;
  currencyType: string;
  blockedByFinancier: boolean;
  blockedReason: string;
  paymentSupport: boolean;
  successArStatus: string;
  remainingArAmount: number;
  dealerAgreementId: number;
  financierEntId: number;
  basisInterestType: string;
  basisInterestTerm: string;
  basisInterestRate: number;
  creditInterestRate: number;
  preferentialInterestRate: number;
  termSpreadRate: number;
  basisInterestBankCode: string;
  showExpectedTotalInterestRate: boolean;
  hasBasisInterest: boolean;
}

export interface SuccessArForRequestLoanVOModel extends SuccessArForRequestLoanVO {
  currencyType: CURRENCY_TYPE;
  successArStatus: SUCCESS_AR_STATUS;
  basisInterestType: BASIS_INTEREST_TYPE;
}

export function formattingToSuccessArForRequestLoanVOModel(
  data: SuccessArForRequestLoanVO,
): SuccessArForRequestLoanVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    successArStatus: stringToEnum(SUCCESS_AR_STATUS, data.successArStatus),
    basisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.basisInterestType),
  };
}
