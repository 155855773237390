import { COLLATERAL_TYPE } from 'enums';

import useExtraInformationViewModel from '../../extraInformation/useExtraInformationViewModel';
import useFinancingOptionViewModel from '../../models/useFinancingOptionViewModel';

const useDisbursementAccountController = () => {
  const { supportedCollateralType } = useExtraInformationViewModel();
  const { anchorFinancingOption } = useFinancingOptionViewModel();
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const isShowAccountInput = (isAr && anchorFinancingOption.anchorAccount) || !isAr;

  return {
    isShowAccountInput,
    isAr,
  };
};

export default useDisbursementAccountController;
