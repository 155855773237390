import type Pageable from 'models/Pageable';
import type {
  WaitingAnchorAgreementDetailVO,
  WaitingAnchorAgreementDetailVOModel,
} from 'models/vo/WaitingAnchorAgreementDetailVO';
import { formattingToWaitingAnchorAgreementDetailVOModel } from 'models/vo/WaitingAnchorAgreementDetailVO';
import type { WaitingAnchorAgreementVO, WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import { formattingToWaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  CreateFiWaitingAnchorAgreementDTO,
  ReturnFiWaitingAnchorAgreementDTO,
  SearchFiWaitingAnchorAgreementDTO,
  UpdateFiWaitingAnchorAgreementDTO,
} from './requests';

export async function requestFinancierWaitingAnchorAgreementList(
  pageNumber: number,
  rowCount: number,
  SearchFiWaitingAnchorAgreementDTO: SearchFiWaitingAnchorAgreementDTO,
): Promise<Pageable<WaitingAnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorAgreementVO[]>>({
    url: API_FI.WAITING_ANCHOR_AGREEMENTS.WAITING_ANCHOR_AGREEMENT,
    data: {
      pageNumber,
      rowCount,
      ...SearchFiWaitingAnchorAgreementDTO,
    },
  });

  const waitingAnchorAgreementList = response.content.map(data => formattingToWaitingAnchorAgreementVOModel(data));

  return {
    ...response,
    content: waitingAnchorAgreementList,
  };
}

export async function requestFinancierWaitingAnchorAgreementDetail(
  waitingAnchorAgreementId: number,
): Promise<WaitingAnchorAgreementDetailVOModel> {
  const response = await http.get<WaitingAnchorAgreementDetailVO>({
    url: API_FI.WAITING_ANCHOR_AGREEMENTS.WAITING_ANCHOR_AGREEMENT_DETAIL(waitingAnchorAgreementId),
  });

  const waitingAnchorAgreementData = formattingToWaitingAnchorAgreementDetailVOModel(response);

  return waitingAnchorAgreementData;
}

export async function requestRegisterWaitingAnchorAgreement(
  CreateFiWaitingAnchorAgreementDTO: CreateFiWaitingAnchorAgreementDTO,
): Promise<WaitingAnchorAgreementVOModel> {
  const response = await http.post<WaitingAnchorAgreementVO>({
    url: API_FI.WAITING_ANCHOR_AGREEMENTS.WAITING_ANCHOR_AGREEMENT,
    data: CreateFiWaitingAnchorAgreementDTO,
  });

  const waitingAnchorAgreementData = formattingToWaitingAnchorAgreementVOModel(response);

  return waitingAnchorAgreementData;
}

export async function requestUpdateWaitingAnchorAgreement(
  waitingAnchorAgreementId: number,
  UpdateFiWaitingAnchorAgreementDTO: UpdateFiWaitingAnchorAgreementDTO,
): Promise<WaitingAnchorAgreementVOModel> {
  const response = await http.put<WaitingAnchorAgreementVO>({
    url: API_FI.WAITING_ANCHOR_AGREEMENTS.WAITING_ANCHOR_AGREEMENT_DETAIL(waitingAnchorAgreementId),
    data: UpdateFiWaitingAnchorAgreementDTO,
  });

  const waitingAnchorAgreementData = formattingToWaitingAnchorAgreementVOModel(response);

  return waitingAnchorAgreementData;
}

export async function requestCancelWaitingAnchorAgreement(
  waitingAnchorAgreementId: number,
): Promise<WaitingAnchorAgreementVOModel> {
  const response = await http.put<WaitingAnchorAgreementVO>({
    url: API_FI.WAITING_ANCHOR_AGREEMENTS.CANCEL_WAITING_ANCHOR_AGREEMENT(waitingAnchorAgreementId),
  });

  const waitingAnchorAgreementData = formattingToWaitingAnchorAgreementVOModel(response);

  return waitingAnchorAgreementData;
}

export async function requestReturnWaitingAnchorAgreement(
  waitingAnchorAgreementId: number,
  ReturnFiWaitingAnchorPartnerDTO: ReturnFiWaitingAnchorAgreementDTO,
): Promise<WaitingAnchorAgreementVOModel> {
  const response = await http.put<WaitingAnchorAgreementVO>({
    url: API_FI.WAITING_ANCHOR_AGREEMENTS.RETURN_WAITING_ANCHOR_AGREEMENT(waitingAnchorAgreementId),
    data: ReturnFiWaitingAnchorPartnerDTO,
  });

  const waitingAnchorAgreementData = formattingToWaitingAnchorAgreementVOModel(response);

  return waitingAnchorAgreementData;
}

export async function requestApproveWaitingAnchorAgreement(
  waitingAnchorAgreementId: number,
): Promise<WaitingAnchorAgreementVOModel> {
  const response = await http.put<WaitingAnchorAgreementVO>({
    url: API_FI.WAITING_ANCHOR_AGREEMENTS.APPROVE_WAITING_ANCHOR_AGREEMENT(waitingAnchorAgreementId),
  });

  const waitingAnchorAgreementData = formattingToWaitingAnchorAgreementVOModel(response);

  return waitingAnchorAgreementData;
}
