import { useAnchorAgreementInfoContext } from './AnchorAgreementInfoContext';

const useAnchorAgreementInfoViewModel = () => {
  const { anchorAgreementInfo, updateAnchorAgreementInfo, fetchAnchorAgreementDetail } =
    useAnchorAgreementInfoContext();

  return {
    anchorAgreementInfo,
    updateAnchorAgreementInfo,
    fetchAnchorAgreementDetail,
  };
};

export default useAnchorAgreementInfoViewModel;
