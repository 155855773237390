import type Pageable from 'models/Pageable';
import type { DealerAgreementDetailVO, DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import { formattingToDealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type {
  RelatedAnchorClientLimitInfoVO,
  RelatedAnchorClientLimitInfoVOModel,
} from 'models/vo/RelatedAnchorClientLimitInfoVO';
import { formattingToRelatedAnchorClientLimitInfoVOModel } from 'models/vo/RelatedAnchorClientLimitInfoVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemPartnerAgreementListRequest } from './requests';

export async function requestSystemPartnerAgreementList(
  pageNumber: number,
  rowCount: number,
  data: SystemPartnerAgreementListRequest,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_SY.DEALER_AGREEMENTS.DEALER_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDealerAgreementVOModel(item)),
  };
}

export async function requestSystemPartnerAgreementDetail(
  dealerAgreementId: number,
): Promise<DealerAgreementDetailVOModel> {
  const response = await http.get<DealerAgreementDetailVO>({
    url: API_SY.DEALER_AGREEMENTS.DEALER_AGREEMENT_DETAIL(dealerAgreementId),
  });

  return formattingToDealerAgreementDetailVOModel(response);
}

export async function requestSyAnchorAgreementLimitInfoRelatedDealerAgreement(
  dealerAgreementId: number,
): Promise<RelatedAnchorClientLimitInfoVOModel> {
  const response = await http.get<RelatedAnchorClientLimitInfoVO>({
    url: API_SY.DEALER_AGREEMENTS.ANCHOR_CLIENT_LIMIT_INFO_RELATED_DEALER_AGREEMENT(dealerAgreementId),
  });

  return formattingToRelatedAnchorClientLimitInfoVOModel(response);
}
