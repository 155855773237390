import type {
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  CURRENCY_TYPE,
  DEALER_AGREEMENT_STATUS,
  DEALER_AGREEMENT_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
  LOAN_TERM_TYPE,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

import { formattingToTermSpreadVOModel } from './TermSpreadVO';

import type { TermSpreadVO, TermSpreadVOModel } from './TermSpreadVO';

export interface WaitingDealerAgreementVO {
  waitingDealerAgreementId: number;
  contractNo: string;
  anchorAgreementId: number;
  mainContractNo: string;
  creditRating: string;
  settlementAccountFinancierCode: string;
  settlementAccountFinancierName: string;
  settlementAccountBranchCode: string;
  settlementAccountBranchName: string;
  settlementAccount: string;
  settlementAccountOwner: string;
  principalRepaymentAccountFinancierCode: string;
  principalRepaymentAccountFinancierName: string;
  principalRepaymentAccountBranchCode: string;
  principalRepaymentAccountBranchName: string;
  principalRepaymentAccount: string;
  principalRepaymentAccountOwner: string;
  interestRepaymentAccountFinancierCode: string;
  interestRepaymentAccountFinancierName: string;
  interestRepaymentAccountBranchCode: string;
  interestRepaymentAccountBranchName: string;
  interestRepaymentAccount: string;
  interestRepaymentAccountOwner: string;
  changeDate: string;
  startDate: string;
  expiryDate: string;
  maxExtensibleLoanCount: number;
  earlyRepaymentAllowable: boolean;
  dealerUploadInvoiceAllowable: boolean;
  loanTermUnit: number;
  minimumLoanRange: number;
  maximumLoanRange: number;
  monthlyInterestRepaymentDate: number;
  depositCollateralAccountFinancierCode: string;
  depositCollateralAccountFinancierName: string;
  depositCollateralAccountBranchCode: string;
  depositCollateralAccountBranchName: string;
  depositCollateralAccount: string;
  depositCollateralAccountOwner: string;
  basisInterestBankCode: string;
  basisInterestTerm: string;
  basisInterestRate: number;
  creditInterestRate: number;
  preferentialInterestRate: number;
  preferentialReason: string;
  loanLimitAmount: string;
  authorizerName: string;
  authorizerEmployeeCode: string;
  authorizerEmail: string;
  authorizerMobile: string;
  authorizerPosition: string;
  authorizerBankUserId: string;
  authorizerOtpSerialNo: string;
  branchId: number;
  branchName: string;
  branchCode: string;
  branchAddress: string;
  branchTelephone: string;
  branchFax: string;
  anchorClientId: number;
  anchorClientCode: string;
  anchorClientName: string;
  dealerClientId: number;
  dealerClientCode: string;
  dealerClientName: string;
  financierEntId: number;
  dealerAgreementId: number;
  returnReason: string;
  updatedDateTime: string;
  financierName: string;
  termSpreadList: TermSpreadVO[];
  operatorUserLoginId: string;
  operatorUserName: string;
  authorizerUserLoginId: string;
  authorizerUserName: string;
  adhocLimitAllowable: boolean;
  adhocLimitAmount: string;
  adhocLimitStartDate: string;
  adhocLimitEndDate: string;
  dealerCodeByAnchor: string;
  repaymentDateOverAllowable: boolean;
  drawingPower: string;
  dealerClientTaxCode: string;
  showExpectedTotalInterestRate: boolean;
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  dealerAgreementStatus: DEALER_AGREEMENT_STATUS;
  dealerAgreementType: DEALER_AGREEMENT_TYPE;
  loanLimitCheckType: LOAN_LIMIT_CHECK_TYPE;
  loanTermType: LOAN_TERM_TYPE;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  basisInterestType: BASIS_INTEREST_TYPE;
  approvalType: COMMON_APPROVAL_TYPE;
  showNetDisbursementAmount: boolean;
}

export interface WaitingDealerAgreementVOModel extends WaitingDealerAgreementVO {
  loanLimitAmount: BigNumber;
  adhocLimitAmount: BigNumber;
  drawingPower: BigNumber;
  termSpreadList: TermSpreadVOModel[];
}

export function formattingToWaitingDealerAgreementVOModel(
  data: WaitingDealerAgreementVO,
): WaitingDealerAgreementVOModel {
  const { loanLimitAmount, adhocLimitAmount, drawingPower, termSpreadList } = data;

  return {
    ...data,
    loanLimitAmount: sliceZeroDecimal(loanLimitAmount),
    adhocLimitAmount: sliceZeroDecimal(adhocLimitAmount),
    drawingPower: sliceZeroDecimal(drawingPower),
    termSpreadList: termSpreadList
      ? termSpreadList.map(termSpread => formattingToTermSpreadVOModel(termSpread))
      : termSpreadList,
  };
}
