import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, ENTERPRISE_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemUserList } from 'utils/http/api/system/users';
import type { systemUserSearchRequest } from 'utils/http/api/system/users/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const SY_FINANCIER_ADMIN_LIST_QS_KEY = 'sy-financier-admin-list';

function SystemFinancierAdminList() {
  const { t } = useTranslation(['format']);
  const history = useHistory();
  const mounted = useMounted();
  const modal = useModal();
  const { pageable, setPageable } = usePageable(SY_FINANCIER_ADMIN_LIST_QS_KEY);

  const getProperty = useProperty<systemUserSearchRequest>();
  const [userPage, setUserPage] = useState<Pageable<UserVOModel[]>>();

  const { register, handleSubmit, reset, getValues, setValue } = useForm<systemUserSearchRequest>({
    mode: 'onSubmit',
    shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
  });

  useEffect(() => {
    if (mounted) {
      setFormValues<systemUserSearchRequest>(
        setValue,
        getParsedSearchParams(SY_FINANCIER_ADMIN_LIST_QS_KEY).formSearchData,
      );
      fetchFinancierAdminList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  async function fetchFinancierAdminList(
    pageNumber: number = 1,
    sizePerPage: number = 10,
    data: systemUserSearchRequest,
  ) {
    data.enterpriseType = ENTERPRISE_TYPE.FI;
    data.authorityType = AUTHORITY_TYPE.ADMIN;
    try {
      const userPage = await requestSystemUserList(pageNumber, sizePerPage, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount: sizePerPage,
        },
        SY_FINANCIER_ADMIN_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setUserPage(userPage);
        setPageable(userPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async () => {
    await fetchFinancierAdminList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchFinancierAdminList(
      page,
      sizePerPage,
      getParsedSearchParams(SY_FINANCIER_ADMIN_LIST_QS_KEY).formSearchData,
    );
  };

  const renderGuideMessage = () => {
    const onClickRegister = () => {
      history.push(ROUTES_SY.MANAGEMENT.FINANCIER_ADMIN_REGISTER);
    };

    return (
      <GuideMessage
        message={[
          t('text:Click_the_Register_button_on_the_right_to_register_the_Financier_Admin'),
          t('text:Registered_financier_admin_can_be_found_in_the_list_below'),
        ]}
      >
        {{
          button: <Button onClick={onClickRegister}>{t('text:Register')}</Button>,
        }}
      </GuideMessage>
    );
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
      colWidths: 90,
    },
    {
      headerText: t('text:User_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Tax_Code'),
      colWidths: 130,
    },
    {
      headerText: t('text:Email'),
      colWidths: 120,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 90,
    },
    {
      headerText: t('text:Registration_Status'),
      colWidths: 110,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    const setUserStatusTd = (code: USER_STATUS | undefined) => {
      switch (code) {
        case USER_STATUS.REGISTERED:
          return (
            <Td
              className={getStatusTextClass('USER_STATUS', USER_STATUS.REGISTERED)}
              data={t('text:Send_Invitation_email')}
            />
          );
        case USER_STATUS.INVITED:
          return (
            <Td
              className={getStatusTextClass('USER_STATUS', USER_STATUS.INVITED)}
              data={t('text:Invitation_Email_Sent')}
            />
          );
        case USER_STATUS.ACTIVATED:
          return <Td className={getStatusTextClass('USER_STATUS', USER_STATUS.ACTIVATED)} data={t('text:Active')} />;
        case USER_STATUS.SUSPENDED:
          return (
            <Td className={getStatusTextClass('USER_STATUS', USER_STATUS.SUSPENDED)} data={t('text:Deactivated')} />
          );
      }
    };

    return userPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.employeeCode} />
          <Td data={item.userName} />
          <Td data={item.enterpriseName} />
          <Td data={item.enterpriseTaxCode} />
          <Td data={item.email} />
          <Td data={item.telephone} />
          {setUserStatusTd(item.userStatus)}
          <TdLink path={ROUTES_SY.MANAGEMENT.FINANCIER_ADMIN_DETAIL_BUILD_PATH(item.userId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Financier_Admin')} />
      {renderGuideMessage()}
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:User_Code')} />
              <SearchInput ref={register} name={getProperty('employeeCode')} />
              <SearchLabel label={t('text:User_Name')} />
              <SearchInput ref={register} name={getProperty('userName')} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput ref={register} name="enterpriseName" />
              <SearchLabel label={t('text:Tax_Code')} />
              <SearchInput ref={register} name="taxCode" />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Email')} />
              <SearchInput ref={register} name={getProperty('email')} />
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput ref={register} name={getProperty('telephone')} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Registration_Status')} />
              <SearchSelect
                ref={register}
                name={getProperty('userStatus')}
                selectOptions={getSelectOptions<USER_STATUS>('USER_STATUS', 'ALL', true)}
              />

              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onSearchSubmit)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {userPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemFinancierAdminList;
