import { requestFinancierDownloadScannedInvoice } from 'utils/http/api/financier/success-invoices';
import useModal from 'utils/modal/useModal';

export function useFinancierFinancingDetailInvoiceInformationLogics(invoiceId: number) {
  const modal = useModal();

  // invoice file 다운로드 호출
  const onClickDownloadInvoiceFile = async (e: any) => {
    e.preventDefault();
    try {
      await requestFinancierDownloadScannedInvoice(invoiceId);
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    onClickDownloadInvoiceFile,
  };
}
