export function browserCheck(): boolean | undefined {
  const { userAgent } = navigator;
  const is_chrome = userAgent.toLowerCase().indexOf('chrome') !== -1;
  // 크로미움 기반이어서 Chrome이 포함
  const isSamsungBrowser = userAgent.indexOf('SamsungBrowser');
  const isEdge = userAgent.indexOf('Edg');

  // Chrome
  if (is_chrome) {
    if (isSamsungBrowser < 0 && isEdge < 0) {
      return false;
    }
  }

  return true;
}
