import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import { LOAN_TERM_TYPE } from 'enums';
import type { CalculateForLoanRequestVOModel } from 'models/vo/CalculateForLoanRequestVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';

interface ARFinancingApplicationSummaryProps {
  dealerArDetail: SuccessArDetailVOModel;
  dealerAgreementDetail: DealerAgreementDetailVOModel;
  dealerArCalculateLoanRequest: CalculateForLoanRequestVOModel | undefined;
  factoringEnable: boolean;
  expectedInterestRate: string | undefined;
}

function ARFinancingApplicationSummary({
  dealerArDetail,
  dealerAgreementDetail,
  dealerArCalculateLoanRequest,
  factoringEnable,
  expectedInterestRate,
}: ARFinancingApplicationSummaryProps) {
  const { t } = useTranslation(['format']);
  const { watch } = useFormContext();
  const { disbursementDate, requestedLoanAmount } = watch();

  return (
    <FormBorder>
      <FormContents>
        <div className="row">
          <FormValue
            label={t('text:Requested_Financing_Amount')}
            value={`${t('format:number', { value: requestedLoanAmount })} ${dealerArDetail.currencyType}`}
          />
          <FormValue
            label={t('text:Requested_Disbursement_Date')}
            value={
              dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE
                ? disbursementDate
                : dealerArDetail?.scheduledDisbursementDate
            }
            format="date"
          />
        </div>
        {factoringEnable ? (
          <>
            <div className="row">
              <FormValue
                label={t('text:Advance_Disbursement')}
                value={dealerArCalculateLoanRequest?.netDisbursementAmount}
                format="number"
              />
              <FormValue
                label={t('text:Discount_Charge')}
                value={dealerArCalculateLoanRequest?.expectedInterestAmount}
                format="number"
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Discount_Rate_APR')} value={expectedInterestRate} />
              <FormValue
                label={t('text:Remaining_AR_Balance')}
                value={dealerArCalculateLoanRequest?.remainingCollateralAmount}
                format="number"
              />
            </div>
          </>
        ) : (
          <div className="row">
            <FormValue label={t('text:Expected_Interest(APR)')} value={`${expectedInterestRate}`} />
            <FormValue label={t('text:Scheduled_Repayment_Date')} value={dealerArDetail?.maturityDate} format="date" />
          </div>
        )}
      </FormContents>
    </FormBorder>
  );
}

export default ARFinancingApplicationSummary;
