import type Pageable from 'models/Pageable';
import type { DivisionDetailVO, DivisionDetailVOModel } from 'models/vo/DivisionDetailVO';
import type { DivisionVO, DivisionVOModel } from 'models/vo/DivisionVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SearchSyDivisionDTO, SearchSyDivisionDetailByCodeDTO } from './requests';

export const requestSystemDivisionByCode = async (
  data: SearchSyDivisionDetailByCodeDTO,
): Promise<DivisionDetailVOModel> =>
  await http.get<DivisionDetailVO>({
    url: API_SY.DIVISIONS.DIVISION_BY_CODE,
    data,
  });

export const requestSystemDivisions = async (
  pageNumber: number,
  rowCount: number,
  searchValue?: SearchSyDivisionDTO,
): Promise<Pageable<DivisionVOModel[]>> =>
  await http.get<Pageable<DivisionVO[]>>({
    url: API_SY.DIVISIONS.DIVISIONS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
