import './PPTextField.scss';

interface PPTextStatusField {
  children?: JSX.Element | JSX.Element[];
}

function PPTextStatusField({ children }: PPTextStatusField) {
  return <div className="pp-text-field__status">{children}</div>;
}

export default PPTextStatusField;
