import type { Dispatch, ReactNode, SetStateAction } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import useResizeObserver from 'hooks/useResizeObserver';

export type AccordionStateType = {
  id: string;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
};

export const AccordionContext = createContext<AccordionStateType | null>(null);
AccordionContext.displayName = 'AccordionContext';

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordionContext should be used within AccordionContext.Provider');
  }

  return context;
};

interface AccordionRootPropsType {
  id: string;
  children: ReactNode;
  defaultExpanded?: boolean;
  callback?: () => void;
  trigger?: string;
}

const AccordionRoot = ({ id, children, defaultExpanded = false, callback, trigger }: AccordionRootPropsType) => {
  const [expanded, setExpanded] = useState(false);

  const contextValue = {
    id,
    expanded,
    setExpanded,
  };

  useEffect(() => {
    if (expanded && callback) {
      callback();
    }
  }, [expanded, callback]);

  useLayoutEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded, trigger]);

  return <AccordionContext.Provider value={contextValue}>{children}</AccordionContext.Provider>;
};

type TriggerPropsType = {
  className?: string;
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Trigger = ({ className = '', children, onClick }: TriggerPropsType) => {
  const { expanded, setExpanded } = useAccordionContext();

  const toggleAccordion = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setExpanded(prevExpanded => !prevExpanded);
    onClick && onClick(event);
  };

  return (
    <button
      className={`${expanded ? '' : 'collapsed'} ${className}`}
      type="button"
      aria-expanded={expanded}
      onClick={toggleAccordion}
    >
      {children}
    </button>
  );
};

type ContentPropsType = {
  className?: string;
  children: ReactNode;
};

const Content = ({ className = '', children }: ContentPropsType) => {
  const { id, expanded } = useAccordionContext();

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useResizeObserver<HTMLDivElement>({
    callback: useCallback(
      element => {
        if (contentWrapperRef.current) {
          const height = element.clientHeight;
          contentWrapperRef.current.style.height = expanded ? `${height}px` : '0';
        }
      },
      [expanded],
    ),
  });

  return (
    <div
      className={`accordion-collapse collapse ${expanded ? 'show' : ''}`}
      aria-labelledby={id}
      ref={contentWrapperRef}
    >
      <div className={className} ref={contentRef}>
        {children}
      </div>
    </div>
  );
};

const Accordion = Object.assign(AccordionRoot, {
  Trigger,
  Content,
});
export default Accordion;
