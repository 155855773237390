import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormErrorMessage } from 'components/stateless/CommonForm/FormErrorMessage';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import PasswordValidationBox from 'components/stateless/PasswordValidationBox/PasswordValidationBox';
import Timer from 'components/stateless/Timer/Timer';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_CM } from 'constants/routes/common';
import { AUTHORITY_TYPE, ENTERPRISE_TYPE, OTP_TYPE } from 'enums';
import { InvalidInputValueExceptionCode } from 'enums/exception';
import useMounted from 'hooks/useMounted';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import type { TermsOfUseVersionVOModel } from 'models/vo/TermsOfUseVersionVO';
import { HttpError } from 'utils/error/HttpError';
import { formErrorHandler } from 'utils/error/manager';
import API_AN from 'utils/http/api/anonymous';
import { requestInvitedAnchorUser } from 'utils/http/api/anonymous/agreements';
import { requestClientAuth } from 'utils/http/api/anonymous/client-auth-setting';
import { requestInvitedFinancierClient } from 'utils/http/api/anonymous/financier-clients';
import { requestOtpSend } from 'utils/http/api/anonymous/otp';
import { requestTermsOfUseVersions } from 'utils/http/api/anonymous/terms-of-use-versions';
import { requestCheckUserExistence, requestSignUpAnchorUser } from 'utils/http/api/anonymous/users';
import type { SignUpAnchorUserRequest } from 'utils/http/api/anonymous/users/requests';
import useModal from 'utils/modal/useModal';
import { REG_LOWERCASE_AND_NUMBER } from 'utils/validation/regExp';

function AnchorSignUp() {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const code = query.code as string;

  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const history = useHistory();

  const [anchorUser, setAnchorUser] = useState<AnchorUserVOModel>();
  const [financierClient, setFinancierClient] = useState<FinancierClientVOModel>();
  const [clientAuthSetting, setClientAuthSetting] = useState<FinancierClientAuthSettingVOModel>();
  const [isCheckID, setCheckID] = useState<boolean | undefined>(undefined);
  const [isSendCode, setSendCode] = useState<boolean>(false);
  const [isAdmin, setAdmin] = useState<boolean>(false);
  const [mobileOne, setMobileOne] = useState<string>('');
  const [mobileTwo, setMobileTwo] = useState<string>('');
  const [mobileThree, setMobileThree] = useState<string>('');
  const [mobileFour, setMobileFour] = useState<string>('');
  const [loginIdErrorMessage, setLoginIdErrorMessage] = useState<string>('');
  const [checkedTOUs, setCheckedTOUs] = useState<number[]>([]);
  const [verificationRequestCode, setVerificationRequestCode] = useState<string>();
  const [termsOfUseVersions, setTermsOfUseVersions] = useState<TermsOfUseVersionVOModel[]>([]);

  // error State
  const [mobileInvalid, setMobileInvalid] = useState<boolean>(false);
  const [taxCodeInvalid, setTaxCodeInvalid] = useState<boolean>(false);
  const [otpCodeInvalid, setOtpCodeInvalid] = useState<boolean>(false);

  const emailType = clientAuthSetting?.otpType === OTP_TYPE.EMAIL;
  const aotpType = clientAuthSetting?.otpType === OTP_TYPE.AOTP;

  const mobileCode = mobileOne + mobileTwo + mobileThree + mobileFour;

  // Request UseForm
  const { register, getValues, watch, setError, clearErrors, errors } = useForm<SignUpAnchorUserRequest>();

  const {
    setError: setCheckIdError,
    clearErrors: clearCheckIdErrors,
    errors: checkIdErrors,
  } = useForm<{ loginId: string }>();

  const { newPassword, confirmPassword } = watch();
  const isLoginIdError = errors.loginId || checkIdErrors.loginId;

  const fetchAll = async () => {
    try {
      const [fetchedAnchorUser, fetchedFinancierClient, fetchedClientAuthSetting, fetchedTermsOfUseVersions] =
        await Promise.all([
          requestInvitedAnchorUser(code),
          requestInvitedFinancierClient(code),
          requestClientAuth(code),
          requestTermsOfUseVersions(code),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorUser(fetchedAnchorUser);
        setFinancierClient(fetchedFinancierClient);
        setClientAuthSetting(fetchedClientAuthSetting);
        setTermsOfUseVersions(fetchedTermsOfUseVersions);
        setAdmin(fetchedAnchorUser.authorityType === AUTHORITY_TYPE.ADMIN);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  // onMounted
  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const getMobileCodeClassName = (nthMobile: string) => {
    return `information-form__input spread-xs-input me-1 ${
      errors.mobileCode || (mobileInvalid && !nthMobile) ? 'error-input-border' : ''
    }`;
  };

  const onChangeMobileCode = (e: any, setMobile: React.Dispatch<React.SetStateAction<string>>) => {
    const value = e.target.value.replace(/[^\d]+/g, '');
    setMobile(value);
    if (errors.mobileCode) clearErrors('mobileCode');
  };

  const userVerificationRender = (otpType: OTP_TYPE) => {
    switch (otpType) {
      case OTP_TYPE.EMAIL:
      case OTP_TYPE.SMS:
        return emailSmsTypeForm();
      case OTP_TYPE.OTP:
      case OTP_TYPE.AOTP:
        return otpTypeForm();
      default:
        return null;
    }
  };

  const emailSmsTypeForm = () => {
    return (
      <div className="row">
        <div className="col-12">
          <label className="information-form__label">{emailType ? t('text:Email') : t('text:SMS')}</label>
          <div className="d-flex">
            <input
              className="information-form__input bg-sub200 me-2"
              type="text"
              defaultValue={emailType ? clientAuthSetting?.email : clientAuthSetting?.mobile}
              disabled
            />
            <Button onClick={sendVerificationCode} bold>
              {t('text:Get_OTP_Verification_Code')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const otpTypeForm = () => {
    return (
      <div className="row">
        <div className="col-12">
          <label className="information-form__label">{t('text:OTP_Issuer')}</label>
          <div className="d-flex">
            <input
              className="information-form__input bg-sub200"
              type="text"
              defaultValue={clientAuthSetting?.financierName}
              disabled
            />
            {aotpType && (
              <Button bold onClick={sendVerificationCode}>
                {t('text:Get_OTP_Verification_Code')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Method
  const submitRegister = async () => {
    // reset error
    setMobileInvalid(false);
    setTaxCodeInvalid(false);
    setOtpCodeInvalid(false);

    const data = getValues();

    if (!mobileCode || mobileCode.length !== 4) setMobileInvalid(true);

    if (!data.clientTaxCode) setTaxCodeInvalid(true);

    if (!data.otpCode) setOtpCodeInvalid(true);

    if (!data.loginId || !isCheckID) setCheckID(false);
    if (data.loginId && !isCheckID) return;

    try {
      if (aotpType) {
        data.queryValue = verificationRequestCode;
      }

      data.mobileCode = mobileCode;
      data.code = code;
      data.userTouDTO = {
        // 해당 페이지에서는 회원가입 시 TOU 무조건 한 개만 내려옴
        touVersionId: termsOfUseVersions[0].termsOfUseVersionId,
        financierId: termsOfUseVersions[0].relatedFinancierId,
      };

      await requestSignUpAnchorUser(data);
      history.push(ROUTES_CM.SIGN_UP_COMPLETE, {
        userId: data.loginId,
        userName: anchorUser?.name,
        userAuthority: anchorUser?.authorityType,
      });
    } catch (e) {
      modal.show(e);

      formErrorHandler<SignUpAnchorUserRequest>(e, setError, clearErrors);
      if (e instanceof HttpError) {
        if (e.code === InvalidInputValueExceptionCode.MISMATCHED_TAX_CODE)
          setError('clientTaxCode', { shouldFocus: true });
        if (e.code === InvalidInputValueExceptionCode.MISMATCHED_MOBILE_CODE)
          setError('mobileCode', { shouldFocus: true });
      }
    }
  };

  const checkId = async () => {
    setCheckID(undefined);
    try {
      const response = await requestCheckUserExistence({ loginId: getValues().loginId, code });

      if (!response) {
        modal.show(
          <h6>
            {t('text:This_ID_is_available_for_use')}
            <br />
            {t('text:Please_continue_your_sign_up_process')}
          </h6>,
        );
      } else {
        modal.show(
          <h6>
            {t('text:This_ID_is_already_in_use')}
            <br />
            {t('text:Please_type_in_another_ID_to_use')}
          </h6>,
        );
      }

      setCheckID(!response);
    } catch (error) {
      modal.show(error);
      if (!getValues('loginId')) setCheckID(false);
      formErrorHandler<{ loginId: string }>(error, setCheckIdError, clearCheckIdErrors);
    }
  };

  const sendVerificationCode = async (e: any) => {
    e.preventDefault();
    try {
      setSendCode(false);
      const response = await requestOtpSend(code);
      setVerificationRequestCode(response);

      if (aotpType) {
        const verificationRequestCode: string = response;

        modal.show(
          <div className="verification-modal-form">
            <div className="verification-request-code-form">
              <p>{t('text:Verification_Request_Code')}</p>
              <div className="code-num">{verificationRequestCode}</div>
            </div>
            <h6>
              {t('text:Please_type_the_request_code_above_into_your_A-OTP_device')}
              <br />
              {t('text:A_Verification_code_will_be_issued_from_the_device')}
              <br />
              {t('text:Enter_the_verification_code_into_the_requested_field_on_the_previous_web_page')}
            </h6>
          </div>,
        );
      } else {
        modal.show(
          <h6>
            {t('text:The_OTP_verification_code_has_been_sent')}
            <br />
            {t('text:Please_check_the_verification_code_and_enter_it_below')}
          </h6>,
        );
      }

      setSendCode(true);

      return response;
    } catch (error) {
      modal.show(error);
    }
  };

  const checkSamePassword = (newPassword: string, confirmPassword: string) => {
    return newPassword === confirmPassword;
  };

  const checkIdValidation = () => {
    if (REG_LOWERCASE_AND_NUMBER.test(getValues('loginId'))) {
      setLoginIdErrorMessage('');
    } else {
      setLoginIdErrorMessage(t('text:ID_can_only_be_used_in_lowercase_letters_and_numbers'));
    }
  };

  const handleTOUCheckBox = (e: any, termsOfUseVersionId: number) => {
    if (e.target.checked) {
      setCheckedTOUs([...checkedTOUs, termsOfUseVersionId]);
    } else {
      setCheckedTOUs(checkedTOUs.filter(item => item !== termsOfUseVersionId));
    }
  };

  return (
    <>
      <PublicHeaderBar />
      <div className="common-content pb-5" data-testid="signUpContent">
        <h1 className="signup-title">{t('text:Sign_up')}</h1>
        {clientAuthSetting?.currentAuthorityType === AUTHORITY_TYPE.HQ_OPERATOR ? (
          <GuideMessage
            message={[
              t('text:User_assigned_an_agreement_may_sign_up_on_this_page'),
              t('text:Please_proceed_with_the_verification_process_in_order_to_sign_up'),
              t('text:Please_contact_the_responsible_financier_branch_if_the_information_below_is_incorrect'),
            ]}
            isImportContentArea
          />
        ) : (
          <GuideMessage
            message={[
              t('text:Users_must_sign_up_on_this_page_to_use_the_platform'),
              <Trans
                key="key"
                i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
                components={{ 1: <span className="asterisk" /> }}
              />,
              t('text:Please_proceed_with_the_verification_process_in_order_to_sign_up'),
              t('text:Please_contact_the_responsible_financier_branch_if_the_information_below_is_incorrect'),
            ]}
            isImportContentArea
          />
        )}
        <div className="account-form mt-3">
          <div className="account-balance w-100 justify-content-around">
            <div className="account-balance-label">{t('text:Invitation_Email_Sent_by')}</div>
            <div className="account-balance-value pointfont">{clientAuthSetting?.financierName}</div>
          </div>
        </div>
        <div className="signup-radio-group">
          <RadioButton id="new-member" name="member-signup" checked={true} readOnly>
            <p className="radio-title">{t('text:New_Member')}</p>
            <p className="radio-sub-title">{t('text:Create_an_Account')}</p>
          </RadioButton>
          <RadioButton
            id="existing-member"
            name="member-signup"
            checked={false}
            onChange={() => {
              history.push({ pathname: ROUTES_CM.AC_CERTIFY, search: `?code=${code}` });
            }}
          >
            <p className="radio-title">{t('text:Existing_Member')}</p>
            <p className="radio-sub-title">{t('text:Verify_Account')}</p>
          </RadioButton>
        </div>
        <form>
          <SectionTitle title={t('text:User_Information')} className="mt-5" />
          <FormBorder editable={emailType}>
            <FormContents>
              <div className="row">
                <FormValue className="information-form__input" label={t('text:Name')} value={anchorUser?.name} />
                {!emailType ? (
                  <FormValue
                    className="information-form__input"
                    label={t('text:Mobile')}
                    value={clientAuthSetting?.mobile}
                  />
                ) : (
                  <div className="col-6" data-testid="mobileCodeSectionWhenEmail">
                    <label className="information-form__label star">{t('text:Mobile')}</label>
                    <div className="d-flex">
                      <input
                        className="information-form__input bg-sub200 me-2"
                        type="number"
                        defaultValue={clientAuthSetting?.mobile}
                        disabled
                      />
                      <input
                        className={getMobileCodeClassName(mobileOne)}
                        type="text"
                        maxLength={1}
                        onChange={e => onChangeMobileCode(e, setMobileOne)}
                        autoComplete="off"
                        value={mobileOne}
                      />
                      <input
                        className={getMobileCodeClassName(mobileTwo)}
                        type="text"
                        maxLength={1}
                        onChange={e => onChangeMobileCode(e, setMobileTwo)}
                        autoComplete="off"
                        value={mobileTwo}
                      />
                      <input
                        className={getMobileCodeClassName(mobileThree)}
                        type="text"
                        maxLength={1}
                        onChange={e => onChangeMobileCode(e, setMobileThree)}
                        autoComplete="off"
                        value={mobileThree}
                      />
                      <input
                        className={getMobileCodeClassName(mobileFour)}
                        type="text"
                        maxLength={1}
                        onChange={e => onChangeMobileCode(e, setMobileFour)}
                        autoComplete="off"
                        value={mobileFour}
                      />
                    </div>
                    {(errors.mobileCode || (mobileInvalid && mobileCode.length !== 4)) && (
                      <div className="text-brick-red m-1">
                        {t('text:The_mobile_number_is_invalid')} {t('text:Please_try_again')}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="row">
                <FormValue label={t('text:Email')} className="information-form__input" value={anchorUser?.email} />
                <FormValue
                  label={t('text:Authority')}
                  className="information-form__input"
                  value={t(`code:authority-type.${anchorUser?.authorityType}`)}
                  format="code"
                />
              </div>
            </FormContents>
          </FormBorder>

          <SectionTitle title={t('text:Company_Information')} className="mt-5" />
          <FormBorder editable={emailType}>
            <FormContents>
              <div className="row">
                <FormValue
                  className="information-form__input"
                  label={t('text:Name')}
                  value={financierClient?.financierClientName}
                />
                {isAdmin ? (
                  <div className="col-6">
                    <label id="clientTaxCode" className="information-form__label star">
                      {t('text:Tax_Code')}
                    </label>
                    <input
                      aria-labelledby="clientTaxCode"
                      className={`information-form__input ${
                        errors.clientTaxCode || taxCodeInvalid ? 'error-input-border' : ''
                      }`}
                      type="text"
                      name="clientTaxCode"
                      ref={register}
                      autoComplete="off"
                      onChange={() => {
                        setTaxCodeInvalid(false);
                        clearErrors('clientTaxCode');
                      }}
                    />
                    {(errors.clientTaxCode || taxCodeInvalid) && (
                      <div className="text-brick-red mt-1">
                        {t('text:The_tax_code_is_invalid')}. {t('text:Please_try_again')}
                      </div>
                    )}
                  </div>
                ) : (
                  <FormInput
                    label={t('text:Tax_Code')}
                    defaultValue={financierClient?.financierClientTaxCode}
                    name="clientTaxCode"
                    ref={register}
                    error={errors.clientTaxCode}
                    disabled
                  />
                )}
              </div>
              <div className="row">
                <FormValue
                  label={t('text:Company_Registration_Number')}
                  className="information-form__input"
                  value={financierClient?.financierClientBusinessCode}
                />
                <FormValue
                  col={3}
                  label={t('text:Telephone')}
                  className="information-form__input"
                  value={financierClient?.financierClientTelephone}
                />
                <FormValue
                  col={3}
                  label={t('text:Fax')}
                  className="information-form__input"
                  value={financierClient?.financierClientFax}
                />
              </div>
              <div className="row">
                <FormValue
                  label={t('text:Legal_Representative_Name')}
                  className="information-form__input"
                  value={financierClient?.representativeName}
                />
                <FormValue
                  col={3}
                  label={t('text:Legal_Representative_Title')}
                  className="information-form__input"
                  value={financierClient?.representativePosition}
                />
                <FormValue
                  col={3}
                  label={t('text:Legal_Representative_Email')}
                  className="information-form__input"
                  value={financierClient?.representativeEmail}
                />
              </div>
              <div className="row">
                <FormValue
                  col={12}
                  label={t('text:Registered_Office_Address')}
                  className="information-form__input"
                  value={financierClient?.financierClientAddress}
                />
              </div>
            </FormContents>
          </FormBorder>

          <SectionTitle title={t('text:Account_Information')} className="mt-5" />
          <FormBorder editable>
            <FormContents>
              <div className="row">
                <div className="col-6">
                  <label id="loginId" className="information-form__label star">
                    {t('text:ID')}
                  </label>
                  <div className="d-flex">
                    <input
                      aria-labelledby="loginId"
                      className={`information-form__input ${
                        isLoginIdError || isCheckID === false ? 'error-input-border' : ''
                      }`}
                      type="text"
                      name="loginId"
                      ref={register}
                      onChange={() => {
                        checkIdValidation();
                        setCheckID(undefined);
                        clearCheckIdErrors('loginId');
                        clearErrors('loginId');
                      }}
                      autoComplete="off"
                    />
                    <Button onClick={checkId} disabled={loginIdErrorMessage !== ''}>
                      {t('text:Check_ID')}
                    </Button>
                  </div>
                  {isCheckID && (
                    <div className="text-green mt-1">
                      <FontAwesomeIcon icon={faCheck} /> {t('text:Complete')}
                    </div>
                  )}
                  {isCheckID === false ? (
                    <div className="text-brick-red m-1">
                      {t('text:Please_click_on_the_Check_ID_button_to_see_whether_the_ID_is_available_for_use')}
                    </div>
                  ) : loginIdErrorMessage !== '' ? (
                    <div className="text-brick-red m-1">{loginIdErrorMessage}</div>
                  ) : isLoginIdError ? (
                    <FormErrorMessage error={isLoginIdError} />
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label id="newPassword" className="information-form__label star">
                    {t('text:Password')}
                  </label>
                  <input
                    aria-labelledby="newPassword"
                    className={`information-form__input ${errors.newPassword ? 'error-input-border' : ''}`}
                    type="password"
                    name="newPassword"
                    ref={register}
                    autoComplete="new-password"
                    onChange={() => clearErrors('newPassword')}
                  />
                  <FormErrorMessage error={errors.newPassword} />
                </div>
                <div className="col-6 position-parent">
                  <div style={{ position: 'absolute', top: '15px', width: '350px' }}>
                    <PasswordValidationBox newPassword={newPassword} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label id="confirmPassword" className="information-form__label star">
                    {t('text:Confirm_Password')}
                  </label>
                  <input
                    aria-labelledby="confirmPassword"
                    className={`information-form__input ${errors.confirmPassword ? 'error-input-border' : ''}`}
                    type="password"
                    name="confirmPassword"
                    ref={register}
                    autoComplete="new-password"
                    onChange={() => clearErrors('confirmPassword')}
                  />
                  {!checkSamePassword(newPassword, confirmPassword) ? (
                    <div className="text-brick-red mt-1">
                      <FontAwesomeIcon icon={faBan} /> {t('text:Password_do_not_match')}
                    </div>
                  ) : errors.confirmPassword ? (
                    <FormErrorMessage error={errors.confirmPassword} />
                  ) : null}
                </div>
              </div>
            </FormContents>
          </FormBorder>

          {clientAuthSetting?.otpType !== OTP_TYPE.NONE && (
            <>
              <SectionTitle title={t('text:User_Verification')} className="mt-5" />
              <FormBorder editable>
                <FormContents>
                  <div className="d-flex justify-content-center">
                    <div className="w-50">
                      {userVerificationRender(clientAuthSetting?.otpType!)}
                      <div className="row">
                        <div className="col-12">
                          <label id="otpCode" className="information-form__label star">
                            {t('text:OTP_Verification_Code')}
                          </label>
                          <div className="position-parent">
                            <input
                              aria-labelledby="otpCode"
                              className={`information-form__input ${
                                errors.otpCode || otpCodeInvalid ? 'error-input-border' : ''
                              }`}
                              type="text"
                              name="otpCode"
                              ref={register}
                              autoComplete="off"
                              onChange={() => {
                                setOtpCodeInvalid(false);
                                clearErrors('otpCode');
                              }}
                            />
                            <Timer
                              timeValue={5}
                              isRun={isSendCode}
                              onFinish={() => {
                                setSendCode(false);
                              }}
                            />
                          </div>
                          {(errors.otpCode || otpCodeInvalid) && (
                            <div className="text-brick-red mt-1">
                              {t('text:The_verification_code_is_invalid')} {t('text:Please_try_again')}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </FormContents>
              </FormBorder>
            </>
          )}
          {termsOfUseVersions?.map(termsOfUseVersion => (
            <div key={termsOfUseVersion.termsOfUseVersionId} className="TOU-check-wrap">
              <input
                id={String(termsOfUseVersion.termsOfUseVersionId)}
                className="form-check-input"
                type="checkbox"
                checked={checkedTOUs.includes(termsOfUseVersion.termsOfUseVersionId)}
                onChange={e => handleTOUCheckBox(e, termsOfUseVersion.termsOfUseVersionId)}
              />
              <Trans
                i18nKey={
                  termsOfUseVersion.enterpriseType === ENTERPRISE_TYPE.SY
                    ? 'text:I_have_read_and_agree_to_the_Fin2B_Supply_Chain_Financing_Platform_s_Terms_and_Conditions_of_Use'
                    : 'text:I_have_read_and_agree_to_financierName_s_Terms_and_Conditions_of_Use'
                }
                components={{
                  1: <label className="me-1" htmlFor={String(termsOfUseVersion.termsOfUseVersionId)} />,
                  2: (
                    <a
                      className="link-text-color"
                      href={`${process.env.REACT_APP_BASE_URL}${API_AN.TERMS_OF_USE_VERSIONS.DOWNLOAD(
                        termsOfUseVersion.termsOfUseVersionId,
                      )}?type=INLINE`}
                      target={`tou-${termsOfUseVersion.termsOfUseVersionId}`}
                    />
                  ),
                }}
                values={
                  termsOfUseVersion.enterpriseType === ENTERPRISE_TYPE.SY
                    ? undefined
                    : { financierName: termsOfUseVersion.relatedFinancierName }
                }
              />
            </div>
          ))}
          <div className="flex-end mt-5">
            <Button
              size={ButtonSizeEnum.LG}
              disabled={checkedTOUs.length !== termsOfUseVersions?.length}
              onClick={submitRegister}
            >
              {t('text:Register')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AnchorSignUp;
