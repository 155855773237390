import type { RefObject } from 'react';
import { useRef } from 'react';

const defaultOptions: IntersectionObserverInit = {
  threshold: 0.3,
};

type useIntersectionObserverPropsType<T> = {
  target: RefObject<T>;
  callback: () => void;
  customOptions?: IntersectionObserverInit;
};

const useIntersectionObserver = <T extends Element>({
  target,
  callback,
  customOptions,
}: useIntersectionObserverPropsType<T>) => {
  const observer = useRef<IntersectionObserver>();

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        callback();
      }
    });
  };

  const observe = () => {
    if (target.current) {
      const option = {
        ...defaultOptions,
        customOptions,
      };

      observer.current = new IntersectionObserver(handleIntersection, option);
      observer.current.observe(target.current);
    }
  };

  const disconnect = () => observer.current && observer.current.disconnect();

  return [observe, disconnect];
};

export default useIntersectionObserver;
