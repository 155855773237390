import { languageTextEnum } from 'enums';
import { languageType } from 'enums';
import { setLanguage } from 'utils/storage/LocalStorage';

import i18n from './i18n';

export const LANGUAGE_SELECT_OPTIONS: { text: languageTextEnum; value: languageType }[] = [
  { text: languageTextEnum.EN_USA, value: languageType.EN_USA },
  { text: languageTextEnum.EN_INDIA, value: languageType.EN_INDIA },
  { text: languageTextEnum.VN, value: languageType.VN },
];

export const handleLanguageSelectChange = (language: languageType) => {
  setLanguage(language);
  i18n.changeLanguage(language);
};

export const getLanguageText = (language: languageType) => {
  switch (language) {
    case languageType.EN_INDIA:
      return languageTextEnum.EN_INDIA;
    case languageType.EN_USA:
      return languageTextEnum.EN_USA;
    case languageType.VN:
      return languageTextEnum.VN;
    default:
      throw new Error(`Unsupported language: ${language}`);
  }
};
