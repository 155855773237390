import { COLLATERAL_TYPE, CURRENCY_TYPE, FINANCIAL_SETTING_TYPE, stringToEnum } from 'enums';

export interface OverdueInterestRateSettingVO {
  id: number;
  activated: boolean;
  settingType: string;
  currencyType: string;
  collateralType: string;
  financierId: number;
  financierName: string;
  anchorAgreementId: number;
  anchorAgreementContractNo: string;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  dealerAgreementId: number;
  dealerAgreementContractNo: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerClientCode: string;
  currentTermSpreadGroupId: number;
  currentCostOfFundId: number;
}

export interface OverdueInterestRateSettingVOModel extends OverdueInterestRateSettingVO {
  settingType: FINANCIAL_SETTING_TYPE;
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
}

export function formattingToOverdueInterestRateSettingVOModel(
  data: OverdueInterestRateSettingVO,
): OverdueInterestRateSettingVOModel {
  return {
    ...data,
    settingType: stringToEnum(FINANCIAL_SETTING_TYPE, data.settingType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
  };
}
