import type Pageable from 'models/Pageable';
import type { FinancierDocumentFormVO, FinancierDocumentFormVOModel } from 'models/vo/FinancierDocumentFormVO';
import { formattingToFinancierDocumentFormVOModel } from 'models/vo/FinancierDocumentFormVO';
import http, { ContentType } from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FinancierDocumentListRequest,
  FinancierDocumentRegisterRequest,
  FinancierUpdateDocumentFormRequest,
} from './request';

export async function requestFinancierDocumentList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierDocumentListRequest,
): Promise<Pageable<FinancierDocumentFormVOModel[]>> {
  const response = await http.get<Pageable<FinancierDocumentFormVO[]>>({
    url: API_FI.FINANCIER_DOCUMENT_FORMS.DOCUMENT_FORMS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToFinancierDocumentFormVOModel(item)),
  };
}

export async function requestFinancierDocumentDetail(
  financierDocumentFormId: number,
): Promise<FinancierDocumentFormVOModel> {
  const response = await http.get<FinancierDocumentFormVO>({
    url: API_FI.FINANCIER_DOCUMENT_FORMS.DOCUMENT_DETAIL(financierDocumentFormId),
  });

  return formattingToFinancierDocumentFormVOModel(response);
}

export async function requestFinancierRegisterDocument(
  data: FinancierDocumentRegisterRequest,
): Promise<FinancierDocumentFormVOModel> {
  const response = await http.post<FinancierDocumentFormVO>({
    url: API_FI.FINANCIER_DOCUMENT_FORMS.DOCUMENT_FORMS,
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToFinancierDocumentFormVOModel(response);
}

export async function requestFinancierUpdateDocument(
  financierDocumentFormId: number,
  data: FinancierUpdateDocumentFormRequest,
): Promise<FinancierDocumentFormVOModel> {
  const response = await http.put<FinancierDocumentFormVO>({
    url: API_FI.FINANCIER_DOCUMENT_FORMS.DOCUMENT_DETAIL(financierDocumentFormId),
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToFinancierDocumentFormVOModel(response);
}

export async function requestFinancierDownloadDocument(financierDocumentFormId: number) {
  await http.download(API_FI.FINANCIER_DOCUMENT_FORMS.DOWNLOAD_ATTACHMENT(financierDocumentFormId));
}
