import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { requestSystemEnterpriseList } from 'utils/http/api/system/enterprises';
import useModal from 'utils/modal/useModal';

import Select from '../../Select/Select';

interface selectedDataProps {
  modalId: number;
  getSelectedData(data: EnterpriseVOModel): void;
}

function SearchFinancierModal({ modalId, getSelectedData }: selectedDataProps) {
  const mounted = useMounted();
  const { t } = useTranslation();
  const { pageable, setPageable } = usePageable();
  const modal = useModal();

  const { register, getValues } = useForm<{ selectedKey: string; selectedValue: string }>();

  const [enterPriseData, setEnterPriseData] = useState<Pageable<EnterpriseVOModel[]>>();

  useEffect(() => {
    if (mounted) {
      fetchSyFinancierList(pageable.currentPage, pageable.sizePerPage);
    }
  }, [mounted]);

  const fetchSyFinancierList = async (pageNumber: number = 1, sizePerPage: number = 10) => {
    const enterpriseResponse = await requestSystemEnterpriseList(pageNumber, sizePerPage, {
      enterpriseType: ENTERPRISE_TYPE.FI,
      [getValues().selectedKey]: getValues().selectedValue,
    });
    ReactDOM.unstable_batchedUpdates(() => {
      setEnterPriseData(enterpriseResponse);
      setPageable(enterpriseResponse);
    });
  };

  const onFinancierSelect = (e: any, item: EnterpriseVOModel) => {
    e.preventDefault();
    getSelectedData({ ...item });
    modal.close(modalId);
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchSyFinancierList(page, sizePerPage);
  };

  const renderResultTable = () => {
    return enterPriseData?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.enterpriseName} />
          <Td data={item.businessCode} />
          <Td data={item.taxCode} />
          <Td data={item.telephone} />
          <Td data={item.address} />
          <Td className="information-table-more">
            <Button bold onClick={e => onFinancierSelect(e, { ...item })}>
              {t('text:Select')}
            </Button>
          </Td>
        </Tr>
      );
    });
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 140,
    },
    {
      headerText: t('text:Financier_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Tax_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 170,
    },
    {
      headerText: '',
      colWidths: 65,
    },
  ];

  return (
    <>
      <div className="d-flex information-filter mb-3">
        <Select
          name="selectedKey"
          selectOptions={[
            { label: t('text:Financier_Name'), value: 'enterpriseName' },
            { label: t('text:Financier_Code'), value: 'businessCode' },
            { label: t('text:Tax_Code'), value: 'taxCode' },
          ]}
          className="information-form__select"
          ref={register}
        />
        <input type="text" name="selectedValue" ref={register} placeholder={t('text:Search…')} />
        <Button type="submit" size={ButtonSizeEnum.XS} onClick={() => fetchSyFinancierList()}>
          {t('text:Search')}
        </Button>
      </div>
      <TableBorder>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </>
  );
}

export default SearchFinancierModal;
