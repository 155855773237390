import { initReactI18next } from 'react-i18next';

import dayjs from 'dayjs';
import i18n from 'i18next';

// import en file
import { languageType } from 'enums';
import formatTranslationEN_INDIA from 'locales/translation/en-india/format.json';
import codeTranslationEN from 'locales/translation/en_usa/code.json';
import exceptionTranslationEN from 'locales/translation/en_usa/exception.json';
import formatTranslationEN from 'locales/translation/en_usa/format.json';
import textTranslationEN from 'locales/translation/en_usa/text.json';
import codeTranslationVN from 'locales/translation/vn/code.json';
import exceptionTranslationVN from 'locales/translation/vn/exception.json';
import formatTranslationVN from 'locales/translation/vn/format.json';
import textTranslationVN from 'locales/translation/vn/text.json';
import { getLanguage } from 'utils/storage/LocalStorage';

import { localeNumberFormatter } from './localeNumberFormatter';

import type { TOptions } from 'i18next';

const resources = {
  // language : {
  //   namespace : JSON FIle
  // }
  en_usa: {
    code: codeTranslationEN,
    format: formatTranslationEN,
    exception: exceptionTranslationEN,
    text: textTranslationEN,
  },
  en_india: {
    code: codeTranslationEN,
    exception: exceptionTranslationEN,
    text: textTranslationEN,
    format: formatTranslationEN_INDIA,
  },
  vn: {
    code: codeTranslationVN,
    exception: exceptionTranslationVN,
    text: textTranslationVN,
    format: formatTranslationVN,
  },
};

const nsSeparator = ':';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use({
    type: 'postProcessor',
    name: 'formatProcessor',
    process: function (format: string, key: string, options: TOptions) {
      // ns로 구분
      const namespace = key[0].split(nsSeparator, 2);
      if (namespace[0] === 'format') {
        if (namespace[1] === 'number') {
          return localeNumberFormatter(options.value);
        } else if (options.key) {
          return options.value ? dayjs(options.value).format(format) : '-';
        } else {
          return format;
        }
      } else return format;
    },
  })
  .init({
    resources,
    ns: ['code', 'exception', 'format', 'text', 'TOU'],
    lng: getLanguage() || languageType.EN_USA,
    fallbackLng: languageType.EN_USA,
    keySeparator: '.',
    nsSeparator: nsSeparator,
    postProcess: ['formatProcessor'],
    interpolation: {
      // allows dynamic values into translations
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
