import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { PageableType } from 'hooks/usePageable';
import usePageable from 'hooks/usePageable';
import type { LoanVOModel } from 'models/vo/LoanVO';
import { requestFinancierLoanList } from 'utils/http/api/financier/loans';
import useModal from 'utils/modal/useModal';

type FinancingListStateType = {
  financingList?: LoanVOModel[];
  fetchFinancingList: (dealerAgreementId: number, pageNumber?: number, rowCount?: number) => Promise<void>;
  financingListPageable: PageableType;
};

const FinancingListContext = createContext<FinancingListStateType | null>(null);

export const useFinancingListContext = () => {
  const context = useContext(FinancingListContext);
  if (!context) {
    throw Error('useFinancingListContext should be used within FinancingListContext.Provider');
  }

  return context;
};

type FinancingListProviderPropsType = {
  children: ReactNode;
};

const FinancingListProvider = ({ children }: FinancingListProviderPropsType) => {
  const { show: showModal } = useModal();

  const [financingList, setFinancingList] = useState<LoanVOModel[]>();
  const { pageable: financingListPageable, setPageable: setFinancingListPageable } = usePageable();

  const fetchFinancingList = useCallback(
    async (dealerAgreementId: number, pageNumber: number = 0, rowCount: number = 10) => {
      try {
        const loanList = await requestFinancierLoanList(pageNumber, rowCount, { dealerAgreementId });
        setFinancingList([...loanList.content]);
        setFinancingListPageable(loanList);
      } catch (error) {
        showModal(error);
      }
    },
    [setFinancingListPageable, showModal],
  );

  const value = useMemo(
    () => ({
      financingList,
      fetchFinancingList,
      financingListPageable,
    }),
    [fetchFinancingList, financingList, financingListPageable],
  );

  return <FinancingListContext.Provider value={value}>{children}</FinancingListContext.Provider>;
};

export default FinancingListProvider;
