import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faMinus, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { values } from 'lodash-es';

import PaymentInformationAccordion from 'components/stateless/Accordion/PaymentInformationAccordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder, FormContents } from 'components/stateless/CommonForm';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import InvoiceAmountExceedModal from 'components/stateless/Modal/common/invoice/InvoiceAmountExceedModal';
import InvoiceRegisterCancelModal from 'components/stateless/Modal/common/invoice/InvoiceRegisterCancelModal';
import InvoiceRegistrationModalComplete from 'components/stateless/Modal/common/invoice/InvoiceRegistrationModalComplete';
import InvoiceRegistrationModalNew from 'components/stateless/Modal/common/invoice/InvoiceRegistrationModalNew';
import ShowPaymentDateGuideModal from 'components/stateless/Modal/common/invoice/ShowPaymentDateGuideModal';
import ValidationErrorModal from 'components/stateless/Modal/common/invoice/ValidationErrorModal';
import QuestionButton from 'components/stateless/QuestionButton';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import AdditionalColumnNameList from 'components/stateless/Table/AdditionalColumnNameList';
import AdditionalDataList from 'components/stateless/Table/AdditionalDataList';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import type { CURRENCY_TYPE } from 'enums';
import { LOAN_OPTION } from 'enums';
import { AUTHORITY_TYPE, LOAN_LIMIT_CHECK_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { FiscalYearSettingVOModel } from 'models/vo/FiscalYearSettingVO';
import type { LastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import type { RegistrableLimitVOModel } from 'models/vo/RegistrableLimitVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import type { BoeTableStructure } from 'utils/boe';
import calculateBOEInformation from 'utils/boe';
import { getSum } from 'utils/calculate';
import { convertToServerDateFormat, getDayTerm, removeRangeFromHoliday } from 'utils/date/date';
import { requestAnchorClientAuthByDealerAgreementId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestAnchorAgreementRelatedDealerAgreement,
  requestAnchorDealerAgreementDetail,
} from 'utils/http/api/anchor/dealer-agreements';
import { requestAnchorFinancierCalendar } from 'utils/http/api/anchor/financier-calendar';
import { requestAnchorInvoiceRegister } from 'utils/http/api/anchor/invoice-summaries';
import type {
  AnchorInvoiceRegisterInvoiceListRequest,
  AnchorInvoiceRegisterRequest,
  InvoiceAdditionalData,
} from 'utils/http/api/anchor/invoice-summaries/requests';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestFiscalYearSettingCurrentUser } from 'utils/http/api/common/fiscal-year-setting';
import { requestRegistrableLimitByDealerAgreement } from 'utils/http/api/common/registrable-limit';
import {
  requestTempInvoiceSummaryData,
  requestTempInvoiceSummaryRegister,
} from 'utils/http/api/common/temp-invoice-summaries';
import { initializeRefValue } from 'utils/initialize';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { invoiceOrArAmountInputValidate } from 'utils/logic';
import {
  INVOICE_PAYMENT_TO_DATE_RANGE,
  calculateInvoiceFinancingBalance,
  calculateInvoiceRemainingLimit,
  getInitialSettlementDateFunction,
  invoiceDuplicateValidator,
  isExceedInvoiceAmountWhenRegistration,
  referenceNumberDuplicateValidator,
  repaymentDateSameValidator,
  setEnteredSettlementDate,
} from 'utils/logic/invoice';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { handleScrollLeft } from 'utils/scroll';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';
import useValidation from 'utils/validation/useValidation';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import type { TFunction } from 'i18next';

dayjs.extend(customParseFormat);

const MAX_ROW_LENGTH = 100;
const NUMBER_OF_INVOICE_ADDITIONAL_COLUMNS = 10;

export const getFieldNames = (
  t: TFunction,
  options?: {
    currencyType?: CURRENCY_TYPE;
    hasQuestionButton?: boolean;
  },
) => {
  return {
    billOfExchangeNo: {
      name: t('text:BOE_Number'),
      width: '180px',
      hasQuestionButton: false,
    },
    invoiceNumber: {
      name: t('text:Invoice_Number'),
      width: '180px',
      hasQuestionButton: false,
    },
    referenceNumber: {
      name: t('text:Invoice_Reference_Number'),
      width: '180px',
      hasQuestionButton: false,
    },
    invoiceIssuedDate: {
      name: t('text:Invoice_Issued_Date'),
      width: '180px',
      hasQuestionButton: false,
    },
    settlementDate: {
      name: t('text:Payment_Date'),
      width: '180px',
      hasQuestionButton: options?.hasQuestionButton,
    },
    invoiceAmount: {
      name: `${t('text:Invoice_Amount')} (${t('text:unit')}: ${options?.currencyType ?? ''})`,
      width: '180px',
      hasQuestionButton: false,
    },
  };
};

function AnchorInvoiceRegistrationByPartnerStep2() {
  const { dealerAgreementId } = useParams() as any;
  const { financierId } = useParams() as any;
  const { show: showModal, id: modalId } = useModal();
  const history = useHistory();
  const mounted = useMounted();
  const { t } = useTranslation(['format']);

  const [dealerAgreementData, setDealerAgreementData] = useState<DealerAgreementDetailVOModel>();
  const [registrableLimit, setRegistrableLimit] = useState<RegistrableLimitVOModel>();
  const [financierCommonSettingData, setFinancierCommonSettingData] = useState<FinancierCommonSettingVOModel>();
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회
  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementVOModel>();
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);

  const [isDirectInput, setIsDirectInput] = useState(true);
  const [file, setFile] = useState<File>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [isSavedFields, setIsSavedFields] = useState<boolean>(false); // register, save 유효성 검사 구분 짓기 위한 state
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState<BigNumber>('0');
  const [isExceedAmount, setIsExceedAmount] = useState<boolean>(false);
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();
  const [holidayLists, setHolidayLists] = useState<string[]>();
  const [fiscalYearSettingData, setFiscalYearSettingData] = useState<FiscalYearSettingVOModel | null>(null);
  const [boeTableData, setBoeTableData] = useState<BoeTableStructure[]>([]);
  const [visibleAppendColumnBtn, setVisibleAppendColumnBtn] = useState(false);
  const [maximumColumn, setMaximumColumn] = useState(false);
  const [minDate, setMinDate] = useState<Date>();

  const invoiceRegisterTableRef = useRef<HTMLDivElement>(null);
  const prevColumnListLength = useRef(0);

  const calculatorBigNumber = useMemo(() => new CalculatorBigNumber(), []);

  const boeTableHeaders: HeaderType[] = [
    {
      headerText: t('text:BOE_Number'),
      className: 'text-left',
    },
    {
      headerText: t('text:Payment_Date'),
      className: 'text-left',
    },
    {
      headerText: t('text:Payment_Amount'),
      className: 'text-left',
    },
  ];

  const date = new Date();
  const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];

  const todayDate = new Date(today[0], today[1], today[2]);
  const endDate = new Date(today[0], today[1], today[2] + INVOICE_PAYMENT_TO_DATE_RANGE);
  const tomorrowDate = new Date(today[0], today[1], today[2] + 1);

  const { register, control, errors, getValues, setValue, reset, trigger, watch } =
    useForm<AnchorInvoiceRegisterRequest>({
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
    });

  const { fields, remove } = useFieldArray<AnchorInvoiceRegisterInvoiceListRequest>({
    control,
    name: 'invoiceList',
  });

  const watchedAdditionalColumnNameList = watch('additionalColumnNameList');
  const watchedInvoiceList = watch('invoiceList');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchedInvoiceList[index],
    };
  });

  const fieldNames = getFieldNames(t, {
    currencyType: dealerAgreementData?.currencyType,
    hasQuestionButton: financierCommonSettingData?.invoiceWillBeSettledOnUploadDate,
  });

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: errors.invoiceList,
    rule: 'invoiceList',
    isSavedFields,
  });

  useEffect(() => {
    if ((watchedAdditionalColumnNameList ?? []).length > 9) {
      setMaximumColumn(true);
      prevColumnListLength.current = watchedAdditionalColumnNameList?.length ?? 0;

      return;
    }
    if (prevColumnListLength.current === 10 && watchedAdditionalColumnNameList?.length === 9) {
      setMaximumColumn(false);
    }
    if (watchedAdditionalColumnNameList?.length === 0) {
      setMaximumColumn(false);
    }
  }, [watchedAdditionalColumnNameList]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const updateTotalInvoiceAmount = useCallback(() => {
    const sum = getSum(getValues().invoiceList, 'invoiceAmount');
    setTotalInvoiceAmount(sum);
  }, [getValues]);

  useEffect(() => {
    updateTotalInvoiceAmount();
  }, [fields, updateTotalInvoiceAmount]);

  const fetchAll = async (): Promise<void> => {
    let initialSettlementDate: string | undefined = undefined;

    try {
      const [
        savedInvoiceList,
        dealerAgreementDetailData,
        financierCommonSettingResponse,
        fetchRegistrableLimit,
        anchorAgreementData,
        fiscalYearSettingResponse,
      ] = await Promise.all([
        requestTempInvoiceSummaryData(dealerAgreementId),
        requestAnchorDealerAgreementDetail(dealerAgreementId),
        requestFinancierSettingData(financierId),
        requestRegistrableLimitByDealerAgreement(dealerAgreementId),
        requestAnchorAgreementRelatedDealerAgreement(dealerAgreementId),
        requestFiscalYearSettingCurrentUser(),
      ]);
      const fromDate = financierCommonSettingResponse?.settlementDateIsTodayInvoiceUploadable
        ? todayDate
        : tomorrowDate;

      const fetchFinancierHoliday = await requestAnchorFinancierCalendar(financierId, {
        pageNumber: 0,
        rowCount: Number(getDayTerm(convertToServerDateFormat(fromDate), convertToServerDateFormat(endDate))) + 1,
        fromDate: convertToServerDateFormat(fromDate),
        toDate: convertToServerDateFormat(endDate),
        holiday: true,
      });

      const getHolidayArrays = fetchFinancierHoliday.content.map(item => item.solarDate);

      initialSettlementDate = getInitialSettlementDateFunction(
        getHolidayArrays,
        financierCommonSettingResponse,
        todayDate,
        tomorrowDate,
        endDate,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setHolidayLists(getHolidayArrays);
        setDealerAgreementData(dealerAgreementDetailData);
        setInitialSettlementDate(initialSettlementDate);
        setFinancierCommonSettingData(financierCommonSettingResponse);
        setFinancierHoliday(fetchFinancierHoliday);
        setAnchorAgreementData(anchorAgreementData);
        setRegistrableLimit(fetchRegistrableLimit);
        setFiscalYearSettingData(fiscalYearSettingResponse);
        setMinDate(fromDate);

        const tempInvoiceListContent = savedInvoiceList.tempInvoiceList.content;
        if (tempInvoiceListContent.length !== 0) {
          reset({
            invoiceList: tempInvoiceListContent.map(item => {
              return {
                invoiceNumber: item.invoiceNumber,
                invoiceIssuedDate: item.invoiceIssuedDate,
                settlementDate: setEnteredSettlementDate(
                  getHolidayArrays,
                  item.settlementDate,
                  initialSettlementDate,
                  financierCommonSettingResponse,
                ),
                invoiceAmount: item.invoiceAmount,
                referenceNumber: item.referenceNumber,
                billOfExchangeNo: item.billOfExchangeNo,
                invoiceAdditionalDataList: item.invoiceAdditionalDataList ?? [],
              };
            }),
            additionalColumnNameList: tempInvoiceListContent[0].invoiceAdditionalDataList?.map(item => ({
              name: item.name,
            })),
          });
        } else {
          reset({
            invoiceList: [
              {
                invoiceNumber: undefined,
                invoiceIssuedDate: undefined,
                settlementDate: initialSettlementDate,
                invoiceAmount: undefined,
                referenceNumber: undefined,
                billOfExchangeNo: undefined,
              },
            ],
          });
        }
      });
    } catch (error) {
      showModal(error);
    }
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const arr: number[] = [];
      fields.forEach((el, index) => arr.push(index));
      setCheckedRows(arr);
    } else {
      setCheckedRows([]);
    }
  };

  const handleCheckChange = (e: any, index: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const appendRow = (e: any) => {
    e.preventDefault();
    if (fields.length >= MAX_ROW_LENGTH) {
      showModal(
        <h6>
          {t('text:Allowed_N_rows_at_a_time', { number: MAX_ROW_LENGTH })} {t('text:Please_check_the_data_again')}
        </h6>,
      );
    } else {
      const invoiceAdditionalDataList: AnchorInvoiceRegisterInvoiceListRequest['invoiceAdditionalDataList'] =
        getValues().invoiceList?.[0]?.invoiceAdditionalDataList?.map(() => ({ value: '', name: '' }));

      setValue('invoiceList', [
        ...getValues().invoiceList,
        {
          billOfExchangeNo: undefined,
          invoiceNumber: undefined,
          referenceNumber: undefined,
          invoiceIssuedDate: undefined,
          settlementDate: initialSettlementDate,
          invoiceAmount: undefined,
          invoiceAdditionalDataList,
        },
      ]);
    }
  };

  const appendColumn = () => {
    const { invoiceList, additionalColumnNameList } = getValues();

    const appendAdditionalData = invoiceList?.map(invoiceItem => ({
      ...invoiceItem,
      invoiceAdditionalDataList: (invoiceItem.invoiceAdditionalDataList ?? []).concat({ value: '', name: '' }),
    }));
    const appendColumnNameList = [...(additionalColumnNameList ?? []), { name: '' }];

    setValue('invoiceList', appendAdditionalData);
    setValue('additionalColumnNameList', appendColumnNameList);

    handleScrollLeft(invoiceRegisterTableRef, invoiceRegisterTableRef?.current?.scrollWidth);
  };

  const removeRow = (e: any) => {
    e.preventDefault();
    const upDateInValidState = () => {
      let i = 0;
      const temp = [...checkedValidation];

      for (const index of checkedRows) {
        temp.splice(index - i, 1);
        i++;
      }

      return temp;
    };
    if (checkedRows.length === fields.length) {
      reset({
        invoiceList: [
          {
            invoiceNumber: undefined,
            invoiceIssuedDate: undefined,
            settlementDate: initialSettlementDate,
            invoiceAmount: undefined,
            referenceNumber: undefined,
            billOfExchangeNo: undefined,
          },
        ],
      });
      setCheckedValidation([]);
    } else {
      setCheckedValidation(upDateInValidState());
      remove(checkedRows);
    }

    setCheckedRows([]);
  };

  const removeColumn = (columnIndex: number) => {
    const prevScrollLeft = invoiceRegisterTableRef?.current?.scrollLeft ?? 0;

    const { invoiceList, additionalColumnNameList } = getValues();

    const removeAdditionalData = invoiceList.map(invoiceItem => ({
      ...invoiceItem,
      invoiceAdditionalDataList: invoiceItem.invoiceAdditionalDataList?.filter((_, index) => index !== columnIndex),
    }));
    const removeColumnNameList = additionalColumnNameList?.filter((_, index) => index !== columnIndex);

    setValue('invoiceList', removeAdditionalData);
    setValue('additionalColumnNameList', removeColumnNameList);

    handleScrollLeft(invoiceRegisterTableRef, prevScrollLeft);
  };

  const visibleAppendColumnButton = () => {
    setVisibleAppendColumnBtn(true);
  };

  const invisibleAppendColumnButton = () => {
    setVisibleAppendColumnBtn(false);
  };

  const onClickRegistrationMethodRadioButton = (value: boolean) => {
    if (value !== isDirectInput) {
      setIsDirectInput(value);
    }
  };

  const mappingAdditionalColumnNameAndValue = () => {
    const { additionalColumnNameList, invoiceList } = getValues();

    return invoiceList?.map(invoiceItem => ({
      ...invoiceItem,
      invoiceAdditionalDataList: invoiceItem?.invoiceAdditionalDataList?.map((item, index) => ({
        ...item,
        name: additionalColumnNameList?.[index].name ?? '',
      })),
    }));
  };

  const getRequestValues = () => {
    const { additionalColumnNameList } = getValues();

    const noInvoiceAdditionalDataList = (additionalColumnNameList?.length ?? 0) === 0;
    if (noInvoiceAdditionalDataList) return getValues();

    const mappedAdditionalData = mappingAdditionalColumnNameAndValue();

    return { ...getValues(), invoiceList: mappedAdditionalData };
  };

  const triggerValidationForAdditionalColumnName = async () => {
    if (!watchedAdditionalColumnNameList) return;
    let validationResult = true;

    for (let i = 0; i < watchedAdditionalColumnNameList?.length; i++) {
      const isPassedRequiredValidationForAdditionalColumnName = await trigger(`additionalColumnNameList[${i}].name`);
      if (!isPassedRequiredValidationForAdditionalColumnName) {
        validationResult = false;
      }
    }
    if (!validationResult) showModal(<h6>{t('text:Enter_the_names_for_all_of_the_added_columns')}</h6>);

    return validationResult;
  };

  const onClickDirectInput = async (e: any) => {
    e.preventDefault();
    setIsSavedFields(false);

    const verificationCode: UserVerificationCodeRequest = {};

    const data = getValues();
    setIsExceedAmount(false);
    const executeRegister = () => {
      return new Promise<void>(resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1);
      }).then(async () => {
        if (!(await triggerValidationForAdditionalColumnName())) {
          unShowLoadingUI();

          return;
        }

        await trigger().then(result => {
          setCheckedValidation(data.invoiceList.map(() => true));
          unShowLoadingUI();
          if (!result) {
            showModal(<ValidationErrorModal />, {
              modalSize: ModalSize.NONE,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:Close'),
            });

            return; // FE validation error 있으면 return
          }
          //  validation #2. total amount check
          else if (
            dealerAgreementData?.loanLimitCheckType !== LOAN_LIMIT_CHECK_TYPE.LOAN &&
            isExceedInvoiceAmountWhenRegistration(dealerAgreementData, totalInvoiceAmount)
          ) {
            setIsExceedAmount(true);
            showModal(<InvoiceAmountExceedModal />, {
              modalSize: ModalSize.NONE,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:Close'),
            });
          } else {
            if (data)
              showModal(
                <InvoiceRegistrationModalNew
                  isBoeNumberShow={true}
                  dataList={data}
                  totalCount={fields.length}
                  totalAmount={totalInvoiceAmount}
                  currencyType={dealerAgreementData?.currencyType}
                />,
                {
                  modalSize: ModalSize.XL,
                  modalType: ModalType.CONFIRM,
                  closeBtnText: t('text:Cancel'),
                  confirmBtnText: t('text:Confirm'),
                  confirmBtnCb: async () => {
                    try {
                      const clientAuthTypeData = await requestAnchorClientAuthByDealerAgreementId(dealerAgreementId);
                      if (
                        (clientAuthTypeData.currentAuthorityType === AUTHORITY_TYPE.ADMIN ||
                          clientAuthTypeData.currentAuthorityType === AUTHORITY_TYPE.AUTHORIZER) &&
                        clientAuthTypeData.otpType !== OTP_TYPE.NONE
                      ) {
                        showModal(
                          <UserVerificationModal
                            modalId={modalId}
                            verificationCode={verificationCode}
                            requestIdType="dealerAgreementId"
                            requestId={dealerAgreementId}
                            clientAuthSetting={clientAuthTypeData}
                          />,
                          {
                            modalSize: ModalSize.NONE,
                            modalType: ModalType.CONFIRM,
                            closeBtnText: t('text:Close'),
                            confirmBtnText: t('text:Confirm'),
                            confirmBtnCb: () => onClickConfirm(),
                          },
                        );
                      } else {
                        onClickConfirm();
                      }
                    } catch (error) {
                      showModal(error);
                    }
                  },
                },
              );
          }
        });
      });
    };

    await executeRegister();

    const showInvoiceRegistrationResultModal = (
      anchorInvoiceRegisterData: LastInvoicePhaseVOModel[],
      count: number,
      amount: BigNumber,
    ) => {
      showModal(
        <InvoiceRegistrationModalComplete
          isBoeNumberShow={true}
          dataList={anchorInvoiceRegisterData}
          totalCount={count}
          totalAmount={amount}
          currencyType={dealerAgreementData?.currencyType}
        />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_AC.REGISTER_INVOICE.REGISTRATION_BY_PARTNER_STEP1),
        },
      );
    };

    const onClickConfirm = async (): Promise<void> => {
      const requestedData = {
        ...getRequestValues(),
        dealerAgreementId: Number(dealerAgreementId),
        otpCode: verificationCode.otpCode,
        queryValue: verificationCode.queryValue,
      };

      try {
        const anchorInvoiceRegisterData = await requestAnchorInvoiceRegister(requestedData);
        showInvoiceRegistrationResultModal(
          anchorInvoiceRegisterData,
          anchorInvoiceRegisterData.reduce((acc, cur) => acc + cur.count, 0),
          anchorInvoiceRegisterData.reduce((acc, cur) => calculatorBigNumber.add(acc).add(cur.amount).get(), '0'),
        );
      } catch (error) {
        showModal(error);
      }
    };
  };

  const onChangeUploadExcel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const getInvoiceAdditionalDataList = (data: any, isEmptyColumnObj: { [index: number]: boolean }) => {
      const invoiceAdditionalDataList: InvoiceAdditionalData[] = [];

      for (let columnIndex = 0; columnIndex < NUMBER_OF_INVOICE_ADDITIONAL_COLUMNS; columnIndex++) {
        const invoiceAdditionalCellData = data[`${ExcelTemplatesHeader.INVOICE_DETAIL} (${columnIndex + 1})`];
        const isEmptyColumn = isEmptyColumnObj[columnIndex];

        if (!isEmptyColumn) {
          if (invoiceAdditionalCellData) {
            invoiceAdditionalDataList.push({ name: '', value: invoiceAdditionalCellData });
          } else {
            invoiceAdditionalDataList.push({ name: '', value: '' });
          }
        }
      }

      return invoiceAdditionalDataList;
    };

    const formattedData = (data: any, isEmptyColumnObj: { [index: number]: boolean }) => {
      const formattedSettlementDate = t('format:original-date', {
        value: String(data[ExcelTemplatesHeader.PAYMENT_DATE]),
        key: 'no-line-date',
      });
      const formattedIssuedDate = t('format:original-date', {
        value: String(data[ExcelTemplatesHeader.INVOICE_ISSUED_DATE]),
        key: 'no-line-date',
      });
      const invoiceAdditionalDataList = getInvoiceAdditionalDataList(data, isEmptyColumnObj);

      return {
        invoiceNumber: data[ExcelTemplatesHeader.INVOICE_NUMBER],
        referenceNumber: data[ExcelTemplatesHeader.INVOICE_REFERENCE_NUMBER],
        // TODO: date valid check 를 datePicker component 에서 가능하도록..
        invoiceIssuedDate: dayjs(formattedIssuedDate, 'YYYY-MM-DD', true).isValid() ? formattedIssuedDate : undefined,
        settlementDate: setEnteredSettlementDate(
          holidayLists,
          formattedSettlementDate,
          initialSettlementDate,
          financierCommonSettingData,
        ),
        invoiceAmount: data[ExcelTemplatesHeader.INVOICE_AMOUNT],
        billOfExchangeNo: data[ExcelTemplatesHeader.BILL_OF_EXCHANGE_NUMBER],
        invoiceAdditionalDataList,
      };
    };

    if (e.target.files && e.target.files[0]) {
      const isCsvFile = e.target.files[0].type === 'text/csv';

      try {
        showLoadingUI();
        setCheckedValidation([]);
        setFile(e.target.files[0]);

        const parsedJsonArray = isCsvFile
          ? await csvToJson(e.target.files[0])
          : await excelToJson(e.target.files[0], MAX_ROW_LENGTH);

        const isEmptyColumn = (index: number) =>
          (isCsvFile ? parsedJsonArray : (parsedJsonArray[0] as []))?.every(
            (data: any) => !data[`${ExcelTemplatesHeader.INVOICE_DETAIL} (${index})`],
          );

        const isEmptyColumnObj: { [index: number]: boolean } = {
          0: isEmptyColumn(1),
          1: isEmptyColumn(2),
          2: isEmptyColumn(3),
          3: isEmptyColumn(4),
          4: isEmptyColumn(5),
          5: isEmptyColumn(6),
          6: isEmptyColumn(7),
          7: isEmptyColumn(8),
          8: isEmptyColumn(9),
          9: isEmptyColumn(10),
        };

        const emptyColumnsLength = Object.values(isEmptyColumnObj)?.filter(isEmpty => isEmpty).length;
        const numberOfColumnsToFill = NUMBER_OF_INVOICE_ADDITIONAL_COLUMNS - emptyColumnsLength;
        const additionalColumnNameList = getValues().additionalColumnNameList ?? [];

        const filledAdditionalColumnNameList: { name: string }[] = [];
        for (let i = 0; i < numberOfColumnsToFill; i++) {
          if (additionalColumnNameList[i]) {
            filledAdditionalColumnNameList.push(additionalColumnNameList[i]);
          } else {
            filledAdditionalColumnNameList.push({ name: '' });
          }
        }

        const resetInvoiceList = (formattedDataList: any) => {
          reset({
            invoiceList: formattedDataList,
            additionalColumnNameList: filledAdditionalColumnNameList,
          });
        };

        if (isCsvFile && parsedJsonArray && parsedJsonArray.length !== 0) {
          const formattedDataList = parsedJsonArray.map((data: any) => formattedData(data, isEmptyColumnObj));
          resetInvoiceList(formattedDataList);
          updateTotalInvoiceAmount();

          return;
        }

        if (
          !isCsvFile &&
          parsedJsonArray &&
          Array.isArray(parsedJsonArray) &&
          parsedJsonArray[0] &&
          Array.isArray(parsedJsonArray[0]) &&
          parsedJsonArray[0].length !== 0
        ) {
          const formattedDataList = parsedJsonArray[0].map((data: any) => formattedData(data, isEmptyColumnObj));
          resetInvoiceList(formattedDataList);
          updateTotalInvoiceAmount();

          return;
        }

        showModal(
          <h6>
            {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
            <br />
            {t('text:Please_check_and_upload_it_again')}
          </h6>,
        );
      } catch (error) {
        showModal(error);
        initializeRefValue(fileRef);
      } finally {
        unShowLoadingUI();
      }
    }
  };

  const onClickRemoveExcel = (e: any): void => {
    e.preventDefault();
    initializeRefValue(fileRef);
    if (file) {
      setFile(undefined);
    }
  };

  const onClickCancel = (e: any): void => {
    showModal(<InvoiceRegisterCancelModal />, {
      modalSize: ModalSize.NONE,
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Close'),
      closeBtnCb: () => {},
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => history.push(ROUTES_AC.REGISTER_INVOICE.REGISTRATION_BY_PARTNER_STEP1),
    });

    e.preventDefault();
  };

  const onClickSave = async (e: any) => {
    e.preventDefault();
    setIsSavedFields(prev => (prev ? prev : !prev));
    setCheckedValidation([]);

    new Promise<void>(async resolve => {
      showLoadingUI();
      setTimeout(() => {
        resolve();
      }, 1);
    }).then(async () => {
      unShowLoadingUI();

      const data = getValues();
      let isPass = true;

      for (let i = 0; i < data.invoiceList?.length; i++) {
        const invoiceAmountResult = await trigger(`invoiceList.${i}.invoiceAmount`);

        if (!invoiceAmountResult) {
          isPass = false;
          // save 유효성 검사 실패 시 update해서 Validation Result 보여줌 (유효성 통과 시 Validation Result 숨김)
          setCheckedValidation(data.invoiceList?.map(() => true));
        }
      }

      if (isPass) {
        showModal(
          <h6>
            {t('text:Would_you_like_to_temporarily_save_the_invoice_information_you_entered?')}
            <br />
            {t(
              'text:Please_note_that_the_invoices_will_not_be_registered_completely_until_you_click_the_Register_button',
            )}
          </h6>,
          {
            modalSize: ModalSize.NONE,
            modalType: ModalType.CONFIRM,
            closeBtnText: t('text:Cancel'),
            confirmBtnText: t('text:Confirm'),
            confirmBtnCb: () => onClickConfirm(),
          },
        );
      }
    });

    const onClickConfirm = async (): Promise<void> => {
      try {
        const requestedData = getRequestValues();
        requestedData.dealerAgreementId = Number(dealerAgreementId);
        await requestTempInvoiceSummaryRegister(requestedData);

        showModal(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_AC.REGISTER_INVOICE.REGISTRATION_BY_PARTNER_STEP1),
        });
      } catch (error) {
        showModal(error);
      }
    };
  };

  const refreshBoeTable = useCallback((): void => {
    const { invoiceList } = getValues();

    if (invoiceList && Array.isArray(invoiceList)) {
      const boeTableDatas = calculateBOEInformation<AnchorInvoiceRegisterInvoiceListRequest>(
        invoiceList,
        anchorAgreementData?.billOfExchangeNoRequired,
      );

      setBoeTableData(boeTableDatas);
    }
  }, [anchorAgreementData?.billOfExchangeNoRequired, getValues]);

  const renderBoeTable = (): JSX.Element[] | undefined => {
    return boeTableData.map((item, index) => (
      <Tr key={index}>
        <Td data={item.billOfExchangeNo} />
        <Td data={item.settlementDate} format="date" />
        <Td data={item.invoiceAmount} format="number" />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Invoice_Registration_by_Partner')} />
      <div className="content-area">
        <StepWizard nth={2} title={[t('text:Select_Partner'), t('text:Enter_Invoice_Information_&_Register')]} />
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Partner_Information')} />

        <FormBorder>
          <FormContents>
            <div className="row">
              <div className="col-4">
                <div className="information-form__label">{t('text:Partner_Master_Agreement_Number')}</div>
                <div className="information-form__input border-none">{dealerAgreementData?.contractNo}</div>
              </div>
              <div className="col-4">
                <div className="information-form__label">{t('text:Partner_Name')}</div>
                <div className="information-form__input border-none">
                  {dealerAgreementData?.dealerFinancierClientName}
                </div>
              </div>
              <div className="col-4">
                <div className="information-form__label">{t('text:Currency')}</div>
                <div className="information-form__input border-none">{dealerAgreementData?.currencyType}</div>
              </div>
            </div>
          </FormContents>
        </FormBorder>
      </div>

      {(dealerAgreementData?.loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.BOTH ||
        dealerAgreementData?.loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.INVOICE ||
        anchorAgreementData?.loanOption !== LOAN_OPTION.NONE) && (
        <div className="content-area">
          <SectionTitle title={t('text:Invoice_Registration_Limit')} />

          <div className="information-form__corporation">
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:Financing_Limit')} ①</div>
                <div className="col-6">{t('format:number', { value: dealerAgreementData?.loanLimitAmount })}</div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Financing_Balance')} ②</div>
                <div className="col-6">{calculateInvoiceFinancingBalance(dealerAgreementData)}</div>
              </div>
            </div>
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:Remaining_Limit')} ③ = ① - ②</div>
                <div className="col-6">{calculateInvoiceRemainingLimit(dealerAgreementData)}</div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Registration_Confirmed_Invoice')} ④</div>
                <div className="col-6">
                  {t('format:number', { value: dealerAgreementData?.settlementWaitingInvoiceAmount })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Registration_Requested_Invoice')} ⑤</div>
                <div className="col-6 text-end">
                  {t('format:number', {
                    value: dealerAgreementData?.registeredWaitingInvoiceAmount,
                  })}
                </div>
              </div>
            </div>
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:Limit_Available_for_Registration')} ⑥ = ③ - ④ - ⑤</div>
                <div className="col-6 text-end blackfont">
                  {t('format:number', { value: dealerAgreementData?.totalLimitAmountWithoutAdHoc })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Exceed_Limit')} ⑦</div>
                <div className="col-6 text-end">
                  {t('format:number', {
                    value: dealerAgreementData?.exceedLimit,
                  })}
                </div>
              </div>
            </div>

            {dealerAgreementData?.adhocLimitAllowable && (
              <>
                <div className="corporation-section">
                  <div className="row">
                    <div className="col-6">{t('text:Ad_hoc_Limit_Amount')} ⑧</div>
                    <div className="col-6">
                      {tableValueManage(
                        dealerAgreementData.adhocLimitAmount,
                        t('format:number', { value: dealerAgreementData.adhocLimitAmount }),
                      )}
                    </div>
                  </div>
                  <div className="sub-container">
                    <div className="sub-title">{t('text:Ad_hoc_Limit_Start_Date')}</div>
                    <div className="sub-content">
                      {tableValueManage(
                        dealerAgreementData?.adhocLimitStartDate,
                        t('format:date', { value: dealerAgreementData?.adhocLimitStartDate, key: 'date' }),
                      )}
                    </div>
                  </div>
                  <div className="sub-container">
                    <div className="sub-title">{t('text:Ad_hoc_Limit_End_Date')}</div>
                    <div className="sub-content">
                      {tableValueManage(
                        dealerAgreementData?.adhocLimitEndDate,
                        t('format:date', { value: dealerAgreementData?.adhocLimitEndDate, key: 'date' }),
                      )}
                    </div>
                  </div>
                </div>
                <div className="corporation-section">
                  <div className="row">
                    <div className="col-6">{t('text:Limit_Available_for_Registration_with_Ad_hoc')} ⑨ = ⑥ + ⑧</div>
                    <div className="col-6 text-end">
                      {t('format:number', { value: dealerAgreementData?.totalLimitAmountWithAdHoc })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div className="content-area">
        <h3>{t('text:Select_invoice_registration_method')}</h3>
        <div className="invoice-radio-group">
          <RadioButton
            id="direct-input"
            name="registration-method"
            checked={isDirectInput}
            onChange={() => onClickRegistrationMethodRadioButton(true)}
          >
            {t('text:Direct_Input')}
          </RadioButton>
          <RadioButton
            id="file-upload"
            name="registration-method"
            checked={!isDirectInput}
            onChange={() => onClickRegistrationMethodRadioButton(false)}
          >
            {t('text:File_Upload')}
          </RadioButton>
        </div>
        {!isDirectInput && (
          <div className="excel-form mb-4">
            <div className="excel-download-form d-flex">
              <label className="me-3">
                {t(
                  'text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it_After_uploading_you_can_check_the_uploaded_list_below',
                )}
              </label>
              <ExcelTemplateDownloadButton
                templateFileAddress={
                  financierCommonSettingData?.invoiceWillBeSettledOnUploadDate
                    ? '/templates/InvoiceRegistrationByPartnerTemplate_withoutPaymentDate.xlsx'
                    : '/templates/InvoiceRegistrationByPartnerTemplate.xlsx'
                }
                style={{ marginRight: '4px' }}
              />
              <ExcelTemplateDownloadButton
                downloadAnnounceText={t('text:CSV_Template')}
                templateFileAddress={
                  financierCommonSettingData?.invoiceWillBeSettledOnUploadDate
                    ? '/templates/InvoiceRegistrationByPartnerTemplate_withoutPaymentDate.csv'
                    : '/templates/InvoiceRegistrationByPartnerTemplate.csv'
                }
              />
            </div>
            <div className="detail-in-file-upload-form bg-sub100">
              <div className="d-flex justify-content-between">
                <input
                  onChange={onChangeUploadExcel}
                  type="file"
                  name="file"
                  id="FileUpload"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{ display: 'none' }}
                  ref={fileRef}
                />
                <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
                  {t('text:Attach_File')}
                </label>
                <div id="fileName" className="upload-file-input">
                  {file ? file.name : t('text:No_file_attached')}
                </div>
                <IconButton
                  onClick={onClickRemoveExcel}
                  className={`delete-uploaded-excel-button ${!file && 'd-none'}`}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </IconButton>
              </div>
            </div>
          </div>
        )}
        <div data-testid="content-area">
          <div className="mb-3 position-relative">
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={removeRow}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <div
              className="append-column-btn__area"
              onMouseEnter={visibleAppendColumnButton}
              onMouseLeave={invisibleAppendColumnButton}
            />
            {visibleAppendColumnBtn && !maximumColumn && (
              <Button
                size={ButtonSizeEnum.XS}
                onClick={appendColumn}
                className="append-column-btn flex-center"
                onMouseEnter={visibleAppendColumnButton}
                variant={ButtonVariantEnum.OUTLINED}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            )}
          </div>
          {dealerAgreementData && (
            <>
              <div className="table-overflow-scroll" ref={invoiceRegisterTableRef}>
                <table className="table-border">
                  <colgroup>
                    <col style={{ width: '70px' }} />
                    {values(fieldNames).map(({ width }, index) => (
                      <col key={index} style={{ width }} />
                    ))}
                    {getValues().additionalColumnNameList?.map((_, index) => (
                      <col key={index} style={{ width: '180px' }} />
                    ))}
                    {checkedValidation.length > 0 && <col style={{ width: '180px' }} />}
                  </colgroup>
                  <thead data-column-type="append">
                    <tr>
                      <th scope="col">
                        <div className="text-center">
                          <input
                            className="form-check-input m-0"
                            type="checkbox"
                            value=""
                            id="allCheck2"
                            onChange={handleCheckAll}
                            checked={checkedRows.length === fields.length}
                          />
                        </div>
                      </th>
                      {values(fieldNames).map(({ name, hasQuestionButton }, index, { length }) => (
                        <th
                          key={index}
                          scope="col"
                          onMouseEnter={() => {
                            if (index !== length - 1 || Number(watchedAdditionalColumnNameList?.length) > 0) return;
                            visibleAppendColumnButton();
                          }}
                          onMouseLeave={() => {
                            if (index !== length - 1 || Number(watchedAdditionalColumnNameList?.length) > 0) return;
                            invisibleAppendColumnButton();
                          }}
                        >
                          {name}
                          {hasQuestionButton && (
                            <QuestionButton onClick={() => showModal(<ShowPaymentDateGuideModal />)} />
                          )}
                        </th>
                      ))}
                      <AdditionalColumnNameList
                        control={control}
                        register={register}
                        errors={errors}
                        visibleAppendColumnButton={visibleAppendColumnButton}
                        invisibleAppendColumnButton={invisibleAppendColumnButton}
                        removeColumn={removeColumn}
                      />
                      {checkedValidation.length > 0 && (
                        <th
                          scope="col"
                          onMouseEnter={visibleAppendColumnButton}
                          onMouseLeave={invisibleAppendColumnButton}
                        >
                          {t('text:Validation_Result')}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {controlledFields.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td className="bg-sub100" key={index}>
                            <div className="text-center">
                              <input
                                className="form-check-input m-0"
                                type="checkbox"
                                onChange={e => handleCheckChange(e, index)}
                                checked={checkedRows.includes(index)}
                              />
                            </div>
                          </td>
                          {/* BOE Number START */}
                          <td className={getValidationClassName('billOfExchangeNo', 'td', index)}>
                            <input
                              className={getValidationClassName('billOfExchangeNo', 'input', index)}
                              name={`invoiceList.${index}.billOfExchangeNo`}
                              defaultValue={item.billOfExchangeNo}
                              ref={register({
                                required: anchorAgreementData?.billOfExchangeNoRequired,
                              })}
                            />
                          </td>
                          {/* BOE Number END */}
                          <td className={getValidationClassName('invoiceNumber', 'td', index)}>
                            <input
                              className={getValidationClassName('invoiceNumber', 'input', index)}
                              name={`invoiceList.${index}.invoiceNumber`}
                              defaultValue={item.invoiceNumber}
                              ref={register({
                                required: true,
                                validate: value =>
                                  invoiceDuplicateValidator(
                                    getValues().invoiceList,
                                    value,
                                    index,
                                    fiscalYearSettingData,
                                  ),
                              })}
                            />
                          </td>
                          <td className={getValidationClassName('referenceNumber', 'td', index)}>
                            <input
                              className={getValidationClassName('referenceNumber', 'input', index)}
                              name={`invoiceList.${index}.referenceNumber`}
                              defaultValue={item.referenceNumber}
                              ref={register({
                                required: registrableLimit?.referenceNumberDuplicationCheck ? true : undefined,
                                validate: value =>
                                  registrableLimit?.referenceNumberDuplicationCheck
                                    ? referenceNumberDuplicateValidator(getValues().invoiceList, value, index)
                                    : undefined,
                              })}
                            />
                          </td>
                          <td className={getValidationClassName('invoiceIssuedDate', 'td', index)}>
                            <Controller
                              control={control}
                              name={`invoiceList.${index}.invoiceIssuedDate`}
                              defaultValue={item.invoiceIssuedDate}
                              onClick={() => handleScrollLeft(invoiceRegisterTableRef)}
                              render={field => (
                                <ReactDatePicker
                                  field={field}
                                  className={getValidationClassName('invoiceIssuedDate', 'input', index)}
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </td>
                          <td className={getValidationClassName('settlementDate', 'td', index)}>
                            <Controller
                              control={control}
                              name={`invoiceList.${index}.settlementDate`}
                              defaultValue={item.settlementDate}
                              onClick={() => handleScrollLeft(invoiceRegisterTableRef)}
                              render={field => (
                                <ReactDatePicker
                                  field={field}
                                  className={getValidationClassName('settlementDate', 'input', index)}
                                  minDate={minDate}
                                  maxDate={endDate}
                                  excludeDates={removeRangeFromHoliday(financierHoliday?.content)}
                                  disabled={financierCommonSettingData?.invoiceWillBeSettledOnUploadDate}
                                />
                              )}
                              rules={{
                                required: true,
                                validate: anchorAgreementData?.billOfExchangeNoRequired
                                  ? () => repaymentDateSameValidator(getValues().invoiceList, index)
                                  : undefined,
                              }}
                            />
                          </td>
                          <td className={getValidationClassName('invoiceAmount', 'td', index)}>
                            <input
                              type="text"
                              className={getValidationClassName('invoiceAmount', 'input', index) + ' text-end'}
                              name={`invoiceList.${index}.invoiceAmount`}
                              defaultValue={item.invoiceAmount}
                              ref={register({
                                validate: value =>
                                  invoiceOrArAmountInputValidate(value, dealerAgreementData?.currencyType, 'Invoice'),
                                required: !isSavedFields,
                                min: !isSavedFields ? 0.01 : undefined,
                              })}
                            />
                          </td>
                          <AdditionalDataList
                            rowIndex={index}
                            control={control}
                            register={register}
                            fieldName={{ rowName: 'invoiceList', columnName: 'invoiceAdditionalDataList' }}
                          />
                          {checkedValidation[index]
                            ? getValidationResult(index, isExceedAmount)
                            : checkedValidation.length > 0 && <td />}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="grid-total">
                {t('text:Total')} : {t('format:number', { value: totalInvoiceAmount })}
              </div>
            </>
          )}
          <div className="division-border" style={{ width: '100%' }} />
          <PaymentInformationAccordion id="payment-information" handleRefresh={refreshBoeTable}>
            <>
              <GuideMessage
                message={[
                  anchorAgreementData?.billOfExchangeNoRequired
                    ? t(
                        'text:Invoices_missing_the_BOE_Number_Payment_Date_or_the_Invoice_Amount_will_not_be_reflected_in_the_payment_information_below',
                      )
                    : t(
                        'text:Invoices_missing_the_Payment_Date_or_the_Invoice_Amount_will_not_be_reflected_in_the_payment_information_below',
                      ),
                ]}
                isImportContentArea={true}
              />
              <TableBorder>
                <TableHeader header={boeTableHeaders} />
                <TableBody numOfCol={boeTableHeaders.length}>{renderBoeTable()}</TableBody>
              </TableBorder>
            </>
          </PaymentInformationAccordion>
          <div className="d-flex mt-4">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={onClickCancel}
              className="me-auto"
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onClickSave} variant={ButtonVariantEnum.OUTLINED}>
              {t('text:Save')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onClickDirectInput} className="ms-2">
              {t('text:Register')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnchorInvoiceRegistrationByPartnerStep2;
