import type React from 'react';
import { useRef, useState } from 'react';
import type { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import type { FinancierArPhaseApproveRequest } from 'utils/http/api/financier/ar-summaries/request';
import { initializeRefValue } from 'utils/initialize';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import useModal from 'utils/modal/useModal';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';

const FILE_UPLOAD_MAX_ROW_LENGTH = 500;

type FieldValuesType = FinancierArPhaseApproveRequest;

interface ExcelFormProps {
  getValues: UseFormMethods<FieldValuesType>['getValues'];
  reset: UseFormMethods<FieldValuesType>['reset'];
  trigger: UseFormMethods<FieldValuesType>['trigger'];
  updateTotalApAmount: () => void;
  setCheckedValidation: React.Dispatch<React.SetStateAction<boolean[]>>;
}

function ExcelForm({ getValues, reset, trigger, updateTotalApAmount, setCheckedValidation }: ExcelFormProps) {
  const { t } = useTranslation();
  const modal = useModal();
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();

  const onClickRemoveExcel = (e: any): void => {
    e.preventDefault();
    initializeRefValue(fileRef);
    if (file) {
      setFile(undefined);
    }
  };

  const formattingData = (data: any, key?: string) => {
    if (data === '-' || isNil(data)) return '';
    else if (key === 'arAmount' && isNaN(Number(data))) return '';
    else return data;
  };

  const onChangeUploadExcel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const financierArImportValidate = async () => {
      return new Promise<void>(async resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1000);
      }).then(async () => {
        await trigger().then(result => {
          unShowLoadingUI();
          if (!result) {
            modal.show(
              <>
                <h6>{t('text:The_list_of_AR_contain_errors')}</h6>
                <h6>{t('text:Export_the_AR_list_to_check_the_detailed_validation_results')}</h6>
                <h6>{t('text:Attach_the_updated_AR_list_again_to_register_the_AR')}</h6>
              </>,
            );
            // FE validation error 있으면 return
          }
        });
      });
    };

    const formattedData = (data: any) => {
      const formattedSettlementDate = t('format:original-date', {
        value: String(data[ExcelTemplatesHeader.SETTLEMENT_DATE]),
        key: 'no-line-date',
      });

      const formattedIssuedDate = t('format:original-date', {
        value: String(data[ExcelTemplatesHeader.AR_ISSUED_DATE]),
        key: 'no-line-date',
      });
      const arNumberData = data[ExcelTemplatesHeader.AR_NUMBER];
      const arAmountData = data[ExcelTemplatesHeader.AR_AMOUNT];

      const arNumber = formattingData(arNumberData);
      const arAmount = formattingData(arAmountData, 'arAmount');
      const arIssuedDate = dayjs(formattedIssuedDate, 'YYYY-MM-DD', true).isValid() ? formattedIssuedDate : '';
      const settlementDate = dayjs(formattedSettlementDate, 'YYYY-MM-DD', true).isValid()
        ? formattedSettlementDate
        : '';

      return {
        arNumber,
        arAmount,
        arIssuedDate,
        settlementDate,
      };
    };

    if (e.target.files && e.target.files[0]) {
      const isCsvFile = e.target.files[0].type === 'text/csv';

      try {
        showLoadingUI();
        const jsonArrayData = isCsvFile
          ? await csvToJson(e.target.files[0], FILE_UPLOAD_MAX_ROW_LENGTH)
          : await excelToJson(e.target.files[0], FILE_UPLOAD_MAX_ROW_LENGTH);

        setFile(e.target.files[0]);

        if (isCsvFile && jsonArrayData && jsonArrayData.length !== 0) {
          const changedData: any[] = jsonArrayData.map((data: any) => formattedData(data));

          setCheckedValidation([]);
          reset({
            arList: changedData,
          });

          updateTotalApAmount();
          await financierArImportValidate();
          setCheckedValidation(getValues().arList.map(() => true));

          return;
        }

        if (
          !isCsvFile &&
          jsonArrayData &&
          Array.isArray(jsonArrayData) &&
          jsonArrayData[0] &&
          Array.isArray(jsonArrayData[0]) &&
          jsonArrayData[0].length !== 0
        ) {
          const changedData = jsonArrayData[0].map((data: any) => formattedData(data));
          setCheckedValidation([]);
          reset({
            arList: changedData,
          });

          updateTotalApAmount();
          await financierArImportValidate();
          setCheckedValidation(getValues().arList.map(() => true));

          return;
        }

        modal.show(
          <h6>
            {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
            <br />
            {t('text:Please_check_and_upload_it_again')}
          </h6>,
        );
      } catch (error) {
        modal.show(error);
        initializeRefValue(fileRef);
      } finally {
        unShowLoadingUI();
      }
    }
  };

  return (
    <div className="excel-form mb-5">
      <div className="excel-download-form d-flex">
        <label className="me-3">
          {`${t('text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it')} ${t(
            'text:After_uploading_you_can_check_the_uploaded_list_below',
          )}`}
        </label>
        <ExcelTemplateDownloadButton
          templateFileAddress="/templates/ARRegistrationTemplate.xlsx"
          style={{ marginRight: '4px' }}
        />
        <ExcelTemplateDownloadButton
          downloadAnnounceText={t('text:CSV_Template')}
          templateFileAddress="/templates/ARRegistrationTemplate.csv"
        />
      </div>
      <div className="detail-in-file-upload-form bg-sub100">
        <div className="d-flex justify-content-between">
          <input
            onChange={onChangeUploadExcel}
            ref={fileRef}
            type="file"
            name="file"
            id="FileUpload"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: 'none' }}
          />
          <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
            {t('text:Attach_File')}
          </label>
          <div id="fileName" className="upload-file-input">
            {file ? file.name : t('text:No_file_attached')}
          </div>
          <IconButton onClick={onClickRemoveExcel} className={`delete-uploaded-excel-button ${!file && 'd-none'}`}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default ExcelForm;
