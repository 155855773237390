import type Pageable from 'models/Pageable';
import type { AnchorPartnerVO, AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import { formattingToAnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { AnchorPartnerListRequest } from './requests';

export async function requestFiAnchorPartnerList(
  pageNumber: number,
  rowCount: number,
  data: AnchorPartnerListRequest,
): Promise<Pageable<AnchorPartnerVOModel[]>> {
  const response = await http.get<Pageable<AnchorPartnerVO[]>>({
    url: API_FI.ANCHOR_PARTNERS.PARTNER_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorPartnerVOModel(data)),
  };
}

export async function requestFiAnchorPartnerDetail(anchorPartnerId: number): Promise<AnchorPartnerVOModel> {
  const response = await http.get<AnchorPartnerVO>({
    url: API_FI.ANCHOR_PARTNERS.PARTNER_DETAIL(anchorPartnerId),
  });

  return formattingToAnchorPartnerVOModel(response);
}
