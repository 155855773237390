import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder, FormContents, FormSubtitle, FormValue } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ATTACHMENT_TYPE } from 'enums';
import type { FactoringNoticeDetailVOModel } from 'models/vo/FactoringNoticeDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { requestAnchorFactoringNoticeDownload } from 'utils/http/api/anchor/factoring-notices';
import useModal from 'utils/modal/useModal';

interface AnchorDeptSellingNoticeDetailSummaryProps {
  data?: FactoringNoticeDetailVOModel;
}

function AnchorDeptSellingNoticeDetailSummary({ data }: AnchorDeptSellingNoticeDetailSummaryProps) {
  const { t } = useTranslation();
  const modal = useModal();
  const isSingleLoan = isNil(data?.multipleLoanRequestId);

  const EmailContentList = ({ label, value }: { label: string; value: any }) => (
    <li className="pb-2">
      <span>• {label}</span>: <span className="text-bold-sub700">{value}</span>
    </li>
  );

  const handleClickDownloadButton = async () => {
    try {
      await requestAnchorFactoringNoticeDownload(data?.factoringNoticeId!, {
        attachmentType: ATTACHMENT_TYPE.FACTORING_NOTIFICATION,
      });
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Confirmed_User')} col={4} value={data?.confirmedUserName} />
              <FormValue
                label={t('text:Authority')}
                col={4}
                value={t(`code:authority-type.${data?.confirmedUserAuthority}`)}
                format="code"
              />
              <FormValue
                label={t('text:Confirmation_Status')}
                className={getStatusBadgeClass('FACTORING_NOTICE_CONFIRMATION_STATUS', data?.confirmationStatus)}
                col={4}
                value={t(`code:factoring-notice-confirmation-status.${data?.confirmationStatus}`)}
                format="code"
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <FormBorder>
          <FormSubtitle title={t('text:Debt_Selling_Notification_mail_content')} />
          <div className="d-flex flex-column justify-content-center p-5 m-auto w-75">
            <p className="gray-bold-font pb-2">[{t('text:Financing_Information')}]</p>
            <FormContents>
              <ul>
                <EmailContentList label={t('text:Partner_Name')} value={data?.partnerName} />
                {isSingleLoan && (
                  <>
                    <EmailContentList label={t('text:AR_Number')} value={data?.arNumber} />
                    <EmailContentList label={t('text:Financing_ID')} value={data?.loanId} />
                    <EmailContentList
                      label={t('text:Requested_Financing_Amount')}
                      value={`${t('format:number', { value: data?.requestedAmount })} ${data?.currencyType}`}
                    />
                  </>
                )}
                {!isSingleLoan && (
                  <>
                    <EmailContentList label={t('text:Number_of_AR')} value={data?.totalArCount} />
                    <EmailContentList
                      label={t('text:Total_Financing_Amount')}
                      value={`${t('format:number', { value: data?.totalRequestedAmount })} ${data?.currencyType}`}
                    />
                  </>
                )}

                <EmailContentList
                  label={t('text:Scheduled_Disbursement_Date')}
                  value={t('format:date', { value: data?.desiredDisburseDate, key: 'date' })}
                />
                {isSingleLoan && (
                  <EmailContentList
                    label={t('text:Scheduled_Repayment_Date')}
                    value={t('format:date', { value: data?.repaymentDate, key: 'date' })}
                  />
                )}
              </ul>
            </FormContents>
          </div>

          <FormSubtitle title={t('text:DEBT_SELLING_NOTIFICATION')} />
          <div className="detail-in-file-download-form">
            <div className="d-flex justify-content-between">
              <label className="information-form__label">{t('text:Attached_File')}</label>
              <input type="text" placeholder={data?.agreementAttachmentName} className="upload-file-input" readOnly />
              <DownloadButton onClick={handleClickDownloadButton} />
            </div>
          </div>
        </FormBorder>
      </div>
    </>
  );
}

export default AnchorDeptSellingNoticeDetailSummary;
