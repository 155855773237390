import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import dayjs from 'dayjs';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE, FINANCIER_INTERFACE_TYPE, LOAN_STATUS, SUCCESS_AR_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { PartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { getPrevDay } from 'utils/date/date';
import { requestFinancierDownloadScannedAr } from 'utils/http/api/financier/loans';
import {
  requestFinancierFetchPartialCollaterals,
  requestFinancierRenewArByFinancierInterface,
  requestFinancierSuccessArsActive,
  requestFinancierSuccessArsBlock,
  requestFinancierSuccessArsDelete,
  requestFinancierSuccessArsDetail,
  requestFinancierUpdateStatus,
} from 'utils/http/api/financier/success-ars';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType, getSignIn } from 'utils/storage/LocalStorage';
import { getFilteredDisbursedAmount } from 'utils/valueManager/ValueManager';

import UpdateArSettlementModal from './modal/UpdateArSettlementModal';

import type { UpdateArSettlementProps } from './modal/UpdateArSettlementModal';

function FinancierArDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const signInModel: SignInModel | null = getSignIn();
  const history = useHistory();
  const { successArId } = useParams<any>();
  const { pageable: financingInfoPageable, setPageable: setFinancingInfoPageable } = usePageable();
  const [arDetailData, setArDetailData] = useState<SuccessArDetailVOModel>();
  const [financingInfo, setFinancingInfo] = useState<Pageable<PartialCollateralVOModel[]>>();
  const [arRepaymentInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.AR_PAYMENT_RESULT));
  const [arTransferInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.TRANSFER_RESULT));

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [fetchedDealerArDetailPage, financingInfoResponse] = await Promise.all([
        requestFinancierSuccessArsDetail(successArId),
        requestFinancierFetchPartialCollaterals(
          financingInfoPageable.currentPage,
          financingInfoPageable.sizePerPage,
          successArId,
        ),
      ]);
      ReactDOM.unstable_batchedUpdates(() => {
        setArDetailData(fetchedDealerArDetailPage);
        setFinancingInfo(financingInfoResponse);
        setFinancingInfoPageable(financingInfoResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const FINANCING_INFORMATION_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 150,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 150,
    },
    {
      headerText: t('text:Disbursement_Amount'),
      colWidths: 150,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Repaid_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Tax_Invoice_File'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickLoanStatus,
    },
    {
      headerText: '',
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const showSuccessUpdateTransactionThroughInterfaceModal = () => {
    modal.show(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
      closeBtnText: t('text:OK'),
      closeBtnCb: () => fetchAll(),
    });
  };

  const updateTransactionThroughInterface = async () => {
    try {
      await requestFinancierRenewArByFinancierInterface(successArId);

      showSuccessUpdateTransactionThroughInterfaceModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const updateOrModifyTransactionResult = async () => {
    try {
      const arStatus = await requestFinancierRenewArByFinancierInterface(successArId);

      const isArDetailFailed = arDetailData?.successArStatus === SUCCESS_AR_STATUS.FAILED;
      const isArStatusFailed = arStatus?.successArStatus === SUCCESS_AR_STATUS.FAILED;

      if (isArDetailFailed && isArStatusFailed) showUpdateSettlementStatusModal();
      else showSuccessUpdateTransactionThroughInterfaceModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const showSuccessUpdateTransactionModal = () => {
    modal.show(<h6>{t('text:The_AR_settlement_has_been_completed_successfully')}</h6>, {
      closeBtnText: t('text:OK'),
      closeBtnCb: () => fetchAll(),
    });
  };

  const showUpdateSettlementStatusModal = () => {
    const modalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Update_AR_Settlement'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async (props: UpdateArSettlementProps) => {
        try {
          await requestFinancierUpdateStatus(successArId, {
            targetArStatus: SUCCESS_AR_STATUS.SETTLED,
            settledDate: props.props.settledDate,
          });
          showSuccessUpdateTransactionModal();
        } catch (e) {
          modal.show(e);
        }
      },
    };

    const props = {
      arNumber: arDetailData?.arNumber as string,
      arAmount: arDetailData?.arAmount as number,
      currencyType: arDetailData?.currencyType as keyof typeof CURRENCY_TYPE,
      settlementDate: arDetailData?.settlementDate as string,
      settledDate: '',
    };

    modal.show(<UpdateArSettlementModal props={props} modalId={modal.id} />, modalOptions);
  };

  const showSuccessCancelSettlementCompletionModal = () => {
    modal.show(<h6>{t('text:The_AR_settlement_completion_has_been_cancelled_successfully')}</h6>, {
      closeBtnText: t('text:OK'),
      closeBtnCb: () => fetchAll(),
    });
  };

  const showCancelSettlementCompletionModal = () => {
    const modalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Update_AR_Settlement'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async () => {
        try {
          await requestFinancierUpdateStatus(successArId, { targetArStatus: SUCCESS_AR_STATUS.REGISTERED });
          showSuccessCancelSettlementCompletionModal();
        } catch (e) {
          modal.show(e);
        }
      },
    };

    modal.show(<h6>{t('text:Would_you_like_to_cancel_the_AR_settlement_completion?')}</h6>, modalOptions);
  };

  const renderUpdateTransactionButtonAndGuideMessage = () => {
    if (!arDetailData?.paymentSupport) return null;

    const isShowInterfaceUpdateTransaction =
      arDetailData?.successArStatus === SUCCESS_AR_STATUS.REGISTERED ||
      arDetailData?.successArStatus === SUCCESS_AR_STATUS.FAILED;

    const isShowUpdateTransaction =
      (arDetailData?.successArStatus === SUCCESS_AR_STATUS.REGISTERED ||
        arDetailData?.successArStatus === SUCCESS_AR_STATUS.SETTLED) &&
      signInModel?.authorityType !== AUTHORITY_TYPE.ADMIN;

    const showCondition =
      arRepaymentInterface || arTransferInterface ? isShowInterfaceUpdateTransaction : isShowUpdateTransaction;

    function determineOnClickFunction() {
      if (arRepaymentInterface || (arTransferInterface && signInModel?.authorityType === AUTHORITY_TYPE.ADMIN)) {
        return updateTransactionThroughInterface;
      }

      if (arTransferInterface && signInModel?.authorityType !== AUTHORITY_TYPE.ADMIN) {
        return updateOrModifyTransactionResult;
      }

      if (arDetailData?.successArStatus === SUCCESS_AR_STATUS.REGISTERED) {
        return showUpdateSettlementStatusModal;
      }

      if (arDetailData?.successArStatus === SUCCESS_AR_STATUS.SETTLED) {
        return showCancelSettlementCompletionModal;
      }
    }

    const onClickUpdateTransaction = determineOnClickFunction();

    if (showCondition) {
      const isArStatusRegistered = arDetailData?.successArStatus === SUCCESS_AR_STATUS.REGISTERED;
      const message = isArStatusRegistered
        ? t('text:Click_on_the_Update_Transaction_button_to_update_the_AR_Settlement_information')
        : t('text:Click_on_the_Cancel_AR_settlement_completion_button_to_cancel_the_AR_Settlement_completion');
      const buttonMessage = isArStatusRegistered
        ? t('text:Update_Transaction')
        : t('text:Cancel_AR_settlement_completion');

      return (
        <GuideMessage message={[message]} isImportContentArea={true}>
          {{
            button: <Button onClick={onClickUpdateTransaction}>{buttonMessage}</Button>,
          }}
        </GuideMessage>
      );
    } else {
      return null;
    }
  };

  const onClickMakeActive = async () => {
    try {
      await requestFinancierSuccessArsActive(successArId);
      modal.show(
        <h6>
          {t('text:AR_has_been_successfully_activated')}
          <br />
          {t('text:If_the_AR_settlement_date_has_not_passed_the_AR_can_be_used_for_a_financing_application')}
        </h6>,
        {
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        },
      );
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickBlock = () => {
    let reason = '';

    const showBlockedSuccessModal = () => {
      modal.show(
        <h6>
          {t('text:The_AR_has_been_blocked_successfully')}
          <br />
          {t('text:Click_the_Make_Active_button_to_reactive')}
        </h6>,
        {
          closeBtnCb: () => fetchAll(),
        },
      );
    };

    const requestBlock = async () => {
      try {
        await requestFinancierSuccessArsBlock(successArId, reason);
        showBlockedSuccessModal();
      } catch (e) {
        modal.show(e);
      }
    };
    modal.show(
      <>
        <h6>
          {t('text:Would_you_like_to_block_the_AR?')}
          <br />
          {t('text:If_you_block_the_AR_the_Partner_will_not_be_able_to_use_it_for_financing_applications')}
          <br />
          {t('text:Please_enter_the_reason_for_the_AR_block_below')}
        </h6>
        <ReasonModal
          modalId={modal.id}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Block_AR'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => requestBlock(),
      },
    );
  };

  const getSettlementCompletionDate = () => {
    if (arDetailData?.successArStatus === SUCCESS_AR_STATUS.REGISTERED) return null;
    if (arDetailData?.successArStatus === SUCCESS_AR_STATUS.SETTLED) return arDetailData?.settledDateTime;

    return null;
  };

  /* 가능 시점 : 해당 AR로 대출 신청이 되지 않은 시점 or 만기일 1일 전까지 */
  const deleteOrBlockBtnDisabled = () => {
    const [prevMaturityDate] = getPrevDay(arDetailData?.settlementDate).split('T');
    const [today] = dayjs().format().split('T');

    return (
      (prevMaturityDate < today && !arDetailData?.loanGiven) ||
      arDetailData?.loanGiven ||
      signInModel?.authorityType !== AUTHORITY_TYPE.AUTHORIZER
    );
  };

  const paginateFinancingInfo = async (pageNumber: number, rowCount: number) => {
    try {
      const financingInfoResponse = await requestFinancierFetchPartialCollaterals(pageNumber, rowCount, successArId);

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancingInfo(financingInfoResponse);
        setFinancingInfoPageable(financingInfoResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickDownloadScannedAr = async (loanId: number) => {
    try {
      await requestFinancierDownloadScannedAr(loanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const showSettlementStatusDescription = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_AR_STATUS)}
        statusDescriptionEnumType="SUCCESS_AR_STATUS"
        statusDescriptionModalType="TEXT"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
      },
    );
  };

  const handleClickDeleteButton = () => {
    const requestDelete = async () => {
      await requestFinancierSuccessArsDelete(successArId);
      modal.show(<h6>{t('text:The_AR_has_been_deleted')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnCb: () => history.push(ROUTES_FI.VIEW_TRANSACTION.AR_LIST),
      });
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_delete_the_AR?')}
        <br />
        {t('text:Deleted_AR_information_cannot_be_reversed')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => await requestDelete(),
      },
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AR_Details')} />
      {arDetailData?.blockedByFinancier && (
        <GuideMessage
          message={[t('text:The_AR_has_been_blocked'), t('text:Please_check_the_reason_for_block_below')]}
          messageType={MessageType.ALERT}
          reasonTitle={t('text:REASON_FOR_BLOCK')}
          reason={arDetailData.blockedReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:AR_Information')}>
          {/* 가능 권한 - Financier Authorizer */}
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={handleClickDeleteButton}
            size={ButtonSizeEnum.SM}
            className="me-2"
            disabled={deleteOrBlockBtnDisabled()}
            data-for="delete-button-tip"
            data-tip
          >
            {t('text:Delete')}
          </Button>
          <ReactTooltip id="delete-button-tip" effect="solid" className="tooltip__description__max__width">
            <div className="p-1">
              {t('text:Anchor_Admin_can_delete_ARs')}
              <br />
              {t(
                'text:However_exceptionally_Financier_Authorizer_can_delete_ARs_only_when_meets_the_following_two_conditions',
              )}
              :
              <ul>
                <li>• {t('text:Selected_VFS_without_Anchor_User_Invitation_option_when_register_Anchor')}</li>
                <li>• {t('text:AR_registered_by_Financier_user_due_to_absence_of_Anchor_user')}</li>
              </ul>
            </div>
          </ReactTooltip>

          {signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER && (
            <>
              {arDetailData?.blockedByFinancier ? (
                <Button
                  onClick={onClickMakeActive}
                  size={ButtonSizeEnum.SM}
                  color={ButtonColorEnum.BLUE}
                  variant={ButtonVariantEnum.OUTLINED}
                >
                  {t('text:Make_Active')}
                </Button>
              ) : (
                <Button
                  onClick={onClickBlock}
                  disabled={deleteOrBlockBtnDisabled()}
                  size={ButtonSizeEnum.SM}
                  color={ButtonColorEnum.RED}
                  variant={ButtonVariantEnum.OUTLINED}
                >
                  {t('text:Block')}
                </Button>
              )}
            </>
          )}
        </SectionTitle>
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:AR_Amount')} col={3} value={arDetailData?.arAmount} format="number" />
              <FormValue label={t('text:Currency')} col={3} value={arDetailData?.currencyType} />
              <FormValue
                className={`information-form__input border-none ${getBlockedStatusClassName(
                  arDetailData?.blockedByFinancier,
                )}`}
                col={3}
                label={t('text:Financing_Eligibility')}
                value={arDetailData?.blockedByFinancier ? t('text:Ineligible') : t('text:Eligible')}
              />
              <FormValue
                label={t('text:Settlement_Status')}
                col={3}
                value={t(`code:success-ar-status.${arDetailData?.successArStatus}`)}
                className={
                  'information-form__input border-none ' +
                  getStatusTextClass('SUCCESS_AR_STATUS', arDetailData?.successArStatus)
                }
                format="code"
                hasStatusDescription
                showStatusDescriptionFunc={showSettlementStatusDescription}
              />
            </div>
          </FormContents>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:AR_Number')} col={3} value={arDetailData?.arNumber} />
              <FormValue label={t('text:AR_Issued_Date')} col={3} value={arDetailData?.arIssuedDate} format="date" />
              <FormValue
                label={t('text:Original_Settlement_Date')}
                col={3}
                value={arDetailData?.maturityDate}
                format="date"
              />
              <FormValue
                label={t('text:Modified_Settlement_Date')}
                col={3}
                value={arDetailData?.settlementDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={arDetailData?.anchorName} />
              <FormValue label={t('text:Partner_Name')} value={arDetailData?.anchorPartnerName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Financing_Information')} />
        <TableBorder>
          <TableHeader header={FINANCING_INFORMATION_HEADERS} />
          <TableBody numOfCol={FINANCING_INFORMATION_HEADERS.length}>
            {financingInfo?.content?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.loanId} />
                <Td data={item.netDisbursementAmount} format="number" />
                <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
                <Td data={item.disbursedDate} format="date" />
                <Td data={item.repaidDate} format="date" />
                <Td data={item.attachmentName} />
                <Td
                  data={t(`code:financing-status.${item.loanStatus}`)}
                  className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
                  format="code"
                />
                <Td className="text-center">
                  <DownloadButton
                    onClick={() => onClickDownloadScannedAr(item.loanId)}
                    disabled={!item.attachmentId && !item.attachmentName}
                  />
                </Td>
                <TdLink path={ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(item.loanId)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={financingInfoPageable} paginate={paginateFinancingInfo} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Scheduled_AR_Settlement_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Scheduled_Settlement_Amount')} value={arDetailData?.arAmount} format="number" />
              <FormValue
                label={t('text:Scheduled_Settlement_Date')}
                value={arDetailData?.settlementDate}
                format="date"
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Withdrawal_Bank_Account_Information_Anchor')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} col={6} value={arDetailData?.anchorName} />
              <FormValue label={t('text:Agreement_Number')} col={6} value={arDetailData?.anchorContractNo} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Automatic_Settlement')}
                col={6}
                value={
                  arDetailData?.paymentSupport
                    ? `${t('text:Yes')} (${t('text:Co_operation&Payment')})`
                    : `${t('text:No')} (${t('text:Co_operation')})`
                }
              />
              <FormValue label={t('text:Bank_Name')} col={6} value={arDetailData?.anchorSettlementBankName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} col={6} value={arDetailData?.anchorSettlementAccount} />
              <FormValue label={t('text:Account_Holder')} col={6} value={arDetailData?.anchorSettlementAccountOwner} />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Deposit_Bank_Account_Information_Partner')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Partner_Name')} col={6} value={arDetailData?.anchorPartnerName} />
              <FormValue label={t('text:Bank_Name')} col={6} value={arDetailData?.partnerRequestedBankName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} col={6} value={arDetailData?.partnerRequestedAccount} />
              <FormValue label={t('text:Account_Holder')} col={6} value={arDetailData?.partnerRequestedAccountOwner} />
            </div>
          </FormContents>
          {/*파트너 약정 존재할 경우 해당 섹션 표시*/}
          {arDetailData?.dealerContractNo ? (
            <FormContents>
              <div className="row text-bold-brick-red">
                {t(
                  'text:*_If_the_Partner_has_a_master_agreement_the_settlement_will_be_made_to_the_bank_account_below',
                )}
              </div>
              <div className="row">
                <FormValue label={t('text:Agreement_Number')} col={6} value={arDetailData.dealerContractNo} />
                <FormValue label={t('text:Bank_Name')} col={6} value={arDetailData.principalRepaymentBankName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} col={6} value={arDetailData.principalRepaymentAccount} />
                <FormValue
                  label={t('text:Account_Holder')}
                  col={6}
                  value={arDetailData.principalRepaymentAccountOwner}
                />
              </div>
              <div className="row" />
            </FormContents>
          ) : null}
        </FormBorder>
      </div>

      {arDetailData?.paymentSupport ? (
        <div className="content-area">
          <SectionTitle title={t('text:AR_Settlement_Result')} />
          {renderUpdateTransactionButtonAndGuideMessage()}

          <FormBorder>
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue
                  label={t('text:Settlement_Status')}
                  value={t(`code:success-ar-status.${arDetailData?.successArStatus}`)}
                  className={
                    'information-form__input border-none ' +
                    getStatusTextClass('SUCCESS_AR_STATUS', arDetailData.successArStatus)
                  }
                  format="code"
                />
                <FormValue
                  label={t('text:Settlement_Completion_Date')}
                  value={getSettlementCompletionDate()}
                  className="information-form__input border-none"
                  format="date"
                />
              </div>
            </FormContents>
            <FormSubtitle
              title={t('text:Bank_Information_of_AR_Settlement')}
              backGroundType={BackGroundType.DarkGray}
            />
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue label={t('text:Anchor_Name')} value={arDetailData?.settledAnchorEnterpriseName} />
                <FormValue label={t('text:Bank_Name')} value={arDetailData.settledAnchorBankName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={arDetailData?.settledAnchorAccount} />
                <FormValue label={t('text:Account_Holder')} value={arDetailData?.settledAnchorAccountOwner} />
              </div>
            </FormContents>
            <FormContents backGroundType={BackGroundType.Gray}>
              <div className="row">
                <FormValue label={t('text:Partner_Name')} value={arDetailData?.settledPartnerName} />
                <FormValue label={t('text:Bank_Name')} value={arDetailData.settledPartnerBankName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={arDetailData?.settledPartnerAccount} />
                <FormValue label={t('text:Account_Holder')} value={arDetailData?.settledPartnerAccountOwner} />
              </div>
            </FormContents>
          </FormBorder>
        </div>
      ) : null}
    </>
  );
}

export default FinancierArDetail;
