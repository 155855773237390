import type Pageable from 'models/Pageable';
import type {
  WaitingDealerAgreementDetailVO,
  WaitingDealerAgreementDetailVOModel,
} from 'models/vo/WaitingDealerAgreementDetailVO';
import { formattingToWaitingDealerAgreementDetailVOModel } from 'models/vo/WaitingDealerAgreementDetailVO';
import type { WaitingDealerAgreementVO, WaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import { formattingToWaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';
import type {
  CreateFiWaitingDealerAgreementDTO,
  SearchFiWaitingDealerAgreementDTO,
  UpdateFiWaitingDealerAgreementDTO,
} from 'utils/http/api/financier/waiting-dealer-agreements/request';

export async function requestFinancierWaitingDealerAgreementList(
  pageNumber: number,
  rowCount: number,
  searchData: SearchFiWaitingDealerAgreementDTO,
): Promise<Pageable<WaitingDealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<WaitingDealerAgreementVO[]>>({
    url: API_FI.WAITING_DEALER_AGREEMENTS.WAITING_DEALER_AGREEMENTS,
    data: {
      pageNumber,
      rowCount,
      ...searchData,
    },
  });

  const waitingDealerAgreementList = response.content.map(data => formattingToWaitingDealerAgreementVOModel(data));

  return {
    ...response,
    content: waitingDealerAgreementList,
  };
}

export async function requestFinancierWaitingDealerAgreementDetail(
  waitingDealerAgreementId: number,
): Promise<WaitingDealerAgreementDetailVOModel> {
  const response = await http.get<WaitingDealerAgreementDetailVO>({
    url: API_FI.WAITING_DEALER_AGREEMENTS.WAITING_DEALER_AGREEMENT_DETAIL(waitingDealerAgreementId),
  });

  return formattingToWaitingDealerAgreementDetailVOModel(response);
}

export async function requestUpdateDealerAgreementDetail(data: CreateFiWaitingDealerAgreementDTO): Promise<void> {
  await http.post<WaitingDealerAgreementVO>({
    url: API_FI.WAITING_DEALER_AGREEMENTS.WAITING_DEALER_AGREEMENTS,
    data,
  });

  return;
}

export async function requestReturnDealerAgreementDetail(
  waitingDealerAgreementId: number,
  returnReason: string,
): Promise<void> {
  await http.put<WaitingDealerAgreementVO>({
    url: API_FI.WAITING_DEALER_AGREEMENTS.RETURN_WAITING_DEALER_AGREEMENT(waitingDealerAgreementId),
    data: { returnReason },
  });

  return;
}

export async function requestCancelDealerAgreementDetail(waitingDealerAgreementId: number): Promise<void> {
  await http.put<WaitingDealerAgreementVO>({
    url: API_FI.WAITING_DEALER_AGREEMENTS.CANCEL_WAITING_DEALER_AGREEMENT(waitingDealerAgreementId),
  });

  return;
}

export async function requestConfirmDealerAgreementDetail(waitingDealerAgreementId: number): Promise<void> {
  await http.put<WaitingDealerAgreementVO>({
    url: API_FI.WAITING_DEALER_AGREEMENTS.APPROVE_WAITING_DEALER_AGREEMENT(waitingDealerAgreementId),
  });

  return;
}

export async function requestChangeDealerAgreementDetail(
  waitingDealerAgreementId: number,
  data: UpdateFiWaitingDealerAgreementDTO,
): Promise<void> {
  await http.put<WaitingDealerAgreementVO>({
    url: API_FI.WAITING_DEALER_AGREEMENTS.WAITING_DEALER_AGREEMENT_DETAIL(waitingDealerAgreementId),
    data,
  });

  return;
}
