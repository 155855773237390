import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';

import Accordion from './Accordion';
import './Accordion.scss';

interface AccordionPropsType {
  id: string;
  children: ReactNode;
  handleRefresh: () => void;
}

const PaymentInformationAccordion = ({ id, children, handleRefresh }: AccordionPropsType) => {
  const { t } = useTranslation(['format']);

  return (
    <Accordion className="accordion-container" id={id} refresh={handleRefresh}>
      <Accordion.Header
        id="headingOne"
        sideMenu={
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={handleRefresh}
            size={ButtonSizeEnum.SM}
          >
            <FontAwesomeIcon icon={faSyncAlt} /> {t('text:Refresh')}
          </Button>
        }
      >
        <Accordion.Trigger className="fw-bold">{t('text:Payment_Information')}</Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className="accordion-body">{children}</Accordion.Content>
    </Accordion>
  );
};

export default PaymentInformationAccordion;
