import { Route, Switch } from 'react-router-dom';

import { ROUTES_CM } from 'constants/routes/common';
import NotFoundPage from 'pages/NotFoundPage';
import CreateFinancier from 'pages/common/create-financier/CreateFinancier';
import FindId from 'pages/common/find-id/FindId';
import FindIdEmailSent from 'pages/common/find-id/FindIdEmailSent';
import FindPassword from 'pages/common/find-password/FindPassword';
import FindPasswordEmailSent from 'pages/common/find-password/FindPasswordEmailSent';
import ResetPassword from 'pages/common/reset-password/ResetPassword';
import ResetPasswordComplete from 'pages/common/reset-password/ResetPasswordComplete';
import AnchorCertify from 'pages/common/sign-up/anchor/AnchorCertify';
import AnchorSignUp from 'pages/common/sign-up/anchor/AnchorSignUp';
import AnchorHqOperatorAndDealerOperatorSignUp from 'pages/common/sign-up/anchor-hq-operator-and-dealer-operator/AnchorHqOperatorAndDealerOperatorSignUp';
import SignUpComplete from 'pages/common/sign-up/complete/SignUpComplete';
import DealerAuthorizerCertify from 'pages/common/sign-up/dealer-authorizer/DealerAuthorizerCertify';
import DealerAuthorizerSignUp from 'pages/common/sign-up/dealer-authorizer/DealerAuthorizerSignUp';
import FinancierSignUp from 'pages/common/sign-up/financier/FinancierSignUp';

import 'pages/common/CommonPage.scss';

function CommonRouter() {
  return (
    <div className="main-container">
      <Switch>
        {/* financier 회원가입 */}
        <Route exact path={ROUTES_CM.FI_SIGN_UP} component={FinancierSignUp} />

        {/* Anchor 회원가입 */}
        <Route exact path={ROUTES_CM.AC_SIGN_UP} component={AnchorSignUp} />

        {/* Anchor 기존 회원 인증 */}
        <Route exact path={ROUTES_CM.AC_CERTIFY} component={AnchorCertify} />

        {/* Dealer Authorizer 회원가입 */}
        <Route exact path={ROUTES_CM.DE_AUTHORIZER_SIGN_UP} component={DealerAuthorizerSignUp} />

        {/* Dealer Authorizer 기존회원 인증 */}
        <Route exact path={ROUTES_CM.DE_AUTHORIZER_CERTIFY} component={DealerAuthorizerCertify} />

        {/* Dealer Operator 회원가입 */}
        <Route
          exact
          path={ROUTES_CM.AC_HQ_OPERATOR_AND_DE_OPERATOR_SIGN_UP}
          component={AnchorHqOperatorAndDealerOperatorSignUp}
        />

        {/* 회원가입 완료 페이지 */}
        <Route exact path={ROUTES_CM.SIGN_UP_COMPLETE} component={SignUpComplete} />

        {/* 패스워드 찾기 */}
        <Route exact path={ROUTES_CM.FIND_PASSWORD} component={FindPassword} />

        {/* 아이디 찾기 */}
        <Route exact path={ROUTES_CM.FIND_ID} component={FindId} />

        {/* 인증 이메일 발송 완료 - 아이디 찾기 */}
        <Route exact path={ROUTES_CM.FIND_ID_EMAIL_SENT} component={FindIdEmailSent} />

        {/* 인증 이메일 발송 완료 - 비밀번호 찾기 */}
        <Route exact path={ROUTES_CM.FIND_PASSWORD_EMAIL_SENT} component={FindPasswordEmailSent} />

        {/* 비밀번호 재설정 */}
        <Route exact path={ROUTES_CM.RESET_PASSWORD} component={ResetPassword} />

        {/* 비밀번호 재설정 완료 */}
        <Route exact path={ROUTES_CM.RESET_PASSWORD_COMPLETE} component={ResetPasswordComplete} />

        {/* 셀프 온보딩 */}
        <Route exact path={ROUTES_CM.CREATE_FINANCIER} component={CreateFinancier} />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </div>
  );
}

export default CommonRouter;
