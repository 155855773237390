import type Pageable from 'models/Pageable';
import type {
  StatisticsOfAnchorAgreementVO,
  StatisticsOfAnchorAgreementVOModel,
} from 'models/vo/StatisticsOfAnchorAgreementVO';
import { formattingToStatisticsOfAnchorAgreementModel } from 'models/vo/StatisticsOfAnchorAgreementVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemStatisticsOfAnchorAgreementListRequest } from './requests';

export async function requestSystemAnchorStatisticsOfAnchorAgreementsList(
  pageNumber: number,
  rowCount: number,
  searchValue: SystemStatisticsOfAnchorAgreementListRequest,
): Promise<Pageable<StatisticsOfAnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<StatisticsOfAnchorAgreementVO[]>>({
    url: API_SY.STATISTICS_OF_ANCHOR_AGREEMENTS.STATISTICS_OF_ANCHOR_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  const statisticsOfAnchorAgreementList: StatisticsOfAnchorAgreementVOModel[] = response.content.map(
    (data: StatisticsOfAnchorAgreementVO) => formattingToStatisticsOfAnchorAgreementModel(data),
  );

  return {
    ...response,
    content: statisticsOfAnchorAgreementList,
  };
}
