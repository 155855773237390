import type { HTMLAttributes } from 'react';
import type React from 'react';

import './Badge.scss';

enum BadgeBgColor {
  'RED' = '#C72A32',
  'YELLOW' = '#FFBC00',
  'LIGHT_TURQUOISE' = '#E1EFF3',
  'LIGHT_OCHER' = '#FFF2D1',
  'LIGHT_RED' = 'rgba(255, 0, 13, 0.1)',
  'OCHER' = '#84612E',
}
enum BadgeFontcolor {
  'WHITE' = '#FFFFFF',
  'TURQUOISE' = '#235F62',
  'OCHER' = '#84612E',
  'RED' = '#C72A32',
  'LIGHT_YELLOW' = '#FEFCDE',
}

type BadgeBgColorType = keyof typeof BadgeBgColor;
type BadgeFontcolorType = keyof typeof BadgeFontcolor;

interface BadgeProps extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  content: string | number;
  variant: 'ellipse' | 'square';
  bgColor: BadgeBgColorType;
  fontColor: BadgeFontcolorType;
  style?: React.CSSProperties;
}

function Badge({ className, content, variant, bgColor, fontColor, style, ...props }: BadgeProps) {
  const styles = {
    ...style,
    backgroundColor: BadgeBgColor[bgColor],
    color: BadgeFontcolor[fontColor],
  };

  return (
    <div className={`${variant}-badge ${className ? className : ''}`} style={styles} {...props}>
      {content}
    </div>
  );
}

export default Badge;
