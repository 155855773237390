import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface AnchorPartnerAccountVO {
  anchorPartnerAccountId: number;
  currencyType: string;
  account: string;
  accountOwner: string;
  requestedAccountBankName: string;
  requestedAccountBranchName: string;
  declined: boolean;
  declinedReason: string;
  partnerName: string;
  partnerBusinessCode: string;
  partnerTaxCode: string;
  accountBankCodeId: number;
  accountBankName: string;
  accountBankCode: string;
  accountBranchName: string;
  accountBranchCode: string;
  anchorAgreementId: number;
  dealerAgreementId: number;
  anchorName: string;
  anchorAgreementContractNo: string;
  responsibleFinancierId: number;
  responsibleFinancierName: string;
  responsibleBranchId: number;
  responsibleBranchCode: string;
  responsibleBranchName: string;
  assignedBranchCode: string;
  assignedBranchName: string;
  createdDateTime: string;
}

export interface AnchorPartnerAccountVOModel extends AnchorPartnerAccountVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToAnchorPartnerAccountVOModel(data: AnchorPartnerAccountVO): AnchorPartnerAccountVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
