import { createContext, useContext, useMemo } from 'react';
import type { ReactNode } from 'react';
import type { FieldValues } from 'react-hook-form';
import type { UseFormMethods } from 'react-hook-form';
import type { Place } from 'react-tooltip';

import type { NewObject } from 'types';

import Control from './Control';
import Description from './Description';
import UncontrolledInput from './Input/UncontrolledInput';
import UncontrolledLabel from './Label/UncontrolledLabel';
import UncontrolledNumberInput from './NumberInput/UncontrolledNumberInput';
import { RadioGroup, RadioOption } from './RadioComponents';
import FormReactDatePickerInput from './ReactDatePicker/FormReactDatePickerInput';
import UncontrolledSelect from './Select/UncontrolledSelect';
import Value from './Value/Value';
import './form.scss';

type FormStateType<T extends NewObject> = {
  isEditable: boolean;
  methods: UseFormMethods<T>;
  isEditableStyle: boolean;
  getReadOnlyValue: (parameterName?: string) => boolean;
};

type FormPropsType<T extends FieldValues> = {
  isEditable?: boolean;
  methods: UseFormMethods<T>;
  isEditableStyle: boolean;
  getReadOnlyValue?: (parameterName?: string) => boolean;
  children: ReactNode;
};

export type TextAlignType = 'text-left' | 'text-right' | 'text-center';

export enum SizeType {
  SM = 'sm',
  MD = 'md',
  FU = 'full',
}

export type TooltipOptionType = {
  id: string;
  content: string | ReactNode;
  place?: Place;
};

const FormContext = createContext<FormStateType<any> | null>(null);

export const useFormContext = <T extends NewObject>() => {
  const context = useContext<FormStateType<T> | null>(FormContext);
  if (!context) {
    throw Error('useFormContext should be used within FormContext.Provider');
  }

  return context;
};

const FormRoot = <T extends FieldValues>({
  isEditable = false,
  methods,
  isEditableStyle = false,
  getReadOnlyValue = () => false, // default value
  children,
}: FormPropsType<T>) => {
  const value = useMemo(
    () => ({
      methods,
      isEditable,
      isEditableStyle,
      getReadOnlyValue,
    }),
    [methods, isEditable, getReadOnlyValue, isEditableStyle],
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

const Form = Object.assign(FormRoot, {
  Control,
  Input: UncontrolledInput,
  Label: UncontrolledLabel,
  NumberInput: UncontrolledNumberInput,
  Description,
  Radio: RadioGroup,
  Option: RadioOption,
  DatePickerInput: FormReactDatePickerInput,
  Select: UncontrolledSelect,
  Value,
});

export default Form;
