import type Pageable from 'models/Pageable';
import type { WaitingArVO, WaitingArVOModel } from 'models/vo/WaitingArVO';
import { formattingToWaitingArVOModel } from 'models/vo/WaitingArVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

export async function requestSystemWaitingArList(
  waitingArApprovalPhaseId: number,
  pageNumber?: number,
  rowCount?: number,
): Promise<Pageable<WaitingArVOModel[]>> {
  const response = await http.get<Pageable<WaitingArVO[]>>({
    url: API_SY.WAITING_ARS.WAITING_AR_LIST,
    data: {
      pageNumber,
      rowCount,
      waitingArApprovalPhaseId,
    },
  });
  const waitingArList: WaitingArVOModel[] = response.content.map((data: WaitingArVO) =>
    formattingToWaitingArVOModel(data),
  );

  return {
    ...response,
    content: waitingArList,
  };
}
