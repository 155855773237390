import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { ArCommissionSettlementVOModel } from 'models/vo/ArCommissionSettlementVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFiArCommissionSettlements } from 'utils/http/api/financier/ar-commision-settlements';
import type { SearchFiArCommissionSettlementsDTO } from 'utils/http/api/financier/ar-commision-settlements/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const FI_SETTLEMENT_PAYMENT_LIST_QS_KEY = 'fi-settlement-payment-list';

function FinancierWithdrawalFromPartnerList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();

  const [arCommissionSettlements, setArCommissionSettlements] = useState<Pageable<ArCommissionSettlementVOModel[]>>();
  const { pageable, setPageable } = usePageable(FI_SETTLEMENT_PAYMENT_LIST_QS_KEY);
  const arCommissionSettlementAscended = useRef(false);

  // useForm
  const { register, getValues, reset, setValue, control } = useForm<SearchFiArCommissionSettlementsDTO>();

  useEffect(() => {
    if (mounted) {
      setFormValues<SearchFiArCommissionSettlementsDTO>(
        setValue,
        getParsedSearchParams(FI_SETTLEMENT_PAYMENT_LIST_QS_KEY).formSearchData,
      );
      fetchAll(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  async function fetchAll(
    pageNumber: number = 1,
    rowCount: number = 10,
    searchData: SearchFiArCommissionSettlementsDTO,
  ) {
    try {
      const searchValue = { ...searchData, arSettlementAscended: arCommissionSettlementAscended.current };
      const fetchedArCommissionSettlements = await requestFiArCommissionSettlements(pageNumber, rowCount, searchValue);

      updateSearchParams(
        {
          ...searchValue,
          pageNumber,
          rowCount,
        },
        FI_SETTLEMENT_PAYMENT_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setArCommissionSettlements(fetchedArCommissionSettlements);
        setPageable(fetchedArCommissionSettlements);
      });
    } catch (e) {
      modal.show(e);
    }
  }

  const fetchAndSetSortedArSettlementList = async () => {
    try {
      arCommissionSettlementAscended.current = !arCommissionSettlementAscended.current;
      const searchData = {
        ...getParsedSearchParams(FI_SETTLEMENT_PAYMENT_LIST_QS_KEY).formSearchData,
        arSettlementAscended: arCommissionSettlementAscended.current,
      };

      const sortedArCommissionSettlements = await requestFiArCommissionSettlements(
        pageable.currentPage,
        pageable.sizePerPage,
        searchData,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setArCommissionSettlements(sortedArCommissionSettlements);
        setPageable(sortedArCommissionSettlements);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickSearch = async () => {
    arCommissionSettlementAscended.current = false;
    await fetchAll(1, pageable.sizePerPage, getValues());
  };

  const handleClickExport = async () => {
    const ExcelColumns: ColumnOption<any>[] = [
      {
        header: t('text:Settlement_Date'),
        key: 'settlementDate',
      },
      {
        header: t('text:Anchor_Name'),
        key: 'anchorName',
      },
      {
        header: t('text:Partner_Name'),
        key: 'partnerName',
      },
      {
        header: t('text:Currency'),
        key: 'currencyType',
      },
      {
        header: t('text:Total_Number_of_AR'),
        key: 'totalArCount',
      },
      {
        header: t('text:Total_Amount_of_AR'),
        key: 'totalArAmount',
      },
      {
        header: t('text:Total_Financing_Amount'),
        key: 'totalCollateralizedAmount',
      },
      {
        header: t('text:Total_Transaction_Fee'),
        key: 'totalArCommissionAmount',
      },
    ];

    const PDFColumns: HeaderType[] = [
      {
        headerText: t('text:Settlement_Date'),
      },
      {
        headerText: t('text:Anchor_Name'),
      },
      {
        headerText: t('text:Partner_Name'),
      },
      {
        headerText: t('text:Currency'),
      },
      {
        headerText: t('text:Total_Number_of_AR'),
      },
      {
        headerText: t('text:Total_Amount_of_AR'),
      },
      {
        headerText: t('text:Total_Financing_Amount'),
      },
      {
        headerText: t('text:Total_Transaction_Fee'),
      },
    ];

    try {
      const searchData = getParsedSearchParams(FI_SETTLEMENT_PAYMENT_LIST_QS_KEY).formSearchData;
      const arCommissionSettlementsExcel = await requestFiArCommissionSettlements(
        0,
        EXCEL_EXPORT_MAX_ROW_COUNT,
        searchData,
      );
      const arCommissionSettlementsPdf = arCommissionSettlementsExcel.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: any[] = arCommissionSettlementsExcel.content.map(data => ({
        settlementDate: tableValueManage(t('format:date', { value: data.settlementDate, key: 'date' })),
        anchorName: tableValueManage(data.anchorName),
        partnerName: tableValueManage(data.partnerName),
        currencyType: tableValueManage(data.currencyType),
        totalArCount: tableValueManage(data.totalArCount),
        totalArAmount: tableValueManage(data.totalArAmount),
        totalCollateralizedAmount: tableValueManage(data.totalCollateralizedAmount),
        totalArCommissionAmount: tableValueManage(data.totalArCommissionAmount),
      }));

      const renderPDFTableBodyResult = () => {
        return arCommissionSettlementsPdf?.map((item, i) => {
          return (
            <Tr key={i} className="virtual-table-row">
              <Td data={item.settlementDate} format="date" />
              <Td data={item.anchorName} />
              <Td data={item.partnerName} />
              <Td data={item.currencyType} />
              <Td data={item.totalArCount} format="number" />
              <Td data={item.totalArAmount} format="number" />
              <Td data={item.totalCollateralizedAmount} format="number" />
              <Td data={item.totalArCommissionAmount} format="number" />
            </Tr>
          );
        });
      };

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        columns: ExcelColumns,
        jsonArrayData: jsonArrayData,
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: PDFColumns,
        tableBody: renderPDFTableBodyResult(),
      };
      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchAll(page, sizePerPage, getParsedSearchParams(FI_SETTLEMENT_PAYMENT_LIST_QS_KEY).formSearchData);
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();
    reset();
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Settlement_Date'),
      hasSort: true,
      sortFunc: () => {
        fetchAndSetSortedArSettlementList();
      },
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Total_Number_of_AR'),
    },
    {
      headerText: t('text:Total_Amount_of_AR'),
    },
    {
      headerText: t('text:Total_Financing_Amount'),
    },
    {
      headerText: t('text:Total_Transaction_Fee'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return arCommissionSettlements?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.settlementDate} format="date" />
          <Td data={item.anchorName} />
          <Td data={item.partnerName} />
          <Td data={item.currencyType} />
          <Td data={item.totalArCount} format="number" />
          <Td data={item.totalArAmount} format="number" />
          <Td data={item.totalCollateralizedAmount} format="number" />
          <Td data={item.totalArCommissionAmount} format="number" />
          <TdLink
            path={ROUTES_FI.VIEW_TRANSACTION.SETTLEMENT_PAYMENT_DETAIL_BUILD_PATH(
              item.dealerAgreementId,
              item.settlementDate,
            )}
          />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Withdrawal_from_Partner')} />
      <GuideMessage
        message={[
          t(
            'text:This_page_is_designed_to_review_the_Transaction_Fees_and_Financing_Amount_that_need_to_be_withdrawan_on_the_AR_Settlement_date',
          ),
          t('text:On_the_detailed_page_Financier_can_check_the_AR_that_Anchor_pay_to_Partner_on_the_same_day'),
          t('text:You_can_export_the_data_in_the_list'),
        ]}
      />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Settlement_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={control} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorName" ref={register} />
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="partnerName" ref={register} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {12}
          </p>
          <Button variant={ButtonVariantEnum.OUTLINED} onClick={handleClickExport}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierWithdrawalFromPartnerList;
