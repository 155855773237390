import type * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import NotFoundPage from 'pages/NotFoundPage';
import AccessPage from 'pages/access/AccessPage';
import LoginPage from 'pages/common/login/LoginPage';
import AnchorRouter from 'router/anchor';
import DealerRouter from 'router/dealer';
import FinancierRouter from 'router/financier';
import PotentialPartnerRouter from 'router/potential-partner';
import { preventPageRedirectOnLoading } from 'utils/loadingUIManager/loadingUIManager';
import { homePathByRole } from 'utils/route';
import { ScrollToTop } from 'utils/scrollToTop';
import { getSignIn } from 'utils/storage/LocalStorage';

import { browserHistory } from './browserHistory';
import CommonRouter from './common';
import { PrivateRoute } from './guard/PrivateRoute';

preventPageRedirectOnLoading();

const redirectToHomeOrLogin = (props: any) => {
  const signInModel = getSignIn();

  if (signInModel) {
    return <Redirect to={homePathByRole(signInModel.authorities[0].authority)} />;
  } else {
    return <LoginPage {...props} />;
  }
};

const Root: React.FC = () => {
  return (
    <Router history={browserHistory}>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact render={(props: any) => redirectToHomeOrLogin(props)} />
        <Route path="/common/" component={CommonRouter} />
        <PrivateRoute path="/anchor/" component={AnchorRouter} />
        <PrivateRoute path="/dealer/" component={DealerRouter} />
        <PrivateRoute path="/financier/" component={FinancierRouter} />
        <Route path="/potential-partner/" component={PotentialPartnerRouter} />
        <Route exact path="/access" component={AccessPage} /> {/* 계정 없는 사용자 페이지 */}
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default Root;
