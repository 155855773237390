import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DeletedInvoiceVOModel } from 'models/vo/DeletedInvoiceVO';
import type { SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorDeletedInvoicesList } from 'utils/http/api/anchor/deleted-invoices';
import type { AnchorDeletedInvoicesListRequest } from 'utils/http/api/anchor/deleted-invoices/requests';
import { requestAnchorSuccessInvoiceList } from 'utils/http/api/anchor/success-invoices';
import type { AnchorSuccessInvoiceListRequest } from 'utils/http/api/anchor/success-invoices/requests';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

export const AC_REGISTERED_INVOICE_LIST_QS_KEY = 'ac-registered-invoice-list';
export const AC_DELETED_INVOICE_LIST_QS_KEY = 'ac-deleted-invoice-list';

const useAnchorInvoiceListController = () => {
  const modal = useModal();
  const mounted = useMounted();

  const [registeredInvoicePage, setRegisteredInvoicePage] = useState<Pageable<SuccessInvoiceVOModel[]>>();
  const [deletedInvoicePage, setDeletedInvoicePage] = useState<Pageable<DeletedInvoiceVOModel[]>>();

  const { pageable: registeredListPageable, setPageable: setRegisteredListPageable } = usePageable(
    AC_REGISTERED_INVOICE_LIST_QS_KEY,
  );

  const { pageable: deletedListPageable, setPageable: setDeletedListPageable } =
    usePageable(AC_DELETED_INVOICE_LIST_QS_KEY);

  const registeredInvoiceSearchForm = useForm<AnchorSuccessInvoiceListRequest>();
  const deletedInvoiceSearchForm = useForm<AnchorDeletedInvoicesListRequest>();

  const constructAndAddSearchParams = () => {
    const registeredTabQs = makeSearchParamsPattern(
      {
        ...registeredInvoiceSearchForm.getValues(),
        pageNumber: registeredListPageable.currentPage,
        rowCount: registeredListPageable.sizePerPage,
      },
      AC_REGISTERED_INVOICE_LIST_QS_KEY,
    );

    const deletedTabQs = makeSearchParamsPattern(
      {
        ...deletedInvoiceSearchForm.getValues(),
        pageNumber: deletedListPageable.currentPage,
        rowCount: deletedListPageable.sizePerPage,
      },
      AC_DELETED_INVOICE_LIST_QS_KEY,
    );

    addSearchParams(registeredTabQs + deletedTabQs);
  };
  useEffect(() => {
    if (mounted) {
      setFormValues<AnchorSuccessInvoiceListRequest>(
        registeredInvoiceSearchForm.setValue,
        getParsedSearchParams(AC_REGISTERED_INVOICE_LIST_QS_KEY).formSearchData,
      );
      setFormValues<AnchorDeletedInvoicesListRequest>(
        deletedInvoiceSearchForm.setValue,
        getParsedSearchParams(AC_DELETED_INVOICE_LIST_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const [registeredInvoiceResponse, deletedInvoiceResponse] = await Promise.all([
        requestAnchorSuccessInvoiceList(
          registeredListPageable.currentPage,
          registeredListPageable.sizePerPage,
          registeredInvoiceSearchForm.getValues(),
        ),
        requestAnchorDeletedInvoicesList(
          deletedListPageable.currentPage,
          deletedListPageable.sizePerPage,
          deletedInvoiceSearchForm.getValues(),
        ),
      ]);

      constructAndAddSearchParams();
      setRegisteredInvoicePage(registeredInvoiceResponse);
      setRegisteredListPageable(registeredInvoiceResponse);
      setDeletedInvoicePage(deletedInvoiceResponse);
      setDeletedListPageable(deletedInvoiceResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchAnchorRegisteredInvoiceList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: AnchorSuccessInvoiceListRequest,
  ) {
    try {
      const anchorSuccessArsPage = await requestAnchorSuccessInvoiceList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        AC_REGISTERED_INVOICE_LIST_QS_KEY,
      );

      setRegisteredInvoicePage(anchorSuccessArsPage);
      setRegisteredListPageable(anchorSuccessArsPage);
    } catch (e) {
      modal.show(e);
    }
  }

  const handleClickRegisteredInvoiceSearch = async () => {
    await fetchAnchorRegisteredInvoiceList(
      0,
      registeredListPageable.sizePerPage,
      registeredInvoiceSearchForm.getValues(),
    );
  };

  const paginateRegisteredInvoiceList = async (page: number, sizePerPage: number) => {
    await fetchAnchorRegisteredInvoiceList(
      page,
      sizePerPage,
      getParsedSearchParams(AC_REGISTERED_INVOICE_LIST_QS_KEY).formSearchData,
    );
  };

  async function fetchAnchorDeletedInvoiceList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: AnchorDeletedInvoicesListRequest,
  ) {
    try {
      const anchorDeletedArsPage = await requestAnchorDeletedInvoicesList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        AC_DELETED_INVOICE_LIST_QS_KEY,
      );

      setDeletedInvoicePage(anchorDeletedArsPage);
      setDeletedListPageable(anchorDeletedArsPage);
    } catch (e) {
      modal.show(e);
    }
  }

  const handleClickDeletedInvoiceSearch = async () => {
    await fetchAnchorDeletedInvoiceList(0, deletedListPageable.sizePerPage, deletedInvoiceSearchForm.getValues());
  };

  const paginateDeletedInvoiceList = async (page: number, sizePerPage: number) => {
    await fetchAnchorDeletedInvoiceList(
      page,
      sizePerPage,
      getParsedSearchParams(AC_DELETED_INVOICE_LIST_QS_KEY).formSearchData,
    );
  };

  return {
    registeredInvoicePage,
    deletedInvoicePage,
    registeredInvoiceSearchForm,
    deletedInvoiceSearchForm,
    registeredListPageable,
    deletedListPageable,
    handleClickRegisteredInvoiceSearch,
    paginateRegisteredInvoiceList,
    handleClickDeletedInvoiceSearch,
    paginateDeletedInvoiceList,
  };
};

export default useAnchorInvoiceListController;
