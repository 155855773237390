import { useTranslation } from 'react-i18next';

import { faArrowDown, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import featureIcon1 from 'assets/png/icon64Feature1.png';
import featureIcon2 from 'assets/png/icon64Feature2.png';
import featureIcon3 from 'assets/png/icon64Feature3.png';

import PPCard, { PPCardBackGroundType } from 'components/potential-partner/PPCard/PPCard';
import type { SuccessArGroupCurrencyTypeVOModel } from 'models/vo/SuccessArGroupCurrencyTypeVO';

import './PotentialPartnerHomeBanner.scss';
interface UploadedPartnerHomeBannerProps {
  data: SuccessArGroupCurrencyTypeVOModel[];
  partnerName: string;
}

const LEARN_MORE_LINK_ADDRESS = 'https://www.fin2b.com/solutions.html';

function PotentialPartnerHomeBanner({ data, partnerName }: UploadedPartnerHomeBannerProps) {
  const { t } = useTranslation(['format']);

  const PPBannerFeatures = () => {
    return (
      <div className="pp-banner__features">
        <PPCard backGroundType={PPCardBackGroundType.WarmGray20}>
          <div className="pp-banner__features__title__wrapper">
            <div className="pp-banner__features__title">{t('text:Benefits_of_Supply_Chain_Finance')}</div>
            <div className="pp-banner__features__link">
              <a href={LEARN_MORE_LINK_ADDRESS} target="_blank" rel="noreferrer">
                {t('text:Learn_more')}
              </a>
              <FontAwesomeIcon icon={faUpRightFromSquare} fontSize={12} />
            </div>
          </div>
          <div className="pp-banner__features__icons">
            <div>
              <img src={featureIcon1} alt="feature Icon 1" />
              <span className="pp-banner__features__desc">{t('text:Get_paid_early_from_buyers')}</span>
            </div>
            <div>
              <img src={featureIcon2} alt="feature Icon 2" />
              <span className="pp-banner__features__desc">{t('text:Finance_at_lower_costs')}</span>
            </div>
            <div>
              <img src={featureIcon3} alt="feature Icon 3" />
              <span className="pp-banner__features__desc">{t('text:Access_funds_conveniently_online')}</span>
            </div>
          </div>
        </PPCard>
      </div>
    );
  };

  const PPBannerFinancingAvailableInfo = () => {
    return (
      <div className="pp-banner__total-financing-available__wrapper">
        {data.map((item, index) => {
          return (
            <PPCard backGroundType={PPCardBackGroundType.Yellow95} key={index}>
              <div className="pp-banner__total-financing-available">
                {t('text:Financing_available_in_currencyType', {
                  currencyType: item.currencyType,
                })}
              </div>
              <div className="pp-banner__ar-info">
                <div className="d-flex">
                  <div className="pp-banner__ar-info__card">
                    <p className="pp-banner__ar-info__value">{item.totalArCount}</p>
                    <p className="pp-banner__ar-info__label">{t('text:AR(s)_included')}</p>
                  </div>
                  <div className="pp-banner__ar-info__card">
                    <p className="pp-banner__ar-info__value">{item.maxLtvRatio}%</p>
                    <p className="pp-banner__ar-info__label">{t('text:Maximum_LTV')}</p>
                  </div>
                </div>
                <div className="pp-banner__ar-info__amount">
                  <label>{item.currencyType}</label>
                  <span>{t('format:number', { value: item.ltvTotalArAmount })}</span>
                </div>
              </div>
            </PPCard>
          );
        })}
      </div>
    );
  };

  return (
    <div className="pp-banner">
      <div className="pp-title">
        <span className="pp-title__greeting">{t('text:Better_cash_flow_for')} </span>
        <span className="pp-title__partnerName">{partnerName}</span>
      </div>
      <PPBannerFinancingAvailableInfo />
      <PPBannerFeatures />
      <div className="pp-banner__scroll-icon__wrapper">
        <FontAwesomeIcon icon={faArrowDown} fontSize={28} />
      </div>
    </div>
  );
}

export default PotentialPartnerHomeBanner;
