import type { ReactElement } from 'react';

import type { TabViewProps } from './TabView.d';

function TabView({ tabViewList, isActiveTab }: TabViewProps): ReactElement {
  return (
    <>
      {tabViewList.map((item, index) => {
        return (
          <div key={index} className={isActiveTab(index) ? '' : 'd-none'}>
            {item}
          </div>
        );
      })}
    </>
  );
}

export default TabView;
