import { useTranslation } from 'react-i18next';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { LOAN_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { MultipleAnchorLoanRequestRelatedAnchorLoanVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedAnchorLoanVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface FinancierBulkAnchorFinancingDetailFinancingListProps {
  financingList: MultipleAnchorLoanRequestRelatedAnchorLoanVOModel[];
  paginate(pageNumber: number, rowCount: number): Promise<void>;
  pageable: PageableType;
  isAr: boolean;
}

function FinancierBulkAnchorFinancingDetailFinancingList({
  financingList,
  paginate,
  pageable,
  isAr,
}: FinancierBulkAnchorFinancingDetailFinancingListProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const handleMultipleLoanStatusQuestionMarkClick = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="ANCHOR_LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };
  const FINANCING_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
    },
    {
      headerText: isAr ? t('text:AR_Number') : t('text:Invoice_Number'),
    },
    {
      headerText: t('text:Associated_Partner_Name'),
    },
    {
      headerText: t('text:Requested_Financing_Amount'),
    },
    {
      headerText: t('text:Scheduled_Disbursement_Date'),
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
    },
    {
      headerText: t('text:Financing_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: handleMultipleLoanStatusQuestionMarkClick,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_List')} />
      <TableBorder>
        <TableHeader header={FINANCING_LIST_TABLE_HEADERS} />
        <TableBody numOfCol={FINANCING_LIST_TABLE_HEADERS.length}>
          {financingList?.map((item, index: number) => (
            <Tr key={index}>
              <Td data={item.anchorLoanId} />
              <Td data={isAr ? item.arNumber : item.invoiceNumber} />
              <Td data={isAr ? item.anchorPartnerName : item.dealerClientName} />
              <Td data={item.requestedAmount} format="number" />
              <Td data={isAr ? item.desiredDisbursementDate : ''} format="date" />
              <Td data={isAr ? '' : item.desiredDisbursementDate} format="date" />
              <Td
                data={t(`code:financing-status.${item.status}`)}
                className={getStatusTextClass('LOAN_STATUS', item.status)}
                format="code"
              />
              <TdLink path={ROUTES_FI.MANAGE_ANCHOR_FINANCING.DETAIL_BUILD_PATH(item.anchorLoanId)} />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default FinancierBulkAnchorFinancingDetailFinancingList;
