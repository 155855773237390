import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { isNil } from 'lodash-es';

import type { COLLATERAL_TYPE } from 'enums';
import type { DealerAgreementCommonVOModel } from 'models/convertor/dealerAgreement';
import { convertToDealerAgreementCommonVOModel } from 'models/convertor/dealerAgreement';
import type { TermSpreadVOModel } from 'models/vo/TermSpreadVO';
import { requestFinancierDealerAgreementDetail } from 'utils/http/api/financier/dealer-agreements';
import { requestFinancierTempWaitingDealerAgreementDetail } from 'utils/http/api/financier/temp-waiting-dealer-agreements';
import { requestFinancierWaitingDealerAgreementDetail } from 'utils/http/api/financier/waiting-dealer-agreements';
import useModal from 'utils/modal/useModal';

import { AgreementContext } from './AgreementContext';
import { AnchorAgreementInfoContext, initialAnchorAgreementInfoState } from './AnchorAgreementInfoContext';
import { TermSpreadContext } from './TermSpreadContext';

import type { AgreementType } from './AgreementContext';
import type { AnchorAgreementBeforeConvertedType, AnchorAgreementInfoType } from './AnchorAgreementInfoContext';

type AgreementProviderPropsType = {
  children: ReactNode;
};

const AgreementProvider = ({ children }: AgreementProviderPropsType) => {
  const { show: showModal } = useModal();

  const [agreement, setAgreement] = useState<AgreementType>({} as DealerAgreementCommonVOModel);
  const [termSpreadList, setTermSpreadList] = useState<TermSpreadVOModel[] | null>(null);
  const [anchorAgreementInfo, setAnchorAgreementInfo] = useState<AnchorAgreementInfoType>(
    initialAnchorAgreementInfoState,
  );
  const [isSearchedAgreement, setIsSearchedAgreement] = useState(false);
  const [isMaturityExtension, setIsMaturityExtension] = useState<string | null>(null);

  const isFirstRegisteredWaitingAgreement = isNil(agreement.dealerAgreementId);
  const isPartnerInfoExist = !isNil(agreement.dealerClientId);
  const isAssociatedAnchorAgreementExist = !isNil(anchorAgreementInfo.anchorClientId);

  const updateAgreement = (agreement: Partial<AgreementType>) =>
    setAgreement(prevAgreement => ({
      ...prevAgreement,
      ...agreement,
    }));

  const updateTermSpreadList = (termSpreadList: TermSpreadVOModel[]) => setTermSpreadList(termSpreadList);

  const updateIsSearchedAgreement = (isSearchedAgreement: boolean) => setIsSearchedAgreement(isSearchedAgreement);

  const updateIsMaturityExtension = (isMaturityExtension: string) => setIsMaturityExtension(isMaturityExtension);

  const updateAnchorAgreementInfo = (anchorAgreementInfo?: AnchorAgreementBeforeConvertedType) =>
    setAnchorAgreementInfo({
      anchorClientId: anchorAgreementInfo?.anchorClientId,
      anchorAgreementNo: anchorAgreementInfo?.mainContractNo ?? '',
      anchorClientName: anchorAgreementInfo?.anchorClientName ?? '',
      anchorAgreementStartDate: anchorAgreementInfo?.anchorAgreementStartDate ?? '',
      anchorAgreementExpiryDate: anchorAgreementInfo?.anchorAgreementExpiryDate ?? '',
      anchorUserList: anchorAgreementInfo?.anchorUserList ?? [],
    });

  const updateAllAgreement = useCallback((agreement: DealerAgreementCommonVOModel) => {
    const {
      termSpreadList,
      anchorClientId,
      mainContractNo,
      anchorClientName,
      anchorAgreementStartDate,
      anchorAgreementExpiryDate,
      anchorUserList,
      ...restSavedAgreement
    } = agreement;

    updateTermSpreadList(termSpreadList);
    updateAgreement(restSavedAgreement);
    updateAnchorAgreementInfo({
      anchorClientId,
      mainContractNo,
      anchorClientName,
      anchorAgreementStartDate,
      anchorAgreementExpiryDate,
      anchorUserList,
    });
  }, []);

  const fetchSavedAgreement = useCallback(
    async (collateralType: COLLATERAL_TYPE) => {
      try {
        const savedAgreement = await requestFinancierTempWaitingDealerAgreementDetail(collateralType);

        // 보통 데이터 fetching 후 바로 setState 하지만 임시 저장의 경우 사용하지 않을 수 있기 때문에 여기서는 하지 않음
        return convertToDealerAgreementCommonVOModel(savedAgreement);
      } catch (error) {
        showModal(error);

        return {} as DealerAgreementCommonVOModel;
      }
    },
    [showModal],
  );

  const fetchWaitingAgreement = useCallback(
    async (waitingDealerAgreementId: number) => {
      try {
        const waitingAgreement = await requestFinancierWaitingDealerAgreementDetail(waitingDealerAgreementId);

        return convertToDealerAgreementCommonVOModel(waitingAgreement);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal, updateAllAgreement],
  );

  const fetchAgreementDetail = useCallback(
    async (dealerAgreementId: number) => {
      try {
        const agreementDetail = await requestFinancierDealerAgreementDetail(dealerAgreementId);

        return convertToDealerAgreementCommonVOModel(agreementDetail);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal, updateAllAgreement],
  );

  const agreementValue = useMemo(
    () => ({
      agreement,
      isSearchedAgreement,
      isPartnerInfoExist,
      isMaturityExtension,
      isFirstRegisteredWaitingAgreement,
      updateAgreement,
      updateIsSearchedAgreement,
      updateIsMaturityExtension,
      updateAllAgreement,
      fetchSavedAgreement,
      fetchWaitingAgreement,
      fetchAgreementDetail,
    }),
    [
      agreement,
      isSearchedAgreement,
      isPartnerInfoExist,
      isMaturityExtension,
      isFirstRegisteredWaitingAgreement,
      updateAllAgreement,
      fetchSavedAgreement,
      fetchWaitingAgreement,
      fetchAgreementDetail,
    ],
  );

  const termSpreadValue = useMemo(
    () => ({
      termSpreadList,
      updateTermSpreadList,
    }),
    [termSpreadList],
  );

  const anchorAgreementInfoValue = useMemo(
    () => ({
      anchorAgreementInfo,
      isAssociatedAnchorAgreementExist,
      updateAnchorAgreementInfo,
    }),
    [anchorAgreementInfo, isAssociatedAnchorAgreementExist],
  );

  return (
    <AgreementContext.Provider value={agreementValue}>
      <TermSpreadContext.Provider value={termSpreadValue}>
        <AnchorAgreementInfoContext.Provider value={anchorAgreementInfoValue}>
          {children}
        </AnchorAgreementInfoContext.Provider>
      </TermSpreadContext.Provider>
    </AgreementContext.Provider>
  );
};

export default AgreementProvider;
