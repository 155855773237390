import type Pageable from 'models/Pageable';
import { formattingToDealerAuthorizerDetailVOModel } from 'models/vo/DealerAuthorizerDetailVO';
import type { DealerAuthorizerDetailVO, DealerAuthorizerDetailVOModel } from 'models/vo/DealerAuthorizerDetailVO';
import { formattingToDealerAuthorizerVOModel } from 'models/vo/DealerAuthorizerVO';
import type { DealerAuthorizerVO, DealerAuthorizerVOModel } from 'models/vo/DealerAuthorizerVO';
import { formattingToUserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import type { UserEmailSettingVO, UserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierPartnerUserEmailSettingUpdateRequest } from './requests';
import type { FinancierPartnerUserListRequest } from './requests';

export async function requestFinancierPartnerUserList(
  pageNumber: number,
  rowCount: number,
  searchData?: FinancierPartnerUserListRequest,
): Promise<Pageable<DealerAuthorizerVOModel[]>> {
  const response = await http.get<Pageable<DealerAuthorizerVO[]>>({
    url: API_FI.PARTNER_USERS.PARTNER_USERS_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchData,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToDealerAuthorizerVOModel(data)),
  };
}

export async function requestFinancierPartnerUserDetail(
  dealerAgreementId: number,
): Promise<DealerAuthorizerDetailVOModel> {
  const response = await http.get<DealerAuthorizerDetailVO>({
    url: API_FI.PARTNER_USERS.PARTNER_USERS_FIND_BY_DEALER_AGREEMENT_ID,
    data: {
      'dealer-agreement-id': dealerAgreementId,
    },
  });

  return formattingToDealerAuthorizerDetailVOModel(response);
}

export async function requestFinancierDealerUserEmailSettingList(userId: number): Promise<UserEmailSettingVOModel[]> {
  const response = await http.get<UserEmailSettingVO[]>({
    url: API_FI.PARTNER_USERS.RELATED_USER_EMAIL_SETTING(userId),
  });

  return response.map(item => formattingToUserEmailSettingVOModel(item));
}

export async function requestFinancierDealerUserEmailSettingUpdate(
  userId: number,
  data: FinancierPartnerUserEmailSettingUpdateRequest,
): Promise<UserEmailSettingVOModel[]> {
  const response = await http.put<UserEmailSettingVO[]>({
    url: API_FI.PARTNER_USERS.RELATED_USER_EMAIL_SETTING(userId),
    data,
  });

  return response.map(item => formattingToUserEmailSettingVOModel(item));
}
