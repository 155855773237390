import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormBorder, FormContents, FormSelect } from 'components/stateless/CommonForm';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import { OTP_TYPE } from 'enums';
import type { CreateFinancierAndAdminUserDTO } from 'utils/http/api/anonymous/on-demand/requests';

type OtpSettingsFormType = Pick<CreateFinancierAndAdminUserDTO, 'otpType'>;

const OtpSettings = () => {
  const { t } = useTranslation();

  const { register, errors } = useFormContext<OtpSettingsFormType>();

  return (
    <>
      <SectionTitle title={t('text:OTP_Settings')} />
      <FormBorder editable={true}>
        <FormContents>
          <div className="row">
            <FormSelect
              label={t('text:OTP_Type')}
              selectOptions={getSelectOptions<OTP_TYPE>('OTP_TYPE', [OTP_TYPE.NONE, OTP_TYPE.EMAIL])}
              name="otpType"
              ref={register}
              required
              error={errors.otpType}
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
};

export default OtpSettings;
