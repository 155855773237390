import { DAY_OF_WEEK, stringToEnum } from 'enums';

export interface FinancierCalenderVO {
  id: number;
  year: number;
  month: number;
  day: number;
  solarDate: string;
  dayOfWeek: string;
  holiday: boolean;
  description: string;
}

export interface FinancierCalenderVOModel extends FinancierCalenderVO {
  dayOfWeek: DAY_OF_WEEK;
}

export function formattingToFinancierCalenderVOModel(data: FinancierCalenderVO): FinancierCalenderVOModel {
  return {
    ...data,
    dayOfWeek: stringToEnum(DAY_OF_WEEK, data.dayOfWeek),
  };
}
