import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { FINANCIER_INTERFACE_TYPE } from 'enums';
import useProperty from 'hooks/useProperty';
import type { BranchVOModel } from 'models/vo/BranchVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestBranchRegister } from 'utils/http/api/financier/branches';
import type { FinancierBranchRegisterRequest } from 'utils/http/api/financier/branches/request';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import SearchBranchModal from './Modals/SearchBranchModal';

function FinancierBranchRegister() {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();
  const cancelModal = useModal();
  const errorAlert = useModal();
  const getProperty = useProperty<FinancierBranchRegisterRequest>();
  const [interfaceData, setInterfaceData] = useState<BranchVOModel>();
  const [branchInterfaceEnable] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.BRANCH));

  const {
    register: branchRegister,
    handleSubmit: branchRegisterSubmit,
    getValues: getBranchRegisterValue,
    errors,
    setError,
    clearErrors,
    reset,
  } = useForm<FinancierBranchRegisterRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  // Register button
  const onSubmit = async () => {
    try {
      const data = getBranchRegisterValue();

      requestDTOParser(data);
      await requestBranchRegister(data);
      showSaveConfirmModal();
    } catch (e) {
      errorAlert.show(e);

      formErrorHandler<FinancierBranchRegisterRequest>(e, setError, clearErrors);
    }
  };

  const showSaveConfirmModal = async () => {
    const registerModalOptions: ModalOptions = {
      modalType: ModalType.ALERT,
      title: t('text:Notice'),
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        history.push(ROUTES_FI.SETTINGS.BRANCH_LIST);
      },
    };
    modal.show(<h6>{t('text:Branch_registration_is_complete')}</h6>, registerModalOptions);
  };

  // cancel button handler
  const handleCancel = (e: any) => {
    e.preventDefault();
    const cancelModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Close'),
      confirmBtnCb: async () => {
        history.push(ROUTES_FI.SETTINGS.BRANCH_LIST);
      },
    };
    cancelModal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration')}
        <br />
        {t('text:If_the_registration_is_aborted,_the_entered_content_will_not_be_saved')}
      </h6>,
      cancelModalOptions,
    );
  };

  const showSearchBranchModal = () => {
    modal.show(<SearchBranchModal state={{} as BranchVOModel} />, {
      modalType: ModalType.CONFIRM,
      modalSize: ModalSize.XL,
      title: t('text:Search_Branch'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Apply'),
      confirmBtnCb: (data: any) => {
        if (!data.state.branchCode) return;

        ReactDOM.unstable_batchedUpdates(() => {
          setForm(data.state);
          setInterfaceData(data.state);
        });
      },
    });
  };

  const setForm = (data: BranchVOModel) => {
    reset({
      branchCode: data.branchCode,
      branchName: data.branchName,
      branchBusinessCode: data.branchBusinessCode,
      telephone: data.telephone,
      fax: data.fax,
      managerName: data.managerName,
      managerPosition: data.managerPosition,
      managerEmail: data.managerEmail,
      address: data.address,
    });
  };

  const isReadOnly = (parameterName: string) => {
    if (branchInterfaceEnable) {
      if (!interfaceData) return true;

      if (!(interfaceData as any)[parameterName]) return false;

      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Branch_Registration')} />

      {branchInterfaceEnable ? (
        <GuideMessage
          message={[
            t('text:Click_the_Search_Branch_button_to_retrieve_branch_information_from_the_banking_system'),
            t('text:You_can_enter_the_information_that_was_not_retrieved_from_the_banking_system'),
            <Trans
              key="key"
              i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
              components={{ 1: <span className="asterisk" /> }}
            />,
          ]}
        />
      ) : (
        <GuideMessage
          message={[
            t('text:Enter_the_branch_information_below'),
            <Trans
              key="key"
              i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
              components={{ 1: <span className="asterisk" /> }}
            />,
          ]}
        />
      )}
      <div className="content-area">
        <FormBorder editable>
          <FormContents>
            {branchInterfaceEnable && (
              <div className="row">
                <div className="col-12">
                  <Button size={ButtonSizeEnum.LG} onClick={showSearchBranchModal}>
                    {t('text:Search_Branch')}
                  </Button>
                </div>
              </div>
            )}
            <div className="row">
              <FormInput
                label={t('text:Branch_Code')}
                name={getProperty('branchCode')}
                ref={branchRegister}
                requiredOptions={{ required: true }}
                disabled={isReadOnly('branchCode')}
                error={errors.branchCode}
              />
              <FormInput
                label={t('text:Branch_Name')}
                name={getProperty('branchName')}
                ref={branchRegister}
                requiredOptions={{ required: true }}
                disabled={isReadOnly('branchName')}
                error={errors.branchName}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Business_Registration_Number')}
                name={getProperty('branchBusinessCode')}
                ref={branchRegister}
                disabled={isReadOnly('branchBusinessCode')}
                error={errors.branchBusinessCode}
              />
              <FormInput
                col={3}
                label={t('text:Telephone')}
                name={getProperty('telephone')}
                ref={branchRegister}
                requiredOptions={{ required: true }}
                disabled={isReadOnly('telephone')}
                error={errors.telephone}
              />
              <FormInput
                col={3}
                label={t('text:Fax')}
                name={getProperty('fax')}
                ref={branchRegister}
                disabled={isReadOnly('fax')}
                error={errors.fax}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Legal_Representative_Name')}
                name={getProperty('managerName')}
                ref={branchRegister}
                disabled={isReadOnly('managerName')}
                error={errors.managerName}
              />
              <FormInput
                col={3}
                label={t('text:Legal_Representative_Title')}
                name={getProperty('managerPosition')}
                ref={branchRegister}
                disabled={isReadOnly('managerPosition')}
                error={errors.managerPosition}
              />
              <FormInput
                col={3}
                label={t('text:Legal_Representative_Email')}
                name={getProperty('managerEmail')}
                ref={branchRegister}
                disabled={isReadOnly('managerEmail')}
                error={errors.managerEmail}
              />
            </div>
            <div className="row">
              <FormInput
                col={12}
                label={t('text:Registered_Office_Address')}
                name={getProperty('address')}
                ref={branchRegister}
                requiredOptions={{ required: true }}
                disabled={isReadOnly('address')}
                error={errors.address}
              />
            </div>
          </FormContents>
        </FormBorder>

        <div className="flex-end mt-4">
          <Button
            onClick={handleCancel}
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={branchRegisterSubmit(onSubmit)} className="ms-2">
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default FinancierBranchRegister;
