import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PPTableBorder from 'components/potential-partner/PPTable/PPTableBorder';
import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import TableBody from 'components/stateless/Table/TableBody';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_PP } from 'constants/routes/potentialPartner';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import { ResourceNotFoundExceptionCode } from 'enums/exception';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import { HttpError } from 'utils/error/HttpError';
import {
  requestAnPotentialPartnerFinancingApplicationSubmit,
  requestAnPotentialPartnerFinancingApplicationsActivatedApplication,
} from 'utils/http/api/anonymous/potential-partner-financing-applications';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';
import { hasValue, requestDTOParser, tableValueManage } from 'utils/valueManager/ValueManager';

import {
  usePotentialPartnerApplicationActions,
  usePotentialPartnerApplicationValue,
} from '../../PotentialPartnerApplicationProvider';

import type { PotentialPartnerApplicationPageLocationStateType } from '../logics/types';

interface PotentialPartnerApplicationStep3Props {
  documentInfo: PotentialPartnerAcquisitionDocumentVOModel[];
}

function PotentialPartnerApplicationStep3({ documentInfo }: PotentialPartnerApplicationStep3Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();

  const { anchorClientName, financierName, currencyType, anchorPartnerAccountId } =
    useLocation<PotentialPartnerApplicationPageLocationStateType>().state;
  const { setPrevFormStep, onClickCancelSubmissionBtn } = usePotentialPartnerApplicationActions();
  const { formData, selectedBranchName } = usePotentialPartnerApplicationValue();

  const DOCUMENT_LISTS_HEADER = [
    {
      headerText: t('text:Document_Name'),
    },
    {
      headerText: t('text:Attached_File'),
    },
  ];

  const checkPreviousApplication = async () => {
    const emailCode = getPotentialPartnerEmailCode();
    if (!emailCode) return modal.show('not found email code');
    try {
      const previousSubmissionInfo = await requestAnPotentialPartnerFinancingApplicationsActivatedApplication(
        emailCode,
        anchorPartnerAccountId,
      );
      const { potentialPartnerFinancingApplicationId } = previousSubmissionInfo;

      if (previousSubmissionInfo && hasValue(potentialPartnerFinancingApplicationId)) {
        if (
          previousSubmissionInfo.potentialPartnerFinancingApplicationStatus ===
          POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED
        ) {
          modal.show(
            <h6>
              {t('text:Your_Partner_Master_Agreement_with_financierName_has_been_registered_on_the_platform', {
                financierName: previousSubmissionInfo.financierName,
              })}
              <br />
              {t('text:You_can_see_the_registered_AR_details_by_logging_into_the_platform')}
              <br />
              {t('text:Would_you_like_to_proceed_to_the_platform?')}
            </h6>,
            {
              modalType: ModalType.CONFIRM,
              confirmBtnCb: () => history.push('/'),
            },
          );
        } else if (
          previousSubmissionInfo.potentialPartnerFinancingApplicationStatus ===
            POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED ||
          previousSubmissionInfo.potentialPartnerFinancingApplicationStatus ===
            POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS
        ) {
          // In Progress / Submitted 있다면 제출 상세 페이지로 이동
          modal.show(
            <h6>
              {t('text:You_have_already_submitted_documents_for_this_Financier_and_Buyer_company')}
              <br />
              {t(
                'text:To_resubmit_the_application_you_must_cancel_the_previous_submission_in_the_details_page_before_proceeding',
              )}
            </h6>,
            {
              modalType: ModalType.CONFIRM,
              confirmBtnText: t('text:View_Submission'),
              confirmBtnCb: () => {
                history.push(ROUTES_PP.MY_INFO.SUBMISSION_DETAIL_BUILD_PATH(potentialPartnerFinancingApplicationId));
              },
            },
          );
        }
      }
    } catch (error) {
      if (error instanceof HttpError) {
        if (error.code === ResourceNotFoundExceptionCode.POTENTIAL_PARTNER_FINANCING_APPLICATION_NOT_FOUND) {
          onClickSubmit();
        } else {
          modal.show(error);
        }
      }
    }
  };

  const onClickSubmit = () => {
    const emailCode = getPotentialPartnerEmailCode();
    if (!emailCode) return;
    modal.show(<h6>{t('text:Would_you_like_to_submit_your_application_to_the_Financier?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: async () => {
        try {
          requestDTOParser(formData);
          const res = await requestAnPotentialPartnerFinancingApplicationSubmit({
            ...formData,
            emailCode,
          });
          history.push(ROUTES_PP.APPLICATION.RESULT, {
            potentialPartnerFinancingApplicationId: res.potentialPartnerFinancingApplicationId,
          });
        } catch (e) {
          modal.show(e);
        }
      },
    });
  };

  return (
    <>
      <div className="pp-mb-56">
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t(`text:Financier_Name`)} value={financierName} />
              <FormValue label={t(`text:Preferred_Branch`)} value={selectedBranchName} />
            </div>
            <div className="row">
              <FormValue label={t(`text:Buyer_Name`)} value={anchorClientName} />
              <FormValue label={t(`text:Selected_Currency`)} value={currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="pp-mb-56">
        <SectionTitle title={t('text:My_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Company_Name')} value={formData.partnerName} />
              <FormValue label={t('text:Tax_Code')} value={formData.partnerTaxCode} />
            </div>
            <div className="row">
              <FormValue label={t('text:Company_Registration_Number')} value={formData.partnerBusinessCode} />
              <FormValue label={t('text:Telephone')} col={3} value={formData.telephone} />
              <FormValue label={t('text:Fax')} col={3} value={formData.fax} />
            </div>
            <div className="row">
              <FormValue label={t('text:Legal_Representative_Name')} value={formData.representativeName} />
              <FormValue label={t('text:Legal_Representative_Title')} col={3} value={formData.representativeTitle} />
              <FormValue label={t('text:Legal_Representative_Email')} col={3} value={formData.representativeEmail} />
            </div>
            <div className="row">
              <FormValue label={t('text:Registered_Office_Address')} col={12} value={formData.address} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div>
        <SectionTitle title={t('text:My_Documents')} />
        <PPTableBorder>
          <TableHeader header={DOCUMENT_LISTS_HEADER} />
          <TableBody numOfCol={DOCUMENT_LISTS_HEADER.length}>
            <>
              {documentInfo?.map((item: PotentialPartnerAcquisitionDocumentVOModel, index: number) => (
                <Tr key={index}>
                  <Td>
                    <div className="pp-table-td__data">
                      <div className="bold-font">{tableValueManage(item.documentName)}</div>
                      <div> {item.description}</div>
                    </div>
                  </Td>
                  <Td>
                    <div className="pp-table-td__data">
                      <div>{tableValueManage(formData.requiredAttachment[index].item(0)?.name)}</div>
                      <div className="pp-table-td__data--lighten">{formData.requiredDescription[index]}</div>
                    </div>
                  </Td>
                </Tr>
              ))}
              {formData?.additionalAttachment?.map((item: FileList, index: number) => (
                <Tr key={index}>
                  <Td>
                    <div className="pp-table-td__data bold-font">{t('text:Additional_Documents')}</div>
                  </Td>
                  <Td>
                    <div className="pp-table-td__data">
                      <div>{item[0]?.name}</div>
                      <div className="pp-table-td__data--lighten">{formData.additionalDescription[index]}</div>
                    </div>
                  </Td>
                </Tr>
              ))}
              {documentInfo?.length === 0 && formData?.additionalAttachment?.length === 0 && (
                <tr>
                  <td colSpan={DOCUMENT_LISTS_HEADER.length} className="text-center">
                    {t('text:no_data_available')}
                  </td>
                </tr>
              )}
            </>
          </TableBody>
        </PPTableBorder>
      </div>
      <div className="pp-mt-96 d-flex justify-content-between">
        <Button2 color={Button2ColorEnum.TERTIARY} size={Button2SizeEnum.LG} onClick={onClickCancelSubmissionBtn}>
          {t('text:Cancel_application')}
        </Button2>
        <div className="d-flex flex-end">
          <Button2
            size={Button2SizeEnum.LG}
            color={Button2ColorEnum.TERTIARY}
            icon={<FontAwesomeIcon icon={faArrowLeft} fontSize={19.25} />}
            onClick={() => setPrevFormStep()}
            className="pp-mr-16 pp-step3-previous-page-btn"
          >
            {t('text:Previous_page')}
          </Button2>
          <Button2
            size={Button2SizeEnum.LG}
            icon={<FontAwesomeIcon icon={faCheck} fontSize={19.25} />}
            onClick={checkPreviousApplication}
          >
            {t('text:Submit_for_review')}
          </Button2>
        </div>
      </div>
    </>
  );
}

export default PotentialPartnerApplicationStep3;
