import { useExtraInformationContext } from './Context';

const useExtraInformationViewModel = () => {
  const { supportedCollateralType, errorHandlerOfLocationState, anchorFinancingOptionId } =
    useExtraInformationContext();

  return {
    supportedCollateralType,
    anchorFinancingOptionId,
    errorHandlerOfLocationState,
  };
};

export default useExtraInformationViewModel;
