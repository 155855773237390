import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import type { PageableType } from 'hooks/usePageable';
import type { ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { getDayTerm, getPrevDay } from 'utils/date/date';

interface SystemFinancingDetailExtensionHistoryProps {
  extensionListData: ExtensionRequestVOModel[];
  pageable: PageableType;
  paginate(pageNumber: number, rowCount: number): Promise<void>;
}
function SystemFinancingDetailExtensionHistory({
  extensionListData,
  pageable,
  paginate,
}: SystemFinancingDetailExtensionHistoryProps) {
  const { t } = useTranslation(['format']);

  const EXTENSION_LIST_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Original_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Modified_Repayment_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Extension_Period'),
      colWidths: 120,
    },
    {
      headerText: t('text:Added_Spread_(APR)'),
      colWidths: 80,
    },
    {
      headerText: t('text:Status'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_Extension_History')} />
      <TableBorder>
        <TableHeader header={EXTENSION_LIST_TABLE_HEADER} />
        <TableBody numOfCol={EXTENSION_LIST_TABLE_HEADER.length}>
          {extensionListData?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td data={item.createdDateTime} format="datetime" />
                <Td data={getPrevDay(item.extensionStartDate)} format="date" />
                <Td data={item.extendedRepaymentDate} format="date" />
                <Td
                  data={getDayTerm(item.extensionStartDate, item.extendedRepaymentDate, {
                    includeStartDate: true,
                    withText: true,
                  })}
                />
                <Td data={item.additionalRate} format="percent" />
                <Td
                  data={t(`code:extension-request-status.${item.extensionRequestStatus}`)}
                  className={getStatusTextClass('EXTENSION_REQUEST_STATUS', item.extensionRequestStatus)}
                  format="code"
                />
                <TdLink
                  path={ROUTES_SY.MONITOR_FINANCING.EXTENSION_DETAIL_BUILD_PATH(item.extensionRequestId)}
                  state={{ financierId: item.financierId }}
                />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default SystemFinancingDetailExtensionHistory;
