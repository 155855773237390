import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import QuestionButton from 'components/stateless/QuestionButton';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { EARLY_REPAYMENT_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { requestSystemEarlyRepaymentDetail } from 'utils/http/api/system/early-repayment-requests';
import { requestSystemLoanDetail } from 'utils/http/api/system/loans';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

function SystemEarlyRepaymentDetail() {
  const mounted = useMounted();
  const history = useHistory();
  const earlyRepaymentRequestId = (useParams() as any).id;
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const [systemEarlyRepaymentDetail, setSystemEarlyRepaymentDetail] = useState<EarlyRepaymentRequestVOModel>(
    {} as EarlyRepaymentRequestVOModel,
  );
  const [systemLoanDetail, setSystemLoanDetail] = useState<LoanDetailVOModel>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const systemEarlyRepaymentDetailData = await requestSystemEarlyRepaymentDetail(earlyRepaymentRequestId);
      const systemLoanDetailData = await requestSystemLoanDetail(systemEarlyRepaymentDetailData.loanId);

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemEarlyRepaymentDetail(systemEarlyRepaymentDetailData);
        setSystemLoanDetail(systemLoanDetailData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getStatusText = (status: EARLY_REPAYMENT_REQUEST_STATUS | string): string => {
    switch (status) {
      case EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED:
        return t('text:Requested');
      case EARLY_REPAYMENT_REQUEST_STATUS.COMPLETED:
        return t('text:Completed');
      case EARLY_REPAYMENT_REQUEST_STATUS.EXPIRED:
        return t('text:Rejected');
      default:
        return '';
    }
  };

  const onClickEarlyRepaymentRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EARLY_REPAYMENT_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EARLY_REPAYMENT_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Early_Repayment_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Early_Repayment_Request_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Requested_Date')}
                value={systemEarlyRepaymentDetail.createdDateTime}
                format="datetime"
                col={6}
              />
              <div className="col-6">
                <div className="information-form__label">
                  {t('text:Status')}
                  <QuestionButton onClick={onClickEarlyRepaymentRequestStatus} />
                </div>
                <div className="information-form__input border-none status-container">
                  <div
                    className={getStatusBadgeClass(
                      'EARLY_REPAYMENT_REQUEST_STATUS',
                      systemEarlyRepaymentDetail.earlyRepaymentRequestStatus,
                    )}
                  >
                    {getStatusText(systemEarlyRepaymentDetail.earlyRepaymentRequestStatus)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <FormValue
                label={t('text:Requested_Repayment_Date')}
                value={systemEarlyRepaymentDetail.intendedRepaymentDate}
                format="date"
              />
              <FormValue
                label={t('text:Original_Repayment_Date')}
                value={systemEarlyRepaymentDetail.repaymentDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Partner_Name')} value={systemEarlyRepaymentDetail.dealerClientName} col={6} />
              <FormValue
                label={t('text:Partner_Client_Code')}
                value={systemEarlyRepaymentDetail.dealerClientCode}
                col={6}
              />
            </div>
          </FormContents>
          <FormBorder>
            <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Associated_Financing_Information')}>
              <div className="flex-end align-items-center me-3">
                <span className="me-1">{t('text:Go_to_Financing_Details')}</span>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  className="flex-column-center"
                  onClick={() => {
                    history.push(ROUTES_SY.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(systemLoanDetail?.loanId!), {
                      successInvoiceId: systemLoanDetail?.invoiceId,
                      successArId: systemLoanDetail?.successArId,
                      collateralType: systemLoanDetail?.collateralType,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </FormSubtitle>
          </FormBorder>
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${systemLoanDetail?.collateralType}`)}
                col={3}
                format="code"
              />
              <FormValue label={t('text:Financier_Name')} value={systemEarlyRepaymentDetail?.financierName} col={3} />
              <FormValue
                label={t('text:Financier_Financing_ID')}
                value={systemEarlyRepaymentDetail.financierLoanId}
                col={3}
              />
              <FormValue label={t('text:Platform_Financing_ID')} value={systemEarlyRepaymentDetail.loanId} col={3} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Financing_Amount')}
                value={systemEarlyRepaymentDetail.principalAmount}
                col={3}
                format="number"
              />
              <FormValue
                label={t('text:Disbursed_Date')}
                value={systemEarlyRepaymentDetail.disbursedDate}
                col={3}
                format="date"
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default SystemEarlyRepaymentDetail;
