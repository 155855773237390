import { COLLATERAL_TYPE, CURRENCY_TYPE, FINANCIER_TYPE, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface StatisticsOfAnchorAgreementVO {
  statisticsOfAnchorAgreementId: number;
  totalUploadedInvoiceAmount: string;
  totalDeletedInvoiceAmount: string;
  totalSettledInvoiceAmount: string;
  totalUnsettledInvoiceAmount: string;
  totalDisbursedLoanAmount: string;
  totalUploadedInvoiceCount: number;
  totalDeletedInvoiceCount: number;
  totalSettledInvoiceCount: number;
  totalUnsettledInvoiceCount: number;
  totalDisbursedLoanCount: number;
  anchorAgreementId: number;
  currentDisbursedLoanAmount: string;
  currentDisbursedLoanCount: number;
  currentSettlementWaitingInvoiceAmount: string;
  currentSettlementWaitingInvoiceCount: number;
  currencyType: string;
  contractNo: string;
  anchorFinancierClientId: number;
  anchorFinancierClientCode: string;
  anchorFinancierClientName: string;
  financierName: string;
  financierCode: string;
  collateralType: string;
  paymentSupport: boolean;
  currentPartiallyRepaidPrincipalAmount: string;
}

export interface StatisticsOfAnchorAgreementVOModel extends StatisticsOfAnchorAgreementVO {
  totalUploadedInvoiceAmount: BigNumber;
  totalDeletedInvoiceAmount: BigNumber;
  totalSettledInvoiceAmount: BigNumber;
  totalUnsettledInvoiceAmount: BigNumber;
  totalDisbursedLoanAmount: BigNumber;
  currentDisbursedLoanAmount: BigNumber;
  currentSettlementWaitingInvoiceAmount: BigNumber;
  currencyType: CURRENCY_TYPE;
  financierCode: FINANCIER_TYPE;
  collateralType: COLLATERAL_TYPE;
  currentPartiallyRepaidPrincipalAmount: BigNumber;
  // FRONT 에서 사용하는 통계 값들
  registeredInvoiceAmount: BigNumber; // totalUploadedInvoiceAmount - totalDeletedInvoiceAmount
  registeredInvoiceCount: number; // totalUploadedInvoiceCount - totalDeletedInvoiceCount,
  currentLoanBalanceAmount: BigNumber; // currentDisbursedLoanAmount - currentPartiallyRepaidPrincipalAmount
}

export function formattingToStatisticsOfAnchorAgreementModel(
  data: StatisticsOfAnchorAgreementVO,
): StatisticsOfAnchorAgreementVOModel {
  const slicedZeroDecimalData = {
    totalUploadedInvoiceAmount: sliceZeroDecimal(data.totalUploadedInvoiceAmount),
    totalDeletedInvoiceAmount: sliceZeroDecimal(data.totalDeletedInvoiceAmount),
    totalSettledInvoiceAmount: sliceZeroDecimal(data.totalSettledInvoiceAmount),
    totalUnsettledInvoiceAmount: sliceZeroDecimal(data.totalUnsettledInvoiceAmount),
    totalDisbursedLoanAmount: sliceZeroDecimal(data.totalDisbursedLoanAmount),
    currentDisbursedLoanAmount: sliceZeroDecimal(data.currentDisbursedLoanAmount),
    currentSettlementWaitingInvoiceAmount: sliceZeroDecimal(data.currentSettlementWaitingInvoiceAmount),
    currentPartiallyRepaidPrincipalAmount: sliceZeroDecimal(data.currentPartiallyRepaidPrincipalAmount),
  };

  const calculatorBigNumber = new CalculatorBigNumber();

  return {
    ...data,
    totalUploadedInvoiceAmount: slicedZeroDecimalData.totalUploadedInvoiceAmount,
    totalDeletedInvoiceAmount: slicedZeroDecimalData.totalDeletedInvoiceAmount,
    totalSettledInvoiceAmount: slicedZeroDecimalData.totalSettledInvoiceAmount,
    totalUnsettledInvoiceAmount: slicedZeroDecimalData.totalUnsettledInvoiceAmount,
    totalDisbursedLoanAmount: slicedZeroDecimalData.totalDisbursedLoanAmount,
    currentDisbursedLoanAmount: slicedZeroDecimalData.currentDisbursedLoanAmount,
    currentSettlementWaitingInvoiceAmount: slicedZeroDecimalData.currentSettlementWaitingInvoiceAmount,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    financierCode: stringToEnum(FINANCIER_TYPE, data.financierCode),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currentPartiallyRepaidPrincipalAmount: slicedZeroDecimalData.currentPartiallyRepaidPrincipalAmount,
    // FRONT 에서 사용하는 통계 값들
    registeredInvoiceAmount: calculatorBigNumber
      .add(slicedZeroDecimalData.totalUploadedInvoiceAmount)
      .minus(slicedZeroDecimalData.totalDeletedInvoiceAmount)
      .get(), // totalUploadedInvoiceAmount - totalDeletedInvoiceAmount
    registeredInvoiceCount: data.totalUploadedInvoiceCount - data.totalDeletedInvoiceCount,
    currentLoanBalanceAmount: calculatorBigNumber
      .add(slicedZeroDecimalData.currentDisbursedLoanAmount)
      .minus(slicedZeroDecimalData.currentPartiallyRepaidPrincipalAmount)
      .get(), // currentDisbursedLoanAmount - currentPartiallyRepaidPrincipalAmount
  };
}
