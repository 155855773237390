import CheckIcon from './CheckIcon';
import { NoDataIcon } from './NoDataIcon';
import PaperPlaneIcon from './PaperPlaneIcon';

const Icon = {
  NoData: NoDataIcon,
  PaperPlane: PaperPlaneIcon,
  Check: CheckIcon,
};

export default Icon;
