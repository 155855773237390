import type { ATTACHMENT_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { InvoiceAttachmentVO, InvoiceAttachmentVOModel } from 'models/vo/InvoiceAttachmentVO';
import { formattingToInvoiceAttachmentVOModel } from 'models/vo/InvoiceAttachmentVO';
import http, { ContentType } from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { dealerInvoiceFileUploadRequest } from './request';

// 송장 업로드 파일 목록 조회
export async function requestDealerInvoiceAttachmentList(
  pageNumber: number,
  rowCount: number,
  description?: string,
  attachmentType?: ATTACHMENT_TYPE,
): Promise<Pageable<InvoiceAttachmentVOModel[]>> {
  const response = await http.get<Pageable<InvoiceAttachmentVO[]>>({
    url: API_DE.INVOICE_ATTACHMENTS.INVOICE_ATTACHMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      description,
      attachmentType,
    },
  });
  const dealerInvoiceAttachmentList: InvoiceAttachmentVOModel[] = response.content.map((data: InvoiceAttachmentVO) =>
    formattingToInvoiceAttachmentVOModel(data),
  );
  const dealerInvoiceAttachmentPage: Pageable<InvoiceAttachmentVOModel[]> = {
    ...response,
    content: dealerInvoiceAttachmentList,
  };

  return dealerInvoiceAttachmentPage;
}

// 스캔 송장 업로드
export async function requestInvoiceScannedUpload(
  data: dealerInvoiceFileUploadRequest,
): Promise<InvoiceAttachmentVOModel> {
  const response = await http.post<InvoiceAttachmentVO>({
    url: API_DE.INVOICE_ATTACHMENTS.UPLOAD_SCANNED_INVOICE,
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToInvoiceAttachmentVOModel(response);
}

export async function requestDownloadInvoiceAttachment(invoiceAttachmentId: number) {
  await http.download(API_DE.INVOICE_ATTACHMENTS.DOWNLOAD_INVOICE_ATTACHMENT(invoiceAttachmentId));
}
