import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import type { ConfirmCheckBoxRefHandle } from 'components/stateless/CheckBox/ConfirmCheckBox';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import NavigationGuard from 'components/stateless/NavigationGuard';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { DOCUMENT_FORM_TYPE } from 'enums';
import { LOAN_TERM_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import useScrollIntoView from 'hooks/useScrollIntoView';
import { getSum } from 'utils/calculate';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';

import AdditionalDocuments from './sections/additional-documents';
import ArInformation from './sections/ar-information';
import DesignatedBankAccountInfo from './sections/designated-bank-account-info';
import FinancingRequest from './sections/financing-request';
import PurposeOfFinancing from './sections/purpose-of-financing';
import ARFinancingApplicationSummary from './sections/summary';
import { useDealerArFinancingApplicationStep2State } from './useDealerArFinancingApplicationStep2State';
import RequiredDocumentForm from '../required-documents';

function DealerArFinancingApplicationStep2() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();

  const modal = useModal();
  const [, errorHandlerOfLocationState] = useLocationState<{ successArId: number }>(['successArId']);

  const [formStep, setFormStep] = useState<number>(2);
  const {
    state,
    fetchAll,
    fetchDealerArCalculateLoanRequest,
    fetchLoanAdditionalDocumentList,
    dealerApplicationStep2UseForm,
    etcFileRowsState,
    purposeFileRowsState,
    loanAgreementAttachmentChangedState,
    loanRequestAttachmentChangedState,
    actions,
  } = useDealerArFinancingApplicationStep2State();

  const {
    dealerAgreementDetail,
    dealerArCalculateLoanRequest,
    dealerArDetail,
    documentTypes,
    dealerTempLoanByAr,
    factoringEnable,
    expectedInterestRate,
    additionalDocumentList,
  } = state;

  const {
    onFormSubmit,
    onClickNavigationGuardConfirmBtn,
    showCancelFinancingModal,
    handleClickSave,
    activeNavigationGuard,
    initializeExpectedInterestRate,
  } = actions;

  const [disbursementDateRef, scrollToDisbursementDate] = useScrollIntoView<HTMLDivElement>();
  const [purposeOfFinancingRef, scrollToPurposeOfFinancing] = useScrollIntoView<HTMLDivElement>();
  const [estimateButtonRef, scrollToEstimateButton] = useScrollIntoView<HTMLDivElement>();

  const [isRegistrationResultVisible, setIsRegistrationResultVisible] = useState(false);

  const [arInfoCheckBoxToggle, setArInfoCheckBoxToggle] = useState(false);
  const [financingCheckBoxToggle, setFinancingCheckBoxToggle] = useState(false);
  const [bankAccountCheckBoxToggle, setBankAccountCheckBoxToggle] = useState(false);
  const [checkedRequiredDocumentsIds, setCheckedRequiredDocumentsIds] = useState<number[]>([]);

  const arInfoCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);
  const financingCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);
  const bankAccountCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);

  const { getValues, watch, setError, clearErrors, errors } = dealerApplicationStep2UseForm;
  const { disbursementDate, requestedLoanAmount } = watch();

  useEffect(() => {
    setIsRegistrationResultVisible(!!errors.purposeOfLoanList);
  }, [errors.purposeOfLoanList]);

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll(disbursementDate);
    }
  }, [mounted]);

  // 서식/양식 유무에 따라 다운로드 버튼 show 또는 disabled 처리
  const showDocumentTypeBtn = (documentType: DOCUMENT_FORM_TYPE): boolean => {
    return documentTypes?.includes(documentType);
  };

  const confirmCheckBoxValidator = (): boolean => {
    let validationResult = true;

    const alertModal = (ref: RefObject<ConfirmCheckBoxRefHandle>) => {
      modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          if (ref.current) {
            ref.current.focus();
          }
        },
      });
    };

    if (!arInfoCheckBoxToggle) {
      alertModal(arInfoCheckBoxRef);
      validationResult = false;
    } else if (!financingCheckBoxToggle) {
      alertModal(financingCheckBoxRef);
      validationResult = false;
    } else if (!bankAccountCheckBoxToggle) {
      alertModal(bankAccountCheckBoxRef);
      validationResult = false;
    }

    return validationResult;
  };

  const financingRequestValidator = async (): Promise<boolean> => {
    if (!disbursementDate && dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE) {
      modal.show(<h6>{t('text:Please_select_the_Requested_Disbursement_Date_for_financing')}</h6>, {
        closeBtnCb: () => scrollToDisbursementDate(),
      });

      return false;
    }
    if (!requestedLoanAmount || Number(requestedLoanAmount) <= 0) {
      modal.show(<h6>{t('text:Please_enter_the_Requested_Financing_Amount')}</h6>, {
        closeBtnCb: () => {
          setError('requestedLoanAmount', { shouldFocus: true });
        },
      });

      return false;
    }
    if (Number(requestedLoanAmount) > Number(dealerArCalculateLoanRequest?.maxLoanableAmount!)) {
      modal.show(<h6>{t('text:The_requested_financing_amount_cannot_exceed_the_maximum_applicable_amount')}</h6>, {
        closeBtnCb: () => {
          setError('requestedLoanAmount', { shouldFocus: true });
        },
      });

      return false;
    }
    if (Number(requestedLoanAmount) < Number(dealerArCalculateLoanRequest?.minLoanableAmount!)) {
      modal.show(
        <h6>
          {t('text:The_requested_financing_amount_must_be_equal_to_or_greater_than_the_minimum_financing_limit')}
        </h6>,
        {
          closeBtnCb: () => {
            setError('requestedLoanAmount', { shouldFocus: true });
          },
        },
      );

      return false;
    }

    return true;
  };

  const loanEtcFileAttachmentValidator = (): boolean => {
    const data = getValues();
    const { etcFileRows } = etcFileRowsState;
    let validationResult = true;

    const emptyFile = (id: number, fileName?: string) => data.loanEtcAttachments?.[id]?.length === 0 && !fileName;
    const hasDescription = (id: number) => Boolean(data?.loanEtcAttachmentDescriptions?.[id]);

    for (let i = 0; i < etcFileRows?.length; i++) {
      const item = etcFileRows?.[i];
      const onlyDescriptionAndNoFile = emptyFile(item?.id, item?.fileName) && hasDescription(item?.id);

      if (onlyDescriptionAndNoFile) {
        modal.show(<h6>{t('text:Attach_a_file_to_the_Additional_Documents_section')}</h6>, {
          closeBtnCb: () => {
            setError(`loanEtcAttachments${item?.id}`, { shouldFocus: true });
          },
        });
        validationResult = false;
        break;
      }
    }

    return validationResult;
  };

  const validateStep2Form = async () => {
    clearErrors();
    if (!confirmCheckBoxValidator()) return false;
    if (!(await financingRequestValidator())) return false;
    if (!factoringEnable && !totalAmountValidator()) return false; // 팩토링일 경우 용처 섹션이 없으므로 용처금액과, 대출금액을 비교하는 유효성검사는 수행 X
    if (!loanEtcFileAttachmentValidator()) return false;

    if (!expectedInterestRate) {
      modal.show(<h6>{t('text:Please_check_the_estimate_button')}</h6>, {
        closeBtnCb: () => scrollToEstimateButton(),
      });

      return false;
    }

    return true;
  };

  const totalAmountValidator = () => {
    const { purposeOfLoanList, requestedLoanAmount } = getValues();
    const sum = getSum(purposeOfLoanList, 'purposeAmount');
    const isValid = Number(sum) >= (requestedLoanAmount ?? 0);

    if (!isValid) {
      modal.show(
        <h6>
          {t('text:Total_financing_purpose_amount_must_be_greater_than_or_equal_to_the_requested_financing_amount')}
        </h6>,
        {
          closeBtnCb: () => scrollToPurposeOfFinancing(),
        },
      );
    }

    return isValid;
  };

  const hardCopyConditionCheckboxValidator = () => {
    let validationResult = true;

    const requiredCheckboxesDocumentList = additionalDocumentList.filter(item => item.hardCopyCondition.required);
    if (
      requiredCheckboxesDocumentList.length > 0 &&
      checkedRequiredDocumentsIds.length !== requiredCheckboxesDocumentList.length
    ) {
      modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        modalType: ModalType.ALERT,
      });

      validationResult = false;
    }

    return validationResult;
  };

  const requiredFileUploadLoanAdditionalDocumentValidator = () => {
    let validationResult = true;

    const requiredFileUploadLoanAdditionalDocumentIds = additionalDocumentList
      .filter(item => item.mandatoryCondition.required)
      .map(item => item.loanAdditionalDocumentId);

    const { loanAdditionalDocumentAttachments } = getValues();

    if (loanAdditionalDocumentAttachments) {
      for (let i = 0; i < loanAdditionalDocumentAttachments.length; i++) {
        const item = loanAdditionalDocumentAttachments[i];
        if (
          item?.attachment?.length === 0 &&
          requiredFileUploadLoanAdditionalDocumentIds.includes(Number(item.loanAdditionalDocumentId))
        ) {
          modal.show(
            <>
              <h6>{t('text:This_financing_application_has_required_additional_documents')}</h6>
              <h6>{t('text:Please_attach_file')}</h6>
            </>,
            {
              modalType: ModalType.ALERT,
            },
          );
          validationResult = false;
          break;
        }
      }
    }

    return validationResult;
  };

  const validateStep3Form = async () => {
    if (!hardCopyConditionCheckboxValidator()) return false;
    else if (!requiredFileUploadLoanAdditionalDocumentValidator()) return false;
    else {
      await onFormSubmit();
    }
  };

  const onChangeArInfoCheckBoxToggle = (checked: boolean) => {
    setArInfoCheckBoxToggle(checked);
  };

  const onChangeFinancingCheckBoxToggle = (checked: boolean) => {
    setFinancingCheckBoxToggle(checked);
  };

  const onChangeBankAccountCheckBoxToggle = (checked: boolean) => {
    setBankAccountCheckBoxToggle(checked);
  };

  const handleClickNextPageButton = async () => {
    if (await validateStep2Form()) {
      setFormStep(prevState => prevState + 1);
      scrollToTopOfElement();
      await fetchLoanAdditionalDocumentList();
    }
  };

  const handleClickPrevPageButton = () => {
    setFormStep(prevState => prevState - 1);
    scrollToTopOfElement();
  };

  const handleCheckboxChange = (id: number) => {
    setCheckedRequiredDocumentsIds(prevCheckedIds =>
      prevCheckedIds.includes(id) ? prevCheckedIds.filter(itemId => itemId !== id) : [...prevCheckedIds, id],
    );
  };

  const handleClickBackTitle = () => {
    if (formStep === 3) {
      handleClickPrevPageButton();
    } else {
      history.back();
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Application')} onClick={handleClickBackTitle} />
      <FormProvider {...dealerApplicationStep2UseForm}>
        <form data-testid="financingApplicationId">
          <div className="content-area">
            <StepWizard
              nth={formStep}
              title={[t('text:Select_AR'), t('text:Financing_Request'), t('text:Additional_Documents')]}
            />
          </div>

          <div
            className={clsx({
              'd-none': formStep === 3,
            })}
          >
            <div className="content-area">
              <ArInformation
                dealerArDetail={dealerArDetail}
                dealerTempLoanByAr={dealerTempLoanByAr}
                onChangeArInfoCheckBoxToggle={factoringEnable ? onChangeArInfoCheckBoxToggle : undefined}
                factoringEnable={factoringEnable}
              />
              <ConfirmCheckBox
                id="flexCheckDefault1-2"
                disabled={factoringEnable && !watch('invoiceAttachmentId')}
                checked={arInfoCheckBoxToggle}
                onChangeCheckBox={onChangeArInfoCheckBoxToggle}
                labelText={t('text:I_have_checked_the_AR_information_is_correct')}
                ref={arInfoCheckBoxRef}
              />
            </div>
            <div className="content-area" data-testid="financingRequestId">
              <FinancingRequest
                dealerArApplicationStep2State={state}
                showDocumentTypeBtn={showDocumentTypeBtn}
                fetchDealerArCalculateLoanRequest={fetchDealerArCalculateLoanRequest}
                loanAgreementAttachmentChangedState={loanAgreementAttachmentChangedState}
                loanRequestAttachmentChangedState={loanRequestAttachmentChangedState}
                disbursementDateRef={disbursementDateRef}
                estimateButtonRef={estimateButtonRef}
                initializeExpectedInterestRate={initializeExpectedInterestRate}
              />
              <ConfirmCheckBox
                id="flexCheckDefault1-1"
                checked={financingCheckBoxToggle}
                onChangeCheckBox={onChangeFinancingCheckBoxToggle}
                labelText={t('text:I_have_checked_the_disbursement_date_repayment_date_and_financing_conditions')}
                ref={financingCheckBoxRef}
              />
            </div>
            {!factoringEnable && (
              <div className="content-area" data-testid="purposeFormId">
                <PurposeOfFinancing
                  {...purposeFileRowsState}
                  ref={purposeOfFinancingRef}
                  showDocumentTypeBtn={showDocumentTypeBtn}
                  dealerArDetail={dealerArDetail}
                  dealerTempLoanByAr={dealerTempLoanByAr}
                  isRegistrationResultVisible={isRegistrationResultVisible}
                />
              </div>
            )}
            <div className="content-area">
              <DesignatedBankAccountInfo
                dealerAgreementDetail={dealerAgreementDetail}
                factoringEnable={factoringEnable}
              />
              <ConfirmCheckBox
                id="flexCheckDefault1-3"
                checked={bankAccountCheckBoxToggle}
                onChangeCheckBox={onChangeBankAccountCheckBoxToggle}
                labelText={t('text:I_have_checked_the_information_of_the_designated_bank_accounts')}
                ref={bankAccountCheckBoxRef}
              />
            </div>
            <div className="content-area">
              <AdditionalDocuments {...etcFileRowsState} dealerTempLoanByAr={dealerTempLoanByAr} />
            </div>
          </div>
          <div
            className={clsx({
              'd-none': formStep === 2,
            })}
          >
            <div className="content-area">
              <ARFinancingApplicationSummary
                dealerArDetail={dealerArDetail}
                dealerAgreementDetail={dealerAgreementDetail}
                dealerArCalculateLoanRequest={dealerArCalculateLoanRequest}
                factoringEnable={factoringEnable}
                expectedInterestRate={expectedInterestRate}
              />
            </div>
            {additionalDocumentList.length > 0 ? (
              <div className="content-area">
                <SectionTitle title={t('text:Required_Additional_Documents')} />
                <GuideMessage
                  message={[
                    `${t('text:Please_check_the_documents_submit_to_financier')} ` +
                      `${t(
                        'text:If_you_submit_the_wrong_documents_or_miss_the_documents_your_loan_application_may_not_be_approved',
                      )}`,
                    t(
                      'text:If_you_required_to_submit_hard_copy_please_ask_to_financier_then_check_the_disbursement_date_when_you_can_delivery_hard_copy_before_the_disbursement',
                    ),
                    `${t(
                      'text:If_you_click_the_save_button_and_leave_the_page_the_required_additional_documents_are_not_saved',
                    )} ` + `${t('text:only_the_additional_documents_are_saved')}`,
                  ]}
                  isImportContentArea
                />
                {state.additionalDocumentList?.map((document, index) => (
                  <RequiredDocumentForm
                    key={index}
                    index={index}
                    documentInfo={document}
                    checked={checkedRequiredDocumentsIds.includes(document.loanAdditionalDocumentId)}
                    handleCheckboxChange={handleCheckboxChange}
                    dealerAgreementId={state.dealerArDetail.dealerAgreementId}
                  />
                ))}
              </div>
            ) : (
              <GuideMessage
                className="pb-0"
                message={[
                  `${t('text:There_are_no_additional_documents_required_for_this_financing_application')} ` +
                    `${t('text:Please_click_the_register_button_to_complete_financing_application')}`,
                ]}
              />
            )}
          </div>
          <div className="content-area d-flex">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={showCancelFinancingModal}
              className="me-auto"
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel')}
            </Button>
            {formStep === 2 && (
              <>
                <Button size={ButtonSizeEnum.LG} onClick={handleClickSave} variant={ButtonVariantEnum.OUTLINED}>
                  {t('text:Save')}
                </Button>
                <Button size={ButtonSizeEnum.LG} onClick={handleClickNextPageButton} className="ms-2">
                  {t('text:Next')}
                </Button>
              </>
            )}
            {formStep === 3 && (
              <>
                <Button
                  size={ButtonSizeEnum.LG}
                  variant={ButtonVariantEnum.OUTLINED}
                  onClick={handleClickPrevPageButton}
                  className="ms-2"
                >
                  {t('text:Prev')}
                </Button>
                <Button size={ButtonSizeEnum.LG} onClick={validateStep3Form} className="ms-2">
                  {t('text:Register')}
                </Button>
              </>
            )}
          </div>
        </form>
      </FormProvider>
      <NavigationGuard when={activeNavigationGuard} confirmBtnFunc={onClickNavigationGuardConfirmBtn}>
        <h6>{t('text:Would_you_like_to_leave_this_page_without_saving_the_financing_application_information?')}</h6>
        <h6>{t('text:Click_on_the_“Save”_button_to_save_the_information_you_entered')}</h6>
      </NavigationGuard>
    </>
  );
}

export default DealerArFinancingApplicationStep2;
