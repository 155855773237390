import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import type { ConfirmCheckBoxRefHandle } from 'components/stateless/CheckBox/ConfirmCheckBox';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import NavigationGuard from 'components/stateless/NavigationGuard';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { LOAN_TERM_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import useScrollIntoView from 'hooks/useScrollIntoView';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';

import AdditionalDocuments from './sections/additional-documents';
import DesignatedBankAccountInformation from './sections/designated-bank-account-information';
import FinancingApplication from './sections/financing-application';
import InvoiceInformation from './sections/invoice-information';
import { useDealerInvoiceFinancingApplicationStep2State } from './useDealerInvoiceFinancingApplicationStep2State';
import { getFinancingApplicationStepWizardText } from '../utils/loanApplicationUtils';

export interface EtcFileRowTypes {
  id: number;
  tempLoanEtcAttachmentId: number | undefined;
  fileName: string | undefined;
  changed: boolean;
}

function DealerInvoiceFinancingApplicationStep2() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();
  const { show: showModal } = useModal();

  const [, errorHandlerOfLocationState] = useLocationState<{
    dealerAgreementId: number;
    successInvoiceId: number;
    financierEnterpriseId: number;
  }>(['dealerAgreementId', 'successInvoiceId', 'financierEnterpriseId']);

  const {
    state,
    dealerInvoiceApplicationStep2UseForm,
    fetchAll,
    fetchDealerInvoiceCalculateLoanRequest,
    ignoreNavigationGuardRef,
    etcFileRowsState,
    loanAgreementAttachmentChangedState,
    loanRequestAttachmentChangedState,
    actions,
  } = useDealerInvoiceFinancingApplicationStep2State();

  const {
    showCancelFinancingModal,
    onClickNavigationGuardConfirmBtn,
    onClickSave,
    onFormSubmit,
    activeNavigationGuard,
    initializeExpectedInterestRate,
  } = actions;
  const { dealerAgreementDetail, dealerInvoiceDetail, dealerTempLoanByInvoice, expectedInterestRate } = state;

  const [invoiceInfoToggle, setInvoiceInfoToggle] = useState<boolean>(false);
  const [financingInfoToggle, setFinancingInfoToggle] = useState<boolean>(false);
  const [bankAccountInfoToggle, setBankAccountInfoToggle] = useState<boolean>(false);
  const invoiceInfoCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);
  const financingInfoCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);
  const bankAccountInfoCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);

  const [formStep, setFormStep] = useState<number>(2);
  const [repaymentDateRef, scrollToRepaymentDate] = useScrollIntoView<HTMLDivElement>();
  const [estimateButtonRef, scrollToEstimateButton] = useScrollIntoView<HTMLDivElement>();

  const { getValues, watch, setError, clearErrors } = dealerInvoiceApplicationStep2UseForm;
  const { repaymentDate, requestedLoanAmount } = watch();

  const alreadySecuredInvoiceValidator = useCallback(() => {
    ignoreNavigationGuardRef.current = true;
    showModal(
      <h6>
        {t('text:This_invoice_has_already_been_used_for_a_financing_application')}
        <br />
        {t('text:Please_check_and_proceed_again')}
      </h6>,
      {
        closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
      },
    );
  }, [history, showModal, t]);

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    if (dealerInvoiceDetail?.collateralized) {
      alreadySecuredInvoiceValidator();
    }
  }, [alreadySecuredInvoiceValidator, dealerInvoiceDetail?.collateralized]);

  const confirmCheckBoxValidator = (): boolean => {
    let validationResult = true;

    const alertModal = (ref: React.RefObject<ConfirmCheckBoxRefHandle>) => {
      showModal(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          if (ref.current) {
            ref.current.focus();
          }
        },
      });
    };

    if (!invoiceInfoToggle) {
      alertModal(invoiceInfoCheckBoxRef);
      validationResult = false;
    } else if (!financingInfoToggle) {
      alertModal(financingInfoCheckBoxRef);
      validationResult = false;
    } else if (!bankAccountInfoToggle) {
      alertModal(bankAccountInfoCheckBoxRef);
      validationResult = false;
    }

    return validationResult;
  };

  const loanEtcFileAttachmentValidator = (): boolean => {
    const data = getValues();
    const { etcFileRows } = etcFileRowsState;
    let validationResult = true;

    const emptyFile = (id: number, fileName: string | undefined) =>
      data.loanEtcAttachments?.[id]?.length === 0 && !fileName;
    const hasDescription = (id: number) => Boolean(data?.loanEtcAttachmentDescriptions?.[id]);

    for (let i = 0; i < etcFileRows?.length; i++) {
      const item = etcFileRows?.[i];
      const onlyDescriptionAndNoFile = emptyFile(item?.id, item?.fileName) && hasDescription(item?.id);

      if (onlyDescriptionAndNoFile) {
        showModal(<h6>{t('text:Attach_a_file_to_the_Additional_Documents_section')}</h6>, {
          closeBtnCb: () => {
            setError(`loanEtcAttachments${item?.id}`, { shouldFocus: true });
          },
        });
        validationResult = false;
        break;
      }
    }

    return validationResult;
  };

  const repaymentDateValidator = (): boolean => {
    if (!repaymentDate && dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE) {
      showModal(<h6>{t('text:Please_select_the_Requested_Disbursement_Date_for_financing')}</h6>, {
        closeBtnCb: () => scrollToRepaymentDate(),
      });

      return false;
    }

    return true;
  };

  const requestFinancingAmountValidator = (): boolean => {
    const { requestedLoanAmount } = watch();
    const { maxLoanableAmount } = state;

    if (!requestedLoanAmount || Number(requestedLoanAmount) <= 0) {
      showModal(<h6>{t('text:Please_enter_the_Requested_Financing_Amount')}</h6>, {
        closeBtnCb: () => {
          setError('requestedLoanAmount', { shouldFocus: true });
        },
      });

      return false;
    }

    if (Number(requestedLoanAmount) > Number(maxLoanableAmount)) {
      showModal(<h6>{t('text:The_requested_financing_amount_cannot_exceed_the_maximum_applicable_amount')}</h6>, {
        closeBtnCb: () => {
          setError('requestedLoanAmount', { shouldFocus: true });
        },
      });

      return false;
    }

    return true;
  };

  const onChangeInvoiceInfoCheckBoxToggle = (checked: boolean) => {
    setInvoiceInfoToggle(checked);
  };

  const onChangeBankAccountInfoCheckBoxToggle = (checked: boolean) => {
    setBankAccountInfoToggle(checked);
  };

  const onChangeFinancingInfoCheckBoxToggle = (checked: boolean) => {
    setFinancingInfoToggle(checked);
  };

  const validate = () => {
    clearErrors();
    if (!confirmCheckBoxValidator()) return false;
    if (!repaymentDateValidator()) return false;
    if (!requestFinancingAmountValidator()) return false;
    if (!loanEtcFileAttachmentValidator()) return false;
    if (!expectedInterestRate) {
      showModal(<h6>{t('text:Please_check_the_estimate_button')}</h6>, {
        closeBtnCb: () => scrollToEstimateButton(),
      });

      return false;
    }

    return true;
  };

  const handleClickNextPageButton = async () => {
    if (validate()) {
      setFormStep(prevState => prevState + 1);
      scrollToTopOfElement();
    }
  };

  const handleClickPrevPageButton = () => {
    setFormStep(prevState => prevState - 1);
    scrollToTopOfElement();
  };

  const handleClickBackTitle = () => {
    if (formStep === 3) {
      handleClickPrevPageButton();
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Application')} onClick={handleClickBackTitle} />
      <FormProvider {...dealerInvoiceApplicationStep2UseForm}>
        <div className="content-area">
          <StepWizard
            nth={formStep}
            title={[getFinancingApplicationStepWizardText(), t('text:Financing_Request'), t('text:Review_Application')]}
          />
        </div>
        <div
          className={clsx({
            'd-none': formStep === 3,
          })}
        >
          <div className="content-area">
            <InvoiceInformation
              dealerInvoiceDetail={dealerInvoiceDetail}
              dealerTempLoanByInvoice={dealerTempLoanByInvoice}
            />
            <ConfirmCheckBox
              id="flexCheckDefault1-2"
              checked={invoiceInfoToggle}
              onChangeCheckBox={onChangeInvoiceInfoCheckBoxToggle}
              labelText={t('text:I_have_checked_the_invoice_information_is_correct')}
              ref={invoiceInfoCheckBoxRef}
            />
          </div>

          <div className="content-area">
            <FinancingApplication
              dealerInvoiceApplicationStep2State={state}
              loanAgreementAttachmentChangedState={loanAgreementAttachmentChangedState}
              loanRequestAttachmentChangedState={loanRequestAttachmentChangedState}
              fetchDealerInvoiceCalculateLoanRequest={fetchDealerInvoiceCalculateLoanRequest}
              repaymentDateRef={repaymentDateRef}
              estimateButtonRef={estimateButtonRef}
              initializeExpectedInterestRate={initializeExpectedInterestRate}
            />
            <ConfirmCheckBox
              id="flexCheckDefault1-1"
              checked={financingInfoToggle}
              onChangeCheckBox={onChangeFinancingInfoCheckBoxToggle}
              labelText={t('text:I_have_checked_the_disbursement_date_repayment_date_and_financing_conditions')}
              ref={financingInfoCheckBoxRef}
            />
          </div>

          <div className="content-area">
            <DesignatedBankAccountInformation dealerAgreementDetail={dealerAgreementDetail} />
            <ConfirmCheckBox
              id="flexCheckDefault1-3"
              checked={bankAccountInfoToggle}
              onChangeCheckBox={onChangeBankAccountInfoCheckBoxToggle}
              labelText={t('text:I_have_checked_the_information_of_the_designated_bank_accounts')}
              ref={bankAccountInfoCheckBoxRef}
            />
          </div>

          <div className="content-area">
            <AdditionalDocuments
              dealerTempLoanByInvoice={dealerTempLoanByInvoice}
              etcFileRowsState={etcFileRowsState}
            />
          </div>
        </div>

        <div
          className={clsx({
            'd-none': formStep === 2,
          })}
        >
          <div className="content-area">
            <FormBorder>
              <FormContents>
                <div className="row">
                  <FormValue
                    label={t('text:Requested_Financing_Amount')}
                    value={`${t('format:number', { value: requestedLoanAmount })} ${dealerInvoiceDetail.currencyType}`}
                  />
                  <FormValue
                    label={t('text:Requested_Repayment_Date')}
                    value={
                      dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE
                        ? repaymentDate
                        : dealerInvoiceDetail.scheduledRepaymentDate
                    }
                    format="date"
                  />
                </div>
                <div className="row">
                  <FormValue label={t('text:Expected_Interest(APR)')} value={expectedInterestRate} />
                  <FormValue
                    label={t('text:Scheduled_Disbursement_Date')}
                    value={dealerInvoiceDetail.settlementDate}
                    format="date"
                  />
                </div>
              </FormContents>
            </FormBorder>
          </div>
          <GuideMessage
            message={[
              `${t('text:There_are_no_additional_documents_required_for_this_financing_application')} ` +
                `${t('text:Please_click_the_register_button_to_complete_financing_application')}`,
            ]}
            className="pb-0"
          />
        </div>
        <div className="content-area d-flex">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={showCancelFinancingModal}
            className="me-auto"
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          {formStep === 2 && (
            <>
              <Button size={ButtonSizeEnum.LG} onClick={onClickSave} variant={ButtonVariantEnum.OUTLINED}>
                {t('text:Save')}
              </Button>
              <Button size={ButtonSizeEnum.LG} onClick={handleClickNextPageButton} className="ms-2">
                {t('text:Next')}
              </Button>
            </>
          )}
          {formStep === 3 && (
            <>
              <Button size={ButtonSizeEnum.LG} onClick={handleClickPrevPageButton} className="ms-2">
                {t('text:Prev')}
              </Button>
              <Button size={ButtonSizeEnum.LG} onClick={onFormSubmit} className="ms-2">
                {t('text:Register')}
              </Button>
            </>
          )}
        </div>
      </FormProvider>
      <NavigationGuard
        when={activeNavigationGuard}
        confirmBtnFunc={onClickNavigationGuardConfirmBtn}
        ignore={ignoreNavigationGuardRef.current}
      >
        <h6>{t('text:Would_you_like_to_leave_this_page_without_saving_the_financing_application_information?')}</h6>
        <h6>{t('text:Click_on_the_“Save”_button_to_save_the_information_you_entered')}</h6>
      </NavigationGuard>
    </>
  );
}

export default DealerInvoiceFinancingApplicationStep2;
