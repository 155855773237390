import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button2ColorEnum } from 'components/stateless/Button2/Button2';
import IconButton2 from 'components/stateless/Button2/IconButton2';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import FileInput from 'components/stateless/FileInput/FileInput';
import useMounted from 'hooks/useMounted';
import type { AnPotentialPartnerFinancingApplicationSubmitRequest } from 'utils/http/api/anonymous/potential-partner-financing-applications/request';

import { usePotentialPartnerApplicationValue } from '../../../../PotentialPartnerApplicationProvider';

function UploadedPartnerApplicationStep2AdditionalDocumentForm() {
  const mounted = useMounted();
  const arrId = useRef<number>(0);
  const { t } = useTranslation();
  const { formData } = usePotentialPartnerApplicationValue();
  const { register, getValues } = useFormContext<AnPotentialPartnerFinancingApplicationSubmitRequest>();

  const [additionalDocumentRow, setAdditionalDocumentRow] = useState<{ id: number }[]>([
    {
      id: 0,
    },
  ]);
  const additionalDescriptionMaxLength = 1000;

  useEffect(() => {
    if (!mounted) {
      const savedAdditionalAttachment = formData?.additionalAttachment;
      if (savedAdditionalAttachment?.length === 0) return;

      const updatedState = additionalDocumentRow;
      for (let i = 1; i < savedAdditionalAttachment?.length; i++) {
        arrId.current++;

        updatedState[i] = { id: arrId.current };
      }
      setAdditionalDocumentRow(updatedState);
    }

    return () => {};
  }, [mounted]);

  const appendRow = () => {
    arrId.current++;
    setAdditionalDocumentRow([...additionalDocumentRow, { id: arrId.current }]);
  };

  const removeRow = (id: number) => {
    const newState = additionalDocumentRow.filter(item => {
      return item.id !== id;
    });
    setAdditionalDocumentRow(newState);
  };

  const getAdditionalDocumentIndex = () => {
    const requiredAttachmentIndex = getValues().requiredAttachment?.length + 1;

    return requiredAttachmentIndex > 0 ? requiredAttachmentIndex : 1;
  };

  return (
    <div className="pp-optional-document-form">
      <div className="pp-document-form__name">
        {`${getAdditionalDocumentIndex()}. ` + t('text:Additional_Documents')}
      </div>
      <div className="pp-document-form__border">
        <FormContents backGroundType={BackGroundType.WHITE}>
          <>
            {additionalDocumentRow.map(item => {
              return (
                <div className="pp-document-form__row d-flex" key={item.id}>
                  <div className="w-50 align-self-center">
                    <FileInput id={`additional-${item.id}`} name={`additionalAttachment[${item.id}]`} ref={register} />
                  </div>
                  <div className="w-50">
                    <div className="d-flex justify-content-between">
                      <input
                        type="text"
                        placeholder={t('text:Enter_the_document_description_here')}
                        name={`additionalDescription[${item.id}]`}
                        className="pp-file-description-input"
                        ref={register}
                        maxLength={additionalDescriptionMaxLength}
                      />
                      <div className="pp-document-form__icon-btn">
                        {additionalDocumentRow.length === 1 ? (
                          <IconButton2
                            icon={<FontAwesomeIcon icon={faPlus} fontSize={16.71} />}
                            onClick={e => {
                              e.preventDefault();
                              appendRow();
                            }}
                          />
                        ) : (
                          <IconButton2
                            color={Button2ColorEnum.TERTIARY}
                            icon={<FontAwesomeIcon icon={faMinus} fontSize={16.71} />}
                            onClick={e => {
                              e.preventDefault();
                              removeRow(item.id);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {additionalDocumentRow.length !== 1 && (
              <div className="pp-document-form__row flex-end">
                <IconButton2
                  icon={<FontAwesomeIcon icon={faPlus} fontSize={16.71} />}
                  onClick={e => {
                    e.preventDefault();
                    appendRow();
                  }}
                />
              </div>
            )}
          </>
        </FormContents>
      </div>
    </div>
  );
}

export default UploadedPartnerApplicationStep2AdditionalDocumentForm;
