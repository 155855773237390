import type React from 'react';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { removeRangeFromHoliday } from 'utils/date/date';

import type { AnchorArBulkFinancingApplicationStep2State } from '../../useAnchorArBulkFinancingApplicationStep2State';

dayjs.extend(isBetween);
interface useFinancingRequestProps {
  anchorArBulkApplicationStep2State: AnchorArBulkFinancingApplicationStep2State;
  repaymentDate: string;
  totalRequestedAmount: string;
}

export function useFinancingRequest({
  anchorArBulkApplicationStep2State,
  repaymentDate,
  totalRequestedAmount,
}: useFinancingRequestProps) {
  const { t } = useTranslation(['format']);
  const { setValue } = useFormContext();
  const { anchorFinancingOptionDetail, financierHoliday, maxDate } = anchorArBulkApplicationStep2State;

  const [applicationFileName, setApplicationFileName] = useState<string | undefined>(undefined);
  const [agreementFileName, setAgreementFileName] = useState<string | undefined>(undefined);
  const [expectedInterestRate, setExpectedInterestRate] = useState<string>();

  const disabledRanges = useMemo((): Date[] | [] => {
    if (maxDate) {
      return [...removeRangeFromHoliday(financierHoliday?.content), maxDate && new Date(maxDate)] as Date[];
    } else {
      return removeRangeFromHoliday(financierHoliday?.content);
    }
  }, [maxDate, financierHoliday?.content]);

  const disabledEstimateButton = (): boolean => {
    if (!repaymentDate) {
      return true;
    }

    if (!totalRequestedAmount || totalRequestedAmount === '0') {
      return true;
    }

    return false;
  };

  const returnAmountWithCurrency = (amount: number | string | undefined) => {
    return `${t('format:number', {
      value: amount,
    })} ${anchorFinancingOptionDetail?.currencyType}`;
  };

  const onChangeApplicationFileName = (e: any) => {
    console.log(e.target.files[0]);
    if (e.target?.files[0]?.name) setApplicationFileName(e.target.files[0].name);
  };

  const resetApplicationFileName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setValue('requestAttachmentFile', null);
    setApplicationFileName(undefined);
  };

  const onChangeAgreementFileName = (e: any) => {
    if (e.target?.files[0]?.name) setAgreementFileName(e.target.files[0].name);
  };

  const resetAgreementFileName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setValue('agreementAttachment', null);
    setAgreementFileName(undefined);
  };

  return {
    applicationFileName,
    agreementFileName,
    disabledRanges,
    disabledEstimateButton,
    returnAmountWithCurrency,
    onChangeApplicationFileName,
    resetApplicationFileName,
    onChangeAgreementFileName,
    resetAgreementFileName,
  };
}
