import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import StepWizard from 'components/stateless/StepWizard/StepWizard';
import useMounted from 'hooks/useMounted';

import { usePotentialPartnerApplicationState } from './logics/usePotentialPartnerApplicationState';
import PotentialPartnerApplicationStep1 from './step1/PotentialPartnerApplicationStep1';
import PotentialPartnerApplicationStep2 from './step2/PotentialPartnerApplicationStep2';
import PotentialPartnerApplicationStep3 from './step3/PotentialPartnerApplicationStep3';
import { usePotentialPartnerApplicationValue } from '../PotentialPartnerApplicationProvider';

function PotentialPartnerApplicationStepWrapper() {
  const mounted = useMounted();
  const { formStep } = usePotentialPartnerApplicationValue();
  const { t } = useTranslation();

  const { state, fetchAll, errorHandlerOfLocationState } = usePotentialPartnerApplicationState();

  const { financierGuideMessageInfo, anchorPartnerInfo, branchesInfo, requiredPotentialPartnerAcquisitionDocument } =
    state;

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;

      fetchAll();
    }

    return () => {};
  }, [mounted]);

  return (
    <>
      <div className="pp-step-wizard-wrapper">
        <StepWizard
          nth={formStep}
          title={[t('text:Check_Information'), t('text:Upload_Documents'), t('text:Confirm_Application')]}
        />
      </div>

      {formStep === 1 && (
        <PotentialPartnerApplicationStep1
          financierGuideMessageInfo={financierGuideMessageInfo}
          anchorPartnerInfo={anchorPartnerInfo}
        />
      )}
      {formStep === 2 && (
        <PotentialPartnerApplicationStep2
          branchesInfo={branchesInfo}
          documentInfo={requiredPotentialPartnerAcquisitionDocument}
        />
      )}
      {formStep === 3 && (
        <PotentialPartnerApplicationStep3 documentInfo={requiredPotentialPartnerAcquisitionDocument} />
      )}
    </>
  );
}

export default PotentialPartnerApplicationStepWrapper;
