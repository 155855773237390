import type { ReactNode } from 'react';

import './Title.scss';

interface HeaderTitlePropsType {
  title?: string;
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
}

export const HeaderTitle = ({ title = '', left, right, className = '' }: HeaderTitlePropsType) => {
  return (
    <div className={`header-title ${className}`}>
      {left}
      <h1 className="me-auto">{title}</h1>
      {right}
    </div>
  );
};
