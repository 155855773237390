export class HttpError extends Error {
  data: any;
  code: string;
  constructor(code: string, data?: any, message?: string) {
    super(message);
    this.name = 'HttpError';
    this.code = code;
    this.data = data;
  }
}
