import { useTranslation } from 'react-i18next';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { LOAN_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { MultipleLoanRequestRelatedLoanVOModel } from 'models/vo/MultipleLoanRequestRelatedLoanVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface DealerBulkFinancingDetailFinancingListProps {
  financingList: MultipleLoanRequestRelatedLoanVOModel[];
  paginate(pageNumber: number, rowCount: number): Promise<void>;
  pageable: PageableType;
}

function DealerBulkFinancingDetailFinancingList({
  financingList,
  paginate,
  pageable,
}: DealerBulkFinancingDetailFinancingListProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const handleMultipleLoanStatusQuestionMarkClick = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };
  const FINANCING_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
    },
    {
      headerText: t('text:AR_Number'),
    },
    {
      headerText: t('text:Advance_Disbursement'),
    },
    {
      headerText: t('text:Requested_Financing_Amount'),
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
    },
    {
      headerText: t('text:Financing_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: handleMultipleLoanStatusQuestionMarkClick,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_List')} />
      <TableBorder>
        <TableHeader header={FINANCING_LIST_TABLE_HEADERS} />
        <TableBody numOfCol={FINANCING_LIST_TABLE_HEADERS.length}>
          {financingList?.map((item: MultipleLoanRequestRelatedLoanVOModel, index: number) => (
            <Tr key={index}>
              <Td data={item.loanId} />
              <Td data={item.arNumber} />
              <Td data={item.netDisbursementAmount} format="number" />
              <Td data={item.requestedAmount} format="number" />
              <Td data={item.settlementDate} format="date" />
              <Td
                data={t(`code:financing-status.${item.status}`)}
                className={getStatusTextClass('LOAN_STATUS', item.status)}
                format="code"
              />
              <TdLink path={ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(item.loanId)} />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default DealerBulkFinancingDetailFinancingList;
