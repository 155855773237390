import type { CURRENCY_TYPE } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
export interface DeletedInvoiceVO {
  deletedInvoiceId: number;
  createdDateTime: string;
  invoiceNumber: string;
  referenceNumber: string;
  billOfExchangeNo: string;
  invoiceAmount: BigNumber;
  currencyType: CURRENCY_TYPE;
  settlementDate: string;
  invoiceIssuedDate: string;
  financierEnterpriseId: number;
  financierEnterpriseName: string;
  dealerEnterpriseId: number;
  dealerEnterpriseName: string;
  dealerEnterpriseTaxCode: string;
  dealerEnterpriseBusinessCode: string;
  anchorEnterpriseId: number;
  anchorEnterpriseName: string;
  anchorEnterpriseTaxCode: string;
  anchorEnterpriseBusinessCode: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerClientCode: string;
  dealerClientTaxCode: string;
  dealerClientBusinessCode: string;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  anchorClientTaxCode: string;
  anchorClientBusinessCode: string;
}

export interface DeletedInvoiceVOModel extends DeletedInvoiceVO {}

export function formattingToDeletedInvoiceVOModel(data: DeletedInvoiceVO): DeletedInvoiceVOModel {
  return {
    ...data,
  };
}
