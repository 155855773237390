import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVO, AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { formattingToAnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FiAnchorAgreementInterfaceByKeyUnionRequest,
  FinancierAnchorAgreementListForPartnersRequest,
  SearchFiAllAnchorAgreementsDTO,
  SearchFiAnchorAgreementListDTO,
} from './requests';

export async function requestFinancierAnchorAgreementList(
  pageNumber: number,
  rowCount: number,
  SearchFiAnchorAgreementListDTO: SearchFiAnchorAgreementListDTO,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_FI.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...SearchFiAnchorAgreementListDTO,
    },
  });
  const anchorAgreementList = response.content.map(data => formattingToAnchorAgreementVOModel(data));

  return {
    ...response,
    content: anchorAgreementList,
  };
}

export async function requestFinancierAllAnchorAgreementList(
  pageNumber: number,
  rowCount: number,
  SearchFiAllAnchorAgreementsDTO: SearchFiAllAnchorAgreementsDTO,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_FI.ANCHOR_AGREEMENTS.ALL_ANCHOR_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...SearchFiAllAnchorAgreementsDTO,
    },
  });
  const allAnchorAgreementList = response.content.map(data => formattingToAnchorAgreementVOModel(data));

  return {
    ...response,
    content: allAnchorAgreementList,
  };
}

export async function requestFinancierAnchorAgreementListForPartners(
  searchData: FinancierAnchorAgreementListForPartnersRequest,
): Promise<AnchorAgreementVOModel[]> {
  const response = await http.get<AnchorAgreementVO[]>({
    url: API_FI.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_LIST_FOR_PARTNERS,
    data: {
      ...searchData,
    },
  });

  return response.map(data => formattingToAnchorAgreementVOModel(data));
}

export async function requestFinancierAnchorAgreementCodeRegisteredInterface(
  contractNo: string,
  collateralType: string,
): Promise<AnchorAgreementDetailVOModel> {
  const response = await http.get<AnchorAgreementDetailVO>({
    url: API_FI.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_BY_FINANCIER_INTERFACE(contractNo),
    data: { collateralType },
  });

  const financierAnchorRegisteredInterface = formattingToAnchorAgreementDetailVOModel(response);

  return financierAnchorRegisteredInterface;
}

export async function requestFinancierAnchorAgreementIdRegisteredInterface(
  anchorAgreementId: number,
): Promise<AnchorAgreementDetailVOModel> {
  const response = await http.get<AnchorAgreementDetailVO>({
    url: API_FI.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_INTERFACE(anchorAgreementId),
  });

  const financierAnchorRegisteredInterface = formattingToAnchorAgreementDetailVOModel(response);

  return financierAnchorRegisteredInterface;
}

export async function requestFiAnchorAgreementByKeyUnionInterface(
  params: FiAnchorAgreementInterfaceByKeyUnionRequest,
): Promise<AnchorAgreementDetailVOModel> {
  const response = await http.get<AnchorAgreementDetailVO>({
    url: API_FI.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_BY_KEY_UNION_INTERFACE,
    data: params,
  });

  return formattingToAnchorAgreementDetailVOModel(response);
}

export async function requestFinancierAnchorAgreementData(
  anchorAgreementId: number,
): Promise<AnchorAgreementDetailVOModel> {
  const response = await http.get<AnchorAgreementDetailVO>({
    url: API_FI.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_DETAIL(anchorAgreementId),
  });

  const anchorAgreementData = formattingToAnchorAgreementDetailVOModel(response);

  return anchorAgreementData;
}
