import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { COLLATERAL_TYPE, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { LoanVOModel } from 'models/vo/LoanVO';
import RequestEarlyRepaymentModal from 'pages/dealer/manage-financing/request-for-early-repayment/modal/RequestEarlyRepaymentModal';
import { requestDealerLoanEarlyRepayment, requestDealerLoanList } from 'utils/http/api/dealer/loans';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

function DealerEarlyRepaymentRegister() {
  const mounted = useMounted();
  const history = useHistory();
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const [financingNumber, setFinancingNumber] = useState<string>();
  const [loanData, setLoanData] = useState<Pageable<LoanVOModel[]>>();
  const { pageable, setPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (selectedPageNumber: number = 1, selectedRowNumber: number = 10): Promise<void> => {
    try {
      const loanResponse = await requestDealerLoanList(selectedPageNumber, selectedRowNumber, {
        financierLoanId: financingNumber,
        loanStatusConditions: [LOAN_STATUS.DISBURSED, LOAN_STATUS.RECOURSE_ACTIVATED],
        earlyRepaymentInProgress: false,
        earlyRepaymentAllowable: true,
        repaymentDateFrom: dayjs().add(1, 'days').format('YYYY-MM-DD'),
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setLoanData(loanResponse);
        setPageable(loanResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onChangeFinancingNumberInput = (e: any): void => {
    setFinancingNumber(e.target.value);
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();

    fetchAll();
  };

  const paginate = (selectedPageNumber: number, selectedRowNumber: number) => {
    fetchAll(selectedPageNumber, selectedRowNumber);
  };

  const renderResultTable = (): JSX.Element[] | JSX.Element | undefined => {
    const renderAgreementType = (collateralType: COLLATERAL_TYPE | undefined) => {
      if (collateralType === COLLATERAL_TYPE.AR) {
        return t('text:Vendor_Finance');
      } else if (collateralType === COLLATERAL_TYPE.INVOICE) {
        return t('text:Dealer_Finance');
      } else {
        return null;
      }
    };

    const onClickRequest = async (e: any, loanData: LoanVOModel) => {
      e.preventDefault();

      const onClickConfirm = () => {
        const confirmOKModalOptions: ModalOptions = {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => {
            history.push(ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EARLY_REPAYMENT_LIST);
          },
        };

        modal.show(
          <h6>
            {t('text:The_request_for_early_repayment_has_been_completed')}
            <br />
            {t('text:Please_proceed_with_the_repayment_on_the_scheduled_date_of_the_request')}
          </h6>,
          confirmOKModalOptions,
        );
      };

      const dealerCalculateExpectedAmountRequest = {
        expectedRepaymentDate: '',
      };

      const showRequestEarlyRepaymentModal = () => {
        modal.show(
          <RequestEarlyRepaymentModal
            loanId={loanData.loanId}
            repaymentDate={loanData.repaymentDate}
            disbursedDate={loanData.disbursedDate}
            financierId={loanData.financierId}
            collateralType={loanData.collateralType}
            dealerCalculateExpectedAmountRequest={dealerCalculateExpectedAmountRequest}
          />,
          {
            title: t('text:Request_for_Early_Repayment'),
            modalType: ModalType.CONFIRM,
            closeBtnText: t('text:Cancel'),
            confirmBtnText: t('text:Request'),
            confirmBtnCb: async (props: any) => {
              try {
                await requestDealerLoanEarlyRepayment(loanData.loanId, {
                  intendedRepaymentDate: props.dealerCalculateExpectedAmountRequest.expectedRepaymentDate,
                });
                onClickConfirm();
              } catch (e) {
                modal.close(modal.id);
                modal.show(e, {
                  closeBtnCb: () => {
                    showRequestEarlyRepaymentModal();
                  },
                });
              }
            },
          },
        );
      };
      showRequestEarlyRepaymentModal();
    };

    return loanData?.content.map((item, index: number) => (
      <Tr key={index}>
        <Td data={item.financierName} />
        <Td data={renderAgreementType(item.collateralType)} />
        <Td data={item.financierLoanId} />
        <Td data={item.loanId} />
        <Td data={item.invoiceNumber} />
        <Td data={item.arNumber} />
        <Td data={item.principalAmount} format="number" />
        <Td data={item.disbursedDate} format="date" />
        <Td data={item.repaymentDate} format="date" />
        <Td className="information-table-more">
          <Button onClick={e => onClickRequest(e, item)}>{t('text:Select')}</Button>
        </Td>
      </Tr>
    ));
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Program_Type'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 120,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 120,
    },
    {
      headerText: t('text:Invoice_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:AR_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Original_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 80,
    },
  ];

  return (
    <>
      <BackHeaderTitle title={t('text:Request_for_Early_Repayment')} />
      <div className="content-area">
        <SectionTitle title={t('text:Financing_List_for_Early_Repayment_Request')} />
        <form>
          <div className="d-flex information-filter mt-0">
            <div className="information-form__item__select position-relative">
              <select className="information-form__select">
                <option>{t('text:Financier_Financing_ID')}</option>
              </select>
            </div>
            <input onChange={onChangeFinancingNumberInput} type="text" placeholder={t('text:Please_type_here')} />
            <Button onClick={onClickSearch}>{t('text:Search')}</Button>
          </div>
        </form>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerEarlyRepaymentRegister;
