import { useFinancingOptionContext } from './FinancingOptionContext';

const useFinancingOptionViewModel = () => {
  const {
    anchorFinancingOption,
    isFirstRegisteredWaitingAgreement,
    updateAllFinancingOption,
    updateFinancingOption,
    fetchWaitingFinancingOption,
    fetchFinancingOptionDetail,
  } = useFinancingOptionContext();

  return {
    anchorFinancingOption,
    isFirstRegisteredWaitingAgreement,
    updateAllFinancingOption,
    updateFinancingOption,
    fetchWaitingFinancingOption,
    fetchFinancingOptionDetail,
  };
};

export default useFinancingOptionViewModel;
