import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import { COLLATERAL_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import { checkInterfaceType } from 'utils/storage/LocalStorage';

import useAgreementLocationState from '../../hooks/useAgreementLocationState';

export enum PAGE_TYPE {
  REGISTER,
  WAITING_DETAIL,
  REGISTERED_DETAIL,
}

type ExtraInformationStateType = {
  useAgreementInterface: boolean;
  useAgreementInterfaceByKeyUnion: boolean;
  supportedCollateralType: COLLATERAL_TYPE;
  errorHandlerOfLocationState: () => boolean;
  useAnchorUserInterface: boolean;
  useDepositTransaction: boolean;
  pageType: PAGE_TYPE;
  anchorAgreementId: number;
  waitingAnchorAgreementId: number;
};

const ExtraInformationContext = createContext<ExtraInformationStateType | null>(null);

export const useExtraInformationContext = () => {
  const context = useContext(ExtraInformationContext);
  if (!context) {
    throw Error('useExtraInformationContext should be used within ExtraInformationContext.Provider');
  }

  return context;
};

type ExtraInformationProviderPropsType = {
  children: ReactNode;
};

const channelAnchorUserInterfaceTypes = [FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_ANCHOR_AGREEMENT];
const vendorAnchorUserInterfaceTypes = [FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_BUYER_AGREEMENT];

const ExtraInformationProvider = ({ children }: ExtraInformationProviderPropsType) => {
  const { useAgreementInterface, supportedCollateralType, errorHandlerOfLocationState } = useAgreementLocationState();
  const { anchorAgreementId, waitingAnchorAgreementId } = useParams() as any;

  const useAnchorUserInterface = checkInterfaceType(
    supportedCollateralType === COLLATERAL_TYPE.INVOICE
      ? channelAnchorUserInterfaceTypes
      : vendorAnchorUserInterfaceTypes,
  );

  const useAgreementInterfaceByKeyUnion = checkInterfaceType([
    supportedCollateralType === COLLATERAL_TYPE.INVOICE
      ? FINANCIER_INTERFACE_TYPE.ANCHOR_AGREEMENT_BY_KEY_UNION
      : FINANCIER_INTERFACE_TYPE.BUYER_AGREEMENT_BY_KEY_UNION,
  ]);

  const useDepositTransaction = checkInterfaceType([FINANCIER_INTERFACE_TYPE.DEPOSIT_TRANSACTION]);

  const pageType = useMemo(() => {
    if (!isNil(anchorAgreementId)) return PAGE_TYPE.REGISTERED_DETAIL;
    if (!isNil(waitingAnchorAgreementId)) return PAGE_TYPE.WAITING_DETAIL;
    else return PAGE_TYPE.REGISTER;
  }, [anchorAgreementId, waitingAnchorAgreementId]);

  const value = useMemo(
    () => ({
      useAgreementInterface,
      useAgreementInterfaceByKeyUnion,
      supportedCollateralType,
      errorHandlerOfLocationState,
      useAnchorUserInterface,
      useDepositTransaction,
      pageType,
      anchorAgreementId,
      waitingAnchorAgreementId,
    }),
    [
      anchorAgreementId,
      errorHandlerOfLocationState,
      pageType,
      supportedCollateralType,
      useAgreementInterface,
      useAgreementInterfaceByKeyUnion,
      useAnchorUserInterface,
      useDepositTransaction,
      waitingAnchorAgreementId,
    ],
  );

  return <ExtraInformationContext.Provider value={value}>{children}</ExtraInformationContext.Provider>;
};

export default ExtraInformationProvider;
