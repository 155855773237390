import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import type { PotentialPartnerFinancingApplicationDetailVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';

import PPSubTitle from '../../../../../components/potential-partner/PPSubTitle/PPSubTitle';

interface PotentialPartnerSubmissionDetailAnchorFinancierInfoProps {
  data: PotentialPartnerFinancingApplicationDetailVOModel;
}
function PotentialPartnerSubmissionDetailAnchorFinancierInfo({
  data,
}: PotentialPartnerSubmissionDetailAnchorFinancierInfoProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="pp-mb-56">
        <PPSubTitle title={t('text:Financier_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} value={data.financierName} />
              <FormValue label={t('text:Requested_Branch')} value={data.requestBranchName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="pp-mb-56">
        <PPSubTitle title={t('text:Buyer_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Buyer_Name')} value={data.anchorClientName} />
              <FormValue label={t('text:Selected_Currency')} value={data.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default PotentialPartnerSubmissionDetailAnchorFinancierInfo;
