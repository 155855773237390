import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';

import { useSystemFinancingDetailInvoiceInformationLogics } from './logics';

interface SystemFinancingDetailInvoiceInformationProps {
  invoiceData: SuccessInvoiceDetailVOModel;
  loanData: LoanDetailVOModel;
}
function SystemFinancingDetailInvoiceInformation({
  invoiceData,
  loanData,
}: SystemFinancingDetailInvoiceInformationProps) {
  const { t } = useTranslation(['format']);

  const { onClickDownloadInvoiceFile } = useSystemFinancingDetailInvoiceInformationLogics(loanData.invoiceId);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Invoice_Information')} />
      <div className="information-form-border">
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Invoice_Number')}
              subLabel={`(${t('text:Invoice_Reference_Number')})`}
              value={invoiceData.invoiceNumber}
              subValue={invoiceData.referenceNumber}
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Invoice_Issued_Date')}
              value={invoiceData.invoiceIssuedDate}
              format="date"
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Invoice_Amount')}
              value={invoiceData.invoiceAmount}
              format="number"
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Payment_Date')}
              value={invoiceData.settlementDate}
              format="date"
            />
          </div>
          <div className="row">
            <FormValue col={3} label={t('text:Anchor_Name')} value={invoiceData.anchorClientName} />
            <FormValue col={3} label={t('text:Partner_Name')} value={invoiceData.dealerClientName} />
            <FormValue col={3} label={t('text:Currency')} value={invoiceData.currencyType} />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:Scanned_Tax_Invoice_File')} />
        <div className="detail-in-file-download-form">
          <div className="d-flex justify-content-between">
            <label className="information-form__label">{t('text:Attached_File')}</label>
            <input
              type="text"
              placeholder={loanData.invoiceAttachmentName ? loanData.invoiceAttachmentName : t('text:no_file_attached')}
              className="upload-file-input"
              readOnly
            />
            <DownloadButton onClick={onClickDownloadInvoiceFile} disabled={!loanData.invoiceAttachmentId} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemFinancingDetailInvoiceInformation;
