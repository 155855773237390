import type { ChangeEvent } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { initializeRefValue } from 'utils/initialize';

import './FileInput.scss';
import { Button2ColorEnum } from '../Button2/Button2';
import IconButton2, { IconButtonSizeEnum } from '../Button2/IconButton2';

interface FileInputProps {
  id: string;
  name: string;
  value?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const FileInput = forwardRef(({ id, name, disabled = false, onChange = () => {}, value }: FileInputProps, ref) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState<string | undefined>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  useImperativeHandle(ref, () => fileInputRef.current);

  const onClickRemoveIcon = (e: any): void => {
    e.preventDefault();
    initializeRefValue(fileInputRef);

    setFileName(undefined);
  };

  const handleOnchange = (e: any) => {
    e.preventDefault();
    onChange(e);
    if (e.target?.files[0]?.name) setFileName(e.target.files[0].name);
  };

  useEffect(() => {
    const files = fileInputRef.current?.files;
    if (files && files[0] && files[0].name) {
      setFileName(files[0].name);
    }
  }, [fileInputRef]);

  return (
    <div className="file-input-form d-flex align-items-center">
      <input
        type="file"
        id={id}
        name={name}
        className="d-none"
        onChange={(e: any) => {
          handleOnchange(e);
        }}
        ref={fileInputRef}
        disabled={disabled}
        defaultValue={value}
        // todo : accept
      />
      <label htmlFor={id}>{t('text:Attach_File')}</label>
      <div id="fileName" className="file-input-form__name">
        {fileName ? (
          <span className="file-input-form__name">{fileName}</span>
        ) : (
          <span className="file-input-form__name--no-attached"> {t('text:No_file_attached')}</span>
        )}
      </div>
      {fileName && !disabled && (
        <IconButton2
          icon={<FontAwesomeIcon icon={faXmark} fontSize={12} />}
          onClick={onClickRemoveIcon}
          size={IconButtonSizeEnum.XS}
          color={Button2ColorEnum.GRAY_SCALE}
        />
      )}
    </div>
  );
});

FileInput.displayName = 'FileInput';
export default FileInput;
