import type Pageable from 'models/Pageable';

import { formattingToTempWaitingInvoiceVOModel } from './TempWaitingInvoiceVO';

import type { TempWaitingInvoiceVO, TempWaitingInvoiceVOModel } from './TempWaitingInvoiceVO';

export interface TempInvoiceSummaryVO {
  tempInvoiceSummaryId: number;
  uploadEnterpriseId: number;
  uploadEnterpriseName: string;
  uploadUserId: number;
  uploadUserName: string;
  tempInvoiceList: Pageable<TempWaitingInvoiceVO[]>;
}

export interface TempInvoiceSummaryVOModel extends TempInvoiceSummaryVO {
  tempInvoiceList: Pageable<TempWaitingInvoiceVOModel[]>;
}

export function formattingToTempInvoiceSummaryVOModel(data: TempInvoiceSummaryVO): TempInvoiceSummaryVOModel {
  return {
    ...data,
    tempInvoiceList: {
      ...data.tempInvoiceList,
      content: data.tempInvoiceList.content.map(item => formattingToTempWaitingInvoiceVOModel(item)),
    },
  };
}
