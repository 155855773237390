import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import { Border, Content, Row, SubTitle } from 'components/templates/section';

import useAssociatedAnchorMasterAgreementController from './useAssociatedAnchorMasterAgreementController';

const AssociatedAnchorMasterAgreement = () => {
  const { t } = useTranslation(['format']);

  const { register, handleSearchAnchorAgreementClick, anchorAgreementInfo, isSearchAnchorAgreementButtonVisible } =
    useAssociatedAnchorMasterAgreementController();

  const {
    startDate: anchorAgreementStartDate,
    expiryDate: anchorAgreementExpiryDate,
    contractNo: anchorAgreementContractNo,
    financierClientName: anchorClientName,
    divisionName: anchorAgreementDivisionName,
    division: anchorAgreementDivision,
  } = anchorAgreementInfo;

  return (
    <>
      <SubTitle>{t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT')}</SubTitle>
      <Content hasSubRow>
        <Row singleColumnPosition={isSearchAnchorAgreementButtonVisible ? 'bottom' : 'all'}>
          <Form.Label required={isSearchAnchorAgreementButtonVisible}>{t('text:Anchor_Master_Agreement')}</Form.Label>
          {isSearchAnchorAgreementButtonVisible && (
            <Button size={ButtonSizeEnum.SM} bold onClick={handleSearchAnchorAgreementClick}>
              {t('text:Search_Agreement')}
            </Button>
          )}
          <Border>
            <Content isInnerStyle>
              <input type="hidden" name="anchorAgreementId" ref={register} />
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Anchor_Master_Agreement_Number')}</Form.Label>
                  <Form.Value value={anchorAgreementContractNo} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Anchor_Name')}</Form.Label>
                  <Form.Value value={anchorClientName} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Division')}</Form.Label>
                  <Form.Value
                    value={
                      anchorAgreementDivision ? [anchorAgreementDivision, anchorAgreementDivisionName].join(' / ') : ''
                    }
                  />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Effective_Date')}</Form.Label>
                  <Form.Value value={anchorAgreementStartDate} format="date" />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Expiration_Date')}</Form.Label>
                  <Form.Value value={anchorAgreementExpiryDate} format="date" />
                </Form.Control>
              </Row>
            </Content>
          </Border>
        </Row>
      </Content>
    </>
  );
};

export default AssociatedAnchorMasterAgreement;
