import type { ChangeEvent } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import type { FieldError } from 'react-hook-form';

import useResizeObserver from 'hooks/useResizeObserver';

import { FormErrorMessage } from '../CommonForm/FormErrorMessage';

interface TextAreaProps {
  label: string;
  disabled?: boolean;
  name: string;
  minHeight?: number;
  maxLength?: number;
  placeholder?: string;
  curLength?: number;
  className?: string;
  error?: FieldError;
}

const TextArea = forwardRef(
  (
    {
      label,
      maxLength = 400,
      minHeight = 100,
      placeholder = 'Please type here',
      disabled = false,
      name,
      curLength,
      className = '',
      error,
    }: TextAreaProps,
    ref,
  ) => {
    const [strLength, setStrLength] = useState<number>(0);

    useEffect(() => {
      if (curLength) setStrLength(curLength);
    }, [curLength]);

    const handleTextAreaResize = useCallback(element => {
      // 텍스트 입력 시 자동 높이 조절
      element.style.height = 'auto'; // height 초기화
      element.style.height = element.scrollHeight + 'px';
    }, []);

    const textAreaInputRef = useResizeObserver<HTMLTextAreaElement>({
      callback: handleTextAreaResize,
    });

    useImperativeHandle(ref, () => textAreaInputRef.current);

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setStrLength(e.target.value.length);
      handleTextAreaResize(e.target);
    };

    return (
      <>
        <label className="information-form__label">{label}</label>
        <div>
          <textarea
            style={{
              overflow: 'hidden',
              height: textAreaInputRef.current?.scrollHeight + 'px',
              minHeight: `${minHeight}px`,
              resize: 'none',
            }}
            maxLength={maxLength}
            className={`information-form__input ${error ? 'error-input-border' : ''} ${className}`}
            onChange={e => handleTextAreaChange(e)}
            placeholder={placeholder}
            disabled={disabled}
            ref={textAreaInputRef}
            name={name}
          />
          {!disabled && (
            <div className="d-flex p-1">
              <FormErrorMessage error={error} />
              <div className="ms-auto text-bold-sub700">
                {strLength} / {maxLength}
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
);

TextArea.displayName = 'TextArea';
export default TextArea;
