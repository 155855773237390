import { useFormContext } from 'components/stateless/Form';
import { COLLATERAL_TYPE } from 'enums';

import useExtraInformationViewModel from '../../models/extraInformation/useExtraInformationViewModel';

const useDisbursementAccountController = () => {
  const { supportedCollateralType } = useExtraInformationViewModel();
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const {
    methods: { watch },
  } = useFormContext();

  const { anchorAccount: watchedIsAnchorAccount } = watch();

  const isShowAccountInput = (isAr && watchedIsAnchorAccount === 'true') || !isAr;

  return {
    isShowAccountInput,
    isAr,
  };
};

export default useDisbursementAccountController;
