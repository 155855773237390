import type { ButtonHTMLAttributes } from 'react';

import { clsx } from 'clsx';

import './Button.scss';
import WithLink from './WithLink';

export enum ButtonColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BLUE = 'blue',
  RED = 'red',
}

export enum ButtonVariantEnum {
  FILLED = 'filled',
  OUTLINED = 'outlined',
}

export enum ButtonSizeEnum {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export interface ButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariantEnum;
  size?: ButtonSizeEnum;
  color?: ButtonColorEnum;
  bold?: boolean;
  fullWidth?: boolean;
}

const Button = ({
  type = 'button',
  className = '',
  variant = ButtonVariantEnum.FILLED,
  size = ButtonSizeEnum.XS,
  color = ButtonColorEnum.PRIMARY,
  bold = false,
  fullWidth = false,
  children,
  ...restProps
}: ButtonPropsType) => {
  const classes = clsx('common-button', variant, color, size, {
    [className]: className,
    'text-bold': bold,
    full: fullWidth,
  });

  return (
    <button type={type} className={classes} {...restProps}>
      {children}
    </button>
  );
};

export default WithLink(Button);
