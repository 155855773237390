import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DeletedInvoiceVOModel } from 'models/vo/DeletedInvoiceVO';
import type { SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierDeletedInvoicesList } from 'utils/http/api/financier/deleted-invoices';
import type { FinancierDeletedInvoicesListRequest } from 'utils/http/api/financier/deleted-invoices/requests';
import { requestFinancierSuccessInvoiceList } from 'utils/http/api/financier/success-invoices';
import type { FinancierSuccessInvoiceListRequest } from 'utils/http/api/financier/success-invoices/request';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

export const FI_REGISTERED_INVOICE_LIST_QS_KEY = 'fi-registered-invoice-list';
export const FI_DELETED_INVOICE_LIST_QS_KEY = 'fi-deleted-invoice-list';

const useFinancierInvoiceListController = () => {
  const modal = useModal();
  const mounted = useMounted();

  const [registeredInvoicePage, setRegisteredInvoicePage] = useState<Pageable<SuccessInvoiceVOModel[]>>();
  const [deletedInvoicePage, setDeletedInvoicePage] = useState<Pageable<DeletedInvoiceVOModel[]>>();

  const { pageable: registeredListPageable, setPageable: setRegisteredListPageable } = usePageable(
    FI_REGISTERED_INVOICE_LIST_QS_KEY,
  );

  const { pageable: deletedListPageable, setPageable: setDeletedListPageable } =
    usePageable(FI_DELETED_INVOICE_LIST_QS_KEY);

  const registeredInvoiceSearchForm = useForm<FinancierSuccessInvoiceListRequest>();
  const deletedInvoiceSearchForm = useForm<FinancierDeletedInvoicesListRequest>();

  const constructAndAddSearchParams = () => {
    const registeredTabQs = makeSearchParamsPattern(
      {
        ...registeredInvoiceSearchForm.getValues(),
        pageNumber: registeredListPageable.currentPage,
        rowCount: registeredListPageable.sizePerPage,
      },
      FI_REGISTERED_INVOICE_LIST_QS_KEY,
    );

    const deletedTabQs = makeSearchParamsPattern(
      {
        ...deletedInvoiceSearchForm.getValues(),
        pageNumber: deletedListPageable.currentPage,
        rowCount: deletedListPageable.sizePerPage,
      },
      FI_DELETED_INVOICE_LIST_QS_KEY,
    );

    addSearchParams(registeredTabQs + deletedTabQs);
  };
  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierSuccessInvoiceListRequest>(
        registeredInvoiceSearchForm.setValue,
        getParsedSearchParams(FI_REGISTERED_INVOICE_LIST_QS_KEY).formSearchData,
      );
      setFormValues<FinancierDeletedInvoicesListRequest>(
        deletedInvoiceSearchForm.setValue,
        getParsedSearchParams(FI_DELETED_INVOICE_LIST_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const [registeredInvoiceResponse, deletedInvoiceResponse] = await Promise.all([
        requestFinancierSuccessInvoiceList(
          registeredListPageable.currentPage,
          registeredListPageable.sizePerPage,
          registeredInvoiceSearchForm.getValues(),
        ),
        requestFinancierDeletedInvoicesList(
          deletedListPageable.currentPage,
          deletedListPageable.sizePerPage,
          deletedInvoiceSearchForm.getValues(),
        ),
      ]);

      constructAndAddSearchParams();
      setRegisteredInvoicePage(registeredInvoiceResponse);
      setRegisteredListPageable(registeredInvoiceResponse);
      setDeletedInvoicePage(deletedInvoiceResponse);
      setDeletedListPageable(deletedInvoiceResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchFinancierRegisteredInvoiceList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: FinancierSuccessInvoiceListRequest,
  ) {
    try {
      const anchorSuccessArsPage = await requestFinancierSuccessInvoiceList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        FI_REGISTERED_INVOICE_LIST_QS_KEY,
      );

      setRegisteredInvoicePage(anchorSuccessArsPage);
      setRegisteredListPageable(anchorSuccessArsPage);
    } catch (e) {
      modal.show(e);
    }
  }

  const handleClickRegisteredInvoiceSearch = async () => {
    await fetchFinancierRegisteredInvoiceList(
      0,
      registeredListPageable.sizePerPage,
      registeredInvoiceSearchForm.getValues(),
    );
  };

  const paginateRegisteredInvoiceList = async (page: number, sizePerPage: number) => {
    await fetchFinancierRegisteredInvoiceList(
      page,
      sizePerPage,
      getParsedSearchParams(FI_REGISTERED_INVOICE_LIST_QS_KEY).formSearchData,
    );
  };

  async function fetchFinancierDeletedInvoiceList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: FinancierDeletedInvoicesListRequest,
  ) {
    try {
      const anchorDeletedArsPage = await requestFinancierDeletedInvoicesList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        FI_DELETED_INVOICE_LIST_QS_KEY,
      );

      setDeletedInvoicePage(anchorDeletedArsPage);
      setDeletedListPageable(anchorDeletedArsPage);
    } catch (e) {
      modal.show(e);
    }
  }

  const handleClickDeletedInvoiceSearch = async () => {
    await fetchFinancierDeletedInvoiceList(0, deletedListPageable.sizePerPage, deletedInvoiceSearchForm.getValues());
  };

  const paginateDeletedInvoiceList = async (page: number, sizePerPage: number) => {
    await fetchFinancierDeletedInvoiceList(
      page,
      sizePerPage,
      getParsedSearchParams(FI_DELETED_INVOICE_LIST_QS_KEY).formSearchData,
    );
  };

  return {
    registeredInvoicePage,
    deletedInvoicePage,
    registeredInvoiceSearchForm,
    deletedInvoiceSearchForm,
    registeredListPageable,
    deletedListPageable,
    handleClickRegisteredInvoiceSearch,
    paginateRegisteredInvoiceList,
    handleClickDeletedInvoiceSearch,
    paginateDeletedInvoiceList,
  };
};

export default useFinancierInvoiceListController;
