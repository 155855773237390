import { useTranslation } from 'react-i18next';

import { COLLATERAL_TYPE } from 'enums';

import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const usePlatformPermissionSettingsController = () => {
  const { t } = useTranslation(['format']);

  const { supportedCollateralType } = useExtraInformationViewModel();

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;

  const registrationRequestLabel = isInvoice
    ? t('text:Invoice_Registration_Request')
    : t('text:AR_Registration_Request');

  const registrationRequestDescription = isInvoice
    ? t('text:If_the_Partner_cannot_request_registration_only_the_Anchor_and_Financier_can_register_invoices')
    : t(
        'text:Select_whether_the_Partner_is_allowed_to_request_AR_registration_or_if_only_the_Anchor_can_register_the_AR',
      );

  return {
    registrationRequestLabel,
    registrationRequestDescription,
  };
};

export default usePlatformPermissionSettingsController;
