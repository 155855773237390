import type {
  PotentialPartnerAcquisitionSettingVO,
  PotentialPartnerAcquisitionSettingVOModel,
} from 'models/vo/PotentialPartnerAcquisitionSettingVO';
import { formattingToPotentialPartnerAcquisitionSettingVOModel } from 'models/vo/PotentialPartnerAcquisitionSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierPotentialPartnerAcquisitionSettingRegisterRequest } from './request';

/**
 * desc :: 파트너 제공 정보 조회
 * **/
export async function requestFinancierPotentialPartnerAcquisitionSettingInfo(): Promise<PotentialPartnerAcquisitionSettingVOModel> {
  const response = await http.get<PotentialPartnerAcquisitionSettingVO>({
    url: API_FI.POTENTIAL_PARTNER_ACQUISITION_SETTING.ACQUISITION_SETTING,
  });

  return formattingToPotentialPartnerAcquisitionSettingVOModel(response);
}

/**
 * desc :: 파트너 제공 정보 생성/수정
 * **/
export async function requestFinancierPotentialPartnerAcquisitionSettingRegister(
  data: FinancierPotentialPartnerAcquisitionSettingRegisterRequest,
): Promise<PotentialPartnerAcquisitionSettingVOModel> {
  const response = await http.post<PotentialPartnerAcquisitionSettingVO>({
    url: API_FI.POTENTIAL_PARTNER_ACQUISITION_SETTING.ACQUISITION_SETTING,
    data,
  });

  return formattingToPotentialPartnerAcquisitionSettingVOModel(response);
}
