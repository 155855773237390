import type Pageable from 'models/Pageable';
import type { LoanAdditionalDocumentVO, LoanAdditionalDocumentVOModel } from 'models/vo/LoanAdditionalDocumentVO';
import { formattingToLoanAdditionalDocumentVOModel } from 'models/vo/LoanAdditionalDocumentVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { DeLoanAdditionalDocumentDownloadAttachmentRequest } from './request';
import type { DeLoanAdditionalDocumentListRequest } from './request';

export async function requestDealerLoanAdditionalDocumentList(
  pageNumber: number,
  rowCount: number,
  searchValue: DeLoanAdditionalDocumentListRequest,
): Promise<Pageable<LoanAdditionalDocumentVOModel[]>> {
  const response = await http.get<Pageable<LoanAdditionalDocumentVO[]>>({
    url: API_DE.LOAN_ADDITIONAL_DOCUMENTS.LOAN_ADDITIONAL_DOCUMENTS_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToLoanAdditionalDocumentVOModel(data)),
  };
}

export async function requestDealerLoanAdditionalDocumentDownloadAttachment(
  loanAdditionalDocumentId: number,
  data: DeLoanAdditionalDocumentDownloadAttachmentRequest,
) {
  await http.download(API_DE.LOAN_ADDITIONAL_DOCUMENTS.DOWNLOAD_ATTACHMENT(loanAdditionalDocumentId), data);
}
