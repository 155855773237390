import type { Dispatch, ReactNode, SetStateAction } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import useResizeObserver from 'hooks/useResizeObserver';

export type AccordionStateType = {
  id: string;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
};

export const AccordionContext = createContext<AccordionStateType | null>(null);
AccordionContext.displayName = 'AccordionContext';

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordionContext should be used within AccordionContext.Provider');
  }

  return context;
};

interface AccordionRootPropsType {
  id: string;
  className?: string;
  children: ReactNode;
  defaultExpanded?: boolean;
  refresh?: () => void;
  trigger?: string;
}

const AccordionRoot = ({
  id,
  className,
  children,
  defaultExpanded = false,
  refresh,
  trigger,
}: AccordionRootPropsType) => {
  const [expanded, setExpanded] = useState(false);

  const contextValue = {
    id,
    expanded,
    setExpanded,
  };

  useEffect(() => {
    if (expanded && refresh) {
      refresh();
    }
  }, [expanded, refresh]);

  useLayoutEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded, trigger]);

  return (
    <AccordionContext.Provider value={contextValue}>
      <div className={className} id={id}>
        <div className="accordion-item">{children}</div>
      </div>
    </AccordionContext.Provider>
  );
};

type HeaderPropsType = {
  id: string;
  children: ReactNode;
  sideMenu?: ReactNode;
};

const Header = ({ id, children, sideMenu }: HeaderPropsType) => {
  const { expanded } = useAccordionContext();

  return (
    <h2 className="accordion-header" id={id}>
      {sideMenu ? (
        <div className="row">
          <div className={`pa-0 accordion-trans ${expanded ? 'col-9' : 'col-12'}`}>{children}</div>
          {expanded && (
            <div className="col-3 pa-0">
              <div className="flex-end align-items-center m-3">{sideMenu}</div>
            </div>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </h2>
  );
};

type TriggerPropsType = {
  className?: string;
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Trigger = ({ className = '', children, onClick }: TriggerPropsType) => {
  const { expanded, setExpanded } = useAccordionContext();

  const toggleAccordion = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setExpanded(prevExpanded => !prevExpanded);
    onClick && onClick(event);
  };

  return (
    <button
      className={`accordion-button ${expanded ? '' : 'collapsed'} ${className}`}
      type="button"
      aria-expanded={expanded}
      onClick={toggleAccordion}
    >
      {children}
    </button>
  );
};

type ContentPropsType = {
  className?: string;
  children: ReactNode;
};
const Content = ({ className = '', children }: ContentPropsType) => {
  const { id, expanded } = useAccordionContext();

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useResizeObserver<HTMLDivElement>({
    callback: useCallback(
      element => {
        if (contentWrapperRef.current) {
          const height = element.clientHeight;
          contentWrapperRef.current.style.height = expanded ? `${height}px` : '0';
        }
      },
      [expanded],
    ),
  });

  return (
    <div
      className={`accordion-collapse collapse ${expanded ? 'show' : ''}`}
      aria-labelledby={id}
      ref={contentWrapperRef}
    >
      <div className={className} ref={contentRef}>
        {children}
      </div>
    </div>
  );
};

const Accordion = Object.assign(AccordionRoot, {
  Header,
  Trigger,
  Content,
});
export default Accordion;
