import { useTranslation } from 'react-i18next';

import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';

function DigitalSignaturePluginDownloadModal() {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column gap-4">
      <SectionMessage
        message={[
          t('text:Please_install_the_following_plugin_first_and_restart_your_PC_to_complete_the_installation') +
            ` (${t('text:Plugin_doesnt_support_macOS')})`,
        ]}
      />
      <FormBorder>
        <div className="detail-in-file-download-form">
          <div className="d-flex justify-content-between">
            <label className="information-form__label">{t('text:Plugin')}</label>
            <input type="text" placeholder="BKAVplugin.js" className="upload-file-input" readOnly />
            <a href="/bkav/BkavCA_Signer_Plugin_2.1.6.exe" download>
              <Button className="h-100" variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY}>
                <>
                  <FontAwesomeIcon icon={faFileDownload} className="me-2" />
                  {t('text:Download')}
                </>
              </Button>
            </a>
          </div>
        </div>
      </FormBorder>
    </div>
  );
}

export default DigitalSignaturePluginDownloadModal;
