import type React from 'react';
import type { MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DeletedInvoiceVOModel } from 'models/vo/DeletedInvoiceVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestAnchorDeletedInvoicesList } from 'utils/http/api/anchor/deleted-invoices';
import type { AnchorDeletedInvoicesListRequest } from 'utils/http/api/anchor/deleted-invoices/requests';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

interface AnchorInvoiceListDeletedTabProps {
  invoicePage: Pageable<DeletedInvoiceVOModel[]> | undefined;
  pageable: PageableType;
  paginate(page: number, sizePerPage: number): void;
  handleClickSearch: MouseEventHandler<HTMLButtonElement>;
}

function AnchorInvoiceListDeletedTab({
  invoicePage,
  pageable,
  paginate,
  handleClickSearch,
}: AnchorInvoiceListDeletedTabProps) {
  const { t } = useTranslation();
  const { register, reset, control, getValues } = useFormContext<AnchorDeletedInvoicesListRequest>();
  const modal = useModal();

  const onRemoveFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    reset({});
  };

  const onClickExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const data = getValues();

    const excelColumns: ColumnOption<DeletedInvoiceVOModel>[] = [
      {
        header: t('text:Deleted_Date'),
        key: 'createdDateTime',
      },
      {
        header: t('text:BOE_Number'),
        key: 'billOfExchangeNo',
      },
      {
        header: t('text:Invoice_Number'),
        key: 'invoiceNumber',
      },
      {
        header: t('text:Invoice_Reference_Number'),
        key: 'referenceNumber',
      },
      {
        header: t('text:Partner_Name'),
        key: 'dealerEnterpriseName',
      },
      {
        header: t('text:Tax_Code'),
        key: 'dealerEnterpriseTaxCode',
      },
      {
        header: t('text:Currency'),
        key: 'currencyType',
      },
      {
        header: t('text:Invoice_Amount'),
        key: 'invoiceAmount',

        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Invoice_Issued_Date'),
        key: 'invoiceIssuedDate',
      },
      {
        header: t('text:Original_Payment_Date'),
        key: 'settlementDate',
      },
    ];

    const pdfTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Deleted_Date'),
      },
      {
        headerText: t('text:BOE_Number'),
      },
      {
        headerText: t('text:Invoice_Number'),
      },
      {
        headerText: t('text:Invoice_Reference_Number'),
      },
      {
        headerText: t('text:Partner_Name'),
      },
      {
        headerText: t('text:Tax_Code'),
      },
      {
        headerText: t('text:Currency'),
      },
      {
        headerText: t('text:Invoice_Amount'),
      },
      {
        headerText: t('text:Invoice_Issued_Date'),
      },
      {
        headerText: t('text:Original_Payment_Date'),
      },
    ];

    try {
      const deletedInvoicePageResponse = await requestAnchorDeletedInvoicesList(0, EXCEL_EXPORT_MAX_ROW_COUNT, data);
      const deletedInvoicePDFPageResponse = deletedInvoicePageResponse.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData = deletedInvoicePageResponse.content.map(item => ({
        createdDateTime: tableValueManage(
          item.createdDateTime,
          t('format:original-datetime', {
            value: item.createdDateTime,
            key: 'original-datetime',
          }),
        ),
        billOfExchangeNo: tableValueManage(item.billOfExchangeNo),
        invoiceNumber: tableValueManage(item.invoiceNumber),
        referenceNumber: tableValueManage(item.referenceNumber),
        dealerEnterpriseName: tableValueManage(item.dealerEnterpriseName),
        dealerEnterpriseTaxCode: tableValueManage(item.dealerEnterpriseTaxCode),
        currencyType: tableValueManage(item.currencyType),
        invoiceAmount: Number(item.invoiceAmount),
        invoiceIssuedDate: tableValueManage(
          item.invoiceIssuedDate,
          t('format:original-date', { value: item.invoiceIssuedDate, key: 'original-date' }),
        ),
        settlementDate: tableValueManage(
          item.settlementDate,
          t('format:original-date', { value: item.settlementDate, key: 'original-date' }),
        ),
      }));

      const excelExporterProps: ExportSpreadSheetProps<DeletedInvoiceVOModel> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: pdfTableHeaders,
        tableBody: renderResultTable(deletedInvoicePDFPageResponse, true),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Deleted_Date'),
    },
    {
      headerText: t('text:BOE_Number'),
    },
    {
      headerText: t('text:Invoice_Number'),
    },
    {
      headerText: t('text:Invoice_Reference_Number'),
    },
    {
      headerText: t('text:Dealer_Name'),
    },
    {
      headerText: t('text:Tax_Code'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Invoice_Amount'),
    },
    {
      headerText: t('text:Invoice_Issued_Date'),
    },
    {
      headerText: t('text:Original_Payment_Date'),
    },
  ];

  const renderResultTable = (data: DeletedInvoiceVOModel[], isPdf: boolean = false) => {
    return data?.map((item, i) => {
      return (
        <Tr key={i} className={isPdf ? 'virtual-table-row' : ''}>
          <Td data={item.createdDateTime} format="date" />
          <Td data={item.billOfExchangeNo} />
          <Td data={item.invoiceNumber} />
          <Td data={item.referenceNumber} />
          <Td data={item.dealerEnterpriseName} />
          <Td data={item.dealerEnterpriseTaxCode} />
          <Td data={item.currencyType} />
          <Td data={item.invoiceAmount} format="number" />
          <Td data={item.invoiceIssuedDate} format="date" />
          <Td data={item.settlementDate} format="date" />
        </Tr>
      );
    });
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Deleted_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="createdDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="createdDateTo" control={control} />
              <SearchLabel label={t('text:BOE_Number')} />
              <SearchInput name="billOfExchangeNo" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Number')} />
              <SearchInput name="invoiceNumber" ref={register} />
              <SearchLabel label={t('text:Invoice_Reference_Number')} />
              <SearchInput name="referenceNumber" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Dealer_Name')} />
              <SearchInput name="dealerName" ref={register} />
              <SearchLabel label={t('text:Tax_Code')} />
              <SearchInput name="dealerTaxCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchLabel label={t('text:Invoice_Amount')} />
              <SearchInput col={2} name="minimumInvoiceAmount" placeholder={t('text:from')} ref={register} />
              <SearchInput col={2} name="maximumInvoiceAmount" placeholder={t('text:to')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Issued_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="invoiceIssuedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="invoiceIssuedDateTo" control={control} />
              <SearchLabel label={t('text:Original_Payment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={control} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {invoicePage?.totalElements ? invoicePage.totalElements : '0'}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable(invoicePage?.content!)}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorInvoiceListDeletedTab;
