import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import type { SelectOptionType } from 'components/stateless/Select/Select';
import Select from 'components/stateless/Select/Select';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import usePageable from 'hooks/usePageable';
import type { UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import { getLocalizedFullMonth } from 'utils/date/date';
import { requestSyBillingList, requestSyBillingTotalAmount } from 'utils/http/api/system/billing';
import type { SearchSyBillingDTO } from 'utils/http/api/system/billing/requests';
import useModal from 'utils/modal/useModal';

import UsageTable from '../../components/UsageTable';

function UsageHistory() {
  const { t } = useTranslation();
  const { show: showModal } = useModal();
  const [billingList, setBillingList] = useState<UsageSummaryVOModel[]>([]);
  const { pageable, setPageable } = usePageable();
  const { register, getValues, handleSubmit } = useForm<{ date: Pick<SearchSyBillingDTO, 'fromDate' | 'toDate'> }>();

  const fetchBilling = async (page = 1, sizePerPage = 10) => {
    const searchValue = JSON.parse(getValues().date as string);

    try {
      const [billingList, billingTotalAmount] = await Promise.all([
        requestSyBillingList(page, sizePerPage, searchValue),
        requestSyBillingTotalAmount(page, sizePerPage, searchValue),
      ]);

      billingTotalAmount === null
        ? setBillingList(billingList.content)
        : setBillingList([billingTotalAmount, ...billingList.content]);

      setPageable(billingList);
    } catch (error) {
      showModal(error);
    }
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchBilling(page, sizePerPage);
  };

  const handleFormSubmit = async () => {
    await fetchBilling();
  };

  const monthOptions: SelectOptionType[] = Array.from({ length: 12 }).map((_, index) => {
    const month = dayjs().subtract(index + 1, 'month');
    const startDateOfMonth = month.startOf('month').format('YYYY-MM-DD');
    const endDateOfMonth = month.endOf('month').format('YYYY-MM-DD');

    return {
      label: `${getLocalizedFullMonth(month.format('MMMM'))} ${month.format('YYYY')}`,
      value: JSON.stringify({ fromDate: startDateOfMonth, toDate: endDateOfMonth }),
    };
  });

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Platform_Usage_History')} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex information-filter">
          <div className="flex-align-self-center text-bold-sub700 ms-3 me-2">{t('text:Month')}</div>
          <Select
            className="information-form__select"
            selectOptions={monthOptions}
            name="date"
            ref={register}
            placeholderOptions={{ show: true, text: t('text:Select_Month') }}
          />
          <Button type="submit" className="ms-1">
            {t('text:Search')}
          </Button>
        </div>
      </form>
      <UsageTable usageDataList={billingList} isUsageHistory />
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default UsageHistory;
