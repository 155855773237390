import { ATTACHMENT_TYPE } from 'enums';
import { requestFinancierDownloadScannedAr } from 'utils/http/api/financier/loans';
import { requestFinancierMultipleRequestLoanDownloadAttachment } from 'utils/http/api/financier/multiple-request-loans';
import useModal from 'utils/modal/useModal';

export function useFinancingRequestInformationController(multipleRequestLoansId: number) {
  const modal = useModal();

  const handleDownloadLoanAgreementClick = async (e: any) => {
    e.preventDefault();
    try {
      await requestFinancierMultipleRequestLoanDownloadAttachment(multipleRequestLoansId, {
        attachmentType: ATTACHMENT_TYPE.LOAN_AGREEMENT,
      });
    } catch (e: any) {
      modal.show(e);
    }
  };

  const handleDownloadDebtSellingNotificationClick = async (e: any) => {
    e.preventDefault();
    try {
      await requestFinancierMultipleRequestLoanDownloadAttachment(multipleRequestLoansId, {
        attachmentType: ATTACHMENT_TYPE.FACTORING_NOTIFICATION,
      });
    } catch (e: any) {
      modal.show(e);
    }
  };
  const handleDownloadScannedArFileClick = async (loanId: number) => {
    try {
      await requestFinancierDownloadScannedAr(loanId);
    } catch (e: any) {
      modal.show(e);
    }
  };

  return {
    handleDownloadLoanAgreementClick,
    handleDownloadDebtSellingNotificationClick,
    handleDownloadScannedArFileClick,
  };
}
