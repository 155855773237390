import type Pageable from 'models/Pageable';
import type { UserVO, UserVOModel } from 'models/vo/UserVO';
import { formattingToUserVOModel } from 'models/vo/UserVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { systemUserCreateRequest, systemUserSearchRequest, systemUserUpdateRequest } from './request';

export async function requestSystemUserList(
  pageNumber: number,
  rowCount: number,
  searchValue: systemUserSearchRequest,
): Promise<Pageable<UserVOModel[]>> {
  const response = await http.get<Pageable<UserVO[]>>({
    url: API_SY.USERS.USER_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const userList: UserVOModel[] = response.content.map((data: UserVO) => formattingToUserVOModel(data));

  return { ...response, content: userList };
}

// admin 사용자 상세
export async function requestSystemUserDetail(userId: number): Promise<UserVOModel> {
  const response = await http.get<UserVO>({
    url: API_SY.USERS.USER_DETAIL(userId),
  });

  return formattingToUserVOModel(response);
}

// admin 사용자 수정
export async function requestSystemUserUpdate(userId: number, data: systemUserUpdateRequest): Promise<UserVOModel> {
  const response = await http.put<UserVO>({
    url: API_SY.USERS.USER_DETAIL(userId),
    data,
  });

  return formattingToUserVOModel(response);
}

// admin 사용자 생성
export async function requestSystemUserCreate(data: systemUserCreateRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_SY.USERS.CREATE_FINANCIER_ADMIN,
    data,
  });

  return formattingToUserVOModel(response);
}

// admin 사용자 suspend
export async function requestSystemUserSuspend(userId: number, suspended: boolean): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_SY.USERS.SUSPEND_USER(userId),
    data: { suspended },
  });

  return formattingToUserVOModel(response);
}

// admin 사용자 invite
export async function requestSystemUserInvite(userId: number) {
  return await http.post({
    url: API_SY.USERS.INVITE_FINANCIER_ADMIN(userId),
  });
}
