import { useDivisionsContext } from './Context';

const useDivisionsViewModel = () => {
  const {
    divisionRegistrable,
    divisionName,
    divisionSelectOptions,
    financierName,
    updateDivisionRegistrable,
    updateDivisionName,
    fetchDivisionRegistrable,
    fetchDivisionSelectOptions,
  } = useDivisionsContext();

  return {
    divisionRegistrable,
    divisionName,
    divisionSelectOptions,
    financierName,
    updateDivisionRegistrable,
    updateDivisionName,
    fetchDivisionRegistrable,
    fetchDivisionSelectOptions,
  };
};

export default useDivisionsViewModel;
