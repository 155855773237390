import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface FinancierCurrencyCommonSettingVO {
  financierCurrencyCommonSettingId: number;
  currencyType: string;
  minimumLoanAmount: number;
  minimumUnitAmount: number;
  financierId: number;
  financierName: string;
}

export interface FinancierCurrencyCommonSettingVOModel extends FinancierCurrencyCommonSettingVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToFinancierCurrencyCommonSettingVOModel(
  data: FinancierCurrencyCommonSettingVO,
): FinancierCurrencyCommonSettingVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
