import { DOCUMENT_FORM_TYPE, stringToEnum } from 'enums';

export interface FinancierDocumentFormVO {
  financierDocumentFormId: number;
  description: string;
  documentFormName: string;
  documentFormType: string;
  updatedDateTime: string;
  financierId: number;
  financierName: string;
  attachmentId: number;
  fileName: string;
  path: string;
  fileSize: number;
  fileContentType: string;
}

export interface FinancierDocumentFormVOModel extends FinancierDocumentFormVO {
  documentFormType: DOCUMENT_FORM_TYPE;
}

export function formattingToFinancierDocumentFormVOModel(data: FinancierDocumentFormVO): FinancierDocumentFormVOModel {
  return {
    ...data,
    documentFormType: stringToEnum(DOCUMENT_FORM_TYPE, data.documentFormType),
  };
}
