import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import Icon from 'components/stateless/Icons';

function ResetPasswordComplete() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <PublicHeaderBar />
      <div className="align-middle row">
        <div className="email-sent-form">
          <div className="icon-form">
            <Icon.Check />
          </div>
          <h2 className="common-content__title">{t('text:Password_Changed')}!</h2>
          <div className="common-content__message pt-0 mb-3">
            {t('text:Your_password_has_been_changed_successfully')}
          </div>
          <div className="mt-3 w-100 text-center">
            <Button size={ButtonSizeEnum.LG} onClick={() => history.push('/')} style={{ width: '300px' }}>
              {t('text:Back_to_Sign_in')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordComplete;
