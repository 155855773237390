import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

export async function requestAnchorOtpByDealerAgreementId(dealerAgreementId: number): Promise<string> {
  const response = await http.post<string>({
    url: API_AC.OTP.OTP_BY_DEALER_AGREEMENT_ID,
    data: { dealerAgreementId },
  });

  return response;
}

export async function requestAnchorOtpByFinancierId(financierId: number): Promise<any> {
  const response = await http.post<string>({
    url: API_AC.OTP.OTP_BY_FINANCIER_ID,
    data: { financierId },
  });

  return response;
}
