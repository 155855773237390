import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import {
  convertToDealerAgreementResetData,
  convertToInterfaceDealerAgreementData,
} from 'models/convertor/dealerAgreement';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import SearchInterfaceByContactNoModal from '../../../modals/SearchInterfaceByContactNoModal';
import SearchInterfaceByKeyUnionModal from '../../../modals/SearchInterfaceByKeyUnionModal';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorAgreementInfoViewModel from '../../../models/agreement/useAnchorAgreementInfoViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useRegistrationLoadAgreementController = () => {
  const { show: showModal, id: modalId } = useModal();

  const { t } = useTranslation(['format']);
  const {
    supportedCollateralType,
    useAgreementInterfaceByKeyUnion,
    useAnchorUserInterface,
    updateIsMatchedWithUploadedPartner,
  } = useExtraInformationViewModel();

  const { updateAllAgreement, updateIsSearchedAgreement } = useAgreementViewModel();

  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();

  const interfaceDataRef = useRef<DealerAgreementDetailVOModel>({} as DealerAgreementDetailVOModel);

  const {
    methods: { reset },
  } = useFormContext();

  const setSearchedInterfaceData = useCallback(searchedData => {
    interfaceDataRef.current = searchedData;
  }, []);

  const handleApplyClick = () => {
    const convertedInterfaceAgreement = convertToInterfaceDealerAgreementData({
      prevAnchorAgreementData: anchorAgreementInfo,
      interfaceData: interfaceDataRef.current,
      useAnchorUserInterface,
    });

    if (convertedInterfaceAgreement) {
      updateIsSearchedAgreement(true);
      updateAllAgreement(convertedInterfaceAgreement);
      updateIsMatchedWithUploadedPartner(false);
      reset(convertToDealerAgreementResetData(convertedInterfaceAgreement));
    }
  };

  const updateDealerAgreementModalOptions: ModalOptions = {
    modalType: ModalType.CONFIRM,
    modalSize: ModalSize.XL,
    title: t('text:Search_Partner_Agreement'),
    confirmBtnText: t('text:Apply'),
    closeBtnText: t('text:Close'),
    confirmBtnCb: handleApplyClick,
  };

  const showSearchInterfaceByKeyUnionModal = () => {
    showModal(
      <SearchInterfaceByKeyUnionModal
        modalId={modalId}
        collateralType={supportedCollateralType}
        getInterfaceData={setSearchedInterfaceData}
      />,
      updateDealerAgreementModalOptions,
    );
  };

  const showSearchInterfaceByContactNoModal = () => {
    showModal(
      <SearchInterfaceByContactNoModal
        modalId={modalId}
        collateralType={supportedCollateralType}
        getInterfaceData={setSearchedInterfaceData}
      />,
      updateDealerAgreementModalOptions,
    );
  };

  const handleLoadAgreementButtonClick = () => {
    useAgreementInterfaceByKeyUnion ? showSearchInterfaceByKeyUnionModal() : showSearchInterfaceByContactNoModal();
  };

  return { handleLoadAgreementButtonClick };
};

export default useRegistrationLoadAgreementController;
