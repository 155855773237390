import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash-es';

import { SUPPORTED_COLLATERAL_TYPE } from 'enums';
import { getCollateralType } from 'utils/storage/LocalStorage';

import TabView from './TabView';

import type { GetTabItemName, IsActiveTab, OnClickTab, TabProps } from './Tab.d';

function Tab({
  initialActiveTabIndex = 0,
  tabViewList,
  tabList,
  tabType = 'theme',
  disabled = false,
  className = '',
}: TabProps) {
  const { t } = useTranslation(['format']);
  const collateralType = getCollateralType();

  const WAITING_FOR_APPROVAL_NAME = t('text:Waiting_for_Approval');
  const REGISTRATION_COMPLETED_NAME = t('text:Registration_Completed');
  const VENDOR_FINANCE_NAME = t('text:Vendor_Finance');
  const DEALER_FINANCE_NAME = t('text:Dealer_Finance');

  const [activeTabIndex, setActiveTabIndex] = useState<number>(initialActiveTabIndex);

  const isActiveTab: IsActiveTab = tabIndex => {
    return activeTabIndex === tabIndex;
  };

  const handleClickTab: OnClickTab = (e, tabIndex, onClickTab) => {
    e.preventDefault();

    setActiveTabIndex(tabIndex);

    onClickTab && onClickTab();
  };

  const getTabItemName: GetTabItemName = (tabIndex, tabName) => {
    if (tabName) return tabName;

    if (tabType === 'approvalStatus') {
      return tabIndex === 0 ? WAITING_FOR_APPROVAL_NAME : REGISTRATION_COMPLETED_NAME;
    }

    if (tabType === 'financeType') {
      if (collateralType === SUPPORTED_COLLATERAL_TYPE.AR) return VENDOR_FINANCE_NAME;

      if (collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE) return DEALER_FINANCE_NAME;

      // SUPPORTED_COLLATERAL_TYPE.BOTH or System 계정
      return tabIndex === 0 ? VENDOR_FINANCE_NAME : DEALER_FINANCE_NAME;
    }

    return '';
  };

  const TAB_VIEW_LIST = (() => {
    if (tabType === 'financeType') {
      if (collateralType === SUPPORTED_COLLATERAL_TYPE.AR) return [tabViewList?.[0]] ?? [];

      if (collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE) return [tabViewList?.[1]] ?? [];
    }

    return tabViewList;
  })();

  const TAB_LIST = (() => {
    // 특정 tabType 사용 시 tabList는 필수값 x -> tabView 길이만큼 빈 객체로 채워진 배열 생성
    if (isEmpty(tabList)) return Array(TAB_VIEW_LIST.length).fill({});

    if (tabType === 'financeType' && !isEmpty(tabList)) {
      if (collateralType === SUPPORTED_COLLATERAL_TYPE.AR) return [tabList?.[0]] ?? [];

      if (collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE) return [tabList?.[1]] ?? [];
    }

    return tabList;
  })();

  return (
    <>
      <div
        className={clsx('content-area', {
          [className]: className,
        })}
      >
        <ul
          className={clsx('tab-menu', {
            'tab-menu--disabled': disabled,
          })}
        >
          {TAB_LIST?.map((item, index) => (
            <li
              key={index}
              className={clsx('tab-menu__item', {
                'tab-menu__item--active': isActiveTab(index),
                'tab-menu__item--disabled': disabled && !isActiveTab(index),
              })}
            >
              <a href="#" onClick={e => handleClickTab(e, index, item?.onClickTab)}>
                {getTabItemName(index, item?.tabName)}
                {!isNil(item?.tabItemCount) && <span className="tab-menu__badge">{item?.tabItemCount}</span>}
              </a>
            </li>
          ))}
          <li className={clsx('tab-menu__item', { 'tab-menu__item--disabled': disabled })} />
        </ul>
      </div>
      <TabView isActiveTab={isActiveTab} tabViewList={TAB_VIEW_LIST} />
    </>
  );
}

export default Tab;
