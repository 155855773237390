import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import { TableBody, TableBorder, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import TdLink from 'components/stateless/Table/TdLink';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE, FINANCIAL_SETTING_TYPE, ROLE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { OverdueInterestRateSettingVOModel } from 'models/vo/OverdueInterestRateSettingVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierOverdueInterestRateSettingList } from 'utils/http/api/financier/overdue-interest-rate-settings';
import type { SearchFiOverdueInterestRateSettingRequest } from 'utils/http/api/financier/overdue-interest-rate-settings/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const FI_DELINQUENT_INTEREST_RATE_LIST_QS_KEY = 'fi-delinquent-interest-rate-list';

function FinancierDelinquentInterestRateSettingList() {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;

  const { pageable, setPageable } = usePageable(FI_DELINQUENT_INTEREST_RATE_LIST_QS_KEY);
  const [financierDelinquentInterestRateSettingPage, setFinancierDelinquentInterestRateSettingPage] =
    useState<Pageable<OverdueInterestRateSettingVOModel[]>>();

  const { register, reset, getValues, setValue } = useForm<SearchFiOverdueInterestRateSettingRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<any>(setValue, getParsedSearchParams(FI_DELINQUENT_INTEREST_RATE_LIST_QS_KEY).formSearchData);
      fetchFiOverdueInterestRateSettingList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  const TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Type'),
    },
    {
      headerText: t('text:Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Company_Name'),
    },
    {
      headerText: t('text:Company_Client_Code'),
    },
    {
      headerText: t('text:Activate_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  async function fetchFiOverdueInterestRateSettingList(
    pageNumber: number,
    rowCount: number,
    searchedData: SearchFiOverdueInterestRateSettingRequest,
  ) {
    try {
      const fetchedFinancierDelinquentInterestRateSettingPage = await requestFinancierOverdueInterestRateSettingList(
        pageNumber,
        rowCount,
        searchedData,
      );
      updateSearchParams(
        {
          ...searchedData,
          pageNumber,
          rowCount,
        },
        FI_DELINQUENT_INTEREST_RATE_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierDelinquentInterestRateSettingPage(fetchedFinancierDelinquentInterestRateSettingPage);
        setPageable(fetchedFinancierDelinquentInterestRateSettingPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async (e: any) => {
    e.preventDefault();

    await fetchFiOverdueInterestRateSettingList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    await fetchFiOverdueInterestRateSettingList(
      pageNumber,
      sizePerPage,
      getParsedSearchParams(FI_DELINQUENT_INTEREST_RATE_LIST_QS_KEY).formSearchData,
    );
  };

  const onRemoveFilter = (event: any) => {
    reset();
    event.preventDefault();
  };

  const renderResultTable = (): JSX.Element[] | JSX.Element | undefined => {
    return financierDelinquentInterestRateSettingPage?.content.length !== 0 ? (
      financierDelinquentInterestRateSettingPage?.content.map((item, index) => {
        return (
          <Tr key={index}>
            <Td data={t(`code:collateral-type.${item.collateralType}`)} />
            <Td data={t(`code:financier-setting-type.${item.settingType}`)} />
            <Td
              data={
                item.settingType === FINANCIAL_SETTING_TYPE.ANCHOR
                  ? item.anchorAgreementContractNo
                  : item.dealerAgreementContractNo
              }
            />
            <Td data={item.currencyType} />
            <Td
              data={item.settingType === FINANCIAL_SETTING_TYPE.ANCHOR ? item.anchorClientName : item.dealerClientName}
            />
            <Td
              data={item.settingType === FINANCIAL_SETTING_TYPE.ANCHOR ? item.anchorClientCode : item.dealerClientCode}
            />
            <Td
              className={item.activated ? 'text-bold-blue-main600' : 'text-bold-brick-red'}
              data={item.activated ? t('text:On') : t('text:Off')}
            />
            <TdLink path={ROUTES_FI.PROGRAM_SETTINGS.DELINQUENT_INTEREST_RATE_DETAIL_BUILD_PATH(item.id)} />
          </Tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={7} className="text-center">
          {t('text:no_data_available')}
        </td>
      </tr>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Delinquent_Interest_Rate')} />
      <GuideMessage
        message={[
          t('text:You_can_register_and_check_delinquent_interest_rate_on_this_page'),
          t('text:Delinquent_interest_rate_set_in_Default_will_be_applied_to_all_companies_by_default'),
          t(
            'text:Delinquent_interest_rate_set_By_Anchor_Master_Agreement_will_be_applied_to_all_Partners_associated_with_that_Anchor_Master_Agreement_and_this_takes_precedence_over_the_Default_setting',
          ),
          t(
            'text:Delinquent_interest_rate_set_By_Partner_Master_Agreement_will_be_applied_only_to_Partner_which_is_related_to_that_Partner_Master_Agreement_and_this_takes_precedence_over_the_setting_in_both_By_Anchor_Master_Agreement_and_Default',
          ),
        ]}
      >
        {{
          button: isAdmin ? (
            <Button to={ROUTES_FI.PROGRAM_SETTINGS.DELINQUENT_INTEREST_RATE_REGISTER}>
              {t('text:Register_Delinquent_Interest_Rate')}
            </Button>
          ) : null,
        }}
      </GuideMessage>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>

          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                name="collateralType"
                ref={register}
              />
              <SearchLabel label={t('text:Type')} />
              <SearchSelect
                selectOptions={getSelectOptions<FINANCIAL_SETTING_TYPE>('FINANCIAL_SETTING_TYPE', 'ALL', true)}
                name="settingType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Master_Agreement_Number')} />
              <SearchInput name="contractNo" ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Company_Name')} />
              <SearchInput name="financierClientName" ref={register} />
              <SearchLabel label={t('text:Company_Client_Code')} />
              <SearchInput name="financierClientCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Activate_Status')} />
              <SearchSelect
                selectOptions={[
                  { label: t('text:All'), value: '' },
                  { label: t('text:On'), value: true },
                  { label: t('text:Off'), value: false },
                ]}
                name="activated"
                ref={register}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {financierDelinquentInterestRateSettingPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={TABLE_HEADERS} />
          <TableBody numOfCol={TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierDelinquentInterestRateSettingList;
