import type Pageable from 'models/Pageable';
import type { FinancierClientHistoryVO, FinancierClientHistoryVOModel } from 'models/vo/FinancierClientHistoryVO';
import { formattingToFinancierClientHistoryVOModel } from 'models/vo/FinancierClientHistoryVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

export async function requestFinancierClientHistories(
  pageNumber: number,
  rowCount: number,
  targetFinancierClientId: number,
): Promise<Pageable<FinancierClientHistoryVOModel[]>> {
  const response = await http.get<Pageable<FinancierClientHistoryVO[]>>({
    url: API_FI.FINANCIER_CLIENT_HISTORIES.CLIENT_HISTORY_LIST,
    data: {
      pageNumber,
      rowCount,
      targetFinancierClientId,
    },
  });

  const fiClientHistoryList: FinancierClientHistoryVOModel[] = response.content.map((data: FinancierClientHistoryVO) =>
    formattingToFinancierClientHistoryVOModel(data),
  );

  const fiHistoryListPage: Pageable<FinancierClientHistoryVOModel[]> = {
    ...response,
    content: fiClientHistoryList,
  };

  return fiHistoryListPage;
}
