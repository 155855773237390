import { CURRENCY_TYPE, EXTENSION_REQUEST_STATUS, FINANCIER_TYPE, INTEREST_REPAYMENT_TYPE, stringToEnum } from 'enums';

export interface ExtensionRequestVO {
  extensionRequestId: number;
  extensionStartDate: string;
  extendedRepaymentDate: string;
  additionalRate: number;
  extensionReason: string;
  rejectReason: string;
  extensionRequestStatus: string;
  loanId: number;
  financierLoanId: string;
  currencyType: string;
  financierId: number;
  financierName: string;
  dealerClientId: number;
  dealerClientCode: string;
  dealerClientName: string;
  anchorClientId: number;
  anchorClientCode: string;
  anchorClientName: string;
  disbursedDate: string;
  repaidPrincipalAmount: number;
  interestRepaymentType: string;
  createdDateTime: string;
  disbursedAmount: number;
  disbursedBasisInterestRate: number;
  disbursedTotalInterestRateWithoutBasis: number;
  financierCode: string;
}

export interface ExtensionRequestVOModel extends ExtensionRequestVO {
  extensionRequestStatus: EXTENSION_REQUEST_STATUS;
  currencyType: CURRENCY_TYPE;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  financierCode: FINANCIER_TYPE;
}

export function formattingToExtensionRequestVOModel(data: ExtensionRequestVO): ExtensionRequestVOModel {
  return {
    ...data,
    extensionRequestStatus: stringToEnum(EXTENSION_REQUEST_STATUS, data.extensionRequestStatus),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    interestRepaymentType: stringToEnum(INTEREST_REPAYMENT_TYPE, data.interestRepaymentType),
    financierCode: stringToEnum(FINANCIER_TYPE, data.financierCode),
  };
}
