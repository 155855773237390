import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';

import useFinancierController from './useFinancierController';

const Financier = () => {
  const { t } = useTranslation();
  const { data } = useFinancierController();
  const { financierName, branchCode, branchName, branchTelephone, branchFax, branchAddress } = data;

  return (
    <>
      <SubTitle>{t('text:FINANCIER')}</SubTitle>
      <Content>
        <Row>
          <Form.Label>{t('text:Financier_Name')}</Form.Label>
          <Form.Value value={financierName} />
        </Row>
        <Row singleColumnPosition="all">
          <Cell>
            <Form.Label>{t('text:Responsible_Branch')}</Form.Label>
            <Form.Description>
              {t('text:This_branch_manges_the_Partner_Master_Agreement_and_associated_transactions')}
            </Form.Description>
          </Cell>
          <Border>
            <Content isInnerStyle={true}>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Branch_Code')}</Form.Label>
                  <Form.Value value={branchCode} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Branch_Name')}</Form.Label>
                  <Form.Value value={branchName} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Telephone')}</Form.Label>
                  <Form.Value value={branchTelephone} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Fax')}</Form.Label>
                  <Form.Value value={branchFax} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Registered_Office_Address')}</Form.Label>
                  <Form.Value value={branchAddress} />
                </Form.Control>
              </Row>
            </Content>
          </Border>
        </Row>
      </Content>
    </>
  );
};

export default Financier;
