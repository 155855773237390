import type Pageable from 'models/Pageable';
import type { WaitingBankCodeVO, WaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import { formattingToWaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SyWaitingBankCodeListRequest, SyWaitingBankCodeRegisterRequest } from './request';

export async function requestSyWaitingBankCodeList(
  pageNumber: number,
  rowCount: number,
  searchValue?: SyWaitingBankCodeListRequest,
): Promise<Pageable<WaitingBankCodeVOModel[]>> {
  const response = await http.get<Pageable<WaitingBankCodeVO[]>>({
    url: API_SY.WAITING_BANK_CODE.WAITING_BANK_CODE,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  const bankCodeList: WaitingBankCodeVOModel[] = response.content.map((data: WaitingBankCodeVO) =>
    formattingToWaitingBankCodeVOModel(data),
  );

  return {
    ...response,
    content: bankCodeList,
  };
}

export async function requestSyWaitingBankCodeDetail(waitingBankCodeId: number): Promise<WaitingBankCodeVOModel> {
  const response = await http.get<WaitingBankCodeVO>({
    url: API_SY.WAITING_BANK_CODE.WAITING_BANK_CODE_DETAIL(waitingBankCodeId),
  });

  return formattingToWaitingBankCodeVOModel(response);
}

// bankCode 등록
export async function requestSyWaitingBankCodeRegister(
  data: SyWaitingBankCodeRegisterRequest,
): Promise<WaitingBankCodeVOModel> {
  const response = await http.post<WaitingBankCodeVO>({
    url: API_SY.WAITING_BANK_CODE.WAITING_BANK_CODE,
    data,
  });

  return formattingToWaitingBankCodeVOModel(response);
}

export async function requestSyWaitingBankCodeReject(
  waitingBankCodeId: number,
  rejectReason?: string,
): Promise<WaitingBankCodeVOModel> {
  const response = await http.put<WaitingBankCodeVO>({
    url: API_SY.WAITING_BANK_CODE.REJECT_MODIFICATION(waitingBankCodeId),
    data: { rejectReason },
  });

  return formattingToWaitingBankCodeVOModel(response);
}

export async function requestSyWaitingBankCodeApprove(waitingBankCodeId: number): Promise<WaitingBankCodeVOModel> {
  const response = await http.put<WaitingBankCodeVO>({
    url: API_SY.WAITING_BANK_CODE.APPROVE_MODIFICATION(waitingBankCodeId),
  });

  return formattingToWaitingBankCodeVOModel(response);
}
