import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TdLink from 'components/stateless/Table/TdLink';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierAnchorUsers } from 'utils/http/api/financier/anchor-users';
import type { FinancierAnchorUsersRequest } from 'utils/http/api/financier/anchor-users/requests';
import { requestFinancierWaitingAnchorUserList } from 'utils/http/api/financier/waiting-anchor-users';
import type { FinancierWaitingAnchorUserListRequest } from 'utils/http/api/financier/waiting-anchor-users/request';
import { getWaitingForApprovalCount } from 'utils/logic';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES, setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const FI_ANCHOR_USER_WAITING_LIST_QS_KEY = 'fi-anchor-user-waiting-list';
const FI_ANCHOR_USER_COMPLETED_LIST_QS_KEY = 'fi-anchor-user-completed-list';

function FinancierManageAnchorUserList() {
  const { t } = useTranslation(['format']);
  const signInModel: SignInModel | null = getSignIn();
  const mounted = useMounted();
  const modal = useModal();

  const [anchorUserList, setAnchorUserList] = useState<Pageable<AnchorUserVOModel[]>>();
  const [waitingAnchorUserList, setWaitingAnchorUserList] = useState<Pageable<WaitingAnchorUserVOModel[]>>();
  const [notCancelledWaitingAnchorUserList, setNotCancelledWaitingAnchorUserList] =
    useState<Pageable<WaitingAnchorUserVOModel[]>>();

  const anchorUserPageable = usePageable(FI_ANCHOR_USER_COMPLETED_LIST_QS_KEY);
  const waitingAnchorUserPageable = usePageable(FI_ANCHOR_USER_WAITING_LIST_QS_KEY);

  const getAnchorUserProperty = useProperty<FinancierAnchorUsersRequest>();
  const getWaitingAnchorUserProperty = useProperty<FinancierWaitingAnchorUserListRequest>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const anchorUserSearchForm = useForm<FinancierAnchorUsersRequest>();
  const waitingAnchorUserSearchForm = useForm<FinancierWaitingAnchorUserListRequest>();

  async function fetchAll() {
    setFormValues<FinancierAnchorUsersRequest>(
      anchorUserSearchForm.setValue,
      getParsedSearchParams(FI_ANCHOR_USER_COMPLETED_LIST_QS_KEY).formSearchData,
    );
    setFormValues<FinancierWaitingAnchorUserListRequest>(
      waitingAnchorUserSearchForm.setValue,
      getParsedSearchParams(FI_ANCHOR_USER_WAITING_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
    const completeAnchorUserSearchData = anchorUserSearchForm.getValues();

    const addFiAnchorUserListQs = () => {
      const waitingQs = makeSearchParamsPattern(
        {
          ...waitingForApprovalSearchData,
          pageNumber: waitingAnchorUserPageable.pageable.currentPage,
          rowCount: waitingAnchorUserPageable.pageable.sizePerPage,
        },
        FI_ANCHOR_USER_WAITING_LIST_QS_KEY,
      );
      const completedQs = makeSearchParamsPattern(
        {
          ...completeAnchorUserSearchData,
          pageNumber: anchorUserPageable.pageable.currentPage,
          rowCount: anchorUserPageable.pageable.sizePerPage,
        },
        FI_ANCHOR_USER_COMPLETED_LIST_QS_KEY,
      );
      addSearchParams(waitingQs + completedQs);
    };
    addFiAnchorUserListQs();

    try {
      const [fetchAnchorUserList, fetchWaitingAnchorUserList, fetchNotCancelledWaitingAnchorUserList] =
        await Promise.all([
          requestFinancierAnchorUsers(
            anchorUserPageable.pageable.currentPage,
            anchorUserPageable.pageable.sizePerPage,
            completeAnchorUserSearchData,
          ),
          requestFinancierWaitingAnchorUserList(
            waitingAnchorUserPageable.pageable.currentPage,
            waitingAnchorUserPageable.pageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestFinancierWaitingAnchorUserList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorUserList(fetchAnchorUserList);
        anchorUserPageable.setPageable(fetchAnchorUserList);

        setWaitingAnchorUserList(fetchWaitingAnchorUserList);
        waitingAnchorUserPageable.setPageable(fetchWaitingAnchorUserList);

        setNotCancelledWaitingAnchorUserList(fetchNotCancelledWaitingAnchorUserList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const setWaitingTabSearchValue = (formSearchData?: FinancierWaitingAnchorUserListRequest) => {
    const getFormSearchData = formSearchData ? formSearchData : waitingAnchorUserSearchForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getFormSearchData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getFormSearchData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getFormSearchData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchAnchorUserList = async (
    pageNumber: number,
    rowCount: number,
    searchedData: FinancierAnchorUsersRequest,
  ) => {
    try {
      const anchorUserList = await requestFinancierAnchorUsers(pageNumber, rowCount, searchedData);

      updateSearchParams(
        {
          ...searchedData,
          pageNumber,
          rowCount,
        },
        FI_ANCHOR_USER_COMPLETED_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorUserList(anchorUserList);
        anchorUserPageable.setPageable(anchorUserList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onAnchorSearchFormSubmit = async () => {
    await fetchAnchorUserList(1, anchorUserPageable.pageable.sizePerPage, anchorUserSearchForm.getValues());
  };

  const anchorUserPaginate = async (pageNumber: number, rowCount: number) => {
    await fetchAnchorUserList(
      pageNumber,
      rowCount,
      getParsedSearchParams(FI_ANCHOR_USER_COMPLETED_LIST_QS_KEY).formSearchData,
    );
  };

  const paginateWaitingAnchorUserList = async (pageNumber: number, rowCount: number) => {
    const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
      getParsedSearchParams(FI_ANCHOR_USER_WAITING_LIST_QS_KEY).formSearchData,
    );
    try {
      const waitingAnchorUserList = await requestFinancierWaitingAnchorUserList(
        pageNumber,
        rowCount,
        waitingForApprovalSearchData,
      );
      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber,
          rowCount,
        },
        FI_ANCHOR_USER_WAITING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorUserList(waitingAnchorUserList);
        waitingAnchorUserPageable.setPageable(waitingAnchorUserList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const waitingAnchorUserPaginate = async (pageNumber: number, rowCount: number) => {
    await paginateWaitingAnchorUserList(pageNumber, rowCount);
  };

  const onAnchorUserFormRemoveFilter = (event: any) => {
    event.preventDefault();
    anchorUserSearchForm.reset({});
  };

  const onWaitingAnchorUserFormRemoveFilter = (event: any) => {
    event.preventDefault();
    waitingAnchorUserSearchForm.reset({});
  };

  const onWaitingAnchorSearchFormSubmit = async () => {
    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
    try {
      const [waitingAnchorUserList, notCancelledWaitingAnchorUserList] = await Promise.all([
        requestFinancierWaitingAnchorUserList(
          1,
          waitingAnchorUserPageable.pageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestFinancierWaitingAnchorUserList(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber: 1,
          rowCount: waitingAnchorUserPageable.pageable.sizePerPage,
        },
        FI_ANCHOR_USER_WAITING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorUserList(waitingAnchorUserList);
        waitingAnchorUserPageable.setPageable(waitingAnchorUserList);
        setNotCancelledWaitingAnchorUserList(notCancelledWaitingAnchorUserList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRegistrationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderAnchorUserFormAndList = (): JSX.Element => {
    const FI_ANCHOR_TABLE_HEADERS: HeaderType[] = [
      {
        headerText: t('text:Anchor_Client_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Anchor_Name'),
        colWidths: 170,
      },
      {
        headerText: t('text:User_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:User_Name'),
        colWidths: 170,
      },
      {
        headerText: t('text:Mobile'),
        colWidths: 170,
      },
      {
        headerText: t('text:Email'),
        colWidths: 170,
      },
      {
        headerText: t('text:Authority'),
        colWidths: 150,
      },
      {
        headerText: t('text:User_Account_Status'),
        colWidths: 170,
        hasStatusDescription: true,
        showStatusDescriptionFunc: onClickRegistrationStatus,
      },
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onAnchorUserFormRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Anchor_Client_Code')} />
                <SearchInput name={getAnchorUserProperty('anchorClientCode')} ref={anchorUserSearchForm.register} />
                <SearchLabel label={t('text:Anchor_Name')} />
                <SearchInput name={getAnchorUserProperty('anchorClientName')} ref={anchorUserSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:User_Code')} />
                <SearchInput name={getAnchorUserProperty('bankUserId')} ref={anchorUserSearchForm.register} />
                <SearchLabel label={t('text:User_Name')} />
                <SearchInput name={getAnchorUserProperty('name')} ref={anchorUserSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Mobile')} />
                <SearchInput name={getAnchorUserProperty('mobile')} ref={anchorUserSearchForm.register} />
                <SearchLabel label={t('text:Email')} />
                <SearchInput name={getAnchorUserProperty('email')} ref={anchorUserSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Authority')} />
                <SearchSelect
                  name={getAnchorUserProperty('authorityTypes')}
                  ref={anchorUserSearchForm.register}
                  selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', 'ALL', true)}
                />
                <SearchLabel
                  label={t('text:User_Account_Status')}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickRegistrationStatus}
                />
                <SearchSelect
                  name={getAnchorUserProperty('userStatus')}
                  ref={anchorUserSearchForm.register}
                  selectOptions={getSelectOptions<USER_STATUS>('USER_STATUS', 'ALL', true)}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={anchorUserSearchForm.handleSubmit(onAnchorSearchFormSubmit)}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} : {anchorUserPageable.pageable.totalRows}
          </p>
          <TableBorder>
            <TableHeader header={FI_ANCHOR_TABLE_HEADERS} />
            <TableBody numOfCol={FI_ANCHOR_TABLE_HEADERS.length}>
              {anchorUserList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.anchorClientCode} />
                    <Td data={item.anchorClientName} />
                    <Td data={item.bankUserId} />
                    <Td data={item.name} />
                    <Td data={item.mobile} />
                    <Td data={item.email} />
                    <Td data={t(`code:authority-type.${item.authorityType}`)} format="code" />
                    <Td
                      className={getStatusTextClass('USER_STATUS', item?.userStatus)}
                      data={t(`code:user-status.${item.userStatus}`)}
                      format="code"
                    />
                    <TdLink path={ROUTES_FI.MANAGE_ANCHOR.USER_REGISTERED_DETAIL_BUILD_PATH(item.id)} />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={anchorUserPageable.pageable} paginate={anchorUserPaginate} />
        </div>
      </>
    );
  };

  const renderWaitingUserFromAndList = (): JSX.Element => {
    const onClickConfirmationStatus = (): void => {
      modal.show(
        <StatusDescriptionModal
          statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
          statusDescriptionModalType="TEXT"
          statusDescriptionEnumType="USER_COMMON_APPROVAL_TYPE"
        />,
        {
          modalType: ModalType.ALERT,
          modalSize: ModalSize.XL,
          closeBtnText: t('text:Close'),
        },
      );
    };

    const FI_WAITING_ANCHOR_TABLE_HEADERS: HeaderType[] = [
      {
        headerText: t('text:Anchor_Client_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Anchor_Name'),
        colWidths: 170,
      },
      {
        headerText: t('text:User_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:User_Name'),
        colWidths: 170,
      },
      {
        headerText: t('text:Mobile'),
        colWidths: 170,
      },
      {
        headerText: t('text:Email'),
        colWidths: 170,
      },
      {
        headerText: t('text:Authority'),
        colWidths: 150,
      },
      {
        headerText: t('text:Approval_Status'),
        colWidths: 170,
        hasStatusDescription: true,
        showStatusDescriptionFunc: onClickConfirmationStatus,
      },
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onWaitingAnchorUserFormRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Anchor_Client_Code')} />
                <SearchInput
                  name={getWaitingAnchorUserProperty('anchorClientCode')}
                  ref={waitingAnchorUserSearchForm.register}
                />
                <SearchLabel label={t('text:Anchor_Name')} />
                <SearchInput
                  name={getWaitingAnchorUserProperty('anchorClientName')}
                  ref={waitingAnchorUserSearchForm.register}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:User_Code')} />
                <SearchInput
                  name={getWaitingAnchorUserProperty('bankUserId')}
                  ref={waitingAnchorUserSearchForm.register}
                />
                <SearchLabel label={t('text:User_Name')} />
                <SearchInput name={getWaitingAnchorUserProperty('name')} ref={waitingAnchorUserSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Mobile')} />
                <SearchInput name={getWaitingAnchorUserProperty('mobile')} ref={waitingAnchorUserSearchForm.register} />
                <SearchLabel label={t('text:Email')} />
                <SearchInput name={getWaitingAnchorUserProperty('email')} ref={waitingAnchorUserSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Authority')} />
                <SearchSelect
                  name={getWaitingAnchorUserProperty('authorityType')}
                  ref={waitingAnchorUserSearchForm.register}
                  selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', 'ALL', true)}
                />
                <SearchLabel
                  label={t('text:Approval_Status')}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickConfirmationStatus}
                />
                <SearchSelect
                  name={getWaitingAnchorUserProperty('approvalTypes')}
                  ref={waitingAnchorUserSearchForm.register}
                  selectOptions={[
                    {
                      label: t('text:All'),
                      value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                    },
                    {
                      label: t('text:Requested'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                    },
                    {
                      label: t('text:Reverted'),
                      value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    },
                    {
                      label: t('text:Cancelled'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                    },
                  ]}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button
                size={ButtonSizeEnum.LG}
                onClick={waitingAnchorUserSearchForm.handleSubmit(onWaitingAnchorSearchFormSubmit)}
              >
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>

        <div className="division-border" />

        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} : {waitingAnchorUserPageable.pageable.totalRows}
          </p>
          <TableBorder>
            <TableHeader header={FI_WAITING_ANCHOR_TABLE_HEADERS} />
            <TableBody numOfCol={FI_WAITING_ANCHOR_TABLE_HEADERS.length}>
              {waitingAnchorUserList?.content.map((item, i) => (
                <Tr key={i}>
                  <Td data={item.anchorClientCode} />
                  <Td data={item.anchorClientName} />
                  <Td data={item.bankUserId} />
                  <Td data={item.name} />
                  <Td data={item.mobile} />
                  <Td data={item.email} />
                  <Td data={t(`code:authority-type.${item.authorityType}`)} format="code" />
                  <Td
                    className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
                    data={t(`code:common-approval-type.${item.approvalType}`)}
                    format="code"
                  />
                  <TdLink path={ROUTES_FI.MANAGE_ANCHOR.USER_WAITING_DETAIL_BUILD_PATH(item.id)} />
                </Tr>
              ))}
            </TableBody>
          </TableBorder>

          <Pagination pageable={waitingAnchorUserPageable.pageable} paginate={waitingAnchorUserPaginate} />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Anchor_User')} />
      <GuideMessage
        message={[
          t('text:On_this_page_you_can_view_the_list_of_Anchor_users_registered_on_the_platform'),
          t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Anchor_users'),
        ]}
      >
        {{
          button:
            signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR ? (
              // 수기 상태에서 operator 만 register 할 수 있음
              <Button to={ROUTES_FI.MANAGE_ANCHOR.USER_REGISTER}>{t('text:Register')}</Button>
            ) : null,
        }}
      </GuideMessage>
      <Tab
        initialActiveTabIndex={1}
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              waitingAnchorUserSearchForm.getValues('approvalTypes'),
              notCancelledWaitingAnchorUserList?.totalElements,
            ),
          },
          {
            tabItemCount: anchorUserList?.totalElements ?? 0,
          },
        ]}
        tabViewList={[renderWaitingUserFromAndList(), renderAnchorUserFormAndList()]}
      />
    </>
  );
}

export default FinancierManageAnchorUserList;
