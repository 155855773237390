import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isEmpty, isEqualWith } from 'lodash-es';

import { ROUTES_FI } from 'constants/routes/financier';
import { COLLATERAL_TYPE, DEALER_IDENTIFIER_TYPE, LOAN_OPTION } from 'enums';
import { InvalidInputValueExceptionCode } from 'enums/exception';
import useBeforeUnload from 'hooks/useBeforeUnload';
import type { AnchorAgreementCommonVOModel } from 'models/convertor/anchorAgreement';
import { convertToAnchorAgreementResetData } from 'models/convertor/anchorAgreement';
import { HttpError } from 'utils/error/HttpError';
import { formErrorHandler } from 'utils/error/manager';
import { isNilOrEmptyString } from 'utils/helpers';
import { requestFiTempWaitingAnchorAgreementRegister } from 'utils/http/api/financier/temp-waiting-anchor-agreements';
import { requestRegisterWaitingAnchorAgreement } from 'utils/http/api/financier/waiting-anchor-agreements';
import type { CreateFiWaitingAnchorAgreementDTO } from 'utils/http/api/financier/waiting-anchor-agreements/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getObjectValue } from 'utils/object';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import useAgreementViewModel from '../models/agreement/useAgreementViewModel';
import useAnchorClientInfoViewModel from '../models/agreement/useAnchorClientInfoViewModel';
import useDivisionsViewModel from '../models/divisions/useDivisionsViewModel';
import useExtraInformationViewModel from '../models/extraInformation/useExtraInformationViewModel';

const useRegistrationController = () => {
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();
  const history = useHistory();

  const { supportedCollateralType, errorHandlerOfLocationState, useAgreementInterface } =
    useExtraInformationViewModel();
  const { agreement, isSearchedAgreement, fetchSavedAgreement, updateAllAgreement } = useAgreementViewModel();
  const { fetchDivisionRegistrable, updateDivisionName } = useDivisionsViewModel();
  const { anchorClientInfo } = useAnchorClientInfoViewModel();

  const canLeavePageRef = useRef(false);

  const [initialFormValues, setInitialFormValues] = useState<CreateFiWaitingAnchorAgreementDTO>(
    {} as CreateFiWaitingAnchorAgreementDTO,
  );

  const methods = useForm<CreateFiWaitingAnchorAgreementDTO>();
  const { getValues, reset, clearErrors, setError } = methods;

  const isFormChanged = !isEqualWith(initialFormValues, getValues(), (a, b) => {
    if ((typeof a === 'number' || typeof b === 'number') && a === b) return true;
    if (isNilOrEmptyString(a) && isNilOrEmptyString(b)) return true;
  });

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const ignoreNavigationGuard = useAgreementInterface || canLeavePageRef.current;

  const isEditableStyle = useAgreementInterface ? isSearchedAgreement : true;

  useBeforeUnload(isFormChanged, [initialFormValues, getValues()]);

  const getReadOnlyValue = useCallback(
    (parameterName?: string): boolean => {
      if (!useAgreementInterface) return false;

      const allAgreement = { ...agreement, ...anchorClientInfo };

      const agreementValue = getObjectValue({
        object: allAgreement,
        name: parameterName ?? '',
      });

      return !(isSearchedAgreement && isNilOrEmptyString(agreementValue));
    },
    [agreement, anchorClientInfo, isSearchedAgreement, useAgreementInterface],
  );

  const GuideMessageText: string[] = (() => {
    return useAgreementInterface
      ? [t('text:Click_on_the_Search_Agreement_button_to_view_the_agreement_information')]
      : [
          t('text:Please_enter_the_agreement_information_below'),
          t('text:Enter_the_anchor_or_responsible_branch_information_by_using_the_Search_buttons_below'),
          t('text:Enter_the_admin_information_by_using_the_Search_Anchor_User_button_below'),
        ];
  })();

  // Import Saved Data
  const updateAndResetSavedAgreement = async (savedAgreement: AnchorAgreementCommonVOModel) => {
    reset(convertToAnchorAgreementResetData(savedAgreement));
    updateAllAgreement(savedAgreement);
    // TODO: 개발하면서 divisionName이 별도의 상태로 존재할 필요가 없다고 생각되면 agreement로 통합
    updateDivisionName(savedAgreement.divisionName ?? '');
  };

  const showSavedAgreementModalIfAvailable = async () => {
    if (useAgreementInterface || errorHandlerOfLocationState()) return;

    setInitialFormValues(getValues());

    const savedAgreement = await fetchSavedAgreement(supportedCollateralType);

    if (!isEmpty(savedAgreement)) {
      showModal(
        <h6>
          {t('text:There_is_a_temporarily_saved_agreement')}
          <br />
          {t('text:Would_you_like_to_retrieve_the_saved_draft_and_continue_editing?')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: async () => {
            await updateAndResetSavedAgreement(savedAgreement);
            setInitialFormValues(getValues());
          },
        },
      );
    }
  };

  // Save Button
  const handleUpdateSaveAgreementError = (error: any) => {
    if (error instanceof HttpError && error.code === InvalidInputValueExceptionCode.INVALID_INPUT_VALUE) {
      return showModal(<h6>{t('text:Please_check_that_all_the_required_information_has_been_entered_correctly')}</h6>);
    }

    showModal(error);
  };

  const updateSaveAgreement = async () => {
    const data = getValues();

    const bankUserIdList = data.bankUserIdList as string;
    data.bankUserIdList = bankUserIdList ? bankUserIdList.split(',') : [];

    requestDTOParser(data);
    await requestFiTempWaitingAnchorAgreementRegister(data);
    clearErrors(); // 등록 시도 시 나타난 에러 표시 삭제
  };

  const showSaveCompletedModal = () => {
    canLeavePageRef.current = true;

    showModal(t('text:The_information_has_been_saved_successfully'), {
      closeBtnCb: () => history.push(ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_LIST),
    });
  };

  const handleSaveButtonClick = () => {
    if (isFormChanged) {
      showModal(
        <h6>
          {t('text:Would_you_like_to_save_the_agreement_information_you_entered?')}
          <br />
          {t(
            'text:Please_note_that_the_agreement_will_not_be_submitted_until_you_click_the_Registration_Request_button',
          )}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: async () => {
            try {
              await updateSaveAgreement();
              showSaveCompletedModal();
            } catch (e) {
              handleUpdateSaveAgreementError(e);
            }
          },
        },
      );
    } else {
      showModal(
        <h6>
          {t('text:There_are_no_modified_contents')}
          <br />
          {t('text:Please_make_a_change_to_save_the_agreement')}
        </h6>,
      );
    }
  };

  // Register Button
  const canRegister = () => {
    if (useAgreementInterface && !isSearchedAgreement) {
      showModal(<h6>{t('text:Click_the_Search_Agreement_button_to_retrieve_the_agreement_information')}</h6>);

      return false;
    }

    return true;
  };

  const handleARRequiredFieldsValidation = () => {
    const { paymentSupport, settlementAccount } = getValues();
    /**
     * AR에서 필수인 필드 (조건부 필수 필드 포함) 중,
     * BE에서 다른 필드보다 후순위에 에러 검증이 되어 에러 객체가 늦게 내려오는 필드. 사용자 입장에서 다른 필드와 동시에 에러가 발생할 수 있도록 개발
     */
    const arRequiredFields = [
      { name: 'paymentSupport', value: paymentSupport, isRequired: true },
      { name: 'settlementAccount', value: settlementAccount, isRequired: paymentSupport === 'true' },
    ];

    arRequiredFields.forEach(({ value, name, isRequired }) => {
      if (isRequired && isNilOrEmptyString(value)) {
        setError(name, {
          message: t('text:This_information_is_required'),
        });
      } else clearErrors(name);
    });
  };

  const updateAgreement = async () => {
    const data = getValues();

    const bankUserIdList = data.bankUserIdList as string;
    data.bankUserIdList = bankUserIdList ? bankUserIdList.split(',') : [];

    if (isAr) {
      data.loanOption = LOAN_OPTION.NONE;
      data.dealerIdentifierType = DEALER_IDENTIFIER_TYPE.TAX_CODE;
    }

    requestDTOParser(data);

    await requestRegisterWaitingAnchorAgreement(data);
  };

  const showRegistrationCompletedModal = () => {
    canLeavePageRef.current = true;

    showModal(
      <h6>
        {t('text:Request_for_approval_of_master_agreement_registration_has_been_completed')}
        <br />
        {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
      </h6>,
      {
        closeBtnCb: () => {
          history.push(ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_LIST);
        },
      },
    );
  };

  const handleRegisterButtonClick = () => {
    if (canRegister()) {
      showModal(
        <h6>
          {t('text:Would_you_like_to_request_approval_for_registration?')}
          <br />
          {t(
            'text:Please_note_that_if_modifications_occur_after_the_completion_of_the_request_the_approval_request_must_be_cancelled_and_processed_again',
          )}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: async () => {
            try {
              await updateAgreement();
              showRegistrationCompletedModal();
            } catch (error) {
              showModal(error);
              formErrorHandler<CreateFiWaitingAnchorAgreementDTO>(error, setError, clearErrors);
              if (isAr) handleARRequiredFieldsValidation();
            }
          },
        },
      );
    }
  };

  // Cancel Button
  const handleCancelButtonClick = (): void => {
    canLeavePageRef.current = true;

    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_LIST),
        closeBtnCb: () => (canLeavePageRef.current = false),
      },
    );
  };

  const resetPage = async () => {
    await fetchDivisionRegistrable();
    await showSavedAgreementModalIfAvailable();
  };

  useEffect(() => {
    resetPage();
  }, []);

  return {
    isInvoice,
    isEditableStyle,
    isRegistrationRequestButtonDisabled: !isEditableStyle,
    useAgreementInterface,
    ignoreNavigationGuard,
    supportedCollateralType,
    activatedNavigationGuard: isFormChanged,
    GuideMessageText,
    methods,
    getReadOnlyValue,
    handleSaveButtonClick,
    handleRegisterButtonClick,
    handleCancelButtonClick,
  };
};

export default useRegistrationController;
