import { Route, Switch } from 'react-router-dom';

import Navigation from 'components/stateless/Navigation';
import { ROUTES_SY } from 'constants/routes/system';
import NotFoundPage from 'pages/NotFoundPage';
import SystemBankCodeDetailConfirmed from 'pages/system/management/bank-code/confirmed-detail/SystemBankCodeConfirmedDetail';
import SystemBankCodeList from 'pages/system/management/bank-code/list/SystemBankCodeList';
import SystemBankCodeRegister from 'pages/system/management/bank-code/register/SystemBankCodeRegister';
import SystemBankCodeWaitingDetail from 'pages/system/management/bank-code/waiting-detail/SystemBankCodeWaitingDetail';
import SystemBilling from 'pages/system/management/billing/SystemBilling';
import SystemFinancierDetail from 'pages/system/management/financier/detail/SystemFinancierDetail';
import SystemFinancierList from 'pages/system/management/financier/list/SystemFinancierList';
import SystemFinancierRegister from 'pages/system/management/financier/register/SystemFinancierRegister';
import SystemFinancierAdminDetail from 'pages/system/management/financier-admin/detail/SystemFinancierAdminDetail';
import SystemFinancierAdminList from 'pages/system/management/financier-admin/list/SystemFinancierAdminList';
import SystemFinancierAdminRegister from 'pages/system/management/financier-admin/register/SystemFinancierAdminRegister';
import SystemTouListPage from 'pages/system/management/tou/list/SystemTOUListPage';
import SystemTransactionPerformanceListPage from 'pages/system/management/transaction-performance/list/SystemTransactionPerformanceListPage';
import SystemMonitorAnchorAgreementRegisteredDetail from 'pages/system/monitor-anchor/agreement/detail/SystemMonitorAnchorAgreementRegisteredDetail';
import SystemMonitorAnchorAgreementWaitingDetail from 'pages/system/monitor-anchor/agreement/detail/SystemMonitorAnchorAgreementWaitingDetail';
import SystemMonitorAnchorAgreementList from 'pages/system/monitor-anchor/agreement/list/SystemMonitorAnchorAgreementList';
import SystemMonitorAnchorCompanyDetail from 'pages/system/monitor-anchor/company/detail/SystemMonitorAnchorCompanyDetail';
import SystemMonitorAnchorCompanyList from 'pages/system/monitor-anchor/company/list/SystemMonitorAnchorCompanyList';
import SystemMonitorAnchorReportDetail from 'pages/system/monitor-anchor/report/detail/SystemMonitorAnchorReportDetail';
import SystemMonitorAnchorReportList from 'pages/system/monitor-anchor/report/list/SystemMonitorAnchorReportList';
import SystemMonitorAnchorUserRegisteredDetail from 'pages/system/monitor-anchor/user/detail/SystemMonitorAnchorUserRegisteredDetail';
import SystemMonitorAnchorUserWaitingDetail from 'pages/system/monitor-anchor/user/detail/SystemMonitorAnchorUserWaitingDetail';
import SystemMonitorAnchorUserList from 'pages/system/monitor-anchor/user/list/SystemMonitorAnchorUserList';
import SystemEarlyRepaymentDetail from 'pages/system/monitor-financing/early-repayment/detail/SystemEarlyRepaymentDetail';
import SystemEarlyRepaymentList from 'pages/system/monitor-financing/early-repayment/list/SystemEarlyRepaymentList';
import SystemExtensionDetail from 'pages/system/monitor-financing/extension/detail/SystemExtensionDetail';
import SystemExtensionList from 'pages/system/monitor-financing/extension/list/SystemExtensionList';
import SystemSecuredInventoryDetail from 'pages/system/monitor-financing/secured-inventory/detail/SystemSecuredInventoryDetail';
import SystemSecuredInventoryList from 'pages/system/monitor-financing/secured-inventory/list/SystemSecuredInventoryList';
import SystemMonitorPartnerAgreementList from 'pages/system/monitor-partner/agreement/list/SystemMonitorPartnerAgreementList';
import SystemMonitorPartnerAgreementRegisteredDetail from 'pages/system/monitor-partner/agreement/registered-detail/SystemMonitorPartnerAgreementRegisteredDetail';
import SystemMonitorPartnerAgreementWaitingDetail from 'pages/system/monitor-partner/agreement/waiting-detail/SystemMonitorPartnerAgreementWaitingDetail';
import SystemMonitorPartnerDetail from 'pages/system/monitor-partner/company/detail/SystemMonitorPartnerDetail';
import SystemMonitorPartnerList from 'pages/system/monitor-partner/company/list/SystemMonitorPartnerList';
import SystemMonitorPartnerReportDetail from 'pages/system/monitor-partner/report/detail/SystemMonitorPartnerReportDetail';
import SystemMonitorPartnerReportList from 'pages/system/monitor-partner/report/list/SystemMonitorPartnerReportList';
import SystemMonitorPlatformEnterpriseDetail from 'pages/system/monitor-platform/enterprise/detail/SystemMonitorPlatformEnterpriseDetail';
import SystemMonitorPlatformEnterpriseList from 'pages/system/monitor-platform/enterprise/list/SystemMonitorPlatformEnterpriseList';
import SystemLoginHistoryList from 'pages/system/monitor-platform/login-history/list/SystemLoginHistoryList';
import SystemMonitorPlatformUploadedPartnerDetail from 'pages/system/monitor-platform/uploaded-partner/detail/SystemMonitorPlatformUploadedPartnerDetail';
import SystemMonitorPlatformUploadedPartnerList from 'pages/system/monitor-platform/uploaded-partner/list/SystemMonitorPlatformUploadedPartnerList';
import SystemMonitorPlatformUserDetail from 'pages/system/monitor-platform/user/detail/SystemMonitorPlatformUserdetail';
import SystemMonitorPlatformUserList from 'pages/system/monitor-platform/user/list/SystemMonitorPlatformUserList';
import SystemMyAccount from 'pages/system/my-account/SystemMyAccount';
import SystemArConfirmationDetail from 'pages/system/register-ar/confirmation/detail/SystemArConfirmationDetail';
import SystemArConfirmationList from 'pages/system/register-ar/confirmation/list/SystemArConfirmationList';
import SystemInvoiceConfirmationDetail from 'pages/system/register-invoices/confirmation/detail/SystemInvoiceConfirmationDetail';
import SystemInvoiceConfirmationList from 'pages/system/register-invoices/confirmation/list/SystemInvoiceConfirmationList';
import SystemArDetail from 'pages/system/view-transactions/ar/detail/SystemArDetail';
import SystemArList from 'pages/system/view-transactions/ar/list/SystemArList';
import SystemFinancingDetail from 'pages/system/view-transactions/financing/detail/SystemFinancingDetail';
import SystemFinancingList from 'pages/system/view-transactions/financing/list/SystemFinancingList';
import SystemInvoiceDetail from 'pages/system/view-transactions/invoice/detail/SystemInvoiceDetail';
import SystemInvoiceList from 'pages/system/view-transactions/invoice/list/SystemInvoiceList';
import SystemInvoicePaymentList from 'pages/system/view-transactions/invoice-payment/list/SystemInvoicePaymentList';
import SystemOverviewByAnchorList from 'pages/system/view-transactions/overview-by-anchor/list/SystemOverviewByAnchorList';
import SystemOverviewBySCPartnerList from 'pages/system/view-transactions/overview-by-partner/SystemOverviewBySCPartnerList';

import SystemTouDetailPage from '../../pages/system/management/tou/detail/SystemTouDetailPage';
import SystemTouRegister from '../../pages/system/management/tou/register/SystemTOURegister';

function SystemAdminRouter() {
  return (
    <div className="content-wrapper">
      <Navigation />
      <div className="page-content">
        <Switch>
          {/* Management - financier Management : 은행 목록 */}
          <Route exact path={ROUTES_SY.MANAGEMENT.FINANCIER_LIST} component={SystemFinancierList} />

          {/* Management - financier Management : 은행 등록 */}
          <Route exact path={ROUTES_SY.MANAGEMENT.FINANCIER_REGISTER} component={SystemFinancierRegister} />

          {/* Management - financier Management : 은행 상세 */}
          <Route path={ROUTES_SY.MANAGEMENT.FINANCIER_DETAIL} component={SystemFinancierDetail} />

          {/* Management - financier Admin Management : 은행 admin 목록 */}
          <Route exact path={ROUTES_SY.MANAGEMENT.FINANCIER_ADMIN_LIST} component={SystemFinancierAdminList} />

          {/* Management - financier Admin Management : 은행 admin 등록 */}
          <Route exact path={ROUTES_SY.MANAGEMENT.FINANCIER_ADMIN_REGISTER} component={SystemFinancierAdminRegister} />

          {/* Management - financier Admin Management : 은행 admin 상세 */}
          <Route path={ROUTES_SY.MANAGEMENT.FINANCIER_ADMIN_DETAIL} component={SystemFinancierAdminDetail} />

          {/* Management - Bank Code : Confirmed List */}
          <Route exact path={ROUTES_SY.MANAGEMENT.BANK_CODE_LIST} component={SystemBankCodeList} />

          {/* Management - Bank Code : Register */}
          <Route exact path={ROUTES_SY.MANAGEMENT.BANK_CODE_REGISTER} component={SystemBankCodeRegister} />

          {/* Management - Bank Code : Detail Confirmed  */}
          <Route path={ROUTES_SY.MANAGEMENT.BANK_CODE_CONFIRMED_DETAIL} component={SystemBankCodeDetailConfirmed} />

          {/* Management - Bank Code : Detail Waiting  */}
          <Route path={ROUTES_SY.MANAGEMENT.BANK_CODE_WAITING_DETAIL} component={SystemBankCodeWaitingDetail} />

          {/* Management - Billing  */}
          <Route path={ROUTES_SY.MANAGEMENT.BILLING} component={SystemBilling} />

          {/* Management - Transaction Performance  */}
          <Route
            path={ROUTES_SY.MANAGEMENT.TRANSACTION_PERFORMANCE_LIST}
            component={SystemTransactionPerformanceListPage}
          />

          {/* Management - Transaction TOU  */}
          <Route exact path={ROUTES_SY.MANAGEMENT.TOU_LIST} component={SystemTouListPage} />
          <Route exact path={ROUTES_SY.MANAGEMENT.TOU_REGISTER} component={SystemTouRegister} />
          <Route exact path={ROUTES_SY.MANAGEMENT.TOU_DETAIL} component={SystemTouDetailPage} />

          {/* Monitor Platform - Enterprise : List */}
          <Route
            exact
            path={ROUTES_SY.MONITOR_PLATFORM.ENTERPRISE_LIST}
            component={SystemMonitorPlatformEnterpriseList}
          />

          {/* Monitor Platform - Enterprise : Detail */}
          <Route
            path={ROUTES_SY.MONITOR_PLATFORM.ENTERPRISE_DETAIL}
            component={SystemMonitorPlatformEnterpriseDetail}
          />

          {/* Monitor Platform - User : List */}
          <Route exact path={ROUTES_SY.MONITOR_PLATFORM.USER_LIST} component={SystemMonitorPlatformUserList} />

          {/* Monitor Platform - User : Detail */}
          <Route path={ROUTES_SY.MONITOR_PLATFORM.USER_DETAIL} component={SystemMonitorPlatformUserDetail} />

          {/* Monitor Platform - Uploaded Partner : List */}
          <Route
            exact
            path={ROUTES_SY.MONITOR_PLATFORM.UPLOADED_PARTNER_LIST}
            component={SystemMonitorPlatformUploadedPartnerList}
          />

          {/* Monitor Platform - Uploaded Partner : Detail */}
          <Route
            path={ROUTES_SY.MONITOR_PLATFORM.UPLOADED_PARTNER_DETAIL}
            component={SystemMonitorPlatformUploadedPartnerDetail}
          />

          {/* Monitor Platform - login-history : List */}
          <Route path={ROUTES_SY.MONITOR_PLATFORM.LOGIN_HISTORY_LIST} component={SystemLoginHistoryList} />

          {/* Monitor Anchor - Company : List */}
          <Route exact path={ROUTES_SY.MONITOR_ANCHOR.COMPANY_LIST} component={SystemMonitorAnchorCompanyList} />

          {/* Monitor Anchor - Company : Detail */}
          <Route path={ROUTES_SY.MONITOR_ANCHOR.COMPANY_DETAIL} component={SystemMonitorAnchorCompanyDetail} />

          {/* Monitor Anchor - Agreement : List */}
          <Route exact path={ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_LIST} component={SystemMonitorAnchorAgreementList} />

          {/* Monitor Anchor - Agreement : Waiting Detail */}
          <Route
            path={ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_WAITING_DETAIL}
            component={SystemMonitorAnchorAgreementWaitingDetail}
          />

          {/* Monitor Anchor - Agreement : Registered Detail */}
          <Route
            path={ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_REGISTERED_DETAIL}
            component={SystemMonitorAnchorAgreementRegisteredDetail}
          />

          {/* Monitor Anchor - User : List */}
          <Route exact path={ROUTES_SY.MONITOR_ANCHOR.USER_LIST} component={SystemMonitorAnchorUserList} />

          {/* Monitor Anchor - User : Detail(Registered) */}
          <Route
            path={ROUTES_SY.MONITOR_ANCHOR.USER_REGISTERED_DETAIL}
            component={SystemMonitorAnchorUserRegisteredDetail}
          />

          {/* Monitor Anchor - User : Detail(Waiting) */}
          <Route path={ROUTES_SY.MONITOR_ANCHOR.USER_WAITING_DETAIL} component={SystemMonitorAnchorUserWaitingDetail} />

          {/* Monitor Anchor - Report : List */}
          <Route exact path={ROUTES_SY.MONITOR_ANCHOR.REPORT_LIST} component={SystemMonitorAnchorReportList} />

          {/* Monitor Anchor - Report : Detail */}
          <Route path={ROUTES_SY.MONITOR_ANCHOR.REPORT_DETAIL} component={SystemMonitorAnchorReportDetail} />

          {/* 내 정보 관리 - 내 정보 페이지 */}
          <Route exact path={ROUTES_SY.MY_ACCOUNT} component={SystemMyAccount} />

          {/* Monitor Partner - Company : 파트너 기업 목록 */}
          <Route exact path={ROUTES_SY.MONITOR_PARTNER.COMPANY_LIST} component={SystemMonitorPartnerList} />

          {/* Monitor Partners - Company : 파트너 기업 상세 */}
          <Route path={ROUTES_SY.MONITOR_PARTNER.COMPANY_DETAIL} component={SystemMonitorPartnerDetail} />

          {/* Monitor Partner - Agreement : 파트너 약정 목록 */}
          <Route exact path={ROUTES_SY.MONITOR_PARTNER.AGREEMENT_LIST} component={SystemMonitorPartnerAgreementList} />

          {/* Monitor Partners - Agreement : 파트너 약정 상세 - 등록 완료 */}
          <Route
            path={ROUTES_SY.MONITOR_PARTNER.AGREEMENT_REGISTERED_DETAIL}
            component={SystemMonitorPartnerAgreementRegisteredDetail}
          />

          {/* Monitor Partners - Agreement : 파트너 약정 상세 - 등록 대기 */}
          <Route
            path={ROUTES_SY.MONITOR_PARTNER.AGREEMENT_WAITING_DETAIL}
            component={SystemMonitorPartnerAgreementWaitingDetail}
          />

          {/* Monitor Partner - Report : 파트너 리포트 목록 */}
          <Route exact path={ROUTES_SY.MONITOR_PARTNER.REPORT_LIST} component={SystemMonitorPartnerReportList} />

          {/* Monitor Partners - Report : 파트너 리포트 상세 */}
          <Route path={ROUTES_SY.MONITOR_PARTNER.REPORT_DETAIL} component={SystemMonitorPartnerReportDetail} />

          {/* Monitor Financing - Early Repayment : 조기 상환 신청 목록 */}
          <Route exact path={ROUTES_SY.MONITOR_FINANCING.EARLY_REPAYMENT_LIST} component={SystemEarlyRepaymentList} />

          {/* Monitor Financing - Early Repayment : 조기 상환 신청 상세 */}
          <Route path={ROUTES_SY.MONITOR_FINANCING.EARLY_REPAYMENT_DETAIL} component={SystemEarlyRepaymentDetail} />

          {/* Monitor Financing - Extension - 만기 연장 신청 목록 */}
          <Route exact path={ROUTES_SY.MONITOR_FINANCING.EXTENSION_LIST} component={SystemExtensionList} />

          {/* Monitor Financing - Extension - 만기 연장 신청 상세 */}
          <Route path={ROUTES_SY.MONITOR_FINANCING.EXTENSION_DETAIL} component={SystemExtensionDetail} />

          {/* Monitor Financing - Extension - 재고 담보 목록 */}
          <Route
            exact
            path={ROUTES_SY.MONITOR_FINANCING.SECURED_INVENTORY_LIST}
            component={SystemSecuredInventoryList}
          />

          {/* Monitor Financing - Extension - 재고 담보 상세 */}
          <Route path={ROUTES_SY.MONITOR_FINANCING.SECURED_INVENTORY_DETAIL} component={SystemSecuredInventoryDetail} />

          {/* View Transactions - Invoice : 송장 목록 */}
          <Route exact path={ROUTES_SY.VIEW_TRANSACTION.INVOICE_LIST} component={SystemInvoiceList} />

          {/* View Transactions - Invoice : 송장 상세 */}
          <Route path={ROUTES_SY.VIEW_TRANSACTION.INVOICE_DETAIL} component={SystemInvoiceDetail} />

          {/* View Transactions - Invoice Payment - BOE 단위 송장 목록 */}
          <Route exact path={ROUTES_SY.VIEW_TRANSACTION.INVOICE_PAYMENT_LIST} component={SystemInvoicePaymentList} />

          {/* View Transactions - Invoice : 대출 목록 */}
          <Route exact path={ROUTES_SY.VIEW_TRANSACTION.FINANCING_LIST} component={SystemFinancingList} />

          {/* View Transactions - Invoice : 대출 상세 */}
          <Route path={ROUTES_SY.VIEW_TRANSACTION.FINANCING_DETAIL} component={SystemFinancingDetail} />

          <Route exact path={ROUTES_SY.VIEW_TRANSACTION.AR_LIST} component={SystemArList} />

          <Route path={ROUTES_SY.VIEW_TRANSACTION.AR_DETAIL} component={SystemArDetail} />

          {/* View Transactions - Transactions by Anchor : 앵커 트랜잭션 */}
          <Route
            exact
            path={ROUTES_SY.VIEW_TRANSACTION.OVERVIEW_BY_ANCHOR_LIST}
            component={SystemOverviewByAnchorList}
          />

          {/* View Transactions - Transactions by SC Partners : SC Partners 트랜잭션 */}
          <Route
            exact
            path={ROUTES_SY.VIEW_TRANSACTION.OVERVIEW_BY_PARTNER_LIST}
            component={SystemOverviewBySCPartnerList}
          />

          {/* Register AR - Confirmation List : 미승인된 (등록 중인) 매출채권 목록 조회  */}
          <Route exact path={ROUTES_SY.REGISTER_AR.CONFIRMATION_LIST} component={SystemArConfirmationList} />

          {/* Register AR - Confirmation List : 미승인된 (등록 중인) 매출채권 상세 조회  */}
          <Route exact path={ROUTES_SY.REGISTER_AR.CONFIRMATION_DETAIL} component={SystemArConfirmationDetail} />

          {/* Register Invoice - Confirmation List : 미승인된 (등록 중인) 송장 조회  */}
          <Route exact path={ROUTES_SY.REGISTER_INVOICE.CONFIRMATION_LIST} component={SystemInvoiceConfirmationList} />

          {/* Register Invoice - Confirmation List : 미승인된 (등록 중인) 송장 상세  */}
          <Route
            exact
            path={ROUTES_SY.REGISTER_INVOICE.CONFIRMATION_DETAIL}
            component={SystemInvoiceConfirmationDetail}
          />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
}

export default SystemAdminRouter;
