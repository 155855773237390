import type React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';

import { useDealerFinancingDetailArInformationLogics } from './logics';

interface DealerFinancingDetailArInformationProps {
  arData: SuccessArDetailVOModel;
  loanData: LoanDetailVOModel;
  isAuthorizerRequest: boolean;
  factoringEnable: boolean;
  collateralTypeInfoToggleState: {
    collateralTypeInfoToggle: boolean;
    setCollateralTypeInfoToggle: React.Dispatch<React.SetStateAction<boolean>>;
  };
}
function DealerFinancingDetailArInformation({
  arData,
  loanData,
  isAuthorizerRequest,
  factoringEnable,
  collateralTypeInfoToggleState,
}: DealerFinancingDetailArInformationProps) {
  const { t } = useTranslation(['format']);

  const { onClickDownloadScannedAr } = useDealerFinancingDetailArInformationLogics(loanData.loanId);

  const showCheckbox = isAuthorizerRequest && !loanData.multipleRequested;

  return (
    <div className="content-area">
      <SectionTitle title={t('text:AR_Information')} />
      <FormBorder hideBorderBottom={showCheckbox}>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:AR_Number')}
              value={arData.arNumber}
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:AR_Issued_Date')}
              value={arData.arIssuedDate}
              format="date"
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:AR_Amount')}
              value={arData.arAmount}
              format="number"
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Settlement_Date')}
              value={arData.settlementDate}
              format="date"
            />
          </div>
          <div className="row">
            <FormValue col={3} label={t('text:Anchor_Name')} value={arData.anchorName} />
            <FormValue col={3} label={t('text:Partner_Name')} value={arData.anchorPartnerName} />
            <FormValue col={3} label={t('text:Currency')} value={arData.currencyType} />
          </div>
          {factoringEnable && (
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Remaining_AR_Balance')}
                value={arData.remainingCollateralAmount}
                format="number"
              />
              <FormValue
                col={3}
                label={t('text:Final_Disbursement_Amount')}
                value={arData.remainingCollateralAmount - arData.arCommissionAmount}
                format="number"
              />
            </div>
          )}
        </FormContents>
        <FormSubtitle title={t('text:Scanned_Tax_Invoice_File')} />
        <div className="detail-in-file-download-form">
          <div className="d-flex justify-content-between">
            <label className="information-form__label">{t('text:Attached_File')}</label>
            <input
              type="text"
              placeholder={loanData.invoiceAttachmentName ? loanData.invoiceAttachmentName : t('text:no_file_attached')}
              className="upload-file-input"
              readOnly
            />
            <DownloadButton onClick={onClickDownloadScannedAr} disabled={!loanData.invoiceAttachmentId} />
          </div>
        </div>
      </FormBorder>
      {showCheckbox && (
        <ConfirmCheckBox
          checked={collateralTypeInfoToggleState.collateralTypeInfoToggle}
          id="ar-info-check"
          labelText={t('text:I_have_checked_the_AR_information_is_correct')}
          onChangeCheckBox={() => {
            collateralTypeInfoToggleState.setCollateralTypeInfoToggle(prevState => !prevState);
          }}
        />
      )}
    </div>
  );
}

export default DealerFinancingDetailArInformation;
