import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import { ROUTES_PP } from 'constants/routes/potentialPartner';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { SuccessArGroupAnchorPartnerAccountVOModel } from 'models/vo/SuccessArGroupAnchorPartnerAccountVO';

import PotentialPartnerArDetailTable from './sections/ar-detail-list-table/PotentialPartnerArDetailTable';
import PotentialPartnerArDetailBanner from './sections/banner/PotentialPartnerArDetailBanner';
import { usePotentialPartnerArDetailState } from './usePotentialPartnerArDetailState';
import PPSection from '../../../components/potential-partner/PPSection/PPSection';
import PageTitle from '../../../components/potential-partner/PageTitle/PageTitle';

function PotentialPartnerArDetailPage() {
  const { anchorPartnerAccountId } = useParams() as any;
  const history = useHistory();

  const mounted = useMounted();
  const { t } = useTranslation(['format']);

  const { potentialPartnerFinancingApplicationStatus, potentialPartnerFinancingApplicationId } = useLocation<{
    potentialPartnerFinancingApplicationStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
    potentialPartnerFinancingApplicationId: number;
  }>().state;

  const { fetchAll, state } = usePotentialPartnerArDetailState(anchorPartnerAccountId);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  const stateToSendApplicationPage: SuccessArGroupAnchorPartnerAccountVOModel = state.arGroupAnchorPartnerAccount;

  return (
    <div className="pp-ar-detail-page">
      <PPSection className="bg-warm-gray-30" paddingType="md">
        <PageTitle title={t('text:View_AR(s)_Details')} backButtonColor={Button2ColorEnum.TERTIARY_REVERSE} />
        <PotentialPartnerArDetailBanner data={state.arGroupAnchorPartnerAccount} />
      </PPSection>
      <PPSection className="pp-ar-lists">
        <PotentialPartnerArDetailTable data={state.arList} arSummaryInfo={{ ...state.arGroupAnchorPartnerAccount }} />
        <div className="pp-ar-details-bottom-btn-wrapper">
          {potentialPartnerFinancingApplicationStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED ||
          potentialPartnerFinancingApplicationStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS ? (
            <Button2
              size={Button2SizeEnum.LG}
              onClick={() =>
                history.push(ROUTES_PP.MY_INFO.SUBMISSION_DETAIL_BUILD_PATH(potentialPartnerFinancingApplicationId))
              }
              icon={<FontAwesomeIcon icon={faArrowRight} fontSize={22} />}
            >
              {t('text:Monitor_Progress')}
            </Button2>
          ) : (
            <>
              <div className="pp-ar-lists__bott-msg">
                {t(
                  'text:To_submit_an_application_for_the_vendor_financing_program_please_access_this_page_from_a_desktop',
                )}
              </div>
              <div className="hide-on-mobile">
                <Button2
                  size={Button2SizeEnum.LG}
                  onClick={() =>
                    history.push(ROUTES_PP.APPLICATION.STEP, {
                      anchorClientName: stateToSendApplicationPage.anchorClientName,
                      financierName: stateToSendApplicationPage.financierName,
                      financierId: stateToSendApplicationPage.financierId,
                      anchorPartnerId: stateToSendApplicationPage.anchorPartnerId,
                      anchorPartnerAccountId: stateToSendApplicationPage.anchorPartnerAccountId,
                      currencyType: stateToSendApplicationPage.currencyType,
                    })
                  }
                  icon={<FontAwesomeIcon icon={faArrowRight} fontSize={22} />}
                >
                  {t('text:Submit_Application')}
                </Button2>
              </div>
            </>
          )}
        </div>
      </PPSection>
    </div>
  );
}

export default PotentialPartnerArDetailPage;
