import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { AUTHORITY_TYPE } from 'enums';
import { COMMON_APPROVAL_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemAnchorUserList } from 'utils/http/api/system/anchor-users';
import type { SystemAnchorUserListRequest } from 'utils/http/api/system/anchor-users/requests';
import { requestSystemWaitingAnchorUserList } from 'utils/http/api/system/waiting-anchor-users';
import type { SystemWaitingAnchorUserListRequest } from 'utils/http/api/system/waiting-anchor-users/requests';
import { getWaitingForApprovalCount } from 'utils/logic';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES, setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const onClickConfirmationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_COMMON_APPROVAL_TYPE"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickUserAccountStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_USER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:User_Name'),
    },
    {
      headerText: t('text:User_Code'),
    },
    {
      headerText: t('text:Authority'),
    },
    {
      headerText: t('text:Approval_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickConfirmationStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SEARCHED_REGISTRATION_COMPLETED_ANCHOR_USER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:User_Name'),
    },
    {
      headerText: t('text:User_Code'),
    },
    {
      headerText: t('text:Authority'),
    },
    {
      headerText: t('text:User_Account_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickUserAccountStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_ANCHOR_USER_LIST_QS_KEY = 'sy-anchor-user-list';
  const SY_WAITING_ANCHOR_USER_LIST_QS_KEY = 'sy-waiting-anchor-user-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_USER_TABLE_HEADERS,
    SEARCHED_REGISTRATION_COMPLETED_ANCHOR_USER_TABLE_HEADERS,
    SY_ANCHOR_USER_LIST_QS_KEY,
    SY_WAITING_ANCHOR_USER_LIST_QS_KEY,
    onClickConfirmationStatus,
    onClickUserAccountStatus,
  };
};

function SystemMonitorAnchorUserList(): JSX.Element {
  const {
    mounted,
    modal,
    t,
    SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_USER_TABLE_HEADERS,
    SEARCHED_REGISTRATION_COMPLETED_ANCHOR_USER_TABLE_HEADERS,
    SY_ANCHOR_USER_LIST_QS_KEY,
    SY_WAITING_ANCHOR_USER_LIST_QS_KEY,
    onClickConfirmationStatus,
    onClickUserAccountStatus,
  } = getConstants();

  const [waitingForApprovalAnchorUserPage, setWaitingForApprovalAnchorUserPage] =
    useState<Pageable<WaitingAnchorUserVOModel[]>>();
  const [notCancelledWaitingForApprovalAnchorUserPage, setNotCancelledWaitingForApprovalAnchorUserPage] =
    useState<Pageable<WaitingAnchorUserVOModel[]>>();
  const [registrationCompletedAnchorUserPage, setRegistrationCompletedAnchorUserPage] =
    useState<Pageable<AnchorUserVOModel[]>>();

  const {
    register: waitingForApprovalAnchorUserSearchFormRegister,
    reset: resetWaitingForApprovalAnchorUserSearchForm,
    getValues: getWaitingForApprovalAnchorUserSearchFormValues,
    setValue: setWaitingForApprovalAnchorUserSearchFormValue,
  } = useForm<SystemWaitingAnchorUserListRequest>();
  const {
    register: registrationCompletedAnchorUserSearchFormRegister,
    reset: resetRegistrationCompletedAnchorUserSearchForm,
    getValues: getRegistrationCompletedAnchorUserSearchFormValues,
    setValue: setRegistrationCompletedAnchorUserSearchFormValue,
  } = useForm<SystemAnchorUserListRequest>();

  const { pageable: waitingForApprovalAnchorUserPageable, setPageable: setWaitingForApprovalAnchorUserPageable } =
    usePageable(SY_WAITING_ANCHOR_USER_LIST_QS_KEY);
  const { pageable: registrationCompletedAnchorUserPageable, setPageable: setRegistrationCompletedAnchorUserPageable } =
    usePageable(SY_ANCHOR_USER_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const setWaitingTabSearchValue = (formSearchData?: SystemWaitingAnchorUserListRequest) => {
    const getSearchFormData = formSearchData ? formSearchData : getWaitingForApprovalAnchorUserSearchFormValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchAll = async (): Promise<void> => {
    // completed
    setFormValues<SystemAnchorUserListRequest>(
      setRegistrationCompletedAnchorUserSearchFormValue,
      getParsedSearchParams(SY_ANCHOR_USER_LIST_QS_KEY).formSearchData,
    );
    // waiting
    setFormValues<SystemWaitingAnchorUserListRequest>(
      setWaitingForApprovalAnchorUserSearchFormValue,
      getParsedSearchParams(SY_WAITING_ANCHOR_USER_LIST_QS_KEY).formSearchData,
    );

    const registrationCompletedData = getRegistrationCompletedAnchorUserSearchFormValues();

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    const waitingQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingForApprovalAnchorUserPageable.currentPage,
        rowCount: waitingForApprovalAnchorUserPageable.sizePerPage,
      },
      SY_WAITING_ANCHOR_USER_LIST_QS_KEY,
    );
    const completedQs = makeSearchParamsPattern(
      {
        ...registrationCompletedData,
        pageNumber: registrationCompletedAnchorUserPageable.currentPage,
        rowCount: registrationCompletedAnchorUserPageable.sizePerPage,
      },
      SY_ANCHOR_USER_LIST_QS_KEY,
    );
    addSearchParams(waitingQs + completedQs);

    try {
      const [
        waitingForApprovalAnchorUserPage,
        notCancelledWaitingForApprovalAnchorUserPage,
        registrationCompletedAnchorUserPage,
      ] = await Promise.all([
        requestSystemWaitingAnchorUserList(
          waitingForApprovalAnchorUserPageable.currentPage,
          waitingForApprovalAnchorUserPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestSystemWaitingAnchorUserList(0, 1, notCancelledWaitingForApprovalSearchData),
        requestSystemAnchorUserList(
          registrationCompletedAnchorUserPageable.currentPage,
          registrationCompletedAnchorUserPageable.sizePerPage,
          registrationCompletedData,
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingForApprovalAnchorUserPage(waitingForApprovalAnchorUserPage);
        setNotCancelledWaitingForApprovalAnchorUserPage(notCancelledWaitingForApprovalAnchorUserPage);
        setRegistrationCompletedAnchorUserPage(registrationCompletedAnchorUserPage);
        setWaitingForApprovalAnchorUserPageable(waitingForApprovalAnchorUserPage);
        setRegistrationCompletedAnchorUserPageable(registrationCompletedAnchorUserPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderWaitingForApprovalTabView = (): JSX.Element => {
    const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();

      resetWaitingForApprovalAnchorUserSearchForm();
    };

    const onClickSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
      e.preventDefault();

      try {
        const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

        const [waitingAnchorUserPage, notCancelledWaitingAnchorUserPage] = await Promise.all([
          await requestSystemWaitingAnchorUserList(
            1,
            waitingForApprovalAnchorUserPageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          await requestSystemWaitingAnchorUserList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: 1,
            rowCount: waitingForApprovalAnchorUserPageable.sizePerPage,
          },
          SY_WAITING_ANCHOR_USER_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingForApprovalAnchorUserPage(waitingAnchorUserPage);
          setNotCancelledWaitingForApprovalAnchorUserPage(notCancelledWaitingAnchorUserPage);
          setWaitingForApprovalAnchorUserPageable(waitingAnchorUserPage);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const waitingForApprovalAnchorUserPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
      try {
        const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
          getParsedSearchParams(SY_WAITING_ANCHOR_USER_LIST_QS_KEY).formSearchData,
        );

        const waitingAnchorAgreementPage = await requestSystemWaitingAnchorUserList(
          pageNumber,
          rowCount,
          waitingForApprovalSearchData,
        );

        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber,
            rowCount,
          },
          SY_WAITING_ANCHOR_USER_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingForApprovalAnchorUserPage(waitingAnchorAgreementPage);
          setWaitingForApprovalAnchorUserPageable(waitingAnchorAgreementPage);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const renderSearchedWaitingForApprovalAnchorUserTableBody = (): JSX.Element[] | undefined => {
      const getApprovalTypeText = (approvalType: COMMON_APPROVAL_TYPE): string => {
        switch (approvalType) {
          case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
            return t('text:Requested');
          case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
            return t('text:Request_Cancelled');
          case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
            return t('text:Request_Reverted');
          case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
            return t('text:Registered');
          default:
            return '';
        }
      };

      return waitingForApprovalAnchorUserPage?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.financierName} />
          <Td data={item.anchorClientName} />
          <Td data={item.name} />
          <Td data={item.bankUserId} />
          <Td data={t(`code:authority-type.${item.authorityType}`)} format="code" />
          <Td
            data={getApprovalTypeText(item.approvalType)}
            className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
          />
          <TdLink path={ROUTES_SY.MONITOR_ANCHOR.USER_WAITING_DETAIL_BUILD_PATH(item.id)} />
        </Tr>
      ));
    };

    return (
      <>
        <div className="content-area">
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <form>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Financier_Name')} />
                <SearchInput name="financierName" ref={waitingForApprovalAnchorUserSearchFormRegister} />
                <SearchLabel label={t('text:Anchor_Name')} />
                <SearchInput name="anchorClientName" ref={waitingForApprovalAnchorUserSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:User_Name')} />
                <SearchInput name="name" ref={waitingForApprovalAnchorUserSearchFormRegister} />
                <SearchLabel label={t('text:User_Code')} />
                <SearchInput name="bankUserId" ref={waitingForApprovalAnchorUserSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Authority')} />
                <SearchSelect
                  name="authorityType"
                  ref={waitingForApprovalAnchorUserSearchFormRegister}
                  selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', 'ALL', true)}
                />
                <SearchLabel
                  label={t('text:Approval_Status')}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickConfirmationStatus}
                />
                <SearchSelect
                  name="approvalTypes"
                  ref={waitingForApprovalAnchorUserSearchFormRegister}
                  selectOptions={[
                    {
                      label: t('text:All'),
                      value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                    },
                    {
                      label: t('text:Requested'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                    },
                    {
                      label: t('text:Reverted'),
                      value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    },
                    {
                      label: t('text:Cancelled'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                    },
                  ]}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} : {waitingForApprovalAnchorUserPageable.totalRows}
          </p>
          <TableBorder>
            <TableHeader header={SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_USER_TABLE_HEADERS} />
            <TableBody numOfCol={SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_USER_TABLE_HEADERS.length}>
              {renderSearchedWaitingForApprovalAnchorUserTableBody()}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingForApprovalAnchorUserPageable} paginate={waitingForApprovalAnchorUserPaginate} />
        </div>
      </>
    );
  };

  const renderRegistrationCompletedTabView = (): JSX.Element => {
    const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();

      resetRegistrationCompletedAnchorUserSearchForm();
    };

    const onClickSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
      e.preventDefault();

      try {
        const data = getRegistrationCompletedAnchorUserSearchFormValues();

        const registrationCompletedAnchorUserPage = await requestSystemAnchorUserList(
          1,
          registrationCompletedAnchorUserPageable.sizePerPage,
          data,
        );
        updateSearchParams(
          {
            ...data,
            pageNumber: 1,
            rowCount: registrationCompletedAnchorUserPageable.sizePerPage,
          },
          SY_ANCHOR_USER_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setRegistrationCompletedAnchorUserPage(registrationCompletedAnchorUserPage);
          setRegistrationCompletedAnchorUserPageable(registrationCompletedAnchorUserPage);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const registrationCompletedAnchorUserPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
      try {
        const registrationCompletedAnchorUserPage = await requestSystemAnchorUserList(
          pageNumber,
          rowCount,
          getParsedSearchParams(SY_ANCHOR_USER_LIST_QS_KEY).formSearchData,
        );
        updateSearchParams(
          {
            ...getParsedSearchParams(SY_ANCHOR_USER_LIST_QS_KEY).formSearchData,
            pageNumber,
            rowCount,
          },
          SY_ANCHOR_USER_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setRegistrationCompletedAnchorUserPage(registrationCompletedAnchorUserPage);
          setRegistrationCompletedAnchorUserPageable(registrationCompletedAnchorUserPage);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const renderSearchedRegistrationCompletedAnchorUserTableBody = (): JSX.Element[] | undefined => {
      return registrationCompletedAnchorUserPage?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.financierName} />
          <Td data={item.anchorClientName} />
          <Td data={item.name} />
          <Td data={item.bankUserId} />
          <Td data={t(`code:authority-type.${item.authorityType}`)} format="code" />
          <Td
            data={item.userStatus ? t(`code:user-status.${item.userStatus}`) : undefined}
            className={getStatusTextClass('USER_STATUS', item.userStatus)}
          />
          <TdLink path={ROUTES_SY.MONITOR_ANCHOR.USER_REGISTERED_DETAIL_BUILD_PATH(item.id)} />
        </Tr>
      ));
    };

    return (
      <>
        <div className="content-area">
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <form>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Financier_Name')} />
                <SearchInput name="financierName" ref={registrationCompletedAnchorUserSearchFormRegister} />
                <SearchLabel label={t('text:Anchor_Name')} />
                <SearchInput name="anchorClientName" ref={registrationCompletedAnchorUserSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:User_Name')} />
                <SearchInput name="name" ref={registrationCompletedAnchorUserSearchFormRegister} />
                <SearchLabel label={t('text:User_Code')} />
                <SearchInput name="bankUserId" ref={registrationCompletedAnchorUserSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Authority')} />
                <SearchSelect
                  name="authorityTypes"
                  ref={registrationCompletedAnchorUserSearchFormRegister}
                  selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', 'ALL', true)}
                />
                <SearchLabel
                  label={t('text:User_Account_Status')}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickUserAccountStatus}
                />
                <SearchSelect
                  name="userStatus"
                  ref={registrationCompletedAnchorUserSearchFormRegister}
                  selectOptions={getSelectOptions<USER_STATUS>('USER_STATUS', 'ALL', true)}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} : {registrationCompletedAnchorUserPageable.totalRows}
          </p>
          <TableBorder>
            <TableHeader header={SEARCHED_REGISTRATION_COMPLETED_ANCHOR_USER_TABLE_HEADERS} />
            <TableBody numOfCol={SEARCHED_REGISTRATION_COMPLETED_ANCHOR_USER_TABLE_HEADERS.length}>
              {renderSearchedRegistrationCompletedAnchorUserTableBody()}
            </TableBody>
          </TableBorder>
          <Pagination
            pageable={registrationCompletedAnchorUserPageable}
            paginate={registrationCompletedAnchorUserPaginate}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Anchor_User')} />
      <Tab
        initialActiveTabIndex={1}
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              getWaitingForApprovalAnchorUserSearchFormValues('approvalTypes'),
              notCancelledWaitingForApprovalAnchorUserPage?.totalElements,
            ),
          },
          {
            tabItemCount: registrationCompletedAnchorUserPageable.totalRows ?? 0,
          },
        ]}
        tabViewList={[renderWaitingForApprovalTabView(), renderRegistrationCompletedTabView()]}
      />
    </>
  );
}

export default SystemMonitorAnchorUserList;
