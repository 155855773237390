import { FINANCIER_CLIENT_STATUS, FINANCIER_CLIENT_TYPE, stringToEnum } from 'enums';

export interface FinancierClientHistoryVO {
  id: number;
  clientBusinessCode: string;
  clientTaxCode: string;
  financierClientCode: string;
  name: string;
  address: string;
  telephone: string;
  fax: string;
  representativeName: string;
  representativeEmail: string;
  representativePosition: string;
  financierClientStatus: string;
  financierClientType: string;
  targetFinancierClientId: number;
  updateUserId: number;
  updateUserName: string;
  updateLoginId: string;
  updatedDateTime: string;
  otyBypassed: boolean;
}

export interface FinancierClientHistoryVOModel extends FinancierClientHistoryVO {
  financierClientStatus: FINANCIER_CLIENT_STATUS;
  financierClientType: FINANCIER_CLIENT_TYPE;
}

export function formattingToFinancierClientHistoryVOModel(
  data: FinancierClientHistoryVO,
): FinancierClientHistoryVOModel {
  return {
    ...data,
    financierClientStatus: stringToEnum(FINANCIER_CLIENT_STATUS, data.financierClientStatus),
    financierClientType: stringToEnum(FINANCIER_CLIENT_TYPE, data.financierClientType),
  };
}
