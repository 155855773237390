import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, EARLY_REPAYMENT_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestDealerEarlyRepaymentList } from 'utils/http/api/dealer/early-repayment-requests';
import type { DealerEarlyRepaymentListRequest } from 'utils/http/api/dealer/early-repayment-requests/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const DE_EARLY_REPAYMENT_LIST_QS_KEY = 'de-early-repayment-list';

function DealerEarlyRepaymentList() {
  const mounted = useMounted();
  const history = useHistory();
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const authorizerGuideMessageList: string[] = [
    t('text:Click_the_button_on_the_right_to_request_for_early_repayment'),
    t('text:Early_repayment_requests_can_be_found_in_the_list_below'),
  ];

  const guideMessageList: string[] = [t('text:Early_repayment_requests_can_be_found_in_the_list_below')];

  const {
    register: searchFormRegister,
    getValues: getSearchFormValues,
    reset: searchFormReset,
    setValue: setSearchValue,
    control: searchFormControl,
  } = useForm<DealerEarlyRepaymentListRequest>();

  const [earlyRepaymentData, setEarlyRepaymentData] = useState<Pageable<EarlyRepaymentRequestVOModel[]>>();
  const { pageable, setPageable } = usePageable(DE_EARLY_REPAYMENT_LIST_QS_KEY);
  const userAuthorityType = getSignIn()?.authorityType;

  useEffect(() => {
    if (mounted) {
      setFormValues<DealerEarlyRepaymentListRequest>(
        setSearchValue,
        getParsedSearchParams(DE_EARLY_REPAYMENT_LIST_QS_KEY).formSearchData,
      );
      fetchDeEarlyRepaymentList(pageable.currentPage, pageable.sizePerPage, getSearchFormValues());
    }

    return () => {};
  }, [mounted]);

  const fetchDeEarlyRepaymentList = async (
    selectedPageNumber: number = 1,
    selectedRowNumber: number = 10,
    data: DealerEarlyRepaymentListRequest,
  ): Promise<void> => {
    try {
      const earlyRepaymentResponse = await requestDealerEarlyRepaymentList(selectedPageNumber, selectedRowNumber, data);
      updateSearchParams(
        {
          ...data,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowNumber,
        },
        DE_EARLY_REPAYMENT_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setEarlyRepaymentData(earlyRepaymentResponse);
        setPageable(earlyRepaymentResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();

    fetchDeEarlyRepaymentList(0, pageable.sizePerPage, getSearchFormValues());
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();

    searchFormReset({
      loanId: undefined,
      financierLoanId: '',
      invoiceNumber: '',
      currencyType: undefined,
      principalAmountFrom: undefined,
      principalAmountTo: undefined,
      scheduledPrincipalAmountFrom: undefined,
      scheduledPrincipalAmountTo: undefined,
      disbursedDateFrom: '',
      disbursedDateTo: '',
      repaymentDateFrom: '',
      repaymentDateTo: '',
      intendedRepaymentDateFrom: '',
      intendedRepaymentDateTo: '',
      createdDateFrom: '',
      createdDateTo: '',
      earlyRepaymentRequestStatus: undefined,
    });
  };

  const paginate = (selectedPageNumber: number, selectedRowNumber: number): void => {
    fetchDeEarlyRepaymentList(
      selectedPageNumber,
      selectedRowNumber,
      getParsedSearchParams(DE_EARLY_REPAYMENT_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickEarlyRepaymentRequest = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EARLY_REPAYMENT_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EARLY_REPAYMENT_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderRequestButton = (): JSX.Element => {
    const onClickRequest = (e: any): void => {
      e.preventDefault();
      history.push(ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EARLY_REPAYMENT_REGISTER);
    };

    return <Button onClick={onClickRequest}>{t('text:Request')}</Button>;
  };

  const renderResultTable = (): JSX.Element[] | JSX.Element | undefined => {
    return earlyRepaymentData?.content.map((item, index: number) => (
      <Tr key={index}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.financierName} />
        <Td data={item.principalAmount} format="number" />
        <Td data={item.disbursedDate} format="date" />
        <Td data={item.repaymentDate} format="date" />
        <Td data={item.intendedRepaymentDate} format="date" />
        <Td
          data={t(`code:early-repayment-request-status.${item.earlyRepaymentRequestStatus}`)}
          className={getStatusTextClass('EARLY_REPAYMENT_REQUEST_STATUS', item.earlyRepaymentRequestStatus)}
        />
        <TdLink
          path={ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EARLY_REPAYMENT_DETAIL_BUILD_PATH(item.earlyRepaymentRequestId)}
        />
      </Tr>
    ));
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Original_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Requested_Repayment_Date'),
      colWidths: 120,
    },

    {
      headerText: t('text:Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickEarlyRepaymentRequest,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <HeaderTitle title={t('text:Request_for_Early_Repayment')} />
      <GuideMessage
        message={userAuthorityType === AUTHORITY_TYPE.AUTHORIZER ? authorizerGuideMessageList : guideMessageList}
        messageType={MessageType.BASIC}
      >
        {{
          link: undefined,
          button: userAuthorityType === AUTHORITY_TYPE.AUTHORIZER ? renderRequestButton() : undefined,
        }}
      </GuideMessage>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Requested_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="createdDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="createdDateTo" control={searchFormControl} />
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financing_Amount')} />
              <SearchInput
                name="principalAmountFrom"
                ref={searchFormRegister}
                type="number"
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name="principalAmountTo"
                ref={searchFormRegister}
                type="number"
                col={2}
                placeholder={t('text:to')}
              />
              <SearchLabel label={t('text:Disbursed_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="disbursedDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="disbursedDateTo" control={searchFormControl} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Original_Repayment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="repaymentDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="repaymentDateTo" control={searchFormControl} />
              <SearchLabel label={t('text:Requested_Repayment_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="intendedRepaymentDateFrom"
                control={searchFormControl}
              />
              <SearchDatePicker placeholder={t('text:to')} name="intendedRepaymentDateTo" control={searchFormControl} />
            </div>
            <div className="row">
              <SearchLabel
                label={t('text:Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickEarlyRepaymentRequest}
              />
              <SearchSelect
                name="earlyRepaymentRequestStatus"
                ref={searchFormRegister}
                selectOptions={getSelectOptions<EARLY_REPAYMENT_REQUEST_STATUS>(
                  'EARLY_REPAYMENT_REQUEST_STATUS',
                  'ALL',
                  true,
                )}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {earlyRepaymentData?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerEarlyRepaymentList;
