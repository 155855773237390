import type { COLLATERAL_TYPE, COMMON_APPROVAL_TYPE, LOAN_STATUS } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface MultipleAnchorLoanRequestRelatedAnchorLoanVO {
  anchorLoanId: number;
  collateralType: COLLATERAL_TYPE;
  successArId: number;
  arNumber: string;
  arSettlementDate: string;
  successInvoiceId: number;
  invoiceNumber: string;
  invoiceSettlementDate: string;
  anchorPartnerId: number;
  anchorPartnerName: string;
  dealerId: number;
  dealerName: string;
  dealerClientId: number;
  dealerClientName: string;
  requestedAmount: string;
  desiredDisbursementDate: string;
  status: LOAN_STATUS;
  approvalType: COMMON_APPROVAL_TYPE;
}

export interface MultipleAnchorLoanRequestRelatedAnchorLoanVOModel
  extends MultipleAnchorLoanRequestRelatedAnchorLoanVO {
  requestedAmount: BigNumber;
}

export function formattingToMultipleAnchorLoanRequestRelatedAnchorLoanVOModel(
  data: MultipleAnchorLoanRequestRelatedAnchorLoanVO,
): MultipleAnchorLoanRequestRelatedAnchorLoanVOModel {
  return {
    ...data,
  };
}
