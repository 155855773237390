import { useState } from 'react';
import { useForm } from 'react-hook-form';

import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorFinancingOptionVOModel } from 'models/vo/AnchorFinancingOptionVO';
import type { WaitingAnchorFinancingOptionVOModel } from 'models/vo/WaitingAnchorFinancingOptionVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierAnchorFinancingOptionsList } from 'utils/http/api/financier/anchor-financing-opitons';
import type { SearchFiAnchorFinancingOptionsDTO } from 'utils/http/api/financier/anchor-financing-opitons/requests';
import { requestFinancierWaitingAnchorFinancingOptionList } from 'utils/http/api/financier/waiting-anchor-financing-options';
import type { SearchFiWaitingAnchorFinancingOptionDTO } from 'utils/http/api/financier/waiting-anchor-financing-options/requests';
import { setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

const FI_ANCHOR_FINANCING_OPTION_WAITING_LIST_QS_KEY = 'fi-anchor-financing-option-waiting';
const FI_ANCHOR_FINANCING_OPTION_COMPLETED_LIST_QS_KEY = 'fi-anchor-financing-option-completed';

const useFinancierAnchorFinancingOptionListController = () => {
  const modal = useModal();

  const [anchorFinancingOptionCompletedPage, setAnchorFinancingOptionCompletedPage] =
    useState<Pageable<AnchorFinancingOptionVOModel[]>>();
  const [waitingAnchorFinancingOptionPage, setWaitingAnchorFinancingOptionPage] =
    useState<Pageable<WaitingAnchorFinancingOptionVOModel[]>>();
  const [notCancelledWaitingAnchorFinancingOptionPage, setNotCancelledWaitingAnchorFinancingOptionPage] =
    useState<Pageable<WaitingAnchorFinancingOptionVOModel[]>>();

  const { pageable: completedAnchorFinancingOptionPageable, setPageable: setCompletedAnchorFinancingOptionPageable } =
    usePageable(FI_ANCHOR_FINANCING_OPTION_COMPLETED_LIST_QS_KEY);

  const { pageable: waitingAnchorFinancingOptionPageable, setPageable: setWaitingAnchorFinancingOptionPageable } =
    usePageable(FI_ANCHOR_FINANCING_OPTION_WAITING_LIST_QS_KEY);

  const completedAnchorFinancingOptionListSearchForm = useForm<SearchFiAnchorFinancingOptionsDTO>();
  const waitingAnchorFinancingOptionListSearchForm = useForm<SearchFiWaitingAnchorFinancingOptionDTO>();

  /*  fetch All */
  const fetchAll = async (): Promise<void> => {
    setFormValues<SearchFiWaitingAnchorFinancingOptionDTO>(
      waitingAnchorFinancingOptionListSearchForm.setValue,
      getParsedSearchParams(FI_ANCHOR_FINANCING_OPTION_WAITING_LIST_QS_KEY).formSearchData,
    );
    setFormValues<SearchFiAnchorFinancingOptionsDTO>(
      completedAnchorFinancingOptionListSearchForm.setValue,
      getParsedSearchParams(FI_ANCHOR_FINANCING_OPTION_COMPLETED_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalFinancingSearchData, notCancelledWaitingForApprovalFinancingSearchData } =
      setFinancingWaitingTabSearchValue();

    const addListQs = () => {
      const completedTabQs = makeSearchParamsPattern(
        {
          ...completedAnchorFinancingOptionListSearchForm.getValues(),
          pageNumber: completedAnchorFinancingOptionPageable.currentPage,
          rowCount: completedAnchorFinancingOptionPageable.sizePerPage,
        },
        FI_ANCHOR_FINANCING_OPTION_COMPLETED_LIST_QS_KEY,
      );
      const waitingTabQs = makeSearchParamsPattern(
        {
          ...waitingForApprovalFinancingSearchData,
          pageNumber: waitingAnchorFinancingOptionPageable.currentPage,
          rowCount: waitingAnchorFinancingOptionPageable.sizePerPage,
        },
        FI_ANCHOR_FINANCING_OPTION_WAITING_LIST_QS_KEY,
      );
      addSearchParams(completedTabQs + waitingTabQs);
    };
    addListQs();

    try {
      const [
        completeAnchorFinancingOptionPageResponse,
        waitingAnchorFinancingOptionPageResponse,
        notCancelledWaitingAnchorFinancingOptionPageResponse,
      ] = await Promise.all([
        requestFinancierAnchorFinancingOptionsList(
          completedAnchorFinancingOptionPageable.currentPage,
          completedAnchorFinancingOptionPageable.sizePerPage,
          completedAnchorFinancingOptionListSearchForm.getValues(),
        ),
        requestFinancierWaitingAnchorFinancingOptionList(
          waitingAnchorFinancingOptionPageable.currentPage,
          waitingAnchorFinancingOptionPageable.sizePerPage,
          waitingAnchorFinancingOptionListSearchForm.getValues(),
        ),
        requestFinancierWaitingAnchorFinancingOptionList(0, 1, notCancelledWaitingForApprovalFinancingSearchData),
      ]);

      setAnchorFinancingOptionCompletedPage(completeAnchorFinancingOptionPageResponse);
      setWaitingAnchorFinancingOptionPage(waitingAnchorFinancingOptionPageResponse);
      setCompletedAnchorFinancingOptionPageable(completeAnchorFinancingOptionPageResponse);
      setWaitingAnchorFinancingOptionPageable(waitingAnchorFinancingOptionPageResponse);
      setNotCancelledWaitingAnchorFinancingOptionPage(notCancelledWaitingAnchorFinancingOptionPageResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const setFinancingWaitingTabSearchValue = (formSearchData?: SearchFiWaitingAnchorFinancingOptionDTO) => {
    const getSearchFormData = formSearchData ? formSearchData : waitingAnchorFinancingOptionListSearchForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalFinancingSearchData: waitingForApprovalSearchData,
      notCancelledWaitingForApprovalFinancingSearchData: notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchAnchorFinancingOptionList = async (
    pageNumber: number,
    rowCount: number,
    searchedData: SearchFiAnchorFinancingOptionsDTO,
  ) => {
    try {
      const response = await requestFinancierAnchorFinancingOptionsList(pageNumber, rowCount, searchedData);
      updateSearchParams(
        {
          ...searchedData,
          pageNumber,
          rowCount,
        },
        FI_ANCHOR_FINANCING_OPTION_COMPLETED_LIST_QS_KEY,
      );

      setAnchorFinancingOptionCompletedPage(response);
      setCompletedAnchorFinancingOptionPageable(response);
    } catch (error) {
      modal.show(error);
    }
  };

  const handleClickSearchFinancingOptionCompleteTab = async (e: any): Promise<void> => {
    e.preventDefault();
    await fetchAnchorFinancingOptionList(
      1,
      completedAnchorFinancingOptionPageable.sizePerPage,
      completedAnchorFinancingOptionListSearchForm.getValues(),
    );
  };

  const paginateFinancingOptionCompleteTab = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    await fetchAnchorFinancingOptionList(
      pageNumber,
      sizePerPage,
      getParsedSearchParams(FI_ANCHOR_FINANCING_OPTION_COMPLETED_LIST_QS_KEY).formSearchData,
    );
  };

  const handleClickSearchWaitingFinancingOptionTab = async (e: any): Promise<void> => {
    e.preventDefault();
    const { waitingForApprovalFinancingSearchData, notCancelledWaitingForApprovalFinancingSearchData } =
      setFinancingWaitingTabSearchValue();

    try {
      const [waitingAnchorFinancingOptionPageResponse, notCancelledWaitingAnchorFinancingOptionPageResponse] =
        await Promise.all([
          requestFinancierWaitingAnchorFinancingOptionList(
            1,
            waitingAnchorFinancingOptionPageable.sizePerPage,
            waitingForApprovalFinancingSearchData,
          ),
          requestFinancierWaitingAnchorFinancingOptionList(0, 1, notCancelledWaitingForApprovalFinancingSearchData),
        ]);

      updateSearchParams(
        {
          ...waitingForApprovalFinancingSearchData,
          pageNumber: 1,
          rowCount: waitingAnchorFinancingOptionPageable.sizePerPage,
        },
        FI_ANCHOR_FINANCING_OPTION_WAITING_LIST_QS_KEY,
      );

      setWaitingAnchorFinancingOptionPage(waitingAnchorFinancingOptionPageResponse);
      setWaitingAnchorFinancingOptionPageable(waitingAnchorFinancingOptionPageResponse);
      setNotCancelledWaitingAnchorFinancingOptionPage(notCancelledWaitingAnchorFinancingOptionPageResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const paginateWaitingFinancingOptionTab = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    const { waitingForApprovalFinancingSearchData } = setFinancingWaitingTabSearchValue();
    try {
      const response = await requestFinancierWaitingAnchorFinancingOptionList(
        pageNumber,
        sizePerPage,
        waitingForApprovalFinancingSearchData,
      );
      updateSearchParams(
        {
          ...waitingForApprovalFinancingSearchData,
          pageNumber,
          sizePerPage,
        },
        FI_ANCHOR_FINANCING_OPTION_WAITING_LIST_QS_KEY,
      );

      setWaitingAnchorFinancingOptionPage(response);
      setWaitingAnchorFinancingOptionPageable(response);
    } catch (error) {
      modal.show(error);
    }
  };

  return {
    fetchAll,
    financingOptionCompletedTabUtils: {
      completedAnchorFinancingOptionListSearchForm,
      anchorFinancingOptionCompletedPage,
      completedAnchorFinancingOptionPageable,
      handleClickSearchFinancingOptionCompleteTab,
      paginateFinancingOptionCompleteTab,
    },
    financingOptionWaitingTabUtils: {
      notCancelledWaitingAnchorFinancingOptionPage,
      waitingAnchorFinancingOptionListSearchForm,
      waitingAnchorFinancingOptionPage,
      waitingAnchorFinancingOptionPageable,
      handleClickSearchWaitingFinancingOptionTab,
      paginateWaitingFinancingOptionTab,
    },
  };
};

export default useFinancierAnchorFinancingOptionListController;
