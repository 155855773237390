import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty, isNil } from 'lodash-es';

import { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import {
  AUTHORITY_TYPE,
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  DEALER_AGREEMENT_STATUS,
  DEALER_AGREEMENT_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
} from 'enums';
import { AgreementExceptionCode } from 'enums/exception';
import type { SignInModel } from 'models/SignInModel';
import { convertToDealerAgreementResetData } from 'models/convertor/dealerAgreement';
import { formErrorHandler } from 'utils/error/manager';
import { isNilOrEmptyString } from 'utils/helpers';
import {
  requestCancelDealerAgreementDetail,
  requestChangeDealerAgreementDetail,
  requestConfirmDealerAgreementDetail,
  requestReturnDealerAgreementDetail,
} from 'utils/http/api/financier/waiting-dealer-agreements';
import type { UpdateFiWaitingDealerAgreementDTO } from 'utils/http/api/financier/waiting-dealer-agreements/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getObjectValue } from 'utils/object';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser, requestDTOParserForArrayObj } from 'utils/valueManager/ValueManager';

import useAgreementViewModel from '../models/agreement/useAgreementViewModel';
import useAnchorAgreementInfoViewModel from '../models/agreement/useAnchorAgreementInfoViewModel';
import useTermSpreadViewModel from '../models/agreement/useTermSpreadViewModel';
import useExtraInformationViewModel from '../models/extraInformation/useExtraInformationViewModel';
import useFinancierSettingViewModel from '../models/financierSetting/useFinancierSettingViewModel';

const useWaitingDetailController = () => {
  const methods = useForm<UpdateFiWaitingDealerAgreementDTO>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const { reset, getValues, setError, clearErrors } = methods;

  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation(['format']);
  const { show: showModal, id: modalId } = useModal();

  const {
    agreement,
    fetchWaitingAgreement,
    updateIsSearchedAgreement,
    isFirstRegisteredWaitingAgreement,
    isSearchedAgreement,
    updateAllAgreement,
    isMaturityExtension,
  } = useAgreementViewModel();
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();
  const { termSpreadList } = useTermSpreadViewModel();
  const { potentialPartnerRegistrable, fetchFinancierSetting, factoringEnable } = useFinancierSettingViewModel();
  const {
    waitingDealerAgreementId,
    supportedCollateralType,
    errorHandlerOfLocationState,
    isMatchedWithUploadedPartner,
    useAgreementInterface,
  } = useExtraInformationViewModel();

  const { approvalType, returnReason } = agreement;

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const isSameBranch = signInModel?.branchId === agreement?.branchId;

  const isEditable =
    approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED &&
    signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR &&
    isSameBranch;

  const isEditableStyle = useAgreementInterface ? isSearchedAgreement : isEditable;

  const isVendorFactoring = factoringEnable && isAr;

  const getReadOnlyValue = useCallback(
    (parameterName?: string): boolean => {
      if (!useAgreementInterface || !isEditable) return false;

      const allAgreement = { ...agreement, ...anchorAgreementInfo, termSpreadList };

      const agreementValue = getObjectValue({
        object: allAgreement,
        name: parameterName ?? '',
      });

      return !(isSearchedAgreement && (isNil(agreementValue) || agreementValue === ''));
    },
    [useAgreementInterface, isEditable, agreement, anchorAgreementInfo, termSpreadList, isSearchedAgreement],
  );

  const resetPage = async () => {
    if (isNil(waitingDealerAgreementId) || errorHandlerOfLocationState()) return;

    try {
      const [agreement] = await Promise.all([fetchWaitingAgreement(waitingDealerAgreementId), fetchFinancierSetting()]);

      if (agreement) {
        if (agreement.basisInterestBankCode === BASIS_INTEREST_TYPE.FIXED) agreement.basisInterestBankCode = '';
        reset(convertToDealerAgreementResetData(agreement));
        updateIsSearchedAgreement(false);
        updateAllAgreement(agreement);
      }
    } catch (error) {
      showModal(error);
    }
  };

  const conditionChecker = (conditionAuthority: AUTHORITY_TYPE, conditionApprovalType: COMMON_APPROVAL_TYPE) => {
    if (signInModel?.authorityType !== conditionAuthority) return false;

    if (agreement?.approvalType !== conditionApprovalType) return false;

    return true;
  };

  // Change Request Button
  const handleARRequiredFieldsValidation = () => {
    const data = getValues();
    /**
     * AR에서 필수
     * 전송 시 몇몇 필드들에 대한 검증이 먼저 들어가기 때문에 앞서 들어간 검증이 통과되어야 에러코드가 내려옴(늦게). 우선 FE에서 에러 표시
     */
    const arRequiredFields = [
      { name: 'principalRepaymentAccountFinancierName', value: data.principalRepaymentAccountFinancierName },
      { name: 'principalRepaymentAccountBranchName', value: data.principalRepaymentAccountBranchName },
      { name: 'principalRepaymentAccount', value: data.principalRepaymentAccount },
      { name: 'principalRepaymentAccountOwner', value: data.principalRepaymentAccountOwner },
    ];

    if (isAr) {
      arRequiredFields.map(item => {
        if (isNilOrEmptyString(item.value)) {
          setError(item.name, {
            message: t('text:This_information_is_required'),
          });
        } else clearErrors(item.name);
      });
    }
  };

  const handleVendorFactoringRequiredFieldsValidation = () => {
    const data = getValues();
    const vendorFactoringRequiredFields = [
      { name: 'settlementAccountFinancierName', value: data.settlementAccountFinancierName },
      { name: 'settlementAccountBranchName', value: data.settlementAccountBranchName },
      { name: 'settlementAccount', value: data.settlementAccount },
      { name: 'settlementAccountOwner', value: data.settlementAccountOwner },
    ];

    if (isVendorFactoring) {
      vendorFactoringRequiredFields.forEach(item => {
        if (isNilOrEmptyString(item.value)) {
          setError(item.name, {
            message: t('text:This_information_is_required'),
          });
        } else clearErrors(item.name);
      });
    }
  };

  const handleUpdateAgreementError = (error: any) => {
    showModal(error);

    const isInvalidError = !isEmpty(formErrorHandler<UpdateFiWaitingDealerAgreementDTO>(error, setError, clearErrors));

    handleARRequiredFieldsValidation();
    handleVendorFactoringRequiredFieldsValidation();

    if (isInvalidError) return;

    const getMonthlyInterestRepaymentDateErrorMessage = () => {
      const monthlyInterestRepaymentDate = getValues('monthlyInterestRepaymentDate');
      if (isNil(monthlyInterestRepaymentDate) || Number(monthlyInterestRepaymentDate) === 0)
        return t('text:0_and_blank_are_not_allowed');
      else return t('text:Date_must_be_between_1_and_31');
    };

    switch (error.code) {
      case AgreementExceptionCode.INVALID_BASIS_INTEREST_RATE:
        return setError('basisInterestRate', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_LOAN_TERM:
        return setError('loanTermUnit', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_LOAN_LIMIT_CHECK_TYPE:
        return setError('loanLimitCheckType', { shouldFocus: true });
      case AgreementExceptionCode.OMITTED_ANCHOR_AGREEMENT_INFO:
        return setError('anchorAgreementId', { shouldFocus: true });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_INTEREST_REPAYMENT_TYPE:
        return setError('monthlyInterestRepaymentDate', {
          shouldFocus: true,
          message: getMonthlyInterestRepaymentDateErrorMessage(),
        });
      default:
        return;
    }
  };

  const updateAgreement = async () => {
    const data = getValues();

    const bankUserIdList = data.bankUserIdList as string;
    data.bankUserIdList = bankUserIdList ? bankUserIdList.split(',') : [];
    data.termSpreadList = data.termSpreadList ?? termSpreadList;

    if (isMaturityExtension === 'false') data.maxExtensibleLoanCount = data.maxExtensibleLoanCount ?? 0;

    if (agreement.basisInterestType === BASIS_INTEREST_TYPE.FIXED)
      agreement.basisInterestBankCode = BASIS_INTEREST_TYPE.FIXED;

    if (isInvoice && isFirstRegisteredWaitingAgreement) data.dealerAgreementStatus = DEALER_AGREEMENT_STATUS.ACTIVATED;

    if (isAr) {
      if (data.loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE) data.loanLimitAmount = String(0);
    }

    if (isVendorFactoring) {
      data.interestRepaymentType = INTEREST_REPAYMENT_TYPE.EARLIER;
      data.showNetDisbursementAmount = 'true';
      data.earlyRepaymentAllowable = 'false';
      data.maxExtensibleLoanCount = 0;
      data.showExpectedTotalInterestRate = 'true';
    }

    requestDTOParser(data);
    requestDTOParserForArrayObj(data.termSpreadList);
    await requestChangeDealerAgreementDetail(waitingDealerAgreementId, data);
  };

  const showChangeRequestCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:Request_for_registration_approval_has_been_completed')}
            <br />
            {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
          </>
        ) : (
          <>
            {t('text:Request_for_modification_approval_has_been_completed')}
            <br />
            {t('text:Modification_will_be_completed_after_approval_by_the_Authorizer')}
          </>
        )}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleChangeRequestButtonClick = () => {
    const shouldCheckUploadedPartner =
      isAr &&
      getValues('dealerAgreementType') === DEALER_AGREEMENT_TYPE.WITH_ANCHOR &&
      potentialPartnerRegistrable &&
      isFirstRegisteredWaitingAgreement;

    if (shouldCheckUploadedPartner && !isMatchedWithUploadedPartner) {
      return showModal(<h6>{t('text:Please_check_uploaded_partner')}</h6>);
    }

    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:Would_you_like_to_request_approval_for_registration?')}
            <br />
            {t(
              'text:Please_note_that_if_modifications_occur_after_the_completion_of_the_request_the_approval_request_must_be_cancelled_and_processed_again',
            )}
          </>
        ) : (
          t('text:Would_you_like_to_request_approval_for_modification?')
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await updateAgreement();
            showChangeRequestCompletedModal();
          } catch (e) {
            handleUpdateAgreementError(e);
          }
        },
      },
    );
  };

  // Cancel Button
  const showCancelCompletedModal = async () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:The_registration_request_has_been_cancelled')
          : t('text:The_modification_request_has_been_cancelled')}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleCancelButtonClick = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t(`text:Would_you_like_to_cancel_the_registration_request?`)
          : t('text:Would_you_like_to_cancel_the_modification_request?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await requestCancelDealerAgreementDetail(waitingDealerAgreementId);
            showCancelCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  // Return Button
  const showReturnCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:The_registration_request_has_been_reverted')
          : t('text:The_modification_request_has_been_reverted')}
      </h6>,
      { closeBtnCb: resetPage },
    );
  };

  const handleReturnButtonClick = () => {
    let reason = '';

    showModal(
      <>
        <h6>
          {t('text:Would_you_like_to_revert_the_registration_request?')}
          <br />
          {t('text:Please_enter_the_reason_for_the_revert_below')}
        </h6>
        <ReasonModal
          modalId={modalId}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await requestReturnDealerAgreementDetail(waitingDealerAgreementId, reason);
            showReturnCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  // Confirm Button
  const showConfirmCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:The_Partner_Master_Agreement_has_been_registered_successfully')}
            <br />
            {t('text:Please_check_the_registered_information_in_the_Registration_Completed_tab')}
          </>
        ) : (
          <>
            {t('text:The_Partner_Master_Agreement_has_been_modified_successfully')}
            <br />
            {t('text:Please_check_the_modified_information_in_the_Registration_Completed_tab')}
          </>
        )}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleConfirmButtonClick = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:Would_you_like_to_approve_the_Partner_Master_Agreement_registration_request?')
          : t('text:Would_you_like_to_approve_the_Partner_Master_Agreement_modification_request?')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestConfirmDealerAgreementDetail(waitingDealerAgreementId);
            showConfirmCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  // Guide Message
  const getGuideMessageOptionsForRequestType = () => {
    let message: string[] = [];
    const fixedMessage = isFirstRegisteredWaitingAgreement
      ? t('text:Registration_of_the_partner_master_agreement_has_been_requested')
      : t('text:Modification_of_the_partner_master_agreement_has_been_requested');

    if (isSameBranch) {
      switch (signInModel?.authorityType) {
        case AUTHORITY_TYPE.OPERATOR:
          message = [fixedMessage, t('text:To_cancel_the_request_please_click_on_the_Cancel_Request_button_below')];
          break;
        case AUTHORITY_TYPE.AUTHORIZER:
          message = [
            fixedMessage,
            t('text:Please_check_the_master_agreement_information_and_press_the_button_below_to_proceed_with_approval'),
          ];
          break;
        default:
          message = [fixedMessage];
          break;
      }
    } else {
      message = [fixedMessage];
    }

    return { message };
  };

  const getGuideMessageOptionsForReturnType = () => {
    let message: string[] = [];
    const fixedMessage = isFirstRegisteredWaitingAgreement
      ? t('text:The_master_agreement_registration_request_has_been_reverted')
      : t('text:The_master_agreement_modification_request_has_been_reverted');

    isSameBranch && signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR
      ? (message = [fixedMessage, t('text:Please_check_the_reason_for_the_revert_below_and_proceed_with_the_update')])
      : (message = [fixedMessage]);

    return {
      message,
      messageType: MessageType.ALERT,
      reason: returnReason,
      reasonTitle: t('text:REASON_FOR_REVERT'),
    };
  };

  const GuideMessageOption = (() => {
    if (approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) return getGuideMessageOptionsForRequestType();

    if (approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED) return getGuideMessageOptionsForReturnType();

    return null;
  })();

  useEffect(() => {
    resetPage();
  }, []);

  return {
    isAr,
    isSameBranch,
    isEditable,
    isEditableStyle,
    isFirstRegisteredWaitingAgreement,
    methods,
    GuideMessageOption,
    supportedCollateralType,
    useAgreementInterface,
    getReadOnlyValue,
    conditionChecker,
    handleCancelButtonClick,
    handleChangeRequestButtonClick,
    handleReturnButtonClick,
    handleConfirmButtonClick,
    isRegistrationRequestButtonDisabled: !(useAgreementInterface ? isSearchedAgreement : true),
    approvalType,
    isVendorFactoring,
  };
};

export default useWaitingDetailController;
