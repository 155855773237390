import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import { ROUTES_PP } from 'constants/routes/potentialPartner';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import PotentialPartnerSubmissionDetailAnchorFinancierInfo from './sections/anchor-financier-info/PotentialPartnerSubmissionDetailAnchorFinancierInfo';
import PotentialPartnerSubmissionDetailMyCompanyInfo from './sections/company-info/PotentialPartnerSubmissionDetailMyCompanyInfo';
import PotentialPartnerSubmissionDetailDocumentListTable from './sections/document-list/PotentialPartnerSubmissionDetailDocumentListTable';
import PotentialPartnerSubmissionDetailSummary from './sections/summary/PotentialPartnerSubmissionDetailSummary';
import { usePotentialPartnerSubmissionDetailState } from './usePotentialPartnerSubmissionDetailState';
import PPSection from '../../../components/potential-partner/PPSection/PPSection';
import PageTitle from '../../../components/potential-partner/PageTitle/PageTitle';

function PotentialPartnerSubmissionDetailPage() {
  const modal = useModal();
  const mounted = useMounted();
  const history = useHistory();

  const { t } = useTranslation();

  const { state, fetchAll, cancelSubmission, updateDocumentApis } = usePotentialPartnerSubmissionDetailState();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  const onClickCancelSubmission = async () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_application?')}
        <br />
        {t(
          'text:If_cancelled_the_financier_will_not_be_able_to_review_your_information_and_you_will_have_to_submit_your_documents_again',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          await cancelSubmission(state.applicationInfo.potentialPartnerFinancingApplicationId);
          history.push(ROUTES_PP.HOME);
        },
      },
    );
  };

  return (
    <div className="pp-view-submission-page">
      <PPSection className="bg-warm-gray-30" paddingType="md">
        <PageTitle title={t('text:View_Application')} backButtonColor={Button2ColorEnum.TERTIARY_REVERSE} />
        <PotentialPartnerSubmissionDetailSummary
          data={state.applicationInfo}
          numOfNeedResubmission={state.numOfNeedResubmission}
        />
      </PPSection>
      <PPSection>
        <PotentialPartnerSubmissionDetailMyCompanyInfo data={state.potentialPartnerInfo} />
        <PotentialPartnerSubmissionDetailAnchorFinancierInfo data={state.applicationInfo} />
        <PotentialPartnerSubmissionDetailDocumentListTable
          submittedDocumentList={state.submittedDocumentList}
          applicationStatus={state.applicationInfo.potentialPartnerFinancingApplicationStatus}
          updateDocumentApis={updateDocumentApis}
        />
        {state.applicationInfo.potentialPartnerFinancingApplicationStatus ===
          POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED && (
          <div className="pp-cancel-submission-btn-wrapper">
            <Button2 size={Button2SizeEnum.LG} onClick={onClickCancelSubmission} color={Button2ColorEnum.TERTIARY}>
              {t('text:Cancel_application')}
            </Button2>
          </div>
        )}
      </PPSection>
    </div>
  );
}

export default PotentialPartnerSubmissionDetailPage;
