import { useTranslation } from 'react-i18next';

import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';

interface SystemFinancingDetailDesignatedBankAccountInformationProps {
  data: LoanDetailVOModel;
  factoringEnable: boolean;
}
function SystemFinancingDetailDesignatedBankAccountInformation({
  data,
  factoringEnable,
}: SystemFinancingDetailDesignatedBankAccountInformationProps) {
  const { t } = useTranslation(['format']);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Designated_Bank_Account_Information')} />
      <div className="information-form-border">
        <FormSubtitle title={t('text:DISBURSEMENT_ACCOUNT')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Bank_Name')} value={data.settlementAccountFinancierName} />
            <FormValue label={t('text:Bank_Branch_Name')} value={data.settlementAccountBranchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Account_Number')} value={data.settlementAccount} />
            <FormValue label={t('text:Account_Holder_Name')} value={data.settlementAccountOwner} />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:REPAYMENT_ACCOUNT')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Bank_Name')} value={data.principalRepaymentAccountFinancierName} />
            <FormValue label={t('text:Bank_Branch_Name')} value={data.principalRepaymentAccountBranchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Account_Number')} value={data.principalRepaymentAccount} />
            <FormValue label={t('text:Account_Holder_Name')} value={data.principalRepaymentAccountOwner} />
          </div>
        </FormContents>
        {!factoringEnable && (
          <>
            <FormSubtitle title={t('text:REPAYMENT_ACCOUNT_-_INTEREST')} />
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue label={t('text:Bank_Name')} value={data.interestRepaymentAccountFinancierName} />
                <FormValue label={t('text:Bank_Branch_Name')} value={data.interestRepaymentAccountBranchName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={data.interestRepaymentAccount} />
                <FormValue label={t('text:Account_Holder_Name')} value={data.interestRepaymentAccountOwner} />
              </div>
            </FormContents>
          </>
        )}
      </div>
    </div>
  );
}
export default SystemFinancingDetailDesignatedBankAccountInformation;
