import React from 'react';
import type { UseFormMethods } from 'react-hook-form';

interface AdditionalDataListType {
  columns: string[];
  rowIndex: number;
  register: UseFormMethods['register'];
  fieldName: {
    rowName: string;
    columnName: string;
  };
}

function AdditionalDataListCopy({ columns, rowIndex, register, fieldName }: AdditionalDataListType) {
  const { rowName, columnName } = fieldName;

  return (
    <>
      {columns.map((_, additionalIndex) => {
        return (
          <td key={additionalIndex}>
            <input
              className="grid-input"
              name={`${rowName}[${rowIndex}].${columnName}[${additionalIndex}].value`}
              type="text"
              ref={register()}
            />
            <input
              type="hidden"
              name={`${rowName}[${rowIndex}].${columnName}[${additionalIndex}].name`}
              ref={register()}
            />
          </td>
        );
      })}
    </>
  );
}

export default React.memo(AdditionalDataListCopy);
