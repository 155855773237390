import type { ANCHOR_FINANCING_CONDITION, BASIS_INTEREST_TYPE, INTEREST_REPAYMENT_TYPE, LOAN_TERM_TYPE } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

import { formattingToTermSpreadVOModel } from './TermSpreadVO';

import type { AnchorFinancingOptionVO } from './AnchorFinancingOptionVO';
import type { TermSpreadVO, TermSpreadVOModel } from './TermSpreadVO';

export interface AnchorFinancingOptionDetailVO extends AnchorFinancingOptionVO {
  anchorAgreementId: number;
  anchorAgreementContractNo: string;
  anchorAgreementDivision: string;
  anchorAgreementDivisionName: string;
  anchorClientTaxCode: string;
  branchAddress: string;
  branchFax: string;
  branchTelephone: string;
  financierEntId: number;
  financierName: string;
  anchorAgreementCollateralIssuedLimitAmount: string;
  loanLimitCheck: boolean;
  maxLtvRatio: number;
  loanLimitAmount: string;
  basisInterestType: BASIS_INTEREST_TYPE;
  basisInterestBankCode: string;
  basisInterestTerm: string;
  basisInterestRate: number;
  creditInterestRate: number;
  preferentialInterestRate: number;
  termSpreadList: TermSpreadVO[];
  anchorFinancingCondition: ANCHOR_FINANCING_CONDITION;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  monthlyInterestRepaymentDate: number;
  loanTermUnit: number;
  loanTermType: LOAN_TERM_TYPE;
  minimumLoanRange: number;
  maximumLoanRange: number;
  disbursementDateOverAllowable: boolean;
  repaymentDateOverAllowable: boolean;
  anchorAccount: boolean;
  disbursementAccountType: string;
  disbursementAccountFinancierCode: string;
  disbursementAccountFinancierName: string;
  disbursementAccountBranchCode: string;
  disbursementAccountBranchName: string;
  disbursementAccount: string;
  disbursementAccountOwner: string;
  showNetDisbursementAmount: boolean;
  maxAgreementLoanCapacityAmount: string;
}

export interface AnchorFinancingOptionDetailVOModel extends AnchorFinancingOptionDetailVO {
  anchorAgreementCollateralIssuedLimitAmount: BigNumber;
  loanLimitAmount: BigNumber;
  termSpreadList: TermSpreadVOModel[];
  maxAgreementLoanCapacityAmount: BigNumber;
}

export function formattingToAnchorFinancingOptionDetailVOModel(
  data: AnchorFinancingOptionDetailVO,
): AnchorFinancingOptionDetailVOModel {
  const {
    loanLimitAmount,
    termSpreadList,
    anchorAgreementCollateralIssuedLimitAmount,
    maxAgreementLoanCapacityAmount,
  } = data;

  return {
    ...data,
    loanLimitAmount: sliceZeroDecimal(loanLimitAmount),
    anchorAgreementCollateralIssuedLimitAmount: sliceZeroDecimal(anchorAgreementCollateralIssuedLimitAmount),
    termSpreadList: termSpreadList
      ? termSpreadList.map(termSpread => formattingToTermSpreadVOModel(termSpread))
      : termSpreadList,
    maxAgreementLoanCapacityAmount: sliceZeroDecimal(maxAgreementLoanCapacityAmount),
  };
}
