import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface InvoicePaymentVO {
  financierName: string;
  anchorAgreementContractNo: string;
  anchorClientName: string;
  anchorName: string;
  dealerCodeByAnchor: string;
  partnerAgreementId: number;
  partnerAgreementContractNo: string;
  partnerClientName: string;
  partnerName: string;
  currencyType: string;
  billOfExchangeNo: string;
  settlementDate: string;
  totalCount: number;
  totalAmount: number;
}

export interface InvoicePaymentVOModel extends InvoicePaymentVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToInvoicePaymentVOModel(data: InvoicePaymentVO): InvoicePaymentVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
