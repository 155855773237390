import type React from 'react';
import type { ReactNode } from 'react';
import type { Control, FieldError } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { FormErrorMessage } from './FormErrorMessage';
import ReactDatePicker from '../Form/ReactDatePicker/ReactDatePicker';

export interface FormDatePickerInputProps {
  name: string;
  className?: string;
  disabled?: boolean;
  col?: number;
  required?: boolean;
  label: string;
  error?: FieldError;
  minDate?: Date;
  maxDate?: Date;
  excludeDates?: Date[];
  wrapperStyle?: React.CSSProperties;
  control: Control;
  placeholder?: string;
  defaultValue?: string | null;
  children?: ReactNode;
}

export const FormDatePickerInput = ({
  name,
  disabled = false,
  col = 6,
  required = false,
  label,
  className = 'information-form__input',
  error,
  minDate,
  maxDate = new Date('2999-12-31'),
  excludeDates,
  wrapperStyle,
  control,
  placeholder,
  children,
  defaultValue = null,
}: FormDatePickerInputProps) => {
  return (
    <div className={`col-${col}`} style={wrapperStyle}>
      <label
        className={!disabled && required ? 'information-form__label star' : 'information-form__label'}
        style={label === '' ? { minHeight: '15px' } : undefined}
      >
        {label}
      </label>
      <div className="position-parent">
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={field => (
            <div className="d-flex">
              <ReactDatePicker
                field={field}
                className={`${className} ${error ? 'error-input-border' : ''}`}
                minDate={minDate}
                maxDate={maxDate}
                excludeDates={excludeDates}
                disabled={disabled}
                placeholder={placeholder}
              />
              {children}
            </div>
          )}
          rules={{ required: true }}
        />
      </div>
      <FormErrorMessage error={error} />
    </div>
  );
};

export default FormDatePickerInput;
