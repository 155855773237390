import { useTranslation } from 'react-i18next';

import { isNull } from 'lodash-es';

import Form, { SizeType } from 'components/stateless/Form';
import NumberInput from 'components/stateless/Form/NumberInput/NumberInput';
import { RadioGroup } from 'components/stateless/Form/RadioComponents';
import Radio from 'components/stateless/Form/RadioComponents/Radio';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';

import useFinancingLimitController from './useFinancingLimitController';

const FinancingLimit = () => {
  const { t } = useTranslation();
  const {
    errors,
    register,
    handleFinancingLimitCheckPointChange,
    isInvoice,
    isEditable,
    ltvState: { isLtvHundred, ltvValue },
    anchorAgreementInfo,
    handleIsLtvHundredChange,
    handleEnterLtvChange,
  } = useFinancingLimitController();

  const { collateralIssuedLimitCheck, collateralIssuedLimitAmount, loanLimitCheck, loanLimitAmount, currencyType } =
    anchorAgreementInfo;

  return (
    <>
      <div>
        <SubTitle>{t('text:FINANCING_LIMIT')}</SubTitle>
        <Content>
          {!isInvoice && (
            <>
              <Row>
                <Form.Label position="top" required>
                  {t('text:Limit_of_AR_Issuance')}
                </Form.Label>
                {collateralIssuedLimitCheck ? (
                  <Form.Value
                    className="w-25"
                    value={collateralIssuedLimitAmount}
                    format="number"
                    rightUnit={currencyType}
                  />
                ) : (
                  <Form.Value className="w-25" value={t('text:Not_Applied')} />
                )}
              </Row>
              <Row>
                <Cell>
                  <Form.Label position="top" required>
                    {t('text:Financing_Limit_of_Anchor')} ({t('text:for_Partner')})
                  </Form.Label>
                  <Form.Description>
                    {t(
                      'text:The_financing_limit_of_Anchor_is_applied_to_all_of_its_partners_when_they_request_financing',
                    )}
                  </Form.Description>
                </Cell>
                {loanLimitCheck ? (
                  <Form.Value className="w-25" value={loanLimitAmount} format="number" rightUnit={currencyType} />
                ) : (
                  <Form.Value className="w-25" value={t('text:Not_Applied')} />
                )}
              </Row>
            </>
          )}
          <Row>
            <Form.Control name="loanLimitCheck" required>
              <Cell>
                <Form.Label position="top">{t('text:Anchor_Financing_Limit')}</Form.Label>
                <Form.Description>
                  {t('text:Anchor_financing_limits_apply_only_to_direct_financings_from_anchor_companies')}
                </Form.Description>
              </Cell>
              <Form.Radio onChange={handleFinancingLimitCheckPointChange}>
                <Form.Option
                  label={t('text:Applied')}
                  value="true"
                  tooltipOptions={{
                    id: 'loanLimitCheckType-Applied',
                    content: t('text:The_limit_will_be_recovered_when_the_financing_is_repaid'),
                  }}
                  render={(isParentReadOnly, isParentRadioUnChecked) => (
                    <Border>
                      <Content isInnerStyle>
                        <Row>
                          <Form.Control name="loanLimitAmount" required>
                            <Form.Label>{t('text:Financing_Limit_Amount')}</Form.Label>
                            <Form.NumberInput
                              currencyType={currencyType}
                              leftUnit={currencyType}
                              textAlign="text-right"
                              fieldSize={SizeType.MD}
                              numberType="bigNumber"
                              readOnly={isParentRadioUnChecked}
                              parentReadOnly={isParentReadOnly}
                            />
                          </Form.Control>
                        </Row>
                      </Content>
                    </Border>
                  )}
                />
                <Form.Option label={t('text:Not_Applied')} value="false" />
              </Form.Radio>
            </Form.Control>
          </Row>
          {isInvoice && (
            <>
              <input name="maxLtvRatio" type="hidden" ref={register} />
              <Row>
                <Form.Control required>
                  <Cell>
                    <Form.Label position="top" required>
                      {t('text:Expected_Disbursement_Ratio')} (%)
                    </Form.Label>
                    <Form.Description>
                      {`${t('text:Only_invoices_with_approved_partner_financing_can_be_applied_for_by_anchors')} 
                    ${t('text:Sets_the_percentage_that_anchors_can_apply_for')}`}
                    </Form.Description>
                  </Cell>
                  <RadioGroup
                    name="isLtvHundred"
                    stateValue={String(isLtvHundred)}
                    onChange={handleIsLtvHundredChange}
                    isEditable={isEditable}
                    error={errors?.maxLtvRatio}
                    showError={isNull(isLtvHundred)}
                  >
                    <Radio label="100%" value="true" />
                    <Radio
                      label={t('text:Enter_the_Ratio')}
                      value="false"
                      render={(_, isParentRadioUnChecked) => (
                        <Cell>
                          <NumberInput
                            fieldSize={SizeType.SM}
                            useUpToOneHundredPercent
                            rightUnit="%"
                            textAlign="text-right"
                            readOnly={isParentRadioUnChecked}
                            onChange={handleEnterLtvChange}
                            value={ltvValue}
                            isEditable={isEditable}
                            numberType="integer"
                          />
                        </Cell>
                      )}
                    />
                  </RadioGroup>
                </Form.Control>
              </Row>
            </>
          )}
        </Content>
      </div>
    </>
  );
};

export default FinancingLimit;
