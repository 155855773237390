const DE_DASHBOARD_PATH = '/dealer/dashboard';

const DE_REGISTER_INVOICE_PATH = '/dealer/register-invoice';
const DE_REGISTER_INVOICE_CONFIRMATION_PATH = `${DE_REGISTER_INVOICE_PATH}/confirmation`;
const DE_REGISTER_INVOICE_REGISTRATION_PATH = `${DE_REGISTER_INVOICE_PATH}/registration`;

const DE_REGISTER_AR_PATH = '/dealer/register-ar';
const DE_REGISTER_AR_CONFIRMATION_PATH = `${DE_REGISTER_AR_PATH}/confirmation`;
const DE_REGISTER_AR_REGISTRATION_PATH = `${DE_REGISTER_AR_PATH}/registration`;

const DE_MANAGE_FINANCING_PATH = '/dealer/manage-financing';
const DE_MANAGE_FINANCING_APPLICATION_PATH = `${DE_MANAGE_FINANCING_PATH}/application`;
const DE_MANAGE_FINANCING_CONFIRMATION_PATH = `${DE_MANAGE_FINANCING_PATH}/confirmation`;
const DE_MANAGE_FINANCING_BULK_CONFIRMATION_PATH = `${DE_MANAGE_FINANCING_PATH}/bulk-confirmation`;
const DE_MANAGE_FINANCING_REQUEST_FOR_EARLY_REPAYMENT_PATH = `${DE_MANAGE_FINANCING_PATH}/request-for-early-repayment`;
const DE_MANAGE_FINANCING_REQUEST_FOR_EXTENSION_PATH = `${DE_MANAGE_FINANCING_PATH}/request-for-extension`;
const DE_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH = `${DE_MANAGE_FINANCING_PATH}/secured-inventory-update`;

const DE_VIEW_TRANSACTION_PATH = '/dealer/view-transaction';
const DE_VIEW_TRANSACTION_INVOICE_PATH = `${DE_VIEW_TRANSACTION_PATH}/invoice`;
const DE_VIEW_TRANSACTION_AR_PATH = `${DE_VIEW_TRANSACTION_PATH}/ar`;
const DE_VIEW_TRANSACTION_FINANCING_PATH = `${DE_VIEW_TRANSACTION_PATH}/financing`;

const DE_COMPANY_PATH = '/dealer/company';
const DE_COMPANY_USER_PATH = `${DE_COMPANY_PATH}/user`;
const DE_COMPANY_AGREEMENT_PATH = `${DE_COMPANY_PATH}/agreement`;

const DE_MY_ACCOUNT_PATH = '/dealer/my-account';
const DE_MY_ACCOUNT_EMAIL_NOTIFICATION_SETTING_PATH = `${DE_MY_ACCOUNT_PATH}/email-setting`;

export const ROUTES_DE = {
  DASHBOARD: DE_DASHBOARD_PATH,

  REGISTER_INVOICE: {
    CONFIRMATION_LIST: DE_REGISTER_INVOICE_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${DE_REGISTER_INVOICE_CONFIRMATION_PATH}/:invoiceSummaryId/:financierEnterpriseId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (invoiceSummaryId: number, financierEnterpriseId: number) =>
      `${DE_REGISTER_INVOICE_CONFIRMATION_PATH}/${invoiceSummaryId}/${financierEnterpriseId}`,

    REGISTRATION_STEP1: DE_REGISTER_INVOICE_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${DE_REGISTER_INVOICE_REGISTRATION_PATH}/:dealerAgreementId/:financierId`,
    REGISTRATION_STEP2_BUILD_PATH: (dealerAgreementId: number, financierId: number) =>
      `${DE_REGISTER_INVOICE_REGISTRATION_PATH}/${dealerAgreementId}/${financierId}`,
  },

  REGISTER_AR: {
    CONFIRMATION_LIST: DE_REGISTER_AR_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${DE_REGISTER_AR_CONFIRMATION_PATH}/:apSummaryId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (apSummaryId: number) => `${DE_REGISTER_AR_CONFIRMATION_PATH}/${apSummaryId}`,

    REGISTRATION_STEP1: DE_REGISTER_AR_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${DE_REGISTER_AR_REGISTRATION_PATH}/:anchorAgreementId`,
    REGISTRATION_STEP2_BUILD_PATH: (id: number) => `${DE_REGISTER_AR_REGISTRATION_PATH}/${id}`,
  },

  MANAGE_FINANCING: {
    APPLICATION_LIST_STEP1: DE_MANAGE_FINANCING_APPLICATION_PATH,
    APPLICATION_INVOICE_STEP2: `${DE_MANAGE_FINANCING_APPLICATION_PATH}/invoice/:successInvoiceId`,
    APPLICATION_INVOICE_STEP2_BUILD_PATH: (successInvoiceId: number) =>
      `${DE_MANAGE_FINANCING_APPLICATION_PATH}/invoice/${successInvoiceId}`,
    APPLICATION_AR_STEP2: `${DE_MANAGE_FINANCING_APPLICATION_PATH}/ar/:successArId`,
    APPLICATION_AR_STEP2_BUILD_PATH: (successArId: number) =>
      `${DE_MANAGE_FINANCING_APPLICATION_PATH}/ar/${successArId}`,
    BULK_APPLICATION_AR_STEP2: `${DE_MANAGE_FINANCING_APPLICATION_PATH}/ar-multiple-loan/:dealerAgreementId`,
    BULK_APPLICATION_AR_STEP2_BUILD_PATH: (dealerAgreementId: number) =>
      `${DE_MANAGE_FINANCING_APPLICATION_PATH}/ar-multiple-loan/${dealerAgreementId}`,

    CONFIRMATION_LIST: DE_MANAGE_FINANCING_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${DE_MANAGE_FINANCING_CONFIRMATION_PATH}/:loanId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (loanId: number) => `${DE_MANAGE_FINANCING_CONFIRMATION_PATH}/${loanId}`,

    BULK_CONFIRMATION_LIST: DE_MANAGE_FINANCING_BULK_CONFIRMATION_PATH,
    BULK_CONFIRMATION_DETAIL: `${DE_MANAGE_FINANCING_BULK_CONFIRMATION_PATH}/:multipleRequestLoansId`,
    BULK_CONFIRMATION_DETAIL_BUILD_PATH: (multipleRequestLoansId: number) =>
      `${DE_MANAGE_FINANCING_BULK_CONFIRMATION_PATH}/${multipleRequestLoansId}`,

    REQUEST_FOR_EARLY_REPAYMENT_LIST: DE_MANAGE_FINANCING_REQUEST_FOR_EARLY_REPAYMENT_PATH,
    REQUEST_FOR_EARLY_REPAYMENT_REGISTER: `${DE_MANAGE_FINANCING_REQUEST_FOR_EARLY_REPAYMENT_PATH}/register`,
    REQUEST_FOR_EARLY_REPAYMENT_DETAIL: `${DE_MANAGE_FINANCING_REQUEST_FOR_EARLY_REPAYMENT_PATH}/:earlyRepaymentRequestId`,
    REQUEST_FOR_EARLY_REPAYMENT_DETAIL_BUILD_PATH: (earlyRepaymentRequestId: number) =>
      `${DE_MANAGE_FINANCING_REQUEST_FOR_EARLY_REPAYMENT_PATH}/${earlyRepaymentRequestId}`,

    REQUEST_FOR_EXTENSION_LIST: DE_MANAGE_FINANCING_REQUEST_FOR_EXTENSION_PATH,
    REQUEST_FOR_EXTENSION_REGISTER: `${DE_MANAGE_FINANCING_REQUEST_FOR_EXTENSION_PATH}/register`,
    REQUEST_FOR_EXTENSION_DETAIL: `${DE_MANAGE_FINANCING_REQUEST_FOR_EXTENSION_PATH}/:extensionRequestId`,
    REQUEST_FOR_EXTENSION_DETAIL_BUILD_PATH: (extensionRequestId: number) =>
      `${DE_MANAGE_FINANCING_REQUEST_FOR_EXTENSION_PATH}/${extensionRequestId}`,

    SECURED_INVENTORY_UPDATE_LIST: DE_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH,
    SECURED_INVENTORY_UPDATE_REGISTER: `${DE_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH}/register`,
    SECURED_INVENTORY_UPDATE_DETAIL: `${DE_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH}/:inventorySummaryId`,
    SECURED_INVENTORY_UPDATE_DETAIL_BUILD_PATH: (inventorySummaryId: number) =>
      `${DE_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH}/${inventorySummaryId}`,
  },

  VIEW_TRANSACTION: {
    INVOICE_LIST: DE_VIEW_TRANSACTION_INVOICE_PATH,
    INVOICE_DETAIL: `${DE_VIEW_TRANSACTION_INVOICE_PATH}/:successInvoiceId`,
    INVOICE_DETAIL_BUILD_PATH: (successInvoiceId: number) => `${DE_VIEW_TRANSACTION_INVOICE_PATH}/${successInvoiceId}`,

    AR_LIST: DE_VIEW_TRANSACTION_AR_PATH,
    AR_DETAIL: `${DE_VIEW_TRANSACTION_AR_PATH}/:successArId`,
    AR_DETAIL_BUILD_PATH: (successArId: number) => `${DE_VIEW_TRANSACTION_AR_PATH}/${successArId}`,

    FINANCING_LIST: DE_VIEW_TRANSACTION_FINANCING_PATH,
    FINANCING_DETAIL: `${DE_VIEW_TRANSACTION_FINANCING_PATH}/:loanId`,
    FINANCING_DETAIL_BUILD_PATH: (loanId: number) => `${DE_VIEW_TRANSACTION_FINANCING_PATH}/${loanId}`,
  },

  COMPANY: {
    USER_LIST: DE_COMPANY_USER_PATH,
    USER_REGISTER: `${DE_COMPANY_USER_PATH}/register`,
    USER_DETAIL: `${DE_COMPANY_USER_PATH}/:userId`,
    USER_DETAIL_BUILD_PATH: (userId: number) => `${DE_COMPANY_USER_PATH}/${userId}`,

    AGREEMENT_LIST: DE_COMPANY_AGREEMENT_PATH,
    AGREEMENT_DETAIL: `${DE_COMPANY_AGREEMENT_PATH}/:dealerAgreementId`,
    AGREEMENT_DETAIL_BUILD_PATH: (dealerAgreementId: number) => `${DE_COMPANY_AGREEMENT_PATH}/${dealerAgreementId}`,
  },

  MY_ACCOUNT: {
    ACCOUNT_SETTINGS: DE_MY_ACCOUNT_PATH,
    EMAIL_NOTIFICATION_SETTING: `${DE_MY_ACCOUNT_EMAIL_NOTIFICATION_SETTING_PATH}/:financierId`,
    EMAIL_NOTIFICATION_SETTING_BUILD_PATH: (financierId: number) =>
      `${DE_MY_ACCOUNT_EMAIL_NOTIFICATION_SETTING_PATH}/${financierId}`,
  },
};
