import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

import type { TFunctionResult } from 'i18next';

export type HeaderBarDropDownStateType = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  currentValue: HeaderBarDropDownValueType;
  setCurrentValue: Dispatch<SetStateAction<any>>;
};

export type HeaderBarDropDownValueType = string | TFunctionResult;

export const HeaderBarDropDownContext = createContext<HeaderBarDropDownStateType | null>(null);
HeaderBarDropDownContext.displayName = 'HeaderBarDropDownContext';

export const useHeaderBarDropDownContext = () => {
  const context = useContext(HeaderBarDropDownContext);
  if (!context) {
    throw new Error('useHeaderBarDropDownContext should be used within HeaderBarDropDownContext.Provider');
  }

  return context;
};
