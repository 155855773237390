import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorFinancingOptionVOModel } from 'models/vo/AnchorFinancingOptionVO';
import { requestAnchorAgreementsList } from 'utils/http/api/anchor/anchor-agreements';
import type { AnchorAgreementListRequest } from 'utils/http/api/anchor/anchor-agreements/requests';
import { requestAnchorFinancingOptionsList } from 'utils/http/api/anchor/anchor-financing-opitons';
import type { SearchAcAnchorFinancingOptionsDTO } from 'utils/http/api/anchor/anchor-financing-opitons/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

import AnchorAgreementListTab from './AnchorAgreementListTab';
import AnchorFinancingOptionListTab from './AnchorFinancingOptionListTab';

const AC_ANCHOR_AGREEMENT_LIST_QS_KEY = 'ac-anchor-agreement-list';
const AC_FINANCING_OPTION_LIST_QS_KEY = 'ac-financing-option-list';

function AnchorAgreementList() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(AC_ANCHOR_AGREEMENT_LIST_QS_KEY);
  const { pageable: financingOptionPageable, setPageable: setFinancingOptionPageable } = usePageable(
    AC_FINANCING_OPTION_LIST_QS_KEY,
  );

  const [anchorAgreementList, setAnchorAgreementList] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const [searchAgreementNumber, setSearchAgreementNumber] = useState<string>('');

  const [anchorFinancingOptionList, setAnchorFinancingOptionList] =
    useState<Pageable<AnchorFinancingOptionVOModel[]>>();
  const [searchFinancingOptionNumber, setSearchFinancingOptionNumber] = useState<string>('');

  useEffect(() => {
    if (mounted) {
      setSearchAgreementNumber(getParsedSearchParams(AC_ANCHOR_AGREEMENT_LIST_QS_KEY).formSearchData.contractNo);
      fetchAcAgreementList(
        pageable.currentPage,
        pageable.sizePerPage,
        getParsedSearchParams(AC_ANCHOR_AGREEMENT_LIST_QS_KEY).formSearchData,
      );
      setAnchorFinancingOptionList(getParsedSearchParams(AC_FINANCING_OPTION_LIST_QS_KEY).formSearchData.contractNo);
      fetchAcFinancingOptionList(
        financingOptionPageable.currentPage,
        financingOptionPageable.sizePerPage,
        getParsedSearchParams(AC_FINANCING_OPTION_LIST_QS_KEY).formSearchData,
      );
    }

    return () => {};
  }, [mounted]);

  async function fetchAcAgreementList(
    pageNumber: number = 1,
    sizePerPage: number = 10,
    searchData: AnchorAgreementListRequest,
  ) {
    try {
      const anchorAgreementPage = await requestAnchorAgreementsList(pageNumber, sizePerPage, searchData);

      updateSearchParams({ ...searchData, pageNumber, rowCount: sizePerPage }, AC_ANCHOR_AGREEMENT_LIST_QS_KEY);

      setAnchorAgreementList(anchorAgreementPage);
      setPageable(anchorAgreementPage);
    } catch (error) {
      modal.show(error);
    }
  }

  const paginateAnchorAgreement = (page: number, sizePerPage: number) => {
    fetchAcAgreementList(
      page,
      sizePerPage,
      getParsedSearchParams(AC_ANCHOR_AGREEMENT_LIST_QS_KEY).formSearchData.contractNo,
    );
  };

  const onBlurUpdateAgreementNumber = (e: any) => {
    setSearchAgreementNumber(e.target.value);
  };

  const handleClickAnchorAgreementSearch = (e: any) => {
    e.preventDefault();
    fetchAcAgreementList(1, pageable.sizePerPage, { contractNo: searchAgreementNumber });
  };

  async function fetchAcFinancingOptionList(
    pageNumber: number = 1,
    sizePerPage: number = 10,
    searchData: SearchAcAnchorFinancingOptionsDTO,
  ) {
    try {
      const anchorAgreementPage = await requestAnchorFinancingOptionsList(pageNumber, sizePerPage, searchData);

      updateSearchParams({ ...searchData, pageNumber, rowCount: sizePerPage }, AC_FINANCING_OPTION_LIST_QS_KEY);
      setAnchorFinancingOptionList(anchorAgreementPage);
      setFinancingOptionPageable(anchorAgreementPage);
    } catch (error) {
      modal.show(error);
    }
  }

  const paginateFinancingOptionList = (page: number, sizePerPage: number) => {
    fetchAcFinancingOptionList(
      page,
      sizePerPage,
      getParsedSearchParams(AC_FINANCING_OPTION_LIST_QS_KEY).formSearchData.contractNo,
    );
  };

  const onBlurUpdateFinancingOptionNumber = (e: any) => {
    setSearchFinancingOptionNumber(e.target.value);
  };

  const handleClickAnchorFinancingOptionSearch = (e: any) => {
    e.preventDefault();
    fetchAcFinancingOptionList(1, financingOptionPageable.sizePerPage, { contractNo: searchFinancingOptionNumber });
  };

  return (
    <>
      <HeaderTitle title={t('text:Agreement')} />
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:Master_Agreement'),
            tabItemCount: anchorAgreementList?.totalElements ?? 0,
          },
          {
            tabName: t('text:Financing_Option'),
            tabItemCount: anchorFinancingOptionList?.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <AnchorAgreementListTab
            key="agreement"
            anchorAgreementList={anchorAgreementList?.content}
            searchAgreementNumber={searchAgreementNumber}
            onBlurUpdateAgreementNumber={onBlurUpdateAgreementNumber}
            handleClickAnchorAgreementSearch={handleClickAnchorAgreementSearch}
            pageable={pageable}
            paginateAnchorAgreement={paginateAnchorAgreement}
          />,
          <AnchorFinancingOptionListTab
            key="financing-option"
            anchorFinancingOptionList={anchorFinancingOptionList?.content}
            searchFinancingOptionNumber={searchFinancingOptionNumber}
            onBlurUpdateFinancingOptionNumber={onBlurUpdateFinancingOptionNumber}
            handleClickAnchorFinancingOptionSearch={handleClickAnchorFinancingOptionSearch}
            pageable={financingOptionPageable}
            paginateFinancingOptionList={paginateFinancingOptionList}
          />,
        ]}
      />
    </>
  );
}

export default AnchorAgreementList;
