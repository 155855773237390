import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { COLLATERAL_TYPE } from 'enums';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { requestFiAnchorAgreementByKeyUnionInterface } from 'utils/http/api/financier/anchor-agreements';
import type { FiAnchorAgreementInterfaceByKeyUnionRequest } from 'utils/http/api/financier/anchor-agreements/requests';
import { getHttpErrorExceptionMessage } from 'utils/text';

const useSearchInterfaceByKeyUnionModalController = (collateralType: COLLATERAL_TYPE) => {
  const { t } = useTranslation(['format']);

  const { register, getValues, setError, errors, clearErrors } = useForm<FiAnchorAgreementInterfaceByKeyUnionRequest>();

  const [searchedInterfaceData, setSearchedInterfaceData] = useState<AnchorAgreementDetailVOModel>(
    {} as AnchorAgreementDetailVOModel,
  );

  const [errorMessage, setErrorMessage] = useState<string>('');

  const canSearch = () => {
    const { anchorFinancierClientCode, currencyType } = getValues();

    const isEmptyAnchorFinancierClientCode = !anchorFinancierClientCode || anchorFinancierClientCode.trim() === '';

    if (isEmptyAnchorFinancierClientCode || !currencyType) {
      isEmptyAnchorFinancierClientCode && setError('anchorFinancierClientCode', {});
      !currencyType && setError('currencyType', {});

      setErrorMessage(t('text:Please_enter_the_Anchor_client_code_and_select_the_agreement_currency'));

      return false;
    }

    return true;
  };

  const handleSearchButtonClick = async () => {
    setErrorMessage('');
    clearErrors();

    if (!canSearch()) return;

    const data = getValues();

    data.collateralType = collateralType;

    try {
      const response = await requestFiAnchorAgreementByKeyUnionInterface(data);
      setSearchedInterfaceData(response);
    } catch (error: any) {
      setErrorMessage(getHttpErrorExceptionMessage(error));
      setSearchedInterfaceData({} as AnchorAgreementDetailVOModel);
    }
  };

  return {
    errorMessage,
    searchedInterfaceData,
    handleSearchButtonClick,
    register,
    errors,
  };
};

export default useSearchInterfaceByKeyUnionModalController;
