import type { CURRENCY_TYPE, ENTERPRISE_TYPE, WAITING_AR_APPROVAL_PHASE_STATUS } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface ArSummaryVO {
  arSummaryId: number;
  succeededArCount: number;
  currentApprovalCount: number;
  succeededArAmount: string;
  currentApprovalAmount: string;
  createDateTime: string;
  rejected: boolean;
  rejectReason: string;
  rejectedDateTime: string;
  rejectUserId: number;
  rejectUsername: string;
  financierEnterpriseId: number;
  financierEnterpriseName: string;
  anchorEnterpriseId: number;
  anchorClientId: number;
  anchorEnterpriseName: string;
  anchorClientName: string;
  anchorAgreementId: number;
  anchorContractNo: string;
  initialArApprovalDateTime: string;
  initialUploadEnterpriseId: number;
  initialUploadEnterpriseName: string;
  initialUploadUserId: number;
  initialUploadUserName: string;
  currentArApprovalPhaseStatus: WAITING_AR_APPROVAL_PHASE_STATUS;
  currentArApprovalDateTime: string;
  currentConfirmEnterpriseId: number;
  currentConfirmEnterpriseName: string;
  currentConfirmUserId: number;
  currentConfirmUserName: string;
  currentConfirmEnterpriseType: ENTERPRISE_TYPE;
  attachmentId: number;
  attachmentPath: string;
  attachmentFileName: string;
  inProgress: boolean;
  currencyType: CURRENCY_TYPE;
  rejectEnterpriseType: ENTERPRISE_TYPE;
}

export interface ArSummaryVOModel extends ArSummaryVO {
  succeededArAmount: BigNumber;
  currentApprovalAmount: BigNumber;
}

export function formattingToArSummaryVOModel(data: ArSummaryVO): ArSummaryVOModel {
  const { currentApprovalAmount, succeededArAmount } = data;

  return {
    ...data,
    currentApprovalAmount: sliceZeroDecimal(currentApprovalAmount),
    succeededArAmount: sliceZeroDecimal(succeededArAmount),
  };
}
