import i18next from 'i18next';

import { unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import type { ModalContextProps } from 'utils/modal/ModalContext';
import { ModalType } from 'utils/modal/ModalWrapper';

import { BkavCAPlugin } from '../../bkav/BkavCAPlugin';
import DigitalSignaturePluginDownloadModal from '../../modals/DigitalSignaturePluginDownloadModal';
import { GetVersionAPPCallBack } from '../getVersionAPPCallBack';

type CheckPluginGetCertProps = {
  data: any;
  modal: ModalContextProps;
  PDFInfos: { loanAgreementAttachment: string; loanFactoringNotificationAttachment: string };
  pdfSignCompletedCb: (loanAgreementFileList: FileList, loanFactoringNotificationFileList: FileList) => void;
};

export function CheckPluginGetCert({ data, modal, PDFInfos, pdfSignCompletedCb }: CheckPluginGetCertProps) {
  switch (data) {
    case '0':
      unShowLoadingUI();
      modal.show(<DigitalSignaturePluginDownloadModal />, {
        title: i18next.t('text:Digital_Signature'),
        modalType: ModalType.ALERT,
        closeBtnText: i18next.t('text:Close'),
      });

      break;
    case '1':
      BkavCAPlugin.GetVersion((data: any) =>
        GetVersionAPPCallBack({
          data,
          modal,
          PDFInfos,
          pdfSignCompletedCb,
        }),
      );

      break;
    default:
      /* swal('There was an error in authenticating the Plugin!'); */
      break;
  }
}
