import { useTranslation } from 'react-i18next';

import { CalculatorBigNumber } from 'utils/bigNumber';
import { getFinancingLimitValue, getRemainingApplicableLimitValue } from 'utils/logic';

import useAgreementViewModel from '../../models/agreement/useAgreementViewModel';

const SummaryOfPartner = () => {
  const { t } = useTranslation(['format']);
  const calculatorBigNumber = new CalculatorBigNumber();

  const { agreement } = useAgreementViewModel();

  const {
    loanLimitCheckType,
    loanLimitAmount,
    partiallyRepaidPrincipalAmount,
    requestedLoanAmount,
    disbursedLoanAmount,
    settlementWaitingInvoiceAmount,
    settlementWaitingInvoiceCount,
    disbursedLoanCount,
    totalUnsettledInvoiceAmount,
    totalUnsettledInvoiceCount,
  } = agreement;

  return (
    <div className="information-grid-form">
      <div className="row">
        <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">{getFinancingLimitValue(loanLimitCheckType, loanLimitAmount)}</div>
        </div>
        <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">
            {getRemainingApplicableLimitValue(loanLimitCheckType, {
              loanLimitAmount,
              partiallyRepaidPrincipalAmount,
              requestedLoanAmount,
            })}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Balance')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">
            {t('format:number', {
              value: calculatorBigNumber.add(disbursedLoanAmount).minus(partiallyRepaidPrincipalAmount).get(),
            })}
          </div>
        </div>
        <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Count')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">{t('format:number', { value: disbursedLoanCount })}</div>
        </div>
      </div>
      <div className="row">
        <div className="grid-form__item__label-wrap col-2">{t('text:Pending_Invoice_Amount')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">{t('format:number', { value: settlementWaitingInvoiceAmount })}</div>
        </div>
        <div className="grid-form__item__label-wrap col-2">{t('text:Number_of_Pending_Invoices')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">{t('format:number', { value: settlementWaitingInvoiceCount })}</div>
        </div>
      </div>
      <div className="row">
        <div className="grid-form__item__label-wrap col-2">{t('text:Not_-_Proceeded_Invoice_Amount')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">{t('format:number', { value: totalUnsettledInvoiceAmount })}</div>
        </div>
        <div className="grid-form__item__label-wrap col-2">{t('text:Number_of_Not_-_Proceeded_Invoices')}</div>
        <div className="grid-form__item__data__wrap col-4">
          <div className="grid-form__data">{t('format:number', { value: totalUnsettledInvoiceCount })}</div>
        </div>
      </div>
    </div>
  );
};

export default SummaryOfPartner;
