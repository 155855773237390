import type React from 'react';
import type { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { ANCHOR_AGREEMENT_STATUS, COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { SystemAnchorAgreementListRequest } from 'utils/http/api/system/anchor-agreements/requests';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const history = useHistory();
  const SEARCHED_REGISTRATION_COMPLETED_ANCHOR_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Client_Code'),
    },
    {
      headerText: t('text:Anchor_Tax_Code'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Expiration_Date'),
    },
    {
      headerText: t('text:Agreement_Suspension'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return {
    t,
    modal,
    history,
    SEARCHED_REGISTRATION_COMPLETED_ANCHOR_AGREEMENT_TABLE_HEADERS,
  };
};

interface SystemMonitorAnchorAgreementRegisteredTabProps {
  useForm: UseFormMethods<SystemAnchorAgreementListRequest>;
  registrationCompletedAnchorAgreementData: Pageable<AnchorAgreementVOModel[]>;
  onClickSearchRegistered: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  registrationCompletedAnchorAgreementPaginate(pageNumber: number, rowCount: number): Promise<void>;
  registrationCompletedAnchorAgreementPageable: PageableType;
}

function SystemMonitorAnchorAgreementRegisteredTab({
  useForm,
  registrationCompletedAnchorAgreementData,
  onClickSearchRegistered,
  registrationCompletedAnchorAgreementPaginate,
  registrationCompletedAnchorAgreementPageable,
}: SystemMonitorAnchorAgreementRegisteredTabProps) {
  const { t, SEARCHED_REGISTRATION_COMPLETED_ANCHOR_AGREEMENT_TABLE_HEADERS } = getConstants();

  const {
    register: registrationCompletedAnchorAgreementSearchFormRegister,
    reset: resetRegistrationCompletedAnchorAgreementSearchForm,
    control: registrationCompletedAnchorAgreementSearchFormControl,
  } = useForm;

  const renderSearchedRegistrationCompletedAnchorAgreementTableBody = (): JSX.Element[] | undefined => {
    return registrationCompletedAnchorAgreementData?.content?.map((item, index) => (
      <Tr key={index}>
        <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
        <Td data={item.financierName} />
        <Td data={item.financierClientName} />
        <Td data={item.financierClientCode} />
        <Td data={item.financierClientTaxCode} />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={item.startDate} format="date" />
        <Td data={item.expiryDate} format="date" />
        <Td
          data={t(`code:anchor-agreement-status.${item.anchorAgreementStatus}`)}
          format="code"
          className={getStatusTextClass('ANCHOR_AGREEMENT_STATUS', item.anchorAgreementStatus)}
        />
        <TdLink
          path={ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.anchorAgreementId)}
          state={{ supportedCollateralType: item.collateralType }}
        />
      </Tr>
    ));
  };

  return (
    <>
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={() => resetRegistrationCompletedAnchorAgreementSearchForm()}
          >
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={registrationCompletedAnchorAgreementSearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={registrationCompletedAnchorAgreementSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name="anchorClientCode" ref={registrationCompletedAnchorAgreementSearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Tax_Code')} />
              <SearchInput name="anchorClientTaxCode" ref={registrationCompletedAnchorAgreementSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name="contractNo" ref={registrationCompletedAnchorAgreementSearchFormRegister} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                ref={registrationCompletedAnchorAgreementSearchFormRegister}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Effective_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="startDateFrom"
                control={registrationCompletedAnchorAgreementSearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name="startDateTo"
                control={registrationCompletedAnchorAgreementSearchFormControl}
              />
              <SearchLabel label={t('text:Expiration_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="expiryDateFrom"
                control={registrationCompletedAnchorAgreementSearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name="expiryDateTo"
                control={registrationCompletedAnchorAgreementSearchFormControl}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Agreement_Suspension')} />
              <SearchSelect
                name="agreementStatus"
                ref={registrationCompletedAnchorAgreementSearchFormRegister}
                selectOptions={getSelectOptions<ANCHOR_AGREEMENT_STATUS>('ANCHOR_AGREEMENT_STATUS', 'ALL', true)}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                ref={registrationCompletedAnchorAgreementSearchFormRegister}
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearchRegistered}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {registrationCompletedAnchorAgreementPageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_REGISTRATION_COMPLETED_ANCHOR_AGREEMENT_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_REGISTRATION_COMPLETED_ANCHOR_AGREEMENT_TABLE_HEADERS.length}>
            {renderSearchedRegistrationCompletedAnchorAgreementTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination
          pageable={registrationCompletedAnchorAgreementPageable}
          paginate={registrationCompletedAnchorAgreementPaginate}
        />
      </div>
    </>
  );
}

export default SystemMonitorAnchorAgreementRegisteredTab;
