import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { PageableType } from 'hooks/usePageable';
import usePageable from 'hooks/usePageable';
import type { WaitingAnchorFinancingOptionVOModel } from 'models/vo/WaitingAnchorFinancingOptionVO';
import { requestAnchorWaitingAnchorFinancingOptionList } from 'utils/http/api/anchor/waiting-anchor-financing-options';
import useModal from 'utils/modal/useModal';

type WaitingAnchorFinancingOptionStateType = {
  waitingFinancingOption: WaitingAnchorFinancingOptionVOModel[];
  waitingFinancingOptionPageable: PageableType;
  fetchWaitingFinancingOption: (
    anchorFinancingOptionId: number,
    pageNumber?: number,
    rowCount?: number,
  ) => Promise<void>;
};

const WaitingFinancingOptionContext = createContext<WaitingAnchorFinancingOptionStateType | null>(null);

export const useWaitingAnchorFinancingOptionContext = () => {
  const context = useContext(WaitingFinancingOptionContext);
  if (!context) {
    throw Error('useWaitingOptionContext should be used within WaitingOptionContext.Provider');
  }

  return context;
};

type WaitingAnchorFinancingOptionProviderPropsType = {
  children: ReactNode;
};

const WaitingAnchorFinancingOptionProvider = ({ children }: WaitingAnchorFinancingOptionProviderPropsType) => {
  const { show: showModal } = useModal();

  const [waitingFinancingOption, setWaitingFinancingOption] = useState<WaitingAnchorFinancingOptionVOModel[]>([]);
  const { pageable: waitingFinancingOptionPageable, setPageable: setWaitingFinancingOptionPageable } = usePageable();

  const fetchWaitingFinancingOption = useCallback(
    async (anchorFinancingOptionId: number, pageNumber: number = 0, rowCount: number = 10) => {
      try {
        const waitingAgreement = await requestAnchorWaitingAnchorFinancingOptionList(pageNumber, rowCount, {
          anchorFinancingOptionId,
        });
        setWaitingFinancingOption([...waitingAgreement.content]);
        setWaitingFinancingOptionPageable(waitingAgreement);
      } catch (error) {
        showModal(error);
      }
    },
    [setWaitingFinancingOptionPageable, showModal],
  );

  const value = useMemo(
    () => ({
      waitingFinancingOption,
      waitingFinancingOptionPageable,
      fetchWaitingFinancingOption,
    }),
    [waitingFinancingOption, waitingFinancingOptionPageable, fetchWaitingFinancingOption],
  );

  return <WaitingFinancingOptionContext.Provider value={value}>{children}</WaitingFinancingOptionContext.Provider>;
};

export default WaitingAnchorFinancingOptionProvider;
