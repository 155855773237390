import type { MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { COLLATERAL_TYPE, LOAN_LIMIT_CHECK_TYPE, LOAN_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestAnchorStatisticsOfDealerAgreementsList } from 'utils/http/api/anchor/statistics-of-dealer-agreements';
import type { AnchorStatisticsOfDealerAgreementListRequest } from 'utils/http/api/anchor/statistics-of-dealer-agreements/requests';
import {
  getChannelTotalRemainingLimit,
  getTotalInterestRateByStaticsOfDealerAgreement,
  isNoneLoanLimitCheckType,
} from 'utils/logic';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams } from 'utils/searchParams';
import type { ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import { useAnchorOverviewByPartnerListChannelTabConstants } from './constants';
import { AC_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY } from '../../AnchorOverviewByPartnerList';

interface AnchorOverviewByPartnerListChannelTabProps {
  onClickSearch: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  overviewByPartnerPageData?: Pageable<StatisticsOfDealerAgreementVOModel[]>;
  pageable: PageableType;
  paginate: (selectedPageNumber: number, selectedRowCount: number) => void;
}

const [todayDate] = dayjs().format().split('T');

function AnchorOverviewByPartnerListChannelTab({
  onClickSearch,
  overviewByPartnerPageData,
  pageable,
  paginate,
}: AnchorOverviewByPartnerListChannelTabProps) {
  const { t } = useTranslation(['format']);
  const getProperty = useProperty<AnchorStatisticsOfDealerAgreementListRequest>();
  const modal = useModal();
  const { register, reset } = useFormContext();

  const {
    excelColumnsInvoice,
    invoiceMergedCells,
    invoiceMergedPdfTableHeader,
    invoicePdfTableHeader,
    INVOICE_TABLE_HEADER,
    INVOICE_BIG_TABLE_HEADER,
  } = useAnchorOverviewByPartnerListChannelTabConstants();

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();

    reset({});
  };

  const isAdhocValueShow = (
    adhocLimitAllowable: boolean,
    adhocLimitStartDate: string,
    adhocLimitEndDate: string,
    loanLimitCheckType?: keyof typeof LOAN_LIMIT_CHECK_TYPE,
  ): boolean => {
    if (!adhocLimitAllowable) return false;
    if (loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE) return false;

    if (adhocLimitStartDate <= todayDate && todayDate <= adhocLimitEndDate) {
      return true;
    } else {
      return false;
    }
  };

  const onClickExport = async (e: any) => {
    e.preventDefault();

    const data = getParsedSearchParams(AC_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY).formSearchData;
    data.collateralType = COLLATERAL_TYPE.INVOICE;

    try {
      const overviewByPartnerResponse = await requestAnchorStatisticsOfDealerAgreementsList(
        0,
        EXCEL_EXPORT_MAX_ROW_COUNT,
        data,
      );
      const overviewByPartnerPDFResponse = overviewByPartnerResponse.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: any[] = overviewByPartnerResponse.content.map(item => ({
        dealerClientName: tableValueManage(item.dealerClientName),
        contractNo: tableValueManage(item.contractNo),
        dealerCodeByAnchor: item.dealerCodeByAnchor,
        anchorAgreementContractNo: item.anchorAgreementContractNo,
        currencyType: tableValueManage(item.currencyType),
        startDate: tableValueManage(item.startDate, t('format:date', { value: item.startDate, key: 'date' })),
        expiryDate: tableValueManage(item.expiryDate, t('format:date', { value: item.expiryDate, key: 'date' })),
        basisInterestRate: getTotalInterestRateByStaticsOfDealerAgreement(item),
        loanLimitAmount: isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.loanLimitAmount,
        adhocLimitStartDate: isAdhocValueShow(
          item.adhocLimitAllowable,
          item.adhocLimitStartDate,
          item.adhocLimitEndDate,
        )
          ? t('format:date', { value: item.adhocLimitStartDate, key: 'date' })
          : '-',
        adhocLimitEndDate: isAdhocValueShow(item.adhocLimitAllowable, item.adhocLimitStartDate, item.adhocLimitEndDate)
          ? t('format:date', { value: item.adhocLimitEndDate, key: 'date' })
          : '-',
        adhocLimitAmount: isAdhocValueShow(
          item.adhocLimitAllowable,
          item.adhocLimitStartDate,
          item.adhocLimitEndDate,
          item.loanLimitCheckType,
        )
          ? item.adhocLimitAmount
          : '-',

        currentLoanBalanceAmount: item.currentLoanBalanceAmount,
        remainingLimit: isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.remainingLimit,
        totalRemainingLimit: isNoneLoanLimitCheckType(item.loanLimitCheckType)
          ? '-'
          : getChannelTotalRemainingLimit(
              item.adhocLimitStartDate,
              item.adhocLimitEndDate,
              item.remainingLimit,
              item.totalRemainingLimit,
              true,
            ),
        currentOverdueLoanCount: item.currentOverdueLoanCount > 0 ? 'Y' : 'N',
        dealerClientCode: tableValueManage(item.dealerClientCode),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumnsInvoice,
        options: {
          mergedCells: invoiceMergedCells,
          rowHeight: [
            { position: 1, height: 20 },
            { position: 2, height: 15 },
          ],
        },
      };

      const renderInvoicePdfTableBodyResult = (): JSX.Element[] | undefined => {
        return overviewByPartnerPDFResponse?.map((item, i) => (
          <Tr key={i} className="virtual-table-row">
            <Td data={item.dealerClientName} />
            <Td data={item.dealerClientCode} />
            <Td data={item.dealerCodeByAnchor} />
            <Td data={item.contractNo} />
            <Td data={item.anchorAgreementContractNo} />
            <Td data={item.currencyType} />
            <Td data={item.startDate} format="date" />
            <Td data={item.expiryDate} format="date" />
            <Td data={getTotalInterestRateByStaticsOfDealerAgreement(item)} />
            <Td data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.loanLimitAmount} format="number" />
            <Td
              data={
                isAdhocValueShow(
                  item.adhocLimitAllowable,
                  item.adhocLimitStartDate,
                  item.adhocLimitEndDate,
                  item.loanLimitCheckType,
                )
                  ? item.adhocLimitAmount
                  : '-'
              }
              format="number"
            />
            <Td
              data={
                isAdhocValueShow(item.adhocLimitAllowable, item.adhocLimitStartDate, item.adhocLimitEndDate)
                  ? tableValueManage(
                      item.adhocLimitStartDate,
                      t('format:date', { value: item.adhocLimitStartDate, key: 'date' }),
                    )
                  : '-'
              }
            />
            <Td
              data={
                isAdhocValueShow(item.adhocLimitAllowable, item.adhocLimitStartDate, item.adhocLimitEndDate)
                  ? tableValueManage(
                      item.adhocLimitEndDate,
                      t('format:date', { value: item.adhocLimitEndDate, key: 'date' }),
                    )
                  : '-'
              }
            />
            <Td data={item.currentLoanBalanceAmount} format="number" />
            <Td data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.remainingLimit} format="number" />
            <Td
              className="text-end"
              data={
                isNoneLoanLimitCheckType(item.loanLimitCheckType)
                  ? '-'
                  : getChannelTotalRemainingLimit(
                      item.adhocLimitStartDate,
                      item.adhocLimitEndDate,
                      item.remainingLimit,
                      item.totalRemainingLimit,
                    )
              }
            />
            <Td data={item.currentOverdueLoanCount > 0 ? 'Y' : 'N'} />
          </Tr>
        ));
      };

      const pdfExporterProps: PDFExporterProps = {
        mergedTableHeaders: [invoiceMergedPdfTableHeader],
        tableHeaders: invoicePdfTableHeader,
        tableBody: renderInvoicePdfTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name={getProperty('dealerClientName')} ref={register} />
              <SearchLabel label={t('text:Partner_Code_of_Anchor')} />
              <SearchInput name={getProperty('dealerCodeByAnchor')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
              <SearchInput name={getProperty('contractNo')} ref={register} />
              <SearchLabel label={t('text:Associated_Anchor_Master_Agreement_Number')} />
              <SearchInput name={getProperty('anchorAgreementContractNo')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name={getProperty('currencyType')}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchLabel label={t('text:Delinquent_Status')} />
              <SearchSelect
                name={getProperty('overdue')}
                selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                ref={register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {overviewByPartnerPageData?.totalElements ? overviewByPartnerPageData.totalElements : '0'}
          </p>
          <Button onClick={onClickExport} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={INVOICE_TABLE_HEADER} bigHeader={INVOICE_BIG_TABLE_HEADER} />
          <TableBody numOfCol={INVOICE_TABLE_HEADER.length}>
            {overviewByPartnerPageData?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.dealerClientName} />
                  <Td data={item.dealerClientCode} />
                  <Td data={item.dealerCodeByAnchor} />
                  <Td data={item.contractNo} />
                  <Td data={item.anchorAgreementContractNo} />
                  <Td data={item.currencyType} />
                  <Td data={item.startDate} format="date" />
                  <Td data={item.expiryDate} format="date" />
                  <Td data={getTotalInterestRateByStaticsOfDealerAgreement(item)} />
                  <Td
                    data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.loanLimitAmount}
                    format="number"
                  />
                  <Td
                    data={
                      isAdhocValueShow(
                        item.adhocLimitAllowable,
                        item.adhocLimitStartDate,
                        item.adhocLimitEndDate,
                        item.loanLimitCheckType,
                      )
                        ? item.adhocLimitAmount
                        : '-'
                    }
                    format="number"
                  />
                  <Td
                    data={
                      isAdhocValueShow(item.adhocLimitAllowable, item.adhocLimitStartDate, item.adhocLimitEndDate)
                        ? tableValueManage(
                            item.adhocLimitStartDate,
                            t('format:date', { value: item.adhocLimitStartDate, key: 'date' }),
                          )
                        : '-'
                    }
                  />
                  <Td
                    data={
                      isAdhocValueShow(item.adhocLimitAllowable, item.adhocLimitStartDate, item.adhocLimitEndDate)
                        ? tableValueManage(
                            item.adhocLimitEndDate,
                            t('format:date', { value: item.adhocLimitEndDate, key: 'date' }),
                          )
                        : '-'
                    }
                  />
                  {/* Financing Balance / Count */}
                  <td className="text-end">
                    {tableValueManage(
                      item.currentLoanBalanceAmount,
                      t('format:number', { value: item.currentLoanBalanceAmount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_AC.VIEW_TRANSACTION.FINANCING_LIST}
                      state={{
                        pathname: ROUTES_AC.VIEW_TRANSACTION.FINANCING_LIST,
                        state: {
                          dealerEnterpriseName: item.dealerName,
                          currencyType: item.currencyType,
                          loanStatusConditions: [LOAN_STATUS.DISBURSED, LOAN_STATUS.OVERDUE],
                          collateralType: item.collateralType,
                        },
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  {/* Remaining Limit */}
                  <Td
                    data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.remainingLimit}
                    format="number"
                  />
                  <Td
                    className="text-end"
                    data={
                      isNoneLoanLimitCheckType(item.loanLimitCheckType)
                        ? '-'
                        : getChannelTotalRemainingLimit(
                            item.adhocLimitStartDate,
                            item.adhocLimitEndDate,
                            item.remainingLimit,
                            item.totalRemainingLimit,
                          )
                    }
                  />
                  {/* Delinquent Status  */}
                  <Td data={item.currentOverdueLoanCount > 0 ? 'Y' : 'N'} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorOverviewByPartnerListChannelTab;
