export const stringToBoolean = (value: string): boolean => {
  switch (value) {
    case 'true' || 'TRUE':
      return true;
    case 'false' || 'FALSE':
      return false;
    default:
      // eslint-disable-next-line no-console
      console.warn('String to boolean :: Not certain value, please check parameter');

      return false;
  }
};
