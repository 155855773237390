import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash-es';

import InvoiceSummaryPhaseAccordion from 'components/invoice/InvoiceSummaryPhaseAccordion';
import PaymentInformationAccordion from 'components/stateless/Accordion/PaymentInformationAccordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import InvoiceAmountExceedModal from 'components/stateless/Modal/common/invoice/InvoiceAmountExceedModal';
import InvoiceRegistrationModalComplete from 'components/stateless/Modal/common/invoice/InvoiceRegistrationModalComplete';
import InvoiceRegistrationModalNew from 'components/stateless/Modal/common/invoice/InvoiceRegistrationModalNew';
import ShowPaymentDateGuideModal from 'components/stateless/Modal/common/invoice/ShowPaymentDateGuideModal';
import ValidationErrorModal from 'components/stateless/Modal/common/invoice/ValidationErrorModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import QuestionButton from 'components/stateless/QuestionButton';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import AdditionalColumnNameList from 'components/stateless/Table/AdditionalColumnNameList';
import AdditionalDataList from 'components/stateless/Table/AdditionalDataList';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE, EXECUTION_STATUS, LOAN_LIMIT_CHECK_TYPE, WAITING_INVOICE_APPROVAL_PHASE_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import useProgressStatus from 'hooks/useProgressStatus';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { FiscalYearSettingVOModel } from 'models/vo/FiscalYearSettingVO';
import type { InvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import type { InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import type { LastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import type { RegistrableLimitVOModel } from 'models/vo/RegistrableLimitVO';
import type { BigNumber } from 'utils/bigNumber';
import type { BoeTableStructure } from 'utils/boe';
import calculateBOEInformation from 'utils/boe';
import { getSum } from 'utils/calculate';
import { convertToServerDateFormat, getDayTerm, removeRangeFromHoliday } from 'utils/date/date';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestFiscalYearSettingByDealerAgreementId } from 'utils/http/api/common/fiscal-year-setting';
import { requestRegistrableLimitByDealerAgreement } from 'utils/http/api/common/registrable-limit';
import {
  requestFinancierAnchorAgreementRelatedDealerAgreement,
  requestFinancierDealerAgreementDetail,
} from 'utils/http/api/financier/dealer-agreements';
import { requestFinancierCalendarList } from 'utils/http/api/financier/financier-calendar';
import { requestFinancierInvoiceSummaryPhasesList } from 'utils/http/api/financier/invoice-approval-phases';
import {
  requestFinancierInvoicePhaseApprove,
  requestFinancierInvoicePhaseReject,
  requestFinancierInvoiceSummaryDetail,
  requestFinancierLastPhaseInvoicePhasesData,
} from 'utils/http/api/financier/invoice-summaries';
import type {
  FinancierApproveInvoicePhaseRequest,
  FinancierInvoiceSummaryRegisterInvoiceListRequest,
} from 'utils/http/api/financier/invoice-summaries/request';
import { requestFinancierWaitingInvoiceList } from 'utils/http/api/financier/waiting-invoices';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { invoiceOrArAmountInputValidate } from 'utils/logic';
import {
  INVOICE_PAYMENT_TO_DATE_RANGE,
  calculateInvoiceFinancingBalance,
  calculateInvoiceRemainingLimit,
  getInitialSettlementDateFunction,
  invoiceDuplicateValidator,
  isExceedInvoiceAmountWhenConfirmation,
  referenceNumberDuplicateValidator,
  repaymentDateSameValidator,
  setEnteredSettlementDate,
} from 'utils/logic/invoice';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { handleScrollLeft } from 'utils/scroll';
import { getSignIn } from 'utils/storage/LocalStorage';
import useValidation from 'utils/validation/useValidation';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import type { TFunction } from 'i18next';

const MAX_ROW_LENGTH = 500;

export const getFieldNames = (
  t: TFunction,
  options?: {
    currencyType?: CURRENCY_TYPE;
    hasQuestionButton?: boolean;
  },
) => {
  return {
    invoiceNumber: {
      name: t('text:Invoice_Number'),
      width: '180px',
      hasQuestionButton: false,
    },
    referenceNumber: {
      name: t('text:Invoice_Reference_Number'),
      width: '180px',
      hasQuestionButton: false,
    },
    invoiceIssuedDate: {
      name: t('text:Invoice_Issued_Date'),
      width: '180px',
      hasQuestionButton: false,
    },
    settlementDate: {
      name: t('text:Payment_Date'),
      width: '180px',
      hasQuestionButton: options?.hasQuestionButton,
    },
    invoiceAmount: {
      name: `${t('text:Invoice_Amount')} (${t('text:unit')}: ${options?.currencyType ?? ''})`,
      width: '180px',
      hasQuestionButton: false,
    },
  };
};

function FinancierInvoiceConfirmationDetail() {
  const history = useHistory();
  const mounted = useMounted();
  const { show: showModal, id: modalId } = useModal();
  const { t } = useTranslation(['format']);
  const { invoiceSummaryId } = useParams() as any;
  const { financierEnterpriseId } = useParams() as any;
  const isAdmin =
    getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN || getSignIn()?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR;

  const [invoiceSummaryData, setInvoiceSummaryData] = useState<InvoiceSummaryVOModel>({} as InvoiceSummaryVOModel);
  const [invoiceSummaryPhaseDataList, setInvoiceSummaryPhaseDataList] = useState<InvoicePhaseVOModel[]>([]);
  const [dealerAgreementData, setDealerAgreementData] = useState<DealerAgreementDetailVOModel>();
  const [editableInvoiceSummaryData, setEditableInvoiceSummaryData] = useState<LastInvoicePhaseVOModel>(
    {} as LastInvoicePhaseVOModel,
  );
  const [financierCommonSettingData, setFinancierCommonSettingData] = useState<FinancierCommonSettingVOModel>();
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회
  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementVOModel>();
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();
  const [registrableLimit, setRegistrableLimit] = useState<RegistrableLimitVOModel>();
  const [fiscalYearSettingData, setFiscalYearSettingData] = useState<FiscalYearSettingVOModel | null>(null);

  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState<BigNumber>('0');
  const [isExceedAmount, setIsExceedAmount] = useState<boolean>(false);
  const [boeTableData, setBoeTableData] = useState<BoeTableStructure[]>([]);
  const [boeNumber, setBoeNumber] = useState<string>('');
  const [isEditable] = useState<boolean>(!isAdmin);
  const [visibleAppendColumnBtn, setVisibleAppendColumnBtn] = useState(false);
  const [maximumColumn, setMaximumColumn] = useState(false);

  const { renderProgressStatus } = useProgressStatus(invoiceSummaryData);

  const invoiceRegisterTableRef = useRef<HTMLDivElement>(null);
  const prevColumnListLength = useRef(0);

  const date = new Date();
  const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];

  const todayDate = new Date(today[0], today[1], today[2]);
  const tomorrowDate = new Date(today[0], today[1], today[2] + 1);
  const minDate = financierCommonSettingData?.settlementDateIsTodayInvoiceUploadable ? todayDate : tomorrowDate;
  const toDate = new Date(today[0], today[1], today[2] + INVOICE_PAYMENT_TO_DATE_RANGE);
  const { register, control, errors, getValues, setValue, reset, trigger, watch } =
    useForm<FinancierApproveInvoicePhaseRequest>({
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
    });

  const { fields, remove } = useFieldArray({
    control,
    name: 'invoiceList',
  });

  // TODO: getValues()로 값을 가져올 지 watch로 가져올지...?
  const watchedAdditionalColumnNameList = watch('additionalColumnNameList');
  const watchedInvoiceList = watch('invoiceList');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchedInvoiceList[index],
    };
  });

  const fieldNames = getFieldNames(t, {
    currencyType: dealerAgreementData?.currencyType,
    hasQuestionButton: financierCommonSettingData?.invoiceWillBeSettledOnUploadDate,
  });

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: errors.invoiceList,
    rule: 'invoiceList',
  });

  const boeTableHeaders: HeaderType[] = [
    {
      headerText: t('text:Payment_Date'),
      className: 'text-left',
    },
    {
      headerText: t('text:Payment_Amount'),
      className: 'text-left',
    },
  ];

  const updateTotalInvoiceAmount = useCallback(() => {
    const sum = getSum(getValues().invoiceList, 'invoiceAmount');
    setTotalInvoiceAmount(sum);
  }, [getValues]);

  useEffect(() => {
    updateTotalInvoiceAmount();
  }, [fields, updateTotalInvoiceAmount]);

  useEffect(() => {
    if ((watchedAdditionalColumnNameList ?? []).length > 9) {
      setMaximumColumn(true);
      prevColumnListLength.current = watchedAdditionalColumnNameList?.length ?? 0;

      return;
    }
    if (prevColumnListLength.current === 10 && watchedAdditionalColumnNameList?.length === 9) {
      setMaximumColumn(false);
    }
    if (watchedAdditionalColumnNameList?.length === 0) {
      setMaximumColumn(false);
    }
  }, [watchedAdditionalColumnNameList]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    let initialSettlementDate: string | undefined = undefined;

    try {
      const [invoiceSummaryResponse, invoiceSummaryPhasePageResponse] = await Promise.all([
        requestFinancierInvoiceSummaryDetail(invoiceSummaryId),
        requestFinancierInvoiceSummaryPhasesList(invoiceSummaryId),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setInvoiceSummaryData(invoiceSummaryResponse);
        setInvoiceSummaryPhaseDataList(invoiceSummaryPhasePageResponse.content);
      });

      if (isEditable) {
        const editableInvoiceSummaryResponse = await requestFinancierLastPhaseInvoicePhasesData(invoiceSummaryId);

        if (editableInvoiceSummaryResponse.authorized) {
          const [
            dealerAgreementData,
            financierCommonSettingResponse,
            fetchFinancierHoliday,
            fetchRegistrableLimit,
            fiscalYearSettingResponse,
          ] = await Promise.all([
            requestFinancierDealerAgreementDetail(invoiceSummaryResponse.dealerAgreementId),
            requestFinancierSettingData(financierEnterpriseId),
            requestFinancierCalendarList(
              0,
              Number(getDayTerm(convertToServerDateFormat(minDate), convertToServerDateFormat(toDate))) + 1,
              {
                fromDate: convertToServerDateFormat(minDate),
                toDate: convertToServerDateFormat(toDate),
                holiday: true,
              },
            ),
            requestRegistrableLimitByDealerAgreement(invoiceSummaryResponse.dealerAgreementId),
            requestFiscalYearSettingByDealerAgreementId(invoiceSummaryResponse.dealerAgreementId),
          ]);

          if (dealerAgreementData.anchorAgreementId) {
            const anchorAgreementData = await requestFinancierAnchorAgreementRelatedDealerAgreement(
              invoiceSummaryResponse.dealerAgreementId,
            );
            setAnchorAgreementData(anchorAgreementData);
          }

          const getHolidayArrays = fetchFinancierHoliday.content.map(item => item.solarDate);

          initialSettlementDate = getInitialSettlementDateFunction(
            getHolidayArrays,
            financierCommonSettingResponse,
            todayDate,
            tomorrowDate,
            toDate,
          );

          setDealerAgreementData(dealerAgreementData);
          setRegistrableLimit(fetchRegistrableLimit);
          setFinancierCommonSettingData(financierCommonSettingResponse);
          setFinancierHoliday(fetchFinancierHoliday);
          setFiscalYearSettingData(fiscalYearSettingResponse);

          const fetchedInvoiceListContent = editableInvoiceSummaryResponse.invoiceList.content;
          if (fetchedInvoiceListContent.length !== 0) {
            reset({
              invoiceList: fetchedInvoiceListContent.map(item => {
                return {
                  billOfExchangeNo: item.billOfExchangeNo,
                  invoiceNumber: item.invoiceNumber,
                  referenceNumber: item.referenceNumber,
                  invoiceIssuedDate: item.invoiceIssuedDate,
                  settlementDate: setEnteredSettlementDate(
                    getHolidayArrays,
                    item.settlementDate,
                    initialSettlementDate,
                    financierCommonSettingResponse,
                  ),
                  invoiceAmount: item.invoiceAmount,
                  invoiceAdditionalDataList: item.invoiceAdditionalDataList ?? [],
                };
              }),
              additionalColumnNameList: fetchedInvoiceListContent[0].invoiceAdditionalDataList?.map(item => ({
                name: item.name,
              })),
            });

            setBoeNumber(fetchedInvoiceListContent[0] ? fetchedInvoiceListContent[0].billOfExchangeNo : '');
          } else {
            reset({
              invoiceList: [
                {
                  billOfExchangeNo: undefined,
                  invoiceNumber: undefined,
                  referenceNumber: undefined,
                  invoiceIssuedDate: undefined,
                  settlementDate: initialSettlementDate,
                  invoiceAmount: undefined,
                },
              ],
            });
          }
        }

        ReactDOM.unstable_batchedUpdates(() => {
          setEditableInvoiceSummaryData(editableInvoiceSummaryResponse);
          setInitialSettlementDate(initialSettlementDate);
        });
      }
    } catch (error: any) {
      showModal(error);
    }
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const arr: number[] = [];
      fields.forEach((el, index) => arr.push(index));
      setCheckedRows(arr);
    } else {
      setCheckedRows([]);
    }
  };

  const handleCheckChange = (e: any, index: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const appendRow = (e: any) => {
    e.preventDefault();
    if (fields.length >= MAX_ROW_LENGTH) {
      showModal(
        <h6>
          {t('text:Allowed_N_rows_at_a_time', { number: MAX_ROW_LENGTH })} {t('text:Please_check_the_data_again')}
        </h6>,
      );
    } else {
      const invoiceAdditionalDataList: FinancierInvoiceSummaryRegisterInvoiceListRequest['invoiceAdditionalDataList'] =
        getValues().invoiceList?.[0]?.invoiceAdditionalDataList?.map(() => ({ value: '', name: '' }));

      setValue('invoiceList', [
        ...getValues().invoiceList,
        {
          billOfExchangeNo: boeNumber,
          invoiceNumber: undefined,
          referenceNumber: undefined,
          invoiceIssuedDate: undefined,
          settlementDate: initialSettlementDate,
          invoiceAmount: undefined,
          invoiceAdditionalDataList,
        },
      ]);
    }
  };

  const appendColumn = () => {
    const { invoiceList, additionalColumnNameList } = getValues();

    const appendAdditionalData = invoiceList?.map(invoiceItem => ({
      ...invoiceItem,
      invoiceAdditionalDataList: (invoiceItem.invoiceAdditionalDataList ?? []).concat({ value: '', name: '' }),
    }));
    const appendColumnNameList = [...(additionalColumnNameList ?? []), { name: '' }];

    setValue('invoiceList', appendAdditionalData);
    setValue('additionalColumnNameList', appendColumnNameList);

    handleScrollLeft(invoiceRegisterTableRef, invoiceRegisterTableRef?.current?.scrollWidth);
  };

  const removeRow = (e: any) => {
    e.preventDefault();
    const upDateInValidState = () => {
      let i = 0;
      const temp = [...checkedValidation];

      for (const index of checkedRows) {
        temp.splice(index - i, 1);
        i++;
      }

      return temp;
    };
    if (checkedRows.length === fields.length) {
      reset({
        invoiceList: [
          {
            billOfExchangeNo: boeNumber,
            invoiceNumber: undefined,
            referenceNumber: undefined,
            invoiceIssuedDate: undefined,
            settlementDate: initialSettlementDate,
            invoiceAmount: undefined,
          },
        ],
      });
      setCheckedValidation([]);
    } else {
      setCheckedValidation(upDateInValidState());
      remove(checkedRows);
    }
    setCheckedRows([]);
  };

  const removeColumn = (columnIndex: number) => {
    const prevScrollLeft = invoiceRegisterTableRef?.current?.scrollLeft ?? 0;

    const { invoiceList, additionalColumnNameList } = getValues();

    const removeAdditionalData = invoiceList.map(invoiceItem => ({
      ...invoiceItem,
      invoiceAdditionalDataList: invoiceItem.invoiceAdditionalDataList?.filter((_, index) => index !== columnIndex),
    }));
    const removeColumnNameList = additionalColumnNameList?.filter((_, index) => index !== columnIndex);

    setValue('invoiceList', removeAdditionalData);
    setValue('additionalColumnNameList', removeColumnNameList);

    handleScrollLeft(invoiceRegisterTableRef, prevScrollLeft);
  };

  const visibleAppendColumnButton = () => {
    setVisibleAppendColumnBtn(true);
  };

  const invisibleAppendColumnButton = () => {
    setVisibleAppendColumnBtn(false);
  };

  const onClickReject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    let reason = '';

    const onClickConfirmOnReject = async (): Promise<void> => {
      try {
        await requestFinancierInvoicePhaseReject({
          invoiceSummaryId: invoiceSummaryId,
          reason: reason,
        });

        showModal(<h6>{t('text:The_registration_request_has_been_rejected')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => {
            fetchAll();
          },
        });
      } catch (error: any) {
        showModal(error);
      }
    };

    if (
      invoiceSummaryData.executionStatus === EXECUTION_STATUS.RUNNING ||
      invoiceSummaryData.executionStatus === EXECUTION_STATUS.REGISTERED
    ) {
      showModal(<h6>{t('text:The_invoice_confirmation_has_already_been_requested_and_is_in_progress')}</h6>);
    } else {
      showModal(
        <>
          <h6>
            {t('text:Would_you_like_to_reject_the_registration_request?')}
            <br />
            {t(
              'text:If_you_reject_the_registration_request_you_will_have_to_proceed_with_the_invoice_registration_again',
            )}
            <br />
            {t('text:Please_enter_the_reason_for_the_rejection_below')}
          </h6>
          <ReasonModal
            modalId={modalId}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: () => onClickConfirmOnReject(),
        },
      );
    }
  };

  const mappingAdditionalColumnNameAndValue = () => {
    const { additionalColumnNameList, invoiceList } = getValues();

    return invoiceList?.map(invoiceItem => ({
      ...invoiceItem,
      invoiceAdditionalDataList: invoiceItem?.invoiceAdditionalDataList?.map((item, index) => ({
        ...item,
        name: additionalColumnNameList?.[index].name ?? '',
      })),
    }));
  };

  const getRequestValues = () => {
    const { additionalColumnNameList } = getValues();

    const noInvoiceAdditionalDataList = (additionalColumnNameList?.length ?? 0) === 0;
    if (noInvoiceAdditionalDataList) return getValues();

    const mappedAdditionalData = mappingAdditionalColumnNameAndValue();

    return { ...getValues(), invoiceList: mappedAdditionalData };
  };

  const triggerValidationForAdditionalColumnName = async () => {
    if (!watchedAdditionalColumnNameList) return;
    let validationResult = true;

    for (let i = 0; i < watchedAdditionalColumnNameList?.length; i++) {
      const isPassedRequiredValidationForAdditionalColumnName = await trigger(`additionalColumnNameList[${i}].name`);
      if (!isPassedRequiredValidationForAdditionalColumnName) {
        validationResult = false;
      }
    }

    if (!validationResult) showModal(<h6>{t('text:Enter_the_names_for_all_of_the_added_columns')}</h6>);

    return validationResult;
  };

  const onClickRegister = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    const showInvoiceRegistrationResultModal = (
      resultDataList: LastInvoicePhaseVOModel,
      count: number,
      amount: BigNumber,
    ) => {
      showModal(
        <InvoiceRegistrationModalComplete
          dataList={resultDataList}
          totalCount={count}
          totalAmount={amount}
          currencyType={invoiceSummaryData?.currencyType}
        />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_FI.REGISTER_INVOICE.CONFIRMATION_LIST),
        },
      );
    };

    const data = getValues();

    setIsExceedAmount(false);

    const executeRegister = () => {
      return new Promise<void>(resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1);
      }).then(async () => {
        if (!(await triggerValidationForAdditionalColumnName())) {
          unShowLoadingUI();

          return;
        }

        await trigger().then(result => {
          setCheckedValidation(data.invoiceList.map(() => true));
          unShowLoadingUI();
          // validation #1. duplicate invoice Number / invoice amount = 0 / missing items
          if (!result) {
            showModal(<ValidationErrorModal />, {
              modalSize: ModalSize.NONE,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:Close'),
            });

            return; // FE validation error 있으면 return
          }
          //  validation #2. total amount check
          else if (
            dealerAgreementData?.loanLimitCheckType !== LOAN_LIMIT_CHECK_TYPE.LOAN &&
            isExceedInvoiceAmountWhenConfirmation(
              dealerAgreementData,
              editableInvoiceSummaryData.amount,
              totalInvoiceAmount,
            )
          ) {
            setIsExceedAmount(true);
            showModal(<InvoiceAmountExceedModal />, {
              modalSize: ModalSize.NONE,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:Close'),
            });
          } else if (
            invoiceSummaryData.executionStatus === EXECUTION_STATUS.RUNNING ||
            invoiceSummaryData.executionStatus === EXECUTION_STATUS.REGISTERED
          ) {
            showModal(<h6>{t('text:The_invoice_confirmation_has_already_been_requested_and_is_in_progress')}</h6>);
          } else {
            showModal(
              <InvoiceRegistrationModalNew
                dataList={data}
                totalCount={fields.length}
                totalAmount={totalInvoiceAmount}
                currencyType={invoiceSummaryData?.currencyType}
              />,
              {
                modalSize: ModalSize.XL,
                modalType: ModalType.CONFIRM,
                closeBtnText: t('text:Cancel'),
                confirmBtnText: t('text:Confirm'),
                confirmBtnCb: async () => {
                  try {
                    const requestedData = getRequestValues();
                    requestedData.invoiceSummaryId = Number(invoiceSummaryId);
                    const approveResult = await requestFinancierInvoicePhaseApprove(requestedData);

                    showInvoiceRegistrationResultModal(approveResult, approveResult.count, approveResult.amount);
                  } catch (error: any) {
                    showModal(error);
                  }
                },
              },
            );
          }
        });
      });
    };
    await executeRegister();
  };

  const onClickStatus = () => {
    showModal(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(WAITING_INVOICE_APPROVAL_PHASE_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="WAITING_INVOICE_APPROVAL_PHASE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const handleInvoiceSummaryPhaseAccordionClick = (invoiceSummaryPhase: InvoicePhaseVOModel) => {
    return requestFinancierWaitingInvoiceList(0, MAX_ROW_LENGTH, invoiceSummaryPhase.waitingInvoiceApprovalPhaseId);
  };

  const refreshBoeTable = useCallback((): void => {
    const { invoiceList } = getValues();

    if (invoiceList && Array.isArray(invoiceList)) {
      const boeTableDatas = calculateBOEInformation<FinancierInvoiceSummaryRegisterInvoiceListRequest>(invoiceList);

      setBoeTableData(boeTableDatas);
    }
  }, [getValues]);

  const renderBoeTable = (): JSX.Element[] | undefined => {
    return boeTableData.map((item, index) => (
      <Tr key={index}>
        <Td data={item.settlementDate} />
        <Td data={item.invoiceAmount} format="number" />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Invoice_Confirmation_Details')} />
      {invoiceSummaryData.rejected && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_invoice_registration_request_has_been_rejected'),
            t('text:Please_check_the_reason_below_for_the_details'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={invoiceSummaryData.rejectReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Registration_Status')}
                value={renderProgressStatus()}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickStatus}
              />
              <FormValue
                col={3}
                className="information-form__input border-none pointfont"
                label={t('text:Number_of_Valid_Invoices')}
                value={invoiceSummaryData.rejected ? '-' : invoiceSummaryData.currentApprovalCount}
              />
              <FormValue
                col={3}
                className="information-form__input border-none pointfont"
                label={t('text:Total_Amount_of_Valid_Invoices')}
                value={
                  invoiceSummaryData.rejected
                    ? '-'
                    : t('format:number', { value: invoiceSummaryData.currentApprovalAmount })
                }
              />
            </div>
            <div className="row">
              <FormValue label={t('text:BOE_Number')} value={invoiceSummaryData.billOfExchangeNo} col={3} />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Partner_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Partner_Master_Agreement_Number')}
                value={invoiceSummaryData.dealerContractNo}
              />
              <div className="col-3" />
              <FormValue col={3} label={t('text:Partner_Name')} value={invoiceSummaryData.dealerClientName} />
              <FormValue col={3} label={t('text:Currency')} value={invoiceSummaryData.currencyType} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Associated_Master_Agreement_of_Anchor_Number')}
                value={invoiceSummaryData.anchorContractNo}
              />
              <FormValue label={t('text:Associated_Anchor_Name')} value={invoiceSummaryData.anchorClientName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {isEditable &&
        editableInvoiceSummaryData.authorized &&
        !editableInvoiceSummaryData.inProgress &&
        !invoiceSummaryData.rejected &&
        dealerAgreementData?.loanLimitCheckType !== LOAN_LIMIT_CHECK_TYPE.LOAN && (
          <div className="content-area">
            <SectionTitle title={t('text:Invoice_Registration_Limit')} />
            <div className="information-form__corporation">
              <div className="corporation-section">
                <div className="row">
                  <div className="col-6">{t('text:Financing_Limit')} ①</div>
                  <div className="col-6">{t('format:number', { value: dealerAgreementData?.loanLimitAmount })}</div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Financing_Balance')} ②</div>
                  <div className="col-6">{calculateInvoiceFinancingBalance(dealerAgreementData)}</div>
                </div>
              </div>
              <div className="corporation-section">
                <div className="row">
                  <div className="col-6">{t('text:Remaining_Limit')} ③ = ① - ②</div>
                  <div className="col-6">{calculateInvoiceRemainingLimit(dealerAgreementData)}</div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Registration_Confirmed_Invoice')} ④</div>
                  <div className="col-6">
                    {t('format:number', { value: dealerAgreementData?.settlementWaitingInvoiceAmount })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Registration_Requested_Invoice')} ⑤</div>
                  <div className="col-6 text-end">
                    {t('format:number', {
                      value: dealerAgreementData?.registeredWaitingInvoiceAmount,
                    })}
                  </div>
                </div>
              </div>
              <div className="corporation-section">
                <div className="row">
                  <div className="col-6">{t('text:Limit_Available_for_Registration')} ⑥ = ③ - ④ - ⑤</div>
                  <div className="col-6 text-end blackfont">
                    {t('format:number', { value: dealerAgreementData?.totalLimitAmountWithoutAdHoc })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Exceed_Limit')} ⑦</div>
                  <div className="col-6 text-end">
                    {t('format:number', {
                      value: dealerAgreementData?.exceedLimit,
                    })}
                  </div>
                </div>
              </div>
              {editableInvoiceSummaryData?.adhocLimitAllowable && (
                <>
                  <div className="corporation-section">
                    <div className="row">
                      <div className="col-6">{t('text:Ad_hoc_Limit_Amount')} ⑧</div>
                      <div className="col-6">
                        {tableValueManage(
                          dealerAgreementData?.adhocLimitAmount,
                          t('format:number', { value: dealerAgreementData?.adhocLimitAmount }),
                        )}
                      </div>
                    </div>
                    <div className="sub-container">
                      <div className="sub-title">{t('text:Ad_hoc_Limit_Start_Date')}</div>
                      <div className="sub-content">
                        {tableValueManage(
                          dealerAgreementData?.adhocLimitStartDate,
                          t('format:date', {
                            value: dealerAgreementData?.adhocLimitStartDate,
                            key: 'date',
                          }),
                        )}
                      </div>
                    </div>
                    <div className="sub-container">
                      <div className="sub-title">{t('text:Ad_hoc_Limit_End_Date')}</div>
                      <div className="sub-content">
                        {tableValueManage(
                          dealerAgreementData?.adhocLimitEndDate,
                          t('format:date', {
                            value: dealerAgreementData?.adhocLimitEndDate,
                            key: 'date',
                          }),
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="corporation-section">
                    <div className="row">
                      <div className="col-6">{t('text:Limit_Available_for_Registration_with_Ad_hoc')} ⑨ = ⑥ + ⑧</div>
                      <div className="col-6 text-end">
                        {t('format:number', { value: dealerAgreementData?.totalLimitAmountWithAdHoc })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      <div className="content-area">
        <SectionTitle title={t('text:Invoice_List')} />
        {isEditable &&
          editableInvoiceSummaryData.authorized &&
          !editableInvoiceSummaryData.inProgress &&
          !invoiceSummaryData.rejected && (
            <div className="editable-section">
              <div className="clearfix">
                <div className="mb-3 position-relative" data-testid="button-groups">
                  <Button
                    size={ButtonSizeEnum.SM}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={removeRow}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                  <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  <div
                    className="append-column-btn__area"
                    onMouseEnter={visibleAppendColumnButton}
                    onMouseLeave={invisibleAppendColumnButton}
                  />
                  {visibleAppendColumnBtn && !maximumColumn && (
                    <Button
                      size={ButtonSizeEnum.XS}
                      onClick={appendColumn}
                      className="append-column-btn flex-center"
                      onMouseEnter={visibleAppendColumnButton}
                      variant={ButtonVariantEnum.OUTLINED}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  )}
                </div>
                {invoiceSummaryData && (
                  <>
                    <div className="table-overflow-scroll" ref={invoiceRegisterTableRef}>
                      <table className="table-border" data-testid="register-table">
                        <colgroup>
                          <col style={{ width: '70px' }} />
                          <col className="d-none" />
                          {values(fieldNames).map(({ width }, index) => (
                            <col key={index} style={{ width }} />
                          ))}
                          {getValues().additionalColumnNameList?.map((_, index) => (
                            <col key={index} style={{ width: '180px' }} />
                          ))}
                          {checkedValidation.length > 0 && <col style={{ width: '180px' }} />}
                        </colgroup>
                        <thead data-column-type="append">
                          <tr>
                            <th scope="col">
                              <div className="text-center">
                                <input
                                  className="form-check-input m-0"
                                  type="checkbox"
                                  value=""
                                  id="allCheck2"
                                  onChange={handleCheckAll}
                                  checked={checkedRows.length === fields.length}
                                />
                              </div>
                            </th>
                            <th className="d-none" scope="col">
                              {t('text:BOE_Number')}
                            </th>
                            {values(fieldNames).map(({ name, hasQuestionButton }, index, { length }) => (
                              <th
                                key={index}
                                scope="col"
                                onMouseEnter={() => {
                                  if (index !== length - 1 || Number(watchedAdditionalColumnNameList?.length) > 0)
                                    return;
                                  visibleAppendColumnButton();
                                }}
                                onMouseLeave={() => {
                                  if (index !== length - 1 || Number(watchedAdditionalColumnNameList?.length) > 0)
                                    return;
                                  invisibleAppendColumnButton();
                                }}
                              >
                                {name}
                                {hasQuestionButton && (
                                  <QuestionButton onClick={() => showModal(<ShowPaymentDateGuideModal />)} />
                                )}
                              </th>
                            ))}
                            <AdditionalColumnNameList
                              control={control}
                              register={register}
                              errors={errors}
                              visibleAppendColumnButton={visibleAppendColumnButton}
                              invisibleAppendColumnButton={invisibleAppendColumnButton}
                              removeColumn={removeColumn}
                            />
                            {checkedValidation.length > 0 && (
                              <th
                                scope="col"
                                onMouseEnter={visibleAppendColumnButton}
                                onMouseLeave={invisibleAppendColumnButton}
                              >
                                {t('text:Validation_Result')}
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {controlledFields.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <td className="bg-sub100" key={index}>
                                  <div className="text-center">
                                    <input
                                      className="form-check-input m-0"
                                      type="checkbox"
                                      onChange={e => handleCheckChange(e, index)}
                                      checked={checkedRows.includes(index)}
                                    />
                                  </div>
                                </td>
                                <td className="d-none">
                                  <input
                                    name={`invoiceList.${index}.billOfExchangeNo`}
                                    defaultValue={item.billOfExchangeNo}
                                    ref={register()}
                                  />
                                </td>
                                <td className={getValidationClassName('invoiceNumber', 'td', index)}>
                                  <input
                                    className={getValidationClassName('invoiceNumber', 'input', index)}
                                    name={`invoiceList.${index}.invoiceNumber`}
                                    defaultValue={item.invoiceNumber}
                                    ref={register({
                                      required: true,
                                      validate: value =>
                                        invoiceDuplicateValidator(
                                          getValues().invoiceList,
                                          value,
                                          index,
                                          fiscalYearSettingData,
                                        ),
                                    })}
                                  />
                                </td>
                                <td className={getValidationClassName('referenceNumber', 'td', index)}>
                                  <input
                                    className={getValidationClassName('referenceNumber', 'input', index)}
                                    name={`invoiceList.${index}.referenceNumber`}
                                    defaultValue={item.referenceNumber}
                                    ref={register({
                                      required: registrableLimit?.referenceNumberDuplicationCheck ? true : undefined,
                                      validate: value =>
                                        registrableLimit?.referenceNumberDuplicationCheck
                                          ? referenceNumberDuplicateValidator(getValues().invoiceList, value, index)
                                          : undefined,
                                    })}
                                  />
                                </td>
                                <td className={getValidationClassName('invoiceIssuedDate', 'td', index)}>
                                  <Controller
                                    control={control}
                                    name={`invoiceList.${index}.invoiceIssuedDate`}
                                    defaultValue={item.invoiceIssuedDate}
                                    onClick={() => handleScrollLeft(invoiceRegisterTableRef)}
                                    render={field => (
                                      <ReactDatePicker
                                        field={field}
                                        className={getValidationClassName('invoiceIssuedDate', 'input', index)}
                                      />
                                    )}
                                    rules={{ required: true }}
                                  />
                                </td>
                                <td className={getValidationClassName('settlementDate', 'td', index)}>
                                  <Controller
                                    control={control}
                                    name={`invoiceList.${index}.settlementDate`}
                                    defaultValue={item.settlementDate}
                                    onClick={() => handleScrollLeft(invoiceRegisterTableRef)}
                                    render={field => (
                                      <ReactDatePicker
                                        field={field}
                                        className={getValidationClassName('settlementDate', 'input', index)}
                                        minDate={minDate}
                                        maxDate={toDate}
                                        excludeDates={removeRangeFromHoliday(financierHoliday?.content)}
                                        disabled={financierCommonSettingData?.invoiceWillBeSettledOnUploadDate}
                                      />
                                    )}
                                    rules={{
                                      required: true,
                                      validate: anchorAgreementData?.billOfExchangeNoRequired
                                        ? () => repaymentDateSameValidator(getValues().invoiceList, index)
                                        : undefined,
                                    }}
                                  />
                                </td>
                                <td className={getValidationClassName('invoiceAmount', 'td', index)}>
                                  <NumericFormatInput
                                    control={control}
                                    name={`invoiceList.${index}.invoiceAmount`}
                                    numberType="bigNumber"
                                    className="grid-input"
                                    defaultValue={item.invoiceAmount}
                                    currencyType={invoiceSummaryData?.currencyType}
                                    textAlign="text-end"
                                    error={errors.invoiceList && errors.invoiceList[index]?.invoiceAmount}
                                    rules={{
                                      validate: value =>
                                        invoiceOrArAmountInputValidate(
                                          value,
                                          invoiceSummaryData?.currencyType,
                                          'Invoice',
                                        ),
                                      required: true,
                                      min: 0.01,
                                    }}
                                  />
                                </td>
                                <AdditionalDataList
                                  rowIndex={index}
                                  control={control}
                                  register={register}
                                  fieldName={{ rowName: 'invoiceList', columnName: 'invoiceAdditionalDataList' }}
                                />
                                {checkedValidation[index]
                                  ? getValidationResult(index, isExceedAmount)
                                  : checkedValidation.length > 0 && <td />}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="grid-total mb-4" data-testid="total">
                      {t('text:Total')} : {t('format:number', { value: totalInvoiceAmount })}
                    </div>
                    <PaymentInformationAccordion id="payment-information" handleRefresh={refreshBoeTable}>
                      <>
                        <GuideMessage
                          message={[
                            t(
                              'text:Invoices_missing_the_Payment_Date_or_the_Invoice_Amount_will_not_be_reflected_in_the_payment_information_below',
                            ),
                          ]}
                          isImportContentArea={true}
                        />
                        <TableBorder>
                          <TableHeader header={boeTableHeaders} />
                          <TableBody numOfCol={boeTableHeaders.length}>{renderBoeTable()}</TableBody>
                        </TableBorder>
                      </>
                    </PaymentInformationAccordion>
                  </>
                )}
                <div className="flex-end mt-3">
                  <Button
                    size={ButtonSizeEnum.LG}
                    onClick={onClickReject}
                    className="me-2"
                    color={ButtonColorEnum.SECONDARY}
                    variant={ButtonVariantEnum.OUTLINED}
                  >
                    {t('text:Reject')}
                  </Button>
                  <Button size={ButtonSizeEnum.LG} onClick={onClickRegister}>
                    {t('text:Register')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        {invoiceSummaryPhaseDataList.map((invoiceSummaryPhase, index) => (
          <InvoiceSummaryPhaseAccordion
            key={index}
            invoiceSummary={invoiceSummaryData}
            invoiceSummaryPhase={invoiceSummaryPhase}
            onAccordionClick={() => handleInvoiceSummaryPhaseAccordionClick(invoiceSummaryPhase)}
          />
        ))}
      </div>
    </>
  );
}

export default FinancierInvoiceConfirmationDetail;
