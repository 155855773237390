import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { PotentialPartnerFinancingApplicationVOModel } from 'models/vo/PotentialPartnerFinancingApplicationVO';

import FinancierScPartnerAcquisitionListSearchForm from './sections/searchForm';
import FinancierScPartnerAcquisitionListTable from './sections/table';

export type AcquisitionTabType = 'submitted' | 'inProgress' | 'rejected' | 'completed';

interface FinancierScPartnerAcquisitionListTabWrapperProps {
  data: Pageable<PotentialPartnerFinancingApplicationVOModel[]>;
  tabType: AcquisitionTabType;
  pageable: PageableType;
  listFetchUtils: () => {
    onClickSearch: () => Promise<void>;
    paginate: (pageNumber: number, rowCount: number) => Promise<void>;
  };
}

function FinancierScPartnerAcquisitionListTabWrapper({
  data,
  listFetchUtils,
  tabType,
  pageable,
}: FinancierScPartnerAcquisitionListTabWrapperProps) {
  const { onClickSearch, paginate } = listFetchUtils();

  return (
    <>
      <FinancierScPartnerAcquisitionListSearchForm tab={tabType} onClickSearch={onClickSearch} />
      <FinancierScPartnerAcquisitionListTable tab={tabType} data={data} pageable={pageable} paginate={paginate} />
    </>
  );
}

export default FinancierScPartnerAcquisitionListTabWrapper;
