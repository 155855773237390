import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import RequiredAdditionalDocumentList from 'components/loan/RequiredAdditionalDocumentList';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { SignInModel } from 'models/SignInModel';
import { isLoanDisbursed } from 'utils/status';
import { getSignIn } from 'utils/storage/LocalStorage';

import DealerFinancingDetailAdditionalDocuments from './sections/additional-documents';
import DealerFinancingDetailArInformation from './sections/ar-information';
import DealerFinancingDetailDesignatedBankAccountInformation from './sections/designated-bank-account-information';
import DealerFinancingDetailEarlyRepaymentRequestInformation from './sections/early-repayment-request-information';
import DealerFinancingDetailExtensionHistory from './sections/financing-extension-history';
import DealerFinancingDetailFinancingRequestInformation from './sections/financing-request-information';
import DealerFinancingDetailGuideMessage from './sections/guide-message';
import DealerFinancingDetailInvoiceInformation from './sections/invoice-information';
import DealerFinancingDetailPurposeOfFinancingList from './sections/purpose-of-financing';
import DealerFinancingDetailRepaymentTransactionHistory from './sections/repayment-transaction-history';
import DealerFinancingDetailSummary from './sections/summary';
import { useDealerFinancingDetailState } from './useDealerFinancingDetailState';

function DealerFinancingDetail() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const signInModel: SignInModel | null = getSignIn();
  const history = useHistory();

  const [financingInfoToggle, setFinancingInfoToggle] = useState<boolean>(false);
  const [collateralTypeInfoToggle, setCollateralTypeInfoToggle] = useState<boolean>(false);
  const [bankAccountInfoToggle, setBankAccountInfoToggle] = useState<boolean>(false);
  const [checkedRequiredDocumentsIds, setCheckedRequiredDocumentsIds] = useState<number[]>([]);

  const { state, fetchAll, paginates, pageables, updateFinancingStatusActions } = useDealerFinancingDetailState();
  const { requiredAdditionalDocuments } = state;

  const { factoringEnable, multipleRequested, multipleLoanRequestId } = state.deLoanDetail;

  const {
    onClickUpdateTransaction,
    onClickSubmitFinancingApplicationOfAuthorizer,
    onClickCancelRequestOfOperator,
    onClickCancelOfAuthorizer,
    onClickViewExpectedRepaymentAmount,
  } = updateFinancingStatusActions;

  // 사용자가 Authorizer 이고, 대출 상태가 DEALER_CREATED 인지 확인
  const isAuthorizerRequest =
    signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER &&
    state.deLoanDetail.loanStatus === LOAN_STATUS.DEALER_CREATED;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    if (isAuthorizerRequest) {
      setFinancingInfoToggle(false);
      setCollateralTypeInfoToggle(false);
      setBankAccountInfoToggle(false);
    }
  }, [isAuthorizerRequest]);

  const handleRequiredAdditionalDocumentCheckboxChange = (id: number) => {
    setCheckedRequiredDocumentsIds(prevCheckedIds =>
      prevCheckedIds.includes(id) ? prevCheckedIds.filter(itemId => itemId !== id) : [...prevCheckedIds, id],
    );
  };

  const hardCopyConditionCheckboxValidator = () => {
    if (requiredAdditionalDocuments.length < 1) {
      return true;
    }

    const requiredCheckboxes = requiredAdditionalDocuments.filter(document => document.hardCopyCondition.required);

    return requiredCheckboxes.length === checkedRequiredDocumentsIds.length;
  };

  const renderButton = () => {
    const submitButtonDisabled = !(
      financingInfoToggle &&
      collateralTypeInfoToggle &&
      bankAccountInfoToggle &&
      hardCopyConditionCheckboxValidator()
    );

    if (isAuthorizerRequest) {
      return (
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancelOfAuthorizer}
            className="me-2"
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickSubmitFinancingApplicationOfAuthorizer}
            disabled={submitButtonDisabled}
          >
            {t('text:Submit_Financing_Application')}
          </Button>
        </div>
      );
    }

    if (
      signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR &&
      state.deLoanDetail.loanStatus === LOAN_STATUS.DEALER_CREATED
    ) {
      return (
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancelRequestOfOperator}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel_Request')}
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <BackHeaderTitle
        title={isAuthorizerRequest ? t('text:Financing_Confirmation_Details') : t('text:Financing_Details')}
      />
      <DealerFinancingDetailGuideMessage
        data={state.deLoanDetail}
        isAuthorizerRequest={isAuthorizerRequest}
        useLoanResultInterface={state.useLoanResultInterface}
        onClickUpdateTransaction={onClickUpdateTransaction}
      />
      {!isAuthorizerRequest && (
        <DealerFinancingDetailSummary
          data={state.deLoanDetail}
          factoringEnable={factoringEnable}
          onClickViewExpectedRepaymentAmount={onClickViewExpectedRepaymentAmount}
        />
      )}
      <DealerFinancingDetailFinancingRequestInformation
        data={state.deLoanDetail}
        factoringEnable={factoringEnable}
        isAuthorizerRequest={isAuthorizerRequest}
        financingInfoToggleState={{
          financingInfoToggle,
          setFinancingInfoToggle,
        }}
      />
      {!state.isNilInvoiceIdAndSuccessArId && state.deLoanDetail.collateralType === COLLATERAL_TYPE.INVOICE && (
        <DealerFinancingDetailInvoiceInformation
          invoiceData={state.deInvoiceDetail}
          loanData={state.deLoanDetail}
          isAuthorizerRequest={isAuthorizerRequest}
          collateralTypeInfoToggleState={{
            collateralTypeInfoToggle,
            setCollateralTypeInfoToggle,
          }}
        />
      )}
      {!state.isNilInvoiceIdAndSuccessArId && state.deLoanDetail.collateralType === COLLATERAL_TYPE.AR && (
        <DealerFinancingDetailArInformation
          arData={state.deArDetail}
          loanData={state.deLoanDetail}
          isAuthorizerRequest={isAuthorizerRequest}
          factoringEnable={factoringEnable}
          collateralTypeInfoToggleState={{
            collateralTypeInfoToggle,
            setCollateralTypeInfoToggle,
          }}
        />
      )}
      {state.deLoanDetail.collateralType === COLLATERAL_TYPE.AR &&
        state.deLoanDetail.purposeOfLoanList &&
        !factoringEnable && (
          <DealerFinancingDetailPurposeOfFinancingList
            purposeListData={state.deLoanDetail.purposeOfLoanList}
            totalPurposeOfLoanAmount={state.deLoanDetail.totalPurposeOfLoanAmount}
          />
        )}
      <DealerFinancingDetailDesignatedBankAccountInformation
        data={state.deLoanDetail}
        factoringEnable={factoringEnable}
        isAuthorizerRequest={isAuthorizerRequest}
        bankAccountInfoToggleState={{
          bankAccountInfoToggle,
          setBankAccountInfoToggle,
        }}
      />

      {!multipleRequested && (
        <>
          <div className="content-area">
            <DealerFinancingDetailAdditionalDocuments
              loanId={state.deLoanDetail.loanId}
              loanEtcAttachmentListData={state.deLoanDetail.loanEtcAttachment}
            />
          </div>
          <RequiredAdditionalDocumentList
            requiredAdditionalDocuments={state.requiredAdditionalDocuments}
            isRenderConfirmCheckbox={isAuthorizerRequest}
            checkedRequiredDocumentsIds={checkedRequiredDocumentsIds}
            handleRequiredAdditionalDocumentCheckboxChange={handleRequiredAdditionalDocumentCheckboxChange}
          />
          <div className="content-area">{renderButton()}</div>
        </>
      )}

      {isLoanDisbursed(state.deLoanDetail.loanStatus) && (
        <DealerFinancingDetailRepaymentTransactionHistory
          loanTransactionHistoryData={state.deLoanTransactionList}
          pageable={pageables.loanTransactionHistoryPageable}
          paginate={paginates.getLoanTransactionHistoryPaginate}
        />
      )}
      {state.deEarlyRepaymentList?.length !== 0 && (
        <DealerFinancingDetailEarlyRepaymentRequestInformation
          earlyRepaymentListData={state.deEarlyRepaymentList}
          pageable={pageables.earlyRepaymentPageable}
          paginate={paginates.getEarlyRepaymentListPaginate}
        />
      )}
      {state.deExtensionList?.length !== 0 && (
        <DealerFinancingDetailExtensionHistory
          extensionListData={state.deExtensionList}
          pageable={pageables.extensionPageable}
          paginate={paginates.getExtensionListPaginate}
        />
      )}
      {multipleRequested && (
        <div className="content-area flex-end">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={() => {
              history.push(ROUTES_DE.MANAGE_FINANCING.BULK_CONFIRMATION_DETAIL_BUILD_PATH(multipleLoanRequestId));
            }}
          >
            {t('text:Go_to_Bulk_Financing_Page')}
          </Button>
        </div>
      )}
    </>
  );
}

export default DealerFinancingDetail;
