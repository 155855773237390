import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import i18n from 'i18next';
import { isEmpty } from 'lodash-es';

import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';

dayjs.extend(isBetween, customParseFormat);

export function convertToServerDateFormat(date: Date): string {
  // convert to "yyyy-mm-dd" format
  const year = date.getFullYear();
  const month = ('0' + (1 + date.getMonth())).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return year + '-' + month + '-' + day;
}

export function convertToTestDateFormat(date: string | Date): string {
  // convert to "mm-dd-yyyy" format
  let year, month, day;

  if (date instanceof Date) {
    year = date.getFullYear();
    month = ('0' + (1 + date.getMonth())).slice(-2);
    day = ('0' + date.getDate()).slice(-2);
  } else if (typeof date === 'string') {
    [year, month, day] = date.split('-');
  }

  return `${month}-${day}-${year}`;
}

// D-day 구하기
export function getDday(targetDate: string, baseDate?: string) {
  const referenceDate = baseDate ? baseDate : dayjs().format('YYYY-MM-DD');
  const diff = dayjs(targetDate).diff(referenceDate, 'days');
  if (diff >= 0) {
    return `D-${diff}`;
  } else {
    return `D+${Math.abs(diff)}`;
  }
}

export function getNextDay(date: string | undefined): string {
  return dayjs(date).add(1, 'day').format();
}

export function getPrevDay(date: string | undefined): string {
  return dayjs(date).subtract(1, 'day').format();
}

export function enumerateDaysBetweenDates(startDate: any, endDate: any): string[] {
  const date = [];
  date.push(dayjs(startDate).format('YYYY-MM-DD'));
  while (dayjs(startDate) < dayjs(endDate)) {
    startDate = dayjs(startDate).add(1, 'days').format('YYYY-MM-DD');
    date.push(startDate);
  }

  return date;
}

export function isSelectableRange(date: Date, startDate: Date | string, endDate: Date | string = '2999-12-31') {
  return dayjs(date).isBetween(startDate, endDate, 'date', '[]');
}

export function removeRangeFromHoliday(data: FinancierCalenderVOModel[] | [] | undefined): Date[] | [] {
  if (isEmpty(data)) return [];
  const sortedDescSolarDateList = data?.map(item => new Date(item.solarDate)).sort((a, b) => a.getTime() - b.getTime());

  return sortedDescSolarDateList as Date[];
}

export function getDayTerm(
  startDate: string | undefined,
  endDate: string | undefined,
  options?: { includeStartDate?: boolean; withText?: boolean },
): string {
  if (!startDate || !endDate) {
    return `- ${options?.withText && i18n.t('text:Days')}`;
  }
  let result = dayjs(endDate).diff(dayjs(startDate).format('YYYY-MM-DD'), 'days');
  if (isNaN(result)) {
    throw new Error(`not number`);
  } else {
    // 시작일  포함
    if (options?.includeStartDate) result = result + 1;

    return options?.withText ? `${result}  ${i18n.t('text:Days')}` : result.toString();
  }
}

export function getAvailableSettlementDateRangeForRegistrationArOrAp(
  holidayList: string[],
  minimumDate: Date,
  maximumDate: Date,
) {
  const getRanges = enumerateDaysBetweenDates(minimumDate, maximumDate);
  const availableDateRange = getRanges.filter(el => !holidayList.includes(el));

  return availableDateRange;
}

export const verifySettlementDateForRegistrationArOrAp = (
  targetDate: string,
  initialSettlementDate: string | undefined,
  availableSettlementDateRange: string[] | undefined,
): string | undefined => {
  if (!dayjs(targetDate, 'YYYY-MM-DD', true).isValid()) return undefined;

  if (
    availableSettlementDateRange &&
    availableSettlementDateRange.length !== 0 &&
    !availableSettlementDateRange?.includes(targetDate)
  )
    return undefined;

  return initialSettlementDate && new Date(initialSettlementDate) <= new Date(targetDate) ? targetDate : undefined;
};

type FullMonthsKey =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

export function getLocalizedFullMonth(key: string): string {
  const monthKey = key as FullMonthsKey;

  switch (monthKey) {
    case 'January':
      return i18n.t('text:January');
    case 'February':
      return i18n.t('text:February');
    case 'March':
      return i18n.t('text:March');
    case 'April':
      return i18n.t('text:April');
    case 'May':
      return i18n.t('text:May');
    case 'June':
      return i18n.t('text:June');
    case 'July':
      return i18n.t('text:July');
    case 'August':
      return i18n.t('text:August');
    case 'September':
      return i18n.t('text:September');
    case 'October':
      return i18n.t('text:October');
    case 'November':
      return i18n.t('text:November');
    case 'December':
      return i18n.t('text:December');
    default:
      return 'Invalid month key';
  }
}
