import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormSelect } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { FINANCIER_CLIENT_TYPE, FINANCIER_INTERFACE_TYPE, OTP_TYPE } from 'enums';
import SearchDealerModal from 'pages/financier/manage-partner/company/modals/SearchDealerModal';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierAuthSetting } from 'utils/http/api/financier/client-auth-setting';
import { requestFinancierClientRegister } from 'utils/http/api/financier/financier-clients';
import type { FinancierClientRegisterRequest } from 'utils/http/api/financier/financier-clients/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

const financierClientType = FINANCIER_CLIENT_TYPE.DEALER;

function FinancierManageDealerRegistration() {
  const modal = useModal();
  const errorAlert = useModal();
  const history = useHistory();
  const [isEnable] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.ENTERPRISE));
  const { t } = useTranslation(['format']);
  const [isOTPUsed, setIsOTPUsed] = useState(false);

  useEffect(() => {
    (async () => {
      const { otpType } = await requestFinancierAuthSetting();
      setIsOTPUsed(otpType !== OTP_TYPE.NONE);
    })();
  }, []);

  const [newFinancierClientData, setNewFinancierClientData] = useState<FinancierClientRegisterRequest>(
    {} as FinancierClientRegisterRequest,
  );

  const {
    register: newFinancierRegister,
    reset: newFinancierReset,
    getValues: newFinancierGetValues,
    errors,
    setError,
    clearErrors,
  } = useForm<FinancierClientRegisterRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const isReadOnly = (parameterName: keyof FinancierClientRegisterRequest) => {
    if (isEnable) {
      if (isEmpty(newFinancierClientData)) return true;

      return !!newFinancierClientData[parameterName];
    } else {
      return false;
    }
  };

  const showSearchDealerModal = (event: any) => {
    event.preventDefault();

    const initializeDealerData = () => {
      newFinancierReset(isEmpty(newFinancierClientData) ? {} : newFinancierClientData);
    };

    const setDealerData = () => {
      const data = newFinancierGetValues();

      if (data.financierClientCode !== '' || data.financierClientTaxCode !== '' || data.financierClientName !== '') {
        setNewFinancierClientData(data);
      } else if (newFinancierClientData) {
        initializeDealerData();
      }
    };

    const setDealerInfoFromModal = (data: FinancierClientRegisterRequest) => {
      newFinancierReset(data);
    };

    modal.show(<SearchDealerModal financierClientDataHandler={setDealerInfoFromModal} isOtpUsed={isOTPUsed} />, {
      title: t('text:Search_Partner'),
      modalType: ModalType.CONFIRM,
      modalSize: ModalSize.XL,
      closeBtnText: t('text:Close'),
      confirmBtnText: t('text:Apply'),
      closeBtnCb: () => initializeDealerData(),
      confirmBtnCb: () => setDealerData(),
    });
  };

  const showRegistrationRequestModal = (event: any) => {
    event.preventDefault();

    modal.show(<h6>{t('text:Would_you_like_to_register_the_Partner_company?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => showRegistrationRequestConfirmModal(),
    });
  };

  const showRegistrationRequestConfirmModal = async () => {
    const newFinancierRegistrationData = isEnable
      ? { ...newFinancierClientData, ...newFinancierGetValues() }
      : newFinancierGetValues();

    if (newFinancierRegistrationData) {
      try {
        requestDTOParser(newFinancierRegistrationData);

        await requestFinancierClientRegister(newFinancierRegistrationData);

        modal.show(<h6>{t('text:The_partner_information_has_been_registered_successfully')}</h6>, {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_FI.MANAGE_PARTNER.COMPANY_LIST),
        });
      } catch (e) {
        errorAlert.show(e);

        formErrorHandler<FinancierClientRegisterRequest>(e, setError, clearErrors);
      }
    }
  };

  const showCancelModal = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_modification_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => history.push(ROUTES_FI.MANAGE_PARTNER.COMPANY_LIST),
      },
    );
  };

  const renderSearchDealerButton = () => {
    return (
      isEnable && (
        <div className="row">
          <div className="d-flex">
            <Button size={ButtonSizeEnum.LG} onClick={showSearchDealerModal}>
              {t('text:Search_Partner')}
            </Button>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Partner_Registration')} />
      {isEnable ? (
        <GuideMessage
          message={[
            t('text:Click_on_the_Search_Partner_button_to_retrieve_information_from_the_bank_system'),
            t('text:The_information_from_the_financier_system_cannot_be_changed'),
            t('text:The_information_that_wasnt_retrieved_can_be_entered_manually'),
            t('text:Click_on_the_Register_button_to_complete_the_registration'),
          ]}
        />
      ) : (
        <GuideMessage
          message={[
            t('text:Enter_the_Partner_information_to_register_the_company_on_the_platform'),
            <Trans
              key="key"
              i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
              components={{ 1: <span className="asterisk" /> }}
            />,
            t('text:Click_on_the_Register_button_to_complete_the_registration'),
          ]}
        />
      )}
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Partner_Information')} />
          <FormBorder editable={true}>
            <FormContents>
              {renderSearchDealerButton()}
              <div className="row">
                <FormInput
                  label={t('text:Client_Code')}
                  name="financierClientCode"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('financierClientCode')}
                  requiredOptions={{ required: true }}
                  error={errors.financierClientCode}
                />
                <FormInput
                  label={t('text:Partner_Name')}
                  name="financierClientName"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('financierClientName')}
                  requiredOptions={{ required: true }}
                  error={errors.financierClientName}
                />
              </div>
              <div className="row">
                <FormInput
                  col={3}
                  label={t('text:Tax_Code')}
                  name="financierClientTaxCode"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('financierClientTaxCode')}
                  requiredOptions={{ required: true }}
                  error={errors.financierClientTaxCode}
                />
                <FormInput
                  col={3}
                  label={t('text:Company_Registration_Number')}
                  name="financierClientBusinessCode"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('financierClientBusinessCode')}
                  error={errors.financierClientBusinessCode}
                />
                <FormInput
                  col={3}
                  label={t('text:Telephone')}
                  name="financierClientTelephone"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('financierClientTelephone')}
                  error={errors.financierClientTelephone}
                />
                <FormInput
                  col={3}
                  label={t('text:Fax')}
                  name="financierClientFax"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('financierClientFax')}
                  error={errors.financierClientFax}
                />
              </div>
              <div className="row">
                <FormInput
                  col={isOTPUsed ? 3 : 6}
                  label={t('text:Legal_Representative_Name')}
                  name="representativeName"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('representativeName')}
                  error={errors.representativeName}
                />
                <FormInput
                  col={3}
                  label={t('text:Legal_Representative_Title')}
                  name="representativePosition"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('representativePosition')}
                  error={errors.representativePosition}
                />
                <FormInput
                  col={3}
                  label={t('text:Legal_Representative_Email')}
                  name="representativeEmail"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('representativeEmail')}
                  error={errors.representativeEmail}
                />
                {isOTPUsed && (
                  <FormSelect
                    col={3}
                    label={t('text:OTP_Verification')}
                    selectOptions={getSelectOptions('OTP_VERIFICATION')}
                    name="otpBypassed"
                    ref={newFinancierRegister}
                    error={errors.otpBypassed}
                    required={true}
                  />
                )}
              </div>
              <div className="row">
                <FormInput
                  col={12}
                  label={t('text:Registered_Office_Address')}
                  name="financierClientAddress"
                  ref={newFinancierRegister}
                  disabled={isReadOnly('financierClientAddress')}
                  error={errors.financierClientAddress}
                />
                <input
                  name="financierClientType"
                  type="hidden"
                  defaultValue={financierClientType}
                  ref={newFinancierRegister}
                />
              </div>
            </FormContents>
          </FormBorder>

          <div className="flex-end mt-4">
            <Button
              onClick={showCancelModal}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
              size={ButtonSizeEnum.LG}
            >
              {t('text:Cancel')}
            </Button>
            <Button onClick={showRegistrationRequestModal} size={ButtonSizeEnum.LG} className="ms-2">
              {t('text:Register')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default FinancierManageDealerRegistration;
