import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { requestFinancierAnchorAgreementList } from 'utils/http/api/financier/anchor-agreements';
import { requestFinancierAnchorDealerDetail } from 'utils/http/api/financier/anchor-dealers';
import { requestFinancierWaitingAnchorDealerList } from 'utils/http/api/financier/waiting-anchor-dealers';
import useModal from 'utils/modal/useModal';

import InformationEditHistory from '../../components/InformationEditHistory';
import RelatedAnchorAgreementList from '../../components/RelatedAnchorAgreementList';

function FinancierAnchorDealerRegistrationConfirmedDetailPage() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { anchorDealerId } = useParams() as any;
  const { pageable: anchorAgreementPageable, setPageable: setAnchorAgreementPageable } = usePageable();
  const { pageable: historyPageable, setPageable: setHistoryPageable } = usePageable();

  const [anchorDealerInfo, setAnchorDealerInfo] = useState<AnchorDealerVOModel>();
  const [relatedAnchorAgreement, setRelatedAnchorAgreement] = useState<AnchorAgreementVOModel[]>();
  const [anchorDealerHistoryList, setAnchorDealerHistoryList] = useState<WaitingAnchorDealerVOModel[]>([]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const [anchorDealerResponse, anchorDealerHistoryResponse] = await Promise.all([
        requestFinancierAnchorDealerDetail(anchorDealerId),
        requestFinancierWaitingAnchorDealerList(historyPageable.currentPage, historyPageable.sizePerPage, {
          hasTargetAnchorDealer: true,
          targetAnchorDealerId: anchorDealerId,
          approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
        }),
      ]);

      const relatedAnchorAgreementResponse = await requestFinancierAnchorAgreementList(1, 10, {
        anchorClientId: anchorDealerResponse.anchorClientId,
      });

      setAnchorDealerInfo(anchorDealerResponse);

      setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
      setAnchorAgreementPageable(relatedAnchorAgreementResponse);

      setAnchorDealerHistoryList(anchorDealerHistoryResponse.content);
      setHistoryPageable(anchorDealerHistoryResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  const anchorAgreementPaginate = async (pageNumber: number, sizePerPage: number) => {
    const relatedAnchorAgreementResponse = await requestFinancierAnchorAgreementList(pageNumber, sizePerPage, {
      anchorClientId: anchorDealerInfo?.anchorClientId,
    });

    setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
    setAnchorAgreementPageable(relatedAnchorAgreementResponse);
  };

  const historyPaginate = async (pageNumber: number, sizePerPage: number) => {
    const historyResponse = await requestFinancierWaitingAnchorDealerList(pageNumber, sizePerPage, {
      hasTargetAnchorDealer: true,
      targetAnchorDealerId: anchorDealerId,
      approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
    });

    setAnchorDealerHistoryList(historyResponse.content);
    setHistoryPageable(historyResponse);
  };

  return (
    <>
      <BackHeaderTitle title={anchorDealerInfo?.name} />
      <div className="content-area">
        <SectionTitle title={t(`text:Dealer_Information`)} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t(`text:Dealer_Name`)} value={anchorDealerInfo?.name} />
              <FormValue label={t(`text:Tax_Code`)} value={anchorDealerInfo?.taxCode} />
            </div>
            <div className="row">
              <FormValue label={t(`text:Company_Registration_Number`)} value={anchorDealerInfo?.businessCode} />
              <FormValue col={3} label={t(`text:Telephone`)} value={anchorDealerInfo?.telephone} />
              <FormValue col={3} label={t(`text:Fax`)} value={anchorDealerInfo?.fax} />
            </div>
            <div className="row">
              <FormValue label={t(`text:Legal_Representative_Name`)} value={anchorDealerInfo?.representativeName} />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Title`)}
                value={anchorDealerInfo?.representativePosition}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Email`)}
                value={anchorDealerInfo?.representativeEmail}
              />
            </div>
            <div className="row">
              <FormValue col={12} label={t(`text:Registered_Office_Address`)} value={anchorDealerInfo?.address} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <RelatedAnchorAgreementList
        relatedAnchorAgreement={relatedAnchorAgreement}
        pageable={anchorAgreementPageable}
        paginate={anchorAgreementPaginate}
      />
      <InformationEditHistory
        anchorDealerHistoryList={anchorDealerHistoryList}
        pageable={historyPageable}
        paginate={historyPaginate}
      />
    </>
  );
}

export default FinancierAnchorDealerRegistrationConfirmedDetailPage;
