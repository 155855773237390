import type { RegisterOptions } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { clsx } from 'clsx';
import { isNil } from 'lodash-es';

import { isNilOrEmptyString } from 'utils/helpers';

import ReactDatePicker from './ReactDatePicker';
import { SizeType, useFormContext } from '..';
import Value from '../Value/Value';

type FormReactDatePickerInputPropsType = {
  name?: string;
  className?: string;
  defaultValue?: string | null;
  disabled?: boolean;
  minimumDate?: Date;
  maximumDate?: Date;
  excludeDates?: Date[];
  rules?: RegisterOptions;
  fieldSize?: SizeType;
  readOnly?: boolean;
  parentReadOnly?: boolean;
  showError?: boolean;
};
const FormReactDatePickerInput = ({
  name = '',
  className = '',
  disabled = false,
  defaultValue = null,
  minimumDate,
  maximumDate,
  excludeDates,
  rules,
  fieldSize = SizeType.MD,
  showError = true,
  readOnly = false,
  parentReadOnly,
}: FormReactDatePickerInputPropsType) => {
  const {
    methods: { control, watch, errors },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();

  const fetchedReadOnly = getReadOnlyValue(name);

  const currentValue = watch(name);

  const hasNoValue = isNilOrEmptyString(defaultValue) && isNilOrEmptyString(currentValue);

  const shouldShowDash = (() => {
    const defaultCondition = hasNoValue && (readOnly || fetchedReadOnly || disabled || !isEditable);

    if (isNil(parentReadOnly)) return defaultCondition;

    return parentReadOnly && defaultCondition;
  })();

  const error = (() => {
    if (!showError) return null;

    return errors?.[name];
  })();

  return (
    <div className="date-picker-wrapper">
      {
        /*datePicker input과 캘린더를 묶기 위해 div로 감쌌습니다.*/
        shouldShowDash ? (
          <Value value="-" />
        ) : (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={field => {
              return (
                <ReactDatePicker
                  field={field}
                  className={clsx('form__input', {
                    [className]: className,
                  })}
                  minDate={minimumDate}
                  maxDate={maximumDate}
                  excludeDates={excludeDates}
                  fieldSize={fieldSize}
                  disabled={!isEditable || disabled}
                  readOnly={readOnly || fetchedReadOnly}
                  error={error}
                />
              );
            }}
            rules={rules}
          />
        )
      }
    </div>
  );
};

export default FormReactDatePickerInput;
