export interface InventoryItemVO {
  inventoryItemId: number;
  itemNumber: string;
  itemDescription: string;
  itemAmount: number;
  invoiceNumber: string;
  quantity: number;
  unitPrice: number;
}

export interface InventoryItemVOModel extends InventoryItemVO {}

export function formattingToInventoryItemVOModel(data: InventoryItemVO): InventoryItemVOModel {
  return {
    ...data,
  };
}
