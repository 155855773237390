import { createContext, useContext } from 'react';

import type { DealerAgreementCommonVOModel } from 'models/convertor/dealerAgreement';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';

export type AnchorAgreementInfoType = Pick<
  DealerAgreementCommonVOModel,
  'anchorClientId' | 'anchorClientName' | 'anchorAgreementStartDate' | 'anchorAgreementExpiryDate' | 'anchorUserList'
> & { anchorAgreementNo: string };

export type AnchorAgreementBeforeConvertedType = Pick<
  DealerAgreementCommonVOModel,
  | 'anchorClientId'
  | 'mainContractNo'
  | 'anchorClientName'
  | 'anchorAgreementStartDate'
  | 'anchorAgreementExpiryDate'
  | 'anchorUserList'
>;

type AnchorAgreementInfoStateType = {
  anchorAgreementInfo: AnchorAgreementInfoType;
  isAssociatedAnchorAgreementExist: boolean;
  updateAnchorAgreementInfo: (anchorAgreementInfo?: AnchorAgreementBeforeConvertedType) => void;
};

export const AnchorAgreementInfoContext = createContext<AnchorAgreementInfoStateType | null>(null);

export const initialAnchorAgreementInfoState: AnchorAgreementInfoType = {
  anchorClientId: undefined,
  anchorAgreementNo: '',
  anchorClientName: '',
  anchorAgreementStartDate: '',
  anchorAgreementExpiryDate: '',
  anchorUserList: [] as AnchorUserVOModel[],
};

export const useAnchorAgreementInfoContext = () => {
  const context = useContext(AnchorAgreementInfoContext);
  if (!context) {
    throw Error('useAnchorAgreementInfoContext should be used within AnchorAgreementInfoContext.Provider');
  }

  return context;
};
