import type {
  AUTHORIZER_STATUS,
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  CURRENCY_TYPE,
  DEALER_AGREEMENT_STATUS,
  DEALER_AGREEMENT_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
  LOAN_TERM_TYPE,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface DealerAgreementVO {
  dealerFinancierClientId: number;
  dealerFinancierClientCode: string;
  dealerClientTaxCode: string;
  contractNo: string;
  dealerCodeByAnchor: string;
  dealerFinancierClientName: string;
  dealerFinancierClientBusinessCode: string;
  dealerFinancierClientAddress: string;
  dealerFinancierClientTaxCode: string;
  dealerFinancierClientRepresentativeName: string;
  dealerAgreementId: number;
  startDate: string;
  expiryDate: string;
  changeDate: string;
  branchId: number;
  branchCode: string;
  branchName: string;
  branchManagerCode: string;
  branchManagerName: string;
  maxExtensibleLoanCount: number;
  earlyRepaymentAllowable: boolean;
  dealerUploadInvoiceAllowable: boolean;
  loanTermUnit: number;
  minimumLoanRange: number;
  maximumLoanRange: number;
  monthlyInterestRepaymentDate: number;
  basisInterestBankCode: string;
  basisInterestTerm: string;
  basisInterestRate: number;
  creditInterestRate: number;
  preferentialInterestRate: number;
  preferentialReason: string;
  loanLimitAmount: string;
  registeredWaitingInvoiceAmount: string;
  settlementWaitingInvoiceAmount: string;
  disbursedLoanAmount: string;
  requestedLoanAmount: string;
  settlementWaitingInvoiceCount: number;
  disbursedLoanCount: number;
  requestedLoanCount: number;
  overdueLoanCount: number;
  settlementAccountFinancierCode: string;
  settlementAccountFinancierName: string;
  settlementAccountBranchCode: string;
  settlementAccountBranchName: string;
  settlementAccount: string;
  settlementAccountOwner: string;
  principalRepaymentAccountFinancierCode: string;
  principalRepaymentAccountFinancierName: string;
  principalRepaymentAccountBranchCode: string;
  principalRepaymentAccountBranchName: string;
  principalRepaymentAccount: string;
  principalRepaymentAccountOwner: string;
  authorizerName: string;
  authorizerEmployeeCode: string;
  authorizerEmail: string;
  authorizerMobile: string;
  authorizerPosition: string;
  authorizerBankUserId: string;
  authorizerOtpSerialNo: string;
  authorizerStatus: AUTHORIZER_STATUS;
  repaymentDateOverAllowable: boolean;
  anchorFinancierClientId: number;
  anchorFinancierClientName: string;
  anchorFinancierClientCode: string;
  anchorAgreementId: number;
  anchorAgreementContractNo: string;
  exists: boolean;
  financierId: number;
  financierName: string;
  adhocLimitAllowable: boolean;
  adhocLimitAmount: string;
  adhocLimitStartDate: string;
  adhocLimitEndDate: string;
  drawingPower: string;
  partiallyRepaidPrincipalAmount: string;
  showExpectedTotalInterestRate: boolean;
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  dealerAgreementStatus: DEALER_AGREEMENT_STATUS;
  dealerAgreementType: DEALER_AGREEMENT_TYPE;
  loanLimitCheckType: LOAN_LIMIT_CHECK_TYPE;
  loanTermType: LOAN_TERM_TYPE;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  basisInterestType: BASIS_INTEREST_TYPE;
  showNetDisbursementAmount: boolean;
}

export interface DealerAgreementVOModel extends DealerAgreementVO {
  loanLimitAmount: BigNumber;
  registeredWaitingInvoiceAmount: BigNumber;
  settlementWaitingInvoiceAmount: BigNumber;
  disbursedLoanAmount: BigNumber;
  adhocLimitAmount: BigNumber;
  partiallyRepaidPrincipalAmount: BigNumber;
  drawingPower: BigNumber;
}

export function formattingToDealerAgreementVOModel(data: DealerAgreementVO): DealerAgreementVOModel {
  const {
    loanLimitAmount,
    registeredWaitingInvoiceAmount,
    settlementWaitingInvoiceAmount,
    disbursedLoanAmount,
    requestedLoanAmount,
    adhocLimitAmount,
    partiallyRepaidPrincipalAmount,
    drawingPower,
  } = data;

  return {
    ...data,
    loanLimitAmount: sliceZeroDecimal(loanLimitAmount),
    registeredWaitingInvoiceAmount: sliceZeroDecimal(registeredWaitingInvoiceAmount),
    settlementWaitingInvoiceAmount: sliceZeroDecimal(settlementWaitingInvoiceAmount),
    disbursedLoanAmount: sliceZeroDecimal(disbursedLoanAmount),
    requestedLoanAmount: sliceZeroDecimal(requestedLoanAmount),
    adhocLimitAmount: sliceZeroDecimal(adhocLimitAmount),
    partiallyRepaidPrincipalAmount: sliceZeroDecimal(partiallyRepaidPrincipalAmount),
    drawingPower: sliceZeroDecimal(drawingPower),
  };
}
