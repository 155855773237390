import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormSelect } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import Pagination from 'components/stateless/Pagination/Pagination';
import TdLink from 'components/stateless/Table/TdLink';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, DEALER_AGREEMENT_STATUS, FINANCIER_INTERFACE_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { FinancierClientHistoryVOModel } from 'models/vo/FinancierClientHistoryVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierAuthSetting } from 'utils/http/api/financier/client-auth-setting';
import { requestFinancierDealerAgreementList } from 'utils/http/api/financier/dealer-agreements';
import type { FinancierDealerAgreementListRequest } from 'utils/http/api/financier/dealer-agreements/request';
import { requestFinancierClientHistories } from 'utils/http/api/financier/financier-client-histories';
import { requestFinancierClientDetail, requestFinancierClientUpdate } from 'utils/http/api/financier/financier-clients';
import type { FinancierClientUpdateRequest } from 'utils/http/api/financier/financier-clients/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType, getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser, tableValueManage } from 'utils/valueManager/ValueManager';

import UpdateDealerModal from '../modals/UpdateDealerModal';

function FinancierManageDealerDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const [financierClientData, setFinancierClientData] = useState<FinancierClientVOModel>();
  const [dealerInfoEditHistory, setDealerInfoEditHistory] = useState<Pageable<FinancierClientHistoryVOModel[]>>();
  const [dealerAgreementsPage, setDealerAgreementsPage] = useState<Pageable<DealerAgreementVOModel[]>>();
  const dealerAgreementPageHandler = usePageable();
  const dealerHistoriesPageHandler = usePageable();
  const [willUpdateFinancierClientData, setWillUpdateFinancierClientData] = useState<FinancierClientUpdateRequest>();
  const [isEdit, setIsEdit] = useState(false);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const { t } = useTranslation(['format']);
  const { financierClientId } = useParams() as any;
  const [isEnable] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.ENTERPRISE));
  const [userRole] = useState<AUTHORITY_TYPE | undefined>(getSignIn()?.authorityType);
  const [isOTPUsed, setIsOTPUsed] = useState(false);

  useEffect(() => {
    if (mounted) {
      fetchAll(
        dealerAgreementPageHandler.pageable.currentPage,
        dealerAgreementPageHandler.pageable.sizePerPage,
        dealerHistoriesPageHandler.pageable.currentPage,
        dealerHistoriesPageHandler.pageable.sizePerPage,
      );
    }
  }, [mounted]);

  const fetchAll = async (
    dealerAgreementsPageNumber: number = 1,
    dealerAgreementsSizePerPage: number = 10,
    financierManageDealerHistoriesPageNumber: number = 1,
    financierManageDealerHistoriesSizePerPage: number = 10,
  ) => {
    try {
      const [financierClientData, dealerAgreementsPage, waitingFinancierClientHistoriesPage, { otpType }] =
        await Promise.all([
          requestFinancierClientDetail(financierClientId),
          requestFinancierDealerAgreementList(dealerAgreementsPageNumber, dealerAgreementsSizePerPage, {
            financierClientId: financierClientId,
            dealerClientId: financierClientId,
          } as FinancierDealerAgreementListRequest),
          requestFinancierClientHistories(
            financierManageDealerHistoriesPageNumber,
            financierManageDealerHistoriesSizePerPage,
            financierClientId,
          ),
          requestFinancierAuthSetting(),
        ]);

      setIsOTPUsed(otpType !== OTP_TYPE.NONE);
      setPartnerInfoForm(financierClientData);

      ReactDOM.unstable_batchedUpdates(() => {
        setIsEdit(false);
        setFinancierClientData(financierClientData);
        setDealerAgreementsPage(dealerAgreementsPage);
        dealerAgreementPageHandler.setPageable(dealerAgreementsPage);
        setDealerInfoEditHistory(waitingFinancierClientHistoriesPage);
        dealerHistoriesPageHandler.setPageable(waitingFinancierClientHistoriesPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const setPartnerInfoForm = (
    data: FinancierClientUpdateRequest | FinancierClientVOModel | undefined,
    isInterface?: boolean,
  ) => {
    reset({
      financierClientName: data?.financierClientName,
      financierClientBusinessCode: data?.financierClientBusinessCode,
      financierClientAddress: data?.financierClientAddress,
      financierClientTelephone: data?.financierClientTelephone,
      financierClientFax: data?.financierClientFax,
      representativeName: data?.representativeName,
      representativeEmail: data?.representativeEmail,
      representativePosition: data?.representativePosition,
      financierClientStatus: data?.financierClientStatus,
      otpBypassed: data?.otpBypassed,
      financierClientTaxCode: isInterface
        ? financierClientData?.enterpriseId && !data?.financierClientTaxCode
          ? financierClientData.financierClientTaxCode
          : data?.financierClientTaxCode
        : data?.financierClientTaxCode,
    });
  };

  const isReadonly = (parameterName: string): boolean => {
    if (isEdit) {
      if (isEnable) {
        if (parameterName === 'otpBypassed') return false;
        if (!willUpdateFinancierClientData) return true;

        return !(!(willUpdateFinancierClientData as any)[parameterName] && isSearched);
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const onEditClick = (event: any): void => {
    setIsEdit(true);
    setIsSearched(false);
    event.preventDefault();
  };

  const onCancelClick = (event: any): void => {
    event.preventDefault();
    showChangeRequestCancelModal();
  };

  const { register, reset, getValues, errors, setError, clearErrors } = useForm<FinancierClientUpdateRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const getDealerAgreementStatusClassName = (dealerAgreementStatus: DEALER_AGREEMENT_STATUS) => {
    switch (dealerAgreementStatus) {
      case DEALER_AGREEMENT_STATUS.ACTIVATED:
        return 'text-bold-dark-green';
      case DEALER_AGREEMENT_STATUS.SUSPENDED:
        return 'text-bold-brick-red';
      default:
        return '';
    }
  };

  const dealerAgreementsPaginate = async (pageNumber: number, sizePerPage: number) => {
    try {
      const dealerAgreementsPage = await requestFinancierDealerAgreementList(pageNumber, sizePerPage, {
        financierClientId: financierClientId,
        dealerClientId: financierClientId,
      } as FinancierDealerAgreementListRequest);

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementsPage(dealerAgreementsPage);
        dealerAgreementPageHandler.setPageable(dealerAgreementsPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const financierClientHistoriesPaginate = async (pageNumber: number, sizePerPage: number) => {
    try {
      const waitingFinancierManageDealersPage = await requestFinancierClientHistories(
        pageNumber,
        sizePerPage,
        financierClientId,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerInfoEditHistory(waitingFinancierManageDealersPage);
        dealerHistoriesPageHandler.setPageable(waitingFinancierManageDealersPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const showUpdateDealerModal = (event: any): void => {
    event.preventDefault();
    const setWillUpdateData = (data: FinancierClientUpdateRequest) => {
      setPartnerInfoForm(data, true);
    };

    const onClickClose = () => {
      setPartnerInfoForm(financierClientData);
    };

    const onClickConfirm = () => {
      setWillUpdateFinancierClientData(getValues());
      setIsSearched(true);
    };

    modal.show(
      <UpdateDealerModal
        financierClientData={financierClientData}
        propFunction={setWillUpdateData}
        isOtpUsed={isOTPUsed}
        modalId={modal.id}
      />,
      {
        modalType: ModalType.CONFIRM,
        modalSize: ModalSize.XL,
        title: t(`text:Update_Partner`),
        confirmBtnText: t(`text:Apply`),
        confirmBtnCb: () => onClickConfirm(),
        closeBtnText: t(`text:Cancel`),
        closeBtnCb: () => onClickClose(),
      },
    );
  };

  const requestUpdate = async (e: any) => {
    e.preventDefault();

    const data = getValues();
    try {
      requestDTOParser(data);
      await requestFinancierClientUpdate(financierClientId, data);
      showRequestConfirmModal();
    } catch (e) {
      modal.show(e);

      formErrorHandler<FinancierClientUpdateRequest>(e, setError, clearErrors);
    }
  };

  const showRequestConfirmModal = (): void => {
    modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
      modalType: ModalType.ALERT,
      confirmBtnText: t(`text:OK`),
      closeBtnCb: () => fetchAll(),
    });
  };

  const showChangeRequestCancelModal = (): void => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_information_modification?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_modification_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnText: t(`text:Confirm`),
        closeBtnText: t(`text:Close`),
        confirmBtnCb: () => {
          setIsEdit(false);
          setIsSearched(false);
          setPartnerInfoForm(financierClientData);

          setWillUpdateFinancierClientData({} as FinancierClientUpdateRequest);
        },
      },
    );
  };

  const renderDetailInformation = () => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Partner_Information')}>
            {userRole !== AUTHORITY_TYPE.ADMIN &&
              (!isEdit ? (
                <Button size={ButtonSizeEnum.SM} onClick={onEditClick} style={{ width: '60px' }}>
                  {t('text:Edit')}
                </Button>
              ) : (
                <>
                  <Button
                    size={ButtonSizeEnum.SM}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onCancelClick}
                  >
                    {t('text:Cancel')}
                  </Button>
                  <Button size={ButtonSizeEnum.SM} onClick={requestUpdate} style={{ width: '60px' }}>
                    {t('text:Save')}
                  </Button>
                </>
              ))}
          </SectionTitle>
          <FormBorder editable={isEdit}>
            <FormContents>
              {isEnable && isEdit && (
                <div className="row">
                  <div className="d-flex">
                    <Button size={ButtonSizeEnum.LG} onClick={showUpdateDealerModal}>
                      {t(`text:Update_Partner`)}
                    </Button>
                  </div>
                </div>
              )}
              <div className="row">
                <FormInput
                  col={6}
                  label={t(`text:Client_Code`)}
                  value={financierClientData?.financierClientCode ?? ''}
                  disabled={true}
                />
                <FormInput
                  col={6}
                  label={t(`text:Partner_Name`)}
                  name="financierClientName"
                  ref={register}
                  disabled={isReadonly('financierClientName')}
                  requiredOptions={{ required: true }}
                  error={errors.financierClientName}
                />
              </div>
              <div className="row">
                <FormInput
                  col={3}
                  requiredOptions={{
                    fixedRequired:
                      (isEnable && !isReadonly('financierClientTaxCode') && isNil(financierClientData?.enterpriseId)) ||
                      (!isEnable && isEdit && isNil(financierClientData?.enterpriseId)),
                  }}
                  label={t(`text:Tax_Code`)}
                  name="financierClientTaxCode"
                  ref={register}
                  disabled={!(!isReadonly('financierClientTaxCode') && financierClientData?.enterpriseId === null)}
                  error={errors.financierClientTaxCode}
                />
                <FormInput
                  col={3}
                  label={t(`text:Company_Registration_Number`)}
                  name="financierClientBusinessCode"
                  ref={register}
                  disabled={isReadonly('financierClientBusinessCode')}
                  error={errors.financierClientBusinessCode}
                />
                <FormInput
                  col={3}
                  label={t(`text:Telephone`)}
                  name="financierClientTelephone"
                  ref={register}
                  disabled={isReadonly('financierClientTelephone')}
                  error={errors.financierClientTelephone}
                />
                <FormInput
                  col={3}
                  label={t(`text:Fax`)}
                  name="financierClientFax"
                  ref={register}
                  disabled={isReadonly('financierClientFax')}
                  error={errors.financierClientFax}
                />
              </div>
              <div className="row">
                <FormInput
                  col={isOTPUsed ? 3 : 6}
                  label={t(`text:Legal_Representative_Name`)}
                  name="representativeName"
                  ref={register}
                  disabled={isReadonly('representativeName')}
                  error={errors.representativeName}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  name="representativePosition"
                  ref={register}
                  disabled={isReadonly('representativePosition')}
                  error={errors.representativePosition}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  name="representativeEmail"
                  ref={register}
                  disabled={isReadonly('representativeEmail')}
                  error={errors.representativeEmail}
                />
                {isOTPUsed && (
                  <FormSelect
                    col={3}
                    label={t('text:OTP_Verification')}
                    selectOptions={getSelectOptions('OTP_VERIFICATION')}
                    name="otpBypassed"
                    ref={register}
                    error={errors.otpBypassed}
                    disabled={isReadonly('otpBypassed')}
                    required={true}
                    placeholderOptions={{ show: true }}
                  />
                )}
              </div>
              <div className="row">
                <FormInput
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  name="financierClientAddress"
                  ref={register}
                  disabled={isReadonly('financierClientAddress')}
                  error={errors.financierClientAddress}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderAgreementListTable = () => {
    return dealerAgreementsPage?.content.length !== 0 ? (
      dealerAgreementsPage?.content.map((item, index) => {
        return (
          <tr key={index}>
            <td>{tableValueManage(renderAgreementType(item.collateralType))}</td>
            <td>{tableValueManage(item.contractNo)}</td>
            <td>{tableValueManage(item.currencyType)}</td>
            <td>{tableValueManage(item.branchName)}</td>
            <td>{tableValueManage(item.branchCode)}</td>
            <td>{tableValueManage(item.anchorFinancierClientName)}</td>
            <td>{tableValueManage(item.startDate, t('format:date', { value: item.startDate, key: 'datetime' }))}</td>
            <td>{tableValueManage(item.expiryDate, t('format:date', { value: item.expiryDate, key: 'datetime' }))}</td>
            <td className={getDealerAgreementStatusClassName(item.dealerAgreementStatus)}>
              {t(`code:anchor-agreement-status.${item?.dealerAgreementStatus}`)}
            </td>
            <TdLink
              path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.dealerAgreementId)}
              state={{
                dealerFinancierClientId: item.dealerFinancierClientId,
                supportedCollateralType: item.collateralType,
              }}
            />
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={10} className="text-center">
          {t('text:no_data_available')}
        </td>
      </tr>
    );
  };

  const renderHistoryListTable = () => {
    return dealerInfoEditHistory?.content.length !== 0 ? (
      dealerInfoEditHistory?.content.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              {tableValueManage(
                item.updatedDateTime,
                t('format:datetime', { value: item.updatedDateTime, key: 'datetime' }),
              )}
            </td>
            <td>{tableValueManage(item.financierClientCode)}</td>
            <td>{tableValueManage(item.name)}</td>
            <td>{tableValueManage(item.telephone)}</td>
            <td>{tableValueManage(item.fax)}</td>
            <td>{tableValueManage(item.clientBusinessCode)}</td>
            <td>{tableValueManage(item.clientTaxCode)}</td>
            <td>{tableValueManage(item.address)}</td>
            <td>{tableValueManage(item.representativeName)}</td>
            <td>{tableValueManage(item.representativePosition)}</td>
            <td>{tableValueManage(item.representativeEmail)}</td>
            {isOTPUsed && <td>{item.otyBypassed ? t('text:Not_Applied') : t('text:Applied')}</td>}
            <td>
              {tableValueManage(item.updateLoginId)} / {tableValueManage(item.updateUserName)}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={isOTPUsed ? 13 : 12} className="text-center">
          {t('text:no_data_available')}
        </td>
      </tr>
    );
  };

  const renderAgreementType = (collateralType: COLLATERAL_TYPE): string => {
    switch (collateralType) {
      case COLLATERAL_TYPE.AR:
        return t('text:Vendor_Finance');
      case COLLATERAL_TYPE.INVOICE:
        return t('text:Dealer_Finance');
    }
  };

  return (
    <>
      <BackHeaderTitle title={financierClientData?.financierClientName} />
      {renderDetailInformation()}
      <div className="content-area">
        <SectionTitle title={t('text:Master_Agreement_List')}>
          {userRole === AUTHORITY_TYPE.OPERATOR && (
            <Button to={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_LIST} size={ButtonSizeEnum.SM}>
              {t(`text:Add`)}
            </Button>
          )}
        </SectionTitle>
        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '96px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '90px' }} />
              <col style={{ width: '50px' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t(`text:Program_Type`)}</th>
                <th scope="col">{t('text:Partner_Master_Agreement_Number')}</th>
                <th scope="col">{t('text:Currency')}</th>
                <th scope="col">{t('text:Responsible_Branch_Name')}</th>
                <th scope="col">{t('text:Responsible_Branch_Code')}</th>
                <th scope="col">{t('text:Associated_Anchor_Name')}</th>
                <th scope="col">{t('text:Effective_Date')}</th>
                <th scope="col">{t('text:Expiration_Date')}</th>
                <th scope="col">{t('text:Agreement_Suspension')}</th>
                <th scope="col" className="table-column-right-fixed" />
              </tr>
            </thead>
            <tbody className="bg-white">{renderAgreementListTable()}</tbody>
          </table>
        </div>
        <Pagination pageable={dealerAgreementPageHandler.pageable} paginate={dealerAgreementsPaginate} />
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '120px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '250px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '150px' }} />
              {isOTPUsed && <col style={{ width: '150px' }} />}
              <col style={{ width: '150px' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t(`text:Edited_Time`)}</th>
                <th scope="col">{t(`text:Client_Code`)}</th>
                <th scope="col">{t(`text:Partner_Name`)}</th>
                <th scope="col">{t(`text:Telephone`)}</th>
                <th scope="col">{t(`text:Fax`)}</th>
                <th scope="col">{t(`text:Company_Registration_Number`)}</th>
                <th scope="col">{t(`text:Tax_Code`)}</th>
                <th scope="col">{t(`text:Registered_Office_Address`)}</th>
                <th scope="col">{t(`text:Legal_Representative_Name`)}</th>
                <th scope="col">{t(`text:Legal_Representative_Title`)}</th>
                <th scope="col">{t(`text:Legal_Representative_Email`)}</th>
                {isOTPUsed && <th scope="col">{t('text:OTP_Verification')}</th>}
                <th scope="col">{t('text:Updated_User_ID/Name')}</th>
              </tr>
            </thead>
            <tbody className="bg-white">{renderHistoryListTable()}</tbody>
          </table>
        </div>
        <Pagination pageable={dealerHistoriesPageHandler.pageable} paginate={financierClientHistoriesPaginate} />
      </div>
    </>
  );
}

export default FinancierManageDealerDetail;
