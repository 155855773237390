import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import useMounted from 'hooks/useMounted';

import FinancierSCPartnerAcquisitionSettingContactForm from './sections/setting-contact-form';
import FinancierSCPartnerAcquisitionSettingDocumentForm from './sections/setting-document-forms';
import { useFinancierSCPartnerAcquisitionSettingState } from './useFinancierSCPartnerAcquisitionSettingState';

function FinancierSCPartnerAcquisitionSettingPage() {
  const mounted = useMounted();
  const { t } = useTranslation();
  const { fetchAll, updateContactInfo, state, contactForm } = useFinancierSCPartnerAcquisitionSettingState();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  return (
    <>
      <BackHeaderTitle title={t('text:Acquisition_Settings')} />
      <GuideMessage
        message={[
          t(
            'text:When_the_Anchor_company_registers_AR_the_Uploaded_Suppliers_will_receive_an_email_granting_them_temporary_access_to_the_platform',
          ),
          t(
            'text:The_Uploaded_Suppliers_will_be_able_to_submit_documents_to_the_platform_and_conveniently_apply_for_vendor_financing_product',
          ),
          t(
            'text:On_this_page_the_Admin_user_can_register_the_information_that_the_Uploaded_Suppliers_will_be_provided_or_the_documents_that_they_should_submit_for_the_financier_to_review',
          ),
          t(
            'text:Uploaded_Suppliers_will_not_be_able_to_see_or_submit_the_document_types_that_have_been_hidden_from_view',
          ),
        ]}
      />
      <FormProvider {...contactForm}>
        <FinancierSCPartnerAcquisitionSettingContactForm
          data={state.contactInfo}
          updateContactInfo={updateContactInfo}
        />
      </FormProvider>

      <FinancierSCPartnerAcquisitionSettingDocumentForm
        activatedList={state.activatedDocumentList}
        deactivatedList={state.deactivatedDocumentList}
      />
    </>
  );
}

export default FinancierSCPartnerAcquisitionSettingPage;
