import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FactoringNoticeVOModel } from 'models/vo/FactoringNoticeVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierFactoringNoticeList } from 'utils/http/api/financier/factoring-notices';
import useModal from 'utils/modal/useModal';
import { updateSearchParams } from 'utils/searchParams';

const FI_FACTORING_NOTICE_LIST_QS_KEY = 'fi-factoring-notice-list-qs-key';

function FinancierDeptSellingNoticeList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const [financierFactoringNoticeList, setFinancierFactoringNoticeList] =
    useState<Pageable<FactoringNoticeVOModel[]>>();
  const { pageable, setPageable } = usePageable(FI_FACTORING_NOTICE_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      fetchFinancierFactoringNoticeList();
    }
  }, [mounted]);

  const fetchFinancierFactoringNoticeList = async () => {
    updateSearchParams(
      { pageNumber: pageable.currentPage, rowCount: pageable.sizePerPage },
      FI_FACTORING_NOTICE_LIST_QS_KEY,
    );

    try {
      const fetchedFinancierFactoringNoticeList = await requestFinancierFactoringNoticeList(
        pageable.currentPage,
        pageable.sizePerPage,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierFactoringNoticeList(fetchedFinancierFactoringNoticeList);
        setPageable(fetchedFinancierFactoringNoticeList);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const paginate = async (page: number, sizePerPage: number) => {
    try {
      const fetchedAnchorFactoringNoticeList = await requestFinancierFactoringNoticeList(page, sizePerPage);

      updateSearchParams({ pageNumber: page, rowCount: sizePerPage }, FI_FACTORING_NOTICE_LIST_QS_KEY);

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierFactoringNoticeList(fetchedAnchorFactoringNoticeList);
        setPageable(fetchedAnchorFactoringNoticeList);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const ConfirmNoticeTableHeaders: HeaderType[] = [
    {
      headerText: t('text:Sending_Date'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Total_Financing_Amount'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Reception_Status'),
    },
    {
      headerText: t('text:Confirmation_Status'),
    },
    {
      headerText: t('text:Confirmed_User'),
    },
    {
      headerText: '',
    },
  ];

  const renderConfirmNoticeTableList = () => {
    return financierFactoringNoticeList?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.sentDateTime} format="date" />
        <Td data={item.anchorName} />
        <Td data={item.partnerName} />
        <Td data={item.totalRequestedAmount ?? item.requestedAmount} format="number" />
        <Td data={item.currencyType} />
        <Td data={`${item.receivedUserCount}/${item.totalReceiverCount}`} />
        <Td
          className={getStatusTextClass('FACTORING_NOTICE_CONFIRMATION_STATUS', item.confirmationStatus)}
          data={t(`code:factoring-notice-confirmation-status.${item.confirmationStatus}`)}
          format="code"
        />
        <Td data={`${item.confirmedUserName} (${t(`code:authority-type.${item.confirmedUserAuthority}`)})`} />
        <TdLink path={ROUTES_FI.VIEW_TRANSACTION.DEPT_SELLING_NOTICE_BUILD_PATH(item.factoringNoticeId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Confirmation_Notification')} />
      <div className="content-area">
        <SectionTitle title={t('text:Confirm_Receipt_of_Debt_Selling_Notification_Mail')} />
      </div>
      <GuideMessage
        message={[
          t('text:Please_check_the_Debt_Selling_Notification_mail_sent_to_the_anchor_company'),
          t('text:Check_how_many_anchor_company_users_have_received_your_email_in_the_reception_status_field'),
          t(
            'text:If_the_anchor_company_user_has_confirmed_receipt_of_the_mail_on_the_platform_the_Confirmation_Status_field_will_show_Confirmed',
          ),
        ]}
      />
      <div className="content-area">
        <TableBorder>
          <TableHeader header={ConfirmNoticeTableHeaders} />
          <TableBody numOfCol={ConfirmNoticeTableHeaders.length}>{renderConfirmNoticeTableList()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierDeptSellingNoticeList;
