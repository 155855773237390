import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { Cell, Content, Row, SubTitle } from 'components/templates/section';
import { useUppercaseInput } from 'hooks/useUppercaseInput';

import useDisbursementAccountController from './useDisbursementAccountController';

const DisbursementAccount = () => {
  const { t } = useTranslation();
  const handleUppercaseInput = useUppercaseInput();

  const { isShowAccountInput, isAr } = useDisbursementAccountController();

  return (
    <>
      <Content>
        {isAr && (
          <Row>
            <Form.Control name="anchorAccount" required>
              <Cell>
                <Form.Label position="top">{t('text:Disbursement_Account')}</Form.Label>
                <Form.Description>
                  {t(
                    'text:If_you_select_a_partner_account_financing_disbursements_will_be_made_directly_to_the_partner_account_registered_by_the_anchor',
                  ) +
                    ' ' +
                    t(
                      'text:If_an_agreement_exists_disbursement_will_be_made_to_the_account_according_to_the_partner_agreement',
                    )}
                </Form.Description>
              </Cell>
              <Form.Radio defaultValue="true">
                <Form.Option label={`${t('text:Anchor_Account')} (${t('text:Default')})`} value="true" />
                <Form.Option label={t('text:Partner_Account')} value="false" />
              </Form.Radio>
            </Form.Control>
          </Row>
        )}
      </Content>
      {isShowAccountInput && (
        <>
          <SubTitle>{t('text:DISBURSEMENT_ACCOUNT')}</SubTitle>
          <Content>
            <Row singleColumnPosition="top">
              <SectionMessage
                messageType="description"
                message={[
                  t('text:The_financing_principal_amount_will_be_collected_from_this_bank_account_for_disbursement'),
                ]}
              />
              <Form.Control name="disbursementAccountFinancierName" required>
                <Form.Label>{t('text:Bank_Name')}</Form.Label>
                <Form.Input />
              </Form.Control>
              <Form.Control name="disbursementAccountBranchName" required>
                <Form.Label>{t('text:Branch_Name')}</Form.Label>
                <Form.Input />
              </Form.Control>
              <Form.Control name="disbursementAccount" required>
                <Form.Label>{t('text:Bank_Account_Number')}</Form.Label>
                <Form.Input onChange={handleUppercaseInput} />
              </Form.Control>
              <Form.Control name="disbursementAccountOwner" required>
                <Form.Label>{t('text:Bank_Account_Holder')}</Form.Label>
                <Form.Input onChange={handleUppercaseInput} />
              </Form.Control>
            </Row>
          </Content>
        </>
      )}
    </>
  );
};

export default DisbursementAccount;
