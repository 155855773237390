import type Pageable from 'models/Pageable';
import type { DeletedInvoiceVO, DeletedInvoiceVOModel } from 'models/vo/DeletedInvoiceVO';
import { formattingToDeletedInvoiceVOModel } from 'models/vo/DeletedInvoiceVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorDeletedInvoicesListRequest } from './requests';

// 앵커 - 등록 완료된 AR 목록 조회
export async function requestAnchorDeletedInvoicesList(
  pageNumber: number,
  rowCount: number,
  searchValue?: AnchorDeletedInvoicesListRequest,
): Promise<Pageable<DeletedInvoiceVOModel[]>> {
  const response = await http.get<Pageable<DeletedInvoiceVO[]>>({
    url: API_AC.DELETED_INVOICES.DELETED_INVOICES,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return { ...response, content: response.content.map(data => formattingToDeletedInvoiceVOModel(data)) };
}
