import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import type { CreateFinancierAndAdminUserDTO } from 'utils/http/api/anonymous/on-demand/requests';

import { useCreateFinancierStep1Value } from './CreateFinancierStep1Provider';
import CommonSettings from './sections/CommonSettings';
import CurrencySettings from './sections/CurrencySettings';
import FinancierInformation from './sections/FinancierInformation';
import OtpSettings from './sections/OtpSettings';
import { useCreateFinancierActions } from '../../CreateFinancierProvider';

const CreateFinancierStep1 = () => {
  const { t } = useTranslation();

  const { onStepTransition, onCancelClick: handleCancelClick } = useCreateFinancierActions();
  const { supportedCurrencyList } = useCreateFinancierStep1Value();
  const { setValue } = useFormContext<CreateFinancierAndAdminUserDTO>();

  const handleNextClick = () => {
    setValue('supportedCurrencyTypes', supportedCurrencyList);
    onStepTransition(1);
  };

  return (
    <>
      <FinancierInformation />
      <div className="pb-5" />
      <CommonSettings />
      <div className="pb-5" />
      <OtpSettings />
      <div className="pb-5" />
      <CurrencySettings />
      <div className="d-flex mt-5">
        <Button
          className="me-auto"
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
          size={ButtonSizeEnum.LG}
          onClick={handleCancelClick}
        >
          {t('text:Cancel')}
        </Button>

        <Button size={ButtonSizeEnum.LG} onClick={handleNextClick}>
          {t('text:Next')}
        </Button>
      </div>
    </>
  );
};
export default CreateFinancierStep1;
