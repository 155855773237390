import fileDownload from 'js-file-download';
import { PDFDocument } from 'pdf-lib';

import { EXPORT_DOCUMENT_TYPE } from 'enums';
import i18n from 'locales/i18n';

import type { CalculateAvailableRowHeight, PaperSize } from './exportPDF.d';

const A4_SIZE: PaperSize = <const>{
  width: 297,
  height: 210,
};

const LETTER_SIZE: PaperSize = <const>{
  width: 279.4,
  height: 215.9,
};

const PAPER_TYPE = <const>{
  [EXPORT_DOCUMENT_TYPE.PDF_A4]: A4_SIZE,
  [EXPORT_DOCUMENT_TYPE.PDF_LETTER]: LETTER_SIZE,
};

const DEFAULT_FILE_NAME = `${i18n.t('text:Report')}.pdf`;

const tableRowBorderHeight = 0.35;

const calculateAvailableRowHeight = (
  tableRowList: HTMLElement[],
  availableMaxHeight: number,
  borderHeight: number,
): CalculateAvailableRowHeight => {
  let nextRowIndex = -1;
  let isBreak = false;

  const rowHeight = tableRowList.reduce((prev, acc, index) => {
    if (index === 0) {
      return acc.getBoundingClientRect().height - borderHeight;
    } else if (isBreak) {
      return prev;
    } else if (prev + acc.getBoundingClientRect().height + borderHeight > availableMaxHeight) {
      if (nextRowIndex === -1) {
        nextRowIndex = index;
        isBreak = true;
      }

      return prev;
    } else {
      return prev + acc.getBoundingClientRect().height - borderHeight;
    }
  }, 0);

  return {
    height: rowHeight,
    cuttedRowList: nextRowIndex === -1 ? [] : tableRowList.splice(nextRowIndex),
    toAppendRowList: tableRowList.slice(0, nextRowIndex === -1 ? tableRowList.length : nextRowIndex),
  };
};

const exportPDF = (targetPaperType: 'PDF_A4' | 'PDF_LETTER') => {
  let tableRowDOMList: HTMLElement[] = Array.prototype.slice.call(document.getElementsByClassName('virtual-table-row'));
  let calculatedTableRowInfo;

  const convertToImg = async () => {
    // html to imageFile
    const targetDOM = document.getElementById('pdf-target');
    const mergedTableHeaderDOM = document.getElementsByClassName('merged-virtual-table-header');
    const tableHeaderDOM = document.getElementById('virtual-table-header');
    const tableBodyDOM = document.getElementById('virtual-table-body');

    if (targetDOM && tableHeaderDOM && tableRowDOMList && tableBodyDOM) {
      const tableImageWidth = targetDOM.getBoundingClientRect().width;

      const tableHeaderHeight =
        (mergedTableHeaderDOM.length !== 0
          ? Array.from(mergedTableHeaderDOM).reduce(
              (acc, cur) => acc + (cur as HTMLElement).getBoundingClientRect().height,
              0,
            ) + tableRowBorderHeight
          : 0) +
        tableHeaderDOM.getBoundingClientRect().height +
        tableRowBorderHeight;

      const cuttedTableImageMaxHeight =
        (PAPER_TYPE[targetPaperType]['height'] * tableImageWidth) / PAPER_TYPE[targetPaperType]['width'];

      calculatedTableRowInfo = calculateAvailableRowHeight(
        tableRowDOMList,
        cuttedTableImageMaxHeight - tableHeaderHeight,
        tableRowBorderHeight,
      );

      tableRowDOMList = calculatedTableRowInfo.cuttedRowList;

      calculatedTableRowInfo.toAppendRowList.forEach(tableRowItem => {
        const cloneNode = tableRowItem.cloneNode(true);
        tableBodyDOM.appendChild(cloneNode);
      });

      const { default: html2canvas } = await import('html2canvas');
      const canvas = await html2canvas(targetDOM);
      const imageFile = canvas.toDataURL('image/png', 1.0);

      tableBodyDOM.innerHTML = '';

      return imageFile;
    } else {
      throw 'Not enough PDF related DOMs rendered';
    }
  };

  const convertToPdf = async (imageURLs: string[]) => {
    const pdfDoc = await PDFDocument.create();
    const pdfFileName = DEFAULT_FILE_NAME;
    const pdfWidth = PAPER_TYPE[targetPaperType]['width'];
    const pdfHeight = PAPER_TYPE[targetPaperType]['height'];

    for (let i = 0; i < imageURLs.length; i++) {
      const image = await pdfDoc.embedPng(imageURLs[i]);
      const page = pdfDoc.addPage([pdfWidth, pdfHeight]);
      const imageHeight = (image.height * pdfWidth) / image.width;

      page.drawImage(image, {
        x: 0,
        y: pdfHeight - imageHeight,
        width: pdfWidth,
        height: imageHeight,
        opacity: 1,
      });
    }

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    fileDownload(pdfBlob, pdfFileName);
  };

  return {
    viewWithPdf: async () => {
      const imageURLs: string[] = [];

      // html to imageFile
      while (tableRowDOMList.length > 0) {
        imageURLs.push(await convertToImg());
      }

      // imageFile to Pdf
      await convertToPdf(imageURLs);
    },
  };
};

export { PAPER_TYPE, exportPDF };
