import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { ANCHOR_AGREEMENT_STATUS, COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorAgreementsList } from 'utils/http/api/anchor/anchor-agreements';
import type { AnchorAgreementListRequest } from 'utils/http/api/anchor/anchor-agreements/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const useConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const AC_INVOICE_REGISTER_BY_ANCHOR_LIST_QS_KEY = 'ac-invoice-register-by-anchor-list';

  const SEARCH_RESULT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 160,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 60,
    },
  ];

  return {
    mounted,
    modal,
    t,
    SEARCH_RESULT_TABLE_HEADERS,
    AC_INVOICE_REGISTER_BY_ANCHOR_LIST_QS_KEY,
  };
};

function AnchorInvoiceRegistrationStep1(): JSX.Element {
  const { mounted, modal, t, SEARCH_RESULT_TABLE_HEADERS, AC_INVOICE_REGISTER_BY_ANCHOR_LIST_QS_KEY } = useConstants();
  const { register, getValues, reset, setValue } = useForm<AnchorAgreementListRequest>();

  const [anchorAgreementPageData, setAnchorAgreementPageData] = useState<Pageable<AnchorAgreementVOModel[]>>();

  const { pageable, setPageable } = usePageable(AC_INVOICE_REGISTER_BY_ANCHOR_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<AnchorAgreementListRequest>(
        setValue,
        getParsedSearchParams(AC_INVOICE_REGISTER_BY_ANCHOR_LIST_QS_KEY).formSearchData,
      );

      fetchAcAnchorAgreementList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  const fetchAcAnchorAgreementList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    searchData: AnchorAgreementListRequest,
  ): Promise<void> => {
    try {
      const anchorAgreementPageResponse = await requestAnchorAgreementsList(pageNumber, rowCount, {
        ...searchData,
        collateralType: COLLATERAL_TYPE.INVOICE,
        agreementStatus: ANCHOR_AGREEMENT_STATUS.ACTIVATED,
      });
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        AC_INVOICE_REGISTER_BY_ANCHOR_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementPageData(anchorAgreementPageResponse);
        setPageable(anchorAgreementPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearch = async (e: any): Promise<void> => {
    e.preventDefault();
    await fetchAcAnchorAgreementList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    await fetchAcAnchorAgreementList(
      pageNumber,
      sizePerPage,
      getParsedSearchParams(AC_INVOICE_REGISTER_BY_ANCHOR_LIST_QS_KEY).formSearchData,
    );
  };

  const renderSearchResultTable = (): JSX.Element[] | null => {
    if (!anchorAgreementPageData?.content) {
      return null;
    }

    return anchorAgreementPageData.content.map(anchorAgreement => (
      <tr key={anchorAgreement.anchorAgreementId}>
        <td>{anchorAgreement.financierName}</td>
        <td>{anchorAgreement.contractNo}</td>
        <td>{anchorAgreement.currencyType}</td>
        <Td className="information-table-more">
          <Button
            size={ButtonSizeEnum.XS}
            to={ROUTES_AC.REGISTER_INVOICE.REGISTRATION_STEP2_BUILD_PATH(
              anchorAgreement.anchorAgreementId,
              anchorAgreement.financierId,
            )}
          >
            {t('text:Select')}
          </Button>
        </Td>
      </tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Invoice_Registration')} />
      <div className="content-area">
        <StepWizard nth={1} title={[t('text:Select_Anchor_Master_Agreement'), t('text:Enter_Invoice_Information')]} />
      </div>

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Anchor_Master_Agreement_List')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name="contractNo" ref={register} />
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <TableBorder>
          <TableHeader header={SEARCH_RESULT_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCH_RESULT_TABLE_HEADERS.length}>{renderSearchResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorInvoiceRegistrationStep1;
