import { useFormContext } from 'components/stateless/Form';

import useRegistrationAssociatedAnchorMasterAgreementController from './useRegistrationAssociatedAnchorMasterAgreementController';
import useRegistrationDetailAssociatedAnchorMasterAgreementController from './useRegistrationDetailAssociatedAnchorMasterAgreementController';
import useWaitingAssociatedAnchorMasterAgreementController from './useWaitingAssociatedAnchorMasterAgreementController';
import useExtraInformationViewModel from '../../models/extraInformation/useExtraInformationViewModel';

const useAssociatedAnchorMasterAgreementController = () => {
  const { getControllerByPageType } = useExtraInformationViewModel();
  const {
    methods: { register, errors, watch },
  } = useFormContext();

  const controller = getControllerByPageType({
    register: useRegistrationAssociatedAnchorMasterAgreementController,
    registeredDetail: useRegistrationDetailAssociatedAnchorMasterAgreementController,
    waitingDetail: useWaitingAssociatedAnchorMasterAgreementController,
  });

  return { ...controller, register, errors, watch };
};

export default useAssociatedAnchorMasterAgreementController;
