import type Pageable from 'models/Pageable';
import type { BranchVO, BranchVOModel } from 'models/vo/BranchVO';
import { formattingToBranchVOModel } from 'models/vo/BranchVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

// 금융사의 지점 조회
export async function requestAnFinancierBranch(
  pageNumber: number,
  rowCount: number,
  code: string,
  financierId: number,
): Promise<Pageable<BranchVOModel[]>> {
  const response = await http.get<Pageable<BranchVO[]>>({
    url: API_AN.BRANCHES.BRANCHES,
    data: {
      pageNumber,
      rowCount,
      code,
      financierId,
    },
  });
  const FinancierBranchList: BranchVOModel[] = response.content.map((data: BranchVO) =>
    formattingToBranchVOModel(data),
  );

  return {
    ...response,
    content: FinancierBranchList,
  };
}

// 은행 가입 - 가입 대상 지점 조회
export async function requestInvitedBranch(code: string): Promise<BranchVOModel> {
  const response = await http.get<BranchVO>({
    url: API_AN.BRANCHES.INVITE_BRANCH,
    data: { code },
  });

  return formattingToBranchVOModel(response);
}
