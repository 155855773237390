import { useState } from 'react';
import { useParams } from 'react-router-dom';

import type { BranchVOModel } from 'models/vo/BranchVO';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import type { PotentialPartnerAcquisitionSettingVOModel } from 'models/vo/PotentialPartnerAcquisitionSettingVO';
import { requestAnFinancierBranch } from 'utils/http/api/anonymous/branches';
import { requestAnFinancierPotentialPartnerAcquisitionDocumentList } from 'utils/http/api/anonymous/potential-partner-acquisition-documents';
import { requestAnPotentialPartnerAcquisitionSettingFindOneByFinancier } from 'utils/http/api/anonymous/potential-partner-acquisition-settings';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';

interface PotentialPartnerFinancierDetailStateType {
  financierPotentialPartnerAcquisitionSetting: PotentialPartnerAcquisitionSettingVOModel;
  branchesInfo: BranchVOModel[];
  requiredPotentialPartnerAcquisitionDocument: PotentialPartnerAcquisitionDocumentVOModel[];
}

export function usePotentialPartnerFinancierDetailState() {
  const emailCode = getPotentialPartnerEmailCode();
  const modal = useModal();
  const { financierId } = useParams() as any;

  const [dataState, setDataState] = useState<PotentialPartnerFinancierDetailStateType>({
    financierPotentialPartnerAcquisitionSetting: {} as PotentialPartnerAcquisitionSettingVOModel,
    branchesInfo: [] as BranchVOModel[],
    requiredPotentialPartnerAcquisitionDocument: [] as PotentialPartnerAcquisitionDocumentVOModel[],
  });

  async function fetchAll() {
    if (!emailCode) return modal.show('not found email code');
    try {
      const [fetchFinancierAcquisitionSetting, fetchBranch, fetchFinancierPotentialPartnerAcquisitionDocuments] =
        await Promise.all([
          requestAnPotentialPartnerAcquisitionSettingFindOneByFinancier(emailCode, financierId),
          requestAnFinancierBranch(0, 100, emailCode, financierId),
          requestAnFinancierPotentialPartnerAcquisitionDocumentList(0, 100, emailCode, financierId),
        ]);

      setDataState(prevState => ({
        ...prevState,
        financierPotentialPartnerAcquisitionSetting: fetchFinancierAcquisitionSetting,
        branchesInfo: fetchBranch.content,
        requiredPotentialPartnerAcquisitionDocument: fetchFinancierPotentialPartnerAcquisitionDocuments.content,
      }));
    } catch (e) {
      modal.show(e);
    }
  }

  return {
    state: dataState,
    fetchAll,
  };
}
