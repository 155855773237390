import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import {
  convertToAnchorAgreementResetData,
  convertToInterfaceAnchorAgreementData,
} from 'models/convertor/anchorAgreement';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { requestFinancierAnchorAgreementIdRegisteredInterface } from 'utils/http/api/financier/anchor-agreements';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import SearchInterfaceByIdModal from '../../../modals/SearchInterfaceByIdModal';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorClientInfoViewModel from '../../../models/agreement/useAnchorClientInfoViewModel';
import useDivisionsViewModel from '../../../models/divisions/useDivisionsViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useRegistrationDetailLoadAgreementController = () => {
  const { show: showModal } = useModal();
  const { t } = useTranslation(['format']);

  const { anchorAgreementId, useAnchorUserInterface } = useExtraInformationViewModel();
  const { updateAllAgreement, updateIsSearchedAgreement } = useAgreementViewModel();
  const { updateDivisionName } = useDivisionsViewModel();
  const { anchorClientInfo } = useAnchorClientInfoViewModel();

  const interfaceDataRef = useRef<AnchorAgreementDetailVOModel>({} as AnchorAgreementDetailVOModel);

  const {
    methods: { reset },
  } = useFormContext();

  const setSearchedInterfaceData = useCallback(searchedData => {
    interfaceDataRef.current = searchedData;
  }, []);

  const handleApplyClick = () => {
    const convertedInterfaceAgreement = convertToInterfaceAnchorAgreementData({
      prevAnchorClientData: anchorClientInfo,
      interfaceData: interfaceDataRef.current,
      useAnchorUserInterface,
    });

    if (convertedInterfaceAgreement) {
      updateIsSearchedAgreement(true);
      updateAllAgreement({
        ...convertedInterfaceAgreement,
        anchorAgreementId,
      });
      updateDivisionName(convertedInterfaceAgreement.divisionName ?? '');
      reset(convertToAnchorAgreementResetData(convertedInterfaceAgreement));
    }
  };

  const showSearchInterfaceByIdModal = async () => {
    try {
      showLoadingUI();
      const interfaceData = await requestFinancierAnchorAgreementIdRegisteredInterface(anchorAgreementId);
      setSearchedInterfaceData(interfaceData);

      showModal(<SearchInterfaceByIdModal searchedInterfaceData={interfaceData} />, {
        modalType: ModalType.CONFIRM,
        modalSize: ModalSize.XL,
        title: t('text:Update_Agreement'),
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Apply'),
        confirmBtnCb: handleApplyClick,
      });
    } catch (e) {
      showModal(e);
    } finally {
      unShowLoadingUI();
    }
  };

  const handleLoadAgreementButtonClick = () => {
    showSearchInterfaceByIdModal();
  };

  return { handleLoadAgreementButtonClick };
};

export default useRegistrationDetailLoadAgreementController;
