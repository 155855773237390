import {
  requestDealerAccountAttachmentDownload,
  requestDealerPurposeAttachmentDownload,
} from 'utils/http/api/dealer/purpose-of-loans';
import useModal from 'utils/modal/useModal';

export function useDealerFinancingDetailPurposeOfFinancingListLogics() {
  const modal = useModal();

  const onClickDownloadAccountFile = async (e: any, purposeOfLoanId: number) => {
    e.preventDefault();
    try {
      await requestDealerAccountAttachmentDownload(purposeOfLoanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickDownloadPurposeFile = async (e: any, purposeOfLoanId: number) => {
    e.preventDefault();
    try {
      await requestDealerPurposeAttachmentDownload(purposeOfLoanId);
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    onClickDownloadAccountFile,
    onClickDownloadPurposeFile,
  };
}
