import { Redirect, Route } from 'react-router';

import { ROLE } from 'enums';
import { getSignIn } from 'utils/storage/LocalStorage';

const translateRoleToMatchUrl = () => {
  const role = getSignIn()?.authorities[0].authority;

  if (role) {
    switch (role) {
      case ROLE.ROLE_FINANCIER:
        return '/financier';
      case ROLE.ROLE_ANCHOR:
        return '/anchor';
      case ROLE.ROLE_DEALER:
        return '/dealer';
      case ROLE.ROLE_SYSTEM:
        return '/system';
    }
  }
};

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        const isMatchedUrl = translateRoleToMatchUrl() === props.match.url;

        if (!isMatchedUrl) return <Redirect to="/" />;

        return getSignIn() ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  );
};
