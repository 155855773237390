import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';

import AnchorFinancingDetailArInformation from './sections/ar-information';
import AnchorFinancingDetailDesignatedBankAccountInformation from './sections/designated-bank-account-information';
import AnchorFinancingDetailFinancingRequestInformation from './sections/financing-request-information';
import AnchorFinancingDetailGuideMessage from './sections/guide-message';
import AnchorFinancingDetailInvoiceInformation from './sections/invoice-information';
import AnchorFinancingDetailSummary from './sections/summary';
import { useAcAnchorLoanDetailState } from './useAcAnchorLoanDetailState';

function AcAnchorLoanDetail() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const history = useHistory();

  const { state, fetchAll, onClickViewExpectedRepaymentAmount } = useAcAnchorLoanDetailState();

  const { loanDetail } = state;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Details')} />
      <AnchorFinancingDetailGuideMessage data={loanDetail} />

      <AnchorFinancingDetailSummary
        data={loanDetail}
        onClickViewExpectedRepaymentAmount={onClickViewExpectedRepaymentAmount}
      />

      <AnchorFinancingDetailFinancingRequestInformation data={loanDetail} />
      {loanDetail.collateralType === COLLATERAL_TYPE.INVOICE && (
        <AnchorFinancingDetailInvoiceInformation invoiceData={state.invoiceDetail} />
      )}
      {loanDetail.collateralType === COLLATERAL_TYPE.AR && (
        <AnchorFinancingDetailArInformation arData={state.arDetail} />
      )}

      <AnchorFinancingDetailDesignatedBankAccountInformation data={loanDetail} />

      <div className="content-area flex-end">
        <Button
          size={ButtonSizeEnum.LG}
          onClick={() => {
            history.push(
              ROUTES_AC.MANAGE_ANCHOR_FINANCING.BULK_FINANCING_DETAIL_BUILD_PATH(
                loanDetail.anchorMultipleLoanRequestId,
              ),
            );
          }}
        >
          {t('text:Go_to_Bulk_Financing_Page')}
        </Button>
      </div>
    </>
  );
}

export default AcAnchorLoanDetail;
