import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { PageableType } from 'hooks/usePageable';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';

interface FinancierFinancingDetailEarlyRepaymentRequestInformationProps {
  earlyRepaymentListData: EarlyRepaymentRequestVOModel[];
  pageable: PageableType;
  paginate(pageNumber: number, rowCount: number): Promise<void>;
}

function FinancierFinancingDetailEarlyRepaymentRequestInformation({
  earlyRepaymentListData,
  pageable,
  paginate,
}: FinancierFinancingDetailEarlyRepaymentRequestInformationProps) {
  const { t } = useTranslation(['format']);

  const EARLY_REPAYMENT_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 200,
    },
    {
      headerText: t('text:Requested_Repayment_Date'),
      colWidths: 200,
    },
    {
      headerText: t('text:Status'),
      colWidths: 150,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Early_Repayment_Request_Information')} />
      <TableBorder>
        <TableHeader header={EARLY_REPAYMENT_TABLE_HEADER} />
        <TableBody numOfCol={EARLY_REPAYMENT_TABLE_HEADER.length}>
          {earlyRepaymentListData?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td data={item.createdDateTime} format="datetime" />
                <Td data={item.intendedRepaymentDate} format="date" />
                <Td
                  data={t(`code:early-repayment-request-status.${item.earlyRepaymentRequestStatus}`)}
                  className={getStatusTextClass('EARLY_REPAYMENT_REQUEST_STATUS', item.earlyRepaymentRequestStatus)}
                />
                <TdLink
                  path={ROUTES_FI.MANAGE_FINANCING.EARLY_REPAYMENT_DETAIL_BUILD_PATH(item.earlyRepaymentRequestId)}
                />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default FinancierFinancingDetailEarlyRepaymentRequestInformation;
