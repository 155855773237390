import type Pageable from 'models/Pageable';
import type { ArSummaryVO, ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import { formattingToArSummaryVOModel } from 'models/vo/ArSummaryVO';
import type { LastArPhaseVO, LastArPhaseVOModel } from 'models/vo/LastArPhaseVO';
import { formattingToLastArPhaseVOModel } from 'models/vo/LastArPhaseVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierArPhaseApproveRequest, FinancierArSummariesListRequest } from './request';

// 매출채권 목록 조회
export async function requestFinancierArSummaryList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierArSummariesListRequest,
): Promise<Pageable<ArSummaryVOModel[]>> {
  const response = await http.get<Pageable<ArSummaryVO[]>>({
    url: API_FI.AR_SUMMARIES.AR_SUMMARIES,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const arSummaryList: ArSummaryVOModel[] = response.content.map((data: ArSummaryVO) =>
    formattingToArSummaryVOModel(data),
  );
  const arSummaryPage: Pageable<ArSummaryVOModel[]> = {
    ...response,
    content: arSummaryList,
  };

  return arSummaryPage;
}

// 매출채권 상세 조회
export async function requestFinancierArSummaryDetail(arSummaryId: number): Promise<ArSummaryVOModel> {
  const response = await http.get<ArSummaryVO>({
    url: API_FI.AR_SUMMARIES.AR_SUMMARY_DETAIL(arSummaryId),
  });

  return formattingToArSummaryVOModel(response);
}

export async function requestFinancierLastPhaseArPhasesData(arSummaryId: number): Promise<LastArPhaseVOModel> {
  const response = await http.get<LastArPhaseVO>({
    url: API_FI.AR_SUMMARIES.AR_SUMMARY_FIND_LAST_PHASE_WITH_ARS(arSummaryId),
  });

  return formattingToLastArPhaseVOModel(response);
}

export async function requestFinancierApproveArPhase(data: FinancierArPhaseApproveRequest): Promise<void> {
  await http.put<null>({
    url: API_FI.AR_SUMMARIES.AR_SUMMARY_APPROVE,
    data,
  });
}

export async function requestFinancierRejectArRegistration(arSummaryId: number, reason: string): Promise<void> {
  await http.put<null>({
    url: API_FI.AR_SUMMARIES.AR_SUMMARY_REJECT,
    data: {
      arSummaryId,
      reason,
    },
  });
}
