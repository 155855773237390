import type { AUTHORITY_TYPE, WAITING_INVOICE_APPROVAL_PHASE_STATUS } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface InvoicePhaseVO {
  invoiceSummaryId: number;
  waitingInvoiceApprovalPhaseId: number;
  count: number;
  amount: string;
  invoiceApprovalPhaseStatus: WAITING_INVOICE_APPROVAL_PHASE_STATUS;
  approveEnterpriseId: number;
  approveEnterpriseName: string;
  approveUserId: number;
  approveUserName: string;
  createdDateTime: string;
  approveUserAuthorityType: AUTHORITY_TYPE;
  approveUserLoginId: number;
  inProgress: boolean;
}

export interface InvoicePhaseVOModel extends InvoicePhaseVO {
  amount: BigNumber;
}

export function formattingToInvoicePhaseVOModel(data: InvoicePhaseVO): InvoicePhaseVOModel {
  const { amount } = data;

  return {
    ...data,
    amount: sliceZeroDecimal(amount),
  };
}
