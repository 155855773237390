import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { AUTHORITY_TYPE, ROLE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { UserVOModel } from 'models/vo/UserVO';
import { useSignInStore } from 'store';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierSupportData } from 'utils/http/api/common/financier-common-setting';
import { requestSystemLogin } from 'utils/http/api/system/login';
import { requestSystemUserDetail } from 'utils/http/api/system/users';
import useModal from 'utils/modal/useModal';
import { homePathByRole } from 'utils/route';
import { setFinancierSupportData, setSignIn } from 'utils/storage/LocalStorage';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  return {
    mounted,
    modal,
    t,
  };
};

function SystemMonitorPlatformUserDetail(): JSX.Element {
  const { mounted, modal, t } = getConstants();

  const history = useHistory();
  const { setStoreSignIn } = useSignInStore();
  const { userId } = useParams() as any;

  const [systemMonitorPlatformUserData, setSystemMonitorPlatformUserData] = useState<UserVOModel>();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const systemMonitorPlatformUserData = await requestSystemUserDetail(userId);

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemMonitorPlatformUserData(systemMonitorPlatformUserData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getAuthorityTypeText = (authorityType?: AUTHORITY_TYPE): string => {
    switch (authorityType) {
      case AUTHORITY_TYPE.ADMIN:
        return t('text:Admin');
      case AUTHORITY_TYPE.AUTHORIZER:
        return t('text:Authorizer');
      case AUTHORITY_TYPE.OPERATOR:
        return t('text:Operator');
      case AUTHORITY_TYPE.HQ_OPERATOR:
        return t('text:Admin_Operator');
      default:
        return '';
    }
  };

  const fetchAndSetSignIn = async (): Promise<keyof typeof ROLE> => {
    const signInModel = await requestSystemLogin(systemMonitorPlatformUserData?.userId as number);
    signInModel.signInTime = new Date();
    setSignIn(signInModel);
    setStoreSignIn(signInModel);

    return signInModel.authorities[0].authority;
  };

  const fetchAndSetSupportData = async () => {
    const financierSupportData = await requestFinancierSupportData();
    setFinancierSupportData(financierSupportData);
  };

  const onClickAccessAccountButton = async () => {
    try {
      const authorityType = await fetchAndSetSignIn();
      if (authorityType !== ROLE.ROLE_SYSTEM) {
        await fetchAndSetSupportData();
      }

      history.push(homePathByRole(authorityType));
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:User_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:User_Information')}>
          <div className="flex-end align-items-center">
            <span className="me-1">{t('text:Go_to_Enterprise_Details')}</span>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              className="flex-column-center"
              onClick={() => {
                history.push(
                  ROUTES_SY.MONITOR_PLATFORM.ENTERPRISE_DETAIL_BUILD_PATH(systemMonitorPlatformUserData?.enterpriseId!),
                );
              }}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </SectionTitle>
        <FormBorder>
          <FormSubtitle title={t('text:ENTERPRISE_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue col={5} label={t('text:Enterprise_ID')} value={systemMonitorPlatformUserData?.enterpriseId} />
              <FormValue
                col={5}
                label={t('text:Enterprise_Name')}
                value={systemMonitorPlatformUserData?.enterpriseName}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:USER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue col={5} label={t('text:User_Name')} value={systemMonitorPlatformUserData?.userName} />
              <FormValue col={5} label={t('text:User_ID')} value={systemMonitorPlatformUserData?.userId} />
            </div>
            <div className="row">
              <FormValue col={5} label={t('text:User_Code')} value={systemMonitorPlatformUserData?.employeeCode} />
              <FormValue col={5} label={t('text:Mobile')} value={systemMonitorPlatformUserData?.mobile} />
            </div>
            <div className="row">
              <FormValue col={5} label={t('text:Title')} value={systemMonitorPlatformUserData?.position} />
              <FormValue col={5} label={t('text:Department')} value={systemMonitorPlatformUserData?.department} />
            </div>
            <div className="row">
              <FormValue col={5} label={t('text:Email')} value={systemMonitorPlatformUserData?.email} />
              <FormValue col={5} label={t('text:Telephone')} value={systemMonitorPlatformUserData?.telephone} />
            </div>
            <div className="row">
              <FormValue col={5} label={t('text:User_Login_ID')} value={systemMonitorPlatformUserData?.loginId} />
              <FormValue
                col={5}
                label={t('text:Authority')}
                value={getAuthorityTypeText(systemMonitorPlatformUserData?.authorityType)}
              />
            </div>
            <div className="row">
              <FormValue
                col={5}
                label={t('text:User_Sign_Up_Date')}
                value={systemMonitorPlatformUserData?.signUpDateTime}
                format="datetime"
              />
              <FormValue
                col={4}
                label={t('text:User_Account_Status')}
                className={
                  'information-form__input border-bottom-0 ' +
                  getStatusTextClass('USER_STATUS', systemMonitorPlatformUserData?.userStatus)
                }
                value={t(`code:user-status.${systemMonitorPlatformUserData?.userStatus}`)}
              />
              <div className="col-3">
                <Button size={ButtonSizeEnum.SM} onClick={() => onClickAccessAccountButton()}>
                  {t('text:Access_Account')}
                </Button>
              </div>
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default SystemMonitorPlatformUserDetail;
