import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { PageableType } from 'hooks/usePageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';

interface FinancierManageAnchorUserDetailDealerAgreementListProps {
  dealerAgreementList: DealerAgreementVOModel[] | undefined;
  paginate(pageNumber: number, rowCount: number): Promise<void>;
  pageable: PageableType;
}

const FinancierManageAnchorUserDetailDealerAgreementList = ({
  dealerAgreementList,
  paginate,
  pageable,
}: FinancierManageAnchorUserDetailDealerAgreementListProps) => {
  const { t } = useTranslation();

  const PARTNER_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Master_Agreement_Number_of_Partner'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 96,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 45,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Associated_Partner_Master_Agreement_List')} />
      <TableBorder>
        <TableHeader header={PARTNER_AGREEMENT_TABLE_HEADERS} />
        <TableBody numOfCol={PARTNER_AGREEMENT_TABLE_HEADERS.length}>
          {dealerAgreementList?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td data={item.dealerFinancierClientName} />
                <Td data={item.contractNo} />
                <Td data={item.currencyType} />
                <Td data={item.startDate} format="date" />
                <Td data={item.expiryDate} format="date" />
                <TdLink
                  path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.dealerAgreementId)}
                  state={{
                    dealerFinancierClientId: item.dealerFinancierClientId,
                    supportedCollateralType: item.collateralType,
                  }}
                />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
};

export default FinancierManageAnchorUserDetailDealerAgreementList;
