import type React from 'react';
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { debounce, isNil } from 'lodash-es';

import Button from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import { NumericFormatInput } from 'components/stateless/CommonForm';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import Value from 'components/stateless/Form/Value/Value';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { TableBody, TableBorder, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { ATTACHMENT_TYPE } from 'enums';
import type { InvoiceAttachmentVOModel } from 'models/vo/InvoiceAttachmentVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { formatStringWithEllipsis } from 'utils/formatter';
import type { RequestCalculateDeForMultipleLoan } from 'utils/http/api/dealer/multiple-request-loans/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import useValidation from 'utils/validation/useValidation';

import { useFinancingRequest } from './logics';
import AttachFileModal from '../../../modal/AttachFileModal';

import type { DealerArBulkApplicationStep2State } from '../../useDealerArBulkFinancingApplicationStep2State';

interface FinancingRequestProps {
  dealerArBulkApplicationStep2State: DealerArBulkApplicationStep2State;
  fetchDealerArBulkCalculateLoanRequest: (data: RequestCalculateDeForMultipleLoan) => Promise<void>;
  totalRequestedAmount?: BigNumber;
  calculateRequestedAmount: (checkedArList: number[]) => void;
  arListTableRef: React.RefObject<HTMLDivElement>;
  disbursementDateRef: React.RefObject<HTMLDivElement>;
  handleCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  getCheckedLoanByArRequestList: () => {
    index: number;
    successArId: number;
    requestedLoanAmount: string;
  }[];
  focusErrorElement: () => void;
  estimateButtonRef: React.RefObject<HTMLDivElement>;
  selectedTotalArAmountState: {
    selectedTotalArAmount: BigNumber | undefined;
    setSelectedTotalArAmount: React.Dispatch<React.SetStateAction<BigNumber | undefined>>;
  };
  showedTotalRequestedAmountState: {
    showedTotalRequestedAmount: BigNumber | undefined;
    setShowedTotalRequestedAmount: React.Dispatch<React.SetStateAction<BigNumber | undefined>>;
  };
  initializeFinancingConditionInfo: () => void;
}

function FinancingRequest({
  dealerArBulkApplicationStep2State,
  totalRequestedAmount = '0',
  calculateRequestedAmount,
  fetchDealerArBulkCalculateLoanRequest,
  disbursementDateRef,
  arListTableRef,
  handleCheckAll,
  handleCheckChange,
  getCheckedLoanByArRequestList,
  focusErrorElement,
  estimateButtonRef,
  selectedTotalArAmountState,
  showedTotalRequestedAmountState,
  initializeFinancingConditionInfo,
}: FinancingRequestProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { setValue, control, errors, register, setError, clearErrors, watch } = useFormContext();
  const { disbursementDate } = watch();

  const {
    dealerAgreementDetail,
    dealerArBulkCalculateLoanRequest,
    minDate,
    maxDate,
    checkedArList,
    successArList,
    totalSuccessArListCount,
  } = dealerArBulkApplicationStep2State;

  const { selectedTotalArAmount, setSelectedTotalArAmount } = selectedTotalArAmountState;
  const { showedTotalRequestedAmount, setShowedTotalRequestedAmount } = showedTotalRequestedAmountState;
  const [arFileNameList, setArFileNameList] = useState<string[]>([]);

  const { getValidationClassName } = useValidation({
    errorFields: errors.requestedArs,
  });

  const { disabledRanges, disabledEstimateButton, returnAmountWithCurrency } = useFinancingRequest({
    dealerArBulkApplicationStep2State,
    disbursementDate,
    totalRequestedAmount,
  });

  const resetSelectedFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault();

    const resultList = arFileNameList.map((arFileName, nameIndex) => (nameIndex === index ? '' : arFileName));
    setArFileNameList(resultList);
    setValue(`requestedArs.${index}.invoiceAttachmentId`, null);
  };

  const setSelectedFileInfo = (data: InvoiceAttachmentVOModel, index: number) => {
    const resultList = [...arFileNameList];
    resultList[index] = data.fileName;
    setArFileNameList(resultList);
    setValue(`requestedArs.${index}.invoiceAttachmentId`, data.invoiceAttachmentId, { shouldDirty: true });
  };

  const handleClickEstimateButton = async () => {
    clearErrors();
    const checkedLoanByArRequestList = getCheckedLoanByArRequestList();
    let arLimitExceededErrorCount = 0;

    checkedLoanByArRequestList.forEach(checkedArInfo => {
      if (Number(checkedArInfo.requestedLoanAmount) > Number(successArList[checkedArInfo.index].remainingArAmount)) {
        setError(`requestedArs.${checkedArInfo.index}.requestedLoanAmount`, { type: 'max' });
        arLimitExceededErrorCount++;
      }
    });

    if (arLimitExceededErrorCount) {
      modal.show(<h6>{t('text:The_financing_amount_exceeds_the_Available_Request')}</h6>, {
        closeBtnCb: () => focusErrorElement(),
      });

      return false;
    }

    try {
      await fetchDealerArBulkCalculateLoanRequest({
        dealerAgreementId: dealerAgreementDetail.dealerAgreementId,
        requestedArs: checkedLoanByArRequestList,
        disbursementDate,
      });

      const checkedArInfoList = successArList?.filter((_: any, rowIndex: number) => checkedArList.includes(rowIndex));
      const calculatorBigNumber = new CalculatorBigNumber();
      let arAmountSum: BigNumber = '0';

      if (checkedArInfoList?.length !== 0) {
        checkedArInfoList.forEach(arInfo => {
          arAmountSum = calculatorBigNumber.add(arAmountSum).add(arInfo.arAmount).get();
        });
      }
      ReactDOM.unstable_batchedUpdates(() => {
        setSelectedTotalArAmount(arAmountSum);
        setShowedTotalRequestedAmount(totalRequestedAmount);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const showArUploadModal = (index: number) => {
    modal.show(
      <AttachFileModal
        getSelectedData={data => setSelectedFileInfo(data, index)}
        modalId={modal.id}
        attachmentType={ATTACHMENT_TYPE.SCANNED_AR}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        title: t('text:Attach_File'),
        closeBtnText: t('text:Close'),
      },
    );
  };

  const debouncedCalculateRequestedAmount = useCallback(
    debounce(checkedArList => {
      calculateRequestedAmount(checkedArList);
    }, 300),
    [],
  );

  const renderArTable = (() => {
    const tableHeaders: HeaderType[] = [
      {
        hasCheckBox: true,
        handleClickAllCheckBox: handleCheckAll,
        isAllChecked: successArList?.length !== 0 && checkedArList.length === successArList?.length,
        colWidths: 70,
      },
      {
        headerText: t('text:AR_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:AR_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Issued_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Settlement_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Scheduled_Repayment_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Available_Request'),
        colWidths: 100,
      },
      {
        headerText: t('text:Requested_Financing_Amount'),
        colWidths: 120,
        required: true,
      },
      {
        headerText: t('text:Scanned_Tax_Invoice_File'),
        colWidths: 120,
        required: true,
      },
    ];

    const renderResultTable = () => {
      return successArList?.map((ar, index) => {
        return (
          <Tr key={index}>
            <td>
              <div className="text-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  onChange={e => handleCheckChange(e, index)}
                  checked={checkedArList.includes(index)}
                />
              </div>
            </td>
            <Td data={ar.arNumber} />
            <Td data={ar.arAmount} format="number" />
            <Td data={ar.arIssuedDate} format="date" />
            <Td data={ar.settlementDate} format="date" />
            <Td data={ar.settlementDate} format="date" />
            <Td data={ar.remainingArAmount} format="number" />
            <td className={getValidationClassName('requestedLoanAmount', 'td', index)}>
              <NumericFormatInput
                control={control}
                name={`requestedArs.${index}.requestedLoanAmount`}
                numberType="bigNumber"
                className={clsx(getValidationClassName('requestedLoanAmount', 'input', index), {
                  'd-none': !checkedArList.includes(index),
                })}
                onChange={() => {
                  debouncedCalculateRequestedAmount(checkedArList);
                  initializeFinancingConditionInfo();
                }}
                currencyType={dealerAgreementDetail?.currencyType}
                textAlign="text-end"
              />
              <Value
                value="-"
                className={clsx('grid-input', {
                  'd-none': checkedArList.includes(index),
                })}
              />
            </td>
            <td className={getValidationClassName('invoiceAttachmentId', 'td', index)}>
              <div className="flex-align-center">
                <div
                  id="fileName"
                  tabIndex={-1}
                  className={clsx('attach-file-link-button me-1', {
                    disabled: !checkedArList.includes(index),
                    'error-field': errors?.requestedArs?.[index]?.invoiceAttachmentId,
                  })}
                  onClick={() => showArUploadModal(index)}
                >
                  {arFileNameList[index] ? formatStringWithEllipsis(arFileNameList[index]) : t('text:Attach_File')}
                </div>
                <IconButton
                  onClick={e => resetSelectedFile(e, index)}
                  className={`${(!arFileNameList[index] || !checkedArList.includes(index)) && 'd-none'}`}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </IconButton>
                <input type="hidden" name={`requestedArs.${index}.successArId`} ref={register} />
              </div>
            </td>
          </Tr>
        );
      });
    };

    return (
      <TableBorder scrollYMaxHeight={570}>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
      </TableBorder>
    );
  })();

  //AR 500건 초과 시
  const ExceedsArLimitMessage = () => {
    return (
      <GuideMessage
        className="side-border-none"
        message={[t('text:500_ARs_are_displayed_in_order_of_earliest_Settlement_Date')]}
        useContentAreaClassName={false}
      />
    );
  };

  const FactoringAPRSection = () => {
    return (
      <>
        <div className="detail-title bg-sub100">
          <div className="d-flex w-50 justify-content-between">
            <div className="me-auto">{t('text:Financing_Condition')}</div>
            <div className="align-self-center" ref={estimateButtonRef}>
              <Button
                onClick={handleClickEstimateButton}
                className="flex-column-center text-bold"
                disabled={disabledEstimateButton()}
              >
                {t('text:Estimate')}
              </Button>
            </div>
          </div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Total_Requested_Financing_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(showedTotalRequestedAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Selected_AR_Amount')}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(selectedTotalArAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:LTV(%)')}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {dealerArBulkCalculateLoanRequest?.maxLtvRatio ?? '-'}
              </div>
            </div>
          </div>
          {dealerAgreementDetail.showExpectedTotalInterestRate && (
            <>
              <div className="row">
                <div className="col-3">
                  <div className="information-form__label flex-label">{t('text:Discount_Rate_APR')}</div>
                </div>
                <div className="col-3 ">
                  <div className="information-form__input flex-input gray-bold-font">
                    {dealerArBulkCalculateLoanRequest?.totalInterestRate
                      ? `${dealerArBulkCalculateLoanRequest?.totalInterestRate} %`
                      : '-'}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="information-form__label flex-label">{t('text:Discount_Charge')}</div>
                </div>
                <div className="col-3">
                  <div className="information-form__input flex-input gray-bold-font">
                    {returnAmountWithCurrency(dealerArBulkCalculateLoanRequest?.expectedTotalInterestAmount)}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="border-bottom-light-gray my-3" />
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {t('text:Advance_Disbursement')}
                <QuestionTooltip
                  id="netDisburseAmountTooltip"
                  place="top"
                  contentText={
                    <>
                      <div>{t('text:Principal_Amount')} ⓐ</div>
                      <div>{t('text:Prepayment_Interest')} ⓑ</div>
                      <div>--------------------------------------------------------</div>
                      <div>{t('text:Advance_Disbursement')} ⓒ = ⓐ - ⓑ</div>
                    </>
                  }
                />
              </div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(dealerArBulkCalculateLoanRequest?.totalNetDisbursementAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Remaining_of_Selected_AR_Balance')}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(dealerArBulkCalculateLoanRequest?.totalRemainingCollateralAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Transaction_Fee')}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(dealerArBulkCalculateLoanRequest?.totalArCommissionAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {t('text:Final_Disbursement_Amount')}
                <QuestionTooltip
                  id="finalDisburseAmountTooltip"
                  place="top"
                  contentText={
                    <>
                      <div>{t('text:Remaining_AR_Balance')} ⓐ</div>
                      <div>{t('text:Transaction_Fee')} ⓑ</div>
                      <div>--------------------------------------------------------</div>
                      <div>{t('text:Final_Disbursement_Amount')} ⓒ = ⓐ - ⓑ</div>
                    </>
                  }
                />
              </div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(
                  new CalculatorBigNumber()
                    .add(dealerArBulkCalculateLoanRequest?.totalRemainingCollateralAmount)
                    .minus(dealerArBulkCalculateLoanRequest?.totalArCommissionAmount)
                    .get(),
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <SectionTitle title={t('text:Financing_Request')} />
      <GuideMessage
        isImportContentArea={true}
        message={[
          t('text:Please_Select_Disbursement_Date_you_would_like_to_receive'),
          t('text:Choose_the_AR_for_which_you_are_applying_for_financing_and_verify_that_the_information_is_correct'),
          t('text:Upload_Scanned_Tax_Invoice_Files_related_to_the_selected_AR'),
          t(
            'text:Once_you_have_selected_the_AR_and_entered_the_Requested_Financing_Amount_press_the_estimate_button_to_check_the_estimated_financing_terms',
          ),
        ]}
      />
      <div className="information-form__detail-form border-bott-none mt-4">
        <div className="detail-title bg-sub100 border-bottom-2px-light-gray">
          <div className="d-flex justify-content-between w-50">
            <div ref={disbursementDateRef}>{t('text:Requested_Disbursement_Date')}</div>
            <div className="d-flex align-items-center">
              <Controller
                control={control}
                name="disbursementDate"
                defaultValue={null}
                render={field => (
                  <ReactDatePicker
                    field={field}
                    className="detail-title-input"
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={disabledRanges}
                    onChange={() => initializeFinancingConditionInfo()}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
        </div>
        {!isNil(dealerAgreementDetail?.maxAgreementLoanCapacityAmount) && (
          <div className="detail-title bg-sub100 border-bottom-2px-light-gray">
            <div className="d-flex w-50 justify-content-between">
              <div>{t('text:Total_Requested_Financing_Amount')}</div>
              <div className="d-flex align-items-center">
                {t('format:number', { value: totalRequestedAmount })} {dealerAgreementDetail?.currencyType}
              </div>
            </div>
            <div className="d-flex w-50 justify-content-between">
              <div className="d-flex gap-3">
                <span>≤</span>
                <div>{t('text:Financing_Limit')}</div>
                <div>{returnAmountWithCurrency(dealerAgreementDetail?.maxAgreementLoanCapacityAmount)}</div>
              </div>
            </div>
          </div>
        )}
        <div className="information-form information-flex__form bg-white">
          <div className="col-3 information-form__label">{t('text:Select_AR_for_Financing_Application')}</div>
          <div ref={arListTableRef} className="mt-4">
            {renderArTable}
          </div>
        </div>
        {totalSuccessArListCount > 500 && <ExceedsArLimitMessage />}
        <FactoringAPRSection />
      </div>
    </>
  );
}

export default FinancingRequest;
