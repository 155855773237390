import type React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from 'components/stateless/Button/IconButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ATTACHMENT_TYPE } from 'enums';
import type { InvoiceAttachmentVOModel } from 'models/vo/InvoiceAttachmentVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import type { TempLoanVOModel } from 'models/vo/TempLoanVO';
import { requestDownloadInvoiceAttachment } from 'utils/http/api/dealer/invoice-attachments';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import AttachFileModal from '../../../modal/AttachFileModal';

interface InvoiceInformationProps {
  dealerInvoiceDetail: SuccessInvoiceDetailVOModel;
  dealerTempLoanByInvoice: TempLoanVOModel;
}

function InvoiceInformation({ dealerInvoiceDetail, dealerTempLoanByInvoice }: InvoiceInformationProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { register, setValue } = useFormContext();

  const [invoiceFileName, setInvoiceFileName] = useState<string | undefined>(undefined);
  const [invoiceFileChanged, setInvoiceFileChanged] = useState(false);

  useEffect(() => {
    setInvoiceFileName(dealerTempLoanByInvoice.invoiceScannedAttachmentName);
  }, [dealerTempLoanByInvoice]);

  const showInvoiceUploadModal = () => {
    modal.show(
      <AttachFileModal
        getSelectedData={setSelectedFileInfo}
        modalId={modal.id}
        attachmentType={ATTACHMENT_TYPE.SCANNED_INVOICE}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        title: t('text:Attach_File'),
        closeBtnText: t('text:Close'),
      },
    );
  };

  const setSelectedFileInfo = (data: InvoiceAttachmentVOModel) => {
    setInvoiceFileChanged(true);
    setInvoiceFileName(data.fileName);
    setValue('invoiceAttachmentId', data.invoiceAttachmentId, { shouldDirty: true });
  };

  const resetInvoiceFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setInvoiceFileChanged(true);
    setInvoiceFileName(undefined);
    setValue('invoiceAttachmentId', null);
  };

  const onClickInvoiceFileDownLoad = async () => {
    try {
      await requestDownloadInvoiceAttachment(dealerTempLoanByInvoice.invoiceAttachmentId);
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <SectionTitle title={t('text:Invoice_Information')} />

      <GuideMessage
        isImportContentArea={true}
        message={[
          t(
            'text:Check_the_information_of_the_invoice_the_financing_application_is_based_on_and_upload_the_scanned_copy_of_the_tax_invoice',
          ),
          t('text:Click_on_the_checkbox_to_confirm_the_invoice_information_is_correct'),
        ]}
      />

      <FormBorder hideBorderBottom>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              className="information-form__input border-none"
              col={3}
              label={t('text:Invoice_Number')}
              subLabel={`(${t('text:Invoice_Reference_Number')})`}
              value={dealerInvoiceDetail?.invoiceNumber}
              subValue={dealerInvoiceDetail?.referenceNumber}
            />
            <FormValue
              className="information-form__input border-none"
              col={3}
              label={t('text:Invoice_Amount')}
              value={dealerInvoiceDetail?.invoiceAmount}
              format="number"
            />
            <FormValue
              className="information-form__input border-none"
              col={3}
              label={t('text:Invoice_Issued_Date')}
              value={dealerInvoiceDetail?.invoiceIssuedDate}
              format="date"
            />
            <FormValue
              className="information-form__input border-none"
              col={3}
              label={t('text:Payment_Date')}
              value={dealerInvoiceDetail?.settlementDate}
              format="date"
            />
          </div>
          <div className="row">
            <FormValue col={3} label={t('text:Anchor_Name')} value={dealerInvoiceDetail?.anchorClientName} />
            <FormValue label={t('text:Partner_Name')} col={3} value={dealerInvoiceDetail?.dealerClientName} />
            <FormValue label={t('text:Currency')} col={3} value={dealerInvoiceDetail?.currencyType} />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:Scanned_Tax_Invoice_File')} />
        <div className="detail-in-file-upload-form">
          <div className="file-upload">
            <div className="d-flex justify-content-between">
              <label
                htmlFor="invoiceAttachmentFile"
                className="attach-file-link-button"
                onClick={showInvoiceUploadModal}
              >
                {t('text:Attach_File')}
              </label>
              <div id="fileName" className="upload-file-input">
                {invoiceFileName ? invoiceFileName : t('text:No_file_attached')}
                {invoiceFileName && !invoiceFileChanged && (
                  <IconButton onClick={onClickInvoiceFileDownLoad} type="button">
                    <FontAwesomeIcon icon={faDownload} />
                  </IconButton>
                )}
              </div>
              <IconButton
                onClick={resetInvoiceFile}
                className={`delete-uploaded-excel-button ${!invoiceFileName && 'd-none'}`}
                type="button"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
              <input type="hidden" name="invoiceAttachmentId" ref={register} />
            </div>
          </div>
        </div>
      </FormBorder>
    </>
  );
}

export default InvoiceInformation;
