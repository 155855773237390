import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ApAccordionList from 'components/ap/ApSummaryPhaseAccordion';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { WAITING_AR_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import useProgressStatus from 'hooks/useProgressStatus';
import type Pageable from 'models/Pageable';
import type { ArPhaseVOModel } from 'models/vo/ArPhaseVO';
import type { ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import type { LastArPhaseVOModel } from 'models/vo/LastArPhaseVO';
import { requestFinancierArPhaseList } from 'utils/http/api/financier/ar-approval-phases';
import {
  requestFinancierArSummaryDetail,
  requestFinancierLastPhaseArPhasesData,
} from 'utils/http/api/financier/ar-summaries';
import { requestFinancierWaitingArList } from 'utils/http/api/financier/waiting-ars';
import useModal from 'utils/modal/useModal';

import FinancierArInputTable from './FinancerARInputTable';
import { showLoadingUI, unShowLoadingUI } from '../../../../../utils/loadingUIManager/loadingUIManager';

const getConstant = () => {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const { arSummaryId } = useParams() as any;

  return {
    modal,
    t,
    mounted,
    arSummaryId,
  };
};

function FinancierArConfirmationDetail() {
  const { modal, t, mounted, arSummaryId } = getConstant();

  const [arSummaryData, setArSummaryData] = useState<ArSummaryVOModel>();
  const [arSummaryPhaseDataList, setArSummaryPhaseDataList] = useState<Pageable<ArPhaseVOModel[]>>();
  const [arSummaryLastPhaseData, setArSummaryLastPhaseData] = useState<LastArPhaseVOModel>();
  const [numberOfPreviousPhaseInvalidAP, setNumberOfPreviousPhaseInvalidAP] = useState<number>(0); // 이전 단계에서 등록에 성공(유효)한 매출채권 개수
  const [editable, setEditable] = useState<boolean>(false); // form editable 여부
  const [partnerInfo, setPartnerInfo] = useState<{ partnerName: string; partnerTaxCode: string }>();

  // ar lists state - phase 별로
  const [searchedWaitingApprovalPhaseId, setSearchedWaitingApprovalPhaseId] = useState<number[]>([]);

  const { renderProgressStatus } = useProgressStatus(arSummaryData, '', 'Ar');

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  // 이전 Phase AR 목록 가져오기
  const fetchPreviousPhaseArList = async (waitingArApprovalPhaseId: number) => {
    const previousPhaseArList = await requestFinancierWaitingArList(waitingArApprovalPhaseId, 0, 3000);
    const registeredArCount = previousPhaseArList.content.filter(
      ar => ar.waitingArStatus === WAITING_AR_STATUS.REGISTERED,
    ).length;
    const invalidApCount = previousPhaseArList.content.length - registeredArCount;
    const partnerInfo = {
      partnerName: previousPhaseArList.content[0].partnerName,
      partnerTaxCode: previousPhaseArList.content[0].partnerTaxCode,
    };

    return { invalidApCount, partnerInfo };
  };

  const fetchAll = async () => {
    try {
      showLoadingUI();
      const [arSummaryDetail, arSummaryPhaseList, arLastPhaseResponse] = await Promise.all([
        requestFinancierArSummaryDetail(arSummaryId),
        requestFinancierArPhaseList(0, 10, arSummaryId),
        requestFinancierLastPhaseArPhasesData(arSummaryId),
      ]);

      setArSummaryData(arSummaryDetail);
      setArSummaryPhaseDataList(arSummaryPhaseList);
      setArSummaryLastPhaseData(arLastPhaseResponse);
      setEditable(arLastPhaseResponse?.authorized && !arLastPhaseResponse?.inProgress && !arSummaryDetail?.rejected);

      // Fetch and process previous phase AR list
      const { invalidApCount, partnerInfo } = await fetchPreviousPhaseArList(
        arSummaryPhaseList.content[0].waitingArApprovalPhaseId,
      );
      setNumberOfPreviousPhaseInvalidAP(invalidApCount);
      setPartnerInfo(partnerInfo);
      unShowLoadingUI();
    } catch (error: any) {
      modal.show(`Failed to fetch data: ${error.message}`);
      unShowLoadingUI();
    }
  };

  const handleArSummaryPhaseAccordionClick = (arPhaseData: ArPhaseVOModel) => {
    return requestFinancierWaitingArList(arPhaseData.waitingArApprovalPhaseId, 0, 3000);
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AR_Confirmation_Details')} />
      {arSummaryData?.rejected && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[t('text:The_AR_registration_request_was_rejected'), t('text:Please_check_the_reason_below')]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={arSummaryData.rejectReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Registration_Status')} col={4} value={renderProgressStatus()} />
              <FormValue
                label={t('text:Number_of_Valid_AR')}
                className="information-form__input border-none pointfont"
                col={4}
                value={arSummaryData?.currentApprovalCount}
                format="number"
              />
              <FormValue
                label={t('text:Total_Amount_of_Valid_AR')}
                className="information-form__input border-none pointfont"
                col={4}
                value={arSummaryData?.currentApprovalAmount}
                format="number"
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Anchor_Master_Agreement_Information')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} col={4} value={arSummaryData?.anchorClientName} />
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                col={4}
                value={arSummaryData?.anchorContractNo}
              />
              <FormValue label={t('text:Currency')} col={4} value={arSummaryData?.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:AR_List')} />
        <div className="mb-3">
          {numberOfPreviousPhaseInvalidAP > 0 && (
            <>
              <GuideMessage
                message={[
                  t('text:There_is_an_invalid_AR_to_register_in_the_previous_step'),
                  t('text:Please_click_on_the_previous_step_below_to_see'),
                ]}
                isImportContentArea={true}
                messageType={MessageType.ALERT}
                useContentAreaClassName={false}
              />
            </>
          )}
        </div>
        {editable && arSummaryData?.financierEnterpriseId && (
          <div className="editable-section">
            <FinancierArInputTable
              arSummaryDetail={arSummaryData}
              arSummaryLastPhaseData={arSummaryLastPhaseData}
              partnerInfo={partnerInfo}
              buttonActionCb={fetchAll}
            />
          </div>
        )}
        {arSummaryData &&
          arSummaryPhaseDataList?.content.map(arSummaryPhase => {
            return (
              <ApAccordionList
                onAccordionClick={() => handleArSummaryPhaseAccordionClick(arSummaryPhase)}
                searchedWaitingApprovalPhaseId={searchedWaitingApprovalPhaseId}
                setSearchedWaitingApprovalPhaseId={setSearchedWaitingApprovalPhaseId}
                arSummaryData={arSummaryData}
                arSummaryPhase={arSummaryPhase}
                key={arSummaryPhase.waitingArApprovalPhaseId}
              />
            );
          })}
      </div>
    </>
  );
}

export default FinancierArConfirmationDetail;
