import type Pageable from 'models/Pageable';
import type {
  PotentialPartnerAcquisitionDocumentVO,
  PotentialPartnerAcquisitionDocumentVOModel,
} from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import { formattingToPotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

/**
 * desc :: 금융사에서 요구한 문서 정보 조회
 * params :: emailCode / financierId
 * **/
export async function requestAnFinancierPotentialPartnerAcquisitionDocumentList(
  pageNumber: number,
  rowCount: number,
  code: string,
  financierId: number,
): Promise<Pageable<PotentialPartnerAcquisitionDocumentVOModel[]>> {
  const response = await http.get<Pageable<PotentialPartnerAcquisitionDocumentVO[]>>({
    url: API_AN.POTENTIAL_PARTNER_ACQUISITION_DOCUMENTS.DOCUMENT_LIST,
    data: { pageNumber, rowCount, code, financierId },
  });

  return {
    ...response,
    content: response.content.map((data: PotentialPartnerAcquisitionDocumentVO) =>
      formattingToPotentialPartnerAcquisitionDocumentVOModel(data),
    ),
  };
}

/**
 * desc :: 금융사에서 요구한 문서 템플릿 다운로드
 * params :: potentialPartnerAcquisitionDocumentSettingId / emailCode
 * **/
export async function requestAnFinancierPotentialPartnerAcquisitionDocumentDownload(
  potentialPartnerAcquisitionDocumentSettingId: number,
  code: string,
) {
  return await http.download(
    API_AN.POTENTIAL_PARTNER_ACQUISITION_DOCUMENTS.DOWNLOAD_DOCUMENT(potentialPartnerAcquisitionDocumentSettingId),
    { code },
  );
}
