import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import { Cell, Content, Row, SubTitle } from 'components/templates/section';

import DesignatedAccountOfARSettlementOrInvoicePayment from '../common/DesignatedAccountOfARSettlementOrInvoicePayment';

const InvoicePayment = () => {
  const { t } = useTranslation(['format']);

  return (
    <>
      <SubTitle>{t('text:INVOICE_PAYMENT')}</SubTitle>
      <Content>
        <Row singleColumnPosition="all">
          <Cell>
            <Form.Label>{t('text:Designated_Disbursement_Account')}</Form.Label>
            <Form.Description>
              {t('text:The_invoice_payment_is_deposited_into_the_Anchor_s_designated_account')}
            </Form.Description>
          </Cell>
          <DesignatedAccountOfARSettlementOrInvoicePayment />
        </Row>
      </Content>
    </>
  );
};

export default InvoicePayment;
