import { useState } from 'react';

import type { SuccessArGroupAnchorPartnerAccountVOModel } from 'models/vo/SuccessArGroupAnchorPartnerAccountVO';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';
import {
  requestAnSuccessAr,
  requestAnSuccessArGroupAnchorPartnerAccountAnchorPartnerAccountId,
} from 'utils/http/api/anonymous/success-ars';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';

interface PotentialPartnerArDetailStateType {
  arList: SuccessArVOModel[];
  arGroupAnchorPartnerAccount: SuccessArGroupAnchorPartnerAccountVOModel;
}

export function usePotentialPartnerArDetailState(anchorPartnerAccountId: number) {
  const modal = useModal();

  const [dataState, setDataState] = useState<PotentialPartnerArDetailStateType>({
    arList: [] as SuccessArVOModel[],
    arGroupAnchorPartnerAccount: {} as SuccessArGroupAnchorPartnerAccountVOModel,
  });
  const emailCode = getPotentialPartnerEmailCode();

  const fetchAll = async (): Promise<void> => {
    if (!emailCode) return modal.show('not found email code');
    try {
      const [fetchArList, fetchAvailableFinancingAmount] = await Promise.all([
        requestAnSuccessAr(0, 1000, emailCode, anchorPartnerAccountId),
        requestAnSuccessArGroupAnchorPartnerAccountAnchorPartnerAccountId(anchorPartnerAccountId, emailCode),
      ]);

      setDataState(prevState => ({
        ...prevState,
        arList: fetchArList.content,
        arGroupAnchorPartnerAccount: fetchAvailableFinancingAmount,
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    fetchAll,
    state: dataState,
  };
}
