import { forwardRef, useImperativeHandle, useRef } from 'react';
import type { FieldError } from 'react-hook-form';

import clsx from 'clsx';

import { FormErrorMessage } from './FormErrorMessage';
import Value from '../Form/Value/Value';
import Select from '../Select/Select';

import type { SelectProps } from '../Select/Select';

interface FormSelectProps extends SelectProps {
  label: string;
  col?: number;
  required?: boolean;
  error?: FieldError;
  isShouldShowDash?: boolean;
}

const FormSelect = forwardRef<HTMLSelectElement, FormSelectProps>((props, ref) => {
  const {
    className = 'information-form__input',
    col = 6,
    selectOptions,
    placeholderOptions,
    label,
    required = false,
    disabled = false,
    value,
    defaultValue,
    error,
    isShouldShowDash = false,
    ...restProps
  } = props;

  const selectRef = useRef<HTMLSelectElement>(null);
  const shouldShowDash = isShouldShowDash && disabled && !value && !defaultValue;

  useImperativeHandle(ref, () => selectRef.current as HTMLSelectElement);

  return (
    <div className={`col-${col}`}>
      <label
        className={`information-form__label ${!disabled && required && 'star'}`}
        style={label === '' ? { minHeight: '15px' } : undefined}
      >
        {label}
      </label>
      <div
        className={clsx('w-100', {
          'd-none': !shouldShowDash,
        })}
      >
        <Value value="-" />
      </div>
      <Select
        className={clsx(className, {
          'error-input-border': error,
          'd-none': shouldShowDash,
        })}
        ref={ref}
        selectOptions={selectOptions}
        placeholderOptions={placeholderOptions}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        {...restProps}
      />
      <FormErrorMessage error={error} />
    </div>
  );
});

FormSelect.displayName = 'FormSelect';
export default FormSelect;
