import { useTranslation } from 'react-i18next';

import PPSubTitle from 'components/potential-partner/PPSubTitle/PPSubTitle';
import PPTableBorder from 'components/potential-partner/PPTable/PPTableBorder';
import type { PPHeaderType } from 'components/potential-partner/PPTable/PPTableHeader';
import PPTableHeader from 'components/potential-partner/PPTable/PPTableHeader';
import TableBody from 'components/stateless/Table/TableBody';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import type { BranchVOModel } from 'models/vo/BranchVO';

interface PotentialPartnerFinancierDetailBranchesProps {
  branchesList: BranchVOModel[];
}

function PotentialPartnerFinancierDetailBranches({ branchesList }: PotentialPartnerFinancierDetailBranchesProps) {
  const { t } = useTranslation();
  const BRANCH_LISTS_HEADER: PPHeaderType[] = [
    {
      headerText: t('text:Branch_Name'),
      ratio: 25,
    },
    {
      headerText: t('text:Telephone'),
      ratio: 25,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      ratio: 50,
    },
  ];

  return (
    <div className="pp-mb-56">
      <PPSubTitle title={t('text:Branches')} />
      <PPTableBorder>
        <PPTableHeader headers={BRANCH_LISTS_HEADER} />
        <TableBody numOfCol={BRANCH_LISTS_HEADER.length}>
          {branchesList?.map((item: BranchVOModel, index: number) => (
            <Tr key={index}>
              <Td>
                <div className="pp-table-td__label">{BRANCH_LISTS_HEADER[0].headerText}</div>
                <div className="pp-table-td__data">{item.branchName}</div>
              </Td>
              <Td>
                <div className="pp-table-td__label">{BRANCH_LISTS_HEADER[1].headerText}</div>
                <div className="pp-table-td__data">{item.telephone}</div>
              </Td>
              <Td>
                <div className="pp-table-td__label">{BRANCH_LISTS_HEADER[2].headerText}</div>
                <div className="pp-table-td__data">{item.address}</div>
              </Td>
            </Tr>
          ))}
        </TableBody>
      </PPTableBorder>
    </div>
  );
}

export default PotentialPartnerFinancierDetailBranches;
