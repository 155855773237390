import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import type { WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestAnchorPartnerModification, requestAnchorPartnersData } from 'utils/http/api/anchor/anchor-partners';
import type { UpdateAcAnchorPartnerRequest } from 'utils/http/api/anchor/anchor-partners/requests';
import { requestWaitingAnchorPartnerPage } from 'utils/http/api/anchor/waiting-anchor-partners';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

function AnchorPartnerCompanyInfoConfirmedDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();
  const mounted = useMounted();

  const { anchorPartnerId } = useParams() as any;
  const { pageable: editHistoryPageable, setPageable: setEditHistoryPageable } = usePageable();

  const [partnerInfo, setPartnerInfo] = useState<AnchorPartnerVOModel>();
  const [editHistoryInfoList, setEditHistoryInfoList] = useState<Pageable<WaitingAnchorPartnerVOModel[]>>();
  const [isEditSupplychainPartnerInfo, setIsEditSupplychainPartnerInfo] = useState(false);

  const signInInfo = getSignIn();
  const isHqOperatorOrAdmin =
    signInInfo?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR || signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;

  const { register, getValues, errors, setError, clearErrors, reset } = useForm<UpdateAcAnchorPartnerRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (mounted) {
      fetchAll(editHistoryPageable.currentPage, editHistoryPageable.sizePerPage);
    }
  }, [mounted]);

  async function fetchAll(editHistoryPageNumber: number = 1, editHistoryPageRowCount: number = 10) {
    try {
      const partnerDetailInfo = await requestAnchorPartnersData(anchorPartnerId);
      const editHistoryInfos = await requestWaitingAnchorPartnerPage(editHistoryPageNumber, editHistoryPageRowCount, {
        targetAnchorPartnerId: anchorPartnerId,
        hasTargetAnchorPartner: true,
        approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerInfo(partnerDetailInfo);
        setEditHistoryInfoList(editHistoryInfos);
        setEditHistoryPageable(editHistoryInfos);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Supplier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Tax_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Company_Registration_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 100,
    },
    {
      headerText: t('text:Fax'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Title'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Email'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 100,
    },
  ];

  const renderSupplyChainPartnerInformation = (): JSX.Element => {
    const handleClickEditBtn = (e: any) => {
      e.preventDefault();

      setIsEditSupplychainPartnerInfo(true);
    };

    const handleClickCancelBtn = (e: any) => {
      e.preventDefault();

      modal.show(
        <h6>
          {t('text:Would_you_like_to_stop_modifying_the_information?')}
          <br />
          {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnCb: () => {
            setIsEditSupplychainPartnerInfo(false);
            reset();
            fetchAll();
          },
        },
      );
    };

    const modifyRequestSuccessModal = () => {
      modal.show(
        <h6>
          {t('text:Request_for_modification_approval_has_been_completed')}
          <br />
          {t('text:Modification_will_be_completed_after_approval_by_the_Admin')}
        </h6>,
        {
          closeBtnCb: () => {
            history.push(ROUTES_AC.MANAGE_PARTNER.COMPANY_INFO_LIST);
          },
        },
      );
    };

    const modifySuccessModal = () => {
      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>);
    };

    const handleClickSaveBtn = (e: any) => {
      e.preventDefault();

      modal.show(<h6>{t('text:Would_you_like_to_modify_the_suppliers_information?')}</h6>, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            const response = await requestAnchorPartnerModification(anchorPartnerId, getValues());
            if (response.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) {
              modifyRequestSuccessModal();
            } else {
              modifySuccessModal();
              fetchAll();
            }
            setIsEditSupplychainPartnerInfo(false);
            clearErrors();
          } catch (error) {
            modal.show(error);
            formErrorHandler<UpdateAcAnchorPartnerRequest>(error, setError, clearErrors);
          }
        },
      });
    };

    const renderEditSaveBtn = () => {
      return !isEditSupplychainPartnerInfo ? (
        <Button size={ButtonSizeEnum.SM} onClick={handleClickEditBtn} style={{ width: '60px' }}>
          {t('text:Edit')}
        </Button>
      ) : (
        <>
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={handleClickCancelBtn}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.SM} onClick={handleClickSaveBtn} style={{ width: '60px' }}>
            {t('text:Save')}
          </Button>
        </>
      );
    };

    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Supplier_Information`)}>
            {isHqOperatorOrAdmin && renderEditSaveBtn()}
          </SectionTitle>
          <FormBorder editable={isEditSupplychainPartnerInfo}>
            <FormContents>
              <div className="row">
                <FormInput
                  label={t(`text:Supplier_Name`)}
                  name="name"
                  defaultValue={partnerInfo?.name}
                  ref={register}
                  disabled={!isEditSupplychainPartnerInfo}
                  requiredOptions={{ required: true }}
                  error={errors.name}
                />
                <FormInput
                  label={t(`text:Tax_Code`)}
                  name="partnerTaxCode"
                  defaultValue={partnerInfo?.partnerTaxCode}
                  ref={register}
                  disabled={
                    !isEditSupplychainPartnerInfo || (isEditSupplychainPartnerInfo && !isNil(partnerInfo?.partnerId))
                  }
                  requiredOptions={{ required: true }}
                  error={errors.partnerTaxCode}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Company_Registration_Number`)}
                  name="partnerBusinessCode"
                  defaultValue={partnerInfo?.partnerBusinessCode}
                  ref={register}
                  disabled={!isEditSupplychainPartnerInfo}
                  error={errors.partnerBusinessCode}
                />
                <FormInput
                  col={3}
                  label={t(`text:Telephone`)}
                  name="telephone"
                  defaultValue={partnerInfo?.telephone}
                  ref={register}
                  disabled={!isEditSupplychainPartnerInfo}
                  requiredOptions={{ required: true }}
                  error={errors.telephone}
                />
                <FormInput
                  col={3}
                  label={t(`text:Fax`)}
                  name="fax"
                  defaultValue={partnerInfo?.fax}
                  ref={register}
                  disabled={!isEditSupplychainPartnerInfo}
                  error={errors.fax}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Legal_Representative_Name`)}
                  name="representativeName"
                  defaultValue={partnerInfo?.representativeName}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditSupplychainPartnerInfo}
                  error={errors.representativeName}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  name="representativePosition"
                  defaultValue={partnerInfo?.representativePosition}
                  ref={register}
                  disabled={!isEditSupplychainPartnerInfo}
                  error={errors.representativePosition}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  name="representativeEmail"
                  defaultValue={partnerInfo?.representativeEmail}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditSupplychainPartnerInfo}
                  error={errors.representativeEmail}
                />
              </div>
              <div className="row">
                <FormInput
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  name="address"
                  defaultValue={partnerInfo?.address}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditSupplychainPartnerInfo}
                  error={errors.address}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderEditHistoryInformation = () => {
    const paginate = async (selectedPageNumber: number, selectedRowCount: number) => {
      try {
        const editHistoryInfos = await requestWaitingAnchorPartnerPage(selectedPageNumber, selectedRowCount, {
          targetAnchorPartnerId: anchorPartnerId,
          hasTargetAnchorPartner: true,
          approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
        });

        ReactDOM.unstable_batchedUpdates(() => {
          setEditHistoryInfoList(editHistoryInfos);
          setEditHistoryPageable(editHistoryInfos);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    return (
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={EDIT_HISTORY_TABLE_HEADERS} />
          <TableBody numOfCol={EDIT_HISTORY_TABLE_HEADERS.length}>
            {editHistoryInfoList?.content.map((item, i) => (
              <Tr key={i}>
                <Td data={item.updatedDateTime} format="datetime" />
                <Td data={item.name} />
                <Td data={item.partnerTaxCode} />
                <Td data={item.partnerBusinessCode} />
                <Td data={item.telephone} />
                <Td data={item.fax} />
                <Td data={item.representativeName} />
                <Td data={item.representativePosition} />
                <Td data={item.representativeEmail} />
                <Td data={item.address} />
                <Td data={`${item.operatorUserLoginId} / ${item.operatorUserName}`} />
                <Td data={`${item.authorizerUserLoginId} / ${item.authorizerUserName}`} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={editHistoryPageable} paginate={paginate} />
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={partnerInfo?.name} />
      {renderSupplyChainPartnerInformation()}
      {renderEditHistoryInformation()}
    </>
  );
}

export default AnchorPartnerCompanyInfoConfirmedDetail;
