import React, { forwardRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faDownload, faMinus, faPlus, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import { NumericFormatInput } from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Select from 'components/stateless/Select/Select';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import type { DISBURSEMENT_ACCOUNT_TYPE, PURPOSE_TYPE } from 'enums';
import { DOCUMENT_FORM_TYPE } from 'enums';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { TempLoanVOModel } from 'models/vo/TempLoanVO';
import useValidation from 'utils/validation/useValidation';

import { usePurposeOfFinancing } from './logics';

import type { PurposeFileRowTypes } from '../../useDealerArFinancingApplicationStep2State';
import type { TFunction } from 'i18next';

export const getFieldNames = (t: TFunction) => {
  return {
    purposeType: {
      name: t('text:Purpose'),
      width: '150px',
    },
    purposeAmount: {
      name: t('text:Amount'),
      width: '150px',
    },
    disbursementAccountType: {
      name: t('text:Disbursement_Account'),
      width: '210px',
    },
    bankCodeId: {
      name: t('text:Bank_Code'),
      width: '150px',
    },
    bankName: {
      name: t('text:Bank_Name'),
      width: '150px',
    },
    account: {
      name: t('text:Bank_Account_Number'),
      width: '150px',
    },
    accountOwner: {
      name: t('text:Bank_Account_Holder'),
      width: '150px',
    },
  };
};

interface PurposeOfFinancingProps {
  showDocumentTypeBtn: (documentType: DOCUMENT_FORM_TYPE) => boolean;
  dealerArDetail: SuccessArDetailVOModel;
  dealerTempLoanByAr: TempLoanVOModel;
  purposeFileRows: PurposeFileRowTypes[];
  setPurposeFileRows: React.Dispatch<React.SetStateAction<PurposeFileRowTypes[]>>;
  purposeRowId: React.MutableRefObject<number>;
  isRegistrationResultVisible: boolean;
}

const PurposeOfFinancing = forwardRef<HTMLDivElement, PurposeOfFinancingProps>(
  (
    {
      showDocumentTypeBtn,
      dealerArDetail,
      dealerTempLoanByAr,
      purposeFileRows,
      setPurposeFileRows,
      purposeRowId,
      isRegistrationResultVisible,
    },
    ref,
  ) => {
    const { t } = useTranslation(['format']);
    const { register, watch, errors, control } = useFormContext();
    const { purposeOfLoanList } = watch();

    const fieldNames = getFieldNames(t);

    const { getValidationClassName, getValidationResult } = useValidation({
      errorFields: errors.purposeOfLoanList,
      rule: 'loanRegister',
    });

    const {
      totalArAmount,
      checkedRows,
      onClickBulkTransferDownloadForm,
      onClickRemittanceDownloadForm,
      purposeAddRow,
      purposeRemoveRow,
      handleCheckAll,
      handleCheckChange,
      searchCitadCode,
      disabledBankInfoFormWhenTempPaymentAccountTypeDesignated,
      tdClassAddDisabled,
      ableOrDisableBankAccountInfoUi,
      updateTotalArAmount,
      onChangeAccountFileName,
      onChangePurposeFileName,
      resetAccountFile,
      resetPurposeFile,
      onClickAccountFileDownLoad,
      onClickPurposeFileDownLoad,
    } = usePurposeOfFinancing({
      financierId: dealerArDetail.financierId,
      dealerTempLoanByAr,
      purposeOfLoanList,
      purposeFileRows,
      setPurposeFileRows,
      purposeRowId,
    });

    useEffect(() => {
      updateTotalArAmount();
    }, [updateTotalArAmount, purposeOfLoanList]);

    useEffect(() => {
      disabledBankInfoFormWhenTempPaymentAccountTypeDesignated();
    }, [disabledBankInfoFormWhenTempPaymentAccountTypeDesignated]);

    useEffect(() => {
      if (!dealerTempLoanByAr.tempPurposeOfLoanList) return;
      if (dealerTempLoanByAr.tempPurposeOfLoanList.length === 0) return;

      const tempPurposeOfLoanListState: PurposeFileRowTypes[] = dealerTempLoanByAr.tempPurposeOfLoanList.map(item => ({
        index: purposeRowId.current++,
        accountFileName: item.accountAttachmentName,
        purposeFileName: item.purposeAttachmentName,
        accountFileChanged: false,
        purposeFileChanged: false,
        bankName: item.bankName,
        bankCode: item.bankCode,
      }));

      setPurposeFileRows(tempPurposeOfLoanListState);
    }, [dealerTempLoanByAr.tempPurposeOfLoanList, purposeRowId, setPurposeFileRows]);

    const colSpan = Object.keys(fieldNames).length + (isRegistrationResultVisible ? 1 : 0);

    const renderPurposeOfFinancingTable = () => {
      return (
        <div className="editable-section" ref={ref}>
          <div className="clearfix">
            <div className="flex-end mb-3">
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={e => {
                  e.preventDefault();
                  purposeRemoveRow();
                }}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
              <Button
                size={ButtonSizeEnum.SM}
                className=" ms-2"
                onClick={e => {
                  e.preventDefault();
                  purposeAddRow();
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>

            <div className="table-overflow-scroll">
              <table className="table-border">
                <colgroup>
                  <col style={{ width: '70px' }} />
                  {values(fieldNames).map(({ width }, index) => (
                    <col key={index} style={{ width }} />
                  ))}
                  {isRegistrationResultVisible && <col style={{ width: '200px' }} />}
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="text-center">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          value=""
                          id="allCheck2"
                          onChange={handleCheckAll}
                          checked={checkedRows.length === purposeFileRows.length}
                        />
                      </div>
                    </th>
                    {values(fieldNames).map(({ name }, index) => (
                      <th key={index} scope="col">
                        {name}
                      </th>
                    ))}
                    {isRegistrationResultVisible && <th scope="col">{t('text:Registration_Result')}</th>}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {purposeFileRows.map((item, index) => {
                    return (
                      <React.Fragment key={item.index}>
                        <tr className="d-none">
                          <td>
                            <input
                              type="hidden"
                              name={`purposeOfLoanList[${item.index}].tempPurposeOfLoanId`}
                              ref={register}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={3} className="bg-sub100">
                            <div className="text-center">
                              <input
                                className="form-check-input m-0"
                                type="checkbox"
                                value=""
                                onChange={e => handleCheckChange(e, index)}
                                checked={checkedRows.includes(index)}
                              />
                            </div>
                          </td>
                          <td className={getValidationClassName('purposeType', 'td', index)}>
                            <Select
                              className={getValidationClassName('purposeType', 'select', index)}
                              name={`purposeOfLoanList[${item.index}].purposeType`}
                              ref={register({
                                required: true,
                              })}
                              placeholderOptions={{ show: true, text: t('text:Select') }}
                              selectOptions={getSelectOptions<PURPOSE_TYPE>('PURPOSE_TYPE')}
                            />
                          </td>
                          <td className={getValidationClassName('purposeAmount', 'td', index)}>
                            <NumericFormatInput
                              control={control}
                              name={`purposeOfLoanList[${item.index}].purposeAmount`}
                              numberType="bigNumber"
                              className={getValidationClassName('purposeAmount', 'input', index)}
                              currencyType={dealerArDetail?.currencyType}
                              error={errors.purposeOfLoanList && errors.purposeOfLoanList[index]?.purposeAmount}
                              rules={{
                                required: true,
                              }}
                            />
                          </td>
                          <td className={getValidationClassName('disbursementAccountType', 'td', index)}>
                            <Select
                              className={getValidationClassName('disbursementAccountType', 'select', index)}
                              onChange={e => {
                                tdClassAddDisabled(e, item.index);
                              }}
                              name={`purposeOfLoanList[${item.index}].disbursementAccountType`}
                              ref={register({
                                required: true,
                              })}
                              placeholderOptions={{ show: true, text: t('text:Select') }}
                              selectOptions={getSelectOptions<DISBURSEMENT_ACCOUNT_TYPE>('DISBURSEMENT_ACCOUNT_TYPE')}
                            />
                          </td>
                          <td className={ableOrDisableBankAccountInfoUi(item.index)}>
                            <div className="position-parent" onClick={() => searchCitadCode(item.index)}>
                              <input
                                type="hidden"
                                name={`purposeOfLoanList[${item.index}].bankCodeId`}
                                ref={register()}
                                readOnly
                              />
                              <input className="grid-input" defaultValue={item.bankCode} readOnly />
                              <span className="search-icon">
                                <FontAwesomeIcon icon={faSearch} />
                              </span>
                            </div>
                          </td>
                          <td className={ableOrDisableBankAccountInfoUi(item.index)}>
                            <input className="grid-input" defaultValue={item.bankName} disabled />
                          </td>
                          <td
                            className={`${ableOrDisableBankAccountInfoUi(item.index)} ${getValidationClassName(
                              'account',
                              'td',
                              index,
                            )}`}
                          >
                            <input
                              className={getValidationClassName('account', 'input', index)}
                              name={`purposeOfLoanList[${item.index}].account`}
                              ref={register({
                                maxLength: 40,
                              })}
                            />
                          </td>
                          <td
                            className={`${ableOrDisableBankAccountInfoUi(item.index)} ${getValidationClassName(
                              'accountOwner',
                              'td',
                              index,
                            )}`}
                          >
                            <input
                              className={getValidationClassName('accountOwner', 'input', index)}
                              name={`purposeOfLoanList[${item.index}].accountOwner`}
                              ref={register({
                                maxLength: 150,
                              })}
                            />
                          </td>
                          {isRegistrationResultVisible && getValidationResult(index)}
                        </tr>
                        <tr>
                          <td colSpan={colSpan}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <div className="attach-file-link-button-disable me-3">{t('text:Account_Doc')}</div>
                                <input
                                  name={`accountAttachments[${item.index}]`}
                                  type="file"
                                  id={`purposeOfLoanListAccountAttachment${item.index}`}
                                  style={{ display: 'none' }}
                                  ref={register()}
                                  onChange={(e: any) => onChangeAccountFileName(e, item.index)}
                                />
                                <label
                                  htmlFor={`purposeOfLoanListAccountAttachment${item.index}`}
                                  className="attach-file-link-button"
                                >
                                  {t('text:Attach_File')}
                                </label>
                                <div id={`FileUploadAccountAttachment${item.index}`} className="align-self-center ms-3">
                                  {item.accountFileName ? item.accountFileName : t('text:No_file_attached')}
                                  {item.accountFileName && !item.accountFileChanged && (
                                    <IconButton onClick={() => onClickAccountFileDownLoad(item.index)}>
                                      <FontAwesomeIcon icon={faDownload} />
                                    </IconButton>
                                  )}
                                </div>
                              </div>
                              <IconButton
                                onClick={e => resetAccountFile(e, item.index)}
                                className={`delete-uploaded-excel-button ${!item.accountFileName && 'd-none'}`}
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={colSpan}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <div className="attach-file-link-button-disable me-3">{t('text:Purpose_Doc')}</div>
                                <input
                                  name={`purposeAttachments[${item.index}]`}
                                  type="file"
                                  id={`purposeOfLoanListPurposeAttachment${item.index}`}
                                  style={{ display: 'none' }}
                                  ref={register()}
                                  onChange={(e: any) => onChangePurposeFileName(e, item.index)}
                                />
                                <label
                                  htmlFor={`purposeOfLoanListPurposeAttachment${item.index}`}
                                  className="attach-file-link-button"
                                >
                                  {t('text:Attach_File')}
                                </label>
                                <div id={`purposeFileName${item.index}`} className="align-self-center ms-3">
                                  {item.purposeFileName ? item.purposeFileName : t('text:No_file_attached')}
                                  {item.purposeFileName && !item.purposeFileChanged && (
                                    <IconButton onClick={() => onClickPurposeFileDownLoad(item.index)}>
                                      <FontAwesomeIcon icon={faDownload} />
                                    </IconButton>
                                  )}
                                </div>
                              </div>
                              <IconButton
                                onClick={e => resetPurposeFile(e, item.index)}
                                className={`delete-uploaded-excel-button ${!item.purposeFileName && 'd-none'}`}
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="grid-total">
              {t('text:Total')} : {t('format:number', { value: totalArAmount })}
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <SectionTitle title={t('text:Purpose_of_Financing')} />
        <GuideMessage
          isImportContentArea={true}
          message={[
            `${t(
              'text:If_requesting_disbursement_to_a_third_party_select_Beneficiary_Account_from_the_Disbursement_Account_and_enter_the_account_information',
            )} ${t('text:If_not_select_Designated_Disbursement_Account')}`,
            t(
              'text:The_Remittance_Document_and_the_Purpose_of_Financing_Document_can_be_submitted_by_attaching_them_to_the_Account_Doc_and_the_Purpose_Doc_section_respectively',
            ),
          ]}
        >
          {{
            button: (
              <>
                <Button
                  onClick={onClickBulkTransferDownloadForm}
                  disabled={!showDocumentTypeBtn(DOCUMENT_FORM_TYPE.BULK_TRANSFER)}
                  bold
                >
                  {t('text:Application_for_Bulk_Transfer_Form')}
                </Button>
                <Button
                  disabled={!showDocumentTypeBtn(DOCUMENT_FORM_TYPE.REMITTANCE)}
                  onClick={onClickRemittanceDownloadForm}
                  bold
                >
                  {t('text:Application_for_Remittance_Form')}
                </Button>
              </>
            ),
          }}
        </GuideMessage>

        {renderPurposeOfFinancingTable()}
      </>
    );
  },
);

PurposeOfFinancing.displayName = 'PurposeOfFinancing';

export default PurposeOfFinancing;
