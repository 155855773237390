import type { ATTACHMENT_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type {
  MultipleAnchorLoanRequestDetailVO,
  MultipleAnchorLoanRequestDetailVOModel,
} from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import { formattingToMultipleAnchorLoanRequestDetailVOModel } from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import type {
  MultipleAnchorLoanRequestRelatedAnchorLoanVO,
  MultipleAnchorLoanRequestRelatedAnchorLoanVOModel,
} from 'models/vo/MultipleAnchorLoanRequestRelatedAnchorLoanVO';
import { formattingToMultipleAnchorLoanRequestRelatedAnchorLoanVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedAnchorLoanVO';
import type {
  MultipleAnchorLoanRequestRelatedSuccessArVO,
  MultipleAnchorLoanRequestRelatedSuccessArVOModel,
} from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessArVO';
import { formattingToMultipleAnchorLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessArVO';
import type {
  MultipleAnchorLoanRequestRelatedSuccessInvoiceVO,
  MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel,
} from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessInvoiceVO';
import { formattingToMultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessInvoiceVO';
import type {
  MultipleAnchorLoanRequestVO,
  MultipleAnchorLoanRequestVOModel,
} from 'models/vo/MultipleAnchorLoanRequestVO';
import { formattingToMultipleAnchorLoanRequestVOModel } from 'models/vo/MultipleAnchorLoanRequestVO';
import http from 'utils/http';

import API_FI from '../index';

import type { FinancierMultipleLoanListRequest } from './requests';

export async function requestFinancierMultipleAnchorLoanList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierMultipleLoanListRequest,
): Promise<Pageable<MultipleAnchorLoanRequestVOModel[]>> {
  const response = await http.get<Pageable<MultipleAnchorLoanRequestVO[]>>({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.MULTIPLE_REQUEST_ANCHOR_LOAN,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return { ...response, content: response.content.map(data => formattingToMultipleAnchorLoanRequestVOModel(data)) };
}

export async function requestFinancierMultipleAnchorLoanDetail(
  multipleRequestAnchorLoanId: number,
): Promise<MultipleAnchorLoanRequestDetailVOModel> {
  const response = await http.get<MultipleAnchorLoanRequestDetailVO>({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.MULTIPLE_REQUEST_ANCHOR_LOANS_DETAIL(multipleRequestAnchorLoanId),
  });

  return formattingToMultipleAnchorLoanRequestDetailVOModel(response);
}

export async function requestFinancierMultipleAnchorLoanApprove(multipleRequestAnchorLoanId: number) {
  return await http.put({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.APPROVE(multipleRequestAnchorLoanId),
  });
}

export async function requestFinancierMultipleAnchorLoanApproveRequest(multipleRequestAnchorLoanId: number) {
  return await http.put({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.APPROVE_REQUEST(multipleRequestAnchorLoanId),
  });
}

export async function requestFinancierMultipleAnchorLoanCancel(multipleRequestAnchorLoanId: number) {
  return await http.put({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.CANCEL(multipleRequestAnchorLoanId),
  });
}

export async function requestFinancierMultipleAnchorLoanReject(multipleRequestAnchorLoanId: number, reason: string) {
  return await http.put({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.REJECT(multipleRequestAnchorLoanId),
    data: {
      reason,
    },
  });
}

export async function requestFinancierMultipleAnchorLoanReturn(multipleRequestAnchorLoanId: number, reason: string) {
  return await http.put({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.RETURN(multipleRequestAnchorLoanId),
    data: {
      reason,
    },
  });
}

// 승인된 복수 대출 거절 요청 승인
export async function requestFinancierMultipleAnchorLoanApproveLoanRejection(multipleRequestAnchorLoanId: number) {
  return await http.put({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.APPROVE_APPROVED_LOAN_REJECTION(multipleRequestAnchorLoanId),
  });
}

// 승인된 복수 대출 거절 요청
export async function requestFinancierMultipleAnchorLoanRequestApprovedLoanRejection(
  multipleRequestAnchorLoanId: number,
  rejectReason: string,
) {
  return await http.put({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.REQUEST_APPROVED_LOAN_REJECTION(multipleRequestAnchorLoanId),
    data: {
      rejectReason,
    },
  });
}

//  복수 대출 관련 파일 다운로드
export async function requestFinancierMultipleAnchorLoanRequestDownloadAttachment(
  multipleRequestAnchorLoanId: number,
  attachmentType: ATTACHMENT_TYPE,
  anchorLoanEtcAttachmentId?: number,
) {
  return await http.download(API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.DOWNLOAD_ATTACHMENT(multipleRequestAnchorLoanId), {
    attachmentType,
    anchorLoanEtcAttachmentId,
  });
}

export async function requestFinancierMultipleAnchorLoanListRelatedArs(
  multipleRequestAnchorLoanId: number,
): Promise<Pageable<MultipleAnchorLoanRequestRelatedSuccessArVOModel[]>> {
  const response = await http.get<Pageable<MultipleAnchorLoanRequestRelatedSuccessArVO[]>>({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.ARS(multipleRequestAnchorLoanId),
  });

  return {
    ...response,
    content: response.content.map(data => formattingToMultipleAnchorLoanRequestRelatedSuccessArVOModel(data)),
  };
}

export async function requestFinancierMultipleAnchorLoanListRelatedInvoices(
  multipleRequestAnchorLoanId: number,
): Promise<Pageable<MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel[]>> {
  const response = await http.get<Pageable<MultipleAnchorLoanRequestRelatedSuccessInvoiceVO[]>>({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.INVOICES(multipleRequestAnchorLoanId),
  });

  return {
    ...response,
    content: response.content.map(data => formattingToMultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel(data)),
  };
}

export async function requestFinancierMultipleAnchorLoanListRelatedLoan(
  multipleRequestAnchorLoanId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<MultipleAnchorLoanRequestRelatedAnchorLoanVOModel[]>> {
  const response = await http.get<Pageable<MultipleAnchorLoanRequestRelatedAnchorLoanVO[]>>({
    url: API_FI.MULTIPLE_REQUEST_ANCHOR_LOANS.LOANS(multipleRequestAnchorLoanId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToMultipleAnchorLoanRequestRelatedAnchorLoanVOModel(data)),
  };
}
