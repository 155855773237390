export interface RegistrableLimitVO {
  referenceNumberDuplicationCheck: boolean;
  maxLimit: number;
}

export interface RegistrableLimitVOModel extends RegistrableLimitVO {}

export function formattingToRegistrableLimitVOModel(data: RegistrableLimitVO): RegistrableLimitVOModel {
  return {
    ...data,
  };
}
