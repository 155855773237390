import type Pageable from 'models/Pageable';
import type { EnterpriseVO, EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { formattingToEnterpriseVOModel } from 'models/vo/EnterpriseVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

export async function requestAnchorRelatedFinanciers(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<EnterpriseVOModel[]>> {
  const response = await http.get<Pageable<EnterpriseVO[]>>({
    url: API_AC.ENTERPRISES.RELATED_FINANCIERS,
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToEnterpriseVOModel(data)),
  };
}
