import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { NumberFormatValues } from 'react-number-format';

import { isEmpty, isNumber } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import { maxLength } from 'components/stateless/Form/NumberInput/NumberInput';
import type { SelectOptionType } from 'components/stateless/Form/Select/Select';
import { LOAN_TERM_TYPE } from 'enums';
import useAgreementViewModel from 'pages/financier/manage-partner/agreement/models/agreement/useAgreementViewModel';
import { getSelectedLoanTermType } from 'utils/logic';

const useFinancingDurationController = (isVendorFactoring?: boolean) => {
  const { t } = useTranslation('format');
  const { agreement, isMaturityExtension, updateIsMaturityExtension } = useAgreementViewModel();

  const { loanTermType, maxExtensibleLoanCount } = agreement;

  const {
    methods: { setValue, register, errors, clearErrors },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();

  const [checkedLoanTermType, setCheckedLoanTermType] = useState<LOAN_TERM_TYPE | null>(null);
  const [selectedLoanTermType, setSelectedLoanTermType] = useState<LOAN_TERM_TYPE>(LOAN_TERM_TYPE.DAILY);

  const isFixedDurationEditable =
    isEditable && (!getReadOnlyValue('loanTermType') || !getReadOnlyValue('loanTermUnit'));

  const loanTermTypeSelectOptions: SelectOptionType[] = useMemo(() => {
    const selectOptions: SelectOptionType[] = [
      { label: t('text:Days'), value: LOAN_TERM_TYPE.DAILY },
      { label: t('text:Months'), value: LOAN_TERM_TYPE.MONTHLY },
    ];

    return selectOptions;
  }, [t]);

  const getIsMaxExtensibleLoanCountAllowed = (values: NumberFormatValues) => {
    const { value } = values;

    const [integer] = value.split('.');
    const isValidInteger = integer.length <= maxLength.integer;
    const isGreaterThanOne = isEmpty(integer) || Number(integer) >= 1;

    return isValidInteger && isGreaterThanOne;
  };

  const handleSelectedLoanTermTypeChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    setCheckedLoanTermType(value as LOAN_TERM_TYPE);
    setSelectedLoanTermType(value as LOAN_TERM_TYPE);
    setValue('loanTermType', value);
  };

  const handleCheckedLoanTermTypeChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCheckedLoanTermType(value as LOAN_TERM_TYPE);
    setSelectedLoanTermType(LOAN_TERM_TYPE.DAILY); // select initial value
    setValue('loanTermType', value);
    setValue('minimumLoanRange', null);
    setValue('maximumLoanRange', null);
    setValue('loanTermUnit', null);

    clearErrors(['loanTermType', 'minimumLoanRange', 'maximumLoanRange', 'loanTermUnit']);
  };

  const handleIsMaturityExtensionChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    updateIsMaturityExtension(value);
    setValue('maxExtensibleLoanCount', null);

    if (errors?.maxExtensibleLoanCount) clearErrors('maxExtensibleLoanCount');
  };

  useEffect(() => {
    if (isNumber(maxExtensibleLoanCount)) updateIsMaturityExtension(String(maxExtensibleLoanCount > 0));
    if (maxExtensibleLoanCount === 0) setValue('maxExtensibleLoanCount', null);
  }, [isEditable, maxExtensibleLoanCount, setValue]);

  useEffect(() => {
    setCheckedLoanTermType(loanTermType || null);
    setSelectedLoanTermType(getSelectedLoanTermType(loanTermType) || LOAN_TERM_TYPE.DAILY);

    if (loanTermType !== LOAN_TERM_TYPE.RANGE) {
      setValue('minimumLoanRange', null);
      setValue('maximumLoanRange', null);
    }

    if (loanTermType !== LOAN_TERM_TYPE.DAILY && loanTermType !== LOAN_TERM_TYPE.MONTHLY) {
      setValue('loanTermUnit', null);
    }
  }, [isEditable, loanTermType, setValue]);

  useEffect(() => {
    if (isVendorFactoring && isEditable) {
      setCheckedLoanTermType(LOAN_TERM_TYPE.RANGE);
      setValue('loanTermType', LOAN_TERM_TYPE.RANGE);
      setValue('loanTermUnit', null);
    }
  }, [isEditable, isVendorFactoring, setValue]);

  return {
    register,
    errors,
    isEditable,
    selectedLoanTermType,
    checkedLoanTermType,
    isMaturityExtension,
    isFixedDurationEditable,
    getReadOnlyValue,
    getIsMaxExtensibleLoanCountAllowed,
    loanTermTypeSelectOptions,
    handleCheckedLoanTermTypeChange,
    handleIsMaturityExtensionChange,
    handleSelectedLoanTermTypeChange,
  };
};

export default useFinancingDurationController;
