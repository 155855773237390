import { COST_OF_FUND_TYPE, stringToEnum } from 'enums';

export interface CostOfFundVO {
  id: number;
  daysToBill: number;
  type: string;
  amount: number;
  rate: number;
  overdueInterestRateSettingId: number;
}

export interface CostOfFundVOModel extends CostOfFundVO {
  type: COST_OF_FUND_TYPE;
}

export function formattingToCostOfFundVOModel(data: CostOfFundVO): CostOfFundVOModel {
  return {
    ...data,
    type: stringToEnum(COST_OF_FUND_TYPE, data.type),
  };
}
