import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { InventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestDealerInventoryList } from 'utils/http/api/dealer/inventory-summaries';
import type { DealerInventorySummaryListRequest } from 'utils/http/api/dealer/inventory-summaries/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const DE_SECURED_INVENTORY_LIST_QS_KEY = 'de-secured-inventory-list';

function DealerSecuredInventoryList() {
  // 기본 State
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { pageable, setPageable } = usePageable(DE_SECURED_INVENTORY_LIST_QS_KEY);
  const getProperty = useProperty<DealerInventorySummaryListRequest>();

  const signInData = getSignIn();

  // 화면 State
  const [inventoryList, setInventoryList] = useState<Pageable<InventorySummaryVOModel[]>>();

  // useForm
  const { register, getValues, reset, setValue, control } = useForm<DealerInventorySummaryListRequest>();

  // onMounted
  useEffect(() => {
    if (mounted) {
      setFormValues<DealerInventorySummaryListRequest>(
        setValue,
        getParsedSearchParams(DE_SECURED_INVENTORY_LIST_QS_KEY).formSearchData,
      );
      fetchDeInventoryList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  const fetchDeInventoryList = async (
    pageNumber: number,
    rowCount: number,
    data: DealerInventorySummaryListRequest,
  ) => {
    try {
      const fetchInventoryList = await requestDealerInventoryList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        DE_SECURED_INVENTORY_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setInventoryList(fetchInventoryList);
        setPageable(fetchInventoryList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onSearchSubmit = async () => {
    await fetchDeInventoryList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    await fetchDeInventoryList(
      pageNumber,
      sizePerPage,
      getParsedSearchParams(DE_SECURED_INVENTORY_LIST_QS_KEY).formSearchData,
    );
  };

  const onRemoveFilter = (event: any) => {
    reset({});
    event.preventDefault();
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Registration_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Secured_Creditor'),
      colWidths: 120,
    },
    {
      headerText: t('text:Number_of_Items'),
      colWidths: 120,
    },
    {
      headerText: t('text:Total_Quantity'),
      colWidths: 120,
    },
    {
      headerText: t('text:Total_Amount'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return inventoryList?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.financierName} />
          <Td data={item.inventoryDescription} format="number" />
          <Td data={item.totalCount} format="number" />
          <Td data={item.totalAmount} format="number" />
          <TdLink
            path={ROUTES_DE.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_DETAIL_BUILD_PATH(item.inventorySummaryId)}
          />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Secured_Inventory_Update')} />
      <GuideMessage
        message={
          signInData?.authorityType === AUTHORITY_TYPE.AUTHORIZER
            ? [
                t('text:Click_the_button_on_the_right_to_register_the_secured_inventory'),
                t('text:The_registered_secured_inventory_information_can_be_found_in_the_list_below'),
              ]
            : signInData?.authorityType === AUTHORITY_TYPE.OPERATOR
            ? [t('text:The_registered_secured_inventory_information_can_be_found_in_the_list_below')]
            : []
        }
      >
        {{
          button: (
            <Button to={ROUTES_DE.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_REGISTER}>{t('text:Register')}</Button>
          ),
        }}
      </GuideMessage>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name={getProperty('createdDateFrom')} control={control} />
              <SearchDatePicker placeholder={t('text:to')} name={getProperty('createdDateTo')} control={control} />
              <SearchLabel label={t('text:Secured_Creditor')} />
              <SearchInput name={getProperty('financierName')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Number_of_Items')} />
              <SearchInput
                type="number"
                name={getProperty('minimumInventoryDescription')}
                ref={register}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                type="number"
                name={getProperty('maximumInventoryDescription')}
                ref={register}
                col={2}
                placeholder={t('text:to')}
              />
              <SearchLabel label={t('text:Total_Quantity')} />
              <SearchInput
                type="number"
                name={getProperty('minimumTotalCount')}
                ref={register}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                type="number"
                name={getProperty('maximumTotalCount')}
                ref={register}
                col={2}
                placeholder={t('text:to')}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Total_Amount')} />
              <SearchInput
                type="number"
                name={getProperty('minimumTotalAmount')}
                ref={register}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                type="number"
                name={getProperty('maximumTotalAmount')}
                ref={register}
                col={2}
                placeholder={t('text:to')}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {inventoryList?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerSecuredInventoryList;
