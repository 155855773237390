import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

const useScrollIntoView = <T extends Element>(): [RefObject<T>, () => void] => {
  const ref = useRef<T>(null);
  const [shouldScrollTo, setShouldScrollTo] = useState(false);

  const scrollToView = () => setShouldScrollTo(true);

  useEffect(() => {
    if (ref.current && shouldScrollTo) {
      ref.current.scrollIntoView({ block: 'center' });
      setShouldScrollTo(false);
    }
  }, [shouldScrollTo]);

  return [ref, scrollToView];
};

export default useScrollIntoView;
