import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import Select from 'components/stateless/Select/Select';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import getSelectOptions from 'constants/selectOptions';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { requestFinancierAnchorAgreementList } from 'utils/http/api/financier/anchor-agreements';
import type { SearchFiAnchorAgreementListDTO } from 'utils/http/api/financier/anchor-agreements/requests';
import useModal from 'utils/modal/useModal';

interface FinancierAnchorReportSearchAnchorAgreementModalProps {
  modalId: number;
  getSelectedAnchorAgreementData(data: AnchorAgreementVOModel): void;
}

const getConstants = () => {
  const { t } = useTranslation(['format']);

  const SEARCH_ANCHOR_AGREEMENT_RESULT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Master_Agreement_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Agreement_Status'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 80,
    },
  ];

  return { t, SEARCH_ANCHOR_AGREEMENT_RESULT_TABLE_HEADERS };
};

function FinancierAnchorReportSearchAnchorAgreementModal({
  modalId,
  getSelectedAnchorAgreementData,
}: FinancierAnchorReportSearchAnchorAgreementModalProps): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();
  const { t, SEARCH_ANCHOR_AGREEMENT_RESULT_TABLE_HEADERS } = getConstants();
  const [anchorAgreementPageData, setAnchorAgreementPageData] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const { pageable: anchorAgreementPageable, setPageable: setAnchorAgreementPageable } = usePageable();
  const {
    register: anchorAgreementSearchFormRegister,
    getValues: getAnchorAgreementSearchFormValues,
    handleSubmit: handleAnchorAgreementSearchFormSubmit,
    watch: watchAnchorAgreementSearchForm,
  } = useForm<{ selectedKey: keyof SearchFiAnchorAgreementListDTO; inputValue: string }>();
  const { inputValue: inputValueWatcher } = watchAnchorAgreementSearchForm();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (selectedPageNumber: number = 1, selectedRowCount: number = 10): Promise<void> => {
    const searchParameters = getAnchorAgreementSearchFormValues();

    try {
      const anchorAgreementPageResponse = await requestFinancierAnchorAgreementList(
        selectedPageNumber,
        selectedRowCount,
        {
          [searchParameters.selectedKey]: searchParameters.inputValue,
        },
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementPageData(anchorAgreementPageResponse);
        setAnchorAgreementPageable(anchorAgreementPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearch = async (): Promise<void> => {
    await fetchAll(1, anchorAgreementPageable.sizePerPage);
  };

  const getSearchFormInputValueTextLength = (): string => {
    if (inputValueWatcher && inputValueWatcher.length !== 0)
      return t('text:Current_text_length_is_') + `${inputValueWatcher.length}`;

    return '';
  };

  const onSelect = (e: any, data: AnchorAgreementVOModel): void => {
    e.preventDefault();
    getSelectedAnchorAgreementData(data);

    modal.close(modalId);
  };

  const renderSearchAnchorAgreementResult = (): JSX.Element[] | undefined => {
    return anchorAgreementPageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.financierClientName} />
        <Td data={item.financierClientCode} />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
        <Td data={t(`code:anchor-agreement-status.${item.anchorAgreementStatus}`)} format="code" />
        <Td className="information-table-more">
          <Button onClick={e => onSelect(e, { ...item })}>{t('text:Select')}</Button>
        </Td>
      </Tr>
    ));
  };

  return (
    <div className="modal-body">
      <div className="modal-container">
        <div className="d-flex information-filter mt-0">
          <Select
            name="selectedKey"
            className="information-form__select"
            selectOptions={getSelectOptions<string>('FINANCIER_ANCHOR_AGREEMENT_LIST_REQUEST', [
              'anchorClientCode',
              'contractNo',
              'anchorClientName',
            ])}
            ref={anchorAgreementSearchFormRegister}
          />
          <form onSubmit={handleAnchorAgreementSearchFormSubmit(onClickSearch)}>
            <input type="text" placeholder="Search..." name="inputValue" ref={anchorAgreementSearchFormRegister} />
            <Button type="submit">{t('text:Search')}</Button>
          </form>
        </div>
        <div className="text-brick-red p-2 mb-2">{getSearchFormInputValueTextLength()}</div>
        <TableBorder>
          <TableHeader header={SEARCH_ANCHOR_AGREEMENT_RESULT_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCH_ANCHOR_AGREEMENT_RESULT_TABLE_HEADERS.length}>
            {renderSearchAnchorAgreementResult()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={anchorAgreementPageable} paginate={fetchAll} />
      </div>
    </div>
  );
}

export default FinancierAnchorReportSearchAnchorAgreementModal;
