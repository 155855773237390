import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from 'components/stateless/Select/Select';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { AnPotentialPartnerFinancingApplicationSubmitRequest } from 'utils/http/api/anonymous/potential-partner-financing-applications/request';

import { usePotentialPartnerApplicationActions } from '../../../../PotentialPartnerApplicationProvider';

interface PotentialPartnerApplicationStep2SelectBranchProps {
  branchesInfo: BranchVOModel[];
}

function PotentialPartnerApplicationStep2SelectBranch({
  branchesInfo,
}: PotentialPartnerApplicationStep2SelectBranchProps) {
  const { t } = useTranslation();
  const { register } = useFormContext<AnPotentialPartnerFinancingApplicationSubmitRequest>();
  const { selectBranch } = usePotentialPartnerApplicationActions();

  const [showSelectedMessage, setShowSelectedMessage] = useState<boolean>(false);
  const branchSelectOptions = useMemo(() => {
    return branchesInfo.map(item => {
      return {
        label: item.branchName,
        value: item.branchId,
      };
    });
  }, []);

  const onChangeBranchSelect = (e: any) => {
    const selectedTarget = e.target;
    selectBranch(selectedTarget.options[selectedTarget.selectedIndex].label);
    setShowSelectedMessage(true);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="pp-branch-select-wrapper">
        <Select
          className="pp-branch-select"
          selectOptions={branchSelectOptions}
          name="branchId"
          placeholderOptions={{ show: true, text: t('text:Select') }}
          ref={register({
            required: true,
          })}
          onChange={onChangeBranchSelect}
        />
      </div>
      <div className="pp-branch-select-msg">
        {showSelectedMessage &&
          t('text:The_branch_that_reviews_your_application_may_be_different_from_the_branch_you_selected')}
      </div>
    </div>
  );
}

export default PotentialPartnerApplicationStep2SelectBranch;
