import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { PageableType } from 'hooks/usePageable';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';

interface InformationEditHistoryProps {
  anchorDealerHistoryList: WaitingAnchorDealerVOModel[];
  pageable: PageableType;
  paginate(pageNumber: number, sizePerPage: number): Promise<void>;
}

function InformationEditHistory({ anchorDealerHistoryList, pageable, paginate }: InformationEditHistoryProps) {
  const { t } = useTranslation();
  const EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Dealer_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Tax_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Company_Registration_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 100,
    },
    {
      headerText: t('text:Fax'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Title'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Email'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 100,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Information_Edit_History')} />
      <TableBorder>
        <TableHeader header={EDIT_HISTORY_TABLE_HEADERS} />
        <TableBody numOfCol={EDIT_HISTORY_TABLE_HEADERS.length}>
          {anchorDealerHistoryList?.map((item, i) => (
            <Tr key={i}>
              <Td data={item.updatedDateTime} format="datetime" />
              <Td data={item.name} />
              <Td data={item.taxCode} />
              <Td data={item.businessCode} />
              <Td data={item.telephone} />
              <Td data={item.fax} />
              <Td data={item.representativeName} />
              <Td data={item.representativePosition} />
              <Td data={item.representativeEmail} />
              <Td data={item.address} />
              <Td data={`${item.operatorUserLoginId} / ${item.operatorUserName}`} />
              <Td data={`${item.authorizerUserLoginId} / ${item.authorizerUserName}`} />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default InformationEditHistory;
