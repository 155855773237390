import type { MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import { COMMON_APPROVAL_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';

import { ROUTES_AC } from '../../../../../../constants/routes/anchor';

interface AnchorDealerCompanyInfoListWaitingTabProps {
  data: Pageable<WaitingAnchorDealerVOModel[]>;
  pageable: PageableType;
  paginate(page: number, sizePerPage: number): void;
  handleClickSearch: MouseEventHandler<HTMLButtonElement>;
}

function AnchorDealerCompanyInfoListWaitingTab({
  data,
  pageable,
  paginate,
  handleClickSearch,
}: AnchorDealerCompanyInfoListWaitingTabProps) {
  const { register, reset } = useFormContext();
  const { t } = useTranslation(['format']);

  const WAITING_TAB_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Dealer_Name'),
    },
    {
      headerText: t('text:Dealer_Tax_Code'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Responsible_Financier_Name'),
    },
    {
      headerText: t('text:Registration_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={() => {
              reset({});
            }}
          >
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Dealer_Name')} />
              <SearchInput name="name" ref={register} />
              <SearchLabel label={t('text:Dealer_Tax_Code')} />
              <SearchInput name="taxCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Legal_Representative_Name')} />
              <SearchInput name="representativeName" ref={register} />
              <SearchLabel label={t('text:Legal_Representative_Email')} />
              <SearchInput name="representativeEmail" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput name="telephone" ref={register} />
              <SearchLabel label={t('text:Registered_Office_Address')} />
              <SearchInput name="address" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Responsible_Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
              <SearchLabel label={t('text:Registration_Status')} />
              <SearchSelect
                name="approvalTypes"
                ref={register}
                selectOptions={getSelectOptions<COMMON_APPROVAL_TYPE>(
                  'COMMON_APPROVAL_TYPE',
                  [
                    COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                    COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                    COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  ],
                  true,
                )}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {data.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={WAITING_TAB_TABLE_HEADERS} />
          <TableBody numOfCol={WAITING_TAB_TABLE_HEADERS.length}>
            {data?.content?.map((item: WaitingAnchorDealerVOModel, index: number) => (
              <Tr key={index}>
                <Td data={item.name} />
                <Td data={item.taxCode} />
                <Td data={item.representativeName} />
                <Td data={item.representativeEmail} />
                <Td data={item.telephone} />
                <Td data={item.address} />
                <Td data={item.financierName} />
                <Td
                  className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
                  data={t(`code:common-approval-type.${item.approvalType}`)}
                />
                <TdLink path={ROUTES_AC.MANAGE_DEALER.COMPANY_INFO_WAITING_DETAIL_BUILD_PATH(item.id)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorDealerCompanyInfoListWaitingTab;
