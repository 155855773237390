import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, BASIS_INTEREST_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { BasisInterestVOModel } from 'models/vo/BasisInterestVO';
import {
  requestFinancierBasisInterestList,
  requestFinancinerBasisInterestDelete,
} from 'utils/http/api/financier/basis-interests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType, getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

function FinancierInterestRateFeeList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const CURRENT_USER_AUTHORITY: AUTHORITY_TYPE | undefined = getSignIn()?.authorityType;
  const { pageable, setPageable } = usePageable();
  const [basisInterestPage, setBasisInterestPage] = useState<Pageable<BasisInterestVOModel[]>>();
  const [interestInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.BASIS_INTEREST_RATE));
  const isAdmin = CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.ADMIN;

  useEffect(() => {
    if (mounted) {
      fetchedBasisInterestList();
    }
  }, [mounted]);

  async function fetchedBasisInterestList(pageNumber: number = 0, rowCount: number = 10) {
    try {
      const financierBasisInterestPage = await requestFinancierBasisInterestList(pageNumber, rowCount);

      ReactDOM.unstable_batchedUpdates(() => {
        setBasisInterestPage(financierBasisInterestPage);
        setPageable(financierBasisInterestPage);
      });
    } catch (e) {
      modal.show(e);
    }
  }

  const onClickRemoveRegisteredList = (basisInterestId: number) => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_delete_the_base_interest_rate_information?')}
        <br />
        {t('text:If_deleted_today_s_effective_base_interest_rate_may_change')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          try {
            await requestFinancinerBasisInterestDelete(basisInterestId).then(() => fetchedBasisInterestList());
          } catch (e) {
            modal.show(e);
          }
        },
      },
    );
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchedBasisInterestList(page, sizePerPage);
  };

  const INTERFACE_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Registration_Date'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Base_Interest_Type'),
    },
    {
      headerText: t('text:Base_Interest_Term'),
    },
    {
      headerText: `${t('text:Base_Interest_Rate')} (%)`,
    },
  ];

  const TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Registration_Date'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Base_Interest_Type'),
    },
    {
      headerText: t('text:Base_Interest_Term'),
    },
    {
      headerText: `${t('text:Base_Interest_Rate')} (%)`,
    },
  ];

  const ADMIN_TABLE_HEADERS: HeaderType[] = [
    ...TABLE_HEADERS,
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const getTableHeaders = (): HeaderType[] => {
    return isAdmin ? ADMIN_TABLE_HEADERS : TABLE_HEADERS;
  };

  const renderResultTable = () => {
    return basisInterestPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.currencyType} />
          <Td data={item.basisInterestDate} format="date" />
          {item.basisInterestType !== BASIS_INTEREST_TYPE.CUSTOM && <Td data={item.basisInterestType} />}
          {item.basisInterestType === BASIS_INTEREST_TYPE.CUSTOM && (
            <Td>
              <div>{tableValueManage(item.basisInterestType)}</div>
              <div>({tableValueManage(item.basisInterestBankCode)})</div>
            </Td>
          )}
          <Td data={item.basisInterestTerm} />
          <Td data={item.basisInterestRate} format="percent" />
          {isAdmin && !interestInterface && (
            <Td className="text-center">
              <IconButton
                className="trash-button"
                onClick={() => {
                  onClickRemoveRegisteredList(item.basisInterestId);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </IconButton>
            </Td>
          )}
        </Tr>
      );
    });
  };

  const getGuideMessage = () => {
    if (isAdmin) {
      if (interestInterface) {
        return [
          t('text:Click_the_button_on_the_right_to_register_the_base_interest_rate'),
          `${t(
            'text:Base_interest_rate_information_is_required_in_order_to_show_the_expected_interest_rate_when_Partners_make_financing_applications',
          )} ${t('text:It_is_applied_in_the_formula_to_draw_the_final_interest')}`,
        ];
      } else {
        return [
          t('text:Click_the_button_on_the_right_to_register_the_base_interest_rate'),
          `${t(
            'text:Base_interest_rate_information_is_required_in_order_to_show_the_expected_interest_rate_when_Partners_make_financing_applications',
          )} ${t('text:It_is_applied_in_the_formula_to_draw_the_final_interest')}`,
          t(
            'text:To_revise_the_registered_base_interest_information_delete_the_previous_interest_information_before_registration',
          ),
        ];
      }
    } else {
      return [
        `${t(
          'text:Base_interest_rate_information_is_required_in_order_to_show_the_expected_interest_rate_when_Partners_make_financing_applications',
        )} ${t('text:It_is_applied_in_the_formula_to_draw_the_final_interest')}`,
      ];
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:Base_Interest_Rate')} />
      <GuideMessage message={getGuideMessage()}>
        {{
          button: (
            <>
              {isAdmin && (
                <Button to={ROUTES_FI.PROGRAM_SETTINGS.INTEREST_RATE_FEE_REGISTER}>
                  {t('text:Register_Base_Interest_Rate')}
                </Button>
              )}
            </>
          ),
        }}
      </GuideMessage>
      <div className="content-area">
        <SectionTitle title={t('text:Registered_List')} />
        <TableBorder>
          <TableHeader header={interestInterface ? INTERFACE_TABLE_HEADERS : getTableHeaders()} />
          <TableBody numOfCol={interestInterface ? INTERFACE_TABLE_HEADERS.length : getTableHeaders().length}>
            {renderResultTable()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierInterestRateFeeList;
