import type { ANCHOR_AGREEMENT_STATUS, CURRENCY_TYPE } from 'enums';
import type { COLLATERAL_TYPE } from 'enums';

export interface AnchorFinancingOptionVO {
  anchorFinancingOptionId: number;
  anchorAgreementId: number;
  updatedDateTime: string;
  contractNo: string;
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  branchId: number;
  branchCode: string;
  branchName: string;
  anchorAgreementStartDate: string;
  anchorAgreementExpiryDate: string;
  anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS;
  financierName: string;
}

export interface AnchorFinancingOptionVOModel extends AnchorFinancingOptionVO {}

export function formattingToAnchorFinancingOptionVOModel(data: AnchorFinancingOptionVO): AnchorFinancingOptionVOModel {
  return {
    ...data,
  };
}
