export interface PPHeaderType {
  headerText?: string | JSX.Element;
  ratio?: number;
  textAlign?: 'center' | 'left' | 'right';
}

interface PPTableHeaderProps {
  headers: PPHeaderType[];
}
function PPTableHeader({ headers }: PPTableHeaderProps) {
  return (
    <thead>
      <tr>
        {headers.map((value, index) => (
          <th
            className={value.textAlign ? `text-${value.textAlign}` : ''}
            scope="col"
            key={index}
            style={{ width: `${value.ratio}%` }}
          >
            {value.headerText}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default PPTableHeader;
