import type Pageable from 'models/Pageable';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVO, AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { formattingToAnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { UserVO, UserVOModel } from 'models/vo/UserVO';
import { formattingToUserVOModel } from 'models/vo/UserVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';
import type {
  AnchorAdminOperatorRegisterRequest,
  AnchorAdminOperatorUpdateRequest,
  AnchorUserListRequest,
  AnchorUserNotRelatedAnchorAgreementListRequest,
} from 'utils/http/api/anchor/users/requests';

export async function requestAnchorUserList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorUserListRequest,
): Promise<Pageable<UserVOModel[]>> {
  const response = await http.get<Pageable<UserVO[]>>({
    url: API_AC.USERS.USER,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const userList: UserVOModel[] = response.content.map((data: UserVO) => formattingToUserVOModel(data));
  const userPage: Pageable<UserVOModel[]> = { ...response, content: userList };

  return userPage;
}

// 사용자 상세
export async function requestAnchorUserDetail(userId: number): Promise<UserVOModel> {
  const response = await http.get<UserVO>({
    url: API_AC.USERS.USER_DETAIL(userId),
  });

  return formattingToUserVOModel(response);
}

// 사용자 등록(admin operator)
export async function requestAnchorUserRegister(data: AnchorAdminOperatorRegisterRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_AC.USERS.USER,
    data,
  });

  return formattingToUserVOModel(response);
}

// 사용자 수정
export async function requestAnchorUserEdit(
  userId: number,
  data: AnchorAdminOperatorUpdateRequest,
): Promise<UserVOModel> {
  const response = await http.put<UserVO>({
    url: API_AC.USERS.USER_DETAIL(userId),
    data,
  });

  return formattingToUserVOModel(response);
}

// admin operator 활성 상태 수정
export async function requestAnchorUserSuspendAdminOperator(userId: number, suspended: boolean): Promise<UserVOModel> {
  const response = await http.put<UserVO>({
    url: API_AC.USERS.SUSPEND_ADMIN_OPERATOR(userId),
    data: { suspended },
  });

  return formattingToUserVOModel(response);
}

// Admin Operator 회원가입 초대메일 발송
export async function requestAnchorUserInviteAdminOperator(userId: number) {
  const response = await http.post({
    url: API_AC.USERS.INVITE_ADMIN_OPERATOR(userId),
  });

  return response;
}

// 사용자 관련 sc partner 약정 조회
export async function requestAnchorUserDealerAgreementList(
  userId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_AC.USERS.DEALER_AGREEMENT_LIST_RELATED_USER(userId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const dealerAgreementList: DealerAgreementVOModel[] = response.content.map((data: DealerAgreementVO) =>
    formattingToDealerAgreementVOModel(data),
  );
  const dealerAgreementPage: Pageable<DealerAgreementVOModel[]> = { ...response, content: dealerAgreementList };

  return dealerAgreementPage;
}

// 사용자 관련 anchor 약정 조회
export async function requestAnchorUserAnchorAgreementList(
  userId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_AC.USERS.ANCHOR_AGREEMENT_LIST_RELATED_USER(userId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const anchorAgreementList: AnchorAgreementVOModel[] = response.content.map((data: AnchorAgreementVO) =>
    formattingToAnchorAgreementVOModel(data),
  );
  const anchorAgreementPage: Pageable<AnchorAgreementVOModel[]> = { ...response, content: anchorAgreementList };

  return anchorAgreementPage;
}

// admin에 지정된 앵커약정중 admin operator 가 지정되지않은 약정 조회
export async function requestAnchorUserNotRelatedAnchorAgreementList(
  userId: number,
  searchValue: AnchorUserNotRelatedAnchorAgreementListRequest,
): Promise<AnchorAgreementVOModel[]> {
  const response = await http.get<AnchorAgreementVO[]>({
    url: API_AC.USERS.ANCHOR_AGREEMENT_LIST_NOT_RELATED_USER(userId),
    data: searchValue,
  });
  const notAnchorAgreementList: AnchorAgreementVOModel[] = response.map((data: AnchorAgreementVO) =>
    formattingToAnchorAgreementVOModel(data),
  );

  return notAnchorAgreementList;
}

// admin operator anchor 약정 할당
export async function requestAnchorUserAssignAnchorAgreement(userId: number, anchorAgreementIds: number[]) {
  const response = await http.post({
    url: API_AC.USERS.ASSIGN_ANCHOR_AGREEMENT(userId),
    data: { anchorAgreementIds },
  });

  return response;
}

// admin operator anchor 약정 할당 해제
export async function requestAnchorUserReleaseAnchorAgreement(userId: number, anchorAgreementIds: number[]) {
  const response = await http.post({
    url: API_AC.USERS.RELEASE_ANCHOR_AGREEMENT(userId),
    data: { anchorAgreementIds },
  });

  return response;
}

// 각 은행에서 등록한 로그인 유저(한명)에 관한 정보
export async function requestRelatedAnchorUsers(): Promise<AnchorUserVOModel[]> {
  const response = await http.get<AnchorUserVO[]>({
    url: API_AC.USERS.USER_INFO_REGISTERED_BY_FINANCIER,
  });

  return response.map(data => formattingToAnchorUserVOModel(data));
}

export async function requestAnchorAdminOperatorInviteUrl(userId: number): Promise<string | null> {
  const response = await http.get<string | null>({
    url: API_AC.USERS.ADMIN_OPERATOR_INVITE_URL(userId),
  });

  return response;
}

// TODO: 사용자 정보 수정 히스토리 조회
