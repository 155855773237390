import type { ReactNode } from 'react';

import { clsx } from 'clsx';

type BorderPropsType = {
  isEditable?: boolean;
  className?: string;
  children: ReactNode;
};

const Border = ({ isEditable = false, className = '', children }: BorderPropsType) => {
  const classes = clsx('section__border', {
    'section__border--editable': isEditable,
    [className]: className,
  });

  return <div className={classes}>{children}</div>;
};

export default Border;
