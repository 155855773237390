import type React from 'react';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CitadCodeModal from 'components/stateless/Modal/common/CitadCodeModal';
import { DISBURSEMENT_ACCOUNT_TYPE, DOCUMENT_FORM_TYPE } from 'enums';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { TempLoanVOModel } from 'models/vo/TempLoanVO';
import type { TempPurposeOfLoanVOModel } from 'models/vo/TempPurposeOfLoanVO';
import type { BigNumber } from 'utils/bigNumber';
import { getSum } from 'utils/calculate';
import { requestDealerFinancierDocumentFormsAttachmentDownload } from 'utils/http/api/dealer/financier-document-forms';
import type { DealerSuccessArLoanRequest, purposeOfLoanListType } from 'utils/http/api/dealer/success-ars/requests';
import {
  requestDealerTempAccountAttachmentDownload,
  requestDealerTempPurposeAttachmentDownload,
} from 'utils/http/api/dealer/temp-purpose-of-loans';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import type { PurposeFileRowTypes } from '../../useDealerArFinancingApplicationStep2State';

const INITIAL_ROW_COUNT = 1;

interface usePurposeOfFinancingProps {
  financierId: number;
  dealerTempLoanByAr: TempLoanVOModel;
  purposeOfLoanList: purposeOfLoanListType[];
  purposeFileRows: PurposeFileRowTypes[];
  setPurposeFileRows: React.Dispatch<React.SetStateAction<PurposeFileRowTypes[]>>;
  purposeRowId: React.MutableRefObject<number>;
}

export function usePurposeOfFinancing({
  financierId,
  dealerTempLoanByAr,
  purposeOfLoanList,
  purposeFileRows,
  setPurposeFileRows,
  purposeRowId,
}: usePurposeOfFinancingProps) {
  const modal = useModal();
  const { t } = useTranslation();
  const { setValue, getValues, reset, clearErrors } = useFormContext<DealerSuccessArLoanRequest>();

  const [accountTypePartnerChecked, setAccountTypePartnerChecked] = useState<number[]>([]);
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [totalArAmount, setTotalArAmount] = useState<BigNumber>('0');

  const onClickBulkTransferDownloadForm = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerFinancierDocumentFormsAttachmentDownload(financierId, DOCUMENT_FORM_TYPE.BULK_TRANSFER);
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickRemittanceDownloadForm = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerFinancierDocumentFormsAttachmentDownload(financierId, DOCUMENT_FORM_TYPE.REMITTANCE);
    } catch (e) {
      modal.show(e);
    }
  };

  const updateTotalArAmount = useCallback(() => {
    const sum = getSum(purposeOfLoanList, 'purposeAmount');
    setTotalArAmount(sum);
  }, [purposeOfLoanList]);

  const purposeAddRow = () => {
    purposeRowId.current === 0 ? (purposeRowId.current = 1) : purposeRowId.current;
    setPurposeFileRows([
      ...purposeFileRows,
      {
        index: purposeRowId.current++,
        accountFileName: undefined,
        purposeFileName: undefined,
        accountFileChanged: false,
        purposeFileChanged: false,
        bankName: undefined,
        bankCode: undefined,
      },
    ]);
  };

  const purposeRemoveRow = () => {
    setCheckedRows([]);

    if (purposeFileRows.length === checkedRows.length) {
      purposeRowId.current = INITIAL_ROW_COUNT;
      setPurposeFileRows([
        {
          index: 0,
          accountFileName: undefined,
          purposeFileName: undefined,
          accountFileChanged: false,
          purposeFileChanged: false,
          bankName: undefined,
          bankCode: undefined,
        },
      ]);
      setAccountTypePartnerChecked([]);
      // reset
      setValue(`purposeOfLoanList[0].tempPurposeOfLoanId`, undefined);
      setValue(`purposeOfLoanList[0].bankCodeId`, undefined);
      reset(
        {
          ...getValues(),
          purposeOfLoanList: [],
        },
        {
          dirtyFields: true,
        },
      );

      return;
    } else {
      const temp = [...purposeFileRows];
      const newState = temp.filter((i, j) => !checkedRows.some(item => item === j));
      const newStateIndex = newState.map(({ index }) => index);

      setPurposeFileRows(newState);
      setAccountTypePartnerChecked(prev => prev?.filter(index => newStateIndex.includes(index)));
    }

    updateTotalArAmount();
  };

  const setAccountFileName = (fileName: any, index: number) => {
    setPurposeFileRows(prevCheck =>
      prevCheck?.map(items => {
        return items.index === index ? { ...items, accountFileName: fileName, accountFileChanged: true } : items;
      }),
    );
  };

  const setPurposeFileName = (fileName: any, index: number) => {
    setPurposeFileRows(prevCheck =>
      prevCheck?.map(items => {
        return items.index === index ? { ...items, purposeFileName: fileName, purposeFileChanged: true } : items;
      }),
    );
  };

  const setSearchBankInfo = useCallback(
    (rowIndex: number, bankName: string, bankCode: string) => {
      setPurposeFileRows(prevCheck =>
        prevCheck?.map(items => {
          return items.index === rowIndex ? { ...items, bankName: bankName, bankCode: bankCode } : items;
        }),
      );
    },
    [setPurposeFileRows],
  );

  const disabledBankInfoFormWhenTempPaymentAccountTypeDesignated = useCallback(() => {
    const accountTypePartnerCheckedArr: number[] = [];

    dealerTempLoanByAr.tempPurposeOfLoanList?.forEach((item: TempPurposeOfLoanVOModel, index: number) => {
      if (item.disbursementAccountType === DISBURSEMENT_ACCOUNT_TYPE.DESIGNATED) {
        accountTypePartnerCheckedArr.push(index);
      }
    });

    setAccountTypePartnerChecked(accountTypePartnerCheckedArr);
  }, [dealerTempLoanByAr.tempPurposeOfLoanList]);

  // 지급 계좌 타입이 DESIGNATED 일 경우 form reset
  const tdClassAddDisabled = (e: any, index: number) => {
    const arrIndex = index;
    if (e.target.value === DISBURSEMENT_ACCOUNT_TYPE.DESIGNATED) {
      setAccountTypePartnerChecked([...accountTypePartnerChecked, index]);

      setValue(`purposeOfLoanList[${index}].bankCodeId`, undefined);
      setValue(`purposeOfLoanList[${index}].account`, undefined);
      setValue(`purposeOfLoanList[${index}].accountOwner`, undefined);
      setSearchBankInfo(index, '', '');
      clearErrors();
    } else if (e.target.value === DISBURSEMENT_ACCOUNT_TYPE.BENEFICIARY) {
      setAccountTypePartnerChecked(accountTypePartnerChecked.filter(item => item !== arrIndex));
    }
  };

  // 지급 계좌 타입이 DESIGNATED 일 경우 UI 비활성화 아닐 경우 활성화
  const ableOrDisableBankAccountInfoUi = useCallback(
    (index: number) => {
      return accountTypePartnerChecked.includes(index) ? 'bg-pattern' : '';
    },
    [accountTypePartnerChecked],
  );

  const searchCitadCode = (index: number) => {
    const setBankCodeData = (data: BankCodeVOModel): void => {
      setValue(`purposeOfLoanList[${index}].bankCodeId`, data.id);
      setSearchBankInfo(index, data.bankName, data.bankCode);
    };

    modal.show(<CitadCodeModal handleBankCodeData={setBankCodeData} modalId={modal.id} financierId={financierId} />, {
      title: t('text:Find_Bank_Code'),
      modalType: ModalType.ALERT,
      modalSize: ModalSize.L,
      closeBtnText: t('text:Close'),
    });
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const arr: number[] = [];
      purposeFileRows.forEach((el, index) => arr.push(index));
      setCheckedRows(arr);
    } else {
      setCheckedRows([]);
    }
  };

  const handleCheckChange = (e: any, index: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const onChangeAccountFileName = (e: any, index: number) => {
    const fileName = e.target?.files[0]?.name;
    if (fileName) setAccountFileName(fileName, index);
  };

  const onChangePurposeFileName = (e: any, index: number) => {
    const fileName = e.target?.files[0]?.name;
    if (fileName) setPurposeFileName(fileName, index);
  };

  const resetAccountFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault();
    setValue(`accountAttachments[${index}]`, null);
    setAccountFileName(undefined, index);
  };

  const resetPurposeFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault();
    setValue(`purposeAttachments[${index}]`, null);
    setPurposeFileName(undefined, index);
  };

  const onClickAccountFileDownLoad = async (index: number) => {
    try {
      const tempPurposeOfLoanId = getValues().purposeOfLoanList[index]?.tempPurposeOfLoanId;
      await requestDealerTempAccountAttachmentDownload(tempPurposeOfLoanId as number);
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickPurposeFileDownLoad = async (index: number) => {
    try {
      const tempPurposeOfLoanId = getValues().purposeOfLoanList[index]?.tempPurposeOfLoanId;
      await requestDealerTempPurposeAttachmentDownload(tempPurposeOfLoanId as number);
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    purposeRowId,
    totalArAmount,
    checkedRows,
    onClickBulkTransferDownloadForm,
    onClickRemittanceDownloadForm,
    purposeAddRow,
    purposeRemoveRow,
    handleCheckAll,
    handleCheckChange,
    searchCitadCode,
    setAccountFileName,
    setPurposeFileName,
    disabledBankInfoFormWhenTempPaymentAccountTypeDesignated,
    tdClassAddDisabled,
    ableOrDisableBankAccountInfoUi,
    updateTotalArAmount,
    onChangeAccountFileName,
    onChangePurposeFileName,
    resetAccountFile,
    resetPurposeFile,
    onClickAccountFileDownLoad,
    onClickPurposeFileDownLoad,
  };
}
