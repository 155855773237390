import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

// 용처 :: 용처증빙 파일 다운로드
export async function requestFinancierPurposeAttachmentDownload(purposeOfLoanId: number) {
  await http.download(API_FI.PURPOSE_OF_LOANS.DOWNLOAD_PURPOSE_ATTACHMENT(purposeOfLoanId));
}

// 용처 :: 지급계좌 파일 다운로드
export async function requestFinancierAccountAttachmentDownload(purposeOfLoanId: number) {
  await http.download(API_FI.PURPOSE_OF_LOANS.DOWNLOAD_ACCOUNT_ATTACHMENT(purposeOfLoanId));
}
