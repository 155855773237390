import './PPCard.scss';

export enum PPCardBackGroundType {
  'WHITE' = 'bg-white',
  'Yellow95' = 'bg-yellow-95',
  'WarmGray20' = 'bg-warm-gray-20',
  null = '',
}

interface PPCardProps {
  children: JSX.Element | (JSX.Element | null | undefined)[] | string | null | undefined;
  backGroundType?: PPCardBackGroundType;
}

function PPCard({ children, backGroundType = PPCardBackGroundType.WHITE }: PPCardProps) {
  return <div className={`pp-card ${backGroundType}`}>{children}</div>;
}

export default PPCard;
