import type React from 'react';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { debounce, isNil } from 'lodash-es';

import Button from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import { FormSubtitle, NumericFormatInput } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import Value from 'components/stateless/Form/Value/Value';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { TableBody, TableBorder, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import type { AnchorMultipleLoanCalculateSuccessInvoicesRequest } from 'utils/http/api/anchor/multiple-request-anchor-loans/requests';
import type { AnchorMultipleLoanSuccessInvoicesRequest } from 'utils/http/api/anchor/multiple-request-anchor-loans/requests';
import useModal from 'utils/modal/useModal';
import useValidation from 'utils/validation/useValidation';

import { useFinancingRequest } from './logics';

import type { AnchorInvoiceBulkFinancingApplicationStep2State } from '../../useAnchorInvoiceBulkFinancingApplicationStep2State';

interface FinancingRequestProps {
  anchorInvoiceBulkApplicationStep2State: AnchorInvoiceBulkFinancingApplicationStep2State;
  fetchAnchorInvoiceBulkCalculateLoanRequest: (
    data: AnchorMultipleLoanCalculateSuccessInvoicesRequest,
  ) => Promise<void>;
  totalRequestedAmount?: BigNumber;
  calculateRequestedAmount: (checkedArList: number[]) => void;
  invoiceListTableRef: React.RefObject<HTMLDivElement>;
  disbursementDateRef: React.RefObject<HTMLDivElement>;
  handleCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  getCheckedLoanByInvoiceRequestList: () => {
    index: number;
    successInvoiceId: number;
    requestedLoanAmount: string;
  }[];
  focusErrorElement: () => void;
  estimateButtonRef: React.RefObject<HTMLDivElement>;
  selectedTotalInvoiceAmountState: {
    selectedTotalInvoiceAmount: BigNumber | undefined;
    setSelectedTotalInvoiceAmount: React.Dispatch<React.SetStateAction<BigNumber | undefined>>;
  };
  showedTotalRequestedAmountState: {
    showedTotalRequestedAmount: BigNumber | undefined;
    setShowedTotalRequestedAmount: React.Dispatch<React.SetStateAction<BigNumber | undefined>>;
  };
  initializeFinancingConditionInfo: () => void;
}

function FinancingRequest({
  anchorInvoiceBulkApplicationStep2State,
  totalRequestedAmount = '0',
  calculateRequestedAmount,
  fetchAnchorInvoiceBulkCalculateLoanRequest,
  disbursementDateRef,
  invoiceListTableRef,
  handleCheckAll,
  handleCheckChange,
  getCheckedLoanByInvoiceRequestList,
  focusErrorElement,
  estimateButtonRef,
  selectedTotalInvoiceAmountState,
  showedTotalRequestedAmountState,
  initializeFinancingConditionInfo,
}: FinancingRequestProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { control, errors, register, setError, clearErrors, watch } =
    useFormContext<AnchorMultipleLoanSuccessInvoicesRequest>();
  const { disbursementDate } = watch();

  const { getValidationClassName } = useValidation({
    errorFields: errors.requestedInvoices,
  });

  const {
    anchorFinancingOptionDetail,
    calculateLoanRequest,
    minDate,
    maxDate,
    checkedInvoiceList,
    successInvoiceList,
    totalSuccessInvoiceListCount,
  } = anchorInvoiceBulkApplicationStep2State;

  const { selectedTotalInvoiceAmount, setSelectedTotalInvoiceAmount } = selectedTotalInvoiceAmountState;
  const { showedTotalRequestedAmount, setShowedTotalRequestedAmount } = showedTotalRequestedAmountState;

  const {
    disabledRanges,
    disabledEstimateButton,
    returnAmountWithCurrency,
    onChangeApplicationFileName,
    resetApplicationFileName,
    applicationFileName,
    agreementFileName,
    onChangeAgreementFileName,
    resetAgreementFileName,
  } = useFinancingRequest({
    anchorInvoiceBulkApplicationStep2State,
    disbursementDate,
    totalRequestedAmount,
  });

  const handleClickEstimateButton = async () => {
    clearErrors();
    const checkedLoanByInvoiceRequestList = getCheckedLoanByInvoiceRequestList();
    let invoiceLimitExceededErrorCount = 0;

    checkedLoanByInvoiceRequestList.forEach(checkedInvoiceInfo => {
      if (
        Number(checkedInvoiceInfo.requestedLoanAmount) >
        Number(successInvoiceList[checkedInvoiceInfo.index].invoiceAmount)
      ) {
        setError(`requestedInvoices.${checkedInvoiceInfo.index}.requestedLoanAmount`, { type: 'max' });
        invoiceLimitExceededErrorCount++;
      }
    });

    if (invoiceLimitExceededErrorCount) {
      modal.show(<h6>{t('text:The_financing_amount_exceeds_the_Available_Request')}</h6>, {
        closeBtnCb: () => focusErrorElement(),
      });

      return false;
    }

    try {
      await fetchAnchorInvoiceBulkCalculateLoanRequest({
        anchorAgreementId: anchorFinancingOptionDetail.anchorAgreementId,
        requestedInvoices: checkedLoanByInvoiceRequestList.map(item => ({
          successInvoiceId: item.successInvoiceId,
          requestedLoanAmount: item.requestedLoanAmount,
        })),
        disbursementDate,
      });

      const checkedInvoiceInfoList = successInvoiceList?.filter((_: any, rowIndex: number) =>
        checkedInvoiceList.includes(rowIndex),
      );
      const calculatorBigNumber = new CalculatorBigNumber();
      let invoiceAmountSum: BigNumber = '0';

      if (checkedInvoiceInfoList?.length !== 0) {
        checkedInvoiceInfoList.forEach(invoiceInfo => {
          invoiceAmountSum = calculatorBigNumber.add(invoiceAmountSum).add(invoiceInfo.invoiceAmount).get();
        });
      }

      setSelectedTotalInvoiceAmount(invoiceAmountSum);
      setShowedTotalRequestedAmount(totalRequestedAmount);
    } catch (e) {
      modal.show(e);
    }
  };

  const debouncedCalculateRequestedAmount = useCallback(
    debounce(checkedInvoiceList => {
      calculateRequestedAmount(checkedInvoiceList);
    }, 300),
    [],
  );

  const renderInvoiceTable = (() => {
    const tableHeaders: HeaderType[] = [
      {
        hasCheckBox: true,
        handleClickAllCheckBox: handleCheckAll,
        isAllChecked: successInvoiceList?.length !== 0 && checkedInvoiceList.length === successInvoiceList?.length,
        colWidths: 70,
      },
      {
        headerText: t('text:Invoice_Number'),
      },
      {
        headerText: t('text:Partner_Name'),
      },
      {
        headerText: t('text:Partner_Tax_Code'),
      },
      {
        headerText: t('text:Invoice_Amount'),
      },
      {
        headerText: t('text:Issued_Date'),
      },
      {
        headerText: t('text:Payment_Date'),
      },
      {
        headerText: t('text:Expected_Disbursement_Date_of_Partner'),
      },
      {
        headerText: t('text:Financed_Invoice_Amount_by_Partner'),
      },
      {
        headerText: t('text:Financing_Available_Amount'),
      },
      {
        headerText: t('text:Requested_Financing_Amount'),
      },
    ];

    const renderResultTable = () => {
      return successInvoiceList?.map((item, index) => {
        return (
          <Tr key={index}>
            <td>
              <div className="text-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  onChange={e => handleCheckChange(e, index)}
                  checked={checkedInvoiceList.includes(index)}
                />
              </div>
            </td>
            <Td data={item.invoiceNumber} />
            <Td data={item.dealerName} />
            <Td data={item.dealerTaxCode} />
            <Td data={item.invoiceAmount} format="number" />
            <Td data={item.invoiceIssuedDate} format="date" />
            <Td data={item.invoiceSettlementDate} format="date" />
            <Td data={item.desiredDisburseDateByPartner} format="date" />
            <Td data={item.collateralizedAmountByPartner} format="number" />
            <Td data={item.maxLtvAmount} format="number" />
            <td className={getValidationClassName('requestedLoanAmount', 'td', index)}>
              <input type="hidden" name={`requestedInvoices.${index}.successInvoiceId`} ref={register} />
              <NumericFormatInput
                control={control}
                name={`requestedInvoices.${index}.requestedLoanAmount`}
                numberType="bigNumber"
                className={clsx(getValidationClassName('requestedLoanAmount', 'input', index), {
                  'd-none': !checkedInvoiceList.includes(index),
                })}
                onChange={() => {
                  debouncedCalculateRequestedAmount(checkedInvoiceList);
                  initializeFinancingConditionInfo();
                }}
                currencyType={anchorFinancingOptionDetail?.currencyType}
                textAlign="text-end"
              />
              <Value
                value="-"
                className={clsx('grid-input', {
                  'd-none': checkedInvoiceList.includes(index),
                })}
              />
            </td>
          </Tr>
        );
      });
    };

    return (
      <TableBorder scrollYMaxHeight={570}>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
      </TableBorder>
    );
  })();

  const ExceedsInvoiceLimitMessage = () => {
    return (
      <GuideMessage
        className="side-border-none"
        message={[t('text:3000_Invoices_are_displayed_in_order_of_earliest_Settlement_Date')]}
        useContentAreaClassName={false}
      />
    );
  };

  const FinancingConditionSection = () => {
    return (
      <>
        <div className="detail-title bg-sub100">
          <div className="d-flex w-50 justify-content-between">
            <div className="me-auto">{t('text:Financing_Condition')}</div>
            <div className="align-self-center" ref={estimateButtonRef}>
              <Button
                onClick={handleClickEstimateButton}
                className="flex-column-center text-bold"
                disabled={disabledEstimateButton()}
              >
                {t('text:Estimate')}
              </Button>
            </div>
          </div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Requested_Financing_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(showedTotalRequestedAmount)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Selected_Invoice_Amount')}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(selectedTotalInvoiceAmount)}
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="information-form__label flex-label">{t('text:Expected_Disbursement_Ratio')} (%)</div>
              </div>
              <div className="col-3 ">
                <div className="information-form__input flex-input gray-bold-font">
                  {calculateLoanRequest?.maxLtvRatio ?? '-'} %
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Expected_Interest_Rate')} (%)</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {calculateLoanRequest?.totalInterestRate ?? '-'} %
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Expected_Interest_Amount')}</div>
            </div>
            <div className="col-3 ">
              <div className="information-form__input flex-input gray-bold-font">
                {returnAmountWithCurrency(calculateLoanRequest?.expectedTotalInterestAmount)}
              </div>
            </div>
          </div>
          {anchorFinancingOptionDetail.showNetDisbursementAmount && (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="border-bottom-light-gray my-3" />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="information-form__label flex-label">
                    {t('text:Advance_Disbursement')}
                    <QuestionTooltip
                      id="netDisburseAmountTooltip"
                      place="top"
                      contentText={
                        <>
                          <div>{t('text:Principal_Amount')} ⓐ</div>
                          <div>{t('text:Prepayment_Interest')} ⓑ</div>
                          <div>--------------------------------------------------------</div>
                          <div>{t('text:Advance_Disbursement')} ⓒ = ⓐ - ⓑ</div>
                        </>
                      }
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="information-form__input flex-input gray-bold-font">
                    {returnAmountWithCurrency(calculateLoanRequest?.totalNetDisbursementAmount)}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const FinancingAgreementDocumentForm = () => (
    <>
      <FormSubtitle backGroundType={BackGroundType.Gray} title={t('text:Financing_Agreement_Document')} />
      <div className="detail-in-file-upload-form">
        <div className="file-upload">
          <div className="d-flex justify-content-between">
            <input
              type="file"
              id="agreementAttachmentFile"
              className="d-none"
              name="agreementAttachment"
              ref={register}
              onChange={onChangeAgreementFileName}
            />
            <label htmlFor="agreementAttachmentFile" className="attach-file-link-button">
              {t('text:Attach_File')}
            </label>
            <div id="fileName" className="upload-file-input">
              {agreementFileName ? agreementFileName : t('text:No_file_attached')}
            </div>
            <IconButton
              onClick={resetAgreementFileName}
              className={`delete-uploaded-excel-button ${!agreementFileName && 'd-none'}`}
              type="button"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );

  const FinancingApplicationDocumentForm = () => (
    <>
      <FormSubtitle backGroundType={BackGroundType.Gray} title={t('text:Financing_Application_Document')} />
      <div className="detail-in-file-upload-form">
        <div className="file-upload">
          <div className="d-flex justify-content-between">
            <input
              type="file"
              id="requestAttachmentFile"
              className="d-none"
              name="requestAttachment"
              ref={register}
              onChange={onChangeApplicationFileName}
            />
            <label htmlFor="requestAttachmentFile" className="attach-file-link-button">
              {t('text:Attach_File')}
            </label>
            <div id="fileName" className="upload-file-input">
              {applicationFileName ? applicationFileName : t('text:No_file_attached')}
            </div>
            <IconButton
              onClick={resetApplicationFileName}
              className={`delete-uploaded-excel-button ${!applicationFileName && 'd-none'}`}
              type="button"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <SectionTitle title={t('text:Financing_Request')} />
      <GuideMessage
        isImportContentArea={true}
        message={[
          t('text:Please_select_disbursement_date_you_would_like_to_receive'),
          t(
            'text:Choose_the_Invoice_for_which_you_are_applying_for_financing_and_verify_that_the_information_is_correct',
          ),
          t(
            'text:Once_you_have_selected_the_Invoice_and_entered_the_Requested_Financing_Amount_press_the_estimate_button_to_check_the_estimated_financing_terms',
          ),
        ]}
      />
      <div className="information-form__detail-form border-bott-none mt-4">
        <div className="detail-title bg-sub100 border-bottom-2px-light-gray">
          <div className="d-flex justify-content-between w-50">
            <div ref={disbursementDateRef}>{t('text:Requested_Disbursement_Date')}</div>
            <div className="d-flex align-items-center">
              <Controller
                control={control}
                name="disbursementDate"
                defaultValue={null}
                render={field => (
                  <ReactDatePicker
                    field={field}
                    className="detail-title-input"
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={disabledRanges}
                    onChange={() => initializeFinancingConditionInfo()}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
        </div>

        <div className="detail-title bg-sub100 border-bottom-2px-light-gray">
          <div className="d-flex w-50 justify-content-between">
            <div>{t('text:Total_Requested_Financing_Amount')}</div>
            <div className="d-flex align-items-center">
              {t('format:number', { value: totalRequestedAmount })} {anchorFinancingOptionDetail?.currencyType}
            </div>
          </div>
          {!isNil(anchorFinancingOptionDetail?.maxAgreementLoanCapacityAmount) && (
            <div className="d-flex w-50 justify-content-between">
              <div className="d-flex gap-3">
                <span>≤</span>
                <div>{t('text:Anchor_Financing_Limit')}</div>
                <div>{returnAmountWithCurrency(anchorFinancingOptionDetail?.maxAgreementLoanCapacityAmount)}</div>
              </div>
            </div>
          )}
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="col-3 information-form__label">{t('text:Select_Invoice_for_Financing_Application')}</div>
          <div ref={invoiceListTableRef} className="mt-4">
            {renderInvoiceTable}
          </div>
        </div>
        {totalSuccessInvoiceListCount > 3000 && <ExceedsInvoiceLimitMessage />}
        <FinancingConditionSection />
        {FinancingApplicationDocumentForm()}
        {FinancingAgreementDocumentForm()}
      </div>
    </>
  );
}

export default FinancingRequest;
