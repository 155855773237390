import type {
  ANCHOR_AGREEMENT_STATUS,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  CURRENCY_TYPE,
  DEALER_IDENTIFIER_TYPE,
  LOAN_OPTION,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface WaitingAnchorAgreementVO {
  waitingAnchorAgreementId: number;
  loanLimitAmount: string;
  settlementDateOverAllowable: boolean;
  referenceNumberDuplicationCheck: boolean;
  paymentSupport: boolean;
  contractNo: string;
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  dealerIdentifierType: DEALER_IDENTIFIER_TYPE;
  anchorAgreementId: number;
  settlementAccountFinancierCode: string;
  settlementAccountFinancierName: string;
  settlementAccountBranchCode: string;
  settlementAccountBranchName: string;
  settlementAccount: string;
  settlementAccountOwner: string;
  anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS;
  startDate: string;
  expiryDate: string;
  changeDate: string;
  loanOption: LOAN_OPTION;
  approvalType: COMMON_APPROVAL_TYPE;
  returnReason: string;
  branchId: number;
  branchName: string;
  branchCode: string;
  branchAddress: string;
  branchTelephone: string;
  branchFax: string;
  anchorClientId: number;
  anchorClientCode: string;
  anchorClientName: string;
  anchorClientTaxCode: string;
  financierEntId: number;
  financierName: string;
  updatedDateTime: string;
  operatorUserLoginId: string;
  operatorUserName: string;
  authorizerUserLoginId: string;
  authorizerUserName: string;
  billOfExchangeNoRequired: boolean;
  maxLtvRatio: number;
  anchorClientAutoSignUpForAnchorAdminOfVendorFinance: boolean;
}

export interface WaitingAnchorAgreementVOModel extends WaitingAnchorAgreementVO {
  loanLimitAmount: BigNumber;
}

export function formattingToWaitingAnchorAgreementVOModel(
  data: WaitingAnchorAgreementVO,
): WaitingAnchorAgreementVOModel {
  const { loanLimitAmount } = data;

  return {
    ...data,
    loanLimitAmount: sliceZeroDecimal(loanLimitAmount),
  };
}
