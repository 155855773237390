import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Big from 'big.js';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import QuestionButton from 'components/stateless/QuestionButton';
import TdLink from 'components/stateless/Table/TdLink';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import {
  AUTHORIZER_STATUS,
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  DEALER_AGREEMENT_STATUS,
  DEALER_AGREEMENT_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
  LOAN_STATUS,
  LOAN_TERM_TYPE,
  USER_STATUS,
} from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { LoanVOModel } from 'models/vo/LoanVO';
import type { WaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import { CalculatorBigNumber } from 'utils/bigNumber';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestDealerAgreementDetail } from 'utils/http/api/dealer/dealer-agreements';
import { requestDealerLoanList } from 'utils/http/api/dealer/loans';
import { requestWaitingDealerAgreementList } from 'utils/http/api/dealer/waiting-dealer-agreements';
import { getFinancingLimitValue, getRemainingApplicableLimitValue } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { isLoanDisbursed } from 'utils/status';
import { tableValueManage } from 'utils/valueManager/ValueManager';

function DealerAgreementDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const [dealerAgreementDetail, setDealerAgreementDetail] = useState<DealerAgreementDetailVOModel>(
    {} as DealerAgreementDetailVOModel,
  );
  const [loanList, setLoanList] = useState<Pageable<LoanVOModel[]>>();
  const [editHistoryList, setEditHistoryList] = useState<Pageable<WaitingDealerAgreementVOModel[]>>();
  const { dealerAgreementId } = useParams<any>();
  const loanPageable = usePageable();
  const editHistoryPageable = usePageable();
  const [isMaturityExtension, setMaturityExtension] = useState<boolean>(false);
  const [useTermSpread, setUseTermSpread] = useState<boolean>(false);

  const [locationState, errorHandlerOfLocationState] = useLocationState<{
    supportedCollateralType: COLLATERAL_TYPE;
  }>(['supportedCollateralType']);
  const { supportedCollateralType } = locationState;

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const isBasisInterestBankCodeHidden = dealerAgreementDetail?.basisInterestType !== BASIS_INTEREST_TYPE.CUSTOM;

  const calculatorBigNumber = new CalculatorBigNumber();

  const loanTermText = (loanTerm: LOAN_TERM_TYPE) => {
    switch (loanTerm) {
      case LOAN_TERM_TYPE.DAILY:
        return t('text:days');
      case LOAN_TERM_TYPE.MONTHLY:
        return t('text:month');
    }
  };

  const loanLimitTypeText = (loanLimitType: LOAN_LIMIT_CHECK_TYPE) => {
    switch (loanLimitType) {
      case LOAN_LIMIT_CHECK_TYPE.BOTH:
        return t('text:Both');
      case LOAN_LIMIT_CHECK_TYPE.INVOICE:
        return t('text:Invoice_Registration');
      case LOAN_LIMIT_CHECK_TYPE.LOAN:
        return t('text:Loan_Application');
      case LOAN_LIMIT_CHECK_TYPE.NONE:
        return t('text:None');
    }
  };

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (
    loanPageNumber: number = loanPageable.pageable.currentPage,
    loanRowCount: number = loanPageable.pageable.sizePerPage,
    historyPageNumber: number = editHistoryPageable.pageable.currentPage,
    historyRowCount: number = editHistoryPageable.pageable.sizePerPage,
  ) => {
    try {
      const [fetchedDealerAgreementDetail, fetchedLoanList, fetchedWaitingDealerAgreementList] = await Promise.all([
        requestDealerAgreementDetail(dealerAgreementId),
        requestDealerLoanList(loanPageNumber, loanRowCount, { dealerAgreementId }),
        requestWaitingDealerAgreementList(historyPageNumber, historyRowCount, { dealerAgreementId }),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementDetail(fetchedDealerAgreementDetail);
        setMaturityExtension(fetchedDealerAgreementDetail.maxExtensibleLoanCount > 0);
        setUseTermSpread(fetchedDealerAgreementDetail.termSpreadList.length > 0);
        setLoanList(fetchedLoanList);
        loanPageable.setPageable(fetchedLoanList);
        setEditHistoryList(fetchedWaitingDealerAgreementList);
        editHistoryPageable.setPageable(fetchedWaitingDealerAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const loanPaginate = async (pageNumber: number, rowCount: number) => {
    const fetchedLoanList = await requestDealerLoanList(pageNumber, rowCount, { dealerAgreementId });
    ReactDOM.unstable_batchedUpdates(() => {
      setLoanList(fetchedLoanList);
      loanPageable.setPageable(fetchedLoanList);
    });
  };

  const editHistoryPaginate = async (pageNumber: number, rowCount: number) => {
    const fetchedWaitingDealerAgreementList = await requestWaitingDealerAgreementList(pageNumber, rowCount, {
      dealerAgreementId,
    });
    ReactDOM.unstable_batchedUpdates(() => {
      setEditHistoryList(fetchedWaitingDealerAgreementList);
      editHistoryPageable.setPageable(fetchedWaitingDealerAgreementList);
    });
  };

  const onClickAuthorizerStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(AUTHORIZER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="AUTHORIZER_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickCommonApprovalType = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  // render 함수
  const renderTermSpread = () => {
    const renderTermSpreadRow = () => {
      const termSpreadList = dealerAgreementDetail?.termSpreadList;

      return termSpreadList?.map((item, index, array) => {
        return (
          <tr key={`termspreadrow-${index}`}>
            <td>
              <div className="scope-box">
                <div className="text-start">
                  <div className="spread-xs-input">
                    {t('format:number', {
                      value: item.minimumDateRange,
                    })}
                  </div>
                </div>
                <div className="angle-sign">&lt;</div>
                <div>
                  <strong>T</strong>
                </div>
                {(dealerAgreementDetail?.termSpreadList.length || 0) > index + 1 && (
                  <>
                    <div className="angle-sign">≤</div>
                    <div />
                    <div className="text-start">
                      <div className="spread-xs-input">
                        {t('format:number', {
                          value: array[index + 1] ? array[index + 1].minimumDateRange : '',
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </td>
            <td>
              <div className="spread-xs-input me-1">
                {t('format:number', {
                  value: item.termSpreadRate,
                })}
              </div>{' '}
              %
            </td>
          </tr>
        );
      });
    };

    return (
      <div className="row">
        <FormRadioWrap label={t('text:Term_Spread')} col={12}>
          <FormRadio label={t('text:Applied')} defaultChecked={useTermSpread} disabled />
          <FormRadio label={t('text:Not_Applied')} defaultChecked={!useTermSpread} disabled />
          {useTermSpread && (
            <table className="table-border spread-form text-center sub200-bg">
              <thead>
                <tr>
                  <th scope="col">{t('text:Term')}</th>
                  <th scope="col">{t('text:Term_Spread')} (%)</th>
                </tr>
              </thead>
              <tbody className="bg-white">{renderTermSpreadRow()}</tbody>
            </table>
          )}
        </FormRadioWrap>
      </div>
    );
  };

  const renderAnchorUserInformationTable = (): JSX.Element[] | JSX.Element | undefined => {
    return dealerAgreementDetail?.anchorUserList ? (
      dealerAgreementDetail.anchorUserList.map((anchorUser, index: number) => {
        return (
          <tr key={index}>
            <td>{tableValueManage(anchorUser.bankUserId)}</td>
            <td>{tableValueManage(anchorUser.name)}</td>
            <td>{tableValueManage(anchorUser.mobile)}</td>
            <td>{tableValueManage(anchorUser.email)}</td>
            <td>{tableValueManage(anchorUser.anchorUserOtpSerialNo)}</td>
            <td>{t(`code:authority-type.${anchorUser.authorityType}`)}</td>
            <td className={getStatusTextClass('USER_STATUS', anchorUser?.userStatus)}>
              {tableValueManage(anchorUser.userStatus, t(`code:user-status.${anchorUser.userStatus}`))}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={6} className="text-center">
          {t('text:No_data_available')}
        </td>
      </tr>
    );
  };

  const renderAuthorizerInformationTable = (): JSX.Element | undefined => {
    const renderAuthorizerStatus = (authorizerStatus: AUTHORIZER_STATUS): JSX.Element | undefined => {
      switch (authorizerStatus) {
        case AUTHORIZER_STATUS.ACTIVATED:
          return (
            <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.ACTIVATED)}>{t('text:Active')}</td>
          );
        case AUTHORIZER_STATUS.MAIL_SENT:
          return (
            <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.MAIL_SENT)}>
              {t('text:Invitation_Email_Sent')}
            </td>
          );
        case AUTHORIZER_STATUS.NONE:
          return <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.NONE)}>{t('text:Input')}</td>;
        default:
          return <td>{authorizerStatus}</td>;
      }
    };

    return (
      <table className="table-border">
        <thead>
          <tr>
            <th scope="col">{t('text:User_Code')}</th>
            <th scope="col">{t('text:Name')}</th>
            <th scope="col">{t('text:Mobile')}</th>
            <th scope="col">{t('text:Email')}</th>
            <th scope="col">{t('text:OTP_Number')}</th>
            <th scope="col">
              {t('text:User_Account_Status')}
              <QuestionButton onClick={onClickAuthorizerStatus} />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td>{tableValueManage(dealerAgreementDetail?.authorizerBankUserId)}</td>
            <td>{tableValueManage(dealerAgreementDetail?.authorizerName)}</td>
            <td>{tableValueManage(dealerAgreementDetail?.authorizerMobile)}</td>
            <td>{tableValueManage(dealerAgreementDetail?.authorizerEmail)}</td>
            <td>{tableValueManage(dealerAgreementDetail?.authorizerOtpSerialNo)}</td>
            {renderAuthorizerStatus(dealerAgreementDetail?.authorizerStatus)}
          </tr>
        </tbody>
      </table>
    );
  };

  const renderLoanTable = () => {
    return loanList?.content.length !== 0 ? (
      loanList?.content.map((value, index) => {
        return (
          <tr key={`loanList-${index}`}>
            <td>{tableValueManage(value.loanId)}</td>
            <td>{tableValueManage(value.financierLoanId)}</td>
            <td>{tableValueManage(value.invoiceNumber)}</td>
            <td>{tableValueManage(value.referenceNumber)}</td>
            <td>{tableValueManage(value.arNumber)}</td>
            <td>{tableValueManage(value.currencyType)}</td>
            <td className="text-end">
              {isLoanDisbursed(value.loanStatus)
                ? tableValueManage(value.disbursedAmount, t('format:number', { value: value.disbursedAmount }))
                : '-'}
            </td>
            <td>
              {tableValueManage(value.disbursedDate, t('format:date', { value: value.disbursedDate, key: 'date' }))}
            </td>
            <td>
              {tableValueManage(value.repaymentDate, t('format:date', { value: value.repaymentDate, key: 'date' }))}
            </td>
            <td className={getStatusTextClass('LOAN_STATUS', value.loanStatus)}>
              {t(`code:financing-status.${value.loanStatus}`)}
            </td>
            <TdLink
              path={ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(value.loanId)}
              state={{
                collateralType: value.collateralType,
                successArId: value.successArId,
                successInvoiceId: value.successInvoiceId,
                dealerAgreementId: value.dealerAgreementId,
                financierId: value.financierId,
              }}
            />
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="text-center" colSpan={10}>
          {t('text:no_data_available')}
        </td>
      </tr>
    );
  };

  const renderEditHistoryTable = () => {
    const getApprovalTypeText = (approvalType: COMMON_APPROVAL_TYPE): string => {
      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Request_Cancelled');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Registered');
        default:
          return approvalType;
      }
    };

    return editHistoryList?.content.length !== 0 ? (
      editHistoryList?.content.map((value, index) => {
        return (
          <tr key={`editHistory-${index}`}>
            <td>
              {tableValueManage(
                value.updatedDateTime,
                t('format:datetime', { value: value.updatedDateTime, key: 'datetime' }),
              )}
            </td>
            <td>{tableValueManage(value.contractNo)}</td>
            <td>{tableValueManage(value.expiryDate, t('format:date', { value: value.expiryDate, key: 'date' }))}</td>
            <td>{tableValueManage(value.branchCode)}</td>
            <td>{tableValueManage(value.authorizerName)}</td>
            <td>{tableValueManage(value.authorizerMobile)}</td>
            <td>{tableValueManage(value.authorizerEmail)}</td>
            <td className={getStatusTextClass('COMMON_APPROVAL_TYPE', value.approvalType)}>
              {getApprovalTypeText(value.approvalType)}
            </td>

            <td>
              {tableValueManage(value.operatorUserLoginId)} / {tableValueManage(value.operatorUserName)}
            </td>
            <td>
              {tableValueManage(value.authorizerUserLoginId)} / {tableValueManage(value.authorizerUserName)}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="text-center" colSpan={10}>
          {t('text:no_data_available')}
        </td>
      </tr>
    );
  };

  const renderAgreementType = (collateralType: COLLATERAL_TYPE): string => {
    switch (collateralType) {
      case COLLATERAL_TYPE.AR:
        return t('text:Vendor_Finance');
      case COLLATERAL_TYPE.INVOICE:
        return t('text:Dealer_Finance');
    }
  };

  const { loanLimitCheckType, loanLimitAmount, partiallyRepaidPrincipalAmount, requestedLoanAmount, currencyType } =
    dealerAgreementDetail;

  const financingLimitValue =
    loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE && loanLimitAmount === '0' ? ' ' : loanLimitAmount;

  return (
    <>
      <BackHeaderTitle title={t('text:Partner_Master_Agreement_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Agreement_Information')} />
        <FormBorder>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:PARTNER_INFORMATION')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Client_Code')}
                value={dealerAgreementDetail?.dealerFinancierClientCode}
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Partner_Name')}
                value={dealerAgreementDetail?.dealerFinancierClientName}
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Tax_Code')}
                value={dealerAgreementDetail?.dealerFinancierClientTaxCode}
              />
            </div>
          </FormContents>
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT_INFORMATION')}
          />
          <FormContents>
            <div className="row">
              <FormRadioWrap label={t('text:Is_there_an_associated_Anchor_Master_Agreement?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={dealerAgreementDetail?.dealerAgreementType === DEALER_AGREEMENT_TYPE.WITH_ANCHOR}
                  disabled
                />
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={dealerAgreementDetail?.dealerAgreementType === DEALER_AGREEMENT_TYPE.WITHOUT_ANCHOR}
                  disabled
                />
              </FormRadioWrap>
            </div>
            {dealerAgreementDetail?.dealerAgreementType === DEALER_AGREEMENT_TYPE.WITH_ANCHOR && (
              <div className="row">
                <FormValue
                  className="information-form__input"
                  label={t('text:Anchor_Master_Agreement_Number')}
                  value={dealerAgreementDetail?.anchorAgreementContractNo}
                />
                <FormValue
                  className="information-form__input"
                  label={t('text:Anchor_Name')}
                  value={dealerAgreementDetail?.anchorFinancierClientName}
                />
              </div>
            )}
          </FormContents>
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:PARTNER_MASTER_AGREEMENT_INFORMATION')}
          />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Partner_Master_Agreement_Number')}
                value={dealerAgreementDetail?.contractNo}
              />
              {isInvoice && (
                <FormValue
                  className="information-form__input"
                  label={t('text:Partner_Code_of_Anchor')}
                  value={dealerAgreementDetail?.dealerCodeByAnchor}
                />
              )}
            </div>
            <div className="row">
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Currency')}
                value={dealerAgreementDetail?.currencyType}
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Program_Type')}
                value={renderAgreementType(dealerAgreementDetail.collateralType)}
                format="code"
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Effective_Date')}
                value={dealerAgreementDetail?.startDate}
                format="date"
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Expiration_Date')}
                value={dealerAgreementDetail?.expiryDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Drawing_Power')}
                value={dealerAgreementDetail?.drawingPower}
                format="number"
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Financing_Limit_Checkpoint')}
                value={loanLimitTypeText(dealerAgreementDetail?.loanLimitCheckType)}
              />
              <FormValue
                col={3}
                className="information-form__input"
                label={t('text:Financing_Limit')}
                value={financingLimitValue}
                format="number"
              />
            </div>
            <div className="row">
              <FormValue
                col={isBasisInterestBankCodeHidden ? 6 : 3}
                className="information-form__input"
                label={t('text:Base_Interest_Type')}
                value={dealerAgreementDetail?.basisInterestType}
              />
              <FormValue
                col={3}
                className="information-form__input mt-3"
                label=""
                hidden={isBasisInterestBankCodeHidden}
                value={dealerAgreementDetail?.basisInterestBankCode}
              />
              {dealerAgreementDetail?.basisInterestType === BASIS_INTEREST_TYPE.FIXED ? (
                <FormValue
                  className="information-form__input"
                  label={t('text:Base_Interest_Rate')}
                  value={dealerAgreementDetail?.basisInterestRate}
                  format="number"
                />
              ) : (
                <FormValue
                  className="information-form__input"
                  label={t('text:Base_Interest_Term')}
                  value={dealerAgreementDetail?.basisInterestTerm}
                />
              )}
            </div>
            <div className="row">
              <FormValue
                col={6}
                className="information-form__input"
                label={t('text:Credit_Spread_(%)')}
                value={dealerAgreementDetail?.creditInterestRate}
                format="number"
              />
              <FormValue
                col={6}
                className="information-form__input"
                label={t('text:Interest_Rate_Adjustment_(%)')}
                value={dealerAgreementDetail?.preferentialInterestRate}
                format="number"
              />
            </div>
            {renderTermSpread()}
            <div className="row">
              <FormRadioWrap label={t('text:Select_Interest_Payment_Method')}>
                <FormRadio
                  label={t('text:Prepayment')}
                  defaultChecked={dealerAgreementDetail?.interestRepaymentType === INTEREST_REPAYMENT_TYPE.EARLIER}
                  disabled
                >
                  {dealerAgreementDetail?.interestRepaymentType === INTEREST_REPAYMENT_TYPE.EARLIER &&
                    (dealerAgreementDetail?.showNetDisbursementAmount
                      ? `( ${t('text:Interest_is_deducted_upon_disbursement')} )`
                      : `( ${t('text:Interest_is_charged_after_disbursement')} )`)}
                </FormRadio>
                <FormRadio
                  label={t('text:Postpayment')}
                  defaultChecked={dealerAgreementDetail?.interestRepaymentType === INTEREST_REPAYMENT_TYPE.LATTER}
                  disabled
                />
                <FormRadio
                  label={t('text:Monthly')}
                  defaultChecked={dealerAgreementDetail?.interestRepaymentType === INTEREST_REPAYMENT_TYPE.MONTHLY}
                  disabled
                >
                  {dealerAgreementDetail?.interestRepaymentType === INTEREST_REPAYMENT_TYPE.MONTHLY &&
                    `( ${t('text:Date')} : ${dealerAgreementDetail?.monthlyInterestRepaymentDate} )`}
                </FormRadio>
              </FormRadioWrap>
              <FormRadioWrap label={t('text:Allow_the_partner_to_specify_the_financing_duration?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE}
                  disabled
                >
                  {dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE &&
                    `( ${t('text:Minimum')} : ${t('format:number', {
                      value: dealerAgreementDetail?.minimumLoanRange,
                    })} ${t('text:Days')} / ${t('text:Maximum')} : ${t('format:number', {
                      value: dealerAgreementDetail?.maximumLoanRange,
                    })} ${t('text:Days')} )`}
                </FormRadio>
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={dealerAgreementDetail?.loanTermType !== LOAN_TERM_TYPE.RANGE}
                  disabled
                >
                  {dealerAgreementDetail?.loanTermType !== LOAN_TERM_TYPE.RANGE &&
                    `( ${t('text:Fixed_Duration')} : ${t('format:number', {
                      value: dealerAgreementDetail?.loanTermUnit,
                    })} ${loanTermText(dealerAgreementDetail?.loanTermType)} )`}
                </FormRadio>
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_Maturity_Extension?')}>
                <FormRadio label={t('text:Yes')} defaultChecked={isMaturityExtension} disabled>
                  {isMaturityExtension &&
                    `( ${t('format:number', {
                      value: dealerAgreementDetail?.maxExtensibleLoanCount,
                    })} ${t('text:Time(s)')} )`}
                </FormRadio>
                <FormRadio label={t('text:No')} defaultChecked={!isMaturityExtension} disabled />
              </FormRadioWrap>
              <FormRadioWrap label={t('text:Allow_Early_Repayment?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={dealerAgreementDetail?.earlyRepaymentAllowable}
                  disabled
                />
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={!dealerAgreementDetail?.earlyRepaymentAllowable}
                  disabled
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_the_partner_to_initiate_Invoice_or_AR_registration?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={dealerAgreementDetail?.dealerUploadInvoiceAllowable}
                  disabled
                />
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={!dealerAgreementDetail?.dealerUploadInvoiceAllowable}
                  disabled
                />
              </FormRadioWrap>
              <FormRadioWrap
                label={t('text:Allow_the_financing_repayment_date_to_exceed_the_agreement_expiration_date?')}
              >
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={dealerAgreementDetail?.repaymentDateOverAllowable}
                  disabled
                />
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={!dealerAgreementDetail?.repaymentDateOverAllowable}
                  disabled
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_the_partner_to_see_the_expected_interest_rate?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={dealerAgreementDetail?.showExpectedTotalInterestRate}
                  disabled
                />
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={!dealerAgreementDetail?.showExpectedTotalInterestRate}
                  disabled
                />
              </FormRadioWrap>
              <FormRadioWrap label={t('text:Agreement_Suspension')}>
                <FormRadio
                  label={t('text:Not_Suspended')}
                  defaultChecked={dealerAgreementDetail?.dealerAgreementStatus === DEALER_AGREEMENT_STATUS.ACTIVATED}
                  disabled
                />
                <FormRadio
                  label={t('text:Suspended')}
                  defaultChecked={dealerAgreementDetail?.dealerAgreementStatus === DEALER_AGREEMENT_STATUS.SUSPENDED}
                  disabled
                />
              </FormRadioWrap>
            </div>
          </FormContents>

          {isInvoice && (
            <>
              <FormSubtitle title={t('text:AD_HOC_LIMIT')} backGroundType={BackGroundType.DarkGray} />
              <FormContents>
                <div className="row">
                  <FormRadioWrap label={t('text:Apply_Ad_hoc_Limit?')}>
                    <FormRadio
                      label={t('text:Applied')}
                      defaultChecked={dealerAgreementDetail?.adhocLimitAllowable}
                      disabled
                    />
                    <FormRadio
                      label={t('text:Not_Applied')}
                      defaultChecked={!dealerAgreementDetail?.adhocLimitAllowable}
                      disabled
                    />
                  </FormRadioWrap>
                </div>
                <div className="row">
                  <FormValue
                    className="information-form__input"
                    col={6}
                    label={t('text:Ad_hoc_Limit_Amount')}
                    value={dealerAgreementDetail.adhocLimitAmount}
                    format="number"
                  />
                  <FormValue
                    className="information-form__input"
                    col={3}
                    label={t('text:Start_Date')}
                    value={dealerAgreementDetail.adhocLimitStartDate}
                  />
                  <FormValue
                    className="information-form__input"
                    col={3}
                    label={t('text:End_Date')}
                    value={dealerAgreementDetail.adhocLimitEndDate}
                  />
                </div>
              </FormContents>
            </>
          )}

          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:DISBURSEMENT_ACCOUNT')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Name')}
                value={dealerAgreementDetail?.settlementAccountFinancierName}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Name')}
                value={dealerAgreementDetail?.settlementAccountBranchName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Number')}
                value={dealerAgreementDetail?.settlementAccount}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Holder')}
                value={dealerAgreementDetail?.settlementAccountOwner}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:REPAYMENT_ACCOUNT')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Name')}
                value={dealerAgreementDetail?.principalRepaymentAccountFinancierName}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Name')}
                value={dealerAgreementDetail?.principalRepaymentAccountBranchName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Number')}
                value={dealerAgreementDetail?.principalRepaymentAccount}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Holder')}
                value={dealerAgreementDetail?.principalRepaymentAccountOwner}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:REPAYMENT_ACCOUNT_-_INTEREST')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Name')}
                value={dealerAgreementDetail?.interestRepaymentAccountFinancierName}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Name')}
                value={dealerAgreementDetail?.interestRepaymentAccountBranchName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Number')}
                value={dealerAgreementDetail?.interestRepaymentAccount}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Holder')}
                value={dealerAgreementDetail?.interestRepaymentAccountOwner}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:SECURED_DEPOSIT_ACCOUNT')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Name')}
                value={dealerAgreementDetail?.depositCollateralAccountFinancierName}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Name')}
                value={dealerAgreementDetail?.depositCollateralAccountBranchName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Number')}
                value={dealerAgreementDetail?.depositCollateralAccount}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Bank_Account_Holder')}
                value={dealerAgreementDetail?.depositCollateralAccountOwner}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:RESPONSIBLE_BRANCH')} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Code')}
                value={dealerAgreementDetail?.branchCode}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Branch_Name')}
                value={dealerAgreementDetail?.branchName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Telephone')}
                value={dealerAgreementDetail?.branchTelephone}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Fax')}
                value={dealerAgreementDetail?.branchFax}
              />
            </div>
            <div className="row">
              <FormValue
                col={12}
                className="information-form__input"
                label={t('text:Registered_Office_Address')}
                value={dealerAgreementDetail?.branchAddress}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:AUTHORIZER_INFORMATION')} />
          <FormContents>
            <div className="row">
              <div className="col-12">{renderAuthorizerInformationTable()}</div>
            </div>
          </FormContents>
          {dealerAgreementDetail?.dealerAgreementType === DEALER_AGREEMENT_TYPE.WITH_ANCHOR &&
            dealerAgreementDetail.collateralType === COLLATERAL_TYPE.INVOICE && (
              <>
                <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_USER_INFORMATION')} />
                <FormContents>
                  <div className="row">
                    <div className="col-12">
                      <table className="table-border">
                        <thead>
                          <tr>
                            <th scope="col">{t('text:User_Code')}</th>
                            <th scope="col">{t('text:Name')}</th>
                            <th scope="col">{t('text:Mobile')}</th>
                            <th scope="col">{t('text:Email')}</th>
                            <th scope="col">{t('text:OTP_Number')}</th>
                            <th scope="col">{t('text:Authority')}</th>
                            <th scope="col">
                              {t('text:User_Account_Status')}
                              <QuestionButton onClick={onClickUserStatus} />
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">{renderAnchorUserInformationTable()}</tbody>
                      </table>
                    </div>
                  </div>
                </FormContents>
              </>
            )}
        </FormBorder>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Platform_Usage_History')} />
        <div className="information-grid-form">
          {supportedCollateralType === COLLATERAL_TYPE.AR && dealerAgreementDetail.useTotalLimit && (
            <div className="row">
              <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit_of_Partner')}</div>
              <div className="grid-form__item__data__wrap col-4">
                <div className="grid-form__data">
                  {t('format:number', {
                    value: dealerAgreementDetail.totalLimitAmount,
                  })}{' '}
                  {dealerAgreementDetail.totalLimitCurrencyType}
                </div>
              </div>
              <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit_of_Partner')}</div>
              <div className="grid-form__item__data__wrap col-4">
                <div className="grid-form__data">
                  {t('format:number', {
                    value: Big(dealerAgreementDetail.originCurrencyTotalBalance).lt(0)
                      ? 0
                      : dealerAgreementDetail.originCurrencyTotalBalance,
                  })}{' '}
                  {dealerAgreementDetail.totalLimitCurrencyType}
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {supportedCollateralType === COLLATERAL_TYPE.AR
                  ? getFinancingLimitValue(loanLimitCheckType, loanLimitAmount, currencyType)
                  : getFinancingLimitValue(loanLimitCheckType, loanLimitAmount)}
              </div>
            </div>
            <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {supportedCollateralType === COLLATERAL_TYPE.AR
                  ? getRemainingApplicableLimitValue(
                      loanLimitCheckType,
                      {
                        loanLimitAmount,
                        partiallyRepaidPrincipalAmount,
                        requestedLoanAmount,
                      },
                      currencyType,
                    )
                  : getRemainingApplicableLimitValue(loanLimitCheckType, {
                      loanLimitAmount,
                      partiallyRepaidPrincipalAmount,
                      requestedLoanAmount,
                    })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Balance')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {t('format:number', {
                  value: calculatorBigNumber
                    .add(dealerAgreementDetail.disbursedLoanAmount)
                    .minus(dealerAgreementDetail.partiallyRepaidPrincipalAmount)
                    .get(),
                })}{' '}
                {supportedCollateralType === COLLATERAL_TYPE.AR && dealerAgreementDetail.currencyType}
              </div>
            </div>
            <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Count')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {t('format:number', { value: dealerAgreementDetail?.disbursedLoanCount })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="grid-form__item__label-wrap col-2">
              {supportedCollateralType === COLLATERAL_TYPE.INVOICE
                ? t('text:Pending_Invoice_Amount')
                : t('text:Pending_AR_Amount')}
            </div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {supportedCollateralType === COLLATERAL_TYPE.INVOICE
                  ? t('format:number', { value: dealerAgreementDetail.settlementWaitingInvoiceAmount })
                  : `${t('format:number', { value: dealerAgreementDetail.settlementWaitingInvoiceAmount })} ${
                      dealerAgreementDetail.currencyType
                    }`}
              </div>
            </div>
            <div className="grid-form__item__label-wrap col-2">
              {supportedCollateralType === COLLATERAL_TYPE.INVOICE
                ? t('text:Number_of_Pending_Invoices')
                : t('text:Number_of_Pending_AR')}
            </div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {t('format:number', { value: dealerAgreementDetail.settlementWaitingInvoiceCount })}
              </div>
            </div>
          </div>
          {supportedCollateralType === COLLATERAL_TYPE.INVOICE && (
            <div className="row">
              <div className="grid-form__item__label-wrap col-2">{t('text:Not_-_Proceeded_Invoice_Amount')}</div>
              <div className="grid-form__item__data__wrap col-4">
                <div className="grid-form__data">
                  {t('format:number', { value: dealerAgreementDetail.totalUnsettledInvoiceAmount })}
                </div>
              </div>
              <div className="grid-form__item__label-wrap col-2">{t('text:Number_of_Not_-_Proceeded_Invoices')}</div>
              <div className="grid-form__item__data__wrap col-4">
                <div className="grid-form__data">
                  {t('format:number', { value: dealerAgreementDetail.totalUnsettledInvoiceCount })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Financing_List')} />
        <p className="total-data">
          {t('text:Total')} {loanPageable.pageable.totalRows}
        </p>
        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '80px' }} />
              <col style={{ width: '50px' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t('text:Platform_Financing_ID')}</th>
                <th scope="col">{t('text:Financier_Financing_ID')}</th>
                <th scope="col">{t('text:Invoice_Number')}</th>
                <th scope="col">{t('text:Invoice_Reference_Number')}</th>
                <th scope="col">{t('text:AR_Number')}</th>
                <th scope="col">{t('text:Currency')}</th>
                <th scope="col">{t('text:Disbursement_Amount')}</th>
                <th scope="col">{t('text:Disbursed_Date')}</th>
                <th scope="col">{t('text:Scheduled_Repayment_Date')}</th>
                <th scope="col">
                  {t('text:Financing_Status')}
                  <QuestionButton onClick={onClickLoanStatus} />
                </th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody className="bg-white">{renderLoanTable()}</tbody>
          </table>
        </div>
        <Pagination pageable={loanPageable.pageable} paginate={loanPaginate} />
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '120px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '120px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '150px' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t('text:Edited_Time')}</th>
                <th scope="col">{t('text:Master_Agreement_Number_of_Partner')}</th>
                <th scope="col">{t('text:Expiration_Date')}</th>
                <th scope="col">{t('text:Branch_Code')}</th>
                <th scope="col">{t('text:Authorizer_Name')}</th>
                <th scope="col">{t('text:Authorizer_Mobile')}</th>
                <th scope="col">{t('text:Authorizer_Email')}</th>
                <th scope="col">
                  {t('text:Approval_Status')}
                  <QuestionButton onClick={onClickCommonApprovalType} />
                </th>
                <th scope="col">{t('text:Edit_requested_by_User_ID/Name')}</th>
                <th scope="col">{t('text:Edit_approved_by_User_ID/Name')}</th>
              </tr>
            </thead>
            <tbody className="bg-white">{renderEditHistoryTable()}</tbody>
          </table>
        </div>
        <Pagination pageable={editHistoryPageable.pageable} paginate={editHistoryPaginate} />
      </div>
    </>
  );
}

export default DealerAgreementDetail;
