import type { ClipboardEvent, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { Control, ControllerRenderProps, FieldErrors, UseControllerOptions } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { NumberFormatValues, NumericFormatProps } from 'react-number-format';
import { NumericFormat, numericFormatter, useNumericFormat } from 'react-number-format';

import { clsx } from 'clsx';
import { isNil, isUndefined } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import type { CURRENCY_TYPE } from 'enums';
import { languageType } from 'enums';
import { isNilOrEmptyString } from 'utils/helpers';
import { isDecimalCurrencyType } from 'utils/logic';
import { getObjectValue } from 'utils/object';
import { getLanguage } from 'utils/storage/LocalStorage';

import { FormErrorMessage } from '../ErrorMessage';
import Value from '../Value/Value';

import type { SizeType, TextAlignType } from '..';

type NumericType = 'integer' | 'bigNumber' | 'float';

export interface NumberInputProps extends Omit<NumericFormatProps, 'value' | 'onChange'> {
  name?: ControllerRenderProps['name'];
  value?: string | null;
  onChange?: (value: string) => void;
  numberType?: NumericType;
  fieldSize?: SizeType;
  textAlign?: TextAlignType;
  currencyType?: CURRENCY_TYPE;
  rules?: UseControllerOptions['rules'];
  isDisplayNone?: boolean;
  useIsAllowed?: boolean;
  useDecimalScale?: boolean;
  useUpToOneHundredPercent?: boolean;
  leftUnit?: ReactNode;
  rightUnit?: ReactNode;
  isEditable?: boolean;
  errors?: FieldErrors;
  showError?: boolean;
  control?: Control;
  currentValue?: string;
  fetchedReadOnly?: boolean;
  parentReadOnly?: boolean;
}

type NumericSeparator = Pick<NumericFormatProps, 'thousandSeparator' | 'decimalSeparator' | 'thousandsGroupStyle'>;

export const maxLength = {
  integer: 9,
  float: 11,
  bigNumber: 19,
};

export const separators: {
  [key in languageType]: NumericSeparator;
} = {
  [languageType.EN_USA]: { thousandSeparator: ',', decimalSeparator: '.', thousandsGroupStyle: 'thousand' },
  [languageType.VN]: { thousandSeparator: '.', decimalSeparator: ',', thousandsGroupStyle: 'thousand' },
  [languageType.EN_INDIA]: { thousandSeparator: ',', decimalSeparator: '.', thousandsGroupStyle: 'lakh' },
  [languageType.KO]: { thousandSeparator: ',', decimalSeparator: '.', thousandsGroupStyle: 'thousand' },
};

const NumberInput = ({
  name = '',
  value,
  defaultValue,
  rules,
  className = '',
  fieldSize,
  onChange,
  onPaste,
  currencyType,
  thousandSeparator = true,
  allowedDecimalSeparators = ['.', ','],
  allowNegative = false,
  disabled = false,
  numberType = 'float',
  useIsAllowed = true,
  useDecimalScale = true,
  placeholder,
  textAlign = 'text-left',
  isAllowed, // 입력 값의 유효성을 검사하고 입력 값의 업데이트 여부를 결정하는 함수
  useUpToOneHundredPercent = false,
  leftUnit,
  rightUnit,
  readOnly = false,
  isEditable = false,
  errors,
  showError = true,
  control,
  currentValue,
  fetchedReadOnly = false,
  parentReadOnly,
}: NumberInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const language = getLanguage() as languageType;

  const id = uuidv4();

  if (!isUndefined(value) && !isUndefined(defaultValue)) {
    throw new Error("Both 'value' and 'defaultValue' props cannot be provided at the same time.");
  }

  const getSeparator: NumericSeparator = useMemo(
    () =>
      separators[language] || {
        thousandSeparator: ',',
        decimalSeparator: '.',
        thousandsGroupStyle: 'thousand',
      },
    [language],
  );

  const getDecimalScale = (() => {
    if (currencyType) {
      return isDecimalCurrencyType(currencyType) ? 2 : 0;
    } else {
      if (numberType === 'integer') return 0;
      else return 4;
    }
  })();

  const isAllowedUpToOneHundredPercent = (values: NumberFormatValues) => {
    const { floatValue } = values;

    return !(floatValue && floatValue > 100);
  };

  const defaultIsAllowed = (values: NumberFormatValues) => {
    const { value } = values;

    const [integer] = value.split('.');
    const isValidInteger = integer.length <= maxLength[numberType];

    return isValidInteger;
  };

  const getIsAllowed = (() => {
    if (!useIsAllowed) return undefined;
    if (useUpToOneHundredPercent) return isAllowedUpToOneHundredPercent;

    return isAllowed ? isAllowed : defaultIsAllowed;
  })();

  const { removeFormatting = () => '' } = useNumericFormat({
    thousandSeparator: thousandSeparator && getSeparator['thousandSeparator'],
    thousandsGroupStyle: getSeparator['thousandsGroupStyle'],
    decimalScale: getDecimalScale,
    decimalSeparator: getSeparator['decimalSeparator'],
    allowedDecimalSeparators: allowedDecimalSeparators,
    valueIsNumericString: true,
    allowNegative,
  });

  const getError = useCallback(
    (name: string) => {
      return getObjectValue({
        object: errors,
        name,
      });
    },
    [errors],
  );

  const handleValueChange = (values: NumberFormatValues, uncontrolledChange?: ControllerRenderProps['onChange']) => {
    const { value } = values;
    uncontrolledChange?.(value);
    onChange?.(value);
  };

  const handlePaste = (
    event: ClipboardEvent<HTMLInputElement>,
    onValueChange: ControllerRenderProps['onChange'] | NumberInputProps['onChange'],
  ) => {
    if (!useIsAllowed) return;
    if (useUpToOneHundredPercent) return;

    event.preventDefault();
    const text = event.clipboardData.getData('text');
    const unformattedText = removeFormatting(text);
    const formattedTextWithDecimalScale = numericFormatter(unformattedText, {
      decimalScale: getDecimalScale,
    });

    const [integer, decimal = ''] = formattedTextWithDecimalScale.split('.');
    const validInteger = integer.substring(0, maxLength[numberType]);
    const validNumeric = `${validInteger}${decimal ? '.' : ''}${decimal}`;
    onValueChange?.(validNumeric);
    if (onPaste) onPaste(event);
  };

  const getPlaceHolder = (() => {
    if (disabled || !isEditable) return '';
    if (placeholder === undefined) return t('text:Please_type_here');
    else return placeholder;
  })();

  useEffect(() => {
    if (!getError(name)) return;
    inputRef.current && inputRef.current.focus();
  }, [errors, getError, name]);

  const UncontrolledInput = (
    <Controller
      control={control}
      name={name}
      defaultValue={isNilOrEmptyString(defaultValue) ? null : defaultValue}
      render={({ onChange: uncontrolledChange, value: controlledValue }) => {
        return (
          <NumericFormat
            id={id}
            name={name}
            aria-label={name}
            className={clsx('form__input', [textAlign], {
              'error-input-border': getError(name) && showError,
              [className]: className,
            })}
            value={controlledValue ?? ''}
            onValueChange={values => handleValueChange(values, uncontrolledChange)}
            onPaste={(event: ClipboardEvent<HTMLInputElement>) => handlePaste(event, uncontrolledChange)}
            thousandSeparator={thousandSeparator && getSeparator['thousandSeparator']}
            thousandsGroupStyle={getSeparator['thousandsGroupStyle']}
            decimalScale={useDecimalScale ? getDecimalScale : undefined}
            decimalSeparator={getSeparator['decimalSeparator']}
            allowedDecimalSeparators={allowedDecimalSeparators}
            allowNegative={allowNegative}
            isAllowed={getIsAllowed}
            getInputRef={inputRef}
            valueIsNumericString
            placeholder={getPlaceHolder}
            readOnly={readOnly || fetchedReadOnly}
            disabled={disabled || !isEditable}
          />
        );
      }}
      rules={rules}
    />
  );

  const ControlledInput = (
    <NumericFormat
      id={id}
      name={name}
      aria-label={name}
      className={clsx('form__input', [textAlign], {
        [className]: className,
      })}
      value={value ?? ''}
      onValueChange={values => handleValueChange(values)}
      onPaste={(event: ClipboardEvent<HTMLInputElement>) => handlePaste(event, onChange)}
      thousandSeparator={thousandSeparator && getSeparator['thousandSeparator']}
      thousandsGroupStyle={getSeparator['thousandsGroupStyle']}
      decimalScale={useDecimalScale ? getDecimalScale : undefined}
      decimalSeparator={getSeparator['decimalSeparator']}
      allowedDecimalSeparators={allowedDecimalSeparators}
      allowNegative={allowNegative}
      isAllowed={getIsAllowed}
      valueIsNumericString
      placeholder={getPlaceHolder}
      readOnly={readOnly || fetchedReadOnly}
      disabled={disabled || !isEditable}
    />
  );

  const renderInput = (() => {
    const hasNoValue =
      isNilOrEmptyString(defaultValue) && isNilOrEmptyString(value) && isNilOrEmptyString(currentValue);

    const shouldShowDash = (() => {
      const defaultCondition = hasNoValue && (readOnly || fetchedReadOnly || disabled || !isEditable);

      if (isNil(parentReadOnly)) return defaultCondition;

      return parentReadOnly && defaultCondition;
    })();

    if (shouldShowDash) return <Value value="-" textAlign={textAlign} />;

    return control ? UncontrolledInput : ControlledInput;
  })();

  return (
    <div className="flex-align-center">
      {leftUnit && <span className="unit">{leftUnit}</span>}
      <div
        className={clsx({
          [`field--size-${fieldSize}`]: fieldSize,
          'flex-1': !fieldSize,
        })}
        data-testid="numeric-format-div"
      >
        {renderInput}
        {errors && showError && <FormErrorMessage error={getError(name)} />}
      </div>
      {rightUnit && <span className="unit">{rightUnit}</span>}
    </div>
  );
};

export default NumberInput;
