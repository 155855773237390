import { ENTERPRISE_TYPE, FINANCIER_ALERT_TYPE, stringToEnum } from 'enums';

export interface UserEmailSettingVO {
  userEmailSettingId: number;
  alertType: string;
  emailEnabled: boolean;
  recentUpdateEnterpriseId: number;
  recentUpdateEnterpriseName: string;
  recentUpdateEnterpriseType: string;
}

export interface UserEmailSettingVOModel extends UserEmailSettingVO {
  alertType: FINANCIER_ALERT_TYPE;
  recentUpdateEnterpriseType: ENTERPRISE_TYPE;
}

export function formattingToUserEmailSettingVOModel(data: UserEmailSettingVO): UserEmailSettingVOModel {
  const alertTypeToEnum = stringToEnum(FINANCIER_ALERT_TYPE, data.alertType);

  return {
    ...data,
    alertType: alertTypeToEnum,
    recentUpdateEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.recentUpdateEnterpriseType),
  };
}
