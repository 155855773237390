import { memo, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getMswDevToolOpen, setIsMswUsedOfStorage, setMswDevToolOpen } from 'utils/storage/LocalStorage';

import Content from './Content';
import './MSWDevTool.scss';

export const MSW_PARAMS = {
  MSW: 'msw',
  PASS: 'pass',
  EMPTY: 'empty',
  ERROR: 'error',
} as const;

const MSWDevToolWrapper = () => {
  const shouldNotBeRendered = process.env.REACT_APP_IS_MSW_USED === 'false' || process.env.REACT_APP_IS_MOCK === 'true';
  if (shouldNotBeRendered) return null;

  return <MSWDevTool />;
};

const MSWDevTool = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const isMswOn = Boolean(searchParams.get(MSW_PARAMS.MSW)) ?? false;

  const [isOpen, setIsOpen] = useState(false);
  const [isMswUsed, setIsMswUsed] = useState(isMswOn);

  const getSearchParams = (param: string, newValue = 'true') => {
    const isMswParamExist = searchParams.get(MSW_PARAMS.MSW);
    const paramWithSameValue = Array.from(searchParams.entries()).find(([, value]) => value === newValue);

    if (!isMswParamExist && !paramWithSameValue) {
      searchParams.append(param, newValue);

      return searchParams.toString();
    }

    if (param === MSW_PARAMS.MSW) {
      Object.values(MSW_PARAMS).forEach(param => searchParams.delete(param));

      return searchParams.toString();
    }

    if (param !== MSW_PARAMS.MSW) {
      const newSearchParams = Array.from(searchParams.entries()).filter(([, value]) => value !== newValue);
      if (!paramWithSameValue || paramWithSameValue[0] !== param) newSearchParams.push([param, newValue]);

      return new URLSearchParams(newSearchParams).toString();
    }
  };

  const changeSearchParams = (param: string, value?: string) => {
    const searchParamsToBeAdded = getSearchParams(param, value);
    history.replace({ ...location, search: searchParamsToBeAdded });
    window.location.reload();
  };

  const handleMswButtonClick = () => {
    setIsMswUsed(prevIsMswUsed => !prevIsMswUsed);
    setIsMswUsedOfStorage(!isMswUsed);
    changeSearchParams('msw');
  };

  const handleVisibleButtonClick = (isOpen: boolean) => {
    setIsOpen(isOpen);
    setMswDevToolOpen(isOpen);
  };

  useLayoutEffect(() => {
    const isMswDevToolOpen = getMswDevToolOpen();
    setIsOpen(isMswDevToolOpen);

    setIsMswUsedOfStorage(isMswUsed);
  }, [isMswUsed]);

  return (
    <>
      {!isOpen && (
        <button
          type="button"
          title="Show MSW Panel"
          className="dev-tool__open-button"
          onClick={() => handleVisibleButtonClick(true)}
        >
          M
        </button>
      )}
      <div className={`dev-tool__container ${isOpen ? 'active' : ''}`}>
        <div className="dev-tool__header">
          <h2 className="dev-tool__title">MSW DevTool</h2>
          <button
            type="button"
            title="Close MSW Panel"
            className="dev-tool__close-button"
            onClick={() => handleVisibleButtonClick(false)}
          >
            <FontAwesomeIcon icon={faClose} color="white" />
          </button>
        </div>
        <div className="dev-tool__content">
          <button className="dev-tool__msw-button" onClick={handleMswButtonClick}>
            MSW : {isMswUsed ? 'ON' : 'OFF'}
          </button>
          <Content isMswUsed={isMswUsed} changeSearchParams={changeSearchParams} />
        </div>
      </div>
    </>
  );
};

export default memo(MSWDevToolWrapper);
