import { LOAN_STATUS } from 'enums';

// 대출 상태가 지급 이후면 true 반환
export function isLoanDisbursed(code: LOAN_STATUS | null | undefined): boolean {
  switch (code) {
    case null:
    case LOAN_STATUS.DEALER_CREATED:
    case LOAN_STATUS.DEALER_REQUESTED:
    case LOAN_STATUS.DEALER_CANCELLED:
    case LOAN_STATUS.REJECTED:
    case LOAN_STATUS.APPROVED:
      return false;
    case LOAN_STATUS.DISBURSED:
    case LOAN_STATUS.REPAID:
    case LOAN_STATUS.RECOURSE_ACTIVATED:
    case LOAN_STATUS.OVERDUE:
    case LOAN_STATUS.EXPIRATION: {
      return true;
    }
    default:
      return false;
  }
}
