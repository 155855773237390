import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from 'components/stateless/Accordion/Accordion';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import WaitingInvoiceResultText from 'components/stateless/ResultTextByStatus/WaitingInvoiceResultText';
import { TableBody, TableBorder, TableHeader } from 'components/stateless/Table';
import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import useProgressStatus from 'hooks/useProgressStatus';
import type Pageable from 'models/Pageable';
import type { InvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import type { InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import type { WaitingInvoiceVOModel } from 'models/vo/WaitingInvoiceVO';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

type InvoiceSummaryPhaseAccordionPropsType = {
  invoiceSummary: InvoiceSummaryVOModel;
  invoiceSummaryPhase: InvoicePhaseVOModel;
  onAccordionClick: () => Promise<Pageable<WaitingInvoiceVOModel[]>>;
};

const InvoiceSummaryPhaseAccordion = ({
  invoiceSummary,
  invoiceSummaryPhase,
  onAccordionClick,
}: InvoiceSummaryPhaseAccordionPropsType) => {
  const [invoiceList, setInvoiceList] = useState<WaitingInvoiceVOModel[]>([]);
  const [searchedWaitingApprovalPhaseId, setSearchedWaitingApprovalPhaseId] = useState<number[]>([]);

  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { progressStatusText, progressStatusClassName, progressReviewedTimeText } = useProgressStatus(
    invoiceSummary,
    invoiceSummaryPhase,
  );

  const additionalHeaders: HeaderType[] | undefined = invoiceList?.[0]?.invoiceAdditionalDataList?.map(
    additionalData => ({
      headerText: additionalData.name,
      colWidths: 180,
    }),
  );

  const additionalDataListLength = invoiceList?.[0]?.invoiceAdditionalDataList?.length ?? 0;

  const INVOICE_SUMMARY_PHASE_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      colWidths: 50,
    },
    {
      headerText: t('text:Invoice_Number'),
      colWidths: 180,
    },
    {
      headerText: t('text:Reference_Number'),
      colWidths: 180,
    },
    {
      headerText: t('text:Invoice_Issued_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Payment_Date'),
      colWidths: 180,
    },
    {
      headerText: `${t('text:Invoice_Amount')} (${t('text:unit')}: ${invoiceSummary.currencyType ?? ''})`,
      colWidths: 180,
    },
    ...(additionalHeaders ?? []),
    {
      headerText: t('text:Validation_Result'),
      className: additionalDataListLength > 0 ? 'd-none' : '',
      colWidths: 180,
    },
  ];

  const INVOICE_SUMMARY_PHASE_TABLE_BIG_HEADERS: BigHeaderType[] = [
    {
      headerText: '',
      colSpan: 1,
    },
    {
      headerText: t('text:Invoice_Summary'),
      colSpan: 5,
    },
    {
      headerText: t('text:Invoice_Details'),
      colSpan: additionalDataListLength,
    },
    {
      headerText: t('text:Validation_Result'),
      colSpan: 1,
      rowSpan: 2,
    },
  ];

  const onClickInvoiceContainer = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    invoicePhaseData: InvoicePhaseVOModel,
  ) => {
    event.preventDefault();
    if (searchedWaitingApprovalPhaseId.includes(invoicePhaseData.waitingInvoiceApprovalPhaseId)) return;
    else {
      try {
        const waitingInvoiceData = await onAccordionClick();
        setSearchedWaitingApprovalPhaseId(prevState => [...prevState, invoicePhaseData.waitingInvoiceApprovalPhaseId]);
        setInvoiceList(waitingInvoiceData?.content ?? []);
      } catch (error) {
        modal.show(error);
      }
    }
  };

  return (
    <Accordion
      className="accordion accordion-information-form"
      id={`invoice-list-${invoiceSummaryPhase.waitingInvoiceApprovalPhaseId}`}
    >
      <Accordion.Header id={`invoice-list-header-${invoiceSummaryPhase.waitingInvoiceApprovalPhaseId}`}>
        <Accordion.Trigger onClick={event => onClickInvoiceContainer(event, invoiceSummaryPhase)}>
          <div className="accordion-information-form__head">
            <div className={progressStatusClassName}>{progressStatusText}</div>
            <div className="accordion-information-form__head--text">
              <div className="fw-bold">
                {tableValueManage(t(`code:authority-type.${invoiceSummaryPhase.approveUserAuthorityType}`))} (
                {invoiceSummaryPhase.approveUserName}, {invoiceSummaryPhase.approveUserLoginId})
              </div>
              {invoiceSummaryPhase.approveEnterpriseName}
            </div>
            <div className="accordion-information-form__head--text">
              <div className="fw-bold">{progressReviewedTimeText}</div>
              {t('format:datetime', { value: invoiceSummaryPhase.createdDateTime, key: 'date' })}
            </div>
          </div>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className="accordion-body">
        <TableBorder scrollYMaxHeight={600}>
          <TableHeader
            header={INVOICE_SUMMARY_PHASE_TABLE_HEADERS}
            bigHeader={additionalDataListLength > 0 ? INVOICE_SUMMARY_PHASE_TABLE_BIG_HEADERS : undefined}
            backGroundType={BackGroundType.DarkGray}
          />
          <TableBody numOfCol={INVOICE_SUMMARY_PHASE_TABLE_HEADERS?.length}>
            {invoiceList.map((invoice, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td>{tableValueManage(invoice.invoiceNumber)}</td>
                <td>{tableValueManage(invoice.referenceNumber)}</td>
                <td>
                  {tableValueManage(
                    invoice.invoiceIssuedDate,
                    t('format:date', { value: invoice.invoiceIssuedDate, key: 'datetime' }),
                  )}
                </td>
                <td>
                  {tableValueManage(
                    invoice.settlementDate,
                    t('format:date', { value: invoice.settlementDate, key: 'datetime' }),
                  )}
                </td>
                <td>{tableValueManage(t('format:number', { value: invoice.invoiceAmount }))}</td>
                {invoice.invoiceAdditionalDataList?.map((additionalData, additionalDataIndex) => (
                  <td key={`${additionalDataIndex}-${additionalData.value}`}>
                    {tableValueManage(additionalData.value)}
                  </td>
                ))}
                <WaitingInvoiceResultText
                  status={invoice.waitingInvoiceStatus}
                  exceptionMessage={invoice.exceptionMessage}
                />
              </tr>
            ))}
          </TableBody>
        </TableBorder>

        <div className="grid-total">
          {t('text:Total')} : {t('format:number', { value: invoiceSummaryPhase.amount })}
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default InvoiceSummaryPhaseAccordion;
