import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import type { UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';

type UsageTableProps = {
  usageDataList: UsageSummaryVOModel[];
  isUsageHistory: boolean;
};

function UsageTable({ usageDataList, isUsageHistory }: UsageTableProps) {
  const { t } = useTranslation();

  const tableHeaders: HeaderType[] = useMemo(
    () => [
      {
        headerText: t('text:Financier'),
        colWidths: 120,
      },
      {
        headerText: t('text:API_Requests'),
        colWidths: 120,
      },
      {
        headerText: t('text:Active_Users'),
        colWidths: 120,
      },
      {
        headerText: t('text:Active_Clients'),
        colWidths: 120,
      },
      {
        headerText: t('text:Performance_Fee'),
        colWidths: 120,
      },
      {
        headerText: t('text:Notifications'),
        colWidths: 120,
      },
      {
        headerText: t('text:Storage'),
        colWidths: 120,
      },
      {
        headerText: t('text:Platform_Usage_Fee'),
        colWidths: 120,
      },
    ],
    [t],
  );

  return (
    <TableBorder>
      <TableHeader header={tableHeaders} />
      <TableBody numOfCol={tableHeaders.length}>
        {usageDataList?.map((item, index) => (
          <Tr key={item.enterpriseId} className={isUsageHistory && index === 0 ? 'main100' : ''}>
            <Td data={isUsageHistory && index === 0 ? t('text:Total') : item.enterpriseName} />
            <Td data={item.apiUsage} format="number" right={t('text:Requests')} />
            <Td data={item.userUsage} format="number" right={t('text:Users')} />
            <Td data={item.clientUsage} format="number" right={t('text:Clients')} />
            <Td data={item.loanUsageAmount} format="number" left="USD" />
            <Td data={item.notificationUsage + item.emailUsage} format="number" />
            <Td data={item.storageUsage} format="number" right="MB" />
            <Td className="text-green text-bold" data={item.totalAmount} format="number" left="USD" />
          </Tr>
        ))}
      </TableBody>
    </TableBorder>
  );
}

export default UsageTable;
