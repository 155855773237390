import Big from 'big.js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { INVOICE_NUMBER_DUPLICATION_POLICY } from 'enums';
import i18n from 'locales/i18n';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { FiscalYearSettingVOModel } from 'models/vo/FiscalYearSettingVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { enumerateDaysBetweenDates } from 'utils/date/date';

dayjs.extend(customParseFormat);

export const INVOICE_PAYMENT_TO_DATE_RANGE = 180;
export interface InvoiceType {
  dealerIdentifierCode?: string;
  invoiceNumber: string;
  referenceNumber?: string;
  billOfExchangeNo?: string;
  invoiceIssuedDate: string;
  settlementDate: string;
  invoiceAmount: number;
}

interface InvoiceTypeWithFiscalYear extends InvoiceType {
  fiscalYear: number;
}

interface LimitAmountsType {
  loanLimitAmount: number | BigNumber;
  disbursedLoanAmount: number | BigNumber;
  settlementWaitingInvoiceAmount: number | BigNumber;
  registeredWaitingInvoiceAmount: number | BigNumber;
  partiallyRepaidPrincipalAmount?: BigNumber;
}

export const invoiceDuplicateValidator = (
  invoiceList: InvoiceType[],
  targetInvoiceNumber: string,
  targetIndex: number,
  fiscalYearSettingData: FiscalYearSettingVOModel | null,
): boolean => {
  if (!targetInvoiceNumber) return true;

  const noFiscalYearDate =
    fiscalYearSettingData === null ||
    fiscalYearSettingData.invoiceNumberDuplicationPolicy === INVOICE_NUMBER_DUPLICATION_POLICY.ALWAYS;

  if (noFiscalYearDate) {
    const isDuplicated = invoiceList.some(({ invoiceNumber }, currentIndex) => {
      if (!invoiceNumber) return false;
      if (currentIndex === targetIndex) return false;

      return invoiceNumber === targetInvoiceNumber;
    });
    const isPassedValidation = !isDuplicated;

    return isPassedValidation;
  } else {
    const targetIssuedDate = invoiceList[targetIndex].invoiceIssuedDate;
    if (!targetIssuedDate) return true;

    const { month, date } = fiscalYearSettingData;
    const fiscalYearBaseDate = dayjs(`${month}-${date}`, 'M-D');
    const fiscalYearMonth = fiscalYearBaseDate.month();
    const fiscalYearDate = fiscalYearBaseDate.date();

    const mappedFiscalYearToInvoiceList: InvoiceTypeWithFiscalYear[] = invoiceList.map(invoiceItem => {
      if (!invoiceItem.invoiceIssuedDate) return { ...invoiceItem, fiscalYear: NaN };
      const dayjsInvoiceIssuedDate = dayjs(invoiceItem.invoiceIssuedDate);
      const issuedYear = dayjsInvoiceIssuedDate.year();
      const issuedMonth = dayjsInvoiceIssuedDate.month();
      const issuedDate = dayjsInvoiceIssuedDate.date();

      const isBefore =
        issuedMonth < fiscalYearMonth || (issuedMonth === fiscalYearMonth && issuedDate < fiscalYearDate);
      if (isBefore) {
        return { ...invoiceItem, fiscalYear: issuedYear - 1 };
      } else {
        return { ...invoiceItem, fiscalYear: issuedYear };
      }
    });

    const targetFiscalYear = mappedFiscalYearToInvoiceList[targetIndex].fiscalYear;
    const isDuplicated = mappedFiscalYearToInvoiceList.some(({ invoiceNumber, fiscalYear }, currentIndex) => {
      if (!invoiceNumber) return false;
      if (currentIndex === targetIndex) return false;

      return invoiceNumber === targetInvoiceNumber && targetFiscalYear === fiscalYear;
    });
    const isPassedValidation = !isDuplicated;

    return isPassedValidation;
  }
};

export const referenceNumberDuplicateValidator = (
  invoiceData: InvoiceType[],
  value: string,
  index: number,
): boolean => {
  if (invoiceData) {
    const getReferenceNumbers = invoiceData.map(item => item.referenceNumber);
    getReferenceNumbers.splice(index, 1);

    const referenceNumbers = getReferenceNumbers.filter(item => {
      if (item && item !== '') return item;
    });

    if (referenceNumbers.includes(value)) {
      return false;
    }
  }

  return true;
};

export const repaymentDateSameValidator = (invoiceData: InvoiceType[], index: number) => {
  if (invoiceData) {
    const getBoeAndSettlement = invoiceData.map(item => {
      return {
        billOfExchangeNo: item.billOfExchangeNo,
        settlementDate: item.settlementDate,
      };
    });

    const [target] = getBoeAndSettlement.splice(index, 1);

    if (!target.settlementDate) {
      return true;
    }

    return (
      getBoeAndSettlement.find(item => {
        return item.billOfExchangeNo === target.billOfExchangeNo && item.settlementDate !== target.settlementDate;
      }) === undefined
    );
  }
};

// 송장 등록/승인 시에 결제일 setting Function
export const getInitialSettlementDateFunction = (
  holidayArrays: string[],
  financierCommonSettingResponse: FinancierCommonSettingVOModel,
  startDate: Date,
  nextDate: Date,
  toDate: Date,
): string => {
  if (financierCommonSettingResponse.invoiceWillBeSettledOnUploadDate) {
    return financierCommonSettingResponse.availableCurrentDisburseBusinessDate;
  } else {
    const upLoadableStartDate = financierCommonSettingResponse.settlementDateIsTodayInvoiceUploadable
      ? startDate
      : nextDate;
    if (holidayArrays.length === 0) {
      return dayjs(upLoadableStartDate).format('YYYY-MM-DD');
    } else {
      const getRanges = enumerateDaysBetweenDates(upLoadableStartDate, toDate);
      const result = getRanges.filter(el => !holidayArrays.includes(el));

      // return 'YYYY-MM-DD' format
      return result[0];
    }
  }
};

// temp 값이나 excel upload 시에, 결제일 setting Function
export const setEnteredSettlementDate = (
  financierHolidayLists: string[] | undefined,
  targetDate: string,
  initialSettlementDate: string | undefined,
  financierCommonSettingResponse: FinancierCommonSettingVOModel | undefined,
): string | undefined => {
  if (financierCommonSettingResponse?.invoiceWillBeSettledOnUploadDate) {
    return financierCommonSettingResponse.availableCurrentDisburseBusinessDate;
  } else {
    // targetDate가 invalid 한 형식이면 undefined 반환
    if (!dayjs(targetDate, 'YYYY-MM-DD', true).isValid()) return undefined;
    // targetDate가 휴일에 포함되면 undefined 반환
    if (financierHolidayLists?.includes(targetDate)) return undefined;
    // initialSettlementDateValue기 nullable한 값이면 undefined 반환
    if (initialSettlementDate === undefined) return undefined;
    // targetDate가 initialSettlementDateValue 이전 날짜이면 undefined 반환
    if (dayjs(targetDate).isBefore(initialSettlementDate)) return undefined;

    const startDate = dayjs().format('YYYY-MM-DD');
    const toDate = dayjs().add(180, 'day').format('YYYY-MM-DD');
    const nextDate = dayjs().add(1, 'day').format('YYYY-MM-DD');

    const registerableStartDate = financierCommonSettingResponse?.settlementDateIsTodayInvoiceUploadable
      ? startDate
      : nextDate;

    // targetDate가 등록 가능한 시작일 이전이거나 등록 가능한 마감일 이후이면 undefined 반환
    if (dayjs(targetDate).isBefore(registerableStartDate) || dayjs(targetDate).isAfter(toDate)) {
      return undefined;
    }

    return targetDate;
  }
};

export const calculateTotalLimitAmountWithoutAdHoc = (limitAmounts: LimitAmountsType): string => {
  const {
    loanLimitAmount,
    disbursedLoanAmount,
    settlementWaitingInvoiceAmount,
    registeredWaitingInvoiceAmount,
    partiallyRepaidPrincipalAmount = 0,
  } = limitAmounts;

  const calculatorBigNumber = new CalculatorBigNumber();

  // 총 대출 한도 금액 - (지급된 대출 금액 + 결제 대기 송장 금액 + 등록 완료 대기 송장 금액)  + 부분 상환 금액
  // loanLimitAmount - (disbursedLoanAmount + settlementWaitingInvoiceAmount + registeredWaitingInvoiceAmount) + partiallyRepaidPrincipalAmount
  return calculatorBigNumber
    .add(loanLimitAmount)
    .add(partiallyRepaidPrincipalAmount)
    .minus(disbursedLoanAmount)
    .minus(settlementWaitingInvoiceAmount)
    .minus(registeredWaitingInvoiceAmount)
    .get();
};

export const isExceedInvoiceAmountWhenConfirmation = (
  dealerAgreementDetailData: DealerAgreementDetailVOModel | undefined,
  prevPhaseTotalAmount: BigNumber,
  totalInvoiceAmount: BigNumber,
): boolean | undefined => {
  if (dealerAgreementDetailData && totalInvoiceAmount) {
    const { adhocLimitAllowable, totalLimitAmountWithAdHoc, totalLimitAmountWithoutAdHoc } = dealerAgreementDetailData;

    const calculatorBigNumber = new CalculatorBigNumber();

    return adhocLimitAllowable
      ? Big(totalInvoiceAmount).gt(calculatorBigNumber.add(prevPhaseTotalAmount).add(totalLimitAmountWithAdHoc).get())
      : Big(totalInvoiceAmount).gt(
          calculatorBigNumber.add(prevPhaseTotalAmount).add(totalLimitAmountWithoutAdHoc).get(),
        );
  } else return undefined;
};

export const isExceedInvoiceAmountWhenRegistration = (
  dealerAgreementDetailData: DealerAgreementDetailVOModel | undefined,
  totalInvoiceAmount: BigNumber,
): boolean | undefined => {
  if (dealerAgreementDetailData && totalInvoiceAmount) {
    const { adhocLimitAllowable, totalLimitAmountWithAdHoc, totalLimitAmountWithoutAdHoc } = dealerAgreementDetailData;

    return adhocLimitAllowable
      ? Big(totalInvoiceAmount).gt(totalLimitAmountWithAdHoc)
      : Big(totalInvoiceAmount).gt(totalLimitAmountWithoutAdHoc);
  } else return undefined;
};

export const calculateInvoiceFinancingBalance = (dealerAgreementDetailData?: DealerAgreementDetailVOModel) => {
  let result;

  if (dealerAgreementDetailData) {
    // disbursedLoanAmount - partiallyRepaidPrincipalAmount
    const { disbursedLoanAmount, partiallyRepaidPrincipalAmount } = dealerAgreementDetailData;
    const calculatorBigNumber = new CalculatorBigNumber();

    result = i18n.t('format:number', {
      value: calculatorBigNumber.add(disbursedLoanAmount).minus(partiallyRepaidPrincipalAmount).get(),
    });
  } else result = '-';

  return result;
};

export const calculateInvoiceRemainingLimit = (dealerAgreementDetailData?: DealerAgreementDetailVOModel) => {
  let result;

  if (dealerAgreementDetailData) {
    // loanLimitAmount + partiallyRepaidPrincipalAmount - disbursedLoanAmount
    const { loanLimitAmount, partiallyRepaidPrincipalAmount, disbursedLoanAmount } = dealerAgreementDetailData;
    const calculatorBigNumber = new CalculatorBigNumber();

    result = i18n.t('format:number', {
      value: calculatorBigNumber
        .add(loanLimitAmount)
        .add(partiallyRepaidPrincipalAmount)
        .minus(disbursedLoanAmount)
        .get(),
    });
  } else result = '-';

  return result;
};
