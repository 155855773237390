import type Pageable from 'models/Pageable';
import type { SuccessInvoiceDetailVO, SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { formattingToSuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import type { SuccessInvoiceVO, SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import { formattingToSuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemSuccessInvoiceListRequest } from './requests';

/*
	URL :: /api/v1/sy/success-invoices
	Method :: GET
*/
export async function requestSystemSuccessInvoiceList(
  pageNumber: number,
  rowCount: number,
  data: SystemSuccessInvoiceListRequest,
): Promise<Pageable<SuccessInvoiceVOModel[]>> {
  const response = await http.get<Pageable<SuccessInvoiceVO[]>>({
    url: API_SY.SUCCESS_INVOICES.SUCCESS_INVOICE_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  const successInvoiceList = response.content.map(data => formattingToSuccessInvoiceVOModel(data));

  return {
    ...response,
    content: successInvoiceList,
  };
}

/*
	URL :: /api/v1/sy/success-invoices/{success-invoice-id}
	Method :: GET
*/
export async function requestSystemSuccessInvoiceDetail(
  successInvoiceId: number,
): Promise<SuccessInvoiceDetailVOModel> {
  const response = await http.get<SuccessInvoiceDetailVO>({
    url: API_SY.SUCCESS_INVOICES.SUCCESS_INVOICE_DETAIL(successInvoiceId),
  });

  return formattingToSuccessInvoiceDetailVOModel(response);
}

/*
  URL :: /api/v1/sy/success-invoices/{success-invoice-id}/download:scanned-invoice
  Method :: GET
*/
export async function requestSystemDownloadScannedInvoice(successInvoiceId: number) {
  await http.download(API_SY.SUCCESS_INVOICES.DOWNLOAD_SCANNED_INVOICE(successInvoiceId));
}
