import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import ChangePasswordModal from 'components/stateless/Modal/common/ChangePasswordModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { UserVOModel } from 'models/vo/UserVO';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestCurrentUser,
  requestCurrentUserChangeInfo,
  requestCurrentUserResetPassword,
} from 'utils/http/api/common/users';
import type { CurrentUserChangeInfoRequest, CurrentUserChangePassword } from 'utils/http/api/common/users/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import Button, {
  ButtonColorEnum,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../components/stateless/Button/Button';
import getStatusTextClass from '../../../../utils/classNames/getStatusTextClass';

function FinancierMyAccount() {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const [currentUserInfo, setCurrentUserInfo] = useState<UserVOModel>();
  const getProperty = useProperty<CurrentUserChangeInfoRequest>();

  const [isEditable, setIsEditable] = useState<boolean>(); // edit 활성화 여부 state

  // edit button handler
  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    if (mounted) {
      fetchCurrentUser();
    }
  }, [mounted]);

  const userInfoChangeForm = useForm<CurrentUserChangeInfoRequest>({
    mode: 'onSubmit',
    shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
  });

  async function fetchCurrentUser() {
    try {
      const currentUserInfo = await requestCurrentUser();

      ReactDOM.unstable_batchedUpdates(() => {
        setIsEditable(false);
        setCurrentUserInfo(currentUserInfo);

        userInfoChangeForm.reset({
          userName: currentUserInfo?.userName,
          telephone: currentUserInfo?.telephone,
          email: currentUserInfo?.email,
        });
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSubmitUserChangeForm = async (e: any) => {
    e.preventDefault();

    const data = userInfoChangeForm.getValues();

    try {
      requestDTOParser(data);

      await requestCurrentUserChangeInfo(data);
      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        closeBtnCb: () => fetchCurrentUser(),
      });
      userInfoChangeForm.clearErrors();
    } catch (e) {
      modal.show(e);

      formErrorHandler<CurrentUserChangeInfoRequest>(e, userInfoChangeForm.setError, userInfoChangeForm.clearErrors);
    }
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setIsEditable(false);
    userInfoChangeForm.reset({
      userName: currentUserInfo?.userName,
      telephone: currentUserInfo?.telephone,
      email: currentUserInfo?.email,
    });
  };

  const onSubmitChangePasswordForm = async (data: CurrentUserChangePassword) => {
    try {
      await requestCurrentUserResetPassword(data);
      modal.show(<h6>{t('text:Password_successfully_changed')}</h6>, {
        closeBtnCb: () => fetchCurrentUser(),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const showChangePasswordModal = () => {
    modal.show(<ChangePasswordModal state={{} as CurrentUserChangePassword} modalId={modal.id} />, {
      modalType: ModalType.CONFIRM,
      title: t('text:Change_password'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: (data: { state: CurrentUserChangePassword }) => {
        onSubmitChangePasswordForm(data.state);
      },
    });
  };

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={[USER_STATUS.ACTIVATED, USER_STATUS.SUSPENDED]}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:My_Account')} />
      <GuideMessage
        message={[
          t('text:The_user’s_account_information_is_shown_below'),
          t('text:Click_on_the_“Edit“_button_to_modify_the_user_information'),
        ]}
      />

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:User_Information')}>
            {isEditable ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={handleCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onSubmitUserChangeForm} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button size={ButtonSizeEnum.SM} onClick={handleEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            )}
          </SectionTitle>
          <FormBorder editable={isEditable}>
            <FormContents>
              <div className="row">
                <FormInput
                  label={t('text:User_Name')}
                  name={getProperty('userName')}
                  ref={userInfoChangeForm.register}
                  disabled={!isEditable}
                  error={userInfoChangeForm.errors.userName}
                  requiredOptions={{ required: true }}
                />
                <FormValue
                  className="information-form__input"
                  label={t('text:User_Code')}
                  value={currentUserInfo?.employeeCode}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Telephone')}
                  name={getProperty('telephone')}
                  ref={userInfoChangeForm.register}
                  disabled={!isEditable}
                  error={userInfoChangeForm.errors.telephone}
                />
                <FormInput
                  label={t('text:Email')}
                  name={getProperty('email')}
                  ref={userInfoChangeForm.register}
                  disabled={!isEditable}
                  error={userInfoChangeForm.errors.email}
                  requiredOptions={{ required: true }}
                />
              </div>
              <div className="row">
                <FormValue
                  className="information-form__input"
                  label={t('text:Branch_Code')}
                  value={currentUserInfo?.branchCode}
                />
                <FormValue
                  className="information-form__input"
                  label={t('text:Branch_Name')}
                  value={currentUserInfo?.branchName}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Account_Information')} />
          <FormBorder>
            <FormContents>
              <div className="row">
                <FormValue className="information-form__input" label={t('text:ID')} value={currentUserInfo?.loginId} />
                <FormValue className="information-form__input" label={t('text:Password')} value="●●●●●●●●">
                  <Button bold onClick={showChangePasswordModal}>
                    {t('text:Change_password')}
                  </Button>
                </FormValue>
              </div>
              <div className="row">
                <FormValue
                  className="information-form__input"
                  label={t('text:Authority')}
                  value={t(`code:authority-type.${currentUserInfo?.authorityType}`)}
                />
                <FormValue
                  className={`information-form__input ${getStatusTextClass(
                    'USER_STATUS',
                    currentUserInfo?.userStatus,
                  )}`}
                  label={t('text:User_Account_Status')}
                  value={t(`code:user-status.${currentUserInfo?.userStatus}`)}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickUserStatus}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    </>
  );
}

export default FinancierMyAccount;
