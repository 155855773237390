import type { HTMLAttributes } from 'react';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import './ChoiceChip.scss';

interface ChoiceChipProps extends HTMLAttributes<HTMLDivElement> {
  label: string | number;
  onSelect: () => void;
  onUnSelect: () => void;
  defaultSelected?: boolean;
  size?: 'sm' | 'md' | 'lg';
  icon?: {
    leftIcon?: React.ReactElement;
    rightIcon?: React.ReactElement;
  };
  style?: React.CSSProperties;
}

function ChoiceChip({
  className,
  label,
  defaultSelected = false,
  onSelect,
  onUnSelect,
  size = 'lg',
  icon,
  style,
  ...props
}: ChoiceChipProps) {
  const [selected, setSelected] = useState<boolean>(false);
  const chipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const onClick = () => {
    const isSelected = chipRef.current?.classList.contains('selected');

    setSelected(!selected);
    isSelected ? onUnSelect() : onSelect();
  };

  return (
    <div
      ref={chipRef}
      className={`choice-chip choice-chip-${size} ${selected ? 'selected' : ''} ${className ? className : ''}`}
      style={style}
      onClick={onClick}
      {...props}
    >
      {selected && icon?.leftIcon}
      {label}
      {selected && icon?.rightIcon}
    </div>
  );
}

export default ChoiceChip;
