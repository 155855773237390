import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { COMMON_APPROVAL_TYPE } from 'enums';
import { ModalSize } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';
import useRevisionHistoryListViewModel from '../../../models/waitingAgreement/useWaitingAgreementViewModel';

const useAgreementRevisionHistoryController = () => {
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();
  const { waitingAgreement, waitingAgreementPageable, fetchWaitingAgreement } = useRevisionHistoryListViewModel();
  const { dealerAgreementId } = useExtraInformationViewModel();

  const handleApprovalStatusQuestionButtonClick = () => {
    showModal(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const REVISION_HISTORY_LIST_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Time'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Branch_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Authorizer_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Authorizer_Mobile'),
      colWidths: 120,
    },
    {
      headerText: t('text:Authorizer_Email'),
      colWidths: 150,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: handleApprovalStatusQuestionButtonClick,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 150,
    },
  ];

  const waitingAgreementPaginate = async (selectedPageNumber: number, selectedRowCount: number) => {
    await fetchWaitingAgreement(dealerAgreementId, selectedPageNumber, selectedRowCount);
  };

  const resetSection = useCallback(async () => {
    if (isNil(dealerAgreementId)) return;

    await fetchWaitingAgreement(dealerAgreementId);
  }, [dealerAgreementId]);

  useEffect(() => {
    resetSection();
  }, [resetSection]);

  return { REVISION_HISTORY_LIST_HEADERS, waitingAgreement, waitingAgreementPageable, waitingAgreementPaginate };
};

export default useAgreementRevisionHistoryController;
