import type Pageable from 'models/Pageable';
import type {
  SuccessArGroupAnchorPartnerAccountVO,
  SuccessArGroupAnchorPartnerAccountVOModel,
} from 'models/vo/SuccessArGroupAnchorPartnerAccountVO';
import { formattingToSuccessArGroupAnchorPartnerAccountVOModel } from 'models/vo/SuccessArGroupAnchorPartnerAccountVO';
import { formattingToSuccessGroupCurrencyTypeVOModel } from 'models/vo/SuccessArGroupCurrencyTypeVO';
import type {
  SuccessArGroupCurrencyTypeVO,
  SuccessArGroupCurrencyTypeVOModel,
} from 'models/vo/SuccessArGroupCurrencyTypeVO';
import type { SuccessArVO, SuccessArVOModel } from 'models/vo/SuccessArVO';
import { formattingToSuccessArVOModel } from 'models/vo/SuccessArVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

/**
 * desc :: 잠재 파트너 - 매출채권 목록 조회
 * params ::  code (email),  anchorPartnerAccountI
 * **/
export async function requestAnSuccessAr(
  pageNumber: number,
  rowCount: number,
  code: string, // email code
  anchorPartnerAccountId: number,
): Promise<Pageable<SuccessArVOModel[]>> {
  const response = await http.get<Pageable<SuccessArVO[]>>({
    url: API_AN.SUCCESS_ARS.SUCCESS_AR_LIST,
    data: {
      pageNumber,
      rowCount,
      code,
      anchorPartnerAccountId,
    },
  });
  const successArList: SuccessArVOModel[] = response.content.map((data: SuccessArVO) =>
    formattingToSuccessArVOModel(data),
  );

  return { ...response, content: successArList };
}
/**
 * desc :: 잠재 파트너 - 통화별 대출 가능 AP 금액 총액 조회
 * params :: pageable, code (email)
 * **/
export async function requestAnSuccessArGroupAnchorPartnerAccount(
  pageNumber: number,
  rowCount: number,
  code: string, // email code
): Promise<Pageable<SuccessArGroupAnchorPartnerAccountVOModel[]>> {
  const response = await http.get<Pageable<SuccessArGroupAnchorPartnerAccountVO[]>>({
    url: API_AN.SUCCESS_ARS.GROUP_ANCHOR_PARTNER_ACCOUNT,
    data: {
      pageNumber,
      rowCount,
      code,
    },
  });
  const successArList: SuccessArGroupAnchorPartnerAccountVOModel[] = response.content.map(
    (data: SuccessArGroupAnchorPartnerAccountVO) => formattingToSuccessArGroupAnchorPartnerAccountVOModel(data),
  );

  return { ...response, content: successArList };
}

/**
 * desc :: 잠재 파트너 - 통화별 매출채권 총액 조회
 * params :: anchorPartnerAccountId (path), code (email)
 * **/

export async function requestAnSuccessArGroupAnchorPartnerAccountAnchorPartnerAccountId(
  anchorPartnerAccountId: number,
  code: string,
): Promise<SuccessArGroupAnchorPartnerAccountVOModel> {
  const response = await http.get<SuccessArGroupAnchorPartnerAccountVO>({
    url: API_AN.SUCCESS_ARS.GROUP_ANCHOR_PARTNER_ACCOUNT_ID(anchorPartnerAccountId),
    data: { code },
  });

  return formattingToSuccessArGroupAnchorPartnerAccountVOModel(response);
}

/**
 * desc :: 잠재 파트너 - 통화별 대출 가능한 AR 총액 조회
 * params :: pageable , code (email)
 * **/

export async function requestAnSuccessArsGroupCurrencyType(
  pageNumber: number,
  rowCount: number,
  code: string,
): Promise<Pageable<SuccessArGroupCurrencyTypeVOModel[]>> {
  const response = await http.get<Pageable<SuccessArGroupCurrencyTypeVO[]>>({
    url: API_AN.SUCCESS_ARS.GROUP_CURRENCY_TYPE,
    data: {
      pageNumber,
      rowCount,
      code,
    },
  });

  return { ...response, content: response.content.map(data => formattingToSuccessGroupCurrencyTypeVOModel(data)) };
}
