export interface BranchHistoryVO {
  branchHistoryId: number;
  updateDateTime: string;
  updateUserLoginId: string;
  updateUserName: string;
  branchName: string;
  telephone: string;
  branchCode: string;
  branchBusinessCode: string;
  managerName: string;
  address: string;
  fax: string;
  managerMobile: string;
  managerEmail: string;
}

export interface BranchHistoryVOModel extends BranchHistoryVO {}

export function formattingToBranchHistoryVOModel(data: BranchHistoryVO): BranchHistoryVOModel {
  return {
    ...data,
  };
}
