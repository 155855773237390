import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { isEmpty } from 'lodash-es';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import Pagination from 'components/stateless/Pagination/Pagination';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { COLLATERAL_TYPE } from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import useModal from 'utils/modal/useModal';
import { getProgramTypeText } from 'utils/text';

import useSearchInterfaceByKeyUnionModalController from './useSearchInterfaceByKeyUnionModalController';

interface SearchInterfaceByKeyUnionModalProps {
  modalId: number;
  collateralType: COLLATERAL_TYPE;
  getInterfaceData(data?: DealerAgreementDetailVOModel): void;
}

const SearchInterfaceByKeyUnionModal = ({
  modalId,
  collateralType,
  getInterfaceData,
}: SearchInterfaceByKeyUnionModalProps) => {
  const { t } = useTranslation(['format']);
  const { disableConfirmBtn } = useModal();

  const {
    ANCHOR_AGREEMENT_LIST_TABLE_HEADERS,
    checkedRow,
    checkboxErrorMessage,
    errorMessage,
    financierClientCode,
    anchorAgreementList,
    anchorAgreementListPageable,
    searchedInterfaceData,
    anchorAgreementListPaginate,
    handleCheckBoxInputChange,
    handleFinancierClientCodeChange,
    handleSearchButtonClick,
  } = useSearchInterfaceByKeyUnionModalController(collateralType, modalId);

  useEffect(() => {
    getInterfaceData(searchedInterfaceData);
  }, [getInterfaceData, searchedInterfaceData]);

  useEffect(() => {
    disableConfirmBtn(modalId, isEmpty(searchedInterfaceData));
  }, [disableConfirmBtn, modalId, searchedInterfaceData]);

  return (
    <form data-testid="formId">
      <div className="mb-3">
        <div className="d-flex">
          <div>
            <label className="information-form__label">{t('text:Partner_Client_Code')}</label>
            <input
              className={clsx('information-form__input w-100', {
                'error-input-border text-bold-brick-red': errorMessage !== '',
              })}
              type="text"
              name="dealerFinancierClientCode"
              onChange={handleFinancierClientCodeChange}
              value={financierClientCode || ''}
              placeholder={t('text:Please_type_here')}
            />
          </div>
        </div>
        <div className="text-bold-brick-red m-2">{errorMessage !== '' && errorMessage}</div>

        <div className="mt-3">
          <label className="information-form__label">{t('text:Corresponding_Anchor_Agreement')}</label>
        </div>
        <div className="mt-2" />
        <TableBorder scrollYMaxHeight={200}>
          <TableHeader header={ANCHOR_AGREEMENT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={ANCHOR_AGREEMENT_LIST_TABLE_HEADERS.length}>
            {anchorAgreementList?.content.map((item, index) => (
              <Tr key={index}>
                <Td className="bg-sub100">
                  <div className="text-center">
                    <input
                      className="form-check-input m-0"
                      type="checkbox"
                      checked={checkedRow === index}
                      onChange={() => handleCheckBoxInputChange(index)}
                    />
                  </div>
                </Td>
                <Td data={item.financierClientName} />
                <Td data={item.contractNo} />
                <Td data={getProgramTypeText(item.collateralType)} />
                <Td data={item.currencyType} />
                <Td data={item.startDate} format="date" />
                <Td data={item.expiryDate} format="date" />
                <Td data={item.branchName} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>

        <div className="text-bold-brick-red m-2">{checkboxErrorMessage !== '' && checkboxErrorMessage}</div>

        <Pagination pageable={anchorAgreementListPageable} paginate={anchorAgreementListPaginate} />

        <div className="flex-center mt-4">
          <Button size={ButtonSizeEnum.LG} onClick={handleSearchButtonClick}>
            {t('text:Search')}
          </Button>
        </div>
      </div>

      <div className="division-border" />

      <SectionTitle title={t('text:Result')} />
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormValue
              className="information-form__input"
              label={t('text:Partner_Name')}
              value={searchedInterfaceData?.dealerFinancierClientName}
            />
            <FormValue
              className="information-form__input"
              label={t('text:Tax_Code')}
              value={searchedInterfaceData?.dealerFinancierClientTaxCode}
            />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input"
              label={t('text:Partner_Master_Agreement_Number')}
              value={searchedInterfaceData?.contractNo}
            />
            <FormValue
              col={3}
              className="information-form__input"
              label={t('text:Currency')}
              value={searchedInterfaceData?.currencyType}
            />
            <FormValue
              col={3}
              className="information-form__input"
              label={t('text:Financing_Program')}
              value={getProgramTypeText(searchedInterfaceData?.collateralType)}
            />
          </div>
          <div className="row">
            <FormValue
              col={3}
              className="information-form__input"
              label={t('text:Effective_Date')}
              value={searchedInterfaceData?.startDate}
              format="date"
            />
            <FormValue
              col={3}
              className="information-form__input"
              label={t('text:Expiration_Date')}
              value={searchedInterfaceData?.expiryDate}
              format="date"
            />
            <FormValue
              col={3}
              className="information-form__input"
              label={t('text:Responsible_Branch_Name')}
              value={searchedInterfaceData?.branchName}
            />
          </div>
        </FormContents>
      </FormBorder>
    </form>
  );
};

export default SearchInterfaceByKeyUnionModal;
