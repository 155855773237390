import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import type Pageable from 'models/Pageable';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import type { SuccessArGroupAnchorPartnerAccountVOModel } from 'models/vo/SuccessArGroupAnchorPartnerAccountVO';
import type { SuccessArGroupCurrencyTypeVOModel } from 'models/vo/SuccessArGroupCurrencyTypeVO';
import { requestAnFinancierPotentialPartnerAcquisitionDocumentList } from 'utils/http/api/anonymous/potential-partner-acquisition-documents';
import {
  requestAnSuccessArGroupAnchorPartnerAccount,
  requestAnSuccessArsGroupCurrencyType,
} from 'utils/http/api/anonymous/success-ars';
import useModal from 'utils/modal/useModal';
import { getFormSearchParamValue } from 'utils/searchParams';
import { getPotentialPartnerEmailCode, setPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';

export type apAmountListByAnchorPartnerAccountTypes = {
  anchorPartnerAccountInfo: SuccessArGroupAnchorPartnerAccountVOModel;
  acquisitionDocumentsList: PotentialPartnerAcquisitionDocumentVOModel[] | undefined;
};

interface UploadedPartnerHomeStateType {
  apAmountListByAnchorPartnerAccount: apAmountListByAnchorPartnerAccountTypes[];
  apTotalAmountByCurrency: SuccessArGroupCurrencyTypeVOModel[];
  partnerName: string;
}

export function usePotentialPartnerHomeState() {
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const [dataState, setDataState] = useState<UploadedPartnerHomeStateType>({
    apAmountListByAnchorPartnerAccount: [] as apAmountListByAnchorPartnerAccountTypes[],
    apTotalAmountByCurrency: [] as SuccessArGroupCurrencyTypeVOModel[],
    partnerName: '',
  });

  const emailCode = getPotentialPartnerEmailCode() ? getPotentialPartnerEmailCode() : getFormSearchParamValue('code');
  setPotentialPartnerEmailCode(emailCode as string);

  const fetchAll = async () => {
    if (!emailCode) return modal.show('not found email code');
    let fetchAcquisitionDocumentsOfFirstAnchorPartnerAccount: Pageable<PotentialPartnerAcquisitionDocumentVOModel[]>;

    try {
      const [fetchApAmountByGroupAnchorPartnerAccount, fetchAPAmountByCurrency] = await Promise.all([
        requestAnSuccessArGroupAnchorPartnerAccount(0, 100, emailCode),
        requestAnSuccessArsGroupCurrencyType(0, 100, emailCode),
      ]);

      const firstFinancierIdOfGroupAnchorPartnerAccount =
        fetchApAmountByGroupAnchorPartnerAccount?.content[0]?.financierId;

      if (isNil(firstFinancierIdOfGroupAnchorPartnerAccount))
        modal.show(t('text:There_are_no_valid_Account_Payables_currently'));
      else {
        // 맨 처음 로딩시에는 첫번째 acquisition setting area가 열려 있어야함.
        fetchAcquisitionDocumentsOfFirstAnchorPartnerAccount =
          await requestAnFinancierPotentialPartnerAcquisitionDocumentList(
            0,
            100,
            emailCode,
            firstFinancierIdOfGroupAnchorPartnerAccount,
          );
      }

      const addAcquisitionDocumentListOfFirstElement: apAmountListByAnchorPartnerAccountTypes[] =
        fetchApAmountByGroupAnchorPartnerAccount?.content.map((item, idx) => {
          return {
            anchorPartnerAccountInfo: item,
            acquisitionDocumentsList: idx === 0 ? fetchAcquisitionDocumentsOfFirstAnchorPartnerAccount?.content : [],
          };
        });

      setDataState(prevState => ({
        ...prevState,
        apAmountListByAnchorPartnerAccount: addAcquisitionDocumentListOfFirstElement,
        apTotalAmountByCurrency: fetchAPAmountByCurrency?.content,
        partnerName: fetchApAmountByGroupAnchorPartnerAccount?.content?.[0]?.anchorPartnerName,
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchAcquisitionSetting = async (financierId: number, anchorPartnerAccountId: number) => {
    if (!emailCode) return modal.show('not found email code');

    try {
      // 맨 처음 로딩시에는 첫번째 acquisition setting area가 열려 있어야함.
      const fetchAcquisitionDocuments = await requestAnFinancierPotentialPartnerAcquisitionDocumentList(
        0,
        100,
        emailCode,
        financierId,
      );

      const updateIdx = dataState.apAmountListByAnchorPartnerAccount.findIndex(
        item => item.anchorPartnerAccountInfo.anchorPartnerAccountId === anchorPartnerAccountId,
      );

      // 변경 될 예정
      setDataState(prevState => ({
        ...prevState,

        acquisitionDocumentsList: [
          ...prevState.apAmountListByAnchorPartnerAccount,
          (prevState.apAmountListByAnchorPartnerAccount[updateIdx].acquisitionDocumentsList =
            fetchAcquisitionDocuments.content),
        ],
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  return { state: dataState, fetchAll, fetchAcquisitionSetting };
}
