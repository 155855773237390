import type Pageable from 'models/Pageable';
import type {
  PotentialPartnerAcquisitionDocumentVO,
  PotentialPartnerAcquisitionDocumentVOModel,
} from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import { formattingToPotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import http, { ContentType } from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierPotentialPartnerAcquisitionDocumentRegisterRequest } from './request';

/**
 * desc :: 파트너 제공 문서 정보 조회
 * **/
export async function requestFinancierPotentialPartnerAcquisitionDocumentList(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<PotentialPartnerAcquisitionDocumentVOModel[]>> {
  const response = await http.get<Pageable<PotentialPartnerAcquisitionDocumentVO[]>>({
    url: API_FI.POTENTIAL_PARTNER_ACQUISITION_DOCUMENTS.ACQUISITION_DOCUMENTS,
    data: { pageNumber, rowCount },
  });

  return {
    ...response,
    content: response.content.map((data: PotentialPartnerAcquisitionDocumentVO) =>
      formattingToPotentialPartnerAcquisitionDocumentVOModel(data),
    ),
  };
}

/**
 * desc :: 파트너 제공 문서 정보 추가 / 수정
 * params :: FinancierPotentialPartnerAcquisitionDocumentRegisterRequest
 * **/
export async function requestFinancierPotentialPartnerAcquisitionDocumentRegister(
  data: FinancierPotentialPartnerAcquisitionDocumentRegisterRequest,
): Promise<PotentialPartnerAcquisitionDocumentVOModel> {
  const response = await http.post<PotentialPartnerAcquisitionDocumentVO>({
    url: API_FI.POTENTIAL_PARTNER_ACQUISITION_DOCUMENTS.ACQUISITION_DOCUMENTS,
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToPotentialPartnerAcquisitionDocumentVOModel(response);
}

/**
 * desc :: 파트너 제공 문서 정보 삭제
 * params :: FinancierPotentialPartnerAcquisitionDocumentRegisterRequest
 * **/
export async function requestFinancierPotentialPartnerAcquisitionDocumentDelete(
  potentialPartnerAcquisitionDocumentSettingId: number,
): Promise<void> {
  await http.put<PotentialPartnerAcquisitionDocumentVO>({
    url: API_FI.POTENTIAL_PARTNER_ACQUISITION_DOCUMENTS.ACQUISITION_DOCUMENT_DETAIL(
      potentialPartnerAcquisitionDocumentSettingId,
    ),
    data: potentialPartnerAcquisitionDocumentSettingId,
  });
}

/**
 * desc :: 파트너 제공 문서 템플릿 다운로드
 * params :: potentialPartnerAcquisitionDocumentSettingId
 * **/
export async function requestFinancierPotentialPartnerAcquisitionDocumentDownload(
  potentialPartnerAcquisitionDocumentSettingId: number,
) {
  return await http.download(
    API_FI.POTENTIAL_PARTNER_ACQUISITION_DOCUMENTS.DOWNLOAD_ATTACHMENT(potentialPartnerAcquisitionDocumentSettingId),
  );
}
