import { useTranslation } from 'react-i18next';

import Form, { useFormContext } from 'components/stateless/Form';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { Content, Row, SubTitle } from 'components/templates/section';
import { useUppercaseInput } from 'hooks/useUppercaseInput';

const SecuredDepositAccount = () => {
  const { t } = useTranslation();
  const handleUppercaseInput = useUppercaseInput();

  const {
    methods: { register },
  } = useFormContext();

  return (
    <>
      <SubTitle>{t('text:SECURED_DEPOSIT_ACCOUNT')}</SubTitle>
      <Content>
        <Row singleColumnPosition="top">
          <SectionMessage
            messageType="description"
            message={[t('text:The_Partner_s_security_deposit_will_be_held_in_this_bank_account_by_the_financier')]}
          />
          <Form.Control name="depositCollateralAccountFinancierName">
            <Form.Label>{t('text:Bank_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
          <Form.Control name="depositCollateralAccountBranchName">
            <Form.Label>{t('text:Branch_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
          <Form.Control name="depositCollateralAccount">
            <Form.Label>{t('text:Bank_Account_Number')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
          <Form.Control name="depositCollateralAccountOwner">
            <Form.Label>{t('text:Bank_Account_Holder')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
          <input type="hidden" name="depositCollateralAccountFinancierCode" ref={register} />
          <input type="hidden" name="depositCollateralAccountBranchCode" ref={register} />
        </Row>
      </Content>
    </>
  );
};

export default SecuredDepositAccount;
