import i18n from 'locales/i18n';
import type { InputType } from 'pages/anchor/register-ap/registration/step2/AnchorApRegisterStep2';

import type { ExcelDataType, ExcelTemplatesHeader } from '../types';

const EXCEL_TEMPLATES_HEADER_ROW_NUMBER = 2;

export const excelToJson = async (
  excelFile: File,
  fileUploadMaxRowLength: number = 500,
  bulkUploadMaxRowLength: number = 3000,
  inputType: InputType = 'fileUpload',
): Promise<Array<ExcelDataType<any>[]>> => {
  return new Promise(async (resolve, reject) => {
    const result: ExcelDataType<any>[] = []; // result will be [{ 'Invoice Number' : data, 'Invoice Issued Date':data ... }]
    const header: ExcelTemplatesHeader[] = [];
    const workbook = new (await import('exceljs')).Workbook();

    try {
      await workbook.xlsx.load(await excelFile.arrayBuffer());
      resolve(
        (() => {
          const [worksheet] = workbook.worksheets; // 첫 번째 sheet 선택
          if (
            worksheet.actualRowCount - EXCEL_TEMPLATES_HEADER_ROW_NUMBER > fileUploadMaxRowLength &&
            inputType === 'fileUpload'
          ) {
            // HEADER_ROW_NUMBER 를 빼주는 이유는 메시지(Row1)와 Header(Row2) 제외 하기 위함
            reject(
              `${i18n.t('text:Allowed_N_rows_at_a_time', { number: fileUploadMaxRowLength })} ${i18n.t(
                'text:Please_use_bulk_upload',
              )}`,
            );
          } else if (
            worksheet.actualRowCount - EXCEL_TEMPLATES_HEADER_ROW_NUMBER > bulkUploadMaxRowLength &&
            inputType === 'bulkImport'
          ) {
            reject(
              `${i18n.t('text:Allowed_N_rows_at_a_time', { number: bulkUploadMaxRowLength })} ${i18n.t(
                'text:Please_check_the_data_again',
              )}`,
            );
          }
          // get header :: row2
          worksheet.getRow(EXCEL_TEMPLATES_HEADER_ROW_NUMBER).eachCell(headerCell => {
            header.push(headerCell.value as ExcelTemplatesHeader);
          });

          worksheet.eachRow(function (row, rowNumber) {
            const rowObj: ExcelDataType<any> = {} as ExcelDataType<any>;
            if (rowNumber < EXCEL_TEMPLATES_HEADER_ROW_NUMBER + 1) return;
            else {
              row.eachCell((cell, colNumber) => {
                if (cell.isHyperlink) {
                  rowObj[header[colNumber - 1]] = cell.text;
                } else {
                  rowObj[header[colNumber - 1]] = cell.value;
                }
              });
            }
            result.push(rowObj);
          });

          return [result];
        })(),
      );
    } catch (error) {
      reject(error);
    }
  });
};
