import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormSubtitle } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { DOCUMENT_CONDITION_TYPE, ROLE } from 'enums';
import type { LoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type { LoanAdditionalDocumentConditionVOModel } from 'models/vo/LoanAdditionalDocumentConditionVO';
import { requestDealerLoanAdditionalDocumentAttachmentsDownload } from 'utils/http/api/dealer/loan-additional-document-attachments';
import { requestFinancierLoanAdditionalDocumentAttachmentsDownload } from 'utils/http/api/financier/loan-additional-document-attachments';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

interface RequiredAdditionalDocumentListProps {
  requiredAdditionalDocuments: LoanAdditionalDocumentAttachmentVOModel[];
  isRenderConfirmCheckbox: boolean;
  checkedRequiredDocumentsIds: number[];
  handleRequiredAdditionalDocumentCheckboxChange: (id: number) => void;
}

function RequiredAdditionalDocumentList({
  requiredAdditionalDocuments,
  isRenderConfirmCheckbox,
  checkedRequiredDocumentsIds,
  handleRequiredAdditionalDocumentCheckboxChange,
}: RequiredAdditionalDocumentListProps) {
  const { t } = useTranslation();
  const modal = useModal();
  const isFinancier = getSignIn()?.authorities[0].authority === ROLE.ROLE_FINANCIER;
  const hasRequiredAdditionalDocument = requiredAdditionalDocuments.length > 0;

  const ADDITIONAL_DOCUMENT_TABLE_HEADERS: HeaderType[] = [
    { headerText: t('text:File_Name'), colWidths: 150 },
    { headerText: t('text:Required_condition') },
    { headerText: t('text:Note') },
    { headerText: '', colWidths: 120 },
  ];

  const handleClickDownloadButton = async (loanAdditionalDocumentAttachmentId: number) => {
    try {
      isFinancier
        ? await requestFinancierLoanAdditionalDocumentAttachmentsDownload(loanAdditionalDocumentAttachmentId)
        : await requestDealerLoanAdditionalDocumentAttachmentsDownload(loanAdditionalDocumentAttachmentId);
    } catch (e) {
      modal.show(e);
    }
  };

  const RenderCondition = ({ condition }: { condition: LoanAdditionalDocumentConditionVOModel }) => {
    const conditionTypeText = () => {
      switch (condition.documentConditionType) {
        case DOCUMENT_CONDITION_TYPE.SINGLE_AMOUNT:
          return t('text:each_loan_application');
        case DOCUMENT_CONDITION_TYPE.ACCUMULATE_AMOUNT:
          return t('text:accumulated_loan_including_this_application');
      }
    };

    return (
      <>
        {t(`code:document-condition-amount-range.${condition.documentConditionAmountRange}`)}{' '}
        {t('format:number', { value: condition.amount })} {condition.currencyType} {t('text:for')} {conditionTypeText()}
      </>
    );
  };

  const RequiredAdditionalDocument = ({ document }: { document: LoanAdditionalDocumentAttachmentVOModel }) => {
    const {
      documentFormName,
      documentFileName,
      mandatoryCondition,
      hardCopyCondition,
      documentAttachmentDescription,
      loanAdditionalDocumentAttachmentId,
    } = document;

    return (
      <>
        <FormSubtitle
          title={documentFormName}
          isRequired={mandatoryCondition.required}
          backGroundType={BackGroundType.DarkGray}
        />
        <TableBorder>
          <TableHeader header={ADDITIONAL_DOCUMENT_TABLE_HEADERS} />
          <TableBody numOfCol={ADDITIONAL_DOCUMENT_TABLE_HEADERS.length}>
            <Tr>
              <Td data={documentFileName} />
              <Td>
                {mandatoryCondition.useSpecificCondition && (
                  <li>
                    {t('text:mandatory')} : <RenderCondition condition={mandatoryCondition} />
                  </li>
                )}
                {hardCopyCondition.useSpecificCondition && (
                  <li>
                    {t('text:hard_copy')} : <RenderCondition condition={hardCopyCondition} />
                  </li>
                )}
              </Td>
              <Td data={documentAttachmentDescription} />
              <Td>
                <DownloadButton onClick={() => handleClickDownloadButton(loanAdditionalDocumentAttachmentId)} />
              </Td>
            </Tr>
          </TableBody>
        </TableBorder>
      </>
    );
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Required_Additional_Documents')} />
      {isFinancier && hasRequiredAdditionalDocument && (
        <GuideMessage
          message={[
            t('text:This_partner_has_documents_that_has_to_be_submitted') +
              ' ' +
              t('text:Please_check_the_document_items_below'),
          ]}
          isImportContentArea
        />
      )}
      {hasRequiredAdditionalDocument ? (
        <>
          {requiredAdditionalDocuments?.map((documentItem, index) => (
            <div className="mb-4" key={index}>
              <RequiredAdditionalDocument document={documentItem} />
              {isRenderConfirmCheckbox && documentItem.hardCopyCondition.required && (
                <ConfirmCheckBox
                  checked={checkedRequiredDocumentsIds.includes(documentItem.loanAdditionalDocumentAttachmentId)}
                  id={`required-additional-document-check-${index}`}
                  labelText={
                    isFinancier
                      ? t('text:I_have_received_the_hard_copy_for_this_document')
                      : t('text:I_have_checked_the_information_of_required_additional_documents')
                  }
                  onChangeCheckBox={() =>
                    handleRequiredAdditionalDocumentCheckboxChange(documentItem.loanAdditionalDocumentAttachmentId)
                  }
                />
              )}
            </div>
          ))}
        </>
      ) : (
        <TableBorder>
          <TableHeader header={ADDITIONAL_DOCUMENT_TABLE_HEADERS} />
          <TableBody numOfCol={ADDITIONAL_DOCUMENT_TABLE_HEADERS.length} />
        </TableBorder>
      )}
    </div>
  );
}

export default RequiredAdditionalDocumentList;
