import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestSystemUserDetail,
  requestSystemUserInvite,
  requestSystemUserSuspend,
  requestSystemUserUpdate,
} from 'utils/http/api/system/users';
import type { systemUserUpdateRequest } from 'utils/http/api/system/users/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser, tableValueManage } from 'utils/valueManager/ValueManager';

function SystemFinancierAdminDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { id } = useParams<any>();
  const getProperty = useProperty<systemUserUpdateRequest>();

  // 화면 관련 state
  const [editable, setEditable] = useState<boolean>();
  const [adminUserDetailData, setAdminUserDetailData] = useState<UserVOModel>();

  // useForm
  const { register, getValues, setError, reset, errors, clearErrors } = useForm<systemUserUpdateRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const fetchUserDetail = await requestSystemUserDetail(id);
      ReactDOM.unstable_batchedUpdates(() => {
        setAdminUserDetailData(fetchUserDetail);
        setEditable(false);
        reset({
          employeeCode: tableValueManage(fetchUserDetail?.employeeCode),
          userName: tableValueManage(fetchUserDetail?.userName),
          telephone: tableValueManage(fetchUserDetail?.telephone),
          email: tableValueManage(fetchUserDetail?.email),
        });
      });
    } catch (error) {
      modal.show(error);
    }
  }
  const onClickSave = async (event: any) => {
    event.preventDefault();

    const data = getValues();
    if (adminUserDetailData) data.authorityType = adminUserDetailData?.authorityType;
    try {
      requestDTOParser(data);
      await requestSystemUserUpdate(id, data);
      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => await fetchAll(),
      });
    } catch (e: any) {
      modal.show(e);

      formErrorHandler<systemUserUpdateRequest>(e, setError, clearErrors);
    }
  };

  const handleEdit = () => {
    setEditable(prevState => !prevState);
    reset({
      employeeCode: adminUserDetailData?.employeeCode,
      userName: adminUserDetailData?.userName,
      telephone: adminUserDetailData?.telephone,
      email: adminUserDetailData?.email,
    });
  };

  const onClickSendEmail = () => {
    const showEmailSendConfirmModal = () => {
      modal.show(<h6>{t('text:The_invitation_email_has_been_sent')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          fetchAll();
        },
      });
    };
    modal.show(<h6>{t('text:Would_you_like_to_send_an_invitation_email')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestSystemUserInvite(id);
          showEmailSendConfirmModal();
        } catch (e) {
          modal.show(e);
        }
      },
    });
  };

  const renderGuideMessage = () => {
    return (
      <GuideMessage
        message={[
          t('text:Please_enter_financier_admin_information'),
          <Trans
            key="key"
            i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
            components={{ 1: <span className="asterisk" /> }}
          />,
        ]}
      />
    );
  };
  const handleCancel = (e: any) => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information')}?<br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      },
    );

    const onClickConfirm = () => {
      setEditable(prevState => !prevState);
      reset({
        employeeCode: tableValueManage(adminUserDetailData?.employeeCode),
        userName: tableValueManage(adminUserDetailData?.userName),
        telephone: tableValueManage(adminUserDetailData?.telephone),
        email: tableValueManage(adminUserDetailData?.email),
      });
    };
  };
  // make user Active
  const onMakeUserActive = async (e: any) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_activate_the_user_account')}
        <br />
        {t(
          'text:If_the_account_is_activated_the_user_will_be_able_to_log_in_to_the_platform_and_use_its_features_again',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestSystemUserSuspend(id, false);
            showRequestUserActiveConfirmModal();
          } catch (e) {
            modal.show(e);
          }
        },
      },
    );
    const showRequestUserActiveConfirmModal = () => {
      modal.show(<h6>{t('text:The_user_account_has_been_activated')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          fetchAll();
        },
      });
    };
  };

  // 사용자 이용중지
  const onMakeUserInactive = async (e: any) => {
    e.preventDefault();
    const showRequestUserInactiveConfirmModal = () => {
      modal.show(
        <h6>
          {t('text:The_user_account_has_been_deactivated')}
          <br />
          {t('text:To_reactivate_the_account_click_on_the_Activate_button')}
        </h6>,
        {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => {
            fetchAll();
          },
        },
      );
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_deactivate_the_user_account?')}
        <br />
        {t(
          'text:If_the_account_is_deactivated_the_user_will_not_be_able_to_log_in_to_the_platform_or_use_its_features',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestSystemUserSuspend(id, true);
            showRequestUserInactiveConfirmModal();
          } catch (e) {
            modal.show(e);
          }
        },
      },
    );
  };

  const renderButtons = () => {
    return editable ? (
      <>
        <Button
          size={ButtonSizeEnum.SM}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          onClick={handleCancel}
        >
          {t('text:Cancel')}
        </Button>
        <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
          {t('text:Save')}
        </Button>
      </>
    ) : (
      <>
        {adminUserDetailData?.userStatus === USER_STATUS.SUSPENDED && (
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.BLUE}
            onClick={onMakeUserActive}
          >
            {t('text:Activate')}
          </Button>
        )}
        {adminUserDetailData?.userStatus === USER_STATUS.ACTIVATED && (
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.RED}
            onClick={onMakeUserInactive}
          >
            {t('text:Deactivate')}
          </Button>
        )}

        <Button size={ButtonSizeEnum.SM} onClick={handleEdit} style={{ width: '60px' }}>
          {t('text:Edit')}
        </Button>
      </>
    );
  };

  const showSendEmailButton = (): boolean => {
    return (
      adminUserDetailData?.userStatus === USER_STATUS.REGISTERED ||
      adminUserDetailData?.userStatus === USER_STATUS.INVITED
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financier_Admin_Details')} />
      {editable && renderGuideMessage()}

      <div className="content-area">
        <SectionTitle title={t('text:Financier_Admin_Information')}>{renderButtons()}</SectionTitle>
        <FormBorder>
          <FormSubtitle title={t('text:FINANCIER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput label={t('text:Financier_Name')} value={adminUserDetailData?.enterpriseName ?? ''} disabled />
              <FormInput
                label={t('text:Financier_Code')}
                value={adminUserDetailData?.enterpriseBusinessCode ?? ''}
                disabled
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Registered_Office_Address')}
                value={adminUserDetailData?.enterpriseAddress ?? ''}
                disabled
                col={12}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:ADMIN_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:User_Code')}
                name={getProperty('employeeCode')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.employeeCode}
                disabled={!editable}
              />
              <FormInput
                label={t('text:User_Name')}
                name={getProperty('userName')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.userName}
                disabled={!editable}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Telephone')}
                name={getProperty('telephone')}
                ref={register}
                disabled={!editable}
                error={errors.telephone}
              />
              <FormInput
                label={t('text:Email')}
                name={getProperty('email')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.email}
                disabled={!editable}
              >
                {showSendEmailButton() && !editable && <Button onClick={onClickSendEmail}>{t('text:Send')}</Button>}
              </FormInput>
            </div>
            {!editable && (
              <div className="row">
                <FormInput label={t('text:User_ID')} value={adminUserDetailData?.loginId ?? ''} disabled />
                <FormValue
                  label={t('text:Registration_Status')}
                  value={t(`code:user-status.${adminUserDetailData?.userStatus}`)}
                  className={
                    'information-form__input ' + getStatusTextClass('USER_STATUS', adminUserDetailData?.userStatus)
                  }
                />
              </div>
            )}
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default SystemFinancierAdminDetail;
