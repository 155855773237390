import React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormBorder, FormContents, FormSubtitle } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { DOCUMENT_CONDITION_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { LoanAdditionalDocumentConditionVOModel } from 'models/vo/LoanAdditionalDocumentConditionVO';
import type { LoanAdditionalDocumentVOModel } from 'models/vo/LoanAdditionalDocumentVO';
import { requestDealerLoanAdditionalDocumentDownloadAttachment } from 'utils/http/api/dealer/loan-additional-documents';
import useModal from 'utils/modal/useModal';

interface RequiredDocumentFormsProps {
  documentInfo: LoanAdditionalDocumentVOModel;
  checked: boolean;
  handleCheckboxChange(index: number): void;
  dealerAgreementId: number;
  index: number;
}

function RequiredDocumentForm({
  index,
  documentInfo,
  checked,
  handleCheckboxChange,
  dealerAgreementId,
}: RequiredDocumentFormsProps) {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const { loanAdditionalDocumentId, mandatoryCondition, hardCopyCondition } = documentInfo;
  const [documentFileName, setDocumentFileName] = useState<string | undefined>(undefined);

  const { register, setValue } = useFormContext();

  useEffect(() => {
    if (mounted)
      setValue(`loanAdditionalDocumentAttachments[${index}].loanAdditionalDocumentId`, loanAdditionalDocumentId);
  }, [mounted]);

  const resetSelectedFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setDocumentFileName(undefined);
    setValue(`loanAdditionalDocumentAttachments[${index}].attachment`, undefined);
  };

  const handleClickDownloadFormButton = async () => {
    try {
      await requestDealerLoanAdditionalDocumentDownloadAttachment(loanAdditionalDocumentId, {
        dealerAgreementId,
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const RenderCondition = ({ condition }: { condition: LoanAdditionalDocumentConditionVOModel }) => {
    const conditionTypeText = () => {
      switch (condition.documentConditionType) {
        case DOCUMENT_CONDITION_TYPE.SINGLE_AMOUNT:
          return t('text:each_loan_application');
        case DOCUMENT_CONDITION_TYPE.ACCUMULATE_AMOUNT:
          return t('text:accumulated_loan_including_this_application');
      }
    };

    return (
      <>
        {t(`code:document-condition-amount-range.${condition.documentConditionAmountRange}`)}{' '}
        {t('format:number', { value: condition.amount })} {condition.currencyType} {t('text:for')} {conditionTypeText()}
      </>
    );
  };

  const showFileUploadForm = !(hardCopyCondition.achieved && !mandatoryCondition.achieved);

  const FormattedTextArea = ({ text }: { text: string }) => {
    const lines = text.replace(/\r\n/g, '<br/>').split(/<br\s*\/?>/i);

    return (
      <p>
        {lines.map((line, index, array) => (
          <React.Fragment key={index}>
            {line}
            {index < array.length - 1 && <br />}
          </React.Fragment>
        ))}
      </p>
    );
  };

  return (
    <div className="mt-4">
      <FormBorder hideBorderBottom={showFileUploadForm}>
        <FormSubtitle
          title={documentInfo.documentFormName}
          isRequired={mandatoryCondition.required && mandatoryCondition.achieved}
        >
          <div className="flex-end align-items-center me-3">
            <Button
              style={{ height: '25px' }}
              className="flex-column-center text-bold"
              onClick={handleClickDownloadFormButton}
            >
              {t('text:Download_Form')}
            </Button>
          </div>
        </FormSubtitle>
        <FormContents backGroundType={BackGroundType.WHITE}>
          {mandatoryCondition.achieved && mandatoryCondition.useSpecificCondition && (
            <p className="text-brick-red pb-3">
              {t('text:Mandatory_Required_condition')} : <RenderCondition condition={mandatoryCondition} />
            </p>
          )}
          {hardCopyCondition.achieved && hardCopyCondition.useSpecificCondition && (
            <p className="text-brick-red pb-3">
              {t('text:Hard_copy_Required_condition')} : <RenderCondition condition={hardCopyCondition} />
            </p>
          )}
          <FormattedTextArea text={documentInfo.noticeForPartner} />
        </FormContents>
      </FormBorder>
      {showFileUploadForm && (
        <FormBorder hideBorderBottom={hardCopyCondition.required}>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <>
              <div className="mb-4">{t('text:Upload_any_required_additional_documents_below')}</div>
              <div className="row">
                <input
                  hidden
                  name={`loanAdditionalDocumentAttachments[${index}].loanAdditionalDocumentId`}
                  ref={register}
                  type="number"
                />
                <div className="col-6 d-flex align-items-center">
                  <input
                    hidden
                    type="file"
                    name={`loanAdditionalDocumentAttachments[${index}].attachment`}
                    ref={register}
                    id={`loanAdditionalDocumentAttachments[${index}]`}
                    onChange={(e: any) => {
                      if (e.target?.files[0]?.name) setDocumentFileName(e.target?.files[0]?.name);
                    }}
                  />

                  <label
                    htmlFor={`loanAdditionalDocumentAttachments[${index}]`}
                    className="attach-file-link-button me-3"
                  >
                    {t('text:Attach_File')}
                  </label>
                  <div id="fileName" className="upload-file-input">
                    {documentFileName ? documentFileName : t('text:No_file_attached')}
                  </div>
                  <IconButton
                    onClick={resetSelectedFile}
                    className="delete-uploaded-excel-button ms-2"
                    hidden={!documentFileName}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </IconButton>
                </div>
                <div className="col-6 d-flex justify-content-between">
                  <input
                    type="text"
                    name={`loanAdditionalDocumentAttachments[${index}].description`}
                    placeholder={t('text:Please_note_here')}
                    className="bg-sub100 p-2 w-100 me-3"
                    ref={register}
                  />
                </div>
              </div>
            </>
          </FormContents>
        </FormBorder>
      )}
      {documentInfo.hardCopyCondition.required && (
        <ConfirmCheckBox
          id={`required-documents-confirm-checkbox-${loanAdditionalDocumentId}`}
          checked={checked}
          onChangeCheckBox={() => handleCheckboxChange(loanAdditionalDocumentId)}
          labelText={t('text:I_have_checked_this_document_has_to_be_delivered_as_hard_copy')}
        />
      )}
    </div>
  );
}

export default RequiredDocumentForm;
