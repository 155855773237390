import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { getDate, getDay, getMonth, getYear } from 'date-fns';
import { range } from 'lodash-es';

import IconButton from 'components/stateless/Button/IconButton';
import CustomSelect from 'components/stateless/CustomSelect/CustomSelect';

interface CustomHeaderProps extends ReactDatePickerCustomHeaderProps {
  handleSelectToday(): void;
  todayDisabled: boolean;
}

const CustomHeader = (headerProps: CustomHeaderProps) => {
  const {
    date,
    decreaseMonth,
    increaseMonth,
    changeMonth,
    changeYear,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
    handleSelectToday,
    todayDisabled,
  } = headerProps;
  const { t } = useTranslation(['format']);

  const days = [
    t('text:Sun'),
    t('text:Mon'),
    t('text:Tue'),
    t('text:Wed'),
    t('text:Thu'),
    t('text:Fri'),
    t('text:Sat'),
  ];
  const today = new Date();
  const months = [
    t('text:January'),
    t('text:February'),
    t('text:March'),
    t('text:April'),
    t('text:May'),
    t('text:June'),
    t('text:July'),
    t('text:August'),
    t('text:September'),
    t('text:October'),
    t('text:November'),
    t('text:December'),
  ];
  const years = range(getYear(new Date()) - 3, getYear(new Date()) + 3, 1);

  return (
    <>
      <div className="wrap-custom-header">
        <IconButton onClick={decreaseMonth} type="button" disabled={prevMonthButtonDisabled}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </IconButton>

        <CustomSelect
          options={months}
          value={months[getMonth(date)]}
          getCurrentViewFunc={month => <div>{month}</div>}
          getOptionViewFunc={month => <div onClick={() => changeMonth(months.indexOf(month))}>{month}</div>}
        />
        <CustomSelect
          options={years}
          value={getYear(date)}
          getCurrentViewFunc={year => <div>{year}</div>}
          getOptionViewFunc={year => <div onClick={() => changeYear(year)}>{year}</div>}
        />
        <IconButton onClick={increaseMonth} type="button" disabled={nextMonthButtonDisabled}>
          <FontAwesomeIcon icon={faAngleRight} />
        </IconButton>
      </div>
      <div
        className={clsx('today-box', {
          disabled: todayDisabled,
        })}
        onClick={handleSelectToday}
      >
        {t('text:Today_day_month_date_year', {
          day: days[getDay(today)],
          month: months[getMonth(today)],
          date: getDate(today),
          year: getYear(today),
        })}
      </div>
    </>
  );
};

export default CustomHeader;
