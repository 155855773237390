import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface FactoringNoticeRelatedSuccessArVO {
  successArId: number;
  arNumber: string;
  anchorName: string;
  partnerName: string;
  arAmount: number;
  currencyType: string;
  settlementDate: string;
}

export interface FactoringNoticeRelatedSuccessArVOModel extends FactoringNoticeRelatedSuccessArVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToFactoringNoticeRelatedSuccessArVOModel(
  data: FactoringNoticeRelatedSuccessArVO,
): FactoringNoticeRelatedSuccessArVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
