import clsx from 'clsx';

import GuideMessage from './GuideMessage';
import './GuideMessage.scss';
import { BackGroundType } from '../CommonForm/FormSubtitle';

import type { GuideMessagePropsType } from './GuideMessage';

export interface FormGuideMessagePropsType extends GuideMessagePropsType {
  backgroundType?: BackGroundType;
}

export const FormGuideMessage = ({
  backgroundType = BackGroundType.Gray,
  ...guideMessageProps
}: FormGuideMessagePropsType) => {
  return (
    <div
      className={clsx('form-guide-message-container', {
        [backgroundType]: backgroundType,
      })}
    >
      <GuideMessage {...guideMessageProps} useContentAreaClassName={false} />
    </div>
  );
};
