import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { removeRangeFromHoliday } from 'utils/date/date';

import type { DealerArBulkApplicationStep2State } from '../../useDealerArBulkFinancingApplicationStep2State';

dayjs.extend(isBetween);
interface useFinancingRequestProps {
  dealerArBulkApplicationStep2State: DealerArBulkApplicationStep2State;
  disbursementDate?: string;
  totalRequestedAmount: string;
}

export function useFinancingRequest({
  dealerArBulkApplicationStep2State,
  disbursementDate,
  totalRequestedAmount,
}: useFinancingRequestProps) {
  const { t } = useTranslation(['format']);

  const { dealerAgreementDetail, financierHoliday, maxDate } = dealerArBulkApplicationStep2State;

  const disabledRanges = useMemo((): Date[] | [] => {
    if (maxDate) {
      return [...removeRangeFromHoliday(financierHoliday?.content), maxDate && new Date(maxDate)] as Date[];
    } else {
      return removeRangeFromHoliday(financierHoliday?.content);
    }
  }, [maxDate, financierHoliday?.content]);

  const disabledEstimateButton = (): boolean => {
    if (!disbursementDate) {
      return true;
    }

    if (!totalRequestedAmount || totalRequestedAmount === '0') {
      return true;
    }

    return false;
  };

  const returnAmountWithCurrency = (amount: number | string | undefined) => {
    return `${t('format:number', {
      value: amount,
    })} ${dealerAgreementDetail?.currencyType}`;
  };

  return {
    disabledRanges,
    disabledEstimateButton,
    returnAmountWithCurrency,
  };
}
