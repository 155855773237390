import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { COMMON_APPROVAL_TYPE } from 'enums';
import { ModalSize } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import useExtraInformationViewModel from '../../extraInformation/useExtraInformationViewModel';
import useWaitingFinancingOptionViewModel from '../../models/waitingFinancingOption/useWaitingFinancingOptionViewModel';

const useAgreementRevisionHistoryController = () => {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const { anchorFinancingOptionId } = useExtraInformationViewModel();
  const { waitingFinancingOption, waitingFinancingOptionPageable, fetchWaitingFinancingOption } =
    useWaitingFinancingOptionViewModel();

  const handleApprovalStatusQuestionButtonClick = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const REVISION_HISTORY_LIST_HEADERS = [
    {
      headerText: t('text:Edited_Time'),
      colWidths: 120,
    },
    {
      headerText: t('text:Branch_Code'),
      colWidths: 150,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: handleApprovalStatusQuestionButtonClick,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 200,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 200,
    },
  ];

  const waitingFinancingOptionPaginate = async (selectedPageNumber: number, selectedRowCount: number) => {
    await fetchWaitingFinancingOption(anchorFinancingOptionId, selectedPageNumber, selectedRowCount);
  };

  const resetSection = useCallback(async () => {
    if (isNil(anchorFinancingOptionId)) return;

    await fetchWaitingFinancingOption(anchorFinancingOptionId);
  }, [anchorFinancingOptionId]);

  useEffect(() => {
    resetSection();
  }, [resetSection]);

  return {
    REVISION_HISTORY_LIST_HEADERS,
    waitingFinancingOption,
    waitingFinancingOptionPageable,
    waitingFinancingOptionPaginate,
  };
};

export default useAgreementRevisionHistoryController;
