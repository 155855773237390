import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import type { WaitingAnchorDealers } from 'utils/http/api/anchor/waiting-anchor-dealers/request';

interface AnchorDealerRegistrationConfirmModalProps {
  waitingAnchorDealers: WaitingAnchorDealers[];
  isFinancier?: boolean; // anchor: dealer, financier: uploaded dealer -> 용어 차이 때문에 prop 추가
}

function AnchorDealerRegistrationConfirmModal({
  waitingAnchorDealers,
  isFinancier = false,
}: AnchorDealerRegistrationConfirmModalProps) {
  const { t } = useTranslation(['format']);
  const tableHeaders: HeaderType[] = [
    {
      headerText: isFinancier ? t('text:Uploaded_Dealer_Name') : t('text:Dealer_Name'),
    },
    {
      headerText: isFinancier ? t('text:Uploaded_Dealer_Tax_Code') : t('text:Dealer_Tax_Code'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
  ];

  return (
    <>
      <h6>
        {t('text:Would_you_like_to_register_the_Dealers_you_entered_below?')}
        <br />
        {t('text:Please_check_the_information_you_entered_below')}
      </h6>
      <br />
      <ul className="invoice-registration-sum">
        <li>
          • {t('text:Total_Number_of_Registration_Requested')} :{' '}
          <strong>{t('format:number', { value: waitingAnchorDealers.length })}</strong>
        </li>
      </ul>
      <br />
      <TableBorder>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>
          {waitingAnchorDealers.map((item, index) => (
            <Tr key={index}>
              <Td data={item.name} />
              <Td data={item.taxCode} />
              <Td data={item.representativeName} />
              <Td data={item.representativeEmail} />
              <Td data={item.telephone} />
              <Td data={item.address} />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
    </>
  );
}

export default AnchorDealerRegistrationConfirmModal;
