import { useFinancierSettingContext } from './Context';

const useFinancierSettingViewModel = () => {
  const { potentialPartnerRegistrable, divisionRegistrable, fetchFinancierSetting, financierName, factoringEnable } =
    useFinancierSettingContext();

  return {
    potentialPartnerRegistrable,
    divisionRegistrable,
    fetchFinancierSetting,
    financierName,
    factoringEnable,
  };
};

export default useFinancierSettingViewModel;
