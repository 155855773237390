import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import dayjs from 'dayjs';

import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import type { UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import { getLocalizedFullMonth } from 'utils/date/date';

type UsageTableProps = {
  usageData: UsageSummaryVOModel | UsageSummaryVOModel[] | undefined;
  isThisMonthSummary: boolean;
};

function UsageTable({ usageData, isThisMonthSummary }: UsageTableProps) {
  const { t } = useTranslation();

  const tableHeaders: HeaderType[] = useMemo(
    () => [
      {
        headerText: t('text:Month'),
        colWidths: 120,
      },
      {
        headerText: t('text:API_Requests'),
        colWidths: 120,
      },
      {
        headerText: t('text:Active_Users'),
        colWidths: 120,
      },
      {
        headerText: t('text:Active_Clients'),
        colWidths: 120,
      },
      {
        headerText: t('text:Performance_Fee'),
        colWidths: 120,
      },
      {
        headerText: t('text:Notifications'),
        colWidths: 120,
      },
      {
        headerText: t('text:Storage'),
        colWidths: 120,
      },
      {
        headerText: t('text:Platform_Usage_Fee'),
        colWidths: 120,
      },
    ],
    [t],
  );

  const usageDataList = useMemo(
    () => (Array.isArray(usageData) ? usageData : usageData === undefined ? undefined : [usageData]),
    [usageData],
  );
  const fontWeightStyle = clsx({ 'text-bold': isThisMonthSummary });

  return (
    <TableBorder>
      <TableHeader header={tableHeaders} />
      <TableBody numOfCol={tableHeaders.length}>
        {usageDataList?.map(item => (
          <Tr
            key={isThisMonthSummary ? item.enterpriseId : item.billingId}
            className={isThisMonthSummary ? 'main100' : ''}
          >
            <Td
              className={fontWeightStyle}
              data={`${getLocalizedFullMonth(dayjs(item.fromDate).format('MMMM'))} ${dayjs(item.fromDate).format(
                'YYYY',
              )}`}
            />
            <Td className={fontWeightStyle} data={item.apiUsage} format="number" right={t('text:Requests')} />
            <Td className={fontWeightStyle} data={item.userUsage} format="number" right={t('text:Users')} />
            <Td className={fontWeightStyle} data={item.clientUsage} format="number" right={t('text:Clients')} />
            <Td className={fontWeightStyle} data={item.loanUsageAmount} format="number" left="USD" />
            <Td className={fontWeightStyle} data={item.notificationUsage + item.emailUsage} format="number" />
            <Td className={fontWeightStyle} data={item.storageUsage} format="number" right="MB" />
            <Td className="text-green text-bold" data={item.totalAmount} format="number" left="USD" />
          </Tr>
        ))}
      </TableBody>
    </TableBorder>
  );
}

export default UsageTable;
