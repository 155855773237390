import { CURRENCY_TYPE, SUCCESS_AR_STATUS, stringToEnum } from 'enums';

export interface SuccessArDetailVO {
  successArId: number;
  arSummaryId: number;
  createdDateTime: string;
  updatedDateTime: string;
  arNumber: string;
  arAmount: number;
  arIssuedDate: string;
  settlementDate: string;
  maturityDate: string;
  anchorPartnerName: string;
  anchorPartnerTaxCode: string;
  currencyType: string;
  successArStatus: string;
  anchorAgreementId: number;
  anchorContractNo: string;
  dealerAgreementId: number;
  dealerContractNo: string;
  paymentSupport: boolean;
  loanGiven: boolean;
  blockedByFinancier: boolean;
  blockedReason: string;
  financierId: number;
  financierName: string;
  anchorSettlementBankCode: string;
  anchorSettlementBankName: string;
  anchorSettlementBranchCode: string;
  anchorSettlementBranchName: string;
  anchorSettlementAccount: string;
  anchorSettlementAccountOwner: string;
  partnerRequestedBankCode: string;
  partnerRequestedBankName: string;
  partnerRequestedBranchCode: string;
  partnerRequestedBranchName: string;
  partnerRequestedAccount: string;
  partnerRequestedAccountOwner: string;
  partnerSettlementBankCode: string;
  partnerSettlementBankName: string;
  partnerSettlementBranchCode: string;
  partnerSettlementBranchName: string;
  partnerSettlementAccount: string;
  partnerSettlementAccountOwner: string;
  principalRepaymentBankCode: string;
  principalRepaymentBankName: string;
  principalRepaymentBranchCode: string;
  principalRepaymentBranchName: string;
  principalRepaymentAccount: string;
  principalRepaymentAccountOwner: string;
  settledAnchorEnterpriseName: string;
  settledAnchorBankCode: string;
  settledAnchorBankName: string;
  settledAnchorAccount: string;
  settledAnchorAccountOwner: string;
  settledPartnerName: string;
  settledPartnerBankCode: string;
  settledPartnerBankName: string;
  settledPartnerBranchCode: string;
  settledPartnerBranchName: string;
  settledPartnerAccount: string;
  settledPartnerAccountOwner: string;
  anchorName: string;
  scheduledDisbursementDate: string;
  settledDateTime: string;
  remainingCollateralAmount: number;
  arCommissionAmount: number;
}

export interface SuccessArDetailVOModel extends SuccessArDetailVO {
  currencyType: CURRENCY_TYPE;
  successArStatus: SUCCESS_AR_STATUS;
}

export function formattingToSuccessArDetailVOModel(data: SuccessArDetailVO): SuccessArDetailVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    successArStatus: stringToEnum(SUCCESS_AR_STATUS, data.successArStatus),
  };
}
