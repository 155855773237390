import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { LOAN_STATUS } from 'enums';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';

interface DealerFinancingDetailGuideMessageProps {
  data: LoanDetailVOModel;
  isAuthorizerRequest: boolean;
  useLoanResultInterface: boolean;
  onClickUpdateTransaction: (e: any) => void;
}

function DealerFinancingDetailGuideMessage({
  data,
  isAuthorizerRequest,
  useLoanResultInterface,
  onClickUpdateTransaction,
}: DealerFinancingDetailGuideMessageProps) {
  const { t } = useTranslation(['format']);

  const renderIsNotAuthorizerRequestGuideMessage = (code: LOAN_STATUS | undefined) => {
    switch (code) {
      case LOAN_STATUS.DEALER_CANCELLED:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_application_has_been_cancelled'),
              t('text:Please_check_the_reason_for_the_cancellation_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_CANCELLATION')}
            reason={data.cancelReason}
          />
        );
      case LOAN_STATUS.REJECTED:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_application_has_been_rejected'),
              t('text:Please_check_the_reason_below_and_contact_the_responsible_branch_for_further_details'),
            ]}
            reasonTitle={t('text:REASON_FOR_REJECTION')}
            reason={data.rejectReason}
          />
        );
      case LOAN_STATUS.EXPIRATION:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:Financing_has_expired'),
              t('text:Please_check_the_reason_below_and_contact_the_responsible_branch_for_further_details'),
            ]}
            reasonTitle={t('text:REASON_FOR_EXPIRATION')}
            reason={data.expirationReason}
          />
        );
      default:
        return null;
    }
  };

  const renderUpdateTransactionGuideMessage = (loanStatus: LOAN_STATUS | undefined) => {
    if (
      useLoanResultInterface &&
      (loanStatus === LOAN_STATUS.APPROVED || loanStatus === LOAN_STATUS.DISBURSED || loanStatus === LOAN_STATUS.REPAID)
    ) {
      return (
        <GuideMessage
          message={[
            t('text:Click_on_the_Update_Transaction_button_to_reflect_the_changes_to_the_financing_information'),
          ]}
        >
          {{
            button: (
              <Button size={ButtonSizeEnum.SM} onClick={onClickUpdateTransaction}>
                {t('text:Update_Transaction')}
              </Button>
            ),
          }}
        </GuideMessage>
      );
    }

    if (loanStatus === LOAN_STATUS.OVERDUE) {
      if (useLoanResultInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_repayment_is_delinquent'),
              t('text:Click_on_the_Update_Transaction_button_to_reflect_the_changes_to_the_financing_information'),
            ]}
          >
            {{
              button: (
                <Button size={ButtonSizeEnum.SM} onClick={onClickUpdateTransaction}>
                  {t('text:Update_Transaction')}
                </Button>
              ),
            }}
          </GuideMessage>
        );
      } else {
        return (
          <GuideMessage messageType={MessageType.ALERT} message={[t('text:The_financing_repayment_is_delinquent')]} />
        );
      }
    }
  };

  return (
    <>
      {isAuthorizerRequest ? (
        <GuideMessage
          message={[
            t('text:The_operator_has_requested_approval_for_the_financing_application_below'),
            t(
              'text:After_reviewing_the_financing_request_information_and_the_attached_files_approve_and_submit_the_financing_application_to_the_Financier',
            ),
          ]}
        />
      ) : (
        renderIsNotAuthorizerRequestGuideMessage(data.loanStatus)
      )}
      {renderUpdateTransactionGuideMessage(data.loanStatus)}
    </>
  );
}

export default DealerFinancingDetailGuideMessage;
