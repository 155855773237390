import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import type { SignInModel } from 'models/SignInModel';

interface SignInState {
  storeSignIn: SignInModel | null;
  setStoreSignIn: (signInModel: SignInModel) => void;
  removeStoreSignIn: () => void;
}

export const useSignInStore = create<SignInState>()(
  devtools(
    persist(
      set => ({
        storeSignIn: null,
        setStoreSignIn: signInModel => set({ storeSignIn: signInModel }),
        removeStoreSignIn: () => set({ storeSignIn: null }),
      }),
      {
        name: 'STORE_SIGN_IN',
      },
    ),
  ),
);
