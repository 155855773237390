import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

// 인증 코드 요청

export async function requestOtpSend(code: string) {
  return await http.post<string>({
    url: API_AN.OTP.SEND_CODE,
    data: { code },
  });
}
