import { useTranslation } from 'react-i18next';

import { FormBorder, FormSelect } from 'components/stateless/CommonForm';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { ROLE } from 'enums';
import type { FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import { getSignIn } from 'utils/storage/LocalStorage';

import { Utils } from '../bkav/Utils';

interface DigitalSignatureAuthModalProps {
  loanAgreementAttachment: string;
  loanFactoringNotificationAttachment: string;
}

const PDF_DATA_PREFIX = 'data:application/pdf;base64,';

function DigitalSignatureAuthModal({
  loanAgreementAttachment,
  loanFactoringNotificationAttachment,
}: DigitalSignatureAuthModalProps) {
  const { t } = useTranslation();
  const role = getSignIn()?.authorities[0].authority;
  const financierMessage = [
    t(
      'text:Once_the_digital_signatures_for_the_two_documents_above_are_completed_the_financing_approval_will_be_finalized',
    ),
    t('text:After_reviewing_the_documents_please_select_a_certificate_and_proceed_with_the_digital_signature'),
  ];
  const partnerMessage = [
    t(
      'text:Once_the_digital_signatures_for_the_two_documents_above_are_completed_the_financing_application_to_the_financier_will_be_finalized',
    ),
    t('text:After_reviewing_the_documents_please_select_a_certificate_and_proceed_with_the_digital_signature'),
  ];

  return (
    <FormBorder>
      <input type="hidden" id="selectCertIssuer" />
      <input type="hidden" id="selectCertSerial" />
      <input type="hidden" id="selectCertExpire" />
      <div className="row flex-grow-1">
        <div className="col-6">
          <label className="information-form__label">{t('text:APPENDIX_OF_DEBT_SALE_AND_PURCHASE_AGREEMENT')}</label>
          <iframe className="ds-pdf-viewer" src={`${PDF_DATA_PREFIX}${loanAgreementAttachment}`} />
        </div>
        <div className="col-6">
          <label className="information-form__label">{t('text:DEBT_SELLING_NOTIFICATION')}</label>
          <iframe className="ds-pdf-viewer" src={`${PDF_DATA_PREFIX}${loanFactoringNotificationAttachment}`} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <SectionMessage
            message={role === ROLE.ROLE_FINANCIER ? financierMessage : partnerMessage}
            className="h-100"
          />
        </div>
        <FormSelect
          col={6}
          id="selectCert"
          name="cert"
          label={t('text:Certificate')}
          placeholderOptions={{ show: true }}
          selectOptions={[]}
          onChange={Utils.selectChange}
        />
        {/*  //selectOptions 빈배열 넣은 부분 후에 인증서 목록 jQuery가 아닌 다른 방식으로 핸들링하면 변경 */}
      </div>
    </FormBorder>
  );
}

export default DigitalSignatureAuthModal;
