export interface PotentialPartnerVO {
  potentialPartnerId: number;
  potentialPartnerName: string;
  potentialPartnerTaxCode: string;
  potentialPartnerBusinessCode: string;
  potentialPartnerTelephone: string;
  potentialPartnerFax: string;
  potentialPartnerRepresentativeTitle: string;
  potentialPartnerRepresentativeEmail: string;
  potentialPartnerRepresentativeName: string;
  potentialPartnerAddress: string;
  anchorPartnerId: number;
  anchorPartnerName: string;
  anchorPartnerTaxCode: string;
  anchorPartnerBusinessCode: string;
  anchorPartnerTelephone: string;
  anchorPartnerFax: string;
  anchorPartnerRepresentativeTitle: string;
  anchorPartnerRepresentativeEmail: string;
  anchorPartnerRepresentativeName: string;
  anchorPartnerAddress: string;
}

export interface PotentialPartnerVOModel extends PotentialPartnerVO {}

export function formattingToPotentialPartnerVOModel(data: PotentialPartnerVO): PotentialPartnerVOModel {
  return {
    ...data,
  };
}
