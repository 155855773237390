import { memo, useCallback, useEffect, useState } from 'react';
import type { UseFormMethods } from 'react-hook-form';

import { countBy, groupBy, mapValues, values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ToggleButton from 'components/stateless/Button/ToggleButton';
import Exporter from 'components/stateless/Exporter/Exporter';
import { TableBorder } from 'components/stateless/Table';
import type { CURRENCY_TYPE } from 'enums';
import type { BulkImportErrorObjArr } from 'utils/error/bulkImportManager';
import type { AnchorArListElementType } from 'utils/http/api/anchor/ar-summaries/request';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import ApInputBulkTableBody from './ApInputBulkImportTableBody';

import type { FieldValuesType } from './ApInputBulkTable';
import type { TFunction } from 'i18next';
interface APInputBulkImportTableProps {
  errors: UseFormMethods<FieldValuesType>['errors'];
  register: UseFormMethods<FieldValuesType>['register'];
  originalExcelInfos?: AnchorArListElementType[];
  rows?: string[];
  currencyType?: CURRENCY_TYPE;
  totalApAmount: string;
  t: TFunction;
  holidays: string[];
  minDate: Date;
  maxDate: Date;
}

interface APBulkTableData {
  anchorPartnerTaxCode: string;
  arNumber: string;
  arIssuedDate: string;
  settlementDate: string;
  arAmount: string | number;
  rowIndex: number;
}

export interface InvalidExcelInfoType extends AnchorArListElementType {
  rowIndex: number;
}

const getFieldNames = (t: TFunction, options?: { currencyType?: CURRENCY_TYPE }) => {
  return {
    partnerTaxCode: {
      name: t('text:Partner_Tax_Code'),
    },
    arNumber: {
      name: t('text:AP_Number(Tax_Invoice_Number)'),
    },
    arIssuedDate: {
      name: t('text:AP_Issued_Date'),
    },
    settlementDate: {
      name: t('text:Settlement_Date'),
    },
    arAmount: {
      name: `${t('text:AP_Amount')} (${t('text:unit')}: ${options?.currencyType ?? ''})`,
    },
  };
};

const ApInputBulkImportTable = ({
  errors,
  register,
  originalExcelInfos,
  rows,
  currencyType,
  totalApAmount,
  t,
  holidays,
  minDate,
  maxDate,
}: APInputBulkImportTableProps) => {
  const modal = useModal();
  const fieldNames = getFieldNames(t, { currencyType });
  const [turnOnInvalidFilter, setTurnOnInvalidFilter] = useState(false);
  const [invalidExcelInfos, setInvalidExcelInfos] = useState<InvalidExcelInfoType[]>();
  useEffect(() => {
    if (originalExcelInfos) {
      const tempExcelInfos = originalExcelInfos.reduce((result: InvalidExcelInfoType[], excelInfo, index) => {
        if (errors?.arList?.[index]) {
          result.push({ ...excelInfo, rowIndex: index });
        }

        return result;
      }, []);
      setInvalidExcelInfos(tempExcelInfos);
    }
  }, [originalExcelInfos, errors]);

  const handleToggleClick = () => {
    setTurnOnInvalidFilter(prevState => !prevState);
  };

  const isApNumberDuplicated = useCallback(
    (partnerTaxCode: string, arNumber: string): boolean | string => {
      // 모든 partnerTaxCode를 소문자로 변환
      const lowercasedPartnerTaxCodeOriginalExcelInfos = originalExcelInfos?.map(info => ({
        ...info,
        partnerTaxCode: String(info.partnerTaxCode).toLowerCase(),
      }));

      const partnerTaxCodeGroupedByArNumbers = mapValues(
        groupBy(lowercasedPartnerTaxCodeOriginalExcelInfos, 'partnerTaxCode'),
        item => item.map(({ arNumber }) => arNumber),
      );

      // 입력된 partnerTaxCode도 소문자로 변환
      const lowercasedPartnerTaxCode = String(partnerTaxCode).toLowerCase();
      const targetArNumbers = partnerTaxCodeGroupedByArNumbers[lowercasedPartnerTaxCode];
      const arNumbers = targetArNumbers.filter(item => item);
      const countedArNumbers = countBy(arNumbers);

      return countedArNumbers[arNumber] <= 1 ? true : 'text:Fail_Duplicate_AP_Number';
    },
    [originalExcelInfos],
  );

  const handleExportButton = async () => {
    if (rows && rows.length > 0 && originalExcelInfos) {
      try {
        const jsonArrayData: APBulkTableData[] = originalExcelInfos.map((item, index) => ({
          rowIndex: index + 1,
          anchorPartnerTaxCode: tableValueManage(item.partnerTaxCode),
          arNumber: tableValueManage(item.arNumber),
          arIssuedDate: tableValueManage(item.arIssuedDate),
          settlementDate: tableValueManage(item.settlementDate),
          arAmount: tableValueManage(item.arAmount),
        }));

        const excelColumns: ColumnOption<APBulkTableData>[] = [
          {
            header: 'No',
            key: 'rowIndex',
            width: 20,
          },
          {
            header: 'Partner Tax Identification Number',
            key: 'anchorPartnerTaxCode',
            width: 40,
          },
          {
            header: ExcelTemplatesHeader.AP_NUMBER,
            key: 'arNumber',
            width: 30,
          },
          {
            header: ExcelTemplatesHeader.AP_ISSUED_DATE,
            key: 'arIssuedDate',
            width: 30,
          },
          {
            header: ExcelTemplatesHeader.SETTLEMENT_DATE,
            key: 'settlementDate',
            width: 30,
          },
          {
            header: ExcelTemplatesHeader.AP_AMOUNT,
            key: 'arAmount',
            width: 30,
          },
        ];

        const excelExporterProps: ExportSpreadSheetProps<any> = {
          jsonArrayData,
          columns: excelColumns,
          errors: errors.arList as BulkImportErrorObjArr,
          options: {
            rowHeight: [{ position: 1, height: 15 }],
          },
          bulkImportOptions: {
            minDate,
            maxDate,
          },
        };
        showLoadingUI();
        modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} />, {
          title: t('text:Export_File'),
          closeBtnText: t('text:Close'),
        });
      } catch (e) {
        modal.show(e);
      } finally {
        unShowLoadingUI();
      }
    }
  };

  return (
    <form>
      {rows && rows.length > 0 && (
        <div className="d-flex pb-3" data-testid="button-groups">
          <Button
            onClick={handleExportButton}
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.PRIMARY}
          >
            {t('text:Export')}
          </Button>
          <div className="d-flex align-items-center ps-3">
            <ToggleButton isActive={turnOnInvalidFilter} onClick={handleToggleClick} />
            <p className="ms-3">{t('text:Show_only_invalid_APs')}</p>
          </div>
        </div>
      )}
      <TableBorder scrollYMaxHeight={1000} data-testid="register-table">
        <colgroup>
          <col style={{ width: '60px' }} />
          {values(fieldNames).map((_, index) => (
            <col key={index} />
          ))}
          {rows && rows.length > 0 && <col />}
        </colgroup>
        <thead>
          <tr>
            <th scope="col" />
            {values(fieldNames).map(({ name }, index) => (
              <th key={index} scope="col">
                {name}
              </th>
            ))}
            {rows && rows.length > 0 && <th scope="col">{t('text:Validation_Result')}</th>}
          </tr>
        </thead>
        <tbody className={!turnOnInvalidFilter ? 'bg-white' : 'd-none bg-white'}>
          <ApInputBulkTableBody
            register={register}
            errors={errors}
            excelInfos={originalExcelInfos}
            minDate={minDate}
            maxDate={maxDate}
            holidays={holidays}
            t={t}
            currencyType={currencyType}
            isApNumberDuplicated={isApNumberDuplicated}
            fieldNames={fieldNames}
          />
        </tbody>
        <tbody className={turnOnInvalidFilter ? 'bg-white' : 'd-none bg-white'}>
          <ApInputBulkTableBody
            register={register}
            errors={errors}
            excelInfos={invalidExcelInfos}
            minDate={minDate}
            maxDate={maxDate}
            holidays={holidays}
            t={t}
            currencyType={currencyType}
            isApNumberDuplicated={isApNumberDuplicated}
            isInvalidTable
            fieldNames={fieldNames}
          />
        </tbody>
      </TableBorder>
      {rows && rows.length > 0 && (
        <div className="grid-total" data-testid="total">
          {t('text:Total')} : {t('format:number', { value: totalApAmount })}
        </div>
      )}
    </form>
  );
};

export default memo(ApInputBulkImportTable);
