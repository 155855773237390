import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import SearchFinancierModal from 'components/stateless/Modal/system/SearchFinancierModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import useProperty from 'hooks/useProperty';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestSystemUserCreate } from 'utils/http/api/system/users';
import type { systemUserCreateRequest } from 'utils/http/api/system/users/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function SystemFinancierAdminRegister() {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const history = useHistory();
  const getProperty = useProperty<systemUserCreateRequest>();

  // state
  const [searchFiData, setSearchFiData] = useState<EnterpriseVOModel>();

  // useForm
  const { register, getValues, setError, errors, clearErrors } = useForm<systemUserCreateRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const renderGuideMessage = () => {
    return (
      <GuideMessage
        message={[
          t('text:Please_enter_financier_admin_information'),
          <Trans
            key="key"
            i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
            components={{ 1: <span className="asterisk" /> }}
          />,
        ]}
      />
    );
  };

  const requestUserRegister = async () => {
    const data = getValues();
    if (searchFiData) data.enterpriseId = searchFiData?.enterpriseId;
    try {
      requestDTOParser(data);
      await requestSystemUserCreate(data);
      modal.show(<h6>{t('text:The_financier_admin_information_has_been_registered_successfully')}</h6>, {
        closeBtnCb: () => history.push(ROUTES_SY.MANAGEMENT.FINANCIER_ADMIN_LIST),
      });
    } catch (e: any) {
      modal.show(e);
      formErrorHandler<systemUserCreateRequest>(e, setError, clearErrors);
    }
  };

  const onClickRegister = (event: any) => {
    event.preventDefault();
    modal.show(<h6>{t('text:Would_you_like_to_register_financier_admin_information?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      closeBtnCb: () => {},
      confirmBtnCb: () => requestUserRegister(),
    });
  };

  const onClickCancel = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnCb: () => history.push(ROUTES_SY.MANAGEMENT.FINANCIER_ADMIN_LIST),
      },
    );
  };

  const onClickSearchFinancier = () => {
    const setSelectedBranchInfo = (data: EnterpriseVOModel) => {
      setSearchFiData(data);
    };

    modal.show(<SearchFinancierModal modalId={modal.id} getSelectedData={setSelectedBranchInfo} />, {
      modalSize: ModalSize.XL,
      title: t('text:Search_Financier'),
      closeBtnText: t('text:Close'),
    });
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financier_Admin_Registration')} />
      {renderGuideMessage()}
      <div className="content-area">
        <SectionTitle title={t('text:Financier_Admin_Information')} />
        <FormBorder>
          <FormSubtitle title={t('text:FINANCIER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Financier_Name')}
                disabled
                requiredOptions={{ fixedRequired: true }}
                value={searchFiData?.enterpriseName ?? ''}
                error={errors.enterpriseId}
              >
                <Button bold onClick={onClickSearchFinancier}>
                  {t('text:Search_Financier')}
                </Button>
              </FormInput>
              <FormInput label={t('text:Financier_Code')} disabled value={searchFiData?.businessCode ?? ''} />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Registered_Office_Address')}
                disabled
                col={12}
                requiredOptions={{ fixedRequired: true }}
                value={searchFiData?.address ?? ''}
                error={errors.enterpriseId}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:ADMIN_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:User_Code')}
                name={getProperty('employeeCode')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.employeeCode}
              />
              <FormInput
                label={t('text:User_Name')}
                name={getProperty('userName')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.userName}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Telephone')}
                name={getProperty('telephone')}
                ref={register}
                error={errors.telephone}
              />
              <FormInput
                label={t('text:Email')}
                name={getProperty('email')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.email}
              />
            </div>
          </FormContents>
        </FormBorder>
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancel}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onClickRegister} className="ms-2">
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default SystemFinancierAdminRegister;
