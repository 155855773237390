import type Pageable from 'models/Pageable';
import type { InvoicePaymentVO, InvoicePaymentVOModel } from 'models/vo/InvoicePaymentVO';
import { formattingToInvoicePaymentVOModel } from 'models/vo/InvoicePaymentVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SearchSystemInvoicePaymentListRequest } from './requests';

export async function requestSystemInvoicePaymentList(
  pageNumber: number,
  rowCount: number,
  searchValue?: SearchSystemInvoicePaymentListRequest,
): Promise<Pageable<InvoicePaymentVOModel[]>> {
  const response = await http.get<Pageable<InvoicePaymentVO[]>>({
    url: API_SY.INVOICE_PAYMENTS.INVOICE_PAYMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToInvoicePaymentVOModel(data)),
  };
}
