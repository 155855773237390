import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import {
  FormBorder,
  FormContents,
  FormInput,
  FormRadio,
  FormRadioWrap,
  FormSelect,
  FormSubtitle,
  NumericFormatInput,
} from 'components/stateless/CommonForm';
import { FormErrorMessage } from 'components/stateless/CommonForm/FormErrorMessage';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { DOCUMENT_CONDITION_AMOUNT_RANGE, DOCUMENT_CONDITION_TYPE } from 'enums';
import { requestFinancierCreateLoanAdditionalDocument } from 'utils/http/api/financier/loan-additional-document-attachments';
import type { CreateFiLoanAdditionalDocumentDTO } from 'utils/http/api/financier/loan-additional-document-attachments/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

const FinancierAdditionalDocumentRegister = () => {
  const { t } = useTranslation();
  const modal = useModal();
  const history = useHistory();

  const { register, errors, setValue, handleSubmit, watch, control, clearErrors, getValues } =
    useForm<CreateFiLoanAdditionalDocumentDTO>();

  const { mandatoryCondition, hardCopyCondition } = watch();

  const [fileName, setFileName] = useState<string>('');

  const isFieldRequired = (fieldValue: string) =>
    String(fieldValue) === 'true' ? t('text:This_information_is_required') : false;

  const handleClickCancelBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_LIST),
      },
    );
  };

  const handleClickSaveBtn = async (data: CreateFiLoanAdditionalDocumentDTO, e: any): Promise<void> => {
    e.preventDefault();

    const showRegisterModal = (): void => {
      modal.show(<h6>{t('text:Document_has_been_registered_successfully')}</h6>, {
        closeBtnText: t('text:OK'),
        closeBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_LIST),
      });
    };

    try {
      requestDTOParser(data);

      await requestFinancierCreateLoanAdditionalDocument(data);

      showRegisterModal();
    } catch (error) {
      modal.show(error);
    }
  };

  const setFileNameToUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const [file] = e.target.files;
      if (file?.name) setFileName(file.name);
    }
  };

  const handleClickRemoveFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setFileName('');
    setValue('file', null);
  };

  const handleChangeMandatoryUseSpecificCondition = () => {
    setValue('mandatoryCondition.documentConditionType', null);
    setValue('mandatoryCondition.documentConditionAmountRange', null);
    setValue('mandatoryCondition.amount', '');
    setValue('mandatoryCondition.currencyType', null);
    clearErrors([
      'mandatoryCondition.documentConditionType',
      'mandatoryCondition.documentConditionAmountRange',
      'mandatoryCondition.amount',
      'mandatoryCondition.currencyType',
    ]);
  };

  const handleChangeHardCopyUseSpecificCondition = () => {
    setValue('hardCopyCondition.documentConditionType', null);
    setValue('hardCopyCondition.documentConditionAmountRange', null);
    setValue('hardCopyCondition.amount', '');
    setValue('hardCopyCondition.currencyType', null);
    clearErrors([
      'hardCopyCondition.documentConditionType',
      'hardCopyCondition.documentConditionAmountRange',
      'hardCopyCondition.amount',
      'hardCopyCondition.currencyType',
    ]);
  };

  const currencyTypeValidate = () => {
    if (
      String(mandatoryCondition.useSpecificCondition) === 'true' &&
      String(hardCopyCondition.useSpecificCondition) === 'true'
    ) {
      if (getValues('mandatoryCondition.currencyType') !== getValues('hardCopyCondition.currencyType')) {
        return t('text:Each_condition_has_a_different_currency');
      }
    }
    clearErrors(['mandatoryCondition.currencyType', 'hardCopyCondition.currencyType']);

    return true;
  };

  const renderEditSaveBtn = () => {
    return (
      <div className="flex-end mt-4">
        <Button
          size={ButtonSizeEnum.LG}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          onClick={handleClickCancelBtn}
        >
          {t('text:Cancel')}
        </Button>
        <Button
          size={ButtonSizeEnum.LG}
          type="submit"
          className="ms-2"
          onClick={handleSubmit((data, e) => handleClickSaveBtn(data, e))}
        >
          {t('text:Save')}
        </Button>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Additional_Document_Registration')} />
      <div className="content-area">
        <FormBorder>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Template_Information')} />
          <FormContents>
            <div className="row d-flex justify-content-between">
              <FormInput
                label={t('text:Template_Name')}
                name="documentFormName"
                ref={register({ required: t('text:This_information_is_required') })}
                error={errors['documentFormName']}
                requiredOptions={{ required: true }}
              />
              <FormRadioWrap col={5} label={t('text:Template_Status')} required error={errors['activated']}>
                <FormRadio name="activated" value="true" label={t('text:Active')} ref={register({ required: true })} />
                <FormRadio
                  name="activated"
                  value="false"
                  label={t('text:Suspended')}
                  ref={register({ required: true })}
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormInput
                col={12}
                name="description"
                ref={register()}
                label={t('text:Template_Description')}
                error={errors['description']}
              />
            </div>
            <div className="row">
              <label className="information-form__label">{t('text:Notice_for_Partner_User')}</label>
              <div>
                <textarea
                  name="noticeForPartner"
                  ref={register()}
                  className={clsx('information-form__input overflow-scroll', {
                    'error-input-border': errors['noticeForPartner'],
                  })}
                  placeholder={t('text:Please_type_here')}
                />
                <div className="p-1">
                  <FormErrorMessage error={errors['noticeForPartner']} />
                </div>
              </div>
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <FormBorder>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Template_Condition')} />
          <FormContents>
            <div className="row">
              <FormRadioWrap
                col={6}
                label={t('text:Is_a_Mandatory_Documents?')}
                required
                error={errors.mandatoryCondition?.required}
              >
                <FormRadio
                  name="mandatoryCondition.required"
                  value="true"
                  ref={register({ required: true })}
                  label={t('text:Yes')}
                />
                <FormRadio
                  name="mandatoryCondition.required"
                  value="false"
                  ref={register({ required: true })}
                  label={t('text:No')}
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap
                col={6}
                label={t('text:Set_the_Specific_Required_Condition_for_Mandatory_Document?')}
                required
                error={errors.mandatoryCondition?.useSpecificCondition}
              >
                <FormRadio
                  name="mandatoryCondition.useSpecificCondition"
                  value="true"
                  onChange={handleChangeMandatoryUseSpecificCondition}
                  ref={register({ required: true })}
                  label={t('text:Yes')}
                />
                <FormRadio
                  name="mandatoryCondition.useSpecificCondition"
                  value="false"
                  onChange={handleChangeMandatoryUseSpecificCondition}
                  ref={register({ required: true })}
                  label={t('text:No_Applied_all_loan_application')}
                />
              </FormRadioWrap>
            </div>
            <div
              className={clsx('row d-flex justify-content-around', {
                'd-none': String(mandatoryCondition?.useSpecificCondition) !== 'true',
              })}
            >
              <FormSelect
                col={3}
                name="mandatoryCondition.documentConditionType"
                required
                className={clsx('information-form__input', {
                  placeholder: !mandatoryCondition?.documentConditionType,
                })}
                label={t('text:Application_Type')}
                placeholderOptions={{ show: true }}
                selectOptions={[
                  {
                    label: t(`code:document-condition-type.${DOCUMENT_CONDITION_TYPE.SINGLE_AMOUNT}`),
                    value: DOCUMENT_CONDITION_TYPE.SINGLE_AMOUNT,
                  },
                  {
                    label: t(`code:document-condition-type.${DOCUMENT_CONDITION_TYPE.ACCUMULATE_AMOUNT}`),
                    value: DOCUMENT_CONDITION_TYPE.ACCUMULATE_AMOUNT,
                  },
                ]}
                ref={register({
                  required: isFieldRequired(mandatoryCondition?.useSpecificCondition),
                })}
                error={errors.mandatoryCondition?.documentConditionType}
              />
              <FormSelect
                col={3}
                name="mandatoryCondition.documentConditionAmountRange"
                required
                className={clsx('information-form__input', {
                  placeholder: !mandatoryCondition?.documentConditionAmountRange,
                })}
                label={t('text:Range')}
                placeholderOptions={{ show: true }}
                selectOptions={[
                  {
                    label: t(`code:document-condition-amount-range.${DOCUMENT_CONDITION_AMOUNT_RANGE.GT}`),
                    value: DOCUMENT_CONDITION_AMOUNT_RANGE.GT,
                  },
                  {
                    label: t(`code:document-condition-amount-range.${DOCUMENT_CONDITION_AMOUNT_RANGE.GOE}`),
                    value: DOCUMENT_CONDITION_AMOUNT_RANGE.GOE,
                  },
                ]}
                ref={register({
                  required: isFieldRequired(mandatoryCondition?.useSpecificCondition),
                })}
                error={errors.mandatoryCondition?.documentConditionAmountRange}
              />
              <NumericFormatInput
                col={3}
                name="mandatoryCondition.amount"
                control={control}
                label={t('text:Application_loan_Amount')}
                required
                error={errors.mandatoryCondition?.amount}
                rules={{ required: isFieldRequired(mandatoryCondition?.useSpecificCondition) }}
              />
              <FormSelect
                col={3}
                required
                name="mandatoryCondition.currencyType"
                className={clsx('information-form__input', {
                  placeholder: !mandatoryCondition?.currencyType,
                })}
                label={t('text:Currency')}
                placeholderOptions={{ show: true }}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
                ref={register({
                  required: isFieldRequired(mandatoryCondition?.useSpecificCondition),
                  validate: currencyTypeValidate,
                })}
                error={errors.mandatoryCondition?.currencyType}
              />
            </div>
          </FormContents>
        </FormBorder>
        <FormBorder>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Hard_copy_Require_Condition')} />
          <FormContents>
            <div className="row">
              <FormRadioWrap
                col={6}
                label={t('text:Is_It_necessary_to_deliver_the_hard_copies?')}
                required
                error={errors.hardCopyCondition?.required}
              >
                <FormRadio
                  name="hardCopyCondition.required"
                  value="true"
                  ref={register({ required: true })}
                  label={t('text:Yes')}
                />
                <FormRadio
                  name="hardCopyCondition.required"
                  value="false"
                  ref={register({ required: true })}
                  label={t('text:No')}
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap
                col={6}
                label={t('text:Set_the_Specific_Required_Condition_for_Template?')}
                required
                error={errors.hardCopyCondition?.useSpecificCondition}
              >
                <FormRadio
                  name="hardCopyCondition.useSpecificCondition"
                  onChange={handleChangeHardCopyUseSpecificCondition}
                  value="true"
                  ref={register({ required: true })}
                  label={t('text:Yes')}
                />
                <FormRadio
                  name="hardCopyCondition.useSpecificCondition"
                  value="false"
                  onChange={handleChangeHardCopyUseSpecificCondition}
                  ref={register({ required: true })}
                  label={t('text:No_Applied_all_loan_application')}
                />
              </FormRadioWrap>
            </div>
            <div
              className={clsx('row d-flex justify-content-around', {
                'd-none': String(hardCopyCondition?.useSpecificCondition) !== 'true',
              })}
            >
              <FormSelect
                col={3}
                name="hardCopyCondition.documentConditionType"
                className={clsx('information-form__input', {
                  placeholder: !hardCopyCondition?.documentConditionType,
                })}
                required
                label={t('text:Application_Type')}
                placeholderOptions={{ show: true }}
                selectOptions={[
                  {
                    label: t(`code:document-condition-type.${DOCUMENT_CONDITION_TYPE.SINGLE_AMOUNT}`),
                    value: DOCUMENT_CONDITION_TYPE.SINGLE_AMOUNT,
                  },
                  {
                    label: t(`code:document-condition-type.${DOCUMENT_CONDITION_TYPE.ACCUMULATE_AMOUNT}`),
                    value: DOCUMENT_CONDITION_TYPE.ACCUMULATE_AMOUNT,
                  },
                ]}
                ref={register({
                  required: isFieldRequired(hardCopyCondition?.useSpecificCondition),
                })}
                error={errors.hardCopyCondition?.documentConditionType}
              />
              <FormSelect
                col={3}
                name="hardCopyCondition.documentConditionAmountRange"
                className={clsx('information-form__input', {
                  placeholder: !hardCopyCondition?.documentConditionAmountRange,
                })}
                required
                label={t('text:Range')}
                placeholderOptions={{ show: true }}
                selectOptions={[
                  {
                    label: t(`code:document-condition-amount-range.${DOCUMENT_CONDITION_AMOUNT_RANGE.GT}`),
                    value: DOCUMENT_CONDITION_AMOUNT_RANGE.GT,
                  },
                  {
                    label: t(`code:document-condition-amount-range.${DOCUMENT_CONDITION_AMOUNT_RANGE.GOE}`),
                    value: DOCUMENT_CONDITION_AMOUNT_RANGE.GOE,
                  },
                ]}
                ref={register({
                  required: isFieldRequired(hardCopyCondition?.useSpecificCondition),
                })}
                error={errors.hardCopyCondition?.documentConditionAmountRange}
              />
              <NumericFormatInput
                col={3}
                name="hardCopyCondition.amount"
                control={control}
                label={t('text:Application_loan_Amount')}
                required
                error={errors.hardCopyCondition?.amount}
                rules={{ required: isFieldRequired(hardCopyCondition?.useSpecificCondition) }}
              />
              <FormSelect
                col={3}
                name="hardCopyCondition.currencyType"
                className={clsx('information-form__input', {
                  placeholder: !hardCopyCondition?.currencyType,
                })}
                required
                label={t('text:Currency')}
                placeholderOptions={{ show: true }}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
                ref={register({
                  required: isFieldRequired(hardCopyCondition?.useSpecificCondition),
                  validate: currencyTypeValidate,
                })}
                error={errors.hardCopyCondition?.currencyType}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <FormBorder>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Template_File_Upload')} isRequired />
          <div className="detail-in-file-upload-form bg-sub100">
            <div className="d-flex ps-3">
              <input
                className="d-none"
                onChange={setFileNameToUpload}
                ref={register({ required: t('text:This_information_is_required') })}
                type="file"
                name="formAttachment"
                id="FileUpload"
              />
              <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
                {t('text:Attach_File')}
              </label>
              <div id="fileName" className="upload-file-input ps-3">
                {fileName ? fileName : t('text:No_file_attached')}
                {errors['formAttachment'] && <FontAwesomeIcon icon={faCheckCircle} className="check-icon ms-2" />}
              </div>
              <IconButton
                onClick={handleClickRemoveFile}
                className={`delete-uploaded-excel-button ${!fileName && 'd-none'}`}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
            </div>
          </div>
        </FormBorder>
        {renderEditSaveBtn()}
      </div>
    </>
  );
};

export default FinancierAdditionalDocumentRegister;
