import type { CURRENCY_TYPE, ENTERPRISE_TYPE, EXECUTION_STATUS, WAITING_INVOICE_APPROVAL_PHASE_STATUS } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface InvoiceSummaryVO {
  invoiceSummaryId: number;
  succeededInvoiceCount: number; // 송장 등록 신청 건수
  currentApprovalCount: number;
  succeededInvoiceAmount: string; // 총 송장 등록 신청 금액
  currentApprovalAmount: string;
  currencyType: CURRENCY_TYPE;
  createDateTime: string;
  currentInvoiceApprovalPhaseStatus: WAITING_INVOICE_APPROVAL_PHASE_STATUS;
  financierEnterpriseId: number;
  financierName: string;
  dealerEnterpriseId: number;
  dealerName: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerContractNo: string;
  dealerRepresentativeName: string;
  anchorEnterpriseId: number;
  anchorName: string;
  anchorClientId: number;
  anchorClientName: string;
  anchorContractNo: string;
  initialInvoiceApprovalDateTime: string;
  initialUploadEnterpriseId: number;
  initialUploadEnterpriseName: string;
  initialUploadUserId: number;
  initialUploadUserName: string;
  currentInvoiceApprovalDateTime: string;
  currentConfirmEnterpriseId: number;
  currentConfirmEnterpriseName: string;
  currentConfirmUserId: number;
  currentConfirmUserName: string;
  attachmentId: number;
  attachmentPath: string;
  attachmentFileName: string;
  currentConfirmEnterpriseType: ENTERPRISE_TYPE;
  rejected: boolean;
  rejectReason: string;
  rejectedDateTime: string;
  rejectUserId: string;
  rejectUsername: string;
  rejectEnterpriseType: ENTERPRISE_TYPE;
  dealerAgreementId: number;
  inProgress: boolean;
  billOfExchangeNo: string;
  billOfExchangeNoRequired: boolean;
  executionStatus: EXECUTION_STATUS;
  exceptionCode: string;
}

export interface InvoiceSummaryVOModel extends InvoiceSummaryVO {
  currentApprovalAmount: BigNumber;
  succeededInvoiceAmount: BigNumber;
}

export function formattingToInvoiceSummaryVOModel(data: InvoiceSummaryVO): InvoiceSummaryVOModel {
  const { currentApprovalAmount, succeededInvoiceAmount } = data;

  return {
    ...data,
    currentApprovalAmount: sliceZeroDecimal(currentApprovalAmount),
    succeededInvoiceAmount: sliceZeroDecimal(succeededInvoiceAmount),
  };
}
