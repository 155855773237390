import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { requestTempDealerDealerAgreementsList } from 'utils/http/api/dealer/dealer-agreements';
import useModal from 'utils/modal/useModal';
import { getFormSearchParamValue, updateSearchParams } from 'utils/searchParams';

const DE_INVOICE_REGISTER_STEP1_LIST_QS_KEY = 'de-invoice-register-step1-list';

function DealerInvoiceRegisterStep1() {
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const [searchDealerAgreementContractNo, setSearchDealerAgreementContractNo] = useState<string>('');
  const [dealerAgreementPage, setDealerAgreementPage] = useState<Pageable<DealerAgreementVOModel[]>>();
  const { pageable, setPageable } = usePageable(DE_INVOICE_REGISTER_STEP1_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      const searchedContractNo = getFormSearchParamValue('contractNo');
      setSearchDealerAgreementContractNo(searchedContractNo);
      fetchAll(pageable.currentPage, pageable.sizePerPage, searchedContractNo);
    }

    return () => {};
  }, [mounted]);

  const fetchAll = async (
    selectedPageNumber: number = 1,
    selectedRowNumber: number = 10,
    searchContractNo: string,
  ): Promise<void> => {
    try {
      const dealerAgreementPageResponse = await requestTempDealerDealerAgreementsList(
        selectedPageNumber,
        selectedRowNumber,
        {
          contractNo: searchContractNo,
        },
      );

      updateSearchParams(
        { contractNo: searchContractNo, pageNumber: selectedPageNumber, rowCount: selectedRowNumber },
        DE_INVOICE_REGISTER_STEP1_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPageable(dealerAgreementPageResponse);
        setDealerAgreementPage(dealerAgreementPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onBlurUpdateContractNo = (e: any): void => {
    setSearchDealerAgreementContractNo(e.target.value);
  };

  const onClickSearch = async (e: any): Promise<void> => {
    e.preventDefault();

    fetchAll(1, pageable.sizePerPage, searchDealerAgreementContractNo);
  };

  const onClickSelect = (e: any, item: DealerAgreementVOModel): void => {
    e.preventDefault();

    history.push(ROUTES_DE.REGISTER_INVOICE.REGISTRATION_STEP2_BUILD_PATH(item.dealerAgreementId, item.financierId), {
      anchorAgreementId: item.anchorAgreementId,
    });
  };

  const paginate = async (selectedPageNumber: number, selectedRowNumber: number): Promise<void> => {
    await fetchAll(selectedPageNumber, selectedRowNumber, getFormSearchParamValue('contractNo'));
  };

  const renderDealerAgreementTable = (): JSX.Element[] | JSX.Element | undefined => {
    return dealerAgreementPage?.content.map((item, index: number) => (
      <Tr key={index}>
        <Td data={item.contractNo} />
        <Td data={item.anchorFinancierClientName} />
        <Td data={item.anchorAgreementContractNo} />
        <Td data={item.currencyType} />
        <Td data={item.dealerFinancierClientAddress} />
        <Td data={item.dealerFinancierClientRepresentativeName} />
        <Td className="information-table-more">
          <Button onClick={e => onClickSelect(e, item)} bold>
            {t('text:Select')}
          </Button>
        </Td>
      </Tr>
    ));
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 250,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 180,
    },
    {
      headerText: '',
      colWidths: 80,
    },
  ];

  return (
    <>
      <HeaderTitle title={t('text:Invoice_Registration_Request')} />
      <div className="content-area">
        <StepWizard nth={1} title={[t('text:Select_Partner'), t('text:Enter_Invoice_Information_&_Register')]} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Partner_List')} />
        <form>
          <div className="d-flex information-filter mt-0">
            <input
              style={{ width: '200px' }}
              className="only-input"
              type="text"
              placeholder={t('text:Partner_Master_Agreement_Number')}
              onBlur={onBlurUpdateContractNo}
              defaultValue={searchDealerAgreementContractNo}
            />
            <Button onClick={onClickSearch}>{t('text:Search')}</Button>
          </div>
        </form>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderDealerAgreementTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerInvoiceRegisterStep1;
