import type Pageable from 'models/Pageable';
import type { ArCommissionVO, ArCommissionVOModel} from 'models/vo/ArCommissionVO';
import { formattingToArCommissionVOModel } from 'models/vo/ArCommissionVO';
import http from 'utils/http';

import API_FI from '..';

import type { SearchFiArCommissionDTO, UpdateAllArCommissionStatusPaidDTO, UpdateArCommissionStatusDTO } from './requests';

export async function requestFiArCommissions(
  pageNumber: number,
  rowCount: number,
  data: SearchFiArCommissionDTO,
): Promise<Pageable<ArCommissionVOModel[]>> {
  const response = await http.get<Pageable<ArCommissionVO[]>>({
    url: API_FI.AR_COMMISSION.AR_COMMISSION,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToArCommissionVOModel(data)),
  };
}

export const requestFiAllArCommissionStatusUpdate = async (data: UpdateAllArCommissionStatusPaidDTO) => {
  return await http.put({
    url: API_FI.AR_COMMISSION.UPDATE_ALL_AR_COMMISSION_STATUS,
    data,
  });
};

export const requestFiArCommissionStatusUpdate = async (data: UpdateArCommissionStatusDTO) => {
  return await http.put({
    url: API_FI.AR_COMMISSION.UPDATE_AR_COMMISSION_STATUS,
    data,
  });
};
