import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, MULTIPLE_LOAN_APPROVAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { MultipleAnchorLoanRequestDetailVOModel } from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import type { MultipleAnchorLoanRequestRelatedAnchorLoanVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedAnchorLoanVO';
import type { MultipleAnchorLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessArVO';
import type { MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessInvoiceVO';
import {
  requestFinancierMultipleAnchorLoanApprove,
  requestFinancierMultipleAnchorLoanApproveLoanRejection,
  requestFinancierMultipleAnchorLoanApproveRequest,
  requestFinancierMultipleAnchorLoanCancel,
  requestFinancierMultipleAnchorLoanDetail,
  requestFinancierMultipleAnchorLoanListRelatedArs,
  requestFinancierMultipleAnchorLoanListRelatedInvoices,
  requestFinancierMultipleAnchorLoanListRelatedLoan,
  requestFinancierMultipleAnchorLoanReject,
  requestFinancierMultipleAnchorLoanRequestApprovedLoanRejection,
  requestFinancierMultipleAnchorLoanReturn,
} from 'utils/http/api/financier/multiple-request-anchor-loans';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';
import { getSignIn } from 'utils/storage/LocalStorage';

const useFinancierAnchorBulkFinancingConfirmationDetailController = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation();
  const { multipleRequestAnchorLoanId } = useParams<any>();

  const [dataState, setDataState] = useState({
    bulkFinancingDetail: {} as MultipleAnchorLoanRequestDetailVOModel,
    bulkFinancingArList: [] as MultipleAnchorLoanRequestRelatedSuccessArVOModel[],
    bulkFinancingInvoiceList: [] as MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel[],
    bulkFinancingRelatedFinancingList: [] as MultipleAnchorLoanRequestRelatedAnchorLoanVOModel[],
    showDisbursementAccountInfo: false,
  });

  const [financingInfoCheck, setFinancingInfoCheck] = useState<boolean>(false);
  const [bankAccountInfoCheck, setBankAccountInfoCheck] = useState<boolean>(false);

  const { pageable: relatedFinancingPageable, setPageable: setRelatedFinancingPageablePageable } = usePageable();

  const { multipleLoanRequestStatus, approvalType } = dataState.bulkFinancingDetail;
  const isAuthorizerRequest =
    getSignIn()?.authorityType === AUTHORITY_TYPE.AUTHORIZER &&
    approvalType === MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST;

  const isOperatorRequest =
    getSignIn()?.authorityType === AUTHORITY_TYPE.OPERATOR &&
    multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPLIED &&
    approvalType !== MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [fetchedBulkFinancingDetail, fetchedBulkFinancingRelatedLoanList] = await Promise.all([
        requestFinancierMultipleAnchorLoanDetail(multipleRequestAnchorLoanId),
        requestFinancierMultipleAnchorLoanListRelatedLoan(multipleRequestAnchorLoanId, 0, 10),
      ]);

      setDataState(prevState => ({
        ...prevState,
        bulkFinancingDetail: fetchedBulkFinancingDetail,
        bulkFinancingRelatedFinancingList: fetchedBulkFinancingRelatedLoanList.content,
        showDisbursementAccountInfo: !isNil(fetchedBulkFinancingDetail.disbursementAccount),
      }));
      setRelatedFinancingPageablePageable(fetchedBulkFinancingRelatedLoanList);

      if (fetchedBulkFinancingDetail.collateralType === COLLATERAL_TYPE.AR) {
        const fetchedBulkFinancingArList = await requestFinancierMultipleAnchorLoanListRelatedArs(
          multipleRequestAnchorLoanId,
        );
        setDataState(prevState => ({
          ...prevState,
          bulkFinancingArList: fetchedBulkFinancingArList.content,
        }));
      } else if (fetchedBulkFinancingDetail.collateralType === COLLATERAL_TYPE.INVOICE) {
        const fetchedBulkFinancingInvoiceList = await requestFinancierMultipleAnchorLoanListRelatedInvoices(
          multipleRequestAnchorLoanId,
        );
        setDataState(prevState => ({
          ...prevState,
          bulkFinancingInvoiceList: fetchedBulkFinancingInvoiceList.content,
        }));
      }
    } catch (e) {
      modal.show(e);
    }
  };

  const paginateRelatedFinancingList = async (pageNumber: number, rowCount: number) => {
    try {
      const fetchedBulkFinancingRelatedLoanList = await requestFinancierMultipleAnchorLoanListRelatedLoan(
        multipleRequestAnchorLoanId,
        pageNumber,
        rowCount,
      );
      setDataState(prevState => ({
        ...prevState,
        bulkFinancingRelatedFinancingList: fetchedBulkFinancingRelatedLoanList.content,
      }));
      setRelatedFinancingPageablePageable(fetchedBulkFinancingRelatedLoanList);
    } catch (e) {
      modal.show(e);
    }
  };

  const submitBulkFinancingCancel = async () => {
    const showLoanCancelConfirmModal = () => {
      modal.show(<h6>{t('text:Financing_approval_request_is_successfully_cancelled')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    };

    try {
      await requestFinancierMultipleAnchorLoanCancel(multipleRequestAnchorLoanId);
      showLoanCancelConfirmModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const handleOperatorBulkFinancingApproveRequestButtonClick = async () => {
    if (!financingInfoCheck || (dataState.showDisbursementAccountInfo && !bankAccountInfoCheck)) {
      return modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        closeBtnText: t('text:OK'),
      });
    }

    const submitApproveRequest = async () => {
      try {
        await requestFinancierMultipleAnchorLoanApproveRequest(multipleRequestAnchorLoanId);
        showLoanConfirmRequestOKModal();
      } catch (e) {
        modal.show(e);
      }
    };

    const showLoanConfirmRequestOKModal = () => {
      modal.show(<h6>{t('text:The_financing_application_approval_has_been_completed')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_approve_the_financing_application?')}
        <br />
        {t('text:The_financing_approval_will_be_completed_after_being_reviewed_by_the_authorizer')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => {
          submitApproveRequest();
        },
      },
    );
  };

  const handleOperatorBulkFinancingRejectButtonClick = () => {
    let reason = '';
    const submitChangeReject = async () => {
      try {
        await requestFinancierMultipleAnchorLoanReject(multipleRequestAnchorLoanId, reason);
        modal.show(<h6>{t('text:The_financing_application_rejection_has_been_completed')}</h6>, {
          modalType: ModalType.ALERT,
          title: t('text:Financing_Rejection'),
          closeBtnText: t('text:OK'),
          closeBtnCb: async () => {
            await fetchAll();
            scrollToTopOfElement();
          },
        });
      } catch (e) {
        modal.show(e);
      }
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_reject_the_financing_application?')}
        <br />
        {t(
          'text:If_you_refuse,_the_previous_status_cannot_be_reversed_and_the_financing_process_must_be_resumed_from_the_beginning',
        )}
        <br />
        {t('text:Please_enter_the_reason_for_cancelling_the_financing_application_below')}
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          await submitChangeReject();
        },
      },
    );
  };

  const submitFinancingRejectionRequest = async (reason: string) => {
    try {
      await requestFinancierMultipleAnchorLoanRequestApprovedLoanRejection(multipleRequestAnchorLoanId, reason);
      modal.show(<h6>{t('text:The_financing_rejection_request_has_been_submitted_to_the_Authorizer')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const submitFinancingRejectionApprove = async () => {
    try {
      await requestFinancierMultipleAnchorLoanApproveLoanRejection(multipleRequestAnchorLoanId);
      modal.show(<h6>{t('text:The_financing_rejection_has_been_completed')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const submitBulkFinancingReturn = async (reason: string) => {
    try {
      await requestFinancierMultipleAnchorLoanReturn(multipleRequestAnchorLoanId, reason);
      modal.show(<h6>{t('text:The_financing_rejection_request_has_been_reverted')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const showAuthorizerApproveConfirmModal = () => {
    modal.show(<h6>{t('text:The_financing_confirmation_has_been_completed')}</h6>, {
      modalType: ModalType.ALERT,
      title: t('text:Notice'),
      closeBtnText: t('text:OK'),
      closeBtnCb: async () => {
        await fetchAll();
        scrollToTopOfElement();
      },
    });
  };
  const handleAuthorizerBulkFinancingApproveButtonClick = async () => {
    await requestFinancierMultipleAnchorLoanApprove(multipleRequestAnchorLoanId);
    showAuthorizerApproveConfirmModal();
  };

  const handleAuthorizerBulkFinancingRevertButtonClick = () => {
    let reason = '';

    modal.show(
      <h6>
        {t('text:Would_you_like_to_revert_the_financing_application_approval_request?')},
        <br />
        {t('text:Please_enter_the_reason_for_the_revert_below')},
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          await submitBulkFinancingReturn(reason);
        },
      },
    );
  };

  return {
    state: dataState,
    paginateRelatedFinancingList,
    relatedFinancingPageable,
    financingInfoCheckBoxState: {
      financingInfoCheck,
      setFinancingInfoCheck,
    },
    bankAccountInfoCheckBoxState: {
      bankAccountInfoCheck,
      setBankAccountInfoCheck,
    },
    isOperatorRequest,
    isAuthorizerRequest,
    buttonClickHandlers: {
      submitBulkFinancingCancel,
      submitFinancingRejectionRequest,
      submitFinancingRejectionApprove,
      submitBulkFinancingReturn,
      handleOperatorBulkFinancingApproveRequestButtonClick,
      handleOperatorBulkFinancingRejectButtonClick,
      handleAuthorizerBulkFinancingApproveButtonClick,
      handleAuthorizerBulkFinancingRevertButtonClick,
    },
  };
};

export default useFinancierAnchorBulkFinancingConfirmationDetailController;
