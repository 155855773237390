import type Pageable from 'models/Pageable';
import type {
  StatisticsOfDealerAgreementVO,
  StatisticsOfDealerAgreementVOModel,
} from 'models/vo/StatisticsOfDealerAgreementVO';
import { formattingToStatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemStatisticsOfSCPartnerListRequest } from './requests';

export async function requestSystemStatisticsOfSCPartnersList(
  pageNumber: number,
  rowCount: number,
  searchValue: SystemStatisticsOfSCPartnerListRequest,
): Promise<Pageable<StatisticsOfDealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<StatisticsOfDealerAgreementVO[]>>({
    url: API_SY.STATISTICS_OF_SC_PARTNERS.STATISTICS_OF_SC_PARTNER_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  const statisticsOfDealerAgreementList: StatisticsOfDealerAgreementVOModel[] = response.content.map(
    (data: StatisticsOfDealerAgreementVO) => formattingToStatisticsOfDealerAgreementVOModel(data),
  );

  return {
    ...response,
    content: statisticsOfDealerAgreementList,
  };
}
