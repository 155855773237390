import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { FACTORING_NOTICE_CONFIRMATION_STATUS } from 'enums';

import AnchorDeptSellingNoticeDetailArList from './sections/AnchorDeptSellingNoticeDetailARList';
import AnchorDeptSellingNoticeDetailRecipientList from './sections/AnchorDeptSellingNoticeDetailRecipientList';
import AnchorDeptSellingNoticeDetailSummary from './sections/AnchorDeptSellingNoticeDetailSummary';
import useAnchorDeptSellingNoticeDetailController from './useAnchorDeptSellingNoticeDetailController';

function AnchorDeptSellingNoticeDetail() {
  const { t } = useTranslation();

  const {
    state: { deptSellingDetail, recipientList, relatedArList },
    recipientListPageable,
    relatedArListPageable,
    paginateReceiptList,
    paginateArList,
    handleClickConfirmNotificationButton,
  } = useAnchorDeptSellingNoticeDetailController();

  return (
    <>
      <BackHeaderTitle title={t('text:Confirm_Notification_Details')} />
      <GuideMessage
        message={[
          t('text:On_this_page_you_can_confirm_receipt_of_the_Debt_Selling_Notification_mail'),
          t('text:You_can_check_the_list_of_related_APs'),
          t('text:You_can_check_the_list_of_receiving_email_addresses_and_whether_they_have_received_the_email_or_not'),
        ]}
      >
        {{
          button:
            deptSellingDetail?.confirmationStatus === FACTORING_NOTICE_CONFIRMATION_STATUS.WAITING ? (
              <Button bold onClick={handleClickConfirmNotificationButton}>
                {t('text:Confirm_Notification')}
              </Button>
            ) : null,
        }}
      </GuideMessage>
      <AnchorDeptSellingNoticeDetailSummary data={deptSellingDetail} />
      <AnchorDeptSellingNoticeDetailArList
        data={relatedArList?.content}
        pageable={relatedArListPageable}
        paginate={paginateArList}
      />
      <AnchorDeptSellingNoticeDetailRecipientList
        data={recipientList?.content}
        pageable={recipientListPageable}
        paginate={paginateReceiptList}
      />
    </>
  );
}

export default AnchorDeptSellingNoticeDetail;
