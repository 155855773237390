import type React from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import Timer from 'components/stateless/Timer/Timer';
import { OTP_TYPE, ROLE } from 'enums';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import { requestAnchorOtpByDealerAgreementId, requestAnchorOtpByFinancierId } from 'utils/http/api/anchor/otp';
import { requestDealerOtpByAnchorAgreementId, requestDealerOtpByDealerAgreementId } from 'utils/http/api/dealer/otp';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

type requestIdType = 'anchorAgreementId' | 'dealerAgreementId' | 'financierId';

export interface UserVerificationCodeRequest {
  otpCode?: string;
  queryValue?: string;
}

interface UserVerificationModalPropsType {
  modalId: number;
  requestIdType: requestIdType;
  requestId: number;
  verificationCode: UserVerificationCodeRequest;
  clientAuthSetting: FinancierClientAuthSettingVOModel;
}

function UserVerificationModal({
  modalId,
  requestId,
  requestIdType,
  verificationCode,
  clientAuthSetting,
}: UserVerificationModalPropsType): JSX.Element {
  const isEmail = clientAuthSetting.otpType === OTP_TYPE.EMAIL;
  const isSMS = clientAuthSetting.otpType === OTP_TYPE.SMS;
  const isOtp = clientAuthSetting.otpType === OTP_TYPE.OTP;
  const isAotp = clientAuthSetting.otpType === OTP_TYPE.AOTP;

  const [verificationCodeInput, setVerificationCodeInput] = useState<string>();
  const [verificationRequestCode, setVerificationRequestCode] = useState<string>();
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState<boolean>(false);

  const modal = useModal();
  const { t } = useTranslation(['format']);

  const onClickVerification = async () => {
    const requestOtp = (requestId: number) => {
      const authority = getSignIn()?.authorities[0].authority;

      if (authority === ROLE.ROLE_ANCHOR) {
        if (requestIdType === 'financierId') return requestAnchorOtpByFinancierId(requestId);
        else if (requestIdType === 'dealerAgreementId') return requestAnchorOtpByDealerAgreementId(requestId);
      } else if (authority === ROLE.ROLE_DEALER) {
        if (requestIdType === 'anchorAgreementId') return requestDealerOtpByAnchorAgreementId(requestId);
        else if (requestIdType === 'dealerAgreementId') return requestDealerOtpByDealerAgreementId(requestId);
      } else modal.close(modalId);
    };

    try {
      setIsVerificationCodeSent(false);

      const response = await requestOtp(Number(requestId));
      if (isAotp) setVerificationRequestCode(response);

      ReactDOM.unstable_batchedUpdates(() => setIsVerificationCodeSent(true));
    } catch (error) {
      modal.close(modalId);
      modal.show(error);
    }
  };

  const getLabel = () => {
    if (isEmail) return t('text:Email');
    if (isSMS) return t('text:SMS');

    return t('text:OTP_Issuer');
  };

  const getInputName = () => {
    if (isEmail) return 'email';
    if (isSMS) return 'mobile';

    return 'financierName';
  };

  const getInputValue = () => {
    if (isEmail) return clientAuthSetting?.email;
    if (isSMS) return clientAuthSetting?.mobile;

    return clientAuthSetting?.financierName;
  };

  const handleChangeVerificationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCodeInput(e.target.value);

    verificationCode.otpCode = e.target.value;
    if (isAotp) verificationCode.queryValue = verificationRequestCode;
  };

  return (
    <div className="modal-container">
      <FormBorder editable>
        <FormContents>
          <div className="row">
            <div className="col-12">
              <label className="information-form__label">{getLabel()}</label>
              <div className="d-flex">
                <input
                  name={getInputName()}
                  className="information-form__input bg-sub200"
                  type="text"
                  value={getInputValue()}
                  disabled={true}
                />
                {!isOtp && (
                  <Button className="ms-2" onClick={onClickVerification} bold>
                    {t('text:Get_OTP_Verification_Code')}
                  </Button>
                )}
              </div>
              {(isEmail || isSMS) && isVerificationCodeSent && (
                <div className="text-green mt-1">
                  <FontAwesomeIcon icon={faCheck} /> {t('text:The_OTP_verification_code_has_been_sent')}
                </div>
              )}
            </div>
          </div>
          {isAotp && (
            <div className="row">
              <div className="col-12">
                <label className="information-form__label">{t('text:Verification_Request_Code')}</label>
                <input
                  name="verificationRequestCode"
                  className="information-form__input text-center pointfont"
                  type="text"
                  defaultValue={verificationRequestCode}
                  disabled
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <label className="information-form__label star">{t('text:OTP_Verification_Code')}</label>
              <div className="position-parent">
                <input
                  name="verificationCode"
                  className="information-form__input w-100"
                  type="text"
                  defaultValue={verificationCodeInput}
                  onChange={handleChangeVerificationCode}
                />
                <Timer isRun={isVerificationCodeSent} timeValue={5} onFinish={() => setIsVerificationCodeSent(false)} />
              </div>
            </div>
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default UserVerificationModal;
