import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import { EARLY_REPAYMENT_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemEarlyRepaymentList } from 'utils/http/api/system/early-repayment-requests';
import type { SystemEarlyRepaymentListRequest } from 'utils/http/api/system/early-repayment-requests/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const onClickEarlyRepaymentRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EARLY_REPAYMENT_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EARLY_REPAYMENT_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const SEARCHED_SYSTEM_EARLY_REPAYMENT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
    },
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Partner_Client_Code'),
    },
    {
      headerText: t('text:Financier_Financing_ID'),
    },
    {
      headerText: t('text:Platform_Financing_ID'),
    },
    {
      headerText: t('text:Financing_Amount'),
    },
    {
      headerText: t('text:Disbursed_Date'),
    },
    {
      headerText: t('text:Original_Repayment_Date'),
    },
    {
      headerText: t('text:Requested_Repayment_Date'),
    },
    {
      headerText: t('text:Status'),

      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickEarlyRepaymentRequestStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_EARLY_REPAYMENT_LIST_QS_KEY = 'sy-early-repayment-list';

  return {
    mounted,
    modal,
    t,
    onClickEarlyRepaymentRequestStatus,
    SEARCHED_SYSTEM_EARLY_REPAYMENT_LIST_TABLE_HEADERS,
    SY_EARLY_REPAYMENT_LIST_QS_KEY,
  };
};

function SystemEarlyRepaymentList() {
  const {
    mounted,
    modal,
    t,
    onClickEarlyRepaymentRequestStatus,
    SEARCHED_SYSTEM_EARLY_REPAYMENT_LIST_TABLE_HEADERS,
    SY_EARLY_REPAYMENT_LIST_QS_KEY,
  } = getConstants();

  const {
    register: systemEarlyRepaymentSearchFormRegister,
    getValues: getSystemEarlyRepaymentSearchFormValues,
    reset: resetSystemEarlyRepaymentSearchFormValue,
    setValue: setSystemEarlyRepaymentSearchFormValue,
    control: systemEarlyRepaymentSearchFormControl,
  } = useForm<SystemEarlyRepaymentListRequest>();

  const [systemEarlyRepaymentPage, setSystemEarlyRepaymentPage] = useState<Pageable<EarlyRepaymentRequestVOModel[]>>();
  const getSystemEarlyRepaymentProperty = useProperty<SystemEarlyRepaymentListRequest>();
  const { pageable: systemEarlyRepaymentPageable, setPageable: setSystemEarlyRepaymentPageable } =
    usePageable(SY_EARLY_REPAYMENT_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemEarlyRepaymentListRequest>(
        setSystemEarlyRepaymentSearchFormValue,
        getParsedSearchParams(SY_EARLY_REPAYMENT_LIST_QS_KEY).formSearchData,
      );
      fetchSysEarlyRepaymentList(
        systemEarlyRepaymentPageable.currentPage,
        systemEarlyRepaymentPageable.sizePerPage,
        getSystemEarlyRepaymentSearchFormValues(),
      );
    }
  }, [mounted]);

  const fetchSysEarlyRepaymentList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemEarlyRepaymentListRequest,
  ): Promise<void> => {
    try {
      const systemEarlyRepaymentPageData = await requestSystemEarlyRepaymentList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_EARLY_REPAYMENT_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setSystemEarlyRepaymentPage(systemEarlyRepaymentPageData);
        setSystemEarlyRepaymentPageable(systemEarlyRepaymentPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    resetSystemEarlyRepaymentSearchFormValue();
  };

  const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    fetchSysEarlyRepaymentList(1, systemEarlyRepaymentPageable.sizePerPage, getSystemEarlyRepaymentSearchFormValues());
  };

  const onClickExportButton = async () => {
    const data = getParsedSearchParams(SY_EARLY_REPAYMENT_LIST_QS_KEY).formSearchData;

    try {
      const earlyRepaymentListToUseForExcel = await requestSystemEarlyRepaymentList(
        0,
        EXCEL_EXPORT_MAX_ROW_COUNT,
        data,
      );
      const earlyRepaymentListToUseForPdf = earlyRepaymentListToUseForExcel.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: unknown[] = earlyRepaymentListToUseForExcel.content.map(item => ({
        createdDateTime: tableValueManage(
          item.createdDateTime,
          t('format:datetime', { value: item.createdDateTime, key: 'datetime' }),
        ),
        financierName: tableValueManage(item.financierName),
        dealerClientName: tableValueManage(item.dealerClientName),
        dealerClientCode: tableValueManage(item.dealerClientCode),
        financierLoanId: tableValueManage(item.financierLoanId),
        loanId: tableValueManage(item.loanId),
        principalAmount: Number(item.principalAmount),
        disbursedDate: tableValueManage(
          item.disbursedDate,
          t('format:datetime', { value: item.disbursedDate, key: 'datetime' }),
        ),
        repaymentDate: tableValueManage(
          item.repaymentDate,
          t('format:date', { value: item.repaymentDate, key: 'date' }),
        ),
        intendedRepaymentDate: tableValueManage(
          item.intendedRepaymentDate,
          t('format:date', { value: item.intendedRepaymentDate, key: 'date' }),
        ),
        earlyRepaymentRequestStatus: tableValueManage(
          item.earlyRepaymentRequestStatus,
          t(`code:early-repayment-request-status.${item.earlyRepaymentRequestStatus}`),
        ),
      }));

      const excelColumns: ColumnOption<EarlyRepaymentRequestVOModel>[] = [
        {
          header: t('text:Requested_Date'),
          key: 'createdDateTime',
        },
        {
          header: t('text:Financier_Name'),
          key: 'financierName',
        },
        {
          header: t('text:Partner_Name'),
          key: 'dealerClientName',
        },
        {
          header: t('text:Partner_Client_Code'),
          key: 'dealerClientCode',
        },
        {
          header: t('text:Financier_Financing_ID'),
          key: 'financierLoanId',
        },
        {
          header: t('text:Platform_Financing_ID'),
          key: 'loanId',
        },
        {
          header: t('text:Financing_Amount'),
          key: 'principalAmount',
        },
        {
          header: t('text:Disbursed_Date'),
          key: 'disbursedDate',
        },
        {
          header: t('text:Original_Repayment_Date'),
          key: 'repaymentDate',
        },
        {
          header: t('text:Requested_Repayment_Date'),
          key: 'intendedRepaymentDate',
        },
        {
          header: t('text:Status'),
          key: 'earlyRepaymentRequestStatus',
        },
      ];

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfTableHeaders: HeaderType[] = [
        {
          headerText: t('text:Requested_Date'),
        },
        {
          headerText: t('text:Financier_Name'),
        },
        {
          headerText: t('text:Partner_Name'),
        },
        {
          headerText: t('text:Partner_Client_Code'),
        },
        {
          headerText: t('text:Financier_Financing_ID'),
        },
        {
          headerText: t('text:Platform_Financing_ID'),
        },
        {
          headerText: t('text:Financing_Amount'),
        },
        {
          headerText: t('text:Disbursed_Date'),
        },
        {
          headerText: t('text:Original_Repayment_Date'),
        },
        {
          headerText: t('text:Requested_Repayment_Date'),
        },
        {
          headerText: t('text:Status'),
        },
      ];

      const renderPDFTableBodyResult = () => {
        return earlyRepaymentListToUseForPdf.map((item, i) => {
          return (
            <Tr key={i} className="virtual-table-row">
              <Td data={item.createdDateTime} format="datetime" />
              <Td data={item.financierName} />
              <Td data={item.dealerClientName} />
              <Td data={item.dealerClientCode} />
              <Td data={item.financierLoanId} />
              <Td data={item.loanId} />
              <Td data={item.principalAmount} format="number" />
              <Td data={item.disbursedDate} format="datetime" />
              <Td data={item.repaymentDate} format="date" />
              <Td data={item.intendedRepaymentDate} format="date" />
              <Td
                data={t(`code:early-repayment-request-status.${item.earlyRepaymentRequestStatus}`)}
                className={getStatusTextClass('EARLY_REPAYMENT_REQUEST_STATUS', item.earlyRepaymentRequestStatus)}
              />
            </Tr>
          );
        });
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: pdfTableHeaders,
        tableBody: renderPDFTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const systemEarlyRepaymentPaginate = (pageNumber: number, rowCount: number): void => {
    fetchSysEarlyRepaymentList(
      pageNumber,
      rowCount,
      getParsedSearchParams(SY_EARLY_REPAYMENT_LIST_QS_KEY).formSearchData,
    );
  };

  const renderSearchedSystemEarlyRepaymentTableBody = (): JSX.Element[] | undefined => {
    return systemEarlyRepaymentPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.financierName} />
        <Td data={item.dealerClientName} />
        <Td data={item.dealerClientCode} />
        <Td data={item.financierLoanId} />
        <Td data={item.loanId} />
        <Td data={item.principalAmount} format="number" />
        <Td data={item.disbursedDate} format="datetime" />
        <Td data={item.repaymentDate} format="date" />
        <Td data={item.intendedRepaymentDate} format="date" />
        <Td
          data={t(`code:early-repayment-request-status.${item.earlyRepaymentRequestStatus}`)}
          className={getStatusTextClass('EARLY_REPAYMENT_REQUEST_STATUS', item.earlyRepaymentRequestStatus)}
        />
        <TdLink path={ROUTES_SY.MONITOR_FINANCING.EARLY_REPAYMENT_DETAIL_BUILD_PATH(item.earlyRepaymentRequestId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Early_Repayment')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Requested_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getSystemEarlyRepaymentProperty('createdDateFrom')}
                control={systemEarlyRepaymentSearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getSystemEarlyRepaymentProperty('createdDateTo')}
                control={systemEarlyRepaymentSearchFormControl}
              />
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput
                name={getSystemEarlyRepaymentProperty('financierName')}
                ref={systemEarlyRepaymentSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput
                name={getSystemEarlyRepaymentProperty('dealerFinancierClientName')}
                ref={systemEarlyRepaymentSearchFormRegister}
              />
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput
                name={getSystemEarlyRepaymentProperty('dealerFinancierClientCode')}
                ref={systemEarlyRepaymentSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financier_Financing_ID')} />
              <SearchInput
                name={getSystemEarlyRepaymentProperty('financierLoanId')}
                ref={systemEarlyRepaymentSearchFormRegister}
              />
              <SearchLabel label={t('text:Platform_Financing_ID')} />
              <SearchInput
                name={getSystemEarlyRepaymentProperty('loanId')}
                ref={systemEarlyRepaymentSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financing_Amount')} />
              <SearchInput
                name={getSystemEarlyRepaymentProperty('principalAmountFrom')}
                ref={systemEarlyRepaymentSearchFormRegister}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name={getSystemEarlyRepaymentProperty('principalAmountTo')}
                ref={systemEarlyRepaymentSearchFormRegister}
                col={2}
                placeholder={t('text:to')}
              />
              <SearchLabel label={t('text:Disbursed_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getSystemEarlyRepaymentProperty('disbursedDateFrom')}
                control={systemEarlyRepaymentSearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getSystemEarlyRepaymentProperty('disbursedDateTo')}
                control={systemEarlyRepaymentSearchFormControl}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Original_Repayment_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getSystemEarlyRepaymentProperty('repaymentDateFrom')}
                control={systemEarlyRepaymentSearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getSystemEarlyRepaymentProperty('repaymentDateTo')}
                control={systemEarlyRepaymentSearchFormControl}
              />
              <SearchLabel label={t('text:Requested_Repayment_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getSystemEarlyRepaymentProperty('intendedRepaymentDateFrom')}
                control={systemEarlyRepaymentSearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getSystemEarlyRepaymentProperty('intendedRepaymentDateTo')}
                control={systemEarlyRepaymentSearchFormControl}
              />
            </div>
            <div className="row">
              <SearchLabel
                label={t('text:Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickEarlyRepaymentRequestStatus}
              />
              <SearchSelect
                name={getSystemEarlyRepaymentProperty('earlyRepaymentRequestStatus')}
                selectOptions={getSelectOptions<EARLY_REPAYMENT_REQUEST_STATUS>(
                  'EARLY_REPAYMENT_REQUEST_STATUS',
                  'ALL',
                  true,
                )}
                ref={systemEarlyRepaymentSearchFormRegister}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')}: {systemEarlyRepaymentPageable.totalRows}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={SEARCHED_SYSTEM_EARLY_REPAYMENT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_SYSTEM_EARLY_REPAYMENT_LIST_TABLE_HEADERS.length}>
            {renderSearchedSystemEarlyRepaymentTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={systemEarlyRepaymentPageable} paginate={systemEarlyRepaymentPaginate} />
      </div>
    </>
  );
}

export default SystemEarlyRepaymentList;
