import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import type {
  UpdateUserEmailSettingListType,
  UserEmailSettingGroupedMap,
} from 'components/email-notification-setting/types';
import { classifyUserEmailSettingAlertOptions } from 'components/email-notification-setting/utils';
import useMounted from 'hooks/useMounted';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import {
  requestDealerEmailSettingListRequest,
  requestDealerEmailSettingListUpdate,
} from 'utils/http/api/dealer/user-email-setting';
import useModal from 'utils/modal/useModal';

function useDealerMyAccountEmailNotificationSettingController() {
  const { financierId } = useParams() as any;
  const mounted = useMounted();
  const modal = useModal();

  const [selectedCommonAlertSetting, setSelectedCommonAlertSetting] = useState<UserEmailSettingGroupedMap | null>();
  const [selectedVendorAlertSetting, setSelectedVendorAlertSetting] = useState<UserEmailSettingGroupedMap | null>();
  const [selectedDealerAlertSetting, setSelectedDealerAlertSetting] = useState<UserEmailSettingGroupedMap | null>();
  const [factoringEnable, setFactoringEnable] = useState<boolean>();

  useEffect(() => {
    if (mounted) {
      fetchEmailSetting();
    }
  }, [mounted]);

  const fetchEmailSetting = async (): Promise<void> => {
    try {
      const [alertSettingResponse, financierCommonSettingResponse] = await Promise.all([
        requestDealerEmailSettingListRequest({
          financierId,
        }),
        requestFinancierSettingData(financierId),
      ]);

      const { factoringEnable } = financierCommonSettingResponse;

      const { commonFormList, vendorFormList, dealerFormList } = classifyUserEmailSettingAlertOptions(
        alertSettingResponse,
        factoringEnable,
      );

      setFactoringEnable(factoringEnable);
      setSelectedCommonAlertSetting(commonFormList);
      setSelectedVendorAlertSetting(vendorFormList);
      setSelectedDealerAlertSetting(dealerFormList);
    } catch (error) {
      modal.show(error);
    }
  };

  const updateEmailSettingHandler = async (updateUserEmailSettingList: UpdateUserEmailSettingListType[]) => {
    try {
      const updatedAlertSettingResponse = await requestDealerEmailSettingListUpdate({
        financierId,
        updateUserEmailSettingList,
      });

      const { commonFormList, vendorFormList, dealerFormList } = classifyUserEmailSettingAlertOptions(
        updatedAlertSettingResponse,
        factoringEnable!,
      );
      setSelectedCommonAlertSetting(commonFormList);
      setSelectedVendorAlertSetting(vendorFormList);
      setSelectedDealerAlertSetting(dealerFormList);
    } catch (error) {
      modal.show(error);
    }
  };

  return {
    selectedCommonAlertSetting,
    selectedVendorAlertSetting,
    selectedDealerAlertSetting,
    updateEmailSettingHandler,
  };
}

export default useDealerMyAccountEmailNotificationSettingController;
