import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import { requestFiUsage } from 'utils/http/api/financier/usage';
import type { SearchFiUsageDTO } from 'utils/http/api/financier/usage/requests';
import useModal from 'utils/modal/useModal';

import UsageTable from '../../components/UsageTable';

function MonthSummary() {
  const { t } = useTranslation();
  const { show: showModal } = useModal();
  const [usage, setUsage] = useState<UsageSummaryVOModel>();

  const fetchUsage = async () => {
    const firstDateOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const todayDate = dayjs().format('YYYY-MM-DD');
    const searchValue: SearchFiUsageDTO = { fromDate: firstDateOfMonth, toDate: todayDate };

    try {
      const response = await requestFiUsage(searchValue);
      setUsage(response);
    } catch (error) {
      showModal(error);
    }
  };

  useEffect(() => {
    fetchUsage();
  }, []);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:This_Month_Summary')} />
      {/* TODO: 컴포넌트 생성 */}
      <div className="account-form mb-3">
        <div className="account-balance">
          <div className="account-balance-label">{t('text:Estimate_Fee')}</div>
          <div className="account-balance-value">{`USD ${t('format:number', { value: usage?.totalAmount })}`}</div>
        </div>
        <div className="account-balance bg-sub100">
          <div className="account-balance-label">{t('text:Billing_Period')}</div>
          <div className="account-balance-value">{`${usage?.fromDate} ~ ${usage?.toDate}`}</div>
        </div>
      </div>
      <UsageTable usageData={usage} isThisMonthSummary={true} />
    </div>
  );
}

export default MonthSummary;
