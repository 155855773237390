import { requestFinancierDownloadScannedAr } from 'utils/http/api/financier/loans';
import useModal from 'utils/modal/useModal';

export function useFinancierFinancingDetailArInformationLogics(loanId: number) {
  const modal = useModal();

  const onClickDownloadScannedAr = async (e: any) => {
    e.preventDefault();
    try {
      await requestFinancierDownloadScannedAr(loanId);
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    onClickDownloadScannedAr,
  };
}
