import type Pageable from 'models/Pageable';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementDetailVO, DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import { formattingToDealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';
import type { TempAnchorDealerAgreementsListRequest } from 'utils/http/api/anchor/dealer-agreements/requests';

// 딜러 약정 상세 목록 조회
export async function requestAnchorDealerAgreementDetail(
  dealerAgreementId: number,
): Promise<DealerAgreementDetailVOModel> {
  const response = await http.get<DealerAgreementDetailVO>({
    url: API_AC.DEALER_AGREEMENTS.DEALER_AGREEMENT_DETAIL(dealerAgreementId),
  });

  return formattingToDealerAgreementDetailVOModel(response);
}

export async function requestTempAnchorDealerAgreementsList(
  pageNumber: number,
  rowCount: number,
  searchValue: TempAnchorDealerAgreementsListRequest,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_AC.DEALER_AGREEMENTS.TEMP_DEALER_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const dealerAgreementList: DealerAgreementVOModel[] = response.content.map((data: DealerAgreementVO) =>
    formattingToDealerAgreementVOModel(data),
  );
  const dealerAgreementPage: Pageable<DealerAgreementVOModel[]> = {
    ...response,
    content: dealerAgreementList,
  };

  return dealerAgreementPage;
}

export async function requestAnchorAgreementRelatedDealerAgreement(
  dealerAgreementId: number,
): Promise<AnchorAgreementVOModel> {
  const response = await http.get<AnchorAgreementVO>({
    url: API_AC.DEALER_AGREEMENTS.ANCHOR_AGREEMENT_RELATED_DEALER_AGREEMENT(dealerAgreementId),
  });

  return formattingToAnchorAgreementVOModel(response);
}
