import { base64 } from 'digital-signatures/bkav/base64';
import i18n from 'i18next';

import { base64ToFile, fileToFileList } from 'utils/file';
import { unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import type { ModalContextProps } from 'utils/modal/ModalContext';

type SignTrxNoticeCallbackType = {
  data: any;
  modal: ModalContextProps;
  SignedTrxAppData: any;
  pdfSignCompletedCb: (loanAgreementFileList: FileList, loanFactoringNotificationFileList: FileList) => void;
};

export async function SignTrxNoticeCallback({
  data,
  modal,
  SignedTrxAppData,
  pdfSignCompletedCb,
}: SignTrxNoticeCallbackType) {
  const decodeData = base64.decode(data)!;
  const iRet = parseInt(decodeData, 10);
  unShowLoadingUI();
  let dataRet;
  switch (iRet) {
    case 0:
      modal.show(<h6>{i18n.t('text:You_do_not_have_the_digital_signature_authorities')}</h6>);
      break;
    case 1:
      // swal('Signed successfully');
      break;
    case 2:
      modal.show(<h6>{i18n.t('text:This_document_has_already_been_signed')}</h6>);
      break;
    case 3:
      modal.show(
        <h6>
          {i18n.t('text:The_certificate_for_digital_signature_could_not_be_found')}
          <br />
          {i18n.t('text:Please_check_your_USB_token')}
        </h6>,
      );
      break;
    case 4:
      modal.show(<h6>{i18n.t('text:Input_data_for_digital_signature_is_not_properly_formatted')}</h6>);
      break;
    case 5:
      modal.show(<h6>{i18n.t('text:An_error_occurred_during_the_signing_process')}</h6>);
      break;
    case 6:
      modal.show(<h6>{i18n.t('text:An_error_occurred_while_saving_the_digital_signature')}</h6>);
      break;
    case 13:
      modal.show(<h6>{i18n.t('text:The_user_canceled_digital_signature')}</h6>);
      break;
    default:
      // swal('Signed successfully');
      break;
  }

  const loanAgreementFile = base64ToFile(SignedTrxAppData, 'DEPT SALE AND PURCHASE AGREEMENT');
  const loanFactoringNotificationFile = base64ToFile(data, 'DEBT SELLING NOTIFICATION');

  const loanAgreementFileList = fileToFileList(loanAgreementFile);
  const loanFactoringNotificationFileList = fileToFileList(loanFactoringNotificationFile);

  if (loanAgreementFileList && loanFactoringNotificationFileList) {
    modal.close(modal.id); // 전자서명 모달 닫기
    pdfSignCompletedCb(loanAgreementFileList, loanFactoringNotificationFileList);
  }
}
