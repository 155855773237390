import type { LOAN_OPTION, WAITING_AR_APPROVAL_PHASE_STATUS } from 'enums';
import type Pageable from 'models/Pageable';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

import { formattingToSuccessArVOModel } from './SuccessArVO';
import { formattingToWaitingArVOModel } from './WaitingArVO';

import type { SuccessArVO, SuccessArVOModel } from './SuccessArVO';
import type { WaitingArVO, WaitingArVOModel } from './WaitingArVO';

export interface LastArPhaseVO {
  arSummaryId: number;
  waitingArApprovalPhaseId: number;
  count: number;
  amount: string;
  arApprovalPhaseStatus: WAITING_AR_APPROVAL_PHASE_STATUS;
  approveEnterpriseId: number;
  approveEnterpriseName: string;
  approveUserId: number;
  approveUserName: string;
  arList: Pageable<WaitingArVO[]>;
  inProgress: boolean;
  loanLimitAmount: number;
  collateralIssuedLimitAmount: number;
  registeredWaitingArAmount: number;
  settlementWaitingInvoiceAmount: number;
  disbursedLoanAmount: number;
  requestedLoanAmount: number;
  collateralIssuedLimitCheck: boolean;
  loanOption: LOAN_OPTION;
  loanRequestFailedArList: SuccessArVO[];
  authorized: boolean;
  previousPhaseAmount: number;
}

export interface LastArPhaseVOModel extends LastArPhaseVO {
  amount: BigNumber;
  arList: Pageable<WaitingArVOModel[]>;
  loanRequestFailedArList: SuccessArVOModel[];
  // only front
  remainingRegistrationLimit: BigNumber; // collateralIssuedLimitAmount - settlementWaitingInvoiceAmount - registeredWaitingArAmount
}

export function formattingToLastArPhaseVOModel(data: LastArPhaseVO): LastArPhaseVOModel {
  const {
    arList,
    amount,
    loanRequestFailedArList,
    collateralIssuedLimitAmount,
    settlementWaitingInvoiceAmount,
    registeredWaitingArAmount,
  } = data;

  const formattedLoanRequestFailedArList: SuccessArVOModel[] = loanRequestFailedArList
    ? loanRequestFailedArList.map((data: SuccessArVO) => formattingToSuccessArVOModel(data))
    : [];

  const formattedWaitingArList: WaitingArVOModel[] = arList
    ? arList.content.map((data: WaitingArVO) => formattingToWaitingArVOModel(data))
    : [];

  const formattedArPage: Pageable<WaitingArVOModel[]> = {
    ...arList,
    content: formattedWaitingArList,
  };

  const calculatorBigNumber = new CalculatorBigNumber();

  return {
    ...data,
    amount: sliceZeroDecimal(amount),
    arList: formattedArPage,
    loanRequestFailedArList: formattedLoanRequestFailedArList,
    // only front
    remainingRegistrationLimit: calculatorBigNumber
      .add(collateralIssuedLimitAmount)
      .minus(settlementWaitingInvoiceAmount)
      .minus(registeredWaitingArAmount)
      .get(), // collateralIssuedLimitAmount - settlementWaitingInvoiceAmount - registeredWaitingArAmount
  };
}
