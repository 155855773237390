import type Pageable from 'models/Pageable';
import type {
  WaitingAnchorPartnerDetailVO,
  WaitingAnchorPartnerDetailVOModel,
} from 'models/vo/WaitingAnchorPartnerDetailVO';
import { formattingToWaitingAnchorPartnerDetailVOModel } from 'models/vo/WaitingAnchorPartnerDetailVO';
import type { WaitingAnchorPartnerVO, WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import { formattingToWaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  ApproveFiWaitingAnchorPartnerRequest,
  CancelFiWaitingAnchorPartnerRequest,
  CreateFiWaitingAnchorPartnerRequest,
  ReturnFiWaitingAnchorPartnerRequest,
  UpdateFiWaitingAnchorPartnerRequest,
  WaitingAnchorPartnerListRequest,
} from './request';

export async function requestFiWaitingAnchorPartnerList(
  pageNumber: number,
  rowCount: number,
  data: WaitingAnchorPartnerListRequest,
): Promise<Pageable<WaitingAnchorPartnerVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorPartnerVO[]>>({
    url: API_FI.WAITING_ANCHOR_PARTNERS.WAITING_ANCHOR_PARTNER,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorPartnerVOModel(data)),
  };
}

export async function requestFiWaitingAnchorPartnerDetail(
  waitingAnchorPartnerId: number,
): Promise<WaitingAnchorPartnerDetailVOModel> {
  const response = await http.get<WaitingAnchorPartnerDetailVO>({
    url: API_FI.WAITING_ANCHOR_PARTNERS.WAITING_ANCHOR_PARTNER_DETAIL(waitingAnchorPartnerId),
  });

  return formattingToWaitingAnchorPartnerDetailVOModel(response);
}

export async function requestFiWaitingAnchorPartnerModification(
  waitingAnchorPartnerId: number,
  data: UpdateFiWaitingAnchorPartnerRequest,
): Promise<WaitingAnchorPartnerVOModel> {
  const response = await http.put<WaitingAnchorPartnerVO>({
    url: API_FI.WAITING_ANCHOR_PARTNERS.REGISTER_WAITING_ANCHOR_PARTNER(waitingAnchorPartnerId),
    data,
  });

  return formattingToWaitingAnchorPartnerVOModel(response);
}

export async function requestFiWaitingAnchorPartnerRegistration(
  data: CreateFiWaitingAnchorPartnerRequest,
): Promise<Pageable<WaitingAnchorPartnerVOModel[]>> {
  const response = await http.post<Pageable<WaitingAnchorPartnerVO[]>>({
    url: API_FI.WAITING_ANCHOR_PARTNERS.WAITING_ANCHOR_PARTNER,
    data,
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorPartnerVOModel(data)),
  };
}

export async function requestFiWaitingAnchorPartnerApprove(
  data: ApproveFiWaitingAnchorPartnerRequest,
): Promise<Pageable<WaitingAnchorPartnerVOModel[]>> {
  const response = await http.put<Pageable<WaitingAnchorPartnerVO[]>>({
    url: API_FI.WAITING_ANCHOR_PARTNERS.APPROVE_WAITING_ANCHOR_PARTNER,
    data,
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorPartnerVOModel(data)),
  };
}

export async function requestFiWaitingAnchorPartnerCancel(
  waitingAnchorPartnerIds: CancelFiWaitingAnchorPartnerRequest,
): Promise<void> {
  await http.put({
    url: API_FI.WAITING_ANCHOR_PARTNERS.CANCEL_WAITING_ANCHOR_PARTNER,
    data: waitingAnchorPartnerIds,
  });
}

export async function requestFiWaitingAnchorPartnerReturn(data: ReturnFiWaitingAnchorPartnerRequest): Promise<void> {
  await http.put({
    url: API_FI.WAITING_ANCHOR_PARTNERS.RETURN_WAITING_ANCHOR_PARTNER,
    data,
  });
}
