import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import {
  ANCHOR_AGREEMENT_STATUS,
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  SUPPORTED_COLLATERAL_TYPE,
} from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierAnchorAgreementList } from 'utils/http/api/financier/anchor-agreements';
import type { SearchFiAnchorAgreementListDTO } from 'utils/http/api/financier/anchor-agreements/requests';
import { requestFinancierWaitingAnchorAgreementList } from 'utils/http/api/financier/waiting-anchor-agreements';
import type { SearchFiWaitingAnchorAgreementDTO } from 'utils/http/api/financier/waiting-anchor-agreements/requests';
import { getWaitingForApprovalCount } from 'utils/logic';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES, setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';
import { getCollateralType as getCollateralTypeFromLocalStorage, getSignIn } from 'utils/storage/LocalStorage';

const FI_ANCHOR_AGREEMENT_WAITING_LIST_QS_KEY = 'fi-anchor-agreement-waiting-list';
const FI_ANCHOR_AGREEMENT_COMPLETED_LIST_QS_KEY = 'fi-anchor-agreement-completed-list';

function FinancierAnchorAgreementList() {
  const signInModel: SignInModel | null = getSignIn();

  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();
  const { t } = useTranslation(['format']);

  const [completeAnchorAgreementPage, setCompleteAnchorAgreementPage] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const [waitingAnchorAgreementPage, setWaitingAnchorAgreementPage] =
    useState<Pageable<WaitingAnchorAgreementVOModel[]>>();
  const [notCancelledWaitingAnchorAgreementPage, setNotCancelledWaitingAnchorAgreementPage] =
    useState<Pageable<WaitingAnchorAgreementVOModel[]>>();

  const { pageable: completeAnchorAgreementPageable, setPageable: setCompleteAnchorAgreementPageable } = usePageable(
    FI_ANCHOR_AGREEMENT_COMPLETED_LIST_QS_KEY,
  );
  const { pageable: waitingAnchorAgreementPageable, setPageable: setWaitingAnchorAgreementPageable } = usePageable(
    FI_ANCHOR_AGREEMENT_WAITING_LIST_QS_KEY,
  );

  const {
    register: completeAnchorAgreementRegister,
    getValues: getCompleteAnchorAgreementValues,
    reset: completeAnchorAgreementReset,
    setValue: setCompleteAnchorAgreementValue,
    control: completeAnchorAgreementControl,
  } = useForm<SearchFiAnchorAgreementListDTO>();
  const {
    register: waitingAnchorAgreementRegister,
    getValues: getWaitingAnchorAgreementValues,
    reset: waitingAnchorAgreementReset,
    setValue: setWaitingAnchorAgreementValue,
    control: waitingAnchorAgreementControl,
  } = useForm<SearchFiWaitingAnchorAgreementDTO>();

  const supportedCollateralType = getCollateralTypeFromLocalStorage();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    setFormValues<SearchFiWaitingAnchorAgreementDTO>(
      setWaitingAnchorAgreementValue,
      getParsedSearchParams(FI_ANCHOR_AGREEMENT_WAITING_LIST_QS_KEY).formSearchData,
    );

    setFormValues<SearchFiAnchorAgreementListDTO>(
      setCompleteAnchorAgreementValue,
      getParsedSearchParams(FI_ANCHOR_AGREEMENT_COMPLETED_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
    const completeAnchorAgreementSearchData = getCompleteAnchorAgreementValues();

    const addFiAnchorAgreementListQs = () => {
      const waitingQs = makeSearchParamsPattern(
        {
          ...waitingForApprovalSearchData,
          pageNumber: waitingAnchorAgreementPageable.currentPage,
          rowCount: waitingAnchorAgreementPageable.sizePerPage,
        },
        FI_ANCHOR_AGREEMENT_WAITING_LIST_QS_KEY,
      );
      const completedQs = makeSearchParamsPattern(
        {
          ...completeAnchorAgreementSearchData,
          pageNumber: completeAnchorAgreementPageable.currentPage,
          rowCount: completeAnchorAgreementPageable.sizePerPage,
        },
        FI_ANCHOR_AGREEMENT_COMPLETED_LIST_QS_KEY,
      );
      addSearchParams(waitingQs + completedQs);
    };
    addFiAnchorAgreementListQs();

    try {
      const [
        completeAnchorAgreementPageResponse,
        waitingAnchorAgreementPageResponse,
        notCancelledWaitingAnchorAgreementPageResponse,
      ] = await Promise.all([
        requestFinancierAnchorAgreementList(
          completeAnchorAgreementPageable.currentPage,
          completeAnchorAgreementPageable.sizePerPage,
          completeAnchorAgreementSearchData,
        ),
        requestFinancierWaitingAnchorAgreementList(
          waitingAnchorAgreementPageable.currentPage,
          waitingAnchorAgreementPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestFinancierWaitingAnchorAgreementList(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setCompleteAnchorAgreementPage(completeAnchorAgreementPageResponse);
        setWaitingAnchorAgreementPage(waitingAnchorAgreementPageResponse);
        setCompleteAnchorAgreementPageable(completeAnchorAgreementPageResponse);
        setWaitingAnchorAgreementPageable(waitingAnchorAgreementPageResponse);
        setNotCancelledWaitingAnchorAgreementPage(notCancelledWaitingAnchorAgreementPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const setWaitingTabSearchValue = (formSearchData?: SearchFiWaitingAnchorAgreementDTO) => {
    const getSearchFormData = formSearchData ? formSearchData : getWaitingAnchorAgreementValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const onClickRegisterAgreement = (e: any, type: COLLATERAL_TYPE): void => {
    e.preventDefault();
    history.push(ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTER, {
      supportedCollateralType: type === COLLATERAL_TYPE.INVOICE ? COLLATERAL_TYPE.INVOICE : COLLATERAL_TYPE.AR,
    });
  };

  const renderWaitingTabView = (): JSX.Element => {
    const onClickSearchWaitingTab = async (): Promise<void> => {
      try {
        const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

        const [waitingAnchorAgreementPageResponse, notCancelledWaitingAnchorAgreementPageResponse] = await Promise.all([
          requestFinancierWaitingAnchorAgreementList(
            1,
            waitingAnchorAgreementPageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestFinancierWaitingAnchorAgreementList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: 1,
            rowCount: waitingAnchorAgreementPageable.sizePerPage,
          },
          FI_ANCHOR_AGREEMENT_WAITING_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingAnchorAgreementPage(waitingAnchorAgreementPageResponse);
          setWaitingAnchorAgreementPageable(waitingAnchorAgreementPageResponse);
          setNotCancelledWaitingAnchorAgreementPage(notCancelledWaitingAnchorAgreementPageResponse);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickRemoveFilter = (e: any): void => {
      waitingAnchorAgreementReset({
        contractNo: '',
        currencyType: undefined,
        anchorClientCode: '',
        anchorClientName: '',
        assignedBranchCode: '',
        assignedBranchName: '',
        fromStartDate: '',
        toStartDate: '',
        fromExpiryDate: '',
        toExpiryDate: '',
        approvalTypes: undefined,
      });

      e.preventDefault();
    };

    const paginateWaitingTab = async (pageNumber: number, sizePerPage: number): Promise<void> => {
      const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
        getParsedSearchParams(FI_ANCHOR_AGREEMENT_WAITING_LIST_QS_KEY).formSearchData,
      );
      try {
        const waitingAnchorAgreementPageResponse = await requestFinancierWaitingAnchorAgreementList(
          pageNumber,
          sizePerPage,
          waitingForApprovalSearchData,
        );
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber,
            rowCount: sizePerPage,
          },
          FI_ANCHOR_AGREEMENT_WAITING_LIST_QS_KEY,
        );
        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingAnchorAgreementPageable(waitingAnchorAgreementPageResponse);
          setWaitingAnchorAgreementPage(waitingAnchorAgreementPageResponse);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickConfirmationStatus = (): void => {
      modal.show(
        <StatusDescriptionModal
          statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
          statusDescriptionModalType="TEXT"
          statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
        />,
        {
          modalType: ModalType.ALERT,
          modalSize: ModalSize.XL,
          closeBtnText: t('text:Close'),
        },
      );
    };

    const renderWaitingAnchorAgreementTable = (): JSX.Element[] | JSX.Element | undefined => {
      const getConfirmationStatusText = (approvalType: COMMON_APPROVAL_TYPE): string => {
        switch (approvalType) {
          case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
            return t('text:Confirmed');
          case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
            return t('text:Request_Reverted');
          case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
            return t('text:Request_Cancelled');
          case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
            return t('text:Requested');
          default:
            return '';
        }
      };

      return waitingAnchorAgreementPage?.content.map(
        (item, index: number): JSX.Element => (
          <Tr key={index}>
            <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
            <Td data={item.contractNo} />
            <Td data={item.currencyType} />
            <Td data={item.anchorClientName} />
            <Td data={item.anchorClientCode} />
            <Td data={item.branchCode} />
            <Td data={item.branchName} />
            <Td data={item.startDate} format="date" />
            <Td data={item.expiryDate} format="date" />
            <Td
              data={getConfirmationStatusText(item.approvalType)}
              className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
            />
            <TdLink
              path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_WAITING_DETAIL_BUILD_PATH(item.waitingAnchorAgreementId)}
              state={{ supportedCollateralType: item.collateralType }}
            />
          </Tr>
        ),
      );
    };

    const WAITING_ANCHOR_AGREEMENT_LIST_HEADER: HeaderType[] = [
      {
        headerText: t('text:Program_Type'),
        colWidths: 100,
      },
      {
        headerText: t('text:Anchor_Master_Agreement_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 96,
      },
      {
        headerText: t('text:Anchor_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Anchor_Client_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Responsible_Bank_Branch_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Responsible_Bank_Branch_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Approval_Status'),
        colWidths: 150,
        hasStatusDescription: true,
        showStatusDescriptionFunc: onClickConfirmationStatus,
      },
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
                <SearchInput name="contractNo" ref={waitingAnchorAgreementRegister} />
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  name="currencyType"
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                  ref={waitingAnchorAgreementRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Anchor_Client_Code')} />
                <SearchInput name="anchorClientCode" ref={waitingAnchorAgreementRegister} />
                <SearchLabel label={t('text:Anchor_Name')} />
                <SearchInput name="anchorClientName" ref={waitingAnchorAgreementRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Responsible_Bank_Branch_Code')} />
                <SearchInput name="assignedBranchCode" ref={waitingAnchorAgreementRegister} />
                <SearchLabel label={t('text:Responsible_Bank_Branch_Name')} />
                <SearchInput name="assignedBranchName" ref={waitingAnchorAgreementRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Effective_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name="fromStartDate"
                  control={waitingAnchorAgreementControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name="toStartDate"
                  control={waitingAnchorAgreementControl}
                />
                <SearchLabel label={t('text:Expiration_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name="fromExpiryDate"
                  control={waitingAnchorAgreementControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name="toExpiryDate"
                  control={waitingAnchorAgreementControl}
                />
              </div>
              <div className="row">
                <SearchLabel
                  label={t('text:Approval_Status')}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickConfirmationStatus}
                />
                <SearchSelect
                  name="approvalTypes"
                  selectOptions={[
                    {
                      label: t('text:All'),
                      value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                    },
                    {
                      label: t('text:Requested'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                    },
                    {
                      label: t('text:Reverted'),
                      value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    },
                    {
                      label: t('text:Cancelled'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                    },
                  ]}
                  ref={waitingAnchorAgreementRegister}
                />
                <SearchLabel label={t('text:Program_Type')} />
                <SearchSelect
                  name="collateralType"
                  selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                  ref={waitingAnchorAgreementRegister}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickSearchWaitingTab}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Results')} />
          <p className="total-data">
            {t('text:Total')}: {waitingAnchorAgreementPageable.totalRows}
          </p>
          <TableBorder>
            <TableHeader header={WAITING_ANCHOR_AGREEMENT_LIST_HEADER} />
            <TableBody numOfCol={WAITING_ANCHOR_AGREEMENT_LIST_HEADER.length}>
              {renderWaitingAnchorAgreementTable()}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingAnchorAgreementPageable} paginate={paginateWaitingTab} />
        </div>
      </>
    );
  };

  const renderCompleteTabView = (): JSX.Element => {
    const fetchFinancierAnchorAgreementList = async (
      pageNumber: number,
      rowCount: number,
      searchedData: SearchFiAnchorAgreementListDTO,
    ) => {
      try {
        const completeAnchorAgreementPageResponse = await requestFinancierAnchorAgreementList(
          pageNumber,
          rowCount,
          searchedData,
        );
        updateSearchParams(
          {
            ...searchedData,
            pageNumber,
            rowCount,
          },
          FI_ANCHOR_AGREEMENT_COMPLETED_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setCompleteAnchorAgreementPage(completeAnchorAgreementPageResponse);
          setCompleteAnchorAgreementPageable(completeAnchorAgreementPageResponse);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchCompleteTab = async (e: any): Promise<void> => {
      e.preventDefault();
      await fetchFinancierAnchorAgreementList(
        1,
        completeAnchorAgreementPageable.sizePerPage,
        getCompleteAnchorAgreementValues(),
      );
    };

    const onClickRemoveFilter = (e: any): void => {
      e.preventDefault();

      completeAnchorAgreementReset({
        contractNo: '',
        currencyType: undefined,
        anchorClientCode: '',
        anchorClientName: '',
        branchCode: '',
        branchName: '',
        startDateFrom: '',
        startDateTo: '',
        expiryDateFrom: '',
        expiryDateTo: '',
        agreementStatus: undefined,
      });
    };

    const paginateCompleteTab = async (pageNumber: number, sizePerPage: number): Promise<void> => {
      await fetchFinancierAnchorAgreementList(
        pageNumber,
        sizePerPage,
        getParsedSearchParams(FI_ANCHOR_AGREEMENT_COMPLETED_LIST_QS_KEY).formSearchData,
      );
    };

    const renderAnchorAgreementTable = (): JSX.Element[] | JSX.Element | undefined => {
      const getAnchorAgreementStatusClassName = (anchorAgreementStatus: string): string => {
        switch (anchorAgreementStatus) {
          case ANCHOR_AGREEMENT_STATUS.ACTIVATED:
            return 'text-bold-dark-green';
          case ANCHOR_AGREEMENT_STATUS.SUSPENDED:
            return 'text-bold-brick-red';
          default:
            return '';
        }
      };

      return completeAnchorAgreementPage?.content.map(
        (item, index: number): JSX.Element => (
          <Tr key={index}>
            <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
            <Td data={item.contractNo} />
            <Td data={item.currencyType} />
            <Td data={item.financierClientName} />
            <Td data={item.financierClientCode} />
            <Td data={item.branchCode} />
            <Td data={item.branchName} />
            <Td data={item.startDate} format="date" />
            <Td data={item.expiryDate} format="date" />
            <Td
              data={t(`code:anchor-agreement-status.${item?.anchorAgreementStatus}`)}
              className={getAnchorAgreementStatusClassName(item.anchorAgreementStatus)}
              format="code"
            />
            <TdLink
              path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.anchorAgreementId)}
              state={{ supportedCollateralType: item.collateralType }}
            />
          </Tr>
        ),
      );
    };

    const ANCHOR_AGREEMENT_LIST_HEADER: HeaderType[] = [
      {
        headerText: t('text:Program_Type'),
        colWidths: 100,
      },
      {
        headerText: t('text:Anchor_Master_Agreement_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 100,
      },
      {
        headerText: t('text:Anchor_Name'),
        colWidths: 180,
      },
      {
        headerText: t('text:Anchor_Client_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Responsible_Bank_Branch_Code'),
        colWidths: 150,
      },
      {
        headerText: t('text:Responsible_Bank_Branch_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Agreement_Suspension'),
        colWidths: 100,
      },
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>

            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
                <SearchInput name="contractNo" ref={completeAnchorAgreementRegister} />
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  name="currencyType"
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                  ref={completeAnchorAgreementRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Anchor_Client_Code')} />
                <SearchInput name="anchorClientCode" ref={completeAnchorAgreementRegister} />
                <SearchLabel label={t('text:Anchor_Name')} />
                <SearchInput name="anchorClientName" ref={completeAnchorAgreementRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Responsible_Bank_Branch_Code')} />
                <SearchInput name="branchCode" ref={completeAnchorAgreementRegister} />
                <SearchLabel label={t('text:Responsible_Bank_Branch_Name')} />
                <SearchInput name="branchName" ref={completeAnchorAgreementRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Effective_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name="startDateFrom"
                  control={completeAnchorAgreementControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name="startDateTo"
                  control={completeAnchorAgreementControl}
                />
                <SearchLabel label={t('text:Expiration_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name="expiryDateFrom"
                  control={completeAnchorAgreementControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name="expiryDateTo"
                  control={completeAnchorAgreementControl}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Agreement_Suspension')} />
                <SearchSelect
                  name="agreementStatus"
                  selectOptions={getSelectOptions<ANCHOR_AGREEMENT_STATUS>('ANCHOR_AGREEMENT_STATUS', 'ALL', true)}
                  ref={completeAnchorAgreementRegister}
                />
                <SearchLabel label={t('text:Program_Type')} />
                <SearchSelect
                  name="collateralType"
                  selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                  ref={completeAnchorAgreementRegister}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickSearchCompleteTab}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Results')} />
          <p className="total-data">
            {t('text:Total')}: {completeAnchorAgreementPage?.totalElements}
          </p>
          <TableBorder>
            <TableHeader header={ANCHOR_AGREEMENT_LIST_HEADER} />
            <TableBody numOfCol={ANCHOR_AGREEMENT_LIST_HEADER.length}>{renderAnchorAgreementTable()}</TableBody>
          </TableBorder>
          <Pagination pageable={completeAnchorAgreementPageable} paginate={paginateCompleteTab} />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Anchor_Master_Agreement')} />
      {signInModel?.authorityType !== AUTHORITY_TYPE.OPERATOR ? (
        <GuideMessage
          message={[
            t('text:On_this_page,_you_can_view_the_list_of_Anchor_Master_Agreements_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Anchor_Master_Agreement'),
          ]}
        />
      ) : (
        <GuideMessage
          message={[
            t(
              'text:Click_the_corresponding_button_on_the_right_to_register_an_Anchor_Master_Agreement_for_the_financing_program',
            ),
            t('text:On_this_page,_you_can_view_the_list_of_Anchor_Master_Agreements_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Anchor_Master_Agreement'),
          ]}
        >
          {{
            button: (
              <>
                {(supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.AR ||
                  supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) && (
                  <Button fullWidth onClick={e => onClickRegisterAgreement(e, COLLATERAL_TYPE.AR)}>
                    {t('text:Register_Master_Agreement_of_Vendor_Finance')}
                  </Button>
                )}
                {(supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE ||
                  supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) && (
                  <Button fullWidth onClick={e => onClickRegisterAgreement(e, COLLATERAL_TYPE.INVOICE)}>
                    {t('text:Register_Master_Agreement_of_Dealer_Finance')}
                  </Button>
                )}
              </>
            ),
          }}
        </GuideMessage>
      )}

      <Tab
        initialActiveTabIndex={1}
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              getWaitingAnchorAgreementValues('approvalTypes'),
              notCancelledWaitingAnchorAgreementPage?.totalElements,
            ),
          },
          {
            tabItemCount: completeAnchorAgreementPage?.totalElements ?? 0,
          },
        ]}
        tabViewList={[renderWaitingTabView(), renderCompleteTabView()]}
      />
    </>
  );
}

export default FinancierAnchorAgreementList;
