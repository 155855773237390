import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import WithProvider from 'utils/render';

import WaitingAnchorOptionApprovalInfo from './sections/WaitingAnchorOptionApprovalInfo';
import useWaitingDetailController from './useWaitingDetailController';
import ExtraInformationProvider from '../models/extraInformation/Context';
import FinancingOptionProvider from '../models/financingOption/Provider';
import AssociatedAnchorMasterAgreement from '../sections/AssociatedAnchorMasterAgreement';
import DisbursementAccount from '../sections/DisbursementAccount';
import Financier from '../sections/Financier';
import FinancingDuration from '../sections/FinancingDuration';
import FinancingLimit from '../sections/FinancingLimit';
import GeneralInformation from '../sections/GeneralInformation';
import InterestRate from '../sections/InterestRate';

const AnchorFinancingOptionWaitingDetail = () => {
  const { t } = useTranslation(['format']);

  const {
    methods,
    isEditable,
    isSameBranch,
    conditionChecker,
    isFirstRegisteredWaitingAgreement,
    handleCancelButtonClick,
    handleChangeRequestButtonClick,
    handleReturnButtonClick,
    handleConfirmButtonClick,
    GuideMessageOption,
    approvalType,
  } = useWaitingDetailController();

  return (
    <>
      <BackHeaderTitle
        title={
          isFirstRegisteredWaitingAgreement
            ? t('text:Anchor_Financing_Option_Registration_Request')
            : t('text:Anchor_Financing_Option_Modification_Request')
        }
      />

      {GuideMessageOption && <GuideMessage {...GuideMessageOption} />}

      {approvalType && <WaitingAnchorOptionApprovalInfo />}

      <Form methods={methods} isEditable={isEditable} isEditableStyle={isEditable}>
        <div className="content-area">
          <SectionTitle title={t('text:Contracting_Parties')} />
          <Border isEditable={isEditable}>
            <AssociatedAnchorMasterAgreement />
            <Financier />
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle title={t('text:General_Information')} />
          <Border isEditable={isEditable}>
            <GeneralInformation />
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle title={t('text:Financing_Terms_and_Conditions')} />
          <Border isEditable={isEditable}>
            <FinancingLimit />
            <InterestRate />
            <FinancingDuration />
          </Border>
        </div>
        <div className="content-area">
          <SectionTitle title={t('text:Designated_Account')} />
          <Border isEditable={isEditable}>
            <DisbursementAccount />
          </Border>
        </div>
      </Form>

      {isSameBranch && (
        <div className="content-area">
          <div className="flex-end">
            {conditionChecker(AUTHORITY_TYPE.OPERATOR, COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED) && (
              <>
                <Button
                  size={ButtonSizeEnum.LG}
                  onClick={handleCancelButtonClick}
                  color={ButtonColorEnum.SECONDARY}
                  variant={ButtonVariantEnum.OUTLINED}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.LG} onClick={handleChangeRequestButtonClick} className="ms-2">
                  {isFirstRegisteredWaitingAgreement ? t('text:Registration_Request') : t('text:Request_Change')}
                </Button>
              </>
            )}
            {conditionChecker(AUTHORITY_TYPE.AUTHORIZER, COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) && (
              <>
                <Button
                  size={ButtonSizeEnum.LG}
                  onClick={handleReturnButtonClick}
                  color={ButtonColorEnum.RED}
                  variant={ButtonVariantEnum.OUTLINED}
                >
                  {t('text:Revert')}
                </Button>
                <Button size={ButtonSizeEnum.LG} onClick={handleConfirmButtonClick} className="ms-2">
                  {t('text:Confirm')}
                </Button>
              </>
            )}
            {conditionChecker(AUTHORITY_TYPE.OPERATOR, COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) && (
              <Button
                size={ButtonSizeEnum.LG}
                onClick={handleCancelButtonClick}
                color={ButtonColorEnum.SECONDARY}
                variant={ButtonVariantEnum.OUTLINED}
              >
                {t('text:Cancel_Request')}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default WithProvider({
  Component: AnchorFinancingOptionWaitingDetail,
  Provider: [ExtraInformationProvider, FinancingOptionProvider],
});
