import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import SearchBranchModal from 'components/stateless/Modal/financier/SearchBranchModal';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useDivisionsViewModel from '../../../models/divisions/useDivisionsViewModel';

const useFinancierController = () => {
  const { show: showModal, id: modalId } = useModal();
  const { t } = useTranslation(['format']);

  const { agreement, updateBranchInfo } = useAgreementViewModel();
  const { financierName } = useDivisionsViewModel();

  const {
    methods: { setValue, register, errors, clearErrors },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();

  const isShowSearchBranchButton = isEditable && !getReadOnlyValue('branchId');

  const setSelectedBranchData = (selectedBranchData: BranchVOModel) => {
    setValue('branchId', selectedBranchData.branchId);
    updateBranchInfo(selectedBranchData);

    if (errors?.branchId) clearErrors('branchId');
  };

  const showBranchModal = () => {
    const searchBranchModalOptions: ModalOptions = {
      modalType: ModalType.ALERT,
      modalSize: ModalSize.XL,
      title: t('text:Search_Branch'),
      closeBtnText: t('text:Close'),
    };

    showModal(
      <SearchBranchModal getSelectedData={setSelectedBranchData} modalId={modalId} />,
      searchBranchModalOptions,
    );
  };

  const handleSearchBranchClick = () => {
    showBranchModal();
  };

  return {
    isShowSearchBranchButton,
    data: {
      financierName,
      branchCode: agreement.branchCode,
      branchName: agreement.branchName,
      branchTelephone: agreement.branchTelephone,
      branchFax: agreement.branchFax,
      branchAddress: agreement.branchAddress,
    },
    handleSearchBranchClick,
    register,
    errors,
  };
};

export default useFinancierController;
