import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash-es';

import ApAccordionList from 'components/ap/ApSummaryPhaseAccordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import useProgressStatus from 'hooks/useProgressStatus';
import type Pageable from 'models/Pageable';
import type { ArPhaseVOModel } from 'models/vo/ArPhaseVO';
import type { ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { LastArPhaseVOModel } from 'models/vo/LastArPhaseVO';
import DealerAPRequestModal from 'pages/dealer/register-ar/modals/DealerAPRequestModal';
import DealerArRegisterValidationErrorModal from 'pages/dealer/register-ar/modals/DealerArRegisterValidationErrorModal';
import type { BigNumber } from 'utils/bigNumber';
import { getSum } from 'utils/calculate';
import {
  convertToServerDateFormat,
  getAvailableSettlementDateRangeForRegistrationArOrAp,
  getDayTerm,
  removeRangeFromHoliday,
  verifySettlementDateForRegistrationArOrAp,
} from 'utils/date/date';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestDealerArPhaseList } from 'utils/http/api/dealer/ar-approval-phases';
import {
  requestDealerApproveArPhase,
  requestDealerArSummaryDetail,
  requestDealerArSummaryLastPhase,
  requestDealerRejectArRegistration,
} from 'utils/http/api/dealer/ar-summaries';
import type { DealerArPhaseApproveRequest } from 'utils/http/api/dealer/ar-summaries/request';
import { requestDealerClientAuthByAnchorAgreementId } from 'utils/http/api/dealer/client-auth-setting';
import { requestDealerFinancierCalendar } from 'utils/http/api/dealer/financier-calendar';
import { requestDealerWaitingArList } from 'utils/http/api/dealer/waiting-ars';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { invoiceOrArAmountInputValidate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import useValidation from 'utils/validation/useValidation';

import type { TFunction } from 'i18next';

const getFieldNames = (t: TFunction, options?: { currencyType?: CURRENCY_TYPE }) => {
  return {
    arNumber: {
      name: t('text:AR_Number_(Tax_Invoice_Number)'),
    },
    arIssuedDate: {
      name: t('text:AR_Issued_Date'),
    },
    settlementDate: {
      name: t('text:Settlement_Date'),
    },
    arAmount: {
      name: `${t('text:AR_Amount')} (${t('text:unit')}: ${options?.currencyType ?? ''})`,
    },
  };
};

const getConstant = () => {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const { apSummaryId } = useParams() as any;

  const MAX_ROW_LENGTH = 100;

  const userAuthorityType = getSignIn()?.authorityType;
  const history = useHistory();

  return {
    modal,
    t,
    mounted,
    apSummaryId,
    MAX_ROW_LENGTH,
    userAuthorityType,
    history,
  };
};

function DealerApConfirmationDetail() {
  const { modal, t, mounted, apSummaryId, MAX_ROW_LENGTH, userAuthorityType } = getConstant();

  const [arSummaryData, setArSummaryData] = useState<ArSummaryVOModel>();
  const [arSummaryPhaseDataList, setArSummaryPhaseDataList] = useState<Pageable<ArPhaseVOModel[]>>();
  const [editableArSummaryData, setEditableArSummaryData] = useState<LastArPhaseVOModel>();
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회

  // ar lists state - phase 별로
  const [searchedWaitingApprovalPhaseId, setSearchedWaitingApprovalPhaseId] = useState<number[]>([]);
  const [settlementDatePeriod, setSettlementDatePeriod] = useState({
    minimumDate: new Date(),
    maximumDate: new Date(),
  });

  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [totalApAmount, setTotalApAmount] = useState<BigNumber>('0');
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();

  const { register, control, errors, getValues, reset, trigger } = useForm<DealerArPhaseApproveRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'arList',
  });

  const fieldNames = getFieldNames(t, { currencyType: arSummaryData?.currencyType });

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: errors.arList,
    rule: 'arList',
    accountType: 'AR',
  });

  const { renderProgressStatus } = useProgressStatus(arSummaryData, '', 'Ar');

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    const date = new Date();
    const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];

    try {
      const [arSummaryDetailResponse, editableArSummaryResponse, arSummaryPhaseResponse] = await Promise.all([
        requestDealerArSummaryDetail(apSummaryId),
        requestDealerArSummaryLastPhase(apSummaryId),
        requestDealerArPhaseList(0, 10, apSummaryId),
      ]);

      const financierCommonSettingResponse = await requestFinancierSettingData(
        arSummaryDetailResponse.financierEnterpriseId,
      );
      const { minimumPeriodForRegisterAr, maximumPeriodForRegisterAr } = financierCommonSettingResponse;
      const minimumDate = new Date(today[0], today[1], today[2] + minimumPeriodForRegisterAr);
      const maximumDate = new Date(today[0], today[1], today[2] + maximumPeriodForRegisterAr);

      const fetchFinancierHoliday = await requestDealerFinancierCalendar(
        arSummaryDetailResponse.financierEnterpriseId,
        {
          pageNumber: 0,
          rowCount:
            Number(getDayTerm(convertToServerDateFormat(minimumDate), convertToServerDateFormat(maximumDate))) + 1,
          fromDate: convertToServerDateFormat(minimumDate),
          toDate: convertToServerDateFormat(maximumDate),
          holiday: true,
        },
      );

      const getHolidayArrays = fetchFinancierHoliday.content.map(item => item.solarDate);
      const availableDateRange = getAvailableSettlementDateRangeForRegistrationArOrAp(
        getHolidayArrays,
        minimumDate,
        maximumDate,
      );
      const [initialSettlementDate] = availableDateRange;

      ReactDOM.unstable_batchedUpdates(() => {
        setArSummaryData(arSummaryDetailResponse);
        setEditableArSummaryData(editableArSummaryResponse);
        setArSummaryPhaseDataList(arSummaryPhaseResponse);
        setFinancierHoliday(fetchFinancierHoliday);
        setInitialSettlementDate(initialSettlementDate);
        setSettlementDatePeriod({ maximumDate, minimumDate });
        if (editableArSummaryResponse.arList.content.length !== 0) {
          reset({
            arList: editableArSummaryResponse.arList.content.map(item => {
              return {
                arNumber: item.arNumber,
                arAmount: item.arAmount,
                arIssuedDate: item.arIssuedDate,
                settlementDate: verifySettlementDateForRegistrationArOrAp(
                  item.settlementDate,
                  initialSettlementDate,
                  availableDateRange,
                ),
              };
            }),
          });
        } else {
          reset({
            arList: [
              {
                arNumber: undefined,
                arAmount: undefined,
                arIssuedDate: undefined,
                settlementDate: initialSettlementDate,
              },
            ],
          });
        }
      });
    } catch (error: any) {
      modal.show(error);
    }
  };

  const updateTotalApAmount = useCallback(() => {
    const sum = getSum(getValues().arList, 'arAmount');
    setTotalApAmount(sum);
  }, [getValues]);

  useEffect(() => {
    updateTotalApAmount();
  }, [updateTotalApAmount, fields]);

  const handleArSummaryPhaseAccordionClick = (arPhaseData: ArPhaseVOModel) => {
    return requestDealerWaitingArList({
      waitingArApprovalPhaseId: arPhaseData.waitingArApprovalPhaseId,
      rowCount: 3000,
    });
  };

  const renderLastPhase = () => {
    const appendRow = (e: any) => {
      e.preventDefault();
      if (fields.length >= MAX_ROW_LENGTH) {
        modal.show(
          <h6>
            {t('text:Allowed_N_rows_at_a_time', { number: MAX_ROW_LENGTH })} {t('text:Please_check_the_data_again')}
          </h6>,
        );
      } else {
        append({
          arNumber: undefined,
          arAmount: undefined,
          arIssuedDate: undefined,
          settlementDate: initialSettlementDate,
        });
      }
    };

    const removeRow = (e: any) => {
      const upDateInValidState = () => {
        let i = 0;
        const temp = [...checkedValidation];

        for (const index of checkedRows) {
          temp.splice(index - i, 1);
          i++;
        }

        return temp;
      };
      e.preventDefault();
      if (checkedRows.length === fields.length) {
        reset({
          arList: [
            {
              arNumber: undefined,
              arAmount: undefined,
              arIssuedDate: undefined,
              settlementDate: initialSettlementDate,
            },
          ],
        });
        setCheckedValidation([]);
      } else {
        setCheckedValidation(upDateInValidState());
        remove(checkedRows);
      }

      setCheckedRows([]);
    };

    const handleCheckAll = (e: any) => {
      if (e.target.checked) {
        const arr: number[] = [];
        fields.forEach((el, index) => arr.push(index));
        setCheckedRows(arr);
      } else {
        setCheckedRows([]);
      }
    };

    const handleCheckChange = (e: any, index: number) => {
      if (e.target.checked) {
        setCheckedRows([...checkedRows, index]);
      } else {
        setCheckedRows(checkedRows.filter(el => el !== index));
      }
    };

    const apNumberDuplicateValidator = (value: string, index: number) => {
      const data = getValues();
      if (data.arList) {
        const getArNumbers = data.arList.map(item => item.arNumber);
        getArNumbers.splice(index, 1);
        const arNumbers = getArNumbers.filter(item => {
          if (item) return item;
        });

        if (arNumbers.includes(value)) {
          return false;
        }
      }

      return true;
    };

    const onClickReject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      let reason = '';

      const onClickConfirmOnReject = async (): Promise<void> => {
        try {
          await requestDealerRejectArRegistration(Number(apSummaryId), reason);

          modal.show(<h6>{t('text:The_registration_request_has_been_rejected')}</h6>, {
            modalSize: ModalSize.NONE,
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => {
              fetchAll();
            },
          });
        } catch (error: any) {
          modal.show(error);
        }
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_reject_the_registration_request?')}
            <br />
            {t('text:If_you_reject_the_registration_request_you_will_have_to_proceed_with_the_AR_registration_again')}
            <br />
            {t('text:Please_enter_the_reason_for_the_rejection_below')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: () => onClickConfirmOnReject(),
        },
      );

      e.preventDefault();
    };

    const onClickRegister = async (e: any) => {
      e.preventDefault();

      const verificationCode: UserVerificationCodeRequest = {};

      const data = getValues();

      const onClickConfirm = async (): Promise<void> => {
        const requestedData = {
          ...getValues(),
          arSummaryId: Number(apSummaryId),
          anchorAgreementId: Number(arSummaryData?.anchorAgreementId),
          otpCode: verificationCode.otpCode,
          queryValue: verificationCode.queryValue,
        };
        try {
          await requestDealerApproveArPhase(requestedData);
          modal.show(<h6>{t('text:The_AR_confirmation_request_has_been_completed_and_is_in_progress')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => fetchAll(),
          });
        } catch (error: any) {
          modal.show(error);
        }
      };

      const showUserVerificationModal = async () => {
        try {
          if (userAuthorityType === AUTHORITY_TYPE.AUTHORIZER && arSummaryData?.anchorAgreementId) {
            const clientAuthTypeData = await requestDealerClientAuthByAnchorAgreementId(
              arSummaryData.anchorAgreementId,
            );
            if (clientAuthTypeData.otpType !== OTP_TYPE.NONE) {
              modal.show(
                <UserVerificationModal
                  modalId={modal.id}
                  verificationCode={verificationCode}
                  requestIdType="anchorAgreementId"
                  requestId={arSummaryData.anchorAgreementId}
                  clientAuthSetting={clientAuthTypeData}
                />,
                {
                  modalType: ModalType.CONFIRM,
                  title: t('text:User_Verification'),
                  closeBtnText: t('text:Cancel'),
                  confirmBtnCb: () => onClickConfirm(),
                },
              );
            } else {
              onClickConfirm();
            }
          } else {
            onClickConfirm();
          }
        } catch (e: any) {
          modal.show(e);
        }
      };

      const executeRegister = () => {
        return new Promise<void>(async resolve => {
          showLoadingUI();
          setTimeout(() => {
            resolve();
          }, 1000);
        }).then(async () => {
          await trigger().then(result => {
            setCheckedValidation(data.arList.map(() => true));
            unShowLoadingUI();
            // validation #1. duplicate ap Number / ap amount = 0 / missing items
            if (!result) {
              modal.show(<DealerArRegisterValidationErrorModal />, {
                modalSize: ModalSize.NONE,
                modalType: ModalType.ALERT,
                closeBtnText: t('text:Close'),
              });

              return; // FE validation error 있으면 return
            } else {
              // FRONT Validation 통과
              if (data) {
                modal.show(
                  <DealerAPRequestModal
                    dataList={data.arList}
                    totalCount={fields.length}
                    totalAmount={totalApAmount}
                    currencyType={arSummaryData?.currencyType}
                  />,
                  {
                    modalSize: ModalSize.XL,
                    modalType: ModalType.CONFIRM,
                    closeBtnText: t('text:Cancel'),
                    confirmBtnCb: () => {
                      showUserVerificationModal();
                    },
                  },
                );
              }
            }
          });
        });
      };

      await executeRegister();
    };

    return (
      editableArSummaryData?.authorized &&
      !editableArSummaryData?.inProgress &&
      !arSummaryData?.rejected && (
        <div className="editable-section">
          <div className="flex-end mb-3" data-testid="button-groups">
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={removeRow}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          {arSummaryData && (
            <>
              <table className="table-border" data-testid="register-table">
                <colgroup>
                  <col style={{ width: '70px' }} />
                  {values(fieldNames).map((_, index) => (
                    <col key={index} />
                  ))}
                  {checkedValidation.length > 0 && <col />}
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="text-center">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          value=""
                          id="allCheck2"
                          onChange={handleCheckAll}
                          checked={checkedRows.length === fields.length}
                        />
                      </div>
                    </th>
                    {values(fieldNames).map(({ name }, index) => (
                      <th key={index} scope="col">
                        {name}
                      </th>
                    ))}
                    {checkedValidation.length > 0 && <th scope="col">{t('text:Validation_Result')}</th>}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {fields.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td className="bg-sub100" key={index}>
                          <div className="text-center">
                            <input
                              className="form-check-input m-0"
                              type="checkbox"
                              onChange={e => handleCheckChange(e, index)}
                              checked={checkedRows.includes(index)}
                            />
                          </div>
                        </td>

                        <td className={getValidationClassName('arNumber', 'td', index)}>
                          <input
                            className={getValidationClassName('arNumber', 'input', index)}
                            name={`arList.${index}.arNumber`}
                            defaultValue={item.arNumber}
                            ref={register({
                              required: true,
                              validate: value => apNumberDuplicateValidator(value, index),
                            })}
                          />
                        </td>
                        <td className={getValidationClassName('arIssuedDate', 'td', index)}>
                          <div className="position-parent">
                            <Controller
                              control={control}
                              defaultValue={item.arIssuedDate}
                              name={`arList.${index}.arIssuedDate`}
                              render={field => (
                                <ReactDatePicker
                                  field={field}
                                  className={getValidationClassName('arIssuedDate', 'input', index)}
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                        </td>
                        <td className={getValidationClassName('settlementDate', 'td', index)}>
                          <div className="position-parent">
                            <Controller
                              control={control}
                              defaultValue={item.settlementDate}
                              name={`arList.${index}.settlementDate`}
                              render={field => (
                                <ReactDatePicker
                                  field={field}
                                  className={getValidationClassName('settlementDate', 'input', index)}
                                  minDate={settlementDatePeriod.minimumDate}
                                  maxDate={settlementDatePeriod.maximumDate}
                                  excludeDates={removeRangeFromHoliday(financierHoliday?.content)}
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                        </td>
                        <td className={getValidationClassName('arAmount', 'td', index)}>
                          <NumericFormatInput
                            control={control}
                            name={`arList.${index}.arAmount`}
                            numberType="bigNumber"
                            className="grid-input"
                            defaultValue={item.arAmount}
                            currencyType={arSummaryData?.currencyType}
                            onChange={updateTotalApAmount}
                            rules={{
                              validate: value =>
                                invoiceOrArAmountInputValidate(value, arSummaryData?.currencyType, 'AR'),
                              required: true,
                              min: 0.01,
                            }}
                            error={errors.arList && errors.arList[index]?.arAmount}
                          />
                        </td>
                        {checkedValidation[index] ? getValidationResult(index) : checkedValidation.length > 0 && <td />}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="grid-total" data-testid="total">
                {t('text:Total')} : {t('format:number', { value: totalApAmount })}
              </div>
            </>
          )}
          <div className="flex-end mt-3">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={onClickReject}
              className="me-2"
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Reject')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onClickRegister}>
              {t('text:Register')}
            </Button>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AR_Confirmation_Details')} />
      {arSummaryData?.rejected && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[t('text:The_AR_registration_request_was_rejected'), t('text:Please_check_the_reason_below')]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={arSummaryData.rejectReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Registration_Status')} col={4} value={renderProgressStatus()} />
              <FormValue
                label={t('text:Number_of_Valid_AR')}
                col={4}
                className="information-form__input border-none pointfont"
                value={arSummaryData?.currentApprovalCount}
                format="number"
              />
              <FormValue
                label={t('text:Total_Amount_of_Valid_AR')}
                col={4}
                className="information-form__input border-none pointfont"
                value={arSummaryData?.currentApprovalAmount}
                format="number"
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Anchor_Master_Agreement_Information')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} col={3} value={arSummaryData?.financierEnterpriseName} />
              <FormValue label={t('text:Anchor_Name')} col={3} value={arSummaryData?.anchorClientName} />
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                col={3}
                value={arSummaryData?.anchorContractNo}
              />
              <FormValue label={t('text:Currency')} col={3} value={arSummaryData?.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {editableArSummaryData?.authorized &&
        !editableArSummaryData?.inProgress &&
        !arSummaryData?.rejected &&
        editableArSummaryData.collateralIssuedLimitCheck && (
          <div className="content-area">
            <SectionTitle title={t('text:AR_Registration_Limit')} />
            <div className="information-form__corporation">
              <div className="corporation-section">
                <div className="row">
                  <div className="col-6">{t('text:AR_Issuance_Limit')} ①</div>
                  <div className="col-6">
                    {t('format:number', { value: editableArSummaryData?.collateralIssuedLimitAmount })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Registration_Confirmed_AR')} ②</div>
                  <div className="col-6">
                    {t('format:number', { value: editableArSummaryData?.settlementWaitingInvoiceAmount })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Registration_Requested_AR')} ③</div>
                  <div className="col-6">
                    {t('format:number', { value: editableArSummaryData?.registeredWaitingArAmount })}
                  </div>
                </div>
              </div>
              <div className="corporation-section">
                <div className="row">
                  <div className="col-6">{t('text:Issuance_Limit_Available_for_Registration')} ④ = ① - ② - ③</div>
                  <div className="col-6 blackfont">
                    {t('format:number', {
                      value: editableArSummaryData?.remainingRegistrationLimit,
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      <div className="content-area">
        <SectionTitle title={t('text:AR_List')} />
        {renderLastPhase()}
        {arSummaryData &&
          arSummaryPhaseDataList?.content.map(arSummaryPhase => {
            return (
              <ApAccordionList
                onAccordionClick={() => handleArSummaryPhaseAccordionClick(arSummaryPhase)}
                searchedWaitingApprovalPhaseId={searchedWaitingApprovalPhaseId}
                setSearchedWaitingApprovalPhaseId={setSearchedWaitingApprovalPhaseId}
                arSummaryData={arSummaryData}
                arSummaryPhase={arSummaryPhase}
                key={arSummaryPhase.waitingArApprovalPhaseId}
              />
            );
          })}
      </div>
    </>
  );
}

export default DealerApConfirmationDetail;
