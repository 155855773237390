import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE, ENTERPRISE_TYPE } from 'enums';
import { WAITING_INVOICE_APPROVAL_PHASE_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierInvoiceSummariesList } from 'utils/http/api/financier/invoice-summaries';
import type { FinancierInvoiceSummaryListRequest } from 'utils/http/api/financier/invoice-summaries/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const FI_INVOICE_CONFIRMATION_LIST_QS_KEY = 'fi-invoice-confirmation-list';

function FinancierInvoiceConfirmationList() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const { pageable, setPageable } = usePageable(FI_INVOICE_CONFIRMATION_LIST_QS_KEY);
  const [invoiceSummaryPageData, setInvoiceSummaryPageData] = useState<Pageable<InvoiceSummaryVOModel[]>>();

  const {
    register: searchFormRegister,
    getValues: getSearchFormValues,
    reset: searchFormReset,
    setValue: setSearchValue,
    control: searchFormControl,
  } = useForm<FinancierInvoiceSummaryListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierInvoiceSummaryListRequest>(
        setSearchValue,
        getParsedSearchParams(FI_INVOICE_CONFIRMATION_LIST_QS_KEY).formSearchData,
      );
      fetchFiInvoiceSummaryList(pageable.currentPage, pageable.sizePerPage, getSearchFormValues());
    }

    return () => {};
  }, [mounted]);

  const fetchFiInvoiceSummaryList = async (
    selectedPageNumber: number = 1,
    selectedRowNumber: number = 10,
    searchData: FinancierInvoiceSummaryListRequest,
  ) => {
    try {
      const invoiceSummaryPageResponse = await requestFinancierInvoiceSummariesList(
        selectedPageNumber,
        selectedRowNumber,
        searchData,
      );
      updateSearchParams(
        {
          ...searchData,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowNumber,
        },
        FI_INVOICE_CONFIRMATION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setInvoiceSummaryPageData(invoiceSummaryPageResponse);
        setPageable(invoiceSummaryPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginate = async (selectedPageNumber: number, selectedRowNumber: number) => {
    await fetchFiInvoiceSummaryList(
      selectedPageNumber,
      selectedRowNumber,
      getParsedSearchParams(FI_INVOICE_CONFIRMATION_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();
    fetchFiInvoiceSummaryList(0, pageable.sizePerPage, getSearchFormValues());
  };

  const onClickRemove = (e: any) => {
    e.preventDefault();

    searchFormReset({
      createdDateFrom: '',
      createdDateTo: '',
      anchorClientName: '',
      anchorContractNo: '',
      dealerClientName: '',
      dealerContractNo: '',
      currencyType: undefined,
      billOfExchangeNo: undefined,
      minimumCurrentApprovalCount: undefined,
      maximumCurrentApprovalCount: undefined,
      minimumCurrentApprovalAmount: undefined,
      maximumCurrentApprovalAmount: undefined,
      currentConfirmDateFrom: '',
      currentConfirmDateTo: '',
      currentConfirmEnterpriseType: undefined,
      currentInvoiceApprovalPhaseStatus: undefined,
      exceptedInvoiceApprovalPhaseStatus: undefined,
      rejected: undefined,
    });
  };

  const setProgressStatusClassName = (code: WAITING_INVOICE_APPROVAL_PHASE_STATUS, rejected: boolean) => {
    if (rejected) {
      return getStatusTextClass(
        'WAITING_INVOICE_APPROVAL_PHASE_STATUS',
        WAITING_INVOICE_APPROVAL_PHASE_STATUS.REJECTED,
      );
    } else {
      return getStatusTextClass('WAITING_INVOICE_APPROVAL_PHASE_STATUS', code);
    }
  };

  const onClickStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(WAITING_INVOICE_APPROVAL_PHASE_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="WAITING_INVOICE_APPROVAL_PHASE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderInvoiceSummariesTable = (): JSX.Element[] | JSX.Element | undefined => {
    return invoiceSummaryPageData?.content.map((invoiceSummaryData, index: number) => (
      <Tr key={index}>
        <Td data={invoiceSummaryData.createDateTime} format="datetime" />
        <Td data={invoiceSummaryData.anchorClientName} />
        <Td data={invoiceSummaryData.anchorContractNo} />
        <Td data={invoiceSummaryData.dealerClientName} />
        <Td data={invoiceSummaryData.dealerContractNo} />
        <Td data={invoiceSummaryData.currencyType} className="text-center" />
        <Td data={invoiceSummaryData.billOfExchangeNo} />
        <Td data={invoiceSummaryData.rejected ? 'Y' : 'N'} className="text-center" />
        <Td
          data={
            invoiceSummaryData.rejected
              ? '-'
              : t(`code:current-invoice-approval-phase-status.${invoiceSummaryData.currentInvoiceApprovalPhaseStatus}`)
          }
          className={setProgressStatusClassName(
            invoiceSummaryData.currentInvoiceApprovalPhaseStatus,
            invoiceSummaryData.rejected,
          )}
        />
        <Td data={t(`code:enterprise-type.${invoiceSummaryData.currentConfirmEnterpriseType}`)} />
        <Td data={invoiceSummaryData.currentInvoiceApprovalDateTime} format="datetime" />
        <Td data={invoiceSummaryData.rejected ? '-' : invoiceSummaryData.currentApprovalCount} format="number" />
        <Td data={invoiceSummaryData.rejected ? '-' : invoiceSummaryData.currentApprovalAmount} format="number" />
        <TdLink
          path={ROUTES_FI.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(
            invoiceSummaryData.invoiceSummaryId,
            invoiceSummaryData.financierEnterpriseId,
          )}
          state={{ dealerAgreementId: invoiceSummaryData.dealerAgreementId }}
        />
      </Tr>
    ));
  };

  // TODO: 결과 테이블 순서 수정 필요
  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Registration_Initiated_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 96,
    },
    {
      headerText: t('text:BOE_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registration_Rejection'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registration_Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickStatus,
    },
    {
      headerText: t('text:Validated_By'),
      colWidths: 100,
    },
    {
      headerText: t('text:Validation_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Number_of_Valid_Invoices'),
      colWidths: 96,
    },
    {
      headerText: t('text:Total_Amount_of_Valid_Invoices'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <HeaderTitle title={t('text:Invoice_Confirmation')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemove}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Initiated_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="createdDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="createdDateTo" control={searchFormControl} />
              <SearchEmpty />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={searchFormRegister} />
              <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
              <SearchInput name="anchorContractNo" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="dealerClientName" ref={searchFormRegister} />
              <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
              <SearchInput name="dealerContractNo" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={searchFormRegister}
              />
              <SearchLabel label={t('text:BOE_Number')} />
              <SearchInput name="billOfExchangeNo" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Registration_Rejection')} />
              <SearchSelect
                name="rejected"
                selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                ref={searchFormRegister}
              />
              <SearchLabel
                label={t('text:Registration_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickStatus}
              />
              <SearchSelect
                name="currentInvoiceApprovalPhaseStatus"
                selectOptions={getSelectOptions<WAITING_INVOICE_APPROVAL_PHASE_STATUS>(
                  'WAITING_INVOICE_APPROVAL_PHASE_STATUS',
                  'ALL',
                  true,
                ).filter(item => item.value !== WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED)}
                ref={searchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Validated_By')} />
              <SearchSelect
                name="currentConfirmEnterpriseType"
                selectOptions={getSelectOptions<ENTERPRISE_TYPE>('ENTERPRISE_TYPE', 'ALL', true)}
                ref={searchFormRegister}
              />
              <SearchLabel label={t('text:Validation_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="currentConfirmDateFrom"
                control={searchFormControl}
              />
              <SearchDatePicker placeholder={t('text:to')} name="currentConfirmDateTo" control={searchFormControl} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Number_of_Valid_Invoices')} />
              <SearchInput
                name="minimumCurrentApprovalCount"
                ref={searchFormRegister}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name="maximumCurrentApprovalCount"
                ref={searchFormRegister}
                col={2}
                placeholder={t('text:to')}
              />
              <SearchLabel label={t('text:Total_Amount_of_Valid_Invoices')} />
              <SearchInput
                name="minimumCurrentApprovalAmount"
                ref={searchFormRegister}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name="maximumCurrentApprovalAmount"
                ref={searchFormRegister}
                col={2}
                placeholder={t('text:to')}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {invoiceSummaryPageData?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderInvoiceSummariesTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierInvoiceConfirmationList;
