import './StepWizard.scss';

interface PropsType {
  nth: number;
  title: string[];
}

const StepWizard = ({ nth, title }: PropsType) => {
  const getStepClassName = (i: number) => {
    if (i === 0) {
      return 'first-step';
    } else if (i === title.length - 1) {
      return 'last-step';
    } else {
      return 'middle-step';
    }
  };

  return (
    <div className="step-wizard">
      {title.map((item, i) => (
        <div key={i} className={`step-wizard__item ${nth === i + 1 && 'on'} ${getStepClassName(i)}`}>
          <div className="step-wizard__badge">{i + 1}</div>
          <div className="step-wizard__title">{item}</div>
        </div>
      ))}
    </div>
  );
};

export default StepWizard;
