import type Pageable from 'models/Pageable';
import type { AnchorReportVO, AnchorReportVOModel } from 'models/vo/AnchorReportVO';
import { formattingToAnchorReportVOModel } from 'models/vo/AnchorReportVO';
import http from 'utils/http';
import type {
  AnchorReportListRequest,
  DealerReportListRequest,
} from 'utils/http/api/financier/anchor-reports/requests';
import API_SY from 'utils/http/api/system';

export async function requestSyAnchorReportList<T extends AnchorReportListRequest | DealerReportListRequest>(
  pageNumber: number,
  rowCount: number,
  data: T,
): Promise<Pageable<AnchorReportVOModel[]>> {
  const response = await http.get<Pageable<AnchorReportVO[]>>({
    url: API_SY.ANCHOR_REPORTS.ANCHOR_REPORT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map((data: AnchorReportVO) => formattingToAnchorReportVOModel(data)),
  };
}

export async function requestSyAnchorReportDownload(anchorReportId: number): Promise<void> {
  await http.download(API_SY.ANCHOR_REPORTS.DOWNLOAD_REPORT(anchorReportId));
}
