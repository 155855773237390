import type { HTMLAttributes } from 'react';
import type React from 'react';

import './Card.scss';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  bgColor?: string;
  fontColor?: string;
  style?: React.CSSProperties;
  className?: string;
}

function Card({
  className = 'simple-card',
  bgColor = '#ffffff',
  fontColor,
  style,
  children,
  ...props
}: CardProps): JSX.Element {
  const styles = {
    ...style,
    backgroundColor: bgColor,
    color: fontColor,
  };

  return (
    <div className={className} style={styles} {...props}>
      {children}
    </div>
  );
}

export default Card;
