import type { RefObject } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import type { ConfirmCheckBoxRefHandle } from 'components/stateless/CheckBox/ConfirmCheckBox';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import useMounted from 'hooks/useMounted';
import useScrollIntoView from 'hooks/useScrollIntoView';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import CompanyInformation from './sections/companyInfo';
import FinancingRequest from './sections/financing-request';
import { useAnchorArBulkFinancingApplicationStep2State } from './useAnchorArBulkFinancingApplicationStep2State';
import DesignatedBankAccountInfo from '../ar/sections/designated-bank-account-info';
import AdditionalDocuments from '../section/additional-documents';

function AnchorArBulkFinancingApplicationStep2() {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const [estimateButtonRef, scrollToEstimateButton] = useScrollIntoView<HTMLDivElement>();

  const {
    state,
    fetchAll,
    anchorArBulkFinancingUseForm,
    fetchAnchorArBulkCalculateLoanRequest,
    handleCheckAll,
    handleCheckChange,
    calculateRequestedAmount,
    totalRequestedAmount,
    etcFileRowsState,
    showCancelFinancingModal,
    showLoanRequestModal,
    getCheckedLoanByArRequestList,
    initializeFinancingConditionInfo,
  } = useAnchorArBulkFinancingApplicationStep2State();

  const { checkedArList, anchorFinancingOptionDetail } = state;

  const { etcFileRows } = etcFileRowsState;

  const [disbursementDateRef, scrollToDisbursementDate] = useScrollIntoView<HTMLDivElement>();
  const [arListTableRef, scrollToArListTable] = useScrollIntoView<HTMLDivElement>();

  const [financingCheckBoxToggle, setFinancingCheckBoxToggle] = useState(false);
  const [bankAccountCheckBoxToggle, setBankAccountCheckBoxToggle] = useState(false);

  const financingCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);
  const bankAccountCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);

  const { getValues, watch, setError, clearErrors } = anchorArBulkFinancingUseForm;
  const { repaymentDate } = watch();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    calculateRequestedAmount(checkedArList);
  }, [checkedArList]);

  const confirmCheckBoxValidator = (): boolean => {
    let validationResult = true;

    const alertModal = (ref: RefObject<ConfirmCheckBoxRefHandle>) => {
      modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          if (ref.current) {
            ref.current.focus();
          }
        },
      });
    };

    if (!financingCheckBoxToggle) {
      alertModal(financingCheckBoxRef);
      validationResult = false;
    } else if (!bankAccountCheckBoxToggle && state.showDisbursementAccountInfo) {
      alertModal(bankAccountCheckBoxRef);
      validationResult = false;
    }

    return validationResult;
  };

  const financingRequestValidator = async (): Promise<boolean> => {
    const checkedLoanByArRequestList = getCheckedLoanByArRequestList();
    let agreementLimitExceededErrorCount = 0;

    const isLoanLimitCheck = anchorFinancingOptionDetail?.loanLimitCheck;

    if (
      isLoanLimitCheck &&
      Number(totalRequestedAmount) > Number(anchorFinancingOptionDetail?.maxAgreementLoanCapacityAmount)
    ) {
      agreementLimitExceededErrorCount++;
    }
    if (!repaymentDate) {
      modal.show(<h6>{t('text:Please_select_the_Requested_Repayment_Date_for_financing')}</h6>, {
        closeBtnCb: () => scrollToDisbursementDate(),
      });

      return false;
    } else if (agreementLimitExceededErrorCount) {
      modal.show(<h6>{t('text:The_financing_amount_exceeds_the_Financing_Limit')}</h6>, {
        closeBtnCb: () => scrollToArListTable(),
      });

      return false;
    } else if (checkedLoanByArRequestList.length === 0) {
      modal.show(<h6>{t('text:Please_enter_the_Requested_Financing_Amount')}</h6>, {
        closeBtnCb: () => scrollToArListTable(),
      });

      return false;
    }

    return true;
  };

  const loanEtcFileAttachmentValidator = (): boolean => {
    const data = getValues();
    let validationResult = true;

    const emptyFile = (id: number, fileName?: string) => data.etcAttachments?.[id]?.length === 0 && !fileName;
    const hasDescription = (id: number) => Boolean(data?.etcAttachmentDescriptions?.[id]);

    for (let i = 0; i < etcFileRows?.length; i++) {
      const item = etcFileRows?.[i];
      const onlyDescriptionAndNoFile = emptyFile(item?.id, item?.fileName) && hasDescription(item?.id);

      if (onlyDescriptionAndNoFile) {
        modal.show(<h6>{t('text:Attach_a_file_to_the_Additional_Documents_section')}</h6>, {
          closeBtnCb: () => {
            setError(`loanEtcAttachments${item?.id}`, { shouldFocus: true });
          },
        });
        validationResult = false;
        break;
      }
    }

    return validationResult;
  };

  const onChangeFinancingCheckBoxToggle = (checked: boolean) => {
    setFinancingCheckBoxToggle(checked);
  };

  const onChangeBankAccountCheckBoxToggle = (checked: boolean) => {
    setBankAccountCheckBoxToggle(checked);
  };

  const validate = async () => {
    clearErrors();

    if (!confirmCheckBoxValidator()) return false;
    else if (!(await financingRequestValidator())) return false;
    else if (!loanEtcFileAttachmentValidator()) return false;
    else if (isEmpty(state.calculateLoanRequest)) {
      modal.show(<h6>{t('text:Please_check_the_estimate_button')}</h6>, {
        closeBtnCb: () => scrollToEstimateButton(),
      });

      return false;
    } else {
      await showLoanRequestModal();
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Application')} />
      <FormProvider {...anchorArBulkFinancingUseForm}>
        <form>
          <div className="content-area">
            <StepWizard nth={2} title={[t('text:Select_AP'), t('text:Financing_Request')]} />
          </div>

          <div className="content-area">
            <CompanyInformation data={anchorFinancingOptionDetail} />
          </div>
          <div className="content-area">
            <FinancingRequest
              anchorArBulkApplicationStep2State={state}
              fetchAnchorArBulkCalculateLoanRequest={fetchAnchorArBulkCalculateLoanRequest}
              totalRequestedAmount={totalRequestedAmount}
              handleCheckAll={handleCheckAll}
              handleCheckChange={handleCheckChange}
              disbursementDateRef={disbursementDateRef}
              arListTableRef={arListTableRef}
              getCheckedLoanByArRequestList={getCheckedLoanByArRequestList}
              estimateButtonRef={estimateButtonRef}
              initializeFinancingConditionInfo={initializeFinancingConditionInfo}
            />
            <ConfirmCheckBox
              id="flexCheckDefault1-1"
              checked={financingCheckBoxToggle}
              onChangeCheckBox={onChangeFinancingCheckBoxToggle}
              labelText={t('text:I_have_checked_that_the_AP_information_settlement_date_and_financing_conditions')}
              ref={financingCheckBoxRef}
            />
          </div>
          <div className="content-area">
            <DesignatedBankAccountInfo
              anchorFinancingOptionDetail={anchorFinancingOptionDetail}
              showDisbursementAccountInfo={state.showDisbursementAccountInfo}
            />
            {state.showDisbursementAccountInfo && (
              <ConfirmCheckBox
                id="flexCheckDefault1-2"
                checked={bankAccountCheckBoxToggle}
                onChangeCheckBox={onChangeBankAccountCheckBoxToggle}
                labelText={t('text:I_have_checked_the_information_of_the_designated_bank_accounts')}
                ref={bankAccountCheckBoxRef}
              />
            )}
          </div>
          <div className="content-area">
            <AdditionalDocuments {...etcFileRowsState} />
          </div>

          <div className="content-area d-flex">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={showCancelFinancingModal}
              className="me-auto"
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={validate} className="ms-2">
              {t('text:Register')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default AnchorArBulkFinancingApplicationStep2;
