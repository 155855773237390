import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { AUTHORITY_TYPE } from 'enums';
import useProperty from 'hooks/useProperty';
import { formErrorHandler } from 'utils/error/manager';
import { requestDealerUserRegister } from 'utils/http/api/dealer/users';
import type { DealerUserRegisterRequest } from 'utils/http/api/dealer/users/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function DealerUserRegister() {
  const { t } = useTranslation();
  const modal = useModal();
  const getProperty = useProperty<DealerUserRegisterRequest>();
  const history = useHistory();

  const { register, getValues, errors, setError, clearErrors, handleSubmit } = useForm<DealerUserRegisterRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const onClickRegister = async () => {
    const requestData = getValues();
    requestData.authorityType = AUTHORITY_TYPE.OPERATOR; // Authority 타입은 operator 로 고정, authorizer 는 은행 초대로만 가능
    try {
      requestDTOParser(requestData);
      await requestDealerUserRegister(requestData);
      modal.show(
        <h6>
          {t('text:User_registration_is_complete')}
          <br />
          {t('text:Click_the_Send_button_to_send_an_invitation_mail')}
        </h6>,
        {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_DE.COMPANY.USER_LIST),
        },
      );
    } catch (e) {
      modal.show(e);
      formErrorHandler<DealerUserRegisterRequest>(e, setError, clearErrors);
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:User_Registration')} />
      <div className="content-area">
        <FormBorder editable={true}>
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Name')}
                name={getProperty('userName')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.userName}
              />
              <FormInput
                label={t('text:Employee_Code')}
                name={getProperty('employeeCode')}
                ref={register}
                error={errors.employeeCode}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Department')}
                name={getProperty('department')}
                ref={register}
                error={errors.department}
              />
              <FormInput
                label={t('text:Title')}
                name={getProperty('position')}
                ref={register}
                error={errors.position}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Mobile')}
                name={getProperty('mobile')}
                ref={register}
                error={errors.mobile}
                requiredOptions={{ required: true }}
              />
              <FormInput
                label={t('text:Telephone')}
                name={getProperty('telephone')}
                ref={register}
                error={errors.telephone}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Email')}
                name={getProperty('email')}
                ref={register}
                error={errors.email}
                requiredOptions={{ required: true }}
              />
              <FormValue label={t('text:Authority')} value={t('text:Operator')} className="information-form__input" />
            </div>
          </FormContents>
        </FormBorder>
        <div className="flex-end">
          <Button onClick={handleSubmit(onClickRegister)} className="mt-4" size={ButtonSizeEnum.LG}>
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default DealerUserRegister;
