import type Pageable from 'models/Pageable';
import type { AnchorReportSettingVO, AnchorReportSettingVOModel } from 'models/vo/AnchorReportSettingVO';
import { formattingToAnchorReportSettingVOModel } from 'models/vo/AnchorReportSettingVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemAnchorReportSettingListRequest } from './requests';

export async function requestSystemAnchorReportSettingList(
  pageNumber: number,
  rowCount: number,
  data: SystemAnchorReportSettingListRequest,
): Promise<Pageable<AnchorReportSettingVOModel[]>> {
  const response = await http.get<Pageable<AnchorReportSettingVO[]>>({
    url: API_SY.ANCHOR_REPORT_SETTINGS.ANCHOR_REPORT_SETTING_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToAnchorReportSettingVOModel(item)),
  };
}

export async function requestSystemAnchorReportSettingData(
  anchorReportSettingId: number,
): Promise<AnchorReportSettingVOModel> {
  const response = await http.get<AnchorReportSettingVO>({
    url: API_SY.ANCHOR_REPORT_SETTINGS.ANCHOR_REPORT_SETTING_DETAIL(anchorReportSettingId),
  });

  return formattingToAnchorReportSettingVOModel(response);
}
