import { useTranslation } from 'react-i18next';

import Form, { useFormContext } from 'components/stateless/Form';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { Content, Row, SubTitle } from 'components/templates/section';
import { useUppercaseInput } from 'hooks/useUppercaseInput';

const RepaymentAccountInterest = () => {
  const { t } = useTranslation();

  const {
    methods: { register },
  } = useFormContext();
  const handleUppercaseInput = useUppercaseInput();

  return (
    <>
      <SubTitle>{t('text:REPAYMENT_ACCOUNT_-_INTEREST')}</SubTitle>
      <Content>
        <Row singleColumnPosition="top">
          <SectionMessage
            messageType="description"
            message={[t('text:The_financing_interest_amount_will_be_collected_from_this_bank_account_for_repayment')]}
          />
          <Form.Control name="interestRepaymentAccountFinancierName">
            <Form.Label>{t('text:Bank_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
          <Form.Control name="interestRepaymentAccountBranchName">
            <Form.Label>{t('text:Branch_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
          <Form.Control name="interestRepaymentAccount">
            <Form.Label>{t('text:Bank_Account_Number')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
          <Form.Control name="interestRepaymentAccountOwner">
            <Form.Label>{t('text:Bank_Account_Holder')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
          <input type="hidden" name="interestRepaymentAccountFinancierCode" ref={register} />
          <input type="hidden" name="interestRepaymentAccountBranchCode" ref={register} />
        </Row>
      </Content>
    </>
  );
};

export default RepaymentAccountInterest;
