import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isEmpty, isEqual } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { BranchHistoryVOModel } from 'models/vo/BranchHistoryVO';
import type { BranchVOModel } from 'models/vo/BranchVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestBranchDetail, requestBranchEdit, requestBranchHistoryList } from 'utils/http/api/financier/branches';
import type { FinancierBranchUpdateRequest } from 'utils/http/api/financier/branches/request';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType, getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import UpdateBranchModal from '../register/Modals/UpdateBranchModal';

function FinancierBranchDetail() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable();
  const modal = useModal();
  const errorAlert = useModal();
  const [branchDetail, setBranchDetail] = useState<BranchVOModel>();
  const [branchInterfaceDetail, setBranchInterfaceDetail] = useState<BranchVOModel>();

  const signInModel: SignInModel | null = getSignIn();
  const { branchId } = useParams<any>();
  const [branchInterfaceEnable] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.BRANCH));

  const [BranchHistoryPage, setBranchHistoryPage] = useState<Pageable<BranchHistoryVOModel[]>>();

  // edit 활성화
  const [btnEditActive, setBtnEditActive] = useState<boolean>(false);

  const { register, handleSubmit, reset, getValues, errors, setError, clearErrors } =
    useForm<FinancierBranchUpdateRequest>({
      mode: 'onSubmit',
      shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
    });

  useEffect(() => {
    if (mounted) {
      fetchAll(pageable.currentPage, pageable.sizePerPage);
    }
  }, [mounted]);

  const fetchAll = async (pageNumber: number = 1, sizePerPage: number = 10) => {
    try {
      const fetchedBranchDetail = requestBranchDetail(branchId);
      const branchDetailData = await fetchedBranchDetail;

      const BranchHistoryResponse = await requestBranchHistoryList(branchId, pageNumber, sizePerPage);

      ReactDOM.unstable_batchedUpdates(() => {
        setBranchDetail(branchDetailData);
        setBranchInfoForm(branchDetailData);

        // history
        setBranchHistoryPage(BranchHistoryResponse);
        setPageable(BranchHistoryResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  // edit button handler
  const handleEdit = (e: any) => {
    e.preventDefault();
    setBtnEditActive(true);
  };

  const setBranchInfoForm = (data: BranchVOModel | undefined) => {
    reset({
      branchCode: data?.branchCode,
      branchName: data?.branchName,
      branchBusinessCode: data?.branchBusinessCode,
      telephone: data?.telephone,
      fax: data?.fax,
      managerName: data?.managerName,
      managerPosition: data?.managerPosition,
      managerEmail: data?.managerEmail,
      address: data?.address,
    });
  };

  // cancel button handler
  const handleCancel = (e: any) => {
    e.preventDefault();
    const cancelModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Close'),
      confirmBtnCb: async () => {
        try {
          setBtnEditActive(false);
          setBranchInfoForm(branchDetail);
        } catch (e) {
          errorAlert.show(e);
        }
      },
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information?')}
        <br />
        {t('text:If_the_modification_is_aborted,_the_entered_content_will_not_be_saved')}
      </h6>,
      cancelModalOptions,
    );
  };

  // history list pagination
  const paginate = async (pageNumber: number, sizePerPage: number) => {
    await fetchedBranchHistory(pageNumber, sizePerPage);
  };

  // Save
  const submitChangeSave = async () => {
    try {
      const data = getValues();

      requestDTOParser(data);

      await requestBranchEdit(branchId, data);
      showSaveConfirmModal();
    } catch (e) {
      errorAlert.show(e);

      formErrorHandler<FinancierBranchUpdateRequest>(e, setError, clearErrors);
    }
  };

  // Save - 정상
  const showSaveConfirmModal = async () => {
    setBtnEditActive(false);
    const saveModalOptions: ModalOptions = {
      modalType: ModalType.ALERT,
      title: t('text:Notice'),
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        try {
          fetchAll();
        } catch (e) {
          errorAlert.show(e);
        }
      },
    };
    modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, saveModalOptions);
  };

  const showUpdateBranchModal = async (e: any) => {
    e.preventDefault();

    let interfaceData: BranchVOModel;

    modal.show(
      <UpdateBranchModal
        branchData={branchDetail}
        getUpdatedBranchData={data => {
          interfaceData = { ...data };
        }}
        modalId={modal.id}
      />,
      {
        modalType: ModalType.CONFIRM,
        modalSize: ModalSize.XL,
        title: t('text:Search_Branch'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Apply'),
        confirmBtnCb: () => {
          if (!isEqual(branchInterfaceDetail, interfaceData) && !isEmpty(interfaceData)) {
            setBranchInterfaceDetail(interfaceData);
            setBranchInfoForm(interfaceData);
          }
        },
      },
    );
  };

  const fetchedBranchHistory = async (pageNumber: number, sizePerPage: number) => {
    try {
      const BranchHistoryResponse = await requestBranchHistoryList(branchId, pageNumber, sizePerPage);

      ReactDOM.unstable_batchedUpdates(() => {
        setBranchHistoryPage(BranchHistoryResponse);
        setPageable(BranchHistoryResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 110,
    },
    {
      headerText: t('text:Branch_Code'),
      colWidths: 110,
    },
    {
      headerText: t('text:Branch_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 150,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 310,
    },
    {
      headerText: t('text:Edited_By'),
      colWidths: 100,
    },
  ];
  const renderBranchListTable = () => {
    return BranchHistoryPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.updateDateTime} format="datetime" />
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td data={item.managerName} />
          <Td data={item.telephone} />
          <Td data={item.address} />
          <Td data={item.updateUserName} />
        </Tr>
      );
    });
  };

  const isReadOnly = (parameterName: string): boolean => {
    if (btnEditActive) {
      if (branchInterfaceEnable) {
        if (!branchInterfaceDetail) return true;
        if (!(branchInterfaceDetail as any)[parameterName]) return false;

        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Branch_Information_Details')} />
      {btnEditActive && signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && (
        <GuideMessage
          message={[
            t('text:Please_enter_branch_and_legal_representative_information'),
            <Trans
              key="key"
              i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
              components={{ 1: <span className="asterisk" /> }}
            />,
          ]}
        />
      )}
      <div className="content-area">
        {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && (
          <SectionTitle>
            {btnEditActive ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={handleCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={handleSubmit(submitChangeSave)} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button size={ButtonSizeEnum.SM} onClick={handleEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            )}
          </SectionTitle>
        )}
        <FormBorder editable={btnEditActive}>
          <FormContents>
            {branchInterfaceEnable && btnEditActive && (
              <div className="row">
                <div className="col-12">
                  <Button size={ButtonSizeEnum.LG} onClick={showUpdateBranchModal}>
                    {t('text:Update_Branch')}
                  </Button>
                </div>
              </div>
            )}
            <div className="row">
              <FormInput
                label={t('text:Branch_Code')}
                name="branchCode"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={isReadOnly('branchCode')}
                error={errors.branchCode}
              />
              <FormInput
                label={t('text:Branch_Name')}
                name="branchName"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={isReadOnly('branchName')}
                error={errors.branchName}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Business_Registration_Number')}
                name="branchBusinessCode"
                ref={register}
                disabled={isReadOnly('branchBusinessCode')}
                error={errors.branchBusinessCode}
              />
              <FormInput
                col={3}
                label={t('text:Telephone')}
                name="telephone"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={isReadOnly('telephone')}
                error={errors.telephone}
              />
              <FormInput
                col={3}
                label={t('text:Fax')}
                name="fax"
                ref={register}
                disabled={isReadOnly('fax')}
                error={errors.fax}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Legal_Representative_Name')}
                name="managerName"
                ref={register}
                disabled={isReadOnly('managerName')}
                error={errors.managerName}
              />
              <FormInput
                col={3}
                label={t('text:Legal_Representative_Title')}
                name="managerPosition"
                ref={register}
                disabled={isReadOnly('managerPosition')}
                error={errors.managerPosition}
              />
              <FormInput
                col={3}
                label={t('text:Legal_Representative_Email')}
                name="managerEmail"
                ref={register}
                disabled={isReadOnly('managerEmail')}
                error={errors.managerEmail}
              />
            </div>
            <div className="row">
              <FormInput
                col={12}
                label={t('text:Registered_Office_Address')}
                name="address"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={isReadOnly('address')}
                error={errors.address}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderBranchListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierBranchDetail;
