import type Pageable from 'models/Pageable';
import type { WaitingInvoiceVO, WaitingInvoiceVOModel } from 'models/vo/WaitingInvoiceVO';
import { formattingToWaitingInvoiceVOModel } from 'models/vo/WaitingInvoiceVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

// 송장 결재별 송장 목록 조회
export async function requestAnchorWaitingInvoiceList(
  pageNumber: number,
  rowCount: number,
  waitingInvoiceApprovalPhaseId?: number,
): Promise<Pageable<WaitingInvoiceVOModel[]>> {
  const response = await http.get<Pageable<WaitingInvoiceVO[]>>({
    url: API_AC.WAITING_INVOICES.WAITING_INVOICE_LIST,
    data: {
      pageNumber,
      rowCount,
      waitingInvoiceApprovalPhaseId,
    },
  });
  const waitingInvoiceList: WaitingInvoiceVOModel[] = response.content.map((data: WaitingInvoiceVO) =>
    formattingToWaitingInvoiceVOModel(data),
  );
  const waitingInvoicePage: Pageable<WaitingInvoiceVOModel[]> = {
    ...response,
    content: waitingInvoiceList,
  };

  return waitingInvoicePage;
}
