import type Pageable from 'models/Pageable';
import type { PlatformTransactionVO, PlatformTransactionVOModel } from 'models/vo/PlatformTransactionVO';
import { formattingToPlatformTransactionVOModel } from 'models/vo/PlatformTransactionVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemPlatformTransactionListRequest } from './requests';

// 플랫폼 수수료 조회
export async function requestSystemPlatformTransactionFeeList(
  pageNumber: number,
  rowCount: number,
  data?: SystemPlatformTransactionListRequest,
): Promise<Pageable<PlatformTransactionVOModel[]>> {
  const response = await http.get<Pageable<PlatformTransactionVO[]>>({
    url: API_SY.PLATFORM_TRANSACTION.FEE_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  const feeList = response.content.map(data => formattingToPlatformTransactionVOModel(data));

  return {
    ...response,
    content: feeList,
  };
}

// 플랫폼 실적 조회
export async function requestSystemPlatformTransactionExecutionList(
  pageNumber: number,
  rowCount: number,
  data: SystemPlatformTransactionListRequest,
): Promise<Pageable<PlatformTransactionVOModel[]>> {
  const response = await http.get<Pageable<PlatformTransactionVO[]>>({
    url: API_SY.PLATFORM_TRANSACTION.EXECUTION_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  const executionList = response.content.map(data => formattingToPlatformTransactionVOModel(data));

  return {
    ...response,
    content: executionList,
  };
}
