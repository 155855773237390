import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormSelect, FormSubtitle } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import SearchFinancierClientModal from 'components/stateless/Modal/financier/SearchFinancierClientModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, FINANCIER_CLIENT_TYPE } from 'enums';
import { AnchorUserExceptionCode } from 'enums/exception';
import useProperty from 'hooks/useProperty';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestWaitingAnchorUserRegistration } from 'utils/http/api/financier/waiting-anchor-users';
import type { FinancierWaitingAnchorUserRegisterRequest } from 'utils/http/api/financier/waiting-anchor-users/request';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function FinancierManageAnchorUserRegistration() {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();
  const errorAlert = useModal();
  const [anchorInfo, setAnchorInfo] = useState<{
    financierClientCode: string;
    financierClientName: string;
    anchorClientId: number;
  }>();
  const getProperty = useProperty<FinancierWaitingAnchorUserRegisterRequest>();
  const { register, getValues, errors, setError, clearErrors } = useForm<FinancierWaitingAnchorUserRegisterRequest>();

  const onSubmit = async () => {
    const data = getValues();

    if (anchorInfo) data.anchorClientId = anchorInfo?.anchorClientId;

    const confirmRequestModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          requestDTOParser(data);

          await requestWaitingAnchorUserRegistration(data);
          showRegistrationRequestConfirmModal();
        } catch (e: any) {
          errorAlert.show(e);

          formErrorHandler<FinancierWaitingAnchorUserRegisterRequest>(e, setError, clearErrors);

          if (e.code === AnchorUserExceptionCode.INVALID_TARGET_AUTHORITY) setError('authorityType', {});
        }
      },
    };
    modal.show(<h6>{t('text:Would_you_like_to_request_approval_for_registration?')}</h6>, confirmRequestModalOptions);
  };

  // register 성공 modal
  const showRegistrationRequestConfirmModal = async () => {
    const confirmRequestModalOptions: ModalOptions = {
      modalType: ModalType.ALERT,
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        history.push(ROUTES_FI.MANAGE_ANCHOR.USER_LIST);
      },
    };
    modal.show(
      <h6>
        {t('text:Request_for_registration_approval_has_been_completed')}
        <br />
        {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
      </h6>,
      confirmRequestModalOptions,
    );
  };

  // search anchor
  const onClickSearchAnchor = (e: any): void => {
    e.preventDefault();

    modal.show(
      <SearchFinancierClientModal
        modalId={modal.id}
        clientType={FINANCIER_CLIENT_TYPE.ANCHOR}
        getSelectedData={setFinancierClientData}
      />,
      {
        title: t('text:Search_Anchor'),
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const setFinancierClientData = (data: FinancierClientVOModel): void => {
    setAnchorInfo({
      financierClientCode: data.financierClientCode,
      financierClientName: data.financierClientName,
      anchorClientId: data.financierClientId,
    });
  };

  // cancel modal
  const showCancelModal = (e: any) => {
    e.preventDefault();

    const cancelModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: () => {
        history.goBack();
      },
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration_request?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      cancelModalOptions,
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_User_Registration')} />
      <GuideMessage
        message={[
          t('text:Enter_the_Anchor_information_to_register_the_user_on_the_platform'),
          <Trans
            key="key"
            i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
            components={{ 1: <span className="asterisk" /> }}
          />,
          t('text:Click_on_the_Confirm_Request_button_to_request_registration_approval_to_the_Authorizer'),
        ]}
      />
      <div className="content-area">
        <FormBorder editable={true}>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_INFORMATION')} />
          <FormContents>
            <div className="row">
              <FormInput
                requiredOptions={{ fixedRequired: true }}
                label={t('text:Client_Code')}
                value={anchorInfo?.financierClientCode ?? ''}
                disabled={true}
                error={errors.anchorClientId}
              >
                <Button onClick={onClickSearchAnchor} bold>
                  {t('text:Search_Anchor')}
                </Button>
              </FormInput>
              <FormInput
                requiredOptions={{ fixedRequired: true }}
                label={t('text:Anchor_Name')}
                value={anchorInfo?.financierClientName ?? ''}
                disabled={true}
                error={errors.anchorClientId}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_USER_INFORMATION')} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:User_Code')}
                name={getProperty('bankUserId')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.bankUserId}
              />
              <FormInput
                label={t('text:User_Name')}
                name={getProperty('name')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.name}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Mobile')}
                name={getProperty('mobile')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.mobile}
              />
              <FormInput
                label={t('text:Email')}
                name={getProperty('email')}
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.email}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:OTP_Number')}
                name={getProperty('anchorUserOtpSerialNo')}
                ref={register}
                error={errors.anchorUserOtpSerialNo}
              />
              <FormSelect
                label={t('text:Authority')}
                name={getProperty('authorityType')}
                ref={register}
                selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', [
                  AUTHORITY_TYPE.ADMIN,
                  AUTHORITY_TYPE.AUTHORIZER,
                  AUTHORITY_TYPE.OPERATOR,
                ])}
                required={true}
                placeholderOptions={{ show: true }}
                error={errors.authorityType}
              />
            </div>
          </FormContents>
        </FormBorder>
        <div className="flex-end mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={showCancelModal}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onSubmit} className="ms-2">
            {t('text:Confirm_Request')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default FinancierManageAnchorUserRegistration;
