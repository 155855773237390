import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { AnchorFinancingOptionDetailVOModel } from 'models/vo/AnchorFinancingOptionDetailVO';

interface CompanyInformationProps {
  data: AnchorFinancingOptionDetailVOModel;
}

function CompanyInformation({ data }: CompanyInformationProps) {
  const { t } = useTranslation(['format']);

  return (
    <>
      <SectionTitle title={t('text:Company_Information')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue col={3} label={t('text:Financier_Name')} value={data.financierName} />
            <FormValue col={3} label={t('text:Anchor_Name')} value={data?.anchorClientName} />
            <FormValue col={3} label={t('text:Currency')} value={data?.currencyType} />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default CompanyInformation;
