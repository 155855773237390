import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ApAccordionList from 'components/ap/ApSummaryPhaseAccordion';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import useMounted from 'hooks/useMounted';
import useProgressStatus from 'hooks/useProgressStatus';
import type Pageable from 'models/Pageable';
import type { ArPhaseVOModel } from 'models/vo/ArPhaseVO';
import type { ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import { requestSystemArPhaseList } from 'utils/http/api/system/ar-approval-phases';
import { requestSystemArSummaryDetail } from 'utils/http/api/system/ar-summaries';
import { requestSystemWaitingArList } from 'utils/http/api/system/waiting-ars';
import useModal from 'utils/modal/useModal';

const getConstant = () => {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const { arSummaryId } = useParams() as any;

  return {
    modal,
    t,
    mounted,
    arSummaryId,
  };
};

function SystemArConfirmationDetail() {
  const { modal, t, mounted, arSummaryId } = getConstant();

  const [arSummaryData, setArSummaryData] = useState<ArSummaryVOModel>();
  const [arSummaryPhaseDataList, setArSummaryPhaseDataList] = useState<Pageable<ArPhaseVOModel[]>>();

  // ar lists state - phase 별로
  const [searchedWaitingApprovalPhaseId, setSearchedWaitingApprovalPhaseId] = useState<number[]>([]);

  const { renderProgressStatus } = useProgressStatus(arSummaryData, '', 'Ar');

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [arSummaryDetailResponse, arSummaryPhaseResponse] = await Promise.all([
        requestSystemArSummaryDetail(arSummaryId),
        requestSystemArPhaseList(0, 10, arSummaryId),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setArSummaryData(arSummaryDetailResponse);
        setArSummaryPhaseDataList(arSummaryPhaseResponse);
      });
    } catch (error: any) {
      modal.show(error);
    }
  };

  const handleArSummaryPhaseAccordionClick = (arPhaseData: ArPhaseVOModel) => {
    return requestSystemWaitingArList(arPhaseData.waitingArApprovalPhaseId, 0, 3000);
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AR_Confirmation_Details')} />
      {arSummaryData?.rejected && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[t('text:The_AR_registration_request_was_rejected'), t('text:Please_check_the_reason_below')]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={arSummaryData.rejectReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Registration_Status')} col={4} value={renderProgressStatus()} />
              <FormValue
                label={t('text:Number_of_Valid_AR')}
                col={4}
                className="information-form__input border-none pointfont"
                value={arSummaryData?.currentApprovalCount}
                format="number"
              />
              <FormValue
                label={t('text:Total_Amount_of_Valid_AR')}
                col={4}
                className="information-form__input border-none pointfont"
                value={arSummaryData?.currentApprovalAmount}
                format="number"
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Anchor_Master_Agreement_Information')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} col={3} value={arSummaryData?.financierEnterpriseName} />
              <FormValue label={t('text:Anchor_Name')} col={3} value={arSummaryData?.anchorClientName} />
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                col={3}
                value={arSummaryData?.anchorContractNo}
              />
              <FormValue label={t('text:Currency')} col={3} value={arSummaryData?.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:AR_List')} />
        {arSummaryData &&
          arSummaryPhaseDataList?.content.map(arSummaryPhase => {
            return (
              <ApAccordionList
                onAccordionClick={() => handleArSummaryPhaseAccordionClick(arSummaryPhase)}
                searchedWaitingApprovalPhaseId={searchedWaitingApprovalPhaseId}
                setSearchedWaitingApprovalPhaseId={setSearchedWaitingApprovalPhaseId}
                arSummaryData={arSummaryData}
                arSummaryPhase={arSummaryPhase}
                key={arSummaryPhase.waitingArApprovalPhaseId}
              />
            );
          })}
      </div>
    </>
  );
}

export default SystemArConfirmationDetail;
