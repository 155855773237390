import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { formErrorHandler } from 'utils/error/manager';
import { requestAnchorUserRegister } from 'utils/http/api/anchor/users';
import type { AnchorAdminOperatorRegisterRequest } from 'utils/http/api/anchor/users/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function AnchorUserRegister() {
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, errors, getValues, setError, clearErrors } = useForm<AnchorAdminOperatorRegisterRequest>();

  const onClickRegister = async () => {
    const data = getValues();
    const showRegisterCompletedModal = () => {
      modal.show(
        <h6>
          {t('text:User_registration_is_complete')}
          <br />
          {t('text:Click_the_Send_button_to_send_an_invitation_mail')}
        </h6>,
        {
          closeBtnCb: () => history.push(ROUTES_AC.COMPANY.USER_LIST),
        },
      );
    };

    try {
      requestDTOParser(data);
      await requestAnchorUserRegister(data);
      showRegisterCompletedModal();
    } catch (e) {
      modal.show(e);
      formErrorHandler<AnchorAdminOperatorRegisterRequest>(e, setError, clearErrors);
    }
  };

  const onClickCancel = (e: any) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => history.push(ROUTES_AC.COMPANY.USER_LIST),
      },
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Admin_Operator_Registration')} />
      <div className="content-area">
        <SectionTitle title={t('text:User_Information')} />
        <form>
          <FormBorder editable={true}>
            <FormContents>
              <div className="row">
                <FormInput
                  label={t('text:Name')}
                  name="userName"
                  ref={register}
                  requiredOptions={{ required: true }}
                  error={errors.userName}
                />
                <FormInput
                  label={t('text:Employee_Code')}
                  name="employeeCode"
                  ref={register}
                  error={errors.employeeCode}
                />
              </div>
              <div className="row">
                <FormInput label={t('text:Department')} name="department" ref={register} error={errors.department} />
                <FormInput label={t('text:Title')} name="position" ref={register} error={errors.position} />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Mobile')}
                  name="mobile"
                  ref={register}
                  requiredOptions={{ required: true }}
                  error={errors.mobile}
                />
                <FormInput label={t('text:Telephone')} name="telephone" ref={register} error={errors.telephone} />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Email')}
                  name="email"
                  ref={register}
                  requiredOptions={{ required: true }}
                  error={errors.email}
                />
                <FormValue
                  className="information-form__input"
                  label={t('text:Authority')}
                  value={t('text:Admin_Operator')}
                />
              </div>
            </FormContents>
          </FormBorder>
          <div className="flex-end mt-3">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={onClickCancel}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              className="me-2"
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onClickRegister}>
              {t('text:Register')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AnchorUserRegister;
