import NumberInput from './NumberInput';
import { useFormContext } from '..';

import type { NumberInputProps } from './NumberInput';

const UncontrolledNumberInput = (props: NumberInputProps) => {
  const { name = '', defaultValue, rules } = props;
  const {
    methods: { control, errors, watch },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();

  const currentValue = watch(name);
  const fetchedReadOnly = getReadOnlyValue(name);

  return (
    <NumberInput
      {...props}
      isEditable={isEditable}
      errors={errors}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      currentValue={currentValue}
      fetchedReadOnly={fetchedReadOnly}
    />
  );
};
export default UncontrolledNumberInput;
