import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import ChangePasswordModal from 'components/stateless/Modal/common/ChangePasswordModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestAnchorUserAnchorAgreementList,
  requestAnchorUserDealerAgreementList,
  requestRelatedAnchorUsers,
} from 'utils/http/api/anchor/users';
import {
  requestCurrentUser,
  requestCurrentUserChangeInfo,
  requestCurrentUserResetPassword,
} from 'utils/http/api/common/users';
import type { CurrentUserChangeInfoRequest, CurrentUserChangePassword } from 'utils/http/api/common/users/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { getPaymentSupportText } from 'utils/text';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const AUTHORITY_BY_FINANCIER_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Authority'),
    },
    {
      headerText: t('text:Email_Notification_Setting'),
    },
  ];

  const ASSOCIATED_ANCHOR_MASTER_AGREEMENT_LIST_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 200,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 120,
    },
    {
      headerText: t('text:Program_Type'),
      colWidths: 120,
    },
    {
      headerText: t('text:Automatic_Settlement'),
      colWidths: 180,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 150,
    },
    {
      headerText: '',
      colWidths: 70,
    },
  ];

  const ASSOCIATED_PARTNER_MASTER_AGREEMENT_LIST_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 200,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 120,
    },
    {
      headerText: t('text:Program_Type'),
      colWidths: 120,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 150,
    },
  ];

  return {
    mounted,
    modal,
    t,
    AUTHORITY_BY_FINANCIER_HEADERS,
    ASSOCIATED_ANCHOR_MASTER_AGREEMENT_LIST_HEADERS,
    ASSOCIATED_PARTNER_MASTER_AGREEMENT_LIST_HEADERS,
  };
};

function AnchorMyAccount() {
  const {
    t,
    modal,
    mounted,
    AUTHORITY_BY_FINANCIER_HEADERS,
    ASSOCIATED_ANCHOR_MASTER_AGREEMENT_LIST_HEADERS,
    ASSOCIATED_PARTNER_MASTER_AGREEMENT_LIST_HEADERS,
  } = getConstants();

  const signInInfo: SignInModel | null = getSignIn();
  const userId = signInInfo?.userId ?? NaN;
  const isAdmin =
    signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN || signInInfo?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR;

  const [currentUserInfo, setCurrentUserInfo] = useState<UserVOModel>();
  const [authorityByFinancierList, setAuthorityByFinancierList] = useState<AnchorUserVOModel[]>();
  const [associatedAnchorMasterAgreementList, setAssociatedAnchorMasterAgreementList] =
    useState<Pageable<AnchorAgreementVOModel[]>>();
  const [associatedPartnerMasterAgreementList, setAssociatedPartnerMasterAgreementList] =
    useState<Pageable<DealerAgreementVOModel[]>>();

  const [isEditable, setIsEditable] = useState<boolean>(false); // edit 활성화 여부 state
  const getProperty = useProperty<CurrentUserChangeInfoRequest>();

  const {
    pageable: associatedAnchorMasterAgreementListPageable,
    setPageable: setAssociatedAnchorMasterAgreementListPageable,
  } = usePageable();

  const {
    pageable: associatedPartnerMasterAgreementListPageable,
    setPageable: setAssociatedPartnerMasterAgreementListPageable,
  } = usePageable();

  // edit button handler
  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const { register, getValues, setValue, reset, errors, setError, clearErrors } = useForm<CurrentUserChangeInfoRequest>(
    {
      mode: 'onSubmit',
      shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
    },
  );

  async function fetchAll() {
    try {
      const fetchAssociatedMasterAgreementListData = isAdmin
        ? fetchAssociatedAnchorMasterAgreementList
        : fetchAssociatedPartnerMasterAgreementList;

      const [currentUserInfo, authorityByFinancierListData, associatedAnchorMasterAgreementListData] =
        await Promise.all([
          requestCurrentUser(),
          requestRelatedAnchorUsers(),
          fetchAssociatedMasterAgreementListData(),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setIsEditable(false);
        setCurrentUserInfo(currentUserInfo);
        setValue('userName', currentUserInfo?.userName);
        setValue('department', currentUserInfo?.department);
        setValue('position', currentUserInfo?.position);
        setValue('email', currentUserInfo?.email);
        setValue('telephone', currentUserInfo?.telephone);
        setValue('mobile', currentUserInfo?.mobile);
        setAuthorityByFinancierList(authorityByFinancierListData);
        if (isAdmin) {
          setAssociatedAnchorMasterAgreementList(
            associatedAnchorMasterAgreementListData as Pageable<AnchorAgreementVOModel[]>,
          );
          setAssociatedAnchorMasterAgreementListPageable(associatedAnchorMasterAgreementListData);
        } else {
          setAssociatedPartnerMasterAgreementList(
            associatedAnchorMasterAgreementListData as Pageable<DealerAgreementVOModel[]>,
          );
          setAssociatedPartnerMasterAgreementListPageable(associatedAnchorMasterAgreementListData);
        }
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchAssociatedAnchorMasterAgreementList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
  ): Promise<Pageable<AnchorAgreementVOModel[]>> => {
    const associatedAnchorMasterAgreementListData = await requestAnchorUserAnchorAgreementList(
      userId,
      pageNumber,
      rowCount,
    );

    return associatedAnchorMasterAgreementListData;
  };

  const fetchAndSetAssociatedAnchorMasterAgreementList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
  ): Promise<void> => {
    try {
      const associatedAnchorMasterAgreementListData = await fetchAssociatedAnchorMasterAgreementList(
        pageNumber,
        rowCount,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAssociatedAnchorMasterAgreementList(associatedAnchorMasterAgreementListData);
        setAssociatedAnchorMasterAgreementListPageable(associatedAnchorMasterAgreementListData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchAssociatedPartnerMasterAgreementList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
  ): Promise<Pageable<DealerAgreementVOModel[]>> => {
    const associatedPartnerMasterAgreementListData = await requestAnchorUserDealerAgreementList(
      userId,
      pageNumber,
      rowCount,
    );

    return associatedPartnerMasterAgreementListData;
  };

  const fetchAndSetAssociatedPartnerMasterAgreementList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
  ): Promise<void> => {
    try {
      const associatedPartnerMasterAgreementListData = await fetchAssociatedAnchorMasterAgreementList(
        pageNumber,
        rowCount,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAssociatedAnchorMasterAgreementList(associatedPartnerMasterAgreementListData);
        setAssociatedAnchorMasterAgreementListPageable(associatedPartnerMasterAgreementListData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={[USER_STATUS.ACTIVATED, USER_STATUS.SUSPENDED]}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onSubmitUserChangeForm = async (e: any) => {
    e.preventDefault();

    const data = getValues();
    try {
      requestDTOParser(data);

      await requestCurrentUserChangeInfo(data);

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        closeBtnCb: () => fetchAll(),
      });
      clearErrors();
    } catch (e) {
      modal.show(e);

      formErrorHandler<CurrentUserChangeInfoRequest>(e, setError, clearErrors);
    }
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setIsEditable(false);
    reset({
      userName: currentUserInfo?.userName,
      department: currentUserInfo?.department,
      position: currentUserInfo?.position,
      email: currentUserInfo?.email,
      telephone: currentUserInfo?.telephone,
      mobile: currentUserInfo?.mobile,
    });
  };

  const onSubmitChangePasswordForm = async (data: CurrentUserChangePassword) => {
    try {
      requestDTOParser(data);

      await requestCurrentUserResetPassword(data);

      modal.show(<h6>{t('text:Password_successfully_changed')}</h6>, {
        closeBtnCb: () => fetchAll(),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const showChangePasswordModal = () => {
    modal.show(<ChangePasswordModal state={{} as CurrentUserChangePassword} modalId={modal.id} />, {
      modalType: ModalType.CONFIRM,
      title: t('text:Change_password'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: (data: { state: CurrentUserChangePassword }) => {
        onSubmitChangePasswordForm(data.state);
      },
    });
  };

  const renderAuthorityByFinancierTableBody = (): JSX.Element[] | undefined => {
    return authorityByFinancierList?.map((data, index) => (
      <Tr key={index}>
        <Td data={data.financierName} />
        <Td data={t(`code:authority-type.${data.authorityType}`)} format="code" />
        <TdLink path={ROUTES_AC.MY_ACCOUNT.EMAIL_NOTIFICATION_SETTING_BUILD_PATH(data.financierId)} />
      </Tr>
    ));
  };

  const renderAssociatedAnchorMasterAgreementList = (): JSX.Element[] | undefined => {
    return associatedAnchorMasterAgreementList?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.financierName} />
        <Td data={data.contractNo} />
        <Td data={data.currencyType} className="text-center" />
        <Td data={t(`code:collateral-type.${data.collateralType}`)} format="code" />
        <Td data={getPaymentSupportText(data.paymentSupport)} />
        <Td data={data.startDate} format="date" />
        <Td data={data.expiryDate} format="date" />
        <TdLink path={ROUTES_AC.COMPANY.AGREEMENT_DETAIL_BUILD_PATH(data.anchorAgreementId)} />
      </Tr>
    ));
  };

  const renderAssociatedPartnerMasterAgreementTable = (): JSX.Element[] | undefined => {
    return associatedPartnerMasterAgreementList?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.financierName} />
        <Td data={data.dealerFinancierClientName} />
        <Td data={data.contractNo} />
        <Td data={data.currencyType} className="text-center" />
        <Td data={t(`code:collateral-type.${data.collateralType}`)} format="code" />
        <Td data={data.startDate} />
        <Td data={data.expiryDate} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:My_Account')} />
      <GuideMessage
        message={[
          t('text:The_user’s_account_information_is_shown_below'),
          t('text:Click_on_the_“Edit“_button_to_modify_the_user_information'),
        ]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:User_Information')}>
          {isEditable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={handleCancel}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={onSubmitUserChangeForm} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <Button size={ButtonSizeEnum.SM} onClick={handleEdit} style={{ width: '60px' }}>
              {t('text:Edit')}
            </Button>
          )}
        </SectionTitle>
        <FormBorder editable={isEditable}>
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:User_Name')}
                name={getProperty('userName')}
                ref={register}
                disabled={!isEditable}
                requiredOptions={{ required: true }}
                error={errors.userName}
              />
              <FormValue
                className="information-form__input"
                label={t('text:User_Code')}
                value={currentUserInfo?.employeeCode}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Department')}
                name={getProperty('department')}
                ref={register}
                disabled={!isEditable}
                error={errors.department}
              />
              <FormInput
                label={t('text:Title')}
                name={getProperty('position')}
                ref={register}
                disabled={!isEditable}
                error={errors.position}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Email')}
                name={getProperty('email')}
                ref={register}
                disabled={!isEditable}
                error={errors.email}
                requiredOptions={{ required: true }}
              />
              <FormInput
                label={t('text:Telephone')}
                name={getProperty('telephone')}
                ref={register}
                disabled={!isEditable}
                error={errors.telephone}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Mobile')}
                name={getProperty('mobile')}
                ref={register}
                disabled={!isEditable}
                error={errors.mobile}
              />
              <FormValue
                className={`information-form__input ${getStatusTextClass('USER_STATUS', currentUserInfo?.userStatus)}`}
                label={t('text:User_Account_Status')}
                value={t(`code:user-status.${currentUserInfo?.userStatus}`)}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickUserStatus}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Account_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue className="information-form__input" label={t('text:ID')} value={currentUserInfo?.loginId} />
              <FormValue className="information-form__input" label={t('text:Password')} value="●●●●●●●●">
                <Button onClick={showChangePasswordModal} bold>
                  {t('text:Change_password')}
                </Button>
              </FormValue>
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Associated_Financier')} />
        <TableBorder>
          <TableHeader header={AUTHORITY_BY_FINANCIER_HEADERS} />
          <TableBody numOfCol={AUTHORITY_BY_FINANCIER_HEADERS.length}>
            {renderAuthorityByFinancierTableBody()}
          </TableBody>
        </TableBorder>
      </div>
      <div className="content-area">
        {isAdmin ? (
          <>
            <SectionTitle title={t('text:Associated_Anchor_Master_Agreement_List')} />
            <TableBorder>
              <TableHeader header={ASSOCIATED_ANCHOR_MASTER_AGREEMENT_LIST_HEADERS} />
              <TableBody numOfCol={ASSOCIATED_ANCHOR_MASTER_AGREEMENT_LIST_HEADERS.length}>
                {renderAssociatedAnchorMasterAgreementList()}
              </TableBody>
            </TableBorder>
            <Pagination
              pageable={associatedAnchorMasterAgreementListPageable}
              paginate={fetchAndSetAssociatedAnchorMasterAgreementList}
            />
          </>
        ) : (
          <>
            <SectionTitle title={t('text:Associated_Partner_Master_Agreement_List')} />
            <TableBorder>
              <TableHeader header={ASSOCIATED_PARTNER_MASTER_AGREEMENT_LIST_HEADERS} />
              <TableBody numOfCol={ASSOCIATED_PARTNER_MASTER_AGREEMENT_LIST_HEADERS.length}>
                {renderAssociatedPartnerMasterAgreementTable()}
              </TableBody>
            </TableBorder>
            <Pagination
              pageable={associatedPartnerMasterAgreementListPageable}
              paginate={fetchAndSetAssociatedPartnerMasterAgreementList}
            />
          </>
        )}
      </div>
    </>
  );
}

export default AnchorMyAccount;
