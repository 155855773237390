import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import { COMMON_APPROVAL_TYPE, DEALER_AGREEMENT_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { WaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemPartnerAgreementList } from 'utils/http/api/system/dealer-agreements';
import type { SystemPartnerAgreementListRequest } from 'utils/http/api/system/dealer-agreements/requests';
import { requestSystemWaitingPartnerAgreementList } from 'utils/http/api/system/waiting-dealer-agreements';
import type { SystemWaitingPartnerAgreementListRequest } from 'utils/http/api/system/waiting-dealer-agreements/requests';
import { getWaitingForApprovalCount } from 'utils/logic';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES, setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

const getConstant = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const onClickConfirmationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const PARTNER_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Tax_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Agreement_Suspension'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const WAITING_PARTNER_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Tax_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickConfirmationStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_PARTNER_AGREEMENT_LIST_QS_KEY = 'sy-partner-agreement-list';
  const SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY = 'sy-waiting-partner-agreement-list';

  return {
    mounted,
    modal,
    t,
    onClickConfirmationStatus,
    PARTNER_AGREEMENT_TABLE_HEADERS,
    WAITING_PARTNER_AGREEMENT_TABLE_HEADERS,
    SY_PARTNER_AGREEMENT_LIST_QS_KEY,
    SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY,
  };
};

function SystemMonitorPartnerAgreementList(): JSX.Element {
  const {
    mounted,
    modal,
    t,
    onClickConfirmationStatus,
    PARTNER_AGREEMENT_TABLE_HEADERS,
    WAITING_PARTNER_AGREEMENT_TABLE_HEADERS,
    SY_PARTNER_AGREEMENT_LIST_QS_KEY,
    SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY,
  } = getConstant();

  const { pageable: partnerAgreementPageable, setPageable: setPartnerAgreementPageable } = usePageable(
    SY_PARTNER_AGREEMENT_LIST_QS_KEY,
  );
  const { pageable: waitingPartnerAgreementPageable, setPageable: setWaitingPartnerAgreementPageable } = usePageable(
    SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY,
  );

  const [partnerAgreementPage, setPartnerAgreementPage] = useState<Pageable<DealerAgreementVOModel[]>>();
  const [waitingPartnerAgreementPage, setWaitingPartnerAgreementPage] =
    useState<Pageable<WaitingDealerAgreementVOModel[]>>();
  const [notCancelledWaitingPartnerAgreementTotalCount, setNotCancelledWaitingPartnerAgreementTotalCount] =
    useState<number>(0);

  const {
    register: partnerAgreementRegister,
    reset: partnerAgreementReset,
    getValues: partnerAgreementGetValues,
    setValue: partnerAgreementSetValue,
    control: partnerAgreementControl,
  } = useForm<SystemPartnerAgreementListRequest>({
    mode: 'onSubmit',
  });

  const {
    register: waitingPartnerAgreementRegister,
    reset: waitingPartnerAgreementReset,
    getValues: waitingPartnerAgreementGetValues,
    setValue: waitingPartnerAgreementSetValue,
    control: waitingPartnerAgreementControl,
  } = useForm<SystemWaitingPartnerAgreementListRequest>({
    mode: 'onSubmit',
  });

  const getPartnerAgreementProperty = useProperty<SystemPartnerAgreementListRequest>();
  const getWaitingPartnerAgreementProperty = useProperty<SystemWaitingPartnerAgreementListRequest>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const setWaitingTabSearchValue = (formSearchData?: SystemWaitingPartnerAgreementListRequest) => {
    const getSearchFormData = formSearchData ? formSearchData : waitingPartnerAgreementGetValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchAll = async (): Promise<void> => {
    // completed
    setFormValues<SystemPartnerAgreementListRequest>(
      partnerAgreementSetValue,
      getParsedSearchParams(SY_PARTNER_AGREEMENT_LIST_QS_KEY).formSearchData,
    );
    // waiting
    setFormValues<SystemWaitingPartnerAgreementListRequest>(
      waitingPartnerAgreementSetValue,
      getParsedSearchParams(SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY).formSearchData,
    );

    const completedListSearchData = partnerAgreementGetValues();

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    const waitingQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingPartnerAgreementPageable.currentPage,
        rowCount: waitingPartnerAgreementPageable.sizePerPage,
      },
      SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY,
    );
    const completedQs = makeSearchParamsPattern(
      {
        ...completedListSearchData,
        pageNumber: partnerAgreementPageable.currentPage,
        rowCount: partnerAgreementPageable.sizePerPage,
      },
      SY_PARTNER_AGREEMENT_LIST_QS_KEY,
    );
    addSearchParams(waitingQs + completedQs);

    try {
      const [partnerAgreementList, waitingPartnerAgreementList, notCancelledWaitingPartnerAgreementList] =
        await Promise.all([
          requestSystemPartnerAgreementList(
            partnerAgreementPageable.currentPage,
            partnerAgreementPageable.sizePerPage,
            completedListSearchData,
          ),
          requestSystemWaitingPartnerAgreementList(
            waitingPartnerAgreementPageable.currentPage,
            waitingPartnerAgreementPageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestSystemWaitingPartnerAgreementList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerAgreementPage(partnerAgreementList);
        setPartnerAgreementPageable(partnerAgreementList);
        setWaitingPartnerAgreementPage(waitingPartnerAgreementList);
        setWaitingPartnerAgreementPageable(waitingPartnerAgreementList);
        setNotCancelledWaitingPartnerAgreementTotalCount(notCancelledWaitingPartnerAgreementList.totalElements);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchAndSetPartnerAgreementList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
    data: SystemPartnerAgreementListRequest,
  ): Promise<void> => {
    try {
      const partnerAgreementList = await requestSystemPartnerAgreementList(selectedPageNumber, selectedRowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowCount,
        },
        SY_PARTNER_AGREEMENT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerAgreementPage(partnerAgreementList);
        setPartnerAgreementPageable(partnerAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderWaitingForApprovalTabView = (): JSX.Element => {
    const fetchAndSetWaitingPartnerAgreementList = async (
      selectedPageNumber: number = 1,
      selectedRowCount: number = 10,
    ): Promise<void> => {
      try {
        const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: selectedPageNumber,
            rowCount: selectedRowCount,
          },
          SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY,
        );

        const [waitingPartnerAgreementList, notCancelledWaitingPartnerAgreementList] = await Promise.all([
          requestSystemWaitingPartnerAgreementList(selectedPageNumber, selectedRowCount, waitingForApprovalSearchData),
          requestSystemWaitingPartnerAgreementList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingPartnerAgreementPage(waitingPartnerAgreementList);
          setWaitingPartnerAgreementPageable(waitingPartnerAgreementList);
          setNotCancelledWaitingPartnerAgreementTotalCount(notCancelledWaitingPartnerAgreementList.totalElements);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const paginateWaitingPartnerAgreementList = async (
      selectedPageNumber: number = 1,
      selectedRowCount: number = 10,
    ): Promise<void> => {
      try {
        const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
          getParsedSearchParams(SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY).formSearchData,
        );
        const response = await requestSystemWaitingPartnerAgreementList(
          selectedPageNumber,
          selectedRowCount,
          waitingForApprovalSearchData,
        );
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: selectedPageNumber,
            rowCount: selectedRowCount,
          },
          SY_WAITING_PARTNER_AGREEMENT_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingPartnerAgreementPage(response);
          setWaitingPartnerAgreementPageable(response);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchWaitingTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      fetchAndSetWaitingPartnerAgreementList(0, waitingPartnerAgreementPageable.sizePerPage);
    };

    const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      waitingPartnerAgreementReset();
    };

    const renderApprovalType = (approvalType: COMMON_APPROVAL_TYPE): JSX.Element => {
      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('code:common-approval-type.OPERATOR_REQUEST');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('code:common-approval-type.OPERATOR_CANCEL');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('code:common-approval-type.AUTHORIZER_APPROVED');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted');
        default:
          return approvalType;
      }
    };
    const renderWaitingPartnerAgreementListTable = (): JSX.Element[] | JSX.Element | undefined =>
      waitingPartnerAgreementPage?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item?.financierName} />
          <Td data={item?.dealerClientName} />
          <Td data={item?.dealerClientCode} />
          <Td data={item.dealerClientTaxCode} />
          <Td data={item.anchorClientName} />
          <Td data={item.mainContractNo} />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.startDate} format="date" />
          <Td data={item.expiryDate} format="date" />
          <Td className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}>
            {renderApprovalType(item.approvalType)}
          </Td>
          <TdLink path={ROUTES_SY.MONITOR_PARTNER.AGREEMENT_WAITING_DETAIL_BUILD_PATH(item.waitingDealerAgreementId)} />
        </Tr>
      ));

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Financier_Name')} />
                <SearchInput
                  name={getWaitingPartnerAgreementProperty('financierName')}
                  ref={waitingPartnerAgreementRegister}
                />
                <SearchLabel label={t('text:Partner_Name')} />
                <SearchInput
                  name={getWaitingPartnerAgreementProperty('dealerClientName')}
                  ref={waitingPartnerAgreementRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Partner_Client_Code')} />
                <SearchInput
                  name={getWaitingPartnerAgreementProperty('dealerClientCode')}
                  ref={waitingPartnerAgreementRegister}
                />
                <SearchLabel label={t('text:Partner_Tax_Code')} />
                <SearchInput
                  name={getWaitingPartnerAgreementProperty('dealerClientTaxCode')}
                  ref={waitingPartnerAgreementRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Associated_Anchor_Name')} />
                <SearchInput
                  name={getWaitingPartnerAgreementProperty('anchorClientName')}
                  ref={waitingPartnerAgreementRegister}
                />
                <SearchLabel label={t('text:Associated_Anchor_Master_Agreement_Number')} />
                <SearchInput
                  name={getWaitingPartnerAgreementProperty('anchorAgreementContractNo')}
                  ref={waitingPartnerAgreementRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
                <SearchInput
                  name={getWaitingPartnerAgreementProperty('contractNo')}
                  ref={waitingPartnerAgreementRegister}
                />
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                  name={getWaitingPartnerAgreementProperty('currencyType')}
                  ref={waitingPartnerAgreementRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Effective_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name={getWaitingPartnerAgreementProperty('fromStartDate')}
                  control={waitingPartnerAgreementControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name={getWaitingPartnerAgreementProperty('toStartDate')}
                  control={waitingPartnerAgreementControl}
                />
                <SearchLabel label={t('text:Expiration_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name={getWaitingPartnerAgreementProperty('fromExpiryDate')}
                  control={waitingPartnerAgreementControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name={getWaitingPartnerAgreementProperty('toExpiryDate')}
                  control={waitingPartnerAgreementControl}
                />
              </div>
              <div className="row">
                <SearchLabel
                  label={t('text:Approval_Status')}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickConfirmationStatus}
                />
                <SearchSelect
                  name="approvalTypes"
                  ref={waitingPartnerAgreementRegister}
                  selectOptions={[
                    {
                      label: t('text:All'),
                      value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                    },
                    {
                      label: t('text:Requested'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                    },
                    {
                      label: t('text:Reverted'),
                      value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    },
                    {
                      label: t('text:Cancelled'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                    },
                  ]}
                />
                <SearchLabel label={t('text:Program_Type')} />
                <SearchSelect
                  selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                  name={getWaitingPartnerAgreementProperty('collateralType')}
                  ref={waitingPartnerAgreementRegister}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickSearchWaitingTab}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} : {waitingPartnerAgreementPage?.totalElements}
          </p>
          <TableBorder>
            <TableHeader header={WAITING_PARTNER_AGREEMENT_TABLE_HEADERS} />
            <TableBody numOfCol={WAITING_PARTNER_AGREEMENT_TABLE_HEADERS.length}>
              {renderWaitingPartnerAgreementListTable()}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingPartnerAgreementPageable} paginate={paginateWaitingPartnerAgreementList} />
        </div>
      </>
    );
  };

  const renderRegistrationCompletedTabView = (): JSX.Element => {
    const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();
      fetchAndSetPartnerAgreementList(1, partnerAgreementPageable.sizePerPage, partnerAgreementGetValues());
    };

    const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      partnerAgreementReset();
    };

    const renderPartnerAgreementListTable = (): JSX.Element[] | JSX.Element | undefined => {
      const renderAgreementStatus = (agreementStatus: DEALER_AGREEMENT_STATUS): JSX.Element | undefined => {
        switch (agreementStatus) {
          case DEALER_AGREEMENT_STATUS.ACTIVATED:
            return <td className="text-bold-dark-green">{t('text:Not_Suspended')}</td>;
          case DEALER_AGREEMENT_STATUS.SUSPENDED:
            return <td className="text-bold-brick-red">{t('text:Suspended')}</td>;
          default:
            return <td>{agreementStatus}</td>;
        }
      };

      return partnerAgreementPage?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.financierName} />
          <Td data={item.dealerFinancierClientName} />
          <Td data={item.dealerFinancierClientCode} />
          <Td data={item.dealerFinancierClientTaxCode} />
          <Td data={item.anchorFinancierClientName} />
          <Td data={item.anchorAgreementContractNo} />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.startDate} format="date" />
          <Td data={item.expiryDate} format="date" />
          {renderAgreementStatus(item.dealerAgreementStatus)}
          <TdLink path={ROUTES_SY.MONITOR_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.dealerAgreementId)} />
        </Tr>
      ));
    };

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Financier_Name')} />
                <SearchInput name={getPartnerAgreementProperty('financierName')} ref={partnerAgreementRegister} />
                <SearchLabel label={t('text:Partner_Name')} />
                <SearchInput name={getPartnerAgreementProperty('dealerClientName')} ref={partnerAgreementRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Partner_Client_Code')} />
                <SearchInput name={getPartnerAgreementProperty('dealerClientCode')} ref={partnerAgreementRegister} />
                <SearchLabel label={t('text:Partner_Tax_Code')} />
                <SearchInput name={getPartnerAgreementProperty('dealerClientTaxCode')} ref={partnerAgreementRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Associated_Anchor_Name')} />
                <SearchInput name={getPartnerAgreementProperty('anchorClientName')} ref={partnerAgreementRegister} />
                <SearchLabel label={t('text:Associated_Anchor_Master_Agreement_Number')} />
                <SearchInput
                  name={getPartnerAgreementProperty('anchorAgreementContractNo')}
                  ref={partnerAgreementRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
                <SearchInput name={getPartnerAgreementProperty('contractNo')} ref={partnerAgreementRegister} />
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                  name={getPartnerAgreementProperty('currencyType')}
                  ref={partnerAgreementRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Effective_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name={getPartnerAgreementProperty('fromStartDate')}
                  control={partnerAgreementControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name={getPartnerAgreementProperty('toStartDate')}
                  control={partnerAgreementControl}
                />
                <SearchLabel label={t('text:Expiration_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name={getPartnerAgreementProperty('fromExpiryDate')}
                  control={partnerAgreementControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name={getPartnerAgreementProperty('toExpiryDate')}
                  control={partnerAgreementControl}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Agreement_Suspension')} />
                <SearchSelect
                  name={getPartnerAgreementProperty('dealerAgreementStatus')}
                  selectOptions={getSelectOptions<DEALER_AGREEMENT_STATUS>('DEALER_AGREEMENT_STATUS', 'ALL', true)}
                  ref={partnerAgreementRegister}
                />
                <SearchLabel label={t('text:Program_Type')} />
                <SearchSelect
                  name={getPartnerAgreementProperty('collateralType')}
                  selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                  ref={partnerAgreementRegister}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} : {partnerAgreementPage?.totalElements}
          </p>
          <TableBorder>
            <TableHeader header={PARTNER_AGREEMENT_TABLE_HEADERS} />
            <TableBody numOfCol={PARTNER_AGREEMENT_TABLE_HEADERS.length}>{renderPartnerAgreementListTable()}</TableBody>
          </TableBorder>
          <Pagination
            pageable={partnerAgreementPageable}
            paginate={(page, sizePerPage) =>
              fetchAndSetPartnerAgreementList(
                page,
                sizePerPage,
                getParsedSearchParams(SY_PARTNER_AGREEMENT_LIST_QS_KEY).formSearchData,
              )
            }
          />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_Master_Agreement')} />
      <Tab
        initialActiveTabIndex={1}
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              waitingPartnerAgreementGetValues('approvalTypes'),
              notCancelledWaitingPartnerAgreementTotalCount,
            ),
          },
          {
            tabItemCount: partnerAgreementPage?.totalElements ?? 0,
          },
        ]}
        tabViewList={[renderWaitingForApprovalTabView(), renderRegistrationCompletedTabView()]}
      />
    </>
  );
}

export default SystemMonitorPartnerAgreementList;
