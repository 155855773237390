import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import TextArea from 'components/stateless/TextArea/TextArea';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE } from 'enums';
import { ColorMap } from 'enums/colorMap';
import type { PotentialPartnerAcquisitionSettingVOModel } from 'models/vo/PotentialPartnerAcquisitionSettingVO';
import { getSignIn } from 'utils/storage/LocalStorage';

interface FinancierSCPartnerAcquisitionSettingContactFormProps {
  data: PotentialPartnerAcquisitionSettingVOModel;
  updateContactInfo: () => Promise<boolean>;
}

function FinancierSCPartnerAcquisitionSettingContactForm({
  data,
  updateContactInfo,
}: FinancierSCPartnerAcquisitionSettingContactFormProps) {
  const signInInfo = getSignIn();
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;

  const { t } = useTranslation();
  const { register, reset, watch, errors } = useFormContext();
  const [editable, setEditable] = useState<boolean>(false);
  const { message } = watch();

  const onClickSave = async () => {
    const isSuccess = await updateContactInfo();
    if (isSuccess) setEditable(false);
  };

  const onClickCancel = () => {
    setEditable(false);
    reset({
      message: data.message,
      telephone: data.telephone,
      fax: data.fax,
      webSite: data.webSite,
      email: data.email,
      address: data.address,
    });
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Provide_the_contact_details_for_the_Uploaded_Suppliers')}>
        {isAdmin &&
          (editable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={() => onClickCancel()}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <Button size={ButtonSizeEnum.SM} onClick={() => setEditable(true)} style={{ width: '60px' }}>
              {t('text:Edit')}
            </Button>
          ))}
      </SectionTitle>
      <FormBorder editable={editable}>
        <FormContents>
          <div className="row">
            <FormInput
              label={t('text:Telephone')}
              disabled={!editable}
              ref={register}
              name="telephone"
              error={errors.telephone}
            />
            <FormInput
              label={t('text:Link')}
              disabled={!editable}
              ref={register}
              name="webSite"
              error={errors.webSite}
            />
          </div>
          <div className="row">
            <FormInput label={t('text:Fax')} disabled={!editable} ref={register} name="fax" error={errors.fax} />
            <FormInput label={t('text:Email')} disabled={!editable} ref={register} name="email" error={errors.email} />
          </div>
          <div className="row">
            <FormInput
              label={t('text:Address')}
              disabled={!editable}
              col={12}
              ref={register}
              name="address"
              error={errors.address}
            />
          </div>
          <div className="row">
            <TextArea
              label={t('text:Message_for_Uploaded_Suppliers')}
              disabled={!editable}
              ref={register}
              name="message"
              curLength={message?.length}
              error={errors.message}
            />
            <div style={{ color: ColorMap.sub500 }}>
              *{' '}
              {t(
                'text:If_a_message_isn_t_written_the_platform_will_show_the_default_message_to_the_Uploaded_Suppliers',
              )}
            </div>
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default FinancierSCPartnerAcquisitionSettingContactForm;
