import { useMemo } from 'react';

import { COLLATERAL_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import { checkInterfaceType } from 'utils/storage/LocalStorage';

const useAgreementLocationState = () => {
  const [{ supportedCollateralType }, errorHandlerOfLocationState] = useLocationState<{
    supportedCollateralType: COLLATERAL_TYPE;
  }>(['supportedCollateralType']);

  const useAgreementInterface = useMemo(() => {
    const channelInterfaceTypes = [
      FINANCIER_INTERFACE_TYPE.ANCHOR_AGREEMENT_BY_CONTRACT_NO,
      FINANCIER_INTERFACE_TYPE.ANCHOR_AGREEMENT_BY_KEY_UNION,
    ];

    const vendorInterfaceTypes = [
      FINANCIER_INTERFACE_TYPE.BUYER_AGREEMENT_BY_CONTRACT_NO,
      FINANCIER_INTERFACE_TYPE.BUYER_AGREEMENT_BY_KEY_UNION,
    ];

    return checkInterfaceType(
      supportedCollateralType === COLLATERAL_TYPE.INVOICE ? channelInterfaceTypes : vendorInterfaceTypes,
    );
  }, [supportedCollateralType]);

  return {
    supportedCollateralType,
    errorHandlerOfLocationState,
    useAgreementInterface,
  };
};

export default useAgreementLocationState;
