import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

export async function requestDealerTempAgreementAttachmentDownload(tempLoanId: number) {
  await http.download(API_DE.TEMP_LOANS.DOWNLOAD_LOAN_AGREEMENT(tempLoanId));
}

export async function requestDealerTempApplicationAttachmentDownload(tempLoanId: number) {
  await http.download(API_DE.TEMP_LOANS.DOWNLOAD_LOAN_REQUEST_FORM(tempLoanId));
}

export async function requestDealerTempEtcAttachmentDownload(tempLoanId: number, tempLoanEtcAttachmentId: number) {
  await http.download(API_DE.TEMP_LOANS.DOWNLOAD_LOAN_ETC(tempLoanId), {
    tempLoanEtcAttachmentId,
  });
}
