import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROLE } from 'enums';
import { acMenuInfo } from 'router/anchor/AcMenuInfo';
import { deMenuInfo } from 'router/dealer/DeMenuInfo';
import { fiMenuInfo } from 'router/financier/FiMenuInfo';
import { syMenuInfo } from 'router/system/SyMenuInfo';
import { getSignIn } from 'utils/storage/LocalStorage';

export interface SideBarMainMenuType {
  title: string;
  path?: string;
  hidden?: boolean;
}

export interface SideBarSubMenuType {
  title: string;
  path: string;
  hidden?: boolean;
}

export interface SidebarMenuInfoType {
  mainMenu: SideBarMainMenuType;
  subMenu?: SideBarSubMenuType[];
}

const useSidebarController = () => {
  const { pathname: currentPath } = useLocation();
  const [openedMenuTitle, setOpenedMenuTitle] = useState<string>('');

  const getMenuInfoByRole = (): SidebarMenuInfoType[] => {
    const role = getSignIn()?.authorities[0].authority;

    switch (role) {
      case ROLE.ROLE_ANCHOR:
        return acMenuInfo().menuInfo;
      case ROLE.ROLE_DEALER:
        return deMenuInfo().menuInfo;
      case ROLE.ROLE_FINANCIER:
        return fiMenuInfo().menuInfo;
      default:
        return syMenuInfo().menuInfo;
    }
  };

  const menu = getMenuInfoByRole();

  /* 현재 url path 와 타켓 path 가 같은지 확인
    url : anchor/manage-partner/registration
    menuType === 'main' : manage-partner
    menuType === 'sub' : registration
  * */
  const isSameCurrentAndTargetPathByMenuType = useCallback(
    (targetPath: string, menuType: 'main' | 'sub') => {
      const splitLimit = menuType === 'main' ? 2 : 3;

      return currentPath.split('/')[splitLimit] === targetPath.split('/')[splitLimit];
    },
    [currentPath],
  );

  const updateOpenMenuTitle = () => {
    // open 시킬 메뉴를 current path 와 sub menu path 를 비교하여 state로 관리

    const hasActiveMainMenu = menu.some(
      menuItem => menuItem.mainMenu.path && isSameCurrentAndTargetPathByMenuType(menuItem.mainMenu.path, 'main'),
    );

    if (hasActiveMainMenu) setOpenedMenuTitle('');
    else {
      menu.forEach(menuItem => {
        const hasActiveSubMenu = menuItem.subMenu?.some(subMenuItem =>
          isSameCurrentAndTargetPathByMenuType(subMenuItem.path, 'main'),
        );

        if (hasActiveSubMenu) setOpenedMenuTitle(menuItem.mainMenu.title);
      });
    }
  };

  useEffect(() => {
    updateOpenMenuTitle();
  }, [currentPath, isSameCurrentAndTargetPathByMenuType, menu]);

  return {
    currentPath,
    isSameCurrentAndTargetPathByMenuType,
    getMenuInfoByRole,
    menu,
    openedMenuTitle,
  };
};

export default useSidebarController;
