import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';

import AnchorApListDeletedTab from './tabs/AnchorApListDeletedTab';
import AnchorApListRegisteredTab from './tabs/AnchorApListRegisteredTab';
import useAnchorApListController from './useAnchorApListController';

function AnchorApList() {
  const { t } = useTranslation(['format']);

  const {
    successApPage,
    deletedApPage,
    registeredAPSearchForm,
    deletedAPSearchForm,
    registeredListPageable,
    deletedListPageable,
    handleClickRegisteredAPSearch,
    paginateRegisteredAPList,
    handleClickDeletedAPSearch,
    paginateDeletedAPList,
  } = useAnchorApListController();

  return (
    <>
      <HeaderTitle title={t('text:AP')} />
      <GuideMessage
        message={[
          t('text:APs_that_wait_for_approval_or_are_registered_can_be_found_on_the_Registered_tab'),
          t('text:APs_deleted_by_admin_after_approval_is_completed_are_available_on_the_Deleted_tab'),
        ]}
      />
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:Registered'),
            tabItemCount: successApPage?.totalElements ?? 0,
          },
          {
            tabName: t('text:Deleted'),
            tabItemCount: deletedApPage?.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...registeredAPSearchForm} key="registered">
            <AnchorApListRegisteredTab
              successApList={successApPage}
              pageable={registeredListPageable}
              paginate={paginateRegisteredAPList}
              handleClickSearch={handleClickRegisteredAPSearch}
            />
          </FormProvider>,
          <FormProvider {...deletedAPSearchForm} key="deleted">
            <AnchorApListDeletedTab
              deletedAPList={deletedApPage}
              pageable={deletedListPageable}
              paginate={paginateDeletedAPList}
              handleClickSearch={handleClickDeletedAPSearch}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default AnchorApList;
