import type { HTMLAttributes } from 'react';
import type React from 'react';

interface CardHeaderProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

function CardHeader({ className = 'simple-card-header', style, children, ...props }: CardHeaderProps) {
  return (
    <div className={className} style={style} {...props}>
      {children}
    </div>
  );
}

export default CardHeader;
