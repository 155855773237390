import type { HTMLAttributes } from 'react';
import type React from 'react';

interface CardBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

function CardBody({ className = 'simple-card-body', style, children, ...props }: CardBodyProps) {
  return (
    <div className={className} style={style} {...props}>
      {children}
    </div>
  );
}

export default CardBody;
