import type Pageable from 'models/Pageable';
import type { WaitingAnchorAgreementVO, WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import { formattingToWaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorAgreementHistoryListRequest } from './request';

export async function requestWaitingAnchorAgreementList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorAgreementHistoryListRequest,
): Promise<Pageable<WaitingAnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorAgreementVO[]>>({
    url: API_AC.WAITING_ANCHOR_AGREEMENTS.WAITING_ANCHOR_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const anchorAgreementHistoryList: WaitingAnchorAgreementVOModel[] = response.content.map(
    (data: WaitingAnchorAgreementVO) => formattingToWaitingAnchorAgreementVOModel(data),
  );
  const anchorAgreementHistoryPage: Pageable<WaitingAnchorAgreementVOModel[]> = {
    ...response,
    content: anchorAgreementHistoryList,
  };

  return anchorAgreementHistoryPage;
}
