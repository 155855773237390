import type Pageable from 'models/Pageable';
import type { WaitingAnchorUserVO, WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import { formattingToWaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemWaitingAnchorUserListRequest } from './requests';

export async function requestSystemWaitingAnchorUserList(
  pageNumber: number,
  rowCount: number,
  data: SystemWaitingAnchorUserListRequest,
): Promise<Pageable<WaitingAnchorUserVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorUserVO[]>>({
    url: API_SY.WAITING_ANCHOR_USERS.WAITING_ANCHOR_USER_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToWaitingAnchorUserVOModel(item)),
  };
}

export async function requestSystemWaitingAnchorUserData(
  waitingAnchorUserId: number,
): Promise<WaitingAnchorUserVOModel> {
  const response = await http.get<WaitingAnchorUserVO>({
    url: API_SY.WAITING_ANCHOR_USERS.WAITING_ANCHOR_USER_DETAIL(waitingAnchorUserId),
  });

  return formattingToWaitingAnchorUserVOModel(response);
}
