import type { HTMLAttributes } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import clsx from 'clsx';

import './ConfirmCheckBox.scss';

interface ConfirmCheckBoxProps extends HTMLAttributes<HTMLInputElement> {
  id: string;
  checked: boolean;
  onChangeCheckBox: (checked: boolean) => void;
  labelText: string;
  disabled?: boolean;
}

export interface ConfirmCheckBoxRefHandle {
  focus: () => void;
}

const ConfirmCheckBox = forwardRef<ConfirmCheckBoxRefHandle, ConfirmCheckBoxProps>(
  ({ id, checked, onChangeCheckBox, labelText, disabled }, ref) => {
    const confirmCheckBoxRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      focus: () => {
        confirmCheckBoxRef?.current?.focus();
      },
    }));

    return (
      <div className={clsx('check-confirm-form', { 'checked-confirm': checked })}>
        <div className="form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            disabled={disabled}
            id={id}
            checked={checked}
            onChange={e => onChangeCheckBox(e.target.checked)}
            ref={confirmCheckBoxRef}
          />
          <label className={clsx('form-check-label', { opacityApplied: disabled })} htmlFor={id}>
            {labelText}
          </label>
        </div>
      </div>
    );
  },
);

ConfirmCheckBox.displayName = 'ConfirmCheckBox';
export default ConfirmCheckBox;
