import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormSelect, FormSubtitle, FormValue } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormGuideMessage } from 'components/stateless/GuideMessage/FormGuideMessage';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { REPORT_TYPE } from 'enums';
import { AUTHORITY_TYPE } from 'enums';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierAnchorAgreementData } from 'utils/http/api/financier/anchor-agreements';
import { requestFiAnchorReportSettingRegist } from 'utils/http/api/financier/anchor-report-settings';
import type { FiAnchorReportSettingRegisterRequest } from 'utils/http/api/financier/anchor-report-settings/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import FinancierAnchorReportSearchAnchorAgreementModal from '../modal/FinancierAnchorReportSearchAnchorAgreementModal';

const getConstants = () => {
  const { t } = useTranslation(['format']);

  const ANCHOR_USER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:User_Name'),
      colWidths: 140,
    },
    {
      headerText: t('text:Email'),
      colWidths: 120,
    },
    {
      headerText: t('text:Authority'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registration_Status'),
      colWidths: 100,
    },
  ];

  return { t, ANCHOR_USER_TABLE_HEADERS };
};

function FinancierAnchorReportRegister(): JSX.Element {
  const modal = useModal();
  const history = useHistory();
  const { t, ANCHOR_USER_TABLE_HEADERS } = getConstants();
  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementDetailVOModel>();
  const {
    register: anchorReportSettingRegister,
    setValue: setAnchorReportSettingValue,
    getValues: getAnchorReportSettingValues,
    watch: watchAnchorReportSetting,
    setError: setAnchorReportSettingError,
    errors: anchorReportSettingErrors,
    clearErrors: clearAnchorReportSettingErrors,
  } = useForm<FiAnchorReportSettingRegisterRequest>();

  const { anchorAgreementId: anchorAgreementIdWatcher, reportType: reportTypeWatcher } = watchAnchorReportSetting();

  const isRegisterButtonDisabled = (): boolean => {
    return (
      anchorAgreementIdWatcher === undefined ||
      anchorAgreementIdWatcher === null ||
      (anchorAgreementIdWatcher as any) === '' ||
      reportTypeWatcher === undefined ||
      reportTypeWatcher === null ||
      (reportTypeWatcher as any) === ''
    );
  };

  const onClickSearchAgreement = (e: any) => {
    e.preventDefault();

    const modalId = modal.id;

    const setSelectedAnchorAgreementData = async (data: AnchorAgreementVOModel): Promise<void> => {
      try {
        const anchorAgreementDetailResponse = await requestFinancierAnchorAgreementData(data.anchorAgreementId);
        setAnchorReportSettingValue('anchorAgreementId', data.anchorAgreementId);
        setAnchorAgreementData(anchorAgreementDetailResponse);
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <FinancierAnchorReportSearchAnchorAgreementModal
        modalId={modalId}
        getSelectedAnchorAgreementData={setSelectedAnchorAgreementData}
      />,
      {
        title: t('text:Search_Agreement'),
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickRegister = async (e: any): Promise<void> => {
    e.preventDefault();

    try {
      const data = getAnchorReportSettingValues();

      await requestFiAnchorReportSettingRegist(data);

      modal.show(<h6>{t('text:The_report_setting_was registered_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          history.push(ROUTES_FI.MANAGE_ANCHOR.REPORT_LIST);
        },
      });
    } catch (error) {
      modal.show(error);
      formErrorHandler<FiAnchorReportSettingRegisterRequest>(
        error,
        setAnchorReportSettingError,
        clearAnchorReportSettingErrors,
      );
    }
  };

  const renderAnchorUserInformationTable = (): JSX.Element[] | undefined => {
    const getAuthorityTypeText = (data?: AUTHORITY_TYPE): string => {
      switch (data) {
        case AUTHORITY_TYPE.OPERATOR:
          return t('text:Operator');
        case AUTHORITY_TYPE.AUTHORIZER:
          return t('text:Authorizer');
        case AUTHORITY_TYPE.HQ_OPERATOR:
          return t('text:Admin_Operator');
        case AUTHORITY_TYPE.ADMIN:
          return t('text:Admin');
        default:
          return '';
      }
    };

    return anchorAgreementData?.anchorUserList.map((item, index) => (
      <Tr key={index}>
        <Td data={item.anchorClientCode} />
        <Td data={item.anchorClientName} />
        <Td data={item.email} />
        <Td data={getAuthorityTypeText(item.authorityType)} />
        <Td
          className={getStatusTextClass('USER_STATUS', item?.userStatus)}
          data={t(`code:user-status.${item.userStatus}`)}
        />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Report')} />
      <div className="content-area">
        <SectionTitle title={t('text:Report_Receiver')} />
        <FormBorder editable={true}>
          <FormGuideMessage
            message={[
              t('text:The_report_will_be_sent_to_the_admin_user_designated_on_the_agreement'),
              t('text:Click_[Search_Agreement]_to_select_an_agreement_to_register'),
              t('text:After_selecting_the_agreement_check_the_information_of_the_agreement_below'),
            ]}
          >
            {{
              button: <Button onClick={onClickSearchAgreement}>{t('text:Search_Agreement')}</Button>,
            }}
          </FormGuideMessage>
          <FormSubtitle title={t('text:Anchor_Agreement_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <input type="hidden" name="anchorAgreementId" ref={anchorReportSettingRegister} />
            <div className="row">
              <FormValue label={t('text:Master_Agreement_Number')} value={anchorAgreementData?.contractNo} />
              <FormValue label={t('text:Client_Code')} value={anchorAgreementData?.financierClientCode} />
            </div>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={anchorAgreementData?.financierClientName} />
              <FormValue col={3} label={t('text:Currency')} value={anchorAgreementData?.currencyType} />
              <FormValue
                col={3}
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${anchorAgreementData?.collateralType}`)}
                format="code"
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Agreement_Status')}
                value={t(`code:anchor-agreement-status.${anchorAgreementData?.anchorAgreementStatus}`)}
                format="code"
              />
              <FormValue
                col={3}
                label={t('text:Effective_Date')}
                format="date"
                value={anchorAgreementData?.startDate}
              />
              <FormValue
                col={3}
                label={t('text:Expiration_Date')}
                format="date"
                value={anchorAgreementData?.expiryDate}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Anchor_User_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <TableBorder>
              <TableHeader header={ANCHOR_USER_TABLE_HEADERS} />
              <TableBody numOfCol={ANCHOR_USER_TABLE_HEADERS.length}>{renderAnchorUserInformationTable()}</TableBody>
            </TableBorder>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Report_Type')} />
        <FormBorder editable={true}>
          <FormGuideMessage
            message={[
              t('text:Select_the_type_of_report_to_send'),
              `${t('text:Only_one_report_can_be_registered_at_a_time')} ${t(
                'text:Follow_the_same_procedure_to_register_multiple_reports',
              )}`,
            ]}
          />
          <div className="border-bottom-light-gray" />
          <FormContents>
            <FormSelect
              name="reportType"
              label={t('text:Report_Type')}
              ref={anchorReportSettingRegister}
              selectOptions={getSelectOptions<REPORT_TYPE>('REPORT_TYPE')}
              placeholderOptions={{ show: true }}
              error={anchorReportSettingErrors.reportType}
            />
          </FormContents>
        </FormBorder>
        <div className="flex-end mt-3">
          <Button disabled={isRegisterButtonDisabled()} onClick={onClickRegister} size={ButtonSizeEnum.LG}>
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default FinancierAnchorReportRegister;
