import PotentialPartnerApplicationProvider from './PotentialPartnerApplicationProvider';
import PotentialPartnerApplicationStepWrapper from './steps/PotentialPartnerApplicationStepWrapper';

function PotentialPartnerApplicationPage() {
  return (
    <PotentialPartnerApplicationProvider>
      <PotentialPartnerApplicationStepWrapper />
    </PotentialPartnerApplicationProvider>
  );
}

export default PotentialPartnerApplicationPage;
