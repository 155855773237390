import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

import { formattingToAnchorUserVOModel } from './AnchorUserVO';

import type { AnchorUserVO, AnchorUserVOModel } from './AnchorUserVO';
import type { WaitingAnchorAgreementVO } from './WaitingAnchorAgreementVO';

export interface TempWaitingAnchorAgreementVO extends WaitingAnchorAgreementVO {
  tempWaitingAnchorAgreementId: number;
  loanLimitCheck: boolean;
  collateralIssuedLimitCheck: boolean;
  collateralIssuedLimitAmount: string;
  division: string;
  divisionName: string;
  anchorUserList: AnchorUserVO[];
}

export interface TempWaitingAnchorAgreementVOModel extends TempWaitingAnchorAgreementVO {
  loanLimitAmount: BigNumber;
  collateralIssuedLimitAmount: BigNumber;
  anchorUserList: AnchorUserVOModel[];
}

export function formattingToTempWaitingAnchorAgreementVOModel(
  data: TempWaitingAnchorAgreementVO,
): TempWaitingAnchorAgreementVOModel {
  const { loanLimitAmount, collateralIssuedLimitAmount } = data;

  return {
    ...data,
    loanLimitAmount: sliceZeroDecimal(loanLimitAmount),
    collateralIssuedLimitAmount: sliceZeroDecimal(collateralIssuedLimitAmount),
    anchorUserList: data?.anchorUserList?.map(anchorUser => formattingToAnchorUserVOModel(anchorUser)),
  };
}
