import type { ReactNode } from 'react';

type DescriptionPropsType = {
  children: ReactNode;
};

const Description = ({ children }: DescriptionPropsType) => {
  return <p className="form__description">{children}</p>;
};

export default Description;
