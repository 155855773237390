import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { requestTempAnchorDealerAgreementsList } from 'utils/http/api/anchor/dealer-agreements';
import useModal from 'utils/modal/useModal';
import { getFormSearchParamValue, updateSearchParams } from 'utils/searchParams';

const AC_INVOICE_REGISTER_STEP1_LIST_QS_KEY = 'ac-invoice-register-step1-list';

function AnchorInvoiceRegistrationByPartnerStep1() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(AC_INVOICE_REGISTER_STEP1_LIST_QS_KEY);
  const [searchDealerName, setSearchDealerName] = useState<string>('');
  const [dealerAgreementPage, setDealerAgreementPage] = useState<Pageable<DealerAgreementVOModel[]>>();

  useEffect(() => {
    if (mounted) {
      setSearchDealerName(getFormSearchParamValue('dealerName'));
      fetchAll(pageable.currentPage, pageable.sizePerPage, getFormSearchParamValue('dealerName'));
    }
  }, [mounted]);

  async function fetchAll(pageNumber: number = 1, sizePerPage: number = 10, searchData: string): Promise<void> {
    try {
      const dealerAgreementPage = await requestTempAnchorDealerAgreementsList(pageNumber, sizePerPage, {
        dealerName: searchData,
      });

      updateSearchParams(
        { dealerName: searchData, pageNumber, rowCount: sizePerPage },
        AC_INVOICE_REGISTER_STEP1_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementPage(dealerAgreementPage);
        setPageable(dealerAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onBlurUpdateDealerName = (e: any) => {
    setSearchDealerName(e.target.value);
  };

  const onClickSearch = async (e: any) => {
    e.preventDefault();
    fetchAll(1, pageable.sizePerPage, searchDealerName);
  };

  const paginate = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    await fetchAll(pageNumber, sizePerPage, getFormSearchParamValue('dealerName'));
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Code_of_Anchor'),
      colWidths: 120,
    },
    {
      headerText: t('text:Tax_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 90,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 180,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 150,
    },
    {
      headerText: '',
      colWidths: 80,
    },
  ];

  const renderResultTable = () => {
    return dealerAgreementPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.dealerFinancierClientName} />
          <Td data={item.dealerCodeByAnchor} />
          <Td data={item.dealerFinancierClientTaxCode} />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.dealerFinancierClientAddress} />
          <Td data={item.dealerFinancierClientRepresentativeName} />
          <Td className="text-center">
            <Button
              size={ButtonSizeEnum.XS}
              bold
              to={ROUTES_AC.REGISTER_INVOICE.REGISTRATION_BY_PARTNER_STEP2_BUILD_PATH(
                item.dealerAgreementId,
                item.financierId,
              )}
              state={{
                anchorAgreementId: item.anchorAgreementId,
              }}
            >
              {t('text:Select')}
            </Button>
          </Td>
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Invoice_Registration_by_Partner')} />
      <div className="content-area">
        <StepWizard nth={1} title={[t('text:Select_Partner'), t('text:Enter_Invoice_Information_&_Register')]} />
      </div>
      <div className="content-area">
        <div className="d-flex">
          <h2>{t('text:Partner_List')}</h2>
        </div>
        <form>
          <div className="d-flex information-filter mt-2">
            <input
              className="only-input"
              type="text"
              placeholder={t('text:Partner_Name')}
              onBlur={onBlurUpdateDealerName}
              defaultValue={searchDealerName}
            />
            <Button size={ButtonSizeEnum.XS} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorInvoiceRegistrationByPartnerStep1;
