import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import NavigationGuard from 'components/stateless/NavigationGuard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import WithProvider from 'utils/render';

import useRegistrationController from './useRegistrationController';
import ExtraInformationProvider from '../models/extraInformation/Context';
import FinancingOptionProvider from '../models/financingOption/Provider';
import AssociatedAnchorMasterAgreement from '../sections/AssociatedAnchorMasterAgreement';
import DisbursementAccount from '../sections/DisbursementAccount';
import Financier from '../sections/Financier';
import FinancingDuration from '../sections/FinancingDuration';
import FinancingLimit from '../sections/FinancingLimit';
import GeneralInformation from '../sections/GeneralInformation';
import InterestRate from '../sections/InterestRate';

const AnchorFinancingOptionRegistration = () => {
  const { t } = useTranslation(['format']);

  const { methods, handleRegisterButtonClick, activatedNavigationGuard, ignoreNavigationGuard } =
    useRegistrationController();

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Financing_Option_Registration')} />

      <GuideMessage
        message={[
          t('text:Please_enter_the_financing_option_information_below'),
          t('text:Enter_the_anchor_financing_option_information_by_using_the_Search_button_below'),
        ]}
      />

      <Form methods={methods} isEditable isEditableStyle>
        <div className="content-area">
          <SectionTitle title={t('text:Contracting_Parties')} />
          <Border isEditable>
            <AssociatedAnchorMasterAgreement />
            <Financier />
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle title={t('text:General_Information')} />
          <Border isEditable>
            <GeneralInformation />
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle title={t('text:Financing_Terms_and_Conditions')} />
          <Border isEditable>
            <FinancingLimit />
            <InterestRate />
            <FinancingDuration />
          </Border>
        </div>
        <div className="content-area">
          <SectionTitle title={t('text:Designated_Account')} />
          <Border isEditable>
            <DisbursementAccount />
          </Border>
        </div>
      </Form>

      <div className="content-area flex-end">
        <Button size={ButtonSizeEnum.LG} onClick={handleRegisterButtonClick}>
          {t('text:Registration_Request')}
        </Button>
      </div>
      <NavigationGuard
        when={activatedNavigationGuard}
        ignore={ignoreNavigationGuard}
        useTwoButtons
        confirmBtnText={t('text:OK')}
        cancelBtnText={t('text:Close')}
        buttonSize={ButtonSizeEnum.MD}
      >
        <h6>
          {t('text:Are_you_sure_you_want_to_leave_this_page?')}
          <br />
          {t('text:Please_note_that_all_unsaved_changes_will_be_lost_if_you_leave_this_page_without_saving')}
        </h6>
      </NavigationGuard>
    </>
  );
};

export default WithProvider({
  Component: AnchorFinancingOptionRegistration,
  Provider: [ExtraInformationProvider, FinancingOptionProvider],
});
