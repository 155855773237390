import { AUTHORITY_TYPE, NOTIFICATION_TYPE, ROLE } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { UserNotification } from 'models/vo/UserNotificationVO';
import { getSignIn } from 'utils/storage/LocalStorage';

import type { TFunction } from 'i18next';

type NotificationContentsType = {
  [event in NOTIFICATION_TYPE]: () => {
    condition: boolean;
    title: string;
    description: string;
  }[];
};

export const getNotificationContents = (notificationItem: UserNotification, t: TFunction) => {
  const signInModel: SignInModel | null = getSignIn();
  const role = signInModel?.authorities[0].authority as ROLE;
  const authorityType = signInModel?.authorityType as AUTHORITY_TYPE;

  const {
    notificationType,
    preRequestedUserName,
    preRequestedUserAuthorityType,
    preRequestedEnterpriseName,
    eventIdentifier,
    relatedAnchorClientName,
    relatedBranchName,
    relatedFinancierName,
    relatedPartnerClientName,
    relatedPartnerName,
    requestedEnterpriseName,
    requestedUserAuthorityType,
    requestedUserName,
  } = notificationItem;

  const contents: NotificationContentsType = {
    [NOTIFICATION_TYPE.LOAN_DEALER_CREATED]: () => [
      {
        condition: true,
        title: t('text:Financing_Requested'),
        description: t('text:Operator_requestedUserName_has_requested_approval_for_financing_request', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_DEALER_CANCELLED]: () => [
      {
        condition: true,
        title: t('text:Financing_Request_Cancelled'),
        description: t('text:authorityType_requestedUserName_has_cancelled_the_financing_request', {
          authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_DEALER_REQUESTED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Request_Submitted'),
        description: t('text:Authorizer_requestedUserName_has_submitted_financing_request_to_the_financier', {
          requestedUserName,
        }),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Requested'),
        description: t('text:Partner_Company_relatedPartnerClientName_has_requested_approval_for_financing_request', {
          relatedPartnerClientName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_REJECTED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Rejected'),
        description: t('text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_financing_request', {
          relatedFinancierName,
          relatedBranchName,
        }),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Request_Rejected'),
        description: t(
          'text:Operator_requestedUserName_has_rejected_the_financing_request_from_Partner_Company_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_APPROVAL_REQUEST]: () => [
      {
        condition: true,
        title: t('text:Financing_Approval_Requested'),
        description: t(
          'text:Operator_requestedUserName_has_requested_Authorizer_to_approve_the_financing_request_from_Partner_Company_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_APPROVAL_REQUEST_CANCEL]: () => [
      {
        condition: true,
        title: t('text:Financing_Approval_Request_Cancelled'),
        description: t(
          'text:Operator_requestedUserName_has_cancelled_the_approval_request_for_relatedPartnerClientName_s_financing_request',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_APPROVAL_REQUEST_RETURN]: () => [
      {
        condition: true,
        title: t('text:Financing_Request_Reverted'),
        description: t(
          'text:Authorizer_requestedUserName_has_reverted_the_financing_request_from_Partner_Company_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_APPROVED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Confirmed'),
        description: t('text:Financier_relatedFinancierName_relatedBranchName_has_confirmed_the_financing_request', {
          relatedFinancierName,
          relatedBranchName,
        }),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Confirmed'),
        description: t(
          'text:Authorizer_requestedUserName_has_confirmed_the_financing_request_from_Partner_Company_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST]: () => [
      {
        condition: true,
        title: t('text:Financing_Rejection_Requested'),
        description: t(
          'text:Operator_requestedUserName_has_requested_Authorizer_to_cancel_the_approval_and_reject_the_financing_request_from_Partner_Company_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST_CANCEL]: () => [
      {
        condition: true,
        title: t('text:Financing_Rejection_Request_Cancelled'),
        description: t(
          'text:Operator_requestedUserName_has_cancelled_the_financing_approval_rejection_for_relatedPartnerClientName_s_financing_request',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST_RETURNED]: () => [
      {
        condition: true,
        title: t('text:Financing_Rejection_Request_Reverted'),
        description: t(
          'text:Authorizer_requestedUserName_has_reverted_the_request_to_cancel_the_approval_for_financing_request_from_Partner_Company_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_REJECTION_REQUEST_CONFIRM]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Rejected'),
        description: t('text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_financing_request', {
          relatedFinancierName,
          relatedBranchName,
        }),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Rejected'),
        description: t(
          'text:Authorizer_requestedUserName_has_approved_the_request_to_cancel_the_financing_approval_rejecting_the_financing_request_from_Partner_Company_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.EARLY_PAYMENT_REQUESTED]: () => [
      {
        condition: true,
        title: t('text:Early_Repayment_Requested'),
        description: t(
          'text:Partner_Company_relatedPartnerClientName_has_requested_the_early_repayment_of_financing_eventIdentifier',
          {
            eventIdentifier,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.EARLY_PAYMENT_COMPLETED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Early_Repayment_Confirmed'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_confirmed_the_early_repayment_of_financing_eventIdentifier',
          {
            relatedFinancierName,
            relatedBranchName,
            eventIdentifier,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Early_Repayment_Confirmed'),
        description: t(
          'text:authorityType_requestedUserName_has_confirmed_the_early_repayment_request_from_Partner_Company_relatedPartnerClientName_financing_id_eventIdentifier',
          {
            authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
            relatedPartnerClientName,
            eventIdentifier,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.EARLY_PAYMENT_EXPIRED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Early_Repayment_Rejected'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_early_repayment_of_financing_eventIdentifier',
          {
            relatedFinancierName,
            relatedBranchName,
            eventIdentifier,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Early_Repayment_Rejected'),
        description: t(
          'text:authorityType_requestedUserName_has_rejected_the_early_repayment_request_from_Partner_Company_relatedPartnerClientName_financing_id_eventIdentifier',
          {
            authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
            relatedPartnerClientName,
            eventIdentifier,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_EXTENSION_REQUESTED]: () => [
      {
        condition: true,
        title: t('text:Maturity_Extension_Requested'),
        description: t(
          'text:Partner_Company_relatedPartnerClientName_has_requested_maturity_extension_of_financing_eventIdentifier',
          {
            eventIdentifier,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_EXTENSION_REJECTED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Maturity_Extension_Rejected'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_maturity_extension_request_of_financing_eventIdentifier',
          {
            eventIdentifier,
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Maturity_Extension_Rejected'),
        description: t(
          'text:Operator_requestedUserName_has_rejected_the_maturity_extension_request_from_Partner_Company_relatedPartnerClientName_financing_id_eventIdentifier',
          {
            requestedUserName,
            eventIdentifier,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_EXTENSION_PREAPPROVED]: () => [
      {
        condition: true,
        title: t('text:Confirmation_for_Maturity_Extension_Pended'),
        description: t(
          'text:Operator_userName_has_reviewed_and_requested_Authorizer_to_confirm_the_maturity_extension_request_from_Partner_Company_relatedPartnerClientName_financing_id_eventIdentifier',
          {
            userName: requestedUserName,
            relatedPartnerClientName,
            eventIdentifier,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_EXTENSION_RETURNED]: () => [
      {
        condition: true,
        title: t('text:Maturity_Extension_Reverted'),
        description: t(
          'text:Authorizer_requestedUserName_has_reverted_the_maturity_extension_request_from_Partner_Company_relatedPartnerClientName_financing_id_eventIdentifier',
          {
            requestedUserName,
            relatedPartnerClientName,
            eventIdentifier,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.LOAN_EXTENSION_APPROVED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Maturity_Extension_Confirmed'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_confirmed_the_maturity_extension_request_of_financing_eventIdentifier',
          {
            relatedFinancierName,
            relatedBranchName,
            eventIdentifier,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Maturity_Extension_Confirmed'),
        description: t(
          'text:Authorizer_requestedUserName_has_confirmed_the_maturity_extension_request_from_Partner_Company_relatedPartnerClientName_financing_id_eventIdentifier',
          {
            requestedUserName,
            relatedPartnerClientName,
            eventIdentifier,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.ANCHOR_AGREEMENT_REQUEST]: () => [
      {
        condition: true,
        title: t('text:Anchor_Agreement_Update_Requested'),
        description: t(
          'text:Operator_requestedUserName_has_requested_approval_to_update_the_Anchor_Master_Agreement_with_relatedAnchorClientName',
          {
            requestedUserName,
            relatedAnchorClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.ANCHOR_AGREEMENT_REQUEST_CANCEL]: () => [
      {
        condition: true,
        title: t('text:Anchor_Agreement_Update_Cancelled'),
        description: t(
          'text:Operator_requestedUserName_has_cancelled_the_update_of_Anchor_Master_Agreement_with_relatedAnchorClientName',
          {
            requestedUserName,
            relatedAnchorClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.ANCHOR_AGREEMENT_REQUEST_RETURNED]: () => [
      {
        condition: true,
        title: t('text:Anchor_Agreement_Update_Reverted'),
        description: t(
          'text:Authorizer_requestedUserName_has_reverted_the_update_of_Anchor_Master_Agreement_with_relatedAnchorClientName',
          {
            requestedUserName,
            relatedAnchorClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.ANCHOR_AGREEMENT_REQUEST_APPROVED]: () => [
      {
        condition: true,
        title: t('text:Anchor_Agreement_Update_Confirmed'),
        description: t(
          'text:Authorizer_requestedUserName_has_confirmed_the_update_of_Anchor_Master_Agreement_with_relatedAnchorClientName',
          {
            requestedUserName,
            relatedAnchorClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.PARTNER_AGREEMENT_REQUEST]: () => [
      {
        condition: true,
        title: t('text:Partner_Agreement_Update_Requested'),
        description: t(
          'text:Operator_requestedUserName_has_requested_approval_to_update_the_Partner_Master_Agreement_with_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.PARTNER_AGREEMENT_REQUEST_CANCEL]: () => [
      {
        condition: true,
        title: t('text:Partner_Agreement_Update_Cancelled'),
        description: t(
          'text:Operator_requestedUserName_has_cancelled_the_update_of_Partner_Master_Agreement_with_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.PARTNER_AGREEMENT_REQUEST_RETURNED]: () => [
      {
        condition: true,
        title: t('text:Partner_Agreement_Update_Reverted'),
        description: t(
          'text:Authorizer_requestedUserName_has_reverted_the_update_of_Partner_Master_Agreement_with_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.PARTNER_AGREEMENT_REQUEST_APPROVED]: () => [
      {
        condition: true,
        title: t('text:Partner_Agreement_Update_Confirmed'),
        description: t(
          'text:Authorizer_requestedUserName_has_confirmed_the_update_of_Partner_Master_Agreement_with_relatedPartnerClientName',
          {
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.AR_APPROVAL_REQUEST]: () => [
      {
        condition: true,
        title: t('text:AR_Registration_Requested'),
        description: t('text:Operator_requestedUserName_has_requested_approval_for_AR_registration', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.AR_APPROVAL_REJECT]: () => [
      {
        condition: true,
        title: t('text:AR_Registration_Request_Rejected'),
        description: t('text:Authorizer_requestedUserName_has_rejected_AR_registration_request', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.AR_REGISTRATION_REQUEST]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:AR_Registration_Request_Submitted'),
        description: t('text:Authorizer_requestedUserName_has_submitted_AR_registration_request_to_Anchor', {
          requestedUserName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:AP_Registration_Requested'),
        description: t('text:Partner_Company_requestedEnterpriseName_has_requested_approval_for_AP_registration', {
          requestedEnterpriseName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.AR_REGISTRATION_REQUEST_TO_FI]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:AR_Registration_Request_Submitted'),
        description: t('text:Authorizer_requestedUserName_has_submitted_AR_registration_request_to_Financier', {
          requestedUserName,
        }),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:AR_Registration_Requested'),
        description: t('text:Partner_Company_requestedEnterpriseName_has_requested_approval_for_AR_registration', {
          requestedEnterpriseName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.AR_REGISTRATION_REJECT_BY_FI]: () => [
      {
        condition: true,
        title: t('text:AR_Registration_Request_Rejected'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_AR_registration_request',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
    ],

    [NOTIFICATION_TYPE.AR_REGISTRATION_REJECT]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:AR_Registration_Request_Rejected'),
        description: t('text:Anchor_Company_requestedEnterpriseName_has_rejected_the_AR_registration_request', {
          requestedEnterpriseName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:AP_Registration_Request_Rejected'),
        description: t(
          'text:authorityType_requestedUserName_has_rejected_the_AP_registration_request_from_Partner_Company_relatedPartnerName',
          {
            authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
            relatedPartnerName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.AR_CONFIRMATION_REQUEST]: () => [
      {
        condition: true,
        title: t('text:AP_Registration_Requested'),
        description: t('text:Admin_Operator_requestedUserName_has_requested_approval_for_AP_registration', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.AR_CONFIRMATION_REQUEST_BY_FI]: () => [
      {
        condition: true,
        title: t('text:AR_Registration_Requested'),
        description: t('text:Operator_requestedUserName_has_requested_approval_for_AR_registration_request', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.AR_CONFIRMATION_REJECT]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:AR_Registration_Request_Rejected'),
        description: t('text:Anchor_Company_requestedEnterpriseName_has_rejected_the_AR_registration_request', {
          requestedEnterpriseName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:AP_Registration_Request_Rejected'),
        description: t(
          'text:Admin_requestedUserName_has_rejected_the_AP_registration_request_of_Admin_Operator_preRequestedUserName',
          {
            preRequestedUserName,
            requestedUserName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.AR_CONFIRMATION_REJECT_BY_FI]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:AR_Registration_Request_Rejected'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_AR_registration_request',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:AR_Registration_Request_Rejected'),
        description: t(
          'text:Authorizer_requestedUserName_has_rejected_the_AR_registration_request_of_the_Partner_preRequestedEnterpriseName',
          {
            requestedUserName,
            preRequestedEnterpriseName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.AR_CONFIRMED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:New_AR_Registered'),
        description: t('text:Anchor_Company_requestedEnterpriseName_has_confirmed_the_AR_registration', {
          requestedEnterpriseName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:New_AP_Registered'),
        description: t('text:Admin_requestedUserName_has_registered_new_AP(s)', { requestedUserName }),
      },
    ],
    [NOTIFICATION_TYPE.AR_CONFIRMED_BY_FI]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:New_AR_Registered'),
        description: t('text:Financier_relatedFinancierName_relatedBranchName_has_confirmed_the_AR_registration', {
          relatedFinancierName,
          relatedBranchName,
        }),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:New_AR_Registered'),
        description: t(
          'text:Authorizer_requestedUserName_has_confirmed_the_AR_registration_request_from_Operator_preRequestedUserName',
          {
            requestedUserName,
            preRequestedUserName,
          },
        ),
      },
    ],

    [NOTIFICATION_TYPE.INVOICE_APPROVAL_REQUEST]: () => [
      {
        condition: true,
        title: t('text:Invoice_Registration_Requested'),
        description: t('text:Operator_requestedUserName_has_requested_approval_for_an_invoice_registration_request', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_APPROVAL_REJECT]: () => [
      {
        condition: true,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t('text:Authorizer_requestedUserName_has_rejected_the_invoice_registration_request', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_REGISTRATION_REQUEST]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Invoice_Registration_Request_Submitted'),
        description: t('text:Authorizer_requestedUserName_has_reviewed_and_submitted_an_invoice_registration_request', {
          requestedUserName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:Invoice_Registration_Requested'),
        description: t(
          'text:Partner_Company_relatedPartnerClientName_has_requested_approval_for_invoice_registration',
          {
            relatedPartnerClientName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Invoice_Registration_Requested'),
        description: t(
          'text:Partner_Company_relatedPartnerClientName_has_requested_approval_for_invoice_registration',
          {
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_AC]: () => [
      {
        condition: true,
        title: t('text:Invoice_Registration_Requested'),
        description: t(
          'text:authorityType_requestedUserName_has_requested_approval_for_an_invoice_registration_request',
          {
            authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_REGISTRATION_REJECT_BY_AC]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t('text:Anchor_Company_relatedAnchorClientName_has_rejected_the_invoice_registration_request', {
          relatedAnchorClientName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t(
          'text:authorityType_requestedUserName_has_rejected_the_invoice_registration_request_of_Partner_Company_relatedPartnerClientName',
          {
            authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REJECT_BY_AC]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t('text:Anchor_Company_relatedAnchorClientName_has_rejected_the_invoice_registration_request', {
          relatedAnchorClientName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t(
          'text:requestedAuthorityType_requestedUserName_has_rejected_the_invoice_registration_request_from_preRequestedAuthorityType_preRequestedUserName',
          {
            requestedAuthorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
            preRequestedAuthorityType: t(`code:authority-type.${preRequestedUserAuthorityType}`),
            preRequestedUserName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_CONFIRMATION_CANCEL_BY_AC]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t('text:Anchor_Company_relatedAnchorClientName_has_rejected_the_invoice_registration_request', {
          relatedAnchorClientName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:Invoice_Registration_Cancelled'),
        description: t(
          'text:authorityType_requestedUserName_has_cancelled_the_invoice_registration_request_for_the_Partner_relatedPartnerClientName',
          {
            authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_CONFIRMED_BY_AC]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:New_Invoice_Registered'),
        description: t('text:Anchor_Company_relatedAnchorClientName_has_confirmed_the_invoice_registration_request', {
          relatedAnchorClientName,
        }),
      },
      {
        condition: role === ROLE.ROLE_ANCHOR,
        title: t('text:New_Invoice_Registered'),
        description: t(
          'text:authorityType_requestedUserName_has_confirmed_the_invoice_registration_request_for_the_Partner_relatedPartnerClientName',
          {
            authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
            relatedPartnerClientName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_REGISTRATION_REJECT_BY_FI]: () => [
      {
        condition: true,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_invoice_registration_request',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_FI]: () => [
      {
        condition: true,
        title: t('text:Invoice_Registration_Requested'),
        description: t('text:Operator_requestedUserName_has_requested_approval_for_an_invoice_registration_request', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_CONFIRMATION_REJECT_BY_FI]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_invoice_registration_request',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Invoice_Registration_Request_Rejected'),
        description: t(
          'text:authorityType_requestedUserName_has_rejected_the_invoice_registration_request_for_the_Partner_relatedPartnerName',
          {
            authorityType: t(`code:authority-type.${requestedUserAuthorityType}`),
            requestedUserName,
            relatedPartnerName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.INVOICE_CONFIRMED_BY_FI]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:New_Invoice_Registered'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_confirmed_the_invoice_registration_request',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:New_Invoice_Registered'),
        description: t(
          'text:Authorizer_requestedUserName_has_confirmed_the_invoice_registration_request_from_Operator_preRequestedUserName',
          {
            preRequestedUserName,
            requestedUserName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.AUTO_LOAN_REQUESTED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Automatic_Financing_Request'),
        description: t(
          'text:A_financing_request_has_been_submitted_automatically_to_the_Financier_relatedFinancierName_relatedBranchName',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Automatic_Financing_Request'),
        description: t('text:A_financing_request_has_been_submitted_automatically_for_the_Partner_relatedPartnerName', {
          relatedPartnerName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.AUTO_LOAN_APPROVED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Automatic_Financing_Confirmation'),
        description: t(
          'text:A_financing_request_to_Financier_relatedFinancierName_relatedBranchName_has_been_submitted_and_confirmed_automatically',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Automatic_Financing_Confirmation'),
        description: t(
          'text:A_financing_request_has_been_submitted_and_confirmed_automatically_for_the_Partner_relatedPartnerName',
          {
            relatedPartnerName,
          },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_DEALER_CREATED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Requested'),
        description: t('text:Operator_requestedUserName_has_Requested_approval_for_bulk_financing_request', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_DEALER_REQUESTED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Request_Submitted'),
        description: t('text:Authorizer_requestedUserName_has_submitted_bulk_financing_request_to_the_financier', {
          requestedUserName,
        }),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Requested'),
        description: t(
          'text:Partner_Company_relatedPartnerClientName_has_requested_approval_for_bulk_financing_request',
          { relatedPartnerClientName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_DEALER_CANCELLED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER && authorityType === AUTHORITY_TYPE.OPERATOR,
        title: t('text:Financing_Request_Cancelled'),
        description: t('text:Operator_requestedUserName_has_cancelled_the_bulk_financing_request', {
          requestedUserName,
        }),
      },
      {
        condition: role === ROLE.ROLE_DEALER && authorityType === AUTHORITY_TYPE.AUTHORIZER,
        title: t('text:Financing_Request_Cancelled'),
        description: t('text:Authorizer_requestedUserName_has_cancelled_the_bulk_financing_request', {
          requestedUserName,
        }),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Confirmed'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_confirmed_the_bulk_financing_request',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Confirmed'),
        description: t(
          'text:Authorizer_has_confirmed_the_bulk_financing_request_from_Partner_Company_relatedPartnerClientName',
          { relatedPartnerClientName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST]: () => [
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Approval_Requested'),
        description: t(
          'text:Operator_requestedUserName_has_requested_Authorizer_to_approve_the_bulk_financing_request_from_Partner_Company_relatedPartnerClientName',
          { requestedUserName, relatedPartnerClientName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTED]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Rejected'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_bulk_financing_request',
          {
            relatedFinancierName,
            relatedBranchName,
          },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Request_Rejected'),
        description: t(
          'text:Operator_requestedUserName_has_rejected_the_bulk_financing_request_from_Partner_Company_relatedPartnerClientName',
          { requestedUserName, relatedPartnerClientName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST_CANCEL]: () => [
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Approval_Request_Cancelled'),
        description: t(
          'text:Operator_requestedUserName_has_cancelled_the_approval_request_for_relatedPartnerName_s_bulk_financing_request',
          { requestedUserName, relatedPartnerName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN]: () => [
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Request_Reverted'),
        description: t(
          'text:Authorizer_requestedUserName_has_reverted_the_bulk_financing_request_from_Partner_Company_relatedPartnerClientName',
          { requestedUserName, relatedPartnerClientName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST]: () => [
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Rejection_Requested'),
        description: t(
          'text:Operator_requestedUserName_has_requested_Authorizer_to_cancel_the_approval_and_reject_the_financing_request_from_Partner_Company_relatedPartnerClientName',
          { requestedUserName, relatedPartnerClientName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST_RETURNED]: () => [
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Rejection_Request_Reverted'),
        description: t(
          'text:Authorizer_requestedUserName_has_reverted_the_request_to_cancel_the_approval_for_financing_request_from_Partner_Company_relatedPartnerClientName',
          { requestedUserName, relatedPartnerClientName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST_CANCEL]: () => [
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Rejection_Request_Cancelled'),
        description: t(
          'text:Operator_requestedUserName_has_cancelled_the_financing_approval_rejection_for_relatedPartnerClientName_s_financing_request',
          { requestedUserName, relatedPartnerClientName },
        ),
      },
    ],
    [NOTIFICATION_TYPE.MULTIPLE_LOAN_REJECTION_REQUEST_CONFIRM]: () => [
      {
        condition: role === ROLE.ROLE_DEALER,
        title: t('text:Financing_Rejected'),
        description: t(
          'text:Financier_relatedFinancierName_relatedBranchName_has_rejected_the_bulk_financing_request',
          { relatedFinancierName, relatedBranchName },
        ),
      },
      {
        condition: role === ROLE.ROLE_FINANCIER,
        title: t('text:Financing_Rejected'),
        description: t(
          'text:Authorizer_requestedUserName_has_approved_the_request_to_cancel_the_financing_approval_rejecting_the_financing_request_from_Partner_Company_relatedPartnerClientName',
          { requestedUserName, relatedPartnerClientName },
        ),
      },
    ],
  };

  if (role === ROLE.ROLE_SYSTEM) return;

  return contents[notificationType];
};
