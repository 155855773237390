import type Pageable from 'models/Pageable';
import type { WaitingInvoiceVO, WaitingInvoiceVOModel } from 'models/vo/WaitingInvoiceVO';
import { formattingToWaitingInvoiceVOModel } from 'models/vo/WaitingInvoiceVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

export async function requestFinancierWaitingInvoiceList(
  pageNumber: number,
  rowCount: number,
  waitingInvoiceApprovalPhaseId: number,
): Promise<Pageable<WaitingInvoiceVOModel[]>> {
  const response = await http.get<Pageable<WaitingInvoiceVO[]>>({
    url: API_FI.WAITING_INVOICES.WAITING_INVOICE_LIST,
    data: {
      pageNumber,
      rowCount,
      waitingInvoiceApprovalPhaseId: waitingInvoiceApprovalPhaseId,
    },
  });

  const waitingInvoiceData = response.content.map(waitingInvoice => formattingToWaitingInvoiceVOModel(waitingInvoice));

  return {
    ...response,
    content: waitingInvoiceData,
  };
}
