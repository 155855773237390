import {
  CURRENCY_TYPE,
  FINANCIER_TYPE,
  LOAN_STATUS,
  SETTLEMENT_METHOD_TYPE,
  SUCCESS_INVOICE_STATUS,
  stringToEnum,
} from 'enums';
import { isLoanDisbursed } from 'utils/status';

import type { InvoiceAdditionalDataVO } from './InvoiceAdditionalDataVO';

export interface SuccessInvoiceVO {
  successInvoiceId: number;
  invoiceNumber: string;
  referenceNumber: string;
  invoiceIssuedDate: string;
  settlementDate: string;
  invoiceAmount: number;
  currencyType: string;
  successInvoiceStatus: string;
  blockedReason: string;
  blockedDateTime: string;
  blockedByFinancier: boolean;
  collateralized: boolean;
  financierEnterpriseId: number;
  financierName: string;
  financierCode: string;
  dealerEnterpriseId: number;
  dealerEnterpriseName: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerClientCode: string;
  dealerClientBusinessCode: string;
  dealerCodeByAnchor: string;
  anchorEnterpriseId: number;
  anchorEnterpriseName: string;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  anchorClientBusinessCode: string;
  dealerAgreementId: number;
  createdDateTime: string;
  dealerBusinessCode: string;
  dealerTaxCode: string;
  dealerClientTaxCode: string;
  anchorBusinessCode: string;
  anchorTaxCode: string;
  anchorClientTaxCode: string;
  loanId: number;
  loanStatus: string;
  billOfExchangeNo: string;
  invoiceAdditionalDataList: InvoiceAdditionalDataVO[];
  settlementMethod: string;
  settlementOtherMethodReason: string;
}

export interface SuccessInvoiceVOModel extends SuccessInvoiceVO {
  currencyType: CURRENCY_TYPE;
  successInvoiceStatus: SUCCESS_INVOICE_STATUS;
  financierCode: FINANCIER_TYPE;
  loanStatus: LOAN_STATUS;
  settlementMethod: SETTLEMENT_METHOD_TYPE;
  // only front
  paid: boolean;
}

export function formattingToSuccessInvoiceVOModel(data: SuccessInvoiceVO): SuccessInvoiceVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    successInvoiceStatus: stringToEnum(SUCCESS_INVOICE_STATUS, data.successInvoiceStatus),
    financierCode: stringToEnum(FINANCIER_TYPE, data.financierCode),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
    settlementMethod: stringToEnum(SETTLEMENT_METHOD_TYPE, data.settlementMethod),
    // only front
    paid: isLoanDisbursed(stringToEnum(LOAN_STATUS, data.loanStatus)),
  };
}
