import type { ChangeEvent, ReactNode } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

export interface FormRadioPropsType {
  label?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  col?: number;
  value?: string;
  defaultChecked?: boolean | null;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  className?: string;
  id?: string;
}

const FormRadio = forwardRef(
  (
    {
      name = '',
      label,
      value = '',
      defaultChecked,
      disabled = false,
      onChange = () => {},
      children,
      id,
    }: FormRadioPropsType,
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
      if (inputRef.current) {
        if (defaultChecked !== undefined) inputRef.current.checked = !!defaultChecked;
      }
    }, [defaultChecked]);

    return (
      <div className="information-form__radio--wrap">
        <div className="information-form__radio form-check">
          <input
            id={id ?? `${name}-${label}`}
            type="radio"
            className="form-check-input"
            name={name}
            onChange={onChange}
            disabled={disabled}
            defaultChecked={defaultChecked === undefined ? undefined : !!defaultChecked}
            value={value}
            ref={inputRef}
          />
          <label className="form-check-label" htmlFor={id ?? `${name}-${label}`}>
            {label}
          </label>
          {children && <div className="information-form__radio-group">{children}</div>}
        </div>
      </div>
    );
  },
);

FormRadio.displayName = 'FormRadio';
export default FormRadio;
