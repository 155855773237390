import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faCheckCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { DAY_OF_WEEK, FINANCIER_INTERFACE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestInsertHolidayByDayOfWeek,
  requestInsertHolidayByInterface,
  requestInsertHolidayBySpecificDates,
} from 'utils/http/api/financier/financier-calendar';
import type {
  FinancierInsertHolidayDayOfWeekRequest,
  FinancierInsertHolidaySpecificDatesRequest,
} from 'utils/http/api/financier/financier-calendar/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType } from 'utils/storage/LocalStorage';
import useValidation from 'utils/validation/useValidation';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function FinancierHolidaysRegister() {
  const { t } = useTranslation(['format']);

  const [useInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.BUSINESS_DATE)); // 전문 연동
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();

  const useFormDayOfWeek = useForm<FinancierInsertHolidayDayOfWeekRequest>();
  const useFormInterface = useForm<{ fromDate: string; toDate: string }>();

  const useFormSpecificDates = useForm<FinancierInsertHolidaySpecificDatesRequest>(); // use grid
  const { fields, append, remove } = useFieldArray({
    control: useFormSpecificDates.control,
    name: 'holidayItemList',
  });

  const { getValidationClassName } = useValidation({
    errorFields: useFormSpecificDates.errors.holidayItemList,
  });

  useEffect(() => {
    if (mounted) {
      useFormSpecificDates.reset({
        holidayItemList: [
          {
            date: undefined,
            description: undefined,
          },
        ],
      });
    }
  }, [mounted]);

  const checkBoxOptions = [
    DAY_OF_WEEK.MON,
    DAY_OF_WEEK.TUE,
    DAY_OF_WEEK.WED,
    DAY_OF_WEEK.THU,
    DAY_OF_WEEK.FRI,
    DAY_OF_WEEK.SAT,
    DAY_OF_WEEK.SUN,
  ];

  const appendRow = (e: any) => {
    e.preventDefault();
    append({
      date: undefined,
      description: undefined,
    });
  };

  const removeRow = (e: any) => {
    e.preventDefault();
    if (checkedRows.length === fields.length) {
      useFormSpecificDates.reset({
        holidayItemList: [
          {
            date: undefined,
            description: undefined,
          },
        ],
      });
    } else {
      remove(checkedRows);
    }

    setCheckedRows([]);
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const arr: number[] = [];
      fields.forEach((el, index) => arr.push(index));
      setCheckedRows(arr);
    } else {
      setCheckedRows([]);
    }
  };

  const handleCheckChange = (e: any, index: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const renderHandWritingForm = () => {
    const renderByDayOfWeek = () => {
      return (
        <div className="content-area">
          <FormBorder>
            <FormContents>
              <div className="row">
                <div className="col-12">
                  <div className="common-checkbox-wrap">
                    <div className="checkbox-form">
                      {checkBoxOptions?.map((value, index) => {
                        return (
                          <div className="form-check-inline" key={index}>
                            <div className="d-flex align-items-center">
                              <input
                                className="form-check-input m-0"
                                type="checkbox"
                                value={value}
                                id={`flexCheckDefault${index}`}
                                name="dayOfWeekList"
                                ref={useFormDayOfWeek.register}
                              />
                              <label className="ms-1" htmlFor={`flexCheckDefault${index}`}>
                                {t(checkBoxOptions[index])}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                      {useFormDayOfWeek.errors.dayOfWeekList && (
                        <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <FormDatePickerInput
                  placeholder={t('text:from')}
                  label={t('text:Start_Date')}
                  name="fromDate"
                  control={useFormDayOfWeek.control}
                  error={useFormDayOfWeek.errors.fromDate}
                />
                <FormDatePickerInput
                  placeholder={t('text:to')}
                  label={t('text:End_Date')}
                  name="toDate"
                  control={useFormDayOfWeek.control}
                  error={useFormDayOfWeek.errors.toDate}
                />
              </div>
            </FormContents>
          </FormBorder>
          <div className="flex-end mt-3">
            <Button size={ButtonSizeEnum.LG} onClick={showRegisterModalByDayOfWeek}>
              {t('text:Register')}
            </Button>
          </div>
        </div>
      );
    };
    const renderBySpecificDate = () => {
      return (
        <div className="content-area">
          <div className="flex-end mb-4">
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={removeRow}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>

          <table className="table-border">
            <colgroup>
              <col style={{ width: '70px' }} />
              <col />
              <col style={{ width: '60%' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <div className="text-center">
                    <input
                      className="form-check-input m-0"
                      type="checkbox"
                      value=""
                      id="allCheck2"
                      onChange={handleCheckAll}
                      checked={checkedRows.length === fields.length}
                    />
                  </div>
                </th>
                <th scope="col">{t('text:Date')}</th>
                <th scope="col">{t('text:Description')}</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {fields.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td className="bg-sub100" key={index}>
                      <div className="text-center">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          onChange={e => handleCheckChange(e, index)}
                          checked={checkedRows.includes(index)}
                        />
                      </div>
                    </td>
                    <td className={getValidationClassName('date', 'td', index)}>
                      <div className="position-parent">
                        <Controller
                          control={useFormSpecificDates.control}
                          name={`holidayItemList.${index}.date`}
                          defaultValue={item.invoiceIssuedDate}
                          render={field => (
                            <ReactDatePicker field={field} className={getValidationClassName('date', 'input', index)} />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </td>
                    <td className={getValidationClassName('description', 'td', index)}>
                      <input
                        className={getValidationClassName('description', 'input', index)}
                        name={`holidayItemList.${index}.description`}
                        defaultValue={item.invoiceNumber}
                        ref={useFormSpecificDates.register({
                          required: true,
                        })}
                        maxLength={500}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex-end mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={showRegisterModalBySpecificDate}>
              {t('text:Register')}
            </Button>
          </div>
        </div>
      );
    };

    return (
      <Tab
        tabList={[
          {
            tabName: t('text:By_Day_of_the_Week'),
          },
          {
            tabName: t('text:By_Specific_Day'),
          },
        ]}
        tabViewList={[renderByDayOfWeek(), renderBySpecificDate()]}
      />
    );
  };

  const renderInterfaceForm = () => {
    return (
      <>
        <GuideMessage
          message={[
            t('text:Enter_the_date_range_and_click_on_the_Register_button'),
            t('text:The_maximum_date_range_that_can_be_selected_3_months'),
            t(
              'text:If_the_selected_date_range_overlaps_with_holidays_that_have_already_been_registered,_the_overlapping_period_will_be_updated_with_the_newly_retrieved_information',
            ),
          ]}
        />
        <div className="content-area">
          <FormBorder>
            <FormContents>
              <div className="row">
                <FormDatePickerInput
                  placeholder={t('text:from')}
                  label={t('text:Date_Range')}
                  name="fromDate"
                  control={useFormInterface.control}
                  error={useFormInterface.errors.fromDate}
                />
                <FormDatePickerInput
                  placeholder={t('text:to')}
                  label=""
                  name="toDate"
                  control={useFormInterface.control}
                  disabled={!useFormInterface.watch('fromDate')}
                  minDate={dayjs(useFormInterface.watch('fromDate')).toDate()}
                  maxDate={dayjs(useFormInterface.watch('fromDate')).add(90, 'days').toDate()}
                  error={useFormInterface.errors.toDate}
                />
              </div>
            </FormContents>
          </FormBorder>
          <div className="flex-end mt-3">
            <Button size={ButtonSizeEnum.LG} onClick={showRegisterModalInterface}>
              {t('text:Register')}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const showRegisterCompletedModal = () => {
    modal.show(
      <h6>
        {t('text:The_holiday_has_been_registered')}
        <br />
        {t('text:The_registered_information_can_be_seen_in_the_holidays_list')}
      </h6>,
      {
        modalType: ModalType.ALERT,
        closeBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.HOLIDAYS_LIST),
      },
    );
  };
  const showRegisterModalByDayOfWeek = (e: any) => {
    e.preventDefault();

    const requestRegisterHolidays = async () => {
      const data = useFormDayOfWeek.getValues();
      try {
        requestDTOParser(data);

        await requestInsertHolidayByDayOfWeek(data);
        showRegisterCompletedModal();
      } catch (e) {
        modal.show(e);

        formErrorHandler<FinancierInsertHolidayDayOfWeekRequest>(
          e,
          useFormDayOfWeek.setError,
          useFormDayOfWeek.clearErrors,
        );
      }
    };
    modal.show(<h6>{t('text:Would_you_like_to_register_the_holiday?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => requestRegisterHolidays(),
    });
  };

  const showRegisterModalBySpecificDate = (e: any) => {
    e.preventDefault();

    modal.show(<h6>{t('text:Would_you_like_to_register_the_holiday?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => onSubmitSpecificDates(),
    });

    const onSubmitSpecificDates = async () => {
      const data = useFormSpecificDates.getValues();
      try {
        requestDTOParser(data);

        await requestInsertHolidayBySpecificDates(data);
        showRegisterCompletedModal();
      } catch (e) {
        modal.show(e);

        formErrorHandler<FinancierInsertHolidaySpecificDatesRequest>(
          e,
          useFormSpecificDates.setError,
          useFormSpecificDates.clearErrors,
        );
      }
    };
  };

  const showRegisterModalInterface = (e: any) => {
    e.preventDefault();

    const requestRegisterHolidaysInterface = async () => {
      const data = useFormInterface.getValues();

      try {
        requestDTOParser(data);

        await requestInsertHolidayByInterface(data.fromDate, data.toDate);
        showRegisterCompletedModal();
      } catch (e) {
        modal.show(e);

        formErrorHandler(e, useFormInterface.setError, useFormInterface.clearErrors);
      }
    };
    modal.show(<h6>{t('text:Would_you_like_to_register_the_holiday?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => requestRegisterHolidaysInterface(),
    });
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Register_Holiday(s)')} />
      {useInterface ? renderInterfaceForm() : renderHandWritingForm()}
    </>
  );
}

export default FinancierHolidaysRegister;
