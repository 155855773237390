import { AUTHORITY_TYPE, USER_STATUS, stringToEnum } from 'enums';

export interface UserVO {
  userId: number;
  loginId: string;
  email: string;
  employeeCode: string;
  userName: string;
  department: string;
  position: string;
  telephone: string;
  mobile: string;
  userStatus: string;
  authorityType: string;
  createDateTime: string;
  signUpDateTime: string;
  recentSignInDateTime: string;
  recentChangeStatusDateTime: string;
  enterpriseId: number;
  enterpriseName: string;
  enterpriseBusinessCode: string;
  enterpriseTaxCode: string;
  enterpriseAddress: string;
  enterpriseTelephone: string;
  branchId: number;
  branchName: string;
  branchCode: string;
}

export interface UserVOModel extends UserVO {
  userStatus: USER_STATUS;
  authorityType: AUTHORITY_TYPE;
}

export function formattingToUserVOModel(data: UserVO): UserVOModel {
  return {
    ...data,
    userStatus: stringToEnum(USER_STATUS, data.userStatus),
    authorityType: stringToEnum(AUTHORITY_TYPE, data.authorityType),
  };
}
