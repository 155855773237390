import { PARTNER_STATUS, stringToEnum } from 'enums';

export interface AnchorPartnerVO {
  anchorPartnerId: number;
  partnerBusinessCode: string;
  partnerTaxCode: string;
  anchorPartnerCode: string;
  name: string;
  address: string;
  telephone: string;
  fax: string;
  representativeName: string;
  representativeEmail: string;
  representativePosition: string;
  anchorPartnerStatus: string;
  anchorId: number;
  partnerId: number;
  partnerName: string;
  anchorName: string;
  createdDateTime: string;
}

export interface AnchorPartnerVOModel extends AnchorPartnerVO {
  anchorPartnerStatus: PARTNER_STATUS;
}

export function formattingToAnchorPartnerVOModel(data: AnchorPartnerVO): AnchorPartnerVOModel {
  return {
    ...data,
    anchorPartnerStatus: stringToEnum(PARTNER_STATUS, data.anchorPartnerStatus),
  };
}

export interface AnchorArPartnerNameVO {
  partnerName: string;
  taxCode: string;
}
