import './SearchForm.scss';

interface PropsType {
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
}

function SearchBorder({ children }: PropsType) {
  return <div className="search-border">{children}</div>;
}

export default SearchBorder;
