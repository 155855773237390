import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVO, AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { formattingToAnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemAnchorAgreementListRequest } from './requests';

export async function requestSystemAnchorAgreementList(
  pageNumber: number,
  rowCount: number,
  data: SystemAnchorAgreementListRequest,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_SY.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToAnchorAgreementVOModel(item)),
  };
}

export async function requestSystemAnchorAgreementData(
  anchorAgreementId: number,
): Promise<AnchorAgreementDetailVOModel> {
  const response = await http.get<AnchorAgreementDetailVO>({
    url: API_SY.ANCHOR_AGREEMENTS.ANCHOR_AGREEMENT_DETAIL(anchorAgreementId),
  });

  return formattingToAnchorAgreementDetailVOModel(response);
}
