import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import {
  FormBorder,
  FormContents,
  FormDatePickerInput,
  FormInput,
  FormSelect,
  FormValue,
  NumericFormatInput,
} from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { BASIS_INTEREST_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFinancierBasisInterestRegister,
  requestFinancierBasisInterestRegisterByInterface,
} from 'utils/http/api/financier/basis-interests';
import type {
  FinancierBasisInterestRegisterByInterfaceRequest,
  FinancierBasisInterestRegisterRequest,
} from 'utils/http/api/financier/basis-interests/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function FinancierInterestRateFeeRegister() {
  const { t } = useTranslation(['format']);
  const history = useHistory();
  const { show: showModal } = useModal();
  const date = new Date();
  const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];

  const effectiveDateStartDay = new Date(today[0] - 20, today[1], today[2]);
  const effectiveDateEndDay = new Date(today[0], today[1], today[2]);

  const isBasisInterestRateInterface = checkInterfaceType(FINANCIER_INTERFACE_TYPE.BASIS_INTEREST_RATE);

  const { register, getValues, errors, setError, clearErrors, control } = useForm<
    FinancierBasisInterestRegisterRequest & FinancierBasisInterestRegisterByInterfaceRequest
  >({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const handleRegisterButtonClick = () => {
    showModal(
      <h6>
        {t('text:Would_you_like_to_register_the_base_interest_rate_information?')}
        {!isBasisInterestRateInterface && (
          <>
            <br />
            {t(
              'text:Once_registered_the_base_interest_rate_can_only_be_modified_by_deleting_the_previous_data_and_registering_it_again',
            )}
          </>
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          try {
            const data = getValues();

            data.basisInterestType = BASIS_INTEREST_TYPE.CUSTOM;
            requestDTOParser(data);

            isBasisInterestRateInterface
              ? await requestFinancierBasisInterestRegisterByInterface(data)
              : await requestFinancierBasisInterestRegister(data);

            showModal(<h6>{t('text:The_base_interest_rate_information_has_been_registered_successfully')}</h6>, {
              closeBtnText: t('text:OK'),
              closeBtnCb: () => {
                history.push(ROUTES_FI.PROGRAM_SETTINGS.INTEREST_RATE_FEE_LIST);
              },
            });
          } catch (error) {
            showModal(error);
            isBasisInterestRateInterface
              ? formErrorHandler<FinancierBasisInterestRegisterByInterfaceRequest>(error, setError, clearErrors)
              : formErrorHandler<FinancierBasisInterestRegisterRequest>(error, setError, clearErrors);
          }
        },
      },
    );
  };

  const onClickCancel = (e: any) => {
    e.preventDefault();

    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_base_interest_rate_registration?')}
        <br />
        {t('text:If_you_stop_registering,_the_entered_information_will_not_be_saved')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.INTEREST_RATE_FEE_LIST),
      },
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Register_Base_Interest_Information')} />
      {isBasisInterestRateInterface && (
        <GuideMessage
          message={[
            `${t('text:Enter_the_base_interest_rate_information_below')} ${(
              <Trans
                key="key"
                i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
                components={{ 1: <span className="asterisk" /> }}
              />
            )},
            )}`,
            t(
              'text:The_entered_information_must_correspond_with_the_financiers_internal_base_interest_rate_information',
            ),
          ]}
        />
      )}
      <div className="content-area">
        <FormBorder editable={true}>
          <FormContents>
            <div className="row">
              <FormDatePickerInput
                label={t('text:Effective_Date')}
                minDate={effectiveDateStartDay}
                maxDate={effectiveDateEndDay}
                name="basisInterestDate"
                control={control}
                required
                error={errors.basisInterestDate}
              />
              <FormSelect
                placeholderOptions={{ show: true }}
                label={t('text:Currency')}
                name="currencyType"
                error={errors.currencyType}
                ref={register}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
                required
              />
            </div>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Base_Interest_Type')}
                className="information-form__input"
                value={t('text:Variable')}
              />
              <FormInput
                col={3}
                label=""
                name="basisInterestBankCode"
                ref={register}
                error={errors.basisInterestBankCode}
              />
              <FormInput
                col={isBasisInterestRateInterface ? 6 : 3}
                label={t('text:Base_Interest_Term')}
                name="basisInterestTerm"
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.basisInterestTerm}
              />
              {!isBasisInterestRateInterface && (
                <NumericFormatInput
                  control={control}
                  numberType="float"
                  name="basisInterestRate"
                  label={`${t('text:Base_Interest_Rate')} (%)`}
                  col={3}
                  required
                  error={errors.basisInterestRate}
                />
              )}
            </div>
          </FormContents>
        </FormBorder>
        <div className="flex-end mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={onClickCancel}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} className="ms-2" onClick={handleRegisterButtonClick}>
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default FinancierInterestRateFeeRegister;
