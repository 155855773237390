import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { MultipleLoanRequestDetailVO } from 'models/vo/MultipleLoanRequestDetailVO';

interface DealerBulkFinancingDetailDesignatedBankAccountInformationProps {
  bulkFinancingDetailInfo: MultipleLoanRequestDetailVO;
  isAuthorizerRequest: boolean;
  bankAccountInfoCheckBoxState: {
    bankAccountInfoCheck: boolean;
    setBankAccountInfoCheck: Dispatch<SetStateAction<boolean>>;
  };
}

function DealerBulkFinancingDetailDesignatedBankAccountInformation({
  bulkFinancingDetailInfo,
  isAuthorizerRequest,
  bankAccountInfoCheckBoxState,
}: DealerBulkFinancingDetailDesignatedBankAccountInformationProps) {
  const { t } = useTranslation(['format']);
  const { bankAccountInfoCheck, setBankAccountInfoCheck } = bankAccountInfoCheckBoxState;

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Designated_Bank_Account_Information')} />
      <FormBorder hideBorderBottom={isAuthorizerRequest}>
        <FormSubtitle title={t('text:DISBURSEMENT_ACCOUNT')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Bank_Name')} value={bulkFinancingDetailInfo.settlementAccountFinancierName} />
            <FormValue label={t('text:Bank_Branch_Name')} value={bulkFinancingDetailInfo.settlementAccountBranchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Account_Number')} value={bulkFinancingDetailInfo.settlementAccount} />
            <FormValue label={t('text:Account_Holder_Name')} value={bulkFinancingDetailInfo.settlementAccountOwner} />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:REPAYMENT_ACCOUNT')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              label={t('text:Bank_Name')}
              value={bulkFinancingDetailInfo.principalRepaymentAccountFinancierName}
            />
            <FormValue
              label={t('text:Bank_Branch_Name')}
              value={bulkFinancingDetailInfo.principalRepaymentAccountBranchName}
            />
          </div>
          <div className="row">
            <FormValue label={t('text:Account_Number')} value={bulkFinancingDetailInfo.principalRepaymentAccount} />
            <FormValue
              label={t('text:Account_Holder_Name')}
              value={bulkFinancingDetailInfo.principalRepaymentAccountOwner}
            />
          </div>
        </FormContents>
      </FormBorder>

      {isAuthorizerRequest && (
        <ConfirmCheckBox
          checked={bankAccountInfoCheck}
          id="account-info-check"
          labelText={t('text:I_have_checked_the_information_of_the_designated_bank_accounts')}
          onChangeCheckBox={() => {
            setBankAccountInfoCheck(prevState => !prevState);
          }}
        />
      )}
    </div>
  );
}
export default DealerBulkFinancingDetailDesignatedBankAccountInformation;
