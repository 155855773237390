import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import { Cell, Content, Row, SubTitle } from 'components/templates/section';

const InvoiceRegistration = () => {
  const { t } = useTranslation(['format']);

  return (
    <>
      <SubTitle>{t('text:INVOICE_REGISTRATION')}</SubTitle>
      <Content>
        <Row>
          <Form.Control name="settlementDateOverAllowable">
            <Cell>
              <Form.Label position="top">{t('text:Invoice_Payment_Date')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Indicates_whether_invoices_with_payment_dates_after_the_agreement_expiration_date_can_be_registered_on_the_platform',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio defaultValue="false">
              <Form.Option value="true" label={t('text:Can_exceed_agreement_expiration_date')} />
              <Form.Option
                value="false"
                label={`${t('text:Must_be_before_agreement_expiration')} (${t('text:Default')})`}
              />
            </Form.Radio>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="referenceNumberDuplicationCheck">
            <Cell>
              <Form.Label position="top">{t('text:Invoice_Reference_Number')}</Form.Label>
              <Form.Description>
                {t('text:If_the_reference_number_is_mandatory_it_must_always_be_unique')}
              </Form.Description>
            </Cell>
            <Form.Radio defaultValue="true">
              <Form.Option value="true" label={`${t('text:Mandatory')} (${t('text:Default')})`} />
              <Form.Option value="false" label={t('text:Optional')} />
            </Form.Radio>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="billOfExchangeNoRequired">
            <Cell>
              <Form.Label position="top">{t('text:BOE_Number')}</Form.Label>
              <Form.Description>{t('text:If_the_BOE_number_is_mandatory_it_must_always_be_unique')}</Form.Description>
            </Cell>
            <Form.Radio defaultValue="false">
              <Form.Option value="true" label={t('text:Mandatory')} />
              <Form.Option value="false" label={`${t('text:Optional')} (${t('text:Default')})`} />
            </Form.Radio>
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default InvoiceRegistration;
