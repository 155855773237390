import type { DependencyList } from 'react';
import { useCallback, useEffect } from 'react';

const useBeforeUnload = (condition: boolean, deps: DependencyList) => {
  const handleBeforeunload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (condition) {
        e.preventDefault();
        e.returnValue = '';

        return '';
      }

      return undefined;
    },
    [condition],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, [handleBeforeunload, deps]);
};

export default useBeforeUnload;
