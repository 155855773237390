import { memo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { debounce, isEmpty, values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import Value from 'components/stateless/Form/Value/Value';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import type { LastArPhaseVOModel } from 'models/vo/LastArPhaseVO';
import {
  requestFinancierApproveArPhase,
  requestFinancierRejectArRegistration,
} from 'utils/http/api/financier/ar-summaries';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { invoiceOrArAmountInputValidate, invoiceOrArSettlementDateInputValidate } from 'utils/logic';
import useARInputTableController from 'utils/logic/useARInputTableController';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import useValidation from 'utils/validation/useValidation';

import ExcelForm from './ExcelForm';
import FinancierARRequestModal from './FinancierARRequestModal';

interface FinancierArInputTableProps {
  arSummaryDetail: ArSummaryVOModel | undefined;
  arSummaryLastPhaseData: LastArPhaseVOModel | undefined;
  partnerInfo: { partnerName: string; partnerTaxCode: string } | undefined;
  buttonActionCb: () => Promise<void>;
}

function FinancierArInputTable({
  arSummaryDetail,
  arSummaryLastPhaseData,
  partnerInfo,
  buttonActionCb,
}: FinancierArInputTableProps) {
  const date = new Date();
  const today = [date.getFullYear(), date.getMonth(), date.getDate()];
  const { t } = useTranslation();
  const { show: showModal, id: modalId } = useModal();

  const {
    apRegistrationUseForm,
    apNumberDuplicateValidator,
    getFieldNames,
    removeRow,
    appendRow,
    handleCheckAll,
    handleCheckChange,
    checkedRows,
    checkedValidation,
    setCheckedValidation,
    totalApAmount,
    updateTotalApAmount,
    settlementDatePeriod,
    holidays,
    fields,
  } = useARInputTableController(arSummaryDetail?.financierEnterpriseId!, arSummaryLastPhaseData);

  const { getValues, register, control, formState, trigger, reset } = apRegistrationUseForm;

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: formState.errors.arList,
    rule: 'arList',
    accountType: 'AR',
  });

  const fieldNames = getFieldNames(t, { currencyType: arSummaryDetail?.currencyType });

  const handleClickReject = () => {
    let reason = '';
    showModal(
      <>
        <h6>
          {t('text:Would_you_like_to_reject_the_registration_request?')}
          <br />
          {t('text:If_you_reject_the_registration_request_you_will_have_to_proceed_with_the_AR_registration_again')}
          <br />
          {t('text:Please_enter_the_reason_for_the_rejection_below')}
        </h6>
        <ReasonModal
          modalId={modalId}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => requestReject(),
      },
    );

    const requestReject = async (): Promise<void> => {
      try {
        await requestFinancierRejectArRegistration(Number(arSummaryDetail?.arSummaryId), reason);

        showModal(<h6>{t('text:The_registration_request_has_been_rejected')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: buttonActionCb,
        });
      } catch (error) {
        showModal(error);
      }
    };
  };

  const handleClickRegister = async () => {
    const data = getValues();

    const onClickConfirm = async (): Promise<void> => {
      data.arSummaryId = Number(arSummaryDetail?.arSummaryId);

      try {
        await requestFinancierApproveArPhase(data);
        showModal(<h6>{t('text:The_AR_confirmation_request_has_been_completed_and_is_in_progress')}</h6>, {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: buttonActionCb,
        });
      } catch (error) {
        showModal(error);
      }
    };

    const executeRegister = () => {
      return new Promise<void>(async resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1000);
      }).then(async () => {
        await trigger().then(result => {
          setCheckedValidation(data.arList.map(() => true));
          unShowLoadingUI();

          if (!result || !isEmpty(formState.errors)) {
            showModal(
              <>
                <h6>
                  {t('text:The_list_of_AR_to_upload_contains_errors')}
                  <br />
                  {t('text:Please_check_the_Registration_Result_and_continue_registration_again')}
                </h6>
              </>,
              {
                modalSize: ModalSize.NONE,
                modalType: ModalType.ALERT,
                closeBtnText: t('text:Close'),
              },
            );

            return; // FE validation error 있으면 return
          } else {
            showModal(
              <FinancierARRequestModal
                dataList={data.arList}
                totalCount={data.arList.length}
                totalAmount={totalApAmount}
                currencyType={arSummaryDetail?.currencyType}
                partnerName={partnerInfo?.partnerName!}
              />,
              {
                modalSize: ModalSize.XL,
                modalType: ModalType.CONFIRM,
                closeBtnText: t('text:Cancel'),
                confirmBtnCb: onClickConfirm,
              },
            );
          }
        });
      });
    };
    await executeRegister();
  };

  return (
    <>
      <ExcelForm
        getValues={getValues}
        reset={reset}
        trigger={trigger}
        updateTotalApAmount={updateTotalApAmount}
        setCheckedValidation={setCheckedValidation}
      />
      <form>
        <div className={clsx('mb-3 flex-end')}>
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={removeRow}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
          <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>

        <div className="table-overflow-scroll" style={{ maxHeight: '1000px' }}>
          <table className="table-border">
            <colgroup>
              <col style={{ width: '70px' }} />
              {values(fieldNames).map((_, index) => (
                <col key={index} />
              ))}
              {checkedValidation.length > 0 && <col />}
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <div className="text-center">
                    <input
                      className="form-check-input m-0"
                      type="checkbox"
                      value=""
                      id="allCheck"
                      onChange={handleCheckAll}
                      checked={checkedRows.length === fields.length}
                    />
                  </div>
                </th>
                {values(fieldNames).map(({ name }, index) => (
                  <th key={index} scope="col">
                    {name}
                  </th>
                ))}
                {checkedValidation.length > 0 && <th scope="col">{t('text:Validation_Result')}</th>}
              </tr>
            </thead>
            <tbody className="bg-white">
              {fields.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td className="bg-sub100" key={index}>
                      <div className="text-center">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          onChange={e => handleCheckChange(e, index)}
                          checked={checkedRows.includes(index)}
                        />
                      </div>
                    </td>
                    <td>
                      <Value value={partnerInfo?.partnerName} />
                    </td>
                    <td>
                      <input
                        className="bg-white"
                        name={`arList.${index}.partnerTaxCode`}
                        defaultValue={partnerInfo?.partnerTaxCode}
                        ref={register()}
                        disabled
                      />
                    </td>
                    <td className={getValidationClassName('arNumber', 'td', index)}>
                      <input
                        className={getValidationClassName('arNumber', 'input', index)}
                        name={`arList.${index}.arNumber`}
                        defaultValue={item.arNumber}
                        ref={register({
                          required: 'text:Fail_Missing_item(s)',
                          validate: value => apNumberDuplicateValidator(value, index),
                          maxLength: {
                            value: 20,
                            message: 'text:Fail_The_AR_Number_must_be_between_1_and_20_characters',
                          },
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('arIssuedDate', 'td', index)}>
                      <Controller
                        control={control}
                        defaultValue={item.arIssuedDate}
                        name={`arList.${index}.arIssuedDate`}
                        render={field => (
                          <ReactDatePicker
                            field={field}
                            className={getValidationClassName('arIssuedDate', 'input', index)}
                          />
                        )}
                        rules={{
                          required: 'text:Fail_Missing_item(s)',
                        }}
                      />
                    </td>
                    <td className={getValidationClassName('settlementDate', 'td', index)}>
                      <Controller
                        control={control}
                        name={`arList.${index}.settlementDate`}
                        defaultValue={item.settlementDate}
                        render={field => {
                          return (
                            <ReactDatePicker
                              field={field}
                              className={getValidationClassName('settlementDate', 'input', index)}
                              minDate={settlementDatePeriod.minimumDate}
                              maxDate={settlementDatePeriod.maximumDate}
                              excludeDates={holidays.map(item => new Date(item))}
                            />
                          );
                        }}
                        rules={{
                          required: 'text:Fail_Missing_item(s)',
                          validate: () =>
                            invoiceOrArSettlementDateInputValidate(
                              today,
                              getValues(`arList.${index}.settlementDate`),
                              holidays,
                              settlementDatePeriod.minimumDate,
                              settlementDatePeriod.maximumDate,
                            ),
                        }}
                      />
                    </td>
                    <td className={getValidationClassName('arAmount', 'td', index)}>
                      <input
                        type="text"
                        className={getValidationClassName('arAmount', 'input', index) + ' text-end'}
                        name={`arList.${index}.arAmount`}
                        defaultValue={item.arAmount}
                        ref={register({
                          validate: value => invoiceOrArAmountInputValidate(value, arSummaryDetail?.currencyType, 'AP'),
                          required: 'text:Fail_Missing_item(s)',
                          min: { value: 0.01, message: 'text:Fail_AR_Amount_must_exceed_0' },
                        })}
                        onChange={debounce(updateTotalApAmount, 300)}
                      />
                    </td>
                    {checkedValidation[index] ? getValidationResult(index) : checkedValidation.length > 0 && <td />}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="grid-total">
          {t('text:Total')} : {t('format:number', { value: totalApAmount })}
        </div>
      </form>
      <div className="flex-end mt-3">
        <Button
          size={ButtonSizeEnum.LG}
          onClick={handleClickReject}
          className="me-2"
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
        >
          {t('text:Reject')}
        </Button>
        <Button size={ButtonSizeEnum.LG} onClick={handleClickRegister}>
          {t('text:Register')}
        </Button>
      </div>
    </>
  );
}

export default memo(FinancierArInputTable);
