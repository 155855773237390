import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';

import { useAnchorAgreementInfoContext } from './AnchorAgreementInfoContext';

const useAnchorAgreementInfoViewModel = () => {
  const { anchorAgreementInfo, isAssociatedAnchorAgreementExist, updateAnchorAgreementInfo } =
    useAnchorAgreementInfoContext();

  const convertToAnchorAgreementInfoType = (data: AnchorAgreementVOModel) => {
    return {
      anchorUserList: [],
      anchorClientId: data?.financierClientId,
      mainContractNo: data?.contractNo,
      anchorClientName: data?.financierClientName,
      anchorAgreementStartDate: data?.startDate,
      anchorAgreementExpiryDate: data?.expiryDate,
    };
  };

  const updateAnchorUserList = (anchorUserList: AnchorUserVOModel[]) => {
    updateAnchorAgreementInfo({
      ...anchorAgreementInfo,
      mainContractNo: anchorAgreementInfo.anchorAgreementNo,
      anchorUserList,
    });
  };

  return {
    anchorAgreementInfo,
    isAssociatedAnchorAgreementExist,
    convertToAnchorAgreementInfoType,
    updateAnchorAgreementInfo,
    updateAnchorUserList,
  };
};

export default useAnchorAgreementInfoViewModel;
