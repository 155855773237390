import { ANCHOR_AGREEMENT_STATUS, COLLATERAL_TYPE, CURRENCY_TYPE, REPORT_TYPE, stringToEnum } from 'enums';

export interface AnchorReportSettingVO {
  anchorReportSettingId: number;
  anchorAgreementId: number;
  contractNo: string;
  anchorName: string;
  financierName: string;
  currencyType: string;
  collateralType: string;
  reportType: string;
  agreementStatus: string;
  activated: boolean;
}

export interface AnchorReportSettingVOModel extends AnchorReportSettingVO {
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  reportType: REPORT_TYPE;
  agreementStatus: ANCHOR_AGREEMENT_STATUS;
}

export function formattingToAnchorReportSettingVOModel(data: AnchorReportSettingVO): AnchorReportSettingVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    reportType: stringToEnum(REPORT_TYPE, data.reportType),
    agreementStatus: stringToEnum(ANCHOR_AGREEMENT_STATUS, data.agreementStatus),
  };
}
