export interface NoDataIconProps {
  color?: string;
  className?: string;
  size?: number;
  rotate?: number;
}

export const NoDataIcon = ({ color, className, size = 24, rotate }: NoDataIconProps) => {
  return (
    <div
      className={`'scf-icon' ${className ? className : ''}`}
      style={{
        width: size,
        minWidth: size,
        height: size,
        transform: `rotate(${rotate}deg)`,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.9 62.6">
        <g fill={color || '#F6F6F6'}>
          <path d="M31.4,62.5L31.4,62.5c-17.1,0-31.2-14-31.2-31.2v0c0-17.1,14-31.2,31.2-31.2h0c17.1,0,31.2,14,31.2,31.2v0   C62.6,48.4,48.6,62.5,31.4,62.5z" />
          <g fill={color || '#CCCCCC'}>
            <g fill={color || '#CCCCCC'}>
              <path d="M31.4,24.1c-0.5,0-0.9-0.4-0.9-0.9v-8.5c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v8.5     C32.3,23.7,31.9,24.1,31.4,24.1z" />
              <g fill={color || '#CCCCCC'}>
                <path d="M26.2,24.6c-0.3,0-0.6-0.2-0.7-0.4l-4.2-7.3c-0.2-0.4-0.1-0.9,0.3-1.2c0.4-0.2,0.9-0.1,1.2,0.3l4.2,7.3      c0.2,0.4,0.1,0.9-0.3,1.2C26.5,24.6,26.3,24.6,26.2,24.6z" />
                <path d="M36.7,24.6c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.8-0.3-1.2l4.2-7.3c0.2-0.4,0.8-0.6,1.2-0.3      c0.4,0.2,0.6,0.8,0.3,1.2l-4.2,7.3C37.3,24.5,37,24.6,36.7,24.6z" />
              </g>
            </g>
            <path d="M46.3,37.3l-5.9-9.5c-0.2-0.3-0.4-0.4-0.7-0.4H23.2c-0.3,0-0.6,0.2-0.7,0.4l-5.9,9.5    c-0.1,0.1-0.1,0.3-0.1,0.5v7c0,0.5,0.4,0.9,0.9,0.9h28.3c0.5,0,0.9-0.4,0.9-0.9v-7C46.4,37.6,46.4,37.4,46.3,37.3z M23.7,29.1    h15.6l4.9,8h-8.7v1.5c0,0.9-0.7,1.6-1.6,1.6h-4.9c-0.9,0-1.6-0.7-1.6-1.6v-1.5h-8.6L23.7,29.1z M18.2,43.9v-5.2h7.5    c0.1,1.7,1.5,3.1,3.3,3.1h4.9c1.7,0,3.2-1.4,3.3-3.1h7.6v5.2H18.2z" />
          </g>
        </g>
      </svg>
    </div>
  );
};
