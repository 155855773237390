import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash-es';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import { ColorMap } from 'enums/colorMap';
import useOutsideClick from 'hooks/useOutsideClick';
import type { PotentialPartnerFinancingApplicationDetailVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';

import './PotentialPartnerSubmissionDetailSummary.scss';

interface PotentialPartnerSubmissionDetailSummaryProps {
  data: PotentialPartnerFinancingApplicationDetailVOModel;
  numOfNeedResubmission: number;
}

const BREAKPOINT_MEDIUM = 768;

function PotentialPartnerSubmissionDetailSummary({
  data,
  numOfNeedResubmission,
}: PotentialPartnerSubmissionDetailSummaryProps) {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobileVer, setMobileVer] = useState(window.innerWidth < BREAKPOINT_MEDIUM);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const {
    reviewedBranchCode,
    reviewedBranchName,
    requestBranchCode,
    potentialPartnerFinancingApplicationStatus,
    createdDateTime,
    latestDocumentUpdateDateTime,
  } = data;

  const isSameBranch = reviewedBranchCode === requestBranchCode;

  const isVisibleTooltip =
    potentialPartnerFinancingApplicationStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS &&
    !isSameBranch;

  useOutsideClick(() => ReactTooltip.hide(), tooltipRef);

  useEffect(() => {
    const handleResize = debounce(() => {
      setScreenWidth(window.innerWidth);
    }, 1000);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setMobileVer(screenWidth < BREAKPOINT_MEDIUM);
  }, [screenWidth]);

  const renderApplicationStatusSentence = (status: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS) => {
    const defaultClassName = 'pp-application-status ';
    switch (status) {
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED:
        return (
          <span
            className={
              defaultClassName +
              getStatusTextClass(
                'POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS',
                POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED,
              )
            }
          >
            {t('code:potential-partner-financing-application-status.SUBMITTED')}
          </span>
        );

      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS:
        return (
          <span
            className={
              defaultClassName +
              getStatusTextClass(
                'POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS',
                POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS,
              )
            }
          >
            {t('code:potential-partner-financing-application-status.IN_PROGRESS')}
          </span>
        );
    }
  };

  return (
    <div className="pp-submission-detail-summary">
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row--status">
            <div className="pp-submission__application-status-wrapper">
              <FormValue
                label={t('text:Application_Status')}
                value={renderApplicationStatusSentence(potentialPartnerFinancingApplicationStatus)}
                col={12}
              />
              {numOfNeedResubmission > 0 && (
                <SectionMessage
                  messageType="alert"
                  message={[
                    t('text:NumberOfDocument_s_must_be_resubmitted', {
                      NumberOfDocument: numOfNeedResubmission,
                    }),
                  ]}
                />
              )}
            </div>
          </div>
          <div className="row">
            <FormValue label={t('text:Initial_Submission')} value={createdDateTime} format="datetime" col={3} />
            <FormValue label={t('text:Recent_Update')} value={latestDocumentUpdateDateTime} format="datetime" col={3} />
            {potentialPartnerFinancingApplicationStatus !==
              POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED && (
              <FormValue
                label={t('text:Reviewed_By')}
                value={reviewedBranchName}
                col={3}
                labelChildren={
                  isVisibleTooltip && (
                    <>
                      <a
                        data-tip
                        data-for="reviewedBranchDesc"
                        style={{
                          color: ColorMap.sub600,
                          cursor: 'pointer',
                        }}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </a>
                      <div ref={tooltipRef}>
                        <ReactTooltip
                          key={isMobileVer ? 'mobile-tooltip' : 'pc-tooltip'}
                          id="reviewedBranchDesc"
                          event={isMobileVer ? 'click' : ''}
                          place={isMobileVer ? 'right' : 'top'}
                          scrollHide
                          isCapture
                        >
                          {t(
                            'text:The_branch_that_reviews_your_application_may_differ_from_the_branch_that_you_initially_selected',
                          )}
                        </ReactTooltip>
                      </div>
                    </>
                  )
                }
              />
            )}
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default PotentialPartnerSubmissionDetailSummary;
