import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import preval from 'preval.macro';

import Button, { ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { ROUTES_CM } from 'constants/routes/common';
import { ROLE, languageType } from 'enums';
import useMounted from 'hooks/useMounted';
import useErrorHandler from 'hooks/useOnErrorHandler';
import { LANGUAGE_SELECT_OPTIONS, handleLanguageSelectChange } from 'locales/languageSettings';
import type { SignInModel } from 'models/SignInModel';
import { useSignInStore } from 'store';
import { browserCheck } from 'utils/browser';
import { requestFinancierSupportData } from 'utils/http/api/common/financier-common-setting';
import { requestSignIn } from 'utils/http/api/common/signin';
import type { SignInRequest } from 'utils/http/api/common/signin/requests';
import useModal from 'utils/modal/useModal';
import { homePathByRole } from 'utils/route';
import { getLanguage, getSignIn, setFinancierSupportData, setSignIn } from 'utils/storage/LocalStorage';

import '../CommonPage.scss';

function LoginPage() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const errorHandler = useErrorHandler();
  const { register, handleSubmit } = useForm<SignInRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const { setStoreSignIn } = useSignInStore();
  const buildTimestamp = preval`module.exports = new Date().getTime();`;

  useEffect(() => {
    if (mounted) {
      // 지역, 빌드 시간 출력
      const getBuildDateTime = () => {
        return dayjs.unix(buildTimestamp / 1000).format('YYYY-MM-DD HH:mm:ss');
      };

      // eslint-disable-next-line no-console
      console.log(`Build Locale :: ${process.env.REACT_APP_LOCALE}`);
      // eslint-disable-next-line no-console
      console.log(`Build Time :: ${getBuildDateTime()}`);

      const signInModel: SignInModel | null = getSignIn();
      if (signInModel) {
        toRouteFetchDataAndSetStorage(signInModel.authorities[0].authority);
      }

      if (browserCheck()) {
        modal.show(
          <h6>
            {t('text:This_browser_is_not_supported')}
            <br />
            {t('text:Please_access_with_a_Chrome_browser')}
            <br />
            <a href="https://www.google.com/chrome/">Chrome Download</a>
          </h6>,
        );
      }
    }
  }, [mounted]);

  const fetchDataAndSetStorage = async () => {
    try {
      const financierSupportData = await requestFinancierSupportData();
      setFinancierSupportData(financierSupportData);
    } catch (error) {
      modal.show(error);
    }
  };

  const toRouteFetchDataAndSetStorage = async (authorityType: keyof typeof ROLE) => {
    if (authorityType !== ROLE.ROLE_SYSTEM) {
      await fetchDataAndSetStorage();
    }

    history.push(homePathByRole(authorityType));
  };

  const onSubmit = async (data: SignInRequest) => {
    try {
      const signInModel: SignInModel = await requestSignIn(data);
      signInModel.signInTime = new Date();

      const role = signInModel.authorities[0].authority;
      const notAccessLoginUser = process.env.REACT_APP_BUILD_TARGET === 'index' && role === ROLE.ROLE_SYSTEM;

      if (notAccessLoginUser) return modal.show(t('exception:ATN-011'));
      setSignIn(signInModel);
      setStoreSignIn(signInModel);
      toRouteFetchDataAndSetStorage(role);
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <div className="login d-flex h-100">
      <div className="login-container">
        <form>
          <div className="login-form">
            <h2 className="login-form__title">{t('text:Welcome')}</h2>
            <div className="border-bottom-light-gray mb-3" />
            <div className="login-form__label d-flex justify-content-between">
              <label>{t('text:ID')}</label>
              <Link to={ROUTES_CM.FIND_ID}>{t('text:Forgot_ID?')}</Link>
            </div>
            <div className="login-form__input mb-3">
              <input
                className="information-form__input"
                type="text"
                name="id"
                placeholder={t('text:Please_type_here')}
                ref={register({ required: true })}
              />
            </div>
            <div className="login-form__label d-flex justify-content-between">
              <label>{t('text:Password')}</label>
              <Link to={ROUTES_CM.FIND_PASSWORD}>{t('text:Forgot_Password?')}</Link>
            </div>
            <div className="login-form__input">
              <input
                className="information-form__input"
                type="password"
                name="password"
                placeholder={t('text:Please_type_here')}
                ref={register({ required: true })}
              />
            </div>
            <div className="mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onSubmit, errorHandler)} type="submit">
                {t('text:Sign_in')}
              </Button>
            </div>
            <div className="login-form__label d-flex justify-content-end mt-1">
              <span className="me-1">{t('text:To_continue_with_a_temporary_access_code,')}</span>
              <Link to="/access">{t('text:click_here')}</Link>
            </div>
            {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
              <div className="mt-4">
                <Button
                  size={ButtonSizeEnum.LG}
                  variant={ButtonVariantEnum.OUTLINED}
                  onClick={() => history.push(ROUTES_CM.CREATE_FINANCIER)}
                >
                  {t('text:Create_an_Account')}
                </Button>
              </div>
            )}
          </div>
          <div className="language-selection d-flex justify-content-center">
            <div>{i18n.t('text:Language')}</div>
            <select
              defaultValue={getLanguage() || languageType.EN_USA}
              onChange={e => handleLanguageSelectChange(e.target.value as languageType)}
            >
              {LANGUAGE_SELECT_OPTIONS.map(({ text, value }, index) => (
                <option key={index} value={value}>
                  {text}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
      <div className="content-container">
        <div className="content__product-name">
          {process.env.REACT_APP_IS_KB === 'true' && (
            <img className="logo" src="/static/image/logo_kbbank.png" alt="KB Bank Logo" />
          )}
          <p className="mb-2">{t('text:A_powerful_early_payment_solution')}</p>
          <h1>
            {t('text:Supply_Chain_Financing_Platform')}
            {process.env.REACT_APP_BUILD_TARGET === 'admin' && (
              <span className="env-local-title ms-2">in {process.env.REACT_APP_LOCALE}</span>
            )}
          </h1>
        </div>
        <div className="content__help-desk-box">
          <div className="content__help-desk-box__label">{t('text:Operating_Hours')}</div>
          <div className="text-wrapper">
            {/*text와 text__small을 감싸기 위해 사용*/}
            <span className="content__help-desk-box__text">24/7</span>
            <span className="content__help-desk-box__text__small">{t('text:24_hours_a_day_and_7_days_a_week')}</span>
          </div>
          <div className="content__help-desk-box__label margin-top-8">
            {t('text:Optimized_for_Chrome_Browser_on_Windows')}
          </div>
          <div className="line" />
          <div className="content__help-desk-box__service-name">Fin2B SCF Platform SaaS 1.0.0</div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
