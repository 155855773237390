import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import { useFormContext } from 'components/stateless/Form';
import { LOAN_OPTION } from 'enums';
import { isNilOrEmptyString } from 'utils/helpers';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useAutomaticFinancingController = () => {
  const {
    agreement: { loanOption },
    isSearchedAgreement,
  } = useAgreementViewModel();
  const { useAgreementInterface } = useExtraInformationViewModel();

  const {
    methods: { register, setValue, errors, clearErrors },
    getReadOnlyValue,
    isEditable,
  } = useFormContext();

  const [hasLoanOption, setHasLoanOption] = useState<boolean | null>(null);
  const [canAutoApproval, setCanAutoApproval] = useState<boolean | null>(null);

  const isEditableField = useAgreementInterface ? isSearchedAgreement : isEditable;

  const isLoanOptionNotNone = (option: LOAN_OPTION | undefined | null | '') => {
    if (isNilOrEmptyString(option)) return null;

    return option !== LOAN_OPTION.NONE;
  };

  const hasAutoApprovalLoanOption = (option: LOAN_OPTION | undefined | null | '') => {
    if (isNilOrEmptyString(option) || option === LOAN_OPTION.NONE) return null;

    return option === LOAN_OPTION.AUTO_APPROVE;
  };

  const handleHasLoanOptionChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const updatedHasLoanOption = value === 'true';
    setHasLoanOption(updatedHasLoanOption);
    setCanAutoApproval(null);

    const updatedLoanOption = updatedHasLoanOption ? undefined : LOAN_OPTION.NONE;
    setValue('loanOption', updatedLoanOption);

    if (errors?.loanOption) clearErrors('loanOption');
  };

  const handleCanAutoApprovalChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const updatedCanAutoApproval = value === 'true';
    setCanAutoApproval(updatedCanAutoApproval);

    const updatedLoanOption = updatedCanAutoApproval ? LOAN_OPTION.AUTO_APPROVE : LOAN_OPTION.AUTO_REQUEST;
    setValue('loanOption', updatedLoanOption);

    if (errors?.loanOption) clearErrors('loanOption');
  };

  useEffect(() => {
    setHasLoanOption(isLoanOptionNotNone(loanOption));
    setCanAutoApproval(hasAutoApprovalLoanOption(loanOption));
  }, [loanOption]);

  return {
    register,
    errors,
    getReadOnlyValue,
    isEditableField,
    hasLoanOption,
    canAutoApproval,
    handleHasLoanOptionChange,
    handleCanAutoApprovalChange,
  };
};

export default useAutomaticFinancingController;
