import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import InvoiceInputTable from 'components/invoice/InvoiceInputTable';
import InvoiceSummaryPhaseAccordion from 'components/invoice/InvoiceSummaryPhaseAccordion';
import PaymentInformationAccordion from 'components/stateless/Accordion/PaymentInformationAccordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import InvoiceAmountExceedModal from 'components/stateless/Modal/common/invoice/InvoiceAmountExceedModal';
import InvoiceRegistrationModalComplete from 'components/stateless/Modal/common/invoice/InvoiceRegistrationModalComplete';
import InvoiceRegistrationModalNew from 'components/stateless/Modal/common/invoice/InvoiceRegistrationModalNew';
import RejectConfirmModal from 'components/stateless/Modal/common/invoice/RejectConfirmModal';
import ValidationErrorModal from 'components/stateless/Modal/common/invoice/ValidationErrorModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import {
  AUTHORITY_TYPE,
  EXECUTION_STATUS,
  LOAN_LIMIT_CHECK_TYPE,
  LOAN_OPTION,
  OTP_TYPE,
  WAITING_INVOICE_APPROVAL_PHASE_STATUS,
} from 'enums';
import useMounted from 'hooks/useMounted';
import useProgressStatus from 'hooks/useProgressStatus';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { FiscalYearSettingVOModel } from 'models/vo/FiscalYearSettingVO';
import type { InvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import type { InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import type { LastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import type { RegistrableLimitVOModel } from 'models/vo/RegistrableLimitVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import type { BoeTableStructure } from 'utils/boe';
import calculateBOEInformation from 'utils/boe';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestAnchorClientAuthByDealerAgreementId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestAnchorAgreementRelatedDealerAgreement,
  requestAnchorDealerAgreementDetail,
} from 'utils/http/api/anchor/dealer-agreements';
import { requestAnchorFinancierCalendar } from 'utils/http/api/anchor/financier-calendar';
import { requestAnchorInvoicePhasesList } from 'utils/http/api/anchor/invoice-approval-phases';
import {
  requestAnchorApproveInvoicePhase,
  requestAnchorInvoiceSummaryDetail,
  requestAnchorInvoiceSummaryLastPhase,
  requestAnchorRejectInvoiceRegistration,
} from 'utils/http/api/anchor/invoice-summaries';
import type {
  AnchorInvoicePhaseApproveRequest,
  AnchorInvoiceRegisterInvoiceListRequest,
} from 'utils/http/api/anchor/invoice-summaries/requests';
import { requestAnchorWaitingInvoiceList } from 'utils/http/api/anchor/waiting-invoices';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestFiscalYearSettingCurrentUser } from 'utils/http/api/common/fiscal-year-setting';
import { requestRegistrableLimitByDealerAgreement } from 'utils/http/api/common/registrable-limit';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import {
  INVOICE_PAYMENT_TO_DATE_RANGE,
  calculateInvoiceFinancingBalance,
  calculateInvoiceRemainingLimit,
  getInitialSettlementDateFunction,
  isExceedInvoiceAmountWhenConfirmation,
  setEnteredSettlementDate,
} from 'utils/logic/invoice';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const MAX_ROW_LENGTH = 500;

function AnchorInvoiceConfirmationDetail() {
  const history = useHistory();
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const signInModel: SignInModel | null = getSignIn();

  const { invoiceSummaryId } = useParams() as any;
  const { financierEnterpriseId } = useParams() as any;

  const userAuthorityType = getSignIn()?.authorityType;

  const [invoiceSummaryData, setInvoiceSummaryData] = useState<InvoiceSummaryVOModel>({} as InvoiceSummaryVOModel);
  const [invoiceSummaryPhaseDataList, setInvoiceSummaryPhaseDataList] = useState<InvoicePhaseVOModel[]>([]);
  const [editableInvoiceSummaryData, setEditableInvoiceSummaryData] = useState<LastInvoicePhaseVOModel>(
    {} as LastInvoicePhaseVOModel,
  );
  const [dealerAgreementData, setDealerAgreementData] = useState<DealerAgreementDetailVOModel>();
  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementVOModel>();
  const [financierCommonSettingData, setFinancierCommonSettingData] = useState<FinancierCommonSettingVOModel>();

  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState<BigNumber>('0');
  const [isExceedAmount, setIsExceedAmount] = useState<boolean>(false);
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();
  const [registrableLimit, setRegistrableLimit] = useState<RegistrableLimitVOModel>();
  const [fiscalYearSettingData, setFiscalYearSettingData] = useState<FiscalYearSettingVOModel | null>(null);
  const [boeTableData, setBoeTableData] = useState<BoeTableStructure[]>([]);
  const [boeNumber, setBoeNumber] = useState<string>('');
  const [rows, setRows] = useState<string[]>(['']);
  const [columns, setColumns] = useState<string[]>([]);
  const [minDate, setMinState] = useState<Date>();

  const { renderProgressStatus } = useProgressStatus(invoiceSummaryData);

  const date = new Date();
  const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];

  const startDate = new Date(today[0], today[1], today[2]);
  const toDate = new Date(today[0], today[1], today[2] + INVOICE_PAYMENT_TO_DATE_RANGE);
  const nextDate = new Date(today[0], today[1], today[2] + 1);

  const calculatorBigNumber = useMemo(() => new CalculatorBigNumber(), []);

  const invoiceConfirmationUseForm = useForm<AnchorInvoicePhaseApproveRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { getValues, reset, trigger } = invoiceConfirmationUseForm;

  const boeTableHeaders: HeaderType[] = [
    {
      headerText: t('text:Payment_Date'),
      className: 'text-left',
    },
    {
      headerText: t('text:Payment_Amount'),
      className: 'text-left',
    },
  ];

  const sumInvoiceAmount = useCallback(() => {
    const data = getValues();

    let sum: BigNumber = '0';

    if (data.invoiceList) {
      data.invoiceList.forEach(item => {
        if (item && item.invoiceAmount) {
          sum = calculatorBigNumber.add(sum).add(item.invoiceAmount).get();
        }
      });
    }

    setTotalInvoiceAmount(sum);
  }, [calculatorBigNumber, getValues]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    let initialSettlementDate: string | undefined = undefined;
    try {
      const [fetchInvoiceSummaryData, fetchInvoiceSummaryPhasePageData, fetchEditableInvoiceSummaryData] =
        await Promise.all([
          requestAnchorInvoiceSummaryDetail(invoiceSummaryId),
          requestAnchorInvoicePhasesList(1, 10, invoiceSummaryId),
          requestAnchorInvoiceSummaryLastPhase(invoiceSummaryId),
        ]);
      if (fetchEditableInvoiceSummaryData.authorized) {
        const [
          fetchDealerAgreementData,
          fetchFinancierCommonSettingData,
          fetchRegistrableLimit,
          anchorAgreementData,
          fiscalYearSettingResponse,
        ] = await Promise.all([
          requestAnchorDealerAgreementDetail(fetchInvoiceSummaryData.dealerAgreementId),
          requestFinancierSettingData(financierEnterpriseId),
          requestRegistrableLimitByDealerAgreement(fetchInvoiceSummaryData.dealerAgreementId),
          requestAnchorAgreementRelatedDealerAgreement(fetchInvoiceSummaryData.dealerAgreementId),
          requestFiscalYearSettingCurrentUser(),
        ]);
        const fromDate = fetchFinancierCommonSettingData?.settlementDateIsTodayInvoiceUploadable ? startDate : nextDate;

        const fetchFinancierHoliday = await requestAnchorFinancierCalendar(financierEnterpriseId, {
          pageNumber: 0,
          rowCount: Number(getDayTerm(convertToServerDateFormat(fromDate), convertToServerDateFormat(toDate))) + 1,
          fromDate: convertToServerDateFormat(fromDate),
          toDate: convertToServerDateFormat(toDate),
          holiday: true,
        });

        const getHolidayArrays = fetchFinancierHoliday.content.map(item => item.solarDate);

        initialSettlementDate = getInitialSettlementDateFunction(
          getHolidayArrays,
          fetchFinancierCommonSettingData,
          startDate,
          nextDate,
          toDate,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setDealerAgreementData(fetchDealerAgreementData);
          setAnchorAgreementData(anchorAgreementData);
          setRegistrableLimit(fetchRegistrableLimit);
          setFinancierCommonSettingData(fetchFinancierCommonSettingData);
          setFinancierHoliday(fetchFinancierHoliday);
          setBoeNumber(
            fetchEditableInvoiceSummaryData.invoiceList.content[0]
              ? fetchEditableInvoiceSummaryData.invoiceList.content[0].billOfExchangeNo
              : '',
          );
          setFiscalYearSettingData(fiscalYearSettingResponse);
          setMinState(fromDate);
        });

        const fetchedInvoiceListContent = fetchEditableInvoiceSummaryData.invoiceList.content;
        if (fetchedInvoiceListContent.length !== 0) {
          const additionalColumnNameList = fetchedInvoiceListContent[0].invoiceAdditionalDataList?.map(() => '') ?? [];
          setColumns(additionalColumnNameList);
          setRows(Array(fetchedInvoiceListContent.length).fill(''));

          reset({
            invoiceList: fetchedInvoiceListContent.map(item => {
              return {
                billOfExchangeNo: item.billOfExchangeNo,
                invoiceNumber: item.invoiceNumber,
                referenceNumber: item.referenceNumber,
                invoiceIssuedDate: item.invoiceIssuedDate,
                settlementDate: setEnteredSettlementDate(
                  getHolidayArrays,
                  item.settlementDate,
                  initialSettlementDate,
                  fetchFinancierCommonSettingData,
                ),
                invoiceAmount: item.invoiceAmount,
                invoiceAdditionalDataList: item.invoiceAdditionalDataList ?? [],
              };
            }),
            additionalColumnNameList: fetchedInvoiceListContent[0].invoiceAdditionalDataList?.map(item => ({
              name: item.name,
            })),
          });

          setBoeNumber(fetchedInvoiceListContent[0].billOfExchangeNo);
        } else {
          reset({
            invoiceList: [
              {
                billOfExchangeNo: undefined,
                invoiceNumber: undefined,
                referenceNumber: undefined,
                invoiceIssuedDate: undefined,
                settlementDate: initialSettlementDate,
                invoiceAmount: undefined,
              },
            ],
          });
        }
      }

      ReactDOM.unstable_batchedUpdates(() => {
        setInvoiceSummaryData(fetchInvoiceSummaryData);
        setInvoiceSummaryPhaseDataList(fetchInvoiceSummaryPhasePageData.content);
        setEditableInvoiceSummaryData(fetchEditableInvoiceSummaryData);
        setTotalInvoiceAmount(calculatorBigNumber.add(fetchEditableInvoiceSummaryData.amount).get());
        setInitialSettlementDate(initialSettlementDate);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickReject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    let reason = '';

    e.preventDefault();

    const onClickConfirmOnReject = async (): Promise<void> => {
      try {
        await requestAnchorRejectInvoiceRegistration({
          invoiceSummaryId: invoiceSummaryId,
          reason: reason,
        });

        modal.show(<RejectConfirmModal />, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => {
            fetchAll();
          },
        });
      } catch (error) {
        modal.show(error);
      }
    };

    if (
      invoiceSummaryData.executionStatus === EXECUTION_STATUS.RUNNING ||
      invoiceSummaryData.executionStatus === EXECUTION_STATUS.REGISTERED
    ) {
      modal.show(<h6>{t('text:The_invoice_confirmation_has_already_been_requested_and_is_in_progress')}</h6>, {
        closeBtnCb: fetchAll,
      });
    } else {
      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_reject_the_registration_request?')}
            <br />
            {t(
              'text:If_you_reject_the_registration_request_you_will_have_to_proceed_with_the_invoice_registration_again',
            )}
            <br />
            {t('text:Please_enter_the_reason_for_the_rejection_below')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: () => onClickConfirmOnReject(),
        },
      );
    }
  };

  const mappingAdditionalColumnNameAndValue = () => {
    const { additionalColumnNameList, invoiceList } = getValues();

    return invoiceList?.map(invoiceItem => ({
      ...invoiceItem,
      invoiceAdditionalDataList: invoiceItem?.invoiceAdditionalDataList?.map((item, index) => ({
        ...item,
        name: additionalColumnNameList?.[index].name ?? '',
      })),
    }));
  };

  const getRequestValues = () => {
    const { additionalColumnNameList } = getValues();

    const noInvoiceAdditionalDataList = (additionalColumnNameList?.length ?? 0) === 0;
    if (noInvoiceAdditionalDataList) return getValues();

    const mappedAdditionalData = mappingAdditionalColumnNameAndValue();

    return { ...getValues(), invoiceList: mappedAdditionalData };
  };

  const triggerValidationForAdditionalColumnName = async () => {
    if (columns.length === 0) return true;

    const additionalColumnFieldNameList = columns.map((_, index) => `additionalColumnNameList[${index}].name`);
    const validationAdditionalColumnName = await trigger(additionalColumnFieldNameList);

    if (!validationAdditionalColumnName) {
      modal.show(<h6>{t('text:Enter_the_names_for_all_of_the_added_columns')}</h6>);
    }

    return validationAdditionalColumnName;
  };

  const onClickRegister = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    const verificationCode: UserVerificationCodeRequest = {};

    const data = getValues();
    setIsExceedAmount(false);

    const executeRegister = () => {
      return new Promise<void>(resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1000);
      }).then(async () => {
        if (!(await triggerValidationForAdditionalColumnName())) {
          unShowLoadingUI();

          return;
        }

        await trigger().then(result => {
          setCheckedValidation(data.invoiceList.map(() => true));
          unShowLoadingUI();
          if (!result) {
            modal.show(<ValidationErrorModal />, {
              modalSize: ModalSize.NONE,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:Close'),
            });

            return; // FE validation error 있으면 return
          }
          //  validation #2. total amount check
          else if (
            dealerAgreementData?.loanLimitCheckType !== LOAN_LIMIT_CHECK_TYPE.LOAN &&
            isExceedInvoiceAmountWhenConfirmation(
              dealerAgreementData,
              editableInvoiceSummaryData.amount,
              totalInvoiceAmount,
            )
          ) {
            setIsExceedAmount(true);
            modal.show(<InvoiceAmountExceedModal />, {
              modalSize: ModalSize.NONE,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:Close'),
            });
          }
          // Execution Status check
          else if (
            invoiceSummaryData.executionStatus === EXECUTION_STATUS.RUNNING ||
            invoiceSummaryData.executionStatus === EXECUTION_STATUS.REGISTERED
          ) {
            modal.show(<h6>{t('text:The_invoice_confirmation_has_already_been_requested_and_is_in_progress')}</h6>);
          } else {
            if (data)
              modal.show(
                <InvoiceRegistrationModalNew
                  dataList={data}
                  totalCount={rows.length}
                  totalAmount={totalInvoiceAmount}
                  currencyType={invoiceSummaryData?.currencyType}
                />,
                {
                  modalSize: ModalSize.XL,
                  modalType: ModalType.CONFIRM,
                  closeBtnText: t('text:Cancel'),
                  confirmBtnText: t('text:Confirm'),
                  confirmBtnCb: async () => {
                    try {
                      const clientAuthTypeData = await requestAnchorClientAuthByDealerAgreementId(
                        invoiceSummaryData.dealerAgreementId,
                      );

                      if (
                        (clientAuthTypeData.currentAuthorityType === AUTHORITY_TYPE.ADMIN ||
                          clientAuthTypeData.currentAuthorityType === AUTHORITY_TYPE.AUTHORIZER) &&
                        clientAuthTypeData.otpType !== OTP_TYPE.NONE
                      ) {
                        modal.show(
                          <UserVerificationModal
                            modalId={modal.id}
                            verificationCode={verificationCode}
                            requestIdType="dealerAgreementId"
                            requestId={invoiceSummaryData.dealerAgreementId}
                            clientAuthSetting={clientAuthTypeData}
                          />,
                          {
                            modalSize: ModalSize.NONE,
                            modalType: ModalType.CONFIRM,
                            closeBtnText: t('text:Cancel'),
                            closeBtnCb: () => {},
                            confirmBtnText: t('text:Confirm'),
                            confirmBtnCb: () => onClickUserVerificationModalConfirm(),
                          },
                        );
                      } else {
                        onClickUserVerificationModalConfirm();
                      }
                    } catch (e) {
                      modal.show(e);
                    }
                  },
                },
              );
          }
        });
      });
    };
    await executeRegister();
    const showInvoiceRegistrationResultModal = (
      resultDataList: LastInvoicePhaseVOModel,
      count: number,
      amount: BigNumber,
    ) => {
      modal.show(
        <InvoiceRegistrationModalComplete
          dataList={resultDataList}
          totalCount={count}
          totalAmount={amount}
          currencyType={invoiceSummaryData?.currencyType}
        />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_AC.REGISTER_INVOICE.CONFIRMATION_LIST),
        },
      );
    };

    const onClickUserVerificationModalConfirm = async () => {
      const requestedData = {
        ...getRequestValues(),
        invoiceSummaryId,
        dealerAgreementId: invoiceSummaryData.dealerAgreementId,
        otpCode: verificationCode.otpCode,
        queryValue: verificationCode.queryValue,
      };

      try {
        const approveResult = await requestAnchorApproveInvoicePhase(requestedData);

        // TODO API 요청 시 verification code가 다를 때 띄워줄 에러 모달, 백엔드 통신 시 유효성 검사 통과하지 못했을때의 모달이 추가로 필요할 것으로 예상됌
        showInvoiceRegistrationResultModal(approveResult, approveResult.count, approveResult.amount);
      } catch (error) {
        modal.show(error);
      }
    };
  };

  const onClickInvoiceConfirmationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(WAITING_INVOICE_APPROVAL_PHASE_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="WAITING_INVOICE_APPROVAL_PHASE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickInvoiceConfirmationProgressStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={[
          EXECUTION_STATUS.NONE,
          EXECUTION_STATUS.REGISTERED,
          EXECUTION_STATUS.RUNNING,
          EXECUTION_STATUS.FAILED,
        ]}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="EXECUTION_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const handleInvoiceSummaryPhaseAccordionClick = (invoiceSummaryPhase: InvoicePhaseVOModel) => {
    return requestAnchorWaitingInvoiceList(1, MAX_ROW_LENGTH, invoiceSummaryPhase.waitingInvoiceApprovalPhaseId);
  };

  const renderConfirmationProgressStatus = () => {
    const getConfirmationProgressStatusText = (executionStatus?: EXECUTION_STATUS) => {
      switch (executionStatus) {
        case EXECUTION_STATUS.NONE:
          return t('text:None');
        case EXECUTION_STATUS.REGISTERED:
          return t('text:Waiting');
        case EXECUTION_STATUS.RUNNING:
          return t('text:In_Progress');
        case EXECUTION_STATUS.FAILED:
          return t('text:Failed');
        default:
          return '';
      }
    };

    if (!invoiceSummaryData.executionStatus) return '-';

    return (
      <div className={getStatusBadgeClass('EXECUTION_STATUS', invoiceSummaryData.executionStatus)}>
        {getConfirmationProgressStatusText(invoiceSummaryData.executionStatus)}
      </div>
    );
  };

  const refreshBoeTable = useCallback(() => {
    const { invoiceList } = getValues();

    if (invoiceList && Array.isArray(invoiceList)) {
      const boeTableDatas = calculateBOEInformation<AnchorInvoiceRegisterInvoiceListRequest>(invoiceList);

      setBoeTableData(boeTableDatas);
    }
  }, [getValues]);

  const renderBoeTable = (): JSX.Element[] | undefined => {
    return boeTableData.map((item, index) => (
      <Tr key={index}>
        <Td data={item.settlementDate} />
        <Td data={item.invoiceAmount} format="number" />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Invoice_Confirmation_Details')} />
      {invoiceSummaryData.rejected ? (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_invoice_registration_request_has_been_rejected'),
            t('text:Please_check_the_reason_below_for_the_details'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={invoiceSummaryData.rejectReason}
        />
      ) : (
        invoiceSummaryData.executionStatus === EXECUTION_STATUS.FAILED && (
          <GuideMessage
            message={[
              t('text:Invoice_confirmation_request_has_failed'),
              t('text:Please_check_the_reason_below_for_the_details'),
            ]}
            messageType={MessageType.ALERT}
            reasonTitle={t('text:REASON_FOR_FAILURE')}
            reason={t(`exception:${invoiceSummaryData.exceptionCode}`)}
          />
        )
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Registration_Status')}
                value={renderProgressStatus()}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickInvoiceConfirmationStatus}
              />
              <FormValue
                col={3}
                label={t('text:Confirmation_Progress_Status')}
                value={renderConfirmationProgressStatus()}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickInvoiceConfirmationProgressStatus}
              />
              <FormValue
                col={3}
                label={t('text:Number_of_Valid_Invoices')}
                value={invoiceSummaryData.rejected ? '-' : invoiceSummaryData.currentApprovalCount}
                className="information-form__input border-none pointfont"
              />
              <FormValue
                col={3}
                label={t('text:Total_Amount_of_Valid_Invoices')}
                value={
                  invoiceSummaryData.rejected
                    ? '-'
                    : t('format:number', { value: invoiceSummaryData.currentApprovalAmount })
                }
                className="information-form__input border-none pointfont"
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} value={invoiceSummaryData.financierName} col={3} />
              <FormValue label={t('text:BOE_Number')} value={invoiceSummaryData.billOfExchangeNo} col={3} />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Partner_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Partner_Master_Agreement_Number')}
                value={invoiceSummaryData.dealerContractNo}
              />
              <FormValue col={3} label={t('text:Partner_Name')} value={invoiceSummaryData.dealerClientName} />
              <FormValue col={3} label={t('text:Currency')} value={invoiceSummaryData.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {editableInvoiceSummaryData.authorized &&
        !editableInvoiceSummaryData.inProgress &&
        !invoiceSummaryData.rejected &&
        (dealerAgreementData?.loanLimitCheckType !== LOAN_LIMIT_CHECK_TYPE.LOAN ||
          anchorAgreementData?.loanOption !== LOAN_OPTION.NONE) && (
          <div className="content-area">
            <SectionTitle title={t('text:Invoice_Registration_Limit')} />
            <div className="information-form__corporation">
              <div className="corporation-section">
                <div className="row">
                  <div className="col-6">{t('text:Financing_Limit')} ①</div>
                  <div className="col-6">{t('format:number', { value: dealerAgreementData?.loanLimitAmount })}</div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Financing_Balance')} ②</div>
                  <div className="col-6">{calculateInvoiceFinancingBalance(dealerAgreementData)}</div>
                </div>
              </div>
              <div className="corporation-section">
                <div className="row">
                  <div className="col-6">{t('text:Remaining_Limit')} ③ = ① - ②</div>
                  <div className="col-6">{calculateInvoiceRemainingLimit(dealerAgreementData)}</div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Registration_Confirmed_Invoice')} ④</div>
                  <div className="col-6">
                    {t('format:number', { value: dealerAgreementData?.settlementWaitingInvoiceAmount })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Registration_Requested_Invoice')} ⑤</div>
                  <div className="col-6 text-end">
                    {t('format:number', {
                      value: dealerAgreementData?.registeredWaitingInvoiceAmount,
                    })}
                  </div>
                </div>
              </div>
              <div className="corporation-section">
                <div className="row">
                  <div className="col-6">{t('text:Limit_Available_for_Registration')} ⑥ = ③ - ④ - ⑤</div>
                  <div className="col-6 text-end blackfont">
                    {t('format:number', { value: dealerAgreementData?.totalLimitAmountWithoutAdHoc })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">{t('text:Exceed_Limit')} ⑦</div>
                  <div className="col-6 text-end">
                    {t('format:number', {
                      value: dealerAgreementData?.exceedLimit,
                    })}
                  </div>
                </div>
              </div>
              {editableInvoiceSummaryData?.adhocLimitAllowable && (
                <>
                  <div className="corporation-section">
                    <div className="row">
                      <div className="col-6">{t('text:Ad_hoc_Limit_Amount')} ⑧</div>
                      <div className="col-6">
                        {tableValueManage(
                          dealerAgreementData?.adhocLimitAmount,
                          t('format:number', { value: dealerAgreementData?.adhocLimitAmount }),
                        )}
                      </div>
                    </div>
                    <div className="sub-container">
                      <div className="sub-title">{t('text:Ad_hoc_Limit_Start_Date')}</div>
                      <div className="sub-content">
                        {tableValueManage(
                          dealerAgreementData?.adhocLimitStartDate,
                          t('format:date', {
                            value: dealerAgreementData?.adhocLimitStartDate,
                            key: 'date',
                          }),
                        )}
                      </div>
                    </div>
                    <div className="sub-container">
                      <div className="sub-title">{t('text:Ad_hoc_Limit_Start_Date')}</div>
                      <div className="sub-content">
                        {tableValueManage(
                          dealerAgreementData?.adhocLimitEndDate,
                          t('format:date', {
                            value: dealerAgreementData?.adhocLimitEndDate,
                            key: 'date',
                          }),
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="corporation-section">
                    <div className="row">
                      <div className="col-6">{t('text:Limit_Available_for_Registration_with_Ad_hoc')} ⑨ = ⑥ + ⑧</div>
                      <div className="col-6 text-end">
                        {t('format:number', { value: dealerAgreementData?.totalLimitAmountWithAdHoc })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      <div className="content-area">
        <SectionTitle title={t('text:Invoice_List')} />
        {editableInvoiceSummaryData.authorized &&
          !editableInvoiceSummaryData.inProgress &&
          !invoiceSummaryData.rejected && (
            <div className="editable-section">
              <div className="clearfix">
                {invoiceSummaryData && (
                  <>
                    <FormProvider {...invoiceConfirmationUseForm}>
                      <form>
                        <InvoiceInputTable
                          columns={columns}
                          setColumns={setColumns}
                          rows={rows}
                          setRows={setRows}
                          checkedValidation={checkedValidation}
                          setCheckedValidation={setCheckedValidation}
                          totalInvoiceAmount={totalInvoiceAmount}
                          sumInvoiceAmount={sumInvoiceAmount}
                          isExceedAmount={isExceedAmount}
                          boeNumber={boeNumber}
                          initialSettlementDate={initialSettlementDate}
                          anchorAgreementData={anchorAgreementData}
                          financierCommonSettingData={financierCommonSettingData}
                          financierHoliday={financierHoliday}
                          minDate={minDate}
                          toDate={toDate}
                          fiscalYearSettingData={fiscalYearSettingData}
                          invoiceSummaryData={invoiceSummaryData}
                          registrableLimit={registrableLimit}
                        />
                      </form>
                    </FormProvider>
                    <PaymentInformationAccordion id="payment-information" handleRefresh={refreshBoeTable}>
                      <>
                        <GuideMessage
                          message={[
                            `${t(
                              'text:Invoices_missing_the_Payment_Date_or_the_Invoice_Amount_will_not_be_reflected_in_the_payment_information_below',
                            )}`,
                          ]}
                          isImportContentArea={true}
                        />
                        <TableBorder>
                          <TableHeader header={boeTableHeaders} />
                          <TableBody numOfCol={boeTableHeaders.length}>{renderBoeTable()}</TableBody>
                        </TableBorder>
                      </>
                    </PaymentInformationAccordion>
                  </>
                )}
                <div className="flex-end mt-3">
                  <Button
                    size={ButtonSizeEnum.LG}
                    onClick={onClickReject}
                    className="me-2"
                    color={ButtonColorEnum.SECONDARY}
                    variant={ButtonVariantEnum.OUTLINED}
                  >
                    {t('text:Reject')}
                  </Button>
                  <Button size={ButtonSizeEnum.LG} onClick={onClickRegister}>
                    {t('text:Register')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        {invoiceSummaryPhaseDataList.map((invoiceSummaryPhase, index) => (
          <InvoiceSummaryPhaseAccordion
            key={index}
            invoiceSummary={invoiceSummaryData}
            invoiceSummaryPhase={invoiceSummaryPhase}
            onAccordionClick={() => handleInvoiceSummaryPhaseAccordionClick(invoiceSummaryPhase)}
          />
        ))}
        {(userAuthorityType === AUTHORITY_TYPE.OPERATOR || userAuthorityType === AUTHORITY_TYPE.HQ_OPERATOR) &&
          signInModel?.userId === invoiceSummaryData.currentConfirmUserId &&
          !invoiceSummaryData.rejected && (
            <div className="flex-end mt-3">
              <Button
                size={ButtonSizeEnum.LG}
                onClick={onClickReject}
                className="me-2"
                color={ButtonColorEnum.SECONDARY}
                variant={ButtonVariantEnum.OUTLINED}
              >
                {t('text:Cancel_Request')}
              </Button>
            </div>
          )}
      </div>
    </>
  );
}

export default AnchorInvoiceConfirmationDetail;
