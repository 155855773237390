import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { PageableType } from 'hooks/usePageable';
import usePageable from 'hooks/usePageable';
import type { WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import { requestFinancierWaitingAnchorAgreementList } from 'utils/http/api/financier/waiting-anchor-agreements';
import useModal from 'utils/modal/useModal';

type WaitingAgreementStateType = {
  waitingAgreement: WaitingAnchorAgreementVOModel[];
  waitingAgreementPageable: PageableType;
  fetchWaitingAgreement: (anchorAgreementId: number, pageNumber?: number, rowCount?: number) => Promise<void>;
};

const WaitingAgreementContext = createContext<WaitingAgreementStateType | null>(null);

export const useWaitingAgreementContext = () => {
  const context = useContext(WaitingAgreementContext);
  if (!context) {
    throw Error('useWaitingAgreementContext should be used within WaitingAgreementContext.Provider');
  }

  return context;
};

type WaitingAgreementProviderPropsType = {
  children: ReactNode;
};

const WaitingAgreementProvider = ({ children }: WaitingAgreementProviderPropsType) => {
  const { show: showModal } = useModal();

  const [waitingAgreement, setWaitingAgreement] = useState<WaitingAnchorAgreementVOModel[]>([]);
  const { pageable: waitingAgreementPageable, setPageable: setWaitingAgreementPageable } = usePageable();

  const fetchWaitingAgreement = useCallback(
    async (anchorAgreementId: number, pageNumber: number = 0, rowCount: number = 10) => {
      try {
        const waitingAgreement = await requestFinancierWaitingAnchorAgreementList(pageNumber, rowCount, {
          anchorAgreementId,
        });
        setWaitingAgreement([...waitingAgreement.content]);
        setWaitingAgreementPageable(waitingAgreement);
      } catch (error) {
        showModal(error);
      }
    },
    [setWaitingAgreementPageable, showModal],
  );

  const value = useMemo(
    () => ({
      waitingAgreement,
      waitingAgreementPageable,
      fetchWaitingAgreement,
    }),
    [fetchWaitingAgreement, waitingAgreement, waitingAgreementPageable],
  );

  return <WaitingAgreementContext.Provider value={value}>{children}</WaitingAgreementContext.Provider>;
};

export default WaitingAgreementProvider;
