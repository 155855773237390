import type Pageable from 'models/Pageable';
import type {
  WaitingAnchorPartnerDetailVO,
  WaitingAnchorPartnerDetailVOModel,
} from 'models/vo/WaitingAnchorPartnerDetailVO';
import { formattingToWaitingAnchorPartnerDetailVOModel } from 'models/vo/WaitingAnchorPartnerDetailVO';
import type { WaitingAnchorPartnerVO, WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import { formattingToWaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type {
  UpdateAcWaitingAnchorPartnerOnModificationRequest,
  UpdateAcWaitingAnchorPartnerOnRegistrationRequest,
  WaitingAnchorPartnerApproveRequest,
  WaitingAnchorPartnerCancelRequest,
  WaitingAnchorPartnerForModificationPageRequest,
  WaitingAnchorPartnerPageRequest,
  WaitingAnchorPartnerRegistRequest,
  WaitingAnchorPartnerReturnRequest,
} from './requests';

export async function requestWaitingAnchorPartnerPage(
  pageNumber: number,
  rowCount: number,
  data: WaitingAnchorPartnerPageRequest,
): Promise<Pageable<WaitingAnchorPartnerVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorPartnerVO[]>>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.WAITING_ANCHOR_PARTNER,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorPartnerVOModel(data)),
  };
}

export async function requestWaitingAnchorPartnerForModificationPage(
  pageNumber: number,
  rowCount: number,
  data: WaitingAnchorPartnerForModificationPageRequest,
): Promise<Pageable<WaitingAnchorPartnerVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorPartnerVO[]>>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.WAITING_ANCHOR_PARTNER_LIST_FOR_MODIFICATION,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorPartnerVOModel(data)),
  };
}

export async function requestWaitingAnchorPartnerOnRegistrationModify(
  waitingAnchorPartnerId: number,
  data: UpdateAcWaitingAnchorPartnerOnRegistrationRequest,
): Promise<WaitingAnchorPartnerVOModel> {
  const response = await http.put<WaitingAnchorPartnerVO>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.REGISTER_WAITING_ANCHOR_PARTNER(waitingAnchorPartnerId),
    data,
  });

  return formattingToWaitingAnchorPartnerVOModel(response);
}

export async function requestWaitingAnchorPartnerOnModificationModify(
  waitingAnchorPartnerId: number,
  data: UpdateAcWaitingAnchorPartnerOnModificationRequest,
): Promise<WaitingAnchorPartnerVOModel> {
  const response = await http.put<WaitingAnchorPartnerVO>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.MODIFY_WAITING_ANCHOR_PARTNER(waitingAnchorPartnerId),
    data,
  });

  return formattingToWaitingAnchorPartnerVOModel(response);
}

export async function requestWaitingAnchorPartnerApprove(
  data: WaitingAnchorPartnerApproveRequest,
): Promise<Pageable<WaitingAnchorPartnerVOModel[]>> {
  const response = await http.put<Pageable<WaitingAnchorPartnerVO[]>>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.APPROVE_WAITING_ANCHOR_PARTNER,
    data,
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorPartnerVOModel(data)),
  };
}

export async function requestWaitingAnchorPartnerApproveModification(
  waitingAnchorPartnerId: number,
): Promise<WaitingAnchorPartnerVOModel> {
  const response = await http.put<WaitingAnchorPartnerVO>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.APPROVE_WAITING_ANCHOR_PARTNER_MODIFICATION(waitingAnchorPartnerId),
  });

  return formattingToWaitingAnchorPartnerVOModel(response);
}

export async function requestWaitingAnchorPartnerReturn(data: WaitingAnchorPartnerReturnRequest): Promise<void> {
  await http.put({
    url: API_AC.WAITING_ANCHOR_PARTNERS.RETURN_WAITING_ANCHOR_PARTNER,
    data,
  });
}

export async function requestWaitingAnchorPartnerCancel(data: WaitingAnchorPartnerCancelRequest): Promise<{}> {
  const response = await http.put<{}>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.CANCEL_WAITING_ANCHOR_PARTNER,
    data,
  });

  return response;
}

export async function requestWaitingAnchorPartnerRegist(
  data: WaitingAnchorPartnerRegistRequest,
): Promise<Pageable<WaitingAnchorPartnerVOModel[]>> {
  const response = await http.post<Pageable<WaitingAnchorPartnerVO[]>>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.WAITING_ANCHOR_PARTNER,
    data,
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorPartnerVOModel(data)),
  };
}

export async function requestWaitingAnchorPartnerData(
  waitingAnchorPartnerId: number,
): Promise<WaitingAnchorPartnerDetailVOModel> {
  const response = await http.get<WaitingAnchorPartnerDetailVO>({
    url: API_AC.WAITING_ANCHOR_PARTNERS.WAITING_ANCHOR_PARTNER_DETAIL(waitingAnchorPartnerId),
  });

  return formattingToWaitingAnchorPartnerDetailVOModel(response);
}
