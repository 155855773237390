import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import Tab from 'components/stateless/TabManager/Tab';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import WithProvider from 'utils/render';

import ExtraInformationProvider from './extraInformation/Context';
import FinancingOptionProvider from './models/Provider';
import AssociatedAnchorMasterAgreement from './sections/AssociatedAnchorMasterAgreement';
import DisbursementAccount from './sections/DisbursementAccount';
import Financier from './sections/Financier';
import FinancingDuration from './sections/FinancingDuration';
import FinancingLimit from './sections/FinancingLimit';
import FinancingOptionRevisionHistory from './sections/FinancingOptionRevisionHistory';
import GeneralInformation from './sections/GeneralInformation';
import InterestRate from './sections/InterestRate';
import useRegistrationDetailController from './useAnchorFinancingOptionDetailController';

function AnchorFinancingOptionDetail() {
  const { t } = useTranslation();

  const { handleRevisionHistoryTabClick, handleAgreementTabClick, methods } = useRegistrationDetailController();

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Financing_Option_Details')} />
      <Tab
        tabList={[
          {
            tabName: t('text:Anchor_Financing_Option'),
            onClickTab: handleAgreementTabClick,
          },
          {
            tabName: t('text:Revision_History'),
            onClickTab: handleRevisionHistoryTabClick,
          },
        ]}
        tabViewList={[
          <Fragment key="agreement">
            <Form methods={methods} isEditableStyle={false}>
              <div className="content-area">
                <SectionTitle title={t('text:Contracting_Parties')} />
                <Border>
                  <AssociatedAnchorMasterAgreement />
                  <Financier />
                </Border>
              </div>

              <div className="content-area">
                <SectionTitle title={t('text:General_Information')} />
                <Border>
                  <GeneralInformation />
                </Border>
              </div>

              <div className="content-area">
                <SectionTitle title={t('text:Financing_Terms_and_Conditions')} />
                <Border>
                  <FinancingLimit />
                  <InterestRate />
                  <FinancingDuration />
                </Border>
              </div>
              <div className="content-area">
                <SectionTitle title={t('text:Designated_Account')} />
                <Border>
                  <DisbursementAccount />
                </Border>
              </div>
            </Form>
          </Fragment>,

          <FinancingOptionRevisionHistory key="history" />,
        ]}
      />
    </>
  );
}

export default WithProvider({
  Component: AnchorFinancingOptionDetail,
  Provider: [ExtraInformationProvider, FinancingOptionProvider],
});
