import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import useMounted from 'hooks/useMounted';

import SyPartnerAgreementRegisteredDetailInfo from './sections/agreement-info';
import SyPartnerAgreementRegisteredDetailFinancingList from './sections/financing-list';
import SyPartnerAgreementRegisteredDetailInfoEditHistory from './sections/info-edit-history';
import SyPartnerAgreementRegisteredDetailPlatformUsageHistory from './sections/platform-usage-history';
import { useSyPartnerAgreementRegisteredDetailState } from './useSyPartnerAgreementRegisteredDetailState';

function SystemMonitorPartnerAgreementRegisteredDetail(): JSX.Element {
  const mounted = useMounted();
  const { t } = useTranslation(['format']);

  const {
    fetchAll,
    state,
    getSyLoanListPaginate,
    getSyEditHistoryListPaginate,
    financingPageable,
    editHistoryPageable,
  } = useSyPartnerAgreementRegisteredDetailState();

  const { syPartnerAgreementDetail, syAnchorAgreementLimitInfo, syLoanList, syEditHistoryList } = state;

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  return (
    <>
      <BackHeaderTitle title={t('text:Partner_Master_Agreement_Details')} />
      <SyPartnerAgreementRegisteredDetailInfo data={syPartnerAgreementDetail} />
      <SyPartnerAgreementRegisteredDetailPlatformUsageHistory
        partnerAgreementData={syPartnerAgreementDetail}
        anchorAgreementLimitData={syAnchorAgreementLimitInfo}
      />
      <SyPartnerAgreementRegisteredDetailFinancingList
        data={syLoanList}
        pageable={financingPageable}
        paginate={getSyLoanListPaginate}
      />
      <SyPartnerAgreementRegisteredDetailInfoEditHistory
        data={syEditHistoryList}
        pageable={editHistoryPageable}
        paginate={getSyEditHistoryListPaginate}
      />
    </>
  );
}

export default SystemMonitorPartnerAgreementRegisteredDetail;
