import type Pageable from 'models/Pageable';
import type { WaitingAnchorUserVO, WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import { formattingToWaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FinancierWaitingAnchorUserListRequest,
  FinancierWaitingAnchorUserRegisterRequest,
  FinancierWaitingAnchorUserReturnModificationRequest,
} from './request';

export async function requestFinancierWaitingAnchorUserList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierWaitingAnchorUserListRequest,
): Promise<Pageable<WaitingAnchorUserVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorUserVO[]>>({
    url: API_FI.WAITING_ANCHOR_USERS.WAITING_ANCHOR_USERS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  const financierWaitingAnchorUserList = response.content.map(data => formattingToWaitingAnchorUserVOModel(data));

  return {
    ...response,
    content: financierWaitingAnchorUserList,
  };
}

// 앵커 유저 등록/수정 대기
export async function requestWaitingAnchorUserRegistration(
  data: FinancierWaitingAnchorUserRegisterRequest,
): Promise<WaitingAnchorUserVOModel> {
  const response = await http.post<WaitingAnchorUserVO>({
    url: API_FI.WAITING_ANCHOR_USERS.WAITING_ANCHOR_USERS,
    data,
  });

  return formattingToWaitingAnchorUserVOModel(response);
}

// 앵커 유저 등록 대기 상세 조회
export async function requestWaitingAnchorUserDetail(id: number): Promise<WaitingAnchorUserVOModel> {
  const response = await http.get<WaitingAnchorUserVO>({
    url: API_FI.WAITING_ANCHOR_USERS.WAITING_ANCHOR_USER_DETAIL(id),
  });

  return formattingToWaitingAnchorUserVOModel(response);
}

// 앵커 유저 등록 대기 요청 반려
export async function requestWaitingAnchorUserReturn(
  id: number,
  returnReason: string,
): Promise<WaitingAnchorUserVOModel> {
  const response = await http.put<WaitingAnchorUserVO>({
    url: API_FI.WAITING_ANCHOR_USERS.RETURN_USER_MODIFICATION(id),
    data: { returnReason },
  });

  return formattingToWaitingAnchorUserVOModel(response);
}

// 앵커 유저 등록 대기 요청 반려에 대한 수정
export async function requestWaitingAnchorUserReturnModification(
  id: number,
  data: FinancierWaitingAnchorUserReturnModificationRequest,
): Promise<WaitingAnchorUserVOModel> {
  const response = await http.put<WaitingAnchorUserVO>({
    url: API_FI.WAITING_ANCHOR_USERS.WAITING_ANCHOR_USER_DETAIL(id),
    data,
  });

  return formattingToWaitingAnchorUserVOModel(response);
}

// 앵커 유저 등록 대기 요청 승인
export async function requestWaitingAnchorUserApprove(id: number): Promise<WaitingAnchorUserVOModel> {
  const response = await http.put<WaitingAnchorUserVO>({
    url: API_FI.WAITING_ANCHOR_USERS.APPROVE_USER(id),
  });

  return formattingToWaitingAnchorUserVOModel(response);
}

// 앵커 유저 등록 대기 요청 취소
export async function requestWaitingAnchorUserCancel(id: number): Promise<WaitingAnchorUserVOModel> {
  const response = await http.put<WaitingAnchorUserVO>({
    url: API_FI.WAITING_ANCHOR_USERS.CANCEL_USER(id),
  });

  return formattingToWaitingAnchorUserVOModel(response);
}
