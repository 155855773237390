import { useLayoutEffect } from 'react';

import { getZoom, setZoom } from 'utils/storage/LocalStorage';

const useZoomSetterController = () => {
  const ZOOM_OPTIONS = ['80%', '90%', '100%', '110%', '120%', '130%', '140%', '150%'];

  const handleZoomValue = (zoom: string) => {
    setZoom(zoom);
    //@ts-ignore
    document.body.style.zoom = zoom;
  };

  useLayoutEffect(() => {
    handleZoomValue(getZoom() || '100%');

    return () => handleZoomValue('100%');
  }, []);

  const getZoomDropdownOptions = () => {
    return ZOOM_OPTIONS.map(zoom => {
      return {
        text: zoom,
        value: zoom,
        onClick: () => handleZoomValue(zoom),
      };
    });
  };

  return { getZoomDropdownOptions, defaultValue: getZoom() || '100%' };
};

export default useZoomSetterController;
