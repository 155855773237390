import { COLLATERAL_TYPE, CURRENCY_TYPE, SCHEDULE_EVENT_TYPE, stringToEnum } from 'enums';

export interface DashBoardScheduleVO {
  collateralType: string;
  currencyType: string;
  scheduleEventType: string;
  eventDate: string;
  eventId: number;
  eventIdentifier: string;
  financierId: number;
}

export interface DashBoardScheduleVOModel extends DashBoardScheduleVO {
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
  scheduleEventType: SCHEDULE_EVENT_TYPE;
}

export function formattingToDashBoardScheduleVOModel(data: DashBoardScheduleVO): DashBoardScheduleVOModel {
  return {
    ...data,
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    scheduleEventType: stringToEnum(SCHEDULE_EVENT_TYPE, data.scheduleEventType),
  };
}
