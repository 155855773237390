import { useEffect, useState } from 'react';

import { removeRangeFromHoliday } from 'utils/date/date';
import { requestFinancierCalendarList } from 'utils/http/api/financier/financier-calendar';
import useModal from 'utils/modal/useModal';

const useFinancierHolidays = (rowCount: number, fromDate: string, toDate: string) => {
  const { show: showModal } = useModal();
  const [financierHolidays, setFinancierHolidays] = useState<Date[] | []>();

  useEffect(() => {
    (async () => {
      try {
        const financierHolidays = await requestFinancierCalendarList(0, rowCount, {
          fromDate,
          toDate,
          holiday: true,
        });

        setFinancierHolidays(removeRangeFromHoliday(financierHolidays.content));
      } catch (error) {
        showModal(error);
      }
    })();
  }, [fromDate, toDate, showModal]);

  return { financierHolidays };
};

export default useFinancierHolidays;
