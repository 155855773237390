import { useTranslation } from 'react-i18next';

import { EmailNotificationSettingForm } from 'components/email-notification-setting/EmailNotificationSettingForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';

import useFinancierManageDealerUserDetailEmailSettingController from './useFinancierManageDealerUserDetailEmailSettingController';

const FinancierManageDealerUserDetailEmailSetting = () => {
  const { t } = useTranslation();
  const {
    selectedCommonAlertSetting,
    selectedVendorAlertSetting,
    selectedDealerAlertSetting,
    updateEmailSettingHandler,
  } = useFinancierManageDealerUserDetailEmailSettingController();

  return (
    <>
      <BackHeaderTitle title={t('text:Email_Notification_Setting')} />
      <GuideMessage
        message={[
          t('text:You_can_change_the_email_notification_settings_for_a_specific_user'),
          t('text:If_the_setting_is_done_by_the_financier_the_label_Financier_will_appear_in_the_relevant_box'),
        ]}
      />
      {selectedCommonAlertSetting && (
        <div className="content-area">
          <EmailNotificationSettingForm
            title={t('text:Common')}
            emailSettingData={selectedCommonAlertSetting}
            updateEmailSettingHandler={updateEmailSettingHandler}
          />
        </div>
      )}

      {selectedVendorAlertSetting && (
        <div className="content-area">
          <EmailNotificationSettingForm
            title={t('text:Vendor_Finance_Program')}
            emailSettingData={selectedVendorAlertSetting}
            updateEmailSettingHandler={updateEmailSettingHandler}
          />
        </div>
      )}
      {selectedDealerAlertSetting && (
        <div className="content-area">
          <EmailNotificationSettingForm
            title={t('text:Dealer_Finance_Program')}
            emailSettingData={selectedDealerAlertSetting}
            updateEmailSettingHandler={updateEmailSettingHandler}
          />
        </div>
      )}
    </>
  );
};

export default FinancierManageDealerUserDetailEmailSetting;
