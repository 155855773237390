import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES_AC } from 'constants/routes/anchor';
import { ROUTES_DE } from 'constants/routes/dealer';
import { ROUTES_FI } from 'constants/routes/financier';
import { ROUTES_SY } from 'constants/routes/system';
import { ROLE } from 'enums';
import useMounted from 'hooks/useMounted';
import { useSignInStore } from 'store';
import { requestSignOut } from 'utils/http/api/common/signin';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { removeSignIn } from 'utils/storage/LocalStorage';

function useProfileController() {
  const portalContainerRef = useRef<Element | null>(null);
  const myAccountRef = useRef<HTMLDivElement>(null);

  const { removeStoreSignIn, storeSignIn } = useSignInStore();
  const { username, authorityType, authorities } = storeSignIn ?? {};
  const { show: showModal } = useModal();
  const { t } = useTranslation(['format']);
  const history = useHistory();

  const mounted = useMounted();
  const badgeText = username?.charAt(0).toUpperCase();

  useEffect(() => {
    if (!mounted) {
      portalContainerRef.current = document.getElementById('portals-container');
    }
  }, [mounted]);

  const role = (() => {
    switch (authorities?.[0].authority) {
      case ROLE.ROLE_ANCHOR:
        return t('text:Anchor');
      case ROLE.ROLE_DEALER:
        return t('text:Partner');
      case ROLE.ROLE_FINANCIER:
        return t('text:Financier');
      case ROLE.ROLE_SYSTEM:
        return t('text:System');
      default:
        return '-';
    }
  })();

  const handleMyAccountLinkClick = () => {
    switch (authorities?.[0].authority) {
      case ROLE.ROLE_ANCHOR:
        return history.push(ROUTES_AC.MY_ACCOUNT.ACCOUNT_SETTINGS);
      case ROLE.ROLE_DEALER:
        return history.push(ROUTES_DE.MY_ACCOUNT.ACCOUNT_SETTINGS);
      case ROLE.ROLE_FINANCIER:
        return history.push(ROUTES_FI.MY_ACCOUNT);
      case ROLE.ROLE_SYSTEM:
        return history.push(ROUTES_SY.MY_ACCOUNT);
      default:
        return;
    }
  };

  const handleLogOutLinkClick = () => {
    showModal(`${t('text:Are_you_sure_you_want_to_sign_out')}?`, {
      closeBtnText: t('text:Close'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        removeSignIn();
        removeStoreSignIn();
        requestSignOut();
        setTimeout(() => history.push('/'), 0);
      },
      modalType: ModalType.CONFIRM,
    });
  };

  return {
    t,
    badgeText,
    loginUserInfo: { username, authorityType },
    role,
    portalContainerRef,
    myAccountRef,
    mounted,
    handleMyAccountLinkClick,
    handleLogOutLinkClick,
  };
}

export default useProfileController;
