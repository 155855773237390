import type { ReactNode, TdHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { isNil } from 'lodash-es';

import { tableValueManage } from 'utils/valueManager/ValueManager';

import type { TFunctionResult } from 'i18next';

type FormatType = 'number' | 'date' | 'datetime' | 'percent' | 'code';

interface PropsType extends TdHTMLAttributes<HTMLElement> {
  data?: string | number | TFunctionResult | null;
  className?: string;
  format?: FormatType;
  left?: ReactNode;
  right?: ReactNode;
}

const Td = ({ className = '', data, children, format, left, right, ...props }: PropsType) => {
  const { t } = useTranslation(['format']);

  const formatData = (value: any, format?: FormatType) => {
    switch (format) {
      case 'date':
        return t('format:date', {
          value: value,
          key: 'date',
        });
      case 'datetime':
        return t('format:datetime', {
          value: value,
          key: 'datetime',
        });
      case 'number':
        if (typeof value === 'number' || typeof value === 'string') return t('format:number', { value: value });
        else return value;
      case 'percent':
        if (typeof value === 'number') {
          return `${t('format:number', { value: value })}%`;
        } else {
          return value;
        }
      case 'code':
        const status = value.split('.');

        return status[1] === 'undefined' || status[1] === 'null' ? '-' : value;
      default:
        return tableValueManage(data);
    }
  };

  return children ? (
    <td className={className} {...props}>
      {children}
    </td>
  ) : (
    <td
      {...props}
      className={clsx({ [className]: className, 'text-end': format === 'number' || format === 'percent' })}
    >
      {!isNil(data) && left && `${left} `}
      {data?.toString() ? formatData(data, format) : tableValueManage(data)}
      {!isNil(data) && right && ` ${right}`}
    </td>
  );
};

export default Td;
