import { groupBy } from 'lodash-es';

import { AUTHORITY_TYPE, FINANCIER_ALERT_TYPE, ROLE } from 'enums';
import type { UserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import { emptyObjectToNull } from 'utils/object';
import { getSignIn } from 'utils/storage/LocalStorage';

import type { UserEmailSettingGroupedMap } from './types';
import type { UserEmailSettingSubDivisionType, UserEmailSettingTypeFE } from './types';

// TODO type, type 에러 나서 임시 처리
const USER_EMAIL_ALERT_DATA_WITH_ORDER_SUBDIVISION: {
  [key in string]: { order: number; subDivision: UserEmailSettingSubDivisionType };
} = {
  [FINANCIER_ALERT_TYPE.ANCHOR_AGREEMENT_MODIFICATION]: {
    order: 1,
    subDivision: 'COMMON',
  },
  [FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_MODIFICATION]: {
    order: 2,
    subDivision: 'COMMON',
  },
  [FINANCIER_ALERT_TYPE.ANCHOR_AGREEMENT_EXPIRATION]: {
    order: 3,
    subDivision: 'COMMON',
  },
  [FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_EXPIRATION]: {
    order: 4,
    subDivision: 'COMMON',
  },
  [FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_REGISTRATION]: {
    order: 5,
    subDivision: 'PARTNER_MANAGEMENT',
  },
  [FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_PA_OP]: {
    order: 6,
    subDivision: 'AR_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_PA_AU]: {
    order: 7,
    subDivision: 'AR_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_AC_OP]: {
    order: 8,
    subDivision: 'AR_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.AR_REGISTERED]: {
    order: 9,
    subDivision: 'AR_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.AR_UPCOMING_SETTLEMENT_DATE]: {
    order: 10,
    subDivision: 'AR_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.AR_LOAN_CREATED]: {
    order: 11,
    subDivision: 'AR_LOAN',
  },
  [FINANCIER_ALERT_TYPE.AR_LOAN_REQUESTED]: {
    order: 12,
    subDivision: 'AR_LOAN',
  },
  [FINANCIER_ALERT_TYPE.AR_LOAN_APPROVED_BY_FI_OP]: {
    order: 13,
    subDivision: 'AR_LOAN',
  },
  [FINANCIER_ALERT_TYPE.AR_LOAN_APPROVED_BY_FI_AU]: {
    order: 14,
    subDivision: 'AR_LOAN',
  },
  [FINANCIER_ALERT_TYPE.AR_LOAN_DISBURSED]: {
    order: 15,
    subDivision: 'AR_LOAN',
  },
  [FINANCIER_ALERT_TYPE.AR_LOAN_REJECTED]: {
    order: 16,
    subDivision: 'AR_LOAN',
  },
  [FINANCIER_ALERT_TYPE.AR_LOAN_REPAYMENT_DATE]: {
    order: 17,
    subDivision: 'AR_LOAN',
  },
  [FINANCIER_ALERT_TYPE.AR_EARLY_LOAN_REPAYMENT_REQUEST]: {
    order: 18,
    subDivision: 'AR_LOAN',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_PA_OP]: {
    order: 19,
    subDivision: 'INVOICE_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_PA_AU]: {
    order: 20,
    subDivision: 'INVOICE_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_AC_OP]: {
    order: 21,
    subDivision: 'INVOICE_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_AC_AU]: {
    order: 22,
    subDivision: 'INVOICE_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_FI_OP]: {
    order: 23,
    subDivision: 'INVOICE_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_REGISTRED]: {
    order: 24,
    subDivision: 'INVOICE_REGISTRATION',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_LOAN_CREATED]: {
    order: 25,
    subDivision: 'INVOICE_LOAN',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_LOAN_REQUESTED]: {
    order: 26,
    subDivision: 'INVOICE_LOAN',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_LOAN_APPROVED_BY_FI_OP]: {
    order: 27,
    subDivision: 'INVOICE_LOAN',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_LOAN_APPROVED_BY_FI_AU]: {
    order: 28,
    subDivision: 'INVOICE_LOAN',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_LOAN_DISBURSED]: {
    order: 29,
    subDivision: 'INVOICE_LOAN',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_LOAN_REJECTED]: {
    order: 30,
    subDivision: 'INVOICE_LOAN',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_LOAN_REPAYMENT_DATE]: {
    order: 31,
    subDivision: 'INVOICE_LOAN',
  },
  [FINANCIER_ALERT_TYPE.INVOICE_EARLY_LOAN_REPAYMENT_REQUEST]: {
    order: 32,
    subDivision: 'INVOICE_LOAN',
  },
};

export const classifyUserEmailSettingAlertOptions = (
  alertSettingResponse: UserEmailSettingVOModel[],
  factoringEnable: boolean,
) => {
  const isAnchor = (getSignIn()?.authorities[0].authority as ROLE) === ROLE.ROLE_ANCHOR;
  const isAcAdmin = isAnchor && getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;

  const setReadonly = (alertType: FINANCIER_ALERT_TYPE) => {
    return (
      isAcAdmin &&
      factoringEnable &&
      (alertType === FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_REGISTRATION ||
        alertType === FINANCIER_ALERT_TYPE.AR_LOAN_APPROVED_BY_FI_AU)
    );
  };

  const classifySettingItem: UserEmailSettingTypeFE[] = alertSettingResponse.map(item => {
    return {
      ...item,
      readonly: setReadonly(item.alertType),
      order: USER_EMAIL_ALERT_DATA_WITH_ORDER_SUBDIVISION[item.alertType].order,
      subDivision: USER_EMAIL_ALERT_DATA_WITH_ORDER_SUBDIVISION[item.alertType].subDivision,
    };
  });

  const sortedAlertSettingResponse = classifySettingItem.sort((a, b) => a.order - b.order);
  const groupedAlertSettingData = groupBy(sortedAlertSettingResponse, 'subDivision') as UserEmailSettingGroupedMap;

  const commonAlertSettingList = groupedAlertSettingData['COMMON'] ? { COMMON: groupedAlertSettingData['COMMON'] } : {};
  const vendorPartnerManagementAlertSettingList = groupedAlertSettingData['PARTNER_MANAGEMENT']
    ? { PARTNER_MANAGEMENT: groupedAlertSettingData['PARTNER_MANAGEMENT'] }
    : {};
  const vendorARRegistrationAlertSettingList = groupedAlertSettingData['AR_REGISTRATION']
    ? { AR_REGISTRATION: groupedAlertSettingData['AR_REGISTRATION'] }
    : {};
  const vendorFinancingAlertSettingList = groupedAlertSettingData['AR_LOAN']
    ? { AR_LOAN: groupedAlertSettingData['AR_LOAN'] }
    : {};
  const dealerInvoiceRegistrationAlertSettingList = groupedAlertSettingData['INVOICE_REGISTRATION']
    ? { INVOICE_REGISTRATION: groupedAlertSettingData['INVOICE_REGISTRATION'] }
    : {};
  const dealerFinancingAlertSettingList = groupedAlertSettingData['INVOICE_LOAN']
    ? { INVOICE_LOAN: groupedAlertSettingData['INVOICE_LOAN'] }
    : {};

  return {
    commonFormList: emptyObjectToNull(commonAlertSettingList),
    vendorFormList: emptyObjectToNull({
      ...vendorPartnerManagementAlertSettingList,
      ...vendorARRegistrationAlertSettingList,
      ...vendorFinancingAlertSettingList,
    }),
    dealerFormList: emptyObjectToNull({
      ...dealerInvoiceRegistrationAlertSettingList,
      ...dealerFinancingAlertSettingList,
    }),
  };
};
