import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';

import AnchorBulkFinancingConfirmationListTable from './components/AnchorBulkFinancingConfirmationListTable';
import { useAnchorBulkFinancingConfirmationListController } from './useAnchorBulkFinancingConfirmationListController';

function AnchorBulkFinancingConfirmationList() {
  const { t } = useTranslation(['format']);

  const {
    waitingTabUtils: { bulkFinancingWaitingList, bulkFinancingWaitingPageable, multipleRequestLoanWaitingListPaginate },
    completedTabUtils: {
      bulkFinancingCompletedList,
      bulkFinancingCompletedPageable,
      multipleRequestLoanCompletedListPaginate,
    },
  } = useAnchorBulkFinancingConfirmationListController();

  return (
    <>
      <HeaderTitle title={t('text:Financing_Confirmation')} />
      <GuideMessage
        message={[
          `${t(
            'text:The_In_Progress_tab_displays_financing_applications_that_have_not_yet_been_approved_by_the_financier',
          )} ${t('text:Review_and_approve_the_financing_in_the_Application_Created_status_submitted')}`,
          `${t(
            'text:The_Completed_tab_shows_financing_applications_that_have_been_approved_rejected_or_canceled_by_the_financier',
          )}
          ${t('text:Check_the_details_on_the_detail_page')}`,
        ]}
      />
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:In_Progress'),
            tabItemCount: bulkFinancingWaitingList.totalElements ?? 0,
          },
          {
            tabName: t('text:Completed'),
            tabItemCount: bulkFinancingCompletedList.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <AnchorBulkFinancingConfirmationListTable
            key="waiting"
            bulkFinancingConfirmationListData={bulkFinancingWaitingList.content}
            pageable={bulkFinancingWaitingPageable}
            paginate={multipleRequestLoanWaitingListPaginate}
          />,
          <AnchorBulkFinancingConfirmationListTable
            key="complete"
            bulkFinancingConfirmationListData={bulkFinancingCompletedList.content}
            pageable={bulkFinancingCompletedPageable}
            paginate={multipleRequestLoanCompletedListPaginate}
          />,
        ]}
      />
    </>
  );
}

export default AnchorBulkFinancingConfirmationList;
