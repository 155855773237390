import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Notification from 'components/stateless/Notification/Notification';

import { useNotificationButtonController } from './useNotificationButtonController';

const NotificationButton = () => {
  const {
    role,
    isNotificationOpen,
    hasNewNotification,
    handleNotificationButtonClick,
    closeNotification,
    updateHasNewNotification,
  } = useNotificationButtonController();

  return (
    <>
      <button className="header-bar__button icon-bell" onClick={handleNotificationButtonClick}>
        <FontAwesomeIcon icon={faBell} fontSize={16} />
        {hasNewNotification && <span className="new-notification-alert-badge" />}
      </button>
      {role && (
        <Notification
          isVisible={isNotificationOpen}
          closeNotification={closeNotification}
          updateHasNewNotification={updateHasNewNotification}
        />
      )}
    </>
  );
};

export default NotificationButton;
