import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import type { COLLATERAL_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';

export enum PAGE_TYPE {
  REGISTER,
  WAITING_DETAIL,
  REGISTERED_DETAIL,
}

type ExtraInformationStateType = {
  supportedCollateralType: COLLATERAL_TYPE;
  errorHandlerOfLocationState: () => boolean;
  pageType: PAGE_TYPE;
  anchorFinancingOptionId: number;
  waitingAnchorFinancingOptionId: number;
};

const ExtraInformationContext = createContext<ExtraInformationStateType | null>(null);

export const useExtraInformationContext = () => {
  const context = useContext(ExtraInformationContext);
  if (!context) {
    throw Error('useExtraInformationContext should be used within ExtraInformationContext.Provider');
  }

  return context;
};

type ExtraInformationProviderPropsType = {
  children: ReactNode;
};

const ExtraInformationProvider = ({ children }: ExtraInformationProviderPropsType) => {
  const [{ supportedCollateralType }, errorHandlerOfLocationState] = useLocationState<{
    supportedCollateralType: COLLATERAL_TYPE;
  }>(['supportedCollateralType']);

  const { anchorFinancingOptionId, waitingAnchorFinancingOptionId } = useParams() as any;

  const pageType = useMemo(() => {
    if (!isNil(anchorFinancingOptionId)) return PAGE_TYPE.REGISTERED_DETAIL;
    if (!isNil(waitingAnchorFinancingOptionId)) return PAGE_TYPE.WAITING_DETAIL;
    else return PAGE_TYPE.REGISTER;
  }, [anchorFinancingOptionId, waitingAnchorFinancingOptionId]);

  const value = useMemo(
    () => ({
      supportedCollateralType,
      errorHandlerOfLocationState,
      pageType,
      anchorFinancingOptionId,
      waitingAnchorFinancingOptionId,
    }),
    [
      supportedCollateralType,
      errorHandlerOfLocationState,
      pageType,
      anchorFinancingOptionId,
      waitingAnchorFinancingOptionId,
    ],
  );

  return <ExtraInformationContext.Provider value={value}>{children}</ExtraInformationContext.Provider>;
};

export default ExtraInformationProvider;
