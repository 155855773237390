import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { HeaderType } from 'components/stateless/Table/TableHeader';

import useAccountTransactionsViewModel from '../../../models/accountTransactions/useAccountTransactionsViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useDesignatedAccountTransactionHistoryController = () => {
  const { t } = useTranslation(['format']);
  const { useDepositTransaction, useAgreementInterface, anchorAgreementId } = useExtraInformationViewModel();
  const {
    settlementAccountBalance,
    accountTransactions,
    accountTransactionsPageable,
    fetchSettlementAccountBalance,
    fetchAccountTransactions,
  } = useAccountTransactionsViewModel();

  const TRANSACTION_HISTORY_LIST_HEADERS: HeaderType[] = [
    { headerText: t('text:Transaction_Time') },
    { headerText: t('text:Memo') },
    { headerText: t('text:Deposit') },
    { headerText: t('text:Withdrawal') },
  ];

  const resetSection = useCallback(async () => {
    if (!anchorAgreementId) return;

    if (useDepositTransaction) {
      await fetchAccountTransactions(anchorAgreementId);
    }

    if (useAgreementInterface) {
      await fetchSettlementAccountBalance(anchorAgreementId);
    }
  }, [anchorAgreementId]);

  useEffect(() => {
    resetSection();
  }, [resetSection]);

  return {
    useDepositTransaction,
    useAgreementInterface,
    settlementAccountBalance,
    accountTransactions,
    accountTransactionsPageable,
    fetchSettlementAccountBalance,
    fetchAccountTransactions,
    TRANSACTION_HISTORY_LIST_HEADERS,
  };
};

export default useDesignatedAccountTransactionHistoryController;
