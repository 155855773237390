import { useTranslation } from 'react-i18next';

// 실패-입력 형식 및 유효성 오류 모달
function ValidationErrorModal() {
  const { t } = useTranslation();

  return (
    <h6>
      {t('text:The_list_of_invoices_to_upload_contains_errors')}
      <br />
      {t('text:Please_check_and_press_the_register_button_again')}
    </h6>
  );
}

export default ValidationErrorModal;
