import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import { Content, Row } from 'components/templates/section';
import { ANCHOR_AGREEMENT_STATUS } from 'enums';

const GeneralInformation = () => {
  const { t } = useTranslation(['format']);

  return (
    <Content>
      <Row>
        <Form.Control name="contractNo">
          <Form.Label>{t('text:Sub-Contract_Number')}</Form.Label>
          <Form.Input />
        </Form.Control>
      </Row>
      <Row>
        <Form.Control name="anchorAgreementStatus">
          <Form.Label position="top">{t('text:Option_Activation')}</Form.Label>
          <Form.Radio>
            <Form.Option value={ANCHOR_AGREEMENT_STATUS.ACTIVATED} label={t('text:Activate')} />
            <Form.Option value={ANCHOR_AGREEMENT_STATUS.SUSPENDED} label={t('text:Deactivate')} />
          </Form.Radio>
        </Form.Control>
      </Row>
    </Content>
  );
};

export default GeneralInformation;
