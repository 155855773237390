import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { InvoicePaymentVOModel } from 'models/vo/InvoicePaymentVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemInvoicePaymentList } from 'utils/http/api/system/invoice-payments';
import type { SearchSystemInvoicePaymentListRequest } from 'utils/http/api/system/invoice-payments/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const SEARCHED_SYSTEM_INVOICE_PAYMENT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:BOE_Number'),
    },
    {
      headerText: t('text:Payment_Date'),
    },
    {
      headerText: t('text:Number_of_Invoices'),
    },
    {
      headerText: t('text:Payment_Amount'),
    },
  ];

  const SY_INVOICE_PAYMENT_LIST_QS_KEY = 'sy-invoice-payment-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_SYSTEM_INVOICE_PAYMENT_LIST_TABLE_HEADERS,
    SY_INVOICE_PAYMENT_LIST_QS_KEY,
  };
};

function SystemInvoicePaymentList() {
  const { mounted, modal, t, SEARCHED_SYSTEM_INVOICE_PAYMENT_LIST_TABLE_HEADERS, SY_INVOICE_PAYMENT_LIST_QS_KEY } =
    getConstants();

  const {
    register: systemInvoicePaymentSearchFormRegister,
    getValues: getSystemInvoicePaymentSearchFormValues,
    reset: resetSystemInvoicePaymentSearchFormValue,
    setValue: setSystemInvoicePaymentSearchFormValue,
    control: systemInvoicePaymentSearchFormControl,
  } = useForm<SearchSystemInvoicePaymentListRequest>();

  const [systemInvoicePaymentPage, setSystemInvoicePaymentPage] = useState<Pageable<InvoicePaymentVOModel[]>>();
  const getSystemInvoicePaymentProperty = useProperty<SearchSystemInvoicePaymentListRequest>();
  const { pageable: systemInvoicePaymentPageable, setPageable: setSystemInvoicePaymentPageable } =
    usePageable(SY_INVOICE_PAYMENT_LIST_QS_KEY);

  const location = useLocation<SearchSystemInvoicePaymentListRequest>();
  const filteredOptions = location.state;

  useEffect(() => {
    if (mounted) {
      setFormValues<SearchSystemInvoicePaymentListRequest>(
        setSystemInvoicePaymentSearchFormValue,
        filteredOptions ?? getParsedSearchParams(SY_INVOICE_PAYMENT_LIST_QS_KEY).formSearchData,
      );
      fetchSyInvoicePaymentList(
        systemInvoicePaymentPageable.currentPage,
        systemInvoicePaymentPageable.sizePerPage,
        getSystemInvoicePaymentSearchFormValues(),
      );
    }
  }, [mounted]);

  const fetchSyInvoicePaymentList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SearchSystemInvoicePaymentListRequest,
  ) => {
    try {
      const systemInvoicePaymentPageData = await requestSystemInvoicePaymentList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_INVOICE_PAYMENT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemInvoicePaymentPage(systemInvoicePaymentPageData);
        setSystemInvoicePaymentPageable(systemInvoicePaymentPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    resetSystemInvoicePaymentSearchFormValue();
  };

  const onClickSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    fetchSyInvoicePaymentList(1, systemInvoicePaymentPageable.sizePerPage, getSystemInvoicePaymentSearchFormValues());
  };

  const systemInvoicePaymentPaginate = (pageNumber: number, rowCount: number) => {
    fetchSyInvoicePaymentList(
      pageNumber,
      rowCount,
      getParsedSearchParams(SY_INVOICE_PAYMENT_LIST_QS_KEY).formSearchData,
    );
  };

  const renderSearchedSystemInvoicePaymentTableBody = () => {
    return systemInvoicePaymentPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.financierName} />
        <Td data={item.anchorName} />
        <Td data={item.anchorAgreementContractNo} />
        <Td data={item.partnerName} />
        <Td data={item.partnerAgreementContractNo} />
        <Td className="text-center" data={item.currencyType} />
        <Td data={item.billOfExchangeNo} />
        <Td data={item.settlementDate} format="date" />
        <Td className="text-end" data={item.totalCount} format="number" />
        <Td className="text-end" data={item.totalAmount} format="number" />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Invoice_Payment')} />
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput
                name={getSystemInvoicePaymentProperty('financierName')}
                ref={systemInvoicePaymentSearchFormRegister}
              />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput
                name={getSystemInvoicePaymentProperty('anchorName')}
                ref={systemInvoicePaymentSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput
                name={getSystemInvoicePaymentProperty('anchorAgreementContractNo')}
                ref={systemInvoicePaymentSearchFormRegister}
              />
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput
                name={getSystemInvoicePaymentProperty('partnerName')}
                ref={systemInvoicePaymentSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
              <SearchInput
                name={getSystemInvoicePaymentProperty('partnerAgreementContractNo')}
                ref={systemInvoicePaymentSearchFormRegister}
              />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                ref={systemInvoicePaymentSearchFormRegister}
                name={getSystemInvoicePaymentProperty('currencyType')}
                selectOptions={getSelectOptions('CURRENCY_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:BOE_Number')} />
              <SearchInput
                name={getSystemInvoicePaymentProperty('billOfExchangeNo')}
                ref={systemInvoicePaymentSearchFormRegister}
              />
              <SearchLabel label={t('text:Payment_Date')} />
              <SearchDatePicker
                name={getSystemInvoicePaymentProperty('paymentDateFrom')}
                placeholder={t('text:from')}
                control={systemInvoicePaymentSearchFormControl}
              />
              <SearchDatePicker
                name={getSystemInvoicePaymentProperty('paymentDateTo')}
                placeholder={t('text:to')}
                control={systemInvoicePaymentSearchFormControl}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')} : {systemInvoicePaymentPageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_SYSTEM_INVOICE_PAYMENT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_SYSTEM_INVOICE_PAYMENT_LIST_TABLE_HEADERS.length}>
            {renderSearchedSystemInvoicePaymentTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={systemInvoicePaymentPageable} paginate={systemInvoicePaymentPaginate} />
      </div>
    </>
  );
}

export default SystemInvoicePaymentList;
