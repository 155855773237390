import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button2ColorEnum } from 'components/stateless/Button2/Button2';
import IconButton2, { IconButtonSizeEnum } from 'components/stateless/Button2/IconButton2';

// 기존 데이터
export interface UploadDocumentDataType {
  description: string;
  attachmentName: string;
}

export interface UploadDocumentFormDataType {
  description: string;
  attachment: FileList;
  attachmentChanged: boolean;
}

export interface UploadDocumentModalProps {
  documentData?: UploadDocumentDataType;
  formData: UploadDocumentFormDataType;
  type: 'register' | 'update';
}

function UploadDocumentModal({ documentData, formData, type }: UploadDocumentModalProps) {
  const { t } = useTranslation();
  const { reset, getValues, watch, register, setValue } = useForm<UploadDocumentFormDataType>();

  const [fileName, setFileName] = useState<string | undefined>();
  const { attachmentChanged, description, attachment } = watch();

  useEffect(() => {
    if (documentData) {
      reset({
        description: documentData.description,
        attachmentChanged: false,
      });

      setFileName(documentData.attachmentName);
    }
  }, [documentData, reset]);

  useEffect(() => {
    Object.assign(formData, getValues());
  }, [attachmentChanged, description, attachment, formData, getValues]);

  const handleOnchange = (e: any) => {
    e.preventDefault();

    if (e.target?.files[0]?.name) {
      setFileName(e.target.files[0].name);

      if (type === 'update') setValue('attachmentChanged', true);
    }
  };

  const handleClickRemoveIcon = (e: any): void => {
    e.preventDefault();
    setValue('attachment', null);
    setFileName(undefined);

    if (type === 'update') setValue('attachmentChanged', true);
  };

  return (
    <div className="pp-upload-document-modal">
      <div className="w-50">
        {type === 'update' && <input type="hidden" name="attachmentChanged" ref={register} />}
        <div className="file-input-form d-flex align-items-center">
          <input
            type="file"
            id="pp-attachment"
            name="attachment"
            className="d-none"
            onChange={handleOnchange}
            ref={register}
          />
          <label htmlFor="pp-attachment">{t('text:Attach_File')}</label>
          <div id="fileName" className="file-input-form__name">
            {fileName ? (
              <span className="file-input-form__name">{fileName}</span>
            ) : (
              <span className="file-input-form__name--no-attached">{t('text:No_file_attached')}</span>
            )}
          </div>
          {fileName && (
            <IconButton2
              icon={<FontAwesomeIcon icon={faXmark} fontSize={12} />}
              onClick={handleClickRemoveIcon}
              size={IconButtonSizeEnum.XS}
              color={Button2ColorEnum.GRAY_SCALE}
            />
          )}
        </div>
      </div>
      <div className="w-50">
        <input
          type="text"
          className="pp-file-description-input"
          name="description"
          placeholder={t('text:Please_note_here')}
          ref={register}
        />
      </div>
    </div>
  );
}

export default UploadDocumentModal;
