import { DOCUMENT_FORM_TYPE, stringToEnum } from 'enums';

import { formattingToLoanAdditionalDocumentConditionVOModel } from './LoanAdditionalDocumentConditionVO';

import type {
  LoanAdditionalDocumentConditionVO,
  LoanAdditionalDocumentConditionVOModel,
} from './LoanAdditionalDocumentConditionVO';

export interface LoanAdditionalDocumentVO {
  financierDocumentFormId: number;
  description: string;
  documentFormName: string;
  documentFormType: string;
  fileName: string;
  loanAdditionalDocumentId: number;
  noticeForPartner: string;
  activated: boolean;
  attachmentId: number;
  mandatoryCondition: LoanAdditionalDocumentConditionVO;
  hardCopyCondition: LoanAdditionalDocumentConditionVO;
}

export interface LoanAdditionalDocumentVOModel extends LoanAdditionalDocumentVO {
  documentFormType: DOCUMENT_FORM_TYPE;
  mandatoryCondition: LoanAdditionalDocumentConditionVOModel;
  hardCopyCondition: LoanAdditionalDocumentConditionVOModel;
}

export function formattingToLoanAdditionalDocumentVOModel(data: LoanAdditionalDocumentVO) {
  return {
    ...data,
    documentFormType: stringToEnum(DOCUMENT_FORM_TYPE, data.documentFormType),
    mandatoryCondition: formattingToLoanAdditionalDocumentConditionVOModel(data.mandatoryCondition),
    hardCopyCondition: formattingToLoanAdditionalDocumentConditionVOModel(data.hardCopyCondition),
  };
}
