import { BANK_CODE_APPROVAL_TYPE, stringToEnum } from 'enums';

export interface WaitingBankCodeVO {
  id: number;
  bankCode: string;
  branchCode: string;
  bankName: string;
  branchName: string;
  deleted: boolean;
  approvalType: string;
  rejectReason: string;
  targetBankCodeId: number;
  targetBankCode: string;
  targetBranchCode: string;
  targetBankName: string;
  targetBranchName: string;
  operatorUserId: number;
  operatorUserLoginId: string;
  operatorUserName: string;
  updatedDateTime: string;
  operatorEnterpriseName: string;
  financierId: number;
  financierName: string;
}

export interface WaitingBankCodeVOModel extends WaitingBankCodeVO {
  approvalType: BANK_CODE_APPROVAL_TYPE;
}

export function formattingToWaitingBankCodeVOModel(data: WaitingBankCodeVO): WaitingBankCodeVOModel {
  return {
    ...data,
    approvalType: stringToEnum(BANK_CODE_APPROVAL_TYPE, data.approvalType),
  };
}
