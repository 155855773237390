import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { AUTHORITY_TYPE, MULTIPLE_LOAN_APPROVAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { MultipleAnchorLoanRequestDetailVOModel } from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

interface FinancierBulkAnchorFinancingDetailGuideMessageProps {
  bulkFinancingDetailInfo: MultipleAnchorLoanRequestDetailVOModel;
  submitBulkFinancingCancel(): Promise<void>;
  submitFinancingRejectionApprove(): Promise<void>;
  submitFinancingRejectionRequest(reason: string): Promise<void>;
  submitBulkFinancingReturn(reason: string): Promise<void>;
}

function FinancierBulkAnchorFinancingDetailGuideMessage({
  bulkFinancingDetailInfo,
  submitBulkFinancingCancel,
  submitBulkFinancingReturn,
  submitFinancingRejectionRequest,
  submitFinancingRejectionApprove,
}: FinancierBulkAnchorFinancingDetailGuideMessageProps) {
  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation();
  const modal = useModal();
  const { approvalType, multipleLoanRequestStatus, rejectReason, returnReason } = bulkFinancingDetailInfo;

  const handleOperatorBulkFinancingCancelConfirmButtonClick = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_approval_request?')}
        <br />
        {t('text:Cancelled_requests_cannot_be_reversed_and_must_be_requested_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Cancel_Financing_Request'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          await submitBulkFinancingCancel();
        },
      },
    );
  };

  const handleOperatorBulkFinancingCancelRejectionRequestButtonClick = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_rejection_request?')}
        <br />
        {t('text:Cancelled_requests_cannot_be_reversed_and_must_be_requested_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Cancel_Rejection_Request'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          await submitBulkFinancingCancel();
        },
      },
    );
  };

  const handleOperatorBulkFinancingCancelFinancingApprovalClick = () => {
    let reason = '';

    modal.show(
      <h6>
        {t('text:Would_you_like_to_request_the_Authorizers_confirmation_to_cancel_the_financing_approval?')}
        <br />
        {t(
          'text:By_cancelling_the_approval_the_financing_status_will_change_to_Rejected_and_the_financing_application_will_have_to_be_resubmitted',
        )}
        <br />
        {t('text:Please_enter_the_reason_for_the_rejection_below')}
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Financing_Rejection_Request'),
        confirmBtnCb: async () => {
          await submitFinancingRejectionRequest(reason);
        },
      },
    );
  };

  const handleAuthorizerBulkFinancingConfirmRejectionRequestButtonClick = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_confirm_the_rejection_request?')}
        <br />
        {t('text:The_rejection_cannot_be_reverted_and_the_financing_application_will_have_to_be_resubmitted')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Confirm_Rejection_Request'),
        confirmBtnCb: async () => {
          await submitFinancingRejectionApprove();
        },
      },
    );
  };

  const handleAuthorizerBulkFinancingRevertRejectionRequestButtonClick = () => {
    let reason = '';

    modal.show(
      <h6>
        {t('text:Would_you_like_to_revert_the_financing_rejection_request?')}
        <br />
        {t('text:Please_enter_the_reason_for_the_revert_below')}
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Revert_Rejection_Request'),
        confirmBtnCb: async () => {
          await submitBulkFinancingReturn(reason);
        },
      },
    );
  };

  const OperatorMessage = () => {
    if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPLIED) {
      switch (approvalType) {
        case MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST:
          return (
            <GuideMessage
              message={[
                t('text:The_request_for_the_Authorizer_s_financing_approval_has_been_submitted'),
                t('text:To_cancel_the_approval_request_click_on_the_Cancel_Confirm_Request_on_the_right'),
              ]}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleOperatorBulkFinancingCancelConfirmButtonClick}
                  >
                    {t('text:Cancel_Confirm_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        case MULTIPLE_LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_approval_request_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleOperatorBulkFinancingCancelConfirmButtonClick}
                  >
                    {t('text:Cancel_Confirm_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        default:
          return (
            <GuideMessage
              message={[
                t('text:Check_the_financing_request_information_below_and_proceed_with_confirmation'),
                t('text:Click_on_the_Confirm_button_to_request_approval_from_the_Financier_Authorizer'),
              ]}
            />
          );
      }
    } else if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPROVED) {
      switch (approvalType) {
        case MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_rejection_has_been_requested'),
                t('text:To_cancel_the_request_please_click_on_the_Cancel_Rejection_Request_button_on_the_right'),
              ]}
              reason={bulkFinancingDetailInfo.rejectReason}
              reasonTitle={t('text:REASON_FOR_REJECTION')}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleOperatorBulkFinancingCancelRejectionRequestButtonClick}
                  >
                    {t('text:Cancel_Rejection_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        case MULTIPLE_LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED:
          return (
            <GuideMessage
              message={[
                t('text:The_financing_rejection_request_has_been_reverted'),
                t('text:Please_check_the_reason_for_revert_below'),
              ]}
              reason={bulkFinancingDetailInfo.returnReason}
              reasonTitle={t('text:REASON_FOR_REJECTION')}
              messageType={MessageType.ALERT}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleOperatorBulkFinancingCancelRejectionRequestButtonClick}
                  >
                    {t('text:Cancel_Rejection_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        case null:
          return (
            <GuideMessage
              message={[
                t('text:The_financing_application_has_been_approved'),
                t('text:The_bulk_approval_process_has_been_completed') +
                  ' ' +
                  t('text:Check_each_financing_details_pages_for_details'),
                t(
                  'text:If_the_financing_cannot_be_disbursed_and_the_approval_must_be_cancelled_click_on_the_Cancel_Financing_Approval_button_on_the_right',
                ),
              ]}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleOperatorBulkFinancingCancelFinancingApprovalClick}
                  >
                    {t('text:Cancel_Financing_Approval')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        default:
          return null;
      }
    } else if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.REJECTED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_application_has_been_rejected'),
            t('text:Check_the_reason_for_the_rejection_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={rejectReason}
        />
      );
    } else return null;
  };

  const AuthorizerMessage = () => {
    if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPLIED) {
      switch (approvalType) {
        case MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST:
          return (
            <GuideMessage message={[t('text:The_request_for_the_Authorizers_financing_approval_has_been_submitted')]} />
          );
        case MULTIPLE_LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_approval_request_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            />
          );
        default:
          return null;
      }
    } else if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPROVED) {
      switch (approvalType) {
        case null:
          return (
            <GuideMessage message={[t('text:The_request_for_the_Authorizers_financing_approval_has_been_submitted')]} />
          );
        case MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_rejection_has_been_requested'),
                t('text:Click_on_the_buttons_to_the_right_to_confirm_or_revert_the_financing_rejection_request'),
              ]}
              reason={bulkFinancingDetailInfo.rejectReason}
              reasonTitle={t('text:REASON_FOR_REJECTION')}
            >
              {{
                button: (
                  <>
                    <Button onClick={handleAuthorizerBulkFinancingConfirmRejectionRequestButtonClick}>
                      {t('text:Confirm_Rejection_Request')}
                    </Button>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={handleAuthorizerBulkFinancingRevertRejectionRequestButtonClick}
                    >
                      {t('text:Revert_Rejection_Request')}
                    </Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        case MULTIPLE_LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_rejection_request_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reason={bulkFinancingDetailInfo.returnReason}
              reasonTitle={t('text:REASON_FOR_REJECTION')}
            />
          );
        default:
          return null;
      }
    } else if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.REJECTED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_application_has_been_rejected'),
            t('text:Check_the_reason_for_the_rejection_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={rejectReason}
        />
      );
    } else return null;
  };

  const AdminMessage = () => {
    if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPROVED) {
      switch (approvalType) {
        case MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_rejection_has_been_requested'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reason={bulkFinancingDetailInfo.rejectReason}
              reasonTitle={t('text:REASON_FOR_REJECTION')}
            />
          );
        case MULTIPLE_LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_rejection_request_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reason={bulkFinancingDetailInfo.returnReason}
              reasonTitle={t('text:REASON_FOR_REJECTION')}
            />
          );
        default:
          return null;
      }
    } else return null;
  };

  return (
    <>
      {signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR && <OperatorMessage />}
      {signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER && <AuthorizerMessage />}
      {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && <AdminMessage />}
    </>
  );
}

export default FinancierBulkAnchorFinancingDetailGuideMessage;
