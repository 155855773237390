import {
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  CURRENCY_TYPE,
  ENTERPRISE_TYPE,
  PARTNER_STATUS,
  stringToEnum,
} from 'enums';

export interface WaitingAnchorPartnerDetailVO {
  waitingAnchorPartnerId: number;
  partnerBusinessCode: string;
  partnerTaxCode: string;
  name: string;
  address: string;
  telephone: string;
  fax: string;
  representativeName: string;
  representativeEmail: string;
  representativePosition: string;
  anchorPartnerStatus: string;
  approvalType: string;
  returnReason: string;
  account: string;
  accountOwner: string;
  requestedAccountBankName: string;
  requestedAccountBranchName: string;
  accountBankCodeId: number;
  accountBankName: string;
  accountBankCode: string;
  accountBranchName: string;
  accountBranchCode: string;
  anchorAgreementId: number;
  anchorAgreementContractNo: string;
  anchorClientName: string;
  collateralType: string;
  currencyType: string;
  paymentSupport: boolean;
  responsibleFinancierId: number;
  responsibleFinancierName: string;
  responsibleBranchName: string;
  responsibleBranchCode: string;
  operatorUserId: number;
  operatorUserLoginId: string;
  operatorUserName: string;
  operatorEnterpriseId: number;
  operatorEnterpriseType: string;
  operatorEnterpriseName: string;
  authorizerUserId: number;
  authorizerUserLoginId: string;
  authorizerUserName: string;
  authorizerEnterpriseId: number;
  authorizerEnterpriseType: string;
  authorizerEnterpriseName: string;
  targetAnchorPartnerId: number;
  createdDateTime: string;
  updatedDateTime: string;
}

export interface WaitingAnchorPartnerDetailVOModel extends WaitingAnchorPartnerDetailVO {
  anchorPartnerStatus: PARTNER_STATUS;
  approvalType: COMMON_APPROVAL_TYPE;
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
  operatorEnterpriseType: ENTERPRISE_TYPE;
  authorizerEnterpriseType: ENTERPRISE_TYPE;
}

export function formattingToWaitingAnchorPartnerDetailVOModel(
  data: WaitingAnchorPartnerDetailVO,
): WaitingAnchorPartnerDetailVOModel {
  return {
    ...data,
    anchorPartnerStatus: stringToEnum(PARTNER_STATUS, data.anchorPartnerStatus),
    approvalType: stringToEnum(COMMON_APPROVAL_TYPE, data.approvalType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    operatorEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.operatorEnterpriseType),
    authorizerEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.authorizerEnterpriseType),
  };
}
