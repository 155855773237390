import { every, isEmpty } from 'lodash-es';
import qs from 'qs';

import { browserHistory } from 'router/browserHistory';
import type { NewObject } from 'types';

const ParamsRelatedMSW: NewObject = {
  msw: 'MSW',
  error: 'ERROR',
  empty: 'EMPTY',
  pass: 'PASS',
};

const getParamsRelatedMSW = (query: string) => {
  const searchParams = new URLSearchParams(query);
  const mswParam = Array.from(searchParams.entries()).filter(([key]) => ParamsRelatedMSW[key]);

  if (isEmpty(mswParam)) return '';

  return mswParam && `&${new URLSearchParams(mswParam).toString()}`;
};

const removeParamsRelatedMSW = (query: qs.ParsedQs) => {
  for (const [key] of Object.entries(ParamsRelatedMSW)) {
    delete query[key];
  }
};

export const makeSearchParamsPattern = (query: any, qsKey: string): string => {
  const searchQueries: string = location.search;
  const numberOfQsKey = (searchQueries.match(/qsKey/g) || []).length;
  const { pageNumber, rowCount, ...formSearchData } = query;

  if (numberOfQsKey === 0 && every(formSearchData, isEmpty) && (isNaN(pageNumber) || isNaN(rowCount))) return '';

  const searchObj: NewObject = {};
  for (const key in query) {
    if (query.hasOwnProperty(key) && query[key]) {
      searchObj[key] = query[key];
    }
  }

  const qsResult = qs.stringify(searchObj);

  return `?qsKey=${qsKey}&${qsResult}`;
};

export const addSearchParams = (query: any) => {
  const history = browserHistory;
  const mswParams = getParamsRelatedMSW(location.search);
  history.replace({ search: `${query}${mswParams}` });
};

export const updateSearchParams = (query: any, qsKey: string) => {
  const searchQueries: string = location.search;
  const history = browserHistory;

  const newParams = makeSearchParamsPattern(query, qsKey);
  const mswParams = getParamsRelatedMSW(location.search);

  if (!searchQueries.includes(`qsKey=${qsKey}`)) {
    history.replace({ search: `${searchQueries && `${searchQueries}`}${newParams}${mswParams}` });
  } else {
    // 파라미터로 들어온 qsKey 에 해당하는 쿼리 삭제
    const deletedPrevQs = searchQueries
      .split('?')
      .filter(item => !item.includes(qsKey))
      .join('?');

    // make new
    history.replace({ search: `${deletedPrevQs}${newParams && `${newParams}`}${mswParams}` });
  }
};

const getQueriesByQsKey = (): string[] => {
  const searchQueries: string = location.search;

  return searchQueries.split('?qsKey=').filter(item => item); // result :: ['qsKey1'&someting , 'qsKey2&something2']
};

export const getFormSearchParamValue = (parameterKey: string): string => {
  const allSearchQueries = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  removeParamsRelatedMSW(allSearchQueries);
  const { pageNumber, rowCount, ...formSearchQueries } = allSearchQueries;

  return formSearchQueries[parameterKey] as string;
};

export const getParsedSearchParams = (qsKey: string): { formSearchData: any; pageNumber: number; rowCount: number } => {
  const getQueries = getQueriesByQsKey()
    .filter(item => item.includes(qsKey))
    .join('')
    .split(`${qsKey}&`)
    .join('');
  const allQsParsedResult = qs.parse(getQueries);
  removeParamsRelatedMSW(allQsParsedResult);
  const { pageNumber, rowCount, ...formSearchData } = allQsParsedResult;

  return {
    formSearchData,
    pageNumber: Number(pageNumber),
    rowCount: Number(rowCount),
  };
};
