import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormBorder, FormContents, FormInput, FormSubtitle } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { CreateFinancierAndAdminUserDTO } from 'utils/http/api/anonymous/on-demand/requests';

type FinancierInfoFormType = Pick<
  CreateFinancierAndAdminUserDTO,
  | 'financierName'
  | 'businessCode'
  | 'taxCode'
  | 'address'
  | 'telephone'
  | 'fax'
  | 'representativeName'
  | 'representativeMobile'
  | 'representativeEmail'
>;

const FinancierInformation = () => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext<FinancierInfoFormType>();

  const renderGeneralInformationForm = (
    <>
      <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:General_Information')} />
      <FormContents>
        <div className="row">
          <FormInput
            label={t('text:Financier_Name')}
            name="financierName"
            requiredOptions={{ required: true }}
            ref={register}
            error={errors.financierName}
          />
          <FormInput
            label={t('text:Tax_Code')}
            name="taxCode"
            requiredOptions={{ required: true }}
            ref={register}
            error={errors.taxCode}
          />
        </div>
        <div className="row">
          <FormInput
            label={t('text:Company_Registration_Number')}
            name="businessCode"
            ref={register}
            error={errors.businessCode}
          />
          <FormInput
            col={3}
            label={t('text:Telephone')}
            name="telephone"
            requiredOptions={{ required: true }}
            ref={register}
            error={errors.telephone}
          />
          <FormInput col={3} label={t('text:Fax')} name="fax" ref={register} error={errors.fax} />
        </div>
        <div className="row">
          <FormInput
            col={12}
            label={t('text:Registered_Office_Address')}
            name="address"
            requiredOptions={{ required: true }}
            ref={register}
            error={errors.address}
          />
        </div>
      </FormContents>
    </>
  );

  const renderLegalRepresentativeInformationForm = (
    <>
      <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Legal_Representative_Information')} />
      <FormContents>
        <div className="row">
          <FormInput
            label={t('text:Name')}
            name="representativeName"
            requiredOptions={{ required: true }}
            ref={register}
            error={errors.representativeName}
          />
          <FormInput
            label={t('text:Telephone')}
            name="representativeMobile"
            ref={register}
            error={errors.representativeMobile}
          />
        </div>
        <div className="row">
          <FormInput
            label={t('text:Email')}
            name="representativeEmail"
            ref={register}
            error={errors.representativeEmail}
          />
        </div>
      </FormContents>
    </>
  );

  return (
    <>
      <SectionTitle title={t('text:Financier_Information')} />
      <FormBorder editable>
        {renderGeneralInformationForm}
        {renderLegalRepresentativeInformationForm}
      </FormBorder>
    </>
  );
};

export default FinancierInformation;
