interface PPTextFieldLabelProps {
  label: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

function PPTextFieldLabel({ label, className = '', children }: PPTextFieldLabelProps) {
  return (
    <div className={`pp-text-field__label ${className}`}>
      {label}
      {children}
    </div>
  );
}

export default PPTextFieldLabel;
