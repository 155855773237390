import { useTranslation } from 'react-i18next';

import Form, { SizeType } from 'components/stateless/Form';
import { Border, Content, Row, SubTitle } from 'components/templates/section';
import { LOAN_LIMIT_CHECK_TYPE } from 'enums';

import useFinancingLimitController from './useFinancingLimitController';

const FinancingLimit = () => {
  const { t } = useTranslation();
  const { currencyType, loanLimitCheckType, handleFinancingLimitCheckPointChange } = useFinancingLimitController();

  return (
    <div>
      <SubTitle>{t('text:FINANCING_LIMIT')}</SubTitle>
      <Content>
        <Row>
          <Form.Control name="loanLimitCheckType" required>
            <Form.Label position="top">{t('text:Financing_Limit')}</Form.Label>
            <Form.Radio onChange={handleFinancingLimitCheckPointChange}>
              <Form.Option
                label={t('text:Applied')}
                value={LOAN_LIMIT_CHECK_TYPE.LOAN}
                tooltipOptions={{
                  id: 'loanLimitCheckType-Applied',
                  content: t('text:The_limit_will_be_recovered_when_the_financing_is_repaid'),
                }}
                render={(isParentReadOnly, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Form.Control name="loanLimitAmount" required>
                          <Form.Label>{t('text:Financing_Limit_Amount')}</Form.Label>
                          <Form.NumberInput
                            currencyType={currencyType}
                            leftUnit={currencyType}
                            textAlign="text-right"
                            fieldSize={SizeType.MD}
                            numberType="bigNumber"
                            readOnly={isParentRadioUnChecked}
                            parentReadOnly={isParentReadOnly}
                            showError={loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.LOAN}
                          />
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
              <Form.Option label={t('text:Not_Applied')} value={LOAN_LIMIT_CHECK_TYPE.NONE} />
            </Form.Radio>
          </Form.Control>
        </Row>
      </Content>
    </div>
  );
};

export default FinancingLimit;
