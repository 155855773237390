import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { USER_STATUS } from 'enums';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface SyPartnerAgreementRegisteredDetailAnchorUserInfoProps {
  data: AnchorUserVOModel[];
}

function SyPartnerAgreementRegisteredDetailAnchorUserInfo({
  data,
}: SyPartnerAgreementRegisteredDetailAnchorUserInfoProps): JSX.Element {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const ANCHOR_USER_INFO_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Mobile'),
      colWidths: 100,
    },
    {
      headerText: t('text:Email'),
      colWidths: 100,
    },
    {
      headerText: t('text:Authority'),
      colWidths: 100,
    },
    {
      headerText: t('text:User_Account_Status'),
      colWidths: 120,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickUserStatus,
    },
    {
      headerText: t('text:Invitation_Email'),
      colWidths: 120,
    },
  ];

  const renderAnchorUserInformationTable = (): ReactNode => {
    return data.map((item, index) => (
      <Tr key={index}>
        <Td data={item.bankUserId} />
        <Td data={item.name} />
        <Td data={item.mobile} />
        <Td data={item.email} />
        <Td data={t(`code:authority-type.${item.authorityType}`)} format="code" />
        <Td data={t(`code:user-status.${item.userStatus}`)} format="code" />
        <Td className="text-center">
          <Button disabled>{t('text:Send')}</Button>
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_USER_INFORMATION')} />
      <FormContents>
        <div className="row">
          <div className="col-12">
            <TableBorder>
              <TableHeader header={ANCHOR_USER_INFO_TABLE_HEADERS} />
              <TableBody numOfCol={ANCHOR_USER_INFO_TABLE_HEADERS.length}>
                {renderAnchorUserInformationTable()}
              </TableBody>
            </TableBorder>
          </div>
        </div>
      </FormContents>
    </>
  );
}

export default SyPartnerAgreementRegisteredDetailAnchorUserInfo;
