import { POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS, stringToEnum } from 'enums';

export interface PotentialPartnerFinancingApplicationDocumentVO {
  applicationDocumentId: number;
  applicationDocumentUpdatedDateTime: string;
  applicationDocumentDescription: string;
  applicationDocumentStatus: string;
  applicationAttachmentName: string;
  acquisitionDocumentId: number;
  acquisitionDocumentName: string;
  acquisitionDocumentDescription: string;
  acquisitionAttachmentId: number;
  acquisitionAttachmentFileName: string;
}

export interface PotentialPartnerFinancingApplicationDocumentVOModel
  extends PotentialPartnerFinancingApplicationDocumentVO {
  applicationDocumentStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS;
}

export function formattingToPotentialPartnerFinancingApplicationDocumentVOModel(
  data: PotentialPartnerFinancingApplicationDocumentVO,
): PotentialPartnerFinancingApplicationDocumentVOModel {
  return {
    ...data,
    applicationDocumentStatus: stringToEnum(
      POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS,
      data.applicationDocumentStatus,
    ),
  };
}
