import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder, FormContents, FormSelect } from 'components/stateless/CommonForm';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, REPORT_RECEIVER_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorReportSettingVOModel } from 'models/vo/AnchorReportSettingVO';
import type { AnchorReportVOModel } from 'models/vo/AnchorReportVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierAnchorAgreementData } from 'utils/http/api/financier/anchor-agreements';
import {
  requestFiAnchorReportSettingData,
  requestFiAnchorReportSettingUpdate,
} from 'utils/http/api/financier/anchor-report-settings';
import type { FiAnchorReportSettingUpdateRequest } from 'utils/http/api/financier/anchor-report-settings/requests';
import { requestAnchorReportDownload, requestAnchorReportList } from 'utils/http/api/financier/anchor-reports';
import type { AnchorReportListRequest } from 'utils/http/api/financier/anchor-reports/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

const getConstants = () => {
  const { t } = useTranslation(['format']);

  const ANCHOR_USER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:User_Name'),
      colWidths: 140,
    },
    {
      headerText: t('text:Email'),
      colWidths: 120,
    },
    {
      headerText: t('text:Authority'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registration_Status'),
      colWidths: 100,
    },
  ];

  const REPORT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Created_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Sent_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Email'),
      colWidths: 260,
    },
    {
      headerText: '',
      colWidths: 100,
    },
  ];

  const REPORT_RECEIVER_TYPE_ANCHOR_USER: REPORT_RECEIVER_TYPE.ANCHOR_USER = REPORT_RECEIVER_TYPE.ANCHOR_USER;

  return {
    t,
    ANCHOR_USER_TABLE_HEADERS,
    REPORT_LIST_TABLE_HEADERS,
    REPORT_RECEIVER_TYPE_ANCHOR_USER,
  };
};

function FinancierAnchorReportDetail(): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t, ANCHOR_USER_TABLE_HEADERS, REPORT_LIST_TABLE_HEADERS, REPORT_RECEIVER_TYPE_ANCHOR_USER } = getConstants();
  const { anchorAgreementId } = useParams() as any;
  const { anchorReportSettingId } = useParams() as any;
  const { reportType } = useParams() as any;
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementDetailVOModel>();
  const [reportSettingEditable, setReportSettingEditable] = useState<boolean>(false);
  const [anchorReportSettingData, setAnchorReportSettingData] = useState<AnchorReportSettingVOModel>();
  const [anchorReportPageData, setAnchorReportPageData] = useState<Pageable<AnchorReportVOModel[]>>();
  const { pageable: anchorReportPageable, setPageable: setAnchorReportPageable } = usePageable();
  const {
    register: anchorReportSettingRegister,
    getValues: getAnchorReportSettingValues,
    reset: anchorReportSettingReset,
    setError: setAnchorReportSettingError,
    errors: anchorReportSettingErrors,
    clearErrors: clearAnchorReportSettingErrors,
  } = useForm<FiAnchorReportSettingUpdateRequest>();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [anchorReportSettingResponse, anchorAgreementDetailResponse, anchorReportPageResponse] = await Promise.all([
        requestFiAnchorReportSettingData(anchorReportSettingId),
        requestFinancierAnchorAgreementData(anchorAgreementId),
        requestAnchorReportList<AnchorReportListRequest>(1, 10, {
          anchorAgreementId,
          reportType,
          reportReceiverType: REPORT_RECEIVER_TYPE_ANCHOR_USER,
        }),
      ]);

      anchorReportSettingReset({ activated: anchorReportSettingResponse.activated });

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorReportSettingData(anchorReportSettingResponse);
        setAnchorAgreementData(anchorAgreementDetailResponse);
        setAnchorReportPageData(anchorReportPageResponse);
        setAnchorReportPageable(anchorReportPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickCancel = (e: any): void => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_information_modification')}
        <br />
        {t('text:The_information_will_not_be_saved_if_modification_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => {
          setReportSettingEditable(false);

          anchorReportSettingReset({ activated: anchorReportSettingData?.activated });
        },
      },
    );
  };

  const onClickSave = async (e: any): Promise<void> => {
    e.preventDefault();

    try {
      const data = getAnchorReportSettingValues();

      await requestFiAnchorReportSettingUpdate(anchorReportSettingId, data);

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          setReportSettingEditable(false);

          fetchAll();
        },
      });
    } catch (error) {
      modal.show(error);
      formErrorHandler<FiAnchorReportSettingUpdateRequest>(
        error,
        setAnchorReportSettingError,
        clearAnchorReportSettingErrors,
      );
    }
  };

  const onClickEdit = (e: any): void => {
    e.preventDefault();

    setReportSettingEditable(true);
  };

  const onClickGoToAgreementDetails = (e: any): void => {
    e.preventDefault();

    history.push(ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(anchorAgreementId), {
      supportedCollateralType: anchorAgreementData?.collateralType,
    });
  };

  const paginateAnchorReport = async (selectedPageNumber: number, selectedRowCount: number): Promise<void> => {
    try {
      const anchorReportPageResponse = await requestAnchorReportList<AnchorReportListRequest>(
        selectedPageNumber,
        selectedRowCount,
        {
          anchorAgreementId,
          reportType,
          reportReceiverType: REPORT_RECEIVER_TYPE_ANCHOR_USER,
        },
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorReportPageData(anchorReportPageResponse);
        setAnchorReportPageable(anchorReportPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderAnchorUserInformationTable = (): JSX.Element[] | undefined => {
    const getAuthorityTypeText = (data?: AUTHORITY_TYPE): string => {
      switch (data) {
        case AUTHORITY_TYPE.OPERATOR:
          return t('text:Operator');
        case AUTHORITY_TYPE.AUTHORIZER:
          return t('text:Authorizer');
        case AUTHORITY_TYPE.HQ_OPERATOR:
          return t('text:Admin_Operator');
        case AUTHORITY_TYPE.ADMIN:
          return t('text:Admin');
        default:
          return '';
      }
    };

    return anchorAgreementData?.anchorUserList.map((item, index) => (
      <Tr key={index}>
        <Td data={item.anchorClientCode} />
        <Td data={item.anchorClientName} />
        <Td data={item.email} />
        <Td data={getAuthorityTypeText(item.authorityType)} />
        <Td
          className={getStatusTextClass('USER_STATUS', item?.userStatus)}
          data={t(`code:user-status.${item.userStatus}`)}
        />
      </Tr>
    ));
  };

  const renderReportPageTable = (): JSX.Element[] | undefined => {
    const onClickDownload = async (e: any, item: AnchorReportVOModel): Promise<void> => {
      e.preventDefault();

      try {
        await requestAnchorReportDownload(item.anchorReportId);
      } catch (error) {
        modal.show(error);
      }
    };

    return anchorReportPageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.sent ? item.updatedDateTime : ''} format="datetime" />
        <Td data={item.sentEmail} />
        <Td className="text-center">
          <DownloadButton disabled={!item.sent} onClick={e => onClickDownload(e, item)} />
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Report')} />
      <div className="content-area">
        <SectionTitle title={t('text:Report_Setting')}>
          {isAdmin ? (
            reportSettingEditable ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button size={ButtonSizeEnum.SM} onClick={onClickEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            )
          ) : undefined}
        </SectionTitle>
        <FormBorder editable={reportSettingEditable}>
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Report_Type')}
                value={t(`code:report-type.${anchorReportSettingData?.reportType}`)}
                format="code"
              />
              <FormSelect
                name="activated"
                label={t('text:Report_Status')}
                ref={anchorReportSettingRegister}
                selectOptions={getSelectOptions<string>('SEND_REPORT', 'ALL')}
                placeholderOptions={{ show: true }}
                disabled={!reportSettingEditable}
                error={anchorReportSettingErrors.activated}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Report_Receiver')}>
          <div className="flex-end align-items-center">
            <span className="me-1">{t('text:Go_to_Agreement_Details')}</span>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              className="flex-column-center"
              onClick={onClickGoToAgreementDetails}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </SectionTitle>
        <FormBorder>
          <FormSubtitle title={t('text:Anchor_Agreement_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Master_Agreement_Number')} value={anchorAgreementData?.contractNo} />
              <FormValue label={t('text:Client_Code')} value={anchorAgreementData?.financierClientCode} />
            </div>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={anchorAgreementData?.financierClientName} />
              <FormValue col={3} label={t('text:Currency')} value={anchorAgreementData?.currencyType} />
              <FormValue
                col={3}
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${anchorAgreementData?.collateralType}`)}
                format="code"
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Agreement_Status')}
                value={t(`code:anchor-agreement-status.${anchorAgreementData?.anchorAgreementStatus}`)}
                format="code"
              />
              <FormValue
                col={3}
                label={t('text:Effective_Date')}
                format="date"
                value={anchorAgreementData?.startDate}
              />
              <FormValue
                col={3}
                label={t('text:Expiration_Date')}
                format="date"
                value={anchorAgreementData?.expiryDate}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Anchor_User_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <TableBorder>
              <TableHeader header={ANCHOR_USER_TABLE_HEADERS} />
              <TableBody numOfCol={ANCHOR_USER_TABLE_HEADERS.length}>{renderAnchorUserInformationTable()}</TableBody>
            </TableBorder>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Report')} />
        <TableBorder>
          <TableHeader header={REPORT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={REPORT_LIST_TABLE_HEADERS.length}>{renderReportPageTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={anchorReportPageable} paginate={paginateAnchorReport} />
      </div>
    </>
  );
}

export default FinancierAnchorReportDetail;
