import type { SignInModel, SignInResponse } from 'models/SignInModel';
import { formattingToSignInModel } from 'models/SignInModel';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

export async function requestSystemLogin(userId: number): Promise<SignInModel> {
  const response = await http.post<SignInResponse>({
    url: API_SY.LOGIN.LOGIN,
    data: { userId },
  });

  return formattingToSignInModel(response);
}
