import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DeletedArVOModel } from 'models/vo/DeletedArVO';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorDeletedArsList } from 'utils/http/api/anchor/deleted-ars';
import type { AnchorDeletedARsListRequest } from 'utils/http/api/anchor/deleted-ars/requests';
import { requestAnchorSuccessArsList } from 'utils/http/api/anchor/success-ars';
import type { AnchorSuccessArsListRequest } from 'utils/http/api/anchor/success-ars/requests';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

export const AC_REGISTERED_AP_LIST_QS_KEY = 'ac-registered-ap-list';
export const AC_DELETED_AP_LIST_QS_KEY = 'ac-deleted-ap-list';

const useAnchorApListController = () => {
  const mounted = useMounted();
  const modal = useModal();

  const [successApPage, setSuccessApPage] = useState<Pageable<SuccessArVOModel[]>>();
  const [deletedApPage, setDeletedApPage] = useState<Pageable<DeletedArVOModel[]>>();

  const { pageable: registeredListPageable, setPageable: setRegisteredListPageable } =
    usePageable(AC_REGISTERED_AP_LIST_QS_KEY);
  const { pageable: deletedListPageable, setPageable: setDeletedListPageable } = usePageable(AC_DELETED_AP_LIST_QS_KEY);

  const registeredAPSearchForm = useForm<AnchorSuccessArsListRequest>();
  const deletedAPSearchForm = useForm<AnchorDeletedARsListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<AnchorSuccessArsListRequest>(
        registeredAPSearchForm.setValue,
        getParsedSearchParams(AC_REGISTERED_AP_LIST_QS_KEY).formSearchData,
      );
      setFormValues<AnchorDeletedARsListRequest>(
        deletedAPSearchForm.setValue,
        getParsedSearchParams(AC_DELETED_AP_LIST_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  const constructAndAddSearchParams = () => {
    const registeredTabQs = makeSearchParamsPattern(
      {
        ...registeredAPSearchForm.getValues(),
        pageNumber: registeredListPageable.currentPage,
        rowCount: registeredListPageable.sizePerPage,
      },
      AC_REGISTERED_AP_LIST_QS_KEY,
    );

    const deletedTabQs = makeSearchParamsPattern(
      {
        ...deletedAPSearchForm.getValues(),
        pageNumber: deletedListPageable.currentPage,
        rowCount: deletedListPageable.sizePerPage,
      },
      AC_DELETED_AP_LIST_QS_KEY,
    );

    addSearchParams(registeredTabQs + deletedTabQs);
  };

  const fetchAll = async (): Promise<void> => {
    try {
      const [registeredList, deletedList] = await Promise.all([
        requestAnchorSuccessArsList(
          registeredListPageable.currentPage,
          registeredListPageable.sizePerPage,
          registeredAPSearchForm.getValues(),
        ),
        requestAnchorDeletedArsList(
          deletedListPageable.currentPage,
          deletedListPageable.sizePerPage,
          deletedAPSearchForm.getValues(),
        ),
      ]);

      constructAndAddSearchParams();
      setSuccessApPage(registeredList);
      setRegisteredListPageable(registeredList);
      setDeletedApPage(deletedList);
      setDeletedListPageable(deletedList);
    } catch (error) {
      modal.show(error);
    }
  };

  async function fetchAnchorRegisteredAPList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: AnchorSuccessArsListRequest,
  ) {
    try {
      const anchorSuccessArsPage = await requestAnchorSuccessArsList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        AC_REGISTERED_AP_LIST_QS_KEY,
      );

      setSuccessApPage(anchorSuccessArsPage);
      setRegisteredListPageable(anchorSuccessArsPage);
    } catch (e) {
      modal.show(e);
    }
  }

  const handleClickRegisteredAPSearch = async () => {
    await fetchAnchorRegisteredAPList(0, registeredListPageable.sizePerPage, registeredAPSearchForm.getValues());
  };

  const paginateRegisteredAPList = async (page: number, sizePerPage: number) => {
    await fetchAnchorRegisteredAPList(
      page,
      sizePerPage,
      getParsedSearchParams(AC_REGISTERED_AP_LIST_QS_KEY).formSearchData,
    );
  };

  async function fetchAnchorDeletedAPList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: AnchorDeletedARsListRequest,
  ) {
    try {
      const anchorDeletedArsPage = await requestAnchorDeletedArsList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        AC_DELETED_AP_LIST_QS_KEY,
      );

      setDeletedListPageable(anchorDeletedArsPage);
      setDeletedApPage(anchorDeletedArsPage);
    } catch (e) {
      modal.show(e);
    }
  }

  const handleClickDeletedAPSearch = async () => {
    await fetchAnchorDeletedAPList(0, deletedListPageable.sizePerPage, deletedAPSearchForm.getValues());
  };

  const paginateDeletedAPList = async (page: number, sizePerPage: number) => {
    await fetchAnchorDeletedAPList(page, sizePerPage, getParsedSearchParams(AC_DELETED_AP_LIST_QS_KEY).formSearchData);
  };

  return {
    successApPage,
    deletedApPage,
    registeredAPSearchForm,
    deletedAPSearchForm,
    registeredListPageable,
    deletedListPageable,
    handleClickRegisteredAPSearch,
    paginateRegisteredAPList,
    handleClickDeletedAPSearch,
    paginateDeletedAPList,
  };
};

export default useAnchorApListController;
