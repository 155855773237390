import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { FINANCIER_CLIENT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemFinancierClientList } from 'utils/http/api/system/financier-clients';
import type { SystemFinancierClientListRequest } from 'utils/http/api/system/financier-clients/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const SEARCHED_ANCHOR_COMPANY_PAGE_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Client_Code'),
    },
    {
      headerText: t('text:Anchor_Tax_Code'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_ANCHOR_COMPANY_LIST_QS_KEY = 'sy-anchor-company-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_ANCHOR_COMPANY_PAGE_TABLE_HEADERS,
    SY_ANCHOR_COMPANY_LIST_QS_KEY,
  };
};

function SystemMonitorAnchorCompanyList(): JSX.Element {
  const { mounted, modal, t, SEARCHED_ANCHOR_COMPANY_PAGE_TABLE_HEADERS, SY_ANCHOR_COMPANY_LIST_QS_KEY } =
    getConstants();

  const {
    register: anchorCompanySearchFormRegister,
    getValues: getAnchorCompanySearchFormValues,
    reset: resetAnchorCompanySearchForm,
    setValue: setValueAnchorCompanySearchForm,
  } = useForm<SystemFinancierClientListRequest>();

  const [anchorCompanyPage, setAnchorCompanyPage] = useState<Pageable<FinancierClientVOModel[]>>();
  const { pageable: anchorCompanyPageable, setPageable: setAnchorCompanyPageable } =
    usePageable(SY_ANCHOR_COMPANY_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemFinancierClientListRequest>(
        setValueAnchorCompanySearchForm,
        getParsedSearchParams(SY_ANCHOR_COMPANY_LIST_QS_KEY).formSearchData,
      );
      fetchAnchorCompanyList(
        anchorCompanyPageable.currentPage,
        anchorCompanyPageable.sizePerPage,
        getAnchorCompanySearchFormValues(),
      );
    }
  }, [mounted]);

  const fetchAnchorCompanyList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemFinancierClientListRequest,
  ): Promise<void> => {
    try {
      const anchorCompanyPage = await requestSystemFinancierClientList(pageNumber, rowCount, {
        ...data,
        financierClientType: FINANCIER_CLIENT_TYPE.ANCHOR,
      });

      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_ANCHOR_COMPANY_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorCompanyPage(anchorCompanyPage);
        setAnchorCompanyPageable(anchorCompanyPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    resetAnchorCompanySearchForm();
  };

  const onClickSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    await fetchAnchorCompanyList(1, anchorCompanyPageable.sizePerPage, getAnchorCompanySearchFormValues());
  };

  const anchorCompanyPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    await fetchAnchorCompanyList(
      pageNumber,
      rowCount,
      getParsedSearchParams(SY_ANCHOR_COMPANY_LIST_QS_KEY).formSearchData,
    );
  };

  const renderSearchedAnchorCompanyPageTableBody = (): JSX.Element[] | undefined => {
    return anchorCompanyPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.financierName} />
        <Td data={item.financierClientName} />
        <Td data={item.financierClientCode} />
        <Td data={item.financierClientTaxCode} />
        <TdLink path={ROUTES_SY.MONITOR_ANCHOR.COMPANY_DETAIL_BUILD_PATH(item.financierClientId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Anchor_List')} />
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={anchorCompanySearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="financierClientName" ref={anchorCompanySearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name="financierClientCode" ref={anchorCompanySearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Tax_Code')} />
              <SearchInput name="financierClientTaxCode" ref={anchorCompanySearchFormRegister} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {anchorCompanyPageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_ANCHOR_COMPANY_PAGE_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_ANCHOR_COMPANY_PAGE_TABLE_HEADERS.length}>
            {renderSearchedAnchorCompanyPageTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={anchorCompanyPageable} paginate={anchorCompanyPaginate} />
      </div>
    </>
  );
}

export default SystemMonitorAnchorCompanyList;
