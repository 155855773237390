import { useTranslation } from 'react-i18next';

import { languageType } from 'enums';
import { LANGUAGE_SELECT_OPTIONS, getLanguageText, handleLanguageSelectChange } from 'locales/languageSettings';
import { getLanguage } from 'utils/storage/LocalStorage';

const useLanguageSetterController = () => {
  const { i18n } = useTranslation();
  const currentLanguage = (getLanguage() as languageType) || languageType.EN_USA;

  const HEADER_BAR_LANGUAGE_SETTER_OPTIONS = LANGUAGE_SELECT_OPTIONS.map(option => ({
    text: option.text,
    value: option.text,
    onClick: () => handleLanguageSelectChange(option.value),
  }));

  return {
    i18n,
    HEADER_BAR_LANGUAGE_SETTER_OPTIONS,
    defaultLanguage: getLanguageText(currentLanguage),
  };
};

export default useLanguageSetterController;
