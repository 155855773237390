import type { ReactNode } from 'react';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import useOutsideClick from 'hooks/useOutsideClick';

import './HeaderBarDropDown.scss';
import { HeaderBarDropDownContext, useHeaderBarDropDownContext } from './HeaderBarDropDownContext';

import type { HeaderBarDropDownValueType } from './HeaderBarDropDownContext';

interface HeaderBarDropDownRootPropsType {
  children: ReactNode;
  value: HeaderBarDropDownValueType;
}

const HeaderBarDropDownRoot = ({ children, value }: HeaderBarDropDownRootPropsType) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<HeaderBarDropDownValueType>(value);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(() => setOpened(false), dropDownRef);

  const contextValue = {
    opened,
    setOpened,
    currentValue,
    setCurrentValue,
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <HeaderBarDropDownContext.Provider value={contextValue}>
      <div
        ref={dropDownRef}
        className={clsx('header-bar__dropdown-wrapper', {
          active: opened,
        })}
      >
        {children}
      </div>
    </HeaderBarDropDownContext.Provider>
  );
};

type TriggerPropsType = {
  className?: string;
  children?: ReactNode;
};

const Trigger = ({ children, className }: TriggerPropsType) => {
  const { setOpened, currentValue } = useHeaderBarDropDownContext();

  return (
    <div
      className={clsx('header-bar__dropdown__trigger', [className])}
      onClick={() => {
        setOpened(prevState => !prevState);
      }}
    >
      <span className="header-bar__dropdown__selected">{currentValue}</span>
      {children}
    </div>
  );
};

type ItemsPropsType = {
  items: { text: string; value: HeaderBarDropDownValueType; onClick: React.MouseEventHandler<HTMLDivElement> }[];
  textAlign?: 'center' | 'end' | 'start';
};

const Items = ({ items, textAlign }: ItemsPropsType) => {
  const { opened, setOpened, setCurrentValue, currentValue } = useHeaderBarDropDownContext();

  const handleClickOptions = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: HeaderBarDropDownValueType,
    onClick: React.MouseEventHandler<HTMLDivElement>,
  ) => {
    setCurrentValue(value);
    setOpened(false);
    onClick(event);
  };

  return (
    <div>
      {opened && (
        <div className="header-bar__dropdown">
          {items.map(({ text, value, onClick }, index) => (
            <div
              className={clsx('header-bar__dropdown__item', {
                'justify-content-center': textAlign === 'center',
                'justify-content-end': textAlign === 'end',
                active: value === currentValue,
              })}
              onClick={event => handleClickOptions(event, value, onClick)}
              key={index}
            >
              {text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const HeaderBarDropDown = Object.assign(HeaderBarDropDownRoot, {
  Trigger,
  Items,
});
export default HeaderBarDropDown;
