import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import type { HeaderType } from 'components/stateless/Table/TableHeader';
import type { COLLATERAL_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import { requestFinancierAllAnchorAgreementList } from 'utils/http/api/financier/anchor-agreements';
import { requestFiDealerAgreementByKeyUnionInterface } from 'utils/http/api/financier/dealer-agreements';
import type { FiDealerAgreementByKeyUnionInterfaceRequest } from 'utils/http/api/financier/dealer-agreements/request';
import useModal from 'utils/modal/useModal';
import { getHttpErrorExceptionMessage } from 'utils/text';

const useSearchInterfaceByKeyUnionModalController = (collateralType: COLLATERAL_TYPE, modalId: number) => {
  const { show: showModal, close: closeModal } = useModal();
  const { t } = useTranslation(['format']);

  const [anchorAgreementList, setAnchorAgreementList] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const { pageable: anchorAgreementListPageable, setPageable: setAnchorAgreementListPageable } = usePageable();

  const [financierClientCode, setFinancierClientCode] = useState<string>('');
  const [searchedInterfaceData, setSearchedInterfaceData] = useState<DealerAgreementDetailVOModel>(
    {} as DealerAgreementDetailVOModel,
  );

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [checkedRow, setCheckedRow] = useState<number | undefined>(undefined);
  const [checkboxErrorMessage, setCheckboxErrorMessage] = useState<string>('');

  const ANCHOR_AGREEMENT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      colWidths: 60,
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Financing_Program'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Expiration_Date'),
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
    },
  ];

  const fetchAnchorAgreementList = async () => {
    try {
      const anchorAgreementListResponse = await requestFinancierAllAnchorAgreementList(
        anchorAgreementListPageable.currentPage,
        anchorAgreementListPageable.sizePerPage,
        {
          collateralType,
        },
      );
      setAnchorAgreementList(anchorAgreementListResponse);
    } catch (e) {
      closeModal(modalId);
      showModal(e);
    }
  };

  const anchorAgreementListPaginate = async (pageNumber: number = 1, rowCount: number = 10) => {
    try {
      const anchorAgreementPageResponse = await requestFinancierAllAnchorAgreementList(pageNumber, rowCount, {
        collateralType,
      });
      setAnchorAgreementList(anchorAgreementPageResponse);
      setAnchorAgreementListPageable(anchorAgreementPageResponse);
      setCheckedRow(undefined);
    } catch (e) {
      showModal(e);
    }
  };

  const handleFinancierClientCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setFinancierClientCode(e.target.value);
  };

  const handleSearchButtonClick = async () => {
    setCheckboxErrorMessage('');
    setErrorMessage('');

    const isEmptyDealerFinancierClientCode = !financierClientCode || financierClientCode.trim() === '';

    if (isEmptyDealerFinancierClientCode)
      setErrorMessage(t('text:Please_type_the_client_code_issued_by_financier_system'));
    else if (isNil(checkedRow))
      setCheckboxErrorMessage(
        t('text:Please_select_an_anchor_agreement_that_corresponds_to_the_partner_client_you_entered'),
      );
    else {
      const requestData: FiDealerAgreementByKeyUnionInterfaceRequest = {
        collateralType,
        anchorAgreementId: anchorAgreementList?.content[checkedRow]?.anchorAgreementId,
        dealerFinancierClientCode: financierClientCode,
      };

      try {
        const response = await requestFiDealerAgreementByKeyUnionInterface(requestData);
        setSearchedInterfaceData(response);
      } catch (e: any) {
        setErrorMessage(getHttpErrorExceptionMessage(e));
        setSearchedInterfaceData({} as DealerAgreementDetailVOModel);
      }
    }
  };

  const handleCheckBoxInputChange = (index: number) => {
    setCheckedRow(index);
  };

  useEffect(() => {
    fetchAnchorAgreementList();
  }, []);

  return {
    ANCHOR_AGREEMENT_LIST_TABLE_HEADERS,
    checkedRow,
    checkboxErrorMessage,
    errorMessage,
    financierClientCode,
    anchorAgreementList,
    anchorAgreementListPageable,
    anchorAgreementListPaginate,
    handleCheckBoxInputChange,
    handleFinancierClientCodeChange,
    handleSearchButtonClick,
    searchedInterfaceData,
  };
};

export default useSearchInterfaceByKeyUnionModalController;
