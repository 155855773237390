import type { ReactElement, ReactNode } from 'react';

import { clsx } from 'clsx';

import Form from 'components/stateless/Form';
import { passPropsToChildren } from 'utils/render';

type RowPropsType = {
  isInnerStyle?: boolean;
  singleColumnPosition?: 'all' | 'top' | 'bottom';
  className?: string;
  children: ReactNode;
};

const Row = ({ isInnerStyle = false, singleColumnPosition = 'bottom', className = '', children }: RowPropsType) => {
  const classes = clsx('section__row', {
    'section__row--inner': isInnerStyle,
    'section__row--single-column-bottom': singleColumnPosition === 'bottom',
    'section__row--single-column-top': singleColumnPosition === 'top',
    [className]: className,
  });

  return (
    <div className={classes}>
      {passPropsToChildren(children, {
        isInnerStyle: (child: ReactElement) => (Object.values(Form).includes(child.type) ? isInnerStyle : null),
      })}
    </div>
  );
};

export default Row;
