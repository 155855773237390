import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import type { NewObject } from 'types';
import useModal from 'utils/modal/useModal';
import { homePathByRole } from 'utils/route';
import { getSignIn } from 'utils/storage/LocalStorage';

export default function useLocationState<T extends NewObject>(neededStateArray: (keyof T)[]): [T, () => boolean] {
  const modal = useModal();
  const history = useHistory();
  const { state: locationState = {} as T } = useLocation<T>();

  const isLocationStateEmptyObject = () => Object.keys(locationState).length === 0;

  const exceptionModal = (state?: keyof T) => {
    modal.show(`${state ? String(state) : 'location.state'} is undefined`, {
      closeBtnCb: () => {
        if (history.location.pathname === '/') return;
        const signInModel = getSignIn();
        signInModel ? history.push(homePathByRole(signInModel.authorities[0].authority)) : history.push('/');
      },
    });
  };

  const errorHandlerOfLocationState = useCallback((): boolean => {
    if (isLocationStateEmptyObject()) {
      exceptionModal();

      return true;
    }

    for (const state of neededStateArray) {
      if (locationState[state] === undefined) {
        exceptionModal(state);
        throw new Error(`${String(state)} is undefined`);
      }
    }

    return false;
  }, []);

  return [locationState, errorHandlerOfLocationState];
}
