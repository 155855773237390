import type { Dispatch, RefObject, SelectHTMLAttributes, SetStateAction } from 'react';
import { forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { isNil, isUndefined } from 'lodash-es';

import { isNilOrEmptyString } from 'utils/helpers';

import { FormErrorMessage } from '../ErrorMessage';
import Value from '../Value/Value';

import type { SizeType } from '..';

interface SelectPlaceholderOptionType {
  show: boolean;
  text?: string;
}

export interface SelectOptionType {
  label: string;
  value: any;
}

export interface SelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'placeholder' | 'value'> {
  value?: string | number | readonly string[] | null;
  setValue?: Dispatch<SetStateAction<string>>;
  selectOptions: SelectOptionType[];
  placeholderOptions?: SelectPlaceholderOptionType;
  fieldSize?: SizeType;
  isEditable?: boolean;
  fetchedReadOnly?: boolean;
  readOnly?: boolean;
  currentValue?: string | number | readonly string[] | null;
  parentReadOnly?: boolean;
  error?: FieldError;
  showError?: boolean;
}

const Select = forwardRef((props: SelectProps, ref) => {
  const {
    value,
    setValue,
    selectOptions,
    placeholderOptions,
    disabled = false,
    defaultValue,
    className = 'form__input',
    fieldSize,
    isEditable = false,
    fetchedReadOnly = false,
    readOnly = false,
    currentValue,
    parentReadOnly,
    error,
    showError = true,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const { show: showPlaceholder = false, text: placeholderText = t('text:Please_select_type') } =
    placeholderOptions ?? {};

  const isDisabled = disabled || !isEditable;

  const isReadOnly = readOnly || fetchedReadOnly;

  const hasNoValue = isNilOrEmptyString(defaultValue) && isNilOrEmptyString(value) && isNilOrEmptyString(currentValue);

  const shouldShowDash = (() => {
    const defaultCondition = hasNoValue && (readOnly || fetchedReadOnly || disabled || !isEditable);

    if (isNil(parentReadOnly)) return defaultCondition;

    return parentReadOnly && defaultCondition;
  })();

  return (
    <div>
      <div
        className={clsx('position-parent', {
          [`field--size-${fieldSize}`]: fieldSize,
        })}
      >
        {shouldShowDash ? (
          <Value value="-" />
        ) : (
          <>
            <select
              className={clsx('opacity-1', {
                'error-input-border': error && showError,
                [className]: className,
              })}
              disabled={isDisabled || isReadOnly}
              ref={ref as RefObject<HTMLSelectElement>}
              {...(!isUndefined(value) && { value: value ?? '' })}
              {...(!isUndefined(defaultValue) && { defaultValue: defaultValue ?? '' })}
              onChange={e => setValue?.(e.target.value)}
              {...(showPlaceholder && isNil(defaultValue) && { defaultValue: '' })}
              {...restProps}
            >
              {showPlaceholder && (
                <option value="" disabled>
                  {placeholderText}
                </option>
              )}
              {selectOptions.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <FontAwesomeIcon className="select__caret" icon={faAngleDown} fontSize={12} />
          </>
        )}
      </div>
      {error && showError && <FormErrorMessage error={error} />}
    </div>
  );
});

Select.displayName = 'Select';

export default Select;
