import type Pageable from 'models/Pageable';
import { formattingToDeletedArVOModel } from 'models/vo/DeletedArVO';
import type { DeletedArVO, DeletedArVOModel } from 'models/vo/DeletedArVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorDeletedARsListRequest } from './requests';

// 앵커 - 등록 완료된 AR 목록 조회
export async function requestAnchorDeletedArsList(
  pageNumber: number,
  rowCount: number,
  searchValue?: AnchorDeletedARsListRequest,
): Promise<Pageable<DeletedArVOModel[]>> {
  const response = await http.get<Pageable<DeletedArVO[]>>({
    url: API_AC.DELETED_ARS.DELETED_ARS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return { ...response, content: response.content.map((data: DeletedArVO) => formattingToDeletedArVOModel(data)) };
}
