import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorFinancingOptionForRequestMultipleAnchorLoanVOModel } from 'models/vo/AnchorFinancingOptionForRequestMultipleAnchorLoanVO';
import {
  requestAnchorArListForMultipleRequestAnchorLoans,
  requestAnchorInvoiceListForMultipleRequestAnchorLoans,
} from 'utils/http/api/anchor/anchor-financing-opitons';
import useModal from 'utils/modal/useModal';
import { getCollateralType, getSignIn } from 'utils/storage/LocalStorage';

function AnchorFinancingApplicationStep1() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable: invoicePageable, setPageable: setInvoicePageable } = usePageable();
  const { pageable: arPageable, setPageable: setArPageable } = usePageable();

  const modal = useModal();
  const collateralType = getCollateralType();

  const isDFSAuthority = getSignIn()?.authorityType !== AUTHORITY_TYPE.HQ_OPERATOR;

  const isVFSAuthority =
    getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN || getSignIn()?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR;

  const [invoicePage, setInvoicePage] =
    useState<Pageable<AnchorFinancingOptionForRequestMultipleAnchorLoanVOModel[]>>();
  const [arPage, setArPage] = useState<Pageable<AnchorFinancingOptionForRequestMultipleAnchorLoanVOModel[]>>();

  useEffect(() => {
    if (mounted) {
      fetchOnMount();
    }
  }, [mounted]);

  async function fetchOnMount() {
    if (collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE) {
      fetchInvoiceList();
    } else if (collateralType === SUPPORTED_COLLATERAL_TYPE.AR) {
      fetchArList();
    } else {
      fetchAll();
    }
  }

  async function fetchAll(pageNumber: number = 1, sizePerPage: number = 10) {
    try {
      if (isDFSAuthority) await fetchInvoiceList();

      if (isVFSAuthority) await fetchArList();
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchInvoiceList = async (pageNumber: number = 1, sizePerPage: number = 10) => {
    try {
      const invoicePage = await requestAnchorInvoiceListForMultipleRequestAnchorLoans(pageNumber, sizePerPage);
      setInvoicePage(invoicePage);
      setInvoicePageable(invoicePage);
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchArList = async (pageNumber: number = 1, sizePerPage: number = 10) => {
    try {
      const arPage = await requestAnchorArListForMultipleRequestAnchorLoans(pageNumber, sizePerPage);

      setArPage(arPage);
      setArPageable(arPage);
    } catch (e) {
      modal.show(e);
    }
  };

  const getStepWizardText = (): string => {
    switch (collateralType) {
      case SUPPORTED_COLLATERAL_TYPE.BOTH:
        return t('text:Select_AP_or_Invoice');
      case SUPPORTED_COLLATERAL_TYPE.AR:
        return t('text:Select_AP');
      case SUPPORTED_COLLATERAL_TYPE.INVOICE:
        return t('text:Select_Invoice');
      default:
        return '';
    }
  };

  const renderArTable = () => {
    const AR_TABLE_HEADERS: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
      },
      {
        headerText: t('text:Currency'),
      },
      {
        headerText: t('text:Total_Amount_of_AP'),
      },
      {
        headerText: t('text:APs_available_for_financing'),
      },

      {
        headerText: '',
        colWidths: 80,
      },
    ];

    const renderResultTable = () => {
      return arPage?.content.map((item, i) => {
        return (
          <Tr key={i}>
            <Td data={item.financierName} />
            <Td data={item.currencyType} />
            <Td data={item.totalAmount} format="number" />
            <Td data={item.totalCount} format="number" />
            <Td className="information-table-more">
              <Button
                size={ButtonSizeEnum.XS}
                bold
                to={ROUTES_AC.MANAGE_ANCHOR_FINANCING.APPLICATION_AR_STEP2_BUILD_PATH(item.anchorFinancingOptionId)}
              >
                {t('text:Select')}
              </Button>
            </Td>
          </Tr>
        );
      });
    };

    return (
      <>
        <div className="content-area">
          <TableBorder>
            <TableHeader header={AR_TABLE_HEADERS} />
            <TableBody numOfCol={AR_TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
          </TableBorder>
          <Pagination pageable={arPageable} paginate={fetchArList} />
        </div>
      </>
    );
  };

  const renderInvoiceTable = () => {
    const INVOICE_TABLE_HEADER: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
      },
      {
        headerText: t('text:Currency'),
      },
      {
        headerText: t('text:Financing_Available_Amount'),
        labelChildren: (
          <QuestionTooltip
            id="financingAvailableAmountTooltip"
            place="top"
            contentText={
              <>
                <div>
                  {t('text:Total_Amount_of_Expected_Disbursement_on_Invoice_*_Expected_Disbursement_Ratio')} (%)
                </div>
              </>
            }
          />
        ),
      },
      {
        headerText: t('text:Invoices_available_for_financing'),
      },
      {
        headerText: '',
        colWidths: 80,
      },
    ];

    const renderResultTable = () => {
      return invoicePage?.content.map((item, i) => {
        return (
          <Tr key={i}>
            <Td data={item.financierName} />
            <Td data={item.currencyType} />
            <Td data={item.totalAmount} format="number" />
            <Td data={item.totalCount} format="number" />
            <Td className="information-table-more">
              <Button
                size={ButtonSizeEnum.XS}
                bold
                to={ROUTES_AC.MANAGE_ANCHOR_FINANCING.APPLICATION_INVOICE_STEP2_BUILD_PATH(
                  item.anchorFinancingOptionId,
                )}
              >
                {t('text:Select')}
              </Button>
            </Td>
          </Tr>
        );
      });
    };

    return (
      <div className="content-area">
        <TableBorder>
          <TableHeader header={INVOICE_TABLE_HEADER} />
          <TableBody numOfCol={INVOICE_TABLE_HEADER.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={invoicePageable} paginate={fetchInvoiceList} />
      </div>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing_Application')} />
      <div className="content-area">
        <StepWizard nth={1} title={[getStepWizardText(), t('text:Fill_out_Financing_Application')]} />
      </div>
      <Tab
        tabList={[
          ...(collateralType !== SUPPORTED_COLLATERAL_TYPE.AR
            ? [{ tabName: t('text:Invoice'), tabItemCount: invoicePage?.totalElements ?? 0 }]
            : []),
          ...(collateralType !== SUPPORTED_COLLATERAL_TYPE.INVOICE
            ? [{ tabName: t('text:AP'), tabItemCount: arPage?.totalElements ?? 0 }]
            : []),
        ]}
        tabViewList={[
          ...(collateralType !== SUPPORTED_COLLATERAL_TYPE.AR ? [renderInvoiceTable()] : []),
          ...(collateralType !== SUPPORTED_COLLATERAL_TYPE.INVOICE ? [renderArTable()] : []),
        ]}
      />
    </>
  );
}

export default AnchorFinancingApplicationStep1;
