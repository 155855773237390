export interface BankCodeVO {
  id: number;
  bankCode: string;
  branchCode: string;
  bankName: string;
  branchName: string;
  deleted: boolean;
  updatedDateTime: string;
  financierId: number;
  financierName: string;
}

export interface BankCodeVOModel extends BankCodeVO {}

export function formattingToBankCodeVOModel(data: BankCodeVO): BankCodeVOModel {
  return {
    ...data,
  };
}
