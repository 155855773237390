import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import type { Location } from 'history';

function useBlocker(blocker: (tx: Location) => false, when = true) {
  const history = useHistory();

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = history.block((location: Location) => blocker(location));

    return unblock;
  }, [history, blocker, when]);
}

export function usePrompt(when: boolean) {
  const history = useHistory();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const confirmNavigation = useCallback(() => {
    setConfirmedNavigation(true);
  }, []);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (tx: Location) => {
      if (!confirmedNavigation && tx.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(tx);

        return false;
      }

      return true;
    },
    [confirmedNavigation, location.pathname],
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useBlocker(handleBlockedNavigation as (tx: Location) => false, when);

  return { showPrompt, confirmNavigation, cancelNavigation };
}
