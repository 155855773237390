import type { TFunction } from 'react-i18next';

import { CURRENCY_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { PlatformTransactionVOModel } from 'models/vo/PlatformTransactionVO';
import type { SystemPlatformTransactionListRequest } from 'utils/http/api/system/platform-transaction/requests';
import type { ColumnOption, EtcInsertDataTypes, MergedCellTypes } from 'utils/spreadSheet/types';
import { getProgramTypeText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

export function systemTransactionPerformanceListPageExcelExportPropsData(
  maxRowCount: number,
  financierName: string | undefined,
  searchedResultInfo: SystemPlatformTransactionListRequest,
  t: TFunction<'format'[], undefined>,
) {
  // api list data
  const platformTransactionListMergedCells: MergedCellTypes[] = [
    {
      target: 'A:D',
      rowIndex: 13,
      value: t('text:Client_Information'),
    },
    {
      target: 'E:N',
      rowIndex: 13,
      value: t('text:Financing_Information'),
    },
    {
      target: 'O:R',
      rowIndex: 13,
      value: t('text:Transaction_Fee_Information'),
    },
  ];

  const platformTransactionListColumns: ColumnOption<PlatformTransactionVOModel>[] = [
    {
      header: t('text:Program_Type'),
      key: 'collateralType',
    },
    {
      header: t('text:Anchor_Name'),
      key: 'anchorClientName',
      width: 30,
    },
    {
      header: t('text:Partner_Name'),
      key: 'dealerClientName',
      width: 30,
    },
    {
      header: t('text:Currency'),
      key: 'currencyType',
    },
    {
      header: t('text:Platform_Financing_ID'),
      key: 'loanId',
    },
    {
      header: t('text:Collateral_Number'),
      key: 'collateralNumber',
    },
    {
      header: t('text:Reference_Number'),
      key: 'collateralReferenceNumber',
    },
    {
      header: t('text:Collateral_Amount'),
      key: 'collateralAmount',
    },
    {
      header: t('text:Disbursed_Amount'),
      key: 'disbursedAmount',
    },
    {
      header: t('text:Disbursed_Date'),
      key: 'disbursedDate',
      style: {
        numFmt: 'yyyy-mm-dd',
      },
    },
    {
      header: t('text:Scheduled_Repayment_Date'),
      key: 'repaymentDate',
      style: {
        numFmt: 'yyyy-mm-dd',
      },
    },
    {
      header: t('text:Repaid_Date'),
      key: 'repaidDate',
      style: {
        numFmt: 'yyyy-mm-dd',
      },
    },
    {
      header: t('text:Expired_Date'),
      key: 'expiredDate',
      style: {
        numFmt: 'yyyy-mm-dd',
      },
    },
    {
      header: t('text:Financing_Status'),
      key: 'loanStatus',
    },
    {
      header: t('text:Start_Date'),
      key: 'startDate',

      style: {
        numFmt: 'yyyy-mm-dd',
      },
    },
    {
      header: t('text:End_Date'),
      key: 'endDate',
      style: {
        numFmt: 'yyyy-mm-dd',
      },
    },
    {
      header: t('text:Financing_Term'),
      key: 'term',
    },
    {
      header: t('text:Fee'),
      key: 'performanceFee',
    },
  ];

  const platformTransactionListJsonArrayData = (res: Pageable<PlatformTransactionVOModel[]>) => {
    return res?.content.map(item => ({
      collateralType: getProgramTypeText(item.collateralType),
      anchorClientName: item.anchorClientName,
      dealerClientName: item.dealerClientName,
      currencyType: item.currencyType,
      loanId: item.loanId,
      collateralNumber: item.collateralNumber,
      collateralReferenceNumber: tableValueManage(item.collateralReferenceNumber),
      collateralAmount: item.collateralAmount,
      disbursedAmount: item.disbursedAmount,
      disbursedDate: item.disbursedDate ? new Date(item.disbursedDate) : '-',
      repaymentDate: item.repaymentDate ? new Date(item.repaymentDate) : '-',
      repaidDate: item.repaidDate ? new Date(item.repaidDate) : '-',
      expiredDate: item.expiredDate ? new Date(item.expiredDate) : '-',
      loanStatus: tableValueManage(t(`code:financing-status.${item.loanStatus}`)),
      startDate: item.startDate ? new Date(item.startDate) : '-',
      endDate: item.endDate ? new Date(item.endDate) : '-',
      term: `${item.term} ${t('text:Days')}`,
      performanceFee: item.performanceFee,
    }));
  };

  // etc insert data
  const searchedResultInfoCells: EtcInsertDataTypes[] = [
    {
      target: 'A1',
      value: t('text:Financier'),
      applyDefaultStyle: false,
      style: {
        font: {
          bold: true,
          size: 13,
        },
      },
    },
    {
      target: 'B1',
      value: financierName,
      applyDefaultStyle: false,
    },
    {
      target: 'A2',
      value: t('text:Fee_Rate(APR,%)'),
      applyDefaultStyle: false,
      style: {
        font: {
          bold: true,
          size: 13,
        },
      },
    },
    {
      target: 'B2',
      value: tableValueManage(searchedResultInfo?.fee, t('format:number', { value: searchedResultInfo?.fee })),
      applyDefaultStyle: false,
    },
    {
      target: 'A3',
      value: t('text:Date'),
      applyDefaultStyle: false,
      style: {
        font: {
          bold: true,
          size: 13,
        },
      },
    },
    {
      target: 'B3',
      value: `${t('format:original-date', { value: searchedResultInfo?.fromDate, key: 'original-date' })} ~ ${t(
        'format:original-date',
        {
          value: searchedResultInfo?.toDate,
          key: 'original-date',
        },
      )}`,
      applyDefaultStyle: false,
    },
  ];

  const totalAmountColumnsCells: EtcInsertDataTypes[] = [
    {
      target: 'A5',
      value: '',
    },
    {
      target: 'A6',
      value: t('text:USD'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
    {
      target: 'A7',
      value: t('text:KRW'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
    {
      target: 'A8',
      value: t('text:INR'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
    {
      target: 'A9',
      value: t('text:IDR'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
    {
      target: 'A10',
      value: t('text:JPY'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
    {
      target: 'A11',
      value: t('text:VND'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
    {
      target: 'B5',
      value: t('text:Collateral_Amount'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
    {
      target: 'C5',
      value: t('text:Disbursed_Amount'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
    {
      target: 'D5',
      value: t('text:Fee'),
      style: {
        alignment: { horizontal: 'center' },
      },
    },
  ];

  // 통화 별로 컬럼 합산
  const totalAmountDataCells = (amountType: 'collateral' | 'disbursed' | 'fee'): EtcInsertDataTypes[] => {
    const START_DATA_CELL = 15;
    const END_DATA_CELL = maxRowCount;
    const START_CURRENCY_TYPE_CELL = `D${START_DATA_CELL}`;
    const END_CURRENCY_TYPE_CELL = `D${END_DATA_CELL}`;

    // all 제외, enum 타입 순서 중요(엑셀 위치 고정)
    const currencyTypeArr = Object.values(CURRENCY_TYPE).filter(currencyType => currencyType !== '');

    if (amountType) {
      if (amountType === 'collateral') {
        return currencyTypeArr.map((currencyType, index) => {
          return {
            target: `B${index + 6}`,
            value: {
              formula: `SUMIF(${START_CURRENCY_TYPE_CELL}:${END_CURRENCY_TYPE_CELL},"${currencyType}",H${START_DATA_CELL}:H${END_DATA_CELL})`,
            },
            applyDefaultStyle: false,
          };
        });
      } else if (amountType === 'disbursed') {
        return currencyTypeArr.map((currencyType, index) => {
          return {
            target: `C${index + 6}`,
            value: {
              formula: `SUMIF(${START_CURRENCY_TYPE_CELL}:${END_CURRENCY_TYPE_CELL},"${currencyType}",I${START_DATA_CELL}:I${END_DATA_CELL})`,
            },
            applyDefaultStyle: false,
          };
        });
      } else {
        return currencyTypeArr.map((currencyType, index) => {
          return {
            target: `D${index + 6}`,
            value: {
              formula: `SUMIF(${START_CURRENCY_TYPE_CELL}:${END_CURRENCY_TYPE_CELL},"${currencyType}",R${START_DATA_CELL}:R${END_DATA_CELL})`,
            },
            applyDefaultStyle: false,
          };
        });
      }
    } else {
      return [
        {
          target: '',
          value: '',
          applyDefaultStyle: false,
        },
      ];
    }
  };

  return {
    platformTransactionListMergedCells,
    platformTransactionListColumns,
    platformTransactionListJsonArrayData,
    searchedResultInfoCells,
    totalAmountColumnsCells,
    totalAmountDataCells,
  };
}
