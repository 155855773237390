import type { ChangeEvent, InputHTMLAttributes } from 'react';

interface RadioButtonPropsType extends InputHTMLAttributes<HTMLInputElement> {}

const RadioButton = ({ id, checked, children, disabled, readOnly, onChange, ...restProps }: RadioButtonPropsType) => {
  const getRadioButtonClassName = () => {
    const classNames = ['form-check-inline'];
    if (checked) classNames.push('radio-btn-checked');
    if (disabled) classNames.push('disabled');

    return classNames.join(' ');
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!readOnly && onChange) {
      onChange(e);
    }
  };

  return (
    <label className={getRadioButtonClassName()} htmlFor={id}>
      <input
        id={id}
        className="form-check-input"
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={handleOnChange}
        readOnly={readOnly}
        {...restProps}
      />
      <span>{children}</span>
    </label>
  );
};

export default RadioButton;
