import { clsx } from 'clsx';

export enum BackGroundType {
  'WHITE' = 'bg-white',
  'Gray' = 'bg-sub100',
  'DarkGray' = 'bg-sub200',
  null = '',
}

interface PropsType {
  title: string;
  backGroundType?: BackGroundType;
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
  isRequired?: boolean;
}

export const FormSubtitle = ({ title, backGroundType = BackGroundType.Gray, children, isRequired }: PropsType) => {
  const flexSpaceBetween = children ? 'd-flex justify-content-between' : '';

  return (
    <div className={clsx('information-form__subtitle', [backGroundType], [flexSpaceBetween])}>
      <div>
        {title}
        {isRequired && <span className="asterisk ms-2">*</span>}
      </div>
      {children}
    </div>
  );
};
