import type Pageable from 'models/Pageable';
import type {
  AnchorFinancingOptionDetailVO,
  AnchorFinancingOptionDetailVOModel,
} from 'models/vo/AnchorFinancingOptionDetailVO';
import { formattingToAnchorFinancingOptionDetailVOModel } from 'models/vo/AnchorFinancingOptionDetailVO';
import type {
  AnchorFinancingOptionForRequestMultipleAnchorLoanVO,
  AnchorFinancingOptionForRequestMultipleAnchorLoanVOModel,
} from 'models/vo/AnchorFinancingOptionForRequestMultipleAnchorLoanVO';
import { formattingToAnchorFinancingOptionForRequestMultipleAnchorLoanVOModel } from 'models/vo/AnchorFinancingOptionForRequestMultipleAnchorLoanVO';
import { formattingToAnchorFinancingOptionVOModel } from 'models/vo/AnchorFinancingOptionVO';
import type { AnchorFinancingOptionVO, AnchorFinancingOptionVOModel } from 'models/vo/AnchorFinancingOptionVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { SearchAcAnchorFinancingOptionsDTO } from './requests';

export async function requestAnchorFinancingOptionsList(
  pageNumber: number,
  rowCount: number,
  SearchFiAnchorFinancingAgreementDTO: SearchAcAnchorFinancingOptionsDTO,
): Promise<Pageable<AnchorFinancingOptionVOModel[]>> {
  const response = await http.get<Pageable<AnchorFinancingOptionVO[]>>({
    url: API_AC.ANCHOR_FINANCING_OPTIONS.ANCHOR_FINANCING_OPTION_LIST,
    data: {
      pageNumber,
      rowCount,
      ...SearchFiAnchorFinancingAgreementDTO,
    },
  });

  const anchorFinancingAgreementList = response.content.map(data => formattingToAnchorFinancingOptionVOModel(data));

  return {
    ...response,
    content: anchorFinancingAgreementList,
  };
}

export async function requestAnchorFinancingOptionDetail(
  anchorFinancingOptionId: number,
): Promise<AnchorFinancingOptionDetailVOModel> {
  const response = await http.get<AnchorFinancingOptionDetailVO>({
    url: API_AC.ANCHOR_FINANCING_OPTIONS.ANCHOR_FINANCING_OPTION_DETAIL(anchorFinancingOptionId),
  });

  return formattingToAnchorFinancingOptionDetailVOModel(response);
}

export async function requestAnchorArListForMultipleRequestAnchorLoans(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<AnchorFinancingOptionForRequestMultipleAnchorLoanVOModel[]>> {
  const response = await http.get<Pageable<AnchorFinancingOptionForRequestMultipleAnchorLoanVO[]>>({
    url: API_AC.ANCHOR_FINANCING_OPTIONS.AR_LIST_FOR_MULTIPLE_REQUEST_ANCHOR_LOANS,
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorFinancingOptionForRequestMultipleAnchorLoanVOModel(data)),
  };
}

export async function requestAnchorInvoiceListForMultipleRequestAnchorLoans(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<AnchorFinancingOptionForRequestMultipleAnchorLoanVOModel[]>> {
  const response = await http.get<Pageable<AnchorFinancingOptionForRequestMultipleAnchorLoanVO[]>>({
    url: API_AC.ANCHOR_FINANCING_OPTIONS.INVOICE_LIST_FOR_MULTIPLE_REQUEST_ANCHOR_LOANS,
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorFinancingOptionForRequestMultipleAnchorLoanVOModel(data)),
  };
}
