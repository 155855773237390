import { AUTHORIZER_STATUS, stringToEnum } from 'enums';

export interface DealerAuthorizerVO {
  dealerAgreementId: number;
  dealerClientName: string;
  authorizerName: string;
  authorizerEmployeeCode: string;
  authorizerEmail: string;
  authorizerMobile: string;
  authorizerPosition: string;
  authorizerStatus: string;
}

export interface DealerAuthorizerVOModel extends DealerAuthorizerVO {
  authorizerStatus: AUTHORIZER_STATUS;
}

export function formattingToDealerAuthorizerVOModel(data: DealerAuthorizerVO): DealerAuthorizerVOModel {
  return {
    ...data,
    authorizerStatus: stringToEnum(AUTHORIZER_STATUS, data.authorizerStatus),
  };
}
