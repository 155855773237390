import { useFormContext } from 'components/stateless/Form';
import useExtraInformationViewModel from 'pages/financier/manage-partner/agreement/models/extraInformation/useExtraInformationViewModel';

import useRegistrationDetailPartnerCompanyController from './useRegistrationDetailPartnerCompanyController';
import useRegistrationPartnerCompanyController from './useRegistrationPartnerCompanyController';
import useWaitingDetailPartnerCompanyController from './useWaitingDetailPartnerCompanyController';

const usePartnerCompanyController = () => {
  const { getControllerByPageType } = useExtraInformationViewModel();

  const {
    methods: { register, errors },
    isEditable,
  } = useFormContext();

  const controller = getControllerByPageType({
    register: useRegistrationPartnerCompanyController,
    registeredDetail: useRegistrationDetailPartnerCompanyController,
    waitingDetail: useWaitingDetailPartnerCompanyController,
  });

  return { ...controller, register, errors, isEditable };
};

export default usePartnerCompanyController;
