import {
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  CURRENCY_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  stringToEnum,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { getDday } from 'utils/date/date';
import { hasValue } from 'utils/valueManager/ValueManager';

import { formattingToLoanEtcAttachmentVOModel } from './LoanEtcAttachmentVO';
import { formattingToOverdueInterestRateTermSpreadVOModel } from './OverdueInterestRateTermSpreadVO';

import type { LoanEtcAttachmentVO, LoanEtcAttachmentVOModel } from './LoanEtcAttachmentVO';
import type { OverdueInterestRateTermSpreadVO } from './OverdueInterestRateTermSpreadVO';
import type { OverdueInterestRateTermSpreadVOModel } from './OverdueInterestRateTermSpreadVO';

export interface AnchorLoanDetailVO {
  anchorLoanId: number;
  financierLoanId: number;
  anchorAgreementId: number;
  anchorFinancingOptionId: string;
  currencyType: string;
  collateralType: string;
  successArId: number;
  arNumber: string;
  successInvoiceId: number;
  invoiceNumber: string;
  anchorPartnerId: number;
  anchorPartnerName: string;
  financierId: number;
  financierName: string;
  anchorClientId: number;
  anchorClientName: string;
  interestRepaymentType: string;
  monthlyInterestRepaymentDate: number;
  showNetDisbursementAmount: boolean;
  netDisbursementAmount: BigNumber;
  disbursedAmount: number;
  principalAmount: number;
  totalInterestAmount: BigNumber;
  totalDelayAmount: BigNumber;
  accruedInterestAmount: BigNumber;
  outstandingAccruedInterestAmount: BigNumber;
  overdueDays: number;
  tempTransactionDate: string;
  tempTotalInterestAmount: number;
  tempTotalDelayAmount: number;
  repaidPrincipalAmount: number;
  repaidInterestAmount: number;
  repaidDelayAmount: number;
  financingPeriod: number;
  scheduledFinancingPeriod: number;
  loanStatus: string;
  loanApprovalType: string;
  createdDateTime: string;
  requestedDateTime: string;
  disbursedDate: string;
  repaymentDate: string;
  repaidDate: string;
  approvedDateTime: string;
  desiredDisburseDate: string;
  approvedDisburseDate: string;
  createdBasisInterestType: string;
  createdBasisInterestRate: number;
  createdBasisInterestTerm: string;
  createdBasisInterestBankCode: string;
  createdCreditSpreadInterestRate: number;
  createdTermSpreadInterestRate: number;
  createdPreferentialInterestRate: number;
  createdTotalInterestRateWithoutBasis: number;
  createdFeeAmount: number;
  approvedBasisInterestType: string;
  approvedBasisInterestRate: number;
  approvedBasisInterestTerm: string;
  approvedCreditSpreadInterestRate: number;
  approvedTermSpreadInterestRate: number;
  approvedPreferentialInterestRate: number;
  approvedTotalInterestRateWithoutBasis: number;
  approvedFeeAmount: number;
  disbursedBasisInterestType: string;
  disbursedBasisInterestRate: number;
  disbursedBasisInterestTerm: string;
  disbursedCreditSpreadInterestRate: number;
  disbursedTermSpreadInterestRate: number;
  disbursedPreferentialInterestRate: number;
  disbursedTotalInterestRateWithoutBasis: number;
  disbursedFeeAmount: number;
  isAnchorAccount: boolean;
  disbursementAccountFinancierCode: string;
  disbursementAccountFinancierName: string;
  disbursementAccountBranchCode: string;
  disbursementAccountBranchName: string;
  disbursementAccount: string;
  disbursementAccountOwner: string;
  loanAgreementAttachmentId: number;
  loanAgreementAttachmentName: string;
  loanAgreementAttachmentPath: string;
  loanRequestAttachmentId: number;
  loanRequestAttachmentName: string;
  loanRequestAttachmentPath: string;
  overdueInterestRateTermSpreads: OverdueInterestRateTermSpreadVO[];
  cancelReason: string;
  rejectReason: string;
  expirationReason: string;
  anchorMultipleLoanRequestId: number;
}

export interface AnchorLoanDetailVOModel extends AnchorLoanDetailVO {
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  loanStatus: LOAN_STATUS;
  loanApprovalType: LOAN_APPROVAL_TYPE;
  createdBasisInterestType: BASIS_INTEREST_TYPE;
  approvedBasisInterestType: BASIS_INTEREST_TYPE;
  disbursedBasisInterestType: BASIS_INTEREST_TYPE;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  overdueInterestRateTermSpreads: OverdueInterestRateTermSpreadVOModel[];
  // only front
  outstandingBalance: BigNumber;
  remainingFinancingTerm?: string;
}

export function formattingToAnchorLoanDetailVOModel(data: AnchorLoanDetailVO): AnchorLoanDetailVOModel {
  const calculatorBigNumber = new CalculatorBigNumber();

  const getRemainingFinancingTerm = (loanStatus: LOAN_STATUS, repaymentDate: string) => {
    if ((loanStatus === LOAN_STATUS.DISBURSED || loanStatus === LOAN_STATUS.OVERDUE) && hasValue(repaymentDate)) {
      return getDday(repaymentDate);
    }
  };

  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
    interestRepaymentType: stringToEnum(INTEREST_REPAYMENT_TYPE, data.interestRepaymentType),
    loanApprovalType: stringToEnum(LOAN_APPROVAL_TYPE, data.loanApprovalType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    createdBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.createdBasisInterestType),
    approvedBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.approvedBasisInterestType),
    disbursedBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.disbursedBasisInterestType),
    overdueInterestRateTermSpreads: data.overdueInterestRateTermSpreads?.map(termSpread =>
      formattingToOverdueInterestRateTermSpreadVOModel(termSpread),
    ),
    // only front
    remainingFinancingTerm: getRemainingFinancingTerm(stringToEnum(LOAN_STATUS, data.loanStatus), data.repaymentDate),
    outstandingBalance: calculatorBigNumber.add(data.disbursedAmount).minus(data.repaidPrincipalAmount).get(), // disbursedAmount - repaidPrincipalAmount
  };
}
