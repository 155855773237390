import { useTranslation } from 'react-i18next';

import WithProvider from 'utils/render';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import useSummaryOfAssociatedAnchorController from './useSummaryOfAssociatedAnchorController';
import AnchorClientLimitProvider from '../../../models/anchorClientLimit/Context';

const SummaryOfAssociatedAnchor = () => {
  const { t } = useTranslation(['format']);
  const {
    dealerAgreementCurrencyType,
    useTotalLimit,
    loanLimitAmount,
    disbursedLoanAmount,
    loanLimitBalance,
    currencyType,
    totalLimitAmount,
    totalLimitCurrencyType,
    originCurrencyTotalBalance,
    anchorAgreementStatus,
    loanLimitCheck,
    maximumBalanceAmount,
    formattedAmountWithCurrency,
  } = useSummaryOfAssociatedAnchorController();

  return (
    <>
      <div className="account-form mb-3">
        <div className="account-balance">
          <div className="account-balance-label">{t('text:Maximum_Applicable_Amount')}</div>
          <div className="account-balance-value">
            {formattedAmountWithCurrency(maximumBalanceAmount, dealerAgreementCurrencyType)}
          </div>
        </div>
        <div className="bg-pattern" />
      </div>

      {/* Summary of Associated Anchor */}
      <div className="section-title">
        <h2 style={{ fontSize: '16px' }}>{t('text:Summary_of_Associated_Anchor')}</h2>
      </div>
      <div className="information-grid-form">
        {/* 통합 한도 존재할 시에만 해당 row 표시 */}
        {useTotalLimit && (
          <div className="row">
            <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit_of_Anchor')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {/* 앵커 기업 통합 한도 */}
                {formattedAmountWithCurrency(totalLimitAmount, totalLimitCurrencyType)}
              </div>
            </div>
            <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit_of_Anchor')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {/* 잔여 대출 신청 가능 통합 한도 (통합 한도 통화) */}
                {formattedAmountWithCurrency(originCurrencyTotalBalance, totalLimitCurrencyType)}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit_of_Agreement')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {/* 앵커 약정 한도 */}
              {loanLimitCheck ? formattedAmountWithCurrency(loanLimitAmount, currencyType) : '-'}
            </div>
          </div>
          <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit_of_Agreement')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {/* 잔여 대출 신청 가능 통합 한도 (약정 통화)  */}
              {loanLimitCheck ? formattedAmountWithCurrency(loanLimitBalance, currencyType) : '-'}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Balance')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">{formattedAmountWithCurrency(disbursedLoanAmount, currencyType)}</div>
          </div>
          <div className="grid-form__item__label-wrap col-2">{t('text:Agreement_Suspension')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {tableValueManage(t(`code:anchor-agreement-status.${anchorAgreementStatus}`))}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3" />
    </>
  );
};

export default WithProvider({
  Component: SummaryOfAssociatedAnchor,
  Provider: AnchorClientLimitProvider,
});
