import type Pageable from 'models/Pageable';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementDetailVO, DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import { formattingToDealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type {
  RelatedAnchorClientLimitInfoVO,
  RelatedAnchorClientLimitInfoVOModel,
} from 'models/vo/RelatedAnchorClientLimitInfoVO';
import { formattingToRelatedAnchorClientLimitInfoVOModel } from 'models/vo/RelatedAnchorClientLimitInfoVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FiDealerAgreementByKeyUnionInterfaceRequest,
  FinancierDealerAgreementListRequest,
  TempFinancierDealerAgreementListRequest,
} from './request';

export async function requestFinancierDealerAgreementList(
  pageNumber: number,
  rowCount: number,
  searchData: FinancierDealerAgreementListRequest,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_FI.DEALER_AGREEMENTS.DEALER_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchData,
    },
  });

  const dealerAgreementList = response.content.map(data => formattingToDealerAgreementVOModel(data));

  return {
    ...response,
    content: dealerAgreementList,
  };
}

export async function requestFinancierDealerAgreementDetail(
  dealerAgreementId: number,
): Promise<DealerAgreementDetailVOModel> {
  const response = await http.get<DealerAgreementDetailVO>({
    url: API_FI.DEALER_AGREEMENTS.DEALER_AGREEMENT_DETAIL(dealerAgreementId),
  });

  return formattingToDealerAgreementDetailVOModel(response);
}

export async function requestFinancierDealerAgreementDetailByCodeInterface(
  contractNo: string,
  collateralType: string,
): Promise<DealerAgreementDetailVOModel> {
  const response = await http.get<DealerAgreementDetailVO>({
    url: API_FI.DEALER_AGREEMENTS.DEALER_AGREEMENT_BY_FINANCIER_INTERFACE(contractNo),
    data: { collateralType },
  });

  return formattingToDealerAgreementDetailVOModel(response);
}

export async function requestFinancierDealerAgreementDetailByIdInterface(
  dealerAgreementId: number,
): Promise<DealerAgreementDetailVOModel> {
  const response = await http.get<DealerAgreementDetailVO>({
    url: API_FI.DEALER_AGREEMENTS.DEALER_AGREEMENT_INTERFACE(dealerAgreementId),
  });

  return formattingToDealerAgreementDetailVOModel(response);
}

export async function requestFiDealerAgreementByKeyUnionInterface(
  data: FiDealerAgreementByKeyUnionInterfaceRequest,
): Promise<DealerAgreementDetailVOModel> {
  const response = await http.get<DealerAgreementDetailVO>({
    url: API_FI.DEALER_AGREEMENTS.DEALER_AGREEMENT_BY_KEY_UNION_INTERFACE,
    data,
  });

  return formattingToDealerAgreementDetailVOModel(response);
}

export async function requestTempFinancierDealerAgreementList(
  pageNumber: number,
  rowCount: number,
  searchData: TempFinancierDealerAgreementListRequest,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_FI.DEALER_AGREEMENTS.TEMP_DEALER_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchData,
    },
  });

  const dealerAgreementList = response.content.map(data => formattingToDealerAgreementVOModel(data));

  return {
    ...response,
    content: dealerAgreementList,
  };
}

export async function requestFinancierAnchorAgreementRelatedDealerAgreement(
  dealerAgreementId: number,
): Promise<AnchorAgreementVOModel> {
  const response = await http.get<AnchorAgreementVO>({
    url: API_FI.DEALER_AGREEMENTS.ANCHOR_AGREEMENT_RELATED_DEALER_AGREEMENT(dealerAgreementId),
  });

  return formattingToAnchorAgreementVOModel(response);
}

export async function requestFinancierAnchorAgreementLimitInfoRelatedDealerAgreement(
  dealerAgreementId: number,
): Promise<RelatedAnchorClientLimitInfoVOModel> {
  const response = await http.get<RelatedAnchorClientLimitInfoVO>({
    url: API_FI.DEALER_AGREEMENTS.ANCHOR_CLIENT_LIMIT_INFO_RELATED_DEALER_AGREEMENT(dealerAgreementId),
  });

  return formattingToRelatedAnchorClientLimitInfoVOModel(response);
}

export async function requestFinancierDealerAuthorizerInviteUrl(dealerAgreementId: number): Promise<string | null> {
  const response = await http.get<string | null>({
    url: API_FI.DEALER_AGREEMENTS.DEALER_INVITE_URL(dealerAgreementId),
  });

  return response;
}
