import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { EXTENSION_REQUEST_STATUS, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { ExtensionRequestDetailVOModel } from 'models/vo/ExtensionRequestDetailVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { getDayTerm } from 'utils/date/date';
import { requestSystemExtensionDetail } from 'utils/http/api/system/extension-requests';
import { requestSystemLoanDetail } from 'utils/http/api/system/loans';
import { getTotalInterestRate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableNumberValueIsZero } from 'utils/valueManager/ValueManager';

function SystemExtensionDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();
  const extensionRequestId = (useParams() as any).id;
  const modal = useModal();

  const [systemExtensionDetail, setSystemExtensionDetail] = useState<ExtensionRequestDetailVOModel>();
  const [systemLoanDetail, setSystemLoanDetail] = useState<LoanDetailVOModel>();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const systemExtensionDetailData = await requestSystemExtensionDetail(extensionRequestId);
      const systemLoanDetailData = await requestSystemLoanDetail(systemExtensionDetailData.loanId);

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemExtensionDetail(systemExtensionDetailData);
        setSystemLoanDetail(systemLoanDetailData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const isExtensionStatusRequested = () => {
    return systemExtensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.REQUESTED;
  };

  const onClickExtensionRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EXTENSION_REQUEST_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="EXTENSION_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickFinancingStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderExtensionRequestInfo = () => {
    return (
      <div className="content-area">
        <SectionTitle title={t('text:Request_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Requested_Date')}
                value={systemExtensionDetail?.createdDateTime}
                format="datetime"
              />
              <FormValue col={3} label={t('text:Partner_Name')} value={systemExtensionDetail?.dealerClientName} />
              <FormValue
                col={3}
                label={t('text:Partner_Client_Code')}
                value={systemExtensionDetail?.dealerClientCode}
              />
              <FormValue
                col={3}
                className={getStatusBadgeClass(
                  'EXTENSION_REQUEST_STATUS',
                  systemExtensionDetail?.extensionRequestStatus,
                )}
                label={t('text:Status')}
                value={t(`code:extension-request-status.${systemExtensionDetail?.extensionRequestStatus}`)}
                format="code"
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickExtensionRequestStatus}
              />
            </div>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Requested_Repayment_Date')}
                format="date"
                value={systemExtensionDetail?.extendedRepaymentDate}
              />
              <FormValue
                col={3}
                label={t('text:Original_Repayment_Date')}
                format="date"
                value={
                  systemExtensionDetail?.extensionStartDate &&
                  dayjs(systemExtensionDetail?.extensionStartDate, 'YYYY-MM-DD').subtract(1, 'days')
                }
              />
              <FormValue
                col={3}
                label={t('text:Remaining_Requests')}
                format="number"
                value={Number(systemLoanDetail?.maxExtensibleLoanCount) - Number(systemLoanDetail?.extendedLoanCount)}
              />
            </div>
          </FormContents>

          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Financing_Information')}>
            <div className="flex-end align-items-center me-3">
              <span className="me-1">{t('text:Go_to_Financing_Details')}</span>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                className="flex-column-center"
                onClick={() => {
                  history.push(ROUTES_SY.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(systemLoanDetail?.loanId!), {
                    successInvoiceId: systemLoanDetail?.invoiceId,
                    successArId: systemLoanDetail?.successArId,
                    collateralType: systemLoanDetail?.collateralType,
                  });
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </FormSubtitle>
          <FormContents backGroundType={BackGroundType.Gray}>
            <div className="row">
              <FormValue col={3} label={t('text:Financier_Name')} value={systemExtensionDetail?.financierName} />
              <FormValue col={3} label={t('text:Financier_Financing_ID')} value={systemLoanDetail?.financierLoanId} />
              <FormValue col={3} label={t('text:Platform_Financing_ID')} value={systemLoanDetail?.loanId} />
              <FormValue
                col={3}
                label={t('text:Financing_Amount')}
                value={systemLoanDetail?.principalAmount}
                format="number"
              />
            </div>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Disbursed_Date')}
                value={systemLoanDetail?.disbursedDate}
                format="date"
              />
              <FormValue
                col={3}
                label={t('text:Initial_Repayment_Date')}
                value={systemLoanDetail?.originalRepaymentDate}
                format="date"
              />
              <FormValue
                col={3}
                label={t('text:Total_Interest_Rate_(APR)')}
                value={getTotalInterestRate(
                  systemLoanDetail?.disbursedBasisInterestRate!,
                  systemLoanDetail?.disbursedTotalInterestRateWithoutBasis!,
                )}
              />
              <FormValue
                col={3}
                className={getStatusBadgeClass('LOAN_STATUS', systemLoanDetail?.loanStatus)}
                label={t('text:Financing_Status')}
                format="code"
                value={t(`code:financing-status.${systemLoanDetail?.loanStatus}`)}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickFinancingStatus}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    );
  };

  const renderExtensionConfirmationInfo = () => {
    const isOperatorAction = () => {
      return (
        systemExtensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.PREAPPROVED ||
        systemExtensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.REJECTED
      );
    };

    const renderConfirmationPersonInfo = () => {
      return (
        <>
          <FormSubtitle title={t('text:Authorization')} backGroundType={BackGroundType.DarkGray} />
          <FormContents backGroundType={BackGroundType.Gray}>
            <div className="row">
              <FormValue col={3} label={t('text:Financier_Name')} value={systemExtensionDetail?.financierName} />
              <FormValue
                col={3}
                label={t('text:User_Name')}
                value={
                  isOperatorAction() ? systemExtensionDetail?.preApprovedName : systemExtensionDetail?.approvedName
                }
              />
              <FormValue
                col={3}
                label={t('text:Authority')}
                format="code"
                value={
                  isOperatorAction()
                    ? t(`code:authority-type.${systemExtensionDetail?.preApprovedAuthorityType}`)
                    : t(`code:authority-type.${systemExtensionDetail?.approvedAuthorityType}`)
                }
              />
              <FormValue
                col={3}
                label={t('text:Approved_Date')}
                format="datetime"
                value={
                  isOperatorAction()
                    ? systemExtensionDetail?.preApprovedDateTime
                    : systemExtensionDetail?.approvedDateTime
                }
              />
            </div>
          </FormContents>
        </>
      );
    };

    return (
      <div className="content-area">
        <SectionTitle title={t('text:Approved_Extension_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Modified_Repayment_Date')}
                format="date"
                value={systemExtensionDetail?.extendedRepaymentDate}
              />
              <FormValue
                col={3}
                label={t('text:Start_Date_of_Financing_Extension')}
                format="date"
                value={systemExtensionDetail?.extensionStartDate}
              />
              <FormValue
                col={3}
                label={t('text:Financing_Extension_Period')}
                value={getDayTerm(
                  systemExtensionDetail?.extensionStartDate,
                  systemExtensionDetail?.extendedRepaymentDate,
                  { includeStartDate: true, withText: true },
                )}
              />
              <FormValue
                col={3}
                label={t('text:Added_Spread_(APR)')}
                value={tableNumberValueIsZero(systemExtensionDetail?.additionalRate)}
              />
            </div>
          </FormContents>
          {renderConfirmationPersonInfo()}
        </FormBorder>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Extension_Request_Details')} />
      {renderExtensionRequestInfo()}
      {!isExtensionStatusRequested() && renderExtensionConfirmationInfo()}
    </>
  );
}

export default SystemExtensionDetail;
