import type Pageable from 'models/Pageable';

import { formattingToTempWaitingArVOModel } from './TempWaitingArVO';

import type { TempWaitingArVO, TempWaitingArVOModel } from './TempWaitingArVO';

export interface TempArSummaryVO {
  tempArSummaryId: number;
  uploadEnterpriseId: number;
  uploadEnterpriseName: string;
  uploadUserId: number;
  uploadUserName: string;
  tempArList: Pageable<TempWaitingArVO[]>;
}

export interface TempArSummaryVOModel extends TempArSummaryVO {
  tempArList: Pageable<TempWaitingArVOModel[]>;
}

export function formattingToTempArSummaryVOModel(data: TempArSummaryVO): TempArSummaryVOModel {
  const tempArList: TempWaitingArVOModel[] = data.tempArList
    ? data.tempArList.content.map((data: TempWaitingArVO) => formattingToTempWaitingArVOModel(data))
    : [];
  const tempArPage: Pageable<TempWaitingArVOModel[]> = {
    ...data.tempArList,
    content: tempArList,
  };

  return {
    ...data,
    tempArList: tempArPage,
  };
}
