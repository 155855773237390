import type Pageable from 'models/Pageable';
import type { DashBoardApprovalVO, DashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import { formattingToDashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import type { DashBoardScheduleVO, DashBoardScheduleVOModel } from 'models/vo/DashBoardScheduleVO';
import { formattingToDashBoardScheduleVOModel } from 'models/vo/DashBoardScheduleVO';
import type {
  DashboardPartnerPerformanceVO,
  DashboardPartnerPerformanceVOModel,
} from 'models/vo/DashboardPartnerPerformanceVO';
import { formattingToDashboardPartnerPerformanceVOModel } from 'models/vo/DashboardPartnerPerformanceVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { PaDashboardPerformanceListRequest, PaDashboardScheduleListRequest } from './request';

export const requestPartnerInternalApproval = async (
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<DashBoardApprovalVOModel[]>> => {
  const response = await http.get<Pageable<DashBoardApprovalVO[]>>({
    url: API_DE.DASHBOARD.INTERNAL_APPROVAL,
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashBoardApprovalVOModel(item)),
  };
};

export const requestPartnerTaskSchedule = async (
  pageNumber: number,
  rowCount: number,
  requestValue: PaDashboardScheduleListRequest,
): Promise<Pageable<DashBoardScheduleVOModel[]>> => {
  const response = await http.get<Pageable<DashBoardScheduleVO[]>>({
    url: API_DE.DASHBOARD.TASK_SCHEDULE,
    data: {
      pageNumber,
      rowCount,
      ...requestValue,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashBoardScheduleVOModel(item)),
  };
};

export const requestPartnerPerformance = async (
  requestValue: PaDashboardPerformanceListRequest,
): Promise<DashboardPartnerPerformanceVOModel> => {
  const response = await http.get<DashboardPartnerPerformanceVO>({
    url: API_DE.DASHBOARD.PERFORMANCE,
    data: requestValue,
  });

  return formattingToDashboardPartnerPerformanceVOModel(response);
};
