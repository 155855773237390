import { useTranslation } from 'react-i18next';

import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { LOAN_STATUS } from 'enums';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';

interface AnchorFinancingDetailGuideMessageProps {
  data: AnchorLoanDetailVOModel;
}

function AnchorFinancingDetailGuideMessage({ data }: AnchorFinancingDetailGuideMessageProps) {
  const { t } = useTranslation(['format']);

  const renderGuideMessage = (code: LOAN_STATUS | undefined) => {
    switch (code) {
      case LOAN_STATUS.DEALER_CANCELLED:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_application_has_been_cancelled'),
              t('text:Please_check_the_reason_for_the_cancellation_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_CANCELLATION')}
            reason={data.cancelReason}
          />
        );
      case LOAN_STATUS.REJECTED:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_application_has_been_rejected'),
              t('text:Please_check_the_reason_below_and_contact_the_responsible_branch_for_further_details'),
            ]}
            reasonTitle={t('text:REASON_FOR_REJECTION')}
            reason={data.rejectReason}
          />
        );
      case LOAN_STATUS.EXPIRATION:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:Financing_has_expired'),
              t('text:Please_check_the_reason_below_and_contact_the_responsible_branch_for_further_details'),
            ]}
            reasonTitle={t('text:REASON_FOR_EXPIRATION')}
            reason={data.expirationReason}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderGuideMessage(data.loanStatus)}</>;
}

export default AnchorFinancingDetailGuideMessage;
