import { useState } from 'react';

export function useArrayState<T>() {
  const [array, setArray] = useState<T[]>([]);

  // todo value type
  const update = (targetIndex: number, value: any) => {
    const prev = [...array];
    prev[targetIndex] = value;
    setArray(prev);
  };

  const remove = (targetIndex: number) => {
    const newEditable = array.filter((t, index) => targetIndex !== index);
    setArray(newEditable);
  };

  const append = (value: any) => {
    setArray(array?.length === 0 ? [value] : prevState => [...prevState, value]);
  };

  const initialize = (value: T[]) => {
    const updateState: T[] = [];
    value.forEach(item => {
      updateState.push(item);
    });
    setArray(updateState);
  };

  return {
    arr: array,
    update,
    remove,
    append,
    initialize,
  };
}
