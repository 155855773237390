import type React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import Accordion from 'components/stateless/Accordion/Accordion';
import Button, { ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormContents, FormValue } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import Exporter from 'components/stateless/Exporter/Exporter';
import { getWaitingApResultText } from 'components/stateless/ResultTextByStatus/WaitingApResultText';
import WaitingArResultText from 'components/stateless/ResultTextByStatus/WaitingArResultText';
import { TableBody, TableBorder, TableHeader } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { EXPORT_DOCUMENT_TYPE, ROLE, WAITING_AR_STATUS } from 'enums';
import useProgressStatus from 'hooks/useProgressStatus';
import type Pageable from 'models/Pageable';
import type { ArPhaseVOModel } from 'models/vo/ArPhaseVO';
import type { ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import type { WaitingArVOModel } from 'models/vo/WaitingArVO';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import ToggleButton from '../stateless/Button/ToggleButton';

type ApAccordionListPropsType = {
  onAccordionClick: () => Promise<Pageable<WaitingArVOModel[]>>;
  searchedWaitingApprovalPhaseId: number[];
  setSearchedWaitingApprovalPhaseId: React.Dispatch<React.SetStateAction<number[]>>;
  arSummaryData: ArSummaryVOModel;
  arSummaryPhase: ArPhaseVOModel;
};

const ApAccordionList = ({
  onAccordionClick,
  searchedWaitingApprovalPhaseId,
  setSearchedWaitingApprovalPhaseId,
  arSummaryData,
  arSummaryPhase,
}: ApAccordionListPropsType) => {
  const { t } = useTranslation();
  const modal = useModal();
  const role = getSignIn()?.authorities[0].authority;

  const { progressStatusText, progressStatusClassName, progressReviewedTimeText } = useProgressStatus(
    arSummaryData,
    arSummaryPhase,
    'Ar',
  );

  const [arList, setArList] = useState<WaitingArVOModel[]>([]);
  const [turnOnInvalidFilter, setTurnOnInvalidFilter] = useState(false);

  const getUniquePartnerNameCount = () => {
    const allPartnerNames = arList?.map(item => item.partnerName);
    const uniquePartnerNames = new Set(allPartnerNames);

    return uniquePartnerNames.size;
  };

  const totalUploadedAPCount = arList?.length || 0;
  const validAPCount = useMemo(() => {
    return arList?.filter(ar => ar.waitingArStatus === WAITING_AR_STATUS.REGISTERED).length || 0;
  }, [arList]);
  const invalidAPCount = totalUploadedAPCount - validAPCount;
  const uniquePartnerNameCount = useMemo(getUniquePartnerNameCount, [arList]);

  const AR_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      colWidths: 50,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Partner_Tax_Code'),
      colWidths: 180,
    },
    {
      headerText:
        role === ROLE.ROLE_ANCHOR ? t('text:AP_Number_(Tax_Invoice_Number)') : t('text:AR_Number_(Tax_Invoice_Number)'),
      colWidths: 220,
    },
    {
      headerText: role === ROLE.ROLE_ANCHOR ? t('text:AP_Issued_Date') : t('text:AR_Issued_Date'),
      colWidths: 170,
    },
    {
      headerText: t('text:Settlement_Date'),
      colWidths: 180,
    },
    {
      headerText: role === ROLE.ROLE_ANCHOR ? t('text:AP_Amount') : t('text:AR_Amount'),
      colWidths: 160,
    },
    {
      headerText: t('text:Validation_Result'),
      colWidths: 170,
    },
  ];

  const onClickArContainer = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    arPhaseData: ArPhaseVOModel,
  ): Promise<void> => {
    event.preventDefault();
    if (searchedWaitingApprovalPhaseId.includes(arPhaseData.waitingArApprovalPhaseId)) return;
    else {
      try {
        const waitingArResponse = await onAccordionClick();
        setSearchedWaitingApprovalPhaseId(prevState => [...prevState, arPhaseData.waitingArApprovalPhaseId]);
        setArList(waitingArResponse?.content ?? []);
      } catch (e: any) {
        modal.show(e);
      }
    }
  };

  const excelColumns: ColumnOption<any>[] = [
    {
      header: t('text:Partner_Name'),
      key: 'partnerName',
      width: 30,
    },
    {
      header: t('text:Partner_Tax_Code'),
      key: 'partnerTaxCode',
      width: 30,
    },
    {
      header:
        role === ROLE.ROLE_ANCHOR ? t('text:AP_Number_(Tax_Invoice_Number)') : t('text:AR_Number_(Tax_Invoice_Number)'),
      key: 'arNumber',
      width: 35,
    },
    {
      header: role === ROLE.ROLE_ANCHOR ? t('text:AP_Issued_Date') : t('text:AR_Issued_Date'),
      key: 'arIssuedDate',
      width: 25,
    },
    {
      header: t('text:Settlement_Date'),
      key: 'settlementDate',
      width: 30,
    },
    {
      header: role === ROLE.ROLE_ANCHOR ? t('text:AP_Amount') : t('text:AR_Amount'),
      key: 'arAmount',
      width: 30,
    },
    {
      header: t('text:Validation_Result'),
      key: 'waitingArStatus',
      width: 80,
    },
  ];

  const jsonArrayData: any[] = arList.map(data => ({
    partnerName: tableValueManage(data.partnerName),
    partnerTaxCode: tableValueManage(data.partnerTaxCode),
    arNumber: tableValueManage(data.arNumber),
    arAmount: tableValueManage(data.arAmount),
    arIssuedDate: t('format:original-date', {
      value: data.arIssuedDate,
      key: 'original-date',
    }),
    settlementDate: t('format:original-date', {
      value: data.settlementDate,
      key: 'original-date',
    }),
    waitingArStatus: getWaitingApResultText(data.waitingArStatus, data.exceptionMessage),
  }));

  const handleClickExportButton = async () => {
    const excelExporterProps: ExportSpreadSheetProps<any> = {
      jsonArrayData,
      columns: excelColumns,
      options: {
        rowHeight: [{ position: 1, height: 30 }],
      },
      exportDocumentType: [EXPORT_DOCUMENT_TYPE.EXCEL, EXPORT_DOCUMENT_TYPE.CSV],
    };

    modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} />, {
      title: t('text:Export_File'),
      closeBtnText: t('text:Close'),
    });
  };

  const handleToggleClick = () => {
    setTurnOnInvalidFilter(prevState => !prevState);
  };

  return (
    <Accordion
      className="accordion accordion-information-form"
      id={`ar-list-${arSummaryPhase.waitingArApprovalPhaseId}`}
    >
      <Accordion.Header id={`ar-list-header-${arSummaryPhase.waitingArApprovalPhaseId}`}>
        <Accordion.Trigger onClick={event => onClickArContainer(event, arSummaryPhase)}>
          <div className="accordion-information-form__head">
            <div className={progressStatusClassName}>{progressStatusText}</div>
            <div className="accordion-information-form__head--text">
              <div className="fw-bold">
                {tableValueManage(t(`code:authority-type.${arSummaryPhase.approveUserAuthorityType}`))} (
                {arSummaryPhase.approveUserName}, {arSummaryPhase.approveUserLoginId})
              </div>
              {arSummaryPhase.approveEnterpriseName}
            </div>
            <div className="accordion-information-form__head--text">
              <div className="fw-bold">{progressReviewedTimeText}</div>
              {t('format:datetime', { value: arSummaryPhase.createdDateTime, key: 'date' })}
            </div>
          </div>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className="accordion-body">
        <SectionTitle title={t('text:Summary')} />
        <div className="information-form-border border-all mb-4">
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Number_of_Uploaded_Partner')}
                col={4}
                className="information-form__input border-none text-bold-sub700"
                value={uniquePartnerNameCount}
                format="number"
              />
              <FormValue
                label={t('text:Total_Amount_of_Valid_AP')}
                col={4}
                className="information-form__input border-none text-bold-sub700"
                value={arSummaryPhase.amount}
                format="number"
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Number_of_Uploaded_AP')}
                col={4}
                className="information-form__input border-none text-bold-sub700"
                value={totalUploadedAPCount}
                format="number"
              />
              <FormValue
                label={t('text:Number_of_Valid_AP')}
                col={4}
                className="information-form__input border-none text-bold-sub700"
                value={validAPCount}
                format="number"
              />
              <FormValue
                label={t('text:Number_of_Invalid_AP')}
                col={4}
                className="information-form__input border-none text-bold-sub700"
                value={invalidAPCount}
                format="number"
              />
            </div>
          </FormContents>
        </div>

        <div className="d-flex pb-3">
          <Button onClick={handleClickExportButton} variant={ButtonVariantEnum.OUTLINED} size={ButtonSizeEnum.SM}>
            {t('text:Export')}
          </Button>
          <div className="d-flex align-items-center ps-3">
            <ToggleButton isActive={turnOnInvalidFilter} onClick={handleToggleClick} />
            <p className="ms-3">{t('text:Show_only_invalid_APs')}</p>
          </div>
        </div>

        <TableBorder scrollYMaxHeight={600}>
          <TableHeader header={AR_LIST_TABLE_HEADERS} backGroundType={BackGroundType.DarkGray} />
          <TableBody numOfCol={AR_LIST_TABLE_HEADERS?.length}>
            <tr
              className={clsx({
                'd-none': !turnOnInvalidFilter || invalidAPCount > 0,
              })}
            >
              <td colSpan={8} className="text-center fs-7 fw-normal">
                {t('text:There_are_no_invalid_AP')}
              </td>
            </tr>
            {arList.map((ar, index) => (
              <tr
                key={index}
                className={clsx({
                  'd-none': turnOnInvalidFilter && ar.waitingArStatus === WAITING_AR_STATUS.REGISTERED,
                })}
              >
                <td className="text-center">{index + 1}</td>
                <td>{tableValueManage(ar.partnerName)}</td>
                <td>{tableValueManage(ar.partnerTaxCode)}</td>
                <td>{tableValueManage(ar.arNumber)}</td>
                <td>{tableValueManage(ar.arIssuedDate, t('format:date', { value: ar.arIssuedDate, key: 'date' }))}</td>
                <td>
                  {tableValueManage(ar.settlementDate, t('format:date', { value: ar.settlementDate, key: 'date' }))}
                </td>
                <td>{tableValueManage(t('format:number', { value: ar.arAmount }))}</td>
                <WaitingArResultText status={ar.waitingArStatus} exceptionMessage={ar.exceptionMessage} />
              </tr>
            ))}
          </TableBody>
        </TableBorder>
      </Accordion.Content>
    </Accordion>
  );
};

export default ApAccordionList;
