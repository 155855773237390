import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { removeRangeFromHoliday } from 'utils/date/date';

import type { AnchorInvoiceBulkFinancingApplicationStep2State } from '../../useAnchorInvoiceBulkFinancingApplicationStep2State';

interface useFinancingRequestProps {
  anchorInvoiceBulkApplicationStep2State: AnchorInvoiceBulkFinancingApplicationStep2State;
  disbursementDate: string | undefined;
  totalRequestedAmount: string;
}

export function useFinancingRequest({
  anchorInvoiceBulkApplicationStep2State,
  disbursementDate,
  totalRequestedAmount,
}: useFinancingRequestProps) {
  const { t } = useTranslation(['format']);
  const { setValue } = useFormContext();
  const { anchorFinancingOptionDetail, financierHoliday } = anchorInvoiceBulkApplicationStep2State;

  const [applicationFileName, setApplicationFileName] = useState<string | undefined>(undefined);
  const [agreementFileName, setAgreementFileName] = useState<string | undefined>(undefined);

  const disabledRanges = useMemo((): Date[] | [] => {
    return removeRangeFromHoliday(financierHoliday?.content);
  }, [financierHoliday?.content]);

  const disabledEstimateButton = (): boolean => {
    if (!disbursementDate) {
      return true;
    }

    if (!totalRequestedAmount || totalRequestedAmount === '0') {
      return true;
    }

    return false;
  };

  const returnAmountWithCurrency = (amount: number | string | undefined) => {
    return `${t('format:number', {
      value: amount,
    })} ${anchorFinancingOptionDetail?.currencyType}`;
  };

  const onChangeApplicationFileName = (e: any) => {
    if (e.target?.files[0]?.name) setApplicationFileName(e.target.files[0].name);
  };

  const resetApplicationFileName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setValue('loanRequestAttachment', null);
    setApplicationFileName(undefined);
  };

  const onChangeAgreementFileName = (e: any) => {
    if (e.target?.files[0]?.name) setAgreementFileName(e.target.files[0].name);
  };

  const resetAgreementFileName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setValue('loanAgreementAttachment', null);
    setAgreementFileName(undefined);
  };

  return {
    applicationFileName,
    agreementFileName,
    disabledRanges,
    disabledEstimateButton,
    returnAmountWithCurrency,
    onChangeApplicationFileName,
    resetApplicationFileName,
    onChangeAgreementFileName,
    resetAgreementFileName,
  };
}
