import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import RequiredAdditionalDocumentList from 'components/loan/RequiredAdditionalDocumentList';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, LOAN_APPROVAL_TYPE, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { SignInModel } from 'models/SignInModel';
import { isLoanDisbursed } from 'utils/status';
import { getSignIn } from 'utils/storage/LocalStorage';

import FinancierFinancingDetailAdditionalDocuments from './sections/additional-documents';
import FinancierFinancingDetailArInformation from './sections/ar-information';
import FinancierFinancingDetailDesignatedBankAccountInformation from './sections/designated-bank-account-information';
import FinancierFinancingDetailEarlyRepaymentRequestInformation from './sections/early-repayment-request-information';
import FinancierFinancingDetailExtensionHistory from './sections/financing-extension-history';
import FinancierFinancingDetailFinancingRequestInformation from './sections/financing-request-information';
import FinancierFinancingDetailGuideMessage from './sections/guide-message';
import FinancierFinancingDetailInvoiceInformation from './sections/invoice-information';
import FinancierFinancingDetailPurposeOfFinancingList from './sections/purpose-of-financing';
import FinancierFinancingDetailRepaymentTransactionHistory from './sections/repayment-transaction-history';
import FinancierFinancingDetailSummary from './sections/summary';
import { useFinancierFinancingDetailState } from './useFinancierFinancingDetailState';

function FinancierFinancingDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const signInModel: SignInModel | null = getSignIn();
  const history = useHistory();

  const [financingInfoToggle, setFinancingInfoToggle] = useState<boolean>(false);
  const [collateralTypeInfoToggle, setCollateralTypeInfoToggle] = useState<boolean>(false);
  const [bankAccountInfoToggle, setBankAccountInfoToggle] = useState<boolean>(false);
  const [checkedRequiredDocumentsIds, setCheckedRequiredDocumentsIds] = useState<number[]>([]);

  const { state, fetchAll, pageables, paginates, updateFinancingStatusActions } = useFinancierFinancingDetailState();

  const {
    fiLoanDetail,
    useInterface,
    fiArDetail,
    fiInvoiceDetail,
    fiEarlyRepaymentList,
    fiExtensionList,
    fiLoanTransactionHistoryPage,
    isNilInvoiceIdAndSuccessArId,
    requiredAdditionalDocuments,
  } = state;

  const {
    loanStatus,
    loanApprovalType,
    collateralType,
    purposeOfLoanList,
    totalPurposeOfLoanAmount,
    loanId,
    loanEtcAttachment,
    factoringEnable,
    multipleRequested,
    multipleLoanRequestId,
  } = fiLoanDetail;

  const {
    onClickRenewLoanTransactions,
    onClickDeleteLoanTransactions,
    onClickConfirmRequest,
    onClickApproveLoanDisbursement,
    onClickRevertRequest,
    onClickRejectRequest,
    onClickViewExpectedRepaymentAmount,
  } = updateFinancingStatusActions;

  const isOperatorRequest =
    signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR &&
    loanStatus === LOAN_STATUS.DEALER_REQUESTED &&
    (!loanApprovalType || loanApprovalType === LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL);

  const isAuthorizerRequest =
    signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER &&
    loanStatus === LOAN_STATUS.DEALER_REQUESTED &&
    loanApprovalType === LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    if (isOperatorRequest) {
      setFinancingInfoToggle(false);
      setCollateralTypeInfoToggle(false);
      setBankAccountInfoToggle(false);
    }
  }, [isOperatorRequest]);

  const hardCopyConditionCheckboxValidator = () => {
    if (requiredAdditionalDocuments.length < 1) {
      return true;
    }

    const requiredCheckboxes = requiredAdditionalDocuments.filter(document => document.hardCopyCondition.required);

    return requiredCheckboxes.length === checkedRequiredDocumentsIds.length;
  };

  const renderButton = () => {
    if (isOperatorRequest) {
      return (
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
            onClick={onClickRejectRequest}
          >
            {t('text:Reject')}
          </Button>
          <Button
            size={ButtonSizeEnum.LG}
            disabled={
              !(
                financingInfoToggle &&
                collateralTypeInfoToggle &&
                bankAccountInfoToggle &&
                hardCopyConditionCheckboxValidator()
              )
            }
            onClick={onClickConfirmRequest}
            className="ms-2"
          >
            {t('text:Confirm')}
          </Button>
        </div>
      );
    }

    if (isAuthorizerRequest) {
      return (
        <div className="flex-end mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.RED}
            variant={ButtonVariantEnum.OUTLINED}
            onClick={onClickRevertRequest}
          >
            {t('text:Revert')}
          </Button>
          <Button size={ButtonSizeEnum.LG} className="ms-3" onClick={onClickApproveLoanDisbursement}>
            {t('text:Confirm')}
          </Button>
        </div>
      );
    }
  };

  const handleRequiredAdditionalDocumentCheckboxChange = (id: number) => {
    setCheckedRequiredDocumentsIds(prevCheckedIds =>
      prevCheckedIds.includes(id) ? prevCheckedIds.filter(itemId => itemId !== id) : [...prevCheckedIds, id],
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Details')} />
      <FinancierFinancingDetailGuideMessage
        useInterface={useInterface}
        loanDetailData={fiLoanDetail}
        updateFinancingStatusActions={updateFinancingStatusActions}
      />
      <FinancierFinancingDetailSummary
        data={fiLoanDetail}
        factoringEnable={factoringEnable}
        useInterface={useInterface}
        onClickViewExpectedRepaymentAmount={onClickViewExpectedRepaymentAmount}
      />
      <FinancierFinancingDetailFinancingRequestInformation
        data={fiLoanDetail}
        factoringEnable={factoringEnable}
        isOperatorRequest={isOperatorRequest}
        financingInfoToggleState={{
          financingInfoToggle,
          setFinancingInfoToggle,
        }}
      />
      {!isNilInvoiceIdAndSuccessArId && collateralType === COLLATERAL_TYPE.INVOICE && (
        <FinancierFinancingDetailInvoiceInformation
          invoiceData={fiInvoiceDetail}
          loanData={fiLoanDetail}
          isOperatorRequest={isOperatorRequest}
          collateralTypeInfoToggleState={{
            collateralTypeInfoToggle,
            setCollateralTypeInfoToggle,
          }}
        />
      )}
      {!isNilInvoiceIdAndSuccessArId && collateralType === COLLATERAL_TYPE.AR && (
        <FinancierFinancingDetailArInformation
          arData={fiArDetail}
          loanData={fiLoanDetail}
          isOperatorRequest={isOperatorRequest}
          factoringEnable={factoringEnable}
          collateralTypeInfoToggleState={{
            collateralTypeInfoToggle,
            setCollateralTypeInfoToggle,
          }}
        />
      )}
      {collateralType === COLLATERAL_TYPE.AR && purposeOfLoanList && !factoringEnable && (
        <FinancierFinancingDetailPurposeOfFinancingList
          purposeListData={purposeOfLoanList}
          totalPurposeOfLoanAmount={totalPurposeOfLoanAmount}
        />
      )}
      <FinancierFinancingDetailDesignatedBankAccountInformation
        data={fiLoanDetail}
        factoringEnable={factoringEnable}
        bankAccountInfoToggleState={{
          bankAccountInfoToggle,
          setBankAccountInfoToggle,
        }}
        isOperatorRequest={isOperatorRequest}
      />
      {!multipleRequested && (
        <>
          <div className="content-area">
            <FinancierFinancingDetailAdditionalDocuments
              loanId={loanId}
              loanEtcAttachmentListData={loanEtcAttachment}
            />
          </div>
          <RequiredAdditionalDocumentList
            requiredAdditionalDocuments={state.requiredAdditionalDocuments}
            isRenderConfirmCheckbox={isOperatorRequest}
            checkedRequiredDocumentsIds={checkedRequiredDocumentsIds}
            handleRequiredAdditionalDocumentCheckboxChange={handleRequiredAdditionalDocumentCheckboxChange}
          />
          <div className="content-area">{renderButton()}</div>
        </>
      )}
      {isLoanDisbursed(loanStatus) && (
        <FinancierFinancingDetailRepaymentTransactionHistory
          useInterface={useInterface}
          loanStatus={loanStatus}
          loanTransactionHistoryData={fiLoanTransactionHistoryPage}
          onClickDeleteLoanTransactions={onClickDeleteLoanTransactions}
          onClickRenewLoanTransactions={onClickRenewLoanTransactions}
          factoringEnable={factoringEnable}
          pageable={pageables.loanTransactionHistoryPageable}
          paginate={paginates.getLoanTransactionHistoryPaginate}
        />
      )}
      {/* 조기 상환 신청 내역 */}
      {fiEarlyRepaymentList?.length !== 0 && (
        <FinancierFinancingDetailEarlyRepaymentRequestInformation
          earlyRepaymentListData={fiEarlyRepaymentList}
          pageable={pageables.earlyRepaymentPageable}
          paginate={paginates.getEarlyRepaymentListPaginate}
        />
      )}
      {/* 만기 연장 결재 내역 */}
      {fiExtensionList?.length !== 0 && (
        <FinancierFinancingDetailExtensionHistory
          extensionListData={fiExtensionList}
          pageable={pageables.extensionPageable}
          paginate={paginates.getExtensionListPaginate}
        />
      )}
      {multipleRequested && (
        <div className="content-area flex-end">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={() => {
              history.push(ROUTES_FI.MANAGE_FINANCING.BULK_APPROVAL_DETAIL_BUILD_PATH(multipleLoanRequestId));
            }}
          >
            {t('text:Go_to_Bulk_Financing_Page')}
          </Button>
        </div>
      )}
    </>
  );
}

export default FinancierFinancingDetail;
