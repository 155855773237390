import i18n from 'i18next';

import type { AcquisitionTabType } from '../FinancierScPartnerAcquisitionListTabWrapper';

export const renderBranchNameText = (tab: AcquisitionTabType) => {
  switch (tab) {
    case 'completed':
      return i18n.t('text:Supplier_Agreement_Branch_Name');
    case 'inProgress':
    case 'rejected':
      return i18n.t('text:Reviewed_Branch_Name');
    case 'submitted':
      return i18n.t('text:Requested_Branch_Name');
  }
};

export const renderBranchCodeText = (tab: AcquisitionTabType) => {
  switch (tab) {
    case 'completed':
      return i18n.t('text:Supplier_Agreement_Branch_Code');
    case 'inProgress':
    case 'rejected':
      return i18n.t('text:Reviewed_Branch_Code');
    case 'submitted':
      return i18n.t('text:Requested_Branch_Code');
  }
};

export const defineBranchInfoRequest = (tab: AcquisitionTabType, type: 'Name' | 'Code') => {
  switch (tab) {
    case 'completed':
      return `assignedBranch${type}`;
    case 'rejected':
    case 'inProgress':
      return `reviewedBranch${type}`;
    case 'submitted':
      return `requestBranch${type}`;
  }
};
