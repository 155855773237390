import type React from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import type { FormState, UseFormMethods } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { countBy, debounce, groupBy, isEmpty, mapValues, values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ToggleButton from 'components/stateless/Button/ToggleButton';
import Exporter from 'components/stateless/Exporter/Exporter';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import Value from 'components/stateless/Form/Value/Value';
import type { CURRENCY_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { BulkImportErrorObjArr } from 'utils/error/bulkImportManager';
import type {
  AnchorArListElementType,
  AnchorArPhaseApproveRequest,
  AnchorArRegisterRequest,
} from 'utils/http/api/anchor/ar-summaries/request';
import { updateErrors, updateErrorsRefWithFieldsRef } from 'utils/inputTable';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { invoiceOrArAmountInputValidate, invoiceOrArSettlementDateInputValidate } from 'utils/logic';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';
import { REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING } from 'utils/validation/regExp';
import useValidation from 'utils/validation/useValidation';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import type { TFunction } from 'i18next';

dayjs.extend(customParseFormat);

const MAX_ROW_LENGTH = 500;

interface APBulkTableData {
  anchorPartnerTaxCode: string;
  arNumber: string;
  arIssuedDate: string;
  settlementDate: string;
  arAmount: string | number;
  rowIndex: number;
}

const getFieldNames = (t: TFunction, options?: { currencyType?: CURRENCY_TYPE }) => {
  return {
    partnerName: {
      name: t('text:Partner_Name'),
    },
    partnerTaxCode: {
      name: t('text:Partner_Tax_Code'),
    },
    arNumber: {
      name: t('text:AP_Number(Tax_Invoice_Number)'),
    },
    arIssuedDate: {
      name: t('text:AP_Issued_Date'),
    },
    settlementDate: {
      name: t('text:Settlement_Date'),
    },
    arAmount: {
      name: `${t('text:AP_Amount')} (${t('text:unit')}: ${options?.currencyType ?? ''})`,
    },
  };
};

export type FieldValuesType = AnchorArRegisterRequest | AnchorArPhaseApproveRequest;
interface APInputTableProps {
  register: UseFormMethods<FieldValuesType>['register'];
  errors: UseFormMethods<FieldValuesType>['errors'];
  getValues: UseFormMethods<FieldValuesType>['getValues'];
  reset: UseFormMethods<FieldValuesType>['reset'];
  clearErrors: UseFormMethods<FieldValuesType>['clearErrors'];
  control: UseFormMethods<FieldValuesType>['control'];
  setValue: UseFormMethods<FieldValuesType>['setValue'];
  rows: string[];
  setRows: React.Dispatch<React.SetStateAction<string[]>>;
  checkedValidation: boolean[];
  setCheckedValidation: React.Dispatch<React.SetStateAction<boolean[]>>;
  totalApAmount: string;
  updateTotalApAmount: () => void;
  isSavedFields?: boolean;
  pageType: 'registration' | 'confirmation';
  initialSettlementDate?: string;
  currencyType?: CURRENCY_TYPE;
  minimumDate: Date;
  maximumDate: Date;
  financierHoliday?: Pageable<FinancierCalenderVOModel[]>;
  partnerNames: string[];
  setPartnerNames: React.Dispatch<React.SetStateAction<string[]>>;
  onChangePartnerTaxCode: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  anchorApBulkImportValidate?: () => Promise<void>;
  formState: FormState<AnchorArPhaseApproveRequest>;
  isValidPartnerName?: () => void;
}

function ApInputBulkTable({
  anchorApBulkImportValidate,
  isValidPartnerName,
  formState,
  register,
  errors,
  getValues,
  reset,
  clearErrors,
  control,
  setValue,
  rows,
  setRows,
  checkedValidation,
  setCheckedValidation,
  totalApAmount,
  isSavedFields = false,
  pageType,
  initialSettlementDate,
  currencyType,
  updateTotalApAmount,
  minimumDate,
  maximumDate,
  financierHoliday,
  partnerNames,
  setPartnerNames,
  onChangePartnerTaxCode,
}: APInputTableProps) {
  const { show: showModal } = useModal();
  const { t } = useTranslation(['format']);

  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [turnOnInvalidFilter, setTurnOnInvalidFilter] = useState(false);

  const date = new Date();
  const today = [date.getFullYear(), date.getMonth(), date.getDate()];

  const isAppendRowCalled = useRef(false);

  const fieldNames = getFieldNames(t, { currencyType: currencyType });

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: formState.errors.arList,
    rule: 'arList',
    accountType: 'AP',
    isSavedFields,
  });

  const handleToggleClick = () => {
    setTurnOnInvalidFilter(prevState => !prevState);
    setCheckedRows([]);
  };

  const appendRow = useCallback(
    (e: any) => {
      e.preventDefault();
      if (rows.length >= MAX_ROW_LENGTH) {
        showModal(
          <h6>
            {t('text:Allowed_N_rows_at_a_time', { number: MAX_ROW_LENGTH })} {t('text:Please_check_the_data_again')}
          </h6>,
        );
      } else {
        isAppendRowCalled.current = true;
        setRows([...rows, '']);
        clearErrors(`arList.${rows.length}.partnerTaxCode`);
        clearErrors(`arList.${rows.length}.arNumber`);
        clearErrors(`arList.${rows.length}.arIssuedDate`);
        clearErrors(`arList.${rows.length}.arAmount`);
      }
    },
    [clearErrors, rows, setRows, showModal, t],
  );

  useEffect(() => {
    if (isAppendRowCalled.current) {
      setValue(`arList.${rows.length - 1}.settlementDate`, initialSettlementDate);
      isAppendRowCalled.current = false;
    }
  }, [appendRow, initialSettlementDate, rows.length, setValue]);

  const removeRow = (e: any) => {
    e.preventDefault();
    isAppendRowCalled.current = false;
    const updateCheckedValidation = () => {
      let i = 0;
      const temp = [...checkedValidation];
      for (const index of checkedRows) {
        temp.splice(index - i, 1);
        i++;
      }

      return temp;
    };
    // UI
    const restRows = rows.filter((_, index) => !checkedRows.includes(index));
    const emptyRow = restRows.length === 0;

    const updatedPartnerNames = partnerNames.reduce((newPartnerNames, partnerName, index) => {
      if (checkedRows.includes(index)) return newPartnerNames;

      const currentIndex = newPartnerNames.length;
      newPartnerNames[currentIndex] = partnerName;

      return newPartnerNames;
    }, [] as string[]);

    setPartnerNames(updatedPartnerNames);

    if (emptyRow) {
      setRows(['']);
      setCheckedValidation([]);
      clearErrors();
    } else {
      setRows(restRows);
      setCheckedValidation(updateCheckedValidation());

      if (Array.isArray(errors.arList) && errors.arList.length > 0) {
        updateErrors(errors.arList, checkedRows);
        updateErrorsRefWithFieldsRef(errors.arList, control.fieldsRef.current, 'arList');
      }
    }
    // Data
    const { arList } = getValues();
    const restArList = arList.filter((_, index) => !checkedRows.includes(index));
    if (restArList.length === 0) {
      const arList = [
        {
          arNumber: undefined,
          arAmount: undefined,
          arIssuedDate: '',
          settlementDate: initialSettlementDate ?? '',
          partnerTaxCode: undefined,
        },
      ];
      reset(
        {
          arList: arList,
        },
        {
          errors: true,
        },
      );
    } else {
      reset(
        {
          arList: restArList,
        },
        {
          errors: true,
        },
      );
    }
    setCheckedRows([]);
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const allRowIndexList = rows.reduce((result: number[], _, index) => {
        if (turnOnInvalidFilter) {
          if (!checkedValidation[index] || errors?.arList?.[index]) {
            result.push(index);
          }
        } else {
          result.push(index);
        }

        return result;
      }, []);
      setCheckedRows(allRowIndexList);
    } else {
      setCheckedRows([]);
    }
  };

  const handleCheckChange = (e: any, index: number) => {
    // TODO : handleCheck : too slow...
    if (e.target.checked) {
      const target = [...checkedRows, index];
      setCheckedRows(target);
    } else {
      const target = checkedRows.filter(el => el !== index);
      setCheckedRows(target);
    }
  };

  const getIsAllChecked = () => {
    if (turnOnInvalidFilter) {
      const invalidAPIndexList = rows
        .filter((_, index) => !checkedValidation[index] || errors?.arList?.[index])
        .map((_, index) => index);

      return checkedRows.length > 0 && checkedRows.length === invalidAPIndexList.length;
    } else {
      return checkedRows.length === rows.length;
    }
  };

  const handleExportButton = async () => {
    if (rows && rows.length > 0) {
      if (anchorApBulkImportValidate) {
        showLoadingUI();
        await anchorApBulkImportValidate();
      }

      try {
        const jsonArrayData: APBulkTableData[] = getValues().arList.map((item, index) => ({
          rowIndex: index + 1,
          anchorPartnerTaxCode: tableValueManage(item.partnerTaxCode),
          arNumber: tableValueManage(item.arNumber),
          arIssuedDate: tableValueManage(item.arIssuedDate),
          settlementDate: tableValueManage(item.settlementDate),
          arAmount: tableValueManage(item.arAmount),
        }));

        const excelColumns: ColumnOption<APBulkTableData>[] = [
          {
            header: 'No',
            key: 'rowIndex',
            width: 20,
          },
          {
            header: 'Partner Tax Identification Number',
            key: 'anchorPartnerTaxCode',
            width: 40,
          },
          {
            header: ExcelTemplatesHeader.AP_NUMBER,
            key: 'arNumber',
            width: 30,
          },
          {
            header: ExcelTemplatesHeader.AP_ISSUED_DATE,
            key: 'arIssuedDate',
            width: 30,
          },
          {
            header: ExcelTemplatesHeader.SETTLEMENT_DATE,
            key: 'settlementDate',
            width: 30,
          },
          {
            header: ExcelTemplatesHeader.AP_AMOUNT,
            key: 'arAmount',
            width: 30,
          },
        ];

        showLoadingUI();

        if (isValidPartnerName) {
          isValidPartnerName();
          setCheckedValidation(getValues().arList.map(() => true));
        }

        const excelExporterProps: ExportSpreadSheetProps<any> = {
          jsonArrayData,
          columns: excelColumns,
          errors: formState.errors.arList?.map((ar: any) => {
            const { partnerName, ...rest } = ar;

            return rest;
          }) as BulkImportErrorObjArr,
          options: {
            rowHeight: [{ position: 1, height: 15 }],
          },
          bulkImportOptions: {
            minDate: minimumDate,
            maxDate: maximumDate,
          },
        };
        showModal(<Exporter spreadSheetExporterProps={excelExporterProps} />, {
          title: t('text:Export_File'),
          closeBtnText: t('text:Close'),
        });
      } catch (e) {
        showModal(e);
      } finally {
        unShowLoadingUI();
      }
    }
  };

  const isApNumberDuplicated = (partnerTaxCode: string, arNumber: string): boolean => {
    // apNumber + partnerTaxCode Validator
    // return false :: duplicated

    // 모든 partnerTaxCode를 소문자로 변환
    const lowercasedPartnerTaxCodeArList = getValues().arList.map(info => ({
      ...info,
      partnerTaxCode: info.partnerTaxCode.toLowerCase(),
    }));
    const partnerTaxCodeGroupedByArNumbers = mapValues(
      groupBy(lowercasedPartnerTaxCodeArList, 'partnerTaxCode'),
      (item: AnchorArListElementType[]) => item.map(({ arNumber }) => arNumber),
    );

    const lowercasedPartnerTaxCode = partnerTaxCode.toLowerCase();
    const targetArNumbers = partnerTaxCodeGroupedByArNumbers[lowercasedPartnerTaxCode];
    const arNumbers = targetArNumbers.filter(item => item);
    const countedArNumbers = countBy(arNumbers);

    return countedArNumbers[arNumber] <= 1;
  };

  return (
    <form>
      <div
        className={clsx('mb-3', {
          'flex-center-between': pageType === 'confirmation',
          'flex-end': pageType === 'registration',
        })}
        data-testid="button-groups"
      >
        <div className="d-flex align-items-center justify-content-between">
          <div
            className={clsx('flex-center ps-3-3', {
              'd-none': pageType === 'registration',
            })}
          >
            <Button
              onClick={handleExportButton}
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.PRIMARY}
              className={pageType === 'confirmation' ? '' : 'd-none'}
            >
              {t('text:Export')}
            </Button>
            <div className="d-flex align-items-center ps-3">
              <ToggleButton isActive={turnOnInvalidFilter} onClick={handleToggleClick} />
              <p className="ms-3">{t('text:Show_only_invalid_APs')}</p>
            </div>
          </div>
        </div>
        <div>
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={removeRow}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
          <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </div>
      {currencyType && (
        <>
          <div className="table-overflow-scroll" style={{ maxHeight: '1000px' }}>
            <table className="table-border" data-testid="register-table">
              <colgroup>
                <col style={{ width: '70px' }} />
                {values(fieldNames).map((_, index) => (
                  <col key={index} />
                ))}
                {checkedValidation.length > 0 && <col />}
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="text-center">
                      <input
                        className="form-check-input m-0"
                        type="checkbox"
                        value=""
                        id="allCheck2"
                        onChange={handleCheckAll}
                        checked={getIsAllChecked()}
                      />
                    </div>
                  </th>
                  {values(fieldNames).map(({ name }, index) => (
                    <th key={index} scope="col">
                      {name}
                    </th>
                  ))}
                  {checkedValidation.length > 0 && <th scope="col">{t('text:Validation_Result')}</th>}
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr
                  className={clsx({
                    'd-none':
                      !turnOnInvalidFilter ||
                      !isEmpty(errors.arList?.filter(Boolean)) ||
                      rows.length > checkedValidation.length, // 새롭게 추가된 AP를 토글 버튼 상관없이 보여주기 위해 추가
                  })}
                >
                  <td
                    colSpan={values(fieldNames).length + (checkedValidation.length > 0 ? 2 : 1)} // 유효성 검사 진행한 AP가 존재 하는지에 따라 colSpan 조절
                    className="text-center fs-7 fw-normal"
                  >
                    {t('text:There_are_no_invalid_AP')}
                  </td>
                </tr>
                {rows.map((_, rowIndex) => {
                  return (
                    <tr
                      key={rowIndex}
                      className={clsx({
                        'd-none': turnOnInvalidFilter && checkedValidation[rowIndex] && !errors?.arList?.[rowIndex],
                      })}
                    >
                      <td className="bg-sub100" key={rowIndex}>
                        <div className="text-center">
                          <input
                            className="form-check-input m-0"
                            type="checkbox"
                            onChange={e => handleCheckChange(e, rowIndex)}
                            checked={checkedRows.includes(rowIndex)}
                          />
                        </div>
                      </td>
                      <td className={getValidationClassName('partnerName', 'td', rowIndex)}>
                        <Value
                          className={getValidationClassName('partnerName', 'td', rowIndex)}
                          value={partnerNames?.[rowIndex]}
                        />
                      </td>
                      <td className={getValidationClassName('partnerTaxCode', 'td', rowIndex)}>
                        <input
                          className={getValidationClassName('partnerTaxCode', 'input', rowIndex)}
                          name={`arList.${rowIndex}.partnerTaxCode`}
                          onChange={onChangePartnerTaxCode}
                          ref={register({
                            pattern: {
                              value: REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING,
                              message: 'text:Fail_The_tax_code_must_consist_only_of_letters_and_numbers',
                            },
                            required: 'text:Fail_Missing_item(s)',
                          })}
                        />
                      </td>
                      <td className={getValidationClassName('arNumber', 'td', rowIndex)}>
                        <input
                          className={getValidationClassName('arNumber', 'input', rowIndex)}
                          name={`arList.${rowIndex}.arNumber`}
                          ref={register({
                            required: 'text:Fail_Missing_item(s)',
                            validate: value =>
                              isApNumberDuplicated(getValues(`arList.${rowIndex}.partnerTaxCode`)!, value),
                            maxLength: {
                              value: 20,
                              message: 'text:Fail_The_AP_Number_must_be_between_1_and_20_characters',
                            },
                          })}
                        />
                      </td>
                      <td className={getValidationClassName('arIssuedDate', 'td', rowIndex)}>
                        <Controller
                          control={control}
                          name={`arList.${rowIndex}.arIssuedDate`}
                          defaultValue={null}
                          render={field => (
                            <ReactDatePicker
                              field={field}
                              className={getValidationClassName('arIssuedDate', 'input', rowIndex)}
                            />
                          )}
                          rules={{
                            required: 'text:Fail_Missing_item(s)',
                          }}
                        />
                      </td>
                      <td className={getValidationClassName('settlementDate', 'td', rowIndex)}>
                        <Controller
                          control={control}
                          name={`arList.${rowIndex}.settlementDate`}
                          defaultValue={null}
                          render={field => {
                            return (
                              <ReactDatePicker
                                field={field}
                                className={getValidationClassName('settlementDate', 'input', rowIndex)}
                                minDate={minimumDate}
                                maxDate={maximumDate}
                                excludeDates={
                                  financierHoliday?.content?.map(item => new Date(item.solarDate)) as Date[] | undefined
                                }
                              />
                            );
                          }}
                          rules={{
                            required: 'text:Fail_Missing_item(s)',
                            validate: () =>
                              invoiceOrArSettlementDateInputValidate(
                                today,
                                getValues(`arList.${rowIndex}.settlementDate`),
                                financierHoliday?.content.map(item => item.solarDate)!,
                                minimumDate,
                                maximumDate,
                              ),
                          }}
                        />
                      </td>
                      <td className={getValidationClassName('arAmount', 'td', rowIndex)}>
                        {pageType === 'registration' && (
                          <input
                            type="text"
                            className={getValidationClassName('arAmount', 'input', rowIndex) + ' text-end'}
                            name={`arList.${rowIndex}.arAmount`}
                            ref={register({
                              validate: value => invoiceOrArAmountInputValidate(value, currencyType, 'AP'),
                              required: !isSavedFields,
                              min: !isSavedFields ? 0.01 : undefined,
                            })}
                            onChange={debounce(updateTotalApAmount, 300)}
                          />
                        )}
                        {pageType === 'confirmation' && (
                          <input
                            type="text"
                            className={getValidationClassName('arAmount', 'input', rowIndex) + ' text-end'}
                            name={`arList.${rowIndex}.arAmount`}
                            ref={register({
                              validate: value => invoiceOrArAmountInputValidate(value, currencyType, 'AP'),
                              required: 'text:Fail_Missing_item(s)',
                              min: { value: 0.01, message: 'text:Fail_AP_Amount_must_exceed_0' },
                            })}
                            onChange={debounce(updateTotalApAmount, 300)}
                          />
                        )}
                      </td>
                      {checkedValidation[rowIndex]
                        ? getValidationResult(rowIndex)
                        : checkedValidation.length > 0 && <td />}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="grid-total" data-testid="total">
            {t('text:Total')} : {t('format:number', { value: totalApAmount })}
          </div>
        </>
      )}
    </form>
  );
}

export default memo(ApInputBulkTable);
