import type { COLLATERAL_TYPE, CURRENCY_TYPE, MULTIPLE_LOAN_APPROVAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface MultipleAnchorLoanRequestVO {
  multipleAnchorLoanRequestId: number;
  anchorAgreementId: number;
  anchorFinancingOptionId: number;
  collateralType: COLLATERAL_TYPE;
  financierId: number;
  financierName: string;
  anchorClientId: number;
  anchorClientName: string;
  currencyType: CURRENCY_TYPE;
  requestedDateTime: string;
  desiredDisburseDate: string;
  originalRepaymentDate: string;
  totalRequestedAmount: string;
  totalCollateralCount: number;
  multipleLoanRequestStatus: MULTIPLE_LOAN_REQUEST_STATUS;
  approvalType: MULTIPLE_LOAN_APPROVAL_TYPE;
}

export interface MultipleAnchorLoanRequestVOModel extends MultipleAnchorLoanRequestVO {
  totalRequestedAmount: BigNumber;
}

export function formattingToMultipleAnchorLoanRequestVOModel(
  data: MultipleAnchorLoanRequestVO,
): MultipleAnchorLoanRequestVOModel {
  return {
    ...data,
  };
}
