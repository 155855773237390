import type { MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { LOAN_STATUS, SUCCESS_AR_STATUS, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { StatisticsOfAnchorAgreementVOModel } from 'models/vo/StatisticsOfAnchorAgreementVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestSystemAnchorStatisticsOfAnchorAgreementsList } from 'utils/http/api/system/statistics-of-anchor-agreements';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams } from 'utils/searchParams';
import type { ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getPaymentSupportText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import { useSystemOverviewByAnchorListVendorTabConstants } from './constants';
import { SY_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY } from '../../SystemOverviewByAnchorList';

interface SystemOverviewByAnchorListVendorTabProps {
  onClickSearch: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  overviewByAnchorPageData?: Pageable<StatisticsOfAnchorAgreementVOModel[]>;
  pageable: PageableType;
  paginate: (selectedPageNumber: number, selectedRowCount: number) => void;
}

function SystemOverviewByAnchorListVendorTab({
  onClickSearch,
  overviewByAnchorPageData,
  pageable,
  paginate,
}: SystemOverviewByAnchorListVendorTabProps) {
  const { t } = useTranslation(['format']);
  const { register, reset } = useFormContext();
  const modal = useModal();

  const { AR_TABLE_HEADER, AR_BIG_TABLE_HEADER, excelColumnsAr, arMergedCells, arTableHeader, arMergedTableHeader } =
    useSystemOverviewByAnchorListVendorTabConstants();

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();

    reset({});
  };

  const onClickExport = async (e: any): Promise<void> => {
    e.preventDefault();

    try {
      const data = getParsedSearchParams(SY_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY).formSearchData;
      data.collateralType = SUPPORTED_COLLATERAL_TYPE.AR;

      const anchorMonitoringPageForExcel = await requestSystemAnchorStatisticsOfAnchorAgreementsList(
        0,
        EXCEL_EXPORT_MAX_ROW_COUNT,
        data,
      );
      const anchorMonitoringPageForPDF = anchorMonitoringPageForExcel.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: any[] = anchorMonitoringPageForExcel.content.map(item => ({
        financierName: tableValueManage(item.financierName),
        anchorFinancierClientName: tableValueManage(item.anchorFinancierClientName),
        anchorFinancierClientCode: tableValueManage(item.anchorFinancierClientCode),
        contractNo: tableValueManage(item.contractNo),
        currencyType: tableValueManage(item.currencyType),
        currentLoanBalanceAmount: item.currentLoanBalanceAmount,
        currentDisbursedLoanCount: item.currentDisbursedLoanCount,
        currentSettlementWaitingInvoiceAmount: item.currentSettlementWaitingInvoiceAmount,
        currentSettlementWaitingInvoiceCount: item.currentSettlementWaitingInvoiceCount,
        registeredInvoiceAmount: item.registeredInvoiceAmount,
        registeredInvoiceCount: item.registeredInvoiceCount,
        totalDisbursedLoanAmount: item.totalDisbursedLoanAmount,
        totalDisbursedLoanCount: item.totalDisbursedLoanCount,
        paymentSupport: getPaymentSupportText(item.paymentSupport),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumnsAr,
        options: {
          mergedCells: arMergedCells,
          rowHeight: [
            { position: 1, height: 20 },
            { position: 2, height: 15 },
          ],
        },
      };

      const renderArTableBodyResult = (): JSX.Element[] | undefined => {
        return anchorMonitoringPageForPDF?.map((item, index) => (
          <Tr key={index} className="virtual-table-row">
            <Td data={item.financierName} />
            <Td data={item.anchorFinancierClientName} />
            <Td data={item.anchorFinancierClientCode} />
            <Td data={item.contractNo} />
            <Td data={item.currencyType} />
            <Td data={getPaymentSupportText(item.paymentSupport)} />
            <Td data={item.currentLoanBalanceAmount} format="number" />
            <Td data={item.currentDisbursedLoanCount} format="number" />
            <Td data={item.currentSettlementWaitingInvoiceAmount} format="number" />
            <Td data={item.currentSettlementWaitingInvoiceCount} format="number" />
            <Td data={item.registeredInvoiceAmount} format="number" />
            <Td data={item.registeredInvoiceCount} format="number" />
            <Td data={item.totalDisbursedLoanAmount} format="number" />
            <Td data={item.totalDisbursedLoanCount} format="number" />
          </Tr>
        ));
      };
      const pdfExporterProps: PDFExporterProps = {
        mergedTableHeaders: [arMergedTableHeader],
        tableHeaders: arTableHeader,
        tableBody: renderArTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorFinancierClientName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name="anchorFinancierClientCode" ref={register} />
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name="contractNo" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {overviewByAnchorPageData?.totalElements ? overviewByAnchorPageData.totalElements : '0'}
          </p>
          <Button onClick={onClickExport} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={AR_TABLE_HEADER} bigHeader={AR_BIG_TABLE_HEADER} />
          <TableBody numOfCol={AR_TABLE_HEADER.length}>
            {overviewByAnchorPageData?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.financierName} />
                  <Td data={item.anchorFinancierClientName} />
                  <Td data={item.anchorFinancierClientCode} />
                  <Td data={item.contractNo} />
                  <Td data={item.currencyType} />
                  <Td data={getPaymentSupportText(item.paymentSupport)} />
                  {/* Financing Balance / Count */}
                  <td className="text-end">
                    {tableValueManage(
                      item.currentLoanBalanceAmount,
                      t('format:number', {
                        value: item.currentLoanBalanceAmount,
                      }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.currentDisbursedLoanCount,
                      t('format:number', { value: item.currentDisbursedLoanCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_SY.VIEW_TRANSACTION.FINANCING_LIST}
                      state={{
                        anchorClientName: item.anchorFinancierClientName,
                        anchorClientCode: item.anchorFinancierClientCode,
                        currencyType: item.currencyType,
                        loanStatusConditions: [LOAN_STATUS.DISBURSED, LOAN_STATUS.OVERDUE],
                        collateralType: item.collateralType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  {/*  AR Amount to be paid / Count */}
                  <td className="text-end">
                    {tableValueManage(
                      item.currentSettlementWaitingInvoiceAmount,
                      t('format:number', { value: item.currentSettlementWaitingInvoiceAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.currentSettlementWaitingInvoiceCount,
                      t('format:number', { value: item.currentSettlementWaitingInvoiceCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_SY.VIEW_TRANSACTION.AR_LIST}
                      state={{
                        anchorName: item.anchorFinancierClientName,
                        currencyType: item.currencyType,
                        successArStatus: SUCCESS_AR_STATUS.REGISTERED,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  {/*  Registered AR Amount / Count */}
                  <td className="text-end">
                    {t('format:number', { value: item.registeredInvoiceAmount })}
                    {' / '}
                    {t('format:number', { value: item.registeredInvoiceCount })}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_SY.VIEW_TRANSACTION.AR_LIST}
                      state={{
                        anchorName: item.anchorFinancierClientName,
                        currencyType: item.currencyType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  {/*  Total Financing Amount / Count */}
                  <td className="text-end">
                    {tableValueManage(
                      item.totalDisbursedLoanAmount,
                      t('format:number', { value: item.totalDisbursedLoanAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.totalDisbursedLoanCount,
                      t('format:number', { value: item.totalDisbursedLoanCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_SY.VIEW_TRANSACTION.FINANCING_LIST}
                      state={{
                        anchorClientName: item.anchorFinancierClientName,
                        anchorClientCode: item.anchorFinancierClientCode,
                        currencyType: item.currencyType,
                        loanStatusConditions: [
                          LOAN_STATUS.DISBURSED,
                          LOAN_STATUS.OVERDUE,
                          LOAN_STATUS.EXPIRATION,
                          LOAN_STATUS.REPAID,
                        ],
                        collateralType: item.collateralType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemOverviewByAnchorListVendorTab;
