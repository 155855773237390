import http, { ContentType } from 'utils/http';
import type { CommonResponse } from 'utils/http/CommonResponse';
import API_AN from 'utils/http/api/anonymous';

import type { CreateFinancierAndAdminUserDTO } from './requests';

export async function requestOnDemandCreateFinancier(data: CreateFinancierAndAdminUserDTO): Promise<void> {
  await http.post<CommonResponse>({
    url: API_AN.ON_DEMAND.JOIN,
    data,
    contentType: ContentType.MULTIPART,
  });
}

export async function requestOnDemandCheckUserExistence(loginId: string) {
  return await http.get<boolean>({
    url: API_AN.ON_DEMAND.USER_EXISTENCE,
    data: { loginId },
  });
}
