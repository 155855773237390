import { POTENTIAL_PARTNER_FINANCING_DASHBOARD_TYPE, stringToEnum } from 'enums';

export interface DashboardPotentialPartnerFinancingVO {
  potentialPartnerFinancingApplicationId: number;
  createDateTime: string;
  anchorClientName: string;
  potentialPartnerName: string;
  potentialPartnerFinancingDashboardType: string;
}

export interface DashboardPotentialPartnerFinancingVOModel extends DashboardPotentialPartnerFinancingVO {
  potentialPartnerFinancingDashboardType: POTENTIAL_PARTNER_FINANCING_DASHBOARD_TYPE;
}

export function formattingToDashboardPotentialPartnerFinancingVOModel(
  data: DashboardPotentialPartnerFinancingVO,
): DashboardPotentialPartnerFinancingVOModel {
  return {
    ...data,
    potentialPartnerFinancingDashboardType: stringToEnum(
      POTENTIAL_PARTNER_FINANCING_DASHBOARD_TYPE,
      data.potentialPartnerFinancingDashboardType,
    ),
  };
}
