import type React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';

interface FinancierFinancingDetailDesignatedBankAccountInformationProps {
  data: LoanDetailVOModel;
  factoringEnable: boolean;
  bankAccountInfoToggleState: {
    bankAccountInfoToggle: boolean;
    setBankAccountInfoToggle: React.Dispatch<React.SetStateAction<boolean>>;
  };
  isOperatorRequest: boolean;
}
function FinancierFinancingDetailDesignatedBankAccountInformation({
  data,
  factoringEnable,
  bankAccountInfoToggleState,
  isOperatorRequest,
}: FinancierFinancingDetailDesignatedBankAccountInformationProps) {
  const { t } = useTranslation(['format']);
  const showCheckbox = isOperatorRequest && !data.multipleRequested;

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Designated_Bank_Account_Information')} />
      <FormBorder hideBorderBottom={showCheckbox}>
        <FormSubtitle title={t('text:DISBURSEMENT_ACCOUNT')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Bank_Name')} value={data.settlementAccountFinancierName} />
            <FormValue label={t('text:Bank_Branch_Name')} value={data.settlementAccountBranchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Account_Number')} value={data.settlementAccount} />
            <FormValue label={t('text:Account_Holder_Name')} value={data.settlementAccountOwner} />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:REPAYMENT_ACCOUNT')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Bank_Name')} value={data.principalRepaymentAccountFinancierName} />
            <FormValue label={t('text:Bank_Branch_Name')} value={data.principalRepaymentAccountBranchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Account_Number')} value={data.principalRepaymentAccount} />
            <FormValue label={t('text:Account_Holder_Name')} value={data.principalRepaymentAccountOwner} />
          </div>
        </FormContents>
        {!factoringEnable && (
          <>
            <FormSubtitle title={t('text:REPAYMENT_ACCOUNT_-_INTEREST')} />
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue label={t('text:Bank_Name')} value={data.interestRepaymentAccountFinancierName} />
                <FormValue label={t('text:Bank_Branch_Name')} value={data.interestRepaymentAccountBranchName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={data.interestRepaymentAccount} />
                <FormValue label={t('text:Account_Holder_Name')} value={data.interestRepaymentAccountOwner} />
              </div>
            </FormContents>
          </>
        )}
      </FormBorder>
      {showCheckbox && (
        <ConfirmCheckBox
          checked={bankAccountInfoToggleState.bankAccountInfoToggle}
          id="designated-bank-account-info-check"
          labelText={t('text:I_have_checked_the_information_of_the_designated_bank_accounts')}
          onChangeCheckBox={() => {
            bankAccountInfoToggleState.setBankAccountInfoToggle(prevState => !prevState);
          }}
        />
      )}
    </div>
  );
}
export default FinancierFinancingDetailDesignatedBankAccountInformation;
