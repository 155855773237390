import { CURRENCY_TYPE, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface DealerAgreementForRequestMultipleLoanVO {
  dealerAgreementId: number;
  financierId: number;
  financierName: string;
  anchorClientName: string;
  anchorAgreementContractNo: string;
  dealerAgreementContractNo: string;
  currencyType: string;
  totalAmount: string;
  totalCount: number;
  maxLtvRatio: number;
}

export interface DealerAgreementForRequestMultipleLoanVOModel extends DealerAgreementForRequestMultipleLoanVO {
  currencyType: CURRENCY_TYPE;
  totalAmount: BigNumber;
}

export function formattingToDealerAgreementForRequestMultipleLoanVOModel(
  data: DealerAgreementForRequestMultipleLoanVO,
): DealerAgreementForRequestMultipleLoanVOModel {
  const { currencyType, totalAmount } = data;

  return {
    ...data,
    totalAmount: sliceZeroDecimal(totalAmount),
    currencyType: stringToEnum(CURRENCY_TYPE, currencyType),
  };
}
