import type { COLLATERAL_TYPE, COMMON_APPROVAL_TYPE, CURRENCY_TYPE } from 'enums';
export interface WaitingAnchorFinancingOptionVO {
  waitingAnchorFinancingOptionId: number;
  updatedDateTime: string;
  anchorAgreementId: number;
  targetAnchorFinancingOptionId: number;
  contractNo: string;
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  branchId: number;
  branchName: string;
  branchCode: string;
  anchorAgreementStartDate: string;
  anchorAgreementExpiryDate: string;
  approvalType: COMMON_APPROVAL_TYPE;
  operatorUserLoginId: string;
  operatorUserName: string;
  authorizerUserLoginId: string;
  authorizerUserName: string;
  returnReason: string;
}

export interface WaitingAnchorFinancingOptionVOModel extends WaitingAnchorFinancingOptionVO {}

export function formattingToWaitingAnchorFinancingOptionVOModel(
  data: WaitingAnchorFinancingOptionVO,
): WaitingAnchorFinancingOptionVOModel {
  return {
    ...data,
  };
}
