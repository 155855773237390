import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { WaitingAnchorPartnerDetailVOModel } from 'models/vo/WaitingAnchorPartnerDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestWaitingAnchorPartnerApproveModification,
  requestWaitingAnchorPartnerCancel,
  requestWaitingAnchorPartnerData,
  requestWaitingAnchorPartnerOnModificationModify,
  requestWaitingAnchorPartnerReturn,
} from 'utils/http/api/anchor/waiting-anchor-partners';
import type { UpdateAcWaitingAnchorPartnerOnModificationRequest } from 'utils/http/api/anchor/waiting-anchor-partners/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

function AnchorPartnerCompanyInfoWaitingDetail() {
  const { t } = useTranslation();
  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();

  const { waitingAnchorPartnerId } = useParams() as any;
  const [waitingAnchorPartnerDetailInfo, setWaitingAnchorPartnerDetailInfo] =
    useState<WaitingAnchorPartnerDetailVOModel>();

  const { register, getValues, errors, setError, clearErrors } =
    useForm<UpdateAcWaitingAnchorPartnerOnModificationRequest>({
      mode: 'onSubmit',
      shouldFocusError: true,
    });

  const getWaitingAnchorPartnerProperty = useProperty<UpdateAcWaitingAnchorPartnerOnModificationRequest>();

  const signInInfo = getSignIn();
  const isHqOperator = signInInfo?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR;
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;
  const isHqOperatorOrAdmin =
    signInInfo?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR || signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;

  const approvalType = waitingAnchorPartnerDetailInfo?.approvalType;
  const isRequested = approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST;
  const isCanceled = approvalType === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL;
  const isReverted = approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED;
  const isEditable = isReverted && isHqOperator;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const waitingAnchorPartnerDetailInfo = await requestWaitingAnchorPartnerData(waitingAnchorPartnerId);
      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerDetailInfo(waitingAnchorPartnerDetailInfo);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const getGuideMessage = (): JSX.Element | null => {
    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        if (isHqOperator) {
          return (
            <GuideMessage
              message={[
                t('text:The_Suppliers_company_information_modification_has_been_requested'),
                t('text:Modification_will_be_completed_when_the_Authorizer_confirms_the_modification_request'),
              ]}
            />
          );
        }

        if (isAdmin) {
          return (
            <GuideMessage
              message={[
                t('text:The_Suppliers_company_information_modification_has_been_requested'),
                t('text:Click_on_the_Confirm_button_at_the_bottom_of_the_page_to_complete_the_modification'),
                t(
                  'text:Click_on_the_Revert_button_at_the_bottom_of_the_page_to_return_the_modification_request_to_the_HQ_Operator',
                ),
              ]}
            />
          );
        }

        return null;
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        if (isHqOperatorOrAdmin) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[t('text:The_Supplier_modification_request_has_been_reverted')]}
              reason={waitingAnchorPartnerDetailInfo?.returnReason}
              reasonTitle={t('text:REASON_FOR_REVERT')}
            />
          );
        }

        return null;
      default:
        return null;
    }
  };

  const getApprovalRequestStatusInfo = () => {
    const hqOperatorInfo = `${t('text:Admin_Operator')} (${waitingAnchorPartnerDetailInfo?.operatorUserName} / ${
      waitingAnchorPartnerDetailInfo?.operatorUserLoginId
    }) `;

    const adminInfo = `${t('text:Admin')} (${waitingAnchorPartnerDetailInfo?.authorizerUserName} / ${
      waitingAnchorPartnerDetailInfo?.authorizerUserLoginId
    }) `;

    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return {
          badgeTitle: t('text:Requested'),
          requestor: hqOperatorInfo,
          timeTitle: t('text:Requested_Date'),
        };
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return {
          badgeTitle: t('text:Reverted'),
          requestor: adminInfo,
          timeTitle: t('text:Reverted_Date'),
        };
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return {
          badgeTitle: t('text:Cancelled'),
          requestor: hqOperatorInfo,
          timeTitle: t('text:Cancelled_Date'),
        };
      default:
        return;
    }
  };

  const renderRegistrationRequestStateBlock = (): JSX.Element => {
    const registrationStatusInfo = getApprovalRequestStatusInfo();

    return (
      <>
        {getGuideMessage()}
        <div className="content-area">
          <div className="supply-chain-partner-status-block">
            <span
              className={`me-4 ${getStatusBadgeClass(
                'COMMON_APPROVAL_TYPE',
                waitingAnchorPartnerDetailInfo?.approvalType,
              )}`}
            >
              {registrationStatusInfo?.badgeTitle}
            </span>
            <span className="supply-chain-partner-requester-info">
              <span className="supply-chain-partner-requester-info-title">{registrationStatusInfo?.requestor}</span>
              <span>{waitingAnchorPartnerDetailInfo?.accountBankName}</span>
            </span>
            <span className="supply-chain-partner-requester-info supply-chain-partner-requester-info-time">
              <span className="supply-chain-partner-requester-info-title">{registrationStatusInfo?.timeTitle}</span>
              <span>
                {t('format:datetime', {
                  value: waitingAnchorPartnerDetailInfo?.updatedDateTime,
                  key: 'datetime',
                })}
              </span>
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderSupplyChainPartnerInformation = (): JSX.Element => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Supplier_Information`)} />
          <FormBorder editable={isEditable}>
            <FormContents>
              <div className="row">
                <FormInput
                  label={t(`text:Supplier_Name`)}
                  name={getWaitingAnchorPartnerProperty('name')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.name}
                  ref={register}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  error={errors.name}
                />
                <FormInput
                  label={t(`text:Tax_Code`)}
                  name={getWaitingAnchorPartnerProperty('partnerTaxCode')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.partnerTaxCode}
                  ref={register}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  error={errors.partnerTaxCode}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Company_Registration_Number`)}
                  name={getWaitingAnchorPartnerProperty('partnerBusinessCode')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.partnerBusinessCode}
                  ref={register}
                  disabled={!isEditable}
                  error={errors.partnerBusinessCode}
                />
                <FormInput
                  col={3}
                  label={t(`text:Telephone`)}
                  name={getWaitingAnchorPartnerProperty('telephone')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.telephone}
                  ref={register}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  error={errors.telephone}
                />
                <FormInput
                  col={3}
                  label={t(`text:Fax`)}
                  name={getWaitingAnchorPartnerProperty('fax')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.fax}
                  ref={register}
                  disabled={!isEditable}
                  error={errors.fax}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Legal_Representative_Name`)}
                  name={getWaitingAnchorPartnerProperty('representativeName')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.representativeName}
                  ref={register}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  error={errors.representativeName}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  name={getWaitingAnchorPartnerProperty('representativePosition')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.representativePosition}
                  ref={register}
                  disabled={!isEditable}
                  error={errors.representativePosition}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  name={getWaitingAnchorPartnerProperty('representativeEmail')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.representativeEmail}
                  ref={register}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  error={errors.representativeEmail}
                />
              </div>
              <div className="row">
                <FormInput
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  name={getWaitingAnchorPartnerProperty('address')}
                  defaultValue={waitingAnchorPartnerDetailInfo?.address}
                  ref={register}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  error={errors.address}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderHqOperatorAuthorityButton = () => {
    if (isCanceled) return null;

    const cancelPartnerInfoRevision = async () => {
      try {
        await requestWaitingAnchorPartnerCancel({
          waitingAnchorPartnerIds: [waitingAnchorPartnerId],
        });
        fetchAll();
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickCancelRequest = () => {
      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_modification_request?')}
          <br />
          {t('text:The_information_will_not_be_saved_if_modification_is_cancelled')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: cancelPartnerInfoRevision,
        },
      );
    };

    const successChangeRequestModal = () => {
      modal.show(
        <h6>
          {t('text:Request_for_modification_approval_has_been_completed')}
          <br />
          {t('text:Modification_will_be_completed_after_approval_by_the_Admin')}
        </h6>,
        {
          closeBtnCb: fetchAll,
        },
      );
    };

    const reapplyPartnerInfoRevision = async () => {
      try {
        await requestWaitingAnchorPartnerOnModificationModify(waitingAnchorPartnerId, getValues());
        clearErrors();
        successChangeRequestModal();
      } catch (error) {
        modal.show(error);
        formErrorHandler<UpdateAcWaitingAnchorPartnerOnModificationRequest>(error, setError, clearErrors);
      }
    };

    const onClickChangeRequest = () => {
      modal.show(<h6>{t('text:Would_you_like_to_request_approval_for_the_modification')}</h6>, {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: reapplyPartnerInfoRevision,
      });
    };

    return (
      <>
        {isReverted && (
          <Button size={ButtonSizeEnum.LG} onClick={onClickChangeRequest} className="me-2">
            {t('text:Request_Revision')}
          </Button>
        )}
        <Button
          size={ButtonSizeEnum.LG}
          onClick={onClickCancelRequest}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
        >
          {t('text:Cancel_Request')}
        </Button>
      </>
    );
  };

  const renderHqAuthorizerAuthorityButton = () => {
    if (!isRequested) return null;

    const onClickConfirm = () => {
      const successfullyConfirmedModal = () => {
        modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
          closeBtnCb: () => {
            history.push(ROUTES_AC.MANAGE_PARTNER.COMPANY_INFO_LIST);
          },
        });
      };

      const approvePartnerInfoRevision = async () => {
        try {
          await requestWaitingAnchorPartnerApproveModification(waitingAnchorPartnerId);
          successfullyConfirmedModal();
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(<h6>{t('text:Would_you_like_to_confirm_the_request?')}</h6>, {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: approvePartnerInfoRevision,
      });
    };

    const onClickRevert = () => {
      let reason = '';

      const successfullyRevertedModal = () => {
        modal.show(<h6>{t('text:The_modification_request_has_been_reverted')}</h6>);
      };

      const revertPartnerInfoRevision = async () => {
        try {
          await requestWaitingAnchorPartnerReturn({
            returnReason: reason,
            waitingAnchorPartnerIds: [waitingAnchorPartnerId],
          });
          successfullyRevertedModal();
          fetchAll();
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_modification_request')}
            <br />
            {t('text:Please_type_below_the_reason_for_the_revert')}
            <br />
            {t('text:Click_on_Confirm_to_revert_the_request')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: revertPartnerInfoRevision,
        },
      );
    };

    return (
      <>
        <Button
          size={ButtonSizeEnum.LG}
          onClick={onClickRevert}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.RED}
          className="me-2"
        >
          {t('text:Revert')}
        </Button>
        <Button size={ButtonSizeEnum.LG} onClick={onClickConfirm}>
          {t('text:Confirm')}
        </Button>
      </>
    );
  };

  return (
    <>
      <BackHeaderTitle title={waitingAnchorPartnerDetailInfo?.name} />
      {renderRegistrationRequestStateBlock()}
      {renderSupplyChainPartnerInformation()}
      <div className="content-area">
        <div className="flex-end mt-3">
          {isHqOperator && renderHqOperatorAuthorityButton()}
          {isAdmin && renderHqAuthorizerAuthorityButton()}
        </div>
      </div>
    </>
  );
}

export default AnchorPartnerCompanyInfoWaitingDetail;
