import type { BigNumber } from 'utils/bigNumber';

export interface MultipleAnchorLoanRequestRelatedSuccessArVO {
  successArId: number;
  arNumber: string;
  anchorPartnerId: number;
  anchorPartnerName: string;
  anchorPartnerTaxCode: string;
  arAmount: BigNumber;
  arIssuedDate: string;
  anchorLoanId: number;
  arSettlementDate: string;
}

export interface MultipleAnchorLoanRequestRelatedSuccessArVOModel extends MultipleAnchorLoanRequestRelatedSuccessArVO {}

export function formattingToMultipleAnchorLoanRequestRelatedSuccessArVOModel(
  data: MultipleAnchorLoanRequestRelatedSuccessArVO,
): MultipleAnchorLoanRequestRelatedSuccessArVOModel {
  return {
    ...data,
  };
}
