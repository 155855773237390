import { Big } from 'big.js';

import { REG_NUMBER } from 'utils/validation/regExp';

export type BigNumber = string;

interface ICalculatorBigNumber {
  get: () => string;
  add: (value?: BigNumber | number) => this;
  minus: (value?: BigNumber | number) => this;
  div: (value?: BigNumber | number) => this;
  times: (value?: BigNumber | number) => this;
}

const computableValue = (value: BigNumber): boolean => {
  return REG_NUMBER.test(String(value));
};

// bigJs 에러 발생 방지
const convertToRemovePlusAndAddZero = (value?: BigNumber | number): BigNumber => {
  value = String(value);

  if (value.indexOf('+') === 0) value = value?.length > 1 ? value.slice(1) : '0'; // plus 제거
  if (value.indexOf('.') === 0) value = '0' + value; // .1 -> 0.1

  return value;
};

export class CalculatorBigNumber implements ICalculatorBigNumber {
  result: Big = Big('0');

  get() {
    const getResult = this.result.toString();
    this.result = Big('0');

    return getResult;
  }

  add(value?: BigNumber | number) {
    value = convertToRemovePlusAndAddZero(value);
    if (computableValue(value)) this.result = Big(this.result).add(Big(value));

    return this;
  }

  minus(value?: BigNumber | number) {
    value = convertToRemovePlusAndAddZero(value);
    if (computableValue(value)) this.result = Big(this.result).minus(Big(value));

    return this;
  }

  div(value?: BigNumber | number) {
    value = convertToRemovePlusAndAddZero(value);

    // value = 0 -> Division by zero error
    if (value !== '0' && computableValue(value)) this.result = Big(this.result).div(Big(value));

    return this;
  }

  times(value?: BigNumber | number) {
    value = convertToRemovePlusAndAddZero(value);
    if (computableValue(value)) this.result = Big(this.result).times(Big(value));

    return this;
  }
}
