import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import Pagination from 'components/stateless/Pagination/Pagination';
import Tab from 'components/stateless/TabManager/Tab';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { ATTACHMENT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { InvoiceAttachmentVOModel } from 'models/vo/InvoiceAttachmentVO';
import {
  requestDealerInvoiceAttachmentList,
  requestDownloadInvoiceAttachment,
  requestInvoiceScannedUpload,
} from 'utils/http/api/dealer/invoice-attachments';
import type { dealerInvoiceFileUploadRequest } from 'utils/http/api/dealer/invoice-attachments/request';
import useModal from 'utils/modal/useModal';

interface selectedDataProps {
  modalId: number;
  getSelectedData(data: InvoiceAttachmentVOModel): void;
  attachmentType: ATTACHMENT_TYPE;
}

const DEFAULT_INVOICE_ATTACHMENT_SIZE_PER_PAGE = 5;

function AttachFileModal({ modalId, getSelectedData, attachmentType }: selectedDataProps) {
  const { t } = useTranslation();
  const attachFileModal = useModal();
  const errorModal = useModal();
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable();
  const [invoiceAttachment, setInvoiceAttachment] = useState<Pageable<InvoiceAttachmentVOModel[]>>();
  const [invoiceFileName, setInvoiceFileName] = useState<string | undefined>(undefined);

  const fileUploadProperty = useProperty<dealerInvoiceFileUploadRequest>();
  const searchFileProperty = useProperty<{ description: string }>();

  useEffect(() => {
    if (mounted) {
      fetchFile(pageable.currentPage, DEFAULT_INVOICE_ATTACHMENT_SIZE_PER_PAGE);
    }
  }, [mounted]);

  // File upload Form
  const {
    register: uploadFileRegister,
    getValues: uploadFileGetValues,
    reset: resetUploadFile,
  } = useForm<dealerInvoiceFileUploadRequest>({
    mode: 'onSubmit',
  });

  // File Search Form
  const {
    register: searchFileRegister,
    getValues: searchFileGetValues,
    handleSubmit: searchFileHandleSubmit,
  } = useForm({
    mode: 'onSubmit',
  });

  const uploadFile = async (e: any) => {
    e.preventDefault();
    const data = uploadFileGetValues();
    data.attachmentType = attachmentType;
    try {
      await requestInvoiceScannedUpload(data);
      fetchFile(1, DEFAULT_INVOICE_ATTACHMENT_SIZE_PER_PAGE);
      resetUploadFile({});
      setInvoiceFileName(undefined);
    } catch (e) {
      errorModal.show(e);
    }
  };

  async function fetchFile(pageNumber: number = 1, sizePerPage: number = DEFAULT_INVOICE_ATTACHMENT_SIZE_PER_PAGE) {
    const data = searchFileGetValues(searchFileProperty('description'));

    try {
      const invoiceAttachment = await requestDealerInvoiceAttachmentList(pageNumber, sizePerPage, data, attachmentType);
      ReactDOM.unstable_batchedUpdates(() => {
        setInvoiceAttachment(invoiceAttachment);
        setPageable(invoiceAttachment);
      });
    } catch (e) {
      errorModal.show(e);
    }
  }

  const onFileSelect = (e: any, item: InvoiceAttachmentVOModel) => {
    e.preventDefault();
    getSelectedData({ ...item });

    attachFileModal.close(modalId);
  };

  const onSearchSubmit = () => {
    fetchFile(1, DEFAULT_INVOICE_ATTACHMENT_SIZE_PER_PAGE);
  };

  const paginate = (pageNumber: number, sizePerPage: number) => {
    fetchFile(pageNumber, sizePerPage);
  };

  const onClickDownload = async (invoiceAttachmentId: number) => {
    try {
      await requestDownloadInvoiceAttachment(invoiceAttachmentId);
    } catch (e) {
      errorModal.show(e);
    }
  };

  const renderNewFileForm = () => {
    return (
      <div className="tab-menu-container">
        <div className="file-upload-form">
          <form>
            <div className="file-upload">
              <div className="d-flex justify-content-between">
                <input
                  type="file"
                  name={fileUploadProperty('scannedInvoiceAttachment')}
                  id="FileUpload"
                  style={{ display: 'none' }}
                  ref={uploadFileRegister}
                  onChange={(e: any) => {
                    if (e.target?.files[0]?.name) setInvoiceFileName(e.target.files[0].name);
                  }}
                />
                <label htmlFor="FileUpload" className="attach-file-link-button mt-4">
                  {t('text:Attach_File')}
                </label>
                <div className="upload-file-input">
                  {invoiceFileName ? invoiceFileName : t('text:No_file_attached')}
                </div>

                <div className="note-form">
                  <label className="information-form__label">{t('text:Note')}</label>
                  <input
                    type="text"
                    placeholder={t('text:Please_note_here')}
                    className="note-input"
                    ref={uploadFileRegister}
                    name={fileUploadProperty('description')}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="flex-end mt-2">
            <Button bold size={ButtonSizeEnum.SM} onClick={uploadFile} style={{ width: '70px' }}>
              {t('text:Upload')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderUploadedFileForm = () => {
    const TABLE_HEADERS = [
      {
        headerText: t('text:File_Name'),
        colWidths: 180,
      },
      {
        headerText: t('text:Note'),
      },
      {
        headerText: t('text:Uploaded_Time'),
      },
      {
        headerText: '',
        colWidths: 112,
      },
      {
        headerText: '',
        colWidths: 80,
      },
    ];

    return (
      <div className="tab-menu-container">
        <div className="modal-input-form mb-3">
          <label className="information-form__label">{t('text:Note')}</label>
          <form onSubmit={searchFileHandleSubmit(onSearchSubmit)}>
            <div className="d-flex">
              <input
                className="information-form__input bg-sub100"
                type="text"
                placeholder={t('text:Please_type_here')}
                name={searchFileProperty('description')}
                ref={searchFileRegister}
              />
              <Button type="submit">{t('text:Search')}</Button>
            </div>
          </form>
        </div>
        <TableBorder>
          <TableHeader header={TABLE_HEADERS} />
          <TableBody numOfCol={TABLE_HEADERS.length}>
            {invoiceAttachment?.content.map((item, i) => (
              <Tr key={i}>
                <Td data={item.fileName} />
                <Td data={item.description} />
                <Td data={item.createdDateTime} format="datetime" />
                <Td>
                  <DownloadButton onClick={() => onClickDownload(item.invoiceAttachmentId)} />
                </Td>
                <Td>
                  <Button onClick={e => onFileSelect(e, { ...item })}>{t('text:Select')}</Button>
                </Td>
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    );
  };

  return (
    <div className="modal-container">
      <Tab
        className="w-100 mb-4"
        tabList={[
          { tabName: t('text:Recent_Upload_File') },
          {
            tabName: t('text:New_Upload_File'),
          },
        ]}
        tabViewList={[renderUploadedFileForm(), renderNewFileForm()]}
      />
    </div>
  );
}

export default AttachFileModal;
