import {
  BASIS_INTEREST_TYPE,
  CURRENCY_TYPE,
  FINANCIER_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
  SUPPORTED_COLLATERAL_TYPE,
  stringToEnum,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface StatisticsOfDealerAgreementVO {
  statisticsOfDealerAgreementId: number;
  totalUploadedInvoiceAmount: string;
  totalDeletedInvoiceAmount: string;
  totalSettledInvoiceAmount: string;
  totalUnsettledInvoiceAmount: string;
  totalDisbursedLoanAmount: string;
  totalUploadedInvoiceCount: number;
  totalDeletedInvoiceCount: number;
  totalSettledInvoiceCount: number;
  totalUnsettledInvoiceCount: number;
  totalDisbursedLoanCount: number;
  dealerAgreementId: number;
  loanLimitAmount: string;
  loanLimitCheckType: string;
  currentSettlementWaitingInvoiceAmount: string;
  currentDisbursedLoanAmount: string;
  currentRequestedLoanAmount: string;
  currentSettlementWaitingInvoiceCount: number;
  currentDisbursedLoanCount: number;
  currentRequestedLoanCount: number;
  currencyType: string;
  contractNo: string;
  currentOverdueLoanCount: number;
  dealerClientId: number;
  dealerClientCode: string;
  dealerClientName: string;
  dealerCodeByAnchor: string;
  anchorClientId: number;
  anchorClientCode: string;
  anchorClientName: string;
  dealerEnterpriseId: number;
  dealerName: string;
  anchorEnterpriseId: number;
  anchorName: string;
  financierName: string;
  financierCode: string;
  collateralType: string;
  startDate: string;
  expiryDate: string;
  basisInterestType: string;
  basisInterestBankCode: string;
  basisInterestTerm: string;
  basisInterestRate: number;
  creditInterestRate: number;
  preferentialInterestRate: number;
  anchorAgreementContractNo: string;
  paymentSupport: boolean;
  adhocLimitAllowable: boolean;
  adhocLimitAmount: string;
  adhocLimitStartDate: string;
  adhocLimitEndDate: string;
  dealerClientTaxCode: string;
  currentPartiallyRepaidPrincipalAmount: string;
}

export interface StatisticsOfDealerAgreementVOModel extends StatisticsOfDealerAgreementVO {
  totalUploadedInvoiceAmount: BigNumber;
  totalDeletedInvoiceAmount: BigNumber;
  totalSettledInvoiceAmount: BigNumber;
  totalUnsettledInvoiceAmount: BigNumber;
  totalDisbursedLoanAmount: BigNumber;
  loanLimitAmount: BigNumber;
  loanLimitCheckType: LOAN_LIMIT_CHECK_TYPE;
  currentSettlementWaitingInvoiceAmount: BigNumber;
  currentDisbursedLoanAmount: BigNumber;
  currentRequestedLoanAmount: BigNumber;
  currencyType: CURRENCY_TYPE;
  financierCode: FINANCIER_TYPE;
  collateralType: SUPPORTED_COLLATERAL_TYPE;
  basisInterestType: BASIS_INTEREST_TYPE;
  adhocLimitAmount: BigNumber;
  currentPartiallyRepaidPrincipalAmount: BigNumber;
  // 프론트에서만 사용하는 통계 관련 컬럼
  remainingLimit: BigNumber; // remainingLimit = loanLimitAmount + currentPartiallyRepaidPrincipalAmount - currentDisbursedLoanAmount
  totalRemainingLimit: BigNumber; // totalRemainingLimit = remainingLimit + (adhocLimitAllowable === true ? adhocLimitAmount : 0)
  registeredInvoiceAmount: BigNumber; // totalUploadedInvoiceAmount - totalDeletedInvoiceAmount
  registeredInvoiceCount: number; // totalUploadedInvoiceCount - totalDeletedInvoiceCount,
  currentLoanBalanceAmount: BigNumber; // currentDisbursedLoanAmount - currentPartiallyRepaidPrincipalAmount
}

export function formattingToStatisticsOfDealerAgreementVOModel(
  data: StatisticsOfDealerAgreementVO,
): StatisticsOfDealerAgreementVOModel {
  const slicedZeroDecimalData = {
    totalUploadedInvoiceAmount: sliceZeroDecimal(data.totalUploadedInvoiceAmount),
    totalDeletedInvoiceAmount: sliceZeroDecimal(data.totalDeletedInvoiceAmount),
    totalSettledInvoiceAmount: sliceZeroDecimal(data.totalSettledInvoiceAmount),
    totalUnsettledInvoiceAmount: sliceZeroDecimal(data.totalUnsettledInvoiceAmount),
    totalDisbursedLoanAmount: sliceZeroDecimal(data.totalDisbursedLoanAmount),
    currentDisbursedLoanAmount: sliceZeroDecimal(data.currentDisbursedLoanAmount),
    currentRequestedLoanAmount: sliceZeroDecimal(data.currentRequestedLoanAmount),
    currentSettlementWaitingInvoiceAmount: sliceZeroDecimal(data.currentSettlementWaitingInvoiceAmount),
    loanLimitAmount: sliceZeroDecimal(data.loanLimitAmount),
    adhocLimitAmount: sliceZeroDecimal(data.adhocLimitAmount),
    currentPartiallyRepaidPrincipalAmount: sliceZeroDecimal(data.currentPartiallyRepaidPrincipalAmount),
  };

  const calculatorBigNumber = new CalculatorBigNumber();
  // remainingLimit = loanLimitAmount + currentPartiallyRepaidPrincipalAmount - currentDisbursedLoanAmount
  const remainingLimit = calculatorBigNumber
    .add(slicedZeroDecimalData.loanLimitAmount)
    .add(slicedZeroDecimalData.currentPartiallyRepaidPrincipalAmount)
    .minus(slicedZeroDecimalData.currentDisbursedLoanAmount)
    .get();

  return {
    ...data,
    totalUploadedInvoiceAmount: slicedZeroDecimalData.totalUploadedInvoiceAmount,
    totalDeletedInvoiceAmount: slicedZeroDecimalData.totalDeletedInvoiceAmount,
    totalSettledInvoiceAmount: slicedZeroDecimalData.totalSettledInvoiceAmount,
    totalUnsettledInvoiceAmount: slicedZeroDecimalData.totalUnsettledInvoiceAmount,
    totalDisbursedLoanAmount: slicedZeroDecimalData.totalDisbursedLoanAmount,
    loanLimitAmount: slicedZeroDecimalData.loanLimitAmount,
    loanLimitCheckType: stringToEnum(LOAN_LIMIT_CHECK_TYPE, data.loanLimitCheckType),
    currentSettlementWaitingInvoiceAmount: slicedZeroDecimalData.currentSettlementWaitingInvoiceAmount,
    currentDisbursedLoanAmount: slicedZeroDecimalData.currentDisbursedLoanAmount,
    currentRequestedLoanAmount: slicedZeroDecimalData.currentRequestedLoanAmount,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    financierCode: stringToEnum(FINANCIER_TYPE, data.financierCode),
    collateralType: stringToEnum(SUPPORTED_COLLATERAL_TYPE, data.collateralType),
    basisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.basisInterestType),
    adhocLimitAmount: slicedZeroDecimalData.adhocLimitAmount,
    currentPartiallyRepaidPrincipalAmount: slicedZeroDecimalData.currentPartiallyRepaidPrincipalAmount,
    // 프론트에서만 사용하는 통계 관련 컬럼
    remainingLimit: remainingLimit,
    /**
     * totalRemainingLimit = adhocLimitAllowable === true -> remainingLimit + adhocLimitAmount
     * totalRemainingLimit = adhocLimitAllowable === false -> remainingLimit
     */
    totalRemainingLimit: data.adhocLimitAllowable
      ? calculatorBigNumber.add(remainingLimit).add(slicedZeroDecimalData.adhocLimitAmount).get()
      : remainingLimit,
    registeredInvoiceAmount: calculatorBigNumber
      .add(slicedZeroDecimalData.totalUploadedInvoiceAmount)
      .minus(slicedZeroDecimalData.totalDeletedInvoiceAmount)
      .get(), // totalUploadedInvoiceAmount - totalDeletedInvoiceAmount
    registeredInvoiceCount: data.totalUploadedInvoiceCount - data.totalDeletedInvoiceCount,
    currentLoanBalanceAmount: calculatorBigNumber
      .add(slicedZeroDecimalData.currentDisbursedLoanAmount)
      .minus(slicedZeroDecimalData.currentPartiallyRepaidPrincipalAmount)
      .get(), // currentLoanBalanceAmount = currentDisbursedLoanAmount - currentPartiallyRepaidPrincipalAmount
  };
}
