import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { isLoanDisbursed } from 'utils/status';

import SystemFinancingDetailAdditionalDocuments from './sections/additional-documents';
import SystemFinancingDetailArInformation from './sections/ar-information';
import SystemFinancingDetailDesignatedBankAccountInformation from './sections/designated-bank-account-information';
import SystemFinancingDetailEarlyRepaymentRequestInformation from './sections/early-repayment-request-information';
import SystemFinancingDetailExtensionHistory from './sections/financing-extension-history';
import SystemFinancingDetailFinancingRequestInformation from './sections/financing-request-information';
import SystemFinancingDetailGuideMessage from './sections/guide-message';
import SystemFinancingDetailInvoiceInformation from './sections/invoice-information';
import SystemFinancingDetailPurposeOfFinancingList from './sections/purpose-of-financing';
import SystemFinancingDetailRepaymentTransactionHistory from './sections/repayment-transaction-history';
import SystemFinancingDetailSummary from './sections/summary';
import { useSystemFinancingDetailState } from './useSystemFinancingDetailState';

function SystemFinancingDetail() {
  const mounted = useMounted();
  const { t } = useTranslation(['format']);

  const { state, fetchAll, paginates, pageables, onClickUpdateTransaction, onClickViewExpectedRepaymentAmount } =
    useSystemFinancingDetailState();

  const { factoringEnable } = state.syLoanDetail;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Details')} />
      <SystemFinancingDetailGuideMessage
        data={state.syLoanDetail}
        loanInterface={state.loanInterface}
        onClickUpdateTransaction={onClickUpdateTransaction}
      />
      <SystemFinancingDetailSummary
        data={state.syLoanDetail}
        factoringEnable={factoringEnable}
        useInterface={state.loanInterface}
        onClickViewExpectedRepaymentAmount={onClickViewExpectedRepaymentAmount}
      />
      <SystemFinancingDetailFinancingRequestInformation data={state.syLoanDetail} factoringEnable={factoringEnable} />
      {!state.isNilInvoiceIdAndSuccessArId && state.syLoanDetail.collateralType === COLLATERAL_TYPE.INVOICE && (
        <SystemFinancingDetailInvoiceInformation invoiceData={state.syInvoiceDetail} loanData={state.syLoanDetail} />
      )}
      {!state.isNilInvoiceIdAndSuccessArId && state.syLoanDetail.collateralType === COLLATERAL_TYPE.AR && (
        <SystemFinancingDetailArInformation
          arData={state.syArDetail}
          loanData={state.syLoanDetail}
          factoringEnable={factoringEnable}
        />
      )}
      {state.syLoanDetail.collateralType === COLLATERAL_TYPE.AR &&
        state.syLoanDetail.purposeOfLoanList?.length !== 0 &&
        !factoringEnable && (
          <SystemFinancingDetailPurposeOfFinancingList
            purposeListData={state.syLoanDetail.purposeOfLoanList}
            totalPurposeOfLoanAmount={state.syLoanDetail.totalPurposeOfLoanAmount}
          />
        )}
      <SystemFinancingDetailDesignatedBankAccountInformation
        data={state.syLoanDetail}
        factoringEnable={factoringEnable}
      />
      <SystemFinancingDetailAdditionalDocuments
        loanId={state.syLoanDetail.loanId}
        loanEtcAttachmentListData={state.syLoanDetail.loanEtcAttachment}
      />
      {isLoanDisbursed(state.syLoanDetail.loanStatus) && (
        <SystemFinancingDetailRepaymentTransactionHistory
          loanTransactionHistoryData={state.syLoanTransactionHistoryList}
          pageable={pageables.loanTransactionHistoryPageable}
          paginate={paginates.getLoanTransactionHistoryPaginate}
        />
      )}
      {state.syEarlyRepaymentList?.length !== 0 && (
        <SystemFinancingDetailEarlyRepaymentRequestInformation
          earlyRepaymentListData={state.syEarlyRepaymentList}
          pageable={pageables.earlyRepaymentPageable}
          paginate={paginates.getEarlyRepaymentListPaginate}
        />
      )}
      {state.syExtensionList?.length !== 0 && (
        <SystemFinancingDetailExtensionHistory
          extensionListData={state.syExtensionList}
          pageable={pageables.extensionPageable}
          paginate={paginates.getExtensionListPaginate}
        />
      )}
    </>
  );
}

export default SystemFinancingDetail;
