import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNil, isNull } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AR_COMMISSION_STATUS, AUTHORITY_TYPE, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { ArCommissionSettlementDetailVOModel } from 'models/vo/ArCommissionSettlementDetailVO';
import type { ArCommissionSettlementRelatedLoanVOModel } from 'models/vo/ArCommissionSettlementRelatedLoanVO';
import type { ArCommissionVOModel } from 'models/vo/ArCommissionVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import {
  requestFiArCommissionSettlementDetail,
  requestFiArCommissionSettlementRelatedLoans,
} from 'utils/http/api/financier/ar-commision-settlements';
import {
  requestFiAllArCommissionStatusUpdate,
  requestFiArCommissionStatusUpdate,
  requestFiArCommissions,
} from 'utils/http/api/financier/ar-commissions';
import type {
  UpdateAllArCommissionStatusPaidDTO,
  UpdateArCommissionStatusDTO,
} from 'utils/http/api/financier/ar-commissions/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { addSearchParams, makeSearchParamsPattern, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import FinancierSettlementPaymentSummary from '../sections/FinancierArSettlementPaymentSummary';

type CheckedArCommission = {
  index: number;
  arCommissionId: number;
  arCommissionStatus: AR_COMMISSION_STATUS;
};

const FI_AR_COMMISSION_LIST_QS_KEY = 'fi-ar-commission-list';
const FI_AR_COMMISSION_SETTLEMENT_RELATED_LOAN_LIST_QS_KEY = 'fi-ar-commission-settlement-related-loan-list';

function FinancierWithdrawalFromPartnerDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { dealerAgreementId, settlementDate } = useParams<any>();

  const [financierArSettlementDetail, setFinancierArSettlementDetail] = useState<ArCommissionSettlementDetailVOModel>();
  const [arCommissions, setArCommissions] = useState<Pageable<ArCommissionVOModel[]>>();
  const [arCommissionSettlementRelatedLoans, setArCommissionSettlementRelatedLoans] =
    useState<Pageable<ArCommissionSettlementRelatedLoanVOModel[]>>();

  const arCommissionsPageHandler = usePageable(FI_AR_COMMISSION_LIST_QS_KEY);
  const arCommissionSettlementRelatedLoansPageHandler = usePageable(
    FI_AR_COMMISSION_SETTLEMENT_RELATED_LOAN_LIST_QS_KEY,
  );

  const [checkedArCommissions, setCheckedArCommissions] = useState<CheckedArCommission[]>([]);
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const getCheckedPendingArCommissionIds = useCallback((): number[] => {
    return checkedArCommissions
      .filter(
        arCommission =>
          arCommission.arCommissionStatus === AR_COMMISSION_STATUS.CREATED && !isNull(arCommission.arCommissionId),
      )
      .map(arCommission => arCommission.arCommissionId);
  }, [checkedArCommissions]);

  const getCheckedCompletedOrWaivedArCommissionIds = useCallback(
    (): number[] =>
      checkedArCommissions
        .filter(
          arCommission =>
            arCommission.arCommissionStatus !== AR_COMMISSION_STATUS.CREATED && !isNull(arCommission.arCommissionId),
        )
        .map(arCommission => arCommission.arCommissionId),
    [checkedArCommissions],
  );

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [
        fetchedFinancierSettlementPaymentDetail,
        fetchedFinancierArCommissions,
        fetchedFiArCommissionSettlementRelatedLoans,
      ] = await Promise.all([
        requestFiArCommissionSettlementDetail(dealerAgreementId, settlementDate),
        requestFiArCommissions(
          arCommissionsPageHandler.pageable.currentPage,
          arCommissionsPageHandler.pageable.sizePerPage,
          {
            dealerAgreementId,
            settlementDateFrom: settlementDate,
            settlementDateTo: settlementDate,
          },
        ),
        requestFiArCommissionSettlementRelatedLoans(
          arCommissionSettlementRelatedLoansPageHandler.pageable.currentPage,
          arCommissionSettlementRelatedLoansPageHandler.pageable.sizePerPage,
          {
            dealerAgreementId,
            settlementDate,
          },
        ),
      ]);

      addArCommissionsAndSettlementRelatedLoan();

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierArSettlementDetail(fetchedFinancierSettlementPaymentDetail);
        setArCommissions(fetchedFinancierArCommissions);
        setArCommissionSettlementRelatedLoans(fetchedFiArCommissionSettlementRelatedLoans);
        arCommissionsPageHandler.setPageable(fetchedFinancierArCommissions);
        arCommissionSettlementRelatedLoansPageHandler.setPageable(fetchedFiArCommissionSettlementRelatedLoans);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const addArCommissionsAndSettlementRelatedLoan = () => {
    const arCommissionListQs = makeSearchParamsPattern(
      {
        pageNumber: arCommissionsPageHandler.pageable.currentPage,
        rowCount: arCommissionsPageHandler.pageable.sizePerPage,
      },
      FI_AR_COMMISSION_LIST_QS_KEY,
    );
    const arCommissionSettlementRelatedLoanListQs = makeSearchParamsPattern(
      {
        pageNumber: arCommissionSettlementRelatedLoansPageHandler.pageable.currentPage,
        rowCount: arCommissionSettlementRelatedLoansPageHandler.pageable.sizePerPage,
      },
      FI_AR_COMMISSION_SETTLEMENT_RELATED_LOAN_LIST_QS_KEY,
    );
    addSearchParams(arCommissionListQs + arCommissionSettlementRelatedLoanListQs);
  };

  const arCommissionsPaginate = async (page: number, sizePerPage: number) => {
    try {
      const fetchedFinancierArCommissions = await requestFiArCommissions(page, sizePerPage, {
        dealerAgreementId,
        settlementDateFrom: settlementDate,
        settlementDateTo: settlementDate,
      });

      updateSearchParams(
        {
          pageNumber: page,
          rowCount: sizePerPage,
        },
        FI_AR_COMMISSION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setArCommissions(fetchedFinancierArCommissions);
        setCheckedArCommissions([]);
        arCommissionsPageHandler.setPageable(fetchedFinancierArCommissions);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const arCommissionSettlementRelatedLoansPaginate = async (page: number, sizePerPage: number) => {
    try {
      const fetchedFiArCommissionSettlementRelatedLoans = await requestFiArCommissionSettlementRelatedLoans(
        page,
        sizePerPage,
        {
          dealerAgreementId,
          settlementDate,
        },
      );

      updateSearchParams(
        {
          pageNumber: page,
          rowCount: sizePerPage,
        },
        FI_AR_COMMISSION_SETTLEMENT_RELATED_LOAN_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setArCommissionSettlementRelatedLoans(fetchedFiArCommissionSettlementRelatedLoans);
        arCommissionSettlementRelatedLoansPageHandler.setPageable(fetchedFiArCommissionSettlementRelatedLoans);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const allArCommission = arCommissions?.content.map((item, index) => ({
        index: index,
        arCommissionId: item.arCommissionId,
        arCommissionStatus: item.arCommissionStatus,
      }));
      setCheckedArCommissions(allArCommission ?? []);
    } else {
      setCheckedArCommissions([]);
    }
  };

  const handleCheckChange = (e: any, arInfo: CheckedArCommission) => {
    // TODO : handleCheck : too slow...
    if (e.target.checked) {
      const target = [...checkedArCommissions, arInfo];
      setCheckedArCommissions(target);
    } else {
      const target = checkedArCommissions.filter(el => el.index !== arInfo.index);
      setCheckedArCommissions(target);
    }
  };

  const handleArCommissionModalClose = async () => {
    setCheckedArCommissions([]);
    try {
      fetchAll();
    } catch (error) {
      modal.show(error);
    }
  };

  const handleCompleteAllArCommissions = async () => {
    try {
      modal.show(
        <>
          <h6>{t('text:Would_you_like_to_withdraw_all_Transaction_Fees?')}</h6>
          <h6>{t('text:Please_check_the_following')} :</h6>
          <ul className="mt-3 ms-3">
            <li>
              • {t('text:Number_of_AR')} :{' '}
              <strong>{t('format:number', { value: financierArSettlementDetail?.totalArCommissionCount })}</strong>
            </li>
            <li>
              • {t('text:Full_Transaction_fee')} :{' '}
              <strong>{t('format:number', { value: financierArSettlementDetail?.totalArCommissionAmount })}</strong>
            </li>
          </ul>
        </>,
        {
          title: t('text:Complete_all'),
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => handleConfirm(),
        },
      );
    } catch (error) {
      modal.show(error);
    }

    const handleConfirm = async (): Promise<void> => {
      const updateAllArCommissionStatusPaidDTO: UpdateAllArCommissionStatusPaidDTO = {
        dealerAgreementId,
        settlementDate,
      };
      try {
        await requestFiAllArCommissionStatusUpdate(updateAllArCommissionStatusPaidDTO);
        modal.show(<h6>{t('text:The_Transaction_fee_Status_has_been_modified_successfully')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => handleArCommissionModalClose(),
        });
      } catch (error) {
        modal.show(error);
      }
    };
  };

  const handleCompleteCheckedArCommissions = async () => {
    const checkedPendingArCommissionIds = getCheckedPendingArCommissionIds();
    try {
      modal.show(
        <>
          <h6>{t('text:Would_you_like_to_withdraw_the_Transaction_fee?')}</h6>
          <h6>{t('text:Please_check_the_following')} :</h6>
          <ul className="mt-3 ms-3">
            <li>
              • {t('text:Number_of_AR')} : <strong>{checkedPendingArCommissionIds.length}</strong>
            </li>
          </ul>
        </>,
        {
          title: t('text:Complete'),
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => handleConfirm(),
        },
      );
    } catch (error) {
      modal.show(error);
    }

    const handleConfirm = async (): Promise<void> => {
      try {
        const updateArCommissionStatusDTO: UpdateArCommissionStatusDTO = {
          dealerAgreementId,
          arCommissionIds: checkedPendingArCommissionIds,
          targetCommissionStatus: AR_COMMISSION_STATUS.PAID,
        };
        await requestFiArCommissionStatusUpdate(updateArCommissionStatusDTO);
        modal.show(<h6>{t('text:The_Transaction_fee_Status_has_been_modified_successfully')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => handleArCommissionModalClose(),
        });
      } catch (error) {
        modal.show(error);
      }
    };
  };

  const handleWaivedCheckedArCommissions = async () => {
    const checkedPendingArCommissionIds = getCheckedPendingArCommissionIds();
    try {
      modal.show(
        <>
          <h6>{t('text:The_Transaction_fee_are_waived')}</h6>
          <h6>{t('text:Please_check_the_following')} :</h6>
          <ul className="mt-3 ms-3">
            <li>
              • {t('text:Number_of_AR')} : <strong>{checkedPendingArCommissionIds.length}</strong>
            </li>
          </ul>
        </>,
        {
          title: t('text:Waive'),
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => handleConfirm(),
        },
      );
    } catch (error) {
      modal.show(error);
    }

    const handleConfirm = async (): Promise<void> => {
      try {
        const updateArCommissionStatusDTO: UpdateArCommissionStatusDTO = {
          dealerAgreementId,
          arCommissionIds: checkedPendingArCommissionIds,
          targetCommissionStatus: AR_COMMISSION_STATUS.WAIVED,
        };
        await requestFiArCommissionStatusUpdate(updateArCommissionStatusDTO);
        modal.show(<h6>{t('text:The_Transaction_fee_Status_has_been_modified_successfully')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => handleArCommissionModalClose(),
        });
      } catch (error) {
        modal.show(error);
      }
    };
  };

  const handleResetCheckedArCommissions = async () => {
    const checkedCompletedOrWaivedArCommissionIds = getCheckedCompletedOrWaivedArCommissionIds();

    try {
      modal.show(
        <>
          <h6>{t('text:The_Transaction_fee_will_return_to_pending_status')}</h6>
          <h6>{t('text:Please_check_the_following')} :</h6>
          <ul className="mt-3 ms-3">
            <li>
              • {t('text:Number_of_AR')} : <strong>{checkedCompletedOrWaivedArCommissionIds.length}</strong>
            </li>
          </ul>
        </>,
        {
          title: t('text:Status_Reset'),
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => handleConfirm(),
        },
      );
    } catch (error) {
      modal.show(error);
    }

    const handleConfirm = async (): Promise<void> => {
      const checkedCompletedOrWaivedArCommissionIds = getCheckedCompletedOrWaivedArCommissionIds();

      try {
        const updateArCommissionStatusDTO: UpdateArCommissionStatusDTO = {
          dealerAgreementId,
          arCommissionIds: checkedCompletedOrWaivedArCommissionIds,
          targetCommissionStatus: AR_COMMISSION_STATUS.CREATED,
        };
        await requestFiArCommissionStatusUpdate(updateArCommissionStatusDTO);
        modal.show(<h6>{t('text:The_Transaction_fee_Status_has_been_modified_successfully')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => handleArCommissionModalClose(),
        });
      } catch (error) {
        modal.show(error);
      }
    };
  };

  const arCommissionsTableHeaders: HeaderType[] = [
    ...(!isNil(arCommissions) && !isAdmin
      ? [
          {
            hasCheckBox: true,
            handleClickAllCheckBox: handleCheckAll,
            isAllChecked: checkedArCommissions.length === arCommissions?.content.length,
            colWidths: 70,
          },
        ]
      : []),
    {
      headerText: t('text:AR_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:AR_Amount'),
      colWidths: 100,
    },
    {
      headerText: t('text:Transaction_Fee'),
      colWidths: 100,
    },
    {
      headerText: t('text:Transaction_Fee_Status'),
      colWidths: 120,
      hasStatusDescription: true,
      showStatusDescriptionFunc: () => {
        modal.show(
          <StatusDescriptionModal
            statusDescriptionEnum={Object.values(AR_COMMISSION_STATUS)}
            statusDescriptionModalType="TEXT"
            statusDescriptionEnumType="AR_COMMISSION_STATUS"
          />,
          {
            modalType: ModalType.ALERT,
            modalSize: ModalSize.XL,
            closeBtnText: t('text:Close'),
          },
        );
      },
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const arCommissionSettlementRelatedLoansTableHeaders: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:AR_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 100,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 120,
      hasStatusDescription: true,
      showStatusDescriptionFunc: () => {
        modal.show(
          <StatusDescriptionModal
            statusDescriptionEnum={Object.values(LOAN_STATUS)}
            statusDescriptionModalType="TEXT"
            statusDescriptionEnumType="LOAN_STATUS"
          />,
          {
            modalType: ModalType.ALERT,
            modalSize: ModalSize.XL,
            closeBtnText: t('text:Close'),
          },
        );
      },
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderArCommissionsTableResult = (() => {
    return arCommissions?.content.map((item, index) => {
      return (
        <Tr key={index}>
          {!isAdmin && (
            <td>
              <div className="text-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  onChange={e =>
                    handleCheckChange(e, {
                      index: index,
                      arCommissionId: item.arCommissionId,
                      arCommissionStatus: item.arCommissionStatus,
                    })
                  }
                  checked={checkedArCommissions.some(arCommission => arCommission.index === index)}
                />
              </div>
            </td>
          )}
          <Td data={item.arNumber} />
          <Td data={item.arAmount} format="number" />
          <Td data={item.arCommissionAmount} format="number" />
          <Td
            className={getStatusTextClass('AR_COMMISSION_STATUS', item.arCommissionStatus)}
            data={t(`code:ar-commission-status.${item.arCommissionStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_FI.VIEW_TRANSACTION.AR_DETAIL_BUILD_PATH(item.successArId)} />
        </Tr>
      );
    });
  })();

  const renderArCommissionSettlementRelatedLoansResultTable = (() => {
    return arCommissionSettlementRelatedLoans?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.loanId} />
          <Td data={item.arNumber} />
          <Td data={item.netDisbursementAmount} format="number" />
          <Td data={item.disbursedDate} format="date" />
          <Td data={item.principalAmount} format="number" />
          <Td
            className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
            data={t(`code:financing-status.${item.loanStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(item.loanId)} />
        </Tr>
      );
    });
  })();

  return (
    <>
      <BackHeaderTitle
        title={`${financierArSettlementDetail?.partnerName} - [${tableValueManage(
          financierArSettlementDetail?.settlementDate,
          t('format:date', { value: financierArSettlementDetail?.settlementDate, key: 'date' }),
        )}]`}
      />
      <FinancierSettlementPaymentSummary financierArSettlementDetail={financierArSettlementDetail} />
      <div className="content-area">
        <SectionTitle title={t('text:AR_List_for_Transaction_Fee')} />
        <GuideMessage
          message={
            isAdmin
              ? [t('text:You_can_check_the_Transaction_Fee_imposed_on_ARs')]
              : [
                  t(
                    'text:You_can_change_the_Transaction_Fee_status_of_pending_ARs_in_the_list_by_using_the_Complete_All_button',
                  ),
                  t(
                    'text:You_can_change_the_Transaction_Fee_status_to_complete_for_selected_ARs_by_using_the_Complete_Checked_button',
                  ),
                  t('text:You_can_exempt_the_Transaction_Fee_for_selected_ARs_by_using_th_Waive_button'),
                  t('text:You_can_cancel_the_settlement_for_selected_ARs_by_using_the_Status_Reset_button') +
                    ' ' +
                    t('text:It_will_return_to_the_pending_status'),
                ]
          }
          isImportContentArea={true}
        >
          {{
            button: !isAdmin ? (
              <>
                <Button bold onClick={handleCompleteAllArCommissions}>
                  {t('text:Complete_all')}
                </Button>
                <Button onClick={handleCompleteCheckedArCommissions} bold>
                  {t('text:Complete_checked')}
                </Button>
                <Button
                  onClick={handleWaivedCheckedArCommissions}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  bold
                >
                  {t('text:Waive')}
                </Button>
                <Button
                  onClick={handleResetCheckedArCommissions}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.RED}
                  bold
                >
                  {t('text:Status_Reset')}
                </Button>
              </>
            ) : null,
          }}
        </GuideMessage>
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {arCommissions?.content.length ?? '-'}
          </p>
        </div>
        <TableBorder>
          <TableHeader header={arCommissionsTableHeaders} />
          <TableBody numOfCol={arCommissionsTableHeaders.length}>{renderArCommissionsTableResult}</TableBody>
        </TableBorder>
        <Pagination pageable={arCommissionsPageHandler.pageable} paginate={arCommissionsPaginate} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Financing_List')} />
        <TableBorder>
          <TableHeader header={arCommissionSettlementRelatedLoansTableHeaders} />
          <TableBody numOfCol={arCommissionSettlementRelatedLoansTableHeaders.length}>
            {renderArCommissionSettlementRelatedLoansResultTable}
          </TableBody>
        </TableBorder>
        <Pagination
          pageable={arCommissionSettlementRelatedLoansPageHandler.pageable}
          paginate={arCommissionSettlementRelatedLoansPaginate}
        />
      </div>
    </>
  );
}

export default FinancierWithdrawalFromPartnerDetail;
