import i18n from 'i18next';

import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import type { PotentialPartnerFinancingApplicationVOModel } from 'models/vo/PotentialPartnerFinancingApplicationVO';

import type { AcquisitionTabType } from '../../FinancierScPartnerAcquisitionListTabWrapper';

export const renderBranchNameData = (
  tab: AcquisitionTabType,
  dataModel: PotentialPartnerFinancingApplicationVOModel,
) => {
  switch (tab) {
    case 'completed':
      return dataModel.requestBranchName;
    case 'rejected':
    case 'inProgress':
      return dataModel.reviewedBranchName;
    case 'submitted':
      return dataModel.requestBranchName;
  }
};

export const renderBranchCodeData = (
  tab: AcquisitionTabType,
  dataModel: PotentialPartnerFinancingApplicationVOModel,
) => {
  switch (tab) {
    case 'completed':
      return dataModel.requestBranchCode;
    case 'rejected':
    case 'inProgress':
      return dataModel.reviewedBranchCode;
    case 'submitted':
      return dataModel.requestBranchCode;
  }
};

export const renderPotentialPartnerFinancingApplicationStatusText = (
  status: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS,
) => {
  switch (status) {
    case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED:
      return i18n.t('text:Submitted');
    case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL:
      return i18n.t('text:Cancelled');
  }
};
