import type Pageable from 'models/Pageable';
import type { AccountTransactionVO, AccountTransactionVOModel } from 'models/vo/AccountTransactionVO';
import { formattingToAccountTransactionVOModel } from 'models/vo/AccountTransactionVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemAccountTransactionListRequest } from './requests';

export async function requestSystemAccountTransactionList(
  pageNumber: number,
  rowCount: number,
  anchorAgreementId: number,
  data: SystemAccountTransactionListRequest,
): Promise<Pageable<AccountTransactionVOModel[]>> {
  const response = await http.get<Pageable<AccountTransactionVO[]>>({
    url: API_SY.ACCOUNT_TRANSACTIONS.ACCOUNT_TRANSACTION_LIST(anchorAgreementId),
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToAccountTransactionVOModel(item)),
  };
}

export async function requestSystemAccountTransactionSettlementAccountBalance(
  anchorAgreementId: number,
): Promise<number> {
  return await http.get<number>({
    url: API_SY.ACCOUNT_TRANSACTIONS.SETTLEMENT_ACCOUNT_BALANCE(anchorAgreementId),
  });
}
