import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import ReactDatePicker from '../Form/ReactDatePicker/ReactDatePicker';

interface SearchDatePickerProps {
  placeholder: string;
  name: string;
  disabled?: boolean;
  defaultValue?: string | number | ReadonlyArray<string> | null;
  minDate?: Date;
  maxDate?: Date;
  col?: number;
  control: Control;
}
const SearchDatePicker = ({
  defaultValue = null,
  minDate = new Date('1970-01-01'),
  maxDate = new Date('2999-12-31'),
  name,
  disabled = false,
  col = 2,
  placeholder,
  control,
}: SearchDatePickerProps) => {
  return (
    <div className={`search-form__input-wrap col-${col}`}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={field => (
          <ReactDatePicker
            field={field}
            className="input_search"
            placeholder={placeholder}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
          />
        )}
        rules={{ required: true }}
      />
    </div>
  );
};

export default SearchDatePicker;
