import type { ReactNode, RefObject } from 'react';
import type React from 'react';
import { createContext, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import './AccordionTr.scss';

import type { AccordionStateType } from './Accordion';

interface AccordionTrStateType extends AccordionStateType {
  accordionRef: RefObject<AccordionRefType>;
}

export const AccordionTrContext = createContext<AccordionTrStateType | null>(null);
AccordionTrContext.displayName = 'AccordionTrContext';

export const useAccordionTrContext = () => {
  const context = useContext(AccordionTrContext);
  if (!context) {
    throw new Error('useAccordionTrContext should be used within AccordionTrContext.Provider');
  }

  return context;
};

interface AccordionTrRootPropsType {
  id: string;
  className?: string;
  children: ReactNode;
  defaultExpanded?: boolean;
}

type AccordionRefType = {
  length: () => number;
};

const AccordionTrRoot = ({ id, children, defaultExpanded = false }: AccordionTrRootPropsType) => {
  const [expanded, setExpanded] = useState(false);
  const accordionRef = useRef<AccordionRefType>(null);

  const contextValue = {
    id,
    expanded,
    setExpanded,
    accordionRef,
  };

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <AccordionTrContext.Provider value={contextValue}>
      <>{children}</>
    </AccordionTrContext.Provider>
  );
};

type TriggerPropsType = {
  className?: string;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
};

const Trigger = ({ className, children, onClick }: TriggerPropsType) => {
  const { id, expanded, setExpanded, accordionRef } = useAccordionTrContext();

  useImperativeHandle(accordionRef, () => ({
    length: () => (children ? (Array.isArray(children) ? children.length : 1) : 0),
  }));

  const toggleAccordion = (event: React.MouseEvent<HTMLTableRowElement>) => {
    setExpanded(prevExpanded => !prevExpanded);
    onClick && onClick(event);
  };

  return (
    <tr id={id} className={className} onClick={toggleAccordion} aria-expanded={expanded}>
      {children}
    </tr>
  );
};

type ContentPropsType = {
  children: ReactNode;
};

const Content = ({ children }: ContentPropsType) => {
  const { id, expanded, accordionRef } = useAccordionTrContext();

  return (
    <tr id={`${id}-container`} className={`accordion-collapse collapse ${expanded ? 'show' : ''}`}>
      <td id={`${id}-inner-container`} className="accordion-td" colSpan={accordionRef?.current?.length()}>
        {children}
      </td>
    </tr>
  );
};

const AccordionTr = Object.assign(AccordionTrRoot, {
  Trigger,
  Content,
});

export default AccordionTr;
