import type { RegistrableLimitVO, RegistrableLimitVOModel } from 'models/vo/RegistrableLimitVO';
import { formattingToRegistrableLimitVOModel } from 'models/vo/RegistrableLimitVO';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';

export async function requestRegistrableLimitByDealerAgreement(
  dealerAgreementId: number,
): Promise<RegistrableLimitVOModel> {
  const response = await http.get<RegistrableLimitVO>({
    url: API_CM.REGISTRABLE_LIMIT.LIMIT_BY_DEALER_AGREEMENT(dealerAgreementId),
  });

  return formattingToRegistrableLimitVOModel(response);
}
