import type { UserVO, UserVOModel } from 'models/vo/UserVO';
import { formattingToUserVOModel } from 'models/vo/UserVO';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';
import type { CurrentUserChangeInfoRequest, CurrentUserChangePassword } from 'utils/http/api/common/users/request';

// 내 정보 조회
export async function requestCurrentUser(): Promise<UserVOModel> {
  const response = await http.get<UserVO>({
    url: API_CM.USERS.CURRENT_USER,
  });

  return formattingToUserVOModel(response);
}

// 패스워드 재설정 - 로그인 한 유저
export async function requestCurrentUserResetPassword(data: CurrentUserChangePassword) {
  return await http.put({
    url: API_CM.USERS.RESET_PASSWORD,
    data,
  });
}

// 내 정보 변경
export async function requestCurrentUserChangeInfo(data: CurrentUserChangeInfoRequest) {
  return await http.put({
    url: API_CM.USERS.CURRENT_USER,
    data,
  });
}
