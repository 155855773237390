export interface BranchVO {
  branchId: number;
  branchName: string;
  branchCode: string;
  branchBusinessCode: string;
  branchTaxCode: string;
  zipCode: string;
  address: string;
  telephone: string;
  fax: string;
  managerName: string;
  managerEmployeeCode: string;
  managerEmail: string;
  managerMobile: string;
  managerPosition: string;
  financierId: number;
}

export interface BranchVOModel extends BranchVO {}

export function formattingToBranchVOModel(data: BranchVO): BranchVOModel {
  return {
    ...data,
  };
}
