import { useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Form, { useFormContext } from 'components/stateless/Form';
import { Border } from 'components/templates/section';
import type { TermSpreadVOModel } from 'models/vo/TermSpreadVO';
import { isNilOrEmptyString } from 'utils/helpers';
import type { CreateFiWaitingTermSpreadDTO } from 'utils/http/api/financier/dealer-agreements/request';

import './termSpread.scss';

const TERM_SPREAD_DEFAULT_SIZE = 5;

type TermSpreadPropsType = {
  useTermSpread: boolean | null;
  termSpreadData?: TermSpreadVOModel[] | null;
  useAgreementInterface: boolean;
};

const TermSpread = ({ useTermSpread, termSpreadData, useAgreementInterface }: TermSpreadPropsType) => {
  const { t } = useTranslation(['format']);

  const {
    methods: { register, control, watch },
    isEditable,
  } = useFormContext();

  const termSpreadList = watch('termSpreadList', termSpreadData);

  const { fields, append, remove } = useFieldArray<CreateFiWaitingTermSpreadDTO>({
    control,
    name: 'termSpreadList',
  });

  const isButtonHidden = useAgreementInterface ? termSpreadData : !(isEditable && useTermSpread);

  const addTermSpreadRow = useCallback(() => {
    append({
      minimumDateRange: null,
      termSpreadRate: null,
    });
  }, [append]);

  // 가산금리 데이터가 없을 때 row 초기화
  const resetTermSpread = useCallback(
    (useTermSpread: boolean | null) => {
      if (useTermSpread) {
        for (let i = 0; i < TERM_SPREAD_DEFAULT_SIZE; i++) {
          addTermSpreadRow();
        }
      } else {
        remove();
      }
    },
    [addTermSpreadRow, remove],
  );

  const handleAddRowClick = () => addTermSpreadRow();

  const handleDeleteLastRowClick = () => remove(fields.length - 1);

  useEffect(() => {
    if (isEmpty(termSpreadData)) resetTermSpread(useTermSpread);
  }, [resetTermSpread, termSpreadData, useTermSpread]);

  if (!useTermSpread) return null;

  return (
    <Border>
      <table className="term-spread__table">
        <colgroup>
          <col />
          <col style={{ width: '30%' }} />
          <col style={{ width: '56px' }} />
        </colgroup>
        <thead className="term-spread__head">
          <tr>
            <th scope="col">
              <Form.Label name="termSpreadList" required>
                {t('text:Term_Days')}
              </Form.Label>
            </th>
            <th scope="col">
              <Form.Label name="termSpreadList" required>
                {t('text:Term_Spread')} (%)
              </Form.Label>
            </th>
            <th scope="col" className={clsx({ 'term-spread__no-left-border': isButtonHidden })} />
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const minimumDateRange = termSpreadList?.[index]?.minimumDateRange;
            const isEdgeRow = (index: number) => index === 0 || index === fields.length - 1;

            return (
              <tr
                key={`term-spread-row-${item.id}`}
                className={clsx({ 'term-spread__tr--background-gray': isEdgeRow(index) })}
              >
                <td>
                  <div className="term-spread__term-box">
                    <div>
                      {/* 첫 번째 minimumDateRange는 무조건 '0' */}
                      {index === 0 && (
                        <input
                          type="hidden"
                          defaultValue={0}
                          disabled
                          name="termSpreadList.0.minimumDateRange"
                          ref={register()}
                        />
                      )}
                      <div className="term-spread__value" data-testid="nextRowMinimumDateRangeTag">
                        {isNilOrEmptyString(minimumDateRange)
                          ? '-'
                          : t('format:number', {
                              value: index === 0 ? 0 : minimumDateRange,
                            })}
                      </div>
                    </div>
                    <div className="term-spread__angle-sign">&lt;</div>
                    <div>T</div>
                    {fields.length !== index + 1 && (
                      <>
                        <div className="term-spread__angle-sign">≤</div>
                        <div>
                          <Form.NumberInput
                            name={`termSpreadList.${index + 1}.minimumDateRange`}
                            numberType="integer"
                            defaultValue={termSpreadData?.[index + 1]?.minimumDateRange ?? null}
                            disabled={!isEditable}
                            textAlign="text-center"
                            placeholder="e.g. 30"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <Form.NumberInput
                    name={`termSpreadList.${index}.termSpreadRate`}
                    numberType="float"
                    defaultValue={item.termSpreadRate}
                    disabled={!isEditable}
                    textAlign="text-right"
                    rightUnit="%"
                  />
                </td>
                <td style={{ maxWidth: '56px' }} className={clsx({ 'term-spread__no-left-border': isButtonHidden })}>
                  {!isButtonHidden && fields.length === index + 1 && fields.length !== 2 && (
                    <Button
                      className="term-spread__minus-button"
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={handleDeleteLastRowClick}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
          {!isButtonHidden && (
            <tr className="term-spread__tr--background-gray">
              <td colSpan={3}>
                <Button
                  onClick={handleAddRowClick}
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  fullWidth
                  bold
                >
                  + {t('text:Add_New_Term')}
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Border>
  );
};

export default TermSpread;
