import type { TempArSummaryVO, TempArSummaryVOModel } from 'models/vo/TempArSummaryVO';
import { formattingToTempArSummaryVOModel } from 'models/vo/TempArSummaryVO';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';

import type { TempArRegisterRequest } from './request';

export async function requestTempArData(anchorAgreementId: number): Promise<TempArSummaryVOModel> {
  const response = await http.get<TempArSummaryVO>({
    url: API_CM.TEMP_AR_SUMMARIES.TEMP_AR_SUMMARY_DETAIL(anchorAgreementId),
  });

  return formattingToTempArSummaryVOModel(response);
}

export async function requestTempArRegister(data: TempArRegisterRequest): Promise<void> {
  await http.post<TempArSummaryVO>({
    url: API_CM.TEMP_AR_SUMMARIES.TEMP_AR_SUMMARIES,
    data,
  });
}
