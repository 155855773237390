import { isEmpty } from 'lodash-es';

import type { NewObject } from 'types';

export const getObjectValue = ({ object, name }: { object?: NewObject; name: string }) => {
  const keys = name.split('.');

  const getObjectValueWithKey = (object: NewObject, keyIndex: number = 0): any => {
    const value = object?.[keys?.[keyIndex]];

    if (keys.length === keyIndex + 1) return value;
    else return getObjectValueWithKey(value, keyIndex + 1);
  };

  return object && getObjectValueWithKey(object);
};

export const emptyObjectToNull = (obj: any) => {
  return isEmpty(obj) ? null : obj;
};
