import type Pageable from 'models/Pageable';
import type { CalculateForLoanRequestVO, CalculateForLoanRequestVOModel } from 'models/vo/CalculateForLoanRequestVO';
import { formattingToCalculateForLoanRequestVOModel } from 'models/vo/CalculateForLoanRequestVO';
import type { FactoringAttachmentVO, FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import { formattingToFactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import type { LoanDetailVO, LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import { formattingToLoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { PartialCollateralVO, PartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import { formattingToPartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import type { SuccessArDetailVO, SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import { formattingToSuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessArForRequestLoanVO, SuccessArForRequestLoanVOModel } from 'models/vo/SuccessArForRequestLoanVO';
import { formattingToSuccessArForRequestLoanVOModel } from 'models/vo/SuccessArForRequestLoanVO';
import type {
  SuccessArForRequestMultipleLoanVO,
  SuccessArForRequestMultipleLoanVOModel,
} from 'models/vo/SuccessArForRequestMultipleLoanVO';
import { formattingToSuccessArForRequestMultipleLoanVOModel } from 'models/vo/SuccessArForRequestMultipleLoanVO';
import type { SuccessArVO, SuccessArVOModel } from 'models/vo/SuccessArVO';
import { formattingToSuccessArVOModel } from 'models/vo/SuccessArVO';
import type { TempLoanVO, TempLoanVOModel } from 'models/vo/TempLoanVO';
import { formattingToTempLoanVOModel } from 'models/vo/TempLoanVO';
import http, { ContentType } from 'utils/http';
import API_DE from 'utils/http/api/dealer';
import type {
  CreateDeTempLoanByArRequest,
  DealerSuccessArLoanRequest,
  DealerSuccessArsListForLoanRequest,
  DealerSuccessArsListRequest,
} from 'utils/http/api/dealer/success-ars/requests';
import type { DeSuccessArsFactoringAttachmentsRequest } from 'utils/http/api/dealer/success-ars/requests';

// 딜러 - 등록 완료된 AR 목록 조회
export async function requestDealerSuccessArsList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerSuccessArsListRequest,
): Promise<Pageable<SuccessArVOModel[]>> {
  const response = await http.get<Pageable<SuccessArVO[]>>({
    url: API_DE.SUCCESS_ARS.SUCCESS_AR,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const successArList: SuccessArVOModel[] = response.content.map((data: SuccessArVO) =>
    formattingToSuccessArVOModel(data),
  );
  const successArPage: Pageable<SuccessArVOModel[]> = { ...response, content: successArList };

  return successArPage;
}

// 딜러 - 등록 완료된 AR 상세 조회
export async function requestDealerSuccessArsDetail(successArId: number): Promise<SuccessArDetailVOModel> {
  const response = await http.get<SuccessArDetailVO>({
    url: API_DE.SUCCESS_ARS.SUCCESS_AR_DETAIL(successArId),
  });

  return formattingToSuccessArDetailVOModel(response);
}

// 딜러 - 대출 신청가능한 AR 목록 조회
export async function requestDealerSuccessArsListForRequestLoan(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerSuccessArsListForLoanRequest,
): Promise<Pageable<SuccessArForRequestLoanVOModel[]>> {
  const response = await http.get<Pageable<SuccessArForRequestLoanVO[]>>({
    url: API_DE.SUCCESS_ARS.SUCCESS_AR_LIST_FOR_REQUEST_LOAN,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const successArListForRequestLoanList: SuccessArForRequestLoanVOModel[] = response.content.map(
    (data: SuccessArForRequestLoanVO) => formattingToSuccessArForRequestLoanVOModel(data),
  );
  const successArListForRequestLoanPage: Pageable<SuccessArForRequestLoanVOModel[]> = {
    ...response,
    content: successArListForRequestLoanList,
  };

  return successArListForRequestLoanPage;
}

// 대출 신청
export async function requestDealerArLoanRegistration(
  successArId: number,
  data: DealerSuccessArLoanRequest,
): Promise<LoanDetailVOModel> {
  const response = await http.post<LoanDetailVO>({
    url: API_DE.SUCCESS_ARS.REGISTER_LOAN(successArId),
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToLoanDetailVOModel(response);
}

export async function requestDealerArCalculateLoanRequest(
  successArId: number,
  disbursementDate: string,
  requestedAmount: number,
): Promise<CalculateForLoanRequestVOModel> {
  const response = await http.get<CalculateForLoanRequestVO>({
    url: API_DE.SUCCESS_ARS.CALCULATE_LOAN_REQUEST,
    data: {
      successArId,
      disbursementDate,
      requestedAmount,
    },
  });

  return formattingToCalculateForLoanRequestVOModel(response);
}
export async function requestDealerRenewArByFinancierInterface(successArId: number) {
  const response = await http.put({
    url: API_DE.SUCCESS_ARS.RENEW_AR_BY_FINANCIER_INTERFACE(successArId),
  });

  return response;
}

export async function requestDealerTempLoanByAr(successArId: number): Promise<TempLoanVOModel> {
  const response = await http.get<TempLoanVO>({
    url: API_DE.SUCCESS_ARS.TEMP_LOAN(successArId),
  });

  return formattingToTempLoanVOModel(response);
}

export async function requestDealerTempLoanByArRegistration(
  successArId: number,
  data: CreateDeTempLoanByArRequest,
): Promise<TempLoanVOModel> {
  const response = await http.post<TempLoanVO>({
    url: API_DE.SUCCESS_ARS.TEMP_LOAN(successArId),
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToTempLoanVOModel(response);
}

export async function requestDealerPartialCollaterals(
  pageNumber: number,
  rowCount: number,
  successArId: number,
): Promise<Pageable<PartialCollateralVOModel[]>> {
  const response = await http.get<Pageable<PartialCollateralVO[]>>({
    url: API_DE.SUCCESS_ARS.PARTIAL_COLLATERALS(successArId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToPartialCollateralVOModel(item)),
  };
}

// 전자서명을 위한 대출서류 생성 API
export async function requestDealerFactoringAttachments(
  data: DeSuccessArsFactoringAttachmentsRequest,
): Promise<FactoringAttachmentVOModel> {
  const response = await http.post<FactoringAttachmentVO>({
    url: API_DE.SUCCESS_ARS.FACTORING_ATTACHMENTS,
    data,
    hasLoadingUI: false,
  });

  return formattingToFactoringAttachmentVOModel(response);
}

// 딜러 - 복수 대출 신청가능한 AR 목록 조회
export async function requestDealerSuccessArListForRequestMultipleLoan(
  dealerAgreementId: number,
): Promise<Pageable<SuccessArForRequestMultipleLoanVOModel[]>> {
  const response = await http.get<Pageable<SuccessArForRequestMultipleLoanVO[]>>({
    url: API_DE.SUCCESS_ARS.SUCCESS_AR_LIST_FOR_MULTIPLE_REQUEST_LOANS,
    data: {
      dealerAgreementId,
      pageNumber: 1,
      rowCount: 500,
    },
  });
  const successArListForRequestMultipleLoanList: SuccessArForRequestMultipleLoanVOModel[] = response.content.map(
    (data: SuccessArForRequestMultipleLoanVO) => formattingToSuccessArForRequestMultipleLoanVOModel(data),
  );
  const successArListForRequestMultipleLoanPage: Pageable<SuccessArForRequestMultipleLoanVOModel[]> = {
    ...response,
    content: successArListForRequestMultipleLoanList,
  };

  return successArListForRequestMultipleLoanPage;
}
