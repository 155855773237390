import { formattingToUserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import type { UserEmailSettingVO, UserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorUserEmailSettingListRequest, AnchorUserEmailSettingUpdateRequest } from './requests';

export async function requestAnchorEmailSettingList(
  searchValue: AnchorUserEmailSettingListRequest,
): Promise<UserEmailSettingVOModel[]> {
  const response = await http.get<UserEmailSettingVO[]>({
    url: API_AC.USER_EMAIL_SETTING.USER_EMAIL_SETTING,
    data: {
      ...searchValue,
    },
  });

  return response.map(item => formattingToUserEmailSettingVOModel(item));
}

export async function requestAnchorEmailSettingListUpdate(
  data: AnchorUserEmailSettingUpdateRequest,
): Promise<UserEmailSettingVOModel[]> {
  const response = await http.put<UserEmailSettingVO[]>({
    url: API_AC.USER_EMAIL_SETTING.USER_EMAIL_SETTING,
    data,
  });

  return response.map(item => formattingToUserEmailSettingVOModel(item));
}
