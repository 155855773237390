import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Form, { useFormContext } from 'components/stateless/Form';
import { Cell, Content, Row, SubTitle } from 'components/templates/section';

import DesignatedAccountOfARSettlementOrInvoicePayment from '../common/DesignatedAccountOfARSettlementOrInvoicePayment';

const ARSettlement = () => {
  const { t } = useTranslation(['format']);

  const {
    methods: { clearErrors },
  } = useFormContext();

  const handlePaymentSupportChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const isRequiredSettlementAccount = value === 'true';
    if (!isRequiredSettlementAccount) clearErrors('settlementAccount');
  };

  return (
    <>
      <SubTitle>{t('text:AR_SETTLEMENT')}</SubTitle>
      <Content>
        <Row>
          <Form.Control required name="paymentSupport">
            <Cell>
              <Form.Label position="top">{t('text:AR_Settlement_at_Maturity')}</Form.Label>
              <Form.Description>
                {t(
                  'text:If_automatic_settlement_is_used_the_financier_will_transfer_the_settlement_to_the_partners_individually',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio onChange={handlePaymentSupportChange}>
              <Form.Option value="true" label={t('text:Automatic_Settlement_by_Financier')} />
              <Form.Option value="false" label={t('text:Manual_Settlement_by_Anchor')} />
            </Form.Radio>
          </Form.Control>
        </Row>

        <Row singleColumnPosition="all">
          <Cell>
            <Form.Label>{t('text:Designated_Withdrawal_Account')}</Form.Label>
            <Form.Description>
              {t('text:The_AR_settlement_amount_is_withdrawn_from_the_Anchor_s_designated_account')}
            </Form.Description>
          </Cell>
          <DesignatedAccountOfARSettlementOrInvoicePayment />
        </Row>
      </Content>
    </>
  );
};

export default ARSettlement;
