import { useTranslation } from 'react-i18next';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { COMMON_APPROVAL_TYPE, USER_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

interface FinancierManageAnchorUserDetailHistoryProps {
  userEditHistory: WaitingAnchorUserVOModel[] | undefined;
  paginate(pageNumber: number, rowCount: number): Promise<void>;
  pageable: PageableType;
}

const FinancierManageAnchorUserDetailHistory = ({
  userEditHistory,
  paginate,
  pageable,
}: FinancierManageAnchorUserDetailHistoryProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  const onClickRegistrationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Time'),
      colWidths: 120,
    },
    {
      headerText: t('text:User_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Mobile'),
      colWidths: 120,
    },
    {
      headerText: t('text:Email'),
      colWidths: 120,
    },
    {
      headerText: t('text:Authority'),
      colWidths: 120,
    },
    {
      headerText: t('text:User_Account_Status'),
      colWidths: 120,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickRegistrationStatus,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 150,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Information_Edit_History')} />
      <TableBorder>
        <TableHeader header={HISTORY_TABLE_HEADERS} />
        <TableBody numOfCol={HISTORY_TABLE_HEADERS.length}>
          {userEditHistory?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td data={item.updatedDateTime} format="datetime" />
                <Td data={item.name} />
                <Td data={item.mobile} />
                <Td data={item.email} />
                <Td data={t(`code:authority-type.${item.authorityType}`)} />
                <Td
                  className={getStatusTextClass('USER_STATUS', item?.userStatus)}
                  data={t(`code:user-status.${item.userStatus}`)}
                />
                <Td>
                  {tableValueManage(item.operatorUserLoginId)} / {tableValueManage(item.operatorUserName)}
                </Td>
                <Td>
                  {item.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL ||
                  item.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST
                    ? '-'
                    : tableValueManage(item.authorizerUserLoginId)}{' '}
                  / {tableValueManage(item.authorizerUserName)}
                </Td>
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
};

export default FinancierManageAnchorUserDetailHistory;
