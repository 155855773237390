import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { InventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierInventoryList } from 'utils/http/api/financier/inventory-summaries';
import type { FinancierInventorySummariesListRequest } from 'utils/http/api/financier/inventory-summaries/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const FI_SECURED_INVENTORY_LIST_QS_KEY = 'fi-secured-inventory-list';

function FinancierSecuredInventoryList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(FI_SECURED_INVENTORY_LIST_QS_KEY);
  const getProperty = useProperty<FinancierInventorySummariesListRequest>();
  const modal = useModal();

  const [inventoryList, setInventoryList] = useState<Pageable<InventorySummaryVOModel[]>>();

  const { register, getValues, reset, setValue, control } = useForm<FinancierInventorySummariesListRequest>();

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Registration_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Number_of_Items'),
      colWidths: 250,
    },
    {
      headerText: t('text:Total_Quantity'),
      colWidths: 80,
    },
    {
      headerText: t('text:Total_Amount'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  // onMounted
  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierInventorySummariesListRequest>(
        setValue,
        getParsedSearchParams(FI_SECURED_INVENTORY_LIST_QS_KEY).formSearchData,
      );
      fetchFiInventoryList(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  const fetchFiInventoryList = async (
    pageNumber: number,
    rowCount: number,
    searchData: FinancierInventorySummariesListRequest,
  ) => {
    try {
      const fetchInventoryList = await requestFinancierInventoryList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        FI_SECURED_INVENTORY_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setInventoryList(fetchInventoryList);
        setPageable(fetchInventoryList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onSearchSubmit = async () => {
    await fetchFiInventoryList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    await fetchFiInventoryList(
      pageNumber,
      sizePerPage,
      getParsedSearchParams(FI_SECURED_INVENTORY_LIST_QS_KEY).formSearchData,
    );
  };

  const onRemoveFilter = (event: any) => {
    reset({});
    event.preventDefault();
  };

  const renderResultTable = () => {
    return inventoryList?.content.map((item, i) => (
      <Tr key={i}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.dealerClientName} />
        <Td data={item.inventoryDescription} format="number" />
        <Td data={item.totalCount} format="number" />
        <Td data={item.totalAmount} format="number" />
        <TdLink path={ROUTES_FI.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_DETAIL_BUILD_PATH(item.inventorySummaryId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Secured_Inventory_Update')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name={getProperty('createdDateFrom')} control={control} />
              <SearchDatePicker placeholder={t('text:to')} name={getProperty('createdDateTo')} control={control} />
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name={getProperty('dealerClientName')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Number_of_Items')} />
              <SearchInput
                name={getProperty('minimumInventoryDescription')}
                ref={register}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name={getProperty('maximumInventoryDescription')}
                ref={register}
                col={2}
                placeholder={t('text:to')}
              />
              <SearchLabel label={t('text:Total_Quantity')} />
              <SearchInput
                name={getProperty('minimumTotalCount')}
                ref={register}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput name={getProperty('maximumTotalCount')} ref={register} col={2} placeholder={t('text:to')} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Total_Amount')} />
              <SearchInput
                name={getProperty('minimumTotalAmount')}
                ref={register}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput name={getProperty('maximumTotalAmount')} ref={register} col={2} placeholder={t('text:to')} />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {inventoryList?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierSecuredInventoryList;
