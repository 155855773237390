import type Pageable from 'models/Pageable';
import { formattingToArCommissionSettingDetailVOModel } from 'models/vo/ArCommissionSettingDetailVO';
import type {
  ArCommissionSettingDetailVO,
  ArCommissionSettingDetailVOModel,
} from 'models/vo/ArCommissionSettingDetailVO';
import { formattingToArCommissionSettingVOModel } from 'models/vo/ArCommissionSettingVO';
import type { ArCommissionSettingVO, ArCommissionSettingVOModel } from 'models/vo/ArCommissionSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { UpdateFiArCommissionSpreadDTO } from './requests';
import type { CreateFiArCommissionSettingDTO, SearchFiArCommissionSettingDTO } from './requests';

// 매출채권 수수료 설정 목록 조회
export const requestFiArCommissionSettings = async (
  pageNumber: number,
  rowCount: number,
  data: SearchFiArCommissionSettingDTO,
): Promise<Pageable<ArCommissionSettingVOModel[]>> => {
  const response = await http.get<Pageable<ArCommissionSettingVO[]>>({
    url: API_FI.AR_COMMISSION_SETTINGS.AR_COMMISSION_SETTINGS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToArCommissionSettingVOModel(data)),
  };
};

// 매출채권 수수료 설정 상세 조회
export const requestFiArCommissionSettingsDetail = async (
  arCommissionSettingId: number,
): Promise<ArCommissionSettingDetailVOModel> => {
  const response = await http.get<ArCommissionSettingDetailVO>({
    url: API_FI.AR_COMMISSION_SETTINGS.AR_COMMISSION_SETTINGS_DETAIL(arCommissionSettingId),
  });

  return formattingToArCommissionSettingDetailVOModel(response);
};

// 매출채권 수수료 설정 생성
export const requestFiArCommissionSettingsRegister = async (
  data: CreateFiArCommissionSettingDTO,
): Promise<ArCommissionSettingDetailVOModel> => {
  const response = await http.post<ArCommissionSettingDetailVO>({
    url: API_FI.AR_COMMISSION_SETTINGS.AR_COMMISSION_SETTINGS,
    data,
  });

  return formattingToArCommissionSettingDetailVOModel(response);
};

// 매출채권 수수료 설정 활성화/비활성화
export const requestFiArCommissionSettingsUpdateStatus = async (arCommissionSettingId: number, activated: boolean) => {
  return await http.put({
    url: API_FI.AR_COMMISSION_SETTINGS.AR_COMMISSION_SETTINGS_DETAIL(arCommissionSettingId),
    data: {
      activated,
    },
  });
};

// 매출채권 수수료 수정
export const requestFiArCommissionSettingsUpdateCommissionSpread = async (
  arCommissionSettingId: number,
  data: UpdateFiArCommissionSpreadDTO,
): Promise<void> => {
  return await http.put({
    url: API_FI.AR_COMMISSION_SETTINGS.AR_COMMISSION_SETTINGS_SPREAD(arCommissionSettingId),
    data,
  });
};
