import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

// 용처 :: 용처증빙 파일 다운로드
export async function requestDealerPurposeAttachmentDownload(purposeOfLoanId: number) {
  await http.download(API_DE.PURPOSE_OF_LOANS.DOWNLOAD_PURPOSE_ATTACHMENT(purposeOfLoanId));
}

// 용처 :: 지급계좌 파일 다운로드
export async function requestDealerAccountAttachmentDownload(purposeOfLoanId: number) {
  await http.download(API_DE.PURPOSE_OF_LOANS.DOWNLOAD_ACCOUNT_ATTACHMENT(purposeOfLoanId));
}
