import type Pageable from 'models/Pageable';
import type { ArSettlementDetailVO, ArSettlementDetailVOModel } from 'models/vo/ArSettlementDetailVO';
import { formattingToArSettlementDetailVOModel } from 'models/vo/ArSettlementDetailVO';
import type { ArSettlementVO, ArSettlementVOModel } from 'models/vo/ArSettlementVO';
import { formattingToArSettlementVOModel } from 'models/vo/ArSettlementVO';
import type { SuccessArVO, SuccessArVOModel } from 'models/vo/SuccessArVO';
import { formattingToSuccessArVOModel } from 'models/vo/SuccessArVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';
import type {
  FinancierArSettlementListRequest,
  FinancierUpdateArSettledRequest,
} from 'utils/http/api/financier/ar-settlements/requests';

// AR 결제 그룹 목록 조회
export async function requestFinancierArSettlementList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierArSettlementListRequest,
): Promise<Pageable<ArSettlementVOModel[]>> {
  const response = await http.get<Pageable<ArSettlementVO[]>>({
    url: API_FI.AR_SETTLEMENTS.AR_SETTLEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const arSettlementList: ArSettlementVOModel[] = response.content.map((data: ArSettlementVO) =>
    formattingToArSettlementVOModel(data),
  );
  const arSettlementPage: Pageable<ArSettlementVOModel[]> = { ...response, content: arSettlementList };

  return arSettlementPage;
}

// AR 그룹 상세 조회
export async function requestFinancierSettlementDetail(
  anchorAgreementId: number,
  settlementDate: string,
): Promise<ArSettlementDetailVOModel> {
  const response = await http.get<ArSettlementDetailVO>({
    url: API_FI.AR_SETTLEMENTS.AR_SETTLEMENT_DETAIL(anchorAgreementId, settlementDate),
  });

  return formattingToArSettlementDetailVOModel(response);
}

export async function requestFinancierUpdateSettled(
  anchorAgreementId: number,
  payload: FinancierUpdateArSettledRequest,
): Promise<SuccessArVOModel[]> {
  const response = await http.post<SuccessArVO[]>({
    url: API_FI.AR_SETTLEMENTS.UPDATE_SETTLED(anchorAgreementId),
    data: { ...payload },
  });

  return response.map(data => formattingToSuccessArVOModel(data));
}
