import type { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface SuccessArGroupAnchorPartnerAccountVO {
  totalArCount: number;
  totalArAmount: number;
  ltvTotalArAmount: number;
  maxLtvRatio: number;
  currencyType: string;
  financierId: number;
  financierName: string;
  anchorClientName: string;
  anchorPartnerAccountId: number;
  anchorPartnerId: number;
  anchorPartnerName: string;
  potentialPartnerFinancingApplicationStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
  potentialPartnerFinancingApplicationId: number;
}

export interface SuccessArGroupAnchorPartnerAccountVOModel extends SuccessArGroupAnchorPartnerAccountVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToSuccessArGroupAnchorPartnerAccountVOModel(
  data: SuccessArGroupAnchorPartnerAccountVO,
): SuccessArGroupAnchorPartnerAccountVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
