import type { Dispatch, SetStateAction } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormSelect } from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import type { NewObject } from 'types';
import { FormErrorCodeType, formErrorHandler } from 'utils/error/manager';
import { requestFiDivisionsRegister } from 'utils/http/api/financier/divisions';
import type { CreateFiDivisionDTO, DivisionDTO } from 'utils/http/api/financier/divisions/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParserForArrayObj } from 'utils/valueManager/ValueManager';

type RegisterDivisionPropsType = {
  showCancelModal: () => void;
  financierClientId: number;
  setIsSuccessRegisterDivision: Dispatch<SetStateAction<boolean>>;
};

const initialDivision = {
  code: '',
  name: '',
  limitAllowable: false,
  limitCurrencyType: '' as CURRENCY_TYPE,
  limitAmount: 0,
};

const RegisterDivision = ({
  showCancelModal,
  financierClientId,
  setIsSuccessRegisterDivision,
}: RegisterDivisionPropsType) => {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const history = useHistory();

  const { control, register, errors, getValues, setValue, setError, clearErrors, watch, reset } =
    useForm<CreateFiDivisionDTO>({
      mode: 'onSubmit',
      shouldFocusError: true,
      defaultValues: {
        divisions: [initialDivision],
      },
    });

  const { fields, append, remove } = useFieldArray<DivisionDTO>({ control: control, name: 'divisions' });

  const limitAllowableFields = fields.map((_, index) => `divisions[${index}].limitAllowable`);
  const watchLimitAllowableFields: NewObject = watch(limitAllowableFields);

  const handleClickAppend = () => {
    append(initialDivision);
  };

  const handleClickDelete = (index: number) => {
    if (fields.length === 1) {
      reset({
        divisions: [initialDivision],
      });

      return;
    }

    remove(index);
  };

  const handleDivisionsRegister = async () => {
    const { divisions } = getValues();
    requestDTOParserForArrayObj(divisions);
    const requestData: CreateFiDivisionDTO = { anchorClientId: financierClientId, divisions };

    try {
      await requestFiDivisionsRegister(requestData);
      setIsSuccessRegisterDivision(true);

      modal.show(<h6>{t('text:The_company_division_information_has_been_registered_successfully')}</h6>, {
        closeBtnCb: () => history.push(ROUTES_FI.MANAGE_ANCHOR.COMPANY_LIST),
      });
    } catch (error) {
      modal.show(error);
      formErrorHandler<CreateFiDivisionDTO>(error, setError, clearErrors, true, () => ({
        [FormErrorCodeType.Duplication]: {
          text: 'This_Division_Code_is_already_being_used',
          priority: 10,
        },
      }));
    }
  };

  const handleRegisterDivisionButtonClick = () => {
    modal.show(<h6>{t('text:Would_you_like_to_register_division_information?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t(`text:Cancel`),
      confirmBtnText: t(`text:Confirm`),
      closeBtnCb: () => {},
      confirmBtnCb: handleDivisionsRegister,
    });
  };

  return (
    <>
      <GuideMessage
        message={[
          t('text:Register_the_Anchor_company’s_business_divisions_by_clicking_on_the_“Add_a_division”_button'),
          t(
            'text:If_the_Anchor_company_does_not_have_separate_business_divisions,_then_the_information_below_is_not_required',
          ),
        ]}
        isImportContentArea
        useContentAreaClassName={false}
      />
      <form className="mt-3">
        {fields.map((division, index) => (
          <div key={division.id} className="mb-4">
            <div className="flex-end mb-2">
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={() => handleClickDelete(index)}
                size={ButtonSizeEnum.SM}
              >
                {t('text:Delete')}
              </Button>
            </div>
            <FormBorder editable={true}>
              <FormContents>
                <div className="row">
                  <FormInput
                    label={t('text:Division_Code')}
                    name={`divisions[${index}].code`}
                    ref={register}
                    requiredOptions={{ required: true }}
                    error={errors?.divisions?.[index]?.code}
                  />
                  <FormInput
                    label={t('text:Division_Name')}
                    name={`divisions[${index}].name`}
                    ref={register}
                    error={errors?.divisions?.[index]?.name}
                  />
                </div>
                <div className="row">
                  <FormSelect
                    col={3}
                    label={t('text:Financing_Limit')}
                    name={`divisions[${index}].limitAllowable`}
                    ref={register}
                    placeholderOptions={{ show: true }}
                    selectOptions={[
                      {
                        label: t('text:Applied'),
                        value: true,
                      },
                      {
                        label: t('text:Not_Applied'),
                        value: false,
                      },
                    ]}
                    onChange={e => {
                      if (e.target.value === 'false') {
                        setValue(`divisions[${index}].limitCurrencyType`, '');
                        setValue(`divisions[${index}].limitAmount`, '');
                      }
                    }}
                    defaultValue={String(division.limitAllowable)}
                  />
                  <FormSelect
                    col={3}
                    label={t('text:Financing Limit_Currency')}
                    name={`divisions[${index}].limitCurrencyType`}
                    ref={register}
                    placeholderOptions={{ show: true }}
                    selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
                    disabled={String(watchLimitAllowableFields[`divisions[${index}].limitAllowable`]) === 'false'}
                    error={errors?.divisions?.[index]?.limitCurrencyType}
                  />
                  <FormInput
                    label={t('text:Financing Limit_Amount')}
                    name={`divisions[${index}].limitAmount`}
                    ref={register}
                    disabled={String(watchLimitAllowableFields[`divisions[${index}].limitAllowable`]) === 'false'}
                    error={errors?.divisions?.[index]?.limitAmount}
                  />
                </div>
              </FormContents>
            </FormBorder>
          </div>
        ))}
        <Button
          size={ButtonSizeEnum.XL}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          onClick={handleClickAppend}
          fullWidth
        >
          <FontAwesomeIcon icon={faPlus} size="lg" className="me-3" />
          {t('text:Add_a_division')}
        </Button>
        <div className="flex-end mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={showCancelModal}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} className="ms-2" onClick={handleRegisterDivisionButtonClick}>
            {t('text:Register_Division')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default RegisterDivision;
