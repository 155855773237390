import type { ReactNode } from 'react';
import type { Place } from 'react-tooltip';
import ReactTooltip from 'react-tooltip';

import QuestionButton from '../QuestionButton';

type QuestionTooltipProps = {
  id: string;
  place: Place;
  contentText: string | ReactNode;
};

const QuestionTooltip = ({ id, place, contentText }: QuestionTooltipProps) => (
  <>
    <QuestionButton data-tip data-for={`question-tooltip-${id}`} />
    <ReactTooltip id={`question-tooltip-${id}`} effect="solid" place={place}>
      {contentText}
    </ReactTooltip>
  </>
);

export default QuestionTooltip;
