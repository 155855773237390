import type {
  FinancierClientAuthSettingVO,
  FinancierClientAuthSettingVOModel,
} from 'models/vo/FinancierClientAuthSettingVO';
import { formattingToFinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

// 앵커 인증 - 인증 설정 정보 조회
export async function requestAnchorClientAuthByDealerAgreementId(
  dealerAgreementId: number,
): Promise<FinancierClientAuthSettingVOModel> {
  const response = await http.get<FinancierClientAuthSettingVO>({
    url: API_AC.CLIENT_AUTH_SETTING.CLIENT_AUTH_BY_DEALER_AGREEMENT_ID,
    data: {
      'dealer-agreement-id': dealerAgreementId,
    },
  });

  return formattingToFinancierClientAuthSettingVOModel(response);
}

export async function requestAnchorClientAuthByFinancierId(
  financierId: number,
): Promise<FinancierClientAuthSettingVOModel> {
  const response = await http.get<FinancierClientAuthSettingVO>({
    url: API_AC.CLIENT_AUTH_SETTING.CLIENT_AUTH_BY_FINANCIER_ID,
    data: {
      'financier-id': financierId,
    },
  });

  return formattingToFinancierClientAuthSettingVOModel(response);
}
