import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { BranchVOModel } from 'models/vo/BranchVO';
import { requestBranchList } from 'utils/http/api/financier/branches';
import useModal from 'utils/modal/useModal';

interface AssignBranchModalPropsType {
  currentModalId: number;
  selectButtonCb(branch: BranchVOModel): void;
}

function AssignBranchModal({ currentModalId, selectButtonCb }: AssignBranchModalPropsType) {
  const mounted = useMounted();
  const { t } = useTranslation();
  const [branchPageData, setBranchPageData] = useState<Pageable<BranchVOModel[]>>();
  const { pageable, setPageable } = usePageable();
  const modal = useModal();

  const BRANCH_ASSIGNMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Branch_Code'),
    },
    {
      headerText: t('text:Branch_Name'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Mobile'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: '',
      colWidths: 100,
    },
  ];

  useEffect(() => {
    if (mounted) fetchFiBranchList();
  }, [mounted]);

  const fetchFiBranchList = async (pageNumber: number = 1, rowCount: number = 10): Promise<void> => {
    try {
      const branchPageData = await requestBranchList(pageNumber, rowCount, {});

      ReactDOM.unstable_batchedUpdates(() => {
        setBranchPageData(branchPageData);
        setPageable(branchPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSelect = (e: any, item: BranchVOModel) => {
    e.preventDefault();

    selectButtonCb(item);
    modal.close(currentModalId);
  };

  const renderBranchListTable = (): JSX.Element => {
    const paginate = (selectedPageNumber: number, selectedRowCount: number): void => {
      fetchFiBranchList(selectedPageNumber, selectedRowCount);
    };

    const renderResultTable = (): JSX.Element[] | undefined => {
      return branchPageData?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td data={item.telephone} />
          <Td data={item.managerName} />
          <Td data={item.managerMobile} />
          <Td data={item.managerEmail} />
          <Td className="information-table-more">
            <Button size={ButtonSizeEnum.XS} onClick={e => onClickSelect(e, item)}>
              {t('text:Select')}
            </Button>
          </Td>
        </Tr>
      ));
    };

    return (
      <>
        <TableBorder>
          <TableHeader header={BRANCH_ASSIGNMENT_TABLE_HEADERS} />
          <TableBody numOfCol={BRANCH_ASSIGNMENT_TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </>
    );
  };

  return (
    <div className="content-area">
      <GuideMessage
        message={[
          t(
            'text:Please_click_the_Select_button_to_choose_the_branch_that_will_be_responsible_for_management_and_operation_of_the_selected_dealer',
          ),
          t('text:The_responsible_Financier_branch_can_process_the_dealer_and_the_loan_agreement_after_the_assignment'),
        ]}
        isImportContentArea
      />
      {renderBranchListTable()}
    </div>
  );
}

export default AssignBranchModal;
