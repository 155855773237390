import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import type { PotentialPartnerVOModel } from 'models/vo/PotentialPartnerVO';

import PPSubTitle from '../../../../../components/potential-partner/PPSubTitle/PPSubTitle';

interface PotentialPartnerSubmissionDetailMyCompanyInfoProps {
  data: PotentialPartnerVOModel;
}

function PotentialPartnerSubmissionDetailMyCompanyInfo({ data }: PotentialPartnerSubmissionDetailMyCompanyInfoProps) {
  const { t } = useTranslation(['format']);

  return (
    <div className="pp-mb-56">
      <PPSubTitle title={t('text:Company_Information')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Company_Name')} value={data.potentialPartnerName} />
            <FormValue label={t('text:Tax_Code')} value={data.potentialPartnerTaxCode} />
          </div>
          <div className="row">
            <FormValue label={t('text:Company_Registration_Number')} value={data.potentialPartnerBusinessCode} />
            <FormValue label={t('text:Telephone')} col={3} value={data.potentialPartnerTelephone} />
            <FormValue label={t('text:Fax')} col={3} value={data.potentialPartnerFax} />
          </div>
          <div className="row">
            <FormValue label={t('text:Legal_Representative_Name')} value={data.potentialPartnerRepresentativeName} />
            <FormValue
              label={t('text:Legal_Representative_Title')}
              col={3}
              value={data.potentialPartnerRepresentativeTitle}
            />
            <FormValue
              label={t('text:Legal_Representative_Email')}
              col={3}
              value={data.potentialPartnerRepresentativeEmail}
            />
          </div>
          <div className="row">
            <FormValue label={t('text:Registered_Office_Address')} col={12} value={data.potentialPartnerAddress} />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default PotentialPartnerSubmissionDetailMyCompanyInfo;
