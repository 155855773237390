import { ANCHOR_AGREEMENT_STATUS, CURRENCY_TYPE, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface RelatedAnchorClientLimitInfoVO {
  loanLimitAmount: string;
  loanLimitBalance: string;
  disbursedLoanAmount: string;
  currencyType: string;
  useTotalLimit: boolean;
  totalLimitAmount: string;
  originCurrencyTotalBalance: string;
  agreementCurrencyTotalBalance: string;
  totalLimitCurrencyType: string;
  anchorAgreementStatus: string;
  loanLimitCheck: boolean;
  maximumBalanceAmount: string;
}

export interface RelatedAnchorClientLimitInfoVOModel extends RelatedAnchorClientLimitInfoVO {
  loanLimitAmount: BigNumber;
  loanLimitBalance: BigNumber;
  disbursedLoanAmount: BigNumber;
  totalLimitAmount: BigNumber;
  originCurrencyTotalBalance: BigNumber;
  agreementCurrencyTotalBalance: BigNumber;
  maximumBalanceAmount: BigNumber;
  currencyType: CURRENCY_TYPE;
  anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS;
  totalLimitCurrencyType: CURRENCY_TYPE;
}

export function formattingToRelatedAnchorClientLimitInfoVOModel(
  data: RelatedAnchorClientLimitInfoVO,
): RelatedAnchorClientLimitInfoVOModel {
  const slicedZeroDecimalData = {
    loanLimitAmount: sliceZeroDecimal(data.loanLimitAmount),
    loanLimitBalance: sliceZeroDecimal(data.loanLimitBalance),
    disbursedLoanAmount: sliceZeroDecimal(data.disbursedLoanAmount),
    totalLimitAmount: sliceZeroDecimal(data.totalLimitAmount),
    originCurrencyTotalBalance: sliceZeroDecimal(data.originCurrencyTotalBalance),
    agreementCurrencyTotalBalance: sliceZeroDecimal(data.agreementCurrencyTotalBalance),
    maximumBalanceAmount: sliceZeroDecimal(data.maximumBalanceAmount),
  };

  const {
    loanLimitAmount,
    loanLimitBalance,
    disbursedLoanAmount,
    totalLimitAmount,
    originCurrencyTotalBalance,
    agreementCurrencyTotalBalance,
    maximumBalanceAmount,
  } = slicedZeroDecimalData;

  return {
    ...data,
    loanLimitAmount,
    loanLimitBalance,
    disbursedLoanAmount,
    totalLimitAmount,
    originCurrencyTotalBalance,
    agreementCurrencyTotalBalance,
    maximumBalanceAmount,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    anchorAgreementStatus: stringToEnum(ANCHOR_AGREEMENT_STATUS, data.anchorAgreementStatus),
    totalLimitCurrencyType: stringToEnum(CURRENCY_TYPE, data.totalLimitCurrencyType),
  };
}
