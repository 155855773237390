import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemUserList } from 'utils/http/api/system/users';
import type { systemUserSearchRequest } from 'utils/http/api/system/users/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const SEARCHED_SYSTEM_MONITOR_PLATFORM_USER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Enterprise_ID'),
    },
    {
      headerText: t('text:Enterprise_Name'),
    },
    {
      headerText: t('text:User_ID'),
    },
    {
      headerText: t('text:Login_ID'),
    },
    {
      headerText: t('text:User_Name'),
    },
    {
      headerText: t('text:User_Code'),
    },
    {
      headerText: t('text:User_Email'),
    },
    {
      headerText: t('text:Authority'),
    },
    {
      headerText: t('text:User_Account_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_USER_LIST_QS_KEY = 'sy-user-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_SYSTEM_MONITOR_PLATFORM_USER_TABLE_HEADERS,
    SY_USER_LIST_QS_KEY,
  };
};

function SystemMonitorPlatformUserList(): JSX.Element {
  const { mounted, modal, t, SEARCHED_SYSTEM_MONITOR_PLATFORM_USER_TABLE_HEADERS, SY_USER_LIST_QS_KEY } =
    getConstants();

  const [systemMonitorPlatformUserPage, setSystemMonitorPlatformUserPage] = useState<Pageable<UserVOModel[]>>();

  const {
    register: systemMonitorPlatformUserSearchFormRegister,
    getValues: getSystemMonitorPlatformUserSearchFormValues,
    reset: resetSystemMonitorPlatformUserSearchForm,
    setValue: setSystemMonitorPlatformUserSearchFormValue,
  } = useForm<systemUserSearchRequest>();

  const {
    pageable: searchedSystemMonitorPlatformUserPageable,
    setPageable: setSearchedSystemMonitorPlatformUserPageable,
  } = usePageable(SY_USER_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<systemUserSearchRequest>(
        setSystemMonitorPlatformUserSearchFormValue,
        getParsedSearchParams(SY_USER_LIST_QS_KEY).formSearchData,
      );

      fetchSyUserList(
        searchedSystemMonitorPlatformUserPageable.currentPage,
        searchedSystemMonitorPlatformUserPageable.sizePerPage,
        getSystemMonitorPlatformUserSearchFormValues(),
      );
    }
  }, [mounted]);

  const fetchSyUserList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: systemUserSearchRequest,
  ): Promise<void> => {
    try {
      const systemMonitorPlatformUserResponse = await requestSystemUserList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_USER_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemMonitorPlatformUserPage(systemMonitorPlatformUserResponse);
        setSearchedSystemMonitorPlatformUserPageable(systemMonitorPlatformUserResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    resetSystemMonitorPlatformUserSearchForm();
  };

  const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    fetchSyUserList(
      1,
      searchedSystemMonitorPlatformUserPageable.sizePerPage,
      getSystemMonitorPlatformUserSearchFormValues(),
    );
  };

  const searchedSystemMonitorPlatformUserPaginate = (pageNumber: number, rowCount: number): void => {
    fetchSyUserList(pageNumber, rowCount, getParsedSearchParams(SY_USER_LIST_QS_KEY).formSearchData);
  };

  const renderSearchedSystemMonitorPlatformUserTableBody = (): JSX.Element[] | undefined => {
    const getAuthorityTypeText = (authorityType: AUTHORITY_TYPE): string => {
      switch (authorityType) {
        case AUTHORITY_TYPE.ADMIN:
          return t('text:Admin');
        case AUTHORITY_TYPE.AUTHORIZER:
          return t('text:Authorizer');
        case AUTHORITY_TYPE.OPERATOR:
          return t('text:Operator');
        case AUTHORITY_TYPE.HQ_OPERATOR:
          return t('text:Admin_Operator');
        default:
          return '';
      }
    };

    return systemMonitorPlatformUserPage?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.enterpriseId} />
        <Td data={data.enterpriseName} />
        <Td data={data.userId} />
        <Td data={data.loginId} />
        <Td data={data.userName} />
        <Td data={data.employeeCode} />
        <Td data={data.email} />
        <Td data={getAuthorityTypeText(data.authorityType)} />
        <Td
          className={getStatusTextClass('USER_STATUS', data.userStatus)}
          data={t(`code:user-status.${data.userStatus}`)}
        />
        <TdLink path={ROUTES_SY.MONITOR_PLATFORM.USER_DETAIL_BUILD_PATH(data.userId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:User_List')} />
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Enterprise_ID')} />
              <SearchInput name="enterpriseId" ref={systemMonitorPlatformUserSearchFormRegister} />
              <SearchLabel label={t('text:Enterprise_Name')} />
              <SearchInput name="enterpriseName" ref={systemMonitorPlatformUserSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:User_ID')} />
              <SearchInput name="userId" ref={systemMonitorPlatformUserSearchFormRegister} />
              <SearchLabel label={t('text:Login_ID')} />
              <SearchInput name="loginId" ref={systemMonitorPlatformUserSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:User_Name')} />
              <SearchInput name="userName" ref={systemMonitorPlatformUserSearchFormRegister} />
              <SearchLabel label={t('text:User_Code')} />
              <SearchInput name="employeeCode" ref={systemMonitorPlatformUserSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:User_Email')} />
              <SearchInput name="email" ref={systemMonitorPlatformUserSearchFormRegister} />
              <SearchLabel label={t('text:Authority')} />
              <SearchSelect
                name="authorityType"
                ref={systemMonitorPlatformUserSearchFormRegister}
                selectOptions={getSelectOptions('AUTHORITY_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:User_Account_Status')} />
              <SearchSelect
                name="userStatus"
                ref={systemMonitorPlatformUserSearchFormRegister}
                selectOptions={getSelectOptions('USER_STATUS', 'ALL', true)}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {searchedSystemMonitorPlatformUserPageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_SYSTEM_MONITOR_PLATFORM_USER_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_SYSTEM_MONITOR_PLATFORM_USER_TABLE_HEADERS.length}>
            {renderSearchedSystemMonitorPlatformUserTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination
          pageable={searchedSystemMonitorPlatformUserPageable}
          paginate={searchedSystemMonitorPlatformUserPaginate}
        />
      </div>
    </>
  );
}

export default SystemMonitorPlatformUserList;
