import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Accordion from 'components/stateless/Accordion/Accordion';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { requestSystemWaitingAnchorUserData } from 'utils/http/api/system/waiting-anchor-users';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  return {
    mounted,
    modal,
    t,
  };
};

function SystemMonitorAnchorUserWaitingDetail(): JSX.Element {
  const { mounted, modal, t } = getConstants();
  const { waitingAnchorUserId } = useParams() as any;

  const [waitingAnchorUserData, setWaitingAnchorUserData] = useState<WaitingAnchorUserVOModel>();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const waitingAnchorUserData = await requestSystemWaitingAnchorUserData(waitingAnchorUserId);

      setWaitingAnchorUserData(waitingAnchorUserData);
    } catch (error) {
      modal.show(error);
    }
  };

  const getStatusUserInfo = (): string => {
    switch (waitingAnchorUserData?.approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return `${t('text:Operator')} (${waitingAnchorUserData?.operatorUserName} / ${
          waitingAnchorUserData?.operatorUserLoginId
        })`;
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
      case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
        return `${t('text:Authorizer')} (${waitingAnchorUserData?.authorizerUserName} / ${
          waitingAnchorUserData?.authorizerUserLoginId
        })`;
      default:
        return '';
    }
  };

  const getStatusDateText = () => {
    switch (waitingAnchorUserData?.approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return t('text:Requested_Date');
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return t('text:Reverted_Date');
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return t('text:Cancelled_Date');
      case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
        return t('text:Registered_Date');
      default:
        return '';
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_User_Details')} />
      <div className="content-area">
        <Accordion id="waiting-user-detail" className="accordion accordion-information-form" defaultExpanded={true}>
          <Accordion.Header id="waiting-user-detail-header">
            <Accordion.Trigger>
              <div className="accordion-information-form__head">
                <div className={getStatusBadgeClass('COMMON_APPROVAL_TYPE', waitingAnchorUserData?.approvalType)}>
                  {t(`code:common-approval-type.${waitingAnchorUserData?.approvalType}`)}
                </div>
                <div className="accordion-information-form__head--text">
                  <div className="fw-bold">{getStatusUserInfo()}</div>
                  {waitingAnchorUserData?.financierName}
                </div>
                <div className="accordion-information-form__head--text">
                  <div className="fw-bold">{getStatusDateText()}</div>
                  {t('format:datetime', { value: waitingAnchorUserData?.updatedDateTime, key: 'date' })}
                </div>
              </div>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <FormBorder>
              <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_INFORMATION')} />
              <FormContents>
                <div className="row">
                  <FormValue label={t('text:Client_Code')} value={waitingAnchorUserData?.anchorClientCode} />
                  <FormValue label={t('text:Anchor_Name')} value={waitingAnchorUserData?.anchorClientName} />
                </div>
              </FormContents>
              <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_USER_INFORMATION')} />
              <FormContents>
                <div className="row">
                  <FormValue label={t('text:User_Code')} value={waitingAnchorUserData?.bankUserId} />
                  <FormValue label={t('text:User_Name')} value={waitingAnchorUserData?.name} />
                </div>
                <div className="row">
                  <FormValue label={t('text:Mobile')} value={waitingAnchorUserData?.mobile} />
                  <FormValue label={t('text:Email')} value={waitingAnchorUserData?.email} />
                </div>
                <div className="row">
                  <FormValue label={t('text:OTP_Number')} value={waitingAnchorUserData?.anchorUserOtpSerialNo} />
                  <FormValue
                    label={t('text:Authority')}
                    value={t(`code:authority-type.${waitingAnchorUserData?.authorityType}`)}
                    format="code"
                  />
                </div>
              </FormContents>
            </FormBorder>
          </Accordion.Content>
        </Accordion>
      </div>
    </>
  );
}

export default SystemMonitorAnchorUserWaitingDetail;
