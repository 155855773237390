import { EARLY_REPAYMENT_REQUEST_STATUS, stringToEnum } from 'enums';

export interface EarlyRepaymentRequestVO {
  earlyRepaymentRequestId: number;
  intendedRepaymentDate: string;
  earlyRepaymentRequestStatus: string;
  createdDateTime: string;
  updatedDateTime: string;
  loanId: number;
  financierLoanId: string;
  financierName: string;
  financierCode: string;
  currencyType: string;
  dealerClientId: number;
  dealerClientCode: string;
  dealerClientName: string;
  principalAmount: number;
  repaidPrincipalAmount: number;
  disbursedDate: string;
  repaymentDate: string;
}

export interface EarlyRepaymentRequestVOModel extends EarlyRepaymentRequestVO {
  earlyRepaymentRequestStatus: EARLY_REPAYMENT_REQUEST_STATUS;
}

export function formattingToEarlyRepaymentRequestVOModel(data: EarlyRepaymentRequestVO): EarlyRepaymentRequestVOModel {
  return {
    ...data,
    earlyRepaymentRequestStatus: stringToEnum(EARLY_REPAYMENT_REQUEST_STATUS, data.earlyRepaymentRequestStatus),
  };
}
