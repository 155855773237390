import { ROUTES_AC } from 'constants/routes/anchor';
import { ROUTES_DE } from 'constants/routes/dealer';
import { ROUTES_FI } from 'constants/routes/financier';
import { ROUTES_SY } from 'constants/routes/system';
import { ROLE } from 'enums';

export const homePathByRole = (role: keyof typeof ROLE) => {
  switch (role) {
    case ROLE.ROLE_FINANCIER:
      return ROUTES_FI.DASHBOARD;
    case ROLE.ROLE_ANCHOR:
      return ROUTES_AC.DASHBOARD;
    case ROLE.ROLE_DEALER:
      return ROUTES_DE.DASHBOARD;
    case ROLE.ROLE_SYSTEM:
      return ROUTES_SY.MANAGEMENT.FINANCIER_LIST;
    default:
      return '/';
  }
};
