import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import Select from 'components/stateless/Select/Select';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import getSelectOptions from 'constants/selectOptions';
import { DEALER_AGREEMENT_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import {
  requestFiAnchorReportReceiverNotRelatedAgreementList,
  requestFiAnchorReportReceiverRelatedAgreementList,
} from 'utils/http/api/financier/anchor-report-receivers';
import type { FiAnchorReportReceiverRelatedAgreementListRequest } from 'utils/http/api/financier/anchor-report-receivers/requests';
import { requestFinancierDealerAgreementList } from 'utils/http/api/financier/dealer-agreements';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

type getCheckedDealerAgreementList = (data: DealerAgreementVOModel[]) => void;

interface FinancierAnchorReportPartnerAgreementModal {
  taskType: 'CREATE' | 'ASSIGN' | 'RELEASE';
  getCheckedDealerAgreementList: getCheckedDealerAgreementList;
}

interface FinancierAnchorReportCreatePartnerAgreementModalProps extends FinancierAnchorReportPartnerAgreementModal {
  taskType: 'CREATE';
  anchorAgreementId: number;
  checkedDealerAgreementList: DealerAgreementVOModel[];
}

interface FinancierAnchorReportUpdatePartnerAgreementModalProps extends FinancierAnchorReportPartnerAgreementModal {
  taskType: 'ASSIGN' | 'RELEASE';
  anchorReportReceiverId: number;
}

type FinancierAnchorReportUpdatePartnerAgreementModalPropsType =
  | FinancierAnchorReportCreatePartnerAgreementModalProps
  | FinancierAnchorReportUpdatePartnerAgreementModalProps;

interface PageDealerAgreementModel extends DealerAgreementVOModel {
  pageNumber?: number;
}

function FinancierAnchorReportUpdatePartnerAgreementModal(
  props: FinancierAnchorReportUpdatePartnerAgreementModalPropsType,
): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { pageable, setPageable } = usePageable();

  const [dealerAgreementPageData, setDealerAgreementPageData] = useState<Pageable<DealerAgreementVOModel[]>>();
  const [checkedDealerAgreementList, setCheckedDealerAgreementList] = useState<PageDealerAgreementModel[]>([]);
  const [allCheekdPage, setAllCheckedPage] = useState<number[]>([]);

  const onClickAllCheckbox = (e: any): void => {
    if (!dealerAgreementPageData) return;

    if (e.target.checked) {
      const mappedData = dealerAgreementPageData.content.map(item => ({ ...item, pageNumber: pageable.currentPage }));

      setCheckedDealerAgreementList(prev => {
        const mergePrevAndMappedData = [...prev, ...mappedData];
        const removedDuplicateCheckedDealerAgreementList = mergePrevAndMappedData.reduce(
          (uniqueArr: PageDealerAgreementModel[], current: PageDealerAgreementModel): PageDealerAgreementModel[] => {
            const noFindDuplicateCheckedDealerAgreementList =
              uniqueArr.findIndex(
                ({ dealerFinancierClientId }) => dealerFinancierClientId === current.dealerFinancierClientId,
              ) === -1;

            if (noFindDuplicateCheckedDealerAgreementList) {
              return [...uniqueArr, current];
            }

            return uniqueArr;
          },
          [],
        );
        props.getCheckedDealerAgreementList(removedDuplicateCheckedDealerAgreementList);

        return removedDuplicateCheckedDealerAgreementList;
      });
    } else {
      const filteredData = checkedDealerAgreementList.filter(item => item.pageNumber !== pageable.currentPage);

      props.getCheckedDealerAgreementList(filteredData);
      setCheckedDealerAgreementList(filteredData);
    }
  };

  const removedDuplicateAllCheckedPage = allCheekdPage.reduce((uniqueArr: number[], pageNum: number): number[] => {
    return uniqueArr.includes(pageNum) ? uniqueArr : [...uniqueArr, pageNum];
  }, []);

  useEffect(() => {
    const currentPageCheckedList = checkedDealerAgreementList.filter(item => item.pageNumber === pageable.currentPage);

    if (dealerAgreementPageData?.content.length === currentPageCheckedList.length) {
      setAllCheckedPage([...allCheekdPage, pageable.currentPage]);
    } else {
      setAllCheckedPage(removedDuplicateAllCheckedPage.filter(pageNum => pageNum !== pageable.currentPage));
    }
  }, [dealerAgreementPageData, checkedDealerAgreementList]);

  const partnerAgreementTableHeaders: HeaderType[] = [
    {
      headerText: '',
      className: 'text-center',
      colWidths: 50,
      hasCheckBox: true,
      handleClickAllCheckBox: onClickAllCheckbox,
      checkBoxId: String(pageable.currentPage),
      allCheckedPages: removedDuplicateAllCheckedPage,
    },
    {
      headerText: t('text:Partner_Name'),
      className: 'text-left',
      colWidths: 140,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      className: 'text-left',
      colWidths: 140,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      className: 'text-left',
      colWidths: 200,
    },
    {
      headerText: t('text:Agreement_Suspension'),
      className: 'text-left',
      colWidths: 140,
    },
  ];

  const {
    register: partnerAgreementSearchFormRegister,
    getValues: getPartnerAgreementSearchFormValues,
    handleSubmit: handlePartnerAgreementSearchFormSubmit,
    watch: watchPartnerAgreementSearchForm,
  } = useForm<{ selectedKey: keyof FiAnchorReportReceiverRelatedAgreementListRequest; inputValue: string }>();
  const { inputValue: inputValueWatcher } = watchPartnerAgreementSearchForm();

  useEffect(() => {
    if (mounted) {
      (async () => {
        await fetchAll();

        if (props.taskType === 'CREATE') setCheckedDealerAgreementList(props.checkedDealerAgreementList);
      })();
    }
  }, [mounted]);

  const fetchAll = async (selectedPageNumber: number = 1, selectedRowCount: number = 10): Promise<void> => {
    try {
      let dealerAgreementPageData: Pageable<DealerAgreementVOModel[]>;

      const requestData = {
        [getPartnerAgreementSearchFormValues().selectedKey]: getPartnerAgreementSearchFormValues().inputValue,
      };

      requestDTOParser(requestData);

      if (props.taskType === 'CREATE') {
        dealerAgreementPageData = await requestFinancierDealerAgreementList(selectedPageNumber, selectedRowCount, {
          anchorAgreementId: props.anchorAgreementId,
          ...requestData,
        });
      } else if (props.taskType === 'ASSIGN') {
        dealerAgreementPageData = await requestFiAnchorReportReceiverNotRelatedAgreementList(
          selectedPageNumber,
          selectedRowCount,
          props.anchorReportReceiverId,
          { ...requestData },
        );
      } else if (props.taskType === 'RELEASE') {
        dealerAgreementPageData = await requestFiAnchorReportReceiverRelatedAgreementList(
          selectedPageNumber,
          selectedRowCount,
          props.anchorReportReceiverId,
          { ...requestData },
        );
      } else {
        throw 'Unexpected error';
      }

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementPageData(dealerAgreementPageData);
        setPageable(dealerAgreementPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearch = (): void => {
    fetchAll(1, pageable.sizePerPage);
  };

  const getSearchFormInputValueTextLength = (): string => {
    if (inputValueWatcher && inputValueWatcher.length !== 0)
      return t('text:Current_text_length_is_') + `${inputValueWatcher.length}`;

    return '';
  };

  const renderSearchPartnerAgreementResult = (): JSX.Element[] | undefined => {
    const getAgreementSuspensionText = (data?: DEALER_AGREEMENT_STATUS): string => {
      switch (data) {
        case DEALER_AGREEMENT_STATUS.ACTIVATED:
          return t('text:Not_Suspended');
        case DEALER_AGREEMENT_STATUS.SUSPENDED:
          return t('text:Suspended');
        default:
          return '';
      }
    };

    const handleCheckChange = (e: any, data: DealerAgreementVOModel) => {
      if (e.target.checked) {
        props.getCheckedDealerAgreementList([
          ...checkedDealerAgreementList,
          { ...data, pageNumber: pageable.currentPage },
        ]);
        setCheckedDealerAgreementList([...checkedDealerAgreementList, { ...data, pageNumber: pageable.currentPage }]);
      } else {
        props.getCheckedDealerAgreementList(
          checkedDealerAgreementList.filter(item => item.dealerAgreementId !== data.dealerAgreementId),
        );
        setCheckedDealerAgreementList(
          checkedDealerAgreementList.filter(item => item.dealerAgreementId !== data.dealerAgreementId),
        );
      }
    };

    return dealerAgreementPageData?.content.map((data, index) => (
      <Tr key={index}>
        <Td className="bg-sub100">
          <div className="text-center">
            <input
              className="form-check-input m-0"
              type="checkbox"
              onChange={e => handleCheckChange(e, data)}
              checked={checkedDealerAgreementList.map(data => data.dealerAgreementId).includes(data.dealerAgreementId)}
            />
          </div>
        </Td>
        <Td data={data.dealerFinancierClientName} />
        <Td data={data.dealerFinancierClientCode} />
        <Td data={data.contractNo} />
        <Td
          className={getStatusTextClass('DEALER_AGREEMENT_STATUS', data.dealerAgreementStatus)}
          data={getAgreementSuspensionText(data.dealerAgreementStatus)}
        />
      </Tr>
    ));
  };

  return (
    <div className="modal-body">
      <div className="modal-container">
        <div className="d-flex information-filter mt-0">
          <Select
            name="selectedKey"
            className="information-form__select"
            selectOptions={getSelectOptions<string>('FINANCIER_DEALER_AGREEMENT_LIST_FOR_REPORT_RECEIVER_REQUEST')}
            ref={partnerAgreementSearchFormRegister}
          />
          <form onSubmit={handlePartnerAgreementSearchFormSubmit(onClickSearch)}>
            <input type="text" placeholder="Search..." name="inputValue" ref={partnerAgreementSearchFormRegister} />
            <Button type="submit">{t('text:Search')}</Button>
          </form>
        </div>
        <div className="text-brick-red mt-1 mb-1 ml-1">{getSearchFormInputValueTextLength()}</div>
        <TableBorder>
          <TableHeader header={partnerAgreementTableHeaders} />
          <TableBody numOfCol={partnerAgreementTableHeaders.length}>{renderSearchPartnerAgreementResult()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={fetchAll} showSelectRow={false} />
      </div>
    </div>
  );
}

export default FinancierAnchorReportUpdatePartnerAgreementModal;
