import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { StatisticsOfAnchorAgreementVOModel } from 'models/vo/StatisticsOfAnchorAgreementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemAnchorStatisticsOfAnchorAgreementsList } from 'utils/http/api/system/statistics-of-anchor-agreements';
import type { SystemStatisticsOfAnchorAgreementListRequest } from 'utils/http/api/system/statistics-of-anchor-agreements/requests';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

import SystemOverviewByAnchorListChannelTab from './sections/channel-tab';
import SystemOverviewByAnchorListVendorTab from './sections/vendor-tab';

export const SY_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY = 'vendor-tab';
export const SY_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY = 'channel-tab';

function SystemOverviewByAnchorList() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const [overviewByAnchorVendorPage, setOverviewByAnchorVendorPage] =
    useState<Pageable<StatisticsOfAnchorAgreementVOModel[]>>();
  const [overviewByAnchorChannelPage, setOverviewByAnchorChannelPage] =
    useState<Pageable<StatisticsOfAnchorAgreementVOModel[]>>();

  const { pageable: overviewByAnchorVendorPageable, setPageable: setOverviewByAnchorVendorPageable } = usePageable(
    SY_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY,
  );
  const { pageable: overviewByAnchorChannelPageable, setPageable: setOverviewByAnchorChannelPageable } = usePageable(
    SY_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY,
  );

  const vendorTabSearchForm = useForm<SystemStatisticsOfAnchorAgreementListRequest>({
    mode: 'onSubmit',
  });

  const channelTabSearchForm = useForm<SystemStatisticsOfAnchorAgreementListRequest>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemStatisticsOfAnchorAgreementListRequest>(
        vendorTabSearchForm.setValue,
        getParsedSearchParams(SY_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY).formSearchData,
      );
      setFormValues<SystemStatisticsOfAnchorAgreementListRequest>(
        channelTabSearchForm.setValue,
        getParsedSearchParams(SY_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    const vendorTabQs = makeSearchParamsPattern(
      {
        ...vendorTabSearchForm.getValues(),
        pageNumber: overviewByAnchorVendorPageable.currentPage,
        rowCount: overviewByAnchorVendorPageable.sizePerPage,
      },
      SY_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY,
    );
    const dealerTabQs = makeSearchParamsPattern(
      {
        ...channelTabSearchForm.getValues(),
        pageNumber: overviewByAnchorChannelPageable.currentPage,
        rowCount: overviewByAnchorChannelPageable.sizePerPage,
      },
      SY_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY,
    );
    addSearchParams(vendorTabQs + dealerTabQs);

    try {
      const [fetchVendorStatisticsList, fetchDealerStatisticsList] = await Promise.all([
        requestSystemAnchorStatisticsOfAnchorAgreementsList(
          overviewByAnchorVendorPageable.currentPage,
          overviewByAnchorVendorPageable.sizePerPage,
          { ...vendorTabSearchForm.getValues(), collateralType: COLLATERAL_TYPE.AR },
        ),
        requestSystemAnchorStatisticsOfAnchorAgreementsList(
          overviewByAnchorChannelPageable.currentPage,
          overviewByAnchorChannelPageable.sizePerPage,
          { ...channelTabSearchForm.getValues(), collateralType: COLLATERAL_TYPE.INVOICE },
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByAnchorVendorPage(fetchVendorStatisticsList);
        setOverviewByAnchorChannelPage(fetchDealerStatisticsList);

        setOverviewByAnchorVendorPageable(fetchVendorStatisticsList);
        setOverviewByAnchorChannelPageable(fetchDealerStatisticsList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchSyAnchorStatisticsListVendorTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: SystemStatisticsOfAnchorAgreementListRequest,
  ): Promise<void> => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.AR,
    };
    try {
      const response = await requestSystemAnchorStatisticsOfAnchorAgreementsList(pageNumber, rowCount, searchData);

      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        SY_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByAnchorVendorPage(response);
        setOverviewByAnchorVendorPageable(response);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchVendorTab = async (e: any): Promise<void> => {
    e.preventDefault();
    const data = vendorTabSearchForm.getValues();
    await fetchSyAnchorStatisticsListVendorTab(1, overviewByAnchorVendorPageable.sizePerPage, data);
  };

  const paginateVendorTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchSyAnchorStatisticsListVendorTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(SY_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY).formSearchData,
    );
  };

  const fetchSyAnchorStatisticsListChannelTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: SystemStatisticsOfAnchorAgreementListRequest,
  ): Promise<void> => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.INVOICE,
    };
    try {
      const response = await requestSystemAnchorStatisticsOfAnchorAgreementsList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        SY_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByAnchorChannelPage(response);
        setOverviewByAnchorChannelPageable(response);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchChannelTab = async (e: any): Promise<void> => {
    e.preventDefault();
    const data = channelTabSearchForm.getValues();
    await fetchSyAnchorStatisticsListChannelTab(1, overviewByAnchorChannelPageable.sizePerPage, data);
  };

  const paginateChannelTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchSyAnchorStatisticsListChannelTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(SY_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY).formSearchData,
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Overview_by_Anchor')} />
      <Tab
        tabType="financeType"
        tabViewList={[
          <FormProvider {...vendorTabSearchForm} key="vendor">
            <SystemOverviewByAnchorListVendorTab
              onClickSearch={onClickSearchVendorTab}
              overviewByAnchorPageData={overviewByAnchorVendorPage}
              pageable={overviewByAnchorVendorPageable}
              paginate={paginateVendorTab}
            />
          </FormProvider>,
          <FormProvider {...channelTabSearchForm} key="dealer">
            <SystemOverviewByAnchorListChannelTab
              onClickSearch={onClickSearchChannelTab}
              overviewByAnchorPageData={overviewByAnchorChannelPage}
              pageable={overviewByAnchorChannelPageable}
              paginate={paginateChannelTab}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default SystemOverviewByAnchorList;
