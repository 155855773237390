import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { AnchorFinancingOptionDetailVOModel } from 'models/vo/AnchorFinancingOptionDetailVO';

interface DesignatedBankAccountInfoProps {
  anchorFinancingOptionDetail: AnchorFinancingOptionDetailVOModel;
  showDisbursementAccountInfo: boolean;
}

function DesignatedBankAccountInfo({
  anchorFinancingOptionDetail,
  showDisbursementAccountInfo,
}: DesignatedBankAccountInfoProps) {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle title={t('text:Designated_Bank_Account_Information')} />
      {!showDisbursementAccountInfo && (
        <GuideMessage
          isImportContentArea
          useContentAreaClassName={false}
          message={[
            t(
              'text:Check_the_information_of_the_disbursement_account_and_the_repayment_accounts_designated_on_the_Financing_Option',
            ),
            t('text:Click_on_the_checkbox_to_confirm_the_information_of_the_designated_bank_accounts_is_correct'),
          ]}
        />
      )}
      {showDisbursementAccountInfo && (
        <FormBorder hideBorderBottom>
          <FormSubtitle title={t('text:Disbursement_Account')} />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Bank_Name')}
                value={anchorFinancingOptionDetail.disbursementAccountFinancierName}
              />
              <FormValue
                label={t('text:Branch_Name')}
                value={anchorFinancingOptionDetail.disbursementAccountBranchName}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Bank_Account_Number')}
                value={anchorFinancingOptionDetail.disbursementAccount}
              />
              <FormValue
                label={t('text:Bank_Account_Holder')}
                value={anchorFinancingOptionDetail.disbursementAccountOwner}
              />
            </div>
          </FormContents>
        </FormBorder>
      )}
    </>
  );
}

export default DesignatedBankAccountInfo;
