import type Pageable from 'models/Pageable';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementDetailVO, DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import { formattingToDealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type {
  DealerAgreementForRequestMultipleLoanVO,
  DealerAgreementForRequestMultipleLoanVOModel,
} from 'models/vo/DealerAgreementForRequestMultipleLoanVO';
import { formattingToDealerAgreementForRequestMultipleLoanVOModel } from 'models/vo/DealerAgreementForRequestMultipleLoanVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { SearchDeDealerAgreementDTO, SearchDeDealerAgreementListForTempInvoiceDTO } from './request';

// 딜러 약정 목록 조회
export async function requestDealerAgreementsList(
  pageNumber: number,
  rowCount: number,
  data: SearchDeDealerAgreementDTO,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_DE.DEALER_AGREEMENTS.AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDealerAgreementVOModel(item)),
  };
}

// 딜러 약정 상세 목록 조회
export async function requestDealerAgreementDetail(dealerAgreementId: number): Promise<DealerAgreementDetailVOModel> {
  const response = await http.get<DealerAgreementDetailVO>({
    url: API_DE.DEALER_AGREEMENTS.AGREEMENT_DETAIL(dealerAgreementId),
  });

  return formattingToDealerAgreementDetailVOModel(response);
}

export async function requestTempDealerDealerAgreementsList(
  pageNumber: number,
  rowCount: number,
  searchValue: SearchDeDealerAgreementListForTempInvoiceDTO,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_DE.DEALER_AGREEMENTS.TEMP_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDealerAgreementVOModel(item)),
  };
}

export async function requestDealerAnchorAgreementRelatedDealerAgreement(
  dealerAgreementId: number,
): Promise<AnchorAgreementVOModel> {
  const response = await http.get<AnchorAgreementVO>({
    url: API_DE.DEALER_AGREEMENTS.ANCHOR_AGREEMENT_RELATED_DEALER_AGREEMENT(dealerAgreementId),
  });

  return formattingToAnchorAgreementVOModel(response);
}

export async function requestDealerAgreementListForMultipleRequestLoans(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<DealerAgreementForRequestMultipleLoanVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementForRequestMultipleLoanVO[]>>({
    url: API_DE.DEALER_AGREEMENTS.AR_LIST_FOR_MULTIPLE_REQUEST_LOANS,
    data: {
      pageNumber,
      rowCount,
    },
  });
  const DealerAgreementForRequestMultipleLoanList: DealerAgreementForRequestMultipleLoanVOModel[] =
    response.content.map((data: DealerAgreementForRequestMultipleLoanVO) =>
      formattingToDealerAgreementForRequestMultipleLoanVOModel(data),
    );
  const DealerAgreementForRequestMultipleLoanPage: Pageable<DealerAgreementForRequestMultipleLoanVOModel[]> = {
    ...response,
    content: DealerAgreementForRequestMultipleLoanList,
  };

  return DealerAgreementForRequestMultipleLoanPage;
}
