import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { ANCHOR_AGREEMENT_STATUS } from 'enums';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorFinancingOptionVOModel } from 'models/vo/AnchorFinancingOptionVO';
import type { SearchFiAnchorFinancingOptionsDTO } from 'utils/http/api/financier/anchor-financing-opitons/requests';

interface FinancierAnchorFinancingAgreementListRegisteredTabProps {
  anchorFinancingOptionCompletedPage: Pageable<AnchorFinancingOptionVOModel[]> | undefined;
  completedAnchorFinancingOptionPageable: PageableType;
  handleClickSearchFinancingOptionCompleteTab(e: any): Promise<void>;
  paginateFinancingOptionCompleteTab(pageNumber: number, sizePerPage: number): Promise<void>;
}

function FinancierAnchorFinancingOptionListRegisteredTab({
  anchorFinancingOptionCompletedPage,
  completedAnchorFinancingOptionPageable,
  handleClickSearchFinancingOptionCompleteTab,
  paginateFinancingOptionCompleteTab,
}: FinancierAnchorFinancingAgreementListRegisteredTabProps) {
  const { t } = useTranslation();
  const { register, reset, control } = useFormContext<SearchFiAnchorFinancingOptionsDTO>();

  const onClickRemoveFilter = (e: any): void => {
    e.preventDefault();

    reset({});
  };

  const renderAnchorAgreementTable = (): JSX.Element[] | JSX.Element | undefined => {
    const getAnchorAgreementStatusClassName = (anchorAgreementStatus: string): string => {
      switch (anchorAgreementStatus) {
        case ANCHOR_AGREEMENT_STATUS.ACTIVATED:
          return 'text-bold-dark-green';
        case ANCHOR_AGREEMENT_STATUS.SUSPENDED:
          return 'text-bold-brick-red';
        default:
          return '';
      }
    };

    return anchorFinancingOptionCompletedPage?.content.map(
      (item, index: number): JSX.Element => (
        <Tr key={index}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.anchorClientName} />
          <Td data={item.anchorClientCode} />
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td data={item.anchorAgreementStartDate} format="date" />
          <Td data={item.anchorAgreementExpiryDate} format="date" />
          <Td
            data={t(`code:anchor-agreement-status.${item?.anchorAgreementStatus}`)}
            className={getAnchorAgreementStatusClassName(item.anchorAgreementStatus)}
            format="code"
          />
          <TdLink
            path={ROUTES_FI.MANAGE_ANCHOR.FINANCING_OPTION_REGISTERED_DETAIL_BUILD_PATH(item.anchorFinancingOptionId)}
            state={{ supportedCollateralType: item.collateralType }}
          />
        </Tr>
      ),
    );
  };

  const FINANCING_OPTION_LIST_HEADER: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Sub-Contract_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Anchor_Client_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Responsible_Bank_Branch_Code'),
      colWidths: 150,
    },
    {
      headerText: t('text:Responsible_Bank_Branch_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Agreement_Suspension'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>

          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Sub-Contract_Number')} />
              <SearchInput name="contractNo" ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name="anchorClientCode" ref={register} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Responsible_Bank_Branch_Code')} />
              <SearchInput name="assignedBranchCode" ref={register} />
              <SearchLabel label={t('text:Responsible_Bank_Branch_Name')} />
              <SearchInput name="assignedBranchName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Effective_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="startDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="startDateTo" control={control} />
              <SearchLabel label={t('text:Expiration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="expiryDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="expiryDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Agreement_Suspension')} />
              <SearchSelect
                name="agreementStatus"
                selectOptions={getSelectOptions<ANCHOR_AGREEMENT_STATUS>('ANCHOR_AGREEMENT_STATUS', 'ALL', true)}
                ref={register}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                ref={register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearchFinancingOptionCompleteTab}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {anchorFinancingOptionCompletedPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={FINANCING_OPTION_LIST_HEADER} />
          <TableBody numOfCol={FINANCING_OPTION_LIST_HEADER.length}>{renderAnchorAgreementTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={completedAnchorFinancingOptionPageable} paginate={paginateFinancingOptionCompleteTab} />
      </div>
    </>
  );
}

export default FinancierAnchorFinancingOptionListRegisteredTab;
