import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { EARLY_REPAYMENT_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierEarlyRepaymentList } from 'utils/http/api/financier/early-repayment-requests';
import type { FinancierEarlyRepaymentListRequest } from 'utils/http/api/financier/early-repayment-requests/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const FI_EARLY_REPAYMENT_LIST_QS_KEY = 'fi-early-repayment-list';

function FinancierEarlyRepaymentList() {
  const mounted = useMounted();

  const { t } = useTranslation(['format']);
  const modal = useModal();

  const [earlyRepaymentRequestData, setEarlyRepaymentRequestData] =
    useState<Pageable<EarlyRepaymentRequestVOModel[]>>();
  const { pageable, setPageable } = usePageable(FI_EARLY_REPAYMENT_LIST_QS_KEY);

  const {
    register: searchFormRegister,
    reset: searchFormReset,
    getValues: getSearchFormValues,
    setValue: setFormValue,
    control: searchFormControl,
  } = useForm<FinancierEarlyRepaymentListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierEarlyRepaymentListRequest>(
        setFormValue,
        getParsedSearchParams(FI_EARLY_REPAYMENT_LIST_QS_KEY).formSearchData,
      );
      fetchAll(pageable.currentPage, pageable.sizePerPage, getSearchFormValues());
    }

    return () => {};
  }, [mounted]);

  const fetchAll = async (
    selectedPageNumber: number = 1,
    selectedRowNumber: number = 10,
    searchData: FinancierEarlyRepaymentListRequest,
  ): Promise<void> => {
    try {
      const earlyRepaymentRequestResponse = await requestFinancierEarlyRepaymentList(
        selectedPageNumber,
        selectedRowNumber,
        searchData,
      );

      updateSearchParams(
        {
          ...searchData,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowNumber,
        },
        FI_EARLY_REPAYMENT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setEarlyRepaymentRequestData(earlyRepaymentRequestResponse);
        setPageable(earlyRepaymentRequestResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();

    fetchAll(0, pageable.sizePerPage, getSearchFormValues());
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();

    searchFormReset({
      loanId: undefined,
      financierLoanId: '',
      invoiceNumber: '',
      currencyType: undefined,
      dealerFinancierClientCode: '',
      dealerFinancierClientName: '',
      principalAmountFrom: undefined,
      principalAmountTo: undefined,
      scheduledPrincipalAmountFrom: undefined,
      scheduledPrincipalAmountTo: undefined,
      disbursedDateFrom: '',
      disbursedDateTo: '',
      repaymentDateFrom: '',
      repaymentDateTo: '',
      intendedRepaymentDateFrom: '',
      intendedRepaymentDateTo: '',
      createdDateFrom: '',
      createdDateTo: '',
      earlyRepaymentRequestStatus: undefined,
    });
  };

  const paginate = (selectedPageNumber: number, selectedRowNumber: number) => {
    fetchAll(
      selectedPageNumber,
      selectedRowNumber,
      getParsedSearchParams(FI_EARLY_REPAYMENT_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickEarlyRepaymentRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EARLY_REPAYMENT_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EARLY_REPAYMENT_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderResultTable = (): JSX.Element[] | JSX.Element | undefined => {
    return earlyRepaymentRequestData?.content.map((item, index: number) => (
      <Tr key={index}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.financierLoanId} />
        <Td data={item.loanId} />
        <Td data={item.dealerClientName} />
        <Td data={item.dealerClientCode} />
        <Td data={item.principalAmount} format="number" />
        <Td data={item.disbursedDate} format="date" />
        <Td data={item.repaymentDate} format="date" />
        <Td data={item.intendedRepaymentDate} format="date" />
        <Td
          data={t(`code:early-repayment-request-status.${item.earlyRepaymentRequestStatus}`)}
          className={getStatusTextClass('EARLY_REPAYMENT_REQUEST_STATUS', item.earlyRepaymentRequestStatus)}
        />
        <TdLink path={ROUTES_FI.MANAGE_FINANCING.EARLY_REPAYMENT_DETAIL_BUILD_PATH(item.earlyRepaymentRequestId)} />
      </Tr>
    ));
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 120,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Original_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Requested_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickEarlyRepaymentRequestStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <HeaderTitle title={t('text:Early_Repayment')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Financing_ID')} />
              <SearchInput name="financierLoanId" ref={searchFormRegister} />
              <SearchLabel label={t('text:Platform_Financing_ID')} />
              <SearchInput name="loanId" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="dealerFinancierClientName" ref={searchFormRegister} />
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput name="dealerFinancierClientCode" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financing_Amount')} />
              <SearchInput name="principalAmountFrom" ref={searchFormRegister} col={2} placeholder={t('text:from')} />
              <SearchInput name="principalAmountTo" ref={searchFormRegister} col={2} placeholder={t('text:to')} />
              <SearchLabel label={t('text:Disbursed_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="disbursedDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="disbursedDateTo" control={searchFormControl} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Original_Repayment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="repaymentDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="repaymentDateTo" control={searchFormControl} />
              <SearchLabel label={t('text:Requested_Repayment_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="intendedRepaymentDateFrom"
                control={searchFormControl}
              />
              <SearchDatePicker placeholder={t('text:to')} name="intendedRepaymentDateTo" control={searchFormControl} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Requested_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="createdDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="createdDateTo" control={searchFormControl} />
              <SearchLabel
                label={t('text:Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickEarlyRepaymentRequestStatus}
              />
              <SearchSelect
                name="earlyRepaymentRequestStatus"
                selectOptions={getSelectOptions<EARLY_REPAYMENT_REQUEST_STATUS>(
                  'EARLY_REPAYMENT_REQUEST_STATUS',
                  'ALL',
                  true,
                )}
                ref={searchFormRegister}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {earlyRepaymentRequestData?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierEarlyRepaymentList;
