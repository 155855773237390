import {
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  CURRENCY_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  stringToEnum,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { getDday } from 'utils/date/date';
import { hasValue } from 'utils/valueManager/ValueManager';

import { formattingToLoanEtcAttachmentVOModel } from './LoanEtcAttachmentVO';
import { formattingToOverdueInterestRateTermSpreadVOModel } from './OverdueInterestRateTermSpreadVO';
import { formattingToPurposeOfLoanVOModel } from './PurposeOfLoanVO';

import type { LoanEtcAttachmentVO, LoanEtcAttachmentVOModel } from './LoanEtcAttachmentVO';
import type {
  OverdueInterestRateTermSpreadVO,
  OverdueInterestRateTermSpreadVOModel,
} from './OverdueInterestRateTermSpreadVO';
import type { PurposeOfLoanVO, PurposeOfLoanVOModel } from './PurposeOfLoanVO';

export interface LoanDetailVO {
  loanId: number;
  financierId: number;
  financierName: string;
  financierLoanId: string;
  currencyType: string;
  dealerClientId: number;
  dealerClientCode: string;
  dealerClientName: string;
  anchorClientId: number;
  anchorClientCode: string;
  anchorClientName: string;
  createdDateTime: string;
  requestedDateTime: string;
  disbursedDate: string;
  repaymentDate: string;
  repaidDate: string;
  disbursedAmount: number;
  loanStatus: string;
  cancelDisbursementReason: string;
  createdBasisInterestType: string;
  createdBasisInterestRate: number;
  createdBasisInterestTerm: string;
  createdCreditSpreadInterestRate: number;
  createdTermSpreadInterestRate: number;
  createdPreferentialInterestRate: number;
  createdTotalInterestRateWithoutBasis: number;
  createdFeeAmount: number;
  approvedBasisInterestType: string;
  approvedBasisInterestRate: number;
  approvedBasisInterestTerm: string;
  approvedCreditSpreadInterestRate: number;
  approvedTermSpreadInterestRate: number;
  approvedPreferentialInterestRate: number;
  approvedTotalInterestRateWithoutBasis: number;
  approvedFeeAmount: number;
  disbursedBasisInterestType: string;
  disbursedBasisInterestRate: number;
  disbursedBasisInterestTerm: string;
  disbursedCreditSpreadInterestRate: number;
  disbursedTermSpreadInterestRate: number;
  disbursedPreferentialInterestRate: number;
  disbursedTotalInterestRateWithoutBasis: number;
  disbursedFeeAmount: number;
  desiredDisburseDate: string;
  approvedDisburseDate: string;
  cancelDateTime: string;
  cancelReason: string;
  approvedDateTime: string;
  returnDateTime: string;
  returnReason: string;
  rejectDateTime: string;
  rejectReason: string;
  expiredDate: string;
  expirationDateTime: string;
  expirationReason: string;
  originalRepaymentDate: string;
  principalAmount: number;
  enableTransactionFee: boolean;
  repaidPrincipalAmount: number;
  repaidInterestAmount: number;
  repaidDelayAmount: number;
  totalInterestAmount: number;
  accruedInterestAmount: number;
  outstandingAccruedInterestAmount: number;
  totalDelayAmount: number;
  maxExtensibleLoanCount: number;
  extendedLoanCount: number;
  earlyRepaymentAllowable: boolean;
  interestRepaymentType: string;
  monthlyInterestRepaymentDate: number;
  settlementAccountFinancierCode: string;
  settlementAccountFinancierName: string;
  settlementAccountBranchCode: string;
  settlementAccountBranchName: string;
  settlementAccount: string;
  settlementAccountOwner: string;
  principalRepaymentAccountFinancierCode: string;
  principalRepaymentAccountFinancierName: string;
  principalRepaymentAccountBranchCode: string;
  principalRepaymentAccountBranchName: string;
  principalRepaymentAccount: string;
  principalRepaymentAccountOwner: string;
  interestRepaymentAccountFinancierCode: string;
  interestRepaymentAccountFinancierName: string;
  interestRepaymentAccountBranchCode: string;
  interestRepaymentAccountBranchName: string;
  interestRepaymentAccount: string;
  interestRepaymentAccountOwner: string;
  loanAgreementAttachmentId: number;
  loanAgreementAttachmentName: string;
  loanAgreementAttachmentPath: string;
  loanRequestAttachmentId: number;
  loanRequestAttachmentName: string;
  loanRequestAttachmentPath: string;
  invoiceId: number;
  invoiceNumber: string;
  referenceNumber: string;
  invoiceAttachmentId: number;
  invoiceAttachmentName: string;
  invoiceAttachmentPath: string;
  loanEtcAttachment: LoanEtcAttachmentVO[];
  loanApprovalType: string;
  financingPeriod: number;
  scheduledFinancingPeriod: number;
  tempTransactionDate: string;
  tempTotalInterestAmount: number;
  tempTotalDelayAmount: number;
  collateralType: string;
  purposeOfLoanList: PurposeOfLoanVO[];
  totalPurposeOfLoanAmount: number;
  successArId: number;
  dealerAgreementId: number;
  showExpectedTotalInterestRate: boolean;
  createdBasisInterestBankCode: string;
  approvedBasisInterestBankCode: string;
  disbursedBasisInterestBankCode: string;
  showNetDisbursementAmount: boolean;
  netDisbursementAmount: number;
  loanFactoringNotificationAttachmentId: number;
  loanFactoringNotificationAttachmentName: string;
  factoringEnable: boolean;
  eSignatureEnable: boolean;
  multipleRequested: boolean;
  multipleLoanRequestId: number;
  overdueInterestRateTermSpreads: OverdueInterestRateTermSpreadVO[];
}

export interface LoanDetailVOModel extends LoanDetailVO {
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  loanStatus: LOAN_STATUS;
  loanApprovalType: LOAN_APPROVAL_TYPE;
  createdBasisInterestType: BASIS_INTEREST_TYPE;
  approvedBasisInterestType: BASIS_INTEREST_TYPE;
  disbursedBasisInterestType: BASIS_INTEREST_TYPE;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  loanEtcAttachment: LoanEtcAttachmentVOModel[];
  purposeOfLoanList: PurposeOfLoanVOModel[];
  overdueInterestRateTermSpreads: OverdueInterestRateTermSpreadVOModel[];
  // only front
  outstandingBalance: BigNumber;
  approvedFinancingAmount?: number;
  remainingFinancingTerm?: string;
}

export function formattingToLoanDetailVOModel(data: LoanDetailVO): LoanDetailVOModel {
  const calculatorBigNumber = new CalculatorBigNumber();
  const { loanStatus } = data;

  const getRemainingFinancingTerm = (loanStatus: LOAN_STATUS, repaymentDate: string) => {
    if ((loanStatus === LOAN_STATUS.OVERDUE || loanStatus === LOAN_STATUS.DISBURSED) && hasValue(repaymentDate)) {
      return getDday(repaymentDate);
    }
  };

  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
    createdBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.createdBasisInterestType),
    approvedBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.approvedBasisInterestType),
    disbursedBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.disbursedBasisInterestType),
    interestRepaymentType: stringToEnum(INTEREST_REPAYMENT_TYPE, data.interestRepaymentType),
    loanApprovalType: stringToEnum(LOAN_APPROVAL_TYPE, data.loanApprovalType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    loanEtcAttachment: data.loanEtcAttachment.map(loanEtcAttachment =>
      formattingToLoanEtcAttachmentVOModel(loanEtcAttachment),
    ),
    purposeOfLoanList: data.purposeOfLoanList.map(purposeOfLoan => formattingToPurposeOfLoanVOModel(purposeOfLoan)),
    // only front
    outstandingBalance: calculatorBigNumber.add(data.disbursedAmount).minus(data.repaidPrincipalAmount).get(), // disbursedAmount - repaidPrincipalAmount
    approvedFinancingAmount:
      loanStatus === LOAN_STATUS.DEALER_CREATED ||
      loanStatus === LOAN_STATUS.DEALER_REQUESTED ||
      loanStatus === LOAN_STATUS.DEALER_CANCELLED ||
      loanStatus === LOAN_STATUS.REJECTED
        ? undefined
        : data.principalAmount,
    overdueInterestRateTermSpreads: data.overdueInterestRateTermSpreads?.map(termSpread =>
      formattingToOverdueInterestRateTermSpreadVOModel(termSpread),
    ),
    remainingFinancingTerm: getRemainingFinancingTerm(stringToEnum(LOAN_STATUS, data.loanStatus), data.repaymentDate),
  };
}
