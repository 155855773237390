import { useTranslation } from 'react-i18next';

import { isNull } from 'lodash-es';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import ClipboardWriteButton from 'components/stateless/Button/ClipboardWriteButton';
import Form from 'components/stateless/Form';
import { FormErrorMessage } from 'components/stateless/Form/ErrorMessage';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';
import { ColorMap } from 'enums/colorMap';

import usePartnerCompanyController from './usePartnerCompanyController';

const PartnerCompany = () => {
  const { t } = useTranslation();

  const {
    register,
    errors,
    isEditable,
    agreement: { authorizerBankUserId, authorizerStatus, dealerClientName, dealerClientTaxCode, dealerClientCode },
    isSearchPartnerButtonVisible,
    inviteUrl,
    isUserAccountStatusVisible,
    isSendEmailVisible,
    isClipboardWriteButtonVisible,
    renderAuthorizerStatus,
    handleSearchPartnerButtonClick,
    handleInviteAuthorizerButtonClick,
    handleClipboardButtonClick,
    handleUserAccountStatusClick,
  } = usePartnerCompanyController();

  return (
    <>
      <SubTitle>{t('text:PARTNER_COMPANY')}</SubTitle>
      <Content>
        <Row singleColumnPosition={isSearchPartnerButtonVisible ? 'bottom' : 'all'}>
          <Form.Label required={isSearchPartnerButtonVisible}>{t('text:Partner_Client')}</Form.Label>
          {isSearchPartnerButtonVisible && (
            <div className="flex-align-center">
              <Button className="me-2" size={ButtonSizeEnum.SM} bold onClick={handleSearchPartnerButtonClick}>
                {t('text:Search_Partner')}
              </Button>
              {errors?.dealerClientId && <FormErrorMessage error={errors.dealerClientId} />}
            </div>
          )}
          <Border>
            <Content isInnerStyle={true}>
              <input type="hidden" name="dealerClientId" ref={register} />
              <Row>
                <Form.Control name="dealerClientCode">
                  <Form.Label>{t('text:Client_Code')}</Form.Label>
                  <Form.Input readOnly={isEditable} defaultValue={dealerClientCode} showError={false} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control name="dealerClientName">
                  <Form.Label>{t('text:Partner_Name')}</Form.Label>
                  <Form.Value value={dealerClientName} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Form.Label>{t('text:Tax_Code')}</Form.Label>
                  <Form.Value value={dealerClientTaxCode} />
                </Form.Control>
              </Row>
            </Content>
          </Border>
        </Row>
        <Row singleColumnPosition="all">
          <Form.Label>{t('text:Partner_Authorizer_Information')}</Form.Label>
          <Border>
            <Content isInnerStyle>
              <input type="hidden" name="bankUserIdList" ref={register} />
              <input type="hidden" name="authorizerEmployeeCode" ref={register} />
              <input type="hidden" name="authorizerPosition" ref={register} />
              <Row>
                <Form.Control name="authorizerBankUserId" required>
                  <Form.Label>{t('text:User_Code')}</Form.Label>
                  <Form.Input />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control name="authorizerName" required>
                  <Form.Label>{t('text:Name')}</Form.Label>
                  <Form.Input />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control name="authorizerMobile" required>
                  <Form.Label>{t('text:Mobile')}</Form.Label>
                  <Form.Input />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control name="authorizerEmail" required>
                  <Form.Label>{t('text:Email')}</Form.Label>
                  <Form.Input />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control name="authorizerOtpSerialNo">
                  <Form.Label>{t('text:OTP_Number')}</Form.Label>
                  <Form.Input />
                </Form.Control>
              </Row>
              {isUserAccountStatusVisible && (
                <Row>
                  <Form.Control>
                    <Form.Label handleQuestionButtonClick={handleUserAccountStatusClick}>
                      {t('text:User_Account_Status')}
                    </Form.Label>
                    <Cell className="flex-row flex-align-center flex--gap-8">
                      <Form.Value value={renderAuthorizerStatus(authorizerStatus)} />
                      {isSendEmailVisible && (
                        <Button size={ButtonSizeEnum.SM} onClick={handleInviteAuthorizerButtonClick} bold>
                          {t('text:Send_Invitation_Email')}
                        </Button>
                      )}
                      {isClipboardWriteButtonVisible && (
                        <ClipboardWriteButton
                          id={authorizerBankUserId}
                          disabled={isNull(inviteUrl)}
                          onClickClipboardWriteButton={handleClipboardButtonClick}
                          toolTipBgColor={ColorMap.black}
                          style={{ backgroundColor: ColorMap.white }}
                          toolTipText={t('text:Copy_Sign_up_link')}
                        />
                      )}
                    </Cell>
                  </Form.Control>
                </Row>
              )}
            </Content>
          </Border>
        </Row>
      </Content>
    </>
  );
};

export default PartnerCompany;
