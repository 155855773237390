import type React from 'react';
import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { BASIS_INTEREST_TYPE, INTEREST_REPAYMENT_TYPE } from 'enums';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import { wrappingGetInterestRateWithOutConditionOfHasBasisInterest } from 'utils/logic';

interface AnchorFinancingDetailFinancingRequestInformationProps {
  data: AnchorLoanDetailVOModel;
}

function AnchorFinancingDetailFinancingRequestInformation({
  data,
}: AnchorFinancingDetailFinancingRequestInformationProps) {
  const { t } = useTranslation(['format']);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_Request_Information')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              className="information-form__input border-none bold-font"
              col={3}
              label={t('text:Requested_Financing_Amount')}
              value={data.principalAmount}
              format="number"
            />
            <FormValue
              className="information-form__input border-none bold-font"
              col={3}
              label={t('text:Scheduled_Disbursement_Date')}
              value={data.desiredDisburseDate}
              format="date"
            />
            <FormValue
              className="information-form__input border-none bold-font"
              col={3}
              label={t('text:Scheduled_Repayment_Date')}
              value={data.repaymentDate}
              format="date"
            />
            <FormValue
              col={3}
              label={t('text:Financing_Term')}
              value={`${data.scheduledFinancingPeriod}  ${t('text:Days')}`}
            />
          </div>
          <div className="row">
            <FormValue
              col={3}
              label={t('text:Interest_Payment_Method')}
              value={`${t(`code:interest-repayment-type.${data.interestRepaymentType}`)}${
                data.interestRepaymentType === INTEREST_REPAYMENT_TYPE.MONTHLY
                  ? ` (${t('text:Date')} : ${data.monthlyInterestRepaymentDate})`
                  : ''
              }`}
            />
            <FormValue col={3} label={t('text:Base_Interest_Type')} value={data.createdBasisInterestBankCode} />
            <FormValue
              col={3}
              label={
                data.createdBasisInterestType === BASIS_INTEREST_TYPE.FIXED
                  ? t('text:Basis_Interest_Rate(APR)')
                  : t('text:Base_Interest_Term')
              }
              value={
                data.createdBasisInterestType === BASIS_INTEREST_TYPE.FIXED
                  ? `${t('format:number', { value: data.createdBasisInterestRate })}%`
                  : data.createdBasisInterestTerm
                  ? data.createdBasisInterestTerm
                  : '-'
              }
            />
            <FormValue
              col={3}
              label={t('text:Expected_Interest_Rate')}
              value={wrappingGetInterestRateWithOutConditionOfHasBasisInterest({
                showExpectedTotalInterestRate: true,
                basisInterestBankCode: data.createdBasisInterestBankCode,
                basisInterestRate: data.createdBasisInterestRate,
                termSpreadRate: data.createdTermSpreadInterestRate,
                creditInterestRate: data.createdCreditSpreadInterestRate,
                preferentialInterestRate: data.createdPreferentialInterestRate,
              })}
            />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default AnchorFinancingDetailFinancingRequestInformation;
