import type Pageable from 'models/Pageable';
import { formattingToAnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { AnchorDealerVO, AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorDealerRequest } from './request';
import type { AnchorDealerListRequest } from './request';

export async function requestAnchorDealerList(
  pageNumber: number,
  rowCount: number,
  searchValue?: AnchorDealerListRequest,
): Promise<Pageable<AnchorDealerVOModel[]>> {
  const response = await http.get<Pageable<AnchorDealerVO[]>>({
    url: API_AC.ANCHOR_DEALERS.ANCHOR_DEALERS_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorDealerVOModel(data)),
  };
}

export async function requestAnchorDealerDetail(anchorDealerId: number): Promise<AnchorDealerVOModel> {
  const response = await http.get<AnchorDealerVO>({
    url: API_AC.ANCHOR_DEALERS.ANCHOR_DEALERS_DETAIL(anchorDealerId),
  });

  return formattingToAnchorDealerVOModel(response);
}

export async function requestAnchorDealerRegister(
  anchorDealerId: number,
  data: AnchorDealerRequest,
): Promise<AnchorDealerVOModel> {
  const response = await http.post<AnchorDealerVO>({
    url: API_AC.ANCHOR_DEALERS.ANCHOR_DEALERS_DETAIL(anchorDealerId),
    data,
  });

  return formattingToAnchorDealerVOModel(response);
}
