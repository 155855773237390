import type React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import type { ControllerRenderProps, FieldError, UseControllerOptions } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { NumberFormatValues, NumericFormatProps } from 'react-number-format';
import { NumericFormat, numericFormatter, useNumericFormat } from 'react-number-format';

import { isNil } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import type { CURRENCY_TYPE } from 'enums';
import { languageType } from 'enums';
import { isDecimalCurrencyType } from 'utils/logic';
import { getLanguage } from 'utils/storage/LocalStorage';

import { FormErrorMessage } from './FormErrorMessage';

export type TextAlignType = 'text-center' | 'text-start' | 'text-end';
export type NumericType = 'integer' | 'bigNumber' | 'float';

export interface NumericFormatInputProps extends NumericFormatProps {
  control: UseControllerOptions['control'];
  name: ControllerRenderProps['name'];
  numberType?: NumericType;
  label?: string;
  col?: number;
  textAlign?: TextAlignType;
  currencyType?: CURRENCY_TYPE;
  error?: FieldError;
  rules?: UseControllerOptions['rules'];
  required?: boolean;
  isDisplayNone?: boolean;
  useIsAllowed?: boolean;
  useDecimalScale?: boolean;
  useUpToOneHundredPercent?: boolean;
}

type NumericSeparator = Pick<NumericFormatProps, 'thousandSeparator' | 'decimalSeparator' | 'thousandsGroupStyle'>;

export interface NumericFormatInputRefHandle {
  focus: () => void;
}

export const maxLength = {
  integer: 9,
  float: 11,
  bigNumber: 19,
};

export const separators: {
  [key in languageType]: NumericSeparator;
} = {
  [languageType.EN_USA]: { thousandSeparator: ',', decimalSeparator: '.', thousandsGroupStyle: 'thousand' },
  [languageType.VN]: { thousandSeparator: '.', decimalSeparator: ',', thousandsGroupStyle: 'thousand' },
  [languageType.EN_INDIA]: { thousandSeparator: ',', decimalSeparator: '.', thousandsGroupStyle: 'lakh' },
  [languageType.KO]: { thousandSeparator: ',', decimalSeparator: '.', thousandsGroupStyle: 'thousand' },
};

const NumericFormatInput = forwardRef<NumericFormatInputRefHandle, NumericFormatInputProps>(
  (
    {
      control,
      name,
      className,
      onChange,
      onPaste,
      currencyType,
      thousandSeparator = true,
      allowedDecimalSeparators = ['.', ','],
      allowNegative = false,
      disabled = false,
      numberType = 'float',
      useIsAllowed = true,
      isDisplayNone = false,
      useDecimalScale = true,
      placeholder,
      label,
      col = 6,
      required = false,
      textAlign = '',
      error,
      rules,
      defaultValue = '',
      isAllowed, // 입력 값의 유효성을 검사하고 입력 값의 업데이트 여부를 결정하는 함수
      useUpToOneHundredPercent = false,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) inputRef.current.focus();
      },
    }));

    const { t } = useTranslation();
    const language = getLanguage() as languageType;

    const id = uuidv4();

    const getSeparator: NumericSeparator = useMemo(
      () =>
        separators[language] || {
          thousandSeparator: ',',
          decimalSeparator: '.',
          thousandsGroupStyle: 'thousand',
        },
      [language],
    );

    const getDecimalScale = (() => {
      if (currencyType) {
        return isDecimalCurrencyType(currencyType) ? 2 : 0;
      } else {
        if (numberType === 'integer') return 0;
        else return 4;
      }
    })();

    const isAllowedUpToOneHundredPercent = (values: NumberFormatValues) => {
      const { floatValue } = values;

      if (floatValue && floatValue > 100) return false;
      else return true;
    };

    const defaultIsAllowed = (values: NumberFormatValues) => {
      const { value } = values;

      const [integer] = value.split('.');
      const isValidInteger = integer.length <= maxLength[numberType];

      return isValidInteger;
    };

    const getIsAllowed = (() => {
      if (!useIsAllowed) return undefined;
      if (useUpToOneHundredPercent) return isAllowedUpToOneHundredPercent;

      return isAllowed ? isAllowed : defaultIsAllowed;
    })();

    const { removeFormatting = () => '' } = useNumericFormat({
      thousandSeparator: thousandSeparator && getSeparator['thousandSeparator'],
      thousandsGroupStyle: getSeparator['thousandsGroupStyle'],
      decimalScale: getDecimalScale,
      decimalSeparator: getSeparator['decimalSeparator'],
      allowedDecimalSeparators: allowedDecimalSeparators,
      valueIsNumericString: true,
      allowNegative,
    });

    const handlePaste = (
      event: React.ClipboardEvent<HTMLInputElement>,
      onValueChange: ControllerRenderProps['onChange'],
    ) => {
      if (!useIsAllowed) return;
      if (useUpToOneHundredPercent) return;

      event.preventDefault();
      const text = event.clipboardData.getData('text');
      const unformattedText = removeFormatting(text);
      const formattedTextWithDecimalScale = numericFormatter(unformattedText, {
        decimalScale: getDecimalScale,
      });

      const [integer, decimal = ''] = formattedTextWithDecimalScale.split('.');
      const validInteger = integer.substring(0, maxLength[numberType]);
      const validNumeric = `${validInteger}${decimal ? '.' : ''}${decimal}`;
      onValueChange(validNumeric);
      if (onPaste) onPaste(event);
    };

    const getPlaceHolder = (() => {
      if (disabled) return '';
      if (label && placeholder === undefined) return t('text:Please_type_here');
      else return placeholder;
    })();

    useEffect(() => {
      if (!error) return;
      inputRef.current && inputRef.current.focus();
    }, [error]);

    const renderInput = (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange: onValueChange, name, value }) => {
          return (
            <NumericFormat
              id={id}
              name={name}
              aria-label={name}
              className={`${className} ${label && `information-form__input`} ${
                error && 'error-input-border'
              } ${textAlign}`}
              value={isNil(value) ? '' : value}
              onChange={onChange}
              onValueChange={values => onValueChange(values.value)}
              onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => handlePaste(event, onValueChange)}
              thousandSeparator={thousandSeparator && getSeparator['thousandSeparator']}
              thousandsGroupStyle={getSeparator['thousandsGroupStyle']}
              decimalScale={useDecimalScale ? getDecimalScale : undefined}
              decimalSeparator={getSeparator['decimalSeparator']}
              allowedDecimalSeparators={allowedDecimalSeparators}
              allowNegative={allowNegative}
              isAllowed={getIsAllowed}
              getInputRef={inputRef}
              valueIsNumericString
              placeholder={getPlaceHolder}
              disabled={disabled}
            />
          );
        }}
        rules={rules}
      />
    );

    return label ? (
      <div className={`col-${col} ${isDisplayNone ? 'd-none' : ''}`} data-testid="numeric-format-div">
        <label htmlFor={id} className={`information-form__label ${required && !disabled ? 'star' : ''}`}>
          {label}
        </label>
        {renderInput}
        {error && <FormErrorMessage error={error} />}
      </div>
    ) : (
      renderInput
    );
  },
);

NumericFormatInput.displayName = 'NumericFormatInput';
export default NumericFormatInput;
