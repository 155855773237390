import type { ReactNode } from 'react';
import { createContext } from 'react';

import type { ModalOptions } from 'utils/modal/ModalWrapper';

export interface ModalContextProps {
  show: (children: ReactNode | any, options?: ModalOptions) => void;
  close: (id: number) => void;
  disableConfirmBtn: (id: number, disabled: boolean) => void;
  id: number;
}

export const defaultModalContext: ModalContextProps = {
  show: () => {},
  close: () => {},
  disableConfirmBtn: () => {},
  id: 0,
};

const ModalContext = createContext<ModalContextProps>(defaultModalContext);

export default ModalContext;
