import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import dayjs from 'dayjs';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import {
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  FINANCIER_INTERFACE_TYPE,
  LOAN_STATUS,
  OTP_TYPE,
  SUCCESS_AR_STATUS,
} from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { PartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { getPrevDay } from 'utils/date/date';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestAnchorDownloadScannedAr } from 'utils/http/api/anchor/loans';
import {
  requestAnchorPartialCollaterals,
  requestAnchorRenewArByFinancierInterface,
  requestAnchorSuccessArsDelete,
  requestAnchorSuccessArsDetail,
} from 'utils/http/api/anchor/success-ars';
import { requestFinancierInterfaceSetting } from 'utils/http/api/common/financier-interface-setting';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getBlockedStatusText } from 'utils/text';
import { getFilteredDisbursedAmount } from 'utils/valueManager/ValueManager';

function AnchorApDetail() {
  const history = useHistory();
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const { successArId } = useParams() as any;
  const { pageable: financingInfoPageable, setPageable: setFinancingInfoPageable } = usePageable();

  // state
  const [anchorApDetail, setAnchorApDetail] = useState<SuccessArDetailVOModel>();
  const [financingInfo, setFinancingInfo] = useState<Pageable<PartialCollateralVOModel[]>>();
  const [clientAuthType, setClientAuthType] = useState<FinancierClientAuthSettingVOModel>();
  const [arRepaymentInterface, setArRepaymentInterface] = useState<boolean>();

  const fetchAll = async () => {
    const fetchedAnchorApDetailPage = await requestAnchorSuccessArsDetail(successArId);
    try {
      const [clientAuthTypeData, arRepaymentInterfaceType, financingInfoResponse] = await Promise.all([
        requestAnchorClientAuthByFinancierId(fetchedAnchorApDetailPage.financierId),
        requestFinancierInterfaceSetting(
          fetchedAnchorApDetailPage.financierId,
          FINANCIER_INTERFACE_TYPE.AR_PAYMENT_RESULT,
        ),
        requestAnchorPartialCollaterals(
          financingInfoPageable.currentPage,
          financingInfoPageable.sizePerPage,
          successArId,
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorApDetail(fetchedAnchorApDetailPage);
        setClientAuthType(clientAuthTypeData);
        setArRepaymentInterface(arRepaymentInterfaceType);
        setFinancingInfo(financingInfoResponse);
        setFinancingInfoPageable(financingInfoResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const FINANCING_INFORMATION_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 150,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 150,
    },
    {
      headerText: t('text:Disbursement_Amount'),
      colWidths: 150,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Repaid_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Tax_Invoice_File'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickLoanStatus,
    },
    {
      headerText: '',
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const paginateFinancingInfo = async (pageNumber: number, rowCount: number) => {
    try {
      const financingInfoResponse = await requestAnchorPartialCollaterals(pageNumber, rowCount, successArId);

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancingInfo(financingInfoResponse);
        setFinancingInfoPageable(financingInfoResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickDownloadScannedAr = async (loanId: number) => {
    try {
      await requestAnchorDownloadScannedAr(loanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const renderRenewTransactionButton = () => {
    const fetchRenewArByFinancierInterface = async () => {
      try {
        await requestAnchorRenewArByFinancierInterface(anchorApDetail?.successArId!);
      } catch (e) {
        modal.show(e);
      }
    };

    const onClickRenewTransaction = async (e: any) => {
      e.preventDefault();

      fetchRenewArByFinancierInterface().then(() => fetchAll());
    };

    if (
      arRepaymentInterface &&
      anchorApDetail?.paymentSupport &&
      (anchorApDetail?.successArStatus === SUCCESS_AR_STATUS.REGISTERED ||
        anchorApDetail?.successArStatus === SUCCESS_AR_STATUS.FAILED)
    ) {
      return (
        <GuideMessage
          message={[t('text:Please_click_Renew_Transaction_button_to_update_transaction_status')]}
          isImportContentArea={true}
        >
          {{
            button: <Button onClick={onClickRenewTransaction}>{t('text:Renew_Transaction')}</Button>,
          }}
        </GuideMessage>
      );
    } else {
      return null;
    }
  };

  // Edit, Delete 버튼 가능 시점 조건
  // 대출이 없는 AP는 만기일 1일 전까지 가능, 대출이 있는 AP는 불가능
  const btnDisabled = () => {
    const [prevMaturityDate] = getPrevDay(anchorApDetail?.settlementDate).split('T');
    const [today] = dayjs().format().split('T');

    return (
      (prevMaturityDate < today && !anchorApDetail?.loanGiven) ||
      anchorApDetail?.loanGiven ||
      clientAuthType?.currentAuthorityType !== AUTHORITY_TYPE.ADMIN
    );
  };

  const onClickDeleteAP = () => {
    const verificationCode: UserVerificationCodeRequest = {};

    const requestDelete = async () => {
      if (anchorApDetail?.financierId) {
        try {
          await requestAnchorSuccessArsDelete({
            successArId: successArId,
            financierId: anchorApDetail?.financierId,
            otpCode: verificationCode.otpCode,
            queryValue: verificationCode.queryValue,
          });
          modal.show(<h6>{t('text:The_AP_has_been_deleted')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnCb: () => history.push(ROUTES_AC.VIEW_TRANSACTION.AP_LIST),
          });
        } catch (e) {
          modal.show(e);
        }
      }
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_delete_the_AP?')}
        <br />
        {t('text:Deleted_AP_information_cannot_be_reversed')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => {
          if (clientAuthType) {
            if (clientAuthType.otpType !== OTP_TYPE.NONE) {
              modal.show(
                <UserVerificationModal
                  modalId={modal.id}
                  requestIdType="financierId"
                  requestId={Number(anchorApDetail?.financierId)}
                  clientAuthSetting={clientAuthType}
                  verificationCode={verificationCode}
                />,
                {
                  modalType: ModalType.CONFIRM,
                  title: t('text:User_Verification'),
                  closeBtnText: t('text:Cancel'),
                  confirmBtnCb: () => requestDelete(),
                },
              );
            } else {
              requestDelete();
            }
          }
        },
      },
    );
  };

  const showSettlementStatusDescription = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_AR_STATUS)}
        statusDescriptionEnumType="SUCCESS_AR_STATUS"
        statusDescriptionModalType="TEXT"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
      },
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AP_Details')} />
      {anchorApDetail?.blockedByFinancier && (
        <GuideMessage
          message={[t('text:The_AP_has_been_blocked'), t('text:Please_check_the_reason_for_block_below')]}
          messageType={MessageType.ALERT}
          reasonTitle={t('text:REASON_FOR_BLOCK')}
          reason={anchorApDetail.blockedReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:AP_Information')}>
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={onClickDeleteAP}
            disabled={btnDisabled()}
            size={ButtonSizeEnum.SM}
            data-for="delete-button-tip"
            data-tip
          >
            {t('text:Delete')}
          </Button>
          <ReactTooltip id="delete-button-tip" effect="solid">
            <div className="p-1">
              {t('text:Admin_user_can_delete_APs_under_the_following_conditions')} :
              <ul>
                <li>• {t('text:AP_for_which_financing_applications_have_not_been_made')}</li>
                <li>• {t('text:AP_for_which_settlement_date_has_not_arrived')}</li>
              </ul>
            </div>
          </ReactTooltip>
        </SectionTitle>
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:AP_Amount')}
                className="information-form__input border-none bold-font"
                col={3}
                value={anchorApDetail?.arAmount}
                format="number"
              />
              <FormValue
                label={t('text:Currency')}
                className="information-form__input border-none bold-font"
                col={3}
                value={anchorApDetail?.currencyType}
              />
              <FormValue
                label={t('text:Financing_Eligibility')}
                className={`information-form__input border-none ${getBlockedStatusClassName(
                  anchorApDetail?.blockedByFinancier,
                )}`}
                col={3}
                value={getBlockedStatusText(anchorApDetail?.blockedByFinancier)}
              />
              <FormValue
                label={t('text:Settlement_Status')}
                className={
                  'information-form__input border-none ' +
                  getStatusTextClass('SUCCESS_AR_STATUS', anchorApDetail?.successArStatus)
                }
                col={3}
                value={t(`code:success-ar-status.${anchorApDetail?.successArStatus}`)}
                format="code"
                hasStatusDescription
                showStatusDescriptionFunc={showSettlementStatusDescription}
              />
            </div>
          </FormContents>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:AP_Number')} col={3} value={anchorApDetail?.arNumber} />
              <FormValue label={t('text:AP_Issued_Date')} col={3} value={anchorApDetail?.arIssuedDate} format="date" />
              <FormValue
                label={t('text:Original_Settlement_Date')}
                col={3}
                value={anchorApDetail?.maturityDate}
                format="date"
              />
              <FormValue
                label={t('text:Modified_Settlement_Date')}
                col={3}
                value={anchorApDetail?.settlementDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={anchorApDetail?.anchorName} />
              <FormValue label={t('text:Uploaded_Partner_Name')} value={anchorApDetail?.anchorPartnerName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Financing_Information')} />
        <TableBorder>
          <TableHeader header={FINANCING_INFORMATION_HEADERS} />
          <TableBody numOfCol={FINANCING_INFORMATION_HEADERS.length}>
            {financingInfo?.content?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.loanId} />
                <Td data={item.netDisbursementAmount} format="number" />
                <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
                <Td data={item.disbursedDate} format="date" />
                <Td data={item.repaidDate} format="date" />
                <Td data={item.attachmentName} />
                <Td
                  data={t(`code:financing-status.${item.loanStatus}`)}
                  className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
                  format="code"
                />
                <Td className="text-center">
                  <DownloadButton
                    onClick={() => onClickDownloadScannedAr(item.loanId)}
                    disabled={!item.attachmentId && !item.attachmentName}
                  />
                </Td>
                <TdLink
                  path={ROUTES_AC.VIEW_TRANSACTION.FINANCING_LIST}
                  state={{
                    loanId: item.loanId,
                    loanStatusConditions: [item.loanStatus],
                    collateralType: COLLATERAL_TYPE.AR,
                    maximumDisbursedAmount: item.disbursedAmount > 0 ? item.disbursedAmount : null,
                    disbursedDateTo: item.disbursedDate,
                    repaymentDateTo: item.repaidDate,
                  }}
                />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={financingInfoPageable} paginate={paginateFinancingInfo} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Scheduled_AP_Settlement_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Scheduled_Settlement_Amount')}
                value={anchorApDetail?.arAmount}
                format="number"
              />
              <FormValue
                label={t('text:Scheduled_Settlement_Date')}
                value={anchorApDetail?.settlementDate}
                format="date"
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Withdrawal_Bank_Account_Information_Anchor')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={anchorApDetail?.anchorName} />
              <FormValue label={t('text:Agreement_Number')} value={anchorApDetail?.anchorContractNo} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Automatic_Settlement')}
                value={
                  anchorApDetail?.paymentSupport
                    ? `${t('text:Yes')} (${t('text:Co_operation&Payment')})`
                    : `${t('text:No')} (${t('text:Co_operation')})`
                }
              />
              <FormValue label={t('text:Bank_Name')} value={anchorApDetail?.anchorSettlementBankName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} value={anchorApDetail?.anchorSettlementAccount} />
              <FormValue label={t('text:Account_Holder')} value={anchorApDetail?.anchorSettlementAccountOwner} />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Deposit_Bank_Account_Information_Partner')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Partner_Name')} value={anchorApDetail?.anchorPartnerName} />
              <FormValue label={t('text:Bank_Name')} value={anchorApDetail?.partnerRequestedBankName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} value={anchorApDetail?.partnerRequestedAccount} />
              <FormValue label={t('text:Account_Holder')} value={anchorApDetail?.partnerRequestedAccountOwner} />
            </div>
          </FormContents>
          {/* 파트너 약정 존재할 경우 해당 섹션 표시 */}
          {anchorApDetail?.dealerContractNo ? (
            <FormContents>
              <div className="row text-bold-brick-red">
                {t(
                  'text:*_If_the_Partner_has_a_master_agreement_the_settlement_will_be_made_to_the_bank_account_below',
                )}
              </div>
              <div className="row">
                <FormValue label={t('text:Agreement_Number')} col={6} value={anchorApDetail?.dealerContractNo} />
                <FormValue label={t('text:Bank_Name')} col={6} value={anchorApDetail?.principalRepaymentBankName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} col={6} value={anchorApDetail?.principalRepaymentAccount} />
                <FormValue
                  label={t('text:Account_Holder')}
                  col={6}
                  value={anchorApDetail?.principalRepaymentAccountOwner}
                />
              </div>
            </FormContents>
          ) : null}
        </FormBorder>
      </div>
      {anchorApDetail?.paymentSupport ? (
        <div className="content-area">
          <SectionTitle title={t('text:AP_Settlement_Result')} />
          {renderRenewTransactionButton()}
          <FormBorder>
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue
                  label={t('text:Settlement_Status')}
                  col={6}
                  value={t(`code:success-ar-status.${anchorApDetail?.successArStatus}`)}
                  className={`information-form__input border-none + ${getStatusTextClass(
                    'SUCCESS_AR_STATUS',
                    anchorApDetail.successArStatus,
                  )}`}
                  format="code"
                />
              </div>
            </FormContents>
            <FormSubtitle
              title={t('text:Bank_Information_of_AP_Settlement')}
              backGroundType={BackGroundType.DarkGray}
            />
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue label={t('text:Anchor_Name')} value={anchorApDetail?.settledAnchorEnterpriseName} />
                <FormValue label={t('text:Bank_Name')} value={anchorApDetail.settledAnchorBankName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={anchorApDetail?.settledAnchorAccount} />
                <FormValue label={t('text:Account_Holder')} value={anchorApDetail?.settledAnchorAccountOwner} />
              </div>
            </FormContents>
            <FormContents backGroundType={BackGroundType.Gray}>
              <div className="row">
                <FormValue label={t('text:Partner_Name')} value={anchorApDetail?.settledPartnerName} />
                <FormValue label={t('text:Bank_Name')} value={anchorApDetail.settledPartnerBankName} />
              </div>
              <div className="row">
                <FormValue label={t('text:Account_Number')} value={anchorApDetail?.settledPartnerAccount} />
                <FormValue label={t('text:Account_Holder')} value={anchorApDetail?.settledPartnerAccountOwner} />
              </div>
            </FormContents>
          </FormBorder>
        </div>
      ) : null}
    </>
  );
}

export default AnchorApDetail;
