import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { EXTENSION_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierExtensionList } from 'utils/http/api/financier/extension-requests';
import type { FinancierExtensionListRequest } from 'utils/http/api/financier/extension-requests/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const FI_EXTENSION_LIST_QS_KEY = 'fi-extension-list';

function FinancierExtensionList() {
  // 기본 state
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(FI_EXTENSION_LIST_QS_KEY);
  const getProperty = useProperty<FinancierExtensionListRequest>();

  const modal = useModal();

  // 화면 state
  const [extensionList, setExtensionList] = useState<Pageable<ExtensionRequestVOModel[]>>();

  // useForm
  const { register, handleSubmit, getValues, reset, setValue } = useForm<FinancierExtensionListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierExtensionListRequest>(
        setValue,
        getParsedSearchParams(FI_EXTENSION_LIST_QS_KEY).formSearchData,
      );
      fetchAll(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  async function fetchAll(pageNumber: number = 1, rowCount: number = 10, searchData: FinancierExtensionListRequest) {
    try {
      const fetchExtensionData = await requestFinancierExtensionList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        FI_EXTENSION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setExtensionList(fetchExtensionData);
        setPageable(fetchExtensionData);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async () => {
    fetchAll(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchAll(page, sizePerPage, getParsedSearchParams(FI_EXTENSION_LIST_QS_KEY).formSearchData);
  };

  const onRemoveFilter = (event: any) => {
    reset({});
    event.preventDefault();
  };

  const onClickExtensionRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EXTENSION_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EXTENSION_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 100,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Original_Repayment_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Total_Interest_Rate_(APR)'),
      colWidths: 120,
    },
    {
      headerText: t('text:Added_Spread_(APR)'),
      colWidths: 100,
    },
    {
      headerText: t('text:Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickExtensionRequestStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderExtensionRequestList = () => {
    return extensionList?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.financierLoanId} />
          <Td data={item.loanId} />
          <Td data={item.dealerClientName} />
          <Td data={item.dealerClientCode} />
          <Td data={item.disbursedAmount} format="number" />
          <Td data={item.disbursedDate} format="date" />
          <Td
            data={item.extensionStartDate && dayjs(item.extensionStartDate, 'YYYY-MM-DD').subtract(1, 'days')}
            format="date"
          />
          <Td data={item.extendedRepaymentDate} format="date" />
          <Td data={item.disbursedTotalInterestRateWithoutBasis + item.disbursedBasisInterestRate} format="percent" />
          <Td
            data={item.extensionRequestStatus !== EXTENSION_REQUEST_STATUS.REQUESTED ? item.additionalRate : '-'}
            format="percent"
          />
          <Td
            className={getStatusTextClass('EXTENSION_REQUEST_STATUS', item.extensionRequestStatus)}
            data={t(`code:extension-request-status.${item.extensionRequestStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_FI.MANAGE_FINANCING.EXTENSION_DETAIL_BUILD_PATH(item.extensionRequestId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing_Extension')} />

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Financing_ID')} />
              <SearchInput name={getProperty('financierLoanId')} ref={register} />
              <SearchLabel label={t('text:Platform_Financing_ID')} />
              <SearchInput name={getProperty('loanId')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name={getProperty('dealerClientName')} ref={register} />
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput name={getProperty('dealerClientCode')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel
                label={t('text:Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickExtensionRequestStatus}
              />
              <SearchSelect
                name={getProperty('extensionRequestStatus')}
                selectOptions={getSelectOptions<EXTENSION_REQUEST_STATUS>('EXTENSION_REQUEST_STATUS', 'ALL', true)}
                ref={register}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onSearchSubmit)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {extensionList?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderExtensionRequestList()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierExtensionList;
