import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';

import Form, { SizeType } from 'components/stateless/Form';
import { FormErrorMessage } from 'components/stateless/Form/ErrorMessage';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';

import useFinancingDurationController from './useFinancingDurationController';

const FinancingDuration = () => {
  const { t } = useTranslation();

  const { register, errors } = useFinancingDurationController();

  return (
    <>
      <SubTitle>{t('text:FINANCING_DURATION')}</SubTitle>
      <Content>
        <input type="hidden" name="loanTermType" ref={register} />
        <Row>
          <Form.Control name="loanTermType" required>
            <Cell>
              <Form.Label position="top">{t('text:Is_the_financing_duration_flexible_or_fixed?')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_the_financing_duration_is_fixed_or_it_can_be_flexibly_adjusted_during_financing_application',
                )}
              </Form.Description>
            </Cell>
            <Border>
              <Content isInnerStyle>
                <Row>
                  <Form.Control name="minimumLoanRange" required>
                    <Form.Label>{t('text:Minimum_Days')}</Form.Label>
                    <Cell>
                      <Form.NumberInput
                        className={clsx({ 'error-input-border': errors?.minimumLoanRange })}
                        numberType="integer"
                        fieldSize={SizeType.SM}
                        textAlign="text-right"
                        showError={false}
                      />
                      {errors?.minimumLoanRange && <FormErrorMessage error={errors.minimumLoanRange} />}
                    </Cell>
                  </Form.Control>
                </Row>
                <Row>
                  <Form.Control name="maximumLoanRange" required>
                    <Form.Label>{t('text:Maximum_Days')}</Form.Label>
                    <Cell>
                      <Form.NumberInput
                        className={clsx({ 'error-input-border': errors?.maximumLoanRange })}
                        numberType="integer"
                        fieldSize={SizeType.SM}
                        textAlign="text-right"
                        showError={false}
                      />
                      {errors?.maximumLoanRange && <FormErrorMessage error={errors.maximumLoanRange} />}
                    </Cell>
                  </Form.Control>
                </Row>
              </Content>
            </Border>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="disbursementDateOverAllowable" required>
            <Cell>
              <Form.Label position="top">{t('text:Financing_Disbursement_Date')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_the_Partner_can_apply_for_financing_that_will_be_repaid_after_the_master_agreement_expires',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio>
              <Form.Option label={t('text:Can_exceed_agreement_expiration_date')} value="true" />
              <Form.Option label={t('text:Must_be_before_agreement_expiration')} value="false" />
            </Form.Radio>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="repaymentDateOverAllowable" required>
            <Cell>
              <Form.Label position="top">{t('text:Financing_Repayment_Date')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_the_Partner_can_apply_for_financing_that_will_be_repaid_after_the_master_agreement_expires',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio>
              <Form.Option label={t('text:Can_exceed_agreement_expiration_date')} value="true" />
              <Form.Option label={t('text:Must_be_before_agreement_expiration')} value="false" />
            </Form.Radio>
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default FinancingDuration;
