import axios from 'axios';
import fileDownload from 'js-file-download';
import qs from 'qs';

import { AuthenticationExceptionCode, CommonResponseCode } from 'enums/exception';
import { stringToBoolean } from 'utils/downCasting/DownCasting';
import { HttpError } from 'utils/error/HttpError';
import type { CommonResponse } from 'utils/http/CommonResponse';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { removeSignIn, setSessionDurationNeedReset, setSessionDurationTime } from 'utils/storage/LocalStorage';

import type { AxiosInstance, AxiosResponse } from 'axios';

export enum ContentType {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded',
  OCTET_STREAM = 'application/octet-stream',
  TEXT = 'text/plain',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

type HTTPMethodPropsType = {
  url: string;
  data?: any;
  contentType?: ContentType;
  hasLoadingUI?: boolean;
};

// axios instance 생성
export const axiosCreate = axios.create();
const axiosInstance: AxiosInstance = axiosCreate;

axiosInstance.defaults.baseURL = `${window.location.origin}/api/`;
axiosInstance.defaults.headers.post['Content-Type'] = ContentType.JSON;

axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // 오류 요청을 보내기전 수행할 일
    unShowLoadingUI();

    return Promise.reject(error);
  },
);

// 응답 인터셉터 추가
axiosInstance.interceptors.response.use(
  function (response) {
    // 응답이 Error인 경우
    const commonResponse: CommonResponse = response.data;

    if (commonResponse.code !== CommonResponseCode.SUCCESS) {
      if (
        commonResponse.code === AuthenticationExceptionCode.NOT_AUTHENTICATED ||
        commonResponse.code === AuthenticationExceptionCode.HANDLE_ACCESS_DENIED
      ) {
        removeSignIn();
      }
    }

    if (process.env.REACT_APP_IS_MOCK && !stringToBoolean(process.env.REACT_APP_IS_MOCK)) {
      const sessionDurationTimeFromServer: number = Number(response.headers['session-duration-time']);

      if (sessionDurationTimeFromServer) {
        // 일반적으로 로그인 되었을 경우
        setSessionDurationTime(sessionDurationTimeFromServer);
      } else {
        // 재 로그인이 되었을 경우, 이 경우에는 Lifecycle이 항상 살아있는 Component가 없기에 state에 담을 수 없어서, 부득이하게 파라미터를 Session에 저장해 이를 기점으로 다시 타이머가 돌아가도록 설정
        setSessionDurationNeedReset(true);
      }
    }

    return response;
  },
  function (error) {
    // 오류 응답을 처리
    unShowLoadingUI();

    return Promise.reject(error);
  },
);

function convertToFormData(data: { [index: string]: any }) {
  const formData: FormData = new FormData();
  Object.keys(data).forEach(key => {
    if (data[key] instanceof Array || data[key] instanceof FileList) {
      const size = data[key].length;
      for (let i = 0; i < size; i++) {
        if (data[key][i] instanceof File) {
          formData.append(key, data[key][i]);
        } else if (typeof data[key][i] === 'string') {
          formData.append(`${key}[${i}]`, data[key][i]);
        } else if (data[key][i] instanceof FileList) {
          const fileSize = data[key][i].length;
          if (fileSize === 0) {
            formData.append(key, new Blob());
          } else {
            for (let j = 0; j < fileSize; j++) {
              formData.append(key, data[key][i][j]);
            }
          }
        } else if (data[key][i] instanceof Object) {
          for (const [objKey, value] of Object.entries(data[key][i])) {
            if (data[key][i][objKey] instanceof FileList) {
              const fileSize = data[key][i][objKey].length;
              if (fileSize === 0) {
                formData.append(`${key}[${i}].${objKey}`, new Blob());
              } else {
                for (let j = 0; j < fileSize; j++) {
                  formData.append(`${key}[${i}].${objKey}`, data[key][i][objKey][j]);
                }
              }
            } else formData.append(`${key}[${i}].${objKey}`, value as string);
          }
        } else {
          formData.append(key, JSON.stringify(data[key][i]));
        }
      }
    } else if (data[key] instanceof Object) {
      const objectKeys = Object.keys(data[key]);
      objectKeys.forEach(objectKey => {
        formData.append(`${key}.${objectKey}`, data[key][objectKey]);
      });
    } else {
      if (data[key] !== null) {
        formData.append(key, data[key]);
      }
    }
  });

  return formData;
}

class http {
  private displayExceptionUsingStatusCode = [500, 502, 413];

  async download(url: string, data?: any): Promise<void> {
    const queryParam = qs.stringify(data);

    const axiosResponse: AxiosResponse = await axiosInstance.get(!queryParam ? url : `${url}?${queryParam}`, {
      responseType: 'blob',
    });

    // 에러 발생시에는 데이터 타입이 JSON 형태로 내려옴
    if (axiosResponse.data.type === ContentType.JSON) {
      const commonResponse: CommonResponse = JSON.parse(await axiosResponse.data.text());
      throw new HttpError(commonResponse.code, commonResponse.data, commonResponse.message);
    } else {
      // 정상 응답
      const contentDisposition = axiosResponse.headers['content-disposition'];
      const fileName = contentDisposition?.match(/filename[*]=UTF-8''(.+)/)?.[1];
      if (fileName) fileDownload(axiosResponse.data, decodeURI(fileName));
    }
  }

  async get<T>({ url, data }: HTTPMethodPropsType): Promise<T> {
    // TODO: Backend에서 관리하는 Pageable 객체의 PageNumber는 0부터 시작함. 추후 Frontend도 동일하게 작동하게 끔 수정 필요.
    // https://www.npmjs.com/package/qs
    if (data?.pageNumber) {
      data.pageNumber = data.pageNumber - 1;
    }
    const queryParam = qs.stringify(data);

    const axiosResponse: AxiosResponse<CommonResponse<T>> = await axiosInstance.get<
      CommonResponse<T>,
      AxiosResponse<CommonResponse<T>>
    >(!queryParam ? url : `${url}?${queryParam}`);

    const commonResponse: CommonResponse<T> = axiosResponse.data;

    if (commonResponse.code === CommonResponseCode.SUCCESS) {
      return commonResponse.data;
    } else {
      if (commonResponse.code === AuthenticationExceptionCode.NOT_AUTHENTICATED) {
        removeSignIn();
      }
      throw new HttpError(commonResponse.code, commonResponse.data, commonResponse.message);
    }
  }

  async post<T>({ url, data, contentType = ContentType.JSON, hasLoadingUI = true }: HTTPMethodPropsType): Promise<T> {
    if (process.env.REACT_APP_IS_MOCK && stringToBoolean(process.env.REACT_APP_IS_MOCK)) {
      contentType = ContentType.JSON;
    }

    const options = {
      headers: { 'Content-Type': contentType },
    };

    if (contentType === ContentType.MULTIPART) {
      data = convertToFormData(data);
    }

    if (contentType === ContentType.URLENCODED) {
      data = qs.stringify(data);
    }

    hasLoadingUI && showLoadingUI();

    const axiosResponse: AxiosResponse<CommonResponse<T>> = await axiosInstance
      .post<CommonResponse<T>, AxiosResponse<CommonResponse<T>>>(`${url}`, data, options)
      .catch(error => {
        if (
          error.response &&
          error.response.status &&
          this.displayExceptionUsingStatusCode.includes(error.response.status)
        ) {
          throw new HttpError(error.response.status);
        } else {
          throw new Error(error.message);
        }
      })
      .finally(() => {
        hasLoadingUI && unShowLoadingUI();
      });

    const commonResponse: CommonResponse<T> = axiosResponse.data;
    if (commonResponse.code === CommonResponseCode.SUCCESS) {
      return commonResponse.data;
    } else {
      if (commonResponse.code === AuthenticationExceptionCode.NOT_AUTHENTICATED) {
        removeSignIn();
      }
      throw new HttpError(commonResponse.code, commonResponse.data, commonResponse.message);
    }
  }

  async put<T>({ url, data, contentType = ContentType.JSON, hasLoadingUI = true }: HTTPMethodPropsType): Promise<T> {
    const options = {
      headers: { 'Content-Type': contentType },
    };

    if (contentType === ContentType.MULTIPART) {
      data = convertToFormData(data);
    }

    if (contentType === ContentType.URLENCODED) {
      data = qs.stringify(data);
    }

    hasLoadingUI && showLoadingUI();

    const axiosResponse: AxiosResponse<CommonResponse<T>> = await axiosInstance
      .put<CommonResponse<T>, AxiosResponse<CommonResponse<T>>>(`${url}`, data, options)
      .catch(error => {
        if (
          error.response &&
          error.response.status &&
          this.displayExceptionUsingStatusCode.includes(error.response.status)
        ) {
          throw new HttpError(error.response.status);
        } else {
          throw new Error(error.message);
        }
      })
      .finally(() => {
        hasLoadingUI && unShowLoadingUI();
      });

    const commonResponse: CommonResponse<T> = axiosResponse.data;
    if (commonResponse.code === CommonResponseCode.SUCCESS) {
      return commonResponse.data;
    } else {
      if (commonResponse.code === AuthenticationExceptionCode.NOT_AUTHENTICATED) {
        removeSignIn();
      }
      throw new HttpError(commonResponse.code, commonResponse.data, commonResponse.message);
    }
  }

  async delete<T>({ url, data, hasLoadingUI = true }: HTTPMethodPropsType): Promise<T> {
    hasLoadingUI && showLoadingUI();

    const axiosResponse: AxiosResponse<CommonResponse<T>> = await axiosInstance.delete<
      CommonResponse<T>,
      AxiosResponse<CommonResponse<T>>
    >(`${url}`, { data });

    hasLoadingUI && unShowLoadingUI();

    const commonResponse: CommonResponse<T> = axiosResponse.data;
    if (commonResponse.code === CommonResponseCode.SUCCESS) {
      return commonResponse.data;
    } else {
      if (commonResponse.code === AuthenticationExceptionCode.NOT_AUTHENTICATED) {
        removeSignIn();
      }
      throw new HttpError(commonResponse.code, commonResponse.data, commonResponse.message);
    }
  }
}

export default new http();
