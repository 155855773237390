import type { BigNumber } from 'utils/bigNumber';

export interface CalculateForMultipleAnchorLoanRequestVO {
  maxLtvRatio: number;
  totalRequestedAmount: string;
  expectedTotalInterestAmount: string;
  totalNetDisbursementAmount: string;
  totalInterestRate: number;
}

export interface CalculateForMultipleAnchorLoanRequestVOModel extends CalculateForMultipleAnchorLoanRequestVO {
  totalRequestedAmount: BigNumber;
  expectedTotalInterestAmount: BigNumber;
  totalNetDisbursementAmount: BigNumber;
}

export function formattingToCalculateForMultipleAnchorLoanRequestVOModel(
  data: CalculateForMultipleAnchorLoanRequestVO,
): CalculateForMultipleAnchorLoanRequestVOModel {
  return {
    ...data,
  };
}
