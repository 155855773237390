import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ANCHOR_AGREEMENT_STATUS, AUTHORITY_TYPE, COLLATERAL_TYPE, COMMON_APPROVAL_TYPE, LOAN_OPTION } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AccountTransactionVOModel } from 'models/vo/AccountTransactionVO';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import {
  requestSystemAccountTransactionList,
  requestSystemAccountTransactionSettlementAccountBalance,
} from 'utils/http/api/system/account-transactions';
import { requestSystemAnchorAgreementData } from 'utils/http/api/system/anchor-agreements';
import { requestSystemWaitingAnchorAgreementList } from 'utils/http/api/system/waiting-anchor-agreements';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const onClickCommonApprovalType = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const ANCHOR_USER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
    },
    {
      headerText: t('text:Name'),
    },
    {
      headerText: t('text:Mobile'),
    },
    {
      headerText: t('text:Email'),
    },
    {
      headerText: t('text:Authority'),
    },
  ];
  const ACCOUNT_TRANSACTION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Transaction_Time'),
    },
    {
      headerText: t('text:Memo'),
    },
    {
      headerText: t('text:Deposit'),
    },
    {
      headerText: t('text:Withdrawal'),
    },
  ];
  const INFORMATION_EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Time'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Branch_Code'),
      colWidths: 150,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickCommonApprovalType,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 200,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 200,
    },
  ];

  return {
    mounted,
    modal,
    t,
    ANCHOR_USER_TABLE_HEADERS,
    ACCOUNT_TRANSACTION_TABLE_HEADERS,
    INFORMATION_EDIT_HISTORY_TABLE_HEADERS,
  };
};

function SystemMonitorAnchorAgreementRegisteredDetail(): JSX.Element {
  const {
    mounted,
    modal,
    t,
    ANCHOR_USER_TABLE_HEADERS,
    ACCOUNT_TRANSACTION_TABLE_HEADERS,
    INFORMATION_EDIT_HISTORY_TABLE_HEADERS,
  } = getConstants();
  const { anchorAgreementId } = useParams() as any;
  const [fromDate] = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T');
  const [toDate] = new Date().toISOString().split('T');
  const [locationState, errorHandlerOfLocationState] = useLocationState<{ supportedCollateralType: COLLATERAL_TYPE }>([
    'supportedCollateralType',
  ]);
  const { supportedCollateralType } = locationState;
  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementDetailVOModel>();
  const [settlementAccountBalance, setSettlementAccountBalance] = useState<number>(0);
  const [accountTransactionPage, setAccountTransactionPage] = useState<Pageable<AccountTransactionVOModel[]>>();
  const [waitingAnchorAgreementPage, setWaitingAnchorAgreementPage] =
    useState<Pageable<WaitingAnchorAgreementVOModel[]>>();
  const [divisionName, setDivisionName] = useState('');
  const [divisionRegistrable, setDivisionRegistrable] = useState(false);
  const { pageable: accountTransactionPageable, setPageable: setAccountTransactionPageable } = usePageable();
  const { pageable: waitingAnchorAgreementPageable, setPageable: setWaitingAnchorAgreementPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [anchorAgreementData, accountTransactionPage, waitingAnchorAgreementPage, settlementAccountBalance] =
        await Promise.all([
          requestSystemAnchorAgreementData(anchorAgreementId),
          requestSystemAccountTransactionList(
            accountTransactionPageable.currentPage,
            accountTransactionPageable.sizePerPage,
            anchorAgreementId,
            {
              fromDate,
              toDate,
            },
          ),
          requestSystemWaitingAnchorAgreementList(
            waitingAnchorAgreementPageable.currentPage,
            waitingAnchorAgreementPageable.sizePerPage,
            {
              anchorAgreementId: anchorAgreementId,
            },
          ),
          requestSystemAccountTransactionSettlementAccountBalance(anchorAgreementId),
        ]);

      const { financierId, divisionName } = anchorAgreementData;
      const { divisionRegistrable } = await requestFinancierSettingData(financierId);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementData(anchorAgreementData);
        setAccountTransactionPage(accountTransactionPage);
        setAccountTransactionPageable(accountTransactionPage);
        setWaitingAnchorAgreementPage(waitingAnchorAgreementPage);
        setWaitingAnchorAgreementPageable(waitingAnchorAgreementPage);
        setSettlementAccountBalance(settlementAccountBalance);
        setDivisionName(divisionName);
        setDivisionRegistrable(divisionRegistrable);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const accountTransactionPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const accountTransactionPage = await requestSystemAccountTransactionList(
        pageNumber,
        rowCount,
        anchorAgreementId,
        {
          fromDate,
          toDate,
        },
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAccountTransactionPage(accountTransactionPage);
        setAccountTransactionPageable(accountTransactionPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const informationEditHistoryPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const waitingAnchorAgreementPage = await requestSystemWaitingAnchorAgreementList(pageNumber, rowCount, {
        anchorAgreementId: anchorAgreementId,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorAgreementPage(waitingAnchorAgreementPage);
        setWaitingAnchorAgreementPageable(waitingAnchorAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getProgramType = (collateralType?: COLLATERAL_TYPE): string => {
    switch (collateralType) {
      case COLLATERAL_TYPE.AR:
        return t('text:Vendor_Finance');
      case COLLATERAL_TYPE.INVOICE:
        return t('text:Dealer_Finance');
      default:
        return '';
    }
  };

  const renderAdminInformationTable = (): JSX.Element[] | undefined => {
    const getAuthorityTypeText = (authorityType: AUTHORITY_TYPE): string => {
      switch (authorityType) {
        case AUTHORITY_TYPE.ADMIN:
          return t('text:Admin');
        case AUTHORITY_TYPE.AUTHORIZER:
          return t('text:Authorizer');
        case AUTHORITY_TYPE.OPERATOR:
          return t('text:Operator');
        default:
          return '';
      }
    };

    return anchorAgreementData?.anchorUserList.map((item, index) => (
      <Tr key={index}>
        <Td data={item.bankUserId} />
        <Td data={item.name} />
        <Td data={item.mobile} />
        <Td data={item.email} />
        <Td data={getAuthorityTypeText(item.authorityType)} />
      </Tr>
    ));
  };

  const renderAccountTransactionTableBody = (): JSX.Element[] | undefined => {
    return accountTransactionPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.transactionDateTime} format="datetime" />
        <Td data={item.memo} />
        <Td data={item.depositAmount} format="number" />
        <Td data={item.withdrawalAmount} format="number" />
      </Tr>
    ));
  };

  const renderInformationEditHistoryTableBody = (): JSX.Element[] | undefined => {
    const getApprovalTypeText = (approvalType: COMMON_APPROVAL_TYPE): string => {
      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Request_Cancelled');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Registered');
        default:
          return '';
      }
    };

    return waitingAnchorAgreementPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.updatedDateTime} format="datetime" />
        <Td data={item.contractNo} />
        <Td data={item.expiryDate} format="datetime" />
        <Td data={item.branchCode} />
        <Td
          className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
          data={getApprovalTypeText(item.approvalType)}
        />
        <Td data={`${item.operatorUserLoginId} / ${item.operatorUserName}`} />
        <Td data={`${item.authorizerUserLoginId} / ${item.authorizerUserName}`} />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_Master_Agreement_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Agreement_Information')} />
        <FormBorder>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_INFORMATION')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Client_Code')} value={anchorAgreementData?.financierClientCode} />
              <FormValue label={t('text:Anchor_Name')} value={anchorAgreementData?.financierClientName} />
            </div>
            {divisionRegistrable && (
              <div className="row">
                <FormValue label={t('text:Division_Code')} value={anchorAgreementData?.division} />
                <FormValue label={t('text:Division_Name')} value={divisionName} />
              </div>
            )}
          </FormContents>
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:ANCHOR_MASTER_AGREEMENT_INFORMATION')}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Anchor_Master_Agreement_Number')} value={anchorAgreementData?.contractNo} />
              <FormValue label={t('text:Currency')} col={3} value={anchorAgreementData?.currencyType} />
              <FormValue
                label={t('text:Program_Type')}
                col={3}
                value={getProgramType(anchorAgreementData?.collateralType)}
              />
            </div>
            <div className="row">
              <FormValue
                label={isInvoice ? `${t('text:Financing_to_Invoice_Ratio')} (%)` : t('text:Financing_LTV(%)')}
                value={anchorAgreementData?.maxLtvRatio}
              />
              <FormValue
                label={t('text:Effective_Date')}
                col={3}
                value={anchorAgreementData?.startDate}
                format="date"
              />
              <FormValue
                label={t('text:Expiration_Date')}
                col={3}
                value={anchorAgreementData?.expiryDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_the_partners_automatic_financing_option?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={anchorAgreementData?.loanOption !== LOAN_OPTION.NONE}
                  disabled
                >
                  {anchorAgreementData?.loanOption !== LOAN_OPTION.NONE && (
                    <>
                      <span style={{ color: '#ada8a8', fontWeight: 'normal' }} className="me-1">
                        {t('text:Allow_Financiers_Automatic_Approval?')}
                      </span>
                      {`( ${t(`code:loan-option.${anchorAgreementData?.loanOption}`)} )`}
                    </>
                  )}
                </FormRadio>
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={anchorAgreementData?.loanOption === LOAN_OPTION.NONE}
                  disabled
                />
              </FormRadioWrap>
              <FormRadioWrap label={t('text:Allow_the_payment_date_to_exceed_the_agreement_expiration_date?')}>
                <FormRadio
                  label={t('text:Yes')}
                  defaultChecked={anchorAgreementData?.settlementDateOverAllowable}
                  disabled
                />
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={!anchorAgreementData?.settlementDateOverAllowable}
                  disabled
                />
              </FormRadioWrap>
            </div>
            {isInvoice && (
              <div className="row">
                <FormRadioWrap label={t('text:Allow_registration_of_duplicate_reference_numbers?')}>
                  <FormRadio
                    label={t('text:Yes')}
                    defaultChecked={!anchorAgreementData?.referenceNumberDuplicationCheck}
                    disabled
                  />
                  <FormRadio
                    label={t('text:No')}
                    defaultChecked={anchorAgreementData?.referenceNumberDuplicationCheck}
                    disabled
                  />
                </FormRadioWrap>
                <FormRadioWrap label={t('text:Require_BOE_number_when_registering_invoices?')}>
                  <FormRadio
                    label={t('text:Yes')}
                    defaultChecked={anchorAgreementData?.billOfExchangeNoRequired}
                    disabled
                  />
                  <FormRadio
                    label={t('text:No')}
                    defaultChecked={!anchorAgreementData?.billOfExchangeNoRequired}
                    disabled
                  />
                </FormRadioWrap>
              </div>
            )}
            <div className="row">
              <FormRadioWrap label={t('text:Agreement_Suspension')}>
                <FormRadio
                  label={t('text:Not_Suspended')}
                  defaultChecked={anchorAgreementData?.anchorAgreementStatus === ANCHOR_AGREEMENT_STATUS.ACTIVATED}
                  disabled
                />
                <FormRadio
                  label={t('text:Suspended')}
                  defaultChecked={anchorAgreementData?.anchorAgreementStatus === ANCHOR_AGREEMENT_STATUS.SUSPENDED}
                  disabled
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormValue
                label={t('text:Partner_Identification_Type')}
                value={t(`code:dealer-identifier-type.${anchorAgreementData?.dealerIdentifierType}`)}
                format="code"
              />
            </div>
          </FormContents>
          {isAr && (
            <>
              <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:AR_FINANCING_INFORMATION')} />
              <FormContents>
                <div className="row">
                  <FormRadioWrap label={t('text:Automatic_Settlement')}>
                    <FormRadio
                      label={t('text:Yes_(Co-operation_&_Payment)')}
                      defaultChecked={anchorAgreementData?.paymentSupport}
                      disabled
                    />
                    <FormRadio
                      label={t('text:No_(Co-operation)')}
                      defaultChecked={!anchorAgreementData?.paymentSupport}
                      disabled
                    />
                  </FormRadioWrap>
                </div>
                <div className="row">
                  <FormRadioWrap label={t('text:Limit_of_AR_Issuance')}>
                    <FormRadio
                      label={t('text:Applied')}
                      defaultChecked={anchorAgreementData?.collateralIssuedLimitCheck}
                      disabled
                    >
                      {anchorAgreementData?.collateralIssuedLimitCheck &&
                        `( ${t('format:number', { value: anchorAgreementData?.collateralIssuedLimitAmount })} )`}
                    </FormRadio>
                    <FormRadio
                      label={t('text:Not_Applied')}
                      defaultChecked={!anchorAgreementData?.collateralIssuedLimitCheck}
                      disabled
                    />
                  </FormRadioWrap>
                  <FormRadioWrap label={t('text:Financing_Limit_of_Anchor')}>
                    <FormRadio label={t('text:Applied')} defaultChecked={anchorAgreementData?.loanLimitCheck} disabled>
                      {anchorAgreementData?.loanLimitCheck &&
                        `( ${t('format:number', { value: anchorAgreementData?.loanLimitAmount })} )`}
                    </FormRadio>
                    <FormRadio
                      label={t('text:Not_Applied')}
                      defaultChecked={!anchorAgreementData?.loanLimitCheck}
                      disabled
                    />
                  </FormRadioWrap>
                </div>
              </FormContents>
            </>
          )}
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:DESIGNATED_ACCOUNT')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Bank_Name')} value={anchorAgreementData?.settlementAccountFinancierName} />
              <FormValue label={t('text:Branch_Name')} value={anchorAgreementData?.settlementAccountBranchName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Bank_Account_Number')} value={anchorAgreementData?.settlementAccount} />
              <FormValue label={t('text:Bank_Account_Holder')} value={anchorAgreementData?.settlementAccountOwner} />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:RESPONSIBLE_BRANCH')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Branch_Code')} value={anchorAgreementData?.branchCode} />
              <FormValue label={t('text:Branch_Name')} value={anchorAgreementData?.branchName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Telephone')} value={anchorAgreementData?.branchTelephone} />
              <FormValue label={t('text:Fax')} value={anchorAgreementData?.branchFax} />
            </div>
            <div className="row">
              <FormValue
                col={12}
                label={t('text:Registered_Office_Address')}
                value={anchorAgreementData?.branchAddress}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ADMIN_INFORMATION')} />
          <FormContents>
            <div className="row">
              <div className="col-12">
                <TableBorder>
                  <TableHeader header={ANCHOR_USER_TABLE_HEADERS} />
                  <TableBody numOfCol={ANCHOR_USER_TABLE_HEADERS.length}>{renderAdminInformationTable()}</TableBody>
                </TableBorder>
              </div>
            </div>
          </FormContents>
        </FormBorder>
      </div>

      {/* TODO :: Account Transaction table condition not using? */}
      {/* START */}
      <div className="content-area">
        <SectionTitle title={t('text:Designated_Account_Transaction_History')} />
        <div className="account-form mb-3">
          <div className="account-balance">
            <div className="account-balance-label">{t('text:Designated_Account_Balance')}</div>
            <div className="account-balance-value">{t('format:number', { value: settlementAccountBalance })}</div>
          </div>
          <div className="bg-pattern" />
        </div>
        <TableBorder>
          <TableHeader header={ACCOUNT_TRANSACTION_TABLE_HEADERS} />
          <TableBody numOfCol={ACCOUNT_TRANSACTION_TABLE_HEADERS.length}>
            {renderAccountTransactionTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={accountTransactionPageable} paginate={accountTransactionPaginate} showSelectRow={false} />
      </div>
      {/* END */}
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={INFORMATION_EDIT_HISTORY_TABLE_HEADERS} />
          <TableBody numOfCol={INFORMATION_EDIT_HISTORY_TABLE_HEADERS.length}>
            {renderInformationEditHistoryTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={waitingAnchorAgreementPageable} paginate={informationEditHistoryPaginate} />
      </div>
    </>
  );
}

export default SystemMonitorAnchorAgreementRegisteredDetail;
