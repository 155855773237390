import { useTranslation } from 'react-i18next';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { MultipleLoanRequestVOModel } from 'models/vo/MultipleLoanRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface DealerBulkFinancingConfirmationListTableProps {
  bulkFinancingConfirmationListData: MultipleLoanRequestVOModel[] | undefined;
  pageable: PageableType;
  paginate: (pageNumber: number, sizePerPage: number) => Promise<void>;
}

function DealerBulkFinancingConfirmationListTable({
  bulkFinancingConfirmationListData,
  pageable,
  paginate,
}: DealerBulkFinancingConfirmationListTableProps) {
  const { t } = useTranslation(['format']);

  const modal = useModal();

  const onClickFinancingApprovalStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(MULTIPLE_LOAN_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="MULTIPLE_LOAN_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const BULK_CONFIRMATION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement'),
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Total_Amount_of_AR'),
    },
    {
      headerText: t('text:Total_Requested_Financing_Amount'),
    },
    {
      headerText: t('text:Discount_Rate_APR'),
    },
    {
      headerText: t('text:Total_Number_of_Requested_AR'),
    },
    {
      headerText: t('text:Bulk_Financing_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickFinancingApprovalStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Select_Financing')} />
      <TableBorder>
        <TableHeader header={BULK_CONFIRMATION_TABLE_HEADERS} />
        <TableBody numOfCol={BULK_CONFIRMATION_TABLE_HEADERS.length}>
          {bulkFinancingConfirmationListData?.map((item: MultipleLoanRequestVOModel, index: number) => (
            <Tr key={index}>
              <Td data={item.financierName} />
              <Td data={item.anchorClientName} />
              <Td data={item.anchorAgreementContractNo} />
              <Td data={item.dealerAgreementContractNo} />
              <Td data={item.currencyType} />
              <Td data={item.totalArAmount} format="number" />
              <Td data={item.totalRequestAmount} format="number" />
              <Td data={item.averageInterestRate} format="percent" />
              <Td data={item.totalArCount} />
              <Td
                data={t(`code:multiple-loan-request-status.${item.multipleLoanRequestStatus}`)}
                className={getStatusTextClass('MULTIPLE_LOAN_REQUEST_STATUS', item.multipleLoanRequestStatus)}
                format="code"
              />
              <TdLink
                path={ROUTES_DE.MANAGE_FINANCING.BULK_CONFIRMATION_DETAIL_BUILD_PATH(item.multipleLoanRequestId)}
              />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default DealerBulkFinancingConfirmationListTable;
