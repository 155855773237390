import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { RelatedAnchorClientLimitInfoVOModel } from 'models/vo/RelatedAnchorClientLimitInfoVO';
import { requestFinancierAnchorAgreementLimitInfoRelatedDealerAgreement } from 'utils/http/api/financier/dealer-agreements';
import useModal from 'utils/modal/useModal';

type AnchorClientLimitStateType = {
  anchorClientLimitInfo: RelatedAnchorClientLimitInfoVOModel;
  fetchAnchorClientLimitInfo: (dealerAgreementId: number) => Promise<void>;
};

const AnchorClientLimitContext = createContext<AnchorClientLimitStateType | null>(null);

export const useAnchorClientLimitContext = () => {
  const context = useContext(AnchorClientLimitContext);
  if (!context) {
    throw Error('useAnchorClientLimitContext should be used within AnchorClientLimitContext.Provider');
  }

  return context;
};

type AnchorClientLimitProviderPropsType = {
  children: ReactNode;
};

const AnchorClientLimitProvider = ({ children }: AnchorClientLimitProviderPropsType) => {
  const { show: showModal } = useModal();

  const [anchorClientLimitInfo, setAnchorClientLimitInfo] = useState<RelatedAnchorClientLimitInfoVOModel>(
    {} as RelatedAnchorClientLimitInfoVOModel,
  );

  const fetchAnchorClientLimitInfo = useCallback(
    async (dealerAgreementId: number) => {
      try {
        const anchorLimitInfo = await requestFinancierAnchorAgreementLimitInfoRelatedDealerAgreement(dealerAgreementId);
        setAnchorClientLimitInfo(anchorLimitInfo);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal],
  );

  const value = useMemo(
    () => ({
      anchorClientLimitInfo,
      fetchAnchorClientLimitInfo,
    }),
    [anchorClientLimitInfo, fetchAnchorClientLimitInfo],
  );

  return <AnchorClientLimitContext.Provider value={value}>{children}</AnchorClientLimitContext.Provider>;
};

export default AnchorClientLimitProvider;
