import type Pageable from 'models/Pageable';
import type { InvoiceSummaryVO, InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import { formattingToInvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemInvoiceSummaryListRequest } from './request';

export async function requestSystemInvoiceSummariesList(
  pageNumber: number,
  rowCount: number,
  data: SystemInvoiceSummaryListRequest,
): Promise<Pageable<InvoiceSummaryVOModel[]>> {
  const response = await http.get<Pageable<InvoiceSummaryVO[]>>({
    url: API_SY.INVOICE_SUMMARIES.INVOICE_SUMMARY_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  const invoiceSummaryPageData = response.content.map(invoiceSummary =>
    formattingToInvoiceSummaryVOModel(invoiceSummary),
  );

  return {
    ...response,
    content: invoiceSummaryPageData,
  };
}

// system 송장 상세 조회
export async function requestSystemInvoiceSummaryDetail(invoiceSummaryId: number): Promise<InvoiceSummaryVOModel> {
  const response = await http.get<InvoiceSummaryVO>({
    url: API_SY.INVOICE_SUMMARIES.INVOICE_SUMMARY_DETAIL(invoiceSummaryId),
  });

  return formattingToInvoiceSummaryVOModel(response);
}
