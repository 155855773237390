import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Accordion from 'components/stateless/Accordion/Accordion';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import {
  AUTHORITY_TYPE,
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  DEALER_AGREEMENT_STATUS,
  DEALER_AGREEMENT_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
  LOAN_TERM_TYPE,
} from 'enums';
import useMounted from 'hooks/useMounted';
import type { WaitingDealerAgreementDetailVOModel } from 'models/vo/WaitingDealerAgreementDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { requestSystemWaitingDealerAgreementDetail } from 'utils/http/api/system/waiting-dealer-agreements';
import useModal from 'utils/modal/useModal';

const getConstant = () => {
  const modal = useModal();
  const mounted = useMounted();
  const { t } = useTranslation(['format']);

  const ANCHOR_USER_INFO_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Mobile'),
      colWidths: 100,
    },
    {
      headerText: t('text:Email'),
      colWidths: 100,
    },
    {
      headerText: t('text:Authority'),
      colWidths: 100,
    },
  ];

  return {
    mounted,
    modal,
    t,
    ANCHOR_USER_INFO_TABLE_HEADERS,
  };
};

function SystemMonitorPartnerAgreementWaitingDetail(): JSX.Element {
  const waitingDealerAgreementId = (useParams() as any).id;

  const { mounted, modal, t, ANCHOR_USER_INFO_TABLE_HEADERS } = getConstant();

  const [waitingPartnerAgreementInfo, setwaitingPartnerAgreementInfo] = useState<WaitingDealerAgreementDetailVOModel>();

  useEffect(() => {
    if (mounted) {
      fetchWaitingPartnerAgreementInfo();
    }
  }, [mounted]);

  const fetchWaitingPartnerAgreementInfo = async () => {
    try {
      const waitingPartnerAgreementInfomation = await requestSystemWaitingDealerAgreementDetail(
        waitingDealerAgreementId,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setwaitingPartnerAgreementInfo(waitingPartnerAgreementInfomation);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderAccordionHeader = (): JSX.Element => {
    const renderStatusBadgeText = () => {
      switch (waitingPartnerAgreementInfo?.approvalType) {
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Registered');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Request_Cancelled');
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested');
      }
    };
    const getCurrentActorInfoText = () => {
      switch (waitingPartnerAgreementInfo?.approvalType) {
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return `${t('text:Authorizer')} (${waitingPartnerAgreementInfo?.authorizerUserName} / ${
            waitingPartnerAgreementInfo?.authorizerUserLoginId
          })`;
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return `${t('text:Operator')} (${waitingPartnerAgreementInfo?.operatorUserName} / ${
            waitingPartnerAgreementInfo?.operatorUserLoginId
          })`;
      }
    };

    const getStatusChangeTimeText = () => {
      switch (waitingPartnerAgreementInfo?.approvalType) {
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Registered_Time');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted_Time');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Request_cancelled_time');
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested_Time');
      }
    };

    return (
      <div className="accordion-information-form__head">
        <div className={getStatusBadgeClass('COMMON_APPROVAL_TYPE', waitingPartnerAgreementInfo?.approvalType)}>
          {renderStatusBadgeText()}
        </div>
        <div className="accordion-information-form__head--text">
          <div className="fw-bold">{getCurrentActorInfoText()}</div>
          {waitingPartnerAgreementInfo?.financierName}
        </div>
        <div className="accordion-information-form__head--text">
          <div className="fw-bold">{getStatusChangeTimeText()}</div>
          {t('format:datetime', {
            value: waitingPartnerAgreementInfo?.updatedDateTime,
            key: 'datetime',
          })}
        </div>
      </div>
    );
  };

  const renderTermSpread = (): JSX.Element => {
    const useTermSpread = (waitingPartnerAgreementInfo?.termSpreadList?.length ?? 0) > 0;

    const renderTermSpreadRow = () => {
      const termSpreadList = waitingPartnerAgreementInfo?.termSpreadList;

      return termSpreadList?.map((item, index, array) => {
        return (
          <tr key={`termspreadrow-${index}`}>
            <td>
              <div className="scope-box">
                <div className="text-start">
                  <div className="spread-xs-input">
                    {t('format:number', {
                      value: item.minimumDateRange,
                    })}
                  </div>
                </div>
                <div className="angle-sign">&lt;</div>
                <div>
                  <strong>T</strong>
                </div>
                {(waitingPartnerAgreementInfo?.termSpreadList.length || 0) > index + 1 && (
                  <>
                    <div className="angle-sign">≤</div>
                    <div />
                    <div className="text-start">
                      <div className="spread-xs-input">
                        {t('format:number', {
                          value: array[index + 1] ? array[index + 1].minimumDateRange : '',
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </td>
            <td>
              <div className="spread-xs-input me-1">
                {t('format:number', {
                  value: item.termSpreadRate,
                })}
              </div>{' '}
              %
            </td>
          </tr>
        );
      });
    };

    return (
      <div className="row">
        <FormRadioWrap label={t('text:Term_Spread')} col={12}>
          <FormRadio label={t('text:Applied')} defaultChecked={useTermSpread} disabled />
          <FormRadio label={t('text:Not_Applied')} defaultChecked={!useTermSpread} disabled />
          {useTermSpread && (
            <table className="table-border spread-form text-center sub200-bg">
              <thead>
                <tr>
                  <th scope="col">{t('text:Term')}</th>
                  <th scope="col">{t('text:Term_Spread')} (%)</th>
                </tr>
              </thead>
              <tbody className="bg-white">{renderTermSpreadRow()}</tbody>
            </table>
          )}
        </FormRadioWrap>
      </div>
    );
  };

  const renderAnchorUserInformationTable = (): JSX.Element[] | JSX.Element | undefined => {
    const getAuthorityTypeText = (authorityType: AUTHORITY_TYPE): string => {
      switch (authorityType) {
        case AUTHORITY_TYPE.ADMIN:
          return t('text:Admin');
        case AUTHORITY_TYPE.AUTHORIZER:
          return t('text:Authorizer');
        case AUTHORITY_TYPE.OPERATOR:
          return t('text:Operator');
        default:
          return '';
      }
    };

    return waitingPartnerAgreementInfo?.anchorUserList?.map((item, index: number) => (
      <Tr key={index}>
        <Td data={item?.bankUserId} />
        <Td data={item?.name} />
        <Td data={item?.mobile} />
        <Td data={item?.email} />
        <Td data={getAuthorityTypeText(item?.authorityType)} />
      </Tr>
    ));
  };

  const renderAgreementType = (collateralType: COLLATERAL_TYPE | undefined): string => {
    switch (collateralType) {
      case COLLATERAL_TYPE.AR:
        return t('text:Vendor_Finance');
      case COLLATERAL_TYPE.INVOICE:
        return t('text:Dealer_Finance');
      default:
        return '-';
    }
  };

  const loanLimitTypeText = (loanLimitType: LOAN_LIMIT_CHECK_TYPE | undefined) => {
    switch (loanLimitType) {
      case LOAN_LIMIT_CHECK_TYPE.BOTH:
        return t('text:Both');
      case LOAN_LIMIT_CHECK_TYPE.INVOICE:
        return t('text:Invoice_Registration');
      case LOAN_LIMIT_CHECK_TYPE.LOAN:
        return t('text:Loan_Application');
      case LOAN_LIMIT_CHECK_TYPE.NONE:
        return t('text:None');
      default:
        return '-';
    }
  };

  const loanTermText = (loanTerm: LOAN_TERM_TYPE | undefined) => {
    switch (loanTerm) {
      case LOAN_TERM_TYPE.DAILY:
        return t('text:days');
      case LOAN_TERM_TYPE.MONTHLY:
        return t('text:month');
      default:
        return '-';
    }
  };

  const renderPartnerAgreementDetails = (): JSX.Element | null => {
    if (!waitingPartnerAgreementInfo) return null;

    const {
      dealerAgreementType,
      basisInterestType,
      loanTermType,
      maxExtensibleLoanCount,
      collateralType,
      interestRepaymentType,
      loanLimitCheckType,
      loanLimitAmount,
    } = waitingPartnerAgreementInfo ?? ({} as WaitingDealerAgreementDetailVOModel);

    const isDealerAgreementTypeWithAnchor = dealerAgreementType === DEALER_AGREEMENT_TYPE.WITH_ANCHOR;
    const isBasisInterestTypeFixed = basisInterestType === BASIS_INTEREST_TYPE.FIXED;
    const isRangeOfLoanTerm = loanTermType === LOAN_TERM_TYPE.RANGE;
    const isMaturityExtension = (maxExtensibleLoanCount ?? 0) > 0;
    const isInvoice = collateralType === COLLATERAL_TYPE.INVOICE;
    const isInterestRepaymentTypeMonthly = interestRepaymentType === INTEREST_REPAYMENT_TYPE.MONTHLY;
    const financingLimitValue =
      loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE && loanLimitAmount === '0' ? ' ' : loanLimitAmount;

    return (
      <FormBorder>
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:PARTNER_INFORMATION')} />
        <FormContents>
          <div className="row">
            <FormValue label={t('text:Client_Code')} value={waitingPartnerAgreementInfo?.dealerClientCode} />
            <FormValue col={3} label={t('text:Partner_Name')} value={waitingPartnerAgreementInfo?.dealerClientName} />
            <FormValue col={3} label={t('text:Tax_Code')} value={waitingPartnerAgreementInfo?.dealerClientTaxCode} />
          </div>
        </FormContents>
        <FormSubtitle
          backGroundType={BackGroundType.DarkGray}
          title={t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT_INFORMATION')}
        />
        <FormContents>
          <div className="row">
            <FormRadioWrap label={t('text:Is_there_an_associated_Anchor_Master_Agreement?')}>
              <FormRadio label={t('text:Yes')} defaultChecked={isDealerAgreementTypeWithAnchor} disabled />
              <FormRadio
                label={t('text:No')}
                defaultChecked={
                  waitingPartnerAgreementInfo?.dealerAgreementType === DEALER_AGREEMENT_TYPE.WITHOUT_ANCHOR
                }
                disabled
              />
            </FormRadioWrap>
          </div>
          {isDealerAgreementTypeWithAnchor && (
            <>
              <div className="row">
                <FormValue
                  label={t('text:Anchor_Master_Agreement_Number')}
                  value={waitingPartnerAgreementInfo?.mainContractNo}
                />
                <FormValue label={t('text:Anchor_Name')} value={waitingPartnerAgreementInfo?.anchorClientName} />
              </div>
              <div className="row">
                <FormValue
                  label={t('text:Effective_Date')}
                  value={waitingPartnerAgreementInfo?.anchorAgreementStartDate}
                  format="date"
                />
                <FormValue
                  label={t('text:Expiration_Date')}
                  value={waitingPartnerAgreementInfo?.anchorAgreementExpiryDate}
                  format="date"
                />
              </div>
            </>
          )}
        </FormContents>
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:PARTNER_MASTER_AGREEMENT_INFORMATION')} />
        <FormContents>
          <div className="row">
            <FormValue
              label={t('text:Partner_Master_Agreement_Number')}
              value={waitingPartnerAgreementInfo?.contractNo}
            />
            <FormValue
              label={t('text:Partner_Code_of_Anchor')}
              value={waitingPartnerAgreementInfo?.dealerCodeByAnchor}
            />
          </div>
          <div className="row">
            <FormValue col={3} label={t('text:Currency')} value={waitingPartnerAgreementInfo?.currencyType} />
            <FormValue
              col={3}
              label={t('text:Program_Type')}
              value={renderAgreementType(waitingPartnerAgreementInfo?.collateralType)}
              format="code"
            />
            <FormValue
              col={3}
              label={t('text:Effective_Date')}
              value={waitingPartnerAgreementInfo?.startDate}
              format="date"
            />
            <FormValue
              col={3}
              label={t('text:Expiration_Date')}
              value={waitingPartnerAgreementInfo?.expiryDate}
              format="date"
            />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Drawing_Power')}
              value={waitingPartnerAgreementInfo?.drawingPower}
              format="number"
            />
            <FormValue
              col={3}
              label={t('text:Financing_Limit_Checkpoint')}
              value={loanLimitTypeText(waitingPartnerAgreementInfo?.loanLimitCheckType)}
            />
            <FormValue col={3} label={t('text:Financing_Limit')} value={financingLimitValue} format="number" />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Base_Interest_Type')}
              col={3}
              value={waitingPartnerAgreementInfo?.basisInterestType}
            />
            <FormValue label="" col={3} value={waitingPartnerAgreementInfo?.basisInterestBankCode} />
            {isBasisInterestTypeFixed && (
              <FormValue
                col={6}
                label={t('text:Base_Interest_Rate')}
                value={waitingPartnerAgreementInfo?.basisInterestRate}
                format="number"
              />
            )}
            {!isBasisInterestTypeFixed && (
              <FormValue
                col={6}
                label={t('text:Base_Interest_Term')}
                value={waitingPartnerAgreementInfo?.basisInterestTerm}
              />
            )}
          </div>
          <div className="row">
            <FormValue
              col={6}
              label={t('text:Credit_Spread_(%)')}
              value={waitingPartnerAgreementInfo?.creditInterestRate}
              format="number"
            />
            <FormValue
              col={6}
              label={t('text:Interest_Rate_Adjustment_(%)')}
              value={waitingPartnerAgreementInfo?.preferentialInterestRate}
              format="number"
            />
          </div>
          {renderTermSpread()}
          <div className="row">
            <FormRadioWrap label={t('text:Select_Interest_Payment_Method')}>
              <FormRadio
                label={t('text:Prepayment')}
                defaultChecked={waitingPartnerAgreementInfo?.interestRepaymentType === INTEREST_REPAYMENT_TYPE.EARLIER}
                disabled
              />
              <FormRadio
                label={t('text:Postpayment')}
                defaultChecked={waitingPartnerAgreementInfo?.interestRepaymentType === INTEREST_REPAYMENT_TYPE.LATTER}
                disabled
              />
              <FormRadio label={t('text:Monthly')} defaultChecked={isInterestRepaymentTypeMonthly} disabled>
                {isInterestRepaymentTypeMonthly &&
                  `( ${t('text:Date')} : ${waitingPartnerAgreementInfo?.monthlyInterestRepaymentDate} )`}
              </FormRadio>
            </FormRadioWrap>
            <FormRadioWrap label={t('text:Allow_the_partner_to_specify_the_financing_duration?')}>
              <FormRadio label={t('text:Yes')} defaultChecked={isRangeOfLoanTerm} disabled>
                {isRangeOfLoanTerm &&
                  `( ${t('text:Minimum')} : ${t('format:number', {
                    value: waitingPartnerAgreementInfo?.minimumLoanRange,
                  })} ${t('text:Days')} / ${t('text:Maximum')} : ${t('format:number', {
                    value: waitingPartnerAgreementInfo?.maximumLoanRange,
                  })} ${t('text:Days')} )`}
              </FormRadio>
              <FormRadio label={t('text:No')} defaultChecked={!isRangeOfLoanTerm} disabled>
                {!isRangeOfLoanTerm &&
                  `( ${t('text:Fixed_Duration')} : ${t('format:number', {
                    value: waitingPartnerAgreementInfo?.loanTermUnit,
                  })} ${loanTermText(waitingPartnerAgreementInfo?.loanTermType)} )`}
              </FormRadio>
            </FormRadioWrap>
          </div>
          <div className="row">
            <FormRadioWrap label={t('text:Allow_Maturity_Extension?')}>
              <FormRadio label={t('text:Yes')} defaultChecked={isMaturityExtension} disabled>
                {isMaturityExtension &&
                  `( ${t('format:number', {
                    value: waitingPartnerAgreementInfo?.maxExtensibleLoanCount,
                  })} ${t('text:Time(s)')} )`}
              </FormRadio>
              <FormRadio label={t('text:No')} defaultChecked={!isMaturityExtension} disabled />
            </FormRadioWrap>
            <FormRadioWrap label={t('text:Allow_Early_Repayment?')}>
              <FormRadio
                label={t('text:Yes')}
                defaultChecked={waitingPartnerAgreementInfo?.earlyRepaymentAllowable}
                disabled
              />
              <FormRadio
                label={t('text:No')}
                defaultChecked={!waitingPartnerAgreementInfo?.earlyRepaymentAllowable}
                disabled
              />
            </FormRadioWrap>
          </div>
          <div className="row">
            <FormRadioWrap label={t('text:Allow_the_partner_to_initiate_Invoice_or_AR_registration?')}>
              <FormRadio
                label={t('text:Yes')}
                defaultChecked={waitingPartnerAgreementInfo?.dealerUploadInvoiceAllowable}
                disabled
              />
              <FormRadio
                label={t('text:No')}
                defaultChecked={!waitingPartnerAgreementInfo?.dealerUploadInvoiceAllowable}
                disabled
              />
            </FormRadioWrap>
            <FormRadioWrap
              label={t('text:Allow_the_financing_repayment_date_to_exceed_the_agreement_expiration_date?')}
            >
              <FormRadio
                label={t('text:Yes')}
                defaultChecked={waitingPartnerAgreementInfo?.repaymentDateOverAllowable}
                disabled
              />
              <FormRadio
                label={t('text:No')}
                defaultChecked={!waitingPartnerAgreementInfo?.repaymentDateOverAllowable}
                disabled
              />
            </FormRadioWrap>
          </div>
          <div className="row">
            <FormRadioWrap label={t('text:Allow_the_partner_to_see_the_expected_interest_rate?')}>
              <FormRadio
                label={t('text:Yes')}
                defaultChecked={waitingPartnerAgreementInfo?.showExpectedTotalInterestRate}
                disabled
              />
              <FormRadio
                label={t('text:No')}
                defaultChecked={!waitingPartnerAgreementInfo?.showExpectedTotalInterestRate}
                disabled
              />
            </FormRadioWrap>
            <FormRadioWrap label={t('text:Agreement_Suspension')}>
              <FormRadio
                label={t('text:Not_Suspended')}
                defaultChecked={
                  waitingPartnerAgreementInfo?.dealerAgreementStatus === DEALER_AGREEMENT_STATUS.ACTIVATED
                }
                disabled
              />
              <FormRadio
                label={t('text:Suspended')}
                defaultChecked={
                  waitingPartnerAgreementInfo?.dealerAgreementStatus === DEALER_AGREEMENT_STATUS.SUSPENDED
                }
                disabled
              />
            </FormRadioWrap>
          </div>
        </FormContents>
        {isInvoice && (
          <>
            <FormSubtitle title={t('text:AD_HOC_LIMIT')} backGroundType={BackGroundType.DarkGray} />
            <FormContents>
              <div className="row">
                <FormRadioWrap label={t('text:Apply_Ad_hoc_Limit?')}>
                  <FormRadio
                    label={t('text:Applied')}
                    defaultChecked={waitingPartnerAgreementInfo?.adhocLimitAllowable}
                    disabled
                  />
                  <FormRadio
                    label={t('text:Not_Applied')}
                    defaultChecked={!waitingPartnerAgreementInfo?.adhocLimitAllowable}
                    disabled
                  />
                </FormRadioWrap>
              </div>
              <div className="row">
                <FormValue
                  col={6}
                  label={t('text:Ad_hoc_Limit_Amount')}
                  value={waitingPartnerAgreementInfo?.adhocLimitAmount}
                  format="number"
                />
                <FormValue
                  col={3}
                  label={t('text:Start_Date')}
                  value={waitingPartnerAgreementInfo?.adhocLimitStartDate}
                  format="date"
                />
                <FormValue
                  col={3}
                  label={t('text:End_Date')}
                  value={waitingPartnerAgreementInfo?.adhocLimitEndDate}
                  format="date"
                />
              </div>
            </FormContents>
          </>
        )}
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:DISBURSEMENT_ACCOUNT')} />
        <FormContents>
          <div className="row">
            <FormValue
              label={t('text:Bank_Name')}
              value={waitingPartnerAgreementInfo?.settlementAccountFinancierName}
            />
            <FormValue label={t('text:Branch_Name')} value={waitingPartnerAgreementInfo?.settlementAccountBranchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Bank_Account_Number')} value={waitingPartnerAgreementInfo?.settlementAccount} />
            <FormValue
              label={t('text:Bank_Account_Holder')}
              value={waitingPartnerAgreementInfo?.settlementAccountOwner}
            />
          </div>
        </FormContents>
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:REPAYMENT_ACCOUNT')} />
        <FormContents>
          <div className="row">
            <FormValue
              label={t('text:Bank_Name')}
              value={waitingPartnerAgreementInfo?.principalRepaymentAccountFinancierName}
            />
            <FormValue
              label={t('text:Branch_Name')}
              value={waitingPartnerAgreementInfo?.principalRepaymentAccountBranchName}
            />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Bank_Account_Number')}
              value={waitingPartnerAgreementInfo?.principalRepaymentAccount}
            />
            <FormValue
              label={t('text:Bank_Account_Holder')}
              value={waitingPartnerAgreementInfo?.principalRepaymentAccountOwner}
            />
          </div>
        </FormContents>
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:REPAYMENT_ACCOUNT_-_INTEREST')} />
        <FormContents>
          <div className="row">
            <FormValue
              label={t('text:Bank_Name')}
              value={waitingPartnerAgreementInfo?.interestRepaymentAccountFinancierName}
            />
            <FormValue
              label={t('text:Branch_Name')}
              value={waitingPartnerAgreementInfo?.interestRepaymentAccountBranchName}
            />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Bank_Account_Number')}
              value={waitingPartnerAgreementInfo?.interestRepaymentAccount}
            />
            <FormValue
              label={t('text:Bank_Account_Holder')}
              value={waitingPartnerAgreementInfo?.interestRepaymentAccountOwner}
            />
          </div>
        </FormContents>
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:SECURED_DEPOSIT_ACCOUNT')} />
        <FormContents>
          <div className="row">
            <FormValue
              label={t('text:Bank_Name')}
              value={waitingPartnerAgreementInfo?.depositCollateralAccountFinancierName}
            />
            <FormValue
              label={t('text:Branch_Name')}
              value={waitingPartnerAgreementInfo?.depositCollateralAccountBranchName}
            />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Bank_Account_Number')}
              value={waitingPartnerAgreementInfo?.depositCollateralAccount}
            />
            <FormValue
              label={t('text:Bank_Account_Holder')}
              value={waitingPartnerAgreementInfo?.depositCollateralAccountOwner}
            />
          </div>
        </FormContents>
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:RESPONSIBLE_BRANCH')} />
        <FormContents>
          <div className="row">
            <FormValue label={t('text:Branch_Code')} value={waitingPartnerAgreementInfo?.branchCode} />
            <FormValue label={t('text:Branch_Name')} value={waitingPartnerAgreementInfo?.branchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Telephone')} value={waitingPartnerAgreementInfo?.branchTelephone} />
            <FormValue label={t('text:Fax')} value={waitingPartnerAgreementInfo?.branchFax} />
          </div>
          <div className="row">
            <FormValue
              col={12}
              label={t('text:Registered_Office_Address')}
              value={waitingPartnerAgreementInfo?.branchAddress}
            />
          </div>
        </FormContents>
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:AUTHORIZER_INFORMATION')} />
        <FormContents>
          <div className="row">
            <FormValue label={t('text:Name')} value={waitingPartnerAgreementInfo?.authorizerName} />
            <FormValue col={3} label={t('text:Mobile')} value={waitingPartnerAgreementInfo?.authorizerMobile} />
            <FormValue col={3} label={t('text:Email')} value={waitingPartnerAgreementInfo?.authorizerEmail} />
          </div>
        </FormContents>
        {isDealerAgreementTypeWithAnchor && isInvoice && (
          <>
            <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:ANCHOR_USER_INFORMATION')} />
            <FormContents>
              <div className="row">
                <div className="col-12">
                  <TableBorder>
                    <TableHeader header={ANCHOR_USER_INFO_TABLE_HEADERS} />
                    <TableBody numOfCol={ANCHOR_USER_INFO_TABLE_HEADERS.length}>
                      {renderAnchorUserInformationTable()}
                    </TableBody>
                  </TableBorder>
                </div>
              </div>
            </FormContents>
          </>
        )}
      </FormBorder>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Partner_Master_Agreement_Details')} />
      <div className="content-area">
        <Accordion
          id="waiting-agreement-detail"
          className="accordion accordion-information-form"
          defaultExpanded={true}
        >
          <Accordion.Header id="waiting-agreement-detail-header">
            <Accordion.Trigger>{renderAccordionHeader()}</Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="accordion-body p-0">{renderPartnerAgreementDetails()}</Accordion.Content>
        </Accordion>
      </div>
    </>
  );
}

export default SystemMonitorPartnerAgreementWaitingDetail;
