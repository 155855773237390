export interface PotentialPartnerAcquisitionSettingVO {
  financierId: number;
  financierName: string;
  message: string;
  telephone: string;
  fax: string;
  webSite: string;
  email: string;
  address: string;
}

export interface PotentialPartnerAcquisitionSettingVOModel extends PotentialPartnerAcquisitionSettingVO {}

export function formattingToPotentialPartnerAcquisitionSettingVOModel(
  data: PotentialPartnerAcquisitionSettingVO,
): PotentialPartnerAcquisitionSettingVOModel {
  return {
    ...data,
  };
}
