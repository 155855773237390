import type { MouseEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { intersectionBy } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { REQUEST_RESULT } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { FinancierAnchorDealerListRequest } from 'utils/http/api/financier/anchor-dealers/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface FinancierAnchorDealerAssignmentListAssignedTabProps {
  assignedAnchorDealerList: Pageable<AnchorDealerVOModel[]> | undefined;
  pageable: PageableType;
  paginate(page: number, sizePerPage: number): void;
  handleClickSearch: MouseEventHandler<HTMLButtonElement>;
  requestDecline(anchorDealerIds: number[], reason: string): Promise<REQUEST_RESULT>;
}

function FinancierAnchorDealerAssignmentListAssignedTab({
  assignedAnchorDealerList,
  pageable,
  paginate,
  handleClickSearch,
  requestDecline,
}: FinancierAnchorDealerAssignmentListAssignedTabProps) {
  const modal = useModal();
  const { t } = useTranslation();
  const { register, reset } = useFormContext<FinancierAnchorDealerListRequest>();

  const [checkedAnchorDealerData, setCheckedAnchorDealerData] = useState<AnchorDealerVOModel[]>([]);

  useEffect(() => {
    setCheckedAnchorDealerData([]);
  }, [pageable.currentPage]);

  useEffect(() => {
    const newCheckedWaitingAnchorPartner = intersectionBy(
      assignedAnchorDealerList?.content,
      checkedAnchorDealerData,
      'id',
    );
    setCheckedAnchorDealerData(newCheckedWaitingAnchorPartner);
  }, [pageable.sizePerPage]);

  const handleClickAllCheckBox = (e: any) => {
    if (assignedAnchorDealerList?.content === undefined) return;

    if (e.target.checked) {
      const checkedCheckboxes = [...checkedAnchorDealerData];

      assignedAnchorDealerList?.content.forEach(async item => {
        if (checkedAnchorDealerData.map(data => data.id).indexOf(item.id) < 0) {
          checkedCheckboxes.push(item);
        }
      });

      setCheckedAnchorDealerData(checkedCheckboxes);
    } else {
      setCheckedAnchorDealerData([]);
    }
  };

  const onChangeCheckbox = (e: any, item: AnchorDealerVOModel): void => {
    if (e.target.checked) {
      setCheckedAnchorDealerData(prev => {
        const newArr = [...prev];

        newArr.push(item);

        return newArr;
      });
    } else {
      setCheckedAnchorDealerData(prev => {
        const newArr = [...prev];

        newArr.splice(prev.map(data => data.id).indexOf(item.id), 1);

        return newArr;
      });
    }
  };

  const ASSIGNED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      className: 'text-center',
      colWidths: 50,
      hasCheckBox: true,
      handleClickAllCheckBox: handleClickAllCheckBox,
      checkBoxId: String(pageable.currentPage),
      isAllChecked:
        assignedAnchorDealerList?.content.length !== 0 &&
        checkedAnchorDealerData.length === assignedAnchorDealerList?.content.length,
    },
    {
      headerText: t('text:Uploaded_Dealer_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Dealer_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const handleClickDeclineButton = () => {
    let reason: string;
    // 완료 후 체크박스초기화
    const showResultModal = () => {
      setCheckedAnchorDealerData([]);
      modal.show(<h6>{t('text:The_dealer_has_been_declined')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
      });
    };

    modal.show(
      <>
        <h6>
          {t('text:Would_you_like_to_decline_dealer_assignment?')}
          <br />
          {t('text:Please_type_below_the_reason_for_the_decline')}
          <br />
          {t('text:Click_on_Confirm_to_decline_the_assignment')}
        </h6>
        <ReasonModal
          modalId={modal.id}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </>,
      {
        title: t('text:Notice'),
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          const isSuccess = await requestDecline(
            checkedAnchorDealerData.map(data => data.id),
            reason,
          );
          if (isSuccess) {
            showResultModal();
            setCheckedAnchorDealerData([]);
          }
        },
      },
    );
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Uploaded_Dealer_Name')} />
              <SearchInput name="name" ref={register} />
              <SearchLabel label={t('text:Uploaded_Dealer_Tax_Code')} />
              <SearchInput name="taxCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
              <SearchLabel label={t('text:Registered_Office_Address')} />
              <SearchInput name="address" ref={register} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {assignedAnchorDealerList?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={ASSIGNED_TABLE_HEADERS} />
          <TableBody numOfCol={ASSIGNED_TABLE_HEADERS?.length}>
            {assignedAnchorDealerList?.content.map((item, index) => (
              <Tr key={index}>
                <Td>
                  <div className="text-center">
                    <input
                      className="form-check-input m-0"
                      type="checkbox"
                      onChange={e => onChangeCheckbox(e, item)}
                      checked={checkedAnchorDealerData.map(item => item.id).includes(item.id)}
                    />
                  </div>
                </Td>
                <Td data={item.name} />
                <Td data={item.taxCode} />
                <Td data={item.anchorName} />
                <Td data={item.address} />
                <TdLink path={ROUTES_FI.MANAGE_DEALER.ASSIGNMENT_DETAIL_BUILD_PATH(item.id)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
      <div className="content-area flex-end">
        <Button
          size={ButtonSizeEnum.LG}
          onClick={handleClickDeclineButton}
          disabled={checkedAnchorDealerData.length === 0}
        >
          {t('text:Decline')}
        </Button>
      </div>
    </>
  );
}

export default FinancierAnchorDealerAssignmentListAssignedTab;
