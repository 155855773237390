import type Pageable from 'models/Pageable';
import { formattingToTermsOfUseDetailVOModel } from 'models/vo/TermsOfUseDetailVO';
import type { TermsOfUseDetailVO, TermsOfUseDetailVOModel } from 'models/vo/TermsOfUseDetailVO';
import { formattingToTermsOfUseVOModel } from 'models/vo/TermsOfUseVO';
import type { TermsOfUseVO, TermsOfUseVOModel } from 'models/vo/TermsOfUseVO';
import http, { ContentType } from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemTermsOfUsesRequest } from './requests';

export async function requestSystemTOUList(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<TermsOfUseVOModel[]>> {
  const response = await http.get<Pageable<TermsOfUseVO[]>>({
    url: API_SY.TERMS_OF_USES.TERMS_OF_USES,
    data: {
      pageNumber,
      rowCount,
    },
  });

  return { ...response, content: response.content.map(data => formattingToTermsOfUseVOModel(data)) };
}

export async function requestSystemTOUDetail(termsOfUseId: number): Promise<TermsOfUseDetailVOModel> {
  const response = await http.get<TermsOfUseDetailVO>({
    url: API_SY.TERMS_OF_USES.TERMS_OF_USES_DETAIL(termsOfUseId),
  });

  return formattingToTermsOfUseDetailVOModel(response);
}

export async function requestSystemTOURegister(data: SystemTermsOfUsesRequest): Promise<void> {
  return await http.post({
    url: API_SY.TERMS_OF_USES.TERMS_OF_USES,
    contentType: ContentType.MULTIPART,
    data,
  });
}
