import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import FinancierCitadCodeModal from 'components/stateless/Modal/financier/FinancierCitadCodeModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { WaitingAnchorPartnerDetailVOModel } from 'models/vo/WaitingAnchorPartnerDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFiWaitingAnchorPartnerApprove,
  requestFiWaitingAnchorPartnerCancel,
  requestFiWaitingAnchorPartnerDetail,
  requestFiWaitingAnchorPartnerModification,
  requestFiWaitingAnchorPartnerReturn,
} from 'utils/http/api/financier/waiting-anchor-partners';
import type { UpdateFiWaitingAnchorPartnerRequest } from 'utils/http/api/financier/waiting-anchor-partners/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { getPaymentSupportText } from 'utils/text';

type DataRelatedToTheSelectedBankCode = {
  bankCodeId: BankCodeVOModel['id'];
  bankCode: BankCodeVOModel['bankCode'];
  bankName: BankCodeVOModel['bankName'];
  branchCode: BankCodeVOModel['branchCode'];
  branchName: BankCodeVOModel['branchName'];
};

function FinancierPotentialSupplyChainPartnerRegistrationWaitingDetail() {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const history = useHistory();
  const { waitingAnchorPartnerId } = useParams() as any;

  const [waitingAnchorPartnerInfo, setWaitingAnchorPartnerInfo] = useState<WaitingAnchorPartnerDetailVOModel>(
    {} as WaitingAnchorPartnerDetailVOModel,
  );
  const [dataRelatedToTheSelectedBankCode, setDataRelatedToTheSelectedBankCode] = useState<
    Partial<DataRelatedToTheSelectedBankCode>
  >({} as DataRelatedToTheSelectedBankCode);

  const signInInfo = getSignIn();
  const isOperator = signInInfo?.authorityType === AUTHORITY_TYPE.OPERATOR;
  const isAuthorizer = signInInfo?.authorityType === AUTHORITY_TYPE.AUTHORIZER;

  const approvalType = waitingAnchorPartnerInfo?.approvalType;
  const isRequested = approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST;
  const isCanceled = approvalType === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL;
  const isReverted = approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED;

  const isEditable = isReverted && isOperator;

  const { register, getValues, errors, setError, clearErrors, reset, setValue } =
    useForm<UpdateFiWaitingAnchorPartnerRequest>({
      mode: 'onSubmit',
      shouldFocusError: true,
    });

  const getWaitingAnchorPartnerProperty = useProperty<UpdateFiWaitingAnchorPartnerRequest>();

  useEffect(() => {
    if (mounted) {
      fetchWaitingAnchorPartnerDetailInfo();
    }
  }, [mounted]);

  async function fetchWaitingAnchorPartnerDetailInfo() {
    try {
      const waitingAnchorPartnerDetailInfo = await requestFiWaitingAnchorPartnerDetail(waitingAnchorPartnerId);

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerInfo(waitingAnchorPartnerDetailInfo);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const getGuideMessage = (): JSX.Element | null => {
    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        if (isOperator || signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN) {
          return (
            <GuideMessage
              message={[
                t('text:The_Uploaded_Supplier_Registration_has_been_requested'),
                t('text:Registration_will_be_completed_when_the_Authorizer_approves_the_request'),
              ]}
            />
          );
        }

        if (isAuthorizer) {
          return (
            <GuideMessage
              message={[
                t('text:The_registration_request_for_the_Uploaded_Supplier_below_has_been_submitted'),
                t('text:Click_on_the_Confirm_button_to_approve_the_registration_request'),
                t(
                  'text:If_the_company_s_tax_code_is_incorrect_click_on_the_Revert_button_and_return_the_registration_request',
                ),
              ]}
            />
          );
        }

        return null;
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        if (isOperator) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_Uploaded_Supplier_registration_request_has_been_reverted'),
                t('text:Please_check_the_reason_for_the_revert_below_and_proceed_with_the_update'),
              ]}
              reason={waitingAnchorPartnerInfo?.returnReason}
              reasonTitle={t('text:REASON_FOR_REVERT')}
            />
          );
        }
        if (isAuthorizer || signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[t('text:The_Uploaded_Supplier_registration_request_has_been_reverted')]}
              reason={waitingAnchorPartnerInfo?.returnReason}
              reasonTitle={t('text:REASON_FOR_REVERT')}
            />
          );
        }

        return null;
      default:
        return null;
    }
  };

  const approvalRequestStatusInfo = () => {
    const operatorInfo = `${t('text:Operator')} (${waitingAnchorPartnerInfo?.operatorUserName} / ${
      waitingAnchorPartnerInfo?.operatorUserLoginId
    }) `;
    const authorizerInfo = `${t('text:Authorizer')} (${waitingAnchorPartnerInfo?.authorizerUserName} / ${
      waitingAnchorPartnerInfo?.authorizerUserLoginId
    }) `;
    const operatorEnInfo = waitingAnchorPartnerInfo?.operatorEnterpriseName;
    const authorizerEnInfo = waitingAnchorPartnerInfo?.authorizerEnterpriseName;

    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return {
          badgeTitle: t('text:Requested'),
          requestor: operatorInfo,
          requestorEnterpriseType: operatorEnInfo,
          timeTitle: t('text:Requested_Date'),
        };
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return {
          badgeTitle: t('text:Reverted'),
          requestor: authorizerInfo,
          requestorEnterpriseType: authorizerEnInfo,
          timeTitle: t('text:Reverted_Date'),
        };
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return {
          badgeTitle: t('text:Cancelled'),
          requestor: operatorInfo,
          requestorEnterpriseType: operatorEnInfo,
          timeTitle: t('text:Cancelled_Date'),
        };
      default:
        return;
    }
  };

  const getDesignatedBankAccountInfo = () => {
    if (isEmpty(dataRelatedToTheSelectedBankCode)) {
      const {
        accountBankCode: bankCode,
        accountBankName: bankName,
        accountBranchCode: branchCode,
        accountBranchName: branchName,
      } = waitingAnchorPartnerInfo;

      return {
        bankCode: bankCode ?? '',
        bankName: bankName ?? '',
        branchCode: branchCode ?? '',
        branchName: branchName ?? '',
      };
    } else {
      const { bankCode, bankName, branchCode, branchName } = dataRelatedToTheSelectedBankCode;

      return {
        bankCode: bankCode ?? '',
        bankName: bankName ?? '',
        branchCode: branchCode ?? '',
        branchName: branchName ?? '',
      };
    }
  };

  const renderRegistrationRequestStateBlock = (): JSX.Element => {
    const registrationStatusInfo = approvalRequestStatusInfo();

    return (
      <>
        {getGuideMessage()}
        <div className="content-area">
          <div className="supply-chain-partner-status-block">
            <span
              className={`me-4 ${getStatusBadgeClass('COMMON_APPROVAL_TYPE', waitingAnchorPartnerInfo?.approvalType)}`}
            >
              {registrationStatusInfo?.badgeTitle}
            </span>
            <span className="supply-chain-partner-requester-info">
              <span className="supply-chain-partner-requester-info-title">{registrationStatusInfo?.requestor}</span>
              <span>{registrationStatusInfo?.requestorEnterpriseType}</span>
            </span>
            <span className="supply-chain-partner-requester-info supply-chain-partner-requester-info-time">
              <span className="supply-chain-partner-requester-info-title">{registrationStatusInfo?.timeTitle}</span>
              <span>{t('format:datetime', { value: waitingAnchorPartnerInfo?.updatedDateTime, key: 'datetime' })}</span>
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderSupplyChainPartnerInformation = (): JSX.Element => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Uploaded_Supplier_Information`)} />
          <FormBorder editable={isEditable}>
            <FormContents>
              <div className="row">
                <FormInput
                  label={t(`text:Uploaded_Supplier_Name`)}
                  name={getWaitingAnchorPartnerProperty('name')}
                  defaultValue={waitingAnchorPartnerInfo?.name}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  ref={register}
                  error={errors.name}
                />
                <FormInput
                  label={t(`text:Tax_Code`)}
                  name={getWaitingAnchorPartnerProperty('partnerTaxCode')}
                  defaultValue={waitingAnchorPartnerInfo?.partnerTaxCode}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  ref={register}
                  error={errors.partnerTaxCode}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Company_Registration_Number`)}
                  name={getWaitingAnchorPartnerProperty('partnerBusinessCode')}
                  defaultValue={waitingAnchorPartnerInfo?.partnerBusinessCode}
                  disabled={!isEditable}
                  ref={register}
                  error={errors.partnerBusinessCode}
                />
                <FormInput
                  col={3}
                  label={t(`text:Telephone`)}
                  name={getWaitingAnchorPartnerProperty('telephone')}
                  defaultValue={waitingAnchorPartnerInfo?.telephone}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  ref={register}
                  error={errors.telephone}
                />
                <FormInput
                  col={3}
                  label={t(`text:Fax`)}
                  name={getWaitingAnchorPartnerProperty('fax')}
                  defaultValue={waitingAnchorPartnerInfo?.fax}
                  disabled={!isEditable}
                  ref={register}
                  error={errors.fax}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Legal_Representative_Name`)}
                  name={getWaitingAnchorPartnerProperty('representativeName')}
                  defaultValue={waitingAnchorPartnerInfo?.representativeName}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  ref={register}
                  error={errors.representativeName}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  name={getWaitingAnchorPartnerProperty('representativePosition')}
                  defaultValue={waitingAnchorPartnerInfo?.representativePosition}
                  disabled={!isEditable}
                  ref={register}
                  error={errors.representativePosition}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  name={getWaitingAnchorPartnerProperty('representativeEmail')}
                  defaultValue={waitingAnchorPartnerInfo?.representativeEmail}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  ref={register}
                  error={errors.representativeEmail}
                />
              </div>
              <div className="row">
                <FormInput
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  name={getWaitingAnchorPartnerProperty('address')}
                  defaultValue={waitingAnchorPartnerInfo?.address}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  ref={register}
                  error={errors.address}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderDesignatedBankAccountInformationforAPSettlement = (): JSX.Element => {
    const openFindCitadModal = (e: any) => {
      e.preventDefault();

      const setBankCodeData = (data: BankCodeVOModel): void => {
        setDataRelatedToTheSelectedBankCode({
          bankCodeId: data.id,
          bankCode: data.bankCode,
          bankName: data.bankName,
          branchCode: data.branchCode,
          branchName: data.branchName,
        });
      };

      modal.show(<FinancierCitadCodeModal handleBankCodeData={setBankCodeData} modalId={modal.id} />, {
        title: t('text:Find_Bank_Code'),
        modalType: ModalType.ALERT,
        modalSize: ModalSize.L,
        closeBtnText: t('text:Close'),
      });
    };

    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Designated_Bank_Account_Information_for_AP_Settlement`)} />
          <FormBorder editable={isEditable}>
            <FormSubtitle
              backGroundType={BackGroundType.DarkGray}
              title={t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT_INFORMATION')}
            >
              <div className="flex-end align-items-center me-3">
                <span className="me-1">{t('text:Go_to_Agreement_Details')}</span>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  className="flex-column-center"
                  to={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(
                    waitingAnchorPartnerInfo?.anchorAgreementId!,
                  )}
                  state={{
                    supportedCollateralType: waitingAnchorPartnerInfo?.collateralType,
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </FormSubtitle>
            <FormContents>
              <div className="row">
                <FormValue label={t('text:Anchor_Name')} value={waitingAnchorPartnerInfo?.anchorClientName} />
                <FormValue
                  label={t(`text:Anchor_Master_Agreement_Number`)}
                  value={waitingAnchorPartnerInfo?.anchorAgreementContractNo}
                />
              </div>
              <div className="row">
                <FormValue label={t(`text:Currency`)} value={waitingAnchorPartnerInfo?.currencyType} />
                <FormValue
                  label={t(`text:Automatic_Settlement`)}
                  value={getPaymentSupportText(waitingAnchorPartnerInfo?.paymentSupport)}
                />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Responsible_Branch_Name`)}
                  value={waitingAnchorPartnerInfo?.responsibleBranchName}
                />
              </div>
            </FormContents>

            <FormSubtitle
              backGroundType={BackGroundType.DarkGray}
              title={t('text:DESIGNATED_BANK_ACCOUNT_INFORMATION')}
            />
            <FormContents>
              <div className="row">
                <FormInput
                  label={t(`text:Bank_Code`)}
                  name="bankCode"
                  value={getDesignatedBankAccountInfo().bankCode}
                  disabled
                  requiredOptions={{ fixedRequired: isEditable }}
                  error={errors.bankCodeId}
                >
                  {isEditable && <Button onClick={openFindCitadModal}>{t(`text:Find`)}</Button>}
                </FormInput>
                <FormInput
                  requiredOptions={{ fixedRequired: isEditable }}
                  label={t(`text:Bank_Name`)}
                  name="bankName"
                  value={getDesignatedBankAccountInfo().bankName}
                  disabled
                />
              </div>
              <div className="row">
                <FormInput
                  requiredOptions={{ fixedRequired: isEditable }}
                  label={t(`text:Branch_Code`)}
                  name="branchCode"
                  value={getDesignatedBankAccountInfo().branchCode}
                  disabled
                />
                <FormInput
                  requiredOptions={{ fixedRequired: isEditable }}
                  label={t(`text:Branch_Name`)}
                  name="branchName"
                  value={getDesignatedBankAccountInfo().branchName}
                  disabled
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Bank_Account_Number`)}
                  name={getWaitingAnchorPartnerProperty('account')}
                  defaultValue={waitingAnchorPartnerInfo?.account}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  ref={register}
                  error={errors.account}
                  applyUpperCase
                />
                <FormInput
                  label={t(`text:Bank_Account_Holder`)}
                  name={getWaitingAnchorPartnerProperty('accountOwner')}
                  defaultValue={waitingAnchorPartnerInfo?.accountOwner}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  ref={register}
                  error={errors.accountOwner}
                  applyUpperCase
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderOperatorButton = (): JSX.Element | null => {
    if (isCanceled) return null;

    const cancelPartnerRegistrationRequest = async () => {
      try {
        await requestFiWaitingAnchorPartnerCancel({ waitingAnchorPartnerIds: [waitingAnchorPartnerId] });
        await fetchWaitingAnchorPartnerDetailInfo();
        setDataRelatedToTheSelectedBankCode({} as DataRelatedToTheSelectedBankCode);
        reset(waitingAnchorPartnerInfo);
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickCancelRequest = () => {
      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_request?')}
          <br />
          {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: cancelPartnerRegistrationRequest,
        },
      );
    };

    const successChangeRequestModal = () => {
      modal.show(
        <h6>
          {t('text:The_request_for_registration_confirmation_has_been_submitted_successfully')}
          <br />
          {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
        </h6>,
      );
    };

    const requestUpdatePartnerInfo = async () => {
      const bankCodeId =
        dataRelatedToTheSelectedBankCode?.bankCodeId ?? waitingAnchorPartnerInfo?.accountBankCodeId ?? NaN;

      const partnerInfo: UpdateFiWaitingAnchorPartnerRequest = { ...getValues(), bankCodeId };

      try {
        await requestFiWaitingAnchorPartnerModification(waitingAnchorPartnerId, partnerInfo);
        successChangeRequestModal();
        reset();
        fetchWaitingAnchorPartnerDetailInfo();
      } catch (e) {
        modal.show(e);
        formErrorHandler<UpdateFiWaitingAnchorPartnerRequest>(e, setError, clearErrors);
      }
    };

    const onClickChangeRequest = () => {
      modal.show(<h6>{t('text:Would_you_like_to_request_approval_for_modification?')}</h6>, {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: requestUpdatePartnerInfo,
      });
    };

    if (isRequested) {
      return (
        <div className="content-area">
          <div className="flex-end mt-4">
            <Button size={ButtonSizeEnum.LG} color={ButtonColorEnum.SECONDARY} variant={ButtonVariantEnum.OUTLINED}>
              {t('text:Cancel_Request')}
            </Button>
          </div>
        </div>
      );
    }

    if (isReverted) {
      return (
        <div className="content-area clearfix">
          <div className="flex-end mt-4">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={onClickCancelRequest}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel_Request')}
            </Button>
            <Button size={ButtonSizeEnum.LG} className="ms-2" onClick={onClickChangeRequest}>
              {t('text:Request_Change')}
            </Button>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderRevertAndConfirmButton = (): JSX.Element | null => {
    if (!isRequested) return null;

    const handleClickRevert = () => {
      let reason = '';

      const requestRevertSuccessModal = () => {
        modal.show(<h6>{t('text:The_Uploaded_Supplier_bank_account_modification_request_has_been_reverted')}</h6>, {
          title: t('text:Notice'),
        });
      };

      const revertPartnerRegistrationRequest = async () => {
        try {
          await requestFiWaitingAnchorPartnerReturn({
            waitingAnchorPartnerIds: [waitingAnchorPartnerId],
            returnReason: reason,
          });
          requestRevertSuccessModal();
          fetchWaitingAnchorPartnerDetailInfo();
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_registration_request')}
            <br />
            {t('text:Please_type_below_the_reason_for_the_decline')}
            <br />
            {t('text:Click_on_Confirm_to_revert_the_request')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          title: t('text:Notice'),
          modalType: ModalType.CONFIRM,
          confirmBtnCb: revertPartnerRegistrationRequest,
          closeBtnText: t('text:Cancel'),
        },
      );
    };

    const handleClickConfirm = () => {
      const requestConfirmSuccessModal = () => {
        modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
          title: t('text:Notice'),
          closeBtnCb: () => {
            history.push(ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_LIST);
          },
        });
      };

      const approvePartnerRegistrationRequest = async () => {
        try {
          await requestFiWaitingAnchorPartnerApprove({ waitingAnchorPartnerIds: [waitingAnchorPartnerId] });
          requestConfirmSuccessModal();
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(<h6>{t('text:Would_you_like_to_confirm_the_request?')}</h6>, {
        title: t('text:Notice'),
        modalType: ModalType.CONFIRM,
        confirmBtnCb: approvePartnerRegistrationRequest,
        closeBtnText: t('text:Cancel'),
      });
    };

    return (
      <div className="content-area">
        <div className="flex-end mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={handleClickRevert}
            color={ButtonColorEnum.RED}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Revert')}
          </Button>
          <Button size={ButtonSizeEnum.LG} className="ms-2" onClick={handleClickConfirm}>
            {t('text:Confirm')}
          </Button>
        </div>
      </div>
    );
  };

  const renderButtonByAuthority = (): JSX.Element | null => {
    if (isOperator) {
      return renderOperatorButton();
    }

    if (isAuthorizer) {
      return renderRevertAndConfirmButton();
    }

    return null;
  };

  return (
    <>
      <BackHeaderTitle title={waitingAnchorPartnerInfo?.name} />
      {renderRegistrationRequestStateBlock()}
      {renderSupplyChainPartnerInformation()}
      {renderDesignatedBankAccountInformationforAPSettlement()}
      {renderButtonByAuthority()}
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerRegistrationWaitingDetail;
