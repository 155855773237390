import { useTranslation } from 'react-i18next';

import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import NumericFormatInput from 'components/stateless/CommonForm/NumericFormatInput';
import Label from 'components/stateless/Form/Label/Label';
import { Border } from 'components/templates/section';
import { isNilOrEmptyString } from 'utils/helpers';

import { useProgramChargeRateFormController } from './useProgramChargeRateFormController';

interface ProgramChargeFormPropsType {
  editable?: boolean;
  isOpacity?: boolean;
}

export const PROGRAM_CHARGE_RATE_FIELD_NAME = 'commissionSpreads';

function ProgramChargeRateForm({ editable = true, isOpacity }: ProgramChargeFormPropsType) {
  const { t } = useTranslation(['format']);
  const { methods, fields, handleAddRowClick, handleDeleteLastRowClick } = useProgramChargeRateFormController();

  const { register, watch, control } = methods;

  const watchProgramChargeList = watch(PROGRAM_CHARGE_RATE_FIELD_NAME);

  return (
    <Border
      isEditable={editable}
      className={clsx({
        opacityApplied: isOpacity,
      })}
    >
      <table className="term-spread__table">
        <colgroup>
          <col />
          <col style={{ width: '30%' }} />
          <col style={{ width: '56px' }} />
        </colgroup>
        <thead className="term-spread__head">
          <tr>
            <th scope="col">
              <Label name={PROGRAM_CHARGE_RATE_FIELD_NAME} required>
                {t('text:AR_Amount')}
              </Label>
            </th>
            <th scope="col">
              <Label name={PROGRAM_CHARGE_RATE_FIELD_NAME} required>
                {t('text:Fee_Rate')} (%)
              </Label>
            </th>
            <th scope="col" className={clsx({ 'term-spread__no-left-border': !editable })} />
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const minimumAmountRange = watchProgramChargeList?.[index]?.minimumAmountRange;
            const isEdgeRow = (index: number) => index === 0 || index === fields.length - 1;

            return (
              <tr
                key={`term-spread-row-${item.id}`}
                className={clsx({ 'term-spread__tr--background-gray': isEdgeRow(index) })}
              >
                <td>
                  <div className="term-spread__term-box">
                    <div>
                      {index === 0 && (
                        <input
                          type="hidden"
                          defaultValue={0}
                          disabled
                          name={`${PROGRAM_CHARGE_RATE_FIELD_NAME}.0.minimumAmountRange`}
                          ref={register()}
                        />
                      )}
                      <div className="term-spread__value">
                        {isNilOrEmptyString(minimumAmountRange)
                          ? '-'
                          : t('format:number', {
                              value: index === 0 ? 0 : minimumAmountRange,
                            })}
                      </div>
                    </div>
                    <div className="term-spread__angle-sign">&lt;</div>
                    <div>{t('text:AR_AMOUNT')}</div>
                    {fields.length !== index + 1 && (
                      <>
                        <div className="term-spread__angle-sign">≤</div>
                        <div>
                          <NumericFormatInput
                            name={`${PROGRAM_CHARGE_RATE_FIELD_NAME}.${index + 1}.minimumAmountRange`}
                            disabled={!editable}
                            defaultValue={fields?.[index + 1]?.minimumAmountRange ?? null}
                            placeholder="e.g. 30"
                            control={control}
                            className="form__input text-center"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <div className="d-flex">
                    <NumericFormatInput
                      name={`${PROGRAM_CHARGE_RATE_FIELD_NAME}.${index}.rate`}
                      defaultValue={`${item.rate}`}
                      disabled={!editable}
                      className="form__input text-right"
                      control={control}
                    />
                    <span className="unit">%</span>
                  </div>
                </td>
                <td style={{ maxWidth: '56px' }} className={clsx({ 'term-spread__no-left-border': !editable })}>
                  {editable && fields.length === index + 1 && fields.length !== 2 && (
                    <Button
                      className="term-spread__minus-button"
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={handleDeleteLastRowClick}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
          {editable && (
            <tr className="term-spread__tr--background-gray">
              <td colSpan={3}>
                <Button
                  onClick={handleAddRowClick}
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  fullWidth
                  bold
                >
                  + {t('text:Add_new_AR_Amount')}
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Border>
  );
}

export default ProgramChargeRateForm;
