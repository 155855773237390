import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { isEmpty, isNull } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import { BASIS_INTEREST_TYPE, COLLATERAL_TYPE, INTEREST_REPAYMENT_TYPE } from 'enums';

import { PAGE_TYPE } from '../../models/extraInformation/Context';
import useExtraInformationViewModel from '../../models/extraInformation/useExtraInformationViewModel';
import useFinancingOptionViewModel from '../../models/financingOption/useFinancingOptionViewModel';
import useTermSpreadViewModel from '../../models/financingOption/useTermSpreadViewModel';

const useInterestRateController = () => {
  const { pageType, supportedCollateralType } = useExtraInformationViewModel();
  const { termSpreadList, updateTermSpreadList } = useTermSpreadViewModel();
  const {
    anchorFinancingOption: { basisInterestType, interestRepaymentType },
  } = useFinancingOptionViewModel();

  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;
  const isRegistrationPage = pageType === PAGE_TYPE.REGISTER;

  const [useTermSpread, setUseTermSpread] = useState<boolean | null>(isRegistrationPage ? true : null);

  const {
    methods: { register, setValue, errors, clearErrors },
    isEditable,
  } = useFormContext();

  const handleInterestRepaymentTypeChange = () => {
    setValue('monthlyInterestRepaymentDate', null);
    setValue('showNetDisbursementAmount', null);
    clearErrors(['monthlyInterestRepaymentDate', 'showNetDisbursementAmount']);
  };

  const handleBasisInterestTypeChange = () => {
    setValue('basisInterestBankCode', null);
    setValue('basisInterestRate', null);
    setValue('basisInterestTerm', null);
    clearErrors(['basisInterestType', 'basisInterestRate', 'basisInterestTerm', 'basisInterestBankCode']);
  };

  const handleTermSpreadRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedUseTermSpread = event.target.value === 'true';
    setUseTermSpread(updatedUseTermSpread);
    if (errors?.termSpreadList) clearErrors('termSpreadList');
    if (!updatedUseTermSpread) {
      setValue('termSpreadList', []);
      (!isEmpty(termSpreadList) || isNull(termSpreadList)) && updateTermSpreadList([]);
    }
  };

  const initUseTermSpread = useCallback(() => {
    const updatedUseTermSpread = (termSpreadList?.length ?? 0) > 0;
    setUseTermSpread(updatedUseTermSpread);
  }, [termSpreadList]);

  useEffect(() => {
    if (!isNull(termSpreadList) || !isRegistrationPage) initUseTermSpread();
  }, [initUseTermSpread, isRegistrationPage, termSpreadList]);

  const resetEmptyValue = () => {
    if (basisInterestType !== BASIS_INTEREST_TYPE.FIXED) setValue('basisInterestRate', null);
    if (basisInterestType !== BASIS_INTEREST_TYPE.CUSTOM) setValue('basisInterestBankCode', null);
    if (basisInterestType !== BASIS_INTEREST_TYPE.CUSTOM) setValue('basisInterestTerm', null);
    if (interestRepaymentType !== INTEREST_REPAYMENT_TYPE.MONTHLY) setValue('monthlyInterestRepaymentDate', null);
  };

  useEffect(() => {
    if (isEditable) resetEmptyValue();
  }, [isEditable]);

  return {
    register,
    errors,
    termSpreadList,
    useTermSpread,
    isEditable,
    isAr,
    handleTermSpreadRadioChange,
    handleBasisInterestTypeChange,
    handleInterestRepaymentTypeChange,
  };
};

export default useInterestRateController;
