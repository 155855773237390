import type Pageable from 'models/Pageable';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVO, AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { formattingToAnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemAnchorUserListRequest } from './requests';

export async function requestSystemAnchorUserList(
  pageNumber: number,
  rowCount: number,
  data: SystemAnchorUserListRequest,
): Promise<Pageable<AnchorUserVOModel[]>> {
  const response = await http.get<Pageable<AnchorUserVO[]>>({
    url: API_SY.ANCHOR_USERS.ANCHOR_USER_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToAnchorUserVOModel(item)),
  };
}

export async function requestSystemAnchorUserData(anchorUserId: number): Promise<AnchorUserVOModel> {
  const response = await http.get<AnchorUserVO>({
    url: API_SY.ANCHOR_USERS.ANCHOR_USER_DETAIL(anchorUserId),
  });

  return formattingToAnchorUserVOModel(response);
}

export async function requestSystemAnchorAgreementsRelatedAnchorUser(
  pageNumber: number,
  rowCount: number,
  anchorUserId: number,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_SY.ANCHOR_USERS.ANCHOR_AGREEMENT_LIST_RELATED_ANCHOR_USER(anchorUserId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToAnchorAgreementVOModel(item)),
  };
}

export async function requestSystemDealerAgreementsRelatedAnchorUser(
  pageNumber: number,
  rowCount: number,
  anchorUserId: number,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_SY.ANCHOR_USERS.DEALER_AGREEMENT_LIST_RELATED_ANCHOR_USER(anchorUserId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDealerAgreementVOModel(item)),
  };
}
