import type { EmailVO, EmailVOModel } from 'models/vo/EmailVO';
import { formattingToEmailVOModel } from 'models/vo/EmailVO';
import http from 'utils/http';
import type { CommonResponse } from 'utils/http/CommonResponse';
import API_AN from 'utils/http/api/anonymous';

import type { AnEmailFindTypeAndCodeRequest } from './request';

export async function requestAnEmailFindTypeAndCode(data: AnEmailFindTypeAndCodeRequest): Promise<EmailVOModel> {
  const response = await http.post<EmailVO>({
    url: API_AN.EMAIL.EMAIL_TYPE_AND_CODE,
    data,
  });

  return formattingToEmailVOModel(response);
}

export async function requestAnEmailCheckStatus(code: string): Promise<void> {
  await http.get<CommonResponse>({ url: API_AN.EMAIL.CHECK_EMAIL_STATUS(code) });
}
