import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { isNil } from 'lodash-es';

import i18n from 'locales/i18n';

import { getSortedJsonArrayData } from '../converters/getSortedJsonArrayData';

import type { ExportSpreadSheetProps } from '../types';

const CSV_EXPORT_FILE_TYPE = 'text/csv;charset=UTF-8';
const CSV_EXPORT_FILE_EXTENSION = '.csv';
const EXPORT_FILE_NAME = 'report';

const COMMA = ',';
const NEW_LINE = '\r\n';

const exportCSVFile = (excelExportProps: ExportSpreadSheetProps<any>, isBulkImport: boolean) => {
  const { jsonArrayData, columns, errors = [] } = excelExportProps;

  const appendCSVRow = (rowKeys: string[], row: any): string[] => {
    const replacer = (value: any): string => {
      return value === '' || isNil(value) ? '-' : `"${String(value)}"`;
    };

    return rowKeys.map(rowKey => {
      if (isBulkImport && dayjs(row[rowKey], i18n.t('format:original-date'), true).isValid()) {
        row[rowKey] = dayjs(row[rowKey], i18n.t('format:original-date')).format('YYYYMMDD');
      }

      return replacer(row[rowKey]);
    });
  };

  const header = columns.map(item => item.header);

  const getBulkImportCsv = () => {
    const headerWithValidation = [...header, 'Validation Result'];
    const sortedJsonArrayData = getSortedJsonArrayData(jsonArrayData, errors);

    const rowsWithValidation = sortedJsonArrayData.map(item => {
      const row = appendCSVRow(
        columns.map(item => item.key),
        item,
      );
      const validationResult = errors[item.rowIndex - 1] ? i18n.t('text:Fail') : i18n.t('text:Pass');

      return [...row, validationResult];
    });
    const dataWithValidation = [headerWithValidation, ...rowsWithValidation];

    return (
      `"* ${i18n.t('text:APs_that_have_failed_the_validation_check_are_highlighted_in_red')} ${i18n.t(
        'text:Check_their_validation_results_and_the_reasons_for_the_failure',
      )} ${i18n.t(
        'text:If_there_are_any_APs_that_need_revision,_make_the_changes_below_and_re-upload_this_file_to_the_platform',
      )}"` +
      NEW_LINE +
      dataWithValidation.map(row => row.join(COMMA)).join(NEW_LINE)
    );
  };

  const getCsv = () => {
    return (
      header.join(COMMA) +
      NEW_LINE +
      jsonArrayData
        .map(item =>
          appendCSVRow(
            columns.map(item => item.key),
            item,
          ).join(COMMA),
        )
        .join(NEW_LINE)
    );
  };

  const csv = isBulkImport ? getBulkImportCsv() : getCsv();
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csv], { type: CSV_EXPORT_FILE_TYPE });
  fileDownload(blob, EXPORT_FILE_NAME + CSV_EXPORT_FILE_EXTENSION);
};

export default exportCSVFile;
