import { sortBy } from 'lodash-es';

import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';

const DEALER_IDENTIFIER_CODE = <const>'dealerIdentifierCode';

export interface BoeTableStructure {
  billOfExchangeNo?: string;
  settlementDate: string;
  invoiceAmount: BigNumber | number;
  dealerIdentifierCode?: string;
}

const calculateBOEInformation = <T extends BoeTableStructure>(
  data: T[],
  billOfExchangeNoRequired?: boolean,
): BoeTableStructure[] => {
  const isSameConditions = (boeTableData: BoeTableStructure, curBoeTableData: BoeTableStructure) => {
    if (
      DEALER_IDENTIFIER_CODE in curBoeTableData &&
      boeTableData.dealerIdentifierCode?.toLowerCase() !== curBoeTableData.dealerIdentifierCode?.toLowerCase()
    )
      return false;
    if (boeTableData.billOfExchangeNo !== curBoeTableData.billOfExchangeNo) return false;
    if (boeTableData.settlementDate !== curBoeTableData.settlementDate) return false;

    return true;
  };

  const sumInvoiceAmountWhenSameConditions = (boeTableData: BoeTableStructure, curBoeTableData: BoeTableStructure) => {
    if (isSameConditions(boeTableData, curBoeTableData)) {
      const calculatorBigNumber = new CalculatorBigNumber();

      return {
        ...boeTableData,
        invoiceAmount: calculatorBigNumber.add(boeTableData.invoiceAmount).add(curBoeTableData.invoiceAmount).get(),
      };
    } else {
      return boeTableData;
    }
  };

  const boeTableDataList = data.reduce(
    (accBoeTableDataList: BoeTableStructure[], curBoeTableData: T): BoeTableStructure[] => {
      const { invoiceAmount, dealerIdentifierCode, billOfExchangeNo, settlementDate } = curBoeTableData;

      if (DEALER_IDENTIFIER_CODE in curBoeTableData && !dealerIdentifierCode) return accBoeTableDataList;
      if (billOfExchangeNoRequired === true && !billOfExchangeNo) return accBoeTableDataList;
      if (!settlementDate) return accBoeTableDataList;
      if (!invoiceAmount || Number(invoiceAmount) === 0 || isNaN(Number(invoiceAmount))) return accBoeTableDataList;

      const findSameConditionsBoeTableData = accBoeTableDataList.find((boeTableData: BoeTableStructure) =>
        isSameConditions(boeTableData, curBoeTableData),
      );
      if (findSameConditionsBoeTableData) {
        const mappingBoeTableData = accBoeTableDataList?.map(item =>
          sumInvoiceAmountWhenSameConditions(item, curBoeTableData),
        );

        return mappingBoeTableData;
      } else {
        const mergedCurBoeTableDataIntoAccBoeTableDataList: BoeTableStructure[] = [
          ...accBoeTableDataList,
          {
            billOfExchangeNo,
            settlementDate,
            invoiceAmount: String(invoiceAmount),
            ...(DEALER_IDENTIFIER_CODE in curBoeTableData && {
              dealerIdentifierCode,
            }),
          },
        ];

        return mergedCurBoeTableDataIntoAccBoeTableDataList;
      }
    },
    [],
  );

  const orderOptions =
    DEALER_IDENTIFIER_CODE in data[0]
      ? ['dealerIdentifierCode', 'billOfExchangeNo', 'settlementDate', 'invoiceAmount']
      : ['billOfExchangeNo', 'settlementDate', 'invoiceAmount'];

  const orderedBoeTableDataList = sortBy(boeTableDataList, orderOptions);

  return orderedBoeTableDataList;
};

export default calculateBOEInformation;
