import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import QuestionButton from 'components/stateless/QuestionButton';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, EARLY_REPAYMENT_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import {
  requestFinancierEarlyRepaymentComplete,
  requestFinancierEarlyRepaymentDetail,
  requestFinancierEarlyRepaymentExpire,
} from 'utils/http/api/financier/early-repayment-requests';
import { requestFinancierLoanDetail } from 'utils/http/api/financier/loans';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

function FinancierEarlyRepaymentDetail() {
  const mounted = useMounted();
  const authorityType = getSignIn()?.authorityType;
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { earlyRepaymentRequestId } = useParams() as any;

  const [earlyRepaymentRequestData, setEarlyRepaymentRequestData] = useState<EarlyRepaymentRequestVOModel>(
    {} as EarlyRepaymentRequestVOModel,
  );
  const [loanDetail, setLoanDetail] = useState<LoanDetailVOModel>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const earlyRepaymentRequestResponse = await requestFinancierEarlyRepaymentDetail(earlyRepaymentRequestId);
      const loanDetailResponse = await requestFinancierLoanDetail(earlyRepaymentRequestResponse.loanId);
      setEarlyRepaymentRequestData(earlyRepaymentRequestResponse);
      setLoanDetail(loanDetailResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const getStatusText = (status: EARLY_REPAYMENT_REQUEST_STATUS | string): string => {
    switch (status) {
      case EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED:
        return t('text:Requested');
      case EARLY_REPAYMENT_REQUEST_STATUS.COMPLETED:
        return t('text:Completed');
      case EARLY_REPAYMENT_REQUEST_STATUS.EXPIRED:
        return t('text:Rejected');
      default:
        return '';
    }
  };

  const guideMessageTextForRequested: string[] = (() => {
    const fixedMessage = t(
      'text:Check_the_information_of_the_Partners_early_repayment_request_submitted_to_the_Financier',
    );

    return authorityType !== AUTHORITY_TYPE.ADMIN
      ? [
          fixedMessage,
          t('text:Click_on_Complete_if_the_repayment_has_been_completed_successfully'),
          t(
            'text:Click_on_Reject_to_reject_the_Partners_request_or_if_the_repayment_has_not_been_made_on_the_requested_repayment_date',
          ),
        ]
      : [fixedMessage];
  })();

  const onClickComplete = (e: any) => {
    e.preventDefault();

    const onClickConfirm = async () => {
      try {
        await requestFinancierEarlyRepaymentComplete(earlyRepaymentRequestId);

        modal.show(<h6>{t('text:The_early_repayment_request_has_been_completed')}</h6>, {
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_complete_early_repayment?')}
        <br />
        {t('text:The_early_repayment_can_be_completed_if_the_repayment_was_made_on_the_scheduled_early_repayment_date')}
        <br />
        {t('text:Please_proceed_after_checking_the_repayment_status_in_the_banking_system')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      },
    );
  };

  const onClickExpiration = (e: any) => {
    e.preventDefault();

    const onClickConfirm = async () => {
      try {
        await requestFinancierEarlyRepaymentExpire(earlyRepaymentRequestId);

        modal.show(<h6>{t('text:The_early_repayment_request_was_rejected')}</h6>, {
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_reject_the_early_repayment_request?')}
        <br />
        {t(
          'text:The_early_repayment_can_be_rejected_if_the_repayment_was_not_made_on_the_scheduled_early_repayment_date',
        )}
        <br />
        {t('text:Please_proceed_after_checking_the_repayment_status_in_the_banking_system')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      },
    );
  };

  const onClickEarlyRepaymentRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EARLY_REPAYMENT_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EARLY_REPAYMENT_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderAgreementType = (collateralType: COLLATERAL_TYPE | undefined) => {
    if (collateralType === COLLATERAL_TYPE.AR) {
      return t('text:Vendor_Finance');
    } else if (collateralType === COLLATERAL_TYPE.INVOICE) {
      return t('text:Dealer_Finance');
    } else {
      return null;
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Early_Repayment_Details')} />
      {earlyRepaymentRequestData.earlyRepaymentRequestStatus === EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED && (
        <GuideMessage message={guideMessageTextForRequested} />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Early_Repayment_Request_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Requested_Date')}
                value={tableValueManage(
                  earlyRepaymentRequestData.createdDateTime,
                  t('format:datetime', { value: earlyRepaymentRequestData.createdDateTime, key: 'datetime' }),
                )}
                col={6}
              />
              <div className="col-6">
                <div className="information-form__label">
                  {t('text:Status')}
                  <QuestionButton onClick={onClickEarlyRepaymentRequestStatus} />
                </div>
                <div className="information-form__input border-none status-container">
                  <div
                    className={getStatusBadgeClass(
                      'EARLY_REPAYMENT_REQUEST_STATUS',
                      earlyRepaymentRequestData.earlyRepaymentRequestStatus,
                    )}
                  >
                    {getStatusText(earlyRepaymentRequestData.earlyRepaymentRequestStatus)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <FormValue
                label={t('text:Requested_Repayment_Date')}
                value={earlyRepaymentRequestData.intendedRepaymentDate}
                format="date"
              />
              <FormValue
                label={t('text:Original_Repayment_Date')}
                value={earlyRepaymentRequestData.repaymentDate}
                format="date"
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Partner_Name')}
                value={tableValueManage(earlyRepaymentRequestData.dealerClientName)}
                col={6}
              />
              <FormValue
                label={t('text:Partner_Client_Code')}
                value={tableValueManage(earlyRepaymentRequestData.dealerClientCode)}
                col={6}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Financing_Information')}>
            <div className="flex-end align-items-center me-3">
              <span className="me-1">{t('text:Go_to_Financing_Details')}</span>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                className="flex-column-center"
                to={ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(loanDetail?.loanId!)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </FormSubtitle>
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Program_Type')}
                value={tableValueManage(renderAgreementType(loanDetail?.collateralType))}
                col={6}
              />
              <FormValue
                label={t('text:Financier_Financing_ID')}
                value={tableValueManage(earlyRepaymentRequestData.financierLoanId)}
                col={3}
              />
              <FormValue
                label={t('text:Platform_Financing_ID')}
                value={tableValueManage(earlyRepaymentRequestData.loanId)}
                col={3}
              />
              {/* <FormValue label={t('text:Repaid_Date')} value={'논의 필요'} col={3} /> */}
            </div>
            <div className="row">
              <FormValue
                label={t('text:Financing_Amount')}
                value={earlyRepaymentRequestData.principalAmount}
                col={3}
                format="number"
              />
              <FormValue
                label={t('text:Disbursed_Date')}
                value={earlyRepaymentRequestData.disbursedDate}
                col={3}
                format="date"
              />
            </div>
          </FormContents>
        </FormBorder>
        {authorityType !== AUTHORITY_TYPE.ADMIN &&
          earlyRepaymentRequestData.earlyRepaymentRequestStatus === EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED && (
            <div className="flex-end mt-4">
              <Button
                onClick={onClickExpiration}
                size={ButtonSizeEnum.LG}
                color={ButtonColorEnum.RED}
                variant={ButtonVariantEnum.OUTLINED}
              >
                {t('text:Reject')}
              </Button>
              <Button onClick={onClickComplete} size={ButtonSizeEnum.LG} className="ms-2">
                {t('text:Complete')}
              </Button>
            </div>
          )}
      </div>
    </>
  );
}

export default FinancierEarlyRepaymentDetail;
