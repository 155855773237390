import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import type { UserEmailSettingGroupedMap } from 'components/email-notification-setting/types';
import type { UpdateUserEmailSettingListType } from 'components/email-notification-setting/types';
import { classifyUserEmailSettingAlertOptions } from 'components/email-notification-setting/utils';
import useMounted from 'hooks/useMounted';
import {
  requestFinancierAnchorUserEmailSettingList,
  requestFinancierAnchorUserEmailSettingUpdate,
} from 'utils/http/api/financier/anchor-users';
import useModal from 'utils/modal/useModal';
import { getFactoringEnable } from 'utils/storage/LocalStorage';

function useFinancierManageAnchorUserDetailEmailSettingController() {
  const { anchorUserId } = useParams() as any;
  const factoringEnable = getFactoringEnable();
  const mounted = useMounted();
  const modal = useModal();

  const [selectedCommonAlertSetting, setSelectedCommonAlertSetting] = useState<UserEmailSettingGroupedMap | null>();
  const [selectedVendorAlertSetting, setSelectedVendorAlertSetting] = useState<UserEmailSettingGroupedMap | null>();
  const [selectedDealerAlertSetting, setSelectedDealerAlertSetting] = useState<UserEmailSettingGroupedMap | null>();

  useEffect(() => {
    if (mounted) {
      fetchEmailSetting();
    }
  }, [mounted]);

  const fetchEmailSetting = async (): Promise<void> => {
    try {
      const alertSettingResponse = await requestFinancierAnchorUserEmailSettingList(anchorUserId);

      const { commonFormList, vendorFormList, dealerFormList } = classifyUserEmailSettingAlertOptions(
        alertSettingResponse,
        factoringEnable,
      );

      setSelectedCommonAlertSetting(commonFormList);
      setSelectedVendorAlertSetting(vendorFormList);
      setSelectedDealerAlertSetting(dealerFormList);
    } catch (error) {
      modal.show(error);
    }
  };

  const updateEmailSettingHandler = async (updateUserEmailSettingList: UpdateUserEmailSettingListType[]) => {
    try {
      const alertSettingResponse = await requestFinancierAnchorUserEmailSettingUpdate(anchorUserId, {
        updateUserEmailSettingList,
      });

      const { commonFormList, vendorFormList, dealerFormList } = classifyUserEmailSettingAlertOptions(
        alertSettingResponse,
        factoringEnable,
      );

      setSelectedCommonAlertSetting(commonFormList);
      setSelectedVendorAlertSetting(vendorFormList);
      setSelectedDealerAlertSetting(dealerFormList);
    } catch (error) {
      modal.show(error);
    }
  };

  return {
    updateEmailSettingHandler,
    selectedCommonAlertSetting,
    selectedVendorAlertSetting,
    selectedDealerAlertSetting,
  };
}

export default useFinancierManageAnchorUserDetailEmailSettingController;
