import type { COLLATERAL_TYPE } from 'enums';
import type {
  TempWaitingDealerAgreementVO,
  TempWaitingDealerAgreementVOModel,
} from 'models/vo/TempWaitingDealerAgreementVO';
import { formattingToTempWaitingDealerAgreementVOModel } from 'models/vo/TempWaitingDealerAgreementVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { CreateFiTempWaitingDealerAgreementDTO } from './requests';

export async function requestFinancierTempWaitingDealerAgreementRegister(
  data: CreateFiTempWaitingDealerAgreementDTO,
): Promise<TempWaitingDealerAgreementVOModel> {
  const response = await http.post<TempWaitingDealerAgreementVO>({
    url: API_FI.TEMP_WAITING_DEALER_AGREEMENTS.TEMP_WAITING_DEALER_AGREEMENTS,
    data,
  });

  return formattingToTempWaitingDealerAgreementVOModel(response);
}

export async function requestFinancierTempWaitingDealerAgreementDetail(
  collateralType: COLLATERAL_TYPE,
): Promise<TempWaitingDealerAgreementVOModel> {
  const response = await http.get<TempWaitingDealerAgreementVO>({
    url: API_FI.TEMP_WAITING_DEALER_AGREEMENTS.TEMP_WAITING_DEALER_AGREEMENTS,
    data: { collateralType },
  });

  return response ? formattingToTempWaitingDealerAgreementVOModel(response) : response;
}
