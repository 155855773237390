import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import type { FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';

interface DigitalSignatureOpModalProps {
  loanAgreementAttachment: string;
  loanFactoringNotificationAttachment: string;
}

const PDF_DATA_PREFIX = 'data:application/pdf;base64,';

function DigitalSignatureOpModal({
  loanAgreementAttachment,
  loanFactoringNotificationAttachment,
}: DigitalSignatureOpModalProps) {
  const { t } = useTranslation();

  return (
    <FormBorder>
      <div className="row flex-grow-1">
        <div className="col-6">
          <label className="information-form__label">{t('text:APPENDIX_OF_DEBT_SALE_AND_PURCHASE_AGREEMENT')}</label>
          <iframe className="ds-pdf-viewer" src={`${PDF_DATA_PREFIX}${loanAgreementAttachment}`} />
        </div>
        <div className="col-6">
          <label className="information-form__label">{t('text:DEBT_SELLING_NOTIFICATION')}</label>
          <iframe className="ds-pdf-viewer" src={`${PDF_DATA_PREFIX}${loanFactoringNotificationAttachment}`} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <SectionMessage
            message={[
              t('text:Two_documents_for_factoring_have_been_created'),
              t('text:Partner_Authorizer_and_Financier_will_digitally_sign_the_above_documents'),
            ]}
            className="h-100"
          />
        </div>
      </div>
    </FormBorder>
  );
}

export default DigitalSignatureOpModal;
