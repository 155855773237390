import type Pageable from 'models/Pageable';
import type {
  FinancierCurrencyCommonSettingVO,
  FinancierCurrencyCommonSettingVOModel,
} from 'models/vo/FinancierCurrencyCommonSettingVO';
import { formattingToFinancierCurrencyCommonSettingVOModel } from 'models/vo/FinancierCurrencyCommonSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierFinancierCurrencyCommonSettingRequest } from './requests';

export async function requestFinancierFinancierCurrencyCommonSettingList(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<FinancierCurrencyCommonSettingVOModel[]>> {
  const response = await http.get<Pageable<FinancierCurrencyCommonSettingVO[]>>({
    url: API_FI.FINANCIER_CURRENCY_COMMON_SETTING.CURRENCY_COMMON_SETTINGS,
    data: { pageNumber, rowCount },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToFinancierCurrencyCommonSettingVOModel(data)),
  };
}

export async function requestFinancierFinancierCurrencyCommonSettingModify(
  financierCurrencyCommonSettingId: number,
  data: FinancierFinancierCurrencyCommonSettingRequest,
): Promise<FinancierCurrencyCommonSettingVOModel> {
  const response = await http.put<FinancierCurrencyCommonSettingVO>({
    url: API_FI.FINANCIER_CURRENCY_COMMON_SETTING.CURRENCY_COMMON_SETTING_DETAIL(financierCurrencyCommonSettingId),
    data,
  });

  return formattingToFinancierCurrencyCommonSettingVOModel(response);
}

export async function requestFinancierFinancierCurrencyCommonSettingCreate(
  data: FinancierFinancierCurrencyCommonSettingRequest,
): Promise<{}> {
  const response = await http.post<{}>({
    url: API_FI.FINANCIER_CURRENCY_COMMON_SETTING.CURRENCY_COMMON_SETTINGS,
    data,
  });

  return response;
}

export async function requestFinancierFinancierCurrencyCommonSettingDelete(financierCurrencyCommonSettingId: number) {
  const response = await http.delete({
    url: API_FI.FINANCIER_CURRENCY_COMMON_SETTING.CURRENCY_COMMON_SETTING_DETAIL(financierCurrencyCommonSettingId),
  });

  return response;
}
