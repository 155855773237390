import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';
import { isEmpty, isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormSelect } from 'components/stateless/CommonForm';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { DivisionExceptionCode } from 'enums/exception';
import { HttpError } from 'utils/error/HttpError';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFiDivisionById,
  requestFiDivisionDelete,
  requestFiDivisionEdit,
} from 'utils/http/api/financier/divisions';
import type { UpdateFiDivisionDTO } from 'utils/http/api/financier/divisions/request';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

interface EditDivisionModalPropsType {
  divisionId: number;
  isShow: boolean;
  reFetchDivisions: () => void;
  closeModal: () => void;
}

function EditDivisionModal({ divisionId, isShow, reFetchDivisions, closeModal }: EditDivisionModalPropsType) {
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();

  const [divisionCode, setDivisionCode] = useState('');
  const [canDelete, setCanDelete] = useState(false);
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const { register, reset, getValues, setValue, watch, errors, setError, clearErrors } = useForm<UpdateFiDivisionDTO>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const { limitAllowable, limitCurrencyType, limitAmount } = watch();
  const booleanLimitAllowable = isNil(limitAllowable) ? false : JSON.parse(String(limitAllowable));

  const handleDelete = async () => {
    try {
      await requestFiDivisionDelete(divisionId);
      closeModal();
      showModal(<h6>{t('text:The_company_division_has_been_deleted_successfully')}</h6>, {
        closeBtnCb: reFetchDivisions,
      });
    } catch (error) {
      if (
        error instanceof HttpError &&
        Object.values(DivisionExceptionCode).includes(error.code as DivisionExceptionCode)
      ) {
        showModal(t('text:The_division_cannot_be_deleted_as_there_is_already_an_agreement_related_to_this_division'));

        return;
      }
      showModal(error);
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleConfirm = async () => {
    const divisionData = getValues();

    try {
      requestDTOParser(divisionData);
      await requestFiDivisionEdit(divisionId, divisionData);
      closeModal();
      showModal(<h6>{t('text:The_company_division_information_has_been_modified_successfully')}</h6>, {
        closeBtnCb: reFetchDivisions,
      });
    } catch (error) {
      showModal(error);
      formErrorHandler<UpdateFiDivisionDTO>(error, setError, clearErrors);
    }
  };

  const fetchDivisionDetail = useCallback(async () => {
    try {
      const { code, name, limitAllowable, limitCurrencyType, limitAmount, deletable } = await requestFiDivisionById(
        divisionId,
      );

      setDivisionCode(code);
      setCanDelete(deletable);
      reset({
        name,
        limitAllowable,
        limitCurrencyType: limitAllowable ? limitCurrencyType : undefined,
        limitAmount: limitAllowable ? limitAmount : undefined,
      });
    } catch (error) {
      showModal(error);
    }
  }, [divisionId, reset, showModal]);

  useEffect(() => {
    fetchDivisionDetail();
  }, [fetchDivisionDetail]);

  useEffect(() => {
    if (!booleanLimitAllowable) {
      setValue('limitCurrencyType', '');
      setValue('limitAmount', '');
    }
  }, [booleanLimitAllowable, setValue]);

  useEffect(() => {
    setDisableConfirmButton(booleanLimitAllowable && (isEmpty(limitCurrencyType) || isEmpty(limitAmount)));
  }, [booleanLimitAllowable, limitAmount, limitCurrencyType]);

  useEffect(() => {
    if (!booleanLimitAllowable && (errors.limitAmount || errors.limitCurrencyType)) {
      clearErrors(['limitCurrencyType', 'limitAmount']);
    }
  }, [booleanLimitAllowable, clearErrors, errors, setValue]);

  return (
    <>
      {isShow && (
        <div
          className={clsx('modal fade modal-background', {
            'show d-block': isShow,
            'd-none': !isShow,
          })}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="ModalLabel"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="ConfirmLabel">
                  {t('text:Edit_Division')}
                </h5>
              </div>
              <div className="modal-body">
                <div className="modal-container">
                  <form>
                    <FormBorder editable>
                      <FormContents>
                        <div className="row">
                          <FormInput label={t('text:Division_Code')} name="code" value={divisionCode} disabled />
                          <FormInput label={t('text:Division_Name')} name="name" ref={register} error={errors.name} />
                        </div>
                        <div className="row">
                          <FormSelect
                            name="limitAllowable"
                            label={t('text:Financing Limit')}
                            ref={register}
                            placeholderOptions={{ show: true }}
                            selectOptions={[
                              {
                                label: t('text:Applied'),
                                value: true,
                              },
                              {
                                label: t('text:Not_Applied'),
                                value: false,
                              },
                            ]}
                            defaultValue="false"
                            error={errors.limitAllowable}
                          />
                          <FormSelect
                            label={t('text:Currency')}
                            name="limitCurrencyType"
                            selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
                            ref={register}
                            placeholderOptions={{ show: true }}
                            disabled={!booleanLimitAllowable}
                            error={errors.limitCurrencyType}
                          />
                        </div>
                        <div className="row">
                          <FormInput
                            label={t('text:Financing_Limit_Amount')}
                            name="limitAmount"
                            ref={register}
                            disabled={!booleanLimitAllowable}
                            error={errors.limitAmount}
                          />
                        </div>
                      </FormContents>
                    </FormBorder>
                  </form>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <div>
                  {canDelete && (
                    <Button
                      size={ButtonSizeEnum.MD}
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      className="text-bold"
                      data-bs-dismiss="modal"
                      onClick={handleDelete}
                    >
                      {t('text:Delete')}
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    size={ButtonSizeEnum.MD}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    className="text-bold me-2"
                    data-bs-dismiss="modal"
                    onClick={handleCancel}
                  >
                    {t('text:Cancel')}
                  </Button>
                  <Button
                    size={ButtonSizeEnum.MD}
                    data-bs-dismiss="modal"
                    onClick={handleConfirm}
                    disabled={disableConfirmButton}
                  >
                    {t('text:Confirm')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditDivisionModal;
