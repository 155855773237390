import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import { COLLATERAL_TYPE } from 'enums';
import { FINANCIER_INTERFACE_TYPE } from 'enums';
import useAgreementLocationState from 'pages/financier/manage-partner/agreement/hooks/useAgreementLocationState';
import { checkInterfaceType } from 'utils/storage/LocalStorage';

export enum PAGE_TYPE {
  REGISTER,
  WAITING_DETAIL,
  REGISTERED_DETAIL,
}

type ExtraInformationStateType = {
  useAgreementInterface: boolean;
  supportedCollateralType: COLLATERAL_TYPE;
  errorHandlerOfLocationState: () => boolean;
  useAnchorUserInterface: boolean;
  useAgreementInterfaceByKeyUnion: boolean;
  isMatchedWithUploadedPartner: boolean;
  updateIsMatchedWithUploadedPartner: (isMatchedWithUploadedPartner: boolean) => void;
  pageType: PAGE_TYPE;
  dealerAgreementId: number;
  waitingDealerAgreementId: number;
};

const ExtraInformationContext = createContext<ExtraInformationStateType | null>(null);

export const useExtraInformationContext = () => {
  const context = useContext(ExtraInformationContext);
  if (!context) {
    throw Error('useExtraInformationContext should be used within ExtraInformationContext.Provider');
  }

  return context;
};

type ExtraInformationProviderPropsType = {
  children: ReactNode;
};

const channelAnchorUserInterfaceTypes = [FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_DEALER_AGREEMENT];

const ExtraInformationProvider = ({ children }: ExtraInformationProviderPropsType) => {
  const { useAgreementInterface, supportedCollateralType, errorHandlerOfLocationState } = useAgreementLocationState();
  const { dealerAgreementId, waitingDealerAgreementId } = useParams() as any;

  const useAnchorUserInterface = checkInterfaceType(channelAnchorUserInterfaceTypes);

  const useAgreementInterfaceByKeyUnion = checkInterfaceType([
    supportedCollateralType === COLLATERAL_TYPE.INVOICE
      ? FINANCIER_INTERFACE_TYPE.DEALER_AGREEMENT_BY_KEY_UNION
      : FINANCIER_INTERFACE_TYPE.SUPPLIER_AGREEMENT_BY_KEY_UNION,
  ]);

  const [isMatchedWithUploadedPartner, setIsMatchedWithUploadedPartner] = useState(false);

  const updateIsMatchedWithUploadedPartner = (isMatchedWithUploadedPartner: boolean) =>
    setIsMatchedWithUploadedPartner(isMatchedWithUploadedPartner);

  const pageType = useMemo(() => {
    if (!isNil(dealerAgreementId)) return PAGE_TYPE.REGISTERED_DETAIL;
    if (!isNil(waitingDealerAgreementId)) return PAGE_TYPE.WAITING_DETAIL;
    else return PAGE_TYPE.REGISTER;
  }, [dealerAgreementId, waitingDealerAgreementId]);

  const value = useMemo(
    () => ({
      useAgreementInterface,
      supportedCollateralType,
      errorHandlerOfLocationState,
      useAnchorUserInterface,
      useAgreementInterfaceByKeyUnion,
      isMatchedWithUploadedPartner,
      updateIsMatchedWithUploadedPartner,
      pageType,
      dealerAgreementId,
      waitingDealerAgreementId,
    }),
    [
      useAgreementInterface,
      supportedCollateralType,
      errorHandlerOfLocationState,
      useAnchorUserInterface,
      isMatchedWithUploadedPartner,
      pageType,
      dealerAgreementId,
      waitingDealerAgreementId,
      useAgreementInterfaceByKeyUnion,
    ],
  );

  return <ExtraInformationContext.Provider value={value}>{children}</ExtraInformationContext.Provider>;
};

export default ExtraInformationProvider;
