import type { REPORT_TYPE } from 'enums';

const FI_DASHBOARD_PATH = '/financier/dashboard';

const FI_MANAGE_ANCHOR_PATH = '/financier/manage-anchor';
const FI_MANAGE_ANCHOR_COMPANY_PATH = `${FI_MANAGE_ANCHOR_PATH}/company`;
const FI_MANAGE_ANCHOR_AGREEMENT_PATH = `${FI_MANAGE_ANCHOR_PATH}/agreement`;
const FI_MANAGE_ANCHOR_FINANCING_OPTION_PATH = `${FI_MANAGE_ANCHOR_PATH}/financing-option`;

const FI_MANAGE_ANCHOR_USER_PATH = `${FI_MANAGE_ANCHOR_PATH}/user`;
const FI_MANAGE_ANCHOR_REPORT_PATH = `${FI_MANAGE_ANCHOR_PATH}/report`;

const FI_MANAGE_ANCHOR_FINANCING_PATH = '/financier/manage-anchor-financing';
const FI_MANAGE_ANCHOR_FINANCING_APPROVAL_PATH = `${FI_MANAGE_ANCHOR_FINANCING_PATH}/approval`;
const FI_MANAGE_ANCHOR_FINANCING_VIEW_PATH = `${FI_MANAGE_ANCHOR_FINANCING_PATH}/view`;

const FI_MANAGE_DEALER_PATH = '/financier/manage-dealer';
const FI_MANAGE_DEALER_REGISTRATION_PATH = `${FI_MANAGE_DEALER_PATH}/registration`;
const FI_MANAGE_DEALER_ASSIGNMENT_PATH = `${FI_MANAGE_DEALER_PATH}/assignment`;

const FI_MANAGE_PARTNER_PATH = '/financier/manage-partner';
const FI_MANAGE_PARTNER_COMPANY_PATH = `${FI_MANAGE_PARTNER_PATH}/company`;
const FI_MANAGE_PARTNER_AGREEMENT_PATH = `${FI_MANAGE_PARTNER_PATH}/agreement`;
const FI_MANAGE_PARTNER_REPORT_PATH = `${FI_MANAGE_PARTNER_PATH}/report`;
const FI_MANAGE_PARTNER_USER_PATH = `${FI_MANAGE_PARTNER_PATH}/user`;

const FI_MANAGE_POTENTIAL_PARTNER_PATH = '/financier/manage-potential-partner';
const FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH = `${FI_MANAGE_POTENTIAL_PARTNER_PATH}/registration`;
const FI_MANAGE_POTENTIAL_PARTNER_ACQUISITION_PATH = `${FI_MANAGE_POTENTIAL_PARTNER_PATH}/acquisition`;
const FI_MANAGE_POTENTIAL_PARTNER_ASSIGNMENT_PATH = `${FI_MANAGE_POTENTIAL_PARTNER_PATH}/assignment`;
const FI_MANAGE_POTENTIAL_PARTNER_BANK_ACCOUNT_REVISION_PATH = `${FI_MANAGE_POTENTIAL_PARTNER_PATH}/bank-account-revision`;

const FI_REGISTER_INVOICE_PATH = '/financier/register-invoice';
const FI_REGISTER_INVOICE_REGISTRATION_PATH = `${FI_REGISTER_INVOICE_PATH}/registration`;
const FI_REGISTER_INVOICE_CONFIRMATION_PATH = `${FI_REGISTER_INVOICE_PATH}/confirmation`;

const FI_REGISTER_AR_PATH = '/financier/register-ar';
const FI_REGISTER_AR_CONFIRMATION_PATH = `${FI_REGISTER_AR_PATH}/confirmation`;

const FI_MANAGE_FINANCING_PATH = '/financier/manage-financing';
const FI_MANAGE_FINANCING_APPROVAL_PATH = `${FI_MANAGE_FINANCING_PATH}/approval`;
const FI_MANAGE_FINANCING_BULK_APPROVAL_PATH = `${FI_MANAGE_FINANCING_PATH}/bulk`;
const FI_MANAGE_FINANCING_EXTENSION_PATH = `${FI_MANAGE_FINANCING_PATH}/extension`;
const FI_MANAGE_FINANCING_EARLY_REPAYMENT_PATH = `${FI_MANAGE_FINANCING_PATH}/early-repayment`;
const FI_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH = `${FI_MANAGE_FINANCING_PATH}/secured-inventory-update`;

const FI_VIEW_TRANSACTION_PATH = '/financier/view-transaction';
const FI_VIEW_TRANSACTION_INVOICE_PATH = `${FI_VIEW_TRANSACTION_PATH}/invoice`;
const FI_VIEW_TRANSACTION_INVOICE_PAYMENT_PATH = `${FI_VIEW_TRANSACTION_PATH}/invoice-payment`;
const FI_VIEW_TRANSACTION_AR_PATH = `${FI_VIEW_TRANSACTION_PATH}/ar`;
const FI_VIEW_TRANSACTION_AR_SETTLEMENT_PATH = `${FI_VIEW_TRANSACTION_PATH}/ar-settlement`;
const FI_VIEW_TRANSACTION_DEPT_SELLING_NOTICE_PATH = `${FI_VIEW_TRANSACTION_PATH}/dept-selling-notice`;
const FI_VIEW_TRANSACTION_SETTLEMENT_PAYMENT_PATH = `${FI_VIEW_TRANSACTION_PATH}/settlement-payment`;
const FI_VIEW_TRANSACTION_FINANCING_PATH = `${FI_VIEW_TRANSACTION_PATH}/financing`;
const FI_VIEW_TRANSACTION_OVERVIEW_BY_ANCHOR_PATH = `${FI_VIEW_TRANSACTION_PATH}/overview-by-anchor`;
const FI_VIEW_TRANSACTION_OVERVIEW_BY_PARTNER_PATH = `${FI_VIEW_TRANSACTION_PATH}/overview-by-partner`;
const FI_VIEW_TRANSACTION_INTEREST_PAYMENT_PATH = `${FI_VIEW_TRANSACTION_PATH}/interest-payment`;

const FI_SETTINGS_PATH = '/financier/settings';
const FI_SETTINGS_BRANCH_PATH = `${FI_SETTINGS_PATH}/branch`;
const FI_SETTINGS_USER_PATH = `${FI_SETTINGS_PATH}/user`;

const FI_PROGRAM_SETTINGS_PATH = '/financier/program-settings';
const FI_PROGRAM_SETTINGS_HOLIDAYS_PATH = `${FI_PROGRAM_SETTINGS_PATH}/holidays`;
const FI_PROGRAM_SETTINGS_OPERATION_HOURS_PATH = `${FI_PROGRAM_SETTINGS_PATH}/operation-hours`;
const FI_PROGRAM_SETTINGS_INTEREST_RATE_FEE_PATH = `${FI_PROGRAM_SETTINGS_PATH}/interest-rate-fee`;
const FI_PROGRAM_SETTINGS_DELINQUENT_INTEREST_RATE_PATH = `${FI_PROGRAM_SETTINGS_PATH}/delinquent-interest-rate`;
const FI_PROGRAM_SETTINGS_BANK_CODE_PATH = `${FI_PROGRAM_SETTINGS_PATH}/bank-code`;
const FI_PROGRAM_SETTINGS_DOCUMENT_PATH = `${FI_PROGRAM_SETTINGS_PATH}/document`;
const FI_PROGRAM_SETTINGS_FINANCING_PATH = `${FI_PROGRAM_SETTINGS_PATH}/financing`;
const FI_PROGRAM_SETTINGS_CHARGE_PATH = `${FI_PROGRAM_SETTINGS_PATH}/charge`;
const FI_PROGRAM_SETTINGS_EMAIL_NOTIFICATION_PATH = `${FI_PROGRAM_SETTINGS_PATH}/email-notification`;

const FI_BILLING = '/financier/billing';

const FI_MY_ACCOUNT = '/financier/my-account';

export const ROUTES_FI = {
  DASHBOARD: FI_DASHBOARD_PATH,
  MANAGE_ANCHOR: {
    COMPANY_LIST: FI_MANAGE_ANCHOR_COMPANY_PATH,
    COMPANY_REGISTER: `${FI_MANAGE_ANCHOR_COMPANY_PATH}/register`,
    COMPANY_DETAIL: `${FI_MANAGE_ANCHOR_COMPANY_PATH}/:financierClientId`,
    COMPANY_DETAIL_BUILD_PATH: (financierClientId: number) => `${FI_MANAGE_ANCHOR_COMPANY_PATH}/${financierClientId}`,

    AGREEMENT_LIST: FI_MANAGE_ANCHOR_AGREEMENT_PATH,
    AGREEMENT_REGISTER: `${FI_MANAGE_ANCHOR_AGREEMENT_PATH}/register`,
    AGREEMENT_REGISTERED_DETAIL: `${FI_MANAGE_ANCHOR_AGREEMENT_PATH}/registered-detail/:anchorAgreementId`,
    AGREEMENT_REGISTERED_DETAIL_BUILD_PATH: (anchorAgreementId: number) =>
      `${FI_MANAGE_ANCHOR_AGREEMENT_PATH}/registered-detail/${anchorAgreementId}`,
    AGREEMENT_WAITING_DETAIL: `${FI_MANAGE_ANCHOR_AGREEMENT_PATH}/waiting-detail/:waitingAnchorAgreementId`,
    AGREEMENT_WAITING_DETAIL_BUILD_PATH: (waitingAnchorAgreementId: number) =>
      `${FI_MANAGE_ANCHOR_AGREEMENT_PATH}/waiting-detail/${waitingAnchorAgreementId}`,

    FINANCING_OPTION_LIST: FI_MANAGE_ANCHOR_FINANCING_OPTION_PATH,
    FINANCING_OPTION_REGISTER: `${FI_MANAGE_ANCHOR_FINANCING_OPTION_PATH}/register`,
    FINANCING_OPTION_REGISTERED_DETAIL: `${FI_MANAGE_ANCHOR_FINANCING_OPTION_PATH}/:anchorFinancingOptionId`,
    FINANCING_OPTION_REGISTERED_DETAIL_BUILD_PATH: (anchorFinancingOptionId: number) =>
      `${FI_MANAGE_ANCHOR_FINANCING_OPTION_PATH}/${anchorFinancingOptionId}`,
    FINANCING_OPTION_WAITING_DETAIL: `${FI_MANAGE_ANCHOR_FINANCING_OPTION_PATH}/waiting/:waitingAnchorFinancingOptionId`,
    FINANCING_OPTION_WAITING_DETAIL_BUILD_PATH: (waitingAnchorFinancingOptionId: number) =>
      `${FI_MANAGE_ANCHOR_FINANCING_OPTION_PATH}/waiting/${waitingAnchorFinancingOptionId}`,

    USER_LIST: FI_MANAGE_ANCHOR_USER_PATH,
    USER_REGISTER: `${FI_MANAGE_ANCHOR_USER_PATH}/register`,
    USER_REGISTERED_DETAIL: `${FI_MANAGE_ANCHOR_USER_PATH}/registered-detail/:anchorUserId`,
    USER_REGISTERED_DETAIL_BUILD_PATH: (anchorUserId: number) =>
      `${FI_MANAGE_ANCHOR_USER_PATH}/registered-detail/${anchorUserId}`,
    USER_REGISTERED_DETAIL_EMAIL_SETTING: `${FI_MANAGE_ANCHOR_USER_PATH}/registered-detail/:anchorUserId/email-setting`,
    USER_REGISTERED_DETAIL_EMAIL_SETTING_BUILD_PATH: (anchorUserId: number) =>
      `${FI_MANAGE_ANCHOR_USER_PATH}/registered-detail/${anchorUserId}/email-setting`,
    USER_WAITING_DETAIL: `${FI_MANAGE_ANCHOR_USER_PATH}/waiting-detail/:waitingAnchorUserId`,
    USER_WAITING_DETAIL_BUILD_PATH: (waitingAnchorUserId: number) =>
      `${FI_MANAGE_ANCHOR_USER_PATH}/waiting-detail/${waitingAnchorUserId}`,

    REPORT_LIST: FI_MANAGE_ANCHOR_REPORT_PATH,
    REPORT_REGISTER: `${FI_MANAGE_ANCHOR_REPORT_PATH}/register`,
    REPORT_DETAIL: `${FI_MANAGE_ANCHOR_REPORT_PATH}/:anchorReportSettingId/:anchorAgreementId/:reportType`,
    REPORT_DETAIL_BUILD_PATH: (anchorReportSettingId: number, anchorAgreementId: number, reportType: REPORT_TYPE) =>
      `${FI_MANAGE_ANCHOR_REPORT_PATH}/${anchorReportSettingId}/${anchorAgreementId}/${reportType}`,
  },
  MANAGE_ANCHOR_FINANCING: {
    APPROVAL_LIST: FI_MANAGE_ANCHOR_FINANCING_APPROVAL_PATH,
    APPROVAL_DETAIL: `${FI_MANAGE_ANCHOR_FINANCING_APPROVAL_PATH}/:multipleRequestAnchorLoanId`,
    APPROVAL_DETAIL_BUILD_PATH: (multipleRequestAnchorLoanId: number) =>
      `${FI_MANAGE_ANCHOR_FINANCING_APPROVAL_PATH}/${multipleRequestAnchorLoanId}`,
    LIST: FI_MANAGE_ANCHOR_FINANCING_VIEW_PATH,
    DETAIL: `${FI_MANAGE_ANCHOR_FINANCING_VIEW_PATH}/:anchorLoanId`,
    DETAIL_BUILD_PATH: (anchorLoanId: number) => `${FI_MANAGE_ANCHOR_FINANCING_VIEW_PATH}/${anchorLoanId}`,
  },

  MANAGE_DEALER: {
    REGISTRATION_STEP1: FI_MANAGE_DEALER_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${FI_MANAGE_DEALER_REGISTRATION_PATH}/step2`,
    REGISTRATION_LIST: `${FI_MANAGE_DEALER_REGISTRATION_PATH}/list`,
    REGISTRATION_DETAIL: `${FI_MANAGE_DEALER_REGISTRATION_PATH}/:anchorDealerId`,
    REGISTRATION_DETAIL_BUILD_PATH: (anchorDealerId: number) =>
      `${FI_MANAGE_DEALER_REGISTRATION_PATH}/${anchorDealerId}`,
    REGISTRATION_WAITING_DETAIL: `${FI_MANAGE_DEALER_REGISTRATION_PATH}/waiting/:waitingAnchorDealerId`,
    REGISTRATION_WAITING_DETAIL_BUILD_PATH: (waitingAnchorDealerId: number) =>
      `${FI_MANAGE_DEALER_REGISTRATION_PATH}/waiting/${waitingAnchorDealerId}`,
    ASSIGNMENT_LIST: `${FI_MANAGE_DEALER_ASSIGNMENT_PATH}/list`,
    ASSIGNMENT_LIST_ADMIN: `${FI_MANAGE_DEALER_ASSIGNMENT_PATH}/list-admin`,
    ASSIGNMENT_DETAIL: `${FI_MANAGE_DEALER_ASSIGNMENT_PATH}/:anchorDealerId`,
    ASSIGNMENT_DETAIL_BUILD_PATH: (anchorDealerId: number) => `${FI_MANAGE_DEALER_ASSIGNMENT_PATH}/${anchorDealerId}`,
  },
  MANAGE_PARTNER: {
    COMPANY_LIST: FI_MANAGE_PARTNER_COMPANY_PATH,
    COMPANY_REGISTER: `${FI_MANAGE_PARTNER_COMPANY_PATH}/register`,
    COMPANY_DETAIL: `${FI_MANAGE_PARTNER_COMPANY_PATH}/:financierClientId`,
    COMPANY_DETAIL_BUILD_PATH: (financierClientId: number) => `${FI_MANAGE_PARTNER_COMPANY_PATH}/${financierClientId}`,

    AGREEMENT_LIST: FI_MANAGE_PARTNER_AGREEMENT_PATH,
    AGREEMENT_REGISTER: `${FI_MANAGE_PARTNER_AGREEMENT_PATH}/register`,
    AGREEMENT_REGISTERED_DETAIL: `${FI_MANAGE_PARTNER_AGREEMENT_PATH}/registered-detail/:dealerAgreementId`,
    AGREEMENT_REGISTERED_DETAIL_BUILD_PATH: (dealerAgreementId: number) =>
      `${FI_MANAGE_PARTNER_AGREEMENT_PATH}/registered-detail/${dealerAgreementId}`,
    AGREEMENT_WAITING_DETAIL: `${FI_MANAGE_PARTNER_AGREEMENT_PATH}/waiting-detail/:waitingDealerAgreementId`,
    AGREEMENT_WAITING_DETAIL_BUILD_PATH: (waitingDealerAgreementId: number) =>
      `${FI_MANAGE_PARTNER_AGREEMENT_PATH}/waiting-detail/${waitingDealerAgreementId}`,

    REPORT_LIST: FI_MANAGE_PARTNER_REPORT_PATH,
    REPORT_REGISTER: `${FI_MANAGE_PARTNER_REPORT_PATH}/register`,
    REPORT_DETAIL: `${FI_MANAGE_PARTNER_REPORT_PATH}/:anchorReportReceiverId`,
    REPORT_DETAIL_BUILD_PATH: (anchorReportReceiverId: number) =>
      `${FI_MANAGE_PARTNER_REPORT_PATH}/${anchorReportReceiverId}`,

    USER_LIST: FI_MANAGE_PARTNER_USER_PATH,
    USER_DETAIL: `${FI_MANAGE_PARTNER_USER_PATH}/:dealerAgreementId`,
    USER_DETAIL_BUILD_PATH: (dealerAgreementId: number) => `${FI_MANAGE_PARTNER_USER_PATH}/${dealerAgreementId}`,
    USER_DETAIL_EMAIL_SETTING: `${FI_MANAGE_PARTNER_USER_PATH}/:userId/email-setting`,
    USER_DETAIL_EMAIL_SETTING_BUILD_PATH: (userId: number) => `${FI_MANAGE_PARTNER_USER_PATH}/${userId}/email-setting`,
  },

  REGISTER_INVOICE: {
    REGISTRATION_STEP1: FI_REGISTER_INVOICE_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${FI_REGISTER_INVOICE_REGISTRATION_PATH}/:dealerAgreementId/:financierId`,
    REGISTRATION_STEP2_BUILD_PATH: (dealerAgreementId: number, financierId: number) =>
      `${FI_REGISTER_INVOICE_REGISTRATION_PATH}/${dealerAgreementId}/${financierId}`,

    CONFIRMATION_LIST: FI_REGISTER_INVOICE_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${FI_REGISTER_INVOICE_CONFIRMATION_PATH}/:invoiceSummaryId/:financierEnterpriseId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (invoiceSummaryId: number, financierEnterpriseId: number) =>
      `${FI_REGISTER_INVOICE_CONFIRMATION_PATH}/${invoiceSummaryId}/${financierEnterpriseId}`,
  },

  REGISTER_AR: {
    CONFIRMATION_LIST: FI_REGISTER_AR_CONFIRMATION_PATH,
    CONFIRMATION_DETAIL: `${FI_REGISTER_AR_CONFIRMATION_PATH}/:arSummaryId`,
    CONFIRMATION_DETAIL_BUILD_PATH: (arSummaryId: number) => `${FI_REGISTER_AR_CONFIRMATION_PATH}/${arSummaryId}`,
  },

  MANAGE_FINANCING: {
    APPROVAL_LIST: FI_MANAGE_FINANCING_APPROVAL_PATH,
    APPROVAL_DETAIL: `${FI_MANAGE_FINANCING_APPROVAL_PATH}/:loanId`,
    APPROVAL_DETAIL_BUILD_PATH: (loanId: number) => `${FI_MANAGE_FINANCING_APPROVAL_PATH}/${loanId}`,

    BULK_APPROVAL_LIST: FI_MANAGE_FINANCING_BULK_APPROVAL_PATH,
    BULK_APPROVAL_DETAIL: `${FI_MANAGE_FINANCING_BULK_APPROVAL_PATH}/:multipleRequestLoansId`,
    BULK_APPROVAL_DETAIL_BUILD_PATH: (multipleRequestLoansId: number) =>
      `${FI_MANAGE_FINANCING_BULK_APPROVAL_PATH}/${multipleRequestLoansId}`,

    EXTENSION_LIST: FI_MANAGE_FINANCING_EXTENSION_PATH,
    EXTENSION_DETAIL: `${FI_MANAGE_FINANCING_EXTENSION_PATH}/:extensionRequestId`,
    EXTENSION_DETAIL_BUILD_PATH: (extensionRequestId: number) =>
      `${FI_MANAGE_FINANCING_EXTENSION_PATH}/${extensionRequestId}`,

    EARLY_REPAYMENT_LIST: FI_MANAGE_FINANCING_EARLY_REPAYMENT_PATH,
    EARLY_REPAYMENT_DETAIL: `${FI_MANAGE_FINANCING_EARLY_REPAYMENT_PATH}/:earlyRepaymentRequestId`,
    EARLY_REPAYMENT_DETAIL_BUILD_PATH: (earlyRepaymentRequestId: number) =>
      `${FI_MANAGE_FINANCING_EARLY_REPAYMENT_PATH}/${earlyRepaymentRequestId}`,

    SECURED_INVENTORY_UPDATE_LIST: FI_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH,
    SECURED_INVENTORY_UPDATE_DETAIL: `${FI_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH}/:inventorySummaryId`,
    SECURED_INVENTORY_UPDATE_DETAIL_BUILD_PATH: (inventorySummaryId: number) =>
      `${FI_MANAGE_FINANCING_SECURED_INVENTORY_UPDATE_PATH}/${inventorySummaryId}`,
  },

  VIEW_TRANSACTION: {
    INVOICE_LIST: FI_VIEW_TRANSACTION_INVOICE_PATH,
    INVOICE_DETAIL: `${FI_VIEW_TRANSACTION_INVOICE_PATH}/:successInvoiceId`,
    INVOICE_DETAIL_BUILD_PATH: (successInvoiceId: number) => `${FI_VIEW_TRANSACTION_INVOICE_PATH}/${successInvoiceId}`,

    INVOICE_PAYMENT_LIST: FI_VIEW_TRANSACTION_INVOICE_PAYMENT_PATH,

    AR_LIST: FI_VIEW_TRANSACTION_AR_PATH,
    AR_DETAIL: `${FI_VIEW_TRANSACTION_AR_PATH}/:successArId`,
    AR_DETAIL_BUILD_PATH: (successArId: number) => `${FI_VIEW_TRANSACTION_AR_PATH}/${successArId}`,

    AR_SETTLEMENT_LIST: FI_VIEW_TRANSACTION_AR_SETTLEMENT_PATH,
    AR_SETTLEMENT_DETAIL: `${FI_VIEW_TRANSACTION_AR_SETTLEMENT_PATH}/:anchorAgreementId`,
    AR_SETTLEMENT_DETAIL_BUILD_PATH: (anchorAgreementId: number) =>
      `${FI_VIEW_TRANSACTION_AR_SETTLEMENT_PATH}/${anchorAgreementId}`,

    DEPT_SELLING_NOTICE_LIST: FI_VIEW_TRANSACTION_DEPT_SELLING_NOTICE_PATH,
    DEPT_SELLING_NOTICE_DETAIL: `${FI_VIEW_TRANSACTION_DEPT_SELLING_NOTICE_PATH}/:factoringNoticeId`,
    DEPT_SELLING_NOTICE_BUILD_PATH: (factoringNoticeId: number) =>
      `${FI_VIEW_TRANSACTION_DEPT_SELLING_NOTICE_PATH}/${factoringNoticeId}`,

    SETTLEMENT_PAYMENT_LIST: FI_VIEW_TRANSACTION_SETTLEMENT_PAYMENT_PATH,
    SETTLEMENT_PAYMENT_DETAIL: `${FI_VIEW_TRANSACTION_SETTLEMENT_PAYMENT_PATH}/:dealerAgreementId/:settlementDate`,
    SETTLEMENT_PAYMENT_DETAIL_BUILD_PATH: (dealerAgreementId: number, settlementDate: string) =>
      `${FI_VIEW_TRANSACTION_SETTLEMENT_PAYMENT_PATH}/${dealerAgreementId}/${settlementDate}`,

    FINANCING_LIST: FI_VIEW_TRANSACTION_FINANCING_PATH,
    FINANCING_DETAIL: `${FI_VIEW_TRANSACTION_FINANCING_PATH}/:loanId`,
    FINANCING_DETAIL_BUILD_PATH: (loanId: number) => `${FI_VIEW_TRANSACTION_FINANCING_PATH}/${loanId}`,

    INTEREST_PAYMENT_LIST: FI_VIEW_TRANSACTION_INTEREST_PAYMENT_PATH,

    OVERVIEW_BY_ANCHOR_LIST: FI_VIEW_TRANSACTION_OVERVIEW_BY_ANCHOR_PATH,

    OVERVIEW_BY_PARTNER_LIST: FI_VIEW_TRANSACTION_OVERVIEW_BY_PARTNER_PATH,
  },

  MANAGE_UPLOADED_PARTNER: {
    BANK_ACCOUNT_REVISION_LIST: FI_MANAGE_POTENTIAL_PARTNER_BANK_ACCOUNT_REVISION_PATH,
    BANK_ACCOUNT_REVISION_CONFIRMED_DETAIL: `${FI_MANAGE_POTENTIAL_PARTNER_BANK_ACCOUNT_REVISION_PATH}/confirmed-detail/:anchorPartnerAccountId`,
    BANK_ACCOUNT_REVISION_CONFIRMED_DETAIL_BUILD_PATH: (anchorPartnerAccountId: number) =>
      `${FI_MANAGE_POTENTIAL_PARTNER_BANK_ACCOUNT_REVISION_PATH}/confirmed-detail/${anchorPartnerAccountId}`,
    BANK_ACCOUNT_REVISION_WAITING_DETAIL: `${FI_MANAGE_POTENTIAL_PARTNER_BANK_ACCOUNT_REVISION_PATH}/waiting-detail/:waitingAnchorPartnerAccountId`,
    BANK_ACCOUNT_REVISION_WAITING_DETAIL_BUILD_PATH: (waitingAnchorPartnerAccountId: number) =>
      `${FI_MANAGE_POTENTIAL_PARTNER_BANK_ACCOUNT_REVISION_PATH}/waiting-detail/${waitingAnchorPartnerAccountId}`,

    REGISTRATION_STEP1: FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH,
    REGISTRATION_STEP2: `${FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH}/:anchorAgreementId`,
    REGISTRATION_STEP2_BUILD_PATH: (anchorAgreementId: number) =>
      `${FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH}/${anchorAgreementId}`,
    REGISTRATION_LIST: `${FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH}/list`,
    REGISTRATION_CONFIRMED_DETAIL: `${FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH}/confirmed-detail/:anchorPartnerId`,
    REGISTRATION_CONFIRMED_DETAIL_BUILD_PATH: (anchorPartnerId: number) =>
      `${FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH}/confirmed-detail/${anchorPartnerId}`,
    REGISTRATION_WAITING_DETAIL: `${FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH}/waiting-detail/:waitingAnchorPartnerId`,
    REGISTRATION_WAITING_DETAIL_BUILD_PATH: (waitingAnchorPartnerId: number) =>
      `${FI_MANAGE_POTENTIAL_PARTNER_REGISTRATION_PATH}/waiting-detail/${waitingAnchorPartnerId}`,

    ASSIGNMENT_LIST: FI_MANAGE_POTENTIAL_PARTNER_ASSIGNMENT_PATH,
    ASSIGNMENT_DETAIL: `${FI_MANAGE_POTENTIAL_PARTNER_ASSIGNMENT_PATH}/:anchorPartnerAccountId`,
    ASSIGNMENT_DETAIL_BUILD_PATH: (anchorPartnerAccountId: number) =>
      `${FI_MANAGE_POTENTIAL_PARTNER_ASSIGNMENT_PATH}/${anchorPartnerAccountId}`,

    ACQUISITION_LIST: FI_MANAGE_POTENTIAL_PARTNER_ACQUISITION_PATH,
    ACQUISITION_SETTING: `${FI_MANAGE_POTENTIAL_PARTNER_ACQUISITION_PATH}/setting`,
    ACQUISITION_DETAIL: `${FI_MANAGE_POTENTIAL_PARTNER_ACQUISITION_PATH}/:potentialPartnerFinancingApplicationId`,
    ACQUISITION_DETAIL_BUILD_PATH: (potentialPartnerFinancingApplicationId: number) =>
      `${FI_MANAGE_POTENTIAL_PARTNER_ACQUISITION_PATH}/${potentialPartnerFinancingApplicationId}`,
  },

  SETTINGS: {
    BRANCH_LIST: FI_SETTINGS_BRANCH_PATH,
    BRANCH_REGISTER: `${FI_SETTINGS_BRANCH_PATH}/register`,
    BRANCH_DETAIL: `${FI_SETTINGS_BRANCH_PATH}/:branchId`,
    BRANCH_DETAIL_BUILD_PATH: (branchId: number) => `${FI_SETTINGS_BRANCH_PATH}/${branchId}`,

    USER_LIST: FI_SETTINGS_USER_PATH,
    USER_REGISTER: `${FI_SETTINGS_USER_PATH}/register`,
    USER_DETAIL: `${FI_SETTINGS_USER_PATH}/:userId`,
    USER_DETAIL_BUILD_PATH: (userId: number) => `${FI_SETTINGS_USER_PATH}/${userId}`,
  },

  PROGRAM_SETTINGS: {
    INTEREST_RATE_FEE_LIST: FI_PROGRAM_SETTINGS_INTEREST_RATE_FEE_PATH,
    INTEREST_RATE_FEE_REGISTER: `${FI_PROGRAM_SETTINGS_INTEREST_RATE_FEE_PATH}/register`,

    DELINQUENT_INTEREST_RATE_LIST: FI_PROGRAM_SETTINGS_DELINQUENT_INTEREST_RATE_PATH,
    DELINQUENT_INTEREST_RATE_REGISTER: `${FI_PROGRAM_SETTINGS_DELINQUENT_INTEREST_RATE_PATH}/register`,
    DELINQUENT_INTEREST_RATE_DETAIL: `${FI_PROGRAM_SETTINGS_DELINQUENT_INTEREST_RATE_PATH}/:overdueInterestRateSettingId`,
    DELINQUENT_INTEREST_RATE_DETAIL_BUILD_PATH: (overdueInterestRateSettingId: number) =>
      `${FI_PROGRAM_SETTINGS_DELINQUENT_INTEREST_RATE_PATH}/${overdueInterestRateSettingId}`,

    HOLIDAYS_LIST: FI_PROGRAM_SETTINGS_HOLIDAYS_PATH,
    HOLIDAYS_REGISTER: `${FI_PROGRAM_SETTINGS_HOLIDAYS_PATH}/register`,
    HOLIDAYS_DETAIL: `${FI_PROGRAM_SETTINGS_HOLIDAYS_PATH}/:financierCalendarId`,
    HOLIDAYS_DETAIL_BUILD_PATH: (financierCalendarId: number) =>
      `${FI_PROGRAM_SETTINGS_HOLIDAYS_PATH}/${financierCalendarId}`,

    OPERATION_HOURS: FI_PROGRAM_SETTINGS_OPERATION_HOURS_PATH,

    DOCUMENT_LIST: FI_PROGRAM_SETTINGS_DOCUMENT_PATH,
    DOCUMENT_REGISTER: `${FI_PROGRAM_SETTINGS_DOCUMENT_PATH}/register`,
    DOCUMENT_DETAIL: `${FI_PROGRAM_SETTINGS_DOCUMENT_PATH}/:financierDocumentFormId`,
    DOCUMENT_DETAIL_BUILD_PATH: (financierDocumentFormId: number) =>
      `${FI_PROGRAM_SETTINGS_DOCUMENT_PATH}/${financierDocumentFormId}`,
    ADDITIONAL_DOCUMENT_REGISTER: `${FI_PROGRAM_SETTINGS_DOCUMENT_PATH}/additional-document-register`,
    ADDITIONAL_DOCUMENT_DETAIL: `${FI_PROGRAM_SETTINGS_DOCUMENT_PATH}/additional-document/:financierDocumentFormId`,
    ADDITIONAL_DOCUMENT_DETAIL_BUILD_PATH: (financierDocumentFormId: number) =>
      `${FI_PROGRAM_SETTINGS_DOCUMENT_PATH}/additional-document/${financierDocumentFormId}`,

    BANK_CODE_LIST: FI_PROGRAM_SETTINGS_BANK_CODE_PATH,
    BANK_CODE_REGISTER: `${FI_PROGRAM_SETTINGS_BANK_CODE_PATH}/register`,
    BANK_CODE_CONFIRMED_DETAIL: `${FI_PROGRAM_SETTINGS_BANK_CODE_PATH}/confirmed-detail/:bankCodeId`,
    BANK_CODE_CONFIRMED_BUILD_PATH: (bankCodeId: number) =>
      `${FI_PROGRAM_SETTINGS_BANK_CODE_PATH}/confirmed-detail/${bankCodeId}`,
    BANK_CODE_WAITING_DETAIL: `${FI_PROGRAM_SETTINGS_BANK_CODE_PATH}/waiting-detail/:waitingBankCodeId`,
    BANK_CODE_WAITING_BUILD_PATH: (waitingBankCodeId: number) =>
      `${FI_PROGRAM_SETTINGS_BANK_CODE_PATH}/waiting-detail/${waitingBankCodeId}`,

    FINANCING: FI_PROGRAM_SETTINGS_FINANCING_PATH,

    CHARGE: FI_PROGRAM_SETTINGS_CHARGE_PATH,
    CHARGE_REGISTER: `${FI_PROGRAM_SETTINGS_CHARGE_PATH}/register`,
    CHARGE_DETAIL: `${FI_PROGRAM_SETTINGS_CHARGE_PATH}/:arCommissionSettingId`,
    CHARGE_DETAIL_BUILD_PATH: (arCommissionSettingId: number) =>
      `${FI_PROGRAM_SETTINGS_CHARGE_PATH}/${arCommissionSettingId}`,

    EMAIL_NOTIFICATION: FI_PROGRAM_SETTINGS_EMAIL_NOTIFICATION_PATH,
  },

  BILLING: FI_BILLING,

  MY_ACCOUNT: FI_MY_ACCOUNT,
};
