import type { TempInvoiceSummaryVO, TempInvoiceSummaryVOModel } from 'models/vo/TempInvoiceSummaryVO';
import { formattingToTempInvoiceSummaryVOModel } from 'models/vo/TempInvoiceSummaryVO';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';

import type { TempInvoiceSummaryRegisterRequest } from './requests';

export async function requestTempInvoiceSummaryData(dealerAgreementId: number): Promise<TempInvoiceSummaryVOModel> {
  const response = await http.get<TempInvoiceSummaryVO>({
    url: API_CM.TEMP_INVOICE_SUMMARIES.TEMP_INVOICE_DETAIL(dealerAgreementId),
  });

  return formattingToTempInvoiceSummaryVOModel(response);
}

export async function requestTempInvoiceSummaryRegister(
  tempInvoiceSummaryRegisterRequest: TempInvoiceSummaryRegisterRequest,
): Promise<TempInvoiceSummaryVOModel> {
  const response = await http.post<TempInvoiceSummaryVO>({
    url: API_CM.TEMP_INVOICE_SUMMARIES.TEMP_INVOICE_SUMMARIES,
    data: tempInvoiceSummaryRegisterRequest,
  });

  return formattingToTempInvoiceSummaryVOModel(response);
}
