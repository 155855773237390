import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FormRadio, FormRadioWrap } from 'components/stateless/CommonForm';
import type { TermSpreadVOModel } from 'models/vo/TermSpreadVO';

interface SyPartnerAgreementRegisteredDetailTermSpreadProps {
  data: TermSpreadVOModel[];
}

function SyPartnerAgreementRegisteredDetailTermSpread({
  data,
}: SyPartnerAgreementRegisteredDetailTermSpreadProps): JSX.Element {
  const { t } = useTranslation(['format']);

  const useTermSpread = (data?.length ?? 0) > 0;

  const renderTermSpreadRow = (): ReactNode => {
    return data.map((item, index, array) => {
      return (
        <tr key={`termspreadrow-${index}`}>
          <td>
            <div className="scope-box">
              <div className="text-start">
                <div className="spread-xs-input">
                  {t('format:number', {
                    value: item.minimumDateRange,
                  })}
                </div>
              </div>
              <div className="angle-sign">&lt;</div>
              <div>
                <strong>T</strong>
              </div>
              {(data.length || 0) > index + 1 && (
                <>
                  <div className="angle-sign">≤</div>
                  <div />
                  <div className="text-start">
                    <div className="spread-xs-input">
                      {t('format:number', {
                        value: array[index + 1] ? array[index + 1].minimumDateRange : '',
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </td>
          <td>
            <div className="spread-xs-input me-1">
              {t('format:number', {
                value: item.termSpreadRate,
              })}
            </div>{' '}
            %
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="row">
      <FormRadioWrap label={t('text:Term_Spread')} col={12}>
        <FormRadio label={t('text:Applied')} defaultChecked={useTermSpread} disabled />
        <FormRadio label={t('text:Not_Applied')} defaultChecked={!useTermSpread} disabled />
        {useTermSpread && (
          <table className="table-border spread-form text-center sub200-bg">
            <thead>
              <tr>
                <th scope="col">{t('text:Term')}</th>
                <th scope="col">{t('text:Term_Spread')} (%)</th>
              </tr>
            </thead>
            <tbody className="bg-white">{renderTermSpreadRow()}</tbody>
          </table>
        )}
      </FormRadioWrap>
    </div>
  );
}

export default SyPartnerAgreementRegisteredDetailTermSpread;
