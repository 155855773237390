import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import { ANCHOR_AGREEMENT_STATUS, AUTHORITY_TYPE, COLLATERAL_TYPE, DEALER_IDENTIFIER_TYPE, LOAN_OPTION } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import { convertToAnchorAgreementResetData } from 'models/convertor/anchorAgreement';
import { formErrorHandler } from 'utils/error/manager';
import { isNilOrEmptyString } from 'utils/helpers';
import { requestRegisterWaitingAnchorAgreement } from 'utils/http/api/financier/waiting-anchor-agreements';
import type { CreateFiWaitingAnchorAgreementDTO } from 'utils/http/api/financier/waiting-anchor-agreements/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getObjectValue } from 'utils/object';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import useAgreementViewModel from '../models/agreement/useAgreementViewModel';
import useAnchorClientInfoViewModel from '../models/agreement/useAnchorClientInfoViewModel';
import useDivisionsViewModel from '../models/divisions/useDivisionsViewModel';
import useExtraInformationViewModel from '../models/extraInformation/useExtraInformationViewModel';

const useRegistrationDetailController = () => {
  const signInModel: SignInModel | null = getSignIn();
  const { branchId, authorityType } = signInModel ?? {};
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();

  const {
    supportedCollateralType,
    errorHandlerOfLocationState,
    anchorAgreementId,
    useAgreementInterface,
    useDepositTransaction,
  } = useExtraInformationViewModel();

  const { agreement, fetchAgreementDetail, updateIsSearchedAgreement, updateAllAgreement, isSearchedAgreement } =
    useAgreementViewModel();

  const { updateDivisionName, fetchDivisionRegistrable } = useDivisionsViewModel();

  const { anchorClientInfo } = useAnchorClientInfoViewModel();

  const methods = useForm<CreateFiWaitingAnchorAgreementDTO>();
  const { reset, getValues, setError, clearErrors } = methods;

  const [isEditable, setIsEditable] = useState(false);
  const [isAgreementTab, setIsAgreementTab] = useState<boolean>(true);

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const isUserEditable = branchId === agreement?.branchId && authorityType === AUTHORITY_TYPE.OPERATOR;

  const isEditButtonVisible = isUserEditable && isAgreementTab;

  const isEditableStyle = useAgreementInterface ? isSearchedAgreement : isEditable;

  const isDesignatedAccountTransactionHistoryRender = useAgreementInterface || useDepositTransaction;

  const getReadOnlyValue = useCallback(
    (parameterName?: string): boolean => {
      if (!useAgreementInterface || !isEditable) return false;

      const allAgreement = { ...agreement, ...anchorClientInfo };

      const agreementValue = getObjectValue({
        object: allAgreement,
        name: parameterName ?? '',
      });

      return !(isSearchedAgreement && isNilOrEmptyString(agreementValue));
    },
    [useAgreementInterface, isEditable, agreement, anchorClientInfo, isSearchedAgreement],
  );

  const GuideMessageText = (() => {
    const isShowGuideMessage = agreement.anchorAgreementStatus === ANCHOR_AGREEMENT_STATUS.SUSPENDED;

    if (!isShowGuideMessage) return [];

    const fixedMessage = t('text:The_master_agreement_and_all_subsequent_transactions_have_been_suspended');

    return isUserEditable
      ? [
          fixedMessage,
          t(
            'text:To_allow_transactions_to_resume_modify_the_Agreement_Suspension_field_in_the_General_Information_section',
          ),
        ]
      : [fixedMessage];
  })();

  const resetPage = async () => {
    if (isNil(anchorAgreementId) || errorHandlerOfLocationState()) return;

    const [agreementDetail] = await Promise.all([fetchAgreementDetail(anchorAgreementId), fetchDivisionRegistrable()]);

    if (agreementDetail) {
      reset(convertToAnchorAgreementResetData(agreementDetail));
      setIsEditable(false);
      updateDivisionName(agreementDetail.divisionName);
      updateIsSearchedAgreement(false);
      updateAllAgreement(agreementDetail);
    }
  };

  // Tab Button
  const handleRevisionHistoryTabClick = () => setIsAgreementTab(false);
  const handleAgreementTabClick = () => setIsAgreementTab(true);

  // Cancel Button
  const handleCancelButtonClick = () => {
    showModal(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_agreement?')}
        <br />
        {t('text:If_the_modification_is_stopped_the_entered_content_will_not_be_saved')}.
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: resetPage,
      },
    );
  };

  const handleARRequiredFieldsValidation = () => {
    const { paymentSupport, settlementAccount } = getValues();
    /**
     * AR에서 필수인 필드 (조건부 필수 필드 포함) 중,
     * BE에서 다른 필드보다 후순위에 에러 검증이 되어 에러 객체가 늦게 내려오는 필드. 사용자 입장에서 다른 필드와 동시에 에러가 발생할 수 있도록 개발
     */
    const arRequiredFields = [
      { name: 'paymentSupport', value: paymentSupport, isRequired: true },
      { name: 'settlementAccount', value: settlementAccount, isRequired: paymentSupport === 'true' },
    ];

    arRequiredFields.forEach(({ value, name, isRequired }) => {
      if (isRequired && isNilOrEmptyString(value)) {
        setError(name, {
          message: t('text:This_information_is_required'),
        });
      } else clearErrors(name);
    });
  };

  // Request Change Button
  const updateAgreement = async () => {
    const data = getValues();

    const bankUserIdList = data.bankUserIdList as string;
    data.bankUserIdList = bankUserIdList ? bankUserIdList.split(',') : [];
    data.targetAnchorAgreementId = anchorAgreementId;

    if (isAr) {
      data.loanOption = LOAN_OPTION.NONE;
      data.dealerIdentifierType = DEALER_IDENTIFIER_TYPE.TAX_CODE;
    }

    requestDTOParser(data);
    await requestRegisterWaitingAnchorAgreement(data);
  };

  const showRequestChangeCompletedModal = () => {
    showModal(
      <h6>
        {t('text:Request_for_modification_approval_has_been_completed')}
        <br />
        {t('text:Modification_will_be_completed_after_approval_by_the_Authorizer')}
      </h6>,
      {
        closeBtnText: t('text:OK'),
        closeBtnCb: resetPage,
      },
    );
  };

  const handleRequestChangeButtonClick = () => {
    showModal(<h6>{t('text:Would_you_like_to_request_approval_for_modification?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnText: t('text:Confirm'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await updateAgreement();
          showRequestChangeCompletedModal();
        } catch (error) {
          showModal(error);
          formErrorHandler<CreateFiWaitingAnchorAgreementDTO>(error, setError, clearErrors);
          if (isAr) handleARRequiredFieldsValidation();
        }
      },
    });
  };

  // Edit Button
  const handleEditButtonClick = () => setIsEditable(true);

  useEffect(() => {
    resetPage();
  }, []);

  return {
    isInvoice,
    isEditable,
    isEditButtonVisible,
    useAgreementInterface,
    isEditableStyle,
    isRequestChangeButtonDisabled: !(useAgreementInterface ? isSearchedAgreement : true),
    isDesignatedAccountTransactionHistoryRender,
    getReadOnlyValue,
    GuideMessageText,
    methods,
    handleRevisionHistoryTabClick,
    handleAgreementTabClick,
    handleEditButtonClick,
    handleCancelButtonClick,
    handleRequestChangeButtonClick,
  };
};

export default useRegistrationDetailController;
