import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { SUCCESS_AR_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestDealerSuccessArsList } from 'utils/http/api/dealer/success-ars';
import type { DealerSuccessArsListRequest } from 'utils/http/api/dealer/success-ars/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const DE_AR_LIST_QS_KEY = 'de-ar-list';

function DealerArList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { pageable, setPageable } = usePageable(DE_AR_LIST_QS_KEY);
  const [successArPage, setSuccessArPage] = useState<Pageable<SuccessArVOModel[]>>();

  // useForm
  const { register, getValues, reset, setValue, control } = useForm<DealerSuccessArsListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<DealerSuccessArsListRequest>(setValue, getParsedSearchParams(DE_AR_LIST_QS_KEY).formSearchData);
      fetchDeSuccessArsList(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  async function fetchDeSuccessArsList(
    pageNumber: number = 0,
    rowCount: number = 10,
    data: DealerSuccessArsListRequest,
  ) {
    try {
      const dealerSuccessArsPage = await requestDealerSuccessArsList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        DE_AR_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSuccessArPage(dealerSuccessArsPage);
        setPageable(dealerSuccessArsPage);
      });
    } catch (e) {
      modal.show(e);
    }
  }

  const onClickSearch = async () => {
    await fetchDeSuccessArsList(0, pageable.sizePerPage, getValues());
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchDeSuccessArsList(page, sizePerPage, getParsedSearchParams(DE_AR_LIST_QS_KEY).formSearchData);
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();
    reset({});
  };

  const onClickSettlementStatusModal = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_AR_STATUS)}
        statusDescriptionEnumType="SUCCESS_AR_STATUS"
        statusDescriptionModalType="TEXT"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
      },
    );
  };
  const getTableHeaders = (isPDFHeader = false) => {
    const headers: HeaderType[] = [
      {
        headerText: t('text:Anchor_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Anchor_Tax_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:AR_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:AR_Issued_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Original_Settlement_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Modified_Settlement_Date'),
        colWidths: 110,
      },
      {
        headerText: t('text:AR_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financing_Eligibility'),
        colWidths: 100,
      },
      {
        headerText: t('text:Settlement_Status'),
        colWidths: 120,
        hasStatusDescription: true,
        showStatusDescriptionFunc: onClickSettlementStatusModal,
      },
    ];

    return isPDFHeader ? headers : [...headers, { headerText: '', colWidths: 50 }];
  };

  const renderResultTable = () => {
    return successArPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.anchorName} />
          <Td data={item.anchorTaxCode} />
          <Td data={item.arNumber} />
          <Td data={item.arIssuedDate} format="date" />
          <Td data={item.maturityDate} format="date" />
          <Td data={item.settlementDate} format="date" />
          <Td data={item.arAmount} format="number" />
          <Td data={item.currencyType} />
          <Td
            className={getBlockedStatusClassName(item.blockedByFinancier)}
            data={item.blockedByFinancier ? t('text:Ineligible') : t('text:Eligible')}
          />
          <Td
            className={getStatusTextClass('SUCCESS_AR_STATUS', item.successArStatus)}
            data={t(`code:success-ar-status.${item.successArStatus}`)}
            format="code"
          />
          <TdLink
            path={ROUTES_DE.VIEW_TRANSACTION.AR_DETAIL_BUILD_PATH(item.successArId)}
            state={{ financierId: item.financierId }}
          />
        </Tr>
      );
    });
  };

  const onClickExportButton = async () => {
    const data = getParsedSearchParams(DE_AR_LIST_QS_KEY).formSearchData;

    try {
      const sucessArListResponse = await requestDealerSuccessArsList(0, EXCEL_EXPORT_MAX_ROW_COUNT, data);
      const sucessArListPdfResponse = sucessArListResponse.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: unknown[] = sucessArListResponse.content.map(item => ({
        anchorName: tableValueManage(item.anchorName),
        anchorTaxCode: tableValueManage(item.anchorTaxCode),
        arNumber: tableValueManage(item.arNumber),
        arIssuedDate: tableValueManage(item.arIssuedDate, t('format:date', { value: item.arIssuedDate, key: 'date' })),
        maturityDate: tableValueManage(item.maturityDate, t('format:date', { value: item.maturityDate, key: 'date' })),
        settlementDate: tableValueManage(
          item.settlementDate,
          t('format:date', { value: item.settlementDate, key: 'date' }),
        ),
        arAmount: Number(item.arAmount),
        currencyType: tableValueManage(item.currencyType),
        blockedByFinancier: item.blockedByFinancier ? t('text:Ineligible') : t('text:Eligible'),
        successArStatus: tableValueManage(item.successArStatus, t(`code:success-ar-status.${item.successArStatus}`)),
      }));

      const excelColumns: ColumnOption<SuccessArVOModel>[] = [
        {
          header: t('text:Anchor_Name'),
          key: 'anchorName',
        },
        {
          header: t('text:Anchor_Tax_Code'),
          key: 'anchorTaxCode',
        },
        {
          header: t('text:AR_Number'),
          key: 'arNumber',
        },
        {
          header: t('text:AR_Issued_Date'),
          key: 'arIssuedDate',
        },
        {
          header: t('text:Original_Settlement_Date'),
          key: 'maturityDate',
        },
        {
          header: t('text:Modified_Settlement_Date'),
          key: 'settlementDate',
        },
        {
          header: t('text:AR_Amount'),
          key: 'arAmount',
        },
        {
          header: t('text:Currency'),
          key: 'currencyType',
        },
        {
          header: t('text:Financing_Eligibility'),
          key: 'blockedByFinancier',
        },
        {
          header: t('text:Settlement_Status'),
          key: 'successArStatus',
        },
      ];

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const renderPDFTableBodyResult = () => {
        return sucessArListPdfResponse.map((item, i) => {
          return (
            <Tr key={i} className="virtual-table-row">
              <Td data={item.anchorName} />
              <Td data={item.anchorTaxCode} />
              <Td data={item.arNumber} />
              <Td data={item.arIssuedDate} format="date" />
              <Td data={item.maturityDate} format="date" />
              <Td data={item.settlementDate} format="date" />
              <Td data={item.arAmount} format="number" />
              <Td data={item.currencyType} />
              <Td
                className={getBlockedStatusClassName(item.blockedByFinancier)}
                data={item.blockedByFinancier ? t('text:Ineligible') : t('text:Eligible')}
              />
              <Td
                className={getStatusTextClass('SUCCESS_AR_STATUS', item.successArStatus)}
                data={t(`code:success-ar-status.${item.successArStatus}`)}
                format="code"
              />
            </Tr>
          );
        });
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: getTableHeaders(true),
        tableBody: renderPDFTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:AR')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorName" ref={register} />
              <SearchLabel label={t('text:Anchor_Tax_Code')} />
              <SearchInput name="anchorTaxCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:AR_Number')} />
              <SearchInput name="arNumber" ref={register} />
              <SearchLabel label={t('text:AR_Issued_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="arIssuedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="arIssuedDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Original_Settlement_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="maturityDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="maturityDateTo" control={control} />
              <SearchLabel label={t('text:Modified_Settlement_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={control} />
              <SearchLabel label={t('text:AR_Amount')} />
              <SearchInput col={2} name="minimumArAmount" ref={register} placeholder={t('text:from')} />
              <SearchInput col={2} name="maximumArAmount" ref={register} placeholder={t('text:to')} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financing_Eligibility')} />
              <SearchSelect
                selectOptions={getSelectOptions<string>('FINANCING_ELIGIBILITY', 'ALL', true)}
                name="blocked"
                ref={register}
              />
              <SearchLabel
                label={t('text:Settlement_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickSettlementStatusModal}
              />
              <SearchSelect
                selectOptions={getSelectOptions<SUCCESS_AR_STATUS>('SUCCESS_AR_STATUS', 'ALL', true)}
                name="successArStatus"
                ref={register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {successArPage?.totalElements}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={getTableHeaders()} />
          <TableBody numOfCol={getTableHeaders().length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerArList;
