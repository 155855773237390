import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import {
  AUTHORITY_TYPE,
  AUTHORIZER_STATUS,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  DEALER_AGREEMENT_STATUS,
  LOAN_LIMIT_CHECK_TYPE,
  SUPPORTED_COLLATERAL_TYPE,
} from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { WaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import type { BigNumber } from 'utils/bigNumber';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierDealerAgreementList } from 'utils/http/api/financier/dealer-agreements';
import type { FinancierDealerAgreementListRequest } from 'utils/http/api/financier/dealer-agreements/request';
import { requestFinancierWaitingDealerAgreementList } from 'utils/http/api/financier/waiting-dealer-agreements';
import type { SearchFiWaitingDealerAgreementDTO } from 'utils/http/api/financier/waiting-dealer-agreements/request';
import { getWaitingForApprovalCount } from 'utils/logic';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES, setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';
import { getCollateralType as getCollateralTypeFromLocalStorage, getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const FI_DEALER_AGREEMENT_WAITING_LIST_QS_KEY = 'fi-dealer-agreement-waiting-list';
const FI_DEALER_AGREEMENT_COMPLETED_LIST_QS_KEY = 'fi-dealer-agreement-completed-list';

function FinancierDealerAgreementList() {
  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();
  const { t } = useTranslation(['format']);

  const supportedCollateralType = getCollateralTypeFromLocalStorage();
  const signInModel: SignInModel | null = getSignIn();

  const [dealerAgreementList, setDealerAgreementList] = useState<Pageable<DealerAgreementVOModel[]>>();
  const [waitingDealerAgreementList, setWaitingDealerAgreementList] =
    useState<Pageable<WaitingDealerAgreementVOModel[]>>();
  const [notCancelledWaitingDealerAgreementList, setNotCancelledWaitingDealerAgreementList] =
    useState<Pageable<WaitingDealerAgreementVOModel[]>>();

  const dealerAgreementPageable = usePageable(FI_DEALER_AGREEMENT_COMPLETED_LIST_QS_KEY);
  const waitingDealerAgreementPageable = usePageable(FI_DEALER_AGREEMENT_WAITING_LIST_QS_KEY);

  const dealerAgreementSearchForm = useForm<FinancierDealerAgreementListRequest>();
  const waitingDealerAgreementSearchForm = useForm<SearchFiWaitingDealerAgreementDTO>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  const setWaitingTabSearchValue = (formSearchData?: SearchFiWaitingDealerAgreementDTO) => {
    const getSearchFormData = formSearchData ? formSearchData : waitingDealerAgreementSearchForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchAll = async () => {
    setFormValues<FinancierDealerAgreementListRequest>(
      dealerAgreementSearchForm.setValue,
      getParsedSearchParams(FI_DEALER_AGREEMENT_COMPLETED_LIST_QS_KEY).formSearchData,
    );
    setFormValues<SearchFiWaitingDealerAgreementDTO>(
      waitingDealerAgreementSearchForm.setValue,
      getParsedSearchParams(FI_DEALER_AGREEMENT_WAITING_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
    const completeDealerAgreementSearchData = dealerAgreementSearchForm.getValues();

    const addFiDealerAgreementListQs = () => {
      const waitingQs = makeSearchParamsPattern(
        {
          ...waitingForApprovalSearchData,
          pageNumber: waitingDealerAgreementPageable.pageable.currentPage,
          rowCount: waitingDealerAgreementPageable.pageable.sizePerPage,
        },
        FI_DEALER_AGREEMENT_WAITING_LIST_QS_KEY,
      );
      const completedQs = makeSearchParamsPattern(
        {
          ...completeDealerAgreementSearchData,
          pageNumber: dealerAgreementPageable.pageable.currentPage,
          rowCount: dealerAgreementPageable.pageable.sizePerPage,
        },
        FI_DEALER_AGREEMENT_COMPLETED_LIST_QS_KEY,
      );
      addSearchParams(waitingQs + completedQs);
    };
    addFiDealerAgreementListQs();

    try {
      const [
        fetchedDealerAgreementList,
        fetchedWaitingDealerAgreementList,
        fetchedNotCancelledWaitingDealerAgreementList,
      ] = await Promise.all([
        requestFinancierDealerAgreementList(
          dealerAgreementPageable.pageable.currentPage,
          dealerAgreementPageable.pageable.sizePerPage,
          completeDealerAgreementSearchData,
        ),
        requestFinancierWaitingDealerAgreementList(
          waitingDealerAgreementPageable.pageable.currentPage,
          waitingDealerAgreementPageable.pageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestFinancierWaitingDealerAgreementList(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementList(fetchedDealerAgreementList);
        dealerAgreementPageable.setPageable(fetchedDealerAgreementList);
        setWaitingDealerAgreementList(fetchedWaitingDealerAgreementList);
        waitingDealerAgreementPageable.setPageable(fetchedWaitingDealerAgreementList);
        setNotCancelledWaitingDealerAgreementList(fetchedNotCancelledWaitingDealerAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickConfirmationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(COMMON_APPROVAL_TYPE)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickUserRegistrationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(AUTHORIZER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="AUTHORIZER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickRegisterAgreement = (e: any, type: COLLATERAL_TYPE): void => {
    e.preventDefault();

    type === COLLATERAL_TYPE.INVOICE
      ? history.push(ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTER, {
          supportedCollateralType: COLLATERAL_TYPE.INVOICE,
        })
      : history.push(ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTER, {
          supportedCollateralType: COLLATERAL_TYPE.AR,
        });
  };

  const renderFinancingLimitLoanLimitCheckTypeIsNotNone = (
    loanLimitAmount: BigNumber,
    adhocLimitAllowable: boolean,
    adhocLimitAmount: BigNumber,
  ) => {
    return (
      <>
        {tableValueManage(loanLimitAmount, t('format:number', { value: loanLimitAmount }))}
        {adhocLimitAllowable && String(adhocLimitAmount) && (
          <>
            <br />
            <span className="text-bold-brick-red">+ {t('format:number', { value: adhocLimitAmount })}</span>
          </>
        )}
      </>
    );
  };

  const renderFinancingLimit = (
    loanLimitCheckType: keyof typeof LOAN_LIMIT_CHECK_TYPE,
    limitCheckTypeIsNotNoneElement: JSX.Element,
  ) => {
    return loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE ? <>-</> : limitCheckTypeIsNotNoneElement;
  };

  const renderWaitingTabView = (): JSX.Element => {
    const WAITING_DEALER_AGREEMENT_LIST_HEADER: HeaderType[] = [
      {
        headerText: t('text:Program_Type'),
        colWidths: 100,
      },
      {
        headerText: t('text:Partner_Master_Agreement_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 96,
      },
      {
        headerText: t('text:Partner_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Partner_Client_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Associated_Anchor_Client_Code'),
        colWidths: 150,
      },
      {
        headerText: t('text:Responsible_Bank_Branch_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Responsible_Bank_Branch_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financing_Limit_(+_Ad_hoc_limit)'),
        colWidths: 150,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Approval_Status'),
        colWidths: 100,
        hasStatusDescription: true,
        showStatusDescriptionFunc: onClickConfirmationStatus,
      },
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    const renderWaitingDealerAgreementResultTable = (): JSX.Element[] | undefined => {
      const renderApprovalType = (approvalType: COMMON_APPROVAL_TYPE): JSX.Element => {
        switch (approvalType) {
          case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
            return t('code:common-approval-type.OPERATOR_REQUEST');
          case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
            return t('code:common-approval-type.OPERATOR_CANCEL');
          case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
            return t('code:common-approval-type.AUTHORIZER_APPROVED');
          case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
            return t('text:Request_Reverted');
          default:
            return approvalType;
        }
      };

      return waitingDealerAgreementList?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.dealerClientName} />
          <Td data={item.dealerClientCode} />
          <Td data={item.anchorClientName} />
          <Td data={item.anchorClientCode} />
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td className="text-end">
            {renderFinancingLimit(
              item.loanLimitCheckType,
              renderFinancingLimitLoanLimitCheckTypeIsNotNone(
                item.loanLimitAmount,
                item.adhocLimitAllowable,
                item.adhocLimitAmount,
              ),
            )}
          </Td>
          <Td data={item.startDate} format="date" />
          <Td data={item.expiryDate} format="date" />
          <Td className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}>
            {renderApprovalType(item.approvalType)}
          </Td>
          <TdLink
            path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_WAITING_DETAIL_BUILD_PATH(item.waitingDealerAgreementId)}
            state={{ supportedCollateralType: item.collateralType }}
          />
        </Tr>
      ));
    };

    const waitingDealerAgreementPaginate = async (pageNumber: number, rowCount: number) => {
      const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
        getParsedSearchParams(FI_DEALER_AGREEMENT_WAITING_LIST_QS_KEY).formSearchData,
      );
      try {
        const fetchedWaitingDealerAgreementList = await requestFinancierWaitingDealerAgreementList(
          pageNumber,
          rowCount,
          waitingForApprovalSearchData,
        );
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber,
            rowCount,
          },
          FI_DEALER_AGREEMENT_WAITING_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingDealerAgreementList(fetchedWaitingDealerAgreementList);
          waitingDealerAgreementPageable.setPageable(fetchedWaitingDealerAgreementList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onWaitingDealerAgreementSearchRemoveFilter = (event: any) => {
      event.preventDefault();
      waitingDealerAgreementSearchForm.reset();
    };

    const onWaitingDealerAgreementSearchSubmit = async () => {
      const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
      try {
        const [fetchedWaitingDealerAgreementList, fetchedNotCancelledWaitingDealerAgreementList] = await Promise.all([
          requestFinancierWaitingDealerAgreementList(
            0,
            waitingDealerAgreementPageable.pageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestFinancierWaitingDealerAgreementList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: 0,
            rowCount: waitingDealerAgreementPageable.pageable.sizePerPage,
          },
          FI_DEALER_AGREEMENT_WAITING_LIST_QS_KEY,
        );
        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingDealerAgreementList(fetchedWaitingDealerAgreementList);
          waitingDealerAgreementPageable.setPageable(fetchedWaitingDealerAgreementList);
          setNotCancelledWaitingDealerAgreementList(fetchedNotCancelledWaitingDealerAgreementList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onWaitingDealerAgreementSearchRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
                <SearchInput name="contractNo" ref={waitingDealerAgreementSearchForm.register} />
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  name="currencyType"
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                  ref={waitingDealerAgreementSearchForm.register}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Associated_Anchor_Client_Code')} />
                <SearchInput name="anchorClientCode" ref={waitingDealerAgreementSearchForm.register} />
                <SearchLabel label={t('text:Associated_Anchor_Name')} />
                <SearchInput name="anchorClientName" ref={waitingDealerAgreementSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Partner_Client_Code')} />
                <SearchInput name="dealerClientCode" ref={waitingDealerAgreementSearchForm.register} />
                <SearchLabel label={t('text:Partner_Name')} />
                <SearchInput name="dealerClientName" ref={waitingDealerAgreementSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Responsible_Bank_Branch_Code')} />
                <SearchInput name="assignedBranchCode" ref={waitingDealerAgreementSearchForm.register} />
                <SearchLabel label={t('text:Responsible_Bank_Branch_Name')} />
                <SearchInput name="assignedBranchName" ref={waitingDealerAgreementSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Effective_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name="fromStartDate"
                  control={waitingDealerAgreementSearchForm.control}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name="toStartDate"
                  control={waitingDealerAgreementSearchForm.control}
                />
                <SearchLabel label={t('text:Expiration_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name="fromExpiryDate"
                  control={waitingDealerAgreementSearchForm.control}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name="toExpiryDate"
                  control={waitingDealerAgreementSearchForm.control}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Financing_Limit')} />
                <SearchInput
                  name="minimumLoanLimitAmount"
                  ref={waitingDealerAgreementSearchForm.register}
                  type="number"
                  col={2}
                  placeholder={t('text:from')}
                />
                <SearchInput
                  name="maximumLoanLimitAmount"
                  ref={waitingDealerAgreementSearchForm.register}
                  type="number"
                  col={2}
                  placeholder={t('text:to')}
                />
                <SearchLabel
                  label={t('text:Approval_Status')}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickConfirmationStatus}
                />
                <SearchSelect
                  name="approvalTypes"
                  ref={waitingDealerAgreementSearchForm.register}
                  selectOptions={[
                    {
                      label: t('text:All'),
                      value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                    },
                    {
                      label: t('text:Requested'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                    },
                    {
                      label: t('text:Reverted'),
                      value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    },
                    {
                      label: t('text:Cancelled'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                    },
                  ]}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Program_Type')} />
                <SearchSelect
                  name="collateralType"
                  selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                  ref={waitingDealerAgreementSearchForm.register}
                />
                <SearchEmpty />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onWaitingDealerAgreementSearchSubmit}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Results')} />
          <p className="total-data">
            {t('text:Total')}: {waitingDealerAgreementPageable.pageable.totalRows}
          </p>
          <TableBorder>
            <TableHeader header={WAITING_DEALER_AGREEMENT_LIST_HEADER} />
            <TableBody numOfCol={WAITING_DEALER_AGREEMENT_LIST_HEADER.length}>
              {renderWaitingDealerAgreementResultTable()}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingDealerAgreementPageable.pageable} paginate={waitingDealerAgreementPaginate} />
        </div>
      </>
    );
  };

  const renderCompleteTabView = (): JSX.Element => {
    const DEALER_AGREEMENT_LIST_HEADER: HeaderType[] = [
      {
        headerText: t('text:Program_Type'),
        colWidths: 100,
      },
      {
        headerText: t('text:Partner_Master_Agreement_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 96,
      },
      {
        headerText: t('text:Partner_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Partner_Client_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Associated_Anchor_Client_Code'),
        colWidths: 150,
      },
      {
        headerText: t('text:Responsible_Bank_Branch_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Responsible_Bank_Branch_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financing_Limit_(+_Ad_hoc_limit)'),
        colWidths: 150,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Agreement_Suspension'),
        colWidths: 120,
      },
      {
        headerText: t('text:User_Account_Status'),
        colWidths: 120,
        hasStatusDescription: true,
        showStatusDescriptionFunc: onClickUserRegistrationStatus,
      },
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    const renderDealerAgreementResultTable = (): JSX.Element[] | undefined => {
      const renderAgreementStatus = (agreementStatus: DEALER_AGREEMENT_STATUS): JSX.Element | undefined => {
        switch (agreementStatus) {
          case DEALER_AGREEMENT_STATUS.ACTIVATED:
            return <td className="text-bold-dark-green">{t('text:Not_Suspended')}</td>;
          case DEALER_AGREEMENT_STATUS.SUSPENDED:
            return <td className="text-bold-brick-red">{t('text:Suspended')}</td>;
          default:
            return <td>{agreementStatus}</td>;
        }
      };

      const renderAuthorizerStatus = (authorizerStatus: AUTHORIZER_STATUS): JSX.Element | undefined => {
        switch (authorizerStatus) {
          case AUTHORIZER_STATUS.ACTIVATED:
            return (
              <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.ACTIVATED)}>
                {t('text:Active')}
              </td>
            );
          case AUTHORIZER_STATUS.MAIL_SENT:
            return (
              <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.MAIL_SENT)}>
                {t('text:Invitation_Email_Sent')}
              </td>
            );
          case AUTHORIZER_STATUS.NONE:
            return (
              <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.NONE)}>{t('text:Input')}</td>
            );
          default:
            return <td>{authorizerStatus}</td>;
        }
      };

      return dealerAgreementList?.content.map((item, index) => (
        <tr key={index}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.dealerFinancierClientName} />
          <Td data={item.dealerFinancierClientCode} />
          <Td data={item.anchorFinancierClientName} />
          <Td data={item.anchorFinancierClientCode} />
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td className="text-end">
            {renderFinancingLimit(
              item.loanLimitCheckType,
              renderFinancingLimitLoanLimitCheckTypeIsNotNone(
                item.loanLimitAmount,
                item.adhocLimitAllowable,
                item.adhocLimitAmount,
              ),
            )}
          </Td>
          <Td data={item.startDate} format="date" />
          <Td data={item.expiryDate} format="date" />
          {renderAgreementStatus(item.dealerAgreementStatus)}
          {renderAuthorizerStatus(item.authorizerStatus)}
          <TdLink
            path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.dealerAgreementId)}
            state={{
              supportedCollateralType: item.collateralType,
              dealerFinancierClientId: item.dealerFinancierClientId,
            }}
          />
        </tr>
      ));
    };

    const onDealerAgreementSearchRemoveFilter = (event: any) => {
      event.preventDefault();
      dealerAgreementSearchForm.reset();
    };

    const fetchDealerAgreement = async (
      pageNumber: number,
      rowCount: number,
      searchedData: FinancierDealerAgreementListRequest,
    ) => {
      try {
        const fetchedDealerAgreementList = await requestFinancierDealerAgreementList(
          pageNumber,
          rowCount,
          searchedData,
        );
        updateSearchParams({ ...searchedData, pageNumber, rowCount }, FI_DEALER_AGREEMENT_COMPLETED_LIST_QS_KEY);

        ReactDOM.unstable_batchedUpdates(() => {
          setDealerAgreementList(fetchedDealerAgreementList);
          dealerAgreementPageable.setPageable(fetchedDealerAgreementList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onDealerAgreementSearchSubmit = async () => {
      await fetchDealerAgreement(
        1,
        dealerAgreementPageable.pageable.sizePerPage,
        dealerAgreementSearchForm.getValues(),
      );
    };

    const dealerAgreementPaginate = async (pageNumber: number, sizePerPage: number) => {
      await fetchDealerAgreement(
        pageNumber,
        sizePerPage,
        getParsedSearchParams(FI_DEALER_AGREEMENT_COMPLETED_LIST_QS_KEY).formSearchData,
      );
    };

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onDealerAgreementSearchRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
                <SearchInput name="contractNo" ref={dealerAgreementSearchForm.register} />
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  name="currencyType"
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                  ref={dealerAgreementSearchForm.register}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Associated_Anchor_Client_Code')} />
                <SearchInput name="anchorClientCode" ref={dealerAgreementSearchForm.register} />
                <SearchLabel label={t('text:Associated_Anchor_Name')} />
                <SearchInput name="anchorClientName" ref={dealerAgreementSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Partner_Client_Code')} />
                <SearchInput name="dealerClientCode" ref={dealerAgreementSearchForm.register} />
                <SearchLabel label={t('text:Partner_Name')} />
                <SearchInput name="dealerClientName" ref={dealerAgreementSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Responsible_Bank_Branch_Code')} />
                <SearchInput name="branchCode" ref={dealerAgreementSearchForm.register} />
                <SearchLabel label={t('text:Responsible_Bank_Branch_Name')} />
                <SearchInput name="branchName" ref={dealerAgreementSearchForm.register} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Effective_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name="startDateFrom"
                  control={dealerAgreementSearchForm.control}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name="startDateTo"
                  control={dealerAgreementSearchForm.control}
                />
                <SearchLabel label={t('text:Expiration_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name="expiryDateFrom"
                  control={dealerAgreementSearchForm.control}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name="expiryDateTo"
                  control={dealerAgreementSearchForm.control}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Financing_Limit')} />
                <SearchInput
                  name="loanLimitAmountFrom"
                  ref={dealerAgreementSearchForm.register}
                  col={2}
                  placeholder={t('text:from')}
                />
                <SearchInput
                  name="loanLimitAmountTo"
                  ref={dealerAgreementSearchForm.register}
                  col={2}
                  placeholder={t('text:to')}
                />
                <SearchLabel label={t('text:Agreement_Suspension')} />
                <SearchSelect
                  name="dealerAgreementStatus"
                  selectOptions={getSelectOptions<DEALER_AGREEMENT_STATUS>('DEALER_AGREEMENT_STATUS', 'ALL', true)}
                  ref={dealerAgreementSearchForm.register}
                />
              </div>
              <div className="row">
                <SearchLabel
                  label={t('text:User_Account_Status')}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickUserRegistrationStatus}
                />
                <SearchSelect
                  name="authorizerStatus"
                  selectOptions={getSelectOptions<AUTHORIZER_STATUS>('AUTHORIZER_STATUS', 'ALL', true)}
                  ref={dealerAgreementSearchForm.register}
                />
                <SearchLabel label={t('text:Program_Type')} />
                <SearchSelect
                  name="collateralType"
                  selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                  ref={dealerAgreementSearchForm.register}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onDealerAgreementSearchSubmit}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>

        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Results')} />
          <p className="total-data">
            {t('text:Total')}: {dealerAgreementPageable.pageable.totalRows}
          </p>
          <TableBorder>
            <TableHeader header={DEALER_AGREEMENT_LIST_HEADER} />
            <TableBody numOfCol={DEALER_AGREEMENT_LIST_HEADER.length}>{renderDealerAgreementResultTable()}</TableBody>
          </TableBorder>
          <Pagination pageable={dealerAgreementPageable.pageable} paginate={dealerAgreementPaginate} />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_Master_Agreement')} />
      {signInModel?.authorityType !== AUTHORITY_TYPE.OPERATOR ? (
        <GuideMessage
          message={[
            t('text:On_this_page,_you_can_view_the_list_of_Partner_Master_Agreements_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Partner_Master_Agreement'),
          ]}
        />
      ) : (
        <GuideMessage
          message={[
            t(
              'text:Click_the_corresponding_button_on_the_right_to_register_a_Partner_Master_Agreement_for_the_financing_program',
            ),
            t('text:On_this_page,_you_can_view_the_list_of_Partner_Master_Agreements_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Partner_Master_Agreement'),
          ]}
        >
          {{
            button: (
              <>
                {(supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.AR ||
                  supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) && (
                  <Button fullWidth onClick={e => onClickRegisterAgreement(e, COLLATERAL_TYPE.AR)}>
                    {t('text:Register_Master_Agreement_of_Vendor_Finance')}
                  </Button>
                )}
                {(supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE ||
                  supportedCollateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) && (
                  <Button fullWidth onClick={e => onClickRegisterAgreement(e, COLLATERAL_TYPE.INVOICE)}>
                    {t('text:Register_Master_Agreement_of_Dealer_Finance')}
                  </Button>
                )}
              </>
            ),
          }}
        </GuideMessage>
      )}

      <Tab
        tabType="approvalStatus"
        initialActiveTabIndex={1}
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              waitingDealerAgreementSearchForm.getValues('approvalTypes'),
              notCancelledWaitingDealerAgreementList?.totalElements,
            ),
          },
          {
            tabItemCount: dealerAgreementList?.totalElements ?? 0,
          },
        ]}
        tabViewList={[renderWaitingTabView(), renderCompleteTabView()]}
      />
    </>
  );
}

export default FinancierDealerAgreementList;
