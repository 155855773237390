import type { ButtonHTMLAttributes } from 'react';

import './Button2.scss';

export enum Button2ColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TERTIARY_REVERSE = 'tertiary-reverse',
  GHOST = 'ghost',
  GRAY_SCALE = 'gray-scale',
}

export enum Button2SizeEnum {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

interface Button2PropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: Button2SizeEnum;
  color?: Button2ColorEnum;
  icon?: JSX.Element;
}

function Button2({
  children,
  size = Button2SizeEnum.SM,
  color = Button2ColorEnum.PRIMARY,
  disabled,
  onClick,
  onMouseEnter,
  type = 'button',
  icon,
  className = '',
  ...restProps
}: Button2PropsType) {
  const handleClick = (e?: any) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      type={type}
      disabled={disabled}
      className={`common-button2 ${color} ${size} ${className}`}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      {...restProps}
    >
      {children}
      {icon}
    </button>
  );
}

export default Button2;
