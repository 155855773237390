import type { BigNumber } from 'utils/bigNumber';

export interface MultipleAnchorLoanRequestRelatedSuccessInvoiceVO {
  successInvoiceId: number;
  invoiceNumber: string;
  dealerId: number;
  dealerName: string;
  dealerTaxCode: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerClientTaxCode: string;
  invoiceAmount: number;
  invoiceIssuedDate: string;
  invoiceSettlementDate: string;
  requestedAmount: BigNumber;
  collateralizedAmountByPartner: BigNumber;
  desiredDisburseDateByPartner: string;
  anchorLoanId: number;
}

export interface MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel
  extends MultipleAnchorLoanRequestRelatedSuccessInvoiceVO {}

export function formattingToMultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel(
  data: MultipleAnchorLoanRequestRelatedSuccessInvoiceVO,
): MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel {
  return {
    ...data,
  };
}
