import { ATTACHMENT_TYPE } from 'enums';
import { requestDealerMultipleRequestLoanDownloadAttachment } from 'utils/http/api/dealer/multiple-request-loans';
import useModal from 'utils/modal/useModal';

export function useDealerFinancingDetailAdditionalDocumentsLogics(multipleLoanRequestId: number) {
  const modal = useModal();

  const handleLoanEtcFileClick = async (e: any, loanEtcAttachmentId: number) => {
    e.preventDefault();
    try {
      await requestDealerMultipleRequestLoanDownloadAttachment(multipleLoanRequestId, {
        attachmentType: ATTACHMENT_TYPE.LOAN_ETC,
        loanEtcAttachmentId,
      });
    } catch (e: any) {
      modal.show(e);
    }
  };

  return { handleLoanEtcFileClick };
}
