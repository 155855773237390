import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { LOAN_TRANSACTION_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';

interface DealerFinancingDetailRepaymentTransactionHistoryProps {
  loanTransactionHistoryData: LoanTransactionVOModel[];
  pageable: PageableType;
  paginate(pageNumber: number, rowCount: number): Promise<void>;
}
function DealerFinancingDetailRepaymentTransactionHistory({
  loanTransactionHistoryData,
  pageable,
  paginate,
}: DealerFinancingDetailRepaymentTransactionHistoryProps) {
  const { t } = useTranslation(['format']);

  const TRANSACTION_TABLE_HEADER: HeaderType[] = [
    {
      headerText: '',
      colWidths: 50,
    },
    {
      headerText: t('text:Transaction_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Repayment_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Interest_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Principal_Repayment'),
      colWidths: 120,
    },
    {
      headerText: t('text:Interest_Repayment'),
      colWidths: 120,
    },
    {
      headerText: t('text:Principal_Balance'),
      colWidths: 120,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Repayment_Transaction_History')} />
      <TableBorder>
        <TableHeader header={TRANSACTION_TABLE_HEADER} />
        <TableBody numOfCol={TRANSACTION_TABLE_HEADER.length}>
          {loanTransactionHistoryData?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td className="text-center" data={pageable.sizePerPage * (pageable.currentPage - 1) + (i + 1)} />
                <Td data={item.transactionDateTime} format="date" />
                <Td data={t(`code:loan-transaction-type.${item.loanTransactionType}`)} format="code" />
                <Td data={t(`code:interest-type.${item.loanTransactionType}`)} format="code" />
                <Td
                  data={item.loanTransactionType === LOAN_TRANSACTION_TYPE.PRINCIPAL ? item.transactionAmount : '-'}
                  format="number"
                />
                <Td
                  data={
                    item.loanTransactionType === LOAN_TRANSACTION_TYPE.INTEREST ||
                    item.loanTransactionType === LOAN_TRANSACTION_TYPE.DELAY
                      ? item.transactionAmount
                      : '-'
                  }
                  format="number"
                />
                <Td
                  data={
                    item.loanTransactionType !== LOAN_TRANSACTION_TYPE.DISBURSED ? item.remainingPrincipalAmount : '-'
                  }
                  format="number"
                />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>

      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default DealerFinancingDetailRepaymentTransactionHistory;
