import type { MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { FinancierAnchorDealerListRequest } from 'utils/http/api/financier/anchor-dealers/request';

interface FinancierAnchorDealerAssignmentListDeclinedTabProps {
  declinedAnchorDealerList: Pageable<AnchorDealerVOModel[]> | undefined;
  pageable: PageableType;
  paginate(page: number, sizePerPage: number): void;
  handleClickSearch: MouseEventHandler<HTMLButtonElement>;
}

function FinancierAnchorDealerAssignmentListDeclinedTab({
  declinedAnchorDealerList,
  pageable,
  paginate,
  handleClickSearch,
}: FinancierAnchorDealerAssignmentListDeclinedTabProps) {
  const { t } = useTranslation();
  const { register, reset } = useFormContext<FinancierAnchorDealerListRequest>();

  const DECLINED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Uploaded_Dealer_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Dealer_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Uploaded_Dealer_Name')} />
              <SearchInput name="name" ref={register} />
              <SearchLabel label={t('text:Uploaded_Dealer_Tax_Code')} />
              <SearchInput name="taxCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
              <SearchLabel label={t('text:Registered_Office_Address')} />
              <SearchInput name="address" ref={register} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {declinedAnchorDealerList?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={DECLINED_TABLE_HEADERS} />
          <TableBody numOfCol={DECLINED_TABLE_HEADERS?.length}>
            {declinedAnchorDealerList?.content.map((item, index) => (
              <Tr key={index}>
                <Td data={item.name} />
                <Td data={item.taxCode} />
                <Td data={item.anchorName} />
                <Td data={item.address} />
                <TdLink path={ROUTES_FI.MANAGE_DEALER.ASSIGNMENT_DETAIL_BUILD_PATH(item.id)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierAnchorDealerAssignmentListDeclinedTab;
