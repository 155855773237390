import type React from 'react';

import './VerticalStepWizard.scss';

type WrapperProps = {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

const Wrapper = ({ className = '', style, children }: WrapperProps) => {
  return (
    <div className={`vertical-step-wizard-wrapper ${className}`} style={style}>
      {children}
    </div>
  );
};

type VerticalStepWizardProps<T> = {
  title: string;
  label: string;
  step: T;
  activeStep: T;
  children: React.ReactNode;
};

const VerticalStepWizardRoot = <T,>({ title, label, step, activeStep, children }: VerticalStepWizardProps<T>) => {
  return (
    <div className="vertical-step-wizard">
      <div className="vertical-step-wizard__header-wrap">
        <div className={`vertical-step-wizard__badge--${activeStep === step ? 'active' : 'inactive'}`}>{label}</div>
        <h2 className={`vertical-step-wizard__header--${activeStep === step ? 'active' : 'inactive'}`}>{title}</h2>
      </div>
      <div className="vertical-step-wizard__content-wrap">
        <div className="vertical-step-wizard__content-inner">{children}</div>
      </div>
    </div>
  );
};

const VerticalStepWizard = Object.assign(VerticalStepWizardRoot, {
  Wrapper,
});

export default VerticalStepWizard;
