import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import TdLink from 'components/stateless/Table/TdLink';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAuthorizerVOModel } from 'models/vo/DealerAuthorizerVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierPartnerUserList } from 'utils/http/api/financier/partner-users';
import type { FinancierPartnerUserListRequest } from 'utils/http/api/financier/partner-users/requests';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const FI_DEALER_USER_LIST_QS_KEY = 'fi-dealer-user-list';

const FinancierManageDealerUserList = () => {
  const { t } = useTranslation();
  const mounted = useMounted();

  const { pageable, setPageable } = usePageable(FI_DEALER_USER_LIST_QS_KEY);
  const { register, reset, getValues, setValue } = useForm<FinancierPartnerUserListRequest>();
  const [dealerUserList, setDealerUserList] = useState<Pageable<DealerAuthorizerVOModel[]>>();

  const FI_DEALER_USER_TABLE_HEADERS = [
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:User_Code'),
    },
    {
      headerText: t('text:User_Name'),
    },
    {
      headerText: t('text:Mobile'),
    },
    {
      headerText: t('text:Email'),
    },
    {
      headerText: '',
      colWidths: 80,
    },
  ];

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierPartnerUserListRequest>(
        setValue,
        getParsedSearchParams(FI_DEALER_USER_LIST_QS_KEY).formSearchData,
      );
      fetchPartnerUserList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  const fetchPartnerUserList = async (
    pageNumber: number,
    rowCount: number,
    searchValue: FinancierPartnerUserListRequest,
  ) => {
    const response = await requestFinancierPartnerUserList(pageNumber, rowCount, searchValue);
    updateSearchParams(
      {
        ...searchValue,
        pageNumber,
        rowCount,
      },
      FI_DEALER_USER_LIST_QS_KEY,
    );

    setDealerUserList(response);
    setPageable(response);
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    await fetchPartnerUserList(
      pageNumber,
      sizePerPage,
      getParsedSearchParams(FI_DEALER_USER_LIST_QS_KEY).formSearchData,
    );
  };

  const handleClickSearch = async () => {
    await fetchPartnerUserList(1, pageable.sizePerPage, getValues());
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_User')} />
      <GuideMessage
        message={[
          t('text:You_can_check_the_user_s_details_on_this_page'),
          t('text:Click_on_the_arrow_button_to_view_the_details_of_the_relevant_master_agreement'),
        ]}
      />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="dealerClientName" ref={register} />
              <SearchLabel label={t('text:User_Code')} />
              <SearchInput name="authorizerEmployeeCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:User_Name')} />
              <SearchInput name="authorizerName" ref={register} />
              <SearchLabel label={t('text:Mobile')} />
              <SearchInput name="authorizerMobile" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Email')} />
              <SearchInput name="authorizerEmail" ref={register} />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {pageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={FI_DEALER_USER_TABLE_HEADERS} />
          <TableBody numOfCol={FI_DEALER_USER_TABLE_HEADERS.length}>
            {dealerUserList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.dealerClientName} />
                  <Td data={item.authorizerEmployeeCode} />
                  <Td data={item.authorizerName} />
                  <Td data={item.authorizerMobile} />
                  <Td data={item.authorizerEmail} />
                  <TdLink path={ROUTES_FI.MANAGE_PARTNER.USER_DETAIL_BUILD_PATH(item.dealerAgreementId)} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
};

export default FinancierManageDealerUserList;
