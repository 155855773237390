import type Pageable from 'models/Pageable';
import { formattingToAnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { AnchorDealerVO, AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierAnchorDealerAssignRequest } from './request';
import type { FinancierAnchorDealerListRequest } from './request';

export async function requestFinancierAnchorDealerList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierAnchorDealerListRequest,
): Promise<Pageable<AnchorDealerVOModel[]>> {
  const response = await http.get<Pageable<AnchorDealerVO[]>>({
    url: API_FI.ANCHOR_DEALERS.ANCHOR_DEALERS_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorDealerVOModel(data)),
  };
}

export async function requestFinancierAnchorDealerDetail(anchorDealerId: number): Promise<AnchorDealerVOModel> {
  const response = await http.get<AnchorDealerVO>({
    url: API_FI.ANCHOR_DEALERS.ANCHOR_DEALERS_DETAIL(anchorDealerId),
  });

  return formattingToAnchorDealerVOModel(response);
}

export async function requestFinancierAnchorDealerAssign(
  data: FinancierAnchorDealerAssignRequest,
): Promise<AnchorDealerVOModel> {
  const response = await http.post<AnchorDealerVO>({
    url: API_FI.ANCHOR_DEALERS.ANCHOR_DEALERS_ASSIGN,
    data,
  });

  return formattingToAnchorDealerVOModel(response);
}

export async function requestFinancierAnchorDealerDecline(anchorDealerIds: number[], reason: string): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_DEALERS.ANCHOR_DEALERS_DECLINE,
    data: {
      anchorDealerIds,
      reason,
    },
  });
}

export async function requestFinancierAnchorDealerReinstate(anchorDealerId: number): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_DEALERS.ANCHOR_DEALERS_REINSTATE(anchorDealerId),
  });
}

export async function requestFinancierAnchorDealerCancelAssignment(anchorDealerId: number): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_DEALERS.ANCHOR_DEALERS_CANCEL_ASSIGNMENT(anchorDealerId),
  });
}
