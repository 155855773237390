import { useTranslation } from 'react-i18next';

import {
  FormBorder,
  FormContents,
  FormRadio,
  FormRadioWrap,
  FormSubtitle,
  FormValue,
} from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import {
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  DEALER_AGREEMENT_STATUS,
  DEALER_AGREEMENT_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
  LOAN_TERM_TYPE,
} from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';

import SyPartnerAgreementRegisteredDetailAnchorUserInfo from './anchor-user-info';
import SyPartnerAgreementRegisteredDetailAuthorizerInfo from './authorizer-info';
import SyPartnerAgreementRegisteredDetailTermSpread from './term-spread';

interface SyPartnerAgreementRegisteredDetailInfoProps {
  data: DealerAgreementDetailVOModel;
}

function SyPartnerAgreementRegisteredDetailInfo({ data }: SyPartnerAgreementRegisteredDetailInfoProps) {
  const { t } = useTranslation(['format']);

  const {
    dealerAgreementType,
    basisInterestType,
    loanTermType,
    maxExtensibleLoanCount,
    collateralType,
    interestRepaymentType,
    loanLimitCheckType,
    loanLimitAmount,
  } = data ?? ({} as DealerAgreementDetailVOModel);

  const isDealerAgreementTypeWithAnchor = dealerAgreementType === DEALER_AGREEMENT_TYPE.WITH_ANCHOR;
  const isBasisInterestTypeFixed = basisInterestType === BASIS_INTEREST_TYPE.FIXED;
  const isRangeOfLoanTerm = loanTermType === LOAN_TERM_TYPE.RANGE;
  const isMaturityExtension = (maxExtensibleLoanCount ?? 0) > 0;
  const isInvoice = collateralType === COLLATERAL_TYPE.INVOICE;

  const isInterestRepaymentTypeMonthly = interestRepaymentType === INTEREST_REPAYMENT_TYPE.MONTHLY;
  const financingLimitValue =
    loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE && loanLimitAmount === '0' ? ' ' : loanLimitAmount;

  const loanLimitTypeText = (loanLimitType: LOAN_LIMIT_CHECK_TYPE | undefined) => {
    switch (loanLimitType) {
      case LOAN_LIMIT_CHECK_TYPE.BOTH:
        return t('text:Both');
      case LOAN_LIMIT_CHECK_TYPE.INVOICE:
        return t('text:Invoice_Registration');
      case LOAN_LIMIT_CHECK_TYPE.LOAN:
        return t('text:Loan_Application');
      case LOAN_LIMIT_CHECK_TYPE.NONE:
        return t('text:None');
      default:
        return '-';
    }
  };

  const loanTermText = (loanTerm: LOAN_TERM_TYPE | undefined) => {
    switch (loanTerm) {
      case LOAN_TERM_TYPE.DAILY:
        return t('text:days');
      case LOAN_TERM_TYPE.MONTHLY:
        return t('text:month');
      default:
        return '-';
    }
  };

  return (
    <div className="content-area">
      <form>
        <SectionTitle title={t('text:Agreement_Information')} />
        <FormBorder>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:PARTNER_INFORMATION')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Client_Code')} value={data.dealerFinancierClientCode} />
              <FormValue col={3} label={t('text:Partner_Name')} value={data.dealerFinancierClientName} />
              <FormValue col={3} label={t('text:Tax_Code')} value={data.dealerFinancierClientTaxCode} />
            </div>
          </FormContents>
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT_INFORMATION')}
          />
          <FormContents>
            <div className="row">
              <FormRadioWrap label={t('text:Is_there_an_associated_Anchor_Master_Agreement?')}>
                <FormRadio label={t('text:Yes')} defaultChecked={isDealerAgreementTypeWithAnchor} disabled />
                <FormRadio
                  label={t('text:No')}
                  defaultChecked={data.dealerAgreementType === DEALER_AGREEMENT_TYPE.WITHOUT_ANCHOR}
                  disabled
                />
              </FormRadioWrap>
            </div>
            {isDealerAgreementTypeWithAnchor && (
              <>
                <div className="row">
                  <FormValue label={t('text:Anchor_Master_Agreement_Number')} value={data.anchorAgreementContractNo} />
                  <FormValue label={t('text:Anchor_Name')} value={data.anchorFinancierClientName} />
                </div>
                <div className="row">
                  <FormValue label={t('text:Effective_Date')} value={data.anchorAgreementStartDate} format="date" />
                  <FormValue label={t('text:Expiration_Date')} value={data.anchorAgreementExpiryDate} format="date" />
                </div>
              </>
            )}
          </FormContents>
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:PARTNER_MASTER_AGREEMENT_INFORMATION')}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Partner_Master_Agreement_Number')} value={data.contractNo} />
              <FormValue label={t('text:Partner_Code_of_Anchor')} value={data.dealerCodeByAnchor} />
            </div>
            <div className="row">
              <FormValue col={3} label={t('text:Currency')} value={data.currencyType} />
              <FormValue
                col={3}
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${data.collateralType}`)}
                format="code"
              />
              <FormValue col={3} label={t('text:Effective_Date')} value={data.startDate} format="date" />
              <FormValue col={3} label={t('text:Expiration_Date')} value={data.expiryDate} format="date" />
            </div>
            <div className="row">
              <FormValue label={t('text:Drawing_Power')} value={data.drawingPower} format="number" />
              <FormValue
                col={3}
                label={t('text:Financing_Limit_Checkpoint')}
                value={loanLimitTypeText(data.loanLimitCheckType)}
              />
              <FormValue col={3} label={t('text:Financing_Limit')} value={financingLimitValue} format="number" />
            </div>
            <div className="row">
              <FormValue label={t('text:Base_Interest_Type')} col={3} value={data.basisInterestType} />
              <FormValue col={3} label="" value={data.basisInterestBankCode} />
              {isBasisInterestTypeFixed && (
                <FormValue
                  col={6}
                  label={t('text:Base_Interest_Rate')}
                  value={data.basisInterestRate}
                  format="number"
                />
              )}
              {!isBasisInterestTypeFixed && (
                <FormValue col={6} label={t('text:Base_Interest_Term')} value={data.basisInterestTerm} />
              )}
            </div>
            <div className="row">
              <FormValue col={6} label={t('text:Credit_Spread_(%)')} value={data.creditInterestRate} format="number" />
              <FormValue
                col={6}
                label={t('text:Interest_Rate_Adjustment_(%)')}
                value={data.preferentialInterestRate}
                format="number"
              />
            </div>
            <SyPartnerAgreementRegisteredDetailTermSpread data={data.termSpreadList} />
            <div className="row">
              <FormRadioWrap label={t('text:Select_Interest_Payment_Method')}>
                <FormRadio
                  label={t('text:Prepayment')}
                  defaultChecked={data.interestRepaymentType === INTEREST_REPAYMENT_TYPE.EARLIER}
                  disabled
                />
                <FormRadio
                  label={t('text:Postpayment')}
                  defaultChecked={data.interestRepaymentType === INTEREST_REPAYMENT_TYPE.LATTER}
                  disabled
                />
                <FormRadio label={t('text:Monthly')} defaultChecked={isInterestRepaymentTypeMonthly} disabled>
                  {isInterestRepaymentTypeMonthly && `( ${t('text:Date')} : ${data.monthlyInterestRepaymentDate} )`}
                </FormRadio>
              </FormRadioWrap>
              <FormRadioWrap label={t('text:Allow_the_partner_to_specify_the_financing_duration?')}>
                <FormRadio label={t('text:Yes')} defaultChecked={isRangeOfLoanTerm} disabled>
                  {isRangeOfLoanTerm &&
                    `( ${t('text:Minimum')} : ${t('format:number', {
                      value: data.minimumLoanRange,
                    })} ${t('text:Days')} / ${t('text:Maximum')} : ${t('format:number', {
                      value: data.maximumLoanRange,
                    })} ${t('text:Days')} )`}
                </FormRadio>
                <FormRadio label={t('text:No')} defaultChecked={!isRangeOfLoanTerm} disabled>
                  {!isRangeOfLoanTerm &&
                    `( ${t('text:Fixed_Duration')} : ${t('format:number', {
                      value: data.loanTermUnit,
                    })} ${loanTermText(data.loanTermType)} )`}
                </FormRadio>
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_Maturity_Extension?')}>
                <FormRadio label={t('text:Yes')} defaultChecked={isMaturityExtension} disabled>
                  {isMaturityExtension &&
                    `( ${t('format:number', {
                      value: data.maxExtensibleLoanCount,
                    })} ${t('text:Time(s)')} )`}
                </FormRadio>
                <FormRadio label={t('text:No')} defaultChecked={!isMaturityExtension} disabled />
              </FormRadioWrap>
              <FormRadioWrap label={t('text:Allow_Early_Repayment?')}>
                <FormRadio label={t('text:Yes')} defaultChecked={data.earlyRepaymentAllowable} disabled />
                <FormRadio label={t('text:No')} defaultChecked={!data.earlyRepaymentAllowable} disabled />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_the_partner_to_initiate_Invoice_or_AR_registration?')}>
                <FormRadio label={t('text:Yes')} defaultChecked={data.dealerUploadInvoiceAllowable} disabled />
                <FormRadio label={t('text:No')} defaultChecked={!data.dealerUploadInvoiceAllowable} disabled />
              </FormRadioWrap>
              <FormRadioWrap
                label={t('text:Allow_the_financing_repayment_date_to_exceed_the_agreement_expiration_date?')}
              >
                <FormRadio label={t('text:Yes')} defaultChecked={data.repaymentDateOverAllowable} disabled />
                <FormRadio label={t('text:No')} defaultChecked={!data.repaymentDateOverAllowable} disabled />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_the_partner_to_see_the_expected_interest_rate?')}>
                <FormRadio label={t('text:Yes')} defaultChecked={data.showExpectedTotalInterestRate} disabled />
                <FormRadio label={t('text:No')} defaultChecked={!data.showExpectedTotalInterestRate} disabled />
              </FormRadioWrap>
              <FormRadioWrap label={t('text:Agreement_Suspension')}>
                <FormRadio
                  label={t('text:Not_Suspended')}
                  defaultChecked={data.dealerAgreementStatus === DEALER_AGREEMENT_STATUS.ACTIVATED}
                  disabled
                />
                <FormRadio
                  label={t('text:Suspended')}
                  defaultChecked={data.dealerAgreementStatus === DEALER_AGREEMENT_STATUS.SUSPENDED}
                  disabled
                />
              </FormRadioWrap>
            </div>
          </FormContents>
          {isInvoice && (
            <>
              <FormSubtitle title={t('text:AD_HOC_LIMIT')} backGroundType={BackGroundType.DarkGray} />
              <FormContents>
                <div className="row">
                  <FormRadioWrap label={t('text:Apply_Ad_hoc_Limit?')}>
                    <FormRadio label={t('text:Applied')} defaultChecked={data.adhocLimitAllowable} disabled />
                    <FormRadio label={t('text:Not_Applied')} defaultChecked={!data.adhocLimitAllowable} disabled />
                  </FormRadioWrap>
                </div>
                <div className="row">
                  <FormValue
                    col={6}
                    label={t('text:Ad_hoc_Limit_Amount')}
                    value={data.adhocLimitAmount}
                    format="number"
                  />
                  <FormValue col={3} label={t('text:Start_Date')} value={data.adhocLimitStartDate} format="date" />
                  <FormValue col={3} label={t('text:End_Date')} value={data.adhocLimitEndDate} format="date" />
                </div>
              </FormContents>
            </>
          )}
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:DISBURSEMENT_ACCOUNT')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Bank_Name')} value={data.settlementAccountFinancierName} />
              <FormValue label={t('text:Branch_Name')} value={data.settlementAccountBranchName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Bank_Account_Number')} value={data.settlementAccount} />
              <FormValue label={t('text:Bank_Account_Holder')} value={data.settlementAccountOwner} />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:REPAYMENT_ACCOUNT')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Bank_Name')} value={data.principalRepaymentAccountFinancierName} />
              <FormValue label={t('text:Branch_Name')} value={data.principalRepaymentAccountBranchName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Bank_Account_Number')} value={data.principalRepaymentAccount} />
              <FormValue label={t('text:Bank_Account_Holder')} value={data.principalRepaymentAccountOwner} />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:REPAYMENT_ACCOUNT_-_INTEREST')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Bank_Name')} value={data.interestRepaymentAccountFinancierName} />
              <FormValue label={t('text:Branch_Name')} value={data.interestRepaymentAccountBranchName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Bank_Account_Number')} value={data.interestRepaymentAccount} />
              <FormValue label={t('text:Bank_Account_Holder')} value={data.interestRepaymentAccountOwner} />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:SECURED_DEPOSIT_ACCOUNT')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Bank_Name')} value={data.depositCollateralAccountFinancierName} />
              <FormValue label={t('text:Branch_Name')} value={data.depositCollateralAccountBranchName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Bank_Account_Number')} value={data.depositCollateralAccount} />
              <FormValue label={t('text:Bank_Account_Holder')} value={data.depositCollateralAccountOwner} />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:RESPONSIBLE_BRANCH')} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Branch_Code')} value={data.branchCode} />
              <FormValue label={t('text:Branch_Name')} value={data.branchName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Telephone')} value={data.branchTelephone} />
              <FormValue label={t('text:Fax')} value={data.branchFax} />
            </div>
            <div className="row">
              <FormValue col={12} label={t('text:Registered_Office_Address')} value={data.branchAddress} />
            </div>
          </FormContents>
          <SyPartnerAgreementRegisteredDetailAuthorizerInfo data={data} />
          {isDealerAgreementTypeWithAnchor && isInvoice && (
            <SyPartnerAgreementRegisteredDetailAnchorUserInfo data={data.anchorUserList} />
          )}
        </FormBorder>
      </form>
    </div>
  );
}

export default SyPartnerAgreementRegisteredDetailInfo;
