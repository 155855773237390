import i18n from 'i18next';

import { WAITING_AR_STATUS } from 'enums';

type WaitingApResultTextPropsType = {
  status: WAITING_AR_STATUS;
  exceptionMessage?: string;
};

export const getWaitingApResultText = (status: WAITING_AR_STATUS, exceptionMessage?: string) => {
  const message = exceptionMessage && exceptionMessage.split(':').at(-1)?.trim();
  switch (status) {
    case WAITING_AR_STATUS.REGISTERED:
      return i18n.t('text:Pass');
    case WAITING_AR_STATUS.INVALID_SETTLEMENT_DATE:
      return i18n.t('text:Fail_The_settlement_date_entered_cannot_be_registered');
    case WAITING_AR_STATUS.DUPLICATE_AR:
      return i18n.t('text:Fail_Duplicate_AP_Number');
    case WAITING_AR_STATUS.ALREADY_CONFIRMED_AR:
      return i18n.t('text:Fail_This_AP_is_already_registered_with_the_platform');
    case WAITING_AR_STATUS.INVALID_ANCHOR_PARTNER:
      return i18n.t('text:Fail_Partner_associated_with_the_AP_is_not_registered_with_the_platform');
    case WAITING_AR_STATUS.INVALID_ANCHOR_PARTNER_ACCOUNT:
      return i18n.t('text:Fail_Account_Information_of_Partner_associated_with_AP_is_invalid');
    case WAITING_AR_STATUS.SETTLEMENT_DATE_EXCEED_ANCHOR_AGREEMENT_EXPIRY_DATE:
      return i18n.t('text:Fail_The_AP_settlement_date_must_not_exceed_the_Anchor_Master_Agreement_expiration_date');

    case WAITING_AR_STATUS.SETTLEMENT_REQUEST_FAILED:
      return i18n.t('text:Fail_The_financier_has_rejected_the_AP_registration') + ' ' + { message };
    case WAITING_AR_STATUS.AR_REGISTER_FAILED:
      return i18n.t('text:Fail_AP_registration_failed');
    case WAITING_AR_STATUS.INVALID_DEALER_AGREEMENT_WITH_POTENTIAL_PARTNER_OPTION_OFF:
      return i18n.t('text:Fail_This_partner_is_not_registered');
    case WAITING_AR_STATUS.INVALID_DEALER_AGREEMENT_SETTLEMENT_ACCOUNT:
      return i18n.t(
        'text:Fail_The_partner_s_account_information_and_the_account_information_designated_in_the_partner_s_agreement_do_not_match',
      );
    case WAITING_AR_STATUS.SETTLEMENT_DATE_EXCEED_MAXIMUM_SETTLEMENT_DATE:
      return i18n.t('text:Fail_The_settlement_date_entered_cannot_be_registered');
    case WAITING_AR_STATUS.SETTLEMENT_DATE_IS_HOLIDAY:
      return i18n.t('text:Fail_The_AP_settlement_date_must_not_be_a_holiday');
    case WAITING_AR_STATUS.INVALID_TAX_CODE:
      return i18n.t('text:Fail_The_Partners_tax_code_registered_on_the_platform_must_be_updated');
    case WAITING_AR_STATUS.IN_PROGRESS:
      return i18n.t('text:The_registration_is_in_progress');
    default:
      return status;
  }
};

const WaitingApResultText = ({ status, exceptionMessage }: WaitingApResultTextPropsType) => {
  const resultText = getWaitingApResultText(status, exceptionMessage);

  if (status === WAITING_AR_STATUS.REGISTERED) {
    return <td className="text-bold-blue-main600">{resultText}</td>;
  } else if (status === WAITING_AR_STATUS.IN_PROGRESS) {
    return <td className="text-yellow">{resultText}</td>;
  } else {
    return <td className="text-bold-blue-main600">{resultText}</td>;
  }
};

export default WaitingApResultText;
