import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { isNil } from 'lodash-es';

import type { COLLATERAL_TYPE } from 'enums';
import { convertToAnchorAgreementCommonVOModel } from 'models/convertor/anchorAgreement';
import type { AnchorAgreementCommonVOModel } from 'models/convertor/anchorAgreement';
import { requestFinancierAnchorAgreementData } from 'utils/http/api/financier/anchor-agreements';
import { requestFiTempWaitingAnchorAgreementDetail } from 'utils/http/api/financier/temp-waiting-anchor-agreements';
import { requestFinancierWaitingAnchorAgreementDetail } from 'utils/http/api/financier/waiting-anchor-agreements';
import useModal from 'utils/modal/useModal';

import { AgreementContext } from './AgreementContext';
import { AnchorClientInfoContext } from './AnchorClientInfoContext';

import type { AgreementType } from './AgreementContext';
import type { AnchorClientInfoType } from './AnchorClientInfoContext';

type AgreementProviderPropsType = {
  children: ReactNode;
};

const AgreementProvider = ({ children }: AgreementProviderPropsType) => {
  const { show: showModal } = useModal();

  const [agreement, setAgreement] = useState<AgreementType>({} as AgreementType);
  const [anchorClientInfo, setAnchorClientInfo] = useState<AnchorClientInfoType>({} as AnchorClientInfoType);
  const [isSearchedAgreement, setIsSearchedAgreement] = useState(false);

  const isFirstRegisteredWaitingAgreement = isNil(agreement.anchorAgreementId);

  const updateAgreement = (agreement: Partial<AgreementType>) =>
    setAgreement(prevAgreement => ({
      ...prevAgreement,
      ...agreement,
    }));

  const updateAnchorClientInfo = (anchorClientInfo: Partial<AnchorClientInfoType>) =>
    setAnchorClientInfo(prevAnchorClientInfo => ({
      ...prevAnchorClientInfo,
      ...anchorClientInfo,
    }));

  const updateIsSearchedAgreement = (isSearchedAgreement: boolean) => setIsSearchedAgreement(isSearchedAgreement);

  const updateAllAgreement = useCallback((agreement: AnchorAgreementCommonVOModel) => {
    const {
      anchorClientId,
      anchorClientCode,
      anchorClientName,
      anchorUserList,
      anchorClientAutoSignUpForAnchorAdminOfVendorFinance,
      ...restSavedAgreement
    } = agreement;

    updateAgreement(restSavedAgreement);
    updateAnchorClientInfo({
      anchorClientId,
      anchorClientCode,
      anchorClientName,
      anchorUserList,
      anchorClientAutoSignUpForAnchorAdminOfVendorFinance,
    });
  }, []);

  const fetchSavedAgreement = useCallback(
    async (collateralType: COLLATERAL_TYPE) => {
      try {
        const savedAgreement = await requestFiTempWaitingAnchorAgreementDetail(collateralType);

        // 보통 데이터 fetching 후 바로 setState 하지만 임시 저장의 경우 사용하지 않을 수 있기 때문에 여기서는 하지 않음
        return convertToAnchorAgreementCommonVOModel(savedAgreement);
      } catch (error) {
        showModal(error);

        return {} as AnchorAgreementCommonVOModel;
      }
    },
    [showModal],
  );

  const fetchWaitingAgreement = useCallback(
    async (waitingAnchorAgreementId: number) => {
      try {
        const waitingAgreement = await requestFinancierWaitingAnchorAgreementDetail(waitingAnchorAgreementId);

        return convertToAnchorAgreementCommonVOModel(waitingAgreement);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal],
  );

  const fetchAgreementDetail = useCallback(
    async (anchorAgreementId: number) => {
      try {
        const agreementDetail = await requestFinancierAnchorAgreementData(anchorAgreementId);

        return convertToAnchorAgreementCommonVOModel(agreementDetail);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal],
  );

  const agreementValue = useMemo(
    () => ({
      agreement,
      isSearchedAgreement,
      isFirstRegisteredWaitingAgreement,
      updateAgreement,
      updateIsSearchedAgreement,
      updateAllAgreement,
      fetchSavedAgreement,
      fetchWaitingAgreement,
      fetchAgreementDetail,
    }),
    [
      agreement,
      isSearchedAgreement,
      isFirstRegisteredWaitingAgreement,
      updateAllAgreement,
      fetchSavedAgreement,
      fetchWaitingAgreement,
      fetchAgreementDetail,
    ],
  );

  const anchorClientInfoValue = useMemo(
    () => ({
      anchorClientInfo,
      updateAnchorClientInfo,
    }),
    [anchorClientInfo],
  );

  return (
    <AgreementContext.Provider value={agreementValue}>
      <AnchorClientInfoContext.Provider value={anchorClientInfoValue}>{children}</AnchorClientInfoContext.Provider>
    </AgreementContext.Provider>
  );
};

export default AgreementProvider;
