import { faExclamationCircle, faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';

import './SectionMessage.scss';

interface SectionMessageProps {
  message: string[];
  messageType?: 'notice' | 'alert' | 'error' | 'description';
  className?: string;
}

function SectionMessage({ message, messageType = 'notice', className = '' }: SectionMessageProps) {
  const Icon = (() => {
    if (messageType === 'alert') return faTriangleExclamation;
    else if (messageType === 'error') return faExclamationCircle;
    else return faInfoCircle;
  })();

  return (
    <div
      className={clsx('section-message ', {
        [`section__message__wrapper--${messageType}`]: messageType,
        [className]: className,
      })}
    >
      <FontAwesomeIcon icon={Icon} />
      <ul className="align-self-center">
        {message.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export default SectionMessage;
