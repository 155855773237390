import type Pageable from 'models/Pageable';
import type { InventorySummaryDetailVO, InventorySummaryDetailVOModel } from 'models/vo/InventorySummaryDetailVO';
import { formattingToInventorySummaryDetailVOModel } from 'models/vo/InventorySummaryDetailVO';
import type { InventorySummaryVO, InventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import { formattingToInventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemInventorySummariesListRequest } from './requests';

export async function requestSystemInventorySummariesList(
  pageNumber: number,
  rowCount: number,
  searchValue: SystemInventorySummariesListRequest,
): Promise<Pageable<InventorySummaryVOModel[]>> {
  const response = await http.get<Pageable<InventorySummaryVO[]>>({
    url: API_SY.INVENTORY_SUMMARIES.INVENTORY_SUMMARY_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToInventorySummaryVOModel(item)),
  };
}

export async function requestSystemInventorySummariesDetail(
  inventorySummaryId: number,
): Promise<InventorySummaryDetailVOModel> {
  const response = await http.get<InventorySummaryDetailVO>({
    url: API_SY.INVENTORY_SUMMARIES.INVENTORY_SUMMARY_DETAIL(inventorySummaryId),
  });

  return formattingToInventorySummaryDetailVOModel(response);
}

export async function requestSystemInventoryAttachmentDownload(inventorySummaryId: number) {
  await http.download(API_SY.INVENTORY_SUMMARIES.DOWNLOAD_ATTACHMENT(inventorySummaryId));
}
