import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { REPORT_RECEIVER_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorReportReceiverVOModel } from 'models/vo/AnchorReportReceiverVO';
import type { AnchorReportVOModel } from 'models/vo/AnchorReportVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { DealerReportListRequest } from 'utils/http/api/financier/anchor-reports/requests';
import { requestSyAnchorReportDownload, requestSyAnchorReportList } from 'utils/http/api/system/anchor-report';
import {
  requestSystemAnchorReportReceiverDetail,
  requestSystemAnchorReportReceiverRelatedAgreementList,
} from 'utils/http/api/system/anchor-report-receivers';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);

  const PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 140,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 140,
    },
    {
      headerText: t('text:Agreement_Suspension'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const REPORT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Created_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Sent_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Email'),
      colWidths: 260,
    },
    {
      headerText: '',
      colWidths: 100,
    },
  ];

  const REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER: REPORT_RECEIVER_TYPE.ANCHOR_REPORT_RECEIVER =
    REPORT_RECEIVER_TYPE.ANCHOR_REPORT_RECEIVER;

  return {
    mounted,
    modal,
    history,
    t,
    PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS,
    REPORT_LIST_TABLE_HEADERS,
    REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER,
  };
};

function SystemMonitorPartnerReportDetail(): JSX.Element {
  const {
    mounted,
    modal,
    t,
    PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS,
    REPORT_LIST_TABLE_HEADERS,
    REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER,
  } = getConstants();

  const anchorReportReceiverId: number = (useParams() as any).id;

  const [reportSettingInfo, setReportSettingInfo] = useState<AnchorReportReceiverVOModel>();
  const [partnerMasterAgreementPageData, setPartnerMasterAgreementPageData] =
    useState<Pageable<DealerAgreementVOModel[]>>();
  const [reportPageData, setReportPageData] = useState<Pageable<AnchorReportVOModel[]>>();

  const { pageable: partnerMasterAgreementPageable, setPageable: setPartnerMasterAgreementPageable } = usePageable();
  const { pageable: reportPageable, setPageable: setReportPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [reportSettingInfomation, partnerMasterAgreementList, reportList] = await Promise.all([
        requestSystemAnchorReportReceiverDetail(anchorReportReceiverId),
        requestSystemAnchorReportReceiverRelatedAgreementList(
          partnerMasterAgreementPageable.currentPage,
          partnerMasterAgreementPageable.sizePerPage,
          anchorReportReceiverId,
        ),
        fetchReportList(reportPageable.currentPage, reportPageable.sizePerPage),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setReportSettingInfo(reportSettingInfomation);
        setPartnerMasterAgreementPageData(partnerMasterAgreementList);
        setPartnerMasterAgreementPageable(partnerMasterAgreementList);
        setReportPageData(reportList);
        setReportPageable(reportList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchPartnerMasterAgreementList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
  ): Promise<void> => {
    try {
      const partnerMasterAgreementList = await requestSystemAnchorReportReceiverRelatedAgreementList(
        selectedPageNumber,
        selectedRowCount,
        anchorReportReceiverId,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerMasterAgreementPageData(partnerMasterAgreementList);
        setPartnerMasterAgreementPageable(partnerMasterAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchReportList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
  ): Promise<Pageable<AnchorReportVOModel[]>> => {
    const reportList = await requestSyAnchorReportList<DealerReportListRequest>(selectedPageNumber, selectedRowCount, {
      anchorReportReceiverId,
      reportReceiverType: REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER,
    });

    return reportList;
  };

  const fetchAndSetReportList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
  ): Promise<void> => {
    try {
      const reportList = await fetchReportList(selectedPageNumber, selectedRowCount);

      ReactDOM.unstable_batchedUpdates(() => {
        setReportPageData(reportList);
        setReportPageable(reportList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getRecipientStatusText = (activated: boolean | undefined): string => {
    if (activated === true) return t('text:Active');
    if (activated === false) return t('text:Deactivated');

    return '-';
  };

  const renderPartnerMasterAgreementPageTable = (): JSX.Element[] | undefined => {
    return partnerMasterAgreementPageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.dealerFinancierClientName} />
        <Td data={item.dealerFinancierClientCode} />
        <Td data={item.contractNo} />
        <Td
          className={getStatusTextClass('DEALER_AGREEMENT_STATUS', item.dealerAgreementStatus)}
          data={t(`code:dealer-agreement-status.${item.dealerAgreementStatus}`)}
          format="code"
        />
        <TdLink path={ROUTES_SY.MONITOR_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.dealerAgreementId)} />
      </Tr>
    ));
  };

  const renderReportPageTable = (): JSX.Element[] | undefined => {
    const onClickDownload = async (e: any, item: AnchorReportVOModel): Promise<void> => {
      e.preventDefault();

      try {
        await requestSyAnchorReportDownload(item.anchorReportId);
      } catch (error) {
        modal.show(error);
      }
    };

    return reportPageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.sent ? item.updatedDateTime : ''} format="datetime" />
        <Td data={item.sentEmail} />
        <Td className="text-center">
          <DownloadButton onClick={e => onClickDownload(e, item)} disabled={!item.sent} />
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Recipient_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Report_Setting')} />
        <FormBorder>
          <FormSubtitle title={t('text:Report_Setting_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Report_Type')}
                value={t(`code:report-type.${reportSettingInfo?.reportType}`)}
                format="code"
              />
              <FormValue
                className={reportSettingInfo?.activated ? 'text-bold-blue-main600' : 'text-bold-brick-red'}
                label={t('text:Recipient_Status')}
                value={getRecipientStatusText(reportSettingInfo?.activated)}
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Associated_Anchor_Master_Agreement_Information')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={reportSettingInfo?.anchorClientName} />
              <FormValue label={t('text:Anchor_Client_Code')} value={reportSettingInfo?.anchorClientCode} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                value={reportSettingInfo?.anchorAgreementContractNo}
              />
              <FormValue col={3} label={t('text:Currency')} value={reportSettingInfo?.currencyType} />
              <FormValue
                col={3}
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${reportSettingInfo?.collateralType}`)}
                format="code"
              />
            </div>
            <div className="row">
              <FormValue
                col={6}
                label={t('text:Agreement_Suspension')}
                value={t(`code:anchor-agreement-status.${reportSettingInfo?.anchorAgreementStatus}`)}
                format="code"
              />
              <FormValue
                col={3}
                label={t('text:Effective_Date')}
                format="date"
                value={reportSettingInfo?.agreementStartDate}
              />
              <FormValue
                col={3}
                label={t('text:Expiration_Date')}
                format="date"
                value={reportSettingInfo?.agreementExpiryDate}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Recipient_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Recipient_Name')} value={reportSettingInfo?.name} />
              <FormValue col={3} label={t('text:Email')} value={reportSettingInfo?.email} />
              <FormValue col={3} label={t('text:Affiliation')} value={reportSettingInfo?.affiliation} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <div className="d-flex align-items-center mb-3">
          <SectionTitle title={t('text:Partner_Master_Agreement_List')} />
        </div>
        <TableBorder>
          <TableHeader header={PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS.length}>
            {renderPartnerMasterAgreementPageTable()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={partnerMasterAgreementPageable} paginate={fetchPartnerMasterAgreementList} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Report')} />
        <TableBorder>
          <TableHeader header={REPORT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={REPORT_LIST_TABLE_HEADERS.length}>{renderReportPageTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={reportPageable} paginate={fetchAndSetReportList} />
      </div>
    </>
  );
}

export default SystemMonitorPartnerReportDetail;
