import type React from 'react';
import { useHistory } from 'react-router-dom';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './PageTitle.scss';
import { Button2ColorEnum } from '../../stateless/Button2/Button2';
import IconButton2 from '../../stateless/Button2/IconButton2';

interface PageTitleProps {
  title: string;
  backButtonColor?: Button2ColorEnum;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function PageTitle({ title, backButtonColor = Button2ColorEnum.TERTIARY, onClick }: PageTitleProps) {
  const history = useHistory();

  return (
    <div className="pp-page-title">
      <IconButton2
        onClick={onClick ? onClick : history.goBack}
        color={backButtonColor}
        icon={<FontAwesomeIcon icon={faArrowLeft} color={backButtonColor} fontSize={20} />}
      />
      <div className={`pp-page-title__text--${backButtonColor}`}>{title}</div>
    </div>
  );
}

export default PageTitle;
