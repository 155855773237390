import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import CitadCodeModal from 'components/stateless/Modal/common/CitadCodeModal';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { WaitingAnchorPartnerDetailVOModel } from 'models/vo/WaitingAnchorPartnerDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { formErrorHandler } from 'utils/error/manager';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestWaitingAnchorPartnerApprove,
  requestWaitingAnchorPartnerCancel,
  requestWaitingAnchorPartnerData,
  requestWaitingAnchorPartnerOnRegistrationModify,
  requestWaitingAnchorPartnerReturn,
} from 'utils/http/api/anchor/waiting-anchor-partners';
import type { UpdateAcWaitingAnchorPartnerOnRegistrationRequest } from 'utils/http/api/anchor/waiting-anchor-partners/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getPaymentSupportText } from 'utils/text';

interface RenderManagerOption {
  targetAuthorityType?: AUTHORITY_TYPE | AUTHORITY_TYPE[];
  targetApprovalType?: COMMON_APPROVAL_TYPE | COMMON_APPROVAL_TYPE[];
}

function AnchorPartnerRegistrationWaitingDetail() {
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { waitingAnchorPartnerId } = useParams() as any;
  const { financierId } = useParams() as any;
  const { register, getValues, setValue, errors, setError, clearErrors } =
    useForm<UpdateAcWaitingAnchorPartnerOnRegistrationRequest>({
      mode: 'onSubmit',
      shouldFocusError: true,
    });

  const getWaitingAnchorPartnerProperty = useProperty<UpdateAcWaitingAnchorPartnerOnRegistrationRequest>();

  const [waitingAnchorPartnerDetailData, setWaitingAnchorPartnerDetailData] =
    useState<WaitingAnchorPartnerDetailVOModel>();
  const [financierClientAuthSettingData, setFinancierClientAuthSettingData] =
    useState<FinancierClientAuthSettingVOModel>();

  const isOperatorEnterpriseTypeAnchor = waitingAnchorPartnerDetailData?.operatorEnterpriseType === ENTERPRISE_TYPE.AC;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [waitingAnchorPartnerDetailData, financierClientAuthSettingData] = await Promise.all([
        requestWaitingAnchorPartnerData(waitingAnchorPartnerId),
        requestAnchorClientAuthByFinancierId(financierId),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerDetailData(waitingAnchorPartnerDetailData);
        setFinancierClientAuthSettingData(financierClientAuthSettingData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderManager = (option: RenderManagerOption): boolean => {
    let authorityTypeList = undefined;
    let approvalTypeList = undefined;

    if (option.targetAuthorityType) {
      authorityTypeList = Array.isArray(option.targetAuthorityType)
        ? option.targetAuthorityType
        : [option.targetAuthorityType];
    }

    if (option.targetApprovalType) {
      approvalTypeList = Array.isArray(option.targetApprovalType)
        ? option.targetApprovalType
        : [option.targetApprovalType];
    }

    if (authorityTypeList) {
      if (
        financierClientAuthSettingData?.currentAuthorityType &&
        authorityTypeList.includes(financierClientAuthSettingData.currentAuthorityType)
      ) {
        if (approvalTypeList) {
          if (
            waitingAnchorPartnerDetailData?.approvalType &&
            approvalTypeList.includes(waitingAnchorPartnerDetailData.approvalType)
          ) {
            return true;
          } else return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      if (approvalTypeList) {
        if (
          waitingAnchorPartnerDetailData?.approvalType &&
          approvalTypeList.includes(waitingAnchorPartnerDetailData.approvalType)
        ) {
          return true;
        } else return false;
      }

      return false;
    }
  };

  const isDisabled = (): boolean => {
    if (
      renderManager({
        targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
        targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
      }) &&
      isOperatorEnterpriseTypeAnchor
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isEditable = (): boolean => {
    return (
      renderManager({
        targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
        targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
      }) && isOperatorEnterpriseTypeAnchor
    );
  };

  const renderGuideMessageComponent = (): JSX.Element | undefined => {
    //TODO: OperatorEnterpriseType에 따라 각기 다른 가이드 메시지 보여줄 필요 있음.
    const getGuideMessage = (): string[] => {
      if (
        renderManager({
          targetAuthorityType: AUTHORITY_TYPE.ADMIN,
          targetApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        })
      ) {
        return [
          t('text:The_registration_request_has_been_submitted_for_confirmation'),
          t('text:Click_on_the_Confirm_button_to_approve_the_registration_request'),
          t(
            'text:If_the_company_s_tax_code_is_incorrect_click_on_the_Revert_button_and_return_the_registration_request',
          ),
        ];
      } else if (
        renderManager({
          targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
          targetApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        })
      ) {
        return [
          t('text:The_Supplier_registration_has_been_requested'),
          t('text:Registration_will_be_completed_when_the_Admin_confirms_the_request'),
        ];
      } else if (
        renderManager({
          targetAuthorityType: AUTHORITY_TYPE.ADMIN,
          targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
        })
      ) {
        return [t('text:The_registration_request_has_been_reverted')];
      } else if (
        renderManager({
          targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
          targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
        })
      ) {
        return [
          t('text:The_registration_request_has_been_reverted'),
          t('text:Please_check_the_reason_for_the_revert_below_and_proceed_with_the_update'),
        ];
      } else return [];
    };

    return renderManager({
      targetAuthorityType: [AUTHORITY_TYPE.ADMIN, AUTHORITY_TYPE.HQ_OPERATOR],
      targetApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
    }) ||
      renderManager({
        targetAuthorityType: [AUTHORITY_TYPE.ADMIN, AUTHORITY_TYPE.HQ_OPERATOR],
        targetApprovalType: [COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED],
      }) ? (
      <GuideMessage
        message={getGuideMessage()}
        messageType={
          renderManager({ targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED })
            ? MessageType.ALERT
            : MessageType.BASIC
        }
        reason={
          renderManager({ targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED })
            ? waitingAnchorPartnerDetailData?.returnReason
            : undefined
        }
        reasonTitle={
          renderManager({ targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED })
            ? t('text:REASON_FOR_REVERT')
            : ''
        }
      />
    ) : undefined;
  };

  const renderStatusBadgeComponent = (): JSX.Element => {
    const getStatusBadgeText = (): string => {
      switch (waitingAnchorPartnerDetailData?.approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Reverted');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Cancelled');
        default:
          return '';
      }
    };

    const getStatusBadgeUserInfo = (): JSX.Element => {
      switch (waitingAnchorPartnerDetailData?.approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return (
            <>
              <span className="supply-chain-partner-requester-info-title">
                {t('text:Admin_Operator')} ({waitingAnchorPartnerDetailData.operatorUserName} /{' '}
                {waitingAnchorPartnerDetailData?.operatorUserLoginId})
              </span>
              <span>{waitingAnchorPartnerDetailData?.operatorEnterpriseName}</span>
            </>
          );
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return (
            <>
              <span className="supply-chain-partner-requester-info-title">
                {t('text:Admin')} ({waitingAnchorPartnerDetailData.authorizerUserName} /{' '}
                {waitingAnchorPartnerDetailData?.authorizerUserLoginId})
              </span>
              <span>{waitingAnchorPartnerDetailData?.authorizerEnterpriseName}</span>
            </>
          );
        default:
          return <></>;
      }
    };

    const getStatusTimeText = (): string => {
      switch (waitingAnchorPartnerDetailData?.approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested_Date');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Reverted_Date');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Cancelled_Date');
        default:
          return '';
      }
    };

    return (
      <div className="content-area">
        <div className="supply-chain-partner-status-block">
          <span
            className={`me-4 ${getStatusBadgeClass(
              'COMMON_APPROVAL_TYPE',
              waitingAnchorPartnerDetailData?.approvalType,
            )}`}
          >
            {getStatusBadgeText()}
          </span>
          <span className="supply-chain-partner-requester-info">{getStatusBadgeUserInfo()}</span>
          <span className="supply-chain-partner-requester-info supply-chain-partner-requester-info-time">
            <span className="supply-chain-partner-requester-info-title">{getStatusTimeText()}</span>
            <span>
              {t('format:datetime', { value: waitingAnchorPartnerDetailData?.updatedDateTime, key: 'datetime' })}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderSupplyChainPartnerInformationComponent = (): JSX.Element => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Supplier_Information`)} />
          <FormBorder editable={isEditable()}>
            <FormContents>
              <div className="row">
                <FormInput
                  requiredOptions={{
                    fixedRequired: renderManager({
                      targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                      targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    }),
                  }}
                  label={t(`text:Supplier_Name`)}
                  name={getWaitingAnchorPartnerProperty('name')}
                  defaultValue={waitingAnchorPartnerDetailData?.name}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.name}
                />
                <FormInput
                  requiredOptions={{
                    fixedRequired: renderManager({
                      targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                      targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    }),
                  }}
                  label={t(`text:Tax_Code`)}
                  name={getWaitingAnchorPartnerProperty('partnerTaxCode')}
                  defaultValue={waitingAnchorPartnerDetailData?.partnerTaxCode}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.partnerTaxCode}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Company_Registration_Number`)}
                  name={getWaitingAnchorPartnerProperty('partnerBusinessCode')}
                  defaultValue={waitingAnchorPartnerDetailData?.partnerBusinessCode}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.partnerBusinessCode}
                />
                <FormInput
                  col={3}
                  requiredOptions={{
                    fixedRequired: renderManager({
                      targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                      targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    }),
                  }}
                  label={t(`text:Telephone`)}
                  name={getWaitingAnchorPartnerProperty('telephone')}
                  defaultValue={waitingAnchorPartnerDetailData?.telephone}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.telephone}
                />
                <FormInput
                  col={3}
                  label={t(`text:Fax`)}
                  name={getWaitingAnchorPartnerProperty('fax')}
                  defaultValue={waitingAnchorPartnerDetailData?.fax}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.fax}
                />
              </div>
              <div className="row">
                <FormInput
                  requiredOptions={{
                    fixedRequired: renderManager({
                      targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                      targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    }),
                  }}
                  label={t(`text:Legal_Representative_Name`)}
                  name={getWaitingAnchorPartnerProperty('representativeName')}
                  defaultValue={waitingAnchorPartnerDetailData?.representativeName}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.representativeName}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  name={getWaitingAnchorPartnerProperty('representativePosition')}
                  defaultValue={waitingAnchorPartnerDetailData?.representativePosition}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.representativePosition}
                />
                <FormInput
                  col={3}
                  requiredOptions={{
                    fixedRequired: renderManager({
                      targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                      targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    }),
                  }}
                  label={t(`text:Legal_Representative_Email`)}
                  name={getWaitingAnchorPartnerProperty('representativeEmail')}
                  defaultValue={waitingAnchorPartnerDetailData?.representativeEmail}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.representativeEmail}
                />
              </div>
              <div className="row">
                <FormInput
                  col={12}
                  requiredOptions={{
                    fixedRequired: renderManager({
                      targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                      targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    }),
                  }}
                  label={t(`text:Registered_Office_Address`)}
                  name={getWaitingAnchorPartnerProperty('address')}
                  defaultValue={waitingAnchorPartnerDetailData?.address}
                  disabled={isDisabled()}
                  ref={register}
                  error={errors.address}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderDesignatedBankAccountInformationforAPSettlementComponent = (): JSX.Element => {
    const onClickFind = (e: any): void => {
      e.preventDefault();

      const setBankCodeData = (data: BankCodeVOModel): void => {
        setValue('bankCodeId', data.id);
        setValue('bankCode', data.bankCode);
        setValue('bankName', data.bankName);
        setValue('branchCode', data.branchCode);
        setValue('branchName', data.branchName);
      };

      modal.show(
        <CitadCodeModal
          handleBankCodeData={setBankCodeData}
          modalId={modal.id}
          financierId={financierClientAuthSettingData?.financierId!}
        />,
        {
          title: t('text:Find_Bank_Code'),
          modalType: ModalType.ALERT,
          modalSize: ModalSize.L,
          closeBtnText: t('text:Close'),
        },
      );
    };

    return (
      <div className="content-area">
        <SectionTitle title={t(`text:Designated_Bank_Account_Information_for_AP_Settlement`)} />
        <FormBorder editable={isEditable()}>
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:Associated_Anchor_Master_Agreement_Information')}
          />
          <FormContents>
            <div className="row">
              <FormValue
                label={t(`text:Anchor_Master_Agreement_Number`)}
                value={waitingAnchorPartnerDetailData?.anchorAgreementContractNo}
              />
            </div>
            <div className="row">
              <FormValue label={t(`text:Currency`)} value={waitingAnchorPartnerDetailData?.currencyType} />
              <FormValue
                label={t(`text:Automatic_Settlement`)}
                value={getPaymentSupportText(waitingAnchorPartnerDetailData?.paymentSupport)}
              />
            </div>
            <div className="row">
              <FormValue
                label={t(`text:Responsible_Bank_Name`)}
                value={waitingAnchorPartnerDetailData?.responsibleFinancierName}
              />
              <FormValue
                label={t(`text:Responsible_Branch_Name`)}
                value={waitingAnchorPartnerDetailData?.responsibleBranchName}
              />
            </div>
          </FormContents>

          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:Designated_Bank_Account_Information')}
          />
          <FormContents>
            <div className="row">
              <input type="hidden" name={getWaitingAnchorPartnerProperty('bankCodeId')} ref={register} />
              {waitingAnchorPartnerDetailData && (
                <FormInput
                  requiredOptions={{
                    fixedRequired: renderManager({
                      targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                      targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    }),
                  }}
                  label={t(`text:Bank_Code`)}
                  name={getWaitingAnchorPartnerProperty('bankCode')}
                  defaultValue={String(waitingAnchorPartnerDetailData.accountBankCode)}
                  disabled={true}
                  ref={register}
                  error={errors.bankCode}
                >
                  {isEditable() && (
                    <Button size={ButtonSizeEnum.SM} onClick={onClickFind}>
                      {t('text:Find')}
                    </Button>
                  )}
                </FormInput>
              )}
              <FormInput
                requiredOptions={{
                  fixedRequired: renderManager({
                    targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                    targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  }),
                }}
                label={t(`text:Bank_Name`)}
                name={getWaitingAnchorPartnerProperty('bankName')}
                defaultValue={waitingAnchorPartnerDetailData?.accountBankName}
                disabled={true}
                ref={register}
                error={errors.bankName}
              />
            </div>
            <div className="row">
              <FormInput
                requiredOptions={{
                  fixedRequired: renderManager({
                    targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                    targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  }),
                }}
                label={t(`text:Branch_Code`)}
                name={getWaitingAnchorPartnerProperty('branchCode')}
                defaultValue={waitingAnchorPartnerDetailData?.accountBranchCode}
                disabled={true}
                ref={register}
                error={errors.branchCode}
              />
              <FormInput
                requiredOptions={{
                  fixedRequired: renderManager({
                    targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                    targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  }),
                }}
                label={t(`text:Branch_Name`)}
                name={getWaitingAnchorPartnerProperty('branchName')}
                defaultValue={waitingAnchorPartnerDetailData?.accountBranchName}
                disabled={true}
                ref={register}
                error={errors.branchName}
              />
            </div>
            <div className="row">
              <FormInput
                requiredOptions={{
                  fixedRequired: renderManager({
                    targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                    targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  }),
                }}
                label={t(`text:Bank_Account_Number`)}
                name={getWaitingAnchorPartnerProperty('account')}
                defaultValue={waitingAnchorPartnerDetailData?.account}
                disabled={isDisabled()}
                ref={register}
                error={errors.account}
                applyUpperCase
              />
              <FormInput
                requiredOptions={{
                  fixedRequired: renderManager({
                    targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                    targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  }),
                }}
                label={t(`text:Bank_Account_Holder`)}
                name={getWaitingAnchorPartnerProperty('accountOwner')}
                defaultValue={waitingAnchorPartnerDetailData?.accountOwner}
                disabled={isDisabled()}
                ref={register}
                error={errors.accountOwner}
                applyUpperCase
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    );
  };

  const renderManageButtonComponent = (): JSX.Element | undefined => {
    const onClickCancelRequest = (e: any): void => {
      e.preventDefault();

      const onClickConfirm = async (): Promise<void> => {
        try {
          waitingAnchorPartnerDetailData?.waitingAnchorPartnerId &&
            (await requestWaitingAnchorPartnerCancel({
              waitingAnchorPartnerIds: [waitingAnchorPartnerDetailData.waitingAnchorPartnerId],
            }));

          fetchAll();
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_Supplier_registration_request?')}
          <br />
          {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirm(),
        },
      );
    };

    const onClickChangeRequest = (): void => {
      const onClickConfirm = async (): Promise<void> => {
        try {
          const isNotSelectedBankCodeId = getValues('bankCodeId') === ('' as unknown);
          const alreadyRegisteredBankCodeId = waitingAnchorPartnerDetailData?.accountBankCodeId as number;
          const newlySelectedBankCodeId = getValues('bankCodeId');

          const bankCodeId = isNotSelectedBankCodeId ? alreadyRegisteredBankCodeId : newlySelectedBankCodeId;
          const data = { ...getValues(), bankCodeId };

          waitingAnchorPartnerDetailData?.waitingAnchorPartnerId &&
            (await requestWaitingAnchorPartnerOnRegistrationModify(
              waitingAnchorPartnerDetailData?.waitingAnchorPartnerId,
              data,
            ));

          clearErrors();

          modal.show(<h6>{t('text:The_request_for_registration_confirmation_has_been_submitted_successfully')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => fetchAll(),
          });
        } catch (error) {
          modal.show(error);
          formErrorHandler<UpdateAcWaitingAnchorPartnerOnRegistrationRequest>(error, setError, clearErrors);
        }
      };

      modal.show(<h6>{t('text:Would_you_like_to_request_approval_for_modification?')}</h6>, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      });
    };

    const onClickConfirm = async (e: any): Promise<void> => {
      const financierId = financierClientAuthSettingData?.financierId ?? NaN;
      e.preventDefault();

      const showVerificationOtpModal = () => {
        const verificationCode: UserVerificationCodeRequest = {};

        modal.show(
          <UserVerificationModal
            modalId={modal.id}
            verificationCode={verificationCode}
            requestIdType="financierId"
            requestId={financierId}
            clientAuthSetting={financierClientAuthSettingData as FinancierClientAuthSettingVOModel}
          />,
          {
            modalType: ModalType.CONFIRM,
            title: t('text:User_Verification'),
            closeBtnText: t('text:Cancel'),
            confirmBtnCb: () => onClickConfirm(verificationCode),
          },
        );
      };

      const onClickConfirm = async (verificationCode?: UserVerificationCodeRequest): Promise<void> => {
        const waitingAnchorPartnerId = waitingAnchorPartnerDetailData?.waitingAnchorPartnerId ?? NaN;

        try {
          const data = {
            financierId,
            otpCode: verificationCode?.otpCode,
            queryValue: verificationCode?.queryValue,
            waitingAnchorPartnerIds: [waitingAnchorPartnerId],
          };

          await requestWaitingAnchorPartnerApprove(data);

          modal.show(<h6>{t('text:The_Supplier_has_been_registered_successfully')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => history.push(ROUTES_AC.MANAGE_PARTNER.REGISTRATION_LIST),
          });
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(<h6>{t('text:Would_you_like_to_confirm_the_request?')}</h6>, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () =>
          financierClientAuthSettingData?.otpType !== OTP_TYPE.NONE ? showVerificationOtpModal() : onClickConfirm(),
      });
    };

    const onClickRevert = async (e: any): Promise<void> => {
      e.preventDefault();

      let reason: string = '';

      const onClickConfirm = async (): Promise<void> => {
        try {
          waitingAnchorPartnerDetailData?.waitingAnchorPartnerId &&
            (await requestWaitingAnchorPartnerReturn({
              waitingAnchorPartnerIds: [waitingAnchorPartnerDetailData.waitingAnchorPartnerId],
              returnReason: reason,
            }));

          modal.show(<h6>{t('text:The_registration_request_has_been_reverted')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => fetchAll(),
          });
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_registration_request?')}
            <br />
            {t('text:Please_type_below_the_reason_for_the_revert')}
            <br />
            {t('text:Click_on_Confirm_to_revert_the_request')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirm(),
        },
      );
    };

    if (
      renderManager({
        targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
        targetApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
      })
    ) {
      return (
        <div className="content-area flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancelRequest}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
          >
            {t('text:Cancel_Request')}
          </Button>
        </div>
      );
    } else if (
      renderManager({
        targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
        targetApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
      })
    ) {
      return (
        <div className="content-area flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancelRequest}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            className="me-2"
          >
            {t('text:Cancel_Request')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onClickChangeRequest}>
            {t('text:Request_Change')}
          </Button>
        </div>
      );
    } else if (
      renderManager({
        targetAuthorityType: AUTHORITY_TYPE.ADMIN,
        targetApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
      })
    ) {
      return (
        <div className="content-area flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickRevert}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.RED}
            className="me-2"
          >
            {t('text:Revert')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onClickConfirm}>
            {t('text:Confirm')}
          </Button>
        </div>
      );
    } else return;
  };

  return (
    <div className="">
      <BackHeaderTitle title={waitingAnchorPartnerDetailData?.name} />
      {renderGuideMessageComponent()}
      {renderStatusBadgeComponent()}
      {renderSupplyChainPartnerInformationComponent()}
      {renderDesignatedBankAccountInformationforAPSettlementComponent()}
      {isOperatorEnterpriseTypeAnchor && renderManageButtonComponent()}
    </div>
  );
}

export default AnchorPartnerRegistrationWaitingDetail;
