import { formattingToUserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import type { UserEmailSettingVO, UserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import http from 'utils/http';

import API_DE from '../index';

import type { DealerUserEmailSettingListRequest, DealerUserEmailSettingUpdateRequest } from './requests';

export async function requestDealerEmailSettingListRequest(
  searchValue: DealerUserEmailSettingListRequest,
): Promise<UserEmailSettingVOModel[]> {
  const response = await http.get<UserEmailSettingVO[]>({
    url: API_DE.USER_EMAIL_SETTING.USER_EMAIL_SETTING,
    data: {
      ...searchValue,
    },
  });

  return response.map(item => formattingToUserEmailSettingVOModel(item));
}

export async function requestDealerEmailSettingListUpdate(
  data: DealerUserEmailSettingUpdateRequest,
): Promise<UserEmailSettingVOModel[]> {
  const response = await http.put<UserEmailSettingVO[]>({
    url: API_DE.USER_EMAIL_SETTING.USER_EMAIL_SETTING,
    data,
  });

  return response.map(item => formattingToUserEmailSettingVOModel(item));
}
