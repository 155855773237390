import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';

import { useAgreementContext } from './AgreementContext';

const useAgreementViewModel = () => {
  const {
    agreement,
    isSearchedAgreement,
    isPartnerInfoExist,
    isFirstRegisteredWaitingAgreement,
    isMaturityExtension,
    updateAgreement,
    updateIsSearchedAgreement,
    updateIsMaturityExtension,
    updateAllAgreement,
    fetchSavedAgreement,
    fetchWaitingAgreement,
    fetchAgreementDetail,
  } = useAgreementContext();

  const dealerTaxCode = agreement?.dealerClientTaxCode;

  const updateDealerAgreementInfo = (data: FinancierClientVOModel) => {
    updateAgreement({
      dealerClientId: data.financierClientId,
      dealerClientCode: data.financierClientCode,
      dealerClientName: data.financierClientName,
      dealerClientTaxCode: data.financierClientTaxCode,
    });
  };

  const updateAssociatedAnchorAgreementInfo = (data?: AnchorAgreementVOModel) => {
    updateAgreement({
      anchorAgreementId: data?.anchorAgreementId,
      currencyType: data?.currencyType,
    });
  };

  const updateDivisionInfo = (data?: AnchorAgreementDetailVOModel) => {
    updateAgreement({
      division: data?.division ?? '',
      divisionName: data?.divisionName ?? '',
    });
  };

  const updateBranchInfo = (data: BranchVOModel) => {
    updateAgreement({
      branchCode: data.branchCode,
      branchName: data.branchName,
      branchTelephone: data.telephone,
      branchFax: data.fax,
      branchAddress: data.address,
    });
  };

  return {
    agreement,
    isSearchedAgreement,
    isPartnerInfoExist,
    isFirstRegisteredWaitingAgreement,
    isMaturityExtension,
    dealerTaxCode,
    updateAgreement,
    updateAllAgreement,
    updateDealerAgreementInfo,
    updateAssociatedAnchorAgreementInfo,
    updateDivisionInfo,
    updateBranchInfo,
    updateIsSearchedAgreement,
    updateIsMaturityExtension,
    fetchSavedAgreement,
    fetchWaitingAgreement,
    fetchAgreementDetail,
  };
};

export default useAgreementViewModel;
