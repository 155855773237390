import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { CURRENCY_TYPE } from 'enums';

type CreateFinancierStep1Value = {
  supportedCurrencyList: CURRENCY_TYPE[];
  logoAttachFileName: string;
};

type CreateFinancierStep1Actions = {
  updateSupportedCurrencyList: (updateFunc: (prevList: CURRENCY_TYPE[]) => CURRENCY_TYPE[]) => void;
  updateLogoAttachFileName: (name?: string) => void;
};

const CreateFinancierStep1ValueContext = createContext<CreateFinancierStep1Value>({
  supportedCurrencyList: [],
  logoAttachFileName: '',
});

const CreateFinancierStep1ActionsContext = createContext<CreateFinancierStep1Actions>({
  updateSupportedCurrencyList: () => {},
  updateLogoAttachFileName: () => {},
});

const CreateFinancierStep1Provider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [supportedCurrencyList, setSupportedCurrencyList] = useState<CURRENCY_TYPE[]>([]);
  const [logoAttachFileName, setLogoAttachFileName] = useState<string>('');

  const updateSupportedCurrencyList = useCallback(currencyType => {
    setSupportedCurrencyList(currencyType);
  }, []);

  const updateLogoAttachFileName = useCallback(name => {
    setLogoAttachFileName(name || '');
  }, []);

  const actions: CreateFinancierStep1Actions = useMemo(() => {
    return {
      updateSupportedCurrencyList,
      updateLogoAttachFileName,
    };
  }, [updateLogoAttachFileName, updateSupportedCurrencyList]);

  return (
    <CreateFinancierStep1ActionsContext.Provider value={actions}>
      <CreateFinancierStep1ValueContext.Provider
        value={{
          supportedCurrencyList,
          logoAttachFileName,
        }}
      >
        {children}
      </CreateFinancierStep1ValueContext.Provider>
    </CreateFinancierStep1ActionsContext.Provider>
  );
};

export default CreateFinancierStep1Provider;

export const useCreateFinancierStep1Value = () => useContext(CreateFinancierStep1ValueContext);

export const useCreateFinancierStep1Actions = () => useContext(CreateFinancierStep1ActionsContext);
