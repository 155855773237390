import { useTranslation } from 'react-i18next';

import type { SidebarMenuInfoType } from 'components/stateless/Navigation/Sidebar/useSidebarController';
import { ROUTES_SY } from 'constants/routes/system';

export function syMenuInfo() {
  const { t } = useTranslation(['format']);
  const isProductionEnvironment = process.env.REACT_APP_ENVIRONMENT === 'production';

  const menuInfo: Array<SidebarMenuInfoType> = [
    {
      mainMenu: { title: t('text:Management') },
      subMenu: [
        { title: t('text:Financier'), path: ROUTES_SY.MANAGEMENT.FINANCIER_LIST },
        { title: t('text:Financier_Admin'), path: ROUTES_SY.MANAGEMENT.FINANCIER_ADMIN_LIST },
        { title: t('text:Bank_Code'), path: ROUTES_SY.MANAGEMENT.BANK_CODE_LIST },
        { title: t('text:Billing'), path: ROUTES_SY.MANAGEMENT.BILLING, hidden: isProductionEnvironment },
        { title: t('text:Transaction_Performance'), path: ROUTES_SY.MANAGEMENT.TRANSACTION_PERFORMANCE_LIST },
        { title: t('text:TOU'), path: ROUTES_SY.MANAGEMENT.TOU_LIST },
      ],
    },
    {
      mainMenu: { title: t('text:Monitor_Platform') },
      subMenu: [
        { title: t('text:Enterprise'), path: ROUTES_SY.MONITOR_PLATFORM.ENTERPRISE_LIST },
        { title: t('text:User'), path: ROUTES_SY.MONITOR_PLATFORM.USER_LIST },
        { title: t('text:Uploaded_Partner'), path: ROUTES_SY.MONITOR_PLATFORM.UPLOADED_PARTNER_LIST },
        { title: t('text:Login_History'), path: ROUTES_SY.MONITOR_PLATFORM.LOGIN_HISTORY_LIST },
      ],
    },
    {
      mainMenu: { title: t('text:Monitor_Anchor') },
      subMenu: [
        { title: t('text:Company'), path: ROUTES_SY.MONITOR_ANCHOR.COMPANY_LIST },
        { title: t('text:Agreement'), path: ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_LIST },
        { title: t('text:User'), path: ROUTES_SY.MONITOR_ANCHOR.USER_LIST },
        { title: t('text:Report'), path: ROUTES_SY.MONITOR_ANCHOR.REPORT_LIST },
      ],
    },
    {
      mainMenu: { title: t('text:Monitor_Partner') },
      subMenu: [
        { title: t('text:Company'), path: ROUTES_SY.MONITOR_PARTNER.COMPANY_LIST },
        { title: t('text:Master_Agreement'), path: ROUTES_SY.MONITOR_PARTNER.AGREEMENT_LIST },
        { title: t('text:Report'), path: ROUTES_SY.MONITOR_PARTNER.REPORT_LIST },
      ],
    },
    {
      mainMenu: { title: t('text:Register_Invoices') },
      subMenu: [{ title: t('text:Confirmation'), path: ROUTES_SY.REGISTER_INVOICE.CONFIRMATION_LIST }],
    },
    {
      mainMenu: { title: t('text:Register_AR') },
      subMenu: [{ title: t('text:Confirmation'), path: ROUTES_SY.REGISTER_AR.CONFIRMATION_LIST }],
    },
    {
      mainMenu: { title: t('text:Monitor_Financing') },
      subMenu: [
        { title: t('text:Early_Repayment'), path: ROUTES_SY.MONITOR_FINANCING.EARLY_REPAYMENT_LIST },
        { title: t('text:Extension'), path: ROUTES_SY.MONITOR_FINANCING.EXTENSION_LIST },
        { title: t('text:Secured_Inventory'), path: ROUTES_SY.MONITOR_FINANCING.SECURED_INVENTORY_LIST },
      ],
    },
    {
      mainMenu: { title: t('text:View_Transactions') },
      subMenu: [
        { title: t('text:AR'), path: ROUTES_SY.VIEW_TRANSACTION.AR_LIST },
        { title: t('text:Invoice'), path: ROUTES_SY.VIEW_TRANSACTION.INVOICE_LIST },
        { title: t('text:Invoice_Payment'), path: ROUTES_SY.VIEW_TRANSACTION.INVOICE_PAYMENT_LIST },
        { title: t('text:Financing'), path: ROUTES_SY.VIEW_TRANSACTION.FINANCING_LIST },
        { title: t('text:Overview_by_Anchor'), path: ROUTES_SY.VIEW_TRANSACTION.OVERVIEW_BY_ANCHOR_LIST },
        { title: t('text:Overview_by_Partner'), path: ROUTES_SY.VIEW_TRANSACTION.OVERVIEW_BY_PARTNER_LIST },
      ],
    },
    {
      mainMenu: { title: t('text:My_Account'), path: ROUTES_SY.MY_ACCOUNT },
    },
  ];

  return { menuInfo };
}
