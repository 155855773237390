import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faMinus, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import RadioButton from 'components/stateless/Button/RadioButton';
import type { NumericType } from 'components/stateless/CommonForm/NumericFormatInput';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Select from 'components/stateless/Select/Select';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { requestDealerFinancierClients } from 'utils/http/api/dealer/financier-clients';
import { requestDealerInventoryRegister } from 'utils/http/api/dealer/inventory-summaries';
import type {
  DealerInventorySummaryRegisterRequest,
  InventoryRegisterJsonTypes,
} from 'utils/http/api/dealer/inventory-summaries/request';
import { initializeRefValue } from 'utils/initialize';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { getRegNumberPattern } from 'utils/validation/regExp';
import useValidation from 'utils/validation/useValidation';

import type { TFunction } from 'i18next';

const getFieldNames = (t: TFunction) => {
  return {
    invoiceNumber: {
      name: t('text:Invoice_Number'),
      width: '',
    },
    itemNumber: {
      name: t('text:Item_Number'),
      width: '',
    },
    itemDescription: {
      name: t('text:Items'),
      width: '300px',
    },
    unitPrice: {
      name: t('text:Unit_Price'),
      width: '',
    },
    quantity: {
      name: t('text:Quantity'),
      width: '100px',
    },
    itemAmount: {
      name: t('text:Amount_(Unit_Price*Quantity)'),
      width: '',
    },
  };
};

function DealerSecuredInventoryRegister() {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();
  const getProperty = useProperty<DealerInventorySummaryRegisterRequest>();
  // 화면 state
  const [financierClientData, setFinancierClientData] = useState<Pageable<FinancierClientVOModel[]>>();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [isDirectInput, setIsDirectInput] = useState<boolean>(true);
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();

  const { register, getValues } = useForm<DealerInventorySummaryRegisterRequest>();

  const useFormSecuredInvoiceList = useForm<InventoryRegisterJsonTypes>({
    mode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control: useFormSecuredInvoiceList.control,
    name: 'inventoryItems',
  });

  const fieldNames = getFieldNames(t);

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: useFormSecuredInvoiceList?.errors?.inventoryItems,
    rule: 'securedInventory',
  });

  const errorsInventoryItems = useFormSecuredInvoiceList?.errors?.inventoryItems;

  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [itemAmount, setItemAmount] = useState<string[]>([]);
  const [totalItemAmount, setTotalItemAmount] = useState<BigNumber>('0');
  const [totalItemQuantity, setTotalItemQuantity] = useState<number>(0);
  const calculatorBigNumber = new CalculatorBigNumber();

  const setItemAmountAndQuantity = React.useCallback(() => {
    const data = useFormSecuredInvoiceList.getValues();

    let eachAmountList = ['0'];
    let eachQuantityList = [0];
    let totalAmount = '0';
    let totalQuantity = 0;

    if (data.inventoryItems) {
      eachAmountList = data.inventoryItems.map(data => {
        if (isEmpty(data.unitPrice) || isNaN(data.unitPrice) || isNaN(data.quantity)) return '';
        else return calculatorBigNumber.add(data.quantity).times(data.unitPrice).get();
      });
      eachQuantityList = data.inventoryItems.map(data => Number(data.quantity));
      totalAmount = eachAmountList.reduce((a: BigNumber, b: BigNumber) => calculatorBigNumber.add(a).add(b).get(), '0');
      totalQuantity = eachQuantityList.reduce((a: number, b: number) => a + b, 0);
    }
    setItemAmount(eachAmountList);
    setTotalItemAmount(totalAmount);
    setTotalItemQuantity(totalQuantity);
  }, [useFormSecuredInvoiceList.getValues, fields.length]);

  useEffect(() => {
    setItemAmountAndQuantity();

    return () => {};
  }, [setItemAmountAndQuantity]);

  useEffect(() => {
    if (mounted) {
      fetchFinancierClient();
      useFormSecuredInvoiceList.reset({
        inventoryItems: [
          {
            invoiceNumber: undefined,
            itemNumber: undefined,
            itemDescription: undefined,
            itemAmount: undefined,
            quantity: undefined,
            unitPrice: undefined,
          },
        ],
      });
    }
  }, [mounted]);

  // data fetch
  async function fetchFinancierClient() {
    try {
      const financierClient = await requestDealerFinancierClients();

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierClientData(financierClient);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const appendRow = (e: any) => {
    e.preventDefault();
    append({
      invoiceNumber: undefined,
      itemNumber: undefined,
      itemDescription: undefined,
      itemAmount: undefined,
      quantity: undefined,
      unitPrice: undefined,
    });
  };

  const removeRow = (e: any) => {
    e.preventDefault();
    if (checkedRows.length === fields.length) {
      useFormSecuredInvoiceList.reset({
        inventoryItems: [
          {
            invoiceNumber: undefined,
            itemNumber: undefined,
            itemDescription: undefined,
            itemAmount: undefined,
            quantity: undefined,
            unitPrice: undefined,
          },
        ],
      });
      setItemAmountAndQuantity();
    } else {
      remove(checkedRows);
    }
    setCheckedRows([]);
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const arr: number[] = [];
      fields.forEach((el, index) => arr.push(index));
      setCheckedRows(arr);
    } else {
      setCheckedRows([]);
    }
  };

  const handleCheckChange = (e: any, index: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const inputNumberValidate = (
    value: string,
    numberType: NumericType,
    numberTextType: 'unit_price' | 'quantity',
  ): string | undefined => {
    const { REG_MAX_INTEGER_LENGTH_PATTERN, REG_DECIMAL_PATTERN, REG_INTEGER_PATTERN } =
      getRegNumberPattern(numberType);

    value = String(value);

    if (isNaN(value as unknown as number) || value.trim() === '')
      return `text:Fail_The_${numberTextType}_must_consist_only_of_numbers`;
    else {
      if (!REG_MAX_INTEGER_LENGTH_PATTERN.test(value))
        return `text:Fail_The_${numberTextType}_exceeds_the_maximum_limit`;
      else {
        switch (numberType) {
          case 'bigNumber':
            return !REG_DECIMAL_PATTERN.test(value)
              ? `text:Fail_The_${numberTextType}_can_have_up_to_4_decimals`
              : undefined;
          default:
            return !REG_INTEGER_PATTERN.test(value)
              ? `text:Fail_The_${numberTextType}_cannot_have_decimals`
              : undefined;
        }
      }
    }
  };

  const totalItemAmountOrQuantityValidator = () => {
    const isTotalItemAmountPattern = getRegNumberPattern('bigNumber').REG_DECIMAL_PATTERN.test(totalItemAmount);
    const isTotalItemQuantityPattern = getRegNumberPattern('float').REG_INTEGER_PATTERN.test(String(totalItemQuantity));

    if (!isTotalItemAmountPattern && !isTotalItemQuantityPattern)
      return modal.show(
        t('text:The_total_amount_and_quantity_of_the_secured_inventory_list_exceeds_the_maximum_limit'),
      );
    else if (!isTotalItemAmountPattern)
      return modal.show(t('text:The_total_amount_of_the_secured_inventory_list_exceeds_the_maximum_limit'));
    else if (!isTotalItemQuantityPattern)
      return modal.show(t('text:The_total_quantity_of_the_secured_inventory_list_exceeds_the_maximum_limit'));
  };

  const showRequestModal = async () => {
    const resultValidationCheck = await useFormSecuredInvoiceList.trigger();

    if (!resultValidationCheck) return modal.show(t('text:Please_check_and_enter_it_again'));
    else {
      // totalItmAmount, totalItmQuantity 패턴 체크
      totalItemAmountOrQuantityValidator();
    }

    const onSubmitRegisterForm = async (): Promise<void> => {
      const calculatorBigNumber = new CalculatorBigNumber();

      const json: InventoryRegisterJsonTypes = {
        financierId: getValues('financierId'),
        attachmentDescription: getValues('attachmentDescription'),
        inventoryItems: useFormSecuredInvoiceList.getValues().inventoryItems?.map(data => {
          return {
            invoiceNumber: String(data.invoiceNumber),
            itemNumber: String(data.itemNumber),
            itemDescription: data.itemDescription,
            itemAmount: calculatorBigNumber.add(data.unitPrice).times(data.quantity).get() as unknown as number,
            quantity: data.quantity,
            unitPrice: data.unitPrice,
          };
        }),
      };

      const requestData: DealerInventorySummaryRegisterRequest = {
        json: new Blob([JSON.stringify(json)], {
          type: 'application/json',
        }),
        attachment: getValues('attachment'),
      };

      try {
        await requestDealerInventoryRegister(requestData);
        showConfirmModal();
      } catch (e) {
        modal.show(e);
      }
    };

    const showConfirmModal = () => {
      modal.show(
        <h6>
          {t('text:Secured_inventory_information_registration_has_been_completed')}
          <br />
          {t('text:Please_check_the_secured_inventory_list')}
        </h6>,
        {
          closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_LIST),
        },
      );
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_register_secured_inventory_information?')}
        <br />
        {t('text:It_cannot_be_modified_after_registration_so_please_make_sure_it_is_entered_correctly')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => {
          onSubmitRegisterForm();
        },
      },
    );
  };

  const showCancelModal = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_secured_inventory_registration?')}

        <br />
        {t('text:Upon_cancellation,_the_entered_information_will_not_be_saved')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_LIST),
      },
    );
  };

  // render Secured Creditor Select Option
  const renderBankSelectOption = () => {
    const bankSelectOptions = financierClientData?.content.map(item => {
      return {
        label: item.financierName,
        value: item.financierId,
      };
    });

    return (
      <Select
        className="information-form__select only-selectbox"
        selectOptions={bankSelectOptions ? bankSelectOptions : []}
        name="financierId"
        ref={register}
        placeholderOptions={{ show: true, text: t('text:Select_Secured_Creditor') }}
      />
    );
  };

  // render file upload form
  const renderFileUploadForm = () => {
    return (
      <div className="file-upload-form">
        <div className="file-upload">
          <div className="d-flex justify-content-between">
            <input
              type="file"
              id="normal-file-upload"
              className="d-none"
              name={getProperty('attachment')}
              ref={register}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target?.files[0]?.name) setFileName(e.target.files[0].name);
              }}
            />
            <label htmlFor="normal-file-upload" className="attach-file-link-button">
              {t('text:Attach_File')}
            </label>
            <div id="fileName" className="upload-file-input w-100">
              {fileName ? fileName : t('text:No_file_attached')}
            </div>
            <input
              type="text"
              ref={register}
              name="attachmentDescription"
              placeholder={t('text:Please_note_here')}
              className="note-input"
            />
          </div>
        </div>
      </div>
    );
  };

  const onClickRegistrationMethodRadioButton = (value: boolean) => {
    if (value !== isDirectInput) {
      setIsDirectInput(value);
    }
  };

  const onChangeUploadExcel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    e.preventDefault();
    const formattedData = (data: any) => {
      return {
        invoiceNumber: data['Invoice Number'],
        itemNumber: data['Item Number'],
        itemDescription: data['Items'],
        itemAmount: data['Total Amount(Unit Price*Quantity)'],
        quantity: data['Quantity'],
        unitPrice: data['Unit Price'],
      };
    };

    if (e.target.files && e.target.files[0]) {
      const isCsvFile = e.target.files[0].type === 'text/csv';
      setFile(e.target.files[0]);

      try {
        showLoadingUI();
        const jsonArrayData = isCsvFile
          ? await csvToJson(e.target.files[0])
          : await excelToJson(e.target.files[0], 1000);

        if (isCsvFile && jsonArrayData && jsonArrayData.length !== 0) {
          const changedData = jsonArrayData.map((data: any) => formattedData(data));

          useFormSecuredInvoiceList.reset({
            inventoryItems: changedData,
          });

          return;
        }

        if (
          !isCsvFile &&
          jsonArrayData &&
          Array.isArray(jsonArrayData) &&
          jsonArrayData[0] &&
          Array.isArray(jsonArrayData[0]) &&
          jsonArrayData[0].length !== 0
        ) {
          const changedData = jsonArrayData[0].map((data: any) => formattedData(data));

          useFormSecuredInvoiceList.reset({
            inventoryItems: changedData,
          });

          return;
        }

        modal.show(
          <h6>
            {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
            <br />
            {t('text:Please_check_and_upload_it_again')}
          </h6>,
        );
      } catch (error) {
        modal.show(error);
        initializeRefValue(fileRef);
      } finally {
        unShowLoadingUI();
      }
    }
  };

  const onClickRemoveExcel = (e: any): void => {
    e.preventDefault();
    initializeRefValue(fileRef);

    if (file) {
      setFile(undefined);
    }
  };

  const checkValidationBlank = (value: string): boolean => {
    return value.trim() !== '';
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Secured_Inventory_Registration')} />

      <GuideMessage
        message={[
          t('text:Select_a_secured_creditor'),
          t('text:Enter_the_inventory_information_and_click_on_the_Register_button_to_complete_the_registration'),
          t('text:The_inventory_list_cannot_be_modified_once_the_registration_is_completed'),
        ]}
      />
      <div className="content-area pb-0">
        <SectionTitle title={t('text:Secured_Creditor')} />
        <div className="information-filter mt-2 w-50">
          <div className="information-form__item__select position-relative">{renderBankSelectOption()}</div>
        </div>
      </div>
      <div className="content-area">
        <form>
          <h3>{t('text:Select_secured_inventory_registration_method')}</h3>
          <div className="invoice-radio-group">
            <RadioButton
              id="direct-input"
              name="registration-method"
              checked={isDirectInput}
              onChange={() => onClickRegistrationMethodRadioButton(true)}
            >
              {t('text:Direct_Input')}
            </RadioButton>
            <RadioButton
              id="file-upload"
              name="registration-method"
              checked={!isDirectInput}
              onChange={() => onClickRegistrationMethodRadioButton(false)}
            >
              {t('text:File_Upload')}
            </RadioButton>
          </div>
          {!isDirectInput && (
            <div className="excel-form">
              <div className="excel-download-form d-flex">
                <label className="me-3">
                  {t(
                    'text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it_After_uploading_you_can_check_the_uploaded_list_below',
                  )}
                </label>
                <ExcelTemplateDownloadButton
                  templateFileAddress="/templates/SecuredInventoryRegistrationTemplate.xlsx"
                  style={{ marginRight: '4px' }}
                />
                <ExcelTemplateDownloadButton
                  downloadAnnounceText={t('text:CSV_Template')}
                  templateFileAddress="/templates/SecuredInventoryRegistrationTemplate.csv"
                />
              </div>
              <div className="detail-in-file-upload-form bg-sub100">
                <div className="d-flex justify-content-between">
                  <input
                    ref={fileRef}
                    onChange={onChangeUploadExcel}
                    type="file"
                    name="file"
                    id="excel-file-upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="excel-file-upload" className="attach-file-link-button bg-sub100">
                    {t('text:Attach_File')}
                  </label>
                  <div id="fileName" className="upload-file-input">
                    {file ? file.name : t('text:No_file_attached')}
                  </div>
                  <IconButton
                    onClick={onClickRemoveExcel}
                    className={`delete-uploaded-excel-button ${!file && 'd-none'}`}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </IconButton>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Secured_Inventory_List')}>
          <div className="flex-end">
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={removeRow}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </SectionTitle>
        <table className="table-border">
          <colgroup>
            <col style={{ width: '70px' }} />
            {values(fieldNames).map(({ width }, index) => (
              <col key={index} style={{ width }} />
            ))}
            {errorsInventoryItems && <col style={{ width: '180px' }} />}
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <div className="text-center">
                  <input
                    className="form-check-input m-0"
                    type="checkbox"
                    id="allCheck2"
                    onChange={handleCheckAll}
                    checked={checkedRows.length === fields.length}
                  />
                </div>
              </th>
              {values(fieldNames).map(({ name }, index) => (
                <th key={index} scope="col">
                  {name}
                </th>
              ))}
              {errorsInventoryItems && <th scope="col">{t('text:Validation_Result')}</th>}
            </tr>
          </thead>
          <tbody className="bg-white">
            {fields.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td className="bg-sub100" key={index}>
                    <div className="text-center">
                      <input
                        className="form-check-input m-0"
                        type="checkbox"
                        onChange={e => handleCheckChange(e, index)}
                        checked={checkedRows.includes(index)}
                      />
                    </div>
                  </td>
                  <td className={getValidationClassName('invoiceNumber', 'td', index)}>
                    <input
                      className={getValidationClassName('invoiceNumber', 'input', index)}
                      name={`inventoryItems.${index}.invoiceNumber`}
                      defaultValue={item.invoiceNumber}
                      ref={useFormSecuredInvoiceList.register({
                        required: true,
                        validate: checkValidationBlank,
                      })}
                    />
                  </td>
                  <td className={getValidationClassName('itemNumber', 'td', index)}>
                    <input
                      className={getValidationClassName('itemNumber', 'input', index)}
                      name={`inventoryItems.${index}.itemNumber`}
                      defaultValue={item.itemNumber}
                      ref={useFormSecuredInvoiceList.register({
                        required: true,
                        validate: checkValidationBlank,
                      })}
                    />
                  </td>
                  <td className={getValidationClassName('itemDescription', 'td', index)}>
                    <input
                      className={getValidationClassName('itemDescription', 'input', index)}
                      name={`inventoryItems.${index}.itemDescription`}
                      defaultValue={item.itemDescription}
                      ref={useFormSecuredInvoiceList.register({
                        required: true,
                        validate: checkValidationBlank,
                      })}
                    />
                  </td>

                  <td className={getValidationClassName('unitPrice', 'td', index)}>
                    <input
                      type="text"
                      className={getValidationClassName('unitPrice', 'input', index)}
                      name={`inventoryItems.${index}.unitPrice`}
                      defaultValue={item.unitPrice}
                      ref={useFormSecuredInvoiceList.register({
                        required: true,
                        min: 0,
                        validate: value => inputNumberValidate(value, 'bigNumber', 'unit_price'),
                      })}
                      onChange={setItemAmountAndQuantity}
                    />
                  </td>

                  <td className={getValidationClassName('quantity', 'td', index)}>
                    <input
                      type="text"
                      className={getValidationClassName('quantity', 'input', index)}
                      name={`inventoryItems.${index}.quantity`}
                      defaultValue={item.quantity}
                      ref={useFormSecuredInvoiceList.register({
                        required: true,
                        min: 0,
                        validate: value => inputNumberValidate(value, 'float', 'quantity'), // 소수점 x, 정수 15자리까지
                      })}
                      onChange={setItemAmountAndQuantity}
                    />
                  </td>
                  <td className={getValidationClassName('itemAmount', 'td', index)}>
                    <input
                      className={getValidationClassName('itemAmount', 'input', index)}
                      name={`inventoryItems.${index}.itemAmount`}
                      value={itemAmount[index] === '0' || itemAmount[index] === undefined ? '' : itemAmount[index]}
                      ref={useFormSecuredInvoiceList.register({
                        required: true,
                        min: 0,
                        validate: value => {
                          const isItemAmountPattern = getRegNumberPattern('bigNumber').REG_DECIMAL_PATTERN.test(value);

                          return !isItemAmountPattern
                            ? 'text:Fail_The_item_amount_exceeds_the_maximum_limit'
                            : undefined;
                        },
                      })}
                      disabled
                    />
                  </td>
                  {errorsInventoryItems && getValidationResult(index)}
                </tr>
              );
            })}
          </tbody>
        </table>
        <table className="table-border border-top-0">
          <tbody className="bg-white">
            <tr>
              <td colSpan={7} className="summary-area text-end">
                {t('text:Total_Quantity')}: {t('format:number', { value: totalItemQuantity })}
                <span className="me-5" />
                {t('text:Total_Amount')}: {t('format:number', { value: totalItemAmount })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Additional_Documents')} />
        {renderFileUploadForm()}
        <div className="flex-end mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={showCancelModal}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={showRequestModal} className="ms-2">
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default DealerSecuredInventoryRegister;
