import type Pageable from 'models/Pageable';
import type { InvoicePaymentVO, InvoicePaymentVOModel } from 'models/vo/InvoicePaymentVO';
import { formattingToInvoicePaymentVOModel } from 'models/vo/InvoicePaymentVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierInvoicePaymentListRequest } from './requests';

export async function requestFinancierInvoicePaymentList(
  pageNumber: number,
  rowCount: number,
  data: FinancierInvoicePaymentListRequest,
): Promise<Pageable<InvoicePaymentVOModel[]>> {
  const response = await http.get<Pageable<InvoicePaymentVO[]>>({
    url: API_FI.INVOICE_PAYMENTS.INVOICE_PAYMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToInvoicePaymentVOModel(data)),
  };
}
