import { useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash-es';

import './BasicTable.scss';

interface PropsType {
  className?: string;
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
  basicTableWrapMaxHeight?: string | number;
  arSettlementAscended?: boolean;
}

function BasicTableBorder({ children, className, basicTableWrapMaxHeight, arSettlementAscended }: PropsType) {
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);
  const [containerClientWidth, setContainerClientWidth] = useState<number>();
  const [containerScrollWidth, setContainerScrollWidth] = useState<number>();

  const setContainerWidths = () => {
    const clientWidth = containerRef.current?.clientWidth;
    const scrollWidth = containerRef.current?.scrollWidth;
    setContainerClientWidth(clientWidth);
    setContainerScrollWidth(scrollWidth);
  };

  const handleResize = debounce(() => setContainerWidths());

  useEffect(() => {
    setContainerWidths();
  }, []);

  useEffect(() => {
    wrapRef.current?.scrollTo(0, 0);
  }, [arSettlementAscended]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const getBasicTableWrapWidth = (): '100%' | number => {
    if (isNaN(Number(containerClientWidth)) || isNaN(Number(containerScrollWidth))) {
      return '100%';
    }

    return Number(containerClientWidth) - Number(containerScrollWidth) >= 0 ? '100%' : Number(containerScrollWidth);
  };

  return (
    <div className="basic-table-container" ref={containerRef}>
      <div
        className="basic-table-wrap"
        style={{ width: getBasicTableWrapWidth(), maxHeight: basicTableWrapMaxHeight }}
        ref={wrapRef}
      >
        <table className={`basic-table` + ` ${className ? className : ''}`}>{children}</table>
      </div>
    </div>
  );
}

export default BasicTableBorder;
