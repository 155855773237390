import type { CURRENCY_TYPE } from 'enums';
import type { FINANCIAL_SETTING_TYPE } from 'enums';
import type { AR_COMMISSION_CREATE_PHASE } from 'enums';

export interface ArCommissionSettingVO {
  arCommissionSettingId: number;
  arCommissionSettingType: FINANCIAL_SETTING_TYPE;
  currencyType: CURRENCY_TYPE;
  activated: boolean;
  clientName: string;
  clientCode: string;
  financierId: number;
  agreementId: number;
  agreementContractNo: string;
  arCommissionCreatePhase: AR_COMMISSION_CREATE_PHASE;
}

export interface ArCommissionSettingVOModel extends ArCommissionSettingVO {}

export function formattingToArCommissionSettingVOModel(data: ArCommissionSettingVO): ArCommissionSettingVOModel {
  return {
    ...data,
  };
}
