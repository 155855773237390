import { isNil } from 'lodash-es';

import type { LOAN_STATUS } from 'enums';
import { localeNumberFormatter } from 'locales/localeNumberFormatter';
import { isLoanDisbursed } from 'utils/status';

import type { TFunctionResult } from 'i18next';

export const tableValueManage = (
  originalValue: string | undefined | null | number | TFunctionResult | boolean,
  formattedValue?: string,
): string => {
  if (
    originalValue === '' ||
    originalValue === undefined ||
    originalValue === null ||
    Number.isNaN(originalValue) ||
    String(originalValue).includes('null') ||
    String(originalValue).includes('undefined')
  ) {
    return '-';
  }

  // (&nbsp - 연속 공백을 표현하기 위함) 공백 대신 &nbsp인 경우 단어 간의 줄바꿈이 제대로 실행되지 않음.
  // 마지막 공백만 문자열 공백 처리
  if (typeof originalValue === 'string' || originalValue instanceof String) {
    const REG_LAST_SPACES = /\s(?!\s)/g;

    originalValue = originalValue.replaceAll(' ', '\u00a0').replace(REG_LAST_SPACES, ' ');
  }

  return formattedValue ? formattedValue : (originalValue as string);
};

export const tableNumberValueIsZero = (value: number | undefined | null): string => {
  if (value === 0 || isNil(value)) {
    return '-';
  }

  return localeNumberFormatter(value);
};

// TODO :: 추후 가능하다면 이어서 개발
// export const requestDTOParser = (data: any, enumPropertyNameList: string[]) => {
//   console.log(data);

//   enumPropertyNameList.map((enumPropertyName) => {
//     console.log(data[enumPropertyName]);
//     if (data[enumPropertyName] === '') {
//       console.log('case occurred');
//       data[enumPropertyName] = null;
//     }
//   });
// };

export const requestDTOParser = (data: any): void => {
  const keyList = Object.keys(data);
  keyList.forEach(key => {
    if (data[key] === '') {
      data[key] = null;
    }
  });
};

// TODO: requestDTOParser에 추가하려 했으나, 기존 로직과 꼬일 우려가 있어 일단은 분리, 추후에 병합
// exampleObj: [{ex1: "", ex2: "0"} ,{ex1: "", ex2: "0"},{ex1: "", ex2: "0"}] 인 경우에 사용
export const requestDTOParserForArrayObj = (data: any) => {
  if (Array.isArray(data) && data.length > 0) {
    data.forEach(function (item: any, index: number) {
      for (const [objKey, value] of Object.entries(item)) {
        if (value === '') {
          data[index][objKey] = null;
        }
      }
    });
  }
};

export const hasValue = (value: any): boolean => {
  return !(value === '' || value === undefined || value === null || value === 'null' || value === 'undefined');
};

export const replaceHyphenWithEmptyValue = (value: string) => {
  return value === '-' ? '' : value;
};

export const getFilteredDisbursedAmount = (loanStatus: LOAN_STATUS, disbursedAmount: number) => {
  return isLoanDisbursed(loanStatus) ? disbursedAmount : '';
};
