export async function clipboardWriteText(text: string): Promise<'success' | undefined> {
  if (navigator.clipboard) {
    // safari 보안정책을 회피하기 위해 setTimeOut
    const promise: Promise<'success'> = new Promise(resolve => {
      setTimeout(async () => {
        try {
          await navigator.clipboard.writeText(text);
          resolve('success');
        } catch (e) {
          alert(e);
        }
      });
    });

    return promise;
  } else {
    if (!document.queryCommandSupported('copy')) {
      alert('Your browser is not supported for clipboard copying.');

      return;
    }

    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    return 'success';
  }
}
