import { createContext, useContext } from 'react';

import type { AnchorFinancingOptionCommonVOModel } from 'models/convertor/anchorFinancingOption';

export type FinancingOptionType = AnchorFinancingOptionCommonVOModel;

type FinancingOptionStateType = {
  anchorFinancingOption: FinancingOptionType;
  isFirstRegisteredWaitingAgreement: boolean;
  updateFinancingOption: (agreement: Partial<FinancingOptionType>) => void;
  updateAllFinancingOption: (agreement: FinancingOptionType) => void;
  fetchWaitingFinancingOption: (
    waitingAnchorFinancingOptionId: number,
  ) => Promise<AnchorFinancingOptionCommonVOModel | undefined>;
  fetchFinancingOptionDetail: (
    anchorFinancingOptionId: number,
  ) => Promise<AnchorFinancingOptionCommonVOModel | undefined>;
};

export const FinancingOptionContext = createContext<FinancingOptionStateType | null>(null);

export const useFinancingOptionContext = () => {
  const context = useContext(FinancingOptionContext);
  if (!context) {
    throw Error('useFinancingOption should be used within FinancingOptionContext.Provider');
  }

  return context;
};
