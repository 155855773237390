import i18n from 'i18next';

import { COLLATERAL_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import type { HttpError } from 'utils/error/HttpError';

export const getBlockedStatusText = (blockedStatus?: boolean): string => {
  return blockedStatus === true ? i18n.t('text:Ineligible') : blockedStatus === false ? i18n.t('text:Eligible') : '-';
};

export const getPaymentSupportText = (paymentSupport?: boolean): string => {
  return paymentSupport === true
    ? `Y (${i18n.t('text:Cooperation_&_Payment')})`
    : paymentSupport === false
    ? `N (${i18n.t('text:Cooperation')})`
    : '-';
};

export const getProgramTypeText = (collateralType?: COLLATERAL_TYPE): string => {
  switch (collateralType) {
    case COLLATERAL_TYPE.AR:
      return i18n.t('text:Vendor_Finance');
    case COLLATERAL_TYPE.INVOICE:
      return i18n.t('text:Dealer_Finance');
    default:
      return '';
  }
};

export const getApprovalTypeText = (approvalType?: COMMON_APPROVAL_TYPE): string => {
  switch (approvalType) {
    case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
      return i18n.t('text:Requested');
    case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
      return i18n.t('text:Request_Reverted');
    case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
      return i18n.t('text:Request_Cancelled');
    case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
      return i18n.t('text:Registered');
    default:
      return '';
  }
};

export const getApprovalTypeTimeText = (approvalType?: COMMON_APPROVAL_TYPE): string => {
  switch (approvalType) {
    case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
      return i18n.t('text:Requested_Time');
    case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
      return i18n.t('text:Request_Reverted_Time');
    case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
      return i18n.t('text:Request_Cancelled_Time');
    case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
      return i18n.t('text:Registered_Time');
    default:
      return '';
  }
};

export const getHttpErrorExceptionMessage = (httpErrorObj: HttpError) => {
  const isDisplayExceptionUsingMessage = ['FIF-029'];

  if (isDisplayExceptionUsingMessage.includes(httpErrorObj.code)) {
    return httpErrorObj.message
      ? `${i18n.t(`exception:${httpErrorObj.code}`)} (${httpErrorObj.message.split(':')[1].trim()})`
      : i18n.t(`exception:${httpErrorObj.code}`);
  } else {
    return i18n.t(`exception:${httpErrorObj.code}`);
  }
};
