import type { NewObject } from 'types';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';

export const getSum = (list: NewObject[], property: string): BigNumber => {
  const calculatorBigNumber = new CalculatorBigNumber();

  let sum: BigNumber = '0';

  if (list) {
    list.forEach(item => {
      if (item[property]) {
        sum = calculatorBigNumber.add(sum).add(item[property]).get();
      }
    });
  }

  return sum;
};
