import type Pageable from 'models/Pageable';
import type { AnchorReportVO, AnchorReportVOModel } from 'models/vo/AnchorReportVO';
import { formattingToAnchorReportVOModel } from 'models/vo/AnchorReportVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { AnchorReportListRequest, DealerReportListRequest } from './requests';

export async function requestAnchorReportList<T extends AnchorReportListRequest | DealerReportListRequest>(
  pageNumber: number,
  rowCount: number,
  data: T,
): Promise<Pageable<AnchorReportVOModel[]>> {
  const response = await http.get<Pageable<AnchorReportVO[]>>({
    url: API_FI.ANCHOR_REPORTS.ANCHOR_REPORT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorReportVOModel(data)),
  };
}

export async function requestAnchorReportDownload(anchorReportId: number): Promise<void> {
  await http.download(API_FI.ANCHOR_REPORTS.DOWNLOAD_ANCHOR_REPORT(anchorReportId));

  return;
}
