import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';

import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { PotentialPartnerFinancingApplicationVOModel } from 'models/vo/PotentialPartnerFinancingApplicationVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierPotentialPartnerFinancingApplicationList } from 'utils/http/api/financier/potential-partner-financing-applications';
import type { FinancierPotentialPartnerFinancingApplicationListRequest } from 'utils/http/api/financier/potential-partner-financing-applications/request';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

interface FinancierScPartnerAcquisitionListPageStateType {
  submittedListResult: Pageable<PotentialPartnerFinancingApplicationVOModel[]>;
  inProgressListResult: Pageable<PotentialPartnerFinancingApplicationVOModel[]>;
  rejectedListResult: Pageable<PotentialPartnerFinancingApplicationVOModel[]>;
  completedListResult: Pageable<PotentialPartnerFinancingApplicationVOModel[]>;
}

const SUBMITTED_LIST_QS_KEY = 'submitted-list';
const IN_PROGRESS_LIST_QS_KEY = 'inProgress-list';
const REJECTED_LIST_QS_KEY = 'rejected-list';
const COMPLETED_LIST_QS_KEY = 'completed-list';

export function useFinancierScPartnerAcquisitionListPageState() {
  const modal = useModal();

  // pageable..
  const { pageable: submittedListPageable, setPageable: setSubmittedListPageable } = usePageable(SUBMITTED_LIST_QS_KEY);
  const { pageable: inProgressListPageable, setPageable: setInProgressListPageable } =
    usePageable(IN_PROGRESS_LIST_QS_KEY);
  const { pageable: rejectedListPageable, setPageable: setRejectedListPageable } = usePageable(REJECTED_LIST_QS_KEY);
  const { pageable: completedListPageable, setPageable: setCompletedListPageable } = usePageable(COMPLETED_LIST_QS_KEY);

  // useForm
  const submittedListSearchForm = useForm<FinancierPotentialPartnerFinancingApplicationListRequest>();
  const inProgressListSearchForm = useForm<FinancierPotentialPartnerFinancingApplicationListRequest>();
  const rejectedListSearchForm = useForm<FinancierPotentialPartnerFinancingApplicationListRequest>();
  const completedListSearchForm = useForm<FinancierPotentialPartnerFinancingApplicationListRequest>();

  const [dataState, setDataState] = useState<FinancierScPartnerAcquisitionListPageStateType>({
    submittedListResult: {} as Pageable<PotentialPartnerFinancingApplicationVOModel[]>,
    inProgressListResult: {} as Pageable<PotentialPartnerFinancingApplicationVOModel[]>,
    rejectedListResult: {} as Pageable<PotentialPartnerFinancingApplicationVOModel[]>,
    completedListResult: {} as Pageable<PotentialPartnerFinancingApplicationVOModel[]>,
  });

  // due to default status value
  const settingSearchValue = () => {
    const inProgressedSearchListData = inProgressListSearchForm.getValues();
    inProgressedSearchListData.potentialPartnerFinancingApplicationStatus =
      POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS;

    const rejectedSearchListData = rejectedListSearchForm.getValues();
    rejectedSearchListData.potentialPartnerFinancingApplicationStatus =
      POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED;

    const completedSearchListData = completedListSearchForm.getValues();
    completedSearchListData.potentialPartnerFinancingApplicationStatus =
      POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED;

    return {
      inProgressedSearchListData,
      rejectedSearchListData,
      completedSearchListData,
    };
  };

  const fetchAll = async (): Promise<void> => {
    setFormValues<FinancierPotentialPartnerFinancingApplicationListRequest>(
      submittedListSearchForm.setValue,
      getParsedSearchParams(SUBMITTED_LIST_QS_KEY).formSearchData,
    );
    setFormValues<FinancierPotentialPartnerFinancingApplicationListRequest>(
      inProgressListSearchForm.setValue,
      getParsedSearchParams(IN_PROGRESS_LIST_QS_KEY).formSearchData,
    );
    setFormValues<FinancierPotentialPartnerFinancingApplicationListRequest>(
      rejectedListSearchForm.setValue,
      getParsedSearchParams(REJECTED_LIST_QS_KEY).formSearchData,
    );
    setFormValues<FinancierPotentialPartnerFinancingApplicationListRequest>(
      completedListSearchForm.setValue,
      getParsedSearchParams(COMPLETED_LIST_QS_KEY).formSearchData,
    );

    const { inProgressedSearchListData, rejectedSearchListData, completedSearchListData } = settingSearchValue();

    const submittedQs = makeSearchParamsPattern(
      {
        ...submittedListSearchForm.getValues(),
        pageNumber: submittedListPageable.currentPage,
        rowCount: submittedListPageable.sizePerPage,
      },
      SUBMITTED_LIST_QS_KEY,
    );
    const inProgressQs = makeSearchParamsPattern(
      {
        ...inProgressedSearchListData,
        pageNumber: inProgressListPageable.currentPage,
        rowCount: inProgressListPageable.sizePerPage,
      },
      IN_PROGRESS_LIST_QS_KEY,
    );
    const rejectedQs = makeSearchParamsPattern(
      {
        ...rejectedSearchListData,
        pageNumber: rejectedListPageable.currentPage,
        rowCount: rejectedListPageable.sizePerPage,
      },
      REJECTED_LIST_QS_KEY,
    );
    const completedQs = makeSearchParamsPattern(
      {
        ...completedSearchListData,
        pageNumber: completedListPageable.currentPage,
        rowCount: completedListPageable.sizePerPage,
      },
      COMPLETED_LIST_QS_KEY,
    );

    addSearchParams(submittedQs + inProgressQs + rejectedQs + completedQs);

    try {
      const [submittedList, inProgressList, rejectedList, completedList] = await Promise.all([
        requestFinancierPotentialPartnerFinancingApplicationList(
          submittedListPageable.currentPage,
          submittedListPageable.sizePerPage,
          submittedListSearchForm.getValues(),
        ),
        requestFinancierPotentialPartnerFinancingApplicationList(
          inProgressListPageable.currentPage,
          inProgressListPageable.sizePerPage,
          inProgressedSearchListData,
        ),
        requestFinancierPotentialPartnerFinancingApplicationList(
          rejectedListPageable.currentPage,
          rejectedListPageable.sizePerPage,
          rejectedSearchListData,
        ),
        requestFinancierPotentialPartnerFinancingApplicationList(
          completedListPageable.currentPage,
          completedListPageable.sizePerPage,
          completedSearchListData,
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setDataState(prevState => ({
          ...prevState,
          submittedListResult: submittedList,
          inProgressListResult: inProgressList,
          rejectedListResult: rejectedList,
          completedListResult: completedList,
        }));

        setSubmittedListPageable(submittedList);
        setInProgressListPageable(inProgressList);
        setRejectedListPageable(rejectedList);
        setCompletedListPageable(completedList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const submittedListFetchUtils = () => {
    const fetchSubmittedList = async (
      pageNumber: number,
      rowCount: number,
      data: FinancierPotentialPartnerFinancingApplicationListRequest,
    ) => {
      try {
        const submittedList = await requestFinancierPotentialPartnerFinancingApplicationList(
          pageNumber,
          rowCount,
          data,
        );

        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          SUBMITTED_LIST_QS_KEY,
        );
        ReactDOM.unstable_batchedUpdates(() => {
          setDataState(prevState => ({
            ...prevState,
            submittedListResult: submittedList,
          }));

          setSubmittedListPageable(submittedList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchSubmittedList = async () => {
      await fetchSubmittedList(0, submittedListPageable.sizePerPage, submittedListSearchForm.getValues());
    };

    const paginateSubmittedList = async (pageNumber: number, rowCount: number) => {
      await fetchSubmittedList(pageNumber, rowCount, getParsedSearchParams(SUBMITTED_LIST_QS_KEY).formSearchData);
    };

    return {
      onClickSearch: onClickSearchSubmittedList,
      paginate: paginateSubmittedList,
    };
  };

  const inProgressListFetchUtils = () => {
    const fetchInProgressList = async (
      pageNumber: number,
      rowCount: number,
      data: FinancierPotentialPartnerFinancingApplicationListRequest,
    ) => {
      try {
        const inProgressList = await requestFinancierPotentialPartnerFinancingApplicationList(
          pageNumber,
          rowCount,
          data,
        );
        data.potentialPartnerFinancingApplicationStatus = POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS;
        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          IN_PROGRESS_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setDataState(prevState => ({
            ...prevState,
            inProgressListResult: inProgressList,
          }));

          setInProgressListPageable(inProgressList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchInProgressList = async () => {
      const { inProgressedSearchListData } = settingSearchValue();
      await fetchInProgressList(0, inProgressListPageable.sizePerPage, inProgressedSearchListData);
    };

    const paginateInProgressList = async (pageNumber: number, rowCount: number) => {
      await fetchInProgressList(pageNumber, rowCount, getParsedSearchParams(IN_PROGRESS_LIST_QS_KEY).formSearchData);
    };

    return {
      onClickSearch: onClickSearchInProgressList,
      paginate: paginateInProgressList,
    };
  };

  const rejectedListFetchUtils = () => {
    const fetchRejectedList = async (
      pageNumber: number,
      rowCount: number,
      data: FinancierPotentialPartnerFinancingApplicationListRequest,
    ) => {
      try {
        const rejectedList = await requestFinancierPotentialPartnerFinancingApplicationList(pageNumber, rowCount, data);
        data.potentialPartnerFinancingApplicationStatus = POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED;
        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          REJECTED_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setDataState(prevState => ({
            ...prevState,
            rejectedListResult: rejectedList,
          }));

          setRejectedListPageable(rejectedList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchRejectedList = async () => {
      const { rejectedSearchListData } = settingSearchValue();
      await fetchRejectedList(0, rejectedListPageable.sizePerPage, rejectedSearchListData);
    };

    const paginateRejectedList = async (pageNumber: number, rowCount: number) => {
      await fetchRejectedList(pageNumber, rowCount, getParsedSearchParams(REJECTED_LIST_QS_KEY).formSearchData);
    };

    return {
      onClickSearch: onClickSearchRejectedList,
      paginate: paginateRejectedList,
    };
  };

  const completedListFetchUtils = () => {
    const fetchCompletedList = async (
      pageNumber: number,
      rowCount: number,
      data: FinancierPotentialPartnerFinancingApplicationListRequest,
    ) => {
      try {
        const completedList = await requestFinancierPotentialPartnerFinancingApplicationList(
          pageNumber,
          rowCount,
          data,
        );
        data.potentialPartnerFinancingApplicationStatus = POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED;
        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          COMPLETED_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setDataState(prevState => ({
            ...prevState,
            completedListResult: completedList,
          }));

          setCompletedListPageable(completedList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchCompletedList = async () => {
      const { completedSearchListData } = settingSearchValue();
      await fetchCompletedList(0, completedListPageable.sizePerPage, completedSearchListData);
    };

    const paginateCompletedList = async (pageNumber: number, rowCount: number) => {
      await fetchCompletedList(pageNumber, rowCount, getParsedSearchParams(COMPLETED_LIST_QS_KEY).formSearchData);
    };

    return {
      onClickSearch: onClickSearchCompletedList,
      paginate: paginateCompletedList,
    };
  };

  return {
    fetchAll,
    state: dataState,
    useForms: {
      submittedListSearchForm,
      inProgressListSearchForm,
      rejectedListSearchForm,
      completedListSearchForm,
    },
    pageableObjects: {
      submittedListPageable,
      inProgressListPageable,
      rejectedListPageable,
      completedListPageable,
    },
    fetchUtils: {
      submittedListFetchUtils,
      inProgressListFetchUtils,
      rejectedListFetchUtils,
      completedListFetchUtils,
    },
  };
}
