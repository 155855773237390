import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckPluginValid } from 'digital-signatures/bkav/CheckPluginValid';
import { CheckPluginGetCert } from 'digital-signatures/utils/checkPluginGetCert';

import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { AUTHORITY_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { SignInModel } from 'models/SignInModel';
import type { LoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type { MultipleLoanRequestDetailVOModel } from 'models/vo/MultipleLoanRequestDetailVO';
import type { MultipleLoanRequestRelatedLoanVOModel } from 'models/vo/MultipleLoanRequestRelatedLoanVO';
import type { MultipleLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleLoanRequestRelatedSuccessArVO';
import {
  requestDealerMultipleLoanAdditionalDocumentAttachments,
  requestDealerMultipleRequestLoanApprove,
  requestDealerMultipleRequestLoanArsList,
  requestDealerMultipleRequestLoanCancel,
  requestDealerMultipleRequestLoanDetail,
  requestDealerMultipleRequestLoanDownloadAttachmentForEsign,
  requestDealerMultipleRequestLoanRelatedLoanList,
} from 'utils/http/api/dealer/multiple-request-loans';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';
import { getSignIn } from 'utils/storage/LocalStorage';

const UseDealerBulkFinancingConfirmationDetailController = (multipleRequestLoansId: number) => {
  const mounted = useMounted();
  const modal = useModal();
  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation();

  const [dataState, setDataState] = useState({
    bulkFinancingDetail: {} as MultipleLoanRequestDetailVOModel,
    bulkFinancingArList: [] as MultipleLoanRequestRelatedSuccessArVOModel[],
    bulkFinancingRelatedFinancingList: [] as MultipleLoanRequestRelatedLoanVOModel[],
    requiredAdditionalDocuments: [] as LoanAdditionalDocumentAttachmentVOModel[],
  });

  const [financingInfoCheck, setFinancingInfoCheck] = useState<boolean>(false);
  const [bankAccountInfoCheck, setBankAccountInfoCheck] = useState<boolean>(false);
  const [checkedRequiredDocumentsIds, setCheckedRequiredDocumentsIds] = useState<number[]>([]);

  const { pageable: relatedFinancingPageable, setPageable: setRelatedFinancingPageablePageable } = usePageable();

  const isAuthorizerRequest =
    signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER &&
    dataState.bulkFinancingDetail.multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.CREATED;

  const isOperatorRequest =
    signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR &&
    dataState.bulkFinancingDetail.multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.CREATED;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [
        fetchedDealerBulkFinancingDetail,
        fetchedDealerBulkFinancingArList,
        fetchedDealerBulkFinancingRelatedFinancingList,
        fetchAdditionalDocumentAttachments,
      ] = await Promise.all([
        requestDealerMultipleRequestLoanDetail(multipleRequestLoansId),
        requestDealerMultipleRequestLoanArsList(multipleRequestLoansId),
        requestDealerMultipleRequestLoanRelatedLoanList(
          multipleRequestLoansId,
          relatedFinancingPageable.currentPage,
          relatedFinancingPageable.sizePerPage,
        ),
        requestDealerMultipleLoanAdditionalDocumentAttachments(multipleRequestLoansId, 0, 1000),
      ]);
      setDataState(prevState => ({
        ...prevState,
        bulkFinancingDetail: fetchedDealerBulkFinancingDetail,
        bulkFinancingArList: fetchedDealerBulkFinancingArList.content,
        bulkFinancingRelatedFinancingList: fetchedDealerBulkFinancingRelatedFinancingList.content,
        requiredAdditionalDocuments: fetchAdditionalDocumentAttachments.content,
      }));
      setRelatedFinancingPageablePageable(fetchedDealerBulkFinancingRelatedFinancingList);
    } catch (e) {
      modal.show(e);
    }
  };

  const paginateRelatedFinancingList = async (pageNumber: number, rowCount: number) => {
    try {
      const fetchedDealerBulkFinancingRelatedFinancingList = await requestDealerMultipleRequestLoanRelatedLoanList(
        multipleRequestLoansId,
        pageNumber,
        rowCount,
      );
      setDataState(prevState => ({
        ...prevState,
        bulkFinancingRelatedFinancingList: fetchedDealerBulkFinancingRelatedFinancingList.content,
      }));
      setRelatedFinancingPageablePageable(fetchedDealerBulkFinancingRelatedFinancingList);
    } catch (e) {
      modal.show(e);
    }
  };

  const submitBulkFinancingCancel = async (cancelReason: string) => {
    const showLoanCancelConfirmModal = () => {
      modal.show(<h6>{t('text:Financing_approval_request_is_successfully_cancelled')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    };

    try {
      await requestDealerMultipleRequestLoanCancel(multipleRequestLoansId, cancelReason);
      showLoanCancelConfirmModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const handleCancelButtonClick = () => {
    let reason = '';

    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_financing_application?')}
        <br />
        {t(
          'text:If_you_cancel_the_financing_application_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
        )}
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Cancel_Financing_Request'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          await submitBulkFinancingCancel(reason);
        },
      },
    );
  };

  const showAuthorizerApproveConfirmModal = () => {
    modal.show(
      <h6>
        {t('text:The_financing_confirmation_has_been_completed')}
        <br />
        {t(
          'text:You_can_monitor_the_details_and_progress_of_the_financing_applied_from_the_View_Transaction_Financing_menu',
        )}
      </h6>,
      {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      },
    );
  };

  const submitDigitalSignatureFinancing = async () => {
    const { dealerAgreementId } = dataState.bulkFinancingDetail;
    showLoadingUI();

    try {
      const factoringDocs = await requestDealerMultipleRequestLoanDownloadAttachmentForEsign(multipleRequestLoansId);

      CheckPluginValid.CheckPluginValid((data: any) =>
        CheckPluginGetCert({
          data,
          modal,
          PDFInfos: factoringDocs,
          pdfSignCompletedCb: async (loanAgreementFileList: FileList, loanFactoringNotificationFileList: FileList) => {
            try {
              await requestDealerMultipleRequestLoanApprove(multipleRequestLoansId, {
                dealerAgreementId,
                agreementAttachment: loanAgreementFileList,
                factoringNotificationAttachment: loanFactoringNotificationFileList,
              });
              showAuthorizerApproveConfirmModal();
            } catch (e) {
              modal.show(e);
            }
          },
        }),
      );
    } catch (e) {
      unShowLoadingUI();
      modal.show(e);
    }

    return;
  };

  const hardCopyConditionCheckboxValidator = () => {
    const { requiredAdditionalDocuments } = dataState;
    if (requiredAdditionalDocuments.length < 1) {
      return true;
    }

    const requiredCheckboxes = requiredAdditionalDocuments.filter(document => document.hardCopyCondition.required);

    return requiredCheckboxes.length === checkedRequiredDocumentsIds.length;
  };

  const handleSubmitFinancingApplicationButtonClick = async () => {
    if (!financingInfoCheck || !bankAccountInfoCheck || !hardCopyConditionCheckboxValidator()) {
      return modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
      });
    }

    await submitDigitalSignatureFinancing();
  };

  const handleRequiredAdditionalDocumentCheckboxChange = (id: number) => {
    setCheckedRequiredDocumentsIds(prevCheckedIds =>
      prevCheckedIds.includes(id) ? prevCheckedIds.filter(itemId => itemId !== id) : [...prevCheckedIds, id],
    );
  };

  return {
    state: dataState,
    paginateRelatedFinancingList,
    isAuthorizerRequest,
    isOperatorRequest,
    relatedFinancingPageable,
    financingInfoCheckBoxState: {
      financingInfoCheck,
      setFinancingInfoCheck,
    },
    bankAccountInfoCheckBoxState: {
      bankAccountInfoCheck,
      setBankAccountInfoCheck,
    },
    handleCancelButtonClick,
    handleSubmitFinancingApplicationButtonClick,
    checkedRequiredDocumentsIds,
    handleRequiredAdditionalDocumentCheckboxChange,
  };
};

export default UseDealerBulkFinancingConfirmationDetailController;
