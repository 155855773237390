import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { InvoicePaymentVOModel } from 'models/vo/InvoicePaymentVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierInvoicePaymentList } from 'utils/http/api/financier/invoice-payments';
import type { FinancierInvoicePaymentListRequest } from 'utils/http/api/financier/invoice-payments/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const SEARCHED_FINANCIER_INVOICE_PAYMENT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:BOE_Number'),
    },
    {
      headerText: t('text:Payment_Date'),
    },
    {
      headerText: t('text:Number_of_Invoices'),
    },
    {
      headerText: t('text:Payment_Amount'),
    },
  ];

  const FI_INVOICE_PAYMENT_LIST_QS_KEY = 'fi-invoice-payment-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_FINANCIER_INVOICE_PAYMENT_LIST_TABLE_HEADERS,
    FI_INVOICE_PAYMENT_LIST_QS_KEY,
  };
};

function FinancierInvoicePaymentList() {
  const { mounted, modal, t, SEARCHED_FINANCIER_INVOICE_PAYMENT_LIST_TABLE_HEADERS, FI_INVOICE_PAYMENT_LIST_QS_KEY } =
    getConstants();

  const {
    register: financierInvoicePaymentSearchFormRegister,
    getValues: getFinancierInvoicePaymentSearchFormValues,
    reset: resetFinancierInvoicePaymentSearchFormValue,
    setValue: setFinancierInvoicePaymentSearchFormValue,
    control: financierInvoicePaymentSearchFormControl,
  } = useForm<FinancierInvoicePaymentListRequest>();

  const getProperty = useProperty<FinancierInvoicePaymentListRequest>();

  const [invoicePaymentPage, setInvoicePaymentPage] = useState<Pageable<InvoicePaymentVOModel[]>>();

  const { pageable: financierInvoicePaymentPageable, setPageable: setFinancierInvoicePaymentPageable } =
    usePageable(FI_INVOICE_PAYMENT_LIST_QS_KEY);

  const location = useLocation<FinancierInvoicePaymentListRequest>();
  const filteredOptions = location.state;

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierInvoicePaymentListRequest>(
        setFinancierInvoicePaymentSearchFormValue,
        filteredOptions ?? getParsedSearchParams(FI_INVOICE_PAYMENT_LIST_QS_KEY).formSearchData,
      );
      fetchFiInvoicePaymentList();
    }
  }, [mounted]);

  const fetchFiInvoicePaymentList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    searchData: FinancierInvoicePaymentListRequest = {},
  ) => {
    try {
      const invoicePaymentPage = await requestFinancierInvoicePaymentList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        FI_INVOICE_PAYMENT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setInvoicePaymentPage(invoicePaymentPage);
        setFinancierInvoicePaymentPageable(invoicePaymentPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    resetFinancierInvoicePaymentSearchFormValue();
  };

  const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    await fetchFiInvoicePaymentList(
      1,
      financierInvoicePaymentPageable.sizePerPage,
      getFinancierInvoicePaymentSearchFormValues(),
    );
  };

  const financierInvoicePaymentPaginate = async (pageNumber: number, rowCount: number) => {
    await fetchFiInvoicePaymentList(
      pageNumber,
      rowCount,
      getParsedSearchParams(FI_INVOICE_PAYMENT_LIST_QS_KEY).formSearchData,
    );
  };

  const renderSearchedFinancierInvoicePaymentTableBody = () => {
    return invoicePaymentPage?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.anchorClientName} />
        <Td data={data.anchorAgreementContractNo} />
        <Td data={data.partnerClientName} />
        <Td data={data.partnerAgreementContractNo} />
        <Td className="text-center" data={data.currencyType} />
        <Td data={data.billOfExchangeNo} />
        <Td format="date" data={data.settlementDate} />
        <Td format="number" data={data.totalCount} />
        <Td format="number" data={data.totalAmount} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Invoice_Payment')} />
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name={getProperty('anchorClientName')} ref={financierInvoicePaymentSearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput
                name={getProperty('anchorAgreementContractNo')}
                ref={financierInvoicePaymentSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name={getProperty('partnerClientName')} ref={financierInvoicePaymentSearchFormRegister} />
              <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
              <SearchInput
                name={getProperty('partnerAgreementContractNo')}
                ref={financierInvoicePaymentSearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name={getProperty('currencyType')}
                ref={financierInvoicePaymentSearchFormRegister}
                selectOptions={getSelectOptions('CURRENCY_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:BOE_Number')} />
              <SearchInput name={getProperty('billOfExchangeNo')} ref={financierInvoicePaymentSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Payment_Date')} />
              <SearchDatePicker
                name={getProperty('paymentDateFrom')}
                placeholder={t('text:from')}
                control={financierInvoicePaymentSearchFormControl}
              />
              <SearchDatePicker
                name={getProperty('paymentDateTo')}
                placeholder={t('text:to')}
                control={financierInvoicePaymentSearchFormControl}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area ">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {financierInvoicePaymentPageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_FINANCIER_INVOICE_PAYMENT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_FINANCIER_INVOICE_PAYMENT_LIST_TABLE_HEADERS.length}>
            {renderSearchedFinancierInvoicePaymentTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={financierInvoicePaymentPageable} paginate={financierInvoicePaymentPaginate} />
      </div>
    </>
  );
}

export default FinancierInvoicePaymentList;
