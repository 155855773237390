import type Pageable from 'models/Pageable';
import type { DashBoardApprovalVO, DashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import { formattingToDashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import type { DashBoardScheduleVO, DashBoardScheduleVOModel } from 'models/vo/DashBoardScheduleVO';
import { formattingToDashBoardScheduleVOModel } from 'models/vo/DashBoardScheduleVO';
import type {
  DashboardFinancierPerformanceVO,
  DashboardFinancierPerformanceVOModel,
} from 'models/vo/DashboardFinancierPerformanceVO';
import { formattingToDashboardFinancierPerformanceVOModel } from 'models/vo/DashboardFinancierPerformanceVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FiDashboardPerformanceListRequest, FiDashboardScheduleListRequest } from './request';

export const requestFinancierClientRequest = async (
  pageNumber: number,
  rowCount: number,
  branchId?: number,
): Promise<Pageable<DashBoardApprovalVOModel[]>> => {
  const response = await http.get<Pageable<DashBoardApprovalVO[]>>({
    url: API_FI.DASHBOARD.CLIENT_REQUEST,
    data: {
      pageNumber,
      rowCount,
      branchId,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashBoardApprovalVOModel(item)),
  };
};

export const requestFinancierInternalApproval = async (
  pageNumber: number,
  rowCount: number,
  branchId?: number,
): Promise<Pageable<DashBoardApprovalVOModel[]>> => {
  const response = await http.get<Pageable<DashBoardApprovalVO[]>>({
    url: API_FI.DASHBOARD.INTERNAL_APPROVAL,
    data: {
      pageNumber,
      rowCount,
      branchId,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashBoardApprovalVOModel(item)),
  };
};

export const requestFinancierTaskSchedule = async (
  pageNumber: number,
  rowCount: number,
  requestValue: FiDashboardScheduleListRequest,
): Promise<Pageable<DashBoardScheduleVOModel[]>> => {
  const response = await http.get<Pageable<DashBoardScheduleVO[]>>({
    url: API_FI.DASHBOARD.TASK_SCHEDULE,
    data: {
      pageNumber,
      rowCount,
      ...requestValue,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashBoardScheduleVOModel(item)),
  };
};

export const requestFinancierPerformance = async (
  pageNumber: number,
  rowCount: number,
  requestValue: FiDashboardPerformanceListRequest,
): Promise<Pageable<DashboardFinancierPerformanceVOModel[]>> => {
  const response = await http.get<Pageable<DashboardFinancierPerformanceVO[]>>({
    url: API_FI.DASHBOARD.PERFORMANCE,
    data: {
      pageNumber,
      rowCount,
      ...requestValue,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashboardFinancierPerformanceVOModel(item)),
  };
};
