import type { HTMLAttributes, MouseEvent, ReactNode } from 'react';
import { createContext, useContext } from 'react';

const RadioContext = createContext<RadioState | null>(null);
RadioContext.displayName = 'RadioContext';

const useRadioContext = () => {
  const context = useContext(RadioContext);

  if (!context) {
    throw new Error('useRadioContext should be used within RadioContext.Provider');
  }

  return context;
};

type RadioState = {
  name: string;
  selectedValue?: string;
  onClick: (event: MouseEvent<HTMLInputElement>) => void;
};

interface RadioWrapperPropsType extends RadioState {
  children: ReactNode;
}

const RadioWrapper = ({ name, selectedValue, onClick, children }: RadioWrapperPropsType) => {
  return (
    <RadioContext.Provider value={{ name, selectedValue, onClick }}>
      <div className="dev-tool__radio-group">{children}</div>
    </RadioContext.Provider>
  );
};

interface RadioPropsType extends HTMLAttributes<HTMLInputElement> {
  value: string;
}

const RadioItem = ({ id, children, value, ...restProps }: RadioPropsType) => {
  const { selectedValue, ...restState } = useRadioContext();

  return (
    <div className="dev-tool__radio-wrapper">
      <input
        id={id}
        className="dev-tool__radio-input"
        type="radio"
        defaultChecked={selectedValue === value}
        value={value}
        {...restState}
        {...restProps}
      />
      <label htmlFor={id} className="dev-tool__radio-label">
        {children}
      </label>
    </div>
  );
};

const Radio = Object.assign(RadioItem, {
  Wrapper: RadioWrapper,
});

export default Radio;
