import { createContext, useContext } from 'react';

import type { AnchorAgreementCommonVOModel } from 'models/convertor/anchorAgreement';

export type AnchorClientInfoType = Pick<
  AnchorAgreementCommonVOModel,
  | 'anchorClientId'
  | 'anchorClientCode'
  | 'anchorClientName'
  | 'anchorUserList'
  | 'anchorClientAutoSignUpForAnchorAdminOfVendorFinance'
>;

type AnchorClientInfoStateType = {
  anchorClientInfo: AnchorClientInfoType;
  updateAnchorClientInfo: (anchorClientInfo: Partial<AnchorClientInfoType>) => void;
};

export const AnchorClientInfoContext = createContext<AnchorClientInfoStateType | null>(null);

export const useAnchorClientInfoContext = () => {
  const context = useContext(AnchorClientInfoContext);
  if (!context) {
    throw Error('useAnchorClientInfoContext should be used within AnchorClientInfoContext.Provider');
  }

  return context;
};
