import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { Content, Row, SubTitle } from 'components/templates/section';
import { COLLATERAL_TYPE } from 'enums';
import { useUppercaseInput } from 'hooks/useUppercaseInput';

import useExtraInformationViewModel from '../../models/extraInformation/useExtraInformationViewModel';

const RepaymentAccountPrincipal = () => {
  const { t } = useTranslation();
  const { supportedCollateralType } = useExtraInformationViewModel();

  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;
  const handleUppercaseInput = useUppercaseInput();

  return (
    <>
      <SubTitle>{t('text:REPAYMENT_ACCOUNT')}</SubTitle>
      <Content>
        <Row singleColumnPosition="top">
          <SectionMessage
            messageType="description"
            message={[t('text:The_financing_principal_amount_will_be_collected_from_this_bank_account_for_repayment')]}
          />
          <Form.Control name="principalRepaymentAccountFinancierName" required={isAr}>
            <Form.Label>{t('text:Bank_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
          <Form.Control name="principalRepaymentAccountBranchName" required={isAr}>
            <Form.Label>{t('text:Branch_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
          <Form.Control name="principalRepaymentAccount" required={isAr}>
            <Form.Label>{t('text:Bank_Account_Number')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
          <Form.Control name="principalRepaymentAccountOwner" required={isAr}>
            <Form.Label>{t('text:Bank_Account_Holder')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default RepaymentAccountPrincipal;
