import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestDealerAnchorAgreementListForArs } from 'utils/http/api/dealer/anchor-agreements';
import type { DealerAnchorAgreementListForArsRequest } from 'utils/http/api/dealer/anchor-agreements/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const DE_AP_REGISTRATION_STEP1_LIST_QS_KEY = 'de-register-ap-step1-list';

function DealerArRegisterStep1() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(DE_AP_REGISTRATION_STEP1_LIST_QS_KEY);
  const modal = useModal();

  const [acAgreementList, setAcAgreementList] = useState<Pageable<AnchorAgreementVOModel[]>>();

  const { register, getValues, reset, setValue } = useForm<DealerAnchorAgreementListForArsRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<DealerAnchorAgreementListForArsRequest>(
        setValue,
        getParsedSearchParams(DE_AP_REGISTRATION_STEP1_LIST_QS_KEY).formSearchData,
      );
      fetchAll(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  async function fetchAll(
    pageNumber: number = 0,
    sizePerPage: number = 10,
    searchData: DealerAnchorAgreementListForArsRequest,
  ) {
    try {
      const arResponse = await requestDealerAnchorAgreementListForArs(pageNumber, sizePerPage, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount: sizePerPage,
        },
        DE_AP_REGISTRATION_STEP1_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAcAgreementList(arResponse);
        setPageable(arResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = (pageNumber: number, sizePerPage: number) => {
    fetchAll(pageNumber, sizePerPage, getParsedSearchParams(DE_AP_REGISTRATION_STEP1_LIST_QS_KEY).formSearchData);
  };

  const onClickSearch = async (e: any) => {
    e.preventDefault();

    await fetchAll(0, pageable.sizePerPage, getValues());
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 135,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 75,
    },
  ];

  const renderAnchorAgreementTable = () => {
    return acAgreementList?.content.map(item => {
      return (
        <Tr key={item.anchorAgreementId}>
          <Td data={item.financierName} />
          <Td data={item.financierClientName} />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td className="information-table-more">
            <Button
              bold
              to={ROUTES_DE.REGISTER_AR.REGISTRATION_STEP2_BUILD_PATH(item.anchorAgreementId)}
              state={{
                financierId: item.financierId,
                anchorAgreementId: item.anchorAgreementId,
                financierName: item.financierName,
                financierClientName: item.financierClientName,
                contractNo: item.contractNo,
                currencyType: item.currencyType,
              }}
            >
              {t('text:Select')}
            </Button>
          </Td>
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:AR_Registration_Request')} />
      <div className="content-area">
        <StepWizard nth={1} title={[t('text:Select_Anchor_Master_Agreement'), t('text:Enter_AR_Information')]} />
      </div>

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Anchor_Master_Agreement_List')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name="contractNo" ref={register} />
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderAnchorAgreementTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerArRegisterStep1;
