import { memo } from 'react';
import type { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';

import { values } from 'lodash-es';

import type { CURRENCY_TYPE } from 'enums';
import type { AnchorArListElementType } from 'utils/http/api/anchor/ar-summaries/request';
import { invoiceOrArAmountInputValidate, invoiceOrArSettlementDateInputValidate } from 'utils/logic';
import { REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING } from 'utils/validation/regExp';
import useValidation from 'utils/validation/useValidation';
import { replaceHyphenWithEmptyValue } from 'utils/valueManager/ValueManager';

import type { InvalidExcelInfoType } from './ApInputBulkImportTable';
import type { FieldValuesType } from './ApInputBulkTable';
import type { TFunction } from 'i18next';

interface ApInputBulkTableBodyProps {
  errors: DeepMap<FieldValuesType, FieldError>;
  register: UseFormMethods<FieldValuesType>['register'];
  excelInfos?: AnchorArListElementType[] | InvalidExcelInfoType[];
  minDate: Date;
  maxDate: Date;
  holidays: string[];
  t: TFunction;
  currencyType?: CURRENCY_TYPE;
  isApNumberDuplicated: (partnerTaxCode: string, arNumber: string) => boolean | string;
  isInvalidTable?: boolean;
  fieldNames: { [key in keyof AnchorArListElementType]: { name: string } };
}

const ApInputBulkTableBody = ({
  errors,
  register,
  excelInfos,
  minDate,
  maxDate,
  holidays,
  currencyType,
  t,
  isApNumberDuplicated,
  isInvalidTable = false,
  fieldNames,
}: ApInputBulkTableBodyProps) => {
  const date = new Date();
  const today = [date.getFullYear(), date.getMonth(), date.getDate()];

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: errors.arList,
    rule: 'arList',
    accountType: 'AP',
    minDate,
    maxDate,
  });

  return (
    <>
      {!excelInfos && (
        <tr>
          <td colSpan={values(fieldNames).length + 1} className="text-center fs-7 fw-normal">
            {t('text:Attach_a_file_to_upload_AP_data')}
          </td>
        </tr>
      )}

      {!errors.arList && isInvalidTable ? (
        <tr>
          <td colSpan={values(fieldNames).length + 2} className="text-center fs-7 fw-normal">
            {t('text:There_are_no_invalid_AP')}
          </td>
        </tr>
      ) : (
        excelInfos &&
        excelInfos.map((excelInfo, index) => {
          const rowIndex = 'rowIndex' in excelInfo ? excelInfo.rowIndex : index;

          return (
            <tr key={rowIndex}>
              <td className="bg-sub100" key={rowIndex}>
                <div className="text-center fs-7 fw-bold">{rowIndex + 1}</div>
              </td>
              <td className={getValidationClassName('partnerTaxCode', 'td', rowIndex)}>
                <input
                  className={getValidationClassName('partnerTaxCode', 'input', rowIndex)}
                  name={`arList.${rowIndex}.partnerTaxCode`}
                  ref={register({
                    pattern: {
                      value: REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING,
                      message: 'text:Fail_The_tax_code_must_consist_only_of_letters_and_numbers',
                    },
                    required: 'text:Fail_Missing_item(s)',
                  })}
                  disabled={true}
                />
              </td>
              <td className={getValidationClassName('arNumber', 'td', rowIndex)}>
                <input
                  className={getValidationClassName('arNumber', 'input', rowIndex)}
                  name={`arList.${rowIndex}.arNumber`}
                  ref={register({
                    required: 'text:Fail_Missing_item(s)',
                    validate: value => isApNumberDuplicated(excelInfo.partnerTaxCode!, value),
                    maxLength: {
                      value: 20,
                      message: 'text:Fail_The_AP_Number_must_be_between_1_and_20_characters',
                    },
                  })}
                  disabled={true}
                />
              </td>
              <td className={getValidationClassName('arIssuedDate', 'td', rowIndex)}>
                <input
                  className="d-none"
                  name={`arList.${rowIndex}.arIssuedDate`}
                  ref={register({
                    required: 'text:Fail_Missing_item(s)',
                  })}
                />
                <input
                  className={getValidationClassName('arIssuedDate', 'input', rowIndex)}
                  value={replaceHyphenWithEmptyValue(t('format:date', { value: excelInfo.arIssuedDate, key: 'date' }))}
                  disabled={true}
                />
              </td>
              <td className={getValidationClassName('settlementDate', 'td', rowIndex)}>
                <input
                  className="d-none"
                  name={`arList.${rowIndex}.settlementDate`}
                  ref={register({
                    required: 'text:Fail_Missing_item(s)',
                    validate: () =>
                      invoiceOrArSettlementDateInputValidate(
                        today,
                        excelInfo.settlementDate,
                        holidays,
                        minDate,
                        maxDate,
                      ),
                  })}
                />
                <input
                  className={getValidationClassName('settlementDate', 'input', rowIndex)}
                  value={replaceHyphenWithEmptyValue(
                    t('format:date', { value: excelInfo.settlementDate, key: 'date' }),
                  )}
                  disabled={true}
                />
              </td>
              <td className={getValidationClassName('arAmount', 'td', rowIndex)}>
                <input
                  type="text"
                  className={getValidationClassName('arAmount', 'input', rowIndex) + ' text-end'}
                  name={`arList.${rowIndex}.arAmount`}
                  ref={register({
                    validate: value => invoiceOrArAmountInputValidate(value, currencyType, 'AP'),
                    required: 'text:Fail_Missing_item(s)',
                    min: { value: 0.01, message: 'text:Fail_AP_Amount_must_exceed_0' },
                  })}
                  disabled={true}
                />
              </td>
              {getValidationResult(rowIndex)}
            </tr>
          );
        })
      )}
    </>
  );
};

export default memo(ApInputBulkTableBody);
