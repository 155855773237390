import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import fin2bLogo from 'assets/png/fin2bLogo.png';

import { ROUTES_PP } from 'constants/routes/potentialPartner';
import type { languageType } from 'enums';
import { LANGUAGE_SELECT_OPTIONS, handleLanguageSelectChange } from 'locales/languageSettings';

import './PPHeader.scss';

function PPHeader() {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  return (
    <div className="pp-header-bar__wrapper">
      <div className="pp-header-bar">
        <div
          className="d-flex"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push(ROUTES_PP.HOME);
          }}
        >
          <img src={fin2bLogo} className="pp-header-bar__logo" alt="Fin2b logo" />
          <h2 className="pp-header-bar__title">{t('text:Supply_Chain_Financing_Platform')}</h2>
        </div>
        <div className="pp-header-bar__select-box">
          <select
            id="language"
            defaultValue={i18n.language}
            onChange={e => handleLanguageSelectChange(e.target.value as languageType)}
          >
            {LANGUAGE_SELECT_OPTIONS.map(({ text, value }, index) => (
              <option key={index} value={value}>
                {text}
              </option>
            ))}
          </select>
          <span className="pp-header-bar__arrow-down-icon" />
        </div>
      </div>
    </div>
  );
}

export default PPHeader;
