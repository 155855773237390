import type Pageable from 'models/Pageable';
import type {
  DealerAgreementRepaidAmountVO,
  DealerAgreementRepaidAmountVOModel,
} from 'models/vo/DealerAgreementRepaidAmountVO';
import { formattingToDealerAgreementRepaidAmountVOModel } from 'models/vo/DealerAgreementRepaidAmountVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorDealerAgreementRepaidAmountsListRequest } from './requests';

export async function requestAnchorDealerAgreementRepaidAmountsList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorDealerAgreementRepaidAmountsListRequest,
): Promise<Pageable<DealerAgreementRepaidAmountVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementRepaidAmountVO[]>>({
    url: API_AC.DEALER_AGREEMENT_REPAID_AMOUNTS.DEALER_AGREEMENT_REPAID_AMOUNT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDealerAgreementRepaidAmountVOModel(item)),
  };
}
