import type { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { tableValueManage } from 'utils/valueManager/ValueManager';

import './PPTextField.scss';

type FormatType = 'number' | 'date' | 'datetime' | 'percent' | 'code';

interface PPTextFieldValueProps extends HTMLAttributes<HTMLDivElement> {
  value?: any;
  className?: string;
  children?: JSX.Element | JSX.Element[];
  format?: FormatType;
}

function PPTextFieldValue({ value, className = '', children, format = undefined, ...props }: PPTextFieldValueProps) {
  const { t } = useTranslation(['format']);
  const formatData = (value: any, format?: FormatType) => {
    switch (format) {
      case 'date':
        return t('format:date', {
          value: value,
          key: 'date',
        });
      case 'datetime':
        return t('format:datetime', {
          value: value,
          key: 'datetime',
        });
      case 'number':
        if (typeof value === 'number' || typeof value === 'string') return t('format:number', { value: value });
        else return value;
      case 'percent':
        if (typeof value === 'number') {
          return `${t('format:number', { value: value })}%`;
        } else {
          return value;
        }
      case 'code':
        const status = value.split('.');

        return status[1] === 'undefined' || status[1] === 'null' ? '-' : value;
      default:
        return value;
    }
  };

  return (
    <div className={`pp-text-field__value ${className}`} {...props}>
      {children ? children : value?.toString() ? formatData(value, format) : tableValueManage(value)}
    </div>
  );
}

export default PPTextFieldValue;
