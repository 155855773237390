import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import WithProvider from 'utils/render';

import WaitingAnchorAgreementApprovalInfo from './sections/WaitingAnchorAgreementApprovalInfo';
import useWaitingDetailController from './useWaitingDetailController';
import AgreementProvider from '../models/agreement/Provider';
import DivisionsProvider from '../models/divisions/Context';
import ExtraInformationProvider from '../models/extraInformation/Context';
import AnchorCompany from '../sections/common/AnchorCompany';
import Financier from '../sections/common/Financier';
import FinancingLimitOfPartners from '../sections/common/FinancingLimitOfPartners';
import GeneralInformation from '../sections/common/GeneralInformation';
import LoadAgreement from '../sections/common/LoadAgreement';
import AutomaticFinancing from '../sections/dealer/AutomaticFinancing';
import InvoicePayment from '../sections/dealer/InvoicePayment';
import InvoiceRegistration from '../sections/dealer/InvoiceRegistration';
import ARRegistration from '../sections/vendor/ARRegistration';
import ARSettlement from '../sections/vendor/ARSettlement';

const AnchorAgreementWaitingDetail = () => {
  const { t } = useTranslation(['format']);

  const {
    isInvoice,
    isEditable,
    isEditableStyle,
    isFirstRegisteredWaitingAgreement,
    useAgreementInterface,
    isSameBranch,
    isRegistrationRequestButtonDisabled,
    methods,
    approvalType,
    GuideMessageOption,
    getReadOnlyValue,
    conditionChecker,
    handleCancelButtonClick,
    handleChangeRequestButtonClick,
    handleReturnButtonClick,
    handleConfirmButtonClick,
  } = useWaitingDetailController();

  return (
    <>
      <BackHeaderTitle
        title={
          isFirstRegisteredWaitingAgreement
            ? t('text:Anchor_Master_Agreement_Registration_Request')
            : t('text:Anchor_Master_Agreement_Modification_Request')
        }
      />

      {GuideMessageOption && <GuideMessage {...GuideMessageOption} />}

      {approvalType && <WaitingAnchorAgreementApprovalInfo />}

      <Form
        methods={methods}
        isEditable={isEditable}
        isEditableStyle={isEditableStyle}
        getReadOnlyValue={getReadOnlyValue}
      >
        {useAgreementInterface && isEditable && (
          <div className="content-area">
            <SectionTitle title={t('text:Load_Agreement_from_Financier_System')} />
            <LoadAgreement />
          </div>
        )}

        <div className="content-area">
          <SectionTitle title={t('text:Contracting_Parties')} />
          <Border isEditable={isEditableStyle}>
            <Financier />
            <AnchorCompany />
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle title={t('text:General_Information')} />
          <Border isEditable={isEditableStyle}>
            <GeneralInformation />
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle
            title={isInvoice ? t('text:Invoice_Registration_and_Payment') : t('text:AR_Registration_and_Settlement')}
          />
          <Border isEditable={isEditableStyle}>
            {isInvoice ? (
              <>
                <InvoiceRegistration />
                <InvoicePayment />
              </>
            ) : (
              <>
                <ARRegistration />
                <ARSettlement />
              </>
            )}
          </Border>
        </div>

        <div className="content-area">
          <SectionTitle title={t('text:Financing_Terms_and_Conditions_for_Partners')} />
          <Border isEditable={isEditableStyle}>
            <FinancingLimitOfPartners />
            {isInvoice && <AutomaticFinancing />}
          </Border>
        </div>
      </Form>

      {/* button */}
      {isSameBranch && (
        <div className="content-area">
          <div className="flex-end">
            {conditionChecker(AUTHORITY_TYPE.OPERATOR, COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED) && (
              <>
                <Button
                  size={ButtonSizeEnum.LG}
                  onClick={handleCancelButtonClick}
                  color={ButtonColorEnum.SECONDARY}
                  variant={ButtonVariantEnum.OUTLINED}
                >
                  {t('text:Cancel')}
                </Button>
                <Button
                  size={ButtonSizeEnum.LG}
                  onClick={handleChangeRequestButtonClick}
                  className="ms-2"
                  disabled={isRegistrationRequestButtonDisabled}
                >
                  {isFirstRegisteredWaitingAgreement ? t('text:Registration_Request') : t('text:Request_Change')}
                </Button>
              </>
            )}
            {conditionChecker(AUTHORITY_TYPE.AUTHORIZER, COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) && (
              <>
                <Button
                  size={ButtonSizeEnum.LG}
                  onClick={handleReturnButtonClick}
                  color={ButtonColorEnum.RED}
                  variant={ButtonVariantEnum.OUTLINED}
                >
                  {t('text:Revert')}
                </Button>
                <Button size={ButtonSizeEnum.LG} onClick={handleConfirmButtonClick} className="ms-2">
                  {t('text:Confirm')}
                </Button>
              </>
            )}
            {conditionChecker(AUTHORITY_TYPE.OPERATOR, COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) && (
              <Button
                size={ButtonSizeEnum.LG}
                onClick={handleCancelButtonClick}
                color={ButtonColorEnum.SECONDARY}
                variant={ButtonVariantEnum.OUTLINED}
              >
                {t('text:Cancel_Request')}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default WithProvider({
  Component: AnchorAgreementWaitingDetail,
  Provider: [AgreementProvider, DivisionsProvider, ExtraInformationProvider],
});
