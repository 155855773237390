import type { CURRENCY_TYPE } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface ArCommissionSettlementDetailVO {
  daysToMaturity: number; // 남은 일수
  settlementDate: string;
  anchorName: string;
  partnerName: string;
  currencyType: CURRENCY_TYPE;
  totalArAmount: BigNumber;
  totalArCount: number;
  dealerAgreementId: number;
  totalArCommissionAmount: BigNumber;
  totalArCommissionCount: number;
  arCommissionCreatedAmount: BigNumber;
  arCommissionCreatedCount: number;
  arCommissionPaidAmount: BigNumber;
  arCommissionPaidCount: number;
  disbursableStatusAmount: BigNumber;
  disbursableStatusCount: number;
  pendingStatusDisbursementAmount: BigNumber;
  pendingStatusDisbursementCount: number;
  completedStatusDisbursementAmount: BigNumber;
  completedStatusDisbursementCount: number;
}

export interface ArCommissionSettlementDetailVOModel extends ArCommissionSettlementDetailVO {}

export function formattingToArCommissionSettlementDetailVOModel(
  data: ArCommissionSettlementDetailVO,
): ArCommissionSettlementDetailVOModel {
  return {
    ...data,
  };
}
