import { useTranslation } from 'react-i18next';

import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import type { ColumnOption, MergedCellTypes } from 'utils/spreadSheet/types';

export function useFinancierOverviewByPartnerListChannelTabConstants() {
  const { t } = useTranslation(['format']);
  const excelColumnsInvoice: ColumnOption<StatisticsOfDealerAgreementVOModel>[] = [
    {
      header: t('text:Partner_Name'),
      key: 'dealerClientName',
    },
    {
      header: t('text:Partner_Client_Code'),
      key: 'dealerClientCode',
    },
    {
      header: t('text:Partner_Master_Agreement_Number'),
      key: 'contractNo',
    },
    {
      header: t('text:Associated_Anchor_Name'),
      key: 'anchorClientName',
    },
    {
      header: t('text:Associated_Anchor_Client_Code'),
      key: 'anchorClientCode',
    },
    {
      header: t('text:Associated_Anchor_Master_Agreement_Number'),
      key: 'anchorAgreementContractNo',
    },
    {
      header: t('text:Currency'),
      key: 'currencyType',
    },
    {
      header: t('text:Effective_Date'),
      key: 'startDate',
    },
    {
      header: t('text:Expiration_Date'),
      key: 'expiryDate',
    },
    {
      header: t('text:Interest_Rate_(APR)'),
      key: 'basisInterestRate',
    },
    {
      header: t('text:Financing_Limit'),
      key: 'loanLimitAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Ad_hoc_Limit'),
      key: 'adhocLimitAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Ad_hoc_Limit_Start_Date'),
      key: 'adhocLimitStartDate',
    },
    {
      header: t('text:Ad_hoc_Limit_End_Date'),
      key: 'adhocLimitEndDate',
    },
    {
      header: t('text:Pending_Invoice_Amount'),
      key: 'currentSettlementWaitingInvoiceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Pending_Invoices'),
      key: 'currentSettlementWaitingInvoiceCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Outstanding_Financing_Balance'),
      key: 'currentLoanBalanceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Outstanding_Financing'),
      key: 'currentDisbursedLoanCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Remaining_Financing_Limit'),
      key: 'remainingLimit',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Remaining_Limit'),
      key: 'totalRemainingLimit',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Delinquent_Status'),
      key: 'currentOverdueLoanCount',
    },
    {
      header: t('text:Total_Registered_Invoice_Amount'),
      key: 'registeredInvoiceAmount',

      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Registered_Invoices'),
      key: 'registeredInvoiceCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Financing_Amount'),
      key: 'totalDisbursedLoanAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Disbursed_Financing'),
      key: 'totalDisbursedLoanCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Amount_of_Not_Proceeded_Invoices'),
      key: 'totalUnsettledInvoiceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Not_Proceeded_Invoices'),
      key: 'totalUnsettledInvoiceCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
  ];

  const invoiceMergedCells: MergedCellTypes[] = [
    {
      target: 'A:N',
      rowIndex: 1,
      value: t('text:Partner_Information'),
    },
    {
      target: 'O:U',
      rowIndex: 1,
      value: t('text:Current_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFFCDE' },
        },
      },
    },
    {
      target: 'V:AA',
      rowIndex: 1,
      value: t('text:Accumulated_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFF8BD' },
        },
      },
    },
  ];

  const invoiceMergedPdfTableHeader: BigHeaderType[] = [
    {
      headerText: t('text:Partner_Information'),
      colSpan: 14,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 7,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 6,
      className: 'main200',
    },
  ];

  const invoicePdfTableHeader: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 165,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 165,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 165,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 165,
    },
    {
      headerText: t('text:Associated_Anchor_Client_Code'),
      colWidths: 165,
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement_Number'),
      colWidths: 165,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Interest_Rate_(APR)'),
      colWidths: 165,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 165,
    },
    {
      headerText: t('text:Ad_hoc_Limit'),
      colWidths: 180,
    },
    {
      headerText: t('text:Ad_hoc_Limit_Start_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Ad_hoc_Limit_End_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Pending_Invoice_Amount'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Number_of_Pending_Invoices'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Outstanding_Financing_Balance'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Number_of_Outstanding_Financing'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Remaining_Financing_Limit'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Remaining_Limit'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Delinquent_Status'),
      colWidths: 100,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Registered_Invoice_Amount'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Number_of_Registered_Invoices'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Financing_Amount'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Number_of_Disbursed_Financing'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Amount_of_Not_Proceeded_Invoices'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Number_of_Not_Proceeded_Invoices'),
      colWidths: 180,
      className: 'main200',
    },
  ];

  const INVOICE_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 170,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Associated_Anchor_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Interest_Rate_(APR)'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 140,
    },
    {
      headerText: t('text:Ad_hoc_Limit'),
      colWidths: 160,
    },
    {
      headerText: t('text:Ad_hoc_Limit_Effective_Period'),
      colWidths: 160,
    },
    {
      headerText: t('text:Pending_Invoice_Amount_/_Count'),
      colWidths: 155,
      className: 'main100',
    },
    {
      headerText: t('text:Outstanding_Financing_Balance_/_Count'),
      colWidths: 120,
      className: 'main100',
    },
    {
      headerText: t('text:Remaining_Financing_Limit'),
      colWidths: 165,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Remaining_Limit'),
      colWidths: 150,
      className: 'main100',
    },
    {
      headerText: t('text:Delinquent_Status'),
      colWidths: 90,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Registered_Invoice_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Financing_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Nonproceeded_Invoice_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
  ];

  const INVOICE_BIG_TABLE_HEADER: BigHeaderType[] = [
    {
      headerText: t('text:Partner_Information'),
      colSpan: 13,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 5,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 3,
      className: 'main200',
    },
  ];

  return {
    excelColumnsInvoice,
    invoiceMergedCells,
    invoiceMergedPdfTableHeader,
    invoicePdfTableHeader,
    INVOICE_TABLE_HEADER,
    INVOICE_BIG_TABLE_HEADER,
  };
}
