import { useTranslation } from 'react-i18next';

import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import type { ColumnOption, MergedCellTypes } from 'utils/spreadSheet/types';

export function useAnchorOverviewByPartnerListChannelTabConstants() {
  const { t } = useTranslation(['format']);

  const excelColumnsInvoice: ColumnOption<StatisticsOfDealerAgreementVOModel>[] = [
    {
      header: t('text:Partner_Name'),
      key: 'dealerClientName',
    },
    {
      header: t('text:Partner_Client_Code'),
      key: 'dealerClientCode',
    },
    {
      header: t('text:Partner_Code_of_Anchor'),
      key: 'dealerCodeByAnchor',
    },
    {
      header: t('text:Partner_Master_Agreement_Number'),
      key: 'contractNo',
    },
    {
      header: t('text:Associated_Anchor_Master_Agreement_Number'),
      key: 'anchorAgreementContractNo',
    },
    {
      header: t('text:Currency'),
      key: 'currencyType',
    },
    {
      header: t('text:Effective_Date'),
      key: 'startDate',
    },
    {
      header: t('text:Expiration_Date'),
      key: 'expiryDate',
    },
    {
      header: t('text:Interest_Rate_(APR)'),
      key: 'basisInterestRate',
    },
    {
      header: t('text:Financing_Limit'),
      key: 'loanLimitAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Ad_hoc_Limit'),
      key: 'adhocLimitAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Ad_hoc_Limit_Start_Date'),
      key: 'adhocLimitStartDate',
    },
    {
      header: t('text:Ad_hoc_Limit_End_Date'),
      key: 'adhocLimitEndDate',
    },
    {
      header: t('text:Outstanding_Financing_Balance'),
      key: 'currentLoanBalanceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Remaining_Financing_Limit'),
      key: 'remainingLimit',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Remaining_Limit'),
      key: 'totalRemainingLimit',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Delinquent_Status'),
      key: 'currentOverdueLoanCount',
    },
  ];

  const invoiceMergedCells: MergedCellTypes[] = [
    {
      target: 'A:M',
      rowIndex: 1,
      value: t('text:Partner_Information'),
    },
    {
      target: 'N:Q',
      rowIndex: 1,
      value: t('text:Current_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFFCDE' },
        },
      },
    },
  ];

  const invoiceMergedPdfTableHeader: BigHeaderType[] = [
    {
      headerText: t('text:Partner_Information'),
      colSpan: 13,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      className: 'main100',
      colSpan: 4,
    },
  ];

  const invoicePdfTableHeader: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Code_of_Anchor'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 150,
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement_Number'),
      colWidths: 180,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Interest_Rate_(APR)'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 120,
    },
    {
      headerText: t('text:Ad_hoc_Limit'),
      colWidths: 140,
    },
    {
      headerText: t('text:Ad_hoc_Limit_Start_Date'),
      colWidths: 140,
    },
    {
      headerText: t('text:Ad_hoc_Limit_End_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Outstanding_Financing_Balance'),
      colWidths: 120,
      className: 'main100',
    },
    {
      headerText: t('text:Remaining_Limit'),
      colWidths: 140,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Remaining_Limit'),
      colWidths: 200,
      className: 'main100',
    },
    {
      headerText: t('text:Delinquent_Status'),
      colWidths: 85,
      className: 'main100',
    },
  ];

  const INVOICE_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Code_of_Anchor'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 150,
    },
    {
      headerText: t('text:Associated_Anchor_Master_Agreement_Number'),
      colWidths: 180,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Interest_Rate_(APR)'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 120,
    },
    {
      headerText: t('text:Ad_hoc_Limit'),
      colWidths: 140,
    },
    {
      headerText: t('text:Ad_hoc_Limit_Start_Date'),
      colWidths: 140,
    },
    {
      headerText: t('text:Ad_hoc_Limit_End_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Outstanding_Financing_Balance'),
      colWidths: 120,
      className: 'main100',
    },
    {
      headerText: t('text:Remaining_Limit'),
      colWidths: 140,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Remaining_Limit'),
      colWidths: 200,
      className: 'main100',
    },
    {
      headerText: t('text:Delinquent_Status'),
      colWidths: 85,
      className: 'main100',
    },
  ];

  const INVOICE_BIG_TABLE_HEADER: BigHeaderType[] = [
    {
      headerText: t('text:Partner_Information'),
      colSpan: 13,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 4,
      className: 'main100',
    },
  ];

  return {
    excelColumnsInvoice,
    invoiceMergedCells,
    invoiceMergedPdfTableHeader,
    invoicePdfTableHeader,
    INVOICE_TABLE_HEADER,
    INVOICE_BIG_TABLE_HEADER,
  };
}
