import { useTranslation } from 'react-i18next';

import { LOAN_APPROVAL_TYPE, LOAN_STATUS } from 'enums';
import type { LoanVOModel } from 'models/vo/LoanVO';
import type { ColumnOption } from 'utils/spreadSheet/types';

export function useSystemFinancingListConstants() {
  const { t } = useTranslation(['format']);

  const SY_FINANCING_LIST_QS_KEY = 'sy-financing-list';

  const LOAN_STATUS_CHECK_BOX_OPTIONS = [
    'ALL',
    LOAN_STATUS.DEALER_CREATED,
    LOAN_STATUS.DEALER_REQUESTED,
    LOAN_STATUS.DEALER_CANCELLED,
    LOAN_STATUS.REJECTED,
    LOAN_STATUS.APPROVED,
    LOAN_STATUS.DISBURSED,
    LOAN_STATUS.REPAID,
    LOAN_STATUS.OVERDUE,
    LOAN_STATUS.EXPIRATION,
    LOAN_STATUS.RECOURSE_ACTIVATED,
  ];

  const LOAN_APPROVAL_TYPE_CHECK_BOX_OPTIONS = [
    'ALL',
    LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL,
    LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED,
    LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE,
    LOAN_APPROVAL_TYPE.OPERATOR_REPAID,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID,
    LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE,
    LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION,
  ];
  const LOAN_APPROVAL_TYPE_WITH_INTERFACE_CHECK_BOX_OPTIONS = [
    'ALL',
    LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL,
    LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION,
  ];

  const NUM_OF_CHECKBOX_LOAN_STATUS = LOAN_STATUS_CHECK_BOX_OPTIONS.length;
  const NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE = LOAN_APPROVAL_TYPE_CHECK_BOX_OPTIONS.length;
  const NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE_INTERFACE = LOAN_APPROVAL_TYPE_WITH_INTERFACE_CHECK_BOX_OPTIONS.length;

  const getSystemLoanListTableHeaders = (isPDFHeader = false) => {
    const headers = [
      {
        headerText: t('text:Program_Type'),
        colWidths: 100,
      },
      {
        headerText: t('text:Platform_Financing_ID'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financier_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Partner_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 90,
      },
      {
        headerText: t('text:Invoice_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:AR_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Applied_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Scheduled_Disbursement_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Disbursed_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Requested_Financing_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financing_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Advance_Disbursement'),
        colWidths: 100,
      },
      {
        headerText: t('text:Scheduled_Repayment_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Repaid_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Repaid_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Outstanding_Balance'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financing_Status'),
        colWidths: 100,
      },
      {
        headerText: t('text:Approval_Status'),
        colWidths: 100,
      },
    ];

    return isPDFHeader ? headers : [...headers, { headerText: '', colWidths: 50 }];
  };

  const excelColumns: ColumnOption<LoanVOModel>[] = [
    {
      header: t('text:Program_Type'),
      key: 'collateralType',
    },
    {
      header: t('text:Platform_Financing_ID'),
      key: 'loanId',
    },
    {
      header: t('text:Financier_Financing_ID'),
      key: 'financierLoanId',
    },
    {
      header: t('text:Financier_Name'),
      key: 'financierName',
    },
    {
      header: t('text:Associated_Anchor_Name'),
      key: 'anchorClientName',
    },
    {
      header: t('text:Associated_Anchor_Client_Code'),
      key: 'anchorClientCode',
    },
    {
      header: t('text:Partner_Name'),
      key: 'dealerClientName',
    },
    {
      header: t('text:Partner_Client_Code'),
      key: 'dealerClientCode',
    },
    {
      header: t('text:Currency'),
      key: 'currencyType',
    },
    {
      header: t('text:BOE_Number'),
      key: 'billOfExchangeNo',
    },
    {
      header: t('text:Invoice_Number'),
      key: 'invoiceNumber',
    },
    {
      header: t('text:Invoice_Reference_Number'),
      key: 'referenceNumber',
    },
    {
      header: t('text:AR_Number'),
      key: 'arNumber',
    },
    {
      header: t('text:Applied_Date'),
      key: 'requestedDateTime',
    },
    {
      header: t('text:Scheduled_Disbursement_Date'),
      key: 'desiredDisburseDate',
    },
    {
      header: t('text:Disbursed_Date'),
      key: 'disbursedDate',
    },
    {
      header: t('text:Requested_Financing_Amount'),
      key: 'principalAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Financing_Amount'),
      key: 'disbursedAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Advance_Disbursement'),
      key: 'netDisbursementAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Scheduled_Repayment_Date'),
      key: 'repaymentDate',
    },
    {
      header: t('text:Remaining_Financing_Term'),
      key: 'remainingFinancingTerm',
      style: {
        alignment: { horizontal: 'right' },
        numFmt: `"D"+0;"D"-0;0`,
      },
    },
    {
      header: t('text:Repaid_Date'),
      key: 'repaidDate',
    },
    {
      header: t('text:Repaid_Amount'),
      key: 'repaidPrincipalAmount',
    },
    {
      header: t('text:Outstanding_Balance'),
      key: 'outstandingBalance',
    },
    {
      header: t('text:Accrued_Interest_Amount'),
      key: 'accruedInterestAmount',
    },
    {
      header: t('text:Outstanding_Accrued_Interest_Amount'),
      key: 'outstandingAccruedInterestAmount',
    },
    {
      header: t('text:Financing_Status'),
      key: 'loanStatus',
    },
    {
      header: t('text:Approval_Status'),
      key: 'loanApprovalType',
    },
  ];

  return {
    t,
    SY_FINANCING_LIST_QS_KEY,
    getSystemLoanListTableHeaders,
    excelColumns,
    checkBoxOptions: {
      LOAN_STATUS_CHECK_BOX_OPTIONS,
      LOAN_APPROVAL_TYPE_CHECK_BOX_OPTIONS,
      LOAN_APPROVAL_TYPE_WITH_INTERFACE_CHECK_BOX_OPTIONS,
      NUM_OF_CHECKBOX_LOAN_STATUS,
      NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE,
      NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE_INTERFACE,
    },
  };
}
