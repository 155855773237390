import type React from 'react';
import { useState } from 'react';

import useOutsideClick from './useOutsideClick';

export default function useToggle<T>(ref: React.RefObject<T>): [boolean, (b?: boolean) => void] {
  const [state, setState] = useState<boolean>(false);

  useOutsideClick(() => setState(false), ref);

  return [
    state,
    (bool?: boolean) => {
      if (bool) setState(bool);
      else setState(!state);
    },
  ];
}
