import type Pageable from 'models/Pageable';
import type { ArSummaryVO, ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import { formattingToArSummaryVOModel } from 'models/vo/ArSummaryVO';
import type { LastArPhaseVO, LastArPhaseVOModel } from 'models/vo/LastArPhaseVO';
import { formattingToLastArPhaseVOModel } from 'models/vo/LastArPhaseVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { DealerArPhaseApproveRequest, DealerArRegisterRequest, DealerArSummariesListRequest } from './request';

// ar summary list
export async function requestDealerArSummaryList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerArSummariesListRequest,
): Promise<Pageable<ArSummaryVOModel[]>> {
  const response = await http.get<Pageable<ArSummaryVO[]>>({
    url: API_DE.AR_SUMMARIES.AR_SUMMARIES,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const arSummaryList: ArSummaryVOModel[] = response.content.map((data: ArSummaryVO) =>
    formattingToArSummaryVOModel(data),
  );
  const arSummaryPage: Pageable<ArSummaryVOModel[]> = {
    ...response,
    content: arSummaryList,
  };

  return arSummaryPage;
}

// 수기 AR 등록
export async function requestDealerArRegister(data: DealerArRegisterRequest): Promise<void> {
  await http.post<null>({
    url: API_DE.AR_SUMMARIES.AR_SUMMARIES,
    data,
  });
}

// AR 결재 요약 단건 조회
export async function requestDealerArSummaryDetail(arSummaryId: number): Promise<ArSummaryVOModel> {
  const response = await http.get<ArSummaryVO>({
    url: API_DE.AR_SUMMARIES.AR_SUMMARY_DETAIL(arSummaryId),
  });

  return formattingToArSummaryVOModel(response);
}

// 마지막 단계 AR 결재 조회
export async function requestDealerArSummaryLastPhase(arSummaryId: number): Promise<LastArPhaseVOModel> {
  const response = await http.get<LastArPhaseVO>({
    url: API_DE.AR_SUMMARIES.AR_SUMMARY_LAST_PHASE(arSummaryId),
  });

  return formattingToLastArPhaseVOModel(response);
}

// ar 결재 승인
export async function requestDealerApproveArPhase(data: DealerArPhaseApproveRequest): Promise<void> {
  await http.put<null>({
    url: API_DE.AR_SUMMARIES.AR_SUMMARY_APPROVE,
    data,
  });
}

// ar 거절
export async function requestDealerRejectArRegistration(arSummaryId: number, reason?: string) {
  const response = await http.put({
    url: API_DE.AR_SUMMARIES.AR_SUMMARY_REJECT,
    data: {
      arSummaryId: arSummaryId,
      reason: reason,
    },
  });

  return response;
}
