import { AUTHORITY_TYPE, ENTERPRISE_TYPE, stringToEnum } from 'enums';

export interface LoginHistoryVO {
  loginHistoryId: number;
  success: boolean;
  failedReason: string;
  os: string;
  browser: string;
  ipAddress: string;
  countryName: string;
  createdDateTime: string;
  logoutDateTime: string;
  userId: number;
  loginId: string;
  userName: string;
  authorityType: string;
  enterpriseId: number;
  enterpriseName: string;
  enterpriseType: string;
}

export interface LoginHistoryVOModel extends LoginHistoryVO {
  authorityType: AUTHORITY_TYPE;
  enterpriseType: ENTERPRISE_TYPE;
}

export function formattingToLoginHistoryVOModel(data: LoginHistoryVO): LoginHistoryVOModel {
  return {
    ...data,
    authorityType: stringToEnum(AUTHORITY_TYPE, data.authorityType),
    enterpriseType: stringToEnum(ENTERPRISE_TYPE, data.enterpriseType),
  };
}
