import type * as React from 'react';
import { Suspense, lazy } from 'react';
import { Redirect } from 'react-router';
import { Route, Router, Switch } from 'react-router-dom';

import NotFoundPage from 'pages/NotFoundPage';
import LoginPage from 'pages/common/login/LoginPage';
import { browserHistory } from 'router/browserHistory';
import { PrivateRoute } from 'router/guard/PrivateRoute';
import SystemAdminRouter from 'router/system';
import { preventPageRedirectOnLoading } from 'utils/loadingUIManager/loadingUIManager';
import { homePathByRole } from 'utils/route';
import { ScrollToTop } from 'utils/scrollToTop';
import { getSignIn } from 'utils/storage/LocalStorage';

import { WrapWithNavigationFallBack } from './lazy-fallback';

preventPageRedirectOnLoading();

const AnchorRouter = lazy(() => import('router/anchor'));
const DealerRouter = lazy(() => import('router/dealer'));
const FinancierRouter = lazy(() => import('router/financier'));

const redirectToHomeOrLogin = (props: any) => {
  const signInModel = getSignIn();

  if (signInModel) return <Redirect to={homePathByRole(signInModel.authorities[0].authority)} />;
  else return <LoginPage {...props} />;
};

const Root: React.FC = () => (
  <Router history={browserHistory}>
    <ScrollToTop />
    <Suspense fallback={<WrapWithNavigationFallBack />}>
      <Switch>
        <Route path="/" exact render={(props: any) => redirectToHomeOrLogin(props)} />
        <PrivateRoute path="/system/" component={SystemAdminRouter} />
        <PrivateRoute path="/financier/" component={FinancierRouter} />
        <PrivateRoute path="/anchor/" component={AnchorRouter} />
        <PrivateRoute path="/dealer/" component={DealerRouter} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  </Router>
);

export default Root;
