import type Pageable from 'models/Pageable';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVO, AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { formattingToAnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { UserEmailSettingVO, UserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import { formattingToUserEmailSettingVOModel } from 'models/vo/UserEmailSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierAnchorUserEmailSettingUpdateRequest } from './requests';
import type { FinancierAnchorUsersRequest } from './requests';

export const requestFinancierAnchorUsers = async (
  pageNumber: number,
  rowCount: number,
  data: FinancierAnchorUsersRequest,
): Promise<Pageable<AnchorUserVOModel[]>> => {
  const response = await http.get<Pageable<AnchorUserVO[]>>({
    url: API_FI.ANCHOR_USERS.ANCHOR_USER_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(anchorUserVOData => formattingToAnchorUserVOModel(anchorUserVOData)),
  };
};

export const requestFinancierAnchorUserDetail = async (anchorUserId: number): Promise<AnchorUserVOModel> => {
  const response = await http.get<AnchorUserVO>({
    url: API_FI.ANCHOR_USERS.ANCHOR_USER_DETAIL(anchorUserId),
  });

  return formattingToAnchorUserVOModel(response);
};

export const requestInviteAnchorUser = async (anchorUserId: number): Promise<void> => {
  await http.post({
    url: API_FI.ANCHOR_USERS.INVITE_ANCHOR_USER(anchorUserId),
  });

  return;
};

export const requestFinancierAnchorUserInviteUrl = async (anchorUserId: number): Promise<string | null> => {
  const response = await http.get<string | null>({
    url: API_FI.ANCHOR_USERS.ANCHOR_USER_INVITE_URL(anchorUserId),
  });

  return response;
};

export async function requestFiRelatedAnchorAgreement(
  pageNumber: number,
  rowCount: number,
  anchorUserId: number,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_FI.ANCHOR_USERS.ANCHOR_AGREEMENT_RELATED_ANCHOR_USER(anchorUserId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  const anchorAgreementList = response.content.map(data => formattingToAnchorAgreementVOModel(data));

  return {
    ...response,
    content: anchorAgreementList,
  };
}

export async function requestFiRelatedDealerAgreement(
  pageNumber: number,
  rowCount: number,
  anchorUserId: number,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_FI.ANCHOR_USERS.DEALER_AGREEMENT_RELATED_ANCHOR_USER(anchorUserId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  const dealerAgreementList = response.content.map(data => formattingToDealerAgreementVOModel(data));

  return {
    ...response,
    content: dealerAgreementList,
  };
}

export async function requestFinancierAnchorUserEmailSettingList(
  anchorUserId: number,
): Promise<UserEmailSettingVOModel[]> {
  const response = await http.get<UserEmailSettingVO[]>({
    url: API_FI.ANCHOR_USERS.RELATED_USER_EMAIL_SETTING(anchorUserId),
  });

  return response.map(item => formattingToUserEmailSettingVOModel(item));
}

export async function requestFinancierAnchorUserEmailSettingUpdate(
  anchorUserId: number,
  data: FinancierAnchorUserEmailSettingUpdateRequest,
): Promise<UserEmailSettingVOModel[]> {
  const response = await http.put<UserEmailSettingVO[]>({
    url: API_FI.ANCHOR_USERS.RELATED_USER_EMAIL_SETTING(anchorUserId),
    data,
  });

  return response.map(item => formattingToUserEmailSettingVOModel(item));
}
