import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemTermsOfUsesDownloadRequest } from './requests';

export async function requestSystemTOUVersionUpdate(termsOfUseVersionId: number, activated: boolean): Promise<void> {
  return await http.put<void>({
    url: API_SY.TERMS_OF_USE_VERSIONS.TERMS_OF_USE_VERSIONS_ID(termsOfUseVersionId),
    data: {
      activated,
    },
  });
}

export async function requestSystemTOUVersionDownload(
  termsOfUseVersionId: number,
  data: SystemTermsOfUsesDownloadRequest,
) {
  return await http.download(API_SY.TERMS_OF_USE_VERSIONS.DOWNLOAD(termsOfUseVersionId), data);
}
