import type Pageable from 'models/Pageable';
import type { AnchorLoanDetailVO, AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import { formattingToAnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import { formattingToAnchorLoanVOModel } from 'models/vo/AnchorLoanVO';
import type { AnchorLoanVO, AnchorLoanVOModel } from 'models/vo/AnchorLoanVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AcAnchorLoanListRequest } from './request';

export async function requestAcAnchorLoanList(
  pageNumber: number,
  rowCount: number,
  searchValue?: AcAnchorLoanListRequest,
): Promise<Pageable<AnchorLoanVOModel[]>> {
  const response = await http.get<Pageable<AnchorLoanVO[]>>({
    url: API_AC.ANCHOR_LOANS.LOANS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorLoanVOModel(data)),
  };
}

export async function requestAcAnchorLoanDetail(anchorLoanId: number): Promise<AnchorLoanDetailVOModel> {
  const response = await http.get<AnchorLoanDetailVO>({
    url: API_AC.ANCHOR_LOANS.LOAN(anchorLoanId),
  });

  return formattingToAnchorLoanDetailVOModel(response);
}
