import {
  requestDealerDownloadDebtSellingNotification,
  requestDealerDownloadLoanAgreement,
  requestDealerDownloadLoanRequestForm,
} from 'utils/http/api/dealer/loans';
import useModal from 'utils/modal/useModal';

export function useDealerFinancingDetailFinancingRequestInformationLogics(loanId: number) {
  const modal = useModal();

  // 건별 대출 약정서, 채권 양도 계약서 다운로드 호출
  const handleDownloadLoanAgreementClick = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerDownloadLoanAgreement(loanId);
    } catch (e: any) {
      modal.show(e);
    }
  };

  // 대출 신청서 다운로드 호출
  const handleDownloadLoanRequestFormClick = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerDownloadLoanRequestForm(loanId);
    } catch (e: any) {
      modal.show(e);
    }
  };

  // 채권 양도 통지서 다운로드 호출
  const handleDownloadDebtSellingNotificationClick = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerDownloadDebtSellingNotification(loanId);
    } catch (e: any) {
      modal.show(e);
    }
  };

  return {
    handleDownloadLoanAgreementClick,
    handleDownloadLoanRequestFormClick,
    handleDownloadDebtSellingNotificationClick,
  };
}
