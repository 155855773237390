import type { CURRENCY_TYPE } from 'enums';

export interface ArCommissionSettlementVO {
  settlementDate: string;
  anchorName: string;
  partnerName: string;
  currencyType: CURRENCY_TYPE;
  totalArCount: number;
  totalArAmount: number;
  totalCollateralizedAmount: number;
  totalArCommissionAmount: number;
  dealerAgreementId: number;
  arSettlementAscended: boolean;
}

export interface ArCommissionSettlementVOModel extends ArCommissionSettlementVO {}

export function formattingToArCommissionSettlementsVOModel(
  data: ArCommissionSettlementVO,
): ArCommissionSettlementVOModel {
  return {
    ...data,
  };
}
