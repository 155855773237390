import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';

import useRegistrationDetailLoadAgreementController from './useRegistrationDetailLoadAgreementController';
import useRegistrationLoadAgreementController from './useRegistrationLoadAgreementController';
import useWaitingDetailLoadAgreementController from './useWaitingDetailLoadAgreementController';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import { PAGE_TYPE } from '../../../models/extraInformation/Context';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useLoadAgreementController = () => {
  const { t } = useTranslation(['format']);
  const { getControllerByPageType, pageType } = useExtraInformationViewModel();
  const { isSearchedAgreement, isFirstRegisteredWaitingAgreement } = useAgreementViewModel();
  const { isEditable } = useFormContext();

  const isSearchAgreementTextVisible = pageType === PAGE_TYPE.REGISTER || isFirstRegisteredWaitingAgreement;

  const loadAgreementDescription = isSearchedAgreement
    ? t('text:The_Partner_Master_Agreement_information_has_been_retrieved_from_the_financier_system_successfully')
    : isFirstRegisteredWaitingAgreement
    ? t(
        'text:Click_on_the_Search_Agreement_button_to_retrieve_the_Partner_Master_Agreement_information_from_the_financier_s_system',
      )
    : t(
        'text:Click_on_the_Update_Agreement_button_to_retrieve_the_Partner_Master_Agreement_information_from_the_financier_s_system',
      );

  const controller = getControllerByPageType({
    register: useRegistrationLoadAgreementController,
    registeredDetail: useRegistrationDetailLoadAgreementController,
    waitingDetail: useWaitingDetailLoadAgreementController,
  });

  return { ...controller, loadAgreementDescription, isEditable, isSearchAgreementTextVisible };
};

export default useLoadAgreementController;
