import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import useMounted from 'hooks/useMounted';
import { getWaitingForApprovalCount } from 'utils/logic';
import useModal from 'utils/modal/useModal';

import SystemMonitorAnchorAgreementRegisteredTab from './registered-tab/SystemMonitorAnchorAgreementRegisteredTab';
import { useSystemMonitorAnchorAgreementListState } from './useSystemMonitorAnchorAgreementListState';
import SystemMonitorAnchorAgreementWaitingTab from './waiting-tab/SystemMonitorAnchorAgreementWaitingTab';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);

  return {
    mounted,
    modal,
    history,
    t,
  };
};

function SystemMonitorAnchorAgreementList(): JSX.Element {
  const { mounted, t } = getConstants();
  const {
    fetchAll,
    state,
    onClickSearchWaiting,
    waitingForApprovalAnchorAgreementPaginate,
    waitingForApprovalAnchorAgreementPageable,
    systemWaitingAnchorAgreementListForm,
    onClickSearchRegistered,
    registrationCompletedAnchorAgreementPaginate,
    registrationCompletedAnchorAgreementPageable,
    systemRegisteredAnchorAgreementListForm,
  } = useSystemMonitorAnchorAgreementListState();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  return (
    <>
      <HeaderTitle title={t('text:Anchor_Master_Agreement')} />
      <Tab
        initialActiveTabIndex={1}
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              systemWaitingAnchorAgreementListForm.getValues('approvalTypes'),
              state.notCancelledWaitingForApprovalAnchorAgreementPage?.totalElements,
            ),
          },
          {
            tabItemCount: registrationCompletedAnchorAgreementPageable.totalRows ?? 0,
          },
        ]}
        tabViewList={[
          <SystemMonitorAnchorAgreementWaitingTab
            key="waiting" // for key props error
            waitingForApprovalAnchorAgreementListData={state.waitingForApprovalAnchorAgreementPage}
            useForm={systemWaitingAnchorAgreementListForm}
            onClickSearchWaiting={onClickSearchWaiting}
            waitingForApprovalAnchorAgreementPaginate={waitingForApprovalAnchorAgreementPaginate}
            waitingForApprovalAnchorAgreementPageable={waitingForApprovalAnchorAgreementPageable}
          />,
          <SystemMonitorAnchorAgreementRegisteredTab
            key="registered"
            registrationCompletedAnchorAgreementData={state.registrationCompletedAnchorAgreementPage}
            useForm={systemRegisteredAnchorAgreementListForm}
            onClickSearchRegistered={onClickSearchRegistered}
            registrationCompletedAnchorAgreementPaginate={registrationCompletedAnchorAgreementPaginate}
            registrationCompletedAnchorAgreementPageable={registrationCompletedAnchorAgreementPageable}
          />,
        ]}
      />
    </>
  );
}

export default SystemMonitorAnchorAgreementList;
