import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import Button from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import type { COLLATERAL_TYPE } from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import useModal from 'utils/modal/useModal';
import { getProgramTypeText } from 'utils/text';

import useSearchInterfaceByContactNoModalController from './useSearchInterfaceByContactNoModalController';

interface SearchInterfaceByContactNoModalProps {
  modalId: number;
  collateralType: COLLATERAL_TYPE;
  getInterfaceData(data?: DealerAgreementDetailVOModel): void;
}

const SearchInterfaceByContactNoModal = ({
  modalId,
  collateralType,
  getInterfaceData,
}: SearchInterfaceByContactNoModalProps) => {
  const { t } = useTranslation(['format']);
  const { disableConfirmBtn } = useModal();

  const {
    errorMessage,
    handleSearchButtonClick,
    handleContactNoInputChange,
    searchedInterfaceData,
    contractNo,
    contractNoLengthText,
    basisInterestTypeText,
  } = useSearchInterfaceByContactNoModalController(collateralType);

  useEffect(() => {
    getInterfaceData(searchedInterfaceData);
  }, [getInterfaceData, searchedInterfaceData]);

  useEffect(() => {
    disableConfirmBtn(modalId, isEmpty(searchedInterfaceData));
  }, [disableConfirmBtn, modalId, searchedInterfaceData]);

  const {
    contractNo: partnerAgreementNumber,
    currencyType,
    dealerFinancierClientCode,
    dealerFinancierClientName,
    dealerFinancierClientTaxCode,
    startDate,
    expiryDate,
    loanLimitAmount,
    basisInterestType,
    branchCode,
    branchName,
  } = searchedInterfaceData ?? {};

  return (
    <>
      <form data-testid="formId">
        <div className="d-flex m-2">
          <div className="me-3">
            <label className="information-form__label">{t('text:Program_Type')}</label>
            <div className="information-form__input w-100">{getProgramTypeText(collateralType)}</div>
          </div>
          <div>
            <label className="information-form__label">
              {t('text:Please_enter_the_agreement_number_to_retrieve_the_agreement_information')}
            </label>
            <div className="d-flex">
              <input
                className="information-form__input bg-sub100 w-100"
                type="text"
                onChange={handleContactNoInputChange}
                value={contractNo || ''}
                data-testid="searchContractNoInput"
              />
              <Button onClick={handleSearchButtonClick}>{t('text:Search')}</Button>
            </div>
            <div className="text-bold-brick-red m-1 mb-0">
              {errorMessage !== '' ? errorMessage : contractNoLengthText}
            </div>
          </div>
        </div>
      </form>
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormValue
              className="information-form__input"
              label={t('text:Partner_Master_Agreement_Number')}
              value={partnerAgreementNumber}
            />
            <FormValue className="information-form__input" label={t('text:Currency')} value={currencyType} />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input"
              label={t('text:Client_Code')}
              value={dealerFinancierClientCode}
            />
            <FormValue
              col={3}
              className="information-form__input"
              label={t('text:Partner_Name')}
              value={dealerFinancierClientName}
            />
            <FormValue
              col={3}
              className="information-form__input"
              label={t('text:Tax_Code')}
              value={dealerFinancierClientTaxCode}
            />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input"
              label={t('text:Effective_Date')}
              value={startDate}
              format="date"
            />
            <FormValue
              className="information-form__input"
              label={t('text:Expiration_Date')}
              value={expiryDate}
              format="date"
            />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input"
              label={t('text:Financing_Limit')}
              value={loanLimitAmount}
              format="number"
            />
            <FormValue
              className="information-form__input"
              label={t('text:Financing_Interest')}
              value={basisInterestType ? basisInterestTypeText : '-'}
            />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input"
              label={t('text:Responsible_Branch_Code')}
              value={branchCode}
            />
            <FormValue
              className="information-form__input"
              label={t('text:Responsible_Branch_Name')}
              value={branchName}
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
};

export default SearchInterfaceByContactNoModal;
