import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorReportSettingVOModel } from 'models/vo/AnchorReportSettingVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemAnchorReportSettingList } from 'utils/http/api/system/anchor-report-settings';
import type { SystemAnchorReportSettingListRequest } from 'utils/http/api/system/anchor-report-settings/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();

  const { t } = useTranslation(['format']);
  const SEARCHED_ANCHOR_REPORT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Agreement_Suspension'),
    },
    {
      headerText: t('text:Report_Type'),
    },
    {
      headerText: t('text:Report_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];
  const SY_ANCHOR_REPORT_LIST_QS_KEY = 'sy-anchor-report-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_ANCHOR_REPORT_TABLE_HEADERS,
    SY_ANCHOR_REPORT_LIST_QS_KEY,
  };
};

function SystemMonitorAnchorReportList(): JSX.Element {
  const { mounted, modal, t, SEARCHED_ANCHOR_REPORT_TABLE_HEADERS, SY_ANCHOR_REPORT_LIST_QS_KEY } = getConstants();
  const [anchorReportSettingPage, setAnchorReportSettingPage] = useState<Pageable<AnchorReportSettingVOModel[]>>();

  const { pageable: anchorReportSettingPageable, setPageable: setAnchorReportSettingPageable } =
    usePageable(SY_ANCHOR_REPORT_LIST_QS_KEY);
  const {
    register: anchorReportSettingSearchFormRegister,
    getValues: getAnchorReportSettingSearchFormValues,
    reset: resetAnchorReportSettingSearchForm,
    setValue: setAnchorReportSettingSearchFormValue,
  } = useForm<SystemAnchorReportSettingListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemAnchorReportSettingListRequest>(
        setAnchorReportSettingSearchFormValue,
        getParsedSearchParams(SY_ANCHOR_REPORT_LIST_QS_KEY).formSearchData,
      );
      fetchAnchorReportList(
        anchorReportSettingPageable.currentPage,
        anchorReportSettingPageable.sizePerPage,
        getAnchorReportSettingSearchFormValues(),
      );
    }
  }, [mounted]);

  const fetchAnchorReportList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemAnchorReportSettingListRequest,
  ): Promise<void> => {
    try {
      const anchorReportSettingPage = await requestSystemAnchorReportSettingList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_ANCHOR_REPORT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorReportSettingPage(anchorReportSettingPage);
        setAnchorReportSettingPageable(anchorReportSettingPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    resetAnchorReportSettingSearchForm();
  };

  const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    fetchAnchorReportList(1, anchorReportSettingPageable.sizePerPage, getAnchorReportSettingSearchFormValues());
  };

  const anchorReportSettingPaginate = (pageNumber: number, rowCount: number): void => {
    fetchAnchorReportList(pageNumber, rowCount, getParsedSearchParams(SY_ANCHOR_REPORT_LIST_QS_KEY).formSearchData);
  };

  const renderSearchedAnchorReportPageTableBody = (): JSX.Element[] | undefined => {
    const getReportStatusText = (isActivated: boolean): string => {
      switch (isActivated) {
        case true:
          return t('text:Active');
        case false:
          return t('text:Deactivated');
        default:
          return '';
      }
    };

    const getReportStatusClassName = (isActivated: boolean): string => {
      switch (isActivated) {
        case true:
          return 'text-bold-blue-main600';
        case false:
          return 'text-bold-brick-red';
        default:
          return '';
      }
    };

    return anchorReportSettingPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.financierName} />
        <Td data={item.anchorName} />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
        <Td data={t(`code:anchor-agreement-status.${item.agreementStatus}`)} format="code" />
        <Td data={t(`code:report-type.${item.reportType}`)} format="code" />
        <Td className={getReportStatusClassName(item.activated)} data={getReportStatusText(item.activated)} />
        <TdLink
          path={ROUTES_SY.MONITOR_ANCHOR.REPORT_DETAIL_BUILD_PATH(item.anchorReportSettingId)}
          state={{ anchorAgreementId: item.anchorAgreementId, reportType: item.reportType }}
        />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Anchor_Report')} />
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={anchorReportSettingSearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorName" ref={anchorReportSettingSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name="contractNo" ref={anchorReportSettingSearchFormRegister} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                ref={anchorReportSettingSearchFormRegister}
                selectOptions={getSelectOptions('CURRENCY_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Agreement_Suspension')} />
              <SearchSelect
                name="agreementStatus"
                ref={anchorReportSettingSearchFormRegister}
                selectOptions={getSelectOptions('ANCHOR_AGREEMENT_STATUS', 'ALL', true)}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                ref={anchorReportSettingSearchFormRegister}
                selectOptions={getSelectOptions('COLLATERAL_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Report_Type')} />
              <SearchSelect
                name="reportType"
                ref={anchorReportSettingSearchFormRegister}
                selectOptions={getSelectOptions('REPORT_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Send_Report_Status')} />
              <SearchSelect
                name="activated"
                ref={anchorReportSettingSearchFormRegister}
                selectOptions={getSelectOptions('ACTIVE_STATUS', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {anchorReportSettingPageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_ANCHOR_REPORT_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_ANCHOR_REPORT_TABLE_HEADERS.length}>
            {renderSearchedAnchorReportPageTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={anchorReportSettingPageable} paginate={anchorReportSettingPaginate} />
      </div>
    </>
  );
}

export default SystemMonitorAnchorReportList;
