import type Pageable from 'models/Pageable';
import type { SuccessInvoiceDetailVO, SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { formattingToSuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import type {
  SuccessInvoiceForRequestMultipleAnchorLoanVO,
  SuccessInvoiceForRequestMultipleAnchorLoanVOModel,
} from 'models/vo/SuccessInvoiceForRequestMultipleAnchorLoanVO';
import { formattingToSuccessInvoiceForRequestMultipleAnchorLoanVOModel } from 'models/vo/SuccessInvoiceForRequestMultipleAnchorLoanVO';
import type { SuccessInvoiceVO, SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import { formattingToSuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';
import type {
  AnchorInvoiceDeleteRequest,
  AnchorSuccessInvoiceListRequest,
} from 'utils/http/api/anchor/success-invoices/requests';

// 송장 목록 조회
export async function requestAnchorSuccessInvoiceList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorSuccessInvoiceListRequest,
): Promise<Pageable<SuccessInvoiceVOModel[]>> {
  const response = await http.get<Pageable<SuccessInvoiceVO[]>>({
    url: API_AC.SUCCESS_INVOICES.SUCCESS_INVOICE,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const successInvoiceList: SuccessInvoiceVOModel[] = response.content.map((data: SuccessInvoiceVO) =>
    formattingToSuccessInvoiceVOModel(data),
  );
  const successInvoicePage: Pageable<SuccessInvoiceVOModel[]> = {
    ...response,
    content: successInvoiceList,
  };

  return successInvoicePage;
}

// 송장 단건 조회
export async function requestAnchorSuccessInvoiceDetail(
  successInvoiceId: number,
): Promise<SuccessInvoiceDetailVOModel> {
  const response = await http.get<SuccessInvoiceDetailVO>({
    url: API_AC.SUCCESS_INVOICES.SUCCESS_INVOICE_DETAIL(successInvoiceId),
  });

  return formattingToSuccessInvoiceDetailVOModel(response);
}

// 송장 삭제
export async function requestAnchorSuccessInvoiceDelete(data: AnchorInvoiceDeleteRequest) {
  const response = await http.delete({
    url: API_AC.SUCCESS_INVOICES.SUCCESS_INVOICE,
    data,
  });

  return response;
}

// 송장 원본 다운로드
export async function requestAnchorSuccessInvoiceDownload(successInvoiceId: number) {
  await http.download(API_AC.SUCCESS_INVOICES.DOWNLOAD_SUCCESS_INVOICE(successInvoiceId));
}

export async function requestAnchorSuccessInvoiceListForMultipleRequestLoans(
  pageNumber: number,
  rowCount: number,
  anchorAgreementId: number,
): Promise<Pageable<SuccessInvoiceForRequestMultipleAnchorLoanVOModel[]>> {
  const response = await http.get<Pageable<SuccessInvoiceForRequestMultipleAnchorLoanVO[]>>({
    url: API_AC.SUCCESS_INVOICES.LIST_FOR_MULTIPLE_REQUEST_ANCHOR_LOANS,
    data: {
      pageNumber,
      rowCount,
      anchorAgreementId,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToSuccessInvoiceForRequestMultipleAnchorLoanVOModel(item)),
  };
}
