import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { ArCommissionSettingDetailVOModel } from 'models/vo/ArCommissionSettingDetailVO';
import {
  requestFiArCommissionSettingsDetail,
  requestFiArCommissionSettingsUpdateCommissionSpread,
  requestFiArCommissionSettingsUpdateStatus,
} from 'utils/http/api/financier/ar-commission-settings';
import type { UpdateFiArCommissionSpreadDTO } from 'utils/http/api/financier/ar-commission-settings/requests';
import type { CommissionSpreadType } from 'utils/http/api/financier/ar-commission-settings/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import { PROGRAM_CHARGE_RATE_FIELD_NAME } from '../sections/ProgramChargeRateForm';

const useFinancierProgramChargeDetail = () => {
  const { arCommissionSettingId } = useParams<any>();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const methods = useForm<UpdateFiArCommissionSpreadDTO>();

  const [programChargeDetail, setProgramChargeDetail] = useState<ArCommissionSettingDetailVOModel>();
  const [editable, setEditable] = useState<boolean>(false);

  useEffect(() => {
    if (mounted) {
      fetchFiProgramChargeDetail();
    }
  }, [mounted]);

  const resetData = (data: CommissionSpreadType[]) => {
    methods.reset({ [PROGRAM_CHARGE_RATE_FIELD_NAME]: data });
  };

  const fetchFiProgramChargeDetail = async () => {
    try {
      const response = await requestFiArCommissionSettingsDetail(Number(arCommissionSettingId));
      ReactDOM.unstable_batchedUpdates(() => {
        setProgramChargeDetail(response);
        resetData(response.commissionSpreads);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const handleClickEditButton = () => {
    setEditable(true);
  };

  const handleClickCancelButton = () => {
    modal.show(<h6>{t('text:Would_you_like_to_cancel_this_process?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: () => {
        setEditable(false);
        if (programChargeDetail?.commissionSpreads) resetData(programChargeDetail?.commissionSpreads);
      },
    });
  };

  const handleClickSaveButton = async () => {
    const data: UpdateFiArCommissionSpreadDTO = methods.getValues();

    try {
      await requestFiArCommissionSettingsUpdateCommissionSpread(arCommissionSettingId, data);

      modal.show(t('text:The_Transaction_Fee_has_been_edited_successfully'), {
        closeBtnCb: () => {
          setEditable(false);
          fetchFiProgramChargeDetail();
        },
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const handleClickActivateButton = () => {
    const requestActivate = async () => {
      try {
        await requestFiArCommissionSettingsUpdateStatus(arCommissionSettingId, true);

        modal.show(<h6>{t('text:The_Transaction_Fee_has_been_activated')}</h6>, {
          closeBtnCb: () => {
            fetchFiProgramChargeDetail();
          },
        });
      } catch (e) {
        modal.show(e);
      }
    };

    modal.show(t('text:Would_you_like_to_activate_the_Transaction_Fee?'), {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: async () => {
        await requestActivate();
      },
    });
  };

  const handleClickDeactivateButton = () => {
    const requestDeactivate = async () => {
      try {
        await requestFiArCommissionSettingsUpdateStatus(arCommissionSettingId, false);

        modal.show(
          <h6>
            {t('text:The_Transaction_Fee_has_been_deactivated')} <br />
            {t('text:To_reactivate_click_the_Activate_button')}
          </h6>,
          {
            closeBtnCb: () => {
              fetchFiProgramChargeDetail();
            },
          },
        );
      } catch (e) {
        modal.show(e);
      }
    };

    modal.show(t('text:Would_you_like_to_deactivate_the_Transaction_Fee?'), {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: async () => {
        await requestDeactivate();
      },
    });
  };

  return {
    programChargeDetail,
    isAdmin,
    editable,
    methods,
    handleClickEditButton,
    handleClickCancelButton,
    handleClickSaveButton,
    handleClickActivateButton,
    handleClickDeactivateButton,
  };
};

export default useFinancierProgramChargeDetail;
