import { useTranslation } from 'react-i18next';

import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PPSubTitle from 'components/potential-partner/PPSubTitle/PPSubTitle';
import PPTableBorder from 'components/potential-partner/PPTable/PPTableBorder';
import type { PPHeaderType } from 'components/potential-partner/PPTable/PPTableHeader';
import PPTableHeader from 'components/potential-partner/PPTable/PPTableHeader';
import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import TableBody from 'components/stateless/Table/TableBody';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import { requestAnFinancierPotentialPartnerAcquisitionDocumentDownload } from 'utils/http/api/anonymous/potential-partner-acquisition-documents';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';

interface PotentialPartnerFinancierDetailRequiredDocumentListProps {
  documentList: PotentialPartnerAcquisitionDocumentVOModel[];
}

function PotentialPartnerFinancierDetailRequiredDocumentList({
  documentList,
}: PotentialPartnerFinancierDetailRequiredDocumentListProps) {
  const { t } = useTranslation();
  const modal = useModal();
  const emailCode = getPotentialPartnerEmailCode();

  const DOCUMENT_LISTS_HEADER: PPHeaderType[] = [
    {
      headerText: t('text:Document_Name'),
      ratio: 70,
    },
    {
      headerText: t('text:Template'),
      ratio: 30,
    },
  ];

  const onClickDownload = async (potentialPartnerAcquisitionDocumentSettingId: number) => {
    if (!emailCode) return modal.show('not found email code');
    try {
      await requestAnFinancierPotentialPartnerAcquisitionDocumentDownload(
        potentialPartnerAcquisitionDocumentSettingId,
        emailCode,
      );
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <div className="pp-mb-56">
      <PPSubTitle title={t('text:Required_Documents')} />
      <div className="pp-mb-16">
        <SectionMessage
          message={[t('text:Please_prepare_these_documents_and_attach_them_to_your_application_when_submitting')]}
        />
      </div>
      <PPTableBorder>
        <PPTableHeader headers={DOCUMENT_LISTS_HEADER} />
        <TableBody numOfCol={DOCUMENT_LISTS_HEADER.length}>
          {documentList?.map((item: PotentialPartnerAcquisitionDocumentVOModel, index: number) => (
            <Tr key={index}>
              <Td>
                <div className="pp-table-td__label">{DOCUMENT_LISTS_HEADER[0].headerText}</div>
                <div className="pp-table-td__data">
                  <div className="text-bold">{item.documentName}</div>
                  <div>{item.description}</div>
                </div>
              </Td>
              <Td>
                <div className="pp-table-td__label">{DOCUMENT_LISTS_HEADER[1].headerText}</div>
                <div className="pp-table-td__data">
                  <Button2
                    size={Button2SizeEnum.SM}
                    onClick={async () => {
                      await onClickDownload(item.potentialPartnerAcquisitionDocumentSettingId);
                    }}
                    color={Button2ColorEnum.TERTIARY}
                    icon={<FontAwesomeIcon icon={faFileArrowDown} fontSize={20} />}
                    disabled={!item.attachmentName}
                  >
                    {t('text:Download')}
                  </Button2>
                </div>
              </Td>
            </Tr>
          ))}
        </TableBody>
      </PPTableBorder>
    </div>
  );
}

export default PotentialPartnerFinancierDetailRequiredDocumentList;
