import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import {
  requestSystemAnchorAgreementsRelatedAnchorUser,
  requestSystemAnchorUserData,
  requestSystemDealerAgreementsRelatedAnchorUser,
} from 'utils/http/api/system/anchor-users';
import { requestSystemWaitingAnchorUserList } from 'utils/http/api/system/waiting-anchor-users';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);
  const RELATED_ANCHOR_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
    },
    {
      headerText: t('text:Responsible_Branch_Code'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Expiration_Date'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];
  const RELATED_DEALER_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
    },
    {
      headerText: t('text:Responsible_Branch_Code'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Expiration_Date'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];
  const EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
    },
    {
      headerText: t('text:User_Code'),
    },
    {
      headerText: t('text:User_Name'),
    },
    {
      headerText: t('text:Mobile'),
    },
    {
      headerText: t('text:Email'),
    },
    {
      headerText: t('text:Authority'),
    },
    {
      headerText: t('text:User_Account_Status'),
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
    },
  ];

  return {
    mounted,
    modal,
    history,
    t,
    RELATED_ANCHOR_AGREEMENT_TABLE_HEADERS,
    RELATED_DEALER_AGREEMENT_TABLE_HEADERS,
    EDIT_HISTORY_TABLE_HEADERS,
  };
};

function SystemMonitorAnchorUserRegisteredDetail(): JSX.Element {
  const {
    mounted,
    modal,
    history,
    t,
    RELATED_ANCHOR_AGREEMENT_TABLE_HEADERS,
    RELATED_DEALER_AGREEMENT_TABLE_HEADERS,
    EDIT_HISTORY_TABLE_HEADERS,
  } = getConstants();
  const { anchorUserId } = useParams() as any;

  const [anchorUserData, setAnchorUserData] = useState<AnchorUserVOModel>();
  const [anchorAgreementPage, setAnchorAgreementPage] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const [dealerAgreementPage, setDealerAgreementPage] = useState<Pageable<DealerAgreementVOModel[]>>();
  const [waitingAnchorUserPage, setWaitingAnchorUserPage] = useState<Pageable<WaitingAnchorUserVOModel[]>>();
  const { pageable: anchorAgreementPageable, setPageable: setAnchorAgreementPageable } = usePageable();
  const { pageable: dealerAgreementPageable, setPageable: setDealerAgreementPageable } = usePageable();
  const { pageable: waitingAnchorUserPageable, setPageable: setWaitingAnchorUserPageable } = usePageable();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [anchorUserData, anchorAgreementPage, dealerAgreementPage] = await Promise.all([
        requestSystemAnchorUserData(anchorUserId),
        requestSystemAnchorAgreementsRelatedAnchorUser(
          anchorAgreementPageable.currentPage,
          anchorAgreementPageable.sizePerPage,
          anchorUserId,
        ),
        requestSystemDealerAgreementsRelatedAnchorUser(
          dealerAgreementPageable.currentPage,
          dealerAgreementPageable.sizePerPage,
          anchorUserId,
        ),
      ]);

      const waitingAnchorUserPage = await requestSystemWaitingAnchorUserList(
        waitingAnchorUserPageable.currentPage,
        waitingAnchorUserPageable.sizePerPage,
        { targetAnchorUserId: anchorUserId },
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorUserData(anchorUserData);
        setAnchorAgreementPage(anchorAgreementPage);
        setDealerAgreementPage(dealerAgreementPage);
        setWaitingAnchorUserPage(waitingAnchorUserPage);
        setAnchorAgreementPageable(anchorAgreementPage);
        setDealerAgreementPageable(dealerAgreementPage);
        setWaitingAnchorUserPageable(waitingAnchorUserPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const anchorAgreementPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const anchorAgreementPage = await requestSystemAnchorAgreementsRelatedAnchorUser(
        pageNumber,
        rowCount,
        anchorUserId,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementPage(anchorAgreementPage);
        setAnchorAgreementPageable(anchorAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const dealerAgreementPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const dealerAgreementPage = await requestSystemDealerAgreementsRelatedAnchorUser(
        pageNumber,
        rowCount,
        anchorUserId,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementPage(dealerAgreementPage);
        setDealerAgreementPageable(dealerAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const waitingAnchorUserPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const waitingAnchorUserPage = await requestSystemWaitingAnchorUserList(pageNumber, rowCount, {
        targetAnchorUserId: anchorUserId,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorUserPage(waitingAnchorUserPage);
        setWaitingAnchorUserPageable(waitingAnchorUserPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getAuthorityTypeText = (authorityType: AUTHORITY_TYPE): string => {
    switch (authorityType) {
      case AUTHORITY_TYPE.ADMIN:
        return t('text:Admin');
      case AUTHORITY_TYPE.HQ_OPERATOR:
        return t('text:Admin_Operator');
      case AUTHORITY_TYPE.AUTHORIZER:
        return t('text:Authorizer');
      case AUTHORITY_TYPE.OPERATOR:
        return t('text:Operator');
      default:
        return '';
    }
  };

  const onClickRegistrationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderRelatedAnchorAgreementTableBody = (): JSX.Element[] | undefined => {
    return anchorAgreementPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={item.branchName} />
        <Td data={item.branchCode} />
        <Td data={item.startDate} format="date" />
        <Td data={item.expiryDate} format="date" />
        <TdLink
          path={ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.anchorAgreementId)}
          state={{ supportedCollateralType: item.collateralType }}
        />
      </Tr>
    ));
  };

  const renderRelatedDealerAgreementTableBody = (): JSX.Element[] | undefined => {
    return dealerAgreementPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={item.branchName} />
        <Td data={item.branchCode} />
        <Td data={item.startDate} format="date" />
        <Td data={item.expiryDate} format="date" />
        <TdLink
          path={ROUTES_SY.MONITOR_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.dealerAgreementId)}
          state={{
            dealerFinancierClientId: item.dealerFinancierClientId,
            supportedCollateralType: item.collateralType,
          }}
        />
      </Tr>
    ));
  };

  const renderEditHistoryTableBody = (): JSX.Element[] | undefined => {
    return waitingAnchorUserPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.updatedDateTime} format="datetime" />
        <Td data={item.bankUserId} />
        <Td data={item.name} />
        <Td data={item.mobile} />
        <Td data={item.email} />
        <Td data={getAuthorityTypeText(item.authorityType)} />
        <Td data={t(`code:user-status.${item.userStatus}`)} />
        <Td data={`${item.operatorUserLoginId} / ${item.operatorUserName}`} />
        <Td
          data={
            item.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL ||
            item.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST
              ? '-'
              : `${item.authorizerUserLoginId} / ${item.authorizerUserName}`
          }
        />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_User_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Anchor_User_Information')}>
          <div className="flex-end align-items-center">
            <span className="me-2">{t('text:Go_to_Company_Details')}</span>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              className="flex-column-center"
              onClick={() => {
                history.push(ROUTES_SY.MONITOR_ANCHOR.COMPANY_DETAIL_BUILD_PATH(anchorUserData?.anchorClientId!));
              }}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </SectionTitle>
        <FormBorder>
          <FormSubtitle title={t('text:ANCHOR_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Client_Code')} value={anchorUserData?.anchorClientCode} />
              <FormValue label={t('text:Anchor_Name')} value={anchorUserData?.anchorClientName} />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:ANCHOR_USER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:User_Code')} value={anchorUserData?.bankUserId} />
              <FormValue label={t('text:User_Name')} value={anchorUserData?.name} />
            </div>
            <div className="row">
              <FormValue label={t('text:Mobile')} value={anchorUserData?.mobile} />
              <FormValue label={t('text:Email')} value={anchorUserData?.email} />
            </div>
            <div className="row">
              <FormValue label={t('text:OTP_Number')} value={anchorUserData?.anchorUserOtpSerialNo} />
              <FormValue
                label={t('text:Authority')}
                value={anchorUserData ? getAuthorityTypeText(anchorUserData.authorityType) : undefined}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:User_Account_Status')}
                value={t(`code:user-status.${anchorUserData?.userStatus}`)}
                hasStatusDescription={true}
                className={anchorUserData ? getStatusTextClass('USER_STATUS', anchorUserData.userStatus) : ''}
                showStatusDescriptionFunc={onClickRegistrationStatus}
              />
              <FormValue label={t('text:Anchor_User_ID')} value={anchorUserData?.userLoginId} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Associated_Anchor_Master_Agreement_List')} />
        <TableBorder>
          <TableHeader header={RELATED_ANCHOR_AGREEMENT_TABLE_HEADERS} />
          <TableBody numOfCol={RELATED_ANCHOR_AGREEMENT_TABLE_HEADERS.length}>
            {renderRelatedAnchorAgreementTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={anchorAgreementPageable} paginate={anchorAgreementPaginate} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Associated_Partner_Master_Agreement_List')} />
        <TableBorder>
          <TableHeader header={RELATED_DEALER_AGREEMENT_TABLE_HEADERS} />
          <TableBody numOfCol={RELATED_DEALER_AGREEMENT_TABLE_HEADERS.length}>
            {renderRelatedDealerAgreementTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={dealerAgreementPageable} paginate={dealerAgreementPaginate} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={EDIT_HISTORY_TABLE_HEADERS} />
          <TableBody numOfCol={EDIT_HISTORY_TABLE_HEADERS.length}>{renderEditHistoryTableBody()}</TableBody>
        </TableBorder>
        <Pagination pageable={waitingAnchorUserPageable} paginate={waitingAnchorUserPaginate} />
      </div>
    </>
  );
}

export default SystemMonitorAnchorUserRegisteredDetail;
