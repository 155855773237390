import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';

import useFinancierProgramChargeDetail from './useFinancierProgramChargeDetail';
import ProgramChargeRateForm from '../sections/ProgramChargeRateForm';

function FinancierProgramChargeDetailPage() {
  const { t } = useTranslation(['format']);

  const {
    programChargeDetail,
    isAdmin,
    editable,
    methods,
    handleClickEditButton,
    handleClickCancelButton,
    handleClickSaveButton,
    handleClickActivateButton,
    handleClickDeactivateButton,
  } = useFinancierProgramChargeDetail();

  const isActive = programChargeDetail?.activated;

  const ActiveButton = () => {
    return (
      <Button
        size={ButtonSizeEnum.SM}
        variant={ButtonVariantEnum.OUTLINED}
        color={ButtonColorEnum.BLUE}
        onClick={handleClickActivateButton}
      >
        {t('text:Activate')}
      </Button>
    );
  };

  const DeactiveButton = () => {
    return (
      <Button
        size={ButtonSizeEnum.SM}
        variant={ButtonVariantEnum.OUTLINED}
        color={ButtonColorEnum.RED}
        onClick={handleClickDeactivateButton}
      >
        {t('text:Deactivate')}
      </Button>
    );
  };

  const EditButtons = () => {
    return (
      <>
        {editable ? (
          <>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={handleClickCancelButton}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={handleClickSaveButton}>
              {t('text:Save')}
            </Button>
          </>
        ) : (
          <Button size={ButtonSizeEnum.SM} onClick={handleClickEditButton}>
            {t('text:Edit')}
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Transaction_Fee_Setting_Details')} />
      <div className="content-area">
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Type')}
                value={t(`code:financier-setting-type.${programChargeDetail?.arCommissionSettingType}`)}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Master_Agreement_Number')}
                value={programChargeDetail?.agreementContractNo}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Currency')}
                value={programChargeDetail?.currencyType}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Company_Name')}
                value={programChargeDetail?.clientName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Company_Client_Code')}
                value={programChargeDetail?.clientCode}
              />
              <FormValue
                className={clsx('information-form__input', {
                  'text-bold-blue-main600': isActive,
                  'text-bold-brick-red': !isActive,
                })}
                label={t('text:Activate_Status')}
                value={isActive ? 'On' : 'Off'}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Timing_of_Transaction_Fee_being_charged')}
                value={t(`code:ar-commission-create-phase.${programChargeDetail?.arCommissionCreatePhase}`)}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <FormProvider {...methods}>
        <div className="content-area">
          <SectionTitle title={t('text:Transaction_Fee_Rate')}>
            {isAdmin && !isActive && <ActiveButton />}
            {isAdmin && isActive && <DeactiveButton />}
            {isAdmin && isActive && <EditButtons />}
          </SectionTitle>
          <ProgramChargeRateForm editable={editable} isOpacity={!isActive} />
        </div>
      </FormProvider>
    </>
  );
}

export default FinancierProgramChargeDetailPage;
