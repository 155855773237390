import type React from 'react';
import type { MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import { SUCCESS_INVOICE_STATUS } from 'enums';
import type { CURRENCY_TYPE, LOAN_STATUS, SETTLEMENT_METHOD_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestAnchorSuccessInvoiceList } from 'utils/http/api/anchor/success-invoices';
import type { AnchorSuccessInvoiceListRequest } from 'utils/http/api/anchor/success-invoices/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getBlockedStatusText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const INVOICE_DETAILS_MAX_COUNT = 10;

interface AnchorInvoiceListRegisteredTabProps {
  invoicePage: Pageable<SuccessInvoiceVOModel[]> | undefined;
  pageable: PageableType;
  paginate(page: number, sizePerPage: number): void;
  handleClickSearch: MouseEventHandler<HTMLButtonElement>;
}

function AnchorInvoiceListRegisteredTab({
  invoicePage,
  pageable,
  paginate,
  handleClickSearch,
}: AnchorInvoiceListRegisteredTabProps) {
  const { t } = useTranslation();
  const { register, reset, control, getValues } = useFormContext<AnchorSuccessInvoiceListRequest>();
  const modal = useModal();

  const onRemoveFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    reset({});
  };

  const onClickSuccessInvoiceStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_INVOICE_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="SUCCESS_INVOICE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const data = getValues();

    const excelInvoiceDetailsHeaders: ColumnOption<any>[] = Array(INVOICE_DETAILS_MAX_COUNT)
      .fill('')
      .map((_, index) => ({
        header: `Invoice Detail (${index + 1})`,
        key: `invoiceDetails${index + 1}`,
      }));

    const excelColumns: ColumnOption<SuccessInvoiceVOModel>[] = [
      {
        header: t('text:Registration_Date'),
        key: 'createdDateTime',
      },
      {
        header: t('text:BOE_Number'),
        key: 'billOfExchangeNo',
      },
      {
        header: t('text:Invoice_Number'),
        key: 'invoiceNumber',
      },
      {
        header: t('text:Invoice_Reference_Number'),
        key: 'referenceNumber',
      },
      {
        header: t('text:Partner_Name'),
        key: 'dealerClientName',
      },
      {
        header: t('text:Partner_Code_of_Anchor'),
        key: 'dealerCodeByAnchor',
      },
      {
        header: t('text:Tax_Code'),
        key: 'dealerTaxCode',
      },
      {
        header: t('text:Currency'),
        key: 'currencyType',
      },
      {
        header: t('text:Invoice_Amount'),
        key: 'invoiceAmount',
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Invoice_Issued_Date'),
        key: 'invoiceIssuedDate',
      },
      {
        header: t('text:Original_Payment_Date'),
        key: 'settlementDate',
      },
      {
        header: t('text:Financing_Eligibility'),
        key: 'blockedByFinancier',
      },
      {
        header: t('text:Settlement_Method'),
        key: 'settlementMethod',
      },
      {
        header: t('text:Financing_Status'),
        key: 'loanStatus',
      },
      {
        header: t('text:Payment_Status'),
        key: 'successInvoiceStatus',
      },
      ...excelInvoiceDetailsHeaders,
    ];

    try {
      const successInvoicePageResponse = await requestAnchorSuccessInvoiceList(0, EXCEL_EXPORT_MAX_ROW_COUNT, data);
      const successInvoicePDFPageResponse = successInvoicePageResponse.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const makeInvoiceDetailsObj = (index: number) => {
        const invoiceDetailsObj =
          successInvoicePageResponse?.content?.[index]?.invoiceAdditionalDataList?.reduce((acc, cur, curIndex) => {
            return { ...acc, [`invoiceDetails${curIndex + 1}`]: `${cur.name}: ${cur.value === '' ? '-' : cur.value}` };
          }, {}) ?? {};

        const invoiceDetailsObjLength = Object.keys(invoiceDetailsObj).length;

        const toFillTheEmptyObj = Array(INVOICE_DETAILS_MAX_COUNT - invoiceDetailsObjLength)
          .fill('')
          .reduce((acc, cur, curIndex) => {
            return { ...acc, [`invoiceDetails${invoiceDetailsObjLength + (curIndex + 1)}`]: '-' };
          }, {});

        return { ...invoiceDetailsObj, ...toFillTheEmptyObj };
      };

      const jsonArrayData = successInvoicePageResponse.content.map((successInvoiceData, index) => ({
        createdDateTime: tableValueManage(
          successInvoiceData.createdDateTime,
          t('format:original-datetime', {
            value: successInvoiceData.createdDateTime,
            key: 'original-datetime',
          }),
        ),
        billOfExchangeNo: tableValueManage(successInvoiceData.billOfExchangeNo),
        invoiceNumber: tableValueManage(successInvoiceData.invoiceNumber),
        referenceNumber: tableValueManage(successInvoiceData.referenceNumber),
        dealerClientName: tableValueManage(successInvoiceData.dealerClientName),
        dealerCodeByAnchor: tableValueManage(successInvoiceData.dealerCodeByAnchor),
        dealerTaxCode: tableValueManage(successInvoiceData.dealerTaxCode),
        currencyType: tableValueManage(successInvoiceData.currencyType),
        invoiceAmount: Number(successInvoiceData.invoiceAmount),
        invoiceIssuedDate: tableValueManage(
          successInvoiceData.invoiceIssuedDate,
          t('format:original-date', { value: successInvoiceData.invoiceIssuedDate, key: 'original-date' }),
        ),
        settlementDate: tableValueManage(
          successInvoiceData.settlementDate,
          t('format:original-date', { value: successInvoiceData.settlementDate, key: 'original-date' }),
        ),
        blockedByFinancier: getBlockedStatusText(successInvoiceData.blockedByFinancier),
        settlementMethod: tableValueManage(
          successInvoiceData.settlementMethod,
          t(`code:settlement-method-type.${successInvoiceData.settlementMethod}`),
        ),
        loanStatus: tableValueManage(
          successInvoiceData.loanStatus,
          t(`code:financing-status.${successInvoiceData.loanStatus}`),
        ),
        successInvoiceStatus: tableValueManage(
          successInvoiceData.successInvoiceStatus,
          t(`code:success-invoice-status.${successInvoiceData.successInvoiceStatus}`),
        ),
        ...makeInvoiceDetailsObj(index),
      }));

      const excelExporterProps: ExportSpreadSheetProps<SuccessInvoiceVOModel> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: getTableHeaders(true),
        tableBody: renderResultTable(successInvoicePDFPageResponse, true),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const getTableHeaders = (isPDFHeader = false) => {
    const headers: HeaderType[] = [
      {
        headerText: t('text:Registration_Date'),
        colWidths: 150,
      },
      {
        headerText: t('text:BOE_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Invoice_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Invoice_Reference_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Partner_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Partner_Code_of_Anchor'),
        colWidths: 120,
      },
      {
        headerText: t('text:Tax_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 90,
      },
      {
        headerText: t('text:Invoice_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Invoice_Issued_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Original_Payment_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financing_Eligibility'),
        colWidths: 120,
      },
      {
        headerText: t('text:Settlement_Method'),
        colWidths: 120,
      },
      {
        headerText: t('text:Financing_Status'),
        colWidths: 120,
      },
      {
        headerText: t('text:Payment_Status'),
        colWidths: 120,
        hasStatusDescription: true,
        showStatusDescriptionFunc: onClickSuccessInvoiceStatus,
      },
    ];

    const pdfInvoiceDetailsHeaders: HeaderType[] = Array(INVOICE_DETAILS_MAX_COUNT)
      .fill('')
      .map((_, index) => ({
        headerText: `Invoice Detail (${index + 1})`,
        colWidths: 120,
      }));

    return isPDFHeader ? [...headers, ...pdfInvoiceDetailsHeaders] : [...headers, { headerText: '', colWidths: 50 }];
  };

  const renderResultTable = (data: SuccessInvoiceVOModel[] | undefined, isPdf: boolean = false) => {
    return data?.map((item, index) => (
      <tr key={index} className={isPdf ? 'virtual-table-row' : ''}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.billOfExchangeNo} />
        <Td data={item.invoiceNumber} />
        <Td data={item.referenceNumber} />
        <Td data={item.dealerClientName} />
        <Td data={item.dealerCodeByAnchor} />
        <Td data={item.dealerTaxCode} />
        <Td data={item.currencyType} />
        <Td data={item.invoiceAmount} format="number" />
        <Td data={item.invoiceIssuedDate} format="date" />
        <Td data={item.settlementDate} format="date" />
        <Td
          className={getBlockedStatusClassName(item.blockedByFinancier)}
          data={getBlockedStatusText(item.blockedByFinancier)}
        />
        <Td data={t(`code:settlement-method-type.${item.settlementMethod}`)} format="code" />
        <Td
          data={t(`code:financing-status.${item.loanStatus}`)}
          className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
          format="code"
        />
        <Td
          className={getStatusTextClass('SUCCESS_INVOICE_STATUS', item.successInvoiceStatus)}
          data={t(`code:success-invoice-status.${item.successInvoiceStatus}`)}
          format="code"
        />
        {isPdf ? (
          <>
            {item.invoiceAdditionalDataList?.map(({ name, value }, index) => (
              <Td key={`${name}-${value}-${index}`} data={`${name}: ${value === '' ? '-' : value}`} />
            ))}
            {Array(INVOICE_DETAILS_MAX_COUNT - (item.invoiceAdditionalDataList?.length ?? 0))
              .fill('')
              .map((_, index) => (
                <Td key={`fill-empty-${index}`} data="-" />
              ))}
          </>
        ) : (
          <TdLink path={ROUTES_AC.VIEW_TRANSACTION.INVOICE_DETAIL_BUILD_PATH(item.successInvoiceId)} />
        )}
      </tr>
    ));
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="confirmedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="confirmedDateTo" control={control} />
              <SearchLabel label={t('text:BOE_Number')} />
              <SearchInput name="billOfExchangeNo" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Number')} />
              <SearchInput name="invoiceNumber" ref={register} />
              <SearchLabel label={t('text:Invoice_Reference_Number')} />
              <SearchInput name="referenceNumber" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="dealerName" ref={register} />
              <SearchLabel label={t('text:Partner_Code_of_Anchor')} />
              <SearchInput name="dealerCodeByAnchor" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Tax_Code')} />
              <SearchInput name="dealerTaxCode" ref={register} />
              <SearchLabel label={t('text:Invoice_Amount')} />
              <SearchInput col={2} name="minimumInvoiceAmount" placeholder={t('text:from')} ref={register} />
              <SearchInput col={2} name="maximumInvoiceAmount" placeholder={t('text:to')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchLabel label={t('text:Invoice_Issued_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="invoiceIssuedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="invoiceIssuedDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Original_Payment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={control} />
              <SearchLabel label={t('text:Financing_Eligibility')} />
              <SearchSelect
                ref={register}
                name="blocked"
                selectOptions={getSelectOptions<string>('FINANCING_ELIGIBILITY', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Settlement_Method')} />
              <SearchSelect
                ref={register}
                name="settlementMethod"
                selectOptions={getSelectOptions<SETTLEMENT_METHOD_TYPE>('SETTLEMENT_METHOD_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Financing_Status')} />
              <SearchSelect
                ref={register}
                name="loanStatusConditions"
                selectOptions={getSelectOptions<LOAN_STATUS>('LOAN_STATUS', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel
                label={t('text:Payment_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickSuccessInvoiceStatus}
              />
              <SearchSelect
                ref={register}
                name="successInvoiceStatus"
                selectOptions={getSelectOptions<SUCCESS_INVOICE_STATUS>('SUCCESS_INVOICE_STATUS', 'ALL', true)}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {invoicePage?.totalElements ? invoicePage.totalElements : '0'}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={getTableHeaders()} />
          <TableBody numOfCol={getTableHeaders.length}>{renderResultTable(invoicePage?.content)}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorInvoiceListRegisteredTab;
