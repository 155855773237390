import { CURRENCY_TYPE, FINANCIER_TYPE, LOAN_STATUS, SUCCESS_INVOICE_STATUS, stringToEnum } from 'enums';

import type { InvoiceAdditionalDataVO } from './InvoiceAdditionalDataVO';

export interface SuccessInvoiceForRequestLoanVO {
  successInvoiceId: number;
  invoiceNumber: string;
  referenceNumber: string;
  invoiceIssuedDate: string;
  settlementDate: string;
  invoiceAmount: number;
  currencyType: string;
  successInvoiceStatus: string;
  billOfExchangeNo: string;
  invoiceAdditionalDataList: InvoiceAdditionalDataVO[];
  blockedByFinancier: boolean;
  blockedReason: string;
  blockedDateTime: string;
  collateralized: boolean;
  financierEnterpriseId: number;
  financierName: string;
  dealerEnterpriseId: number;
  dealerEnterpriseName: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerClientCode: string;
  dealerClientBusinessCode: string;
  anchorEnterpriseId: number;
  anchorEnterpriseName: string;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  anchorClientBusinessCode: string;
  loanId: number;
  loanStatus: string;
  financierCode: string;
  dealerBusinessCode: string;
  dealerTaxCode: string;
  dealerClientTaxCode: string;
  anchorBusinessCode: string;
  anchorTaxCode: string;
  anchorClientTaxCode: string;
  dealerAgreementId: number;
  createdDateTime: string;
  dealerCodeByAnchor: string;
  remainingInvoiceAmount: number;
}

export interface SuccessInvoiceForRequestLoanVOModel extends SuccessInvoiceForRequestLoanVO {
  currencyType: CURRENCY_TYPE;
  successInvoiceStatus: SUCCESS_INVOICE_STATUS;
  financierCode: FINANCIER_TYPE;
  loanStatus: LOAN_STATUS;
}

export function formattingToSSuccessInvoiceForRequestLoanVOModel(
  data: SuccessInvoiceForRequestLoanVO,
): SuccessInvoiceForRequestLoanVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    successInvoiceStatus: stringToEnum(SUCCESS_INVOICE_STATUS, data.successInvoiceStatus),
    financierCode: stringToEnum(FINANCIER_TYPE, data.financierCode),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
  };
}
