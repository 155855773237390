import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Select from 'components/stateless/Select/Select';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import useMounted from 'hooks/useMounted';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { requestFinancierAnchorAgreementListForPartners } from 'utils/http/api/financier/anchor-agreements';
import type { FinancierAnchorAgreementListForPartnersRequest } from 'utils/http/api/financier/anchor-agreements/requests';
import useModal from 'utils/modal/useModal';
import { getPaymentSupportText } from 'utils/text';

function FinancierPotentialSupplyChainPartnerRegisterStep1() {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();

  const [anchorAgreementListForPartners, setAnchorAgreementListForPartners] = useState<AnchorAgreementVOModel[]>();
  const { register, getValues, handleSubmit } = useForm<{
    selectedKey: keyof FinancierAnchorAgreementListForPartnersRequest;
    selectedValue: string;
  }>({
    mode: 'onSubmit',
  });

  const TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Anchor_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Responsible_Branch_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Automatic_Settlement'),
      colWidths: 100,
    },
    {
      headerText: t('text:Agreement_Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Agreement_Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 70,
    },
  ];

  useEffect(() => {
    if (mounted) {
      fetchAnchorAgreementListForPartners();
    }
  }, [mounted]);

  async function fetchAnchorAgreementListForPartners() {
    try {
      const anchorAgreementList = await requestFinancierAnchorAgreementListForPartners({
        [getValues().selectedKey]: getValues().selectedValue,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementListForPartners(anchorAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const handleAnchorAgreementSearchSubmit = async () => {
    await fetchAnchorAgreementListForPartners();
  };

  return (
    <>
      <HeaderTitle title={t('text:Uploaded_Supplier_Registration')} />
      <div className="content-area">
        <StepWizard
          nth={1}
          title={[t('text:Select_Anchor_Master_Agreement'), t('text:Register_Uploaded_Supplier_Information')]}
        />
      </div>
      <GuideMessage
        message={[t('text:Select_the_Anchor_Master_Agreement_that_the_Uploaded_Suppliers_are_associated_with')]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Search_Anchor')} />
        <form>
          <div className="d-flex information-filter mt-0">
            <Select
              name="selectedKey"
              className="information-form__select"
              selectOptions={getSelectOptions<string>('FINANCIER_ANCHOR_AGREEMENT_LIST_FOR_PARTNERS_REQUEST')}
              ref={register}
            />
            <input type="text" placeholder={t('text:Please_type_here')} name="selectedValue" ref={register} />
            <Button onClick={handleSubmit(handleAnchorAgreementSearchSubmit)}>{t('text:Search')}</Button>
          </div>
        </form>
        <TableBorder>
          <TableHeader header={TABLE_HEADERS} />
          <TableBody numOfCol={TABLE_HEADERS.length}>
            {anchorAgreementListForPartners?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.financierClientCode} />
                <Td data={item.financierClientName} />
                <Td data={item.contractNo} />
                <Td data={item.currencyType} />
                <Td data={item.branchCode} />
                <Td data={item.branchName} />
                <Td data={getPaymentSupportText(item.paymentSupport)} />
                <Td data={item.startDate} format="date" />
                <Td data={item.expiryDate} format="date" />
                <Td className="information-table-more">
                  <Button to={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_STEP2_BUILD_PATH(item.anchorAgreementId)}>
                    {t('text:Select')}
                  </Button>
                </Td>
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
      </div>
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerRegisterStep1;
