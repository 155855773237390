import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import { homePathByRole } from 'utils/route';
import { getSignIn } from 'utils/storage/LocalStorage';

function NotFoundPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const loginInfo = getSignIn();

  const onClickHomeButton = () => {
    loginInfo ? history.push(homePathByRole(loginInfo.authorities[0].authority)) : history.push('/');
  };

  return (
    <>
      {!loginInfo && <PublicHeaderBar />}
      <div className="align-middle row">
        <div className="text-center">
          <h2 className="login-form__title">
            <div className="mb-3">Oops!</div>
            <div>{t('text:The_page_you_are_looking_for_doesnt_exist')}</div>
          </h2>
          <div className="mt-3">
            <Button size={ButtonSizeEnum.MD} onClick={onClickHomeButton} style={{ width: '450px' }}>
              {t('text:Home')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
