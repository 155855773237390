export interface LoanEtcAttachmentVO {
  loanEtcAttachmentId: number;
  loanEtcAttachmentName: string;
  loanEtcAttachmentPath: string;
  description: string;
}

export interface LoanEtcAttachmentVOModel extends LoanEtcAttachmentVO {}

export function formattingToLoanEtcAttachmentVOModel(data: LoanEtcAttachmentVO): LoanEtcAttachmentVOModel {
  return {
    ...data,
  };
}
