import type { BranchVOModel } from 'models/vo/BranchVO';

import { useAgreementContext } from './AgreementContext';

const useAgreementViewModel = () => {
  const {
    agreement,
    isSearchedAgreement,
    isFirstRegisteredWaitingAgreement,
    updateAgreement,
    updateIsSearchedAgreement,
    updateAllAgreement,
    fetchSavedAgreement,
    fetchWaitingAgreement,
    fetchAgreementDetail,
  } = useAgreementContext();

  const updateBranchInfo = (data: BranchVOModel) => {
    updateAgreement({
      branchCode: data.branchCode,
      branchName: data.branchName,
      branchTelephone: data.telephone,
      branchFax: data.fax,
      branchAddress: data.address,
    });
  };

  return {
    agreement,
    isSearchedAgreement,
    isFirstRegisteredWaitingAgreement,
    updateBranchInfo,
    updateAgreement,
    updateIsSearchedAgreement,
    updateAllAgreement,
    fetchSavedAgreement,
    fetchWaitingAgreement,
    fetchAgreementDetail,
  };
};

export default useAgreementViewModel;
