import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { AUTHORIZER_STATUS, USER_STATUS } from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface SyPartnerAgreementRegisteredDetailAuthorizerInfoProps {
  data: DealerAgreementDetailVOModel;
}

function SyPartnerAgreementRegisteredDetailAuthorizerInfo({
  data,
}: SyPartnerAgreementRegisteredDetailAuthorizerInfoProps): JSX.Element {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const AUTHORIZER_INFO_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Mobile'),
      colWidths: 100,
    },
    {
      headerText: t('text:Email'),
      colWidths: 100,
    },
    {
      headerText: t('text:User_Account_Status'),
      colWidths: 120,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickUserStatus,
    },
    {
      headerText: t('text:Invitation_Email'),
      colWidths: 120,
    },
  ];

  const renderAuthorizerInformationTable = (): ReactNode => {
    const renderAuthorizerStatus = (authorizerStatus: AUTHORIZER_STATUS | undefined): JSX.Element | undefined => {
      switch (authorizerStatus) {
        case AUTHORIZER_STATUS.ACTIVATED:
          return (
            <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.ACTIVATED)}>{t('text:Active')}</td>
          );
        case AUTHORIZER_STATUS.MAIL_SENT:
          return (
            <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.MAIL_SENT)}>
              {t('text:Invitation_Email_Sent')}
            </td>
          );
        case AUTHORIZER_STATUS.NONE:
          return <td className={getStatusTextClass('AUTHORIZER_STATUS', AUTHORIZER_STATUS.NONE)}>{t('text:Input')}</td>;
        default:
          return <td>{authorizerStatus}</td>;
      }
    };

    return (
      <Tr>
        <Td data={data.authorizerName} />
        <Td data={data.authorizerMobile} />
        <Td data={data.authorizerEmail} />
        {renderAuthorizerStatus(data.authorizerStatus)}
        <Td className="text-center">
          <Button disabled>{t('text:Send')}</Button>
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:AUTHORIZER_INFORMATION')} />
      <FormContents>
        <div className="row">
          <div className="col-12">
            <TableBorder>
              <TableHeader header={AUTHORIZER_INFO_TABLE_HEADERS} />
              <TableBody numOfCol={AUTHORIZER_INFO_TABLE_HEADERS.length}>
                {renderAuthorizerInformationTable()}
              </TableBody>
            </TableBorder>
          </div>
        </div>
      </FormContents>
    </>
  );
}

export default SyPartnerAgreementRegisteredDetailAuthorizerInfo;
