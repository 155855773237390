import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import { Cell, Content, Row } from 'components/templates/section';
import { ANCHOR_AGREEMENT_STATUS, DEALER_IDENTIFIER_TYPE } from 'enums';
import { getProgramTypeText } from 'utils/text';

import useGeneralInformationController from './useGeneralInformationController';

const GeneralInformation = () => {
  const { t } = useTranslation(['format']);
  const {
    supportedCollateralType,
    isCurrencyTypeReadOnly,
    isAnchorAgreementStatusRender,
    DealerIdentifierTypeDescription,
    defaultDealerIdentifierTypeValue,
    selectCurrencyTypeOptions,
    register,
    handleCurrencyTypeChange,
    isInvoice,
  } = useGeneralInformationController();

  return (
    <Content>
      <Row>
        <Form.Control name="contractNo" required>
          <Form.Label>{t('text:Anchor_Master_Agreement_Number')}</Form.Label>
          <Form.Input />
        </Form.Control>
      </Row>
      <Row>
        <Form.Control>
          <Form.Label>{t('text:Program_Type')}</Form.Label>
          <Form.Value value={getProgramTypeText(supportedCollateralType)} />
          <input type="hidden" name="collateralType" defaultValue={supportedCollateralType} ref={register} />
        </Form.Control>
      </Row>
      <Row>
        <Form.Control name="currencyType" required={!isCurrencyTypeReadOnly}>
          <Form.Label>{t('text:Currency')}</Form.Label>
          <Form.Select
            selectOptions={selectCurrencyTypeOptions}
            placeholderOptions={{ show: true }}
            onChange={handleCurrencyTypeChange}
            readOnly={isCurrencyTypeReadOnly}
          />
        </Form.Control>
      </Row>
      <Row>
        <Form.Control name="startDate" required>
          <Form.Label>{t('text:Effective_Date')}</Form.Label>
          <Form.DatePickerInput />
        </Form.Control>
      </Row>
      <Row>
        <Form.Control name="expiryDate" required>
          <Form.Label>{t('text:Expiration_Date')}</Form.Label>
          <Form.DatePickerInput />
        </Form.Control>
      </Row>
      {isAnchorAgreementStatusRender && (
        <Row>
          <Form.Control name="anchorAgreementStatus" required>
            <Form.Label position="top">{t('text:Agreement_Suspension')}</Form.Label>
            <Form.Radio>
              <Form.Option value={ANCHOR_AGREEMENT_STATUS.ACTIVATED} label={t('text:Not_Suspended')} />
              <Form.Option value={ANCHOR_AGREEMENT_STATUS.SUSPENDED} label={t('text:Suspended')} />
            </Form.Radio>
          </Form.Control>
        </Row>
      )}
      {isInvoice && (
        <Row>
          <Form.Control name="dealerIdentifierType" required>
            <Cell>
              <Form.Label position="top">{t('text:Partner_Identification_Type')}</Form.Label>
              <Form.Description>{DealerIdentifierTypeDescription}</Form.Description>
            </Cell>
            <Form.Radio defaultValue={defaultDealerIdentifierTypeValue}>
              <Form.Option value={DEALER_IDENTIFIER_TYPE.TAX_CODE} label={t('text:Tax_Code')} />
              <Form.Option
                value={DEALER_IDENTIFIER_TYPE.DEALER_CODE_BY_ANCHOR}
                label={t('text:Partner_Code_of_Anchor')}
                tooltipOptions={{
                  id: DEALER_IDENTIFIER_TYPE.DEALER_CODE_BY_ANCHOR,
                  content: `${t(
                    'text:The_Partner_Code_of_Anchor_is_the_partner_identifier_code_that_the_Anchor_may_use_internally',
                  )} ${t('text:Select_Tax_Code_if_the_Partner_Code_of_Anchor_doesn_t_exist')}`,
                }}
              />
            </Form.Radio>
          </Form.Control>
        </Row>
      )}
    </Content>
  );
};

export default GeneralInformation;
