import './PPTable.scss';

interface PPTableBorderProps {
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
}

function PPTableBorder({ children }: PPTableBorderProps) {
  return <table className="pp-table-border">{children}</table>;
}

export default PPTableBorder;
