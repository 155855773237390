import type React from 'react';
import { useTranslation } from 'react-i18next';

import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonVariantEnum } from './Button';

interface ExcelTemplateDownloadButtonProps {
  templateFileAddress: string;
  downloadAnnounceText?: string;
  style?: React.CSSProperties;
}

function ExcelTemplateDownloadButton({
  templateFileAddress,
  downloadAnnounceText,
  style,
}: ExcelTemplateDownloadButtonProps): JSX.Element {
  const { t } = useTranslation(['format']);
  const onClickTemplateDownloadButton = (e: any): void => {
    e.preventDefault();

    window.location.href = templateFileAddress;
  };

  return (
    <Button
      onClick={onClickTemplateDownloadButton}
      style={style}
      color={ButtonColorEnum.SECONDARY}
      variant={ButtonVariantEnum.OUTLINED}
    >
      <>
        <FontAwesomeIcon icon={faFileDownload} className="me-1" />
        {downloadAnnounceText ? downloadAnnounceText : t('text:Excel_Template')}
      </>
    </Button>
  );
}

export default ExcelTemplateDownloadButton;
