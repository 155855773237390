import type { InputHTMLAttributes, LegacyRef } from 'react';
import { forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';

import IconButton from 'components/stateless/Button/IconButton';

import { FormErrorMessage } from '../ErrorMessage';

import type { SizeType } from '..';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  fieldSize?: SizeType;
  error?: FieldError;
}

const ReactDatePickerInput = forwardRef(
  (
    {
      value,
      onClick,
      onChange,
      onKeyDown,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      readOnly,
      className = '',
      name,
      fieldSize,
      error,
    }: CustomInputProps,
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    return (
      <div>
        <div
          className={clsx('position-parent', {
            [`field--size-${fieldSize}`]: fieldSize,
          })}
          onClick={onClick}
        >
          <input
            className={clsx({
              'error-input-border': error,
              [className]: className,
            })}
            name={name}
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={ref}
            disabled={disabled}
            readOnly={readOnly}
            autoComplete="off"
          />
          <IconButton className="date-picker-icon" type="button" disabled={disabled || readOnly}>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </IconButton>
        </div>
        {error && <FormErrorMessage error={error} />}
      </div>
    );
  },
);

ReactDatePickerInput.displayName = 'ReactDatePickerInput';
export default ReactDatePickerInput;
