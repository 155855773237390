import type { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isArray } from 'lodash-es';

interface FormErrorMessagePropsType {
  error?: FieldError;
}

export const FormErrorMessage = ({ error }: FormErrorMessagePropsType) => {
  const { t } = useTranslation(['format']);

  const returnErrorText = (): string => {
    if (error && error.message) {
      if (isArray(error.message)) {
        const [textKey, arguState] = error.message;

        return t(`text:${textKey}`, arguState);
      } else return t(`text:${error.message}`);
    } else return '';
  };

  return (
    <>
      {returnErrorText() !== '' && (
        <div
          className="m-1 text-brick-red"
          // style={{ height: '-webkit-fill-available' }}
        >
          {returnErrorText()}
        </div>
      )}
    </>
  );
};
