import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ClipboardWriteButton from 'components/stateless/Button/ClipboardWriteButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestAnchorAdminOperatorInviteUrl,
  requestAnchorUserAnchorAgreementList,
  requestAnchorUserAssignAnchorAgreement,
  requestAnchorUserDealerAgreementList,
  requestAnchorUserDetail,
  requestAnchorUserEdit,
  requestAnchorUserInviteAdminOperator,
  requestAnchorUserReleaseAnchorAgreement,
  requestAnchorUserSuspendAdminOperator,
} from 'utils/http/api/anchor/users';
import type { AnchorAdminOperatorUpdateRequest } from 'utils/http/api/anchor/users/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { getPaymentSupportText } from 'utils/text';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import AssignAgreementModal from './modal/AssignAgreementModal';
import DeleteAgreementModal from './modal/DeleteAgreementModal';

function AnchorUserDetail() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { userId } = useParams<any>();
  const signInModel: SignInModel | null = getSignIn();

  const [userDetail, setUserDetail] = useState<UserVOModel>();
  const [anchorAgreementList, setAnchorAgreementList] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const [dealerAgreementList, setDealerAgreementList] = useState<Pageable<DealerAgreementVOModel[]>>();
  const { pageable: anchorAgreementPageable, setPageable: setAnchorAgreementPageable } = usePageable();
  const { pageable: dealerAgreementPageable, setPageable: setDealerAgreementPageable } = usePageable();
  const [editable, setEditable] = useState<boolean>(false);

  const [showSendEmailButton, setShowSendEmailButton] = useState<boolean>(false);
  const [editableAdmin, setEditableAdmin] = useState<boolean>(false);
  const [inviteUrl, setInviteUrl] = useState<string | null>('');

  const { register, getValues, reset, errors, setError, clearErrors } = useForm<AnchorAdminOperatorUpdateRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    if (userDetail) {
      const editAdmin =
        signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && userDetail?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR;

      setEditableAdmin(editAdmin);

      if (userDetail.userStatus === USER_STATUS.REGISTERED || userDetail.userStatus === USER_STATUS.INVITED) {
        if (editAdmin) {
          setShowSendEmailButton(true);
        } else {
          setShowSendEmailButton(false);
        }
      } else {
        setShowSendEmailButton(false);
      }
    }
  }, [userDetail]);

  async function fetchAll() {
    try {
      const [fetchedUserDetail, anchorAgreementList, dealerAgreementList] = await Promise.all([
        requestAnchorUserDetail(userId),
        requestAnchorUserAnchorAgreementList(
          userId,
          anchorAgreementPageable.currentPage,
          anchorAgreementPageable.sizePerPage,
        ),
        requestAnchorUserDealerAgreementList(
          userId,
          dealerAgreementPageable.currentPage,
          dealerAgreementPageable.sizePerPage,
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setUserDetail(fetchedUserDetail);
        setAnchorAgreementList(anchorAgreementList);
        setAnchorAgreementPageable(anchorAgreementList);
        setDealerAgreementList(dealerAgreementList);
        setDealerAgreementPageable(dealerAgreementList);
        // input 값 설정
        reset({
          userName: fetchedUserDetail.userName,
          employeeCode: fetchedUserDetail.employeeCode,
          department: fetchedUserDetail.department,
          position: fetchedUserDetail.position,
          mobile: fetchedUserDetail.mobile,
          telephone: fetchedUserDetail.telephone,
          email: fetchedUserDetail.email,
        });

        setEditable(false);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchAnchorAgreementList = async (pageNumber: number, rowCount: number) => {
    try {
      const anchorAgreementList = await requestAnchorUserAnchorAgreementList(userId, pageNumber, rowCount);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementList(anchorAgreementList);
        setAnchorAgreementPageable(anchorAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchDealerAgreementList = async (pageNumber: number, rowCount: number) => {
    try {
      const dealerAgreementList = await requestAnchorUserDealerAgreementList(userId, pageNumber, rowCount);

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementList(dealerAgreementList);
        setDealerAgreementPageable(dealerAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginateAnchorAgreementList = async (pageNumber: number, rowCount: number) => {
    try {
      await fetchAnchorAgreementList(pageNumber, rowCount);
    } catch (error) {
      modal.show(error);
    }
  };

  const paginateDealerAgreementList = async (pageNumber: number, rowCount: number) => {
    try {
      await fetchDealerAgreementList(pageNumber, rowCount);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickAssignAgreement = () => {
    let checkedAnchorAgreementIds: number[] = [];
    const showOnClickAssign = () => {
      const onClickConfirm = async () => {
        try {
          await requestAnchorUserAssignAnchorAgreement(userId, checkedAnchorAgreementIds);
          modal.show(<h6>{t('text:The_agreements_have_been_assigned_successfully')}</h6>, {
            closeBtnCb: () => fetchAnchorAgreementList(1, 10),
          });
        } catch (e) {
          modal.show(e);
        }
      };

      if (checkedAnchorAgreementIds.length === 0) {
        modal.show(<h6>{t('text:Select_an_agreement_to_assign')}</h6>, {
          closeBtnCb: () => onClickAssignAgreement(),
        });
      } else {
        modal.show(
          <h6>
            {t('text:Would_you_like_to_assign_agreements_to_this_user?', { number: checkedAnchorAgreementIds.length })}
          </h6>,
          {
            modalType: ModalType.CONFIRM,
            confirmBtnCb: onClickConfirm,
          },
        );
      }
    };

    const getCheckedData = (data: number[]) => {
      checkedAnchorAgreementIds = data;
    };

    modal.show(<AssignAgreementModal userId={userId} getCheckedData={getCheckedData} />, {
      modalSize: ModalSize.XL,
      title: t('text:Assign_Agreement'),
      modalType: ModalType.CONFIRM,
      confirmBtnText: t('text:Assign'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => {
        showOnClickAssign();
      },
    });
  };

  const onClickDeleteAgreement = (): void => {
    let assignedAnchorAgreementIds: number[] = [];

    const setSelectedAssignedAnchorAgreementIds = (selectedAssignedAnchorAgreementIds: number[]) => {
      assignedAnchorAgreementIds = selectedAssignedAnchorAgreementIds;
    };

    const showOnClickDelete = () => {
      const onClickConfirm = async () => {
        try {
          await requestAnchorUserReleaseAnchorAgreement(userId, assignedAnchorAgreementIds);

          modal.show(t('text:The_agreements_have_been_deleted_successfully'), {
            closeBtnText: t('text:OK'),
            closeBtnCb: () => {
              fetchAnchorAgreementList(1, 10);
            },
          });
        } catch (e) {
          modal.show(e);
        }
      };

      if (assignedAnchorAgreementIds.length === 0) {
        modal.show(<h6>{t('text:Select_an_agreement_to_delete')}</h6>, {
          closeBtnCb: () => onClickDeleteAgreement(),
        });
      } else {
        modal.show(
          <h6>
            {t('text:Would_you_like_to_delete_agreements_to_this_user?', { number: assignedAnchorAgreementIds.length })}
          </h6>,
          {
            modalType: ModalType.CONFIRM,
            confirmBtnCb: onClickConfirm,
          },
        );
      }
    };

    modal.show(
      <DeleteAgreementModal
        userId={userId}
        getSelectedAssignedAnchorAgreementIds={setSelectedAssignedAnchorAgreementIds}
      />,
      {
        title: t('text:Delete_Agreement'),
        modalSize: ModalSize.XL,
        modalType: ModalType.CONFIRM,
        confirmBtnText: t('text:Delete'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => {
          showOnClickDelete();
        },
      },
    );
  };

  const onSendEmailModalHandler = () => {
    const showEmailSendConfirmModal = () => {
      modal.show(<h6>{t('text:The_invitation_email_has_been_sent')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          fetchAll();
        },
      });
    };
    modal.show(<h6>{t('text:Would_you_like_to_send_an_invitation_email')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestAnchorUserInviteAdminOperator(userId);
          showEmailSendConfirmModal();
          setInviteUrl('');
        } catch (e) {
          modal.show(e);
        }
      },
    });
  };

  const renderButtons = () => {
    const onClickCancel = (e: any) => {
      e.preventDefault();
      modal.show(
        <h6>
          {t('text:Would_you_like_to_stop_modifying_the_information')}?<br />
          {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirm(),
        },
      );
      const onClickConfirm = () => {
        setEditable(prevState => !prevState);
        reset({
          userName: userDetail?.userName,
          employeeCode: userDetail?.employeeCode,
          department: userDetail?.department,
          position: userDetail?.position,
          mobile: userDetail?.mobile,
          telephone: userDetail?.telephone,
          email: userDetail?.email,
        });
      };
    };

    const onClickEdit = () => {
      setEditable(prevState => !prevState);
    };

    const onClickSave = async (e: any) => {
      e.preventDefault();

      const data = getValues();

      try {
        requestDTOParser(data);
        await requestAnchorUserEdit(userId, data);

        modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: async () => await fetchAll(),
        });
        clearErrors();
      } catch (e) {
        modal.show(e);

        formErrorHandler<AnchorAdminOperatorUpdateRequest>(e, setError, clearErrors);
      }
    };

    const onMakeUserActive = (e: any) => {
      e.preventDefault();
      const activatedConfirmModal = () => {
        modal.show(<h6>{t('text:The_user_account_has_been_activated')}</h6>, {
          closeBtnCb: () => fetchAll(),
        });
      };
      modal.show(
        <h6>
          {t('text:Would_you_like_to_activate_the_user_account')}
          <br />
          {t(
            'text:If_the_account_is_activated_the_user_will_be_able_to_log_in_to_the_platform_and_use_its_features_again',
          )}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: async () => {
            try {
              await requestAnchorUserSuspendAdminOperator(userId, false);
              activatedConfirmModal();
            } catch (e) {
              modal.show(e);
            }
          },
        },
      );
    };

    const onMakeUserInactive = (e: any) => {
      e.preventDefault();
      const inActivatedConfirmModal = () => {
        modal.show(
          <h6>
            {t('text:The_user_account_has_been_deactivated')}
            <br />
            {t('text:To_reactivate_the_account_click_on_the_Activate_button')}
          </h6>,
          {
            closeBtnCb: () => fetchAll(),
          },
        );
      };
      modal.show(
        <h6>
          {t('text:Would_you_like_to_deactivate_the_user_account?')}
          <br />
          {t(
            'text:If_the_account_is_deactivated_the_user_will_not_be_able_to_log_in_to_the_platform_or_use_its_features',
          )}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: async () => {
            try {
              await requestAnchorUserSuspendAdminOperator(userId, true);
              inActivatedConfirmModal();
            } catch (e) {
              modal.show(e);
            }
          },
        },
      );
    };

    return editable ? (
      <>
        <Button
          size={ButtonSizeEnum.SM}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          onClick={onClickCancel}
        >
          {t('text:Cancel')}
        </Button>
        <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
          {t('text:Save')}
        </Button>
      </>
    ) : (
      <>
        {userDetail?.userStatus === USER_STATUS.SUSPENDED && (
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.BLUE}
            onClick={onMakeUserActive}
          >
            {t('text:Activate')}
          </Button>
        )}
        {userDetail?.userStatus === USER_STATUS.ACTIVATED && (
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.RED}
            onClick={onMakeUserInactive}
          >
            {t('text:Deactivate')}
          </Button>
        )}

        <Button size={ButtonSizeEnum.SM} onClick={onClickEdit} style={{ width: '60px' }}>
          {t('text:Edit')}
        </Button>
      </>
    );
  };

  const renderDeAgreements = () => {
    const deAgreementListTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Partner_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Partner_Master_Agreement_Number'),
        colWidths: 200,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 120,
      },
      {
        headerText: t('text:Program_Type'),
        colWidths: 120,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 150,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 150,
      },
    ];

    const deAgreementListWithFiNameTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Partner_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Partner_Master_Agreement_Number'),
        colWidths: 200,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 120,
      },
      {
        headerText: t('text:Program_Type'),
        colWidths: 120,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 150,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 150,
      },
    ];

    return (
      <>
        <TableBorder>
          <TableHeader
            header={
              signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ||
              signInModel?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR
                ? deAgreementListWithFiNameTableHeaders
                : deAgreementListTableHeaders
            }
          />
          <TableBody
            numOfCol={
              signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ||
              signInModel?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR
                ? deAgreementListWithFiNameTableHeaders.length
                : deAgreementListTableHeaders.length
            }
          >
            {dealerAgreementList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.financierName} />
                  <Td data={item.dealerFinancierClientName} />
                  <Td data={item.contractNo} />
                  <Td data={item.currencyType} />
                  <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
                  <Td data={item.startDate} />
                  <Td data={item.expiryDate} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={dealerAgreementPageable} paginate={paginateDealerAgreementList} />
      </>
    );
  };

  const renderAcAgreements = () => {
    const acAgreementListTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Anchor_Master_Agreement_Number'),
        colWidths: 200,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 120,
      },
      {
        headerText: t('text:Program_Type'),
        colWidths: 120,
      },
      {
        headerText: t('text:Automatic_Settlement'),
        colWidths: 180,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 150,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 150,
      },
      {
        headerText: '',
        colWidths: 70,
      },
    ];

    return (
      <>
        <TableBorder>
          <TableHeader header={acAgreementListTableHeaders} />
          <TableBody numOfCol={acAgreementListTableHeaders.length}>
            {anchorAgreementList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.financierName} />
                  <Td data={item.contractNo} />
                  <Td data={item.currencyType} />
                  <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
                  <Td data={getPaymentSupportText(item.paymentSupport)} />
                  <Td data={item.startDate} format="date" />
                  <Td data={item.expiryDate} format="date" />
                  <TdLink path={ROUTES_AC.COMPANY.AGREEMENT_DETAIL_BUILD_PATH(item.anchorAgreementId)} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={anchorAgreementPageable} paginate={paginateAnchorAgreementList} />
      </>
    );
  };

  const renderGuideMessage = () => {
    if (
      (signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ||
        signInModel?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR) &&
      (userDetail?.authorityType === AUTHORITY_TYPE.ADMIN || userDetail?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR)
    ) {
      if (editableAdmin) {
        return (
          <GuideMessage
            message={[
              t('text:You_can_check_the_anchor_users_details_on_this_page'),
              t('text:Click_on_the_arrow_button_to_view_the_details_of_the_relevant_master_agreements'),
              t('text:Click_the_Edit_button_to_modify_the_anchor_users_details'),
            ]}
          />
        );
      } else {
        return (
          <GuideMessage
            message={[
              t('text:You_can_check_the_anchor_users_details_on_this_page'),
              t('text:Click_on_the_arrow_button_to_view_the_details_of_the_relevant_master_agreements'),
            ]}
          />
        );
      }
    } else {
      return <GuideMessage message={[t('text:You_can_check_the_anchor_users_details_on_this_page')]} />;
    }
  };

  const onClickClipboardWriteButton = async () => {
    if (inviteUrl === null) return null;

    try {
      const inviteUrl = await requestAnchorAdminOperatorInviteUrl(userId);
      if (inviteUrl === null) {
        modal.show(t('text:Click_on_the_Send_button_before_copying_the_invitation_email_link'));
      }
      setInviteUrl(inviteUrl);

      return inviteUrl;
    } catch (e) {
      modal.show(e);

      return null;
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_User_Information_Details')} />
      {renderGuideMessage()}
      <div className="content-area">
        <SectionTitle title="">{editableAdmin && renderButtons()}</SectionTitle>
        <FormBorder editable={editable}>
          <FormSubtitle title={t('text:ANCHOR_USER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:User_Name')}
                defaultValue={userDetail?.userName}
                name="userName"
                ref={register}
                disabled={!editable}
                requiredOptions={{ required: true }}
                error={errors.userName}
              />
              <FormInput
                label={t('text:Employee_Code')}
                defaultValue={userDetail?.employeeCode}
                disabled={!editable}
                name="employeeCode"
                ref={register}
                error={errors.employeeCode}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Department')}
                defaultValue={userDetail?.department}
                disabled={!editable}
                name="department"
                ref={register}
                error={errors.department}
              />
              <FormInput
                label={t('text:Title')}
                defaultValue={userDetail?.position}
                disabled={!editable}
                name="position"
                ref={register}
                error={errors.position}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Mobile')}
                defaultValue={userDetail?.mobile}
                requiredOptions={{ required: true }}
                disabled={!editable}
                name="mobile"
                ref={register}
                error={errors.mobile}
              />
              <FormInput
                label={t('text:Email')}
                defaultValue={userDetail?.email}
                requiredOptions={{ required: true }}
                disabled={!editable}
                name="email"
                ref={register}
                error={errors.email}
              >
                <>
                  {!editable && editableAdmin && userDetail?.userStatus === USER_STATUS.INVITED && (
                    <ClipboardWriteButton
                      className="ms-2"
                      onClickClipboardWriteButton={onClickClipboardWriteButton}
                      disabled={inviteUrl === null}
                    />
                  )}
                  {!editable && showSendEmailButton && (
                    <Button onClick={onSendEmailModalHandler} className="ms-2">
                      {t('text:Send')}
                    </Button>
                  )}
                </>
              </FormInput>
            </div>
            <div className="row">
              <FormInput
                label={t('text:Telephone')}
                defaultValue={userDetail?.telephone}
                disabled={!editable}
                name="telephone"
                ref={register}
                error={errors.telephone}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Authority')}
                value={t(`code:authority-type.${userDetail?.authorityType}`)}
              />
            </div>
            <div className="row">
              <FormValue className="information-form__input" label={t('text:User_ID')} value={userDetail?.loginId} />
              <FormValue
                className={`information-form__input ${getStatusTextClass('USER_STATUS', userDetail?.userStatus)}`}
                label={t('text:User_Account_Status')}
                value={t(`code:user-status.${userDetail?.userStatus}`)}
                format="code"
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Registered_Date')}
                value={userDetail?.createDateTime}
                format="datetime"
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {/* Agreement List : 로그인 한 Admin, Admin Operator 만 조회 가능 */}
      {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ||
      signInModel?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR ? (
        userDetail?.authorityType === AUTHORITY_TYPE.ADMIN ||
        userDetail?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR ? (
          <div className="content-area">
            <SectionTitle title={t('text:Associated_Anchor_Master_Agreement_List')}>
              {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN &&
                userDetail?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR && (
                  <>
                    <Button size={ButtonSizeEnum.SM} onClick={onClickAssignAgreement}>
                      {t('text:Assign_Agreement')}
                    </Button>
                    <Button size={ButtonSizeEnum.SM} onClick={onClickDeleteAgreement} color={ButtonColorEnum.SECONDARY}>
                      {t('text:Delete_Agreement')}
                    </Button>
                  </>
                )}
            </SectionTitle>
            {renderAcAgreements()}
          </div>
        ) : (
          <div className="content-area">
            <SectionTitle title={t('text:Associated_Partner_Master_Agreement_List')} />
            {renderDeAgreements()}
          </div>
        )
      ) : null}
    </>
  );
}

export default AnchorUserDetail;
