import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import ExpectedFinancingRepaymentModal from 'components/stateless/Modal/common/loan/ExpectedFinancingRepaymentModal';
import { COLLATERAL_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import type { ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { requestFinancierInterfaceSetting } from 'utils/http/api/common/financier-interface-setting';
import { requestSystemEarlyRepaymentList } from 'utils/http/api/system/early-repayment-requests';
import { requestSystemExtensionList } from 'utils/http/api/system/extension-requests';
import {
  requestSystemCalculateExpectedAmount,
  requestSystemLoanDetail,
  requestSystemLoanTransactionList,
  requestSystemRenewLoanByFinancierInterface,
} from 'utils/http/api/system/loans';
import { requestSystemSuccessArsDetail } from 'utils/http/api/system/success-ars';
import { requestSystemSuccessInvoiceDetail } from 'utils/http/api/system/success-invoices';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

export function useSystemFinancingDetailState() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { loanId } = useParams<any>();

  const [dataState, setDataState] = useState({
    syLoanDetail: {} as LoanDetailVOModel,
    syInvoiceDetail: {} as SuccessInvoiceDetailVOModel,
    syArDetail: {} as SuccessArDetailVOModel,
    syEarlyRepaymentList: [] as EarlyRepaymentRequestVOModel[],
    syExtensionList: [] as ExtensionRequestVOModel[],
    syLoanTransactionHistoryList: [] as LoanTransactionVOModel[],
    isNilInvoiceIdAndSuccessArId: undefined as boolean | undefined,
    loanInterface: false,
  });

  const { pageable: earlyRepaymentPageable, setPageable: setEarlyRepaymentPageable } = usePageable();
  const { pageable: extensionPageable, setPageable: setExtensionPageable } = usePageable();
  const { pageable: loanTransactionHistoryPageable, setPageable: setLoanTransactionHistoryPageable } = usePageable();

  const fetchAll = async () => {
    let isNilInvoiceIdAndSuccessArId: boolean;

    try {
      const [fetchedLoanDetail, fetchedLoanTransactionList, fetchedEarlyRepaymentList, fetchedExtensionList] =
        await Promise.all([
          requestSystemLoanDetail(loanId),
          requestSystemLoanTransactionList(loanId, 0, 10),
          requestSystemEarlyRepaymentList(0, 10, {
            loanId,
          }),
          requestSystemExtensionList(0, 10, { loanId }),
        ]);

      isNilInvoiceIdAndSuccessArId = isNil(fetchedLoanDetail.invoiceId) && isNil(fetchedLoanDetail.successArId);

      setDataState(prevState => ({
        ...prevState,
        syLoanDetail: fetchedLoanDetail,
        syLoanTransactionHistoryList: [...fetchedLoanTransactionList.content],
        syEarlyRepaymentList: [...fetchedEarlyRepaymentList.content],
        syExtensionList: [...fetchedExtensionList.content],
        isNilInvoiceIdAndSuccessArId: isNilInvoiceIdAndSuccessArId,
      }));

      if (!isNilInvoiceIdAndSuccessArId) {
        if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.INVOICE) {
          const [fetchedInvoiceDetail, invoiceLoanInterfaceEnable] = await Promise.all([
            requestSystemSuccessInvoiceDetail(fetchedLoanDetail.invoiceId),
            requestFinancierInterfaceSetting(fetchedLoanDetail.financierId, FINANCIER_INTERFACE_TYPE.LOAN_RESULT),
          ]);

          setDataState(prevState => ({
            ...prevState,
            syInvoiceDetail: fetchedInvoiceDetail,
            loanInterface: invoiceLoanInterfaceEnable,
          }));
        }

        if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.AR) {
          const [fetchedArDetail, arLoanInterfaceEnable] = await Promise.all([
            requestSystemSuccessArsDetail(fetchedLoanDetail.successArId),
            requestFinancierInterfaceSetting(fetchedLoanDetail.financierId, FINANCIER_INTERFACE_TYPE.LOAN_RESULT_AR),
          ]);

          setDataState(prevState => ({
            ...prevState,
            syArDetail: fetchedArDetail,
            loanInterface: arLoanInterfaceEnable,
          }));
        }
      }

      setEarlyRepaymentPageable(fetchedEarlyRepaymentList);
      setLoanTransactionHistoryPageable(fetchedLoanTransactionList);
      setExtensionPageable(fetchedExtensionList);
    } catch (e: any) {
      modal.show(e);
    }
  };

  async function getLoanTransactionHistoryPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestSystemLoanTransactionList(loanId, pageNumber, rowCount);
      setLoanTransactionHistoryPageable(response);
      setDataState(prevState => ({
        ...prevState,
        syLoanTransactionHistoryList: [...response.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  }

  async function getEarlyRepaymentListPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestSystemEarlyRepaymentList(pageNumber, rowCount, { loanId: loanId });
      setEarlyRepaymentPageable(response);
      setDataState(prevState => ({
        ...prevState,
        syEarlyRepaymentList: [...response.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  }

  async function getExtensionListPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestSystemExtensionList(pageNumber, rowCount, { loanId: loanId });
      setExtensionPageable(response);
      setDataState(prevState => ({
        ...prevState,
        syExtensionList: [...response.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  }

  const onClickUpdateTransaction = async (e: any) => {
    e.preventDefault();
    try {
      await requestSystemRenewLoanByFinancierInterface(loanId);
      modal.show(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
        closeBtnCb: fetchAll,
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickViewExpectedRepaymentAmount = async (e: any) => {
    e.preventDefault();
    const { repaymentDate } = dataState.syLoanDetail;

    async function fetchCalculateExpectedAmount() {
      const [expectedFinancingRepaymentDataResponse] = await Promise.all([
        requestSystemCalculateExpectedAmount(loanId, repaymentDate),
      ]);

      return { expectedFinancingRepaymentDataResponse };
    }

    modal.show(
      <ExpectedFinancingRepaymentModal
        loanId={loanId}
        repaymentDate={repaymentDate}
        fetchAll={fetchCalculateExpectedAmount}
        disbursedDate={dataState.syLoanDetail.disbursedDate}
        requestSearch={requestSystemCalculateExpectedAmount}
        factoringEnable={dataState.syLoanDetail.factoringEnable}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.L,
        title: t('text:View_Expected_Repayment_Amount'),
      },
    );
  };

  return {
    state: dataState,
    fetchAll,
    onClickUpdateTransaction,
    onClickViewExpectedRepaymentAmount,
    pageables: {
      earlyRepaymentPageable,
      extensionPageable,
      loanTransactionHistoryPageable,
    },
    paginates: { getLoanTransactionHistoryPaginate, getEarlyRepaymentListPaginate, getExtensionListPaginate },
  };
}
