import type { UserVO, UserVOModel } from 'models/vo/UserVO';
import { formattingToUserVOModel } from 'models/vo/UserVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

import type {
  CertifyAnchorUserRequest,
  CertifyDealerAuthorizerRequest,
  FindPasswordRequest,
  GetExistenceDTO,
  ResetUserPasswordRequest,
  SignUpAnchorUserRequest,
  SignUpDealerAuthorizerRequest,
  SignUpDealerOperatorRequest,
  SignUpFinancierUserRequest,
} from './requests';

// ID 찾기
export async function requestUserFindId(email: string) {
  return await http.post({
    url: API_AN.USERS.FIND_ID,
    data: { email },
  });
}

// 패스워드 찾기  - 로그인 하지 않은 유저
export async function requestUserFindPassword(data: FindPasswordRequest) {
  return await http.post({
    url: API_AN.USERS.FIND_PASSWORD,
    data,
  });
}

// 패스워드 재설정 - 로그인 하지 않은 유저
export async function requestUserResetPassword(data: ResetUserPasswordRequest) {
  return await http.post({
    url: API_AN.USERS.RESET_PASSWORD,
    data,
  });
}

// 가입 공통 - 아이디 중복 체크
export async function requestCheckUserExistence(data: GetExistenceDTO) {
  return await http.get<boolean>({
    url: API_AN.USERS.USER_EXISTENCE,
    data,
  });
}

// 가입 대상 사용자 조회
export async function requestInvitedUser(code: string): Promise<UserVOModel> {
  const response = await http.get<UserVO>({
    url: API_AN.USERS.INVITED_USER,
    data: { code },
  });

  return formattingToUserVOModel(response);
}

// 은행 사용자 가입 요청
export async function requestSignUpFinancier(data: SignUpFinancierUserRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_AN.USERS.SIGN_UP_FINANCIER_USER,
    data,
  });

  return formattingToUserVOModel(response);
}

export async function requestSignUpAnchorUser(data: SignUpAnchorUserRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_AN.USERS.SIGN_UP_ANCHOR_USER,
    data,
  });

  return formattingToUserVOModel(response);
}

export async function requestCertifyAnchorUser(data: CertifyAnchorUserRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_AN.USERS.CERTIFY_ANCHOR_USER,
    data,
  });

  return formattingToUserVOModel(response);
}

export async function requestSignUpDealerAuthorizer(data: SignUpDealerAuthorizerRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_AN.USERS.SIGN_UP_DEALER_AUTHORIZER,
    data,
  });

  return formattingToUserVOModel(response);
}

export async function requestCertifyDealerAuthorizer(data: CertifyDealerAuthorizerRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_AN.USERS.CERTIFY_DEALER_AUTHORIZER,
    data,
  });

  return formattingToUserVOModel(response);
}

export async function requestSignUpAcHqOpAndDeOp(data: SignUpDealerOperatorRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_AN.USERS.SIGN_UP_DEALER_OPERATOR,
    data,
  });

  return formattingToUserVOModel(response);
}
