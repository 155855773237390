import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import type { AnPotentialPartnerFinancingApplicationSubmitRequest } from 'utils/http/api/anonymous/potential-partner-financing-applications/request';

import PotentialPartnerApplicationStep1FinancierGuideMessage from './sections/financier-guide-message/PotentialPartnerApplicationStep1FinancierGuideMessage';
import PotentialPartnerApplicationStep1MyInfoForm from './sections/my-information-form/PotentialPartnerApplicationStep1MyInfoForm';
import {
  usePotentialPartnerApplicationActions,
  usePotentialPartnerApplicationValue,
} from '../../PotentialPartnerApplicationProvider';

import type {
  PotentialPartnerApplicationStep1AnchorPartnerInfoType,
  PotentialPartnerApplicationStep1FinancierGuideMessageType,
} from '../logics/types';

export type PotentialPartnerApplicationStep1FormDataType = Pick<
  AnPotentialPartnerFinancingApplicationSubmitRequest,
  | 'anchorPartnerAccountId'
  | 'partnerName'
  | 'partnerTaxCode'
  | 'partnerBusinessCode'
  | 'telephone'
  | 'fax'
  | 'representativeName'
  | 'representativeTitle'
  | 'representativeEmail'
  | 'address'
>;

interface PotentialPartnerApplicationStep1Props {
  financierGuideMessageInfo: PotentialPartnerApplicationStep1FinancierGuideMessageType;
  anchorPartnerInfo: PotentialPartnerApplicationStep1AnchorPartnerInfoType;
}
function PotentialPartnerApplicationStep1({
  financierGuideMessageInfo,
  anchorPartnerInfo,
}: PotentialPartnerApplicationStep1Props) {
  const { t } = useTranslation();

  const { formData } = usePotentialPartnerApplicationValue();
  const { setNextFormStep, setFormValues, onClickCancelSubmissionBtn } = usePotentialPartnerApplicationActions();

  const step1Form = useForm<PotentialPartnerApplicationStep1FormDataType>({
    defaultValues: formData,
  });

  const [editable, setEditable] = useState<boolean>(false);
  const [confirmedCheckbox, setConfirmedCheckbox] = useState<boolean>(false);

  const onClickNext = () => {
    setNextFormStep();
    setFormValues(step1Form.getValues());
  };

  return (
    <FormProvider {...step1Form}>
      <div className="pp-mb-56">
        <PotentialPartnerApplicationStep1FinancierGuideMessage data={financierGuideMessageInfo} />
      </div>
      <PotentialPartnerApplicationStep1MyInfoForm
        anchorPartnerInfo={anchorPartnerInfo}
        editableState={{
          editable,
          setEditable,
        }}
        confirmCheckboxState={{
          confirmedCheckbox,
          setConfirmedCheckbox,
        }}
      />

      <div className="pp-mt-96 d-flex justify-content-between">
        <Button2 color={Button2ColorEnum.TERTIARY} size={Button2SizeEnum.LG} onClick={onClickCancelSubmissionBtn}>
          {t('text:Cancel_application')}
        </Button2>

        <Button2
          size={Button2SizeEnum.LG}
          disabled={editable || !confirmedCheckbox}
          icon={<FontAwesomeIcon icon={faArrowRight} fontSize={19.25} />}
          onClick={onClickNext}
        >
          {t('text:Next_page')}
        </Button2>
      </div>
    </FormProvider>
  );
}

export default PotentialPartnerApplicationStep1;
