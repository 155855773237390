import { useHistory } from 'react-router-dom';

import { ROUTES_AC } from 'constants/routes/anchor';
import { ROUTES_DE } from 'constants/routes/dealer';
import { ROUTES_FI } from 'constants/routes/financier';
import { ROLE } from 'enums';
import { getLogo, getSignIn } from 'utils/storage/LocalStorage';

const BLANK_LOGO = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAIAAAgABJ60+RsAAAAASUVOREKuYII=';

const useHeaderBarController = () => {
  const history = useHistory();

  const logos = getLogo();

  const role = getSignIn()?.authorities[0].authority;

  const isLogoRender = logos && logos !== BLANK_LOGO;

  const handleLogoClick = () => {
    switch (role) {
      case ROLE.ROLE_ANCHOR:
        return history.push(ROUTES_AC.DASHBOARD);
      case ROLE.ROLE_DEALER:
        return history.push(ROUTES_DE.DASHBOARD);
      case ROLE.ROLE_FINANCIER:
        return history.push(ROUTES_FI.DASHBOARD);
      default:
        return;
    }
  };

  return {
    isLogoRender,
    logos,
    handleLogoClick,
  };
};

export default useHeaderBarController;
