import type { ReactNode } from 'react';

import { Cell } from 'components/templates/section';
import { passPropsToChildren } from 'utils/render';

type ControlPropsType = {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  children: ReactNode;
  isInnerStyle?: boolean;
  getReadOnlyValue?: (parameterName?: string) => boolean;
};

const Control = (props: ControlPropsType) => {
  const { children, isInnerStyle, ...restProps } = props;

  if (isInnerStyle) return <>{passPropsToChildren(children, restProps, Cell)}</>;

  return <>{passPropsToChildren(children, restProps)}</>;
};

export default Control;
