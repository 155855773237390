import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { isNil } from 'lodash-es';

import AccordionTr from 'components/stateless/Accordion/AccordionTr';
import { SizeType } from 'components/stateless/Form';
import Description from 'components/stateless/Form/Description';
import Label from 'components/stateless/Form/Label/Label';
import NumberInput from 'components/stateless/Form/NumberInput/NumberInput';
import { RadioGroup } from 'components/stateless/Form/RadioComponents';
import Radio from 'components/stateless/Form/RadioComponents/Radio';
import Value from 'components/stateless/Form/Value/Value';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';
import { COST_OF_FUND_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { CostOfFundVOModel } from 'models/vo/CostOfFundVO';
import type { OverdueInterestRateSettingChangeHistoryVOModel } from 'models/vo/OverdueInterestRateSettingChangeHistoryVO';
import type { OverdueInterestRateTermSpreadVOModel } from 'models/vo/OverdueInterestRateTermSpreadVO';
import { isNilOrEmptyString } from 'utils/helpers';
import { requestFiCostOfFundsDetail } from 'utils/http/api/financier/cost-of-funds';
import { requestFinancierOverdueInterestRateTermSpreadGroups } from 'utils/http/api/financier/overdue-interest-rate-settings';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import useModal from 'utils/modal/useModal';

interface DelinquentInterestRateInformationEditHistoryProps {
  detailHistories: {
    [id: number]: {
      termSpreadHistories: OverdueInterestRateTermSpreadVOModel[];
      costOfFundHistories: CostOfFundVOModel;
    };
  };
  setDetailHistories: Dispatch<
    SetStateAction<{
      [id: number]: {
        termSpreadHistories: OverdueInterestRateTermSpreadVOModel[];
        costOfFundHistories: CostOfFundVOModel;
      };
    }>
  >;
  editHistoryList?: OverdueInterestRateSettingChangeHistoryVOModel[];
  paginate: (pageNumber: number, sizePerPage: number) => Promise<void>;
  pageable: PageableType;
}

const DelinquentInterestRateInformationEditHistory = ({
  detailHistories,
  setDetailHistories,
  editHistoryList,
  paginate,
  pageable,
}: DelinquentInterestRateInformationEditHistoryProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  const HISTORY_TABLE_HEADERS: HeaderType[] = [
    { headerText: t('text:Edited_Time'), colWidths: 120 },
    { headerText: t('text:User_Name'), colWidths: 100 },
    { headerText: t('text:Activate_Status'), colWidths: 120 },
  ];

  const fetchAccordionData = async (item: OverdueInterestRateSettingChangeHistoryVOModel) => {
    const { termSpreadGroupId, id, costOfFundId } = item;
    const accordionDomId = `history-accordion-wrapper-${id}`;

    try {
      showLoadingUI(accordionDomId);
      const fetchTermSpreadList = await requestFinancierOverdueInterestRateTermSpreadGroups(termSpreadGroupId);

      if (costOfFundId === null) {
        setDetailHistories(prevState => ({
          ...prevState,
          [id]: { termSpreadHistories: fetchTermSpreadList, costOfFundHistories: null! },
        }));
      } else {
        const fetchCostOfFund = await requestFiCostOfFundsDetail(costOfFundId);
        setDetailHistories(prevState => ({
          ...prevState,
          [id]: { termSpreadHistories: fetchTermSpreadList, costOfFundHistories: fetchCostOfFund },
        }));
      }
      unShowLoadingUI(accordionDomId);
    } catch (error) {
      modal.show(error);
      unShowLoadingUI(accordionDomId);
    }
  };

  const TermSpreadInfo = ({ id }: { id: number }) => {
    const termSpreadHistories = detailHistories[id]?.termSpreadHistories || [];
    const termSpreadLength = termSpreadHistories.length;

    return (
      <TableBorder className="term-spread__table">
        <colgroup>
          <col />
          <col style={{ width: '30%' }} />
        </colgroup>
        <thead className="term-spread__head">
          <tr>
            <th scope="col">
              <Label>{t('text:Terms_Days')}</Label>
            </th>
            <th scope="col">
              <Label className="pe-5">{t('text:Term_Spread')} (%)</Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {termSpreadHistories.map((item, index) => {
            const { minimumDateRange, termSpreadRate } = item;
            const isEdgeRow = index === 0 || index === termSpreadLength - 1;

            return (
              <tr key={index} className={clsx({ 'term-spread__tr--background-gray': isEdgeRow }, 'term-spread__tr')}>
                <td>
                  <div className="term-spread__term-box">
                    <div className="form__input text-center border-none">
                      {isNilOrEmptyString(minimumDateRange)
                        ? '-'
                        : t('format:number', { value: index === 0 ? 0 : minimumDateRange })}
                    </div>
                    <div className="term-spread__angle-sign">&lt;</div>
                    <div className="form__input text-center border-none">T</div>
                    {index < termSpreadLength - 1 && (
                      <>
                        <div className="term-spread__angle-sign">≤</div>
                        <div>
                          <Value className="form__input text-center" value={item.minimumDateRange} />
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <div className="flex-end pe-5">
                    <Value className="form__input text-right" value={termSpreadRate} />
                    <span className="unit">%</span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </TableBorder>
    );
  };

  const CostFundInfo = ({ id }: { id: number }) => {
    const costFundHistoryInfo = detailHistories[id]?.costOfFundHistories;
    const useCostOfFund = !isNil(costFundHistoryInfo?.id);

    return (
      <Border isEditable={false}>
        <SubTitle>{t('text:PENALTY_FEE_SETTINGS')}</SubTitle>
        <Content>
          <Row>
            <Cell>
              <Label position="top">
                {t('text:Do_you_charge_the_penalty_fee_in_case_of_delinquent_principal_and_interest?')}
              </Label>
            </Cell>
            <Cell>
              <RadioGroup stateValue={useCostOfFund ? 'true' : 'false'}>
                <Radio value="true" label={t('text:Yes')} />
                <Radio value="false" label={t('text:No')} />
              </RadioGroup>
            </Cell>
          </Row>

          <Row>
            <Label>{t('text:From_how_many_days_after_the_start_of_delinquency_does_the_penalty_fee_incur?')}</Label>
            <Cell className={clsx({ opacityApplied: !useCostOfFund })}>
              <NumberInput
                fieldSize={SizeType.SM}
                rightUnit={t('text:Days')}
                value={String(costFundHistoryInfo?.daysToBill)}
              />
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Label position="top">{t('text:Type_of_Penalty_Fee')}</Label>
              <Description>{t('text:Please_choose_the_amount_for_the_penalty_fee')}</Description>
            </Cell>
            <Cell className={clsx({ opacityApplied: !useCostOfFund })}>
              <RadioGroup stateValue={costFundHistoryInfo?.type}>
                <Radio value={COST_OF_FUND_TYPE.FIXED} label={t('text:Fixed_Amount')}>
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Cell>
                          <Label>{t('text:Amount')}</Label>
                        </Cell>
                        <NumberInput
                          fieldSize={SizeType.MD}
                          textAlign="text-right"
                          numberType="integer"
                          value={String(costFundHistoryInfo?.amount)}
                        />
                      </Row>
                    </Content>
                  </Border>
                </Radio>
                <Radio value={COST_OF_FUND_TYPE.PRINCIPAL} label={t('text:Proportional_to_the_principal')}>
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Cell>
                          <Label>{t('text:Ratio')} (%)</Label>
                        </Cell>
                        <NumberInput
                          fieldSize={SizeType.SM}
                          rightUnit="%"
                          textAlign="text-right"
                          numberType="integer"
                          value={
                            costFundHistoryInfo?.type === COST_OF_FUND_TYPE.PRINCIPAL
                              ? String(costFundHistoryInfo?.rate)
                              : undefined
                          }
                        />
                      </Row>
                    </Content>
                  </Border>
                </Radio>
                <Radio value={COST_OF_FUND_TYPE.BALANCE} label={t('text:Proportional_to_the_delinquent_balance')}>
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Cell>
                          <Label>{t('text:Ratio')} (%)</Label>
                        </Cell>
                        <NumberInput
                          fieldSize={SizeType.SM}
                          rightUnit="%"
                          textAlign="text-right"
                          numberType="integer"
                          value={
                            costFundHistoryInfo?.type === COST_OF_FUND_TYPE.BALANCE
                              ? String(costFundHistoryInfo?.rate)
                              : undefined
                          }
                        />
                      </Row>
                    </Content>
                  </Border>
                </Radio>
              </RadioGroup>
            </Cell>
          </Row>
        </Content>
      </Border>
    );
  };

  const renderAccordionContent = (id: number) => (
    <div className="bg-sub200 p-4">
      {detailHistories[id] ? (
        <>
          <TermSpreadInfo id={id} />
          <div className="mb-4" />
          <CostFundInfo id={id} />
        </>
      ) : (
        <div id={`history-accordion-wrapper-${id}`} />
      )}
    </div>
  );

  return (
    <>
      <SectionTitle title={t('text:Information_Edit_History')} />
      <TableBorder>
        <TableHeader header={HISTORY_TABLE_HEADERS} />
        <TableBody numOfCol={HISTORY_TABLE_HEADERS.length}>
          {editHistoryList?.map((item, i) => (
            <AccordionTr key={i} id={`edit-history-${item.id}`} defaultExpanded={!!detailHistories[item.id]}>
              <AccordionTr.Trigger className="clickable-tr" onClick={() => fetchAccordionData(item)}>
                <Td data={item.updatedDateTime} format="datetime" />
                <Td data={item.updatedUserName} />
                <Td
                  className={item.activated ? 'text-bold-blue-main600' : 'text-bold-brick-red'}
                  data={item.activated ? 'On' : 'Off'}
                />
              </AccordionTr.Trigger>
              <AccordionTr.Content>{renderAccordionContent(item.id)}</AccordionTr.Content>
            </AccordionTr>
          ))}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </>
  );
};

export default DelinquentInterestRateInformationEditHistory;
