import type { WAITING_INVOICE_APPROVAL_PHASE_STATUS } from 'enums';
import type Pageable from 'models/Pageable';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

import { formattingToWaitingInvoiceVOModel } from './WaitingInvoiceVO';

import type { WaitingInvoiceVO, WaitingInvoiceVOModel } from './WaitingInvoiceVO';

export interface LastInvoicePhaseVO {
  invoiceSummaryId: number;
  waitingInvoiceApprovalPhaseId: number;
  count: number;
  amount: string;
  invoiceApprovalPhaseStatus: WAITING_INVOICE_APPROVAL_PHASE_STATUS;
  approveEnterpriseId: number;
  approveEnterpriseName: string;
  approveUserId: number;
  approveUserName: string;
  loanLimitAmount: number; // 한도 금액
  settlementWaitingInvoiceAmount: number; // 결제대기 송장금액 (대출 가능한 송장금액 + 실행전 대출 금액=결제상태가 완료되지 않은 송장금액)
  disbursedLoanAmount: number; // 실행 ~ 상환 전 대출금액
  requestedLoanAmount: number; // 신청 ~ 상환 전 대출금액
  invoiceList: Pageable<WaitingInvoiceVO[]>;
  authorized: boolean;
  registeredWaitingInvoiceAmount: number;
  inProgress: boolean;
  adhocLimitAllowable: boolean;
  adhocLimitAmount: number;
  adhocLimitStartDate: string;
  adhocLimitEndDate: string;
  dealerAgreementId: number;
}

export interface LastInvoicePhaseVOModel extends LastInvoicePhaseVO {
  amount: BigNumber;
  invoiceList: Pageable<WaitingInvoiceVOModel[]>;
}

export function formattingToLastInvoicePhaseVOModel(data: LastInvoicePhaseVO): LastInvoicePhaseVOModel {
  const { invoiceList, amount } = data;

  const formattedWaitingInvoiceList: WaitingInvoiceVOModel[] = invoiceList
    ? invoiceList.content.map((data: WaitingInvoiceVO) => formattingToWaitingInvoiceVOModel(data))
    : [];

  const formattedInvoicePage: Pageable<WaitingInvoiceVOModel[]> = {
    ...invoiceList,
    content: formattedWaitingInvoiceList,
  };

  return {
    ...data,
    amount: sliceZeroDecimal(amount),
    invoiceList: formattedInvoicePage,
  };
}
