import type { FINANCIER_TYPE } from 'enums';
import type { FinancierCommonSettingVO, FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import { formattingToFinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import http, { ContentType } from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { UpdateFinancierCommonSettingLogoRequest } from './requests';

export async function requestFinancierCommonSetting(financierId: number): Promise<FinancierCommonSettingVOModel> {
  const response = await http.get<FinancierCommonSettingVO>({
    url: API_SY.FINANCIER_COMMON_SETTING.FINANCIER_COMMON_SETTING_DETAIL(financierId),
  });

  return formattingToFinancierCommonSettingVOModel(response);
}

export async function requestFinancierCommonSettingLogoUpdate(
  data: UpdateFinancierCommonSettingLogoRequest,
): Promise<{}> {
  return await http.put<{}>({
    url: API_SY.FINANCIER_COMMON_SETTING.FINANCIER_COMMON_SETTING,
    data,
    contentType: ContentType.MULTIPART,
  });
}

export async function requestFinancierCommonSettingLogoDownload(financierCommonSettingId: number) {
  return await http.download(API_SY.FINANCIER_COMMON_SETTING.DOWNLOAD_LOGO(financierCommonSettingId));
}

export async function requestFinancierCommonSettingMisassignedFinancierTypeList(): Promise<FINANCIER_TYPE[]> {
  return await http.get<FINANCIER_TYPE[]>({
    url: API_SY.FINANCIER_COMMON_SETTING.MISASSIGNED_FINANCIER_TYPE,
  });
}
