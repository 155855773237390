import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNull } from 'lodash-es';

import IconButton from 'components/stateless/Button/IconButton';
import ToggleButton from 'components/stateless/Button/ToggleButton';
import SkeletonBox from 'components/stateless/Skeleton/SkeletonBox';
import Spinner from 'components/stateless/Spinner/Spinner';
import { ROLE } from 'enums';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useOutsideClick from 'hooks/useOutsideClick';
import type { SignInModel } from 'models/SignInModel';
import type { UserNotification } from 'models/vo/UserNotificationVO';
import { requestNotificationList, requestNotificationReadStatus } from 'utils/http/api/common/notifications';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import './Notification.scss';
import { getNotificationContents } from './contents';
import getPath from './path';

const LOADING_UI = {
  SKELETON: 'SKELETON',
  SPINNER: 'SPINNER',
} as const;

type LoadingUIType = keyof typeof LOADING_UI;

export interface NotificationPropsType {
  isVisible: boolean;
  closeNotification: () => void;
  updateHasNewNotification: () => void;
}

const Notification = ({ isVisible, closeNotification, updateHasNewNotification }: NotificationPropsType) => {
  const signInModel: SignInModel | null = getSignIn();
  const role = signInModel?.authorities[0].authority as ROLE;

  const [notificationItems, setNotificationItems] = useState<UserNotification[]>([]);
  const [loadingUI, setLoadingUI] = useState<LoadingUIType | null>(null);
  const [turnOnUnreadFilter, setTurnOnUnreadFilter] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(isVisible);

  const hasNextRef = useRef(true);
  const lastNotificationRef = useRef<HTMLLIElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  useOutsideClick(closeNotification, notificationRef);

  const { t } = useTranslation(['format']);
  const history = useHistory();
  const { show: showModal } = useModal();

  const lastNotificationId = notificationItems && notificationItems.at(-1)?.userNotificationId;

  const getNotificationItems = async () => {
    if (role === ROLE.ROLE_SYSTEM) return;
    if (!hasNextRef.current) return;

    try {
      const notificationResponse = await requestNotificationList(turnOnUnreadFilter, lastNotificationId);
      if (notificationResponse) {
        updateHasNewNotification();
        hasNextRef.current = notificationResponse.hasNext;
        setNotificationItems(notificationItems => [...notificationItems, ...notificationResponse.content]);
      }
    } catch (error) {
      showModal(error);
      setLoadingUI(null);
    } finally {
      setLoadingUI(null);
    }
  };

  useEffect(() => {
    if (isVisible) {
      setLoadingUI(LOADING_UI.SKELETON);
      getNotificationItems();
    } else {
      setTurnOnUnreadFilter(false);
      hasNextRef.current = true;
      setNotificationItems([]);
    }
  }, [isVisible, turnOnUnreadFilter]);

  const handleToggleClick = () => {
    setTurnOnUnreadFilter(prevState => !prevState);
    setNotificationItems([]);
    setLoadingUI(LOADING_UI.SKELETON);
    hasNextRef.current = true;
  };

  const handleIntersection = () => {
    setLoadingUI(LOADING_UI.SPINNER);
    getNotificationItems();
  };

  const [observe, disconnect] = useIntersectionObserver<HTMLLIElement>({
    target: lastNotificationRef,
    callback: handleIntersection,
    customOptions: { threshold: 1 },
  });

  useEffect(() => {
    if (isNull(loadingUI) && hasNextRef.current) observe();

    return () => disconnect();
  }, [loadingUI]);

  const fetchNotificationReadStatus = useCallback(
    (role: ROLE, notificationItemIdRange: { startId: number; endId: number }) => {
      if (role === ROLE.ROLE_SYSTEM) return;
      const hasLoadingUI = notificationItemIdRange.startId === notificationItemIdRange.endId; // 단일 읽음 처리인 경우

      return requestNotificationReadStatus(notificationItemIdRange, hasLoadingUI);
    },
    [],
  );

  const updateNotificationReadStatus = (notificationItemId?: number) => {
    const condition = (notificationItem: UserNotification) =>
      notificationItemId ? notificationItem.userNotificationId === notificationItemId : true;
    setNotificationItems(notificationItems => {
      return notificationItems.map(notificationItem => {
        return condition(notificationItem) ? { ...notificationItem, notificationRead: true } : notificationItem;
      });
    });
  };

  const handleNotificationItemClick = async (item: UserNotification) => {
    try {
      if (!item.notificationRead) {
        await fetchNotificationReadStatus(role, { startId: item.userNotificationId, endId: item.userNotificationId });
        updateNotificationReadStatus(item.userNotificationId);
      }
      closeNotification();
      const path = getPath(role, item);
      if (path) history.push(path.route, path.state);
    } catch (error) {
      showModal(error);
    }
  };

  const handleNotificationReadButtonClick = async () => {
    setLoadingUI(LOADING_UI.SKELETON);

    try {
      const [startId, endId] = [notificationItems[0].userNotificationId, notificationItems.at(-1)?.userNotificationId];
      if (startId && endId) {
        await fetchNotificationReadStatus(role, { startId, endId });
        updateNotificationReadStatus();
      }
    } catch (error) {
      showModal(error);
      setLoadingUI(null);
    } finally {
      setLoadingUI(null);
    }
  };

  const renderSkeleton = () => {
    return Array.from({ length: 20 }, (_, index) => {
      const className = index !== 0 ? 'mt-2' : '';

      return <SkeletonBox key={index} className={className} width="100%" height="75px" />;
    });
  };

  const getNotificationItemContents = (notificationItem: UserNotification) => {
    if (role === ROLE.ROLE_SYSTEM) return;
    const contents = getNotificationContents(notificationItem, t);

    if (!contents) return;
    for (const { condition, ...rest } of contents()) {
      if (condition) return rest;
    }
  };

  useEffect(() => {
    if (localVisible && !isVisible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(isVisible);
  }, [localVisible, isVisible]);

  if (!animate && !localVisible) return null;

  return (
    <div className={`notification ${isVisible ? '' : 'fade-out'}`}>
      <div className={`notification__content-wrap ${isVisible ? '' : 'slide-out'}`} ref={notificationRef}>
        <div className="notification__header">
          <IconButton className="notification__close-button" onClick={closeNotification}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </IconButton>
          <h3 className="notification__title">{t('text:Notifications')}</h3>
          <div className="notification__toggle-area">
            <p>{t('text:Only_show_unread')}</p>
            <ToggleButton isActive={turnOnUnreadFilter} onClick={handleToggleClick} disabled={!isNull(loadingUI)} />
          </div>
        </div>
        <div className="notification__content">
          <ul>
            {loadingUI === LOADING_UI.SKELETON && renderSkeleton()}
            {isNull(loadingUI) && notificationItems.length === 0 && (
              <li className="notification__item--no-data">
                <FontAwesomeIcon icon={faBell} color="#c6c6c6" fontSize="4rem" />
                {t('text:There_are_no_notifications')}
              </li>
            )}
            {notificationItems.length > 0 &&
              notificationItems.map((item, index) => {
                if (loadingUI === LOADING_UI.SKELETON) return;

                const contents = getNotificationItemContents(item);
                if (!contents) return;

                return (
                  <li
                    key={index}
                    className={`notification__item ${item.notificationRead ? '' : 'notification__item--unread'}`}
                    onClick={() => handleNotificationItemClick(item)}
                    ref={index === notificationItems.length - 1 ? lastNotificationRef : null}
                  >
                    <h4 className="notification__item-title mb-1">{contents.title}</h4>
                    <p>{contents.description}</p>
                    <p className="notification__item-date mt-1">
                      {t('format:datetime-with-no-second', {
                        value: item.createdDateTime,
                        key: 'datetime',
                      })}
                    </p>
                  </li>
                );
              })}
            {loadingUI === LOADING_UI.SPINNER && (
              <li className="notification__item">
                <Spinner />
              </li>
            )}
          </ul>
        </div>
        {(!isNull(loadingUI) || notificationItems.length !== 0) && (
          <div className="notification__footer">
            <button
              className="notification__read-button"
              onClick={handleNotificationReadButtonClick}
              disabled={!isNull(loadingUI)}
            >
              {t('text:Mark_all_as_read')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
