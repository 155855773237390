import { WAITING_INVOICE_STATUS, stringToEnum } from 'enums';

import type { InvoiceAdditionalDataVO } from './InvoiceAdditionalDataVO';

export interface WaitingInvoiceVO {
  waitingInvoiceId: number;
  waitingInvoiceApprovalPhaseId: number;
  invoiceNumber: string;
  referenceNumber: string;
  invoiceIssuedDate: string;
  settlementDate: string;
  invoiceAmount: number;
  waitingInvoiceStatus: string;
  billOfExchangeNo: string;
  invoiceAdditionalDataList?: InvoiceAdditionalDataVO[];
  exceptionMessage?: string;
}

export interface WaitingInvoiceVOModel extends WaitingInvoiceVO {
  waitingInvoiceStatus: WAITING_INVOICE_STATUS;
}

export function formattingToWaitingInvoiceVOModel(data: WaitingInvoiceVO): WaitingInvoiceVOModel {
  return {
    ...data,
    waitingInvoiceStatus: stringToEnum(WAITING_INVOICE_STATUS, data.waitingInvoiceStatus),
  };
}
