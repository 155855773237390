import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import useMounted from 'hooks/useMounted';
import type { UserVOModel } from 'models/vo/UserVO';
import { requestFinancierInterface } from 'utils/http/api/financier/users';
import useModal from 'utils/modal/useModal';

interface UpdateUserModalProps {
  userCode: string;
  userData: UserVOModel | undefined;
  getUpdatedUserData: (data: UserVOModel) => void;
  modalId: number;
}

function UpdateUserModal({ userData, getUpdatedUserData, userCode, modalId }: UpdateUserModalProps) {
  const { t } = useTranslation();
  const mounted = useMounted();
  const modal = useModal();
  const [financierInterfaceUserData, setFinancierInterfaceUserData] = useState<UserVOModel>(); // 전문 연동

  useEffect(() => {
    if (mounted) {
      fetch();
    }

    return () => {};
  }, [mounted]);

  async function fetch() {
    if (isNil(userCode)) return;
    try {
      const financierUserDetailData = await requestFinancierInterface(userCode);
      setFinancierInterfaceUserData(financierUserDetailData);
      getUpdatedUserData(financierUserDetailData);
    } catch (e: any) {
      modal.close(modalId);
      modal.show(e);
    }
  }

  const getUpdatedInputClassName = (originData: any, updatedData: any) => {
    return `information-form__input ${originData !== updatedData ? 'updated__bg__input' : ''}`;
  };

  return (
    <>
      <div className="changed-data-form d-flex justify-content-end mb-2">
        <span className="changed-data" />
        <p>{t('text:Changed_data')}</p>
      </div>
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormValue
              label={t('text:User_Code')}
              className={getUpdatedInputClassName(userData?.employeeCode, financierInterfaceUserData?.employeeCode)}
              value={financierInterfaceUserData?.employeeCode}
            />
            <FormValue
              label={t('text:User_Name')}
              className={getUpdatedInputClassName(userData?.userName, financierInterfaceUserData?.userName)}
              value={financierInterfaceUserData?.userName}
            />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Telephone')}
              className={getUpdatedInputClassName(userData?.telephone, financierInterfaceUserData?.telephone)}
              value={financierInterfaceUserData?.telephone}
            />
            <FormValue
              label={t('text:Email')}
              className={getUpdatedInputClassName(userData?.email, financierInterfaceUserData?.email)}
              value={financierInterfaceUserData?.email}
            />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Branch_Code')}
              className={getUpdatedInputClassName(userData?.branchCode, financierInterfaceUserData?.branchCode)}
              value={financierInterfaceUserData?.branchCode}
            />
            <FormValue
              label={t('text:Branch_Name')}
              className={getUpdatedInputClassName(userData?.branchName, financierInterfaceUserData?.branchName)}
              value={financierInterfaceUserData?.branchName}
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default UpdateUserModal;
