import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import type { CreateFinancierAndAdminUserDTO } from 'utils/http/api/anonymous/on-demand/requests';

import CreateFinancierStep1 from './step1/CreateFinancierStep1';
import CreateFinancierStep1Provider from './step1/CreateFinancierStep1Provider';
import CreateFinancierStep2 from './step2/CreateFinancierStep2';
import CreateFinancierStep2Provider from './step2/CreateFinancierStep2Provider';
import { useCreateFinancierValue } from '../CreateFinancierProvider';

const CreateFinancierStepWrapper = () => {
  const { t } = useTranslation();

  const { activeStep } = useCreateFinancierValue();
  const createFinancierForm = useForm<CreateFinancierAndAdminUserDTO>({
    shouldUnregister: false,
  });

  return (
    <>
      <PublicHeaderBar />
      <div style={{ width: '910px' }} className="common-content pb-5">
        <HeaderTitle className="w-100" title={t('text:Register_Financier')} />
        <StepWizard
          nth={activeStep}
          title={[t('text:Enter_Financier_Information'), t('text:Enter_Financier_Admin_Information')]}
        />
        <div className="pb-5" />
        <form>
          <FormProvider {...createFinancierForm}>
            <CreateFinancierStep1Provider>{activeStep === 1 && <CreateFinancierStep1 />}</CreateFinancierStep1Provider>
            <CreateFinancierStep2Provider>{activeStep === 2 && <CreateFinancierStep2 />}</CreateFinancierStep2Provider>
          </FormProvider>
        </form>
      </div>
    </>
  );
};

export default CreateFinancierStepWrapper;
