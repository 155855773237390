import type React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import { NumericFormatInput } from 'components/stateless/CommonForm';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { BASIS_INTEREST_TYPE, DOCUMENT_FORM_TYPE, LOAN_TERM_TYPE } from 'enums';
import useProperty from 'hooks/useProperty';
import type { DealerSuccessInvoiceLoanRequest } from 'utils/http/api/dealer/success-invoices/request';

import { useFinancingApplication } from './logics';

import type { DealerInvoiceApplicationStep2State } from '../../useDealerInvoiceFinancingApplicationStep2State';

interface FinancingApplicationProps {
  dealerInvoiceApplicationStep2State: DealerInvoiceApplicationStep2State;
  loanAgreementAttachmentChangedState: {
    loanAgreementAttachmentChanged: boolean;
    setLoanAgreementAttachmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  };
  loanRequestAttachmentChangedState: {
    loanRequestAttachmentChanged: boolean;
    setLoanRequestAttachmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  };
  fetchDealerInvoiceCalculateLoanRequest: () => Promise<void>;
  repaymentDateRef: React.RefObject<HTMLDivElement>;
  estimateButtonRef: React.RefObject<HTMLDivElement>;
  initializeExpectedInterestRate: () => void;
}

function FinancingApplication({
  dealerInvoiceApplicationStep2State,
  loanAgreementAttachmentChangedState,
  loanRequestAttachmentChangedState,
  fetchDealerInvoiceCalculateLoanRequest,
  repaymentDateRef,
  estimateButtonRef,
  initializeExpectedInterestRate,
}: FinancingApplicationProps) {
  const { t } = useTranslation(['format']);
  const getProperty = useProperty<DealerSuccessInvoiceLoanRequest>();
  const { register, control, errors, watch } = useFormContext();
  const {
    dealerAgreementDetail,
    dealerInvoiceDetail,
    dealerInvoiceCalculateLoanRequest,
    fiCommonSettingData,
    minDate,
    maxDate,
    expectedInterestRate,
    maxLoanableAmount,
  } = dealerInvoiceApplicationStep2State;
  const { loanAgreementAttachmentChanged } = loanAgreementAttachmentChangedState;
  const { loanRequestAttachmentChanged } = loanRequestAttachmentChangedState;
  const { requestedLoanAmount, repaymentDate } = watch();

  const {
    applicationFileName,
    agreementFileName,
    getFinancierHolidayDisabledRanges,
    getFinancingTerm,
    getInterestRateAdJustment,
    getUseTermSpreadRate,
    showDocumentTypeBtn,
    onClickApplicationDownloadForm,
    onClickAgreementDownloadForm,
    onChangeApplicationFileName,
    resetApplicationFileName,
    onChangeAgreementFileName,
    resetAgreementFileName,
    onClickApplicationFileDownLoad,
    onClickAgreementFileDownLoad,
    ltvCalculate,
  } = useFinancingApplication({
    dealerInvoiceApplicationStep2State,
    repaymentDate,
    loanAgreementAttachmentChangedState,
    loanRequestAttachmentChangedState,
    requestedLoanAmount,
  });

  const handleClickEstimateButton = async () => {
    await fetchDealerInvoiceCalculateLoanRequest();
  };

  const estimateButtonDisabled = () => {
    if (dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE) {
      // repayment date editable
      return !repaymentDate;
    } else {
      return false;
    }
  };

  return (
    <>
      <SectionTitle title={t('text:Fill_out_Financing_Application')} />
      <GuideMessage
        isImportContentArea={true}
        message={[
          t('text:Fill_out_the_necessary_information_and_check_the_financing_condition_details'),
          t('text:Upload_the_Financing_Application_Document_and_the_Financing_Agreement_Document'),
          t('text:Click_on_the_checkbox_to_confirm_the_financing_request_information_is_correct'),
        ]}
      />
      <div className="information-form__detail-form border-bott-none mt-4">
        <div className="detail-title bg-sub100 border-none">
          <div className="d-flex justify-content-between w-50">
            {dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE ? (
              <>
                <div ref={repaymentDateRef}>{t('text:Requested_Repayment_Date')}</div>
                <div className="d-flex align-items-center">
                  <Controller
                    control={control}
                    name={getProperty('repaymentDate')}
                    defaultValue={null}
                    render={field => (
                      <ReactDatePicker
                        field={field}
                        className="detail-title-input"
                        minDate={minDate}
                        maxDate={maxDate}
                        excludeDates={getFinancierHolidayDisabledRanges()}
                        onChange={() => {
                          initializeExpectedInterestRate();
                        }}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </>
            ) : (
              <>
                <div>{t('text:Requested_Repayment_Date')}</div>
                <div>
                  {t('format:date', {
                    value: dealerInvoiceDetail?.scheduledRepaymentDate,
                    key: 'date',
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Scheduled_Disbursement_Date')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {t('format:date', {
                  value: dealerInvoiceDetail?.settlementDate,
                  key: 'date',
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Financing_Term')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">{getFinancingTerm()}</div>
            </div>
          </div>
        </div>
        <div className="detail-title bg-sub100 border-none">
          <div className="d-flex w-50 justify-content-between">
            <div>{t('text:Requested_Financing_Amount')}</div>

            <div className="d-flex align-items-center">
              <NumericFormatInput
                control={control}
                name="requestedLoanAmount"
                className="detail-title-input"
                numberType="bigNumber"
                currencyType={dealerInvoiceDetail?.currencyType}
                error={errors.requestedLoanAmount}
                disabled={!fiCommonSettingData.invoiceFinancingAmountAdjustable}
              />
            </div>
          </div>
          {fiCommonSettingData.invoiceFinancingAmountAdjustable && (
            <div className="d-flex w-50 justify-content-between">
              <div className="d-flex gap-3">
                <span>≤</span>
                <div>{t('text:Maximum_Applicable_Amount')}</div>
                <div>
                  {`${t('format:number', {
                    value: maxLoanableAmount,
                  })} ${dealerInvoiceDetail.currencyType}`}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Invoice_Amount')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {t('format:number', {
                  value: dealerInvoiceDetail?.invoiceAmount,
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Financing_to_Invoice_Amount')} %</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">{ltvCalculate()}</div>
            </div>
          </div>
        </div>
        <div className="detail-title bg-sub100">
          <div className="d-flex w-50 justify-content-between">
            <div className="me-auto">{t('text:Expected_Interest(APR)')}</div>
            <div className="align-self-center pe-3" ref={estimateButtonRef}>
              <Button
                onClick={handleClickEstimateButton}
                className="flex-column-center text-bold"
                disabled={estimateButtonDisabled()}
              >
                {t('text:Estimate')}
              </Button>
            </div>
            <div>{expectedInterestRate}</div>
          </div>
        </div>
        <div className="information-form information-flex__form bg-white">
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Base_Interest_Type')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {dealerAgreementDetail?.basisInterestType}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">
                {dealerAgreementDetail?.basisInterestType === BASIS_INTEREST_TYPE.FIXED
                  ? t('text:Basis_Interest_Rate(APR)')
                  : t('text:Base_Interest_Term')}
              </div>
            </div>
            <div className="col-3">
              {dealerAgreementDetail?.basisInterestType === BASIS_INTEREST_TYPE.FIXED ? (
                <div className="information-form__input flex-input gray-bold-font">
                  {t('format:number', { value: dealerAgreementDetail?.basisInterestRate }) + '%'}
                </div>
              ) : (
                <div className="information-form__input flex-input gray-bold-font">
                  {dealerAgreementDetail?.basisInterestTerm ? `${dealerAgreementDetail.basisInterestTerm}` : undefined}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Credit_Spread_(%)')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">
                {t('format:number', {
                  value: dealerAgreementDetail?.creditInterestRate,
                })}
                %
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="information-form__label flex-label">{t('text:Interest_Rate_Adjustment_(%)')}</div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">{getInterestRateAdJustment()}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="d-flex h-100">
                <div className="information-form__label flex-label w-auto me-4">{t('text:Term_Spread')}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="information-form__input flex-input gray-bold-font">{getUseTermSpreadRate()}</div>
            </div>
          </div>
          {dealerAgreementDetail.showExpectedTotalInterestRate && (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="border-bottom-light-gray my-3" />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="information-form__label flex-label">{t('text:Expected_Interest_Amount')}</div>
                </div>
                <div className="col-3">
                  <div className="information-form__input flex-input gray-bold-font">
                    {t('format:number', {
                      value: dealerInvoiceCalculateLoanRequest?.expectedInterestAmount,
                    })}{' '}
                    {dealerInvoiceDetail?.currencyType}
                  </div>
                </div>
              </div>
            </>
          )}
          {dealerAgreementDetail.showExpectedTotalInterestRate && dealerAgreementDetail.showNetDisbursementAmount && (
            <div className="row">
              <div className="col-3">
                <div className="information-form__label flex-label">
                  {t('text:Advance_Disbursement')}
                  <QuestionTooltip
                    id="netDisburseAmountTooltip"
                    place="top"
                    contentText={
                      <>
                        <div>{t('text:Principal_Amount')} ⓐ</div>
                        <div>{t('text:Prepayment_Interest')} ⓑ</div>
                        <div>--------------------------------------------------------</div>
                        <div>{t('text:Advance_Disbursement')} ⓒ = ⓐ - ⓑ</div>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="information-form__input flex-input gray-bold-font">
                  {t('format:number', {
                    value: dealerInvoiceCalculateLoanRequest?.netDisbursementAmount,
                  })}{' '}
                  {dealerInvoiceDetail?.currencyType}
                </div>
              </div>
            </div>
          )}
        </div>
        <FormSubtitle backGroundType={BackGroundType.Gray} title={t('text:Financing_Application_Document')}>
          <div className="flex-end align-items-center me-3">
            {showDocumentTypeBtn(DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_REQUEST) && (
              <Button onClick={onClickApplicationDownloadForm} style={{ lineHeight: 1.4 }} bold>
                {t('text:Download_Form')}
              </Button>
            )}
          </div>
        </FormSubtitle>
        <div className="detail-in-file-upload-form">
          <div className="file-upload">
            <div className="d-flex justify-content-between">
              <input
                name={getProperty('loanRequestAttachment')}
                type="file"
                id="loanRequestAttachmentFile"
                style={{ display: 'none' }}
                ref={register}
                onChange={onChangeApplicationFileName}
              />
              <label htmlFor="loanRequestAttachmentFile" className="attach-file-link-button">
                {t('text:Attach_File')}
              </label>
              <div id="fileName" className="upload-file-input">
                {applicationFileName ? applicationFileName : t('text:No_file_attached')}
                {applicationFileName && !loanRequestAttachmentChanged && (
                  <IconButton onClick={onClickApplicationFileDownLoad} type="button">
                    <FontAwesomeIcon icon={faDownload} />
                  </IconButton>
                )}
              </div>
              <IconButton
                onClick={resetApplicationFileName}
                className={`delete-uploaded-excel-button ${!applicationFileName && 'd-none'}`}
                type="button"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
            </div>
          </div>
        </div>
        <FormSubtitle backGroundType={BackGroundType.Gray} title={t('text:Financing_Agreement_Document')}>
          <div className="flex-end align-items-center me-3">
            {showDocumentTypeBtn(DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_AGREEMENT) && (
              <Button onClick={onClickAgreementDownloadForm} style={{ lineHeight: 1.4 }} bold>
                {t('text:Download_Form')}
              </Button>
            )}
          </div>
        </FormSubtitle>
        <div className="detail-in-file-upload-form">
          <div className="file-upload">
            <div className="d-flex justify-content-between">
              <input
                type="file"
                id="loanAgreementAttachmentFile"
                className="d-none"
                name={getProperty('loanAgreementAttachment')}
                ref={register}
                onChange={onChangeAgreementFileName}
              />
              <label htmlFor="loanAgreementAttachmentFile" className="attach-file-link-button">
                {t('text:Attach_File')}
              </label>
              <div id="fileName" className="upload-file-input">
                {agreementFileName ? agreementFileName : t('text:No_file_attached')}
                {agreementFileName && !loanAgreementAttachmentChanged && (
                  <IconButton onClick={onClickAgreementFileDownLoad} type="button">
                    <FontAwesomeIcon icon={faDownload} />
                  </IconButton>
                )}
              </div>
              <IconButton
                onClick={resetAgreementFileName}
                className={`delete-uploaded-excel-button ${!agreementFileName && 'd-none'}`}
                type="button"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinancingApplication;
