import { ENTERPRISE_STATUS, ENTERPRISE_TYPE, stringToEnum } from 'enums';

export interface EnterpriseVO {
  enterpriseId: number;
  enterpriseName: string;
  businessCode: string;
  address: string;
  telephone: string;
  enterpriseStatus: string;
  enterpriseType: string;
  taxCode: string;
  zipCode: string;
  fax: string;
  representativeName: string;
  representativeEmployeeCode: string;
  representativeEmail: string;
  representativeMobile: string;
  representativePosition: string;
}

export interface EnterpriseVOModel extends EnterpriseVO {
  enterpriseStatus: ENTERPRISE_STATUS;
  enterpriseType: ENTERPRISE_TYPE;
}

export function formattingToEnterpriseVOModel(data: EnterpriseVO): EnterpriseVOModel {
  return {
    ...data,
    enterpriseStatus: stringToEnum(ENTERPRISE_STATUS, data.enterpriseStatus),
    enterpriseType: stringToEnum(ENTERPRISE_TYPE, data.enterpriseType),
  };
}
