import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import { Cell, Content, Row } from 'components/templates/section';
import { DEALER_AGREEMENT_STATUS } from 'enums';
import { getProgramTypeText } from 'utils/text';

import useGeneralInformationController from './useGeneralInformationController';

const GeneralInformation = () => {
  const { t } = useTranslation();

  const {
    register,
    supportedCollateralType,
    isCurrencyTypeReadOnly,
    isInvoice,
    isDealerAgreementStatusRender,
    selectCurrencyTypeOptions,
    currencyType,
  } = useGeneralInformationController();

  return (
    <Content>
      <Row>
        <Form.Control name="contractNo" required>
          <Form.Label>{t('text:Partner_Master_Agreement_Number')}</Form.Label>
          <Form.Input />
        </Form.Control>
      </Row>
      {isInvoice && (
        <Row>
          <Form.Control name="dealerCodeByAnchor">
            <Cell>
              <Form.Label>{t('text:Partner_Code_of_Anchor')}</Form.Label>
              <Form.Description>
                {t(
                  'text:This_field_is_required_only_if_the_Anchor_company_uses_a_unique_identifier_code_when_registering_invoices',
                )}
              </Form.Description>
            </Cell>
            <Form.Input />
          </Form.Control>
        </Row>
      )}
      <Row>
        <Form.Control>
          <Form.Label>{t('text:Program_Type')}</Form.Label>
          <Form.Value value={getProgramTypeText(supportedCollateralType)} />
          <input type="hidden" name="collateralType" defaultValue={supportedCollateralType} ref={register} />
        </Form.Control>
      </Row>
      <Row>
        <Form.Control name="currencyType" required={!isCurrencyTypeReadOnly}>
          <Form.Label>{t('text:Currency')}</Form.Label>
          <Form.Select
            selectOptions={selectCurrencyTypeOptions}
            placeholderOptions={{ show: true }}
            readOnly={isCurrencyTypeReadOnly}
            defaultValue={currencyType}
            showError={!isCurrencyTypeReadOnly}
          />
        </Form.Control>
      </Row>
      <Row>
        <Form.Control name="startDate" required>
          <Form.Label>{t('text:Effective_Date')}</Form.Label>
          <Form.DatePickerInput />
        </Form.Control>
      </Row>
      <Row>
        <Form.Control name="expiryDate" required>
          <Form.Label>{t('text:Expiration_Date')}</Form.Label>
          <Form.DatePickerInput />
        </Form.Control>
      </Row>
      {isDealerAgreementStatusRender && (
        <Row>
          <Form.Control name="dealerAgreementStatus" required>
            <Cell>
              <Form.Label>{t('text:Agreement_Suspension')}</Form.Label>
              <Form.Description>
                {t('text:Suspend_the_agreement_to_block_all_associated_transactions')}
              </Form.Description>
            </Cell>
            <Form.Radio>
              <Form.Option label={t('text:Not_Suspended')} value={DEALER_AGREEMENT_STATUS.ACTIVATED} />
              <Form.Option label={t('text:Suspended')} value={DEALER_AGREEMENT_STATUS.SUSPENDED} />
            </Form.Radio>
          </Form.Control>
        </Row>
      )}
    </Content>
  );
};

export default GeneralInformation;
