import type Pageable from 'models/Pageable';
import type { BankCodeVO, BankCodeVOModel } from 'models/vo/BankCodeVO';
import { formattingToBankCodeVOModel } from 'models/vo/BankCodeVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierBankCodeListRequest } from './requests';

export async function requestFinancierBankCodeList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierBankCodeListRequest,
): Promise<Pageable<BankCodeVOModel[]>> {
  const response = await http.get<Pageable<BankCodeVO[]>>({
    url: API_FI.BANK_CODE.BANK_CODE_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToBankCodeVOModel(item)),
  };
}
