import { useTranslation } from 'react-i18next';

// 실패-송장 등록 한도 금액 초과 모달
function InvoiceAmountExceedModal() {
  const { t } = useTranslation();

  return (
    <h6>
      {t('text:The_total_sum_of_the_selected_invoices_cannot_be_greater_than_the_remaining_limit')}
      <br />
      {t('text:Please_check_the_remaining_limit_and_register_again')}
    </h6>
  );
}

export default InvoiceAmountExceedModal;
