import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { COLLATERAL_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { ExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import { convertToServerDateFormat, getDayTerm, removeRangeFromHoliday } from 'utils/date/date';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestDealerFinancierCalendar } from 'utils/http/api/dealer/financier-calendar';
import { requestDealerCalculateExpectedAmount } from 'utils/http/api/dealer/loans';
import type { DealerCalculateExpectedAmountRequest } from 'utils/http/api/dealer/loans/request';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

export interface RequestEarlyRepaymentModalPropType {
  loanId: number;
  repaymentDate: string;
  disbursedDate: string;
  financierId: number;
  collateralType: COLLATERAL_TYPE;
  dealerCalculateExpectedAmountRequest: DealerCalculateExpectedAmountRequest;
}

function RequestEarlyRepaymentModal({
  loanId,
  repaymentDate,
  disbursedDate,
  dealerCalculateExpectedAmountRequest,
  financierId,
  collateralType,
}: RequestEarlyRepaymentModalPropType) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const [expectedRepaymentData, setExpectedRepaymentData] = useState<ExpectedLoanRepaymentVOModel>();
  const maxDate = dayjs(new Date(repaymentDate)).subtract(1, 'days').toDate();
  const [minDate, setMinDate] = useState<Date>();
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회
  const { control, watch, getValues, setError, clearErrors, errors } = useForm<DealerCalculateExpectedAmountRequest>({
    mode: 'onSubmit',
  });
  const { expectedRepaymentDate } = watch();

  const [requestedRepaymentDate, setRequestedRepaymentDate] = useState<string>();

  useEffect(() => {
    Object.assign(dealerCalculateExpectedAmountRequest, getValues());
  }, [expectedRepaymentDate]);

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = async () => {
    let minimumEarlyRepayment: number | undefined; // Minimum required days after disbursement
    try {
      const fetchedFinancierCommonSettingData = await requestFinancierSettingData(financierId);

      // collateralType에 따른 api 호출 / minimumEarlyRepayment setting
      if (collateralType === COLLATERAL_TYPE.INVOICE) {
        if (fetchedFinancierCommonSettingData.minimumPeriodForDealerFinancingEarlyRepayment) {
          minimumEarlyRepayment = Number(
            fetchedFinancierCommonSettingData.minimumPeriodForDealerFinancingEarlyRepayment,
          );
        } else {
          minimumEarlyRepayment = 0;
        }
      } else {
        if (fetchedFinancierCommonSettingData.minimumPeriodForVendorFinancingEarlyRepayment) {
          minimumEarlyRepayment = Number(
            fetchedFinancierCommonSettingData.minimumPeriodForVendorFinancingEarlyRepayment,
          );
        } else {
          minimumEarlyRepayment = 0;
        }
      }

      const today = new Date();
      // disbursedDate + minimumEarlyRepayment
      const disbursementDate = dayjs(new Date(disbursedDate)).add(minimumEarlyRepayment, 'days').toDate();
      const minDate = disbursementDate && disbursementDate > today ? disbursementDate : today;

      const fetchFinancierHoliday = await requestDealerFinancierCalendar(financierId, {
        pageNumber: 0,
        rowCount: Number(getDayTerm(convertToServerDateFormat(minDate), convertToServerDateFormat(maxDate))) + 1,
        fromDate: convertToServerDateFormat(minDate),
        toDate: convertToServerDateFormat(maxDate),
        holiday: true,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierHoliday(fetchFinancierHoliday);
        setMinDate(minDate);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickEstimate = async (e: any): Promise<void> => {
    e.preventDefault();
    setRequestedRepaymentDate(getValues('expectedRepaymentDate'));

    try {
      const response = await requestDealerCalculateExpectedAmount(loanId, getValues('expectedRepaymentDate'));
      setExpectedRepaymentData(response);
    } catch (error) {
      modal.show(error);
      formErrorHandler<DealerCalculateExpectedAmountRequest>(error, setError, clearErrors);
    }
  };

  return (
    <FormBorder editable>
      <FormContents>
        <div className="row mb-3">
          <FormDatePickerInput
            col={9}
            label={t('text:Repayment_Date')}
            name="expectedRepaymentDate"
            excludeDates={removeRangeFromHoliday(financierHoliday?.content)}
            minDate={minDate}
            maxDate={maxDate}
            control={control}
            disabled={minDate! > maxDate}
            error={errors.expectedRepaymentDate}
          />
          <div className="col-3 align-self-end">
            <Button
              size={ButtonSizeEnum.MD}
              onClick={onClickEstimate}
              disabled={!expectedRepaymentDate}
              style={{ lineHeight: 1.4 }}
            >
              {t('text:Apply')}
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FormBorder>
              <FormSubtitle title={t('text:Expected_Repayment')} backGroundType={BackGroundType.DarkGray} />
              <FormContents backGroundType={BackGroundType.WHITE}>
                <div className="row">
                  <div className="col-12">
                    <div className="information-form__label">{t('text:Original_Repayment_Date')}</div>
                    <div className="information-form__input border-none">
                      {tableValueManage(repaymentDate, t('format:date', { value: repaymentDate, key: 'date' }))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="information-form__label">{t('text:Requested_Repayment_Date')}</div>
                    <div className="information-form__input border-none">
                      {requestedRepaymentDate
                        ? tableValueManage(
                            requestedRepaymentDate,
                            t('format:date', { value: requestedRepaymentDate, key: 'date' }),
                          )
                        : '-'}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="information-form__label">{t('text:Original_Loan_Term')}</div>
                    <div className="information-form__input border-none">
                      {getDayTerm(disbursedDate, repaymentDate, {
                        includeStartDate: true,
                        withText: true,
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="information-form__label">{t('text:Modified_Loan_Term')}</div>
                    <div className="information-form__input border-none">
                      {getDayTerm(disbursedDate, requestedRepaymentDate, {
                        includeStartDate: true,
                        withText: true,
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="information-form__label">{t('text:Expected_Principal_Amount')}</div>
                    <div className="information-form__input border-none">
                      {requestedRepaymentDate
                        ? tableValueManage(
                            expectedRepaymentData?.scheduledPrincipalAmount,
                            t('format:number', { value: expectedRepaymentData?.scheduledPrincipalAmount }),
                          )
                        : '-'}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="information-form__label">{t('text:Expected_Normal_Interest_Amount')}</div>
                    <div className="information-form__input border-none">
                      {requestedRepaymentDate
                        ? tableValueManage(
                            expectedRepaymentData?.scheduledInterestAmount,
                            t('format:number', { value: expectedRepaymentData?.scheduledInterestAmount }),
                          )
                        : '-'}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="information-form__label">{t('text:Expected_Delinquent_Interest_Amount')}</div>
                    <div className="information-form__input border-none">
                      {requestedRepaymentDate
                        ? tableValueManage(
                            expectedRepaymentData?.scheduledDelayAmount,
                            t('format:number', { value: expectedRepaymentData?.scheduledDelayAmount }),
                          )
                        : '-'}
                    </div>
                  </div>
                </div>
              </FormContents>
            </FormBorder>
          </div>
          <div className="text-bold-sub700 my-2">
            * {t('text:The_actual_interest_amount_will_be_determined_on_the_date_of_repayment')}
          </div>
          <div className="text-bold-sub700">
            * {t('text:The_requested_repayment_date_will_be_applied_after_the_confirmation_of_financier')}
          </div>
        </div>
      </FormContents>
    </FormBorder>
  );
}

export default RequestEarlyRepaymentModal;
