import type { ChangeEvent } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import Select from '../Select/Select';

import type { SelectOptionType } from '../Select/Select';

interface PropsType {
  name: string;
  disabled?: boolean;
  col?: number;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  selectOptions: SelectOptionType[];
}
const SearchSelect = forwardRef(
  ({ name = '', disabled = false, col = 4, onChange = () => {}, selectOptions }: PropsType, ref) => {
    const selectRef = useRef<HTMLSelectElement | null>(null);
    useImperativeHandle(ref, () => selectRef.current);

    return (
      <div className={`search-form__input-wrap col-${col}`}>
        <Select
          className="search-form__select"
          name={name}
          ref={selectRef}
          disabled={disabled}
          selectOptions={selectOptions}
          onChange={onChange}
        />
      </div>
    );
  },
);
SearchSelect.displayName = 'SearchSelect';
export default SearchSelect;
