import type Pageable from 'models/Pageable';
import type {
  DashboardPotentialPartnerFinancingVO,
  DashboardPotentialPartnerFinancingVOModel,
} from 'models/vo/DashboardPotentialPartnerFinancingVO';
import { formattingToDashboardPotentialPartnerFinancingVOModel } from 'models/vo/DashboardPotentialPartnerFinancingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

export async function requestFiDashboardPotentialPartnerFinancing(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<DashboardPotentialPartnerFinancingVOModel[]>> {
  const response = await http.get<Pageable<DashboardPotentialPartnerFinancingVO[]>>({
    url: API_FI.DASHBOARD_POTENTIAL_PARTNER_FINANCING.DASHBOARD_POTENTIAL_PARTNER_FINANCING,
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashboardPotentialPartnerFinancingVOModel(item)),
  };
}
