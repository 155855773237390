import './CommonForm.scss';

interface PropsType {
  editable?: boolean;
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
  hideBorderBottom?: boolean;
}

export const FormBorder = ({ editable = false, children, hideBorderBottom = false }: PropsType) => {
  return (
    <div
      className={`information-form-border ${editable ? 'editable' : ''} ${hideBorderBottom ? 'border-bottom-0' : ''}`}
    >
      {children}
    </div>
  );
};
