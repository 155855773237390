export interface TempWaitingArVO {
  tempWaitingArId: number;
  partnerName: string;
  partnerTaxCode: string;
  arNumber: string;
  arAmount: number;
  arIssuedDate: string;
  settlementDate: string;
}

export interface TempWaitingArVOModel extends TempWaitingArVO {}

export function formattingToTempWaitingArVOModel(data: TempWaitingArVO): TempWaitingArVOModel {
  return {
    ...data,
  };
}
