import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import { isNil } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import { COLLATERAL_TYPE } from 'enums';

import useExtraInformationViewModel from '../../models/extraInformation/useExtraInformationViewModel';
import useAnchorAgreementInfoViewModel from '../../models/financingOption/useAnchorAgreementInfoViewModel';
import useFinancingOptionViewModel from '../../models/financingOption/useFinancingOptionViewModel';

type LtvStateType = {
  isLtvHundred: null | boolean;
  ltvValue: null | string;
};
const useFinancingLimitController = () => {
  const {
    methods: { setValue, watch, clearErrors, register, errors },
    isEditable,
  } = useFormContext();
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();
  const { supportedCollateralType } = useExtraInformationViewModel();

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const { loanLimitCheck: watchedLoanLimitCheckType } = watch();

  const {
    anchorFinancingOption: { maxLtvRatio },
  } = useFinancingOptionViewModel();

  const [ltvState, setLtvState] = useState<LtvStateType>({
    isLtvHundred: null,
    ltvValue: null,
  });

  const handleFinancingLimitCheckPointChange = () => {
    setValue('loanLimitAmount', null);
    clearErrors('loanLimitAmount');
  };

  const handleIsLtvHundredChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const updatedLtvIsLtvHundred = value === 'true';

    setValue('maxLtvRatio', updatedLtvIsLtvHundred ? 100 : null);

    setLtvState({
      isLtvHundred: updatedLtvIsLtvHundred,
      ltvValue: null,
    });
  };

  const handleEnterLtvChange = (ltvValue: string) => {
    setValue('maxLtvRatio', ltvValue);
    setLtvState(prevState => ({
      ...prevState,
      ltvValue: ltvValue,
    }));
  };

  const updateMaxLtvRatioState = () => {
    let ltvValue;
    let isLtvHundred;

    if (maxLtvRatio === 100) {
      isLtvHundred = true;
      ltvValue = null;
    } else if (isNil(maxLtvRatio)) {
      isLtvHundred = null;
      ltvValue = null;
    } else {
      isLtvHundred = false;
      ltvValue = String(maxLtvRatio);
    }
    setLtvState({
      isLtvHundred: isLtvHundred,
      ltvValue: ltvValue,
    });
  };

  useEffect(() => {
    if (supportedCollateralType === COLLATERAL_TYPE.INVOICE) {
      updateMaxLtvRatioState();
    }
  }, [setValue, isEditable, maxLtvRatio, supportedCollateralType]);

  return {
    register,
    errors,
    loanLimitCheck: watchedLoanLimitCheckType,
    handleFinancingLimitCheckPointChange,
    isInvoice,
    isEditable,
    ltvState,
    handleIsLtvHundredChange,
    handleEnterLtvChange,
    anchorAgreementInfo,
  };
};

export default useFinancingLimitController;
