import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';

export interface MultipleLoanRequestRelatedSuccessArVO {
  successArId: number;
  arNumber: string;
  arIssuedDate: string;
  arAmount: BigNumber;
  settlementDate: string;
  requestedAmount: BigNumber;
  netDisbursementAmount: BigNumber;
  remainingCollateralAmount: BigNumber;
  arCommissionAmount: BigNumber;
  loanId: number;
  invoiceAttachmentId: number;
  invoiceAttachmentName: string;
  invoiceAttachmentPath: string;
}

export interface MultipleLoanRequestRelatedSuccessArVOModel extends MultipleLoanRequestRelatedSuccessArVO {
  finalDisbursementAmount: BigNumber;
}

export function formattingToMultipleLoanRequestRelatedSuccessArVOModel(
  data: MultipleLoanRequestRelatedSuccessArVO,
): MultipleLoanRequestRelatedSuccessArVOModel {
  const calculatorBigNumber = new CalculatorBigNumber();

  return {
    ...data,
    finalDisbursementAmount: calculatorBigNumber
      .add(data.remainingCollateralAmount)
      .minus(data.arCommissionAmount)
      .get(),
  };
}
