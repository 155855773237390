import { useFormContext } from 'components/stateless/Form';
import type { SelectOptionType } from 'components/stateless/Select/Select';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { COLLATERAL_TYPE, DEALER_AGREEMENT_TYPE } from 'enums';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import { PAGE_TYPE } from '../../../models/extraInformation/Context';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useGeneralInformationController = () => {
  const {
    methods: { watch, register },
  } = useFormContext();
  const { dealerAgreementType } = watch();

  const { supportedCollateralType, pageType, useAgreementInterface } = useExtraInformationViewModel();
  const { isFirstRegisteredWaitingAgreement, agreement } = useAgreementViewModel();

  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;
  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;

  const isDealerAgreementTypeWithAnchor = dealerAgreementType === DEALER_AGREEMENT_TYPE.WITH_ANCHOR;

  const isCurrencyTypeReadOnly = (() => {
    // 전문 - fetchReadOnly
    if (useAgreementInterface) return false;

    switch (pageType) {
      case PAGE_TYPE.REGISTER:
        return isDealerAgreementTypeWithAnchor;
      case PAGE_TYPE.REGISTERED_DETAIL:
        return true;
      case PAGE_TYPE.WAITING_DETAIL:
        return !isFirstRegisteredWaitingAgreement || isDealerAgreementTypeWithAnchor;
      default:
        return false;
    }
  })();

  const isDealerAgreementStatusRender = (() => {
    switch (pageType) {
      case PAGE_TYPE.REGISTER:
        return isAr;
      case PAGE_TYPE.REGISTERED_DETAIL:
        return true;
      case PAGE_TYPE.WAITING_DETAIL:
        return (isInvoice && !isFirstRegisteredWaitingAgreement) || isAr;
      default:
        return false;
    }
  })();

  const selectCurrencyTypeOptions: SelectOptionType[] =
    pageType === PAGE_TYPE.REGISTER
      ? getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')
      : getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', [agreement.currencyType]);

  return {
    register,
    supportedCollateralType,
    currencyType: agreement.currencyType,
    isCurrencyTypeReadOnly,
    isInvoice,
    isDealerAgreementStatusRender,
    selectCurrencyTypeOptions,
  };
};

export default useGeneralInformationController;
