import React from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from 'components/stateless/Accordion/Accordion';

import './PotentialPartnerHomeSteps.scss';

interface StepContentType {
  title: string;
  desc: string;
}

function PotentialPartnerHomeSteps() {
  const { t } = useTranslation();

  const STEP_CONTENT: StepContentType[] = [
    {
      title: t('text:Identify_Financing_Needs'),
      desc: t('text:Check_the_AR_and_financing_program_details_for_each_buyer'),
    },
    {
      title: t('text:Submit_Application'),
      desc: t('text:Prepare_and_submit_required_documents_in_advance'),
    },
    {
      title: t('text:Financier_Reviews_Application'),
      desc: t('text:The_financier_reviews_your_application'),
    },
    {
      title: t('text:Sign_Financing_Agreement'),
      desc: t('text:Sign_a_financing_agreement_with_the_financier'),
    },
    {
      title: t('text:Get_Financing_in_need'),
      desc: t('text:Request_early_payment_for_AR_through_Fin2B'),
    },
  ];

  const AccordionSteps = () => {
    const AccordionStep = ({ index, content }: { index: number; content: StepContentType }) => {
      return (
        <React.Fragment key={index}>
          <Accordion id={`steps-accordion-${index}`} className="accordion" defaultExpanded={false}>
            <Accordion.Header id={`steps-heading-${index}`}>
              <Accordion.Trigger
                className={`accordion-button ${(index === 3 || index === 4) && 'accordion-button--lighten'}`}
              >
                <div className="pp-steps__card__num--small">{index + 1}</div>
                <div className="pp-steps__card__title--small">{content.title}</div>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="accordion-body">
              <div className="pp-steps__card__desc">{content.desc}</div>
            </Accordion.Content>
          </Accordion>
        </React.Fragment>
      );
    };

    return (
      <div className="pp-steps-accordion__wrapper">
        <div>
          <div className="pp-steps__wizard__desc--dark">{t('text:Through_this_webpage')}</div>
          <div className="pp-steps__accordion__items__wrapper">
            <div className="pp-steps__wizard-col--first" />
            <div className="w-100">
              <AccordionStep index={0} content={STEP_CONTENT[0]} />
              <AccordionStep index={1} content={STEP_CONTENT[1]} />
              <AccordionStep index={2} content={STEP_CONTENT[2]} />
            </div>
          </div>
        </div>
        <div>
          <div className="pp-steps__wizard__desc--light">{t('text:Visit_the_financier_in_person')}</div>
          <div className="pp-steps__accordion__items__wrapper">
            <div className="pp-steps__wizard-col--second" />
            <div className="w-100">
              <AccordionStep index={3} content={STEP_CONTENT[3]} />
            </div>
          </div>
        </div>
        <div className="pp-steps__wizard__desc--light">{t('text:Through_Fin2B_SCF_Platform')}</div>
        <div className="pp-steps__accordion__items__wrapper">
          <div className="pp-steps__wizard-col--third" />
          <div className="w-100">
            <AccordionStep index={4} content={STEP_CONTENT[4]} />
          </div>
        </div>
      </div>
    );
  };

  const BoxSteps = () => (
    <div className="pp-step__boxes__wrapper">
      <div className="d-flex">
        {STEP_CONTENT.map((content, index) => (
          <div key={index} className="d-flex">
            <div className={`${index < 3 ? 'pp-steps__card' : 'pp-steps__card--lighten'}`}>
              <div className="pp-steps__card__num">{index + 1}</div>
              <div className="pp-steps__card__title">{content.title}</div>
              <div className="pp-steps__card__desc">{content.desc}</div>
            </div>

            {index !== STEP_CONTENT.length - 1 && (
              <div className="pp-steps__card__arrow-wrapper">
                <span className="pp-steps__card__arrow" />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="pp-steps__wizard__items__wrapper">
        <div className="pp-steps__wizard-row__item">
          <div className="pp-steps__wizard-row--first" />
          <div className="pp-steps__wizard__desc--dark">{t('text:Through_this_webpage')}</div>
        </div>
        <div className="pp-steps__wizard-row__item">
          <div className="pp-steps__wizard-row--second" />
          <div className="pp-steps__wizard__desc--light">{t('text:Visit_the_financier_in_person')}</div>
        </div>
        <div className="pp-steps__wizard-row__item">
          <div className="pp-steps__wizard-row--third" />
          <div className="pp-steps__wizard__desc--light">{t('text:Through_Fin2B_SCF_Platform')}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="pp-steps">
      <div className="pp-title">{t('text:Improve_your_cash_flow_with_5_simple_steps')}</div>
      <BoxSteps />
      {/*  XXL 일 때를 제외한.. */}
      <AccordionSteps />
    </div>
  );
}

export default PotentialPartnerHomeSteps;
