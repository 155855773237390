const API_DE = {
  ANCHOR_AGREEMENTS: {
    ANCHOR_AGREEMENT_LIST_FOR_ARS: 'v1/de/anchor-agreements/list-for-ars',
    ANCHOR_AGREEMENT_DETAIL: (anchorAgreementId: number) => `v1/de/anchor-agreements/${anchorAgreementId}`,
  },
  AR_APPROVAL_PHASES: {
    AR_PHASE_LIST: 'v1/de/ar-approval-phases',
  },
  AR_SUMMARIES: {
    AR_SUMMARIES: 'v1/de/ar-summaries',
    AR_SUMMARY_DETAIL: (arSummaryId: number) => `v1/de/ar-summaries/${arSummaryId}`,
    AR_SUMMARY_LAST_PHASE: (arSummaryId: number) => `v1/de/ar-summaries/${arSummaryId}/find:last-phase-with-ars`,
    AR_SUMMARY_APPROVE: 'v1/de/ar-summaries/approve:ar-phase',
    AR_SUMMARY_REJECT: 'v1/de/ar-summaries/reject:ar-registration',
  },
  CLIENT_AUTH_SETTING: {
    CLIENT_AUTH_BY_DEALER_AGREEMENT_ID: 'v1/de/client-auth-setting',
    CLIENT_AUTH_BY_ANCHOR_AGREEMENT_ID: 'v1/de/client-auth-setting/by-anchor-agreement-id',
  },
  DASHBOARD: {
    INTERNAL_APPROVAL: 'v1/de/dashboard/internal-approval',
    TASK_SCHEDULE: 'v1/de/dashboard/task-schedule',
    PERFORMANCE: 'v1/de/dashboard/performance',
  },
  DEALER_AGREEMENTS: {
    AGREEMENT_LIST: 'v1/de/dealer-agreements',
    AGREEMENT_DETAIL: (dealerAgreementId: number) => `v1/de/dealer-agreements/${dealerAgreementId}`,
    TEMP_AGREEMENT_LIST: 'v1/de/dealer-agreements/list-for-temp-invoice',
    ANCHOR_AGREEMENT_RELATED_DEALER_AGREEMENT: (dealerAgreementId: number) =>
      `v1/de/dealer-agreements/${dealerAgreementId}/related-anchor-agreement`,
    AR_LIST_FOR_MULTIPLE_REQUEST_LOANS: 'v1/de/dealer-agreements/list-for-multiple-request-loans',
  },
  EARLY_REPAYMENT_REQUESTS: {
    EARLY_REPAYMENT_LIST: 'v1/de/early-repayment-requests',
    EARLY_REPAYMENT_DETAIL: (earlyRepaymentId: number) => `v1/de/early-repayment-requests/${earlyRepaymentId}`,
  },
  EXTENSION_REQUESTS: {
    EXTENSION_LIST: 'v1/de/extension-requests',
    EXTENSION_DETAIL: (extensionRequestId: number) => `v1/de/extension-requests/${extensionRequestId}`,
  },
  FINANCIER_CALENDAR: {
    FINANCIER_CALENDAR: (financierId: number) => `v1/de/financier-calendar/${financierId}`,
  },
  FINANCIER_CLIENTS: {
    FINANCIER_CLIENT_LIST: 'v1/de/financier-clients',
  },
  FINANCIER_DOCUMENT_FORMS: {
    DOWNLOAD_ATTACHMENT: 'v1/de/financier-document-forms/download:attachment',
    DOCUMENT_LIST: (financierId: number) => `v1/de/financier-document-forms/${financierId}`,
  },
  INVENTORY_SUMMARIES: {
    INVENTORY_LIST: 'v1/de/inventory-summaries',
    INVENTORY_DETAIL: (inventorySummaryId: number) => `v1/de/inventory-summaries/${inventorySummaryId}`,
    DOWNLOAD_ATTACHMENT: (inventorySummaryId: number) =>
      `v1/de/inventory-summaries/${inventorySummaryId}/download:attachment`,
  },
  INVOICE_APPROVAL_PHASES: {
    INVOICE_PHASE_LIST: 'v1/de/invoice-approval-phases',
  },
  INVOICE_ATTACHMENTS: {
    INVOICE_ATTACHMENT_LIST: 'v1/de/invoice-attachments',
    UPLOAD_SCANNED_INVOICE: 'v1/de/invoice-attachments/upload:scanned-invoice',
    DOWNLOAD_INVOICE_ATTACHMENT: (invoiceAttachmentId: number) =>
      `v1/de/invoice-attachments/${invoiceAttachmentId}/download:scanned-invoice`,
  },
  INVOICE_SUMMARIES: {
    INVOICE_SUMMARY: 'v1/de/invoice-summaries',
    INVOICE_SUMMARY_DETAIL: (invoiceSummaryId: number) => `v1/de/invoice-summaries/${invoiceSummaryId}`,
    INVOICE_SUMMARY_LAST_PHASE: (invoiceSummaryId: number) =>
      `v1/de/invoice-summaries/${invoiceSummaryId}/find:last-phase-with-invoices`,
    APPROVE_INVOICE_PHASE: 'v1/de/invoice-summaries/approve:invoice-phase',
    REJECT_INVOICE_REGISTRATION: 'v1/de/invoice-summaries/reject:invoice-registration',
  },
  LOANS: {
    LOAN_LIST: 'v1/de/loans',
    LOAN_DETAIL: (loanId: number) => `v1/de/loans/${loanId}`,
    LOAN_TRANSACTION_LIST: (loanId: number) => `v1/de/loans/${loanId}/loan-transactions`,
    DOWNLOAD_LOAN_AGREEMENT: (loanId: number) => `v1/de/loans/${loanId}/download:loan-agreement`,
    DOWNLOAD_LOAN_ETC: (loanId: number) => `v1/de/loans/${loanId}/download:loan-etc`,
    DOWNLOAD_LOAN_REQUEST_FORM: (loanId: number) => `v1/de/loans/${loanId}/download:loan-request-form`,
    CANCEL_LOAN: (loanId: number) => `v1/de/loans/${loanId}/cancel:loan`,
    APPROVE_LOAN: (loanId: number) => `v1/de/loans/${loanId}/approve:loan`,
    REQUEST_EARLY_REPAYMENT: (loanId: number) => `v1/de/loans/${loanId}/request:early-repayment`,
    REQUEST_LOAN_EXTENSION: (loanId: number) => `v1/de/loans/${loanId}/request:loan-extension`,
    CALCULATE_EXPECTED_AMOUNT: (loanId: number) => `v1/de/loans/${loanId}/calculate:expected-amount`,
    DOWNLOAD_SCANNED_AR: (loanId: number) => `v1/de/loans/${loanId}/download:scanned-ar`,
    RENEW_LOAN_BY_FINANCIER_INTERFACE: (loanId: number) => `v1/de/loans/${loanId}/renew:loan-by-financier-interface`,
    DOWNLOAD_DEBT_SELLING_NOTIFICATION: (loanId: number) =>
      `v1/de/loans/${loanId}/download:loan-factoring-notification`,
    DOWNLOAD_FACTORING_DOCS_FOR_ESIGN: (loanId: number) => `v1/de/loans/${loanId}/download:factoring-docs-for-esign`,
    LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS: (loanId: number) => `v1/de/loans/${loanId}/additional-document-attachments`,
  },
  LOAN_ADDITIONAL_DOCUMENTS: {
    LOAN_ADDITIONAL_DOCUMENTS_LIST: 'v1/de/loan-additional-documents/list-for-request-loans',
    DOWNLOAD_ATTACHMENT: (loanAdditionalDocumentId: number) =>
      `v1/de/loan-additional-documents/${loanAdditionalDocumentId}/download:attachment`,
  },
  LOAN_ADDITIONAL_DOCUMENT_ATTACHMENT: {
    DOWNLOAD_ATTACHMENT: (documentAttachmentId: number) =>
      `v1/de/loan-additional-document-attachments/${documentAttachmentId}/download`,
  },
  MULTIPLE_REQUEST_LOANS: {
    MULTIPLE_REQUEST_LOAN_LIST: 'v1/de/multiple-request-loans',
    REGISTER_MULTIPLE_LOANS: 'v1/de/multiple-request-loans',
    CALCULATE_MULTIPLE_REQUEST_LOANS: 'v1/de/multiple-request-loans/calculate',
    MULTIPLE_FACTORING_ATTACHMENTS: 'v1/de/multiple-request-loans/factoring-attachments',
    MULTIPLE_REQUEST_LOAN_DETAIL: (multipleRequestLoansId: number) =>
      `v1/de/multiple-request-loans/${multipleRequestLoansId}`,
    MULTIPLE_REQUEST_LOAN_AR_LIST: (multipleRequestLoansId: number) =>
      `v1/de/multiple-request-loans/${multipleRequestLoansId}/ars`,
    MULTIPLE_REQUEST_LOAN_RELATED_FINANCING_LIST: (multipleRequestLoansId: number) =>
      `v1/de/multiple-request-loans/${multipleRequestLoansId}/loans`,
    DOWNLOAD_ATTACHMENT: (multipleRequestLoansId: number) =>
      `v1/de/multiple-request-loans/${multipleRequestLoansId}/download:attachment`,
    DOWNLOAD_ATTACHMENT_FOR_ESIGN: (multipleRequestLoansId: number) =>
      `v1/de/multiple-request-loans/${multipleRequestLoansId}/download:attachment-for-esign`,
    CANCEL: (multipleRequestLoansId: number) => `v1/de/multiple-request-loans/${multipleRequestLoansId}/cancel`,
    APPROVE: (multipleRequestLoansId: number) => `v1/de/multiple-request-loans/${multipleRequestLoansId}/approve`,
    LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS: (multipleRequestLoansId: number) =>
      `v1/de/multiple-request-loans/${multipleRequestLoansId}/additional-document-attachments`,
  },
  OTP: {
    OTP_BY_DEALER_AGREEMENT_ID: 'v1/de/otp/send:code',
    OTP_BY_ANCHOR_AGREEMENT_ID: 'v1/de/otp/send:code-by-anchor-agreement-id',
  },
  PURPOSE_OF_LOANS: {
    DOWNLOAD_PURPOSE_ATTACHMENT: (purposeOfLoanId: number) =>
      `v1/de/purpose-of-loans/${purposeOfLoanId}/download:purpose-attachment`,
    DOWNLOAD_ACCOUNT_ATTACHMENT: (purposeOfLoanId: number) =>
      `v1/de/purpose-of-loans/${purposeOfLoanId}/download:account-attachment`,
  },
  SUCCESS_ARS: {
    SUCCESS_AR: 'v1/de/success-ars',
    SUCCESS_AR_DETAIL: (successArId: number) => `v1/de/success-ars/${successArId}`,
    SUCCESS_AR_LIST_FOR_REQUEST_LOAN: 'v1/de/success-ars/list-for-request-loan',
    SUCCESS_AR_LIST_FOR_MULTIPLE_REQUEST_LOANS: 'v1/de/success-ars/list-for-multiple-request-loans',
    REGISTER_LOAN: (successArId: number) => `v1/de/success-ars/${successArId}/request:loan`,
    CALCULATE_LOAN_REQUEST: 'v1/de/success-ars/calculate:loan-request',
    RENEW_AR_BY_FINANCIER_INTERFACE: (successArId: number) =>
      `v1/de/success-ars/${successArId}/renew:ar-by-financier-interface`,
    TEMP_LOAN: (successArId: number) => `v1/de/success-ars/${successArId}/temp-loan`,
    PARTIAL_COLLATERALS: (successArId: number) => `v1/de/success-ars/${successArId}/partial-collaterals`,
    FACTORING_ATTACHMENTS: `v1/de/success-ars/factoring-attachments`,
  },
  SUCCESS_INVOICES: {
    SUCCESS_INVOICE: 'v1/de/success-invoices',
    SUCCESS_INVOICE_LIST_FOR_REQUEST_LOAN: 'v1/de/success-invoices/list-for-request-loan',
    SUCCESS_INVOICE_DETAIL: (successInvoiceId: number) => `v1/de/success-invoices/${successInvoiceId}`,
    DOWNLOAD_SUCCESS_INVOICE: (successInvoiceId: number) =>
      `v1/de/success-invoices/${successInvoiceId}/download:scanned-invoice`,
    REGISTER_LOAN: (successInvoiceId: number) => `v1/de/success-invoices/${successInvoiceId}/request:loan`,
    CALCULATE_LOAN_REQUEST: 'v1/de/success-invoices/calculate:loan-request',
    TEMP_LOAN: (successInvoiceId: number) => `v1/de/success-invoices/${successInvoiceId}/temp-loan`,
    SETTLEMENT_METHOD: (successInvoiceId: number) => `v1/de/success-invoices/${successInvoiceId}/settlement-method`,
  },
  TEMP_LOANS: {
    DOWNLOAD_LOAN_AGREEMENT: (tempLoanId: number) => `v1/de/temp-loans/${tempLoanId}/download:loan-agreement`,
    DOWNLOAD_LOAN_REQUEST_FORM: (tempLoanId: number) => `v1/de/temp-loans/${tempLoanId}/download:loan-request-form`,
    DOWNLOAD_LOAN_ETC: (tempLoanId: number) => `v1/de/temp-loans/${tempLoanId}/download:loan-etc`,
  },
  TEMP_PURPOSE_OF_LOANS: {
    DOWNLOAD_ACCOUNT_ATTACHMENT: (tempPurposeOfLoanId: number) =>
      `v1/de/temp-purpose-of-loans/${tempPurposeOfLoanId}/download:account-attachment`,
    DOWNLOAD_PURPOSE_ATTACHMENT: (tempPurposeOfLoanId: number) =>
      `v1/de/temp-purpose-of-loans/${tempPurposeOfLoanId}/download:purpose-attachment`,
  },
  USERS: {
    USER: 'v1/de/users',
    USER_DETAIL: (userId: number) => `v1/de/users/${userId}`,
    SUSPEND_OPERATOR: (userId: number) => `v1/de/users/${userId}/suspend:operator`,
    INVITE_OPERATOR: (userId: number) => `v1/de/users/${userId}/invite:operator`,
    OPERATOR_INVITE_URL: (userId: number) => `v1/de/users/${userId}/invite-url`,
  },
  USER_EMAIL_SETTING: {
    USER_EMAIL_SETTING: 'v1/de/user-email-setting',
  },
  WAITING_ARS: {
    WAITING_AR_LIST: 'v1/de/waiting-ars',
  },
  WAITING_DEALER_AGREEMENTS: {
    WAITING_DEALER_AGREEMENT_LIST: 'v1/de/waiting-dealer-agreements',
  },
  WAITING_INVOICES: {
    WAITING_INVOICE_LIST: 'v1/de/waiting-invoices',
  },
};

export default API_DE;
