import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { MultipleAnchorLoanRequestDetailVOModel } from 'models/vo/MultipleAnchorLoanRequestDetailVO';

interface FinancierBulkAnchorFinancingDetailDesignatedBankAccountInformationProps {
  bulkFinancingDetailInfo: MultipleAnchorLoanRequestDetailVOModel;
  isOperatorRequest: boolean;
  bankAccountInfoCheckBoxState: {
    bankAccountInfoCheck: boolean;
    setBankAccountInfoCheck: Dispatch<SetStateAction<boolean>>;
  };
  showDisbursementAccountInfo: boolean;
}

function FinancierBulkAnchorFinancingDetailDesignatedBankAccountInformation({
  bulkFinancingDetailInfo,
  isOperatorRequest,
  bankAccountInfoCheckBoxState,
  showDisbursementAccountInfo,
}: FinancierBulkAnchorFinancingDetailDesignatedBankAccountInformationProps) {
  const { t } = useTranslation(['format']);
  const { bankAccountInfoCheck, setBankAccountInfoCheck } = bankAccountInfoCheckBoxState;

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Designated_Bank_Account_Information')} />
      {!showDisbursementAccountInfo && (
        <GuideMessage
          isImportContentArea
          useContentAreaClassName={false}
          message={[
            t(
              'text:Check_the_information_of_the_disbursement_account_and_the_repayment_accounts_designated_on_the_Financing_Option',
            ),
            t('text:Click_on_the_checkbox_to_confirm_the_information_of_the_designated_bank_accounts_is_correct'),
          ]}
        />
      )}
      {showDisbursementAccountInfo && (
        <FormBorder hideBorderBottom={isOperatorRequest}>
          <FormSubtitle title={t('text:DISBURSEMENT_ACCOUNT')} />
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Bank_Name')} value={bulkFinancingDetailInfo.disbursementAccountFinancierName} />
              <FormValue
                label={t('text:Bank_Branch_Name')}
                value={bulkFinancingDetailInfo.disbursementAccountBranchName}
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Account_Number')} value={bulkFinancingDetailInfo.disbursementAccount} />
              <FormValue
                label={t('text:Account_Holder_Name')}
                value={bulkFinancingDetailInfo.disbursementAccountOwner}
              />
            </div>
          </FormContents>
        </FormBorder>
      )}

      {isOperatorRequest && showDisbursementAccountInfo && (
        <ConfirmCheckBox
          checked={bankAccountInfoCheck}
          id="account-info-check"
          labelText={t('text:I_have_checked_the_information_of_the_designated_bank_accounts')}
          onChangeCheckBox={() => {
            setBankAccountInfoCheck(prevState => !prevState);
          }}
        />
      )}
    </div>
  );
}
export default FinancierBulkAnchorFinancingDetailDesignatedBankAccountInformation;
