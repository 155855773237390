import { requestFinancierDownloadLoanEtc } from 'utils/http/api/financier/loans';
import useModal from 'utils/modal/useModal';

export function useFinancierFinancingDetailAdditionalDocumentsLogics(loanId: number) {
  const modal = useModal();
  // 대출 기타 파일 다운로드 호출
  const onClickDownloadLoanEtc = async (e: any, loanEtcAttachmentId: number) => {
    e.preventDefault();
    try {
      await requestFinancierDownloadLoanEtc(loanId, loanEtcAttachmentId);
    } catch (e: any) {
      modal.show(e);
    }
  };

  return { onClickDownloadLoanEtc };
}
