import type Pageable from 'models/Pageable';
import type { CalculateNetDisbursementVO, CalculateNetDisbursementVOModel } from 'models/vo/CalculateNetDisbursementVO';
import { formattingToCalculateNetDisbursementVOModel } from 'models/vo/CalculateNetDisbursementVO';
import type { ExpectedLoanRepaymentVO, ExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import { formattingToExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import { formattingToFactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import type { FactoringAttachmentVO, FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import type {
  LoanAdditionalDocumentAttachmentVO,
  LoanAdditionalDocumentAttachmentVOModel,
} from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import { formattingToLoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type { LoanDetailVO, LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import { formattingToLoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { LoanTransactionVO, LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import { formattingToLoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import type { LoanVO, LoanVOModel } from 'models/vo/LoanVO';
import { formattingToLoanVOModel } from 'models/vo/LoanVO';
import http, { ContentType } from 'utils/http';
import type { CommonResponse } from 'utils/http/CommonResponse';
import API_FI from 'utils/http/api/financier';
import type {
  FinancierExecuteLoanDisbursementRequest,
  FinancierLoanExpirationRequest,
  FinancierLoanListRequest,
  FinancierLoanRepaymentRequest,
  FinancierLoanTransactionRequest,
} from 'utils/http/api/financier/loans/request';
import type { FinancierCalculateNetDisbursementRequest } from 'utils/http/api/financier/loans/request';
import type { FinancierApprovalLoanDisbursementRequest } from 'utils/http/api/financier/loans/request';

// 대출 목록 조회
export async function requestFinancierLoanList(
  pageNumber: number,
  rowCount: number,
  searchData?: FinancierLoanListRequest,
): Promise<Pageable<LoanVOModel[]>> {
  const response = await http.get<Pageable<LoanVO[]>>({
    url: API_FI.LOANS.LOAN_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchData,
    },
  });

  const loanList = response.content.map(data => formattingToLoanVOModel(data));

  return {
    ...response,
    content: loanList,
  };
}

// 대출 상세 조회
export async function requestFinancierLoanDetail(loanId: number): Promise<LoanDetailVOModel> {
  const response = await http.get<LoanDetailVO>({
    url: API_FI.LOANS.LOAN_DETAIL(loanId),
  });

  return formattingToLoanDetailVOModel(response);
}

//채권 양도 계약서 다운로드
export async function requestFinancierDownloadDebtSellingNotification(loanId: number) {
  await http.download(API_FI.LOANS.DOWNLOAD_DEBT_SELLING_NOTIFICATION(loanId));
}

//건별 대출 약정서, 채권 양도 계약서 다운로드
export async function requestFinancierDownloadLoanAgreement(loanId: number) {
  await http.download(API_FI.LOANS.DOWNLOAD_LOAN_AGREEMENT(loanId));
}

// 대출 기타 파일 다운로드
export async function requestFinancierDownloadLoanEtc(loanId: number, loanEtcAttachmentId: number) {
  await http.download(API_FI.LOANS.DOWNLOAD_LOAN_ETC(loanId), { loanEtcAttachmentId });
}

// 대출 신청서 다운로드
export async function requestFinancierDownloadLoanRequestForm(loanId: number) {
  await http.download(API_FI.LOANS.DOWNLOAD_LOAN_REQUEST_FORM(loanId));
}

// 대출 승인 요청
export async function requestFinancierLoanDisbursement(loanId: number) {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.REQUEST_LOAN_DISBURSEMENT(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 대출 승인 요청 승인
export async function requestApproveFinancierLoanDisbursement(
  loanId: number,
  data: FinancierApprovalLoanDisbursementRequest = {},
) {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.APPROVE_LOAN_DISBURSEMENT(loanId),
    contentType: ContentType.MULTIPART,
    data,
  });

  return formattingToLoanVOModel(response);
}

// 대출 거절 상태 변경
export async function requestFinancierUpdateRejectLoanDisbursement(loanId: number, reason: string) {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.REJECT_LOAN_DISBURSEMENT(loanId),
    data: { reason },
  });

  return formattingToLoanVOModel(response);
}

// 대출 지급 확인
export async function requestFinancierExecuteLoanDisbursement(
  loanId: number,
  data: FinancierExecuteLoanDisbursementRequest,
): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.EXECUTE_LOAN_DISBURSEMENT(loanId),
    data,
  });

  return formattingToLoanVOModel(response);
}

// 대출 지급 취소 요청
export async function requestFinancierRequestCancelDisbursement(loanId: number, reason: string): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.REQUEST_CANCEL_LOAN_DISBURSEMENT(loanId),
    data: { reason },
  });

  return formattingToLoanVOModel(response);
}

// 대출 지급 취소 승인
export async function requestFinancierApproveCancelDisbursement(loanId: number): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.APPROVE_CANCEL_LOAN_DISBURSEMENT(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 거래 내역 조회
export async function requestFinancierLoanTransaction(
  loanId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<LoanTransactionVOModel[]>> {
  const response = await http.get<Pageable<LoanTransactionVO[]>>({
    url: API_FI.LOANS.LOAN_TRANSACTIONS(loanId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const LoanTransactionList: LoanTransactionVOModel[] = response.content.map((data: LoanTransactionVO) =>
    formattingToLoanTransactionVOModel(data),
  );
  const LoanTransactionPage: Pageable<LoanTransactionVOModel[]> = {
    ...response,
    content: LoanTransactionList,
  };

  return LoanTransactionPage;
}

// 대출 전문 갱신 요청
export async function requestFinancierRenewLoanByFinancierInterface(loanId: number): Promise<LoanVOModel> {
  const response = await http.post<LoanVO>({
    url: API_FI.LOANS.RENEW_LOAN_BY_FINANCIER_INTERFACE(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 대출 갱신 요청 (수기)
export async function requestFinancierLoanTransactionRenew(
  loanId: number,
  data: FinancierLoanTransactionRequest,
): Promise<LoanTransactionVOModel> {
  const response = await http.post<LoanTransactionVO>({
    url: API_FI.LOANS.LOAN_TRANSACTIONS(loanId),
    data,
  });

  return formattingToLoanTransactionVOModel(response);
}

// 마지막 거래 내역 삭제
export async function requestFinancierLoanTransactionDelete(loanId: number) {
  const response = await http.delete({
    url: API_FI.LOANS.LOAN_TRANSACTIONS(loanId),
  });

  return response;
}

// 결재 요청 반려
export async function requestFinancierReturnLoan(loanId: number, reason: string): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.RETURN_LOAN(loanId),
    data: { reason },
  });

  return formattingToLoanVOModel(response);
}

// 결재 요청 취소
export async function requestFinancierCancelLoan(loanId: number): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.CANCEL_LOAN(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 상환 완료 결재 요청
export async function requestFinancierLoanRepayment(
  loanId: number,
  data: FinancierLoanRepaymentRequest,
): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.REQUEST_LOAN_REPAYMENT(loanId),
    data,
  });

  return formattingToLoanVOModel(response);
}

// 상환 완료 승인
export async function requestFinancierApproveLoanRepayment(loanId: number): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.APPROVE_LOAN_REPAYMENT(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 대출 연체 처리 승인 요청
export async function requestFinancierLoanOverdue(loanId: number): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.REQUEST_LOAN_OVERDUE(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 연체 처리 요청 승인
export async function requestFinancierApproveLoanOverdue(loanId: number): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.APPROVE_LOAN_OVERDUE(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 기타 만료 처리 승인 요청
export async function requestFinancierLoanExpiration(
  loanId: number,
  data: FinancierLoanExpirationRequest,
): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.REQUEST_LOAN_EXPIRATION(loanId),
    data,
  });

  return formattingToLoanVOModel(response);
}

// 기타 만료 처리 승인
export async function requestFinancierApproveLoanExpiration(loanId: number): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.APPROVE_LOAN_EXPIRATION(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 연체 해제 요청
export async function requestFinancierLoanOverdueRelease(loanId: number): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.REQUEST_LOAN_OVERDUE_RELEASE(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 연체 해제 요청 승인
export async function requestFinancierApproveLoanOverdueRelease(loanId: number): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.APPROVE_LOAN_OVERDUE_RELEASE(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 승인된 대출 취소 요청
export async function requestFinancierRequestApprovedLoanRejection(loanId: number, rejectReason: string) {
  await http.put<CommonResponse>({
    url: API_FI.LOANS.REQUEST_APPROVED_LOAN_REJECTION(loanId),
    data: { rejectReason },
  });
}

//승인된 대출 취소 요청 승인
export async function requestFinancierApproveCancelApprovedLoan(loanId: number) {
  const response = await http.put<LoanVO>({
    url: API_FI.LOANS.APPROVE_APPROVED_LOAN_REJECTION(loanId),
  });

  return formattingToLoanVOModel(response);
}

export async function requestFinancierDownloadScannedAr(loanId: number) {
  await http.download(API_FI.LOANS.DOWNLOAD_SCANNED_AR(loanId));
}

// 조기상환가능 예상금액 조회
export async function requestFinancierCalculateExpectedAmount(
  loanId: number,
  expectedRepaymentDate: string,
): Promise<ExpectedLoanRepaymentVOModel> {
  const response = await http.post<ExpectedLoanRepaymentVO>({
    url: API_FI.LOANS.CALCULATE_EXPECTED_AMOUNT(loanId),
    data: { expectedRepaymentDate },
  });

  return formattingToExpectedLoanRepaymentVOModel(response);
}

// 대출 실지급 금액 조회
export async function requestFinancierCalculateNetDisbursement(
  loanId: number,
  data: FinancierCalculateNetDisbursementRequest,
): Promise<CalculateNetDisbursementVOModel> {
  const response = await http.get<CalculateNetDisbursementVO>({
    url: API_FI.LOANS.CALCULATE_NET_DISBURSEMENT(loanId),
    data,
  });

  return formattingToCalculateNetDisbursementVOModel(response);
}

export async function requestFinancierDownloadFactoringDocsForEsign(
  loanId: number,
): Promise<FactoringAttachmentVOModel> {
  const response = await http.get<FactoringAttachmentVO>({
    url: API_FI.LOANS.DOWNLOAD_FACTORING_DOCS_FOR_ESIGN(loanId),
  });

  return formattingToFactoringAttachmentVOModel(response);
}

export async function requestFinancierLoanAdditionalDocumentAttachments(
  loanId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<LoanAdditionalDocumentAttachmentVOModel[]>> {
  const response = await http.get<Pageable<LoanAdditionalDocumentAttachmentVO[]>>({
    url: API_FI.LOANS.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS(loanId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const additionalDocumentsList: LoanAdditionalDocumentAttachmentVOModel[] = response.content.map(data =>
    formattingToLoanAdditionalDocumentAttachmentVOModel(data),
  );

  return {
    ...response,
    content: additionalDocumentsList,
  };
}
