import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormErrorMessage } from 'components/stateless/CommonForm/FormErrorMessage';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import MSWDevTool from 'components/stateless/MSWDevTool';
import PasswordValidationBox from 'components/stateless/PasswordValidationBox/PasswordValidationBox';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_CM } from 'constants/routes/common';
import { AUTHORITY_TYPE, ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { TermsOfUseVersionVOModel } from 'models/vo/TermsOfUseVersionVO';
import type { UserVOModel } from 'models/vo/UserVO';
import { formErrorHandler } from 'utils/error/manager';
import API_AN from 'utils/http/api/anonymous';
import { requestInvitedBranch } from 'utils/http/api/anonymous/branches';
import { requestTermsOfUseVersions } from 'utils/http/api/anonymous/terms-of-use-versions';
import { requestCheckUserExistence, requestInvitedUser, requestSignUpFinancier } from 'utils/http/api/anonymous/users';
import type { SignUpFinancierUserRequest } from 'utils/http/api/anonymous/users/requests';
import useModal from 'utils/modal/useModal';
import { REG_LOWERCASE_AND_NUMBER } from 'utils/validation/regExp';

function FinancierSignUp() {
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();
  const location = useLocation();

  const [branchInfo, setBranchInfo] = useState<BranchVOModel>();
  const [invitedUserInfo, setInvitedUserInfo] = useState<UserVOModel>();
  const [isCheckID, setCheckID] = useState<boolean | undefined>(undefined);
  const [loginIdErrorMessage, setLoginIdErrorMessage] = useState<string>('');
  const [checkedTOUs, setCheckedTOUs] = useState<number[]>([]);
  const [termsOfUseVersions, setTermsOfUseVersions] = useState<TermsOfUseVersionVOModel[]>([]);

  const { register, setError, clearErrors, errors, getValues, watch } = useForm<SignUpFinancierUserRequest>();

  const {
    setError: setCheckIdError,
    clearErrors: clearCheckIdErrors,
    errors: checkIdErrors,
  } = useForm<{ loginId: string }>();

  const { newPassword, confirmPassword } = watch();
  const { t } = useTranslation();
  const getProperty = useProperty<SignUpFinancierUserRequest>();

  // email code 가져오기 (쿼리스트링)
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const emailCode = query.code as string;

  const isLoginIdError = errors.loginId || checkIdErrors.loginId;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    let fetchBranchInfo: BranchVOModel;
    try {
      const [fetchInvitedUserInfo, fetchedTermsOfUseVersions] = await Promise.all([
        requestInvitedUser(emailCode),
        requestTermsOfUseVersions(emailCode),
      ]);
      if (fetchInvitedUserInfo.authorityType !== AUTHORITY_TYPE.ADMIN) {
        fetchBranchInfo = await requestInvitedBranch(emailCode);
      }
      ReactDOM.unstable_batchedUpdates(() => {
        setInvitedUserInfo(fetchInvitedUserInfo);
        setBranchInfo(fetchBranchInfo);
        setTermsOfUseVersions(fetchedTermsOfUseVersions);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  // 아이디 중복 체크
  const checkId = async () => {
    setCheckID(undefined);
    try {
      const response = await requestCheckUserExistence({
        loginId: getValues().loginId,
        code: emailCode,
      });

      if (!response) {
        modal.show(
          <h6>
            {t('text:This_ID_is_available_for_use')}
            <br />
            {t('text:Please_continue_your_sign_up_process')}
          </h6>,
        );
      } else {
        modal.show(
          <h6>
            {t('text:This_ID_is_already_in_use')}
            <br />
            {t('text:Please_type_in_another_ID_to_use')}
          </h6>,
        );
      }

      setCheckID(!response);
    } catch (error) {
      modal.show(error);
      if (!getValues('loginId')) setCheckID(false);
      formErrorHandler<{ loginId: string }>(error, setCheckIdError, clearCheckIdErrors);
    }
  };

  // 사용자 가입 요청
  const submitRegister = async () => {
    const data = getValues();

    if (!data.loginId || !isCheckID) setCheckID(false);
    if (data.loginId && !isCheckID) return;

    data.code = emailCode;
    data.userTouDTO = {
      // 해당 페이지에서는 회원가입 시 TOU 무조건 한 개만 내려옴
      touVersionId: termsOfUseVersions[0].termsOfUseVersionId,
      financierId: termsOfUseVersions[0].relatedFinancierId,
    };

    try {
      await requestSignUpFinancier(data);

      history.push(ROUTES_CM.SIGN_UP_COMPLETE, {
        userId: data.loginId,
        userName: invitedUserInfo?.userName,
        userAuthority: invitedUserInfo?.authorityType,
      });
    } catch (e) {
      modal.show(e);

      formErrorHandler<SignUpFinancierUserRequest>(e, setError, clearErrors);
    }
  };

  const checkIdValidation = () => {
    if (REG_LOWERCASE_AND_NUMBER.test(getValues('loginId'))) {
      setLoginIdErrorMessage('');
    } else {
      setLoginIdErrorMessage(t('text:ID_can_only_be_used_in_lowercase_letters_and_numbers'));
    }
  };

  const checkSamePassword = (newPassword: string, confirmPassword: string) => {
    return newPassword === confirmPassword;
  };

  const handleTOUCheckBox = (e: any, termsOfUseVersionId: number) => {
    if (e.target.checked) {
      setCheckedTOUs([...checkedTOUs, termsOfUseVersionId]);
    } else {
      setCheckedTOUs(checkedTOUs.filter(item => item !== termsOfUseVersionId));
    }
  };

  return (
    <>
      <PublicHeaderBar />
      <div className="common-content pb-5" data-testid="signUpContent">
        <h1 className="signup-title">{t('text:Sign_up')}</h1>
        <GuideMessage
          message={[
            t(
              'text:After_reviewing_the_user_and_branch_information_enter_your_account_information_to_complete_the_sign_up_process',
            ),
            t('text:Please_contact_the_admin_if_the_information_is_incorrect'),
          ]}
          isImportContentArea
        />
        {/*User information form start -->*/}
        <SectionTitle title={t('text:User_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:User_Code')}
                value={invitedUserInfo?.employeeCode}
                className="information-form__input"
              />
              <FormValue
                label={t('text:User_Name')}
                value={invitedUserInfo?.userName}
                className="information-form__input"
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Telephone')}
                value={invitedUserInfo?.telephone}
                className="information-form__input"
              />
              <FormValue label={t('text:Email')} value={invitedUserInfo?.email} className="information-form__input" />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Authorization')}
                format="code"
                value={t(`code:authority-type.${invitedUserInfo?.authorityType}`)}
              />
            </div>
          </FormContents>
        </FormBorder>
        {/*User information form end -->*/}
        {/*Affiliated Branch Information form start -->*/}
        {invitedUserInfo?.authorityType !== AUTHORITY_TYPE.ADMIN && (
          <>
            <SectionTitle title={t('text:Affiliated_Branch_Information')} className="mt-5" />
            <FormBorder>
              <FormContents>
                <div className="row">
                  <FormValue
                    label={t('text:Branch_Code')}
                    value={branchInfo?.branchCode}
                    className="information-form__input"
                  />
                  <FormValue
                    label={t('text:Branch_Name')}
                    value={branchInfo?.branchName}
                    className="information-form__input"
                  />
                </div>
                <div className="row">
                  <FormValue
                    label={t('text:Telephone')}
                    value={branchInfo?.telephone}
                    className="information-form__input"
                  />
                  <FormValue label={t('text:Fax')} value={branchInfo?.fax} className="information-form__input" />
                </div>
                <div className="row">
                  <FormValue
                    label={t('text:Registered_Office_Address')}
                    value={branchInfo?.address}
                    className="information-form__input"
                    col={12}
                  />
                </div>
              </FormContents>
            </FormBorder>
          </>
        )}
        {/*Affiliated Branch Information form end -->*/}
        {/* Account Information form start  -->*/}
        <form>
          <SectionTitle title={t('text:Account_Information')} className="mt-5" />
          <FormBorder editable>
            <FormContents>
              <div className="row">
                <div className="col-6">
                  <label id="loginId" className="information-form__label star">
                    {t('text:ID')}
                  </label>
                  <div className="d-flex">
                    <input
                      aria-labelledby="loginId"
                      className={`information-form__input ${
                        isLoginIdError || isCheckID === false ? 'error-input-border' : ''
                      }`}
                      type="text"
                      name={getProperty('loginId')}
                      autoComplete="off"
                      ref={register}
                      onChange={() => {
                        checkIdValidation();
                        setCheckID(undefined);
                        clearCheckIdErrors('loginId');
                        clearErrors('loginId');
                      }}
                    />
                    <Button onClick={checkId} disabled={loginIdErrorMessage !== ''}>
                      {t('text:Check_ID')}
                    </Button>
                  </div>
                  {isCheckID && (
                    <div className="text-green mt-1">
                      <FontAwesomeIcon icon={faCheck} /> {t('text:Complete')}
                    </div>
                  )}

                  {isCheckID === false ? (
                    <div className="text-brick-red m-1">
                      {t('text:Please_click_on_the_Check_ID_button_to_see_whether_the_ID_is_available_for_use')}
                    </div>
                  ) : loginIdErrorMessage !== '' ? (
                    <div className="text-brick-red m-1">{loginIdErrorMessage}</div>
                  ) : isLoginIdError ? (
                    <FormErrorMessage error={isLoginIdError} />
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label id="newPassword" className="information-form__label star">
                    {t('text:Password')}
                  </label>
                  <input
                    aria-labelledby="newPassword"
                    className={`information-form__input ${errors.newPassword ? 'error-input-border' : ''}`}
                    type="password"
                    ref={register()}
                    name="newPassword"
                    autoComplete="new-password"
                    onChange={() => clearErrors('newPassword')}
                  />
                  <FormErrorMessage error={errors.newPassword} />
                </div>
                <div className="col-6 position-parent">
                  <div style={{ position: 'absolute', top: '15px', width: '350px' }}>
                    <PasswordValidationBox newPassword={newPassword} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label id="confirmPassword" className="information-form__label star">
                    {t('text:Confirm_Password')}
                  </label>
                  <input
                    aria-labelledby="confirmPassword"
                    className={`information-form__input ${errors.confirmPassword ? 'error-input-border' : ''}`}
                    type="password"
                    ref={register()}
                    name={getProperty('confirmPassword')}
                    autoComplete="new-password"
                    onChange={() => clearErrors('confirmPassword')}
                  />
                  {!checkSamePassword(newPassword, confirmPassword) ? (
                    <div className="text-brick-red mt-1">
                      <FontAwesomeIcon icon={faBan} /> {t('text:Password_do_not_match')}
                    </div>
                  ) : errors.confirmPassword ? (
                    <FormErrorMessage error={errors.confirmPassword} />
                  ) : null}
                </div>
              </div>
            </FormContents>
          </FormBorder>
          {termsOfUseVersions?.map(termsOfUseVersion => (
            <div key={termsOfUseVersion.termsOfUseVersionId} className="TOU-check-wrap">
              <input
                id={String(termsOfUseVersion.termsOfUseVersionId)}
                className="form-check-input"
                type="checkbox"
                checked={checkedTOUs.includes(termsOfUseVersion.termsOfUseVersionId)}
                onChange={e => handleTOUCheckBox(e, termsOfUseVersion.termsOfUseVersionId)}
              />
              <Trans
                i18nKey={
                  termsOfUseVersion.enterpriseType === ENTERPRISE_TYPE.SY
                    ? 'text:I_have_read_and_agree_to_the_Fin2B_Supply_Chain_Financing_Platform_s_Terms_and_Conditions_of_Use'
                    : 'text:I_have_read_and_agree_to_financierName_s_Terms_and_Conditions_of_Use'
                }
                components={{
                  1: <label className="me-1" htmlFor={String(termsOfUseVersion.termsOfUseVersionId)} />,
                  2: (
                    <a
                      className="link-text-color"
                      href={`${process.env.REACT_APP_BASE_URL}${API_AN.TERMS_OF_USE_VERSIONS.DOWNLOAD(
                        termsOfUseVersion.termsOfUseVersionId,
                      )}?type=INLINE`}
                      target={`tou-${termsOfUseVersion.termsOfUseVersionId}`}
                    />
                  ),
                }}
                values={
                  termsOfUseVersion.enterpriseType === ENTERPRISE_TYPE.SY
                    ? undefined
                    : { financierName: termsOfUseVersion.relatedFinancierName }
                }
              />
            </div>
          ))}
          <div className="flex-end mt-5">
            <Button
              size={ButtonSizeEnum.LG}
              disabled={checkedTOUs.length !== termsOfUseVersions?.length}
              onClick={submitRegister}
            >
              {t('text:Register')}
            </Button>
          </div>
        </form>
        {/*Account Information form end -->*/}
      </div>
      <MSWDevTool />;
    </>
  );
}

export default FinancierSignUp;
