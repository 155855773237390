import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { FINANCIER_CLIENT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemFinancierClientList } from 'utils/http/api/system/financier-clients';
import type { SystemFinancierClientListRequest } from 'utils/http/api/system/financier-clients/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstant = () => {
  const { t } = useTranslation(['format']);

  const TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Tax_Code'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const financierClientType = FINANCIER_CLIENT_TYPE.DEALER;

  const SY_PARTNER_LIST_QS_KEY = 'sy-partner-list';

  return {
    t,
    TABLE_HEADERS,
    financierClientType,
    SY_PARTNER_LIST_QS_KEY,
  };
};

function SystemMonitorPartnerList(): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();

  const { t, TABLE_HEADERS, financierClientType, SY_PARTNER_LIST_QS_KEY } = getConstant();

  const { pageable, setPageable } = usePageable(SY_PARTNER_LIST_QS_KEY);

  const [systemMonitorPartnerPage, setSystemMonitorPartnerPage] = useState<Pageable<FinancierClientVOModel[]>>();

  const { register, reset, getValues, setValue } = useForm<SystemFinancierClientListRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const getMonitorPartnerProperty = useProperty<SystemFinancierClientListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemFinancierClientListRequest>(
        setValue,
        getParsedSearchParams(SY_PARTNER_LIST_QS_KEY).formSearchData,
      );
      fetchMonitoringPartnerList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  const fetchMonitoringPartnerList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
    data: SystemFinancierClientListRequest,
  ): Promise<void> => {
    try {
      const systemMonitorPartnerList = await requestSystemFinancierClientList(selectedPageNumber, selectedRowCount, {
        ...data,
        financierClientType,
      });

      updateSearchParams(
        {
          ...data,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowCount,
        },
        SY_PARTNER_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemMonitorPartnerPage(systemMonitorPartnerList);
        setPageable(systemMonitorPartnerList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearch = async (e: any): Promise<void> => {
    e.preventDefault();
    await fetchMonitoringPartnerList(1, pageable.sizePerPage, getValues());
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();
    reset();
  };

  const paginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    await fetchMonitoringPartnerList(
      pageNumber,
      rowCount,
      getParsedSearchParams(SY_PARTNER_LIST_QS_KEY).formSearchData,
    );
  };

  const renderMonitorPartnerListTable = (): JSX.Element[] | JSX.Element | undefined =>
    systemMonitorPartnerPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.financierName} />
        <Td data={item.financierClientName} />
        <Td data={item.financierClientCode} />
        <Td data={item.financierClientTaxCode} />
        <TdLink path={ROUTES_SY.MONITOR_PARTNER.COMPANY_DETAIL_BUILD_PATH(item.financierClientId)} />
      </Tr>
    ));

  return (
    <>
      <HeaderTitle title={t('text:Partner_List')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name={getMonitorPartnerProperty('financierName')} ref={register} />
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name={getMonitorPartnerProperty('financierClientName')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput name={getMonitorPartnerProperty('financierClientCode')} ref={register} />
              <SearchLabel label={t('text:Partner_Tax_Code')} />
              <SearchInput name={getMonitorPartnerProperty('financierClientTaxCode')} ref={register} />
            </div>
          </SearchBorder>

          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {systemMonitorPartnerPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={TABLE_HEADERS} />
          <TableBody numOfCol={TABLE_HEADERS.length}>{renderMonitorPartnerListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemMonitorPartnerList;
