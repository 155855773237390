import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import { TableBody, TableBorder, TableHeader, Td, TdLink, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { LOAN_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { LoanVOModel } from 'models/vo/LoanVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { isLoanDisbursed } from 'utils/status';

interface SyPartnerAgreementRegisteredDetailFinancingListProps {
  data: LoanVOModel[];
  pageable: PageableType;
  paginate: (pageNumber: number, sizePerPage: number) => Promise<void>;
}

function SyPartnerAgreementRegisteredDetailFinancingList({
  data,
  pageable,
  paginate,
}: SyPartnerAgreementRegisteredDetailFinancingListProps): JSX.Element {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const onClickFinancingStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const FINANCING_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Reference_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:AR_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Disbursement_Amount'),
      colWidths: 100,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 80,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickFinancingStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderFinancingListTable = (): ReactNode => {
    return data?.map((item, index) => (
      <Tr key={index}>
        <Td data={item.loanId} />
        <Td data={item.financierLoanId} />
        <Td data={item.invoiceNumber} />
        <Td data={item.referenceNumber} />
        <Td data={item.arNumber} />
        <Td className="text-end" data={isLoanDisbursed(item.loanStatus) ? item.disbursedAmount : '-'} format="number" />
        <Td data={item.disbursedDate} format="date" />
        <Td data={item.repaymentDate} format="date" />
        <Td
          className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
          data={t(`code:financing-status.${item.loanStatus}`)}
          format="code"
        />
        <TdLink
          path={ROUTES_SY.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(item.loanId)}
          state={{
            successInvoiceId: item.successInvoiceId,
            successArId: item.successArId,
            collateralType: item.collateralType,
          }}
        />
      </Tr>
    ));
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_List')} />
      <p className="total-data">
        {t('text:Total')} : {pageable.totalRows}
      </p>
      <TableBorder>
        <TableHeader header={FINANCING_TABLE_HEADERS} />
        <TableBody numOfCol={FINANCING_TABLE_HEADERS.length}>{renderFinancingListTable()}</TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default SyPartnerAgreementRegisteredDetailFinancingList;
