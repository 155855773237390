import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { LOAN_APPROVAL_TYPE, LOAN_STATUS } from 'enums';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';

interface SystemFinancingDetailGuideMessageProps {
  data: LoanDetailVOModel;
  loanInterface: boolean;
  onClickUpdateTransaction: (e: any) => void;
}
function SystemFinancingDetailGuideMessage({
  data,
  loanInterface,
  onClickUpdateTransaction,
}: SystemFinancingDetailGuideMessageProps) {
  const { t } = useTranslation(['format']);

  const renderGuideMessage = (loanStatus: LOAN_STATUS, loanApprovalType: LOAN_APPROVAL_TYPE) => {
    if (loanStatus === LOAN_STATUS.DEALER_REQUESTED) {
      if (loanApprovalType === LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL) {
        return <GuideMessage message={[t('text:The_financing_application_has_been_requested_for_approval')]} />;
      }

      if (loanApprovalType === LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_request_for_approval_of_the_financing_application_has_been_reverted'),
              t('text:Please_check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={data.returnReason}
          />
        );
      }
    }

    if (loanStatus === LOAN_STATUS.DEALER_CANCELLED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_application_has_been_cancelled'),
            t('text:Please_check_the_reason_for_the_cancellation_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={data.cancelReason}
        />
      );
    }

    if (loanStatus === LOAN_STATUS.REJECTED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_application_has_been_rejected'),
            t('text:Please_check_the_reason_for_the_rejection_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={data.rejectReason}
        />
      );
    }

    if (loanStatus === LOAN_STATUS.EXPIRATION) {
      if (!loanApprovalType) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_has_expired'),
              t(
                'text:Please_check_the_reason_for_expiration_below_and_contact_the_responsible_branch_for_further_detatils',
              ),
            ]}
            reasonTitle={t('text:REASON_FOR_EXPIRATION')}
            reason={data.expirationReason}
          />
        );
      }
    }

    if (loanStatus === LOAN_STATUS.APPROVED) {
      if (!loanInterface) {
        if (loanApprovalType === LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_rejection_has_been_requested'),
                t('text:See_the_reason_for_the_rejection_below'),
              ]}
              reasonTitle={t('text:REASON_FOR_REJECTION')}
              reason={data.rejectReason}
            />
          );
        } else if (loanApprovalType === LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_rejection_request_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={data.returnReason}
            />
          );
        } else return <GuideMessage message={[t('text:The_financing_application_has_been_approved')]} />;
      }
    }

    if (loanStatus === LOAN_STATUS.OVERDUE) {
      if (!loanInterface) {
        if (loanApprovalType === LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_delinquent_status_request_has_been_reverted'),
                t('text:Please_see_below_for_further_details'),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={data.returnReason}
            />
          );
        }
      }
    }

    if (
      loanStatus === LOAN_STATUS.APPROVED ||
      loanStatus === LOAN_STATUS.DISBURSED ||
      loanStatus === LOAN_STATUS.OVERDUE ||
      loanStatus === LOAN_STATUS.REPAID
    ) {
      if (loanInterface) {
        return (
          <GuideMessage
            message={[
              t('text:Click_on_the_Update_Transaction_button_to_reflect_the_changes_to_the_financing_information'),
            ]}
          >
            {{
              button: <Button onClick={onClickUpdateTransaction}>{t('text:Update_Transaction')}</Button>,
            }}
          </GuideMessage>
        );
      }
    }
  };

  return <>{renderGuideMessage(data.loanStatus, data.loanApprovalType)}</>;
}

export default SystemFinancingDetailGuideMessage;
