import {
  COLLATERAL_TYPE,
  CURRENCY_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  stringToEnum,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { getDday } from 'utils/date/date';
import { hasValue } from 'utils/valueManager/ValueManager';

export interface AnchorLoanVO {
  anchorLoanId: number;
  financierLoanId: number;
  anchorAgreementId: number;
  anchorFinancingOptionId: string;
  currencyType: string;
  collateralType: string;
  successArId: number;
  arNumber: string;
  successInvoiceId: number;
  invoiceNumber: string;
  anchorPartnerId: number;
  anchorPartnerName: string;
  dealerClientId: number;
  dealerClientName: string;
  financierId: number;
  financierName: string;
  anchorClientId: number;
  anchorClientName: string;
  interestRepaymentType: string;
  showNetDisbursementAmount: boolean;
  netDisbursementAmount: BigNumber;
  disbursedAmount: number;
  principalAmount: number;
  repaidPrincipalAmount: number;
  loanStatus: string;
  loanApprovalType: string;
  createdDateTime: string;
  requestedDateTime: string;
  disbursedDate: string;
  repaymentDate: string;
  repaidDate: string;
  approvedDateTime: string;
  desiredDisburseDate: string;
  approvedDisburseDate: string;
}

export interface AnchorLoanVOModel extends AnchorLoanVO {
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  loanStatus: LOAN_STATUS;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  loanApprovalType: LOAN_APPROVAL_TYPE;

  // only front
  remainingFinancingTerm?: string;
  outstandingBalance: BigNumber; // disbursedAmount - repaidPrincipalAmount
}

export function formattingToAnchorLoanVOModel(data: AnchorLoanVO): AnchorLoanVOModel {
  const calculatorBigNumber = new CalculatorBigNumber();

  const getRemainingFinancingTerm = (loanStatus: LOAN_STATUS, repaymentDate: string) => {
    if ((loanStatus === LOAN_STATUS.DISBURSED || loanStatus === LOAN_STATUS.OVERDUE) && hasValue(repaymentDate)) {
      return getDday(repaymentDate);
    }
  };

  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
    interestRepaymentType: stringToEnum(INTEREST_REPAYMENT_TYPE, data.interestRepaymentType),
    loanApprovalType: stringToEnum(LOAN_APPROVAL_TYPE, data.loanApprovalType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    // only front
    remainingFinancingTerm: getRemainingFinancingTerm(stringToEnum(LOAN_STATUS, data.loanStatus), data.repaymentDate),
    outstandingBalance: calculatorBigNumber.add(data.disbursedAmount).minus(data.repaidPrincipalAmount).get(), // disbursedAmount - repaidPrincipalAmount
  };
}
