import type Pageable from 'models/Pageable';
import type { EarlyRepaymentRequestVO, EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import { formattingToEarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';
import type { DealerEarlyRepaymentListRequest } from 'utils/http/api/dealer/early-repayment-requests/request';

// 조기상환 목록 조회
export async function requestDealerEarlyRepaymentList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerEarlyRepaymentListRequest,
): Promise<Pageable<EarlyRepaymentRequestVOModel[]>> {
  const response = await http.get<Pageable<EarlyRepaymentRequestVO[]>>({
    url: API_DE.EARLY_REPAYMENT_REQUESTS.EARLY_REPAYMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const EarlyRepaymentList: EarlyRepaymentRequestVOModel[] = response.content.map((data: EarlyRepaymentRequestVO) =>
    formattingToEarlyRepaymentRequestVOModel(data),
  );
  const EarlyRepaymentPage: Pageable<EarlyRepaymentRequestVOModel[]> = {
    ...response,
    content: EarlyRepaymentList,
  };

  return EarlyRepaymentPage;
}

// 조기상환 상세 조회
export async function requestDealerEarlyRepaymentDetail(
  earlyRepaymentId: number,
): Promise<EarlyRepaymentRequestVOModel> {
  const response = await http.get<EarlyRepaymentRequestVO>({
    url: API_DE.EARLY_REPAYMENT_REQUESTS.EARLY_REPAYMENT_DETAIL(earlyRepaymentId),
  });

  return formattingToEarlyRepaymentRequestVOModel(response);
}
