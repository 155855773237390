import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type { COLLATERAL_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';

type ExtraInformationStateType = {
  supportedCollateralType: COLLATERAL_TYPE;
  errorHandlerOfLocationState: () => boolean;
  anchorFinancingOptionId: number;
};

const ExtraInformationContext = createContext<ExtraInformationStateType | null>(null);

export const useExtraInformationContext = () => {
  const context = useContext(ExtraInformationContext);
  if (!context) {
    throw Error('useExtraInformationContext should be used within ExtraInformationContext.Provider');
  }

  return context;
};

type ExtraInformationProviderPropsType = {
  children: ReactNode;
};

const ExtraInformationProvider = ({ children }: ExtraInformationProviderPropsType) => {
  const [{ supportedCollateralType }, errorHandlerOfLocationState] = useLocationState<{
    supportedCollateralType: COLLATERAL_TYPE;
  }>(['supportedCollateralType']);

  const { anchorFinancingOptionId } = useParams() as any;

  const value = useMemo(
    () => ({
      supportedCollateralType,
      errorHandlerOfLocationState,
      anchorFinancingOptionId,
    }),
    [supportedCollateralType, errorHandlerOfLocationState, anchorFinancingOptionId],
  );

  return <ExtraInformationContext.Provider value={value}>{children}</ExtraInformationContext.Provider>;
};

export default ExtraInformationProvider;
