import { createContext, useContext } from 'react';

import type { TermSpreadVOModel } from 'models/vo/TermSpreadVO';

type TermSpreadStateType = {
  termSpreadList: TermSpreadVOModel[] | null;
  updateTermSpreadList: (termSpreadList: TermSpreadVOModel[]) => void;
};

export const TermSpreadContext = createContext<TermSpreadStateType | null>(null);

export const useTermSpreadContext = () => {
  const context = useContext(TermSpreadContext);

  if (!context) {
    throw Error('useTermSpreadContext should be used within TermSpreadContext.Provider');
  }

  return context;
};
