import type Pageable from 'models/Pageable';
import type { DeletedInvoiceVO, DeletedInvoiceVOModel } from 'models/vo/DeletedInvoiceVO';
import { formattingToDeletedInvoiceVOModel } from 'models/vo/DeletedInvoiceVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierDeletedInvoicesListRequest } from './requests';

// 앵커 - 등록 완료된 AR 목록 조회
export async function requestFinancierDeletedInvoicesList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierDeletedInvoicesListRequest,
): Promise<Pageable<DeletedInvoiceVOModel[]>> {
  const response = await http.get<Pageable<DeletedInvoiceVO[]>>({
    url: API_FI.DELETED_INVOICES.DELETED_INVOICES,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return { ...response, content: response.content.map(data => formattingToDeletedInvoiceVOModel(data)) };
}
