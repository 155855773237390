import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { DuplicateExceptionCode } from 'enums/exception';
import { formErrorHandler } from 'utils/error/manager';
import { requestFiWaitingBankCodeRegister } from 'utils/http/api/financier/waiting-bank-code';
import type { FiWaitingBankCodeRegisterRequest } from 'utils/http/api/financier/waiting-bank-code/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function FinancierBankCodeRegister() {
  const { t } = useTranslation(['format']);
  const history = useHistory();
  const modal = useModal();

  // useForm
  const bankCodeRegisterForm = useForm<FiWaitingBankCodeRegisterRequest>({
    mode: 'onSubmit',
  });

  const onClickCancel = (e: any) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration_request?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_LIST),
      },
    );
  };

  const onClickRegister = (e: any) => {
    e.preventDefault();

    modal.show(<h6>{t('text:Would_you_like_to_request_the_registration_of_the_bank_code_information?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => {
        requestRegister();
      },
    });

    const requestRegister = async () => {
      const data = bankCodeRegisterForm.getValues();
      const showSubmitSuccessModal = () => {
        modal.show(
          <h6>
            {t('text:The_request_has_been_submitted_successfully')}
            <br />
            {t('text:See_the_request_in_the_Waiting_for_Confirmation_tab')}
          </h6>,
          {
            closeBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_LIST),
          },
        );
      };
      try {
        requestDTOParser(data);
        await requestFiWaitingBankCodeRegister(data);
        showSubmitSuccessModal();
      } catch (e: any) {
        if (e.message === DuplicateExceptionCode.BANK_CODE_DUPLICATE) {
          modal.show(<h6>{t('text:The_bank_or_branch_codes_have_already_been_registered')}</h6>);
        } else {
          modal.show(e);
        }
        formErrorHandler<FiWaitingBankCodeRegisterRequest>(
          e,
          bankCodeRegisterForm.setError,
          bankCodeRegisterForm.clearErrors,
        );
      }
    };
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Bank_Code_Registration')} />
      <GuideMessage
        message={[
          t('text:Enter_the_information_of_the_bank_codes_you_would_like_to_register_on_the_platform'),
          <Trans
            key="key"
            i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
            components={{ 1: <span className="asterisk" /> }}
          />,
          t(
            'text:If_the_bank_code_has_already_been_registered_on_the_platform,_revise_the_information_in_the_bank_code_details',
          ),
        ]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Bank_Code_Information')} />
        <form>
          <FormBorder editable={true}>
            <FormContents>
              <div className="row">
                <FormInput
                  label={t('text:Bank_Code')}
                  name="bankCode"
                  ref={bankCodeRegisterForm.register}
                  requiredOptions={{ required: true }}
                  error={bankCodeRegisterForm.errors.bankCode}
                />
                <FormInput
                  label={t('text:Bank_Name')}
                  name="bankName"
                  ref={bankCodeRegisterForm.register}
                  requiredOptions={{ required: true }}
                  error={bankCodeRegisterForm.errors.bankName}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Branch_Code')}
                  name="branchCode"
                  ref={bankCodeRegisterForm.register}
                  error={bankCodeRegisterForm.errors.branchCode}
                />
                <FormInput
                  label={t('text:Branch_Name')}
                  name="branchName"
                  ref={bankCodeRegisterForm.register}
                  error={bankCodeRegisterForm.errors.branchName}
                />
              </div>
            </FormContents>
          </FormBorder>
          <div className="flex-end mt-4">
            <Button
              size={ButtonSizeEnum.LG}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
              onClick={onClickCancel}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.LG} className="ms-3" onClick={onClickRegister}>
              {t('text:Register')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default FinancierBankCodeRegister;
