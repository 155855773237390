﻿/************************************************************************/
/* CheckPluginValid: version plugin >1.0.12                         	*/
/************************************************************************/
import { BkavCAPlugin } from './BkavCAPlugin';

var functionCallBackAfterCheckSuccessEnvironmentSign;
var CheckPluginValid = {
  CheckPluginValid: function (jsCallback) {
    functionCallBackAfterCheckSuccessEnvironmentSign = jsCallback;
    setTimeout(this.CheckPlugin, 1);
  },

  CheckPlugin() {
    BkavCAPlugin.Connect(CheckPluginValid.ProcessResultConnect);
  },

  ProcessResultConnect(result) {
    //connect socket ok
    if (result == 1) {
      console.log('연결 완료');
      BkavCAPlugin.Connect(functionCallBackAfterCheckSuccessEnvironmentSign);
    } else {
      // alert("Vui lòng kiểm tra lại Plugin trên máy");
      return functionCallBackAfterCheckSuccessEnvironmentSign('0');
    }
  },
};

export { CheckPluginValid };
