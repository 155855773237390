import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import ClipboardWriteButton from 'components/stateless/Button/ClipboardWriteButton';
import Form from 'components/stateless/Form';
import { FormErrorMessage } from 'components/stateless/Form/ErrorMessage';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import { Border, Content, Row, SubTitle } from 'components/templates/section';
import { DEALER_AGREEMENT_TYPE } from 'enums';
import { ColorMap } from 'enums/colorMap';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';

import useAssociatedAnchorMasterAgreementController from './useAssociatedAnchorMasterAgreementController';

const AssociatedAnchorMasterAgreement = () => {
  const { t } = useTranslation(['format']);

  const {
    register,
    errors,
    watch,
    isDealerAgreementTypeRadioDisabled,
    isSearchAnchorAgreementButtonVisible,
    isSearchAnchorUserButtonVisible,
    isMoreAnchorUserInfoVisible,
    agreement,
    anchorAgreementInfo,
    divisionRegistrable,
    header,
    isClipboardWriteButtonVisible,
    isClipboardWriteButtonDisabled,
    isSendButtonDisabled,
    handleSearchAnchorAgreementClick,
    handleDealerAgreementTypeChange,
    handleSearchAnchorUserClick,
    handleClipboardButtonClick,
    handleAnchorUserInviteButtonClick,
  } = useAssociatedAnchorMasterAgreementController();

  const { division, divisionName } = agreement;
  const { anchorAgreementNo, anchorClientName, anchorAgreementStartDate, anchorAgreementExpiryDate, anchorUserList } =
    anchorAgreementInfo;

  const { dealerAgreementType } = watch();

  return (
    <>
      <SubTitle>{t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT')}</SubTitle>
      <Content hasSubRow>
        <Row>
          <Form.Control name="dealerAgreementType">
            <Form.Label position="top" required>
              {t('text:Is_there_an_associated_Anchor?')}
            </Form.Label>
            <Form.Radio onChange={handleDealerAgreementTypeChange} disabled={isDealerAgreementTypeRadioDisabled}>
              <Form.Option label={t('text:Yes')} value={DEALER_AGREEMENT_TYPE.WITH_ANCHOR} />
              <Form.Option label={t('text:No')} value={DEALER_AGREEMENT_TYPE.WITHOUT_ANCHOR} />
            </Form.Radio>
          </Form.Control>
        </Row>
        {dealerAgreementType === DEALER_AGREEMENT_TYPE.WITH_ANCHOR && (
          <>
            <Row singleColumnPosition={isSearchAnchorAgreementButtonVisible ? 'bottom' : 'all'}>
              <Form.Label required={isSearchAnchorAgreementButtonVisible}>
                {t('text:Anchor_Master_Agreement')}
              </Form.Label>
              {isSearchAnchorAgreementButtonVisible && (
                <div className="flex-align-center">
                  <Button className="me-2" size={ButtonSizeEnum.SM} bold onClick={handleSearchAnchorAgreementClick}>
                    {t('text:Search_Agreement')}
                  </Button>
                  {/* currencyType : 앵커 약정 변경 시 함께 업데이트되는 값으로 이 값이 없을 때 백엔드에서 에러가 내려와 사용 */}
                  {errors?.currencyType && <FormErrorMessage error={errors.currencyType} />}
                </div>
              )}
              <Border>
                <Content isInnerStyle>
                  <input type="hidden" name="anchorAgreementId" ref={register} />
                  <Row>
                    <Form.Control>
                      <Form.Label>{t('text:Anchor_Master_Agreement_Number')}</Form.Label>
                      <Form.Value value={anchorAgreementNo} />
                    </Form.Control>
                  </Row>
                  <Row>
                    <Form.Control>
                      <Form.Label>{t('text:Anchor_Name')}</Form.Label>
                      <Form.Value value={anchorClientName} />
                    </Form.Control>
                  </Row>
                  {divisionRegistrable && (
                    <Row>
                      <Form.Control>
                        <Form.Label>{t('text:Division')}</Form.Label>
                        <Form.Value value={division ? [division, divisionName].join(' / ') : ''} />
                      </Form.Control>
                    </Row>
                  )}
                  <Row>
                    <Form.Control>
                      <Form.Label>{t('text:Effective_Date')}</Form.Label>
                      <Form.Value value={anchorAgreementStartDate} format="date" />
                    </Form.Control>
                  </Row>
                  <Row>
                    <Form.Control>
                      <Form.Label>{t('text:Expiration_Date')}</Form.Label>
                      <Form.Value value={anchorAgreementExpiryDate} format="date" />
                    </Form.Control>
                  </Row>
                </Content>
              </Border>
            </Row>
            <Row className="pb-2" singleColumnPosition={isSearchAnchorUserButtonVisible ? 'bottom' : 'all'}>
              <Form.Label required={isSearchAnchorUserButtonVisible}>{t('text:Anchor_User')}</Form.Label>
              {isSearchAnchorUserButtonVisible && (
                <Button size={ButtonSizeEnum.SM} bold onClick={handleSearchAnchorUserClick}>
                  {t('text:Search_Anchor_User')}
                </Button>
              )}
              <TableBorder className="table-border">
                <TableHeader header={header} />
                <TableBody numOfCol={header.length}>
                  {anchorUserList.map(anchorUser => {
                    return (
                      <Tr key={anchorUser.bankUserId}>
                        <Td data={anchorUser.bankUserId} />
                        <Td data={anchorUser.name} />
                        <Td data={anchorUser.mobile} />
                        <Td data={anchorUser.email} />
                        <Td data={anchorUser.anchorUserOtpSerialNo} />
                        <Td>{t(`code:authority-type.${anchorUser.authorityType}`)}</Td>
                        {isMoreAnchorUserInfoVisible && (
                          <>
                            <Td
                              className={getStatusTextClass('USER_STATUS', anchorUser?.userStatus)}
                              data={t(`code:user-status.${anchorUser.userStatus}`)}
                              format="code"
                            />
                            <Td className="information-table-more">
                              {isClipboardWriteButtonVisible(anchorUser.userStatus) && (
                                <ClipboardWriteButton
                                  id={String(anchorUser.id)}
                                  onClickClipboardWriteButton={() => handleClipboardButtonClick(anchorUser.id)}
                                  toolTipBgColor={ColorMap.black}
                                  style={{ backgroundColor: ColorMap.white }}
                                  disabled={isClipboardWriteButtonDisabled(anchorUser.id)}
                                  toolTipText={t('text:Copy_Sign_up_link')}
                                />
                              )}
                              <Button
                                bold
                                onClick={() => handleAnchorUserInviteButtonClick(anchorUser)}
                                disabled={isSendButtonDisabled(anchorUser.userStatus)}
                              >
                                {t('text:Send')}
                              </Button>
                            </Td>
                          </>
                        )}
                      </Tr>
                    );
                  })}
                </TableBody>
              </TableBorder>
            </Row>
          </>
        )}
      </Content>
    </>
  );
};

export default AssociatedAnchorMasterAgreement;
