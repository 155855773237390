import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty, isNil } from 'lodash-es';

import {
  AUTHORITY_TYPE,
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  DEALER_AGREEMENT_STATUS,
  INTEREST_REPAYMENT_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
} from 'enums';
import { AgreementExceptionCode } from 'enums/exception';
import type { SignInModel } from 'models/SignInModel';
import { convertToDealerAgreementResetData } from 'models/convertor/dealerAgreement';
import { formErrorHandler } from 'utils/error/manager';
import { isNilOrEmptyString } from 'utils/helpers';
import { requestUpdateDealerAgreementDetail } from 'utils/http/api/financier/waiting-dealer-agreements';
import type { CreateFiWaitingDealerAgreementDTO } from 'utils/http/api/financier/waiting-dealer-agreements/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getObjectValue } from 'utils/object';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser, requestDTOParserForArrayObj } from 'utils/valueManager/ValueManager';

import useAgreementViewModel from '../models/agreement/useAgreementViewModel';
import useAnchorAgreementInfoViewModel from '../models/agreement/useAnchorAgreementInfoViewModel';
import useTermSpreadViewModel from '../models/agreement/useTermSpreadViewModel';
import useExtraInformationViewModel from '../models/extraInformation/useExtraInformationViewModel';
import useFinancierSettingViewModel from '../models/financierSetting/useFinancierSettingViewModel';

const useRegistrationDetailController = () => {
  const [isEditable, setIsEditable] = useState(false);

  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();

  const { supportedCollateralType, dealerAgreementId, errorHandlerOfLocationState, useAgreementInterface } =
    useExtraInformationViewModel();
  const {
    agreement,
    fetchAgreementDetail,
    updateIsSearchedAgreement,
    isSearchedAgreement,
    updateAllAgreement,
    isMaturityExtension,
  } = useAgreementViewModel();
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();
  const { termSpreadList } = useTermSpreadViewModel();
  const { fetchFinancierSetting, factoringEnable } = useFinancierSettingViewModel();

  const [isAgreementTab, setIsAgreementTab] = useState<boolean>(true);

  const methods = useForm<CreateFiWaitingDealerAgreementDTO>();
  const { reset, getValues, setError, clearErrors } = methods;

  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const isUserEditable =
    signInModel?.branchId === agreement?.branchId && signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR;

  const isEditButtonVisible = isUserEditable && isAgreementTab;

  const isEditableStyle = useAgreementInterface ? isSearchedAgreement : isEditable;

  const isVendorFactoring = factoringEnable && isAr;

  const GuideMessageText = (() => {
    const isShowGuideMessage = agreement.dealerAgreementStatus === DEALER_AGREEMENT_STATUS.SUSPENDED;

    if (isShowGuideMessage) {
      const fixedMessage = t('text:The_master_agreement_and_all_subsequent_transactions_have_been_suspended');

      return isUserEditable
        ? [
            fixedMessage,
            t(
              'text:To_allow_transactions_to_resume_modify_the_Agreement_Suspension_field_in_the_General_Information_section',
            ),
          ]
        : [fixedMessage];
    }

    return [];
  })();

  const getReadOnlyValue = useCallback(
    (parameterName?: string): boolean => {
      if (!useAgreementInterface || !isEditable) return false;

      const allAgreement = { ...agreement, ...anchorAgreementInfo, termSpreadList };

      const agreementValue = getObjectValue({
        object: allAgreement,
        name: parameterName ?? '',
      });

      return !(isSearchedAgreement && (isNil(agreementValue) || agreementValue === ''));
    },
    [useAgreementInterface, isEditable, agreement, anchorAgreementInfo, termSpreadList, isSearchedAgreement],
  );

  const resetPage = async () => {
    try {
      if (isNil(dealerAgreementId) || errorHandlerOfLocationState()) return;

      const [agreement] = await Promise.all([fetchAgreementDetail(dealerAgreementId), fetchFinancierSetting()]);

      if (agreement) {
        if (agreement.basisInterestBankCode === BASIS_INTEREST_TYPE.FIXED) agreement.basisInterestBankCode = '';
        reset(convertToDealerAgreementResetData(agreement));
        setIsEditable(false);
        updateIsSearchedAgreement(false);
        updateAllAgreement(agreement);
      }
    } catch (error) {
      showModal(error);
    }
  };

  // Tab Button
  const handleRevisionHistoryTabClick = () => setIsAgreementTab(false);
  const handleAgreementTabClick = () => setIsAgreementTab(true);

  // Cancel Button
  const handleCancelButtonClick = () => {
    showModal(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_agreement?')}
        <br />
        {t('text:If_the_modification_is_stopped_the_entered_content_will_not_be_saved')}.
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: resetPage,
      },
    );
  };

  // Request Change Button
  const handleARRequiredFieldsValidation = () => {
    const data = getValues();
    /**
     * AR에서 필수
     * 전송 시 몇몇 필드들에 대한 검증이 먼저 들어가기 때문에 앞서 들어간 검증이 통과되어야 에러코드가 내려옴(늦게). 우선 FE에서 에러 표시
     */
    const arRequiredFields = [
      { name: 'principalRepaymentAccountFinancierName', value: data.principalRepaymentAccountFinancierName },
      { name: 'principalRepaymentAccountBranchName', value: data.principalRepaymentAccountBranchName },
      { name: 'principalRepaymentAccount', value: data.principalRepaymentAccount },
      { name: 'principalRepaymentAccountOwner', value: data.principalRepaymentAccountOwner },
    ];

    if (isAr) {
      arRequiredFields.map(item => {
        if (isNilOrEmptyString(item.value)) {
          setError(item.name, {
            message: t('text:This_information_is_required'),
          });
        } else clearErrors(item.name);
      });
    }
  };

  const handleVendorFactoringRequiredFieldsValidation = () => {
    const data = getValues();
    const vendorFactoringRequiredFields = [
      { name: 'settlementAccountFinancierName', value: data.settlementAccountFinancierName },
      { name: 'settlementAccountBranchName', value: data.settlementAccountBranchName },
      { name: 'settlementAccount', value: data.settlementAccount },
      { name: 'settlementAccountOwner', value: data.settlementAccountOwner },
    ];

    if (isVendorFactoring) {
      vendorFactoringRequiredFields.forEach(item => {
        if (isNilOrEmptyString(item.value)) {
          setError(item.name, {
            message: t('text:This_information_is_required'),
          });
        } else clearErrors(item.name);
      });
    }
  };

  const handleUpdateAgreementError = (error: any) => {
    showModal(error);

    const isInvalidError = !isEmpty(formErrorHandler<CreateFiWaitingDealerAgreementDTO>(error, setError, clearErrors));

    handleARRequiredFieldsValidation();
    handleVendorFactoringRequiredFieldsValidation();

    if (isInvalidError) return;

    const getMonthlyInterestRepaymentDateErrorMessage = () => {
      const monthlyInterestRepaymentDate = getValues('monthlyInterestRepaymentDate');
      if (isNil(monthlyInterestRepaymentDate) || Number(monthlyInterestRepaymentDate) === 0)
        return t('text:0_and_blank_are_not_allowed');
      else return t('text:Date_must_be_between_1_and_31');
    };

    switch (error.code) {
      case AgreementExceptionCode.INVALID_BASIS_INTEREST_RATE:
        return setError('basisInterestRate', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_LOAN_TERM:
        return setError('loanTermUnit', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_LOAN_LIMIT_CHECK_TYPE:
        return setError('loanLimitCheckType', { shouldFocus: true });
      case AgreementExceptionCode.OMITTED_ANCHOR_AGREEMENT_INFO:
        return setError('anchorAgreementId', { shouldFocus: true });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_INTEREST_REPAYMENT_TYPE:
        return setError('monthlyInterestRepaymentDate', {
          shouldFocus: true,
          message: getMonthlyInterestRepaymentDateErrorMessage(),
        });
      default:
        return;
    }
  };

  const updateAgreement = async () => {
    const data = getValues();

    data.termSpreadList = data.termSpreadList ?? termSpreadList;
    data.targetDealerAgreementId = dealerAgreementId;
    const bankUserIdList = data.bankUserIdList as string;
    data.bankUserIdList = bankUserIdList ? bankUserIdList.split(',') : [];
    if (data.basisInterestType === BASIS_INTEREST_TYPE.FIXED) data.basisInterestBankCode = BASIS_INTEREST_TYPE.FIXED;
    if (isMaturityExtension === 'false') data.maxExtensibleLoanCount = data.maxExtensibleLoanCount ?? 0;

    if (isAr) {
      if (data.loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE) data.loanLimitAmount = String(0);
    }

    if (isVendorFactoring) {
      data.interestRepaymentType = INTEREST_REPAYMENT_TYPE.EARLIER;
      data.showNetDisbursementAmount = 'true';
      data.earlyRepaymentAllowable = 'false';
      data.maxExtensibleLoanCount = 0;
      data.showExpectedTotalInterestRate = 'true';
    }

    requestDTOParser(data);
    requestDTOParserForArrayObj(data.termSpreadList);
    await requestUpdateDealerAgreementDetail(data);
  };

  const showRequestChangeCompletedModal = () => {
    showModal(
      <h6>
        {t('text:Request_for_modification_approval_has_been_completed')}
        <br />
        {t('text:Modification_will_be_completed_after_approval_by_the_Authorizer')}
      </h6>,
      {
        closeBtnText: t('text:OK'),
        closeBtnCb: resetPage,
      },
    );
  };

  const handleRequestChangeButtonClick = () => {
    showModal(<h6>{t('text:Would_you_like_to_request_approval_for_modification?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnText: t('text:Confirm'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await updateAgreement();
          showRequestChangeCompletedModal();
        } catch (e) {
          handleUpdateAgreementError(e);
        }
      },
    });
  };

  // Edit Button
  const handleEditButtonClick = () => setIsEditable(true);

  useEffect(() => {
    resetPage();
  }, []);

  return {
    GuideMessageText,
    supportedCollateralType,
    isAr,
    isEditableStyle,
    isEditable,
    isEditButtonVisible,
    useAgreementInterface,
    methods,
    getReadOnlyValue,
    handleCancelButtonClick,
    handleRequestChangeButtonClick,
    handleEditButtonClick,
    isRequestChangeButtonDisabled: !(useAgreementInterface ? isSearchedAgreement : true),
    handleRevisionHistoryTabClick,
    handleAgreementTabClick,
    isVendorFactoring,
  };
};

export default useRegistrationDetailController;
