// 가입 대상 약정 인증사용자 조회
import type { AnchorUserVO, AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { formattingToAnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { DealerAuthorizerVO, DealerAuthorizerVOModel } from 'models/vo/DealerAuthorizerVO';
import { formattingToDealerAuthorizerVOModel } from 'models/vo/DealerAuthorizerVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

export async function requestInvitedAnchorUser(code: string): Promise<AnchorUserVOModel> {
  const response = await http.get<AnchorUserVO>({ url: API_AN.AGREEMENTS.INVITED_ANCHOR_USER, data: { code } });

  return formattingToAnchorUserVOModel(response);
}

export async function requestInvitedDealerAuthorizer(code: string): Promise<DealerAuthorizerVOModel> {
  const response = await http.get<DealerAuthorizerVO>({
    url: API_AN.AGREEMENTS.INVITED_DEALER_AUTHORIZER,
    data: { code },
  });

  return formattingToDealerAuthorizerVOModel(response);
}
