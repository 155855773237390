import type Pageable from 'models/Pageable';
import type { UsageSummaryVO, UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SearchSyUsageDTO } from './requests';

export async function requestSyUsageList(
  pageNumber: number,
  rowCount: number,
  searchValue: SearchSyUsageDTO,
): Promise<Pageable<UsageSummaryVOModel[]>> {
  return await http.get<Pageable<UsageSummaryVO[]>>({
    url: API_SY.USAGE.USAGE_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
}
