import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import {
  convertToDealerAgreementResetData,
  convertToInterfaceDealerAgreementData,
} from 'models/convertor/dealerAgreement';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import { requestFinancierDealerAgreementDetailByIdInterface } from 'utils/http/api/financier/dealer-agreements';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import SearchInterfaceByContactNoModal from '../../../modals/SearchInterfaceByContactNoModal';
import SearchInterfaceByIdModal from '../../../modals/SearchInterfaceByIdModal';
import SearchInterfaceByKeyUnionModal from '../../../modals/SearchInterfaceByKeyUnionModal';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorAgreementInfoViewModel from '../../../models/agreement/useAnchorAgreementInfoViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useWaitingDetailLoadAgreementController = () => {
  const { t } = useTranslation(['format']);
  const { show: showModal, id: modalId } = useModal();

  const { isFirstRegisteredWaitingAgreement } = useAgreementViewModel();

  const {
    supportedCollateralType,
    useAgreementInterfaceByKeyUnion,
    useAnchorUserInterface,
    updateIsMatchedWithUploadedPartner,
  } = useExtraInformationViewModel();

  const { agreement, updateAllAgreement, updateIsSearchedAgreement } = useAgreementViewModel();
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();
  const { dealerAgreementId } = agreement;

  const interfaceDataRef = useRef<DealerAgreementDetailVOModel>({} as DealerAgreementDetailVOModel);

  const {
    methods: { reset },
  } = useFormContext();

  const setSearchedInterfaceData = useCallback(searchedData => {
    interfaceDataRef.current = searchedData;
  }, []);

  const handleApplyClick = () => {
    const convertedInterfaceAgreement = convertToInterfaceDealerAgreementData({
      prevAnchorAgreementData: anchorAgreementInfo,
      interfaceData: interfaceDataRef.current,
      useAnchorUserInterface,
    });

    if (convertedInterfaceAgreement) {
      updateIsSearchedAgreement(true);
      updateAllAgreement({
        ...agreement,
        ...convertedInterfaceAgreement,
        dealerAgreementId,
      });
      updateIsMatchedWithUploadedPartner(false);
      reset(convertToDealerAgreementResetData(convertedInterfaceAgreement));
    }
  };

  const updateDealerAgreementModalOptions: ModalOptions = {
    modalType: ModalType.CONFIRM,
    modalSize: ModalSize.XL,
    confirmBtnText: t('text:Apply'),
    closeBtnText: t('text:Close'),
    title: isFirstRegisteredWaitingAgreement ? t('text:Search_Partner_Agreement') : t('text:Update_Agreement'),
    confirmBtnCb: handleApplyClick,
  };

  const showSearchInterfaceByIdModal = async () => {
    try {
      showLoadingUI();
      const interfaceData = await requestFinancierDealerAgreementDetailByIdInterface(dealerAgreementId);
      setSearchedInterfaceData(interfaceData);
      showModal(<SearchInterfaceByIdModal searchedInterfaceData={interfaceData} />, updateDealerAgreementModalOptions);
    } catch (e) {
      showModal(e);
    } finally {
      unShowLoadingUI();
    }
  };

  const showSearchInterfaceByKeyUnionModal = () => {
    showModal(
      <SearchInterfaceByKeyUnionModal
        modalId={modalId}
        collateralType={supportedCollateralType}
        getInterfaceData={setSearchedInterfaceData}
      />,
      updateDealerAgreementModalOptions,
    );
  };

  const showSearchInterfaceByContactNoModal = () => {
    showModal(
      <SearchInterfaceByContactNoModal
        modalId={modalId}
        collateralType={supportedCollateralType}
        getInterfaceData={setSearchedInterfaceData}
      />,
      updateDealerAgreementModalOptions,
    );
  };

  const handleLoadAgreementButtonClick = () => {
    if (isFirstRegisteredWaitingAgreement) {
      useAgreementInterfaceByKeyUnion ? showSearchInterfaceByKeyUnionModal() : showSearchInterfaceByContactNoModal();
    } else {
      useAgreementInterfaceByKeyUnion ? showSearchInterfaceByIdModal() : showSearchInterfaceByContactNoModal();
    }
  };

  return { handleLoadAgreementButtonClick };
};

export default useWaitingDetailLoadAgreementController;
