import Select from './Select';
import { useFormContext } from '..';

import type { SelectProps } from './Select';

const UncontrolledSelect = (props: SelectProps) => {
  const { name } = props;
  const {
    methods: { register, watch, errors },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();
  const fetchedReadOnly = getReadOnlyValue(name);

  const currentValue = watch(name ?? '');

  const error = errors?.[name ?? ''];

  return (
    <Select
      {...props}
      ref={register}
      isEditable={isEditable}
      fetchedReadOnly={fetchedReadOnly}
      currentValue={currentValue}
      error={error}
    />
  );
};
export default UncontrolledSelect;
