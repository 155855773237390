import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextArea from 'components/stateless/TextArea/TextArea';
import useModal from 'utils/modal/useModal';

interface PropsType {
  label?: string;
  required?: boolean;
  maxLength?: number;
  getReturnReason: (returnReason: string) => void;
  modalId: number;
}

const ReasonModal = ({ label, required = false, maxLength = 1000, getReturnReason, modalId }: PropsType) => {
  const modal = useModal();
  const { t } = useTranslation();

  const { watch, register } = useForm();
  const { reason } = watch();

  useEffect(() => {
    if (required) modal.disableConfirmBtn(modalId, !reason);
    getReturnReason(reason);
  }, [reason]);

  return (
    <div className="mt-3">
      <TextArea
        className="bg-sub100"
        label={label ? label : t('text:Reason')}
        name="reason"
        maxLength={maxLength}
        ref={register}
      />
    </div>
  );
};

export default React.memo(ReasonModal);
