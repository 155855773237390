import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { TableBody, TableBorder, TableHeader } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import useMounted from 'hooks/useMounted';
import type { InventorySummaryDetailVOModel } from 'models/vo/InventorySummaryDetailVO';
import {
  requestSystemInventoryAttachmentDownload,
  requestSystemInventorySummariesDetail,
} from 'utils/http/api/system/inventory-summaries';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const getConstant = () => {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const inventorySummaryId = (useParams() as any).id;

  const SECURED_INVENTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      colWidths: 50,
    },
    {
      headerText: t('text:Invoice_Number'),
    },
    {
      headerText: t('text:Item_Number'),
    },
    {
      headerText: t('text:Items'),
    },
    {
      headerText: t('text:Unit_Price'),
    },
    {
      headerText: t('text:Quantity'),
    },
    {
      headerText: t('text:Amount_(Unit_Price*Quantity)'),
    },
  ];

  return {
    t,
    mounted,
    modal,
    inventorySummaryId,
    SECURED_INVENTORY_TABLE_HEADERS,
  };
};

function SystemSecuredInventoryDetail() {
  const { t, mounted, modal, inventorySummaryId, SECURED_INVENTORY_TABLE_HEADERS } = getConstant();

  const [systemSecuredInventoryDetail, setSystemSecuredInventoryDetail] = useState<InventorySummaryDetailVOModel>();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const systemSecuredInventoryDetailData = await requestSystemInventorySummariesDetail(inventorySummaryId);

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemSecuredInventoryDetail(systemSecuredInventoryDetailData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickDownloadInventoryFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    try {
      requestSystemInventoryAttachmentDownload(inventorySummaryId);
    } catch (error) {
      modal.show(error);
    }
  };

  const renderSystemSecuredInventoryTableBody = () => {
    return systemSecuredInventoryDetail?.inventoryItems.map((item, index) => (
      <Tr key={index}>
        <Td className="text-center" data={index + 1} />
        <Td data={item.invoiceNumber} />
        <Td data={item.itemNumber} />
        <Td data={item.itemDescription} />
        <Td data={item.unitPrice} format="number" />
        <Td data={item.quantity} format="number" />
        <Td data={item.itemAmount} format="number" />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Secured_Inventory_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Secured_Creditor')} value={systemSecuredInventoryDetail?.financierName} />
              <FormValue
                label={t('text:Registration_Date')}
                format="datetime"
                value={systemSecuredInventoryDetail?.createdDateTime}
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Partner_Name')} value={systemSecuredInventoryDetail?.dealerClientName} />
              <FormValue label={t('text:Partner_Client_Code')} value={systemSecuredInventoryDetail?.dealerClientCode} />
            </div>
          </FormContents>
        </FormBorder>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Secured_Inventory_List')} />
        <TableBorder scrollYMaxHeight={140}>
          <TableHeader header={SECURED_INVENTORY_TABLE_HEADERS} backGroundType={BackGroundType.DarkGray} />
          <TableBody>{renderSystemSecuredInventoryTableBody()}</TableBody>
        </TableBorder>
        <div className="grid-total">
          {t('text:Total_Quantity')}:{' '}
          {t('format:number', {
            value: systemSecuredInventoryDetail?.inventoryItems.reduce((acc, cur) => {
              return acc + cur.quantity;
            }, 0),
          })}
          <span className="me-5" />
          {t('text:Total_Amount')} :{' '}
          {tableValueManage(
            systemSecuredInventoryDetail?.totalAmount,
            t('format:number', { value: systemSecuredInventoryDetail?.totalAmount }),
          )}
        </div>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Attached_Documents')} />
        <FormBorder>
          <div className="detail-in-file-download-form">
            <div className="d-flex justify-content-between">
              <label className="information-form__label">{t('text:Attached_Documents')}</label>
              <input
                type="text"
                placeholder={
                  systemSecuredInventoryDetail?.fileName
                    ? systemSecuredInventoryDetail?.fileName
                    : t('text:No_file_attached')
                }
                className="upload-file-input no-cursor bg-transparent"
                readOnly
              />
              <label className="information-form__label">{t('text:Note')}</label>
              <input
                type="text"
                placeholder={
                  systemSecuredInventoryDetail?.attachmentDescription
                    ? systemSecuredInventoryDetail?.attachmentDescription
                    : ''
                }
                className="upload-file-input"
                readOnly
              />
              <DownloadButton
                onClick={onClickDownloadInventoryFile}
                disabled={!systemSecuredInventoryDetail?.fileName}
              />
            </div>
          </div>
        </FormBorder>
      </div>
    </>
  );
}

export default SystemSecuredInventoryDetail;
