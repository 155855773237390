import type { AnchorPartnerVO, AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import { formattingToAnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

export async function requestAnAnchorPartnerDetail(
  anchorPartnerId: number,
  code: string,
): Promise<AnchorPartnerVOModel> {
  const response = await http.get<AnchorPartnerVO>({
    url: API_AN.ANCHOR_PARTNERS.ANCHOR_PARTNER_DETAIL(anchorPartnerId),
    data: { code },
  });

  return formattingToAnchorPartnerVOModel(response);
}
