import { useWaitingAgreementContext } from './Context';

const useWaitingAgreementViewModel = () => {
  const { waitingAgreement, waitingAgreementPageable, fetchWaitingAgreement } = useWaitingAgreementContext();

  return {
    waitingAgreement,
    waitingAgreementPageable,
    fetchWaitingAgreement,
  };
};

export default useWaitingAgreementViewModel;
