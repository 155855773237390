import type Pageable from 'models/Pageable';
import type { BankCodeVO, BankCodeVOModel } from 'models/vo/BankCodeVO';
import { formattingToBankCodeVOModel } from 'models/vo/BankCodeVO';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';

import type { CommonBankCodeListRequest } from './request';

export async function requestCmBankCodeList(
  pageNumber: number,
  rowCount: number,
  searchValue?: CommonBankCodeListRequest,
): Promise<Pageable<BankCodeVOModel[]>> {
  const response = await http.get<Pageable<BankCodeVO[]>>({
    url: API_CM.BANK_CODE.BANK_CODE_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToBankCodeVOModel(data)),
  };
}

export async function requestCmBankCodeDetail(bankCodeId: number): Promise<BankCodeVOModel> {
  const response = await http.get<BankCodeVO>({
    url: API_CM.BANK_CODE.BANK_CODE_DETAIL(bankCodeId),
  });

  return formattingToBankCodeVOModel(response);
}
