import type Pageable from 'models/Pageable';
import type { WaitingDealerAgreementVO, WaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import { formattingToWaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { SearchDeWaitingDealerAgreementDTO } from './requests';

// 약정 변경내역 조회
export async function requestWaitingDealerAgreementList(
  pageNumber: number,
  rowCount: number,
  data: SearchDeWaitingDealerAgreementDTO,
): Promise<Pageable<WaitingDealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<WaitingDealerAgreementVO[]>>({
    url: API_DE.WAITING_DEALER_AGREEMENTS.WAITING_DEALER_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToWaitingDealerAgreementVOModel(item)),
  };
}
