import './HeaderBar.scss';
import { HeaderBarContainer, HeaderBarList, HeaderBarListItem, HeaderBarTitle } from './HeaderBarComponents';
import LanguageSetter from './sections/LanguageSetter';

const PublicHeaderBar = () => {
  return (
    <HeaderBarContainer>
      <HeaderBarTitle />
      <HeaderBarList>
        <HeaderBarListItem>
          <LanguageSetter triggerClassName="public-header-bar__language-setter__trigger" />
        </HeaderBarListItem>
      </HeaderBarList>
    </HeaderBarContainer>
  );
};

export default PublicHeaderBar;
