import React from 'react';
import ReactDOM from 'react-dom';

import 'locales/i18n';
import AdminRootRouter from 'router/AdminRootRouter';
import RootRouter from 'router/RootRouter';
import { stringToBoolean } from 'utils/downCasting/DownCasting';
import ModalProvider from 'utils/modal/ModalProvider';
import { ThemeProvider } from 'utils/theme/ThemeProvider';

import { initMocks } from './mocks';
import '../newrelic/index';

import 'scss/common.scss';

if (process.env.REACT_APP_IS_MSW_USED && stringToBoolean(process.env.REACT_APP_IS_MSW_USED)) {
  initMocks();
}

const target = process.env.REACT_APP_BUILD_TARGET;

const buildTarget = () => {
  switch (target) {
    case 'index':
      return <RootRouter />;
    case 'admin':
      return <AdminRootRouter />;
    default:
      return <></>;
  }
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <ModalProvider>{buildTarget()}</ModalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('wrap'),
);
