import { CURRENCY_TYPE, MULTIPLE_LOAN_APPROVAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface MultipleLoanRequestVO {
  multipleLoanRequestId: number;
  dealerAgreementId: number;
  currencyType: string;
  financierId: number;
  financierName: string;
  dealerClientId: number;
  dealerClientName: string;
  anchorClientId: number;
  anchorClientName: string;
  totalArAmount: string;
  totalRequestAmount: string;
  averageInterestRate: number;
  totalArCount: number;
  multipleLoanRequestStatus: string;
  approvalType: string;
  factoringEnable: boolean;
  eSignatureEnable: boolean;
  dealerAgreementContractNo: string;
  anchorAgreementContractNo: string;
  dealerEnterpriseId: number;
  dealerName: string;
  anchorEnterpriseId: number;
  anchorName: string;
}

export interface MultipleLoanRequestVOModel extends MultipleLoanRequestVO {
  currencyType: CURRENCY_TYPE;
  multipleLoanRequestStatus: MULTIPLE_LOAN_REQUEST_STATUS;
  approvalType: MULTIPLE_LOAN_APPROVAL_TYPE;
  totalArAmount: BigNumber;
  totalRequestAmount: BigNumber;
}

export function formattingToMultipleLoanRequestVOModel(data: MultipleLoanRequestVO): MultipleLoanRequestVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    multipleLoanRequestStatus: stringToEnum(MULTIPLE_LOAN_REQUEST_STATUS, data.multipleLoanRequestStatus),
    approvalType: stringToEnum(MULTIPLE_LOAN_APPROVAL_TYPE, data.approvalType),
    totalArAmount: sliceZeroDecimal(data.totalArAmount),
    totalRequestAmount: sliceZeroDecimal(data.totalRequestAmount),
  };
}
