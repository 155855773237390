import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierUserList } from 'utils/http/api/financier/enterprises';
import type { FinancierEnterpriseUserListRequest } from 'utils/http/api/financier/enterprises/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const FI_USER_LIST_QS_KEY = 'fi-user-list';

function FinancierUserList() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(FI_USER_LIST_QS_KEY);
  const modal = useModal();

  const [financialUserPage, setFinancialUserPage] = useState<Pageable<UserVOModel[]>>(); // 응답 데이터 state
  const getProperty = useProperty<FinancierEnterpriseUserListRequest>();
  const signInModel: SignInModel | null = getSignIn();

  const { register, handleSubmit, reset, getValues, setValue } = useForm<FinancierEnterpriseUserListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierEnterpriseUserListRequest>(
        setValue,
        getParsedSearchParams(FI_USER_LIST_QS_KEY).formSearchData,
      );
      fetchFinancierUserList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  async function fetchFinancierUserList(
    pageNumber: number,
    sizePerPage: number,
    searchData: FinancierEnterpriseUserListRequest,
  ) {
    try {
      const financialUserPage = await requestFinancierUserList(pageNumber, sizePerPage, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount: sizePerPage,
        },
        FI_USER_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancialUserPage(financialUserPage);
        setPageable(financialUserPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchFinancierUserList(page, sizePerPage, getParsedSearchParams(FI_USER_LIST_QS_KEY).formSearchData);
  };

  const onSearchSubmit = async () => {
    await fetchFinancierUserList(0, pageable.sizePerPage, getValues());
  };

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Branch_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Branch_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:User_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:User_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 85,
    },
    {
      headerText: t('text:Email'),
      colWidths: 120,
    },
    {
      headerText: t('text:Authority'),
      colWidths: 90,
    },
    {
      headerText: t('text:User_Account_Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickUserStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderUserListTable = () => {
    return financialUserPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td data={item.employeeCode} />
          <Td data={item.userName} />
          <Td data={item.telephone} />
          <Td data={item.email} />
          <Td data={t(`code:authority-type.${item.authorityType}`)} />
          <Td
            className={getStatusTextClass('USER_STATUS', item?.userStatus)}
            data={t(`code:user-status.${item.userStatus}`)}
          />
          <TdLink path={ROUTES_FI.SETTINGS.USER_DETAIL_BUILD_PATH(item.userId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:User')} />
      {signInModel?.authorityType !== AUTHORITY_TYPE.OPERATOR && (
        <GuideMessage
          message={[
            t('text:Click_the_button_on_the_right_to_register_a_branch_user'),
            t('text:The_registered_user_information_can_be_found_in_the_list_below'),
          ]}
        >
          {{
            button: <Button to={ROUTES_FI.SETTINGS.USER_REGISTER}>{t('text:Register')}</Button>,
          }}
        </GuideMessage>
      )}
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset();
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Branch_Code')} />
              <SearchInput name={getProperty('branchCode')} ref={register} />
              <SearchLabel label={t('text:Branch_Name')} />
              <SearchInput name={getProperty('branchName')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:User_Code')} />
              <SearchInput name={getProperty('employeeCode')} ref={register} />
              <SearchLabel label={t('text:User_Name')} />
              <SearchInput name={getProperty('userName')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Email')} />
              <SearchInput name={getProperty('email')} ref={register} />
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput name={getProperty('telephone')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Authority')} />
              <SearchSelect
                name={getProperty('authorityType')}
                ref={register}
                selectOptions={getSelectOptions<AUTHORITY_TYPE>(
                  'AUTHORITY_TYPE',
                  [AUTHORITY_TYPE.ADMIN, AUTHORITY_TYPE.AUTHORIZER, AUTHORITY_TYPE.OPERATOR],
                  true,
                )}
              />
              <SearchLabel
                label={t('text:User_Account_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickUserStatus}
              />
              <SearchSelect
                name={getProperty('userStatus')}
                ref={register}
                selectOptions={getSelectOptions<USER_STATUS>('USER_STATUS', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onSearchSubmit)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {financialUserPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderUserListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierUserList;
