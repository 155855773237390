import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import { AUTHORITY_TYPE } from 'enums';
import { getDayTerm, getNextDay } from 'utils/date/date';
import type { FinancierExtensionRequest } from 'utils/http/api/financier/extension-requests/request';
import { getSignIn } from 'utils/storage/LocalStorage';

interface ExtensionConfirmModalPropsType {
  extensionStartDate: string | undefined;
  newRepaymentDate?: string;
  additionalRate?: number;
  getData?: (extendedRepaymentDate: string, additionalRate: number) => void;
}
function ExtensionConfirmModal({
  extensionStartDate,
  newRepaymentDate,
  additionalRate,
  getData,
}: ExtensionConfirmModalPropsType) {
  const { t } = useTranslation(['format']);
  const useFormConfirmExtension = useForm<FinancierExtensionRequest>();
  const CURRENT_USER_AUTHORITY: AUTHORITY_TYPE | undefined = getSignIn()?.authorityType;

  const { extendedRepaymentDate, additionalRate: watchedAdditionalRate } = useFormConfirmExtension.watch();

  if (extendedRepaymentDate || watchedAdditionalRate) {
    if (getData) {
      getData(
        useFormConfirmExtension.getValues('extendedRepaymentDate'),
        useFormConfirmExtension.getValues('additionalRate'),
      );
    }
  }

  const startDateOfExtension = getNextDay(extensionStartDate);

  const renderAuthorizerForm = () => {
    return (
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormValue
              col={12}
              label={t('text:Start_Date_of_Financing_Extension')}
              value={t('format:date', {
                value: extensionStartDate,
                key: 'date',
              })}
              className="information-form__input"
            />
          </div>
          <div className="row">
            <FormValue
              col={12}
              label={t('text:Scheduled_New_Repayment_Date')}
              value={t('format:date', {
                value: newRepaymentDate,
                key: 'date',
              })}
              className="information-form__input"
            />
          </div>
          <div className="row">
            <FormValue
              col={12}
              label={t('text:Financing_Extension_Period')}
              value={getDayTerm(extensionStartDate, newRepaymentDate, { includeStartDate: true })}
              className="information-form__input"
            />
          </div>
          <div className="row">
            <FormValue
              col={12}
              label={t('text:Additional_Interest_Rate')}
              value={`${t('format:number', { value: additionalRate })}%`}
              className="information-form__input"
            />
          </div>
        </FormContents>
      </FormBorder>
    );
  };
  const renderOperatorForm = () => {
    return (
      <FormBorder editable>
        <FormContents>
          <div className="row">
            <FormValue
              col={12}
              label={t('text:Start_Date_of_Financing_Extension')}
              value={t('format:date', {
                value: startDateOfExtension,
                key: 'date',
              })}
              className="information-form__input"
            />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="information-form__label">{t('text:Scheduled_New_Repayment_Date')}</div>
              <div className="position-parent">
                <Controller
                  control={useFormConfirmExtension.control}
                  name="extendedRepaymentDate"
                  defaultValue={null}
                  render={field => (
                    <ReactDatePicker
                      field={field}
                      className="information-form__input"
                      minDate={new Date(getNextDay(startDateOfExtension))}
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <FormValue
              col={12}
              label={t('text:Financing_Extension_Period')}
              value={getDayTerm(startDateOfExtension, useFormConfirmExtension.watch('extendedRepaymentDate'), {
                includeStartDate: true,
                withText: true,
              })}
              className="information-form__input"
            />
          </div>
          <div className="row">
            <div className="col-12">
              <label className="information-form__label">{t('text:Additional_Interest_Rate')}</label>
              <div className="d-flex">
                <NumericFormatInput
                  control={useFormConfirmExtension.control}
                  name="additionalRate"
                  className="information-form__input text-end"
                />
                <div className="ms-2 mt-1">%</div>
              </div>
            </div>
          </div>
        </FormContents>
      </FormBorder>
    );
  };

  return (
    <>
      {CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.AUTHORIZER && renderAuthorizerForm()}
      {CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.OPERATOR && renderOperatorForm()}
    </>
  );
}

export default ExtensionConfirmModal;
