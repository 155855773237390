import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, BANK_CODE_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { WaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import type { CommonBankCodeListRequest } from 'utils/http/api/common/bank-code/request';
import { requestFinancierBankCodeList } from 'utils/http/api/financier/bank-code';
import { requestFiWaitingBankCodeList } from 'utils/http/api/financier/waiting-bank-code';
import type { FiWaitingBankCodeListRequest } from 'utils/http/api/financier/waiting-bank-code/request';
import { getWaitingForApprovalCount } from 'utils/logic';
import {
  WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES,
  setBankCodeApprovalTypesValue,
} from 'utils/logic/setApprovalTypesValue';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const FI_BANK_CODE_WAITING_LIST_QS_KEY = 'fi-bank-code-waiting-list';
const FI_BANK_CODE_COMPLETED_LIST_QS_KEY = 'fi-bank-code-completed-list';

function FinancierBankCodeList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();

  const [bankCodeList, setBankCodeList] = useState<Pageable<BankCodeVOModel[]>>();
  const [waitingBankCodeList, setWaitingBankCodeList] = useState<Pageable<WaitingBankCodeVOModel[]>>();
  const [notCancelledWaitingBankCodeList, setNotCancelledWaitingBankCodeList] =
    useState<Pageable<WaitingBankCodeVOModel[]>>();

  const { pageable: completeBankCodePageable, setPageable: setCompleteBankCodePageable } = usePageable(
    FI_BANK_CODE_COMPLETED_LIST_QS_KEY,
  );
  const { pageable: waitingBankCodePageable, setPageable: setWaitingBankCodePageable } = usePageable(
    FI_BANK_CODE_WAITING_LIST_QS_KEY,
  );
  const signInModel: SignInModel | null = getSignIn();
  const isAdmin: boolean = signInModel?.authorityType === AUTHORITY_TYPE.ADMIN;

  // useForm
  const {
    register: completeBankCodeRegister,
    getValues: getCompleteBankCodeValues,
    setValue: setCompleteBankCodeValue,
    reset: completeBankCodeReset,
    handleSubmit: handleCompleteBankCodeSubmit,
  } = useForm<CommonBankCodeListRequest>();
  const {
    register: waitingBankCodeRegister,
    getValues: getWaitingBankCodeValues,
    setValue: setWaitingBankCodeValue,
    reset: waitingBankCodeReset,
    handleSubmit: handleWaitingBankCodeSubmit,
  } = useForm<FiWaitingBankCodeListRequest>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  const setWaitingTabSearchValue = (formSearchData?: FiWaitingBankCodeListRequest) => {
    const getSearchFormData = formSearchData ? formSearchData : getWaitingBankCodeValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setBankCodeApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
      deleted: false,
      requestEnterpriseId: signInModel?.enterpriseId,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
      deleted: false,
      requestEnterpriseId: signInModel?.enterpriseId,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchAll = async () => {
    setFormValues<CommonBankCodeListRequest>(
      setCompleteBankCodeValue,
      getParsedSearchParams(FI_BANK_CODE_COMPLETED_LIST_QS_KEY).formSearchData,
    );
    setFormValues<FiWaitingBankCodeListRequest>(
      setWaitingBankCodeValue,
      getParsedSearchParams(FI_BANK_CODE_WAITING_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    const completeBankCodeSearchData = getCompleteBankCodeValues();

    completeBankCodeSearchData.deleted = false;

    const addFiBankCodeListQs = () => {
      const completedQs = makeSearchParamsPattern(
        {
          ...completeBankCodeSearchData,
          pageNumber: completeBankCodePageable.currentPage,
          rowCount: completeBankCodePageable.sizePerPage,
        },
        FI_BANK_CODE_COMPLETED_LIST_QS_KEY,
      );
      const waitingQs = makeSearchParamsPattern(
        {
          ...waitingForApprovalSearchData,
          pageNumber: waitingBankCodePageable.currentPage,
          rowCount: waitingBankCodePageable.sizePerPage,
        },
        FI_BANK_CODE_WAITING_LIST_QS_KEY,
      );
      addSearchParams(waitingQs + completedQs);
    };
    addFiBankCodeListQs();

    try {
      const [fetchBankCodeResponse, fetchWaitingBankCodeResponse, fetchNotCancelledWaitingBankCodeResponse] =
        await Promise.all([
          requestFinancierBankCodeList(
            completeBankCodePageable.currentPage,
            completeBankCodePageable.sizePerPage,
            completeBankCodeSearchData,
          ),
          requestFiWaitingBankCodeList(
            waitingBankCodePageable.currentPage,
            waitingBankCodePageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestFiWaitingBankCodeList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setBankCodeList(fetchBankCodeResponse);
        setCompleteBankCodePageable(fetchBankCodeResponse);
        setWaitingBankCodeList(fetchWaitingBankCodeResponse);
        setWaitingBankCodePageable(fetchWaitingBankCodeResponse);
        setNotCancelledWaitingBankCodeList(fetchNotCancelledWaitingBankCodeResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchBankCodeList = async (pageNumber: number, rowCount: number, searchData: CommonBankCodeListRequest) => {
    const searchValue = {
      ...searchData,
      deleted: false,
    };

    try {
      const fetchBankCodeResponse = await requestFinancierBankCodeList(pageNumber, rowCount, searchValue);
      ReactDOM.unstable_batchedUpdates(() => {
        setBankCodeList(fetchBankCodeResponse);
        setCompleteBankCodePageable(fetchBankCodeResponse);
      });

      updateSearchParams(
        {
          ...searchValue,
          pageNumber,
          rowCount,
        },
        FI_BANK_CODE_COMPLETED_LIST_QS_KEY,
      );
    } catch (e) {
      modal.show(e);
    }
  };

  const paginateWaitingBankCodeList = async (pageNumber: number, rowCount: number) => {
    const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
      getParsedSearchParams(FI_BANK_CODE_WAITING_LIST_QS_KEY).formSearchData,
    );

    try {
      const fetchWaitingBankCodeResponse = await requestFiWaitingBankCodeList(
        pageNumber,
        rowCount,
        waitingForApprovalSearchData,
      );
      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber,
          rowCount,
        },
        FI_BANK_CODE_WAITING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingBankCodeList(fetchWaitingBankCodeResponse);
        setWaitingBankCodePageable(fetchWaitingBankCodeResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const renderWaitingBankCodeTabView = (): JSX.Element => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();
      waitingBankCodeReset({});
    };

    const onClickSearchWaitingTab = async () => {
      const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

      try {
        const [fetchWaitingBankCodeResponse, fetchNotCancelledWaitingBankCodeResponse] = await Promise.all([
          requestFiWaitingBankCodeList(0, waitingBankCodePageable.sizePerPage, waitingForApprovalSearchData),
          requestFiWaitingBankCodeList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: 1,
            rowCount: waitingBankCodePageable.sizePerPage,
          },
          FI_BANK_CODE_WAITING_LIST_QS_KEY,
        );
        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingBankCodeList(fetchWaitingBankCodeResponse);
          setWaitingBankCodePageable(fetchWaitingBankCodeResponse);
          setNotCancelledWaitingBankCodeList(fetchNotCancelledWaitingBankCodeResponse);
        });
      } catch (e) {
        modal.show(e);
      }
    };
    const renderTable = () => {
      const tableHeaders: HeaderType[] = [
        {
          headerText: t('text:Bank_Code'),
        },
        {
          headerText: t('text:Bank_Name'),
        },
        {
          headerText: t('text:Branch_Code'),
        },
        {
          headerText: t('text:Branch_Name'),
        },
        {
          headerText: t('text:Request_Status'),
        },
        {
          headerText: '',
          colWidths: 80,
        },
      ];

      const paginate = async (pageNumber: number, sizePerPage: number) => {
        await paginateWaitingBankCodeList(pageNumber, sizePerPage);
      };

      return (
        <div className="content-area">
          <SectionTitle title={t('text:Results')} />
          <div className="d-flex">
            <p className="total-data me-auto">
              {t('text:Total')}: {waitingBankCodeList?.totalElements}
            </p>
          </div>
          <TableBorder>
            <TableHeader header={tableHeaders} />
            <TableBody numOfCol={tableHeaders.length}>
              {waitingBankCodeList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.bankCode} />
                    <Td data={item.bankName} />
                    <Td data={item.branchCode} />
                    <Td data={item.branchName} />
                    <Td
                      className={getStatusTextClass('BANK_CODE_APPROVAL_TYPE', item.approvalType)}
                      data={t(`code:bank-code-approval-type.${item.approvalType}`)}
                    />
                    <TdLink
                      path={ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_WAITING_BUILD_PATH(item.id)}
                      state={{
                        targetBankCodeId: item.targetBankCodeId,
                      }}
                    />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingBankCodePageable} paginate={paginate} />
        </div>
      );
    };

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Bank_Code')} />
                <SearchInput name="bankCode" ref={waitingBankCodeRegister} />
                <SearchLabel label={t('text:Bank_Name')} />
                <SearchInput name="bankName" ref={waitingBankCodeRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Branch_Code')} />
                <SearchInput name="branchCode" ref={waitingBankCodeRegister} />
                <SearchLabel label={t('text:Branch_Name')} />
                <SearchInput name="branchName" ref={waitingBankCodeRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Request_Status')} />
                <SearchSelect
                  name="approvalTypes"
                  selectOptions={[
                    {
                      label: t('text:All'),
                      value: WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES,
                    },
                    {
                      label: t('text:Requested'),
                      value: BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST,
                    },
                    {
                      label: t('text:Cancelled'),
                      value: BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL,
                    },
                    {
                      label: t('text:Rejected'),
                      value: BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED,
                    },
                  ]}
                  ref={waitingBankCodeRegister}
                />
                <SearchEmpty />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={handleWaitingBankCodeSubmit(onClickSearchWaitingTab)}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        {renderTable()}
      </>
    );
  };

  const renderCompleteBankCodeTabView = (): JSX.Element => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();
      completeBankCodeReset({});
    };

    const onClickSearch = async () => {
      await fetchBankCodeList(1, completeBankCodePageable.sizePerPage, getCompleteBankCodeValues());
    };

    const renderTable = () => {
      const tableHeaders: HeaderType[] = [
        {
          headerText: t('text:Bank_Code'),
        },
        {
          headerText: t('text:Bank_Name'),
        },
        {
          headerText: t('text:Branch_Code'),
        },
        {
          headerText: t('text:Branch_Name'),
        },
        {
          headerText: t('text:Latest_Update'),
        },
        {
          headerText: '',
          colWidths: 80,
        },
      ];

      const paginate = async (pageNumber: number, sizePerPage: number) => {
        await fetchBankCodeList(
          pageNumber,
          sizePerPage,
          getParsedSearchParams(FI_BANK_CODE_COMPLETED_LIST_QS_KEY).formSearchData,
        );
      };

      return (
        <div className="content-area">
          <SectionTitle title={t('text:Results')} />
          <div className="d-flex">
            <p className="total-data me-auto">
              {t('text:Total')}: {bankCodeList?.totalElements}
            </p>
          </div>
          <TableBorder>
            <TableHeader header={tableHeaders} />
            <TableBody numOfCol={tableHeaders.length}>
              {bankCodeList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.bankCode} />
                    <Td data={item.bankName} />
                    <Td data={item.branchCode} />
                    <Td data={item.branchName} />
                    <Td data={item.updatedDateTime} format="datetime" />
                    <TdLink path={ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_CONFIRMED_BUILD_PATH(item.id)} />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={completeBankCodePageable} paginate={paginate} />
        </div>
      );
    };

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Bank_Code')} />
                <SearchInput name="bankCode" ref={completeBankCodeRegister} />
                <SearchLabel label={t('text:Bank_Name')} />
                <SearchInput name="bankName" ref={completeBankCodeRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Branch_Code')} />
                <SearchInput name="branchCode" ref={completeBankCodeRegister} />
                <SearchLabel label={t('text:Branch_Name')} />
                <SearchInput name="branchName" ref={completeBankCodeRegister} />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={handleCompleteBankCodeSubmit(onClickSearch)}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        {renderTable()}
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Bank_Code_Management')} />
      <GuideMessage
        message={
          isAdmin
            ? [
                t('text:The_registered_Bank_Code_information_can_be_found_on_the_“Confirmed”_tab'),
                t('text:Click_the_button_on_the_right_to_request_registration_of_a_new_bank_code'),
                t(
                  'text:Click_on_the_arrow_button_to_view_the_details_of_the_registered_bank_code_and_to_request_revision_of_the_information',
                ),
              ]
            : [
                t('text:The_registered_Bank_Code_information_can_be_found_on_the_“Confirmed”_tab'),
                t(
                  'text:Click_on_the_arrow_button_to_view_the_details_of_the_registered_bank_code_and_to_request_revision_of_the_information',
                ),
              ]
        }
      >
        {{
          button: isAdmin ? (
            <Button
              onClick={() => {
                history.push(ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_REGISTER);
              }}
            >
              {t('text:Register')}
            </Button>
          ) : undefined,
        }}
      </GuideMessage>
      <Tab
        tabType="approvalStatus"
        initialActiveTabIndex={1}
        tabList={[
          {
            tabName: t('text:Waiting_for_Confirmation'),
            tabItemCount: getWaitingForApprovalCount(
              getWaitingBankCodeValues('approvalTypes'),
              notCancelledWaitingBankCodeList?.totalElements,
            ),
          },
          {
            tabName: t('text:Confirmed'),
            tabItemCount: bankCodeList?.totalElements ?? 0,
          },
        ]}
        tabViewList={[renderWaitingBankCodeTabView(), renderCompleteBankCodeTabView()]}
      />
    </>
  );
}

export default FinancierBankCodeList;
