import { useTranslation } from 'react-i18next';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './PasswordValidationBox.scss';

interface PasswordValidationBoxProps {
  newPassword: string;
}

function PasswordValidationBox({ newPassword }: PasswordValidationBoxProps) {
  const { t } = useTranslation();
  const sameOrOverEightPassword = (password: string) => {
    return password?.length >= 8;
  };

  const hasUpperAndLowerCasePassword = (password: string) => {
    return password !== password?.toUpperCase() && password !== password?.toLowerCase();
  };

  const hasNumberPassword = (password: string) => {
    return password?.replace(/[^\d]+/g, '').length > 0;
  };

  return (
    <div className="password-validation-box">
      <p className="mb-2">{t('text:Your_password_must_have')}</p>
      <ul>
        <li className={sameOrOverEightPassword(newPassword) ? 'password-validation-box__passed' : ''}>
          <span className="me-2">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          {t('text:8_or_more_characters')}
        </li>
        <li className={hasUpperAndLowerCasePassword(newPassword) ? 'password-validation-box__passed' : ''}>
          <span className="me-2">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          {t('text:Upper_&_lower_case_letters')}
        </li>
        <li className={hasNumberPassword(newPassword) ? 'password-validation-box__passed' : ''}>
          <span className="me-2">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          {t('text:At_least_one_number')}
        </li>
      </ul>
    </div>
  );
}

export default PasswordValidationBox;
