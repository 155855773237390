import { ANCHOR_DEALER_STATUS, COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE, stringToEnum } from 'enums';

export interface WaitingAnchorDealerVO {
  id: number;
  name: string;
  taxCode: string;
  businessCode: string;
  telephone: string;
  address: string;
  fax: string;
  representativeName: string;
  representativeEmail: string;
  representativePosition: string;
  declined: boolean;
  anchorDealerStatus: string;
  approvalType: string;
  financierId: number;
  financierName: string;
  anchorId: number;
  anchorName: string;
  anchorClientId: number;
  operatorUserId: number;
  operatorUserLoginId: string;
  operatorUserName: string;
  operatorEnterpriseId: number;
  operatorEnterpriseType: string;
  operatorEnterpriseName: string;
  authorizerUserLoginId: string;
  authorizerUserName: string;
  authorizerEnterpriseId: number;
  authorizerEnterpriseType: string;
  authorizerEnterpriseName: string;
  targetAnchorDealerId: number;
  createdDateTime: string;
  updatedDateTime: string;
  rejectReason: string;
  assignedBranchId: number;
  assignedBranchName: string;
  assignedBranchCode: string;
  assignedBranchAddress: string;
}

export interface WaitingAnchorDealerVOModel extends WaitingAnchorDealerVO {
  approvalType: COMMON_APPROVAL_TYPE;
  anchorDealerStatus: ANCHOR_DEALER_STATUS;
  operatorEnterpriseType: ENTERPRISE_TYPE;
  authorizerEnterpriseType: ENTERPRISE_TYPE;
}

export function formattingToWaitingAnchorDealerVOModel(data: WaitingAnchorDealerVO): WaitingAnchorDealerVOModel {
  return {
    ...data,
    approvalType: stringToEnum(COMMON_APPROVAL_TYPE, data.approvalType),
    anchorDealerStatus: stringToEnum(ANCHOR_DEALER_STATUS, data.anchorDealerStatus),
    operatorEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.operatorEnterpriseType),
    authorizerEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.authorizerEnterpriseType),
  };
}
