import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { ArCommissionSettlementDetailVOModel } from 'models/vo/ArCommissionSettlementDetailVO';
import useModal from 'utils/modal/useModal';

type FinancierSettlementPaymentSummaryProps = {
  financierArSettlementDetail?: ArCommissionSettlementDetailVOModel;
};

const FinancierSettlementPaymentSummary = ({ financierArSettlementDetail }: FinancierSettlementPaymentSummaryProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  // 만기일자 경과 시 표시
  const overDaysToMaturity = (daysToMaturity: number | undefined) => {
    if (isNil(daysToMaturity) || daysToMaturity <= 0) {
      return '-';
    } else {
      return `D - ${daysToMaturity}`;
    }
  };

  const handleClickPendingDescription = () => {
    modal.show(
      <h6>
        {t('text:The_Financing_that_the_financier_has_not_yet_withdrawn_in_the_financing_list')}
        <br />
        <ul className="mt-3 ms-3">
          <li>
            • {t('text:Financing_Status')} : {t('text:Disbursed')}
          </li>
        </ul>
      </h6>,
      {
        title: t('text:Pending'),
      },
    );
  };

  const handleClickCompletedDescription = () => {
    modal.show(
      <h6>
        {t('text:The_Financing_that_the_financier_has_already_withdrawn_in_the_financing_list')}
        <br />
        <ul className="mt-3 ms-3">
          <li>
            • {t('text:Financing_Status')} : {t('text:Repaid')}
          </li>
        </ul>
      </h6>,
      {
        title: t('text:Completed'),
      },
    );
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Summary')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              label={t('text:Days_Until_Settlement')}
              col={3}
              className="information-form__input border-none pointfont"
              value={overDaysToMaturity(financierArSettlementDetail?.daysToMaturity)}
            />
            <FormValue
              label={t('text:Settlement_Date')}
              col={3}
              value={financierArSettlementDetail?.settlementDate}
              format="date"
            />
            <FormValue
              label={t('text:Total_Amount_of_AR')}
              col={3}
              value={financierArSettlementDetail?.totalArAmount}
              format="number"
            />
            <FormValue
              label={t('text:Total_Number_of_AR')}
              col={3}
              value={financierArSettlementDetail?.totalArCount}
              format="number"
            />
          </div>
        </FormContents>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Anchor_Name')} col={3} value={financierArSettlementDetail?.anchorName} />
            <FormValue label={t('text:Partner_Name')} col={3} value={financierArSettlementDetail?.partnerName} />
            <FormValue label={t('text:Currency')} col={3} value={financierArSettlementDetail?.currencyType} />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:Financing_Settlement')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              label={t('text:Total_Financing_Amount')}
              value={financierArSettlementDetail?.disbursableStatusAmount}
              format="number"
              className="information-form__input border-none pointfont"
            />
            <FormValue
              label={t('text:Remaining_Financing_Amount')}
              col={3}
              value={financierArSettlementDetail?.pendingStatusDisbursementAmount}
              format="number"
              className="information-form__input border-none pointfont"
            />
            <FormValue
              label={t('text:Withdrawn_Financing_Amount')}
              col={3}
              value={financierArSettlementDetail?.completedStatusDisbursementAmount}
              format="number"
              className="information-form__input border-none pointfont text-bold-dark-green"
            />
          </div>
        </FormContents>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              label={t('text:Total_Number_of_Financing')}
              value={financierArSettlementDetail?.disbursableStatusCount}
              format="number"
              className="information-form__input border-none pointfont"
            />
            <FormValue
              label={t('text:Pending')}
              col={3}
              value={financierArSettlementDetail?.pendingStatusDisbursementCount}
              format="number"
              className="information-form__input border-none pointfont"
              hasStatusDescription={true}
              showStatusDescriptionFunc={handleClickPendingDescription}
            />
            <FormValue
              label={t('text:Completed')}
              col={3}
              value={financierArSettlementDetail?.completedStatusDisbursementCount}
              format="number"
              className="information-form__input border-none pointfont text-bold-dark-green"
              hasStatusDescription={true}
              showStatusDescriptionFunc={handleClickCompletedDescription}
            />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:Transaction_Fee_Settlement')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              label={t('text:Total_Transaction_Fee')}
              value={financierArSettlementDetail?.totalArCommissionAmount}
              format="number"
              className="information-form__input border-none pointfont"
            />
            <FormValue
              label={t('text:Remaining_Transaction_Fee')}
              col={3}
              value={financierArSettlementDetail?.arCommissionCreatedAmount}
              format="number"
              className="information-form__input border-none pointfont"
            />
            <FormValue
              label={t('text:Withdrawn_Transaction_Fee')}
              col={3}
              value={financierArSettlementDetail?.arCommissionPaidAmount}
              format="number"
              className="information-form__input border-none pointfont text-bold-dark-green"
            />
          </div>
        </FormContents>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              label={t('text:Total_Number_of_Charged_AR')}
              value={financierArSettlementDetail?.totalArCommissionCount}
              format="number"
              className="information-form__input border-none pointfont"
            />
            <FormValue
              label={t('text:Pending')}
              col={3}
              value={financierArSettlementDetail?.arCommissionCreatedCount}
              format="number"
              className="information-form__input border-none pointfont"
            />
            <FormValue
              label={t('text:Completed')}
              col={3}
              value={financierArSettlementDetail?.arCommissionPaidCount}
              format="number"
              className="information-form__input border-none pointfont text-bold-dark-green"
            />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
};

export default FinancierSettlementPaymentSummary;
