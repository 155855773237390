import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import type { CURRENCY_TYPE } from 'enums';
import useProperty from 'hooks/useProperty';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

export interface UpdateArSettlementProps {
  props: {
    arNumber: string;
    arAmount: number;
    currencyType: keyof typeof CURRENCY_TYPE;
    settlementDate: string;
    settledDate: string;
  };
  modalId: number;
}

function UpdateArSettlementModal({ props, modalId }: UpdateArSettlementProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { arNumber, arAmount, currencyType, settlementDate } = props;
  const getProperty = useProperty<{ settledDate: string }>();

  const { watch, errors, control } = useForm<{ settledDate: string }>({
    mode: 'onSubmit',
  });

  const { settledDate } = watch();

  useEffect(() => {
    modal.disableConfirmBtn(modalId, isEmpty(settledDate));
    Object.assign(props, { settledDate });
  }, [settledDate]);

  return (
    <>
      <div className="mb-2">{t('text:Would_you_like_to_update_the_settlement_information_of_the_AR?')}</div>
      <div className="mb-3">
        {t(
          'text:Select_the_date_the_settlement_was_completed_and_check_the_AR_information_before_clicking_on_the_“Confirm”_button',
        )}
      </div>
      <FormBorder editable={true}>
        <FormContents>
          <div className="row">
            <FormDatePickerInput
              col={12}
              label={t('text:Settlement_Completion_Date')}
              name={getProperty('settledDate')}
              control={control}
              error={errors.settledDate}
            />
          </div>
          <div className="row">
            <FormValue col={12} label={t('text:AR_Number')} value={arNumber} />
          </div>
          <div className="row">
            <FormValue
              col={12}
              label={t('text:AR_Amount')}
              value={`${tableValueManage(arAmount, t('format:number', { value: arAmount }))} ${currencyType}`}
            />
          </div>
          <div className="row">
            <FormValue col={12} label={t('text:Scheduled_Settlement_Date')} value={settlementDate} format="date" />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default UpdateArSettlementModal;
