import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import useFinancierAnchorDealerRegistrationWaitingDetailPageState from './useFinancierAnchorDealerRegistrationWaitingDetailPageState';
import RelatedAnchorAgreementList from '../../components/RelatedAnchorAgreementList';

function FinancierAnchorDealerRegistrationWaitingDetailPage() {
  const { t } = useTranslation();
  const userAuthority = getSignIn()?.authorityType;
  const mounted = useMounted();
  const modal = useModal();

  const isApproveAuthority = userAuthority === AUTHORITY_TYPE.AUTHORIZER;
  const isCancelAuthority = userAuthority === AUTHORITY_TYPE.OPERATOR;

  const {
    fetchAll,
    paginate,
    agreementPageable,
    relatedAnchorAgreement,
    waitingAnchorDealerInfo,
    cancelRequest,
    approveRequest,
    revertRequest,
  } = useFinancierAnchorDealerRegistrationWaitingDetailPageState();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const handleClickCancel = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => cancelRequest(),
      },
    );
  };

  const handelClickRevert = () => {
    let reason = '';
    modal.show(
      <>
        <h6>
          {t('text:Would_you_like_to_revert_the_registration_request?')}
          <br />
          {t('text:Please_type_below_the_reason_for_the_revert')}
          <br />
          {t('text:Click_on_Confirm_to_revert_the_request')}
        </h6>
        <ReasonModal
          modalId={modal.id}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => revertRequest(reason),
      },
    );
  };

  const handleClickConfirm = () => {
    modal.show(<h6>{t('text:Would_you_like_to_confirm_the_Uploaded_Dealer_modification_request?')}</h6>, {
      modalSize: ModalSize.NONE,
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Close'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => approveRequest(),
    });
  };

  const StatusBadgeComponent = () => {
    const getStatusBadgeUserInfo = (): JSX.Element => {
      switch (waitingAnchorDealerInfo?.approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return (
            <>
              <span className="supply-chain-partner-requester-info-title">
                {t('text:Admin_Operator')} ({waitingAnchorDealerInfo.operatorUserName} /{' '}
                {waitingAnchorDealerInfo?.operatorUserLoginId})
              </span>
              <span>{waitingAnchorDealerInfo?.operatorEnterpriseName}</span>
            </>
          );
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return (
            <>
              <span className="supply-chain-partner-requester-info-title">
                {t('text:Admin')} ({waitingAnchorDealerInfo.authorizerUserName} /{' '}
                {waitingAnchorDealerInfo?.authorizerUserLoginId})
              </span>
              <span>{waitingAnchorDealerInfo?.authorizerEnterpriseName}</span>
            </>
          );
        default:
          return <></>;
      }
    };

    const getStatusTimeText = (): string => {
      switch (waitingAnchorDealerInfo?.approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested_Date');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Reverted_Date');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Cancelled_Date');
        default:
          return '';
      }
    };

    return (
      <div className="content-area">
        <div className="supply-chain-partner-status-block">
          <span
            className={`me-4 ${getStatusBadgeClass('COMMON_APPROVAL_TYPE', waitingAnchorDealerInfo?.approvalType)}`}
          >
            {t(`code:common-approval-type.${waitingAnchorDealerInfo?.approvalType}`)}
          </span>
          <span className="supply-chain-partner-requester-info">{getStatusBadgeUserInfo()}</span>
          <span className="supply-chain-partner-requester-info supply-chain-partner-requester-info-time">
            <span className="supply-chain-partner-requester-info-title">{getStatusTimeText()}</span>
            <span>{t('format:datetime', { value: waitingAnchorDealerInfo?.updatedDateTime, key: 'datetime' })}</span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={waitingAnchorDealerInfo?.name} />
      {isApproveAuthority && waitingAnchorDealerInfo?.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST && (
        <GuideMessage
          message={[
            t('text:The_registration_request_for_the_Uploaded_Dealer_below_has_been_submitted'),
            t('text:Click_on_the_Confirm_button_to_approve_the_registration_request'),
            t(
              'text:If_the_company_s_tax_code_is_incorrect_click_on_the_Revert_button_and_return_the_registration_request',
            ),
          ]}
        />
      )}
      {isCancelAuthority && waitingAnchorDealerInfo?.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST && (
        <GuideMessage
          message={[
            t('text:The_Uploaded_Dealer_Registration_has_been_requested'),
            t('text:Registration_will_be_completed_when_the_Authorizer_approves_the_request'),
          ]}
        />
      )}
      {waitingAnchorDealerInfo?.approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED && (
        <GuideMessage
          message={[t('text:The_Dealer_registration_request_has_been_reverted')]}
          messageType={MessageType.ALERT}
          reasonTitle={t('text:REASON_FOR_REVERT')}
          reason={waitingAnchorDealerInfo?.rejectReason}
        />
      )}
      <StatusBadgeComponent />
      <div className="content-area">
        <SectionTitle title={t(`text:Uploaded_Dealer_Information`)} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t(`text:Uploaded_Dealer_Name`)} value={waitingAnchorDealerInfo?.name} />
              <FormValue label={t(`text:Tax_Code`)} value={waitingAnchorDealerInfo?.taxCode} />
            </div>
            <div className="row">
              <FormValue label={t(`text:Company_Registration_Number`)} value={waitingAnchorDealerInfo?.businessCode} />
              <FormValue col={3} label={t(`text:Telephone`)} value={waitingAnchorDealerInfo?.telephone} />
              <FormValue col={3} label={t(`text:Fax`)} value={waitingAnchorDealerInfo?.fax} />
            </div>
            <div className="row">
              <FormValue
                label={t(`text:Legal_Representative_Name`)}
                value={waitingAnchorDealerInfo?.representativeName}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Title`)}
                value={waitingAnchorDealerInfo?.representativePosition}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Email`)}
                value={waitingAnchorDealerInfo?.representativeEmail}
              />
            </div>
            <div className="row">
              <FormValue
                col={12}
                label={t(`text:Registered_Office_Address`)}
                value={waitingAnchorDealerInfo?.address}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <RelatedAnchorAgreementList
        relatedAnchorAgreement={relatedAnchorAgreement}
        pageable={agreementPageable}
        paginate={paginate}
      />
      {isCancelAuthority && waitingAnchorDealerInfo?.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST && (
        <div className="content-area flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={handleClickCancel}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
          >
            {t('text:Cancel_Request')}
          </Button>
        </div>
      )}
      {isApproveAuthority && waitingAnchorDealerInfo?.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST && (
        <div className="content-area flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={handelClickRevert}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.RED}
            className="me-2"
          >
            {t('text:Revert')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={handleClickConfirm}>
            {t('text:Confirm')}
          </Button>
        </div>
      )}
    </>
  );
}

export default FinancierAnchorDealerRegistrationWaitingDetailPage;
