import {
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  CURRENCY_TYPE,
  ENTERPRISE_TYPE,
  PARTNER_STATUS,
  stringToEnum,
} from 'enums';

export interface WaitingAnchorPartnerAccountDetailVO {
  waitingAnchorPartnerAccountId: number;
  account: string;
  accountOwner: string;
  requestedAccountBankName: string;
  requestedAccountBranchName: string;
  partnerBusinessCode: string;
  partnerTaxCode: string;
  partnerName: string;
  address: string;
  telephone: string;
  fax: string;
  representativeName: string;
  representativeEmail: string;
  representativePosition: string;
  anchorPartnerStatus: string;
  accountBankCodeId: number;
  accountBankCode: string;
  accountBranchCode: string;
  accountBankName: string;
  accountBranchName: string;
  anchorAgreementId: number;
  anchorName: string;
  anchorAgreementContractNo: string;
  collateralType: string;
  currencyType: string;
  responsibleFinancierId: number;
  responsibleFinancierName: string;
  responsibleBranchId: number;
  responsibleBranchName: string;
  responsibleBranchCode: string;
  paymentSupport: boolean;
  operatorUserId: number;
  operatorUserName: string;
  operatorUserLoginId: string;
  operatorUserAuthority: string;
  operatorEnterpriseId: number;
  operatorEnterpriseType: string;
  operatorEnterpriseName: string;
  authorizerUserId: number;
  authorizerUserName: string;
  authorizerUserLoginId: string;
  authorizerUserAuthority: string;
  authorizerEnterpriseId: number;
  authorizerEnterpriseType: string;
  authorizerEnterpriseName: string;
  approvalType: string;
  returnReason: string;
  targetAnchorPartnerAccountId: number;
  assignedBranchCode: string;
  assignedBranchName: string;
  createdDateTime: string;
  updatedDateTime: string;
}

export interface WaitingAnchorPartnerAccountDetailVOModel extends WaitingAnchorPartnerAccountDetailVO {
  anchorPartnerStatus: PARTNER_STATUS;
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
  operatorUserAuthority: AUTHORITY_TYPE;
  operatorEnterpriseType: ENTERPRISE_TYPE;
  authorizerUserAuthority: AUTHORITY_TYPE;
  authorizerEnterpriseType: ENTERPRISE_TYPE;
  approvalType: COMMON_APPROVAL_TYPE;
}

export function formattingToWaitingAnchorPartnerAccountDetailVOModel(
  data: WaitingAnchorPartnerAccountDetailVO,
): WaitingAnchorPartnerAccountDetailVOModel {
  return {
    ...data,
    anchorPartnerStatus: stringToEnum(PARTNER_STATUS, data.anchorPartnerStatus),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    operatorUserAuthority: stringToEnum(AUTHORITY_TYPE, data.operatorUserAuthority),
    operatorEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.operatorEnterpriseType),
    authorizerUserAuthority: stringToEnum(AUTHORITY_TYPE, data.authorizerUserAuthority),
    authorizerEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.authorizerEnterpriseType),
    approvalType: stringToEnum(COMMON_APPROVAL_TYPE, data.approvalType),
  };
}
