import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';

interface FinancierSCPartnerAcquisitionDetailAnchorAgreementProps {
  anchorAgreementInfo: AnchorAgreementVOModel;
}

function FinancierSCPartnerAcquisitionDetailAnchorAgreement({
  anchorAgreementInfo,
}: FinancierSCPartnerAcquisitionDetailAnchorAgreementProps) {
  const { t } = useTranslation(['format']);

  const renderAcAgreements = () => {
    const acAgreementListTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Anchor_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Anchor_Master_Agreement_Number'),
        colWidths: 200,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 120,
      },
      {
        headerText: t('text:Responsible_Branch_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
        colWidths: 180,
      },
      {
        headerText: t('text:Agreement_Suspension'),
        colWidths: 150,
      },
      {
        headerText: '',
        colWidths: 70,
      },
    ];

    return (
      <TableBorder>
        <TableHeader header={acAgreementListTableHeaders} />
        <TableBody numOfCol={acAgreementListTableHeaders.length}>
          <Tr>
            <Td data={anchorAgreementInfo.financierClientName} />
            <Td data={anchorAgreementInfo.contractNo} />
            <Td data={anchorAgreementInfo.currencyType} />
            <Td data={anchorAgreementInfo.branchCode} />
            <Td data={anchorAgreementInfo.branchName} />
            <Td data={anchorAgreementInfo.anchorAgreementStatus ? t('text:Not_Suspended') : t('text:Suspended')} />
            <TdLink
              path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(
                anchorAgreementInfo.anchorAgreementId,
              )}
              state={{ supportedCollateralType: anchorAgreementInfo.collateralType }}
            />
          </Tr>
        </TableBody>
      </TableBorder>
    );
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Associated_Anchor_Master_Agreement_List')} />
      {renderAcAgreements()}
    </div>
  );
}

export default FinancierSCPartnerAcquisitionDetailAnchorAgreement;
