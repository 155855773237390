import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

import Badge from 'components/stateless/Badge/Badge';
import BasicTableBody from 'components/stateless/BasicTable/BasicTableBody';
import BasicTableBorder from 'components/stateless/BasicTable/BasicTableBorder';
import BasicTableHeader from 'components/stateless/BasicTable/BasicTableHeader';
import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import Card from 'components/stateless/Card/Card';
import CardBody from 'components/stateless/Card/CardBody';
import CardGroup from 'components/stateless/Card/CardGroup';
import CardHeader from 'components/stateless/Card/CardHeader';
import CustomSelect from 'components/stateless/CustomSelect/CustomSelect';
import Tab from 'components/stateless/TabManager/Tab';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import {
  APPROVAL_EVENT_TYPE,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  CURRENCY_TYPE,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  MULTIPLE_LOAN_REQUEST_STATUS,
  SCHEDULE_EVENT_TYPE,
  SUPPORTED_COLLATERAL_TYPE,
} from 'enums';
import useMounted from 'hooks/useMounted';
import useNonInitialEffect from 'hooks/useNonInitialEffect';
import type Pageable from 'models/Pageable';
import type { DashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import type { DashBoardScheduleVOModel } from 'models/vo/DashBoardScheduleVO';
import type { DashboardPartnerPerformanceVOModel } from 'models/vo/DashboardPartnerPerformanceVO';
import type { LoanVOModel } from 'models/vo/LoanVO';
import {
  requestPartnerInternalApproval,
  requestPartnerPerformance,
  requestPartnerTaskSchedule,
} from 'utils/http/api/dealer/dashboard';
import { requestDealerLoanList } from 'utils/http/api/dealer/loans';
import { getCurrencyByLocale } from 'utils/localeCurrencyType';
import useModal from 'utils/modal/useModal';
import { getCollateralType, getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import './PartnerDashboard.scss';

const getConstant = () => {
  const { t } = useTranslation(['format']);

  const CURRENCY_OPTIONS = [
    {
      label: t('text:USD'),
      value: CURRENCY_TYPE.USD,
    },
    {
      label: t('text:IDR'),
      value: CURRENCY_TYPE.IDR,
    },
    {
      label: t('text:INR'),
      value: CURRENCY_TYPE.INR,
    },
    {
      label: t('text:JPY'),
      value: CURRENCY_TYPE.JPY,
    },
    {
      label: t('text:KRW'),
      value: CURRENCY_TYPE.KRW,
    },
    {
      label: t('text:VND'),
      value: CURRENCY_TYPE.VND,
    },
  ];

  const SCHEDULE_EVENT_OPTIONS = [
    {
      eventName: t('text:All'),
      scheduleEventTypes: [SCHEDULE_EVENT_TYPE.AP_SETTLEMENT, SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT],
    },
    {
      eventName: t('text:Settlement'),
      scheduleEventTypes: [SCHEDULE_EVENT_TYPE.AP_SETTLEMENT],
    },
    {
      eventName: t('text:Repayment'),
      scheduleEventTypes: [SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT],
    },
  ];

  const DELINQUENT_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Repayment_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Days_Overdue'),
      colWidths: 60,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 100,
      className: 'text-end',
    },
    {
      headerText: t('text:Repaid_Principal_Amount'),
      colWidths: 100,
      className: 'text-end',
    },
  ];

  const TASK_SCHEDULER_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Event'),
      colWidths: 200,
    },
  ];

  const INTERNAL_APPROVAL_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Request'),
      colWidths: 200,
    },
    {
      headerText: t('text:Status'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_by'),
      colWidths: 100,
    },
  ];

  return {
    CURRENCY_OPTIONS,
    SCHEDULE_EVENT_OPTIONS,
    TASK_SCHEDULER_HEADERS,
    DELINQUENT_HEADERS,
    INTERNAL_APPROVAL_HEADERS,
  };
};

type FinancingOverViewData = Omit<
  DashboardPartnerPerformanceVOModel,
  'collateralType' | 'currencyType' | 'loanableAmount'
>;

function PartnerDashboard() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();

  const collateralType = getCollateralType();
  const name = getSignIn()?.name;

  const {
    CURRENCY_OPTIONS,
    TASK_SCHEDULER_HEADERS,
    SCHEDULE_EVENT_OPTIONS,
    DELINQUENT_HEADERS,
    INTERNAL_APPROVAL_HEADERS,
  } = getConstant();

  const getInitialRequestCollateralType = (): COLLATERAL_TYPE => {
    if (collateralType === SUPPORTED_COLLATERAL_TYPE.BOTH || collateralType === SUPPORTED_COLLATERAL_TYPE.AR) {
      return COLLATERAL_TYPE.AR;
    } else if (collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE) {
      return COLLATERAL_TYPE.INVOICE;
    } else {
      return COLLATERAL_TYPE.AR;
    }
  };

  const [requestCollateralType, setRequestCollateralType] = useState<COLLATERAL_TYPE>(
    getInitialRequestCollateralType(),
  );
  const [selectedCurrencyValue, setSelectedCurrencyValue] = useState<{ label: string; value: CURRENCY_TYPE }>({
    label: getCurrencyByLocale(),
    value: getCurrencyByLocale(),
  });
  const selectedCurrencyOptions: { label: string; value: CURRENCY_TYPE }[] = CURRENCY_OPTIONS;

  const initialScheduleEventValue = {
    eventName: t('text:All'),
    scheduleEventTypes: [SCHEDULE_EVENT_TYPE.AP_SETTLEMENT, SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT],
  };

  const [selectedScheduleEventValue, setSelectedScheduleEventValue] = useState<{
    eventName: string;
    scheduleEventTypes: SCHEDULE_EVENT_TYPE[];
  }>(initialScheduleEventValue);

  const selectedScheduleEventOptions: {
    eventName: string;
    scheduleEventTypes: SCHEDULE_EVENT_TYPE[];
  }[] = SCHEDULE_EVENT_OPTIONS;

  const [loanList, setLoanList] = useState<Pageable<LoanVOModel[]>>();
  const [internalApprovalList, setInternalApprovalList] = useState<Pageable<DashBoardApprovalVOModel[]>>();
  const [taskScheduleList, setTaskScheduleList] = useState<Pageable<DashBoardScheduleVOModel[]>>();
  const [financingOverView, setFinancingOverView] = useState<DashboardPartnerPerformanceVOModel>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useNonInitialEffect(() => {
    fetchAndSetTaskSchedule();
  }, [selectedScheduleEventValue]);

  useNonInitialEffect(() => {
    fetchAndSetPerformance();
  }, [requestCollateralType, selectedCurrencyValue]);

  const [today] = dayjs().format().split('T');
  const a30DaysLater = dayjs(today).add(30, 'd').format('YYYY-MM-DD');

  async function fetchAll() {
    try {
      const [financingOverView, taskScheduleList, loanList, internalApprovalList] = await Promise.all([
        requestPartnerPerformance({ collateralType: requestCollateralType, currencyType: selectedCurrencyValue.value }),
        requestPartnerTaskSchedule(1, 20, {
          scheduleEventTypes: selectedScheduleEventValue.scheduleEventTypes,
          toDate: a30DaysLater,
        }),
        requestDealerLoanList(1, 20, { loanStatusConditions: [LOAN_STATUS.OVERDUE] }),
        requestPartnerInternalApproval(1, 20),
      ]);

      setFinancingOverView(financingOverView);
      setTaskScheduleList(taskScheduleList);
      setLoanList(loanList);
      setInternalApprovalList(internalApprovalList);
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchAndSetTaskSchedule() {
    try {
      const taskScheduleList = await requestPartnerTaskSchedule(1, 20, {
        scheduleEventTypes: selectedScheduleEventValue.scheduleEventTypes,
        toDate: a30DaysLater,
      });

      setTaskScheduleList(taskScheduleList);
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchAndSetPerformance() {
    try {
      const financingOverView = await requestPartnerPerformance({
        collateralType: requestCollateralType,
        currencyType: selectedCurrencyValue.value,
      });

      setFinancingOverView(financingOverView);
    } catch (error) {
      modal.show(error);
    }
  }

  const goToDeliquentDetail = (loanId: number) => {
    history.push(ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(loanId));
  };

  const goToInternalApprovalDetail = (item: DashBoardApprovalVOModel) => {
    const { eventId, approvalEventType, relatedFinancierId, relatedAnchorAgreementId, relatedPartnerAgreementId } =
      item;

    switch (approvalEventType) {
      case APPROVAL_EVENT_TYPE.LOAN_INTERNAL_REQUEST:
        history.push(ROUTES_DE.MANAGE_FINANCING.CONFIRMATION_DETAIL_BUILD_PATH(eventId), {
          supportedCollateralType: collateralType,
        });
        break;
      case APPROVAL_EVENT_TYPE.INVOICE_APPROVAL_REQUEST:
        history.push(ROUTES_DE.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(eventId, relatedFinancierId), {
          dealerAgreementId: relatedPartnerAgreementId,
        });
        break;
      case APPROVAL_EVENT_TYPE.AP_APPROVAL_REQUEST:
        history.push(ROUTES_DE.REGISTER_AR.CONFIRMATION_DETAIL_BUILD_PATH(eventId), {
          financierEnterpriseId: relatedFinancierId,
          anchorAgreementId: relatedAnchorAgreementId,
        });
        break;
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_INTERNAL_REQUEST:
        history.push(ROUTES_DE.MANAGE_FINANCING.BULK_CONFIRMATION_DETAIL_BUILD_PATH(eventId));
        break;
      default:
        break;
    }
  };

  const goToTaskSchedulerDetail = (item: DashBoardScheduleVOModel) => {
    const { eventId, scheduleEventType, collateralType, financierId } = item;

    switch (scheduleEventType) {
      case SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT:
        history.push(ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(eventId));
        break;
      case SCHEDULE_EVENT_TYPE.AP_SETTLEMENT:
        if (collateralType === COLLATERAL_TYPE.AR) {
          history.push(ROUTES_DE.VIEW_TRANSACTION.AR_DETAIL_BUILD_PATH(eventId), { financierId: financierId });
        } else if (collateralType === COLLATERAL_TYPE.INVOICE) {
          history.push(ROUTES_DE.VIEW_TRANSACTION.INVOICE_DETAIL_BUILD_PATH(eventId));
        }
        break;
      default:
        break;
    }
  };

  const renderFinancingTypeBadge = (collateralType: COLLATERAL_TYPE): JSX.Element | null => {
    if (collateralType === COLLATERAL_TYPE.AR) {
      return (
        <Badge
          content="VENDOR"
          variant="square"
          bgColor="LIGHT_TURQUOISE"
          fontColor="TURQUOISE"
          style={{ marginLeft: 0, marginRight: '8px' }}
        />
      );
    } else if (collateralType === COLLATERAL_TYPE.INVOICE) {
      return (
        <Badge
          content="DEALER"
          variant="square"
          bgColor="LIGHT_OCHER"
          fontColor="OCHER"
          style={{ marginLeft: 0, marginRight: '8px' }}
        />
      );
    } else {
      return null;
    }
  };

  const getInternalApprovalEventTypeText = (eventType: APPROVAL_EVENT_TYPE): string => {
    switch (eventType) {
      case APPROVAL_EVENT_TYPE.LOAN_INTERNAL_REQUEST:
        return t('text:Financing_Request');
      case APPROVAL_EVENT_TYPE.INVOICE_APPROVAL_REQUEST:
        return t('text:Invoice_Approval_Request');
      case APPROVAL_EVENT_TYPE.AP_APPROVAL_REQUEST:
        return t('text:AP_Approval_Request');
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_INTERNAL_REQUEST:
        return t('text:Bulk_Financing_Request');
      default:
        return '-';
    }
  };

  const getInternalApprovalCurrentEventStatusText = (
    eventType: APPROVAL_EVENT_TYPE,
    currentEventStatus: string,
  ): string => {
    let loanApprovalRequestStatus;
    let multipleLoanRequestApprovalStatus;
    if (currentEventStatus === LOAN_STATUS.DEALER_CREATED) {
      loanApprovalRequestStatus = LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL;
    }
    if (currentEventStatus === MULTIPLE_LOAN_REQUEST_STATUS.CREATED) {
      multipleLoanRequestApprovalStatus = COMMON_APPROVAL_TYPE.OPERATOR_REQUEST;
    }

    switch (eventType) {
      case APPROVAL_EVENT_TYPE.LOAN_INTERNAL_REQUEST: // LOAN_APPROVAL_TYPE
        return t(`code:approval-status.${loanApprovalRequestStatus}`);
      case APPROVAL_EVENT_TYPE.INVOICE_APPROVAL_REQUEST: // WAITING_INVOICE_APPROVAL_PHASE_STATUS
        return t(`code:waiting-invoice-approval-phase-status-by-financier.${currentEventStatus}`);
      case APPROVAL_EVENT_TYPE.AP_APPROVAL_REQUEST: // WAITING_AR_APPROVAL_PHASE_STATUS
        return t(`code:waiting-ar-approval-phase-status.${currentEventStatus}`);
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_INTERNAL_REQUEST:
        return t(`code:multiple-loan-approval-type.${multipleLoanRequestApprovalStatus}`);
      default:
        return '-';
    }
  };

  const getTaskSchedulerEventTypeText = (eventType: SCHEDULE_EVENT_TYPE): string => {
    switch (eventType) {
      case SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT:
        return t('text:Repayment');
      case SCHEDULE_EVENT_TYPE.AP_SETTLEMENT:
        return t('text:Settlement');
      default:
        return '-';
    }
  };

  const getTaskSchedulerEventTypeSubText = (eventType: SCHEDULE_EVENT_TYPE, eventIdentifier: string): string => {
    switch (eventType) {
      case SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT:
        return `${t('text:Loan')} #: ${isNil(eventIdentifier) ? 'Not provided' : eventIdentifier}`;
      case SCHEDULE_EVENT_TYPE.AP_SETTLEMENT:
        return `${t('text:AP')} #: ${eventIdentifier}`;
      default:
        return '-';
    }
  };

  const getFinancingOverViewData = (datakey: keyof FinancingOverViewData) => {
    const data = financingOverView;

    if (data) {
      const {
        requestedLoanCount,
        requestedLoanAmount,
        disbursedLoanCount,
        disbursedLoanAmount,
        overdueLoanCount,
        overdueLoanAmount,
        loanableCount,
      } = data;

      switch (datakey) {
        case 'requestedLoanCount':
          return tableValueManage(requestedLoanCount);
        case 'requestedLoanAmount':
          return tableValueManage(requestedLoanAmount, t('format:number', { value: requestedLoanAmount }));
        case 'disbursedLoanCount':
          return tableValueManage(disbursedLoanCount);
        case 'disbursedLoanAmount':
          return tableValueManage(disbursedLoanAmount, t('format:number', { value: disbursedLoanAmount }));
        case 'overdueLoanCount':
          return tableValueManage(overdueLoanCount);
        case 'overdueLoanAmount':
          return tableValueManage(overdueLoanAmount, t('format:number', { value: overdueLoanAmount }));
        case 'loanableCount':
          return loanableCount >= 100 ? `100+` : tableValueManage(loanableCount);
        default:
          return '-';
      }
    }

    return '-';
  };

  const showDeliquentSection = (): boolean => {
    return !!(loanList?.content && loanList?.content.length > 0);
  };

  return (
    <div className="bg-gray-wrap">
      <HeaderTitle title={`${t('text:Welcome')}, ${name}`} />
      <div className="content-area mb-0">
        <div className="row">
          <CardGroup className="simple-card-left-group" col={8}>
            <Card>
              <CardHeader>
                <h2 className="simple-card-title">{t('text:Financing_Overview')}</h2>
              </CardHeader>
              <CardBody>
                <>
                  <Tab
                    className="w-100"
                    tabType="financeType"
                    tabList={[
                      {
                        onClickTab: () => {
                          setRequestCollateralType(COLLATERAL_TYPE.AR);
                        },
                      },
                      {
                        onClickTab: () => {
                          setRequestCollateralType(COLLATERAL_TYPE.INVOICE);
                        },
                      },
                    ]}
                    tabViewList={[]}
                  />
                  <div className="custom-select-wrapper mt-4 mb-4 ms-2">
                    <div className="custom-select-caption">{t('text:Agreement_Currency')}</div>
                    <CustomSelect
                      style={{
                        width: 'max-width',
                        fontSize: '12px',
                        border: '1px solid #E0E0E0',
                        borderRadius: '3px',
                      }}
                      options={selectedCurrencyOptions}
                      value={selectedCurrencyValue}
                      getCurrentViewFunc={o => <div>{o.label}</div>}
                      getOptionViewFunc={o => <div onClick={() => setSelectedCurrencyValue(o)}>{o.label}</div>}
                    />
                  </div>
                  <Card bgColor="#F6F6F6">
                    <CardBody>
                      <div className="d-flex">
                        <div className="w-50 me-4 bg-white flex-column-center-between financing-overview">
                          <div className="financing-overview-info-title">{t('text:Available_for_Financing')}</div>
                          <div className="financing-overview-info-lg-data">
                            {getFinancingOverViewData('loanableCount')}
                          </div>
                          <Button
                            onClick={() => {
                              history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1, {
                                collateralType: requestCollateralType,
                              });
                            }}
                            size={ButtonSizeEnum.LG}
                          >
                            {t('text:Apply_Now')}
                          </Button>
                        </div>
                        <div className="w-50 financing-overview p-0 flex-column-center-between">
                          <div className="financing-overview-info flex-center-between w-100 p-18 bg-white">
                            <div className="financing-overview-info-title">{t('text:Requested_Amt_/_#')}</div>
                            <div className="financing-overview-info-sm-data">
                              {getFinancingOverViewData('requestedLoanAmount')} /{' '}
                              {getFinancingOverViewData('requestedLoanCount')}
                            </div>
                          </div>
                          <div className="financing-overview-info flex-center-between w-100 p-18 bg-white">
                            <div className="financing-overview-info-title">{t('text:Disbursed_Amt_/_#')}</div>
                            <div className="financing-overview-info-sm-data">
                              {getFinancingOverViewData('disbursedLoanAmount')} /{' '}
                              {getFinancingOverViewData('disbursedLoanCount')}
                            </div>
                          </div>
                          <div className="financing-overview-info flex-center-between w-100 p-18 bg-white">
                            <div className="financing-overview-info-title">{t('text:Delinquent_Amt_/_#')}</div>
                            <div className="financing-overview-info-sm-data">
                              {getFinancingOverViewData('overdueLoanAmount')} /{' '}
                              {getFinancingOverViewData('overdueLoanCount')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </>
              </CardBody>
            </Card>
            {showDeliquentSection() && (
              <Card style={{ height: '300px' }}>
                <CardHeader>
                  <h2 className="simple-card-title">{t('text:Delinquency_Management')}</h2>
                  <Badge content={loanList?.totalElements ?? 0} variant="ellipse" fontColor="WHITE" bgColor="RED" />
                </CardHeader>
                <CardBody>
                  <BasicTableBorder basicTableWrapMaxHeight="152px">
                    <BasicTableHeader header={DELINQUENT_HEADERS} />
                    <BasicTableBody numOfCol={DELINQUENT_HEADERS.length}>
                      {loanList?.content.map((item, index) => (
                        <Tr
                          key={index}
                          onClick={() => {
                            goToDeliquentDetail(item.loanId);
                          }}
                        >
                          <Td data={item.financierName} />
                          <Td data={item.repaymentDate} format="date" />
                          <Td data={`+ ${item.overdueDays}`} className="warning" />
                          <Td data={item.disbursedAmount} format="number" />
                          <Td data={item.repaidPrincipalAmount} format="number" />
                        </Tr>
                      ))}
                    </BasicTableBody>
                  </BasicTableBorder>
                </CardBody>
              </Card>
            )}
            <Card style={{ height: '340px' }}>
              <CardHeader>
                <h2 className="simple-card-title">{t('text:Internal_Approval')}</h2>
                <Badge
                  content={internalApprovalList?.totalElements ?? 0}
                  variant="ellipse"
                  fontColor="WHITE"
                  bgColor="YELLOW"
                />
              </CardHeader>
              <CardBody>
                <BasicTableBorder>
                  <BasicTableHeader header={INTERNAL_APPROVAL_HEADERS} />
                  <BasicTableBody numOfCol={INTERNAL_APPROVAL_HEADERS.length}>
                    {internalApprovalList?.content.map((item, index) => (
                      <Tr
                        key={index}
                        onClick={() => {
                          goToInternalApprovalDetail(item);
                        }}
                      >
                        <Td data={item.updatedDateTime} format="datetime" />
                        <Td>
                          <div className="d-flex align-items-center">
                            {renderFinancingTypeBadge(item.collateralType)}
                            {getInternalApprovalEventTypeText(item.approvalEventType)}
                          </div>
                        </Td>
                        <Td
                          className="fw-bold"
                          data={getInternalApprovalCurrentEventStatusText(
                            item.approvalEventType,
                            item.currentEventStatus,
                          )}
                        />
                        <Td data={item.requestedUserName} />
                      </Tr>
                    ))}
                  </BasicTableBody>
                </BasicTableBorder>
              </CardBody>
            </Card>
          </CardGroup>
          <CardGroup className="simple-card-right-group" col={4}>
            <Card style={{ height: '100%' }}>
              <CardHeader>
                <h2 className="simple-card-title">{t('text:Upcoming_Transactions')}</h2>
              </CardHeader>
              <CardBody>
                <>
                  <div className="custom-select-wrapper mb-4">
                    <CustomSelect
                      style={{
                        width: 'max-width',
                        fontSize: '12px',
                        border: '1px solid #E0E0E0',
                        borderRadius: '3px',
                      }}
                      options={selectedScheduleEventOptions}
                      value={selectedScheduleEventValue}
                      getCurrentViewFunc={o => <div>{o.eventName}</div>}
                      getOptionViewFunc={o => <div onClick={() => setSelectedScheduleEventValue(o)}>{o.eventName}</div>}
                    />
                  </div>
                  <BasicTableBorder basicTableWrapMaxHeight={showDeliquentSection() ? '978px' : '663px'}>
                    <BasicTableHeader header={TASK_SCHEDULER_HEADERS} />
                    <BasicTableBody numOfCol={TASK_SCHEDULER_HEADERS.length}>
                      {taskScheduleList?.content.map((item, index) => (
                        <Tr
                          key={index}
                          onClick={() => {
                            goToTaskSchedulerDetail(item);
                          }}
                        >
                          <Td className="fw-bold" data={item.eventDate} format="date" />
                          <Td>
                            <div className="d-flex align-items-center fw-bold">
                              {renderFinancingTypeBadge(item.collateralType)}
                              {getTaskSchedulerEventTypeText(item.scheduleEventType)}
                            </div>
                            <div className="sub-content">
                              {getTaskSchedulerEventTypeSubText(item.scheduleEventType, item.eventIdentifier)}
                            </div>
                          </Td>
                        </Tr>
                      ))}
                    </BasicTableBody>
                  </BasicTableBorder>
                </>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </div>
    </div>
  );
}

export default PartnerDashboard;
