import Profile from '../Profile';

const ProfileButton = () => {
  return (
    <div className="profile-button__wrapper">
      <Profile />
    </div>
  );
};

export default ProfileButton;
