import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import dayjs from 'dayjs';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { LOAN_STATUS } from 'enums';
import type Pageable from 'models/Pageable';
import type { ExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import { getDday, removeRangeFromHoliday } from 'utils/date/date';
import useModal from 'utils/modal/useModal';

interface ExpectedFinancingRepaymentModalProps {
  loanId: number;
  repaymentDate: string;
  disbursedDate: string;
  maxDate?: Date;
  factoringEnable: boolean;
  fetchAll: () => Promise<{
    expectedFinancingRepaymentDataResponse: ExpectedLoanRepaymentVOModel;
    financierHolidayResponse?: Pageable<FinancierCalenderVOModel[]>;
  }>;
  requestSearch: (loanId: number, expectedRepaymentDate: string) => Promise<ExpectedLoanRepaymentVOModel>;
}

function ExpectedFinancingRepaymentModal({
  loanId,
  repaymentDate,
  disbursedDate,
  maxDate,
  requestSearch,
  fetchAll,
  factoringEnable,
}: ExpectedFinancingRepaymentModalProps) {
  const [expectedFinancingRepaymentData, setExpectedFinancingRepaymentData] = useState<ExpectedLoanRepaymentVOModel>();
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회

  const modal = useModal();

  useEffect(() => {
    async function fetchAllAndSetState() {
      try {
        const { expectedFinancingRepaymentDataResponse, financierHolidayResponse } = await fetchAll();

        ReactDOM.unstable_batchedUpdates(() => {
          setExpectedFinancingRepaymentData(expectedFinancingRepaymentDataResponse);
          setFinancierHoliday(financierHolidayResponse);
        });
      } catch (e) {
        modal.show(e);
      }
    }

    fetchAllAndSetState();
  }, [fetchAll]);

  const { t } = useTranslation(['format']);

  const setEnteredSettlementDate = (ScheduledRepaymentDate: string) => {
    const financierHolidayList = financierHoliday?.content.map(item => item.solarDate);
    // 예정상환일이  invalid 한 형식이면 undefined 반환
    if (!dayjs(ScheduledRepaymentDate, 'YYYY-MM-DD', true).isValid()) return undefined;
    // 예정상환일이 휴일에 포함되면 undefined 반환
    if (financierHolidayList?.includes(ScheduledRepaymentDate)) return undefined;
    //예정상환일이 지급날짜 이전이면 undefined 반환
    if (dayjs(ScheduledRepaymentDate).isBefore(disbursedDate)) return undefined;

    return ScheduledRepaymentDate;
  };

  const { handleSubmit, errors, control, watch } = useForm<{ expectedRepaymentDate: string }>({
    defaultValues: {
      expectedRepaymentDate: setEnteredSettlementDate(repaymentDate),
    },
  });

  const { expectedRepaymentDate } = watch();

  const onClickSearch = async (data: { expectedRepaymentDate: string }) => {
    try {
      const response = await requestSearch(loanId, data.expectedRepaymentDate);
      setExpectedFinancingRepaymentData(response);
    } catch (e) {
      modal.show(e);
    }
  };

  const EXPECTED_FINANCING_REPAYMENT_TABLE_HEADER: (HeaderType | boolean)[] = [
    {
      headerText: t('text:Principal_Amount'),
    },
    !factoringEnable && {
      headerText: t('text:Interest_Amount'),
    },
    {
      headerText: t('text:Delinquent_Interest_Amount'),
    },
  ].filter(Boolean);

  const EXPECTED_FINANCING_REPAYMENT_DUE_DATE_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:No'),
      colWidths: 50,
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
    },
    {
      headerText: t('text:Estimated_Interest_Amount'),
    },
  ];

  return (
    <div style={{ minHeight: '390px' }}>
      <div className="d-flex">
        <FormDatePickerInput
          col={4}
          label={t('text:Requested_Repayment_Date')}
          name="expectedRepaymentDate"
          placeholder={t('text:Please_type_here')}
          className="information-form__input bg-sub100"
          control={control}
          error={errors.expectedRepaymentDate}
          excludeDates={removeRangeFromHoliday(financierHoliday?.content)}
          minDate={new Date(disbursedDate)}
          maxDate={maxDate}
        />
        <div className="align-self-end">
          <Button size={ButtonSizeEnum.SM} onClick={handleSubmit(onClickSearch)} style={{ lineHeight: 1.4 }}>
            {t('text:Search')}
          </Button>
        </div>
      </div>
      <div className="text-bold-brick-red ps-2 mb-4">{getDday(repaymentDate, expectedRepaymentDate)}</div>
      <span className="information-form__label mb-2">{t('text:Expected_Repayment_Amount')}</span>
      <TableBorder>
        <TableHeader header={EXPECTED_FINANCING_REPAYMENT_TABLE_HEADER as HeaderType[]} />
        <TableBody numOfCol={EXPECTED_FINANCING_REPAYMENT_TABLE_HEADER.length}>
          <Tr>
            <Td data={expectedFinancingRepaymentData?.scheduledPrincipalAmount} format="number" />
            {!factoringEnable && <Td data={expectedFinancingRepaymentData?.scheduledInterestAmount} format="number" />}
            <Td data={expectedFinancingRepaymentData?.scheduledDelayAmount} format="number" />
          </Tr>
        </TableBody>
      </TableBorder>

      {!factoringEnable && (
        <>
          <div className="mt-5">
            <span className="information-form__label mb-2">{t('text:Expected_Repayment_Schedule')}</span>
            <TableBorder>
              <TableHeader header={EXPECTED_FINANCING_REPAYMENT_DUE_DATE_TABLE_HEADER} />
              <TableBody numOfCol={EXPECTED_FINANCING_REPAYMENT_DUE_DATE_TABLE_HEADER.length}>
                {expectedFinancingRepaymentData?.expectedLoanRepaymentDueDateList?.map((item, i) => {
                  return (
                    <Tr key={i}>
                      <Td className="text-center" data={i + 1} />
                      <Td data={item.repaymentDate} format="date" />
                      <Td data={item.amount} format="number" />
                    </Tr>
                  );
                })}
              </TableBody>
            </TableBorder>
          </div>

          <div className="text-bold-brick-red mt-2">
            {t(
              'text:If_the_variable_interest_rate_is_applied_to_the_loan,_the_interest_amount_may_change_depending_on_the_requested_repayment_date_and_the_actual_repayment_date',
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ExpectedFinancingRepaymentModal;
