import type {
  ExpectedLoanRepaymentDueDateVO,
  ExpectedLoanRepaymentDueDateVOModel,
} from './ExpectedLoanRepaymentDueDateVO';

export interface ExpectedLoanRepaymentVO {
  intendedRepaymentDate: string;
  scheduledPrincipalAmount: number;
  scheduledInterestAmount: number;
  scheduledDelayAmount: number;
  expectedLoanRepaymentDueDateList: ExpectedLoanRepaymentDueDateVO[];
}

export interface ExpectedLoanRepaymentVOModel extends ExpectedLoanRepaymentVO {
  expectedLoanRepaymentDueDateList: ExpectedLoanRepaymentDueDateVOModel[];
}

export function formattingToExpectedLoanRepaymentVOModel(data: ExpectedLoanRepaymentVO): ExpectedLoanRepaymentVOModel {
  return {
    ...data,
  };
}
