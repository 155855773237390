import { useTranslation } from 'react-i18next';

import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DropDown from 'components/potential-partner/DropDown/DropDown';
import PPSubTitle from 'components/potential-partner/PPSubTitle/PPSubTitle';
import PPTableBorder from 'components/potential-partner/PPTable/PPTableBorder';
import Button2, { Button2SizeEnum } from 'components/stateless/Button2/Button2';
import TableBody from 'components/stateless/Table/TableBody';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import {
  POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS,
  POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS,
} from 'enums';
import type { PotentialPartnerFinancingApplicationDocumentVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDocumentVO';
import { requestAnFinancierPotentialPartnerAcquisitionDocumentDownload } from 'utils/http/api/anonymous/potential-partner-acquisition-documents';
import { requestAnPotentialPartnerFinancingApplicationDocumentDownload } from 'utils/http/api/anonymous/potential-partner-financing-application-documents';
import type {
  AnPotentialPartnerFinancingApplicationDocumentCreate,
  AnPotentialPartnerFinancingApplicationDocumentUpdate,
} from 'utils/http/api/anonymous/potential-partner-financing-application-documents/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';

import './PotentialPartnerSubmissionDetailDocumentListTable.scss';
import UploadDocumentModal from './UploadDocumentModal';

import type {
  UploadDocumentDataType,
  UploadDocumentFormDataType,
  UploadDocumentModalProps,
} from './UploadDocumentModal';

interface PotentialPartnerSubmissionDetailDocumentListTableProps {
  submittedDocumentList: PotentialPartnerFinancingApplicationDocumentVOModel[];
  applicationStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
  updateDocumentApis: {
    updateDocument: (
      potentialPartnerFinancingApplicationDocumentId: number,
      data: Pick<
        AnPotentialPartnerFinancingApplicationDocumentUpdate,
        'description' | 'attachment' | 'attachmentChanged'
      >,
    ) => Promise<void>;
    addNewDocument: (
      data: Pick<AnPotentialPartnerFinancingApplicationDocumentCreate, 'description' | 'attachment'>,
    ) => Promise<void>;
    deleteDocument: (potentialPartnerFinancingApplicationDocumentId: number) => Promise<void>;
  };
}

function PotentialPartnerSubmissionDetailDocumentListTable({
  submittedDocumentList,
  applicationStatus,
  updateDocumentApis,
}: PotentialPartnerSubmissionDetailDocumentListTableProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { updateDocument, addNewDocument, deleteDocument } = updateDocumentApis;
  const emailCode = getPotentialPartnerEmailCode();
  const isApplicationStatusCancelledOrRejected = !(
    applicationStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL ||
    applicationStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED
  );

  const DOCUMENT_LIST_HEADER: HeaderType[] = [
    {
      headerText: t('text:Document_Name'),
    },
    {
      headerText: t('text:Attached_File'),
    },
    {
      headerText: t('text:Submitted_Date'),
    },
    {
      headerText: t('text:Status'),
    },
    {
      headerText: '',
      colWidths: 64,
      className: 'text-center',
    },
  ];

  const downloadUploadedFile = async (potentialPartnerFinancingApplicationDocumentId: number) => {
    if (!emailCode) return modal.show('not found email code');
    try {
      await requestAnPotentialPartnerFinancingApplicationDocumentDownload(
        potentialPartnerFinancingApplicationDocumentId,
        emailCode,
      );
    } catch (e) {
      modal.show(e);
    }
  };

  const renderApplicationDocumentStatusData = (
    documentStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS,
  ) => {
    switch (documentStatus) {
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.CONFIRMED:
        return (
          <div className="pp-table-td__data document-status--confirmed">
            <FontAwesomeIcon icon={faCircle} fontSize={10} />
            {t('text:Confirmed')}
          </div>
        );
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.NEED_RESUBMISSION:
        return (
          <div className="pp-table-td__data document-status--need-resubmission">
            <FontAwesomeIcon icon={faCircle} fontSize={10} />
            {t('text:Needs_Resubmission')}
          </div>
        );
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.SUBMITTED:
        return (
          <div className="pp-table-td__data document-status--submitted">
            <FontAwesomeIcon icon={faCircle} fontSize={10} />
            {t('text:Submitted')}
          </div>
        );
    }
  };

  const onClickDeleteAttachment = (applicationDocumentId: number) => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_delete_the_attached_file?')}
        <br />
        {t(
          'text:If_deleted_the_financier_will_not_be_able_to_view_the_document_and_you_may_have_to_submit_your_document_again',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Delete_Attachment'),
        confirmBtnCb: () => deleteDocument(applicationDocumentId),
      },
    );
  };

  const onClickAddAdditionalDocument = () => {
    modal.show(<UploadDocumentModal type="register" formData={{} as UploadDocumentFormDataType} />, {
      modalType: ModalType.CONFIRM,
      title: t('text:Upload_Document'),
      modalSize: ModalSize.L,
      confirmBtnCb: async ({ formData }: UploadDocumentModalProps) => {
        await addNewDocument(formData);
      },
    });
  };

  const onClickEditDocument = (
    potentialPartnerFinancingApplicationDocumentId: number,
    documentData: UploadDocumentDataType,
  ) => {
    modal.show(
      <UploadDocumentModal type="update" documentData={documentData} formData={{} as UploadDocumentFormDataType} />,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Upload_Document'),
        modalSize: ModalSize.L,
        confirmBtnCb: async ({ formData }: UploadDocumentModalProps) => {
          await updateDocument(potentialPartnerFinancingApplicationDocumentId, formData);
        },
      },
    );
  };

  const onClickDownloadTemplate = async (acquisitionDocumentId: number) => {
    if (!emailCode) return modal.show('not found email code');
    try {
      await requestAnFinancierPotentialPartnerAcquisitionDocumentDownload(acquisitionDocumentId, emailCode);
    } catch (e) {
      modal.show(e);
    }
  };

  const renderDropDown = (documentItem: PotentialPartnerFinancingApplicationDocumentVOModel) => {
    return (
      documentItem.applicationDocumentStatus !== POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.CONFIRMED &&
      isApplicationStatusCancelledOrRejected && (
        <DropDown
          items={[
            {
              text: t('text:Edit_Attachment'),
              action: () =>
                onClickEditDocument(documentItem.applicationDocumentId, {
                  attachmentName: documentItem.applicationAttachmentName,
                  description: documentItem.applicationDocumentDescription,
                }),
            },
            ...(documentItem.applicationAttachmentName || documentItem.applicationDocumentDescription
              ? [
                  {
                    text: t('text:Delete_Attachment'),
                    action: () => onClickDeleteAttachment(documentItem.applicationDocumentId),
                  },
                ]
              : []),
            ...(documentItem.acquisitionAttachmentId && documentItem.acquisitionDocumentName
              ? [
                  {
                    text: t('text:Download_Template'),
                    action: () => onClickDownloadTemplate(documentItem.acquisitionDocumentId),
                  },
                ]
              : []),
          ]}
        />
      )
    );
  };

  return (
    <div className="pp-submission-detail-document-list">
      <PPSubTitle title={t('text:Submitted_documents')}>
        {isApplicationStatusCancelledOrRejected && (
          <div className="hide-on-mobile">
            <Button2 onClick={onClickAddAdditionalDocument} size={Button2SizeEnum.SM}>
              {t('text:Add_Additional_Document')}
            </Button2>
          </div>
        )}
      </PPSubTitle>
      <PPTableBorder>
        <TableHeader header={DOCUMENT_LIST_HEADER} />
        <TableBody numOfCol={DOCUMENT_LIST_HEADER.length}>
          {submittedDocumentList.map((item, index) => (
            <Tr key={index}>
              <Td>
                <div className="pp-table-td__label">{DOCUMENT_LIST_HEADER[0].headerText}</div>
                <div className="pp-table-td__data">
                  <div className="bold-font">
                    {item.acquisitionDocumentName ? item.acquisitionDocumentName : t('text:Additional_Documents')}
                  </div>
                  {item.acquisitionDocumentDescription && <div>{item.acquisitionDocumentDescription}</div>}
                </div>
              </Td>
              <Td>
                <div className="pp-table-td__label">{DOCUMENT_LIST_HEADER[1].headerText}</div>
                <div className="pp-table-td__data">
                  {item.applicationAttachmentName ? (
                    <div
                      className="pp-download--file-label"
                      onClick={() => downloadUploadedFile(item.applicationDocumentId)}
                    >
                      {item.applicationAttachmentName}
                    </div>
                  ) : (
                    <div>{t('text:No_file_attached')}</div>
                  )}
                  {item.applicationDocumentDescription && (
                    <div className="pp-download--file-desc">{item.applicationDocumentDescription}</div>
                  )}
                </div>
              </Td>
              <Td>
                <div className="hide-on-mobile">
                  <div className="pp-table-td__label">{DOCUMENT_LIST_HEADER[2].headerText}</div>
                  <div className="pp-table-td__data">
                    {t('format:datetime', {
                      value: item.applicationDocumentUpdatedDateTime,
                      key: 'datetime',
                    })}
                  </div>
                </div>
              </Td>
              <Td>
                <div className="pp-table-td__label">{DOCUMENT_LIST_HEADER[3].headerText}</div>

                {applicationStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS ? (
                  renderApplicationDocumentStatusData(item.applicationDocumentStatus)
                ) : (
                  <div className="pp-table-td__data">-</div>
                )}
              </Td>

              <Td className="text-center">
                <div className="hide-on-mobile">{renderDropDown(item)}</div>
              </Td>
            </Tr>
          ))}
        </TableBody>
      </PPTableBorder>
    </div>
  );
}

export default PotentialPartnerSubmissionDetailDocumentListTable;
