import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ClipboardWriteButton from 'components/stateless/Button/ClipboardWriteButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type { SignInModel } from 'models/SignInModel';
import type { UserVOModel } from 'models/vo/UserVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestDealerAgreementsList } from 'utils/http/api/dealer/dealer-agreements';
import {
  requestDealerEmailSend,
  requestDealerOperatorInviteUrl,
  requestDealerSuspend,
  requestDealerUserDetail,
  requestDealerUserEdit,
} from 'utils/http/api/dealer/users';
import type { DealerUserUpdateRequest } from 'utils/http/api/dealer/users/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import Button, {
  ButtonColorEnum,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../components/stateless/Button/Button';
import getStatusTextClass from '../../../../../utils/classNames/getStatusTextClass';

function DealerUserDetail() {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const getProperty = useProperty<DealerUserUpdateRequest>();
  const { userId } = useParams<any>();

  const [userDetail, setUserDetail] = useState<UserVOModel>(); // 사용자 상세
  const [editable, setEditable] = useState<boolean>(false);
  const [inviteUrl, setInviteUrl] = useState<string | null>('');
  const agreementPageable = usePageable();
  const signInModel: SignInModel | null = getSignIn();
  const isAuthorizer = signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER;

  const { register, getValues, reset, errors, setError, clearErrors, handleSubmit } = useForm<DealerUserUpdateRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (mounted) {
      fetchAll(agreementPageable.pageable.currentPage, agreementPageable.pageable.sizePerPage);
    }
  }, [mounted]);

  async function fetchAll(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const [fetchUserDetail, fetchAgreementList] = await Promise.all([
        requestDealerUserDetail(userId),
        requestDealerAgreementsList(pageNumber, rowCount, {}),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setUserDetail(fetchUserDetail);
        agreementPageable.setPageable(fetchAgreementList);
        setEditable(false);
        reset({
          userName: fetchUserDetail.userName,
          employeeCode: fetchUserDetail.employeeCode,
          department: fetchUserDetail.department,
          position: fetchUserDetail.position,
          mobile: fetchUserDetail.mobile,
          telephone: fetchUserDetail.telephone,
          email: fetchUserDetail.email,
        });
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const showSendEmailButton = () => {
    return userDetail?.userStatus === USER_STATUS.REGISTERED || userDetail?.userStatus === USER_STATUS.INVITED;
  };

  const onClickCancel = (e: any) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information')}?<br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      },
    );
    const onClickConfirm = () => {
      setEditable(prevState => !prevState);
      reset({
        userName: userDetail?.userName,
        employeeCode: userDetail?.employeeCode,
        department: userDetail?.department,
        position: userDetail?.position,
        mobile: userDetail?.mobile,
        telephone: userDetail?.telephone,
        email: userDetail?.email,
      });
    };
  };

  const onClickSave = async () => {
    const data = getValues();

    try {
      requestDTOParser(data);
      await requestDealerUserEdit(userId, data);

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => await fetchAll(),
      });
    } catch (e) {
      modal.show(e);

      formErrorHandler<DealerUserUpdateRequest>(e, setError, clearErrors);
    }
  };

  // make user Active
  const onMakeUserActive = async (e: any) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_activate_the_user_account')}
        <br />
        {t(
          'text:If_the_account_is_activated_the_user_will_be_able_to_log_in_to_the_platform_and_use_its_features_again',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestDealerSuspend(userId, false);
            showRequestUserActiveConfirmModal();
          } catch (e) {
            modal.show(e);
          }
        },
      },
    );

    const showRequestUserActiveConfirmModal = () => {
      modal.show(<h6>{t('text:The_user_account_has_been_activated')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          fetchAll();
        },
      });
    };
  };

  // 사용자 이용중지
  const onMakeUserInactive = async (e: any) => {
    e.preventDefault();
    const showRequestUserInactiveConfirmModal = () => {
      modal.show(
        <h6>
          {t('text:The_user_account_has_been_deactivated')}
          <br />
          {t('text:To_reactivate_the_account_click_on_the_Activate_button')}
        </h6>,
        {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => {
            fetchAll();
          },
        },
      );
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_deactivate_the_user_account?')}
        <br />
        {t(
          'text:If_the_account_is_deactivated_the_user_will_not_be_able_to_log_in_to_the_platform_or_use_its_features',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestDealerSuspend(userId, true);
            showRequestUserInactiveConfirmModal();
          } catch (e) {
            modal.show(e);
          }
        },
      },
    );
  };

  const handleEdit = () => {
    setEditable(prevState => !prevState);
  };

  const renderButtons = () => {
    return editable ? (
      <>
        <Button
          size={ButtonSizeEnum.SM}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          onClick={onClickCancel}
        >
          {t('text:Cancel')}
        </Button>
        <Button size={ButtonSizeEnum.SM} onClick={handleSubmit(onClickSave)} style={{ width: '60px' }}>
          {t('text:Save')}
        </Button>
      </>
    ) : (
      <>
        {userDetail?.userStatus === USER_STATUS.SUSPENDED && (
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.BLUE}
            onClick={onMakeUserActive}
          >
            {t('text:Activate')}
          </Button>
        )}
        {userDetail?.userStatus === USER_STATUS.ACTIVATED && (
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.RED}
            onClick={onMakeUserInactive}
          >
            {t('text:Deactivate')}
          </Button>
        )}

        <Button size={ButtonSizeEnum.SM} onClick={handleEdit} style={{ width: '60px' }}>
          {t('text:Edit')}
        </Button>
      </>
    );
  };

  const showEmailSendConfirmModal = (modalId: number) => {
    modal.show(<h6>{t('text:The_invitation_email_has_been_sent')}</h6>, {
      modalType: ModalType.ALERT,
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        modal.close(modalId);
        fetchAll();
      },
    });
  };

  // 이메일 send 요청
  const onSendEmailModalHandler = async () => {
    modal.show(<h6>{t('text:Would_you_like_to_send_an_invitation_email')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestDealerEmailSend(userId);
          showEmailSendConfirmModal(modal.id);
          setInviteUrl('');
        } catch (e) {
          modal.show(e);
        }
      },
    });
  };

  const onClickClipboardWriteButton = async () => {
    if (inviteUrl === null) return null;

    try {
      const inviteUrl = await requestDealerOperatorInviteUrl(userId);
      if (inviteUrl === null) {
        modal.show(t('text:Click_on_the_Send_button_before_copying_the_invitation_email_link'));
      }
      setInviteUrl(inviteUrl);

      return inviteUrl;
    } catch (e) {
      modal.show(e);

      return null;
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:User_Details')} />
      <div className="content-area">
        <SectionTitle title="">{isAuthorizer && renderButtons()}</SectionTitle>
        <FormBorder editable={editable}>
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Name')}
                name={getProperty('userName')}
                ref={register}
                requiredOptions={{ required: true }}
                disabled={!editable}
                error={errors.userName}
              />
              <FormInput
                label={t('text:Employee_Code')}
                name={getProperty('employeeCode')}
                ref={register}
                disabled={!editable}
                error={errors.employeeCode}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Department')}
                name={getProperty('department')}
                ref={register}
                disabled={!editable}
                error={errors.department}
              />
              <FormInput
                label={t('text:Title')}
                name={getProperty('position')}
                ref={register}
                disabled={!editable}
                error={errors.position}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Mobile')}
                name={getProperty('mobile')}
                ref={register}
                disabled={!editable}
                error={errors.mobile}
                requiredOptions={{ required: true }}
              />
              <FormInput
                label={t('text:Telephone')}
                name={getProperty('telephone')}
                ref={register}
                disabled={!editable}
                error={errors.telephone}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Email')}
                name={getProperty('email')}
                ref={register}
                disabled={!editable}
                error={errors.email}
                requiredOptions={{ required: true }}
              >
                <>
                  {!editable && isAuthorizer && userDetail?.userStatus === USER_STATUS.INVITED && (
                    <ClipboardWriteButton
                      onClickClipboardWriteButton={onClickClipboardWriteButton}
                      disabled={inviteUrl === null}
                      className="ms-2"
                    />
                  )}
                  {showSendEmailButton() && !editable && isAuthorizer && (
                    <Button onClick={onSendEmailModalHandler} className="ms-2">
                      {t('text:Send')}
                    </Button>
                  )}
                </>
              </FormInput>
              <FormValue
                label={t('text:Authority')}
                value={t(`code:authority-type.${userDetail?.authorityType}`)}
                className="information-form__input"
              />
            </div>
            <div className="row">
              <FormValue label={t('text:User_ID')} value={userDetail?.loginId} className="information-form__input" />
              <FormValue
                label={t('text:User_Account_Status')}
                value={t(`code:user-status.${userDetail?.userStatus}`)}
                className={'information-form__input ' + getStatusTextClass('USER_STATUS', userDetail?.userStatus)}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickUserStatus}
              />
            </div>
            <div className="row">
              {userDetail?.createDateTime && (
                <FormValue
                  label={t('text:Registered_Date')}
                  value={userDetail.createDateTime}
                  format="datetime"
                  className="information-form__input"
                />
              )}
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default DealerUserDetail;
