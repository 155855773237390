import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';

interface ArInformationProps {
  dealerAgreementDetail: DealerAgreementDetailVOModel;
}

function ArInformation({ dealerAgreementDetail }: ArInformationProps) {
  const { t } = useTranslation(['format']);

  return (
    <>
      <SectionTitle title={t('text:Company_Information')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Anchor_Name')} value={dealerAgreementDetail?.anchorFinancierClientName} />
            <FormValue
              col={3}
              label={t('text:Partner_Name')}
              value={dealerAgreementDetail?.dealerFinancierClientName}
            />
            <FormValue col={3} label={t('text:Currency')} value={dealerAgreementDetail?.currencyType} />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default ArInformation;
