import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import PageTitle from 'components/potential-partner/PageTitle/PageTitle';
import { ROUTES_PP } from 'constants/routes/potentialPartner';
import type { AnPotentialPartnerFinancingApplicationSubmitRequest } from 'utils/http/api/anonymous/potential-partner-financing-applications/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';

type PotentialPartnerApplicationValue = {
  formData: AnPotentialPartnerFinancingApplicationSubmitRequest;
  formStep: number;
  selectedBranchName: string;
};

type PotentialPartnerApplicationActions = {
  setFormValues: (values: any) => void;
  setPrevFormStep: () => void;
  setNextFormStep: () => void;
  selectBranch: (branchName: string) => void;
  onClickCancelSubmissionBtn: () => void;
};

export const PotentialPartnerApplicationValueContext = createContext<PotentialPartnerApplicationValue>({
  formData: {} as AnPotentialPartnerFinancingApplicationSubmitRequest,
  formStep: 1,
  selectedBranchName: '',
});

export const PotentialPartnerApplicationActionsContext = createContext<PotentialPartnerApplicationActions>({
  setFormValues: () => {},
  setPrevFormStep: () => {},
  setNextFormStep: () => {},
  selectBranch: () => {},
  onClickCancelSubmissionBtn: () => {},
});

const PotentialPartnerApplicationProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [formData, setFormData] = useState<AnPotentialPartnerFinancingApplicationSubmitRequest>(
    {} as AnPotentialPartnerFinancingApplicationSubmitRequest,
  );
  const [formStep, setFormStep] = useState<number>(1); // step 1,2,3
  const [selectedBranchName, setSelectedBranchName] = useState<string>(''); // step2 에서 선택한 지점명 -> step 3에서 보여주기
  const history = useHistory();
  const modal = useModal();
  const { t } = useTranslation();
  const ppContainerDOMClassName = 'pp-wrapper';

  const actions = useMemo(
    () => ({
      setPrevFormStep() {
        scrollToTopOfElement(ppContainerDOMClassName);
        setFormStep(currentStep => currentStep - 1);
      },
      setNextFormStep() {
        scrollToTopOfElement(ppContainerDOMClassName);
        setFormStep(currentStep => currentStep + 1);
      },
      setFormValues(values: any) {
        setFormData(prevValues => ({
          ...prevValues,
          ...values,
        }));
      },
      selectBranch(branchName: string) {
        setSelectedBranchName(branchName);
      },
      onClickCancelSubmissionBtn() {
        modal.show(
          <h6>
            {t('text:Would_you_like_to_cancel_the_application?')}
            <br />
            {t('text:The_information_will_not_be_saved_if_the_application_is_cancelled')}
          </h6>,
          {
            modalType: ModalType.CONFIRM,
            confirmBtnCb: () => history.push(ROUTES_PP.HOME),
          },
        );
      },
    }),
    [],
  );

  return (
    <PotentialPartnerApplicationActionsContext.Provider value={actions}>
      <PotentialPartnerApplicationValueContext.Provider
        value={{
          formData,
          formStep,
          selectedBranchName,
        }}
      >
        <div className="pp-application-page-wrapper">
          <PageTitle title={t('text:Submit_Vendor_Finance_Application')} onClick={actions.onClickCancelSubmissionBtn} />
          {children}
        </div>
      </PotentialPartnerApplicationValueContext.Provider>
    </PotentialPartnerApplicationActionsContext.Provider>
  );
};

export default PotentialPartnerApplicationProvider;

export const usePotentialPartnerApplicationValue = () => useContext(PotentialPartnerApplicationValueContext);

export const usePotentialPartnerApplicationActions = () => useContext(PotentialPartnerApplicationActionsContext);
