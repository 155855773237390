import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './HeaderBar.scss';
import { HeaderBarContainer, HeaderBarList, HeaderBarListItem, HeaderBarTitle } from './HeaderBarComponents';
import NotificationButton from './sections/NotificationButton';
import ProfileButton from './sections/ProfileButton';
import SessionTimerButton from './sections/SessionTimerButton';
import ZoomSetter from './sections/ZoomSetter';
import useHeaderBarController from './useHeaderBarController';

interface HeaderBarPropsType {
  sideBarCollapsed: boolean;
  updateSideBarCollapsed?(show: boolean): void;
}

const HeaderBar = ({ sideBarCollapsed, updateSideBarCollapsed }: HeaderBarPropsType) => {
  const { isLogoRender, logos, handleLogoClick } = useHeaderBarController();

  const handleClickHamburgerIcon = () => {
    if (updateSideBarCollapsed) updateSideBarCollapsed(!sideBarCollapsed);
  };

  return (
    <HeaderBarContainer>
      <button className="header-bar__button icon-bars" onClick={handleClickHamburgerIcon}>
        <FontAwesomeIcon icon={faBars} fontSize={17.5} fontWeight={900} color="white" />
      </button>

      {isLogoRender && (
        <div className="header-bar__logo-wrapper">
          <a className="header-bar__logo" onClick={handleLogoClick}>
            <img src={`data:image/png;base64,${logos}`} alt="FIN2B" />
          </a>
        </div>
      )}
      <HeaderBarTitle />
      <HeaderBarList>
        <HeaderBarListItem>
          <ZoomSetter />
        </HeaderBarListItem>

        <HeaderBarListItem>
          <SessionTimerButton />
        </HeaderBarListItem>

        <HeaderBarListItem>
          <NotificationButton />
        </HeaderBarListItem>

        <HeaderBarListItem>
          <ProfileButton />
        </HeaderBarListItem>
      </HeaderBarList>
    </HeaderBarContainer>
  );
};

export default HeaderBar;
