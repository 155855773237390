import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import type { PotentialPartnerFinancingApplicationDetailVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';

interface FinancierSCPartnerAcquisitionDetailSummaryProps {
  applicationSummaryInfo: PotentialPartnerFinancingApplicationDetailVOModel;
}

function FinancierSCPartnerAcquisitionDetailSummary({
  applicationSummaryInfo,
}: FinancierSCPartnerAcquisitionDetailSummaryProps) {
  const { t } = useTranslation(['format']);

  const setSubmissionStatusText = (status: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS) => {
    switch (status) {
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED: {
        return t('text:Submitted');
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED: {
        return t('text:Completed');
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED: {
        return t('text:Rejected');
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL: {
        return t('text:Cancelled');
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS: {
        return t('text:In_Progress');
      }
    }
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Summary')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              col={4}
              label={t('text:Submission_Date')}
              format="datetime"
              value={applicationSummaryInfo.createdDateTime}
            />
            <FormValue
              col={4}
              label={t('text:Recent_Update')}
              format="datetime"
              value={applicationSummaryInfo.latestDocumentUpdateDateTime}
            />
            <FormValue
              col={4}
              className={getStatusBadgeClass(
                'POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS',
                applicationSummaryInfo.potentialPartnerFinancingApplicationStatus,
              )}
              label={t('text:Submission_Status')}
              value={setSubmissionStatusText(applicationSummaryInfo.potentialPartnerFinancingApplicationStatus)}
            />
          </div>
          <div className="row">
            <FormValue col={4} label={t('text:Requested_Branch')} value={applicationSummaryInfo.requestBranchName} />
            {applicationSummaryInfo.potentialPartnerFinancingApplicationStatus !==
              POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED && (
              <FormValue col={4} label={t('text:Reviewed_Branch')} value={applicationSummaryInfo.reviewedBranchName} />
            )}
            {applicationSummaryInfo.potentialPartnerFinancingApplicationStatus ===
              POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED && (
              <FormValue
                col={4}
                label={t('text:Rejected_Date')}
                format="datetime"
                value={applicationSummaryInfo.rejectedDateTime}
              />
            )}
            {applicationSummaryInfo.potentialPartnerFinancingApplicationStatus ===
              POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL && (
              <FormValue
                col={4}
                label={t('text:Cancelled_Date')}
                format="datetime"
                value={applicationSummaryInfo.cancelledDateTime}
              />
            )}
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default FinancierSCPartnerAcquisitionDetailSummary;
