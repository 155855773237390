import { COLLATERAL_TYPE, CURRENCY_TYPE, LOAN_STATUS, stringToEnum } from 'enums';

export interface PlatformTransactionVO {
  loanId: number;
  anchorClientName: string;
  dealerClientName: string;
  collateralType: string;
  currencyType: string;
  collateralNumber: string;
  collateralReferenceNumber: string;
  collateralAmount: number;
  disbursedAmount: number;
  disbursedDate: string;
  repaymentDate: string;
  repaidDate: string;
  expiredDate: string;
  loanStatus: string;
  startDate: string;
  endDate: string;
  term: number;
  performanceFee: number;
}

export interface PlatformTransactionVOModel extends PlatformTransactionVO {
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
  loanStatus: LOAN_STATUS;
}

export function formattingToPlatformTransactionVOModel(data: PlatformTransactionVO): PlatformTransactionVOModel {
  return {
    ...data,
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
  };
}
