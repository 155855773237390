import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Accordion from 'components/stateless/Accordion/Accordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { BANK_CODE_APPROVAL_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { WaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { requestCmBankCodeDetail } from 'utils/http/api/common/bank-code';
import { requestSyAnchorPartnerAccountsList } from 'utils/http/api/system/anchor-partner-accounts';
import {
  requestSyWaitingBankCodeApprove,
  requestSyWaitingBankCodeDetail,
  requestSyWaitingBankCodeReject,
} from 'utils/http/api/system/waiting-bank-code';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

function SystemBankCodeWaitingDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();
  const [waitingBankCodeDetail, setWaitingBankCodeDetail] = useState<WaitingBankCodeVOModel>();
  const [bankCodeDetail, setBankCodeDetail] = useState<BankCodeVOModel>();
  const [bankAccountList, setBankAccountList] = useState<Pageable<AnchorPartnerAccountVOModel[]>>();
  const bankAccountPageable = usePageable();
  const { waitingBankCodeId } = useParams<any>();
  const [locationState, errorHandlerOfLocationState] = useLocationState<{
    targetBankCodeId: number;
  }>(['targetBankCodeId']);
  const { targetBankCodeId } = locationState;

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      if (targetBankCodeId) {
        const [fetchWaitingBankCode, fetchBankCodeDetail, fetchAnchorPartnerAccounts] = await Promise.all([
          requestSyWaitingBankCodeDetail(waitingBankCodeId),
          requestCmBankCodeDetail(targetBankCodeId),
          requestSyAnchorPartnerAccountsList(
            bankAccountPageable.pageable.currentPage,
            bankAccountPageable.pageable.sizePerPage,
            {
              accountBankCodeId: targetBankCodeId,
            },
          ),
        ]);
        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingBankCodeDetail(fetchWaitingBankCode);
          setBankCodeDetail(fetchBankCodeDetail);
          bankAccountPageable.setPageable(fetchAnchorPartnerAccounts);
          setBankAccountList(fetchAnchorPartnerAccounts);
        });
      } else {
        const fetchWaitingBankCode = await requestSyWaitingBankCodeDetail(waitingBankCodeId);
        setWaitingBankCodeDetail(fetchWaitingBankCode);
      }
    } catch (e) {
      modal.show(e);
    }
  };

  const renderBankAccountInfo = () => {
    const bankAccountInfoTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
      },
      {
        headerText: t('text:Responsible_Bank_Name'),
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
      },
      {
        headerText: t('text:Uploaded_Partner_Name'),
      },
      {
        headerText: t('text:Uploaded_Partner_Tax_Code'),
      },
      {
        headerText: t('text:Bank_Account_Holder'),
      },
      {
        headerText: t('text:Bank_Account_Number'),
      },
    ];

    const bankAccountPaginate = async (pageNumber: number, sizePerPage: number) => {
      try {
        const fetchAnchorPartnerAccounts = await requestSyAnchorPartnerAccountsList(pageNumber, sizePerPage, {
          accountBankCodeId: targetBankCodeId,
        });
        ReactDOM.unstable_batchedUpdates(() => {
          setBankAccountList(fetchAnchorPartnerAccounts);
          bankAccountPageable.setPageable(fetchAnchorPartnerAccounts);
        });
      } catch (e) {
        modal.show(e);
      }
    };

    return (
      <>
        <TableBorder>
          <TableHeader header={bankAccountInfoTableHeaders} backGroundType={BackGroundType.DarkGray} />
          <TableBody numOfCol={bankAccountInfoTableHeaders.length}>
            {bankAccountList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.anchorAgreementContractNo} />
                  <Td data={item.anchorName} />
                  <Td data={item.responsibleFinancierName} />
                  <Td data={item.responsibleBranchName} />
                  <Td data={item.partnerName} />
                  <Td data={item.partnerTaxCode} />
                  <Td data={item.accountOwner} />
                  <Td data={item.account} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={bankAccountPageable.pageable} paginate={bankAccountPaginate} />
      </>
    );
  };

  const onClickReject = () => {
    modal.show(<h6>{t('text:Would_you_like_to_reject_the_request?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => requestReject(),
    });

    const requestReject = async () => {
      try {
        await requestSyWaitingBankCodeReject(waitingBankCodeId);
        showRejectSuccessModal();
      } catch (e) {
        modal.show(e);
      }
    };

    const showRejectSuccessModal = () => {
      modal.show(<h6>{t('text:The_request_has_been_rejected_successfully')}</h6>, {
        closeBtnCb: () => fetchAll(),
      });
    };
  };

  const onClickConfirm = () => {
    modal.show(<h6>{t('text:Would_you_like_to_confirm_the_request?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => requestApprove(),
    });

    const requestApprove = async () => {
      try {
        await requestSyWaitingBankCodeApprove(waitingBankCodeId);
        showSavedSuccessModal();
      } catch (e) {
        modal.show(e);
      }
    };
    const showSavedSuccessModal = () => {
      modal.show(<h6>{t('text:The_Bank_Code_has_been_saved')}</h6>, {
        closeBtnCb: () => history.push(ROUTES_SY.MANAGEMENT.BANK_CODE_LIST),
      });
    };
  };

  const renderProgressStatus = (progressStatus: BANK_CODE_APPROVAL_TYPE | undefined): JSX.Element => {
    switch (progressStatus) {
      case bankCodeDetail && BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST:
        return t('text:Revision_Requested');
      case !bankCodeDetail && BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST:
        return t('text:Registration_Requested');
      case BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL:
        return t('text:Cancelled');
      case BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED:
        return t('text:Rejected');
      case BANK_CODE_APPROVAL_TYPE.SYSTEM_APPROVED:
        return t('text:Registered');
      default:
        return <></>;
    }
  };

  const renderTimeText = (progressStatus: BANK_CODE_APPROVAL_TYPE | undefined): string => {
    switch (progressStatus) {
      case BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST:
        return t('text:Requested_Time');
      case BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL:
        return t('text:Cancelled_Time');
      case BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED:
        return t('text:Rejected_Time');
      default:
        return '';
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Bank_Code_Management_Details')} />
      {waitingBankCodeDetail?.approvalType !== BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED && (
        <GuideMessage
          message={[
            t('text:A_financier_has_requested_the_bank_code_information_below_be_revised'),
            t(
              'text:Please_check_the_bank_accounts_of_companies_currently_using_the_bank_code_before_confirming_or_rejecting_the_request',
            ),
          ]}
        />
      )}
      {waitingBankCodeDetail && (
        <div className="content-area">
          <Accordion
            id="waiting-bank-code-detail"
            className="accordion accordion-information-form"
            defaultExpanded={true}
          >
            <Accordion.Header id="waiting-bank-code-detail-header">
              <Accordion.Trigger>
                <div className="accordion-information-form__head">
                  <div className={getStatusBadgeClass('BANK_CODE_APPROVAL_TYPE', waitingBankCodeDetail?.approvalType)}>
                    {renderProgressStatus(waitingBankCodeDetail?.approvalType)}
                  </div>
                  <div className="accordion-information-form__head--text">
                    <div className="fw-bold">
                      {t('text:Admin')} ( {waitingBankCodeDetail?.operatorUserName} /{' '}
                      {waitingBankCodeDetail?.operatorUserLoginId} )
                    </div>
                    {waitingBankCodeDetail.operatorEnterpriseName}
                  </div>
                  <div className="accordion-information-form__head--text">
                    <div className="fw-bold">{renderTimeText(waitingBankCodeDetail.approvalType)}</div>
                    {t('format:datetime', { value: waitingBankCodeDetail?.updatedDateTime, key: 'datetime' })}
                  </div>
                </div>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="accordion-body p-0">
              <FormBorder>
                <FormContents>
                  <div className="row">
                    <FormValue label={t('text:Financier_Name')} value={waitingBankCodeDetail?.financierName} />
                  </div>
                  <div className="row">
                    <FormValue label={t('text:Bank_Code')} value={waitingBankCodeDetail?.bankCode} />
                    <FormValue label={t('text:Bank_Name')} value={waitingBankCodeDetail?.bankName} />
                  </div>
                  <div className="row">
                    <FormValue label={t('text:Branch_Code')} value={waitingBankCodeDetail?.branchCode} />
                    <FormValue label={t('text:Branch_Name')} value={waitingBankCodeDetail?.branchName} />
                  </div>
                  <div className="row">
                    <FormRadioWrap label={t('text:Bank_code_Activation_Status')}>
                      <FormRadio label={t('text:Activate')} defaultChecked={!waitingBankCodeDetail?.deleted} disabled />
                      <FormRadio
                        label={t('text:Deactivate')}
                        defaultChecked={waitingBankCodeDetail?.deleted}
                        disabled
                      />
                    </FormRadioWrap>
                  </div>
                </FormContents>
              </FormBorder>
              <FormBorder>
                <FormSubtitle
                  title={t('text:Associated_Bank_Account_Information')}
                  backGroundType={BackGroundType.DarkGray}
                />
                <FormContents>{renderBankAccountInfo()}</FormContents>
              </FormBorder>
            </Accordion.Content>
          </Accordion>
          {waitingBankCodeDetail.approvalType === BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST && (
            <div className="flex-end mt-4">
              <Button
                size={ButtonSizeEnum.LG}
                onClick={onClickReject}
                color={ButtonColorEnum.SECONDARY}
                variant={ButtonVariantEnum.OUTLINED}
              >
                {t('text:Reject')}
              </Button>
              <Button size={ButtonSizeEnum.LG} onClick={onClickConfirm} className="ms-2">
                {t('text:Confirm')}
              </Button>
            </div>
          )}
        </div>
      )}
      {bankCodeDetail && (
        <div className="content-area">
          <Accordion className="accordion accordion-information-form" id="bank-code-detail">
            <Accordion.Header id="bank-code-detail-header">
              <Accordion.Trigger>
                <div className="accordion-information-form__head">
                  <div className="d-flex align-items-center text-nowrap">
                    {renderProgressStatus(BANK_CODE_APPROVAL_TYPE.SYSTEM_APPROVED)}
                  </div>
                  <div className="accordion-information-form__head--text" />
                  <div className="accordion-information-form__head--text">
                    <div className="fw-bold">{t('text:Latest_Update_Date')}</div>
                    {t('format:datetime', { value: bankCodeDetail.updatedDateTime, key: 'datetime' })}
                  </div>
                </div>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="accordion-body p-0">
              <FormBorder>
                <FormSubtitle title={t('text:Bank_Code_Information')} backGroundType={BackGroundType.DarkGray} />
                <FormContents>
                  <div className="row">
                    <FormValue label={t('text:Bank_Code')} value={bankCodeDetail?.bankCode} />
                    <FormValue label={t('text:Bank_Name')} value={bankCodeDetail?.bankName} />
                  </div>
                  <div className="row">
                    <FormValue label={t('text:Branch_Code')} value={bankCodeDetail?.branchCode} />
                    <FormValue label={t('text:Branch_Name')} value={bankCodeDetail?.branchName} />
                  </div>
                  <div className="row">
                    <FormRadioWrap label={t('text:Bank_code_Activation_Status')}>
                      <FormRadio label={t('text:Activate')} defaultChecked={!bankCodeDetail?.deleted} disabled />
                      <FormRadio label={t('text:Deactivate')} defaultChecked={bankCodeDetail?.deleted} disabled />
                    </FormRadioWrap>
                  </div>
                </FormContents>
              </FormBorder>
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </>
  );
}

export default SystemBankCodeWaitingDetail;
