import type Pageable from 'models/Pageable';
import type { AnchorReportSettingVO, AnchorReportSettingVOModel } from 'models/vo/AnchorReportSettingVO';
import { formattingToAnchorReportSettingVOModel } from 'models/vo/AnchorReportSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FiAnchorReportSettingListRequest,
  FiAnchorReportSettingRegisterRequest,
  FiAnchorReportSettingUpdateRequest,
} from './requests';

export async function requestFiAnchorReportSettingList(
  pageNumber: number,
  rowCount: number,
  data: FiAnchorReportSettingListRequest,
): Promise<Pageable<AnchorReportSettingVOModel[]>> {
  const response = await http.get<Pageable<AnchorReportSettingVO[]>>({
    url: API_FI.ANCHOR_REPORT_SETTINGS.ANCHOR_REPORT_SETTINGS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorReportSettingVOModel(data)),
  };
}

export async function requestFiAnchorReportSettingData(
  anchorReportSettingId: number,
): Promise<AnchorReportSettingVOModel> {
  const response = await http.get<AnchorReportSettingVO>({
    url: API_FI.ANCHOR_REPORT_SETTINGS.ANCHOR_REPORT_SETTING_DETAIL(anchorReportSettingId),
  });

  return formattingToAnchorReportSettingVOModel(response);
}

export async function requestFiAnchorReportSettingRegist(
  data: FiAnchorReportSettingRegisterRequest,
): Promise<AnchorReportSettingVOModel> {
  const response = await http.post<AnchorReportSettingVO>({
    url: API_FI.ANCHOR_REPORT_SETTINGS.ANCHOR_REPORT_SETTINGS,
    data,
  });

  return formattingToAnchorReportSettingVOModel(response);
}

export async function requestFiAnchorReportSettingUpdate(
  anchorReportSettingId: number,
  data: FiAnchorReportSettingUpdateRequest,
): Promise<AnchorReportSettingVOModel> {
  const response = await http.put<AnchorReportSettingVO>({
    url: API_FI.ANCHOR_REPORT_SETTINGS.ANCHOR_REPORT_SETTING_DETAIL(anchorReportSettingId),
    data,
  });

  return formattingToAnchorReportSettingVOModel(response);
}
