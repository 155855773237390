import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE, ENTERPRISE_TYPE } from 'enums';
import { WAITING_INVOICE_APPROVAL_PHASE_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestDealerInvoiceSummaryList } from 'utils/http/api/dealer/invoice-summaries';
import type { DealerInvoiceSummaryListRequest } from 'utils/http/api/dealer/invoice-summaries/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const DE_INVOICE_CONFIRMATION_LIST_QS_KEY = 'de-invoice-confirmation-list';

function DealerInvoiceConfirmationList() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { pageable, setPageable } = usePageable(DE_INVOICE_CONFIRMATION_LIST_QS_KEY);
  const { register, getValues, reset, setValue, control } = useForm<DealerInvoiceSummaryListRequest>();

  const [invoiceSummaryPage, setInvoiceSummaryPage] = useState<Pageable<InvoiceSummaryVOModel[]>>();

  useEffect(() => {
    setFormValues<DealerInvoiceSummaryListRequest>(
      setValue,
      getParsedSearchParams(DE_INVOICE_CONFIRMATION_LIST_QS_KEY).formSearchData,
    );

    if (mounted) {
      fetchDeInvoiceSummaryList(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  const fetchDeInvoiceSummaryList = async (
    selectedPageNumber: number = 1,
    selectedRowNumber: number = 10,
    data: DealerInvoiceSummaryListRequest,
  ): Promise<void> => {
    try {
      const invoiceSummaryPageResponse = await requestDealerInvoiceSummaryList(
        selectedPageNumber,
        selectedRowNumber,
        data,
      );
      updateSearchParams(
        {
          ...data,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowNumber,
        },
        DE_INVOICE_CONFIRMATION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPageable(invoiceSummaryPageResponse);
        setInvoiceSummaryPage(invoiceSummaryPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginate = async (selectedPageNumber: number, selectedRowNumber: number): Promise<void> => {
    fetchDeInvoiceSummaryList(
      selectedPageNumber,
      selectedRowNumber,
      getParsedSearchParams(DE_INVOICE_CONFIRMATION_LIST_QS_KEY).formSearchData,
    );
  };

  const setProgressStatusClassName = (code: WAITING_INVOICE_APPROVAL_PHASE_STATUS, rejected: boolean) => {
    if (rejected) {
      return getStatusTextClass(
        'WAITING_INVOICE_APPROVAL_PHASE_STATUS',
        WAITING_INVOICE_APPROVAL_PHASE_STATUS.REJECTED,
      );
    } else {
      return getStatusTextClass('WAITING_INVOICE_APPROVAL_PHASE_STATUS', code);
    }
  };

  const onClickRemove = (e: any): void => {
    e.preventDefault();
    reset({});
  };

  const onClickSearch = async (e: any): Promise<void> => {
    e.preventDefault();
    fetchDeInvoiceSummaryList(0, pageable.sizePerPage, getValues());
  };

  const onClickInvoiceConfirmationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(WAITING_INVOICE_APPROVAL_PHASE_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="WAITING_INVOICE_APPROVAL_PHASE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderResultTable = (): JSX.Element[] | JSX.Element | undefined => {
    return invoiceSummaryPage?.content.map((item, i: number) => {
      return (
        <Tr key={i}>
          <Td data={item.createDateTime} format="datetime" />
          <Td data={item.anchorClientName} />
          <Td data={item.anchorContractNo} />
          <Td data={item.currencyType} className="text-center" />
          <Td data={item.billOfExchangeNo} />
          <Td data={item.rejected ? 'Y' : 'N'} className="text-center" />
          <Td
            data={
              item.rejected
                ? '-'
                : t(`code:current-invoice-approval-phase-status.${item.currentInvoiceApprovalPhaseStatus}`)
            }
            className={setProgressStatusClassName(item.currentInvoiceApprovalPhaseStatus, item.rejected)}
          />
          <Td data={t(`code:enterprise-type.${item.currentConfirmEnterpriseType}`)} />
          <Td data={item.currentInvoiceApprovalDateTime} format="datetime" />
          <Td data={item.rejected ? '-' : item.currentApprovalCount} format="number" />
          <Td data={item.rejected ? '-' : item.currentApprovalAmount} format="number" />
          <TdLink
            path={ROUTES_DE.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(
              item.invoiceSummaryId,
              item.financierEnterpriseId,
            )}
            state={{ dealerAgreementId: item.dealerAgreementId }}
          />
        </Tr>
      );
    });
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Registration_Initiated_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:BOE_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registration_Rejection'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registration_Status'),
      colWidths: 150,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickInvoiceConfirmationStatus,
    },
    {
      headerText: t('text:Validated_By'),
      colWidths: 100,
    },
    {
      headerText: t('text:Validation_Date'),
      colWidths: 96,
    },
    {
      headerText: t('text:Number_of_Valid_Invoices'),
      colWidths: 100,
    },
    {
      headerText: t('text:Total_Amount_of_Valid_Invoices'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <HeaderTitle title={t('text:Invoice_Confirmation')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemove}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Initiated_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="createdDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="createdDateTo" control={control} />
              <SearchEmpty />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name="anchorContractNo" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchLabel label={t('text:BOE_Number')} />
              <SearchInput name="billOfExchangeNo" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Registration_Rejection')} />
              <SearchSelect
                name="rejected"
                selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                ref={register}
              />
              <SearchLabel
                label={t('text:Registration_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickInvoiceConfirmationStatus}
              />
              <SearchSelect
                name="currentInvoiceApprovalPhaseStatus"
                selectOptions={getSelectOptions<WAITING_INVOICE_APPROVAL_PHASE_STATUS>(
                  'WAITING_INVOICE_APPROVAL_PHASE_STATUS',
                  'ALL',
                  true,
                )}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Validated_By')} />
              <SearchSelect
                name="currentConfirmEnterpriseType"
                selectOptions={getSelectOptions<ENTERPRISE_TYPE>('ENTERPRISE_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchLabel label={t('text:Validation_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="currentConfirmDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="currentConfirmDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Number_of_Valid_Invoices')} />
              <SearchInput name="minimumCurrentApprovalCount" ref={register} col={2} placeholder={t('text:from')} />
              <SearchInput name="maximumCurrentApprovalCount" ref={register} col={2} placeholder={t('text:to')} />
              <SearchLabel label={t('text:Total_Amount_of_Valid_Invoices')} />
              <SearchInput name="minimumCurrentApprovalAmount" ref={register} col={2} placeholder={t('text:from')} />
              <SearchInput name="maximumCurrentApprovalAmount" ref={register} col={2} placeholder={t('text:to')} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {invoiceSummaryPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerInvoiceConfirmationList;
