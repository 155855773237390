import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import type { ATTACHMENT_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type {
  CalculateForMultipleAnchorLoanRequestVO,
  CalculateForMultipleAnchorLoanRequestVOModel,
} from 'models/vo/CalculateForMultipleAnchorLoanRequestVO';
import { formattingToCalculateForMultipleAnchorLoanRequestVOModel } from 'models/vo/CalculateForMultipleAnchorLoanRequestVO';
import type {
  MultipleAnchorLoanRequestDetailVO,
  MultipleAnchorLoanRequestDetailVOModel,
} from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import { formattingToMultipleAnchorLoanRequestDetailVOModel } from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import type {
  MultipleAnchorLoanRequestRelatedAnchorLoanVO,
  MultipleAnchorLoanRequestRelatedAnchorLoanVOModel,
} from 'models/vo/MultipleAnchorLoanRequestRelatedAnchorLoanVO';
import { formattingToMultipleAnchorLoanRequestRelatedAnchorLoanVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedAnchorLoanVO';
import { formattingToMultipleAnchorLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessArVO';
import type {
  MultipleAnchorLoanRequestRelatedSuccessArVO,
  MultipleAnchorLoanRequestRelatedSuccessArVOModel,
} from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessArVO';
import { formattingToMultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessInvoiceVO';
import type {
  MultipleAnchorLoanRequestRelatedSuccessInvoiceVO,
  MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel,
} from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessInvoiceVO';
import type {
  MultipleAnchorLoanRequestVO,
  MultipleAnchorLoanRequestVOModel,
} from 'models/vo/MultipleAnchorLoanRequestVO';
import { formattingToMultipleAnchorLoanRequestVOModel } from 'models/vo/MultipleAnchorLoanRequestVO';
import http, { ContentType } from 'utils/http';
import API_AC from 'utils/http/api/anchor';


import type {
  AnchorMultipleLoanCalculateSuccessArsRequest,
  AnchorMultipleLoanCalculateSuccessInvoicesRequest,
  AnchorMultipleLoanSuccessArsRequest,
  AnchorMultipleLoanSuccessInvoicesRequest,
} from './requests';
import type { AnchorMultipleLoanListRequest } from './requests';

export async function requestAnchorMultipleLoanList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorMultipleLoanListRequest,
): Promise<Pageable<MultipleAnchorLoanRequestVOModel[]>> {
  const response = await http.get<Pageable<MultipleAnchorLoanRequestVO[]>>({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.MULTIPLE_REQUEST_ANCHOR_LOAN,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return { ...response, content: response.content.map(data => formattingToMultipleAnchorLoanRequestVOModel(data)) };
}

// AC 복수 대출 상세 조회
export async function requestAnchorMultipleLoanDetail(
  multipleRequestAnchorLoanId: number,
): Promise<MultipleAnchorLoanRequestDetailVOModel> {
  const response = await http.get<MultipleAnchorLoanRequestDetailVO>({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.MULTIPLE_REQUEST_ANCHOR_LOANS_DETAIL(multipleRequestAnchorLoanId),
  });

  return formattingToMultipleAnchorLoanRequestDetailVOModel(response);
}

// AC 복수 매출채권 대출 신청
export async function requestAnchorMultipleRequestLoanRequestSuccessArs(data: AnchorMultipleLoanSuccessArsRequest) {
  return await http.post({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.REQUEST_SUCCESS_ARS,
    data,
    contentType: ContentType.MULTIPART,
  });
}

export async function requestAnchorMultipleRequestLoanRequestCalculateSuccessArs(
  data: AnchorMultipleLoanCalculateSuccessArsRequest,
): Promise<CalculateForMultipleAnchorLoanRequestVOModel> {
  const response = await http.post<CalculateForMultipleAnchorLoanRequestVO>({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.CALCULATE_SUCCESS_ARS,
    data,
  });

  return formattingToCalculateForMultipleAnchorLoanRequestVOModel(response);
}

// AC 복수 송장 대출 신청
export async function requestAnchorMultipleRequestLoanRequestSuccessInvoices(
  data: AnchorMultipleLoanSuccessInvoicesRequest,
) {
  return await http.post({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.REQUEST_SUCCESS_INVOICES,
    data,
    contentType: ContentType.MULTIPART,
  });
}

export async function requestAnchorMultipleRequestLoanRequestCalculateSuccessInvoices(
  data: AnchorMultipleLoanCalculateSuccessInvoicesRequest,
): Promise<CalculateForMultipleAnchorLoanRequestVOModel> {
  const response = await http.post<CalculateForMultipleAnchorLoanRequestVO>({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.CALCULATE_SUCCESS_INVOICES,
    data,
  });

  return formattingToCalculateForMultipleAnchorLoanRequestVOModel(response);
}

//  복수 대출 신청 요청 승인
export async function requestAnchorMultipleRequestLoanRequestApprove(
  multipleRequestAnchorLoanId: number,
  anchorAgreementId: number,
  data?: UserVerificationCodeRequest,
) {
  return await http.put({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.APPROVE(multipleRequestAnchorLoanId),
    data: {
      ...data,
      anchorAgreementId,
    },
  });
}

export async function requestAnchorMultipleRequestLoanRequestCancel(
  multipleRequestAnchorLoanId: number,
  cancelReason: string,
) {
  return await http.put({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.CANCEL(multipleRequestAnchorLoanId),
    data: {
      cancelReason,
    },
  });
}

//  복수 대출 관련 파일 다운로드
export async function requestAnchorMultipleRequestLoanRequestDownloadAttachment(
  multipleRequestAnchorLoanId: number,
  attachmentType: ATTACHMENT_TYPE,
  anchorLoanEtcAttachmentId?: number,
) {
  return await http.download(API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.DOWNLOAD_ATTACHMENT(multipleRequestAnchorLoanId), {
    attachmentType,
    anchorLoanEtcAttachmentId,
  });
}

export async function requestAnchorMultipleLoanListRelatedArs(
  multipleRequestAnchorLoanId: number,
): Promise<Pageable<MultipleAnchorLoanRequestRelatedSuccessArVOModel[]>> {
  const response = await http.get<Pageable<MultipleAnchorLoanRequestRelatedSuccessArVO[]>>({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.ARS(multipleRequestAnchorLoanId),
  });

  return {
    ...response,
    content: response.content.map(data => formattingToMultipleAnchorLoanRequestRelatedSuccessArVOModel(data)),
  };
}

export async function requestAnchorMultipleLoanListRelatedInvoices(
  multipleRequestAnchorLoanId: number,
): Promise<Pageable<MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel[]>> {
  const response = await http.get<Pageable<MultipleAnchorLoanRequestRelatedSuccessInvoiceVO[]>>({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.INVOICES(multipleRequestAnchorLoanId),
  });

  return {
    ...response,
    content: response.content.map(data => formattingToMultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel(data)),
  };
}

export async function requestAnchorMultipleLoanListRelatedLoan(
  multipleRequestAnchorLoanId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<MultipleAnchorLoanRequestRelatedAnchorLoanVOModel[]>> {
  const response = await http.get<Pageable<MultipleAnchorLoanRequestRelatedAnchorLoanVO[]>>({
    url: API_AC.MULTIPLE_REQUEST_ANCHOR_LOANS.LOANS(multipleRequestAnchorLoanId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToMultipleAnchorLoanRequestRelatedAnchorLoanVOModel(data)),
  };
}
