import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { SUPPORTED_COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementForRequestMultipleLoanVOModel } from 'models/vo/DealerAgreementForRequestMultipleLoanVO';
import type { SuccessArForRequestLoanVOModel } from 'models/vo/SuccessArForRequestLoanVO';
import type { SuccessInvoiceForRequestLoanVOModel } from 'models/vo/SuccessInvoiceForRequestLoanVO';
import { requestFinancierSupportData } from 'utils/http/api/common/financier-common-setting';
import { requestDealerAgreementListForMultipleRequestLoans } from 'utils/http/api/dealer/dealer-agreements';
import { requestDealerSuccessArsListForRequestLoan } from 'utils/http/api/dealer/success-ars';
import { requestDealerLoanSuccessInvoiceList } from 'utils/http/api/dealer/success-invoices';
import { wrappingGetInterestRate } from 'utils/logic';
import useModal from 'utils/modal/useModal';
import { getCollateralType } from 'utils/storage/LocalStorage';

function DealerFinancingApplicationStep1() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable: invoicePageable, setPageable: setInvoicePageable } = usePageable();
  const { pageable: arPageable, setPageable: setArPageable } = usePageable();
  const { pageable: bulkArPageable, setPageable: setBulkArPageable } = usePageable();
  const modal = useModal();
  const collateralType = getCollateralType();

  const [invoicePage, setInvoicePage] = useState<Pageable<SuccessInvoiceForRequestLoanVOModel[]>>();
  const [arPage, setArPage] = useState<Pageable<SuccessArForRequestLoanVOModel[]>>();
  const [bulkArPage, setBulkArPage] = useState<Pageable<DealerAgreementForRequestMultipleLoanVOModel[]>>();
  const [factoringEnable, setFactoringEnable] = useState<boolean>();

  useEffect(() => {
    if (mounted) {
      fetchOnMount();
    }
  }, [mounted]);

  async function fetchOnMount() {
    const { factoringEnable } = await requestFinancierSupportData();
    setFactoringEnable(factoringEnable);

    if (collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE) {
      fetchInvoiceList();
    } else if (collateralType === SUPPORTED_COLLATERAL_TYPE.AR) {
      ReactDOM.unstable_batchedUpdates(() => {
        if (factoringEnable) {
          fetchArList();
          fetchBulkArList();
        } else {
          fetchArList();
        }
      });
    } else {
      fetchAll();
    }
  }

  async function fetchAll(pageNumber: number = 1, sizePerPage: number = 10) {
    try {
      const [invoicePage, arPage, bulkArPage] = await Promise.all([
        requestDealerLoanSuccessInvoiceList(pageNumber, sizePerPage, {}),
        requestDealerSuccessArsListForRequestLoan(pageNumber, sizePerPage, {}),
        requestDealerAgreementListForMultipleRequestLoans(pageNumber, sizePerPage),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setInvoicePage(invoicePage);
        setInvoicePageable(invoicePage);
        setArPage(arPage);
        setArPageable(arPage);
        setBulkArPage(bulkArPage);
        setBulkArPageable(bulkArPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchInvoiceList = async (pageNumber: number = 1, sizePerPage: number = 10) => {
    try {
      const invoicePage = await requestDealerLoanSuccessInvoiceList(pageNumber, sizePerPage, {});
      ReactDOM.unstable_batchedUpdates(() => {
        setInvoicePage(invoicePage);
        setInvoicePageable(invoicePage);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchArList = async (pageNumber: number = 1, sizePerPage: number = 10) => {
    try {
      const arPage = await requestDealerSuccessArsListForRequestLoan(pageNumber, sizePerPage, {});
      ReactDOM.unstable_batchedUpdates(() => {
        setArPage(arPage);
        setArPageable(arPage);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchBulkArList = async (pageNumber: number = 1, sizePerPage: number = 10) => {
    try {
      const bulkArPage = await requestDealerAgreementListForMultipleRequestLoans(pageNumber, sizePerPage);
      ReactDOM.unstable_batchedUpdates(() => {
        setBulkArPage(bulkArPage);
        setBulkArPageable(bulkArPage);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const getStepWizardText = (): string => {
    switch (collateralType) {
      case SUPPORTED_COLLATERAL_TYPE.BOTH:
        return t('text:Select_AR_or_Invoice');
      case SUPPORTED_COLLATERAL_TYPE.AR:
        return t('text:Select_AR');
      case SUPPORTED_COLLATERAL_TYPE.INVOICE:
        return t('text:Select_Invoice');
      default:
        return '';
    }
  };

  const renderArTable = () => {
    const tableHeaders: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:AR_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:AR_Issued_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Settlement_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 100,
      },
      {
        headerText: t('text:AR_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Available_Request'),
        colWidths: 120,
      },
      {
        headerText: t('text:Discount_Rate_APR'),
        colWidths: 120,
      },
      {
        headerText: '',
        colWidths: 80,
      },
    ];

    const renderResultTable = () => {
      return arPage?.content.map((item, i) => {
        return (
          <Tr key={i}>
            <Td data={item.financierName} />
            <Td data={item.anchorName} />
            <Td data={item.arNumber} />
            <Td data={item.arIssuedDate} format="date" />
            <Td data={item.settlementDate} format="date" />
            <Td data={item.currencyType} />
            <Td data={item.arAmount} format="number" />
            <Td data={item.remainingArAmount} format="number" />
            <Td
              data={wrappingGetInterestRate({
                showExpectedTotalInterestRate: item.showExpectedTotalInterestRate,
                hasBasisInterest: item.hasBasisInterest,
                basisInterestRate: item.basisInterestRate,
                basisInterestBankCode: item.basisInterestBankCode,
                creditInterestRate: item.creditInterestRate,
                termSpreadRate: item.termSpreadRate,
                preferentialInterestRate: item.preferentialInterestRate,
              })}
            />
            <Td className="information-table-more">
              <Button
                size={ButtonSizeEnum.XS}
                bold
                to={ROUTES_DE.MANAGE_FINANCING.APPLICATION_AR_STEP2_BUILD_PATH(item.successArId)}
                state={{
                  successArId: item.successArId,
                }}
              >
                {t('text:Select')}
              </Button>
            </Td>
          </Tr>
        );
      });
    };

    return (
      <>
        {factoringEnable && (
          <GuideMessage
            message={[
              t('text:This_tab_is_a_page_to_apply_for_financing_for_one_AR'),
              t('text:To_apply_for_financing_for_multiple_ARs_at_once_go_to_the_Bulk_Application_tab_on_the_right'),
            ]}
          />
        )}
        <div className="content-area">
          <TableBorder>
            <TableHeader header={tableHeaders} />
            <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
          </TableBorder>
          <Pagination pageable={arPageable} paginate={fetchArList} />
        </div>
      </>
    );
  };

  const renderBulkArTable = () => {
    const tableHeaders: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Associated_Anchor_Master_Agreement_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Partner_Master_Agreement_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 100,
      },
      {
        headerText: t('text:Total_Amount_of_AR'),
        colWidths: 100,
      },
      {
        headerText: t('text:LTV(%)'),
        colWidths: 100,
      },
      {
        headerText: t('text:Total_Number_of_AR'),
        colWidths: 100,
      },
      {
        headerText: '',
        colWidths: 80,
      },
    ];

    const renderResultTable = () => {
      return bulkArPage?.content.map((item, i) => {
        return (
          <Tr key={i}>
            <Td data={item.financierName} />
            <Td data={item.anchorClientName} />
            <Td data={item.anchorAgreementContractNo} />
            <Td data={item.dealerAgreementContractNo} />
            <Td data={item.currencyType} />
            <Td data={item.totalAmount} format="number" />
            <Td data={item.maxLtvRatio} format="number" />
            <Td data={item.totalCount} />
            <Td className="information-table-more">
              <Button
                size={ButtonSizeEnum.XS}
                bold
                to={ROUTES_DE.MANAGE_FINANCING.BULK_APPLICATION_AR_STEP2_BUILD_PATH(item.dealerAgreementId)}
                state={{
                  dealerAgreementId: item.dealerAgreementId,
                  financierId: item.financierId,
                }}
              >
                {t('text:Select')}
              </Button>
            </Td>
          </Tr>
        );
      });
    };

    return (
      <div className="content-area">
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={bulkArPageable} paginate={fetchBulkArList} />
      </div>
    );
  };

  const renderInvoiceTable = () => {
    const tableHeaders: HeaderType[] = [
      {
        headerText: t('text:Financier_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Invoice_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Invoice_Reference_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 100,
      },
      {
        headerText: t('text:Invoice_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Invoice_Issued_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Payment_Date'),
        colWidths: 100,
      },
      {
        headerText: '',
        colWidths: 80,
      },
    ];

    const renderResultTable = () => {
      return invoicePage?.content.map((item, i) => {
        return (
          <Tr key={i}>
            <Td data={item.financierName} />
            <Td data={item.anchorClientName} />
            <Td data={item.invoiceNumber} />
            <Td data={item.referenceNumber} />
            <Td data={item.currencyType} />
            <Td data={item.invoiceAmount} format="number" />
            <Td data={item.invoiceIssuedDate} format="date" />
            <Td data={item.settlementDate} format="date" />
            <Td className="information-table-more">
              <Button
                size={ButtonSizeEnum.XS}
                bold
                to={ROUTES_DE.MANAGE_FINANCING.APPLICATION_INVOICE_STEP2_BUILD_PATH(item.successInvoiceId)}
                state={{
                  dealerAgreementId: item.dealerAgreementId,
                  successInvoiceId: item.successInvoiceId,
                  financierEnterpriseId: item.financierEnterpriseId,
                }}
              >
                {t('text:Select')}
              </Button>
            </Td>
          </Tr>
        );
      });
    };

    return (
      <div className="content-area">
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={invoicePageable} paginate={fetchInvoiceList} />
      </div>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing_Application')} />
      <div className="content-area">
        <StepWizard nth={1} title={[getStepWizardText(), t('text:Financing_Request'), t('text:Review_Application')]} />
      </div>
      <Tab
        tabList={[
          ...(collateralType !== SUPPORTED_COLLATERAL_TYPE.AR
            ? [{ tabName: t('text:Invoice'), tabItemCount: invoicePage?.totalElements ?? 0 }]
            : []),
          ...(collateralType !== SUPPORTED_COLLATERAL_TYPE.INVOICE
            ? [{ tabName: t('text:AR'), tabItemCount: arPage?.totalElements ?? 0 }]
            : []),
          ...(factoringEnable
            ? [{ tabName: t('text:Bulk_Application'), tabItemCount: bulkArPage?.totalElements ?? 0 }]
            : []),
        ]}
        tabViewList={[
          ...(collateralType !== SUPPORTED_COLLATERAL_TYPE.AR ? [renderInvoiceTable()] : []),
          ...(collateralType !== SUPPORTED_COLLATERAL_TYPE.INVOICE ? [renderArTable()] : []),
          ...(factoringEnable ? [renderBulkArTable()] : []),
        ]}
      />
    </>
  );
}

export default DealerFinancingApplicationStep1;
