import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import type { SelectOptionType } from 'components/stateless/Select/Select';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { COLLATERAL_TYPE, DEALER_IDENTIFIER_TYPE } from 'enums';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import { PAGE_TYPE } from '../../../models/extraInformation/Context';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useGeneralInformationController = () => {
  const { t } = useTranslation(['format']);
  const { supportedCollateralType, pageType, useAgreementInterface } = useExtraInformationViewModel();
  const { agreement, isFirstRegisteredWaitingAgreement } = useAgreementViewModel();

  const {
    isEditable,
    methods: { register, setValue, clearErrors },
  } = useFormContext();

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;

  const DealerIdentifierTypeDescription = (() => {
    switch (supportedCollateralType) {
      case COLLATERAL_TYPE.AR:
        return t('text:Anchors_will_use_this_to_distinguish_partners_when_registering_AR');
      case COLLATERAL_TYPE.INVOICE:
        return t('text:Anchors_will_use_this_to_distinguish_partners_when_registering_invoices');
      default:
        return null;
    }
  })();

  const isCurrencyTypeReadOnly = (() => {
    // 전문 - fetchReadOnly
    if (useAgreementInterface) return false;

    switch (pageType) {
      case PAGE_TYPE.REGISTER:
        return false;
      case PAGE_TYPE.REGISTERED_DETAIL:
        return true;
      case PAGE_TYPE.WAITING_DETAIL:
        return !isFirstRegisteredWaitingAgreement;
      default:
        return false;
    }
  })();

  const isAnchorAgreementStatusRender = (() => {
    switch (pageType) {
      case PAGE_TYPE.REGISTER:
        return false;
      case PAGE_TYPE.REGISTERED_DETAIL:
        return true;
      case PAGE_TYPE.WAITING_DETAIL:
        return !isFirstRegisteredWaitingAgreement;
      default:
        return false;
    }
  })();

  const defaultDealerIdentifierTypeValue =
    supportedCollateralType === COLLATERAL_TYPE.AR ? DEALER_IDENTIFIER_TYPE.TAX_CODE : undefined;

  const selectCurrencyTypeOptions: SelectOptionType[] =
    pageType === PAGE_TYPE.REGISTER
      ? getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')
      : getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', [agreement.currencyType]);

  const handleCurrencyTypeChange = useCallback(() => {
    setValue('collateralIssuedLimitAmount', null);
    setValue('loanLimitAmount', null);

    clearErrors(['collateralIssuedLimitAmount', 'loanLimitAmount']);
  }, [clearErrors, setValue]);

  return {
    supportedCollateralType,
    isEditable,
    isCurrencyTypeReadOnly,
    isAnchorAgreementStatusRender,
    DealerIdentifierTypeDescription,
    defaultDealerIdentifierTypeValue,
    selectCurrencyTypeOptions,
    currencyType: agreement.currencyType,
    handleCurrencyTypeChange,
    register,
    isInvoice,
  };
};

export default useGeneralInformationController;
