import type { InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import type { FieldError } from 'react-hook-form';

import clsx from 'clsx';
import { isNil, isUndefined } from 'lodash-es';

import i18n from 'locales/i18n';

import { FormErrorMessage } from './FormErrorMessage';
import { useUppercaseInput } from '../../../hooks/useUppercaseInput';
import Value from '../Form/Value/Value';

interface RequiredOptionType {
  required?: boolean;
  fixedRequired?: boolean;
}

export interface FormInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'required' | 'type'> {
  label: string;
  col?: number;
  children?: ReactNode;
  hidden?: boolean;
  error?: FieldError;
  maxLength?: number;
  type?: 'text' | 'password';
  requiredOptions?: RequiredOptionType;
  labelClassName?: string;
  isShouldShowDash?: boolean;
  applyUpperCase?: boolean;
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>((props, ref) => {
  const {
    label,
    disabled = false,
    type = 'text',
    onChange = () => {},
    col = 6,
    placeholder = i18n.t('text:Please_type_here'),
    children,
    value,
    defaultValue,
    hidden = false,
    error,
    maxLength = 2000,
    requiredOptions,
    labelClassName = '',
    autoComplete = 'off',
    isShouldShowDash = false,
    applyUpperCase = false,
    ...restProps
  } = props;

  const { required = false, fixedRequired = false } = requiredOptions ?? {};
  const isRequiredLabel = (!disabled && required) || fixedRequired;

  const shouldShowDash = isShouldShowDash && disabled && !value && !defaultValue;

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  if (!isUndefined(value) && !isUndefined(defaultValue)) {
    throw new Error("Both 'value' and 'defaultValue' props cannot be provided at the same time.");
  }

  const handleUppercaseInput = useUppercaseInput();

  const renderLabel = () => {
    return (
      <label
        className={`information-form__label ${isRequiredLabel && 'star'} ${labelClassName}`}
        style={label === '' ? { minHeight: '15px' } : undefined}
      >
        {label}
      </label>
    );
  };

  const renderInput = () => {
    return (
      <div className="d-flex">
        <div
          className={clsx('w-100', {
            'd-none': !shouldShowDash,
          })}
        >
          <Value value="-" />
        </div>
        <input
          type={type}
          autoComplete={autoComplete}
          className={clsx('information-form__input', {
            'error-input-border': error,
            'd-none': shouldShowDash,
          })}
          disabled={disabled}
          placeholder={disabled ? '' : placeholder}
          onChange={onChange}
          onInput={applyUpperCase ? handleUppercaseInput : undefined}
          onWheelCapture={e => {
            e.currentTarget.blur();
          }}
          ref={ref}
          maxLength={maxLength}
          {...(!isNil(defaultValue) && { defaultValue })}
          {...(!isNil(value) && { value })}
          {...restProps}
        />
        {children}
      </div>
    );
  };

  return (
    <div className={`col-${col} ${hidden && 'd-none'}`}>
      {renderLabel()}
      {renderInput()}
      <FormErrorMessage error={error} />
    </div>
  );
});

FormInput.displayName = 'FormInput';
export default FormInput;
