import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';

import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import { ROUTES_DE } from 'constants/routes/dealer';
import type { DOCUMENT_FORM_TYPE } from 'enums';
import { AUTHORITY_TYPE, LOAN_TERM_TYPE, OTP_TYPE } from 'enums';
import { CollateralExceptionCode } from 'enums/exception';
import useBeforeUnload from 'hooks/useBeforeUnload';
import useLocationState from 'hooks/useLocationState';
import type Pageable from 'models/Pageable';
import type { CalculateForLoanRequestVOModel } from 'models/vo/CalculateForLoanRequestVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import type { TempLoanVOModel } from 'models/vo/TempLoanVO';
import { convertToServerDateFormat, getDayTerm, removeRangeFromHoliday } from 'utils/date/date';
import { HttpError } from 'utils/error/HttpError';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestDealerClientAuthByDealerAgreementId } from 'utils/http/api/dealer/client-auth-setting';
import { requestDealerAgreementDetail } from 'utils/http/api/dealer/dealer-agreements';
import { requestDealerFinancierCalendar } from 'utils/http/api/dealer/financier-calendar';
import { requestDealerFinancierDocumentList } from 'utils/http/api/dealer/financier-document-forms';
import {
  requestDealerInvoiceCalculateLoanRequest,
  requestDealerInvoiceDetail,
  requestDealerLoanRegistration,
  requestDealerTempLoanByInvoice,
  requestDealerTempLoanByInvoiceRegistration,
} from 'utils/http/api/dealer/success-invoices';
import type { DealerSuccessInvoiceLoanRequest } from 'utils/http/api/dealer/success-invoices/request';
import type { CreateDeTempLoanByInvoiceRequest } from 'utils/http/api/dealer/success-invoices/request';
import { wrappingGetInterestRate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import type { EtcFileRowTypes } from './DealerInvoiceFinancingApplicationStep2';

export interface DealerInvoiceApplicationStep2State {
  dealerAgreementDetail: DealerAgreementDetailVOModel;
  dealerInvoiceDetail: SuccessInvoiceDetailVOModel;
  financierHoliday: Pageable<FinancierCalenderVOModel[]>;
  documentTypes: DOCUMENT_FORM_TYPE[];
  dealerTempLoanByInvoice: TempLoanVOModel;
  fiCommonSettingData: FinancierCommonSettingVOModel;
  dealerInvoiceCalculateLoanRequest?: CalculateForLoanRequestVOModel;
  minDate?: Date;
  maxDate?: Date;
  maxLoanableAmount?: number;
  expectedInterestRate?: string;
}

export function useDealerInvoiceFinancingApplicationStep2State() {
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation();
  const userAuthorityType = getSignIn()?.authorityType;

  const verificationCode: UserVerificationCodeRequest = {};

  const { show: showModal, id: modalId, close: closeModal } = useModal();
  const todayDisburseDate = dayjs().format('YYYY-MM-DD');

  const [locationState] = useLocationState<{
    dealerAgreementId: number;
    successInvoiceId: number;
    financierEnterpriseId: number;
  }>(['dealerAgreementId', 'successInvoiceId', 'financierEnterpriseId']);
  const { dealerAgreementId, successInvoiceId, financierEnterpriseId } = locationState;

  const [dataState, setDataState] = useState<DealerInvoiceApplicationStep2State>({
    dealerAgreementDetail: {} as DealerAgreementDetailVOModel,
    dealerInvoiceDetail: {} as SuccessInvoiceDetailVOModel,
    financierHoliday: {} as Pageable<FinancierCalenderVOModel[]>,
    documentTypes: [] as DOCUMENT_FORM_TYPE[],
    dealerInvoiceCalculateLoanRequest: {} as CalculateForLoanRequestVOModel,
    dealerTempLoanByInvoice: {} as TempLoanVOModel,
    fiCommonSettingData: {} as FinancierCommonSettingVOModel,
  });

  const [etcFileRows, setEtcFileRows] = useState<EtcFileRowTypes[]>([
    {
      id: 0,
      tempLoanEtcAttachmentId: undefined,
      fileName: undefined,
      changed: false,
    },
  ]);
  const [loanAgreementAttachmentChanged, setLoanAgreementAttachmentChanged] = useState(false);
  const [loanRequestAttachmentChanged, setLoanRequestAttachmentChanged] = useState(false);

  const dealerInvoiceApplicationStep2UseForm = useForm<DealerSuccessInvoiceLoanRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const {
    reset,
    getValues,
    watch,
    formState: { dirtyFields },
  } = dealerInvoiceApplicationStep2UseForm;

  const ignoreNavigationGuardRef = useRef(false);
  const saveBtnClickRef = useRef(false);
  const activeNavigationGuard = !saveBtnClickRef.current && !isEmpty(dirtyFields);

  useBeforeUnload(activeNavigationGuard, [saveBtnClickRef.current, dirtyFields]);

  const calculateRepaymentDate = (
    loanTermType: string,
    scheduledRepaymentDate: string | undefined,
    repaymentDate: string | undefined,
  ) => {
    let calculatedRepaymentDate: string;
    if (loanTermType === LOAN_TERM_TYPE.RANGE) {
      if (repaymentDate) {
        calculatedRepaymentDate = repaymentDate;
      } else {
        calculatedRepaymentDate = todayDisburseDate;
      }
    } else {
      calculatedRepaymentDate = scheduledRepaymentDate!;
    }

    return calculatedRepaymentDate;
  };

  const fetchDealerInvoiceCalculateLoanRequest = async () => {
    const { creditInterestRate, preferentialInterestRate, loanTermType } = dataState.dealerAgreementDetail;
    const { scheduledRepaymentDate } = dataState.dealerInvoiceDetail;
    const { repaymentDate, requestedLoanAmount } = watch();

    try {
      const calculatedRepaymentDate = calculateRepaymentDate(loanTermType, scheduledRepaymentDate, repaymentDate);

      const fetchCalculateLoan = await requestDealerInvoiceCalculateLoanRequest(
        successInvoiceId,
        calculatedRepaymentDate,
        requestedLoanAmount,
      );
      const {
        showExpectedTotalInterestRate,
        hasBasisInterest,
        basisInterestBankCode,
        basisInterestRate,
        termSpreadRate,
      } = fetchCalculateLoan;

      setDataState(prevState => ({
        ...prevState,
        dealerInvoiceCalculateLoanRequest: fetchCalculateLoan,
        expectedInterestRate: wrappingGetInterestRate({
          showExpectedTotalInterestRate,
          hasBasisInterest,
          basisInterestBankCode,
          basisInterestRate,
          termSpreadRate,
          creditInterestRate,
          preferentialInterestRate,
        }),
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchAll = async () => {
    const documentTypeArr: DOCUMENT_FORM_TYPE[] = [];

    try {
      const dealerInvoiceData = await requestDealerInvoiceDetail(successInvoiceId);
      const [dealerAgreementData, fetchedFinancierDocumentList, dealerTempLoanByInvoiceData, fiCommonSettingData] =
        await Promise.all([
          requestDealerAgreementDetail(dealerAgreementId),
          requestDealerFinancierDocumentList(financierEnterpriseId),
          requestDealerTempLoanByInvoice(successInvoiceId),
          requestFinancierSettingData(dealerInvoiceData.financierEnterpriseId),
        ]);

      const minDate = dayjs(dealerInvoiceData.settlementDate)
        .add(dealerAgreementData.minimumLoanRange, 'days')
        .toDate();

      const maxDate = dayjs(dealerInvoiceData.settlementDate)
        .add(dealerAgreementData.maximumLoanRange, 'days')
        .toDate();

      const fetchFinancierHoliday = await requestDealerFinancierCalendar(financierEnterpriseId, {
        pageNumber: 0,
        rowCount: Number(getDayTerm(convertToServerDateFormat(minDate), convertToServerDateFormat(maxDate))) + 1,
        fromDate: convertToServerDateFormat(minDate),
        toDate: convertToServerDateFormat(maxDate),
        holiday: true,
      });

      const fetchCalculateLoan = await requestDealerInvoiceCalculateLoanRequest(
        successInvoiceId,
        calculateRepaymentDate(
          dealerAgreementData.loanTermType,
          dealerInvoiceData.scheduledRepaymentDate,
          watch().repaymentDate,
        ),
        dealerInvoiceData?.invoiceAmount,
      );

      fetchedFinancierDocumentList.content.forEach(item => documentTypeArr.push(item.documentFormType));

      const dataState = {
        dealerAgreementDetail: dealerAgreementData,
        dealerInvoiceDetail: dealerInvoiceData,
        financierHoliday: fetchFinancierHoliday,
        documentTypes: documentTypeArr,
        dealerTempLoanByInvoice: dealerTempLoanByInvoiceData,
        fiCommonSettingData,
        minDate,
        maxDate,
        maxLoanableAmount: fetchCalculateLoan.maxLoanableAmount,
      };

      setDataState(prevState => ({
        ...prevState,
        ...dataState,
      }));

      initialResetForm(dataState);
    } catch (e) {
      modal.show(e);
    }
  };

  const initialResetForm = (dataState: DealerInvoiceApplicationStep2State) => {
    const {
      dealerAgreementDetail,
      dealerInvoiceDetail,
      financierHoliday,
      dealerTempLoanByInvoice,
      fiCommonSettingData,
      maxLoanableAmount,
    } = dataState;
    // Form reset 용 객체
    const dealerLoanByInvoiceData = getValues();

    //  requestedLoanAmount setting
    let requestedLoanAmount;

    if (fiCommonSettingData.invoiceFinancingAmountAdjustable && maxLoanableAmount) {
      requestedLoanAmount =
        dealerTempLoanByInvoice.requestedLoanAmount > maxLoanableAmount ||
        dealerTempLoanByInvoice.requestedLoanAmount <= 0
          ? undefined
          : dealerTempLoanByInvoice.requestedLoanAmount;
    } else {
      requestedLoanAmount = maxLoanableAmount;
    }

    dealerLoanByInvoiceData.requestedLoanAmount = requestedLoanAmount!;

    //  repaymentDate setting
    let repaymentDate;

    if (dealerAgreementDetail.loanTermType === LOAN_TERM_TYPE.RANGE) {
      repaymentDate = isValidTempRepaymentDate({
        invoiceSettlementDate: dealerInvoiceDetail.settlementDate,
        minimumLoanRange: dealerAgreementDetail.minimumLoanRange,
        maximumLoanRange: dealerAgreementDetail.maximumLoanRange,
        repaymentDate: dealerTempLoanByInvoice.repaymentDate,
        financierHoliday: financierHoliday.content,
      })
        ? dealerTempLoanByInvoice.repaymentDate
        : undefined;
    } else {
      repaymentDate = dealerInvoiceDetail.scheduledRepaymentDate;
    }
    dealerLoanByInvoiceData.repaymentDate = repaymentDate;

    // invoice Attachment setting
    dealerLoanByInvoiceData.invoiceAttachmentId = dealerTempLoanByInvoice.invoiceAttachmentId;

    // etc Attachment setting
    if (dealerTempLoanByInvoice?.tempLoanEtcAttachmentList?.length !== 0) {
      dealerLoanByInvoiceData.loanEtcAttachmentDescriptions = dealerTempLoanByInvoice?.tempLoanEtcAttachmentList?.map(
        ({ description }) => description,
      );

      dealerLoanByInvoiceData.tempLoanEtcAttachmentId = dealerTempLoanByInvoice?.tempLoanEtcAttachmentList?.map(
        ({ tempLoanEtcAttachmentId }) => tempLoanEtcAttachmentId,
      );
    }

    // execute reset
    reset(dealerLoanByInvoiceData);
  };

  const initializeExpectedInterestRate = () => {
    setDataState(prevState => ({
      ...prevState,
      dealerInvoiceCalculateLoanRequest: undefined,
      expectedInterestRate: undefined,
    }));
  };
  const isValidTempRepaymentDate = ({
    invoiceSettlementDate,
    minimumLoanRange,
    maximumLoanRange,
    repaymentDate,
    financierHoliday,
  }: {
    invoiceSettlementDate: string;
    minimumLoanRange: number;
    maximumLoanRange: number;
    repaymentDate: string;
    financierHoliday: FinancierCalenderVOModel[];
  }) => {
    const getMaturityDateRange = (disbursementDate: string, range: number) => {
      return dayjs(disbursementDate).add(range, 'days').toDate();
    };

    const getRequestedRepaymentDateSelectableRanges = (date: Date): boolean => {
      const startDate = getMaturityDateRange(invoiceSettlementDate, minimumLoanRange);
      const endDate = getMaturityDateRange(invoiceSettlementDate, maximumLoanRange);

      return dayjs(date).isBetween(startDate, endDate, 'date', '[]');
    };
    const getFinancierHolidayDisabledRanges = () => {
      return removeRangeFromHoliday(financierHoliday);
    };

    const isSelectableTempRepaymentDate = () => {
      const tempRepaymentDate = dayjs(repaymentDate).toDate();

      return getRequestedRepaymentDateSelectableRanges(tempRepaymentDate);
    };

    const isDisabledTempRepaymentDate = () => {
      if (isEmpty(getFinancierHolidayDisabledRanges())) return false;

      const ranges = getFinancierHolidayDisabledRanges();
      const tempRepaymentDate = dayjs(repaymentDate).toDate();

      return ranges.some(date => date.getTime() === tempRepaymentDate.getTime());
    };

    return isSelectableTempRepaymentDate() && !isDisabledTempRepaymentDate();
  };

  const manipulateLoanEtcAttachmentRequestData = (
    data: DealerSuccessInvoiceLoanRequest,
    type: 'application' | 'temp',
  ) => {
    const loanEtcAttachmentsArr: FileList[] = [];
    const loanEtcAttachmentDescriptionsArr: string[] = [];
    const loanEtcAttachmentsChangedArr: boolean[] = [];
    const tempLoanEtcAttachmentIdArr: number[] = [];

    const emptyFile = (id: number, fileName: string | undefined) =>
      data.loanEtcAttachments?.[id]?.length === 0 && !fileName;
    const emptyDescription = (id: number) => !data?.loanEtcAttachmentDescriptions?.[id];

    etcFileRows?.forEach(item => {
      if (type === 'application' && emptyFile(item.id, item.fileName)) return;
      if (emptyFile(item.id, item.fileName) && emptyDescription(item.id)) return;
      loanEtcAttachmentsArr.push((data.loanEtcAttachments as FileList[])?.[item.id]);
      loanEtcAttachmentDescriptionsArr.push(data?.loanEtcAttachmentDescriptions?.[item.id] as string);
      tempLoanEtcAttachmentIdArr.push(data?.tempLoanEtcAttachmentId?.[item.id] as number);
      loanEtcAttachmentsChangedArr.push(item.changed);
    });

    data.tempLoanEtcAttachmentId = tempLoanEtcAttachmentIdArr;
    data.loanEtcAttachments = loanEtcAttachmentsArr;
    data.loanEtcAttachmentDescriptions = loanEtcAttachmentDescriptionsArr;
    data.loanEtcAttachmentsChanged = loanEtcAttachmentsChangedArr;
  };

  const manipulateRegistrationRequestData = (): DealerSuccessInvoiceLoanRequest => {
    const data = getValues();
    manipulateLoanEtcAttachmentRequestData(data, 'application');

    data.loanAgreementAttachmentChanged = loanAgreementAttachmentChanged;
    data.loanRequestAttachmentChanged = loanRequestAttachmentChanged;

    data.dealerAgreementId = dealerAgreementId;
    if (userAuthorityType === AUTHORITY_TYPE.AUTHORIZER) {
      data.otpCode = verificationCode.otpCode;
      data.queryValue = verificationCode.queryValue;
    }

    return data;
  };

  const requestRegisterInvoiceLoan = async () => {
    const data = manipulateRegistrationRequestData();

    try {
      requestDTOParser(data);
      await requestDealerLoanRegistration(successInvoiceId, data);
      saveBtnClickRef.current = true;
      showFinancingRequestCompletedModal();
    } catch (e) {
      if (e instanceof HttpError && e.code === CollateralExceptionCode.ALREADY_COLLATERALIZED) {
        ignoreNavigationGuardRef.current = true;
        showModal(e, {
          closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
        });
      } else {
        showModal(e);
      }
    }
  };

  // OTP 이후,
  const showLoanRequestModal = () => {
    showModal(
      <>
        {userAuthorityType === AUTHORITY_TYPE.AUTHORIZER ? (
          <h6>{t('text:Would_you_like_to_submit_the_financing_application?')}</h6>
        ) : (
          <h6>{t('text:Would_you_like_to_submit_the_financing_application_to_the_Authorizer?')}</h6>
        )}
      </>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        confirmBtnCb: requestRegisterInvoiceLoan,
        closeBtnText: t('text:Cancel'),
      },
    );
  };

  const showUserVerificationModal = (clientAuthTypeData: FinancierClientAuthSettingVOModel) => {
    showModal(
      <UserVerificationModal
        modalId={modalId}
        verificationCode={verificationCode}
        requestIdType="dealerAgreementId"
        requestId={dealerAgreementId}
        clientAuthSetting={clientAuthTypeData}
      />,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => {
          if (!verificationCode) {
            closeModal(modalId);
            showModal(t('text:Please_check_the_verification_code_and_enter_it_below'), {
              closeBtnCb: () => {
                showUserVerificationModal(clientAuthTypeData);
              },
            });
          } else {
            showLoanRequestModal();
          }
        },
      },
    );
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    if (userAuthorityType !== AUTHORITY_TYPE.AUTHORIZER) {
      showLoanRequestModal();

      return;
    }

    try {
      const clientAuthTypeData = await requestDealerClientAuthByDealerAgreementId(dealerAgreementId);
      if (clientAuthTypeData.otpType !== OTP_TYPE.NONE) {
        showUserVerificationModal(clientAuthTypeData);
      } else {
        showLoanRequestModal();
      }
    } catch (e) {
      showModal(e);
    }
  };

  const showCancelFinancingModal = () => {
    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_financing_application?')}
        <br />
        {t(
          'text:If_you_cancel_the_financing_application_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        title: t('text:Cancel_Financing_Request'),
        confirmBtnCb: () => {
          ignoreNavigationGuardRef.current = true;
          setTimeout(() => {
            history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1);
          }, 0);
        },
      },
    );
  };

  const manipulateSaveRequestData = (): CreateDeTempLoanByInvoiceRequest => {
    const data = getValues();
    manipulateLoanEtcAttachmentRequestData(data, 'temp');

    data.loanAgreementAttachmentChanged = loanAgreementAttachmentChanged;
    data.loanRequestAttachmentChanged = loanRequestAttachmentChanged;

    return data;
  };

  const successSaveModal = () => {
    showModal(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
      modalType: ModalType.ALERT,
      title: t('text:Notice'),
      closeBtnText: t('text:OK'),
      closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
    });
  };

  const onClickSave = () => {
    showModal(
      <h6>
        {t('text:Would_you_like_to_save_the_financing_information_you_entered?')}
        <br />
        {t('text:Please_note_that_the_financing_application_will_not_be_submitted_until_you_click_the_Register_button')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          try {
            const data = manipulateSaveRequestData();
            await requestDealerTempLoanByInvoiceRegistration(successInvoiceId, data);
            saveBtnClickRef.current = true;
            successSaveModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  const showFinancingRequestCompletedModal = () => {
    showModal(
      <h6>
        {userAuthorityType === AUTHORITY_TYPE.AUTHORIZER ? (
          <>
            {t('text:The_financing_application_has_been_submitted')}
            <br />
            {t(
              'text:You_can_monitor_the_details_and_progress_of_the_financing_applied_from_the_View_Transaction>Financing_menu',
            )}
          </>
        ) : (
          <>
            {t('text:The_financing_application_has_been_submitted_to_the_Authorizer')}
            <br />
            {t('text:The_application_will_be_submitted_to_the_Financier_after_the_Authorizer_s_approval')}
          </>
        )}
      </h6>,
      {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
      },
    );
  };

  const onClickNavigationGuardConfirmBtn = async () => {
    const data = manipulateSaveRequestData();
    await requestDealerTempLoanByInvoiceRegistration(successInvoiceId, data);
    saveBtnClickRef.current = true;
  };

  return {
    state: dataState,
    ignoreNavigationGuardRef,
    fetchAll,
    fetchDealerInvoiceCalculateLoanRequest,
    dealerInvoiceApplicationStep2UseForm,
    etcFileRowsState: {
      etcFileRows,
      setEtcFileRows,
    },
    loanAgreementAttachmentChangedState: {
      loanAgreementAttachmentChanged,
      setLoanAgreementAttachmentChanged,
    },
    loanRequestAttachmentChangedState: { loanRequestAttachmentChanged, setLoanRequestAttachmentChanged },
    actions: {
      showCancelFinancingModal,
      onClickNavigationGuardConfirmBtn,
      onClickSave,
      onFormSubmit,
      activeNavigationGuard,
      initializeExpectedInterestRate,
    },
  };
}
