import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import WithProvider from 'utils/render';
import { getApprovalTypeText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import useRevisionHistoryListController from './useAgreementRevisionHistoryController';
import WaitingAgreementProvider from '../../../models/waitingAgreement/Context';

const AgreementRevisionHistory = () => {
  const { t } = useTranslation(['format']);
  const { REVISION_HISTORY_LIST_HEADERS, waitingAgreement, waitingAgreementPageable, waitingAgreementPaginate } =
    useRevisionHistoryListController();

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Agreement_Revision_History')} />
      <TableBorder>
        <TableHeader header={REVISION_HISTORY_LIST_HEADERS} />
        <TableBody numOfCol={REVISION_HISTORY_LIST_HEADERS.length}>
          {waitingAgreement?.map((history, index) => (
            <Tr key={index}>
              <Td data={history.updatedDateTime} format="datetime" />
              <Td data={history.contractNo} />
              <Td data={history.expiryDate} format="date" />
              <Td data={history.branchCode} />
              <Td data={history.authorizerName} />
              <Td data={history.authorizerMobile} />
              <Td data={history.authorizerEmail} />
              <Td
                className={getStatusTextClass('COMMON_APPROVAL_TYPE', history.approvalType)}
                data={getApprovalTypeText(history.approvalType)}
              />
              <Td
                data={`${tableValueManage(history.operatorUserLoginId)} / ${tableValueManage(
                  history.operatorUserName,
                )}`}
              />
              <Td
                data={`${tableValueManage(history.authorizerUserLoginId)} / ${tableValueManage(
                  history.authorizerUserName,
                )}`}
              />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
      <Pagination pageable={waitingAgreementPageable} paginate={waitingAgreementPaginate} />
    </div>
  );
};

export default WithProvider({
  Component: AgreementRevisionHistory,
  Provider: WaitingAgreementProvider,
});
