import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';

import Button, { ButtonSizeEnum, ButtonVariantEnum } from '../Button/Button';

interface TdLinkPropsType {
  path: string;
  state?: any;
  sticky?: boolean;
}

const TdLink = ({ path, state, sticky = true }: TdLinkPropsType) => {
  return (
    <td
      className={clsx('information-table-more', {
        sticky: sticky,
      })}
    >
      <Button variant={ButtonVariantEnum.OUTLINED} size={ButtonSizeEnum.SM} to={path} state={state}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </td>
  );
};

export default TdLink;
