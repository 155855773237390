import type { HTMLAttributes } from 'react';
import type React from 'react';

type LiteralUnion<T extends U, U = string> = T | (U & Record<never, never>);

interface CardGroupProps extends HTMLAttributes<HTMLDivElement> {
  className: LiteralUnion<'simple-card-left-group' | 'simple-card-right-group'>;
  col?: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

function CardGroup({ className, col = 6, style, children, ...props }: CardGroupProps): JSX.Element {
  return (
    <div className={`${className} col-${col}`} style={style} {...props}>
      {children}
    </div>
  );
}

export default CardGroup;
