import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { isEmpty, isNull } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import { BASIS_INTEREST_TYPE, COLLATERAL_TYPE, INTEREST_REPAYMENT_TYPE } from 'enums';

import useExtraInformationViewModel from '../../extraInformation/useExtraInformationViewModel';
import useFinancingOptionViewModel from '../../models/useFinancingOptionViewModel';
import useTermSpreadViewModel from '../../models/useTermSpreadViewModel';

const useInterestRateController = () => {
  const { supportedCollateralType } = useExtraInformationViewModel();
  const { termSpreadList } = useTermSpreadViewModel();
  const {} = useFinancingOptionViewModel();

  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const [useTermSpread, setUseTermSpread] = useState<boolean | null>(null);

  const {
    methods: { register, errors },
  } = useFormContext();

  const initUseTermSpread = useCallback(() => {
    const updatedUseTermSpread = (termSpreadList?.length ?? 0) > 0;
    setUseTermSpread(updatedUseTermSpread);
  }, [termSpreadList]);

  useEffect(() => {
    initUseTermSpread();
  }, [initUseTermSpread, termSpreadList]);

  return {
    termSpreadList,
    useTermSpread,
    isAr,
  };
};

export default useInterestRateController;
