import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { tableValueManage } from 'utils/valueManager/ValueManager';

import useSessionTimerButtonController from './useSessionTimerButtonController';

const SessionTimerButton = () => {
  const { t } = useTranslation(['format']);
  const { handleRefreshButtonClick, leftSessionExpireTime, signInTime } = useSessionTimerButtonController();

  return (
    <>
      <div className="refresh-session-button-wrapper" data-tip data-for="refresh">
        {leftSessionExpireTime} {t('text:left')}
        <button className="refresh-session-button" onClick={handleRefreshButtonClick}>
          <FontAwesomeIcon icon={faArrowRotateRight} fontSize={14} />
        </button>
      </div>

      <ReactTooltip
        id="refresh"
        effect="solid"
        place="bottom"
        className="react-tooltip--refresh"
        backgroundColor="#333333E5"
        arrowColor="#333333E5"
      >
        {t('text:Sign_in')}{' '}
        <span className="text-bold">
          {tableValueManage(
            t('format:datetime', {
              value: signInTime,
              key: 'datetime',
            }),
          )}
        </span>
      </ReactTooltip>
    </>
  );
};

export default SessionTimerButton;
