import { useTranslation } from 'react-i18next';

function AnchorApRegisterValidationErrorModal() {
  const { t } = useTranslation();

  return (
    <h6>
      {t('text:The_list_of_AP_to_upload_contains_errors')}
      <br />
      {t('text:Please_check_the_Registration_Result_and_continue_registration_again')}
    </h6>
  );
}

export default AnchorApRegisterValidationErrorModal;
