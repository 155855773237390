import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestDealerUserList } from 'utils/http/api/dealer/users';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

function DealerUserList() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();

  const { pageable, setPageable } = usePageable();
  const [userPage, setUserPage] = useState<Pageable<UserVOModel[]>>();
  const signInModel: SignInModel | null = getSignIn();

  useEffect(() => {
    if (mounted) {
      fetchDeUserList(pageable.currentPage, pageable.sizePerPage);
    }

    return () => {};
  }, [mounted]);

  async function fetchDeUserList(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const userPage = await requestDealerUserList(pageNumber, rowCount, {});

      ReactDOM.unstable_batchedUpdates(() => {
        setUserPage(userPage);
        setPageable(userPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = async (page: number, rowCount: number) => {
    await fetchDeUserList(page, rowCount);
  };

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:User_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Employee_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Title'),
      colWidths: 100,
    },
    {
      headerText: t('text:Mobile'),
      colWidths: 85,
    },
    {
      headerText: t('text:Email'),
      colWidths: 120,
    },
    {
      headerText: t('text:Authority'),
      colWidths: 90,
    },
    {
      headerText: t('text:User_Account_Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickUserStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderUserListTable = () => {
    return userPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.loginId} />
          <Td data={item.userName} />
          <Td data={item.employeeCode} />
          <Td data={item.position} />
          <Td data={item.mobile} />
          <Td data={item.email} />
          <Td data={t(`code:authority-type.${item.authorityType}`)} format="code" />
          <Td
            data={t(`code:user-status.${item.userStatus}`)}
            className={getStatusTextClass('USER_STATUS', item?.userStatus)}
          />
          <TdLink path={ROUTES_DE.COMPANY.USER_DETAIL_BUILD_PATH(item.userId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:User')} />
      {signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER && (
        <GuideMessage
          message={[
            t('text:On_this_page_you_can_view_the_list_of_Partner_users_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Partner_users'),
            t('text:Click_on_the_Register_button_to_register_Operator_users'),
          ]}
        >
          {{
            button: <Button onClick={() => history.push(ROUTES_DE.COMPANY.USER_REGISTER)}>{t('text:Register')}</Button>,
          }}
        </GuideMessage>
      )}
      <div className="content-area">
        <SectionTitle title={t('text:User_List')} />
        <p className="total-data">
          {t('text:Total')} {userPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderUserListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerUserList;
