import { useState } from 'react';

import Sidebar from './Sidebar';
import HeaderBar from '../HeaderBar/HeaderBar';

const Navigation = () => {
  const [sideBarCollapsed, setSideBarCollapsed] = useState<boolean>(false);
  const updateSideBarCollapsed = (show: boolean) => setSideBarCollapsed(show);

  return (
    <>
      <HeaderBar sideBarCollapsed={sideBarCollapsed} updateSideBarCollapsed={updateSideBarCollapsed} />
      <Sidebar sideBarCollapsed={sideBarCollapsed} />
    </>
  );
};

export default Navigation;
