import type { HTMLAttributes } from 'react';

import './SkeletonBox.scss';

export interface SkeletonBoxProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  width?: number | string;
  height?: number | string;
}

function SkeletonBox({ className, width, height, children, ...props }: SkeletonBoxProps) {
  return (
    <div
      className={`skeleton-box ${className ? className : ''}`}
      style={{
        width: width,
        height: height,
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export default SkeletonBox;
