import type { ChangeEvent, MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, OTP_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestAnchorWaitingAnchorDealerRegister } from 'utils/http/api/anchor/waiting-anchor-dealers';
import type {
  AnchorWaitingAnchorDealerRequest,
  WaitingAnchorDealers,
} from 'utils/http/api/anchor/waiting-anchor-dealers/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import AnchorDealerRegistrationConfirmModal from '../../../../../components/stateless/Modal/common/dealer/AnchorDealerRegistrationConfirmModal';
import AnchorDealerRegistrationResultModal from '../../../../../components/stateless/Modal/common/dealer/AnchorDealerRegistrationResultModal';

const useAnchorDealerRegistrationStep2Page = () => {
  const [locationState] = useLocationState<{
    financierId: number;
    financierName: string;
  }>(['financierId', 'financierName']);

  const { t } = useTranslation();
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();
  const userAuthority = getSignIn()?.authorityType;

  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [financierClientAuthSetting, setFinancierClientAuthSetting] = useState<FinancierClientAuthSettingVOModel>();

  const formMethods = useForm<{
    waitingAnchorDealers: WaitingAnchorDealers[];
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { reset, control, getValues, trigger } = formMethods;

  const useFieldArrayMethods = useFieldArray<WaitingAnchorDealers>({
    control,
    name: 'waitingAnchorDealers',
  });

  const { fields, append, remove } = useFieldArrayMethods;

  const fetchFinancierClientAuthSetting = useCallback(async () => {
    try {
      const financierClientAuthSettingResponse = await requestAnchorClientAuthByFinancierId(locationState.financierId);
      setFinancierClientAuthSetting(financierClientAuthSettingResponse);
    } catch (error) {
      modal.show(error);
    }
  }, [locationState.financierId]);

  useEffect(() => {
    if (mounted) {
      fetchFinancierClientAuthSetting();
      reset({
        waitingAnchorDealers: [{}],
      });
    }
  }, [fetchFinancierClientAuthSetting, mounted, reset]);

  const validateDuplicateTaxCode = (taxCode: string, index: number): boolean => {
    const { waitingAnchorDealers } = getValues();
    const lowercasedTaxCodes = waitingAnchorDealers.map(({ taxCode }) => taxCode.toLowerCase());
    lowercasedTaxCodes.splice(index, 1);
    const filteredTaxCodes = lowercasedTaxCodes.filter(taxCode => taxCode);
    const isPassedDuplicateValidity = !filteredTaxCodes.includes(taxCode.toLowerCase());

    return isPassedDuplicateValidity;
  };

  const updateCheckedValidation = () => {
    let i = 0;
    const temp = [...checkedValidation];

    for (const index of checkedRows) {
      temp.splice(index - i, 1);
      i++;
    }

    return temp;
  };

  const removeRow = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (checkedRows.length === fields.length) {
      reset({
        waitingAnchorDealers: [{}],
      });
      setCheckedValidation([]);
    } else {
      remove(checkedRows);
      setCheckedValidation(updateCheckedValidation());
    }
    setCheckedRows([]);
  };

  const appendRow = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    append({});
  };

  const handleCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const checkedRows = isChecked ? fields.map((_, index) => index) : [];
    setCheckedRows(checkedRows);
  };

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    if (event.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const handleClickCancelButton = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_AC.MANAGE_DEALER.REGISTRATION_STEP1),
      },
    );
  };

  const isOtpAuthority = userAuthority === AUTHORITY_TYPE.AUTHORIZER || userAuthority === AUTHORITY_TYPE.ADMIN;

  const handleClickRegisterButton = async () => {
    const { waitingAnchorDealers } = getValues();
    const isPassedFrontValidate = await trigger();

    if (!isPassedFrontValidate) {
      setCheckedValidation(waitingAnchorDealers.map(() => true));

      return;
    }

    const registrationRequestSuccessModal = (requestResult: WaitingAnchorDealerVOModel[]) => {
      modal.show(<AnchorDealerRegistrationResultModal requestResult={requestResult} />, {
        modalSize: ModalSize.XL,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => history.push(ROUTES_AC.MANAGE_DEALER.REGISTRATION_LIST),
      });
    };

    const requestDealerRegistration = async (verificationCode?: UserVerificationCodeRequest) => {
      const data: AnchorWaitingAnchorDealerRequest = {
        ...getValues(),
        financierId: locationState.financierId,
        otpCode: verificationCode?.otpCode,
        queryValue: verificationCode?.queryValue,
      };

      try {
        const response = await requestAnchorWaitingAnchorDealerRegister(data);

        registrationRequestSuccessModal(response);
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(<AnchorDealerRegistrationConfirmModal waitingAnchorDealers={waitingAnchorDealers} />, {
      modalSize: ModalSize.XL,
      modalType: ModalType.CONFIRM,
      confirmBtnCb: () => {
        isOtpAuthority && financierClientAuthSetting?.otpType !== OTP_TYPE.NONE
          ? showVerificationOtpModal()
          : requestDealerRegistration();
      },
    });

    const showVerificationOtpModal = () => {
      const verificationCode: UserVerificationCodeRequest = {};

      modal.show(
        <UserVerificationModal
          modalId={modal.id}
          verificationCode={verificationCode}
          requestIdType="financierId"
          requestId={locationState.financierId}
          clientAuthSetting={financierClientAuthSetting as FinancierClientAuthSettingVOModel}
        />,
        {
          modalType: ModalType.CONFIRM,
          title: t('text:User_Verification'),
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: () => requestDealerRegistration(verificationCode),
        },
      );
    };
  };

  return {
    formMethods,
    useFieldArrayMethods,
    appendRow,
    removeRow,
    handleCheckAll,
    handleCheckChange,
    checkedRows,
    validateDuplicateTaxCode,
    handleClickCancelButton,
    handleClickRegisterButton,
    checkedValidation,
  };
};

export default useAnchorDealerRegistrationStep2Page;
