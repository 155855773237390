import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { FINANCIAL_SETTING_TYPE } from 'enums';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierOverdueInterestRateSettingRegister } from 'utils/http/api/financier/overdue-interest-rate-settings';
import type { CreateFiOverdueInterestRateSettingRequest } from 'utils/http/api/financier/overdue-interest-rate-settings/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import CostOfFundForm from '../sections/delinquent-interest-rate-cost-of-fund';
import DelinquentInterestRateTermSpreadForm from '../sections/delinquent-interest-rate-term-spread-form';
import DelinquentInterestRateTypeForm from '../sections/delinquent-interest-rate-type-form';

function FinancierDelinquentInterestRateSettingRegister() {
  const { t } = useTranslation(['format']);
  const history = useHistory();
  const modal = useModal();

  // useForm
  const delinquentInterestRateSettingRegisterForm = useForm<CreateFiOverdueInterestRateSettingRequest>({
    mode: 'onSubmit',
    defaultValues: {
      termSpreads: Array.from({ length: 5 }, () => ({ minimumDateRange: null, termSpreadRate: null })),
    },
  });

  const { setError, clearErrors, getValues } = delinquentInterestRateSettingRegisterForm;
  const handleClickCancel = (e: any) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration_request?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.DELINQUENT_INTEREST_RATE_LIST),
      },
    );
  };

  const handleClickRegister = (e: any) => {
    e.preventDefault();
    const data = getValues();
    const { settingType, anchorAgreementId, dealerAgreementId } = data;
    const isAnchorType = settingType === FINANCIAL_SETTING_TYPE.ANCHOR;
    const isPartnerType = settingType === FINANCIAL_SETTING_TYPE.PARTNER;
    const isNotDefaultType = isPartnerType || isAnchorType;

    if (isNotDefaultType) {
      if ((isAnchorType && !anchorAgreementId) || (isPartnerType && !dealerAgreementId)) {
        modal.show(<h6>{t('text:Press_the_search_button_to_select_an_agreement')}</h6>, {
          modalType: ModalType.ALERT,
        });

        return;
      }
    }

    modal.show(
      <h6>{t('text:Would_you_like_to_request_the_registration_of_the_delinquent_interest_rate_information?')}</h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => {
          requestRegister();
        },
      },
    );

    const requestRegister = async () => {
      const showSubmitSuccessModal = () => {
        modal.show(
          <h6>
            {t('text:The_request_has_been_submitted_successfully')}
            <br />
            {t('text:See_the_request_in_the_Waiting_for_Confirmation_tab')}
          </h6>,
          {
            closeBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.DELINQUENT_INTEREST_RATE_LIST),
          },
        );
      };
      try {
        requestDTOParser(data);
        await requestFinancierOverdueInterestRateSettingRegister(data);
        showSubmitSuccessModal();
      } catch (e: any) {
        modal.show(e);
        formErrorHandler<CreateFiOverdueInterestRateSettingRequest>(e, setError, clearErrors);
      }
    };
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Register_Delinquent_Interest_Rate')} />
      <GuideMessage
        message={[
          t('text:You_can_register_and_check_delinquent_interest_rate_on_this_page'),
          t('text:You_can_set_the_additional_interest_rate_that_will_occur_during_the_delinquent_period'),
          `${t('text:Values_calculated_on_the_platform_may_not_be_accurate')} ${t(
            'text:Pay_attention_to_the_amount_when_entering',
          )}`,
        ]}
      />
      <FormProvider {...delinquentInterestRateSettingRegisterForm}>
        <div className="content-area">
          <SectionTitle title={t('text:Delinquent_Interest_Rate_Type')} />
          <DelinquentInterestRateTypeForm />
        </div>
        <div className="content-area">
          <SectionTitle title={t('text:Delinquent_Interest_Rate_Term_Spread')} />
          <DelinquentInterestRateTermSpreadForm editable />
        </div>
        <div className="content-area">
          <SectionTitle title={t('text:Penalty_Fee')} />
          <CostOfFundForm isEditable />
        </div>
      </FormProvider>
      <div className="content-area flex-end mt-3">
        <Button
          size={ButtonSizeEnum.LG}
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
          onClick={handleClickCancel}
          className="me-3"
        >
          {t('text:Cancel')}
        </Button>
        <Button size={ButtonSizeEnum.LG} onClick={handleClickRegister}>
          {t('text:Register')}
        </Button>
      </div>
    </>
  );
}

export default FinancierDelinquentInterestRateSettingRegister;
