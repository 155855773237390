import { useAccountTransactionsContext } from './Context';

const useAccountTransactionsViewModel = () => {
  const {
    settlementAccountBalance,
    accountTransactions,
    accountTransactionsPageable,
    fetchSettlementAccountBalance,
    fetchAccountTransactions,
  } = useAccountTransactionsContext();

  return {
    settlementAccountBalance,
    accountTransactions,
    accountTransactionsPageable,
    fetchSettlementAccountBalance,
    fetchAccountTransactions,
  };
};

export default useAccountTransactionsViewModel;
