import type Pageable from 'models/Pageable';
import type { EarlyRepaymentRequestVO, EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import { formattingToEarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import type { ExpectedLoanRepaymentVO, ExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import { formattingToExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import type { ExtensionRequestVO, ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import { formattingToExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import type { FactoringAttachmentVO, FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import { formattingToFactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import { formattingToLoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type {
  LoanAdditionalDocumentAttachmentVO,
  LoanAdditionalDocumentAttachmentVOModel,
} from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type { LoanDetailVO, LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import { formattingToLoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { LoanTransactionVO, LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import { formattingToLoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import type { LoanVO, LoanVOModel } from 'models/vo/LoanVO';
import { formattingToLoanVOModel } from 'models/vo/LoanVO';
import http, { ContentType } from 'utils/http';
import API_DE from 'utils/http/api/dealer';
import type {
  DealerEarlyRepaymentRequest,
  DealerLoanApprovalRequest,
  DealerLoanListRequest,
} from 'utils/http/api/dealer/loans/request';

// dealer 대출 목록 조회
export async function requestDealerLoanList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerLoanListRequest,
): Promise<Pageable<LoanVOModel[]>> {
  const response = await http.get<Pageable<LoanVO[]>>({
    url: API_DE.LOANS.LOAN_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const LoanList: LoanVOModel[] = response.content.map((data: LoanVO) => formattingToLoanVOModel(data));
  const LoanPage: Pageable<LoanVOModel[]> = {
    ...response,
    content: LoanList,
  };

  return LoanPage;
}

// dealer 대출 상세 조회
export async function requestDealerLoanDetail(loanId: number): Promise<LoanDetailVOModel> {
  const response = await http.get<LoanDetailVO>({
    url: API_DE.LOANS.LOAN_DETAIL(loanId),
  });

  return formattingToLoanDetailVOModel(response);
}

// dealer 거래 내역 조회
export async function requestDealerLoanTransaction(
  loanId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<LoanTransactionVOModel[]>> {
  const response = await http.get<Pageable<LoanTransactionVO[]>>({
    url: API_DE.LOANS.LOAN_TRANSACTION_LIST(loanId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const LoanTransactionList: LoanTransactionVOModel[] = response.content.map((data: LoanTransactionVO) =>
    formattingToLoanTransactionVOModel(data),
  );
  const LoanTransactionPage: Pageable<LoanTransactionVOModel[]> = {
    ...response,
    content: LoanTransactionList,
  };

  return LoanTransactionPage;
}

//채권 양도 통지서
export async function requestDealerDownloadDebtSellingNotification(loanId: number) {
  await http.download(API_DE.LOANS.DOWNLOAD_DEBT_SELLING_NOTIFICATION(loanId));
}

// dealer 건별 대출약정서 or 채권 양도 계약서 다운로드
export async function requestDealerDownloadLoanAgreement(loanId: number) {
  await http.download(API_DE.LOANS.DOWNLOAD_LOAN_AGREEMENT(loanId));
}

// dealer 대출 기타 파일 다운로드
export async function requestDealerDownloadLoanEtc(loanId: number, loanEtcAttachmentId: number) {
  await http.download(API_DE.LOANS.DOWNLOAD_LOAN_ETC(loanId), { loanEtcAttachmentId });
}

// dealer 대출 신청서 다운로드
export async function requestDealerDownloadLoanRequestForm(loanId: number) {
  await http.download(API_DE.LOANS.DOWNLOAD_LOAN_REQUEST_FORM(loanId));
}

// 신청 취소
export async function requestDealerLoanCancel(loanId: number, cancelReason: string): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_DE.LOANS.CANCEL_LOAN(loanId),
    data: { cancelReason },
  });

  return formattingToLoanVOModel(response);
}

// 신청 승인 확정
export async function requestDealerLoanApproval(loanId: number, data: DealerLoanApprovalRequest): Promise<LoanVOModel> {
  const response = await http.put<LoanVO>({
    url: API_DE.LOANS.APPROVE_LOAN(loanId),
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToLoanVOModel(response);
}

// dealer 조기상환 신청
export async function requestDealerLoanEarlyRepayment(
  loanId: number,
  data: DealerEarlyRepaymentRequest,
): Promise<EarlyRepaymentRequestVOModel> {
  const response = await http.post<EarlyRepaymentRequestVO>({
    url: API_DE.LOANS.REQUEST_EARLY_REPAYMENT(loanId),
    data,
  });

  return formattingToEarlyRepaymentRequestVOModel(response);
}

// dealer 만기연장 신청
export async function requestDealerLoanExtension(loanId: number): Promise<ExtensionRequestVOModel> {
  const response = await http.post<ExtensionRequestVO>({
    url: API_DE.LOANS.REQUEST_LOAN_EXTENSION(loanId),
  });

  return formattingToExtensionRequestVOModel(response);
}

// dealer 조기상환가능 예상금액 조회
export async function requestDealerCalculateExpectedAmount(
  loanId: number,
  expectedRepaymentDate: string,
): Promise<ExpectedLoanRepaymentVOModel> {
  const response = await http.post<ExpectedLoanRepaymentVO>({
    url: API_DE.LOANS.CALCULATE_EXPECTED_AMOUNT(loanId),
    data: { expectedRepaymentDate },
  });

  return formattingToExpectedLoanRepaymentVOModel(response);
}

// 대출 전문 갱신 요청
export async function requestDealerRenewLoanByFinancierInterface(loanId: number): Promise<LoanVOModel> {
  const response = await http.post<LoanVO>({
    url: API_DE.LOANS.RENEW_LOAN_BY_FINANCIER_INTERFACE(loanId),
  });

  return formattingToLoanVOModel(response);
}

export async function requestDealerDownloadScannedAr(loanId: number) {
  await http.download(API_DE.LOANS.DOWNLOAD_SCANNED_AR(loanId));
}

export async function requestDealerDownloadFactoringDocsForEsign(loanId: number): Promise<FactoringAttachmentVOModel> {
  const response = await http.get<FactoringAttachmentVO>({
    url: API_DE.LOANS.DOWNLOAD_FACTORING_DOCS_FOR_ESIGN(loanId),
  });

  return formattingToFactoringAttachmentVOModel(response);
}

export async function requestDealerLoanAdditionalDocumentAttachments(
  loanId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<LoanAdditionalDocumentAttachmentVOModel[]>> {
  const response = await http.get<Pageable<LoanAdditionalDocumentAttachmentVO[]>>({
    url: API_DE.LOANS.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS(loanId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const additionalDocumentsList: LoanAdditionalDocumentAttachmentVOModel[] = response.content.map(data =>
    formattingToLoanAdditionalDocumentAttachmentVOModel(data),
  );

  return {
    ...response,
    content: additionalDocumentsList,
  };
}
