import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import Select from 'components/stateless/Select/Select';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import getSelectOptions from 'constants/selectOptions';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import { requestCmBankCodeList } from 'utils/http/api/common/bank-code';
import useModal from 'utils/modal/useModal';

interface HeaderTypePropsType {
  modalId: number;
  handleBankCodeData: Function;
  financierId: number;
}

interface BankCodePageModalRequest {
  selectKey: 'bankCode' | 'bankName' | 'branchName';
  searchValue: string;
}

function CitadCodeModal({ modalId, handleBankCodeData, financierId }: HeaderTypePropsType) {
  const mounted = useMounted();
  const modal = useModal();

  const { t } = useTranslation(['format']);

  const { pageable, setPageable } = usePageable();
  const [bankCodePage, setBankCodePage] = useState<Pageable<BankCodeVOModel[]>>();
  const { register, getValues } = useForm<BankCodePageModalRequest>();

  const tableHeader: HeaderType[] = [
    {
      headerText: t('text:Bank_Code'),
      className: 'text-center',
      colWidths: 140,
    },
    {
      headerText: t('text:Bank_Name'),
      className: 'text-center',
      colWidths: 240,
    },
    {
      headerText: t('text:Branch_Name'),
      className: 'text-center',
      colWidths: 140,
    },
    {
      headerText: '',
      className: 'text-center',
      colWidths: 60,
    },
  ];

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (pageNumber: number = 1, rowCount: number = 10): Promise<void> => {
    const { selectKey, searchValue } = getValues();

    try {
      const bankCodePage = await requestCmBankCodeList(pageNumber, rowCount, {
        [selectKey]: searchValue,
        deleted: false,
        financierId,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setBankCodePage(bankCodePage);
        setPageable(bankCodePage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearch = async (e: any): Promise<void> => {
    e.preventDefault();

    fetchAll();
  };

  const paginate = async (selectedPageNumber: number, selectedRowCount: number): Promise<void> => {
    fetchAll(selectedPageNumber, selectedRowCount);
  };

  const renderResultTable = (): JSX.Element[] | undefined => {
    const onClickSelect = (item: BankCodeVOModel): void => {
      handleBankCodeData(item);

      modal.close(modalId);
    };

    return bankCodePage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.bankCode} />
        <Td data={item.bankName} />
        <Td data={item.branchName} />
        <Td className="information-table-more">
          <Button
            onClick={() => {
              onClickSelect(item);
            }}
          >
            {t('text:Select')}
          </Button>
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <form>
        <div className="d-flex information-filter mt-0">
          <Select
            name="selectKey"
            className="information-form__select"
            selectOptions={getSelectOptions<string>('COMMON_BANK_CODE_LIST_REQUEST', [
              'bankName',
              'bankCode',
              'branchName',
            ])}
            ref={register}
          />
          <input
            name="searchValue"
            type="text"
            placeholder={t('text:Please_type_here')}
            style={{ width: '68%' }}
            ref={register}
          />
          <Button type="submit" onClick={onClickSearch} bold>
            {t('text:Search')}
          </Button>
        </div>
      </form>
      <TableBorder>
        <TableHeader header={tableHeader} />
        <TableBody numOfCol={tableHeader.length}>{renderResultTable()}</TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </>
  );
}

export default CitadCodeModal;
