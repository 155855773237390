import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import PPTextFieldItem from 'components/potential-partner/PPTextField/PPTextFieldItem';
import PPTextFieldLabel from 'components/potential-partner/PPTextField/PPTextFieldLabel';
import PPTextFieldValue from 'components/potential-partner/PPTextField/PPTextFieldValue';
import { ROUTES_PP } from 'constants/routes/potentialPartner';
import type { SuccessArGroupAnchorPartnerAccountVOModel } from 'models/vo/SuccessArGroupAnchorPartnerAccountVO';

import './PotentialPartnerArDetailBanner.scss';

interface PotentialPartnerArDetailBannerProps {
  data: SuccessArGroupAnchorPartnerAccountVOModel;
}
function PotentialPartnerArDetailBanner({ data }: PotentialPartnerArDetailBannerProps) {
  const { t } = useTranslation(['format']);
  const history = useHistory();

  const goToFinancierDetail = (financierId: number) => {
    history.push(ROUTES_PP.MY_INFO.FINANCIER_DETAIL_BUILD_PATH(financierId));
  };

  return (
    <div className="pp-ar-detail-banner">
      <div className="pp-banner__ar__details__wrapper">
        <div className="pp-banner__ar__details__box text-right">
          <div className="pp-banner__ar__details__title star">{t('text:Financing_Available_for_AR(s)')}</div>
          <div>
            <span className="pp-banner__ar__details__currency">{data.currencyType} </span>
            <span className="pp-banner__ar__details__amount">
              {' '}
              {t('format:number', {
                value: data.ltvTotalArAmount,
              })}
            </span>
            <div className="pp-banner__ar__details__ltv">
              <span className="text-bold">{data.totalArCount}</span> {t('text:AR(s)_included')} |{' '}
              <span className="text-bold">{data.maxLtvRatio}% </span>LTV
            </div>
          </div>
        </div>
        <div className="pp-banner__ar__details__box--buyer-bank-info">
          <div className="pp-row">
            <PPTextFieldItem>
              <PPTextFieldLabel label={t('text:Buyer_Name')} />
              <PPTextFieldValue value={data.anchorClientName} />
            </PPTextFieldItem>
          </div>
          <div className="pp-row">
            <PPTextFieldItem>
              <PPTextFieldLabel label={t('text:Financier_Name')} />
              <PPTextFieldValue
                value={data.financierName}
                className="text-underline cursor-pointer"
                onClick={() => goToFinancierDetail(data.financierId)}
                role="button"
              />
            </PPTextFieldItem>
          </div>
        </div>
      </div>

      <div className="pp-banner__ar__details__guide-meg">
        {t(
          'text:The_financing_conditions_and_total_amount_you_can_receive_as_financing_may_change_depending_on_your_agreement_with_the_Financier',
        )}
      </div>
    </div>
  );
}

export default PotentialPartnerArDetailBanner;
