import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TdLink from 'components/stateless/Table/TdLink';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierCalendarList } from 'utils/http/api/financier/financier-calendar';
import type { FinancierCalenderListRequest } from 'utils/http/api/financier/financier-calendar/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { checkInterfaceType, getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const FI_HOLIDAY_LIST_QS_KEY = 'fi-holiday-list';

function FinancierHolidaysList() {
  const CURRENT_USER_AUTHORITY_TYPE = getSignIn()?.authorityType;
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(FI_HOLIDAY_LIST_QS_KEY);
  const getProperty = useProperty<FinancierCalenderListRequest>();
  const [useInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.BUSINESS_DATE)); // 전문 연동
  const modal = useModal();

  const [holidayList, setHolidayList] = useState<Pageable<FinancierCalenderVOModel[]>>();

  const { getValues, reset, setValue, control } = useForm<FinancierCalenderListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierCalenderListRequest>(
        setValue,
        getParsedSearchParams(FI_HOLIDAY_LIST_QS_KEY).formSearchData,
      );
      fetchAll(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  const fetchAll = async (pageNumber: number, rowCount: number, searchData: FinancierCalenderListRequest) => {
    const searchValue: FinancierCalenderListRequest = {
      ...searchData,
      holiday: true,
    };

    try {
      const fetchHolidayList = await requestFinancierCalendarList(pageNumber, rowCount, searchValue);
      updateSearchParams(
        {
          ...searchValue,
          pageNumber,
          rowCount,
        },
        FI_HOLIDAY_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setHolidayList(fetchHolidayList);
        setPageable(fetchHolidayList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onSearchSubmit = async () => {
    await fetchAll(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    await fetchAll(pageNumber, sizePerPage, getParsedSearchParams(FI_HOLIDAY_LIST_QS_KEY).formSearchData);
  };
  const onRemoveFilter = (event: any) => {
    reset({});
    event.preventDefault();
  };

  const renderResultTable = () => {
    return holidayList?.content.length !== 0 ? (
      holidayList?.content.map((item, i) => {
        return (
          <tr key={i}>
            <td>{tableValueManage(item.year)}</td>
            <td>{tableValueManage(item.month)}</td>
            <td>{tableValueManage(item.day)}</td>
            <td>{tableValueManage(item.dayOfWeek, t(`code:day-of-week.${item.dayOfWeek}`))}</td>
            <td>{tableValueManage(item.description)}</td>
            {/* 수기인 경우만 상세페이지 존재 */}
            {!useInterface && <TdLink path={ROUTES_FI.PROGRAM_SETTINGS.HOLIDAYS_DETAIL_BUILD_PATH(item.id)} />}
          </tr>
        );
      })
    ) : (
      <>
        {!useInterface ? (
          <tr>
            <td colSpan={6} className="text-center">
              {t('text:no_data_available')}
            </td>
          </tr>
        ) : (
          <tr>
            <td colSpan={5} className="text-center">
              {t('text:no_data_available')}
            </td>
          </tr>
        )}
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Holidays')} />
      {/* ADMIN 만 가이드 및 register button 표시 */}
      {CURRENT_USER_AUTHORITY_TYPE === AUTHORITY_TYPE.ADMIN && (
        <GuideMessage
          message={[
            t('text:Click_the_button_on_the_right_to_register_a_new_holiday'),
            t('text:Registered_holidays_can_be_found_in_the_list_below'),
          ]}
        >
          {{
            button: <Button to={ROUTES_FI.PROGRAM_SETTINGS.HOLIDAYS_REGISTER}>{t('text:Register_Holiday')}</Button>,
          }}
        </GuideMessage>
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Holiday_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name={getProperty('fromDate')} control={control} />
              <SearchDatePicker placeholder={t('text:to')} name={getProperty('toDate')} control={control} />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {holidayList?.totalElements}
        </p>
        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '70px' }} />
              <col style={{ width: '70px' }} />
              <col style={{ width: '70px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '180px' }} />
              {!useInterface && <col style={{ width: '50px' }} />}
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t('text:Year')}</th>
                <th scope="col">{t('text:Month')}</th>
                <th scope="col">{t('text:Day')}</th>
                <th scope="col">{t('text:Day_of_the_Week')}</th>
                <th scope="col">{t('text:Description')}</th>
                {!useInterface && <th scope="col" className="table-column-right-fixed" />}
              </tr>
            </thead>
            <tbody className="bg-white">{renderResultTable()}</tbody>
          </table>
        </div>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierHolidaysList;
