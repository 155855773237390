import { PAGE_TYPE, useExtraInformationContext } from './Context';

type Function = (...args: any[]) => any;

type GetControllerByPagePropsType<T, U, V> = {
  register: T;
  registeredDetail: U;
  waitingDetail: V;
};

interface GetControllerByPageType {
  <T extends Function, U extends Function, V extends Function>({
    register,
    registeredDetail,
    waitingDetail,
  }: GetControllerByPagePropsType<T, U, V>): ReturnType<T> & ReturnType<U> & ReturnType<V>;
}

const useExtraInformationViewModel = () => {
  const {
    supportedCollateralType,
    errorHandlerOfLocationState,
    pageType,
    anchorFinancingOptionId,
    waitingAnchorFinancingOptionId,
  } = useExtraInformationContext();

  const getControllerByPageType: GetControllerByPageType = ({ register, registeredDetail, waitingDetail }) => {
    switch (pageType) {
      case PAGE_TYPE.REGISTER:
        return register();
      case PAGE_TYPE.REGISTERED_DETAIL:
        return registeredDetail();
      case PAGE_TYPE.WAITING_DETAIL:
        return waitingDetail();
      default:
        throw new Error(`Invalid pageType: ${pageType}`);
    }
  };

  return {
    supportedCollateralType,
    pageType,
    anchorFinancingOptionId,
    waitingAnchorFinancingOptionId,
    getControllerByPageType,
    errorHandlerOfLocationState,
  };
};

export default useExtraInformationViewModel;
