import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { faCheckCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from 'components/stateless/Button/IconButton';
import Select from 'components/stateless/Select/Select';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import {
  AUTHORITY_TYPE,
  POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS,
  POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS,
} from 'enums';
import { ColorMap } from 'enums/colorMap';
import type { PotentialPartnerFinancingApplicationDocumentVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDocumentVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierPotentialPartnerFinancingApplicationDownloadDocuments } from 'utils/http/api/financier/potential-partner-financing-application-documents';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

interface FinancierSCPartnerAcquisitionDetailSubmittedDocumentsProps {
  documentLists: PotentialPartnerFinancingApplicationDocumentVOModel[];
  applicationStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
  reviewedBranchId: number;
  updateApplicationDocumentStatus(
    applicationDocumentId: number,
    value: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS,
    index: number,
  ): Promise<boolean>;
}

function FinancierSCPartnerAcquisitionDetailSubmittedDocuments({
  documentLists,
  applicationStatus,
  reviewedBranchId,
  updateApplicationDocumentStatus,
}: FinancierSCPartnerAcquisitionDetailSubmittedDocumentsProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const loginUserBranchId = getSignIn()?.branchId;

  const [showChecked, setShowChecked] = useState<boolean[]>([]);

  const updateDocumentStatusForm = useForm();

  useEffect(() => {
    if (documentLists.length > 0) {
      const showCheckedInitialState: boolean[] = documentLists.map(() => false);
      setShowChecked(showCheckedInitialState);

      if (applicationStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS) {
        documentLists?.forEach((item, index) => {
          updateDocumentStatusForm.setValue(`targetStatus[${index}]`, item.applicationDocumentStatus);
        });
      }
    }

    return () => {};
  }, [documentLists]);

  const updateShowChecked = (targetIndex: number, value: boolean) => {
    const prev = [...showChecked];
    prev[targetIndex] = value;
    setShowChecked(prev);
  };

  const onClickDownloadDocument = async (applicationDocumentId: number) => {
    try {
      await requestFinancierPotentialPartnerFinancingApplicationDownloadDocuments(applicationDocumentId);
    } catch (e) {
      modal.show(e);
    }
  };

  const onChangeSelectStatus = async (e: any, applicationDocumentId: number, index: number) => {
    const isSuccess = await updateApplicationDocumentStatus(applicationDocumentId, e.target.value, index);
    if (isSuccess) updateShowChecked(index, true);
    else {
      updateDocumentStatusForm.setValue(`targetStatus[${index}]`, documentLists[index].applicationDocumentStatus);
      updateShowChecked(index, false);
    }
  };

  const DOCUMENT_LIST_HEADER: HeaderType[] = [
    {
      headerText: t('text:Document_Type'),
      colWidths: 200,
    },
    {
      headerText: t('text:File_Name'),
      colWidths: 250,
    },
    {
      headerText: t('text:Submitted_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Status'),
      colWidths: 150,
    },
  ];

  const renderApplicationDocumentStatus = (
    index: number,
    documentItem: PotentialPartnerFinancingApplicationDocumentVOModel,
  ) => {
    if (applicationStatus !== POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS) return <Td />;

    const renderDocumentStatus = (documentStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS) => {
      switch (documentStatus) {
        case POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.CONFIRMED:
          return (
            <span
              className={getStatusTextClass(
                'POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS',
                POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.CONFIRMED,
              )}
            >
              {t('text:Confirmed')}
            </span>
          );
        case POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.NEED_RESUBMISSION:
          return (
            <span
              className={getStatusTextClass(
                'POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS',
                POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.NEED_RESUBMISSION,
              )}
            >
              {t('text:Needs_Resubmission')}
            </span>
          );
        case POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.SUBMITTED:
          return (
            <span
              className={getStatusTextClass(
                'POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS',
                POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.SUBMITTED,
              )}
            >
              {t('text:Submitted')}
            </span>
          );
      }
    };

    return (
      <Td>
        {!documentItem.applicationAttachmentName || isAdmin || reviewedBranchId !== loginUserBranchId ? (
          renderDocumentStatus(documentItem.applicationDocumentStatus)
        ) : (
          <>
            <div className="d-inline-block w-80">
              <Select
                name={`targetStatus[${index}]`}
                className="information-form__select bg-sub100"
                onChange={e => onChangeSelectStatus(e, documentItem.applicationDocumentId, index)}
                selectOptions={[
                  {
                    label: t('text:Submitted'),
                    value: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.SUBMITTED,
                  },
                  {
                    label: t('text:Needs_Resubmission'),
                    value: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.NEED_RESUBMISSION,
                  },
                  {
                    label: t('text:Confirmed'),
                    value: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.CONFIRMED,
                  },
                ]}
                ref={updateDocumentStatusForm.register}
              />
            </div>
            <div className="d-inline-block w-10">
              {showChecked[index] && <FontAwesomeIcon icon={faCheckCircle} className="checked-icon-green ms-2" />}
            </div>
          </>
        )}
      </Td>
    );
  };

  return (
    <div className="content-area">
      {documentLists.length > 0 && (
        <>
          <SectionTitle title={t('text:Submitted_Documents')} />
          <TableBorder>
            <TableHeader header={DOCUMENT_LIST_HEADER} />
            <TableBody>
              {documentLists?.map((documentItem, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <div className="mb-1 bold-font">
                        {documentItem.acquisitionDocumentName ?? t('text:Additional_Documents')}
                      </div>

                      <div>{documentItem.acquisitionDocumentDescription}</div>
                    </Td>
                    <Td className="attach-file-link__td">
                      <div className="d-flex align-items-center">
                        {documentItem.applicationAttachmentName ? (
                          <label
                            className="attach-file-link__td__label text-decoration-none"
                            onClick={async () => {
                              await onClickDownloadDocument(documentItem.applicationDocumentId);
                            }}
                          >
                            {documentItem.applicationAttachmentName}
                          </label>
                        ) : (
                          <span>{t('text:No_file_attached')}</span>
                        )}
                        {documentItem.applicationDocumentDescription && (
                          <>
                            <IconButton
                              data-tip
                              data-for={`description${index}`}
                              style={{
                                color: ColorMap.sub600,
                              }}
                            >
                              <FontAwesomeIcon icon={faQuestionCircle} />
                            </IconButton>
                            <ReactTooltip id={`description${index}`} className="tooltip__description__max__width">
                              {documentItem.applicationDocumentDescription}
                            </ReactTooltip>
                          </>
                        )}
                      </div>
                    </Td>
                    <Td data={documentItem.applicationDocumentUpdatedDateTime} format="datetime" />
                    {renderApplicationDocumentStatus(index, documentItem)}
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
        </>
      )}
    </div>
  );
}

export default FinancierSCPartnerAcquisitionDetailSubmittedDocuments;
