const API_SY = {
  ACCOUNT_TRANSACTIONS: {
    ACCOUNT_TRANSACTION_LIST: (anchorAgreementId: number) => `v1/sy/account-transactions/${anchorAgreementId}`,
    SETTLEMENT_ACCOUNT_BALANCE: (anchorAgreementId: number) =>
      `v1/sy/account-transactions/${anchorAgreementId}/settlement-account-balance`,
  },
  ANCHOR_AGREEMENTS: {
    ANCHOR_AGREEMENT_LIST: 'v1/sy/anchor-agreements',
    ANCHOR_AGREEMENT_DETAIL: (anchorAgreementId: number) => `v1/sy/anchor-agreements/${anchorAgreementId}`,
  },
  ANCHOR_PARTNER_ACCOUNTS: {
    ANCHOR_PARTNER_ACCOUNT_LIST: 'v1/sy/anchor-partner-accounts',
  },
  ANCHOR_PARTNERS: {
    ANCHOR_PARTNER_LIST: 'v1/sy/anchor-partners',
    ANCHOR_PARTNER_DETAIL: (anchorPartnerId: number) => `v1/sy/anchor-partners/${anchorPartnerId}`,
  },
  ANCHOR_REPORTS: {
    ANCHOR_REPORT_LIST: 'v1/sy/anchor-reports',
    DOWNLOAD_REPORT: (anchorReportId: number) => `v1/sy/anchor-reports/${anchorReportId}/download:report`,
  },
  ANCHOR_REPORT_RECEIVERS: {
    ANCHOR_REPORT_RECEIVER_LIST: 'v1/sy/anchor-report-receivers',
    ANCHOR_REPORT_RECEIVER_DETAIL: (anchorReportReceiverId: number) =>
      `v1/sy/anchor-report-receivers/${anchorReportReceiverId}`,
    DEALER_AGREEMENT_LIST_RELATED_ANCHOR_REPORT_RECEIVER: (anchorReportReceiverId: number) =>
      `v1/sy/anchor-report-receivers/${anchorReportReceiverId}/related-agreement-list`,
  },
  ANCHOR_REPORT_SETTINGS: {
    ANCHOR_REPORT_SETTING_LIST: 'v1/sy/anchor-report-settings',
    ANCHOR_REPORT_SETTING_DETAIL: (anchorReportSettingId: number) =>
      `v1/sy/anchor-report-settings/${anchorReportSettingId}`,
  },
  ANCHOR_USERS: {
    ANCHOR_USER_LIST: 'v1/sy/anchor-users',
    ANCHOR_USER_DETAIL: (anchorUserId: number) => `v1/sy/anchor-users/${anchorUserId}`,
    ANCHOR_AGREEMENT_LIST_RELATED_ANCHOR_USER: (anchorUserId: number) =>
      `v1/sy/anchor-users/${anchorUserId}/related-anchor-agreements`,
    DEALER_AGREEMENT_LIST_RELATED_ANCHOR_USER: (anchorUserId: number) =>
      `v1/sy/anchor-users/${anchorUserId}/related-dealer-agreements`,
  },
  AR_APPROVAL_PHASES: {
    AR_APPROVAL_PHASE_LIST: 'v1/sy/ar-approval-phases',
  },
  AR_SUMMARIES: {
    AR_SUMMARY_LIST: 'v1/sy/ar-summaries',
    AR_SUMMARY_DETAIL: (arSummaryId: number) => `v1/sy/ar-summaries/${arSummaryId}`,
  },
  BILLING: {
    BILLING_LIST: 'v1/sy/billing',
    BILLING_TOTAL_AMOUNT: 'v1/sy/billing/total-amount',
  },
  BRANCHES: {
    BRANCH_LIST: 'v1/sy/branches',
  },
  DEALER_AGREEMENTS: {
    DEALER_AGREEMENT_LIST: 'v1/sy/dealer-agreements',
    DEALER_AGREEMENT_DETAIL: (dealerAgreementId: number) => `v1/sy/dealer-agreements/${dealerAgreementId}`,
    ANCHOR_CLIENT_LIMIT_INFO_RELATED_DEALER_AGREEMENT: (dealerAgreementId: number) =>
      `v1/sy/dealer-agreements/${dealerAgreementId}/related-anchor-client-limit-info`,
  },
  DIVISIONS: {
    DIVISIONS: 'v1/sy/divisions',
    DIVISION_BY_CODE: 'v1/sy/divisions/find:by-code',
  },
  EARLY_REPAYMENT_REQUESTS: {
    EARLY_REPAYMENT_REQUESTS: 'v1/sy/early-repayment-requests',
    EARLY_REPAYMENT_DETAIL: (earlyRepaymentRequestId: number) =>
      `v1/sy/early-repayment-requests/${earlyRepaymentRequestId}`,
  },
  ENTERPRISES: {
    ENTERPRISE_LIST: 'v1/sy/enterprises',
    CREATE_FINANCIER: 'v1/sy/enterprises/create:financier',
    ENTERPRISE_DETAIL: (enterpriseId: number) => `v1/sy/enterprises/${enterpriseId}`,
    FINANCIER_INTERFACE_SETTING: (enterpriseId: number) =>
      `v1/sy/enterprises/${enterpriseId}/financier-interface-setting`,
    FINANCIER_CLIENT_AUTH_SETTING: (enterpriseId: number) =>
      `v1/sy/enterprises/${enterpriseId}/financier-client-auth-setting`,
  },
  EXTENSION_REQUESTS: {
    EXTENSION_REQUESTS: 'v1/sy/extension-requests',
    EXTENSION_REQUEST_DETAIL: (extensionRequestId: number) => `v1/sy/extension-requests/${extensionRequestId}`,
  },
  FINANCIER_CLIENT_HISTORIES: {
    FINANCIER_CLIENT_HISTORIES: 'v1/sy/financier-client-histories',
  },
  FINANCIER_CLIENT: {
    FINANCIER_CLIENTS: 'v1/sy/financier-clients',
    FINANCIER_CLIENT_DETAIL: (financierClientId: number) => `v1/sy/financier-clients/${financierClientId}`,
  },
  FINANCIER_COMMON_SETTING: {
    FINANCIER_COMMON_SETTING_DETAIL: (financierId: number) => `v1/sy/financier-common-setting/${financierId}`,
    FINANCIER_COMMON_SETTING: 'v1/sy/financier-common-setting',
    DOWNLOAD_LOGO: (financierCommonSettingId: number) =>
      `v1/sy/financier-common-setting/${financierCommonSettingId}/download:logo`,
    MISASSIGNED_FINANCIER_TYPE: 'v1/sy/financier-common-setting/misassigned-financier-type',
  },
  INVENTORY_SUMMARIES: {
    INVENTORY_SUMMARY_LIST: 'v1/sy/inventory-summaries',
    INVENTORY_SUMMARY_DETAIL: (inventorySummaryId: number) => `v1/sy/inventory-summaries/${inventorySummaryId}`,
    DOWNLOAD_ATTACHMENT: (inventorySummaryId: number) =>
      `v1/sy/inventory-summaries/${inventorySummaryId}/download:attachment`,
  },
  INVOICE_APPROVAL_PHASES: {
    INVOICE_APPROVAL_PHASE_LIST: 'v1/sy/invoice-approval-phases',
  },
  INVOICE_PAYMENTS: {
    INVOICE_PAYMENT_LIST: 'v1/sy/invoice-payments',
  },
  INVOICE_SUMMARIES: {
    INVOICE_SUMMARY_LIST: 'v1/sy/invoice-summaries',
    INVOICE_SUMMARY_DETAIL: (invoiceSummaryId: number) => `v1/sy/invoice-summaries/${invoiceSummaryId}`,
  },
  LOANS: {
    LOAN_LIST: 'v1/sy/loans',
    LOAN_DETAIL: (loanId: number) => `v1/sy/loans/${loanId}`,
    LOAN_TRANSACTION_LIST: (loanId: number) => `v1/sy/loans/${loanId}/loan-transactions`,
    RENEW_LOAN_BY_FINANCIER_INTERFACE: (loanId: number) => `v1/sy/loans/${loanId}/renew:loan-by-financier-interface`,
    CALCULATE_EXPECTED_AMOUNT: (loanId: number) => `v1/sy/loans/${loanId}/calculate:expected-amount`,
    DOWNLOAD_LOAN_ETC: (loanId: number) => `v1/sy/loans/${loanId}/download:loan-etc`,
    DOWNLOAD_LOAN_AGREEMENT: (loanId: number) => `v1/sy/loans/${loanId}/download:loan-agreement`,
    DOWNLOAD_LOAN_REQUEST_FORM: (loanId: number) => `v1/sy/loans/${loanId}/download:loan-request-form`,
    DOWNLOAD_SCANNED_AR: (loanId: number) => `v1/sy/loans/${loanId}/download:scanned-ar`,
    DOWNLOAD_DEBT_SELLING_NOTIFICATION: (loanId: number) =>
      `v1/sy/loans/${loanId}/download:loan-factoring-notification`,
  },
  LOGIN: {
    LOGIN: 'v1/sy/login',
  },
  LOGIN_HISTORY: {
    LOGIN_HISTORY_LIST: 'v1/sy/login-history',
  },
  PLATFORM_TRANSACTION: {
    FEE_LIST: 'v1/sy/platform-transaction/fee',
    EXECUTION_LIST: 'v1/sy/platform-transaction/execution',
  },
  PURPOSE_OF_LOANS: {
    DOWNLOAD_PURPOSE_ATTACHMENT: (purposeOfLoanId: number) =>
      `v1/sy/purpose-of-loans/${purposeOfLoanId}/download:purpose-attachment`,
    DOWNLOAD_ACCOUNT_ATTACHMENT: (purposeOfLoanId: number) =>
      `v1/sy/purpose-of-loans/${purposeOfLoanId}/download:account-attachment`,
  },
  STATISTICS_OF_ANCHOR_AGREEMENTS: {
    STATISTICS_OF_ANCHOR_AGREEMENT_LIST: 'v1/sy/statistics-of-anchor-agreements',
  },
  STATISTICS_OF_SC_PARTNERS: {
    STATISTICS_OF_SC_PARTNER_LIST: 'v1/sy/statistics-of-dealer-agreements',
  },
  SUCCESS_ARS: {
    SUCCESS_AR_LIST: 'v1/sy/success-ars',
    SUCCESS_AR_DETAIL: (successArId: number) => `v1/sy/success-ars/${successArId}`,
    RENEW_AR_BY_FINANCIER_INTERFACE: (successArId: number) =>
      `v1/sy/success-ars/${successArId}/renew:ar-by-financier-interface`,
    PARTIAL_COLLATERALS: (successArId: number) => `v1/sy/success-ars/${successArId}/partial-collaterals`,
  },
  SUCCESS_INVOICES: {
    SUCCESS_INVOICE_LIST: 'v1/sy/success-invoices',
    SUCCESS_INVOICE_DETAIL: (successInvoiceId: number) => `v1/sy/success-invoices/${successInvoiceId}`,
    DOWNLOAD_SCANNED_INVOICE: (successInvoiceId: number) =>
      `v1/sy/success-invoices/${successInvoiceId}/download:scanned-invoice`,
  },
  SUPPORTED_CURRENCY_TYPES: {
    SUPPORTED_CURRENCY_TYPES: 'v1/sy/supported-currency-types',
    SUPPORTED_CURRENCY_TYPE_DETAIL: (supportedCurrencyTypeId: number) =>
      `v1/sy/supported-currency-types/${supportedCurrencyTypeId}`,
  },
  TERMS_OF_USES: {
    TERMS_OF_USES: 'v1/sy/terms-of-uses',
    TERMS_OF_USES_DETAIL: (termsOfUseId: number) => `v1/sy/terms-of-uses/${termsOfUseId}`,
  },
  TERMS_OF_USE_VERSIONS: {
    TERMS_OF_USE_VERSIONS_ID: (termsOfUseId: number) => `v1/sy/terms-of-use-versions/${termsOfUseId}`,
    DOWNLOAD: (termsOfUseId: number) => `v1/sy/terms-of-use-versions/${termsOfUseId}/download`,
  },
  USAGE: {
    USAGE_LIST: 'v1/sy/usage',
  },
  USERS: {
    USER_LIST: 'v1/sy/users',
    USER_DETAIL: (userId: number) => `v1/sy/users/${userId}`,
    CREATE_FINANCIER_ADMIN: 'v1/sy/users/create:financier-admin',
    SUSPEND_USER: (userId: number) => `v1/sy/users/${userId}/suspend:user`,
    INVITE_FINANCIER_ADMIN: (userId: number) => `v1/sy/users/${userId}/invite:financier-admin`,
  },
  WAITING_ANCHOR_AGREEMENTS: {
    WAITING_ANCHOR_AGREEMENT_LIST: 'v1/sy/waiting-anchor-agreements',
    WAITING_ANCHOR_AGREEMENT_DETAIL: (waitingAnchorAgreementId: number) =>
      `v1/sy/waiting-anchor-agreements/${waitingAnchorAgreementId}`,
  },
  WAITING_ANCHOR_USERS: {
    WAITING_ANCHOR_USER_LIST: 'v1/sy/waiting-anchor-users',
    WAITING_ANCHOR_USER_DETAIL: (waitingAnchorUserId: number) => `v1/sy/waiting-anchor-users/${waitingAnchorUserId}`,
  },
  WAITING_ARS: {
    WAITING_AR_LIST: 'v1/sy/waiting-ars',
  },
  WAITING_BANK_CODE: {
    WAITING_BANK_CODE: 'v1/sy/waiting-bank-code',
    WAITING_BANK_CODE_DETAIL: (waitingBankCodeId: number) => `v1/sy/waiting-bank-code/${waitingBankCodeId}`,
    REJECT_MODIFICATION: (waitingBankCodeId: number) =>
      `v1/sy/waiting-bank-code/${waitingBankCodeId}/reject:modification`,
    APPROVE_MODIFICATION: (waitingBankCodeId: number) =>
      `v1/sy/waiting-bank-code/${waitingBankCodeId}/approve:modification`,
  },
  WAITING_DEALER_AGREEMENTS: {
    WAITING_DEALER_AGREEMENT_LIST: 'v1/sy/waiting-dealer-agreements',
    WAITING_DEALER_AGREEMENT_DETAIL: (waitingDealerAgreementId: number) =>
      `v1/sy/waiting-dealer-agreements/${waitingDealerAgreementId}`,
  },
  WAITING_INVOICES: {
    WAITING_INVOICE_LIST: 'v1/sy/waiting-invoices',
  },
};

export default API_SY;
