import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { ATTACHMENT_TYPE, FACTORING_NOTICE_CONFIRMATION_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FactoringNoticeVOModel } from 'models/vo/FactoringNoticeVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import {
  requestAnchorFactoringNoticeConfirm,
  requestAnchorFactoringNoticeDownload,
  requestAnchorFactoringNoticeList,
} from 'utils/http/api/anchor/factoring-notices';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { updateSearchParams } from 'utils/searchParams';

const AC_FACTORING_NOTICE_LIST_QS_KEY = 'ac-factoring-notice-list';

function AnchorDeptSellingNoticeList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const [anchorFactoringNoticeList, setAnchorFactoringNoticeList] = useState<Pageable<FactoringNoticeVOModel[]>>();
  const [checkedMailList, setCheckedMailList] = useState<number[]>([]);
  const { pageable, setPageable } = usePageable(AC_FACTORING_NOTICE_LIST_QS_KEY);
  const [allCheckedPages, setAllCheckedPages] = useState<number[]>([]);

  const allCheckedPageList = allCheckedPages.includes(pageable.currentPage)
    ? allCheckedPages
    : [...allCheckedPages, pageable.currentPage];

  const removeNonAllCheckedPage = allCheckedPages.filter(pageNum => pageNum !== pageable.currentPage);

  const fetchAnchorFactoringNoticeList = async () => {
    updateSearchParams(
      { pageNumber: pageable.currentPage, rowCount: pageable.sizePerPage },
      AC_FACTORING_NOTICE_LIST_QS_KEY,
    );

    try {
      const fetchedAnchorFactoringNoticeList = await requestAnchorFactoringNoticeList(
        pageable.currentPage,
        pageable.sizePerPage,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorFactoringNoticeList(fetchedAnchorFactoringNoticeList);
        setPageable(fetchedAnchorFactoringNoticeList);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  useEffect(() => {
    if (mounted) {
      fetchAnchorFactoringNoticeList();
    }
  }, [mounted]);

  useEffect(() => {
    const availableCheckedList = anchorFactoringNoticeList?.content.filter(
      item => item.confirmationStatus === FACTORING_NOTICE_CONFIRMATION_STATUS.WAITING,
    );
    if (!availableCheckedList?.length) {
      setAllCheckedPages(removeNonAllCheckedPage);

      return;
    }

    const checkedMailSet = new Set(checkedMailList);
    const currentPageAllChecked = availableCheckedList.every(item => checkedMailSet.has(item.factoringNoticeId));

    if (currentPageAllChecked) {
      setAllCheckedPages(allCheckedPageList);
    } else {
      setAllCheckedPages(removeNonAllCheckedPage);
    }
  }, [anchorFactoringNoticeList, checkedMailList]);

  const handleCheckAll = (e: any) => {
    if (anchorFactoringNoticeList?.content === undefined) return;
    const availableCheckedIdList = anchorFactoringNoticeList?.content
      .filter(item => item.confirmationStatus === FACTORING_NOTICE_CONFIRMATION_STATUS.WAITING)
      .map(item => item.factoringNoticeId);

    if (e.target.checked) {
      const currentCheckedMail = [...checkedMailList, ...availableCheckedIdList] ?? [];

      setCheckedMailList([...new Set(currentCheckedMail)]);
    } else {
      const availableCheckedIdSet = new Set(availableCheckedIdList);
      const filteredMailList = checkedMailList.filter(mail => !availableCheckedIdSet.has(mail));

      setCheckedMailList(filteredMailList);
    }
  };

  const handleCheckChange = (e: any, factoringNoticeId: number) => {
    if (e.target.checked) {
      const target = [...checkedMailList, factoringNoticeId];
      setCheckedMailList(target);
    } else {
      const target = checkedMailList.filter(id => id !== factoringNoticeId);
      setCheckedMailList(target);
    }
  };

  const paginate = async (page: number, sizePerPage: number) => {
    try {
      const fetchedAnchorFactoringNoticeList = await requestAnchorFactoringNoticeList(page, sizePerPage);

      updateSearchParams({ pageNumber: page, rowCount: sizePerPage }, AC_FACTORING_NOTICE_LIST_QS_KEY);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorFactoringNoticeList(fetchedAnchorFactoringNoticeList);
        setPageable(fetchedAnchorFactoringNoticeList);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const downloadFile = async (e: any, factoringNoticeId: number) => {
    e.preventDefault();

    try {
      await requestAnchorFactoringNoticeDownload(factoringNoticeId, {
        attachmentType: ATTACHMENT_TYPE.FACTORING_NOTIFICATION,
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const handleClickConfirm = async () => {
    modal.show(<h6>{t('text:Would_you_like_to_confirm_receipt_of_the_email?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async () => {
        try {
          await requestAnchorFactoringNoticeConfirm(checkedMailList);

          modal.show(<h6>{t('text:Reception_of_the_mail_has_been_confirmed_Successfully')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => {
              fetchAnchorFactoringNoticeList();
              setCheckedMailList([]);
            },
          });
        } catch (e) {
          modal.show(e);
        }
      },
    });
  };

  const ConfirmNoticeTableHeaders: HeaderType[] = [
    {
      hasCheckBox: true,
      checkBoxId: String(pageable.currentPage),
      allCheckedPages: allCheckedPages,
      handleClickAllCheckBox: handleCheckAll,
      colWidths: 50,
    },

    {
      headerText: t('text:Sending_Date'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Total_Financing_Amount'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Reception_Status'),
    },
    {
      headerText: t('text:Confirmation_Status'),
    },
    {
      headerText: t('text:Confirmed_User'),
    },
    {
      headerText: t('text:File_Download'),
    },
    {
      headerText: '',
    },
  ];

  const renderConfirmNoticeTableList = () => {
    return anchorFactoringNoticeList?.content.map(item => (
      <Tr key={item.factoringNoticeId}>
        <td>
          {item.confirmationStatus === FACTORING_NOTICE_CONFIRMATION_STATUS.WAITING && (
            <div className="text-center">
              <input
                className="form-check-input m-0"
                type="checkbox"
                onChange={e => handleCheckChange(e, item.factoringNoticeId)}
                checked={checkedMailList.some(id => id === item.factoringNoticeId)}
              />
            </div>
          )}
        </td>

        <Td data={item.sentDateTime} format="date" />
        <Td data={item.partnerName} />
        <Td data={item.totalRequestedAmount ?? item.requestedAmount} format="number" />
        <Td data={item.currencyType} />
        <Td data={`${item.receivedUserCount}/${item.totalReceiverCount}`} />
        <Td
          className={getStatusTextClass('FACTORING_NOTICE_CONFIRMATION_STATUS', item.confirmationStatus)}
          data={t(`code:factoring-notice-confirmation-status.${item.confirmationStatus}`)}
          format="code"
        />
        <Td data={`${item.confirmedUserName} (${t(`code:authority-type.${item.confirmedUserAuthority}`)})`} />
        <Td className="text-center">
          <DownloadButton onClick={e => downloadFile(e, item.factoringNoticeId)} />
        </Td>
        <TdLink path={ROUTES_AC.VIEW_TRANSACTION.DEPT_SELLING_NOTICE_BUILD_PATH(item.factoringNoticeId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Confirm_Notification')} />
      <div className="content-area">
        <SectionTitle title={t('text:Confirm_Receipt_of_Debt_Selling_Notification_Mail')} />
      </div>
      <GuideMessage
        message={[
          t(
            'text:On_this_page_Anchor_Company_can_check_whether_you_have_received_the_Debt_Selling_Notification_mail_for_the_master_agreement_assigned_to_user',
          ),
          t('text:Users_can_change_Confirmation_Status_to_Complete_by_pressing_the_button_at_the_bottom'),
        ]}
      />
      <div className="content-area">
        <TableBorder>
          <TableHeader header={ConfirmNoticeTableHeaders} />
          <TableBody numOfCol={ConfirmNoticeTableHeaders.length}>{renderConfirmNoticeTableList()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
      <div className="content-area flex-end mt-3">
        <Button size={ButtonSizeEnum.LG} onClick={handleClickConfirm} disabled={!checkedMailList.length}>
          {t('text:Confirm_Notification')}
        </Button>
      </div>
    </>
  );
}

export default AnchorDeptSellingNoticeList;
