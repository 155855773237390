import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Accordion from 'components/stateless/Accordion/Accordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { AUTHORITY_TYPE, BANK_CODE_APPROVAL_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { WaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { requestCmBankCodeDetail } from 'utils/http/api/common/bank-code';
import { requestFiAnchorPartnerAccountList } from 'utils/http/api/financier/anchor-partner-accounts';
import {
  requestFiWaitingBankCodeCancel,
  requestFiWaitingBankCodeDetail,
} from 'utils/http/api/financier/waiting-bank-code';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

function FinancierBankCodeWaitingDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const [waitingBankCodeDetail, setWaitingBankCodeDetail] = useState<WaitingBankCodeVOModel>();
  const [bankCodeDetail, setBankCodeDetail] = useState<BankCodeVOModel>();
  const [bankAccountList, setBankAccountList] = useState<Pageable<AnchorPartnerAccountVOModel[]>>();
  const bankAccountPageable = usePageable();
  const signInModel: SignInModel | null = getSignIn();
  const { waitingBankCodeId } = useParams<any>();
  const [locationState, errorHandlerOfLocationState] = useLocationState<{
    targetBankCodeId: number;
  }>(['targetBankCodeId']);
  const { targetBankCodeId } = locationState;

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      if (targetBankCodeId) {
        const [fetchWaitingBankCode, fetchBankCodeDetail, fetchAnchorPartnerAccounts] = await Promise.all([
          requestFiWaitingBankCodeDetail(waitingBankCodeId),
          requestCmBankCodeDetail(targetBankCodeId),
          requestFiAnchorPartnerAccountList(
            bankAccountPageable.pageable.currentPage,
            bankAccountPageable.pageable.sizePerPage,
            {
              accountBankCodeId: targetBankCodeId,
            },
          ),
        ]);
        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingBankCodeDetail(fetchWaitingBankCode);
          setBankCodeDetail(fetchBankCodeDetail);
          bankAccountPageable.setPageable(fetchAnchorPartnerAccounts);
          setBankAccountList(fetchAnchorPartnerAccounts);
        });
      } else {
        const fetchWaitingBankCode = await requestFiWaitingBankCodeDetail(waitingBankCodeId);
        setWaitingBankCodeDetail(fetchWaitingBankCode);
      }
    } catch (e) {
      modal.show(e);
    }
  };

  const showCancelRequestModal = (e: any) => {
    e.preventDefault();
    modal.show(<h6>{t('text:Would_you_like_to_cancel_the_request?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: async () => {
        try {
          await requestFiWaitingBankCodeCancel(waitingBankCodeId);
          showSuccessModal();
        } catch (e) {
          modal.show(e);
        }
      },
    });
    const showSuccessModal = () => {
      modal.show(<h6>{t('text:The_request_has_been_cancelled_successfully')}</h6>, {
        closeBtnCb: () => fetchAll(),
      });
    };
  };

  const renderBankAccountInfo = () => {
    const bankAccountInfoTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
      },
      {
        headerText: t('text:Responsible_Bank_Name'),
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
      },
      {
        headerText: t('text:Uploaded_Partner_Name'),
      },
      {
        headerText: t('text:Uploaded_Partner_Tax_Code'),
      },
      {
        headerText: t('text:Bank_Account_Holder'),
      },
      {
        headerText: t('text:Bank_Account_Number'),
      },
    ];

    const bankAccountPaginate = async (pageNumber: number, sizePerPage: number) => {
      try {
        const fetchAnchorPartnerAccounts = await requestFiAnchorPartnerAccountList(pageNumber, sizePerPage, {
          accountBankCodeId: targetBankCodeId,
        });
        ReactDOM.unstable_batchedUpdates(() => {
          setBankAccountList(fetchAnchorPartnerAccounts);
          bankAccountPageable.setPageable(fetchAnchorPartnerAccounts);
        });
      } catch (e) {
        modal.show(e);
      }
    };

    return (
      <>
        <TableBorder>
          <TableHeader header={bankAccountInfoTableHeaders} backGroundType={BackGroundType.DarkGray} />
          <TableBody numOfCol={8}>
            {bankAccountList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.anchorAgreementContractNo} />
                  <Td data={item.anchorName} />
                  <Td data={item.responsibleFinancierName} />
                  <Td data={item.responsibleBranchName} />
                  <Td data={item.partnerName} />
                  <Td data={item.partnerTaxCode} />
                  <Td data={item.accountOwner} />
                  <Td data={item.account} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={bankAccountPageable.pageable} paginate={bankAccountPaginate} />
      </>
    );
  };

  const renderProgressStatus = (progressStatus: BANK_CODE_APPROVAL_TYPE | undefined): JSX.Element => {
    switch (progressStatus) {
      case bankCodeDetail && BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST:
        return t('text:Revision_Requested');
      case !bankCodeDetail && BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST:
        return t('text:Registration_Requested');
      case BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL:
        return t('text:Cancelled');
      case BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED:
        return t('text:Rejected');
      case BANK_CODE_APPROVAL_TYPE.SYSTEM_APPROVED:
        return t('text:Registered');
      default:
        return <></>;
    }
  };

  const renderTimeText = (progressStatus: BANK_CODE_APPROVAL_TYPE | undefined): string => {
    switch (progressStatus) {
      case BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST:
        return t('text:Requested_Date');
      case BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL:
        return t('text:Cancelled_Date');
      case BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED:
        return t('text:Rejected_Date');
      default:
        return '';
    }
  };

  const renderGuideMessage = (): JSX.Element | null => {
    if (waitingBankCodeDetail?.approvalType === BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL) return null;
    if (waitingBankCodeDetail?.approvalType === BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED) return null;

    if (
      waitingBankCodeDetail?.approvalType === BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST &&
      signInModel?.authorityType === AUTHORITY_TYPE.ADMIN
    ) {
      return (
        <GuideMessage
          message={[
            t('text:The_bank_code_revision_request_has_been_submitted'),
            t('text:Click_on_the_Cancel_Request_button_to_cancel_the_request'),
          ]}
        />
      );
    }

    return <GuideMessage message={[t('text:The_bank_code_revision_request_has_been_submitted')]} />;
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Bank_Code_Management_Details')} />
      {renderGuideMessage()}
      {waitingBankCodeDetail && (
        <div className="content-area">
          <Accordion
            id="waiting-bank-code-detail"
            className="accordion accordion-information-form"
            defaultExpanded={true}
          >
            <Accordion.Header id="waiting-bank-code-detail-header">
              <Accordion.Trigger>
                <div className="accordion-information-form__head">
                  <div className={getStatusBadgeClass('BANK_CODE_APPROVAL_TYPE', waitingBankCodeDetail?.approvalType)}>
                    {renderProgressStatus(waitingBankCodeDetail?.approvalType)}
                  </div>
                  <div className="accordion-information-form__head--text">
                    <div className="fw-bold">
                      {t('text:Admin')} ( {waitingBankCodeDetail?.operatorUserName} /{' '}
                      {waitingBankCodeDetail?.operatorUserLoginId} )
                    </div>
                    {waitingBankCodeDetail.operatorEnterpriseName}
                  </div>
                  <div className="accordion-information-form__head--text">
                    <div className="fw-bold">{renderTimeText(waitingBankCodeDetail.approvalType)}</div>
                    {t('format:datetime', { value: waitingBankCodeDetail?.updatedDateTime, key: 'datetime' })}
                  </div>
                </div>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="accordion-body p-0">
              <FormBorder>
                <FormSubtitle title={t('text:Bank_Code_Information')} backGroundType={BackGroundType.DarkGray} />
                <FormContents>
                  <div className="row">
                    <FormValue label={t('text:Bank_Code')} value={waitingBankCodeDetail?.bankCode} />
                    <FormValue label={t('text:Bank_Name')} value={waitingBankCodeDetail?.bankName} />
                  </div>
                  <div className="row">
                    <FormValue label={t('text:Branch_Code')} value={waitingBankCodeDetail?.branchCode} />
                    <FormValue label={t('text:Branch_Name')} value={waitingBankCodeDetail?.branchName} />
                  </div>
                </FormContents>
              </FormBorder>
              <FormBorder>
                <FormSubtitle
                  title={t('text:Associated_Bank_Account_Information')}
                  backGroundType={BackGroundType.DarkGray}
                />
                <FormContents>{renderBankAccountInfo()}</FormContents>
              </FormBorder>
            </Accordion.Content>
          </Accordion>
          {waitingBankCodeDetail.approvalType === BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST &&
            signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && (
              <div className="flex-end mt-4">
                <Button
                  size={ButtonSizeEnum.LG}
                  color={ButtonColorEnum.SECONDARY}
                  variant={ButtonVariantEnum.OUTLINED}
                  onClick={showCancelRequestModal}
                >
                  {t('text:Cancel_Request')}
                </Button>
              </div>
            )}
        </div>
      )}
      {bankCodeDetail && (
        <div className="content-area">
          <Accordion className="accordion accordion-information-form" id="bank-code-detail">
            <Accordion.Header id="bank-code-detail-header">
              <Accordion.Trigger>
                <div className="accordion-information-form__head">
                  <div
                    className={`d-flex align-items-center text-nowrap ${getStatusBadgeClass(
                      'BANK_CODE_APPROVAL_TYPE',
                      BANK_CODE_APPROVAL_TYPE.SYSTEM_APPROVED,
                    )}`}
                  >
                    {renderProgressStatus(BANK_CODE_APPROVAL_TYPE.SYSTEM_APPROVED)}
                  </div>
                  <div className="accordion-information-form__head--text" />
                  <div className="accordion-information-form__head--text">
                    <div className="fw-bold">{t('text:Latest_Update_Date')}</div>
                    {t('format:datetime', { value: bankCodeDetail.updatedDateTime, key: 'datetime' })}
                  </div>
                </div>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="accordion-body p-0">
              <FormBorder>
                <FormSubtitle title={t('text:Bank_Code_Information')} backGroundType={BackGroundType.DarkGray} />
                <FormContents>
                  <div className="row">
                    <FormValue label={t('text:Bank_Code')} value={bankCodeDetail?.bankCode} />
                    <FormValue label={t('text:Bank_Name')} value={bankCodeDetail?.bankName} />
                  </div>
                  <div className="row">
                    <FormValue label={t('text:Branch_Code')} value={bankCodeDetail?.branchCode} />
                    <FormValue label={t('text:Branch_Name')} value={bankCodeDetail?.branchName} />
                  </div>
                </FormContents>
              </FormBorder>
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </>
  );
}

export default FinancierBankCodeWaitingDetail;
