import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import { Tr } from 'components/stateless/Table';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { PageableType } from 'hooks/usePageable';
import type { FactoringNoticeRelatedSuccessArVOModel } from 'models/vo/FactoringNoticeRelatedSuccessArVO';

interface FinancierDeptSellingNoticeDetailARListProps {
  data?: FactoringNoticeRelatedSuccessArVOModel[];
  paginate(pageNumber: number, rowCount: number): Promise<void>;
  pageable: PageableType;
}
function FinancierDeptSellingNoticeDetailARList({
  data,
  paginate,
  pageable,
}: FinancierDeptSellingNoticeDetailARListProps) {
  const { t } = useTranslation();

  const AR_LIST_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:AR_Number'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:AR_Amount'),
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Settlement_Date'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:AR_List')} />
      <TableBorder>
        <TableHeader header={AR_LIST_TABLE_HEADER} />
        <TableBody numOfCol={AR_LIST_TABLE_HEADER.length}>
          {data?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td data={item.arNumber} />
                <Td data={item.anchorName} />
                <Td data={item.partnerName} />
                <Td data={item.arAmount} format="number" />
                <Td data={item.currencyType} />
                <Td data={item.settlementDate} format="date" />
                <TdLink path={ROUTES_FI.VIEW_TRANSACTION.AR_DETAIL_BUILD_PATH(item.successArId)} />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default FinancierDeptSellingNoticeDetailARList;
