import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import {
  convertToAnchorAgreementResetData,
  convertToInterfaceAnchorAgreementData,
} from 'models/convertor/anchorAgreement';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { isNilOrEmptyString } from 'utils/helpers';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import SearchInterfaceByContactNoModal from '../../../modals/SearchInterfaceByContactNoModal';
import SearchInterfaceByKeyUnionModal from '../../../modals/SearchInterfaceByKeyUnionModal';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorClientInfoViewModel from '../../../models/agreement/useAnchorClientInfoViewModel';
import useDivisionsViewModel from '../../../models/divisions/useDivisionsViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useRegistrationLoadAgreementController = () => {
  const { show: showModal, id: modalId } = useModal();

  const { t } = useTranslation(['format']);
  const { supportedCollateralType, useAgreementInterfaceByKeyUnion, useAnchorUserInterface } =
    useExtraInformationViewModel();

  const { updateAllAgreement, updateIsSearchedAgreement } = useAgreementViewModel();

  const { updateDivisionName, fetchDivisionSelectOptions } = useDivisionsViewModel();

  const { anchorClientInfo } = useAnchorClientInfoViewModel();

  const interfaceDataRef = useRef<AnchorAgreementDetailVOModel>({} as AnchorAgreementDetailVOModel);

  const {
    methods: { reset },
  } = useFormContext();

  const setSearchedInterfaceData = useCallback(searchedData => {
    interfaceDataRef.current = searchedData;
  }, []);

  // TODO ::  updateDivisionName - agreement 와 통합 시 수정
  const updateDivisionHandler = async ({
    anchorClientId,
    interfaceDivision,
    interfaceDivisionName,
  }: {
    anchorClientId?: number;
    interfaceDivision?: string;
    interfaceDivisionName?: string;
  }) => {
    const selectableDivision = !isNil(anchorClientId) && isNilOrEmptyString(interfaceDivision);

    if (selectableDivision) {
      await fetchDivisionSelectOptions(anchorClientId);
      updateDivisionName('');
    } else {
      updateDivisionName(interfaceDivisionName ?? '');
    }
  };

  const handleApplyClick = async () => {
    const convertedInterfaceAgreement = convertToInterfaceAnchorAgreementData({
      prevAnchorClientData: anchorClientInfo,
      interfaceData: interfaceDataRef.current,
      useAnchorUserInterface,
    });

    if (convertedInterfaceAgreement) {
      await updateDivisionHandler({
        anchorClientId: convertedInterfaceAgreement.anchorClientId,
        interfaceDivision: convertedInterfaceAgreement.division,
        interfaceDivisionName: convertedInterfaceAgreement.divisionName,
      });

      updateIsSearchedAgreement(true);
      updateAllAgreement(convertedInterfaceAgreement);
      reset(convertToAnchorAgreementResetData(convertedInterfaceAgreement));
    }
  };

  const searchInterfaceAnchorAgreementModalOptions: ModalOptions = {
    modalType: ModalType.CONFIRM,
    modalSize: ModalSize.XL,
    title: t('text:Search_Anchor_Agreement'),
    confirmBtnText: t('text:Apply'),
    closeBtnText: t('text:Close'),
    confirmBtnCb: handleApplyClick,
  };

  const showSearchInterfaceByKeyUnionModal = () => {
    showModal(
      <SearchInterfaceByKeyUnionModal
        modalId={modalId}
        collateralType={supportedCollateralType}
        getInterfaceData={setSearchedInterfaceData}
      />,
      searchInterfaceAnchorAgreementModalOptions,
    );
  };

  const showSearchInterfaceByContactNoModal = () => {
    showModal(
      <SearchInterfaceByContactNoModal
        modalId={modalId}
        collateralType={supportedCollateralType}
        getInterfaceData={setSearchedInterfaceData}
      />,
      searchInterfaceAnchorAgreementModalOptions,
    );
  };

  const handleLoadAgreementButtonClick = () => {
    useAgreementInterfaceByKeyUnion ? showSearchInterfaceByKeyUnionModal() : showSearchInterfaceByContactNoModal();
  };

  return { handleLoadAgreementButtonClick };
};

export default useRegistrationLoadAgreementController;
