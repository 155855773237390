import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import { TableBody, TableBorder, TableHeader } from 'components/stateless/Table';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import WithProvider from 'utils/render';

import useDesignatedAccountTransactionHistoryController from './useDesignatedAccountTransactionHistoryController';
import AccountTransactionsProvider from '../../../models/accountTransactions/Context';

const DesignatedAccountTransactionHistory = () => {
  const { t } = useTranslation(['format']);

  const {
    useDepositTransaction,
    useAgreementInterface,
    settlementAccountBalance,
    accountTransactions,
    accountTransactionsPageable,
    fetchAccountTransactions,
    TRANSACTION_HISTORY_LIST_HEADERS,
  } = useDesignatedAccountTransactionHistoryController();

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Designated_Account_Transaction_History')} />
      {useAgreementInterface && (
        <div className="account-form mb-3">
          <div className="account-balance">
            <div className="account-balance-label">{t('text:Designated_Account_Balance')}</div>
            <div className="account-balance-value">
              {settlementAccountBalance ? t('format:number', { value: settlementAccountBalance }) : '-'}
            </div>
          </div>
          <div className="bg-pattern" />
        </div>
      )}
      {useDepositTransaction && (
        <>
          <TableBorder>
            <TableHeader header={TRANSACTION_HISTORY_LIST_HEADERS} />
            <TableBody numOfCol={TRANSACTION_HISTORY_LIST_HEADERS.length}>
              {accountTransactions?.map((item, index: number) => (
                <Tr key={index}>
                  <Td data={item.transactionDateTime} format="datetime" />
                  <Td data={item.memo} />
                  <Td data={item.depositAmount} format="number" />
                  <Td data={item.withdrawalAmount} format="number" />
                </Tr>
              ))}
            </TableBody>
          </TableBorder>

          <Pagination
            pageable={accountTransactionsPageable}
            paginate={fetchAccountTransactions}
            showSelectRow={false}
          />
        </>
      )}
    </div>
  );
};

export default WithProvider({
  Component: DesignatedAccountTransactionHistory,
  Provider: AccountTransactionsProvider,
});
