import { useTranslation } from 'react-i18next';

import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { getApprovalTypeText, getApprovalTypeTimeText } from 'utils/text';

import './index.scss';
import useWaitingPartnerAgreementApprovalInfoController from './useWaitingPartnerAgreementApprovalInfoController';

const WaitingPartnerAgreementApprovalInfo = () => {
  const { t } = useTranslation(['format']);

  const { UserInfo, updatedDateTime, financierName, approvalType } = useWaitingPartnerAgreementApprovalInfoController();

  return (
    <div className="content-area">
      <div className="panel-container">
        <div className="panel-content">
          <div className={`panel-item ${getStatusBadgeClass('COMMON_APPROVAL_TYPE', approvalType)}`}>
            {getApprovalTypeText(approvalType)}
          </div>
          <div className="panel-item">
            <div className="panel-item__title">{UserInfo}</div>
            <div className="panel-item__text">{financierName ?? 'financierName'}</div>
          </div>
          <div className="panel-item">
            <div className="panel-item__title">{getApprovalTypeTimeText(approvalType)}</div>
            <div className="panel-item__text">
              {t('format:datetime', {
                value: updatedDateTime,
                key: 'datetime',
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingPartnerAgreementApprovalInfo;
