import type { HTMLAttributes } from 'react';
import type React from 'react';
import { forwardRef } from 'react';

import { ColorMap } from 'enums/colorMap';

import WithLink from './WithLink';

export interface IconButtonPropsType extends HTMLAttributes<HTMLButtonElement> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonPropsType>(
  ({ className = 'ps-2 pe-2', children, color = ColorMap.sub500, onClick, ...restProps }, ref) => {
    return (
      <button ref={ref} className={`icon-button ${className}`} style={{ color }} onClick={onClick} {...restProps}>
        {children}
      </button>
    );
  },
);
IconButton.displayName = 'IconButton';

export default WithLink(IconButton);
