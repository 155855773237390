import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemStatisticsOfSCPartnersList } from 'utils/http/api/system/statistics-of-sc-partners';
import type { SystemStatisticsOfSCPartnerListRequest } from 'utils/http/api/system/statistics-of-sc-partners/requests';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

import SystemOverviewBySCPartnerListChannelTab from './sections/channel-tab';
import SystemOverviewBySCPartnerListVendorTab from './sections/vendor-tab';

export const SY_OVERVIEW_BY_SC_PARTNER_VENDOR_TAB_QS_KEY = 'vendor-tab';
export const SY_OVERVIEW_BY_SC_PARTNER_CHANNEL_TAB_QS_KEY = 'channel-tab';

function SystemOverviewBySCPartnerList() {
  const mounted = useMounted();
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const [overviewByPartnerVendorPage, setOverviewByPartnerVendorPage] =
    useState<Pageable<StatisticsOfDealerAgreementVOModel[]>>();
  const [overviewByPartnerChannelPage, setOverviewByPartnerChannelPage] =
    useState<Pageable<StatisticsOfDealerAgreementVOModel[]>>();

  const { pageable: overviewByPartnerVendorPageable, setPageable: setOverviewByPartnerVendorPageable } = usePageable(
    SY_OVERVIEW_BY_SC_PARTNER_VENDOR_TAB_QS_KEY,
  );
  const { pageable: overviewByPartnerChannelPageable, setPageable: setOverviewByPartnerChannelPageable } = usePageable(
    SY_OVERVIEW_BY_SC_PARTNER_CHANNEL_TAB_QS_KEY,
  );

  const vendorTabSearchForm = useForm<SystemStatisticsOfSCPartnerListRequest>({
    mode: 'onSubmit',
  });

  const channelTabSearchForm = useForm<SystemStatisticsOfSCPartnerListRequest>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemStatisticsOfSCPartnerListRequest>(
        vendorTabSearchForm.setValue,
        getParsedSearchParams(SY_OVERVIEW_BY_SC_PARTNER_VENDOR_TAB_QS_KEY).formSearchData,
      );
      setFormValues<SystemStatisticsOfSCPartnerListRequest>(
        channelTabSearchForm.setValue,
        getParsedSearchParams(SY_OVERVIEW_BY_SC_PARTNER_CHANNEL_TAB_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    const vendorTabQs = makeSearchParamsPattern(
      {
        ...vendorTabSearchForm.getValues(),
        pageNumber: overviewByPartnerVendorPageable.currentPage,
        rowCount: overviewByPartnerVendorPageable.sizePerPage,
      },
      SY_OVERVIEW_BY_SC_PARTNER_VENDOR_TAB_QS_KEY,
    );
    const dealerTabQs = makeSearchParamsPattern(
      {
        ...channelTabSearchForm.getValues(),
        pageNumber: overviewByPartnerChannelPageable.currentPage,
        rowCount: overviewByPartnerChannelPageable.sizePerPage,
      },
      SY_OVERVIEW_BY_SC_PARTNER_CHANNEL_TAB_QS_KEY,
    );
    addSearchParams(vendorTabQs + dealerTabQs);

    try {
      const [fetchOverviewByPartnerVendorPage, fetchOverviewByPartnerChannelPage] = await Promise.all([
        requestSystemStatisticsOfSCPartnersList(
          overviewByPartnerVendorPageable.currentPage,
          overviewByPartnerVendorPageable.sizePerPage,
          {
            ...vendorTabSearchForm.getValues(),
            collateralType: COLLATERAL_TYPE.AR,
          },
        ),
        requestSystemStatisticsOfSCPartnersList(
          overviewByPartnerChannelPageable.currentPage,
          overviewByPartnerChannelPageable.sizePerPage,
          {
            ...channelTabSearchForm.getValues(),
            collateralType: COLLATERAL_TYPE.INVOICE,
          },
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByPartnerVendorPage(fetchOverviewByPartnerVendorPage);
        setOverviewByPartnerVendorPageable(fetchOverviewByPartnerVendorPage);

        setOverviewByPartnerChannelPage(fetchOverviewByPartnerChannelPage);
        setOverviewByPartnerChannelPageable(fetchOverviewByPartnerChannelPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchSyPartnerStatisticsVendorTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: SystemStatisticsOfSCPartnerListRequest,
  ) => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.AR,
    };
    try {
      const response = await requestSystemStatisticsOfSCPartnersList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        SY_OVERVIEW_BY_SC_PARTNER_VENDOR_TAB_QS_KEY,
      );

      setOverviewByPartnerVendorPage(response);
      setOverviewByPartnerVendorPageable(response);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchVendorTab = async (e: any) => {
    e.preventDefault();
    await fetchSyPartnerStatisticsVendorTab(
      1,
      overviewByPartnerVendorPageable.sizePerPage,
      vendorTabSearchForm.getValues(),
    );
  };

  const paginateVendorTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchSyPartnerStatisticsVendorTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(SY_OVERVIEW_BY_SC_PARTNER_VENDOR_TAB_QS_KEY).formSearchData,
    );
  };

  const fetchSyPartnerStatisticsChannelTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: SystemStatisticsOfSCPartnerListRequest,
  ) => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.INVOICE,
    };
    try {
      const response = await requestSystemStatisticsOfSCPartnersList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        SY_OVERVIEW_BY_SC_PARTNER_CHANNEL_TAB_QS_KEY,
      );

      setOverviewByPartnerChannelPage(response);
      setOverviewByPartnerChannelPageable(response);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchChannelTab = async (e: any) => {
    e.preventDefault();
    await fetchSyPartnerStatisticsChannelTab(
      1,
      overviewByPartnerChannelPageable.sizePerPage,
      channelTabSearchForm.getValues(),
    );
  };

  const paginateChannelTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchSyPartnerStatisticsChannelTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(SY_OVERVIEW_BY_SC_PARTNER_CHANNEL_TAB_QS_KEY).formSearchData,
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Overview_by_Partner')} />
      <Tab
        tabType="financeType"
        tabViewList={[
          <FormProvider {...vendorTabSearchForm} key="vendor">
            <SystemOverviewBySCPartnerListVendorTab
              onClickSearch={onClickSearchVendorTab}
              overviewByPartnerPageData={overviewByPartnerVendorPage}
              pageable={overviewByPartnerVendorPageable}
              paginate={paginateVendorTab}
            />
          </FormProvider>,
          <FormProvider {...channelTabSearchForm} key="dealer">
            <SystemOverviewBySCPartnerListChannelTab
              onClickSearch={onClickSearchChannelTab}
              overviewByPartnerPageData={overviewByPartnerChannelPage}
              pageable={overviewByPartnerChannelPageable}
              paginate={paginateChannelTab}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default SystemOverviewBySCPartnerList;
