import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isEmpty, isEqualWith, isNil } from 'lodash-es';

import { ROUTES_FI } from 'constants/routes/financier';
import { BASIS_INTEREST_TYPE, COLLATERAL_TYPE } from 'enums';
import { AgreementExceptionCode } from 'enums/exception';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { formErrorHandler } from 'utils/error/manager';
import { isNilOrEmptyString } from 'utils/helpers';
import { requestRegisterFinancierWaitingAnchorFinancingOption } from 'utils/http/api/financier/waiting-anchor-financing-options';
import type { CreateFiWaitingAnchorFinancingOptionDTO } from 'utils/http/api/financier/waiting-anchor-financing-options/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser, requestDTOParserForArrayObj } from 'utils/valueManager/ValueManager';

import useExtraInformationViewModel from '../models/extraInformation/useExtraInformationViewModel';

const useRegistrationController = () => {
  const { t } = useTranslation();
  const { show: showModal } = useModal();
  const history = useHistory();

  const { supportedCollateralType } = useExtraInformationViewModel();
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const initialFormValues = {};

  const methods = useForm<CreateFiWaitingAnchorFinancingOptionDTO>();
  const { getValues, setError, clearErrors } = methods;

  const canLeavePageRef = useRef(false);

  const isFormChanged = !isEqualWith(initialFormValues, getValues(), (a, b) => {
    if ((typeof a === 'number' || typeof b === 'number') && a === b) return true;
    if (isNilOrEmptyString(a) && isNilOrEmptyString(b)) return true;
  });

  const ignoreNavigationGuard = canLeavePageRef.current;

  useBeforeUnload(isFormChanged, [initialFormValues, getValues()]);

  const registerFinancingOption = async () => {
    const data = getValues();
    data.collateralType = supportedCollateralType;
    data.termSpreadList = data.termSpreadList ?? [];
    if (data.basisInterestType === BASIS_INTEREST_TYPE.FIXED) data.basisInterestBankCode = BASIS_INTEREST_TYPE.FIXED;

    if (supportedCollateralType === COLLATERAL_TYPE.INVOICE) data.anchorAccount = 'true';
    requestDTOParser(data);

    requestDTOParserForArrayObj(data.termSpreadList);

    await requestRegisterFinancierWaitingAnchorFinancingOption(data);
  };

  const showRegistrationCompletedModal = () => {
    canLeavePageRef.current = true;

    showModal(
      <h6>
        {t('text:Request_for_approval_of_financing_option_registration_has_been_completed')}
        <br />
        {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
      </h6>,
      {
        closeBtnCb: () => {
          history.push(ROUTES_FI.MANAGE_ANCHOR.FINANCING_OPTION_LIST);
        },
      },
    );
  };

  const handleARRequiredFieldsValidation = () => {
    const data = getValues();
    /**
     * AR에서 필수
     * 전송 시 몇몇 필드들에 대한 검증이 먼저 들어가기 때문에 앞서 들어간 검증이 통과되어야 에러코드가 내려옴(늦게). 우선 FE에서 에러 표시
     */
    const arRequiredFields = [
      {
        name: 'anchorFinancingCondition',
        value: data.anchorFinancingCondition,
      },
      {
        name: 'anchorAgreementStatus',
        value: data.anchorAgreementStatus,
      },
      {
        name: 'loanLimitCheck',
        value: data.loanLimitCheck,
      },
      ...(data.anchorAccount === 'true'
        ? [
            {
              name: 'disbursementAccountFinancierName',
              value: data.disbursementAccountFinancierName,
            },
            {
              name: 'disbursementAccountBranchName',
              value: data.disbursementAccountBranchName,
            },
            {
              name: 'disbursementAccount',
              value: data.disbursementAccount,
            },
            {
              name: 'disbursementAccountOwner',
              value: data.disbursementAccountOwner,
            },
          ]
        : []),
    ];

    if (isAr) {
      arRequiredFields.forEach(item => {
        if (isNilOrEmptyString(item.value)) {
          setError(item.name, {
            message: t('text:This_information_is_required'),
          });
        } else clearErrors(item.name);
      });
    }
  };

  const handleInvoiceRequiredFieldsValidation = () => {
    const data = getValues();

    const invoiceRequiredFields = [
      {
        name: 'anchorAgreementStatus',
        value: data.anchorAgreementStatus,
      },
      {
        name: 'loanLimitCheck',
        value: data.loanLimitCheck,
      },
      {
        name: 'disbursementAccountFinancierName',
        value: data.disbursementAccountFinancierName,
      },
      {
        name: 'disbursementAccountBranchName',
        value: data.disbursementAccountBranchName,
      },
      {
        name: 'disbursementAccount',
        value: data.disbursementAccount,
      },
      {
        name: 'disbursementAccountOwner',
        value: data.disbursementAccountOwner,
      },
    ];

    if (!isAr) {
      invoiceRequiredFields.forEach(item => {
        if (isNilOrEmptyString(item.value)) {
          setError(item.name, {
            message: t('text:This_information_is_required'),
          });
        } else clearErrors(item.name);
      });
    }
  };
  const handleRegisterButtonClick = () => {
    showModal(
      <h6>
        {t('text:Would_you_like_to_request_approval_for_registration?')}
        <br />
        {t(
          'text:Please_note_that_if_modifications_occur_after_the_completion_of_the_request_the_approval_request_must_be_cancelled_and_processed_again',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          try {
            await registerFinancingOption();
            showRegistrationCompletedModal();
          } catch (error) {
            handleRegisterFinancingOptionError(error);
          }
        },
      },
    );
  };

  const handleRegisterFinancingOptionError = (error: any) => {
    showModal(error);

    const isInvalidError = !isEmpty(
      formErrorHandler<CreateFiWaitingAnchorFinancingOptionDTO>(error, setError, clearErrors),
    );

    handleARRequiredFieldsValidation();

    handleInvoiceRequiredFieldsValidation();

    if (isInvalidError) return;

    const getMonthlyInterestRepaymentDateErrorMessage = () => {
      const monthlyInterestRepaymentDate = getValues('monthlyInterestRepaymentDate');
      if (isNil(monthlyInterestRepaymentDate) || Number(monthlyInterestRepaymentDate) === 0)
        return t('text:0_and_blank_are_not_allowed');
      else return t('text:Date_must_be_between_1_and_31');
    };

    switch (error.code) {
      case AgreementExceptionCode.INVALID_BASIS_INTEREST_RATE:
        return setError('basisInterestRate', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_LOAN_TERM:
        return setError('loanTermUnit', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.OMITTED_ANCHOR_AGREEMENT_INFO:
        return setError('anchorAgreementId', { shouldFocus: true });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_INTEREST_REPAYMENT_TYPE:
        return setError('monthlyInterestRepaymentDate', {
          shouldFocus: true,
          message: getMonthlyInterestRepaymentDateErrorMessage(),
        });
      default:
        return;
    }
  };

  return {
    methods,
    handleRegisterButtonClick,
    activatedNavigationGuard: isFormChanged,
    ignoreNavigationGuard,
  };
};

export default useRegistrationController;
