import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import WithProvider from 'utils/render';
import { getApprovalTypeText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import useAgreementRevisionHistoryController from './useAgreementRevisionHistoryController';
import WaitingAnchorFinancingOptionProvider from '../../models/waitingFinancingOption/Context';

const FinancingOptionRevisionHistory = () => {
  const { t } = useTranslation(['format']);

  const {
    REVISION_HISTORY_LIST_HEADERS,
    waitingFinancingOption,
    waitingFinancingOptionPageable,
    waitingFinancingOptionPaginate,
  } = useAgreementRevisionHistoryController();

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Revision_History')} />
      <TableBorder>
        <TableHeader header={REVISION_HISTORY_LIST_HEADERS} />
        <TableBody numOfCol={REVISION_HISTORY_LIST_HEADERS.length}>
          {waitingFinancingOption?.map((history, index: number) => (
            <Tr key={index}>
              <Td data={history.updatedDateTime} format="datetime" />
              <Td data={history.branchCode} />
              <Td
                className={getStatusTextClass('COMMON_APPROVAL_TYPE', history.approvalType)}
                data={getApprovalTypeText(history.approvalType)}
              />
              <Td
                data={`${tableValueManage(history.operatorUserLoginId)} / ${tableValueManage(
                  history.operatorUserName,
                )}`}
              />
              <Td
                data={`${tableValueManage(history.authorizerUserLoginId)} / ${tableValueManage(
                  history.authorizerUserName,
                )}`}
              />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
      <Pagination pageable={waitingFinancingOptionPageable} paginate={waitingFinancingOptionPaginate} />
    </div>
  );
};

export default WithProvider({
  Component: FinancingOptionRevisionHistory,
  Provider: WaitingAnchorFinancingOptionProvider,
});
