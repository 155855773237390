import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import FileInput from 'components/stateless/FileInput/FileInput';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import { requestAnFinancierPotentialPartnerAcquisitionDocumentDownload } from 'utils/http/api/anonymous/potential-partner-acquisition-documents';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import type { PotentialPartnerApplicationStep2FormDataType } from '../../PotentialPartnerApplicationStep2';

interface UploadedPartnerApplicationStep2RequiredDocumentFormProps {
  documentInfo: PotentialPartnerAcquisitionDocumentVOModel[];
}

function UploadedPartnerApplicationStep2RequiredDocumentForm({
  documentInfo,
}: UploadedPartnerApplicationStep2RequiredDocumentFormProps) {
  const { t } = useTranslation();
  const modal = useModal();
  const { register } = useFormContext<PotentialPartnerApplicationStep2FormDataType>();
  const requiredDescriptionMaxLength = 1000;

  async function downloadFinancierPotentialPartnerAcquisitionDocument(
    potentialPartnerAcquisitionDocumentSettingId: number,
  ) {
    const emailCode = getPotentialPartnerEmailCode();
    if (!emailCode) return modal.show('not found email code');
    try {
      await requestAnFinancierPotentialPartnerAcquisitionDocumentDownload(
        potentialPartnerAcquisitionDocumentSettingId,
        emailCode,
      );
    } catch (e) {
      modal.show(e);
    }
  }

  return (
    <div className="pp-required-document-form">
      {documentInfo.length > 0 ? (
        documentInfo.map((documentItem, index) => {
          return (
            <div key={index} className="pp-required-document-form__row">
              <div className="pp-document-form__name">{`${index + 1}. ` + documentItem.documentName}</div>
              <div className="pp-document-form__border">
                <div className="pp-document-form__header">
                  <div className="pp-document-form__desc">{tableValueManage(documentItem.description)}</div>
                  {documentItem.attachmentName && (
                    <div className="pp-file-label">
                      <a
                        onClick={async () => {
                          await downloadFinancierPotentialPartnerAcquisitionDocument(
                            documentItem.potentialPartnerAcquisitionDocumentSettingId,
                          );
                        }}
                      >
                        {t('text:Download_Template')}
                      </a>
                    </div>
                  )}
                </div>
                <div className="pp-document-form__input">
                  <div className="w-50 align-self-center">
                    <FileInput id={`required-file=${index}`} name={`requiredAttachment[${index}]`} ref={register} />
                  </div>
                  <div className="w-50">
                    <input
                      type="text"
                      name={`requiredDescription[${index}]`}
                      ref={register}
                      placeholder={t('text:Enter_the_document_description_here')}
                      className="pp-file-description-input"
                      maxLength={requiredDescriptionMaxLength}
                    />
                  </div>
                  <input
                    name={`potentialPartnerAcquisitionDocumentId[${index}]`}
                    ref={register}
                    hidden
                    defaultValue={documentItem.potentialPartnerAcquisitionDocumentSettingId}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <FormBorder>
          <div className="p-4 text-center">
            {t('text:The_financier_does_not_require_any_specific_documents_at_this_stage')}
          </div>
        </FormBorder>
      )}
    </div>
  );
}

export default UploadedPartnerApplicationStep2RequiredDocumentForm;
