import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormSelect } from 'components/stateless/CommonForm';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE } from 'enums';
import useProperty from 'hooks/useProperty';
import type { UserVOModel } from 'models/vo/UserVO';
import { requestFinancierInterface } from 'utils/http/api/financier/users';
import type { FinancierUserRegisterRequest } from 'utils/http/api/financier/users/request';
import { getHttpErrorExceptionMessage } from 'utils/text';

function SearchEmployeeModal({ state }: { state: UserVOModel }) {
  const { t } = useTranslation();
  const getProperty = useProperty<FinancierUserRegisterRequest>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  // user register request
  const {
    register: UserModelRegister,
    getValues: getUserRegisterValues,
    setValue: setUserRegisterValue,
  } = useForm<FinancierUserRegisterRequest>({
    mode: 'onSubmit',
  });

  // user code search request
  const {
    register: userCodeRegister,
    getValues: getUserCodeValue,
    handleSubmit: userCodeHandleSubmit,
  } = useForm({
    mode: 'onSubmit',
  });

  const searchEmployeeHandler = async () => {
    setErrorMessage('');
    const userCode = getUserCodeValue('userCodeSearch');
    try {
      const financierEmployeeInfo = await requestFinancierInterface(userCode);

      setUserRegisterValue('employeeCode', financierEmployeeInfo.employeeCode);
      setUserRegisterValue('userName', financierEmployeeInfo.userName);
      setUserRegisterValue('telephone', financierEmployeeInfo.telephone);
      setUserRegisterValue('email', financierEmployeeInfo.email);
      setUserRegisterValue('branchCode', financierEmployeeInfo.branchCode);
      setUserRegisterValue('branchName', financierEmployeeInfo.branchName);
      setUserRegisterValue('authorityType', financierEmployeeInfo.authorityType);
      Object.assign(state, getUserRegisterValues());
    } catch (e: any) {
      setErrorMessage(getHttpErrorExceptionMessage(e));
    }
  };

  return (
    <form>
      <div className="modal-input-form mb-3">
        <label className="information-form__label">{t('text:Employee_Code')}</label>
        <div className="d-flex">
          <input
            className="information-form__input bg-sub100"
            type="text"
            name="userCodeSearch"
            ref={userCodeRegister}
            onChange={() => setErrorMessage('')}
          />
          <Button type="submit" onClick={userCodeHandleSubmit(searchEmployeeHandler)}>
            {t('text:Search')}
          </Button>
        </div>
        {errorMessage !== '' && <div className="text-brick-red mt-1">{t(`exception:${errorMessage}`)}</div>}
      </div>
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormInput
              label={t('text:User_Code')}
              name={getProperty('employeeCode')}
              ref={UserModelRegister}
              disabled={true}
            />
            <FormInput
              label={t('text:User_Name')}
              name={getProperty('userName')}
              ref={UserModelRegister}
              disabled={true}
            />
          </div>
          <div className="row">
            <FormInput
              label={t('text:Telephone')}
              name={getProperty('telephone')}
              ref={UserModelRegister}
              disabled={true}
            />
            <FormInput label={t('text:Email')} name={getProperty('email')} ref={UserModelRegister} disabled={true} />
          </div>
          <div className="row">
            <FormInput
              label={t('text:Branch_Code')}
              name={getProperty('branchCode')}
              ref={UserModelRegister}
              disabled={true}
            />
            <FormInput
              label={t('text:Branch_Name')}
              name={getProperty('branchName')}
              ref={UserModelRegister}
              disabled={true}
            />
          </div>
          <div className="row">
            <FormSelect
              label={t('text:Authority')}
              name={getProperty('authorityType')}
              ref={UserModelRegister}
              selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', [
                AUTHORITY_TYPE.AUTHORIZER,
                AUTHORITY_TYPE.OPERATOR,
              ])}
              disabled={true}
            />
          </div>
        </FormContents>
      </FormBorder>
    </form>
  );
}

export default SearchEmployeeModal;
