export interface PotentialPartnerAcquisitionDocumentVO {
  potentialPartnerAcquisitionDocumentSettingId: number;
  documentName: string;
  description: string;
  activate: boolean;
  attachmentId: number;
  attachmentName: string;
}

export interface PotentialPartnerAcquisitionDocumentVOModel extends PotentialPartnerAcquisitionDocumentVO {}

export function formattingToPotentialPartnerAcquisitionDocumentVOModel(
  data: PotentialPartnerAcquisitionDocumentVO,
): PotentialPartnerAcquisitionDocumentVOModel {
  return {
    ...data,
  };
}
