import type { LoanAdditionalDocumentVO, LoanAdditionalDocumentVOModel } from 'models/vo/LoanAdditionalDocumentVO';
import { formattingToLoanAdditionalDocumentVOModel } from 'models/vo/LoanAdditionalDocumentVO';
import http, { ContentType } from 'utils/http';

import API_FI from '..';

import type { CreateFiLoanAdditionalDocumentDTO, UpdateFiLoanAdditionalDocumentDTO } from './request';

export async function requestFinancierCreateLoanAdditionalDocument(data: CreateFiLoanAdditionalDocumentDTO) {
  await http.post({
    url: API_FI.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS.REGISTER_LOAN_ADDITIONAL_DOCUMENTS,
    data,
    contentType: ContentType.MULTIPART,
  });
}

export async function requestFinancierLoanAdditionalDocumentDetail(
  formId: number,
): Promise<LoanAdditionalDocumentVOModel> {
  const response = await http.get<LoanAdditionalDocumentVO>({
    url: API_FI.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS.LOAN_ADDITIONAL_DOCUMENTS_DETAIL,
    data: { formId },
  });

  return formattingToLoanAdditionalDocumentVOModel(response);
}

export async function requestFinancierModifyLoanAdditionalDocument(
  documentId: number,
  data: UpdateFiLoanAdditionalDocumentDTO,
) {
  await http.put({
    url: API_FI.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS.MODIFY_LOAN_ADDITIONAL_DOCUMENTS(documentId),
    data,
    contentType: ContentType.MULTIPART,
  });
}

export async function requestFinancierDeleteLoanAdditionalDocument(documentId: number) {
  await http.delete({
    url: API_FI.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS.DELETE_LOAN_ADDITIONAL_DOCUMENTS(documentId),
  });
}

export async function requestFinancierDownloadAdditionalDocument(loanAdditionalDocumentId: number) {
  await http.download(API_FI.FINANCIER_DOCUMENT_FORMS.DOWNLOAD_ATTACHMENT(loanAdditionalDocumentId));
}

export async function requestFinancierLoanAdditionalDocumentAttachmentsDownload(documentAttachmentId: number) {
  await http.download(API_FI.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENT.DOWNLOAD_ATTACHMENT(documentAttachmentId));
}
