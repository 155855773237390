import type React from 'react';
import { useRef, useState } from 'react';

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button2ColorEnum } from 'components/stateless/Button2/Button2';
import IconButton2 from 'components/stateless/Button2/IconButton2';
import useOutsideClick from 'hooks/useOutsideClick';

import './DropDown.scss';

interface DropDownProps {
  items: { text: string; action: React.MouseEventHandler<HTMLDivElement> }[];
}

function DropDown({ items }: DropDownProps) {
  const [opened, setOpened] = useState<boolean>(false);

  const dropDownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(() => setOpened(false), dropDownRef);

  return (
    <div ref={dropDownRef} className="dropdown__wrapper">
      <IconButton2
        color={Button2ColorEnum.GHOST}
        icon={<FontAwesomeIcon icon={faEllipsisH} />}
        onClick={() => {
          setOpened(prevState => !prevState);
        }}
        className={opened ? 'active' : ''}
      />
      {opened && (
        <div className="dropdown__list" onClick={() => setOpened(false)}>
          {items.map((dropDownItem, index) => (
            <div className="dropdown__item" onClick={dropDownItem.action} key={index}>
              {dropDownItem.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropDown;
