import { useTranslation } from 'react-i18next';

import WaitingInvoiceResultText from 'components/stateless/ResultTextByStatus/WaitingInvoiceResultText';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import type { CURRENCY_TYPE } from 'enums';
import type { LastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import type { BigNumber } from 'utils/bigNumber';

interface InvoiceRegistrationModalCompletePropsType {
  isBoeNumberShow?: boolean;
  dataList: LastInvoicePhaseVOModel | LastInvoicePhaseVOModel[];
  totalCount: number;
  totalAmount: BigNumber;
  currencyType: CURRENCY_TYPE | undefined;
}

function InvoiceRegistrationModalComplete({
  isBoeNumberShow = false,
  dataList,
  totalCount,
  totalAmount,
  currencyType,
}: InvoiceRegistrationModalCompletePropsType) {
  const { t } = useTranslation(['format']);

  const additionalHeaders: HeaderType[] | undefined = Array.isArray(dataList)
    ? dataList?.[0]?.invoiceList.content?.[0]?.invoiceAdditionalDataList?.map(columnName => ({
        headerText: columnName.name,
        className: 'text-center',
      }))
    : dataList?.invoiceList?.content?.[0]?.invoiceAdditionalDataList?.map(columnName => ({
        headerText: columnName.name,
        className: 'text-center',
      }));

  const noBoeTableHeaders: HeaderType[] = [
    {
      headerText: t('text:Invoice_Number'),
      className: 'text-center',
    },
    {
      headerText: t('text:Reference_Number'),
      className: 'text-center',
    },
    {
      headerText: t('text:Invoice_Issued_Date'),
      className: 'text-center',
    },
    {
      headerText: t('text:Payment_Date'),
      className: 'text-center',
    },
    {
      headerText: `${t('text:Invoice_Amount')} (${t('text:unit')}: ${currencyType ?? ''})`,
      className: 'text-center',
    },
    ...(additionalHeaders ?? []),
    {
      headerText: t('text:Validation_Result'),
      className: additionalHeaders && additionalHeaders.length > 0 ? 'd-none' : 'text-center',
    },
  ];

  const withBoeTableHeaders: HeaderType[] = [
    {
      headerText: t('text:BOE_Number'),
      className: 'text-center',
    },
    ...noBoeTableHeaders,
  ];

  const tableHeaders = isBoeNumberShow ? withBoeTableHeaders : noBoeTableHeaders;

  const bigTableHeaders: BigHeaderType[] = [
    {
      headerText: t('text:Invoice_Summary'),
      colSpan: tableHeaders.length - (additionalHeaders?.length ?? 0) - 1,
    },
    {
      headerText: t('text:Invoice_Details'),
      colSpan: additionalHeaders?.length ?? 0,
    },
    {
      headerText: t('text:Validation_Result'),
      colSpan: 1,
      rowSpan: 2,
      className: 'text-center',
    },
  ];

  const renderRegisterResultTable = () => {
    const result = Array.isArray(dataList) ? dataList : [dataList];

    return result.reduce<JSX.Element[]>((invoiceRows, invoices, invoicesIndex) => {
      return [
        ...invoiceRows,
        ...invoices.invoiceList.content.map((invoice, invoiceIndex) => (
          <Tr key={`table-${invoicesIndex}-${invoiceIndex}`}>
            {isBoeNumberShow && <Td data={invoice.billOfExchangeNo} />}
            <Td data={invoice.invoiceNumber} />
            <Td data={invoice.referenceNumber} />
            <Td data={invoice.invoiceIssuedDate} format="date" />
            <Td data={invoice.settlementDate} format="date" />
            <Td data={invoice.invoiceAmount} format="number" />
            <>
              {invoice.invoiceAdditionalDataList?.map((additionalData, additionalDataIndex) => (
                <Td key={`additional-${additionalDataIndex}`} data={additionalData.value} />
              ))}
            </>
            <WaitingInvoiceResultText
              status={invoice.waitingInvoiceStatus}
              exceptionMessage={invoice.exceptionMessage}
            />
          </Tr>
        )),
      ];
    }, []);
  };

  return (
    <>
      <h6>
        {t('text:Invoice_registration_is_complete')}
        <br />
        {t('text:Please_check_the_result_of_the_invoice_registration_below')}
        <br />
        {isBoeNumberShow ? t('text:Only_successfully_requested_invoices_will_be_submitted_for_approval') : ''}
      </h6>
      <br />
      <ul className="invoice-registration-sum">
        <li>
          • {t('text:Number_of_Registration_Success')} : <strong>{t('format:number', { value: totalCount })}</strong>
        </li>
        <li>
          • {t('text:Total_Amount_of_Registration_Success')} :{' '}
          <strong>{t('format:number', { value: totalAmount })}</strong>
        </li>
        {!isBoeNumberShow ? (
          <li>
            • {t('text:BOE_Number')} :{' '}
            <strong>
              {Array.isArray(dataList)
                ? dataList[0].invoiceList.content[0].billOfExchangeNo
                : dataList.invoiceList.content[0].billOfExchangeNo}
            </strong>
          </li>
        ) : (
          <></>
        )}
      </ul>
      <br />
      <TableBorder scrollYMaxHeight={800}>
        <TableHeader header={tableHeaders} bigHeader={additionalHeaders?.length ? bigTableHeaders : undefined} />
        <TableBody numOfCol={tableHeaders.length}>{renderRegisterResultTable()}</TableBody>
      </TableBorder>
    </>
  );
}

export default InvoiceRegistrationModalComplete;
