import type Pageable from 'models/Pageable';
import type { BasisInterestVO, BasisInterestVOModel } from 'models/vo/BasisInterestVO';
import { formattingToBasisInterestVOModel } from 'models/vo/BasisInterestVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';
import type {
  FinancierBasisInterestListRequest,
  FinancierBasisInterestRegisterByInterfaceRequest,
  FinancierBasisInterestRegisterRequest,
} from 'utils/http/api/financier/basis-interests/requests';

export async function requestFinancierBasisInterestList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierBasisInterestListRequest,
): Promise<Pageable<BasisInterestVOModel[]>> {
  const response = await http.get<Pageable<BasisInterestVO[]>>({
    url: API_FI.BASIS_INTERESTS.BASIS_INTERESTS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const basisInterestList: BasisInterestVOModel[] = response.content.map((data: BasisInterestVO) =>
    formattingToBasisInterestVOModel(data),
  );
  const basisInterestPage: Pageable<BasisInterestVOModel[]> = { ...response, content: basisInterestList };

  return basisInterestPage;
}

export async function requestFinancierBasisInterestRegister(data: FinancierBasisInterestRegisterRequest) {
  return await http.post({
    url: API_FI.BASIS_INTERESTS.BASIS_INTERESTS,
    data,
  });
}

export async function requestFinancierBasisInterestRegisterByInterface(
  data: FinancierBasisInterestRegisterByInterfaceRequest,
) {
  return await http.post({
    url: API_FI.BASIS_INTERESTS.CREATE_BASIS_INTEREST_BY_FINANCIER_INTERFACE,
    data,
  });
}

export async function requestFinancinerBasisInterestDelete(basisInterestId: number): Promise<{}> {
  const response = await http.delete<{}>({
    url: API_FI.BASIS_INTERESTS.DELETE_BASIS_INTEREST(basisInterestId),
  });

  return response;
}
