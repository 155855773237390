import { useTranslation } from 'react-i18next';

import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import type { ColumnOption, MergedCellTypes } from 'utils/spreadSheet/types';

export function useSystemOverviewBySCPartnerListVendorTabConstants() {
  const { t } = useTranslation(['format']);
  const excelColumnsAr: ColumnOption<StatisticsOfDealerAgreementVOModel>[] = [
    {
      header: t('text:Financier_Name'),
      key: 'financierName',
    },
    {
      header: t('text:Associated_Anchor_Name'),
      key: 'anchorClientName',
    },
    {
      header: t('text:Associated_Anchor_Client_Code'),
      key: 'anchorClientCode',
    },
    {
      header: t('text:Anchor_Master_Agreement_Number'),
      key: 'anchorAgreementContractNo',
    },
    {
      header: t('text:Automatic_Settlement'),
      key: 'paymentSupport',
    },
    {
      header: t('text:Partner_Name'),
      key: 'dealerClientName',
    },
    {
      header: t('text:Partner_Master_Agreement_Number'),
      key: 'contractNo',
    },
    {
      header: t('text:Currency'),
      key: 'currencyType',
    },
    {
      header: t('text:Effective_Date'),
      key: 'startDate',
    },
    {
      header: t('text:Expiration_Date'),
      key: 'expiryDate',
    },
    {
      header: t('text:Base_Interest_Rate'),
      key: 'basisInterestRate',
    },
    {
      header: t('text:Financing_Limit'),
      key: 'loanLimitAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Outstanding_Financing_Balance'),
      key: 'currentLoanBalanceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Outstanding_Financing'),
      key: 'currentDisbursedLoanCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Remaining_Limit'),
      key: 'remainingLimit',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Pending_AR_Amount'),
      key: 'currentSettlementWaitingInvoiceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Pending_AR'),
      key: 'currentSettlementWaitingInvoiceCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Delinquent_Status'),
      key: 'currentOverdueLoanCount',
    },
    {
      header: t('text:Total_Registered_AR_Amount'),
      key: 'registeredInvoiceAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Registered_AR'),
      key: 'registeredInvoiceCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Financing_Amount'),
      key: 'totalDisbursedLoanAmount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Financing'),
      key: 'totalDisbursedLoanCount',
      style: {
        alignment: { horizontal: 'right' },
      },
    },
  ];

  const arMergedCells: MergedCellTypes[] = [
    {
      target: 'A:A',
      rowIndex: 1,
      value: t('text:Financier_Information'),
    },
    {
      target: 'B:E',
      rowIndex: 1,
      value: t('text:Anchor_Information'),
    },
    {
      target: 'F:L',
      rowIndex: 1,
      value: t('text:Partner_Information'),
    },
    {
      target: 'M:R',
      rowIndex: 1,
      value: t('text:Current_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFFCDE' },
        },
      },
    },
    {
      target: 'S:V',
      rowIndex: 1,
      value: t('text:Accumulated_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFF8BD' },
        },
      },
    },
  ];
  const arMergedTableHeader: BigHeaderType[] = [
    {
      headerText: t('text:Financier_Information'),
      colSpan: 1,
    },
    {
      headerText: t('text:Anchor_Information'),
      colSpan: 4,
    },
    {
      headerText: t('text:Partner_Information'),
      colSpan: 7,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 6,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 4,
      className: 'main200',
    },
  ];

  const arTableHeader: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 165,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 165,
    },
    {
      headerText: t('text:Associated_Anchor_Client_Code'),
      colWidths: 165,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 165,
    },
    {
      headerText: t('text:Automatic_Settlement'),
      colWidths: 165,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 165,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 165,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Base_Interest_Rate'),
      colWidths: 165,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 180,
    },
    {
      headerText: t('text:Outstanding_Financing_Balance'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Number_of_Outstanding_Financing'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Remaining_Limit'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Pending_AR_Amount'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Number_of_Pending_AR'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Delinquent_Status'),
      colWidths: 100,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Registered_AR_Amount'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Number_of_Registered_AR'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Financing_Amount'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Number_of_Financing'),
      colWidths: 180,
      className: 'main200',
    },
  ];

  const AR_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Associated_Anchor_Client_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 170,
    },
    {
      headerText: t('text:Automatic_Settlement'),
      colWidths: 180,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Base_Interest_Rate'),
      colWidths: 180,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Balance_/_Count'),
      colWidths: 165,
      className: 'main100',
    },
    {
      headerText: t('text:Remaining_Limit'),
      colWidths: 120,
      className: 'main100',
    },
    {
      headerText: t('text:Pending_AR_Amount_/_Count'),
      colWidths: 165,
      className: 'main100',
    },
    {
      headerText: t('text:Delinquent_Status'),
      colWidths: 87,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Registered_AR_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Financing_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
  ];
  const AR_BIG_TABLE_HEADER: BigHeaderType[] = [
    {
      headerText: t('text:Financier_Information'),
      colSpan: 1,
    },
    {
      headerText: t('text:Anchor_Information'),
      colSpan: 4,
    },
    {
      headerText: t('text:Partner_Information'),
      colSpan: 7,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 4,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 2,
      className: 'main200',
    },
  ];

  return { excelColumnsAr, arMergedCells, arMergedTableHeader, arTableHeader, AR_TABLE_HEADER, AR_BIG_TABLE_HEADER };
}
