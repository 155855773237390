import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import type { CalculateForMultipleLoanRequestVOModel } from 'models/vo/CalculateForMultipleLoanVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { BigNumber } from 'utils/bigNumber';
import type { CreateDeMultipleRequestLoanByArRequest } from 'utils/http/api/dealer/multiple-request-loans/request';

interface ARBulkFinancingApplicationSummaryProps {
  totalRequestedAmount: BigNumber | undefined;
  dealerAgreementDetail: DealerAgreementDetailVOModel;
  selectedTotalArAmount: BigNumber | undefined;
  dealerArBulkCalculateLoanRequest?: CalculateForMultipleLoanRequestVOModel;
}

function ARBulkFinancingApplicationSummary({
  totalRequestedAmount,
  dealerAgreementDetail,
  dealerArBulkCalculateLoanRequest,
  selectedTotalArAmount,
}: ARBulkFinancingApplicationSummaryProps) {
  const { t } = useTranslation(['format']);
  const { watch } = useFormContext<CreateDeMultipleRequestLoanByArRequest>();
  const { disbursementDate } = watch();

  return (
    <FormBorder>
      <FormContents>
        <div className="row">
          <FormValue
            label={t('text:Requested_Financing_Amount')}
            value={`${t('format:number', { value: totalRequestedAmount })} ${dealerAgreementDetail.currencyType}`}
          />
          <FormValue label={t('text:Requested_Disbursement_Date')} value={disbursementDate} format="date" />
        </div>

        <div className="row">
          <FormValue
            label={t('text:Selected_AR_Amount')}
            value={`${t('format:number', { value: selectedTotalArAmount })} ${dealerAgreementDetail.currencyType}`}
          />
          <FormValue
            label={t('text:Advance_Disbursement')}
            value={`${t('format:number', { value: dealerArBulkCalculateLoanRequest?.totalNetDisbursementAmount })} ${
              dealerAgreementDetail.currencyType
            }`}
          />
        </div>
        <div className="row">
          <FormValue
            label={t('text:Discount_Charge')}
            value={`${t('format:number', { value: dealerArBulkCalculateLoanRequest?.expectedTotalInterestAmount })} ${
              dealerAgreementDetail.currencyType
            }`}
          />
          <FormValue
            label={t('text:Discount_Rate_APR')}
            value={`${dealerArBulkCalculateLoanRequest?.totalInterestRate} %`}
          />
        </div>
      </FormContents>
    </FormBorder>
  );
}

export default ARBulkFinancingApplicationSummary;
