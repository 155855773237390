import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CheckPluginValid } from 'digital-signatures/bkav/CheckPluginValid';
import { CheckPluginGetCert } from 'digital-signatures/utils/checkPluginGetCert';
import { isNil } from 'lodash-es';

import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import ExpectedFinancingRepaymentModal from 'components/stateless/Modal/common/loan/ExpectedFinancingRepaymentModal';
import { COLLATERAL_TYPE, FINANCIER_INTERFACE_TYPE, OTP_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type { EarlyRepaymentRequestVOModel } from 'models/vo/EarlyRepaymentRequestVO';
import type { ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import type { LoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestFinancierInterfaceSetting } from 'utils/http/api/common/financier-interface-setting';
import { requestDealerClientAuthByDealerAgreementId } from 'utils/http/api/dealer/client-auth-setting';
import { requestDealerEarlyRepaymentList } from 'utils/http/api/dealer/early-repayment-requests';
import { requestDealerExtensionList } from 'utils/http/api/dealer/extension-requests';
import { requestDealerFinancierCalendar } from 'utils/http/api/dealer/financier-calendar';
import {
  requestDealerCalculateExpectedAmount,
  requestDealerDownloadFactoringDocsForEsign,
  requestDealerLoanAdditionalDocumentAttachments,
  requestDealerLoanApproval,
  requestDealerLoanCancel,
  requestDealerLoanDetail,
  requestDealerLoanTransaction,
  requestDealerRenewLoanByFinancierInterface,
} from 'utils/http/api/dealer/loans';
import { requestDealerSuccessArsDetail } from 'utils/http/api/dealer/success-ars';
import { requestDealerInvoiceDetail } from 'utils/http/api/dealer/success-invoices';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';

export function useDealerFinancingDetailState() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { loanId } = useParams<any>();

  const [dataState, setDataState] = useState({
    deLoanDetail: {} as LoanDetailVOModel,
    deInvoiceDetail: {} as SuccessInvoiceDetailVOModel,
    deArDetail: {} as SuccessArDetailVOModel,
    deEarlyRepaymentList: [] as EarlyRepaymentRequestVOModel[],
    deExtensionList: [] as ExtensionRequestVOModel[],
    deLoanTransactionList: [] as LoanTransactionVOModel[],
    isNilInvoiceIdAndSuccessArId: false as boolean,
    useLoanResultInterface: false as boolean,
    requiredAdditionalDocuments: [] as LoanAdditionalDocumentAttachmentVOModel[],
  });

  const { pageable: earlyRepaymentPageable, setPageable: setEarlyRepaymentPageable } = usePageable();
  const { pageable: extensionPageable, setPageable: setExtensionPageable } = usePageable();
  const { pageable: loanTransactionHistoryPageable, setPageable: setLoanTransactionHistoryPageable } = usePageable();

  const fetchAll = async () => {
    let isNilInvoiceIdAndSuccessArId: boolean;

    try {
      const [
        fetchedLoanDetail,
        fetchedLoanTransactionList,
        fetchedEarlyRepaymentList,
        fetchedExtensionList,
        fetchAdditionalDocumentAttachments,
      ] = await Promise.all([
        requestDealerLoanDetail(loanId),
        requestDealerLoanTransaction(loanId, 0, 10),
        requestDealerEarlyRepaymentList(0, 10, {
          loanId,
        }),
        requestDealerExtensionList(0, 10, { loanId }),
        requestDealerLoanAdditionalDocumentAttachments(loanId, 0, 1000),
      ]);

      isNilInvoiceIdAndSuccessArId = isNil(fetchedLoanDetail.invoiceId) && isNil(fetchedLoanDetail.successArId);

      setDataState(prevState => ({
        ...prevState,
        deLoanDetail: fetchedLoanDetail,
        deLoanTransactionList: [...fetchedLoanTransactionList.content],
        deEarlyRepaymentList: [...fetchedEarlyRepaymentList.content],
        deExtensionList: [...fetchedExtensionList.content],
        isNilInvoiceIdAndSuccessArId: isNilInvoiceIdAndSuccessArId,
        requiredAdditionalDocuments: [...fetchAdditionalDocumentAttachments.content],
      }));

      if (!isNilInvoiceIdAndSuccessArId) {
        if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.INVOICE) {
          const [successInvoiceInformationResponse, useLoanResultInterfaceResponse] = await Promise.all([
            requestDealerInvoiceDetail(fetchedLoanDetail.invoiceId),
            requestFinancierInterfaceSetting(fetchedLoanDetail.financierId, FINANCIER_INTERFACE_TYPE.LOAN_RESULT),
          ]);

          setDataState(prevState => ({
            ...prevState,
            deInvoiceDetail: successInvoiceInformationResponse,
            useLoanResultInterface: useLoanResultInterfaceResponse,
          }));
        }

        if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.AR) {
          const [successArInformationResponse, useLoanResultInterfaceResponse] = await Promise.all([
            requestDealerSuccessArsDetail(fetchedLoanDetail.successArId),
            requestFinancierInterfaceSetting(fetchedLoanDetail.financierId, FINANCIER_INTERFACE_TYPE.LOAN_RESULT_AR),
          ]);

          setDataState(prevState => ({
            ...prevState,
            deArDetail: successArInformationResponse,
            useLoanResultInterface: useLoanResultInterfaceResponse,
          }));
        }
      }

      setEarlyRepaymentPageable(fetchedEarlyRepaymentList);
      setLoanTransactionHistoryPageable(fetchedLoanTransactionList);
      setExtensionPageable(fetchedExtensionList);
    } catch (e: any) {
      modal.show(e);
    }
  };

  async function getLoanTransactionHistoryPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestDealerLoanTransaction(loanId, pageNumber, rowCount);
      setLoanTransactionHistoryPageable(response);
      setDataState(prevState => ({
        ...prevState,
        deLoanTransactionList: [...response.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  }

  async function getEarlyRepaymentListPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestDealerEarlyRepaymentList(pageNumber, rowCount, { loanId });
      setEarlyRepaymentPageable(response);
      setDataState(prevState => ({
        ...prevState,
        deEarlyRepaymentList: [...response.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  }

  async function getExtensionListPaginate(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const response = await requestDealerExtensionList(pageNumber, rowCount, { loanId });
      setExtensionPageable(response);
      setDataState(prevState => ({
        ...prevState,
        deExtensionList: [...response.content],
      }));
    } catch (e: any) {
      modal.show(e);
    }
  }

  const onClickUpdateTransaction = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerRenewLoanByFinancierInterface(loanId);
      modal.show(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
        closeBtnCb: fetchAll,
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickViewExpectedRepaymentAmount = async (e: any) => {
    e.preventDefault();
    const { financierId, repaymentDate } = dataState.deLoanDetail;

    const expectedRepaymentDate = new Date(dataState.deLoanDetail.repaymentDate);
    const expectedRepaymentDateList: number[] = [
      expectedRepaymentDate.getFullYear(),
      expectedRepaymentDate.getMonth(),
      expectedRepaymentDate.getDate(),
    ];
    const toDate = new Date(
      expectedRepaymentDateList[0],
      expectedRepaymentDateList[1],
      expectedRepaymentDateList[2] + 180,
    );

    async function fetchAll() {
      const [expectedFinancingRepaymentDataResponse, financierHolidayResponse] = await Promise.all([
        requestDealerCalculateExpectedAmount(loanId, repaymentDate),
        requestDealerFinancierCalendar(financierId, {
          pageNumber: 0,
          rowCount:
            Number(
              getDayTerm(
                convertToServerDateFormat(new Date(dataState.deLoanDetail.disbursedDate)),
                convertToServerDateFormat(toDate),
              ),
            ) + 1,
          fromDate: convertToServerDateFormat(new Date(dataState.deLoanDetail.disbursedDate)),
          toDate: convertToServerDateFormat(toDate),
          holiday: true,
        }),
      ]);

      return { expectedFinancingRepaymentDataResponse, financierHolidayResponse };
    }

    modal.show(
      <ExpectedFinancingRepaymentModal
        loanId={loanId}
        repaymentDate={repaymentDate}
        fetchAll={fetchAll}
        disbursedDate={dataState.deLoanDetail.disbursedDate}
        maxDate={toDate}
        requestSearch={requestDealerCalculateExpectedAmount}
        factoringEnable={dataState.deLoanDetail.factoringEnable}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.L,
        title: t('text:View_Expected_Repayment_Amount'),
      },
    );
  };

  const submitFinancingCancel = async (rejectReason: string) => {
    const showLoanCancelConfirmModal = () => {
      modal.show(<h6>{t('text:Financing_approval_request_is_successfully_cancelled')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    };

    try {
      await requestDealerLoanCancel(loanId, rejectReason);
      showLoanCancelConfirmModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickCancelRequestOfOperator = () => {
    let reason = '';

    modal.show(
      <>
        <h6>
          {t('text:Would_you_like_to_cancel_the_financing_application_approval_request?')}
          <br />
          {t(
            'text:If_you_cancel_the_request_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
          )}
          <br />
          {t('text:Please_enter_the_reason_for_cancelling_the_financing_application_below')}
        </h6>
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Cancel_Request'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          await submitFinancingCancel(reason);
        },
        confirmBtnDisabled: true,
      },
    );
  };

  const onClickCancelOfAuthorizer = () => {
    let reason = '';

    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_financing_application?')}
        <br />
        {t(
          'text:If_you_cancel_the_financing_application_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
        )}
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Cancel_Financing_Request'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          await submitFinancingCancel(reason);
        },
        confirmBtnDisabled: true,
      },
    );
  };

  const executeDigitalSignature = async () => {
    const { dealerAgreementId } = dataState.deLoanDetail;
    showLoadingUI();

    try {
      const factoringDocs = await requestDealerDownloadFactoringDocsForEsign(loanId);

      CheckPluginValid.CheckPluginValid((data: any) =>
        CheckPluginGetCert({
          data,
          modal,
          PDFInfos: factoringDocs,
          pdfSignCompletedCb: async (loanAgreementFileList: FileList, loanFactoringNotificationFileList: FileList) => {
            await requestDealerLoanApproval(loanId, {
              dealerAgreementId,
              loanAgreementAttachment: loanAgreementFileList,
              loanFactoringNotificationAttachment: loanFactoringNotificationFileList,
            });
            modal.show(
              <h6>
                {t('text:The_financing_confirmation_has_been_completed')}
                <br />
                {t(
                  'text:You_can_monitor_the_details_and_progress_of_the_financing_applied_from_the_View_Transaction_Financing_menu',
                )}
              </h6>,
              {
                modalType: ModalType.ALERT,
                title: t('text:Notice'),
                closeBtnText: t('text:OK'),
                closeBtnCb: async () => {
                  await fetchAll();
                  scrollToTopOfElement();
                },
              },
            );
          },
        }),
      );
    } catch (e) {
      unShowLoadingUI();
      modal.show(e);
    }

    return;
  };

  const onClickSubmitFinancingApplicationOfAuthorizer = async () => {
    const { eSignatureEnable } = dataState.deLoanDetail;
    const showAuthorizerApproveConfirmModal = () => {
      modal.show(
        <h6>
          {t('text:The_financing_confirmation_has_been_completed')}
          <br />
          {t(
            'text:You_can_monitor_the_details_and_progress_of_the_financing_applied_from_the_View_Transaction_Financing_menu',
          )}
        </h6>,
        {
          modalType: ModalType.ALERT,
          title: t('text:Notice'),
          closeBtnText: t('text:OK'),
          closeBtnCb: async () => {
            await fetchAll();
            scrollToTopOfElement();
          },
        },
      );
    };

    if (eSignatureEnable) {
      await executeDigitalSignature();

      return;
    }

    try {
      const { dealerAgreementId } = dataState.deLoanDetail;

      const clientAuthTypeData = await requestDealerClientAuthByDealerAgreementId(dealerAgreementId);
      if (clientAuthTypeData.otpType !== OTP_TYPE.NONE) {
        const verificationCode: UserVerificationCodeRequest = {};

        modal.show(
          <UserVerificationModal
            modalId={modal.id}
            verificationCode={verificationCode}
            requestIdType="dealerAgreementId"
            requestId={dealerAgreementId}
            clientAuthSetting={clientAuthTypeData}
          />,
          {
            modalSize: ModalSize.NONE,
            modalType: ModalType.CONFIRM,
            closeBtnText: t('text:Cancel'),
            confirmBtnText: t('text:Confirm'),
            confirmBtnCb: async () => {
              try {
                await requestDealerLoanApproval(loanId, {
                  dealerAgreementId,
                  otpCode: verificationCode.otpCode,
                  queryValue: verificationCode.queryValue,
                });
                showAuthorizerApproveConfirmModal();
              } catch (e) {
                modal.show(e);
              }
            },
          },
        );
      } else {
        await requestDealerLoanApproval(loanId, { dealerAgreementId });
        showAuthorizerApproveConfirmModal();
      }
    } catch (error) {
      modal.show(error);
    }
  };

  return {
    state: dataState,
    fetchAll,
    pageables: {
      earlyRepaymentPageable,
      extensionPageable,
      loanTransactionHistoryPageable,
    },
    paginates: {
      getLoanTransactionHistoryPaginate,
      getEarlyRepaymentListPaginate,
      getExtensionListPaginate,
    },
    updateFinancingStatusActions: {
      onClickUpdateTransaction,
      onClickSubmitFinancingApplicationOfAuthorizer,
      onClickCancelRequestOfOperator,
      onClickCancelOfAuthorizer,
      onClickViewExpectedRepaymentAmount,
    },
  };
}
