import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { SUCCESS_AR_STATUS } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { ArSettlementDetailVOModel } from 'models/vo/ArSettlementDetailVO';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestAnchorSettlementDetail } from 'utils/http/api/anchor/ar-settlements';
import { requestAnchorSuccessArsList } from 'utils/http/api/anchor/success-ars';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableNumberValueIsZero, tableValueManage } from 'utils/valueManager/ValueManager';

function AnchorApSettlementDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { anchorAgreementId } = useParams<any>();

  const [anchorApSettlementDetail, setAnchorApSettlementDetail] = useState<ArSettlementDetailVOModel>();
  const [anchorApList, setAnchorApList] = useState<Pageable<SuccessArVOModel[]>>();

  const { pageable, setPageable } = usePageable();

  const [locationState, errorHandlerOfLocationState] = useLocationState<{ settlementDate: string }>(['settlementDate']);
  const { settlementDate } = locationState;

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const fetchedAnchorApSettlementDetailPage = await requestAnchorSettlementDetail(
        anchorAgreementId,
        settlementDate,
      );
      const fetchedAnchorApListPage = await requestAnchorSuccessArsList(pageable.currentPage, pageable.sizePerPage, {
        anchorAgreementId: anchorAgreementId,
        settlementDateFrom: settlementDate,
        settlementDateTo: settlementDate,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorApSettlementDetail(fetchedAnchorApSettlementDetailPage);
        setAnchorApList(fetchedAnchorApListPage);
        setPageable(fetchedAnchorApListPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginate = async (page: number, sizePerPage: number) => {
    try {
      const fetchedAnchorApListPage = await requestAnchorSuccessArsList(page, sizePerPage, {
        anchorAgreementId: anchorAgreementId,
        settlementDateFrom: settlementDate,
        settlementDateTo: settlementDate,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorApList(fetchedAnchorApListPage);
        setPageable(fetchedAnchorApListPage);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  // 만기일자 경과 시 표시
  const overDaysToMaturity = (daysToMaturity: number | undefined) => {
    if (isNil(daysToMaturity) || daysToMaturity <= 0) {
      return '-';
    } else {
      return `D - ${daysToMaturity}`;
    }
  };

  const renderTableResult = () => {
    return anchorApList?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.arNumber} />
          <Td data={item.anchorPartnerName} />
          <Td data={item.arAmount} format="number" />
          <Td
            className={getStatusTextClass('SUCCESS_AR_STATUS', item.successArStatus)}
            data={t(`code:success-ar-status.${item.successArStatus}`)}
            format="code"
          />
          <TdLink
            path={ROUTES_AC.VIEW_TRANSACTION.AP_DETAIL_BUILD_PATH(item.successArId)}
            state={{ financierId: item.financierId }}
          />
        </Tr>
      );
    });
  };

  const onClickSettlementStatusHelpModal = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_AR_STATUS)}
        statusDescriptionEnumType="SUCCESS_AR_STATUS"
        statusDescriptionModalType="TEXT"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
      },
    );
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:AP_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:AP_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Settlement_Status'),
      colWidths: 120,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickSettlementStatusHelpModal,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <BackHeaderTitle
        title={
          `${t('text:AP_Settlement_Details')}` +
          ` [${tableValueManage(
            anchorApSettlementDetail?.settlementDate,
            t('format:date', { value: anchorApSettlementDetail?.settlementDate, key: 'date' }),
          )}]`
        }
      />
      <GuideMessage
        message={[
          t(
            'text:Review_the_AP_Settlement_information_below_as_the_settlement_payment_will_be_processed_on_the_settlement_date',
          ),
          t(
            'text:If_the_AP_is_used_for_a_financing_application_repayments_will_be_processed_automatically_after_the_settlement_payments',
          ),
          t(
            'text:Ensure_that_the_AP_settlement_amount_is_deposited_in_the_designated_bank_account_by_the_settlement_date_as_the_AP_settlements_may_not_be_processed_if_there_is_insufficient_balance',
          ),
        ]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:AP_Settlement_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Days_Until_Settlement')}
                col={3}
                className="information-form__input border-none pointfont"
                value={overDaysToMaturity(anchorApSettlementDetail?.daysToMaturity)}
              />
              <FormValue
                label={t('text:Settlement_Date')}
                col={3}
                value={anchorApSettlementDetail?.settlementDate}
                format="date"
              />
              <FormValue label={t('text:Financier_Name')} col={3} value={anchorApSettlementDetail?.financierName} />
              <FormValue label={t('text:Currency')} col={3} value={anchorApSettlementDetail?.currencyType} />
            </div>
          </FormContents>
          <FormContents backGroundType={BackGroundType.Gray}>
            <div className="row">
              <FormValue
                label={t('text:Total_Number_of_AP')}
                value={anchorApSettlementDetail?.totalArCount}
                format="number"
                className="information-form__input border-none bold-font"
              />
              <FormValue
                label={t('text:Total_Amount_of_AP')}
                value={anchorApSettlementDetail?.totalArAmount}
                format="number"
                className="information-form__input border-none bold-font"
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:AP_Settlement_Result')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Total_Number_of_AP')}
                value={anchorApSettlementDetail?.totalArCount}
                format="number"
                className="information-form__input border-none bold-font"
              />
              <FormValue
                label={t('text:Total_Amount_of_AP')}
                value={anchorApSettlementDetail?.totalArAmount}
                format="number"
                className="information-form__input border-none bold-font"
              />
            </div>
          </FormContents>
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Number_of_Pending_AP')}
                value={tableNumberValueIsZero(anchorApSettlementDetail?.arRequestedCount)}
              />
              <FormValue
                label={t('text:Amount_of_Pending_AP')}
                value={tableNumberValueIsZero(anchorApSettlementDetail?.arRequestedAmount)}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Number_of_Completed_AP')}
                value={tableNumberValueIsZero(anchorApSettlementDetail?.arSettledCount)}
              />
              <FormValue
                label={t('text:Amount_of_Completed_AP')}
                value={tableNumberValueIsZero(anchorApSettlementDetail?.arSettledAmount)}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Number_of_Failed_AP')}
                value={tableNumberValueIsZero(anchorApSettlementDetail?.arFailedCount)}
              />
              <FormValue
                label={t('text:Amount_of_Failed_AP')}
                value={tableNumberValueIsZero(anchorApSettlementDetail?.arFailedAmount)}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:AP_List')} />
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderTableResult()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorApSettlementDetail;
