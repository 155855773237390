import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import FormInput from 'components/stateless/CommonForm/FormInput';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import { ROUTES_CM } from 'constants/routes/common';
import useProperty from 'hooks/useProperty';
import { formErrorHandler } from 'utils/error/manager';
import { requestUserFindPassword } from 'utils/http/api/anonymous/users';
import type { FindPasswordRequest } from 'utils/http/api/anonymous/users/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

function FindPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();
  const getProperty = useProperty<FindPasswordRequest>();

  const { register, handleSubmit, getValues, errors, setError, clearErrors } = useForm<FindPasswordRequest>({
    mode: 'onSubmit',
  });

  const onClickFindPassword = async () => {
    const data = getValues();
    try {
      // response : false -> 이메일 아이디 매칭 실패
      // response : true -> 이메일 정상 발송 완료
      const response = await requestUserFindPassword(data);
      response
        ? history.push({
            pathname: ROUTES_CM.FIND_PASSWORD_EMAIL_SENT,
            state: data,
          })
        : showInfoErrorModal();
    } catch (e) {
      modal.show(e);
      formErrorHandler<FindPasswordRequest>(e, setError, clearErrors);
    }
  };

  const showInfoErrorModal = () => {
    modal.show(
      <h6>
        {t('text:The_Information_is_not_valid')}
        <br />
        {t('text:Please_check_your_information')}
      </h6>,
      {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
      },
    );
  };

  return (
    <>
      <PublicHeaderBar />
      <div className="align-middle row">
        <div className="find-form">
          <h2 className="login-form__title">{t('text:Forgot_Your_Password')}</h2>
          <div className="border-bottom-light-gray mb-3" />
          <form>
            <div className="login-form__input">
              <FormInput
                label={t(`text:ID`)}
                placeholder={t('text:Please_type_here')}
                name={getProperty('loginId')}
                ref={register}
                col={12}
                error={errors.loginId}
              />
            </div>
            <div className="login-form__input mt-3">
              <FormInput
                label={t(`text:Email`)}
                placeholder={t('text:Please_type_here')}
                name={getProperty('email')}
                ref={register}
                col={12}
                error={errors.email}
              />
            </div>
            <div className="mt-3">
              <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onClickFindPassword)} type="submit">
                {t('text:Find_Password')}
              </Button>
            </div>

            <Link to="/">
              <div className="sign-in-link">{t('text:Back_to_Sign_in')}</div>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
}

export default FindPassword;
