import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import { ENTERPRISE_STATUS, ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemEnterpriseList } from 'utils/http/api/system/enterprises';
import type { SystemEnterpriseListRequest } from 'utils/http/api/system/enterprises/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const SEARCHED_SYSTEM_MONITOR_PLATFORM_ENTERPRISE_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Enterprise_ID'),
    },
    {
      headerText: t('text:Enterprise_Name'),
    },
    {
      headerText: t('text:Tax_Code'),
    },
    {
      headerText: t('text:Company_Registration_Number'),
    },
    {
      headerText: t('text:Enterprise_Type'),
    },
    {
      headerText: t('text:Enterprise_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_ENTERPRISE_LIST_QS_KEY = 'sy-enterprise-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_SYSTEM_MONITOR_PLATFORM_ENTERPRISE_TABLE_HEADERS,
    SY_ENTERPRISE_LIST_QS_KEY,
  };
};

function SystemMonitorPlatformEnterpriseList(): JSX.Element {
  const { mounted, modal, t, SEARCHED_SYSTEM_MONITOR_PLATFORM_ENTERPRISE_TABLE_HEADERS, SY_ENTERPRISE_LIST_QS_KEY } =
    getConstants();

  const [systemMonitorPlatformEnterprisePage, setSystemMonitorPlatformEnterprisePage] =
    useState<Pageable<EnterpriseVOModel[]>>();

  const {
    register: systemMonitorPlatformEnterpriseSearchFormRegister,
    getValues: getSystemMonitorPlatformEnterpriseSearchFormValues,
    reset: resetSystemMonitorPlatformEnterpriseSearchForm,
    setValue: setSystemMonitorPlatformEnterpriseSearchFormValue,
  } = useForm<SystemEnterpriseListRequest>();

  const {
    pageable: searchedSystemMonitorPlatformEnterprisePageable,
    setPageable: setSearchedSystemMonitorPlatformEnterprisePageable,
  } = usePageable(SY_ENTERPRISE_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemEnterpriseListRequest>(
        setSystemMonitorPlatformEnterpriseSearchFormValue,
        getParsedSearchParams(SY_ENTERPRISE_LIST_QS_KEY).formSearchData,
      );
      fetchSyEnterpriseList(
        searchedSystemMonitorPlatformEnterprisePageable.currentPage,
        searchedSystemMonitorPlatformEnterprisePageable.sizePerPage,
        getSystemMonitorPlatformEnterpriseSearchFormValues(),
      );
    }
  }, [mounted]);

  const fetchSyEnterpriseList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemEnterpriseListRequest,
  ): Promise<void> => {
    try {
      const systemMonitorPlatformEnterpriseResponse = await requestSystemEnterpriseList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_ENTERPRISE_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setSystemMonitorPlatformEnterprisePage(systemMonitorPlatformEnterpriseResponse);
        setSearchedSystemMonitorPlatformEnterprisePageable(systemMonitorPlatformEnterpriseResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    resetSystemMonitorPlatformEnterpriseSearchForm();
  };

  const onClickSearch = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    await fetchSyEnterpriseList(
      1,
      searchedSystemMonitorPlatformEnterprisePageable.sizePerPage,
      getSystemMonitorPlatformEnterpriseSearchFormValues(),
    );
  };

  const searchedSystemMonitorPlatformEnterprisePaginate = async (
    pageNumber: number,
    rowCount: number,
  ): Promise<void> => {
    await fetchSyEnterpriseList(pageNumber, rowCount, getParsedSearchParams(SY_ENTERPRISE_LIST_QS_KEY).formSearchData);
  };

  const renderSearchedSystemMonitorPlatformEnterpriseTableBody = (): JSX.Element[] | undefined => {
    const getEnterpriseTypeText = (enterpriseType: ENTERPRISE_TYPE): string => {
      switch (enterpriseType) {
        case ENTERPRISE_TYPE.AC:
          return t('text:Anchor');
        case ENTERPRISE_TYPE.DE:
          return t('text:Partner');
        case ENTERPRISE_TYPE.FI:
          return t('text:Financier');
        case ENTERPRISE_TYPE.SY:
          return t('text:System');
        default:
          return '';
      }
    };

    const getEnterpriseStatusText = (enterpriseStatus: ENTERPRISE_STATUS): string => {
      switch (enterpriseStatus) {
        case ENTERPRISE_STATUS.ACTIVATED:
          return t('text:Activated');
        case ENTERPRISE_STATUS.REGISTERED:
          return t('text:Registered');
        case ENTERPRISE_STATUS.SUSPENDED:
          return t('text:Suspended');
        default:
          return '';
      }
    };

    const getEnterpriseStatusClassName = (enterpriseStatus: ENTERPRISE_STATUS): string => {
      switch (enterpriseStatus) {
        case ENTERPRISE_STATUS.ACTIVATED:
          return 'text-bold-blue-main600';
        case ENTERPRISE_STATUS.REGISTERED:
          return 'text-bold-sub700';
        case ENTERPRISE_STATUS.SUSPENDED:
          return 'text-bold-brick-red';
        default:
          return '';
      }
    };

    return systemMonitorPlatformEnterprisePage?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.enterpriseId} />
        <Td data={data.enterpriseName} />
        <Td data={data.taxCode} />
        <Td data={data.businessCode} />
        <Td data={getEnterpriseTypeText(data.enterpriseType)} />
        <Td
          className={getEnterpriseStatusClassName(data.enterpriseStatus)}
          data={getEnterpriseStatusText(data.enterpriseStatus)}
        />
        <TdLink path={ROUTES_SY.MONITOR_PLATFORM.ENTERPRISE_DETAIL_BUILD_PATH(data.enterpriseId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Enterprise')} />
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Enterprise_ID')} />
              <SearchInput name="enterpriseId" ref={systemMonitorPlatformEnterpriseSearchFormRegister} />
              <SearchLabel label={t('text:Enterprise_Name')} />
              <SearchInput name="enterpriseName" ref={systemMonitorPlatformEnterpriseSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Tax_Code')} />
              <SearchInput name="taxCode" ref={systemMonitorPlatformEnterpriseSearchFormRegister} />
              <SearchLabel label={t('text:Company_Registration_Number')} />
              <SearchInput name="businessCode" ref={systemMonitorPlatformEnterpriseSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Enterprise_Type')} />
              <SearchSelect
                name="enterpriseType"
                ref={systemMonitorPlatformEnterpriseSearchFormRegister}
                selectOptions={getSelectOptions('ENTERPRISE_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Enterprise_Status')} />
              <SearchSelect
                name="enterpriseStatus"
                ref={systemMonitorPlatformEnterpriseSearchFormRegister}
                selectOptions={getSelectOptions('ENTERPRISE_STATUS', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {searchedSystemMonitorPlatformEnterprisePageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_SYSTEM_MONITOR_PLATFORM_ENTERPRISE_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_SYSTEM_MONITOR_PLATFORM_ENTERPRISE_TABLE_HEADERS.length}>
            {renderSearchedSystemMonitorPlatformEnterpriseTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination
          pageable={searchedSystemMonitorPlatformEnterprisePageable}
          paginate={searchedSystemMonitorPlatformEnterprisePaginate}
        />
      </div>
    </>
  );
}

export default SystemMonitorPlatformEnterpriseList;
