import { ENTERPRISE_TYPE, stringToEnum } from 'enums';

export interface TermsOfUseVersionVO {
  termsOfUseId: number;
  termsOfUseVersionId: number;
  updatedDateTime: string;
  description: string;
  version: string;
  activated: boolean;
  attachmentName: string;
  enterpriseId: number;
  enterpriseName: string;
  enterpriseType: string;
  relatedFinancierId: number;
  relatedFinancierName: string;
}

export interface TermsOfUseVersionVOModel extends TermsOfUseVersionVO {
  enterpriseType: ENTERPRISE_TYPE;
}

export function formattingToTermsOfUseVersionVOModel(data: TermsOfUseVersionVO): TermsOfUseVersionVOModel {
  return {
    ...data,
    enterpriseType: stringToEnum(ENTERPRISE_TYPE, data.enterpriseType),
  };
}
