import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { isEmpty, isNumber } from 'lodash-es';

import Button from 'components/stateless/Button/Button';
import { FormValue, NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import useFinancierHolidays from 'hooks/useFinancierHolidays';
import useProperty from 'hooks/useProperty';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestFinancierCalculateExpectedAmount } from 'utils/http/api/financier/loans';
import type { FinancierLoanRepaymentRequest } from 'utils/http/api/financier/loans/request';
import useModal from 'utils/modal/useModal';

interface FinancierFinancingDetailFactoringRequestRepaymentModalProps {
  repaidInterestAmount: number;
  disbursedDate: string;
  getSubmitData(data: FinancierLoanRepaymentRequest): void;
  modalInfo: {
    id: number;
    disableConfirmBtn: (id: number, disabled: boolean) => void;
  };
  repaidDelayAmount: number;
  loanId: number;
}

function FinancierFinancingDetailFactoringRequestRepaymentModal({
  repaidInterestAmount,
  disbursedDate,
  getSubmitData,
  modalInfo,
  repaidDelayAmount,
  loanId,
}: FinancierFinancingDetailFactoringRequestRepaymentModalProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const calculatorBigNumber = new CalculatorBigNumber();

  const getProperty = useProperty<FinancierLoanRepaymentRequest>();

  const { watch, getValues, errors, control, setValue } = useForm<FinancierLoanRepaymentRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const { transactionDate, totalDelayAmount } = watch();
  const { id: modalId, disableConfirmBtn } = modalInfo;

  const canSubmit = useCallback(() => {
    return !isEmpty(transactionDate);
  }, [transactionDate]);

  useEffect(() => {
    if (canSubmit())
      getSubmitData({
        ...getValues(),
        totalDelayAmount: totalDelayAmount ?? 0,
        totalInterestAmount: repaidInterestAmount,
      });
  }, [canSubmit, getSubmitData, getValues, totalDelayAmount]);

  useEffect(() => {
    disableConfirmBtn(modalId, !canSubmit());
  }, [canSubmit, disableConfirmBtn, modalId]);

  const { financierHolidays } = useFinancierHolidays(
    Number(getDayTerm(convertToServerDateFormat(new Date(disbursedDate)), convertToServerDateFormat(new Date()))) + 1,
    disbursedDate,
    dayjs().format('YYYY-MM-DD'),
  );

  const handleClickEstimateButton = async (e: any): Promise<void> => {
    e.preventDefault();

    try {
      const response = await requestFinancierCalculateExpectedAmount(loanId, transactionDate);
      setValue('totalDelayAmount', String(response.scheduledDelayAmount));
    } catch (error) {
      modal.show(error);
    }
  };

  return (
    <>
      <div className="mb-1">* {t('text:Enter_the_information_of_the_completed_repayment_below')}</div>
      <FormBorder editable>
        <FormContents>
          <div className="row">
            <FormDatePickerInput
              col={12}
              label={t('text:Repayment_Date')}
              name={getProperty('transactionDate')}
              control={control}
              error={errors.transactionDate}
              minDate={new Date(disbursedDate)}
              maxDate={new Date()}
              excludeDates={financierHolidays}
            >
              <div className="align-self-center ps-3">
                <Button
                  onClick={handleClickEstimateButton}
                  className="flex-column-center text-bold"
                  disabled={!transactionDate}
                >
                  {t('text:Estimate')}
                </Button>
              </div>
            </FormDatePickerInput>
          </div>
          <div className="row">
            <FormValue
              className="information-form__input text-end"
              col={12}
              label={t('text:Interest_Amount_Repaid_at_this_time')}
              value={repaidInterestAmount}
              format="number"
            />
          </div>
          <div className="row">
            <NumericFormatInput
              control={control}
              className="text-end"
              col={12}
              name={getProperty('totalDelayAmount')}
              label={t('text:Delinquent_Interest_Amount_Repaid')}
              numberType="bigNumber"
              error={errors.totalDelayAmount}
            />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input text-end"
              col={12}
              label={t('text:Delinquent_Interest_Amount_Repaid_at_this_time')}
              value={
                totalDelayAmount &&
                isNumber(repaidDelayAmount) &&
                calculatorBigNumber.add(totalDelayAmount).minus(repaidDelayAmount).get()
              }
              format="number"
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default FinancierFinancingDetailFactoringRequestRepaymentModal;
