import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import RequiredAdditionalDocumentList from 'components/loan/RequiredAdditionalDocumentList';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { MULTIPLE_LOAN_APPROVAL_TYPE } from 'enums';

import FinancierBulkFinancingDetailAdditionalDocuments from './sections/additional-documents';
import FinancierBulkFinancingApprovalDetailInformation from './sections/bulk-financing-information';
import FinancierBulkFinancingDetailDesignatedBankAccountInformation from './sections/designated-bank-account-information';
import FinancierBulkFinancingDetailRelatedFinancingList from './sections/financing-list';
import FinancierBulkFinancingDetailFinancingRequestInformation from './sections/financing-request-information';
import FinancierBulkFinancingDetailGuideMessage from './sections/guide-message/FinancierBulkFinancingDetailGuideMessage';
import useFinancierBulkFinancingApprovalDetailController from './useFinancierBulkFinancingApprovalDetailController';

function FinancierBulkFinancingApprovalDetail() {
  const { t } = useTranslation();
  const { multipleRequestLoansId } = useParams<any>();

  const {
    state,
    paginateRelatedFinancingList,
    isAuthorizerRequest,
    isOperatorRequest,
    relatedFinancingPageable,
    financingInfoCheckBoxState,
    bankAccountInfoCheckBoxState,
    buttonClickHandlers,
    checkedRequiredDocumentsIds,
    handleRequiredAdditionalDocumentCheckboxChange,
  } = useFinancierBulkFinancingApprovalDetailController(multipleRequestLoansId);

  const {
    submitBulkFinancingCancel,
    submitFinancingRejectionRequest,
    submitFinancingRejectionApprove,
    submitBulkFinancingReject,
    handleOperatorBulkFinancingApproveRequestButtonClick,
    handleOperatorBulkFinancingRejectButtonClick,
    handleAuthorizerBulkFinancingApproveButtonClick,
    handleAuthorizerBulkFinancingRevertButtonClick,
  } = buttonClickHandlers;

  const AuthorizerRequestButton = () => {
    return (
      <>
        <Button
          size={ButtonSizeEnum.LG}
          onClick={handleAuthorizerBulkFinancingRevertButtonClick}
          className="me-2"
          color={ButtonColorEnum.RED}
          variant={ButtonVariantEnum.OUTLINED}
        >
          {t('text:Revert')}
        </Button>
        <Button size={ButtonSizeEnum.LG} onClick={handleAuthorizerBulkFinancingApproveButtonClick}>
          {t('text:Confirm')}
        </Button>
      </>
    );
  };

  const OperatorRequestButton = () => {
    const { approvalType } = state.bulkFinancingDetail;

    return (
      <>
        <Button
          size={ButtonSizeEnum.LG}
          onClick={handleOperatorBulkFinancingRejectButtonClick}
          className="me-2"
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
        >
          {t('text:Reject')}
        </Button>
        <Button
          size={ButtonSizeEnum.LG}
          onClick={handleOperatorBulkFinancingApproveRequestButtonClick}
          disabled={approvalType === MULTIPLE_LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED}
        >
          {t('text:Confirm')}
        </Button>
      </>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Bulk_Financing_Approval_Details')} />
      <FinancierBulkFinancingDetailGuideMessage
        bulkFinancingDetailInfo={state.bulkFinancingDetail}
        submitBulkFinancingCancel={submitBulkFinancingCancel}
        submitFinancingRejectionApprove={submitFinancingRejectionApprove}
        submitFinancingRejectionRequest={submitFinancingRejectionRequest}
        submitBulkFinancingReject={submitBulkFinancingReject}
      />
      <FinancierBulkFinancingApprovalDetailInformation bulkFinancingDetailInfo={state.bulkFinancingDetail} />
      <FinancierBulkFinancingDetailFinancingRequestInformation
        bulkFinancingDetailInfo={state.bulkFinancingDetail}
        arList={state.bulkFinancingArList}
        isOperatorRequest={isOperatorRequest}
        financingInfoCheckBoxState={financingInfoCheckBoxState}
      />
      <FinancierBulkFinancingDetailDesignatedBankAccountInformation
        bulkFinancingDetailInfo={state.bulkFinancingDetail}
        isOperatorRequest={isOperatorRequest}
        bankAccountInfoCheckBoxState={bankAccountInfoCheckBoxState}
      />
      <FinancierBulkFinancingDetailAdditionalDocuments
        multipleRequestLoansId={multipleRequestLoansId}
        loanEtcAttachmentListData={state.bulkFinancingDetail.loanEtcAttachment}
      />
      <RequiredAdditionalDocumentList
        requiredAdditionalDocuments={state.requiredAdditionalDocuments}
        isRenderConfirmCheckbox={isOperatorRequest}
        checkedRequiredDocumentsIds={checkedRequiredDocumentsIds}
        handleRequiredAdditionalDocumentCheckboxChange={handleRequiredAdditionalDocumentCheckboxChange}
      />
      <FinancierBulkFinancingDetailRelatedFinancingList
        financingList={state.bulkFinancingRelatedFinancingList}
        paginate={paginateRelatedFinancingList}
        pageable={relatedFinancingPageable}
      />
      <div className="content-area flex-end mt-3">
        {isAuthorizerRequest && <AuthorizerRequestButton />}
        {isOperatorRequest && <OperatorRequestButton />}
      </div>
    </>
  );
}

export default FinancierBulkFinancingApprovalDetail;
