import type Pageable from 'models/Pageable';
import type { FinancierClientVO, FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { formattingToFinancierClientVOModel } from 'models/vo/FinancierClientVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

// 딜러와 관계되어있는 은행목록 조회 - Dealer 재고담보 담보권 설정자
export async function requestDealerFinancierClients(): Promise<Pageable<FinancierClientVOModel[]>> {
  const response = await http.get<Pageable<FinancierClientVO[]>>({
    url: API_DE.FINANCIER_CLIENTS.FINANCIER_CLIENT_LIST,
  });
  const financierClientList: FinancierClientVOModel[] = response.content.map((data: FinancierClientVO) =>
    formattingToFinancierClientVOModel(data),
  );
  const financierClientPage: Pageable<FinancierClientVOModel[]> = {
    ...response,
    content: financierClientList,
  };

  return financierClientPage;
}
