import { COLLATERAL_TYPE, CURRENCY_TYPE, PARTNER_STATUS, stringToEnum } from 'enums';

export interface AnchorPartnerAccountDetailVO {
  anchorPartnerAccountId: number;
  currencyType: string;
  account: string;
  accountOwner: string;
  requestedAccountBankName: string;
  requestedAccountBranchName: string;
  declined: boolean;
  declinedReason: string;
  partnerBusinessCode: string;
  partnerTaxCode: string;
  anchorPartnerCode: string;
  partnerName: string;
  partnerAgreementContractNo: string;
  address: string;
  telephone: string;
  fax: string;
  representativeName: string;
  representativeEmail: string;
  representativePosition: string;
  anchorPartnerStatus: string;
  accountBankCodeId: number;
  accountBankName: string;
  accountBankCode: string;
  accountBranchName: string;
  accountBranchCode: string;
  anchorAgreementId: number;
  anchorName: string;
  anchorAgreementContractNo: string;
  collateralType: string;
  paymentSupport: boolean;
  responsibleFinancierId: number;
  responsibleFinancierName: string;
  responsibleBranchId: number;
  responsibleBranchCode: string;
  responsibleBranchName: string;
  assignedBranchCode: string;
  assignedBranchName: string;
  principalRepaymentAccount: string;
  principalRepaymentAccountOwner: string;
  principalRepaymentAccountFinancierName: string;
}

export interface AnchorPartnerAccountDetailVOModel extends AnchorPartnerAccountDetailVO {
  currencyType: CURRENCY_TYPE;
  anchorPartnerStatus: PARTNER_STATUS;
  collateralType: COLLATERAL_TYPE;
}

export function formattingToAnchorPartnerAccountDetailVOModel(
  data: AnchorPartnerAccountDetailVO,
): AnchorPartnerAccountDetailVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    anchorPartnerStatus: stringToEnum(PARTNER_STATUS, data.anchorPartnerStatus),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
  };
}
