import http from 'utils/http';

import API_CM from '..';

// 서버 시간 조회
export async function requestServerTime(): Promise<string> {
  const response = await http.get<string>({
    url: API_CM.TIME.SEVER_TIME,
  });

  return response;
}
