import type { InvoiceAdditionalDataVO } from './InvoiceAdditionalDataVO';

export interface TempWaitingInvoiceVO {
  tempWaitingInvoiceId: number;
  invoiceNumber: string;
  referenceNumber: string;
  invoiceIssuedDate: string;
  settlementDate: string;
  invoiceAmount: number;
  billOfExchangeNo: string;
  invoiceAdditionalDataList: InvoiceAdditionalDataVO[];
}

export interface TempWaitingInvoiceVOModel extends TempWaitingInvoiceVO {}

export function formattingToTempWaitingInvoiceVOModel(data: TempWaitingInvoiceVO): TempWaitingInvoiceVOModel {
  return {
    ...data,
  };
}
