import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import {
  FormBorder,
  FormContents,
  FormInput,
  FormSelect,
  FormSubtitle,
  FormValue,
} from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, REPORT_RECEIVER_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorReportReceiverVOModel } from 'models/vo/AnchorReportReceiverVO';
import type { AnchorReportVOModel } from 'models/vo/AnchorReportVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFiAnchorReportReceiverAssignAgreement,
  requestFiAnchorReportReceiverDetail,
  requestFiAnchorReportReceiverRelatedAgreementList,
  requestFiAnchorReportReceiverReleaseAgreement,
  requestFiAnchorReportReceiverUpdate,
} from 'utils/http/api/financier/anchor-report-receivers';
import type { FiAnchorReportReceiverUpdateRequest } from 'utils/http/api/financier/anchor-report-receivers/requests';
import { requestAnchorReportDownload, requestAnchorReportList } from 'utils/http/api/financier/anchor-reports';
import type { DealerReportListRequest } from 'utils/http/api/financier/anchor-reports/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import FinancierAnchorReportUpdatePartnerAgreementModal from '../modal/FinancierAnchorReportUpdatePartnerAgreementModal';

const getConstants = () => {
  const { t } = useTranslation(['format']);

  const PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 140,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 140,
    },
    {
      headerText: t('text:Agreement_Suspension'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const REPORT_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Created_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Sent_Date'),
      colWidths: 160,
    },
    {
      headerText: t('text:Email'),
      colWidths: 260,
    },
    {
      headerText: '',
      colWidths: 100,
    },
  ];

  const REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER: REPORT_RECEIVER_TYPE.ANCHOR_REPORT_RECEIVER =
    REPORT_RECEIVER_TYPE.ANCHOR_REPORT_RECEIVER;

  return {
    t,
    PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS,
    REPORT_LIST_TABLE_HEADERS,
    REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER,
  };
};

function FinancierDealerReportDetail(): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();
  const {
    t,
    PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS,
    REPORT_LIST_TABLE_HEADERS,
    REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER,
  } = getConstants();
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;
  const { anchorReportReceiverId } = useParams() as any;
  const getAnchorReportReceiverUpdate = useProperty<FiAnchorReportReceiverUpdateRequest>();

  const [reportSettingInfo, setReportSettingInfo] = useState<AnchorReportReceiverVOModel>();
  const [partnerMasterAgreementPageData, setPartnerMasterAgreementPageData] =
    useState<Pageable<DealerAgreementVOModel[]>>();
  const { pageable: partnerMasterAgreementPageable, setPageable: setPartnerMasterAgreementPageable } = usePageable();

  const [reportPageData, setReportPageData] = useState<Pageable<AnchorReportVOModel[]>>();
  const { pageable: reportPageable, setPageable: setReportPageable } = usePageable();
  const [reportSettingEditable, setReportSettingEditable] = useState<boolean>(false);

  const {
    register: partnerReportReceiverInfoRegister,
    getValues: getPartnerReportReceiverInfo,
    reset,
    errors,
    setError,
    clearErrors,
  } = useForm<FiAnchorReportReceiverUpdateRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      await Promise.all([
        fetchAndSetReportSettingInfo(),
        fetchAndSetPartnerMasterAgreementList(),
        fetchAndSetPartnerReportList(),
      ]);
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchAndSetReportSettingInfo = async (): Promise<void> => {
    try {
      const reportSettingData = await requestFiAnchorReportReceiverDetail(anchorReportReceiverId);
      setReportSettingInfo(reportSettingData);

      reset({
        activated: reportSettingData.activated,
        name: reportSettingData.name,
        email: reportSettingData.email,
        affiliation: reportSettingData.affiliation,
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchAndSetPartnerMasterAgreementList = async (): Promise<void> => {
    try {
      const partnerMasterAgreementList = await requestFiAnchorReportReceiverRelatedAgreementList(
        1,
        10,
        anchorReportReceiverId,
      );

      setPartnerMasterAgreementPageData(partnerMasterAgreementList);
      setPartnerMasterAgreementPageable(partnerMasterAgreementList);
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchAndSetPartnerReportList = async (): Promise<void> => {
    try {
      const reportList = await requestAnchorReportList<DealerReportListRequest>(1, 10, {
        anchorReportReceiverId,
        reportReceiverType: REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER,
      });

      setReportPageData(reportList);
      setReportPageable(reportList);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickCancel = (e: any): void => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_information_modification')}
        <br />
        {t('text:The_information_will_not_be_saved_if_modification_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => {
          setReportSettingEditable(false);

          reset({
            activated: reportSettingInfo?.activated,
            name: reportSettingInfo?.name,
            email: reportSettingInfo?.email,
            affiliation: reportSettingInfo?.affiliation,
          });
        },
      },
    );
  };

  const onClickSave = async (e: any): Promise<void> => {
    e.preventDefault();

    try {
      const data = getPartnerReportReceiverInfo();

      await requestFiAnchorReportReceiverUpdate(anchorReportReceiverId, data);

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          setReportSettingEditable(false);

          fetchAndSetReportSettingInfo();
        },
      });
    } catch (error) {
      modal.show(error);
      formErrorHandler<FiAnchorReportReceiverUpdateRequest>(error, setError, clearErrors);
    }
  };

  const onClickEdit = (e: any): void => {
    e.preventDefault();

    setReportSettingEditable(true);
  };

  const onClickAddPartnerMasterAgreement = async (e: any): Promise<void> => {
    e.preventDefault();

    let dealerAgreements: number[];
    let isSelected: boolean = false;

    const getCheckedDealerAgreementList = (data: DealerAgreementVOModel[]): void => {
      dealerAgreements = data.map(item => item.dealerAgreementId);
      isSelected = data.length !== 0;
    };

    const noSelectedAgreements = () => {
      modal.show(<h6>{t('text:Select_an_agreement_to_add')}</h6>, {
        closeBtnText: t('text:Close'),
        closeBtnCb: () => {
          showPartnerAgreementModal();
        },
      });
    };

    const showPartnerAgreementModal = (): void => {
      modal.show(
        <FinancierAnchorReportUpdatePartnerAgreementModal
          taskType="ASSIGN"
          anchorReportReceiverId={anchorReportReceiverId}
          getCheckedDealerAgreementList={getCheckedDealerAgreementList}
        />,
        {
          title: t('text:Add_Partner_Master_Agreement'),
          modalSize: ModalSize.XL,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Apply'),
          confirmBtnCb: async () => {
            try {
              if (!isSelected) {
                noSelectedAgreements();

                return;
              }

              await requestFiAnchorReportReceiverAssignAgreement(anchorReportReceiverId, { dealerAgreements });
              modal.show(<h6>{t('text:The_agreements_have_been_added_successfully')}</h6>, {
                modalType: ModalType.ALERT,
                closeBtnText: t('text:OK'),
                closeBtnCb: () => {
                  fetchAndSetPartnerMasterAgreementList();
                },
              });
            } catch (error) {
              modal.show(error);
            }
          },
        },
      );
    };

    showPartnerAgreementModal();
  };

  const onClickDeletePartnerMasterAgreement = async (e: any): Promise<void> => {
    e.preventDefault();

    let dealerAgreements: number[] = [];
    let isSelected: boolean = false;

    const getCheckedDealerAgreementList = (data: DealerAgreementVOModel[]): void => {
      dealerAgreements = data.map(item => item.dealerAgreementId);
      isSelected = data.length !== 0;
    };

    const noSelectedAgreements = () => {
      modal.show(<h6>{t('text:Select_an_agreement_to_delete')}</h6>, {
        closeBtnText: t('text:Close'),
        closeBtnCb: () => {
          showPartnerAgreementModal();
        },
      });
    };

    const showPartnerAgreementModal = (): void => {
      modal.show(
        <FinancierAnchorReportUpdatePartnerAgreementModal
          taskType="RELEASE"
          anchorReportReceiverId={anchorReportReceiverId}
          getCheckedDealerAgreementList={getCheckedDealerAgreementList}
        />,
        {
          title: t('text:Delete_Partner_Master_Agreement'),
          modalSize: ModalSize.XL,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Delete'),
          confirmBtnCb: async () => {
            if (!isSelected) {
              noSelectedAgreements();

              return;
            }

            try {
              await requestFiAnchorReportReceiverReleaseAgreement(anchorReportReceiverId, { dealerAgreements });
              modal.show(<h6>{t('text:The_agreements_have_been_deleted_successfully')}</h6>, {
                modalType: ModalType.ALERT,
                closeBtnText: t('text:OK'),
                closeBtnCb: () => {
                  fetchAndSetPartnerMasterAgreementList();
                },
              });
            } catch (error) {
              modal.show(error);
            }
          },
        },
      );
    };

    showPartnerAgreementModal();
  };

  const paginatePartnerMasterAgreement = async (
    selectedPageNumber: number,
    selectedRowCount: number,
  ): Promise<void> => {
    try {
      const partnerMasterAgreementList = await requestFiAnchorReportReceiverRelatedAgreementList(
        selectedPageNumber,
        selectedRowCount,
        anchorReportReceiverId,
      );

      setPartnerMasterAgreementPageData(partnerMasterAgreementList);
      setPartnerMasterAgreementPageable(partnerMasterAgreementList);
    } catch (error) {
      modal.show(error);
    }
  };

  const renderPartnerMasterAgreementPageTable = (): JSX.Element[] | undefined => {
    return partnerMasterAgreementPageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.dealerFinancierClientName} />
        <Td data={item.dealerFinancierClientCode} />
        <Td data={item.contractNo} />
        <Td
          className={getStatusTextClass('DEALER_AGREEMENT_STATUS', item.dealerAgreementStatus)}
          data={t(`code:dealer-agreement-status.${item.dealerAgreementStatus}`)}
          format="code"
        />
        <TdLink
          path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.dealerAgreementId)}
          state={{
            dealerFinancierClientId: item.dealerFinancierClientId,
            supportedCollateralType: item.collateralType,
          }}
        />
      </Tr>
    ));
  };

  const paginateReport = async (selectedPageNumber: number, selectedRowCount: number): Promise<void> => {
    try {
      const reportList = await requestAnchorReportList<DealerReportListRequest>(selectedPageNumber, selectedRowCount, {
        anchorReportReceiverId,
        reportReceiverType: REPORT_RECEIVER_TYPE_ANCHOR_REPORT_RECEIVER,
      });

      setReportPageData(reportList);
      setReportPageable(reportList);
    } catch (error) {
      modal.show(error);
    }
  };

  const renderReportPageTable = (): JSX.Element[] | undefined => {
    const onClickDownload = async (e: any, item: AnchorReportVOModel): Promise<void> => {
      e.preventDefault();

      try {
        await requestAnchorReportDownload(item.anchorReportId);
      } catch (error) {
        modal.show(error);
      }
    };

    return reportPageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.sent ? item.updatedDateTime : ''} format="datetime" />
        <Td data={item.sentEmail} />
        <Td className="text-center">
          <DownloadButton disabled={!item.sent} onClick={e => onClickDownload(e, item)} />
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Recipient_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Report_Setting')}>
          {isAdmin ? (
            reportSettingEditable ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button size={ButtonSizeEnum.SM} onClick={onClickEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            )
          ) : undefined}
        </SectionTitle>
        <FormBorder editable={reportSettingEditable}>
          <FormSubtitle title={t('text:Report_Setting_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Report_Type')}
                value={t(`code:report-type.${reportSettingInfo?.reportType}`)}
                format="code"
              />
              <FormSelect
                name={getAnchorReportReceiverUpdate('activated')}
                label={t('text:Recipient_Status')}
                ref={partnerReportReceiverInfoRegister}
                selectOptions={getSelectOptions<string>('SEND_REPORT', 'ALL')}
                placeholderOptions={{ show: true }}
                disabled={!reportSettingEditable}
                error={errors.activated}
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Associated_Anchor_Master_Agreement_Information')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={reportSettingInfo?.anchorClientName} />
              <FormValue label={t('text:Anchor_Client_Code')} value={reportSettingInfo?.anchorClientCode} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                value={reportSettingInfo?.anchorAgreementContractNo}
              />
              <FormValue col={3} label={t('text:Currency')} value={reportSettingInfo?.currencyType} />
              <FormValue
                col={3}
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${reportSettingInfo?.collateralType}`)}
                format="code"
              />
            </div>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Agreement_Suspension')}
                value={t(`code:anchor-agreement-status.${reportSettingInfo?.anchorAgreementStatus}`)}
                format="code"
              />
              <FormValue
                col={3}
                label={t('text:Effective_Date')}
                format="date"
                value={reportSettingInfo?.agreementStartDate}
              />
              <FormValue
                col={3}
                label={t('text:Expiration_Date')}
                format="date"
                value={reportSettingInfo?.agreementExpiryDate}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Recipient_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Recipient_Name')}
                name={getAnchorReportReceiverUpdate('name')}
                ref={partnerReportReceiverInfoRegister}
                disabled={!reportSettingEditable}
                requiredOptions={{ required: true }}
                error={errors.name}
              />
              <FormInput
                col={3}
                label={t('text:Email')}
                name={getAnchorReportReceiverUpdate('email')}
                ref={partnerReportReceiverInfoRegister}
                disabled={!reportSettingEditable}
                requiredOptions={{ required: true }}
                error={errors.email}
              />
              <FormInput
                col={3}
                label={t('text:Affiliation')}
                name={getAnchorReportReceiverUpdate('affiliation')}
                ref={partnerReportReceiverInfoRegister}
                disabled={!reportSettingEditable}
                error={errors.affiliation}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <div className="d-flex align-items-center mb-3">
          <SectionTitle title={t('text:Partner_Master_Agreement_List')} />
          {isAdmin && (
            <div className="d-flex align-items-center">
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickDeletePartnerMasterAgreement}
                size={ButtonSizeEnum.SM}
                bold
              >
                {t('text:Delete')}
              </Button>
              <Button onClick={onClickAddPartnerMasterAgreement} size={ButtonSizeEnum.SM} bold className="ms-2">
                {t('text:Add')}
              </Button>
            </div>
          )}
        </div>
        <TableBorder>
          <TableHeader header={PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={PARTNER_MASTER_AGREEMENT_LIST_TABLE_HEADERS.length}>
            {renderPartnerMasterAgreementPageTable()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={partnerMasterAgreementPageable} paginate={paginatePartnerMasterAgreement} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Report')} />
        <TableBorder>
          <TableHeader header={REPORT_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={REPORT_LIST_TABLE_HEADERS.length}>{renderReportPageTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={reportPageable} paginate={paginateReport} />
      </div>
    </>
  );
}

export default FinancierDealerReportDetail;
