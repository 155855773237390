import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { BranchVOModel } from 'models/vo/BranchVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestBranchList } from 'utils/http/api/financier/branches';
import type { FinancierBranchListRequest } from 'utils/http/api/financier/branches/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const FI_BRANCH_LIST_QS_KEY = 'fi-branch-list';

function FinancierBranchList() {
  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation();
  const mounted = useMounted();
  const modal = useModal();

  const { pageable, setPageable } = usePageable(FI_BRANCH_LIST_QS_KEY);
  const [BranchPage, setBranchPage] = useState<Pageable<BranchVOModel[]>>();

  const getProperty = useProperty<FinancierBranchListRequest>();
  const { register, handleSubmit, reset, getValues, setValue } = useForm<FinancierBranchListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierBranchListRequest>(setValue, getParsedSearchParams(FI_BRANCH_LIST_QS_KEY).formSearchData);
      fetchFiBranchList(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  async function fetchFiBranchList(
    pageNumber: number = 1,
    sizePerPage: number = 10,
    searchData: FinancierBranchListRequest,
  ) {
    try {
      const branchPageRes = await requestBranchList(pageNumber, sizePerPage, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount: sizePerPage,
        },
        FI_BRANCH_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setBranchPage(branchPageRes);
        setPageable(branchPageRes);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async () => {
    const data = getValues();
    await fetchFiBranchList(1, pageable.sizePerPage, data);
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchFiBranchList(page, sizePerPage, getParsedSearchParams(FI_BRANCH_LIST_QS_KEY).formSearchData);
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Branch_Code'),
      colWidths: 110,
    },
    {
      headerText: t('text:Branch_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 150,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 310,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderBranchListTable = () => {
    return BranchPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.branchCode} />
          <Td data={item.branchName} />
          <Td data={item.managerName} />
          <Td data={item.telephone} />
          <Td data={item.address} />
          <TdLink path={ROUTES_FI.SETTINGS.BRANCH_DETAIL_BUILD_PATH(item.branchId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Branch')} />
      {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && (
        <GuideMessage
          message={[
            t('text:Click_the_button_on_the_right_to_register_a_branch'),
            t('text:Check_the_registered_branches_in_the_list_below'),
          ]}
        >
          {{
            button: <Button to={ROUTES_FI.SETTINGS.BRANCH_REGISTER}>{t('text:Register')}</Button>,
          }}
        </GuideMessage>
      )}
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Branch_Code')} />
              <SearchInput name={getProperty('branchCode')} ref={register} />
              <SearchLabel label={t('text:Branch_Name')} />
              <SearchInput name={getProperty('branchName')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Legal_Representative_Name')} />
              <SearchInput name={getProperty('managerName')} ref={register} />
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput name={getProperty('telephone')} ref={register} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onSearchSubmit)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {BranchPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderBranchListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierBranchList;
