import { useTranslation } from 'react-i18next';

import { faEnvelope, faFax, faGlobe, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PPSubTitle from 'components/potential-partner/PPSubTitle/PPSubTitle';
import type { PotentialPartnerAcquisitionSettingVOModel } from 'models/vo/PotentialPartnerAcquisitionSettingVO';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import './PotentialPartnerFinancierDetailContactInfo.scss';

interface PotentialPartnerFinancierDetailContactInfoProps {
  contactInfo: PotentialPartnerAcquisitionSettingVOModel;
}
function PotentialPartnerFinancierDetailContactInfo({ contactInfo }: PotentialPartnerFinancierDetailContactInfoProps) {
  const { t } = useTranslation();

  const ContactItemLabel = ({ icon, label }: { icon: JSX.Element; label: string }) => {
    return (
      <div className="pp-contact-label">
        <div className="d-flex align-items-center">
          {icon}
          <label>{label}</label>
        </div>
      </div>
    );
  };

  const ContactItemValue = ({ data }: { data: any }) => {
    return <div className="pp-contact-data">{tableValueManage(data)}</div>;
  };

  return (
    <div className="potential-partner-financier-detail__contact">
      <PPSubTitle title={t('text:Contact_Information')} />
      <div className="pp-contact-info-wrapper">
        <div className="row">
          <div className="pp-contact-item">
            <ContactItemLabel label={t('text:Call')} icon={<FontAwesomeIcon icon={faPhone} fontSize={26} />} />
            <ContactItemValue data={contactInfo.telephone} />
          </div>
          <div className="pp-contact-item">
            <ContactItemLabel label={t('text:Link')} icon={<FontAwesomeIcon icon={faGlobe} fontSize={26} />} />
            <ContactItemValue data={contactInfo.webSite} />
          </div>
        </div>
        <div className="row">
          <div className="pp-contact-item">
            <ContactItemLabel label={t('text:Fax')} icon={<FontAwesomeIcon icon={faFax} fontSize={26} />} />
            <ContactItemValue data={contactInfo.fax} />
          </div>
          <div className="pp-contact-item">
            <ContactItemLabel label={t('text:Email')} icon={<FontAwesomeIcon icon={faEnvelope} fontSize={26} />} />
            <ContactItemValue data={contactInfo.email} />
          </div>
        </div>
        <div className="row">
          <div className="pp-contact-item">
            <ContactItemLabel
              label={t('text:Location')}
              icon={<FontAwesomeIcon icon={faMapMarkerAlt} fontSize={26} />}
            />
            <ContactItemValue data={contactInfo.address} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PotentialPartnerFinancierDetailContactInfo;
