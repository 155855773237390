import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';

import FinancierDeptSellingNoticeDetailARList from './sections/FinancierDeptSellingNoticeDetailARList';
import FinancierDeptSellingNoticeDetailRecipientList from './sections/FinancierDeptSellingNoticeDetailRecipientList';
import FinancierDeptSellingNoticeDetailSummary from './sections/FinancierDeptSellingNoticeDetailSummary';
import useFinancierDeptSellingNoticeDetailController from './useFinancierDeptSellingNoticeDetailController';

function FinancierDeptSellingNoticeDetail() {
  const { t } = useTranslation();

  const {
    state: { deptSellingDetail, recipientList, relatedArList },
    recipientListPageable,
    relatedArListPageable,
    paginateReceiptList,
    paginateArList,
    handleClickResendButton,
    handleClickResendAllButton,
  } = useFinancierDeptSellingNoticeDetailController();

  return (
    <>
      <BackHeaderTitle title={t('text:Confirm_Notification_Details')} />
      <GuideMessage
        message={[
          t('text:Check_the_contents_of_the_Debt_Selling_Notification_mail_sent_to_the_anchor_company'),
          t('text:You_can_check_the_list_of_related_APs'),
          t('text:You_can_check_the_list_of_receiving_email_addresses_and_whether_they_have_received_the_email_or_not'),
        ]}
      />
      <FinancierDeptSellingNoticeDetailSummary data={deptSellingDetail} />
      <FinancierDeptSellingNoticeDetailARList
        data={relatedArList?.content}
        pageable={relatedArListPageable}
        paginate={paginateArList}
      />
      <FinancierDeptSellingNoticeDetailRecipientList
        data={recipientList?.content}
        pageable={recipientListPageable}
        paginate={paginateReceiptList}
        handleClickResendButton={handleClickResendButton}
        handleClickResendAllButton={handleClickResendAllButton}
      />
    </>
  );
}

export default FinancierDeptSellingNoticeDetail;
