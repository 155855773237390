import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';
import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import type { ConfirmCheckBoxRefHandle } from 'components/stateless/CheckBox/ConfirmCheckBox';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { LOAN_LIMIT_CHECK_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import useScrollIntoView from 'hooks/useScrollIntoView';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';

import BulkAdditionalDocuments from './sections/bulk-additional_documents';
import BulkArInformation from './sections/bulk-ar-information';
import BulkFinancingRequest from './sections/bulk-financing-request';
import ARBulkFinancingApplicationSummary from './sections/bulk-summary';
import { useDealerArBulkFinancingApplicationStep2State } from './useDealerArBulkFinancingApplicationStep2State';
import DesignatedBankAccountInfo from '../ar/sections/designated-bank-account-info';
import RequiredDocumentForm from '../required-documents';

export interface EtcFileRowTypes {
  id: number;
  fileName?: string;
}

function DealerArBulkFinancingApplicationStep2() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();

  const modal = useModal();
  const [, errorHandlerOfLocationState] = useLocationState<{ dealerAgreementId: number }>(['dealerAgreementId']);
  const [formStep, setFormStep] = useState<number>(2);
  const [checkedRequiredDocumentsIds, setCheckedRequiredDocumentsIds] = useState<number[]>([]);
  const [estimateButtonRef, scrollToEstimateButton] = useScrollIntoView<HTMLDivElement>();

  const {
    state,
    fetchAll,
    dealerBulkApplicationStep2UseForm,
    fetchDealerArBulkCalculateLoanRequest,
    handleCheckAll,
    handleCheckChange,
    calculateRequestedAmount,
    totalRequestedAmount,
    etcFileRowsState,
    showCancelFinancingModal,
    onFormSubmit,
    getCheckedLoanByArRequestList,
    fetchLoanAdditionalDocumentList,
    initializeFinancingConditionInfo,
    showedTotalRequestedAmountState,
    selectedTotalArAmountState,
  } = useDealerArBulkFinancingApplicationStep2State();

  const { checkedArList, dealerAgreementDetail, additionalDocumentList } = state;

  const { etcFileRows } = etcFileRowsState;

  const [disbursementDateRef, scrollToDisbursementDate] = useScrollIntoView<HTMLDivElement>();
  const [arListTableRef, scrollToArListTable] = useScrollIntoView<HTMLDivElement>();

  const [financingCheckBoxToggle, setFinancingCheckBoxToggle] = useState(false);
  const [bankAccountCheckBoxToggle, setBankAccountCheckBoxToggle] = useState(false);

  const financingCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);
  const bankAccountCheckBoxRef = useRef<ConfirmCheckBoxRefHandle>(null);

  const { getValues, watch, setError, clearErrors } = dealerBulkApplicationStep2UseForm;
  const { disbursementDate } = watch();

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    calculateRequestedAmount(checkedArList);
  }, [checkedArList]);

  const focusErrorElement = () => {
    if (arListTableRef.current) {
      // 'error' 문구를 포함하는 클래스를 가진 요소를 찾음
      const errorInput = arListTableRef.current.querySelector('input[class*="error"]') as HTMLElement;
      const errorDiv = arListTableRef.current.querySelector('div[class*="error"]') as HTMLElement;
      // 해당 요소가 존재하면 포커스
      if (errorInput) {
        errorInput.focus();
      } else if (errorDiv) {
        errorDiv.focus();
      }
    }
  };

  const confirmCheckBoxValidator = (): boolean => {
    let validationResult = true;

    const alertModal = (ref: RefObject<ConfirmCheckBoxRefHandle>) => {
      modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          if (ref.current) {
            ref.current.focus();
          }
        },
      });
    };

    if (!financingCheckBoxToggle) {
      alertModal(financingCheckBoxRef);
      validationResult = false;
    } else if (!bankAccountCheckBoxToggle) {
      alertModal(bankAccountCheckBoxRef);
      validationResult = false;
    }

    return validationResult;
  };

  const financingRequestValidator = async (): Promise<boolean> => {
    const checkedLoanByArRequestList = getCheckedLoanByArRequestList();
    let zeroOrNegativeAmountErrorCount = 0;
    let ARLimitExceededErrorCount = 0;
    let agreementLimitExceededErrorCount = 0;
    let attachmentErrorCount = 0;

    const isLoanLimitCheck =
      dealerAgreementDetail?.loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.LOAN ||
      dealerAgreementDetail?.loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.BOTH;

    checkedLoanByArRequestList.forEach(checkedArInfo => {
      if (!checkedArInfo.requestedLoanAmount) {
        setError(`requestedArs.${checkedArInfo.index}.requestedLoanAmount`, { type: 'required' });
        zeroOrNegativeAmountErrorCount++;
      } else if (Number(checkedArInfo.requestedLoanAmount) <= 0) {
        setError(`requestedArs.${checkedArInfo.index}.requestedLoanAmount`, { type: 'min' });
        zeroOrNegativeAmountErrorCount++;
      } else if (
        Number(checkedArInfo.requestedLoanAmount) > Number(state.successArList[checkedArInfo.index].remainingArAmount)
      ) {
        setError(`requestedArs.${checkedArInfo.index}.requestedLoanAmount`, { type: 'max' });
        ARLimitExceededErrorCount++;
      } else if (
        isLoanLimitCheck &&
        Number(checkedArInfo.requestedLoanAmount) > Number(dealerAgreementDetail?.maxAgreementLoanCapacityAmount)
      ) {
        setError(`requestedArs.${checkedArInfo.index}.requestedLoanAmount`, { type: 'max' });
        agreementLimitExceededErrorCount++;
      }

      if (!checkedArInfo.invoiceAttachmentId) {
        setError(`requestedArs.${checkedArInfo.index}.invoiceAttachmentId`, { type: 'required' });
        attachmentErrorCount++;
      }
    });

    if (!disbursementDate) {
      modal.show(<h6>{t('text:Please_select_the_Requested_Disbursement_Date_for_financing')}</h6>, {
        closeBtnCb: () => scrollToDisbursementDate(),
      });

      return false;
    } else if (zeroOrNegativeAmountErrorCount) {
      modal.show(<h6>{t('text:Please_enter_the_Requested_Financing_Amount')}</h6>, {
        closeBtnCb: () => focusErrorElement(),
      });

      return false;
    } else if (ARLimitExceededErrorCount) {
      modal.show(<h6>{t('text:The_financing_amount_exceeds_the_Available_Request')}</h6>, {
        closeBtnCb: () => focusErrorElement(),
      });

      return false;
    } else if (agreementLimitExceededErrorCount) {
      modal.show(<h6>{t('text:The_financing_amount_exceeds_the_Financing_Limit')}</h6>, {
        closeBtnCb: () => focusErrorElement(),
      });

      return false;
    } else if (attachmentErrorCount) {
      modal.show(<h6>{t('text:Please_attach_the_Scanned_Tax_Invoice_File')}</h6>, {
        closeBtnCb: () => focusErrorElement(),
      });

      return false;
    } else if (checkedLoanByArRequestList.length === 0) {
      modal.show(<h6>{t('text:Please_enter_the_Requested_Financing_Amount')}</h6>, {
        closeBtnCb: () => scrollToArListTable(),
      });

      return false;
    }

    return true;
  };

  const loanEtcFileAttachmentValidator = (): boolean => {
    const data = getValues();
    let validationResult = true;

    const emptyFile = (id: number, fileName?: string) => data.etcAttachments?.[id]?.length === 0 && !fileName;
    const hasDescription = (id: number) => Boolean(data?.etcAttachmentDescriptions?.[id]);

    for (let i = 0; i < etcFileRows?.length; i++) {
      const item = etcFileRows?.[i];
      const onlyDescriptionAndNoFile = emptyFile(item?.id, item?.fileName) && hasDescription(item?.id);

      if (onlyDescriptionAndNoFile) {
        modal.show(<h6>{t('text:Attach_a_file_to_the_Additional_Documents_section')}</h6>, {
          closeBtnCb: () => {
            setError(`loanEtcAttachments${item?.id}`, { shouldFocus: true });
          },
        });
        validationResult = false;
        break;
      }
    }

    return validationResult;
  };

  const onChangeFinancingCheckBoxToggle = (checked: boolean) => {
    setFinancingCheckBoxToggle(checked);
  };

  const onChangeBankAccountCheckBoxToggle = (checked: boolean) => {
    setBankAccountCheckBoxToggle(checked);
  };

  const validateStep2Form = async () => {
    clearErrors();

    if (!confirmCheckBoxValidator()) return false;
    else if (!(await financingRequestValidator())) return false;
    else if (!loanEtcFileAttachmentValidator()) return false;
    else if (isEmpty(state.dealerArBulkCalculateLoanRequest)) {
      modal.show(<h6>{t('text:Please_check_the_estimate_button')}</h6>, {
        closeBtnCb: () => scrollToEstimateButton(),
      });

      return false;
    }

    return true;
  };

  const handleClickNextPageButton = async () => {
    if (await validateStep2Form()) {
      setFormStep(prevState => prevState + 1);
      scrollToTopOfElement();
      await fetchLoanAdditionalDocumentList();
    }
  };

  const handleClickPrevPageButton = () => {
    setFormStep(prevState => prevState - 1);
    scrollToTopOfElement();
  };

  const hardCopyConditionCheckboxValidator = () => {
    let validationResult = true;

    const requiredCheckboxesDocumentList = additionalDocumentList.filter(item => item.hardCopyCondition.required);
    if (
      requiredCheckboxesDocumentList.length > 0 &&
      checkedRequiredDocumentsIds.length !== requiredCheckboxesDocumentList.length
    ) {
      modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        modalType: ModalType.ALERT,
      });

      validationResult = false;
    }

    return validationResult;
  };

  const requiredFileUploadLoanAdditionalDocumentValidator = () => {
    let validationResult = true;

    const requiredFileUploadLoanAdditionalDocumentIds = additionalDocumentList
      .filter(item => item.mandatoryCondition.required)
      .map(item => item.loanAdditionalDocumentId);

    const { loanAdditionalDocumentAttachments } = getValues();

    if (loanAdditionalDocumentAttachments) {
      for (let i = 0; i < loanAdditionalDocumentAttachments.length; i++) {
        const item = loanAdditionalDocumentAttachments[i];
        if (
          item?.attachment?.length === 0 &&
          requiredFileUploadLoanAdditionalDocumentIds.includes(Number(item.loanAdditionalDocumentId))
        ) {
          modal.show(
            <>
              <h6>{t('text:This_financing_application_has_required_additional_documents')}</h6>
              <h6>{t('text:Please_attach_file')}</h6>
            </>,
            {
              modalType: ModalType.ALERT,
            },
          );
          validationResult = false;
          break;
        }
      }
    }

    return validationResult;
  };

  const validateStep3Form = async () => {
    if (!hardCopyConditionCheckboxValidator()) return false;
    else if (!requiredFileUploadLoanAdditionalDocumentValidator()) return false;
    else {
      await onFormSubmit();
    }
  };

  const handleCheckboxChange = (id: number) => {
    setCheckedRequiredDocumentsIds(prevCheckedIds =>
      prevCheckedIds.includes(id) ? prevCheckedIds.filter(itemId => itemId !== id) : [...prevCheckedIds, id],
    );
  };

  const handleClickBackTitle = () => {
    if (formStep === 3) {
      handleClickPrevPageButton();
    } else {
      history.back();
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Application')} onClick={handleClickBackTitle} />
      <FormProvider {...dealerBulkApplicationStep2UseForm}>
        <form>
          <div className="content-area">
            <StepWizard
              nth={formStep}
              title={[t('text:Select_AR'), t('text:Financing_Request'), t('text:Additional_Documents')]}
            />
          </div>
          <div
            className={clsx({
              'd-none': formStep === 3,
            })}
          >
            <div className="content-area">
              <BulkArInformation dealerAgreementDetail={dealerAgreementDetail} />
            </div>
            <div className="content-area" data-testid="financingRequestId">
              <BulkFinancingRequest
                dealerArBulkApplicationStep2State={state}
                fetchDealerArBulkCalculateLoanRequest={fetchDealerArBulkCalculateLoanRequest}
                totalRequestedAmount={totalRequestedAmount}
                calculateRequestedAmount={calculateRequestedAmount}
                handleCheckAll={handleCheckAll}
                handleCheckChange={handleCheckChange}
                disbursementDateRef={disbursementDateRef}
                arListTableRef={arListTableRef}
                getCheckedLoanByArRequestList={getCheckedLoanByArRequestList}
                focusErrorElement={focusErrorElement}
                estimateButtonRef={estimateButtonRef}
                selectedTotalArAmountState={selectedTotalArAmountState}
                showedTotalRequestedAmountState={showedTotalRequestedAmountState}
                initializeFinancingConditionInfo={initializeFinancingConditionInfo}
              />
              <ConfirmCheckBox
                id="flexCheckDefault1-1"
                checked={financingCheckBoxToggle}
                onChangeCheckBox={onChangeFinancingCheckBoxToggle}
                labelText={t(
                  'text:I_have_checked_the_AR_information_disbursement_date_repayment_date_and_financing_conditions',
                )}
                ref={financingCheckBoxRef}
              />
            </div>
            <div className="content-area">
              <DesignatedBankAccountInfo dealerAgreementDetail={dealerAgreementDetail} factoringEnable={true} />
              <ConfirmCheckBox
                id="flexCheckDefault1-3"
                checked={bankAccountCheckBoxToggle}
                onChangeCheckBox={onChangeBankAccountCheckBoxToggle}
                labelText={t('text:I_have_checked_the_information_of_the_designated_bank_accounts')}
                ref={bankAccountCheckBoxRef}
              />
            </div>
            <div className="content-area">
              <BulkAdditionalDocuments {...etcFileRowsState} />
            </div>
          </div>
          <div
            className={clsx({
              'd-none': formStep === 2,
            })}
          >
            <div className="content-area">
              <ARBulkFinancingApplicationSummary
                totalRequestedAmount={totalRequestedAmount}
                dealerAgreementDetail={dealerAgreementDetail}
                dealerArBulkCalculateLoanRequest={state.dealerArBulkCalculateLoanRequest}
                selectedTotalArAmount={selectedTotalArAmountState.selectedTotalArAmount}
              />
            </div>
            {additionalDocumentList.length > 0 ? (
              <div className="content-area">
                <SectionTitle title={t('text:Required_Additional_Documents')} />
                <GuideMessage
                  message={[
                    `${t('text:Please_check_the_documents_submit_to_financier')} ` +
                      `${t(
                        'text:If_you_submit_the_wrong_documents_or_miss_the_documents_your_loan_application_may_not_be_approved',
                      )}`,
                    t(
                      'text:If_you_required_to_submit_hard_copy_please_ask_to_financier_then_check_the_disbursement_date_when_you_can_delivery_hard_copy_before_the_disbursement',
                    ),
                    `${t(
                      'text:If_you_click_the_save_button_and_leave_the_page_the_required_additional_documents_are_not_saved',
                    )} ` + `${t('text:only_the_additional_documents_are_saved')}`,
                  ]}
                  isImportContentArea
                />
                {state.additionalDocumentList?.map((document, index) => (
                  <RequiredDocumentForm
                    key={index}
                    index={index}
                    documentInfo={document}
                    checked={checkedRequiredDocumentsIds.includes(document.loanAdditionalDocumentId)}
                    handleCheckboxChange={handleCheckboxChange}
                    dealerAgreementId={state.dealerAgreementDetail.dealerAgreementId}
                  />
                ))}
              </div>
            ) : (
              <GuideMessage
                className="pb-0"
                message={[
                  `${t('text:There_are_no_additional_documents_required_for_this_financing_application')} ` +
                    `${t('text:Please_click_the_register_button_to_complete_financing_application')}`,
                ]}
              />
            )}
          </div>
          <div className="content-area d-flex">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={showCancelFinancingModal}
              className="me-auto"
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel')}
            </Button>
            {formStep === 2 && (
              <Button size={ButtonSizeEnum.LG} onClick={handleClickNextPageButton} className="ms-2">
                {t('text:Next')}
              </Button>
            )}
            {formStep === 3 && (
              <>
                <Button
                  size={ButtonSizeEnum.LG}
                  variant={ButtonVariantEnum.OUTLINED}
                  onClick={handleClickPrevPageButton}
                  className="ms-2"
                >
                  {t('text:Prev')}
                </Button>
                <Button size={ButtonSizeEnum.LG} onClick={validateStep3Form} className="ms-2">
                  {t('text:Register')}
                </Button>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default DealerArBulkFinancingApplicationStep2;
