import type { ButtonHTMLAttributes } from 'react';

import { Button2ColorEnum } from './Button2';
import './IconButton2.scss';

export enum IconButtonSizeEnum {
  DEFAULT = 'default',
  XS = 'xs',
}

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: JSX.Element;
  size?: IconButtonSizeEnum;
  color?: Button2ColorEnum;
  className?: string;
}

function IconButton2({
  icon,
  size = IconButtonSizeEnum.DEFAULT,
  color = Button2ColorEnum.PRIMARY,
  className = '',
  ...props
}: IconButtonProps) {
  return (
    <button className={`icon-button2 ${size} ${color} ${className}`} {...props}>
      {icon}
    </button>
  );
}

export default IconButton2;
