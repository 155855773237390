import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, USER_STATUS, stringToEnum } from 'enums';

export interface WaitingAnchorUserVO {
  id: number;
  name: string;
  employeeCode: number;
  email: string;
  mobile: string;
  position: string;
  userStatus: string;
  authorityType: string;
  bankUserId: string;
  approvalType: string;
  returnReason: string;
  anchorClientName: string;
  anchorClientCode: string;
  financierId: number;
  financierName: string;
  targetAnchorUserId: number;
  updatedDateTime: string;
  operatorUserLoginId: string;
  operatorUserName: string;
  authorizerUserLoginId: string;
  authorizerUserName: string;
  anchorUserOtpSerialNo: string;
}

export interface WaitingAnchorUserVOModel extends WaitingAnchorUserVO {
  userStatus: USER_STATUS;
  authorityType: AUTHORITY_TYPE;
  approvalType: COMMON_APPROVAL_TYPE;
}

export function formattingToWaitingAnchorUserVOModel(data: WaitingAnchorUserVO): WaitingAnchorUserVOModel {
  return {
    ...data,
    userStatus: stringToEnum(USER_STATUS, data.userStatus),
    authorityType: stringToEnum(AUTHORITY_TYPE, data.authorityType),
    approvalType: stringToEnum(COMMON_APPROVAL_TYPE, data.approvalType),
  };
}
