const options = {
  development: {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    }, // NREUM.init
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124433',
      sa: 1,
    }, // NREUM.info
    loader_config: {
      accountID: '3930240',
      trustKey: '3930240',
      agentID: '1386124433',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124433',
    }, // NREUM.loader_config
  },
  staging: {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386116961',
      sa: 1,
    },
    loader_config: {
      accountID: '3930240',
      trustKey: '3930240',
      agentID: '1386116961',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386116961',
    },
  },
  demo: {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124734',
      sa: 1,
    },
    loader_config: {
      accountID: '3930240',
      trustKey: '3930240',
      agentID: '1386124734',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124734',
    },
  },
  vietnamProduction: {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124790',
      sa: 1,
    },
    loader_config: {
      accountID: '3930240',
      trustKey: '3930240',
      agentID: '1386124790',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124790',
    },
  },
  indoProduction: {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124792',
      sa: 1,
    },
    loader_config: {
      accountID: '3930240',
      trustKey: '3930240',
      agentID: '1386124792',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124792',
    },
  },
  indonesiaProduction: {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124791',
      sa: 1,
    },
    loader_config: {
      accountID: '3930240',
      trustKey: '3930240',
      agentID: '1386124791',
      licenseKey: 'NRJS-62ac0985adbc85786fc',
      applicationID: '1386124791',
    },
  },
};

export default options;
