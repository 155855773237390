export enum CommonResponseCode {
  SUCCESS = 'CMN-001',
  FAILED = 'CMN-002',
}

export enum AgreementExceptionCode {
  INVALID_ANCHOR_AGREEMENT = 'AE-001',
  INVALID_DEALER_AGREEMENT = 'AE-002',
  ON_APPROVAL_PROGRESS_AGREEMENT = 'AE-003',
  NOT_ALLOWABLE_APPROVAL_TYPE = 'AE-004',
  OMITTED_ANCHOR_AGREEMENT_INFO = 'AE-005',
  OMITTED_DEALER_AGREEMENT_INFO = 'AE-006',
  INVALID_DEALER_AGREEMENT_LOAN_TERM = 'AE-007',
  INVALID_DEALER_AGREEMENT_INTEREST_REPAYMENT_TYPE = 'AE-008',
  INVALID_DEALER_AGREEMENT_INTEREST_TYPE = 'AE-009',
  INVALID_AGREEMENT_CURRENCY = 'AE-010',
  INVALID_AGREEMENT_DATE = 'AE-011',
  INVALID_AGREEMENT_STATUS = 'AE-012',
  NOT_ALLOWABLE_DEALER_UPLOAD_INVOICE = 'AE-013',
  INVALID_AGREEMENT_COLLATERAL = 'AE-014',
  INVALID_BASIS_INTEREST_RATE = 'AE-015',
  INVALID_LOAN_LIMIT_CHECK_TYPE = 'AE-016',
  INVALID_LOAN_OPTION = 'AE-017',
  INVALID_DEALER_AGREEMENT_PARTNER_TAX_CODE = 'AE-018',
  CAN_NOT_MODIFY_UNIQUE_FIELD = 'AE-019',
  ALREADY_EXIST_DEALER_AGREEMENT = 'AE-020',
}

export enum AnchorPartnerAccountExceptionCode {
  INVALID_PARTNER_ACCOUNT_PHASE = 'PAE-001',
  ON_APPROVAL_PROGRESS_PARTNER_ACCOUNT = 'PAE-002',
  ALREADY_CONFIRMED = 'PAE-003',
  INVALID_APPROVE_REQUEST = 'PAE-004',
  ALREADY_REGISTERED_ACCOUNT = 'PAE-005',
  ALREADY_ASSIGNED_ACCOUNT = 'PAE-006',
  INVALID_BANK_CODE = 'PAE-007',
}

export enum AnchorPartnerExceptionCode {
  INVALID_ANCHOR_PARTNER_PHASE = 'PE-001',
  ON_APPROVAL_PROGRESS_ANCHOR_PARTNER = 'PE-002',
  ALREADY_CONFIRMED = 'PE-003',
  INVALID_APPROVE_REQUEST = 'PE-004',
  ANOTHER_FINANCIER_INCLUDED = 'PE-005',
  MISMATCHED_TAX_CODE = 'PE-006',
  NOT_ALLOWABLE_POTENTIAL_PARTNER_REGISTER = 'PE-007',
}

export enum AnchorUserExceptionCode {
  INVALID_ANCHOR_USER = 'IAU-001',
  ANCHOR_USER_IS_EMPTY = 'IAU-002',
  ON_APPROVAL_PROGRESS = 'IAU-003',
  INVALID_TARGET_AUTHORITY = 'IAU-004',
  NOT_ALLOWABLE_STATUS = 'IAU-005',
  NOT_ALLOWABLE_APPROVAL_TYPE = 'IAU-006',
  ANCHOR_USER_STATUS_NOT_ACTIVATED = 'IAU-007',
  ANCHOR_USER_MUST_JOIN_THE_PLATFORM = 'IAU-008',
}

export enum ArExceptionCode {
  INVALID_AR = 'ARE-001',
  ALREADY_SETTLED = 'ARE-002',
  AR_STATUS_NOT_REGISTERED = 'ARE-003',
  INVALID_SETTLEMENT_DATE = 'ARE-004',
  AR_SETTLEMENT_DATE_OVER = 'ARE-005',
  NOT_AVAILABLE_AR_STATUS = 'ARE-006',
  ALREADY_COLLATERALIZED_AR = 'ARE-007',
  CAN_NOT_UPDATE_AR_ON_OR_AFTER_SETTLEMENT_DATE = 'ARE-008',
  DUPLICATE_AR_NUMBER = 'ARE-009',
  ALREADY_SETTLED_OR_UNSETTLED = 'ARE-010',
}

export enum ArPhaseExceptionCode {
  INVALID_AR_PHASE = 'APE-001',
  NOT_ALLOWABLE_AR_PHASE_STATUS = 'APE-002',
  ALREADY_CONFIRMED = 'APE-003',
}

export enum ArSummaryExceptionCode {
  INVALID_AR_SUMMARY = 'ASE-001',
  REJECTED_AR_SUMMARY = 'ASE-002',
  COLLATERAL_ISSUED_LIMIT_OVER = 'ASE-003',
  INVALID_PARTNER_TAX_CODE = 'ASE-004',
}

export enum AuthenticationExceptionCode {
  NOT_AUTHENTICATED = 'ATN-001',
  METHOD_NOT_ALLOWED = 'ATN-002',
  HANDLE_ACCESS_DENIED = 'ATN-003',
  RESTRICTED_RESOURCE = 'ATN-004',
  INVALID_OTP = 'ATN-005',
  NOT_AUTHORIZED = 'ATN-006',
  NOT_AUTHORIZED_FINANCIER = 'ATN-007',
  NOT_AUTHORIZED_DEALER = 'ATN-008',
  NOT_AUTHORIZED_ANCHOR = 'ATN-009',
  NOT_AUTHORIZED_OPERATOR = 'ATN-010',
  BAD_CREDENTIALS = 'ATN-011',
  INACTIVE_USER = 'ATN-012',
  NOT_AUTHORIZED_BRANCH = 'ATN-013',
  NOT_AUTHORIZED_POTENTIAL_PARTNER = 'ATN-014',
}

export enum BankCodeExceptionCode {
  INVALID_BANK_CODE = 'BC-001',
  NOT_ALLOWABLE_APPROVAL_TYPE = 'BC-002',
  USED_BANK_CODE = 'BC-003',
  INVALID_FINANCIER = 'BC-004',
}

export enum CollateralExceptionCode {
  INVALID_COLLATERAL = 'COL-001',
  ALREADY_COLLATERALIZED = 'COL-002',
  BLOCKED_COLLATERAL = 'COL-003',
  NOT_SUPPORTED_COLLATERAL_TYPE = 'COL-004',
  BALANCE_LIMIT_OVER = 'COL-005',
}

export enum CurrencyExchangeRateCode {
  INVALID_CURRENCY_EXCHANGE_RATE = 'CER-001',
}

export enum DivisionExceptionCode {
  INVALID_DIVISION = 'DIV-001',
  ON_APPROVAL_PROGRESS_AGREEMENT = 'DIV-002',
  ALREADY_EXIST_REGISTERED_AGREEMENT = 'DIV-003',
}

export enum DuplicateExceptionCode {
  DUPLICATE = 'D-001',
  BRANCH_DUPLICATE = 'D-002',
  BRANCH_STAFF_DUPLICATE = 'D-003',
  FINANCIER_CLIENT_CODE_DUPLICATE = 'D-004',
  ANCHOR_AGREEMENT = 'D-005',
  DEALER_AGREEMENT = 'D-006',
  ANCHOR_USER = 'D-007',
  USER_DUPLICATE = 'D-008',
  ENTERPRISE_DUPLICATE = 'D-009',
  FINANCIER_TYPE_DUPLICATE = 'D-010',
  FINANCIER_CLIENT_TAX_CODE_DUPLICATE = 'D-011',
  BANK_CODE_DUPLICATE = 'D-012',
  FINANCIER_DOCUMENT_FORM_DUPLICATE = 'D-013',
  FINANCIER_CURRENCY_COMMON_SETTING_DUPLICATE = 'D-014',
  BRANCH_CODE_DUPLICATE = 'D-015',
  ANCHOR_PARTNER_TAX_CODE_DUPLICATE = 'D-016',
  BASIS_INTEREST_DUPLICATE = 'D-017',
  ANCHOR_REPORT_SETTING_DUPLICATE = 'D-018',
  ANCHOR_REPORT_RECEIVER_DUPLICATE = 'D-019',
  INVOICE_CONFIRMATION_QUEUE_DUPLICATE = 'D-020',
  SUPPORTED_CURRENCY_TYPE_DUPLICATE = 'D-021',
}

export enum EmailExceptionCode {
  INVALID_EMAIL = 'EE-001',
  EXPIRED_EMAIL = 'EE-002',
  INVALID_ACCESS_CODE = 'EE-003',
  NOT_SUPPORTED_EMAIL_TYPE = 'EE-004',
}

export enum ExtensionRequestExceptionCode {
  INVALID_EXTENSION_REQUEST = 'IER-001',
  NOT_ALLOWABLE_EXTENSION_REQUEST_STATUS = 'IER-002',
  INVALID_EXTENSION_REQUEST_DATE = 'IER-003',
}

export enum FileExceptionCode {
  INVALID_FILE = 'FE-001',
  HAVE_NO_FILE = 'FE-002',
  FILE_IS_EMPTY = 'FE-003',
  CANNOT_STORE_FILE_WITH_RELATIVE_PATH = 'FE-004',
  FILE_DOES_NOT_EXISTS = 'FE-005',
  FAILED_TO_STORE = 'FE-006',
  NOT_IMAGE_FILE = 'FE-007',
}

export enum FinancierClientExceptionCode {
  INVALID_FINANCIER_CLIENT = 'IFC-001',
  INVALID_FINANCIER_CLIENT_TYPE = 'IFC-002',
  ON_APPROVAL_PROGRESS = 'IFC-003',
  NOT_ALLOWABLE_APPROVAL_TYPE = 'IFC-004',
  FINANCIER_CLIENT_STATUS_NOT_ACTIVATED = 'IFC-005',
  TAX_CODE_CAN_NOT_BE_CHANGED = 'IFC-006',
  MISMATCHED_TAX_CODE = 'IFC-007',
}

export enum FinancierInterfaceExceptionCode {
  NOT_AVAILABLE = 'FIF-001',
  INTERFACE_REQUEST_FAILED = 'FIF-002',
  STOP_SUPPLIER = 'FIF-003',
  AMOUNT_EXCEED_LIMIT = 'FIF-004',
  DATA_NOT_FOUND = 'FIF-005',
  FAIL = 'FIF-006',
  UNKNOWN_CURRENCY_TYPE = 'FIF-007',
  UNKNOWN_LOAN_STATUS = 'FIF-008',
  UNKNOWN_HOLIDAY_TYPE = 'FIF-009',
  UNKNOWN_AUTHORITY_TYPE = 'FIF-010',
  NOT_AVAILABLE_AUTHORITY = 'FIF-011',
  UNKNOWN_EXTENSION_STATUS = 'FIF-012',
  MISSING_TAX_NUMBER = 'FIF-013',
  UNSUPPORTED_INTERFACE = 'FIF-014',
  REPAYMENT_DATE_EXCEED_DEALER_AGREEMENT_EXPIRY_DATE = 'FIF-015',
  INVOICE_ALREADY_REGISTERED = 'FIF-016',
  ANCHOR_USER_EMAIL_NOT_FOUND = 'FIF-017',
  NOT_AVAILABLE_BASIS_INTEREST_TYPE = 'FIF-018',
  INVALID_PROFILE_OF_REQUEST = 'FIF-019',
  NOT_AVAILABLE_LOAN_TERM_TYPE = 'FIF-020',
  NOT_AVAILABLE_LOAN_TERM_UNIT = 'FIF-021',
  NOT_AVAILABLE_MONTHLY_INTEREST_REPAYMENT_DATE = 'FIF-022',
  NOT_REGISTERED_FINANCIER_HOLIDAYS = 'FIF-023',
  NOT_FOUND_REGION = 'FIF-024',
  NOT_AVAILABLE_LOAN_STATUS = 'FIF-025',
  NOT_AVAILABLE_AR_SETTLEMENT_STATUS = 'FIF-026',
  ACCOUNT_DATA_MISSING = 'FIF-027',
  EMPTY_FINANCIER_RESPONSE_CODE = 'FIF-028',
  UNKNOWN_FINANCIER_RESPONSE_CODE = 'FIF-029',
  UNKNOWN_FINANCIER_BASIS_INTEREST_CODE = 'FIF-030',
  FINANCIER_INTERNAL_SYSTEM_ERROR = 'FIF-031',
  UNKNOWN_FINANCIER_TYPE_CODE = 'FIF-032',
  JSON_PROCESSING_FAILED = 'FIF-033',
  CRYPTO_PROCESSING_FAILED = 'FIF-034',
  UNKNOWN_FINANCIER_INTEREST_REPAYMENT_CODE = 'FIF-035',
  PARTNER_CLIENT_TAX_CODE_MISSING = 'FIF-036',
  MISSING_INPUT_PARAMETER = 'FIF-037',
  DIGITAL_SIGNATURE_FAILED = 'FIF-038',
  AOTP_INVALID_REQUEST = 'FIF-039',
  AOTP_FINANCIER_SERVER_ERROR = 'FIF-040',
  USING_HOLIDAY_AS_SETTLEMENT_DATE = 'FIF-041',
  NOT_FOUND_AR_IN_BANK_SYSTEM = 'FIF-042',
  NOT_FOUND_AGREEMENT_IN_BANK_SYSTEM = 'FIF-043',
  CANNOT_INPUT_PAST_DATE = 'FIF-044',
  AOTP_AUTHENTICATION_FAILED = 'FIF-045',
  MANUAL_INVALID_REQUEST = 'FIF-046',
  XML_PROCESSING_FAILED = 'FIF-047',
  ANCHOR_AGREEMENT_MISSING = 'FIF-048',
  NOT_FOUND_TRANSFER_IN_BANK_SYSTEM = 'FIF-049',
  AOTP_QUERY_INTERNET_BANK_ERROR = 'FIF-050',
}

export enum InvalidArExceptionCode {
  INVALID_AR = 'IAE-001',
  AR_UPLOAD_LIMIT_OVE = 'IAE-002',
}

export enum InvalidInputValueExceptionCode {
  INVALID_INPUT_VALUE = 'IIV-001',
  MISMATCHED_TAX_CODE = 'IIV-002',
  MISMATCHED_ENTERPRISE_TYPE = 'IIV-003',
  MISMATCHED_PASSWORD = 'IIV-004',
  MISMATCHED_AUTHORITY_TYPE = 'IIV-005',
  INVALID_USER_STATUS = 'IIV-006',
  INVALID_FILE_SIZE = 'IIV-007',
  MISMATCHED_MOBILE_CODE = 'IIV-008',
  EXCEEDED_LIMIT_ROWS = 'IIV-009',
  TOTAL_AMOUNT_LESS_THAN_REPAID_AMOUNT = 'IIV-010',
  ALREADY_EXIST_USER_BY_EMPLOYEE_CODE = 'IIV-011',
  MISMATCHED_FILE_AND_DESCRIPTION_COUNT = 'IIV-012',
  INVALID_FILE_COUNT = 'IIV-013',
  MISMATCHED_PURPOSE_OF_LOAN_FILE_COUNT = 'IIV-014',
  INVALID_PERIOD_FOR_REGISTER_AR = 'IIV-015',
  METHOD_ARGUMENT_TYPE_MISMATCH = 'IIV-016',
  FACTORING_REQUIRE_INVOICE_ATTACHMENT = 'IIV-017',
}

export enum InvalidInvoiceExceptionCode {
  INVALID_INVOICE = 'IIE-01',
  INVALID_SETTLEMENT_DATE = 'IIE-02',
  INVALID_OPERATION_TIME = 'IIE-03',
  DUPLICATE_INVOICE = 'IIE-04',
  ALREADY_REGISTERED_INVOICE = 'IIE-05',
  ALREADY_CONFIRMED_INVOICE = 'IIE-06',
  INVOICE_UPLOAD_LIMIT_OVER = 'IIE-07',
  REFERENCE_NUMBER_IS_EMPTY = 'IIE-08',
  BOE_NUMBER_IS_EMPTY = 'IIE-09',
}

export enum InvalidInvoiceMultipleUploadExceptionCode {
  INVALID_INVOICE_MULTIPLE_UPLOAD = 'IMU-001',
}

export enum InvoiceExceptionCode {
  INVALID_INVOICE = 'INE-001',
  ALREADY_SETTLED = 'INE-002',
  INVOICE_STATUS_NOT_REGISTERED = 'INE-003',
  INVALID_SETTLEMENT_DATE = 'INE-004',
  INVOICE_SETTLEMENT_DATE_OVER = 'INE-005',
  NOT_AVAILABLE_INVOICE_STATUS = 'INE-006',
}

export enum InvoicePhaseExceptionCode {
  INVALID_INVOICE_PHASE = 'IPE-001',
  NOT_ALLOWABLE_INVOICE_PHASE_STATUS = 'IPE-002',
  ALREADY_CONFIRMED = 'IPE-003',
}

export enum InvoiceSummaryExceptionCode {
  INVALID_INVOICE_SUMMARY = 'ISE-001',
  REJECTED_INVOICE_SUMMARY = 'ISE-002',
  REGISTRATION_LIMIT_OVER = 'ISE-003',
  APPROVABLE_INVOICE_DOES_NOT_EXIST = 'ISE-004',
}

export enum LoanExceptionCode {
  INVALID_LOAN = 'L-001',
  LIMIT_OVER = 'L-002',
  NOT_ALLOWABLE_LOAN_STATUS = 'L-003',
  NOT_ALLOWABLE_LOAN_APPROVAL_TYPE = 'L-004',
  NOT_ALLOWED_EARLY_REPAYMENT = 'L-005',
  PASSED_DESIRED_DISBURSE_DATE = 'L-006',
  INVALID_LOAN_REPAYMENT = 'L-007',
  LOAN_REQUEST_FAILED = 'L-008',
  LOAN_APPROVE_FAILED = 'L-009',
  REPAYMENT_DATE_EXCEED_DEALER_AGREEMENT_EXPIRY_DATE = 'L-010',
  NOT_ALLOWABLE_LOAN_REJECT = 'L-011',
  OVERDUE_LOAN_EXISTS = 'L-012',
  LOAN_AMOUNT_EXCEED_PURPOSE_AMOUNT = 'L-013',
  LOAN_AMOUNT_IS_BELOW_MINIMUM = 'L-014',
  INVALID_LOAN_DISBURSEMENT_DATE = 'L-015',
  OVERDUE_LOAN_FROM_ANCHOR_AGREEMENT_EXISTS = 'L-016',
  DISBURSEMENT_DATE_EXCEED_ANCHOR_AGREEMENT_EXPIRY_DATE = 'L-017',
  INVALID_INTENDED_EARLY_REPAYMENT_DATE = 'L-018',
  FINANCIER_OPERATION_TIME_DOES_NOT_ALLOW_REQUEST = 'L-019',
  ALREADY_DISBURSED_AMOUNT_CANNOT_BE_CHANGED = 'L-020',
  LOAN_ETC_ATTACHMENT_CANNOT_BE_NULL = 'L-021',
}

export enum LoanTransactionExceptionCode {
  INVALID_LOAN_TRANSACTION = 'LT-001',
  NOT_ALLOWABLE_LOAN_TRANSACTION_STATUS = 'LT-002',
  ALL_OF_INPUT_AMOUNT_CAN_NOT_BE_ZERO = 'LT-003',
}

export enum OperationTimeExceptionCode {
  INVALID_OPERATION_TIME = 'OT-001',
}

export enum OtpExceptionCode {
  INVALID_OTP = 'OE-001',
  INVALID_OTP_CODE = 'OE-002',
  INVALID_OTP_TYPE = 'OE-003',
  EXPIRED_OTP_CODE = 'OE-004',
}

export enum PotentialPartnerFinancingApplicationDocumentExceptionCode {
  INVALID_POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT = 'PDE-001',
  NOT_AVAILABLE_POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS = 'PDE-002',
  REQUIRED_POTENTIAL_PARTNER_FINANCING_APPLICATION_ADDITIONAL_DOCUMENT_ATTACHMENT = 'PDE-003',
}

export enum PotentialPartnerFinancingApplicationExceptionCode {
  INVALID_POTENTIAL_PARTNER_FINANCING_APPLICATION = 'PFE-001',
  NOT_AVAILABLE_POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS = 'PFE-002',
  ALREADY_EXIST_REVIEWABLE_APPLICATION = 'PFE-003',
  ALREADY_EXIST_COMPLETE_APPLICATION = 'PFE-004',
}

export enum ResourceNotFoundExceptionCode {
  RESOURCE_NOT_FOUND = 'RNF-001',
  USER_NOT_FOUND = 'RNF-002',
  BRANCH_NOT_FOUND = 'RNF-003',
  ANCHOR_AGREEMENT_NOT_FOUND = 'RNF-004',
  DEALER_AGREEMENT_NOT_FOUND = 'RNF-005',
  USER_BRANCH_NOT_FOUND = 'RNF-006',
  ENTERPRISE_NOT_FOUND = 'RNF-007',
  FINANCIER_COMMON_SETTING_NOT_FOUND = 'RNF-008',
  FINANCIER_CLIENT_NOT_FOUND = 'RNF-009',
  ANCHOR_USER_NOT_FOUND = 'RNF-010',
  EARLY_REPAYMENT_REQUEST_NOT_FOUND = 'RNF-011',
  EXTENSION_REQUEST_NOT_FOUND = 'RNF-012',
  INVENTORY_SUMMARY_NOT_FOUND = 'RNF-013',
  ATTACHMENT_NOT_FOUND = 'RNF-014',
  INVOICE_SUMMARY_NOT_FOUND = 'RNF-015',
  FINANCIER_OPERATION_TIME_NOT_FOUND = 'RNF-016',
  LOAN_NOT_FOUND = 'RNF-017',
  LOAN_TRANSACTION_NOT_FOUND = 'RNF-018',
  COLLATERAL_NOT_FOUND = 'RNF-019',
  INVOICE_NOT_FOUND = 'RNF-020',
  WAITING_ANCHOR_AGREEMENT_NOT_FOUND = 'RNF-021',
  WAITING_DEALER_AGREEMENT_NOT_FOUND = 'RNF-022',
  WAITING_FINANCIER_CLIENT_NOT_FOUND = 'RNF-023',
  WAITING_ANCHOR_USER_NOT_FOUND = 'RNF-024',
  EMAIL_NOT_FOUND = 'RNF-025',
  FINANCIER_CLIENT_AUTH_SETTING_NOT_FOUND = 'RNF-026',
  DEALER_ENTERPRISE_NOT_FOUND = 'RNF-027',
  ANCHOR_ENTERPRISE_NOT_FOUND = 'RNF-028',
  FINANCIER_CALENDAR_NOT_FOUND = 'RNF-029',
  FINANCIER_LOGO_NOT_FOUND = 'RNF-030',
  INVOICE_PHASE_NOT_FOUND = 'RNF-031',
  OTP_NOT_FOUND = 'RNF-032',
  WAITING_ANCHOR_PARTNER_NOT_FOUND = 'RNF-033',
  FINANCIER_ALERT_SETTING_NOT_FOUND = 'RNF-034',
  AR_SUMMARY_NOT_FOUND = 'RNF-035',
  WAITING_AR_NOT_FOUND = 'RNF-036',
  AR_PHASE_NOT_FOUND = 'RNF-037',
  AR_NOT_FOUND = 'RNF-038',
  ANCHOR_PARTNER_NOT_FOUND = 'RNF-039',
  ANCHOR_PARTNER_ACCOUNT_NOT_FOUND = 'RNF-040',
  AR_SETTLEMENT_DETAIL_NOT_FOUND = 'RNF-041',
  SUCCESS_AR_NOT_FOUND = 'RNF-042',
  BANK_CODE_NOT_FOUND = 'RNF-043',
  ANCHOR_USER_ANCHOR_RELATION_NOT_FOUND = 'RNF-044',
  WAITING_ANCHOR_PARTNER_ACCOUNT_NOT_FOUND = 'RNF-045',
  WAITING_BANK_CODE_NOT_FOUND = 'RNF-046',
  INVOICE_ATTACHMENT_NOT_FOUND = 'RNF-047',
  FINANCIER_DOCUMENT_FORM_NOT_FOUND = 'RNF-048',
  FINANCIER_CURRENCY_COMMON_SETTING_NOT_FOUND = 'RNF-049',
  PURPOSE_OF_LOAN_NOT_FOUND = 'RNF-050',
  PARTIAL_COLLATERAL_NOT_FOUND = 'RNF-051',
  SUCCESS_INVOICE_NOT_FOUND = 'RNF-052',
  BASIS_INTEREST_NOT_FOUND = 'RNF-053',
  ANCHOR_REPORT_SETTING_NOT_FOUND = 'RNF-054',
  ANCHOR_REPORT_NOT_FOUND = 'RNF-055',
  ANCHOR_REPORT_RECEIVER_NOT_FOUND = 'RNF-056',
  CURRENCY_EXCHANGE_RATE_NOT_FOUND = 'RNF-057',
  SUPPORTED_CURRENCY_TYPE_NOT_FOUND = 'RNF-058',
  TEMP_LOAN_NOT_FOUND = 'RNF-059',
  POTENTIAL_PARTNER_ACQUISITION_DOCUMENT_SETTING_NOT_FOUND = 'RNF-060',
  POTENTIAL_PARTNER_FINANCING_APPLICATION_NOT_FOUND = 'RNF-061',
  POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_NOT_FOUND = 'RNF-062',
  DASHBOARD_POTENTIAL_PARTNER_FINANCING_NOT_FOUND = 'RNF-063',
  FISCAL_YEAR_SETTING_NOT_FOUND = 'RNF-064',
  DIVISION_NOT_FOUND = 'RNF-065',
  TRANSFER_NOT_FOUND = 'RNF-066',
  AR_COMMISSION_SETTLEMENT_DETAIL_NOT_FOUND = 'RNF-067',
  AR_COMMISSION_SETTING_NOT_FOUND = 'RNF-068',
  AR_COMMISSION_SETTLEMENT_RELATED_LOAN_DETAIL_NOT_FOUND = 'RNF-069',
}

export enum SystemConfigurationExceptionCode {
  INVALID_SYSTEM_CONFIGURATION = 'SCE-001',
  INVALID_INTERFACE_SETTING = 'SCE-002',
}

export enum TransferExceptionCode {
  CAN_NOT_CREATE_TRANSFER = 'TE-001',
}

export enum UserExceptionCode {
  INVALID_USER = 'USR-001',
  ALREADY_INVITED_USER = 'USR-002',
  NOT_ALLOWABLE_USER_STATUS = 'USR-003',
  NOT_INVITED_USER = 'USR-004',
  MUST_BE_JOINED_FROM_ADMIN = 'USR-005',
}

export type ResponseCodeType =
  | CommonResponseCode
  | AgreementExceptionCode
  | AnchorPartnerAccountExceptionCode
  | AnchorPartnerExceptionCode
  | AnchorUserExceptionCode
  | ArExceptionCode
  | ArPhaseExceptionCode
  | ArSummaryExceptionCode
  | AuthenticationExceptionCode
  | BankCodeExceptionCode
  | CollateralExceptionCode
  | CurrencyExchangeRateCode
  | DuplicateExceptionCode
  | EmailExceptionCode
  | ExtensionRequestExceptionCode
  | FileExceptionCode
  | FinancierClientExceptionCode
  | FinancierInterfaceExceptionCode
  | InvalidArExceptionCode
  | InvalidInputValueExceptionCode
  | InvalidInvoiceExceptionCode
  | InvalidInvoiceMultipleUploadExceptionCode
  | InvoiceExceptionCode
  | InvoicePhaseExceptionCode
  | InvoiceSummaryExceptionCode
  | LoanExceptionCode
  | LoanTransactionExceptionCode
  | OperationTimeExceptionCode
  | OtpExceptionCode
  | PotentialPartnerFinancingApplicationDocumentExceptionCode
  | PotentialPartnerFinancingApplicationExceptionCode
  | ResourceNotFoundExceptionCode
  | SystemConfigurationExceptionCode
  | UserExceptionCode
  | DivisionExceptionCode;
