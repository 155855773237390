import { INVOICE_NUMBER_DUPLICATION_POLICY, stringToEnum } from 'enums';

export interface FiscalYearSettingVO {
  id: number;
  month: number;
  date: number;
  invoiceNumberDuplicationPolicy: string;
  enterpriseId: number;
}

export interface FiscalYearSettingVOModel extends FiscalYearSettingVO {
  invoiceNumberDuplicationPolicy: INVOICE_NUMBER_DUPLICATION_POLICY;
}

export function formattingToFiscalYearSettingVOModel(data: FiscalYearSettingVO): FiscalYearSettingVOModel {
  return {
    ...data,
    invoiceNumberDuplicationPolicy: stringToEnum(
      INVOICE_NUMBER_DUPLICATION_POLICY,
      data.invoiceNumberDuplicationPolicy,
    ),
  };
}
