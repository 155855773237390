import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { requestAnchorUserAnchorAgreementList } from 'utils/http/api/anchor/users';
import useModal from 'utils/modal/useModal';
import { getPaymentSupportText } from 'utils/text';

const getConstants = () => {
  const { t } = useTranslation(['format']);

  const ASSIGEND_ANCHOR_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      colWidths: 45,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 200,
    },
    {
      headerText: t('text:Program_Type'),
      colWidths: 120,
    },
    {
      headerText: t('text:Automatic_Settlement'),
      colWidths: 160,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Responsible_Financier_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 110,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 110,
    },
  ];

  return { t, ASSIGEND_ANCHOR_AGREEMENT_TABLE_HEADERS };
};

interface DeleteAgreementModalPropsType {
  userId: number;
  getSelectedAssignedAnchorAgreementIds(data: number[]): void;
}

function DeleteAgreementModal({
  userId,
  getSelectedAssignedAnchorAgreementIds,
}: DeleteAgreementModalPropsType): JSX.Element {
  const mounted = useMounted();
  const modal = useModal();
  const { t, ASSIGEND_ANCHOR_AGREEMENT_TABLE_HEADERS } = getConstants();

  const [assignedAnchorAgreementPageData, setAssignedAnchorAgreementPageData] =
    useState<Pageable<AnchorAgreementVOModel[]>>();
  const { pageable: assignedAnchorAgreementPageable, setPageable: setAssignedAnchorAgreementPageable } = usePageable();
  const [selectedAssignedAnchorAgreementIds, setSelectedAssignedAnchorAgreementIds] = useState<number[]>([]);
  let guideMessage: string[] = [
    t('text:Below_is_the_list_of_assigned_Anchor_Master_Agreements'),
    t('text:Select_the_agreements_you_want_to_delete_and_click_on_the_Delete_button'),
  ];

  useEffect(() => {
    if (mounted) {
      fetchAll();
      if (!assignedAnchorAgreementPageData) {
        guideMessage = [
          t('text:Below_is_the_list_of_assigned_Anchor_Master_Agreements'),
          `${t('text:Only_the_agreements_you_are_associated_with_can_be_deleted')} ${t(
            'text:No_agreement_will_be_shown_below_if_the_Admin_Operator_wasnt_assigned_an_agreement_that_you_are_authorized_to_delete',
          )}`,
          t('text:Select_the_agreements_you_want_to_delete_and_click_on_the_Delete_button'),
        ];
      }
    }
  }, [mounted]);

  useEffect(() => {
    getSelectedAssignedAnchorAgreementIds(selectedAssignedAnchorAgreementIds);
  }, [selectedAssignedAnchorAgreementIds]);

  const fetchAll = async (selectedPageNumber: number = 1, selectedRowCount: number = 10): Promise<void> => {
    try {
      const assignedAnchorAgreementPageResponse = await requestAnchorUserAnchorAgreementList(
        userId,
        selectedPageNumber,
        selectedRowCount,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAssignedAnchorAgreementPageData(assignedAnchorAgreementPageResponse);
        setAssignedAnchorAgreementPageable(assignedAnchorAgreementPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderAssignedAnchorAgreementSearchResult = (): JSX.Element[] | undefined => {
    const handleCheckChange = (e: any, assignedAnchorAgreementId: number): void => {
      if (e.target.checked) {
        setSelectedAssignedAnchorAgreementIds([...selectedAssignedAnchorAgreementIds, assignedAnchorAgreementId]);
      } else {
        setSelectedAssignedAnchorAgreementIds(
          selectedAssignedAnchorAgreementIds.filter(item => item !== assignedAnchorAgreementId),
        );
      }
    };

    return assignedAnchorAgreementPageData?.content.map((item, index) => (
      <Tr key={index}>
        <td className="bg-sub100" key={index}>
          <div className="text-center">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={e => handleCheckChange(e, item.anchorAgreementId)}
            />
          </div>
        </td>
        <Td data={item.contractNo} />
        <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
        <Td data={getPaymentSupportText(item.paymentSupport)} />
        <Td data={item.currencyType} />
        <Td data={item.financierName} />
        <Td data={item.branchName} />
        <Td data={item.startDate} format="date" />
        <Td data={item.expiryDate} format="date" />
      </Tr>
    ));
  };

  return (
    <>
      <GuideMessage message={guideMessage} />
      <div className="content-area mt-4">
        <SectionTitle title={t('text:Assigned_Agreement')} />
        <TableBorder>
          <TableHeader header={ASSIGEND_ANCHOR_AGREEMENT_TABLE_HEADERS} />
          <TableBody numOfCol={ASSIGEND_ANCHOR_AGREEMENT_TABLE_HEADERS.length}>
            {renderAssignedAnchorAgreementSearchResult()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={assignedAnchorAgreementPageable} paginate={fetchAll} />
      </div>
    </>
  );
}

export default DeleteAgreementModal;
