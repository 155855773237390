import type { FinancierClientAuthSettingVO } from 'models/vo/FinancierClientAuthSettingVO';
import { formattingToFinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

export const requestFinancierAuthSetting = async () => {
  const response = await http.get<FinancierClientAuthSettingVO>({
    url: API_FI.CLIENT_AUTH_SETTING.AUTH_SETTING,
  });

  return formattingToFinancierClientAuthSettingVOModel(response);
};
