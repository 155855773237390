import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

import options from './options';

type NewRelicEnv = 'staging' | 'development' | 'demo' | 'vietnamProduction' | 'indoProduction' | 'indonesiaProduction';

const getEnvironmentOptions = (env: string | undefined): object | undefined => {
  if (!env) {
    return undefined;
  }

  const envMapping: { [K in NewRelicEnv]?: object } = {
    development: options.development,
    staging: options.staging,
    demo: options.demo,
    vietnamProduction: options.vietnamProduction,
    indoProduction: options.indoProduction,
    indonesiaProduction: options.indonesiaProduction,
  };

  return envMapping[env as NewRelicEnv];
};

const environmentOptions = getEnvironmentOptions(process.env.REACT_APP_NEWRELIC_ENVIRONMENT);

let browserAgent;
if (environmentOptions) {
  browserAgent = new BrowserAgent(environmentOptions);
}

export default browserAgent;
