import { useTranslation } from 'react-i18next';

import Form, { useFormContext } from 'components/stateless/Form';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { Content, Row, SubTitle } from 'components/templates/section';
import { useUppercaseInput } from 'hooks/useUppercaseInput';

interface DisbursementAccountPropsType {
  isVendorFactoring?: boolean;
}

const DisbursementAccount = ({ isVendorFactoring }: DisbursementAccountPropsType) => {
  const { t } = useTranslation();
  const handleUppercaseInput = useUppercaseInput();

  const {
    methods: { register },
  } = useFormContext();

  return (
    <>
      <SubTitle>{t('text:DISBURSEMENT_ACCOUNT')}</SubTitle>
      <Content>
        <Row singleColumnPosition="top">
          <SectionMessage
            messageType="description"
            message={[t('text:The_financing_will_be_disbursed_to_this_bank_account_on_the_disbursement_date')]}
          />
          <Form.Control name="settlementAccountFinancierName" required={isVendorFactoring}>
            <Form.Label>{t('text:Bank_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
          <Form.Control name="settlementAccountBranchName" required={isVendorFactoring}>
            <Form.Label>{t('text:Branch_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
          <Form.Control name="settlementAccount" required={isVendorFactoring}>
            <Form.Label>{t('text:Bank_Account_Number')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
          <Form.Control name="settlementAccountOwner" required={isVendorFactoring}>
            <Form.Label>{t('text:Bank_Account_Holder')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
          <input type="hidden" name="settlementAccountBranchCode" ref={register} />
          <input type="hidden" name="settlementAccountFinancierCode" ref={register} />
        </Row>
      </Content>
    </>
  );
};

export default DisbursementAccount;
