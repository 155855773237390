import { useTranslation } from 'react-i18next';

import { COMMON_APPROVAL_TYPE } from 'enums';

import { useFinancingOptionContext } from '../../../models/financingOption/FinancingOptionContext';

const useWaitingAnchorOptionApprovalInfoController = () => {
  const { t } = useTranslation(['format']);
  const { anchorFinancingOption } = useFinancingOptionContext();
  const {
    approvalType,
    updatedDateTime,
    financierName,
    authorizerUserName,
    authorizerUserLoginId,
    operatorUserName,
    operatorUserLoginId,
  } = anchorFinancingOption;

  const UserInfo = (() => {
    const defaultUserName = t('text:User_Name');
    const defaultUserID = t('text:User_ID');

    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return `${t('text:Authorizer')} (${authorizerUserName ?? defaultUserName} / ${
          authorizerUserLoginId ?? defaultUserID
        })`;
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return `${t('text:Operator')} (${operatorUserName ?? defaultUserName} / ${
          operatorUserLoginId ?? defaultUserID
        })`;
      default:
        return '-';
    }
  })();

  return {
    UserInfo,
    updatedDateTime,
    financierName,
    approvalType,
  };
};

export default useWaitingAnchorOptionApprovalInfoController;
