import { parse } from 'csv-parse';
import i18n from 'i18next';

import type { InputType } from 'pages/anchor/register-ap/registration/step2/AnchorApRegisterStep2';

import type { Options } from 'csv-parse';

const DEFAULT_FILE_UPLOAD_MAX_ROW_COUNT = 500;
const DEFAULT_BULK_IMPORT_MAX_ROW_COUNT = 3000;

export const csvToJson = (
  csvFile: File,
  fileUploadMaxRowCount: number = DEFAULT_FILE_UPLOAD_MAX_ROW_COUNT,
  bulkImportMaxRowCount: number = DEFAULT_BULK_IMPORT_MAX_ROW_COUNT,
  inputType: InputType = 'fileUpload',
): Promise<unknown[]> => {
  const csvToArray = (text: string) => {
    const parseOptions: Options = {
      comment: '#',
      columns: true,
      skip_empty_lines: true,
      skip_records_with_empty_values: true,
      trim: true,
      from_line: 2,
    };

    return new Promise((resolve: (value: unknown[]) => void, reject) => {
      parse(text, parseOptions, (error, records: unknown[]) => {
        if (error) {
          reject(error);

          return;
        }
        if (records.length > fileUploadMaxRowCount && inputType === 'fileUpload') {
          reject(
            `${i18n.t('text:Allowed_N_rows_at_a_time', { number: fileUploadMaxRowCount })} ${i18n.t(
              'text:Please_use_bulk_upload',
            )}`,
          );

          return;
        } else if (records.length > bulkImportMaxRowCount && inputType === 'bulkImport') {
          reject(
            `${i18n.t('text:Allowed_N_rows_at_a_time', { number: bulkImportMaxRowCount })} ${i18n.t(
              'text:Please_check_the_data_again',
            )}`,
          );

          return;
        }
        resolve(records);
      });
    });
  };

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const text = reader.result as string;
        const jsonArrayData = await csvToArray(text);
        resolve(jsonArrayData);
      } catch (error) {
        reject(error);
      }
    };

    reader.readAsText(csvFile);
  });
};
