import { useFormContext } from 'components/stateless/Form';
import useExtraInformationViewModel from 'pages/financier/manage-partner/agreement/models/extraInformation/useExtraInformationViewModel';

import useRegistrationAssociatedAnchorMasterAgreementController from './useRegistrationAssociatedAnchorMasterAgreementController';
import useRegistrationDetailAssociatedAnchorMasterAgreementController from './useRegistrationDetailAssociatedAnchorMasterAgreementController';
import useWaitingDetailAssociatedAnchorMasterAgreementController from './useWaitingDetailAssociatedAnchorMasterAgreementController';

const useAssociatedAnchorMasterAgreementController = () => {
  const { getControllerByPageType } = useExtraInformationViewModel();

  const {
    methods: { register },
  } = useFormContext();

  const controller = getControllerByPageType({
    register: useRegistrationAssociatedAnchorMasterAgreementController,
    registeredDetail: useRegistrationDetailAssociatedAnchorMasterAgreementController,
    waitingDetail: useWaitingDetailAssociatedAnchorMasterAgreementController,
  });

  return { ...controller, register };
};

export default useAssociatedAnchorMasterAgreementController;
