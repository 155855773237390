import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import { COLLATERAL_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getCollateralType } from 'utils/storage/LocalStorage';

import useRegistrationAnchorCompanyController from './useRegistrationAnchorCompanyController';
import useRegistrationDetailAnchorCompanyController from './useRegistrationDetailAnchorCompanyController';
import useWaitingDetailAnchorCompanyController from './useWaitingDetailAnchorCompanyController';
import SearchAnchorUserModal from '../../../modals/SearchAnchorUserModal';
import useAnchorClientInfoViewModel from '../../../models/agreement/useAnchorClientInfoViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useAnchorCompanyController = () => {
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();

  const { anchorClientInfo, updateAnchorUserList } = useAnchorClientInfoViewModel();
  const { getControllerByPageType, supportedCollateralType, useAgreementInterface } = useExtraInformationViewModel();
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;
  const isOnlySupportVendorFinancing = getCollateralType() === SUPPORTED_COLLATERAL_TYPE.AR;

  const {
    methods: { register, setValue, errors },
  } = useFormContext();

  const controller = getControllerByPageType({
    register: useRegistrationAnchorCompanyController,
    registeredDetail: useRegistrationDetailAnchorCompanyController,
    waitingDetail: useWaitingDetailAnchorCompanyController,
  });

  const handleSearchAnchorUserClick = () => {
    if (!anchorClientInfo.anchorClientId) {
      return showModal(<h6>{t('text:Please_select_the_anchor_company_first')}</h6>);
    }

    let selectedAnchorUserList: AnchorUserVOModel[] = [];

    const manageSelectedAnchorUserList = (anchorUser: AnchorUserVOModel, isChecked: boolean) => {
      if (isChecked) {
        selectedAnchorUserList = [...selectedAnchorUserList, anchorUser];
      } else {
        selectedAnchorUserList = selectedAnchorUserList.filter(
          selectedAnchorUser => selectedAnchorUser.id !== anchorUser.id,
        );
      }
    };

    const handleConfirmButtonClick = () => {
      setValue(
        'bankUserIdList',
        selectedAnchorUserList.map(anchorUser => anchorUser.bankUserId),
      );

      updateAnchorUserList(selectedAnchorUserList);
    };

    showModal(
      <SearchAnchorUserModal
        anchorClientId={anchorClientInfo.anchorClientId}
        selectedAnchorUserListManager={manageSelectedAnchorUserList}
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: handleConfirmButtonClick,
      },
    );
  };

  return {
    ...controller,
    register,
    errors,
    handleSearchAnchorUserClick,
    isAr,
    useAgreementInterface,
    isOnlySupportVendorFinancing,
  };
};

export default useAnchorCompanyController;
