import type Pageable from 'models/Pageable';
import type { ArSummaryVO, ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import { formattingToArSummaryVOModel } from 'models/vo/ArSummaryVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemArSummariesListRequest } from './request';

// 매출채권 목록 조회
export async function requestSystemArSummaryList(
  pageNumber: number,
  rowCount: number,
  searchValue: SystemArSummariesListRequest,
): Promise<Pageable<ArSummaryVOModel[]>> {
  const response = await http.get<Pageable<ArSummaryVO[]>>({
    url: API_SY.AR_SUMMARIES.AR_SUMMARY_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const arSummaryList: ArSummaryVOModel[] = response.content.map((data: ArSummaryVO) =>
    formattingToArSummaryVOModel(data),
  );

  return {
    ...response,
    content: arSummaryList,
  };
}

// 매출채권 상세 조회
export async function requestSystemArSummaryDetail(arSummaryId: number): Promise<ArSummaryVOModel> {
  const response = await http.get<ArSummaryVO>({
    url: API_SY.AR_SUMMARIES.AR_SUMMARY_DETAIL(arSummaryId),
  });

  return formattingToArSummaryVOModel(response);
}
