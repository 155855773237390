import type Pageable from 'models/Pageable';
import type {
  CalculateForMultipleLoanRequestVO,
  CalculateForMultipleLoanRequestVOModel,
} from 'models/vo/CalculateForMultipleLoanVO';
import { formattingToCalculateForMultipleLoanRequestVOModel } from 'models/vo/CalculateForMultipleLoanVO';
import type { FactoringAttachmentVO, FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import { formattingToFactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import { formattingToLoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type {
  LoanAdditionalDocumentAttachmentVO,
  LoanAdditionalDocumentAttachmentVOModel,
} from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import { formattingToMultipleLoanRequestDetailVOModel } from 'models/vo/MultipleLoanRequestDetailVO';
import type {
  MultipleLoanRequestDetailVO,
  MultipleLoanRequestDetailVOModel,
} from 'models/vo/MultipleLoanRequestDetailVO';
import { formattingToMultipleLoanRequestRelatedLoanVOModel } from 'models/vo/MultipleLoanRequestRelatedLoanVO';
import type {
  MultipleLoanRequestRelatedLoanVO,
  MultipleLoanRequestRelatedLoanVOModel,
} from 'models/vo/MultipleLoanRequestRelatedLoanVO';
import { formattingToMultipleLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleLoanRequestRelatedSuccessArVO';
import type {
  MultipleLoanRequestRelatedSuccessArVO,
  MultipleLoanRequestRelatedSuccessArVOModel,
} from 'models/vo/MultipleLoanRequestRelatedSuccessArVO';
import { formattingToMultipleLoanRequestVOModel } from 'models/vo/MultipleLoanRequestVO';
import type { MultipleLoanRequestVO, MultipleLoanRequestVOModel } from 'models/vo/MultipleLoanRequestVO';
import http, { ContentType } from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type {
  CreateDeMultipleRequestLoanByArRequest,
  DeSuccessArsMultipleFactoringAttachmentRequest,
  DealerMultipleRequestApproveRequest,
  DealerMultipleRequestLoanDownloadAttachmentRequest,
  DealerMultipleRequestLoanListRequest,
  RequestCalculateDeForMultipleLoan,
} from './request';

export async function requestDealerMultipleRequestLoanList(
  pageNumber: number,
  rowCount: number,
  searchValue?: DealerMultipleRequestLoanListRequest,
): Promise<Pageable<MultipleLoanRequestVOModel[]>> {
  const response = await http.get<Pageable<MultipleLoanRequestVO[]>>({
    url: API_DE.MULTIPLE_REQUEST_LOANS.MULTIPLE_REQUEST_LOAN_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map((data: MultipleLoanRequestVO) => formattingToMultipleLoanRequestVOModel(data)),
  };
}

// 복수 대출 신청
export async function requestDealerMultipleArLoanRegistration(data: CreateDeMultipleRequestLoanByArRequest) {
  await http.post({
    url: API_DE.MULTIPLE_REQUEST_LOANS.REGISTER_MULTIPLE_LOANS,
    data,
    contentType: ContentType.MULTIPART,
  });
}
//복수 대출 신청 건 예상 금액 계산
export async function requestDealerArBulkCalculateLoanRequest(
  data: RequestCalculateDeForMultipleLoan,
): Promise<CalculateForMultipleLoanRequestVOModel> {
  const response = await http.post<CalculateForMultipleLoanRequestVO>({
    url: API_DE.MULTIPLE_REQUEST_LOANS.CALCULATE_MULTIPLE_REQUEST_LOANS,
    data,
    hasLoadingUI: false,
  });

  return formattingToCalculateForMultipleLoanRequestVOModel(response);
}

//복수 대출 전자서명을 위한 대출서류 생성
export async function requestDealerMultipleFactoringAttachments(
  data: DeSuccessArsMultipleFactoringAttachmentRequest,
): Promise<FactoringAttachmentVOModel> {
  const response = await http.post<FactoringAttachmentVO>({
    url: API_DE.MULTIPLE_REQUEST_LOANS.MULTIPLE_FACTORING_ATTACHMENTS,
    data,
    hasLoadingUI: false,
  });

  return formattingToFactoringAttachmentVOModel(response);
}

export async function requestDealerMultipleRequestLoanDetail(
  multipleRequestLoansId: number,
): Promise<MultipleLoanRequestDetailVOModel> {
  const response = await http.get<MultipleLoanRequestDetailVO>({
    url: API_DE.MULTIPLE_REQUEST_LOANS.MULTIPLE_REQUEST_LOAN_DETAIL(multipleRequestLoansId),
  });

  return formattingToMultipleLoanRequestDetailVOModel(response);
}

export async function requestDealerMultipleRequestLoanArsList(
  multipleRequestLoansId: number,
  pageNumber?: number,
  rowCount?: number,
): Promise<Pageable<MultipleLoanRequestRelatedSuccessArVOModel[]>> {
  const response = await http.get<Pageable<MultipleLoanRequestRelatedSuccessArVO[]>>({
    url: API_DE.MULTIPLE_REQUEST_LOANS.MULTIPLE_REQUEST_LOAN_AR_LIST(multipleRequestLoansId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map((data: MultipleLoanRequestRelatedSuccessArVO) =>
      formattingToMultipleLoanRequestRelatedSuccessArVOModel(data),
    ),
  };
}

export async function requestDealerMultipleRequestLoanRelatedLoanList(
  multipleRequestLoansId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<MultipleLoanRequestRelatedLoanVOModel[]>> {
  const response = await http.get<Pageable<MultipleLoanRequestRelatedLoanVO[]>>({
    url: API_DE.MULTIPLE_REQUEST_LOANS.MULTIPLE_REQUEST_LOAN_RELATED_FINANCING_LIST(multipleRequestLoansId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map((data: MultipleLoanRequestRelatedLoanVO) =>
      formattingToMultipleLoanRequestRelatedLoanVOModel(data),
    ),
  };
}

export async function requestDealerMultipleRequestLoanDownloadAttachment(
  multipleRequestLoansId: number,
  data: DealerMultipleRequestLoanDownloadAttachmentRequest,
) {
  await http.download(API_DE.MULTIPLE_REQUEST_LOANS.DOWNLOAD_ATTACHMENT(multipleRequestLoansId), data);
}

export async function requestDealerMultipleRequestLoanCancel(
  multipleRequestLoansId: number,
  cancelReason: string,
): Promise<void> {
  await http.put({
    url: API_DE.MULTIPLE_REQUEST_LOANS.CANCEL(multipleRequestLoansId),
    data: {
      cancelReason,
    },
  });
}

export async function requestDealerMultipleRequestLoanApprove(
  multipleRequestLoansId: number,
  data: DealerMultipleRequestApproveRequest,
): Promise<void> {
  await http.put({
    url: API_DE.MULTIPLE_REQUEST_LOANS.APPROVE(multipleRequestLoansId),
    data,
    contentType: ContentType.MULTIPART,
  });
}

export async function requestDealerMultipleRequestLoanDownloadAttachmentForEsign(
  multipleRequestLoansId: number,
): Promise<FactoringAttachmentVOModel> {
  const response = await http.get<FactoringAttachmentVO>({
    url: API_DE.MULTIPLE_REQUEST_LOANS.DOWNLOAD_ATTACHMENT_FOR_ESIGN(multipleRequestLoansId),
  });

  return formattingToFactoringAttachmentVOModel(response);
}

export async function requestDealerMultipleLoanAdditionalDocumentAttachments(
  multipleRequestLoansId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<LoanAdditionalDocumentAttachmentVOModel[]>> {
  const response = await http.get<Pageable<LoanAdditionalDocumentAttachmentVO[]>>({
    url: API_DE.MULTIPLE_REQUEST_LOANS.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS(multipleRequestLoansId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const additionalDocumentsList: LoanAdditionalDocumentAttachmentVOModel[] = response.content.map(data =>
    formattingToLoanAdditionalDocumentAttachmentVOModel(data),
  );

  return {
    ...response,
    content: additionalDocumentsList,
  };
}
