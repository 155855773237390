import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import './GuideMessage.scss';

export enum MessageType {
  BASIC = 'basic-status',
  ALERT = 'reject-status',
}

export interface GuideMessagePropsType {
  message: (string | JSX.Element | boolean)[];
  children?: {
    link?: JSX.Element | (JSX.Element | null | undefined)[] | null | undefined;
    button?: JSX.Element | (JSX.Element | null | undefined)[] | null | undefined;
  };
  messageType?: MessageType;
  reasonTitle?: string;
  reason?: string;
  className?: string;
  isImportContentArea?: boolean;
  useContentAreaClassName?: boolean;
}

const GuideMessage = ({
  message,
  children,
  messageType = MessageType.BASIC,
  reasonTitle,
  reason,
  className = '',
  isImportContentArea = false,
  useContentAreaClassName = true,
}: GuideMessagePropsType) => {
  return (
    <div
      className={clsx('guide-message-container', {
        [className]: className,
        'w-100': isImportContentArea,
        'content-area': useContentAreaClassName,
      })}
    >
      <div className={clsx('guide-message', messageType)}>
        <div className="d-flex flex-grow-1">
          <FontAwesomeIcon icon={messageType === MessageType.ALERT ? faExclamationTriangle : faInfoCircle} />
          <div
            className={clsx('guide-message-information', {
              'w-90': children?.button,
            })}
          >
            <ul>
              {message
                .filter(item => item)
                .map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
            </ul>
            {children?.link}
          </div>
        </div>
        {children?.button && <div className="guide-message-btn">{children?.button}</div>}
      </div>
      {reasonTitle && (
        <div className="guide-message-reason">
          <div className="reason-title">{reasonTitle}</div>
          <div className="reason">{reason}</div>
        </div>
      )}
    </div>
  );
};

export default GuideMessage;
