import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FactoringNoticeDetailVOModel } from 'models/vo/FactoringNoticeDetailVO';
import type { FactoringNoticeReceiverVOModel } from 'models/vo/FactoringNoticeReceiverVO';
import type { FactoringNoticeRelatedSuccessArVOModel } from 'models/vo/FactoringNoticeRelatedSuccessArVO';
import {
  requestFinancierFactoringNoticeDetail,
  requestFinancierFactoringNoticeReceiverList,
  requestFinancierFactoringNoticeRelatedArsList,
  requestFinancierFactoringNoticeResend,
} from 'utils/http/api/financier/factoring-notices';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import { showLoadingUI, unShowLoadingUI } from '../../../../../utils/loadingUIManager/loadingUIManager';

const useFinancierDeptSellingNoticeDetailController = () => {
  const { factoringNoticeId } = useParams<any>();
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation();

  const [deptSellingDetail, setDeptSellingDetail] = useState<FactoringNoticeDetailVOModel>();
  const [recipientList, setRecipientList] = useState<Pageable<FactoringNoticeReceiverVOModel[]>>();
  const [relatedArList, setRelatedArList] = useState<Pageable<FactoringNoticeRelatedSuccessArVOModel[]>>();

  const { pageable: recipientListPageable, setPageable: setRecipientListPageable } = usePageable();
  const { pageable: relatedArListPageable, setPageable: setRelatedArListPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [fetchDeptSellingNoticeDetailInfo, fetchDeptSellingNoticeReceiptList, fetchDeptSellingNoticeRelatedArList] =
        await Promise.all([
          requestFinancierFactoringNoticeDetail(factoringNoticeId),
          requestFinancierFactoringNoticeReceiverList(
            factoringNoticeId,
            recipientListPageable.currentPage,
            recipientListPageable.sizePerPage,
          ),
          requestFinancierFactoringNoticeRelatedArsList(
            factoringNoticeId,
            relatedArListPageable.currentPage,
            relatedArListPageable.sizePerPage,
          ),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setDeptSellingDetail(fetchDeptSellingNoticeDetailInfo);
        setRecipientList(fetchDeptSellingNoticeReceiptList);
        setRelatedArList(fetchDeptSellingNoticeRelatedArList);

        setRecipientListPageable(fetchDeptSellingNoticeReceiptList);
        setRelatedArListPageable(fetchDeptSellingNoticeRelatedArList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginateReceiptList = async (pageNumber: number, rowCount: number) => {
    const response = await requestFinancierFactoringNoticeReceiverList(factoringNoticeId, pageNumber, rowCount);
    setRecipientList(response);
    setRecipientListPageable(response);
  };

  const paginateArList = async (pageNumber: number, rowCount: number) => {
    const response = await requestFinancierFactoringNoticeRelatedArsList(factoringNoticeId, pageNumber, rowCount);
    setRelatedArList(response);
    setRelatedArListPageable(response);
  };

  const fetchReceiptList = async () => {
    try {
      const fetchDeptSellingNoticeReceiptList = await requestFinancierFactoringNoticeReceiverList(
        factoringNoticeId,
        recipientListPageable.currentPage,
        recipientListPageable.sizePerPage,
      );

      setRecipientList(fetchDeptSellingNoticeReceiptList);
      setRecipientListPageable(fetchDeptSellingNoticeReceiptList);
    } catch (error) {
      modal.show(error);
    }
  };

  const handleClickResendButton = async (
    factoringNoticeReceiverId: number,
    receiverInfo: FactoringNoticeReceiverVOModel,
  ) => {
    modal.show(
      <>
        <h6>{t('text:Resend_the_email_to_the_following_address')}</h6>
        <div className="mt-2">
          <li className="px-3 py-1">
            <span>• {t('text:Email')}</span>: <span>{receiverInfo.currentUserEmailAddress}</span>
          </li>
          <li className="px-3 py-1">
            <span>• {t('text:Authority')}</span>: <span>{t(`code:authority-type.${receiverInfo.authorityType}`)}</span>
          </li>
        </div>
      </>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Resend'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          try {
            showLoadingUI();
            await requestFinancierFactoringNoticeResend(factoringNoticeId, factoringNoticeReceiverId);

            setTimeout(async () => {
              unShowLoadingUI();
              await fetchReceiptList();
              modal.show(<h6>{t('text:The_mail_has_been_successfully_resent')}</h6>, {
                modalType: ModalType.ALERT,
                closeBtnText: t('text:OK'),
              });
            }, 2000);
          } catch (e) {
            unShowLoadingUI();
            modal.show(e);
          }
        },
      },
    );
  };

  const handleClickResendAllButton = async () => {
    modal.show(<h6>{t('text:The_mail_will_be_resent_to_all_users_to_receive_the_mail')}</h6>, {
      modalType: ModalType.CONFIRM,
      title: t('text:Resend_all'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async () => {
        try {
          showLoadingUI();
          await requestFinancierFactoringNoticeResend(factoringNoticeId);

          setTimeout(async () => {
            unShowLoadingUI();
            await fetchReceiptList();
            modal.show(<h6>{t('text:The_mail_has_been_successfully_resent')}</h6>, {
              modalType: ModalType.ALERT,
              closeBtnText: t('text:OK'),
            });
          }, 2000);
        } catch (e) {
          unShowLoadingUI();
          modal.show(e);
        }
      },
    });
  };

  return {
    state: {
      deptSellingDetail,
      recipientList,
      relatedArList,
    },
    recipientListPageable,
    relatedArListPageable,
    paginateReceiptList,
    paginateArList,
    handleClickResendButton,
    handleClickResendAllButton,
  };
};

export default useFinancierDeptSellingNoticeDetailController;
