import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormRadioWrap, FormSelect } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { FINANCIER_CLIENT_TYPE, OTP_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import SearchAnchorModal from 'pages/financier/manage-anchor/company/modals/SearchAnchorModal';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierAuthSetting } from 'utils/http/api/financier/client-auth-setting';
import { requestFinancierClientRegister } from 'utils/http/api/financier/financier-clients';
import type { FinancierClientRegisterRequest } from 'utils/http/api/financier/financier-clients/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getCollateralType } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import type { ActiveStepType } from '../../FinancierManageAnchorRegister';

const financierClientType = FINANCIER_CLIENT_TYPE.ANCHOR;

type RegisterAnchorPropsType = {
  showCancelModal: () => void;
  isInterface: boolean;
  divisionRegistrable: boolean;
  activeStep: ActiveStepType;
  setActiveStep: Dispatch<SetStateAction<ActiveStepType>>;
  setFinancierClientId: Dispatch<SetStateAction<number>>;
};

const RegisterAnchor = ({
  showCancelModal,
  isInterface,
  divisionRegistrable,
  activeStep,
  setActiveStep,
  setFinancierClientId,
}: RegisterAnchorPropsType) => {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const history = useHistory();
  const isVendorFinance = getCollateralType() === SUPPORTED_COLLATERAL_TYPE.AR;

  const [interfaceAnchorData, setInterfaceAnchorData] = useState<FinancierClientRegisterRequest>(
    {} as FinancierClientRegisterRequest,
  );

  const [isOTPUsed, setIsOTPUsed] = useState(false);

  useEffect(() => {
    (async () => {
      const { otpType } = await requestFinancierAuthSetting();
      setIsOTPUsed(otpType !== OTP_TYPE.NONE);
    })();
  }, []);

  const { reset, getValues, register, errors, setError, clearErrors } = useForm<FinancierClientRegisterRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const isEditableForm = activeStep === 'anchor';

  const isDisabledInput = (fieldName: keyof FinancierClientRegisterRequest) => {
    if (activeStep === 'division') return true;

    if (isInterface) {
      if (isEmpty(interfaceAnchorData)) return true;

      return !!interfaceAnchorData[fieldName];
    } else {
      return false;
    }
  };

  const showSearchAnchorModal = (event: any) => {
    event.preventDefault();

    const initializeAnchorData = () => {
      reset(isEmpty(interfaceAnchorData) ? {} : interfaceAnchorData);
    };

    const setAnchorData = () => {
      const data = getValues();

      if (data.financierClientCode !== '' || data.financierClientTaxCode !== '' || data.financierClientName !== '') {
        setInterfaceAnchorData(data);
      } else if (interfaceAnchorData) {
        initializeAnchorData();
      }
    };

    const setAnchorInfoFromModal = (data: FinancierClientRegisterRequest) => {
      reset(data);
    };

    modal.show(<SearchAnchorModal financierClientDataHandler={setAnchorInfoFromModal} isOtpUsed={isOTPUsed} />, {
      title: t('text:Search_Anchor'),
      modalType: ModalType.CONFIRM,
      modalSize: ModalSize.XL,
      closeBtnText: t(`text:Close`),
      confirmBtnText: t(`text:Apply`),
      closeBtnCb: () => initializeAnchorData(),
      confirmBtnCb: () => setAnchorData(),
    });
  };

  const showRegistrationRequestModal = (e: any) => {
    e.preventDefault();

    modal.show(<div>{t(`text:Would_you_like_to_register_anchor_information?`)}</div>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t(`text:Cancel`),
      confirmBtnText: t(`text:Confirm`),
      closeBtnCb: () => {},
      confirmBtnCb: () => requestAnchorRegistration(),
    });
  };

  const showDivisionRegistrationConfirmationModal = (response: FinancierClientVOModel) => {
    modal.show(
      <>
        <h6>{t('text:The_anchor_information_has_been_registered_successfully')}</h6>
        <h6>
          {t(
            'text:To_complete_the_anchor_registration_without_adding_company_divisions,_click_on_the_“Complete_Registration”_button',
          )}
        </h6>
      </>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Add_Division'),
        confirmBtnText: t(`text:Complete_Registration`),
        closeBtnCb: () => {
          reset({
            ...response,
            autoSignUpForAnchorAdminOfVendorFinance: String(response.autoSignUpForAnchorAdminOfVendorFinance),
          });

          setFinancierClientId(response.financierClientId);
          setActiveStep('division');
        },
        confirmBtnCb: () => history.push(ROUTES_FI.MANAGE_ANCHOR.COMPANY_LIST),
      },
    );
  };

  const showAnchorRegistrationSuccessModal = () => {
    modal.show(<h6>{t('text:The_anchor_information_has_been_registered_successfully')}</h6>, {
      modalType: ModalType.ALERT,
      closeBtnText: t('text:OK'),
      closeBtnCb: () => history.push(ROUTES_FI.MANAGE_ANCHOR.COMPANY_LIST),
    });
  };

  const requestAnchorRegistration = async () => {
    const newFinancierRegistrationData = isInterface ? { ...interfaceAnchorData, ...getValues() } : getValues();

    if (newFinancierRegistrationData) {
      try {
        requestDTOParser(newFinancierRegistrationData);

        const response = await requestFinancierClientRegister(newFinancierRegistrationData);
        divisionRegistrable
          ? showDivisionRegistrationConfirmationModal(response)
          : showAnchorRegistrationSuccessModal();
      } catch (e) {
        modal.show(e);
        formErrorHandler<FinancierClientRegisterRequest>(e, setError, clearErrors);
      }
    } else {
      modal.show(
        <h6>
          {t(`text:There_is_missing_request_filed(s)`)}.
          <br />
          {t(`text:Please_fill_in_all_required_field(s)`)}.
        </h6>,
        {
          confirmBtnText: t(`text:OK`),
        },
      );
    }
  };

  return (
    <form>
      {!divisionRegistrable && <SectionTitle title={t('text:Anchor_Information')} />}
      <FormBorder editable={isEditableForm}>
        <FormContents>
          {isInterface && activeStep === 'anchor' && (
            <div className="row">
              <div className="col-12">
                <Button size={ButtonSizeEnum.LG} onClick={showSearchAnchorModal}>
                  {t('text:Search_Anchor')}
                </Button>
              </div>
            </div>
          )}
          <div className="row">
            <FormInput
              label={t('text:Client_Code')}
              name="financierClientCode"
              ref={register}
              disabled={isDisabledInput('financierClientCode')}
              requiredOptions={{ required: true }}
              error={errors.financierClientCode}
            />
            <FormInput
              label={t('text:Anchor_Name')}
              name="financierClientName"
              ref={register}
              disabled={isDisabledInput('financierClientName')}
              requiredOptions={{ required: true }}
              error={errors.financierClientName}
            />
          </div>
          <div className="row">
            <FormInput
              col={3}
              label={t('text:Tax_Code')}
              name="financierClientTaxCode"
              ref={register}
              disabled={isDisabledInput('financierClientTaxCode')}
              requiredOptions={{ required: true }}
              error={errors.financierClientTaxCode}
            />
            <FormInput
              col={3}
              label={t('text:Company_Registration_Number')}
              name="financierClientBusinessCode"
              ref={register}
              disabled={isDisabledInput('financierClientBusinessCode')}
              error={errors.financierClientBusinessCode}
            />
            <FormInput
              col={3}
              label={t('text:Telephone')}
              name="financierClientTelephone"
              ref={register}
              disabled={isDisabledInput('financierClientTelephone')}
              error={errors.financierClientTelephone}
            />
            <FormInput
              col={3}
              label={t('text:Fax')}
              name="financierClientFax"
              ref={register}
              disabled={isDisabledInput('financierClientFax')}
              error={errors.financierClientFax}
            />
          </div>
          <div className="row">
            <FormInput
              col={isOTPUsed ? 3 : 6}
              label={t('text:Legal_Representative_Name')}
              name="representativeName"
              ref={register}
              disabled={isDisabledInput('representativeName')}
              error={errors.representativeName}
            />
            <FormInput
              col={3}
              label={t('text:Legal_Representative_Title')}
              name="representativePosition"
              ref={register}
              disabled={isDisabledInput('representativePosition')}
              error={errors.representativePosition}
            />
            <FormInput
              col={3}
              label={t('text:Legal_Representative_Email')}
              name="representativeEmail"
              ref={register}
              disabled={isDisabledInput('representativeEmail')}
              error={errors.representativeEmail}
            />
            {isOTPUsed && (
              <FormSelect
                col={3}
                label={t('text:OTP_Verification')}
                selectOptions={getSelectOptions('OTP_VERIFICATION')}
                name="otpBypassed"
                ref={register}
                error={errors.otpBypassed}
                required={true}
              />
            )}
          </div>
          <div className="row">
            <FormInput
              col={12}
              label={t('text:Registered_Office_Address')}
              name="financierClientAddress"
              ref={register}
              disabled={isDisabledInput('financierClientAddress')}
              error={errors.financierClientAddress}
            />
            <input name="financierClientType" type="hidden" defaultValue={financierClientType} ref={register} />
          </div>
          {isVendorFinance && !isInterface && (
            <div className="row">
              <FormRadioWrap label={t('text:VFS_without_Anchor_User_Invitation')}>
                <FormRadio
                  label={t('text:Yes')}
                  name="autoSignUpForAnchorAdminOfVendorFinance"
                  ref={register}
                  value="true"
                />
                <FormRadio
                  label={t('text:No')}
                  name="autoSignUpForAnchorAdminOfVendorFinance"
                  ref={register}
                  value="false"
                />
              </FormRadioWrap>
            </div>
          )}
        </FormContents>
      </FormBorder>
      {activeStep === 'anchor' && (
        <div className="flex-end mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={showCancelModal}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={showRegistrationRequestModal} className="ms-2">
            {t('text:Register')}
          </Button>
        </div>
      )}
    </form>
  );
};

export default RegisterAnchor;
