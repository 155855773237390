import type Pageable from 'models/Pageable';
import type { InvoicePhaseVO, InvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import { formattingToInvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

export async function requestAnchorInvoicePhasesList(
  pageNumber: number,
  rowCount: number,
  invoiceSummaryId?: number,
): Promise<Pageable<InvoicePhaseVOModel[]>> {
  const response = await http.get<Pageable<InvoicePhaseVO[]>>({
    url: API_AC.INVOICE_APPROVAL_PHASES.INVOICE_PHASE_LIST,
    data: {
      pageNumber,
      rowCount,
      invoiceSummaryId,
    },
  });
  const invoicePhasesList: InvoicePhaseVOModel[] = response.content.map((data: InvoicePhaseVO) =>
    formattingToInvoicePhaseVOModel(data),
  );
  const invoicePhasesPage: Pageable<InvoicePhaseVOModel[]> = {
    ...response,
    content: invoicePhasesList,
  };

  return invoicePhasesPage;
}
