import type Pageable from 'models/Pageable';
import type {
  StatisticsOfAnchorAgreementVO,
  StatisticsOfAnchorAgreementVOModel,
} from 'models/vo/StatisticsOfAnchorAgreementVO';
import { formattingToStatisticsOfAnchorAgreementModel } from 'models/vo/StatisticsOfAnchorAgreementVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';
import type { FinancierStatisticsOfAnchorAgreementListRequest } from 'utils/http/api/financier/statistics-of-anchor-agreements/request';

export async function requestFiAnchorStatisticsOfAnchorAgreementsList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierStatisticsOfAnchorAgreementListRequest,
): Promise<Pageable<StatisticsOfAnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<StatisticsOfAnchorAgreementVO[]>>({
    url: API_FI.STATISTICS_OF_ANCHOR_AGREEMENTS.STATISTICS_OF_ANCHOR_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const statisticsOfAnchorAgreementList: StatisticsOfAnchorAgreementVOModel[] = response.content.map(
    (data: StatisticsOfAnchorAgreementVO) => formattingToStatisticsOfAnchorAgreementModel(data),
  );
  const statisticsOfAnchorAgreementPage: Pageable<StatisticsOfAnchorAgreementVOModel[]> = {
    ...response,
    content: statisticsOfAnchorAgreementList,
  };

  return statisticsOfAnchorAgreementPage;
}
