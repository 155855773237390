import { COLLATERAL_TYPE, CURRENCY_TYPE, stringToEnum } from 'enums';

export interface DashboardFinancierPerformanceVO {
  dashboardFinancierPerformanceId: number;
  collateralType: string;
  currencyType: string;
  baseDate: string;
  anchorAgreementCount: number;
  partnerAgreementCount: number;
  disbursedLoanAmount: number;
  disbursedLoanCount: number;
  overdueLoanAmount: number;
  overdueLoanCount: number;
  accumDisbursedLoanAmount: number;
  accumDisbursedLoanCount: number;
  relatedFinancierId: number;
  relatedFinancierName: string;
  relatedBranchId: number;
  relatedBranchName: string;
}

export interface DashboardFinancierPerformanceVOModel extends DashboardFinancierPerformanceVO {
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
}

export function formattingToDashboardFinancierPerformanceVOModel(
  data: DashboardFinancierPerformanceVO,
): DashboardFinancierPerformanceVOModel {
  return {
    ...data,
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
