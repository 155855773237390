import { useTranslation } from 'react-i18next';

import PPSubTitle from 'components/potential-partner/PPSubTitle/PPSubTitle';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';

import './PotentialPartnerApplicationStep1FinancierGuideMessage.scss';

import type { PotentialPartnerApplicationStep1FinancierGuideMessageType } from '../../../logics/types';

interface PotentialPartnerApplicationStep1FinancierGuideMessageProps {
  data: PotentialPartnerApplicationStep1FinancierGuideMessageType;
}

function PotentialPartnerApplicationStep1FinancierGuideMessage({
  data,
}: PotentialPartnerApplicationStep1FinancierGuideMessageProps) {
  const { t } = useTranslation();

  const DEFAULT_GUIDE_MESSAGE = (
    <>
      {t('text:Welcome_to_the_Fin2B_Supply_Chain_Finance_Platform!')} <br />
      {t('text:Follow_the_steps_below_to_submit_your_Vendor_Finance_application_to_the_financier')} <br />
      {t('text:First_check_that_your_information_registered_by_the_Buyer_is_correct')} <br />
      {t('text:On_the_next_page_attach_the_required_documents_and_select_your_preferred_transaction_branch')} <br />
      {t('text:Finally_review_your_application_before_submitting_it_to_the_financier')} <br />
      {t('text:After_the_submission_is_completed_your_application_will_be_reviewed_by_the_financier')}{' '}
      {t('text:You_may_monitor_your_application_progress_on_this_page')}
    </>
  );

  return (
    <>
      <PPSubTitle title={`${t('text:Get_started_with')} ${data.financierName}.`} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="pp-application-step1-guide__msg">{data.message ? data.message : DEFAULT_GUIDE_MESSAGE}</div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default PotentialPartnerApplicationStep1FinancierGuideMessage;
