import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormBorder, FormSelect } from 'components/stateless/CommonForm';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import getSelectOptions from 'constants/selectOptions';
import useMounted from 'hooks/useMounted';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { requestFinancierClientDetailByClientId } from 'utils/http/api/financier/financier-clients';
import type { FinancierClientUpdateRequest } from 'utils/http/api/financier/financier-clients/requests';
import useModal from 'utils/modal/useModal';

interface UpdateDealerModalPropsType {
  financierClientData: FinancierClientVOModel | undefined;
  propFunction: Function;
  isOtpUsed: boolean;
  modalId: number;
}

function UpdateDealerModal({ financierClientData, propFunction, isOtpUsed, modalId }: UpdateDealerModalPropsType) {
  const mounted = useMounted();
  const modal = useModal();
  const { register, reset, watch } = useForm<FinancierClientUpdateRequest>();
  const { t } = useTranslation(['format']);

  useEffect(() => {
    reset({
      financierClientName: financierClientData?.financierClientName,
      financierClientBusinessCode: financierClientData?.financierClientBusinessCode,
      financierClientTaxCode: financierClientData?.financierClientTaxCode,
      financierClientAddress: financierClientData?.financierClientAddress,
      financierClientTelephone: financierClientData?.financierClientTelephone,
      financierClientFax: financierClientData?.financierClientFax,
      representativeName: financierClientData?.representativeName,
      representativeEmail: financierClientData?.representativeEmail,
      representativePosition: financierClientData?.representativePosition,
      financierClientStatus: financierClientData?.financierClientStatus,
      otpBypassed: financierClientData?.otpBypassed,
    });
    if (mounted) fetchData();
  }, [mounted]);

  const fetchData = async () => {
    if (financierClientData) {
      try {
        const financierClientUpdatedData = await requestFinancierClientDetailByClientId(
          financierClientData.financierClientId,
        );

        reset({
          financierClientName: financierClientUpdatedData?.financierClientName,
          financierClientBusinessCode: financierClientUpdatedData?.financierClientBusinessCode,
          financierClientTaxCode: financierClientUpdatedData?.financierClientTaxCode,
          financierClientAddress: financierClientUpdatedData?.financierClientAddress,
          financierClientTelephone: financierClientUpdatedData?.financierClientTelephone,
          financierClientFax: financierClientUpdatedData?.financierClientFax,
          representativeName: financierClientUpdatedData?.representativeName,
          representativeEmail: financierClientUpdatedData?.representativeEmail,
          otpBypassed: financierClientUpdatedData?.otpBypassed,
          representativePosition: financierClientUpdatedData?.representativePosition,
        });

        propFunction({
          financierClientCode: financierClientUpdatedData?.financierClientCode,
          financierClientName: financierClientUpdatedData?.financierClientName,
          financierClientBusinessCode: financierClientUpdatedData?.financierClientBusinessCode,
          financierClientTaxCode: financierClientUpdatedData?.financierClientTaxCode,
          financierClientAddress: financierClientUpdatedData?.financierClientAddress,
          financierClientTelephone: financierClientUpdatedData?.financierClientTelephone,
          financierClientFax: financierClientUpdatedData?.financierClientFax,
          representativeName: financierClientUpdatedData?.representativeName,
          representativeEmail: financierClientUpdatedData?.representativeEmail,
          otpBypassed: financierClientUpdatedData?.otpBypassed,
          representativePosition: financierClientUpdatedData?.representativePosition,
          financierClientStatus: financierClientUpdatedData?.financierClientStatus || null,
        });
      } catch (e) {
        modal.close(modalId);
        modal.show(e);
      }
    }
  };

  const {
    financierClientName,
    financierClientBusinessCode,
    financierClientTaxCode,
    financierClientAddress,
    financierClientTelephone,
    financierClientFax,
    representativeName,
    representativeEmail,
    otpBypassed,
    representativePosition,
  } = watch();

  return (
    <>
      <div className="changed-data-form d-flex justify-content-end mb-2">
        <span className="changed-data" />
        <p>{t(`text:Changed_data`)}</p>
      </div>
      <FormBorder>
        <FormContents>
          <div className="row">
            <div className="col-3">
              <label className="information-form__label">{t(`text:Client_Code`)}</label>
              <div className="information-form__input">{financierClientData?.financierClientCode}</div>
            </div>
            <div className="col-6">
              <label className="information-form__label">{t('text:Partner_Name')}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.financierClientName !== financierClientName && 'updated__bg__input'
                }`}
                type="text"
                name="financierClientName"
                disabled={true}
                ref={register}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="information-form__label">{t(`text:Tax_Code`)}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.financierClientTaxCode !== financierClientTaxCode && 'updated__bg__input'
                }`}
                type="text"
                name="financierClientTaxCode"
                disabled={true}
                ref={register}
              />
            </div>
            <div className="col-3">
              <label className="information-form__label">{t(`text:Company_Registration_Number`)}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.financierClientBusinessCode !== financierClientBusinessCode &&
                  'updated__bg__input'
                }`}
                type="text"
                name="financierClientBusinessCode"
                disabled={true}
                ref={register}
              />
            </div>
            <div className="col-3">
              <label className="information-form__label">{t(`text:Telephone`)}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.financierClientTelephone !== financierClientTelephone && 'updated__bg__input'
                }`}
                type="text"
                name="financierClientTelephone"
                disabled={true}
                ref={register}
              />
            </div>
            <div className="col-3">
              <label className="information-form__label">{t(`text:Fax`)}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.financierClientFax !== financierClientFax && 'updated__bg__input'
                }`}
                type="text"
                name="financierClientFax"
                disabled={true}
                ref={register}
              />
            </div>
          </div>
          <div className="row">
            <div className={isOtpUsed ? 'col-3' : 'col-6'}>
              <label className="information-form__label">{t(`text:Legal_Representative_Name`)}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.representativeName !== representativeName && 'updated__bg__input'
                }`}
                type="text"
                name="representativeName"
                disabled={true}
                ref={register}
              />
            </div>
            <div className="col-3">
              <label className="information-form__label">{t(`text:Legal_Representative_Title`)}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.representativePosition !== representativePosition && 'updated__bg__input'
                }`}
                type="text"
                name="representativePosition"
                disabled={true}
                ref={register}
              />
            </div>
            <div className="col-3">
              <label className="information-form__label">{t(`text:Legal_Representative_Email`)}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.representativeEmail !== representativeEmail && 'updated__bg__input'
                }`}
                type="text"
                name="representativeEmail"
                disabled={true}
                ref={register}
              />
            </div>
            {isOtpUsed && (
              <FormSelect
                className={`information-form__input ${
                  financierClientData?.otpBypassed !== otpBypassed && 'updated__bg__input'
                }`}
                col={3}
                label={t('text:OTP_Verification')}
                selectOptions={getSelectOptions('OTP_VERIFICATION')}
                name="otpBypassed"
                ref={register}
                disabled={true}
              />
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <label className="information-form__label">{t(`text:Registered_Office`)}</label>
              <input
                className={`information-form__input ${
                  financierClientData?.financierClientAddress !== financierClientAddress && 'updated__bg__input'
                }`}
                type="text"
                name="financierClientAddress"
                disabled={true}
                ref={register}
              />
            </div>
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default UpdateDealerModal;
