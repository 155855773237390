import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';

interface PropsType {
  children?: JSX.Element | (JSX.Element | null | boolean | 0)[] | null | undefined | boolean | 0;
  backGroundType?: BackGroundType;
}

export const FormContents = ({ children, backGroundType = BackGroundType.Gray }: PropsType) => {
  return <div className={'information-form__content ' + backGroundType}>{children}</div>;
};
