import type { FieldErrors } from 'react-hook-form/dist/types/errors';

import { entries, values } from 'lodash-es';

import { getFieldNames as getDealerRegisterFieldNames } from 'pages/anchor/manage-dealer/registration/step2/AnchorDealerRegistrationStep2Page';
import { getFieldNames as getPartnerRegisterFieldNames } from 'pages/anchor/manage-partner/registration/step2/AnchorPartnerRegistrationStep2';
import { getFieldNames as getPurposeOfFinancingFieldNames } from 'pages/dealer/manage-financing/application/step2/ar/sections/purpose-of-financing';
import type { NewObject } from 'types';

import type { TFunction } from 'i18next';

type ValidationRule = ({
  errorRow,
  t,
  accountType,
  minDate,
  maxDate,
}: {
  errorRow: FieldErrors<NewObject>;
  t: TFunction;
  accountType?: 'AR' | 'AP';
  minDate?: Date;
  maxDate?: Date;
}) => {
  condition: boolean;
  message: string;
  options?: {
    fields?: string;
    field?: string;
    date?: string;
  };
}[];

const partnerRegister: ValidationRule = ({ errorRow, t }) => {
  const fieldNames = getPartnerRegisterFieldNames(t);

  const lengthErrorFields = entries(errorRow)
    .filter(([, value]) => value.type?.includes('Length'))
    .map(([key]) => fieldNames[key as keyof typeof fieldNames].name);

  return [
    {
      condition: errorRow.partnerTaxCode?.type === 'validate',
      message: t('text:Fail_Duplicate_Partner_Tax_Code'),
    },
    {
      condition: values(errorRow).some(field => field.type === 'required'),
      message: t('text:Fail_Missing_item(s)'),
    },
    {
      condition: !!lengthErrorFields.length,
      message: t('text:Fail_The_length_of_field_is_inappropriate', { fields: lengthErrorFields.join(', ') }),
    },
    {
      condition: errorRow.representativeEmail?.type === 'pattern',
      message: t('text:Fail_Provide_an_email_in_this_format_example@email_com'),
    },
    {
      condition: errorRow.partnerTaxCode?.type === 'pattern',
      message: t('text:Fail_Field_must_consist_only_of_numbers_and_alphabets', {
        field: fieldNames.partnerTaxCode.name,
      }),
    },
    {
      condition: errorRow.telephone?.type === 'pattern',
      message: t('text:Fail_Field_must_consist_only_of_numbers', { field: fieldNames.telephone.name }),
    },
  ];
};

const loanRegister: ValidationRule = ({ errorRow, t }) => {
  const fieldNames = getPurposeOfFinancingFieldNames(t);

  const lengthErrorFields = entries(errorRow)
    .filter(([, value]) => value.type?.includes('Length'))
    .map(([key]) => fieldNames[key as keyof typeof fieldNames].name);

  return [
    {
      condition: values(errorRow).some(field => field.type === 'required'),
      message: t('text:Fail_Missing_item(s)'),
    },
    {
      condition: !!lengthErrorFields.length,
      message: t('text:Fail_The_length_of_field_is_inappropriate', { fields: lengthErrorFields.join(', ') }),
    },
  ];
};

const arList: ValidationRule = ({ errorRow, t, accountType = 'AR', minDate, maxDate }) => {
  return [
    {
      condition: errorRow.partnerTaxCode?.type === 'pattern',
      message: t('text:Fail_The_tax_code_must_consist_only_of_letters_and_numbers'),
    },
    {
      condition: errorRow.partnerTaxCode?.type === 'validate',
      message: t('text:Fail_No_partner_exists_that_matches_the_tax_code_you_entered'),
    },
    {
      condition: errorRow.partnerName?.type === 'required',
      message: t('text:Fail_No_partner_exists_that_matches_the_tax_code_you_entered'),
    },
    {
      condition: errorRow.arNumber?.type === 'maxLength',
      message:
        accountType === 'AP'
          ? t('text:Fail_The_AP_Number_must_be_between_1_and_20_characters')
          : t('text:Fail_The_AR_Number_must_be_between_1_and_20_characters'),
    },
    {
      condition: errorRow.arNumber?.type === 'validate',
      message:
        accountType === 'AP'
          ? t('text:Fail_Duplicate_AP_Number')
          : t('text:Fail_The_list_contains_duplicate_AR_Numbers'),
    },
    {
      condition: errorRow.arAmount?.type === 'min',
      message: accountType === 'AP' ? t('text:Fail_AP_Amount_must_exceed_0') : t('text:Fail_AR_Amount_must_exceed_0'),
    },
    {
      condition: accountType === 'AP' && errorRow.arAmount?.type === 'validate',
      message: t(errorRow.arAmount?.message),
    },
    {
      condition: errorRow.arAmount?.message === 'text:Fail_The_AR_Amount_must_consist_only_of_numbers',
      message: t('text:Fail_The_AR_Amount_must_consist_only_of_numbers'),
    },
    {
      condition: errorRow.arAmount?.message === 'text:Fail_The_AR_Amount_is_too_large',
      message: t('text:Fail_The_AR_Amount_is_too_large'),
    },
    {
      condition: errorRow.arAmount?.message === 'text:Fail_The_AR_Amount_can_have_up_to_2_decimals',
      message: t('text:Fail_The_AR_Amount_can_have_up_to_2_decimals'),
    },
    {
      condition: errorRow.arAmount?.message === 'text:Fail_The_AR_Amount_cannot_have_decimals',
      message: t('text:Fail_The_AR_Amount_cannot_have_decimals'),
    },
    {
      condition: errorRow.settlementDate?.message === 'text:Fail_The_settlement_date_must_not_be_before_DATE',
      message: t('text:Fail_The_settlement_date_must_not_be_before_DATE', {
        date: t('format:date', { value: minDate, key: 'date' }),
      }),
    },
    {
      condition: errorRow.settlementDate?.message === 'text:Fail_The_settlement_date_must_not_exceed_DATE',
      message: t('text:Fail_The_settlement_date_must_not_exceed_DATE', {
        date: t('format:date', { value: maxDate, key: 'date' }),
      }),
    },
    {
      condition: errorRow.settlementDate?.type === 'validate',
      message: t(errorRow.settlementDate?.message),
    },
    {
      condition: values(errorRow).some(field => field.type === 'required'),
      message: t('text:Fail_Missing_item(s)'),
    },
  ];
};

const invoiceList: ValidationRule = ({ errorRow, t }) => {
  return [
    {
      condition: errorRow.billOfExchangeNo?.type === 'validate',
      message: t('text:Fail_Invoices_issued_for_different_Partners_must_have_different_BOE_numbers'),
    },
    {
      condition: errorRow.invoiceNumber?.type === 'validate',
      message: t('text:Fail_Duplicate_Invoice_Number'),
    },
    {
      condition: errorRow.referenceNumber?.type === 'validate',
      message: t('text:Fail_Duplicate_Reference_Number'),
    },
    {
      condition: errorRow.settlementDate?.type === 'validate',
      message: t('text:Fail_Invoices_with_the_same_BOE_Number_must_have_the_same_payment_date'),
    },
    {
      condition: errorRow.invoiceAmount?.type === 'min',
      message: t('text:Fail_Invoice_Amount_must_exceed_0'),
    },
    {
      condition: errorRow.invoiceAmount?.message === 'text:Fail_The_Invoice_Amount_must_consist_only_of_numbers',
      message: t('text:Fail_The_Invoice_Amount_must_consist_only_of_numbers'),
    },
    {
      condition: errorRow.invoiceAmount?.message === 'text:Fail_The_Invoice_Amount_is_too_large',
      message: t('text:Fail_The_Invoice_Amount_is_too_large'),
    },
    {
      condition: errorRow.invoiceAmount?.message === 'text:Fail_The_Invoice_Amount_can_have_up_to_2_decimals',
      message: t('text:Fail_The_Invoice_Amount_can_have_up_to_2_decimals'),
    },
    {
      condition: errorRow.invoiceAmount?.message === 'text:Fail_The_Invoice_Amount_cannot_have_decimals',
      message: t('text:Fail_The_Invoice_Amount_cannot_have_decimals'),
    },
    {
      condition: values(errorRow).some(field => field.type === 'required'),
      message: t('text:Fail_Missing_item(s)'),
    },
  ];
};

const securedInventory: ValidationRule = ({ errorRow, t }) => {
  return [
    {
      condition:
        errorRow.unitPrice?.type === 'min' || errorRow.quantity?.type === 'min' || errorRow.itemAmount?.type === 'min',
      message: t('text:Fail_The_minimum_value_is_0'),
    },
    {
      condition: errorRow.unitPrice?.message === 'text:Fail_The_unit_price_must_consist_only_of_numbers',
      message: t('text:Fail_The_unit_price_must_consist_only_of_numbers'),
    },
    {
      condition: errorRow.unitPrice?.message === 'text:Fail_The_unit_price_exceeds_the_maximum_limit',
      message: t('text:Fail_The_unit_price_exceeds_the_maximum_limit'),
    },
    {
      condition: errorRow.unitPrice?.message === 'text:Fail_The_unit_price_can_have_up_to_4_decimals',
      message: t('text:Fail_The_unit_price_can_have_up_to_4_decimals'),
    },
    {
      condition: errorRow.unitPrice?.message === 'text:Fail_The_unit_price_cannot_have_decimals',
      message: t('text:Fail_The_unit_price_cannot_have_decimals'),
    },
    {
      condition: errorRow.quantity?.message === 'text:Fail_quantity_must_consist_only_of_numbers',
      message: t('text:Fail_The_quantity_must_consist_only_of_numbers'),
    },
    {
      condition: errorRow.quantity?.message === 'text:Fail_The_quantity_exceeds_the_maximum_limit',
      message: t('text:Fail_The_quantity_exceeds_the_maximum_limit'),
    },
    {
      condition: errorRow.quantity?.message === 'text:Fail_The_quantity_can_have_up_to_4_decimals',
      message: t('text:Fail_The_quantity_can_have_up_to_4_decimals'),
    },
    {
      condition: errorRow.quantity?.message === 'text:Fail_The_quantity_cannot_have_decimals',
      message: t('text:Fail_The_quantity_cannot_have_decimals'),
    },
    {
      condition: errorRow.itemAmount?.type === 'validate',
      message: t('text:Fail_The_item_amount_exceeds_the_maximum_limit'),
    },
    {
      condition: values(errorRow).some(field => field.type === 'required'),
      message: t('text:Fail_Missing_item(s)'),
    },
  ];
};

const dealerRegister: ValidationRule = ({ errorRow, t }) => {
  const fieldNames = getDealerRegisterFieldNames(t);

  const lengthErrorFields = entries(errorRow)
    .filter(([, value]) => value.type?.includes('Length'))
    .map(([key]) => fieldNames[key as keyof typeof fieldNames].name);

  return [
    {
      condition: errorRow.taxCode?.type === 'validate',
      message: t('text:Fail_Duplicate_Dealer_Tax_Code'),
    },
    {
      condition: values(errorRow).some(field => field.type === 'required'),
      message: t('text:Fail_Missing_item(s)'),
    },
    {
      condition: !!lengthErrorFields.length,
      message: t('text:Fail_The_length_of_field_is_inappropriate', { fields: lengthErrorFields.join(', ') }),
    },
    {
      condition: errorRow.representativeEmail?.type === 'pattern',
      message: t('text:Fail_Provide_an_email_in_this_format_example@email_com'),
    },
    {
      condition: errorRow.taxCode?.type === 'pattern',
      message: t('text:Fail_Field_must_consist_only_of_numbers_and_alphabets', {
        field: fieldNames.taxCode.name,
      }),
    },
    {
      condition: errorRow.telephone?.type === 'pattern',
      message: t('text:Fail_Field_must_consist_only_of_numbers', { field: fieldNames.telephone.name }),
    },
  ];
};

export default {
  loanRegister,
  partnerRegister,
  arList,
  invoiceList,
  securedInventory,
  dealerRegister,
};
