import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export enum CHECK_LOGIN_ID_STATUS {
  VALID = 'VALID',
  INVALID = 'INVALID',
  NOT_CHECKED = 'NOT_CHECKED',
  BLOCKED_PATTERN = 'BLOCKED_PATTERN',
}

type CreateFinancierStep2Value = {
  checkLoginIdStatus: CHECK_LOGIN_ID_STATUS;
};

type CreateFinancierStep2Actions = {
  updateCheckLoginIdStatus: (status: CHECK_LOGIN_ID_STATUS) => void;
};

const CreateFinancierStep2ValueContext = createContext<CreateFinancierStep2Value>({
  checkLoginIdStatus: CHECK_LOGIN_ID_STATUS.NOT_CHECKED,
});

const CreateFinancierStep2ActionsContext = createContext<CreateFinancierStep2Actions>({
  updateCheckLoginIdStatus: () => {},
});

const CreateFinancierStep2Provider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [checkLoginIdStatus, setCheckLoginIdStatus] = useState<CHECK_LOGIN_ID_STATUS>(
    CHECK_LOGIN_ID_STATUS.NOT_CHECKED,
  );

  const updateCheckLoginIdStatus = useCallback(status => {
    setCheckLoginIdStatus(status);
  }, []);

  const actions: CreateFinancierStep2Actions = useMemo(() => {
    return {
      updateCheckLoginIdStatus,
    };
  }, [updateCheckLoginIdStatus]);

  return (
    <CreateFinancierStep2ActionsContext.Provider value={actions}>
      <CreateFinancierStep2ValueContext.Provider
        value={{
          checkLoginIdStatus,
        }}
      >
        {children}
      </CreateFinancierStep2ValueContext.Provider>
    </CreateFinancierStep2ActionsContext.Provider>
  );
};

export default CreateFinancierStep2Provider;

export const useCreateFinancierStep2Value = () => useContext(CreateFinancierStep2ValueContext);

export const useCreateFinancierStep2Actions = () => useContext(CreateFinancierStep2ActionsContext);
