import type { ReactElement, ReactNode } from 'react';

import { clsx } from 'clsx';

import { passPropsToChildren } from 'utils/render';

import Row from './Row';

type ContentPropsType = {
  isInnerStyle?: boolean;
  hasSubRow?: boolean;
  children: ReactNode;
};

const Content = ({ isInnerStyle = false, hasSubRow = false, children }: ContentPropsType) => {
  return (
    <div className={clsx('section__content', { 'section__content--sub-row': hasSubRow })}>
      {passPropsToChildren(children, {
        isInnerStyle: (child: ReactElement) => (child.type === Row ? isInnerStyle : null),
      })}
    </div>
  );
};

export default Content;
