import useAnchorAgreementInfoViewModel from '../../models/useAnchorAgreementInfoViewModel';

const useAssociatedAnchorMasterAgreementController = () => {
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();

  return {
    anchorAgreementInfo,
  };
};

export default useAssociatedAnchorMasterAgreementController;
