import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash-es';

import ApInputBulkImportTable from 'components/ap/ApInputBulkImportTable';
import ApInputBulkTable from 'components/ap/ApInputBulkTable';
import ApAccordionList from 'components/ap/ApSummaryPhaseAccordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE, OTP_TYPE, WAITING_AR_STATUS } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import useProgressStatus from 'hooks/useProgressStatus';
import type Pageable from 'models/Pageable';
import type { ArPhaseVOModel } from 'models/vo/ArPhaseVO';
import type { ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { LastArPhaseVOModel } from 'models/vo/LastArPhaseVO';
import type { WaitingArVOModel } from 'models/vo/WaitingArVO';
import AnchorApRegisterValidationErrorModal from 'pages/anchor/register-ap/modals/AnchorApRegisterValidationErrorModal';
import AnchorAPRequestModal from 'pages/anchor/register-ap/modals/anchorAPRequestModal';
import type { InputType } from 'pages/anchor/register-ap/registration/step2/AnchorApRegisterStep2';
import type { BigNumber } from 'utils/bigNumber';
import { getSum } from 'utils/calculate';
import {
  convertToServerDateFormat,
  getAvailableSettlementDateRangeForRegistrationArOrAp,
  getDayTerm,
  verifySettlementDateForRegistrationArOrAp,
} from 'utils/date/date';
import { requestAnchorArPartnerNames } from 'utils/http/api/anchor/anchor-partners';
import { requestAnchorArPhaseList } from 'utils/http/api/anchor/ar-approval-phases';
import {
  requestAnchorApproveArPhase,
  requestAnchorArSummaryDetail,
  requestAnchorArSummaryLastPhase,
  requestAnchorRejectArRegistration,
} from 'utils/http/api/anchor/ar-summaries';
import type { AnchorArListElementType, AnchorArPhaseApproveRequest } from 'utils/http/api/anchor/ar-summaries/request';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestAnchorFinancierCalendar } from 'utils/http/api/anchor/financier-calendar';
import { requestAnchorWaitingArList } from 'utils/http/api/anchor/waiting-ars';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { initializeRefValue } from 'utils/initialize';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';
import { REG_ALL_CHARACTER_AND_NUMBER } from 'utils/validation/regExp';

const getConstant = () => {
  const history = useHistory();
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const { apSummaryId } = useParams() as any;

  const FILE_UPLOAD_MAX_ROW_LENGTH = 500;
  const BULK_IMPORT_MAX_ROW_LENGTH = 3000;
  const BULK_IMPORT: InputType = 'bulkImport';
  const FILE_UPLOAD: InputType = 'fileUpload';

  return {
    history,
    modal,
    t,
    mounted,
    apSummaryId,
    FILE_UPLOAD_MAX_ROW_LENGTH,
    BULK_IMPORT_MAX_ROW_LENGTH,
    BULK_IMPORT,
    FILE_UPLOAD,
  };
};

function AnchorApConfirmationDetail() {
  const {
    modal,
    t,
    mounted,
    apSummaryId,
    FILE_UPLOAD_MAX_ROW_LENGTH,
    BULK_IMPORT_MAX_ROW_LENGTH,
    BULK_IMPORT,
    FILE_UPLOAD,
  } = getConstant();

  const [locationState, errorHandlerOfLocationState] = useLocationState<{ financierEnterpriseId: number }>([
    'financierEnterpriseId',
  ]);
  const { financierEnterpriseId } = locationState;

  const [arSummaryData, setArSummaryData] = useState<ArSummaryVOModel>();
  const [arSummaryPhaseDataList, setArSummaryPhaseDataList] = useState<Pageable<ArPhaseVOModel[]>>();
  const [arSummaryLastPhaseData, setArSummaryLastPhaseData] = useState<LastArPhaseVOModel>();
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회
  const [editable, setEditable] = useState<boolean>(false); // form editable 여부

  // ar lists state - phase 별로
  const [searchedWaitingApprovalPhaseId, setSearchedWaitingApprovalPhaseId] = useState<number[]>([]);
  const [settlementDatePeriod, setSettlementDatePeriod] = useState({
    minimumDate: new Date(),
    maximumDate: new Date(),
  });

  const [numberOfPreviousPhaseInvalidAP, setNumberOfPreviousPhaseInvalidAP] = useState<number>(0); // 이전 단계에서 등록에 성공(유효)한 매출채권 개수
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [totalApAmount, setTotalApAmount] = useState<BigNumber>('0');
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();
  const [rows, setRows] = useState<string[]>(['']);
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [excelInfos, setExcelInfos] = useState<AnchorArListElementType[]>();
  const [holidays, setHolidays] = useState<string[]>(['']);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const isBulkImport =
    arSummaryLastPhaseData && arSummaryLastPhaseData.arList.content.length > FILE_UPLOAD_MAX_ROW_LENGTH;

  const apRegistrationUseForm = useForm<AnchorArPhaseApproveRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { getValues, reset, trigger, errors, register, clearErrors, setValue, control, setError, formState } =
    apRegistrationUseForm;

  const { renderProgressStatus } = useProgressStatus(arSummaryData, '', 'Ar');

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const updateTotalApAmount = useCallback(
    (excelInfoList?: AnchorArListElementType[]) => {
      let sum = '0';
      if (isBulkImport) {
        if (excelInfoList) {
          sum = getSum(excelInfoList, 'arAmount');
        }
      } else {
        sum = getSum(getValues().arList, 'arAmount');
      }
      setTotalApAmount(sum);
    },
    [getValues, isBulkImport],
  );

  useEffect(() => {
    updateTotalApAmount();
  }, [rows, updateTotalApAmount]);

  const fetchAll = async () => {
    try {
      showLoadingUI();
      const financierCommonSettingResponse = await requestFinancierSettingData(financierEnterpriseId);

      const date = new Date();
      const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];
      const { minimumPeriodForRegisterAr, maximumPeriodForRegisterAr } = financierCommonSettingResponse;
      const minimumDate = new Date(today[0], today[1], today[2] + minimumPeriodForRegisterAr);
      const maximumDate = new Date(today[0], today[1], today[2] + maximumPeriodForRegisterAr);

      const [arSummaryDetailResponse, arSummaryLastPhaseResponse, arSummaryPhaseResponse, fetchFinancierHoliday] =
        await Promise.all([
          requestAnchorArSummaryDetail(apSummaryId),
          requestAnchorArSummaryLastPhase(apSummaryId),
          requestAnchorArPhaseList(0, 10, apSummaryId),
          requestAnchorFinancierCalendar(financierEnterpriseId, {
            pageNumber: 0,
            rowCount:
              Number(getDayTerm(convertToServerDateFormat(minimumDate), convertToServerDateFormat(maximumDate))) + 1,
            fromDate: convertToServerDateFormat(minimumDate),
            toDate: convertToServerDateFormat(maximumDate),
            holiday: true,
          }),
        ]);

      const getHolidayArrays = fetchFinancierHoliday.content.map(item => item.solarDate);
      const availableDateRange = getAvailableSettlementDateRangeForRegistrationArOrAp(
        getHolidayArrays,
        minimumDate,
        maximumDate,
      );
      const [initialSettlementDate] = availableDateRange;

      const fetchPreviousPhase = async () => {
        // 이전 단계에 관한 가이드메시지 제공을 위해 이전단계 정보 불러오기
        const previousPhaseArList = await requestAnchorWaitingArList({
          waitingArApprovalPhaseId: arSummaryPhaseResponse.content[0].waitingArApprovalPhaseId,
          rowCount: 3000,
        });
        const validAPCount =
          previousPhaseArList.content.filter(ar => ar.waitingArStatus === WAITING_AR_STATUS.REGISTERED).length || 0;
        setNumberOfPreviousPhaseInvalidAP(previousPhaseArList.content.length - validAPCount);
      };

      ReactDOM.unstable_batchedUpdates(async () => {
        setArSummaryData(arSummaryDetailResponse);
        setArSummaryLastPhaseData(arSummaryLastPhaseResponse);
        setArSummaryPhaseDataList(arSummaryPhaseResponse);
        setFinancierHoliday(fetchFinancierHoliday);
        setInitialSettlementDate(initialSettlementDate);
        setSettlementDatePeriod({ maximumDate, minimumDate });
        setHolidays(getHolidayArrays);
        setEditable(
          arSummaryLastPhaseResponse?.authorized &&
            !arSummaryLastPhaseResponse?.inProgress &&
            !arSummaryDetailResponse?.rejected,
        );

        if (
          arSummaryLastPhaseResponse?.authorized &&
          !arSummaryLastPhaseResponse?.inProgress &&
          !arSummaryDetailResponse?.rejected &&
          arSummaryLastPhaseResponse.arList.content.length !== 0
        ) {
          await fetchPreviousPhase();
          if (arSummaryLastPhaseResponse.arList.content.length > FILE_UPLOAD_MAX_ROW_LENGTH) {
            const formattedData = (data: WaitingArVOModel): AnchorArListElementType => {
              const formattedSettlementDate = t('format:original-date', {
                value: String(data.settlementDate),
                key: 'no-line-date',
              });
              const formattedIssuedDate = t('format:original-date', {
                value: String(data.arIssuedDate),
                key: 'no-line-date',
              });

              const partnerTaxCode = formattingData(data.partnerTaxCode);
              const arNumber = formattingData(data.arNumber);
              const arAmount = formattingData(data.arAmount, 'arAmount');
              const arIssuedDate = dayjs(formattedIssuedDate, 'YYYY-MM-DD', true).isValid() ? formattedIssuedDate : '';
              const settlementDate = dayjs(formattedSettlementDate, 'YYYY-MM-DD', true).isValid()
                ? formattedSettlementDate
                : '';

              return {
                partnerTaxCode,
                arNumber,
                arAmount,
                arIssuedDate,
                settlementDate,
              };
            };
            const changedData: AnchorArListElementType[] = arSummaryLastPhaseResponse.arList.content.map(
              (data: WaitingArVOModel) => formattedData(data),
            );
            const updatedPartnerNames = arSummaryLastPhaseResponse.arList.content.map(item => item.partnerName);
            setExcelInfos(changedData);
            setRows(Array(changedData.length).fill(''));
            reset({
              arList: changedData,
            });
            await anchorApBulkImportValidate();
            isValidPartnerName(Array(arSummaryLastPhaseResponse.arList.content.length).fill(''), updatedPartnerNames);
            updateTotalApAmount(changedData);
          } else {
            setRows(Array(arSummaryLastPhaseResponse.arList.content.length).fill(''));

            const updatedPartnerNames = arSummaryLastPhaseResponse.arList.content.map(item => item.partnerName);

            setPartnerNames(updatedPartnerNames);

            reset({
              arList: arSummaryLastPhaseResponse.arList.content.map(item => {
                return {
                  arNumber: item.arNumber,
                  arAmount: item.arAmount,
                  arIssuedDate: item.arIssuedDate,
                  settlementDate: verifySettlementDateForRegistrationArOrAp(
                    item.settlementDate,
                    initialSettlementDate,
                    availableDateRange,
                  ),
                  partnerTaxCode: item.partnerTaxCode,
                };
              }),
            });
            await anchorApBulkImportValidate();
            updateTotalApAmount();
            isValidPartnerName(Array(arSummaryLastPhaseResponse.arList.content.length).fill(''), updatedPartnerNames);
            setCheckedValidation(arSummaryLastPhaseResponse.arList.content.map(() => true));
          }
        } else {
          reset({
            arList: [
              {
                arNumber: undefined,
                arAmount: undefined,
                arIssuedDate: undefined,
                settlementDate: initialSettlementDate,
                partnerTaxCode: undefined,
              },
            ],
          });
          unShowLoadingUI();
        }
      });
    } catch (error) {
      unShowLoadingUI();
      modal.show(error);
    }
  };

  const formattingData = (data: any, key?: string) => {
    if (data === '-' || isNil(data)) return '';
    else if (key === 'arAmount' && isNaN(Number(data))) return '';
    else return data;
  };

  const anchorApBulkImportValidate = async () => {
    return new Promise<void>(async resolve => {
      showLoadingUI();
      setTimeout(() => {
        resolve();
      }, 1000);
    }).then(async () => {
      await trigger().then(result => {
        unShowLoadingUI();
        if (!result) {
          modal.show(
            <>
              <h6>{t('text:The_list_of_AP_contain_errors')}</h6>
              <h6>{t('text:Export_the_AP_list_to_check_the_detailed_validation_results')}</h6>
              <h6>{t('text:Attach_the_updated_AP_list_again_to_register_the_AP')}</h6>
            </>,
          );
          // FE validation error 있으면 return
        }
      });
    });
  };

  // Partner Names을 요청하는 별도의 함수
  const fetchPartnerName = async (taxCode: string) => {
    const trimmedTaxCode = taxCode.trim();
    const isValidTaxCodeLength = taxCode.length >= 9;
    if (!isValidTaxCodeLength) return '';

    const partnerNames = await requestAnchorArPartnerNames([trimmedTaxCode]);

    return !isEmpty(partnerNames) ? partnerNames[0]['partnerName'] : '';
  };

  // 이벤트 핸들러
  const handlerPartnerTaxCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value, name } = e.target;
    const [_, index] = name.split('.');

    const partnerNameToUpdate = await fetchPartnerName(value);

    setPartnerNames(prevPartnerNames => {
      const newPartnerNames = [...prevPartnerNames];
      newPartnerNames[Number(index)] = partnerNameToUpdate;

      return newPartnerNames;
    });
  };

  const handleArSummaryPhaseAccordionClick = (arPhaseData: ArPhaseVOModel) => {
    return requestAnchorWaitingArList({
      waitingArApprovalPhaseId: arPhaseData.waitingArApprovalPhaseId,
      rowCount: 3000,
    });
  };

  const isValidPartnerName = (rowList = rows, partnerNameList = partnerNames) => {
    rowList.forEach((_, index) => {
      if (!partnerNameList[index]) {
        if (isBulkImport) {
          setError(`arList.${index}.partnerTaxCode`, {
            type: 'validate',
            message: 'text:Fail_No_partner_exists_that_matches_the_tax_code_you_entered',
          });
        } else {
          setError(`arList.${index}.partnerName`, {
            type: 'required',
            message: 'text:Fail_No_partner_exists_that_matches_the_tax_code_you_entered',
          });
          setError(`arList.${index}.partnerTaxCode`, {
            type: 'validate',
            message: 'text:Fail_No_partner_exists_that_matches_the_tax_code_you_entered',
          });
        }
      }
    });
  };

  const renderLastPhase = () => {
    const onClickReject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      let reason = '';

      const onClickConfirmOnReject = async (): Promise<void> => {
        try {
          await requestAnchorRejectArRegistration(Number(apSummaryId), reason);

          modal.show(<h6>{t('text:The_registration_request_has_been_rejected')}</h6>, {
            modalSize: ModalSize.NONE,
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => {
              fetchAll();
            },
          });
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_reject_the_registration_request?')}
            <br />
            {t('text:If_you_reject_the_registration_request,_you_will_have_to_proceed_with_the_AP_registration_again')}
            <br />
            {t('text:Please_enter_the_reason_for_the_rejection_below')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirmOnReject(),
        },
      );

      e.preventDefault();
    };

    const onClickRegister = async (e: any) => {
      e.preventDefault();

      const verificationCode: UserVerificationCodeRequest = {};

      const data = getValues();
      data.arList.forEach((ar, index) => setValue(`arList.${index}.partnerTaxCode`, ar.partnerTaxCode?.trim())); // partnerTaxCode trim 작업
      const trimmedPartnerTaxCodeData = getValues();

      const checkOtpAndConfirm = async () => {
        try {
          const clientAuthTypeData = await requestAnchorClientAuthByFinancierId(financierEnterpriseId);
          if (
            clientAuthTypeData.currentAuthorityType === AUTHORITY_TYPE.ADMIN &&
            clientAuthTypeData.otpType !== OTP_TYPE.NONE
          ) {
            modal.show(
              <UserVerificationModal
                modalId={modal.id}
                verificationCode={verificationCode}
                requestIdType="financierId"
                requestId={financierEnterpriseId}
                clientAuthSetting={clientAuthTypeData}
              />,
              {
                modalType: ModalType.CONFIRM,
                title: t('text:User_Verification'),
                closeBtnText: t('text:Cancel'),
                confirmBtnCb: () => onClickConfirm(),
              },
            );
          } else {
            onClickConfirm();
          }
        } catch (e) {
          modal.show(e);
        }
      };

      const executeRegister = () => {
        return new Promise<void>(async resolve => {
          showLoadingUI();
          setTimeout(() => {
            resolve();
          }, 1000);
        }).then(async () => {
          if (!isBulkImport) {
            await trigger().then(result => {
              isValidPartnerName();
              setCheckedValidation(data.arList.map(() => true));
              unShowLoadingUI();

              if (!result || !isEmpty(formState.errors)) {
                modal.show(<AnchorApRegisterValidationErrorModal />, {
                  modalSize: ModalSize.NONE,
                  modalType: ModalType.ALERT,
                  closeBtnText: t('text:Close'),
                });

                return; // FE validation error 있으면 return
              } else {
                // FRONT Validation 통과
                if (trimmedPartnerTaxCodeData) {
                  modal.show(
                    <AnchorAPRequestModal
                      dataList={trimmedPartnerTaxCodeData.arList}
                      totalCount={trimmedPartnerTaxCodeData.arList.length}
                      totalAmount={totalApAmount}
                      currencyType={arSummaryData?.currencyType}
                      partnerNames={partnerNames}
                    />,
                    {
                      modalSize: ModalSize.XL,
                      modalType: ModalType.CONFIRM,
                      closeBtnText: t('text:Cancel'),
                      confirmBtnCb: checkOtpAndConfirm,
                    },
                  );
                }
              }
            });
          } else {
            checkOtpAndConfirm();
            unShowLoadingUI();
          }
        });
      };
      await executeRegister();

      const onClickConfirm = async (): Promise<void> => {
        const requestedData = isBulkImport ? ({ arList: excelInfos } as AnchorArPhaseApproveRequest) : getValues();
        requestedData.anchorAgreementId = Number(arSummaryData?.anchorAgreementId);
        requestedData.otpCode = verificationCode.otpCode;
        requestedData.queryValue = verificationCode.queryValue;
        requestedData.financierId = Number(arSummaryData?.financierEnterpriseId);
        requestedData.arSummaryId = Number(apSummaryId);

        try {
          await requestAnchorApproveArPhase(requestedData);
          modal.show(<h6>{t('text:The_AP_confirmation_request_has_been_completed_and_is_in_progress')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => fetchAll(),
          });
        } catch (error) {
          if (isBulkImport) {
            modal.show(
              <h6>
                {t('text:The_list_of_AP_contain_errors')}
                <br />
                {t('text:Export_the_AP_list_to_check_the_detailed_validation_results')}
                <br />
                {t('text:Attach_the_updated_AP_list_again_to_register_the_AP')}
              </h6>,
            );
          } else {
            modal.show(error);
          }
        }
      };
    };

    const onChangeUploadExcel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const fetchPartnerNamesByTaxCodes = async (taxCodes: string[]): Promise<{ [key: string]: string }> => {
        const uniqueTaxCodes = [...new Set(taxCodes)];
        const partnerTaxCodesAndNames = await requestAnchorArPartnerNames(uniqueTaxCodes);

        return partnerTaxCodesAndNames.reduce(
          (partnerMap, partnerTaxCodeAndName) => ({
            ...partnerMap,
            [partnerTaxCodeAndName.taxCode]: partnerTaxCodeAndName.partnerName,
          }),
          {},
        );
      };

      const updatePartnerNames = (
        changedData: AnchorArListElementType[],
        partnerNameMap: { [key: string]: string },
      ): string[] => changedData.map(ar => partnerNameMap[String(ar.partnerTaxCode).trim()] || '');

      const isValidPartnerTaxCode = (partnerTaxCode?: string): string => {
        if (partnerTaxCode) {
          const trimmedPartnerTaxCode = partnerTaxCode.trim();

          if (REG_ALL_CHARACTER_AND_NUMBER.test(trimmedPartnerTaxCode) && trimmedPartnerTaxCode.length <= 20) {
            return trimmedPartnerTaxCode;
          }
        }

        return '0';
      };

      const extractValidPartnerTaxCodes = (dataArray: any[]): string[] => {
        return dataArray.map(data => String(data['Partner Tax Identification Number'])).map(isValidPartnerTaxCode);
      };

      const formattedData = (data: any) => {
        const formattedSettlementDate = t('format:original-date', {
          value: String(data[ExcelTemplatesHeader.SETTLEMENT_DATE]),
          key: 'no-line-date',
        });

        const formattedIssuedDate = t('format:original-date', {
          value: String(data[ExcelTemplatesHeader.AP_ISSUED_DATE]),
          key: 'no-line-date',
        });
        const partnerTaxCodeData = String(data['Partner Tax Identification Number']).trim();
        const arNumberData = data[ExcelTemplatesHeader.AP_NUMBER];
        const arAmountData = data[ExcelTemplatesHeader.AP_AMOUNT];

        const partnerTaxCode = formattingData(partnerTaxCodeData);
        const arNumber = formattingData(arNumberData);
        const arAmount = formattingData(arAmountData, 'arAmount');
        const arIssuedDate = dayjs(formattedIssuedDate, 'YYYY-MM-DD', true).isValid() ? formattedIssuedDate : '';
        const settlementDate = dayjs(formattedSettlementDate, 'YYYY-MM-DD', true).isValid()
          ? formattedSettlementDate
          : '';

        return {
          partnerTaxCode,
          arNumber,
          arAmount,
          arIssuedDate,
          settlementDate,
        };
      };

      if (e.target.files && e.target.files[0]) {
        const isCsvFile = e.target.files[0].type === 'text/csv';

        try {
          showLoadingUI();
          const jsonArrayData = isCsvFile
            ? await csvToJson(
                e.target.files[0],
                FILE_UPLOAD_MAX_ROW_LENGTH,
                BULK_IMPORT_MAX_ROW_LENGTH,
                isBulkImport ? BULK_IMPORT : FILE_UPLOAD,
              )
            : await excelToJson(
                e.target.files[0],
                FILE_UPLOAD_MAX_ROW_LENGTH,
                BULK_IMPORT_MAX_ROW_LENGTH,
                isBulkImport ? BULK_IMPORT : FILE_UPLOAD,
              );
          setCheckedValidation([]);
          setFile(e.target.files[0]);

          if (isCsvFile && jsonArrayData && jsonArrayData.length !== 0) {
            const partnerTaxCodes = extractValidPartnerTaxCodes(jsonArrayData);
            const changedData: AnchorArListElementType[] = jsonArrayData.map((data: any) => formattedData(data));
            let updatedNames;

            setRows(Array(changedData.length).fill(''));
            reset({
              arList: changedData,
            });
            if (isBulkImport) {
              setExcelInfos(changedData);
              if (partnerTaxCodes.length === 0) {
                setPartnerNames([]);
              } else {
                const partnerNameMap = await fetchPartnerNamesByTaxCodes(partnerTaxCodes);
                updatedNames = updatePartnerNames(changedData, partnerNameMap);
                setPartnerNames(updatedNames);
              }
              updateTotalApAmount(changedData);
              await anchorApBulkImportValidate();
              isValidPartnerName(Array(changedData.length).fill(''), updatedNames);
            } else {
              if (partnerTaxCodes.length === 0) {
                setPartnerNames([]);
              } else {
                const partnerNameMap = await fetchPartnerNamesByTaxCodes(partnerTaxCodes);
                updatedNames = updatePartnerNames(changedData, partnerNameMap);
                setPartnerNames(updatedNames);
              }
              updateTotalApAmount();
              await anchorApBulkImportValidate();
              isValidPartnerName(Array(changedData.length).fill(''), updatedNames);
              setCheckedValidation(getValues().arList.map(() => true));
            }

            return;
          }

          if (
            !isCsvFile &&
            jsonArrayData &&
            Array.isArray(jsonArrayData) &&
            jsonArrayData[0] &&
            Array.isArray(jsonArrayData[0]) &&
            jsonArrayData[0].length !== 0
          ) {
            const partnerTaxCodes = extractValidPartnerTaxCodes(jsonArrayData[0]);
            const changedData: AnchorArListElementType[] = jsonArrayData[0].map((data: any) => formattedData(data));
            let updatedNames;
            setRows(Array(changedData.length).fill(''));
            reset({
              arList: changedData,
            });
            if (isBulkImport) {
              setExcelInfos(changedData);
              if (partnerTaxCodes.length === 0) {
                setPartnerNames([]);
              } else {
                const partnerNameMap = await fetchPartnerNamesByTaxCodes(partnerTaxCodes);
                updatedNames = updatePartnerNames(changedData, partnerNameMap);
                setPartnerNames(updatedNames);
              }
              updateTotalApAmount(changedData);
              await anchorApBulkImportValidate();
              isValidPartnerName(Array(changedData.length).fill(''), updatedNames);
            } else {
              if (partnerTaxCodes.length === 0) {
                setPartnerNames([]);
              } else {
                const partnerNameMap = await fetchPartnerNamesByTaxCodes(partnerTaxCodes);
                updatedNames = updatePartnerNames(changedData, partnerNameMap);
                setPartnerNames(updatedNames);
              }
              updateTotalApAmount();
              await anchorApBulkImportValidate();
              isValidPartnerName(Array(changedData.length).fill(''), updatedNames);
              setCheckedValidation(getValues().arList.map(() => true));
            }

            return;
          }

          modal.show(
            <h6>
              {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
              <br />
              {t('text:Please_check_and_upload_it_again')}
            </h6>,
          );
        } catch (error) {
          modal.show(error);
          initializeRefValue(fileRef);
        } finally {
          if (!isBulkImport) {
          }
          unShowLoadingUI();
        }
      }
    };

    const onClickRemoveExcel = (e: any): void => {
      e.preventDefault();
      // for same file upload ..
      initializeRefValue(fileRef);
      if (file) {
        setFile(undefined);
      }
    };

    const renderExcelUpload = () => {
      return (
        <div className="excel-form mb-5">
          <div className="excel-download-form d-flex">
            <label className="me-3">
              {isBulkImport
                ? t(
                    'text:To_upload_more_than_500_APs,_please_download_the_template_on_the_right,_fill_it_out,_and_attach_it_below',
                  )
                : `${t('text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it')} ${t(
                    'text:After_uploading_you_can_check_the_uploaded_list_below',
                  )}`}
            </label>
            <ExcelTemplateDownloadButton
              templateFileAddress="/templates/APRegistrationTemplate.xlsx"
              style={{ marginRight: '4px' }}
            />
            <ExcelTemplateDownloadButton
              downloadAnnounceText={t('text:CSV_Template')}
              templateFileAddress="/templates/APRegistrationTemplate.csv"
            />
          </div>
          <div className="detail-in-file-upload-form bg-sub100">
            <div className="d-flex justify-content-between">
              <input
                onChange={onChangeUploadExcel}
                ref={fileRef}
                type="file"
                name="file"
                id="FileUpload"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
              />
              <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
                {t('text:Attach_File')}
              </label>
              <div id="fileName" className="upload-file-input">
                {file ? file.name : t('text:No_file_attached')}
              </div>
              <IconButton onClick={onClickRemoveExcel} className={`delete-uploaded-excel-button ${!file && 'd-none'}`}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
            </div>
          </div>
        </div>
      );
    };

    return (
      editable && (
        <div className="editable-section">
          {renderExcelUpload()}
          {!isEmpty(errors) && (
            <div className="detail-in-file-upload-result-confirmation__alert">
              <SectionMessage
                message={[
                  isBulkImport
                    ? t('text:N_of_N_APs_contain_invalid_information', {
                        errorsLength: errors.arList?.filter(Boolean).length,
                        allLength: excelInfos?.length,
                      })
                    : '',
                  t(
                    'text:Export_the_AP_list_and_check_the_detailed_validation_results_before_uploading_the_revised_AP_information_again',
                  ),
                  t('text:If_you_have_modified_any_APs_click_register_button_to_update_the_validation_result'),
                  t(
                    'text:If_you_click_on_the_check_box_and_use_the_Show_invalid_AP_only_button_the_status_of_the_check_box_is_reset',
                  ),
                  t('text:Please_Check_the_check_box_when_you_delete_the_AP'),
                ].filter(msg => msg !== '')}
                messageType="error"
              />
            </div>
          )}
          {isNil(errors.arList) && isBulkImport && (
            <div className="detail-in-file-upload-result-confirmation">
              <SectionMessage
                message={[
                  t('text:N_APs_passed_the_validation_checks_successfully', {
                    allLength: excelInfos?.length,
                  }) +
                    ' ' +
                    t('text:Click_on_the_Register_button_to_complete_the_AP_registration_request'),
                ]}
              />
            </div>
          )}
          {isBulkImport ? (
            <ApInputBulkImportTable
              errors={errors}
              register={register}
              originalExcelInfos={excelInfos}
              rows={rows}
              holidays={holidays}
              minDate={settlementDatePeriod.minimumDate}
              maxDate={settlementDatePeriod.maximumDate}
              currencyType={arSummaryData?.currencyType}
              totalApAmount={totalApAmount}
              t={t}
            />
          ) : (
            <ApInputBulkTable
              anchorApBulkImportValidate={anchorApBulkImportValidate}
              isValidPartnerName={isValidPartnerName}
              register={register}
              errors={errors}
              getValues={getValues}
              reset={reset}
              clearErrors={clearErrors}
              control={control}
              setValue={setValue}
              formState={formState}
              rows={rows}
              setRows={setRows}
              checkedValidation={checkedValidation}
              setCheckedValidation={setCheckedValidation}
              totalApAmount={totalApAmount}
              updateTotalApAmount={updateTotalApAmount}
              initialSettlementDate={initialSettlementDate}
              pageType="confirmation"
              minimumDate={settlementDatePeriod.minimumDate}
              maximumDate={settlementDatePeriod.maximumDate}
              currencyType={arSummaryData?.currencyType}
              financierHoliday={financierHoliday}
              partnerNames={partnerNames}
              setPartnerNames={setPartnerNames}
              onChangePartnerTaxCode={handlerPartnerTaxCodeChange}
            />
          )}
          <div className="flex-end mt-3">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={onClickReject}
              className="me-2"
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Reject')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onClickRegister}>
              {t('text:Register')}
            </Button>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AP_Confirmation_Details')} />
      {arSummaryData?.rejected && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[t('text:The_AP_registration_request_was_rejected'), t('text:Please_check_the_reason_below')]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={arSummaryData.rejectReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue label={t('text:Registration_Status')} col={4} value={renderProgressStatus()} />
              <FormValue
                label={t('text:Number_of_Valid_AP')}
                col={4}
                className="information-form__input border-none pointfont"
                value={arSummaryData?.currentApprovalCount}
                format="number"
              />
              <FormValue
                label={t('text:Total_Amount_of_Valid_AP')}
                col={4}
                className="information-form__input border-none pointfont"
                value={arSummaryData?.currentApprovalAmount}
                format="number"
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:Anchor_Master_Agreement_Information')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} col={4} value={arSummaryData?.financierEnterpriseName} />
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                col={4}
                value={arSummaryData?.anchorContractNo}
              />
              <FormValue label={t('text:Currency')} col={4} value={arSummaryData?.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {editable && arSummaryLastPhaseData?.collateralIssuedLimitCheck && (
        <div className="content-area">
          <SectionTitle title={t('text:AP_Registration_Limit')} />
          <div className="information-form__corporation">
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:AP_Issuance_Limit')} ①</div>
                <div className="col-6">
                  {t('format:number', { value: arSummaryLastPhaseData?.collateralIssuedLimitAmount })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Registration_Confirmed_AP')} ②</div>
                <div className="col-6">
                  {t('format:number', { value: arSummaryLastPhaseData?.settlementWaitingInvoiceAmount })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Registration_Requested_AP')} ③</div>
                <div className="col-6">
                  {t('format:number', { value: arSummaryLastPhaseData?.registeredWaitingArAmount })}
                </div>
              </div>
            </div>
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:Limit_Available_for_Registration')} ④ = ① - ② - ③</div>
                <div className="col-6 blackfont">
                  {t('format:number', {
                    value: arSummaryLastPhaseData?.remainingRegistrationLimit,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-area">
        <SectionTitle title={t('text:AP_List')} />
        <div className="mb-3">
          {numberOfPreviousPhaseInvalidAP > 0 && (
            <>
              <GuideMessage
                message={[
                  t('text:There_is_an_invalid_AP_registered_in_the_previous_step'),
                  t('text:Please_click_on_the_previous_step_below_to_see'),
                ]}
                isImportContentArea={true}
                messageType={MessageType.ALERT}
                useContentAreaClassName={false}
              />
            </>
          )}
        </div>

        {renderLastPhase()}
        {arSummaryData &&
          arSummaryPhaseDataList?.content.map(arSummaryPhase => {
            return (
              <ApAccordionList
                onAccordionClick={() => handleArSummaryPhaseAccordionClick(arSummaryPhase)}
                searchedWaitingApprovalPhaseId={searchedWaitingApprovalPhaseId}
                setSearchedWaitingApprovalPhaseId={setSearchedWaitingApprovalPhaseId}
                arSummaryData={arSummaryData}
                arSummaryPhase={arSummaryPhase}
                key={arSummaryPhase.waitingArApprovalPhaseId}
              />
            );
          })}
      </div>
    </>
  );
}

export default AnchorApConfirmationDetail;
