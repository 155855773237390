import type { CURRENCY_TYPE } from 'enums';

export interface SuccessArForRequestMultipleAnchorLoanVO {
  successArId: number;
  arNumber: string;
  anchorPartnerName: string;
  anchorPartnerTaxCode: string;
  arAmount: number;
  maxLtvAmount: number;
  arIssuedDate: string;
  arSettlementDate: string;
  currencyType: CURRENCY_TYPE;
}

export interface SuccessArForRequestMultipleAnchorLoanVOModel extends SuccessArForRequestMultipleAnchorLoanVO {}

export function formattingToSuccessArForRequestMultipleAnchorLoanVOModel(
  data: SuccessArForRequestMultipleAnchorLoanVO,
): SuccessArForRequestMultipleAnchorLoanVOModel {
  return {
    ...data,
  };
}
