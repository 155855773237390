const CM_FINANCIER_PATH = '/common/financier';
const CM_ANCHOR_PATH = '/common/anchor';
const CM_DEALER_AUTHORIZER_PATH = '/common/dealer-authorizer';
const CM_AC_HQ_OPERATOR_AND_DE_OPERATOR_PATH = '/common/ac-hq-operator-and-de-operator';

const CM_SIGN_UP_COMPLETE_PATH = '/common/sign-up-complete';

const CM_FIND_ID_PATH = '/common/find-id';
const CM_FIND_ID_EMAIL_SENT_PATH = `${CM_FIND_ID_PATH}/email-sent`;

const CM_FIND_PASSWORD_PATH = '/common/find-password';
const CM_FIND_PASSWORD_EMAIL_SENT_PATH = `${CM_FIND_PASSWORD_PATH}/email-sent`;

const CM_RESET_PASSWORD_PATH = '/common/reset/password';
const CM_RESET_PASSWORD_COMPLETE_PATH = '/common/reset/password-complete';

const CM_CREATE_FINANCIER_PATH = '/common/create-financier';

export const ROUTES_CM = {
  FI_SIGN_UP: `${CM_FINANCIER_PATH}/sign-up`,

  AC_SIGN_UP: `${CM_ANCHOR_PATH}/sign-up`,
  AC_CERTIFY: `${CM_ANCHOR_PATH}/certify`,

  DE_AUTHORIZER_SIGN_UP: `${CM_DEALER_AUTHORIZER_PATH}/sign-up`,
  DE_AUTHORIZER_CERTIFY: `${CM_DEALER_AUTHORIZER_PATH}/certify`,

  AC_HQ_OPERATOR_AND_DE_OPERATOR_SIGN_UP: `${CM_AC_HQ_OPERATOR_AND_DE_OPERATOR_PATH}/sign-up`,

  SIGN_UP_COMPLETE: CM_SIGN_UP_COMPLETE_PATH,

  FIND_ID: CM_FIND_ID_PATH,
  FIND_ID_EMAIL_SENT: CM_FIND_ID_EMAIL_SENT_PATH,

  FIND_PASSWORD: CM_FIND_PASSWORD_PATH,
  FIND_PASSWORD_EMAIL_SENT: CM_FIND_PASSWORD_EMAIL_SENT_PATH,

  RESET_PASSWORD: CM_RESET_PASSWORD_PATH,
  RESET_PASSWORD_COMPLETE: CM_RESET_PASSWORD_COMPLETE_PATH,

  CREATE_FINANCIER: CM_CREATE_FINANCIER_PATH,
};
