import type Pageable from 'models/Pageable';
import type {
  StatisticsOfDealerAgreementVO,
  StatisticsOfDealerAgreementVOModel,
} from 'models/vo/StatisticsOfDealerAgreementVO';
import { formattingToStatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';
import type { AnchorStatisticsOfDealerAgreementListRequest } from 'utils/http/api/anchor/statistics-of-dealer-agreements/requests';

// 앵커 - 딜러 모니터링
export async function requestAnchorStatisticsOfDealerAgreementsList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorStatisticsOfDealerAgreementListRequest,
): Promise<Pageable<StatisticsOfDealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<StatisticsOfDealerAgreementVO[]>>({
    url: API_AC.STATISTICS_OF_DEALER_AGREEMENTS.STATISTICS_OF_DEALER_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const statisticsOfDealerAgreementList: StatisticsOfDealerAgreementVOModel[] = response.content.map(
    (data: StatisticsOfDealerAgreementVO) => formattingToStatisticsOfDealerAgreementVOModel(data),
  );
  const statisticsOfDealerAgreementPage: Pageable<StatisticsOfDealerAgreementVOModel[]> = {
    ...response,
    content: statisticsOfDealerAgreementList,
  };

  return statisticsOfDealerAgreementPage;
}
