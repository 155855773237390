import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, EXTENSION_REQUEST_STATUS, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { ExtensionRequestDetailVOModel } from 'models/vo/ExtensionRequestDetailVO';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { getDayTerm } from 'utils/date/date';
import {
  requestFinancierApproveExtension,
  requestFinancierConfirmExtension,
  requestFinancierExtensionDetail,
  requestFinancierRejectExtension,
  requestFinancierRenewExtension,
  requestFinancierReturnExtension,
} from 'utils/http/api/financier/extension-requests';
import { requestFinancierLoanDetail } from 'utils/http/api/financier/loans';
import { getTotalInterestRate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableNumberValueIsZero, tableValueManage } from 'utils/valueManager/ValueManager';

import ExtensionConfirmModal from './modal/ExtensionConfirmModal';

function FinancierExtensionDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { extensionRequestId } = useParams<any>();
  const modal = useModal();
  const CURRENT_USER_AUTHORITY: AUTHORITY_TYPE | undefined = getSignIn()?.authorityType;

  // 화면 state
  const [extensionDetail, setExtensionDetail] = useState<ExtensionRequestDetailVOModel>();
  const [loanDetail, setLoanDetail] = useState<LoanDetailVOModel>();

  useEffect(() => {
    if (mounted) fetchData();
  }, [mounted]);

  async function fetchData() {
    try {
      const fetchExtensionDetail = await requestFinancierExtensionDetail(extensionRequestId);

      const fetchLoanDetail = await requestFinancierLoanDetail(fetchExtensionDetail.loanId);
      ReactDOM.unstable_batchedUpdates(() => {
        setExtensionDetail(fetchExtensionDetail);
        setLoanDetail(fetchLoanDetail);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const isExtensionStatusRequested = () => {
    return extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.REQUESTED;
  };

  const onClickExtensionRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EXTENSION_REQUEST_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="EXTENSION_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickFinancingStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  // 만기연장 신청 정보
  const renderExtensionRequestInfo = () => {
    return (
      <div className="content-area">
        <SectionTitle title={t('text:Request_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Requested_Date')}
                value={t('format:datetime', {
                  value: extensionDetail?.createdDateTime,
                  key: 'datetime',
                })}
              />
              <FormValue col={3} label={t('text:Partner_Client_Code')} value={extensionDetail?.dealerClientCode} />
              <FormValue col={3} label={t('text:Partner_Name')} value={extensionDetail?.dealerClientName} />
              <FormValue
                col={3}
                className={getStatusBadgeClass('EXTENSION_REQUEST_STATUS', extensionDetail?.extensionRequestStatus)}
                label={t('text:Status')}
                value={t(`code:extension-request-status.${extensionDetail?.extensionRequestStatus}`)}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickExtensionRequestStatus}
              />
            </div>
          </FormContents>

          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Financing_Information')}>
            <div className="flex-end align-items-center me-3">
              <span className="me-1">{t('text:Go_to_Financing_Details')}</span>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                className="flex-column-center"
                to={ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(loanDetail?.loanId!)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </FormSubtitle>
          <FormContents backGroundType={BackGroundType.Gray}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Financier_Financing_ID')}
                value={tableValueManage(loanDetail?.financierLoanId)}
              />
              <FormValue col={3} label={t('text:Platform_Financing_ID')} value={tableValueManage(loanDetail?.loanId)} />
              <FormValue
                col={3}
                label={t('text:Financing_Amount')}
                value={t('format:number', { value: loanDetail?.principalAmount })}
              />
              <FormValue
                col={3}
                label={t('text:Disbursed_Date')}
                value={t('format:date', { value: loanDetail?.disbursedDate, key: 'date' })}
              />
            </div>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Initial_Repayment_Date')}
                value={t('format:date', {
                  value: loanDetail?.originalRepaymentDate,
                  key: 'date',
                })}
              />
              <FormValue
                col={3}
                label={t('text:Total_Interest_Rate_(APR)')}
                value={getTotalInterestRate(
                  loanDetail?.disbursedBasisInterestRate!,
                  loanDetail?.disbursedTotalInterestRateWithoutBasis!,
                )}
              />
              <FormValue
                col={3}
                label={t('text:Remaining_Requests')}
                value={t('format:number', {
                  value: Number(loanDetail?.maxExtensibleLoanCount) - Number(loanDetail?.extendedLoanCount),
                })}
              />
              <FormValue
                col={3}
                className={getStatusBadgeClass('LOAN_STATUS', loanDetail?.loanStatus)}
                label={t('text:Financing_Status')}
                value={t(`code:financing-status.${loanDetail?.loanStatus}`)}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickFinancingStatus}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    );
  };
  const renderGuideMessage = () => {
    if (extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.REJECTED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_application_for_extension_has_been_rejected'),
            t('text:Please_check_the_reason_for_the_rejection_below'),
          ]}
          reason={extensionDetail?.rejectReason}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
        />
      );
    } else if (extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.RETURNED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_request_for_approval_for_extension_has_been_reverted'),
            t('text:Please_check_the_reason_for_revert_below'),
          ]}
          reason={extensionDetail?.returnReason}
          reasonTitle={t('text:REASON_FOR_REVERT')}
        />
      );
    }
  };

  const renderExtensionConfirmationInfo = () => {
    const isOperatorAction = () => {
      return (
        extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.PREAPPROVED ||
        extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.REJECTED
      );
    };
    const renderConfirmationPersonInfo = () => {
      return (
        <>
          <FormSubtitle title={t('text:Authorization')} backGroundType={BackGroundType.DarkGray} />

          <FormContents backGroundType={BackGroundType.Gray}>
            <div className="row">
              <FormValue col={3} label={t('text:Financier_Name')} value={extensionDetail?.financierName} />
              <FormValue
                col={3}
                label={t('text:User_Name')}
                value={isOperatorAction() ? extensionDetail?.preApprovedName : extensionDetail?.approvedName}
              />
              <FormValue
                col={3}
                label={t('text:Authority')}
                value={
                  isOperatorAction()
                    ? t(`code:authority-type.${extensionDetail?.preApprovedAuthorityType}`)
                    : t(`code:authority-type.${extensionDetail?.approvedAuthorityType}`)
                }
              />
              <FormValue
                col={3}
                label={t('text:Approved_Date')}
                value={
                  isOperatorAction()
                    ? t('format:datetime', {
                        value: extensionDetail?.preApprovedDateTime,
                        key: 'datetime',
                      })
                    : t('format:datetime', {
                        value: extensionDetail?.approvedDateTime,
                        key: 'datetime',
                      })
                }
              />
            </div>
          </FormContents>
        </>
      );
    };

    return (
      <div className="content-area">
        <SectionTitle title={t('text:Approved_Extension_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Modified_Repayment_Date')}
                value={tableValueManage(
                  extensionDetail?.extendedRepaymentDate,
                  t('format:date', {
                    value: extensionDetail?.extendedRepaymentDate,
                    key: 'date',
                  }),
                )}
              />
              <FormValue
                col={3}
                label={t('text:Start_Date_of_Financing_Extension')}
                value={tableValueManage(
                  extensionDetail?.extensionStartDate,
                  t('format:date', {
                    value: extensionDetail?.extensionStartDate,
                    key: 'date',
                  }),
                )}
              />
              <FormValue
                col={3}
                label={t('text:Financing_Extension_Period')}
                value={getDayTerm(extensionDetail?.extensionStartDate, extensionDetail?.extendedRepaymentDate, {
                  includeStartDate: true,
                  withText: true,
                })}
              />
              <FormValue
                col={3}
                label={t('text:Added_Spread_(APR)')}
                value={tableNumberValueIsZero(extensionDetail?.additionalRate)}
              />
            </div>
          </FormContents>
          {renderConfirmationPersonInfo()}
        </FormBorder>
      </div>
    );
  };

  const showExtensionCompletedModal = () => {
    modal.show(<h6>{t('text:Approval_for_extension_has_been_completed')}</h6>, {
      closeBtnCb: () => fetchData(),
    });
  };

  const renderOperatorButtons = () => {
    const onClickReject = () => {
      let reason = '';

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_reject_the_application_for_extension?')}
            <br />
            {t('text:Please_enter_the_reason_for_the_rejection_below')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          title: t('text:Reject_Request'),
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: async () => {
            try {
              await requestFinancierRejectExtension(extensionRequestId, reason);
              fetchData();
            } catch (e) {
              modal.show(e);
            }
          },
        },
      );
    };

    const onClickConfirm = () => {
      let extendedRepaymentDateValue = '';
      let additionalRateValue = 0;
      const getData = (extendedRepaymentDate: string, additionalRate: number) => {
        extendedRepaymentDateValue = extendedRepaymentDate;
        additionalRateValue = additionalRate;
      };

      modal.show(<ExtensionConfirmModal extensionStartDate={loanDetail?.repaymentDate} getData={getData} />, {
        modalType: ModalType.CONFIRM,
        title: t('text:Confirm_Financing_Extension'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestFinancierConfirmExtension(extensionRequestId, {
              additionalRate: additionalRateValue,
              extendedRepaymentDate: extendedRepaymentDateValue,
            });
            showExtensionCompletedModal();
          } catch (e) {
            modal.show(e);
          }
        },
      });
    };
    if (
      extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.REQUESTED ||
      extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.RETURNED
    )
      return (
        <div className="content-area">
          <div className="flex-end mt-4">
            <Button
              size={ButtonSizeEnum.LG}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
              onClick={onClickReject}
            >
              {t('text:Reject')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onClickConfirm} className="ms-2">
              {t('text:Confirm')}
            </Button>
          </div>
        </div>
      );
  };

  const renderAuthorizerButtons = () => {
    const onClickRevert = () => {
      let reason = '';

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_financing_extension_approval_request?')}
            <br />
            {t('text:Please_enter_the_reason_for_the_revert_below')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          title: t('text:Revert_Financing_Extension'),
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: async () => {
            try {
              await requestFinancierReturnExtension(extensionRequestId, reason);
              showRevertConfirmModal();
            } catch (e) {
              modal.show(e);
            }
          },
        },
      );

      const showRevertConfirmModal = () => {
        modal.show(<h6>{t('text:The_request_for_approval_for_extension_has_been_reverted')}</h6>, {
          closeBtnCb: () => fetchData(),
        });
      };
    };

    const onClickConfirm = () => {
      modal.show(
        <ExtensionConfirmModal
          extensionStartDate={extensionDetail?.extensionStartDate}
          newRepaymentDate={extensionDetail?.extendedRepaymentDate}
          additionalRate={extensionDetail?.additionalRate}
        />,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          title: t('text:Confirm_Financing_Extension'),
          confirmBtnCb: async () => {
            try {
              await requestFinancierApproveExtension(extensionRequestId);
              showExtensionCompletedModal();
            } catch (e) {
              modal.show(e);
            }
          },
        },
      );
    };

    const onClickUpdateExtension = async () => {
      try {
        await requestFinancierRenewExtension(extensionRequestId);
        fetchData();
      } catch (e) {
        modal.show(e);
      }
    };

    return (
      <div className="content-area">
        {extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.PREAPPROVED && (
          <div className="flex-end mt-4">
            <Button
              size={ButtonSizeEnum.LG}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
              onClick={onClickRevert}
            >
              {t('text:Revert')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onClickConfirm} className="ms-2">
              {t('text:Confirm')}
            </Button>
          </div>
        )}
        {extensionDetail?.extensionRequestStatus === EXTENSION_REQUEST_STATUS.APPROVED && (
          <div className="flex-end mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickUpdateExtension}>
              {t('text:Update_Extension_Info')}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Extension_Request_Details')} />
      {renderGuideMessage()}
      {renderExtensionRequestInfo()}
      {/* 만기연장 결재 테이블 */}
      {!isExtensionStatusRequested() && renderExtensionConfirmationInfo()}
      {/* operator buttons */}
      {CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.OPERATOR && renderOperatorButtons()}
      {/* authorizer buttons */}
      {CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.AUTHORIZER && renderAuthorizerButtons()}
    </>
  );
}

export default FinancierExtensionDetail;
