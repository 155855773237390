import { useTermSpreadContext } from './TermSpreadContext';

const useTermSpreadViewModel = () => {
  const { termSpreadList, updateTermSpreadList } = useTermSpreadContext();

  return {
    termSpreadList,
    updateTermSpreadList,
  };
};

export default useTermSpreadViewModel;
