import type Pageable from 'models/Pageable';
import type { FinancierCalenderVO, FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import { formattingToFinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

import type { DealerFinancierCalenderRequest } from './request';

export async function requestDealerFinancierCalendar(
  financierId: number,
  financierCalenderDto?: DealerFinancierCalenderRequest,
): Promise<Pageable<FinancierCalenderVOModel[]>> {
  const response = await http.get<Pageable<FinancierCalenderVO[]>>({
    url: API_DE.FINANCIER_CALENDAR.FINANCIER_CALENDAR(financierId),
    data: financierCalenderDto,
  });
  const financierCalendarList: FinancierCalenderVOModel[] = response.content.map((data: FinancierCalenderVO) =>
    formattingToFinancierCalenderVOModel(data),
  );
  const financierCalendarPage: Pageable<FinancierCalenderVOModel[]> = {
    ...response,
    content: financierCalendarList,
  };

  return financierCalendarPage;
}
