import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import FormInput from 'components/stateless/CommonForm/FormInput';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import PasswordValidationBox from 'components/stateless/PasswordValidationBox/PasswordValidationBox';
import { ROUTES_CM } from 'constants/routes/common';
import useMounted from 'hooks/useMounted';
import { requestAnEmailCheckStatus } from 'utils/http/api/anonymous/email';
import { requestUserResetPassword } from 'utils/http/api/anonymous/users';
import type { ResetUserPasswordRequest } from 'utils/http/api/anonymous/users/requests';
import useModal from 'utils/modal/useModal';

function ResetPassword() {
  const { t } = useTranslation();
  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();

  const { register, errors, getValues, handleSubmit, watch, formState } = useForm<ResetUserPasswordRequest>({
    mode: 'onChange',
    shouldFocusError: true,
    criteriaMode: 'all',
  });

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const emailCode = query.code as string;

  const requestCheckEmailStatus = useCallback(async () => {
    try {
      await requestAnEmailCheckStatus(emailCode);
    } catch (e: any) {
      modal.show(e);
    }
  }, [emailCode]);

  useEffect(() => {
    if (mounted) {
      requestCheckEmailStatus();
    }

    return () => {};
  }, [requestCheckEmailStatus, mounted]);

  const onSubmitResetPwd = async () => {
    const data = getValues();
    const code = { code: emailCode };

    try {
      await requestUserResetPassword({ ...data, ...code });

      history.push(ROUTES_CM.RESET_PASSWORD_COMPLETE);
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <PublicHeaderBar />
      <div className="align-middle row">
        <div className="find-form">
          <h2 className="login-form__title">{t('text:Reset_Password')}</h2>
          <div className="border-bottom-light-gray mb-3" />
          <form>
            <div className="login-form__input">
              <FormInput
                label={t('text:New_Password')}
                placeholder={t('text:Please_type_here')}
                ref={register({
                  minLength: 8,
                  required: true,
                  validate: {
                    minLength: value => value.length > 0,
                    isUpper: value => /[A-Z]/g.test(value),
                    isLower: value => /[a-z]/g.test(value),
                    isNumber: value => /[0-9]/g.test(value),
                  },
                })}
                name="newPassword"
                type="password"
                col={12}
                error={errors.newPassword}
              />
            </div>
            <div className="login-form__input mt-3">
              <FormInput
                label={t('text:Confirm_New_Password')}
                placeholder={t('text:Please_type_here')}
                ref={register({
                  validate: value => {
                    return value === watch('newPassword');
                  },
                })}
                name="confirmPassword"
                type="password"
                col={12}
                error={errors.confirmPassword}
              />
            </div>
            {errors.confirmPassword?.types?.validate && (
              <div className="text-brick-red mt-1">
                <FontAwesomeIcon icon={faBan} /> {t('text:Password_do_not_match')}
              </div>
            )}
            <div className="mt-4">
              <PasswordValidationBox newPassword={watch().newPassword} />
            </div>
            <Button size={ButtonSizeEnum.MD} disabled={!formState.isValid} onClick={handleSubmit(onSubmitResetPwd)}>
              {t('text:Reset_Password')}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
