import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

export async function requestDealerOtpByDealerAgreementId(dealerAgreementId: number): Promise<any> {
  const response = await http.post<string>({
    url: API_DE.OTP.OTP_BY_DEALER_AGREEMENT_ID,
    data: { dealerAgreementId },
  });

  return response;
}

export async function requestDealerOtpByAnchorAgreementId(anchorAgreementId: number): Promise<any> {
  const response = await http.post<string>({
    url: API_DE.OTP.OTP_BY_ANCHOR_AGREEMENT_ID,
    data: { anchorAgreementId },
  });

  return response;
}
