import type React from 'react';

import QuestionButton from 'components/stateless/QuestionButton';

interface SearchLabelPropsType {
  label: string;
  col?: number;
  className?: string;
  hasStatusDescription?: boolean;
  showStatusDescriptionFunc?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

const SearchLabel = ({
  label,
  col = 2,
  className,
  hasStatusDescription,
  showStatusDescriptionFunc,
}: SearchLabelPropsType) => {
  return (
    <div className={className ?? `search-form__label-wrap col-${col}`}>
      <div className="search-form__label">
        {label}
        {hasStatusDescription && <QuestionButton onClick={showStatusDescriptionFunc} />}
      </div>
    </div>
  );
};

export default SearchLabel;
