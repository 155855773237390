import type Pageable from 'models/Pageable';
import type {
  OverdueInterestRateSettingChangeHistoryVO,
  OverdueInterestRateSettingChangeHistoryVOModel,
} from 'models/vo/OverdueInterestRateSettingChangeHistoryVO';
import { formattingToOverdueInterestRateSettingChangeHistoryVOModel } from 'models/vo/OverdueInterestRateSettingChangeHistoryVO';
import type {
  OverdueInterestRateSettingDetailVO,
  OverdueInterestRateSettingDetailVOModel,
} from 'models/vo/OverdueInterestRateSettingDetailVO';
import { formattingToOverdueInterestRateSettingDetailVOModel } from 'models/vo/OverdueInterestRateSettingDetailVO';
import type {
  OverdueInterestRateSettingVO,
  OverdueInterestRateSettingVOModel,
} from 'models/vo/OverdueInterestRateSettingVO';
import { formattingToOverdueInterestRateSettingVOModel } from 'models/vo/OverdueInterestRateSettingVO';
import type {
  OverdueInterestRateTermSpreadVO,
  OverdueInterestRateTermSpreadVOModel,
} from 'models/vo/OverdueInterestRateTermSpreadVO';
import { formattingToOverdueInterestRateTermSpreadVOModel } from 'models/vo/OverdueInterestRateTermSpreadVO';
import http from 'utils/http';

import API_FI from '..';

import type { UpdateFiOverdueInterestRateSettingCostOfFundRequest } from './request';
import type { UpdateFiOverdueInterestRateSettingTermSpreadRequest } from './request';
import type {
  CreateFiOverdueInterestRateSettingRequest,
  SearchFiOverdueInterestRateSettingRequest,
  UpdateFiOverdueInterestRateSettingRequest,
} from './request';

export async function requestFinancierOverdueInterestRateSettingList(
  pageNumber: number,
  rowCount: number,
  searchValue?: SearchFiOverdueInterestRateSettingRequest,
): Promise<Pageable<OverdueInterestRateSettingVOModel[]>> {
  const response = await http.get<Pageable<OverdueInterestRateSettingVO[]>>({
    url: API_FI.OVERDUE_INTEREST_RATE_SETTINGS.OVERDUE_INTEREST_RATE_SETTINGS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map((data: OverdueInterestRateSettingVO) =>
      formattingToOverdueInterestRateSettingVOModel(data),
    ),
  };
}

export async function requestFinancierOverdueInterestRateSettingRegister(
  data: CreateFiOverdueInterestRateSettingRequest,
) {
  await http.post({
    url: API_FI.OVERDUE_INTEREST_RATE_SETTINGS.OVERDUE_INTEREST_RATE_SETTINGS,
    data,
  });
}

export async function requestFinancierOverdueInterestRateSettingsDetail(
  overdueInterestRateSettingId: number,
): Promise<OverdueInterestRateSettingDetailVOModel> {
  const response = await http.get<OverdueInterestRateSettingDetailVO>({
    url: API_FI.OVERDUE_INTEREST_RATE_SETTINGS.OVERDUE_INTEREST_RATE_SETTINGS_DETAIL(overdueInterestRateSettingId),
  });

  return formattingToOverdueInterestRateSettingDetailVOModel(response);
}

export async function requestFinancierOverdueInterestRateSettingsHistories(
  pageNumber: number,
  rowCount: number,
  overdueInterestRateSettingId: number,
): Promise<Pageable<OverdueInterestRateSettingChangeHistoryVOModel[]>> {
  const response = await http.get<Pageable<OverdueInterestRateSettingChangeHistoryVO[]>>({
    url: API_FI.OVERDUE_INTEREST_RATE_SETTINGS.OVERDUE_INTEREST_RATE_SETTINGS_HISTORY(overdueInterestRateSettingId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map((data: OverdueInterestRateSettingChangeHistoryVO) =>
      formattingToOverdueInterestRateSettingChangeHistoryVOModel(data),
    ),
  };
}

export async function requestUpdateFinancierOverdueInterestRateSettingsDetail(
  overdueInterestRateSettingId: number,
  data: UpdateFiOverdueInterestRateSettingRequest,
) {
  await http.put({
    url: API_FI.OVERDUE_INTEREST_RATE_SETTINGS.OVERDUE_INTEREST_RATE_SETTINGS_DETAIL(overdueInterestRateSettingId),
    data,
  });
}

export async function requestUpdateFinancierOverdueInterestRateSettingsTermSpread(
  overdueInterestRateSettingId: number,
  data: UpdateFiOverdueInterestRateSettingTermSpreadRequest,
) {
  await http.put({
    url: API_FI.OVERDUE_INTEREST_RATE_SETTINGS.OVERDUE_INTEREST_RATE_SETTINGS_TERM_SPREAD(overdueInterestRateSettingId),
    data,
  });
}

export async function requestUpdateFinancierOverdueInterestRateSettingsCostOfFund(
  overdueInterestRateSettingId: number,
  data: UpdateFiOverdueInterestRateSettingCostOfFundRequest,
) {
  await http.put({
    url: API_FI.OVERDUE_INTEREST_RATE_SETTINGS.OVERDUE_INTEREST_RATE_SETTINGS_COST_OF_FUND(
      overdueInterestRateSettingId,
    ),
    data,
  });
}

export async function requestFinancierOverdueInterestRateTermSpreadGroups(
  overdueInterestRateTermSpreadGroupId: number,
): Promise<OverdueInterestRateTermSpreadVOModel[]> {
  const response = await http.get<OverdueInterestRateTermSpreadVO[]>({
    url: API_FI.OVERDUE_INTEREST_RATE_SETTINGS.OVERDUE_INTEREST_RATE_TERM_SPREAD_GROUPS(
      overdueInterestRateTermSpreadGroupId,
    ),
  });

  return response.map((data: OverdueInterestRateTermSpreadVO) =>
    formattingToOverdueInterestRateTermSpreadVOModel(data),
  );
}
