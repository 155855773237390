import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import Select from 'components/stateless/Select/Select';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { FINANCIER_CLIENT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { requestFinancierClientList } from 'utils/http/api/financier/financier-clients';
import useModal from 'utils/modal/useModal';

function FinancierAnchorDealerRegistrationStep1Page() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { pageable, setPageable } = usePageable();

  const { register, getValues } = useForm<{ selectedKey: string; selectedValue: string }>({});

  const [anchorClientList, setAnchorClientList] = useState<FinancierClientVOModel[]>();

  useEffect(() => {
    if (mounted) {
      fetchAcRelatedFinancierList();
    }
  }, [mounted]);

  async function fetchAcRelatedFinancierList(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const anchorClientResponse = await requestFinancierClientList(pageNumber, rowCount, {
        financierClientType: FINANCIER_CLIENT_TYPE.ANCHOR,
        [getValues().selectedKey]: getValues().selectedValue,
      });

      setAnchorClientList(anchorClientResponse.content);
      setPageable(anchorClientResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchAcRelatedFinancierList(page, sizePerPage);
  };

  const TABLE_HEADER = [
    {
      headerText: t('text:Anchor_Client_Code'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Tax_Code'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: '',
      colWidths: 100,
    },
  ];

  const handleClickSearch = async () => {
    await fetchAcRelatedFinancierList(0, pageable.sizePerPage);
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Uploaded_Dealer_Registration')} />
      <div className="content-area">
        <StepWizard
          nth={1}
          title={[t('text:Select_Associated_Anchor'), t('text:Register_Uploaded_Dealer_Information')]}
        />
      </div>
      <GuideMessage message={[t('text:Select_the_Anchor_that_the_Uploaded_Dealers_are_associated_with')]} />
      <div className="content-area">
        <SectionTitle title={t('text:Search_Anchor')} />
        <form>
          <div className="d-flex information-filter mb-3">
            <Select
              name="selectedKey"
              selectOptions={[
                {
                  label: t('text:Anchor_Client_Code'),
                  value: 'financierClientCode',
                },
                {
                  label: t('text:Anchor_Name'),
                  value: 'financierClientName',
                },
                {
                  label: t('text:Tax_Code'),
                  value: 'financierClientTaxCode',
                },
              ]}
              className="information-form__select"
              ref={register}
            />
            <input type="text" name="selectedValue" ref={register} placeholder={t('text:Search…')} />
            <Button size={ButtonSizeEnum.XS} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="content-area">
        <TableBorder>
          <TableHeader header={TABLE_HEADER} />
          <TableBody numOfCol={TABLE_HEADER.length}>
            {anchorClientList?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.financierClientCode} />
                <Td data={item.financierClientName} />
                <Td data={item.financierClientTaxCode} />
                <Td data={item.financierClientAddress} />
                <Td data={item.financierClientTelephone} />
                <Td className="information-table-more">
                  <Button
                    onClick={() => {
                      history.push(ROUTES_FI.MANAGE_DEALER.REGISTRATION_STEP2, item);
                    }}
                  >
                    {t('text:Select')}
                  </Button>
                </Td>
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierAnchorDealerRegistrationStep1Page;
