import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import { requestAnchorAgreementsList } from 'utils/http/api/anchor/anchor-agreements';
import { requestAnchorDealerDetail } from 'utils/http/api/anchor/anchor-dealers';
import useModal from 'utils/modal/useModal';

import RelatedAnchorAgreementList from '../../components/RelatedAnchorAgreementList';

function AnchorDealerRegistrationConfirmedDetailPage() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { anchorDealerId } = useParams() as any;
  const [anchorDealerInfo, setAnchorDealerInfo] = useState<AnchorDealerVOModel>();
  const [relatedAnchorAgreement, setRelatedAnchorAgreement] = useState<AnchorAgreementVOModel[]>();
  const { pageable, setPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const anchorDealerResponse = await requestAnchorDealerDetail(anchorDealerId);

      const relatedAnchorAgreementResponse = await requestAnchorAgreementsList(1, 10, {
        financierName: anchorDealerResponse.financierName,
      });

      setAnchorDealerInfo(anchorDealerResponse);
      setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
      setPageable(relatedAnchorAgreementResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    const relatedAnchorAgreementResponse = await requestAnchorAgreementsList(pageNumber, sizePerPage, {
      financierName: anchorDealerInfo?.financierName,
    });

    setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
    setPageable(relatedAnchorAgreementResponse);
  };

  return (
    <>
      <BackHeaderTitle title={anchorDealerInfo?.name} />
      <GuideMessage
        message={[t('text:If_the_Dealers_information_is_incorrect_revise_the_information_in_company_info_menu')]}
      />
      <div className="content-area">
        <SectionTitle title={t(`text:Dealer_Information`)} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t(`text:Dealer_Name`)} value={anchorDealerInfo?.name} />
              <FormValue label={t(`text:Tax_Code`)} value={anchorDealerInfo?.taxCode} />
            </div>
            <div className="row">
              <FormValue label={t(`text:Company_Registration_Number`)} value={anchorDealerInfo?.businessCode} />
              <FormValue col={3} label={t(`text:Telephone`)} value={anchorDealerInfo?.telephone} />
              <FormValue col={3} label={t(`text:Fax`)} value={anchorDealerInfo?.fax} />
            </div>
            <div className="row">
              <FormValue label={t(`text:Legal_Representative_Name`)} value={anchorDealerInfo?.representativeName} />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Title`)}
                value={anchorDealerInfo?.representativePosition}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Email`)}
                value={anchorDealerInfo?.representativeEmail}
              />
            </div>
            <div className="row">
              <FormValue col={12} label={t(`text:Registered_Office_Address`)} value={anchorDealerInfo?.address} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <RelatedAnchorAgreementList
        relatedAnchorAgreement={relatedAnchorAgreement}
        pageable={pageable}
        paginate={paginate}
      />
    </>
  );
}

export default AnchorDealerRegistrationConfirmedDetailPage;
