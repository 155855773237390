import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { COLLATERAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';

import AnchorBulkFinancingDetailAdditionalDocuments from './sections/additional-documents';
import AnchorBulkFinancingCompanyInformation from './sections/bulk-financing-information';
import AnchorBulkFinancingDetailDesignatedBankAccountInformation from './sections/designated-bank-account-information';
import AnchorBulkFinancingDetailFinancingList from './sections/financing-list';
import AnchorBulkFinancingDetailRequestInformation from './sections/financing-request-information';
import useAnchorBulkFinancingConfirmationDetailController from './useAnchorBulkFinancingConfirmationDetailController';

function AnchorBulkFinancingConfirmationDetail() {
  const { t } = useTranslation();

  const {
    state,
    paginateRelatedFinancingList,
    relatedFinancingPageable,
    financingInfoCheckBoxState,
    bankAccountInfoCheckBoxState,
    isAuthorizerRequest,
    isOperatorRequest,
    handleCancelButtonClick,
    handleSubmitFinancingApplicationButtonClick,
  } = useAnchorBulkFinancingConfirmationDetailController();

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Confirmation_Details')} />
      {isAuthorizerRequest && (
        <GuideMessage
          message={[
            t('text:For_financing_application_the_Requested_Disbursement_Date_is_applied_uniformly'),
            t('text:The_anchor_user_has_requested_approval_for_the_financing_application_below'),
            t(
              'text:After_reviewing_the_financing_request_information_and_the_attached_files_approve_and_submit_the_financing_application_to_the_Financier',
            ),
          ]}
        />
      )}
      {state.bulkFinancingDetail.multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.CANCELLED && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_application_has_been_cancelled'),
            t('text:Please_check_the_reason_for_the_cancellation_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_CANCELLATION')}
          reason={state.bulkFinancingDetail.cancelReason}
        />
      )}

      <AnchorBulkFinancingCompanyInformation bulkFinancingDetailInfo={state.bulkFinancingDetail} />
      <AnchorBulkFinancingDetailRequestInformation
        bulkFinancingDetailInfo={state.bulkFinancingDetail}
        arList={state.bulkFinancingArList}
        invoiceList={state.bulkFinancingInvoiceList}
        isAuthorizerRequest={isAuthorizerRequest}
        financingInfoCheckBoxState={financingInfoCheckBoxState}
      />

      <AnchorBulkFinancingDetailDesignatedBankAccountInformation
        bulkFinancingDetailInfo={state.bulkFinancingDetail}
        isAuthorizerRequest={isAuthorizerRequest}
        bankAccountInfoCheckBoxState={bankAccountInfoCheckBoxState}
        showDisbursementAccountInfo={state.showDisbursementAccountInfo}
      />
      <AnchorBulkFinancingDetailAdditionalDocuments
        multipleAnchorLoanRequestId={state.bulkFinancingDetail.multipleAnchorLoanRequestId}
        loanEtcAttachmentListData={state.bulkFinancingDetail.anchorLoanEtcAttachment}
      />

      <AnchorBulkFinancingDetailFinancingList
        financingList={state.bulkFinancingRelatedFinancingList}
        paginate={paginateRelatedFinancingList}
        pageable={relatedFinancingPageable}
        isAr={state.bulkFinancingDetail.collateralType === COLLATERAL_TYPE.AR}
      />

      <div className="content-area flex-end mt-3">
        {isOperatorRequest && (
          <Button
            size={ButtonSizeEnum.LG}
            onClick={handleCancelButtonClick}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
        )}
        {isAuthorizerRequest && (
          <>
            <Button
              size={ButtonSizeEnum.LG}
              onClick={handleCancelButtonClick}
              className="me-2"
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={handleSubmitFinancingApplicationButtonClick}>
              {t('text:Submit_Financing_Application')}
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default AnchorBulkFinancingConfirmationDetail;
