import type Pageable from 'models/Pageable';
import type { UsageSummaryVO, UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { SearchFiBillingDTO } from './requests';

export const requestFiBillingList = async (
  pageNumber: number,
  rowCount: number,
  searchValue?: SearchFiBillingDTO,
): Promise<Pageable<UsageSummaryVOModel[]>> => {
  return await http.get<Pageable<UsageSummaryVO[]>>({
    url: API_FI.BILLING.BILLING_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
};
