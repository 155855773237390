import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { isNull } from 'lodash-es';

import TermSpread from 'components/TermSpread';
import Form, { SizeType } from 'components/stateless/Form';
import { FormErrorMessage } from 'components/stateless/Form/ErrorMessage';
import { RadioGroup } from 'components/stateless/Form/RadioComponents';
import Radio from 'components/stateless/Form/RadioComponents/Radio';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';
import { ANCHOR_FINANCING_CONDITION, BASIS_INTEREST_TYPE, INTEREST_REPAYMENT_TYPE } from 'enums';

import useInterestRateController from './useInterestRateController';

const InterestRate = () => {
  const { t } = useTranslation();

  const {
    isEditable,
    isAr,
    errors,
    termSpreadList,
    useTermSpread,
    handleTermSpreadRadioChange,
    handleBasisInterestTypeChange,
    handleInterestRepaymentTypeChange,
  } = useInterestRateController();

  return (
    <>
      <SubTitle>{t('text:INTEREST_RATE_APR')}</SubTitle>
      <Content>
        <Row>
          <Form.Control name="basisInterestType" required>
            <Form.Label position="top">{t('text:Base_Interest_Rate')} (%)</Form.Label>
            <Form.Radio onChange={handleBasisInterestTypeChange}>
              <Form.Option
                value={BASIS_INTEREST_TYPE.FIXED}
                label={t('text:Fixed')}
                render={(isParentReadOnly, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Form.Control name="basisInterestRate" required>
                          <Form.Label>{t('text:Fixed_Interest_Rate')} (%)</Form.Label>
                          <Cell className="auto-height">
                            <Form.NumberInput
                              className={clsx({ 'error-input-border': errors?.basisInterestRate })}
                              rightUnit="%"
                              numberType="float"
                              fieldSize={SizeType.SM}
                              textAlign="text-right"
                              parentReadOnly={isParentReadOnly}
                              readOnly={isParentRadioUnChecked}
                              showError={false}
                            />
                            {errors?.basisInterestRate && <FormErrorMessage error={errors.basisInterestRate} />}
                          </Cell>
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
              <Form.Option
                value={BASIS_INTEREST_TYPE.CUSTOM}
                label={t('text:Variable')}
                render={(isParentReadOnly, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Form.Control name="basisInterestBankCode" required>
                          <Form.Label>{t('text:Interest_Type_Name')}</Form.Label>
                          <Form.Input
                            fieldSize={SizeType.MD}
                            placeholder={t('text:eg_SOFR')}
                            readOnly={isParentRadioUnChecked}
                            parentReadOnly={isParentReadOnly}
                          />
                        </Form.Control>
                      </Row>
                      <Row>
                        <Form.Control name="basisInterestTerm" required>
                          <Form.Label>{t('text:Base_Interest_Term')}</Form.Label>
                          <Form.Input
                            fieldSize={SizeType.MD}
                            placeholder={t('text:eg_03M')}
                            readOnly={isParentRadioUnChecked}
                            parentReadOnly={isParentReadOnly}
                          />
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
            </Form.Radio>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="creditInterestRate">
            <Form.Label>{t('text:Credit_Spread')} (%)</Form.Label>
            <Form.NumberInput rightUnit="%" numberType="float" fieldSize={SizeType.SM} textAlign="text-right" />
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="preferentialInterestRate">
            <Form.Label>{t('text:Interest_Rate_Adjustment')} (%)</Form.Label>
            <Form.NumberInput
              rightUnit="%"
              numberType="float"
              fieldSize={SizeType.SM}
              allowNegative
              textAlign="text-right"
            />
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="termSpreadList" required>
            <Form.Label position="top">{t('text:Term_Spread')} (%)</Form.Label>
            <RadioGroup
              name="useTermSpread"
              onChange={handleTermSpreadRadioChange}
              stateValue={String(useTermSpread)}
              error={errors?.termSpreadList}
              showError={isNull(useTermSpread)}
              isEditable={isEditable}
            >
              <Radio label={t('text:Applied')} value="true" />
              <Radio label={t('text:Not_Applied')} value="false" />
            </RadioGroup>
          </Form.Control>
          <TermSpread useTermSpread={useTermSpread} termSpreadData={termSpreadList} useAgreementInterface={false} />
        </Row>
        {isAr && (
          <Row>
            <Form.Control name="anchorFinancingCondition" required>
              <Cell>
                <Form.Label position="top">{t('text:Financing_Enabled_AR')}</Form.Label>
                <Form.Description>{t('text:Select_the_status_of_ARs_that_can_apply_for_financing')}</Form.Description>
              </Cell>
              <Form.Radio>
                <Form.Option value={ANCHOR_FINANCING_CONDITION.ALL} label={t('text:All_registered_AR')} />
                <Form.Option
                  value={ANCHOR_FINANCING_CONDITION.WITH_DEALER_AGREEMENT}
                  label={t('text:AR_with_partner_agreement')}
                />
                <Form.Option
                  value={ANCHOR_FINANCING_CONDITION.AFTER_PARTNER_FINANCING_CREATED}
                  label={t('text:AR_applied_for_partners_financing')}
                />
              </Form.Radio>
            </Form.Control>
          </Row>
        )}
        <Row>
          <Form.Control name="interestRepaymentType" required>
            <Cell>
              <Form.Label position="top">{t('text:Interest_Payment_Method')}</Form.Label>
              <Form.Description>
                {t('text:Select_when_the_financing_interest_is_repaid_at_financing_disbursement_repayment_or_monthly')}
              </Form.Description>
            </Cell>
            <Form.Radio onChange={handleInterestRepaymentTypeChange}>
              <Form.Option
                value={INTEREST_REPAYMENT_TYPE.EARLIER}
                label={t('text:Prepayment')}
                render={() => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Form.Control required>
                          <Form.Label>{t('text:Select_disbursement_method')}</Form.Label>
                          <Form.Radio name="showNetDisbursementAmount" isEditable={isEditable}>
                            <Form.Option
                              label={
                                t('text:The_prepayment_interest_is_deducted_upon_disbursement') +
                                ` (${t('text:Advance_Disbursement')})`
                              }
                              value="true"
                              tooltipOptions={{
                                id: 'showNetDisbursementAmount-true',
                                content: (
                                  <>
                                    <div>{t('text:Principal_Amount')} ⓐ</div>
                                    <div>{t('text:Prepayment_Interest')} ⓑ</div>
                                    <div>-----------------------------------------------------</div>
                                    <div>{t('text:Advance_Disbursement')} ⓒ = ⓐ - ⓑ</div>
                                    <br />
                                    <div>
                                      {t('text:If_you_select_this_option,_The_disbursement_amount_will_be_displayed')}
                                    </div>
                                  </>
                                ),
                              }}
                            />
                            <Form.Option
                              label={t('text:Interest_is_charged_after_disbursement') + ` (${t('text:Default')})`}
                              value="false"
                            />
                          </Form.Radio>
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
              <Form.Option value={INTEREST_REPAYMENT_TYPE.LATTER} label={t('text:Postpayment')} />
              <Form.Option
                value={INTEREST_REPAYMENT_TYPE.MONTHLY}
                label={t('text:Monthly')}
                render={(isParentReadOnly, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle={true}>
                      <Row>
                        <Form.Control name="monthlyInterestRepaymentDate" required>
                          <Form.Label>{t('text:Date')}</Form.Label>
                          <Cell>
                            <Form.NumberInput
                              className={clsx({ 'error-input-border': errors?.monthlyInterestRepaymentDate })}
                              numberType="integer"
                              fieldSize={SizeType.SM}
                              textAlign="text-right"
                              readOnly={isParentRadioUnChecked}
                              parentReadOnly={isParentReadOnly}
                              showError={false}
                            />
                            {errors?.monthlyInterestRepaymentDate && (
                              <FormErrorMessage error={errors.monthlyInterestRepaymentDate} />
                            )}
                          </Cell>
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
            </Form.Radio>
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default InterestRate;
