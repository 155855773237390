import { isNil } from 'lodash-es';

import { AUTHORITY_TYPE, CURRENCY_TYPE, FACTORING_NOTICE_CONFIRMATION_STATUS, stringToEnum } from 'enums';

export interface FactoringNoticeVO {
  factoringNoticeId: number;
  sentDateTime: string;
  partnerName: string;
  anchorName: string;
  confirmedUserName: string;
  confirmedUserAuthority: string;
  currencyType: string;
  loanId: number;
  requestedAmount: number;
  multipleLoanRequestId: number;
  totalRequestedAmount: number;
  totalReceiverCount: number;
  receivedUserCount: number;
}

export interface FactoringNoticeVOModel extends FactoringNoticeVO {
  confirmedUserAuthority: AUTHORITY_TYPE;
  currencyType: CURRENCY_TYPE;
  confirmationStatus: FACTORING_NOTICE_CONFIRMATION_STATUS;
}

export function formattingToFactoringNoticeVOModel(data: FactoringNoticeVO): FactoringNoticeVOModel {
  return {
    ...data,
    confirmedUserAuthority: stringToEnum(AUTHORITY_TYPE, data.confirmedUserAuthority),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    confirmationStatus: isNil(data.confirmedUserName)
      ? FACTORING_NOTICE_CONFIRMATION_STATUS.WAITING
      : FACTORING_NOTICE_CONFIRMATION_STATUS.CONFIRMED,
  };
}
