import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import useMounted from 'hooks/useMounted';
import type { BranchVOModel } from 'models/vo/BranchVO';
import { requestBranchInterface } from 'utils/http/api/financier/branches';
import useModal from 'utils/modal/useModal';

interface UpdateBranchModalPropsType {
  branchData: BranchVOModel | undefined;
  getUpdatedBranchData: (data: BranchVOModel) => void;
  modalId: number;
}

function UpdateBranchModal({ branchData, getUpdatedBranchData, modalId }: UpdateBranchModalPropsType) {
  const { t } = useTranslation();
  const [updatedBranchInterfaceData, setUpdatedBranchInterfaceData] = useState<BranchVOModel>();
  const mounted = useMounted();
  const modal = useModal();

  useEffect(() => {
    if (mounted) {
      fetchData();
    }
  }, [mounted]);

  const fetchData = async () => {
    if (!branchData || !branchData.branchCode) return;

    try {
      const fetchedBranchInterfaceData = await requestBranchInterface(branchData.branchCode);
      setUpdatedBranchInterfaceData(fetchedBranchInterfaceData);
      getUpdatedBranchData(fetchedBranchInterfaceData);
    } catch (e: any) {
      modal.close(modalId);
      modal.show(e);
    }
  };

  const getUpdatedInputClassName = (originData: any, updatedData: any) => {
    return `information-form__input ${originData !== updatedData ? 'updated__bg__input' : ''}`;
  };

  return (
    <>
      <div className="changed-data-form d-flex justify-content-end mb-2">
        <span className="changed-data" />
        <p>{t('text:Changed_data')}</p>
      </div>
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormValue
              className={getUpdatedInputClassName(branchData?.branchCode, updatedBranchInterfaceData?.branchCode)}
              label={t('text:Branch_Code')}
              value={updatedBranchInterfaceData?.branchCode}
            />
            <FormValue
              className={getUpdatedInputClassName(branchData?.branchName, updatedBranchInterfaceData?.branchName)}
              label={t('text:Branch_Name')}
              value={updatedBranchInterfaceData?.branchName}
            />
          </div>
          <div className="row">
            <FormValue
              className={getUpdatedInputClassName(
                branchData?.branchBusinessCode,
                updatedBranchInterfaceData?.branchBusinessCode,
              )}
              label={t('text:Business_Registration_Number')}
              value={updatedBranchInterfaceData?.branchBusinessCode}
            />
            <FormValue
              col={3}
              className={getUpdatedInputClassName(branchData?.telephone, updatedBranchInterfaceData?.telephone)}
              label={t('text:Telephone')}
              value={updatedBranchInterfaceData?.telephone}
            />
            <FormValue
              col={3}
              className={getUpdatedInputClassName(branchData?.fax, updatedBranchInterfaceData?.fax)}
              label={t('text:Fax')}
              value={updatedBranchInterfaceData?.fax}
            />
          </div>
          <div className="row">
            <FormValue
              className={getUpdatedInputClassName(branchData?.managerName, updatedBranchInterfaceData?.managerName)}
              label={t('text:Legal_Representative_Name')}
              value={updatedBranchInterfaceData?.managerName}
            />
            <FormValue
              col={3}
              className={getUpdatedInputClassName(
                branchData?.managerPosition,
                updatedBranchInterfaceData?.managerPosition,
              )}
              label={t('text:Legal_Representative_Title')}
              value={updatedBranchInterfaceData?.managerPosition}
            />
            <FormValue
              col={3}
              className={getUpdatedInputClassName(branchData?.managerEmail, updatedBranchInterfaceData?.managerEmail)}
              label={t('text:Legal_Representative_Email')}
              value={updatedBranchInterfaceData?.managerEmail}
            />
          </div>
          <div className="row">
            <FormValue
              col={12}
              className={getUpdatedInputClassName(branchData?.address, updatedBranchInterfaceData?.address)}
              label={t('text:Registered_Office')}
              value={updatedBranchInterfaceData?.address}
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default UpdateBranchModal;
