import type Pageable from 'models/Pageable';
import type {
  WaitingDealerAgreementDetailVO,
  WaitingDealerAgreementDetailVOModel,
} from 'models/vo/WaitingDealerAgreementDetailVO';
import { formattingToWaitingDealerAgreementDetailVOModel } from 'models/vo/WaitingDealerAgreementDetailVO';
import type { WaitingDealerAgreementVO, WaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import { formattingToWaitingDealerAgreementVOModel } from 'models/vo/WaitingDealerAgreementVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemWaitingPartnerAgreementListRequest } from './requests';

export async function requestSystemWaitingPartnerAgreementList(
  pageNumber: number,
  rowCount: number,
  searchData: SystemWaitingPartnerAgreementListRequest,
): Promise<Pageable<WaitingDealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<WaitingDealerAgreementVO[]>>({
    url: API_SY.WAITING_DEALER_AGREEMENTS.WAITING_DEALER_AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchData,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingDealerAgreementVOModel(data)),
  };
}

export async function requestSystemWaitingDealerAgreementDetail(
  waitingDealerAgreementId: number,
): Promise<WaitingDealerAgreementDetailVOModel> {
  const response = await http.get<WaitingDealerAgreementDetailVO>({
    url: API_SY.WAITING_DEALER_AGREEMENTS.WAITING_DEALER_AGREEMENT_DETAIL(waitingDealerAgreementId),
  });

  return formattingToWaitingDealerAgreementDetailVOModel(response);
}
