import { CURRENCY_TYPE, POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS, stringToEnum } from 'enums';

export interface PotentialPartnerFinancingApplicationDetailVO {
  potentialPartnerFinancingApplicationId: number;
  potentialPartnerFinancingApplicationStatus: string;
  name: string;
  taxCode: string;
  anchorClientName: string;
  anchorClientCode: string;
  anchorAgreementContractNo: string;
  currencyType: string;
  financierName: string;
  requestBranchCode: string;
  requestBranchName: string;
  reviewedBranchId: number;
  reviewedBranchCode: string;
  reviewedBranchName: string;
  assignedBranchCode: string;
  assignedBranchName: string;
  createdDateTime: string;
  latestDocumentUpdateDateTime: string;
  rejectedDateTime: string;
  cancelledDateTime: string;
  rejectedReason: string;
}

export interface PotentialPartnerFinancingApplicationDetailVOModel
  extends PotentialPartnerFinancingApplicationDetailVO {
  potentialPartnerFinancingApplicationStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
  currencyType: CURRENCY_TYPE;
}

export function formattingToPotentialPartnerFinancingApplicationDetailVOModel(
  data: PotentialPartnerFinancingApplicationDetailVO,
): PotentialPartnerFinancingApplicationDetailVOModel {
  return {
    ...data,
    potentialPartnerFinancingApplicationStatus: stringToEnum(
      POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS,
      data.potentialPartnerFinancingApplicationStatus,
    ),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
