import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVO, AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { formattingToAnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type {
  AnchorAgreementListForArsRequest,
  AnchorAgreementListForPartnersRequest,
  AnchorAgreementListRequest,
} from './requests';

// 앵커 기업 약정 목록 조회
export async function requestAnchorAgreementsList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorAgreementListRequest,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_AC.ANCHOR_AGREEMENTS.AGREEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const anchorAgreementList: AnchorAgreementVOModel[] = response.content.map((data: AnchorAgreementVO) =>
    formattingToAnchorAgreementVOModel(data),
  );
  const anchorAgreementPage: Pageable<AnchorAgreementVOModel[]> = {
    ...response,
    content: anchorAgreementList,
  };

  return anchorAgreementPage;
}

// 앵커 기업 약정 단일 조회
export async function requestAnchorAgreementDetail(anchorAgreementId: number): Promise<AnchorAgreementDetailVOModel> {
  const response = await http.get<AnchorAgreementDetailVO>({
    url: API_AC.ANCHOR_AGREEMENTS.AGREEMENT_DETAIL(anchorAgreementId),
  });

  return formattingToAnchorAgreementDetailVOModel(response);
}

export async function requestAnchorAgreementListForArs(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorAgreementListForArsRequest,
): Promise<Pageable<AnchorAgreementVOModel[]>> {
  const response = await http.get<Pageable<AnchorAgreementVO[]>>({
    url: API_AC.ANCHOR_AGREEMENTS.AGREEMENT_LIST_FOR_ARS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  const anchorAgreementListForArs = response.content.map(data => formattingToAnchorAgreementVOModel(data));

  return {
    ...response,
    content: anchorAgreementListForArs,
  };
}

export async function requestAnchorAgreementListForPartners(
  data: AnchorAgreementListForPartnersRequest,
): Promise<AnchorAgreementVOModel[]> {
  const response = await http.get<AnchorAgreementVO[]>({
    url: API_AC.ANCHOR_AGREEMENTS.AGREEMENT_LIST_FOR_PARTNERS,
    data,
  });

  return response.map(data => formattingToAnchorAgreementVOModel(data));
}
