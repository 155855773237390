import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';

import AnchorDealerCompanyInfoListCompletedTab from './tabs/AnchorDealerCompanyInfoListCompletedTab';
import AnchorDealerCompanyInfoListWaitingTab from './tabs/AnchorDealerCompanyInfoListWaitingTab';
import { useAnchorDealerCompanyInfoListPageState } from './useAnchorDealerCompanyInfoListPageState';

function AnchorDealerCompanyInfoListPage() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();

  const { state, fetchAll, useForms, pageableObjects, fetchUtils } = useAnchorDealerCompanyInfoListPageState();

  const { waitingListResult, completedListResult } = state;
  const { waitingListPageable, completedListPageable } = pageableObjects;
  const { waitingListFetchUtils, completedListFetchUtils } = fetchUtils;

  const { waitingListSearchForm, completedListSearchForm } = useForms;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  return (
    <>
      <HeaderTitle title={t('text:Dealer_Company_Information')} />
      <GuideMessage
        message={[
          t('text:Search_the_Dealer_company_you_wish_to_find_using_the_Search_filters_below'),
          t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Dealer'),
        ]}
      />
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:Waiting_for_Approval'),
            tabItemCount:
              (waitingListSearchForm.getValues('approvalTypes') as any) === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL
                ? 0
                : state.waitingListResultForTabCount?.totalElements ?? 0,
          },
          {
            tabName: t('text:Registration_Completed'),
            tabItemCount: completedListResult.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...waitingListSearchForm} key="waiting">
            <AnchorDealerCompanyInfoListWaitingTab
              {...waitingListFetchUtils()}
              pageable={waitingListPageable}
              data={waitingListResult}
            />
          </FormProvider>,
          <FormProvider {...completedListSearchForm} key="completed">
            <AnchorDealerCompanyInfoListCompletedTab
              {...completedListFetchUtils()}
              pageable={completedListPageable}
              data={completedListResult}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default AnchorDealerCompanyInfoListPage;
