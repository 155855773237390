const API_CM = {
  BANK_CODE: {
    BANK_CODE_LIST: 'v1/cm/bank-code',
    BANK_CODE_DETAIL: (bankCodeId: number) => `v1/cm/bank-code/${bankCodeId}`,
  },
  FINANCIER_COMMON_SETTING: {
    SETTING_DETAIL: (financierId: number) => `v1/cm/financier-common-setting/${financierId}`,
    FINANCIER_SUPPORT: 'v1/cm/financier-common-setting/financier-support',
  },
  FINANCIER_INTERFACE_SETTING: {
    FINANCIER_INTERFACE_SETTING: 'v1/cm/financier-interface-setting/enablement',
  },
  FISCAL_YEAR_SETTING: {
    CURRENT_USER: 'v1/cm/fiscal-year-setting/current-user',
    SETTING_BY_DEALER_AGREEMENT_ID: (dealerAgreementId: number) =>
      `v1/cm/fiscal-year-setting/by-dealer-agreement-id/${dealerAgreementId}`,
  },
  NOTIFICATIONS: {
    NOTIFICATION_LIST: 'v1/cm/notifications',
    NEW_USER_NOTIFICATION: 'v1/cm/notifications/new-user-notification',
    NOTIFICATION_READ_STATUS: 'v1/cm/notifications/notification-read-status',
  },
  REGISTRABLE_LIMIT: {
    LIMIT_BY_DEALER_AGREEMENT: (dealerAgreementId: number) =>
      `v1/cm/registrable-limit/${dealerAgreementId}/limit-by-dealer-agreement`,
  },
  SIGN_IN: {
    SIGN_IN: '/sign-in',
    SIGN_OUT: '/sign-out',
  },
  TEMP_AR_SUMMARIES: {
    TEMP_AR_SUMMARIES: 'v1/cm/temp-ar-summaries',
    TEMP_AR_SUMMARY_DETAIL: (anchorAgreementId: number) => `v1/cm/temp-ar-summaries/${anchorAgreementId}`,
  },
  TEMP_INVOICE_SUMMARIES: {
    TEMP_INVOICE_SUMMARIES: 'v1/cm/temp-invoice-summaries',
    TEMP_INVOICE_DETAIL: (dealerAgreementId: number) => `v1/cm/temp-invoice-summaries/${dealerAgreementId}`,
  },
  USERS: {
    CURRENT_USER: 'v1/cm/users/current-user',
    RESET_PASSWORD: 'v1/cm/users/current-user/reset:password',
  },
  TIME: {
    SEVER_TIME: 'v1/an/server-time',
  },
};

export default API_CM;
