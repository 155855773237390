import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollToTopOfElement } from 'utils/scroll';

type LocationType = {
  pathname: string;
  search: string;
};

export function ScrollToTop({ container = 'page-content' }) {
  const location = useLocation();
  const locationRef = useRef<LocationType>(location);

  const isSearchParamsChangedOnly = useCallback(
    () => location.pathname === locationRef.current.pathname && location.search !== locationRef.current.search,
    [location],
  );

  useEffect(() => {
    if (isSearchParamsChangedOnly()) return;

    locationRef.current = location;

    if (location.pathname) scrollToTopOfElement(container);
  }, [container, isSearchParamsChangedOnly, location]);

  return null;
}
