import type { CURRENCY_TYPE } from 'enums';

export interface SuccessInvoiceForRequestMultipleAnchorLoanVO {
  successInvoiceId: number;
  invoiceNumber: string;
  dealerId: number;
  dealerName: string;
  dealerTaxCode: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerClientTaxCode: string;
  invoiceAmount: number;
  invoiceIssuedDate: string;
  invoiceSettlementDate: string;
  desiredDisburseDateByPartner: string;
  collateralizedAmountByPartner: number;
  maxLtvAmount: number;
  currencyType: CURRENCY_TYPE;
}

export interface SuccessInvoiceForRequestMultipleAnchorLoanVOModel
  extends SuccessInvoiceForRequestMultipleAnchorLoanVO {}

export function formattingToSuccessInvoiceForRequestMultipleAnchorLoanVOModel(
  data: SuccessInvoiceForRequestMultipleAnchorLoanVO,
): SuccessInvoiceForRequestMultipleAnchorLoanVOModel {
  return {
    ...data,
  };
}
