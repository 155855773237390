import './PPTextField.scss';

interface PPTextFieldItemProps {
  children: JSX.Element | JSX.Element[];
}

function PPTextFieldItem({ children }: PPTextFieldItemProps) {
  return <div className="pp-text-field-item">{children}</div>;
}

export default PPTextFieldItem;
