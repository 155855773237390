import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { isEmpty } from 'lodash-es';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import Select from 'components/stateless/Select/Select';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import useModal from 'utils/modal/useModal';
import { getProgramTypeText } from 'utils/text';

import useSearchInterfaceByKeyUnionModalController from './useSearchInterfaceByKeyUnionModalController';

interface SearchInterfaceByKeyUnionModalProps {
  modalId: number;
  collateralType: COLLATERAL_TYPE;
  getInterfaceData(data?: AnchorAgreementDetailVOModel): void;
}

const SearchInterfaceByKeyUnionModal = ({
  modalId,
  collateralType,
  getInterfaceData,
}: SearchInterfaceByKeyUnionModalProps) => {
  const { t } = useTranslation(['format']);
  const { disableConfirmBtn } = useModal();

  const { errorMessage, searchedInterfaceData, handleSearchButtonClick, errors, register } =
    useSearchInterfaceByKeyUnionModalController(collateralType);

  useEffect(() => {
    getInterfaceData(searchedInterfaceData);
  }, [getInterfaceData, searchedInterfaceData]);

  useEffect(() => {
    disableConfirmBtn(modalId, isEmpty(searchedInterfaceData));
  }, [disableConfirmBtn, modalId, searchedInterfaceData]);

  const {
    contractNo,
    currencyType,
    financierClientCode,
    financierClientName,
    startDate,
    expiryDate,
    branchCode,
    branchName,
  } = searchedInterfaceData ?? {};

  return (
    <form data-testid="formId">
      <div className="m-2 mb-3">
        <div className="d-flex">
          <div className="me-3">
            <label className="information-form__label">{t('text:Program_Type')}</label>
            <div className="information-form__input">{getProgramTypeText(collateralType)}</div>
          </div>
          <div className="me-3">
            <label className="information-form__label">{t('text:Anchor_Client_Code')}</label>
            <input
              className={clsx('information-form__input bg-sub100', {
                'error-input-border text-bold-brick-red': errors.anchorFinancierClientCode,
              })}
              type="text"
              name="anchorFinancierClientCode"
              ref={register}
              placeholder={t('text:Please_type_here')}
            />
          </div>

          <div className="me-3" style={{ width: '90px' }}>
            <label className="information-form__label">{t('text:Currency')}</label>

            <Select
              className={clsx('information-form__input bg-sub100', {
                'error-input-border text-bold-brick-red': errors.currencyType,
              })}
              name="currencyType"
              ref={register}
              placeholderOptions={{ show: true, text: t('text:Select') }}
              selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE')}
            />
          </div>
          <div className="me-3">
            <label className="information-form__label">{t('text:Division_Code')}</label>
            <input
              className={clsx('information-form__input bg-sub100', {
                'error-input-border text-bold-brick-red': errors.anchorDivision,
              })}
              type="text"
              name="anchorDivision"
              ref={register}
              placeholder={t('text:Please_type_here')}
            />
            <div className="information-form__description">
              {t('text:Enter_this_field_only_if_the_agreement_has_a_division_code')}
            </div>
          </div>
          <div style={{ marginTop: '18px' }}>
            <Button onClick={handleSearchButtonClick} size={ButtonSizeEnum.SM} bold>
              {t('text:Search')}
            </Button>
          </div>
        </div>
        {errorMessage !== '' && <div className="text-bold-brick-red p-2">{errorMessage}</div>}
      </div>

      <FormBorder>
        <FormContents>
          <div className="row">
            <FormValue
              label={t('text:Anchor_Master_Agreement_Number')}
              className="information-form__input"
              value={contractNo}
            />
            <FormValue label={t('text:Currency')} className="information-form__input" value={currencyType} />
          </div>
          <div className="row">
            <FormValue label={t('text:Client_Code')} className="information-form__input" value={financierClientCode} />
            <FormValue label={t('text:Anchor_Name')} className="information-form__input" value={financierClientName} />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Effective_Date')}
              className="information-form__input"
              value={startDate}
              format="date"
            />
            <FormValue
              label={t('text:Expiration_Date')}
              className="information-form__input"
              value={expiryDate}
              format="date"
            />
          </div>
          <div className="row">
            <FormValue
              label={t('text:Responsible_Branch_Code')}
              className="information-form__input"
              value={branchCode}
            />
            <FormValue
              label={t('text:Responsible_Branch_Name')}
              className="information-form__input"
              value={branchName}
            />
          </div>
        </FormContents>
      </FormBorder>
    </form>
  );
};

export default SearchInterfaceByKeyUnionModal;
