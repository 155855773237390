import { BASIS_INTEREST_TYPE, CURRENCY_TYPE, SUCCESS_AR_STATUS, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface SuccessArForRequestMultipleLoanVO {
  successArId: number;
  arNumber: string;
  arAmount: string;
  arIssuedDate: string;
  settlementDate: string;
  maxLtvAmount: string;
  collateralizedAmount: string;
  remainingArAmount: string;
  currencyType: string;
}

export interface SuccessArForRequestMultipleLoanVOModel extends SuccessArForRequestMultipleLoanVO {
  arAmount: BigNumber;
  maxLtvAmount: BigNumber;
  collateralizedAmount: BigNumber;
  remainingArAmount: BigNumber;
  currencyType: CURRENCY_TYPE;
}

export function formattingToSuccessArForRequestMultipleLoanVOModel(
  data: SuccessArForRequestMultipleLoanVO,
): SuccessArForRequestMultipleLoanVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
