import type { HTMLAttributes } from 'react';
import type React from 'react';

interface PropsType extends HTMLAttributes<HTMLTableRowElement> {
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
}

const Tr = ({ onClick, children, className = '', ...props }: PropsType) => {
  return (
    <tr className={className} onClick={onClick} {...props}>
      {children}
    </tr>
  );
};

export default Tr;
