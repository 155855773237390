import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { ENTERPRISE_TYPE, FINANCIER_CLIENT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { requestSystemFinancierBranchList } from 'utils/http/api/system/branches';
import { requestSystemEnterpriseDetail } from 'utils/http/api/system/enterprises';
import { requestSystemFinancierClientList } from 'utils/http/api/system/financier-clients';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const BRANCH_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Branch_Name'),
    },
    {
      headerText: t('text:Branch_Code'),
    },
    {
      headerText: t('text:Business_Registration_Number'),
    },
    {
      headerText: t('text:Fax'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Title'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
  ];

  const REGISTERED_CLIENT_INFORMATION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Financier_Enterprise_ID'),
    },
    {
      headerText: t('text:Client_Name'),
    },
    {
      headerText: t('text:Client_Code'),
    },
    {
      headerText: t('text:Client_Type'),
    },
    {
      headerText: t('text:Financier_Client_ID'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return {
    mounted,
    modal,
    t,
    BRANCH_TABLE_HEADERS,
    REGISTERED_CLIENT_INFORMATION_TABLE_HEADERS,
  };
};

function SystemMonitorPlatformEnterpriseDetail(): JSX.Element {
  const { mounted, modal, t, BRANCH_TABLE_HEADERS, REGISTERED_CLIENT_INFORMATION_TABLE_HEADERS } = getConstants();

  const { enterpriseId } = useParams() as any;

  const [systemMonitorPlatformEnterpriseData, setSystemMonitorPlatformEnterpriseData] = useState<EnterpriseVOModel>();
  const [branchPage, setBranchPage] = useState<Pageable<BranchVOModel[]>>();
  const [registeredClientInformationPage, setRegisteredClientInformationPage] =
    useState<Pageable<FinancierClientVOModel[]>>();

  const { pageable: branchPageable, setPageable: setBranchPageable } = usePageable();
  const { pageable: registeredClientInformationPageable, setPageable: setRegisteredClientInformationPageable } =
    usePageable();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const systemMonitorPlatformEnterpriseData = await requestSystemEnterpriseDetail(enterpriseId);
      let branchPage: Pageable<BranchVOModel[]> | undefined = undefined;
      let registeredClientInformationPage: Pageable<FinancierClientVOModel[]> | undefined = undefined;

      if (systemMonitorPlatformEnterpriseData.enterpriseType === ENTERPRISE_TYPE.FI) {
        branchPage = await requestSystemFinancierBranchList(branchPageable.currentPage, branchPageable.sizePerPage, {
          financierId: systemMonitorPlatformEnterpriseData.enterpriseId,
        });
      } else if (
        systemMonitorPlatformEnterpriseData.enterpriseType === ENTERPRISE_TYPE.AC ||
        systemMonitorPlatformEnterpriseData.enterpriseType === ENTERPRISE_TYPE.DE
      ) {
        registeredClientInformationPage = await requestSystemFinancierClientList(
          registeredClientInformationPageable.currentPage,
          registeredClientInformationPageable.sizePerPage,
          {
            enterpriseId: enterpriseId,
            financierClientType:
              systemMonitorPlatformEnterpriseData.enterpriseType === ENTERPRISE_TYPE.AC
                ? FINANCIER_CLIENT_TYPE.ANCHOR
                : FINANCIER_CLIENT_TYPE.DEALER,
          },
        );
      }

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemMonitorPlatformEnterpriseData(systemMonitorPlatformEnterpriseData);
        setBranchPage(branchPage);
        branchPage && setBranchPageable(branchPage);
        setRegisteredClientInformationPage(registeredClientInformationPage);
        registeredClientInformationPage && setRegisteredClientInformationPageable(registeredClientInformationPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const branchPaginate = async (selectedPageNumber: number, selectedRowCount: number): Promise<void> => {
    try {
      const branchPage = await requestSystemFinancierBranchList(selectedPageNumber, selectedRowCount, {
        financierId: systemMonitorPlatformEnterpriseData?.enterpriseId,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setBranchPage(branchPage);
        setBranchPageable(branchPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const registeredClientInformationPaginate = async (
    selectedPageNumber: number,
    selectedRowCount: number,
  ): Promise<void> => {
    try {
      const registeredClientInformationPage = await requestSystemFinancierClientList(
        selectedPageNumber,
        selectedRowCount,
        {
          enterpriseId: enterpriseId,
          financierClientType:
            systemMonitorPlatformEnterpriseData?.enterpriseType === ENTERPRISE_TYPE.AC
              ? FINANCIER_CLIENT_TYPE.ANCHOR
              : FINANCIER_CLIENT_TYPE.DEALER,
        },
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setRegisteredClientInformationPage(registeredClientInformationPage);
        setRegisteredClientInformationPageable(registeredClientInformationPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderBranchPageTableBody = (): JSX.Element[] | undefined => {
    return branchPage?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.branchName} />
        <Td data={data.branchCode} />
        <Td data={data.branchBusinessCode} />
        <Td data={data.fax} />
        <Td data={data.managerName} />
        <Td data={data.managerPosition} />
        <Td data={data.managerEmail} />
        <Td data={data.address} />
      </Tr>
    ));
  };

  const renderRegisteredClientInformationPageTableBody = (): JSX.Element[] | undefined => {
    return registeredClientInformationPage?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.financierName} />
        <Td data={data.financierId} />
        <Td data={data.financierClientName} />
        <Td data={data.financierClientCode} />
        <Td data={t(`code:financier-client-type.${data.financierClientType}`)} />
        <Td data={data.financierClientId} />
        <TdLink
          path={
            data.financierClientType === FINANCIER_CLIENT_TYPE.ANCHOR
              ? ROUTES_SY.MONITOR_ANCHOR.COMPANY_DETAIL_BUILD_PATH(data.financierClientId)
              : ROUTES_SY.MONITOR_PARTNER.COMPANY_DETAIL_BUILD_PATH(data.financierClientId)
          }
        />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={systemMonitorPlatformEnterpriseData?.enterpriseName} />
      <div className="content-area">
        <SectionTitle title={t('text:Enterprise_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Enterprise_ID')} value={systemMonitorPlatformEnterpriseData?.enterpriseId} />
              <FormValue
                label={t('text:Enterprise_Name')}
                value={systemMonitorPlatformEnterpriseData?.enterpriseName}
              />
            </div>
            <div className="row">
              <FormValue col={3} label={t('text:Tax_Code')} value={systemMonitorPlatformEnterpriseData?.taxCode} />
              <FormValue
                col={3}
                label={t('text:Company_Registration_Number')}
                value={systemMonitorPlatformEnterpriseData?.businessCode}
              />
              <FormValue col={3} label={t('text:Telephone')} value={systemMonitorPlatformEnterpriseData?.telephone} />
              <FormValue col={3} label={t('text:Fax')} value={systemMonitorPlatformEnterpriseData?.fax} />
            </div>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Legal_Representative_Name')}
                value={systemMonitorPlatformEnterpriseData?.representativeName}
              />
              <FormValue
                col={3}
                label={t('text:Legal_Representative_Title')}
                value={systemMonitorPlatformEnterpriseData?.representativePosition}
              />
              <FormValue
                col={3}
                label={t('text:Legal_Representative_Mobile')}
                value={systemMonitorPlatformEnterpriseData?.representativeMobile}
              />
              <FormValue
                col={3}
                label={t('text:Legal_Representative_Email')}
                value={systemMonitorPlatformEnterpriseData?.representativeEmail}
              />
            </div>
            <div className="row">
              <FormValue
                col={12}
                label={t('text:Registered_Office_Address')}
                value={systemMonitorPlatformEnterpriseData?.address}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {systemMonitorPlatformEnterpriseData?.enterpriseType === ENTERPRISE_TYPE.FI && (
        <div className="content-area">
          <SectionTitle title={t('text:Branch')} />
          <TableBorder>
            <TableHeader header={BRANCH_TABLE_HEADERS} />
            <TableBody numOfCol={BRANCH_TABLE_HEADERS.length}>{renderBranchPageTableBody()}</TableBody>
          </TableBorder>
          <Pagination pageable={branchPageable} paginate={branchPaginate} />
        </div>
      )}
      {(systemMonitorPlatformEnterpriseData?.enterpriseType === ENTERPRISE_TYPE.AC ||
        systemMonitorPlatformEnterpriseData?.enterpriseType === ENTERPRISE_TYPE.DE) && (
        <div className="content-area">
          <SectionTitle title={t('text:Registered_Client_Information')} />
          <TableBorder>
            <TableHeader header={REGISTERED_CLIENT_INFORMATION_TABLE_HEADERS} />
            <TableBody numOfCol={REGISTERED_CLIENT_INFORMATION_TABLE_HEADERS.length}>
              {renderRegisteredClientInformationPageTableBody()}
            </TableBody>
          </TableBorder>
          <Pagination pageable={registeredClientInformationPageable} paginate={registeredClientInformationPaginate} />
        </div>
      )}
    </>
  );
}

export default SystemMonitorPlatformEnterpriseDetail;
