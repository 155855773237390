import { useTranslation } from 'react-i18next';

import { EmailNotificationSettingForm } from 'components/email-notification-setting/EmailNotificationSettingForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';

import useAnchorMyAccountEmailNotificationSettingController from './useAnchorMyAccountEmailNotificationSettingController';

function AnchorMyAccountEmailNotificationSetting() {
  const { t } = useTranslation(['format']);
  const {
    isAdmin,
    factoringEnable,
    selectedCommonAlertSetting,
    selectedVendorAlertSetting,
    selectedDealerAlertSetting,
    updateEmailSettingHandler,
  } = useAnchorMyAccountEmailNotificationSettingController();

  return (
    <>
      <BackHeaderTitle title={t('text:Email_Notification_Setting')} />
      <GuideMessage
        message={[
          t('text:You_can_set_whether_to_receive_emails'),
          isAdmin && t('text:Anchor_Admin_users_cannot_change_whether_or_not_to_receive_emails_designated_as_required'),
          t('text:The_financiers_can_change_their_email_settings_based_on_their_policies'),
          isAdmin &&
            factoringEnable &&
            t('text:If_you_would_like_to_change_your_required_email_notification_settings_please_contact_financier'),
        ]}
      />
      {selectedCommonAlertSetting && (
        <div className="content-area">
          <EmailNotificationSettingForm
            title={t('text:Common')}
            emailSettingData={selectedCommonAlertSetting}
            updateEmailSettingHandler={updateEmailSettingHandler}
          />
        </div>
      )}

      {selectedVendorAlertSetting && (
        <div className="content-area">
          <EmailNotificationSettingForm
            title={t('text:Vendor_Finance_Program')}
            emailSettingData={selectedVendorAlertSetting}
            updateEmailSettingHandler={updateEmailSettingHandler}
          />
        </div>
      )}
      {selectedDealerAlertSetting && (
        <div className="content-area">
          <EmailNotificationSettingForm
            title={t('text:Dealer_Finance_Program')}
            emailSettingData={selectedDealerAlertSetting}
            updateEmailSettingHandler={updateEmailSettingHandler}
          />
        </div>
      )}
    </>
  );
}

export default AnchorMyAccountEmailNotificationSetting;
