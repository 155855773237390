import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { TermsOfUseDetailVO } from 'models/vo/TermsOfUseDetailVO';
import API_SY from 'utils/http/api/system';
import {
  requestSystemTOUVersionDownload,
  requestSystemTOUVersionUpdate,
} from 'utils/http/api/system/terms-of-use-versions';
import { requestSystemTOUDetail, requestSystemTOURegister } from 'utils/http/api/system/terms-of-uses';
import type { SystemTermsOfUsesRequest } from 'utils/http/api/system/terms-of-uses/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import SystemTouDetailUploadNewVersionModal from './SystemTouDetailUploadNewVersionModal';

function SystemTouDetailPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const mounted = useMounted();
  const { id: modalId, show: showModal } = useModal();

  const { termsOfUseId } = useParams() as any;
  const [touDetail, setTouDetail] = useState<TermsOfUseDetailVO>();
  const [selectedTOUVersionId, setSelectedTOUVersionId] = useState<number | null>();

  const isFinancier = touDetail?.enterpriseType === ENTERPRISE_TYPE.FI;

  useEffect(() => {
    if (mounted) {
      fetchSyTOUDetail();
    }

    return () => {};
  }, [mounted]);

  const fetchSyTOUDetail = async (): Promise<void> => {
    try {
      const touDetailResponse = await requestSystemTOUDetail(termsOfUseId);

      setTouDetail(touDetailResponse);
    } catch (error) {
      showModal(error);
    }
  };

  const handleClickUpdateActivateStatus = async (activate: boolean) => {
    try {
      await requestSystemTOUVersionUpdate(selectedTOUVersionId!, activate);
      await fetchSyTOUDetail();
    } catch (e) {
      showModal(e);
    }
  };

  const handleClickUploadNewVersionButton = () => {
    const getSubmitData = {} as SystemTermsOfUsesRequest;

    const handleClickRegisterButton = async () => {
      const formData: SystemTermsOfUsesRequest = {
        ...getSubmitData,
        termsOfUseId,
        ...(isFinancier && { financierId: touDetail?.enterpriseId }),
      };
      try {
        await requestSystemTOURegister(formData);
        await fetchSyTOUDetail();
      } catch (e) {
        showModal(e);
      }
    };
    showModal(
      <>
        <SystemTouDetailUploadNewVersionModal getSubmitData={getSubmitData} modalId={modalId} />
      </>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => await handleClickRegisterButton(),
      },
    );
  };

  const handleDownloadButtonClick = async (termsOfUseVersionId: number) => {
    try {
      await requestSystemTOUVersionDownload(termsOfUseVersionId, {
        type: 'ATTACHMENT',
      });
    } catch (e) {
      showModal(e);
    }
  };

  const handleCheckChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    e.target.checked ? setSelectedTOUVersionId(id) : setSelectedTOUVersionId(null);
  };

  const TOU_HISTORY_TABLE_HEADERS = [
    {
      headerText: '',
    },
    {
      headerText: t('text:Updated_Time'),
    },
    {
      headerText: t('text:TOU_file'),
    },
    {
      headerText: t('text:Version'),
    },
    {
      headerText: t('text:Active_Status'),
    },
    {
      headerText: t('text:Description'),
    },
    {
      headerText: t('text:Download'),
    },
  ];

  const renderTOUHistoryTableBody = () => {
    const getDownloadTOUVersionInlineAddress = (termsOfUseVersionId: number) => {
      return `${process.env.REACT_APP_BASE_URL}${API_SY.TERMS_OF_USE_VERSIONS.DOWNLOAD(
        termsOfUseVersionId,
      )}?type=INLINE`;
    };

    return touDetail?.termsOfUseVersionList?.map((data, index) => (
      <Tr key={index}>
        <Td>
          <div className="text-center">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={e => handleCheckChange(e, data.termsOfUseVersionId)}
              checked={selectedTOUVersionId === data.termsOfUseVersionId}
            />
          </div>
        </Td>
        <Td data={data.updatedDateTime} format="datetime" />
        <Td>
          <a
            className="link-text-color"
            target={`tou-${data.termsOfUseVersionId}`}
            href={getDownloadTOUVersionInlineAddress(data.termsOfUseVersionId)}
          >
            {data.attachmentName}
          </a>
        </Td>
        <Td data={data.version} />
        <Td data={data.activated ? t('text:On') : t('text:Off')} />
        <Td data={data.description} />
        <Td>
          <DownloadButton onClick={() => handleDownloadButtonClick(data.termsOfUseVersionId)} />
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={touDetail?.enterpriseName} />
      <div className="content-area">
        <SectionTitle title={t('text:Financier_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} value={touDetail?.enterpriseName} />
              <FormValue label={t('text:Financing_ID')} value={touDetail?.enterpriseId}>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  onClick={() =>
                    history.push(ROUTES_SY.MANAGEMENT.FINANCIER_DETAIL_BUILD_PATH(touDetail?.enterpriseId!))
                  }
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </FormValue>
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')}>
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.BLUE}
            onClick={() => handleClickUpdateActivateStatus(true)}
            disabled={isNil(selectedTOUVersionId)}
          >
            {t('text:Activate')}
          </Button>

          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.RED}
            onClick={() => handleClickUpdateActivateStatus(false)}
            disabled={isNil(selectedTOUVersionId)}
          >
            {t('text:Deactivate')}
          </Button>

          <Button size={ButtonSizeEnum.SM} onClick={handleClickUploadNewVersionButton}>
            {t('text:Upload_New_Version')}
          </Button>
        </SectionTitle>
        <TableBorder>
          <TableHeader header={TOU_HISTORY_TABLE_HEADERS} />
          <TableBody numOfCol={TOU_HISTORY_TABLE_HEADERS.length}>{renderTOUHistoryTableBody()}</TableBody>
        </TableBorder>
      </div>
    </>
  );
}

export default SystemTouDetailPage;
