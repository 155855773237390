import { createContext, useContext } from 'react';

import type { COLLATERAL_TYPE } from 'enums';
import type { DealerAgreementCommonVOModel } from 'models/convertor/dealerAgreement';

export type AgreementType = Omit<
  DealerAgreementCommonVOModel,
  | 'termSpreadList'
  | 'anchorClientId'
  | 'mainContractNo'
  | 'anchorClientName'
  | 'anchorAgreementStartDate'
  | 'anchorAgreementExpiryDate'
  | 'anchorUserList'
>;

type AgreementStateType = {
  agreement: AgreementType;
  isSearchedAgreement: boolean;
  isFirstRegisteredWaitingAgreement: boolean;
  isPartnerInfoExist: boolean;
  updateAgreement: (agreement: Partial<AgreementType>) => void;
  isMaturityExtension: string | null;
  updateIsSearchedAgreement: (isSearchedAgreement: boolean) => void;
  updateIsMaturityExtension: (isMaturityExtension: string) => void;
  updateAllAgreement: (agreement: DealerAgreementCommonVOModel) => void;
  fetchSavedAgreement: (collateralType: COLLATERAL_TYPE) => Promise<DealerAgreementCommonVOModel>;
  fetchWaitingAgreement: (waitingDealerAgreementId: number) => Promise<DealerAgreementCommonVOModel | undefined>;
  fetchAgreementDetail: (dealerAgreementId: number) => Promise<DealerAgreementCommonVOModel | undefined>;
};

export const AgreementContext = createContext<AgreementStateType | null>(null);

export const useAgreementContext = () => {
  const context = useContext(AgreementContext);
  if (!context) {
    throw Error('useAgreementContext should be used within AgreementContext.Provider');
  }

  return context;
};
