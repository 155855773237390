import type { AUTHORITY_TYPE, WAITING_AR_APPROVAL_PHASE_STATUS } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface ArPhaseVO {
  arSummaryId: number;
  waitingArApprovalPhaseId: number;
  count: number;
  amount: string;
  arApprovalPhaseStatus: WAITING_AR_APPROVAL_PHASE_STATUS;
  approveEnterpriseId: number;
  approveEnterpriseName: string;
  approveUserId: number;
  approveUserLoginId: string;
  approveUserName: string;
  approveUserAuthorityType: AUTHORITY_TYPE;
  createdDateTime: string;
  inProgress: boolean;
}

export interface ArPhaseVOModel extends ArPhaseVO {
  amount: BigNumber;
}

export function formattingToArPhaseVOModel(data: ArPhaseVO): ArPhaseVOModel {
  const { amount } = data;

  return {
    ...data,
    amount: sliceZeroDecimal(amount),
  };
}
