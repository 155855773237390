import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash-es';

import useProperty from 'hooks/useProperty';
import type { CurrentUserChangePassword } from 'utils/http/api/common/users/request';
import useModal from 'utils/modal/useModal';

import PasswordValidationBox from '../../PasswordValidationBox/PasswordValidationBox';

function ChangePasswordModal({ state, modalId }: { state: CurrentUserChangePassword; modalId: number }) {
  const { t } = useTranslation();
  const modal = useModal();
  const passwordChangeForm = useForm<CurrentUserChangePassword>({});
  const getProperty = useProperty<CurrentUserChangePassword>();
  const { newPassword, confirmPassword, currentPassword } = passwordChangeForm.watch();

  const sameOrOverEightPassword = (password: string) => {
    return password?.length >= 8;
  };

  const hasUpperAndLowerCasePassword = (password: string) => {
    return password !== password?.toUpperCase() && password !== password?.toLowerCase();
  };

  const hasNumberPassword = (password: string) => {
    return password?.replace(/[^\d]+/g, '').length > 0;
  };

  const checkSamePassword = (newPassword: string, confirmPassword: string) => {
    return newPassword === confirmPassword;
  };

  const isCurrentPasswordEmpty = () => {
    return isEmpty(currentPassword);
  };

  const canSubmit = () => {
    if (!sameOrOverEightPassword(newPassword)) return false;

    if (!hasUpperAndLowerCasePassword(newPassword)) return false;

    if (!hasNumberPassword(newPassword)) return false;

    if (!checkSamePassword(newPassword, confirmPassword)) return false;

    if (isCurrentPasswordEmpty()) return false;

    return true;
  };

  useEffect(() => {
    modal.disableConfirmBtn(modalId, !canSubmit());
    Object.assign(state, passwordChangeForm.getValues());
  }, [newPassword, confirmPassword, currentPassword]);

  return (
    <form>
      <div className="modal-input-form mb-3">
        <label className="information-form__label star">{t('text:Current_Password')}</label>

        <input
          className="information-form__input bg-sub100 w-100"
          name={getProperty('currentPassword')}
          autoComplete="off"
          ref={passwordChangeForm.register}
          type="password"
          placeholder={t('text:Please_type_here')}
        />
      </div>

      <div className="modal-input-form mb-3">
        <label className="information-form__label star">{t('text:New_Password')}</label>

        <input
          className="information-form__input bg-sub100 w-100"
          name={getProperty('newPassword')}
          autoComplete="off"
          ref={passwordChangeForm.register}
          type="password"
          placeholder={t('text:Please_type_here')}
        />
      </div>
      <div className="modal-input-form mb-3">
        <label className="information-form__label star">{t('text:Confirm_New_Password')}</label>
        <input
          className="information-form__input bg-sub100 w-100"
          name={getProperty('confirmPassword')}
          autoComplete="off"
          ref={passwordChangeForm.register}
          type="password"
          placeholder={t('text:Please_type_here')}
        />
        {!checkSamePassword(newPassword, confirmPassword) && (
          <div className="text-brick-red mt-1 bg-white">
            <FontAwesomeIcon icon={faBan} /> {t('text:Password_do_not_match')}
          </div>
        )}
      </div>
      <div className="mt-4">
        <PasswordValidationBox newPassword={newPassword} />
      </div>
    </form>
  );
}

export default ChangePasswordModal;
