﻿import { BkavCAPlugin, ObjPdfSigner } from './BkavCAPlugin';

// SignPDF
var dllName = 'BkavCA,BkavCAv2S';
var PDF = {
  SignPDF: function (
    pdfFileIn,
    pdfFileOut,
    serial,
    nameSigner,
    licenseKey,
    urlIcon,
    offsetX,
    offsetY,
    pagePositionOfDS,
    signingTime,
    jsCallback,
  ) {
    try {
      // var iCheck = checkBrowser();
      var objPDF = new ObjPdfSigner();
      if (pdfFileIn == '' || undefined == pdfFileIn) {
        alert('No PDF data');
        return;
      }
      objPDF.Base64String = pdfFileIn;
      objPDF.CertificateSerialNumber = serial;
      objPDF.SigningType = 1;
      objPDF.Signer = nameSigner;
      objPDF.urlIcon = urlIcon;
      objPDF.offsetX = offsetX;
      objPDF.offsetY = offsetY;
      // objPDF.showDigitalSignature = '1'; // Có thiện stamp digital signature
      objPDF.pagePositionOfDS = pagePositionOfDS;
      objPDF.SigningTime = signingTime;
      objPDF.FunctionCallback = jsCallback;

      BkavCAPlugin.SetLicenseKey(licenseKey);
      BkavCAPlugin.SetHashAlgorithm(0); // HASH_ALGORITHM.SHA1
      BkavCAPlugin.SignPDF(objPDF); // doi voi Extension can bat su kien tuong ung de nhan lai ket qua
    } catch (e) {
      alert(e);
    }
  },
};

export { PDF };
