import type Pageable from 'models/Pageable';
import type { AccountTransactionVO, AccountTransactionVOModel } from 'models/vo/AccountTransactionVO';
import { formattingToAccountTransactionVOModel } from 'models/vo/AccountTransactionVO';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { AccountTransactionRequest } from './requests';

export async function requestAccountTransactionList(
  anchorAgreementId: number,
  pageNumber: number,
  rowCount: number,
  accountTransactionRequest: AccountTransactionRequest,
): Promise<Pageable<AccountTransactionVOModel[]>> {
  const response = await http.get<Pageable<AccountTransactionVO[]>>({
    url: API_FI.ACCOUNT_TRANSACTIONS.ACCOUNT_TRANSACTION_LIST(anchorAgreementId),
    data: {
      pageNumber,
      rowCount,
      ...accountTransactionRequest,
    },
  });

  const accountTransactionPageResponse = response.content.map(item => formattingToAccountTransactionVOModel(item));

  return {
    ...response,
    content: accountTransactionPageResponse,
  };
}

export const requestAnchorSettlementAccountBalance = async (anchorAgreementId: number): Promise<BigNumber> => {
  const response = await http.get<BigNumber>({
    url: API_FI.ACCOUNT_TRANSACTIONS.SETTLEMENT_ACCOUNT_BALANCE(anchorAgreementId),
  });

  return sliceZeroDecimal(response);
};
