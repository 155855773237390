import { useEffect } from 'react';

import { useFormContext } from 'components/stateless/Form';
import { LOAN_LIMIT_CHECK_TYPE } from 'enums';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';

const useFinancingLimitController = () => {
  const {
    methods: { setValue, watch, clearErrors },
    isEditable,
  } = useFormContext();

  const {
    agreement: { loanLimitCheckType },
  } = useAgreementViewModel();

  const { currencyType, loanLimitCheckType: watchedLoanLimitCheckType } = watch();

  const handleFinancingLimitCheckPointChange = () => {
    setValue('loanLimitAmount', null);
    clearErrors('loanLimitAmount');
  };

  useEffect(() => {
    if (loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE) setValue('loanLimitAmount', null);
  }, [isEditable, loanLimitCheckType, setValue]);

  return { currencyType, loanLimitCheckType: watchedLoanLimitCheckType, handleFinancingLimitCheckPointChange };
};

export default useFinancingLimitController;
