import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { convertToAnchorFinancingOptionCommonVOModel } from 'models/convertor/anchorFinancingOption';
import type { AnchorAgreementDetailVO } from 'models/vo/AnchorAgreementDetailVO';
import type { TermSpreadVOModel } from 'models/vo/TermSpreadVO';
import { requestAnchorAgreementDetail } from 'utils/http/api/anchor/anchor-agreements';
import { requestAnchorFinancingOptionDetail } from 'utils/http/api/anchor/anchor-financing-opitons';
import useModal from 'utils/modal/useModal';

import { AnchorAgreementInfoContext, initialAnchorAgreementInfoState } from './AnchorAgreementInfoContext';
import { FinancingOptionContext } from './FinancingOptionContext';
import { TermSpreadContext } from './TermSpreadContext';

import type { AnchorAgreementInfoType } from './AnchorAgreementInfoContext';
import type { FinancingOptionType } from './FinancingOptionContext';

type FinancingOptionProviderPropsType = {
  children: ReactNode;
};

const FinancingOptionProvider = ({ children }: FinancingOptionProviderPropsType) => {
  const { show: showModal } = useModal();

  const [anchorFinancingOption, setAnchorFinancingOption] = useState<FinancingOptionType>({} as any);
  const [termSpreadList, setTermSpreadList] = useState<TermSpreadVOModel[] | null>(null);
  const [anchorAgreementInfo, setAnchorAgreementInfo] = useState<AnchorAgreementInfoType>(
    initialAnchorAgreementInfoState as AnchorAgreementInfoType,
  );

  const updateFinancingOption = (agreement: Partial<FinancingOptionType>) =>
    setAnchorFinancingOption(prevAgreement => ({
      ...prevAgreement,
      ...agreement,
    }));

  const updateTermSpreadList = (termSpreadList: TermSpreadVOModel[]) => setTermSpreadList(termSpreadList);

  const updateAnchorAgreementInfo = (anchorAgreementInfo?: Partial<AnchorAgreementInfoType>) => {
    setAnchorAgreementInfo({
      financierClientName: anchorAgreementInfo?.financierClientName ?? '',
      contractNo: anchorAgreementInfo?.contractNo ?? '',
      startDate: anchorAgreementInfo?.startDate ?? '',
      expiryDate: anchorAgreementInfo?.expiryDate ?? '',
      division: anchorAgreementInfo?.division ?? '',
      divisionName: anchorAgreementInfo?.divisionName ?? '',
      branchCode: anchorAgreementInfo?.branchCode ?? '',
      branchName: anchorAgreementInfo?.branchName ?? '',
      branchTelephone: anchorAgreementInfo?.branchTelephone ?? '',
      branchFax: anchorAgreementInfo?.branchFax ?? '',
      branchAddress: anchorAgreementInfo?.branchAddress ?? '',
      currencyType: anchorAgreementInfo?.currencyType!,
      collateralIssuedLimitAmount: anchorAgreementInfo?.collateralIssuedLimitAmount ?? '',
      collateralIssuedLimitCheck: anchorAgreementInfo?.collateralIssuedLimitCheck!,
      loanLimitCheck: anchorAgreementInfo?.loanLimitCheck!,
      loanLimitAmount: anchorAgreementInfo?.loanLimitAmount!,
    });
  };

  const updateAllFinancingOption = useCallback((agreement: FinancingOptionType) => {
    const {
      termSpreadList,
      anchorClientName,
      anchorAgreementContractNo,
      anchorAgreementStartDate,
      anchorAgreementExpiryDate,
      anchorAgreementDivision,
      anchorAgreementDivisionName,
      branchCode,
      branchName,
      branchTelephone,
      branchFax,
      branchAddress,
      currencyType,
      ...restSavedAgreement
    } = agreement;

    updateTermSpreadList(termSpreadList);
    updateFinancingOption(restSavedAgreement);
  }, []);

  const fetchFinancingOptionDetail = useCallback(
    async (anchorFinancingOptionId: number) => {
      try {
        const agreementDetail = await requestAnchorFinancingOptionDetail(anchorFinancingOptionId);

        return convertToAnchorFinancingOptionCommonVOModel(agreementDetail);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal, updateAllFinancingOption],
  );

  const fetchAnchorAgreementDetail = useCallback(
    async (anchorAgreementId: number) => {
      try {
        return await requestAnchorAgreementDetail(anchorAgreementId);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal, updateAllFinancingOption],
  );

  const updateAnchorAgreementDetail = useCallback(async (anchorAgreementData: AnchorAgreementDetailVO) => {
    updateAnchorAgreementInfo(anchorAgreementData);
  }, []);

  const agreementValue = useMemo(
    () => ({
      anchorFinancingOption,
      updateFinancingOption,
      updateAllFinancingOption,
      fetchFinancingOptionDetail,
      fetchAnchorAgreementDetail,
    }),
    [anchorFinancingOption, updateAllFinancingOption, fetchFinancingOptionDetail, fetchAnchorAgreementDetail],
  );

  const termSpreadValue = useMemo(
    () => ({
      termSpreadList,
      updateTermSpreadList,
    }),
    [termSpreadList],
  );

  const anchorAgreementInfoValue = useMemo(
    () => ({
      anchorAgreementInfo,
      updateAnchorAgreementInfo,
      fetchAnchorAgreementDetail,
      updateAnchorAgreementDetail,
    }),
    [anchorAgreementInfo],
  );

  return (
    <FinancingOptionContext.Provider value={agreementValue}>
      <TermSpreadContext.Provider value={termSpreadValue}>
        <AnchorAgreementInfoContext.Provider value={anchorAgreementInfoValue}>
          {children}
        </AnchorAgreementInfoContext.Provider>
      </TermSpreadContext.Provider>
    </FinancingOptionContext.Provider>
  );
};

export default FinancingOptionProvider;
