import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';

interface SearchInterfaceByIdModalProps {
  searchedInterfaceData?: AnchorAgreementDetailVOModel;
}

const SearchInterfaceByIdModal = ({ searchedInterfaceData }: SearchInterfaceByIdModalProps) => {
  const { t } = useTranslation(['format']);

  const {
    contractNo,
    currencyType,
    financierClientCode,
    financierClientName,
    startDate,
    expiryDate,
    branchCode,
    branchName,
  } = searchedInterfaceData ?? {};

  return (
    <FormBorder>
      <FormContents>
        <div className="row">
          <FormValue
            label={t('text:Anchor_Master_Agreement_Number')}
            className="information-form__input"
            value={contractNo}
          />
          <FormValue label={t('text:Currency')} className="information-form__input" value={currencyType} />
        </div>
        <div className="row">
          <FormValue label={t('text:Client_Code')} className="information-form__input" value={financierClientCode} />
          <FormValue label={t('text:Anchor_Name')} className="information-form__input" value={financierClientName} />
        </div>
        <div className="row">
          <FormValue
            label={t('text:Effective_Date')}
            className="information-form__input"
            value={startDate}
            format="date"
          />
          <FormValue
            label={t('text:Expiration_Date')}
            className="information-form__input"
            value={expiryDate}
            format="date"
          />
        </div>
        <div className="row">
          <FormValue label={t('text:Responsible_Branch_Code')} className="information-form__input" value={branchCode} />
          <FormValue label={t('text:Responsible_Branch_Name')} className="information-form__input" value={branchName} />
        </div>
      </FormContents>
    </FormBorder>
  );
};

export default SearchInterfaceByIdModal;
