import { COLLATERAL_TYPE, CURRENCY_TYPE, stringToEnum } from 'enums';

export interface DashboardPartnerPerformanceVO {
  collateralType: string;
  currencyType: string;
  requestedLoanCount: number;
  requestedLoanAmount: number;
  disbursedLoanCount: number;
  disbursedLoanAmount: number;
  overdueLoanCount: number;
  overdueLoanAmount: number;
  loanableCount: number;
  loanableAmount: number;
}

export interface DashboardPartnerPerformanceVOModel extends DashboardPartnerPerformanceVO {
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
}

export function formattingToDashboardPartnerPerformanceVOModel(
  data: DashboardPartnerPerformanceVO,
): DashboardPartnerPerformanceVOModel {
  return {
    ...data,
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
