export interface ArCommissionSpreadVO {
  minimumAmountRange: number;
  rate: number;
}

export interface ArCommissionSpreadVOModel extends ArCommissionSpreadVO {}

export function formattingToArCommissionSpreadVOModel(data: ArCommissionSpreadVO): ArCommissionSpreadVOModel {
  return {
    ...data,
  };
}
