import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormSelect } from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import SearchBranchModal from 'components/stateless/Modal/financier/SearchBranchModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { SignInModel } from 'models/SignInModel';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { UserVOModel } from 'models/vo/UserVO';
import SearchEmployeeModal from 'pages/financier/settings/user/register/modal/SearchEmployeeModal';
import { formErrorHandler } from 'utils/error/manager';
import { requestCurrentUser } from 'utils/http/api/common/users';
import { requestFinancierUserRegister } from 'utils/http/api/financier/users';
import type { FinancierUserRegisterRequest } from 'utils/http/api/financier/users/request';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType, getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function FinancierUserRegister() {
  const [userInterfaceData, setUserInterfaceData] = useState<UserVOModel>();

  const mounted = useMounted();
  const history = useHistory();
  const { t } = useTranslation();
  const modal = useModal();
  const signInModel: SignInModel | null = getSignIn();
  const getProperty = useProperty<FinancierUserRegisterRequest>();
  const [interfaceEnable] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.USER));
  const isAdmin = signInModel?.authorityType === AUTHORITY_TYPE.ADMIN;

  const { register, setValue, getValues, errors, setError, clearErrors, reset } = useForm<FinancierUserRegisterRequest>(
    {
      mode: 'onSubmit',
      shouldFocusError: true,
    },
  );

  useEffect(() => {
    if (mounted) {
      if (signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER && !interfaceEnable) {
        fetchCurrentUser();
      }
    }
  }, [mounted]);

  async function fetchCurrentUser() {
    try {
      const currentUser = await requestCurrentUser();
      ReactDOM.unstable_batchedUpdates(() => {
        setValue('branchName', currentUser.branchName);
        setValue('branchCode', currentUser.branchCode);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const isReadOnly = (parameterName: string) => {
    if (interfaceEnable) {
      if (!userInterfaceData) return true;

      if (!(userInterfaceData as any)[parameterName]) return false;

      return true;
    } else {
      return false;
    }
  };

  const searchBranchModalOptions: ModalOptions = {
    modalType: ModalType.ALERT,
    modalSize: ModalSize.XL,
    title: t('text:Search_Branch'),
    closeBtnText: t('text:Close'),
  };

  const setForm = (data: UserVOModel) => {
    const textCutter = (data: string | undefined): string | undefined => {
      if (data && (data.includes(' ') || data.includes('\t') || data.includes('\n'))) {
        const newText = data
          .split('')
          .filter((t: string) => t !== ' ' && t !== '\t' && t !== '\n')
          .join('');

        return newText;
      }

      return data;
    };

    reset({
      employeeCode: textCutter(data.employeeCode),
      userName: data.userName,
      telephone: data.telephone,
      email: data.email,
      branchCode: data.branchCode,
      branchName: data.branchName,
      authorityType: data.authorityType,
    });
  };

  const showSearchEmployeeModal = () => {
    modal.show(<SearchEmployeeModal state={{} as UserVOModel} />, {
      modalType: ModalType.CONFIRM,
      modalSize: ModalSize.XL,
      title: t('text:Search_Employee'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Apply'),
      confirmBtnCb: (data: any) => {
        if (!data.state.employeeCode) return;

        ReactDOM.unstable_batchedUpdates(() => {
          setForm(data.state);
          setUserInterfaceData(data.state);
        });
      },
    });
  };

  // bank admin 수기
  const setSelectedBranchInfo = (data: BranchVOModel) => {
    setValue('branchCode', data.branchCode);
    setValue('branchName', data.branchName);
  };

  const showSearchBranchModal = () => {
    modal.show(
      <SearchBranchModal getSelectedData={setSelectedBranchInfo} modalId={modal.id} />,
      searchBranchModalOptions,
    );
  };

  const onCancelEdit = (e: any) => {
    e.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnCb: () => history.push(ROUTES_FI.SETTINGS.USER_LIST),
      },
    );
  };

  const onUserRegisterSubmit = async (e: any) => {
    e.preventDefault();

    const data = getValues();
    try {
      requestDTOParser(data);
      await requestFinancierUserRegister(data);
      modal.show(
        <>
          <h6>{t('text:User_registration_is_complete')}</h6>
          <br />
          <h6>{t('text:Click_the_Send_button_to_send_an_invitation_mail')}</h6>
        </>,
        {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_FI.SETTINGS.USER_LIST),
        },
      );
    } catch (e) {
      modal.show(e);
      formErrorHandler<FinancierUserRegisterRequest>(e, setError, clearErrors);
    }
  };

  const userCodeHandler = (e: any) => {
    if (e.target.value.includes(' ') || e.target.value.includes('\t') || e.target.value.includes('\n')) {
      const newText = e.target.value
        .split('')
        .filter((t: string) => t !== ' ' && t !== '\t' && t !== '\n')
        .join('');

      setValue('employeeCode', newText);
    }
  };

  const renderHandWritingForm = () => {
    return (
      <div className="content-area">
        <form>
          <FormBorder editable={true}>
            <FormContents>
              <div className="row">
                <FormInput
                  label={t('text:User_Code')}
                  name={getProperty('employeeCode')}
                  ref={register}
                  requiredOptions={{ required: true }}
                  error={errors.employeeCode}
                  onChange={userCodeHandler}
                />
                <FormInput
                  label={t('text:User_Name')}
                  name={getProperty('userName')}
                  ref={register}
                  requiredOptions={{ required: true }}
                  error={errors.userName}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Telephone')}
                  name={getProperty('telephone')}
                  ref={register}
                  error={errors.telephone}
                />
                <FormInput
                  label={t('text:Email')}
                  name={getProperty('email')}
                  ref={register}
                  requiredOptions={{ required: true }}
                  error={errors.email}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Branch_Code')}
                  name={getProperty('branchCode')}
                  ref={register}
                  disabled={true}
                  requiredOptions={{ fixedRequired: isAdmin }}
                  error={errors.branchCode}
                >
                  {isAdmin && <Button onClick={showSearchBranchModal}>{t('text:Search')}</Button>}
                </FormInput>
                <FormInput
                  label={t('text:Branch_Name')}
                  name={getProperty('branchName')}
                  ref={register}
                  disabled={true}
                  requiredOptions={{ fixedRequired: isAdmin }}
                  error={errors.branchName}
                />
              </div>
              <div className="row">
                {isAdmin ? (
                  <FormSelect
                    label={t('text:Authority')}
                    name={getProperty('authorityType')}
                    ref={register}
                    selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', [
                      AUTHORITY_TYPE.AUTHORIZER,
                      AUTHORITY_TYPE.OPERATOR,
                    ])}
                    required={true}
                    error={errors.authorityType}
                    placeholderOptions={{ show: true }}
                  />
                ) : (
                  <>
                    {/* 보여주기 용도 */}
                    <FormInput disabled={true} label={t('text:Authority')} value={t('text:Operator')} />
                    <input
                      type="hidden"
                      name={getProperty('authorityType')}
                      defaultValue={AUTHORITY_TYPE.OPERATOR}
                      ref={register}
                    />
                  </>
                )}
              </div>
            </FormContents>
          </FormBorder>
          <div className="flex-end mt-4">
            <Button
              onClick={e => onCancelEdit(e)}
              size={ButtonSizeEnum.LG}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onUserRegisterSubmit} className="ms-2">
              {t('text:Register')}
            </Button>
          </div>
        </form>
      </div>
    );
  };

  const renderInterfaceForm = () => {
    return (
      <div className="content-area">
        <form>
          <FormBorder editable={true}>
            <FormContents>
              <div className="row">
                <div className="col-12">
                  <Button size={ButtonSizeEnum.LG} onClick={showSearchEmployeeModal}>
                    {t('text:Search_Employee')}
                  </Button>
                </div>
              </div>

              <div className="row">
                <FormInput
                  label={t('text:User_Code')}
                  name={getProperty('employeeCode')}
                  ref={register}
                  error={errors.employeeCode}
                  requiredOptions={{ required: true }}
                  onChange={userCodeHandler}
                  disabled={isReadOnly('employeeCode')}
                />
                <FormInput
                  label={t('text:User_Name')}
                  name={getProperty('userName')}
                  ref={register}
                  error={errors.userName}
                  requiredOptions={{ required: true }}
                  disabled={isReadOnly('userName')}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Telephone')}
                  name={getProperty('telephone')}
                  ref={register}
                  error={errors.telephone}
                  disabled={isReadOnly('telephone')}
                />
                <FormInput
                  label={t('text:Email')}
                  name={getProperty('email')}
                  ref={register}
                  error={errors.email}
                  requiredOptions={{ required: true }}
                  disabled={isReadOnly('email')}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Branch_Code')}
                  name={getProperty('branchCode')}
                  ref={register}
                  error={errors.branchCode}
                  requiredOptions={{ required: true }}
                  disabled={isReadOnly('branchCode')}
                />
                <FormInput
                  label={t('text:Branch_Name')}
                  name={getProperty('branchName')}
                  ref={register}
                  error={errors.branchName}
                  requiredOptions={{ required: true }}
                  disabled={isReadOnly('branchName')}
                />
              </div>
              <div className="row">
                {isAdmin ? (
                  <FormSelect
                    label={t('text:Authority')}
                    name={getProperty('authorityType')}
                    ref={register}
                    selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', [
                      AUTHORITY_TYPE.AUTHORIZER,
                      AUTHORITY_TYPE.OPERATOR,
                    ])}
                    required={true}
                    error={errors.authorityType}
                    placeholderOptions={{ show: true }}
                  />
                ) : (
                  <>
                    {/* 보여주기 용도 */}
                    <FormInput label={t('text:Authority')} value={t('text:Operator')} disabled />
                    <input
                      type="hidden"
                      name={getProperty('authorityType')}
                      value={AUTHORITY_TYPE.OPERATOR}
                      ref={register}
                    />
                  </>
                )}
              </div>
            </FormContents>
          </FormBorder>
          <div className="flex-end mt-4">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={e => onCancelEdit(e)}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.LG} onClick={onUserRegisterSubmit} className="ms-2">
              {t('text:Register')}
            </Button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:User_Registration')} />

      {/* 전문연동 메시지 */}
      {interfaceEnable ? (
        <GuideMessage
          message={[
            <Trans
              key="key"
              i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
              components={{ 1: <span className="asterisk" /> }}
            />,
            t('text:Click_on_the_“Search”_button_to_select_the_user’s_affiliated_branch'),
            t(
              'text:If_the_branch_information_cannot_be_found,_register_the_branch_before_proceeding_with_the_user_registration',
            ),
          ]}
        />
      ) : (
        // 수기 메시지
        <>
          {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ? (
            <GuideMessage
              message={[
                <Trans
                  key="key"
                  i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
                  components={{ 1: <span className="asterisk" /> }}
                />,
                t('text:Click_on_the_“Search”_button_to_select_the_user’s_affiliated_branch'),
                t(
                  'text:If_the_branch_information_cannot_be_found,_register_the_branch_before_proceeding_with_the_user_registration',
                ),
              ]}
            />
          ) : (
            <GuideMessage
              message={[
                <Trans
                  key="key"
                  i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
                  components={{ 1: <span className="asterisk" /> }}
                />,
                t('text:To_change_the_user_s_affiliated_branch_or_authority_level_contact_the_Financier_Admin_user'),
              ]}
            />
          )}
        </>
      )}
      {interfaceEnable ? renderInterfaceForm() : renderHandWritingForm()}
    </>
  );
}

export default FinancierUserRegister;
