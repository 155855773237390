import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import Icon from 'components/stateless/Icons';
import type { AUTHORITY_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';

import './SignUpComplete.scss';

function SignUpComplete() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const [locationState, errorHandlerOfLocationState] = useLocationState<{
    userId: string;
    userName: string;
    userAuthority: AUTHORITY_TYPE;
  }>(['userId', 'userName', 'userAuthority']);

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
    }
  }, [mounted, errorHandlerOfLocationState]);

  return (
    <>
      <PublicHeaderBar />
      <div className="align-middle row">
        <div className="signup-complete-form">
          <div className="icon-form">
            <Icon.Check />
          </div>
          <h2 className="common-content__title">{t('text:Sign_up_Complete')}</h2>
          <div className="signup-complete-content__message">
            {t('text:Your_ID_is')}
            <br />
            <span className="signup-complete-content__message__id">{locationState.userId}</span>
          </div>
          <div className="signup-complete-content__information">
            {t('text:User_Name')} :
            <span className="ms-2 signup-complete-content__information__data">{locationState.userName}</span>
            <br />
            {t('text:User_Authorization')} :
            <span className="ms-2 signup-complete-content__information__data">
              {t(`code:authority-type.${locationState.userAuthority}`)}
            </span>
          </div>
          <div className="w-100 text-center">
            <Button to="/" size={ButtonSizeEnum.LG} style={{ width: '300px' }}>
              {t('text:Go_to_sign_in')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpComplete;
