import type React from 'react';
import { useEffect, useState } from 'react';
import type { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Big from 'big.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { DOCUMENT_FORM_TYPE, LOAN_TERM_TYPE } from 'enums';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { requestDealerFinancierDocumentFormsAttachmentDownload } from 'utils/http/api/dealer/financier-document-forms';
import type { DealerSuccessArLoanRequest } from 'utils/http/api/dealer/success-ars/requests';
import {
  requestDealerTempAgreementAttachmentDownload,
  requestDealerTempApplicationAttachmentDownload,
} from 'utils/http/api/dealer/temp-loans';
import useModal from 'utils/modal/useModal';

import type { DealerArApplicationStep2State } from '../../useDealerArFinancingApplicationStep2State';

dayjs.extend(isBetween);
interface useFinancingRequestProps {
  dealerArApplicationStep2State: DealerArApplicationStep2State;
  setValue: UseFormMethods<DealerSuccessArLoanRequest>['setValue'];
  disbursementDate: string | undefined;
  requestedLoanAmount: number | undefined;
  loanAgreementAttachmentChangedState: {
    loanAgreementAttachmentChanged: boolean;
    setLoanAgreementAttachmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  };
  loanRequestAttachmentChangedState: {
    loanRequestAttachmentChanged: boolean;
    setLoanRequestAttachmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export function useFinancingRequest({
  dealerArApplicationStep2State,
  setValue,
  disbursementDate,
  requestedLoanAmount,
  loanAgreementAttachmentChangedState,
  loanRequestAttachmentChangedState,
}: useFinancingRequestProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const [applicationFileName, setApplicationFileName] = useState<string | undefined>(undefined);
  const [agreementFileName, setAgreementFileName] = useState<string | undefined>(undefined);

  const { dealerAgreementDetail, dealerArDetail, dealerArCalculateLoanRequest, dealerTempLoanByAr } =
    dealerArApplicationStep2State;
  const { financierId } = dealerArDetail;
  const { setLoanRequestAttachmentChanged } = loanRequestAttachmentChangedState;
  const { setLoanAgreementAttachmentChanged } = loanAgreementAttachmentChangedState;

  const calculatorBigNumber = new CalculatorBigNumber();

  useEffect(() => {
    setAgreementFileName(dealerTempLoanByAr.loanAgreementAttachmentName);
    setApplicationFileName(dealerTempLoanByAr.loanRequestAttachmentName);
  }, [dealerTempLoanByAr]);

  const disabledEstimateButton = (): boolean => {
    if (dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE && !disbursementDate) {
      return true;
    }

    if (!requestedLoanAmount) {
      return true;
    }

    return false;
  };

  const ltvCalculate = () => {
    const ltv =
      requestedLoanAmount === undefined
        ? 0
        : calculatorBigNumber.add(requestedLoanAmount).div(dealerArDetail?.arAmount).times(100).get(); // (requestedLoanAmount / arAmount) * 100

    if (Big(ltv).lt(0)) return 0;
    else
      return `${t('format:number', {
        value: ltv,
      })} %`;
  };

  const financingPeriodCalculate = (startDate: string | undefined, EndDate: string | undefined): string | undefined => {
    if (!disbursementDate) {
      return `- ${t('text:Days')}`;
    } else {
      return dayjs(EndDate).diff(dayjs(startDate).format('YYYY-MM-DD'), 'days').toString() + ' ' + t('text:Days');
    }
  };

  const loanTermTypeText = (loanTermUnit: number | undefined, loanTermType: string | undefined) => {
    if (loanTermType === LOAN_TERM_TYPE.DAILY) {
      return `${loanTermUnit} ${t('text:Days')}`;
    } else if (loanTermType === LOAN_TERM_TYPE.MONTHLY) {
      return `${loanTermUnit} ${t('text:Month')}`;
    }
  };

  // 서식/양식 다운로드
  const onClickApplicationDownloadForm = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerFinancierDocumentFormsAttachmentDownload(
        financierId,
        DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_REQUEST,
      );
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickAgreementDownloadForm = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerFinancierDocumentFormsAttachmentDownload(
        financierId,
        DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_AGREEMENT,
      );
    } catch (e) {
      modal.show(e);
    }
  };

  const returnTermSpreadRate = () => {
    const useTermSpread = !dealerAgreementDetail?.termSpreadList
      ? undefined
      : dealerAgreementDetail.termSpreadList.length > 0;

    if (!useTermSpread) {
      return '-';
    } else {
      if (dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE) {
        if (!disbursementDate || !dealerArCalculateLoanRequest) {
          return '-';
        } else {
          return t('format:number', {
            value: `${dealerArCalculateLoanRequest?.termSpreadRate}`,
          });
        }
      } else {
        return t('format:number', {
          value: `${dealerArCalculateLoanRequest?.termSpreadRate}`,
        });
      }
    }
  };

  const returnAmountWithCurrency = (amount: number | undefined) => {
    return `${t('format:number', {
      value: amount,
    })} ${dealerArDetail?.currencyType}`;
  };

  const onChangeApplicationFileName = (e: any) => {
    setLoanRequestAttachmentChanged(true);
    if (e.target?.files[0]?.name) setApplicationFileName(e.target.files[0].name);
  };

  const resetApplicationFileName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoanRequestAttachmentChanged(true);
    setValue('loanRequestAttachment', null);
    setApplicationFileName(undefined);
  };

  const onChangeAgreementFileName = (e: any) => {
    setLoanAgreementAttachmentChanged(true);
    if (e.target?.files[0]?.name) setAgreementFileName(e.target.files[0].name);
  };

  const resetAgreementFileName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoanAgreementAttachmentChanged(true);
    setValue('loanAgreementAttachment', null);
    setAgreementFileName(undefined);
  };

  const onClickApplicationFileDownLoad = async () => {
    try {
      await requestDealerTempApplicationAttachmentDownload(dealerTempLoanByAr.tempLoanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickAgreementFileDownLoad = async () => {
    try {
      await requestDealerTempAgreementAttachmentDownload(dealerTempLoanByAr.tempLoanId);
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    applicationFileName,
    agreementFileName,
    disabledEstimateButton,
    ltvCalculate,
    financingPeriodCalculate,
    loanTermTypeText,
    onClickApplicationDownloadForm,
    onClickAgreementDownloadForm,
    returnTermSpreadRate,
    onChangeApplicationFileName,
    resetApplicationFileName,
    onChangeAgreementFileName,
    resetAgreementFileName,
    onClickApplicationFileDownLoad,
    onClickAgreementFileDownLoad,
    returnAmountWithCurrency,
  };
}
