export const base64ToFile = (base64String: string, fileName: string): File => {
  // #1. decode
  const byteString = atob(base64String);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const byteArray = new Uint8Array(arrayBuffer);

  // #2. byteArray
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  return new File([arrayBuffer], fileName + '.pdf', { type: 'application/pdf' });
};

export const fileToFileList = (file: File): FileList => {
  const dataTransferObj = new DataTransfer();
  dataTransferObj.items.add(file);

  return dataTransferObj.files;
};
