import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import { isNil } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import { COLLATERAL_TYPE } from 'enums';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

type LtvStateType = {
  isLtvHundred: null | boolean;
  ltvValue: null | string;
};

const useFinancingLimitOfPartnersController = () => {
  const {
    methods: { register, errors, setValue, watch, clearErrors },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();

  const { useAgreementInterface, supportedCollateralType } = useExtraInformationViewModel();

  const {
    agreement: { loanLimitCheck, maxLtvRatio }, //agreement state에서 가져온 loanLimitCheck는 전문, 임시저장으로 값을 업데이트 했을 때 업데이트 된 값을 체크하기 위해 사용
    isSearchedAgreement,
  } = useAgreementViewModel();

  const isEditableField = useAgreementInterface ? isSearchedAgreement : isEditable;

  const { currencyType, loanLimitCheck: watchedLoanLimitCheck } = watch(); //watch로 가져오는 loanLimitCheck는 사용자가 입력해서 바꾼 값을 즉시 반영하여 Error 메세지를 보여주는 용도

  const [ltvState, setLtvState] = useState<LtvStateType>({
    isLtvHundred: null,
    ltvValue: null,
  });

  const handleFinancingLimitCheckPointChange = () => {
    setValue('loanLimitAmount', null);
    clearErrors('loanLimitAmount');
  };

  const handleIsLtvHundredChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const updatedLtvIsLtvHundred = value === 'true';

    setValue('maxLtvRatio', updatedLtvIsLtvHundred ? 100 : null);

    setLtvState({
      isLtvHundred: updatedLtvIsLtvHundred,
      ltvValue: null,
    });
  };

  const handleEnterLtvChange = (ltvValue: string) => {
    setValue('maxLtvRatio', ltvValue);
    setLtvState(prevState => ({
      ...prevState,
      ltvValue: ltvValue,
    }));
  };

  const updateMaxLtvRatioState = () => {
    let ltvValue;
    let isLtvHundred;

    if (maxLtvRatio === 100) {
      isLtvHundred = true;
      ltvValue = null;
    } else if (isNil(maxLtvRatio)) {
      isLtvHundred = null;
      ltvValue = null;
    } else {
      isLtvHundred = false;
      ltvValue = String(maxLtvRatio);
    }
    setLtvState({
      isLtvHundred: isLtvHundred,
      ltvValue: ltvValue,
    });
  };

  useEffect(() => {
    if (loanLimitCheck === false) setValue('loanLimitAmount', null); // 전문 혹은 임시 저장처럼 사용자의 입력이 아닌 값을 백엔드로 부터 받은 값을 reset, setValue 해주는 경우 loanLimitCheck가 false 임에도 loanLimitAmount가 0 혹은 예상치 못한 값이 들어오는 경우가 있어서 null로 reset해주는 코드 추가

    if (supportedCollateralType === COLLATERAL_TYPE.INVOICE) {
      updateMaxLtvRatioState();
    }
  }, [loanLimitCheck, setValue, isEditable, maxLtvRatio, supportedCollateralType]);

  return {
    errors,
    register,
    supportedCollateralType,
    currencyType,
    loanLimitCheck: watchedLoanLimitCheck,
    isEditableField,
    ltvState,
    getReadOnlyValue,
    handleFinancingLimitCheckPointChange,
    handleIsLtvHundredChange,
    handleEnterLtvChange,
  };
};

export default useFinancingLimitOfPartnersController;
