import type Pageable from 'models/Pageable';
import type { FinancierClientVO, FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { formattingToFinancierClientVOModel } from 'models/vo/FinancierClientVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemFinancierClientListRequest } from './requests';

export async function requestSystemFinancierClientList(
  pageNumber: number,
  rowCount: number,
  data: SystemFinancierClientListRequest,
): Promise<Pageable<FinancierClientVOModel[]>> {
  const response = await http.get<Pageable<FinancierClientVO[]>>({
    url: API_SY.FINANCIER_CLIENT.FINANCIER_CLIENTS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToFinancierClientVOModel(item)),
  };
}

export async function requestSystemFinancierClientData(financierClientId: number): Promise<FinancierClientVOModel> {
  const response = await http.get<FinancierClientVO>({
    url: API_SY.FINANCIER_CLIENT.FINANCIER_CLIENT_DETAIL(financierClientId),
  });

  return formattingToFinancierClientVOModel(response);
}
