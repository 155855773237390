import { useEffect, useState } from 'react';

import { MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { MultipleAnchorLoanRequestVOModel } from 'models/vo/MultipleAnchorLoanRequestVO';
import { requestAnchorMultipleLoanList } from 'utils/http/api/anchor/multiple-request-anchor-loans';
import useModal from 'utils/modal/useModal';

export const useAnchorBulkFinancingConfirmationListController = () => {
  const mounted = useMounted();
  const modal = useModal();

  const [dataState, setDataState] = useState({
    bulkFinancingWaitingList: {} as Pageable<MultipleAnchorLoanRequestVOModel[]>,
    bulkFinancingCompletedList: {} as Pageable<MultipleAnchorLoanRequestVOModel[]>,
  });

  const { pageable: bulkFinancingWaitingPageable, setPageable: setBulkFinancingWaitingPageable } = usePageable();
  const { pageable: bulkFinancingCompletedPageable, setPageable: setBulkFinancingCompletedPageable } = usePageable();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  const fetchAll = async () => {
    const [fetchMultipleRequestLoanWaitingList, fetchMultipleRequestLoanCompletedList] = await Promise.all([
      requestAnchorMultipleLoanList(
        bulkFinancingWaitingPageable.currentPage,
        bulkFinancingWaitingPageable.sizePerPage,
        {
          multipleLoanRequestStatusConditions: [
            MULTIPLE_LOAN_REQUEST_STATUS.CREATED,
            MULTIPLE_LOAN_REQUEST_STATUS.APPLIED,
          ],
        },
      ),
      requestAnchorMultipleLoanList(
        bulkFinancingCompletedPageable.currentPage,
        bulkFinancingCompletedPageable.sizePerPage,
        {
          multipleLoanRequestStatusConditions: [
            MULTIPLE_LOAN_REQUEST_STATUS.APPROVED,
            MULTIPLE_LOAN_REQUEST_STATUS.REJECTED,
            MULTIPLE_LOAN_REQUEST_STATUS.CANCELLED,
          ],
        },
      ),
    ]);

    setDataState(prevState => ({
      ...prevState,
      bulkFinancingWaitingList: fetchMultipleRequestLoanWaitingList,
      bulkFinancingCompletedList: fetchMultipleRequestLoanCompletedList,
    }));
    setBulkFinancingWaitingPageable(fetchMultipleRequestLoanWaitingList);
    setBulkFinancingCompletedPageable(fetchMultipleRequestLoanCompletedList);
  };

  const multipleRequestLoanWaitingListPaginate = async (page: number, sizePerPage: number) => {
    try {
      const response = await requestAnchorMultipleLoanList(page, sizePerPage, {
        multipleLoanRequestStatusConditions: [
          MULTIPLE_LOAN_REQUEST_STATUS.CREATED,
          MULTIPLE_LOAN_REQUEST_STATUS.APPLIED,
        ],
      });
      setDataState(prevState => ({
        ...prevState,
        bulkFinancingWaitingList: response,
      }));
      setBulkFinancingWaitingPageable(response);
    } catch (e) {
      modal.show(e);
    }
  };

  const multipleRequestLoanCompletedListPaginate = async (page: number, sizePerPage: number) => {
    try {
      const response = await requestAnchorMultipleLoanList(page, sizePerPage, {
        multipleLoanRequestStatusConditions: [
          MULTIPLE_LOAN_REQUEST_STATUS.APPROVED,
          MULTIPLE_LOAN_REQUEST_STATUS.REJECTED,
          MULTIPLE_LOAN_REQUEST_STATUS.CANCELLED,
        ],
      });
      setDataState(prevState => ({
        ...prevState,
        bulkFinancingCompletedList: response,
      }));
      setBulkFinancingCompletedPageable(response);
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    waitingTabUtils: {
      bulkFinancingWaitingList: dataState.bulkFinancingWaitingList,
      bulkFinancingWaitingPageable,
      multipleRequestLoanWaitingListPaginate,
    },
    completedTabUtils: {
      bulkFinancingCompletedList: dataState.bulkFinancingCompletedList,
      bulkFinancingCompletedPageable,
      multipleRequestLoanCompletedListPaginate,
    },
  };
};
