export interface OverdueInterestRateSettingChangeHistoryVO {
  id: number;
  activated: boolean;
  overdueInterestRateSettingId: number;
  termSpreadGroupId: number;
  costOfFundId: number;
  updatedUserId: number;
  updatedUserName: string;
  updatedDateTime: string;
}

export interface OverdueInterestRateSettingChangeHistoryVOModel extends OverdueInterestRateSettingChangeHistoryVO {}

export function formattingToOverdueInterestRateSettingChangeHistoryVOModel(
  data: OverdueInterestRateSettingChangeHistoryVO,
): OverdueInterestRateSettingChangeHistoryVOModel {
  return {
    ...data,
  };
}
