import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import NavigationGuard from 'components/stateless/NavigationGuard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import WithProvider from 'utils/render';

import useRegistrationController from './useRegistrationController';
import AgreementProvider from '../models/agreement/Provider';
import ExtraInformationProvider from '../models/extraInformation/Context';
import FinancierSettingProvider from '../models/financierSetting/Context';
import DisbursementAccount from '../sections/common/DisbursementAccount';
import DiscountRate from '../sections/common/DiscountRate';
import Financier from '../sections/common/Financier';
import FinancingDuration from '../sections/common/FinancingDuration';
import GeneralInformation from '../sections/common/GeneralInformation';
import LoadAgreement from '../sections/common/LoadAgreement';
import PartnerCompany from '../sections/common/PartnerCompany';
import PlatformPermissionSettings from '../sections/common/PlatformPermissionSettings';
import RepaymentAccountInterest from '../sections/common/RepaymentAccountInterest';
import RepaymentAccountPrincipal from '../sections/common/RepaymentAccountPrincipal';
import SecuredDepositAccount from '../sections/common/SecuredDepositAccount';
import DealerAssociatedAnchorMasterAgreement from '../sections/dealer/AssociatedAnchorMasterAgreement';
import DealerFinancingLimit from '../sections/dealer/FinancingLimit';
import VendorAssociatedAnchorMasterAgreement from '../sections/vendor/AssociatedAnchorMasterAgreement';
import VendorFinancingLimit from '../sections/vendor/FinancingLimit';

const PartnerAgreementRegistration = () => {
  const { t } = useTranslation(['format']);

  const {
    GuideMessageText,
    isAr,
    useAgreementInterface,
    methods,
    activatedNavigationGuard,
    ignoreNavigationGuard,
    handleRegisterButtonClick,
    handleCancelButtonClick,
    handleSaveButtonClick,
    getReadOnlyValue,
    isRegistrationRequestButtonDisabled,
    isEditableStyle,
    isVendorFactoring,
  } = useRegistrationController();

  return (
    <>
      <BackHeaderTitle title={t('text:Partner_Master_Agreement_Registration')} />
      <GuideMessage message={GuideMessageText} />

      <Form methods={methods} isEditable={true} isEditableStyle={isEditableStyle} getReadOnlyValue={getReadOnlyValue}>
        {useAgreementInterface && (
          <div className="content-area">
            <SectionTitle title={t('text:Load_Agreement_from_Financier_System')} />
            <LoadAgreement />
          </div>
        )}
        <div className="content-area">
          <SectionTitle title={t('text:Contracting_Parties')} />
          <Border isEditable={isEditableStyle}>
            <Financier />
            <PartnerCompany />
            {isAr ? <VendorAssociatedAnchorMasterAgreement /> : <DealerAssociatedAnchorMasterAgreement />}
          </Border>
        </div>
        <div className="content-area">
          <SectionTitle title={t('text:General_Information')} />
          <Border isEditable={isEditableStyle}>
            <GeneralInformation />
          </Border>
        </div>
        <div className="content-area">
          <SectionTitle title={t('text:Financing_Terms_and_Conditions')} />
          <Border isEditable={isEditableStyle}>
            {isAr ? <VendorFinancingLimit /> : <DealerFinancingLimit />}
            <DiscountRate isVendorFactoring={isVendorFactoring} />
            <FinancingDuration isVendorFactoring={isVendorFactoring} />
            <PlatformPermissionSettings isVendorFactoring={isVendorFactoring} />
          </Border>
        </div>
        <div className="content-area">
          <SectionTitle title={t('text:Designated Account')} />
          <Border isEditable={isEditableStyle}>
            <DisbursementAccount isVendorFactoring={isVendorFactoring} />
            <RepaymentAccountPrincipal />
            {!isVendorFactoring && (
              <>
                <RepaymentAccountInterest />
                <SecuredDepositAccount />
              </>
            )}
          </Border>
        </div>
      </Form>
      <div className={`content-area ${useAgreementInterface ? 'flex-end' : 'd-flex'}`}>
        <Button
          className={useAgreementInterface ? 'me-2' : 'me-auto'}
          size={ButtonSizeEnum.LG}
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
          onClick={handleCancelButtonClick}
        >
          {t('text:Cancel')}
        </Button>
        {!useAgreementInterface && (
          <Button
            size={ButtonSizeEnum.LG}
            onClick={handleSaveButtonClick}
            variant={ButtonVariantEnum.OUTLINED}
            className="me-2"
          >
            {t('text:Save')}
          </Button>
        )}
        <Button
          size={ButtonSizeEnum.LG}
          onClick={handleRegisterButtonClick}
          disabled={isRegistrationRequestButtonDisabled}
        >
          {t('text:Registration_Request')}
        </Button>
      </div>
      <NavigationGuard
        when={activatedNavigationGuard}
        ignore={ignoreNavigationGuard}
        useTwoButtons
        confirmBtnText={t('text:OK')}
        cancelBtnText={t('text:Close')}
        buttonSize={ButtonSizeEnum.MD}
      >
        <h6>
          {t('text:Are_you_sure_you_want_to_leave_this_page?')}
          <br />
          {t('text:Please_note_that_all_unsaved_changes_will_be_lost_if_you_leave_this_page_without_saving')}
        </h6>
      </NavigationGuard>
    </>
  );
};

export default WithProvider({
  Component: PartnerAgreementRegistration,
  Provider: [ExtraInformationProvider, AgreementProvider, FinancierSettingProvider],
});
