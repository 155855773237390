import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TdLink from 'components/stateless/Table/TdLink';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, FINANCIER_CLIENT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierClientList } from 'utils/http/api/financier/financier-clients';
import type { FinancierClientListRequest } from 'utils/http/api/financier/financier-clients/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const financierClientType = FINANCIER_CLIENT_TYPE.ANCHOR;
const FI_ANCHOR_LIST_QS_KEY = 'fi-anchor-list';

function FinancierManageAnchorList() {
  const { t } = useTranslation(['format']);
  const CURRENT_USER_AUTHORITY_TYPE = getSignIn()?.authorityType;
  const modal = useModal();
  const mounted = useMounted();

  const { pageable, setPageable } = usePageable(FI_ANCHOR_LIST_QS_KEY);
  const [financierManageAnchorsPage, setFinancierManageAnchorsPage] = useState<Pageable<FinancierClientVOModel[]>>();

  const { register, reset, getValues, setValue } = useForm<FinancierClientListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierClientListRequest>(setValue, getParsedSearchParams(FI_ANCHOR_LIST_QS_KEY).formSearchData);
      fetchFiAnchorClientList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  async function fetchFiAnchorClientList(
    pageNumber: number,
    rowCount: number,
    searchedData: FinancierClientListRequest,
  ) {
    const searchValues = {
      ...searchedData,
      financierClientType: financierClientType,
    };

    try {
      const financierManageAnchorsPage = await requestFinancierClientList(pageNumber, rowCount, searchValues);
      updateSearchParams(
        {
          ...searchValues,
          pageNumber,
          rowCount,
        },
        FI_ANCHOR_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierManageAnchorsPage(financierManageAnchorsPage);
        setPageable(financierManageAnchorsPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async (e: any) => {
    e.preventDefault();

    await fetchFiAnchorClientList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    await fetchFiAnchorClientList(pageNumber, sizePerPage, getParsedSearchParams(FI_ANCHOR_LIST_QS_KEY).formSearchData);
  };

  const onRemoveFilter = (event: any) => {
    reset();
    event.preventDefault();
  };

  const renderResultTable = (): JSX.Element[] | JSX.Element | undefined => {
    return financierManageAnchorsPage?.content.length !== 0 ? (
      financierManageAnchorsPage?.content.map((item, index) => {
        return (
          <tr key={index}>
            <td>{tableValueManage(item.financierClientCode)}</td>
            <td>{tableValueManage(item.financierClientName)}</td>
            <td>{tableValueManage(item.financierClientTaxCode)}</td>
            <td>{tableValueManage(item.financierClientAddress)}</td>
            <td>{tableValueManage(item.representativeName)}</td>
            <TdLink path={ROUTES_FI.MANAGE_ANCHOR.COMPANY_DETAIL_BUILD_PATH(item.financierClientId)} />
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={6} className="text-center">
          {t('text:no_data_available')}
        </td>
      </tr>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Anchor_List')} />
      {CURRENT_USER_AUTHORITY_TYPE !== AUTHORITY_TYPE.ADMIN ? (
        <GuideMessage
          message={[
            t('text:Click_on_the_Register_Anchor_button_to_register_a_new_Anchor_to_the_platform'),
            t('text:Registered_anchors_are_listed_below'),
            t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Anchor'),
          ]}
        >
          {{
            button: <Button to={ROUTES_FI.MANAGE_ANCHOR.COMPANY_REGISTER}>{t('text:Register_Anchor')}</Button>,
          }}
        </GuideMessage>
      ) : (
        <GuideMessage
          message={[
            t('text:Please_apply_filters_and_click_on_the_‘Search’_button'),
            t('text:Click_on_the_Remove_Filter_button_to_clear_all_filter_options_and_view_the_full_Anchor_list'),
            t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Anchor'),
          ]}
        />
      )}

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>

          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name="financierClientCode" ref={register} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="financierClientName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Tax_Code')} />
              <SearchInput name="financierClientTaxCode" ref={register} />
              <SearchLabel label={t('text:Legal_Representative_Name')} />
              <SearchInput name="representativeName" ref={register} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {financierManageAnchorsPage?.totalElements}
        </p>
        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '100px' }} />
              <col style={{ width: '170px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '280px' }} />
              <col style={{ width: '170px' }} />
              <col style={{ width: '50px' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t('text:Anchor_Client_Code')}</th>
                <th scope="col">{t('text:Anchor_Name')}</th>
                <th scope="col">{t('text:Tax_Code')}</th>
                <th scope="col">{t('text:Registered_Office_Address')}</th>
                <th scope="col">{t('text:Legal_Representative_Name')}</th>
                <th scope="col" className="table-column-right-fixed" />
              </tr>
            </thead>
            <tbody>{renderResultTable()}</tbody>
          </table>
        </div>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierManageAnchorList;
