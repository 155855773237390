import { useTranslation } from 'react-i18next';

import Big from 'big.js';

import { COLLATERAL_TYPE } from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { getFinancingLimitValue, getRemainingApplicableLimitValue } from 'utils/logic';

interface SyPartnerAgreementRegisteredDetailSummaryOfPartnerProps {
  data: DealerAgreementDetailVOModel;
}

function SyPartnerAgreementRegisteredDetailSummaryOfPartner({
  data,
}: SyPartnerAgreementRegisteredDetailSummaryOfPartnerProps) {
  const { t } = useTranslation(['format']);
  const calculatorBigNumber = new CalculatorBigNumber();

  const {
    loanLimitCheckType,
    loanLimitAmount,
    partiallyRepaidPrincipalAmount,
    requestedLoanAmount,
    currencyType,
    collateralType,
  } = data;

  const isInvoice = collateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = collateralType === COLLATERAL_TYPE.AR;

  return (
    <>
      <div className="section-title">
        <h2 style={{ fontSize: '16px' }}>{t('text:Summary_of_Partner')}</h2>
      </div>

      <div className="information-grid-form">
        {isAr && data.useTotalLimit && (
          <div className="row">
            <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit_of_Partner')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {t('format:number', {
                  value: data.totalLimitAmount,
                })}{' '}
                {data.totalLimitCurrencyType}
              </div>
            </div>
            <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit_of_Partner')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {t('format:number', {
                  value: Big(data.originCurrencyTotalBalance).lt(0) ? 0 : data.originCurrencyTotalBalance,
                })}{' '}
                {data.totalLimitCurrencyType}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {isAr
                ? getFinancingLimitValue(loanLimitCheckType, loanLimitAmount, currencyType)
                : getFinancingLimitValue(loanLimitCheckType, loanLimitAmount)}
            </div>
          </div>
          <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {isAr
                ? getRemainingApplicableLimitValue(
                    loanLimitCheckType,
                    {
                      loanLimitAmount,
                      partiallyRepaidPrincipalAmount,
                      requestedLoanAmount,
                    },
                    currencyType,
                  )
                : getRemainingApplicableLimitValue(loanLimitCheckType, {
                    loanLimitAmount,
                    partiallyRepaidPrincipalAmount,
                    requestedLoanAmount,
                  })}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Balance')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {t('format:number', {
                value: calculatorBigNumber
                  .add(data.disbursedLoanAmount)
                  .minus(data.partiallyRepaidPrincipalAmount)
                  .get(),
              })}{' '}
              {isAr && data.currencyType}
            </div>
          </div>
          <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Count')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">{t('format:number', { value: data.disbursedLoanCount })}</div>
          </div>
        </div>
        <div className="row">
          <div className="grid-form__item__label-wrap col-2">
            {isInvoice ? t('text:Pending_Invoice_Amount') : t('text:Pending_AR_Amount')}
          </div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {isInvoice
                ? t('format:number', { value: data.settlementWaitingInvoiceAmount })
                : `${t('format:number', { value: data.settlementWaitingInvoiceAmount })} ${data.currencyType}`}
            </div>
          </div>
          <div className="grid-form__item__label-wrap col-2">
            {isInvoice ? t('text:Number_of_Pending_Invoices') : t('text:Number_of_Pending_AR')}
          </div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">{t('format:number', { value: data.settlementWaitingInvoiceCount })}</div>
          </div>
        </div>
        {isInvoice && (
          <div className="row">
            <div className="grid-form__item__label-wrap col-2">{t('text:Not_-_Proceeded_Invoice_Amount')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">{t('format:number', { value: data.totalUnsettledInvoiceAmount })}</div>
            </div>
            <div className="grid-form__item__label-wrap col-2">{t('text:Number_of_Not_-_Proceeded_Invoices')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">{t('format:number', { value: data.totalUnsettledInvoiceCount })}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SyPartnerAgreementRegisteredDetailSummaryOfPartner;
