import { CURRENCY_TYPE, stringToEnum } from 'enums';

export interface DealerAgreementRepaidAmountVO {
  dealerAgreementId: number;
  dealerClientName: string;
  dealerCodeByAnchor: string;
  dealerClientCode: string;
  anchorClientName: string;
  dealerAgreementContractNo: string;
  anchorAgreementContractNo: string;
  currencyType: string;
  repaidPrincipalAmount: number;
  repaidInterestAmount: number;
  repaidDelayAmount: number;
}

export interface DealerAgreementRepaidAmountVOModel extends DealerAgreementRepaidAmountVO {
  currencyType: CURRENCY_TYPE;
}

export function formattingToDealerAgreementRepaidAmountVOModel(
  data: DealerAgreementRepaidAmountVO,
): DealerAgreementRepaidAmountVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
