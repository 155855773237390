import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import WithProvider from 'utils/render';

import useRegistrationDetailController from './useRegistrationDetailController';
import ExtraInformationProvider from '../models/extraInformation/Context';
import FinancingOptionProvider from '../models/financingOption/Provider';
import AssociatedAnchorMasterAgreement from '../sections/AssociatedAnchorMasterAgreement';
import DisbursementAccount from '../sections/DisbursementAccount';
import Financier from '../sections/Financier';
import FinancingDuration from '../sections/FinancingDuration';
import FinancingLimit from '../sections/FinancingLimit';
import FinancingOptionRevisionHistory from '../sections/FinancingOptionRevisionHistory';
import GeneralInformation from '../sections/GeneralInformation';
import InterestRate from '../sections/InterestRate';

const AnchorFinancingOptionRegistrationDetail = () => {
  const { t } = useTranslation(['format']);

  const {
    isEditButtonVisible,
    isEditable,
    handleCancelButtonClick,
    handleRequestChangeButtonClick,
    handleEditButtonClick,
    handleRevisionHistoryTabClick,
    handleAgreementTabClick,
    methods,
    GuideMessageText,
  } = useRegistrationDetailController();

  return (
    <>
      <BackHeaderTitle
        title={t('text:Anchor_Financing_Option_Details')}
        right={
          isEditButtonVisible && (
            <>
              <div className="button-separator" />
              {isEditable ? (
                <>
                  <Button
                    size={ButtonSizeEnum.LG}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleCancelButtonClick}
                  >
                    {t('text:Cancel')}
                  </Button>
                  <Button size={ButtonSizeEnum.LG} onClick={handleRequestChangeButtonClick}>
                    {t('text:Request_Change')}
                  </Button>
                </>
              ) : (
                <Button size={ButtonSizeEnum.LG} onClick={handleEditButtonClick}>
                  {t('text:Edit')}
                </Button>
              )}
            </>
          )
        }
      />
      {!isEmpty(GuideMessageText) && <GuideMessage message={GuideMessageText} messageType={MessageType.ALERT} />}
      <Tab
        tabList={[
          {
            tabName: t('text:Anchor_Financing_Option'),
            onClickTab: handleAgreementTabClick,
          },
          {
            tabName: t('text:Revision_History'),
            onClickTab: handleRevisionHistoryTabClick,
          },
        ]}
        disabled={isEditable}
        tabViewList={[
          <Fragment key="agreement">
            <Form methods={methods} isEditable={isEditable} isEditableStyle={isEditable}>
              <div className="content-area">
                <SectionTitle title={t('text:Contracting_Parties')} />
                <Border isEditable={isEditable}>
                  <AssociatedAnchorMasterAgreement />
                  <Financier />
                </Border>
              </div>

              <div className="content-area">
                <SectionTitle title={t('text:General_Information')} />
                <Border isEditable={isEditable}>
                  <GeneralInformation />
                </Border>
              </div>

              <div className="content-area">
                <SectionTitle title={t('text:Financing_Terms_and_Conditions')} />
                <Border isEditable={isEditable}>
                  <FinancingLimit />
                  <InterestRate />
                  <FinancingDuration />
                </Border>
              </div>
              <div className="content-area">
                <SectionTitle title={t('text:Designated_Account')} />
                <Border isEditable={isEditable}>
                  <DisbursementAccount />
                </Border>
              </div>
            </Form>
          </Fragment>,
          <FinancingOptionRevisionHistory key="history" />,
        ]}
      />
    </>
  );
};

export default WithProvider({
  Component: AnchorFinancingOptionRegistrationDetail,
  Provider: [ExtraInformationProvider, FinancingOptionProvider],
});
