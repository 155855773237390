import type { InputHTMLAttributes, ReactNode, RefObject } from 'react';
import { forwardRef } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';
import { isNil, isUndefined } from 'lodash-es';

import { isNilOrEmptyString } from 'utils/helpers';

import { FormErrorMessage } from '../ErrorMessage';
import Value from '../Value/Value';

import type { SizeType, TextAlignType } from '..';

export interface FormInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'required' | 'type' | 'value'> {
  children?: ReactNode;
  value?: string | number | null;
  hidden?: boolean;
  maxLength?: number;
  type?: 'text' | 'password';
  leftUnit?: string;
  rightUnit?: ReactNode;
  fieldSize?: SizeType;
  textAlign?: TextAlignType;
  isEditable?: boolean;
  errors?: FieldErrors;
  showError?: boolean;
  fetchedReadOnly?: boolean;
  currentValue?: string | number | null;
  parentReadOnly?: boolean;
}

const Input = forwardRef(
  (
    {
      name = '',
      fieldSize,
      defaultValue,
      disabled = false,
      placeholder,
      type = 'text',
      textAlign = 'text-left',
      onChange = () => {},
      className = '',
      maxLength = 2000,
      leftUnit,
      rightUnit,
      value,
      readOnly = false,
      isEditable = false,
      errors,
      showError = true,
      fetchedReadOnly = false,
      currentValue,
      parentReadOnly,
    }: FormInputProps,
    ref,
  ) => {
    const { t } = useTranslation(['format']);

    if (!isUndefined(value) && !isUndefined(defaultValue)) {
      throw new Error("Both 'value' and 'defaultValue' props cannot be provided at the same time.");
    }

    const placeholderText = (() => {
      if (disabled || !isEditable) return '';

      return isNil(placeholder) ? t('text:Please_type_here') : placeholder;
    })();

    const isReadOnly = readOnly || fetchedReadOnly;

    const isDisabled = disabled || !isEditable;

    const getError = () => {
      if (!errors || !name) return undefined;

      return name.includes('.') ? name.split('.').reduce((acc, part) => acc?.[part], errors) : errors[name];
    };

    const UncontrolledInput = (
      <input
        name={name}
        autoComplete="off"
        className={clsx('form__input', [textAlign], {
          'error-input-border': getError() && showError,
          [className]: className,
        })}
        type={type}
        placeholder={placeholderText}
        onChange={onChange}
        onWheelCapture={e => {
          e.currentTarget.blur();
        }}
        maxLength={maxLength}
        ref={ref as RefObject<HTMLInputElement>}
        readOnly={isReadOnly}
        disabled={isDisabled}
        defaultValue={defaultValue}
      />
    );

    const ControlledInput = (
      <input
        name={name}
        autoComplete="off"
        className={clsx('form__input', [textAlign], {
          [className]: className,
        })}
        type={type}
        placeholder={placeholderText}
        onChange={onChange}
        onWheelCapture={e => {
          e.currentTarget.blur();
        }}
        maxLength={maxLength}
        readOnly={isReadOnly}
        disabled={isDisabled}
        value={value ?? ''}
      />
    );

    const renderInput = (() => {
      const hasNoValue =
        isNilOrEmptyString(defaultValue) && isNilOrEmptyString(value) && isNilOrEmptyString(currentValue);

      const shouldShowDash = (() => {
        const defaultCondition = hasNoValue && (readOnly || fetchedReadOnly || disabled || !isEditable);

        if (isNil(parentReadOnly)) return defaultCondition;

        return parentReadOnly && defaultCondition;
      })();

      if (shouldShowDash) {
        return <Value value="-" textAlign={textAlign} />;
      }

      return isUndefined(value) ? UncontrolledInput : ControlledInput;
    })();

    return (
      <div className="flex-align-center">
        {leftUnit && <span className="unit">{leftUnit}</span>}
        <div
          className={clsx({
            [`field--size-${fieldSize}`]: fieldSize,
            'flex-1': !fieldSize,
          })}
        >
          {renderInput}
          {getError() && showError && <FormErrorMessage error={getError()} />}
        </div>
        {rightUnit && <span className="unit">{rightUnit}</span>}
      </div>
    );
  },
);
Input.displayName = 'Input';

export default Input;
