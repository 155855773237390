import type { ChangeEvent } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

interface PropsType {
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  type?: string;
  col?: number;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
const SearchInput = forwardRef(
  (
    {
      name = '',
      value = '',
      disabled = false,
      readOnly = false,
      col = 4,
      placeholder = 'Please type here',
      type = 'text',
      onChange = () => {},
    }: PropsType,
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => inputRef.current);

    const handleKeyDown = (e: any) => {
      // To prevent events when press enter key
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    };

    return (
      <div className={`search-form__input-wrap col-${col}`}>
        <input
          name={name}
          autoComplete="off"
          disabled={disabled}
          readOnly={readOnly}
          type={type}
          placeholder={disabled ? '' : placeholder}
          defaultValue={value}
          onChange={onChange}
          ref={inputRef}
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  },
);

SearchInput.displayName = 'SearchInput';
export default SearchInput;
