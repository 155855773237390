import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DeletedArVOModel } from 'models/vo/DeletedArVO';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierDeletedArsList } from 'utils/http/api/financier/deleted-ars';
import type { FinancierDeletedARsListRequest } from 'utils/http/api/financier/deleted-ars/requests';
import { requestFinancierSuccessArsList } from 'utils/http/api/financier/success-ars';
import type { FinancierSuccessArsListRequest } from 'utils/http/api/financier/success-ars/requests';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

export const FI_REGISTERED_AR_LIST_QS_KEY = 'fi-registered-ar-list';
export const FI_DELETED_AR_LIST_QS_KEY = 'fi-deleted-ar-list';

const useFinancierARListController = () => {
  const mounted = useMounted();
  const modal = useModal();

  const [registeredARPage, setRegisteredARPage] = useState<Pageable<SuccessArVOModel[]>>();
  const [deletedARPage, setDeletedARPage] = useState<Pageable<DeletedArVOModel[]>>();

  const { pageable: registeredListPageable, setPageable: setRegisteredListPageable } =
    usePageable(FI_REGISTERED_AR_LIST_QS_KEY);
  const { pageable: deletedListPageable, setPageable: setDeletedListPageable } = usePageable(FI_DELETED_AR_LIST_QS_KEY);

  const registeredARSearchForm = useForm<FinancierSuccessArsListRequest>();
  const deletedARSearchForm = useForm<FinancierDeletedARsListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierSuccessArsListRequest>(
        registeredARSearchForm.setValue,
        getParsedSearchParams(FI_REGISTERED_AR_LIST_QS_KEY).formSearchData,
      );
      setFormValues<FinancierDeletedARsListRequest>(
        deletedARSearchForm.setValue,
        getParsedSearchParams(FI_DELETED_AR_LIST_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  const constructAndAddSearchParams = () => {
    const registeredTabQs = makeSearchParamsPattern(
      {
        ...registeredARSearchForm.getValues(),
        pageNumber: registeredListPageable.currentPage,
        rowCount: registeredListPageable.sizePerPage,
      },
      FI_REGISTERED_AR_LIST_QS_KEY,
    );

    const deletedTabQs = makeSearchParamsPattern(
      {
        ...deletedARSearchForm.getValues(),
        pageNumber: deletedListPageable.currentPage,
        rowCount: deletedListPageable.sizePerPage,
      },
      FI_DELETED_AR_LIST_QS_KEY,
    );

    addSearchParams(registeredTabQs + deletedTabQs);
  };

  const fetchAll = async (): Promise<void> => {
    try {
      const [registeredList, deletedList] = await Promise.all([
        requestFinancierSuccessArsList(
          registeredListPageable.currentPage,
          registeredListPageable.sizePerPage,
          registeredARSearchForm.getValues(),
        ),
        requestFinancierDeletedArsList(
          deletedListPageable.currentPage,
          deletedListPageable.sizePerPage,
          deletedARSearchForm.getValues(),
        ),
      ]);

      constructAndAddSearchParams();
      setRegisteredARPage(registeredList);
      setRegisteredListPageable(registeredList);
      setDeletedARPage(deletedList);
      setDeletedListPageable(deletedList);
    } catch (error) {
      modal.show(error);
    }
  };

  async function fetchFinancierRegisteredAPList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: FinancierSuccessArsListRequest,
  ) {
    try {
      const anchorSuccessArsPage = await requestFinancierSuccessArsList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        FI_REGISTERED_AR_LIST_QS_KEY,
      );

      setRegisteredARPage(anchorSuccessArsPage);
      setRegisteredListPageable(anchorSuccessArsPage);
    } catch (e) {
      modal.show(e);
    }
  }

  const handleClickRegisteredAPSearch = async () => {
    await fetchFinancierRegisteredAPList(0, registeredListPageable.sizePerPage, registeredARSearchForm.getValues());
  };

  const paginateRegisteredAPList = async (page: number, sizePerPage: number) => {
    await fetchFinancierRegisteredAPList(
      page,
      sizePerPage,
      getParsedSearchParams(FI_REGISTERED_AR_LIST_QS_KEY).formSearchData,
    );
  };

  async function fetchFinancierDeletedAPList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: FinancierDeletedARsListRequest,
  ) {
    try {
      const anchorDeletedArsPage = await requestFinancierDeletedArsList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        FI_DELETED_AR_LIST_QS_KEY,
      );

      setDeletedARPage(anchorDeletedArsPage);
      setDeletedListPageable(anchorDeletedArsPage);
    } catch (e) {
      modal.show(e);
    }
  }

  const handleClickDeletedAPSearch = async () => {
    await fetchFinancierDeletedAPList(0, deletedListPageable.sizePerPage, deletedARSearchForm.getValues());
  };

  const paginateDeletedAPList = async (page: number, sizePerPage: number) => {
    await fetchFinancierDeletedAPList(
      page,
      sizePerPage,
      getParsedSearchParams(FI_DELETED_AR_LIST_QS_KEY).formSearchData,
    );
  };

  return {
    registeredARPage,
    deletedARPage,
    registeredARSearchForm,
    deletedARSearchForm,
    registeredListPageable,
    deletedListPageable,
    handleClickRegisteredAPSearch,
    paginateRegisteredAPList,
    handleClickDeletedAPSearch,
    paginateDeletedAPList,
  };
};

export default useFinancierARListController;
