import type Pageable from 'models/Pageable';
import type {
  ArCommissionSettlementDetailVO,
  ArCommissionSettlementDetailVOModel,
} from 'models/vo/ArCommissionSettlementDetailVO';
import { formattingToArCommissionSettlementDetailVOModel } from 'models/vo/ArCommissionSettlementDetailVO';
import type {
  ArCommissionSettlementRelatedLoanVO,
  ArCommissionSettlementRelatedLoanVOModel,
} from 'models/vo/ArCommissionSettlementRelatedLoanVO';
import { formattingToArCommissionSettlementRelatedLoanVOModel } from 'models/vo/ArCommissionSettlementRelatedLoanVO';
import type { ArCommissionSettlementVO, ArCommissionSettlementVOModel } from 'models/vo/ArCommissionSettlementVO';
import { formattingToArCommissionSettlementsVOModel } from 'models/vo/ArCommissionSettlementVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { SearchFiArCommissionSettlementRelatedLoanDTO, SearchFiArCommissionSettlementsDTO } from './requests';

export async function requestFiArCommissionSettlements(
  pageNumber: number,
  rowCount: number,
  data: SearchFiArCommissionSettlementsDTO,
): Promise<Pageable<ArCommissionSettlementVOModel[]>> {
  const response = await http.get<Pageable<ArCommissionSettlementVO[]>>({
    url: API_FI.AR_COMMISSION_SETTLEMENTS.AR_COMMISSION_SETTLEMENTS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToArCommissionSettlementsVOModel(data)),
  };
}

export async function requestFiArCommissionSettlementDetail(
  dealerAgreementId: number,
  settlementDate: string,
): Promise<ArCommissionSettlementDetailVOModel> {
  const response = await http.get<ArCommissionSettlementDetailVO>({
    url: API_FI.AR_COMMISSION_SETTLEMENTS.AR_COMMISSION_SETTLEMENTS_DETAIL(dealerAgreementId, settlementDate),
  });

  return formattingToArCommissionSettlementDetailVOModel(response);
}

export async function requestFiArCommissionSettlementRelatedLoans(
  pageNumber: number,
  rowCount: number,
  data: SearchFiArCommissionSettlementRelatedLoanDTO,
): Promise<Pageable<ArCommissionSettlementRelatedLoanVOModel[]>> {
  const response = await http.get<Pageable<ArCommissionSettlementRelatedLoanVO[]>>({
    url: API_FI.AR_COMMISSION_SETTLEMENTS.AR_COMMISSION_SETTLEMENT_RELATED_LOAN,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToArCommissionSettlementRelatedLoanVOModel(data)),
  };
}
