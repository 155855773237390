import type Pageable from 'models/Pageable';
import type { WaitingBankCodeVO, WaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import { formattingToWaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FiWaitingBankCodeListRequest, FiWaitingBankCodeRegisterRequest } from './request';

export async function requestFiWaitingBankCodeList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FiWaitingBankCodeListRequest,
): Promise<Pageable<WaitingBankCodeVOModel[]>> {
  const response = await http.get<Pageable<WaitingBankCodeVO[]>>({
    url: API_FI.WAITING_BANK_CODE.WAITING_BANK_CODE,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const bankCodeList: WaitingBankCodeVOModel[] = response.content.map((data: WaitingBankCodeVO) =>
    formattingToWaitingBankCodeVOModel(data),
  );
  const bankCodePage: Pageable<WaitingBankCodeVOModel[]> = {
    ...response,
    content: bankCodeList,
  };

  return bankCodePage;
}

export async function requestFiWaitingBankCodeDetail(waitingBankCodeId: number): Promise<WaitingBankCodeVOModel> {
  const response = await http.get<WaitingBankCodeVO>({
    url: API_FI.WAITING_BANK_CODE.WAITING_BANK_CODE_DETAIL(waitingBankCodeId),
  });

  return formattingToWaitingBankCodeVOModel(response);
}

// bankCode 수정 / 등록 요청
export async function requestFiWaitingBankCodeRegister(
  data: FiWaitingBankCodeRegisterRequest,
): Promise<WaitingBankCodeVOModel> {
  const response = await http.post<WaitingBankCodeVO>({
    url: API_FI.WAITING_BANK_CODE.WAITING_BANK_CODE,
    data,
  });

  return formattingToWaitingBankCodeVOModel(response);
}

export async function requestFiWaitingBankCodeCancel(waitingBankCodeId: number): Promise<WaitingBankCodeVOModel> {
  const response = await http.put<WaitingBankCodeVO>({
    url: API_FI.WAITING_BANK_CODE.CANCEL_WAITING_BANK_CODE(waitingBankCodeId),
  });

  return formattingToWaitingBankCodeVOModel(response);
}
