import type Pageable from 'models/Pageable';
import type { AnchorPartnerAccountVO, AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import { formattingToAnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SyAnchorPartnerAccountsListRequest } from './request';

export async function requestSyAnchorPartnerAccountsList(
  pageNumber: number,
  rowCount: number,
  searchValue?: SyAnchorPartnerAccountsListRequest,
): Promise<Pageable<AnchorPartnerAccountVOModel[]>> {
  const response = await http.get<Pageable<AnchorPartnerAccountVO[]>>({
    url: API_SY.ANCHOR_PARTNER_ACCOUNTS.ANCHOR_PARTNER_ACCOUNT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  const acPartnerAccountList: AnchorPartnerAccountVOModel[] = response.content.map((data: AnchorPartnerAccountVO) => {
    return formattingToAnchorPartnerAccountVOModel(data);
  });

  return {
    ...response,
    content: acPartnerAccountList,
  };
}
