import { Big } from 'big.js';

import type { CURRENCY_TYPE } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';
import { calculateTotalLimitAmountWithoutAdHoc } from 'utils/logic/invoice';

import { formattingToAnchorUserVOModel } from './AnchorUserVO';
import { formattingToTermSpreadVOModel } from './TermSpreadVO';

import type { AnchorUserVO, AnchorUserVOModel } from './AnchorUserVO';
import type { DealerAgreementVO } from './DealerAgreementVO';
import type { TermSpreadVO, TermSpreadVOModel } from './TermSpreadVO';

export interface DealerAgreementDetailVO
  extends Omit<
    DealerAgreementVO,
    | 'exists'
    | 'financierId'
    | 'financierName'
    | 'anchorFinancierClientCode'
    | 'dealerClientTaxCode'
    | 'dealerFinancierClientAddress'
  > {
  creditRating: string;
  branchAddress: string;
  branchFax: string;
  branchTelephone: string;
  totalUnsettledInvoiceAmount: string;
  totalUnsettledInvoiceCount: number;
  interestRepaymentAccountFinancierCode: string;
  interestRepaymentAccountFinancierName: string;
  interestRepaymentAccountBranchCode: string;
  interestRepaymentAccountBranchName: string;
  interestRepaymentAccount: string;
  interestRepaymentAccountOwner: string;
  depositCollateralAccountFinancierCode: string;
  depositCollateralAccountFinancierName: string;
  depositCollateralAccountBranchCode: string;
  depositCollateralAccountBranchName: string;
  depositCollateralAccount: string;
  depositCollateralAccountOwner: string;
  termSpreadList: TermSpreadVO[];
  anchorUserList: AnchorUserVO[];
  useTotalLimit: boolean;
  totalLimitAmount: string;
  originCurrencyTotalBalance: string; // 통합 한도 통화 기준 잔액
  agreementCurrencyTotalBalance: string; // 약정 통화 기준 잔액
  totalLimitCurrencyType: CURRENCY_TYPE;
  anchorFinancierClientName: string;
  dealerFinancierClientName: string;
  currencyType: CURRENCY_TYPE;
  anchorAgreementStartDate: string;
  anchorAgreementExpiryDate: string;
  division: string;
  divisionName: string;
  maxAgreementLoanCapacityAmount?: string;
}

export interface DealerAgreementDetailVOModel extends DealerAgreementDetailVO {
  termSpreadList: TermSpreadVOModel[];
  anchorUserList: AnchorUserVOModel[];
  loanLimitAmount: BigNumber;
  registeredWaitingInvoiceAmount: BigNumber;
  settlementWaitingInvoiceAmount: BigNumber;
  disbursedLoanAmount: BigNumber;
  requestedLoanAmount: BigNumber;
  totalUnsettledInvoiceAmount: BigNumber;
  adhocLimitAmount: BigNumber;
  partiallyRepaidPrincipalAmount: BigNumber;
  totalLimitAmount: BigNumber;
  originCurrencyTotalBalance: BigNumber;
  agreementCurrencyTotalBalance: BigNumber;
  drawingPower: BigNumber;
  maxAgreementLoanCapacityAmount?: BigNumber;
  // only front
  totalLimitAmountWithoutAdHoc: BigNumber;
  totalLimitAmountWithAdHoc: BigNumber;
  exceedLimit: BigNumber;
}

export function formattingToDealerAgreementDetailVOModel(data: DealerAgreementDetailVO): DealerAgreementDetailVOModel {
  const calculatorBigNumber = new CalculatorBigNumber();

  const slicedZeroDecimalData = {
    loanLimitAmount: sliceZeroDecimal(data.loanLimitAmount),
    registeredWaitingInvoiceAmount: sliceZeroDecimal(data.registeredWaitingInvoiceAmount),
    settlementWaitingInvoiceAmount: sliceZeroDecimal(data.settlementWaitingInvoiceAmount),
    disbursedLoanAmount: sliceZeroDecimal(data.disbursedLoanAmount),
    requestedLoanAmount: sliceZeroDecimal(data.requestedLoanAmount),
    totalUnsettledInvoiceAmount: sliceZeroDecimal(data.totalUnsettledInvoiceAmount),
    adhocLimitAmount: sliceZeroDecimal(data.adhocLimitAmount),
    partiallyRepaidPrincipalAmount: sliceZeroDecimal(data.partiallyRepaidPrincipalAmount),
    totalLimitAmount: sliceZeroDecimal(data.totalLimitAmount),
    originCurrencyTotalBalance: sliceZeroDecimal(data.originCurrencyTotalBalance),
    agreementCurrencyTotalBalance: sliceZeroDecimal(data.agreementCurrencyTotalBalance),
    drawingPower: sliceZeroDecimal(data.drawingPower),
  };

  const {
    loanLimitAmount,
    registeredWaitingInvoiceAmount,
    settlementWaitingInvoiceAmount,
    disbursedLoanAmount,
    requestedLoanAmount,
    totalUnsettledInvoiceAmount,
    adhocLimitAmount,
    partiallyRepaidPrincipalAmount,
    totalLimitAmount,
    originCurrencyTotalBalance,
    agreementCurrencyTotalBalance,
    drawingPower,
  } = slicedZeroDecimalData;

  const { termSpreadList, anchorUserList } = data;

  const totalLimitAmountWithoutAdHoc = calculateTotalLimitAmountWithoutAdHoc({
    loanLimitAmount,
    disbursedLoanAmount,
    settlementWaitingInvoiceAmount,
    registeredWaitingInvoiceAmount,
    partiallyRepaidPrincipalAmount,
  });

  return {
    ...data,
    loanLimitAmount,
    registeredWaitingInvoiceAmount,
    settlementWaitingInvoiceAmount,
    disbursedLoanAmount,
    requestedLoanAmount,
    totalUnsettledInvoiceAmount,
    adhocLimitAmount,
    partiallyRepaidPrincipalAmount,
    totalLimitAmount,
    originCurrencyTotalBalance,
    agreementCurrencyTotalBalance,
    drawingPower,
    termSpreadList: termSpreadList
      ? termSpreadList.map(termSpread => formattingToTermSpreadVOModel(termSpread))
      : termSpreadList,
    anchorUserList: anchorUserList
      ? anchorUserList.map(anchorUser => formattingToAnchorUserVOModel(anchorUser))
      : anchorUserList,
    // only front
    totalLimitAmountWithoutAdHoc,
    totalLimitAmountWithAdHoc: calculatorBigNumber.add(adhocLimitAmount).add(totalLimitAmountWithoutAdHoc).get(),
    // exceedLimit = totalLimitAmountWithoutAdHoc 이 음수이면 양수로 표시, 양수인 경우 '0'
    exceedLimit: Big(totalLimitAmountWithoutAdHoc).lt(0) ? Big(totalLimitAmountWithoutAdHoc).abs().toString() : '0',
  };
}
