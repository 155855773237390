import type { ATTACHMENT_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import { formattingToFactoringNoticeDetailVOModel } from 'models/vo/FactoringNoticeDetailVO';
import type { FactoringNoticeDetailVO, FactoringNoticeDetailVOModel } from 'models/vo/FactoringNoticeDetailVO';
import { formattingToFactoringNoticeReceiverVOModel } from 'models/vo/FactoringNoticeReceiverVO';
import type { FactoringNoticeReceiverVO, FactoringNoticeReceiverVOModel } from 'models/vo/FactoringNoticeReceiverVO';
import { formattingToFactoringNoticeRelatedSuccessArVOModel } from 'models/vo/FactoringNoticeRelatedSuccessArVO';
import type {
  FactoringNoticeRelatedSuccessArVO,
  FactoringNoticeRelatedSuccessArVOModel,
} from 'models/vo/FactoringNoticeRelatedSuccessArVO';
import { formattingToFactoringNoticeVOModel } from 'models/vo/FactoringNoticeVO';
import type { FactoringNoticeVO, FactoringNoticeVOModel } from 'models/vo/FactoringNoticeVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorFactoringNoticeReceiversListRequest } from './request';

export async function requestAnchorFactoringNoticeList(
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<FactoringNoticeVOModel[]>> {
  const response = await http.get<Pageable<FactoringNoticeVO[]>>({
    url: API_AC.FACTORING_NOTICES.FACTORING_NOTICE,
    data: {
      pageNumber,
      rowCount,
    },
  });
  const factoringNoticeList: FactoringNoticeVOModel[] = response.content.map((data: FactoringNoticeVO) =>
    formattingToFactoringNoticeVOModel(data),
  );

  return { ...response, content: factoringNoticeList };
}

export async function requestAnchorFactoringNoticeDetail(
  factoringNoticeId: number,
): Promise<FactoringNoticeDetailVOModel> {
  const response = await http.get<FactoringNoticeDetailVO>({
    url: API_AC.FACTORING_NOTICES.FACTORING_NOTICE_DETAIL(factoringNoticeId),
  });

  return formattingToFactoringNoticeDetailVOModel(response);
}

export async function requestAnchorFactoringNoticeDownload(
  factoringNoticeId: number,
  data: { attachmentType: ATTACHMENT_TYPE },
) {
  await http.download(API_AC.FACTORING_NOTICES.FACTORING_NOTICE_DOWNLOAD_ATTACHMENT(factoringNoticeId), data);
}

export async function requestAnchorFactoringNoticeConfirm(factoringNoticeIds: number[]) {
  await http.put<void>({
    url: API_AC.FACTORING_NOTICES.FACTORING_NOTICE_CONFIRM,
    data: {
      factoringNoticeIdList: factoringNoticeIds,
    },
  });
}

export async function requestAnchorFactoringNoticeReceiverList(
  factoringNoticeId: number,
  pageNumber: number,
  rowCount: number,
  searchValue?: AnchorFactoringNoticeReceiversListRequest,
): Promise<Pageable<FactoringNoticeReceiverVOModel[]>> {
  const response = await http.get<Pageable<FactoringNoticeReceiverVO[]>>({
    url: API_AC.FACTORING_NOTICES.FACTORING_NOTICE_RECEIVERS(factoringNoticeId),
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const factoringNoticeReceiverList: FactoringNoticeReceiverVOModel[] = response.content.map(
    (data: FactoringNoticeReceiverVO) => formattingToFactoringNoticeReceiverVOModel(data),
  );

  return { ...response, content: factoringNoticeReceiverList };
}

export async function requestAnchorFactoringNoticeRelatedArsList(
  factoringNoticeId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<FactoringNoticeRelatedSuccessArVOModel[]>> {
  const response = await http.get<Pageable<FactoringNoticeRelatedSuccessArVO[]>>({
    url: API_AC.FACTORING_NOTICES.FACTORING_NOTICE_RELATED_ARS(factoringNoticeId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const factoringNoticeRelatedArsList: FactoringNoticeRelatedSuccessArVOModel[] = response.content.map(
    (data: FactoringNoticeRelatedSuccessArVO) => formattingToFactoringNoticeRelatedSuccessArVOModel(data),
  );

  return { ...response, content: factoringNoticeRelatedArsList };
}
