import type { FINANCIER_ALERT_TYPE } from 'enums';
import type { FinancierAlertSettingVO, FinancierAlertSettingVOModel } from 'models/vo/FinancierAlertSettingVO';
import { formattingToFinancierAlertSettingVOModel } from 'models/vo/FinancierAlertSettingVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierAlertSettingListRequest, FinancierAlertSettingUpdateRequest } from './requests';

export async function requestFinancierAlertSettingList(
  pageNumber: number,
  rowCount: number,
  data: FinancierAlertSettingListRequest,
): Promise<FinancierAlertSettingVOModel[]> {
  const response = await http.get<FinancierAlertSettingVO[]>({
    url: API_FI.FINANCIER_ALERT_SETTING.ALERT_SETTINGS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return response.map(item => formattingToFinancierAlertSettingVOModel(item));
}

export async function requestFinancierAlertSettingUpdate(
  financierAlertSettingUpdateRequest: FinancierAlertSettingUpdateRequest,
): Promise<FinancierAlertSettingVOModel> {
  const response = await http.put<FinancierAlertSettingVO>({
    url: API_FI.FINANCIER_ALERT_SETTING.ALERT_SETTINGS,
    data: financierAlertSettingUpdateRequest,
  });

  return formattingToFinancierAlertSettingVOModel(response);
}

export async function requestFinancierAlertSettingUpdateRelatedUsers(alertType: FINANCIER_ALERT_TYPE): Promise<void> {
  return await http.put({
    url: API_FI.FINANCIER_ALERT_SETTING.UPDATE_RELATED_USERS,
    data: {
      alertType,
    },
  });
}
