import { CURRENCY_TYPE, POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS, stringToEnum } from 'enums';

export interface PotentialPartnerFinancingApplicationVO {
  potentialPartnerFinancingApplicationId: number;
  potentialPartnerFinancingApplicationStatus: string;
  createdDateTime: string;
  name: string;
  taxCode: string;
  anchorClientName: string;
  anchorClientCode: string;
  anchorAgreementContractNo: string;
  currencyType: string;
  financierName: string;
  requestBranchCode: string;
  requestBranchName: string;
  reviewedBranchCode: string;
  reviewedBranchName: string;
  assignedBranchCode: string;
  assignedBranchName: string;
  hasFinancingApplicationDocumentNeedResubmissionStatus: boolean;
}

export interface PotentialPartnerFinancingApplicationVOModel extends PotentialPartnerFinancingApplicationVO {
  potentialPartnerFinancingApplicationStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
  currencyType: CURRENCY_TYPE;
}

export function formattingToPotentialPartnerFinancingApplicationVOModel(
  data: PotentialPartnerFinancingApplicationVO,
): PotentialPartnerFinancingApplicationVOModel {
  return {
    ...data,
    potentialPartnerFinancingApplicationStatus: stringToEnum(
      POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS,
      data.potentialPartnerFinancingApplicationStatus,
    ),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
  };
}
