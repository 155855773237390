import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';

import useMounted from 'hooks/useMounted';
import type { ModalContextProps } from 'utils/modal/ModalContext';
import ModalContext, { defaultModalContext } from 'utils/modal/ModalContext';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import ModalWrapper from 'utils/modal/ModalWrapper';

type ModalProviderPropsType = {
  children: ReactNode;
};

const ModalProvider = ({ children }: ModalProviderPropsType) => {
  const mounted = useMounted();
  const modalContext = useRef<ModalContextProps>(defaultModalContext);
  const root = useRef<Element | null>(null);
  const [modalList, setModalList] = useState<
    { children: ReactNode | null; options: ModalOptions | null; visible: boolean; modalId: number }[]
  >([]);
  const nextModalId = useRef(0);

  useEffect(() => {
    if (!mounted) {
      const themeWrapper = document.getElementById('theme-wrapper');
      root.current = document.createElement('div');
      root.current.id = '__common-modal__';

      const blockUIElement = document.createElement('div');
      blockUIElement.id = '__block-ui__';
      themeWrapper?.appendChild(root.current);
      themeWrapper?.appendChild(blockUIElement);
    }
  }, [mounted]);

  const disableConfirmBtn = useCallback((modalId: number, disabled: boolean) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setModalList(prevState => {
        const index = prevState.findIndex(e => e.modalId === modalId);
        const copyState = [...prevState];
        copyState[index].options = {
          ...copyState[index].options,
          confirmBtnDisabled: disabled,
        };

        return [...copyState];
      });
    });
  }, []);

  const show = useCallback((children: ReactNode | any, options?: ModalOptions) => {
    const modalId = nextModalId.current;
    nextModalId.current += 1;

    ReactDOM.unstable_batchedUpdates(() => {
      setModalList(prevState => {
        const index = prevState.findIndex(e => e.modalId === modalId);
        if (index >= 0) return prevState;
        prevState.push({ children, options: options || null, visible: true, modalId });

        return [...prevState];
      });
    });
  }, []);

  const close = useCallback((modalId: number = nextModalId.current) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setModalList(prevState => {
        const index = prevState.findIndex(e => e.modalId === modalId);
        const copyState = Object.assign([], prevState);
        copyState.splice(index, 1);

        return [...copyState];
      });
    });
  }, []);

  // not modalContext
  modalContext.current = {
    show: show,
    close: close,
    disableConfirmBtn: disableConfirmBtn,
    id: nextModalId.current,
  };

  return (
    <ModalContext.Provider value={modalContext.current}>
      {children}
      {mounted &&
        createPortal(
          modalList.map(value => {
            return (
              <div key={value.modalId}>
                <ModalWrapper modalId={value.modalId} visible={value.visible} close={close} options={value.options}>
                  {value.children}
                </ModalWrapper>
              </div>
            );
          }),
          root.current as Element,
        )}
    </ModalContext.Provider>
  );
};
export default ModalProvider;
