import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ClipboardWriteButton from 'components/stateless/Button/ClipboardWriteButton';
import { FormBorder, FormContents, FormInput, FormSelect } from 'components/stateless/CommonForm';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import { AnchorUserExceptionCode } from 'enums/exception';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { WaitingAnchorUserVOModel } from 'models/vo/WaitingAnchorUserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFiRelatedAnchorAgreement,
  requestFiRelatedDealerAgreement,
  requestFinancierAnchorUserDetail,
  requestFinancierAnchorUserInviteUrl,
  requestInviteAnchorUser,
} from 'utils/http/api/financier/anchor-users';
import {
  requestFinancierWaitingAnchorUserList,
  requestWaitingAnchorUserRegistration,
} from 'utils/http/api/financier/waiting-anchor-users';
import type { FinancierWaitingAnchorUserRegisterRequest } from 'utils/http/api/financier/waiting-anchor-users/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import FinancierManageAnchorUserDetailHistory from './sections/edit-history';
import FinancierManageAnchorUserDetailAnchorAgreementList from './sections/related-anchor-agreement';
import FinancierManageAnchorUserDetailDealerAgreementList from './sections/related-partner-agreement';

function FinancierManageAnchorUserRegisteredDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const requestModal = useModal();
  const confirmModal = useModal();

  const history = useHistory();
  const { anchorUserId } = useParams<any>();
  const getProperty = useProperty<FinancierWaitingAnchorUserRegisterRequest>();
  const [anchorUserDetailData, setAnchorUserDetailData] = useState<AnchorUserVOModel>();
  const [userEditHistory, setUserEditHistory] = useState<Pageable<WaitingAnchorUserVOModel[]>>(); // for History Table

  const [anchorAgreementList, setAnchorAgreementList] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const [dealerAgreementList, setDealerAgreementList] = useState<Pageable<DealerAgreementVOModel[]>>();
  const [inviteUrl, setInviteUrl] = useState<string | null>('');

  const [editToggle, setEditToggle] = useState<boolean>(false);
  const signInModel: SignInModel | null = getSignIn();

  const userEditHistoryPageable = usePageable();
  const anchorAgreementPageable = usePageable();
  const dealerAgreementPageable = usePageable();

  const anchorUserEditForm = useForm<FinancierWaitingAnchorUserRegisterRequest>({
    mode: 'onSubmit',
    shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
  });

  const guideMessage = [];
  if (signInModel?.authorityType === AUTHORITY_TYPE.ADMIN) {
    guideMessage.push(t('text:You_can_check_the_anchor_users_details_on_this_page'));
    guideMessage.push(t('text:Click_on_the_arrow_button_to_view_the_details_of_the_relevant_master_agreements'));
  } else if (signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR) {
    guideMessage.push(t('text:You_can_check_the_anchor_users_details_on_this_page'));
    guideMessage.push(t('text:Click_on_the_arrow_button_to_view_the_details_of_the_relevant_master_agreements'));
    guideMessage.push(t('text:Click_the_Edit_button_to_modify_the_anchor_users_details'));
  } else {
    guideMessage.push(t('text:You_can_check_the_anchor_users_details_on_this_page'));
    guideMessage.push(t('text:Click_on_the_arrow_button_to_view_the_details_of_the_relevant_master_agreements'));
  }

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const resetAnchorUserData = (data: AnchorUserVOModel) => {
    anchorUserEditForm.reset({
      bankUserId: data.bankUserId,
      name: data.name,
      mobile: data.mobile,
      email: data.email,
      authorityType: data.authorityType,
      userStatus: data.userStatus,
      anchorClientId: data.anchorClientId,
      anchorUserOtpSerialNo: data.anchorUserOtpSerialNo,
    });
  };

  async function fetchAll() {
    let fetchAnchorAgreementList: Pageable<AnchorAgreementVOModel[]> | null = null;

    try {
      const [fetchAnchorUser, fetchDealerAgreementList] = await Promise.all([
        requestFinancierAnchorUserDetail(anchorUserId),
        requestFiRelatedDealerAgreement(
          dealerAgreementPageable.pageable.currentPage,
          dealerAgreementPageable.pageable.sizePerPage,
          anchorUserId,
        ),
      ]);
      const fetchUserEditHistory = await requestFinancierWaitingAnchorUserList(
        userEditHistoryPageable.pageable.currentPage,
        userEditHistoryPageable.pageable.sizePerPage,
        {
          targetAnchorUserId: anchorUserId,
        },
      );
      if (fetchAnchorUser.authorityType === AUTHORITY_TYPE.ADMIN) {
        // AnchorAgreementList는 조회하는 사용자가 ADMIN 일 때만 요청,
        fetchAnchorAgreementList = await requestFiRelatedAnchorAgreement(
          anchorAgreementPageable.pageable.currentPage,
          anchorAgreementPageable.pageable.sizePerPage,
          anchorUserId,
        );
      }

      ReactDOM.unstable_batchedUpdates(() => {
        resetAnchorUserData(fetchAnchorUser);
        setAnchorUserDetailData(fetchAnchorUser);
        setUserEditHistory(fetchUserEditHistory);
        if (fetchAnchorAgreementList) {
          setAnchorAgreementList(fetchAnchorAgreementList);
          anchorAgreementPageable.setPageable(fetchAnchorAgreementList);
        }
        setDealerAgreementList(fetchDealerAgreementList);
        setEditToggle(false);
        dealerAgreementPageable.setPageable(fetchDealerAgreementList);
        userEditHistoryPageable.setPageable(fetchUserEditHistory);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchUserEditHistoryList = async (pageNumber: number, rowCount: number) => {
    try {
      const editUserHistoryList = await requestFinancierWaitingAnchorUserList(pageNumber, rowCount, {
        targetAnchorUserId: anchorUserId,
      });
      ReactDOM.unstable_batchedUpdates(() => {
        setUserEditHistory(editUserHistoryList);
        userEditHistoryPageable.setPageable(editUserHistoryList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const waitingAnchorUserPaginate = async (pageNumber: number, rowCount: number) => {
    await fetchUserEditHistoryList(pageNumber, rowCount);
  };

  const fetchAnchorAgreementList = async (pageNumber: number, rowCount: number) => {
    try {
      const anchorAgreementList = await requestFiRelatedAnchorAgreement(pageNumber, rowCount, anchorUserId);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementList(anchorAgreementList);
        anchorAgreementPageable.setPageable(anchorAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const anchorAgreementPaginate = async (pageNumber: number, rowCount: number) => {
    await fetchAnchorAgreementList(pageNumber, rowCount);
  };

  const fetchDealerAgreementList = async (pageNumber: number, rowCount: number) => {
    try {
      const dealerAgreementList = await requestFiRelatedDealerAgreement(pageNumber, rowCount, anchorUserId);
      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementList(dealerAgreementList);
        dealerAgreementPageable.setPageable(dealerAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const dealerAgreementPaginate = async (pageNumber: number, rowCount: number) => {
    await fetchDealerAgreementList(pageNumber, rowCount);
  };
  const onClickSendEmail = async () => {
    modal.show(<h6>{t('text:Would_you_like_to_send_an_invitation_email')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        sendEmailRequest();
      },
    });
  };

  const sendEmailRequest = async () => {
    if (!anchorUserDetailData || !anchorUserDetailData.id) return;
    try {
      await requestInviteAnchorUser(anchorUserDetailData.id);
      modal.show(<h6>{t('text:The_invitation_email_has_been_sent_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => fetchAll(),
      });
      setInviteUrl('');
    } catch (e) {
      modal.show(e);
    }
  };

  const showCancelModal = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information?')}
        <br />
        {t('text:If_the_modification_is_aborted,_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => {
          setEditToggle(false);
          resetAnchorUserData(anchorUserDetailData!);
        },
      },
    );
  };

  const showChangeRequestModal = (e: any) => {
    e.preventDefault();

    requestModal.show(
      <h6>
        {t('text:Would_you_like_to_request_approval_for_modification?')}
        <br />
        {t('text:The_anchor_user_modification_will_be_completed_after_approval_from_the_Authorizer')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            requestModal.close(requestModal.id);
            const data = anchorUserEditForm.getValues();
            data.targetAnchorUserId = anchorUserId;

            requestDTOParser(data);
            await requestWaitingAnchorUserRegistration(data);
            showRequestConfirmModal(confirmModal.id);
          } catch (e: any) {
            modal.show(e);

            formErrorHandler<FinancierWaitingAnchorUserRegisterRequest>(
              e,
              anchorUserEditForm.setError,
              anchorUserEditForm.clearErrors,
            );

            if (e.code === AnchorUserExceptionCode.INVALID_TARGET_AUTHORITY) {
              anchorUserEditForm.setError('authorityType', {});
            }
          }
        },
      },
    );
  };

  const onClickRegistrationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const showRequestConfirmModal = (modalId: number) => {
    confirmModal.show(<h6>{t('text:The_modification_request_has_been_completed')}</h6>, {
      modalType: ModalType.ALERT,
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        confirmModal.close(modalId);
        history.push(ROUTES_FI.MANAGE_ANCHOR.USER_LIST);
      },
    });
  };

  const onClickClipboardWriteButton = async () => {
    if (inviteUrl === null) return null;

    try {
      const inviteUrl = await requestFinancierAnchorUserInviteUrl(anchorUserId);
      if (inviteUrl === null) {
        modal.show(t('text:Click_on_the_Send_button_before_copying_the_invitation_email_link'));
      }
      setInviteUrl(inviteUrl);

      return inviteUrl;
    } catch (e) {
      modal.show(e);

      return null;
    }
  };

  const disabledForm = (): boolean => {
    // 전문으로 자동등록된 앵커 사용자는 정보 수정 불가 (otp number 제외)
    const { autoGenerated } = anchorUserDetailData ?? ({} as AnchorUserVOModel);

    return !(editToggle && !autoGenerated);
  };

  const showSendButton =
    !anchorUserDetailData?.anchorClientAutoSignUpForAnchorAdminOfVendorFinance &&
    (anchorUserDetailData?.userStatus === USER_STATUS.REGISTERED ||
      anchorUserDetailData?.userStatus === USER_STATUS.INVITED) &&
    !editToggle &&
    signInModel?.authorityType !== AUTHORITY_TYPE.ADMIN;

  return (
    <>
      <BackHeaderTitle title={t('text:Anchor_User_Information_Details')} />
      <GuideMessage message={guideMessage} />
      <div className="content-area">
        <SectionTitle>
          {signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR ? (
            <div className="d-flex justify-content-end">
              {editToggle ? (
                <>
                  <Button
                    size={ButtonSizeEnum.SM}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={showCancelModal}
                  >
                    {t('text:Cancel')}
                  </Button>
                  <Button size={ButtonSizeEnum.SM} onClick={showChangeRequestModal}>
                    {t('text:Request_Change')}
                  </Button>
                </>
              ) : (
                <Button
                  size={ButtonSizeEnum.SM}
                  onClick={e => {
                    e.preventDefault();
                    setEditToggle(prevState => !prevState);
                  }}
                  style={{ width: '60px' }}
                >
                  {t('text:Edit')}
                </Button>
              )}
            </div>
          ) : null}
        </SectionTitle>
        <FormBorder editable={editToggle}>
          <FormSubtitle title={t('text:ANCHOR_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput label={t('text:Client_Code')} value={anchorUserDetailData?.anchorClientCode ?? ''} disabled />
              <FormInput label={t('text:Anchor_Name')} value={anchorUserDetailData?.anchorClientName ?? ''} disabled />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:ANCHOR_USER_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:User_Code')}
                name={getProperty('bankUserId')}
                ref={anchorUserEditForm.register}
                disabled={disabledForm()}
                requiredOptions={{ required: true }}
                error={anchorUserEditForm.errors.bankUserId}
              />
              <FormInput
                label={t('text:User_Name')}
                name={getProperty('name')}
                ref={anchorUserEditForm.register}
                disabled={disabledForm()}
                requiredOptions={{ required: true }}
                error={anchorUserEditForm.errors.name}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Mobile')}
                name={getProperty('mobile')}
                ref={anchorUserEditForm.register}
                disabled={disabledForm()}
                requiredOptions={{ required: true }}
                error={anchorUserEditForm.errors.mobile}
              />
              <FormInput
                label={t('text:Email')}
                name={getProperty('email')}
                ref={anchorUserEditForm.register}
                disabled={disabledForm()}
                requiredOptions={{ required: true }}
                error={anchorUserEditForm.errors.email}
              >
                {/* userStatus -> REGISTERED || INVITED 이고, edit 아닐 때  send 버튼 보이기 */}
                <>
                  {anchorUserDetailData?.userStatus === USER_STATUS.INVITED &&
                    !editToggle &&
                    signInModel?.authorityType !== AUTHORITY_TYPE.ADMIN && (
                      <ClipboardWriteButton
                        className="ms-2"
                        onClickClipboardWriteButton={onClickClipboardWriteButton}
                        disabled={inviteUrl === null}
                      />
                    )}
                  {showSendButton && (
                    <Button className="ms-2" onClick={onClickSendEmail}>
                      {t('text:Send')}
                    </Button>
                  )}
                </>
              </FormInput>
            </div>
            <div className="row">
              <FormInput
                label={t('text:OTP_Number')}
                name={getProperty('anchorUserOtpSerialNo')}
                ref={anchorUserEditForm.register}
                disabled={!editToggle}
                error={anchorUserEditForm.errors.anchorUserOtpSerialNo}
              />
              <FormSelect
                label={t('text:Authority')}
                selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', [
                  AUTHORITY_TYPE.ADMIN,
                  AUTHORITY_TYPE.AUTHORIZER,
                  AUTHORITY_TYPE.OPERATOR,
                ])}
                name={getProperty('authorityType')}
                ref={anchorUserEditForm.register}
                disabled={disabledForm()}
                required
                error={anchorUserEditForm.errors.authorityType}
              />
            </div>
            {editToggle ? null : (
              <div className="row">
                <FormValue
                  className={
                    'information-form__input ' + getStatusTextClass('USER_STATUS', anchorUserDetailData?.userStatus)
                  }
                  label={t('text:User_Account_Status')}
                  value={t(`code:user-status.${anchorUserDetailData?.userStatus}`)}
                  hasStatusDescription
                  showStatusDescriptionFunc={onClickRegistrationStatus}
                />
                <FormValue
                  label={t('text:Anchor_User_ID')}
                  className="information-form__input"
                  value={anchorUserDetailData?.userLoginId}
                />
              </div>
            )}
            {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && (
              <div className="row">
                <FormValue
                  label={t('text:Account_Email')}
                  className="information-form__input"
                  value={anchorUserDetailData?.userEmail}
                  labelChildren={
                    <QuestionTooltip
                      id="account-email-tip"
                      place="top"
                      contentText={<>{t('text:This_is_the_email_where_user_will_receive_notifications')}</>}
                    />
                  }
                />
                <div className="col-6">
                  <Button
                    style={{ height: '40px' }}
                    disabled={anchorUserDetailData?.userStatus !== USER_STATUS.ACTIVATED}
                    onClick={() =>
                      history.push(
                        ROUTES_FI.MANAGE_ANCHOR.USER_REGISTERED_DETAIL_EMAIL_SETTING_BUILD_PATH(anchorUserId),
                      )
                    }
                  >
                    {t('text:Email_Notification_Setting')}
                  </Button>
                </div>
              </div>
            )}
            <input type="hidden" name={getProperty('userStatus')} ref={anchorUserEditForm.register} />
            <input type="hidden" name={getProperty('anchorClientId')} ref={anchorUserEditForm.register} />
          </FormContents>
        </FormBorder>
      </div>

      {anchorUserDetailData?.authorityType === AUTHORITY_TYPE.ADMIN && (
        <FinancierManageAnchorUserDetailAnchorAgreementList
          anchorAgreementList={anchorAgreementList?.content}
          paginate={anchorAgreementPaginate}
          pageable={anchorAgreementPageable.pageable}
        />
      )}

      <FinancierManageAnchorUserDetailDealerAgreementList
        dealerAgreementList={dealerAgreementList?.content}
        pageable={dealerAgreementPageable.pageable}
        paginate={dealerAgreementPaginate}
      />

      <FinancierManageAnchorUserDetailHistory
        userEditHistory={userEditHistory?.content}
        pageable={userEditHistoryPageable.pageable}
        paginate={waitingAnchorUserPaginate}
      />
    </>
  );
}

export default FinancierManageAnchorUserRegisteredDetail;
