import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import { TableBody, TableBorder, TableHeader, Td, TdLink, Tr } from 'components/stateless/Table';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import type { PageableType } from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestAnchorPartnersPage } from 'utils/http/api/anchor/anchor-partners';
import type { AnchorPartnersPageRequest } from 'utils/http/api/anchor/anchor-partners/requests';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

type CompleteViewProps = {
  completeViewStates: {
    anchorPartnerPage?: Pageable<AnchorPartnerVOModel[]>;
    anchorPartnerPageable: PageableType;
  };
  completeViewUtils: {
    onClickCompleteViewSearch: (e: any) => Promise<void>;
    completePaginate: (page: number, sizePerPage: number) => Promise<void>;
  };
};

const CompleteView = ({ completeViewStates, completeViewUtils }: CompleteViewProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const getAnchorPartnerProperty = useProperty<AnchorPartnersPageRequest>();
  const { getValues, reset, register, control } = useFormContext();

  const { anchorPartnerPage, anchorPartnerPageable } = completeViewStates;

  const { onClickCompleteViewSearch, completePaginate } = completeViewUtils;

  const renderRegistrationConfirmedSearchTable = (): JSX.Element => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();

      reset({});
    };

    return (
      <form>
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <SearchBorder>
          <div className="row">
            <SearchLabel label={t('text:Registration_Date')} />
            <SearchDatePicker
              placeholder={t('text:from')}
              name={getAnchorPartnerProperty('fromDate')}
              control={control}
            />
            <SearchDatePicker placeholder={t('text:to')} name={getAnchorPartnerProperty('toDate')} control={control} />
            <SearchLabel label={t('text:Supplier_Name')} />
            <SearchInput name={getAnchorPartnerProperty('partnerName')} ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Supplier_Tax_Code')} />
            <SearchInput name={getAnchorPartnerProperty('partnerTaxCode')} ref={register} />
            <SearchLabel label={t('text:Legal_Representative_Name')} />
            <SearchInput name={getAnchorPartnerProperty('representativeName')} ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Telephone')} />
            <SearchInput name={getAnchorPartnerProperty('partnerTelephone')} ref={register} />
            <SearchEmpty />
          </div>
        </SearchBorder>
        <div className="flex-center mt-3">
          <Button size={ButtonSizeEnum.LG} onClick={onClickCompleteViewSearch}>
            {t('text:Search')}
          </Button>
        </div>
      </form>
    );
  };

  const renderRegistrationConfirmedTable = (): JSX.Element => {
    const onClickConfirmedViewExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const CONFIRMED_VIEW_EXCEL_COLUMNS: ColumnOption<AnchorPartnerVOModel>[] = [
        {
          header: t('text:Registration_Date'),
          key: 'createdDateTime',
        },
        {
          header: t('text:Supplier_Name'),
          key: 'name',
        },
        {
          header: t('text:Supplier_Tax_Code'),
          key: 'partnerTaxCode',
        },
        {
          header: t('text:Registered_Office_Address'),
          key: 'address',
        },
        {
          header: t('text:Legal_Representative_Name'),
          key: 'representativeName',
        },
        {
          header: t('text:Legal_Representative_Email'),
          key: 'representativeEmail',
        },
        {
          header: t('text:Telephone'),
          key: 'telephone',
        },
      ];

      const confirmedSearchFormValues = getValues();

      try {
        const confirmedAnchorPartnerExcelPage = await requestAnchorPartnersPage(
          0,
          EXCEL_EXPORT_MAX_ROW_COUNT,
          confirmedSearchFormValues,
        );
        const confirmedAnchorPartnerPDFPage = confirmedAnchorPartnerExcelPage.content.slice(
          0,
          PDF_EXPORT_MAX_ROW_COUNT,
        );

        const jsonArrayData = confirmedAnchorPartnerExcelPage.content.map(confirmedAnchorPartnerData => ({
          createdDateTime: tableValueManage(
            confirmedAnchorPartnerData.createdDateTime,
            t('format:datetime', {
              value: confirmedAnchorPartnerData.createdDateTime,
              key: 'datetime',
            }),
          ),
          name: tableValueManage(confirmedAnchorPartnerData.name),
          partnerTaxCode: tableValueManage(confirmedAnchorPartnerData.partnerTaxCode),
          address: tableValueManage(confirmedAnchorPartnerData.address),
          representativeName: tableValueManage(confirmedAnchorPartnerData.representativeName),
          representativeEmail: tableValueManage(confirmedAnchorPartnerData.representativeEmail),
          telephone: tableValueManage(confirmedAnchorPartnerData.telephone),
        }));

        const excelExporterProps: ExportSpreadSheetProps<AnchorPartnerVOModel> = {
          jsonArrayData: jsonArrayData,
          columns: CONFIRMED_VIEW_EXCEL_COLUMNS,
          options: {
            rowHeight: [{ position: 1, height: 30 }],
          },
        };

        const pdfExporterProps: PDFExporterProps = {
          tableHeaders: getRegistrationConfirmedTableHeaders(true),
          tableBody: renderResultTable(confirmedAnchorPartnerPDFPage, true),
        };

        modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
          title: t('text:Export_File'),
          closeBtnText: t('text:Close'),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const getRegistrationConfirmedTableHeaders = (isPDFHeader = false) => {
      const headers = [
        {
          headerText: t('text:Registration_Date'),
        },
        {
          headerText: t('text:Supplier_Name'),
        },
        {
          headerText: t('text:Supplier_Tax_Code'),
        },
        {
          headerText: t('text:Registered_Office_Address'),
        },
        {
          headerText: t('text:Legal_Representative_Name'),
        },
        {
          headerText: t('text:Legal_Representative_Email'),
        },
        {
          headerText: t('text:Telephone'),
        },
      ];

      // isPDFHeader가 true이면 마지막 항목을 제외합니다.
      return isPDFHeader ? headers : [...headers, { headerText: '', colWidths: 50 }];
    };

    return (
      <>
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {anchorPartnerPage?.totalElements}
          </p>
          <Button onClick={onClickConfirmedViewExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={getRegistrationConfirmedTableHeaders()} />
          <TableBody numOfCol={getRegistrationConfirmedTableHeaders().length}>
            {renderResultTable(anchorPartnerPage?.content)}
          </TableBody>
        </TableBorder>
        <Pagination pageable={anchorPartnerPageable} paginate={completePaginate} />
      </>
    );
  };

  const renderResultTable = (data: AnchorPartnerVOModel[] | undefined, isPdf = false) => {
    return data?.map((item, index) => (
      <Tr key={index} className={isPdf ? 'virtual-table-row' : ''}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.name} />
        <Td data={item.partnerTaxCode} />
        <Td data={item.address} />
        <Td data={item.representativeName} />
        <Td data={item.representativeEmail} />
        <Td data={item.telephone} />
        {!isPdf && (
          <TdLink path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_CONFIRMED_DETAIL_BUILD_PATH(item.anchorPartnerId)} />
        )}
      </Tr>
    ));
  };

  return (
    <>
      <div className="content-area">{renderRegistrationConfirmedSearchTable()}</div>
      <div className="division-border" />
      <div className="content-area">{renderRegistrationConfirmedTable()}</div>
    </>
  );
};

export default CompleteView;
