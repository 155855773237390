import { useState } from 'react';
import type { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { debounce } from 'lodash-es';
import { CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import Icon from 'components/stateless/Icons';

import CustomTooltip from './CustomTooltip';

import type { HorizontalAlignmentType, VerticalAlignmentType } from 'recharts/types/component/DefaultLegendContent';

type AbsoulteStyleType = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

type PropsType = {
  chartData: Array<any> | undefined;
  containerWidth?: string | number;
  containerHeight?: string | number;
  leftYaxisId: string | number;
  rightYaxisId?: string | number;
  xAxisDataKey: string | number;
  mainLabelKey: string | number;
  formatXaxis?: (tickItem: string | number) => string;
  formatLeftYaxis?: (tickItem: string | number) => string;
  formatRightYaxis?: (tickItem: string | number) => string;
  leftYaxisLabelWidth?: number;

  chartMargin?: AbsoulteStyleType;
  xAxisPadding?: {
    left: number;
    right: number;
  };
  toolTipCursorStyle?: {
    stroke: string;
    strokeWidth: number;
  };
  legendStyle?: {
    align?: HorizontalAlignmentType;
    verticalAlign?: VerticalAlignmentType;
    wrapperStyle?: CSSProperties;
    iconSize?: number;
  };
  gridStyle?: {
    gridColor?: string;
    vertical?: boolean;
    horizontal?: boolean;
  };
  children?: JSX.Element;
  xAxisAllowDecimals?: boolean;
  rightYAxisAllowDecimals?: boolean;
  leftYAxisAllowDecimals?: boolean;
  additionalPayLoad?: Array<{ name: string | number; key: string | number }> | undefined;
};

export function getDataKey<T>(key: keyof T): keyof T {
  return key;
}

export default function BarLineChart(props: PropsType) {
  const {
    chartData,
    containerWidth,
    containerHeight,
    leftYaxisId,
    rightYaxisId,
    xAxisDataKey,
    mainLabelKey,
    formatXaxis,
    formatLeftYaxis,
    formatRightYaxis,
    leftYaxisLabelWidth,
    children,
    chartMargin,
    xAxisPadding,
    toolTipCursorStyle,
    legendStyle,
    gridStyle,
    xAxisAllowDecimals,
    leftYAxisAllowDecimals,
    rightYAxisAllowDecimals,
    additionalPayLoad,
  } = props;

  const { t } = useTranslation(['format']);

  const [posData, setposData] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const [tooltipWidth, setTooltipWidth] = useState<number>(0);

  const onMouseMove = (data: any) => {
    if (data.isTooltipActive) {
      setposData(data.activeCoordinate);
      const tootipElem = document.querySelector('.custom-tooltip');
      const tooltipWidthByClientRect = tootipElem?.getBoundingClientRect().width;
      if (tooltipWidthByClientRect) {
        setTooltipWidth(tooltipWidthByClientRect);
      }
    }
  };

  if (!chartData?.length)
    return (
      <div className="w-100 h-100 flex-column-center">
        <Icon.NoData size={60} />
        <div className="mt-3" style={{ fontSize: '16px', color: '#a8a8a8' }}>
          {t('text:No_data_available')}
        </div>
      </div>
    );

  return (
    <ResponsiveContainer debounce={300} minWidth={300} width={containerWidth} height={containerHeight}>
      <ComposedChart data={chartData} margin={chartMargin} onMouseMove={debounce(onMouseMove, 30)}>
        <CartesianGrid
          stroke={gridStyle?.gridColor}
          vertical={gridStyle?.vertical}
          horizontal={gridStyle?.horizontal}
        />
        <XAxis
          dataKey={xAxisDataKey}
          tickFormatter={formatXaxis}
          padding={xAxisPadding}
          allowDecimals={xAxisAllowDecimals}
          tickMargin={8}
        />
        <YAxis
          yAxisId={leftYaxisId}
          tickFormatter={formatLeftYaxis}
          allowDecimals={leftYAxisAllowDecimals}
          tickMargin={8}
          width={leftYaxisLabelWidth}
        />
        {rightYaxisId && (
          <YAxis
            yAxisId={rightYaxisId}
            tickFormatter={formatRightYaxis}
            orientation="right"
            allowDecimals={rightYAxisAllowDecimals}
            tickMargin={8}
          />
        )}
        <Tooltip
          cursor={toolTipCursorStyle}
          position={{ x: posData.x - tooltipWidth / 2, y: -32 }}
          content={<CustomTooltip mainLabelKey={mainLabelKey} additionalPayLoad={additionalPayLoad} />}
        />
        <Legend
          align={legendStyle?.align}
          verticalAlign={legendStyle?.verticalAlign}
          wrapperStyle={legendStyle?.wrapperStyle}
          iconSize={legendStyle?.iconSize}
        />
        {children}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

BarLineChart.defaultProps = {
  containerWidth: '100%',
  containerHeight: 345,
  chartMargin: { top: 50, left: 20, right: 0, bottom: 10 },
  xAxisPadding: { left: 0, right: 0 },
  toolTipCursorStyle: { stroke: '#393939', strokeWidth: 1 },
  legendStyle: {
    align: 'right',
    verticalAlign: 'top',
    wrapperStyle: { width: '300px', minWidth: 'max-content', top: 30, right: 32 },
    iconSize: 24,
  },
  gridStyle: {
    gridColor: '#E0E0E0',
    vertical: false,
    horizontal: true,
  },
  formatXaxis: (tickItem: string | number) => tickItem,
  formatLeftYaxis: (tickItem: string | number) => tickItem,
  formatRightYaxis: (tickItem: string | number) => tickItem,
  leftYaxisLabelWidth: 60,
  xAxisAllowDecimals: true,
  leftYAxisAllowDecimals: true,
  rightYAxisAllowDecimals: true,
};
