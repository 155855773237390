import type { SelectHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil, isUndefined } from 'lodash-es';

interface SelectPlaceholderOptionType {
  show: boolean;
  text?: string;
}
export interface SelectOptionType {
  label: string;
  value: any;
}

export interface SelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'placeholder'> {
  selectOptions: SelectOptionType[];
  placeholderOptions?: SelectPlaceholderOptionType;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { selectOptions, placeholderOptions, disabled = false, value, defaultValue, ...restProps } = props;
  const { t } = useTranslation();

  const { show: showPlaceholder = false, text: placeholderText = t('text:Please_select_type') } =
    placeholderOptions ?? {};

  if (!isUndefined(value) && !isUndefined(defaultValue)) {
    throw new Error("Both 'value' and 'defaultValue' props cannot be provided at the same time.");
  }

  return (
    <div className="position-relative">
      <select
        disabled={disabled}
        ref={ref}
        {...(value && { value })}
        {...(defaultValue && { defaultValue })}
        {...(showPlaceholder && isNil(value) && isNil(defaultValue) && { defaultValue: '' })}
        {...restProps}
      >
        {showPlaceholder && (
          <option value="" disabled>
            {disabled ? '' : placeholderText}
          </option>
        )}
        {selectOptions.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <FontAwesomeIcon className="select__caret" icon={faAngleDown} fontSize={12} />
    </div>
  );
});

Select.displayName = 'Select';
export default Select;
