import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormSubtitle } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import PasswordValidationBox from 'components/stateless/PasswordValidationBox/PasswordValidationBox';
import { formErrorHandler } from 'utils/error/manager';
import { requestOnDemandCheckUserExistence } from 'utils/http/api/anonymous/on-demand';
import type { CreateFinancierAndAdminUserDTO } from 'utils/http/api/anonymous/on-demand/requests';
import useModal from 'utils/modal/useModal';
import { REG_LOWERCASE_AND_NUMBER } from 'utils/validation/regExp';

import {
  CHECK_LOGIN_ID_STATUS,
  useCreateFinancierStep2Actions,
  useCreateFinancierStep2Value,
} from '../CreateFinancierStep2Provider';

type FinancierAdminInfoFormType = Pick<
  CreateFinancierAndAdminUserDTO,
  'userName' | 'employeeCode' | 'email' | 'mobile' | 'loginId' | 'newPassword' | 'confirmPassword'
>;

const FinancierAdminInformation = () => {
  const { t } = useTranslation();
  const { show: showModal } = useModal();

  const { checkLoginIdStatus } = useCreateFinancierStep2Value();
  const { updateCheckLoginIdStatus } = useCreateFinancierStep2Actions();

  const { register, errors, clearErrors, getValues, setError, watch } = useFormContext<FinancierAdminInfoFormType>();
  const { newPassword, confirmPassword } = watch();

  const renderAdminInformationForm = () => {
    return (
      <>
        <FormSubtitle title={t('text:ADMIN_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
        <FormContents>
          <div className="row">
            <FormInput
              label={t('text:User_Code')}
              name="employeeCode"
              requiredOptions={{ required: true }}
              ref={register}
              error={errors.employeeCode}
            />
            <FormInput
              label={t('text:User_Name')}
              name="userName"
              requiredOptions={{ required: true }}
              ref={register}
              error={errors.userName}
            />
          </div>
          <div className="row">
            <FormInput label={t('text:Telephone')} name="mobile" ref={register} error={errors.mobile} />
            <FormInput
              label={t('text:Email')}
              name="email"
              requiredOptions={{ required: true }}
              ref={register}
              error={errors.email}
            />
          </div>
        </FormContents>
      </>
    );
  };

  const renderAccountInformationForm = () => {
    const handleLoginIDChange = () => {
      clearErrors('loginId');

      if (REG_LOWERCASE_AND_NUMBER.test(getValues('loginId')))
        updateCheckLoginIdStatus(CHECK_LOGIN_ID_STATUS.NOT_CHECKED);
      else updateCheckLoginIdStatus(CHECK_LOGIN_ID_STATUS.BLOCKED_PATTERN);
    };

    const handleCheckIdClick = async () => {
      const loginId = getValues('loginId');
      if (!loginId) {
        setError('loginId', {});
        updateCheckLoginIdStatus(CHECK_LOGIN_ID_STATUS.INVALID);

        return;
      }

      // 초기화
      clearErrors('loginId');

      try {
        const invalidId = await requestOnDemandCheckUserExistence(loginId);

        if (invalidId) {
          updateCheckLoginIdStatus(CHECK_LOGIN_ID_STATUS.INVALID);

          showModal(
            <h6>
              {t('text:This_ID_is_already_in_use')}
              <br />
              {t('text:Please_type_in_another_ID_to_use')}
            </h6>,
          );
        } else {
          updateCheckLoginIdStatus(CHECK_LOGIN_ID_STATUS.VALID);

          showModal(
            <h6>
              {t('text:This_ID_is_available_for_use')}
              <br />
              {t('text:Please_continue_your_sign_up_process')}
            </h6>,
          );
        }
      } catch (e) {
        showModal(e);
        updateCheckLoginIdStatus(CHECK_LOGIN_ID_STATUS.NOT_CHECKED);
        formErrorHandler<{ loginId: string }>(e, setError, clearErrors);
      }
    };

    const LoginIdMessage = (() => {
      switch (checkLoginIdStatus) {
        case CHECK_LOGIN_ID_STATUS.VALID:
          return (
            <div className="text-green m-1">
              <FontAwesomeIcon icon={faCheck} /> {t('text:Complete')}
            </div>
          );

        case CHECK_LOGIN_ID_STATUS.INVALID:
          return (
            <div className="text-brick-red m-1">
              {t('text:Please_click_on_the_Check_ID_button_to_see_whether_the_ID_is_available_for_use')}
            </div>
          );

        case CHECK_LOGIN_ID_STATUS.BLOCKED_PATTERN:
          return (
            <div className="text-brick-red m-1">{t('text:ID_can_only_be_used_in_lowercase_letters_and_numbers')}</div>
          );

        default:
          return null;
      }
    })();

    const ConfirmPasswordMessage = (() => {
      if (newPassword !== confirmPassword)
        return (
          <div className="text-brick-red m-1">
            <FontAwesomeIcon icon={faBan} /> {t('text:Password_do_not_match')}
          </div>
        );

      return null;
    })();

    return (
      <>
        <FormSubtitle title={t('text:ACCOUNT_INFORMATION')} backGroundType={BackGroundType.DarkGray} />
        <FormContents>
          <div className="row">
            <FormInput
              label={t('text:ID')}
              name="loginId"
              requiredOptions={{ required: true }}
              onChange={handleLoginIDChange}
              error={LoginIdMessage ? undefined : errors.loginId}
              ref={register}
            >
              <Button
                onClick={handleCheckIdClick}
                disabled={checkLoginIdStatus === CHECK_LOGIN_ID_STATUS.BLOCKED_PATTERN}
              >
                {t('text:Check_ID')}
              </Button>
            </FormInput>
            {LoginIdMessage}
          </div>
          <div className="row">
            <FormInput
              label={t('text:Password')}
              type="password"
              name="newPassword"
              aria-labelledby="newPassword"
              autoComplete="new-password"
              requiredOptions={{ required: true }}
              onChange={() => clearErrors('newPassword')}
              ref={register}
              error={errors.newPassword}
            />
            <div className="col-6 position-parent">
              <div style={{ position: 'absolute', top: '15px', width: '350px' }}>
                <PasswordValidationBox newPassword={newPassword} />
              </div>
            </div>
          </div>
          <div className="row">
            <FormInput
              label={t('text:Confirm_Password')}
              type="password"
              name="confirmPassword"
              aria-labelledby="confirmPassword"
              autoComplete="new-password"
              requiredOptions={{ required: true }}
              onChange={() => clearErrors('confirmPassword')}
              ref={register}
              error={ConfirmPasswordMessage ? undefined : errors.confirmPassword}
            />
            {ConfirmPasswordMessage}
          </div>
        </FormContents>
      </>
    );
  };

  return (
    <FormBorder editable>
      {renderAdminInformationForm()}
      {renderAccountInformationForm()}
    </FormBorder>
  );
};
export default FinancierAdminInformation;
