import type Pageable from 'models/Pageable';
import type { ArSettlementDetailVO, ArSettlementDetailVOModel } from 'models/vo/ArSettlementDetailVO';
import { formattingToArSettlementDetailVOModel } from 'models/vo/ArSettlementDetailVO';
import type { ArSettlementVO, ArSettlementVOModel } from 'models/vo/ArSettlementVO';
import { formattingToArSettlementVOModel } from 'models/vo/ArSettlementVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorArSettlementListRequest } from './requests';

// AR 결제 그룹 목록 조회
export async function requestAnchorArSettlementList(
  pageNumber: number,
  rowCount: number,
  searchValue?: AnchorArSettlementListRequest,
): Promise<Pageable<ArSettlementVOModel[]>> {
  const response = await http.get<Pageable<ArSettlementVO[]>>({
    url: API_AC.AR_SETTLEMENTS.AR_SETTLEMENT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const arSettlementList: ArSettlementVOModel[] = response.content.map((data: ArSettlementVO) =>
    formattingToArSettlementVOModel(data),
  );
  const arSettlementPage: Pageable<ArSettlementVOModel[]> = { ...response, content: arSettlementList };

  return arSettlementPage;
}

// AR 그룹 상세 조회
export async function requestAnchorSettlementDetail(
  anchorAgreementId: number,
  settlementDate: string,
): Promise<ArSettlementDetailVOModel> {
  const response = await http.get<ArSettlementDetailVO>({
    url: API_AC.AR_SETTLEMENTS.AR_SETTLEMENT_DETAIL(anchorAgreementId, settlementDate),
  });

  return formattingToArSettlementDetailVOModel(response);
}
