import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { PageableType } from 'hooks/usePageable';
import usePageable from 'hooks/usePageable';
import type { AccountTransactionVOModel } from 'models/vo/AccountTransactionVO';
import type { BigNumber } from 'utils/bigNumber';
import {
  requestAccountTransactionList,
  requestAnchorSettlementAccountBalance,
} from 'utils/http/api/financier/account-transactions';
import useModal from 'utils/modal/useModal';

type AccountTransactionsStateType = {
  settlementAccountBalance: BigNumber;
  accountTransactions?: AccountTransactionVOModel[];
  accountTransactionsPageable: PageableType;
  fetchSettlementAccountBalance: (anchorAgreementId: number) => Promise<void>;
  fetchAccountTransactions: (anchorAgreementId: number) => Promise<void>;
};

const AccountTransactionsContext = createContext<AccountTransactionsStateType | null>(null);

export const useAccountTransactionsContext = () => {
  const context = useContext(AccountTransactionsContext);
  if (!context) {
    throw Error('useAccountTransactionsContext should be used within AccountTransactionsContext.Provider');
  }

  return context;
};

type AccountTransactionsProviderPropsType = {
  children: ReactNode;
};

/*
  TODO :: Disturbment History를 가져올 때 필요한 오늘 / 한달 전 날짜, 타임존 이슈를 나중에 해결해야 할 듯 하다
*/
const [fromDate] = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T');
const [toDate] = new Date().toISOString().split('T');

const AccountTransactionsProvider = ({ children }: AccountTransactionsProviderPropsType) => {
  const { show: showModal } = useModal();

  const [settlementAccountBalance, setSettlementAccountBalance] = useState('0');
  const [accountTransactions, setAccountTransactions] = useState<AccountTransactionVOModel[]>([]);
  const { pageable: accountTransactionsPageable, setPageable: setAccountTransactionsPageable } = usePageable();

  const fetchSettlementAccountBalance = useCallback(
    async (anchorAgreementId: number) => {
      try {
        const settlementAccountBalance = await requestAnchorSettlementAccountBalance(anchorAgreementId);
        setSettlementAccountBalance(settlementAccountBalance);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal],
  );

  const fetchAccountTransactions = useCallback(
    async (anchorAgreementId: number) => {
      try {
        const accountTransactions = await requestAccountTransactionList(
          anchorAgreementId,
          accountTransactionsPageable.currentPage,
          accountTransactionsPageable.sizePerPage,
          { fromDate: fromDate, toDate: toDate },
        );
        setAccountTransactions([...accountTransactions.content]);
        setAccountTransactionsPageable(accountTransactions);
      } catch (error) {
        showModal(error);
      }
    },
    [
      accountTransactionsPageable.currentPage,
      accountTransactionsPageable.sizePerPage,
      setAccountTransactionsPageable,
      showModal,
    ],
  );

  const value = useMemo(
    () => ({
      settlementAccountBalance,
      accountTransactions,
      accountTransactionsPageable,
      fetchSettlementAccountBalance,
      fetchAccountTransactions,
    }),
    [
      accountTransactions,
      fetchAccountTransactions,
      accountTransactionsPageable,
      settlementAccountBalance,
      fetchSettlementAccountBalance,
    ],
  );

  return <AccountTransactionsContext.Provider value={value}>{children}</AccountTransactionsContext.Provider>;
};

export default AccountTransactionsProvider;
