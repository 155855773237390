import React from 'react';
import { Link } from 'react-router-dom';

import { clsx } from 'clsx';

import Accordion from 'components/stateless/Accordion/Accordion';

import './Sidebar.scss';
import useSidebarController from './useSidebarController';

import type { SideBarMainMenuType, SideBarSubMenuType } from './useSidebarController';

interface SideBarPropsType {
  sideBarCollapsed: boolean;
}

const SideBar = ({ sideBarCollapsed }: SideBarPropsType) => {
  const { menu, isSameCurrentAndTargetPathByMenuType, openedMenuTitle, currentPath } = useSidebarController();

  const MainMenu = ({ mainMenu }: { mainMenu: SideBarMainMenuType }) => {
    const { path, title, hidden } = mainMenu;

    return (
      <div
        className={clsx('side-bar__navigation__main-menu', {
          'd-none': hidden,
          on: path && isSameCurrentAndTargetPathByMenuType(path, 'main'),
        })}
      >
        <Link to={path ? path : '#'}>{title}</Link>
      </div>
    );
  };

  const SubMenu = ({ subMenuItems }: { subMenuItems: SideBarSubMenuType[] }) => {
    return (
      <div className="side-bar__navigation__sub-menu">
        <ol>
          {subMenuItems?.map((subMenuItem, subItemIdx) => {
            const { path, title, hidden } = subMenuItem;

            return (
              !hidden && (
                <li
                  className={clsx('accordion', {
                    on:
                      isSameCurrentAndTargetPathByMenuType(path, 'main') &&
                      isSameCurrentAndTargetPathByMenuType(path, 'sub'),
                  })}
                  key={subItemIdx}
                >
                  <Link to={path}>{title}</Link>
                </li>
              )
            );
          })}
        </ol>
      </div>
    );
  };

  const renderAccordionMenu = () => {
    return menu?.map((menuItem, index) => {
      const { mainMenu, subMenu } = menuItem;

      return (
        <React.Fragment key={index}>
          {subMenu ? (
            <Accordion
              id={`accordion-menu-${index}`}
              className={clsx('accordion', {
                'd-none': mainMenu.hidden,
              })}
              defaultExpanded={openedMenuTitle === mainMenu.title}
              trigger={currentPath}
            >
              <Accordion.Header id={`accordion-menu-header-${index}`}>
                <Accordion.Trigger className="side-bar__navigation__main-menu--collapse">
                  {mainMenu.title}
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <SubMenu subMenuItems={subMenu} />
              </Accordion.Content>
            </Accordion>
          ) : (
            <MainMenu mainMenu={mainMenu} />
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="side-bar">
      <div
        className={clsx('side-bar__navigation', {
          show: !sideBarCollapsed,
          collapsed: sideBarCollapsed,
        })}
      >
        {renderAccordionMenu()}
      </div>
    </div>
  );
};

export default SideBar;
