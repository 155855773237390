import type { ChangeEvent, ReactNode } from 'react';
import { createContext, useContext } from 'react';
import type { FieldError } from 'react-hook-form';

import { passPropsToChildren } from 'utils/render';

import Radio from './Radio';
import { useFormContext } from '../index';

import type { RadioPropsType } from './Radio';

type RadioStateType = {
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

interface RadioGroupPropsType extends Omit<RadioStateType, 'methods'> {
  name?: string;
  children: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  isEditable?: boolean;
  stateValue?: string | null;
  fetchedReadOnly?: boolean;
  error?: FieldError;
  showError?: boolean;
}

const RadioContext = createContext<RadioStateType | null>(null);
RadioContext.displayName = 'RadioContext';

export const useRadioContext = () => {
  const context = useContext(RadioContext);
  if (!context) {
    throw new Error('useRadioContext should be used within RadioContext.Provider');
  }

  return context;
};

export const RadioGroup = (props: RadioGroupPropsType) => {
  const {
    defaultValue,
    onChange,
    children,
    name,
    required,
    disabled,
    isEditable,
    stateValue,
    fetchedReadOnly,
    error,
    showError,
  } = props;

  return (
    <RadioContext.Provider value={{ defaultValue, onChange }}>
      <div className="form__radio-group">
        {passPropsToChildren(children, {
          name,
          required,
          disabled,
          isEditable,
          stateValue,
          fetchedReadOnly,
          error,
          showError,
        })}
      </div>
    </RadioContext.Provider>
  );
};

export const RadioOption = (props: RadioPropsType) => {
  const { name, onChange, showError } = props;
  const {
    methods: { register, watch, errors, clearErrors },
    isEditable,
    getReadOnlyValue,
  } = useFormContext();

  const fetchedReadOnly = getReadOnlyValue(name);
  const currentCheckedValue: string = watch(name);

  const getError = () => {
    if (!errors || !name) return undefined;

    return name.includes('.') ? name.split('.').reduce((acc, part) => acc?.[part], errors) : errors[name];
  };

  return (
    <Radio
      {...props}
      ref={register}
      currentCheckedValue={currentCheckedValue}
      isEditable={isEditable}
      fetchedReadOnly={fetchedReadOnly}
      error={getError()}
      showError={showError}
      onChange={event => {
        if (getError()) clearErrors(name);
        onChange && onChange(event);
      }}
    />
  );
};
