import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type COLLATERAL_TYPE } from 'enums';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { requestFinancierAnchorAgreementCodeRegisteredInterface } from 'utils/http/api/financier/anchor-agreements';
import { getHttpErrorExceptionMessage } from 'utils/text';

const useSearchInterfaceByContactNoModalController = (collateralType: COLLATERAL_TYPE) => {
  const { t } = useTranslation(['format']);

  const [contractNo, setContractNo] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [searchedInterfaceData, setSearchedInterfaceData] = useState<AnchorAgreementDetailVOModel>(
    {} as AnchorAgreementDetailVOModel,
  );

  const isEmptyContractNo = !contractNo || contractNo.trim() === '';

  const contractNoLengthText = !isEmptyContractNo ? `${t('text:Current_text_length_is_')} ${contractNo.length}` : '';

  const handleContactNoInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setContractNo(e.target.value);
  };

  const handleSearchButtonClick = async () => {
    if (isEmptyContractNo) return setErrorMessage(t('text:Please_enter_the_agreement_number'));

    setErrorMessage('');

    try {
      const response = await requestFinancierAnchorAgreementCodeRegisteredInterface(contractNo, collateralType);
      setSearchedInterfaceData(response);
    } catch (error: any) {
      setErrorMessage(getHttpErrorExceptionMessage(error));
      setSearchedInterfaceData({} as AnchorAgreementDetailVOModel);
    }
  };

  return {
    searchedInterfaceData,
    errorMessage,
    handleSearchButtonClick,
    handleContactNoInputChange,
    contractNo,
    contractNoLengthText,
  };
};

export default useSearchInterfaceByContactNoModalController;
