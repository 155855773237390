import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Big from 'big.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { DOCUMENT_FORM_TYPE, LOAN_TERM_TYPE } from 'enums';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { removeRangeFromHoliday } from 'utils/date/date';
import { requestDealerFinancierDocumentFormsAttachmentDownload } from 'utils/http/api/dealer/financier-document-forms';
import {
  requestDealerTempAgreementAttachmentDownload,
  requestDealerTempApplicationAttachmentDownload,
} from 'utils/http/api/dealer/temp-loans';
import useModal from 'utils/modal/useModal';

import type { DealerInvoiceApplicationStep2State } from '../../useDealerInvoiceFinancingApplicationStep2State';

dayjs.extend(isBetween);
interface useFinancingApplicationProps {
  dealerInvoiceApplicationStep2State: DealerInvoiceApplicationStep2State;
  repaymentDate: string | undefined;
  loanAgreementAttachmentChangedState: {
    loanAgreementAttachmentChanged: boolean;
    setLoanAgreementAttachmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  };
  loanRequestAttachmentChangedState: {
    loanRequestAttachmentChanged: boolean;
    setLoanRequestAttachmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  };
  requestedLoanAmount: number;
}

export function useFinancingApplication({
  dealerInvoiceApplicationStep2State,
  repaymentDate,
  loanAgreementAttachmentChangedState,
  loanRequestAttachmentChangedState,
  requestedLoanAmount, // watch
}: useFinancingApplicationProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { setValue } = useFormContext();

  const {
    dealerAgreementDetail,
    documentTypes,
    dealerInvoiceCalculateLoanRequest,
    dealerInvoiceDetail,
    financierHoliday,
    dealerTempLoanByInvoice,
  } = dealerInvoiceApplicationStep2State;
  const [applicationFileName, setApplicationFileName] = useState<string | undefined>(undefined);
  const [agreementFileName, setAgreementFileName] = useState<string | undefined>(undefined);

  const { setLoanRequestAttachmentChanged } = loanRequestAttachmentChangedState;
  const { setLoanAgreementAttachmentChanged } = loanAgreementAttachmentChangedState;

  useEffect(() => {
    setAgreementFileName(dealerTempLoanByInvoice.loanAgreementAttachmentName);
    setApplicationFileName(dealerTempLoanByInvoice.loanRequestAttachmentName);
  }, [dealerTempLoanByInvoice]);

  const getFinancierHolidayDisabledRanges = useCallback((): Date[] | [] => {
    return removeRangeFromHoliday(financierHoliday?.content);
  }, [financierHoliday?.content]);

  const financingPeriodCalculate = (startDate: string | undefined, EndDate: string | undefined): string | undefined => {
    if (!repaymentDate) {
      return `- ${t('text:Days')}`;
    } else {
      return dayjs(EndDate).diff(dayjs(startDate).format('YYYY-MM-DD'), 'days').toString() + ' ' + t('text:Days');
    }
  };

  const loanTermTypeText = (loanTermUnit: number | undefined, loanTermType: string | undefined) => {
    if (loanTermType === LOAN_TERM_TYPE.DAILY) {
      return `${loanTermUnit} ${t('text:Days')}`;
    } else if (loanTermType === LOAN_TERM_TYPE.MONTHLY) {
      return `${loanTermUnit} ${t('text:Month')}`;
    }
  };

  // 서식/양식 유무에 따라 다운로드 버튼 show 또는 disabled 처리
  const showDocumentTypeBtn = (documentType: DOCUMENT_FORM_TYPE): boolean => {
    const isDocumentType = documentTypes?.includes(documentType);

    if (isDocumentType) {
      return true;
    } else {
      return false;
    }
  };

  // 서식/양식 다운로드
  const onClickApplicationDownloadForm = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerFinancierDocumentFormsAttachmentDownload(
        dealerInvoiceDetail?.financierEnterpriseId!,
        DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_REQUEST,
      );
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickAgreementDownloadForm = async (e: any) => {
    e.preventDefault();
    try {
      await requestDealerFinancierDocumentFormsAttachmentDownload(
        dealerInvoiceDetail?.financierEnterpriseId!,
        DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_AGREEMENT,
      );
    } catch (e) {
      modal.show(e);
    }
  };

  const getFinancingTerm = () => {
    return dealerAgreementDetail?.loanTermType === LOAN_TERM_TYPE.RANGE
      ? financingPeriodCalculate(dealerInvoiceDetail?.settlementDate, repaymentDate)
      : loanTermTypeText(dealerAgreementDetail?.loanTermUnit, dealerAgreementDetail?.loanTermType);
  };

  const getInterestRateAdJustment = (): string => {
    if (!dealerAgreementDetail?.preferentialInterestRate) return '-%';

    const sign = dealerAgreementDetail?.preferentialInterestRate >= 0 ? '+' : '';
    const interestRate = t('format:number', {
      value: `${dealerAgreementDetail?.preferentialInterestRate}`,
    });

    return `${sign}${interestRate}%`;
  };

  const getUseTermSpreadRate = () => {
    const useTermSpread = dealerAgreementDetail?.termSpreadList?.length > 0;

    return useTermSpread
      ? `${t('format:number', {
          value: `${dealerInvoiceCalculateLoanRequest?.termSpreadRate}`,
        })}%`
      : '-%';
  };

  const onChangeApplicationFileName = (e: any) => {
    setLoanRequestAttachmentChanged(true);
    if (e.target?.files[0]?.name) setApplicationFileName(e.target.files[0].name);
  };

  const resetApplicationFileName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoanRequestAttachmentChanged(true);
    setValue('loanRequestAttachment', null);
    setApplicationFileName(undefined);
  };

  const onChangeAgreementFileName = (e: any) => {
    setLoanAgreementAttachmentChanged(true);
    if (e.target?.files[0]?.name) setAgreementFileName(e.target.files[0].name);
  };

  const resetAgreementFileName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoanAgreementAttachmentChanged(true);
    setValue('loanAgreementAttachment', null);
    setAgreementFileName(undefined);
  };

  const onClickApplicationFileDownLoad = async () => {
    try {
      await requestDealerTempApplicationAttachmentDownload(dealerTempLoanByInvoice.tempLoanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickAgreementFileDownLoad = async () => {
    try {
      await requestDealerTempAgreementAttachmentDownload(dealerTempLoanByInvoice.tempLoanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const ltvCalculate = () => {
    const calculatorBigNumber = new CalculatorBigNumber();
    const ltv =
      requestedLoanAmount === undefined
        ? 0
        : calculatorBigNumber.add(requestedLoanAmount).div(dealerInvoiceDetail.invoiceAmount).times(100).get();

    if (Big(ltv).lt(0)) return 0;
    else
      return `${t('format:number', {
        value: ltv,
      })} %`;
  };

  return {
    applicationFileName,
    agreementFileName,
    getFinancierHolidayDisabledRanges,
    getFinancingTerm,
    getInterestRateAdJustment,
    getUseTermSpreadRate,
    showDocumentTypeBtn,
    onClickApplicationDownloadForm,
    onClickAgreementDownloadForm,
    onChangeApplicationFileName,
    resetApplicationFileName,
    onChangeAgreementFileName,
    resetAgreementFileName,
    onClickApplicationFileDownLoad,
    onClickAgreementFileDownLoad,
    ltvCalculate,
  };
}
