import type { ChangeEvent, ReactNode } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { useMemo } from 'react';
import { useEffect, useRef } from 'react';
import type { FieldError } from 'react-hook-form';

import { clsx } from 'clsx';
import { isNil } from 'lodash-es';

import { useRadioContext } from '.';
import QuestionTooltip from '../../Tooltip/QuestionTooltip';

import type { TooltipOptionType } from '..';

export interface RadioPropsType {
  name?: string;
  stateValue?: string;
  required?: boolean;
  col?: number;
  value?: string;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  className?: string;
  id?: string;
  tooltipOptions?: TooltipOptionType;
  disabled?: boolean;
  isEditable?: boolean;
  currentCheckedValue?: string;
  render?: (isParentReadOnly?: boolean, isParentRadioUnChecked?: boolean) => ReactNode;
  fetchedReadOnly?: boolean;
  error?: FieldError;
  showError?: boolean;
}

const Radio = forwardRef(
  (
    {
      name = '',
      value = '',
      stateValue, // useState로 관리되는 state 값
      label = '',
      children,
      id,
      tooltipOptions,
      disabled = false,
      currentCheckedValue, // watch로 감시중인 Checked된 formState 값
      isEditable = false,
      render,
      fetchedReadOnly,
      onChange: onChangeRadioOption,
      error,
      showError = true,
    }: RadioPropsType,
    ref,
  ) => {
    const { defaultValue, onChange } = useRadioContext();

    const inputRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => inputRef.current);

    const defaultChecked = useMemo(() => {
      if (defaultValue === undefined) return undefined;
      else return fetchedReadOnly ? undefined : defaultValue === value;
    }, [defaultValue, value, fetchedReadOnly]);

    const checked = useMemo(
      () => (stateValue === undefined ? undefined : stateValue === value),
      [stateValue, value],
    ); /*react State인 stateValue 값과 watch로 감시 중인 formState currentCheckedValue 값 모두 
       값이 변하면 리렌더링 되기 때문에  합쳐서 사용할 수 있을지 검토 */

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
      !isNil(onChangeRadioOption) && onChangeRadioOption(event);
      !isNil(onChange) && onChange(event);
    };

    useEffect(() => {
      if (inputRef.current && defaultChecked !== undefined && checked === undefined && isNil(currentCheckedValue)) {
        inputRef.current.checked = !!defaultChecked;
      }
    }, [defaultChecked, checked, currentCheckedValue]);

    const isFieldDisabled = !isEditable || disabled; // disabled 상태

    const hasInitialChecked =
      (isNil(currentCheckedValue) && defaultChecked) || checked || currentCheckedValue === value; //  초기값 존재, defaultValue와 currentCheckedValue가 모두 존재하면 currentCheckedValue가 우선

    const isOpacity = (isFieldDisabled || fetchedReadOnly) && !hasInitialChecked; // 필드 수정이 수기로 불가능하면서 초기값이 없는 경우 -----opacity 처리

    const isRadioChildrenChecked = currentCheckedValue === value || checked;

    const shouldRenderChildren = (!isFieldDisabled && !fetchedReadOnly) || hasInitialChecked; //사용자가 직접 필드 수정이 가능하거나 직접 수정이 불가능하지만 값이 있는 경우---- children 보여짐

    return (
      <div className="form__radio-wrapper">
        <div
          className={clsx('form__radio', {
            opacityApplied: isOpacity,
          })}
        >
          <input
            id={id ?? `${name}-${label}`}
            type="radio"
            className={clsx('form__radio-input', 'form-check-input', {
              'error-input-border': error && showError,
            })}
            name={name}
            onChange={handleRadioChange}
            readOnly={fetchedReadOnly}
            disabled={isFieldDisabled}
            value={value}
            ref={inputRef}
            {...(!isNil(checked) && { checked })}
          />
          {label && (
            <label htmlFor={id ?? `${name}-${label}`}>
              {label}
              {!isNil(tooltipOptions) && (
                <QuestionTooltip
                  id={tooltipOptions.id}
                  contentText={tooltipOptions.content}
                  place={tooltipOptions.place ?? 'top'}
                />
              )}
            </label>
          )}
        </div>
        {(render || children) && shouldRenderChildren && (
          <div
            className={clsx('form__radio-children', {
              'form__radio-children--unchecked': !isRadioChildrenChecked,
            })}
          >
            {render ? render(fetchedReadOnly, !isRadioChildrenChecked) : children}
          </div>
        )}
      </div>
    );
  },
);

Radio.displayName = 'Radio';

export default Radio;
