import type React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import ConfirmCheckBox from 'components/stateless/CheckBox/ConfirmCheckBox';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { BASIS_INTEREST_TYPE, INTEREST_REPAYMENT_TYPE } from 'enums';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import { wrappingGetInterestRateWithOutConditionOfHasBasisInterest } from 'utils/logic';

import { useFinancierFinancingDetailFinancingRequestInformationLogics } from './logics';

interface FinancierFinancingDetailFinancingRequestInformationProps {
  data: LoanDetailVOModel;
  factoringEnable: boolean;
  isOperatorRequest: boolean;
  financingInfoToggleState: {
    financingInfoToggle: boolean;
    setFinancingInfoToggle: React.Dispatch<React.SetStateAction<boolean>>;
  };
}
function FinancierFinancingDetailFinancingRequestInformation({
  data,
  factoringEnable,
  isOperatorRequest,
  financingInfoToggleState,
}: FinancierFinancingDetailFinancingRequestInformationProps) {
  const { t } = useTranslation(['format']);

  const showCheckbox = isOperatorRequest && !data.multipleRequested;

  const {
    handleDownloadLoanAgreementClick,
    handleDownloadLoanRequestFormClick,
    handleDownloadDebtSellingNotificationClick,
  } = useFinancierFinancingDetailFinancingRequestInformationLogics(data.loanId);

  const FactoringAPRSection = () => (
    <div className="row">
      <FormValue
        col={3}
        label={t('text:Advance_Disbursement')}
        value={data.netDisbursementAmount}
        labelChildren={
          <QuestionTooltip
            id="financing-request-information-netDisbursementAmount-tooltip"
            place="top"
            contentText={
              <>
                <div>{t('text:Requested_Financing_Amount')} ⓐ</div>
                <div>{t('text:Discount_Charge')} ⓑ</div>
                <div>-----------------------------------------------------</div>
                <div>{t('text:Advance_Disbursement')} ⓐ - ⓑ = ⓒ</div>
              </>
            }
          />
        }
        format="number"
      />
      <FormValue
        col={3}
        label={t('text:Discount_Charge')}
        value={data.principalAmount - data.netDisbursementAmount}
        format="number"
      />
      <FormValue
        col={3}
        label={t('text:Discount_Rate_APR')}
        value={wrappingGetInterestRateWithOutConditionOfHasBasisInterest({
          showExpectedTotalInterestRate: data.showExpectedTotalInterestRate,
          basisInterestBankCode: data.createdBasisInterestBankCode,
          basisInterestRate: data.createdBasisInterestRate,
          termSpreadRate: data.createdTermSpreadInterestRate,
          creditInterestRate: data.createdCreditSpreadInterestRate,
          preferentialInterestRate: data.createdPreferentialInterestRate,
        })}
      />
    </div>
  );

  const FinancingAPRSection = () => (
    <div className="row">
      <FormValue
        col={3}
        label={t('text:Interest_Payment_Method')}
        value={`${t(`code:interest-repayment-type.${data.interestRepaymentType}`)}${
          data.interestRepaymentType === INTEREST_REPAYMENT_TYPE.MONTHLY
            ? ` (${t('text:Date')} : ${data.monthlyInterestRepaymentDate})`
            : ''
        }`}
      />
      <FormValue col={3} label={t('text:Base_Interest_Type')} value={data.createdBasisInterestBankCode} />
      <FormValue
        col={3}
        label={
          data.createdBasisInterestType === BASIS_INTEREST_TYPE.FIXED
            ? t('text:Basis_Interest_Rate(APR)')
            : t('text:Base_Interest_Term')
        }
        value={
          data.createdBasisInterestType === BASIS_INTEREST_TYPE.FIXED
            ? `${t('format:number', { value: data.createdBasisInterestRate })}%`
            : data.createdBasisInterestTerm
            ? data.createdBasisInterestTerm
            : '-'
        }
      />
      <FormValue
        col={3}
        label={t('text:Expected_Interest_Rate')}
        value={wrappingGetInterestRateWithOutConditionOfHasBasisInterest({
          showExpectedTotalInterestRate: data.showExpectedTotalInterestRate,
          basisInterestBankCode: data.createdBasisInterestBankCode,
          basisInterestRate: data.createdBasisInterestRate,
          termSpreadRate: data.createdTermSpreadInterestRate,
          creditInterestRate: data.createdCreditSpreadInterestRate,
          preferentialInterestRate: data.createdPreferentialInterestRate,
        })}
      />
    </div>
  );

  const FactoringDownloadSection = () => (
    <>
      <FormSubtitle title={t('text:DEBT_SALE_AND_PURCHASE_AGREEMENT')} />
      <div className="detail-in-file-download-form">
        <div className="d-flex justify-content-between">
          <label className="information-form__label">{t('text:Download')}</label>
          <input
            type="text"
            placeholder={
              data.loanAgreementAttachmentName ? data.loanAgreementAttachmentName : t('text:no_file_attached')
            }
            className="upload-file-input"
            readOnly
          />
          <DownloadButton onClick={handleDownloadLoanAgreementClick} disabled={!data.loanAgreementAttachmentId} />
        </div>
      </div>
      <FormSubtitle title={t('text:DEBT_SELLING_NOTIFICATION')} />
      <div className="detail-in-file-download-form">
        <div className="d-flex justify-content-between">
          <label className="information-form__label">{t('text:Download')}</label>
          <input
            type="text"
            placeholder={
              data.loanFactoringNotificationAttachmentName
                ? data.loanFactoringNotificationAttachmentName
                : t('text:no_file_attached')
            }
            className="upload-file-input"
            readOnly
          />
          <DownloadButton
            onClick={handleDownloadDebtSellingNotificationClick}
            disabled={!data.loanFactoringNotificationAttachmentId}
          />
        </div>
      </div>
    </>
  );

  const FinancingDownloadSection = () => (
    <>
      <FormSubtitle title={t('text:Financing_Application_Document')} />
      <div className="detail-in-file-download-form">
        <div className="d-flex justify-content-between">
          <label className="information-form__label">{t('text:Attached_File')}</label>
          <input
            type="text"
            placeholder={data.loanRequestAttachmentName ? data.loanRequestAttachmentName : t('text:no_file_attached')}
            className="upload-file-input"
            readOnly
          />
          <DownloadButton onClick={handleDownloadLoanRequestFormClick} disabled={!data.loanRequestAttachmentId} />
        </div>
      </div>
      <FormSubtitle title={t('text:Financing_Agreement_Document')} />
      <div className="detail-in-file-download-form">
        <div className="d-flex justify-content-between">
          <label className="information-form__label">{t('text:Attached_File')}</label>
          <input
            type="text"
            placeholder={
              data.loanAgreementAttachmentName ? data.loanAgreementAttachmentName : t('text:no_file_attached')
            }
            className="upload-file-input"
            readOnly
          />
          <DownloadButton onClick={handleDownloadLoanAgreementClick} disabled={!data.loanAgreementAttachmentId} />
        </div>
      </div>
    </>
  );

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Financing_Request_Information')} />
      <FormBorder hideBorderBottom={showCheckbox}>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              className="information-form__input border-none bold-font"
              col={3}
              label={t('text:Requested_Financing_Amount')}
              value={data.principalAmount}
              format="number"
            />
            <FormValue
              className="information-form__input border-none bold-font"
              col={3}
              label={t('text:Scheduled_Disbursement_Date')}
              value={data.desiredDisburseDate}
              format="date"
            />
            <FormValue col={3} label={t('text:Scheduled_Repayment_Date')} value={data.repaymentDate} format="date" />
            <FormValue
              col={3}
              label={t('text:Financing_Term')}
              value={`${data.scheduledFinancingPeriod}  ${t('text:Days')}`}
            />
          </div>
          {factoringEnable ? <FactoringAPRSection /> : <FinancingAPRSection />}
        </FormContents>
        {factoringEnable ? !data.multipleRequested && <FactoringDownloadSection /> : <FinancingDownloadSection />}
      </FormBorder>
      {showCheckbox && (
        <ConfirmCheckBox
          checked={financingInfoToggleState.financingInfoToggle}
          id="financing-request-info-check"
          labelText={t(
            'text:I_have_checked_the_financing_request_information_above_is_correct_and_matches_the_attached_files',
          )}
          onChangeCheckBox={() => {
            financingInfoToggleState.setFinancingInfoToggle(prevState => !prevState);
          }}
        />
      )}
    </div>
  );
}

export default FinancierFinancingDetailFinancingRequestInformation;
