import { LOAN_APPROVAL_TYPE, LOAN_STATUS, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface MultipleLoanRequestRelatedLoanVO {
  loanId: number;
  successArId: number;
  arNumber: string;
  settlementDate: string;
  requestedAmount: BigNumber;
  netDisbursementAmount: BigNumber;
  status: string;
  approvalType: string;
}

export interface MultipleLoanRequestRelatedLoanVOModel extends MultipleLoanRequestRelatedLoanVO {
  status: LOAN_STATUS;
  approvalType: LOAN_APPROVAL_TYPE;
}

export function formattingToMultipleLoanRequestRelatedLoanVOModel(
  data: MultipleLoanRequestRelatedLoanVO,
): MultipleLoanRequestRelatedLoanVOModel {
  return {
    ...data,
    status: stringToEnum(LOAN_STATUS, data.status),
    approvalType: stringToEnum(LOAN_APPROVAL_TYPE, data.approvalType),
  };
}
