import type { ChangeEvent, MouseEvent } from 'react';
import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { faMinus, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import CitadCodeModal from 'components/stateless/Modal/common/CitadCodeModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import AnchorPartnerRegistrationConfirmModal from 'components/stateless/Modal/common/partner/AnchorPartnerRegistrationConfirmModal';
import AnchorPartnerRegistrationResultModal from 'components/stateless/Modal/common/partner/AnchorPartnerRegistrationResultModal';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { useUppercaseInput } from 'hooks/useUppercaseInput';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import { convertToUppercase } from 'utils/helpers';
import { requestAnchorAgreementDetail } from 'utils/http/api/anchor/anchor-agreements';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestWaitingAnchorPartnerRegist } from 'utils/http/api/anchor/waiting-anchor-partners';
import type {
  WaitingAnchorPartner,
  WaitingAnchorPartnerRegistRequest,
} from 'utils/http/api/anchor/waiting-anchor-partners/requests';
import { initializeRefValue } from 'utils/initialize';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';
import { getSignIn } from 'utils/storage/LocalStorage';
import { REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING, REG_EMAIL, REG_PHONE } from 'utils/validation/regExp';
import useValidation from 'utils/validation/useValidation';

import type { TFunction } from 'i18next';

export const getFieldNames = (t: TFunction) => {
  return {
    name: {
      name: t('text:Supplier_Name'),
      width: '200px',
    },
    partnerTaxCode: {
      name: t('text:Supplier_Tax_Code'),
      width: '150px',
    },
    representativeName: {
      name: t('text:Legal_Representative_Name'),
      width: '200px',
    },
    representativeEmail: {
      name: t('text:Legal_Representative_Email'),
      width: '200px',
    },
    telephone: {
      name: t('text:Telephone'),
      width: '120px',
    },
    address: {
      name: t('text:Registered_Office_Address'),
      width: '200px',
    },
    bankCode: {
      name: t('text:Bank_Code'),
      width: '200px',
    },
    requestedAccountBankName: {
      name: t('text:Bank_Name'),
      width: '200px',
    },
    requestedAccountBranchName: {
      name: t('text:Branch_Name'),
      width: '200px',
    },
    account: {
      name: t('text:Bank_Account_Number'),
      width: '200px',
    },
    accountOwner: {
      name: t('text:Bank_Account_Holder'),
      width: '200px',
    },
  };
};

function AnchorPartnerRegistrationStep2() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { show: showModal, id: modalId } = useModal();
  const history = useHistory();

  const { financierId } = useParams() as any;
  const userAuthorityType = getSignIn()?.authorityType;
  const { anchorAgreementId } = useParams() as any;

  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementDetailVOModel>();
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [financierClientAuthSetting, setFinancierClientAuthSetting] = useState<FinancierClientAuthSettingVOModel>();
  const [isDirectInput, setIsDirectInput] = useState(true);
  const [file, setFile] = useState<File>();
  const fileRef = useRef<HTMLInputElement>(null);

  const fieldNames = getFieldNames(t);
  const handleUppercaseInput = useUppercaseInput();

  const { register, control, reset, setValue, errors, getValues, trigger } = useForm<{
    anchorPartners: WaitingAnchorPartner[];
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray<WaitingAnchorPartner>({
    control,
    name: 'anchorPartners',
  });

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: errors.anchorPartners,
    rule: 'partnerRegister',
  });

  const fetchAll = useCallback(async () => {
    try {
      const [financierClientAuthSetting, anchorAgreementDetailData] = await Promise.all([
        requestAnchorClientAuthByFinancierId(financierId),
        requestAnchorAgreementDetail(anchorAgreementId),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierClientAuthSetting(financierClientAuthSetting);
        setAnchorAgreementData(anchorAgreementDetailData);
      });
    } catch (error) {
      showModal(error);
    }
  }, [anchorAgreementId, financierId, showModal]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
      reset({
        anchorPartners: [{}],
      });
    }
  }, [fetchAll, mounted, reset]);

  const updateCheckedValidation = () => {
    let i = 0;
    const temp = [...checkedValidation];

    for (const index of checkedRows) {
      temp.splice(index - i, 1);
      i++;
    }

    return temp;
  };

  const removeRow = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (checkedRows.length === fields.length) {
      reset({
        anchorPartners: [{}],
      });
      setCheckedValidation([]);
    } else {
      remove(checkedRows);
      setCheckedValidation(updateCheckedValidation());
    }
    setCheckedRows([]);
  };

  const appendRow = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    append({});
  };

  const handleCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const checkedRows = isChecked ? fields.map((_, index) => index) : [];
    setCheckedRows(checkedRows);
  };

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    if (event.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const validateDuplicateTaxCode = (taxCode: string, index: number): boolean => {
    const { anchorPartners } = getValues();
    const lowercasedTaxCodes = anchorPartners.map(({ partnerTaxCode }) => partnerTaxCode.toLowerCase());
    lowercasedTaxCodes.splice(index, 1);
    const filteredTaxCodes = lowercasedTaxCodes.filter(partnerTaxCode => partnerTaxCode);
    const isPassedDuplicateValidity = !filteredTaxCodes.includes(taxCode.toLowerCase());

    return isPassedDuplicateValidity;
  };

  const onClickRegister = async () => {
    const { anchorPartners } = getValues();
    const isPassedFrontValidate = await trigger();

    if (!isPassedFrontValidate) {
      setCheckedValidation(anchorPartners.map(() => true));

      return;
    }

    const registrationRequestSuccessModal = (requestResult: WaitingAnchorPartnerVOModel[]) => {
      showModal(
        <AnchorPartnerRegistrationResultModal requestResult={requestResult} currentAuthorityType={userAuthorityType} />,
        {
          modalSize: ModalSize.XL,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_AC.MANAGE_PARTNER.REGISTRATION_LIST),
        },
      );
    };

    const requestPartnerRegistration = async (verificationCode?: UserVerificationCodeRequest) => {
      const data: WaitingAnchorPartnerRegistRequest = {
        ...getValues(),
        anchorAgreementId,
        financierId,
        otpCode: verificationCode?.otpCode,
        queryValue: verificationCode?.queryValue,
      };

      try {
        const response = await requestWaitingAnchorPartnerRegist(data);
        registrationRequestSuccessModal(response.content);
      } catch (error) {
        showModal(error);
      }
    };

    showModal(<AnchorPartnerRegistrationConfirmModal anchorPartners={anchorPartners} />, {
      modalSize: ModalSize.XL,
      modalType: ModalType.CONFIRM,
      confirmBtnCb: () => {
        financierClientAuthSetting?.currentAuthorityType === AUTHORITY_TYPE.ADMIN &&
        financierClientAuthSetting.otpType !== OTP_TYPE.NONE
          ? showVerificationOtpModal()
          : requestPartnerRegistration();
      },
    });

    const showVerificationOtpModal = () => {
      const verificationCode: UserVerificationCodeRequest = {};

      showModal(
        <UserVerificationModal
          modalId={modalId}
          verificationCode={verificationCode}
          requestIdType="financierId"
          requestId={financierId}
          clientAuthSetting={financierClientAuthSetting as FinancierClientAuthSettingVOModel}
        />,
        {
          modalType: ModalType.CONFIRM,
          title: t('text:User_Verification'),
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: () => requestPartnerRegistration(verificationCode),
        },
      );
    };
  };

  const onClickCancel = () => {
    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_AC.MANAGE_PARTNER.REGISTRATION_STEP1),
      },
    );
  };

  const openCitadCodeModal = (index: number) => {
    const setBankCodeData = (data: BankCodeVOModel) => {
      setValue(`anchorPartners.${index}.bankCode`, data.bankCode);
      setValue(`anchorPartners.${index}.requestedAccountBankName`, data.bankName);
      setValue(`anchorPartners.${index}.requestedAccountBranchName`, data.branchName);
    };

    showModal(<CitadCodeModal handleBankCodeData={setBankCodeData} modalId={modalId} financierId={financierId} />, {
      title: t('text:Find_Bank_Code'),
      modalType: ModalType.ALERT,
      modalSize: ModalSize.L,
      closeBtnText: t('text:Close'),
    });
  };

  const onClickRegistrationMethodRadioButton = (value: boolean) => {
    if (value !== isDirectInput) {
      setIsDirectInput(value);
    }
  };

  const onChangeUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const formatData = (data: any) => {
      return {
        name: data[ExcelTemplatesHeader.PARTNER_NAME],
        partnerTaxCode: data[ExcelTemplatesHeader.PARTNER_TAX_CODE],
        representativeName: data[ExcelTemplatesHeader.LEGAL_REPRESENTATIVE_NAME],
        representativeEmail: data[ExcelTemplatesHeader.LEGAL_REPRESENTATIVE_EMAIL],
        telephone: data[ExcelTemplatesHeader.TELEPHONE],
        address: data[ExcelTemplatesHeader.REGISTERED_OFFICE_ADDRESS],
        bankCode: data[ExcelTemplatesHeader.BANK_CODE],
        requestedAccountBankName: data[ExcelTemplatesHeader.BANK_NAME],
        requestedAccountBranchName: data[ExcelTemplatesHeader.BRANCH_NAME],
        account: convertToUppercase(data[ExcelTemplatesHeader.BANK_ACCOUNT_NUMBER]),
        accountOwner: convertToUppercase(data[ExcelTemplatesHeader.BANK_ACCOUNT_HOLDER]),
      };
    };

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const isCsvFile = file.type === 'text/csv';

      const getFormattedData = (data: unknown[]) => {
        if (isCsvFile && data && data.length) return data.map(item => formatData(item));
        if (!isCsvFile && data && Array.isArray(data) && data[0] && Array.isArray(data[0]) && data[0].length)
          return data[0].map(item => formatData(item));
      };

      try {
        showLoadingUI();
        setCheckedValidation([]);
        setFile(file);

        const jsonArrayData = isCsvFile ? await csvToJson(file) : await excelToJson(file);
        const formattedData = getFormattedData(jsonArrayData);

        if (formattedData) {
          reset({
            anchorPartners: formattedData,
          });
        } else {
          showModal(
            <h6>
              {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
              <br />
              {t('text:Please_check_and_upload_it_again')}
            </h6>,
          );
        }
      } catch (error) {
        showModal(error);
        initializeRefValue(fileRef);
      } finally {
        unShowLoadingUI();
      }
    }
  };

  const onClickRemoveFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    initializeRefValue(fileRef);
    if (file) {
      setFile(undefined);
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Supplier_Registration')} />
      <div className="content-area">
        <StepWizard
          nth={2}
          title={[t('text:Select_Anchor_Master_Agreement'), t('text:Register_Supplier_Information')]}
        />
      </div>
      <GuideMessage
        message={[
          t('text:Enter_the_Suppliers_company_and_the_bank_account_information'),
          t(
            'text:If_the_Supplier_has_already_been_registered_under_the_Anchor_Master_Agreement_then_the_Supplier_cannot_be_registered_again',
          ),
          t('text:Check_that_the_Suppliers_tax_codes_are_correct_before_clicking_on_the_Register_button_below'),
        ]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Anchor_Master_Agreement_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue col={4} label={t('text:Financier_Name')} value={anchorAgreementData?.financierName} />
              <FormValue
                col={4}
                label={t('text:Anchor_Master_Agreement_Number')}
                value={anchorAgreementData?.contractNo}
              />
              <FormValue col={4} label={t('text:Currency')} value={anchorAgreementData?.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Supplier_Information')} />
        <h3>{t('text:Select_the_registration_method')}</h3>
        <div className="invoice-radio-group">
          <RadioButton
            id="direct-input"
            name="registration-method"
            checked={isDirectInput}
            onChange={() => onClickRegistrationMethodRadioButton(true)}
          >
            {t('text:Direct_Input')}
          </RadioButton>
          <RadioButton
            id="file-upload"
            name="registration-method"
            checked={!isDirectInput}
            onChange={() => onClickRegistrationMethodRadioButton(false)}
          >
            {t('text:File_Upload')}
          </RadioButton>
        </div>
        {!isDirectInput && (
          <div className="excel-form mb-4">
            <div className="excel-download-form d-flex">
              <label className="me-3">
                {t(
                  'text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it_After_uploading_you_can_check_the_uploaded_list_below',
                )}
                .{' '}
              </label>
              <ExcelTemplateDownloadButton
                templateFileAddress="/templates/SupplierRegistrationTemplate.xlsx"
                style={{ marginRight: '4px' }}
              />
              <ExcelTemplateDownloadButton
                downloadAnnounceText={t('text:CSV_Template')}
                templateFileAddress="/templates/SupplierRegistrationTemplate.csv"
              />
            </div>
            <div className="detail-in-file-upload-form bg-sub100">
              <div className="d-flex justify-content-between">
                <input
                  onChange={onChangeUploadFile}
                  ref={fileRef}
                  type="file"
                  name="file"
                  id="FileUpload"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{ display: 'none' }}
                />
                <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
                  {t('text:Attach_File')}
                </label>
                <div id="fileName" className="upload-file-input">
                  {file ? file.name : t('text:No_file_attached')}
                </div>
                <IconButton onClick={onClickRemoveFile} className={`delete-uploaded-excel-button ${!file && 'd-none'}`}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </IconButton>
              </div>
            </div>
          </div>
        )}

        <div className="flex-end mb-3">
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={removeRow}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
          <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>

        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '70px' }} />
              {values(fieldNames).map(({ width }, index) => (
                <col key={index} style={{ width }} />
              ))}
              {checkedValidation.length > 0 && <col style={{ width: '200px' }} />}
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <div className="text-center">
                    <input
                      className="form-check-input m-0"
                      type="checkbox"
                      id="allCheck2"
                      onChange={handleCheckAll}
                      checked={fields.length > 0 ? checkedRows.length === fields.length : false}
                    />
                  </div>
                </th>
                {values(fieldNames).map(({ name }, index) => (
                  <th key={index} scope="col">
                    {name}
                  </th>
                ))}

                {checkedValidation.length > 0 && <th scope="col">{t('text:Registration_Result')}</th>}
              </tr>
            </thead>
            <tbody>
              {fields.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td className="bg-sub100" key={index}>
                      <div className="text-center">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          id={`check-${index}`}
                          onChange={e => handleCheckChange(e, index)}
                          checked={checkedRows.includes(index)}
                        />
                      </div>
                    </td>
                    <td className={getValidationClassName('name', 'td', index)}>
                      <input
                        className={getValidationClassName('name', 'input', index)}
                        name={`anchorPartners.${index}.name`}
                        defaultValue={item.name}
                        ref={register({
                          required: true,
                          maxLength: 150,
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('partnerTaxCode', 'td', index)}>
                      <input
                        className={getValidationClassName('partnerTaxCode', 'input', index)}
                        name={`anchorPartners.${index}.partnerTaxCode`}
                        defaultValue={item.partnerTaxCode}
                        ref={register({
                          required: true,
                          maxLength: 20,
                          pattern: REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING,
                          validate: value => validateDuplicateTaxCode(value, index),
                        })}
                      />
                    </td>

                    <td className={getValidationClassName('representativeName', 'td', index)}>
                      <input
                        className={getValidationClassName('representativeName', 'input', index)}
                        name={`anchorPartners.${index}.representativeName`}
                        defaultValue={item.representativeName}
                        ref={register({
                          required: true,
                          maxLength: 150,
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('representativeEmail', 'td', index)}>
                      <input
                        className={getValidationClassName('representativeEmail', 'input', index)}
                        name={`anchorPartners.${index}.representativeEmail`}
                        defaultValue={item.representativeEmail}
                        ref={register({
                          required: true,
                          maxLength: 40,
                          pattern: REG_EMAIL,
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('telephone', 'td', index)}>
                      <input
                        className={getValidationClassName('telephone', 'input', index)}
                        name={`anchorPartners.${index}.telephone`}
                        defaultValue={item.telephone}
                        ref={register({
                          required: true,
                          minLength: 6,
                          maxLength: 20,
                          pattern: REG_PHONE,
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('address', 'td', index)}>
                      <input
                        className={getValidationClassName('address', 'input', index)}
                        name={`anchorPartners.${index}.address`}
                        defaultValue={item.address}
                        ref={register({
                          required: true,
                          maxLength: 1000,
                        })}
                      />
                    </td>

                    <td className="divTableCell">
                      <input
                        className="grid-input"
                        name={`anchorPartners.${index}.bankCode`}
                        defaultValue={item.bankCode}
                        onFocus={() => openCitadCodeModal(index)}
                        ref={register()}
                        readOnly={true}
                      />
                    </td>

                    <td className={getValidationClassName('requestedAccountBankName', 'td', index)}>
                      <input
                        className={getValidationClassName('requestedAccountBankName', 'input', index)}
                        name={`anchorPartners.${index}.requestedAccountBankName`}
                        defaultValue={item.requestedAccountBankName}
                        ref={register({
                          required: true,
                          maxLength: 150,
                        })}
                      />
                    </td>

                    <td className={getValidationClassName('requestedAccountBranchName', 'td', index)}>
                      <input
                        className={getValidationClassName('requestedAccountBranchName', 'input', index)}
                        name={`anchorPartners.${index}.requestedAccountBranchName`}
                        defaultValue={item.requestedAccountBranchName}
                        ref={register({
                          maxLength: 150,
                        })}
                      />
                    </td>

                    <td className={getValidationClassName('account', 'td', index)}>
                      <input
                        className={getValidationClassName('account', 'input', index)}
                        name={`anchorPartners.${index}.account`}
                        defaultValue={item.account}
                        ref={register({
                          required: true,
                          maxLength: 40,
                        })}
                        onChange={handleUppercaseInput}
                      />
                    </td>

                    <td className={getValidationClassName('accountOwner', 'td', index)}>
                      <input
                        className={getValidationClassName('accountOwner', 'input', index)}
                        name={`anchorPartners.${index}.accountOwner`}
                        defaultValue={item.accountOwner}
                        ref={register({
                          required: true,
                          maxLength: 150,
                        })}
                        onChange={handleUppercaseInput}
                      />
                    </td>
                    {checkedValidation[index] ? getValidationResult(index) : checkedValidation.length > 0 && <td />}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
            onClick={onClickCancel}
          >
            {t('text:Cancel')}
          </Button>
          <Button className="ms-2" size={ButtonSizeEnum.LG} onClick={onClickRegister}>
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AnchorPartnerRegistrationStep2;
