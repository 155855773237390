import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckPluginValid } from 'digital-signatures/bkav/CheckPluginValid';
import { CheckPluginGetCert } from 'digital-signatures/utils/checkPluginGetCert';

import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { AUTHORITY_TYPE, MULTIPLE_LOAN_APPROVAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { SignInModel } from 'models/SignInModel';
import type { LoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import type { MultipleLoanRequestDetailVOModel } from 'models/vo/MultipleLoanRequestDetailVO';
import type { MultipleLoanRequestRelatedLoanVOModel } from 'models/vo/MultipleLoanRequestRelatedLoanVO';
import type { MultipleLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleLoanRequestRelatedSuccessArVO';
import {
  requestFinancierApproveMultipleRequestLoanRejection,
  requestFinancierMultipleLoanAdditionalDocumentAttachments,
  requestFinancierMultipleRequestLoanApprove,
  requestFinancierMultipleRequestLoanArsList,
  requestFinancierMultipleRequestLoanCancel,
  requestFinancierMultipleRequestLoanDetail,
  requestFinancierMultipleRequestLoanDownloadAttachmentForEsign,
  requestFinancierMultipleRequestLoanReject,
  requestFinancierMultipleRequestLoanRelatedLoanList,
  requestFinancierMultipleRequestLoanRequest,
  requestFinancierMultipleRequestLoanReturn,
  requestFinancierRequestMultipleRequestLoanRejection,
} from 'utils/http/api/financier/multiple-request-loans';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';
import { getSignIn } from 'utils/storage/LocalStorage';

const AR_LIST_ROW_COUNT = 3000;

const useFinancierBulkFinancingApprovalDetailController = (multipleRequestLoansId: number) => {
  const mounted = useMounted();
  const modal = useModal();
  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation();

  const [dataState, setDataState] = useState({
    bulkFinancingDetail: {} as MultipleLoanRequestDetailVOModel,
    bulkFinancingArList: [] as MultipleLoanRequestRelatedSuccessArVOModel[],
    bulkFinancingRelatedFinancingList: [] as MultipleLoanRequestRelatedLoanVOModel[],
    requiredAdditionalDocuments: [] as LoanAdditionalDocumentAttachmentVOModel[],
  });

  const [financingInfoCheck, setFinancingInfoCheck] = useState<boolean>(false);
  const [bankAccountInfoCheck, setBankAccountInfoCheck] = useState<boolean>(false);
  const [checkedRequiredDocumentsIds, setCheckedRequiredDocumentsIds] = useState<number[]>([]);

  const { pageable: relatedFinancingPageable, setPageable: setRelatedFinancingPageablePageable } = usePageable();

  const { multipleLoanRequestStatus, approvalType } = dataState.bulkFinancingDetail;

  const isOperatorRequest =
    signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR &&
    multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPLIED &&
    approvalType !== MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST;

  const isAuthorizerRequest =
    signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER &&
    approvalType === MULTIPLE_LOAN_APPROVAL_TYPE.OPERATOR_REQUEST;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [
        fetchedBulkFinancingDetail,
        fetchedBulkFinancingArList,
        fetchedBulkFinancingRelatedFinancingList,
        fetchAdditionalDocumentAttachments,
      ] = await Promise.all([
        requestFinancierMultipleRequestLoanDetail(multipleRequestLoansId),
        requestFinancierMultipleRequestLoanArsList(multipleRequestLoansId, 0, AR_LIST_ROW_COUNT),
        requestFinancierMultipleRequestLoanRelatedLoanList(
          multipleRequestLoansId,
          relatedFinancingPageable.currentPage,
          relatedFinancingPageable.sizePerPage,
        ),
        requestFinancierMultipleLoanAdditionalDocumentAttachments(multipleRequestLoansId, 0, 1000),
      ]);
      setDataState(prevState => ({
        ...prevState,
        bulkFinancingDetail: fetchedBulkFinancingDetail,
        bulkFinancingArList: fetchedBulkFinancingArList.content,
        bulkFinancingRelatedFinancingList: fetchedBulkFinancingRelatedFinancingList.content,
        requiredAdditionalDocuments: fetchAdditionalDocumentAttachments.content,
      }));
      setRelatedFinancingPageablePageable(fetchedBulkFinancingRelatedFinancingList);
    } catch (e) {
      modal.show(e);
    }
  };

  const paginateRelatedFinancingList = async (pageNumber: number, rowCount: number) => {
    try {
      const fetchedBulkFinancingRelatedFinancingList = await requestFinancierMultipleRequestLoanRelatedLoanList(
        multipleRequestLoansId,
        pageNumber,
        rowCount,
      );
      setDataState(prevState => ({
        ...prevState,
        bulkFinancingRelatedFinancingList: fetchedBulkFinancingRelatedFinancingList.content,
      }));
      setRelatedFinancingPageablePageable(fetchedBulkFinancingRelatedFinancingList);
    } catch (e) {
      modal.show(e);
    }
  };

  const submitBulkFinancingCancel = async () => {
    const showLoanCancelConfirmModal = () => {
      modal.show(<h6>{t('text:Financing_approval_request_is_successfully_cancelled')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    };

    try {
      await requestFinancierMultipleRequestLoanCancel(multipleRequestLoansId);
      showLoanCancelConfirmModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const hardCopyConditionCheckboxValidator = () => {
    const { requiredAdditionalDocuments } = dataState;
    if (requiredAdditionalDocuments.length < 1) {
      return true;
    }

    const requiredCheckboxes = requiredAdditionalDocuments.filter(document => document.hardCopyCondition.required);

    return requiredCheckboxes.length === checkedRequiredDocumentsIds.length;
  };

  const handleOperatorBulkFinancingApproveRequestButtonClick = async () => {
    if (!financingInfoCheck || !bankAccountInfoCheck || !hardCopyConditionCheckboxValidator()) {
      return modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        closeBtnText: t('text:OK'),
      });
    }

    const submitApproveRequest = async () => {
      try {
        await requestFinancierMultipleRequestLoanRequest(multipleRequestLoansId);
        showLoanConfirmRequestOKModal();
      } catch (e) {
        modal.show(e);
      }
    };

    const showLoanConfirmRequestOKModal = () => {
      modal.show(<h6>{t('text:The_financing_application_approval_has_been_completed')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_approve_the_financing_application?')}
        <br />
        {t('text:The_financing_approval_will_be_completed_after_being_reviewed_by_the_authorizer')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => {
          submitApproveRequest();
        },
      },
    );
  };

  const handleOperatorBulkFinancingRejectButtonClick = () => {
    let reason = '';
    const submitChangeReject = async () => {
      try {
        await requestFinancierMultipleRequestLoanReject(multipleRequestLoansId, reason);
        modal.show(<h6>{t('text:The_financing_application_rejection_has_been_completed')}</h6>, {
          modalType: ModalType.ALERT,
          title: t('text:Financing_Rejection'),
          closeBtnText: t('text:OK'),
          closeBtnCb: async () => {
            await fetchAll();
            scrollToTopOfElement();
          },
        });
      } catch (e) {
        modal.show(e);
      }
    };

    modal.show(
      <h6>
        {t('text:Would_you_like_to_reject_the_financing_application?')}
        <br />
        {t(
          'text:If_you_refuse,_the_previous_status_cannot_be_reversed_and_the_financing_process_must_be_resumed_from_the_beginning',
        )}
        <br />
        {t('text:Please_enter_the_reason_for_cancelling_the_financing_application_below')}
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          await submitChangeReject();
        },
      },
    );
  };

  const submitFinancingRejectionRequest = async (reason: string) => {
    try {
      await requestFinancierRequestMultipleRequestLoanRejection(multipleRequestLoansId, reason);
      modal.show(<h6>{t('text:The_financing_rejection_request_has_been_submitted_to_the_Authorizer')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const submitFinancingRejectionApprove = async () => {
    try {
      await requestFinancierApproveMultipleRequestLoanRejection(multipleRequestLoansId);
      modal.show(<h6>{t('text:The_financing_rejection_has_been_completed')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const submitBulkFinancingReject = async (reason: string) => {
    try {
      await requestFinancierMultipleRequestLoanReturn(multipleRequestLoansId, reason);
      modal.show(<h6>{t('text:The_financing_rejection_request_has_been_reverted')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const showAuthorizerApproveConfirmModal = () => {
    modal.show(
      <h6>
        {t('text:The_financing_confirmation_has_been_completed')}
        <br />
        {t(
          'text:You_can_monitor_the_details_and_progress_of_the_financing_applied_from_the_View_Transaction_Financing_menu',
        )}
      </h6>,
      {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      },
    );
  };

  const submitDigitalSignatureFinancing = async () => {
    showLoadingUI();

    try {
      const factoringDocs = await requestFinancierMultipleRequestLoanDownloadAttachmentForEsign(multipleRequestLoansId);
      CheckPluginValid.CheckPluginValid((data: any) =>
        CheckPluginGetCert({
          data,
          modal,
          PDFInfos: factoringDocs,
          pdfSignCompletedCb: async (loanAgreementFileList: FileList, loanFactoringNotificationFileList: FileList) => {
            try {
              await requestFinancierMultipleRequestLoanApprove(multipleRequestLoansId, {
                loanAgreementAttachment: loanAgreementFileList,
                loanFactoringNotificationAttachment: loanFactoringNotificationFileList,
              });
              showAuthorizerApproveConfirmModal();
            } catch (e) {
              modal.show(e);
            }
          },
        }),
      );
    } catch (e) {
      unShowLoadingUI();
      modal.show(e);
    }

    return;
  };

  const handleAuthorizerBulkFinancingApproveButtonClick = async () => {
    const { eSignatureEnable } = dataState.bulkFinancingDetail;

    if (eSignatureEnable) {
      await submitDigitalSignatureFinancing();

      return;
    } else {
      await requestFinancierMultipleRequestLoanApprove(multipleRequestLoansId);
      showAuthorizerApproveConfirmModal();
    }
  };

  const handleAuthorizerBulkFinancingRevertButtonClick = () => {
    let reason = '';

    modal.show(
      <h6>
        {t('text:Would_you_like_to_revert_the_financing_application_approval_request?')},
        <br />
        {t('text:Please_enter_the_reason_for_the_revert_below')},
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          await submitBulkFinancingReject(reason);
        },
      },
    );
  };

  const handleRequiredAdditionalDocumentCheckboxChange = (id: number) => {
    setCheckedRequiredDocumentsIds(prevCheckedIds =>
      prevCheckedIds.includes(id) ? prevCheckedIds.filter(itemId => itemId !== id) : [...prevCheckedIds, id],
    );
  };

  return {
    state: dataState,
    paginateRelatedFinancingList,
    isAuthorizerRequest,
    isOperatorRequest,
    relatedFinancingPageable,
    checkedRequiredDocumentsIds,
    handleRequiredAdditionalDocumentCheckboxChange,
    financingInfoCheckBoxState: {
      financingInfoCheck,
      setFinancingInfoCheck,
    },
    bankAccountInfoCheckBoxState: {
      bankAccountInfoCheck,
      setBankAccountInfoCheck,
    },
    buttonClickHandlers: {
      submitBulkFinancingCancel,
      submitFinancingRejectionRequest,
      submitFinancingRejectionApprove,
      submitBulkFinancingReject,
      handleOperatorBulkFinancingApproveRequestButtonClick,
      handleOperatorBulkFinancingRejectButtonClick,
      handleAuthorizerBulkFinancingApproveButtonClick,
      handleAuthorizerBulkFinancingRevertButtonClick,
    },
  };
};

export default useFinancierBulkFinancingApprovalDetailController;
