import type Pageable from 'models/Pageable';
import type { InvoiceSummaryVO, InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import { formattingToInvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import type { LastInvoicePhaseVO, LastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import { formattingToLastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';
import type {
  FinancierApproveInvoicePhaseRequest,
  FinancierInvoiceSummaryListRequest,
  FinancierInvoiceSummaryRegisterRequest,
  FinancierRejectInvoiceRegistrationRequest,
} from 'utils/http/api/financier/invoice-summaries/request';

export async function requestFinancierInvoiceSummariesList(
  pageNumber: number,
  rowCount: number,
  financierSuccessInvoiceListRequest: FinancierInvoiceSummaryListRequest,
): Promise<Pageable<InvoiceSummaryVOModel[]>> {
  const response = await http.get<Pageable<InvoiceSummaryVO[]>>({
    url: API_FI.INVOICE_SUMMARIES.INVOICE_SUMMARY,
    data: {
      pageNumber,
      rowCount,
      ...financierSuccessInvoiceListRequest,
    },
  });

  const invoiceSummaryPageData = response.content.map(invoiceSummary =>
    formattingToInvoiceSummaryVOModel(invoiceSummary),
  );

  return {
    ...response,
    content: invoiceSummaryPageData,
  };
}

export async function requestFinancierInvoiceSummariesRegister(
  financierInvoiceSummaryRegisterRequest: FinancierInvoiceSummaryRegisterRequest,
): Promise<LastInvoicePhaseVOModel[]> {
  const response = await http.post<LastInvoicePhaseVO[]>({
    url: API_FI.INVOICE_SUMMARIES.INVOICE_SUMMARY,
    data: financierInvoiceSummaryRegisterRequest,
  });

  return response.map(item => formattingToLastInvoicePhaseVOModel(item));
}

export async function requestFinancierInvoiceSummaryDetail(invoiceSummaryId: number): Promise<InvoiceSummaryVOModel> {
  const response = await http.get<InvoiceSummaryVO>({
    url: API_FI.INVOICE_SUMMARIES.INVOICE_SUMMARY_DETAIL(invoiceSummaryId),
  });

  return formattingToInvoiceSummaryVOModel(response);
}

export async function requestFinancierLastPhaseInvoicePhasesData(
  invoiceSummaryId: number,
): Promise<LastInvoicePhaseVOModel> {
  const response = await http.get<LastInvoicePhaseVO>({
    url: API_FI.INVOICE_SUMMARIES.INVOICE_SUMMARY_LAST_PHASE(invoiceSummaryId),
  });

  return formattingToLastInvoicePhaseVOModel(response);
}

export async function requestFinancierInvoicePhaseApprove(
  financierApproveInvoicePhaseRequest: FinancierApproveInvoicePhaseRequest,
): Promise<LastInvoicePhaseVOModel> {
  const response = await http.post<LastInvoicePhaseVO>({
    url: API_FI.INVOICE_SUMMARIES.APPROVE_INVOICE_PHASE,
    data: financierApproveInvoicePhaseRequest,
  });

  return formattingToLastInvoicePhaseVOModel(response);
}

export async function requestFinancierInvoicePhaseReject(
  financierRejectInvoiceRegistrationRequest: FinancierRejectInvoiceRegistrationRequest,
): Promise<{}> {
  const response = await http.put<{}>({
    url: API_FI.INVOICE_SUMMARIES.REJECT_INVOICE_REGISTRATION,
    data: financierRejectInvoiceRegistrationRequest,
  });

  return response;
}
