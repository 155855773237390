import { useState } from 'react';

import { AUTHORITY_TYPE, ENTERPRISE_TYPE, FINANCIER_ALERT_TYPE } from 'enums';
import type { FinancierAlertSettingVOModel } from 'models/vo/FinancierAlertSettingVO';
import { requestFinancierAlertSettingList } from 'utils/http/api/financier/financier-alert-setting';
import type { FinancierAlertSetting } from 'utils/http/api/financier/financier-alert-setting/requests';
import useModal from 'utils/modal/useModal';
import { getFactoringEnable } from 'utils/storage/LocalStorage';

const getConstants = () => {
  const modal = useModal();
  const factoringEnable = getFactoringEnable();

  const RECIPIENT_AUTHORITIES: {
    [index: string]: { enterpriseType: ENTERPRISE_TYPE; authorityType: AUTHORITY_TYPE }[];
  } = {
    [FINANCIER_ALERT_TYPE.ANCHOR_AGREEMENT_MODIFICATION]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_MODIFICATION]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.ANCHOR_AGREEMENT_EXPIRATION]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_EXPIRATION]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_REGISTRATION]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_PA_OP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_PA_AU]: [
      { enterpriseType: ENTERPRISE_TYPE.AC, authorityType: AUTHORITY_TYPE.ADMIN },
      { enterpriseType: ENTERPRISE_TYPE.AC, authorityType: AUTHORITY_TYPE.HQ_OPERATOR },
      { enterpriseType: ENTERPRISE_TYPE.FI, authorityType: AUTHORITY_TYPE.OPERATOR },
    ],
    [FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_AC_OP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_FI_OP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_REGISTERED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_REGISTERED_TO_PP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_UPCOMING_SETTLEMENT_DATE]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_LOAN_CREATED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_LOAN_REQUESTED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_LOAN_APPROVED_BY_FI_OP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_LOAN_APPROVED_BY_FI_AU]: factoringEnable
      ? [
          {
            enterpriseType: ENTERPRISE_TYPE.AC,
            authorityType: AUTHORITY_TYPE.ADMIN,
          },
          {
            enterpriseType: ENTERPRISE_TYPE.AC,
            authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
          },
          {
            enterpriseType: ENTERPRISE_TYPE.DE,
            authorityType: AUTHORITY_TYPE.AUTHORIZER,
          },
        ]
      : [
          {
            enterpriseType: ENTERPRISE_TYPE.DE,
            authorityType: AUTHORITY_TYPE.AUTHORIZER,
          },
        ],
    [FINANCIER_ALERT_TYPE.AR_LOAN_DISBURSED]: factoringEnable
      ? [
          {
            enterpriseType: ENTERPRISE_TYPE.AC,
            authorityType: AUTHORITY_TYPE.ADMIN,
          },
          {
            enterpriseType: ENTERPRISE_TYPE.AC,
            authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
          },
          {
            enterpriseType: ENTERPRISE_TYPE.DE,
            authorityType: AUTHORITY_TYPE.AUTHORIZER,
          },
        ]
      : [
          {
            enterpriseType: ENTERPRISE_TYPE.DE,
            authorityType: AUTHORITY_TYPE.AUTHORIZER,
          },
        ],

    [FINANCIER_ALERT_TYPE.AR_LOAN_REJECTED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_LOAN_REPAYMENT_DATE]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.AR_EARLY_LOAN_REPAYMENT_REQUEST]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_PA_OP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_PA_AU]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_AC_OP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_AC_AU]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_FI_OP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_REGISTRED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_LOAN_CREATED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_LOAN_REQUESTED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_LOAN_APPROVED_BY_FI_OP]: [
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_LOAN_APPROVED_BY_FI_AU]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_LOAN_DISBURSED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_LOAN_REJECTED]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_LOAN_REPAYMENT_DATE]: [
      {
        enterpriseType: ENTERPRISE_TYPE.DE,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
    ],
    [FINANCIER_ALERT_TYPE.INVOICE_EARLY_LOAN_REPAYMENT_REQUEST]: [
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.HQ_OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.AC,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.ADMIN,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.AUTHORIZER,
      },
      {
        enterpriseType: ENTERPRISE_TYPE.FI,
        authorityType: AUTHORITY_TYPE.OPERATOR,
      },
    ],
  };

  const commonFinancierAlertSetting: FinancierAlertSetting[] = [
    {
      alertType: FINANCIER_ALERT_TYPE.ANCHOR_AGREEMENT_MODIFICATION,
      emailEnabled: false,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_MODIFICATION,
      emailEnabled: false,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.ANCHOR_AGREEMENT_EXPIRATION,
      emailEnabled: false,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_EXPIRATION,
      emailEnabled: false,
      smsEnabled: false,
    },
  ];

  const vendorFinanceProgramPartnerManagementFinancierAlertSetting: FinancierAlertSetting[] = [
    {
      alertType: FINANCIER_ALERT_TYPE.DEALER_AGREEMENT_REGISTRATION,
      emailEnabled: false,
      smsEnabled: false,
    },
  ];

  const vendorFinanceProgramARRegistrationFinancierAlertSetting: FinancierAlertSetting[] = [
    {
      alertType: FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_PA_OP,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_PA_AU,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_REGISTRATION_BY_AC_OP,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_REGISTERED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_REGISTERED_TO_PP,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_UPCOMING_SETTLEMENT_DATE,
      emailEnabled: true,
      smsEnabled: false,
    },
  ];

  const vendorFinanceProgramFinancingFinancierAlertSetting: FinancierAlertSetting[] = [
    {
      alertType: FINANCIER_ALERT_TYPE.AR_LOAN_CREATED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_LOAN_REQUESTED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_LOAN_APPROVED_BY_FI_OP,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_LOAN_APPROVED_BY_FI_AU,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_LOAN_DISBURSED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_LOAN_REJECTED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_LOAN_REPAYMENT_DATE,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.AR_EARLY_LOAN_REPAYMENT_REQUEST,
      emailEnabled: true,
      smsEnabled: false,
    },
  ];
  const dealerFinanceProgramInvoiceRegistrationFinancierAlertSetting: FinancierAlertSetting[] = [
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_PA_OP,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_PA_AU,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_AC_OP,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_AC_AU,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_REGISTRATION_BY_FI_OP,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_REGISTRED,
      emailEnabled: true,
      smsEnabled: false,
    },
  ];

  const dealerFinanceProgramFinancingFinancierAlertSetting: FinancierAlertSetting[] = [
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_LOAN_CREATED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_LOAN_REQUESTED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_LOAN_APPROVED_BY_FI_OP,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_LOAN_APPROVED_BY_FI_AU,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_LOAN_DISBURSED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_LOAN_REJECTED,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_LOAN_REPAYMENT_DATE,
      emailEnabled: true,
      smsEnabled: false,
    },
    {
      alertType: FINANCIER_ALERT_TYPE.INVOICE_EARLY_LOAN_REPAYMENT_REQUEST,
      emailEnabled: true,
      smsEnabled: false,
    },
  ];

  return {
    modal,
    RECIPIENT_AUTHORITIES,
    commonFinancierAlertSetting,
    vendorFinanceProgramPartnerManagementFinancierAlertSetting,
    vendorFinanceProgramARRegistrationFinancierAlertSetting,
    vendorFinanceProgramFinancingFinancierAlertSetting,
    dealerFinanceProgramInvoiceRegistrationFinancierAlertSetting,
    dealerFinanceProgramFinancingFinancierAlertSetting,
  };
};
export const useFinancierEmailNotificationSetting = () => {
  const {
    modal,
    RECIPIENT_AUTHORITIES,
    commonFinancierAlertSetting,
    vendorFinanceProgramPartnerManagementFinancierAlertSetting,
    vendorFinanceProgramARRegistrationFinancierAlertSetting,
    vendorFinanceProgramFinancingFinancierAlertSetting,
    dealerFinanceProgramInvoiceRegistrationFinancierAlertSetting,
    dealerFinanceProgramFinancingFinancierAlertSetting,
  } = getConstants();

  const [selectedCommonFinancierAlertSetting, setSelectedCommonFinancierAlertSetting] =
    useState<FinancierAlertSetting[]>(commonFinancierAlertSetting);
  const [
    selectedVendorFinanceProgramPartnerManagementFinancierAlertSetting,
    setSelectedVendorFinanceProgramPartnerManagementFinancierAlertSetting,
  ] = useState<FinancierAlertSetting[]>(vendorFinanceProgramPartnerManagementFinancierAlertSetting);
  const [
    selectedVendorFinanceProgramARRegistrationFinancierAlertSetting,
    setSelectedVendorFinanceProgramARRegistrationFinancierAlertSetting,
  ] = useState<FinancierAlertSetting[]>(vendorFinanceProgramARRegistrationFinancierAlertSetting);
  const [
    selectedVendorFinanceProgramFinancingFinancierAlertSetting,
    setSelectedVendorFinanceProgramFinancingFinancierAlertSetting,
  ] = useState<FinancierAlertSetting[]>(vendorFinanceProgramFinancingFinancierAlertSetting);
  const [
    selectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting,
    setSelectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting,
  ] = useState<FinancierAlertSetting[]>(dealerFinanceProgramInvoiceRegistrationFinancierAlertSetting);
  const [
    selectedDealerFinanceProgramFinancingFinancierAlertSetting,
    setSelectedDealerFinanceProgramFinancingFinancierAlertSetting,
  ] = useState<FinancierAlertSetting[]>(dealerFinanceProgramFinancingFinancierAlertSetting);

  const fetchFinancierAlertSettingList = async (pageNumber: number = 1, rowCount: number = 10): Promise<void> => {
    try {
      const alertSettingResponse = await requestFinancierAlertSettingList(pageNumber, rowCount, {
        alertTypes: Object.values(FINANCIER_ALERT_TYPE),
      });

      classifyFinancierAlertOptions(alertSettingResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const classifyFinancierAlertOptions = (alertSettingResponse: FinancierAlertSettingVOModel[]): void => {
    const mappingAlertType = (item: FinancierAlertSetting): FINANCIER_ALERT_TYPE => item.alertType;

    const commonFinancierAlertSettingOptions = selectedCommonFinancierAlertSetting.map(mappingAlertType);
    const vendorFinanceProgramPartnerManagementFinancierAlertSettingOptions =
      selectedVendorFinanceProgramPartnerManagementFinancierAlertSetting.map(mappingAlertType);
    const vendorFinanceProgramARRegistrationFinancierAlertSettingOptions =
      selectedVendorFinanceProgramARRegistrationFinancierAlertSetting.map(mappingAlertType);
    const vendorFinanceProgramFinancingFinancierAlertSettingOptions =
      selectedVendorFinanceProgramFinancingFinancierAlertSetting.map(mappingAlertType);
    const dealerFinanceProgramInvoiceRegistrationFinancierAlertSettingOptions =
      selectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting.map(mappingAlertType);
    const dealerFinanceProgramFinancingFinancierAlertSettingOptions =
      selectedDealerFinanceProgramFinancingFinancierAlertSetting.map(mappingAlertType);

    const emailAndSmsEnabledSetting = (
      defaultAlertSettingList: FinancierAlertSetting[],
      alertSettingResponseItem: FinancierAlertSettingVOModel,
    ) => {
      const alertSettingList = [...defaultAlertSettingList];
      const targetIndex = alertSettingList.findIndex(
        oldItem => oldItem.alertType === alertSettingResponseItem.alertType,
      );

      if (targetIndex >= 0) {
        alertSettingList[targetIndex].emailEnabled = alertSettingResponseItem.emailEnabled;
        alertSettingList[targetIndex].smsEnabled = alertSettingResponseItem.smsEnabled;
      }

      return alertSettingList;
    };

    alertSettingResponse.forEach(alertSettingResponseItem => {
      if (commonFinancierAlertSettingOptions.includes(alertSettingResponseItem.alertType))
        setSelectedCommonFinancierAlertSetting(defaultAlertSettingList =>
          emailAndSmsEnabledSetting(defaultAlertSettingList, alertSettingResponseItem),
        );

      if (
        vendorFinanceProgramPartnerManagementFinancierAlertSettingOptions.includes(alertSettingResponseItem.alertType)
      )
        setSelectedVendorFinanceProgramPartnerManagementFinancierAlertSetting(defaultAlertSettingList =>
          emailAndSmsEnabledSetting(defaultAlertSettingList, alertSettingResponseItem),
        );

      if (vendorFinanceProgramARRegistrationFinancierAlertSettingOptions.includes(alertSettingResponseItem.alertType))
        setSelectedVendorFinanceProgramARRegistrationFinancierAlertSetting(defaultAlertSettingList =>
          emailAndSmsEnabledSetting(defaultAlertSettingList, alertSettingResponseItem),
        );

      if (vendorFinanceProgramFinancingFinancierAlertSettingOptions.includes(alertSettingResponseItem.alertType))
        setSelectedVendorFinanceProgramFinancingFinancierAlertSetting(defaultAlertSettingList =>
          emailAndSmsEnabledSetting(defaultAlertSettingList, alertSettingResponseItem),
        );

      if (
        dealerFinanceProgramInvoiceRegistrationFinancierAlertSettingOptions.includes(alertSettingResponseItem.alertType)
      )
        setSelectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting(defaultAlertSettingList =>
          emailAndSmsEnabledSetting(defaultAlertSettingList, alertSettingResponseItem),
        );

      if (dealerFinanceProgramFinancingFinancierAlertSettingOptions.includes(alertSettingResponseItem.alertType))
        setSelectedDealerFinanceProgramFinancingFinancierAlertSetting(defaultAlertSettingList =>
          emailAndSmsEnabledSetting(defaultAlertSettingList, alertSettingResponseItem),
        );
    });
  };

  return {
    RECIPIENT_AUTHORITIES,
    fetchFinancierAlertSettingList,
    commonAlertSettingState: {
      selectedCommonFinancierAlertSetting,
      setSelectedCommonFinancierAlertSetting,
    },
    partnerManagementAlertState: {
      selectedVendorFinanceProgramPartnerManagementFinancierAlertSetting,
      setSelectedVendorFinanceProgramPartnerManagementFinancierAlertSetting,
    },
    arRegistrationAlertState: {
      selectedVendorFinanceProgramARRegistrationFinancierAlertSetting,
      setSelectedVendorFinanceProgramARRegistrationFinancierAlertSetting,
    },
    vendorFinancingAlertState: {
      selectedVendorFinanceProgramFinancingFinancierAlertSetting,
      setSelectedVendorFinanceProgramFinancingFinancierAlertSetting,
    },
    invoiceRegistrationAlertState: {
      selectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting,
      setSelectedDealerFinanceProgramInvoiceRegistrationFinancierAlertSetting,
    },
    dealerFinancingAlertState: {
      selectedDealerFinanceProgramFinancingFinancierAlertSetting,
      setSelectedDealerFinanceProgramFinancingFinancierAlertSetting,
    },
  };
};
