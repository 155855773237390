import type React from 'react';

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from 'components/stateless/Button/IconButton';

type QuestionButtonPropsType = {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const QuestionButton = ({ onClick, ...restProps }: QuestionButtonPropsType) => (
  <IconButton
    className="question-button"
    onClick={event => {
      event.preventDefault();
      onClick && onClick(event);
    }}
    {...restProps}
  >
    <FontAwesomeIcon icon={faQuestionCircle} />
  </IconButton>
);

export default QuestionButton;
