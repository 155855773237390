import { COLLATERAL_TYPE, COMMON_APPROVAL_TYPE, CURRENCY_TYPE, ENTERPRISE_TYPE, stringToEnum } from 'enums';

export interface WaitingAnchorPartnerAccountVO {
  waitingAnchorPartnerAccountId: number;
  account: string;
  accountOwner: string;
  requestedAccountBankName: string;
  requestedAccountBranchName: string;
  partnerName: string;
  partnerTaxCode: string;
  partnerBusinessCode: string;
  accountBankName: string;
  accountBankCodeId: number;
  accountBankCode: string;
  anchorAgreementId: number;
  anchorName: string;
  anchorAgreementContractNo: string;
  collateralType: string;
  currencyType: string;
  responsibleFinancierId: number;
  responsibleFinancierName: string;
  responsibleBranchId: number;
  responsibleBranchName: string;
  responsibleBranchCode: string;
  operatorUserId: number;
  operatorUserLoginId: string;
  operatorUserName: string;
  operatorEnterpriseId: number;
  operatorEnterpriseType: string;
  operatorEnterpriseName: string;
  authorizerUserId: number;
  authorizerUserLoginId: string;
  authorizerUserName: string;
  authorizerEnterpriseId: number;
  authorizerEnterpriseType: string;
  authorizerEnterpriseName: string;
  approvalType: string;
  declined: boolean;
  declinedReason: string;
  returnReason: string;
  targetAnchorPartnerAccountId: number;
  assignedBranchCode: string;
  assignedBranchName: string;
  createdDateTime: string;
  updatedDateTime: string;
}

export interface WaitingAnchorPartnerAccountVOModel extends WaitingAnchorPartnerAccountVO {
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
  operatorEnterpriseType: ENTERPRISE_TYPE;
  authorizerEnterpriseType: ENTERPRISE_TYPE;
  approvalType: COMMON_APPROVAL_TYPE;
}

export function formattingToWaitingAnchorPartnerAccountVOModel(
  data: WaitingAnchorPartnerAccountVO,
): WaitingAnchorPartnerAccountVOModel {
  return {
    ...data,
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    operatorEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.operatorEnterpriseType),
    authorizerEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.authorizerEnterpriseType),
    approvalType: stringToEnum(COMMON_APPROVAL_TYPE, data.approvalType),
  };
}
