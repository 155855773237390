import CreateFinancierProvider from './CreateFinancierProvider';
import CreateFinancierStepWrapper from './steps';

const CreateFinancier = () => {
  return (
    <CreateFinancierProvider>
      <CreateFinancierStepWrapper />
    </CreateFinancierProvider>
  );
};

export default CreateFinancier;
