import {
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  CURRENCY_TYPE,
  FINANCIER_TYPE,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  stringToEnum,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { getDday } from 'utils/date/date';
import { hasValue } from 'utils/valueManager/ValueManager';

export interface LoanVO {
  loanId: number;
  financierId: number;
  financierName: string;
  financierLoanId: string;
  currencyType: string;
  invoiceNumber: string;
  referenceNumber: string;
  dealerCodeByAnchor: string;
  dealerClientId: number;
  dealerClientCode: string;
  dealerClientName: string;
  anchorClientId: number;
  anchorClientCode: string;
  anchorClientName: string;
  dealerEnterpriseId: number;
  dealerName: string;
  anchorEnterpriseId: number;
  anchorName: string;
  createdDateTime: string;
  approvedDateTime: string;
  requestedDateTime: string;
  disbursedDate: string;
  desiredDisburseDate: string;
  repaymentDate: string;
  repaidDate: string;
  disbursedAmount: number;
  loanStatus: string;
  createdBasisInterestBankCode: string;
  createdBasisInterestType: string;
  createdBasisInterestRate: number;
  createdCreditSpreadInterestRate: number;
  createdTermSpreadInterestRate: number;
  createdPreferentialInterestRate: number;
  createdTotalInterestRateWithoutBasis: number;
  createdFeeAmount: number;
  approvedBasisInterestBankCode: string;
  approvedBasisInterestType: string;
  approvedBasisInterestRate: number;
  approvedCreditSpreadInterestRate: number;
  approvedTermSpreadInterestRate: number;
  approvedPreferentialInterestRate: number;
  approvedTotalInterestRateWithoutBasis: number;
  approvedFeeAmount: number;
  disbursedBasisInterestBankCode: string;
  disbursedBasisInterestType: string;
  disbursedBasisInterestRate: number;
  disbursedCreditSpreadInterestRate: number;
  disbursedTermSpreadInterestRate: number;
  disbursedPreferentialInterestRate: number;
  disbursedTotalInterestRateWithoutBasis: number;
  disbursedFeeAmount: number;
  loanApprovalType: string;
  successInvoiceId: number;
  dealerAgreementId: number;
  maxExtensibleLoanCount: number;
  extendedLoanCount: number;
  principalAmount: number;
  extendInProgress: boolean;
  earlyRepaymentInProgress: boolean;
  collateralType: string;
  financierCode: string;
  successArId: number;
  arNumber: string;
  overdueDays: number;
  approvedDisburseDate: string;
  repaidDelayAmount: number;
  repaidInterestAmount: number;
  repaidPrincipalAmount: number;
  totalDelayAmount: number;
  totalInterestAmount: number;
  accruedInterestAmount: number;
  outstandingAccruedInterestAmount: number;
  billOfExchangeNo: string;
  netDisbursementAmount: BigNumber;
  factoringEnable: boolean;
  eSignatureEnable: boolean;
}

export interface LoanVOModel extends LoanVO {
  currencyType: CURRENCY_TYPE;
  loanStatus: LOAN_STATUS;
  createdBasisInterestType: BASIS_INTEREST_TYPE;
  approvedBasisInterestType: BASIS_INTEREST_TYPE;
  disbursedBasisInterestType: BASIS_INTEREST_TYPE;
  loanApprovalType: LOAN_APPROVAL_TYPE;
  collateralType: COLLATERAL_TYPE;
  financierCode: FINANCIER_TYPE;
  // only front
  repaidAmount?: number;
  remainingFinancingTerm?: string;
  outstandingBalance: BigNumber; // disbursedAmount - repaidPrincipalAmount
}

export function formattingToLoanVOModel(data: LoanVO): LoanVOModel {
  const calculatorBigNumber = new CalculatorBigNumber();

  const getRemainingFinancingTerm = (loanStatus: LOAN_STATUS, repaymentDate: string) => {
    if ((loanStatus === LOAN_STATUS.DISBURSED || loanStatus === LOAN_STATUS.OVERDUE) && hasValue(repaymentDate)) {
      return getDday(repaymentDate);
    }
  };

  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
    createdBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.createdBasisInterestType),
    approvedBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.approvedBasisInterestType),
    disbursedBasisInterestType: stringToEnum(BASIS_INTEREST_TYPE, data.disbursedBasisInterestType),
    loanApprovalType: stringToEnum(LOAN_APPROVAL_TYPE, data.loanApprovalType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    financierCode: stringToEnum(FINANCIER_TYPE, data.financierCode),
    // only front
    remainingFinancingTerm: getRemainingFinancingTerm(stringToEnum(LOAN_STATUS, data.loanStatus), data.repaymentDate),
    outstandingBalance: calculatorBigNumber.add(data.disbursedAmount).minus(data.repaidPrincipalAmount).get(), // disbursedAmount - repaidPrincipalAmount
  };
}
