import type Pageable from 'models/Pageable';
import type {
  PotentialPartnerFinancingApplicationDocumentVO,
  PotentialPartnerFinancingApplicationDocumentVOModel,
} from 'models/vo/PotentialPartnerFinancingApplicationDocumentVO';
import { formattingToPotentialPartnerFinancingApplicationDocumentVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDocumentVO';
import http, { ContentType } from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

import type {
  AnPotentialPartnerFinancingApplicationDocumentCreate,
  AnPotentialPartnerFinancingApplicationDocumentUpdate,
} from './request';

/**
 * desc :: 약정 심사 신청 건 관련 제출 문서 조회
 * params :: emailCode / potentialPartnerFinancingApplicationId / pageable
 * **/
export async function requestAnPotentialPartnerFinancingApplicationsDocumentList(
  pageNumber: number,
  rowCount: number,
  code: string,
  potentialPartnerFinancingApplicationId: number,
): Promise<Pageable<PotentialPartnerFinancingApplicationDocumentVOModel[]>> {
  const response = await http.get<Pageable<PotentialPartnerFinancingApplicationDocumentVO[]>>({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS.DOCUMENTS,
    data: { pageNumber, rowCount, code, potentialPartnerFinancingApplicationId },
  });

  return {
    ...response,
    content: response.content.map((data: PotentialPartnerFinancingApplicationDocumentVO) =>
      formattingToPotentialPartnerFinancingApplicationDocumentVOModel(data),
    ),
  };
}

/**
 * desc :: 잠재 파트너가 제출한 문서 다운로드
 * params :: potentialPartnerFinancingApplicationDocumentId
 * **/
export async function requestAnPotentialPartnerFinancingApplicationDocumentDownload(
  potentialPartnerFinancingApplicationDocumentId: number,
  code: string,
): Promise<void> {
  await http.download(
    API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS.DOWNLOAD_DOCUMENT(
      potentialPartnerFinancingApplicationDocumentId,
    ),
    { code },
  );
}

/**
 * desc :: 잠재 파트너 - 추가 문서 등록
 * params :: potentialPartnerFinancingApplicationDocumentId / AnPotentialPartnerFinancingApplicationDocumentCreate
 * **/
export async function requestAnPotentialPartnerFinancingApplicationDocumentAdditionalDocumentRegister(
  data: AnPotentialPartnerFinancingApplicationDocumentCreate,
): Promise<PotentialPartnerFinancingApplicationDocumentVOModel> {
  const response = await http.post<PotentialPartnerFinancingApplicationDocumentVO>({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS.ADDITIONAL_DOCUMENTS,
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToPotentialPartnerFinancingApplicationDocumentVOModel(response);
}

/**
 * desc :: 잠재 파트너가 제출한 문서 수정
 * params :: potentialPartnerFinancingApplicationDocumentId / AnPotentialPartnerFinancingApplicationDocumentUpdate
 * **/
export async function requestAnPotentialPartnerFinancingApplicationDocumentUpdate(
  potentialPartnerFinancingApplicationDocumentId: number,
  data: AnPotentialPartnerFinancingApplicationDocumentUpdate,
): Promise<PotentialPartnerFinancingApplicationDocumentVOModel> {
  const response = await http.put<PotentialPartnerFinancingApplicationDocumentVO>({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS.DOCUMENT_DETAIL(
      potentialPartnerFinancingApplicationDocumentId,
    ),
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToPotentialPartnerFinancingApplicationDocumentVOModel(response);
}

/**
 * desc :: 잠재 파트너가 제출한 문서 삭제
 * params :: potentialPartnerFinancingApplicationDocumentId
 * **/
export async function requestAnPotentialPartnerFinancingApplicationDocumentDelete(
  potentialPartnerFinancingApplicationDocumentId: number,
  emailCode: string,
): Promise<void> {
  await http.delete({
    url: API_AN.POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS.DOCUMENTS,
    data: {
      potentialPartnerFinancingApplicationDocumentId,
      emailCode,
    },
  });
}
