import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

interface PropsType {
  numOfCol: number;
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
}

function BasicTableBody({ children, numOfCol }: PropsType) {
  const { t } = useTranslation();

  const renderNoDataAvailable = () => {
    return (
      <tr>
        <td colSpan={numOfCol} className="text-center">
          {t('text:no_data_available')}
        </td>
      </tr>
    );
  };

  return <tbody className="bg-white">{isEmpty(children) ? renderNoDataAvailable() : children}</tbody>;
}

export default BasicTableBody;
