import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { Border } from 'components/templates/section';

import useLoadAgreementController from './useLoadAgreementController';
import '../../sections.scss';

const LoadAgreement = () => {
  const { t } = useTranslation(['format']);
  const { loadAgreementDescription, handleLoadAgreementButtonClick, isEditable, isSearchAgreementTextVisible } =
    useLoadAgreementController();

  return (
    <Border className="section__load-agreement" isEditable={isEditable}>
      <Button size={ButtonSizeEnum.LG} onClick={handleLoadAgreementButtonClick} disabled={!isEditable}>
        {isSearchAgreementTextVisible ? t('text:Search_Agreement') : t('text:Update_Agreement')}
      </Button>
      <p>{loadAgreementDescription}</p>
    </Border>
  );
};

export default LoadAgreement;
