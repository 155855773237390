import type React from 'react';
import type { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { COLLATERAL_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { SystemWaitingAnchorAgreementListRequest } from 'utils/http/api/system/waiting-anchor-agreements/requests';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);

  const onClickConfirmationStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={[
          COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
          COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
          COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
        ]}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="COMMON_APPROVAL_TYPE"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Client_Code'),
    },
    {
      headerText: t('text:Anchor_Tax_Code'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Expiration_Date'),
    },
    {
      headerText: t('text:Approval_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickConfirmationStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const COMMON_APPROVAL_TYPES_SELECT_OPTIONS = [
    {
      label: t('text:All'),
      value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
    },
    {
      label: t('text:Requested'),
      value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
    },
    {
      label: t('text:Reverted'),
      value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
    },
    {
      label: t('text:Cancelled'),
      value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
    },
  ];

  return {
    mounted,
    modal,
    history,
    t,
    SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_AGREEMENT_TABLE_HEADERS,
    COMMON_APPROVAL_TYPES_SELECT_OPTIONS,
    onClickConfirmationStatus,
  };
};

interface SystemMonitorAnchorAgreementWaitingTabProps {
  waitingForApprovalAnchorAgreementListData: Pageable<WaitingAnchorAgreementVOModel[]>;
  useForm: UseFormMethods<SystemWaitingAnchorAgreementListRequest>;
  onClickSearchWaiting: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  waitingForApprovalAnchorAgreementPaginate(pageNumber: number, rowCount: number): Promise<void>;
  waitingForApprovalAnchorAgreementPageable: PageableType;
}

function SystemMonitorAnchorAgreementWaitingTab({
  waitingForApprovalAnchorAgreementListData,
  useForm,
  onClickSearchWaiting,
  waitingForApprovalAnchorAgreementPaginate,
  waitingForApprovalAnchorAgreementPageable,
}: SystemMonitorAnchorAgreementWaitingTabProps) {
  const {
    t,
    SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_AGREEMENT_TABLE_HEADERS,
    COMMON_APPROVAL_TYPES_SELECT_OPTIONS,
    onClickConfirmationStatus,
  } = getConstants();

  const {
    register: waitingForApprovalAnchorAgreementSearchFormRegister,
    reset: resetWaitingForApprovalAnchorAgreementSearchForm,
    control: waitingForApprovalAnchorAgreementSearchFormControl,
  } = useForm;

  const renderSearchedWaitingForApprovalAnchorAgreementTableBody = (): JSX.Element[] | undefined => {
    const getProgramTypeText = (collateralType: COLLATERAL_TYPE): string => {
      switch (collateralType) {
        case COLLATERAL_TYPE.AR:
          return t('text:Vendor_Finance');
        case COLLATERAL_TYPE.INVOICE:
          return t('text:Dealer_Finance');
      }
    };

    const getApprovalTypeText = (approvalType: COMMON_APPROVAL_TYPE): string => {
      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Request_Cancelled');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Registered');
        default:
          return '';
      }
    };

    return waitingForApprovalAnchorAgreementListData?.content?.map((item, index) => (
      <Tr key={index}>
        <Td data={getProgramTypeText(item.collateralType)} />
        <Td data={item.financierName} />
        <Td data={item.anchorClientName} />
        <Td data={item.anchorClientCode} />
        <Td data={item.anchorClientTaxCode} />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={item.startDate} format="date" />
        <Td data={item.expiryDate} format="date" />
        <Td
          data={getApprovalTypeText(item.approvalType)}
          className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
        />
        <TdLink
          path={ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_WAITING_DETAIL_BUILD_PATH(item.waitingAnchorAgreementId)}
          state={{ supportedCollateralType: item.collateralType }}
        />
      </Tr>
    ));
  };

  return (
    <>
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={() => resetWaitingForApprovalAnchorAgreementSearchForm()}
          >
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={waitingForApprovalAnchorAgreementSearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={waitingForApprovalAnchorAgreementSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name="anchorClientCode" ref={waitingForApprovalAnchorAgreementSearchFormRegister} />
              <SearchLabel label={t('text:Anchor_Tax_Code')} />
              <SearchInput name="anchorClientTaxCode" ref={waitingForApprovalAnchorAgreementSearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name="contractNo" ref={waitingForApprovalAnchorAgreementSearchFormRegister} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                ref={waitingForApprovalAnchorAgreementSearchFormRegister}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Effective_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="fromStartDate"
                control={waitingForApprovalAnchorAgreementSearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name="toStartDate"
                control={waitingForApprovalAnchorAgreementSearchFormControl}
              />
              <SearchLabel label={t('text:Expiration_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="fromExpiryDate"
                control={waitingForApprovalAnchorAgreementSearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name="toExpiryDate"
                control={waitingForApprovalAnchorAgreementSearchFormControl}
              />
            </div>
            <div className="row">
              <SearchLabel
                label={t('text:Approval_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickConfirmationStatus}
              />
              <SearchSelect
                name="approvalTypes"
                ref={waitingForApprovalAnchorAgreementSearchFormRegister}
                selectOptions={COMMON_APPROVAL_TYPES_SELECT_OPTIONS}
              />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                ref={waitingForApprovalAnchorAgreementSearchFormRegister}
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearchWaiting}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {waitingForApprovalAnchorAgreementPageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_AGREEMENT_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_WAITING_FOR_APPROVAL_ANCHOR_AGREEMENT_TABLE_HEADERS.length}>
            {renderSearchedWaitingForApprovalAnchorAgreementTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination
          pageable={waitingForApprovalAnchorAgreementPageable}
          paginate={waitingForApprovalAnchorAgreementPaginate}
        />
      </div>
    </>
  );
}

export default SystemMonitorAnchorAgreementWaitingTab;
