import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';
interface PropsType {
  numOfCol?: number;
  children?: ReactNode;
  id?: string;
}
function TableBody({ children, numOfCol, id }: PropsType) {
  const { t } = useTranslation();

  const renderNoDataAvailable = () => {
    return (
      <tr>
        <td colSpan={numOfCol} className="text-center">
          {t('text:no_data_available')}
        </td>
      </tr>
    );
  };

  return (
    <tbody id={id} className="bg-white">
      {isEmpty(children) ? renderNoDataAvailable() : children}
    </tbody>
  );
}

export default TableBody;
