import type { FinancierClientVO, FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { formattingToFinancierClientVOModel } from 'models/vo/FinancierClientVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

export async function requestInvitedFinancierClient(code: string): Promise<FinancierClientVOModel> {
  const response = await http.get<FinancierClientVO>({ url: API_AN.FINANCIER_CLIENTS, data: { code } });

  return formattingToFinancierClientVOModel(response);
}
