import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';

import { tableValueManage } from 'utils/valueManager/ValueManager';

import type { TextAlignType } from '..';
import type { TFunctionResult } from 'i18next';

type FormatType = 'number' | 'date' | 'datetime' | 'percent' | 'code';

interface FormValuePropsType {
  className?: string;
  textAlign?: TextAlignType;
  value?: string | number | TFunctionResult | boolean;
  subValue?: string | number | TFunctionResult | boolean;
  format?: FormatType;
  leftUnit?: ReactNode;
  rightUnit?: ReactNode;
}

const Value = ({
  className = '',
  textAlign = 'text-left',
  value,
  subValue,
  leftUnit,
  rightUnit,
  format,
}: FormValuePropsType) => {
  const { t } = useTranslation(['format']);

  const formatData = (value: any, format?: FormatType) => {
    switch (format) {
      case 'date':
        return t('format:date', {
          value: value,
          key: 'date',
        });
      case 'datetime':
        return t('format:datetime', {
          value: value,
          key: 'datetime',
        });
      case 'number':
        if (typeof value === 'number' || typeof value === 'string') return t('format:number', { value: value });
        else return value;
      case 'percent':
        if (typeof value === 'number') {
          return `${t('format:number', { value: value })}%`;
        } else {
          return value;
        }
      case 'code':
        const status = value.split('.');

        return status[1] === 'undefined' || status[1] === 'null' ? '-' : value;
      default:
        return value;
    }
  };

  return (
    <div className="flex-align-center">
      {leftUnit && <span className="unit">{leftUnit}</span>}
      <div className={clsx('form__input border-none', { [textAlign]: textAlign, [className]: className })}>
        {value?.toString() ? formatData(value, format) : tableValueManage(value)}
        {subValue && ` (${subValue.toString() ? formatData(subValue, format) : tableValueManage(subValue)})`}
      </div>
      {rightUnit && <span className="unit">{rightUnit}</span>}
    </div>
  );
};

export default Value;
