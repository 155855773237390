import type Pageable from 'models/Pageable';
import type { BranchVO, BranchVOModel } from 'models/vo/BranchVO';
import { formattingToBranchVOModel } from 'models/vo/BranchVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemFinancierBranchListRequest } from './requests';

export async function requestSystemFinancierBranchList(
  pageNumber: number,
  rowCount: number,
  data: SystemFinancierBranchListRequest,
): Promise<Pageable<BranchVOModel[]>> {
  const response = await http.get<Pageable<BranchVO[]>>({
    url: API_SY.BRANCHES.BRANCH_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToBranchVOModel(data)),
  };
}
