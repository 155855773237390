import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import usePageable from 'hooks/usePageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { requestFinancierAnchorAgreementList } from 'utils/http/api/financier/anchor-agreements';
import {
  requestFinancierWaitingAnchorDealerApprove,
  requestFinancierWaitingAnchorDealerCancel,
  requestFinancierWaitingAnchorDealerDetail,
  requestFinancierWaitingAnchorDealerReject,
} from 'utils/http/api/financier/waiting-anchor-dealers';
import useModal from 'utils/modal/useModal';

const useFinancierAnchorDealerRegistrationWaitingDetailPageState = () => {
  const modal = useModal();
  const { t } = useTranslation();
  const [waitingAnchorDealerInfo, setWaitingAnchorDealerInfo] = useState<WaitingAnchorDealerVOModel>();
  const [relatedAnchorAgreement, setRelatedAnchorAgreement] = useState<AnchorAgreementVOModel[]>();
  const { pageable: agreementPageable, setPageable: setAgreementPageable } = usePageable();
  const { waitingAnchorDealerId } = useParams() as any;

  async function fetchAll() {
    try {
      const waitingAnchorDealerResponse = await requestFinancierWaitingAnchorDealerDetail(waitingAnchorDealerId);
      const relatedAnchorAgreementResponse = await requestFinancierAnchorAgreementList(1, 10, {
        anchorClientId: waitingAnchorDealerResponse.anchorClientId,
      });

      setWaitingAnchorDealerInfo(waitingAnchorDealerResponse);
      setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
      setAgreementPageable(relatedAnchorAgreementResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchAnchorDealer = async () => {
    try {
      const anchorDealerResponse = await requestFinancierWaitingAnchorDealerDetail(waitingAnchorDealerId);
      setWaitingAnchorDealerInfo(anchorDealerResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const paginate = async (pageNumber: number, sizePerPage: number) => {
    const relatedAnchorAgreementResponse = await requestFinancierAnchorAgreementList(pageNumber, sizePerPage, {
      anchorClientId: waitingAnchorDealerInfo?.anchorClientId,
    });

    setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
    setAgreementPageable(relatedAnchorAgreementResponse);
  };

  const cancelRequest = async () => {
    try {
      await requestFinancierWaitingAnchorDealerCancel([waitingAnchorDealerId]);
      fetchAnchorDealer();
    } catch (error) {
      modal.show(error);
    }
  };

  const revertRequest = async (reason: string) => {
    try {
      await requestFinancierWaitingAnchorDealerReject([waitingAnchorDealerId], reason);
      modal.show(<h6>{t('text:The_Dealer_registration_request_has_been_reverted')}</h6>, {
        closeBtnCb: () => fetchAnchorDealer(),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const approveRequest = async () => {
    try {
      await requestFinancierWaitingAnchorDealerApprove({
        waitingAnchorDealerIds: [waitingAnchorDealerId],
      });
      modal.show(<h6>{t('text:The_dealer_has_been_registered_successfully')}</h6>, {
        closeBtnCb: () => fetchAnchorDealer(),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  return {
    fetchAll,
    paginate,
    agreementPageable,
    relatedAnchorAgreement,
    waitingAnchorDealerInfo,
    cancelRequest,
    approveRequest,
    revertRequest,
  };
};

export default useFinancierAnchorDealerRegistrationWaitingDetailPageState;
