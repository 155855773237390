import { useFinancingOptionContext } from './FinancingOptionContext';

const useFinancingOptionViewModel = () => {
  const { anchorFinancingOption, updateAllFinancingOption, updateFinancingOption, fetchFinancingOptionDetail } =
    useFinancingOptionContext();

  return {
    anchorFinancingOption,
    updateAllFinancingOption,
    updateFinancingOption,
    fetchFinancingOptionDetail,
  };
};

export default useFinancingOptionViewModel;
