import { base64 } from 'digital-signatures/bkav/base64';
import { FILE_TYPE } from 'digital-signatures/enums';
import i18n from 'i18next';

import { unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import type { ModalContextProps } from 'utils/modal/ModalContext';

import { SignBase64PDF } from '../signBase64PDF';
import { SignTrxNoticeCallback } from '../signTrxNoticeCallback';

type SignTrxAppCallbackProps = {
  data: any;
  modal: ModalContextProps;
  trxNoticePDFInfo: string;
  pdfSignCompletedCb: (loanAgreementFileList: FileList, loanFactoringNotificationFileList: FileList) => void;
};

export function SignTrxAppCallback({ data, modal, trxNoticePDFInfo, pdfSignCompletedCb }: SignTrxAppCallbackProps) {
  const decodeData = base64.decode(data)!;
  const iRet = parseInt(decodeData, 10);

  let dataRet;
  let isValid = true;
  switch (iRet) {
    case 0:
      unShowLoadingUI();
      dataRet = "You don't have authorities";
      modal.show(<h6>{i18n.t('text:You_do_not_have_the_digital_signature_authorities')}</h6>);
      isValid = false;
      break;
    case 1:
      // swal('Signed successfully');
      break;
    case 2:
      unShowLoadingUI();
      dataRet = 'Error signing: Data has been signed';
      modal.show(<h6>{i18n.t('text:This_document_has_already_been_signed')}</h6>);
      isValid = false;
      break;
    case 3:
      unShowLoadingUI();
      dataRet = 'No digital deed found';
      modal.show(
        <h6>
          {i18n.t('text:The_certificate_for_digital_signature_could_not_be_found')}
          <br />
          {i18n.t('text:Please_check_your_USB_token')}
        </h6>,
      );
      isValid = false;
      break;
    case 4:
      unShowLoadingUI();
      dataRet = 'Input data is not properly formatted';
      modal.show(<h6>{i18n.t('text:Input_data_for_digital_signature_is_not_properly_formatted')}</h6>);
      isValid = false;
      break;
    case 5:
      unShowLoadingUI();
      dataRet = 'There was an error during the signing process';
      modal.show(<h6>{i18n.t('text:An_error_occurred_during_the_signing_process')}</h6>);
      isValid = false;
      break;
    case 6:
      unShowLoadingUI();
      dataRet = 'There was an error saving the signature';
      modal.show(<h6>{i18n.t('text:An_error_occurred_while_saving_the_digital_signature')}</h6>);
      isValid = false;
      break;
    case 13:
      unShowLoadingUI();
      dataRet = 'User canceled';
      modal.show(<h6>{i18n.t('text:The_user_canceled_digital_signature')}</h6>);
      isValid = false;
      break;
    default:
      dataRet = data;
      // swal('Signed successfully');
      break;
  }
  if (!isValid) return;
  const resultSplit = dataRet.split('*');
  const SignedTrxAppData = resultSplit[0];

  SignBase64PDF(trxNoticePDFInfo, FILE_TYPE.APPLICATION_NOTICE, modal, (data: any) =>
    SignTrxNoticeCallback({
      data,
      modal,
      SignedTrxAppData,
      pdfSignCompletedCb,
    }),
  );
}
