import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { DEALER_AGREEMENT_STATUS, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { FinancierClientHistoryVOModel } from 'models/vo/FinancierClientHistoryVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { requestSystemPartnerAgreementList } from 'utils/http/api/system/dealer-agreements';
import { requestSystemEnterpriseFinancierAuthSetting } from 'utils/http/api/system/enterprises';
import { requestSyFinancierClientHistories } from 'utils/http/api/system/financier-client-histories';
import { requestSystemFinancierClientData } from 'utils/http/api/system/financier-clients';
import useModal from 'utils/modal/useModal';

const getConstant = (isOTPUsed: boolean) => {
  const { t } = useTranslation(['format']);

  const PARTNER_AGREEMENT_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t(`text:Program_Type`),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Responsible_Branch_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Agreement_Suspension'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t(`text:Edited_Time`),
      colWidths: 120,
    },
    {
      headerText: t('text:Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t(`text:Partner_Name`),
      colWidths: 150,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 120,
    },
    {
      headerText: t('text:Fax'),
      colWidths: 120,
    },
    {
      headerText: t('text:Company_Registration_Number'),
      colWidths: 120,
    },
    {
      headerText: t(`text:Tax_Code`),
      colWidths: 120,
    },
    {
      headerText: t(`text:Registered_Office_Address`),
      colWidths: 250,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Legal_Representative_Title'),
      colWidths: 150,
    },
    {
      headerText: t('text:Legal_Representative_Email'),
      colWidths: 150,
    },
    {
      headerText: t('text:Updated_User_ID/Name'),
      colWidths: 150,
    },
  ];

  if (isOTPUsed) {
    EDIT_HISTORY_TABLE_HEADERS.splice(EDIT_HISTORY_TABLE_HEADERS.length - 1, 0, {
      headerText: t('text:OTP_Verification'),
      colWidths: 150,
    });
  }

  return {
    t,
    PARTNER_AGREEMENT_TABLE_HEADERS,
    EDIT_HISTORY_TABLE_HEADERS,
  };
};

interface PartnerInformationType extends Omit<FinancierClientVOModel, 'otpBypassed'> {
  otpBypassed: string;
}

function SystemMonitorPartnerDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const financierClientId = (useParams() as any).id;

  const { pageable: partnerAgreementPageable, setPageable: setPartnerAgreementPageable } = usePageable();
  const { pageable: editHistoryPageable, setPageable: setEditHistoryPageable } = usePageable();

  const [partnerInformation, setPartnerInformation] = useState<PartnerInformationType>();
  const [partnerAgreementPage, setPartnerAgreementPage] = useState<Pageable<DealerAgreementVOModel[]>>();
  const [editHistoryPage, setEditHistoryPage] = useState<Pageable<FinancierClientHistoryVOModel[]>>();
  const [isOTPUsed, setIsOTPUsed] = useState(false);

  const { t, PARTNER_AGREEMENT_TABLE_HEADERS, EDIT_HISTORY_TABLE_HEADERS } = getConstant(isOTPUsed);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [partnerInformation, partnerAgreementList, editHistoryList] = await Promise.all([
        requestSystemFinancierClientData(financierClientId),
        fetchPartnerAgreementList(partnerAgreementPageable.currentPage, partnerAgreementPageable.sizePerPage),
        requestSyFinancierClientHistories(
          editHistoryPageable.currentPage,
          editHistoryPageable.sizePerPage,
          financierClientId,
        ),
      ]);

      const { otpType } = await requestSystemEnterpriseFinancierAuthSetting(partnerInformation.financierId);

      const partnerInfo = {
        ...partnerInformation,
        otpBypassed: partnerInformation.otpBypassed ? t('text:Not_Applied') : t('text:Applied'),
      };

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerInformation(partnerInfo);
        setPartnerAgreementPage(partnerAgreementList);
        setPartnerAgreementPageable(partnerAgreementList);
        setEditHistoryPage(editHistoryList);
        setEditHistoryPageable(editHistoryList);
        setIsOTPUsed(otpType !== OTP_TYPE.NONE);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchPartnerAgreementList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
  ): Promise<Pageable<DealerAgreementVOModel[]>> => {
    return await requestSystemPartnerAgreementList(selectedPageNumber, selectedRowCount, {
      dealerClientId: financierClientId,
    });
  };

  const fetchAndSetPartnerAgreementList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
  ): Promise<void> => {
    try {
      const partnerAgreementList = await fetchPartnerAgreementList(selectedPageNumber, selectedRowCount);

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerAgreementPage(partnerAgreementList);
        setPartnerAgreementPageable(partnerAgreementList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchEditHistoryList = async (selectedPageNumber: number = 1, selectedRowCount: number = 10): Promise<void> => {
    try {
      const editHistoryList = await requestSyFinancierClientHistories(
        selectedPageNumber,
        selectedRowCount,
        financierClientId,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setEditHistoryPage(editHistoryList);
        setEditHistoryPageable(editHistoryList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderPartnerAgreementListTable = (): JSX.Element[] | JSX.Element | undefined => {
    const renderAgreementStatus = (agreementStatus: DEALER_AGREEMENT_STATUS): JSX.Element | undefined => {
      switch (agreementStatus) {
        case DEALER_AGREEMENT_STATUS.ACTIVATED:
          return <td className="text-bold-dark-green">{t('text:Not_Suspended')}</td>;
        case DEALER_AGREEMENT_STATUS.SUSPENDED:
          return <td className="text-bold-brick-red">{t('text:Suspended')}</td>;
        default:
          return <td>{agreementStatus}</td>;
      }
    };

    return partnerAgreementPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={item.branchName} />
        <Td data={item.branchCode} />
        <Td data={item.startDate} format="date" />
        <Td data={item.expiryDate} format="date" />
        {renderAgreementStatus(item.dealerAgreementStatus)}
        <TdLink
          path={ROUTES_SY.MONITOR_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.dealerAgreementId)}
          state={{ financierId: item.financierId }}
        />
      </Tr>
    ));
  };

  const renderEditHistoryListTable = (): JSX.Element[] | JSX.Element | undefined =>
    editHistoryPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.updatedDateTime} format="datetime" />
        <Td data={item.financierClientCode} />
        <Td data={item.name} />
        <Td data={item.telephone} />
        <Td data={item.fax} />
        <Td data={item.clientBusinessCode} />
        <Td data={item.clientTaxCode} />
        <Td data={item.address} />
        <Td data={item.representativeName} />
        <Td data={item.representativePosition} />
        <Td data={item.representativeEmail} />
        {isOTPUsed && <Td data={item.otyBypassed ? t('text:Not_Applied') : t('text:Applied')} />}
        <Td data={`${item.updateLoginId} / ${item.updateUserName}`} />
      </Tr>
    ));

  return (
    <>
      <BackHeaderTitle title={partnerInformation?.financierClientName} />

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Partner_Information')} />
          <FormBorder>
            <FormContents>
              <div className="row">
                <FormValue
                  col={6}
                  label={t('text:Partner_Client_Code')}
                  value={partnerInformation?.financierClientCode}
                />
                <FormValue col={6} label={t(`text:Partner_Name`)} value={partnerInformation?.financierClientName} />
              </div>
              <div className="row">
                <FormValue col={3} label={t(`text:Tax_Code`)} value={partnerInformation?.financierClientTaxCode} />
                <FormValue
                  col={3}
                  label={t('text:Company_Registration_Number')}
                  value={partnerInformation?.financierClientBusinessCode}
                />
                <FormValue col={3} label={t('text:Telephone')} value={partnerInformation?.financierClientTelephone} />
                <FormValue col={3} label={t('text:Fax')} value={partnerInformation?.financierClientFax} />
              </div>
              <div className="row">
                <FormValue
                  col={isOTPUsed ? 3 : 6}
                  label={t('text:Legal_Representative_Name')}
                  value={partnerInformation?.representativeName}
                />
                <FormValue
                  col={3}
                  label={t('text:Legal_Representative_Title')}
                  value={partnerInformation?.representativePosition}
                />
                <FormValue
                  col={3}
                  label={t('text:Legal_Representative_Email')}
                  value={partnerInformation?.representativeEmail}
                />
                {isOTPUsed && (
                  <FormValue col={3} label={t('text:OTP_Verification')} value={partnerInformation?.otpBypassed} />
                )}
              </div>
              <div className="row">
                <FormValue
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  value={partnerInformation?.financierClientAddress}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Master_Agreement_List')} />
        <TableBorder>
          <TableHeader header={PARTNER_AGREEMENT_TABLE_HEADERS} />
          <TableBody numOfCol={PARTNER_AGREEMENT_TABLE_HEADERS.length}>{renderPartnerAgreementListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={partnerAgreementPageable} paginate={fetchAndSetPartnerAgreementList} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={EDIT_HISTORY_TABLE_HEADERS} />
          <TableBody numOfCol={EDIT_HISTORY_TABLE_HEADERS.length}>{renderEditHistoryListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={editHistoryPageable} paginate={fetchEditHistoryList} />
      </div>
    </>
  );
}

export default SystemMonitorPartnerDetail;
