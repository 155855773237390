import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { AUTHORITY_TYPE, PARTNER_STATUS } from 'enums';
import type { WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';

type FinancierUploadedPartnerRegistrationResultModalPropsType = {
  requestResult: WaitingAnchorPartnerVOModel[];
  userAuthorityType: AUTHORITY_TYPE | undefined;
};

function FinancierUploadedPartnerRegistrationResultModal({
  requestResult,
  userAuthorityType,
}: FinancierUploadedPartnerRegistrationResultModalPropsType): JSX.Element {
  const { t } = useTranslation(['format']);
  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Uploaded_Supplier_Name'),
    },
    {
      headerText: t('text:Tax_Code'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Bank_Code'),
    },
    {
      headerText: t('text:Bank_Account_Number'),
    },
    {
      headerText: t('text:Bank_Account_Holder'),
    },
    {
      headerText: t('text:Registration_Result'),
    },
  ];

  const renderMessage = (): JSX.Element | null => {
    if (userAuthorityType === AUTHORITY_TYPE.AUTHORIZER) {
      return (
        <h6>
          {t('text:The_registration_has_been_completed')}
          <br />
          {t('text:Please_check_the_result_of_the_Uploaded_Supplier_registration_below')}
        </h6>
      );
    } else if (userAuthorityType === AUTHORITY_TYPE.OPERATOR) {
      return (
        <h6>
          {t('text:The_registration_request_has_been_submitted_for_confirmation')}
          <br />
          {t('text:Please_check_the_result_of_the_Uploaded_Supplier_registration_below')}
          <br />
          {t(
            'text:Only_successfully_requested_companies_will_be_submitted_to_the_Authorizer_for_registration_confirmation',
          )}
        </h6>
      );
    } else {
      return null;
    }
  };

  const getPartnerStatusText = (status: PARTNER_STATUS, exceptionMessage?: string) => {
    const message = exceptionMessage && exceptionMessage.split(':').at(-1)?.trim();

    switch (status) {
      case PARTNER_STATUS.REGISTERED:
        return <td className="text-bold-blue-main600">{t('text:Pass')}</td>;
      case PARTNER_STATUS.DUPLICATE_TAX_CODE:
        return <td className="text-red">{t('text:Fail_Duplicate_Supplier_Tax_Code')}</td>;
      case PARTNER_STATUS.ALREADY_REGISTERED_ACCOUNT:
        return <td className="text-red">{t('text:Fail_This_supplier_is_already_registered_to_the_agreement')}</td>;
      case PARTNER_STATUS.ON_APPROVAL_PROGRESS_ANCHOR_PARTNER:
        return <td className="text-red">{t('text:Fail_This_Suppliers_approval_is_in_progress')}</td>;
      case PARTNER_STATUS.INVALID_APPROVE_REQUEST:
        return <td className="text-red">{t('text:Fail_The_confirmation_request_is_invalid')}</td>;
      case PARTNER_STATUS.INVALID_BANK_CODE:
        return (
          <td className="text-red">
            {t('text:Fail_The_bank_code_information_is_missing_from_the_Suppliers_bank_account')}
          </td>
        );
      case PARTNER_STATUS.FAILED:
        return <td className="text-red">{t('text:Fail_The_supplier_information_contains_errors')}</td>;
      case PARTNER_STATUS.ANCHOR_PARTNER_INTERFACE_REQUEST_FAILED:
        return (
          <td className="text-red">
            {t('text:Fail_The_financier_has_rejected_the_Supplier_registration')} {message}
          </td>
        );
      default:
        return <td>{status}</td>;
    }
  };

  const totalNumberOfSuccessfullyRegisteredCompanies = (): number => {
    return requestResult.filter(({ anchorPartnerStatus }) => anchorPartnerStatus === PARTNER_STATUS.REGISTERED).length;
  };

  return (
    <>
      {renderMessage()}
      <br />
      <ul className="invoice-registration-sum">
        <li>
          • {t('text:Total_Number_of_Successfully_Registered_Companies')} :{' '}
          <strong>{t('format:number', { value: totalNumberOfSuccessfullyRegisteredCompanies() })}</strong>
        </li>
      </ul>
      <br />
      <TableBorder>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>
          {requestResult.map((partner, index) => (
            <Tr key={index}>
              <Td data={partner.name} />
              <Td data={partner.partnerTaxCode} />
              <Td data={partner.representativeName} />
              <Td data={partner.representativeEmail} />
              <Td data={partner.telephone} />
              <Td data={partner.address} />
              <Td data={partner.accountBankCode} />
              <Td data={partner.account} />
              <Td data={partner.accountOwner} />
              {getPartnerStatusText(partner.anchorPartnerStatus, partner.exceptionMessage)}
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
    </>
  );
}

export default FinancierUploadedPartnerRegistrationResultModal;
