import type Pageable from 'models/Pageable';
import type { EnterpriseVO, EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { formattingToEnterpriseVOModel } from 'models/vo/EnterpriseVO';
import type {
  FinancierClientAuthSettingVO,
  FinancierClientAuthSettingVOModel,
} from 'models/vo/FinancierClientAuthSettingVO';
import { formattingToFinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type {
  FinancierInterfaceSettingVO,
  FinancierInterfaceSettingVOModel,
} from 'models/vo/FinancierInterfaceSettingVO';
import { formattingToFinancierInterfaceSettingVOModel } from 'models/vo/FinancierInterfaceSettingVO';
import http, { ContentType } from 'utils/http';
import API_SY from 'utils/http/api/system';

import type {
  SystemEnterpriseFinancierAuthSettingUpdateRequest,
  SystemEnterpriseFinancierRegisterRequest,
  SystemEnterpriseFinancierSettingUpdateRequest,
  SystemEnterpriseListRequest,
  SystemEnterpriseUpdateRequest,
} from './requests';

export async function requestSystemEnterpriseList(
  pageNumber: number,
  rowCount: number,
  enterpriseListRequest: SystemEnterpriseListRequest,
): Promise<Pageable<EnterpriseVOModel[]>> {
  const response = await http.get<Pageable<EnterpriseVO[]>>({
    url: API_SY.ENTERPRISES.ENTERPRISE_LIST,
    data: {
      pageNumber,
      rowCount,
      ...enterpriseListRequest,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToEnterpriseVOModel(data)),
  };
}

export async function requestSystemEnterpriseFinancierRegister(
  data: SystemEnterpriseFinancierRegisterRequest,
): Promise<EnterpriseVOModel> {
  const response = await http.post<EnterpriseVO>({
    url: API_SY.ENTERPRISES.CREATE_FINANCIER,
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToEnterpriseVOModel(response);
}

export async function requestSystemEnterpriseDetail(enterpriseId: number): Promise<EnterpriseVOModel> {
  const response = await http.get<EnterpriseVO>({
    url: API_SY.ENTERPRISES.ENTERPRISE_DETAIL(enterpriseId),
  });

  return formattingToEnterpriseVOModel(response);
}

export async function requestSystemEnterpriseUpdate(
  enterpriseId: number,
  data: SystemEnterpriseUpdateRequest,
): Promise<EnterpriseVOModel> {
  const response = await http.put<EnterpriseVO>({
    url: API_SY.ENTERPRISES.ENTERPRISE_DETAIL(enterpriseId),
    data,
  });

  return formattingToEnterpriseVOModel(response);
}

export async function requestSystemEnterpriseFinancierInterface(
  enterpriseId: number,
): Promise<FinancierInterfaceSettingVOModel[]> {
  const response = await http.get<FinancierInterfaceSettingVO[]>({
    url: API_SY.ENTERPRISES.FINANCIER_INTERFACE_SETTING(enterpriseId),
  });

  return response.map(item => formattingToFinancierInterfaceSettingVOModel(item));
}

export async function requestSystemEnterpriseFinancierInterfaceUpdate(
  enterpriseId: number,
  data: SystemEnterpriseFinancierSettingUpdateRequest,
): Promise<{}> {
  return await http.put<{}>({
    url: API_SY.ENTERPRISES.FINANCIER_INTERFACE_SETTING(enterpriseId),
    data,
  });
}

export async function requestSystemEnterpriseFinancierAuthSetting(
  enterpriseId: number,
): Promise<FinancierClientAuthSettingVOModel> {
  const response = await http.get<FinancierClientAuthSettingVO>({
    url: API_SY.ENTERPRISES.FINANCIER_CLIENT_AUTH_SETTING(enterpriseId),
  });

  return formattingToFinancierClientAuthSettingVOModel(response);
}

export async function requestSystemEnterpriseFinancierAuthSettingUpdate(
  enterpriseId: number,
  data: SystemEnterpriseFinancierAuthSettingUpdateRequest,
): Promise<FinancierClientAuthSettingVOModel> {
  const response = await http.put<FinancierClientAuthSettingVO>({
    url: API_SY.ENTERPRISES.FINANCIER_CLIENT_AUTH_SETTING(enterpriseId),
    data,
  });

  return formattingToFinancierClientAuthSettingVOModel(response);
}
