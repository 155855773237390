import type { ButtonHTMLAttributes, FC, MouseEvent } from 'react';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

interface LinkButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string;
  state?: any;
}

const WithLink = <T,>(Component: FC<T>) => {
  const LinkButton = forwardRef<HTMLButtonElement, T & LinkButtonPropsType>((props, ref) => {
    const { to, state, onClick } = props;
    const role = to ? 'link' : 'button';

    const history = useHistory();

    const handleLinkButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      onClick && onClick(event);
      to &&
        history.push({
          pathname: to,
          state: { ...state },
        });
    };

    return <Component ref={ref} role={role} onClick={handleLinkButtonClick} {...props} />;
  });
  LinkButton.displayName = 'LinkButton';

  return LinkButton;
};

export default WithLink;
