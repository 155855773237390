import { useTranslation } from 'react-i18next';

// 실패-송장 등록 한도 금액 초과 모달
function InvoiceRegisterCancelModal() {
  const { t } = useTranslation();

  return (
    <h6>
      {t('text:Would_you_like_to_cancel_the_invoice_registration?')}
      <br />
      {t('text:The_information_will_not_be_saved_if_the_registration_is_cancelled')}
    </h6>
  );
}

export default InvoiceRegisterCancelModal;
