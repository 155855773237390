import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import Select from 'components/stateless/Select/Select';
import { Td } from 'components/stateless/Table';
import type { COLLATERAL_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import i18n from 'locales/i18n';
import type Pageable from 'models/Pageable';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { requestFinancierDealerAgreementList } from 'utils/http/api/financier/dealer-agreements';
import type { FinancierDealerAgreementListRequest } from 'utils/http/api/financier/dealer-agreements/request';
import useModal from 'utils/modal/useModal';

type SelectedDataProps = {
  modalId: number;
  getSelectedData(data: DealerAgreementVOModel): void;
  supportedCollateralType: COLLATERAL_TYPE;
};

type SelectedDataType = {
  selectedKey: string;
  selectedValue: string;
};

export default function SearchPartnerAgreementModal({
  modalId,
  getSelectedData,
  supportedCollateralType,
}: SelectedDataProps) {
  const { show: showModal, close: closeModal } = useModal();
  const { t } = useTranslation(['format']);

  const [dealerAgreementList, setDealerAgreementList] = useState<Pageable<DealerAgreementVOModel[]>>();
  const { pageable, setPageable } = usePageable();
  const { register, getValues, handleSubmit, watch } = useForm<SelectedDataType>({
    mode: 'onSubmit',
  });

  const { selectedValue } = watch();

  const getSelectedValueLengthText = (): string => {
    if (selectedValue && selectedValue.length !== 0)
      return t('text:Current_text_length_is_') + ' ' + `${selectedValue.length}`;

    return '';
  };

  const fetchDealerAgreementList = async (pageNumber: number = 1, rowCount: number = 10) => {
    const searchValue = getValues();
    const formattedDate = formatDate(searchValue.selectedValue, t('date') as string);
    const requestOptions = buildRequestOptions(searchValue, formattedDate);
    requestOptions.collateralType = supportedCollateralType;

    try {
      const dealerAgreementList = await requestFinancierDealerAgreementList(pageNumber, rowCount, requestOptions);

      ReactDOM.unstable_batchedUpdates(() => {
        setDealerAgreementList(dealerAgreementList);
        setPageable(dealerAgreementList);
      });
    } catch (e) {
      showModal(e);
    }
  };

  const formatDate = (date: string, format: string): string => {
    return dayjs(date, format).format('YYYY-MM-DD');
  };

  const buildRequestOptions = (searchValue: any, formattedDate: string): FinancierDealerAgreementListRequest => {
    let requestOptions = {} as FinancierDealerAgreementListRequest;

    if (searchValue.selectedKey === 'startDate') {
      requestOptions.startDateFrom = formattedDate;
      requestOptions.startDateTo = formattedDate;
    } else if (searchValue.selectedKey === 'expiryDate') {
      requestOptions.expiryDateFrom = formattedDate;
      requestOptions.expiryDateTo = formattedDate;
    } else {
      requestOptions = { [searchValue.selectedKey]: searchValue.selectedValue };
    }

    return requestOptions;
  };

  const paginate = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    await fetchDealerAgreementList(pageNumber, sizePerPage);
  };

  const handleDealerAgreementSearchSubmit = async () => {
    await fetchDealerAgreementList();
  };

  const onDealerAgreementSelect = (e: any, item: DealerAgreementVOModel) => {
    e.preventDefault();
    getSelectedData({ ...item });
    closeModal(modalId);
  };

  useEffect(() => {
    fetchDealerAgreementList();
  }, []);

  const renderDealerAgreementTable = () => {
    if (dealerAgreementList && dealerAgreementList.content.length > 0) {
      return dealerAgreementList?.content.map((value, index) => {
        return (
          <tr key={index}>
            <td>{value.contractNo}</td>
            <td>{value.currencyType}</td>
            <td>{value.dealerFinancierClientName}</td>
            <td>{t('format:date', { value: value.startDate, key: 'date' })}</td>
            <td>{t('format:date', { value: value.expiryDate, key: 'date' })}</td>
            <Td className="information-table-more">
              <Button
                onClick={e => {
                  onDealerAgreementSelect(e, value);
                }}
              >
                {t('text:Select')}
              </Button>
            </Td>
          </tr>
        );
      });
    } else {
      return (
        <tr key={0}>
          <td colSpan={6} className="text-center">
            {t('text:No_data_available')}
          </td>
        </tr>
      );
    }
  };

  return (
    <form>
      <div className="d-flex information-filter mt-0">
        <Select
          name="selectedKey"
          className="information-form__select"
          selectOptions={[
            { label: i18n.t('text:Partner_Master_Agreement_Number'), value: 'contractNo' },
            { label: i18n.t('text:Currency'), value: 'currencyType' },
            { label: i18n.t('text:Partner_Name'), value: 'dealerClientName' },
            { label: i18n.t('text:Effective_Date'), value: 'startDate' },
            { label: i18n.t('text:Expiration_Date'), value: 'expiryDate' },
          ]}
          ref={register}
        />
        <input type="text" placeholder="Search..." name="selectedValue" ref={register} />
        <Button type="submit" onClick={handleSubmit(handleDealerAgreementSearchSubmit)}>
          {t('text:Search')}
        </Button>
      </div>
      <div className="text-brick-red p-1 mb-2">{getSelectedValueLengthText()}</div>
      <table className="table-border">
        <colgroup>
          <col />
          <col style={{ width: '120px' }} />
          <col />
          <col />
          <col />
          <col style={{ width: '80px' }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">{t('text:Partner_Master_Agreement_Number')}</th>
            <th scope="col">{t('text:Currency')}</th>
            <th scope="col">{t('text:Partner_Name')}</th>
            <th scope="col">{t('text:Effective_Date')}</th>
            <th scope="col">{t('text:Expiration_Date')}</th>
            <th scope="col" className="table-column-right-fixed" />
          </tr>
        </thead>
        <tbody>{renderDealerAgreementTable()}</tbody>
      </table>
      <Pagination pageable={pageable} paginate={paginate} />
    </form>
  );
}
