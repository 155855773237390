import type { HTMLAttributes } from 'react';
import type React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Spinner.scss';

export interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  spinnerColor?: string;
  size?: number;
  style?: React.CSSProperties;
}

function Spinner({ className, size = 20, spinnerColor = '#a8a8a8', style, children }: SpinnerProps) {
  const styles = {
    ...style,
    fontSize: `${size}px`,
  };

  return (
    <div className={`spinner ${className ? className : ''}`} style={styles}>
      {!children && <FontAwesomeIcon icon={faSpinner} style={{ color: spinnerColor }} />}
      {children}
    </div>
  );
}

export default Spinner;
