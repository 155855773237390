import { useEffect, useState } from 'react';

import { isNil } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import { COLLATERAL_TYPE } from 'enums';

import useExtraInformationViewModel from '../../extraInformation/useExtraInformationViewModel';
import useAnchorAgreementInfoViewModel from '../../models/useAnchorAgreementInfoViewModel';
import useFinancingOptionViewModel from '../../models/useFinancingOptionViewModel';

type LtvStateType = {
  isLtvHundred: null | boolean;
  ltvValue: null | string;
};

const useFinancingLimitController = () => {
  const {
    methods: { setValue, watch },
  } = useFormContext();
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();
  const { supportedCollateralType } = useExtraInformationViewModel();

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const { loanLimitCheck: watchedLoanLimitCheckType } = watch();

  const {
    anchorFinancingOption: { maxLtvRatio },
  } = useFinancingOptionViewModel();

  const [ltvState, setLtvState] = useState<LtvStateType>({
    isLtvHundred: null,
    ltvValue: null,
  });

  const updateMaxLtvRatioState = () => {
    let ltvValue;
    let isLtvHundred;

    if (maxLtvRatio === 100) {
      isLtvHundred = true;
      ltvValue = null;
    } else if (isNil(maxLtvRatio)) {
      isLtvHundred = null;
      ltvValue = null;
    } else {
      isLtvHundred = false;
      ltvValue = String(maxLtvRatio);
    }
    setLtvState({
      isLtvHundred: isLtvHundred,
      ltvValue: ltvValue,
    });
  };

  useEffect(() => {
    if (supportedCollateralType === COLLATERAL_TYPE.INVOICE) {
      updateMaxLtvRatioState();
    }
  }, [setValue, maxLtvRatio, supportedCollateralType]);

  return {
    loanLimitCheck: watchedLoanLimitCheckType,
    isInvoice,
    ltvState,
    anchorAgreementInfo,
  };
};

export default useFinancingLimitController;
