import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE, ENTERPRISE_TYPE } from 'enums';
import { WAITING_AR_APPROVAL_PHASE_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { ArSummaryVOModel } from 'models/vo/ArSummaryVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemArSummaryList } from 'utils/http/api/system/ar-summaries';
import type { SystemArSummariesListRequest } from 'utils/http/api/system/ar-summaries/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const SY_AR_CONFIRMATION_LIST_QS_KEY = 'sy-ar-confirmation-list';

function SystemArConfirmationList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { pageable, setPageable } = usePageable(SY_AR_CONFIRMATION_LIST_QS_KEY);

  const [summaryList, setSummaryList] = useState<Pageable<ArSummaryVOModel[]>>();

  // useForm
  const { register, getValues, reset, setValue, control } = useForm<SystemArSummariesListRequest>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemArSummariesListRequest>(
        setValue,
        getParsedSearchParams(SY_AR_CONFIRMATION_LIST_QS_KEY).formSearchData,
      );
      fetchSyArSummaryList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  const fetchSyArSummaryList = async (
    pageNumber: number = 1,
    rowNumber: number = 10,
    data: SystemArSummariesListRequest,
  ) => {
    try {
      const arSummaryPageResponse = await requestSystemArSummaryList(pageNumber, rowNumber, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount: rowNumber,
        },
        SY_AR_CONFIRMATION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSummaryList(arSummaryPageResponse);
        setPageable(arSummaryPageResponse);
      });
    } catch (error: any) {
      modal.show(error);
    }
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();
    fetchSyArSummaryList(0, pageable.sizePerPage, getValues());
  };

  const paginate = async (selectedPageNumber: number, selectedRowNumber: number) => {
    fetchSyArSummaryList(
      selectedPageNumber,
      selectedRowNumber,
      getParsedSearchParams(SY_AR_CONFIRMATION_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();
    reset({});
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Registration_Initiated_Date'),
      colWidths: 110,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Registration_Rejection'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registration_Status'),
      colWidths: 100,
    },
    {
      headerText: t('text:Validated_By'),
      colWidths: 100,
    },
    {
      headerText: t('text:Validation_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Number_of_Valid_AR'),
      colWidths: 120,
    },
    {
      headerText: t('text:Total_Amount_of_Valid_AR'),
      colWidths: 130,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return summaryList?.content.map((summary, i) => {
      const arApprovalPhaseStatus = summary.inProgress
        ? WAITING_AR_APPROVAL_PHASE_STATUS.IN_PROGRESS
        : summary.currentArApprovalPhaseStatus;
      const arApprovalPhaseStatusText = summary.rejected
        ? '-'
        : t(`code:waiting-ar-approval-phase-status.${arApprovalPhaseStatus}`);

      return (
        <Tr key={i}>
          <Td data={summary.createDateTime} format="datetime" />
          <Td data={summary.financierEnterpriseName} />
          <Td data={summary.anchorClientName} />
          <Td data={summary.anchorContractNo} />
          <Td data={summary.currencyType} className="text-center" />
          <Td data={summary.rejected ? 'Y' : 'N'} className="text-center" />
          <Td
            className={setArProgressStatusClassName(arApprovalPhaseStatus, summary.rejected)}
            data={arApprovalPhaseStatusText}
            format="code"
          />
          <Td data={t(`code:enterprise-type.${summary.currentConfirmEnterpriseType}`)} />
          <Td data={summary.currentArApprovalDateTime} format="datetime" />
          <Td data={summary.currentApprovalCount} format="number" />
          <Td data={summary.currentApprovalAmount} format="number" />
          <TdLink path={ROUTES_SY.REGISTER_AR.CONFIRMATION_DETAIL_BUILD_PATH(summary.arSummaryId)} />
        </Tr>
      );
    });
  };

  const setArProgressStatusClassName = (code: WAITING_AR_APPROVAL_PHASE_STATUS, rejected: boolean): string => {
    if (rejected) return 'text-bold-brick-red';

    return getStatusTextClass('WAITING_AR_APPROVAL_PHASE_STATUS', code);
  };

  return (
    <>
      <HeaderTitle title={t('text:AR_Confirmation')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Initiated_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="createdDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="createdDateTo" control={control} />
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name="anchorContractNo" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
              <SearchLabel label={t('text:Registration_Rejection')} />
              <SearchSelect
                selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                name="rejected"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Registration_Status')} />
              <SearchSelect
                selectOptions={getSelectOptions<WAITING_AR_APPROVAL_PHASE_STATUS>(
                  'WAITING_AR_APPROVAL_PHASE_STATUS',
                  'ALL',
                  true,
                )}
                name="currentArApprovalPhaseStatus"
                ref={register}
              />
              <SearchLabel label={t('text:Validated_By')} />
              <SearchSelect
                selectOptions={getSelectOptions<ENTERPRISE_TYPE>('ENTERPRISE_TYPE', 'ALL', true)}
                name="currentConfirmEnterpriseType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Validation_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="currentConfirmDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="currentConfirmDateTo" control={control} />
              <SearchLabel label={t('text:Number_of_Valid_AR')} />
              <SearchInput col={2} placeholder={t('text:from')} name="minimumCurrentApprovalArCount" ref={register} />
              <SearchInput col={2} placeholder={t('text:to')} name="maximumCurrentApprovalArCount" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Total_Amount_of_Valid_AR')} />
              <SearchInput col={2} placeholder={t('text:from')} name="minimumCurrentApprovalArAmount" ref={register} />
              <SearchInput col={2} placeholder={t('text:to')} name="maximumCurrentApprovalArAmount" ref={register} />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {summaryList?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemArConfirmationList;
