import useAnchorAgreementInfoViewModel from '../../models/financingOption/useAnchorAgreementInfoViewModel';

const useRegistrationDetailAssociatedAnchorMasterAgreementController = () => {
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();

  return {
    anchorAgreementInfo,
  };
};

export default useRegistrationDetailAssociatedAnchorMasterAgreementController;
