import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { isNil } from 'lodash-es';

import { AUTHORITY_TYPE } from 'enums';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import type { PotentialPartnerAcquisitionSettingVOModel } from 'models/vo/PotentialPartnerAcquisitionSettingVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierPotentialPartnerAcquisitionDocumentList } from 'utils/http/api/financier/potential-partner-acquisition-documents';
import {
  requestFinancierPotentialPartnerAcquisitionSettingInfo,
  requestFinancierPotentialPartnerAcquisitionSettingRegister,
} from 'utils/http/api/financier/potential-partner-acquisition-setting';
import type { FinancierPotentialPartnerAcquisitionSettingRegisterRequest } from 'utils/http/api/financier/potential-partner-acquisition-setting/request';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

export interface PPAcquisitionDocumentType {
  data: PotentialPartnerAcquisitionDocumentVOModel;
  isEditable: boolean;
}

interface FinancierSCPartnerAcquisitionSettingStateType {
  activatedDocumentList: PPAcquisitionDocumentType[];
  deactivatedDocumentList: PPAcquisitionDocumentType[];
  contactInfo: PotentialPartnerAcquisitionSettingVOModel;
}

export function useFinancierSCPartnerAcquisitionSettingState() {
  const contactForm = useForm<FinancierPotentialPartnerAcquisitionSettingRegisterRequest>();

  const modal = useModal();
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;

  const [dataState, setDataState] = useState<FinancierSCPartnerAcquisitionSettingStateType>({
    activatedDocumentList: [] as PPAcquisitionDocumentType[],
    deactivatedDocumentList: [] as PPAcquisitionDocumentType[],
    contactInfo: {} as PotentialPartnerAcquisitionSettingVOModel,
  });

  const editableDocument = (potentialPartnerAcquisitionDocumentSettingId: number): boolean => {
    return isAdmin && isNil(potentialPartnerAcquisitionDocumentSettingId);
  };

  const fetchAll = async () => {
    try {
      const [fetchedContactInfo, fetchedDocumentLists] = await Promise.all([
        requestFinancierPotentialPartnerAcquisitionSettingInfo(),
        requestFinancierPotentialPartnerAcquisitionDocumentList(0, 100),
      ]);

      resetContactForm(fetchedContactInfo);

      const fetchedActivatedDocumentList = fetchedDocumentLists?.content?.filter(res => res.activate === true);
      const fetchedDeactivatedDocumentList = fetchedDocumentLists?.content?.filter(res => res.activate === false);

      setDataState(prevState => ({
        ...prevState,
        contactInfo: fetchedContactInfo,
        activatedDocumentList: fetchedActivatedDocumentList.map(item => {
          return {
            data: item,
            isEditable: editableDocument(item.potentialPartnerAcquisitionDocumentSettingId),
          };
        }),
        deactivatedDocumentList: fetchedDeactivatedDocumentList.map(item => {
          return {
            data: item,
            isEditable: editableDocument(item.potentialPartnerAcquisitionDocumentSettingId),
          };
        }),
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const resetContactForm = (data: PotentialPartnerAcquisitionSettingVOModel) => {
    contactForm.reset({
      message: data.message,
      telephone: data.telephone,
      fax: data.fax,
      webSite: data.webSite,
      email: data.email,
      address: data.address,
    });
  };

  const updateContactInfo = async (): Promise<boolean> => {
    const formData = contactForm.getValues();
    requestDTOParser(formData);

    try {
      const updatedResponse = await requestFinancierPotentialPartnerAcquisitionSettingRegister(formData);
      resetContactForm(updatedResponse);
      setDataState(prevState => ({
        ...prevState,
        contactInfo: updatedResponse,
      }));

      return true;
    } catch (e) {
      modal.show(e);
      formErrorHandler<FinancierPotentialPartnerAcquisitionSettingRegisterRequest>(
        e,
        contactForm.setError,
        contactForm.clearErrors,
      );

      return false;
    }
  };

  return { state: dataState, fetchAll, updateContactInfo, contactForm };
}
