import { useTranslation } from 'react-i18next';

import Form, { useFormContext } from 'components/stateless/Form';
import { Border, Content, Row } from 'components/templates/section';
import { COLLATERAL_TYPE } from 'enums';
import { useUppercaseInput } from 'hooks/useUppercaseInput';

import useExtraInformationViewModel from '../../models/extraInformation/useExtraInformationViewModel';

const DesignatedAccountOfARSettlementOrInvoicePayment = () => {
  const { t } = useTranslation(['format']);

  const { supportedCollateralType } = useExtraInformationViewModel();

  const {
    methods: { register, watch },
  } = useFormContext();

  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;
  const isSettlementAccountRequired = isAr && watch('paymentSupport') === 'true';

  const handleUppercaseInput = useUppercaseInput();

  return (
    <Border>
      <Content isInnerStyle={true}>
        <input name="settlementAccountBranchCode" type="hidden" ref={register} />
        <input name="settlementAccountFinancierCode" type="hidden" ref={register} />
        <Row>
          <Form.Control name="settlementAccountFinancierName">
            <Form.Label>{t('text:Bank_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="settlementAccountBranchName">
            <Form.Label>{t('text:Branch_Name')}</Form.Label>
            <Form.Input />
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="settlementAccount">
            <Form.Label required={isSettlementAccountRequired}>{t('text:Bank_Account_Number')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="settlementAccountOwner">
            <Form.Label>{t('text:Bank_Account_Holder')}</Form.Label>
            <Form.Input onChange={handleUppercaseInput} />
          </Form.Control>
        </Row>
      </Content>
    </Border>
  );
};

export default DesignatedAccountOfARSettlementOrInvoicePayment;
