import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { BASIS_INTEREST_TYPE } from 'enums';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';

interface SearchInterfaceByIdModalProps {
  searchedInterfaceData?: DealerAgreementDetailVOModel;
}

const SearchInterfaceByIdModal = ({ searchedInterfaceData }: SearchInterfaceByIdModalProps) => {
  const { t } = useTranslation(['format']);

  const {
    contractNo,
    currencyType,
    dealerFinancierClientCode,
    dealerFinancierClientName,
    startDate,
    expiryDate,
    loanLimitAmount,
    basisInterestType,
    basisInterestRate,
    basisInterestTerm,
    branchCode,
    branchName,
  } = searchedInterfaceData ?? {};

  return (
    <FormBorder>
      <FormContents>
        <div className="row">
          <FormValue
            className="information-form__input"
            label={t('text:Partner_Master_Agreement_Number')}
            value={contractNo}
          />
          <FormValue className="information-form__input" label={t('text:Currency')} value={currencyType} />
        </div>
        <div className="row">
          <FormValue
            className="information-form__input"
            label={t('text:Client_Code')}
            value={dealerFinancierClientCode}
          />
          <FormValue
            className="information-form__input"
            label={t('text:Partner_Name')}
            value={dealerFinancierClientName}
          />
        </div>
        <div className="row">
          <FormValue
            className="information-form__input"
            label={t('text:Effective_Date')}
            value={startDate}
            format="date"
          />
          <FormValue
            className="information-form__input"
            label={t('text:Expiration_Date')}
            value={expiryDate}
            format="date"
          />
        </div>
        <div className="row">
          <FormValue
            className="information-form__input"
            label={t('text:Financing_Limit')}
            value={loanLimitAmount}
            format="number"
          />
          <FormValue
            className="information-form__input"
            label={t('text:Financing_Interest')}
            value={
              basisInterestType
                ? `${basisInterestType} +${
                    basisInterestType === BASIS_INTEREST_TYPE.FIXED ? basisInterestRate : basisInterestTerm || '-'
                  }`
                : '-'
            }
          />
        </div>
        <div className="row">
          <FormValue className="information-form__input" label={t('text:Responsible_Branch_Code')} value={branchCode} />
          <FormValue className="information-form__input" label={t('text:Responsible_Branch_Name')} value={branchName} />
        </div>
      </FormContents>
    </FormBorder>
  );
};

export default SearchInterfaceByIdModal;
