import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import type { CURRENCY_TYPE } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import type { deArListElementType } from 'utils/http/api/dealer/ar-summaries/request';

interface DealerAPRequestModalPropsType {
  dataList: deArListElementType[];
  totalCount: number;
  totalAmount: BigNumber;
  currencyType: CURRENCY_TYPE | undefined;
}

function DealerAPRequestModal({ dataList, totalCount, totalAmount, currencyType }: DealerAPRequestModalPropsType) {
  const { t } = useTranslation(['format']);
  const renderResultTable = () => {
    return dataList?.map((item: deArListElementType, i: number) => {
      return (
        <Tr key={i}>
          <Td data={item.arNumber} />
          <Td data={item.arIssuedDate} format="date" />
          <Td data={item.settlementDate} format="date" />
          <Td data={Number(item.arAmount)} format="number" />
        </Tr>
      );
    });
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:AR_Number_(Tax_Invoice_Number)'),
      className: 'text-center',
    },
    {
      headerText: t('text:AR_Issued_Date'),
      className: 'text-center',
    },
    {
      headerText: t('text:Settlement_Date'),
      className: 'text-center',
    },
    {
      headerText: `${t('text:AR_Amount')} (${t('text:unit')}: ${currencyType ? currencyType : ''})`,
      className: 'text-center',
    },
  ];

  return (
    <>
      <h6>
        {t('text:Would_you_like_to_register_the_AR_information?')}
        <br />
        {t('text:Check_the_AR_information_entered_below')}
      </h6>
      <br />
      <ul className="invoice-registration-sum">
        <li>
          • {t('text:Total_number_of_AR_to_register')} : <strong>{t('format:number', { value: totalCount })}</strong>
        </li>
        <li>
          • {t('text:Total_amount_of_AR_to_register')} : <strong>{t('format:number', { value: totalAmount })}</strong>
        </li>
      </ul>
      <br />
      <TableBorder>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
      </TableBorder>
    </>
  );
}

export default DealerAPRequestModal;
