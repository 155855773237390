import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorStatisticsOfDealerAgreementsList } from 'utils/http/api/anchor/statistics-of-dealer-agreements';
import type { AnchorStatisticsOfDealerAgreementListRequest } from 'utils/http/api/anchor/statistics-of-dealer-agreements/requests';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

import AnchorOverviewByPartnerListChannelTab from './sections/channel-tab';
import AnchorOverviewByPartnerListVendorTab from './sections/vendor-tab';

export const AC_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY = 'ac-overview-by-partner-vf-tab';
export const AC_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY = 'ac-overview-by-partner-cf-tab';

function AnchorOverviewByPartnerList(): JSX.Element {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();

  const [overviewByPartnerVendorPage, setOverviewByPartnerVendorPage] =
    useState<Pageable<StatisticsOfDealerAgreementVOModel[]>>();
  const [overviewByPartnerChannelPage, setOverviewByPartnerChannelPage] =
    useState<Pageable<StatisticsOfDealerAgreementVOModel[]>>();

  const { pageable: overviewByPartnerVendorPageable, setPageable: setOverviewByPartnerVendorPageable } = usePageable(
    AC_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY,
  );
  const { pageable: overviewByPartnerChannelPageable, setPageable: setOverviewByPartnerChannelPageable } = usePageable(
    AC_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY,
  );

  const vendorTabSearchForm = useForm<AnchorStatisticsOfDealerAgreementListRequest>();
  const channelTabSearchForm = useForm<AnchorStatisticsOfDealerAgreementListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<AnchorStatisticsOfDealerAgreementListRequest>(
        vendorTabSearchForm.setValue,
        getParsedSearchParams(AC_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY).formSearchData,
      );
      setFormValues<AnchorStatisticsOfDealerAgreementListRequest>(
        channelTabSearchForm.setValue,
        getParsedSearchParams(AC_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    const vendorTabQs = makeSearchParamsPattern(
      {
        ...vendorTabSearchForm.getValues(),
        pageNumber: overviewByPartnerVendorPageable.currentPage,
        rowCount: overviewByPartnerVendorPageable.sizePerPage,
      },
      AC_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY,
    );
    const dealerTabQs = makeSearchParamsPattern(
      {
        ...channelTabSearchForm.getValues(),
        pageNumber: overviewByPartnerChannelPageable.currentPage,
        rowCount: overviewByPartnerChannelPageable.sizePerPage,
      },
      AC_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY,
    );
    addSearchParams(vendorTabQs + dealerTabQs);

    try {
      const [fetchOverviewByPartnerVendorPage, fetchOverviewByPartnerChannelPage] = await Promise.all([
        requestAnchorStatisticsOfDealerAgreementsList(
          overviewByPartnerVendorPageable.currentPage,
          overviewByPartnerVendorPageable.sizePerPage,
          {
            ...vendorTabSearchForm.getValues(),
            collateralType: COLLATERAL_TYPE.AR,
          },
        ),
        requestAnchorStatisticsOfDealerAgreementsList(
          overviewByPartnerChannelPageable.currentPage,
          overviewByPartnerChannelPageable.sizePerPage,
          {
            ...channelTabSearchForm.getValues(),
            collateralType: COLLATERAL_TYPE.INVOICE,
          },
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByPartnerVendorPage(fetchOverviewByPartnerVendorPage);
        setOverviewByPartnerVendorPageable(fetchOverviewByPartnerVendorPage);

        setOverviewByPartnerChannelPage(fetchOverviewByPartnerChannelPage);
        setOverviewByPartnerChannelPageable(fetchOverviewByPartnerChannelPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchAcPartnerStatisticsVendorTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: AnchorStatisticsOfDealerAgreementListRequest,
  ) => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.AR,
    };
    try {
      const response = await requestAnchorStatisticsOfDealerAgreementsList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        AC_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY,
      );

      setOverviewByPartnerVendorPage(response);
      setOverviewByPartnerVendorPageable(response);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchVendorTab = async (e: any) => {
    e.preventDefault();
    await fetchAcPartnerStatisticsVendorTab(
      1,
      overviewByPartnerVendorPageable.sizePerPage,
      vendorTabSearchForm.getValues(),
    );
  };

  const paginateVendorTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchAcPartnerStatisticsVendorTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(AC_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY).formSearchData,
    );
  };

  const fetchAcPartnerStatisticsChannelTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: AnchorStatisticsOfDealerAgreementListRequest,
  ) => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.INVOICE,
    };
    try {
      const response = await requestAnchorStatisticsOfDealerAgreementsList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        AC_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY,
      );

      setOverviewByPartnerChannelPage(response);
      setOverviewByPartnerChannelPageable(response);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchChannelTab = async (e: any) => {
    e.preventDefault();
    await fetchAcPartnerStatisticsChannelTab(
      1,
      overviewByPartnerChannelPageable.sizePerPage,
      channelTabSearchForm.getValues(),
    );
  };

  const paginateChannelTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchAcPartnerStatisticsChannelTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(AC_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY).formSearchData,
    );
  };

  const renderVendorTab = () => {
    return (
      <FormProvider {...vendorTabSearchForm}>
        <AnchorOverviewByPartnerListVendorTab
          onClickSearch={onClickSearchVendorTab}
          overviewByPartnerPageData={overviewByPartnerVendorPage}
          pageable={overviewByPartnerVendorPageable}
          paginate={paginateVendorTab}
        />
      </FormProvider>
    );
  };

  const renderChannelTab = () => {
    return (
      <FormProvider {...channelTabSearchForm}>
        <AnchorOverviewByPartnerListChannelTab
          onClickSearch={onClickSearchChannelTab}
          overviewByPartnerPageData={overviewByPartnerChannelPage}
          pageable={overviewByPartnerChannelPageable}
          paginate={paginateChannelTab}
        />
      </FormProvider>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Overview_by_Partner')} />
      <Tab tabType="financeType" tabViewList={[renderVendorTab(), renderChannelTab()]} />
    </>
  );
}

export default AnchorOverviewByPartnerList;
