const PP_HOME_PATH = '/potential-partner/home';
const PP_AR_DETAIL_PATH = '/potential-partner/ar-detail';
const PP_APPLICATION_PATH = '/potential-partner/application';

const PP_MY_INFO_PATH = '/potential-partner/my-info';
const PP_MY_INFO_SUBMISSION_PATH = `${PP_MY_INFO_PATH}/submission`;
const PP_MY_INFO_FINANCIER_PATH = `${PP_MY_INFO_PATH}/financier`;

export const ROUTES_PP = {
  HOME: PP_HOME_PATH,

  AR_DETAIL: `${PP_AR_DETAIL_PATH}/:anchorPartnerAccountId`,
  AR_DETAIL_BUILD_PATH: (anchorPartnerAccountId: number) => `${PP_AR_DETAIL_PATH}/${anchorPartnerAccountId}`,

  APPLICATION: {
    STEP: PP_APPLICATION_PATH,
    RESULT: `${PP_APPLICATION_PATH}/result`,
  },

  MY_INFO: {
    LIST: PP_MY_INFO_PATH,

    SUBMISSION_DETAIL: `${PP_MY_INFO_SUBMISSION_PATH}/:potentialPartnerFinancingApplicationId`,
    SUBMISSION_DETAIL_BUILD_PATH: (potentialPartnerFinancingApplicationId: number) =>
      `${PP_MY_INFO_SUBMISSION_PATH}/${potentialPartnerFinancingApplicationId}`,

    FINANCIER_DETAIL: `${PP_MY_INFO_FINANCIER_PATH}/:financierId`,
    FINANCIER_DETAIL_BUILD_PATH: (financierId: number) => `${PP_MY_INFO_FINANCIER_PATH}/${financierId}`,
  },
};
