import type React from 'react';
import { createContext, useEffect, useState } from 'react';

import useMounted from '../../hooks/useMounted';
import { getCss, setCss } from '../storage/LocalStorage';

export enum ThemeEnum {
  ORANGE = 'orange',
  BLUE = 'blue',
}

interface ThemeState {
  theme: ThemeEnum | undefined;
  setTheme: React.Dispatch<React.SetStateAction<ThemeEnum | undefined>>;
}

const initialThemeState: ThemeState = {
  theme: ThemeEnum.ORANGE,
  setTheme: () => {},
};

export const ThemeContext = createContext(initialThemeState);

export const ThemeProvider = ({ children }: any) => {
  const currentTheme = getCss();

  const mounted = useMounted();
  const [theme, setTheme] = useState<ThemeEnum>();

  useEffect(() => {
    if (!mounted) {
      if (currentTheme && Object.values(ThemeEnum)?.includes(currentTheme as unknown as ThemeEnum)) {
        setCss(currentTheme);
        setTheme(currentTheme as ThemeEnum);
      } else {
        setCss(ThemeEnum.ORANGE);
        setTheme(ThemeEnum.ORANGE);
      }
    }

    return () => {};
  }, [mounted]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div id="theme-wrapper" className={`theme-${theme}`} style={{ width: '100%', height: '100%', position: 'fixed' }}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
