import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import InvoiceSummaryPhaseAccordion from 'components/invoice/InvoiceSummaryPhaseAccordion';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { WAITING_INVOICE_APPROVAL_PHASE_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import useProgressStatus from 'hooks/useProgressStatus';
import type { InvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import type { InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import { requestSystemInvoiceApprovalPhasesList } from 'utils/http/api/system/invoice-approval-phases';
import { requestSystemInvoiceSummaryDetail } from 'utils/http/api/system/invoice-summaries';
import { requestSystemWaitingInvoiceList } from 'utils/http/api/system/waiting-invoices';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const MAX_ROW_LENGTH = 500;

function SystemInvoiceConfirmationDetail() {
  const [invoiceSummaryData, setInvoiceSummaryData] = useState<InvoiceSummaryVOModel>({} as InvoiceSummaryVOModel);
  const [invoiceSummaryPhaseDataList, setInvoiceSummaryPhaseDataList] = useState<InvoicePhaseVOModel[]>([]);

  const modal = useModal();
  const mounted = useMounted();
  const { t } = useTranslation(['format']);
  const { invoiceSummaryId } = useParams() as any;
  const { renderProgressStatus } = useProgressStatus(invoiceSummaryData);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [invoiceSummaryResponse, invoiceSummaryPhasePageResponse] = await Promise.all([
        requestSystemInvoiceSummaryDetail(invoiceSummaryId),
        requestSystemInvoiceApprovalPhasesList(invoiceSummaryId),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setInvoiceSummaryData(invoiceSummaryResponse);
        setInvoiceSummaryPhaseDataList(invoiceSummaryPhasePageResponse.content);
      });
    } catch (error: any) {
      modal.show(error);
    }
  };

  const onClickStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(WAITING_INVOICE_APPROVAL_PHASE_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="WAITING_INVOICE_APPROVAL_PHASE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const handleInvoiceSummaryPhaseAccordionClick = (invoiceSummaryPhase: InvoicePhaseVOModel) => {
    return requestSystemWaitingInvoiceList(1, MAX_ROW_LENGTH, invoiceSummaryPhase.waitingInvoiceApprovalPhaseId);
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Invoice_Confirmation_Details')} />
      {invoiceSummaryData.rejected && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            `${t('text:The_invoice_registration_request_was_rejected')} ${t(
              'text:Please_check_the_reason_below_for_the_details',
            )}`,
          ]}
          reasonTitle={t('text:REASON_FOR_REVERT')}
          reason={invoiceSummaryData.rejectReason}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Summary')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Registration_Status')}
                value={renderProgressStatus()}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickStatus}
              />
              <FormValue
                col={3}
                className="information-form__input border-none pointfont"
                label={t('text:Number_of_Valid_Invoices')}
                value={invoiceSummaryData.rejected ? '-' : invoiceSummaryData.currentApprovalCount}
              />
              <FormValue
                col={3}
                className="information-form__input border-none pointfont"
                label={t('text:Total_Amount_of_Valid_Invoices')}
                value={invoiceSummaryData.rejected ? '-' : invoiceSummaryData.currentApprovalAmount}
                format="number"
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} value={invoiceSummaryData.financierName} col={3} />
              <FormValue label={t('text:BOE_Number')} value={invoiceSummaryData.billOfExchangeNo} col={3} />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Partner_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                col={3}
                label={t('text:Partner_Master_Agreement_Number')}
                value={invoiceSummaryData.dealerContractNo}
              />
              <div className="col-3" />
              <FormValue col={3} label={t('text:Partner_Name')} value={invoiceSummaryData.dealerClientName} />
              <FormValue col={3} label={t('text:Currency')} value={invoiceSummaryData.currencyType} />
            </div>
            <div className="row">
              <FormValue label={t('text:Anchor_Master_Agreement_Number')} value={invoiceSummaryData.anchorContractNo} />
              <FormValue label={t('text:Anchor_Name')} value={invoiceSummaryData.anchorClientName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Invoice_List')} />
        {invoiceSummaryPhaseDataList.map((invoiceSummaryPhase, index) => (
          <InvoiceSummaryPhaseAccordion
            key={index}
            invoiceSummary={invoiceSummaryData}
            invoiceSummaryPhase={invoiceSummaryPhase}
            onAccordionClick={() => handleInvoiceSummaryPhaseAccordionClick(invoiceSummaryPhase)}
          />
        ))}
      </div>
    </>
  );
}

export default SystemInvoiceConfirmationDetail;
