import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { LoanVOModel } from 'models/vo/LoanVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestDealerLoanExtension, requestDealerLoanList } from 'utils/http/api/dealer/loans';
import type { DealerLoanListRequest } from 'utils/http/api/dealer/loans/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { tableValueManage } from 'utils/valueManager/ValueManager';

function DealerExtensionRegister() {
  // 기본 State
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable();
  const getProperty = useProperty<DealerLoanListRequest>();
  const modal = useModal();

  // 화면 state
  const [financingPage, setFinancingPage] = useState<Pageable<LoanVOModel[]>>();

  // useForm
  const { register, handleSubmit, getValues } = useForm<DealerLoanListRequest>({
    mode: 'onSubmit',
    shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
  });

  useEffect(() => {
    if (mounted) {
      fetchAll(pageable.currentPage, pageable.sizePerPage);
    }
  }, [mounted]);

  async function fetchAll(pageNumber: number = 1, rowCount: number = 10) {
    const data = getValues();

    try {
      const financingPage = await requestDealerLoanList(pageNumber, rowCount, {
        ...data,
        extensible: true,
        loanStatusConditions: [LOAN_STATUS.DISBURSED, LOAN_STATUS.OVERDUE],
        extendInProgress: false,
      });
      ReactDOM.unstable_batchedUpdates(() => {
        setFinancingPage(financingPage);
        setPageable(financingPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async () => {
    fetchAll(1, pageable.sizePerPage);
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchAll(page, sizePerPage);
  };

  const onClickRequestButton = (e: any, item: LoanVOModel) => {
    e.preventDefault();

    const requestLoanExtension = async () => {
      try {
        await requestDealerLoanExtension(item.loanId);
        showConfirmModal();
      } catch (e) {
        modal.show(e);
      }
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_request_the_maturity_extension_for_the_financing_below?')}
        <br />- {t('text:Financing_Number')} : {tableValueManage(item.financierLoanId)}
        <br />- {t('text:Financing_Amount')} : {t('format:number', { value: item.disbursedAmount })}
        <br />- {t('text:Total_Interest_Rate')} :{' '}
        {t('format:number', { value: item.disbursedBasisInterestRate + item.disbursedTotalInterestRateWithoutBasis })}%
        <br />- {t('text:Original_Repayment_Date')} :{' '}
        {t('format:date', {
          value: item.repaymentDate,
          key: 'date',
        })}
        <br />- {t('text:Number_of_remaining_requests')} :{' '}
        {t('format:number', {
          value: Number(item?.maxExtensibleLoanCount) - Number(item?.extendedLoanCount),
        })}
        <br />- {t('text:Financing_Status')} : {t(`code:financing-status.${item.loanStatus}`)}
        <br />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => {
          requestLoanExtension();
        },
      },
    );

    const showConfirmModal = () => {
      modal.show(<h6>{t('text:The_financing_maturity_extension_request_has_been_completed')}</h6>, {
        closeBtnCb: () => fetchAll(),
      });
    };
  };

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 100,
    },
    {
      headerText: t('text:Total_Interest_Rate_(APR)'),
      colWidths: 120,
    },
    {
      headerText: t('text:Original_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Remaining_Requests'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 80,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickLoanStatus,
    },

    {
      headerText: '',
      colWidths: 80,
    },
  ];
  const renderLoanListTable = () => {
    return financingPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.financierName} />
          <Td data={item.financierLoanId} />
          <Td data={item.loanId} />
          <Td data={item.disbursedAmount} format="number" />
          <Td data={item.disbursedBasisInterestRate + item.disbursedTotalInterestRateWithoutBasis} format="percent" />
          <Td data={item.repaymentDate} format="date" />
          <Td data={Number(item?.maxExtensibleLoanCount) - Number(item?.extendedLoanCount)} format="number" />
          <Td
            data={t(`code:financing-status.${item.loanStatus}`)}
            className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
            format="code"
          />
          <Td className="information-table-more">
            <Button onClick={e => onClickRequestButton(e, item)}>{t('text:Request')}</Button>
          </Td>
        </Tr>
      );
    });
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Request_for_Extension')} />
      <div className="content-area">
        <SectionTitle title={t('text:Financing_List_for_Extension_Requests')} />
        <form>
          <div className="d-flex information-filter mt-0">
            <div className="information-form__item__select position-relative">
              <select className="information-form__select">
                <option>{t('text:Financier_Name')}</option>
              </select>
            </div>
            <input type="text" name={getProperty('financierName')} ref={register} placeholder={t('text:Search…')} />
            <Button onClick={handleSubmit(onSearchSubmit)}>{t('text:Search')}</Button>
          </div>
        </form>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderLoanListTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerExtensionRegister;
