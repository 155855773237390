import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE, LOAN_STATUS, SETTLEMENT_METHOD_TYPE } from 'enums';
import { SUCCESS_INVOICE_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestDealerSuccessInvoiceList } from 'utils/http/api/dealer/success-invoices';
import type { DealerSuccessInvoiceListRequest } from 'utils/http/api/dealer/success-invoices/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getBlockedStatusText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const DE_INVOICE_LIST_QS_KEY = 'de-invoice-list';
const INVOICE_DETAILS_MAX_COUNT = 10;

function DealerInvoiceList() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const {
    register: searchFormRegister,
    getValues: getSearchFormValues,
    reset: resetSearchForm,
    setValue: setSearchValue,
    control: searchFormControl,
  } = useForm<DealerSuccessInvoiceListRequest>();

  const [successInvoicePage, setSuccessInvoicePage] = useState<Pageable<SuccessInvoiceVOModel[]>>();

  const { pageable, setPageable } = usePageable(DE_INVOICE_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<DealerSuccessInvoiceListRequest>(
        setSearchValue,
        getParsedSearchParams(DE_INVOICE_LIST_QS_KEY).formSearchData,
      );
      fetchDeInvoiceList(pageable.currentPage, pageable.sizePerPage, getSearchFormValues());
    }

    return () => {};
  }, [mounted]);

  const fetchDeInvoiceList = async (
    selectedPageNumber: number = 1,
    selectedRowNumber: number = 10,
    data: DealerSuccessInvoiceListRequest,
  ): Promise<void> => {
    try {
      const successInvoicePageResponse = await requestDealerSuccessInvoiceList(
        selectedPageNumber,
        selectedRowNumber,
        data,
      );
      updateSearchParams(
        {
          ...data,
          pageNumber: selectedPageNumber,
          rowCount: selectedRowNumber,
        },
        DE_INVOICE_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPageable(successInvoicePageResponse);
        setSuccessInvoicePage(successInvoicePageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: any): void => {
    e.preventDefault();

    resetSearchForm({
      invoiceNumber: '',
      referenceNumber: '',
      dealerName: '',
      anchorName: '',
      currencyType: undefined,
      minimumInvoiceAmount: undefined,
      maximumInvoiceAmount: undefined,
      successInvoiceStatus: undefined,
      confirmedDateFrom: '',
      confirmedDateTo: '',
      invoiceIssuedDateFrom: '',
      invoiceIssuedDateTo: '',
      settlementDateFrom: '',
      settlementDateTo: '',
      blocked: undefined,
      collateralized: undefined,
      billOfExchangeNo: '',
    });
  };

  const onClickSearch = async (e: any): Promise<void> => {
    e.preventDefault();

    fetchDeInvoiceList(0, pageable.sizePerPage, getSearchFormValues());
  };

  const getTableHeaders = (isPDFHeader = false) => {
    const headers: HeaderType[] = [
      {
        headerText: t('text:Registration_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:BOE_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Invoice_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Invoice_Reference_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Anchor_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Anchor_Tax_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 90,
      },
      {
        headerText: t('text:Invoice_Amount'),
        colWidths: 100,
      },
      {
        headerText: t('text:Invoice_Issued_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Original_Payment_Date'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financing_Eligibility'),
        colWidths: 120,
      },
      {
        headerText: t('text:Settlement_Method'),
        colWidths: 120,
      },
      {
        headerText: t('text:Financing_Status'),
        colWidths: 120,
      },
      {
        headerText: t('text:Payment_Status'),
        colWidths: 120,
      },
    ];

    const pdfInvoiceDetailsHeaders: HeaderType[] = Array(INVOICE_DETAILS_MAX_COUNT)
      .fill('')
      .map((_, index) => ({
        headerText: `Invoice Detail (${index + 1})`,
        colWidths: 120,
      }));

    return isPDFHeader ? [...headers, ...pdfInvoiceDetailsHeaders] : [...headers, { headerText: '', colWidths: 50 }];
  };

  const renderResultTable = (data: SuccessInvoiceVOModel[] | undefined, isPdf: boolean = false) => {
    return data?.map((item, index) => (
      <tr key={index} className={isPdf ? 'virtual-table-row' : ''}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.billOfExchangeNo} />
        <Td data={item.invoiceNumber} />
        <Td data={item.referenceNumber} />
        <Td data={item.anchorClientName} />
        <Td data={item.anchorClientTaxCode} />
        <Td data={item.currencyType} />
        <Td data={item.invoiceAmount} format="number" />
        <Td data={item.invoiceIssuedDate} format="date" />
        <Td data={item.settlementDate} format="date" />
        <Td
          className={getBlockedStatusClassName(item.blockedByFinancier)}
          data={getBlockedStatusText(item.blockedByFinancier)}
        />
        <Td data={t(`code:settlement-method-type.${item.settlementMethod}`)} format="code" />
        <Td
          data={t(`code:financing-status.${item.loanStatus}`)}
          className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
          format="code"
        />
        <Td
          className={getStatusTextClass('SUCCESS_INVOICE_STATUS', item.successInvoiceStatus)}
          data={t(`code:success-invoice-status.${item.successInvoiceStatus}`)}
          format="code"
        />
        {isPdf ? (
          <>
            {item.invoiceAdditionalDataList?.map(({ name, value }, index) => (
              <Td key={`${name}-${value}-${index}`} data={`${name}: ${value === '' ? '-' : value}`} />
            ))}
            {Array(INVOICE_DETAILS_MAX_COUNT - (item.invoiceAdditionalDataList?.length ?? 0))
              .fill('')
              .map((_, index) => (
                <Td key={`fill-empty-${index}`} data="-" />
              ))}
          </>
        ) : (
          <TdLink path={ROUTES_DE.VIEW_TRANSACTION.INVOICE_DETAIL_BUILD_PATH(item.successInvoiceId)} />
        )}
      </tr>
    ));
  };

  const onClickExportButton = async (e: any): Promise<void> => {
    e.preventDefault();

    const data = getParsedSearchParams(DE_INVOICE_LIST_QS_KEY).formSearchData;

    const excelInvoiceDetailsHeaders: ColumnOption<any>[] = Array(INVOICE_DETAILS_MAX_COUNT)
      .fill('')
      .map((_, index) => ({
        header: `Invoice Detail (${index + 1})`,
        key: `invoiceDetails${index + 1}`,
      }));

    const excelColumns: ColumnOption<SuccessInvoiceVOModel>[] = [
      {
        header: t('text:Registration_Date'),
        key: 'createdDateTime',
      },
      {
        header: t('text:BOE_Number'),
        key: 'billOfExchangeNo',
      },
      {
        header: t('text:Invoice_Number'),
        key: 'invoiceNumber',
      },
      {
        header: t('text:Invoice_Reference_Number'),
        key: 'referenceNumber',
      },
      {
        header: t('text:Anchor_Name'),
        key: 'anchorClientName',
      },
      {
        header: t('text:Anchor_Tax_Code'),
        key: 'anchorClientTaxCode',
      },
      {
        header: t('text:Currency'),
        key: 'currencyType',
      },
      {
        header: t('text:Invoice_Amount'),
        key: 'invoiceAmount',
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Invoice_Issued_Date'),
        key: 'invoiceIssuedDate',
      },
      {
        header: t('text:Original_Payment_Date'),
        key: 'settlementDate',
      },
      {
        header: t('text:Financing_Eligibility'),
        key: 'blockedByFinancier',
      },
      {
        header: t('text:Settlement_Method'),
        key: 'settlementMethod',
      },
      {
        header: t('text:Financing_Status'),
        key: 'loanStatus',
      },
      {
        header: t('text:Payment_Status'),
        key: 'successInvoiceStatus',
      },
      ...excelInvoiceDetailsHeaders,
    ];

    try {
      const successInvoicePageResponse = await requestDealerSuccessInvoiceList(0, EXCEL_EXPORT_MAX_ROW_COUNT, data);
      const successInvoicePDFPageResponse = successInvoicePageResponse.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const makeInvoiceDetailsObj = (index: number) => {
        const invoiceDetailsObj =
          successInvoicePageResponse?.content?.[index]?.invoiceAdditionalDataList?.reduce((acc, cur, curIndex) => {
            return { ...acc, [`invoiceDetails${curIndex + 1}`]: `${cur.name}: ${cur.value === '' ? '-' : cur.value}` };
          }, {}) ?? {};

        const invoiceDetailsObjLength = Object.keys(invoiceDetailsObj).length;

        const toFillTheEmptyObj = Array(INVOICE_DETAILS_MAX_COUNT - invoiceDetailsObjLength)
          .fill('')
          .reduce((acc, cur, curIndex) => {
            return { ...acc, [`invoiceDetails${invoiceDetailsObjLength + (curIndex + 1)}`]: '-' };
          }, {});

        return { ...invoiceDetailsObj, ...toFillTheEmptyObj };
      };

      const jsonArrayData: any[] = successInvoicePageResponse.content.map((successInvoiceData, index) => ({
        createdDateTime: tableValueManage(
          successInvoiceData.createdDateTime,
          t('format:original-datetime', {
            value: successInvoiceData.createdDateTime,
            key: 'original-datetime',
          }),
        ),
        billOfExchangeNo: tableValueManage(successInvoiceData.billOfExchangeNo),
        invoiceNumber: tableValueManage(successInvoiceData.invoiceNumber),
        referenceNumber: tableValueManage(successInvoiceData.referenceNumber),
        anchorClientName: tableValueManage(successInvoiceData.anchorClientName),
        anchorClientTaxCode: tableValueManage(successInvoiceData.anchorClientTaxCode),
        currencyType: tableValueManage(successInvoiceData.currencyType),
        invoiceAmount: Number(successInvoiceData.invoiceAmount),
        invoiceIssuedDate: tableValueManage(
          successInvoiceData.invoiceIssuedDate,
          t('format:original-date', { value: successInvoiceData.invoiceIssuedDate, key: 'original-date' }),
        ),
        settlementDate: tableValueManage(
          successInvoiceData.settlementDate,
          t('format:original-date', { value: successInvoiceData.settlementDate, key: 'original-date' }),
        ),
        blockedByFinancier: getBlockedStatusText(successInvoiceData.blockedByFinancier),
        settlementMethod: tableValueManage(
          successInvoiceData.settlementMethod,
          t(`code:settlement-method-type.${successInvoiceData.settlementMethod}`),
        ),
        loanStatus: tableValueManage(
          successInvoiceData.loanStatus,
          t(`code:financing-status.${successInvoiceData.loanStatus}`),
        ),
        successInvoiceStatus: tableValueManage(
          successInvoiceData.successInvoiceStatus,
          t(`code:success-invoice-status.${successInvoiceData.successInvoiceStatus}`),
        ),
        ...makeInvoiceDetailsObj(index),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: getTableHeaders(true),
        tableBody: renderResultTable(successInvoicePDFPageResponse, true),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginate = async (selectedPageNumber: number, selectedRowNumber: number): Promise<void> => {
    fetchDeInvoiceList(
      selectedPageNumber,
      selectedRowNumber,
      getParsedSearchParams(DE_INVOICE_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickSuccessInvoiceStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_INVOICE_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="SUCCESS_INVOICE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Invoice')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="confirmedDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="confirmedDateTo" control={searchFormControl} />
              <SearchLabel label={t('text:BOE_Number')} />
              <SearchInput name="billOfExchangeNo" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Number')} />
              <SearchInput name="invoiceNumber" ref={searchFormRegister} />
              <SearchLabel label={t('text:Invoice_Reference_Number')} />
              <SearchInput name="referenceNumber" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorName" ref={searchFormRegister} />
              <SearchLabel label={t('text:Anchor_Tax_Code')} />
              <SearchInput name="anchorTaxCode" ref={searchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Amount')} />
              <SearchInput name="minimumInvoiceAmount" ref={searchFormRegister} col={2} placeholder={t('text:from')} />
              <SearchInput name="maximumInvoiceAmount" ref={searchFormRegister} col={2} placeholder={t('text:to')} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                ref={searchFormRegister}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Issued_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="invoiceIssuedDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="invoiceIssuedDateTo" control={searchFormControl} />
              <SearchLabel label={t('text:Original_Payment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={searchFormControl} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={searchFormControl} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financing_Eligibility')} />
              <SearchSelect
                name="blocked"
                ref={searchFormRegister}
                selectOptions={getSelectOptions<string>('FINANCING_ELIGIBILITY', 'ALL', true)}
              />
              <SearchLabel
                label={t('text:Payment_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickSuccessInvoiceStatus}
              />
              <SearchSelect
                name="successInvoiceStatus"
                ref={searchFormRegister}
                selectOptions={getSelectOptions<SUCCESS_INVOICE_STATUS>('SUCCESS_INVOICE_STATUS', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Settlement_Method')} />
              <SearchSelect
                ref={searchFormRegister}
                name="settlementMethod"
                selectOptions={getSelectOptions<SETTLEMENT_METHOD_TYPE>('SETTLEMENT_METHOD_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Financing_Status')} />
              <SearchSelect
                ref={searchFormRegister}
                name="loanStatusConditions"
                selectOptions={getSelectOptions<LOAN_STATUS>('LOAN_STATUS', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} className="m-0" />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {successInvoicePage?.totalElements ? successInvoicePage.totalElements : '0'}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={getTableHeaders()} />
          <TableBody numOfCol={getTableHeaders().length}>{renderResultTable(successInvoicePage?.content)}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerInvoiceList;
