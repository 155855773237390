import { COLLATERAL_TYPE, CURRENCY_TYPE, FINANCIAL_SETTING_TYPE, stringToEnum } from 'enums';

import { formattingToCostOfFundVOModel } from './CostOfFundVO';
import { formattingToOverdueInterestRateTermSpreadVOModel } from './OverdueInterestRateTermSpreadVO';

import type { CostOfFundVO, CostOfFundVOModel } from './CostOfFundVO';
import type {
  OverdueInterestRateTermSpreadVO,
  OverdueInterestRateTermSpreadVOModel,
} from './OverdueInterestRateTermSpreadVO';

export interface OverdueInterestRateSettingDetailVO {
  id: number;
  activated: boolean;
  settingType: string;
  currencyType: string;
  collateralType: string;
  financierId: number;
  financierName: string;
  anchorAgreementId: number;
  anchorAgreementContractNo: string;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  dealerAgreementId: number;
  dealerAgreementContractNo: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerClientCode: string;
  currentTermSpreadGroupId: number;
  currentCostOfFundId: number;
  termSpreads: OverdueInterestRateTermSpreadVO[];
  costOfFund: CostOfFundVO | null;
}

export interface OverdueInterestRateSettingDetailVOModel extends OverdueInterestRateSettingDetailVO {
  settingType: FINANCIAL_SETTING_TYPE;
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  termSpreads: OverdueInterestRateTermSpreadVOModel[];
  costOfFund: CostOfFundVOModel | null;
}

export function formattingToOverdueInterestRateSettingDetailVOModel(
  data: OverdueInterestRateSettingDetailVO,
): OverdueInterestRateSettingDetailVOModel {
  return {
    ...data,
    settingType: stringToEnum(FINANCIAL_SETTING_TYPE, data.settingType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    termSpreads: data.termSpreads.map(termSpread => formattingToOverdueInterestRateTermSpreadVOModel(termSpread)),
    costOfFund: data.costOfFund ? formattingToCostOfFundVOModel(data.costOfFund) : null,
  };
}
