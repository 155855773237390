import type Pageable from 'models/Pageable';
import type {
  WaitingAnchorPartnerAccountDetailVO,
  WaitingAnchorPartnerAccountDetailVOModel,
} from 'models/vo/WaitingAnchorPartnerAccountDetailVO';
import { formattingToWaitingAnchorPartnerAccountDetailVOModel } from 'models/vo/WaitingAnchorPartnerAccountDetailVO';
import type {
  WaitingAnchorPartnerAccountVO,
  WaitingAnchorPartnerAccountVOModel,
} from 'models/vo/WaitingAnchorPartnerAccountVO';
import { formattingToWaitingAnchorPartnerAccountVOModel } from 'models/vo/WaitingAnchorPartnerAccountVO';
import http from 'utils/http';
import type { CommonResponse } from 'utils/http/CommonResponse';
import API_AC from 'utils/http/api/anchor';

import type {
  ApproveAcWaitingAnchorpartnerAccount,
  CreateAcWaitingAnchorPartnerAccount,
  UpdateAcWaitingAnchorPartnerAccount,
  WaitingAnchorPartnerAccountRequest,
} from './requests';

// 파트너 계좌 수정 대기 목록 조회
export async function requestWaitingAnchorPartnerAccountList(
  pageNumber: number,
  rowCount: number,
  searchValue: WaitingAnchorPartnerAccountRequest,
): Promise<Pageable<WaitingAnchorPartnerAccountVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorPartnerAccountVO[]>>({
    url: API_AC.WAITING_ANCHOR_PARTNER_ACCOUNTS.WAITING_PARTNER_ACCOUNT,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const mappedWaitingAnchorPartnerAccountList: WaitingAnchorPartnerAccountVOModel[] = response.content.map(
    waitingAnchorPartnerAccountData => formattingToWaitingAnchorPartnerAccountVOModel(waitingAnchorPartnerAccountData),
  );

  return {
    ...response,
    content: mappedWaitingAnchorPartnerAccountList,
  };
}

// 파트너 계좌 수정 요청 상세 조회
export async function requestWaitingAnchorPartnerAccountDetail(
  waitingAnchorPartnerAccountId: number,
): Promise<WaitingAnchorPartnerAccountDetailVOModel> {
  const response = await http.get<WaitingAnchorPartnerAccountDetailVO>({
    url: API_AC.WAITING_ANCHOR_PARTNER_ACCOUNTS.WAITING_PARTNER_ACCOUNT_DETAIL(waitingAnchorPartnerAccountId),
  });

  return formattingToWaitingAnchorPartnerAccountDetailVOModel(response);
}

// 파트너 계좌 정보 수정 요청
export async function requestModifyAnchorPartnerAccountDetail(
  data: CreateAcWaitingAnchorPartnerAccount,
): Promise<WaitingAnchorPartnerAccountVOModel> {
  const response = await http.post<WaitingAnchorPartnerAccountVO>({
    url: API_AC.WAITING_ANCHOR_PARTNER_ACCOUNTS.WAITING_PARTNER_ACCOUNT,
    data,
  });

  return formattingToWaitingAnchorPartnerAccountVOModel(response);
}

// 파트너 계좌 정보 수정 요청 수정
export async function requestUpdateWaitingAnchorPartnerAccountDetail(
  waitingAnchorPartnerAccountId: number,
  accountInfo: UpdateAcWaitingAnchorPartnerAccount,
): Promise<WaitingAnchorPartnerAccountVOModel> {
  const response = await http.put<WaitingAnchorPartnerAccountVO>({
    url: API_AC.WAITING_ANCHOR_PARTNER_ACCOUNTS.WAITING_PARTNER_ACCOUNT_DETAIL(waitingAnchorPartnerAccountId),
    data: accountInfo,
  });

  return formattingToWaitingAnchorPartnerAccountVOModel(response);
}

// 파트너 계좌 수정 요청 승인
export async function requestApproveWaitingAnchorPartnerAccountDetail(
  waitingAnchorPartnerAccountId: number,
  otpData: ApproveAcWaitingAnchorpartnerAccount,
): Promise<void> {
  await http.put<WaitingAnchorPartnerAccountVO>({
    url: API_AC.WAITING_ANCHOR_PARTNER_ACCOUNTS.APPROVE_WAITING_PARTNER_ACCOUNT(waitingAnchorPartnerAccountId),
    data: otpData,
  });
}

// 파트너 계좌 수정 요청 반려
export async function requestReturnWaitingAnchorPartnerAccountDetail(
  waitingAnchorPartnerAccountId: number,
  returnReason: string,
): Promise<void> {
  await http.put<WaitingAnchorPartnerAccountVO>({
    url: API_AC.WAITING_ANCHOR_PARTNER_ACCOUNTS.RETURN_WAITING_PARTNER_ACCOUNT(waitingAnchorPartnerAccountId),
    data: { returnReason },
  });
}

// 파트너 계좌 수정 요청 취소
export async function requestCancelWaitingAnchorPartnerAccountDetail(
  waitingAnchorPartnerAccountId: number,
): Promise<void> {
  await http.put<CommonResponse>({
    url: API_AC.WAITING_ANCHOR_PARTNER_ACCOUNTS.CANCEL_WAITING_PARTNER_ACCOUNT(waitingAnchorPartnerAccountId),
  });
}
