import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from '../Button/Button';

interface PropsType {
  title?: string;
  onClick?: (e?: any) => void;
  right?: ReactNode;
}

export const BackHeaderTitle = ({ title, onClick, right }: PropsType) => {
  const history = useHistory();

  return (
    <HeaderTitle
      title={title}
      right={right}
      left={
        <Button
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          size={ButtonSizeEnum.SM}
          onClick={onClick ? onClick : history.goBack}
          className="me-1"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      }
    />
  );
};
