import { useWaitingAnchorFinancingOptionContext } from './Context';

const useWaitingFinancingOptionViewModel = () => {
  const { waitingFinancingOption, waitingFinancingOptionPageable, fetchWaitingFinancingOption } =
    useWaitingAnchorFinancingOptionContext();

  return {
    waitingFinancingOption,
    waitingFinancingOptionPageable,
    fetchWaitingFinancingOption,
  };
};

export default useWaitingFinancingOptionViewModel;
