import type {
  PotentialPartnerAcquisitionSettingVO,
  PotentialPartnerAcquisitionSettingVOModel,
} from 'models/vo/PotentialPartnerAcquisitionSettingVO';
import { formattingToPotentialPartnerAcquisitionSettingVOModel } from 'models/vo/PotentialPartnerAcquisitionSettingVO';
import http from 'utils/http';
import API_AN from 'utils/http/api/anonymous';

/**
 * desc :: 금융사 정보 조회
 * params :: code, financierId
 * **/
export async function requestAnPotentialPartnerAcquisitionSettingFindOneByFinancier(
  code: string,
  financierId: number,
): Promise<PotentialPartnerAcquisitionSettingVOModel> {
  const response = await http.get<PotentialPartnerAcquisitionSettingVO>({
    url: API_AN.POTENTIAL_PARTNER_ACQUISITION_SETTINGS.FIND_ONE_BY_FINANCIER,
    data: { code, 'financier-id': financierId },
  });

  return formattingToPotentialPartnerAcquisitionSettingVOModel(response);
}
