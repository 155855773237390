import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isNil } from 'lodash-es';

import Badge from 'components/stateless/Badge/Badge';
import BasicTableBody from 'components/stateless/BasicTable/BasicTableBody';
import BasicTableBorder from 'components/stateless/BasicTable/BasicTableBorder';
import BasicTableHeader from 'components/stateless/BasicTable/BasicTableHeader';
import Card from 'components/stateless/Card/Card';
import CardBody from 'components/stateless/Card/CardBody';
import CardGroup from 'components/stateless/Card/CardGroup';
import CardHeader from 'components/stateless/Card/CardHeader';
import { InfiniteScroll } from 'components/stateless/InfiniteScroll/InfiniteScroll';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import {
  APPROVAL_EVENT_TYPE,
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  SUCCESS_AR_STATUS,
  SUPPORTED_COLLATERAL_TYPE,
} from 'enums';
import useMounted from 'hooks/useMounted';
import type Pageable from 'models/Pageable';
import type { ArSettlementVOModel } from 'models/vo/ArSettlementVO';
import type { DashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import { requestAnchorArSettlementList } from 'utils/http/api/anchor/ar-settlements';
import { requestAnchorInternalApproval, requestAnchorPartnerRequest } from 'utils/http/api/anchor/dashboard';
import useModal from 'utils/modal/useModal';
import { getCollateralType, getSignIn } from 'utils/storage/LocalStorage';

const getConstant = (sortFunc: Function) => {
  const { t } = useTranslation(['format']);

  const UPCOMING_AP_SETTLEMENT_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Settlement_Date'),
      colWidths: 100,
      hasSort: true,
      sortFunc,
    },
    {
      headerText: t('text:of_AP'),
      colWidths: 50,
      className: 'text-end',
    },
    {
      headerText: t('text:AP_Amount'),
      colWidths: 120,
      className: 'text-end',
    },
  ];

  const PARTNER_REQUEST_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_by'),
      colWidths: 200,
    },
    {
      headerText: t('text:Request'),
      colWidths: 150,
    },
  ];

  const APPROVAL_REQUEST_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_by'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financier'),
      colWidths: 150,
    },
    {
      headerText: t('text:Request'),
      colWidths: 120,
    },
    {
      headerText: t('text:Status'),
      colWidths: 80,
    },
  ];

  return {
    UPCOMING_AP_SETTLEMENT_HEADERS,
    PARTNER_REQUEST_HEADERS,
    APPROVAL_REQUEST_HEADERS,
  };
};

function AnchorDashboard() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();

  const { name, authorityType } = getSignIn() ?? {};
  const collateralType = getCollateralType();

  const [apSettlementList, setApSettlementList] = useState<Pageable<ArSettlementVOModel[]>>();
  const [partnerRequestList, setPartnerRequestList] = useState<Pageable<DashBoardApprovalVOModel[]>>();
  const [internalApprovalList, setInternalApprovalList] = useState<Pageable<DashBoardApprovalVOModel[]>>();
  const [isLoaded, setIsLoaded] = useState(true);
  const arSettlementAscended = useRef(true);
  const pageNumber = useRef(1);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const [apSettlementList, partnerRequestList, internalApprovalList] = await Promise.all([
        requestAnchorArSettlementList(1, 20, {
          arSettlementAscended: arSettlementAscended.current,
          successArStatuses: [SUCCESS_AR_STATUS.REGISTERED, SUCCESS_AR_STATUS.FAILED],
          paymentSupport: true,
        }),
        requestAnchorPartnerRequest(1, 20),
        requestAnchorInternalApproval(1, 20),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setApSettlementList(apSettlementList);
        setPartnerRequestList(partnerRequestList);
        setInternalApprovalList(internalApprovalList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchAndSetSortedArSettlementList = async () => {
    try {
      arSettlementAscended.current = !arSettlementAscended.current;
      pageNumber.current = 1;

      const sortedApSettlementList = await requestAnchorArSettlementList(1, 20, {
        arSettlementAscended: arSettlementAscended.current,
        successArStatuses: [SUCCESS_AR_STATUS.REGISTERED, SUCCESS_AR_STATUS.FAILED],
        paymentSupport: true,
      });

      setApSettlementList(sortedApSettlementList);
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchAndSetArSettlementList = async () => {
    try {
      setIsLoaded(false);
      pageNumber.current += 1;

      const apSettlement = await requestAnchorArSettlementList(pageNumber.current, 20, {
        arSettlementAscended: arSettlementAscended.current,
        successArStatuses: [SUCCESS_AR_STATUS.REGISTERED, SUCCESS_AR_STATUS.FAILED],
        paymentSupport: true,
      });

      setApSettlementList(prev => {
        if (!prev) return prev;

        return { ...prev, content: prev.content.concat(apSettlement.content) };
      });
    } catch (error) {
      modal.show(error);
    } finally {
      setIsLoaded(true);
    }
  };

  const isShowUpComingApSettlement = (): boolean => {
    return (
      (collateralType === SUPPORTED_COLLATERAL_TYPE.AR || collateralType === SUPPORTED_COLLATERAL_TYPE.BOTH) &&
      (authorityType === AUTHORITY_TYPE.ADMIN || authorityType === AUTHORITY_TYPE.HQ_OPERATOR)
    );
  };

  const renderFinancingTypeBadge = (collateralType: COLLATERAL_TYPE): JSX.Element | null => {
    if (collateralType === COLLATERAL_TYPE.AR) {
      return (
        <Badge
          content="VENDOR"
          variant="square"
          bgColor="LIGHT_TURQUOISE"
          fontColor="TURQUOISE"
          style={{ marginLeft: 0, marginRight: '8px' }}
        />
      );
    } else if (collateralType === COLLATERAL_TYPE.INVOICE) {
      return (
        <Badge
          content="DEALER"
          variant="square"
          bgColor="LIGHT_OCHER"
          fontColor="OCHER"
          style={{ marginLeft: 0, marginRight: '8px' }}
        />
      );
    } else {
      return null;
    }
  };

  const getPartnerRequestEventTypeText = (eventType: APPROVAL_EVENT_TYPE): string => {
    switch (eventType) {
      case APPROVAL_EVENT_TYPE.AP_REGISTRATION_REQUEST:
        return t('text:AP_Registration_Request');
      case APPROVAL_EVENT_TYPE.INVOICE_REGISTRATION_REQUEST:
        return t('text:Invoice_Registration_Request');
      default:
        return '-';
    }
  };

  const getInternalApprovalEventTypeText = (eventType: APPROVAL_EVENT_TYPE): string => {
    switch (eventType) {
      case APPROVAL_EVENT_TYPE.AP_CONFIRMATION_REQUEST:
        return t('text:AP_Registration_Request');
      case APPROVAL_EVENT_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_AC:
        return t('text:Invoice_Registration_Request');
      default:
        return '-';
    }
  };

  const getInternalApprovalCurrentEventStatusText = (
    eventType: APPROVAL_EVENT_TYPE,
    currentEventStatus: string,
  ): string => {
    switch (eventType) {
      case APPROVAL_EVENT_TYPE.AP_CONFIRMATION_REQUEST:
        return t(`code:waiting-ar-approval-phase-status.${currentEventStatus}`); // WAITING_AR_APPROVAL_PHASE_STATUS
      case APPROVAL_EVENT_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_AC:
        return t(`code:waiting-invoice-approval-phase-status-by-anchor.${currentEventStatus}`); // WAITING_INVOICE_APPROVAL_PHASE_STATUS
      default:
        return '-';
    }
  };

  const getColPartnerRequestCardGroup = (): number => {
    return isShowUpComingApSettlement() ? 7 : 12;
  };

  const goToApSettlementDetail = (anchorAgreementId: number, settlementDate: string) => {
    history.push(ROUTES_AC.VIEW_TRANSACTION.AP_SETTLEMENT_DETAIL_BUILD_PATH(anchorAgreementId), { settlementDate });
  };

  const goToPartnerRequestDetail = (item: DashBoardApprovalVOModel) => {
    const { eventId, approvalEventType, relatedFinancierId, relatedPartnerAgreementId } = item;

    switch (approvalEventType) {
      case APPROVAL_EVENT_TYPE.AP_REGISTRATION_REQUEST:
        history.push(ROUTES_AC.REGISTER_AP.CONFIRMATION_DETAIL_BUILD_PATH(eventId), {
          financierEnterpriseId: relatedFinancierId,
        });
        break;
      case APPROVAL_EVENT_TYPE.INVOICE_REGISTRATION_REQUEST:
        history.push(ROUTES_AC.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(eventId, relatedFinancierId), {
          dealerAgreementId: relatedPartnerAgreementId,
        });
        break;
      default:
        break;
    }
  };

  const goToInternalApprovalDetail = (item: DashBoardApprovalVOModel) => {
    const { eventId, approvalEventType, relatedFinancierId, relatedPartnerAgreementId } = item;

    switch (approvalEventType) {
      case APPROVAL_EVENT_TYPE.AP_CONFIRMATION_REQUEST:
        history.push(ROUTES_AC.REGISTER_AP.CONFIRMATION_DETAIL_BUILD_PATH(eventId), {
          financierEnterpriseId: relatedFinancierId,
        });
        break;
      case APPROVAL_EVENT_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_AC:
        history.push(ROUTES_AC.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(eventId, relatedFinancierId), {
          dealerAgreementId: relatedPartnerAgreementId,
        });
        break;
      default:
        break;
    }
  };

  const getOverDaysToMaturity = (daysToMaturity: number | undefined) => {
    let result: string;

    if (isNil(daysToMaturity)) result = '-';
    else if (daysToMaturity < 0) result = `D + ${Math.abs(daysToMaturity)}`;
    else result = `D - ${daysToMaturity}`;

    return result;
  };

  const { UPCOMING_AP_SETTLEMENT_HEADERS, PARTNER_REQUEST_HEADERS, APPROVAL_REQUEST_HEADERS } = getConstant(
    fetchAndSetSortedArSettlementList,
  );

  return (
    <div className="bg-gray-wrap">
      <HeaderTitle title={`${t('text:Welcome')}, ${name}`} />
      <div className="content-area mb-0">
        <div className="row">
          <CardGroup className="simple-card-left-group" col={5}>
            {isShowUpComingApSettlement() && (
              <Card style={{ height: '300px' }}>
                <CardHeader>
                  <h2 className="simple-card-title">{t('text:Upcoming_AP_Settlement')}</h2>
                  <Badge
                    content={apSettlementList?.totalElements ?? 0}
                    variant="ellipse"
                    fontColor="WHITE"
                    bgColor="RED"
                  />
                </CardHeader>
                <CardBody>
                  <BasicTableBorder basicTableWrapMaxHeight="152px" arSettlementAscended={arSettlementAscended.current}>
                    <BasicTableHeader header={UPCOMING_AP_SETTLEMENT_HEADERS} />
                    <BasicTableBody numOfCol={UPCOMING_AP_SETTLEMENT_HEADERS.length}>
                      <InfiniteScroll
                        trigger={() => {
                          fetchAndSetArSettlementList();
                        }}
                        isLoaded={isLoaded}
                        pageNumber={pageNumber.current}
                        totalPageCount={apSettlementList?.totalPages ?? 0}
                      >
                        {apSettlementList?.content.map((item, index) => (
                          <Tr
                            key={index}
                            onClick={() => {
                              goToApSettlementDetail(item.anchorAgreementId, item.settlementDate);
                            }}
                          >
                            <Td data={item.settlementDate} format="date">
                              <div className="d-flex align-items-center">
                                {t('format:date', {
                                  value: item.settlementDate,
                                  key: 'date',
                                })}
                                <Badge
                                  className="ms-2"
                                  content={getOverDaysToMaturity(item.daysToMaturity)}
                                  variant="ellipse"
                                  fontColor="RED"
                                  bgColor="LIGHT_RED"
                                  style={{ fontSize: '10px', minWidth: '36px', minHeight: '16px' }}
                                />
                              </div>
                            </Td>
                            <Td className="text-end fw-bold" data={item.totalArCount} />
                            <Td className="text-end">
                              <div className="d-flex align-items-center fw-bold justify-content-end">
                                {`${t('format:number', { value: item.totalArAmount })} ${item.currencyType}`}
                              </div>
                            </Td>
                          </Tr>
                        ))}
                      </InfiniteScroll>
                    </BasicTableBody>
                  </BasicTableBorder>
                </CardBody>
              </Card>
            )}
          </CardGroup>
          <CardGroup className="simple-card-right-group" col={getColPartnerRequestCardGroup()}>
            <Card style={{ height: '300px' }}>
              <CardHeader>
                <h2 className="simple-card-title">{t('text:Partner_Request')}</h2>
                <Badge
                  content={partnerRequestList?.totalElements ?? 0}
                  variant="ellipse"
                  fontColor="WHITE"
                  bgColor="YELLOW"
                />
              </CardHeader>
              <CardBody>
                <BasicTableBorder basicTableWrapMaxHeight="152px">
                  <BasicTableHeader header={PARTNER_REQUEST_HEADERS} />
                  <BasicTableBody numOfCol={PARTNER_REQUEST_HEADERS.length}>
                    {partnerRequestList?.content.map((item, index) => (
                      <Tr
                        key={index}
                        onClick={() => {
                          goToPartnerRequestDetail(item);
                        }}
                      >
                        <Td data={item.updatedDateTime} format="datetime" />
                        <Td data={item.requestedEnterpriseName} />
                        <Td>
                          <div className="d-flex align-items-center">
                            {renderFinancingTypeBadge(item.collateralType)}
                            {getPartnerRequestEventTypeText(item.approvalEventType)}
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </BasicTableBody>
                </BasicTableBorder>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
        <Card style={{ marginTop: '16px', height: '300px' }}>
          <CardHeader>
            <h2 className="simple-card-title">{t('text:Approval_Request')}</h2>
            <Badge
              content={internalApprovalList?.totalElements ?? 0}
              variant="ellipse"
              fontColor="WHITE"
              bgColor="YELLOW"
            />
          </CardHeader>
          <CardBody>
            <BasicTableBorder basicTableWrapMaxHeight="152px">
              <BasicTableHeader header={APPROVAL_REQUEST_HEADERS} />
              <BasicTableBody numOfCol={APPROVAL_REQUEST_HEADERS.length}>
                {internalApprovalList?.content.map((item, index) => (
                  <Tr
                    key={index}
                    onClick={() => {
                      goToInternalApprovalDetail(item);
                    }}
                  >
                    <Td data={item.updatedDateTime} format="datetime" />
                    <Td data={item.requestedUserName} />
                    <Td data={item.relatedFinancierName} />
                    <Td>
                      <div className="d-flex align-items-center">
                        {renderFinancingTypeBadge(item.collateralType)}
                        {getInternalApprovalEventTypeText(item.approvalEventType)}
                      </div>
                    </Td>
                    <Td
                      className="fw-bold"
                      data={getInternalApprovalCurrentEventStatusText(item.approvalEventType, item.currentEventStatus)}
                    />
                  </Tr>
                ))}
              </BasicTableBody>
            </BasicTableBorder>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AnchorDashboard;
