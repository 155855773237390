import type Pageable from 'models/Pageable';
import type {
  WaitingAnchorPartnerAccountDetailVO,
  WaitingAnchorPartnerAccountDetailVOModel,
} from 'models/vo/WaitingAnchorPartnerAccountDetailVO';
import { formattingToWaitingAnchorPartnerAccountDetailVOModel } from 'models/vo/WaitingAnchorPartnerAccountDetailVO';
import type {
  WaitingAnchorPartnerAccountVO,
  WaitingAnchorPartnerAccountVOModel,
} from 'models/vo/WaitingAnchorPartnerAccountVO';
import { formattingToWaitingAnchorPartnerAccountVOModel } from 'models/vo/WaitingAnchorPartnerAccountVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FinancierWaitingAnchorPartnerAccountDataModifyRequest,
  FinancierWaitingAnchorPartnerAccountEditRequest,
  FinancierWaitingAnchorPartnerAccountListRequest,
  FinancierWaitingAnchorPartnerAccountReturnRequest,
} from './requests';

export async function requestFinancierWaitingAnchorPartnerAccountList(
  pageNumber: number,
  rowCount: number,
  data: FinancierWaitingAnchorPartnerAccountListRequest,
): Promise<Pageable<WaitingAnchorPartnerAccountVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorPartnerAccountVO[]>>({
    url: API_FI.WAITING_ANCHOR_PARTNER_ACCOUNTS.WAITING_PARTNER_ACCOUNT,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorPartnerAccountVOModel(data)),
  };
}

export async function requestFinancierWaitingAnchorPartnerAccountData(
  waitingAnchorPartnerAccountId: number,
): Promise<WaitingAnchorPartnerAccountDetailVOModel> {
  const response = await http.get<WaitingAnchorPartnerAccountDetailVO>({
    url: API_FI.WAITING_ANCHOR_PARTNER_ACCOUNTS.WAITING_PARTNER_ACCOUNT_DETAIL(waitingAnchorPartnerAccountId),
  });

  return formattingToWaitingAnchorPartnerAccountDetailVOModel(response);
}

export async function requestFinancierWaitingAnchorPartnerAccountEdit(
  data: FinancierWaitingAnchorPartnerAccountEditRequest,
): Promise<WaitingAnchorPartnerAccountVOModel> {
  const response = await http.post<WaitingAnchorPartnerAccountVO>({
    url: API_FI.WAITING_ANCHOR_PARTNER_ACCOUNTS.WAITING_PARTNER_ACCOUNT,
    data,
  });

  return formattingToWaitingAnchorPartnerAccountVOModel(response);
}

export async function requestFinancierWaitingAnchorPartnerAccountDataModify(
  waitingAnchorPartnerAccountId: number,
  data: FinancierWaitingAnchorPartnerAccountDataModifyRequest,
): Promise<WaitingAnchorPartnerAccountVO> {
  const response = await http.put<WaitingAnchorPartnerAccountVOModel>({
    url: API_FI.WAITING_ANCHOR_PARTNER_ACCOUNTS.WAITING_PARTNER_ACCOUNT_DETAIL(waitingAnchorPartnerAccountId),
    data,
  });

  return formattingToWaitingAnchorPartnerAccountVOModel(response);
}

export async function requestFinancierWaitingAnchorPartnerAccountCancel(
  waitingAnchorPartnerAccountId: number,
): Promise<void> {
  const response = await http.put<void>({
    url: API_FI.WAITING_ANCHOR_PARTNER_ACCOUNTS.CANCEL_WAITING_PARTNER_ACCOUNT(waitingAnchorPartnerAccountId),
  });

  return response;
}

export async function requestFinancierWaitingAnchorPartnerAccountApprove(
  waitingAnchorPartnerAccountId: number,
): Promise<WaitingAnchorPartnerAccountVOModel> {
  const response = await http.put<WaitingAnchorPartnerAccountVO>({
    url: API_FI.WAITING_ANCHOR_PARTNER_ACCOUNTS.APPROVE_WAITING_PARTNER_ACCOUNT(waitingAnchorPartnerAccountId),
  });

  return formattingToWaitingAnchorPartnerAccountVOModel(response);
}

export async function requestFinancierWaitingAnchorPartnerAccountReturn(
  waitingAnchorPartnerAccountId: number,
  data: FinancierWaitingAnchorPartnerAccountReturnRequest,
): Promise<WaitingAnchorPartnerAccountVOModel> {
  const response = await http.put<WaitingAnchorPartnerAccountVO>({
    url: API_FI.WAITING_ANCHOR_PARTNER_ACCOUNTS.RETURN_WAITING_PARTNER_ACCOUNT(waitingAnchorPartnerAccountId),
    data,
  });

  return formattingToWaitingAnchorPartnerAccountVOModel(response);
}
