import type { FinancierOperatorTimeVO, FinancierOperatorTimeVOModel } from 'models/vo/FinancierOperatorTimeVO';
import { formattingToFinancierOperatorTimeVOModel } from 'models/vo/FinancierOperatorTimeVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { UpdateOperatingTimeRequest } from './requests';

export async function requestOperatingTime(): Promise<FinancierOperatorTimeVOModel> {
  const response = await http.get<FinancierOperatorTimeVO>({
    url: API_FI.FINANCIER_OPERATION_TIME.OPERATION_TIME,
  });

  return formattingToFinancierOperatorTimeVOModel(response);
}

export async function requestOperatingTimeUpdate(
  updateOperatingTimeRequest: UpdateOperatingTimeRequest,
): Promise<FinancierOperatorTimeVOModel> {
  const response = await http.put<FinancierOperatorTimeVO>({
    url: API_FI.FINANCIER_OPERATION_TIME.OPERATION_TIME,
    data: updateOperatingTimeRequest,
  });

  return formattingToFinancierOperatorTimeVOModel(response);
}
