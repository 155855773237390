const API_AN = {
  AGREEMENTS: {
    INVITED_ANCHOR_USER: 'v1/an/agreements/invited-anchor-user',
    INVITED_DEALER_AUTHORIZER: 'v1/an/agreements/invited-dealer-authorizer',
  },
  ANCHOR_PARTNERS: {
    ANCHOR_PARTNER_DETAIL: (anchorPartnerId: number) => `v1/an/anchor-partners/${anchorPartnerId}`,
  },
  BRANCHES: {
    BRANCHES: 'v1/an/branches',
    INVITE_BRANCH: 'v1/an/branches/invited-branch',
  },
  CLIENT_AUTH_SETTING: 'v1/an/client-auth-setting',
  EMAIL: {
    EMAIL_TYPE_AND_CODE: 'v1/an/email/find:type_and_code',
    CHECK_EMAIL_STATUS: (code: string) => `v1/an/email/check-status/${code}`,
  },
  ENTERPRISES: {
    INVITED_ENTERPRISE: 'v1/an/enterprises/invited-enterprise',
  },
  FINANCIER_CLIENTS: 'v1/an/financier-clients',
  OTP: {
    SEND_CODE: 'v1/an/otp/send:code',
  },
  POTENTIAL_PARTNER_ACQUISITION_DOCUMENTS: {
    DOCUMENT_LIST: 'v1/an/potential-partner-acquisition-documents',
    DOWNLOAD_DOCUMENT: (potentialPartnerAcquisitionDocumentSettingId: number) =>
      `v1/an/potential-partner-acquisition-documents/${potentialPartnerAcquisitionDocumentSettingId}/download:attachment`,
  },
  POTENTIAL_PARTNER_ACQUISITION_SETTINGS: {
    FIND_ONE_BY_FINANCIER: 'v1/an/potential-partner-acquisition-settings/find-one:by-financier',
  },
  POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS: {
    DOCUMENTS: 'v1/an/potential-partner-financing-application-documents',
    DOWNLOAD_DOCUMENT: (potentialPartnerFinancingApplicationDocumentId: number) =>
      `v1/an/potential-partner-financing-application-documents/${potentialPartnerFinancingApplicationDocumentId}/download:attachment`,
    ADDITIONAL_DOCUMENTS: 'v1/an/potential-partner-financing-application-documents/additional-documents',
    DOCUMENT_DETAIL: (potentialPartnerFinancingApplicationDocumentId: number) =>
      `v1/an/potential-partner-financing-application-documents/${potentialPartnerFinancingApplicationDocumentId}`,
  },
  POTENTIAL_PARTNER_FINANCING_APPLICATIONS: {
    FINANCING_APPLICATIONS: 'v1/an/potential-partner-financing-applications',
    ACTIVATED_APPLICATION: 'v1/an/potential-partner-financing-applications/activated-application',
    FINANCING_APPLICATION_DETAIL: (potentialPartnerFinancingApplicationId: number) =>
      `v1/an/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}`,
    RELATED_POTENTIAL_PARTNER: (potentialPartnerFinancingApplicationId: number) =>
      `v1/an/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/potential-partner`,
    CANCEL_SUBMITTED_APPLICATION: (potentialPartnerFinancingApplicationId: number) =>
      `v1/an/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/cancel:submitted`,
  },
  SUCCESS_ARS: {
    SUCCESS_AR_LIST: 'v1/an/success-ars',
    GROUP_ANCHOR_PARTNER_ACCOUNT: 'v1/an/success-ars/group:anchor-partner-account',
    GROUP_ANCHOR_PARTNER_ACCOUNT_ID: (anchorPartnerAccountId: number) =>
      `v1/an/success-ars/group:anchor-partner-account/${anchorPartnerAccountId}`,
    GROUP_CURRENCY_TYPE: 'v1/an/success-ars/group:currency-type',
  },
  USERS: {
    INVITED_USER: 'v1/an/users/invited-user',
    USER_EXISTENCE: 'v1/an/users/existence',
    SIGN_UP_DEALER_AUTHORIZER: 'v1/an/users/sign-up:dealer-authorizer',
    SIGN_UP_ANCHOR_USER: 'v1/an/users/sign-up:anchor-user',
    SIGN_UP_DEALER_OPERATOR: 'v1/an/users/sign-up:ac-hq-operator-and-de-operator',
    CERTIFY_ANCHOR_USER: 'v1/an/users/certify:anchor-user',
    CERTIFY_DEALER_AUTHORIZER: 'v1/an/users/certify:dealer-authorizer',
    FIND_ID: 'v1/an/users/find:id',
    FIND_PASSWORD: 'v1/an/users/find:password',
    RESET_PASSWORD: 'v1/an/users/reset:password',
    SIGN_UP_FINANCIER_USER: 'v1/an/users/sign-up:financier-user',
  },
  ON_DEMAND: {
    JOIN: 'v1/an/on-demand/join',
    USER_EXISTENCE: 'v1/an/on-demand/users/existence',
  },
  TERMS_OF_USE_VERSIONS: {
    TERMS_OF_USE_VERSIONS: 'v1/an/terms-of-use-versions',
    DOWNLOAD: (termsOfUseVersionId: number) => `v1/an/terms-of-use-versions/${termsOfUseVersionId}/download`,
  },
};

export default API_AN;
