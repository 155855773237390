import type React from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, OTP_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { AnchorFinancingOptionDetailVOModel } from 'models/vo/AnchorFinancingOptionDetailVO';
import type { CalculateForMultipleAnchorLoanRequestVOModel } from 'models/vo/CalculateForMultipleAnchorLoanRequestVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { SuccessArForRequestMultipleAnchorLoanVOModel } from 'models/vo/SuccessArForRequestMultipleAnchorLoanVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestAnchorFinancingOptionDetail } from 'utils/http/api/anchor/anchor-financing-opitons';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestAnchorFinancierCalendar } from 'utils/http/api/anchor/financier-calendar';
import {
  requestAnchorMultipleRequestLoanRequestCalculateSuccessArs,
  requestAnchorMultipleRequestLoanRequestSuccessArs,
} from 'utils/http/api/anchor/multiple-request-anchor-loans';
import type {
  AnchorMultipleLoanCalculateSuccessArsRequest,
  AnchorMultipleLoanSuccessArsRequest,
} from 'utils/http/api/anchor/multiple-request-anchor-loans/requests';
import { requestAnchorSuccessArListForMultipleRequestLoans } from 'utils/http/api/anchor/success-ars';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import type { EtcFileRowTypes } from '../section/additional-documents';

export interface AnchorArBulkFinancingApplicationStep2State {
  anchorFinancingOptionDetail: AnchorFinancingOptionDetailVOModel;
  financierHoliday: Pageable<FinancierCalenderVOModel[]>;
  calculateLoanRequest?: CalculateForMultipleAnchorLoanRequestVOModel;
  successArList: SuccessArForRequestMultipleAnchorLoanVOModel[];
  checkedArList: number[];
  maxDate?: Date;
  minDate?: Date;
  totalSuccessArListCount: number;
  showDisbursementAccountInfo: boolean;
}

export function useAnchorArBulkFinancingApplicationStep2State() {
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation();
  const { anchorFinancingOptionId } = useParams() as any;
  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;

  const [etcFileRows, setEtcFileRows] = useState<EtcFileRowTypes[]>([
    {
      id: 0,
      fileName: undefined,
    },
  ]);

  const [dataState, setDataState] = useState<AnchorArBulkFinancingApplicationStep2State>({
    anchorFinancingOptionDetail: {} as AnchorFinancingOptionDetailVOModel,
    calculateLoanRequest: {} as CalculateForMultipleAnchorLoanRequestVOModel,
    financierHoliday: {} as Pageable<FinancierCalenderVOModel[]>,
    successArList: [],
    checkedArList: [],
    totalSuccessArListCount: 0,
    showDisbursementAccountInfo: false,
  });

  const [totalRequestedAmount, setTotalRequestedAmount] = useState<BigNumber>(); // === selected Total Ar Amount

  const anchorArBulkFinancingUseForm = useForm<AnchorMultipleLoanSuccessArsRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { reset, getValues } = anchorArBulkFinancingUseForm;

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allCheckedArList = dataState.successArList?.map((_: any, index: any) => index);
      setDataState({ ...dataState, checkedArList: allCheckedArList ?? [] });
    } else {
      setDataState({ ...dataState, checkedArList: [] });
      setTotalRequestedAmount('0');
    }
    initializeFinancingConditionInfo();
  };

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.checked) {
      const target = [...dataState.checkedArList, index];
      setDataState({ ...dataState, checkedArList: target });
    } else {
      const target = dataState.checkedArList.filter(arIndex => arIndex !== index);
      setDataState({ ...dataState, checkedArList: target });
    }
    initializeFinancingConditionInfo();
  };

  const fetchFinancingHoliday = async (financierId: number, minDate: Date, maxDate: Date) => {
    return await requestAnchorFinancierCalendar(financierId, {
      pageNumber: 0,
      rowCount: Number(getDayTerm(convertToServerDateFormat(minDate), convertToServerDateFormat(maxDate))) + 1,
      fromDate: convertToServerDateFormat(minDate),
      toDate: convertToServerDateFormat(maxDate),
      holiday: true,
    });
  };

  const getMaxDate = (
    expiryDate: Date,
    settlementDate: Date,
    repaymentDateOverAllowable: boolean,
    maximumLoanRange: number,
  ): Date => {
    const endDate = dayjs(settlementDate).add(maximumLoanRange, 'day').toDate();

    let finalDate = endDate;
    if (!repaymentDateOverAllowable) {
      if (expiryDate < endDate) {
        finalDate = expiryDate;
      }
    }

    return finalDate;
  };

  const fetchAll = async () => {
    try {
      showLoadingUI();
      const fetchedAnchorFinancingOptionDetail = await requestAnchorFinancingOptionDetail(anchorFinancingOptionId);
      const fetchedArSuccessArList = await requestAnchorSuccessArListForMultipleRequestLoans(
        0,
        3000,
        fetchedAnchorFinancingOptionDetail.anchorAgreementId,
      );

      const requestMultipleLoanByArList = fetchedArSuccessArList.content.map(arInfo => ({
        successArId: arInfo.successArId,
        requestedLoanAmount: arInfo.arAmount,
      }));

      const minSettlementDate = new Date(
        Math.min(...fetchedArSuccessArList.content.map(arInfo => new Date(arInfo.arSettlementDate).getTime())),
      );

      const maxSettlementDate = new Date(
        Math.max(...fetchedArSuccessArList.content.map(arInfo => new Date(arInfo.arSettlementDate).getTime())),
      );

      const minDate = dayjs(minSettlementDate)
        .add(fetchedAnchorFinancingOptionDetail.minimumLoanRange, 'days')
        .toDate();

      const maxDate = getMaxDate(
        new Date(fetchedAnchorFinancingOptionDetail.anchorAgreementExpiryDate),
        maxSettlementDate,
        fetchedAnchorFinancingOptionDetail.repaymentDateOverAllowable,
        fetchedAnchorFinancingOptionDetail.maximumLoanRange,
      );

      const holidayList = await fetchFinancingHoliday(
        fetchedAnchorFinancingOptionDetail.financierEntId,
        minDate,
        maxDate,
      );

      unShowLoadingUI();

      setDataState(prevState => ({
        ...prevState,
        anchorFinancingOptionDetail: fetchedAnchorFinancingOptionDetail,
        financierHoliday: holidayList,
        minDate,
        maxDate,
        successArList: fetchedArSuccessArList.content,
        totalSuccessArListCount: fetchedArSuccessArList.totalElements,
        showDisbursementAccountInfo: fetchedAnchorFinancingOptionDetail.anchorAccount,
      }));

      reset({
        ...getValues(),
        requestedArs: requestMultipleLoanByArList,
      });
    } catch (e) {
      unShowLoadingUI();
      modal.show(e);
    }
  };

  const fetchAnchorArBulkCalculateLoanRequest = async (data: AnchorMultipleLoanCalculateSuccessArsRequest) => {
    try {
      const fetchedCalculateMultipleLoanRequest = await requestAnchorMultipleRequestLoanRequestCalculateSuccessArs(
        data,
      );

      setDataState(prevState => ({
        ...prevState,
        calculateLoanRequest: fetchedCalculateMultipleLoanRequest,
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const calculateRequestedAmount = useCallback(
    (checkedArList: number[]) => {
      const arInfoList = dataState.successArList;
      const checkedArInfoList = arInfoList?.filter((_: any, rowIndex: number) => checkedArList.includes(rowIndex));
      const calculatorBigNumber = new CalculatorBigNumber();

      let sum: BigNumber = '0';
      if (checkedArInfoList?.length !== 0) {
        checkedArInfoList?.forEach(arInfo => {
          if (arInfo && arInfo.arAmount) {
            sum = calculatorBigNumber.add(sum).add(arInfo.arAmount).get();
          }
        });
      }
      setTotalRequestedAmount(sum);
    },
    [getValues, setDataState, dataState.successArList],
  );

  const getCheckedLoanByArRequestList = () => {
    const loanByArRequestList = getValues().requestedArs;
    const checkedLoanByArRequestList = loanByArRequestList
      .map((arInfo, index) => ({ ...arInfo, index }))
      .filter((_, rowIndex) => dataState.checkedArList.includes(rowIndex));

    return checkedLoanByArRequestList;
  };

  const manipulateLoanEtcAttachmentRequestData = (data: AnchorMultipleLoanSuccessArsRequest) => {
    const etcAttachmentsArr: FileList[] = [];
    const etcAttachmentDescriptionsArr: string[] = [];

    const emptyFile = (id: number, fileName: string | undefined) =>
      data.etcAttachments?.[id]?.length === 0 && !fileName;
    const emptyDescription = (id: number) => !data?.etcAttachmentDescriptions?.[id];

    etcFileRows?.forEach(item => {
      if (emptyFile(item.id, item.fileName) && emptyDescription(item.id)) return;
      etcAttachmentsArr.push((data.etcAttachments as FileList[])?.[item.id]);
      etcAttachmentDescriptionsArr.push(data?.etcAttachmentDescriptions?.[item.id] as string);
    });

    data.etcAttachments = etcAttachmentsArr;
    data.etcAttachmentDescriptions = etcAttachmentDescriptionsArr;
  };

  const manipulateRegistrationRequestData = (): AnchorMultipleLoanSuccessArsRequest => {
    const data = getValues();
    const { anchorAgreementId } = dataState.anchorFinancingOptionDetail;
    const checkedLoanByArRequestList = getCheckedLoanByArRequestList();

    manipulateLoanEtcAttachmentRequestData(data);

    data.requestedArs = checkedLoanByArRequestList.map(requestedAr => ({
      successArId: requestedAr.successArId,
    }));
    data.anchorAgreementId = anchorAgreementId;

    return data;
  };

  const showUserVerificationModal = (clientAuthTypeData: FinancierClientAuthSettingVOModel) => {
    const { financierEntId } = dataState.anchorFinancingOptionDetail;
    const verificationCode: UserVerificationCodeRequest = {};

    modal.show(
      <UserVerificationModal
        modalId={modal.id}
        verificationCode={verificationCode}
        requestIdType="financierId"
        requestId={financierEntId}
        clientAuthSetting={clientAuthTypeData}
      />,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          if (!verificationCode) {
            modal.close(modal.id);
            modal.show(t('text:Please_check_the_verification_code_and_enter_it_below'), {
              closeBtnCb: () => {
                showUserVerificationModal(clientAuthTypeData);
              },
            });
          } else {
            await requestRegisterArLoan(verificationCode);
          }
        },
      },
    );
  };

  const requestRegisterArLoan = async (verificationCode?: UserVerificationCodeRequest) => {
    const formData = manipulateRegistrationRequestData();

    if (verificationCode) {
      formData.otpCode = verificationCode.otpCode;
      formData.queryValue = verificationCode.queryValue;
    }

    try {
      requestDTOParser(formData);
      await requestAnchorMultipleRequestLoanRequestSuccessArs(formData);
      showFinancingRequestCompletedModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const showFinancingRequestCompletedModal = () => {
    modal.show(<h6>{t('text:The_financing_application_has_been_submitted')}</h6>, {
      modalType: ModalType.ALERT,
      title: t('text:Notice'),
      closeBtnText: t('text:OK'),
      closeBtnCb: () => history.push(ROUTES_AC.MANAGE_ANCHOR_FINANCING.APPLICATION_LIST_STEP1),
    });
  };

  const showLoanRequestModal = () => {
    modal.show(<h6>{t('text:Would_you_like_to_submit_the_financing_application?')}</h6>, {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      confirmBtnCb: async () => {
        const clientAuthTypeData = await requestAnchorClientAuthByFinancierId(
          dataState.anchorFinancingOptionDetail.financierEntId,
        );

        clientAuthTypeData.otpType !== OTP_TYPE.NONE && isAdmin
          ? showUserVerificationModal(clientAuthTypeData)
          : await requestRegisterArLoan();
      },

      closeBtnText: t('text:Cancel'),
    });
  };

  const showCancelFinancingModal = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_financing_application?')}
        <br />
        {t(
          'text:If_you_cancel_the_financing_application_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        title: t('text:Cancel_Financing_Request'),
        confirmBtnCb: () => history.push(ROUTES_AC.MANAGE_ANCHOR_FINANCING.APPLICATION_LIST_STEP1),
      },
    );
  };

  const initializeFinancingConditionInfo = () => {
    setDataState(prevState => ({
      ...prevState,
      calculateLoanRequest: undefined,
    }));
  };

  return {
    state: dataState,
    anchorArBulkFinancingUseForm,
    fetchAnchorArBulkCalculateLoanRequest,
    fetchAll,
    handleCheckAll,
    handleCheckChange,
    totalRequestedAmount,
    etcFileRowsState: {
      etcFileRows,
      setEtcFileRows,
    },
    calculateRequestedAmount,
    showCancelFinancingModal,
    showLoanRequestModal,
    getCheckedLoanByArRequestList,
    initializeFinancingConditionInfo,
  };
}
