import { useState } from 'react';

import type Pageable from 'models/Pageable';
import { getParsedSearchParams } from 'utils/searchParams';

export interface PageableType {
  paginateProxy: (currentPage: number, sizePerPage: number, cb: Function) => Promise<void>;
  currentPage: number;
  totalRows: number;
  sizePerPage: number;
}

export default function usePageable(qsKey?: string) {
  // TODO useCallback
  const paginateProxy = async (currentPage: number, sizePerPage: number, cb: Function) => {
    cb(currentPage, sizePerPage);
    //setPagination({...pagination, currentPage, sizePerPage,})
  };

  // TODO useCallback
  const setPageable = (pageableResponse: Pageable<any>) => {
    // TODO: Backend에서 관리하는 Pageable 객체의 PageNumber는 0부터 시작함. 추후 Frontend도 동일하게 작동하게 끔 수정 필요.
    const currentPage = pageableResponse.pageable.pageNumber + 1;
    const totalRows = pageableResponse.totalElements;
    const sizePerPage = pageableResponse.size;
    setPagination({ ...pagination, currentPage, totalRows, sizePerPage });
  };

  const { pageNumber, rowCount } = getParsedSearchParams(qsKey ? qsKey : '');

  const initialPagination = {
    currentPage: pageNumber ? pageNumber : 1,
    totalRows: 0,
    sizePerPage: rowCount ? rowCount : 10,
  };

  const [pagination, setPagination] = useState(initialPagination);

  return {
    pageable: { ...pagination, paginateProxy },
    setPageable: setPageable,
  };
}
