import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isEmpty, isEqualWith, isNil } from 'lodash-es';

import { ROUTES_FI } from 'constants/routes/financier';
import {
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  DEALER_AGREEMENT_STATUS,
  DEALER_AGREEMENT_TYPE,
  INTEREST_REPAYMENT_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
} from 'enums';
import { AgreementExceptionCode, InvalidInputValueExceptionCode } from 'enums/exception';
import useBeforeUnload from 'hooks/useBeforeUnload';
import type { DealerAgreementCommonVOModel } from 'models/convertor/dealerAgreement';
import { convertToDealerAgreementResetData } from 'models/convertor/dealerAgreement';
import useAgreementViewModel from 'pages/financier/manage-partner/agreement/models/agreement/useAgreementViewModel';
import useTermSpreadViewModel from 'pages/financier/manage-partner/agreement/models/agreement/useTermSpreadViewModel';
import useExtraInformationViewModel from 'pages/financier/manage-partner/agreement/models/extraInformation/useExtraInformationViewModel';
import useFinancierSettingViewModel from 'pages/financier/manage-partner/agreement/models/financierSetting/useFinancierSettingViewModel';
import { HttpError } from 'utils/error/HttpError';
import { formErrorHandler } from 'utils/error/manager';
import { isNilOrEmptyString } from 'utils/helpers';
import { requestFinancierTempWaitingDealerAgreementRegister } from 'utils/http/api/financier/temp-waiting-dealer-agreements';
import { requestUpdateDealerAgreementDetail } from 'utils/http/api/financier/waiting-dealer-agreements';
import type { CreateFiWaitingDealerAgreementDTO } from 'utils/http/api/financier/waiting-dealer-agreements/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getObjectValue } from 'utils/object';
import { requestDTOParser, requestDTOParserForArrayObj } from 'utils/valueManager/ValueManager';

import useAnchorAgreementInfoViewModel from '../models/agreement/useAnchorAgreementInfoViewModel';

const useRegistrationController = () => {
  const [initialFormValues, setInitialFormValues] = useState<CreateFiWaitingDealerAgreementDTO>(
    {} as CreateFiWaitingDealerAgreementDTO,
  );

  const canLeavePageRef = useRef(false);

  const { t } = useTranslation();
  const { show: showModal } = useModal();
  const history = useHistory();

  const { fetchSavedAgreement, updateAllAgreement, agreement, isSearchedAgreement, isMaturityExtension } =
    useAgreementViewModel();
  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();
  const { termSpreadList } = useTermSpreadViewModel();
  const { potentialPartnerRegistrable, factoringEnable } = useFinancierSettingViewModel();
  const { useAgreementInterface, isMatchedWithUploadedPartner, supportedCollateralType, errorHandlerOfLocationState } =
    useExtraInformationViewModel();
  const { fetchFinancierSetting } = useFinancierSettingViewModel();

  const methods = useForm<CreateFiWaitingDealerAgreementDTO>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const getReadOnlyValue = useCallback(
    (parameterName?: string): boolean => {
      if (!useAgreementInterface) return false;

      const allAgreement = { ...agreement, ...anchorAgreementInfo, termSpreadList };

      const agreementValue = getObjectValue({
        object: allAgreement,
        name: parameterName ?? '',
      });

      return !(isSearchedAgreement && isNilOrEmptyString(agreementValue));
    },
    [agreement, anchorAgreementInfo, isSearchedAgreement, termSpreadList, useAgreementInterface],
  );

  const { reset, setValue, getValues, setError, clearErrors } = methods;

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const isFormChanged = !isEqualWith(initialFormValues, getValues(), (a, b) => {
    if ((typeof a === 'number' || typeof b === 'number') && a === b) return true;
    if (isNilOrEmptyString(a) && isNilOrEmptyString(b)) return true;
  });

  const ignoreNavigationGuard = useAgreementInterface || canLeavePageRef.current;

  const isEditableStyle = useAgreementInterface ? isSearchedAgreement : true;

  const isVendorFactoring = factoringEnable && isAr;

  useBeforeUnload(isFormChanged, [initialFormValues, getValues()]);

  const GuideMessageText: string[] = (() => {
    return useAgreementInterface
      ? [t('text:Click_on_the_Search_Agreement_button_to_view_the_agreement_information')]
      : [
          t('text:Please_enter_the_agreement_information_below'),
          t(
            'text:Enter_the_anchor,_partner_company_or_responsible_branch_information_by_using_the_Search_buttons_below',
          ),
          t('text:Enter_the_anchor_user_information_by_using_the_Search_Anchor_User_button_below'),
        ];
  })();

  // Cancel Button
  const handleCancelButtonClick = () => {
    canLeavePageRef.current = true;

    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => history.push(ROUTES_FI.MANAGE_PARTNER.AGREEMENT_LIST),
        closeBtnCb: () => (canLeavePageRef.current = false),
      },
    );
  };

  // Register Button
  const canRegister = () => {
    const shouldCheckUploadedPartner =
      isAr && getValues('dealerAgreementType') === DEALER_AGREEMENT_TYPE.WITH_ANCHOR && potentialPartnerRegistrable;

    if (shouldCheckUploadedPartner && !isMatchedWithUploadedPartner) {
      showModal(<h6>{t('text:Please_check_uploaded_partner')}</h6>);

      return false;
    }

    if (useAgreementInterface && !isSearchedAgreement) {
      showModal(<h6>{t('text:Click_the_Search_Agreement_button_to_retrieve_the_agreement_information')}</h6>);

      return false;
    }

    return true;
  };

  const handleARRequiredFieldsValidation = () => {
    const data = getValues();
    /**
     * AR에서 필수
     * 전송 시 몇몇 필드들에 대한 검증이 먼저 들어가기 때문에 앞서 들어간 검증이 통과되어야 에러코드가 내려옴(늦게). 우선 FE에서 에러 표시
     */
    const arRequiredFields = [
      { name: 'principalRepaymentAccountFinancierName', value: data.principalRepaymentAccountFinancierName },
      { name: 'principalRepaymentAccountBranchName', value: data.principalRepaymentAccountBranchName },
      { name: 'principalRepaymentAccount', value: data.principalRepaymentAccount },
      { name: 'principalRepaymentAccountOwner', value: data.principalRepaymentAccountOwner },
    ];

    if (isAr) {
      arRequiredFields.forEach(item => {
        if (isNilOrEmptyString(item.value)) {
          setError(item.name, {
            message: t('text:This_information_is_required'),
          });
        } else clearErrors(item.name);
      });
    }
  };

  const handleVendorFactoringRequiredFieldsValidation = () => {
    const data = getValues();
    const vendorFactoringRequiredFields = [
      { name: 'settlementAccountFinancierName', value: data.settlementAccountFinancierName },
      { name: 'settlementAccountBranchName', value: data.settlementAccountBranchName },
      { name: 'settlementAccount', value: data.settlementAccount },
      { name: 'settlementAccountOwner', value: data.settlementAccountOwner },
    ];

    if (isVendorFactoring) {
      vendorFactoringRequiredFields.forEach(item => {
        if (isNilOrEmptyString(item.value)) {
          setError(item.name, {
            message: t('text:This_information_is_required'),
          });
        } else clearErrors(item.name);
      });
    }
  };

  const handleUpdateAgreementError = (error: any) => {
    showModal(error);

    const isInvalidError = !isEmpty(formErrorHandler<CreateFiWaitingDealerAgreementDTO>(error, setError, clearErrors));

    handleARRequiredFieldsValidation();

    handleVendorFactoringRequiredFieldsValidation();

    if (isInvalidError) return;

    const getMonthlyInterestRepaymentDateErrorMessage = () => {
      const monthlyInterestRepaymentDate = getValues('monthlyInterestRepaymentDate');
      if (isNil(monthlyInterestRepaymentDate) || Number(monthlyInterestRepaymentDate) === 0)
        return t('text:0_and_blank_are_not_allowed');
      else return t('text:Date_must_be_between_1_and_31');
    };

    switch (error.code) {
      case AgreementExceptionCode.INVALID_BASIS_INTEREST_RATE:
        return setError('basisInterestRate', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_LOAN_TERM:
        return setError('loanTermUnit', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_LOAN_LIMIT_CHECK_TYPE:
        return setError('loanLimitCheckType', { shouldFocus: true });
      case AgreementExceptionCode.OMITTED_ANCHOR_AGREEMENT_INFO:
        return setError('anchorAgreementId', { shouldFocus: true });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_INTEREST_REPAYMENT_TYPE:
        return setError('monthlyInterestRepaymentDate', {
          shouldFocus: true,
          message: getMonthlyInterestRepaymentDateErrorMessage(),
        });
      default:
        return;
    }
  };

  const updateAgreement = async () => {
    const data = getValues();

    const bankUserIdList = data.bankUserIdList as string;

    data.bankUserIdList = bankUserIdList ? bankUserIdList.split(',') : [];
    data.termSpreadList = data.termSpreadList ?? termSpreadList;
    if (isMaturityExtension === 'false') data.maxExtensibleLoanCount = data.maxExtensibleLoanCount ?? 0;
    if (data.basisInterestType === BASIS_INTEREST_TYPE.FIXED) data.basisInterestBankCode = BASIS_INTEREST_TYPE.FIXED;

    if (isInvoice) {
      data.dealerAgreementStatus = DEALER_AGREEMENT_STATUS.ACTIVATED;
    }

    if (isAr) {
      if (data.loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE) data.loanLimitAmount = String(0);
    }

    if (isVendorFactoring) {
      data.interestRepaymentType = INTEREST_REPAYMENT_TYPE.EARLIER;
      data.showNetDisbursementAmount = 'true';
      data.earlyRepaymentAllowable = 'false';
      data.maxExtensibleLoanCount = 0;
      data.showExpectedTotalInterestRate = 'true';
    }

    requestDTOParser(data);
    requestDTOParserForArrayObj(data.termSpreadList);

    await requestUpdateDealerAgreementDetail(data);
  };

  const showRegistrationCompletedModal = () => {
    canLeavePageRef.current = true;

    showModal(
      <h6>
        {t('text:Request_for_approval_of_master_agreement_registration_has_been_completed')}
        <br />
        {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
      </h6>,
      {
        closeBtnCb: () => {
          history.push(ROUTES_FI.MANAGE_PARTNER.AGREEMENT_LIST);
        },
      },
    );
  };

  const handleRegisterButtonClick = () => {
    if (canRegister()) {
      showModal(
        <h6>
          {t('text:Would_you_like_to_request_approval_for_registration?')}
          <br />
          {t(
            'text:Please_note_that_if_modifications_occur_after_the_completion_of_the_request_the_approval_request_must_be_cancelled_and_processed_again',
          )}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: async () => {
            try {
              await updateAgreement();
              showRegistrationCompletedModal();
            } catch (e) {
              handleUpdateAgreementError(e);
            }
          },
        },
      );
    }
  };

  // Save Button
  const handleUpdateSaveAgreementError = (error: any) => {
    if (error instanceof HttpError && error.code === InvalidInputValueExceptionCode.INVALID_INPUT_VALUE) {
      return showModal(<h6>{t('text:Please_check_that_all_the_required_information_has_been_entered_correctly')}</h6>);
    }

    showModal(error);
  };

  const updateSaveAgreement = async () => {
    const data = getValues();

    data.termSpreadList = data.termSpreadList ?? termSpreadList;
    data.bankUserIdList = data.bankUserIdList ? (data.bankUserIdList as string).split(',') : [];
    data.maxExtensibleLoanCount = data.maxExtensibleLoanCount ?? 0;
    if (data.basisInterestType === BASIS_INTEREST_TYPE.FIXED) data.basisInterestBankCode = BASIS_INTEREST_TYPE.FIXED;

    requestDTOParser(data);
    await requestFinancierTempWaitingDealerAgreementRegister(data);
    clearErrors(); // 등록 시도 시 나타난 에러 표시 삭제
  };

  const showSaveCompletedModal = () => {
    canLeavePageRef.current = true;

    showModal(t('text:The_information_has_been_saved_successfully'), {
      closeBtnCb: () => history.push(ROUTES_FI.MANAGE_PARTNER.AGREEMENT_LIST),
    });
  };

  const handleSaveButtonClick = () => {
    if (isFormChanged) {
      showModal(
        <h6>
          {t('text:Would_you_like_to_save_the_agreement_information_you_entered?')}
          <br />
          {t(
            'text:Please_note_that_the_agreement_will_not_be_submitted_until_you_click_the_Registration_Request_button',
          )}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: async () => {
            try {
              await updateSaveAgreement();
              showSaveCompletedModal();
            } catch (e) {
              handleUpdateSaveAgreementError(e);
            }
          },
        },
      );
    } else {
      showModal(
        <h6>
          {t('text:There_are_no_modified_contents')}
          <br />
          {t('text:Please_make_a_change_to_save_the_agreement')}
        </h6>,
      );
    }
  };

  // Import Saved Data
  const updateAndResetSavedAgreement = async (savedAgreement: DealerAgreementCommonVOModel) => {
    if (savedAgreement.basisInterestBankCode === BASIS_INTEREST_TYPE.FIXED) savedAgreement.basisInterestBankCode = '';

    reset(convertToDealerAgreementResetData(savedAgreement));
    updateAllAgreement(savedAgreement);
  };

  const showSavedAgreementModalIfAvailable = async () => {
    if (useAgreementInterface || errorHandlerOfLocationState()) return;

    setInitialFormValues(getValues());

    const savedAgreement = await fetchSavedAgreement(supportedCollateralType);

    if (!isEmpty(savedAgreement)) {
      showModal(
        <h6>
          {t('text:There_is_a_temporarily_saved_agreement')}
          <br />
          {t('text:Would_you_like_to_retrieve_the_saved_draft_and_continue_editing?')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: async () => {
            await updateAndResetSavedAgreement(savedAgreement);
            setInitialFormValues(getValues());
          },
        },
      );
    }
  };

  const resetPage = async () => {
    if (isAr) setValue('dealerAgreementType', DEALER_AGREEMENT_TYPE.WITH_ANCHOR);

    await fetchFinancierSetting();
    await showSavedAgreementModalIfAvailable();
  };

  useEffect(() => {
    resetPage();
  }, []);

  return {
    GuideMessageText,
    isAr,
    supportedCollateralType,
    activatedNavigationGuard: isFormChanged,
    ignoreNavigationGuard,
    useAgreementInterface,
    methods,
    handleSaveButtonClick,
    handleCancelButtonClick,
    handleRegisterButtonClick,
    getReadOnlyValue,
    isRegistrationRequestButtonDisabled: !isEditableStyle,
    isEditableStyle,
    isVendorFactoring,
  };
};

export default useRegistrationController;
