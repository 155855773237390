import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';

import AnchorDealerRegistrationListCompletedTab from './tabs/AnchorDealerRegistrationListCompletedTab';
import AnchorDealerRegistrationListFailedTab from './tabs/AnchorDealerRegistrationListFailedTab';
import AnchorDealerRegistrationListWaitingTab from './tabs/AnchorDealerRegistrationListWaitingTab';
import { useAnchorDealerRegistrationListPageState } from './useAnchorDealerRegistrationListPageState';

function AnchorDealerRegistrationListPage() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();

  const { state, fetchAll, useForms, pageableObjects, fetchUtils } = useAnchorDealerRegistrationListPageState();

  const { waitingListResult, failedListResult, completedListResult } = state;
  const { waitingListPageable, completedListPageable, failedListPageable } = pageableObjects;
  const { waitingListFetchUtils, completedListFetchUtils, failedListFetchUtils } = fetchUtils;

  const { waitingListSearchForm, completedListSearchForm, failedListSearchForm } = useForms;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  return (
    <>
      <HeaderTitle title={t('text:Dealer_Registration')} />
      <GuideMessage
        message={[
          t(
            'text:Click_on_the_Register_Dealer_button_to_register_the_information_of_Anchors_new_Dealer_to_the_platform',
          ),
          t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Dealer'),
        ]}
      >
        {{
          button: (
            <Button
              onClick={() => {
                history.push(ROUTES_AC.MANAGE_DEALER.REGISTRATION_STEP1);
              }}
            >
              {t('text:Register_Dealer')}
            </Button>
          ),
        }}
      </GuideMessage>
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:Waiting_for_Approval'),
            tabItemCount:
              (waitingListSearchForm.getValues('approvalTypes') as any) === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL
                ? 0
                : state.waitingListResultForTabCount?.totalElements ?? 0,
          },
          {
            tabName: t('text:Registration_Completed'),
            tabItemCount: completedListResult.totalElements ?? 0,
          },
          {
            tabName: t('text:Registration_Failed'),
            tabItemCount: failedListResult.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...waitingListSearchForm} key="waiting">
            <AnchorDealerRegistrationListWaitingTab
              {...waitingListFetchUtils()}
              pageable={waitingListPageable}
              waitingAnchorDealerList={waitingListResult}
            />
          </FormProvider>,

          <FormProvider {...completedListSearchForm} key="completed">
            <AnchorDealerRegistrationListCompletedTab
              {...completedListFetchUtils()}
              pageable={completedListPageable}
              data={completedListResult}
            />
          </FormProvider>,
          <FormProvider {...failedListSearchForm} key="failed">
            <AnchorDealerRegistrationListFailedTab
              {...failedListFetchUtils()}
              pageable={failedListPageable}
              data={failedListResult}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default AnchorDealerRegistrationListPage;
