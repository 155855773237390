import type React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE } from 'enums';
import { InvalidInputValueExceptionCode } from 'enums/exception';
import useMounted from 'hooks/useMounted';
import { formErrorHandler } from 'utils/error/manager';
import { requestOperatingTime, requestOperatingTimeUpdate } from 'utils/http/api/financier/financier-operation-time';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

interface UpdateOperatingTime {
  startHour: string;
  startMinute: string;
  endHour: string;
  endMinute: string;
  totalHour: string;
  totalMinute: string;
}

function FinancierManageOperationHour() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation();
  const {
    register: operatingTimeFormRegister,
    getValues: getOperatingTimeFormValues,
    setValue: setOperatingTimeFormValue,
    reset: operatingTimeFormReset,
    watch: watchOperatingTimeForm,
    errors,
    setError,
    clearErrors,
  } = useForm<UpdateOperatingTime>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const [isEdit, setIsEdit] = useState(false);
  const { startHour, startMinute, endHour, endMinute } = watchOperatingTimeForm();
  const CURRENT_USER_AUTHORITY_TYPE = getSignIn()?.authorityType;
  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const operatingTimeResponse = await requestOperatingTime();

      operatingTimeFormReset({
        startHour:
          String(operatingTimeResponse.startHour).length === 1
            ? `0${String(operatingTimeResponse.startHour)}`
            : String(operatingTimeResponse.startHour),
        startMinute:
          String(operatingTimeResponse.startMinute).length === 1
            ? `0${String(operatingTimeResponse.startMinute)}`
            : String(operatingTimeResponse.startMinute),
        endHour:
          String(operatingTimeResponse.endHour).length === 1
            ? `0${String(operatingTimeResponse.endHour)}`
            : String(operatingTimeResponse.endHour),
        endMinute:
          String(operatingTimeResponse.endMinute).length === 1
            ? `0${String(operatingTimeResponse.endMinute)}`
            : String(operatingTimeResponse.endMinute),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const is24h = () => {
    return (
      (startHour === '0' || startHour === '00') &&
      (startMinute === '0' || startMinute === '00') &&
      (endHour === '0' || endHour === '00') &&
      (endMinute === '0' || endMinute === '00')
    );
  };

  const onClick24h = (e: any) => {
    e.preventDefault();

    modal.show(<h6>{t('text:Would_you_like_to_change_the_operating_hours_to_24_hours?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async () => {
        operatingTimeFormReset({
          startHour: '00',
          startMinute: '00',
          endHour: '00',
          endMinute: '00',
        });

        try {
          await requestOperatingTimeUpdate({
            startHour: 0,
            startMinute: 0,
            endHour: 0,
            endMinute: 0,
          });

          modal.show(
            <h6>
              {t('text:The_business_hours_have_been_changed_to_24_hours')}
              <br />
              {t('text:If_you_want_to_change_your_business_hours_again,_click_the_Edit_button_to_change_it')}
            </h6>,
          );
        } catch (e) {
          modal.show(e);

          formErrorHandler<UpdateOperatingTime>(e, setError, clearErrors);
        }
      },
    });
  };

  const onClickEdit = (e: any) => {
    e.preventDefault();

    setIsEdit(true);
  };

  const onClickCancel = (e: any) => {
    e.preventDefault();

    setIsEdit(false);
    operatingTimeFormReset();
  };

  const onClickSave = async (e: any) => {
    e.preventDefault();

    const updateOperatimgTime = getOperatingTimeFormValues();

    try {
      await requestOperatingTimeUpdate({
        startHour: Number(updateOperatimgTime.startHour),
        startMinute: Number(updateOperatimgTime.startMinute),
        endHour: Number(updateOperatimgTime.endHour),
        endMinute: Number(updateOperatimgTime.endMinute),
      });

      setIsEdit(false);
      modal.show(<h6>{t('text:The_operating_hours_have_been_changed')}</h6>);
      fetchAll();
    } catch (e: any) {
      modal.show(e);
      if (e.message === InvalidInputValueExceptionCode.INVALID_INPUT_VALUE) {
        e.data.map((data: { field: keyof UpdateOperatingTime; value: string; details: string }) => {
          setError(data.field, {
            type: InvalidInputValueExceptionCode.INVALID_INPUT_VALUE,
            message: data.details,
          });
        });
      }
    }
  };

  const watchInputValidator = (
    e: React.ChangeEvent<HTMLInputElement>,
    registerName: 'startHour' | 'startMinute' | 'endHour' | 'endMinute',
  ) => {
    const testNumericRegex = /^[0-9]*$/;
    const matchNotNumericRegex = /[0-9]/;
    const showTimeTypeValidateModal = (): void => {
      modal.show(
        <h6>
          {t('text:The_time_input_format_is_not_valid')}
          <br />
          {t('text:Please_check_and_enter_it_again')}
        </h6>,
      );
    };

    if (!testNumericRegex.test(e.target.value)) {
      const newValue =
        e.target.value.match(matchNotNumericRegex) === null ? '' : e.target.value.match(matchNotNumericRegex)?.join('');

      setOperatingTimeFormValue(registerName, newValue);
    } else {
      switch (registerName) {
        case 'startHour':
        case 'endHour':
          if (Number(e.target.value) > 23) {
            showTimeTypeValidateModal();
            setOperatingTimeFormValue(registerName, e.target.value.substring(0, 1));
          }
          break;
        case 'startMinute':
        case 'endMinute':
          if (Number(e.target.value) > 59) {
            showTimeTypeValidateModal();
            setOperatingTimeFormValue(registerName, e.target.value.substring(0, 1));
          }
          break;
        default:
      }
    }
  };

  const operatingHourCalculator = (setPlace: 'HH' | 'MM'): string => {
    if (
      startHour === '' ||
      startHour === undefined ||
      startMinute === '' ||
      startMinute === undefined ||
      endHour === '' ||
      endHour === undefined ||
      endMinute === '' ||
      endMinute === undefined
    ) {
      return '';
    } else if (
      (startHour === '00' || startHour === '0') &&
      (startMinute === '00' || startMinute === '0') &&
      (endHour === '00' || endHour === '0') &&
      (endMinute === '00' || endMinute === '0')
    ) {
      return setPlace === 'HH' ? '24' : '00';
    } else {
      const showMinute =
        Number(endMinute) - Number(startMinute) < 0
          ? Number(endMinute) + 60 - Number(startMinute)
          : Number(endMinute) - Number(startMinute);

      const sHour = Number(startHour);
      const eHour = Number(endMinute) - Number(startMinute) < 0 ? Number(endHour) - 1 : Number(endHour);
      const showHour = eHour - sHour < 0 ? eHour + 24 - sHour : eHour - sHour;

      return setPlace === 'HH'
        ? String(showHour).length === 1
          ? `0${String(showHour)}`
          : String(showHour)
        : String(showMinute).length === 1
        ? `0${String(showMinute)}`
        : String(showMinute);
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:Operating_Hours')} />
      <div className="content-area">
        <form>
          <SectionTitle>
            {CURRENT_USER_AUTHORITY_TYPE === AUTHORITY_TYPE.ADMIN && (
              <>
                {!isEdit && (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    onClick={onClick24h}
                    size={ButtonSizeEnum.SM}
                    className={`${is24h() && 'd-none'}`}
                  >
                    {t('text:Set_to_24H')}
                  </Button>
                )}
                {!isEdit && (
                  <Button size={ButtonSizeEnum.SM} onClick={onClickEdit} style={{ width: '60px' }}>
                    {t('text:Edit')}
                  </Button>
                )}
                {isEdit && (
                  <>
                    <Button
                      size={ButtonSizeEnum.SM}
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancel}
                    >
                      {t('text:Cancel')}
                    </Button>
                    <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
                      {t('text:Save')}
                    </Button>
                  </>
                )}
              </>
            )}
          </SectionTitle>
          <FormBorder>
            <FormContents>
              <div className="row">
                <div className="col-3">
                  <label className="information-form__label">{t('text:Begins_at')}</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      name="startHour"
                      placeholder={t('text:hh')}
                      className={`information-form__input spread-xs-input me-1 ${
                        errors.startHour ? 'red_rejected' : ''
                      }`}
                      disabled={!isEdit}
                      maxLength={2}
                      ref={operatingTimeFormRegister}
                      onChange={e => watchInputValidator(e, 'startHour')}
                    />
                    <div className="mt-2">:</div>
                    <input
                      type="text"
                      name="startMinute"
                      placeholder={t('text:mm')}
                      className={`information-form__input spread-xs-input ms-1 ${
                        errors.startMinute ? 'red_rejected' : ''
                      }`}
                      disabled={!isEdit}
                      maxLength={2}
                      ref={operatingTimeFormRegister}
                      onChange={e => watchInputValidator(e, 'startMinute')}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <label className="information-form__label">{t('text:Ends_at')}</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      name="endHour"
                      placeholder={t('text:hh')}
                      className={`information-form__input spread-xs-input me-1 ${errors.endHour ? 'red_rejected' : ''}`}
                      disabled={!isEdit}
                      maxLength={2}
                      ref={operatingTimeFormRegister}
                      onChange={e => watchInputValidator(e, 'endHour')}
                    />
                    <div className="mt-2">:</div>
                    <input
                      type="text"
                      name="endMinute"
                      placeholder={t('text:mm')}
                      className={`information-form__input spread-xs-input ms-1 ${
                        errors.endMinute ? 'red_rejected' : ''
                      }`}
                      disabled={!isEdit}
                      maxLength={2}
                      ref={operatingTimeFormRegister}
                      onChange={e => watchInputValidator(e, 'endMinute')}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <label className="information-form__label">{t('text:Operating_Hours')}</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      name="totalHour"
                      className="information-form__input spread-xs-input me-1"
                      value={operatingHourCalculator('HH')}
                      disabled={true}
                      maxLength={2}
                      ref={operatingTimeFormRegister}
                    />
                    <div className="mt-2">{t('text:H')}</div>
                    <input
                      type="text"
                      name="totalMinute"
                      className="information-form__input spread-xs-input ms-1"
                      value={operatingHourCalculator('MM')}
                      disabled={true}
                      maxLength={2}
                      ref={operatingTimeFormRegister}
                    />
                    <div className="mt-2">{t('text:M')}</div>
                  </div>
                </div>
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    </>
  );
}

export default FinancierManageOperationHour;
