import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorPartnerAccountDetailVOModel } from 'models/vo/AnchorPartnerAccountDetailVO';
import type { WaitingAnchorPartnerAccountVOModel } from 'models/vo/WaitingAnchorPartnerAccountVO';
import {
  requestFiAnchorPartnerAccountData,
  requestFiAnchorPartnerAccountDecline,
  requestFiAnchorPartnerAccountReinstate,
} from 'utils/http/api/financier/anchor-partner-accounts';
import { requestFinancierWaitingAnchorPartnerAccountList } from 'utils/http/api/financier/waiting-anchor-partner-accounts';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { getPaymentSupportText } from 'utils/text';

function FinancierPotentialSupplyChainPartnerAssignmentDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { anchorPartnerAccountId } = useParams() as any;
  const signInData: SignInModel | null = getSignIn();
  const isAdmin =
    signInData?.authorityType === AUTHORITY_TYPE.ADMIN || signInData?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR;

  const [anchorPartnerAccountDetailData, setAnchorPartnerAccountDetailData] =
    useState<AnchorPartnerAccountDetailVOModel>();
  const [waitingAnchorPartnerAccountPageData, setWaitingAnchorPartnerAccountPageData] =
    useState<Pageable<WaitingAnchorPartnerAccountVOModel[]>>();

  const { pageable: informationEditHistoryPageable, setPageable: setInformationEditHistoryPageable } = usePageable();

  const INFORMATION_EDIT_HISTORY_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Bank_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Branch_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Holder'),
      colWidths: 100,
    },
    {
      headerText: t('text:Declined_Status'),
      colWidths: 100,
    },
  ];

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    const [anchorPartnerAccountDetailData, waitingAnchorPartnerAccountPageData] = await Promise.all([
      requestFiAnchorPartnerAccountData(anchorPartnerAccountId),
      requestFinancierWaitingAnchorPartnerAccountList(1, 10, {
        targetAnchorPartnerAccountId: anchorPartnerAccountId,
        approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
      }),
    ]);

    ReactDOM.unstable_batchedUpdates(() => {
      setAnchorPartnerAccountDetailData(anchorPartnerAccountDetailData);
      setWaitingAnchorPartnerAccountPageData(waitingAnchorPartnerAccountPageData);
      setInformationEditHistoryPageable(waitingAnchorPartnerAccountPageData);
    });
  };

  const getMessage = (): string[] => {
    return isAdmin
      ? [
          t(
            'text:If_the_information_of_the_uploaded_supplier_or_bank_account_is_incorrect_please_go_to_the_company_or_bank_account_details_page_and_correct_the_information',
          ),
        ]
      : anchorPartnerAccountDetailData?.declined
      ? [t('text:Please_click_on_the_Reinstate_button_to_restart_the_assignment_process_for_this_supplier')]
      : [
          `${t('text:To_decline_the_assignment_click_on_the_Decline_Assignment_button_at_the_bottom_of_the_page')} ${t(
            'text:Declined_suppliers_can_be_reinstated',
          )}`,
          t('text:If_the_Suppliers_information_is_invalid_revise_the_information_on_the_Bank_Account_details_page'),
        ];
  };

  const getMessageType = (): MessageType => {
    return isAdmin
      ? MessageType.BASIC
      : anchorPartnerAccountDetailData?.declined
      ? MessageType.ALERT
      : MessageType.BASIC;
  };

  const onClickReinstate = (e: any) => {
    e.preventDefault();

    const onClickConfirm = async (): Promise<void> => {
      try {
        await requestFiAnchorPartnerAccountReinstate(anchorPartnerAccountId);

        modal.show(<h6>{t('text:The_supplier_has_been_reinstated')}</h6>, {
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(<h6>{t('text:Would_you_like_to_reinstate_supplier_assignment?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => onClickConfirm(),
    });
  };

  const onClickDeclineAssignment = (e: any) => {
    e.preventDefault();

    let reason: string = '';

    const onClickConfirm = async (): Promise<void> => {
      try {
        await requestFiAnchorPartnerAccountDecline(anchorPartnerAccountId, { declinedReason: reason });

        modal.show(<h6>{t('text:The_supplier_has_been_declined')}</h6>, {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <div className="modal-container">
        <h6>
          {t('text:Would_you_like_to_decline_the_supplier_assignment?')}
          <br />
          {t('text:Please_type_below_the_reason_for_the_decline')}
          <br />
          {t('text:Click_on_Confirm_to_decline_the_assignment')}
        </h6>
        <ReasonModal
          modalId={modal.id}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </div>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      },
    );
  };

  const renderSupplyChainPartnerInformationComponent = (): JSX.Element => {
    return (
      <div className="content-area">
        <SectionTitle title={t(`text:Uploaded_Supplier_Information`)} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t(`text:Uploaded_Supplier_Name`)} value={anchorPartnerAccountDetailData?.partnerName} />
              <FormValue label={t(`text:Tax_Code`)} value={anchorPartnerAccountDetailData?.partnerTaxCode} />
            </div>
            <div className="row">
              <FormValue
                label={t(`text:Company_Registration_Number`)}
                value={anchorPartnerAccountDetailData?.partnerBusinessCode}
              />
              <FormValue col={3} label={t(`text:Telephone`)} value={anchorPartnerAccountDetailData?.telephone} />
              <FormValue col={3} label={t(`text:Fax`)} value={anchorPartnerAccountDetailData?.fax} />
            </div>
            <div className="row">
              <FormValue
                label={t(`text:Legal_Representative_Name`)}
                value={anchorPartnerAccountDetailData?.representativeName}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Title`)}
                value={anchorPartnerAccountDetailData?.representativePosition}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Email`)}
                value={anchorPartnerAccountDetailData?.representativeEmail}
              />
            </div>
            <div className="row">
              <FormValue
                col={12}
                label={t(`text:Registered_Office_Address`)}
                value={anchorPartnerAccountDetailData?.address}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    );
  };

  const renderDesignatedBankAccountInformationforAPSettlementComponent = (): JSX.Element => {
    return (
      <div className="content-area">
        <SectionTitle title={t('text:Designated_Bank_Account_Information_for_AP_Settlement')}>
          <div className="flex-end align-items-center">
            <span className="gray-bold-font me-2">{t('text:Go_to_bank_account_details')}</span>
            <Link
              to={{
                pathname: ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_CONFIRMED_DETAIL_BUILD_PATH(
                  anchorPartnerAccountDetailData?.anchorPartnerAccountId!,
                ),
              }}
            >
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                className="flex-column-center"
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Link>
          </div>
        </SectionTitle>
        <FormBorder>
          <FormSubtitle
            title={t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT_INFORMATION')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={anchorPartnerAccountDetailData?.anchorName} />
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                value={anchorPartnerAccountDetailData?.anchorAgreementContractNo}
              />
            </div>
            <div className="row">
              <FormValue label={t('text:Currency')} value={anchorPartnerAccountDetailData?.currencyType} />
              <FormValue
                label={t('text:Automatic_Settlement')}
                value={getPaymentSupportText(anchorPartnerAccountDetailData?.paymentSupport)}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Responsible_Branch_Name')}
                value={anchorPartnerAccountDetailData?.responsibleBranchName}
              />
            </div>
          </FormContents>
          <FormSubtitle
            title={t('text:DESIGNATED_BANK_ACCOUNT_INFORMATION')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Bank_Code')} value={anchorPartnerAccountDetailData?.accountBankCode} />
              <FormValue label={t('text:Bank_Name')} value={anchorPartnerAccountDetailData?.accountBankName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Branch_Code')} value={anchorPartnerAccountDetailData?.accountBranchCode} />
              <FormValue label={t('text:Branch_Name')} value={anchorPartnerAccountDetailData?.accountBranchName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Bank_Account_Number')} value={anchorPartnerAccountDetailData?.account} />
              <FormValue label={t('text:Bank_Account_Holder')} value={anchorPartnerAccountDetailData?.accountOwner} />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Assigned_Branch_Code')}
                value={anchorPartnerAccountDetailData?.assignedBranchCode}
              />
              <FormValue
                label={t('text:Assigned_Branch_Name')}
                value={anchorPartnerAccountDetailData?.assignedBranchName}
              />
            </div>
          </FormContents>
        </FormBorder>
        {!isAdmin && anchorPartnerAccountDetailData?.declined === false && (
          <div className="flex-end mt-3">
            <Button onClick={onClickDeclineAssignment} color={ButtonColorEnum.RED} size={ButtonSizeEnum.LG}>
              {t('text:Decline_Assignment')}
            </Button>
          </div>
        )}
      </div>
    );
  };

  const renderInformationEditHistoryComponent = (): JSX.Element => {
    const paginate = async (selectedPageNumber: number, selectedRowCount: number): Promise<void> => {
      try {
        const waitingAnchorPartnerAccountPageData = await requestFinancierWaitingAnchorPartnerAccountList(
          selectedPageNumber,
          selectedRowCount,
          {
            targetAnchorPartnerAccountId: anchorPartnerAccountId,
            approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
          },
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingAnchorPartnerAccountPageData(waitingAnchorPartnerAccountPageData);
          setInformationEditHistoryPageable(waitingAnchorPartnerAccountPageData);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const renderResultTable = (): JSX.Element[] | undefined => {
      return waitingAnchorPartnerAccountPageData?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.updatedDateTime} format="datetime" />
          <Td data={item.anchorName} />
          <Td data={item.anchorAgreementContractNo} />
          <Td data={item.accountBankCode} />
          <Td data={item.requestedAccountBankName} />
          <Td data={item.requestedAccountBranchName} />
          <Td data={item.account} />
          <Td data={item.accountOwner} />
          <Td data={item.declined ? 'Y' : 'N'} />
        </Tr>
      ));
    };

    return (
      <div className="content-area">
        <SectionTitle title={t(`text:Information_Edit_History`)} />
        <TableBorder>
          <TableHeader header={INFORMATION_EDIT_HISTORY_HEADERS} />
          <TableBody numOfCol={11}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={informationEditHistoryPageable} paginate={paginate} />
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={anchorPartnerAccountDetailData?.partnerName} />
      {!isAdmin && (
        <GuideMessage
          message={getMessage()}
          messageType={getMessageType()}
          reasonTitle={!isAdmin && anchorPartnerAccountDetailData?.declined ? t('text:REASON_OF_DECLINE') : undefined}
          reason={
            !isAdmin && anchorPartnerAccountDetailData?.declined
              ? anchorPartnerAccountDetailData.declinedReason
              : undefined
          }
        >
          {{
            button: isAdmin ? undefined : anchorPartnerAccountDetailData?.declined ? (
              <Button onClick={onClickReinstate}>{t('text:Reinstate')}</Button>
            ) : undefined,
          }}
        </GuideMessage>
      )}
      {renderSupplyChainPartnerInformationComponent()}
      {renderDesignatedBankAccountInformationforAPSettlementComponent()}
      {renderInformationEditHistoryComponent()}
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerAssignmentDetail;
