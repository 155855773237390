import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { isNil } from 'lodash-es';

import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

type FinancierSettingStateType = {
  potentialPartnerRegistrable?: boolean;
  divisionRegistrable?: boolean;
  financierName?: string;
  factoringEnable?: boolean;
  fetchFinancierSetting: () => Promise<void>;
};

const FinancierSettingContext = createContext<FinancierSettingStateType | null>(null);

export const useFinancierSettingContext = () => {
  const context = useContext(FinancierSettingContext);
  if (!context) {
    throw Error('useFinancierSettingContext should be used within FinancierSettingContext.Provider');
  }

  return context;
};

type FinancierSettingProviderPropsType = {
  children: ReactNode;
};

const FinancierSettingProvider = ({ children }: FinancierSettingProviderPropsType) => {
  const [potentialPartnerRegistrable, setPotentialPartnerRegistrable] = useState(false);
  const [divisionRegistrable, setDivisionRegistrable] = useState(false);
  const [financierName, setFinancierName] = useState<string>('');
  const [factoringEnable, setFactoringEnable] = useState(false);

  const { show: showModal } = useModal();

  const fetchFinancierSetting = useCallback(async () => {
    const enterpriseId = getSignIn()?.enterpriseId;

    if (!isNil(enterpriseId)) {
      try {
        const { potentialPartnerRegistrable, divisionRegistrable, financierName, factoringEnable } =
          await requestFinancierSettingData(enterpriseId);
        setPotentialPartnerRegistrable(potentialPartnerRegistrable);
        setDivisionRegistrable(divisionRegistrable);
        setFinancierName(financierName);
        setFactoringEnable(factoringEnable);
      } catch (error) {
        showModal(error);
      }
    }
  }, [showModal]);

  const value = useMemo(
    () => ({
      potentialPartnerRegistrable,
      divisionRegistrable,
      fetchFinancierSetting,
      financierName,
      factoringEnable,
    }),
    [fetchFinancierSetting, divisionRegistrable, financierName, potentialPartnerRegistrable, factoringEnable],
  );

  return <FinancierSettingContext.Provider value={value}>{children}</FinancierSettingContext.Provider>;
};

export default FinancierSettingProvider;
