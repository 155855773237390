import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { FINANCIAL_SETTING_TYPE } from 'enums';

import useFinancierDelinquentInterestRateSettingDetail from './useFinancierDelinquentInterestRateSettingDetail';
import DelinquentInterestRateInformationEditHistory from '../sections/delinquent-interest-rate-Infomation-edit-history';
import CostOfFundForm from '../sections/delinquent-interest-rate-cost-of-fund';
import DelinquentInterestRateTermSpreadForm from '../sections/delinquent-interest-rate-term-spread-form';

function FinancierDelinquentInterestRateSettingDetail() {
  const { t } = useTranslation(['format']);

  const {
    delinquentInterestRateSettingDetail,
    delinquentInterestRateSettingHistories,
    detailHistories,
    setDetailHistories,
    isAdmin,
    termSpreadEditable,
    costOfFundEditable,
    termSpreadFormMethods,
    costOfFundFormMethods,
    pageable,
    paginate,
    CostOfFundEditButtons,
    TermSpreadEditButtons,
    ActiveButton,
    DeActiveButton,
  } = useFinancierDelinquentInterestRateSettingDetail();

  // useForm

  const {
    activated,
    settingType,
    anchorAgreementContractNo,
    dealerAgreementContractNo,
    currencyType,
    anchorClientName,
    dealerClientName,
    anchorClientCode,
    dealerClientCode,
  } = delinquentInterestRateSettingDetail || {};

  const isActive = activated ?? false;
  const isAnchorType = delinquentInterestRateSettingDetail?.settingType === FINANCIAL_SETTING_TYPE.ANCHOR;
  const isPartnerType = delinquentInterestRateSettingDetail?.settingType === FINANCIAL_SETTING_TYPE.PARTNER;

  return (
    <>
      <BackHeaderTitle title={t('text:Delinquent_Interest_Rate_Details')} />
      <div className="content-area">
        <SectionTitle title={t('text:Delinquent_Interest_Rate_Type')}>
          {isAdmin && !isActive && <ActiveButton />}
          {isAdmin && isActive && !termSpreadEditable && !costOfFundEditable && <DeActiveButton />}
        </SectionTitle>
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Type')}
                value={t(`code:financier-setting-type.${settingType}`)}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Master_Agreement_Number')}
                value={isAnchorType ? anchorAgreementContractNo : dealerAgreementContractNo}
              />
            </div>
            <div className="row">
              <FormValue className="information-form__input" label={t('text:Currency')} value={currencyType} />
              <FormValue
                className="information-form__input"
                label={t('text:Company_Name')}
                value={isAnchorType ? anchorClientName : dealerClientName}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Company_Client_Code')}
                value={isAnchorType ? anchorClientCode : dealerClientCode}
              />
              <FormValue
                className={clsx('information-form__input', {
                  'text-bold-blue-main600': isActive,
                  'text-bold-brick-red': !isActive,
                })}
                label={t('text:Activate_Status')}
                value={isActive ? 'On' : 'Off'}
              />
            </div>
            {isPartnerType && (
              <div className="row">
                <FormValue
                  className="information-form__input"
                  label={t('text:Associated_Anchor_Name')}
                  value={anchorClientName}
                />
              </div>
            )}
          </FormContents>
        </FormBorder>
      </div>
      <FormProvider {...termSpreadFormMethods}>
        <div className="content-area">
          <SectionTitle title={t('text:Delinquent_Interest_Rate_Term_Spread')}>
            {isAdmin && isActive && <TermSpreadEditButtons />}
          </SectionTitle>
          <DelinquentInterestRateTermSpreadForm editable={termSpreadEditable} />
        </div>
      </FormProvider>
      <FormProvider {...costOfFundFormMethods}>
        <div className="content-area">
          <SectionTitle title={t('text:Penalty_Fee')}>{isAdmin && isActive && <CostOfFundEditButtons />}</SectionTitle>
          <CostOfFundForm isEditable={costOfFundEditable} />
        </div>
      </FormProvider>
      <div className="content-area">
        <DelinquentInterestRateInformationEditHistory
          detailHistories={detailHistories}
          setDetailHistories={setDetailHistories}
          editHistoryList={delinquentInterestRateSettingHistories}
          pageable={pageable}
          paginate={paginate}
        />
      </div>
    </>
  );
}

export default FinancierDelinquentInterestRateSettingDetail;
