export const formattingENIndiaLanguage = (value: string) => {
  let formatValue: string = '';

  // 1,12,23,123 -> 처음에만 세자리 그 이후 두 자리씩 콤마 표시
  // 단위 표시 전에 '-' 제거
  const isMinus = value[0] === '-';
  if (isMinus) value = value.replace('-', '');
  formatValue = isMinus ? '-' : '';

  const dotIdx = value.indexOf('.');

  const onlyIntValue = dotIdx === -1 ? value : value.slice(0, dotIdx); // 실수인 경우 정수만 가져옴
  const onlyDecimalPointValue = dotIdx === -1 ? '' : value.slice(dotIdx); // (소수점 포함) 소수점 이하 값

  // 세자리 콤마 표시 + 소수점 이하 value
  const threeDigitsValue =
    onlyIntValue?.length - 3 > 0
      ? ',' + onlyIntValue.slice(onlyIntValue?.length - 3) + onlyDecimalPointValue
      : onlyIntValue + onlyDecimalPointValue;

  if (onlyIntValue?.length - 3 > 0) {
    // threeDigitsValue 제외한 나머지 string 가져옴
    let twoDigitsValue = onlyIntValue.slice(0, onlyIntValue?.length - 3);

    for (let i = twoDigitsValue?.length - 2; i > 0; i -= 2) {
      twoDigitsValue = twoDigitsValue.slice(0, i) + ',' + twoDigitsValue.slice(i);
    }

    return formatValue + twoDigitsValue + threeDigitsValue;
  } else return formatValue + threeDigitsValue;
};
