import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import { TableBody, TableBorder, TableHeader, Td, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { PARTNER_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestWaitingAnchorPartnerPage } from 'utils/http/api/anchor/waiting-anchor-partners';
import type { WaitingAnchorPartnerPageRequest } from 'utils/http/api/anchor/waiting-anchor-partners/requests';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

type FailedViewProps = {
  failedViewStates: {
    failedAnchorPartnerPage?: Pageable<WaitingAnchorPartnerVOModel[]>;
    failedAnchorPartnerPageable: PageableType;
  };
  failedViewUtils: {
    onClickFailedViewSearch: (e: any) => Promise<void>;
    failedPaginate: (page: number, sizePerPage: number) => Promise<void>;
  };
};

const FailedView = ({ failedViewStates, failedViewUtils }: FailedViewProps) => {
  const { t } = useTranslation();
  const { reset, register, control, getValues } = useFormContext();
  const modal = useModal();
  const getWaitingAnchorPartnerProperty = useProperty<WaitingAnchorPartnerPageRequest>();

  const { failedAnchorPartnerPage, failedAnchorPartnerPageable } = failedViewStates;

  const { onClickFailedViewSearch, failedPaginate } = failedViewUtils;

  const REGISTRATION_FAILED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Responsible_Financier_Name'),
    },
    {
      headerText: t('text:Supplier_Name'),
    },
    {
      headerText: t('text:Supplier_Tax_Code'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Bank_Code'),
    },
    {
      headerText: t('text:Bank_Name'),
    },
    {
      headerText: t('text:Branch_Name'),
    },
    {
      headerText: t('text:Bank_Account_Number'),
    },
    {
      headerText: t('text:Bank_Account_Holder'),
    },
    {
      headerText: t('text:Reason_for_Failure'),
    },
    {
      headerText: t('text:Registration_Date'),
    },
  ];

  const renderRegistrationFailedSearchTable = (): JSX.Element => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();

      reset();
    };

    return (
      <form>
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <SearchBorder>
          <div className="row">
            <SearchLabel label={t('text:Responsible_Financier_Name')} />
            <SearchInput name={getWaitingAnchorPartnerProperty('responsibleFinancierName')} ref={register} />
            <SearchLabel label={t('text:Supplier_Name')} />
            <SearchInput name={getWaitingAnchorPartnerProperty('partnerName')} ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Supplier_Tax_Code')} />
            <SearchInput name={getWaitingAnchorPartnerProperty('partnerTaxCode')} ref={register} />
            <SearchLabel label={t('text:Reason_for_Failure')} />
            <SearchSelect
              selectOptions={[
                {
                  label: t('text:All'),
                  value: Object.keys(PARTNER_STATUS)
                    .filter(key => key !== 'REGISTERED')
                    .map(key => PARTNER_STATUS[key as keyof typeof PARTNER_STATUS]),
                },
                { label: t('code:partner-status.DUPLICATE_TAX_CODE'), value: PARTNER_STATUS.DUPLICATE_TAX_CODE },
                {
                  label: t('code:partner-status.ALREADY_REGISTERED_ACCOUNT'),
                  value: PARTNER_STATUS.ALREADY_REGISTERED_ACCOUNT,
                },
                { label: t('code:partner-status.INVALID_BANK_CODE'), value: PARTNER_STATUS.INVALID_BANK_CODE },
                {
                  label: t('code:partner-status.ON_APPROVAL_PROGRESS_ANCHOR_PARTNER'),
                  value: PARTNER_STATUS.ON_APPROVAL_PROGRESS_ANCHOR_PARTNER,
                },
                {
                  label: t('code:partner-status.INVALID_APPROVE_REQUEST'),
                  value: PARTNER_STATUS.INVALID_APPROVE_REQUEST,
                },
                { label: t('code:partner-status.FAILED'), value: PARTNER_STATUS.FAILED },
                {
                  label: t('code:partner-status.ANCHOR_PARTNER_INTERFACE_REQUEST_FAILED'),
                  value: PARTNER_STATUS.ANCHOR_PARTNER_INTERFACE_REQUEST_FAILED,
                },
              ]}
              name={getWaitingAnchorPartnerProperty('anchorPartnerStatuses')}
              ref={register}
            />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Registration_Date')} />
            <SearchDatePicker
              placeholder={t('text:from')}
              name={getWaitingAnchorPartnerProperty('createdDateFrom')}
              control={control}
            />
            <SearchDatePicker
              placeholder={t('text:to')}
              name={getWaitingAnchorPartnerProperty('createdDateTo')}
              control={control}
            />
            <SearchEmpty />
          </div>
        </SearchBorder>
        <div className="flex-center mt-3">
          <Button size={ButtonSizeEnum.LG} onClick={onClickFailedViewSearch}>
            {t('text:Search')}
          </Button>
        </div>
      </form>
    );
  };

  const renderRegistrationFailedTable = (): JSX.Element => {
    const onClickFailedViewExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const WAITING_VIEW_EXCEL_COLUMNS: ColumnOption<WaitingAnchorPartnerVOModel>[] = [
        {
          header: t('text:Responsible_Financier_Name'),
          key: 'responsibleFinancierName',
        },
        {
          header: t('text:Supplier_Name'),
          key: 'name',
        },
        {
          header: t('text:Supplier_Tax_Code'),
          key: 'partnerTaxCode',
        },
        {
          header: t('text:Legal_Representative_Name'),
          key: 'representativeName',
        },
        {
          header: t('text:Legal_Representative_Email'),
          key: 'representativeEmail',
        },
        {
          header: t('text:Telephone'),
          key: 'telephone',
        },
        {
          header: t('text:Registered_Office_Address'),
          key: 'address',
        },
        {
          header: t('text:Bank_Code'),
          key: 'accountBankCode',
        },
        {
          header: t('text:Bank_Name'),
          key: 'accountBankName',
        },
        {
          header: t('text:Branch_Name'),
          key: 'accountBranchName',
        },
        {
          header: t('text:Bank_Account_Number'),
          key: 'account',
        },
        {
          header: t('text:Bank_Account_Holder'),
          key: 'accountOwner',
        },
        {
          header: t('text:Reason_for_Failure'),
          key: 'anchorPartnerStatus',
        },
        {
          header: t('text:Registration_Date'),
          key: 'createdDateTime',
        },
      ];

      const renderPDFTableBodyResult = (data?: WaitingAnchorPartnerVOModel[]) => {
        return data?.map((item, index) => (
          <tr key={index} className="virtual-table-row">
            <Td data={item.responsibleFinancierName} />
            <Td data={item.name} />
            <Td data={item.partnerTaxCode} />
            <Td data={item.representativeName} />
            <Td data={item.representativeEmail} />
            <Td data={item.telephone} />
            <Td data={item.address} />
            <Td data={item.accountBankCode} />
            <Td data={item.accountBankName} />
            <Td data={item.accountBranchName} />
            <Td data={item.account} />
            <Td data={item.accountOwner} />
            <Td data={t(`code:partner-status.${item.anchorPartnerStatus}`)} />
            <Td data={item.createdDateTime} format="datetime" />
          </tr>
        ));
      };

      const failedSearchFormValues = getValues();

      const failedAnchorPartnerSearchFormValue = { ...failedSearchFormValues, hasTargetAnchorPartner: false };

      try {
        const failedAnchorPartnerExcelPage = await requestWaitingAnchorPartnerPage(
          0,
          EXCEL_EXPORT_MAX_ROW_COUNT,
          failedAnchorPartnerSearchFormValue,
        );
        const failedAnchorPartnerPDFPage = failedAnchorPartnerExcelPage.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

        const jsonArrayData = failedAnchorPartnerExcelPage.content.map(failedAnchorPartnerData => ({
          responsibleFinancierName: tableValueManage(failedAnchorPartnerData.responsibleFinancierName),
          name: tableValueManage(failedAnchorPartnerData.name),
          partnerTaxCode: tableValueManage(failedAnchorPartnerData.partnerTaxCode),
          representativeName: tableValueManage(failedAnchorPartnerData.representativeName),
          representativeEmail: tableValueManage(failedAnchorPartnerData.representativeEmail),
          telephone: tableValueManage(failedAnchorPartnerData.telephone),
          address: tableValueManage(failedAnchorPartnerData.address),
          accountBankCode: tableValueManage(failedAnchorPartnerData.accountBankCode),
          accountBankName: tableValueManage(failedAnchorPartnerData.accountBankName),
          accountBranchName: tableValueManage(failedAnchorPartnerData.accountBranchName),
          account: tableValueManage(failedAnchorPartnerData.account),
          accountOwner: tableValueManage(failedAnchorPartnerData.accountOwner),
          anchorPartnerStatus: tableValueManage(
            failedAnchorPartnerData.anchorPartnerStatus,
            t(`code:partner-status.${failedAnchorPartnerData.anchorPartnerStatus}`),
          ),
          createdDateTime: tableValueManage(
            failedAnchorPartnerData.createdDateTime,
            t('format:datetime', {
              value: failedAnchorPartnerData.createdDateTime,
              key: 'datetime',
            }),
          ),
        }));

        const excelExporterProps: ExportSpreadSheetProps<WaitingAnchorPartnerVOModel> = {
          jsonArrayData: jsonArrayData,
          columns: WAITING_VIEW_EXCEL_COLUMNS,
          options: {
            rowHeight: [{ position: 1, height: 30 }],
          },
        };

        const pdfExporterProps: PDFExporterProps = {
          tableHeaders: REGISTRATION_FAILED_TABLE_HEADERS,
          tableBody: renderPDFTableBodyResult(failedAnchorPartnerPDFPage),
        };

        modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
          title: t('text:Export_File'),
          closeBtnText: t('text:Close'),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const renderResultTable = (): JSX.Element[] | undefined => {
      return failedAnchorPartnerPage?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.responsibleFinancierName} />
          <Td data={item.name} />
          <Td data={item.partnerTaxCode} />
          <Td data={item.representativeName} />
          <Td data={item.representativeEmail} />
          <Td data={item.telephone} />
          <Td data={item.address} />
          <Td data={item.accountBankCode} />
          <Td data={item.accountBankName} />
          <Td data={item.accountBranchName} />
          <Td data={item.account} />
          <Td data={item.accountOwner} />
          <Td data={t(`code:partner-status.${item.anchorPartnerStatus}`)} />
          <Td data={item.createdDateTime} format="datetime" />
        </Tr>
      ));
    };

    return (
      <>
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {failedAnchorPartnerPage?.totalElements}
          </p>
          <Button onClick={onClickFailedViewExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={REGISTRATION_FAILED_TABLE_HEADERS} />
          <TableBody numOfCol={REGISTRATION_FAILED_TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={failedAnchorPartnerPageable} paginate={failedPaginate} />
      </>
    );
  };

  return (
    <>
      <div className="content-area">{renderRegistrationFailedSearchTable()}</div>
      <div className="division-border" />
      <div className="content-area">{renderRegistrationFailedTable()}</div>
    </>
  );
};

export default FailedView;
