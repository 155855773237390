import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ChoiceChip from 'components/stateless/Chips/ChoiceChip';
import { FormBorder, FormContents } from 'components/stateless/CommonForm';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { CURRENCY_TYPE } from 'enums';
import type { CreateFinancierAndAdminUserDTO } from 'utils/http/api/anonymous/on-demand/requests';

import { useCreateFinancierStep1Actions, useCreateFinancierStep1Value } from '../CreateFinancierStep1Provider';

type CurrencySettingsFormType = Pick<CreateFinancierAndAdminUserDTO, 'supportedCurrencyTypes'>;

const CurrencySettings = () => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext<CurrencySettingsFormType>();

  const { supportedCurrencyList } = useCreateFinancierStep1Value();
  const { updateSupportedCurrencyList } = useCreateFinancierStep1Actions();

  const addCurrencyType = (currencyType: CURRENCY_TYPE) => {
    updateSupportedCurrencyList(prevList => [...prevList, currencyType]);
  };

  const removeCurrencyType = (currencyType: CURRENCY_TYPE) => {
    updateSupportedCurrencyList(prevList => prevList.filter(currencyItem => currencyItem !== currencyType));
  };

  return (
    <>
      <SectionTitle title={t('text:Currency_Settings')} />
      <FormBorder editable>
        <FormContents>
          <input type="hidden" name="supportedCurrencyTypes" ref={register} />
          <div className="d-flex align-items-center flex-wrap">
            {Object.values(CURRENCY_TYPE).map((currencyType: CURRENCY_TYPE, index: number) => {
              if (currencyType === '') return null;

              return (
                <ChoiceChip
                  key={index}
                  label={currencyType}
                  onSelect={() => addCurrencyType(currencyType)}
                  onUnSelect={() => removeCurrencyType(currencyType)}
                  icon={{ leftIcon: <FontAwesomeIcon icon={faCheck} /> }}
                  defaultSelected={supportedCurrencyList.includes(currencyType)}
                />
              );
            })}
            {errors.supportedCurrencyTypes && <FontAwesomeIcon icon={faCheckCircle} className="check-icon ms-2" />}
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
};

export default CurrencySettings;
