import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import useMounted from 'hooks/useMounted';
import type { TermsOfUseVOModel } from 'models/vo/TermsOfUseVO';
import { requestSystemTOUList } from 'utils/http/api/system/terms-of-uses';
import useModal from 'utils/modal/useModal';

import TdLink from '../../../../../components/stateless/Table/TdLink';

function SystemTouListPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const mounted = useMounted();
  const modal = useModal();
  const [touList, setTouList] = useState<TermsOfUseVOModel[]>();

  useEffect(() => {
    if (mounted) {
      fetchSyTOUList();
    }

    return () => {};
  }, [mounted]);

  const fetchSyTOUList = async (): Promise<void> => {
    try {
      const touListResponse = await requestSystemTOUList(0, 1000);
      setTouList(touListResponse.content);
    } catch (error) {
      modal.show(error);
    }
  };

  const TOU_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Financier_ID'),
    },
    {
      headerText: t('text:Activated_TOU'),
    },
    {
      headerText: '',
    },
  ];

  return (
    <>
      <HeaderTitle title={t('text:Manage_TOU')} />
      <GuideMessage message={[t('text:Register_to_use_specific_TOU_for_each_Financier')]}>
        {{
          button: (
            <Button size={ButtonSizeEnum.SM} onClick={() => history.push(ROUTES_SY.MANAGEMENT.TOU_REGISTER)}>
              {t('text:Register_TOU')}
            </Button>
          ),
        }}
      </GuideMessage>
      <div className="content-area">
        <SectionTitle title={t('text:Registered_TOU')} />
        <TableBorder>
          <TableHeader header={TOU_TABLE_HEADERS} />
          <TableBody numOfCol={TOU_TABLE_HEADERS.length}>
            {touList?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.enterpriseName} />
                <Td data={item.enterpriseId} />
                <Td data={item.activatedTouFileName} />
                <TdLink path={ROUTES_SY.MANAGEMENT.TOU_DETAIL_BUILD_PATH(item.termsOfUseId)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
      </div>
    </>
  );
}

export default SystemTouListPage;
