import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { SignInModel } from 'models/SignInModel';
import type { MultipleAnchorLoanRequestDetailVOModel } from 'models/vo/MultipleAnchorLoanRequestDetailVO';
import type { MultipleAnchorLoanRequestRelatedAnchorLoanVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedAnchorLoanVO';
import type { MultipleAnchorLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessArVO';
import type { MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel } from 'models/vo/MultipleAnchorLoanRequestRelatedSuccessInvoiceVO';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestAnchorMultipleLoanDetail,
  requestAnchorMultipleLoanListRelatedArs,
  requestAnchorMultipleLoanListRelatedInvoices,
  requestAnchorMultipleLoanListRelatedLoan,
  requestAnchorMultipleRequestLoanRequestApprove,
  requestAnchorMultipleRequestLoanRequestCancel,
} from 'utils/http/api/anchor/multiple-request-anchor-loans';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';
import { getSignIn } from 'utils/storage/LocalStorage';

const useAnchorBulkFinancingConfirmationDetailController = () => {
  const mounted = useMounted();
  const modal = useModal();
  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation();
  const { multipleRequestAnchorLoanId } = useParams<any>();

  const [dataState, setDataState] = useState({
    bulkFinancingDetail: {} as MultipleAnchorLoanRequestDetailVOModel,
    bulkFinancingArList: [] as MultipleAnchorLoanRequestRelatedSuccessArVOModel[],
    bulkFinancingInvoiceList: [] as MultipleAnchorLoanRequestRelatedSuccessInvoiceVOModel[],
    bulkFinancingRelatedFinancingList: [] as MultipleAnchorLoanRequestRelatedAnchorLoanVOModel[],
    showDisbursementAccountInfo: false,
  });

  const [financingInfoCheck, setFinancingInfoCheck] = useState<boolean>(false);
  const [bankAccountInfoCheck, setBankAccountInfoCheck] = useState<boolean>(false);

  const { pageable: relatedFinancingPageable, setPageable: setRelatedFinancingPageablePageable } = usePageable();

  const currentUserAuthorityType = signInModel?.authorityType;
  const { createdAnchorUserAuthorityType, createdAnchorUserId, multipleLoanRequestStatus } =
    dataState.bulkFinancingDetail;
  const isAr = dataState.bulkFinancingDetail.collateralType === COLLATERAL_TYPE.AR;

  const isVFSRequestUser =
    currentUserAuthorityType === AUTHORITY_TYPE.HQ_OPERATOR && signInModel?.userId === createdAnchorUserId;
  const isVFSApproveUser = currentUserAuthorityType === AUTHORITY_TYPE.ADMIN;

  const isDFSOperatorUser =
    (createdAnchorUserAuthorityType === AUTHORITY_TYPE.OPERATOR &&
      currentUserAuthorityType === AUTHORITY_TYPE.OPERATOR) ||
    (createdAnchorUserAuthorityType === AUTHORITY_TYPE.AUTHORIZER &&
      currentUserAuthorityType === AUTHORITY_TYPE.AUTHORIZER);

  const isDFSApproveUser =
    currentUserAuthorityType === AUTHORITY_TYPE.ADMIN ||
    (createdAnchorUserAuthorityType === AUTHORITY_TYPE.OPERATOR &&
      currentUserAuthorityType === AUTHORITY_TYPE.AUTHORIZER);

  const isAuthorizerRequest =
    multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.CREATED && (isAr ? isVFSApproveUser : isDFSApproveUser);

  const isOperatorRequest =
    multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.CREATED && (isAr ? isVFSRequestUser : isDFSOperatorUser);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [fetchedBulkFinancingDetail, fetchedBulkFinancingRelatedLoanList] = await Promise.all([
        requestAnchorMultipleLoanDetail(multipleRequestAnchorLoanId),
        requestAnchorMultipleLoanListRelatedLoan(multipleRequestAnchorLoanId, 0, 10),
      ]);

      setDataState(prevState => ({
        ...prevState,
        bulkFinancingDetail: fetchedBulkFinancingDetail,
        bulkFinancingRelatedFinancingList: fetchedBulkFinancingRelatedLoanList.content,
        showDisbursementAccountInfo: !isNil(fetchedBulkFinancingDetail.disbursementAccount),
      }));
      setRelatedFinancingPageablePageable(fetchedBulkFinancingRelatedLoanList);

      if (fetchedBulkFinancingDetail.collateralType === COLLATERAL_TYPE.AR) {
        const fetchedBulkFinancingArList = await requestAnchorMultipleLoanListRelatedArs(multipleRequestAnchorLoanId);
        setDataState(prevState => ({
          ...prevState,
          bulkFinancingArList: fetchedBulkFinancingArList.content,
        }));
      } else if (fetchedBulkFinancingDetail.collateralType === COLLATERAL_TYPE.INVOICE) {
        const fetchedBulkFinancingInvoiceList = await requestAnchorMultipleLoanListRelatedInvoices(
          multipleRequestAnchorLoanId,
        );
        setDataState(prevState => ({
          ...prevState,
          bulkFinancingInvoiceList: fetchedBulkFinancingInvoiceList.content,
        }));
      }
    } catch (e) {
      modal.show(e);
    }
  };

  const paginateRelatedFinancingList = async (pageNumber: number, rowCount: number) => {
    try {
      const fetchedBulkFinancingRelatedLoanList = await requestAnchorMultipleLoanListRelatedLoan(
        multipleRequestAnchorLoanId,
        pageNumber,
        rowCount,
      );
      setDataState(prevState => ({
        ...prevState,
        bulkFinancingRelatedFinancingList: fetchedBulkFinancingRelatedLoanList.content,
      }));
      setRelatedFinancingPageablePageable(fetchedBulkFinancingRelatedLoanList);
    } catch (e) {
      modal.show(e);
    }
  };

  const submitBulkFinancingCancel = async (cancelReason: string) => {
    const showLoanCancelConfirmModal = () => {
      modal.show(<h6>{t('text:Financing_approval_request_is_successfully_cancelled')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      });
    };

    try {
      await requestAnchorMultipleRequestLoanRequestCancel(multipleRequestAnchorLoanId, cancelReason);
      showLoanCancelConfirmModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const handleCancelButtonClick = () => {
    let reason = '';

    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_financing_application?')}
        <br />
        {t(
          'text:If_you_cancel_the_financing_application_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
        )}
        <ReasonModal
          modalId={modal.id}
          required
          getReturnReason={(cancelReason: string) => {
            reason = cancelReason;
          }}
        />
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Cancel_Financing_Request'),
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          await submitBulkFinancingCancel(reason);
        },
      },
    );
  };

  const showAuthorizerApproveConfirmModal = () => {
    modal.show(
      <h6>
        {t('text:The_financing_confirmation_has_been_completed')}
        <br />
        {t(
          'text:You_can_monitor_the_details_and_progress_of_the_financing_applied_from_the_View_Transaction_Financing_menu',
        )}
      </h6>,
      {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => {
          await fetchAll();
          scrollToTopOfElement();
        },
      },
    );
  };

  const handleSubmitFinancingApplicationButtonClick = async () => {
    if (!financingInfoCheck || (dataState.showDisbursementAccountInfo && !bankAccountInfoCheck)) {
      return modal.show(<h6>{t('text:Please_check_all_the_check_boxes')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
      });
    }

    const { financierId, anchorAgreementId } = dataState.bulkFinancingDetail;

    const clientAuthTypeData = await requestAnchorClientAuthByFinancierId(financierId);

    if (clientAuthTypeData.otpType !== OTP_TYPE.NONE) {
      const verificationCode: UserVerificationCodeRequest = {};

      modal.show(
        <UserVerificationModal
          modalId={modal.id}
          verificationCode={verificationCode}
          requestIdType="financierId"
          requestId={financierId}
          clientAuthSetting={clientAuthTypeData}
        />,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: async () => {
            try {
              await requestAnchorMultipleRequestLoanRequestApprove(multipleRequestAnchorLoanId, anchorAgreementId, {
                otpCode: verificationCode.otpCode,
                queryValue: verificationCode.queryValue,
              });
              showAuthorizerApproveConfirmModal();
            } catch (e) {
              modal.show(e);
            }
          },
        },
      );
    } else {
      try {
        await requestAnchorMultipleRequestLoanRequestApprove(multipleRequestAnchorLoanId, anchorAgreementId);
        showAuthorizerApproveConfirmModal();
      } catch (e) {
        modal.show(e);
      }
    }
  };

  return {
    state: dataState,
    paginateRelatedFinancingList,
    isAuthorizerRequest,
    isOperatorRequest,
    relatedFinancingPageable,
    financingInfoCheckBoxState: {
      financingInfoCheck,
      setFinancingInfoCheck,
    },
    bankAccountInfoCheckBoxState: {
      bankAccountInfoCheck,
      setBankAccountInfoCheck,
    },
    handleCancelButtonClick,
    handleSubmitFinancingApplicationButtonClick,
  };
};

export default useAnchorBulkFinancingConfirmationDetailController;
