import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';

import FinancierBulkAnchorFinancingConfirmationListTable from './components/FinancierBulkAnchorFinancingConfirmationListTable';
import { useFinancierBulkAnchorFinancingConfirmationListController } from './useFinancierBulkAnchorFinancingConfirmationListController';

function FinancierBulkAnchorFinancingConfirmationList() {
  const { t } = useTranslation(['format']);

  const {
    waitingTabUtils: { bulkFinancingWaitingList, bulkFinancingWaitingPageable, multipleRequestLoanWaitingListPaginate },
    completedTabUtils: {
      bulkFinancingCompletedList,
      bulkFinancingCompletedPageable,
      multipleRequestLoanCompletedListPaginate,
    },
  } = useFinancierBulkAnchorFinancingConfirmationListController();

  return (
    <>
      <HeaderTitle title={t('text:Financing_Approval')} />
      <GuideMessage
        message={[
          `${t('text:The_In_Progress_tab_shows_bulk_financing_applications_that_have_not_yet_been_approved')} ${t(
            'text:Review_and_approve_the_bulk_financing_applied_for_by_the_Operator_in_the_detail_page',
          )}`,
          `${t('text:The_Completed_tab_shows_bulk_financing_applications_that_have_either_been_approved_or_rejected')}
          ${t('text:Check_the_details_on_the_detail_page')}`,
          t('text:After_bulk_approval_the_process_can_be_continued_on_the_each_financing_details_page'),
        ]}
      />
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:In_Progress'),
            tabItemCount: bulkFinancingWaitingList.totalElements ?? 0,
          },
          {
            tabName: t('text:Completed'),
            tabItemCount: bulkFinancingCompletedList.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FinancierBulkAnchorFinancingConfirmationListTable
            key="waiting"
            bulkFinancingConfirmationListData={bulkFinancingWaitingList.content}
            pageable={bulkFinancingWaitingPageable}
            paginate={multipleRequestLoanWaitingListPaginate}
          />,
          <FinancierBulkAnchorFinancingConfirmationListTable
            key="complete"
            bulkFinancingConfirmationListData={bulkFinancingCompletedList.content}
            pageable={bulkFinancingCompletedPageable}
            paginate={multipleRequestLoanCompletedListPaginate}
          />,
        ]}
      />
    </>
  );
}

export default FinancierBulkAnchorFinancingConfirmationList;
