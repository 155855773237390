import type Pageable from 'models/Pageable';
import type { ExpectedLoanRepaymentVO, ExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import { formattingToExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import type { LoanDetailVO, LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import { formattingToLoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { LoanTransactionVO, LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import { formattingToLoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import type { LoanVO, LoanVOModel } from 'models/vo/LoanVO';
import { formattingToLoanVOModel } from 'models/vo/LoanVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemLoanListRequest } from './requests';

export async function requestSystemLoanList(
  pageNumber: number,
  rowCount: number,
  data: SystemLoanListRequest,
): Promise<Pageable<LoanVOModel[]>> {
  const response = await http.get<Pageable<LoanVO[]>>({
    url: API_SY.LOANS.LOAN_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  const loanList = response.content.map(data => formattingToLoanVOModel(data));

  return {
    ...response,
    content: loanList,
  };
}

export async function requestSystemLoanDetail(loanId: number): Promise<LoanDetailVOModel> {
  const response = await http.get<LoanDetailVO>({
    url: API_SY.LOANS.LOAN_DETAIL(loanId),
  });

  return formattingToLoanDetailVOModel(response);
}

export async function requestSystemLoanTransactionList(
  loanId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<LoanTransactionVOModel[]>> {
  const response = await http.get<Pageable<LoanTransactionVO[]>>({
    url: API_SY.LOANS.LOAN_TRANSACTION_LIST(loanId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  const loanTransactionList = response.content.map(data => formattingToLoanTransactionVOModel(data));

  return {
    ...response,
    content: loanTransactionList,
  };
}

export async function requestSystemRenewLoanByFinancierInterface(loanId: number): Promise<LoanVOModel> {
  const response = await http.post<LoanVO>({
    url: API_SY.LOANS.RENEW_LOAN_BY_FINANCIER_INTERFACE(loanId),
  });

  return formattingToLoanVOModel(response);
}

// 조기상환가능 예상금액 조회
export async function requestSystemCalculateExpectedAmount(
  loanId: number,
  expectedRepaymentDate: string,
): Promise<ExpectedLoanRepaymentVOModel> {
  const response = await http.post<ExpectedLoanRepaymentVO>({
    url: API_SY.LOANS.CALCULATE_EXPECTED_AMOUNT(loanId),
    data: { expectedRepaymentDate },
  });

  return formattingToExpectedLoanRepaymentVOModel(response);
}

export async function requestSystemDownloadLoanEtc(loanId: number, loanEtcAttachmentId: number) {
  await http.download(API_SY.LOANS.DOWNLOAD_LOAN_ETC(loanId), { loanEtcAttachmentId });
}

//건별 대출 약정서, 채권 양도 계약서 다운로드 호출
export async function requestSystemDownloadLoanAgreement(loanId: number) {
  await http.download(API_SY.LOANS.DOWNLOAD_LOAN_AGREEMENT(loanId));
}

export async function requestSystemDownloadLoanRequestForm(loanId: number) {
  await http.download(API_SY.LOANS.DOWNLOAD_LOAN_REQUEST_FORM(loanId));
}

export async function requestSystemDownloadScannedAr(loanId: number) {
  await http.download(API_SY.LOANS.DOWNLOAD_SCANNED_AR(loanId));
}

//채권 양도 통지서 다운로드 호출
export async function requestSystemDownloadDebtSellingNotification(loanId: number) {
  await http.download(API_SY.LOANS.DOWNLOAD_DEBT_SELLING_NOTIFICATION(loanId));
}
