import { useContext } from 'react';

import ModalContext from 'utils/modal/ModalContext';

export default function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal should be used within ModalContext.Provider');
  }

  return context;
}
