import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, FINANCIER_INTERFACE_TYPE, SUCCESS_AR_STATUS } from 'enums';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { ArSettlementDetailVOModel } from 'models/vo/ArSettlementDetailVO';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import {
  requestFinancierSettlementDetail,
  requestFinancierUpdateSettled,
} from 'utils/http/api/financier/ar-settlements';
import { requestFinancierSuccessArsList } from 'utils/http/api/financier/success-ars';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType, getSignIn } from 'utils/storage/LocalStorage';
import { tableNumberValueIsZero, tableValueManage } from 'utils/valueManager/ValueManager';

function FinancierArSettlementDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { anchorAgreementId } = useParams<any>();

  const [financierArSettlementDetail, setFinancierArSettlementDetail] = useState<ArSettlementDetailVOModel>();
  const [financierArList, setFinancierArList] = useState<Pageable<SuccessArVOModel[]>>();
  const [arRepaymentInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.AR_PAYMENT_RESULT));

  const { pageable, setPageable } = usePageable();

  const [locationState, errorHandlerOfLocationState] = useLocationState<{ settlementDate: string }>(['settlementDate']);
  const { settlementDate } = locationState;

  const isShowUpdateTransaction = !arRepaymentInterface && getSignIn()?.authorityType !== AUTHORITY_TYPE.ADMIN;

  useEffect(() => {
    if (mounted) {
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [fetchedFinancierARSettlementDetailPage, fetchedFinancierArListPage] = await Promise.all([
        requestFinancierSettlementDetail(anchorAgreementId, settlementDate),
        requestFinancierSuccessArsList(pageable.currentPage, pageable.sizePerPage, {
          anchorAgreementId: anchorAgreementId,
          settlementDateFrom: settlementDate,
          settlementDateTo: settlementDate,
        }),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierArSettlementDetail(fetchedFinancierARSettlementDetailPage);
        setFinancierArList(fetchedFinancierArListPage);
        setPageable(fetchedFinancierArListPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginate = async (page: number, sizePerPage: number) => {
    try {
      const fetchedFinancierArListPage = await requestFinancierSuccessArsList(page, sizePerPage, {
        anchorAgreementId: anchorAgreementId,
        settlementDateFrom: settlementDate,
        settlementDateTo: settlementDate,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierArList(fetchedFinancierArListPage);
        setPageable(fetchedFinancierArListPage);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  // 만기일자 경과 시 표시
  const overDaysToMaturity = (daysToMaturity: number | undefined) => {
    if (isNil(daysToMaturity) || daysToMaturity <= 0) {
      return '-';
    } else {
      return `D - ${daysToMaturity}`;
    }
  };

  const renderTableResult = () => {
    return financierArList?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.arNumber} />
          <Td data={item.anchorPartnerName} />
          <Td data={item.arAmount} format="number" />
          <Td
            className={getStatusTextClass('SUCCESS_AR_STATUS', item.successArStatus)}
            data={t(`code:success-ar-status.${item.successArStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_FI.VIEW_TRANSACTION.AR_DETAIL_BUILD_PATH(item.successArId)} />
        </Tr>
      );
    });
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:AR_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:AR_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Settlement_Status'),
      colWidths: 120,
      hasStatusDescription: true,
      showStatusDescriptionFunc: () => {
        modal.show(
          <StatusDescriptionModal
            statusDescriptionEnum={Object.values(SUCCESS_AR_STATUS)}
            statusDescriptionModalType="TEXT"
            statusDescriptionEnumType="SUCCESS_AR_STATUS"
          />,
          {
            modalType: ModalType.ALERT,
            modalSize: ModalSize.XL,
            closeBtnText: t('text:Close'),
          },
        );
      },
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const showResultBulkUpdateARSettlementModal = ({
    numberOfAr,
    amountOfAr,
    settlementCompletionDate,
  }: {
    numberOfAr: number;
    amountOfAr: number;
    settlementCompletionDate: string;
  }) => {
    const modalJsx = (
      <>
        <h6>
          {t('text:The_AR_settlement_has_been_completed_successfully')}
          <br />
          {t('text:Proceed_to_the_AR_details_page_to_see_the_detailed_settlement_information')}
        </h6>
        <br />
        <ul className="invoice-registration-sum">
          <li>
            • {t('text:Number_of_Completed_AR')} : <strong>{t('format:number', { value: numberOfAr })}</strong>
          </li>
          <li>
            • {t('text:Amount_of_Completed_AR')} : <strong>{t('format:number', { value: amountOfAr })}</strong>
          </li>
          <li>
            • {t('text:Settlement_Completion_Date')} :{' '}
            <strong>{t('format:date', { value: settlementCompletionDate, key: 'date' })}</strong>
          </li>
        </ul>
      </>
    );

    const modalOptions: ModalOptions = {
      title: t('text:Update_AR_Settlement'),
      closeBtnCb: () => {
        fetchAll();
      },
    };

    modal.show(modalJsx, modalOptions);
  };

  const showBulkUpdateARSettlementModal = () => {
    const modalJsx = (
      <>
        <h6>
          {t('text:The_settlement_of_the_pending_AR_below_will_be_completed')}
          <br />
          {t('text:Proceed_to_the_AR_details_page_to_update_the_settlement_information_of_each_AR_individually')}
        </h6>
        <br />
        <ul className="invoice-registration-sum">
          <li>
            • {t('text:Number_of_Pending_AR')} :{' '}
            <strong>{t('format:number', { value: financierArSettlementDetail?.arRequestedCount })}</strong>
          </li>
          <li>
            • {t('text:Amount_of_Pending_AR')} :{' '}
            <strong>{t('format:number', { value: financierArSettlementDetail?.arRequestedAmount })}</strong>
          </li>
          <li>
            • {t('text:Settlement_Date')} :{' '}
            <strong>{t('format:date', { value: financierArSettlementDetail?.settlementDate, key: 'date' })}</strong>
          </li>
        </ul>
      </>
    );

    const modalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Update_AR_Settlement'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async () => {
        try {
          const response = await requestFinancierUpdateSettled(anchorAgreementId, {
            targetSettlementDate: settlementDate,
          });
          const numberOfAr = response.length;
          const amountOfAr = response.reduce((acc, cur) => acc + cur.arAmount, 0);
          const settlementCompletionDate = response[0].settlementDate;

          showResultBulkUpdateARSettlementModal({ numberOfAr, amountOfAr, settlementCompletionDate });
        } catch (e) {
          modal.show(e);
        }
      },
    };

    modal.show(modalJsx, modalOptions);
  };

  return (
    <>
      <BackHeaderTitle
        title={
          `${t('text:AR_Settlement_Details')}` +
          ` [${tableValueManage(
            financierArSettlementDetail?.settlementDate,
            t('format:date', { value: financierArSettlementDetail?.settlementDate, key: 'date' }),
          )}]`
        }
      />
      <GuideMessage
        message={[
          t(
            'text:Review_the_AR_settlement_information_below_as_the_settlement_payment_will_be_processed_on_the_settlement_date',
          ),
          t(
            'text:If_the_AR_is_used_for_a_financing_application_repayments_will_be_processed_automatically_after_the_settlement_payments',
          ),
          t(
            'text:Ensure_that_the_AR_settlement_amount_is_deposited_in_the_designated_bank_account_by_the_settlement_date_as_the_AR_settlements_may_not_be_processed_if_there_is_insufficient_balance',
          ),
        ]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:AR_Settlement_Information')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Days_Until_Settlement')}
                col={3}
                className="information-form__input border-none pointfont"
                value={overDaysToMaturity(financierArSettlementDetail?.daysToMaturity)}
              />
              <FormValue
                label={t('text:Settlement_Date')}
                col={3}
                value={financierArSettlementDetail?.settlementDate}
                format="date"
              />
              <FormValue label={t('text:Anchor_Name')} col={3} value={financierArSettlementDetail?.anchorName} />
              <FormValue label={t('text:Currency')} col={3} value={financierArSettlementDetail?.currencyType} />
            </div>
          </FormContents>
          <FormContents backGroundType={BackGroundType.Gray}>
            <div className="row">
              <FormValue
                label={t('text:Total_Number_of_AR')}
                value={financierArSettlementDetail?.totalArCount}
                format="number"
                className="information-form__input border-none bold-font"
              />
              <FormValue
                label={t('text:Total_Amount_of_AR')}
                value={financierArSettlementDetail?.totalArAmount}
                format="number"
                className="information-form__input border-none bold-font"
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:AR_Settlement_Result')} />
        <FormBorder>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                label={t('text:Total_Number_of_AR')}
                value={financierArSettlementDetail?.totalArCount}
                format="number"
                className="information-form__input border-none bold-font"
              />
              <FormValue
                label={t('text:Total_Amount_of_AR')}
                value={financierArSettlementDetail?.totalArAmount}
                format="number"
                className="information-form__input border-none bold-font"
              />
            </div>
          </FormContents>
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Number_of_Pending_AR')}
                value={tableNumberValueIsZero(financierArSettlementDetail?.arRequestedCount)}
              />
              <FormValue
                label={t('text:Amount_of_Pending_AR')}
                value={tableNumberValueIsZero(financierArSettlementDetail?.arRequestedAmount)}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Number_of_Completed_AR')}
                value={tableNumberValueIsZero(financierArSettlementDetail?.arSettledCount)}
              />
              <FormValue
                label={t('text:Amount_of_Completed_AR')}
                value={tableNumberValueIsZero(financierArSettlementDetail?.arSettledAmount)}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Number_of_Failed_AR')}
                value={tableNumberValueIsZero(financierArSettlementDetail?.arFailedCount)}
              />
              <FormValue
                label={t('text:Amount_of_Failed_AR')}
                value={tableNumberValueIsZero(financierArSettlementDetail?.arFailedAmount)}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {isShowUpdateTransaction && (
        <div className="content-area">
          <div className="flex-end mt-2">
            <Button
              size={ButtonSizeEnum.MD}
              disabled={
                financierArSettlementDetail?.arRequestedCount === 0 || !financierArSettlementDetail?.arRequestedCount
              }
              onClick={showBulkUpdateARSettlementModal}
            >
              {t('text:Update_Transaction')}
            </Button>
          </div>
        </div>
      )}
      <div className="content-area">
        <SectionTitle title={t('text:AR_List')} />
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderTableResult()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierArSettlementDetail;
