import type { ErrorOption } from 'react-hook-form';

import { groupBy, minBy } from 'lodash-es';

import type { CommonResponseCode, ResponseCodeType } from 'enums/exception';
import { InvalidInputValueExceptionCode } from 'enums/exception';
import { scrollIntoErrorElement } from 'utils/scroll';

import { HttpError } from './HttpError';

export enum FormErrorCodeType {
  NotNull = 'NotNull',
  NotZeroOrNull = 'NotZeroOrNull',
  NotBlank = 'NotBlank',
  NotEmpty = 'NotEmpty',
  InvalidRange = 'InvalidRange',
  Length = 'Length',
  Size = 'Size',
  Range = 'Range',
  Pattern = 'Pattern',
  Email = 'Email',
  Phone = 'Phone',
  Max = 'Max',
  Min = 'Min',
  DecimalMin = 'DecimalMin',
  Digits = 'Digits',
  PastOrPresent = 'PastOrPresent', // 날짜 검증(현재 또는 과거 날짜만 가능)
  Conditional = 'Conditional', // custom error
  Duplication = 'Duplication',
  CurrencyType = 'CurrencyType',
}

type FormErrorFrontDataType = {
  text: string | [string, any];
  priority: number;
};

type FormErrorFrontObjectType = { [key: string]: FormErrorFrontDataType };

export type FormErrorDataType<T> = FormErrorFrontDataType & {
  field: keyof T;
  value: string;
  details: string;
  code: string;
  arguments: string[];
};

export interface FormError<T> {
  code: Exclude<ResponseCodeType, CommonResponseCode.SUCCESS>;
  data: FormErrorDataType<T>[];
  message: string;
}

export type ErrorObj = {
  column: string;
  rowIndex: number;
  message: string;
};

export type SetError<T> = (name: keyof T, error: ErrorOption) => void;

export type ClearErrors<T> = (name?: keyof T | (keyof T)[] | undefined) => void;

export function formErrorHandler<T>(
  error: FormError<T> | any,
  setError?: SetError<T>,
  clearErrors?: ClearErrors<T>,
  runScrollIntoErrorElement: boolean = true,
  getCustomErrorTypeObject?: (arg1?: string, arg2?: string) => FormErrorFrontObjectType,
): ErrorObj[] {
  const errorArr: ErrorObj[] = [];

  if (error instanceof HttpError) {
    clearErrors?.();
    if (error.code === InvalidInputValueExceptionCode.INVALID_INPUT_VALUE) {
      const getFormErrorCodeResult = (code: string, arg: string[]): FormErrorFrontDataType => {
        const [arg1, arg2] = arg ?? [];

        const errorTypeObject: FormErrorFrontObjectType = {
          [FormErrorCodeType.NotNull]: {
            text: 'This_information_is_required',
            priority: 1,
          },
          [FormErrorCodeType.NotBlank]: {
            text: 'This_information_is_required',
            priority: 1,
          },
          [FormErrorCodeType.NotEmpty]: {
            text: 'This_information_is_required',
            priority: 1,
          },
          [FormErrorCodeType.Conditional]: {
            text: 'This_information_is_required',
            priority: 1,
          },
          [FormErrorCodeType.NotZeroOrNull]: {
            text: '0_and_blank_are_not_allowed',
            priority: 2,
          },
          [FormErrorCodeType.Length]: {
            text: ['The_text_should_be_between_MinSize_to_MaxSize_characters', { minSize: arg1, maxSize: arg2 }],
            priority: 3,
          },
          [FormErrorCodeType.Size]: {
            text: ['The_text_should_be_between_MinSize_to_MaxSize_characters', { minSize: arg1, maxSize: arg2 }],
            priority: 3,
          },
          [FormErrorCodeType.Duplication]: {
            text: 'Duplicate_is_not_possible',
            priority: 4,
          },
          [FormErrorCodeType.InvalidRange]: {
            text: 'Please_check_the_range',
            priority: 5,
          },
          [FormErrorCodeType.Pattern]: {
            text: 'The_input_format_is_invalid',
            priority: 6,
          },
          [FormErrorCodeType.Email]: {
            text: `Provide_an_email_in_this_format_example@email_com`,
            priority: 7,
          },
          [FormErrorCodeType.Range]: {
            text: ['Enter_a_number_between_MinRange_and_MaxRange', { minRange: arg1, maxRange: arg2 }],
            priority: 8,
          },
          [FormErrorCodeType.Min]: {
            text: ['The_minimum_value_is_minNumber', { minNumber: arg1 }],
            priority: 9,
          },
          [FormErrorCodeType.DecimalMin]: {
            text: ['The_maximum_value_is_maxNumber', { maxNumber: arg1 }],
            priority: 10,
          },
          [FormErrorCodeType.Max]: {
            text: ['The_maximum_value_is_maxNumber', { maxNumber: arg1 }],
            priority: 10,
          },
          [FormErrorCodeType.Digits]: {
            text: `Please_check_the_entered_value_again`,
            priority: 11,
          },
          [FormErrorCodeType.CurrencyType]: {
            text: 'Please_check_the_decimal_point',
            priority: 12,
          },
          [FormErrorCodeType.PastOrPresent]: {
            text: 'A_date_in_the_future_cannot_be_selected',
            priority: 13,
          },
          ...getCustomErrorTypeObject?.(arg1, arg2),
        };

        const defaultErrorTypeObject = {
          text: '',
          priority: 11,
        };

        return errorTypeObject[code] ?? defaultErrorTypeObject;
      };

      const errorDataArr = error.data;
      if (errorDataArr !== null) {
        const priorityAndMappedErrorCodeArr = (errorDataArr as FormErrorDataType<T>[]).map(item => {
          const { priority, text } = getFormErrorCodeResult(item.code, item.arguments);

          return {
            ...item,
            priority,
            text,
          };
        });

        const groupByErrorFields = groupBy(priorityAndMappedErrorCodeArr, 'field');
        const uniqErrorCodeArr: FormErrorDataType<T>[] = [];

        for (const field in groupByErrorFields) {
          if (groupByErrorFields[field]?.length > 1) {
            const highPriorityData = minBy(groupByErrorFields[field], 'priority') as FormErrorDataType<T>;
            uniqErrorCodeArr.push(highPriorityData);
          } else uniqErrorCodeArr.push(groupByErrorFields[field][0]);
        }

        uniqErrorCodeArr.forEach((data: FormErrorDataType<T>) => {
          if (setError) {
            setError(data.field, {
              type: data.code,
              message: data.text as string,
            });
          } else {
            errorArr.push({
              column: data.field.toString().split('.', 2)[1],
              rowIndex: parseInt(data.field.toString().match(/\[(\d+)\]/)![1]),
              message: Array.isArray(data.text) ? data.text[0] : data.text,
            });
          }
        });

        const errorFields: FormErrorDataType<T>['field'][] = uniqErrorCodeArr.map(
          (data: FormErrorDataType<T>) => data.field,
        );

        if (runScrollIntoErrorElement) scrollIntoErrorElement(errorFields as string[]);
      }
    }
  }

  return errorArr;
}
