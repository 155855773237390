import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormValue } from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import useAnchorDealerCompanyInfoConfirmedDetailPageState from './useAnchorDealerCompanyInfoConfirmedDetailPageState';
import RelatedAnchorAgreementList from '../../components/RelatedAnchorAgreementList';

function AnchorDealerCompanyInfoConfirmedDetailPage() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();

  const userAuthorityType = getSignIn()?.authorityType;

  const [editable, setEditable] = useState<boolean>(false);

  const {
    state,
    fetchAll,
    formMethods,
    historyPageable,
    historyPaginate,
    agreementPageable,
    agreementPaginate,
    resetFormData,
    checkOtpAuth,
  } = useAnchorDealerCompanyInfoConfirmedDetailPageState();

  const { register } = formMethods;

  const { anchorDealerInfo, anchorDealerHistoryList, relatedAnchorAgreement } = state;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const handleClickEditBtn = () => {
    setEditable(true);
  };

  const handleClickCancelBtn = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information?')}
        <br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => {
          resetFormData(anchorDealerInfo);
          setEditable(false);
        },
      },
    );
  };

  const handleClickSaveBtn = async () => {
    const successModalMessage =
      userAuthorityType === AUTHORITY_TYPE.ADMIN || userAuthorityType === AUTHORITY_TYPE.AUTHORIZER ? (
        <h6>{t('text:The_changes_to_the_Dealers_information_have_been_saved_successfully')}</h6>
      ) : (
        <h6>
          {t('text:Request_for_modification_approval_has_been_completed')}
          <br />
          {t('text:Modification_will_be_completed_after_approval')}
        </h6>
      );

    modal.show(<h6>{t('text:Would_you_like_to_modify_the_dealers_information?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: async () => {
        const isSuccess = await checkOtpAuth();

        if (isSuccess) {
          modal.show(<>{successModalMessage}</>, {
            closeBtnCb: () => {
              setEditable(false);
              fetchAll();
            },
          });
        }
      },
    });
  };

  const EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Dealer_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Tax_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Company_Registration_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 100,
    },
    {
      headerText: t('text:Fax'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Title'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Email'),
      colWidths: 100,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 100,
    },
  ];

  return (
    <>
      <BackHeaderTitle title={anchorDealerInfo?.name} />
      <GuideMessage
        message={[t('text:If_the_Dealers_information_is_incorrect_revise_the_information_in_company_info_menu')]}
      />
      <div className="content-area">
        <SectionTitle title={t(`text:Dealer_Information`)}>
          {editable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={handleClickCancelBtn}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={handleClickSaveBtn} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <Button size={ButtonSizeEnum.SM} onClick={handleClickEditBtn} style={{ width: '60px' }}>
              {t('text:Edit')}
            </Button>
          )}
        </SectionTitle>
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormInput
                label={t(`text:Dealer_Name`)}
                name="waitingAnchorDealer.name"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={!editable}
              />
              <FormInput
                label={t(`text:Tax_Code`)}
                name="waitingAnchorDealer.taxCode"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={!editable}
              />
            </div>
            <div className="row">
              <FormInput
                label={t(`text:Company_Registration_Number`)}
                name="waitingAnchorDealer.businessCode"
                ref={register}
                disabled={!editable}
              />
              <FormInput
                col={3}
                label={t(`text:Telephone`)}
                name="waitingAnchorDealer.telephone"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={!editable}
              />
              <FormInput
                col={3}
                label={t(`text:Fax`)}
                name="waitingAnchorDealer.fax"
                ref={register}
                disabled={!editable}
              />
            </div>
            <div className="row">
              <FormInput
                label={t(`text:Legal_Representative_Name`)}
                name="waitingAnchorDealer.representativeName"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={!editable}
              />
              <FormInput
                col={3}
                label={t(`text:Legal_Representative_Title`)}
                name="waitingAnchorDealer.representativePosition"
                ref={register}
                disabled={!editable}
              />
              <FormInput
                col={3}
                label={t(`text:Legal_Representative_Email`)}
                name="waitingAnchorDealer.representativeEmail"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={!editable}
              />
            </div>
            <div className="row">
              <FormInput
                col={12}
                label={t(`text:Registered_Office_Address`)}
                name="waitingAnchorDealer.address"
                ref={register}
                requiredOptions={{ required: true }}
                disabled={!editable}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <RelatedAnchorAgreementList
        relatedAnchorAgreement={relatedAnchorAgreement}
        pageable={agreementPageable}
        paginate={agreementPaginate}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={EDIT_HISTORY_TABLE_HEADERS} />
          <TableBody numOfCol={EDIT_HISTORY_TABLE_HEADERS.length}>
            {anchorDealerHistoryList?.map((item, i) => (
              <Tr key={i}>
                <Td data={item.updatedDateTime} format="datetime" />
                <Td data={item.name} />
                <Td data={item.taxCode} />
                <Td data={item.businessCode} />
                <Td data={item.telephone} />
                <Td data={item.fax} />
                <Td data={item.representativeName} />
                <Td data={item.representativePosition} />
                <Td data={item.representativeEmail} />
                <Td data={item.address} />
                <Td data={`${item.operatorUserLoginId} / ${item.operatorUserName}`} />
                <Td data={`${item.authorizerUserLoginId} / ${item.authorizerUserName}`} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={historyPageable} paginate={historyPaginate} />
      </div>
    </>
  );
}

export default AnchorDealerCompanyInfoConfirmedDetailPage;
