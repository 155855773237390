import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import useMounted from 'hooks/useMounted';

import FinancierAnchorDealerAssignmentListAdminAssignedTab from './tabs/FinancierAnchorDealerAssignmentListAdminAssignedTab';
import FinancierAnchorDealerAssignmentListAdminWaitingTab from './tabs/FinancierAnchorDealerAssignmentListAdminWaitingTab';
import useFinancierAnchorDealerAssignmentListStateAdmin from './useFinancierAnchorDealerAssignmentListStateAdmin';

function FinancierAnchorDealerAssignmentListAdmin() {
  const mounted = useMounted();
  const { t } = useTranslation();

  const { waitingTabUtils, assignedTabUtils, fetchAll } = useFinancierAnchorDealerAssignmentListStateAdmin();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const {
    waitingForAssignAnchorDealerData,
    waitingTabPageable,
    waitingTabSearchForm,
    handleClickSearchWaitingForAssignList,
    paginateWaitingForAssignList,
    assignRequest,
  } = waitingTabUtils;

  const {
    assignedAnchorDealerData,
    assignedTabPageable,
    assignedTabSearchForm,
    handleClickSearchAssignedList,
    paginateAssignedList,
  } = assignedTabUtils;

  return (
    <>
      <HeaderTitle title={t('text:Uploaded_Partner_Assignment')} />
      <GuideMessage
        message={[
          t(
            'text:Please_assign_the_Uploaded_Dealers_below_to_branches_in_order_to_manage_and_operate_individual_Uploaded_Dealers',
          ),
          t(
            'text:To_assign_multiple_Uploaded_Dealers_at_once_select_the_companies_you_wish_to_assign_and_click_on_the_Assign_Branch_button',
          ),
          t('text:Please_click_on_the_arrow_button_to_see_detailed_information_of_the_Uploaded_Dealers'),
        ]}
      />
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:Waiting_for_Approval'),
            tabItemCount: waitingForAssignAnchorDealerData?.totalElements ?? 0,
          },
          {
            tabName: t('text:Assigned'),
            tabItemCount: assignedAnchorDealerData?.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...waitingTabSearchForm} key="waiting">
            <FinancierAnchorDealerAssignmentListAdminWaitingTab
              waitingAnchorDealerList={waitingForAssignAnchorDealerData}
              pageable={waitingTabPageable}
              paginate={paginateWaitingForAssignList}
              handleClickSearch={handleClickSearchWaitingForAssignList}
              assignRequest={assignRequest}
            />
          </FormProvider>,
          <FormProvider {...assignedTabSearchForm} key="assigned">
            <FinancierAnchorDealerAssignmentListAdminAssignedTab
              assignedAnchorDealerList={assignedAnchorDealerData}
              pageable={assignedTabPageable}
              paginate={paginateAssignedList}
              handleClickSearch={handleClickSearchAssignedList}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default FinancierAnchorDealerAssignmentListAdmin;
