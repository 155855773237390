import { ATTACHMENT_TYPE } from 'enums';
import { requestFinancierMultipleRequestLoanDownloadAttachment } from 'utils/http/api/financier/multiple-request-loans';
import useModal from 'utils/modal/useModal';

export function useAdditionalDocumentsController(multipleRequestLoansId: number) {
  const modal = useModal();

  const handleDownloadEtcFileClick = async (e: any, loanEtcAttachmentId: number) => {
    e.preventDefault();
    try {
      await requestFinancierMultipleRequestLoanDownloadAttachment(multipleRequestLoansId, {
        attachmentType: ATTACHMENT_TYPE.LOAN_ETC,
        loanEtcAttachmentId,
      });
    } catch (e: any) {
      modal.show(e);
    }
  };

  return { handleDownloadEtcFileClick };
}
