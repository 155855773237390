import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import Navigation from 'components/stateless/Navigation';
import { ROUTES_DE } from 'constants/routes/dealer';
import NotFoundPage from 'pages/NotFoundPage';
import DealerAgreementDetail from 'pages/dealer/company/agreement/detail/DealerAgreementDetail';
import DealerAgreementList from 'pages/dealer/company/agreement/list/DealerAgreementList';
import DealerUserDetail from 'pages/dealer/company/user/detail/DealerUserDetail';
import DealerUserList from 'pages/dealer/company/user/list/DealerUserList';
import DealerUserRegister from 'pages/dealer/company/user/register/DealerUserRegister';
import PartnerDashboard from 'pages/dealer/dashboard/PartnerDashboard';
import DealerFinancingApplicationStep1 from 'pages/dealer/manage-financing/application/step1/DealerFinancingApplicationStep1';
import DealerArFinancingApplicationStep2 from 'pages/dealer/manage-financing/application/step2/ar/DealerArFinancingApplicationStep2';
import DealerArBulkFinancingApplicationStep2 from 'pages/dealer/manage-financing/application/step2/arBulk/DealerArBulkFinancingApplicationStep2';
import DealerInvoiceFinancingApplicationStep2 from 'pages/dealer/manage-financing/application/step2/invoice/DealerInvoiceFinancingApplicationStep2';
import DealerBulkFinancingConfirmationDetail from 'pages/dealer/manage-financing/bulk-financing/detail/DealerBulkFinancingConfirmationDetail';
import DealerBulkFinancingConfirmationList from 'pages/dealer/manage-financing/bulk-financing/list/DealerBulkFinancingConfirmationList';
import DealerFinancingConfirmationList from 'pages/dealer/manage-financing/confirmation/list/DealerFinancingConfirmationList';
import DealerEarlyRepaymentDetail from 'pages/dealer/manage-financing/request-for-early-repayment/detail/DealerEarlyRepaymentDetail';
import DealerEarlyRepaymentList from 'pages/dealer/manage-financing/request-for-early-repayment/list/DealerEarlyRepaymentList';
import DealerEarlyRepaymentRegister from 'pages/dealer/manage-financing/request-for-early-repayment/register/DealerEarlyRepaymentRegister';
import DealerExtensionDetail from 'pages/dealer/manage-financing/request-for-extension/detail/DealerExtensionDetail';
import DealerExtensionList from 'pages/dealer/manage-financing/request-for-extension/list/DealerExtensionList';
import DealerExtensionRegister from 'pages/dealer/manage-financing/request-for-extension/register/DealerExtensionRegister';
import DealerSecuredInventoryDetail from 'pages/dealer/manage-financing/secured-inventory-update/detail/DealerSecuredInventoryDetail';
import DealerSecuredInventoryList from 'pages/dealer/manage-financing/secured-inventory-update/list/DealerSecuredInventoryList';
import DealerSecuredInventoryRegister from 'pages/dealer/manage-financing/secured-inventory-update/register/DealerSecuredInventoryRegister';
import DealerMyAccount from 'pages/dealer/my-account/DealerMyAccount';
import DealerMyAccountEmailNotificationSetting from 'pages/dealer/my-account/email-notification-setting';
import DealerArConfirmationDetail from 'pages/dealer/register-ar/confirmation/detail/DealerArConfirmationDetail';
import DealerArConfirmationList from 'pages/dealer/register-ar/confirmation/list/DealerArConfirmationList';
import DealerArRegisterStep1 from 'pages/dealer/register-ar/registration/step1/DealerArRegisterStep1';
import DealerArRegisterStep2 from 'pages/dealer/register-ar/registration/step2/DealerArRegisterStep2';
import DealerInvoiceConfirmationDetail from 'pages/dealer/register-invoices/confirmation/detail/DealerInvoiceConfirmationDetail';
import DealerInvoiceConfirmationList from 'pages/dealer/register-invoices/confirmation/list/DealerInvoiceConfirmationList';
import DealerInvoiceRegisterStep1 from 'pages/dealer/register-invoices/registeration/step1/DealerInvoiceRegisterStep1';
import DealerInvoiceRegisterStep2 from 'pages/dealer/register-invoices/registeration/step2/DealerInvoiceRegisterStep2';
import DealerArDetail from 'pages/dealer/view-transactions/ar/detail/DealerArDetail';
import DealerArList from 'pages/dealer/view-transactions/ar/list/DealerArList';
import DealerFinancingDetail from 'pages/dealer/view-transactions/financing/detail/DealerFinancingDetail';
import DealerViewTransactionFinancingList from 'pages/dealer/view-transactions/financing/list/DealerViewTransactionFinancingList';
import DealerInvoiceDetail from 'pages/dealer/view-transactions/invoice/detail/DealerInvoiceDetail';
import DealerInvoiceList from 'pages/dealer/view-transactions/invoice/list/DealerInvoiceList';

function DealerRouter({ location }: RouteComponentProps<{}>) {
  return (
    <div className="content-wrapper">
      <Navigation />
      <div className="page-content">
        <Switch>
          {/* DashBoard - 대시보드 페이지 */}
          <Route exact path={ROUTES_DE.DASHBOARD} component={PartnerDashboard} />

          {/* Register invoice - Registration - 딜러 선택 */}
          <Route exact path={ROUTES_DE.REGISTER_INVOICE.REGISTRATION_STEP1} component={DealerInvoiceRegisterStep1} />

          {/* Register invoice - Registration - 송장 정보 입력 */}
          <Route path={ROUTES_DE.REGISTER_INVOICE.REGISTRATION_STEP2} component={DealerInvoiceRegisterStep2} />

          {/* Register invoice - Confirmation - 송장 등록 결재 목록 */}
          <Route exact path={ROUTES_DE.REGISTER_INVOICE.CONFIRMATION_LIST} component={DealerInvoiceConfirmationList} />

          {/* Register invoice - Confirmation - 송장 결재 상세 */}
          <Route
            key={location.key}
            path={ROUTES_DE.REGISTER_INVOICE.CONFIRMATION_DETAIL}
            component={DealerInvoiceConfirmationDetail}
          />

          {/* AP 등록 step1 - anchor agreement 선택 */}
          <Route exact path={ROUTES_DE.REGISTER_AR.REGISTRATION_STEP1} component={DealerArRegisterStep1} />

          {/* AP 등록 step2 - ap register page */}
          <Route path={ROUTES_DE.REGISTER_AR.REGISTRATION_STEP2} component={DealerArRegisterStep2} />

          {/* AP confirmation list */}
          <Route exact path={ROUTES_DE.REGISTER_AR.CONFIRMATION_LIST} component={DealerArConfirmationList} />

          {/* AP confirmation detail */}
          <Route
            key={location.key}
            path={ROUTES_DE.REGISTER_AR.CONFIRMATION_DETAIL}
            component={DealerArConfirmationDetail}
          />

          {/* Manage Financing - Application - 대출 신청 가능 목록 */}
          <Route
            exact
            path={ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1}
            component={DealerFinancingApplicationStep1}
          />

          {/* Manage Financing - Application - 대출 신청 페이지  - Invoice */}
          <Route
            path={ROUTES_DE.MANAGE_FINANCING.APPLICATION_INVOICE_STEP2}
            component={DealerInvoiceFinancingApplicationStep2}
          />

          {/* Manage Financing - Application - 대출 신청 페이지 - AR  */}
          <Route path={ROUTES_DE.MANAGE_FINANCING.APPLICATION_AR_STEP2} component={DealerArFinancingApplicationStep2} />
          <Route
            path={ROUTES_DE.MANAGE_FINANCING.BULK_APPLICATION_AR_STEP2}
            component={DealerArBulkFinancingApplicationStep2}
          />
          {/* Manage Financing - Confirmation - 신청된 대출 목록 (Application 상태로 필터링) */}
          <Route
            exact
            path={ROUTES_DE.MANAGE_FINANCING.CONFIRMATION_LIST}
            component={DealerFinancingConfirmationList}
          />

          {/* Manage Financing - Confirmation - 대출 상세 */}
          <Route
            key={location.key}
            path={ROUTES_DE.MANAGE_FINANCING.CONFIRMATION_DETAIL}
            component={DealerFinancingDetail}
          />

          {/* Manage Financing - Bulk Financing Confirmation - 일괄 승인 목록 */}
          <Route
            exact
            path={ROUTES_DE.MANAGE_FINANCING.BULK_CONFIRMATION_LIST}
            component={DealerBulkFinancingConfirmationList}
          />

          <Route
            key={location.key}
            path={ROUTES_DE.MANAGE_FINANCING.BULK_CONFIRMATION_DETAIL}
            component={DealerBulkFinancingConfirmationDetail}
          />

          {/* Manage Financing - Request for Early Repayment - 조기 상환 목록 */}
          <Route
            exact
            path={ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EARLY_REPAYMENT_LIST}
            component={DealerEarlyRepaymentList}
          />

          {/* Manage Financing - Request for Early Repayment - 조기 상환 신청 : 조기 상환 가능 목록 */}
          <Route
            exact
            path={ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EARLY_REPAYMENT_REGISTER}
            component={DealerEarlyRepaymentRegister}
          />

          {/* Manage Financing - Request for Early Repayment - 조기 상환 상세 */}
          <Route
            key={location.key}
            path={ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EARLY_REPAYMENT_DETAIL}
            component={DealerEarlyRepaymentDetail}
          />

          {/* Manage Financing - Request for Extension - 만기 연장 신청 목록 (신청 완료된) */}
          <Route exact path={ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EXTENSION_LIST} component={DealerExtensionList} />

          {/* Manage Financing - Request for Extension - 만기 연장 신청 가능목록 */}
          <Route
            exact
            path={ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EXTENSION_REGISTER}
            component={DealerExtensionRegister}
          />

          {/* Manage Financing - Request for Extension - 만기 연장 신청 상세 */}
          <Route
            key={location.key}
            path={ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EXTENSION_DETAIL}
            component={DealerExtensionDetail}
          />

          {/* Manage Financing - Secured Inventory update - 재고 담보 목록 */}
          <Route
            exact
            path={ROUTES_DE.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_LIST}
            component={DealerSecuredInventoryList}
          />

          {/* Manage Financing - Secured Inventory update - 재고 담보 등록 */}
          <Route
            exact
            path={ROUTES_DE.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_REGISTER}
            component={DealerSecuredInventoryRegister}
          />

          {/* Manage Financing - Secured Inventory update - 재고 담보 상세 */}
          <Route
            path={ROUTES_DE.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_DETAIL}
            component={DealerSecuredInventoryDetail}
          />

          {/* View Transaction - Invoice - 등록 완료 송장 목록 */}
          <Route exact path={ROUTES_DE.VIEW_TRANSACTION.INVOICE_LIST} component={DealerInvoiceList} />

          {/* View Transaction - Invoice - 송장 상세 */}
          <Route path={ROUTES_DE.VIEW_TRANSACTION.INVOICE_DETAIL} component={DealerInvoiceDetail} />

          {/* View Transactions - AP - AP 목록 */}
          <Route exact path={ROUTES_DE.VIEW_TRANSACTION.AR_LIST} component={DealerArList} />

          {/* View Transactions - AP - AP 상세 */}
          <Route path={ROUTES_DE.VIEW_TRANSACTION.AR_DETAIL} component={DealerArDetail} />

          {/* View Transaction - Financing - 대출 목록 (필터링 x) */}
          <Route
            exact
            path={ROUTES_DE.VIEW_TRANSACTION.FINANCING_LIST}
            component={DealerViewTransactionFinancingList}
          />

          {/* View Transaction - Financing - 대출 목록 상세 */}
          <Route
            key={location.key}
            path={ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL}
            component={DealerFinancingDetail}
          />

          {/* Company - User - 사용자 목록 */}
          <Route exact path={ROUTES_DE.COMPANY.USER_LIST} component={DealerUserList} />

          {/* Company - User - 사용자 등록 */}
          <Route exact path={ROUTES_DE.COMPANY.USER_REGISTER} component={DealerUserRegister} />

          {/* Company - User - 사용자 상세 */}
          <Route path={ROUTES_DE.COMPANY.USER_DETAIL} component={DealerUserDetail} />

          {/* Company - Agreement - 약정 목록 */}
          <Route exact path={ROUTES_DE.COMPANY.AGREEMENT_LIST} component={DealerAgreementList} />

          {/* Company - Agreement - 약정 상세 */}
          <Route path={ROUTES_DE.COMPANY.AGREEMENT_DETAIL} component={DealerAgreementDetail} />

          {/* My Account - 내 계정 상세 */}
          <Route exact path={ROUTES_DE.MY_ACCOUNT.ACCOUNT_SETTINGS} component={DealerMyAccount} />

          <Route
            path={ROUTES_DE.MY_ACCOUNT.EMAIL_NOTIFICATION_SETTING}
            component={DealerMyAccountEmailNotificationSetting}
          />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
}

export default DealerRouter;
