import type Pageable from 'models/Pageable';
import type { AnchorAgreementVO, AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { formattingToAnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type {
  PotentialPartnerFinancingApplicationDetailVO,
  PotentialPartnerFinancingApplicationDetailVOModel,
} from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import { formattingToPotentialPartnerFinancingApplicationDetailVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import type {
  PotentialPartnerFinancingApplicationVO,
  PotentialPartnerFinancingApplicationVOModel,
} from 'models/vo/PotentialPartnerFinancingApplicationVO';
import { formattingToPotentialPartnerFinancingApplicationVOModel } from 'models/vo/PotentialPartnerFinancingApplicationVO';
import type { PotentialPartnerVO, PotentialPartnerVOModel } from 'models/vo/PotentialPartnerVO';
import { formattingToPotentialPartnerVOModel } from 'models/vo/PotentialPartnerVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierPotentialPartnerFinancingApplicationListRequest } from './request';

/**
 * desc ::  진행 상태별 목록 조회
 * params :: pageable, FinancierPotentialPartnerFinancingApplicationListRequest
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierPotentialPartnerFinancingApplicationListRequest,
): Promise<Pageable<PotentialPartnerFinancingApplicationVOModel[]>> {
  const response = await http.get<Pageable<PotentialPartnerFinancingApplicationVO[]>>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.FINANCING_APPLICATION_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map((data: PotentialPartnerFinancingApplicationVO) =>
      formattingToPotentialPartnerFinancingApplicationVOModel(data),
    ),
  };
}

/**
 * desc ::  제출 요약 정보 조회
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationDetail(
  potentialPartnerFinancingApplicationId: number,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.get<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.FINANCING_APPLICATION_DETAIL(
      potentialPartnerFinancingApplicationId,
    ),
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}

/**
 * desc ::  Rejected → Submitted 변경 전 해당 약정 심사 신청 건 관련 진행 가능 또는 완료된 건이 있는지 확인
 *          없을 시 Exception, 있을 시 VO 반환
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationActivatedApplication(
  potentialPartnerFinancingApplicationId: number,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.get<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.ACTIVATED_APPLICATION(potentialPartnerFinancingApplicationId),
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}

/**
 * desc :: 잠재파트너 정보
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialPartnerDetail(
  potentialPartnerFinancingApplicationId: number,
): Promise<PotentialPartnerVOModel> {
  const response = await http.get<PotentialPartnerVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.POTENTIAL_PARTNER_DETAIL(
      potentialPartnerFinancingApplicationId,
    ),
  });

  return formattingToPotentialPartnerVOModel(response);
}

/**
 * desc :: 관련 앵커 약정 정보
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialAnchorAgreement(
  potentialPartnerFinancingApplicationId: number,
): Promise<AnchorAgreementVOModel> {
  const response = await http.get<AnchorAgreementVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.ANCHOR_AGREEMENT_RELATED_POTENTIAL_PARTNER(
      potentialPartnerFinancingApplicationId,
    ),
  });

  return formattingToAnchorAgreementVOModel(response);
}

/**
 * desc :: 관련 파트너 약정 정보
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialPartnerAgreement(
  potentialPartnerFinancingApplicationId: number,
): Promise<DealerAgreementVOModel> {
  const response = await http.get<DealerAgreementVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.PARTNER_AGREEMENT_RELATED_POTENTIAL_PARTNER(
      potentialPartnerFinancingApplicationId,
    ),
  });

  return formattingToDealerAgreementVOModel(response);
}

/**
 * desc :: 제출 상태 변경 - Submitted → InProgress
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationReviewSubmitted(
  potentialPartnerFinancingApplicationId: number,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.put<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.REVIEW_SUBMITTED_FINANCING_APPLICATION(
      potentialPartnerFinancingApplicationId,
    ),
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}

/**
 * desc :: 제출 상태 변경 - // InProgress → Submitted
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationCancelInProgress(
  potentialPartnerFinancingApplicationId: number,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.put<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.CANCEL_IN_PROGRESS_FINANCING_APPLICATION(
      potentialPartnerFinancingApplicationId,
    ),
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}

/**
 * desc :: 제출 상태 변경 - // InProgress → Rejected
 * params :: potentialPartnerFinancingApplicationId, rejectReason
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationRejectInProgress(
  potentialPartnerFinancingApplicationId: number,
  rejectReason: string,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.put<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.REJECT_IN_PROGRESS_FINANCING_APPLICATION(
      potentialPartnerFinancingApplicationId,
    ),
    data: { rejectReason },
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}

/**
 * desc :: 제출 상태 변경 - // Rejected → Submitted
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationCancelReject(
  potentialPartnerFinancingApplicationId: number,
): Promise<PotentialPartnerFinancingApplicationDetailVOModel> {
  const response = await http.put<PotentialPartnerFinancingApplicationDetailVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATIONS.CANCEL_FINANCING_APPLICATION_REJECTION(
      potentialPartnerFinancingApplicationId,
    ),
  });

  return formattingToPotentialPartnerFinancingApplicationDetailVOModel(response);
}
