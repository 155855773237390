import type React from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { clsx } from 'clsx';

import useOutsideClick from 'hooks/useOutsideClick';

import './Profile.scss';
import useProfileController from './useProfileController';
import LanguageSetter from '../LanguageSetter';
import ThemeSetter from '../ThemeSetter';

export type ProfileStateType = {
  isMyAccountWindowOpen: boolean;
  profileBadgeRef: React.MutableRefObject<null>;
  closeMyAccountWindow(): void;
};

export const ProfileContext = createContext<ProfileStateType | null>(null);
ProfileContext.displayName = 'ProfileContext';

export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfileContext should be used within ProfileContext.Provider');
  }

  return context;
};

const Profile = () => {
  const [isMyAccountWindowOpen, setIsMyAccountWindowOpen] = useState(false);
  const profileBadgeRef = useRef(null);

  const { badgeText } = useProfileController();

  const closeMyAccountWindow = () => {
    setIsMyAccountWindowOpen(false);
  };

  const handleClickMyAccountBadge = () => {
    setIsMyAccountWindowOpen(prevState => !prevState);
  };

  useEffect(() => {
    closeMyAccountWindow();
  }, [useLocation().pathname]);

  const contextValue = {
    isMyAccountWindowOpen,
    profileBadgeRef,
    closeMyAccountWindow,
  };

  return (
    <ProfileContext.Provider value={contextValue}>
      <>
        <button className="header-bar__button button-badge">
          <span
            className={clsx('profile__badge', {
              'profile__badge-active': isMyAccountWindowOpen,
            })}
            onClick={() => {
              handleClickMyAccountBadge();
            }}
            ref={profileBadgeRef}
          >
            {badgeText}
          </span>
        </button>
        <ProfileWindow />
      </>
    </ProfileContext.Provider>
  );
};

const ProfileWindow = () => {
  const { isMyAccountWindowOpen, profileBadgeRef, closeMyAccountWindow } = useProfileContext();

  const {
    t,
    badgeText,
    loginUserInfo: { username, authorityType },
    role,
    myAccountRef,
    handleMyAccountLinkClick,
    handleLogOutLinkClick,
  } = useProfileController();

  useOutsideClick(e => {
    if (e.target !== profileBadgeRef.current) {
      closeMyAccountWindow();
    }
  }, myAccountRef);

  return (
    <div>
      {isMyAccountWindowOpen && (
        <div className="profile__content" ref={myAccountRef}>
          <div className="profile__header">
            <span className="profile__badge--large">{badgeText}</span>
            <div className="profile__info">
              <div className="profile__userName">{username}</div>
              <div className="profile__role">{`${role} | ${t(`code:authority-type.${authorityType}`)}`}</div>
            </div>
          </div>
          <div className="profile__border" />

          <ul className="profile__list">
            <li className="profile__item">
              <div className="profile-setter__wrapper">
                <span className="profile-setter__label">{t('text:Theme')}</span>
                <ThemeSetter />
              </div>
            </li>

            <li className="profile__item">
              <div className="profile-setter__wrapper">
                <span className="profile-setter__label">{t('text:Language')}</span>
                <div className="profile__dropdown__wrapper">
                  <LanguageSetter triggerClassName="profile__dropdown__trigger" />
                </div>
              </div>
            </li>

            <li className="profile__item">
              <div className="my-account__link" onClick={handleMyAccountLinkClick}>
                {t('text:My_Account')}
              </div>
            </li>

            <li className="profile__item">
              <div className="logout__link" onClick={handleLogOutLinkClick}>
                {t('text:Log_Out')}
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Profile;
