import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFinancierDeleteHoliday,
  requestFinancierHolidayDetail,
  requestFinancierHolidayEdit,
} from 'utils/http/api/financier/financier-calendar';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function FinancierHolidaysDetail() {
  // 기본 State
  const { t } = useTranslation(['format']);
  const CURRENT_USER_AUTHORITY_TYPE = getSignIn()?.authorityType;
  const mounted = useMounted();
  const history = useHistory();
  const [editToggle, setEditToggle] = useState<boolean>();
  const { financierCalendarId } = useParams<any>();
  const modal = useModal();

  // 화면 State
  const [holidayDetail, setHolidayDetail] = useState<FinancierCalenderVOModel>();
  const { register, getValues, reset, errors, setError, clearErrors } = useForm<{ description: string }>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  // onMounted
  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const fetchHolidayDetail = await requestFinancierHolidayDetail(financierCalendarId);

      ReactDOM.unstable_batchedUpdates(() => {
        setHolidayDetail(fetchHolidayDetail);
        reset({
          description: fetchHolidayDetail.description,
        });
        setEditToggle(false);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickCancel = (e: any) => {
    e.preventDefault();
    reset({
      description: holidayDetail?.description,
    });
    setEditToggle(prevState => !prevState);
  };

  const onClickSave = async () => {
    const data = getValues('description');
    try {
      requestDTOParser(data);

      await requestFinancierHolidayEdit(financierCalendarId, data);
      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        closeBtnCb: () => fetchAll(),
      });
    } catch (e: any) {
      modal.show(e);

      formErrorHandler<{ description: string }>(e, setError, clearErrors);
    }
  };

  const onClickDelete = async () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_delete_the_holiday?')}
        <br />
        {t('text:Deleted_information_cannot_be_reversed')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => requestDeleteHoliday(),
        closeBtnText: t('text:Cancel'),
      },
    );

    const requestDeleteHoliday = async () => {
      try {
        await requestFinancierDeleteHoliday(financierCalendarId);
        modal.show(<h6>{t('text:The_holiday_information_has_been_deleted')}</h6>, {
          closeBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.HOLIDAYS_LIST),
        });
      } catch (e) {
        modal.show(e);
      }
    };
  };

  return (
    <>
      <BackHeaderTitle
        title={t('format:date', {
          value: holidayDetail?.solarDate,
          key: 'date',
        })}
      />
      <div className="content-area">
        <SectionTitle>
          {CURRENT_USER_AUTHORITY_TYPE === AUTHORITY_TYPE.ADMIN &&
            (editToggle ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button
                size={ButtonSizeEnum.SM}
                onClick={e => {
                  e.preventDefault();
                  setEditToggle(prevState => !prevState);
                }}
                style={{ width: '60px' }}
              >
                {t('text:Edit')}
              </Button>
            ))}
        </SectionTitle>

        <FormBorder editable={editToggle}>
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Description')}
                name="description"
                ref={register}
                disabled={!editToggle}
                requiredOptions={{ required: true }}
                error={errors.description}
                maxLength={500}
              />
            </div>
          </FormContents>
        </FormBorder>
        {CURRENT_USER_AUTHORITY_TYPE === AUTHORITY_TYPE.ADMIN && (
          <div className="flex-end mt-4">
            <Button
              size={ButtonSizeEnum.LG}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
              onClick={onClickDelete}
            >
              {t('text:Delete')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default FinancierHolidaysDetail;
