import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import TdLink from 'components/stateless/Table/TdLink';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE, SUCCESS_INVOICE_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import type { SystemStatisticsOfAnchorAgreementListRequest } from 'utils/http/api/system/statistics-of-anchor-agreements/requests';
import { requestSystemSuccessInvoiceList } from 'utils/http/api/system/success-invoices';
import type { SystemSuccessInvoiceListRequest } from 'utils/http/api/system/success-invoices/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getBlockedStatusText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const SY_INVOICE_LIST_QS_KEY = 'sy-invoice-list';

function SystemInvoiceList() {
  const mounted = useMounted();

  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { pageable, setPageable } = usePageable(SY_INVOICE_LIST_QS_KEY);
  const location = useLocation<SystemStatisticsOfAnchorAgreementListRequest>();
  const filteredOptions = location.state;

  const [successInvoicePageData, setSuccessInvoicePageData] = useState<Pageable<SuccessInvoiceVOModel[]>>();

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Registration_Time'),
    },
    {
      headerText: t('text:Invoice_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Client_Code'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Partner_Client_Code'),
    },
    {
      headerText: t('text:Uploaded_Partner_Name'),
    },
    {
      headerText: t('text:Invoice_Amount'),
    },
    {
      headerText: t('text:Invoice_Issued_Date'),
    },
    {
      headerText: t('text:Payment_Date'),
    },
    {
      headerText: t('text:Active_Status'),
    },
    {
      headerText: t('text:Financing_Disbursement_Status'),
    },
    {
      headerText: t('text:Payment_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const { register, getValues, reset, setValue, control } = useForm<SystemSuccessInvoiceListRequest>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemSuccessInvoiceListRequest>(
        setValue,
        filteredOptions ? filteredOptions : getParsedSearchParams(SY_INVOICE_LIST_QS_KEY).formSearchData,
      );
      fetchSyInvoiceList(pageable.currentPage, pageable.sizePerPage, filteredOptions ? filteredOptions : getValues());
    }
  }, [mounted]);

  const fetchSyInvoiceList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    searchData: SystemSuccessInvoiceListRequest = {},
  ): Promise<void> => {
    try {
      const successInvoicePageData = await requestSystemSuccessInvoiceList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        SY_INVOICE_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSuccessInvoicePageData(successInvoicePageData);
        setPageable(successInvoicePageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();

    reset({
      invoiceNumber: '',
      financierName: '',
      financierCode: undefined,
      referenceNumber: '',
      anchorClientCode: '',
      anchorName: '',
      dealerClientCode: '',
      dealerName: '',
      currencyType: undefined,
      minimumInvoiceAmount: undefined,
      maximumInvoiceAmount: undefined,
      successInvoiceStatus: '',
      confirmedDateFrom: '',
      confirmedDateTo: '',
      invoiceIssuedDateFrom: '',
      invoiceIssuedDateTo: '',
      settlementDateFrom: '',
      settlementDateTo: '',
      blocked: undefined,
      collateralized: undefined,
    });
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();

    const data = getValues();
    fetchSyInvoiceList(1, pageable.sizePerPage, data);
  };

  const paginate = (selectedPageNumber: number, selectedRowCount: number): void => {
    fetchSyInvoiceList(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(SY_INVOICE_LIST_QS_KEY).formSearchData,
    );
  };

  const renderResultTable = (): JSX.Element[] | undefined => {
    return successInvoicePageData && successInvoicePageData.content && successInvoicePageData.content.length !== 0
      ? successInvoicePageData.content.map((item, index) => (
          <tr key={index}>
            <td>
              {tableValueManage(
                item.createdDateTime,
                t('format:datetime', { value: item.createdDateTime, key: 'datetime' }),
              )}
            </td>
            <td>{tableValueManage(item.invoiceNumber)}</td>
            <td>{tableValueManage(item.currencyType)}</td>
            <td>{tableValueManage(item.financierName)}</td>
            <td>{tableValueManage(item.anchorClientCode)}</td>
            <td>{tableValueManage(item.anchorClientName)}</td>
            <td>{tableValueManage(item.dealerClientCode)}</td>
            <td>{tableValueManage(item.dealerClientName)}</td>
            <td className="text-end">
              {tableValueManage(item.invoiceAmount, t('format:number', { value: item.invoiceAmount }))}
            </td>
            <td>
              {tableValueManage(
                item.invoiceIssuedDate,
                t('format:date', { value: item.invoiceIssuedDate, key: 'datetime' }),
              )}
            </td>
            <td>
              {tableValueManage(item.settlementDate, t('format:date', { value: item.settlementDate, key: 'datetime' }))}
            </td>
            <td>{getBlockedStatusText(item.blockedByFinancier)}</td>
            <td>{item.paid ? 'Y' : 'N'}</td>
            <td className={getStatusTextClass('SUCCESS_INVOICE_STATUS', item.successInvoiceStatus)}>
              {tableValueManage(
                item.successInvoiceStatus,
                t(`code:success-invoice-status.${item.successInvoiceStatus}`),
              )}
            </td>
            <TdLink path={ROUTES_SY.VIEW_TRANSACTION.INVOICE_DETAIL_BUILD_PATH(item.successInvoiceId)} />
          </tr>
        ))
      : undefined;
  };

  return (
    <>
      <HeaderTitle title={t('text:Invoice')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="confirmedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="confirmedDateTo" control={control} />
              <SearchLabel label={t('text:Invoice_Number')} />
              <SearchInput name="invoiceNumber" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name="anchorClientCode" ref={register} />
              <SearchLabel label={t('text:Uploaded_Partner_Name')} />
              <SearchInput name="dealerName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput name="dealerClientCode" ref={register} />
              <SearchLabel label={t('text:Invoice_Amount')} />
              <SearchInput name="minimumInvoiceAmount" col={2} placeholder={t('text:from')} ref={register} />
              <SearchInput name="maximumInvoiceAmount" col={2} placeholder={t('text:to')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchLabel label={t('text:Invoice_Issued_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="invoiceIssuedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="invoiceIssuedDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Payment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={control} />
              <SearchLabel label={t('text:Active_Status')} />
              <SearchSelect
                name="blocked"
                selectOptions={getSelectOptions<string>('BLOCK_STATUS', 'ALL', true)}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Payment_Status')} />
              <SearchSelect
                name="successInvoiceStatus"
                selectOptions={getSelectOptions<SUCCESS_INVOICE_STATUS>('SUCCESS_INVOICE_STATUS', 'ALL', true)}
                ref={register}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {successInvoicePageData?.totalElements ? successInvoicePageData?.totalElements : '0'}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemInvoiceList;
