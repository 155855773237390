import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { ArSettlementVOModel } from 'models/vo/ArSettlementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorArSettlementList } from 'utils/http/api/anchor/ar-settlements';
import type { AnchorArSettlementListRequest } from 'utils/http/api/anchor/ar-settlements/requests';
import type { AnchorSuccessArsListRequest } from 'utils/http/api/anchor/success-ars/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const AC_AP_SETTLEMENT_LIST_QS_KEY = 'ac-ap-settlement-list';

function AnchorApSettlementList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { pageable, setPageable } = usePageable(AC_AP_SETTLEMENT_LIST_QS_KEY);
  const [apSettlementPage, setApSettlementPage] = useState<Pageable<ArSettlementVOModel[]>>();
  const arSettlementAscended = useRef(false);

  // useForm
  const { register, getValues, reset, setValue, control } = useForm<AnchorArSettlementListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<AnchorSuccessArsListRequest>(
        setValue,
        getParsedSearchParams(AC_AP_SETTLEMENT_LIST_QS_KEY).formSearchData,
      );
      fetchAcArSettlementList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  async function fetchAcArSettlementList(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: AnchorSuccessArsListRequest,
  ) {
    const searchData = {
      ...data,
      arSettlementAscended: arSettlementAscended.current,
    };

    try {
      const fetchedApSettlementPage = await requestAnchorArSettlementList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        AC_AP_SETTLEMENT_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setApSettlementPage(fetchedApSettlementPage);
        setPageable(fetchedApSettlementPage);
      });
    } catch (e) {
      modal.show(e);
    }
  }

  const fetchAndSetSortedArSettlementList = async () => {
    try {
      arSettlementAscended.current = !arSettlementAscended.current;
      const searchData = {
        ...getParsedSearchParams(AC_AP_SETTLEMENT_LIST_QS_KEY).formSearchData,
        arSettlementAscended: arSettlementAscended.current,
      };

      const sortedApSettlementPage = await requestAnchorArSettlementList(
        pageable.currentPage,
        pageable.sizePerPage,
        searchData,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setApSettlementPage(sortedApSettlementPage);
        setPageable(sortedApSettlementPage);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickSearch = async () => {
    arSettlementAscended.current = false;
    await fetchAcArSettlementList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchAcArSettlementList(
      page,
      sizePerPage,
      getParsedSearchParams(AC_AP_SETTLEMENT_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();
    reset({});
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Settlement_Date'),
      colWidths: 120,
      hasSort: true,
      sortFunc: () => {
        fetchAndSetSortedArSettlementList();
      },
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Total_Number_of_AP'),
      colWidths: 120,
    },
    {
      headerText: t('text:Total_Amount_of_AP'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return apSettlementPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.financierName} />
          <Td data={item.settlementDate} format="date" />
          <Td data={item.currencyType} />
          <Td data={item.totalArCount} format="number" />
          <Td data={item.totalArAmount} format="number" />
          <TdLink
            path={ROUTES_AC.VIEW_TRANSACTION.AP_SETTLEMENT_DETAIL_BUILD_PATH(item.anchorAgreementId)}
            state={{ settlementDate: item.settlementDate }}
          />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:AP_Settlement')} />
      <GuideMessage
        message={[
          t(
            'text:The_scheduled_AP_settlements_of_Anchor_Master_Agreements_that_have_automatic_settlement_are_listed_below',
          ),
          t('text:Check_the_AP_settlements_scheduled_for_each_settlement_date'),
        ]}
      />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
              <SearchLabel label={t('text:Settlement_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {apSettlementPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorApSettlementList;
