import type Pageable from 'models/Pageable';
import type { InvoicePhaseVO, InvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import { formattingToInvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

export async function requestSystemInvoiceApprovalPhasesList(
  invoiceSummaryId: number,
): Promise<Pageable<InvoicePhaseVOModel[]>> {
  const response = await http.get<Pageable<InvoicePhaseVO[]>>({
    url: API_SY.INVOICE_APPROVAL_PHASES.INVOICE_APPROVAL_PHASE_LIST,
    data: {
      pageNumber: 1,
      rowCount: 10,
      invoiceSummaryId: invoiceSummaryId,
    },
  });

  const invoicePhasesData = response.content.map(invoicePhases => formattingToInvoicePhaseVOModel(invoicePhases));

  return {
    ...response,
    content: invoicePhasesData,
  };
}
