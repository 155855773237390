import type Pageable from 'models/Pageable';
import type { InvoicePhaseVO, InvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import { formattingToInvoicePhaseVOModel } from 'models/vo/InvoicePhaseVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';

// 송장결재 목록 조회
export async function requestInvoicePhasesList(
  pageNumber: number,
  pageSize: number,
  invoiceSummaryId?: number,
): Promise<Pageable<InvoicePhaseVOModel[]>> {
  const response = await http.get<Pageable<InvoicePhaseVO[]>>({
    url: API_DE.INVOICE_APPROVAL_PHASES.INVOICE_PHASE_LIST,
    data: {
      pageNumber,
      pageSize,
      invoiceSummaryId,
    },
  });
  const invoicePhasesList: InvoicePhaseVOModel[] = response.content.map((data: InvoicePhaseVO) =>
    formattingToInvoicePhaseVOModel(data),
  );
  const invoicePhasesPage: Pageable<InvoicePhaseVOModel[]> = {
    ...response,
    content: invoicePhasesList,
  };

  return invoicePhasesPage;
}
