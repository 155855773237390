import { useTranslation } from 'react-i18next';

import { isNull } from 'lodash-es';

import Form from 'components/stateless/Form';
import { RadioGroup } from 'components/stateless/Form/RadioComponents';
import Radio from 'components/stateless/Form/RadioComponents/Radio';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';

import useAutomaticFinancingController from './useAutomaticFinancingController';

const AutomaticFinancing = () => {
  const { t } = useTranslation(['format']);

  const {
    register,
    errors,
    isEditableField,
    hasLoanOption,
    canAutoApproval,
    handleHasLoanOptionChange,
    handleCanAutoApprovalChange,
    getReadOnlyValue,
  } = useAutomaticFinancingController();

  return (
    <>
      <SubTitle>{t('text:AUTOMATIC_FINANCING')}</SubTitle>
      <Content>
        <input name="loanOption" type="hidden" ref={register} />
        <Row>
          <Form.Control required name="loanOption">
            <Cell>
              <Form.Label position="top" required>
                {t('text:Partner_s_Financing_Application')}
              </Form.Label>
              <Form.Description>
                {t(
                  'text:If_automatic_financing_is_allowed_a_financing_application_will_be_automatically_created_and_submitted_with_the_maximum_Financing_to_Invoice_Ratio_by_the_platform_when_the_invoice_is_registered',
                )}
              </Form.Description>
            </Cell>
            <RadioGroup
              name="hasLoanOption"
              onChange={handleHasLoanOptionChange}
              isEditable={isEditableField}
              fetchedReadOnly={getReadOnlyValue('loanOption')}
              stateValue={String(hasLoanOption)}
              error={errors?.loanOption}
              showError={isNull(hasLoanOption)}
            >
              <Radio
                label={t('text:Automatic_Application')}
                value="true"
                render={(_, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Form.Control name="loanOption">
                          <Cell>
                            <Form.Label required>{t('text:Financier_s_Financing_Approval')}</Form.Label>
                            <Form.Description>
                              {t('text:If_allowed_the_approval_will_also_be_processed_automatically')}
                            </Form.Description>
                          </Cell>
                          <Cell>
                            <RadioGroup
                              name="canAutoApproval"
                              onChange={handleCanAutoApprovalChange}
                              isEditable={isEditableField}
                              fetchedReadOnly={getReadOnlyValue('loanOption') || isParentRadioUnChecked}
                              stateValue={String(canAutoApproval)}
                              error={errors?.loanOption}
                              showError={!isNull(hasLoanOption) && isNull(canAutoApproval)}
                            >
                              <Radio label={t('text:Automatic_Approval')} value="true" />
                              <Radio label={t('text:Manual_Approval_Only')} value="false" />
                            </RadioGroup>
                          </Cell>
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
              <Radio label={t('text:Manual_Application_Only')} value="false" />
            </RadioGroup>
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default AutomaticFinancing;
