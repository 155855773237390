/* eslint-disable react/display-name */
import type { ElementType, FC, JSX, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import type { NewObject } from 'types';

export const passPropsToChildren = <T,>(children: ReactNode, props: Partial<T>, Wrapper?: ElementType) =>
  Children.map(children, child => {
    if (isValidElement(child)) {
      const newProps = Object.entries(props).map(([key, prop]) => {
        if (typeof prop !== 'function') return [key, prop];
        if (prop(child)) return [key, prop(child)];
        else return [null];
      });

      if (Wrapper) return <Wrapper>{cloneElement(child, { ...Object.fromEntries(newProps), ...child.props })}</Wrapper>;
      else return cloneElement(child, { ...Object.fromEntries(newProps), ...child.props });
    }

    if (Wrapper) return <Wrapper>child</Wrapper>;
    else return child;
  });

interface WithProviderPropsType<T> {
  Component: FC<T>;
  Provider: FC<ProviderType> | FC<ProviderType>[];
}

interface ProviderType {
  children: JSX.Element;
}

const composeProviders = (providers: FC<ProviderType>[]) => {
  return providers.reduce((ComposedProvider, Provider) => {
    return ({ children }) => (
      <ComposedProvider>
        <Provider>{children}</Provider>
      </ComposedProvider>
    );
  });
};

const wrapWithSingleProvider = <T extends NewObject>(Component: FC<T>, Provider: FC<ProviderType>) => {
  return (props: T) => (
    <Provider>
      <Component {...props} />
    </Provider>
  );
};

const wrapWithMultipleProviders = <T extends NewObject>(Component: FC<T>, Providers: FC<ProviderType>[]) => {
  const ComposedProvider = composeProviders(Providers);

  return (props: T) => (
    <ComposedProvider>
      <Component {...props} />
    </ComposedProvider>
  );
};

const WithProvider = <T extends NewObject>({ Component, Provider }: WithProviderPropsType<T>) => {
  if (Array.isArray(Provider)) {
    return wrapWithMultipleProviders(Component, Provider);
  }

  return wrapWithSingleProvider(Component, Provider);
};

export default WithProvider;
