import { AUTHORITY_TYPE, ROLE, stringToEnum } from 'enums';

import { formattingToFinancierInterfaceSettingVOModel } from './vo/FinancierInterfaceSettingVO';

import type { FinancierInterfaceSettingVO, FinancierInterfaceSettingVOModel } from './vo/FinancierInterfaceSettingVO';

export interface SignInResponse {
  password: null;
  username: string;
  name: string;
  authorities: { authority: string }[];
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
  enterpriseId: number;
  userId: number;
  financierInterfaceSettingList: FinancierInterfaceSettingVO[];
  authorityType: string;
  branchId: number;
}

export interface SignInModel extends SignInResponse {
  authorities: { authority: ROLE }[];
  financierInterfaceSettingList: FinancierInterfaceSettingVOModel[];
  authorityType: AUTHORITY_TYPE;
  signInTime?: Date;
}

export function formattingToSignInModel(data: SignInResponse): SignInModel {
  data.financierInterfaceSettingList = data.financierInterfaceSettingList ? data.financierInterfaceSettingList : [];
  const financierInterfaceSettingList: FinancierInterfaceSettingVOModel[] = [];
  for (const obj of data.financierInterfaceSettingList) {
    financierInterfaceSettingList.push(formattingToFinancierInterfaceSettingVOModel(obj));
  }

  const authorities: { authority: ROLE }[] = [];
  for (const obj of data.authorities) {
    authorities.push({ authority: stringToEnum(ROLE, obj.authority) });
  }

  return {
    ...data,
    authorities: authorities,
    financierInterfaceSettingList: financierInterfaceSettingList,
    authorityType: stringToEnum(AUTHORITY_TYPE, data.authorityType),
  };
}
