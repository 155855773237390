import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';

import { useAnchorClientInfoContext } from './AnchorClientInfoContext';

const useAnchorClientInfoViewModel = () => {
  const { anchorClientInfo, updateAnchorClientInfo } = useAnchorClientInfoContext();

  const convertToAnchorClientInfoType = (data: FinancierClientVOModel) => {
    return {
      anchorClientId: data.financierClientId,
      anchorClientCode: data.financierClientCode,
      anchorClientName: data.financierClientName,
      anchorUserList: [],
      anchorClientAutoSignUpForAnchorAdminOfVendorFinance: data.autoSignUpForAnchorAdminOfVendorFinance,
    };
  };

  const updateAnchorUserList = (anchorUserList: AnchorUserVOModel[]) => {
    updateAnchorClientInfo({
      anchorUserList,
    });
  };

  return { anchorClientInfo, convertToAnchorClientInfoType, updateAnchorClientInfo, updateAnchorUserList };
};

export default useAnchorClientInfoViewModel;
