import type Pageable from 'models/Pageable';
import { formattingToWaitingAnchorFinancingOptionDetailVOModel } from 'models/vo/WaitingAnchorFinancingOptionDetailVO';
import type {
  WaitingAnchorFinancingOptionDetailVO,
  WaitingAnchorFinancingOptionDetailVOModel,
} from 'models/vo/WaitingAnchorFinancingOptionDetailVO';
import { formattingToWaitingAnchorFinancingOptionVOModel } from 'models/vo/WaitingAnchorFinancingOptionVO';
import type {
  WaitingAnchorFinancingOptionVO,
  WaitingAnchorFinancingOptionVOModel,
} from 'models/vo/WaitingAnchorFinancingOptionVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { UpdateFiWaitingAnchorFinancingOptionDTO } from './requests';
import type { CreateFiWaitingAnchorFinancingOptionDTO } from './requests';
import type { SearchFiWaitingAnchorFinancingOptionDTO } from './requests';

export async function requestFinancierWaitingAnchorFinancingOptionList(
  pageNumber: number,
  rowCount: number,
  SearchFiWaitingAnchorFinancingAgreementDTO: SearchFiWaitingAnchorFinancingOptionDTO,
): Promise<Pageable<WaitingAnchorFinancingOptionVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorFinancingOptionVO[]>>({
    url: API_FI.WAITING_ANCHOR_FINANCING_OPTIONS.WAITING_ANCHOR_FINANCING_OPTIONS,
    data: {
      pageNumber,
      rowCount,
      ...SearchFiWaitingAnchorFinancingAgreementDTO,
    },
  });

  const waitingAnchorAgreementList = response.content.map(data =>
    formattingToWaitingAnchorFinancingOptionVOModel(data),
  );

  return {
    ...response,
    content: waitingAnchorAgreementList,
  };
}

export async function requestRegisterFinancierWaitingAnchorFinancingOption(
  CreateFiWaitingAnchorFinancingAgreementDTO: CreateFiWaitingAnchorFinancingOptionDTO,
): Promise<WaitingAnchorFinancingOptionVOModel> {
  const response = await http.post<WaitingAnchorFinancingOptionVO>({
    url: API_FI.WAITING_ANCHOR_FINANCING_OPTIONS.WAITING_ANCHOR_FINANCING_OPTIONS,
    data: CreateFiWaitingAnchorFinancingAgreementDTO,
  });

  return formattingToWaitingAnchorFinancingOptionVOModel(response);
}

export async function requestFinancierWaitingAnchorFinancingOptionDetail(
  waitingAnchorFinancingOptionId: number,
): Promise<WaitingAnchorFinancingOptionDetailVOModel> {
  const response = await http.get<WaitingAnchorFinancingOptionDetailVO>({
    url: API_FI.WAITING_ANCHOR_FINANCING_OPTIONS.WAITING_ANCHOR_FINANCING_OPTIONS_DETAIL(
      waitingAnchorFinancingOptionId,
    ),
  });

  return formattingToWaitingAnchorFinancingOptionDetailVOModel(response);
}

export async function requestUpdateFinancierWaitingAnchorFinancingOption(
  waitingAnchorFinancingOptionId: number,
  UpdateFiWaitingAnchorFinancingAgreementDTO: UpdateFiWaitingAnchorFinancingOptionDTO,
): Promise<WaitingAnchorFinancingOptionVOModel> {
  const response = await http.put<WaitingAnchorFinancingOptionVO>({
    url: API_FI.WAITING_ANCHOR_FINANCING_OPTIONS.WAITING_ANCHOR_FINANCING_OPTIONS_DETAIL(
      waitingAnchorFinancingOptionId,
    ),
    data: UpdateFiWaitingAnchorFinancingAgreementDTO,
  });

  return formattingToWaitingAnchorFinancingOptionVOModel(response);
}
export async function requestCancelWaitingAnchorFinancingOption(
  waitingAnchorFinancingOptionId: number,
): Promise<WaitingAnchorFinancingOptionVOModel> {
  const response = await http.put<WaitingAnchorFinancingOptionVO>({
    url: API_FI.WAITING_ANCHOR_FINANCING_OPTIONS.CANCEL_WAITING_ANCHOR_FINANCING_OPTIONS(
      waitingAnchorFinancingOptionId,
    ),
  });

  return formattingToWaitingAnchorFinancingOptionVOModel(response);
}

export async function requestReturnWaitingAnchorFinancingOption(
  waitingAnchorFinancingOptionId: number,
  returnReason: string,
): Promise<WaitingAnchorFinancingOptionVOModel> {
  const response = await http.put<WaitingAnchorFinancingOptionVO>({
    url: API_FI.WAITING_ANCHOR_FINANCING_OPTIONS.RETURN_WAITING_ANCHOR_FINANCING_OPTIONS(
      waitingAnchorFinancingOptionId,
    ),
    data: {
      returnReason,
    },
  });

  return formattingToWaitingAnchorFinancingOptionVOModel(response);
}

export async function requestApproveWaitingAnchorFinancingOption(
  waitingAnchorFinancingOptionId: number,
): Promise<WaitingAnchorFinancingOptionVOModel> {
  const response = await http.put<WaitingAnchorFinancingOptionVO>({
    url: API_FI.WAITING_ANCHOR_FINANCING_OPTIONS.APPROVE_WAITING_ANCHOR_FINANCING_OPTIONS(
      waitingAnchorFinancingOptionId,
    ),
  });

  return formattingToWaitingAnchorFinancingOptionVOModel(response);
}
