import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import type { CommissionSpreadType } from 'utils/http/api/financier/ar-commission-settings/requests';

import { PROGRAM_CHARGE_RATE_FIELD_NAME } from './index';

import type { FiProgramChargeRegisterFormType } from '../../register';

export function useProgramChargeRateFormController() {
  const methods = useFormContext<FiProgramChargeRegisterFormType>();

  const { control } = methods;

  const { fields, append, remove } = useFieldArray<CommissionSpreadType>({
    control,
    name: PROGRAM_CHARGE_RATE_FIELD_NAME,
  });

  const addRow = useCallback(() => {
    append({
      minimumAmountRange: null,
      rate: null,
    });
  }, [append]);

  const handleAddRowClick = () => addRow();

  const handleDeleteLastRowClick = () => {
    remove(fields.length - 1);
  };

  return {
    methods,
    fields,
    handleAddRowClick,
    handleDeleteLastRowClick,
  };
}
