import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonVariantEnum } from './Button';

interface DownloadButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  btnText?: string;
}
function DownloadButton({ disabled = false, onClick, btnText }: DownloadButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      variant={ButtonVariantEnum.OUTLINED}
      color={ButtonColorEnum.SECONDARY}
      disabled={disabled}
      onClick={onClick}
    >
      <>
        <FontAwesomeIcon icon={faFileDownload} className="me-2" />
        {btnText ? btnText : t('text:Download')}
      </>
    </Button>
  );
}

export default DownloadButton;
