import type React from 'react';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import type { ControllerRenderProps, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

import CustomHeader from './CustomHeader';
import './ReactDatePicker.scss';
import ReactDatePickerInput from './ReactDatePickerInput';

import type { SizeType } from '..';

interface ReactDatePickerProps {
  field: ControllerRenderProps<Record<string, any>>;
  minDate?: Date;
  maxDate?: Date;
  excludeDates?: Date[];
  disabled?: boolean;
  className: string;
  fieldSize?: SizeType;
  readOnly?: boolean;
  error?: FieldError;
  placeholder?: string;
  onChange?: (e: React.SyntheticEvent<any, Event> | undefined) => void;
}

const ReactDatePicker = ({
  field,
  minDate,
  maxDate,
  excludeDates,
  disabled,
  className = '',
  fieldSize,
  readOnly = false,
  placeholder,
  error,
  onChange,
}: ReactDatePickerProps) => {
  const { t } = useTranslation(['format']);
  const today = new Date();
  const isTodaySelectableDate = dayjs(today).isBetween(minDate, maxDate, 'day', '[]');
  const isTodayNotExcluded = !excludeDates || !excludeDates.includes(today);
  const isTodayValidDate = isTodaySelectableDate && isTodayNotExcluded;

  useEffect(() => {
    if (isNil(field.value)) {
      field.onChange('');
    }
  }, [field.value]);

  const dateHandler = (date: Date | null) => {
    date ? field.onChange(t('format:original-date', { value: date, key: 'original-date' })) : field.onChange('');
  };

  const handleOnChange = (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => {
    dateHandler(date);
    if (onChange) onChange(event);
  };

  const handleSelectToday = () => {
    if (isTodayValidDate) {
      field.onChange(t('format:original-date', { value: today, key: 'original-date' }));
      field.ref.current?.setOpen(false); // 캘린더 창 닫기
    }
  };

  const formatWeekDay = (date: string) => {
    const day = new Date(date).getDay();
    const dayNames = [
      t('text:Sun'),
      t('text:Mon'),
      t('text:Tue'),
      t('text:Wed'),
      t('text:Thu'),
      t('text:Fri'),
      t('text:Sat'),
    ];

    return dayNames[day];
  };

  return (
    <DatePicker
      ref={field.ref}
      renderCustomHeader={headerProps => (
        <CustomHeader {...headerProps} handleSelectToday={handleSelectToday} todayDisabled={!isTodayValidDate} />
      )}
      fixedHeight
      formatWeekDay={formatWeekDay}
      useWeekdaysShort={true}
      showPopperArrow={false}
      placeholderText={disabled ? '' : placeholder || t('date')}
      onChange={(date, e) => handleOnChange(date, e)}
      onBlur={field.onBlur}
      name={field.name}
      className={className}
      dateFormat={t('react-date-picker')}
      closeOnScroll={true}
      selected={field.value ? new Date(field.value) : null}
      customInput={<ReactDatePickerInput fieldSize={fieldSize} error={error} />}
      minDate={minDate}
      maxDate={maxDate}
      excludeDates={excludeDates}
      disabled={disabled}
      readOnly={readOnly}
      disabledKeyboardNavigation
    />
  );
};

export default ReactDatePicker;
