import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useZoomSetterController from './useZoomSetterController';
import HeaderBarDropDown from '../../HeaderBarDropDown';

const ZoomSetter = () => {
  const { getZoomDropdownOptions, defaultValue } = useZoomSetterController();

  return (
    <HeaderBarDropDown value={defaultValue}>
      <HeaderBarDropDown.Trigger className="header-bar__dropdown__zoom-setter-trigger">
        <FontAwesomeIcon icon={faCaretDown} fontSize={8} />
      </HeaderBarDropDown.Trigger>
      <HeaderBarDropDown.Items items={getZoomDropdownOptions()} />
    </HeaderBarDropDown>
  );
};

export default ZoomSetter;
