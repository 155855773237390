import Big from 'big.js';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { isNil } from 'lodash-es';

import { BANK_CODE_APPROVAL_TYPE } from 'enums';
import {
  BASIS_INTEREST_TYPE,
  COMMON_APPROVAL_TYPE,
  CURRENCY_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
  LOAN_OPTION,
  LOAN_TERM_TYPE,
} from 'enums';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { getRegNumberPattern } from 'utils/validation/regExp';

export const isDecimalCurrencyType = (currencyType?: CURRENCY_TYPE) => {
  switch (currencyType) {
    case CURRENCY_TYPE.USD:
    case CURRENCY_TYPE.INR:
      return true;
    default:
      return false;
  }
};

// 통화, 최대 길이 패턴 적용
export const invoiceOrArAmountInputValidate = (
  amount: number,
  currencyType: CURRENCY_TYPE | undefined,
  amountTextType: 'Invoice' | 'AR' | 'AP' = 'Invoice',
): undefined | string => {
  const value = String(amount);
  const { REG_MAX_INTEGER_LENGTH_PATTERN, REG_DECIMAL_PATTERN, REG_INTEGER_PATTERN } = getRegNumberPattern(
    'bigNumber',
    currencyType,
  );

  const notNumberMessage = `text:Fail_The_${amountTextType}_Amount_must_consist_only_of_numbers`;
  const exceedMaxLengthMessage = `text:Fail_The_${amountTextType}_Amount_is_too_large`;
  const exceedTwoDecimalPointMessage = `text:Fail_The_${amountTextType}_Amount_can_have_up_to_2_decimals`;
  const notAllowedDecimalMessage = `text:Fail_The_${amountTextType}_Amount_cannot_have_decimals`;

  if (isNaN(value as unknown as number) || value.includes(' ')) return notNumberMessage;
  else {
    if (!REG_MAX_INTEGER_LENGTH_PATTERN.test(value)) return exceedMaxLengthMessage;
    else {
      if (isNil(currencyType)) return undefined;

      if (isDecimalCurrencyType(currencyType))
        return !REG_DECIMAL_PATTERN.test(value) ? exceedTwoDecimalPointMessage : undefined;
      // USD, INR, IDR 소수점 허용, 소수 2자리까지
      else return !REG_INTEGER_PATTERN.test(value) ? notAllowedDecimalMessage : undefined; // 나머지는 숫자만 가능하게
    }
  }
};

export const invoiceOrArSettlementDateInputValidate = (
  today: number[],
  targetDay: string,
  holidayList: string[],
  minDate: Date,
  maxDate: Date,
): undefined | string => {
  const pastDateMessage = 'text:Fail_The_date_in_the_past_cannot_be_selected';
  const holidayDateMessage = 'text:Fail_The_entered_settlement_date_is_a_holiday_and_cannot_be_selected';
  const minDateMessage = 'text:Fail_The_settlement_date_must_not_be_before_DATE';
  const maxDateMessage = 'text:Fail_The_settlement_date_must_not_exceed_DATE';
  if (new Date(today[0], today[1], today[2]).getTime() > new Date(targetDay).getTime()) return pastDateMessage;
  else if (holidayList.includes(targetDay)) return holidayDateMessage;
  else if (minDate.getTime() > new Date(targetDay).getTime()) return minDateMessage;
  else if (maxDate.getTime() < new Date(targetDay).getTime()) return maxDateMessage;
};

export const getTotalInterestRateByStaticsOfDealerAgreement = (data: StatisticsOfDealerAgreementVOModel): string => {
  // return formatted value
  const setNullToZero = (value: number): number => {
    if (isNil(value)) return 0;
    else return value;
  };
  const { basisInterestType } = data;
  const { basisInterestBankCode } = data;
  const basisInterestRate = setNullToZero(data.basisInterestRate);
  const creditInterestRate = setNullToZero(data.creditInterestRate);
  const preferentialInterestRate = setNullToZero(data.preferentialInterestRate);
  const basisInterestTerm = data.basisInterestTerm ?? '-';
  const formattedTotalInterestRate = (totalInterestRate: BigNumber) => {
    return `${i18n.t('format:number', { value: totalInterestRate })}%`;
  };

  const calculatorBigNumber = new CalculatorBigNumber();

  if (basisInterestType === BASIS_INTEREST_TYPE.FIXED) {
    // basisInterestRate + creditInterestRate + preferentialInterestRate;
    const totalInterestRate = calculatorBigNumber
      .add(basisInterestRate)
      .add(creditInterestRate)
      .add(preferentialInterestRate)
      .get();

    return formattedTotalInterestRate(totalInterestRate);
  } else if (basisInterestType === BASIS_INTEREST_TYPE.CUSTOM) {
    // creditInterestRate + preferentialInterestRate
    const totalInterestRate = calculatorBigNumber.add(creditInterestRate).add(preferentialInterestRate).get();

    return `${basisInterestBankCode ?? BASIS_INTEREST_TYPE.CUSTOM} ( ${basisInterestTerm} ) + ${
      Big(totalInterestRate).lt(0)
        ? `( ${formattedTotalInterestRate(totalInterestRate)} )`
        : formattedTotalInterestRate(totalInterestRate)
    }`;
  } else {
    // creditInterestRate + preferentialInterestRate
    const totalInterestRate = calculatorBigNumber.add(creditInterestRate).add(preferentialInterestRate).get();

    return `${basisInterestType} ( ${basisInterestTerm} ) + ${
      Big(totalInterestRate).lt(0)
        ? `( ${formattedTotalInterestRate(totalInterestRate)} )`
        : formattedTotalInterestRate(totalInterestRate)
    }`;
  }
};

// 대출 신청 STEP 1,2, 대출 상세페이지 - expectedInterestRate
interface InterestTypes {
  basisInterestRate: number;
  creditInterestRate: number;
  termSpreadRate: number;
  preferentialInterestRate: number;
}

interface FormattedInterestTypes extends InterestTypes {
  showExpectedTotalInterestRate: boolean;
  hasBasisInterest?: boolean;
  basisInterestBankCode: string;
}

export const getExpectedInterestRate = (interests: InterestTypes): string => {
  const calculatorBigNumber = new CalculatorBigNumber();

  const { basisInterestRate, creditInterestRate, termSpreadRate, preferentialInterestRate } = interests;
  // basisInterestRate + creditInterestRate + termSpreadRate + preferentialInterestRate
  const totalInterestRate = calculatorBigNumber
    .add(basisInterestRate)
    .add(creditInterestRate)
    .add(termSpreadRate)
    .add(preferentialInterestRate)
    .get();

  return totalInterestRate;
};

export const wrappingGetInterestRate = ({
  showExpectedTotalInterestRate,
  hasBasisInterest,
  basisInterestBankCode,
  basisInterestRate,
  termSpreadRate,
  creditInterestRate,
  preferentialInterestRate,
}: FormattedInterestTypes): string => {
  const expectedInterestRate = getExpectedInterestRate({
    basisInterestRate,
    creditInterestRate,
    termSpreadRate,
    preferentialInterestRate,
  });
  const formattedInterestRate = i18n.t('format:number', { value: expectedInterestRate }) + '%';

  return showExpectedTotalInterestRate && hasBasisInterest
    ? formattedInterestRate
    : `${basisInterestBankCode ?? ''} + ${formattedInterestRate}`;
};

export const wrappingGetInterestRateWithOutConditionOfHasBasisInterest = ({
  showExpectedTotalInterestRate,
  basisInterestBankCode,
  basisInterestRate,
  termSpreadRate,
  creditInterestRate,
  preferentialInterestRate,
}: FormattedInterestTypes): string => {
  const expectedInterestRate = getExpectedInterestRate({
    basisInterestRate,
    creditInterestRate,
    termSpreadRate,
    preferentialInterestRate,
  });
  const formattedInterestRate = i18n.t('format:number', { value: expectedInterestRate }) + '%';

  return showExpectedTotalInterestRate
    ? formattedInterestRate
    : `${basisInterestBankCode ?? ''} + ${formattedInterestRate}`;
};

// PA,FI,SY 대출 상세, Extension 상세 - Total Interest Rate(yearly)
export const getTotalInterestRate = (
  disbursedBasisInterestRate: number,
  disbursedTotalInterestRateWithoutBasis: number,
): string => {
  const calculatorBigNumber = new CalculatorBigNumber();

  return `${i18n.t('format:number', {
    value: calculatorBigNumber.add(disbursedBasisInterestRate).add(disbursedTotalInterestRateWithoutBasis).get(),
  })}%`;
};

// AC, FI, SY Total Remaining Limit (Only channel)
export const getChannelTotalRemainingLimit = (
  startDate: string,
  endDate: string,
  remainingLimit: BigNumber,
  totalRemainingLimit: BigNumber,
  excelExport?: boolean,
) => {
  const today = dayjs().format('YYYY-MM-DD');

  if (today >= startDate && today <= endDate) {
    return excelExport
      ? totalRemainingLimit
      : i18n.t('format:number', {
          value: totalRemainingLimit,
        });
  } else {
    return excelExport
      ? remainingLimit
      : i18n.t('format:number', {
          value: remainingLimit,
        });
  }
};

export const isNoneLoanLimitCheckType = (loanLimitCheckType: keyof typeof LOAN_LIMIT_CHECK_TYPE): boolean => {
  return loanLimitCheckType === LOAN_LIMIT_CHECK_TYPE.NONE;
};

export const getFinancingLimitValue = (
  loanLimitCheckType: keyof typeof LOAN_LIMIT_CHECK_TYPE,
  loanLimitAmount: BigNumber,
  currencyType?: CURRENCY_TYPE,
): string => {
  return isNoneLoanLimitCheckType(loanLimitCheckType)
    ? '-'
    : `${i18n.t('format:number', { value: loanLimitAmount })} ${currencyType ?? ''}`;
};

interface CalculateLimitAmountTypes {
  loanLimitAmount?: BigNumber;
  partiallyRepaidPrincipalAmount?: BigNumber;
  requestedLoanAmount?: BigNumber;
}

export const getRemainingApplicableLimitValue = (
  loanLimitCheckType: keyof typeof LOAN_LIMIT_CHECK_TYPE,
  amount: CalculateLimitAmountTypes,
  currencyType?: CURRENCY_TYPE,
): string => {
  const calculatorBigNumber = new CalculatorBigNumber();

  const { loanLimitAmount, partiallyRepaidPrincipalAmount, requestedLoanAmount } = amount;

  // loanLimitAmount + partiallyRepaidPrincipalAmount - requestedLoanAmount
  return isNoneLoanLimitCheckType(loanLimitCheckType)
    ? '-'
    : `${i18n.t('format:number', {
        value: calculatorBigNumber
          .add(loanLimitAmount)
          .add(partiallyRepaidPrincipalAmount)
          .minus(requestedLoanAmount)
          .get(),
      })} ${currencyType ?? ''}`;
};

// FI 앵커 약정 loanOption Select Default value
export const getSelectedLoanOption = (loanOption?: LOAN_OPTION) => {
  return loanOption !== LOAN_OPTION.NONE ? loanOption : undefined;
};

// FI 파트너 약정 loanTermType Select Default value
export const getSelectedLoanTermType = (loanTermType?: LOAN_TERM_TYPE) => {
  return loanTermType === LOAN_TERM_TYPE.RANGE || isNil(loanTermType) ? LOAN_TERM_TYPE.DAILY : loanTermType;
};

export const getWaitingForApprovalCount = (
  approvalTypes?: (COMMON_APPROVAL_TYPE | BANK_CODE_APPROVAL_TYPE)[],
  totalCountWithoutCancel?: number,
) => {
  const approvalTypeAsString = approvalTypes as unknown as string;

  const isOnlyCancelStatus =
    approvalTypeAsString === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL ||
    approvalTypeAsString === BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL;

  return isOnlyCancelStatus ? 0 : totalCountWithoutCancel ?? 0;
};
