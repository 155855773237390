import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { LOAN_STATUS } from 'enums';
import { ModalSize } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';
import useFinancingListViewModel from '../../../models/financingList/useFinancingListViewModel';

const useFinancingListController = () => {
  const { t } = useTranslation(['format']);
  const { show: showModal } = useModal();

  const { dealerAgreementId } = useExtraInformationViewModel();
  const { financingList, financingListPageable, fetchFinancingList } = useFinancingListViewModel();

  const handleFinancingStatusQuestionButtonClick = () => {
    showModal(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const FINANCING_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Reference_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:AR_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Disbursement_Amount'),
      colWidths: 100,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 80,
      hasStatusDescription: true,
      showStatusDescriptionFunc: handleFinancingStatusQuestionButtonClick,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const financingListPaginate = async (selectedPageNumber: number, selectedRowCount: number) => {
    await fetchFinancingList(dealerAgreementId, selectedPageNumber, selectedRowCount);
  };

  const resetSection = useCallback(async () => {
    if (isNil(dealerAgreementId)) return;

    await fetchFinancingList(dealerAgreementId);
  }, [dealerAgreementId]);

  useEffect(() => {
    resetSection();
  }, [resetSection]);

  return { FINANCING_LIST_TABLE_HEADERS, financingList, financingListPageable, financingListPaginate };
};

export default useFinancingListController;
