import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useLanguageSetterController from './useLanguageSetterController';
import HeaderBarDropDown from '../../HeaderBarDropDown';

interface LanguageSetterPropsType {
  triggerClassName: string;
}

const LanguageSetter = ({ triggerClassName }: LanguageSetterPropsType) => {
  const { HEADER_BAR_LANGUAGE_SETTER_OPTIONS, defaultLanguage } = useLanguageSetterController();

  return (
    <HeaderBarDropDown value={defaultLanguage}>
      <HeaderBarDropDown.Trigger className={triggerClassName}>
        <FontAwesomeIcon icon={faAngleDown} fontSize={13.5} />
      </HeaderBarDropDown.Trigger>
      <HeaderBarDropDown.Items textAlign="end" items={HEADER_BAR_LANGUAGE_SETTER_OPTIONS} />
    </HeaderBarDropDown>
  );
};

export default LanguageSetter;
