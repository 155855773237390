import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import type {
  EmailNotificationSettingState,
  UpdateUserEmailSettingListType,
  UserEmailSettingGroupedMap,
  UserEmailSettingTypeFE,
} from 'components/email-notification-setting/types';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder, FormContents, FormSubtitle } from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ENTERPRISE_TYPE } from 'enums';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import Badge from '../stateless/Badge/Badge';

import type { UserEmailSettingSubDivisionType } from './types';

const EmailNotificationSettingForm = ({
  title,
  emailSettingData,
  updateEmailSettingHandler,
}: {
  title: string;
  emailSettingData: UserEmailSettingGroupedMap | undefined;
  updateEmailSettingHandler(updateUserEmailSettingList: UpdateUserEmailSettingListType[]): Promise<void>;
}) => {
  const modal = useModal();
  const { t } = useTranslation();

  const USER_EMAIL_SETTING_SUB_DIVISION_TEXT: { [key in UserEmailSettingSubDivisionType]: string } = {
    COMMON: t('text:Master_Agreement_Management'),
    PARTNER_MANAGEMENT: t('text:Partner_Management'),
    AR_REGISTRATION: t('text:AR_Registration'),
    AR_LOAN: t('text:Financing'),
    INVOICE_REGISTRATION: t('text:Invoice_Registration'),
    INVOICE_LOAN: t('text:Financing'),
  };

  const [emailSettingState, setEmailSettingState] = useState<EmailNotificationSettingState>({
    isFormEditable: false,
    selectedAlertGroupSetting: emailSettingData,
  });

  const handleClickEdit = () => {
    setEmailSettingState(prevState => ({ ...prevState, isFormEditable: true }));
  };

  const handleClickCancel = () => {
    modal.show(<h6>{t('text:Would_you_like_to_cancel_the_modification')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        setEmailSettingState({ selectedAlertGroupSetting: emailSettingData, isFormEditable: false });
      },
    });
  };

  const showSaveSuccessModal = () => {
    modal.show(<h6>{t('text:The_settings_have_been_saved_successfully')}</h6>, {
      modalType: ModalType.ALERT,
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        setEmailSettingState(prevState => ({ ...prevState, isFormEditable: false }));
      },
    });
  };

  const handleClickSave = async () => {
    const { selectedAlertGroupSetting } = emailSettingState;
    if (selectedAlertGroupSetting) {
      const updatedFormData = Object.values(selectedAlertGroupSetting)
        .flat()
        ?.filter(item => {
          return !item.readonly;
        })
        .map(item => ({
          alertType: item.alertType,
          emailEnabled: item.emailEnabled,
        }));

      try {
        await updateEmailSettingHandler(updatedFormData);

        showSaveSuccessModal();
      } catch (e) {
        modal.show(e);
      }
    }
  };

  const handleChangeAlertSettingRadio = (
    itemIndex: number,
    newValue: boolean,
    groupKey: UserEmailSettingSubDivisionType,
  ): void => {
    if (emailSettingState.isFormEditable && emailSettingState.selectedAlertGroupSetting) {
      const prevSettings = emailSettingState.selectedAlertGroupSetting;

      const updatedGroupSettings = JSON.parse(JSON.stringify(prevSettings[groupKey]));
      updatedGroupSettings[itemIndex].emailEnabled = newValue;

      const updatedSettings = {
        ...prevSettings,
        [groupKey]: updatedGroupSettings,
      };

      setEmailSettingState(prevState => ({
        ...prevState,
        selectedAlertGroupSetting: updatedSettings,
      }));
    }

    return;
  };

  const renderBadge = (settingItem: UserEmailSettingTypeFE) => {
    const { userEmailSettingId } = settingItem;

    // 유저가 직접세팅하지 않은 경우엔 Default, 그렇지 않으면 Financier
    return (
      <Badge
        content={isNil(userEmailSettingId) ? t('text:Default') : t('text:Financier')}
        variant="square"
        bgColor="OCHER"
        fontColor="LIGHT_YELLOW"
        className="d-flex align-items-center"
        style={{ fontSize: '10px' }}
      />
    );
  };

  return (
    <>
      <SectionTitle title={title}>
        {emailSettingState.isFormEditable ? (
          <>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={handleClickCancel}
            >
              {t('text:Cancel')}
            </Button>
            <Button size={ButtonSizeEnum.SM} onClick={handleClickSave} style={{ width: '60px' }}>
              {t('text:Save')}
            </Button>
          </>
        ) : (
          <Button size={ButtonSizeEnum.SM} onClick={handleClickEdit} style={{ width: '60px' }}>
            {t('text:Edit')}
          </Button>
        )}
      </SectionTitle>
      <FormBorder editable={emailSettingState.isFormEditable}>
        {emailSettingState.selectedAlertGroupSetting &&
          Object.entries(emailSettingState.selectedAlertGroupSetting).map(([key, groupedItems]) => {
            const subDivisionType = key as UserEmailSettingSubDivisionType;

            return (
              <React.Fragment key={key}>
                <FormSubtitle
                  title={USER_EMAIL_SETTING_SUB_DIVISION_TEXT[subDivisionType]}
                  backGroundType={BackGroundType.DarkGray}
                />
                <FormContents>
                  {groupedItems?.map((settingItem, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-6 d-flex align-self-center">
                          <div className="information-form__label m-0">
                            {t(`code:financier-alert-type.${settingItem.alertType}`)}
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="large-radio-btn">
                            <RadioButton
                              id={`${settingItem.alertType}-none`}
                              name={settingItem.alertType}
                              value="false"
                              onChange={() => handleChangeAlertSettingRadio(index, false, subDivisionType)}
                              disabled={!emailSettingState.isFormEditable}
                              checked={!settingItem.emailEnabled}
                              readOnly={settingItem?.readonly}
                            >
                              <div className="d-flex justify-content-between">
                                {t('text:None')}
                                {emailSettingData?.[subDivisionType][index].recentUpdateEnterpriseType ===
                                  ENTERPRISE_TYPE.FI &&
                                  !emailSettingData?.[subDivisionType][index].emailEnabled &&
                                  renderBadge(emailSettingData?.[subDivisionType][index])}
                              </div>
                            </RadioButton>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="large-radio-btn">
                            <RadioButton
                              id={`${settingItem.alertType}-email`}
                              name={settingItem.alertType}
                              value="true"
                              onChange={() => handleChangeAlertSettingRadio(index, true, subDivisionType)}
                              disabled={!emailSettingState.isFormEditable}
                              checked={settingItem.emailEnabled}
                              readOnly={settingItem?.readonly}
                            >
                              <div className="d-flex justify-content-between">
                                {t('text:Email')}
                                {emailSettingData?.[subDivisionType][index].recentUpdateEnterpriseType ===
                                  ENTERPRISE_TYPE.FI &&
                                  emailSettingData?.[subDivisionType][index].emailEnabled &&
                                  renderBadge(emailSettingData?.[subDivisionType][index])}
                              </div>
                            </RadioButton>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </FormContents>
              </React.Fragment>
            );
          })}
      </FormBorder>
    </>
  );
};

export { EmailNotificationSettingForm };
