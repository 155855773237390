import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

import { formattingToAnchorUserVOModel } from './AnchorUserVO';
import { formattingToTermSpreadVOModel } from './TermSpreadVO';

import type { AnchorUserVO, AnchorUserVOModel } from './AnchorUserVO';
import type { TermSpreadVOModel } from './TermSpreadVO';
import type { WaitingDealerAgreementVO } from './WaitingDealerAgreementVO';

export interface WaitingDealerAgreementDetailVO extends WaitingDealerAgreementVO {
  anchorAgreementStartDate: string;
  anchorAgreementExpiryDate: string;
  anchorUserList: AnchorUserVO[];
  division: string;
  divisionName: string;
}

export interface WaitingDealerAgreementDetailVOModel extends WaitingDealerAgreementDetailVO {
  loanLimitAmount: BigNumber;
  adhocLimitAmount: BigNumber;
  drawingPower: BigNumber;
  termSpreadList: TermSpreadVOModel[];
  anchorUserList: AnchorUserVOModel[];
}

export function formattingToWaitingDealerAgreementDetailVOModel(
  data: WaitingDealerAgreementDetailVO,
): WaitingDealerAgreementDetailVOModel {
  const { loanLimitAmount, adhocLimitAmount, drawingPower, termSpreadList, anchorUserList } = data;

  return {
    ...data,
    loanLimitAmount: sliceZeroDecimal(loanLimitAmount),
    adhocLimitAmount: sliceZeroDecimal(adhocLimitAmount),
    drawingPower: sliceZeroDecimal(drawingPower),
    termSpreadList: termSpreadList
      ? termSpreadList.map(termSpread => formattingToTermSpreadVOModel(termSpread))
      : termSpreadList,
    anchorUserList: anchorUserList
      ? anchorUserList.map(anchorUser => formattingToAnchorUserVOModel(anchorUser))
      : anchorUserList,
  };
}
