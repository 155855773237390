import { isEmpty } from 'lodash-es';

import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { TempWaitingAnchorAgreementVOModel } from 'models/vo/TempWaitingAnchorAgreementVO';
import type { WaitingAnchorAgreementDetailVOModel } from 'models/vo/WaitingAnchorAgreementDetailVO';
import type { AnchorClientInfoType } from 'pages/financier/manage-anchor/agreement/models/agreement/AnchorClientInfoContext';
import { convertToString } from 'utils/formatter';

// AnchorAgreementDetailVOModel 중 제거될 필드
type FieldsToReplace =
  | 'financierClientId'
  | 'financierClientCode'
  | 'financierClientName'
  | 'financierClientBusinessCode'
  | 'financierClientAutoSignUpForAnchorAdminOfVendorFinance';

type ReplacementFields = keyof (WaitingAnchorAgreementDetailVOModel | TempWaitingAnchorAgreementVOModel);

type FieldMapping = {
  [key in FieldsToReplace]: ReplacementFields;
};

// 모든 VO 모델에서 공통으로 사용되는 필드
type CommonAgreementFields = Pick<
  AnchorAgreementDetailVOModel,
  keyof AnchorAgreementDetailVOModel &
    keyof WaitingAnchorAgreementDetailVOModel &
    keyof TempWaitingAnchorAgreementVOModel
>;

type AnchorAgreementDetails = AnchorAgreementDetailVOModel &
  WaitingAnchorAgreementDetailVOModel &
  TempWaitingAnchorAgreementVOModel;

// 모든 VO 모델의 필드를 병합 (세 VO 모델 타입 모두 허용하도록)
type CombinedAgreementFields = CommonAgreementFields & Partial<AnchorAgreementDetails>;

// 공통으로 관리할 파트너 약정 타입
export type AnchorAgreementCommonVOModel = CommonAgreementFields &
  Partial<
    Omit<AnchorAgreementDetailVOModel, FieldsToReplace> &
      WaitingAnchorAgreementDetailVOModel &
      TempWaitingAnchorAgreementVOModel
  >;

// AnchorVOModel -> WaitingAnchorVOModel
const FIELD_MAPPING_CONFIG: FieldMapping = {
  financierClientId: 'anchorClientId',
  financierClientCode: 'anchorClientCode',
  financierClientName: 'anchorClientName',
  financierClientBusinessCode: 'anchorClientTaxCode',
  financierClientAutoSignUpForAnchorAdminOfVendorFinance: 'anchorClientAutoSignUpForAnchorAdminOfVendorFinance',
};

// 필드를 매핑된 필드로 대체하는 함수
const replaceFieldsWithMapping = (originalFields: CombinedAgreementFields, fieldMapping: FieldMapping) => {
  const replacedFields = Object.fromEntries(
    Object.entries(originalFields).map(([key, value]) => [fieldMapping[key as FieldsToReplace] || key, value]),
  ) as AnchorAgreementCommonVOModel;

  return replacedFields;
};

// 약정 정보를 공통으로 관리하는 데이터 변환 함수
export const convertToAnchorAgreementCommonVOModel = (data: CombinedAgreementFields): AnchorAgreementCommonVOModel => {
  if (!data || isEmpty(data)) return {} as AnchorAgreementCommonVOModel;

  const isWaitingType = 'waitingAnchorAgreementId' in data;

  const formattedAgreementFields: CombinedAgreementFields = {
    ...data,
    anchorUserList: data.anchorUserList ?? [],
  };

  if (isWaitingType) return formattedAgreementFields;

  return replaceFieldsWithMapping(formattedAgreementFields, FIELD_MAPPING_CONFIG);
};

// 전문 약정 데이터 불러올 때
export const convertToInterfaceAnchorAgreementData = ({
  prevAnchorClientData,
  interfaceData,
  useAnchorUserInterface,
}: {
  prevAnchorClientData: AnchorClientInfoType;
  interfaceData?: AnchorAgreementDetailVOModel;
  useAnchorUserInterface: boolean;
}) => {
  if (!interfaceData || isEmpty(interfaceData)) return;

  const newData = convertToAnchorAgreementCommonVOModel(interfaceData);
  const isAnchorUserListChanged =
    prevAnchorClientData?.anchorClientId !== newData.anchorClientId || useAnchorUserInterface;

  return {
    ...newData,
    anchorUserList: isAnchorUserListChanged ? newData.anchorUserList : prevAnchorClientData?.anchorUserList,
  };
};

// reset
export const convertToAnchorAgreementResetData = (data: AnchorAgreementCommonVOModel) => {
  return {
    ...data,
    paymentSupport: convertToString(data.paymentSupport),
    loanLimitCheck: convertToString(data.loanLimitCheck),
    collateralIssuedLimitCheck: convertToString(data.collateralIssuedLimitCheck),
    settlementDateOverAllowable: convertToString(data.settlementDateOverAllowable ?? false),
    referenceNumberDuplicationCheck: convertToString(data.referenceNumberDuplicationCheck ?? true),
    billOfExchangeNoRequired: convertToString(data.billOfExchangeNoRequired ?? false),
    bankUserIdList: data.anchorUserList?.map(anchorUser => anchorUser.bankUserId),
  };
};
