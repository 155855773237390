import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

import { formattingToAnchorUserVOModel } from './AnchorUserVO';

import type { AnchorAgreementVO } from './AnchorAgreementVO';
import type { AnchorUserVO, AnchorUserVOModel } from './AnchorUserVO';

export interface AnchorAgreementDetailVO
  extends Omit<AnchorAgreementVO, 'tempArSummaryExists' | 'financierClientTaxCode'> {
  settlementAccountBalanceAmount: string;
  collateralIssuedLimitAmount: string;
  registeredWaitingArAmount: string;
  settlementWaitingInvoiceAmount: string;
  collateralIssuedLimitCheck: boolean;
  loanLimitCheck: boolean;
  division: string;
  divisionName: string;
  anchorUserList: AnchorUserVO[];
}

export interface AnchorAgreementDetailVOModel extends AnchorAgreementDetailVO {
  loanLimitAmount: BigNumber;
  settlementAccountBalanceAmount: BigNumber;
  collateralIssuedLimitAmount: BigNumber;
  registeredWaitingArAmount: BigNumber;
  settlementWaitingInvoiceAmount: BigNumber;
  partiallyRepaidPrincipalAmount: BigNumber;
  anchorUserList: AnchorUserVOModel[];
  // only front
  remainingRegistrationLimit: BigNumber; // collateralIssuedLimitAmount - settlementWaitingInvoiceAmount - registeredWaitingArAmount
}

export function formattingToAnchorAgreementDetailVOModel(data: AnchorAgreementDetailVO): AnchorAgreementDetailVOModel {
  const calculatorBigNumber = new CalculatorBigNumber();

  const slicedZeroDecimalData = {
    loanLimitAmount: sliceZeroDecimal(data.loanLimitAmount),
    settlementAccountBalanceAmount: sliceZeroDecimal(data.settlementAccountBalanceAmount),
    collateralIssuedLimitAmount: sliceZeroDecimal(data.collateralIssuedLimitAmount),
    registeredWaitingArAmount: sliceZeroDecimal(data.registeredWaitingArAmount),
    settlementWaitingInvoiceAmount: sliceZeroDecimal(data.settlementWaitingInvoiceAmount),
    partiallyRepaidPrincipalAmount: sliceZeroDecimal(data.partiallyRepaidPrincipalAmount),
  };

  const { anchorUserList } = data;

  const {
    loanLimitAmount,
    settlementAccountBalanceAmount,
    collateralIssuedLimitAmount,
    registeredWaitingArAmount,
    settlementWaitingInvoiceAmount,
    partiallyRepaidPrincipalAmount,
  } = slicedZeroDecimalData;

  return {
    ...data,
    loanLimitAmount,
    settlementAccountBalanceAmount,
    collateralIssuedLimitAmount,
    registeredWaitingArAmount,
    settlementWaitingInvoiceAmount,
    partiallyRepaidPrincipalAmount,
    anchorUserList: anchorUserList
      ? anchorUserList.map(anchorUserResponse => formattingToAnchorUserVOModel(anchorUserResponse))
      : anchorUserList,
    // only front
    remainingRegistrationLimit: calculatorBigNumber
      .add(collateralIssuedLimitAmount)
      .minus(settlementWaitingInvoiceAmount)
      .minus(registeredWaitingArAmount)
      .get(), // collateralIssuedLimitAmount - settlementWaitingInvoiceAmount - registeredWaitingArAmount
  };
}
