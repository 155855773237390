import type { ButtonHTMLAttributes } from 'react';

import './Button.scss';

interface ToggleButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
}

const ToggleButton = ({ onClick, isActive, ...restProps }: ToggleButtonPropsType) => {
  return (
    <button className={`toggle ${isActive ? 'toggle--on' : ''}`} onClick={onClick} type="button" {...restProps}>
      <span className="toggle__switch" />
    </button>
  );
};

export default ToggleButton;
