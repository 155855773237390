import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isEmpty, isNil } from 'lodash-es';

import ApInputBulkImportTable from 'components/ap/ApInputBulkImportTable';
import ApInputBulkTable from 'components/ap/ApInputBulkTable';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, OTP_TYPE } from 'enums';
import { InvalidInputValueExceptionCode } from 'enums/exception';
import useLocationState from 'hooks/useLocationState';
import useMounted from 'hooks/useMounted';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import AnchorAPRequestModal from 'pages/anchor/register-ap/modals/anchorAPRequestModal';
import type { BigNumber } from 'utils/bigNumber';
import { getSum } from 'utils/calculate';
import {
  convertToServerDateFormat,
  getAvailableSettlementDateRangeForRegistrationArOrAp,
  getDayTerm,
  verifySettlementDateForRegistrationArOrAp,
} from 'utils/date/date';
import { isErrorCodeEqual } from 'utils/error/check';
import { formatDateColumn } from 'utils/formatter';
import { requestAnchorAgreementDetail } from 'utils/http/api/anchor/anchor-agreements';
import { requestAnchorArPartnerNames } from 'utils/http/api/anchor/anchor-partners';
import { requestAnchorArRegister } from 'utils/http/api/anchor/ar-summaries';
import type { AnchorArListElementType, AnchorArRegisterRequest } from 'utils/http/api/anchor/ar-summaries/request';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestAnchorFinancierCalendar } from 'utils/http/api/anchor/financier-calendar';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestTempArData, requestTempArRegister } from 'utils/http/api/common/temp-ar-summaries';
import { initializeRefValue } from 'utils/initialize/index';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';
import { REG_ALL_CHARACTER_AND_NUMBER } from 'utils/validation/regExp';

import AnchorApRegisterValidationErrorModal from '../../modals/AnchorApRegisterValidationErrorModal';

dayjs.extend(customParseFormat);

const FILE_UPLOAD_MAX_ROW_LENGTH = 500;
const BULK_IMPORT_MAX_ROW_LENGTH = 3000;
// TODO : /v1/cm/registrable-limit/${anchorAgreementId}/limit-by-anchor-agreement` 호출 하여 설정
export type InputType = 'direct' | 'fileUpload' | 'bulkImport';

function AnchorApRegisterStep2() {
  const { t } = useTranslation(['format']);
  const { anchorAgreementId } = useParams() as any;
  const [locationState, errorHandlerOfLocationState] = useLocationState<{ financierId: number }>(['financierId']);
  const { financierId } = locationState;
  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();
  const [anchorAgreementDetail, setAnchorAgreementDetail] = useState<AnchorAgreementDetailVOModel>();
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>(); // 비영업일 조회
  const [inputType, setInputType] = useState<InputType>('direct'); // direct input or excel upload state
  const [settlementDatePeriod, setSettlementDatePeriod] = useState({
    minimumDate: new Date(),
    maximumDate: new Date(),
  });
  const [isSavedFields, setIsSavedFields] = useState<boolean>(false); // register, save 유효성 검사 구분 짓기 위한 state
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [totalApAmount, setTotalApAmount] = useState<BigNumber>('0');
  const [totalBulkApAmount, setTotalBulkApAmount] = useState<BigNumber>('0');
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();
  const [availableSettlementDateRange, setAvailableSettlementDateRange] = useState<string[]>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [bulkImportFile, setBulkImportFile] = useState<File>();
  const [rows, setRows] = useState<string[]>(['']);
  const [bulkImportRows, setBulkImportRows] = useState<string[]>();
  const [excelInfos, setExcelInfos] = useState<AnchorArListElementType[]>();
  const [holidays, setHolidays] = useState<string[]>(['']);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);

  const apRegistrationUseForm = useForm<AnchorArRegisterRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const apBulkImportUseForm = useForm<AnchorArRegisterRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { getValues, reset, trigger, errors, register, clearErrors, control, setValue, setError, formState } =
    apRegistrationUseForm;

  const {
    getValues: importGetValues,
    reset: importReset,
    trigger: importTrigger,
    errors: importErrors,
    register: importRegister,
    clearErrors: importClearErrors,
  } = apBulkImportUseForm;

  useEffect(() => {
    if (mounted) {
      // if temp data 가 있으면 data set 하는 함수 호출
      if (errorHandlerOfLocationState()) return;
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const financierCommonSettingResponse = await requestFinancierSettingData(financierId);

      const date = new Date();
      const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];
      const { minimumPeriodForRegisterAr, maximumPeriodForRegisterAr } = financierCommonSettingResponse;
      const minimumDate = new Date(today[0], today[1], today[2] + minimumPeriodForRegisterAr);
      const maximumDate = new Date(today[0], today[1], today[2] + maximumPeriodForRegisterAr);

      const [anchorAgreementDetailData, tempApListResponse, fetchFinancierHoliday] = await Promise.all([
        requestAnchorAgreementDetail(anchorAgreementId),
        requestTempArData(anchorAgreementId),
        requestAnchorFinancierCalendar(financierId, {
          pageNumber: 0,
          rowCount:
            Number(getDayTerm(convertToServerDateFormat(minimumDate), convertToServerDateFormat(maximumDate))) + 1,
          fromDate: convertToServerDateFormat(minimumDate),
          toDate: convertToServerDateFormat(maximumDate),
          holiday: true,
        }),
      ]);

      const getHolidayArrays = fetchFinancierHoliday.content.map(item => item.solarDate);
      const availableDateRange = getAvailableSettlementDateRangeForRegistrationArOrAp(
        getHolidayArrays,
        minimumDate,
        maximumDate,
      );
      const [initialSettlementDate] = availableDateRange;

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementDetail(anchorAgreementDetailData);
        setInitialSettlementDate(initialSettlementDate);
        setFinancierHoliday(fetchFinancierHoliday);
        setAvailableSettlementDateRange(availableDateRange);
        setSettlementDatePeriod({ maximumDate, minimumDate });
        setHolidays(getHolidayArrays);

        if (tempApListResponse.tempArList.content.length !== 0) {
          setRows(Array(tempApListResponse.tempArList.content.length).fill(''));

          const updatedPartnerNames = tempApListResponse.tempArList.content.map(item => item.partnerName);

          setPartnerNames(updatedPartnerNames);

          reset({
            arList: tempApListResponse.tempArList.content.map(item => {
              return {
                arNumber: item.arNumber,
                arAmount: item.arAmount,
                arIssuedDate: item.arIssuedDate,
                settlementDate: verifySettlementDateForRegistrationArOrAp(
                  item.settlementDate,
                  initialSettlementDate,
                  availableDateRange,
                ),
                partnerTaxCode: item.partnerTaxCode,
              };
            }),
          });
        } else {
          reset({
            arList: [
              {
                arNumber: undefined,
                arAmount: undefined,
                arIssuedDate: undefined,
                settlementDate: initialSettlementDate,
                partnerTaxCode: undefined,
              },
            ],
          });
        }
      });
    } catch (error) {
      modal.show(error, {
        closeBtnCb: () => history.push(ROUTES_AC.REGISTER_AP.REGISTRATION_STEP1),
      });
    }
  };

  // 이벤트 핸들러
  const handlerPartnerTaxCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value, name } = e.target;
    const [_, index] = name.split('.'); // name 형식 'arList.index.컬럼명'
    let partnerNameToUpdate = '';
    if (value.length >= 9) {
      const partnerNames = await requestAnchorArPartnerNames([value.trim()]);
      if (!isEmpty(partnerNames)) {
        partnerNameToUpdate = partnerNames[0]['partnerName'];
      }
    }

    setPartnerNames(prevPartnerNames => {
      const currentPartnerNames = [...prevPartnerNames];
      currentPartnerNames[Number(index)] = partnerNameToUpdate;

      return currentPartnerNames;
    });
  };

  const updateTotalApAmount = useCallback(() => {
    const sum = getSum(getValues().arList, 'arAmount');
    setTotalApAmount(sum);
  }, [getValues]);

  const updateTotalBulkApAmount = useCallback(() => {
    const sum = getSum(importGetValues().arList, 'arAmount');
    setTotalBulkApAmount(sum);
  }, [importGetValues]);

  useEffect(() => {
    updateTotalApAmount();
  }, [rows, updateTotalApAmount]);

  useEffect(() => {
    updateTotalBulkApAmount();
  }, [updateTotalBulkApAmount, excelInfos]);

  const onClickRegister = async (e: any) => {
    e.preventDefault();

    const verificationCode: UserVerificationCodeRequest = {};

    setIsSavedFields(false);

    const data = getValues();
    data.arList.forEach((ar, index) => setValue(`arList.${index}.partnerTaxCode`, ar.partnerTaxCode?.trim())); // partnerTaxCode trim 작업

    const trimmedPartnerTaxCodeData = getValues();

    const checkOtpAndConfirm = async () => {
      try {
        const clientAuthTypeData = await requestAnchorClientAuthByFinancierId(financierId);
        if (
          clientAuthTypeData.currentAuthorityType === AUTHORITY_TYPE.ADMIN &&
          clientAuthTypeData.otpType !== OTP_TYPE.NONE
        ) {
          modal.show(
            <UserVerificationModal
              modalId={modal.id}
              requestIdType="financierId"
              requestId={financierId}
              clientAuthSetting={clientAuthTypeData}
              verificationCode={verificationCode}
            />,
            {
              modalType: ModalType.CONFIRM,
              title: t('text:User_Verification'),
              closeBtnText: t('text:Cancel'),
              confirmBtnCb: () => onClickConfirm(),
            },
          );
        } else {
          onClickConfirm();
        }
      } catch (e) {
        modal.show(e);
      }
    };

    const isValidPartnerName = () => {
      ReactDOM.unstable_batchedUpdates(() => {
        rows.forEach((_, index) => {
          if (!partnerNames[index]) {
            setError(`arList.${index}.partnerName`, {
              type: 'required',
              message: t('text:Fail_No_partner_exists_that_matches_the_tax_code_you_entered'),
            });
          }
        });
      });
    };

    const executeRegister = () => {
      return new Promise<void>(async resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1000);
      }).then(async () => {
        if (inputType !== 'bulkImport') {
          await trigger().then(result => {
            isValidPartnerName();
            setCheckedValidation(data.arList.map(() => true));

            unShowLoadingUI();
            if (!result || !isEmpty(formState.errors)) {
              modal.show(<AnchorApRegisterValidationErrorModal />, {
                modalSize: ModalSize.NONE,
                modalType: ModalType.ALERT,
                closeBtnText: t('text:Close'),
              });

              return; // FE validation error 있으면 return
            } else {
              // FRONT Validation 통과
              if (trimmedPartnerTaxCodeData) {
                modal.show(
                  <AnchorAPRequestModal
                    dataList={trimmedPartnerTaxCodeData.arList}
                    totalCount={trimmedPartnerTaxCodeData.arList.length}
                    totalAmount={totalApAmount}
                    currencyType={anchorAgreementDetail?.currencyType}
                    partnerNames={partnerNames}
                  />,
                  {
                    modalSize: ModalSize.XL,
                    modalType: ModalType.CONFIRM,
                    closeBtnText: t('text:Cancel'),
                    confirmBtnCb: checkOtpAndConfirm,
                  },
                );
              }
            }
          });
        } else {
          checkOtpAndConfirm();
          unShowLoadingUI();
        }
      });
    };

    await executeRegister();

    const onClickConfirm = async (): Promise<void> => {
      const requestedData = inputType === 'bulkImport' ? importGetValues() : getValues();

      requestedData.anchorAgreementId = Number(anchorAgreementId);
      requestedData.financierId = Number(financierId);
      requestedData.otpCode = verificationCode.otpCode;
      requestedData.queryValue = verificationCode.queryValue;

      try {
        await requestAnchorArRegister(requestedData);
        modal.show(
          <>
            <h6>{t('text:The_AP_registration_request_has_been_completed')}</h6>
            <h6>{t('text:You_can_check_the_registration_result_on_the_AP_confirmation_menu')}</h6>
          </>,
          {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => history.push(ROUTES_AC.REGISTER_AP.CONFIRMATION_LIST),
          },
        );
      } catch (error) {
        if (inputType === 'bulkImport' && isErrorCodeEqual(error, InvalidInputValueExceptionCode.INVALID_INPUT_VALUE)) {
          modal.show(
            <h6>
              {t('text:The_list_of_AP_contain_errors')}
              <br />
              {t('text:Export_the_AP_list_to_check_the_detailed_validation_results')}
              <br />
              {t('text:Attach_the_updated_AP_list_again_to_register_the_AP')}
            </h6>,
          );
        } else {
          modal.show(error);
        }
      }
    };
  };

  const onClickCancel = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_AP_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
        <br />
        {t('text:Click_the_Save_button_to_save_temporarily')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => history.push(ROUTES_AC.REGISTER_AP.REGISTRATION_STEP1),
      },
    );
  };

  const onClickSave = async () => {
    setIsSavedFields(prev => (prev ? prev : !prev));
    setCheckedValidation([]);

    // isSavedFields = true 로 변경 후, ref 에 걸린 조건이 늦게 반영되므로 setTimeout 함수 추가
    new Promise<void>(async resolve => {
      showLoadingUI();
      setTimeout(() => {
        resolve();
      }, 1000);
    }).then(async () => {
      unShowLoadingUI();

      const data = getValues();
      data.arList.forEach((ar, index) => setValue(`arList.${index}.partnerTaxCode`, ar.partnerTaxCode?.trim())); // partnerTaxCode trim 작업
      const trimmedPartnerTaxCodeData = getValues();

      const arAmountFieldNameList = trimmedPartnerTaxCodeData.arList?.map((_, index) => `arList.${index}.arAmount`);
      const isArAmountsValid = await trigger(arAmountFieldNameList);
      if (isArAmountsValid) {
        modal.show(
          <h6>
            {t('text:Would_you_like_to_temporarily_save_the_entered_AP_information?')}
            <br />
            {t('text:Please_note_that_AP_will_not_be_registered_until_you_click_the_Register_button')}
          </h6>,
          {
            modalType: ModalType.CONFIRM,
            closeBtnText: t('text:Cancel'),
            confirmBtnCb: async () => {
              try {
                const requestedData = getValues();
                requestedData.anchorAgreementId = Number(anchorAgreementId);
                await requestTempArRegister(requestedData); // save temp data api
                savedCompleteModal();
              } catch (e) {
                modal.show(e);
              }
            },
          },
        );
      } else {
        setCheckedValidation(data.arList?.map(() => true));
      }
    });

    const savedCompleteModal = () => {
      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => history.push(ROUTES_AC.REGISTER_AP.REGISTRATION_STEP1),
      });
    };
  };

  const handleRegistrationMethodRadioButton = (value: InputType) => {
    setInputType(value);
    initializeRefValue(fileRef);
  };

  const onChangeUploadExcel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const fetchPartnerNamesByTaxCodes = async (taxCodes: string[]): Promise<{ [key: string]: string }> => {
      const uniqueTaxCodes = [...new Set(taxCodes)];
      const partnerTaxCodesAndNames = await requestAnchorArPartnerNames(uniqueTaxCodes);

      return partnerTaxCodesAndNames.reduce(
        (partnerMap, partnerTaxCodeAndName) => ({
          ...partnerMap,
          [partnerTaxCodeAndName.taxCode]: partnerTaxCodeAndName.partnerName,
        }),
        {},
      );
    };

    const updatePartnerNames = (
      changedData: AnchorArListElementType[],
      partnerNameMap: { [key: string]: string },
    ): string[] => changedData.map(ar => partnerNameMap[ar.partnerTaxCode] || '');

    const settingPartnerNames = async (partnerTaxCodes: string[], changeData: AnchorArListElementType[]) => {
      if (partnerTaxCodes.length === 0) return setPartnerNames([]);

      const partnerNameMap = await fetchPartnerNamesByTaxCodes(partnerTaxCodes);
      const updatedNames = updatePartnerNames(changeData, partnerNameMap);
      setPartnerNames(updatedNames);
    };

    const isValidPartnerTaxCode = (partnerTaxCode?: string): boolean => {
      if (!partnerTaxCode) {
        return false;
      }
      const trimmedPartnerTaxCode = String(partnerTaxCode).trim();

      return REG_ALL_CHARACTER_AND_NUMBER.test(trimmedPartnerTaxCode) && trimmedPartnerTaxCode.length <= 20;
    };

    const extractValidPartnerTaxCodes = (dataArray: any[]): string[] => {
      return dataArray.map(data => data['Partner Tax Identification Number']).filter(isValidPartnerTaxCode);
    };

    const formattedData = (data: any) => {
      const formattedIssuedDate = formatDateColumn(data[ExcelTemplatesHeader.AP_ISSUED_DATE]);
      const formattedSettlementDate = formatDateColumn(data[ExcelTemplatesHeader.SETTLEMENT_DATE]);
      const partnerTaxCodeData = data['Partner Tax Identification Number'];
      const arNumberData = data[ExcelTemplatesHeader.AP_NUMBER];
      const arAmountData = data[ExcelTemplatesHeader.AP_AMOUNT];

      const formattingData = (data: any) => {
        if (data === '-' || isNil(data)) return '';
        else return data;
      };

      const partnerTaxCode = String(formattingData(partnerTaxCodeData));
      const arNumber = String(formattingData(arNumberData));
      const arAmount = formattingData(arAmountData);
      const arIssuedDate = dayjs(formattedIssuedDate, 'YYYY-MM-DD', true).isValid() ? formattedIssuedDate : '';

      const settlementDate =
        inputType === 'fileUpload'
          ? verifySettlementDateForRegistrationArOrAp(
              formattedSettlementDate,
              initialSettlementDate,
              availableSettlementDateRange,
            ) ?? ''
          : dayjs(formattedSettlementDate, 'YYYY-MM-DD', true).isValid()
          ? formattedSettlementDate
          : '';

      return {
        partnerTaxCode,
        arNumber,
        arAmount,
        arIssuedDate,
        settlementDate,
      };
    };

    const anchorApBulkImportValidate = async () => {
      return new Promise<void>(async resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1);
      }).then(async () => {
        await importTrigger().then(result => {
          unShowLoadingUI();
          if (!result) {
            modal.show(
              <>
                <h6>{t('text:The_list_of_AP_contain_errors')}</h6>
                <h6>{t('text:Export_the_AP_list_to_check_the_detailed_validation_results')}</h6>
                <h6>{t('text:Attach_the_updated_AP_list_again_to_register_the_AP')}</h6>
              </>,
            );
            // FE validation error 있으면 return
          }
        });
      });
    };

    if (e.target.files && e.target.files[0]) {
      const isCsvFile = e.target.files[0].type === 'text/csv';

      try {
        showLoadingUI();
        const jsonArrayData = isCsvFile
          ? await csvToJson(e.target.files[0], FILE_UPLOAD_MAX_ROW_LENGTH, BULK_IMPORT_MAX_ROW_LENGTH, inputType)
          : await excelToJson(e.target.files[0], FILE_UPLOAD_MAX_ROW_LENGTH, BULK_IMPORT_MAX_ROW_LENGTH, inputType);

        setCheckedValidation([]);
        inputType === 'bulkImport' ? setBulkImportFile(e.target.files[0]) : setFile(e.target.files[0]);

        if (isCsvFile && jsonArrayData && jsonArrayData.length !== 0) {
          const partnerTaxCodes = extractValidPartnerTaxCodes(jsonArrayData);

          const changedData: AnchorArListElementType[] = jsonArrayData.map((data: any) => formattedData(data));

          if (inputType === 'fileUpload') {
            setRows(Array(changedData.length).fill(''));
            reset({
              arList: changedData,
            });
            updateTotalApAmount();
            await settingPartnerNames(partnerTaxCodes, changedData);
          } else if (inputType === 'bulkImport') {
            setExcelInfos(changedData);
            setBulkImportRows(Array(changedData.length).fill(''));
            importReset({
              arList: changedData,
            });
            importClearErrors();
            updateTotalBulkApAmount();
            await anchorApBulkImportValidate();
          }

          return;
        }

        if (
          !isCsvFile &&
          jsonArrayData &&
          Array.isArray(jsonArrayData) &&
          jsonArrayData[0] &&
          Array.isArray(jsonArrayData[0]) &&
          jsonArrayData[0].length !== 0
        ) {
          const partnerTaxCodes = extractValidPartnerTaxCodes(jsonArrayData[0]);

          const changedData: AnchorArListElementType[] = jsonArrayData[0].map((data: any) => formattedData(data));
          if (inputType === 'fileUpload') {
            setRows(Array(changedData.length).fill(''));
            reset({
              arList: changedData,
            });
            updateTotalApAmount();
            await settingPartnerNames(partnerTaxCodes, changedData);
          } else if (inputType === 'bulkImport') {
            setExcelInfos(changedData);
            setBulkImportRows(Array(changedData.length).fill(''));
            importReset({
              arList: changedData,
            });
            importClearErrors();
            updateTotalBulkApAmount();
            await anchorApBulkImportValidate();
          }

          return;
        }

        modal.show(
          <h6>
            {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
            <br />
            {t('text:Please_check_and_upload_it_again')}
          </h6>,
        );
      } catch (error) {
        modal.show(error);
        initializeRefValue(fileRef);
      } finally {
        unShowLoadingUI();
      }
    }
  };

  const onClickRemoveExcel = (e: any): void => {
    e.preventDefault();
    initializeRefValue(fileRef);
    // for same file upload ..
    if (inputType === 'fileUpload') {
      file && setFile(undefined);
    } else {
      bulkImportFile && setBulkImportFile(undefined);
    }
  };

  const renderExcelUpload = () => {
    return (
      <div className="excel-form">
        <div className="excel-download-form d-flex">
          <label className="me-3">
            {inputType !== 'bulkImport'
              ? t(
                  'text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it_After_uploading_you_can_check_the_uploaded_list_below',
                )
              : t(
                  'text:To_upload_more_than_500_APs,_please_download_the_template_on_the_right,_fill_it_out,_and_attach_it_below',
                )}
          </label>
          <ExcelTemplateDownloadButton
            templateFileAddress="/templates/APRegistrationTemplate.xlsx"
            style={{ marginRight: '4px' }}
          />
          <ExcelTemplateDownloadButton
            downloadAnnounceText={t('text:CSV_Template')}
            templateFileAddress="/templates/APRegistrationTemplate.csv"
          />
        </div>
        <div className="detail-in-file-upload-form bg-sub100">
          <div className="d-flex justify-content-between">
            <input
              onChange={onChangeUploadExcel}
              ref={fileRef}
              type="file"
              name="file"
              id="FileUpload"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: 'none' }}
            />
            <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
              {t('text:Attach_File')}
            </label>
            <div id="fileName" className="upload-file-input">
              {inputType === 'bulkImport'
                ? bulkImportFile
                  ? bulkImportFile.name
                  : t('text:No_file_attached')
                : file
                ? file.name
                : t('text:No_file_attached')}
            </div>
            <IconButton
              onClick={onClickRemoveExcel}
              className={`delete-uploaded-excel-button ${
                inputType === 'bulkImport' ? !bulkImportFile && 'd-none' : !file && 'd-none'
              }`}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:AP_Registration')} onClick={onClickCancel} />
      <div className="content-area">
        <StepWizard nth={2} title={[t('text:Select_Anchor_Master_Agreement'), t('text:Enter_AP_Information')]} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Anchor_Master_Agreement_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} col={4} value={anchorAgreementDetail?.financierName} />
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                col={4}
                value={anchorAgreementDetail?.contractNo}
              />
              <FormValue label={t('text:Currency')} col={4} value={anchorAgreementDetail?.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {anchorAgreementDetail?.collateralIssuedLimitCheck && (
        <div className="content-area">
          <SectionTitle title={t('text:AP_Registration_Limit')} />
          <div className="information-form__corporation">
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:AP_Issuance_Limit')} ①</div>
                <div className="col-6">
                  {t('format:number', { value: anchorAgreementDetail?.collateralIssuedLimitAmount })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Registration_Confirmed_AP')} ②</div>
                <div className="col-6">
                  {t('format:number', { value: anchorAgreementDetail?.settlementWaitingInvoiceAmount })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t('text:Registration_Requested_AP')} ③</div>
                <div className="col-6">
                  {t('format:number', { value: anchorAgreementDetail?.registeredWaitingArAmount })}
                </div>
              </div>
            </div>
            <div className="corporation-section">
              <div className="row">
                <div className="col-6">{t('text:Limit_Available_for_Registration')} ④ = ① - ② - ③</div>
                <div className="col-6 blackfont">
                  {t('format:number', {
                    value: anchorAgreementDetail?.remainingRegistrationLimit,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="content-area">
        <h3>{t('text:Select_AP_Registration_method')}</h3>
        <div className="invoice-radio-group">
          <RadioButton
            id="direct-input"
            name="registration-method"
            checked={inputType === 'direct'}
            onChange={() => handleRegistrationMethodRadioButton('direct')}
          >
            {t('text:Direct_Input')}
          </RadioButton>
          <RadioButton
            id="file-upload"
            name="registration-method"
            checked={inputType === 'fileUpload'}
            onChange={() => handleRegistrationMethodRadioButton('fileUpload')}
          >
            {t('text:File_Upload')}
          </RadioButton>
          <RadioButton
            id="bulk-upload"
            name="registration-method"
            checked={inputType === 'bulkImport'}
            onChange={() => handleRegistrationMethodRadioButton('bulkImport')}
          >
            {t('text:Bulk_Import')}
          </RadioButton>
        </div>
        {(inputType === 'fileUpload' || inputType === 'bulkImport') && renderExcelUpload()}
      </div>
      {importErrors.arList && inputType === 'bulkImport' && (
        <div className="detail-in-file-upload-result-registration__alert">
          <SectionMessage
            message={[
              t('text:N_of_N_APs_contain_invalid_information', {
                errorsLength: importErrors.arList.filter(Boolean).length,
                allLength: bulkImportRows?.length,
              }),
              t(
                'text:Export_the_AP_list_and_check_the_detailed_validation_results_before_uploading_the_revised_AP_information_again',
              ),
            ]}
            messageType="error"
          />
        </div>
      )}
      {excelInfos && isNil(importErrors.arList) && inputType === 'bulkImport' && (
        <div className="detail-in-file-upload-result-registration">
          <SectionMessage
            message={[
              t('text:N_APs_passed_the_validation_checks_successfully', {
                allLength: bulkImportRows?.length,
              }) +
                ' ' +
                t('text:Click_on_the_Register_button_to_complete_the_AP_registration_request'),
            ]}
          />
        </div>
      )}
      <div className="content-area" data-testid="content-area">
        <div className={inputType === 'bulkImport' ? 'd-none' : ''}>
          <ApInputBulkTable
            register={register}
            errors={errors}
            getValues={getValues}
            reset={reset}
            clearErrors={clearErrors}
            control={control}
            setValue={setValue}
            formState={formState}
            rows={rows}
            setRows={setRows}
            checkedValidation={checkedValidation}
            setCheckedValidation={setCheckedValidation}
            totalApAmount={totalApAmount}
            updateTotalApAmount={updateTotalApAmount}
            initialSettlementDate={initialSettlementDate}
            isSavedFields={isSavedFields}
            pageType="registration"
            minimumDate={settlementDatePeriod.minimumDate}
            maximumDate={settlementDatePeriod.maximumDate}
            currencyType={anchorAgreementDetail?.currencyType}
            financierHoliday={financierHoliday}
            partnerNames={partnerNames}
            setPartnerNames={setPartnerNames}
            onChangePartnerTaxCode={handlerPartnerTaxCodeChange}
          />
        </div>
        <div className={inputType !== 'bulkImport' ? 'd-none' : ''}>
          <ApInputBulkImportTable
            errors={importErrors}
            register={importRegister}
            originalExcelInfos={excelInfos}
            holidays={holidays}
            minDate={settlementDatePeriod.minimumDate}
            maxDate={settlementDatePeriod.maximumDate}
            rows={bulkImportRows}
            currencyType={anchorAgreementDetail?.currencyType}
            totalApAmount={totalBulkApAmount}
            t={t}
          />
        </div>
        <div className="d-flex mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancel}
            className="me-auto"
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>

          {inputType !== 'bulkImport' && (
            <Button size={ButtonSizeEnum.LG} onClick={onClickSave} variant={ButtonVariantEnum.OUTLINED}>
              {t('text:Save')}
            </Button>
          )}
          <Button size={ButtonSizeEnum.LG} onClick={onClickRegister} className="ms-2">
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AnchorApRegisterStep2;
