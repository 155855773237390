import dayjs from 'dayjs';
import i18n from 'i18next';
import { isEmpty } from 'lodash-es';

import { SUPPORTED_COLLATERAL_TYPE } from 'enums';
import type { FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import { removeRangeFromHoliday } from 'utils/date/date';
import { base64ToFile, fileToFileList } from 'utils/file';
import { getCollateralType } from 'utils/storage/LocalStorage';

export const getDigitalSignatureTransactionFiles = (digitalSignatureTransactionInfo: FactoringAttachmentVOModel) => {
  const { transactionNo, loanAgreementAttachment, loanFactoringNotificationAttachment } =
    digitalSignatureTransactionInfo;

  const loanAgreementFile = base64ToFile(loanAgreementAttachment, 'DEPT SALE AND PURCHASE AGREEMENT');
  const loanFactoringNotificationFile = base64ToFile(loanFactoringNotificationAttachment, 'DEBT SELLING NOTIFICATION');

  const loanAgreementFileList = fileToFileList(loanAgreementFile);
  const loanFactoringNotificationFileList = fileToFileList(loanFactoringNotificationFile);

  return {
    transactionNo,
    loanAgreementFileList,
    loanFactoringNotificationFileList,
  };
};

export const getMaxDate = (expiryDate: Date, settlementDate: Date): Date => {
  // 당일  ~ 30일 후까지. 30일 후 날짜보다 파트너 약정 만료일이 빠르다면 파트너 약정 만료일까지로 제한.
  // ++ add 결제일 이 저 30일 안에 들어가 있을 경우, 결제일 이후 선택 못하게
  const today = dayjs().format('YYYY-MM-DD');
  const endDate = dayjs(today).add(30, 'day').toDate();
  let finalDate = endDate;

  if (settlementDate < endDate) {
    finalDate = settlementDate;
  } else if (expiryDate < endDate) {
    finalDate = expiryDate;
  }

  return finalDate;
};

const isSelectableTempDisbursementDate = (
  tempDisbursementDate: string,
  dealerAgreementExpiryDate: string,
  arSettlementDate: string,
) => {
  const today = dayjs().format('YYYY-MM-DD');
  let isIncludedSelectableRange: boolean = false;

  if (tempDisbursementDate) {
    // 당일  ~ 30일 후까지. 30일 후 날짜보다 파트너 약정 만료일이 빠르다면 파트너 약정 만료일까지로 제한.
    // ++ add 결제일 이 저 30일 안에 들어가 있을 경우, 결제일 이후 선택 못하게
    const startDate = dayjs(today).toDate();

    const endDate = dayjs(today).add(30, 'day').toDate();
    const expiryDate = new Date(dealerAgreementExpiryDate);
    const settlementDate = new Date(arSettlementDate);

    let finalDate = endDate;

    if (settlementDate < endDate) {
      finalDate = settlementDate;
    } else if (expiryDate < endDate) {
      finalDate = expiryDate;
    }

    isIncludedSelectableRange = dayjs(dayjs(tempDisbursementDate).toDate()).isBetween(
      startDate,
      finalDate,
      'date',
      '[]',
    );
  }

  return isIncludedSelectableRange;
};

export const getDisabledRanges = (settlementDate: string, holidayLists: FinancierCalenderVOModel[]): Date[] => {
  return settlementDate
    ? ([...removeRangeFromHoliday(holidayLists), settlementDate && new Date(settlementDate)] as Date[])
    : removeRangeFromHoliday(holidayLists);
};

const isDisabledTempDisbursementDate = (
  tempDisbursementDate: string,
  settlementDate: string,
  holidayLists: FinancierCalenderVOModel[],
) => {
  const disabledRanges = getDisabledRanges(settlementDate, holidayLists);

  if (isEmpty(disabledRanges)) return false;

  return disabledRanges.some(date => date.getTime() === new Date(tempDisbursementDate).getTime());
};

export const isValidTempDisbursementDate = (
  tempDisbursementDate: string,
  dealerAgreementExpiryDate: string,
  settlementDate: string,
  holidayLists: FinancierCalenderVOModel[],
) => {
  return (
    isSelectableTempDisbursementDate(tempDisbursementDate, dealerAgreementExpiryDate, settlementDate) &&
    !isDisabledTempDisbursementDate(tempDisbursementDate, settlementDate, holidayLists)
  );
};

export const getFinancingApplicationStepWizardText = (): string => {
  const collateralType = getCollateralType();

  switch (collateralType) {
    case SUPPORTED_COLLATERAL_TYPE.BOTH:
      return i18n.t('text:Select_AR_or_Invoice');
    case SUPPORTED_COLLATERAL_TYPE.AR:
      return i18n.t('text:Select_AR');
    case SUPPORTED_COLLATERAL_TYPE.INVOICE:
      return i18n.t('text:Select_Invoice');
    default:
      return '';
  }
};
