import type { UseFormMethods } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import type { AnchorInvoiceBulkRegisterRequest } from 'utils/http/api/anchor/invoice-summaries/requests';
import type {
  DealerInvoiceSummaryRegisterRequest,
  InvoiceAdditionalColumnName,
} from 'utils/http/api/dealer/invoice-summaries/request';
import type { FinancierApproveInvoicePhaseRequest } from 'utils/http/api/financier/invoice-summaries/request';
import useValidation from 'utils/validation/useValidation';

interface AdditionalColumnName extends InvoiceAdditionalColumnName {}
type InvoiceSummaryRegisterRequest =
  | DealerInvoiceSummaryRegisterRequest
  | AnchorInvoiceBulkRegisterRequest
  | FinancierApproveInvoicePhaseRequest;

interface AdditionalColumnNameListType {
  control: UseFormMethods<InvoiceSummaryRegisterRequest>['control'];
  register: UseFormMethods<InvoiceSummaryRegisterRequest>['register'];
  errors: UseFormMethods<InvoiceSummaryRegisterRequest>['errors'];
  visibleAppendColumnButton: () => void;
  invisibleAppendColumnButton: () => void;
  removeColumn: (index: number) => void;
}

const AdditionalColumnNameList = ({
  control,
  register,
  errors,
  visibleAppendColumnButton,
  invisibleAppendColumnButton,
  removeColumn,
}: AdditionalColumnNameListType) => {
  const { t } = useTranslation(['format']);
  const { fields } = useFieldArray<AdditionalColumnName>({
    control,
    name: 'additionalColumnNameList',
  });

  const { getValidationClassName } = useValidation({
    errorFields: errors.additionalColumnNameList,
  });

  return (
    <>
      {fields.map((additionalColumnName, additionalColumnNameIndex, additionalColumnNameList) => {
        const lastAdditionalColumnName = additionalColumnNameIndex + 1 === additionalColumnNameList.length;
        const emptyFunc = () => {};

        return (
          <th
            key={additionalColumnName.id}
            onMouseEnter={lastAdditionalColumnName ? visibleAppendColumnButton : emptyFunc}
            onMouseLeave={lastAdditionalColumnName ? invisibleAppendColumnButton : emptyFunc}
            className={getValidationClassName('name', 'th', additionalColumnNameIndex)}
          >
            <div className="flex-center">
              <input
                className={`column-input ${getValidationClassName('name', 'input', additionalColumnNameIndex)}`}
                name={`additionalColumnNameList[${additionalColumnNameIndex}].name`}
                type="text"
                defaultValue={additionalColumnName.name}
                ref={register({
                  required: true,
                })}
                placeholder={t('text:Enter_Column_Name')}
              />
              <Button
                onClick={() => removeColumn(additionalColumnNameIndex)}
                style={{ width: '20px', height: '20px' }}
                className="flex-center ms-1"
                color={ButtonColorEnum.SECONDARY}
                variant={ButtonVariantEnum.OUTLINED}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </div>
          </th>
        );
      })}
    </>
  );
};

export default AdditionalColumnNameList;
