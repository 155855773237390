import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, COMMON_APPROVAL_TYPE, DEALER_IDENTIFIER_TYPE, LOAN_OPTION } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import { convertToAnchorAgreementResetData } from 'models/convertor/anchorAgreement';
import { formErrorHandler } from 'utils/error/manager';
import { isNilOrEmptyString } from 'utils/helpers';
import {
  requestApproveWaitingAnchorAgreement,
  requestCancelWaitingAnchorAgreement,
  requestReturnWaitingAnchorAgreement,
  requestUpdateWaitingAnchorAgreement,
} from 'utils/http/api/financier/waiting-anchor-agreements';
import type { UpdateFiWaitingAnchorAgreementDTO } from 'utils/http/api/financier/waiting-anchor-agreements/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getObjectValue } from 'utils/object';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import useAgreementViewModel from '../models/agreement/useAgreementViewModel';
import useAnchorClientInfoViewModel from '../models/agreement/useAnchorClientInfoViewModel';
import useDivisionsViewModel from '../models/divisions/useDivisionsViewModel';
import useExtraInformationViewModel from '../models/extraInformation/useExtraInformationViewModel';

const useWaitingDetailController = () => {
  const signInModel: SignInModel | null = getSignIn();
  const { branchId, authorityType } = signInModel ?? {};
  const { t } = useTranslation(['format']);
  const { show: showModal, id: modalId } = useModal();

  const methods = useForm<UpdateFiWaitingAnchorAgreementDTO>();
  const { reset, getValues, setError, clearErrors } = methods;

  const { supportedCollateralType, errorHandlerOfLocationState, waitingAnchorAgreementId, useAgreementInterface } =
    useExtraInformationViewModel();

  const {
    agreement,
    updateAllAgreement,
    isSearchedAgreement,
    fetchWaitingAgreement,
    updateIsSearchedAgreement,
    isFirstRegisteredWaitingAgreement,
  } = useAgreementViewModel();

  const { fetchDivisionRegistrable, fetchDivisionSelectOptions, updateDivisionName } = useDivisionsViewModel();

  const { anchorClientInfo } = useAnchorClientInfoViewModel();

  const { approvalType, returnReason } = agreement;

  const isInvoice = supportedCollateralType === COLLATERAL_TYPE.INVOICE;
  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const isSameBranch = branchId === agreement?.branchId;

  const isEditable =
    approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED &&
    authorityType === AUTHORITY_TYPE.OPERATOR &&
    isSameBranch;

  const isEditableStyle = useAgreementInterface ? isSearchedAgreement : isEditable;

  const getReadOnlyValue = useCallback(
    (parameterName?: string): boolean => {
      if (!useAgreementInterface || !isEditable) return false;

      const allAgreement = { ...agreement, ...anchorClientInfo };

      const agreementValue = getObjectValue({
        object: allAgreement,
        name: parameterName ?? '',
      });

      return !(isSearchedAgreement && isNilOrEmptyString(agreementValue));
    },
    [useAgreementInterface, isEditable, agreement, anchorClientInfo, isSearchedAgreement],
  );

  // Guide Message
  const getGuideMessageOptionsForRequestType = () => {
    const fixedMessage = isFirstRegisteredWaitingAgreement
      ? t('text:Registration_of_the_anchor_master_agreement_has_been_requested')
      : t('text:Modification_of_the_anchor_master_agreement_has_been_requested');

    if (isSameBranch) {
      switch (authorityType) {
        case AUTHORITY_TYPE.OPERATOR:
          return {
            message: [fixedMessage, t('text:To_cancel_the_request_please_click_on_the_Cancel_Request_button_below')],
          };

        case AUTHORITY_TYPE.AUTHORIZER:
          return {
            message: [
              fixedMessage,
              t(
                'text:Please_check_the_master_agreement_information_and_press_the_button_below_to_proceed_with_approval',
              ),
            ],
          };

        default:
          return { message: [fixedMessage] };
      }
    } else {
      return { message: [fixedMessage] };
    }
  };

  const getGuideMessageOptionsForReturnType = () => {
    let message: string[] = [];

    const fixedMessage = isFirstRegisteredWaitingAgreement
      ? t('text:The_master_agreement_registration_request_has_been_reverted')
      : t('text:The_master_agreement_modification_request_has_been_reverted');

    isEditable
      ? (message = [fixedMessage, t('text:Please_check_the_reason_for_the_revert_below_and_proceed_with_the_update')])
      : (message = [fixedMessage]);

    return {
      message,
      messageType: MessageType.ALERT,
      reason: returnReason,
      reasonTitle: t('text:REASON_FOR_REVERT'),
    };
  };

  const GuideMessageOption = (() => {
    if (approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) return getGuideMessageOptionsForRequestType();

    if (approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED) return getGuideMessageOptionsForReturnType();

    return null;
  })();

  const resetPage = async () => {
    if (isNil(waitingAnchorAgreementId) || errorHandlerOfLocationState()) return;

    const [waitingAgreement] = await Promise.all([
      fetchWaitingAgreement(waitingAnchorAgreementId),
      fetchDivisionRegistrable(),
    ]);

    if (waitingAgreement) {
      if (!isNil(waitingAgreement.anchorClientId) && isEditable && !useAgreementInterface) {
        await fetchDivisionSelectOptions(waitingAgreement.anchorClientId);
      }

      reset(convertToAnchorAgreementResetData(waitingAgreement));
      updateDivisionName(waitingAgreement.divisionName ?? '');
      updateIsSearchedAgreement(false);
      updateAllAgreement(waitingAgreement);
    }
  };

  const conditionChecker = (conditionAuthority: AUTHORITY_TYPE, conditionApprovalType: COMMON_APPROVAL_TYPE) => {
    if (authorityType !== conditionAuthority) return false;

    if (agreement?.approvalType !== conditionApprovalType) return false;

    return true;
  };

  const handleARRequiredFieldsValidation = () => {
    const { paymentSupport, settlementAccount } = getValues();
    /**
     * AR에서 필수인 필드 (조건부 필수 필드 포함) 중,
     * BE에서 다른 필드보다 후순위에 에러 검증이 되어 에러 객체가 늦게 내려오는 필드. 사용자 입장에서 다른 필드와 동시에 에러가 발생할 수 있도록 개발
     */
    const arRequiredFields = [
      { name: 'paymentSupport', value: paymentSupport, isRequired: true },
      { name: 'settlementAccount', value: settlementAccount, isRequired: paymentSupport === 'true' },
    ];

    arRequiredFields.forEach(({ value, name, isRequired }) => {
      if (isRequired && isNilOrEmptyString(value)) {
        setError(name, {
          message: t('text:This_information_is_required'),
        });
      } else clearErrors(name);
    });
  };

  // Change Request Button
  const updateAgreement = async () => {
    const data = getValues();

    const bankUserIdList = data.bankUserIdList as string;
    data.bankUserIdList = bankUserIdList ? bankUserIdList.split(',') : [];

    if (isAr) {
      data.loanOption = LOAN_OPTION.NONE;
      data.dealerIdentifierType = DEALER_IDENTIFIER_TYPE.TAX_CODE;
    }

    requestDTOParser(data);
    await requestUpdateWaitingAnchorAgreement(waitingAnchorAgreementId, data);
  };

  const showChangeRequestCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:Request_for_registration_approval_has_been_completed')}
            <br />
            {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
          </>
        ) : (
          <>
            {t('text:Request_for_modification_approval_has_been_completed')}
            <br />
            {t('text:Modification_will_be_completed_after_approval_by_the_Authorizer')}
          </>
        )}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleChangeRequestButtonClick = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:Would_you_like_to_request_approval_for_registration?')}
            <br />
            {t(
              'text:Please_note_that_if_modifications_occur_after_the_completion_of_the_request_the_approval_request_must_be_cancelled_and_processed_again',
            )}
          </>
        ) : (
          t('text:Would_you_like_to_request_approval_for_modification?')
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await updateAgreement();
            showChangeRequestCompletedModal();
          } catch (error) {
            showModal(error);
            formErrorHandler<UpdateFiWaitingAnchorAgreementDTO>(error, setError, clearErrors);
            if (isAr) handleARRequiredFieldsValidation();
          }
        },
      },
    );
  };

  // Cancel Button
  const showCancelCompletedModal = async () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:The_registration_request_has_been_cancelled')
          : t('text:The_modification_request_has_been_cancelled')}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleCancelButtonClick = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t(`text:Would_you_like_to_cancel_the_registration_request?`)
          : t('text:Would_you_like_to_cancel_the_modification_request?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await requestCancelWaitingAnchorAgreement(waitingAnchorAgreementId);
            showCancelCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  // Return Button
  const showReturnCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:The_registration_request_has_been_reverted')
          : t('text:The_modification_request_has_been_reverted')}
      </h6>,
      { closeBtnCb: resetPage },
    );
  };

  const handleReturnButtonClick = () => {
    let returnReason = '';

    showModal(
      <>
        <h6>
          {t('text:Would_you_like_to_revert_the_registration_request?')}
          <br />
          {t('text:Please_enter_the_reason_for_the_revert_below')}
        </h6>
        <ReasonModal
          modalId={modalId}
          getReturnReason={(reason: string) => {
            returnReason = reason;
          }}
        />
      </>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await requestReturnWaitingAnchorAgreement(waitingAnchorAgreementId, { returnReason });
            showReturnCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  // Confirm Button
  const showConfirmCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:The_Anchor_Master_Agreement_has_been_registered_successfully')}
            <br />
            {t('text:Please_check_the_registered_information_in_the_Registration_Completed_tab')}
          </>
        ) : (
          <>
            {t('text:The_Anchor_Master_Agreement_has_been_modified_successfully')}
            <br />
            {t('text:Please_check_the_modified_information_in_the_Registration_Completed_tab')}
          </>
        )}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleConfirmButtonClick = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:Would_you_like_to_approve_the_Anchor_Master_Agreement_registration_request?')
          : t('text:Would_you_like_to_approve_the_Anchor_Master_Agreement_modification_request?')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestApproveWaitingAnchorAgreement(waitingAnchorAgreementId);
            showConfirmCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  useEffect(() => {
    resetPage();
  }, []);

  return {
    isInvoice,
    isEditable,
    isSameBranch,
    isEditableStyle,
    isFirstRegisteredWaitingAgreement,
    useAgreementInterface,
    isRegistrationRequestButtonDisabled: !(useAgreementInterface ? isSearchedAgreement : true),
    GuideMessageOption,
    methods,
    approvalType,
    getReadOnlyValue,
    conditionChecker,
    handleCancelButtonClick,
    handleChangeRequestButtonClick,
    handleReturnButtonClick,
    handleConfirmButtonClick,
  };
};

export default useWaitingDetailController;
