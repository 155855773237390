import type React from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';

import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { WaitingAnchorAgreementVOModel } from 'models/vo/WaitingAnchorAgreementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemAnchorAgreementList } from 'utils/http/api/system/anchor-agreements';
import type { SystemAnchorAgreementListRequest } from 'utils/http/api/system/anchor-agreements/requests';
import { requestSystemWaitingAnchorAgreementList } from 'utils/http/api/system/waiting-anchor-agreements';
import type { SystemWaitingAnchorAgreementListRequest } from 'utils/http/api/system/waiting-anchor-agreements/requests';
import { setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

interface SystemMonitorAnchorAgreementListStateType {
  waitingForApprovalAnchorAgreementPage: Pageable<WaitingAnchorAgreementVOModel[]>;
  notCancelledWaitingForApprovalAnchorAgreementPage: Pageable<WaitingAnchorAgreementVOModel[]>;
  registrationCompletedAnchorAgreementPage: Pageable<AnchorAgreementVOModel[]>;
}

const WAITING_LIST_QS_KEY = 'waiting-list';
const COMPLETED_LIST_QS_KEY = 'completed-list';

export function useSystemMonitorAnchorAgreementListState() {
  const modal = useModal();

  // pageable
  const {
    pageable: waitingForApprovalAnchorAgreementPageable,
    setPageable: setWaitingForApprovalAnchorAgreementPageable,
  } = usePageable(WAITING_LIST_QS_KEY);
  const {
    pageable: registrationCompletedAnchorAgreementPageable,
    setPageable: setRegistrationCompletedAnchorAgreementPageable,
  } = usePageable(COMPLETED_LIST_QS_KEY);

  // data state
  const [dataState, setDataState] = useState<SystemMonitorAnchorAgreementListStateType>({
    registrationCompletedAnchorAgreementPage: {} as Pageable<AnchorAgreementVOModel[]>,
    waitingForApprovalAnchorAgreementPage: {} as Pageable<WaitingAnchorAgreementVOModel[]>,
    notCancelledWaitingForApprovalAnchorAgreementPage: {} as Pageable<WaitingAnchorAgreementVOModel[]>,
  });

  // useForm - search
  const systemWaitingAnchorAgreementListForm = useForm<SystemWaitingAnchorAgreementListRequest>();
  const systemRegisteredAnchorAgreementListForm = useForm<SystemAnchorAgreementListRequest>();

  const setWaitingTabSearchValue = (formSearchData?: SystemWaitingAnchorAgreementListRequest) => {
    const getSearchFormData = formSearchData ? formSearchData : systemWaitingAnchorAgreementListForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchAll = async (): Promise<void> => {
    setFormValues<SystemAnchorAgreementListRequest>(
      systemRegisteredAnchorAgreementListForm.setValue,
      getParsedSearchParams(COMPLETED_LIST_QS_KEY).formSearchData,
    );

    setFormValues<SystemWaitingAnchorAgreementListRequest>(
      systemWaitingAnchorAgreementListForm.setValue,
      getParsedSearchParams(WAITING_LIST_QS_KEY).formSearchData,
    );

    const registrationCompletedSearchData = systemRegisteredAnchorAgreementListForm.getValues();

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    const waitingQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingForApprovalAnchorAgreementPageable.currentPage,
        rowCount: waitingForApprovalAnchorAgreementPageable.sizePerPage,
      },
      WAITING_LIST_QS_KEY,
    );
    const completedQs = makeSearchParamsPattern(
      {
        ...registrationCompletedSearchData,
        pageNumber: registrationCompletedAnchorAgreementPageable.currentPage,
        rowCount: registrationCompletedAnchorAgreementPageable.sizePerPage,
      },
      COMPLETED_LIST_QS_KEY,
    );
    addSearchParams(waitingQs + completedQs);

    try {
      const [anchorAgreementPage, waitingAnchorAgreementPage, notCancelledWaitingAnchorAgreementPage] =
        await Promise.all([
          requestSystemAnchorAgreementList(
            registrationCompletedAnchorAgreementPageable.currentPage,
            registrationCompletedAnchorAgreementPageable.sizePerPage,
            registrationCompletedSearchData,
          ),
          requestSystemWaitingAnchorAgreementList(
            waitingForApprovalAnchorAgreementPageable.currentPage,
            waitingForApprovalAnchorAgreementPageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestSystemWaitingAnchorAgreementList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setDataState(prevState => ({
          ...prevState,
          registrationCompletedAnchorAgreementPage: anchorAgreementPage,
          waitingForApprovalAnchorAgreementPage: waitingAnchorAgreementPage,
          notCancelledWaitingForApprovalAnchorAgreementPage: notCancelledWaitingAnchorAgreementPage,
        }));

        setRegistrationCompletedAnchorAgreementPageable(anchorAgreementPage);
        setWaitingForApprovalAnchorAgreementPageable(waitingAnchorAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchWaiting = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    try {
      const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber: 1,
          rowCount: waitingForApprovalAnchorAgreementPageable.sizePerPage,
        },
        WAITING_LIST_QS_KEY,
      );

      const [waitingAnchorAgreementPage, notCancelledWaitingAnchorAgreementPage] = await Promise.all([
        await requestSystemWaitingAnchorAgreementList(
          1,
          waitingForApprovalAnchorAgreementPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        await requestSystemWaitingAnchorAgreementList(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setDataState(prevState => ({
          ...prevState,
          waitingForApprovalAnchorAgreementPage: waitingAnchorAgreementPage,
          notCancelledWaitingForApprovalAnchorAgreementPage: notCancelledWaitingAnchorAgreementPage,
        }));
        setWaitingForApprovalAnchorAgreementPageable(waitingAnchorAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const waitingForApprovalAnchorAgreementPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
        getParsedSearchParams(WAITING_LIST_QS_KEY).formSearchData,
      );
      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber,
          rowCount,
        },
        WAITING_LIST_QS_KEY,
      );
      const waitingAnchorAgreementPage = await requestSystemWaitingAnchorAgreementList(
        pageNumber,
        rowCount,
        getParsedSearchParams(WAITING_LIST_QS_KEY).formSearchData,
      );

      setDataState(prevState => ({
        ...prevState,
        waitingForApprovalAnchorAgreementPage: waitingAnchorAgreementPage,
      }));

      setWaitingForApprovalAnchorAgreementPageable(waitingAnchorAgreementPage);
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchRegisteredAnchorAgreement = async (
    pageNumber: number,
    rowCount: number,
    data: SystemAnchorAgreementListRequest,
  ) => {
    try {
      const anchorAgreementPage = await requestSystemAnchorAgreementList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        COMPLETED_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setDataState(prevState => ({
          ...prevState,
          registrationCompletedAnchorAgreementPage: anchorAgreementPage,
        }));

        setRegistrationCompletedAnchorAgreementPageable(anchorAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchRegistered = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();
    const data = systemRegisteredAnchorAgreementListForm.getValues();
    await fetchRegisteredAnchorAgreement(1, registrationCompletedAnchorAgreementPageable.sizePerPage, data);
  };

  const registrationCompletedAnchorAgreementPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    await fetchRegisteredAnchorAgreement(
      pageNumber,
      rowCount,
      getParsedSearchParams(COMPLETED_LIST_QS_KEY).formSearchData,
    );
  };

  return {
    fetchAll,
    state: dataState,
    onClickSearchWaiting,
    waitingForApprovalAnchorAgreementPaginate,
    waitingForApprovalAnchorAgreementPageable,
    systemWaitingAnchorAgreementListForm,
    onClickSearchRegistered,
    registrationCompletedAnchorAgreementPaginate,
    registrationCompletedAnchorAgreementPageable,
    systemRegisteredAnchorAgreementListForm,
  };
}
