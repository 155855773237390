import type { MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { LOAN_STATUS, SUCCESS_INVOICE_STATUS, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { StatisticsOfAnchorAgreementVOModel } from 'models/vo/StatisticsOfAnchorAgreementVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestFiAnchorStatisticsOfAnchorAgreementsList } from 'utils/http/api/financier/statistics-of-anchor-agreements';
import type { FinancierStatisticsOfAnchorAgreementListRequest } from 'utils/http/api/financier/statistics-of-anchor-agreements/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams } from 'utils/searchParams';
import type { ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import { useFiOverviewByAnchorListChannelTabConstants } from './constants';
import { FI_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY } from '../../FinancierOverviewByAnchorList';

interface FinancierOverviewByAnchorListChannelTabProps {
  onClickSearch: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  overviewByAnchorPageData?: Pageable<StatisticsOfAnchorAgreementVOModel[]>;
  pageable: PageableType;
  paginate: (selectedPageNumber: number, selectedRowCount: number) => void;
}

function FinancierOverviewByAnchorListChannelTab({
  onClickSearch,
  overviewByAnchorPageData,
  pageable,
  paginate,
}: FinancierOverviewByAnchorListChannelTabProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { register, reset } = useFormContext();
  const getProperty = useProperty<FinancierStatisticsOfAnchorAgreementListRequest>();

  const {
    INVOICE_TABLE_HEADER,
    INVOICE_BIG_TABLE_HEADER,
    excelColumnsInvoice,
    invoiceExcelMergedCells,
    invoicePdfMergedTableHeader,
    invoicePdfTableHeader,
  } = useFiOverviewByAnchorListChannelTabConstants();

  const onClickExport = async (e: any): Promise<void> => {
    e.preventDefault();

    try {
      const searchedData = getParsedSearchParams(FI_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY).formSearchData;
      searchedData.collateralType = SUPPORTED_COLLATERAL_TYPE.INVOICE;

      const anchorMonitoringPage = await requestFiAnchorStatisticsOfAnchorAgreementsList(
        0,
        EXCEL_EXPORT_MAX_ROW_COUNT,
        searchedData,
      );
      const anchorMonitoringPDFPage = anchorMonitoringPage.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: any[] = anchorMonitoringPage.content.map(item => ({
        anchorFinancierClientName: tableValueManage(item.anchorFinancierClientName),
        anchorFinancierClientCode: tableValueManage(item.anchorFinancierClientCode),
        contractNo: tableValueManage(item.contractNo),
        currencyType: tableValueManage(item.currencyType),
        currentLoanBalanceAmount: item.currentLoanBalanceAmount,
        currentDisbursedLoanCount: item.currentDisbursedLoanCount,
        currentSettlementWaitingInvoiceAmount: item.currentSettlementWaitingInvoiceAmount,
        currentSettlementWaitingInvoiceCount: item.currentSettlementWaitingInvoiceCount,
        registeredInvoiceAmount: item.registeredInvoiceAmount,
        registeredInvoiceCount: item.registeredInvoiceCount,
        totalDisbursedLoanAmount: item.totalDisbursedLoanAmount,
        totalDisbursedLoanCount: item.totalDisbursedLoanCount,
        totalUnsettledInvoiceAmount: item.totalUnsettledInvoiceAmount,
        totalUnsettledInvoiceCount: tableValueManage(item.totalUnsettledInvoiceCount),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumnsInvoice,
        options: {
          mergedCells: invoiceExcelMergedCells,
          rowHeight: [
            { position: 1, height: 20 },
            { position: 2, height: 15 },
          ],
        },
      };

      const renderInvoiceTableBodyResult = (): JSX.Element[] | undefined => {
        return anchorMonitoringPDFPage?.map((item, index) => (
          <Tr key={index} className="virtual-table-row">
            <Td data={item.anchorFinancierClientName} />
            <Td data={item.anchorFinancierClientCode} />
            <Td data={item.contractNo} />
            <Td data={item.currencyType} />
            <Td data={item.currentLoanBalanceAmount} format="number" />
            <Td data={item.currentDisbursedLoanCount} format="number" />
            <Td data={item.currentSettlementWaitingInvoiceAmount} format="number" />
            <Td data={item.currentSettlementWaitingInvoiceCount} format="number" />
            <Td data={item.registeredInvoiceAmount} format="number" />
            <Td data={item.registeredInvoiceCount} format="number" />
            <Td data={item.totalDisbursedLoanAmount} format="number" />
            <Td data={item.totalDisbursedLoanCount} format="number" />
            <Td data={item.totalUnsettledInvoiceAmount} format="number" />
            <Td data={item.totalUnsettledInvoiceCount} format="number" />
          </Tr>
        ));
      };

      const pdfExporterProps: PDFExporterProps = {
        mergedTableHeaders: [invoicePdfMergedTableHeader],
        tableHeaders: invoicePdfTableHeader,
        tableBody: renderInvoiceTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset();
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name={getProperty('anchorFinancierClientName')} ref={register} />
              <SearchLabel label={t('text:Anchor_Client_Code')} />
              <SearchInput name={getProperty('anchorFinancierClientCode')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
              <SearchInput name={getProperty('contractNo')} ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name={getProperty('currencyType')}
                ref={register}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {overviewByAnchorPageData?.totalElements ? overviewByAnchorPageData.totalElements : '0'}
          </p>
          <Button onClick={onClickExport} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={INVOICE_TABLE_HEADER} bigHeader={INVOICE_BIG_TABLE_HEADER} />
          <TableBody numOfCol={INVOICE_TABLE_HEADER.length}>
            {overviewByAnchorPageData?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.anchorFinancierClientName} />
                  <Td data={item.anchorFinancierClientCode} />
                  <Td data={item.contractNo} />
                  <Td data={item.currencyType} />
                  <td className="text-end">
                    {tableValueManage(
                      item.currentLoanBalanceAmount,
                      t('format:number', {
                        value: item.currentLoanBalanceAmount,
                      }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.currentDisbursedLoanCount,
                      t('format:number', { value: item.currentDisbursedLoanCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.FINANCING_LIST}
                      state={{
                        anchorClientName: item.anchorFinancierClientName,
                        anchorClientCode: item.anchorFinancierClientCode,
                        currencyType: item.currencyType,
                        loanStatusConditions: [LOAN_STATUS.DISBURSED, LOAN_STATUS.OVERDUE],
                        collateralType: item.collateralType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  <td className="text-end">
                    {tableValueManage(
                      item.currentSettlementWaitingInvoiceAmount,
                      t('format:number', { value: item.currentSettlementWaitingInvoiceAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.currentSettlementWaitingInvoiceCount,
                      t('format:number', { value: item.currentSettlementWaitingInvoiceCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.INVOICE_LIST}
                      state={{
                        anchorName: item.anchorFinancierClientName,
                        anchorClientCode: item.anchorFinancierClientCode,
                        currencyType: item.currencyType,
                        successInvoiceStatus: SUCCESS_INVOICE_STATUS.REGISTERED,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  <td className="text-end">
                    {tableValueManage(
                      item.registeredInvoiceAmount,
                      t('format:number', { value: item.registeredInvoiceAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.registeredInvoiceCount,
                      t('format:number', {
                        value: item.registeredInvoiceCount,
                      }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.INVOICE_LIST}
                      state={{
                        anchorName: item.anchorFinancierClientName,
                        anchorClientCode: item.anchorFinancierClientCode,
                        currencyType: item.currencyType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  <td className="text-end">
                    {tableValueManage(
                      item.totalDisbursedLoanAmount,
                      t('format:number', { value: item.totalDisbursedLoanAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.totalDisbursedLoanCount,
                      t('format:number', { value: item.totalDisbursedLoanCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.FINANCING_LIST}
                      state={{
                        anchorClientName: item.anchorFinancierClientName,
                        anchorClientCode: item.anchorFinancierClientCode,
                        currencyType: item.currencyType,
                        loanStatusConditions: [
                          LOAN_STATUS.DISBURSED,
                          LOAN_STATUS.OVERDUE,
                          LOAN_STATUS.EXPIRATION,
                          LOAN_STATUS.REPAID,
                        ],
                        collateralType: item.collateralType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  <td className="text-end">
                    {tableValueManage(
                      item.totalUnsettledInvoiceAmount,
                      t('format:number', { value: item.totalUnsettledInvoiceAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.totalUnsettledInvoiceCount,
                      t('format:number', { value: item.totalUnsettledInvoiceCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.INVOICE_LIST}
                      state={{
                        anchorName: item.anchorFinancierClientName,
                        anchorClientCode: item.anchorFinancierClientCode,
                        currencyType: item.currencyType,
                        successInvoiceStatus: SUCCESS_INVOICE_STATUS.UNSETTLED,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierOverviewByAnchorListChannelTab;
