import type Pageable from 'models/Pageable';
import type { ArPhaseVO, ArPhaseVOModel } from 'models/vo/ArPhaseVO';
import { formattingToArPhaseVOModel } from 'models/vo/ArPhaseVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

export async function requestFinancierArPhaseList(
  pageNumber: number,
  rowCount: number,
  arSummaryId: number,
): Promise<Pageable<ArPhaseVOModel[]>> {
  const response = await http.get<Pageable<ArPhaseVO[]>>({
    url: API_FI.AR_APPROVAL_PHASES.AR_PHASE_LIST,
    data: {
      pageNumber,
      rowCount,
      arSummaryId,
    },
  });

  const arSummaryList: ArPhaseVOModel[] = response.content.map((data: ArPhaseVO) => formattingToArPhaseVOModel(data));

  return {
    ...response,
    content: arSummaryList,
  };
}
