import { useTranslation } from 'react-i18next';

import Form, { SizeType } from 'components/stateless/Form';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';

const FinancingDuration = () => {
  const { t } = useTranslation();

  return (
    <>
      <SubTitle>{t('text:FINANCING_DURATION')}</SubTitle>
      <Content>
        <Row>
          <Form.Control name="loanTermType">
            <Cell>
              <Form.Label position="top">{t('text:Is_the_financing_duration_flexible_or_fixed?')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_the_financing_duration_is_fixed_or_it_can_be_flexibly_adjusted_during_financing_application',
                )}
              </Form.Description>
              r
            </Cell>
            <Border>
              <Content isInnerStyle>
                <Row>
                  <Form.Control name="minimumLoanRange">
                    <Form.Label>{t('text:Minimum_Days')}</Form.Label>
                    <Cell>
                      <Form.NumberInput numberType="integer" fieldSize={SizeType.SM} textAlign="text-right" />
                    </Cell>
                  </Form.Control>
                </Row>
                <Row>
                  <Form.Control name="maximumLoanRange">
                    <Form.Label>{t('text:Maximum_Days')}</Form.Label>
                    <Cell>
                      <Form.NumberInput numberType="integer" fieldSize={SizeType.SM} textAlign="text-right" />
                    </Cell>
                  </Form.Control>
                </Row>
              </Content>
            </Border>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="disbursementDateOverAllowable">
            <Cell>
              <Form.Label position="top">{t('text:Financing_Disbursement_Date')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_the_Partner_can_apply_for_financing_that_will_be_repaid_after_the_master_agreement_expires',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio>
              <Form.Option label={t('text:Can_exceed_agreement_expiration_date')} value="true" />
              <Form.Option label={t('text:Must_be_before_agreement_expiration')} value="false" />
            </Form.Radio>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="repaymentDateOverAllowable">
            <Cell>
              <Form.Label position="top">{t('text:Financing_Repayment_Date')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_the_Partner_can_apply_for_financing_that_will_be_repaid_after_the_master_agreement_expires',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio>
              <Form.Option label={t('text:Can_exceed_agreement_expiration_date')} value="true" />
              <Form.Option label={t('text:Must_be_before_agreement_expiration')} value="false" />
            </Form.Radio>
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default FinancingDuration;
