import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import { requestFiAnchorPartnerAccountList } from 'utils/http/api/financier/anchor-partner-accounts';
import { requestFiAnchorPartnerDetail } from 'utils/http/api/financier/anchor-partners';
import useModal from 'utils/modal/useModal';

function FinancierPotentialSupplyChainPartnerRegistrationConfirmedDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const anchorPartnerId = (useParams() as any).anchorPartnerId as number;

  const [anchorPartnerData, setAnchorPartnerData] = useState<AnchorPartnerVOModel>();
  const [designatedBankAccountInfo, setDesignatedBankAccountInfo] = useState<Pageable<AnchorPartnerAccountVOModel[]>>();

  const { pageable, setPageable } = usePageable();

  const DESINGATED_BANK_ACCOUNT_INFO_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Registered_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Responsible_Branch_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Branch_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Holder'),
      colWidths: 100,
    },

    {
      headerText: t('text:Bank_Account_Validity'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  useEffect(() => {
    if (mounted) {
      fetchAll(pageable.currentPage, pageable.sizePerPage);
    }
  }, [mounted]);

  async function fetchAll(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const [anchorPartnerData, designatedBankAccountData] = await Promise.all([
        requestFiAnchorPartnerDetail(anchorPartnerId),
        requestFiAnchorPartnerAccountList(pageNumber, rowCount, {
          anchorPartnerId: anchorPartnerId,
        }),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorPartnerData(anchorPartnerData);
        setDesignatedBankAccountInfo(designatedBankAccountData);
        setPageable(designatedBankAccountData);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const renderSupplyChainPartnerInformation = (): JSX.Element => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Supplier_Information`)} />
          <FormBorder>
            <FormContents>
              <div className="row">
                <FormValue label={t(`text:Supplier_Name`)} value={anchorPartnerData?.name} />
                <FormValue label={t(`text:Tax_Code`)} value={anchorPartnerData?.partnerTaxCode} />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Company_Registration_Number`)}
                  value={anchorPartnerData?.partnerBusinessCode}
                />
                <FormValue col={3} label={t(`text:Telephone`)} value={anchorPartnerData?.telephone} />
                <FormValue col={3} label={t(`text:Fax`)} value={anchorPartnerData?.fax} />
              </div>
              <div className="row">
                <FormValue label={t(`text:Legal_Representative_Name`)} value={anchorPartnerData?.representativeName} />
                <FormValue
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  value={anchorPartnerData?.representativePosition}
                />
                <FormValue
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  value={anchorPartnerData?.representativeEmail}
                />
              </div>
              <div className="row">
                <FormValue col={12} label={t(`text:Registered_Office_Address`)} value={anchorPartnerData?.address} />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderDesignatedBankAccountInformationforAPSettlementTable = (): JSX.Element => {
    const paginate = async (pageNumber: number, rowCount: number) => {
      try {
        const designatedBankAccountData = await requestFiAnchorPartnerAccountList(pageNumber, rowCount, {
          anchorPartnerId: anchorPartnerId,
        });

        ReactDOM.unstable_batchedUpdates(() => {
          setDesignatedBankAccountInfo(designatedBankAccountData);
          setPageable(designatedBankAccountData);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    return (
      <div className="content-area">
        <SectionTitle title={t(`text:Designated_Bank_Account_Information_for_AP_Settlement`)} />
        <TableBorder>
          <TableHeader header={DESINGATED_BANK_ACCOUNT_INFO_TABLE_HEADERS} />
          <TableBody numOfCol={DESINGATED_BANK_ACCOUNT_INFO_TABLE_HEADERS.length}>
            {designatedBankAccountInfo?.content.map((item, index) => (
              <Tr key={index}>
                <Td data={item.createdDateTime} format="datetime" />
                <Td data={item.anchorAgreementContractNo} />
                <Td data={item.currencyType} />
                <Td data={item.responsibleBranchCode} />
                <Td data={item.responsibleBranchName} />
                <Td data={item.accountBankCode} />
                <Td data={item.accountBankName} />
                <Td data={item.accountBranchName} />
                <Td data={item.account} />
                <Td data={item.accountOwner} />
                <Td data={item.accountBankCodeId ? 'Y' : 'N'} />
                <TdLink
                  path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_CONFIRMED_DETAIL_BUILD_PATH(
                    item.anchorPartnerAccountId,
                  )}
                />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={anchorPartnerData?.name} />
      <GuideMessage
        message={[
          t(
            'text:If_the_Suppliers_bank_account_information_is_incorrect_proceed_to_the_bank_account_details_page_and_revise_the_information',
          ),
        ]}
      />
      {renderSupplyChainPartnerInformation()}
      {renderDesignatedBankAccountInformationforAPSettlementTable()}
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerRegistrationConfirmedDetail;
