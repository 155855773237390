import { useCallback, useEffect, useState } from 'react';

import { isNil } from 'lodash-es';

import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import { PAGE_TYPE } from '../../models/extraInformation/Context';
import useExtraInformationViewModel from '../../models/extraInformation/useExtraInformationViewModel';
import useAnchorAgreementInfoViewModel from '../../models/financingOption/useAnchorAgreementInfoViewModel';
import useFinancingOptionViewModel from '../../models/financingOption/useFinancingOptionViewModel';

const useFinancierController = () => {
  const { show: showModal } = useModal();

  const [financierName, setFinancierName] = useState<string>('');

  const { anchorAgreementInfo } = useAnchorAgreementInfoViewModel();
  const { pageType } = useExtraInformationViewModel();
  const isRegistrationPage = pageType === PAGE_TYPE.REGISTER;

  const { anchorFinancingOption } = useFinancingOptionViewModel();

  const fetchFinancierName = useCallback(async () => {
    const enterpriseId = getSignIn()?.enterpriseId;

    if (isNil(enterpriseId)) return;

    try {
      const { financierName } = await requestFinancierSettingData(enterpriseId);
      setFinancierName(financierName);
    } catch (error) {
      showModal(error);
    }
  }, [showModal]);

  useEffect(() => {
    if (isRegistrationPage) {
      fetchFinancierName();
    }
  }, [isRegistrationPage]);

  return {
    data: {
      financierName: isRegistrationPage ? financierName : anchorFinancingOption.financierName,
      branchCode: anchorAgreementInfo.branchCode,
      branchName: anchorAgreementInfo.branchName,
      branchTelephone: anchorAgreementInfo.branchTelephone,
      branchFax: anchorAgreementInfo.branchFax,
      branchAddress: anchorAgreementInfo.branchAddress,
    },
  };
};

export default useFinancierController;
