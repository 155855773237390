import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder, FormContents, FormValue } from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import useLocationState from 'hooks/useLocationState';
import { REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING, REG_EMAIL, REG_PHONE } from 'utils/validation/regExp';
import useValidation from 'utils/validation/useValidation';

import AnchorDealerRegistrationStep2ExcelForm from './AnchorDealerRegistrationStep2ExcelForm';
import useAnchorDealerRegistrationStep2Page from './useAnchorDealerRegistrationStep2Page';

import type { TFunction } from 'i18next';

export const getFieldNames = (t: TFunction) => {
  return {
    name: {
      name: t('text:Dealer_Name'),
      width: '200px',
    },
    taxCode: {
      name: t('text:Dealer_Tax_Code'),
      width: '150px',
    },
    representativeName: {
      name: t('text:Legal_Representative_Name'),
      width: '200px',
    },
    representativeEmail: {
      name: t('text:Legal_Representative_Email'),
      width: '200px',
    },
    telephone: {
      name: t('text:Telephone'),
      width: '120px',
    },
    address: {
      name: t('text:Registered_Office_Address'),
      width: '200px',
    },
  };
};
function AnchorDealerRegistrationStep2Page() {
  const { t } = useTranslation(['format']);

  const [locationState] = useLocationState<{
    financierId: number;
    financierName: string;
  }>(['financierId', 'financierName']);

  const [isDirectInput, setIsDirectInput] = useState(true);

  const {
    formMethods,
    useFieldArrayMethods,
    appendRow,
    removeRow,
    handleCheckAll,
    handleCheckChange,
    checkedRows,
    validateDuplicateTaxCode,
    handleClickCancelButton,
    handleClickRegisterButton,
    checkedValidation,
  } = useAnchorDealerRegistrationStep2Page();

  const { register, errors } = formMethods;

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: errors.waitingAnchorDealers,
    rule: 'dealerRegister',
  });

  const fieldNames = getFieldNames(t);

  const { fields } = useFieldArrayMethods;
  const onClickRegistrationMethodRadioButton = (value: boolean) => {
    if (value !== isDirectInput) {
      setIsDirectInput(value);
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Dealer_Registration')} />
      <div className="content-area">
        <StepWizard nth={2} title={[t('text:Select_Financier'), t('text:Register_Dealer_Information')]} />
      </div>
      <GuideMessage
        message={[t('text:Check_that_the_Dealer_tax_code_is_correct_before_clicking_on_the_Register_button_below')]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Financier_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue col={4} label={t('text:Financier_Name')} value={locationState?.financierName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Dealer_Information')} />
        <h3>{t('text:Select_the_registration_method')}</h3>
        <div className="invoice-radio-group">
          <RadioButton
            id="direct-input"
            name="registration-method"
            checked={isDirectInput}
            onChange={() => onClickRegistrationMethodRadioButton(true)}
          >
            {t('text:Direct_Input')}
          </RadioButton>
          <RadioButton
            id="file-upload"
            name="registration-method"
            checked={!isDirectInput}
            onChange={() => onClickRegistrationMethodRadioButton(false)}
          >
            {t('text:File_Upload')}
          </RadioButton>
        </div>
        {!isDirectInput && (
          <FormProvider {...formMethods}>
            <AnchorDealerRegistrationStep2ExcelForm />
          </FormProvider>
        )}
      </div>
      <div className="content-area">
        <div className="flex-end mb-3">
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={removeRow}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
          <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>

        <div className="table-overflow-scroll">
          <table className="table-border">
            <colgroup>
              <col style={{ width: '70px' }} />
              {values(fieldNames).map(({ width }, index) => (
                <col key={index} style={{ width }} />
              ))}
              {checkedValidation.length > 0 && <col style={{ width: '200px' }} />}
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <div className="text-center">
                    <input
                      className="form-check-input m-0"
                      type="checkbox"
                      id="allCheck2"
                      onChange={handleCheckAll}
                      checked={fields.length > 0 ? checkedRows.length === fields.length : false}
                    />
                  </div>
                </th>
                {values(fieldNames).map(({ name }, index) => (
                  <th key={index} scope="col">
                    {name}
                  </th>
                ))}
                {checkedValidation.length > 0 && <th scope="col">{t('text:Registration_Result')}</th>}
              </tr>
            </thead>
            <tbody>
              {fields.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td className="bg-sub100" key={index}>
                      <div className="text-center">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          id={`check-${index}`}
                          onChange={e => handleCheckChange(e, index)}
                          checked={checkedRows.includes(index)}
                        />
                      </div>
                    </td>
                    <td className={getValidationClassName('name', 'td', index)}>
                      <input
                        className={getValidationClassName('name', 'input', index)}
                        name={`waitingAnchorDealers.${index}.name`}
                        defaultValue={item.name}
                        ref={register({
                          required: true,
                          maxLength: 150,
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('taxCode', 'td', index)}>
                      <input
                        className={getValidationClassName('taxCode', 'input', index)}
                        name={`waitingAnchorDealers.${index}.taxCode`}
                        defaultValue={item.taxCode}
                        ref={register({
                          required: true,
                          maxLength: 20,
                          pattern: REG_ALL_CHARACTER_AND_NUMBER_AND_EMPTY_STRING,
                          validate: value => validateDuplicateTaxCode(value, index),
                        })}
                      />
                    </td>

                    <td className={getValidationClassName('representativeName', 'td', index)}>
                      <input
                        className={getValidationClassName('representativeName', 'input', index)}
                        name={`waitingAnchorDealers.${index}.representativeName`}
                        defaultValue={item.representativeName}
                        ref={register({
                          required: true,
                          maxLength: 150,
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('representativeEmail', 'td', index)}>
                      <input
                        className={getValidationClassName('representativeEmail', 'input', index)}
                        name={`waitingAnchorDealers.${index}.representativeEmail`}
                        defaultValue={item.representativeEmail}
                        ref={register({
                          required: true,
                          maxLength: 40,
                          pattern: REG_EMAIL,
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('telephone', 'td', index)}>
                      <input
                        className={getValidationClassName('telephone', 'input', index)}
                        name={`waitingAnchorDealers.${index}.telephone`}
                        defaultValue={item.telephone}
                        ref={register({
                          required: true,
                          minLength: 6,
                          maxLength: 20,
                          pattern: REG_PHONE,
                        })}
                      />
                    </td>
                    <td className={getValidationClassName('address', 'td', index)}>
                      <input
                        className={getValidationClassName('address', 'input', index)}
                        name={`waitingAnchorDealers.${index}.address`}
                        defaultValue={item.address}
                        ref={register({
                          required: true,
                          maxLength: 1000,
                        })}
                      />
                    </td>
                    {checkedValidation[index] ? getValidationResult(index) : checkedValidation.length > 0 && <td />}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
            onClick={handleClickCancelButton}
          >
            {t('text:Cancel')}
          </Button>
          <Button className="ms-2" size={ButtonSizeEnum.LG} onClick={handleClickRegisterButton}>
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AnchorDealerRegistrationStep2Page;
