import type { ReactNode } from 'react';

import './Table.scss';

interface TableBorderPropsType {
  className?: string;
  children?: ReactNode;
  scrollYMaxHeight?: number;
}

function TableBorder({ children, className, scrollYMaxHeight }: TableBorderPropsType) {
  return (
    <div
      className="table-overflow-scroll"
      style={{ maxHeight: scrollYMaxHeight ? `${scrollYMaxHeight}px` : 'initial' }}
    >
      <table className={`table-border` + ` ${className}`}>{children}</table>
    </div>
  );
}

export default TableBorder;
