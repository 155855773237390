import type Pageable from 'models/Pageable';
import { formattingToWaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import type { WaitingAnchorDealerVO, WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierWaitingAnchorDealerApproveRequest, FinancierWaitingAnchorDealerRequest } from './request';
import type { FinancierWaitingAnchorDealerListRequest } from './request';

export async function requestFinancierWaitingAnchorDealerList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierWaitingAnchorDealerListRequest,
): Promise<Pageable<WaitingAnchorDealerVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorDealerVO[]>>({
    url: API_FI.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorDealerVOModel(data)),
  };
}

export async function requestFinancierWaitingAnchorDealerRegister(
  data: FinancierWaitingAnchorDealerRequest,
): Promise<WaitingAnchorDealerVOModel[]> {
  const response = await http.post<WaitingAnchorDealerVO[]>({
    url: API_FI.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS,
    data,
  });

  return response.map(data => formattingToWaitingAnchorDealerVOModel(data));
}

export async function requestFinancierWaitingAnchorDealerCancel(waitingAnchorDealerIds: number[]): Promise<void> {
  await http.put({
    url: API_FI.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_CANCEL,
    data: {
      waitingAnchorDealerIds,
    },
  });
}

export async function requestFinancierWaitingAnchorDealerReject(
  waitingAnchorDealerIds: number[],
  rejectReason: string,
): Promise<void> {
  await http.put({
    url: API_FI.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_REJECT,
    data: {
      waitingAnchorDealerIds,
      rejectReason,
    },
  });
}

export async function requestFinancierWaitingAnchorDealerApprove(
  data: FinancierWaitingAnchorDealerApproveRequest,
): Promise<WaitingAnchorDealerVOModel[]> {
  const response = await http.put<WaitingAnchorDealerVO[]>({
    url: API_FI.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_APPROVE_REGISTER,
    data,
  });

  return response.map(data => formattingToWaitingAnchorDealerVOModel(data));
}

export async function requestFinancierWaitingAnchorDealerDetail(
  waitingAnchorDealerId: number,
): Promise<WaitingAnchorDealerVOModel> {
  const response = await http.get<WaitingAnchorDealerVO>({
    url: API_FI.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_DETAIL(waitingAnchorDealerId),
  });

  return formattingToWaitingAnchorDealerVOModel(response);
}
