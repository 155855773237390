import { useTranslation } from 'react-i18next';

import DownloadButton from 'components/stateless/Button/DownloadButton';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { LoanEtcAttachmentVOModel } from 'models/vo/LoanEtcAttachmentVO';

import { useSystemFinancingDetailAdditionalDocumentsLogics } from './logics';

interface SystemFinancingDetailAdditionalDocumentsProps {
  loanId: number;
  loanEtcAttachmentListData: LoanEtcAttachmentVOModel[];
}
function SystemFinancingDetailAdditionalDocuments({
  loanId,
  loanEtcAttachmentListData,
}: SystemFinancingDetailAdditionalDocumentsProps) {
  const { t } = useTranslation(['format']);
  const { onClickDownloadLoanEtc } = useSystemFinancingDetailAdditionalDocumentsLogics(loanId);

  const ADDITIONAL_DOCUMENT_TABLE_HEADERS: HeaderType[] = [
    { headerText: '', colWidths: 70 },
    { headerText: t('text:File_Name'), colWidths: 180 },
    { headerText: t('text:Note'), colWidths: 180 },
    { headerText: '', colWidths: 120 },
  ];

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Additional_Documents')} />
      <TableBorder>
        <TableHeader header={ADDITIONAL_DOCUMENT_TABLE_HEADERS} />
        <TableBody numOfCol={ADDITIONAL_DOCUMENT_TABLE_HEADERS.length}>
          {loanEtcAttachmentListData?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td className="text-center" data={i + 1} />
                <Td data={item.loanEtcAttachmentName} />
                <Td data={item.description} />
                <Td className="text-center">
                  <DownloadButton onClick={e => onClickDownloadLoanEtc(e, item.loanEtcAttachmentId)} />
                </Td>
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
    </div>
  );
}
export default SystemFinancingDetailAdditionalDocuments;
