import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import LoanAmountSettingModal from 'components/stateless/Modal/common/loan/LoanAmountSettingModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE, CURRENCY_TYPE, DAY_COUNT_CONVENTION } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { FinancierCurrencyCommonSettingVOModel } from 'models/vo/FinancierCurrencyCommonSettingVO';
import { formErrorHandler } from 'utils/error/manager';
import { convertToString } from 'utils/formatter';
import {
  requestFinancierFinancierCommonSettingList,
  requestFinancierFinancierCommonSettingUpdate,
} from 'utils/http/api/financier/financier-common-setting';
import type { FinancierCommonSettingUpdateRequest } from 'utils/http/api/financier/financier-common-setting/requests';
import {
  requestFinancierFinancierCurrencyCommonSettingCreate,
  requestFinancierFinancierCurrencyCommonSettingDelete,
  requestFinancierFinancierCurrencyCommonSettingList,
  requestFinancierFinancierCurrencyCommonSettingModify,
} from 'utils/http/api/financier/financier-currency-common-setting';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getCurrencyType, getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

const useConstants = () => {
  const { t } = useTranslation(['format']);
  const userAuthority = getSignIn()?.authorityType;

  const tableHeaderList: HeaderType[] = [
    {
      headerText: t('text:Currency'),
      colWidths: 200,
    },
    {
      headerText: t('text:Minimum_Amount'),
    },
    {
      headerText: t('text:Edit'),
      colWidths: 100,
    },
    {
      headerText: t('text:Delete'),
      colWidths: 100,
    },
  ];

  const notAdminTableHeaderList: HeaderType[] = [
    {
      headerText: t('text:Currency'),
      colWidths: 200,
    },
    {
      headerText: t('text:Minimum_Amount'),
    },
  ];

  return {
    t,
    tableHeaderList,
    notAdminTableHeaderList,
    userAuthority,
  };
};

function FinancierFinancingSetting() {
  const mounted = useMounted();
  const modal = useModal();
  const { t, tableHeaderList, notAdminTableHeaderList, userAuthority } = useConstants();

  const [financierCurrencyCommonSettingPage, setFinancierCurrencyCommonSettingPage] =
    useState<Pageable<FinancierCurrencyCommonSettingVOModel[]>>();
  const [financierCommonSettingData, setFinancierCommonSettingData] = useState<FinancierCommonSettingVOModel>(
    {} as FinancierCommonSettingVOModel,
  );
  const { pageable, setPageable } = usePageable();
  const [earlyRepaymentEditable, setEarlyRepaymentEditable] = useState<boolean>(false);
  const [financierInterestCalcEditable, setFinancierInterestCalcEditable] = useState<boolean>(false);
  const [financierRequestSettingEditable, setFinancierRequestSettingEditable] = useState<boolean>(false);

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const { register, reset, getValues, errors, setError, clearErrors, control, setValue } =
    useForm<FinancierCommonSettingUpdateRequest>({
      mode: 'onSubmit',
      shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
    });

  const fetchAll = async (pageNumber: number = 1, rowCount: number = 10): Promise<void> => {
    try {
      const financierCurrencyCommonSettingPageResponse = await requestFinancierFinancierCurrencyCommonSettingList(
        pageNumber,
        rowCount,
      );
      const financierCommonSettingDataResponse = await requestFinancierFinancierCommonSettingList();
      const {
        minimumPeriodForVendorFinancingEarlyRepayment,
        minimumPeriodForDealerFinancingEarlyRepayment,
        vendorDayCountConvention,
        dealerDayCountConvention,
        invoiceFinancingAmountAdjustable,
      } = financierCommonSettingDataResponse;

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierCurrencyCommonSettingPage(financierCurrencyCommonSettingPageResponse);
        setPageable(financierCurrencyCommonSettingPageResponse);
        setFinancierCommonSettingData(financierCommonSettingDataResponse);

        reset({
          minimumPeriodForVendorFinancingEarlyRepayment,
          minimumPeriodForDealerFinancingEarlyRepayment,
          vendorDayCountConvention,
          dealerDayCountConvention,
          invoiceFinancingAmountAdjustable: convertToString(invoiceFinancingAmountAdjustable),
        });
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const registeredCurrencyTypes = (): CURRENCY_TYPE[] => {
    return financierCurrencyCommonSettingPage?.content?.map(({ currencyType }) => currencyType)
      ? financierCurrencyCommonSettingPage?.content?.map(({ currencyType }) => currencyType)
      : [];
  };

  const onClickLoanAmountNew = (e: any): void => {
    e.preventDefault();

    let currencyType: CURRENCY_TYPE;
    let minimumLoanAmount: number;

    const loanAmountSettingHandler = (newCurrencyType?: CURRENCY_TYPE, newMinimumLoanAmount?: number): void => {
      if (newCurrencyType) {
        currencyType = newCurrencyType;
      }

      if (newMinimumLoanAmount) {
        minimumLoanAmount = newMinimumLoanAmount;
      }
    };

    const onClickLoanAmountConfirm = async (): Promise<void> => {
      try {
        await requestFinancierFinancierCurrencyCommonSettingCreate({
          currencyType,
          minimumLoanAmount,
        });

        fetchAll();
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <LoanAmountSettingModal
        dataHandler={loanAmountSettingHandler}
        registeredCurrencyTypes={registeredCurrencyTypes()}
        modalId={modal.id}
      />,
      {
        title: t('text:Loan_Amount_Setting'),
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Save'),
        confirmBtnCb: () => onClickLoanAmountConfirm(),
      },
    );
  };

  const onClickLoanAmountEdit = (e: any, item: FinancierCurrencyCommonSettingVOModel): void => {
    e.preventDefault();

    let { currencyType } = item;
    let { minimumLoanAmount }: { minimumLoanAmount?: number } = item;

    const loanAmountSettingHandler = (newCurrencyType?: CURRENCY_TYPE, newMinimumLoanAmount?: string | null): void => {
      if (newCurrencyType) {
        currencyType = newCurrencyType;
      }

      if (newMinimumLoanAmount !== undefined) {
        minimumLoanAmount = newMinimumLoanAmount === null ? undefined : Number(newMinimumLoanAmount);
      }
    };

    const onClickLoanAmountConfirm = async (): Promise<void> => {
      try {
        await requestFinancierFinancierCurrencyCommonSettingModify(item.financierCurrencyCommonSettingId, {
          currencyType,
          minimumLoanAmount,
        });

        fetchAll();
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <LoanAmountSettingModal
        financierCurrencyCommonSettingId={item.financierCurrencyCommonSettingId}
        currencyType={item.currencyType}
        registeredCurrencyTypes={registeredCurrencyTypes()}
        minimumLoanAmount={item.minimumLoanAmount}
        dataHandler={loanAmountSettingHandler}
        modalId={modal.id}
      />,
      {
        title: t('text:Loan_Amount_Setting'),
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Save'),
        confirmBtnCb: () => onClickLoanAmountConfirm(),
      },
    );
  };

  const onClickLoanAmountDelete = async (e: any, financierCurrencyCommonSettingId: number) => {
    e.preventDefault();
    try {
      await requestFinancierFinancierCurrencyCommonSettingDelete(financierCurrencyCommonSettingId);
      fetchAll();
    } catch (e) {
      modal.show(e);
    }
  };

  const renderLoanAmountSettingResult = (): JSX.Element[] | undefined => {
    return financierCurrencyCommonSettingPage?.content.map((item, index: number) => (
      <Tr key={index}>
        <Td data={item.currencyType} />
        <Td data={item.minimumLoanAmount} format="number" />
        {userAuthority === AUTHORITY_TYPE.ADMIN && (
          <>
            <Td className="information-table-more">
              <Button
                size={ButtonSizeEnum.SM}
                onClick={e => {
                  onClickLoanAmountEdit(e, item);
                }}
                bold
              >
                {t('text:Edit')}
              </Button>
            </Td>
            <Td className="information-table-more">
              <Button
                size={ButtonSizeEnum.SM}
                color={ButtonColorEnum.SECONDARY}
                onClick={e => {
                  onClickLoanAmountDelete(e, item.financierCurrencyCommonSettingId);
                }}
                bold
              >
                {t('text:Delete')}
              </Button>
            </Td>
          </>
        )}
      </Tr>
    ));
  };

  const onClickFinancierInterestCalcEdit = (e: any) => {
    e.preventDefault();
    setFinancierInterestCalcEditable(true);
  };

  const onClickFinancierInterestCalcCancel = (e: any) => {
    e.preventDefault();

    modal.show(<h6>{t('text:Would_you_like_to_cancel_this_process?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: () => {
        setFinancierInterestCalcEditable(false);

        setValue('vendorDayCountConvention', financierCommonSettingData?.vendorDayCountConvention);
        setValue('dealerDayCountConvention', financierCommonSettingData?.dealerDayCountConvention);
      },
    });
  };

  const onClickFinancierInterestCalcSave = async () => {
    try {
      const { vendorDayCountConvention, dealerDayCountConvention } = getValues();
      const {
        minimumPeriodForVendorFinancingEarlyRepayment,
        minimumPeriodForDealerFinancingEarlyRepayment,
        invoiceFinancingAmountAdjustable,
      } = financierCommonSettingData;

      const formData: FinancierCommonSettingUpdateRequest = {
        vendorDayCountConvention,
        dealerDayCountConvention,
        invoiceFinancingAmountAdjustable: convertToString(invoiceFinancingAmountAdjustable),
        minimumPeriodForVendorFinancingEarlyRepayment,
        minimumPeriodForDealerFinancingEarlyRepayment,
      };
      requestDTOParser(formData);
      const updatedData = await requestFinancierFinancierCommonSettingUpdate(
        financierCommonSettingData?.financierCommonSettingId!,
        formData,
      );

      modal.show(<h6>{t('text:The_requested_change_is_successfully_applied')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnCb: () => {
          setValue('vendorDayCountConvention', vendorDayCountConvention);
          setValue('dealerDayCountConvention', dealerDayCountConvention);
          setFinancierCommonSettingData(updatedData);
          setFinancierInterestCalcEditable(false);
        },
      });
    } catch (e) {
      modal.show(e);

      formErrorHandler<FinancierCommonSettingUpdateRequest>(e, setError, clearErrors);
    }
  };

  const onClickEarlyRepaymentEdit = (e: any) => {
    e.preventDefault();
    setEarlyRepaymentEditable(true);
  };

  const onClickEarlyRepaymentCancel = (e: any) => {
    e.preventDefault();

    modal.show(<h6>{t('text:Would_you_like_to_cancel_this_process?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: () => {
        setEarlyRepaymentEditable(false);
        setValue(
          'minimumPeriodForVendorFinancingEarlyRepayment',
          financierCommonSettingData?.minimumPeriodForVendorFinancingEarlyRepayment,
        );
        setValue(
          'minimumPeriodForDealerFinancingEarlyRepayment',
          financierCommonSettingData?.minimumPeriodForDealerFinancingEarlyRepayment,
        );
      },
    });
  };

  const onClickEarlyRepaymentSave = async () => {
    const { minimumPeriodForVendorFinancingEarlyRepayment, minimumPeriodForDealerFinancingEarlyRepayment } =
      getValues();
    try {
      const { vendorDayCountConvention, dealerDayCountConvention, invoiceFinancingAmountAdjustable } =
        financierCommonSettingData;

      const formData: FinancierCommonSettingUpdateRequest = {
        minimumPeriodForVendorFinancingEarlyRepayment,
        minimumPeriodForDealerFinancingEarlyRepayment,
        vendorDayCountConvention,
        dealerDayCountConvention,
        invoiceFinancingAmountAdjustable: convertToString(invoiceFinancingAmountAdjustable),
      };
      requestDTOParser(formData);
      const updatedData = await requestFinancierFinancierCommonSettingUpdate(
        financierCommonSettingData?.financierCommonSettingId!,
        formData,
      );

      modal.show(<h6>{t('text:The_requested_change_is_successfully_applied')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnCb: () => {
          setValue('minimumPeriodForVendorFinancingEarlyRepayment', minimumPeriodForVendorFinancingEarlyRepayment);
          setValue('minimumPeriodForDealerFinancingEarlyRepayment', minimumPeriodForDealerFinancingEarlyRepayment);
          setFinancierCommonSettingData(updatedData);
          setEarlyRepaymentEditable(false);
        },
      });
    } catch (e) {
      modal.show(e);

      formErrorHandler<FinancierCommonSettingUpdateRequest>(e, setError, clearErrors);
    }
  };

  const disabledNewButton = (): boolean => {
    const allCurrencyTypesWithOutAll = Object.values(CURRENCY_TYPE).filter(value => value !== CURRENCY_TYPE.ALL);
    const supportedCurrencyTypes = getCurrencyType().length === 0 ? allCurrencyTypesWithOutAll : getCurrencyType();

    const registrableCurrencyTypes = supportedCurrencyTypes.filter(
      (currencyType: CURRENCY_TYPE) => !registeredCurrencyTypes()?.includes(currencyType),
    );

    return registrableCurrencyTypes.length === 0;
  };

  const onClickFinancierRequestSettingEdit = (e: any) => {
    e.preventDefault();
    setFinancierRequestSettingEditable(true);
  };

  const onClickFinancierRequestSettingCancel = (e: any) => {
    e.preventDefault();

    modal.show(<h6>{t('text:Would_you_like_to_cancel_this_process?')}</h6>, {
      modalType: ModalType.CONFIRM,
      confirmBtnCb: () => {
        setFinancierRequestSettingEditable(false);

        setValue(
          'invoiceFinancingAmountAdjustable',
          convertToString(financierCommonSettingData?.invoiceFinancingAmountAdjustable),
        );
      },
    });
  };

  const onClickFinancierRequestSettingSave = async (e: any) => {
    e.preventDefault();

    try {
      const { invoiceFinancingAmountAdjustable } = getValues();
      const {
        minimumPeriodForVendorFinancingEarlyRepayment,
        minimumPeriodForDealerFinancingEarlyRepayment,
        vendorDayCountConvention,
        dealerDayCountConvention,
      } = financierCommonSettingData;

      const formData: FinancierCommonSettingUpdateRequest = {
        invoiceFinancingAmountAdjustable,
        minimumPeriodForVendorFinancingEarlyRepayment,
        minimumPeriodForDealerFinancingEarlyRepayment,
        vendorDayCountConvention,
        dealerDayCountConvention,
      };

      requestDTOParser(formData);
      const updatedData = await requestFinancierFinancierCommonSettingUpdate(
        financierCommonSettingData?.financierCommonSettingId!,
        formData,
      );

      modal.show(<h6>{t('text:The_requested_change_is_successfully_applied')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnCb: () => {
          setValue('invoiceFinancingAmountAdjustable', invoiceFinancingAmountAdjustable);
          setFinancierCommonSettingData(updatedData);
          setFinancierRequestSettingEditable(false);
        },
      });
    } catch (e) {
      modal.show(e);

      formErrorHandler<FinancierCommonSettingUpdateRequest>(e, setError, clearErrors);
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing_Conditions')} />
      <div className="content-area">
        <SectionTitle title={t('text:Minimum_Amount_for_Financing_Application')}>
          {userAuthority === AUTHORITY_TYPE.ADMIN && (
            <Button onClick={onClickLoanAmountNew} size={ButtonSizeEnum.SM} disabled={disabledNewButton()}>
              {t('text:New')}
            </Button>
          )}
        </SectionTitle>
        <TableBorder>
          <TableHeader header={userAuthority === AUTHORITY_TYPE.ADMIN ? tableHeaderList : notAdminTableHeaderList} />
          <TableBody
            numOfCol={userAuthority === AUTHORITY_TYPE.ADMIN ? tableHeaderList.length : notAdminTableHeaderList.length}
          >
            {renderLoanAmountSettingResult()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={fetchAll} />
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Financier_Interest_Calculation_Settings')}>
          {userAuthority === AUTHORITY_TYPE.ADMIN &&
            (financierInterestCalcEditable ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickFinancierInterestCalcCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onClickFinancierInterestCalcSave} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button size={ButtonSizeEnum.SM} onClick={onClickFinancierInterestCalcEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            ))}
        </SectionTitle>

        <FormBorder editable={financierInterestCalcEditable}>
          <FormSubtitle title={t('text:Vendor_Finance')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormRadioWrap
                label={`${t('text:Number_of_days_in_1_year')} (${t('text:Day_Count_Convention')})`}
                error={errors.vendorDayCountConvention}
                editable={financierInterestCalcEditable}
                required
              >
                <FormRadio
                  name="vendorDayCountConvention"
                  label="360"
                  ref={register}
                  value={DAY_COUNT_CONVENTION.THREE_HUNDRED_SIXTY}
                  disabled={!financierInterestCalcEditable}
                />
                <FormRadio
                  name="vendorDayCountConvention"
                  label="365"
                  ref={register}
                  value={DAY_COUNT_CONVENTION.THREE_HUNDRED_SIXTY_FIVE}
                  disabled={!financierInterestCalcEditable}
                />
                <FormRadio
                  name="vendorDayCountConvention"
                  label={t('text:Actual')}
                  ref={register}
                  value={DAY_COUNT_CONVENTION.ACTUAL}
                  disabled={!financierInterestCalcEditable}
                />
              </FormRadioWrap>
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Dealer_Finance')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormRadioWrap
                label={`${t('text:Number_of_days_in_1_year')} (${t('text:Day_Count_Convention')})`}
                error={errors.dealerDayCountConvention}
                editable={financierInterestCalcEditable}
                required
              >
                <FormRadio
                  name="dealerDayCountConvention"
                  label="360"
                  ref={register}
                  value={DAY_COUNT_CONVENTION.THREE_HUNDRED_SIXTY}
                  disabled={!financierInterestCalcEditable}
                />
                <FormRadio
                  name="dealerDayCountConvention"
                  label="365"
                  ref={register}
                  value={DAY_COUNT_CONVENTION.THREE_HUNDRED_SIXTY_FIVE}
                  disabled={!financierInterestCalcEditable}
                />
                <FormRadio
                  name="dealerDayCountConvention"
                  label={t('text:Actual')}
                  ref={register}
                  value={DAY_COUNT_CONVENTION.ACTUAL}
                  disabled={!financierInterestCalcEditable}
                />
              </FormRadioWrap>
            </div>
          </FormContents>
        </FormBorder>
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Minimum_Financing_Term_for_Early_Repayment')}>
          {userAuthority === AUTHORITY_TYPE.ADMIN &&
            (earlyRepaymentEditable ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickEarlyRepaymentCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onClickEarlyRepaymentSave} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button size={ButtonSizeEnum.SM} onClick={onClickEarlyRepaymentEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            ))}
        </SectionTitle>

        <FormBorder editable={earlyRepaymentEditable}>
          <FormSubtitle title={t('text:Vendor_Finance')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <NumericFormatInput
                control={control}
                name="minimumPeriodForVendorFinancingEarlyRepayment"
                numberType="integer"
                label={t('text:Minimum_days_required_after_disbursement')}
                required
                disabled={!earlyRepaymentEditable}
                error={errors.minimumPeriodForVendorFinancingEarlyRepayment}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Dealer_Finance')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <NumericFormatInput
                control={control}
                name="minimumPeriodForDealerFinancingEarlyRepayment"
                numberType="integer"
                label={t('text:Minimum_days_required_after_disbursement')}
                required
                disabled={!earlyRepaymentEditable}
                error={errors.minimumPeriodForDealerFinancingEarlyRepayment}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Financing_Request_Setting')}>
          {userAuthority === AUTHORITY_TYPE.ADMIN &&
            (financierRequestSettingEditable ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickFinancierRequestSettingCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onClickFinancierRequestSettingSave} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button size={ButtonSizeEnum.SM} onClick={onClickFinancierRequestSettingEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            ))}
        </SectionTitle>
        <FormBorder editable={financierRequestSettingEditable}>
          <FormSubtitle title={t('text:Dealer_Finance')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_partner_to_modify_requested_financing_amount_in_Dealer_Financing?')}
                error={errors.dealerDayCountConvention}
                editable={financierRequestSettingEditable}
                required
              >
                <FormRadio
                  name="invoiceFinancingAmountAdjustable"
                  label={t('text:Allow')}
                  ref={register}
                  value="true"
                  disabled={!financierRequestSettingEditable}
                />
                <FormRadio
                  name="invoiceFinancingAmountAdjustable"
                  label={`${t('text:Not_Allowed')} (${t('text:Fix_to_maximum_Financing_to_Invoice_Ratio')})`}
                  ref={register}
                  value="false"
                  disabled={!financierRequestSettingEditable}
                />
              </FormRadioWrap>
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default FinancierFinancingSetting;
