import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormBorder, FormContents } from 'components/stateless/CommonForm';
import FormInput from 'components/stateless/CommonForm/FormInput';
import type { SystemTermsOfUsesRequest } from 'utils/http/api/system/terms-of-uses/requests';
import useModal from 'utils/modal/useModal';

interface SystemTouDetailUploadNewVersionModalProps {
  getSubmitData: SystemTermsOfUsesRequest;
  modalId: number;
}

function SystemTouDetailUploadNewVersionModal({ getSubmitData, modalId }: SystemTouDetailUploadNewVersionModalProps) {
  const { t } = useTranslation();
  const modal = useModal();

  const [touFileName, setTouFileName] = useState<string>();
  const { register, getValues, watch } = useForm<SystemTermsOfUsesRequest>();
  const { touFile, description, version } = watch();

  useEffect(() => {
    modal.disableConfirmBtn(modalId, !version || touFile.length === 0);
    Object.assign(getSubmitData, getValues());
  }, [getSubmitData, getValues, touFile, description, version]);

  return (
    <FormBorder editable>
      <FormContents>
        <div className="row">
          <div className="col-12">
            <div className="d-flex">
              <label className="information-form__label star w-auto">{t('text:TOU_file')}</label>
            </div>
            <div className="align-self-center mt-2">
              <div className="d-flex align-items-center">
                <input
                  type="file"
                  id="touFile"
                  name="touFile"
                  className="d-none"
                  ref={register}
                  onChange={e => {
                    if (e.target.files) {
                      setTouFileName((e.target.files as FileList)[0]?.name);
                    }
                  }}
                />
                <label htmlFor="touFile" className="attach-file-link-button me-3">
                  {t('text:Attach_File')}
                </label>
                <div id="fileName" className="upload-file-input me-auto">
                  {touFileName ? touFileName : t('text:No_file_attached')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <FormInput
            col={12}
            label={t('text:Version')}
            name="version"
            ref={register}
            requiredOptions={{ required: true }}
          />
        </div>
        <div className="row">
          <FormInput col={12} label={t('text:Description')} name="description" ref={register} />
        </div>
      </FormContents>
    </FormBorder>
  );
}

export default SystemTouDetailUploadNewVersionModal;
