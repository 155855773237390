import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import type { CURRENCY_TYPE } from 'enums';
import type { RelatedAnchorClientLimitInfoVOModel } from 'models/vo/RelatedAnchorClientLimitInfoVO';
import { tableValueManage } from 'utils/valueManager/ValueManager';

interface SyPartnerAgreementRegisteredDetailSummaryOfAssociatedAnchorProps {
  data: RelatedAnchorClientLimitInfoVOModel;
}

function SyPartnerAgreementRegisteredDetailSummaryOfAssociatedAnchor({
  data,
}: SyPartnerAgreementRegisteredDetailSummaryOfAssociatedAnchorProps) {
  const { t } = useTranslation(['format']);

  const {
    useTotalLimit,
    loanLimitAmount,
    disbursedLoanAmount,
    loanLimitBalance,
    currencyType,
    totalLimitAmount,
    totalLimitCurrencyType,
    originCurrencyTotalBalance,
    anchorAgreementStatus,
    loanLimitCheck,
  } = data;

  const returnAmountAndCurrency = (amount: string, currency: CURRENCY_TYPE): string => {
    return !isNil(amount)
      ? `${t('format:number', {
          value: amount,
        })} ${currency && currency}`
      : '-';
  };

  return (
    <>
      <div className="section-title">
        <h2 style={{ fontSize: '16px' }}>{t('text:Summary_of_Associated_Anchor')}</h2>
      </div>

      <div className="information-grid-form">
        {/* 통합 한도 존재할 시에만 해당 row 표시 */}
        {useTotalLimit && (
          <div className="row">
            <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit_of_Anchor')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {/* 앵커 기업 통합 한도 */}
                {returnAmountAndCurrency(totalLimitAmount, totalLimitCurrencyType)}
              </div>
            </div>
            <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit_of_Anchor')}</div>
            <div className="grid-form__item__data__wrap col-4">
              <div className="grid-form__data">
                {/* 잔여 대출 신청 가능 통합 한도 (통합 한도 통화) */}
                {returnAmountAndCurrency(originCurrencyTotalBalance, totalLimitCurrencyType)}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="grid-form__item__label-wrap col-2">{t('text:Financing_Limit_of_Agreement')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {/* 앵커 약정 한도 */}
              {loanLimitCheck ? returnAmountAndCurrency(loanLimitAmount, currencyType) : '-'}
            </div>
          </div>
          <div className="grid-form__item__label-wrap col-2">{t('text:Remaining_Applicable_Limit_of_Agreement')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {/* 잔여 대출 신청 가능 통합 한도 (약정 통화)  */}
              {loanLimitCheck ? returnAmountAndCurrency(loanLimitBalance, currencyType) : '-'}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="grid-form__item__label-wrap col-2">{t('text:Outstanding_Financing_Balance')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">{returnAmountAndCurrency(disbursedLoanAmount, currencyType)}</div>
          </div>
          <div className="grid-form__item__label-wrap col-2">{t('text:Agreement_Suspension')}</div>
          <div className="grid-form__item__data__wrap col-4">
            <div className="grid-form__data">
              {tableValueManage(t(`code:anchor-agreement-status.${anchorAgreementStatus}`))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SyPartnerAgreementRegisteredDetailSummaryOfAssociatedAnchor;
