import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorUserList } from 'utils/http/api/anchor/users';
import type { AnchorUserListRequest } from 'utils/http/api/anchor/users/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const AC_USER_LIST_QS_KEY = 'ac-user-list';

function AnchorUserList() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(AC_USER_LIST_QS_KEY);
  const [userPage, setUserPage] = useState<Pageable<UserVOModel[]>>();
  const signInModel: SignInModel | null = getSignIn();
  const history = useHistory();

  const { register, handleSubmit, reset, getValues, setValue } = useForm<AnchorUserListRequest>();

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:User_Name'),
    },
    {
      headerText: t('text:User_ID'),
    },
    {
      headerText: t('text:Employee_Code'),
    },
    {
      headerText: t('text:Mobile'),
    },
    {
      headerText: t('text:Email'),
    },
    {
      headerText: t('text:Authority'),
    },
    {
      headerText: t('text:User_Account_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  useEffect(() => {
    if (mounted) {
      setFormValues<AnchorUserListRequest>(setValue, getParsedSearchParams(AC_USER_LIST_QS_KEY).formSearchData);
      fetchAcUserList(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  async function fetchAcUserList(pageNumber: number = 0, sizePerPage: number = 10, data: AnchorUserListRequest) {
    try {
      const userPage = await requestAnchorUserList(pageNumber, sizePerPage, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount: sizePerPage,
        },
        AC_USER_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setUserPage(userPage);
        setPageable(userPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onClickSearch = () => {
    fetchAcUserList(0, pageable.sizePerPage, getValues());
  };

  const paginate = (pageNumber: number, sizePerPage: number) => {
    fetchAcUserList(pageNumber, sizePerPage, getParsedSearchParams(AC_USER_LIST_QS_KEY).formSearchData);
  };

  const renderResultTable = () => {
    return userPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.userName} />
          <Td data={item.loginId} />
          <Td data={item.employeeCode} />
          <Td data={item.mobile} />
          <Td data={item.email} />
          <Td data={t(`code:authority-type.${item.authorityType}`)} format="code" />
          <Td
            className={getStatusTextClass('USER_STATUS', item.userStatus)}
            data={t(`code:user-status.${item.userStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_AC.COMPANY.USER_DETAIL_BUILD_PATH(item.userId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:User')} />
      {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ? (
        <GuideMessage
          message={[
            t('text:On_this_page_you_can_view_the_list_of_Anchor_users_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Anchor_users'),
            t('text:Click_on_the_Register_button_to_register_Admin_Operator_users'),
          ]}
        >
          {{
            button: (
              <Button size={ButtonSizeEnum.SM} onClick={() => history.push(ROUTES_AC.COMPANY.USER_REGISTER)}>
                {t('text:Register')}
              </Button>
            ),
          }}
        </GuideMessage>
      ) : (
        <GuideMessage
          message={[
            t('text:On_this_page_you_can_view_the_list_of_Anchor_users_registered_on_the_platform'),
            t('text:Click_on_the_arrow_button_to_view_the_details_of_the_Anchor_users'),
          ]}
        />
      )}

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset({});
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:User_Name')} />
              <SearchInput name="userName" ref={register} />
              <SearchLabel label={t('text:Mobile')} />
              <SearchInput name="mobile" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Email')} />
              <SearchInput name="email" ref={register} />
              <SearchLabel label={t('text:Authority')} />
              <SearchSelect
                selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', 'ALL', true)}
                name="authorityType"
                ref={register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onClickSearch)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')} : {userPage?.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorUserList;
