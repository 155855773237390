export interface CalculateNetDisbursementVO {
  requestedAmount: number;
  expectedInterestAmount: number;
  netDisbursementAmount: number;
}

export interface CalculateNetDisbursementVOModel extends CalculateNetDisbursementVO {}

export function formattingToCalculateNetDisbursementVOModel(
  data: CalculateNetDisbursementVO,
): CalculateNetDisbursementVOModel {
  return {
    ...data,
  };
}
