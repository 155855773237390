import type Pageable from 'models/Pageable';
import type {
  AnchorPartnerAccountDetailVO,
  AnchorPartnerAccountDetailVOModel,
} from 'models/vo/AnchorPartnerAccountDetailVO';
import { formattingToAnchorPartnerAccountDetailVOModel } from 'models/vo/AnchorPartnerAccountDetailVO';
import type { AnchorPartnerAccountVO, AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import { formattingToAnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { AnchorPartnerAccountRequest } from './requests';

// 파트너 계좌 목록 조회
export async function requestAnchorPartnerBankAccountList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorPartnerAccountRequest,
): Promise<Pageable<AnchorPartnerAccountVOModel[]>> {
  const response = await http.get<Pageable<AnchorPartnerAccountVO[]>>({
    url: API_AC.ANCHOR_PARTNER_ACCOUNTS.PARTNER_BANK_ACCOUNT_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const mappedAnchorPartnerAccountList: AnchorPartnerAccountVOModel[] = response.content.map(anchorPartnerAccountData =>
    formattingToAnchorPartnerAccountVOModel(anchorPartnerAccountData),
  );

  return {
    ...response,
    content: mappedAnchorPartnerAccountList,
  };
}

// 파트너 계좌 목록 상세 조회
export async function requestAnchorPartnerBankAccountDetail(
  anchorPartnerAccountId: number,
): Promise<AnchorPartnerAccountDetailVOModel> {
  const response = await http.get<AnchorPartnerAccountDetailVO>({
    url: API_AC.ANCHOR_PARTNER_ACCOUNTS.PARTNER_BANK_ACCOUNT_DETAIL(anchorPartnerAccountId),
  });

  return formattingToAnchorPartnerAccountDetailVOModel(response);
}
