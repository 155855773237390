import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { requestFinancierAnchorAgreementData } from 'utils/http/api/financier/anchor-agreements';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import SearchAnchorAgreementModal from '../../../../../../../components/stateless/Modal/financier/SearchAnchorAgreementModal';
import SearchAnchorUserModal from '../../../modals/SearchAnchorUserModal';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorAgreementInfoViewModel from '../../../models/agreement/useAnchorAgreementInfoViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';
import useFinancierSettingViewModel from '../../../models/financierSetting/useFinancierSettingViewModel';

const useRegistrationAssociatedAnchorMasterAgreementController = () => {
  const { show: showModal, id: modalId } = useModal();
  const { t } = useTranslation(['format']);

  const { anchorAgreementInfo, updateAnchorAgreementInfo, updateAnchorUserList, convertToAnchorAgreementInfoType } =
    useAnchorAgreementInfoViewModel();
  const { agreement, isSearchedAgreement, updateAssociatedAnchorAgreementInfo, updateDivisionInfo } =
    useAgreementViewModel();
  const { divisionRegistrable } = useFinancierSettingViewModel();
  const { useAgreementInterface, useAnchorUserInterface, supportedCollateralType } = useExtraInformationViewModel();

  const {
    methods: { setValue, errors, clearErrors },
    getReadOnlyValue,
  } = useFormContext();

  const isDealerAgreementTypeRadioDisabled = useAgreementInterface ? getReadOnlyValue('dealerAgreementType') : false;

  const isSearchAnchorAgreementButtonVisible = useAgreementInterface
    ? isSearchedAgreement && !getReadOnlyValue('anchorAgreementId')
    : true;

  const isSearchAnchorUserButtonVisible = useAgreementInterface
    ? isSearchedAgreement && !useAnchorUserInterface
    : !useAnchorUserInterface;

  const header: HeaderType[] = useMemo(() => {
    const header: HeaderType[] = [
      { headerText: t('text:User_Code') },
      { headerText: t('text:Name') },
      { headerText: t('text:Mobile') },
      { headerText: t('text:Email') },
      { headerText: t('text:OTP_Number') },
      { headerText: t('text:Authority') },
    ];

    return header;
  }, [t]);

  const handleDealerAgreementTypeChange = () => {
    setValue('anchorAgreementId', null);
    setValue('currencyType', null);
    setValue('bankUserIdList', []);

    updateDivisionInfo();
    updateAssociatedAnchorAgreementInfo();
    updateAnchorAgreementInfo();

    if (errors?.currencyType) clearErrors('currencyType');
  };

  // TODO: context 생성 (vendor 포함)
  const updateDivisionIfNeeded = async (data: AnchorAgreementVOModel) => {
    if (!divisionRegistrable) return;

    try {
      const anchorAgreement = await requestFinancierAnchorAgreementData(data.anchorAgreementId);
      updateDivisionInfo(anchorAgreement);
    } catch (error) {
      showModal(error);
    }
  };

  const handleSearchAnchorAgreementClick = () => {
    const setSelectedData = async (data: AnchorAgreementVOModel) => {
      setValue('anchorAgreementId', data.anchorAgreementId);
      setValue('currencyType', data.currencyType);
      setValue('bankUserIdList', []);

      await updateDivisionIfNeeded(data);
      updateAssociatedAnchorAgreementInfo(data);
      updateAnchorAgreementInfo(convertToAnchorAgreementInfoType(data));

      if (errors?.currencyType) clearErrors('currencyType');
    };

    showModal(
      <SearchAnchorAgreementModal
        modalId={modalId}
        getSelectedData={setSelectedData}
        supportedCollateralType={supportedCollateralType}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        title: t('text:Search_Anchor_Master_Agreement'),
        closeBtnText: t('text:Close'),
      },
    );
  };

  const handleSearchAnchorUserClick = () => {
    let selectedAnchorUserList: AnchorUserVOModel[] = [];

    const manageSelectedAnchorUserList = (anchorUser: AnchorUserVOModel, isChecked: boolean) => {
      if (isChecked) {
        selectedAnchorUserList = [...selectedAnchorUserList, anchorUser];
      } else {
        selectedAnchorUserList = selectedAnchorUserList.filter(
          selectedAnchorUser => selectedAnchorUser.id !== anchorUser.id,
        );
      }
    };

    const handleConfirmClick = () => {
      setValue(
        'bankUserIdList',
        selectedAnchorUserList.map(anchorUser => anchorUser.bankUserId),
      );

      updateAnchorUserList(selectedAnchorUserList);
    };

    if (anchorAgreementInfo.anchorClientId) {
      showModal(
        <SearchAnchorUserModal
          anchorClientId={anchorAgreementInfo.anchorClientId}
          selectedAnchorUserListManager={manageSelectedAnchorUserList}
        />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: handleConfirmClick,
        },
      );
    } else {
      showModal(<h6>{t('text:Please_search_anchor_agreement_before_using_this_button')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
      });
    }
  };

  return {
    agreement,
    anchorAgreementInfo,
    divisionRegistrable,
    isDealerAgreementTypeRadioDisabled,
    isSearchAnchorAgreementButtonVisible,
    isSearchAnchorUserButtonVisible,
    header,
    handleDealerAgreementTypeChange,
    handleSearchAnchorAgreementClick,
    handleSearchAnchorUserClick,
  };
};

export default useRegistrationAssociatedAnchorMasterAgreementController;
