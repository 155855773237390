import { ATTACHMENT_TYPE } from 'enums';
import { requestFinancierMultipleAnchorLoanRequestDownloadAttachment } from 'utils/http/api/financier/multiple-request-anchor-loans';
import useModal from 'utils/modal/useModal';

export function useFinancierBulkAnchorFinancingDetailRequestInformationController(multipleAnchorLoanRequestId: number) {
  const modal = useModal();

  const handleDownloadLoanAgreementClick = async () => {
    try {
      await requestFinancierMultipleAnchorLoanRequestDownloadAttachment(
        multipleAnchorLoanRequestId,
        ATTACHMENT_TYPE.LOAN_AGREEMENT,
      );
    } catch (e: any) {
      modal.show(e);
    }
  };

  const handleDownloadLoanRequestAttachmentClick = async () => {
    try {
      await requestFinancierMultipleAnchorLoanRequestDownloadAttachment(
        multipleAnchorLoanRequestId,
        ATTACHMENT_TYPE.LOAN_REQUEST,
      );
    } catch (e: any) {
      modal.show(e);
    }
  };

  return {
    handleDownloadLoanAgreementClick,
    handleDownloadLoanRequestAttachmentClick,
  };
}
