import type { UseFormMethods } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';

import type { InvoiceAdditionalData } from 'utils/http/api/dealer/invoice-summaries/request';

interface AdditionalDataListType {
  rowIndex: number;
  control: UseFormMethods['control'];
  register: UseFormMethods['register'];
  fieldName: {
    rowName: string;
    columnName: string;
  };
}

interface AdditionalData extends InvoiceAdditionalData {}

function AdditionalDataList({ rowIndex, control, register, fieldName }: AdditionalDataListType) {
  const { rowName, columnName } = fieldName;

  const { fields } = useFieldArray<AdditionalData>({
    control,
    name: `${rowName}[${rowIndex}].${columnName}`,
  });

  return (
    <>
      {fields.map((additionalData, additionalIndex) => {
        return (
          <td key={additionalData.id}>
            <input
              className="grid-input"
              name={`${rowName}[${rowIndex}].${columnName}[${additionalIndex}].value`}
              type="text"
              defaultValue={additionalData.value}
              ref={register()}
            />
            <input
              type="hidden"
              name={`${rowName}[${rowIndex}].${columnName}[${additionalIndex}].name`}
              defaultValue={additionalData.name}
              ref={register()}
            />
          </td>
        );
      })}
    </>
  );
}

export default AdditionalDataList;
