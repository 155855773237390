import type { INTEREST_REPAYMENT_TYPE } from 'enums';
import { LOAN_STATUS, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface PartialCollateralVO {
  partialCollateralId: number;
  partialCollateralizedAmount: number;
  invoiceAttachmentId: number;
  invoiceAttachmentDescription: string;
  attachmentId: number;
  attachmentName: string;
  attachmentPath: string;
  loanId: number;
  disbursedAmount: number;
  disbursedDate: string;
  repaidDate: string;
  loanStatus: string;
  collateralId: number;
  interestRepaymentType: INTEREST_REPAYMENT_TYPE;
  showNetDisbursementAmount: boolean;
  netDisbursementAmount: BigNumber;
  factoringEnable: boolean;
}

export interface PartialCollateralVOModel extends PartialCollateralVO {
  loanStatus: LOAN_STATUS;
}

export function formattingToPartialCollateralVOModel(data: PartialCollateralVO): PartialCollateralVOModel {
  return {
    ...data,
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
  };
}
