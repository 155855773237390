import { AUTHORITY_TYPE, OTP_TYPE, stringToEnum } from 'enums';

export interface FinancierClientAuthSettingVO {
  financierClientAuthSettingId: number;
  otpType: string;
  financierId: number;
  financierName: string;
  mobile: string;
  email: string;
  currentAuthorityType: string;
}

export interface FinancierClientAuthSettingVOModel extends FinancierClientAuthSettingVO {
  otpType: OTP_TYPE;
  currentAuthorityType: AUTHORITY_TYPE;
}

export function formattingToFinancierClientAuthSettingVOModel(
  data: FinancierClientAuthSettingVO,
): FinancierClientAuthSettingVOModel {
  return {
    ...data,
    otpType: stringToEnum(OTP_TYPE, data.otpType),
    currentAuthorityType: stringToEnum(AUTHORITY_TYPE, data.currentAuthorityType),
  };
}
