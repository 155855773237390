import type { CostOfFundVO, CostOfFundVOModel } from 'models/vo/CostOfFundVO';
import { formattingToCostOfFundVOModel } from 'models/vo/CostOfFundVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

export async function requestFiCostOfFundsDetail(id: number): Promise<CostOfFundVOModel> {
  const response = await http.get<CostOfFundVO>({
    url: API_FI.COST_OF_FUNDS.COST_OF_FUNDS_DETAIL(id),
  });

  return formattingToCostOfFundVOModel(response);
}
