import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE } from 'enums';
import { DuplicateExceptionCode } from 'enums/exception';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { WaitingBankCodeVOModel } from 'models/vo/WaitingBankCodeVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestCmBankCodeDetail } from 'utils/http/api/common/bank-code';
import { requestFiAnchorPartnerAccountList } from 'utils/http/api/financier/anchor-partner-accounts';
import {
  requestFiWaitingBankCodeList,
  requestFiWaitingBankCodeRegister,
} from 'utils/http/api/financier/waiting-bank-code';
import type { FiWaitingBankCodeRegisterRequest } from 'utils/http/api/financier/waiting-bank-code/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import Button, {
  ButtonColorEnum,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../components/stateless/Button/Button';

function FinancierBankCodeDetailConfirmed() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();
  const [bankCodeDetail, setBankCodeDetail] = useState<BankCodeVOModel>();
  const [waitingBankCodeList, setWaitingBankCodeList] = useState<Pageable<WaitingBankCodeVOModel[]>>();
  const [bankAccountList, setBankAccountList] = useState<Pageable<AnchorPartnerAccountVOModel[]>>();
  const signInModel: SignInModel | null = getSignIn();

  const [editable, setEditable] = useState<boolean>(false);
  const bankAccountPageable = usePageable();
  const editHistoryPageable = usePageable();
  const { register, getValues, reset, errors, setError, clearErrors } = useForm<FiWaitingBankCodeRegisterRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const { bankCodeId } = useParams<any>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const [fetchBankCodeDetailResponse, fetchBankCodeHistory, fetchAnchorPartnerAccounts] = await Promise.all([
        requestCmBankCodeDetail(bankCodeId),
        requestFiWaitingBankCodeList(
          editHistoryPageable.pageable.currentPage,
          editHistoryPageable.pageable.sizePerPage,
          { targetBankCodeId: bankCodeId },
        ),
        requestFiAnchorPartnerAccountList(
          bankAccountPageable.pageable.currentPage,
          bankAccountPageable.pageable.sizePerPage,
          {
            accountBankCodeId: bankCodeId,
          },
        ),
      ]);
      ReactDOM.unstable_batchedUpdates(() => {
        setBankCodeDetail(fetchBankCodeDetailResponse);
        setWaitingBankCodeList(fetchBankCodeHistory);
        editHistoryPageable.setPageable(fetchBankCodeHistory);
        setBankAccountList(fetchAnchorPartnerAccounts);
        bankAccountPageable.setPageable(fetchAnchorPartnerAccounts);
        setBankCodeData(fetchBankCodeDetailResponse);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const setBankCodeData = (data?: BankCodeVOModel) => {
    reset({
      bankCode: data?.bankCode,
      branchCode: data?.branchCode,
      bankName: data?.bankName,
      branchName: data?.branchName,
    });
  };

  const renderButtons = () => {
    const onClickCancel = (e: any) => {
      e.preventDefault();
      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_revision_request?')}
          <br />
          {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => {
            setBankCodeData(bankCodeDetail);

            setEditable(prevState => !prevState);
          },
        },
      );
    };

    const onClickEdit = () => {
      setEditable(prevState => !prevState);
    };

    const onClickSave = (e: any) => {
      e.preventDefault();
      const requestSave = async () => {
        const showSubmitSuccessModal = () => {
          modal.show(
            <h6>
              {t('text:The_request_has_been_submitted_successfully')}
              <br />
              {t('text:See_the_request_in_the_Waiting_for_Confirmation_tab')}
            </h6>,
            {
              modalType: ModalType.ALERT,
              closeBtnText: t('text:OK'),
              closeBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_LIST),
            },
          );
        };

        const data = getValues();
        data.targetBankCodeId = bankCodeId;

        try {
          requestDTOParser(data);
          await requestFiWaitingBankCodeRegister(data);
          showSubmitSuccessModal();
        } catch (e: any) {
          if (e.message === DuplicateExceptionCode.BANK_CODE_DUPLICATE) {
            modal.show(<h6>{t('text:The_bank_or_branch_codes_have_already_been_registered')}</h6>);
          } else {
            modal.show(e);
          }
          formErrorHandler<FiWaitingBankCodeRegisterRequest>(e, setError, clearErrors);
        }
      };
      modal.show(<h6>{t('text:Would_you_like_to_request_the_revision_of_the_bank_code_information?')}</h6>, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: () => requestSave(),
      });
    };

    return editable ? (
      <>
        <Button
          size={ButtonSizeEnum.SM}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          onClick={onClickCancel}
        >
          {t('text:Cancel')}
        </Button>
        <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
          {t('text:Save')}
        </Button>
      </>
    ) : (
      <Button size={ButtonSizeEnum.SM} onClick={onClickEdit} style={{ width: '60px' }}>
        {t('text:Edit')}
      </Button>
    );
  };

  const bankAccountInfoTable = () => {
    const bankAccountInfoTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
      },
      {
        headerText: t('text:Responsible_Bank_Name'),
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
      },
      {
        headerText: t('text:Uploaded_Partner_Name'),
      },
      {
        headerText: t('text:Uploaded_Partner_Tax_Code'),
      },
      {
        headerText: t('text:Bank_Account_Holder'),
      },
      {
        headerText: t('text:Bank_Account_Number'),
      },
    ];

    const bankAccountPaginate = async (pageNumber: number, sizePerPage: number) => {
      try {
        const fetchAnchorPartnerAccounts = await requestFiAnchorPartnerAccountList(pageNumber, sizePerPage, {
          accountBankCodeId: bankCodeId,
        });
        ReactDOM.unstable_batchedUpdates(() => {
          setBankAccountList(fetchAnchorPartnerAccounts);
          bankAccountPageable.setPageable(fetchAnchorPartnerAccounts);
        });
      } catch (e) {
        modal.show(e);
      }
    };

    return (
      <div className="content-area">
        <SectionTitle title={t('text:Associated_Bank_Accounts_of_Partners')} />
        <TableBorder>
          <TableHeader header={bankAccountInfoTableHeaders} />
          <TableBody numOfCol={8}>
            {bankAccountList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.anchorAgreementContractNo} />
                  <Td data={item.anchorName} />
                  <Td data={item.responsibleFinancierName} />
                  <Td data={item.responsibleBranchName} />
                  <Td data={item.partnerName} />
                  <Td data={item.partnerTaxCode} />
                  <Td data={item.accountOwner} />
                  <Td data={item.account} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={bankAccountPageable.pageable} paginate={bankAccountPaginate} />
      </div>
    );
  };

  const renderEditHistory = () => {
    const editHistoryTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Edited_Date'),
        colWidths: 150,
      },
      {
        headerText: t('text:Bank_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Bank_Name'),
        colWidths: 130,
      },
      {
        headerText: t('text:Branch_Code'),
        colWidths: 130,
      },
      {
        headerText: t('text:Branch_Name'),
        colWidths: 130,
      },
    ];

    const historyPaginate = async (pageNumber: number, sizePerPage: number) => {
      try {
        const fetchBankCodeHistory = await requestFiWaitingBankCodeList(pageNumber, sizePerPage, {
          targetBankCodeId: bankCodeId,
        });
        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingBankCodeList(fetchBankCodeHistory);
          editHistoryPageable.setPageable(fetchBankCodeHistory);
        });
      } catch (e) {
        modal.show(e);
      }
    };

    return (
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={editHistoryTableHeaders} />
          <TableBody numOfCol={5}>
            {waitingBankCodeList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.updatedDateTime} format="datetime" />
                  <Td data={item.bankCode} />
                  <Td data={item.bankName} />
                  <Td data={item.branchCode} />
                  <Td data={item.branchName} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={editHistoryPageable.pageable} paginate={historyPaginate} />
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Bank_Code_Details')} />
      {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ? (
        <GuideMessage
          message={[
            t(
              'text:Check_the_bank_code,_associated_Uploaded_Partner’s_bank_accounts,_and_the_information_edit_history_on_this_page',
            ),
            t('text:Click_on_the_Edit_button_to_request_revision_of_the_bank_code_information'),
          ]}
        />
      ) : (
        <GuideMessage
          message={[
            t(
              'text:Check_the_bank_code,_associated_Uploaded_Partner’s_bank_accounts,_and_the_information_edit_history_on_this_page',
            ),
          ]}
        />
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Bank_Code')}>
          {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && renderButtons()}
        </SectionTitle>
        <FormBorder editable={editable}>
          <FormSubtitle title={t('text:Bank_Code_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Bank_Code')}
                disabled={!editable}
                name="bankCode"
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.bankCode}
              />
              <FormInput
                label={t('text:Bank_Name')}
                disabled={!editable}
                name="bankName"
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.bankName}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Branch_Code')}
                disabled={!editable}
                name="branchCode"
                ref={register}
                requiredOptions={{ required: true }}
                error={errors.branchCode}
              />
              <FormInput
                label={t('text:Branch_Name')}
                disabled={!editable}
                name="branchName"
                ref={register}
                error={errors.branchName}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {bankAccountInfoTable()}
      {renderEditHistory()}
    </>
  );
}

export default FinancierBankCodeDetailConfirmed;
