import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import Label from 'components/stateless/Form/Label/Label';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border, Cell, Content, Row } from 'components/templates/section';

function UnitPrice() {
  const { t } = useTranslation();

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Unit_Price')} />
      <Border>
        <Row singleColumnPosition="all">
          <Cell>
            <Label>{t('text:Standard_Plan_Settings')}</Label>
            <Form.Description>
              {t('text:If_the_financier_does_not_follow_a_custom_plan_the_standard_plan_will_be_applied')}
            </Form.Description>
          </Cell>
          <Border>
            <Content isInnerStyle={true}>
              <Row>
                <Form.Control>
                  <Label>{t('text:API_Requests')}</Label>
                  <Form.Value value={t('text:USD_0_001_per_request')} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Label>{t('text:Active_Users')}</Label>
                  <Form.Value value={t('text:USD_3_per_active_user')} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Label>{t('text:Active_Clients')}</Label>
                  <Form.Value value={t('text:USD_10_per_active_client_company')} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Label>{t('text:Performance_Fee')}</Label>
                  <Form.Value value={t('text:Daily_outstanding_balance_X_0_6%')} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Label>{t('text:Notifications')}</Label>
                  <Form.Value value={t('text:USD_0_005_per_notification')} />
                </Form.Control>
              </Row>
              <Row>
                <Form.Control>
                  <Label>{t('text:Storage')}</Label>
                  <Form.Value value={t('text:USD_0_01_per_MB_used')} />
                </Form.Control>
              </Row>
            </Content>
          </Border>
        </Row>
      </Border>
    </div>
  );
}

export default UnitPrice;
