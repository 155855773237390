import { useState } from 'react';
import ReactDOM from 'react-dom';

import type { POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS } from 'enums';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import { ResourceNotFoundExceptionCode } from 'enums/exception';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import type { PotentialPartnerFinancingApplicationDetailVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import type { PotentialPartnerFinancingApplicationDocumentVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDocumentVO';
import type { PotentialPartnerVOModel } from 'models/vo/PotentialPartnerVO';
import { HttpError } from 'utils/error/HttpError';
import {
  requestFinancierPotentialPartnerFinancingApplicationDocumentList,
  requestFinancierPotentialPartnerFinancingApplicationUpdateDocuments,
} from 'utils/http/api/financier/potential-partner-financing-application-documents';
import {
  requestFinancierPotentialAnchorAgreement,
  requestFinancierPotentialPartnerAgreement,
  requestFinancierPotentialPartnerDetail,
  requestFinancierPotentialPartnerFinancingApplicationActivatedApplication,
  requestFinancierPotentialPartnerFinancingApplicationCancelInProgress,
  requestFinancierPotentialPartnerFinancingApplicationCancelReject,
  requestFinancierPotentialPartnerFinancingApplicationDetail,
  requestFinancierPotentialPartnerFinancingApplicationRejectInProgress,
  requestFinancierPotentialPartnerFinancingApplicationReviewSubmitted,
} from 'utils/http/api/financier/potential-partner-financing-applications';
import useModal from 'utils/modal/useModal';

interface FinancierScPartnerAcquisitionDetailStateType {
  applicationSummaryInfo: PotentialPartnerFinancingApplicationDetailVOModel;
  partnerInfo: PotentialPartnerVOModel;
  anchorAgreementInfo: AnchorAgreementVOModel;
  dealerAgreementInfo: DealerAgreementVOModel;
  documentLists: PotentialPartnerFinancingApplicationDocumentVOModel[];
}

const DOCUMENT_LIST_ROW_COUNT = 1000;

export function useFinancierScPartnerAcquisitionDetailState(potentialPartnerFinancingApplicationId: number) {
  const modal = useModal();

  const [dataState, setDataState] = useState<FinancierScPartnerAcquisitionDetailStateType>({
    applicationSummaryInfo: {} as PotentialPartnerFinancingApplicationDetailVOModel,
    partnerInfo: {} as PotentialPartnerVOModel,
    anchorAgreementInfo: {} as AnchorAgreementVOModel,
    dealerAgreementInfo: {} as DealerAgreementVOModel,
    documentLists: {} as PotentialPartnerFinancingApplicationDocumentVOModel[],
  });

  const fetchAll = async (): Promise<void> => {
    let fetchedDealerAgreement: DealerAgreementVOModel;
    try {
      const [fetchedApplicationSummaryInfo, fetchedPartnerInfo, fetchedAnchorAgreement, fetchedDocumentLists] =
        await Promise.all([
          requestFinancierPotentialPartnerFinancingApplicationDetail(potentialPartnerFinancingApplicationId),
          requestFinancierPotentialPartnerDetail(potentialPartnerFinancingApplicationId),
          requestFinancierPotentialAnchorAgreement(potentialPartnerFinancingApplicationId),
          requestFinancierPotentialPartnerFinancingApplicationDocumentList(
            0,
            DOCUMENT_LIST_ROW_COUNT,
            potentialPartnerFinancingApplicationId,
          ),
        ]);
      if (
        fetchedApplicationSummaryInfo.potentialPartnerFinancingApplicationStatus ===
        POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED
      ) {
        fetchedDealerAgreement = await requestFinancierPotentialPartnerAgreement(
          potentialPartnerFinancingApplicationId,
        );
      }

      ReactDOM.unstable_batchedUpdates(() => {
        setDataState(prevState => ({
          ...prevState,
          applicationSummaryInfo: fetchedApplicationSummaryInfo,
          partnerInfo: fetchedPartnerInfo,
          anchorAgreementInfo: fetchedAnchorAgreement,
          dealerAgreementInfo: fetchedDealerAgreement,
          documentLists: fetchedDocumentLists.content,
        }));
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchFiPotentialPartnerFinancingApplicationDocumentList = async () => {
    const documentList = await requestFinancierPotentialPartnerFinancingApplicationDocumentList(
      0,
      DOCUMENT_LIST_ROW_COUNT,
      potentialPartnerFinancingApplicationId,
    );
    setDataState(prevState => ({
      ...prevState,
      documentLists: documentList.content,
    }));
  };

  const updateApplicationSubmitStatusApis = () => {
    const reviewSubmitted = async () => {
      try {
        const applicationSummaryInfo = await requestFinancierPotentialPartnerFinancingApplicationReviewSubmitted(
          potentialPartnerFinancingApplicationId,
        );
        // 제출 상태 수정 후에는 문서 상태가 변경되므로, 제출 상태 수정 문서 후에 문서 리스트 새로 받아오기
        await fetchFiPotentialPartnerFinancingApplicationDocumentList();
        setDataState(prevState => ({
          ...prevState,
          applicationSummaryInfo: applicationSummaryInfo,
        }));
      } catch (e) {
        modal.show(e);
      }
    };

    const cancelInProgress = async () => {
      try {
        const applicationSummaryInfo = await requestFinancierPotentialPartnerFinancingApplicationCancelInProgress(
          potentialPartnerFinancingApplicationId,
        );
        setDataState(prevState => ({
          ...prevState,
          applicationSummaryInfo: applicationSummaryInfo,
        }));
      } catch (e) {
        modal.show(e);
      }
    };

    const rejectInProgress = async (rejectReason: string) => {
      try {
        const applicationSummaryInfo = await requestFinancierPotentialPartnerFinancingApplicationRejectInProgress(
          potentialPartnerFinancingApplicationId,
          rejectReason,
        );
        setDataState(prevState => ({
          ...prevState,
          applicationSummaryInfo: applicationSummaryInfo,
        }));
      } catch (e) {
        modal.show(e);
      }
    };

    const cancelRejected = async () => {
      try {
        const applicationSummaryInfo = await requestFinancierPotentialPartnerFinancingApplicationCancelReject(
          potentialPartnerFinancingApplicationId,
        );
        setDataState(prevState => ({
          ...prevState,
          applicationSummaryInfo: applicationSummaryInfo,
        }));
      } catch (e) {
        modal.show(e);
      }
    };

    // cancelRejected 수행이 가능한지 체크하는 api
    // 불가능 하면 response 를 리턴하여 해당 페이지로 이동하게
    const checkHasActivatedApplication = async () => {
      try {
        const activatedApplication = await requestFinancierPotentialPartnerFinancingApplicationActivatedApplication(
          potentialPartnerFinancingApplicationId,
        );

        return activatedApplication;
      } catch (e) {
        if (
          e instanceof HttpError &&
          e.code === ResourceNotFoundExceptionCode.POTENTIAL_PARTNER_FINANCING_APPLICATION_NOT_FOUND
        ) {
          // 거절 취소 가능
          await cancelRejected();
        } else {
          modal.show(e);
        }
      }
    };

    return {
      cancelInProgress,
      reviewSubmitted,
      rejectInProgress,
      checkHasActivatedApplication,
    };
  };

  const updateApplicationDocumentStatus = async (
    applicationDocumentId: number,
    value: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS,
    index: number,
  ): Promise<boolean> => {
    const getUpdatedDocumentListState = (updatedData: PotentialPartnerFinancingApplicationDocumentVOModel) => {
      const prevDocumentState = dataState.documentLists;
      prevDocumentState[index] = updatedData;

      return prevDocumentState;
    };

    try {
      const updatedResult = await requestFinancierPotentialPartnerFinancingApplicationUpdateDocuments(
        applicationDocumentId,
        value,
      );

      setDataState(prevState => ({
        ...prevState,
        documentLists: getUpdatedDocumentListState(updatedResult),
      }));

      return true;
    } catch (e) {
      modal.show(e);

      return false;
    }
  };

  return {
    fetchAll,
    state: dataState,
    updateApplicationSubmitStatusApis,
    updateApplicationDocumentStatus,
  };
}
