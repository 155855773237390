import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty, isNil } from 'lodash-es';

import { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { AUTHORITY_TYPE, BASIS_INTEREST_TYPE, COLLATERAL_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import { AgreementExceptionCode } from 'enums/exception';
import type { SignInModel } from 'models/SignInModel';
import { convertToAnchorFinancingOptionResetData } from 'models/convertor/anchorFinancingOption';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestApproveWaitingAnchorFinancingOption,
  requestCancelWaitingAnchorFinancingOption,
  requestReturnWaitingAnchorFinancingOption,
  requestUpdateFinancierWaitingAnchorFinancingOption,
} from 'utils/http/api/financier/waiting-anchor-financing-options';
import type { UpdateFiWaitingAnchorFinancingOptionDTO } from 'utils/http/api/financier/waiting-anchor-financing-options/requests';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser, requestDTOParserForArrayObj } from 'utils/valueManager/ValueManager';

import useExtraInformationViewModel from '../models/extraInformation/useExtraInformationViewModel';
import useAnchorAgreementInfoViewModel from '../models/financingOption/useAnchorAgreementInfoViewModel';
import useFinancingOptionViewModel from '../models/financingOption/useFinancingOptionViewModel';
import useTermSpreadViewModel from '../models/financingOption/useTermSpreadViewModel';

const useWaitingDetailController = () => {
  const methods = useForm<UpdateFiWaitingAnchorFinancingOptionDTO>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const { reset, getValues, setError, clearErrors } = methods;

  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation(['format']);
  const { show: showModal, id: modalId } = useModal();

  const {
    anchorFinancingOption,
    fetchWaitingFinancingOption,
    isFirstRegisteredWaitingAgreement,
    updateAllFinancingOption,
  } = useFinancingOptionViewModel();
  const { termSpreadList } = useTermSpreadViewModel();
  const { waitingAnchorFinancingOptionId, supportedCollateralType, errorHandlerOfLocationState } =
    useExtraInformationViewModel();

  const { fetchAnchorAgreementDetail, updateAnchorAgreementDetail } = useAnchorAgreementInfoViewModel();

  const { approvalType, returnReason } = anchorFinancingOption;

  const isAr = supportedCollateralType === COLLATERAL_TYPE.AR;

  const isSameBranch = signInModel?.branchId === anchorFinancingOption?.branchId;

  const isEditable =
    approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED &&
    signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR &&
    isSameBranch;

  const isEditableStyle = isEditable;

  const resetPage = async () => {
    if (isNil(waitingAnchorFinancingOptionId) || errorHandlerOfLocationState()) return;

    try {
      const waitingAnchorFinancingOptionDetail = await fetchWaitingFinancingOption(waitingAnchorFinancingOptionId);
      const anchorAgreementDetail = await fetchAnchorAgreementDetail(
        waitingAnchorFinancingOptionDetail?.anchorAgreementId!,
      );

      if (waitingAnchorFinancingOptionDetail) {
        if (waitingAnchorFinancingOptionDetail.basisInterestBankCode === BASIS_INTEREST_TYPE.FIXED)
          waitingAnchorFinancingOptionDetail.basisInterestBankCode = '';
        reset(convertToAnchorFinancingOptionResetData(waitingAnchorFinancingOptionDetail));
        updateAllFinancingOption(waitingAnchorFinancingOptionDetail);
      }
      if (anchorAgreementDetail) updateAnchorAgreementDetail(anchorAgreementDetail);
    } catch (error) {
      showModal(error);
    }
  };

  const conditionChecker = (conditionAuthority: AUTHORITY_TYPE, conditionApprovalType: COMMON_APPROVAL_TYPE) => {
    if (signInModel?.authorityType !== conditionAuthority) return false;

    if (anchorFinancingOption?.approvalType !== conditionApprovalType) return false;

    return true;
  };

  const handleUpdateAgreementError = (error: any) => {
    showModal(error);

    const isInvalidError = !isEmpty(
      formErrorHandler<UpdateFiWaitingAnchorFinancingOptionDTO>(error, setError, clearErrors),
    );

    if (isInvalidError) return;

    const getMonthlyInterestRepaymentDateErrorMessage = () => {
      const monthlyInterestRepaymentDate = getValues('monthlyInterestRepaymentDate');
      if (isNil(monthlyInterestRepaymentDate) || Number(monthlyInterestRepaymentDate) === 0)
        return t('text:0_and_blank_are_not_allowed');
      else return t('text:Date_must_be_between_1_and_31');
    };

    switch (error.code) {
      case AgreementExceptionCode.INVALID_BASIS_INTEREST_RATE:
        return setError('basisInterestRate', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_LOAN_TERM:
        return setError('loanTermUnit', { shouldFocus: true, message: t('text:0_and_blank_are_not_allowed') });
      case AgreementExceptionCode.OMITTED_ANCHOR_AGREEMENT_INFO:
        return setError('anchorAgreementId', { shouldFocus: true });
      case AgreementExceptionCode.INVALID_DEALER_AGREEMENT_INTEREST_REPAYMENT_TYPE:
        return setError('monthlyInterestRepaymentDate', {
          shouldFocus: true,
          message: getMonthlyInterestRepaymentDateErrorMessage(),
        });
      default:
        return;
    }
  };

  const updateAgreement = async () => {
    const data = getValues();

    data.collateralType = supportedCollateralType;
    data.termSpreadList = data.termSpreadList ?? termSpreadList;

    if (data.basisInterestType === BASIS_INTEREST_TYPE.FIXED) data.basisInterestBankCode = BASIS_INTEREST_TYPE.FIXED;
    if (supportedCollateralType === COLLATERAL_TYPE.INVOICE) data.anchorAccount = 'true';

    requestDTOParser(data);
    requestDTOParserForArrayObj(data.termSpreadList);
    await requestUpdateFinancierWaitingAnchorFinancingOption(waitingAnchorFinancingOptionId, data);
  };

  const showChangeRequestCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:Request_for_registration_approval_has_been_completed')}
            <br />
            {t('text:Registration_will_be_completed_after_approval_by_the_Authorizer')}
          </>
        ) : (
          <>
            {t('text:Request_for_modification_approval_has_been_completed')}
            <br />
            {t('text:Modification_will_be_completed_after_approval_by_the_Authorizer')}
          </>
        )}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleChangeRequestButtonClick = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:Would_you_like_to_request_approval_for_registration?')}
            <br />
            {t(
              'text:Please_note_that_if_modifications_occur_after_the_completion_of_the_request_the_approval_request_must_be_cancelled_and_processed_again',
            )}
          </>
        ) : (
          t('text:Would_you_like_to_request_approval_for_modification?')
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await updateAgreement();
            showChangeRequestCompletedModal();
          } catch (e) {
            handleUpdateAgreementError(e);
          }
        },
      },
    );
  };

  // Cancel Button
  const showCancelCompletedModal = async () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:The_registration_request_has_been_cancelled')
          : t('text:The_modification_request_has_been_cancelled')}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleCancelButtonClick = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t(`text:Would_you_like_to_cancel_the_registration_request?`)
          : t('text:Would_you_like_to_cancel_the_modification_request?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await requestCancelWaitingAnchorFinancingOption(waitingAnchorFinancingOptionId);
            showCancelCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  // Return Button
  const showReturnCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:The_registration_request_has_been_reverted')
          : t('text:The_modification_request_has_been_reverted')}
      </h6>,
      { closeBtnCb: resetPage },
    );
  };

  const handleReturnButtonClick = () => {
    let reason = '';

    showModal(
      <>
        <h6>
          {t('text:Would_you_like_to_revert_the_registration_request?')}
          <br />
          {t('text:Please_enter_the_reason_for_the_revert_below')}
        </h6>
        <ReasonModal
          modalId={modalId}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: async () => {
          try {
            await requestReturnWaitingAnchorFinancingOption(waitingAnchorFinancingOptionId, reason);
            showReturnCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  // Confirm Button
  const showConfirmCompletedModal = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement ? (
          <>
            {t('text:The_Anchor_Financing_Option_has_been_registered_successfully')}
            <br />
            {t('text:Please_check_the_registered_information_in_the_Registration_Completed_tab')}
          </>
        ) : (
          <>
            {t('text:The_Anchor_Financing_Option_has_been_modified_successfully')}
            <br />
            {t('text:Please_check_the_modified_information_in_the_Registration_Completed_tab')}
          </>
        )}
      </h6>,
      {
        closeBtnCb: resetPage,
      },
    );
  };

  const handleConfirmButtonClick = () => {
    showModal(
      <h6>
        {isFirstRegisteredWaitingAgreement
          ? t('text:Would_you_like_to_approve_the_Anchor_Financing_Option_registration_request?')
          : t('text:Would_you_like_to_approve_the_Anchor_Financing_Option_modification_request?')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            await requestApproveWaitingAnchorFinancingOption(waitingAnchorFinancingOptionId);
            showConfirmCompletedModal();
          } catch (e) {
            showModal(e);
          }
        },
      },
    );
  };

  // Guide Message
  const getGuideMessageOptionsForRequestType = () => {
    let message: string[] = [];
    const fixedMessage = isFirstRegisteredWaitingAgreement
      ? t('text:Registration_of_the_anchor_financing_option_has_been_requested')
      : t('text:Modification_of_the_anchor_financing_option_has_been_requested');

    if (isSameBranch) {
      switch (signInModel?.authorityType) {
        case AUTHORITY_TYPE.OPERATOR:
          message = [fixedMessage, t('text:To_cancel_the_request_please_click_on_the_Cancel_Request_button_below')];
          break;
        case AUTHORITY_TYPE.AUTHORIZER:
          message = [
            fixedMessage,
            t(
              'text:Please_check_the_anchor_financing_option_information_and_press_the_button_below_to_proceed_with_approval',
            ),
          ];
          break;
        default:
          message = [fixedMessage];
          break;
      }
    } else {
      message = [fixedMessage];
    }

    return { message };
  };

  const getGuideMessageOptionsForReturnType = () => {
    let message: string[] = [];
    const fixedMessage = isFirstRegisteredWaitingAgreement
      ? t('text:The_anchor_financing_option_registration_request_has_been_reverted')
      : t('text:The_anchor_financing_option_modification_request_has_been_reverted');

    isSameBranch && signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR
      ? (message = [fixedMessage, t('text:Please_check_the_reason_for_the_revert_below_and_proceed_with_the_update')])
      : (message = [fixedMessage]);

    return {
      message,
      messageType: MessageType.ALERT,
      reason: returnReason,
      reasonTitle: t('text:REASON_FOR_REVERT'),
    };
  };

  const GuideMessageOption = (() => {
    if (approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) return getGuideMessageOptionsForRequestType();

    if (approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED) return getGuideMessageOptionsForReturnType();

    return null;
  })();

  useEffect(() => {
    resetPage();
  }, []);

  return {
    isAr,
    isSameBranch,
    isEditable,
    isEditableStyle,
    isFirstRegisteredWaitingAgreement,
    methods,
    GuideMessageOption,
    supportedCollateralType,
    conditionChecker,
    handleCancelButtonClick,
    handleChangeRequestButtonClick,
    handleReturnButtonClick,
    handleConfirmButtonClick,
    approvalType,
  };
};

export default useWaitingDetailController;
