import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';

interface SearchUploadedPartnerModalPropsType {
  uploadedPartner: AnchorPartnerAccountVOModel;
}

function SearchUploadedPartnerModal({ uploadedPartner }: SearchUploadedPartnerModalPropsType) {
  const { t } = useTranslation(['format']);

  return (
    <>
      <h6 className="mb-3">
        {t('text:Please_check_that_the_information_below_matches_the_partners_information_you_want_to_register')}
        <br />
        {t('text:If_not,_please_click_the_Cancel_button_to_modify_or_register_partner_information')}
      </h6>

      <FormBorder>
        <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Uploaded_Partner_Information')} />
        <FormContents>
          <div className="row">
            <FormValue label={t('text:Uploaded_Partner_Name')} value={uploadedPartner.partnerName} />
            <FormValue label={t('text:Uploaded_Partner_Tax_Code')} value={uploadedPartner.partnerTaxCode} />
          </div>
          <div className="row">
            <FormValue label={t('text:Associated_Anchor_Name')} value={uploadedPartner.anchorName} />
            <FormValue
              label={t('text:Associated_Master_Agreement_of_Anchor_Number')}
              value={uploadedPartner.anchorAgreementContractNo}
            />
          </div>
          <div className="row">
            <FormValue label={t('text:Currency')} value={uploadedPartner.currencyType} />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default SearchUploadedPartnerModal;
