import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import { ROUTES_PP } from 'constants/routes/potentialPartner';

import './PotentialPartnerApplicationCompletePage.scss';

function PotentialPartnerApplicationCompletePage() {
  const { t } = useTranslation();
  const { potentialPartnerFinancingApplicationId } = useLocation<{ potentialPartnerFinancingApplicationId: number }>()
    .state;

  return (
    <div className="pp-application-page-wrapper--complete">
      <div className="pp-application-complete">
        <FontAwesomeIcon icon={faCheck} fontSize={128} />

        <div className="pp-application-complete__title">{t('text:Application_Complete!')}</div>
        <div className="pp-application-complete__msg">
          {t('text:Your_application_was_completed_successfully')}
          <br />
          {t('text:The_financier_will_be_in_contact_with_you_soon')}
        </div>

        <div className="pp-application-complete__buttons">
          <Link to={ROUTES_PP.MY_INFO.SUBMISSION_DETAIL_BUILD_PATH(potentialPartnerFinancingApplicationId)}>
            <Button2 className="pp-mr-16" size={Button2SizeEnum.LG} color={Button2ColorEnum.TERTIARY}>
              {t('text:View_application')}
            </Button2>
          </Link>
          <div>
            <Link to={ROUTES_PP.HOME}>
              <Button2 size={Button2SizeEnum.LG} icon={<FontAwesomeIcon icon={faPlus} fontSize={17} />}>
                {t('text:Submit_More')}
              </Button2>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PotentialPartnerApplicationCompletePage;
