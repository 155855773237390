import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import FormInput from 'components/stateless/CommonForm/FormInput';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import { ROUTES_CM } from 'constants/routes/common';
import { formErrorHandler } from 'utils/error/manager';
import { requestUserFindId } from 'utils/http/api/anonymous/users';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

function FindId() {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();
  const { register, handleSubmit, getValues, errors, setError, clearErrors } = useForm<{ email: string }>({
    mode: 'onSubmit',
  });

  const onFindId = async () => {
    const email = getValues('email');
    try {
      const response = await requestUserFindId(email);
      response ? history.push(ROUTES_CM.FIND_ID_EMAIL_SENT) : showInfoErrorModal();
    } catch (e) {
      modal.show(e);
      formErrorHandler<{ email: string }>(e, setError, clearErrors);
    }
  };

  const showInfoErrorModal = () => {
    modal.show(
      <h6>
        {t('text:The_Information_is_not_valid')}
        <br />
        {t('text:Please_check_your_information')}
      </h6>,
      {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
      },
    );
  };

  return (
    <>
      <PublicHeaderBar />
      <div className="align-middle row">
        <div className="find-form">
          <h2 className="login-form__title">{t('text:Forgot_Your_ID')}</h2>
          <div className="border-bottom-light-gray mb-3" />
          <form>
            <div className="login-form__input">
              <FormInput
                label={t(`text:Email`)}
                placeholder={t('text:Please_type_here')}
                name="email"
                ref={register}
                col={12}
                error={errors.email}
              />
            </div>
            <div className="mt-3">
              <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onFindId)} type="submit">
                {t('text:Find_ID')}
              </Button>
            </div>

            <Link to="/">
              <div className="sign-in-link">{t('text:Back_to_Sign_in')}</div>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
}

export default FindId;
