import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { isNil } from 'lodash-es';

import type { SelectOptionType } from 'components/stateless/Form/Select/Select';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestFiDivisions } from 'utils/http/api/financier/divisions';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

export interface DivisionSelectOptionType extends SelectOptionType {
  name: string;
}

type DivisionsStateType = {
  financierName: string;
  divisionRegistrable: boolean;
  divisionName: string;
  divisionSelectOptions: DivisionSelectOptionType[];
  updateDivisionRegistrable: (divisionRegistrable: boolean) => void;
  updateDivisionName: (divisionName: string) => void;
  fetchDivisionRegistrable: () => Promise<void>;
  fetchDivisionSelectOptions: (anchorClientId: number) => Promise<void>;
};

const DivisionsContext = createContext<DivisionsStateType | null>(null);

export const useDivisionsContext = () => {
  const context = useContext(DivisionsContext);
  if (!context) {
    throw Error('useDivisionsContext should be used within DivisionsContext.Provider');
  }

  return context;
};

type DivisionsProviderPropsType = {
  children: ReactNode;
};

const DivisionsProvider = ({ children }: DivisionsProviderPropsType) => {
  const { show: showModal } = useModal();

  const [divisionRegistrable, setDivisionRegistrable] = useState(false);
  const [divisionName, setDivisionName] = useState('');
  const [divisionSelectOptions, setDivisionSelectOptions] = useState<DivisionSelectOptionType[]>([]);
  const [financierName, setFinancierName] = useState<string>('');

  const updateDivisionRegistrable = (divisionRegistrable: boolean) => setDivisionRegistrable(divisionRegistrable);

  const updateDivisionName = (divisionName: string) => setDivisionName(divisionName);

  const fetchDivisionRegistrable = useCallback(async () => {
    const enterpriseId = getSignIn()?.enterpriseId;

    if (isNil(enterpriseId)) return;

    try {
      const { divisionRegistrable, financierName } = await requestFinancierSettingData(enterpriseId);
      setDivisionRegistrable(divisionRegistrable);
      setFinancierName(financierName);
    } catch (error) {
      showModal(error);
    }
  }, [showModal]);

  const fetchDivisionSelectOptions = useCallback(
    async (anchorClientId: number) => {
      try {
        const result = await requestFiDivisions(1, 100, { anchorClientId });

        const divisionSelectOptions: DivisionSelectOptionType[] = result.content.map(({ code, name }) => ({
          label: code,
          value: code,
          name,
        }));

        setDivisionSelectOptions(divisionSelectOptions.length === 0 ? [] : divisionSelectOptions);
      } catch (error) {
        showModal(error);
      }
    },
    [showModal],
  );

  const value = useMemo(
    () => ({
      divisionRegistrable,
      divisionName,
      divisionSelectOptions,
      financierName,
      updateDivisionRegistrable,
      updateDivisionName,
      fetchDivisionRegistrable,
      fetchDivisionSelectOptions,
    }),
    [
      divisionRegistrable,
      divisionName,
      divisionSelectOptions,
      financierName,
      fetchDivisionRegistrable,
      fetchDivisionSelectOptions,
    ],
  );

  return <DivisionsContext.Provider value={value}>{children}</DivisionsContext.Provider>;
};

export default DivisionsProvider;
