import { useTranslation } from 'react-i18next';

import Form from 'components/stateless/Form';
import { Cell, Content, Row, SubTitle } from 'components/templates/section';

import usePlatformPermissionSettingsController from './usePlatformPermissionSettingsController';

interface PlatformPermissionSettingsPropsType {
  isVendorFactoring?: boolean;
}

const PlatformPermissionSettings = ({ isVendorFactoring }: PlatformPermissionSettingsPropsType) => {
  const { t } = useTranslation(['format']);

  const { registrationRequestLabel, registrationRequestDescription } = usePlatformPermissionSettingsController();

  return (
    <>
      <SubTitle>{t('text:PLATFORM_PERMISSION_SETTINGS')}</SubTitle>
      <Content>
        <Row>
          <Form.Control name="dealerUploadInvoiceAllowable" required>
            <Cell>
              <Form.Label position="top">{registrationRequestLabel}</Form.Label>
              <Form.Description>{registrationRequestDescription}</Form.Description>
            </Cell>
            <Form.Radio>
              <Form.Option label={t('text:Allowed')} value="true" />
              <Form.Option label={t('text:Not_Allowed')} value="false" />
            </Form.Radio>
          </Form.Control>
        </Row>
        {!isVendorFactoring && (
          <Row>
            <Form.Control name="showExpectedTotalInterestRate">
              <Cell>
                <Form.Label position="top">{t('text:Expected_Interest')}</Form.Label>
                <Form.Description>
                  {t(
                    'text:Select_whether_the_Partner_may_see_the_expected_total_interest_rate_and_amount_when_applying_for_financing',
                  )}
                </Form.Description>
              </Cell>
              <Form.Radio defaultValue="true">
                <Form.Option label={`${t('text:Show')} (${t('text:Default')})`} value="true" />
                <Form.Option label={t('text:Do_Not_Show')} value="false" />
              </Form.Radio>
            </Form.Control>
          </Row>
        )}
      </Content>
    </>
  );
};

export default PlatformPermissionSettings;
