import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';

interface DesignatedBankAccountInformationProps {
  dealerAgreementDetail: DealerAgreementDetailVOModel;
}

function DesignatedBankAccountInformation({ dealerAgreementDetail }: DesignatedBankAccountInformationProps) {
  const { t } = useTranslation(['format']);

  return (
    <>
      <SectionTitle title={t('text:Designated_Bank_Account_Information')} />

      <GuideMessage
        isImportContentArea={true}
        message={[
          t(
            'text:Check_the_information_of_the_disbursement_account_and_the_repayment_accounts_designated_on_the_Partner_Master_Agreement',
          ),
          t('text:Click_on_the_checkbox_to_confirm_the_information_of_the_designated_bank_accounts_is_correct'),
        ]}
      />

      <FormBorder hideBorderBottom>
        <FormSubtitle title={t('text:Disbursement_Account')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Bank_Name')} value={dealerAgreementDetail?.settlementAccountFinancierName} />
            <FormValue label={t('text:Branch_Name')} value={dealerAgreementDetail?.settlementAccountBranchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Bank_Account_Number')} value={dealerAgreementDetail?.settlementAccount} />
            <FormValue label={t('text:Bank_Account_Holder')} value={dealerAgreementDetail?.settlementAccountOwner} />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:Principal_Repayment_Account')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              label={t('text:Bank_Name')}
              value={dealerAgreementDetail?.principalRepaymentAccountFinancierName}
            />
            <FormValue
              label={t('text:Branch_Name')}
              value={dealerAgreementDetail?.principalRepaymentAccountBranchName}
            />
          </div>
          <div className="row">
            <FormValue label={t('text:Bank_Account_Number')} value={dealerAgreementDetail?.principalRepaymentAccount} />
            <FormValue
              label={t('text:Bank_Account_Holder')}
              value={dealerAgreementDetail?.principalRepaymentAccountOwner}
            />
          </div>
        </FormContents>
        <FormSubtitle title={t('text:Interest_Repayment_Account')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              label={t('text:Bank_Name')}
              value={dealerAgreementDetail?.interestRepaymentAccountFinancierName}
            />
            <FormValue
              label={t('text:Branch_Name')}
              value={dealerAgreementDetail?.interestRepaymentAccountBranchName}
            />
          </div>
          <div className="row">
            <FormValue label={t('text:Bank_Account_Number')} value={dealerAgreementDetail?.interestRepaymentAccount} />
            <FormValue
              label={t('text:Bank_Account_Holder')}
              value={dealerAgreementDetail?.interestRepaymentAccountOwner}
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default DesignatedBankAccountInformation;
