import type { Field, FieldErrors, FieldValues } from 'react-hook-form';

export const updateErrors = <T extends FieldValues>(errors: (FieldErrors<T> | undefined)[], indexes: number[]) => {
  let i = 0;

  for (const index of indexes) {
    errors.splice(index - i, 1);
    i++;
  }

  return errors;
};

export const updateErrorsRefWithFieldsRef = <T extends FieldValues>(
  errors: (FieldErrors<T> | undefined)[],
  fieldsRef: Partial<Record<string, Field>>,
  fieldName: 'arList' | 'invoiceList',
) => {
  errors.forEach((error, index) => {
    for (const field in error) {
      (error as any)[field].ref = fieldsRef[`${fieldName}.${index}.${field}`]?.ref;
    }
  });
};
