import { useState } from 'react';

import useLocationState from 'hooks/useLocationState';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import { requestAnAnchorPartnerDetail } from 'utils/http/api/anonymous/anchor-partners';
import { requestAnFinancierBranch } from 'utils/http/api/anonymous/branches';
import { requestAnFinancierPotentialPartnerAcquisitionDocumentList } from 'utils/http/api/anonymous/potential-partner-acquisition-documents';
import { requestAnPotentialPartnerAcquisitionSettingFindOneByFinancier } from 'utils/http/api/anonymous/potential-partner-acquisition-settings';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';

import type {
  PotentialPartnerApplicationPageLocationStateType,
  PotentialPartnerApplicationStep1AnchorPartnerInfoType,
  PotentialPartnerApplicationStep1FinancierGuideMessageType,
} from './types';

interface PotentialPartnerApplicationStateType {
  financierGuideMessageInfo: PotentialPartnerApplicationStep1FinancierGuideMessageType;
  anchorPartnerInfo: PotentialPartnerApplicationStep1AnchorPartnerInfoType;
  branchesInfo: BranchVOModel[];
  requiredPotentialPartnerAcquisitionDocument: PotentialPartnerAcquisitionDocumentVOModel[];
}
export function usePotentialPartnerApplicationState() {
  const [locationState, errorHandlerOfLocationState] =
    useLocationState<PotentialPartnerApplicationPageLocationStateType>([
      'anchorClientName',
      'financierName',
      'financierId',
      'anchorPartnerId',
      'anchorPartnerAccountId',
      'currencyType',
    ]);

  const { anchorPartnerId, financierId, anchorPartnerAccountId, anchorClientName, financierName } = locationState;
  const emailCode = getPotentialPartnerEmailCode();
  const modal = useModal();

  const [dataState, setDataState] = useState<PotentialPartnerApplicationStateType>({
    financierGuideMessageInfo: {} as PotentialPartnerApplicationStep1FinancierGuideMessageType,
    anchorPartnerInfo: {} as PotentialPartnerApplicationStep1AnchorPartnerInfoType,
    branchesInfo: [] as BranchVOModel[],
    requiredPotentialPartnerAcquisitionDocument: [] as PotentialPartnerAcquisitionDocumentVOModel[],
  });

  // fetch branch info, required documents
  async function fetchAll() {
    if (!emailCode) return modal.show('not found email code');
    try {
      const [
        fetchFinancierAcquisitionSetting,
        fetchAnchorPartnerInfo,
        fetchBranch,
        fetchFinancierPotentialPartnerAcquisitionDocuments,
      ] = await Promise.all([
        requestAnPotentialPartnerAcquisitionSettingFindOneByFinancier(emailCode, financierId),
        requestAnAnchorPartnerDetail(anchorPartnerId, emailCode),
        requestAnFinancierBranch(0, 100, emailCode, financierId),
        requestAnFinancierPotentialPartnerAcquisitionDocumentList(0, 100, emailCode, financierId),
      ]);

      setDataState(prevState => ({
        ...prevState,
        financierGuideMessageInfo: {
          message: fetchFinancierAcquisitionSetting.message,
          anchorClientName,
          financierName,
        },
        anchorPartnerInfo: { ...fetchAnchorPartnerInfo, anchorPartnerAccountId },
        branchesInfo: fetchBranch.content,
        requiredPotentialPartnerAcquisitionDocument: fetchFinancierPotentialPartnerAcquisitionDocuments.content,
        anchorFinancierInfo: { anchorClientName, financierName },
      }));
    } catch (e) {
      modal.show(e);
    }
  }

  return {
    state: dataState,
    errorHandlerOfLocationState,
    fetchAll,
  };
}
