import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { ROUTES_CM } from 'constants/routes/common';
import type { CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE } from 'enums';
import { formErrorHandler } from 'utils/error/manager';
import { requestOnDemandCreateFinancier } from 'utils/http/api/anonymous/on-demand';
import type { CreateFinancierAndAdminUserDTO } from 'utils/http/api/anonymous/on-demand/requests';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import {
  CHECK_LOGIN_ID_STATUS,
  useCreateFinancierStep2Actions,
  useCreateFinancierStep2Value,
} from './CreateFinancierStep2Provider';
import FinancierAdminInformation from './sections/FinancierAdminInformation';
import { useCreateFinancierActions } from '../../CreateFinancierProvider';

const CreateFinancierStep2 = () => {
  const { t } = useTranslation();
  const { show: showModal } = useModal();
  const history = useHistory();

  const { onStepTransition, onCancelClick: handleCancelClick } = useCreateFinancierActions();

  const { checkLoginIdStatus } = useCreateFinancierStep2Value();
  const { updateCheckLoginIdStatus } = useCreateFinancierStep2Actions();

  const { getValues, setError, clearErrors } = useFormContext<CreateFinancierAndAdminUserDTO>();

  const handleBackClick = () => {
    onStepTransition(-1);
  };

  const handleRegistrationClick = async () => {
    if (checkLoginIdStatus === CHECK_LOGIN_ID_STATUS.NOT_CHECKED)
      updateCheckLoginIdStatus(CHECK_LOGIN_ID_STATUS.INVALID);

    if (checkLoginIdStatus !== CHECK_LOGIN_ID_STATUS.VALID) return;

    const data = getValues();

    data.supportedCurrencyTypes = data.supportedCurrencyTypes
      ? (String(data.supportedCurrencyTypes).split(',') as CURRENCY_TYPE[])
      : [];

    try {
      requestDTOParser(data);
      await requestOnDemandCreateFinancier(data);

      const { loginId, userName } = data;

      history.push(ROUTES_CM.SIGN_UP_COMPLETE, {
        userId: loginId,
        userName,
        userAuthority: AUTHORITY_TYPE.ADMIN,
      });
    } catch (e) {
      showModal(e);
      formErrorHandler<CreateFinancierAndAdminUserDTO>(e, setError, clearErrors);
    }
  };

  return (
    <>
      <FinancierAdminInformation />
      <div className="d-flex mt-5">
        <Button
          className="me-auto"
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
          size={ButtonSizeEnum.LG}
          onClick={handleCancelClick}
        >
          {t('text:Cancel')}
        </Button>
        <Button
          size={ButtonSizeEnum.LG}
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
          onClick={handleBackClick}
          className="me-2"
        >
          {t('text:Back')}
        </Button>
        <Button size={ButtonSizeEnum.LG} onClick={handleRegistrationClick}>
          {t('text:Complete_Registration')}
        </Button>
      </div>
    </>
  );
};
export default CreateFinancierStep2;
