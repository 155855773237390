import { useTranslation } from 'react-i18next';

import MSWDevTool from 'components/stateless/MSWDevTool';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';

import MonthSummary from './sections/month-summary';
import UnitPrice from './sections/unit-price';
import UsageHistory from './sections/usage-history';

function FinancierBilling() {
  const { t } = useTranslation();

  return (
    <>
      <HeaderTitle title={t('text:Billing')} />
      <UnitPrice />
      <MonthSummary />
      <UsageHistory />
      <MSWDevTool />
    </>
  );
}

export default FinancierBilling;
