import type { POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS } from 'enums';
import type Pageable from 'models/Pageable';
import type {
  PotentialPartnerFinancingApplicationDocumentVO,
  PotentialPartnerFinancingApplicationDocumentVOModel,
} from 'models/vo/PotentialPartnerFinancingApplicationDocumentVO';
import { formattingToPotentialPartnerFinancingApplicationDocumentVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDocumentVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

/**
 * desc :: 문서 목록
 * params :: potentialPartnerFinancingApplicationId
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationDocumentList(
  pageNumber: number,
  rowCount: number,
  potentialPartnerFinancingApplicationId: number,
): Promise<Pageable<PotentialPartnerFinancingApplicationDocumentVOModel[]>> {
  const response = await http.get<Pageable<PotentialPartnerFinancingApplicationDocumentVO[]>>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS.APPLICATION_DOCUMENT_LIST,
    data: { pageNumber, rowCount, potentialPartnerFinancingApplicationId },
  });

  return {
    ...response,
    content: response.content.map((data: PotentialPartnerFinancingApplicationDocumentVO) =>
      formattingToPotentialPartnerFinancingApplicationDocumentVOModel(data),
    ),
  };
}

/**
 * desc :: 문서 다운로드
 * params :: potentialPartnerFinancingApplicationDocumentId
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationDownloadDocuments(
  potentialPartnerFinancingApplicationDocumentId: number,
) {
  await http.download(
    API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS.DOWNLOAD_ATTACHMENT(
      potentialPartnerFinancingApplicationDocumentId,
    ),
  );
}

/**
 * desc :: 제출한 문서 상태 변경
 * params :: potentialPartnerFinancingApplicationId, targetStatus
 * **/
export async function requestFinancierPotentialPartnerFinancingApplicationUpdateDocuments(
  potentialPartnerFinancingApplicationDocumentId: number,
  targetStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS,
): Promise<PotentialPartnerFinancingApplicationDocumentVOModel> {
  const response = await http.put<PotentialPartnerFinancingApplicationDocumentVO>({
    url: API_FI.POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS.UPDATE_DOCUMENT_STATUS(
      potentialPartnerFinancingApplicationDocumentId,
    ),
    data: { targetStatus },
  });

  return formattingToPotentialPartnerFinancingApplicationDocumentVOModel(response);
}
