import type { LOAN_STATUS } from 'enums';
import type { BigNumber } from 'utils/bigNumber';

export interface ArCommissionSettlementRelatedLoanVO {
  loanId: number;
  arNumber: string;
  principalAmount: BigNumber;
  netDisbursementAmount: BigNumber; // Early Payment
  disbursedAmount: BigNumber; // Repayment to Withdraw
  disbursedDate: string;
  loanStatus: LOAN_STATUS;
}

export interface ArCommissionSettlementRelatedLoanVOModel extends ArCommissionSettlementRelatedLoanVO {}

export function formattingToArCommissionSettlementRelatedLoanVOModel(
  data: ArCommissionSettlementRelatedLoanVO,
): ArCommissionSettlementRelatedLoanVOModel {
  return {
    ...data,
  };
}
