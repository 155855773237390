import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';

import Form, { SizeType } from 'components/stateless/Form';
import NumberInput from 'components/stateless/Form/NumberInput/NumberInput';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';
import { COST_OF_FUND_TYPE } from 'enums';
import type { CreateFiOverdueInterestRateSettingRequest } from 'utils/http/api/financier/overdue-interest-rate-settings/request';

interface CostOfFundFormProps {
  isEditable: boolean;
}

function CostOfFundForm({ isEditable }: CostOfFundFormProps) {
  const { t } = useTranslation();
  const methods = useFormContext<CreateFiOverdueInterestRateSettingRequest>();
  const { watch, register, setValue, getValues, errors } = methods;
  const { useCostOfFund, costOfFund } = watch();

  const handleCostOfFundTypeChange = () => {
    setValue('costOfFund.amount', null);
    setValue('costOfFund.rate', null);
  };

  const handleUseCostOfFundChange = () => {
    setValue('costOfFund.amount', null);
    setValue('costOfFund.rate', null);
    setValue('costOfFund.daysToBill', null);
    setValue('costOfFund.type', null);
  };

  const handleRateChange = (value: string) => {
    setValue('costOfFund.rate', value);
  };

  return (
    <Form methods={methods} isEditable={isEditable} isEditableStyle={true}>
      <Border isEditable={isEditable}>
        <SubTitle>{t('text:PENALTY_FEE_SETTINGS')}</SubTitle>
        <Content>
          <Row>
            <Form.Control name="useCostOfFund" required>
              <Cell>
                <Form.Label position="top">
                  {t('text:Do_you_charge_the_penalty_fee_in_case_of_delinquent_principal_and_interest?')}
                </Form.Label>
              </Cell>
              <Cell>
                <Form.Radio onChange={handleUseCostOfFundChange}>
                  <Form.Option value="true" label={t('text:Yes')} />
                  <Form.Option value="false" label={t('text:No')} />
                </Form.Radio>
              </Cell>
            </Form.Control>
          </Row>
          <Row>
            <Form.Control name="costOfFund.daysToBill" required={useCostOfFund === 'true'}>
              <Form.Label>
                {t('text:From_how_many_days_after_the_start_of_delinquency_does_the_penalty_fee_incur?')}
              </Form.Label>
              <Cell
                className={clsx({
                  opacityApplied: useCostOfFund === 'false',
                })}
              >
                <Form.NumberInput
                  fieldSize={SizeType.SM}
                  placeholder={t('text:Please_type_here')}
                  rightUnit={t('text:Days')}
                  errors={errors.costOfFund?.daysToBill}
                  numberType="integer"
                />
              </Cell>
            </Form.Control>
          </Row>
          <Row>
            <Form.Control name="costOfFund.type" required={useCostOfFund === 'true'}>
              <Cell>
                <Form.Label position="top">{t('text:Type_of_Penalty_Fee')}</Form.Label>
                <Form.Description>{t('text:Please_choose_the_amount_for_the_penalty_fee')}</Form.Description>
              </Cell>
              <Cell
                className={clsx({
                  opacityApplied: useCostOfFund === 'false',
                })}
              >
                <input name="costOfFund.rate" type="hidden" ref={register} />
                <Form.Radio onChange={handleCostOfFundTypeChange}>
                  <Form.Option
                    value={COST_OF_FUND_TYPE.FIXED}
                    label={t('text:Fixed_Amount')}
                    render={(isParentReadOnly, isParentRadioUnChecked) => (
                      <Border>
                        <Content isInnerStyle>
                          <Row>
                            <Form.Control name="costOfFund.amount">
                              <Form.Label>{t('text:Amount')}</Form.Label>
                              <Form.NumberInput
                                rightUnit={getValues().currencyType}
                                fieldSize={SizeType.MD}
                                textAlign="text-right"
                                useIsAllowed
                                readOnly={isParentRadioUnChecked}
                                parentReadOnly={isParentReadOnly}
                              />
                            </Form.Control>
                          </Row>
                        </Content>
                      </Border>
                    )}
                  />
                  <Form.Option
                    value={COST_OF_FUND_TYPE.PRINCIPAL}
                    label={t('text:Proportional_to_the_principal')}
                    render={(isParentReadOnly, isParentRadioUnChecked) => (
                      <Border>
                        <Content isInnerStyle>
                          <Row>
                            <Form.Control>
                              <Form.Label>{t('text:Ratio')} (%)</Form.Label>
                              <NumberInput
                                fieldSize={SizeType.SM}
                                useUpToOneHundredPercent
                                rightUnit="%"
                                textAlign="text-right"
                                readOnly={isParentRadioUnChecked}
                                onChange={handleRateChange}
                                isEditable={isEditable && !isParentRadioUnChecked}
                                value={
                                  costOfFund?.type === COST_OF_FUND_TYPE.PRINCIPAL
                                    ? String(costOfFund?.rate)
                                    : undefined
                                }
                              />
                            </Form.Control>
                          </Row>
                        </Content>
                      </Border>
                    )}
                  />
                  <Form.Option
                    value={COST_OF_FUND_TYPE.BALANCE}
                    label={t('text:Proportional_to_the_delinquent_balance')}
                    render={(isParentReadOnly, isParentRadioUnChecked) => (
                      <Border>
                        <Content isInnerStyle>
                          <Row>
                            <Form.Control>
                              <Form.Label>{t('text:Ratio')} (%)</Form.Label>
                              <NumberInput
                                fieldSize={SizeType.SM}
                                useUpToOneHundredPercent
                                rightUnit="%"
                                textAlign="text-right"
                                readOnly={isParentRadioUnChecked}
                                onChange={handleRateChange}
                                isEditable={isEditable && !isParentRadioUnChecked}
                                value={
                                  costOfFund?.type === COST_OF_FUND_TYPE.BALANCE ? String(costOfFund?.rate) : undefined
                                }
                              />
                            </Form.Control>
                          </Row>
                        </Content>
                      </Border>
                    )}
                  />
                </Form.Radio>
              </Cell>
            </Form.Control>
          </Row>
        </Content>
      </Border>
    </Form>
  );
}

export default CostOfFundForm;
