import { useTranslation } from 'react-i18next';

import { WAITING_INVOICE_STATUS } from 'enums';

type WaitingInvoiceResultTextPropsType = {
  status: WAITING_INVOICE_STATUS;
  exceptionMessage?: string;
};

const WaitingInvoiceResultText = ({ status, exceptionMessage }: WaitingInvoiceResultTextPropsType) => {
  const { t } = useTranslation(['format']);

  const message = exceptionMessage && exceptionMessage.split(':').at(-1)?.trim();

  switch (status) {
    case WAITING_INVOICE_STATUS.REGISTERED:
      return <td className="text-bold-blue-main600">{t('text:Pass')}</td>;
    case WAITING_INVOICE_STATUS.INVALID_SETTLEMENT_DATE:
      return <td className="text-red">{t('text:Fail_The_payment_date_has_already_passed')}</td>;
    case WAITING_INVOICE_STATUS.INVALID_OPERATION_TIME:
      return (
        <td className="text-red">{t('text:Fail_There_is_less_than_1_operating_hour_left_of_the_payment_date')}</td>
      );
    case WAITING_INVOICE_STATUS.INVALID_BILL_OF_EXCHANGE_NO:
      return <td className="text-red">{t('text:Fail_This_BOE_number_has_already_been_registered')}</td>;
    case WAITING_INVOICE_STATUS.DUPLICATE_INVOICE:
      return <td className="text-red">{t('text:Fail_Duplicate_Invoice_Number')}</td>;
    case WAITING_INVOICE_STATUS.ALREADY_CONFIRMED_INVOICE:
      return <td className="text-red">{t('text:Fail_Already_Registered_Invoice')}</td>;
    case WAITING_INVOICE_STATUS.DUPLICATE_REFERENCE_NUMBER:
      return <td className="text-red">{t('text:Fail_Duplicate_Reference_Number')}</td>;
    case WAITING_INVOICE_STATUS.ALREADY_CONFIRMED_REFERENCE_NUMBER:
      return <td className="text-red">{t('text:Fail_Already_Registered_Reference_Number')}</td>;
    case WAITING_INVOICE_STATUS.LOAN_REQUEST_FAILED:
      return (
        <td className="text-red">
          {t('text:Fail_The_financing_application_failed_causing_the_invoice_registration_to_fail')}
        </td>
      );
    case WAITING_INVOICE_STATUS.LOAN_APPROVE_FAILED:
      return (
        <td className="text-red">
          {t('text:Fail_The_financier_has_rejected_the_invoice_registration_request')} {message}
        </td>
      );
    case WAITING_INVOICE_STATUS.INVALID_INVOICE:
      return <td className="text-red">{t('text:Fail_Invalid_Invoice')}</td>;
    case WAITING_INVOICE_STATUS.SETTLEMENT_DATE_EXCEED_AGREEMENT_EXPIRY_DATE:
      return (
        <td className="text-red">
          {t('text:Fail_The_invoice_payment_date_must_not_exceed_the_Anchor_Master_Agreement_expiration_date')}
        </td>
      );
    case WAITING_INVOICE_STATUS.SETTLEMENT_DATE_INVOICE_TODAY_NOT_UPLOADABLE:
      return <td className="text-red">{t('text:Fail_Payment_date_cannot_be_today')}</td>;
    case WAITING_INVOICE_STATUS.AMOUNT_EXCEED_LIMIT:
      return (
        <td className="text-red">{t('text:Fail_The_total_sum_of_invoices_must_not_exceed_the_remaining_limit')}</td>
      );
    case WAITING_INVOICE_STATUS.INVOICE_REGISTER_FAILED:
      return <td className="text-red">{t('text:Fail_The_invoice_registration_failed')}</td>;
    case WAITING_INVOICE_STATUS.SETTLEMENT_DATE_EXCEED_MAX_SETTLEMENT_DATE:
      return (
        <td className="text-red">
          {t('text:Fail_The_invoice_payment_date_cannot_exceed_the_maximum_payment_date_that_can_be_set')}
        </td>
      );
    case WAITING_INVOICE_STATUS.STOP_SUPPLIER:
      return <td className="text-red">{t('text:Fail_This_Partner_has_been_suspended')}</td>;
    case WAITING_INVOICE_STATUS.REPAYMENT_DATE_EXCEED_DEALER_AGREEMENT_EXPIRY_DATE:
      return (
        <td className="text-red">
          {t('text:Fail_The_financing_repayment_date_must_not_exceed_the_Partner_Master_Agreement_expiration_date')}
        </td>
      );
    case WAITING_INVOICE_STATUS.INVOICE_ALREADY_REGISTERED:
      return <td className="text-red">{t('text:Fail_This_invoice_number_has_already_been_registered')}</td>;
    case WAITING_INVOICE_STATUS.PAYMENT_DATE_IS_HOLIDAY:
      return <td className="text-red">{t('text:Fail_The_AP_settlement_date_must_not_be_a_holiday')}</td>;
    case WAITING_INVOICE_STATUS.IN_PROGRESS:
      return <td className="text-yellow">{t('text:The_registration_is_in_progress')}</td>;
    default:
      return <td>{status}</td>;
  }
};

export default WaitingInvoiceResultText;
