import type React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isEmpty, isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import {
  FormBorder,
  FormContents,
  FormInput,
  FormSelect,
  FormSubtitle,
  FormValue,
} from 'components/stateless/CommonForm';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormGuideMessage } from 'components/stateless/GuideMessage/FormGuideMessage';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { REPORT_TYPE } from 'enums';
import { ANCHOR_AGREEMENT_STATUS, DEALER_AGREEMENT_STATUS } from 'enums';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import FinancierAnchorReportSearchAnchorAgreementModal from 'pages/financier/manage-anchor/report/modal/FinancierAnchorReportSearchAnchorAgreementModal';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import { requestFiAnchorReportReceiverRegistration } from 'utils/http/api/financier/anchor-report-receivers';
import type { FiAnchorReportReceiverRegistRequest } from 'utils/http/api/financier/anchor-report-receivers/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import FinancierAnchorReportUpdatePartnerAgreementModal from '../modal/FinancierAnchorReportUpdatePartnerAgreementModal';

const getConstants = () => {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const history = useHistory();

  const selectedPartnerInformationTableHeaders: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      className: 'text-left',
      colWidths: 200,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      className: 'text-left',
      colWidths: 200,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      className: 'text-left',
    },
    {
      headerText: t('text:Agreement_Suspension'),
      className: 'text-left',
      colWidths: 200,
    },
  ];

  return {
    t,
    modal,
    history,
    selectedPartnerInformationTableHeaders,
  };
};

function FinancierDealerReportRegister(): JSX.Element {
  const { t, modal, history, selectedPartnerInformationTableHeaders } = getConstants();

  const [anchorAgreementData, setAnchorAgreementData] = useState<AnchorAgreementVOModel>();
  const [partnerAgreementList, setPartnerAgreementList] = useState<DealerAgreementVOModel[]>();

  const { register, setValue, getValues, handleSubmit, watch, setError, clearErrors, errors } =
    useForm<FiAnchorReportReceiverRegistRequest>();

  const { anchorAgreementId } = watch();

  useEffect(() => {
    setPartnerAgreementList(undefined);
  }, [anchorAgreementData && anchorAgreementData.anchorAgreementId]);

  const onClickSearchAnchorAgreement = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    const showAnchorAgreementModal = (): void => {
      const modalId = modal.id;

      const getSelectedAnchorAgreementData = (data: AnchorAgreementVOModel): void => {
        setValue('anchorAgreementId', data.anchorAgreementId);
        setAnchorAgreementData(data);
      };

      modal.show(
        <FinancierAnchorReportSearchAnchorAgreementModal
          modalId={modalId}
          getSelectedAnchorAgreementData={getSelectedAnchorAgreementData}
        />,
        {
          title: t('text:Search_Anchor_Master_Agreement'),
          modalSize: ModalSize.XL,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:Close'),
        },
      );
    };

    showAnchorAgreementModal();
  };

  const onClickSearchPartnerAgreement = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    let tempCheckedDealerAgreementList: DealerAgreementVOModel[] = [];

    const getCheckedDealerAgreementList = (data: DealerAgreementVOModel[]): void => {
      tempCheckedDealerAgreementList = data;
    };

    const showPartnerAgreementModal = (): void => {
      modal.show(
        <FinancierAnchorReportUpdatePartnerAgreementModal
          taskType="CREATE"
          anchorAgreementId={anchorAgreementId}
          checkedDealerAgreementList={partnerAgreementList ? partnerAgreementList : []}
          getCheckedDealerAgreementList={getCheckedDealerAgreementList}
        />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Apply'),
          confirmBtnCb: () => setPartnerAgreementList(tempCheckedDealerAgreementList),
        },
      );
    };

    showPartnerAgreementModal();
  };

  const onClickRegister = async (data: FiAnchorReportReceiverRegistRequest, e: any): Promise<void> => {
    e.preventDefault();

    const requestData = getValues();

    const showRegisterModal = (): void => {
      modal.show(<h6>{t('text:The_recipient_information_has_been_registered_successfully')}</h6>, {
        closeBtnText: t('text:OK'),
        closeBtnCb: () => history.push(ROUTES_FI.MANAGE_PARTNER.REPORT_LIST),
      });
    };

    try {
      requestDTOParser(requestData);

      await requestFiAnchorReportReceiverRegistration({
        ...requestData,
        dealerAgreements: partnerAgreementList ? partnerAgreementList.map(item => item.dealerAgreementId) : [],
      });

      showRegisterModal();
    } catch (error) {
      modal.show(error);

      formErrorHandler<FiAnchorReportReceiverRegistRequest>(error, setError, clearErrors);
    }
  };

  const onClickCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    const showCancelModal = (): void => {
      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_registration')}
          <br />
          {t('text:The_information_will_not_be_saved_if_the_registration_is_cancelled')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => history.push(ROUTES_FI.MANAGE_PARTNER.REPORT_LIST),
        },
      );
    };

    showCancelModal();
  };

  const isSearchPartnerAgreementButtonDisabled = (): boolean => {
    return isNil(anchorAgreementId) || String(anchorAgreementId) === '';
  };

  const isRegisterButtonDisabled = (): boolean => {
    return isEmpty(partnerAgreementList) || isSearchPartnerAgreementButtonDisabled();
  };

  const getAgreementSuspensionText = (data?: ANCHOR_AGREEMENT_STATUS | DEALER_AGREEMENT_STATUS): string => {
    switch (data) {
      case ANCHOR_AGREEMENT_STATUS.ACTIVATED:
      case DEALER_AGREEMENT_STATUS.ACTIVATED:
        return t('text:Not_Suspended');
      case ANCHOR_AGREEMENT_STATUS.SUSPENDED:
      case DEALER_AGREEMENT_STATUS.SUSPENDED:
        return t('text:Suspended');
      default:
        return '';
    }
  };

  const renderSelectedPartnerInformationTable = (): JSX.Element[] | undefined => {
    return partnerAgreementList?.map((data, index) => (
      <Tr key={index}>
        <Td data={data.dealerFinancierClientName} />
        <Td data={data.dealerFinancierClientCode} />
        <Td data={data.contractNo} />
        <Td
          className={getStatusTextClass('DEALER_AGREEMENT_STATUS', data.dealerAgreementStatus)}
          data={getAgreementSuspensionText(data.dealerAgreementStatus)}
        />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Recipient_Registration')} />
      <div className="content-area">
        <SectionTitle title={t('text:Report_Recipient')} />
        <FormBorder editable>
          <FormGuideMessage
            message={[
              t(
                'text:Click_on_the_Search_Anchor_Agreement_button_to_the_right_to_select_the_Anchor_Master_Agreement_the_recipient_is_associated_with',
              ),
              `${t('text:Enter_the_recipients_information_below')} ${t(
                'text:The_report_will_be_sent_to_the_recipients_registered_email',
              )}`,
              <Trans
                key="key"
                i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
                components={{ 1: <span className="asterisk" /> }}
              />,
            ]}
          >
            {{
              button: <Button onClick={onClickSearchAnchorAgreement}>{t('text:Search_Anchor_Agreement')}</Button>,
            }}
          </FormGuideMessage>
          <FormSubtitle
            title={t('text:Associated_Anchor_Master_Agreement_Information')}
            backGroundType={BackGroundType.DarkGray}
          />
          <FormContents>
            <div className="row">
              <input type="hidden" name="anchorAgreementId" ref={register} />
              <FormValue label={t('text:Anchor_Name')} value={anchorAgreementData?.financierClientName} />
              <FormValue label={t('text:Anchor_Client_Code')} value={anchorAgreementData?.financierClientCode} />
            </div>
            <div className="row">
              <FormValue label={t('text:Anchor_Master_Agreement_Number')} value={anchorAgreementData?.contractNo} />
              <FormValue label={t('text:Currency')} value={anchorAgreementData?.currencyType} col={3} />
              <FormValue
                label={t('text:Program_Type')}
                value={t(`code:collateral-type.${anchorAgreementData?.collateralType}`)}
                format="code"
                col={3}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Agreement_Suspension')}
                value={getAgreementSuspensionText(anchorAgreementData?.anchorAgreementStatus)}
              />
              <FormValue
                label={t('text:Effective_Date')}
                value={anchorAgreementData?.startDate}
                format="date"
                col={3}
              />
              <FormValue
                label={t('text:Expiration_Date')}
                value={anchorAgreementData?.expiryDate}
                format="date"
                col={3}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Recipient_Information')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Recipient_Name')}
                name="name"
                ref={register}
                error={errors.name}
                requiredOptions={{ required: true }}
              />
              <FormInput
                label={t('text:Email')}
                name="email"
                ref={register}
                error={errors.email}
                requiredOptions={{ required: true }}
                col={3}
              />
              <FormInput
                label={t('text:Affiliation')}
                name="affiliation"
                ref={register}
                error={errors.affiliation}
                col={3}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Report_Setting')} />
        <FormBorder editable>
          <FormGuideMessage
            message={[
              t(
                'text:After_selecting_the_Anchor_Agreement_click_on_the_Search_Partner_Agreement_button_to_select_the_Partner_Master_Agreements_that_will_be_included_in_the_report',
              ),
              t(
                'text:Choose_the_type_of_report_the_recipient_should_receive_for_the_selected_Partner_Master_Agreements',
              ),
            ]}
          >
            {{
              button: (
                <Button disabled={isSearchPartnerAgreementButtonDisabled()} onClick={onClickSearchPartnerAgreement}>
                  {t('text:Search_Partner_Agreement')}
                </Button>
              ),
            }}
          </FormGuideMessage>
          <div className="border-bottom-light-gray" />
          <FormContents>
            <div className="row">
              <FormSelect
                label={t('text:Report_Type')}
                name="reportType"
                ref={register}
                error={errors.reportType}
                placeholderOptions={{ show: true }}
                selectOptions={getSelectOptions<REPORT_TYPE>('REPORT_TYPE')}
                required
              />
            </div>
            <TableBorder>
              <TableHeader header={selectedPartnerInformationTableHeaders} />
              <TableBody numOfCol={selectedPartnerInformationTableHeaders.length}>
                {renderSelectedPartnerInformationTable()}
              </TableBody>
            </TableBorder>
          </FormContents>
        </FormBorder>
        <div className="flex-end mt-4">
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={onClickCancel}
            size={ButtonSizeEnum.LG}
          >
            {t('text:Cancel')}
          </Button>
          <Button
            className="ms-3"
            disabled={isRegisterButtonDisabled()}
            onClick={handleSubmit(onClickRegister)}
            size={ButtonSizeEnum.LG}
          >
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default FinancierDealerReportRegister;
