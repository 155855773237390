import { ATTACHMENT_TYPE, stringToEnum } from 'enums';

export interface InvoiceAttachmentVO {
  invoiceAttachmentId: number;
  description: string;
  attachmentId: number;
  fileName: string;
  path: string;
  fileSize: number;
  fileContentType: string;
  attachmentType: string;
  createdDateTime: string;
}

export interface InvoiceAttachmentVOModel extends InvoiceAttachmentVO {
  attachmentType: ATTACHMENT_TYPE;
}

export function formattingToInvoiceAttachmentVOModel(data: InvoiceAttachmentVO): InvoiceAttachmentVOModel {
  return {
    ...data,
    attachmentType: stringToEnum(ATTACHMENT_TYPE, data.attachmentType),
  };
}
