import type Pageable from 'models/Pageable';
import type { FinancierCalenderVO, FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import { formattingToFinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FinancierCalenderListRequest,
  FinancierInsertHolidayDayOfWeekRequest,
  FinancierInsertHolidaySpecificDatesRequest,
} from './request';

// 비영업일 목록
// GET ::: /api/v1/fi/financier-calendar
export async function requestFinancierCalendarList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierCalenderListRequest,
): Promise<Pageable<FinancierCalenderVOModel[]>> {
  const response = await http.get<Pageable<FinancierCalenderVO[]>>({
    url: API_FI.FINANCIER_CALENDAR.FINANCIER_CALENDAR_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  const financierCalendarList: FinancierCalenderVOModel[] = response.content.map((data: FinancierCalenderVO) =>
    formattingToFinancierCalenderVOModel(data),
  );

  const financierCalendarPage: Pageable<FinancierCalenderVOModel[]> = {
    ...response,
    content: financierCalendarList,
  };

  return financierCalendarPage;
}

// ** 전문 : 비영업일 등록
// POST ::: /api/v1/fi/financier-calendar/insert:holiday-by-financier-interface
export async function requestInsertHolidayByInterface(fromDate: string, toDate: string) {
  const response = await http.post({
    url: API_FI.FINANCIER_CALENDAR.INSERT_HOLIDAY_BY_INTERFACE,
    data: { fromDate, toDate },
  });

  return response;
}

// 비영업일 등록 - 특정 요일 비영업일 일괄 등록
// POST ::: /api/v1/fi/financier-calendar/insert:holiday-by-day-of-week
export async function requestInsertHolidayByDayOfWeek(data: FinancierInsertHolidayDayOfWeekRequest) {
  const response = await http.post({
    url: API_FI.FINANCIER_CALENDAR.INSERT_HOLIDAY_BY_DAY_OF_WEEK,
    data,
  });

  return response;
}

// 비영업일 - 다건 등록
// POST ::: /api/v1/fi/financier-calendar/insert:holiday-by-specific-dates
export async function requestInsertHolidayBySpecificDates(data: FinancierInsertHolidaySpecificDatesRequest) {
  const response = await http.post({
    url: API_FI.FINANCIER_CALENDAR.INSERT_HOLIDAY_BY_SPECIFIC_DATES,
    data,
  });

  return response;
}

// 비영업일 단건 조회
// GET ::: /api/v1/fi/financier-calendar/{financier-calendar-id}
export async function requestFinancierHolidayDetail(financierCalendarId: number): Promise<FinancierCalenderVOModel> {
  const response = await http.get<FinancierCalenderVO>({
    url: API_FI.FINANCIER_CALENDAR.FINANCIER_CALENDAR_DETAIL(financierCalendarId),
  });

  return formattingToFinancierCalenderVOModel(response);
}

// 비영업일 수정
// PUT ::: /api/v1/fi/financier-calendar/{financier-calendar-id}
export async function requestFinancierHolidayEdit(
  financierCalendarId: number,
  description: string,
): Promise<FinancierCalenderVOModel> {
  const response = await http.put<FinancierCalenderVO>({
    url: API_FI.FINANCIER_CALENDAR.FINANCIER_CALENDAR_DETAIL(financierCalendarId),
    data: { description },
  });

  return formattingToFinancierCalenderVOModel(response);
}

// 비영업일 삭제
// DELETE ::: /api/v1/fi/financier-calendar/{financier-calendar-id}
export async function requestFinancierDeleteHoliday(financierCalendarId: number) {
  const response = await http.delete({
    url: API_FI.FINANCIER_CALENDAR.FINANCIER_CALENDAR_DETAIL(financierCalendarId),
  });

  return response;
}
