import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import type { WaitingAnchorPartner } from 'utils/http/api/anchor/waiting-anchor-partners/requests';

type AnchorPartnerRegistrationConfirmModalPropsType = {
  anchorPartners: WaitingAnchorPartner[];
};

function AnchorPartnerRegistrationConfirmModal({
  anchorPartners,
}: AnchorPartnerRegistrationConfirmModalPropsType): JSX.Element {
  const { t } = useTranslation(['format']);
  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Supplier_Name'),
    },
    {
      headerText: t('text:Tax_Code'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Bank_Name'),
    },
    {
      headerText: t('text:Branch_Name'),
    },
    {
      headerText: t('text:Bank_Code'),
    },
    {
      headerText: t('text:Bank_Account_Number'),
    },
    {
      headerText: t('text:Bank_Account_Holder'),
    },
  ];

  return (
    <>
      <h6>
        {t('text:Would_you_like_to_register_the_Suppliers_you_entered_below?')}
        <br />
        {t('text:Please_check_the_information_you_entered_below')}
      </h6>
      <br />
      <ul className="invoice-registration-sum">
        <li>
          • {t('text:Total_Number_of_Registration_Requested')} :{' '}
          <strong>{t('format:number', { value: anchorPartners.length })}</strong>
        </li>
      </ul>
      <br />
      <TableBorder>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>
          {anchorPartners.map((item, index) => (
            <Tr key={index}>
              <Td data={item.name} />
              <Td data={item.partnerTaxCode} />
              <Td data={item.representativeName} />
              <Td data={item.representativeEmail} />
              <Td data={item.telephone} />
              <Td data={item.address} />
              <Td data={item.requestedAccountBankName} />
              <Td data={item.requestedAccountBranchName} />
              <Td data={item.bankCode} />
              <Td data={item.account} />
              <Td data={item.accountOwner} />
            </Tr>
          ))}
        </TableBody>
      </TableBorder>
    </>
  );
}

export default AnchorPartnerRegistrationConfirmModal;
