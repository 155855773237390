import { isNil } from 'lodash-es';

import {
  AUTHORITY_TYPE,
  CURRENCY_TYPE,
  FACTORING_NOTICE_CONFIRMATION_STATUS,
  LOAN_STATUS,
  MULTIPLE_LOAN_REQUEST_STATUS,
  stringToEnum,
} from 'enums';

export interface FactoringNoticeDetailVO {
  factoringNoticeId: number;
  sentDateTime: string;
  partnerName: string;
  anchorName: string;
  confirmedUserName: string;
  confirmedUserAuthority: string;
  currencyType: string;
  loanId: number;
  requestedAmount: number;
  multipleLoanRequestId: number;
  totalRequestedAmount: number;
  agreementAttachmentId: number;
  agreementAttachmentName: string;
  agreementAttachmentPath: string;
  desiredDisburseDate: string;
  loanStatus: string;
  arNumber: string;
  repaymentDate: string;
  multipleLoanRequestStatus: string;
  totalArCount: number;
}

export interface FactoringNoticeDetailVOModel extends FactoringNoticeDetailVO {
  confirmedUserAuthority: AUTHORITY_TYPE;
  currencyType: CURRENCY_TYPE;
  loanStatus: LOAN_STATUS;
  multipleLoanRequestStatus: MULTIPLE_LOAN_REQUEST_STATUS;
  confirmationStatus: FACTORING_NOTICE_CONFIRMATION_STATUS;
}

export function formattingToFactoringNoticeDetailVOModel(data: FactoringNoticeDetailVO): FactoringNoticeDetailVOModel {
  return {
    ...data,
    confirmedUserAuthority: stringToEnum(AUTHORITY_TYPE, data.confirmedUserAuthority),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
    multipleLoanRequestStatus: stringToEnum(MULTIPLE_LOAN_REQUEST_STATUS, data.multipleLoanRequestStatus),
    confirmationStatus: isNil(data.confirmedUserName)
      ? FACTORING_NOTICE_CONFIRMATION_STATUS.WAITING
      : FACTORING_NOTICE_CONFIRMATION_STATUS.CONFIRMED,
  };
}
