// financier 지점별 유저 목록 조회
import type Pageable from 'models/Pageable';
import type { UserVO, UserVOModel } from 'models/vo/UserVO';
import { formattingToUserVOModel } from 'models/vo/UserVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierEnterpriseUserListRequest } from './request';

export async function requestFinancierUserList(
  pageNumber: number,
  rowCount: number,
  searchValue: FinancierEnterpriseUserListRequest,
): Promise<Pageable<UserVOModel[]>> {
  const response = await http.get<Pageable<UserVO[]>>({
    url: API_FI.ENTERPRISES.USER_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const financierEnterpriseUserList: UserVOModel[] = response.content.map((data: UserVO) =>
    formattingToUserVOModel(data),
  );
  const financierEnterpriseUserPage: Pageable<UserVOModel[]> = {
    ...response,
    content: financierEnterpriseUserList,
  };

  return financierEnterpriseUserPage;
}
