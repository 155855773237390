import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useThemeSetterController } from './useThemeSetterController';
import HeaderBarDropDown from '../../HeaderBarDropDown';

const ThemeSetter = () => {
  const { defaultTheme, THEME_OPTIONS } = useThemeSetterController();

  return (
    <div className="profile__dropdown__wrapper">
      <HeaderBarDropDown value={defaultTheme}>
        <HeaderBarDropDown.Trigger className="profile__dropdown__trigger">
          <FontAwesomeIcon icon={faAngleDown} fontSize={13.5} />
        </HeaderBarDropDown.Trigger>
        <HeaderBarDropDown.Items textAlign="end" items={THEME_OPTIONS} />
      </HeaderBarDropDown>
    </div>
  );
};

export default ThemeSetter;
