import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import {
  convertToAnchorAgreementResetData,
  convertToInterfaceAnchorAgreementData,
} from 'models/convertor/anchorAgreement';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import { isNilOrEmptyString } from 'utils/helpers';
import { requestFinancierAnchorAgreementIdRegisteredInterface } from 'utils/http/api/financier/anchor-agreements';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import SearchInterfaceByContactNoModal from '../../../modals/SearchInterfaceByContactNoModal';
import SearchInterfaceByIdModal from '../../../modals/SearchInterfaceByIdModal';
import SearchInterfaceByKeyUnionModal from '../../../modals/SearchInterfaceByKeyUnionModal';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorClientInfoViewModel from '../../../models/agreement/useAnchorClientInfoViewModel';
import useDivisionsViewModel from '../../../models/divisions/useDivisionsViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useWaitingDetailLoadAgreementController = () => {
  const { t } = useTranslation(['format']);
  const { show: showModal, id: modalId } = useModal();

  const { isFirstRegisteredWaitingAgreement } = useAgreementViewModel();

  const { supportedCollateralType, useAgreementInterfaceByKeyUnion, useAnchorUserInterface, anchorAgreementId } =
    useExtraInformationViewModel();

  const { updateDivisionName, fetchDivisionSelectOptions } = useDivisionsViewModel();

  const { agreement, updateAllAgreement, updateIsSearchedAgreement } = useAgreementViewModel();

  const { anchorClientInfo } = useAnchorClientInfoViewModel();

  const interfaceDataRef = useRef<AnchorAgreementDetailVOModel>({} as AnchorAgreementDetailVOModel);

  const {
    methods: { reset },
  } = useFormContext();

  const setSearchedInterfaceData = useCallback(searchedData => {
    interfaceDataRef.current = searchedData;
  }, []);

  // TODO ::  updateDivisionName - agreement 와 통합 시 수정
  const updateDivisionHandler = async ({
    anchorClientId,
    interfaceDivision,
    interfaceDivisionName,
  }: {
    anchorClientId?: number;
    interfaceDivision?: string;
    interfaceDivisionName?: string;
  }) => {
    const selectableDivision = !isNil(anchorClientId) && isNilOrEmptyString(interfaceDivision);

    if (selectableDivision) {
      await fetchDivisionSelectOptions(anchorClientId);
      updateDivisionName('');
    } else {
      updateDivisionName(interfaceDivisionName ?? '');
    }
  };

  const handleApplyClick = async () => {
    const convertedInterfaceAgreement = convertToInterfaceAnchorAgreementData({
      prevAnchorClientData: anchorClientInfo,
      interfaceData: interfaceDataRef.current,
      useAnchorUserInterface,
    });

    if (convertedInterfaceAgreement) {
      await updateDivisionHandler({
        anchorClientId: convertedInterfaceAgreement.anchorClientId,
        interfaceDivision: convertedInterfaceAgreement.division,
        interfaceDivisionName: convertedInterfaceAgreement.divisionName,
      });

      updateIsSearchedAgreement(true);
      updateAllAgreement({
        ...agreement,
        ...convertedInterfaceAgreement,
        anchorAgreementId,
      });
      reset(convertToAnchorAgreementResetData(convertedInterfaceAgreement));
    }
  };

  const searchInterfaceAnchorAgreementModalOptions: ModalOptions = {
    modalType: ModalType.CONFIRM,
    modalSize: ModalSize.XL,
    title: isFirstRegisteredWaitingAgreement ? t('text:Search_Anchor_Agreement') : t('text:Update_Agreement'),
    confirmBtnText: t('text:Apply'),
    closeBtnText: t('text:Close'),
    confirmBtnCb: handleApplyClick,
  };

  const showSearchInterfaceByIdModal = async () => {
    try {
      showLoadingUI();
      const interfaceData = await requestFinancierAnchorAgreementIdRegisteredInterface(anchorAgreementId);
      setSearchedInterfaceData(interfaceData);

      showModal(
        <SearchInterfaceByIdModal searchedInterfaceData={interfaceData} />,
        searchInterfaceAnchorAgreementModalOptions,
      );
    } catch (e) {
      showModal(e);
    } finally {
      unShowLoadingUI();
    }
  };

  const showSearchInterfaceByKeyUnionModal = () => {
    showModal(
      <SearchInterfaceByKeyUnionModal
        modalId={modalId}
        collateralType={supportedCollateralType}
        getInterfaceData={setSearchedInterfaceData}
      />,
      searchInterfaceAnchorAgreementModalOptions,
    );
  };

  const showSearchInterfaceByContactNoModal = () => {
    showModal(
      <SearchInterfaceByContactNoModal
        modalId={modalId}
        collateralType={supportedCollateralType}
        getInterfaceData={setSearchedInterfaceData}
      />,
      searchInterfaceAnchorAgreementModalOptions,
    );
  };

  const handleLoadAgreementButtonClick = () => {
    if (isFirstRegisteredWaitingAgreement) {
      useAgreementInterfaceByKeyUnion ? showSearchInterfaceByKeyUnionModal() : showSearchInterfaceByContactNoModal();
    } else {
      useAgreementInterfaceByKeyUnion ? showSearchInterfaceByIdModal() : showSearchInterfaceByContactNoModal();
    }
  };

  return { handleLoadAgreementButtonClick };
};

export default useWaitingDetailLoadAgreementController;
