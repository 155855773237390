import { useTranslation } from 'react-i18next';

import Form, { SizeType } from 'components/stateless/Form';
import { Border, Cell, Content, Row, SubTitle } from 'components/templates/section';

import useARRegistrationController from './useARRegistrationController';

const ARRegistration = () => {
  const { t } = useTranslation(['format']);

  const { currencyType, collateralIssuedLimitCheck, handleCollateralIssuedLimitCheckChange } =
    useARRegistrationController();

  return (
    <>
      <SubTitle>{t('text:AR_REGISTRATION')}</SubTitle>
      <Content>
        <Row>
          <Form.Control name="collateralIssuedLimitCheck" required>
            <Cell>
              <Form.Label position="top">{t('text:Limit_of_AR_Issuance')}</Form.Label>
              <Form.Description>
                {t('text:Anchor_can_register_AR_on_the_platform_up_to_the_maximum_amount')}
              </Form.Description>
            </Cell>
            <Form.Radio onChange={handleCollateralIssuedLimitCheckChange}>
              <Form.Option
                value="true"
                label={t('text:Applied')}
                tooltipOptions={{
                  id: 'collateralIssuedLimitCheck-true',
                  content: t('text:The_limit_will_be_recovered_when_the_AR_settlement_is_completed'),
                }}
                render={(isParentReadOnly, isParentRadioUnChecked) => (
                  <Border>
                    <Content isInnerStyle>
                      <Row>
                        <Form.Control required name="collateralIssuedLimitAmount">
                          <Form.Label>{t('text:Issuance_Limit_Amount')}</Form.Label>
                          <Form.NumberInput
                            fieldSize={SizeType.MD}
                            currencyType={currencyType}
                            leftUnit={currencyType}
                            numberType="bigNumber"
                            textAlign="text-right"
                            parentReadOnly={isParentReadOnly}
                            readOnly={isParentRadioUnChecked}
                            showError={collateralIssuedLimitCheck === 'true'}
                          />
                        </Form.Control>
                      </Row>
                    </Content>
                  </Border>
                )}
              />
              <Form.Option value="false" label={t('text:Not_Applied')} />
            </Form.Radio>
          </Form.Control>
        </Row>
        <Row>
          <Form.Control name="settlementDateOverAllowable">
            <Cell>
              <Form.Label position="top">{t('text:AR_Settlement_Date')}</Form.Label>
              <Form.Description>
                {t(
                  'text:Select_whether_AR_that_mature_after_the_agreement_expiration_date_can_be_registered_on_the_platform',
                )}
              </Form.Description>
            </Cell>
            <Form.Radio defaultValue="false">
              <Form.Option value="true" label={t('text:Can_exceed_agreement_expiration_date')} />
              <Form.Option
                value="false"
                label={`${t('text:Must_be_before_agreement_expiration')} (${t('text:Default')})`}
              />
            </Form.Radio>
          </Form.Control>
        </Row>
      </Content>
    </>
  );
};

export default ARRegistration;
