﻿// Phần mềm BkavCA Signer Plugin mở rộng tính năng cho trình duyệt: Chrome, Firefox, IE, ... gọi tắt là plugin
import { BkavCAPlugin, ObjFilter } from './BkavCAPlugin';
import { base64 } from './base64';
import { X2JS } from './xml2json';
import { convertVietnamDateStringToDateObj } from '../utils/date';

// var iCheck = checkBrowser();
var jsonCert;
var signerName;

let Utils = {
  /************************************************************************/
  /* FileBrowser:                                                         */
  /* Đầu vào: các định dạng file được quy định trong enum OPEN_FILE_FILTER*/
  /* Mã trả về:                                                           */
  /*      Thành công:                                                     */
  /*              - Chrome: Trả về đường dẫn file được mã hóa base64      */
  /*              - Firefox, IE: Trả về đường dẫn file không mã hóa base64*/
  /*      Lỗi: trả về rống                                                */
  /************************************************************************/
  FileBrowser: function (filter, jsCallback) {
    try {
      BkavCAPlugin.FileBrowser(filter, jsCallback); // Plugin sẽ tự gọi lại hàm GetCertResult để trả kết quả
    } catch (e) {
      alert(e);
    }
  },

  /************************************************************************/
  /* GetCertListByFilter: version plugin >1.0.10                          */
  /*  Mã trả về:                                                          */
  /*      Thành công:                                                     */
  /*              - Chrome: Trả về chuối xml được mã hóa base64           */
  /*              - Firefox, IE: Trả về chuối xml không mã hóa base64     */
  /*      Lỗi: trả về rống                                                */
  /************************************************************************/
  // JS callback
  selectChange: function () {
    var val = document.querySelector('#selectCert option:checked').value;
    var count = 0;
    signerName = val;

    for (var key in jsonCert) if (jsonCert.hasOwnProperty(key)) count++;
    for (var i = 0; i < count; i++) {
      if (val == jsonCert[i].SubjectDN.CN) {
        document.getElementById('selectCertIssuer').value = 'Nhà cung cấp: ' + jsonCert[i].IssuerDN.CN;
        document.getElementById('selectCertIssuer').innerHTML = 'Nhà cung cấp: ' + jsonCert[i].IssuerDN.CN;
        document.getElementById('selectCertSerial').value = 'Số serial: ' + jsonCert[i].SerialNumber;
        document.getElementById('selectCertSerial').innerHTML = 'Số serial: ' + jsonCert[i].SerialNumber;
        document.getElementById('selectCertExpire').value = 'Ngày hết hạn: ' + jsonCert[i].TimeValidTo;
        document.getElementById('selectCertExpire').innerHTML = 'Ngày hết hạn: ' + jsonCert[i].TimeValidTo;
      }
    }
  },

  GetCertResult: function (data) {
    var baseDecode = data;
    if (baseDecode == 'MA==') {
      alert('The License does not include Utils Module');
      return;
    }

    baseDecode = base64.decode(data);

    var jsonObj = X2JS.xml_str2json(baseDecode);

    var count = 0;
    jsonCert = jsonObj.Certificates.Certificate;
    for (var key in jsonCert)
      if (isNaN(key)) {
        if (key == 'SerialNumber') count++;
      } else {
        if (jsonCert.hasOwnProperty(key)) count++;
      }
    if (count > 0) {
      var elmnt = document.getElementById('selectCert');
      var numOption = elmnt.options.length;
      var iRemove;
      for (iRemove = elmnt.options.length - 1; iRemove > 0; iRemove--) {
        elmnt.remove(iRemove);
      }
      if (count == 1) {
        var option = document.createElement('option');
        option.text = jsonCert.SubjectDN.CN + ` (Ngày hết hạn: ${jsonCert.TimeValidTo})`;
        option.value = jsonCert.SubjectDN.CN;
        option.disabled = convertVietnamDateStringToDateObj(jsonCert.TimeValidTo) <= new Date();
        elmnt.add(option);
      } else {
        for (var i = 0; i < count; i++) {
          var option = document.createElement('option');
          option.text = jsonCert[i].SubjectDN.CN + ` (Ngày hết hạn: ${jsonCert[i].TimeValidTo})`;
          option.value = jsonCert[i].SubjectDN.CN;
          option.disabled = convertVietnamDateStringToDateObj(jsonCert[i].TimeValidTo) <= new Date();

          elmnt.add(option);
        }
        //  console.log(jsonCert.SubjectDN.S);
      }
    }
  },

  // document.addEventListener(EXTENSION_EVENT_NAME.GET_CERTLIST_BY_FILTER, function (data) {
  //     var result = document.getElementById('hrSignedData').value;
  //     console.log(result);
  //     //alert("No callback");
  //     var baseDecode = data;
  //     if (iCheck == 1) {
  //         baseDecode = base64.decode(data);
  //     }
  //     var x2js = new X2JS();
  //     var jsonObj = x2js.xml_str2json(baseDecode);
  //     var count = 0;
  //     jsonCert = jsonObj.Certificates.Certificate;
  //     for (var key in jsonCert)
  //         if (jsonCert.hasOwnProperty(key))
  //             count++;
  //     if (count > 0) {
  //         var elmnt = document.getElementById('selectCert');
  //         var numOption = elmnt.options.length
  //         var iRemove;
  //         for (iRemove = elmnt.options.length - 1 ; iRemove >= 0 ; iRemove--) {
  //             elmnt.remove(iRemove);
  //         }
  //         for (var i = 0; i < count; i++) {
  //             var option = document.createElement("option");
  //             option.text = jsonCert[i].SubjectDN.CN;
  //             elmnt.add(option);
  //         }
  //         document.getElementById('selectCertIssuer').value = "Nhà cung cấp: " + jsonCert[0].IssuerDN.CN;
  //         document.getElementById('selectCertIssuer').innerHTML = "Nhà cung cấp: " + jsonCert[0].IssuerDN.CN;
  //         document.getElementById('selectCertSerial').value = "Số serial: " + jsonCert[0].SerialNumber;
  //         document.getElementById('selectCertSerial').innerHTML = "Số serial: " + jsonCert[0].SerialNumber;
  //         document.getElementById('selectCertExpire').value = "Ngày hết hạn: " + jsonCert[0].TimeValidTo;
  //         document.getElementById('selectCertExpire').innerHTML = "Ngày hết hạn: " + jsonCert[0].TimeValidTo;
  //     }
  // });

  GetCertListByFilter: function (licenseKey, jsCallback) {
    try {
      var objFilterCert = new ObjFilter(); // Object filter
      /* var INFO_CERT_FILTER = {
        CERTIFICATE_SERIAL_NUMBER: 0,
        CERTIFICATE_SUBJECT_CN: 1,
        CERTIFICATE_ISSUER_CN: 2,
        CERTIFICATE_VALIDTO: 3,
        CERTIFICATE_VALIDFROM: 4,
        CERTIFICATE_TIMEVALID: 5,
      }; */
      //objFilterCert.Value = "2016/09/07 11:50:11";                                 // Giá trị lọc, mặc định là ""
      //objFilterCert.Filter = INFO_CERT_FILTER.CERTIFICATE_TIMEVALID; // Kiểu lọc: có các kiểu SerialNumber, SubjectCN, ....
      // Mặc định nếu không truyền vào thì sẽ lọc theo SerialNumber

      //objFilterCert.UsePKCS11 = false;                                // Sử dụng PKCS11 hoặc CSP để lấy thông tin Cert. Mặc định là dùng CSP
      // Nếu sử dụng PKCS11 thì chỉ lấy được thông tin cert có trong token,
      // không lấy đc cert do người dùng tự cài trong máy
      objFilterCert.isOnlyCertFromToken = false; // Set việc: Chỉ lấy thông tin của cert có trong token hay lấy tất cả
      // Việc set này Chỉ có tác dụng khi sử dụng CSP để lấy cert
      //objFilterCert.Filter = INFO_CERT_FILTER.CERTIFICATE_TIMEVALID;
      objFilterCert.FunctionCallback = jsCallback; // Set hàm JS để plugin gọi lại
      var dllName = 'BkavCA,BkavCAv2S'; // Danh sách dll name. cần phải set nếu sử dụng PKCS11 để lấy cert
      console.log(licenseKey);
      BkavCAPlugin.SetLicenseKey(licenseKey);
      // BkavCAPlugin.SetHashAlgorithm(HASH_ALGORITHM.SHA256);
      BkavCAPlugin.SetHashAlgorithm(0);
      //BkavCAPlugin.SetDLLName(dllName);                    // Set dll name nếu dùng PKCS11 để lấy cert. DÙng CSP thì k cần
      BkavCAPlugin.GetCertListByFilter(objFilterCert); // Có thể bắt event để lấy kết quả nếu k dùng JS Callback
      //BkavCAPlugin.SetAddBase64Cert(ADD_BASE64CERT.YES);
      // Danh sách các event của các hàm xem ở enum EXTENSION_EVENT_NAME trong file bkav-extension-signer.js
    } catch (e) {
      alert(e);
    }
  },

  /************************************************************************/
  /* GetAllCert: version plugin <=1.0.10                                  */
  /*  Mã trả về:                                                          */
  /*      Thành công:                                                     */
  /*              - Chrome: Trả về chuối xml được mã hóa base64           */
  /*              - Firefox, IE: Trả về chuối xml không mã hóa base64     */
  /*      Lỗi: trả về rống                                                */
  /************************************************************************/
  // bat sụ kiện
  //   document
  //   .addEventListener('GetAllCert', function (data) {
  //     var result = document.getElementById('hrSignedData').value;
  //     console.log(result);
  //     alert(result);
  //   });

  GetAllCert: function () {
    var allCert;
    var filter = 'SerialNumber'; // Có các filter: SerialNumber, IssuerCN, SubjectCN, ValidTo, ValidFrom
    var value = '';
    try {
      BkavCAPlugin.SetLicenseKey(licenseKey);
      // có 2 lựa chọn:
      //BkavCAPlugin.GetAllCert(filter, value);                // cần bắt event GetAllCert để lấy kết quả
      BkavCAPlugin.GetAllCert(filter, value, GetCertResult); // plugin sẽ gọi lại hàm GetCertResult để trả kết quả
    } catch (e) {
      alert(e);
    }
  },

  /************************************************************************/
  /*  Hàm  CheckToken                                                     */
  /*  Đầu vào: số serial của cert                                         */
  /*  Mã trả về:                                                          */
  /*      1: OK                                                           */
  /*     -1: Có lỗi trong quá trình kt                                    */
  /*      3: Token đang cắm không chứa cert có serial truyền vào          */
  /*      4: Không cắm token nào                                          */
  /*      5: Không tìm thầy dll PKCS11 (có thế do chưa truyền)            */

  /************************************************************************/
  CheckTokenCallback: function (data) {
    var iRet = parseInt(data);
    var dataRet = 'Lỗi không xác định';
    switch (iRet) {
      case 0:
        dataRet = 'Không có quyền sử dụng chức năng này';
        alert('Không có quyền sử dụng chức năng này');
        break;
      case 1:
        dataRet = 'Kiểm tra thành công';
        alert('Kiểm tra thành công');
        break;
      case -1:
        dataRet = 'Có lỗi trong quá trình kiểm tra';
        alert('Có lỗi trong quá trình kiểm tra');
        break;
      case 3:
        dataRet = 'Usb Token đang cắm không đúng';
        alert('Usb Token đang cắm không đúng');
        break;
      case 4:
        dataRet = 'Không tìm thấy Token';
        alert('Không tìm thấy Token');
        break;
      case 5:
        dataRet = 'Không tìm thấy PKCS#11 driver';
        alert('Không tìm thấy PKCS#11 driver');
        break;
      default:
        alert('Lỗi không xác định');
        break;
    }
    document.getElementById('checkTokenRet').value = dataRet;
    document.getElementById('checkTokenRet').innerHTML = dataRet;
  },

  // document.addEventListener(EXTENSION_EVENT_NAME.CHECK_TOKEN, function (data) {
  //   var result = document.getElementById('hrSignedData').value;
  //   console.log("No callback");
  //   var iRet = parseInt(result);
  //   var dataRet = "Lỗi không xác định";
  //   switch (iRet) {
  //     case 0:
  //       dataRet = "Không có quyền sử dụng chức năng này";
  //       alert("Không có quyền sử dụng chức năng này");
  //       break;
  //     case 1:
  //       dataRet = "Kiểm tra thành công: Token đã được cắm";
  //       alert("Kiểm tra thành công");
  //       break;
  //     case -1:
  //       dataRet = "Có lỗi trong quá trình kiểm tra";
  //       alert("Có lỗi trong quá trình kiểm tra");
  //       break;
  //     case 3:
  //       dataRet = "Usb Token đang cắm không đúng";
  //       alert("Usb Token đang cắm không đúng");
  //       break;
  //     case 4:
  //       dataRet = "Không tìm thấy Token";
  //       alert("Không tìm thấy Token");
  //       break;
  //     case 5:
  //       dataRet = "Không tìm thấy PKCS#11 driver";
  //       alert("Không tìm thấy PKCS#11 driver");
  //       break;
  //     default:
  //       alert("Lỗi không xác định");
  //       break;
  //   }
  //   document.getElementById("checkTokenRet").value = dataRet;
  //   document.getElementById("checkTokenRet").innerHTML = dataRet;
  // });

  CheckToken: function () {
    var iRet;
    var dllName = 'bkavcaetoken,bkavcsp';
    var serial = document.getElementById('checkTokenSerial').value;
    if (serial == '') {
      alert('Nhập serial number');
      return;
    }
    try {
      BkavCAPlugin.SetDLLName(dllName); // Nếu sử dụng PKCS11: thì PHẢI dùng hàm này để set danh sách
      // tên dll để tìm token tương ứng
      // Có 2 lựa chọn cho hàm check token
      BkavCAPlugin.CheckToken(serial, CheckTokenCallback); // Truyền vào serial và hàm JS callback: thì plugin sẽ tự gọi lại hàm JS để trả kết quả
    } catch (e) {
      alert(e);
    }
  },

  /************************************************************************/
  /*  Hàm  ReadFileToBase64: hỗ trợ version < 1.0.10                      */
  /*  Hàm  ReadFileToBase64 đổi tên thành ConvertFileToBase64             */
  /*  Đầu vào: là đường dẫn file                                          */
  /*  Mã trả về:                                                          */
  /*      Thành công: trả về chuỗi base64                                 */
  /*      Thất bại  : trả về rỗng                                         */

  /************************************************************************/
  ReadFileToB64: function () {
    var path = 'D:\\Cai win 17-6-2015\\Test_Plugin\\03001_DK.xml';
    try {
      var iCheck = checkBrowser();
      // check browser
      if (iCheck == 1) {
        BkavCAPlugin.ReadFileToBase64(path); // Bắt sự kiện để nhận lại kết quả
        BkavCAPlugin.ReadFileToBase64(path, TestCallback); // Plugin tự động gọi hàm TestCallback để trả kết quả
      } else {
        initPlugin(); // de su dung duoc plugin thi can goi ham initPlugin();
        // Có 2 lựa chọn:
        var result = BkavPluginSigner.ReadFileToBase64(path); // Nhận kết quả đồng bộ
        BkavPluginSigner.ReadFileToBase64(path, TestCallback); // Plugin tự động gọi lại hàm TestCallback để trả kết quả
      }
    } catch (e) {
      alert(e);
    }
  },

  GetVersion: function () {
    if (iCheck == 1) {
      BkavCAPlugin.GetVersion();
    } else alert(BkavPluginSigner.GetVersion());
  },

  GetCertIndex: function () {
    try {
      var serial = '5403298abdf42f20748915f96e65bfaa';
      BkavPlugin.GetCertIndex(serial);
    } catch (e) {
      alert(e);
    }
  },
};

export { Utils };
