import {
  requestSystemAccountAttachmentDownload,
  requestSystemPurposeAttachmentDownload,
} from 'utils/http/api/system/purpose-of-loans';
import useModal from 'utils/modal/useModal';

export function useSystemFinancingDetailPurposeOfFinancingListLogics() {
  const modal = useModal();

  const onClickDownloadAccountFile = async (e: any, purposeOfLoanId: number) => {
    e.preventDefault();
    try {
      await requestSystemAccountAttachmentDownload(purposeOfLoanId);
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickDownloadPurposeFile = async (e: any, purposeOfLoanId: number) => {
    e.preventDefault();
    try {
      await requestSystemPurposeAttachmentDownload(purposeOfLoanId);
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    onClickDownloadAccountFile,
    onClickDownloadPurposeFile,
  };
}
