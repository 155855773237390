import type Pageable from 'models/Pageable';
import type { LoanVO, LoanVOModel } from 'models/vo/LoanVO';
import { formattingToLoanVOModel } from 'models/vo/LoanVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';
import type { AnchorLoanListRequest } from 'utils/http/api/anchor/loans/requests';

export async function requestAnchorLoanList(
  pageNumber: number,
  rowCount: number,
  searchValue: AnchorLoanListRequest,
): Promise<Pageable<LoanVOModel[]>> {
  const response = await http.get<Pageable<LoanVO[]>>({
    url: API_AC.LOANS.LOAN_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const loanList: LoanVOModel[] = response.content.map((data: LoanVO) => formattingToLoanVOModel(data));
  const loanPage: Pageable<LoanVOModel[]> = { ...response, content: loanList };

  return loanPage;
}

export async function requestAnchorDownloadScannedAr(loanId: number) {
  await http.download(API_AC.LOANS.DOWNLOAD_SCANNED_AR(loanId));
}
