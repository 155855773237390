import type { FINANCIER_CLIENT_TYPE } from 'enums';
import type { UserVO, UserVOModel } from 'models/vo/UserVO';
import { formattingToUserVOModel } from 'models/vo/UserVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierUserRegisterRequest, FinancierUserUpdateRequest, InviteAuthorizerRequest } from './request';

// financier 사용자 상세 조회
export async function requestFinancierUserDetail(userId: number): Promise<UserVOModel> {
  const response = await http.get<UserVO>({
    url: API_FI.USERS.USER_DETAIL(userId),
  });

  return formattingToUserVOModel(response);
}

// financier 사용자 등록
export async function requestFinancierUserRegister(data: FinancierUserRegisterRequest): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_FI.USERS.USER,
    data,
  });

  return formattingToUserVOModel(response);
}

// financier 사용자 수정
export async function requestFinancierUserEdit(userId: number, data: FinancierUserUpdateRequest): Promise<UserVOModel> {
  const response = await http.put<UserVO>({
    url: API_FI.USERS.USER_DETAIL(userId),
    data,
  });

  return formattingToUserVOModel(response);
}

// Financier 직원 정보 전문 조회
export async function requestFinancierInterface(userCode: string): Promise<UserVOModel> {
  const response = await http.get<UserVO>({
    url: API_FI.USERS.USER_BY_FINANCIER_INTERFACE(userCode),
  });

  return formattingToUserVOModel(response);
}

// financier 사용자 이용중지
export async function requestFinancierSuspend(userId: number, suspended: boolean): Promise<UserVOModel> {
  const response = await http.post<UserVO>({
    url: API_FI.USERS.SUSPEND_FINANCIER_USER(userId),
    data: { suspended },
  });

  return formattingToUserVOModel(response);
}

// financier 사용자 초대 email 요청
export async function requestFinancierEmailSend(userId: number): Promise<void> {
  await http.post<UserVO>({
    url: API_FI.USERS.INVITE_FINANCIER_USER(userId),
  });

  return;
}

// Financier 고객 기업 Authorizer 초대 Email 요청
export async function requestFinancierClientEmailSend(
  financierClientType: FINANCIER_CLIENT_TYPE,
  agreementId: number,
): Promise<void> {
  await http.post({
    url: API_FI.USERS.INVITE_CLIENT_AUTHORIZER,
    data: {
      financierClientType,
      agreementId,
    },
  });

  // 아직 API 개발이 덜되었음으로 요청 url만 임시 생성. 추후 바뀌면 교체 필요.
  return;
}

export async function requestInviteAuthorizer(data: InviteAuthorizerRequest): Promise<void> {
  await http.post({
    url: API_FI.USERS.INVITE_DEALER_CLIENT_AUTHORIZER,
    data,
  });

  return;
}

export async function requestFinancierUserInviteUrl(userId: number): Promise<string | null> {
  const response = await http.get<string | null>({
    url: API_FI.USERS.INVITE_URL(userId),
  });

  return response;
}
