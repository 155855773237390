import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Select from 'components/stateless/Select/Select';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import useMounted from 'hooks/useMounted';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { requestAnchorUserNotRelatedAnchorAgreementList } from 'utils/http/api/anchor/users';
import useModal from 'utils/modal/useModal';
import { getPaymentSupportText } from 'utils/text';

interface AssignAgreementModalPropsType {
  userId: number;
  getCheckedData(data: number[]): void;
}

function AssignAgreementModal({ userId, getCheckedData }: AssignAgreementModalPropsType) {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { register, handleSubmit, getValues } = useForm<{ selectedKey: string; selectedValue: string }>({
    mode: 'onSubmit',
  });
  const [notRelatedAnchorAgreementList, setNotRelatedAnchorAgreementList] = useState<AnchorAgreementVOModel[]>();
  const [checkedRows, setCheckedRows] = useState<number[]>([]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    getCheckedData(checkedRows);
  }, [checkedRows]);

  const fetchAll = async () => {
    try {
      const fetchedNotRelatedAnchorAgreementList = await requestAnchorUserNotRelatedAnchorAgreementList(userId, {
        [getValues().selectedKey]: getValues().selectedValue,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setNotRelatedAnchorAgreementList(fetchedNotRelatedAnchorAgreementList);
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const handleCheckChange = (e: any, anchorAgreementId: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, anchorAgreementId]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== anchorAgreementId));
    }
  };

  const onClickSearch = async () => {
    try {
      await fetchAll();
    } catch (e) {
      modal.show(e);
    }
  };

  const renderAnchorAgreementTable = () => {
    const acAgreementListTableHeaders: HeaderType[] = [
      {
        headerText: '',
        colWidths: 45,
      },
      {
        headerText: t('text:Anchor_Master_Agreement_Number'),
        colWidths: 200,
      },
      {
        headerText: t('text:Program_Type'),
        colWidths: 120,
      },
      {
        headerText: t('text:Automatic_Settlement'),
        colWidths: 160,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 80,
      },
      {
        headerText: t('text:Responsible_Financier_Name'),
        colWidths: 180,
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
        colWidths: 180,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 110,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 110,
      },
    ];

    return (
      <TableBorder>
        <TableHeader header={acAgreementListTableHeaders} />
        <TableBody numOfCol={acAgreementListTableHeaders.length}>
          {notRelatedAnchorAgreementList?.map((item, index) => {
            return (
              <Tr key={index}>
                <td className="bg-sub100" key={index}>
                  <div className="text-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={e => handleCheckChange(e, item.anchorAgreementId)}
                    />
                  </div>
                </td>
                <Td data={item.contractNo} />
                <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
                <Td data={getPaymentSupportText(item.paymentSupport)} />
                <Td data={item.currencyType} />
                <Td data={item.financierName} />
                <Td data={item.branchName} />
                <Td data={item.startDate} format="date" />
                <Td data={item.expiryDate} format="date" />
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
    );
  };

  return (
    <>
      <GuideMessage
        message={[
          t('text:Below_is_the_list_of_Anchor_Master_Agreements'),
          t('text:Select_the_agreements_you_want_to_assign_to_the_user_and_click_on_the_Assign_button'),
        ]}
      />
      <div className="content-area mt-4">
        <SectionTitle title={t('text:Search_Agreement')} />
        <form>
          <div className="d-flex information-filter mb-3">
            <Select
              name="selectedKey"
              selectOptions={getSelectOptions<string>('ANCHOR_USER_NOT_RELATED_ANCHOR_AGREEMENT_LIST_REQUEST', [
                'contractNo',
                'financierName',
                'branchName',
              ])}
              className="information-form__select"
              ref={register}
            />
            <input type="text" name="selectedValue" ref={register} placeholder={t('text:Search…')} />
            <Button type="submit" size={ButtonSizeEnum.XS} onClick={handleSubmit(onClickSearch)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
        {renderAnchorAgreementTable()}
      </div>
    </>
  );
}

export default AssignAgreementModal;
