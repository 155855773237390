import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import useMounted from 'hooks/useMounted';
import { useSignInStore } from 'store';
import { HttpError } from 'utils/error/HttpError';
import { requestCurrentUser } from 'utils/http/api/common/users';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  getSessionDurationNeedReset,
  getSessionDurationTime,
  getSignIn,
  removeSignIn,
  setSessionDurationNeedReset,
} from 'utils/storage/LocalStorage';

const useSessionTimerButtonController = () => {
  const mounted = useMounted();
  const { t } = useTranslation(['format']);
  const { id: modalId, show: showModal, close: closeModal } = useModal();

  const [leftSessionExpireTime, setLeftSessionExpireTime] = useState<number>(0);
  const [sessionTimerActive, setSessionTimerActive] = useState<boolean>(false);

  const isModalShown = useRef(false);
  const expireTimeRef = useRef<number>();

  const { removeStoreSignIn, storeSignIn } = useSignInStore();

  const sessionExpireTimeFormatter = (seconds: number) => {
    if (seconds === 0) {
      return '00:00';
    }

    let m = Math.floor(seconds / 60).toString();
    let s = ((seconds % 60) % 60).toString();

    if (m.length === 1) m = `0${m}`;
    if (s.length === 1) s = `0${s}`;

    return `${m}:${s}`;
  };

  const handleRefreshButtonClick = async () => {
    try {
      const currentUserData = await requestCurrentUser();
      const sessionUserData = getSignIn();

      if (currentUserData.userId !== sessionUserData?.userId) {
        setSessionTimerActive(false);
        removeSignIn();
        removeStoreSignIn();

        const dummyErrorInstance = new HttpError('ATN-003', {
          code: 'ATN-003',
          data: null,
          message: 'access is denied',
        });

        showModal(dummyErrorInstance);
      }
    } catch (error) {
      showModal(error);
    }
  };

  useEffect(() => {
    if (mounted) {
      const sessionDurationTime = getSessionDurationTime();

      if (sessionDurationTime) {
        const expireTime = Date.now() + sessionDurationTime * 1000;

        ReactDOM.unstable_batchedUpdates(() => {
          setSessionTimerActive(true);
          expireTimeRef.current = expireTime;
          setLeftSessionExpireTime(sessionDurationTime);
        });
      }
    }
  }, [mounted]);

  useEffect(() => {
    if (sessionTimerActive) {
      const timerManager = setInterval(() => {
        const showSessionExpireNotificationModal = () => {
          showModal(
            <h6>
              {t('text:The_session_will_expire_in_1_minute')}
              <br />
              {t('text:Click_on_the_Confirm_button_to_extend_the_session')}
            </h6>,
            {
              title: t('text:Session_Expiration_Warning'),
              modalType: ModalType.CONFIRM,
              modalSize: ModalSize.NONE,
              closeBtnText: t('text:Cancel'),
              confirmBtnText: t('text:Confirm'),
              confirmBtnCb: async () => {
                try {
                  const currentUserData = await requestCurrentUser();
                  const sessionUserData = getSignIn();

                  if (currentUserData.userId !== sessionUserData?.userId) {
                    setSessionTimerActive(false);
                    removeSignIn();
                    removeStoreSignIn();
                    clearInterval(timerManager);

                    const dummyErrorInstance = new HttpError('ATN-003', {
                      code: 'ATN-003',
                      data: null,
                      message: 'access is denied',
                    });

                    showModal(dummyErrorInstance);
                  }
                } catch (error) {
                  showModal(error);
                }
              },
            },
          );
        };

        if (leftSessionExpireTime <= 60 && !isModalShown.current) {
          showSessionExpireNotificationModal();
          isModalShown.current = true;
        }

        if (leftSessionExpireTime === 2) {
          closeModal(modalId);
        }

        if (leftSessionExpireTime === 0) {
          setSessionTimerActive(false);
          removeSignIn();
          removeStoreSignIn();
          clearInterval(timerManager);

          const dummyErrorInstance = new HttpError('ATN-003', {
            code: 'ATN-003',
            data: null,
            message: 'access is denied',
          });

          showModal(dummyErrorInstance);
        }

        if (getSessionDurationNeedReset() === 'true') {
          const sessionDurationTime = getSessionDurationTime();

          if (sessionDurationTime) {
            const expireTime = Date.now() + sessionDurationTime * 1000;
            setSessionDurationNeedReset(false);
            isModalShown.current = false;

            ReactDOM.unstable_batchedUpdates(() => {
              expireTimeRef.current = expireTime;
              setLeftSessionExpireTime(sessionDurationTime);
            });
          }
        }

        if (expireTimeRef.current) {
          const now = Date.now();
          const leftSessionTime = Math.floor((expireTimeRef.current - now) / 1000);

          if (leftSessionExpireTime !== leftSessionTime && expireTimeRef.current >= now) {
            setLeftSessionExpireTime(leftSessionTime);
          }
        }
      }, 500);

      return () => {
        clearInterval(timerManager);
      };
    }
  }, [sessionTimerActive, leftSessionExpireTime, showModal, t, removeStoreSignIn, closeModal, modalId]);

  return {
    handleRefreshButtonClick,
    leftSessionExpireTime: sessionExpireTimeFormatter(leftSessionExpireTime),
    signInTime: storeSignIn?.signInTime,
  };
};

export default useSessionTimerButtonController;
