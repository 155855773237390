import { ATTACHMENT_TYPE } from 'enums';
import { requestDownloadInvoiceAttachment } from 'utils/http/api/dealer/invoice-attachments';
import { requestDealerMultipleRequestLoanDownloadAttachment } from 'utils/http/api/dealer/multiple-request-loans';
import useModal from 'utils/modal/useModal';

export function useDealerFinancingDetailFinancingRequestInformationController(multipleRequestLoansId: number) {
  const modal = useModal();

  const handleDownloadLoanAgreementClick = async () => {
    try {
      await requestDealerMultipleRequestLoanDownloadAttachment(multipleRequestLoansId, {
        attachmentType: ATTACHMENT_TYPE.LOAN_AGREEMENT,
      });
    } catch (e: any) {
      modal.show(e);
    }
  };

  const handleDownloadDebtSellingNotificationClick = async () => {
    try {
      await requestDealerMultipleRequestLoanDownloadAttachment(multipleRequestLoansId, {
        attachmentType: ATTACHMENT_TYPE.FACTORING_NOTIFICATION,
      });
    } catch (e: any) {
      modal.show(e);
    }
  };
  const handleDownloadScannedInvoiceFileClick = async (invoiceAttachmentId: number) => {
    try {
      await requestDownloadInvoiceAttachment(invoiceAttachmentId);
    } catch (e: any) {
      modal.show(e);
    }
  };

  return {
    handleDownloadLoanAgreementClick,
    handleDownloadDebtSellingNotificationClick,
    handleDownloadScannedInvoiceFileClick,
  };
}
