import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'components/stateless/Form';
import SearchFinancierClientModal from 'components/stateless/Modal/financier/SearchFinancierClientModal';
import { FINANCIER_CLIENT_TYPE } from 'enums';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import useAgreementViewModel from 'pages/financier/manage-partner/agreement/models/agreement/useAgreementViewModel';
import useExtraInformationViewModel from 'pages/financier/manage-partner/agreement/models/extraInformation/useExtraInformationViewModel';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

const useWaitingDetailPartnerCompanyController = () => {
  const { show: showModal, id: modalId } = useModal();
  const { t } = useTranslation();

  const { agreement, updateDealerAgreementInfo, isFirstRegisteredWaitingAgreement } = useAgreementViewModel();
  const { updateIsMatchedWithUploadedPartner, useAgreementInterface } = useExtraInformationViewModel();

  const {
    methods: { setValue, errors, clearErrors },
    isEditable,
  } = useFormContext();

  const isSearchPartnerButtonVisible = !useAgreementInterface && isEditable && isFirstRegisteredWaitingAgreement;

  const handleSearchPartnerButtonClick = () => {
    const setSelectedData = (data: FinancierClientVOModel) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setValue('dealerClientId', data.financierClientId);
        setValue('dealerClientCode', data.financierClientCode);

        updateDealerAgreementInfo(data);
        updateIsMatchedWithUploadedPartner(false);

        if (errors?.dealerClientId) clearErrors('dealerClientId');
      });
    };

    showModal(
      <SearchFinancierClientModal
        modalId={modalId}
        clientType={FINANCIER_CLIENT_TYPE.DEALER}
        getSelectedData={setSelectedData}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        title: t('text:Search_Partner'),
        closeBtnText: t('text:Close'),
      },
    );
  };

  return {
    agreement,
    isSearchPartnerButtonVisible,
    handleSearchPartnerButtonClick,
  };
};

export default useWaitingDetailPartnerCompanyController;
