import type Pageable from 'models/Pageable';
import type { DashBoardApprovalVO, DashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import { formattingToDashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

export const requestAnchorPartnerRequest = async (
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<DashBoardApprovalVOModel[]>> => {
  const response = await http.get<Pageable<DashBoardApprovalVO[]>>({
    url: API_AC.DASHBOARD.CLIENT_REQUEST,
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashBoardApprovalVOModel(item)),
  };
};

export const requestAnchorInternalApproval = async (
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<DashBoardApprovalVOModel[]>> => {
  const response = await http.get<Pageable<DashBoardApprovalVO[]>>({
    url: API_AC.DASHBOARD.INTERNAL_APPROVAL,
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDashBoardApprovalVOModel(item)),
  };
};
