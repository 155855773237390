import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Form from 'components/stateless/Form';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { Border } from 'components/templates/section';
import { COLLATERAL_TYPE } from 'enums';
import WithProvider from 'utils/render';

import useRegistrationDetailController from './useRegistrationDetailController';
import AgreementProvider from '../models/agreement/Provider';
import ExtraInformationProvider from '../models/extraInformation/Context';
import FinancierSettingProvider from '../models/financierSetting/Context';
import AgreementRevisionHistory from '../sections/common/AgreementRevisionHistory';
import DisbursementAccount from '../sections/common/DisbursementAccount';
import DiscountRate from '../sections/common/DiscountRate';
import Financier from '../sections/common/Financier';
import FinancingDuration from '../sections/common/FinancingDuration';
import FinancingList from '../sections/common/FinancingList';
import GeneralInformation from '../sections/common/GeneralInformation';
import LoadAgreement from '../sections/common/LoadAgreement';
import PartnerCompany from '../sections/common/PartnerCompany';
import PlatformPermissionSettings from '../sections/common/PlatformPermissionSettings';
import RepaymentAccountInterest from '../sections/common/RepaymentAccountInterest';
import RepaymentAccountPrincipal from '../sections/common/RepaymentAccountPrincipal';
import SecuredDepositAccount from '../sections/common/SecuredDepositAccount';
import DealerAssociatedAnchorMasterAgreement from '../sections/dealer/AssociatedAnchorMasterAgreement';
import DealerFinancingLimit from '../sections/dealer/FinancingLimit';
import DealerSummaryOfPartner from '../sections/dealer/SummaryOfPartner';
import VendorAssociatedAnchorMasterAgreement from '../sections/vendor/AssociatedAnchorMasterAgreement';
import VendorFinancingLimit from '../sections/vendor/FinancingLimit';
import SummaryOfAssociatedAnchor from '../sections/vendor/SummaryOfAssociatedAnchor';
import VendorSummaryOfPartner from '../sections/vendor/SummaryOfPartner';

const PartnerAgreementRegistrationDetail = () => {
  const { t } = useTranslation(['format']);

  const {
    GuideMessageText,
    isEditButtonVisible,
    isEditable,
    handleCancelButtonClick,
    handleRequestChangeButtonClick,
    handleEditButtonClick,
    supportedCollateralType,
    handleRevisionHistoryTabClick,
    handleAgreementTabClick,
    methods,
    useAgreementInterface,
    isAr,
    getReadOnlyValue,
    isRequestChangeButtonDisabled,
    isEditableStyle,
    isVendorFactoring,
  } = useRegistrationDetailController();

  return (
    <>
      <BackHeaderTitle
        title={t('text:Partner_Master_Agreement_Details')}
        right={
          isEditButtonVisible && (
            <>
              <div className="button-separator" />
              {isEditable ? (
                <>
                  <Button
                    size={ButtonSizeEnum.LG}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleCancelButtonClick}
                  >
                    {t('text:Cancel')}
                  </Button>
                  <Button
                    size={ButtonSizeEnum.LG}
                    onClick={handleRequestChangeButtonClick}
                    disabled={isRequestChangeButtonDisabled}
                  >
                    {t('text:Request_Change')}
                  </Button>
                </>
              ) : (
                <Button size={ButtonSizeEnum.LG} onClick={handleEditButtonClick}>
                  {t('text:Edit')}
                </Button>
              )}
            </>
          )
        }
      />
      {!isEmpty(GuideMessageText) && <GuideMessage message={GuideMessageText} messageType={MessageType.ALERT} />}

      <Tab
        tabList={[
          {
            tabName: t('text:Agreement'),
            onClickTab: handleAgreementTabClick,
          },
          {
            tabName: t('text:Revision_History'),
            onClickTab: handleRevisionHistoryTabClick,
          },
        ]}
        disabled={isEditable}
        tabViewList={[
          // Agreement
          <Fragment key="agreement">
            <Form
              methods={methods}
              isEditable={isEditable}
              isEditableStyle={isEditableStyle}
              getReadOnlyValue={getReadOnlyValue}
            >
              {useAgreementInterface && isEditable && (
                <div className="content-area">
                  <SectionTitle title={t('text:Load_Agreement_from_Financier_System')} />
                  <LoadAgreement />
                </div>
              )}

              <div className="content-area">
                <SectionTitle title={t('text:Contracting_Parties')} />
                <Border isEditable={isEditableStyle}>
                  <Financier />
                  <PartnerCompany />
                  {isAr ? <VendorAssociatedAnchorMasterAgreement /> : <DealerAssociatedAnchorMasterAgreement />}
                </Border>
              </div>
              <div className="content-area">
                <SectionTitle title={t('text:General_Information')} />
                <Border isEditable={isEditableStyle}>
                  <GeneralInformation />
                </Border>
              </div>
              <div className="content-area">
                <SectionTitle title={t('text:Financing_Terms_and_Conditions')} />
                <Border isEditable={isEditableStyle}>
                  {supportedCollateralType === COLLATERAL_TYPE.AR ? <VendorFinancingLimit /> : <DealerFinancingLimit />}
                  <DiscountRate isVendorFactoring={isVendorFactoring} />
                  <FinancingDuration isVendorFactoring={isVendorFactoring} />
                  <PlatformPermissionSettings isVendorFactoring={isVendorFactoring} />
                </Border>
              </div>
              <div className="content-area">
                <SectionTitle title={t('text:Designated Account')} />
                <Border isEditable={isEditableStyle}>
                  <DisbursementAccount isVendorFactoring={isVendorFactoring} />
                  <RepaymentAccountPrincipal />
                  {!isVendorFactoring && (
                    <>
                      <RepaymentAccountInterest />
                      <SecuredDepositAccount />
                    </>
                  )}
                </Border>
              </div>
            </Form>

            <div className="content-area">
              <SectionTitle title={t('text:Platform_Usage_History')} />
              {isAr && <SummaryOfAssociatedAnchor />}

              <div className="section-title">
                <h2 style={{ fontSize: '16px' }}>{t('text:Summary_of_Partner')}</h2>
              </div>
              {isAr ? <VendorSummaryOfPartner /> : <DealerSummaryOfPartner />}
            </div>

            <FinancingList />
          </Fragment>,
          // Revision History
          <AgreementRevisionHistory key="history" />,
        ]}
      />
    </>
  );
};

export default WithProvider({
  Component: PartnerAgreementRegistrationDetail,
  Provider: [ExtraInformationProvider, FinancierSettingProvider, AgreementProvider],
});
