import type { BulkImportErrorObjArr } from '../../error/bulkImportManager';

export const getSortedJsonArrayData = (arrayData: any[], errors: BulkImportErrorObjArr) => {
  return arrayData.sort((a, b) => {
    const aIndex = errors.findIndex((item, index) => item && index === a.rowIndex - 1);
    const bIndex = errors.findIndex((item, index) => item && index === b.rowIndex - 1);

    if (aIndex === -1 && bIndex === -1) {
      return 0;
    } else if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  });
};
