import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import InvoiceBulkInputTable from 'components/invoice/InvoiceBulkInputTable';
import PaymentInformationAccordion from 'components/stateless/Accordion/PaymentInformationAccordion';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import RadioButton from 'components/stateless/Button/RadioButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import InvoiceBulkRegisterFailureModal from 'components/stateless/Modal/common/invoice/InvoiceBulkRegisterFailureModal';
import InvoiceRegisterCancelModal from 'components/stateless/Modal/common/invoice/InvoiceRegisterCancelModal';
import ValidationErrorModal from 'components/stateless/Modal/common/invoice/ValidationErrorModal';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, DEALER_IDENTIFIER_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { FiscalYearSettingVOModel } from 'models/vo/FiscalYearSettingVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import type { BoeTableStructure } from 'utils/boe';
import calculateBOEInformation from 'utils/boe';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestAnchorAgreementDetail } from 'utils/http/api/anchor/anchor-agreements';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestAnchorFinancierCalendar } from 'utils/http/api/anchor/financier-calendar';
import { requestAnchorInvoiceBulkRegister } from 'utils/http/api/anchor/invoice-summaries';
import type {
  AnchorInvoiceBulkRegisterRequest,
  InvoiceAdditionalData,
  MultipleInvoice,
} from 'utils/http/api/anchor/invoice-summaries/requests';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestFiscalYearSettingCurrentUser } from 'utils/http/api/common/fiscal-year-setting';
import { initializeRefValue } from 'utils/initialize';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import {
  INVOICE_PAYMENT_TO_DATE_RANGE,
  getInitialSettlementDateFunction,
  setEnteredSettlementDate,
} from 'utils/logic/invoice';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';

dayjs.extend(customParseFormat);

interface AnchorInvoiceRegisterByAnchorStep2Param {
  anchorAgreementId: string;
  financierId: string;
}

const MAX_ROW_LENGTH = 500;

const useConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);

  const date = new Date();
  const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];
  const startDate = new Date(today[0], today[1], today[2]);
  const toDate = new Date(today[0], today[1], today[2] + INVOICE_PAYMENT_TO_DATE_RANGE);
  const nextDate = new Date(today[0], today[1], today[2] + 1);

  const NUMBER_OF_INVOICE_ADDITIONAL_COLUMNS = 10;

  return {
    mounted,
    modal,
    t,
    history,
    today,
    startDate,
    toDate,
    nextDate,
    NUMBER_OF_INVOICE_ADDITIONAL_COLUMNS,
  };
};

function AnchorInvoiceRegistrationStep2(): JSX.Element {
  const { anchorAgreementId, financierId } = useParams<AnchorInvoiceRegisterByAnchorStep2Param>();
  const [anchorAgreementDetailData, setAnchorAgreementDetailData] = useState<AnchorAgreementDetailVOModel>();
  const [financierCommonSettingData, setFinancierCommonSettingData] = useState<FinancierCommonSettingVOModel>();
  const [financierHoliday, setFinancierHoliday] = useState<Pageable<FinancierCalenderVOModel[]>>();
  const [fiscalYearSettingData, setFiscalYearSettingData] = useState<FiscalYearSettingVOModel | null>(null);
  const [holidayList, setHolidayList] = useState<string[]>();
  const [initialSettlementDate, setInitialSettlementDate] = useState<string>();
  const [isDirectInput, setIsDirectInput] = useState<boolean>(true);
  const [file, setFile] = useState<File>();
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState<BigNumber>('0');
  const [checkedValidation, setCheckedValidation] = useState<boolean[]>([]);
  const [boeTableData, setBoeTableData] = useState<BoeTableStructure[]>([]);
  const [rows, setRows] = useState<string[]>(['']);
  const [columns, setColumns] = useState<string[]>([]);
  const [minDate, setMinDate] = useState<Date>();
  const { mounted, modal, t, history, startDate, toDate, nextDate, NUMBER_OF_INVOICE_ADDITIONAL_COLUMNS } =
    useConstants();

  const fileRef = useRef<HTMLInputElement>(null);

  const invoiceBulkRegistrationUseForm = useForm<AnchorInvoiceBulkRegisterRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { getValues, reset, trigger } = invoiceBulkRegistrationUseForm;

  const boeTableHeaders: HeaderType[] = [
    {
      headerText:
        anchorAgreementDetailData?.dealerIdentifierType === DEALER_IDENTIFIER_TYPE.DEALER_CODE_BY_ANCHOR
          ? t('text:Partner_Code_of_Anchor')
          : anchorAgreementDetailData?.dealerIdentifierType === DEALER_IDENTIFIER_TYPE.TAX_CODE
          ? t('text:Partner_Tax_Code')
          : '',
      className: 'text-left',
    },
    {
      headerText: t('text:BOE_Number'),
      className: 'text-left',
    },
    {
      headerText: t('text:Payment_Date'),
      className: 'text-left',
    },
    {
      headerText: t('text:Payment_Amount'),
      className: 'text-left',
    },
  ];

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      let initialSettlementDate: string | undefined = undefined;

      const [anchorAgreementDetailData, financierCommonSettingResponse, fiscalYearSettingResponse] = await Promise.all([
        requestAnchorAgreementDetail(Number(anchorAgreementId)),
        requestFinancierSettingData(Number(financierId)),
        requestFiscalYearSettingCurrentUser(),
      ]);

      const fromDate = financierCommonSettingResponse?.settlementDateIsTodayInvoiceUploadable ? startDate : nextDate;

      const fetchFinancierHoliday = await requestAnchorFinancierCalendar(Number(financierId), {
        pageNumber: 0,
        rowCount: Number(getDayTerm(convertToServerDateFormat(fromDate), convertToServerDateFormat(toDate))) + 1,
        fromDate: convertToServerDateFormat(fromDate),
        toDate: convertToServerDateFormat(toDate),
        holiday: true,
      });

      const getHolidayArrays = fetchFinancierHoliday.content.map(item => item.solarDate);
      initialSettlementDate = getInitialSettlementDateFunction(
        getHolidayArrays,
        financierCommonSettingResponse,
        startDate,
        nextDate,
        toDate,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setHolidayList(getHolidayArrays);
        setAnchorAgreementDetailData(anchorAgreementDetailData);
        setInitialSettlementDate(initialSettlementDate);
        setFinancierCommonSettingData(financierCommonSettingResponse);
        setFinancierHoliday(fetchFinancierHoliday);
        setFiscalYearSettingData(fiscalYearSettingResponse);
        setMinDate(fromDate);

        reset({
          invoiceList: [
            {
              dealerIdentifierCode: undefined,
              billOfExchangeNo: undefined,
              invoiceNumber: undefined,
              invoiceIssuedDate: undefined,
              settlementDate: initialSettlementDate,
              invoiceAmount: undefined,
              referenceNumber: undefined,
            },
          ],
        });
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const sumInvoiceAmount = useCallback(() => {
    const data = getValues();
    const calculatorBigNumber = new CalculatorBigNumber();

    let sum: BigNumber = '0';

    if (data.invoiceList) {
      data.invoiceList.forEach(item => {
        if (item && item.invoiceAmount) {
          sum = calculatorBigNumber.add(sum).add(item.invoiceAmount).get();
        }
      });
    }

    setTotalInvoiceAmount(sum);
  }, [getValues]);

  useEffect(() => {
    sumInvoiceAmount();
  }, [rows, sumInvoiceAmount]);

  const onClickRegistrationMethodRadioButton = (value: boolean) => {
    if (value !== isDirectInput) {
      setIsDirectInput(value);
    }
  };

  const onClickCancel = (e: any) => {
    e.preventDefault();

    modal.show(<InvoiceRegisterCancelModal />, {
      modalSize: ModalSize.NONE,
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Close'),
      closeBtnCb: () => {},
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => history.push(ROUTES_AC.REGISTER_INVOICE.REGISTRATION_STEP1),
    });
  };

  const mappingAdditionalColumnNameAndValue = () => {
    const { additionalColumnNameList, invoiceList } = getValues();

    return invoiceList?.map(invoiceItem => ({
      ...invoiceItem,
      invoiceAdditionalDataList: invoiceItem?.invoiceAdditionalDataList?.map((item, index) => ({
        ...item,
        name: additionalColumnNameList?.[index].name ?? '',
      })),
    }));
  };

  const getRequestValues = () => {
    const { additionalColumnNameList } = getValues();

    const noInvoiceAdditionalDataList = (additionalColumnNameList?.length ?? 0) === 0;
    if (noInvoiceAdditionalDataList) return getValues();

    const mappedAdditionalData = mappingAdditionalColumnNameAndValue();

    return { ...getValues(), invoiceList: mappedAdditionalData };
  };

  const triggerValidationForAdditionalColumnName = async () => {
    if (columns.length === 0) return true;

    const additionalColumnFieldNameList = columns.map((_, index) => `additionalColumnNameList[${index}].name`);
    const validationAdditionalColumnName = await trigger(additionalColumnFieldNameList);

    if (!validationAdditionalColumnName) {
      modal.show(<h6>{t('text:Enter_the_names_for_all_of_the_added_columns')}</h6>);
    }

    return validationAdditionalColumnName;
  };

  const onClickDirectInput = async (e: any) => {
    e.preventDefault();

    const verificationCode: UserVerificationCodeRequest = {};

    const data = getRequestValues();

    const fetchInvoiceBulkRegist = async (): Promise<void> => {
      try {
        await requestAnchorInvoiceBulkRegister({
          anchorAgreementId: Number(anchorAgreementId),
          otpCode: verificationCode.otpCode,
          queryValue: verificationCode.queryValue,
          invoiceList: data.invoiceList,
        });

        modal.show(
          <>
            <h6>{t('text:The_invoice_registration_request_has_been_completed')}</h6>
            <h6>{t('text:You_can_check_the_registration_result_on_the_invoice_confirmation_menu')}</h6>
          </>,
          {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => history.push(ROUTES_AC.REGISTER_INVOICE.REGISTRATION_STEP1),
          },
        );
      } catch (error: any) {
        if (error.code === 'IMU-001') {
          modal.show(
            <InvoiceBulkRegisterFailureModal
              data={error.data}
              dealerIdentifierType={anchorAgreementDetailData?.dealerIdentifierType}
            />,
            {
              modalSize: ModalSize.XL,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:OK'),
            },
          );
        } else {
          modal.show(error);
        }
      }
    };

    const executeRegister = () => {
      const data = getValues();

      return new Promise<void>(resolve => {
        showLoadingUI();
        setTimeout(() => {
          resolve();
        }, 1000);
      }).then(async () => {
        if (!(await triggerValidationForAdditionalColumnName())) {
          unShowLoadingUI();

          return;
        }

        await trigger().then(async result => {
          setCheckedValidation(data.invoiceList.map(() => true));
          unShowLoadingUI();
          if (!result) {
            modal.show(<ValidationErrorModal />, {
              modalSize: ModalSize.NONE,
              modalType: ModalType.ALERT,
              closeBtnText: t('text:Close'),
            });

            return;
          } else if (data) {
            try {
              const clientAuthTypeData = await requestAnchorClientAuthByFinancierId(Number(financierId));

              if (
                (clientAuthTypeData.currentAuthorityType === AUTHORITY_TYPE.ADMIN ||
                  clientAuthTypeData.currentAuthorityType === AUTHORITY_TYPE.AUTHORIZER) &&
                clientAuthTypeData.otpType !== OTP_TYPE.NONE
              ) {
                modal.show(
                  <UserVerificationModal
                    modalId={modal.id}
                    verificationCode={verificationCode}
                    requestIdType="financierId"
                    requestId={Number(financierId)}
                    clientAuthSetting={clientAuthTypeData}
                  />,
                  {
                    modalSize: ModalSize.NONE,
                    modalType: ModalType.CONFIRM,
                    closeBtnText: t('text:Close'),
                    confirmBtnText: t('text:Confirm'),
                    confirmBtnCb: () => fetchInvoiceBulkRegist(),
                  },
                );
              } else {
                await fetchInvoiceBulkRegist();
              }
            } catch (error) {
              modal.show(error);
            }
          } else {
            return;
          }
        });
      });
    };
    await executeRegister();
  };

  const renderExcelUploadForm = (): JSX.Element => {
    const onChangeUploadExcel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const getInvoiceAdditionalDataList = (data: any, isEmptyColumnObj: { [index: number]: boolean }) => {
        const invoiceAdditionalDataList: InvoiceAdditionalData[] = [];

        for (let columnIndex = 0; columnIndex < NUMBER_OF_INVOICE_ADDITIONAL_COLUMNS; columnIndex++) {
          const invoiceAdditionalCellData = data[`${ExcelTemplatesHeader.INVOICE_DETAIL} (${columnIndex + 1})`];
          const isEmptyColumn = isEmptyColumnObj[columnIndex];

          if (!isEmptyColumn) {
            if (invoiceAdditionalCellData) {
              invoiceAdditionalDataList.push({ name: '', value: invoiceAdditionalCellData });
            } else {
              invoiceAdditionalDataList.push({ name: '', value: '' });
            }
          }
        }

        return invoiceAdditionalDataList;
      };

      const formattedData = (data: any, isEmptyColumnObj: { [index: number]: boolean }) => {
        const formattedSettlementDate = t('format:original-date', {
          value: String(data[ExcelTemplatesHeader.PAYMENT_DATE]),
          key: 'no-line-date',
        });
        const formattedIssuedDate = t('format:original-date', {
          value: String(data[ExcelTemplatesHeader.INVOICE_ISSUED_DATE]),
          key: 'no-line-date',
        });
        const invoiceAdditionalDataList = getInvoiceAdditionalDataList(data, isEmptyColumnObj);

        // TOOD :: Settlement Date must got holiday list and initial settlement date
        return {
          dealerIdentifierCode: data[ExcelTemplatesHeader.PARTNER_CODE],
          billOfExchangeNo: data[ExcelTemplatesHeader.BILL_OF_EXCHANGE_NUMBER],
          invoiceNumber: data[ExcelTemplatesHeader.INVOICE_NUMBER],
          referenceNumber: data[ExcelTemplatesHeader.INVOICE_REFERENCE_NUMBER],
          invoiceIssuedDate: dayjs(formattedIssuedDate, 'YYYY-MM-DD', true).isValid() ? formattedIssuedDate : '',
          settlementDate:
            setEnteredSettlementDate(
              holidayList,
              formattedSettlementDate,
              initialSettlementDate,
              financierCommonSettingData,
            ) ?? '',
          invoiceAmount: data[ExcelTemplatesHeader.INVOICE_AMOUNT],
          invoiceAdditionalDataList,
        };
      };

      if (e.target.files && e.target.files[0]) {
        const isCsvFile = e.target.files[0].type === 'text/csv';

        try {
          showLoadingUI();
          setCheckedValidation([]);
          setFile(e.target.files[0]);

          const parsedJsonArray = isCsvFile
            ? await csvToJson(e.target.files[0], MAX_ROW_LENGTH)
            : await excelToJson(e.target.files[0], MAX_ROW_LENGTH);

          const isEmptyColumn = (index: number) =>
            (isCsvFile ? parsedJsonArray : (parsedJsonArray[0] as []))?.every(
              (data: any) => !data[`${ExcelTemplatesHeader.INVOICE_DETAIL} (${index})`],
            );

          const isEmptyColumnObj: { [index: number]: boolean } = {
            0: isEmptyColumn(1),
            1: isEmptyColumn(2),
            2: isEmptyColumn(3),
            3: isEmptyColumn(4),
            4: isEmptyColumn(5),
            5: isEmptyColumn(6),
            6: isEmptyColumn(7),
            7: isEmptyColumn(8),
            8: isEmptyColumn(9),
            9: isEmptyColumn(10),
          };

          const emptyColumnsLength = Object.values(isEmptyColumnObj)?.filter(isEmpty => isEmpty).length;
          const numberOfColumnsToFill = NUMBER_OF_INVOICE_ADDITIONAL_COLUMNS - emptyColumnsLength;
          const additionalColumnNameList = getValues().additionalColumnNameList ?? [];

          const filledAdditionalColumnNameList: { name: string }[] = [];
          for (let i = 0; i < numberOfColumnsToFill; i++) {
            if (additionalColumnNameList[i]) {
              filledAdditionalColumnNameList.push(additionalColumnNameList[i]);
            } else {
              filledAdditionalColumnNameList.push({ name: '' });
            }
          }

          const resetInvoiceList = (formattedDataList: any) => {
            reset({
              invoiceList: formattedDataList,
              additionalColumnNameList: filledAdditionalColumnNameList,
            });
          };

          const setFormattedDataList = (parsedJsonArray: any) => {
            const formattedDataList = parsedJsonArray.map((data: any) => formattedData(data, isEmptyColumnObj));
            setColumns(formattedDataList?.[0]?.invoiceAdditionalDataList?.map(() => ''));
            setRows(Array(formattedDataList.length).fill(''));
            resetInvoiceList(formattedDataList);
            sumInvoiceAmount();
          };

          if (isCsvFile && parsedJsonArray && parsedJsonArray.length !== 0) {
            setFormattedDataList(parsedJsonArray);

            return;
          }

          if (
            !isCsvFile &&
            parsedJsonArray &&
            Array.isArray(parsedJsonArray) &&
            parsedJsonArray[0] &&
            Array.isArray(parsedJsonArray[0]) &&
            parsedJsonArray[0].length !== 0
          ) {
            setFormattedDataList(parsedJsonArray[0]);

            return;
          }

          modal.show(
            <h6>
              {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
              <br />
              {t('text:Please_check_and_upload_it_again')}
            </h6>,
          );
        } catch (error) {
          modal.show(error);
          initializeRefValue(fileRef);
        } finally {
          unShowLoadingUI();
        }
      }
    };

    const onClickRemoveExcel = (e: any): void => {
      e.preventDefault();
      initializeRefValue(fileRef);
      if (file) {
        setFile(undefined);
      }
    };

    return (
      <div className="excel-form mb-4">
        <div className="excel-download-form d-flex">
          <label className="me-3">
            {t(
              'text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it_After_uploading_you_can_check_the_uploaded_list_below',
            )}
          </label>
          <ExcelTemplateDownloadButton
            templateFileAddress={
              financierCommonSettingData?.invoiceWillBeSettledOnUploadDate
                ? '/templates/InvoiceRegistrationTemplate_withoutPaymentDate.xlsx'
                : '/templates/InvoiceRegistrationTemplate.xlsx'
            }
            style={{ marginRight: '4px' }}
          />
          <ExcelTemplateDownloadButton
            downloadAnnounceText={t('text:CSV_Template')}
            templateFileAddress={
              financierCommonSettingData?.invoiceWillBeSettledOnUploadDate
                ? '/templates/InvoiceRegistrationTemplate_withoutPaymentDate.csv'
                : '/templates/InvoiceRegistrationTemplate.csv'
            }
          />
        </div>
        <div className="detail-in-file-upload-form bg-sub100">
          <div className="d-flex justify-content-between">
            <input
              onChange={onChangeUploadExcel}
              type="file"
              name="file"
              id="FileUpload"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: 'none' }}
              ref={fileRef}
            />
            <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
              {t('text:Attach_File')}
            </label>
            <div id="fileName" className="upload-file-input">
              {file ? file.name : t('text:No_file_attached')}
            </div>
            <IconButton onClick={onClickRemoveExcel} className={`delete-uploaded-excel-button ${!file && 'd-none'}`}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  const refreshBoeTable = useCallback((): void => {
    const { invoiceList } = getValues();

    if (invoiceList && Array.isArray(invoiceList)) {
      const boeTableDatas = calculateBOEInformation<MultipleInvoice>(
        invoiceList,
        anchorAgreementDetailData?.billOfExchangeNoRequired,
      );

      setBoeTableData(boeTableDatas);
    }
  }, [anchorAgreementDetailData?.billOfExchangeNoRequired, getValues]);

  const renderBoeTable = (): JSX.Element[] | undefined => {
    return boeTableData.map((item, index) => (
      <Tr key={index}>
        <Td data={item.dealerIdentifierCode} />
        <Td data={item.billOfExchangeNo} />
        <Td data={item.settlementDate} />
        <Td data={item.invoiceAmount} format="number" />
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Invoice_Registration')} />
      <div className="content-area">
        <StepWizard nth={2} title={[t('text:Select_Anchor_Master_Agreement'), t('text:Enter_Invoice_Information')]} />
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:Anchor_Master_Agreement_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Financier_Name')} col={4} value={anchorAgreementDetailData?.financierName} />
              <FormValue
                label={t('text:Anchor_Master_Agreement_Number')}
                col={4}
                value={anchorAgreementDetailData?.contractNo}
              />
              <FormValue label={t('text:Currency')} col={4} value={anchorAgreementDetailData?.currencyType} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area" data-testid="content-area">
        <h3>{t('text:Select_invoice_registration_method')}</h3>
        <div className="invoice-radio-group">
          <div className="invoice-radio-group">
            <RadioButton
              id="direct-input"
              name="registration-method"
              checked={isDirectInput}
              onChange={() => onClickRegistrationMethodRadioButton(true)}
            >
              {t('text:Direct_Input')}
            </RadioButton>
            <RadioButton
              id="file-upload"
              name="registration-method"
              checked={!isDirectInput}
              onChange={() => onClickRegistrationMethodRadioButton(false)}
            >
              {t('text:File_Upload')}
            </RadioButton>
          </div>
          {!isDirectInput && renderExcelUploadForm()}
          <FormProvider {...invoiceBulkRegistrationUseForm}>
            <form>
              <InvoiceBulkInputTable
                columns={columns}
                setColumns={setColumns}
                rows={rows}
                setRows={setRows}
                checkedValidation={checkedValidation}
                setCheckedValidation={setCheckedValidation}
                totalInvoiceAmount={totalInvoiceAmount}
                setTotalInvoiceAmount={setTotalInvoiceAmount}
                initialSettlementDate={initialSettlementDate}
                minDate={minDate}
                file={file}
                financierCommonSettingData={financierCommonSettingData}
                anchorAgreementDetailData={anchorAgreementDetailData}
                fiscalYearSettingData={fiscalYearSettingData}
                financierHoliday={financierHoliday}
              />
            </form>
          </FormProvider>
        </div>
        <PaymentInformationAccordion id="payment-information" handleRefresh={refreshBoeTable}>
          <>
            <GuideMessage
              message={[
                anchorAgreementDetailData?.billOfExchangeNoRequired
                  ? t(
                      'text:Invoices_missing_the_Partner_Code_BOE_Number_Payment_Date_or_the_Invoice_Amount_will_not_be_reflected_in_the_payment_information_below',
                    )
                  : t(
                      'text:Invoices_missing_the_Partner_Code_Payment_Date_or_the_Invoice_Amount_will_not_be_reflected_in_the_payment_information_below',
                    ),
              ]}
              isImportContentArea={true}
            />
            <TableBorder>
              <TableHeader header={boeTableHeaders} />
              <TableBody numOfCol={boeTableHeaders.length}>{renderBoeTable()}</TableBody>
            </TableBorder>
          </>
        </PaymentInformationAccordion>
        <div className="flex-center-between mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancel}
            className="me-2"
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onClickDirectInput}>
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AnchorInvoiceRegistrationStep2;
