import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { DEALER_IDENTIFIER_TYPE } from 'enums';

interface InvoiceBulkRegisterErrorType {
  taxCode: string;
  code: string;
}

interface InvoiceBulkRegisterFailureModalPropsType {
  data: InvoiceBulkRegisterErrorType[];
  dealerIdentifierType?: DEALER_IDENTIFIER_TYPE;
}

const useConstants = () => {
  const { t } = useTranslation(['format']);
  const resultTableByTaxHeaders: HeaderType[] = [
    {
      headerText: t('text:Partner_Tax_Code'),
      className: 'pl-2',
      colWidths: 300,
    },
    {
      headerText: t('text:REASON_FOR_FAILURE'),
      className: 'pl-2',
    },
  ];
  const resultTableByCodeHeaders: HeaderType[] = [
    {
      headerText: t('text:Partner_Code_of_Anchor'),
      className: 'pl-2',
      colWidths: 300,
    },
    {
      headerText: t('text:REASON_FOR_FAILURE'),
      className: 'pl-2',
    },
  ];

  return {
    t,
    resultTableByTaxHeaders,
    resultTableByCodeHeaders,
  };
};

function InvoiceBulkRegisterFailureModal({
  data,
  dealerIdentifierType,
}: InvoiceBulkRegisterFailureModalPropsType): JSX.Element {
  const { t, resultTableByTaxHeaders, resultTableByCodeHeaders } = useConstants();

  const renderResultTable = (): JSX.Element[] | undefined => {
    return data.map((item, index) => (
      <Tr key={index}>
        <Td data={item.taxCode} />
        <Td className="text-red" data={`${t(`text:Fail__`)}${t(`exception:${item.code}`)}`} />
      </Tr>
    ));
  };

  return (
    <>
      <h6>
        {t('text:The_invoice_registration_has_failed_for_the_following_Partners')}
        <br />
        {t('text:Check_the_reason_for_the_registration_failure_below_and_try_again')}
      </h6>
      <br />
      <br />
      <TableBorder>
        <TableHeader
          header={
            dealerIdentifierType === DEALER_IDENTIFIER_TYPE.TAX_CODE
              ? resultTableByTaxHeaders
              : resultTableByCodeHeaders
          }
        />
        <TableBody numOfCol={2}>{renderResultTable()}</TableBody>
      </TableBorder>
    </>
  );
}

export default InvoiceBulkRegisterFailureModal;
