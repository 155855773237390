import { FINANCIER_ALERT_TYPE, stringToEnum } from 'enums';

export interface FinancierAlertSettingVO {
  financierAlertSettingId: number;
  alertType: string;
  emailEnabled: boolean;
  smsEnabled: boolean;
}

export interface FinancierAlertSettingVOModel extends FinancierAlertSettingVO {
  alertType: FINANCIER_ALERT_TYPE;
}

export function formattingToFinancierAlertSettingVOModel(data: FinancierAlertSettingVO): FinancierAlertSettingVOModel {
  return {
    ...data,
    alertType: stringToEnum(FINANCIER_ALERT_TYPE, data.alertType),
  };
}
