import type React from 'react';
import { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { CheckPluginValid } from 'digital-signatures/bkav/CheckPluginValid';
import DigitalSignatureOpModal from 'digital-signatures/modals/DigitalSignatureOpModal';
import { CheckPluginGetCert } from 'digital-signatures/utils/checkPluginGetCert';

import { ROUTES_DE } from 'constants/routes/dealer';
import type { DOCUMENT_FORM_TYPE } from 'enums';
import { AUTHORITY_TYPE } from 'enums';
import useLocationState from 'hooks/useLocationState';
import type Pageable from 'models/Pageable';
import type { CalculateForMultipleLoanRequestVOModel } from 'models/vo/CalculateForMultipleLoanVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { LoanAdditionalDocumentVOModel } from 'models/vo/LoanAdditionalDocumentVO';
import type { SuccessArForRequestMultipleLoanVOModel } from 'models/vo/SuccessArForRequestMultipleLoanVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestDealerAgreementDetail } from 'utils/http/api/dealer/dealer-agreements';
import { requestDealerFinancierCalendar } from 'utils/http/api/dealer/financier-calendar';
import { requestDealerFinancierDocumentList } from 'utils/http/api/dealer/financier-document-forms';
import { requestDealerLoanAdditionalDocumentList } from 'utils/http/api/dealer/loan-additional-documents';
import {
  requestDealerArBulkCalculateLoanRequest,
  requestDealerMultipleArLoanRegistration,
  requestDealerMultipleFactoringAttachments,
} from 'utils/http/api/dealer/multiple-request-loans';
import type {
  CreateDeMultipleRequestLoanByArRequest,
  RequestCalculateDeForMultipleLoan,
} from 'utils/http/api/dealer/multiple-request-loans/request';
import { requestDealerSuccessArListForRequestMultipleLoan } from 'utils/http/api/dealer/success-ars';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import { getDigitalSignatureTransactionFiles, getMaxDate } from '../utils/loanApplicationUtils';

import type { EtcFileRowTypes } from './DealerArBulkFinancingApplicationStep2';

export interface DealerArBulkApplicationStep2State {
  dealerAgreementDetail: DealerAgreementDetailVOModel;
  financierHoliday: Pageable<FinancierCalenderVOModel[]>;
  documentTypes: DOCUMENT_FORM_TYPE[];
  factoringEnable: boolean;
  eSignatureEnable: boolean;
  dealerArBulkCalculateLoanRequest?: CalculateForMultipleLoanRequestVOModel;
  successArList: SuccessArForRequestMultipleLoanVOModel[];
  checkedArList: number[];
  additionalDocumentList: LoanAdditionalDocumentVOModel[];
  maxDate?: Date;
  minDate?: Date;
  totalSuccessArListCount: number;
}

export function useDealerArBulkFinancingApplicationStep2State() {
  const modal = useModal();
  const history = useHistory();
  const userAuthorityType = getSignIn()?.authorityType;
  const { t } = useTranslation();

  const [locationState] = useLocationState<{ dealerAgreementId: number; financierId: number }>([
    'dealerAgreementId',
    'financierId',
  ]);
  const { dealerAgreementId, financierId } = locationState;

  const digitalSignatureTransactionInfo = useRef<FactoringAttachmentVOModel>({} as FactoringAttachmentVOModel);
  const [etcFileRows, setEtcFileRows] = useState<EtcFileRowTypes[]>([
    {
      id: 0,
      fileName: undefined,
    },
  ]);

  const [dataState, setDataState] = useState<DealerArBulkApplicationStep2State>({
    dealerAgreementDetail: {} as DealerAgreementDetailVOModel,
    dealerArBulkCalculateLoanRequest: {} as CalculateForMultipleLoanRequestVOModel,
    financierHoliday: {} as Pageable<FinancierCalenderVOModel[]>,
    documentTypes: [] as DOCUMENT_FORM_TYPE[],
    factoringEnable: false,
    eSignatureEnable: false,
    successArList: [],
    checkedArList: [],
    totalSuccessArListCount: 0,
    additionalDocumentList: [],
  });

  const [showedTotalRequestedAmount, setShowedTotalRequestedAmount] = useState<BigNumber>(); // "Financing Condition"
  const [selectedTotalArAmount, setSelectedTotalArAmount] = useState<BigNumber>(); // "Financing Condition"
  const [totalRequestedAmount, setTotalRequestedAmount] = useState<BigNumber>();

  const dealerBulkApplicationStep2UseForm = useForm<CreateDeMultipleRequestLoanByArRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { reset, getValues, watch } = dealerBulkApplicationStep2UseForm;

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allCheckedArList = dataState.successArList?.map((_: any, index: any) => index);
      setDataState({ ...dataState, checkedArList: allCheckedArList ?? [] });
    } else {
      ReactDOM.unstable_batchedUpdates(() => {
        setDataState({ ...dataState, checkedArList: [] });
        setTotalRequestedAmount('0');
      });
    }
    initializeFinancingConditionInfo();
  };

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.checked) {
      const target = [...dataState.checkedArList, index];
      setDataState({ ...dataState, checkedArList: target });
    } else {
      const target = dataState.checkedArList.filter(arIndex => arIndex !== index);
      setDataState({ ...dataState, checkedArList: target });
    }
    initializeFinancingConditionInfo();
  };

  const fetchAll = async () => {
    try {
      showLoadingUI();
      const documentTypeArr: DOCUMENT_FORM_TYPE[] = [];
      const [
        fetchedDealerAgreementDetail,
        fetchedDealerSuccessArList,
        fetchedFinancierDocumentList,
        financierCommonSettingData,
      ] = await Promise.all([
        requestDealerAgreementDetail(dealerAgreementId),
        requestDealerSuccessArListForRequestMultipleLoan(dealerAgreementId),
        requestDealerFinancierDocumentList(financierId),
        requestFinancierSettingData(financierId),
      ]);

      const requestMultipleLoanByArList = fetchedDealerSuccessArList.content.map(arInfo => ({
        successArId: arInfo.successArId,
        requestedLoanAmount: arInfo.remainingArAmount,
      }));

      const expiryDate = new Date(fetchedDealerAgreementDetail?.expiryDate);

      const minSettlementDate = new Date(
        Math.min(...fetchedDealerSuccessArList.content.map(arInfo => new Date(arInfo.settlementDate).getTime())),
      );

      const minDate = new Date();
      const maxDate = getMaxDate(expiryDate, minSettlementDate);

      const fetchFinancierHoliday = await requestDealerFinancierCalendar(financierId, {
        pageNumber: 0,
        rowCount: Number(getDayTerm(convertToServerDateFormat(minDate), convertToServerDateFormat(maxDate))) + 1,
        fromDate: convertToServerDateFormat(minDate),
        toDate: convertToServerDateFormat(maxDate),
        holiday: true,
      });

      unShowLoadingUI();

      fetchedFinancierDocumentList.content.forEach(item => documentTypeArr.push(item.documentFormType));

      setDataState(prevState => ({
        ...prevState,
        dealerAgreementDetail: fetchedDealerAgreementDetail,
        financierHoliday: fetchFinancierHoliday,
        documentTypes: documentTypeArr,
        factoringEnable: financierCommonSettingData.factoringEnable,
        eSignatureEnable: financierCommonSettingData.eSignatureEnable,
        minDate,
        maxDate,
        successArList: fetchedDealerSuccessArList.content,
        totalSuccessArListCount: fetchedDealerSuccessArList.totalElements,
      }));

      const createDeMultipleRequestLoanByArRequest = getValues();

      reset({
        ...createDeMultipleRequestLoanByArRequest,
        requestedArs: requestMultipleLoanByArList,
      });
    } catch (e) {
      unShowLoadingUI();
      modal.show(e);
    }
  };

  const fetchDealerArBulkCalculateLoanRequest = async (data: RequestCalculateDeForMultipleLoan) => {
    try {
      const fetchedCalculateMultipleLoanRequest = await requestDealerArBulkCalculateLoanRequest(data);

      setDataState(prevState => ({
        ...prevState,
        dealerArBulkCalculateLoanRequest: fetchedCalculateMultipleLoanRequest,
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const calculateRequestedAmount = useCallback(
    (checkedArList: number[]) => {
      const arInfoList = getValues().requestedArs;
      const checkedArInfoList = arInfoList?.filter((_: any, rowIndex: number) => checkedArList.includes(rowIndex));
      const calculatorBigNumber = new CalculatorBigNumber();
      let sum: BigNumber = '0';
      if (checkedArInfoList?.length !== 0) {
        checkedArInfoList?.forEach(arInfo => {
          if (arInfo && arInfo.requestedLoanAmount) {
            sum = calculatorBigNumber.add(sum).add(arInfo.requestedLoanAmount).get();
          }
        });
      }
      setTotalRequestedAmount(sum);
    },
    [getValues, setDataState],
  );

  const getCheckedLoanByArRequestList = () => {
    const loanByArRequestList = getValues().requestedArs;
    const checkedLoanByArRequestList = loanByArRequestList
      .map((arInfo, index) => ({ ...arInfo, index }))
      .filter((_, rowIndex) => dataState.checkedArList.includes(rowIndex));

    return checkedLoanByArRequestList;
  };

  const fetchDealerFactoringAttachments = async (): Promise<FactoringAttachmentVOModel> => {
    const checkedLoanByArRequestList = getCheckedLoanByArRequestList();
    const factoringAttachmentSuccessArDTOs = checkedLoanByArRequestList.map(requestedAr => ({
      successArId: requestedAr.successArId,
      requestedAmount: requestedAr.requestedLoanAmount,
    }));

    return await requestDealerMultipleFactoringAttachments({
      dealerAgreementId,
      desiredDisburseDate: watch().disbursementDate!,
      factoringAttachmentSuccessArDTOs,
    });
  };

  const executeDigitalSignature = async () => {
    showLoadingUI();

    try {
      const { transactionNo, ...factoringDocs } = await fetchDealerFactoringAttachments();

      CheckPluginValid.CheckPluginValid((data: any) =>
        CheckPluginGetCert({
          data,
          modal,
          PDFInfos: factoringDocs,
          pdfSignCompletedCb: async (agreementFileList: FileList, factoringNotificationFileList: FileList) => {
            const data = getValues();
            const checkedLoanByArRequestList = getCheckedLoanByArRequestList();

            manipulateLoanEtcAttachmentRequestData(data, 'application');
            manipulateLoanRequiredDocumentAttachmentRequestData(data);

            data.dealerAgreementId = dealerAgreementId;
            data.agreementAttachment = agreementFileList;
            data.factoringNotificationAttachment = factoringNotificationFileList;
            data.transactionNo = transactionNo;
            data.requestedArs = checkedLoanByArRequestList.map(requestedAr => ({
              successArId: requestedAr.successArId,
              requestedLoanAmount: requestedAr.requestedLoanAmount,
              invoiceAttachmentId: requestedAr.invoiceAttachmentId,
            }));

            try {
              requestDTOParser(data);
              await requestDealerMultipleArLoanRegistration(data);
              showFinancingRequestCompletedModal();
            } catch (e) {
              modal.show(e);
            }
          },
        }),
      );
    } catch (e) {
      unShowLoadingUI();
      modal.show(e);
    }

    return;
  };

  const manipulateLoanRequiredDocumentAttachmentRequestData = (data: CreateDeMultipleRequestLoanByArRequest) => {
    // 파일을 첨부하지 않은 건은 제외
    const additionalDocumentListFormData = data.loanAdditionalDocumentAttachments;

    const isEmptyFile = (attachmentFileList: FileList): boolean => {
      const fileName = attachmentFileList?.item(0)?.name;

      return attachmentFileList.length === 0 && !fileName;
    };

    const manipulatedLoanAdditionalDocumentAttachments = additionalDocumentListFormData?.filter(
      item => !isEmptyFile(item.attachment),
    );

    if (manipulatedLoanAdditionalDocumentAttachments)
      data.loanAdditionalDocumentAttachments = manipulatedLoanAdditionalDocumentAttachments;
  };

  const manipulateLoanEtcAttachmentRequestData = (
    data: CreateDeMultipleRequestLoanByArRequest,
    type: 'application' | 'temp',
  ) => {
    const etcAttachmentsArr: FileList[] = [];
    const etcAttachmentDescriptionsArr: string[] = [];

    const emptyFile = (id: number, fileName: string | undefined) =>
      data.etcAttachments?.[id]?.length === 0 && !fileName;
    const emptyDescription = (id: number) => !data?.etcAttachmentDescriptions?.[id];

    etcFileRows?.forEach(item => {
      if (type === 'application' && emptyFile(item.id, item.fileName)) return;
      if (emptyFile(item.id, item.fileName) && emptyDescription(item.id)) return;
      etcAttachmentsArr.push((data.etcAttachments as FileList[])?.[item.id]);
      etcAttachmentDescriptionsArr.push(data?.etcAttachmentDescriptions?.[item.id] as string);
    });

    data.etcAttachments = etcAttachmentsArr;
    data.etcAttachmentDescriptions = etcAttachmentDescriptionsArr;
  };

  const manipulateRegistrationRequestData = (): CreateDeMultipleRequestLoanByArRequest => {
    const data = getValues();
    const checkedLoanByArRequestList = getCheckedLoanByArRequestList();

    manipulateLoanEtcAttachmentRequestData(data, 'application');
    manipulateLoanRequiredDocumentAttachmentRequestData(data);

    const { transactionNo, loanAgreementFileList, loanFactoringNotificationFileList } =
      getDigitalSignatureTransactionFiles(digitalSignatureTransactionInfo.current);

    data.agreementAttachment = loanAgreementFileList;
    data.factoringNotificationAttachment = loanFactoringNotificationFileList;
    data.transactionNo = transactionNo;
    data.requestedArs = checkedLoanByArRequestList.map(requestedAr => ({
      successArId: requestedAr.successArId,
      requestedLoanAmount: requestedAr.requestedLoanAmount,
      invoiceAttachmentId: requestedAr.invoiceAttachmentId,
    }));
    data.dealerAgreementId = dealerAgreementId;

    return data;
  };

  const requestRegisterArLoan = async () => {
    const data = manipulateRegistrationRequestData();

    try {
      requestDTOParser(data);
      await requestDealerMultipleArLoanRegistration(data);
      showFinancingRequestCompletedModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const showFinancingRequestCompletedModal = () => {
    modal.show(
      <h6>
        {userAuthorityType === AUTHORITY_TYPE.AUTHORIZER ? (
          <>
            {t('text:The_financing_application_has_been_submitted')}
            <br />
            {t(
              'text:You_can_monitor_the_details_and_progress_of_the_financing_applied_from_the_View_Transaction>Financing_menu',
            )}
          </>
        ) : (
          <>
            <h6>
              {t('text:The_financing_application_has_been_submitted_to_the_Authorizer')}
              <br />
              {t('text:The_application_will_be_submitted_to_the_Financier_after_the_Authorizer_s_approval')}
            </h6>
          </>
        )}
      </h6>,
      {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
      },
    );
  };

  const showLoanRequestModal = () => {
    modal.show(<h6>{t('text:Would_you_like_to_submit_the_financing_application_to_the_Authorizer?')}</h6>, {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      confirmBtnCb: requestRegisterArLoan,
      closeBtnText: t('text:Cancel'),
    });
  };

  const showCancelFinancingModal = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_financing_application?')}
        <br />
        {t(
          'text:If_you_cancel_the_financing_application_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        title: t('text:Cancel_Financing_Request'),
        confirmBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
      },
    );
  };

  const onFormSubmit = async () => {
    if (userAuthorityType === AUTHORITY_TYPE.AUTHORIZER) {
      await executeDigitalSignature();
    } else {
      try {
        showLoadingUI();
        const transactionInfo = await fetchDealerFactoringAttachments();
        digitalSignatureTransactionInfo.current = transactionInfo;
        const { loanAgreementAttachment, loanFactoringNotificationAttachment } = transactionInfo;

        unShowLoadingUI();
        modal.show(
          <DigitalSignatureOpModal
            loanAgreementAttachment={loanAgreementAttachment}
            loanFactoringNotificationAttachment={loanFactoringNotificationAttachment}
          />,
          {
            modalSize: ModalSize.DIGITAL_SIGNATURE,
            modalType: ModalType.CONFIRM,
            title: t('text:Document_creation'),
            confirmBtnCb: () => {
              showLoanRequestModal();
            },
          },
        );
      } catch (e) {
        unShowLoadingUI();
        modal.show(e);

        return null;
      }
    }

    return;
  };

  const fetchLoanAdditionalDocumentList = async () => {
    if (totalRequestedAmount) {
      try {
        const res = await requestDealerLoanAdditionalDocumentList(0, 100, {
          dealerAgreementId,
          requestedLoanAmount: Number(totalRequestedAmount), // 암시로 number 타입 형변환
          currencyType: dataState.dealerAgreementDetail.currencyType,
        });

        setDataState(prevState => ({
          ...prevState,
          additionalDocumentList: res.content,
        }));
      } catch (e) {
        modal.show(e);
      }
    }
  };

  const initializeFinancingConditionInfo = () => {
    setDataState(prevState => ({
      ...prevState,
      dealerArBulkCalculateLoanRequest: undefined,
    }));
    setShowedTotalRequestedAmount(undefined);
    setSelectedTotalArAmount(undefined);
  };

  return {
    state: dataState,
    fetchAll,
    dealerBulkApplicationStep2UseForm,
    handleCheckAll,
    handleCheckChange,
    calculateRequestedAmount,
    fetchDealerArBulkCalculateLoanRequest,
    totalRequestedAmount,
    etcFileRowsState: {
      etcFileRows,
      setEtcFileRows,
    },
    showCancelFinancingModal,
    onFormSubmit,
    getCheckedLoanByArRequestList,
    fetchLoanAdditionalDocumentList,
    initializeFinancingConditionInfo,
    showedTotalRequestedAmountState: {
      showedTotalRequestedAmount,
      setShowedTotalRequestedAmount,
    },
    selectedTotalArAmountState: {
      selectedTotalArAmount,
      setSelectedTotalArAmount,
    },
  };
}
