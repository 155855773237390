import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type Pageable from 'models/Pageable';
import type { PotentialPartnerFinancingApplicationDetailVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import type { PotentialPartnerFinancingApplicationDocumentVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDocumentVO';
import type { PotentialPartnerVOModel } from 'models/vo/PotentialPartnerVO';
import {
  requestAnPotentialPartnerFinancingApplicationDocumentAdditionalDocumentRegister,
  requestAnPotentialPartnerFinancingApplicationDocumentDelete,
  requestAnPotentialPartnerFinancingApplicationDocumentUpdate,
  requestAnPotentialPartnerFinancingApplicationsDocumentList,
} from 'utils/http/api/anonymous/potential-partner-financing-application-documents';
import type {
  AnPotentialPartnerFinancingApplicationDocumentCreate,
  AnPotentialPartnerFinancingApplicationDocumentUpdate,
} from 'utils/http/api/anonymous/potential-partner-financing-application-documents/request';
import {
  requestAnPotentialPartnerFinancingApplicationPartner,
  requestAnPotentialPartnerFinancingApplicationsCancelSubmitted,
  requestAnPotentialPartnerFinancingApplicationsDetail,
} from 'utils/http/api/anonymous/potential-partner-financing-applications';
import useModal from 'utils/modal/useModal';
import { getPotentialPartnerEmailCode } from 'utils/storage/SessionStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import { POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS } from '../../../enums';

interface PotentialPartnerSubmissionDetailStateType {
  applicationInfo: PotentialPartnerFinancingApplicationDetailVOModel;
  potentialPartnerInfo: PotentialPartnerVOModel;
  submittedDocumentList: PotentialPartnerFinancingApplicationDocumentVOModel[];
  numOfNeedResubmission: number;
}

export function usePotentialPartnerSubmissionDetailState() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const emailCode = getPotentialPartnerEmailCode();

  const { potentialPartnerFinancingApplicationId } = useParams() as any;

  const [dataState, setDataState] = useState<PotentialPartnerSubmissionDetailStateType>({
    applicationInfo: {} as PotentialPartnerFinancingApplicationDetailVOModel,
    potentialPartnerInfo: {} as PotentialPartnerVOModel,
    submittedDocumentList: [] as PotentialPartnerFinancingApplicationDocumentVOModel[],
    numOfNeedResubmission: 0,
  });

  const getCountNeedResubmissionDocument = (
    documentList: Pageable<PotentialPartnerFinancingApplicationDocumentVOModel[]>,
  ): number =>
    documentList.content.reduce(
      (count, item) =>
        item.applicationDocumentStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.NEED_RESUBMISSION
          ? count + 1
          : count,
      0,
    );

  const fetchAll = async (): Promise<void> => {
    if (!emailCode) return modal.show('not found email code');
    try {
      const [fetchApplicationDetail, fetchPotentialPartner, fetchDocumentList] = await Promise.all([
        requestAnPotentialPartnerFinancingApplicationsDetail(potentialPartnerFinancingApplicationId, emailCode),
        requestAnPotentialPartnerFinancingApplicationPartner(potentialPartnerFinancingApplicationId, emailCode),
        requestAnPotentialPartnerFinancingApplicationsDocumentList(
          0,
          100,
          emailCode,
          potentialPartnerFinancingApplicationId,
        ),
      ]);

      setDataState(prevState => ({
        ...prevState,
        applicationInfo: fetchApplicationDetail,
        potentialPartnerInfo: fetchPotentialPartner,
        submittedDocumentList: fetchDocumentList.content,
        numOfNeedResubmission: getCountNeedResubmissionDocument(fetchDocumentList),
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchApplicationDetailInfo = async () => {
    if (!emailCode) return modal.show('not found email code');
    try {
      const response = await requestAnPotentialPartnerFinancingApplicationsDetail(
        potentialPartnerFinancingApplicationId,
        emailCode,
      );

      setDataState(prevState => ({
        ...prevState,
        applicationInfo: response,
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchDocumentList = async () => {
    if (!emailCode) return modal.show('not found email code');
    try {
      const response = await requestAnPotentialPartnerFinancingApplicationsDocumentList(
        0,
        100,
        emailCode,
        potentialPartnerFinancingApplicationId,
      );

      setDataState(prevState => ({
        ...prevState,
        submittedDocumentList: response.content,
        numOfNeedResubmission: getCountNeedResubmissionDocument(response),
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const cancelSubmission = async (potentialPartnerFinancingApplicationId: number) => {
    if (!emailCode) return modal.show('not found email code');
    try {
      const updatedApplicationInfo = await requestAnPotentialPartnerFinancingApplicationsCancelSubmitted(
        potentialPartnerFinancingApplicationId,
        emailCode,
      );
      setDataState(prevState => ({
        ...prevState,
        applicationInfo: updatedApplicationInfo,
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const updateDocument = async (
    potentialPartnerFinancingApplicationDocumentId: number,
    data: Pick<
      AnPotentialPartnerFinancingApplicationDocumentUpdate,
      'description' | 'attachment' | 'attachmentChanged'
    >,
  ) => {
    if (!emailCode) return modal.show('not found email code');

    const formData: AnPotentialPartnerFinancingApplicationDocumentUpdate = {
      ...data,
      emailCode,
    };

    try {
      requestDTOParser(formData);
      await requestAnPotentialPartnerFinancingApplicationDocumentUpdate(
        potentialPartnerFinancingApplicationDocumentId,
        formData,
      );
      await fetchDocumentList();
      await fetchApplicationDetailInfo();
      modal.show(<h6>{t('text:The_changes_to_the_document_have_been_saved_successfully')}</h6>);
    } catch (e) {
      modal.show(e);
    }
  };

  const addNewDocument = async (
    data: Pick<AnPotentialPartnerFinancingApplicationDocumentCreate, 'description' | 'attachment'>,
  ) => {
    if (!emailCode) return modal.show('not found email code');
    const formData: AnPotentialPartnerFinancingApplicationDocumentCreate = {
      ...data,
      emailCode,
      potentialPartnerFinancingApplicationId,
    };

    try {
      requestDTOParser(formData);
      await requestAnPotentialPartnerFinancingApplicationDocumentAdditionalDocumentRegister(formData);
      await fetchDocumentList();
      await fetchApplicationDetailInfo();
      modal.show(<h6>{t('text:The_changes_to_the_document_have_been_saved_successfully')}</h6>);
    } catch (e) {
      modal.show(e);
    }
  };

  const deleteDocument = async (potentialPartnerFinancingApplicationDocumentId: number) => {
    if (!emailCode) return modal.show('not found email code');
    try {
      await requestAnPotentialPartnerFinancingApplicationDocumentDelete(
        potentialPartnerFinancingApplicationDocumentId,
        emailCode,
      );
      await fetchDocumentList();
    } catch (e) {
      modal.show(e);
    }
  };

  return {
    state: dataState,
    cancelSubmission,
    fetchAll,
    updateDocumentApis: {
      updateDocument,
      addNewDocument,
      deleteDocument,
    },
  };
}
