import { CURRENCY_TYPE, WAITING_AR_STATUS, stringToEnum } from 'enums';

export interface WaitingArVO {
  waitingArId: number;
  waitingArApprovalPhaseId: number;
  partnerName: string;
  partnerTaxCode: string;
  arNumber: string;
  arIssuedDate: string;
  settlementDate: string;
  arAmount: number;
  currencyType: string;
  waitingArStatus: string;
  exceptionMessage?: string;
}

export interface WaitingArVOModel extends WaitingArVO {
  currencyType: CURRENCY_TYPE;
  waitingArStatus: WAITING_AR_STATUS;
}

export function formattingToWaitingArVOModel(data: WaitingArVO): WaitingArVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    waitingArStatus: stringToEnum(WAITING_AR_STATUS, data.waitingArStatus),
  };
}
