import footerLogo from 'assets/png/footerLogo.png';

import './PPFooter.scss';

const FIN2B_HOME_ADDRESS = 'https://fin2b.com';

function PPFooter() {
  return (
    <div className="pp-footer-wrapper">
      <div className="pp-footer">
        <img className="pp-footer__logo" src={footerLogo} alt="logo" />
        <div className="pp-footer__content">
          <div className="pp-footer__content pp-footer__content--fin2b">
            <div>© 2023 Fin2B Inc.</div>
            <div>support@fin2b.com</div>
            <div>
              <a href={FIN2B_HOME_ADDRESS} target="_blank" rel="noreferrer">
                fin2b.com
              </a>
            </div>
          </div>
          {/* TODO */}
          {/* <div className={'pp-footer__content--term'}>Terms of Use</div> */}
        </div>
      </div>
    </div>
  );
}

export default PPFooter;
