import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { requestFinancierAnchorUsers } from 'utils/http/api/financier/anchor-users';

interface PropsType {
  isAnchor?: boolean;
  anchorClientId: number;
  selectedAnchorUserListManager: (anchorUser: AnchorUserVOModel, isAdd: boolean) => void;
}

export function SearchAnchorUserModal({
  isAnchor = true,
  anchorClientId,
  selectedAnchorUserListManager,
}: PropsType): JSX.Element {
  const mounted = useMounted();
  const [anchorUserPageData, setAnchorUserPageData] = useState<Pageable<AnchorUserVOModel[]>>();
  const { pageable: anchorUserPageable, setPageable: setAnchorUserPageable } = usePageable();
  const { t } = useTranslation();

  const [selectedAnchorUserList, setSelectedAnchorUserList] = useState<AnchorUserVOModel[]>([]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (pageNumber: number = 1, sizePerPage: number = 10): Promise<void> => {
    const anchorUserPageData = await requestFinancierAnchorUsers(pageNumber, sizePerPage, {
      anchorClientId,
      authorityTypes: isAnchor ? [AUTHORITY_TYPE.ADMIN] : [AUTHORITY_TYPE.AUTHORIZER, AUTHORITY_TYPE.OPERATOR],
    });

    ReactDOM.unstable_batchedUpdates(() => {
      setAnchorUserPageData(anchorUserPageData);
      setAnchorUserPageable(anchorUserPageData);
    });
  };

  const paginate = async (pageNumber: number, sizePerPage: number): Promise<void> => {
    fetchAll(pageNumber, sizePerPage);
  };

  // TODO :: CheckBox 이벤트 재 작업
  const onClickCheckBox = (e: any, data: AnchorUserVOModel) => {
    if (e.target.checked) {
      setSelectedAnchorUserList(prev => [...prev, data]);
      selectedAnchorUserListManager(data, true);
    } else {
      setSelectedAnchorUserList(prev => prev.filter(anchorUser => anchorUser.id !== data.id));
      selectedAnchorUserListManager(data, false);
    }
  };

  // TODO :: 동적 렌더링 필요
  const isCheckedBox = (data: AnchorUserVOModel): boolean => {
    return selectedAnchorUserList.map(item => item.id).includes(data.id);
  };

  return (
    <>
      <table className="table-border">
        <colgroup>
          <col style={{ width: '50px' }} />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col" />
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:User_Code')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:Name')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:Mobile')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:Email')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:OTP_Number')}
            </th>
            <th style={{ backgroundColor: '#F5F5F5' }} scope="col">
              {t('text:Authority')}
            </th>
          </tr>
        </thead>
        <tbody>
          {anchorUserPageData?.content.length !== 0 ? (
            anchorUserPageData?.content.map((anchorUserData, index: number) => (
              <tr key={index}>
                <td className="text-center" style={{ backgroundColor: '#F5F5F5' }}>
                  <input
                    className="form-check-input m-0"
                    type="checkbox"
                    onChange={e => onClickCheckBox(e, anchorUserData)}
                    checked={isCheckedBox(anchorUserData)}
                  />
                </td>
                <td>{anchorUserData.bankUserId}</td>
                <td>{anchorUserData.name}</td>
                <td>{anchorUserData.mobile}</td>
                <td>{anchorUserData.email}</td>
                <td>{anchorUserData.anchorUserOtpSerialNo ?? '-'}</td>
                <td>{t(`code:authority-type.${anchorUserData.authorityType}`)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} className="text-center">
                {t('text:No_user_has_been_registered_for_this_Anchor_company_yet')}{' '}
                {t('text:Please_register_the_anchor_user_first_before_proceeding')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination pageable={anchorUserPageable} paginate={paginate} />
    </>
  );
}

export default SearchAnchorUserModal;
