import type Pageable from 'models/Pageable';
import type { FinancierClientHistoryVO, FinancierClientHistoryVOModel } from 'models/vo/FinancierClientHistoryVO';
import { formattingToFinancierClientHistoryVOModel } from 'models/vo/FinancierClientHistoryVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

export async function requestSyFinancierClientHistories(
  pageNumber: number,
  rowCount: number,
  targetFinancierClientId: number,
): Promise<Pageable<FinancierClientHistoryVOModel[]>> {
  const response = await http.get<Pageable<FinancierClientHistoryVO[]>>({
    url: API_SY.FINANCIER_CLIENT_HISTORIES.FINANCIER_CLIENT_HISTORIES,
    data: {
      pageNumber,
      rowCount,
      targetFinancierClientId,
    },
  });

  return {
    ...response,
    content: response.content.map((data: FinancierClientHistoryVO) => formattingToFinancierClientHistoryVOModel(data)),
  };
}
