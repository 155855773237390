import { CURRENCY_TYPE, ENTERPRISE_TYPE, SUCCESS_AR_STATUS, stringToEnum } from 'enums';

export interface SuccessArVO {
  successArId: number;
  createdDateTime: string;
  arNumber: string;
  arAmount: number;
  arIssuedDate: string;
  settlementDate: string;
  validateResult: string;
  maturityDate: string;
  anchorName: string;
  anchorTaxCode: string;
  anchorPartnerName: string;
  anchorPartnerTaxCode: string;
  currencyType: string;
  blockedByFinancier: boolean;
  blockedReason: string;
  paymentSupport: boolean;
  confirmedEnterpriseType: string;
  successArStatus: string;
  financierName: string;
  financierId: number;
  ltvArAmount: number;
}

export interface SuccessArVOModel extends SuccessArVO {
  currencyType: CURRENCY_TYPE;
  confirmedEnterpriseType: ENTERPRISE_TYPE;
  successArStatus: SUCCESS_AR_STATUS;
}

export function formattingToSuccessArVOModel(data: SuccessArVO): SuccessArVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    confirmedEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.confirmedEnterpriseType),
    successArStatus: stringToEnum(SUCCESS_AR_STATUS, data.successArStatus),
  };
}
