import { useEffect } from 'react';

import useMounted from 'hooks/useMounted';

import PotentialPartnerFinancierDetailBranches from './sections/branches/PotentialPartnerFinancierDetailBranches';
import PotentialPartnerFinancierDetailContactInfo from './sections/contact-info/PotentialPartnerFinancierDetailContactInfo';
import PotentialPartnerFinancierDetailRequiredDocumentList from './sections/required-documents/PotentialPartnerFinancierDetailRequiredDocumentList';
import { usePotentialPartnerFinancierDetailState } from './usePotentialPartnerFinancierDetailState';
import PPSection from '../../../components/potential-partner/PPSection/PPSection';
import PageTitle from '../../../components/potential-partner/PageTitle/PageTitle';

function PotentialPartnerFinancierDetailPage() {
  const mounted = useMounted();

  const { state, fetchAll } = usePotentialPartnerFinancierDetailState();

  const { financierPotentialPartnerAcquisitionSetting, requiredPotentialPartnerAcquisitionDocument, branchesInfo } =
    state;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  return (
    <div className="pp-financier-detail-page">
      <PPSection paddingType="md">
        <PageTitle title={financierPotentialPartnerAcquisitionSetting.financierName} />
        <div className="pp-financier-detail-page__content">
          <PotentialPartnerFinancierDetailRequiredDocumentList
            documentList={requiredPotentialPartnerAcquisitionDocument}
          />
          <PotentialPartnerFinancierDetailBranches branchesList={branchesInfo} />
          <PotentialPartnerFinancierDetailContactInfo contactInfo={financierPotentialPartnerAcquisitionSetting} />
        </div>
      </PPSection>
    </div>
  );
}

export default PotentialPartnerFinancierDetailPage;
