import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button2, { Button2ColorEnum, Button2SizeEnum } from 'components/stateless/Button2/Button2';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import type { AnPotentialPartnerFinancingApplicationSubmitRequest } from 'utils/http/api/anonymous/potential-partner-financing-applications/request';
import useModal from 'utils/modal/useModal';

import './PotentialPartnerApplicationStep2.scss';
import PotentialPartnerApplicationStep2AdditionalDocumentForm from './sections/additional-documents-form/UploadedPartnerApplicationStep2AdditionalDocumentForm';
import PotentialPartnerApplicationStep2RequiredDocumentForm from './sections/required-documents-form';
import PotentialPartnerApplicationStep2SelectBranch from './sections/select-branch';
import PPSubTitle from '../../../../../components/potential-partner/PPSubTitle/PPSubTitle';
import {
  usePotentialPartnerApplicationActions,
  usePotentialPartnerApplicationValue,
} from '../../PotentialPartnerApplicationProvider';

import type { PotentialPartnerApplicationPageLocationStateType } from '../logics/types';

export type PotentialPartnerApplicationStep2FormDataType = Pick<
  AnPotentialPartnerFinancingApplicationSubmitRequest,
  | 'branchId'
  | 'requiredAttachment'
  | 'requiredDescription'
  | 'potentialPartnerAcquisitionDocumentId'
  | 'additionalAttachment'
  | 'additionalDescription'
>;

interface PotentialPartnerApplicationStep2Props {
  branchesInfo: BranchVOModel[];
  documentInfo: PotentialPartnerAcquisitionDocumentVOModel[];
}

function PotentialPartnerApplicationStep2({ branchesInfo, documentInfo }: PotentialPartnerApplicationStep2Props) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { anchorClientName, financierName } = useLocation<PotentialPartnerApplicationPageLocationStateType>().state;
  const { setPrevFormStep, setNextFormStep, setFormValues, onClickCancelSubmissionBtn } =
    usePotentialPartnerApplicationActions();
  const { formData } = usePotentialPartnerApplicationValue();

  const step2Form = useForm<PotentialPartnerApplicationStep2FormDataType>({
    defaultValues: formData,
  });

  const onClickPrev = () => {
    setPrevFormStep();
    setFormValues(step2Form.getValues());
  };

  const additionalDocumentValidator = (): boolean => {
    const data = step2Form.getValues();
    let isValid = true;

    data.additionalAttachment.forEach((attachmentItem, index) => {
      if (!attachmentItem[0]?.name && data.additionalDescription[index]) {
        isValid = false;

        return false;
      }
    });
    if (!isValid) {
      modal.show(
        <h6>
          {t('text:The_attached_files_are_missing_from_the_additional_documents_section')}
          <br />
          {t('text:Attach_the_corresponding_file_for_the_document_description_entered')}
        </h6>,
      );
    }

    return isValid;
  };

  const onClickNext = () => {
    const data = step2Form.getValues();

    if (!additionalDocumentValidator()) return;
    else {
      // delete empty rows
      const additionalAttachmentList = data.additionalAttachment;
      const additionalDescriptionList = data.additionalDescription;

      const deletedEmptyAdditionalAttachmentList = additionalAttachmentList.filter((attachmentItem, index) => {
        return additionalAttachmentList[index].item(0)?.name;
      });

      const deletedEmptyAdditionalDescriptionList = additionalDescriptionList.filter((descriptionItem, index) => {
        return additionalAttachmentList[index].item(0)?.name;
      });

      setNextFormStep();
      setFormValues({
        ...data,
        additionalAttachment: deletedEmptyAdditionalAttachmentList,
        additionalDescription: deletedEmptyAdditionalDescriptionList,
      });
    }
  };

  return (
    <FormProvider {...step2Form}>
      <div className="pp-mb-56">
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t(`text:Buyer_Name`)} value={anchorClientName} />
              <FormValue label={t(`text:Financier_Name`)} value={financierName} />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="pp-mb-56">
        <PPSubTitle
          title={`${t('text:Financier_Name_needs_the_following_documents_to_review_your_application', {
            financierName,
          })} ${t('text:Please_attach_them_below')}`}
        />
        <PotentialPartnerApplicationStep2RequiredDocumentForm documentInfo={documentInfo} />
        <PotentialPartnerApplicationStep2AdditionalDocumentForm />
      </div>
      <div>
        <PPSubTitle
          title={`${t('text:Do_you_have_a_preferred_transaction_branch?')} ${t(
            'text:Please_select_it_from_the_list_below',
          )}`}
        />
        <PotentialPartnerApplicationStep2SelectBranch branchesInfo={branchesInfo} />
      </div>

      <div className="pp-mt-96 d-flex justify-content-between">
        <Button2 color={Button2ColorEnum.TERTIARY} size={Button2SizeEnum.LG} onClick={onClickCancelSubmissionBtn}>
          {t('text:Cancel_application')}
        </Button2>
        <div className="d-flex flex-end">
          <Button2
            size={Button2SizeEnum.LG}
            color={Button2ColorEnum.TERTIARY}
            icon={<FontAwesomeIcon icon={faArrowLeft} fontSize={19.25} />}
            onClick={onClickPrev}
            className="pp-mr-16"
          >
            {t('text:Previous_page')}
          </Button2>
          <Button2
            className="pp-next-btn"
            size={Button2SizeEnum.LG}
            icon={<FontAwesomeIcon icon={faArrowRight} fontSize={19.25} />}
            onClick={onClickNext}
          >
            {t('text:Next_page')}
          </Button2>
        </div>
      </div>
    </FormProvider>
  );
}

export default PotentialPartnerApplicationStep2;
