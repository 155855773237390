import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import RadioButton from 'components/stateless/Button/RadioButton';
import ChoiceChip from 'components/stateless/Chips/ChoiceChip';
import {
  FormBorder,
  FormContents,
  FormInput,
  FormRadio,
  FormSelect,
  NumericFormatInput,
} from 'components/stateless/CommonForm';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { FINANCIER_TYPE, OTP_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import { CURRENCY_TYPE, FINANCIER_INTERFACE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { formErrorHandler } from 'utils/error/manager';
import { requestSystemEnterpriseFinancierRegister } from 'utils/http/api/system/enterprises';
import type { SystemEnterpriseFinancierRegisterRequest } from 'utils/http/api/system/enterprises/requests';
import { requestFinancierCommonSettingMisassignedFinancierTypeList } from 'utils/http/api/system/financier-common-setting';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

interface PlatFormSetting {
  keyName: FINANCIER_INTERFACE_TYPE;
  value: string;
}

interface FinancierTypeOptions {
  label: string;
  value: FINANCIER_TYPE;
}

function SystemFinancierRegister() {
  const mounted = useMounted();
  const history = useHistory();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const {
    register: registFormRegister,
    getValues: getRegistFormValues,
    errors: registFormErrors,
    setError: setRegistFormErrors,
    clearErrors: setRegistFormClear,
    setValue: setRegistFormValue,
    watch,
    control,
  } = useForm<SystemEnterpriseFinancierRegisterRequest>();
  const [attachFileName, setAttachFileName] = useState<string>('');
  const [financierTypeOptions, setFinancierTypeOptions] = useState<FinancierTypeOptions[]>([]);

  const { settlementDateIsTodayInvoiceUploadable, supportedCollateralType } = watch();

  const [platformSettingList, setPlatformSettingList] = useState<PlatFormSetting[]>([]);
  const [supportedCurrencyList, setSupportedCurrencyList] = useState<CURRENCY_TYPE[]>([]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    if (supportedCollateralType === 'INVOICE') {
      setRegistFormValue('factoringEnable', 'false');
    }
  }, [supportedCollateralType, setRegistFormValue]);

  const fetchAll = async (): Promise<void> => {
    try {
      const financierTypeOptionsResponse = await requestFinancierCommonSettingMisassignedFinancierTypeList();

      setFinancierTypeOptions(
        financierTypeOptionsResponse.map(item => {
          return {
            label: item,
            value: item,
          };
        }),
      );
    } catch (error) {
      modal.show(error);
    }
  };

  const onChangePlatformSetting = (e: any) => {
    const platformSettingIndex = platformSettingList.map(item => item.keyName).indexOf(e.target.name);
    platformSettingIndex >= 0
      ? setPlatformSettingList(prev => {
          const dummy = [...prev];
          dummy[platformSettingIndex].value = e.target.value;

          return dummy;
        })
      : setPlatformSettingList(prev => [...prev, { keyName: e.target.name, value: e.target.value }]);
  };

  const isPlatformSettingOptionChecked = (settingName: FINANCIER_INTERFACE_TYPE, value: boolean) => {
    const indexOfSettingName = platformSettingList.map(item => item.keyName).indexOf(settingName);

    return indexOfSettingName >= 0 && platformSettingList[indexOfSettingName].value === String(value);
  };

  const onClickRegister = (e: any) => {
    e.preventDefault();
    const onClickConfirm = async (): Promise<void> => {
      const data = getRegistFormValues();

      const interfaceList = platformSettingList.map(item => item.keyName);
      const interfaceEnabledList = platformSettingList.map(item => item.value === 'true');
      const supportedCurrencyTypes = supportedCurrencyList;

      try {
        requestDTOParser(data);

        await requestSystemEnterpriseFinancierRegister({
          ...data,
          interfaceList,
          interfaceEnabledList,
          supportedCurrencyTypes,
        });

        modal.show(<h6>{t('text:The_financier_information_has_been_registered_successfully')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => {
            history.push(ROUTES_SY.MANAGEMENT.FINANCIER_LIST);
          },
        });
      } catch (error: any) {
        modal.show(error);
        formErrorHandler<SystemEnterpriseFinancierRegisterRequest>(error, setRegistFormErrors, setRegistFormClear);
      }
    };

    modal.show(<h6>{t('text:Would_you_like_to_register_financier_information?')}</h6>, {
      modalSize: ModalSize.NONE,
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: async () => onClickConfirm(),
    });
  };

  const onClickCancel = (e: any) => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_registration?')}
        <br />
        {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => {
          history.push(ROUTES_SY.MANAGEMENT.FINANCIER_LIST);
        },
      },
    );
  };

  const renderPlatformSettingBox = () => {
    const PlatFormSettingOptions: { label: string; name: FINANCIER_INTERFACE_TYPE }[] = [
      {
        label: t('text:Branch'),
        name: FINANCIER_INTERFACE_TYPE.BRANCH,
      },
      {
        label: t('text:User'),
        name: FINANCIER_INTERFACE_TYPE.USER,
      },
      {
        label: t('text:SMS'),
        name: FINANCIER_INTERFACE_TYPE.SMS,
      },
      {
        label: t('text:OTP_Query'),
        name: FINANCIER_INTERFACE_TYPE.OTP_QUERY,
      },
      {
        label: t('text:OTP_Validation'),
        name: FINANCIER_INTERFACE_TYPE.OTP_VALIDATION,
      },
      {
        label: t('text:Business_Date'),
        name: FINANCIER_INTERFACE_TYPE.BUSINESS_DATE,
      },
      {
        label: t('text:Deposit_Transaction'),
        name: FINANCIER_INTERFACE_TYPE.DEPOSIT_TRANSACTION,
      },
      {
        label: t('text:Enterprise'),
        name: FINANCIER_INTERFACE_TYPE.ENTERPRISE,
      },
      {
        label: t('text:Enterprise_Loan_Limit'),
        name: FINANCIER_INTERFACE_TYPE.ENTERPRISE_TOTAL_LIMIT,
      },
      {
        label: t('text:Anchor_Agreement_By_Contract_Number'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_AGREEMENT_BY_CONTRACT_NO,
      },
      {
        label: t('text:Anchor_Agreement_By_Key_Union'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_AGREEMENT_BY_KEY_UNION,
      },
      {
        label: t('text:Buyer_Agreement_By_Contract_Number'),
        name: FINANCIER_INTERFACE_TYPE.BUYER_AGREEMENT_BY_CONTRACT_NO,
      },

      {
        label: t('text:Buyer_Agreement_By_Key_Union'),
        name: FINANCIER_INTERFACE_TYPE.BUYER_AGREEMENT_BY_KEY_UNION,
      },

      {
        label: t('text:Dealer_Agreement_By_Contract_Number'),
        name: FINANCIER_INTERFACE_TYPE.DEALER_AGREEMENT_BY_CONTRACT_NO,
      },
      {
        label: t('text:Dealer_Agreement_By_Key_Union'),
        name: FINANCIER_INTERFACE_TYPE.DEALER_AGREEMENT_BY_KEY_UNION,
      },
      {
        label: t('text:Supplier_Agreement_By_Contract_Number'),
        name: FINANCIER_INTERFACE_TYPE.SUPPLIER_AGREEMENT_BY_CONTRACT_NO,
      },
      {
        label: t('text:Supplier_Agreement_By_Key_Union'),
        name: FINANCIER_INTERFACE_TYPE.SUPPLIER_AGREEMENT_BY_KEY_UNION,
      },
      {
        label: t('text:Anchor_User_of_Anchor_Agreement'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_ANCHOR_AGREEMENT,
      },
      {
        label: t('text:Anchor_User_of_Buyer_Agreement'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_BUYER_AGREEMENT,
      },
      {
        label: t('text:Anchor_User_of_Dealer_Agreement'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_DEALER_AGREEMENT,
      },
      {
        label: t('text:Anchor_User_of_Supplier_Agreement'),
        name: FINANCIER_INTERFACE_TYPE.ANCHOR_USER_OF_SUPPLIER_AGREEMENT,
      },
      {
        label: t('text:Loan_Approval'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_APPROVAL,
      },
      {
        label: t('text:Loan_Approval_By_AR'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_APPROVAL_BY_AR,
      },
      {
        label: t('text:Loan_Result'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_RESULT,
      },
      {
        label: t('text:Loan_Result_AR'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_RESULT_AR,
      },
      {
        label: t('text:Loan_Expectation'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EXPECTATION,
      },
      {
        label: t('text:Loan_Expectation_AR'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EXPECTATION_AR,
      },
      {
        label: t('text:Loan_Transaction'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_TRANSACTION,
      },
      {
        label: t('text:Inventory_Request'),
        name: FINANCIER_INTERFACE_TYPE.INVENTORY_REQUEST,
      },
      {
        label: t('text:Loan_Extension_Approval'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EXTENSION_APPROVAL,
      },
      {
        label: t('text:Loan_Extension_Result'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EXTENSION_RESULT,
      },
      {
        label: t('text:Approve_AR_Payment_Request'),
        name: FINANCIER_INTERFACE_TYPE.APPROVE_AR_PAYMENT_REQUEST,
      },
      {
        label: t('text:AR_Payment_Result'),
        name: FINANCIER_INTERFACE_TYPE.AR_PAYMENT_RESULT,
      },
      {
        label: t('text:Transfer_Request'),
        name: FINANCIER_INTERFACE_TYPE.TRANSFER_REQUEST,
      },
      {
        label: t('text:Transfer_Result'),
        name: FINANCIER_INTERFACE_TYPE.TRANSFER_RESULT,
      },
      {
        label: t('text:Supplier_Account_Registration'),
        name: FINANCIER_INTERFACE_TYPE.SUPPLIER_ACCOUNT_REGISTRATION,
      },
      {
        label: t('text:Receive_Loan_Change'),
        name: FINANCIER_INTERFACE_TYPE.RECEIVE_LOAN_CHANGE,
      },
      {
        label: t('text:Receive_AR_Payment_Change'),
        name: FINANCIER_INTERFACE_TYPE.RECEIVE_AR_PAYMENT_CHANGE,
      },
      {
        label: t('text:Loan_Repayment_Aggregation'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_REPAYMENT_AGGREGATION,
      },
      {
        label: t('text:Early_Repayment_Request_-_Invoice'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EARLY_REPAYMENT_REQUEST,
      },
      {
        label: t('text:Early_Repayment_Request_-_AR'),
        name: FINANCIER_INTERFACE_TYPE.LOAN_EARLY_REPAYMENT_REQUEST_BY_AR,
      },
      {
        label: t('text:Basis_Interest_Rate'),
        name: FINANCIER_INTERFACE_TYPE.BASIS_INTEREST_RATE,
      },
      {
        label: t('text:Exchange_Rate'),
        name: FINANCIER_INTERFACE_TYPE.CURRENCY_EXCHANGE_RATE,
      },
      {
        label: t('text:Health_Check'),
        name: FINANCIER_INTERFACE_TYPE.HEALTH_CHECK,
      },
    ];

    return PlatFormSettingOptions.map((item, index) => {
      return (
        <div className="row" key={index}>
          <div className="col-4 d-flex align-self-center">
            <div className="information-form__label m-0">{item.label}</div>
          </div>
          <div className="col-4">
            <div className="large-radio-btn">
              <RadioButton
                id={`${item.name}-manual`}
                name={item.name}
                value="false"
                onChange={event => onChangePlatformSetting(event)}
                checked={isPlatformSettingOptionChecked(item.name, false)}
              >
                {t('text:Manual')}
              </RadioButton>
            </div>
          </div>
          <div className="col-4">
            <div className="large-radio-btn">
              <RadioButton
                id={`${item.name}-api`}
                name={item.name}
                value="true"
                onChange={event => onChangePlatformSetting(event)}
                checked={isPlatformSettingOptionChecked(item.name, true)}
              >
                {t('text:API')}
              </RadioButton>
            </div>
          </div>
        </div>
      );
    });
  };

  const setInvoiceWillBeSettledOnUploadDate = () => {
    setRegistFormValue('invoiceWillBeSettledOnUploadDate', 'false');
  };

  const onSelectCurrencyType = (currencyType: CURRENCY_TYPE) => {
    const data = supportedCurrencyList.concat(currencyType);
    setSupportedCurrencyList(data);
  };

  const onUnSelectCurrencyType = (currencyType: CURRENCY_TYPE) => {
    const data = supportedCurrencyList.filter((currencyItem: CURRENCY_TYPE) => currencyItem !== currencyType);
    setSupportedCurrencyList(data);
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Financier_Registration')} />
      <GuideMessage
        message={[
          t('text:Please_enter_the_information_to_register_the_Financier'),
          <Trans
            key="key"
            i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
            components={{ 1: <span className="asterisk" /> }}
          />,
          t('text:Click_on_the_Register_button_to_complete_the_registration'),
        ]}
        messageType={MessageType.BASIC}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Financier_Information')} />
        <FormBorder editable={true}>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:General_Information')} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Financier_Name')}
                name="financierName"
                ref={registFormRegister}
                requiredOptions={{ required: true }}
                error={registFormErrors.financierName}
              />
              <FormInput
                label={t('text:Tax_Code')}
                name="taxCode"
                ref={registFormRegister}
                requiredOptions={{ required: true }}
                error={registFormErrors.taxCode}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Company_Registration_Number')}
                name="businessCode"
                ref={registFormRegister}
                error={registFormErrors.businessCode}
              />
              <FormInput
                label={t('text:Telephone')}
                name="telephone"
                ref={registFormRegister}
                requiredOptions={{ required: true }}
                error={registFormErrors.telephone}
                col={3}
              />
              <FormInput
                label={t('text:Fax')}
                name="fax"
                ref={registFormRegister}
                error={registFormErrors.fax}
                col={3}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Registered_Office_Address')}
                name="address"
                ref={registFormRegister}
                requiredOptions={{ required: true }}
                col={12}
                error={registFormErrors.address}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Legal_Representative_Information')} />
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Name')}
                name="representativeName"
                ref={registFormRegister}
                requiredOptions={{ required: true }}
                error={registFormErrors.representativeName}
              />
              <FormInput
                label={t('text:Telephone')}
                name="representativeMobile"
                ref={registFormRegister}
                error={registFormErrors.representativeMobile}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Email')}
                name="representativeEmail"
                ref={registFormRegister}
                error={registFormErrors.representativeEmail}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Common_Settings')} />
        <FormBorder editable={true}>
          <FormSubtitle title={t('text:Access_Settings')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <div className="information-form__label">
                {t(
                  'text:Unless_the_Sub-Code_was_provided_in_advance,_the_Financier_Code_and_Sub-Code_must_be_identical',
                )}
              </div>
            </div>
            <div className="row">
              <FormSelect
                label={t('text:Financier_Code')}
                selectOptions={getSelectOptions<FINANCIER_TYPE>(
                  'FINANCIER_TYPE',
                  financierTypeOptions.map(item => item.value),
                )}
                name="financierType"
                ref={registFormRegister}
                required
                error={registFormErrors.financierType}
              />
              <FormInput
                label={t('text:Financier_Sub-Code')}
                name="financierSubType"
                requiredOptions={{ required: true }}
                ref={registFormRegister}
                error={registFormErrors.financierSubType}
              />
            </div>
            <div className="row">
              <FormInput
                label={t('text:Communicator_Root')}
                name="communicatorRootPath"
                ref={registFormRegister}
                error={registFormErrors.communicatorRootPath}
              />
              <FormInput
                label={t('text:Financier_Subdomain')}
                name="subDomain"
                ref={registFormRegister}
                error={registFormErrors.subDomain}
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:General_Settings')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_branches_to_see_all_of_the_financiers_data?')}
                error={registFormErrors.showOnlyAllowedBranchData}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="showOnlyAllowedBranchData"
                  value="false"
                  ref={registFormRegister}
                />
                <FormRadio
                  label={t('text:No')}
                  name="showOnlyAllowedBranchData"
                  value="true"
                  ref={registFormRegister}
                />
              </FormRadioWrap>
              <div className="col-6">
                <div className="d-flex">
                  <label className="information-form__label star w-auto">{t('text:Financier_Logo_File')}</label>
                  {registFormErrors.logo && <FontAwesomeIcon icon={faCheckCircle} className="check-icon ms-2" />}
                </div>
                <div className="align-self-center mt-2">
                  <div className="d-flex align-items-center">
                    <input
                      type="file"
                      id="logoAttachmentFile"
                      name="logo"
                      className="d-none"
                      ref={registFormRegister}
                      onChange={e => {
                        if (e.target.files) {
                          setAttachFileName((e.target.files as FileList)[0]?.name);
                        }
                      }}
                    />
                    <label htmlFor="logoAttachmentFile" className="attach-file-link-button me-3">
                      {t('text:Attach_File')}
                    </label>
                    <div id="fileName" className="upload-file-input me-auto">
                      {attachFileName ? attachFileName : t('text:No_file_attached')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Collateral_Settings')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormSelect
                label={t('text:Collateral_Type')}
                selectOptions={getSelectOptions<SUPPORTED_COLLATERAL_TYPE>('SUPPORTED_COLLATERAL_TYPE')}
                name="supportedCollateralType"
                ref={registFormRegister}
                required
                col={3}
                className="information-form__select"
                error={registFormErrors.supportedCollateralType}
              />
            </div>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_registration_of_invoices_that_mature_today?')}
                error={registFormErrors.settlementDateIsTodayInvoiceUploadable}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="settlementDateIsTodayInvoiceUploadable"
                  value="true"
                  ref={registFormRegister}
                />
                <FormRadio
                  label={t('text:No')}
                  name="settlementDateIsTodayInvoiceUploadable"
                  value="false"
                  ref={registFormRegister}
                  onChange={setInvoiceWillBeSettledOnUploadDate}
                />
              </FormRadioWrap>
              <FormRadioWrap
                label={t('text:Allow_to_fix_the_payment_date_of_invoice_to_the_registration_date?')}
                error={registFormErrors.invoiceWillBeSettledOnUploadDate}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="invoiceWillBeSettledOnUploadDate"
                  value="true"
                  ref={registFormRegister}
                  disabled={settlementDateIsTodayInvoiceUploadable !== 'true'}
                />
                <FormRadio
                  label={t('text:No')}
                  name="invoiceWillBeSettledOnUploadDate"
                  value="false"
                  ref={registFormRegister}
                  disabled={settlementDateIsTodayInvoiceUploadable !== 'true'}
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_anchor_to_register_potential_partners?')}
                error={registFormErrors.potentialPartnerRegistrable}
                required
              >
                <FormRadio
                  label={t('text:Yes')}
                  name="potentialPartnerRegistrable"
                  value="true"
                  ref={registFormRegister}
                />
                <FormRadio
                  label={t('text:No')}
                  name="potentialPartnerRegistrable"
                  value="false"
                  ref={registFormRegister}
                />
              </FormRadioWrap>
              <div className="col-6">
                <label className="information-form__label star">
                  {t('text:How_many_days_until_settlement_must_be_remaining_when_registering_AR?')}
                </label>
                <div className="d-flex mt-3">
                  <NumericFormatInput
                    control={control}
                    name="minimumPeriodForRegisterAr"
                    numberType="integer"
                    className="information-form__input"
                    col={5}
                    placeholder={t('text:Minimum_required_days')}
                    error={registFormErrors.minimumPeriodForRegisterAr}
                  />
                  <div className="me-3" />
                  <NumericFormatInput
                    control={control}
                    name="maximumPeriodForRegisterAr"
                    numberType="integer"
                    className="information-form__input"
                    col={5}
                    placeholder={t('text:Maximum_required_days')}
                    error={registFormErrors.maximumPeriodForRegisterAr}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <FormRadioWrap
                label={t('text:Allow_the_financier_to_register_business_divisions_on_the_platform?')}
                error={registFormErrors.divisionRegistrable}
                required
              >
                <FormRadio label={t('text:Yes')} name="divisionRegistrable" value="true" ref={registFormRegister} />
                <FormRadio
                  label={t('text:No')}
                  name="divisionRegistrable"
                  value="false"
                  ref={registFormRegister}
                  defaultChecked
                />
              </FormRadioWrap>
              <FormRadioWrap
                label={t('text:Select_Vendor_Financing_Method')}
                error={registFormErrors.factoringEnable}
                required
              >
                <FormRadio
                  label={t('text:Loan')}
                  name="factoringEnable"
                  value="false"
                  ref={registFormRegister}
                  defaultChecked
                />
                <FormRadio
                  label={t('text:Factoring')}
                  name="factoringEnable"
                  value="true"
                  ref={registFormRegister}
                  disabled={supportedCollateralType === 'INVOICE'}
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Digital_Signature')} error={registFormErrors.eSignatureEnable} required>
                <FormRadio label={t('text:Applied')} name="eSignatureEnable" value="true" ref={registFormRegister} />
                <FormRadio
                  label={t('text:Not_Applied')}
                  name="eSignatureEnable"
                  value="false"
                  ref={registFormRegister}
                  defaultChecked
                />
              </FormRadioWrap>
              <FormRadioWrap
                label={t('text:Fees_are_charged_to_AR ')}
                error={registFormErrors.eSignatureEnable}
                required
              >
                <FormRadio label={t('text:Applied')} name="arCommissionEnable" value="true" ref={registFormRegister} />
                <FormRadio
                  label={t('text:Not_Applied')}
                  name="arCommissionEnable"
                  value="false"
                  ref={registFormRegister}
                  defaultChecked
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormRadioWrap label={t('text:Allow_anchor_to_financing?')} error={registFormErrors.anchorLoan} required>
                <FormRadio label={t('text:Yes')} name="anchorLoan" value="true" ref={registFormRegister} />
                <FormRadio label={t('text:No')} name="anchorLoan" value="false" ref={registFormRegister} />
              </FormRadioWrap>
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Business_Day_Settings')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <div className="col-12">
                <div className="information-form__label">
                  {t('text:Option_to_change_the_base_date_to_a_business_day_if_is_a_holiday')}
                </div>
              </div>
            </div>
            <div className="row">
              <FormSelect
                label={t('text:Disbursement_Date')}
                selectOptions={getSelectOptions<string>('DATE_BUSINESS_DAY_CONVENTION')}
                name="disburseDateBusinessDayConvention"
                ref={registFormRegister}
                error={registFormErrors.disburseDateBusinessDayConvention}
                required
              />
              <FormSelect
                label={t('text:Repayment_Date')}
                selectOptions={getSelectOptions<string>('DATE_BUSINESS_DAY_CONVENTION')}
                name="repaymentDateBusinessDayConvention"
                ref={registFormRegister}
                error={registFormErrors.repaymentDateBusinessDayConvention}
                required
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:OTP_Settings')} />
        <FormBorder editable={true}>
          <FormContents>
            <div className="row">
              <FormSelect
                label={t('text:OTP_Type')}
                selectOptions={getSelectOptions<OTP_TYPE>('OTP_TYPE')}
                name="otpType"
                ref={registFormRegister}
                required
                error={registFormErrors.otpType}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Currency_Settings')} />
        <FormBorder editable={true}>
          <FormContents>
            <div className="d-flex align-items-center flex-wrap">
              <input
                type="hidden"
                value={supportedCurrencyList}
                name="supportedCurrencyTypes"
                ref={registFormRegister}
                disabled
                required
              />
              {Object.values(CURRENCY_TYPE).map((currencyType: CURRENCY_TYPE, index: number) => {
                if (currencyType === '') return null;

                return (
                  <ChoiceChip
                    key={index}
                    label={currencyType}
                    onSelect={() => onSelectCurrencyType(currencyType)}
                    onUnSelect={() => onUnSelectCurrencyType(currencyType)}
                    icon={{ leftIcon: <FontAwesomeIcon icon={faCheck} /> }}
                  />
                );
              })}
              {registFormErrors.supportedCurrencyTypes && (
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon ms-2" />
              )}
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Platform_Settings')} />
        <FormBorder editable={true}>
          <FormContents>{renderPlatformSettingBox()}</FormContents>
        </FormBorder>
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancel}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onClickRegister} className="ms-2">
            {t('text:Register')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default SystemFinancierRegister;
