import { useTranslation } from 'react-i18next';

import type { SidebarMenuInfoType } from 'components/stateless/Navigation/Sidebar/useSidebarController';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import {
  getAnchorLoanEnable,
  getCollateralType as getCollateralTypeFromLocalStorage,
  getFactoringEnable,
  getLoanRepaymentAggregationEnabled,
  getPotentialPartnerRegistrable,
  getSignIn,
} from 'utils/storage/LocalStorage';

export function acMenuInfo() {
  const { t } = useTranslation(['format']);
  const signInInfo = getSignIn();
  const collateralType = getCollateralTypeFromLocalStorage();
  const isInvoice = collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE;
  const isAr = collateralType === SUPPORTED_COLLATERAL_TYPE.AR;
  const isEnabledLoanRepaymentAggregation = getLoanRepaymentAggregationEnabled();
  const isAdmin =
    signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN || signInInfo?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR;
  const isFactoringEnable = getFactoringEnable();
  const anchorLoanEnable = getAnchorLoanEnable();

  const menuInfo: Array<SidebarMenuInfoType> = [
    {
      mainMenu: { title: t('text:Home'), path: ROUTES_AC.DASHBOARD },
    },
    {
      mainMenu: {
        title: t('text:Manage_Supplier'),
        hidden: !(!isInvoice && getPotentialPartnerRegistrable() && isAdmin),
      },
      subMenu: [
        { title: t('text:Registration'), path: ROUTES_AC.MANAGE_PARTNER.REGISTRATION_LIST },
        { title: t('text:Bank_Account'), path: ROUTES_AC.MANAGE_PARTNER.BANK_ACCOUNT_LIST },
        { title: t('text:Company_Info'), path: ROUTES_AC.MANAGE_PARTNER.COMPANY_INFO_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Manage_Dealer'),
        hidden: isAr,
      },
      subMenu: [
        {
          title: t('text:Registration'),
          path: ROUTES_AC.MANAGE_DEALER.REGISTRATION_LIST,
        },
        { title: t('text:Company_Info'), path: ROUTES_AC.MANAGE_DEALER.COMPANY_INFO_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Register_Invoices'),
        hidden: isAr,
      },
      subMenu: [
        {
          title: t('text:Registration_By_Partner'),
          path: ROUTES_AC.REGISTER_INVOICE.REGISTRATION_BY_PARTNER_STEP1,
        },
        { title: t('text:Registration'), path: ROUTES_AC.REGISTER_INVOICE.REGISTRATION_STEP1 },
        { title: t('text:Confirmation'), path: ROUTES_AC.REGISTER_INVOICE.CONFIRMATION_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Register_AP'),
        hidden: !(!isInvoice && isAdmin),
      },
      subMenu: [
        { title: t('text:Registration'), path: ROUTES_AC.REGISTER_AP.REGISTRATION_STEP1 },
        { title: t('text:Confirmation'), path: ROUTES_AC.REGISTER_AP.CONFIRMATION_LIST },
      ],
    },

    {
      mainMenu: {
        title: t('text:View_Transactions'),
      },
      subMenu: [
        { title: t('text:Invoice'), path: ROUTES_AC.VIEW_TRANSACTION.INVOICE_LIST, hidden: isAr },
        { title: t('text:Invoice_Payment'), path: ROUTES_AC.VIEW_TRANSACTION.INVOICE_PAYMENT_LIST, hidden: isAr },
        { title: t('text:AP'), path: ROUTES_AC.VIEW_TRANSACTION.AP_LIST, hidden: !(!isInvoice && isAdmin) },
        {
          title: t('text:AP_Settlement'),
          path: ROUTES_AC.VIEW_TRANSACTION.AP_SETTLEMENT_LIST,
          hidden: !(!isInvoice && isAdmin),
        },
        {
          title: t('text:Financing'),
          path: ROUTES_AC.VIEW_TRANSACTION.FINANCING_LIST,
        },
        {
          title: t('text:Anchor_Financing'),
          path: ROUTES_AC.VIEW_TRANSACTION.ANCHOR_FINANCING_LIST,
          hidden: !anchorLoanEnable,
        },
        {
          title: t('text:Interest_Payment'),
          path: ROUTES_AC.VIEW_TRANSACTION.INTEREST_PAYMENT_LIST,
          hidden: !(!isAr && isEnabledLoanRepaymentAggregation),
        },
        {
          title: t('text:Overview_by_Partner'),
          path: ROUTES_AC.VIEW_TRANSACTION.OVERVIEW_BY_PARTNER_LIST,
        },
        {
          title: t('text:Confirmation_Notification'),
          path: ROUTES_AC.VIEW_TRANSACTION.DEPT_SELLING_NOTICE_LIST,
          hidden: !isFactoringEnable || !isAdmin,
        },
      ],
    },
    {
      mainMenu: {
        title: t('text:Manage_Anchor_Financing'),
        hidden: !anchorLoanEnable,
      },
      subMenu: [
        { title: t('text:Application'), path: ROUTES_AC.MANAGE_ANCHOR_FINANCING.APPLICATION_LIST_STEP1 },
        { title: t('text:Confirmation'), path: ROUTES_AC.MANAGE_ANCHOR_FINANCING.BULK_FINANCING_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Company'),
      },
      subMenu: [
        { title: t('text:User'), path: ROUTES_AC.COMPANY.USER_LIST, hidden: isInvoice },
        { title: t('text:Agreement'), path: ROUTES_AC.COMPANY.AGREEMENT_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:My_Account'),
        path: ROUTES_AC.MY_ACCOUNT.ACCOUNT_SETTINGS,
      },
    },
  ];

  return { menuInfo };
}
