import { PDF } from 'digital-signatures/bkav/PDF';
import { FILE_TYPE } from 'digital-signatures/enums';
import { PDFDocument } from 'pdf-lib';

import { ROLE } from 'enums';
import { requestServerTime } from 'utils/http/api/common/time';
import { unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import type { ModalContextProps } from 'utils/modal/ModalContext';
import { getSignIn } from 'utils/storage/LocalStorage';

export async function SignBase64PDF(PDFInfo: string, fileType: FILE_TYPE, modal: ModalContextProps, cb: Function) {
  const signer = getSignIn()?.authorities[0].authority;
  const selectCert = document.getElementById('selectCert') as HTMLSelectElement;
  const selectedSerial = document.getElementById('selectCertSerial') as HTMLInputElement;

  if (!(selectCert && selectCert.options[selectCert.selectedIndex].value)) {
    unShowLoadingUI();
    modal.show(<h6>Certification not selected.</h6>);

    return;
  }

  const serial = selectedSerial.value.substring('Số serial: '.length, selectedSerial.value.length);
  const signerName = selectCert.value;
  const urlIcon = '';
  let offsetX = 0;
  let offsetY = 0;

  if (fileType === FILE_TYPE.APPLICATION_FORM) {
    if (signer === ROLE.ROLE_FINANCIER) {
      offsetX = 10;
      offsetY = 222;
    } else {
      offsetX = 300;
      offsetY = 262;
    }
  } else if (fileType === FILE_TYPE.APPLICATION_NOTICE) {
    if (signer === ROLE.ROLE_FINANCIER) {
      offsetX = 10;
      offsetY = 113;
    } else {
      offsetX = 300;
      offsetY = 153;
    }
  }

  const pdfDoc = await PDFDocument.load(PDFInfo);
  // 페이지 수를 얻습니다.
  const pageCount = pdfDoc.getPageCount();

  try {
    const serverTime = await requestServerTime();
    PDF.SignPDF(
      PDFInfo,
      '',
      serial,
      signerName,
      process.env.REACT_APP_DIGITAL_SIGNATURE_LICENSE_KEY,
      urlIcon,
      offsetX,
      offsetY,
      pageCount,
      serverTime,
      cb,
    );
  } catch (e) {
    modal.show(e);
  }
}
