import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import { AUTHORITY_TYPE, COLLATERAL_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import type { DivisionVO } from 'models/vo/DivisionVO';
import type { FinancierClientHistoryVOModel } from 'models/vo/FinancierClientHistoryVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestSystemAnchorAgreementList } from 'utils/http/api/system/anchor-agreements';
import { requestSystemAnchorUserList } from 'utils/http/api/system/anchor-users';
import { requestSystemDivisions } from 'utils/http/api/system/divisions';
import { requestSystemEnterpriseFinancierAuthSetting } from 'utils/http/api/system/enterprises';
import { requestSyFinancierClientHistories } from 'utils/http/api/system/financier-client-histories';
import { requestSystemFinancierClientData } from 'utils/http/api/system/financier-clients';
import useModal from 'utils/modal/useModal';

const getConstants = (isOTPUsed: boolean) => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);
  const DIVISION_LIST_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Division_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Division_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Limit_Amount'),
      colWidths: 120,
    },
  ];
  const SEARCHED_ANCHOR_MASTER_AGREEMENT_PAGE_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
    },
    {
      headerText: t('text:Responsible_Branch_Code'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Expiration_Date'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];
  const SEARCHED_ANCHOR_USER_PAGE_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:User_Code'),
    },
    {
      headerText: t('text:User_Name'),
    },
    {
      headerText: t('text:Email'),
    },
    {
      headerText: t('text:Authority'),
    },
    {
      headerText: t('text:User_Account_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];
  const SEARCHED_ANCHOR_COMPANY_INFORMATION_EDIT_HISTORY_PAGE_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Client_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Company_Registration_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 120,
    },
    {
      headerText: t('text:Fax'),
      colWidths: 120,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 200,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Legal_Representative_Title'),
      colWidths: 120,
    },
    {
      headerText: t('text:Legal_Representative_Email'),
      colWidths: 120,
    },
  ];

  if (isOTPUsed) {
    SEARCHED_ANCHOR_COMPANY_INFORMATION_EDIT_HISTORY_PAGE_TABLE_HEADERS.push({
      headerText: t('text:OTP_Verification'),
      colWidths: 120,
    });
  }

  return {
    mounted,
    modal,
    history,
    t,
    DIVISION_LIST_HEADERS,
    SEARCHED_ANCHOR_MASTER_AGREEMENT_PAGE_TABLE_HEADERS,
    SEARCHED_ANCHOR_USER_PAGE_TABLE_HEADERS,
    SEARCHED_ANCHOR_COMPANY_INFORMATION_EDIT_HISTORY_PAGE_TABLE_HEADERS,
  };
};

interface AnchorInformationType extends Omit<FinancierClientVOModel, 'otpBypassed'> {
  otpBypassed: string;
}

function SystemMonitorAnchorCompanyDetail() {
  const { financierClientId } = useParams() as any;
  const [anchorInformation, setAnchorInformation] = useState<AnchorInformationType>();
  const [divisionPage, setDivisionPage] = useState<Pageable<DivisionVO[]>>();
  const [anchorAgreementPage, setAnchorAgreementPage] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const [anchorUserPage, setAnchorUserPage] = useState<Pageable<AnchorUserVOModel[]>>();
  const [anchorHistoryPage, setAnchorHistoryPage] = useState<Pageable<FinancierClientHistoryVOModel[]>>();
  const [divisionRegistrable, setDivisionRegistrable] = useState(false);
  const { pageable: divisionPageable, setPageable: setDivisionPageable } = usePageable();
  const { pageable: anchorAgreementPageable, setPageable: setAnchorAgreementPageable } = usePageable();
  const { pageable: anchorUserPageable, setPageable: setAnchorUserPageable } = usePageable();
  const { pageable: anchorHistoryPageable, setPageable: setAnchorHistoryPageable } = usePageable();
  const [isOTPUsed, setIsOTPUsed] = useState(false);
  const {
    mounted,
    modal,
    t,
    DIVISION_LIST_HEADERS,
    SEARCHED_ANCHOR_MASTER_AGREEMENT_PAGE_TABLE_HEADERS,
    SEARCHED_ANCHOR_USER_PAGE_TABLE_HEADERS,
    SEARCHED_ANCHOR_COMPANY_INFORMATION_EDIT_HISTORY_PAGE_TABLE_HEADERS,
  } = getConstants(isOTPUsed);

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [anchorData, divisionPageResponse, anchorAgreementPage, anchorUserPage, anchorHistoryPage] =
        await Promise.all([
          requestSystemFinancierClientData(financierClientId),
          requestSystemDivisions(divisionPageable.currentPage, divisionPageable.sizePerPage, {
            anchorClientId: financierClientId,
          }),
          requestSystemAnchorAgreementList(anchorAgreementPageable.currentPage, anchorAgreementPageable.sizePerPage, {
            anchorClientId: financierClientId,
          }),
          requestSystemAnchorUserList(anchorUserPageable.currentPage, anchorUserPageable.sizePerPage, {
            anchorClientId: financierClientId,
          }),
          requestSyFinancierClientHistories(
            anchorHistoryPageable.currentPage,
            anchorHistoryPageable.sizePerPage,
            financierClientId,
          ),
        ]);

      const { divisionRegistrable } = await requestFinancierSettingData(anchorData.financierId);
      const { otpType } = await requestSystemEnterpriseFinancierAuthSetting(anchorData.financierId);

      const anchorInformation = {
        ...anchorData,
        otpBypassed: anchorData?.otpBypassed ? t('text:Not_Applied') : t('text:Applied'),
      };

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorInformation(anchorInformation);
        setDivisionPage(divisionPageResponse);
        setAnchorAgreementPage(anchorAgreementPage);
        setAnchorUserPage(anchorUserPage);
        setAnchorHistoryPage(anchorHistoryPage);
        setDivisionRegistrable(divisionRegistrable);

        setDivisionPageable(divisionPageResponse);
        setAnchorAgreementPageable(anchorAgreementPage);
        setAnchorUserPageable(anchorUserPage);
        setAnchorHistoryPageable(anchorHistoryPage);
        setIsOTPUsed(otpType !== OTP_TYPE.NONE);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const divisionPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const divisionPageResponse = await requestSystemDivisions(pageNumber, rowCount, {
        anchorClientId: financierClientId,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setDivisionPage(divisionPageResponse);
        setDivisionPageable(divisionPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const anchorAgreementPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const anchorAgreementPage = await requestSystemAnchorAgreementList(pageNumber, rowCount, {
        anchorClientId: financierClientId,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementPage(anchorAgreementPage);
        setAnchorAgreementPageable(anchorAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const anchorUserPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const anchorUserPage = await requestSystemAnchorUserList(pageNumber, rowCount, {
        anchorClientId: financierClientId,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorUserPage(anchorUserPage);
        setAnchorUserPageable(anchorUserPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const anchorHistoryPaginate = async (pageNumber: number, rowCount: number): Promise<void> => {
    try {
      const anchorHistoryPage = await requestSyFinancierClientHistories(pageNumber, rowCount, financierClientId);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorHistoryPage(anchorHistoryPage);
        setAnchorHistoryPageable(anchorHistoryPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderCompanyDivisionsTable = () => {
    return divisionPage?.content.map((division, index) => (
      <Tr key={index}>
        <Td data={division.code} />
        <Td data={division.name} />
        <Td data={division.limitAllowable ? t('text:Applied') : t('text:Not_Applied')} />
        <Td>
          {division.limitAllowable
            ? `${t('format:number', { value: division.limitAmount })} ${division.limitCurrencyType}`
            : '-'}
        </Td>
      </Tr>
    ));
  };

  const renderAnchorAgreementTableBody = (): JSX.Element[] | undefined => {
    const getProgramTypeText = (collateralType: COLLATERAL_TYPE): string => {
      switch (collateralType) {
        case COLLATERAL_TYPE.AR:
          return t('text:Vendor_Finance');
        case COLLATERAL_TYPE.INVOICE:
          return t('text:Dealer_Finance');
      }
    };

    return anchorAgreementPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={getProgramTypeText(item.collateralType)} />
        <Td data={item.contractNo} />
        <Td data={item.currencyType} />
        <Td data={item.branchName} />
        <Td data={item.branchCode} />
        <Td data={item.startDate} format="date" />
        <Td data={item.expiryDate} format="date" />
        <TdLink
          path={ROUTES_SY.MONITOR_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.anchorAgreementId)}
          state={{ supportedCollateralType: item.collateralType }}
        />
      </Tr>
    ));
  };

  const renderAnchorUserTableBody = (): JSX.Element[] | undefined => {
    const getAuthorityType = (authorityType: AUTHORITY_TYPE): string => {
      switch (authorityType) {
        case AUTHORITY_TYPE.OPERATOR:
          return t('text:Operator');
        case AUTHORITY_TYPE.AUTHORIZER:
          return t('text:Authorizer');
        case AUTHORITY_TYPE.ADMIN:
          return t('text:Admin');
        case AUTHORITY_TYPE.HQ_OPERATOR:
          return t('text:Admin_Operator');
      }
    };

    return anchorUserPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.bankUserId} />
        <Td data={item.name} />
        <Td data={item.email} />
        <Td data={getAuthorityType(item.authorityType)} />
        <Td
          className={getStatusTextClass('USER_STATUS', item?.userStatus)}
          data={t(`code:user-status.${item.userStatus}`)}
        />
        <TdLink path={ROUTES_SY.MONITOR_ANCHOR.USER_REGISTERED_DETAIL_BUILD_PATH(item.id)} />
      </Tr>
    ));
  };

  const renderAnchorHistoryTableBody = (): JSX.Element[] | undefined => {
    return anchorHistoryPage?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.updatedDateTime} format="datetime" />
        <Td data={item.financierClientCode} />
        <Td data={item.name} />
        <Td data={item.clientTaxCode} />
        <Td data={item.clientBusinessCode} />
        <Td data={item.telephone} />
        <Td data={item.fax} />
        <Td data={item.address} />
        <Td data={item.representativeName} />
        <Td data={item.representativePosition} />
        <Td data={item.representativeEmail} />
        {isOTPUsed && <Td data={item.otyBypassed ? t('text:Not_Applied') : t('text:Applied')} />}
      </Tr>
    ));
  };

  return (
    <>
      <BackHeaderTitle title={anchorInformation?.financierClientName} />
      <div className="content-area">
        <SectionTitle title={t('text:Anchor_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Anchor_Client_Code')} value={anchorInformation?.financierClientCode} />
              <FormValue label={t('text:Anchor_Name')} value={anchorInformation?.financierClientName} />
            </div>
            <div className="row">
              <FormValue col={3} label={t('text:Tax_Code')} value={anchorInformation?.financierClientTaxCode} />
              <FormValue
                col={3}
                label={t('text:Company_Registration_Number')}
                value={anchorInformation?.financierClientBusinessCode}
              />
              <FormValue col={3} label={t('text:Telephone')} value={anchorInformation?.financierClientTelephone} />
              <FormValue col={3} label={t('text:Fax')} value={anchorInformation?.financierClientFax} />
            </div>
            <div className="row">
              <FormValue
                col={isOTPUsed ? 3 : 6}
                label={t('text:Legal_Representative_Name')}
                value={anchorInformation?.representativeName}
              />
              <FormValue
                col={3}
                label={t('text:Legal_Representative_Title')}
                value={anchorInformation?.representativePosition}
              />
              <FormValue
                col={3}
                label={t('text:Legal_Representative_Email')}
                value={anchorInformation?.representativeEmail}
              />
              {isOTPUsed && (
                <FormValue col={3} label={t('text:OTP_Verification')} value={anchorInformation?.otpBypassed} />
              )}
            </div>
            <div className="row">
              <FormValue
                col={12}
                label={t('text:Registered_Office_Address')}
                value={anchorInformation?.financierClientAddress}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      {divisionRegistrable && (
        <div className="content-area">
          <SectionTitle title={t('text:Company_Division')} />
          <TableBorder>
            <TableHeader header={DIVISION_LIST_HEADERS} />
            <TableBody numOfCol={DIVISION_LIST_HEADERS.length}>{renderCompanyDivisionsTable()}</TableBody>
          </TableBorder>
          <Pagination pageable={divisionPageable} paginate={divisionPaginate} />
        </div>
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Master_Agreement_List')} />
        <TableBorder>
          <TableHeader header={SEARCHED_ANCHOR_MASTER_AGREEMENT_PAGE_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_ANCHOR_MASTER_AGREEMENT_PAGE_TABLE_HEADERS.length}>
            {renderAnchorAgreementTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={anchorAgreementPageable} paginate={anchorAgreementPaginate} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Anchor_User_List')} />
        <TableBorder>
          <TableHeader header={SEARCHED_ANCHOR_USER_PAGE_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_ANCHOR_USER_PAGE_TABLE_HEADERS.length}>{renderAnchorUserTableBody()}</TableBody>
        </TableBorder>
        <Pagination pageable={anchorUserPageable} paginate={anchorUserPaginate} />
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={SEARCHED_ANCHOR_COMPANY_INFORMATION_EDIT_HISTORY_PAGE_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_ANCHOR_COMPANY_INFORMATION_EDIT_HISTORY_PAGE_TABLE_HEADERS.length}>
            {renderAnchorHistoryTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={anchorHistoryPageable} paginate={anchorHistoryPaginate} />
      </div>
    </>
  );
}

export default SystemMonitorAnchorCompanyDetail;
