import type Pageable from 'models/Pageable';
import type { AnchorReportReceiverVO, AnchorReportReceiverVOModel } from 'models/vo/AnchorReportReceiverVO';
import { formattingToAnchorReportReceiverVOModel } from 'models/vo/AnchorReportReceiverVO';
import type { DealerAgreementVO, DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import { formattingToDealerAgreementVOModel } from 'models/vo/DealerAgreementVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FiAnchorReportReceiverAgreementRequest,
  FiAnchorReportReceiverListRequest,
  FiAnchorReportReceiverRegistRequest,
  FiAnchorReportReceiverRelatedAgreementListRequest,
  FiAnchorReportReceiverUpdateRequest,
} from './requests';

export async function requestFiAnchorReportReceiverList(
  pageNumber: number,
  rowCount: number,
  data: FiAnchorReportReceiverListRequest,
): Promise<Pageable<AnchorReportReceiverVOModel[]>> {
  const response = await http.get<Pageable<AnchorReportReceiverVO[]>>({
    url: API_FI.ANCHOR_REPORT_RECEIVERS.ANCHOR_REPORT_RECEIVERS,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorReportReceiverVOModel(data)),
  };
}

export async function requestFiAnchorReportReceiverRegistration(
  data: FiAnchorReportReceiverRegistRequest,
): Promise<AnchorReportReceiverVOModel> {
  const response = await http.post<AnchorReportReceiverVO>({
    url: API_FI.ANCHOR_REPORT_RECEIVERS.ANCHOR_REPORT_RECEIVERS,
    data,
  });

  return formattingToAnchorReportReceiverVOModel(response);
}

export async function requestFiAnchorReportReceiverDetail(
  anchorReportReceiverId: number,
): Promise<AnchorReportReceiverVOModel> {
  const response = await http.get<AnchorReportReceiverVO>({
    url: API_FI.ANCHOR_REPORT_RECEIVERS.ANCHOR_REPORT_RECEIVER_DETAIL(anchorReportReceiverId),
  });

  return formattingToAnchorReportReceiverVOModel(response);
}

export async function requestFiAnchorReportReceiverUpdate(
  anchorReportReceiverId: number,
  data: FiAnchorReportReceiverUpdateRequest,
): Promise<AnchorReportReceiverVOModel> {
  const response = await http.put<AnchorReportReceiverVO>({
    url: API_FI.ANCHOR_REPORT_RECEIVERS.ANCHOR_REPORT_RECEIVER_DETAIL(anchorReportReceiverId),
    data,
  });

  return formattingToAnchorReportReceiverVOModel(response);
}

export async function requestFiAnchorReportReceiverRelatedAgreementList(
  pageNumber: number,
  rowCount: number,
  anchorReportReceiverId: number,
  data?: FiAnchorReportReceiverRelatedAgreementListRequest,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_FI.ANCHOR_REPORT_RECEIVERS.DEALER_AGREEMENT_LIST_RELATED_ANCHOR_REPORT_RECEIVER(anchorReportReceiverId),
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDealerAgreementVOModel(item)),
  };
}

export async function requestFiAnchorReportReceiverNotRelatedAgreementList(
  pageNumber: number,
  rowCount: number,
  anchorReportReceiverId: number,
  data: FiAnchorReportReceiverRelatedAgreementListRequest,
): Promise<Pageable<DealerAgreementVOModel[]>> {
  const response = await http.get<Pageable<DealerAgreementVO[]>>({
    url: API_FI.ANCHOR_REPORT_RECEIVERS.DEALER_AGREEMENT_LIST_NOT_RELATED_ANCHOR_REPORT_RECEIVER(
      anchorReportReceiverId,
    ),
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToDealerAgreementVOModel(item)),
  };
}

export async function requestFiAnchorReportReceiverAssignAgreement(
  anchorReportReceiverId: number,
  data: FiAnchorReportReceiverAgreementRequest,
): Promise<void> {
  return await http.put({
    url: API_FI.ANCHOR_REPORT_RECEIVERS.ASSIGN_DEALER_AGREEMENT(anchorReportReceiverId),
    data,
  });
}

export async function requestFiAnchorReportReceiverReleaseAgreement(
  anchorReportReceiverId: number,
  data: FiAnchorReportReceiverAgreementRequest,
): Promise<void> {
  return await http.put({
    url: API_FI.ANCHOR_REPORT_RECEIVERS.RELEASE_DEALER_AGREEMENT(anchorReportReceiverId),
    data,
  });
}
