import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { isBoolean } from 'lodash-es';

import { ROLE } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import { requestNewUserNotification } from 'utils/http/api/common/notifications';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

const useNewNotification = () => {
  const [hasNewNotification, setHasNewNotification] = useState(false);

  const history = useHistory();
  const pathnameRef = useRef(history.location.pathname);
  const { show: showModal } = useModal();

  const updateHasNewNotification = useCallback(async () => {
    const signInModel: SignInModel | null = getSignIn();
    const role = signInModel?.authorities[0].authority;

    if (!role || role === ROLE.ROLE_SYSTEM) return;

    try {
      const hasNewNotification = await requestNewUserNotification();
      if (isBoolean(hasNewNotification)) setHasNewNotification(hasNewNotification);
    } catch (error) {
      showModal(error);
    }
  }, [showModal]);

  useLayoutEffect(() => {
    updateHasNewNotification();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') return;
      updateHasNewNotification();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange, false);
  }, [updateHasNewNotification]);

  useLayoutEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathnameRef.current === pathname) return;
      pathnameRef.current = pathname;
      updateHasNewNotification();
    });

    return unlisten;
  }, [history, updateHasNewNotification]);

  return { hasNewNotification, updateHasNewNotification };
};

export default useNewNotification;
