import type React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder, FormContents, FormInput, FormSelect } from 'components/stateless/CommonForm';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, DOCUMENT_FORM_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierRegisterDocument } from 'utils/http/api/financier/financier-document-forms';
import type { FinancierDocumentRegisterRequest } from 'utils/http/api/financier/financier-document-forms/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

function FinancierDocumentRegister() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();

  const signInInfo = getSignIn();
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;

  const { register, handleSubmit, errors, setError, clearErrors, getValues } =
    useForm<FinancierDocumentRegisterRequest>({
      mode: 'onSubmit',
      shouldFocusError: true,
    });
  const [fileName, setFileName] = useState<string | null>(null);
  const getDocumentFormProperty = useProperty<FinancierDocumentRegisterRequest>();

  useEffect(() => {
    if (mounted) {
      if (!isAdmin) history.goBack();
    }
  }, [mounted]);

  const goToDocumentListPage = () => {
    history.push(ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_LIST);
  };

  const onSubmitFile = async (data: FinancierDocumentRegisterRequest) => {
    if (getValues('formAttachment')?.length === 0) return modal.show(<h6>{t('text:Attach_the_template_file')}</h6>);

    try {
      await requestFinancierRegisterDocument(data);
      goToDocumentListPage();
    } catch (e: any) {
      formErrorHandler<FinancierDocumentRegisterRequest>(e, setError, clearErrors);

      modal.show(e);
    }
  };

  const renderDocumentInformation = (): JSX.Element => {
    return (
      <>
        <SectionTitle title={t(`text:Template_Information`)} />
        <FormBorder editable>
          <FormContents>
            <div className="row">
              <FormInput
                col={12}
                label={t(`text:Template_Name`)}
                name={getDocumentFormProperty('documentFormName')}
                requiredOptions={{ required: true }}
                ref={register}
                error={errors.documentFormName}
              />
            </div>
            <div className="row">
              <FormSelect
                label={t('text:Template_Type')}
                name={getDocumentFormProperty('documentFormType')}
                selectOptions={getSelectOptions<DOCUMENT_FORM_TYPE>('DOCUMENT_FORM_TYPE', [
                  DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_AGREEMENT,
                  DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_REQUEST,
                  DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_AGREEMENT,
                  DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_REQUEST,
                  DOCUMENT_FORM_TYPE.BULK_TRANSFER,
                  DOCUMENT_FORM_TYPE.REMITTANCE,
                ])}
                ref={register}
                required
                placeholderOptions={{ show: true }}
                error={errors.documentFormType}
              />
            </div>
            <div className="row">
              <FormInput
                col={12}
                label={t(`text:Description`)}
                name={getDocumentFormProperty('description')}
                ref={register}
                error={errors.description}
              />
            </div>
          </FormContents>
        </FormBorder>
      </>
    );
  };

  const renderFileUploadForm = (): JSX.Element => {
    const setFileNameToUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const [file] = e.target.files;
        if (file?.name) setFileName(file.name);
      }
    };

    return (
      <>
        <SectionTitle title={t(`text:Template_File_Upload`)} />
        <FormBorder editable>
          <div className="detail-in-file-upload-form bg-sub100">
            <div className="d-flex justify-content-between">
              <input
                type="file"
                name={getDocumentFormProperty('formAttachment')}
                id="FileUpload"
                style={{ opacity: 0, width: 0 }}
                onChange={setFileNameToUpload}
                ref={register}
              />
              <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
                {t('text:Attach_File')}
              </label>
              <div id="fileName" className="upload-file-input">
                {fileName ? fileName : t('text:No_file_attached')}
              </div>
            </div>
          </div>
        </FormBorder>
      </>
    );
  };

  const renderCancelSaveButton = (): JSX.Element => {
    const onClickCancel = () => {
      modal.show(<h6>{t('text:Do_you_want_leave_this_page_without_saving?')}</h6>, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: goToDocumentListPage,
      });
    };

    return (
      <div className="flex-end mt-4">
        <Button
          size={ButtonSizeEnum.LG}
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
          onClick={onClickCancel}
        >
          {t('text:Cancel')}
        </Button>
        <Button size={ButtonSizeEnum.LG} className="ms-2" onClick={handleSubmit(onSubmitFile)}>
          {t('text:Save')}
        </Button>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Document_Template_Registration')} />
      <form>
        <div className="content-area">{renderDocumentInformation()}</div>
        <div className="content-area">{renderFileUploadForm()}</div>
        <div className="content-area clearfix">{renderCancelSaveButton()}</div>
      </form>
    </>
  );
}

export default FinancierDocumentRegister;
