import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import { Tr } from 'components/stateless/Table';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AWS_EMAIL_EVENT_TYPE, EMAIL_SENT_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { FactoringNoticeReceiverVOModel } from 'models/vo/FactoringNoticeReceiverVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

interface FinancierDeptSellingNoticeDetailRecipientListProps {
  data?: FactoringNoticeReceiverVOModel[];
  paginate(pageNumber: number, rowCount: number): Promise<void>;
  pageable: PageableType;
  handleClickResendButton(
    factoringNoticeReceiverId: number,
    receiverInfo: FactoringNoticeReceiverVOModel,
  ): Promise<void>;
  handleClickResendAllButton(): Promise<void>;
}

function FinancierDeptSellingNoticeDetailRecipientList({
  data,
  paginate,
  pageable,
  handleClickResendButton,
  handleClickResendAllButton,
}: FinancierDeptSellingNoticeDetailRecipientListProps) {
  const { t } = useTranslation();
  const modal = useModal();

  const RECIPIENT_LIST_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:User_Name'),
    },
    {
      headerText: t('text:Authority'),
    },
    {
      headerText: t('text:User_Email_Address'),
    },
    {
      headerText: t('text:Receiving_Email_Address'),
    },
    {
      headerText: t('text:Reception_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: () => {
        modal.show(
          <StatusDescriptionModal
            statusDescriptionEnum={Object.values(EMAIL_SENT_STATUS)}
            statusDescriptionModalType="TEXT"
            statusDescriptionEnumType="EMAIL_SENT_STATUS"
          />,
          {
            modalType: ModalType.ALERT,
            modalSize: ModalSize.XL,
            closeBtnText: t('text:Close'),
          },
        );
      },
    },
    {
      headerText: t('text:Sent_Time'),
    },
    {
      headerText: t('text:Reason_for_Failure'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: () => {
        modal.show(
          <StatusDescriptionModal
            statusDescriptionEnum={[
              AWS_EMAIL_EVENT_TYPE.BOUNCE,
              AWS_EMAIL_EVENT_TYPE.NETWORK_ERROR,
              AWS_EMAIL_EVENT_TYPE.MAILBOX_FULL,
              AWS_EMAIL_EVENT_TYPE.MESSAGE_TOO_LARGE,
            ]}
            statusDescriptionModalType="TEXT"
            statusDescriptionEnumType="AWS_EMAIL_EVENT_TYPE"
          />,
          {
            modalType: ModalType.ALERT,
            modalSize: ModalSize.XL,
            closeBtnText: t('text:Close'),
          },
        );
      },
    },
    {
      headerText: t('text:Resend'),
    },
  ];

  const isResendButtonDisabled = (receiver: FactoringNoticeReceiverVOModel) => {
    return (
      receiver.emailSentStatus !== EMAIL_SENT_STATUS.FAIL || // 1. 이메일 발송 중, 성공 일때
      (receiver.awsEmailEventType === AWS_EMAIL_EVENT_TYPE.BOUNCE &&
        receiver.currentUserEmailAddress === receiver.recentSentEmailAddress) // 2. 이메일 발송 실패 & BOUNCE(invalid email address) & 현재 유저 이메일 주소 === 보냈던 이메일 주소
    );
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Recipient_List')} />
      <GuideMessage
        message={[
          t('text:You_can_resend_mail_to_users_who_failed_to_receive_it_using_the_Resend_button'),
          t('text:You_can_resend_the_email_to_all_users_who_failed_to_receive_it_by_clicking_the_Resend_All_button'),
        ]}
        isImportContentArea
      >
        {{
          button: (
            <Button bold onClick={handleClickResendAllButton}>
              {t('text:Resend_all')}
            </Button>
          ),
        }}
      </GuideMessage>
      <TableBorder>
        <TableHeader header={RECIPIENT_LIST_TABLE_HEADER} />
        <TableBody numOfCol={RECIPIENT_LIST_TABLE_HEADER.length}>
          {data?.map((item, i) => {
            return (
              <Tr key={i}>
                <Td data={item.userName} />
                <Td data={t(`code:authority-type.${item.authorityType}`)} format="code" />
                <Td data={item.currentUserEmailAddress} />
                <Td data={item.recentSentEmailAddress} />
                <Td
                  className={getStatusTextClass('EMAIL_SENT_STATUS', item.emailSentStatus)}
                  data={t(`code:email-sent-status.${item.emailSentStatus}`)}
                  format="code"
                />
                <Td data={item.sentDateTime} format="datetime" />
                <Td
                  data={
                    item.emailSentStatus === EMAIL_SENT_STATUS.FAIL
                      ? t(`code:aws-email-event-type.${item.awsEmailEventType}`)
                      : '-'
                  }
                  format="code"
                />
                <Td className="text-center">
                  <Button
                    bold
                    onClick={() => handleClickResendButton(item.factoringNoticeReceiverId, item)}
                    disabled={isResendButtonDisabled(item)}
                  >
                    {t('text:Resend')}
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default FinancierDeptSellingNoticeDetailRecipientList;
