import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import type { OverdueInterestRateTermSpreadRequest } from 'utils/http/api/financier/overdue-interest-rate-settings/request';

import { DELINQUENT_INTEREST_RATE_FIELD_NAME } from '.';

export function useDelinquentInterestRateTermSpreadFormController() {
  const methods = useFormContext();

  const { control } = methods;

  const { fields, append, remove } = useFieldArray<OverdueInterestRateTermSpreadRequest>({
    control,
    name: DELINQUENT_INTEREST_RATE_FIELD_NAME,
  });

  const addRow = useCallback(() => {
    append({
      minimumDateRange: null,
      termSpreadRate: null,
    });
  }, [append]);

  const handleAddRowClick = () => addRow();

  const handleDeleteLastRowClick = () => {
    remove(fields.length - 1);
  };

  return {
    methods,
    fields,
    handleAddRowClick,
    handleDeleteLastRowClick,
  };
}
