import type { SignInModel, SignInResponse } from 'models/SignInModel';
import { formattingToSignInModel } from 'models/SignInModel';
import http, { ContentType } from 'utils/http';
import API_CM from 'utils/http/api/common';
import type { SignInRequest } from 'utils/http/api/common/signin/requests';

export async function requestSignIn(data: SignInRequest): Promise<SignInModel> {
  const response = await http.post<SignInResponse>({
    url: API_CM.SIGN_IN.SIGN_IN,
    data,
    contentType: ContentType.URLENCODED,
  });

  return formattingToSignInModel(response);
}

export async function requestSignOut(): Promise<void> {
  await http.post({
    url: API_CM.SIGN_IN.SIGN_OUT,
    contentType: ContentType.URLENCODED,
  });

  return;
}
