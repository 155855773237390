import type Pageable from 'models/Pageable';
import type { InvoiceSummaryVO, InvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import { formattingToInvoiceSummaryVOModel } from 'models/vo/InvoiceSummaryVO';
import type { LastInvoicePhaseVO, LastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import { formattingToLastInvoicePhaseVOModel } from 'models/vo/LastInvoicePhaseVO';
import http from 'utils/http';
import API_DE from 'utils/http/api/dealer';
import type {
  DealerInvoiceApproveInvoicePhaseRequest,
  DealerInvoiceRejectRequest,
  DealerInvoiceSummaryListRequest,
  DealerInvoiceSummaryRegisterRequest,
} from 'utils/http/api/dealer/invoice-summaries/request';

// 송장결재요약 목록 조회
export async function requestDealerInvoiceSummaryList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerInvoiceSummaryListRequest,
): Promise<Pageable<InvoiceSummaryVOModel[]>> {
  const response = await http.get<Pageable<InvoiceSummaryVO[]>>({
    url: API_DE.INVOICE_SUMMARIES.INVOICE_SUMMARY,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const invoiceSummaryList: InvoiceSummaryVOModel[] = response.content.map((data: InvoiceSummaryVO) =>
    formattingToInvoiceSummaryVOModel(data),
  );
  const invoiceSummaryPage: Pageable<InvoiceSummaryVOModel[]> = {
    ...response,
    content: invoiceSummaryList,
  };

  return invoiceSummaryPage;
}

// 송장결재요약 상세 조회
export async function requestDealerInvoiceSummaryDetail(invoiceSummaryId: number): Promise<InvoiceSummaryVOModel> {
  const response = await http.get<InvoiceSummaryVO>({
    url: API_DE.INVOICE_SUMMARIES.INVOICE_SUMMARY_DETAIL(invoiceSummaryId),
  });

  return formattingToInvoiceSummaryVOModel(response);
}

// 수기 송장 등록
export async function requestDealerInvoiceRegister(
  data: DealerInvoiceSummaryRegisterRequest,
): Promise<LastInvoicePhaseVOModel[]> {
  const response = await http.post<LastInvoicePhaseVO[]>({
    url: API_DE.INVOICE_SUMMARIES.INVOICE_SUMMARY,
    data,
  });

  return response.map(item => formattingToLastInvoicePhaseVOModel(item));
}

// 마지막 단계 송장결재 조회
export async function requestDealerInvoiceSummaryLastPhase(invoiceSummaryId: number): Promise<LastInvoicePhaseVOModel> {
  const response = await http.get<LastInvoicePhaseVO>({
    url: API_DE.INVOICE_SUMMARIES.INVOICE_SUMMARY_LAST_PHASE(invoiceSummaryId),
  });

  return formattingToLastInvoicePhaseVOModel(response);
}

// 송장 결재 승인
export async function requestDealerApproveInvoicePhase(
  data: DealerInvoiceApproveInvoicePhaseRequest,
): Promise<LastInvoicePhaseVOModel> {
  const response = await http.post<LastInvoicePhaseVO>({
    url: API_DE.INVOICE_SUMMARIES.APPROVE_INVOICE_PHASE,
    data,
  });

  return formattingToLastInvoicePhaseVOModel(response);
}

// 송장 거절
export async function requestDealerRejectInvoiceRegistration(data: DealerInvoiceRejectRequest) {
  const response = await http.put({
    url: API_DE.INVOICE_SUMMARIES.REJECT_INVOICE_REGISTRATION,
    data,
  });

  return response;
}
