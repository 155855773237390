import './PPSection.scss';

interface PPSectionProps {
  children: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
  paddingType?: 'md' | 'lg';
  className?: string;
}
function PPSection({ paddingType = 'lg', children, className = '' }: PPSectionProps) {
  return (
    <section className={`pp-section__wrapper--${paddingType} ${className}`}>
      <div className="pp-content__wrapper">{children}</div>
    </section>
  );
}

export default PPSection;
