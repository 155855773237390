import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isEmpty, isEqual } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ClipboardWriteButton from 'components/stateless/Button/ClipboardWriteButton';
import { FormBorder, FormContents, FormInput, FormSelect, FormValue } from 'components/stateless/CommonForm';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import SearchBranchModal from 'components/stateless/Modal/financier/SearchBranchModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, FINANCIER_INTERFACE_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type { SignInModel } from 'models/SignInModel';
import type { BranchVOModel } from 'models/vo/BranchVO';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFinancierEmailSend,
  requestFinancierSuspend,
  requestFinancierUserDetail,
  requestFinancierUserEdit,
  requestFinancierUserInviteUrl,
} from 'utils/http/api/financier/users';
import type { FinancierUserUpdateRequest } from 'utils/http/api/financier/users/request';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType, getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import UpdateUserModal from './modal/UpdateUserModal';

function FinancierUserDetail() {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const getProperty = useProperty<FinancierUserUpdateRequest>();

  const [financierUserDetail, setFinancierUserDetail] = useState<UserVOModel>(); // 사용자 상세
  const [financierInterfaceUserDetail, setFinancierInterfaceUserDetail] = useState<UserVOModel>(); // 전문 연동

  // edit 활성화 여부 state
  const [isEditable, setIsEditable] = useState<boolean>(false);

  // email send button 활성화 여부 state
  const [showSendEmailButton, setShowSendEmailButton] = useState<boolean>(false);
  const signInModel: SignInModel | null = getSignIn();

  const [userInterfaceEnable] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.USER));

  const [inviteUrl, setInviteUrl] = useState<string | null>('');

  const requestModal = useModal();
  const confirmModal = useModal();

  const { userId } = useParams<any>();

  useEffect(() => {
    if (mounted) {
      fetchFinancierUserDetail();
    }
  }, [mounted]);

  const textCutter = (data: string | undefined): string | undefined => {
    if (data && (data.includes(' ') || data.includes('\t') || data.includes('\n'))) {
      const newText = data
        .split('')
        .filter((t: string) => t !== ' ' && t !== '\t' && t !== '\n')
        .join('');

      return newText;
    }

    return data;
  };

  async function fetchFinancierUserDetail() {
    try {
      const financierUserDetailData = await requestFinancierUserDetail(userId);

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancierUserDetail(financierUserDetailData);
        setIsEditable(false);
        // input 값 설정
        setUserInfoForm(financierUserDetailData);

        // 이메일 버튼 활성화 설정
        if (
          financierUserDetailData?.userStatus === USER_STATUS.REGISTERED ||
          financierUserDetailData?.userStatus === USER_STATUS.INVITED
        ) {
          if (isLoginUserHasNotEditRole(financierUserDetailData)) {
            setShowSendEmailButton(false);
          } else {
            setShowSendEmailButton(true);
          }
        } else {
          setShowSendEmailButton(false);
        }
      });
    } catch (error) {
      modal.show(error);
    }
  }

  // edit button handler
  const handleEdit = () => {
    setIsEditable(prevState => !prevState);
  };

  const setUserInfoForm = (data: UserVOModel | undefined) => {
    reset({
      employeeCode: textCutter(data?.employeeCode),
      userName: data?.userName,
      telephone: data?.telephone,
      email: data?.email,
      branchCode: data?.branchCode,
      branchName: data?.branchName,
      authorityType: data?.authorityType,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();

    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information')}?<br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => {
          setIsEditable(false);
          setUserInfoForm(financierUserDetail);
        },
      },
    );
  };

  // 유저 수정 form 제출 용
  const { register, getValues, handleSubmit, setValue, reset, errors, setError, clearErrors } =
    useForm<FinancierUserUpdateRequest>({
      mode: 'onSubmit',
      shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
    });

  // 유저 업데이트 폼 submit
  const onUserUpdateSubmit = async () => {
    const data = getValues();
    try {
      requestDTOParser(data);

      await requestFinancierUserEdit(userId, data);

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: async () => await fetchFinancierUserDetail(),
      });
    } catch (e) {
      modal.show(e);

      formErrorHandler<FinancierUserUpdateRequest>(e, setError, clearErrors);
    }
  };

  const showUpdateUserModal = async () => {
    let interfaceData: UserVOModel;

    modal.show(
      <UpdateUserModal
        userCode={userId}
        userData={financierUserDetail}
        getUpdatedUserData={data => {
          interfaceData = { ...data };
        }}
        modalId={modal.id}
      />,
      {
        modalType: ModalType.CONFIRM,
        modalSize: ModalSize.XL,
        title: t('text:Update_User'),
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Apply'),
        confirmBtnCb: () => {
          if (!isEqual(financierInterfaceUserDetail, interfaceData) && !isEmpty(interfaceData)) {
            setFinancierInterfaceUserDetail(interfaceData);
            setUserInfoForm(interfaceData);
          }
        },
      },
    );
  };

  const searchBranchModalOptions: ModalOptions = {
    modalType: ModalType.ALERT,
    modalSize: ModalSize.XL,
    title: t('text:Search_Branch'),
    closeBtnText: t('text:Close'),
  };

  // searchBranchModal 띄우기
  const showSearchBranchModal = () => {
    // bank admin
    const setSelectedBranchInfo = (data: BranchVOModel) => {
      setValue('branchCode', data.branchCode);
      setValue('branchName', data.branchName);
    };

    modal.show(
      <SearchBranchModal getSelectedData={setSelectedBranchInfo} modalId={modal.id} />,
      searchBranchModalOptions,
    );
  };

  // 이메일 send 요청
  const onSendEmailModalHandler = async () => {
    requestModal.show(<h6>{t('text:Would_you_like_to_send_an_invitation_email')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          requestModal.close(requestModal.id);
          await requestFinancierEmailSend(userId);
          showEmailSendConfirmModal(confirmModal.id);
          setInviteUrl('');
        } catch (e) {
          modal.show(e);
        }
      },
    });
  };

  const showEmailSendConfirmModal = (modalId: number) => {
    confirmModal.show(<h6>{t('text:The_invitation_email_has_been_sent')}</h6>, {
      modalType: ModalType.ALERT,
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        confirmModal.close(modalId);
        fetchFinancierUserDetail();
      },
    });
  };

  // 사용자 이용중지
  const onMakeUserInactive = async (e: any) => {
    e.preventDefault();
    requestModal.show(
      <h6>
        {t('text:Would_you_like_to_deactivate_the_user_account')}
        <br />
        {t('text:When_an_account_is_deactivated_the_user_is_not_allowed_to_sign_in_to_the_platform')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            requestModal.close(requestModal.id);
            await requestFinancierSuspend(userId, true);
            showRequestUserInactiveConfirmModal(confirmModal.id);
          } catch (e) {
            modal.show(e);
          }
        },
      },
    );
  };

  const showRequestUserInactiveConfirmModal = (modalId: number) => {
    confirmModal.show(
      <h6>
        {t('text:The_user_account_has_been_deactivated')}
        <br />
        {t('text:To_reactivate_the_account_click_on_the_Activate_button')}
      </h6>,
      {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          confirmModal.close(modalId);
          fetchFinancierUserDetail();
        },
      },
    );
  };

  // make user Active
  const onMakeUserActive = async (e: any) => {
    e.preventDefault();
    requestModal.show(
      <h6>
        {t('text:Would_you_like_to_activate_the_user_account')}
        <br />
        {t(
          'text:If_the_account_is_activated_the_user_will_be_able_to_log_in_to_the_platform_and_use_its_features_again',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnCb: async () => {
          try {
            requestModal.close(requestModal.id);
            await requestFinancierSuspend(userId, false);
            showRequestUserActiveConfirmModal(confirmModal.id);
          } catch (e) {
            modal.show(e);
          }
        },
      },
    );
  };

  const showRequestUserActiveConfirmModal = (modalId: number) => {
    confirmModal.show(<h6>{t('text:The_user_account_has_been_activated')}</h6>, {
      modalType: ModalType.ALERT,
      closeBtnText: t('text:OK'),
      closeBtnCb: () => {
        confirmModal.close(modalId);
        fetchFinancierUserDetail();
      },
    });
  };

  // Edit 권한 가지지 않을 때 , true 반환
  const isLoginUserHasNotEditRole = (user: UserVOModel | undefined = financierUserDetail) => {
    if (signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER) {
      return signInModel?.branchId !== user?.branchId || user?.authorityType === AUTHORITY_TYPE.AUTHORIZER;
    } else if (signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR || user?.authorityType === AUTHORITY_TYPE.ADMIN) {
      return true;
    } else return false;
  };

  const userCodeHandler = (e: any) => {
    if (e.target.value.includes(' ') || e.target.value.includes('\t') || e.target.value.includes('\n')) {
      const newText = e.target.value
        .split('')
        .filter((t: string) => t !== ' ' && t !== '\t' && t !== '\n')
        .join('');

      setValue('employeeCode', newText);
    }
  };

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickClipboardWriteButton = async () => {
    if (inviteUrl === null) return null;

    try {
      const inviteUrl = await requestFinancierUserInviteUrl(userId);
      if (inviteUrl === null) {
        modal.show(t('text:Click_on_the_Send_button_before_copying_the_invitation_email_link'));
      }
      setInviteUrl(inviteUrl);

      return inviteUrl;
    } catch (e) {
      modal.show(e);

      return null;
    }
  };

  const isReadOnly = (parameterName: string): boolean => {
    if (isEditable) {
      if (userInterfaceEnable) {
        if (!financierInterfaceUserDetail) return true;

        return (financierInterfaceUserDetail as any)[parameterName];
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const renderUserInfoForm = () => {
    return (
      <FormBorder editable={isEditable}>
        <FormContents>
          {isEditable && userInterfaceEnable ? (
            <div className="row">
              <div className="col-12">
                <Button size={ButtonSizeEnum.LG} onClick={showUpdateUserModal}>
                  {t('text:Update_User')}
                </Button>
              </div>
            </div>
          ) : null}
          <div className="row">
            <FormInput
              label={t('text:User_Code')}
              name={getProperty('employeeCode')}
              ref={register}
              requiredOptions={{ required: true }}
              disabled={isReadOnly('employeeCode')}
              onChange={userCodeHandler}
              error={errors.employeeCode}
            />
            <FormInput
              label={t('text:User_Name')}
              name={getProperty('userName')}
              ref={register}
              requiredOptions={{ required: true }}
              disabled={isReadOnly('userName')}
              error={errors.userName}
            />
          </div>
          <div className="row">
            <FormInput
              label={t('text:Telephone')}
              name={getProperty('telephone')}
              ref={register}
              disabled={isReadOnly('telephone')}
              error={errors.telephone}
            />
            <FormInput
              label={t('text:Email')}
              name={getProperty('email')}
              ref={register}
              requiredOptions={{ required: true }}
              disabled={isReadOnly('email')}
              error={errors.email}
            >
              <>
                {financierUserDetail?.userStatus === USER_STATUS.INVITED &&
                  !isEditable &&
                  !isLoginUserHasNotEditRole() && (
                    <ClipboardWriteButton
                      className="ms-2"
                      onClickClipboardWriteButton={onClickClipboardWriteButton}
                      disabled={inviteUrl === null}
                    />
                  )}
                {showSendEmailButton && !isEditable && (
                  <Button bold className="ms-2" onClick={onSendEmailModalHandler}>
                    {t('text:Send')}
                  </Button>
                )}
              </>
            </FormInput>
          </div>
          <div className="row">
            <FormInput
              label={t('text:Branch_Code')}
              name={getProperty('branchCode')}
              ref={register}
              disabled={true}
              error={errors.branchCode}
            >
              {signInModel?.authorityType === AUTHORITY_TYPE.ADMIN && !userInterfaceEnable && isEditable && (
                <Button onClick={showSearchBranchModal}>{t('text:Search')}</Button>
              )}
            </FormInput>
            <FormInput
              label={t('text:Branch_Name')}
              name={getProperty('branchName')}
              ref={register}
              disabled={true}
              error={errors.branchName}
            />
          </div>
          <div className="row">
            {financierUserDetail?.authorityType === AUTHORITY_TYPE.ADMIN ? (
              <FormValue
                className="information-form__input"
                label={t('text:Authority')}
                value={t(`code:authority-type.${financierUserDetail?.authorityType}`)}
              />
            ) : (
              <FormSelect
                label={t('text:Authority')}
                name={getProperty('authorityType')}
                selectOptions={getSelectOptions<AUTHORITY_TYPE>('AUTHORITY_TYPE', [
                  AUTHORITY_TYPE.AUTHORIZER,
                  AUTHORITY_TYPE.OPERATOR,
                ])}
                ref={register}
                disabled={!(isEditable && signInModel?.authorityType === AUTHORITY_TYPE.ADMIN)}
                required={true}
                error={errors.authorityType}
              />
            )}
            <FormValue
              className={
                'information-form__input ' + getStatusTextClass('USER_STATUS', financierUserDetail?.userStatus)
              }
              label={t('text:User_Account_Status')}
              value={t(`code:user-status.${financierUserDetail?.userStatus}`)}
              hasStatusDescription={true}
              showStatusDescriptionFunc={onClickUserStatus}
            />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input"
              label={t('text:User_ID')}
              value={financierUserDetail?.loginId}
            />
            <FormValue
              className="information-form__input"
              label={t('text:User_Sign_Up_Date')}
              value={financierUserDetail?.signUpDateTime}
              format="datetime"
            />
          </div>
        </FormContents>
      </FormBorder>
    );
  };

  return (
    <div data-testid="sectionContainerId">
      <BackHeaderTitle title={t('text:User_Details')} />
      {isLoginUserHasNotEditRole() ? null : (
        <>
          {isEditable ? (
            <>
              {signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER ? (
                <GuideMessage
                  message={[
                    <Trans
                      key="key"
                      i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
                      components={{ 1: <span className="asterisk" /> }}
                    />,
                    t(
                      'text:To_change_the_user_s_affiliated_branch_or_authority_level_contact_the_Financier_Admin_user',
                    ),
                  ]}
                />
              ) : (
                <GuideMessage
                  message={[
                    <Trans
                      key="key"
                      i18nKey="text:All_fields_marked_with_an_asterisk_*_are_mandatory"
                      components={{ 1: <span className="asterisk" /> }}
                    />,
                    t('text:Click_on_the_“Search”_button_to_select_the_user’s_affiliated_branch'),
                    t(
                      'text:If_the_branch_information_cannot_be_found,_register_the_branch_before_proceeding_with_the_user_registration',
                    ),
                  ]}
                />
              )}
            </>
          ) : (
            <GuideMessage
              message={[
                t('text:You_can_check_the_user’s_details_on_this_page'),
                t('text:Click_the_“Edit”_button_to_modify_the_user_information'),
                t('text:Click_the_“Deactivate”_button_to_suspend_the_user’s_account'),
              ]}
            />
          )}
        </>
      )}
      <div className="content-area">
        <SectionTitle title={t('text:User_Information')}>
          {isLoginUserHasNotEditRole() ? null : isEditable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={handleCancel}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={handleSubmit(onUserUpdateSubmit)} style={{ width: '60px' }}>
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <>
              {financierUserDetail?.userStatus === USER_STATUS.SUSPENDED && (
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.BLUE}
                  onClick={onMakeUserActive}
                >
                  {t('text:Activate')}
                </Button>
              )}
              {financierUserDetail?.userStatus === USER_STATUS.ACTIVATED && (
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.RED}
                  onClick={onMakeUserInactive}
                >
                  {t('text:Deactivate')}
                </Button>
              )}

              <Button size={ButtonSizeEnum.SM} onClick={handleEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            </>
          )}
        </SectionTitle>
        {renderUserInfoForm()}
      </div>
    </div>
  );
}

export default FinancierUserDetail;
