import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, values } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ReactDatePicker from 'components/stateless/Form/ReactDatePicker/ReactDatePicker';
import ShowPaymentDateGuideModal from 'components/stateless/Modal/common/invoice/ShowPaymentDateGuideModal';
import QuestionButton from 'components/stateless/QuestionButton';
import AdditionalColumnNameListCopy from 'components/stateless/Table/AdditionalColumnNameListCopy';
import AdditionalDataListCopy from 'components/stateless/Table/AdditionalDataListCopy';
import type { CURRENCY_TYPE } from 'enums';
import { DEALER_IDENTIFIER_TYPE } from 'enums';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementDetailVOModel } from 'models/vo/AnchorAgreementDetailVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierCommonSettingVOModel } from 'models/vo/FinancierCommonSettingVO';
import type { FiscalYearSettingVOModel } from 'models/vo/FiscalYearSettingVO';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';
import type { AnchorInvoiceBulkRegisterRequest } from 'utils/http/api/anchor/invoice-summaries/requests';
import { updateErrors, updateErrorsRefWithFieldsRef } from 'utils/inputTable';
import { invoiceOrArAmountInputValidate } from 'utils/logic';
import {
  INVOICE_PAYMENT_TO_DATE_RANGE,
  invoiceDuplicateValidator,
  referenceNumberDuplicateValidator,
  repaymentDateSameValidator,
} from 'utils/logic/invoice';
import useModal from 'utils/modal/useModal';
import { handleScrollLeft } from 'utils/scroll';
import useValidation from 'utils/validation/useValidation';

import type { TFunction } from 'i18next';

const MAX_ROW_LENGTH = 500;

export const getFieldNames = (
  t: TFunction,
  options?: {
    dealerIdentifierType?: DEALER_IDENTIFIER_TYPE;
    currencyType?: CURRENCY_TYPE;
    hasQuestionButton?: boolean;
  },
) => {
  return {
    dealerIdentifierCode: {
      name:
        options?.dealerIdentifierType === DEALER_IDENTIFIER_TYPE.DEALER_CODE_BY_ANCHOR
          ? t('text:Partner_Code_of_Anchor')
          : options?.dealerIdentifierType === DEALER_IDENTIFIER_TYPE.TAX_CODE
          ? t('text:Partner_Tax_Code')
          : '',
      width: '180px',
      hasQuestionButton: false,
    },
    billOfExchangeNo: {
      name: t('text:BOE_Number'),
      width: '180px',
      hasQuestionButton: false,
    },
    invoiceNumber: {
      name: t('text:Invoice_Number'),
      width: '180px',
      hasQuestionButton: false,
    },
    referenceNumber: {
      name: t('text:Invoice_Reference_Number'),
      width: '180px',
      hasQuestionButton: false,
    },
    invoiceIssuedDate: {
      name: t('text:Invoice_Issued_Date'),
      width: '180px',
      hasQuestionButton: false,
    },
    settlementDate: {
      name: t('text:Payment_Date'),
      width: '180px',
      hasQuestionButton: options?.hasQuestionButton,
    },
    invoiceAmount: {
      name: `${t('text:Invoice_Amount')} (${t('text:unit')}: ${options?.currencyType ?? ''})`,
      width: '180px',
      hasQuestionButton: false,
    },
  };
};

interface BoePartnerObj {
  dealerIdentifierCode: string;
  billOfExchangeNo?: string;
}

interface InvoiceRegistrationTableProps {
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
  rows: string[];
  setRows: React.Dispatch<React.SetStateAction<string[]>>;
  checkedValidation: boolean[];
  setCheckedValidation: React.Dispatch<React.SetStateAction<boolean[]>>;
  totalInvoiceAmount: string;
  setTotalInvoiceAmount: React.Dispatch<React.SetStateAction<string>>;
  initialSettlementDate?: string;
  minDate?: Date;
  file?: File;
  financierCommonSettingData?: FinancierCommonSettingVOModel;
  anchorAgreementDetailData?: AnchorAgreementDetailVOModel;
  financierHoliday?: Pageable<FinancierCalenderVOModel[]>;
  fiscalYearSettingData: FiscalYearSettingVOModel | null;
}

function InvoiceBulkInputTable({
  columns,
  setColumns,
  rows,
  setRows,
  checkedValidation,
  setCheckedValidation,
  totalInvoiceAmount,
  setTotalInvoiceAmount,
  initialSettlementDate,
  minDate,
  file,
  financierCommonSettingData,
  financierHoliday,
  anchorAgreementDetailData,
  fiscalYearSettingData,
}: InvoiceRegistrationTableProps) {
  const { show: showModal } = useModal();
  const { t } = useTranslation(['format']);

  const date = new Date();
  const today: number[] = [date.getFullYear(), date.getMonth(), date.getDate()];
  const toDate = new Date(today[0], today[1], today[2] + INVOICE_PAYMENT_TO_DATE_RANGE);

  const [visibleAppendColumnBtn, setVisibleAppendColumnBtn] = useState(false);
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [maximumColumn, setMaximumColumn] = useState(false);

  const invoiceRegisterTableRef = useRef<HTMLDivElement>(null);
  const prevColumnListLength = useRef(0);
  const isAppendRowCalled = useRef(false);

  const { register, errors, getValues, reset, clearErrors, setValue, control } =
    useFormContext<AnchorInvoiceBulkRegisterRequest>();

  const fieldNames = getFieldNames(t, {
    dealerIdentifierType: anchorAgreementDetailData?.dealerIdentifierType,
    currencyType: anchorAgreementDetailData?.currencyType,
    hasQuestionButton: financierCommonSettingData?.invoiceWillBeSettledOnUploadDate,
  });

  const { getValidationClassName, getValidationResult } = useValidation({
    errorFields: errors.invoiceList,
    rule: 'invoiceList',
  });

  useEffect(() => {
    if ((columns ?? []).length > 9) {
      setMaximumColumn(true);
      prevColumnListLength.current = columns?.length ?? 0;

      return;
    }
    if (prevColumnListLength.current === 10 && columns?.length === 9) {
      setMaximumColumn(false);
    }
    if (columns?.length === 0) {
      setMaximumColumn(false);
    }
  }, [columns]);

  const sumInvoiceAmount = useCallback(() => {
    const data = getValues();
    const calculatorBigNumber = new CalculatorBigNumber();

    let sum: BigNumber = '0';

    if (data.invoiceList) {
      data.invoiceList.forEach(item => {
        if (item && item.invoiceAmount) {
          sum = calculatorBigNumber.add(sum).add(item.invoiceAmount).get();
        }
      });
    }
    setTotalInvoiceAmount(sum);
  }, [getValues, setTotalInvoiceAmount]);

  useEffect(() => {
    sumInvoiceAmount();
  }, [sumInvoiceAmount, file, rows]);

  const appendRow = useCallback(
    (e: any) => {
      e.preventDefault();
      if (rows.length >= MAX_ROW_LENGTH) {
        showModal(
          <h6>
            {t('text:Allowed_N_rows_at_a_time', { number: MAX_ROW_LENGTH })} {t('text:Please_check_the_data_again')}
          </h6>,
        );
      } else {
        isAppendRowCalled.current = true;
        setRows([...rows, '']);
        clearErrors(`invoiceList.${rows.length}.dealerIdentifierCode`);
        clearErrors(`invoiceList.${rows.length}.invoiceNumber`);
        clearErrors(`invoiceList.${rows.length}.invoiceIssuedDate`);
        clearErrors(`invoiceList.${rows.length}.invoiceAmount`);
      }
    },
    [clearErrors, rows, setRows, showModal, t],
  );

  useEffect(() => {
    if (isAppendRowCalled.current) {
      setValue(`invoiceList.${rows.length - 1}.settlementDate`, initialSettlementDate);
      isAppendRowCalled.current = false;
    }
  }, [appendRow, initialSettlementDate, rows.length, setValue]);

  const appendColumn = () => {
    setColumns([...columns, '']);
    clearErrors(`additionalColumnNameList[${columns.length}].name`);

    handleScrollLeft(invoiceRegisterTableRef, invoiceRegisterTableRef?.current?.scrollWidth);
  };

  const removeRow = (e: any) => {
    e.preventDefault();
    isAppendRowCalled.current = false;
    const updateCheckedValidation = () => {
      let i = 0;
      const temp = [...checkedValidation];

      for (const index of checkedRows) {
        temp.splice(index - i, 1);
        i++;
      }

      return temp;
    };

    // UI
    const restRows = rows.filter((_, index) => !checkedRows.includes(index));
    const emptyRow = restRows.length === 0;

    if (emptyRow) {
      setRows(['']);
      setColumns([]);
      setCheckedValidation([]);
      clearErrors();
    } else {
      setRows(restRows);
      setCheckedValidation(updateCheckedValidation());

      if (Array.isArray(errors.invoiceList) && errors.invoiceList.length > 0) {
        updateErrors(errors.invoiceList, checkedRows);
        updateErrorsRefWithFieldsRef(errors.invoiceList, control.fieldsRef.current, 'invoiceList');
      }
    }

    // Data
    const { invoiceList, additionalColumnNameList } = getValues();

    const restInvoiceList = invoiceList.filter((_, index) => !checkedRows.includes(index));
    if (restInvoiceList.length === 0) {
      const initInvoiceList = [
        {
          dealerIdentifierCode: null as unknown as string,
          billOfExchangeNo: null as unknown as string,
          invoiceNumber: null as unknown as string,
          invoiceIssuedDate: null as unknown as string,
          settlementDate: initialSettlementDate ?? '',
          invoiceAmount: null as unknown as number,
          referenceNumber: null as unknown as string,
        },
      ];

      reset(
        {
          invoiceList: initInvoiceList,
          additionalColumnNameList: undefined,
        },
        {
          errors: true,
        },
      );
    } else {
      reset(
        {
          invoiceList: restInvoiceList,
          additionalColumnNameList,
        },
        {
          errors: true,
        },
      );
    }

    setCheckedRows([]);
  };

  const removeColumn = useCallback(
    (columnIndex: number) => {
      const prevScrollLeft = invoiceRegisterTableRef?.current?.scrollLeft ?? 0;

      // UI
      const restCol = columns.filter((_, index) => index !== columnIndex);
      setColumns(restCol);

      // Data
      const { invoiceList, additionalColumnNameList } = getValues();

      const removeAdditionalData = invoiceList.map(invoiceItem => ({
        ...invoiceItem,
        invoiceAdditionalDataList: invoiceItem.invoiceAdditionalDataList?.filter((_, index) => index !== columnIndex),
      }));
      const removeColumnNameList = additionalColumnNameList?.filter((_, index) => index !== columnIndex);

      reset(
        {
          invoiceList: removeAdditionalData,
          additionalColumnNameList: removeColumnNameList,
        },
        { errors: true },
      );

      handleScrollLeft(invoiceRegisterTableRef, prevScrollLeft);
    },
    [columns, getValues, reset, setColumns],
  );

  const visibleAppendColumnButton = useCallback(() => {
    setVisibleAppendColumnBtn(true);
  }, []);

  const invisibleAppendColumnButton = useCallback(() => {
    setVisibleAppendColumnBtn(false);
  }, []);

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const allRowIndexList = rows.map((_, index) => index);
      setCheckedRows(allRowIndexList);
    } else {
      setCheckedRows([]);
    }
  };

  const handleCheckChange = (e: any, index: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter(el => el !== index));
    }
  };

  const boeDuplicateValidator = (value: string, index: number): boolean => {
    const invoiceData = getValues().invoiceList;
    if (invoiceData) {
      const getBoePartnerObj = invoiceData.map<BoePartnerObj>(item => {
        return {
          dealerIdentifierCode: item.dealerIdentifierCode,
          billOfExchangeNo: item.billOfExchangeNo,
        };
      });
      const [target] = getBoePartnerObj.splice(index, 1);

      if (target.billOfExchangeNo === '' || target.billOfExchangeNo === null || target.billOfExchangeNo === undefined) {
        return true;
      }

      return (
        getBoePartnerObj.find(item => {
          return (
            item.dealerIdentifierCode.toLowerCase() !== target.dealerIdentifierCode.toLowerCase() &&
            item.billOfExchangeNo === target.billOfExchangeNo
          );
        }) === undefined
      );
    }

    return true;
  };

  const additionalDataFieldNames = useMemo(
    () => ({
      rowName: 'invoiceList',
      columnName: 'invoiceAdditionalDataList',
    }),
    [],
  );

  const debounceHandler = useCallback(() => debounce(sumInvoiceAmount, 300), [sumInvoiceAmount]);

  return (
    <>
      <div className="mb-3 position-relative">
        <Button
          size={ButtonSizeEnum.SM}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          onClick={removeRow}
        >
          <FontAwesomeIcon icon={faMinus} />
        </Button>
        <Button size={ButtonSizeEnum.SM} onClick={appendRow} className="ms-2">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <div
          className="append-column-btn__area"
          onMouseEnter={visibleAppendColumnButton}
          onMouseLeave={invisibleAppendColumnButton}
        />
        {visibleAppendColumnBtn && !maximumColumn && (
          <Button
            size={ButtonSizeEnum.XS}
            onClick={appendColumn}
            className="append-column-btn flex-center"
            onMouseEnter={visibleAppendColumnButton}
            variant={ButtonVariantEnum.OUTLINED}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </div>

      {anchorAgreementDetailData && (
        <>
          <div className="table-overflow-scroll" ref={invoiceRegisterTableRef} style={{ maxHeight: '1000px' }}>
            <table className="table-border">
              <colgroup>
                <col style={{ width: '70px' }} />
                {values(fieldNames).map(({ width }, index) => (
                  <col key={index} style={{ width }} />
                ))}
                {columns.map((_, index) => (
                  <col key={index} style={{ width: '180px' }} />
                ))}
                {checkedValidation.length > 0 && <col style={{ width: '180px' }} />}
              </colgroup>
              <thead data-column-type="append">
                <tr>
                  <th scope="col">
                    <div className="text-center">
                      <input
                        className="form-check-input m-0"
                        type="checkbox"
                        value=""
                        id="allCheck2"
                        onChange={handleCheckAll}
                        checked={checkedRows.length === rows.length}
                      />
                    </div>
                  </th>
                  {values(fieldNames).map(({ name, hasQuestionButton }, index, { length }) => (
                    <th
                      key={index}
                      scope="col"
                      onMouseEnter={() => {
                        if (index !== length - 1 || columns.length > 0) return;
                        visibleAppendColumnButton();
                      }}
                      onMouseLeave={() => {
                        if (index !== length - 1 || columns.length > 0) return;
                        invisibleAppendColumnButton();
                      }}
                    >
                      {name}
                      {hasQuestionButton && <QuestionButton onClick={() => showModal(<ShowPaymentDateGuideModal />)} />}
                    </th>
                  ))}
                  <AdditionalColumnNameListCopy
                    columns={columns}
                    visibleAppendColumnButton={visibleAppendColumnButton}
                    invisibleAppendColumnButton={invisibleAppendColumnButton}
                    removeColumn={removeColumn}
                  />
                  {checkedValidation.length > 0 && (
                    <th scope="col" onMouseEnter={visibleAppendColumnButton} onMouseLeave={invisibleAppendColumnButton}>
                      {t('text:Validation_Result')}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white">
                {rows.map((_, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      <td className="bg-sub100" key={rowIndex}>
                        <div className="text-center">
                          <input
                            className="form-check-input m-0"
                            type="checkbox"
                            onChange={e => handleCheckChange(e, rowIndex)}
                            checked={checkedRows.includes(rowIndex)}
                          />
                        </div>
                      </td>
                      <td className={getValidationClassName('dealerIdentifierCode', 'td', rowIndex)}>
                        <input
                          className={getValidationClassName('dealerIdentifierCode', 'input', rowIndex)}
                          name={`invoiceList.${rowIndex}.dealerIdentifierCode`}
                          ref={register({
                            required: true,
                          })}
                        />
                      </td>
                      <td className={getValidationClassName('billOfExchangeNo', 'td', rowIndex)}>
                        <input
                          className={getValidationClassName('billOfExchangeNo', 'input', rowIndex)}
                          name={`invoiceList.${rowIndex}.billOfExchangeNo`}
                          ref={register({
                            required: anchorAgreementDetailData.billOfExchangeNoRequired,
                            validate: anchorAgreementDetailData.billOfExchangeNoRequired
                              ? value => boeDuplicateValidator(value, rowIndex)
                              : undefined,
                          })}
                        />
                      </td>
                      <td className={getValidationClassName('invoiceNumber', 'td', rowIndex)}>
                        <input
                          className={getValidationClassName('invoiceNumber', 'input', rowIndex)}
                          name={`invoiceList.${rowIndex}.invoiceNumber`}
                          ref={register({
                            required: true,
                            validate: value =>
                              invoiceDuplicateValidator(
                                getValues().invoiceList,
                                value,
                                rowIndex,
                                fiscalYearSettingData,
                              ),
                          })}
                        />
                      </td>
                      <td className={getValidationClassName('referenceNumber', 'td', rowIndex)}>
                        <input
                          className={getValidationClassName('referenceNumber', 'input', rowIndex)}
                          name={`invoiceList.${rowIndex}.referenceNumber`}
                          ref={register({
                            required: anchorAgreementDetailData?.referenceNumberDuplicationCheck ? true : undefined,
                            validate: value =>
                              anchorAgreementDetailData?.referenceNumberDuplicationCheck
                                ? referenceNumberDuplicateValidator(getValues().invoiceList, value, rowIndex)
                                : undefined,
                          })}
                        />
                      </td>
                      <td className={getValidationClassName('invoiceIssuedDate', 'td', rowIndex)}>
                        <Controller
                          control={control}
                          name={`invoiceList.${rowIndex}.invoiceIssuedDate`}
                          defaultValue={null}
                          render={field => (
                            <ReactDatePicker
                              field={field}
                              className={getValidationClassName('invoiceIssuedDate', 'input', rowIndex)}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </td>
                      <td className={getValidationClassName('settlementDate', 'td', rowIndex)}>
                        <Controller
                          control={control}
                          name={`invoiceList.${rowIndex}.settlementDate`}
                          defaultValue={null}
                          render={field => {
                            return (
                              <ReactDatePicker
                                field={field}
                                className={getValidationClassName('settlementDate', 'input', rowIndex)}
                                minDate={minDate}
                                maxDate={toDate}
                                excludeDates={
                                  financierHoliday?.content?.map(item => new Date(item.solarDate)) as Date[] | undefined
                                }
                                disabled={financierCommonSettingData?.invoiceWillBeSettledOnUploadDate}
                              />
                            );
                          }}
                          rules={{
                            required: true,
                            validate: anchorAgreementDetailData?.billOfExchangeNoRequired
                              ? () => repaymentDateSameValidator(getValues().invoiceList, rowIndex)
                              : undefined,
                          }}
                        />
                      </td>
                      <td className={getValidationClassName('invoiceAmount', 'td', rowIndex)}>
                        <input
                          type="text"
                          className={getValidationClassName('invoiceAmount', 'input', rowIndex) + ' text-end'}
                          name={`invoiceList.${rowIndex}.invoiceAmount`}
                          onChange={debounceHandler()}
                          ref={register({
                            validate: value =>
                              invoiceOrArAmountInputValidate(value, anchorAgreementDetailData?.currencyType, 'Invoice'),
                            required: true,
                            min: 0.01,
                          })}
                        />
                      </td>
                      <AdditionalDataListCopy
                        columns={columns}
                        rowIndex={rowIndex}
                        register={register}
                        fieldName={additionalDataFieldNames}
                      />
                      {checkedValidation[rowIndex]
                        ? getValidationResult(rowIndex)
                        : checkedValidation.length > 0 && <td />}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="grid-total">
            {t('text:Total')} : {t('format:number', { value: totalInvoiceAmount })}
          </div>
        </>
      )}
    </>
  );
}

export default InvoiceBulkInputTable;
