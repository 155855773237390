import HeaderBar from 'components/stateless/HeaderBar/HeaderBar';

export const BasicFallBack = () => (
  <div className="api-loading">
    <img src="/static/image/loader_01.gif" alt="loading-ui" />
  </div>
);

export const WrapWithNavigationFallBack = () => (
  <div className="content-wrapper">
    <HeaderBar sideBarCollapsed={true} />
    <div className="side-bar" />
    <div className="page-content">
      <BasicFallBack />
    </div>
  </div>
);
