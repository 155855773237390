import type { ChangeEvent } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import SearchFinancierClientModal from 'components/stateless/Modal/financier/SearchFinancierClientModal';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { FINANCIER_CLIENT_TYPE } from 'enums';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorClientInfoViewModel from '../../../models/agreement/useAnchorClientInfoViewModel';
import useDivisionsViewModel from '../../../models/divisions/useDivisionsViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';

const useWaitingDetailAnchorCompanyController = () => {
  const { t } = useTranslation(['format']);
  const { show: showModal, id: modalId } = useModal();

  const { isFirstRegisteredWaitingAgreement } = useAgreementViewModel();
  const { anchorClientInfo, updateAnchorClientInfo, convertToAnchorClientInfoType } = useAnchorClientInfoViewModel();
  const { divisionRegistrable, divisionName, divisionSelectOptions, fetchDivisionSelectOptions, updateDivisionName } =
    useDivisionsViewModel();
  const { useAgreementInterface, useAnchorUserInterface } = useExtraInformationViewModel();

  const {
    methods: { setValue, errors, clearErrors },
    isEditable,
  } = useFormContext();

  const isSearchAnchorButtonVisible = !useAgreementInterface && isEditable && isFirstRegisteredWaitingAgreement;
  const isSearchAnchorUserButtonVisible = isEditable && !useAnchorUserInterface;

  const canChangeDivision =
    isEditable && isFirstRegisteredWaitingAgreement && divisionSelectOptions && !isEmpty(divisionSelectOptions);

  const ANCHOR_ADMIN_TABLE_HEADERS: HeaderType[] = useMemo(
    () => [
      { headerText: t('text:User_Code') },
      { headerText: t('text:Name') },
      { headerText: t('text:Mobile') },
      { headerText: t('text:Email') },
      { headerText: t('text:OTP_Number') },
      { headerText: t('text:Authority') },
    ],
    [t],
  );

  const handleSearchAnchorButtonClick = () => {
    const setFinancierClientData = async (data: FinancierClientVOModel) => {
      await fetchDivisionSelectOptions(data.financierClientId);

      setValue('anchorClientId', data.financierClientId);
      setValue('division', '');
      setValue('bankUserIdList', []);

      updateAnchorClientInfo(convertToAnchorClientInfoType(data));
      updateDivisionName('');

      if (errors?.anchorClientId) clearErrors('anchorClientId');
    };

    showModal(
      <SearchFinancierClientModal
        clientType={FINANCIER_CLIENT_TYPE.ANCHOR}
        getSelectedData={setFinancierClientData}
        modalId={modalId}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const handleDivisionSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const divisionName = divisionSelectOptions.find(({ value }) => value === event.target.value)?.name;
    updateDivisionName(divisionName ?? '');
  };

  return {
    isSearchAnchorButtonVisible,
    isSearchAnchorUserButtonVisible,
    canChangeDivision,
    anchorClientInfo,
    divisionRegistrable,
    divisionName,
    divisionSelectOptions,
    ANCHOR_ADMIN_TABLE_HEADERS,
    handleSearchAnchorButtonClick,
    handleDivisionSelectChange,
  };
};

export default useWaitingDetailAnchorCompanyController;
