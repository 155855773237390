export interface InventorySummaryVO {
  inventorySummaryId: number;
  totalCount: number; // = total Quantity
  totalAmount: number;
  // https://fin2b-wiki.atlassian.net/browse/DFS-5072
  inventoryDescription: string; // = total count (list row 개수)
  attachmentId: number;
  fileName: string;
  financierId: number;
  financierName: string;
  dealerClientId: number;
  dealerClientCode: string;
  dealerClientName: string;
  uploadUserId: number;
  uploadUserName: string;
  createdDateTime: string;
}

export interface InventorySummaryVOModel extends InventorySummaryVO {}

export function formattingToInventorySummaryVOModel(data: InventorySummaryVO): InventorySummaryVOModel {
  return {
    ...data,
  };
}
