import type { UserNotificationVO, UserNotificationVOModel } from 'models/vo/UserNotificationVO';
import http from 'utils/http';
import API_CM from 'utils/http/api/common';

export const requestNotificationList = (
  turnOnUnreadFilter: boolean,
  cursorId?: number,
): Promise<UserNotificationVOModel> => {
  return http.get<UserNotificationVO>({
    url: API_CM.NOTIFICATIONS.NOTIFICATION_LIST,
    data: { size: 50, cursorId, turnOnUnreadFilter },
  });
};

export const requestNewUserNotification = () => {
  return http.get<boolean>({ url: API_CM.NOTIFICATIONS.NEW_USER_NOTIFICATION });
};

export const requestNotificationReadStatus = (
  userNotificationReadStatusIdRange: { startId: number; endId: number },
  hasLoadingUI: boolean,
) => {
  return http.put({
    url: API_CM.NOTIFICATIONS.NOTIFICATION_READ_STATUS,
    data: userNotificationReadStatusIdRange,
    hasLoadingUI,
  });
};
