import { BANK_CODE_APPROVAL_TYPE, COMMON_APPROVAL_TYPE } from 'enums';

/**
 *  COMMON_APPROVAL_TYPE 검색 관련 setting :: usage > 앵커/딜러 리스트 페이지 waiting tab
 *  waiting list api 2번 요청
 *  1. for list - default value :: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES
 *  2. for not cancelled list count (탭 name 오른쪽 동그라미 숫자) - default Value :: NOT_CANCELLED_WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES
 *
 * **/

export const WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES = [
  COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
  COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
  COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
];

export const NOT_CANCELLED_WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES = [
  COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
  COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
];

export const WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES = [
  BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST,
  BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED,
  BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL,
];

export const NOT_CANCELLED_WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES = [
  BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST,
  BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED,
];

export function setApprovalTypesValue(commonApprovalSearchData: COMMON_APPROVAL_TYPE[] | undefined) {
  if (!commonApprovalSearchData) {
    return {
      waitingForApprovalTypeData: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
      notCancelledWaitingForApprovalTypeData: NOT_CANCELLED_WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
    };
  }

  let waitingForApprovalTypeData = commonApprovalSearchData;
  let notCancelledWaitingForApprovalTypeData = commonApprovalSearchData; // depends on approvalTypes

  if (
    (commonApprovalSearchData as any) === WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES.join(',') ||
    commonApprovalSearchData === WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES
  ) {
    waitingForApprovalTypeData = WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES;
    notCancelledWaitingForApprovalTypeData = NOT_CANCELLED_WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES;
  }

  return {
    waitingForApprovalTypeData,
    notCancelledWaitingForApprovalTypeData,
  };
}

export function setBankCodeApprovalTypesValue(bankCodeApprovalSearchData: BANK_CODE_APPROVAL_TYPE[] | undefined) {
  if (!bankCodeApprovalSearchData) {
    return {
      waitingForApprovalTypeData: WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES,
      notCancelledWaitingForApprovalTypeData: NOT_CANCELLED_WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES,
    };
  }

  let waitingForApprovalTypeData = bankCodeApprovalSearchData;
  let notCancelledWaitingForApprovalTypeData = bankCodeApprovalSearchData; // depends on approvalTypes

  if (
    (bankCodeApprovalSearchData as any) === WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES.join(',') ||
    bankCodeApprovalSearchData === WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES
  ) {
    waitingForApprovalTypeData = WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES;
    notCancelledWaitingForApprovalTypeData = NOT_CANCELLED_WAITING_BANK_CODE_APPROVAL_TYPE_ALL_VALUES;
  }

  return {
    waitingForApprovalTypeData,
    notCancelledWaitingForApprovalTypeData,
  };
}
