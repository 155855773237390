import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import { requestAnchorRelatedFinanciers } from 'utils/http/api/anchor/enterprises';
import useModal from 'utils/modal/useModal';

function AnchorDealerRegistrationStep1Page() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { pageable, setPageable } = usePageable();

  const [financierList, setFinancierList] = useState<EnterpriseVOModel[]>();

  useEffect(() => {
    if (mounted) {
      fetchAcRelatedFinancierList();
    }
  }, [mounted]);

  async function fetchAcRelatedFinancierList(pageNumber: number = 1, rowCount: number = 10) {
    try {
      const anchorRelatedFinancierResponse = await requestAnchorRelatedFinanciers(pageNumber, rowCount);

      setFinancierList(anchorRelatedFinancierResponse.content);
      setPageable(anchorRelatedFinancierResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchAcRelatedFinancierList(page, sizePerPage);
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Dealer_Registration')} />
      <div className="content-area">
        <StepWizard nth={1} title={[t('text:Select_Financier'), t('text:Register_Dealer_Information')]} />
      </div>

      <GuideMessage message={[t('text:Select_the_Financier_to_share_Dealer_information')]} />
      <div className="content-area">
        <TableBorder>
          <TableHeader
            header={[
              {
                headerText: t('text:Financier_Name'),
              },
              {
                headerText: '',
                colWidths: 100,
              },
            ]}
          />
          <TableBody numOfCol={2}>
            {financierList?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.enterpriseName} />
                <Td className="information-table-more">
                  <Button
                    onClick={() => {
                      history.push(ROUTES_AC.MANAGE_DEALER.REGISTRATION_STEP2, {
                        financierName: item.enterpriseName,
                        financierId: item.enterpriseId,
                      });
                    }}
                  >
                    {t('text:Select')}
                  </Button>
                </Td>
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorDealerRegistrationStep1Page;
