import type { PropsWithChildren } from 'react';

import { clsx } from 'clsx';

import { passPropsToChildren } from 'utils/render';

const Cell = ({ children, className, ...restProps }: PropsWithChildren<any>) => {
  return (
    <div
      className={clsx('section__cell', {
        [className]: className,
      })}
    >
      {passPropsToChildren(children, restProps)}
    </div>
  );
};

export default Cell;
