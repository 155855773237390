import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { InventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemInventorySummariesList } from 'utils/http/api/system/inventory-summaries';
import type { SystemInventorySummariesListRequest } from 'utils/http/api/system/inventory-summaries/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const SEARCHED_SYSTEM_SECURED_INVENTORY_LIST_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Registration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Number_of_Items'),
      colWidths: 200,
    },
    {
      headerText: t('text:Total_Quantity'),
      colWidths: 80,
    },
    {
      headerText: t('text:Total_Amount'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_SECURED_INVENTORY_LIST_QS_KEY = 'sy-secured-inventory-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_SYSTEM_SECURED_INVENTORY_LIST_TABLE_HEADERS,
    SY_SECURED_INVENTORY_LIST_QS_KEY,
  };
};

function SystemSecuredInventoryList() {
  const { mounted, modal, t, SEARCHED_SYSTEM_SECURED_INVENTORY_LIST_TABLE_HEADERS, SY_SECURED_INVENTORY_LIST_QS_KEY } =
    getConstants();

  const {
    register: systemSecuredInventorySearchFormRegister,
    getValues: getSystemSecuredInventorySearchFormValues,
    reset: resetSystemSecuredInventorySearchFormValue,
    setValue: setSystemSecuredInventorySearchFormValue,
    control: systemSecuredInventorySearchFormControl,
  } = useForm<SystemInventorySummariesListRequest>();

  const [systemSecuredInventoryPage, setSystemSecuredInventoryPage] = useState<Pageable<InventorySummaryVOModel[]>>();
  const getSystemSecuredInventoryProperty = useProperty<SystemInventorySummariesListRequest>();
  const { pageable: systemSecuredInventoryPageable, setPageable: setSystemSecuredInventoryPageable } = usePageable(
    SY_SECURED_INVENTORY_LIST_QS_KEY,
  );

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemInventorySummariesListRequest>(
        setSystemSecuredInventorySearchFormValue,
        getParsedSearchParams(SY_SECURED_INVENTORY_LIST_QS_KEY).formSearchData,
      );
      fetchSySecuredInventoryList(
        systemSecuredInventoryPageable.currentPage,
        systemSecuredInventoryPageable.sizePerPage,
        getSystemSecuredInventorySearchFormValues(),
      );
    }
  }, [mounted]);

  const fetchSySecuredInventoryList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemInventorySummariesListRequest,
  ): Promise<void> => {
    try {
      const systemSecuredInventoryPageData = await requestSystemInventorySummariesList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_SECURED_INVENTORY_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemSecuredInventoryPage(systemSecuredInventoryPageData);
        setSystemSecuredInventoryPageable(systemSecuredInventoryPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    resetSystemSecuredInventorySearchFormValue();
  };

  const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    fetchSySecuredInventoryList(
      1,
      systemSecuredInventoryPageable.sizePerPage,
      getSystemSecuredInventorySearchFormValues(),
    );
  };

  const systemSecuredInventoryPaginate = (pageNumber: number, rowCount: number): void => {
    fetchSySecuredInventoryList(
      pageNumber,
      rowCount,
      getParsedSearchParams(SY_SECURED_INVENTORY_LIST_QS_KEY).formSearchData,
    );
  };

  const renderSearchedSystemSecuredInventoryTableBody = () => {
    return systemSecuredInventoryPage?.content.map((item, i) => (
      <Tr key={i}>
        <Td data={item.createdDateTime} format="datetime" />
        <Td data={item.financierName} />
        <Td data={item.dealerClientName} />
        <Td data={item.dealerClientCode} />
        <Td data={item.inventoryDescription} format="number" />
        <Td data={item.totalCount} format="number" />
        <Td data={item.totalAmount} format="number" />
        <TdLink path={ROUTES_SY.MONITOR_FINANCING.SECURED_INVENTORY_DETAIL_BUILD_PATH(item.inventorySummaryId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Secured_Inventory')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getSystemSecuredInventoryProperty('createdDateFrom')}
                control={systemSecuredInventorySearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name={getSystemSecuredInventoryProperty('createdDateTo')}
                control={systemSecuredInventorySearchFormControl}
              />
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput
                name={getSystemSecuredInventoryProperty('financierName')}
                ref={systemSecuredInventorySearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput
                name={getSystemSecuredInventoryProperty('dealerClientName')}
                ref={systemSecuredInventorySearchFormRegister}
              />
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput
                name={getSystemSecuredInventoryProperty('dealerClientCode')}
                ref={systemSecuredInventorySearchFormRegister}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Number_of_Items')} />
              <SearchInput
                name={getSystemSecuredInventoryProperty('minimumInventoryDescription')}
                ref={systemSecuredInventorySearchFormRegister}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name={getSystemSecuredInventoryProperty('maximumInventoryDescription')}
                ref={systemSecuredInventorySearchFormRegister}
                col={2}
                placeholder={t('text:to')}
              />
              <SearchLabel label={t('text:Total_Quantity')} />
              <SearchInput
                name={getSystemSecuredInventoryProperty('minimumTotalCount')}
                ref={systemSecuredInventorySearchFormRegister}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name={getSystemSecuredInventoryProperty('maximumTotalCount')}
                ref={systemSecuredInventorySearchFormRegister}
                col={2}
                placeholder={t('text:to')}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Total_Amount')} />
              <SearchInput
                name={getSystemSecuredInventoryProperty('minimumTotalAmount')}
                ref={systemSecuredInventorySearchFormRegister}
                col={2}
                placeholder={t('text:from')}
              />
              <SearchInput
                name={getSystemSecuredInventoryProperty('maximumTotalAmount')}
                ref={systemSecuredInventorySearchFormRegister}
                col={2}
                placeholder={t('text:to')}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <p className="total-data">
          {t('text:Total')}: {systemSecuredInventoryPageable.totalRows}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_SYSTEM_SECURED_INVENTORY_LIST_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_SYSTEM_SECURED_INVENTORY_LIST_TABLE_HEADERS.length}>
            {renderSearchedSystemSecuredInventoryTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={systemSecuredInventoryPageable} paginate={systemSecuredInventoryPaginate} />
      </div>
    </>
  );
}

export default SystemSecuredInventoryList;
