import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { isNil } from 'lodash-es';

import i18n from 'locales/i18n';

import { getSortedJsonArrayData } from '../converters/getSortedJsonArrayData';
import {
  applyMergedCellStyles,
  borderStyle,
  excelBulkImportErrorStyle,
  excelDefaultHeaderStyle,
  setEtcInsertStyle,
} from '../styles';

import type { ExportSpreadSheetProps } from '../types';
import type { Worksheet } from 'exceljs';

const EXCEL_EXPORT_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const EXCEL_EXPORT_FILE_EXTENSION = '.xlsx';

const HEADER_HEIGHT = 11;
const DEFAULT_HEADER_LINE_NUMBER = 1;
const EXPORT_FILE_NAME = 'report';

const setWorkSheet = async (
  targetWorkSheet: Worksheet,
  excelExportProps: ExportSpreadSheetProps<any>,
  isBulkImport: boolean,
) => {
  const { jsonArrayData, columns, options, errors = [], bulkImportOptions } = excelExportProps;

  const { maxDate, minDate } = bulkImportOptions ?? {};

  const sortedJsonArrayData = getSortedJsonArrayData(jsonArrayData, errors);
  const columnLineNumber = options?.columnLineNumber ?? DEFAULT_HEADER_LINE_NUMBER;
  const worksheet = targetWorkSheet;

  const setColumns = () => {
    const appliedWidthColumn = columns.map(column => ({
      ...column,
      width: column.width ? column.width : column.header?.length + 10,
    }));

    worksheet.columns = appliedWidthColumn;

    // #1-1 columns 위치 지정 - 옵션으로 특정 위치를 받을 때, Default 값은 1
    if (options?.columnLineNumber) {
      // 빈 row 가 맨 위부터 추가되어 기존의 columns 와 데이터 row 들이 아래로 이동
      const insertData: any[] = [];

      for (let i = 0; i < options?.columnLineNumber - 1; i++) {
        insertData.push([]);
      }

      worksheet.spliceRows(1, 0, ...insertData);
    }

    // #1-2 컬럼 스타일 적용
    worksheet.getRow(columnLineNumber).height = HEADER_HEIGHT;

    worksheet.getRow(columnLineNumber).eachCell(cell => {
      worksheet.getCell(cell.address).style = {
        ...cell.style,
        ...excelDefaultHeaderStyle(isBulkImport),
      };
    });
  };

  const setEtcData = () => {
    if (options?.etcInsertData) {
      options?.etcInsertData.forEach(item => {
        const cellStyle = setEtcInsertStyle(item.style, item.applyDefaultStyle);
        worksheet.getCell(item.target).value = item.value;

        if (cellStyle) {
          worksheet.getCell(item.target).style = cellStyle;
        }
      });
    }
  };

  const setArrayData = (item: any, index: number) => {
    if (!isBulkImport) {
      Object.keys(item).forEach(key => {
        if (isNil(item[key])) item[key] = '-';
      });
    }
    worksheet.addRow(item);
    worksheet.getRow(index + 1 + columnLineNumber).eachCell(
      cell =>
        (cell.style = {
          border: borderStyle('000000'),
        }),
    );
  };

  const setBulkImport = () => {
    worksheet.spliceRows(1, 0, [
      `* ${i18n.t('text:APs_that_have_failed_the_validation_check_are_highlighted_in_red')} ${i18n.t(
        'text:Check_their_validation_results_and_the_reasons_for_the_failure',
      )} ${i18n.t(
        'text:If_there_are_any_APs_that_need_revision,_make_the_changes_below_and_re-upload_this_file_to_the_platform',
      )}`,
    ]);
    worksheet.mergeCells('A1:Q1');
    worksheet.getCell('A1').font = { color: { argb: 'FF0000' }, bold: true, size: 13 };
    worksheet.getRow(1).height = 15;

    const headerRow = worksheet.getRow(2);
    const validationResultHeaderCell = headerRow.getCell(7);
    validationResultHeaderCell.style = excelBulkImportErrorStyle(true);
    const validationResultHeaderColumn = worksheet.getColumn('G');
    validationResultHeaderColumn.width = 30;
    validationResultHeaderCell.value = 'Validation Result';

    sortedJsonArrayData.forEach((rowData, index) => {
      const targetRow = worksheet.getRow(index + 3);
      const targetValidationResultCell = targetRow.getCell('G');
      const dateCells = [
        ['arIssuedDate', 'D'],
        ['settlementDate', 'E'],
      ];

      dateCells.forEach(([field, column]) => {
        const targetDateCell = targetRow.getCell(column);
        const formattedDate = rowData[field];

        if (dayjs(formattedDate, i18n.t('format:original-date'), true).isValid()) {
          targetDateCell.value = dayjs(formattedDate).format(i18n.t('format:no-line-date'));
        }
      });

      if (errors[rowData.rowIndex - 1]) {
        targetValidationResultCell.value = i18n.t('text:Fail') as string;
        targetValidationResultCell.style = {
          font: {
            color: { argb: 'FF0000' },
            bold: true,
            size: 11,
          },
          border: borderStyle('000000'),
        };
      } else {
        targetValidationResultCell.value = i18n.t('text:Pass') as string;
        targetValidationResultCell.style = {
          font: {
            color: { argb: '235F62' },
            bold: true,
            size: 11,
          },
          border: borderStyle('000000'),
        };
      }
    });

    if (errors?.length !== 0) {
      errors.forEach((error, index) => {
        for (const key of Object.keys(error)) {
          const targetRow = worksheet.getRow(
            sortedJsonArrayData.findIndex(item => error && index === item.rowIndex - 1) + 3,
          );
          const targetErrorCell = targetRow.getCell(key === 'partnerTaxCode' ? 'anchorPartnerTaxCode' : key);
          targetErrorCell.style = excelBulkImportErrorStyle();

          const getErrorMessage = (errorMessage: string) => {
            switch (errorMessage) {
              case 'text:Fail_No_partner_exists_that_matches_the_tax_code_you_entered':
                return i18n.t('text:Fail_No_partner_exists_that_matches_the_tax_code_you_entered');

              case 'text:Fail_The_tax_code_must_consist_only_of_letters_and_numbers':
                return i18n.t('text:Fail_The_tax_code_must_consist_only_of_letters_and_numbers');

              case 'text:Fail_The_partner’s_tax_code_must_be_between_1_and_20_characters':
                return i18n.t('text:Fail_The_partner’s_tax_code_must_be_between_1_and_20_characters');

              case 'text:Fail_The_AP_Number_must_be_between_1_and_20_characters':
                return i18n.t('text:Fail_The_AP_Number_must_be_between_1_and_20_characters');

              case 'text:Fail_The_AP_Amount_must_consist_only_of_numbers':
                return i18n.t('text:Fail_The_AP_Amount_must_consist_only_of_numbers');

              case 'text:Fail_AP_Amount_must_exceed_0':
                return i18n.t('text:Fail_AP_Amount_must_exceed_0');

              case 'text:Fail_The_AP_Amount_is_too_large':
                return i18n.t('text:Fail_The_AP_Amount_is_too_large');

              case 'text:Fail_The_date_in_the_past_cannot_be_selected':
                return i18n.t('text:Fail_The_date_in_the_past_cannot_be_selected');

              case 'text:Fail_Missing_item(s)':
                return i18n.t('text:Fail_Missing_item(s)');

              case 'text:Fail_Duplicate_AP_Number':
                return i18n.t('text:Fail_Duplicate_AP_Number');

              case 'text:Fail_The_AP_Amount_can_have_up_to_2_decimals':
                return i18n.t('text:Fail_The_AP_Amount_can_have_up_to_2_decimals');

              case 'text:Fail_The_AP_Amount_cannot_have_decimals':
                return i18n.t('text:Fail_The_AP_Amount_cannot_have_decimals');

              case 'text:Fail_The_entered_settlement_date_is_a_holiday_and_cannot_be_selected':
                return i18n.t('text:Fail_The_entered_settlement_date_is_a_holiday_and_cannot_be_selected');

              case 'text:Fail_The_settlement_date_must_not_be_before_DATE':
                return i18n.t('text:Fail_The_settlement_date_must_not_be_before_DATE', {
                  date: i18n.t('format:date', { value: minDate, key: 'date' }),
                });

              case 'text:Fail_The_settlement_date_must_not_exceed_DATE':
                return i18n.t('text:Fail_The_settlement_date_must_not_exceed_DATE', {
                  date: i18n.t('format:date', { value: maxDate, key: 'date' }),
                });

              default:
                return '';
            }
          };

          targetErrorCell.note = getErrorMessage(error[key].message) as string;
        }
      });
    }
  };

  const setExcelData = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!columns || !Array.isArray(columns) || columns.length === 0) {
        reject(new Error(i18n.t('text:No_data_available')));
      }

      // #1. 컬럼 지정
      setColumns();

      // #2. 기본 리스트 데이터 이외의 특정 위치에 데이터 있을 시, 데이터 삽입 및 스타일 적용
      setEtcData();

      // #3. 리스트 데이터 삽입 및 스타일 지정
      const tempJsonArrayData = isBulkImport ? sortedJsonArrayData : jsonArrayData;

      tempJsonArrayData.forEach((item, index) => {
        if (Object.keys(item).length !== columns.length) {
          reject(new Error(i18n.t('text:Excel_template_length_is_not_equal_with_data')));
        }
        setArrayData(item, index);
      });

      // #3. 합치는 셀(merged cell) 지정
      if (options?.mergedCells) {
        const headerLineNumber = options?.columnLineNumber || DEFAULT_HEADER_LINE_NUMBER;
        worksheet.insertRow(headerLineNumber, {});

        applyMergedCellStyles(worksheet, options.mergedCells);
      }

      // #4. 특정 row 에 특정 height 적용
      if (options?.rowHeight) {
        options?.rowHeight.forEach(item => {
          worksheet.getRow(item.position).height = item.height;
        });
      }

      // #5. BulkImport
      if (isBulkImport) {
        setBulkImport();
      }

      resolve();
    });
  };

  await setExcelData();

  return worksheet;
};

const exportExcelFile = async (excelExportProps: ExportSpreadSheetProps<any>, isBulkImport: boolean) => {
  const workbook = new (await import('exceljs')).Workbook();
  const worksheet = workbook.addWorksheet('report');
  await setWorkSheet(worksheet, excelExportProps, isBulkImport);

  // excel file download
  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], { type: EXCEL_EXPORT_FILE_TYPE });
  fileDownload(blob, EXPORT_FILE_NAME + EXCEL_EXPORT_FILE_EXTENSION);
};

export default exportExcelFile;
