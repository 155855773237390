import type Pageable from 'models/Pageable';
import type { InventorySummaryDetailVO, InventorySummaryDetailVOModel } from 'models/vo/InventorySummaryDetailVO';
import { formattingToInventorySummaryDetailVOModel } from 'models/vo/InventorySummaryDetailVO';
import type { InventorySummaryVO, InventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import { formattingToInventorySummaryVOModel } from 'models/vo/InventorySummaryVO';
import http, { ContentType } from 'utils/http';
import API_DE from 'utils/http/api/dealer';
import type {
  DealerInventorySummaryListRequest,
  DealerInventorySummaryRegisterRequest,
} from 'utils/http/api/dealer/inventory-summaries/request';

// 재고담보 목록
export async function requestDealerInventoryList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerInventorySummaryListRequest,
): Promise<Pageable<InventorySummaryVOModel[]>> {
  const response = await http.get<Pageable<InventorySummaryVO[]>>({
    url: API_DE.INVENTORY_SUMMARIES.INVENTORY_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const inventorySummaryList: InventorySummaryVOModel[] = response.content.map((data: InventorySummaryVO) =>
    formattingToInventorySummaryVOModel(data),
  );
  const inventorySummaryPage: Pageable<InventorySummaryVOModel[]> = {
    ...response,
    content: inventorySummaryList,
  };

  return inventorySummaryPage;
}

// 재고담보 상세조회
export async function requestDealerInventoryDetail(inventorySummaryId: number): Promise<InventorySummaryDetailVOModel> {
  const response = await http.get<InventorySummaryDetailVO>({
    url: API_DE.INVENTORY_SUMMARIES.INVENTORY_DETAIL(inventorySummaryId),
  });

  return formattingToInventorySummaryDetailVOModel(response);
}

// 재고담보 등록
export async function requestDealerInventoryRegister(
  data: DealerInventorySummaryRegisterRequest,
): Promise<InventorySummaryDetailVOModel> {
  const response = await http.post<InventorySummaryDetailVO>({
    url: API_DE.INVENTORY_SUMMARIES.INVENTORY_LIST,
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToInventorySummaryDetailVOModel(response);
}

// 재고담보 첨부파일 다운로드
export async function requestDealerInventoryAttachmentDownload(inventorySummaryId: number) {
  await http.download(API_DE.INVENTORY_SUMMARIES.DOWNLOAD_ATTACHMENT(inventorySummaryId));
}
