import type { TermsOfUseVersionVO, TermsOfUseVersionVOModel } from 'models/vo/TermsOfUseVersionVO';
import { formattingToTermsOfUseVersionVOModel } from 'models/vo/TermsOfUseVersionVO';
import http from 'utils/http';

import API_AN from '..';

export async function requestTermsOfUseVersions(emailCode: string): Promise<TermsOfUseVersionVOModel[]> {
  const response = await http.get<TermsOfUseVersionVO[]>({
    url: API_AN.TERMS_OF_USE_VERSIONS.TERMS_OF_USE_VERSIONS,
    data: { emailCode },
  });

  return response.map(item => formattingToTermsOfUseVersionVOModel(item));
}
