import type Pageable from 'models/Pageable';
import type {
  AnchorFinancingOptionDetailVO,
  AnchorFinancingOptionDetailVOModel,
} from 'models/vo/AnchorFinancingOptionDetailVO';
import { formattingToAnchorFinancingOptionDetailVOModel } from 'models/vo/AnchorFinancingOptionDetailVO';
import { formattingToAnchorFinancingOptionVOModel } from 'models/vo/AnchorFinancingOptionVO';
import type { AnchorFinancingOptionVO, AnchorFinancingOptionVOModel } from 'models/vo/AnchorFinancingOptionVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { SearchFiAnchorFinancingOptionsDTO } from './requests';

export async function requestFinancierAnchorFinancingOptionsList(
  pageNumber: number,
  rowCount: number,
  SearchFiAnchorFinancingAgreementDTO: SearchFiAnchorFinancingOptionsDTO,
): Promise<Pageable<AnchorFinancingOptionVOModel[]>> {
  const response = await http.get<Pageable<AnchorFinancingOptionVO[]>>({
    url: API_FI.ANCHOR_FINANCING_OPTIONS.ANCHOR_FINANCING_OPTION_LIST,
    data: {
      pageNumber,
      rowCount,
      ...SearchFiAnchorFinancingAgreementDTO,
    },
  });

  const anchorFinancingAgreementList = response.content.map(data => formattingToAnchorFinancingOptionVOModel(data));

  return {
    ...response,
    content: anchorFinancingAgreementList,
  };
}

export async function requestFinancierAnchorFinancingOptionDetail(
  anchorFinancingOptionId: number,
): Promise<AnchorFinancingOptionDetailVOModel> {
  const response = await http.get<AnchorFinancingOptionDetailVO>({
    url: API_FI.ANCHOR_FINANCING_OPTIONS.ANCHOR_FINANCING_OPTION_DETAIL(anchorFinancingOptionId),
  });

  return formattingToAnchorFinancingOptionDetailVOModel(response);
}
