import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import ChangePasswordModal from 'components/stateless/Modal/common/ChangePasswordModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import type { UserVOModel } from 'models/vo/UserVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestCurrentUser,
  requestCurrentUserChangeInfo,
  requestCurrentUserResetPassword,
} from 'utils/http/api/common/users';
import type { CurrentUserChangeInfoRequest, CurrentUserChangePassword } from 'utils/http/api/common/users/request';
import { requestDealerFinancierClients } from 'utils/http/api/dealer/financier-clients';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

function DealerMyAccount() {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();
  const authority = getSignIn()?.authorityType;

  const [currentUserInfo, setCurrentUserInfo] = useState<UserVOModel>();
  const [financierClientList, setFinancierClientList] = useState<Pageable<FinancierClientVOModel[]>>();
  const [isEditable, setIsEditable] = useState<boolean>(false); // edit 활성화 여부 state
  const getProperty = useProperty<CurrentUserChangeInfoRequest>();

  // edit button handler
  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    if (mounted) {
      fetchCurrentUser();
    }
  }, [mounted]);

  const { register, getValues, setValue, reset, errors, setError, clearErrors } = useForm<
    CurrentUserChangeInfoRequest & CurrentUserChangePassword
  >({
    mode: 'onSubmit',
    shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
  });

  async function fetchCurrentUser() {
    try {
      const currentUserInfo = await requestCurrentUser();
      const financierClients = await requestDealerFinancierClients();
      ReactDOM.unstable_batchedUpdates(() => {
        setIsEditable(false);
        setCurrentUserInfo(currentUserInfo);
        setFinancierClientList(financierClients);

        setValue('userName', currentUserInfo?.userName);
        setValue('department', currentUserInfo?.department);
        setValue('position', currentUserInfo?.position);
        setValue('email', currentUserInfo?.email);
        setValue('telephone', currentUserInfo?.telephone);
        setValue('mobile', currentUserInfo?.mobile);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSubmitUserChangeForm = async () => {
    const data = getValues();
    try {
      requestDTOParser(data);
      await requestCurrentUserChangeInfo(data);

      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        closeBtnCb: () => fetchCurrentUser(),
      });
    } catch (e) {
      modal.show(e);
      formErrorHandler<CurrentUserChangeInfoRequest>(e, setError, clearErrors);
    }
  };

  const onClickUserStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={[USER_STATUS.ACTIVATED, USER_STATUS.SUSPENDED]}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setIsEditable(false);
    reset({
      userName: currentUserInfo?.userName,
      department: currentUserInfo?.department,
      position: currentUserInfo?.position,
      email: currentUserInfo?.email,
      telephone: currentUserInfo?.telephone,
      mobile: currentUserInfo?.mobile,
    });
  };

  const onSubmitChangePasswordForm = async (data: CurrentUserChangePassword) => {
    try {
      await requestCurrentUserResetPassword(data);
      modal.show(<h6>{t('text:Password_successfully_changed')}</h6>, {
        closeBtnCb: () => fetchCurrentUser(),
      });
    } catch (e) {
      modal.show(e);

      formErrorHandler<CurrentUserChangePassword>(e, setError, clearErrors);
    }
  };

  const showChangePasswordModal = () => {
    modal.show(<ChangePasswordModal state={{} as CurrentUserChangePassword} modalId={modal.id} />, {
      modalType: ModalType.CONFIRM,
      title: t('text:Change_password'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: (data: { state: CurrentUserChangePassword }) => {
        onSubmitChangePasswordForm(data.state);
      },
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:My_Account')} />
      <GuideMessage
        message={[
          t('text:The_user’s_account_information_is_shown_below'),
          t('text:Click_on_the_“Edit“_button_to_modify_the_user_information'),
        ]}
      />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:User_Information')}>
            {isEditable ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={handleCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onSubmitUserChangeForm} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              <Button size={ButtonSizeEnum.SM} onClick={handleEdit} style={{ width: '60px' }}>
                {t('text:Edit')}
              </Button>
            )}
          </SectionTitle>
          <FormBorder editable={isEditable}>
            <FormContents>
              <div className="row">
                <FormInput
                  label={t('text:User_Name')}
                  name={getProperty('userName')}
                  ref={register}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  error={errors.userName}
                />
                <FormValue
                  className="information-form__input"
                  label={t('text:User_Code')}
                  value={currentUserInfo?.employeeCode}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Department')}
                  name={getProperty('department')}
                  ref={register}
                  disabled={!isEditable}
                  error={errors.department}
                />
                <FormInput
                  label={t('text:Title')}
                  name={getProperty('position')}
                  ref={register}
                  disabled={!isEditable}
                  error={errors.position}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Email')}
                  name={getProperty('email')}
                  ref={register}
                  disabled={!isEditable}
                  requiredOptions={{ required: true }}
                  error={errors.email}
                />
                <FormInput
                  label={t('text:Telephone')}
                  name={getProperty('telephone')}
                  ref={register}
                  disabled={!isEditable}
                  error={errors.telephone}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Mobile')}
                  name={getProperty('mobile')}
                  ref={register}
                  disabled={!isEditable}
                  error={errors.mobile}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
      {/* Account Information form */}
      <div className="content-area">
        <SectionTitle title={t('text:Account_Information')} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue className="information-form__input" label={t('text:ID')} value={currentUserInfo?.loginId} />
              <FormValue className="information-form__input" label={t('text:Password')} value="●●●●●●●●">
                <Button onClick={showChangePasswordModal} bold>
                  {t('text:Change_password')}
                </Button>
              </FormValue>
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Authority')}
                value={t(`code:authority-type.${currentUserInfo?.authorityType}`)}
              />
              <FormValue
                className={`information-form__input ${getStatusTextClass('USER_STATUS', currentUserInfo?.userStatus)}`}
                label={t('text:User_Account_Status')}
                value={t(`code:user-status.${currentUserInfo?.userStatus}`)}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickUserStatus}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
      <div className="content-area">
        <SectionTitle title={t('text:Associated_Financier')} />
        <TableBorder>
          <TableHeader
            header={[
              {
                headerText: t('text:Financier_Name'),
                colWidths: 160,
              },
              {
                headerText: t('text:Email_Notification_Setting'),
                colWidths: 120,
              },
            ]}
          />
          <TableBody numOfCol={2}>
            {financierClientList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.financierName} />
                  {authority === AUTHORITY_TYPE.AUTHORIZER ? (
                    <TdLink path={ROUTES_DE.MY_ACCOUNT.EMAIL_NOTIFICATION_SETTING_BUILD_PATH(item.financierId)} />
                  ) : (
                    <Td />
                  )}
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
      </div>
    </>
  );
}

export default DealerMyAccount;
