import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import { FormSelect } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import getSelectOptions from 'constants/selectOptions';
import { FINANCIER_CLIENT_TYPE } from 'enums';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import { requestFinancierClientDetailByClientCode } from 'utils/http/api/financier/financier-clients';
import { getHttpErrorExceptionMessage } from 'utils/text';

const financierClientType = FINANCIER_CLIENT_TYPE.ANCHOR;

interface SearchAnchorModalPropsType {
  financierClientDataHandler: Function;
  isOtpUsed: boolean;
}

interface SearchData {
  clientCode: string;
}

function SearchAnchorModal({ financierClientDataHandler, isOtpUsed }: SearchAnchorModalPropsType) {
  const { t } = useTranslation(['format']);
  const { register: searchClientCodeRegister, getValues: searchClientCodeGetValues } = useForm<SearchData>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const { register: newFinancierClientRegister, reset: newFinancierClientReset } = useForm<FinancierClientVOModel>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const [errorMessage, setErrorMessage] = useState('');

  const getSearchFormClassName = (): string => {
    return errorMessage !== ''
      ? 'information-form__input bg-sub100 red_reject-border text-bold-brick-red'
      : 'information-form__input bg-sub100';
  };

  const renderErrorText = () => {
    return errorMessage !== '' && <div className="text-brick-red mt-1">{t(`exception:${errorMessage}`)}</div>;
  };

  const searchClientCode = async (event: any) => {
    event.preventDefault();

    const searchData = searchClientCodeGetValues();

    if (searchData.clientCode !== '') {
      let searchResult: FinancierClientVOModel | undefined = undefined;
      try {
        searchResult = await requestFinancierClientDetailByClientCode(searchData.clientCode, financierClientType);
        setErrorMessage('');

        searchResult
          ? newFinancierClientReset(searchResult)
          : newFinancierClientReset({
              financierClientCode: '',
              financierClientName: '',
              financierClientTaxCode: '',
              financierClientBusinessCode: '',
              financierClientTelephone: '',
              financierClientFax: '',
              representativeName: '',
              representativePosition: '',
              representativeEmail: '',
              otpBypassed: undefined,
              financierClientAddress: '',
            });

        financierClientDataHandler(searchResult);
      } catch (error: any) {
        setErrorMessage(getHttpErrorExceptionMessage(error));
        newFinancierClientReset({
          financierClientCode: '',
          financierClientName: '',
          financierClientTaxCode: '',
          financierClientBusinessCode: '',
          financierClientTelephone: '',
          financierClientFax: '',
          representativeName: '',
          representativePosition: '',
          representativeEmail: '',
          otpBypassed: undefined,
          financierClientAddress: '',
        });
        financierClientDataHandler({
          financierClientCode: '',
          financierClientName: '',
          financierClientTaxCode: '',
          financierClientBusinessCode: '',
          financierClientTelephone: '',
          financierClientFax: '',
          representativeName: '',
          representativePosition: '',
          representativeEmail: '',
          otpBypassed: undefined,
          financierClientAddress: '',
        });
      }
    } else {
      setErrorMessage(t('text:Please_enter_client_code(customer_identifier)'));
    }
  };

  return (
    <div className="modal-container">
      <form>
        <div className="modal-input-form mb-3">
          <label className="information-form__label">{t('text:Client_Code(Customer_Identifier)')}</label>
          <div className="d-flex">
            <input
              name="clientCode"
              className={getSearchFormClassName()}
              type="text"
              ref={searchClientCodeRegister}
              placeholder={t('text:Please_type_here')}
              onChange={() => {
                setErrorMessage('');
              }}
            />
            <Button type="submit" onClick={searchClientCode}>
              {t('text:Search')}
            </Button>
          </div>
          {renderErrorText()}
        </div>
      </form>
      <form>
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormInput
                label={t('text:Client_Code')}
                name="financierClientCode"
                ref={newFinancierClientRegister}
                disabled={true}
              />
              <FormInput
                label={t('text:Anchor_Name')}
                name="financierClientName"
                ref={newFinancierClientRegister}
                disabled={true}
              />
            </div>
            <div className="row">
              <FormInput
                col={3}
                label={t('text:Tax_Code')}
                name="financierClientTaxCode"
                ref={newFinancierClientRegister}
                disabled={true}
              />
              <FormInput
                col={3}
                label={t('text:Company_Registration_Number')}
                name="financierClientBusinessCode"
                ref={newFinancierClientRegister}
                disabled={true}
              />
              <FormInput
                col={3}
                label={t('text:Telephone')}
                name="financierClientTelephone"
                ref={newFinancierClientRegister}
                disabled={true}
              />
              <FormInput
                col={3}
                label={t('text:Fax')}
                name="financierClientFax"
                ref={newFinancierClientRegister}
                disabled={true}
              />
            </div>
            <div className="row">
              <FormInput
                col={isOtpUsed ? 3 : 6}
                label={t('text:Legal_Representative_Name')}
                name="representativeName"
                ref={newFinancierClientRegister}
                disabled={true}
              />
              <FormInput
                col={3}
                label={t('text:Legal_Representative_Title')}
                name="representativePosition"
                ref={newFinancierClientRegister}
                disabled={true}
              />
              <FormInput
                col={3}
                label={t('text:Legal_Representative_Email')}
                name="representativeEmail"
                ref={newFinancierClientRegister}
                disabled={true}
              />
              {isOtpUsed && (
                <FormSelect
                  col={3}
                  label={t('text:OTP_Verification')}
                  selectOptions={getSelectOptions('OTP_VERIFICATION')}
                  name="otpBypassed"
                  placeholderOptions={{ show: true }}
                  ref={newFinancierClientRegister}
                  disabled={true}
                />
              )}
            </div>
            <div className="row">
              <FormInput
                col={12}
                label={t('text:Registered_Office_Address')}
                name="financierClientAddress"
                ref={newFinancierClientRegister}
                disabled={true}
              />
              <input
                name="financierClientType"
                hidden
                ref={newFinancierClientRegister}
                defaultValue={financierClientType}
              />
            </div>
          </FormContents>
        </FormBorder>
      </form>
    </div>
  );
}

export default SearchAnchorModal;
