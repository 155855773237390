import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { ANCHOR_AGREEMENT_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';

interface AnchorAgreementListTabProps {
  anchorAgreementList: AnchorAgreementVOModel[] | undefined;
  searchAgreementNumber: string;
  onBlurUpdateAgreementNumber(e: any): void;
  handleClickAnchorAgreementSearch(e: any): void;
  pageable: PageableType;
  paginateAnchorAgreement(page: number, sizePerPage: number): void;
}

function AnchorAgreementListTab({
  anchorAgreementList,
  searchAgreementNumber,
  onBlurUpdateAgreementNumber,
  handleClickAnchorAgreementSearch,
  pageable,
  paginateAnchorAgreement,
}: AnchorAgreementListTabProps) {
  const { t } = useTranslation();

  const ANCHOR_AGREEMENT_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
    },
    {
      headerText: t('text:Financier_Name'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Responsible_Bank_Branch'),
    },
    {
      headerText: t('text:Effective_Date'),
    },
    {
      headerText: t('text:Expired_Date'),
    },
    {
      headerText: t('text:Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    const getAnchorAgreementStatusClassName = (anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS) => {
      switch (anchorAgreementStatus) {
        case ANCHOR_AGREEMENT_STATUS.ACTIVATED:
          return 'text-bold-dark-green';
        case ANCHOR_AGREEMENT_STATUS.SUSPENDED:
          return 'text-bold-brick-red';
        default:
          return '';
      }
    };

    return anchorAgreementList?.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.financierName} />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.branchName} />
          <Td data={item.startDate} format="date" />
          <Td data={item.expiryDate} format="date" />
          <Td
            data={t(`code:anchor-agreement-status.${item?.anchorAgreementStatus}`)}
            className={getAnchorAgreementStatusClassName(item.anchorAgreementStatus)}
            format="code"
          />
          <TdLink path={ROUTES_AC.COMPANY.AGREEMENT_DETAIL_BUILD_PATH(item.anchorAgreementId)} />
        </Tr>
      );
    });
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Anchor_Master_Agreement_List')} />
      <form>
        <div className="d-flex information-filter mt-0">
          <input
            className="only-input"
            type="text"
            placeholder={t('text:Anchor_Master_Agreement_Number')}
            onBlur={onBlurUpdateAgreementNumber}
            style={{ width: '220px' }}
            defaultValue={searchAgreementNumber}
          />
          <Button onClick={handleClickAnchorAgreementSearch}>{t('text:Search')}</Button>
        </div>
      </form>

      <TableBorder>
        <TableHeader header={ANCHOR_AGREEMENT_TABLE_HEADER} />
        <TableBody numOfCol={ANCHOR_AGREEMENT_TABLE_HEADER.length}>{renderResultTable()}</TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginateAnchorAgreement} />
    </div>
  );
}

export default AnchorAgreementListTab;
