import type { MouseEvent } from 'react';
import type React from 'react';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExcelTemplateDownloadButton from 'components/stateless/Button/ExcelTemplateDownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import { initializeRefValue } from 'utils/initialize';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import useModal from 'utils/modal/useModal';
import { csvToJson, excelToJson } from 'utils/spreadSheet/converters';
import { ExcelTemplatesHeader } from 'utils/spreadSheet/types';

const AnchorDealerRegistrationStep2ExcelForm = () => {
  const { reset } = useFormContext();
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const fileRef = useRef<HTMLInputElement>(null);
  const modal = useModal();

  const onChangeUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const formatData = (data: any) => {
      return {
        name: data[ExcelTemplatesHeader.DEALER_NAME],
        taxCode: data[ExcelTemplatesHeader.DEALER_TAX_CODE],
        representativeName: data[ExcelTemplatesHeader.LEGAL_REPRESENTATIVE_NAME],
        representativeEmail: data[ExcelTemplatesHeader.LEGAL_REPRESENTATIVE_EMAIL],
        telephone: data[ExcelTemplatesHeader.TELEPHONE],
        address: data[ExcelTemplatesHeader.REGISTERED_OFFICE_ADDRESS],
      };
    };

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const isCsvFile = file.type === 'text/csv';

      const getFormattedData = (data: unknown[]) => {
        if (isCsvFile && data && data.length) return data.map(item => formatData(item));
        if (!isCsvFile && data && Array.isArray(data) && data[0] && Array.isArray(data[0]) && data[0].length)
          return data[0].map(item => formatData(item));
      };

      try {
        showLoadingUI();
        setFile(file);

        const jsonArrayData = isCsvFile ? await csvToJson(file) : await excelToJson(file);
        const formattedData = getFormattedData(jsonArrayData);

        if (formattedData) {
          reset({
            waitingAnchorDealers: formattedData,
          });
        } else {
          modal.show(
            <h6>
              {t('text:There_is_no_data_in_the_uploaded_Excel_file')}
              <br />
              {t('text:Please_check_and_upload_it_again')}
            </h6>,
          );
        }
      } catch (error) {
        modal.show(error);
        initializeRefValue(fileRef);
      } finally {
        unShowLoadingUI();
      }
    }
  };

  const onClickRemoveFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    initializeRefValue(fileRef);
    if (file) {
      setFile(undefined);
    }
  };

  return (
    <div className="excel-form mb-4">
      <div className="excel-download-form d-flex">
        <label className="me-3">
          {t(
            'text:Please_download_the_template_on_the_right_fill_it_out_and_upload_it_After_uploading_you_can_check_the_uploaded_list_below',
          )}
          .{' '}
        </label>
        <ExcelTemplateDownloadButton
          templateFileAddress="/templates/DealerRegistrationTemplate.xlsx"
          style={{ marginRight: '4px' }}
        />
        <ExcelTemplateDownloadButton
          downloadAnnounceText={t('text:CSV_Template')}
          templateFileAddress="/templates/DealerRegistrationTemplate.csv"
        />
      </div>
      <div className="detail-in-file-upload-form bg-sub100">
        <div className="d-flex justify-content-between">
          <input
            onChange={onChangeUploadFile}
            ref={fileRef}
            type="file"
            name="file"
            id="FileUpload"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: 'none' }}
          />
          <label htmlFor="FileUpload" className="attach-file-link-button bg-sub100">
            {t('text:Attach_File')}
          </label>
          <div id="fileName" className="upload-file-input">
            {file ? file.name : t('text:No_file_attached')}
          </div>
          <IconButton onClick={onClickRemoveFile} className={`delete-uploaded-excel-button ${!file && 'd-none'}`}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default AnchorDealerRegistrationStep2ExcelForm;
