import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Pagination from 'components/stateless/Pagination/Pagination';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import usePageable from 'hooks/usePageable';
import type { UsageSummaryVOModel } from 'models/vo/UsageSummaryVO';
import { requestFiBillingList } from 'utils/http/api/financier/billing';
import type { SearchFiBillingDTO } from 'utils/http/api/financier/billing/requests';
import useModal from 'utils/modal/useModal';

import UsageTable from '../../components/UsageTable';

function UsageHistory() {
  const { t } = useTranslation();
  const { show: showModal } = useModal();
  const [billingList, setBillingList] = useState<UsageSummaryVOModel[]>([]);
  const { pageable, setPageable } = usePageable();

  const fetchBillingList = async (page = 1, sizePerPage = 10) => {
    const endDateOfLastMonth = dayjs().subtract(1, 'month').endOf('month');
    const startDateOfOneYearAgoFromLastMonth = endDateOfLastMonth.subtract(1, 'year').startOf('month');
    const searchValue: SearchFiBillingDTO = {
      fromDate: startDateOfOneYearAgoFromLastMonth.format('YYYY-MM-DD'),
      toDate: endDateOfLastMonth.format('YYYY-MM-DD'),
    };

    try {
      const billingList = await requestFiBillingList(page, sizePerPage, searchValue);
      setBillingList(billingList.content);
      setPageable(billingList);
    } catch (error) {
      showModal(error);
    }
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchBillingList(page, sizePerPage);
  };

  useEffect(() => {
    fetchBillingList();
  }, []);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Platform_Usage_History')} />
      <UsageTable usageData={billingList} isThisMonthSummary={false} />
      <Pagination pageable={pageable} paginate={paginate} />
    </div>
  );
}

export default UsageHistory;
