import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { uniqBy } from 'lodash-es';

import { useFormContext } from 'components/stateless/Form';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { AUTHORITY_TYPE, USER_STATUS } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorUserVOModel } from 'models/vo/AnchorUserVO';
import { requestFinancierAnchorUserInviteUrl, requestInviteAnchorUser } from 'utils/http/api/financier/anchor-users';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import SearchAnchorUserModal from '../../../modals/SearchAnchorUserModal';
import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';
import useAnchorAgreementInfoViewModel from '../../../models/agreement/useAnchorAgreementInfoViewModel';
import useExtraInformationViewModel from '../../../models/extraInformation/useExtraInformationViewModel';
import useFinancierSettingViewModel from '../../../models/financierSetting/useFinancierSettingViewModel';

type InviteUrlType = {
  id: number;
  inviteUrl: string | null;
};

const useRegistrationDetailAssociatedAnchorMasterAgreementController = () => {
  const [inviteUrlList, setInviteUrlList] = useState<InviteUrlType[]>([]);

  const { show: showModal } = useModal();
  const { t } = useTranslation(['format']);
  const signInModel: SignInModel | null = getSignIn();

  const { agreement, isSearchedAgreement, fetchAgreementDetail } = useAgreementViewModel();
  const { anchorAgreementInfo, updateAnchorUserList } = useAnchorAgreementInfoViewModel();

  const { useAgreementInterface, useAnchorUserInterface } = useExtraInformationViewModel();
  const { divisionRegistrable } = useFinancierSettingViewModel();

  const {
    methods: { setValue },
    isEditable,
  } = useFormContext();

  const isDealerAgreementTypeRadioDisabled = true;

  const isSearchAnchorAgreementButtonVisible = false;

  const isSearchAnchorUserButtonVisible = useAgreementInterface
    ? isSearchedAgreement && isEditable && !useAnchorUserInterface
    : isEditable && !useAnchorUserInterface;

  const isMoreAnchorUserInfoVisible = !isEditable;

  const isClipboardWriteButtonVisible = (anchorUserStatus: keyof typeof USER_STATUS) => {
    return signInModel?.authorityType !== AUTHORITY_TYPE.ADMIN && anchorUserStatus === USER_STATUS.INVITED;
  };

  const isClipboardWriteButtonDisabled = (anchorUserId: number) => {
    return inviteUrlList?.find(inviteUrl => inviteUrl.id === anchorUserId)?.inviteUrl === null;
  };

  const isSendButtonDisabled = (anchorUserStatus: keyof typeof USER_STATUS) => {
    return (
      signInModel?.authorityType === AUTHORITY_TYPE.ADMIN ||
      anchorUserStatus === USER_STATUS.ACTIVATED ||
      anchorUserStatus === USER_STATUS.SUSPENDED
    );
  };

  const handleSearchAnchorUserClick = () => {
    let selectedAnchorUserList: AnchorUserVOModel[] = [];

    const manageSelectedAnchorUserList = (anchorUser: AnchorUserVOModel, isChecked: boolean) => {
      if (isChecked) {
        selectedAnchorUserList = [...selectedAnchorUserList, anchorUser];
      } else {
        selectedAnchorUserList = selectedAnchorUserList.filter(
          selectedAnchorUser => selectedAnchorUser.id !== anchorUser.id,
        );
      }
    };

    const handleConfirmClick = () => {
      setValue(
        'bankUserIdList',
        selectedAnchorUserList.map(anchorUser => anchorUser.bankUserId),
      );

      updateAnchorUserList(selectedAnchorUserList);
    };

    if (anchorAgreementInfo.anchorClientId) {
      showModal(
        <SearchAnchorUserModal
          anchorClientId={anchorAgreementInfo.anchorClientId}
          selectedAnchorUserListManager={manageSelectedAnchorUserList}
        />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: handleConfirmClick,
        },
      );
    } else {
      showModal(<h6>{t('text:Please_search_anchor_agreement_before_using_this_button')}</h6>, {
        modalSize: ModalSize.NONE,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
      });
    }
  };

  const handleUserStatusClick = useCallback(() => {
    showModal(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(USER_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="USER_STATUS"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  }, [t, showModal]);

  const header: HeaderType[] = useMemo(() => {
    const header: HeaderType[] = [
      { headerText: t('text:User_Code') },
      { headerText: t('text:Name') },
      { headerText: t('text:Mobile') },
      { headerText: t('text:Email') },
      { headerText: t('text:OTP_Number') },
      { headerText: t('text:Authority') },
    ];

    if (!isMoreAnchorUserInfoVisible) return header;
    else
      return header.concat([
        {
          headerText: t('text:User_Account_Status'),
          hasStatusDescription: true,
          showStatusDescriptionFunc: handleUserStatusClick,
        },
        { headerText: t('text:Invitation_Email') },
      ]);
  }, [isMoreAnchorUserInfoVisible, handleUserStatusClick, t]);

  const handleClipboardButtonClick = async (anchorUserId: number) => {
    const existingInviteUrl = inviteUrlList.find(inviteUrl => inviteUrl.id === anchorUserId)?.inviteUrl;

    if (existingInviteUrl === null) {
      return null;
    }

    try {
      const inviteUrl = await requestFinancierAnchorUserInviteUrl(anchorUserId);
      if (inviteUrl === null) {
        showModal(t('text:Click_on_the_Send_button_before_copying_the_invitation_email_link'));
      }

      const updatedInviteUrlList = uniqBy([{ id: anchorUserId, inviteUrl }, ...inviteUrlList], 'id');
      setInviteUrlList(updatedInviteUrlList);

      return inviteUrl;
    } catch (e) {
      showModal(e);

      return null;
    }
  };

  const updateInviteUrlList = (anchorUser: AnchorUserVOModel) => {
    const updatedInviteUrlList = inviteUrlList?.map(inviteUrlItem => {
      if (inviteUrlItem.id !== anchorUser.id) return inviteUrlItem;
      else
        return {
          ...inviteUrlItem,
          inviteUrl: '',
        };
    });

    setInviteUrlList(updatedInviteUrlList);
  };

  const handleAnchorUserInviteButtonClick = async (anchorUser: AnchorUserVOModel) => {
    showModal(<h6>{t('text:Would_you_like_to_send_an_invitation_email')}</h6>, {
      modalSize: ModalSize.NONE,
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestInviteAnchorUser(anchorUser.id);

          showModal(<h6>{t('text:An_invitation_Email_is_successfully_sent')}</h6>, {
            modalSize: ModalSize.NONE,
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: async () => {
              const dealerAgreementDetail = await fetchAgreementDetail(agreement.dealerAgreementId);
              if (dealerAgreementDetail) {
                updateAnchorUserList(dealerAgreementDetail.anchorUserList);
              }
            },
          });

          updateInviteUrlList(anchorUser);
        } catch (error: any) {
          showModal(error);
        }
      },
    });
  };

  return {
    agreement,
    anchorAgreementInfo,
    divisionRegistrable,
    inviteUrlList,
    isDealerAgreementTypeRadioDisabled,
    isSearchAnchorAgreementButtonVisible,
    isSearchAnchorUserButtonVisible,
    isMoreAnchorUserInfoVisible,
    header,
    isClipboardWriteButtonVisible,
    isClipboardWriteButtonDisabled,
    isSendButtonDisabled,
    handleSearchAnchorUserClick,
    handleClipboardButtonClick,
    handleAnchorUserInviteButtonClick,
  };
};

export default useRegistrationDetailAssociatedAnchorMasterAgreementController;
