import type Pageable from 'models/Pageable';
import type { PartialCollateralVO, PartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import { formattingToPartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import type { SuccessArDetailVO, SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import { formattingToSuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessArVO, SuccessArVOModel } from 'models/vo/SuccessArVO';
import { formattingToSuccessArVOModel } from 'models/vo/SuccessArVO';
import http from 'utils/http';
import API_SY from 'utils/http/api/system';

import type { SystemSuccessArsListRequest } from './requests';

export async function requestSystemSuccessArsList(
  pageNumber: number,
  rowCount: number,
  data?: SystemSuccessArsListRequest,
): Promise<Pageable<SuccessArVOModel[]>> {
  const response = await http.get<Pageable<SuccessArVO[]>>({
    url: API_SY.SUCCESS_ARS.SUCCESS_AR_LIST,
    data: {
      pageNumber,
      rowCount,
      ...data,
    },
  });

  const successArsList = response.content.map(data => formattingToSuccessArVOModel(data));

  return {
    ...response,
    content: successArsList,
  };
}

export async function requestSystemSuccessArsDetail(successArId: number): Promise<SuccessArDetailVOModel> {
  const response = await http.get<SuccessArDetailVO>({
    url: API_SY.SUCCESS_ARS.SUCCESS_AR_DETAIL(successArId),
  });

  return formattingToSuccessArDetailVOModel(response);
}

export async function requestSystemRenewArByFinancierInterface(successArId: number) {
  return await http.put({
    url: API_SY.SUCCESS_ARS.RENEW_AR_BY_FINANCIER_INTERFACE(successArId),
  });
}

export async function requestSystemFetchPartialCollaterals(
  pageNumber: number,
  rowCount: number,
  successArId: number,
): Promise<Pageable<PartialCollateralVOModel[]>> {
  const response = await http.get<Pageable<PartialCollateralVO[]>>({
    url: API_SY.SUCCESS_ARS.PARTIAL_COLLATERALS(successArId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToPartialCollateralVOModel(item)),
  };
}
