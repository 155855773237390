import type React from 'react';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faMinus, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { Button2ColorEnum } from 'components/stateless/Button2/Button2';
import IconButton2, { IconButtonSizeEnum } from 'components/stateless/Button2/IconButton2';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';

import type { EtcFileRowTypes } from '../../DealerArBulkFinancingApplicationStep2';

const loanEtcAttachmentDescriptionMaxLength = 1000;

interface BulkAdditionalDocumentsProps {
  etcFileRows: EtcFileRowTypes[];
  setEtcFileRows: React.Dispatch<React.SetStateAction<EtcFileRowTypes[]>>;
}

function BulkAdditionalDocuments({ etcFileRows, setEtcFileRows }: BulkAdditionalDocumentsProps) {
  const { t } = useTranslation(['format']);
  const { register, setValue } = useFormContext();
  const etcFileRowId = useRef(0);

  const addRow = () => {
    etcFileRowId.current++;
    setEtcFileRows([
      ...etcFileRows,
      {
        id: etcFileRowId.current,
        fileName: undefined,
      },
    ]);
  };

  const setEtcFileName = (e: any, id: number) => {
    setEtcFileRows(prev =>
      prev?.map(item => {
        return item.id === id
          ? {
              ...item,
              fileName: e.target.files[0].name,
            }
          : item;
      }),
    );
  };

  const removeRow = (id: number) => {
    setEtcFileRows(prev => prev.filter(item => item.id !== id));
  };

  const resetFileInput = (id: number) => {
    setEtcFileRows(prev =>
      prev.map(item => {
        return item.id === id ? { ...item, fileName: undefined } : item;
      }),
    );
  };

  const resetRow = (id: number) => {
    resetFileInput(id);
    setValue(`etcAttachments[${id}]`, undefined);
    setValue(`etcAttachmentDescriptions[${id}]`, undefined);
  };

  return (
    <>
      <SectionTitle title={t('text:Additional_Documents')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="d-flex mb-4">
            <label className="me-auto">{t('text:Upload_any_required_additional_documents_below')}</label>
          </div>
          <>
            {etcFileRows.map(item => {
              return (
                <div className="row" key={item.id}>
                  <div className="col-6 align-self-center">
                    <div className="d-flex align-items-center">
                      {/* TODO: FileInput 적용 */}
                      <input
                        type="file"
                        name={`etcAttachments[${item.id}]`}
                        ref={register}
                        id={`FileUpload${item.id}`}
                        className="d-none"
                        onChange={(e: any) => {
                          if (e.target?.files[0]?.name) setEtcFileName(e, item.id);
                        }}
                      />
                      <label htmlFor={`FileUpload${item.id}`} className="attach-file-link-button me-3">
                        {t('text:Attach_File')}
                      </label>
                      <div id={`fileName${item.id}`} className="upload-file-input flex-center-between">
                        {item.fileName ? (
                          <>
                            {item.fileName}
                            <IconButton2
                              icon={<FontAwesomeIcon icon={faXmark} fontSize={12} />}
                              onClick={() => resetFileInput(item.id)}
                              size={IconButtonSizeEnum.XS}
                              color={Button2ColorEnum.GRAY_SCALE}
                              className="ms-2"
                              type="button"
                            />
                          </>
                        ) : (
                          t('text:No_file_attached')
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex justify-content-between">
                      <input
                        type="text"
                        name={`etcAttachmentDescriptions[${item.id}]`}
                        placeholder={t('text:Please_note_here')}
                        className="bg-sub100 p-2 w-100 me-3"
                        ref={register}
                        maxLength={loanEtcAttachmentDescriptionMaxLength}
                      />
                      {etcFileRows.length !== 1 ? (
                        <Button
                          size={ButtonSizeEnum.SM}
                          variant={ButtonVariantEnum.OUTLINED}
                          color={ButtonColorEnum.SECONDARY}
                          onClick={e => {
                            e.preventDefault();
                            if (etcFileRows.length === 1) {
                              resetRow(item.id);
                            } else {
                              removeRow(item.id);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </Button>
                      ) : (
                        <Button size={ButtonSizeEnum.SM} onClick={addRow}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
          {etcFileRows.length !== 1 && (
            <div className="row">
              <div className="flex-end mb-3">
                <Button size={ButtonSizeEnum.SM} onClick={addRow}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </div>
          )}
        </FormContents>
      </FormBorder>
    </>
  );
}

export default BulkAdditionalDocuments;
