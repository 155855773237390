import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import PublicHeaderBar from 'components/stateless/HeaderBar/PublicHeaderBar';
import Icon from 'components/stateless/Icons';

function FindIdEmailSent() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <PublicHeaderBar />
      <div className="align-middle row">
        <div className="email-sent-form">
          <div className="icon-form">
            <Icon.PaperPlane />
          </div>
          <h2 className="common-content__title">{t('text:Verification_Email_Sent')}</h2>
          <div className="border-bottom-light-gray mb-0" />
          <div className="common-content__message">
            {t('text:We_sent_a_verification_email_to_your_email_address')}
            <br />
            {t('text:Please_check_your_email')}
          </div>
          <div className="mt-3 w-100 text-center">
            <Button size={ButtonSizeEnum.LG} onClick={() => history.push('/')} style={{ width: '300px' }}>
              {t('text:Back_to_Sign_in')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FindIdEmailSent;
