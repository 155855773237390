import type Pageable from 'models/Pageable';
import { formattingToWaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import type { WaitingAnchorDealerVO, WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';

import type { WaitingAnchorDealers } from './request';
import type { AnchorWaitingAnchorDealerApproveModifyRequest } from './request';
import type { AnchorWaitingAnchorDealerApproveRequest } from './request';
import type { AnchorWaitingAnchorDealerRequest } from './request';
import type { AnchorWaitingAnchorDealerListRequest } from './request';

export async function requestAnchorWaitingAnchorDealerList(
  pageNumber: number,
  rowCount: number,
  searchValue?: AnchorWaitingAnchorDealerListRequest,
): Promise<Pageable<WaitingAnchorDealerVOModel[]>> {
  const response = await http.get<Pageable<WaitingAnchorDealerVO[]>>({
    url: API_AC.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToWaitingAnchorDealerVOModel(data)),
  };
}

export async function requestAnchorWaitingAnchorDealerRegister(
  data: AnchorWaitingAnchorDealerRequest,
): Promise<WaitingAnchorDealerVOModel[]> {
  const response = await http.post<WaitingAnchorDealerVO[]>({
    url: API_AC.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS,
    data,
  });

  return response.map(data => formattingToWaitingAnchorDealerVOModel(data));
}

// revert 된 애들 다시 신청 할 때
export async function requestAnchorWaitingAnchorDealerChange(
  waitingAnchorDealerId: number,
  data: WaitingAnchorDealers,
): Promise<void> {
  await http.put({
    url: API_AC.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_DETAIL(waitingAnchorDealerId),
    data,
  });
}
export async function requestAnchorWaitingAnchorDealerCancel(waitingAnchorDealerIds: number[]): Promise<void> {
  await http.put({
    url: API_AC.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_CANCEL,
    data: {
      waitingAnchorDealerIds,
    },
  });
}

export async function requestAnchorWaitingAnchorDealerReject(
  waitingAnchorDealerIds: number[],
  rejectReason: string,
): Promise<void> {
  await http.put({
    url: API_AC.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_REJECT,
    data: {
      waitingAnchorDealerIds,
      rejectReason,
    },
  });
}

export async function requestAnchorWaitingAnchorDealerApprove(
  data: AnchorWaitingAnchorDealerApproveRequest,
): Promise<WaitingAnchorDealerVOModel[]> {
  const response = await http.put<WaitingAnchorDealerVO[]>({
    url: API_AC.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_APPROVE_REGISTER,
    data,
  });

  return response.map(data => formattingToWaitingAnchorDealerVOModel(data));
}

export async function requestAnchorWaitingAnchorDealerDetail(
  waitingAnchorDealerId: number,
): Promise<WaitingAnchorDealerVOModel> {
  const response = await http.get<WaitingAnchorDealerVO>({
    url: API_AC.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_DETAIL(waitingAnchorDealerId),
  });

  return formattingToWaitingAnchorDealerVOModel(response);
}

export async function requestAnchorWaitingAnchorDealerApproveModify(
  waitingAnchorDealerId: number,
  data: AnchorWaitingAnchorDealerApproveModifyRequest,
): Promise<void> {
  await http.put({
    url: API_AC.WAITING_ANCHOR_DEALERS.WAITING_ANCHOR_DEALERS_APPROVE_MODIFY(waitingAnchorDealerId),
    data,
  });
}
