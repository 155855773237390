import { DISBURSEMENT_ACCOUNT_TYPE, PURPOSE_TYPE, stringToEnum } from 'enums';

export interface PurposeOfLoanVO {
  purposeOfLoanId: number;
  loanId: number;
  purposeType: string;
  purposeAmount: number;
  disbursementAccountType: string;
  account: string;
  accountOwner: string;
  bankCodeId: number;
  bankCode: string;
  branchCode: string;
  bankName: string;
  branchName: string;
  deleted: boolean;
  purposeAttachmentId: number;
  purposeAttachmentName: string;
  purposeAttachmentPath: string;
  accountAttachmentId: number;
  accountAttachmentName: string;
  accountAttachmentPath: string;
}

export interface PurposeOfLoanVOModel extends PurposeOfLoanVO {
  purposeType: PURPOSE_TYPE;
  disbursementAccountType: DISBURSEMENT_ACCOUNT_TYPE;
}

export function formattingToPurposeOfLoanVOModel(data: PurposeOfLoanVO): PurposeOfLoanVOModel {
  return {
    ...data,
    purposeType: stringToEnum(PURPOSE_TYPE, data.purposeType),
    disbursementAccountType: stringToEnum(DISBURSEMENT_ACCOUNT_TYPE, data.disbursementAccountType),
  };
}
