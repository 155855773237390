import { useEffect } from 'react';

import { useFormContext } from 'components/stateless/Form';

import useAgreementViewModel from '../../../models/agreement/useAgreementViewModel';

const useARRegistrationController = () => {
  const {
    methods: { watch, setValue, clearErrors },
    isEditable,
  } = useFormContext();

  const { currencyType, collateralIssuedLimitCheck } = watch();

  const { agreement } = useAgreementViewModel();

  const handleCollateralIssuedLimitCheckChange = () => {
    setValue('collateralIssuedLimitAmount', null);
    clearErrors('collateralIssuedLimitAmount');
  };

  useEffect(() => {
    // 상세, 임시저장, 전문 불러올 때 collateralIssuedLimitAmount = 0으로 들어올 가능성 있음
    if (!agreement.collateralIssuedLimitCheck) setValue('collateralIssuedLimitAmount', null);
  }, [isEditable, setValue, agreement.collateralIssuedLimitCheck]);

  return { currencyType, collateralIssuedLimitCheck, handleCollateralIssuedLimitCheckChange };
};

export default useARRegistrationController;
