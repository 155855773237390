import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import type { CURRENCY_TYPE } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
// TODO: 해당 타입 어떻게 공통화(FI, AC, PA) 할지 고민 필요
import type {
  InvoiceAdditionalColumnName,
  InvoiceAdditionalData,
} from 'utils/http/api/dealer/invoice-summaries/request';

export interface InvoiceRegistrationDataType {
  invoiceList: {
    invoiceNumber: string | undefined;
    invoiceIssuedDate: string | undefined;
    settlementDate: string | undefined;
    invoiceAmount: number | undefined;
    referenceNumber?: string;
    registrationResult?: string;
    billOfExchangeNo?: string;
    invoiceAdditionalDataList?: InvoiceAdditionalData[];
  }[];
  additionalColumnNameList?: InvoiceAdditionalColumnName[];
}

interface InvoiceRegistrationModalNewPropsType {
  isBoeNumberShow?: boolean;
  dataList: InvoiceRegistrationDataType;
  totalCount: number;
  totalAmount: BigNumber;
  currencyType: CURRENCY_TYPE | undefined;
}

function InvoiceRegistrationModalNew({
  isBoeNumberShow = false,
  dataList,
  totalCount,
  totalAmount,
  currencyType,
}: InvoiceRegistrationModalNewPropsType) {
  const { t } = useTranslation(['format']);

  const additionalHeaders: HeaderType[] | undefined = dataList.additionalColumnNameList?.map(columnName => ({
    headerText: columnName.name,
    className: 'text-center',
  }));

  const noBoeNumberTableHeaders: HeaderType[] = [
    {
      headerText: t('text:Invoice_Number'),
      className: 'text-center',
    },
    {
      headerText: t('text:Reference_Number'),
      className: 'text-center',
    },
    {
      headerText: t('text:Invoice_Issued_Date'),
      className: 'text-center',
    },
    {
      headerText: t('text:Payment_Date'),
      className: 'text-center',
    },
    {
      headerText: `${t('text:Invoice_Amount')} (${t('text:unit')}: ${currencyType ?? ''})`,
      className: 'text-center',
    },
    ...(additionalHeaders ? additionalHeaders : []),
  ];

  const withBoeNumberTableHeaders: HeaderType[] = [
    {
      headerText: t('text:BOE_Number'),
      className: 'text-center',
    },
    ...noBoeNumberTableHeaders,
  ];

  const tableHeaders = isBoeNumberShow ? withBoeNumberTableHeaders : noBoeNumberTableHeaders;

  const bigTableHeaders: BigHeaderType[] = [
    {
      headerText: t('text:Invoice_Summary'),
      colSpan: tableHeaders.length - (additionalHeaders?.length ?? 0),
    },
    {
      headerText: t('text:Invoice_Details'),
      colSpan: additionalHeaders?.length ?? 0,
    },
  ];

  const renderResultTable = () => {
    return dataList?.invoiceList.map((item, i) => {
      return (
        <Tr key={i}>
          {isBoeNumberShow && <Td data={item.billOfExchangeNo} />}
          <Td data={item.invoiceNumber} />
          <Td data={item.referenceNumber} />
          <Td data={item.invoiceIssuedDate} format="date" />
          <Td data={item.settlementDate} format="date" />
          <Td data={Number(item.invoiceAmount)} format="number" />
          <>
            {item.invoiceAdditionalDataList?.map((additionalData, i) => (
              <Td key={`additional-${i}`} data={additionalData.value} />
            ))}
          </>
        </Tr>
      );
    });
  };

  return (
    <>
      <h6>
        {t('text:Would_you_like_to_register_the_invoice_information?')}
        <br />
        {t('text:Check_the_invoice_information_entered_below')}
      </h6>
      <br />
      <ul className="invoice-registration-sum">
        <li>
          • {t('text:Total_Number_of_Registration')} : <strong>{t('format:number', { value: totalCount })}</strong>
        </li>
        <li>
          • {t('text:Total_Amount_of_Registration')} : <strong>{t('format:number', { value: totalAmount })}</strong>
        </li>
        {!isBoeNumberShow ? (
          <li>
            • {t('text:BOE_Number')} : <strong>{dataList.invoiceList[0].billOfExchangeNo}</strong>
          </li>
        ) : (
          <></>
        )}
      </ul>
      <br />
      <TableBorder scrollYMaxHeight={800}>
        <TableHeader header={tableHeaders} bigHeader={additionalHeaders?.length ? bigTableHeaders : undefined} />
        <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
      </TableBorder>
    </>
  );
}

export default InvoiceRegistrationModalNew;
