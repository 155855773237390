import type { HTMLAttributes } from 'react';

import { clsx } from 'clsx';

import { usePrompt } from 'hooks/usePrompt';
import i18n from 'locales/i18n';
import { useSignInStore } from 'store';
import useModal from 'utils/modal/useModal';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from '../Button/Button';

interface NavigationGuardProps extends HTMLAttributes<HTMLDivElement> {
  when: boolean;
  needSignIn?: boolean;
  ignore?: boolean;
  useTwoButtons?: boolean;
  buttonSize?: ButtonSizeEnum;
  title?: string;
  cancelBtnText?: string;
  closeBtnText?: string;
  confirmBtnText?: string;
  closeBtnFunc?: Function;
  confirmBtnFunc?: Function;
}

function NavigationGuard({
  when,
  needSignIn = true,
  ignore = false,
  useTwoButtons = false,
  buttonSize = ButtonSizeEnum.SM,
  title = i18n.t('text:Notice'),
  cancelBtnText = i18n.t('text:Cancel'),
  closeBtnText = i18n.t('text:Don’t_Save'),
  confirmBtnText = i18n.t('text:Save'),
  closeBtnFunc = () => {},
  confirmBtnFunc = () => {},
  children,
}: NavigationGuardProps) {
  const modal = useModal();
  const { storeSignIn } = useSignInStore();

  const getUsePromptMatchingCondition = () => {
    if (ignore) return usePrompt(false);
    else return needSignIn ? usePrompt(when && Boolean(storeSignIn)) : usePrompt(when);
  };

  const { showPrompt, confirmNavigation, cancelNavigation } = getUsePromptMatchingCondition();

  const onCancel = () => {
    cancelNavigation();
  };

  const onClose = () => {
    cancelNavigation();
    confirmNavigation();
    closeBtnFunc();
  };

  const onConfirm = async () => {
    cancelNavigation();
    try {
      await confirmBtnFunc();
      confirmNavigation();
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      {showPrompt && (
        <div
          className={clsx('modal fade modal-background', {
            'show d-block': showPrompt,
            'd-none': !showPrompt,
          })}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="ModalLabel"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="ConfirmLabel">
                  {title}
                </h5>
              </div>
              <div className="modal-body">
                <div className="modal-container">{children}</div>
              </div>
              <div className="modal-footer">
                <Button
                  size={buttonSize}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  className="text-bold"
                  data-bs-dismiss="modal"
                  onClick={onCancel}
                >
                  {cancelBtnText}
                </Button>
                {!useTwoButtons && (
                  <Button
                    size={buttonSize}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    className="text-bold"
                    data-bs-dismiss="modal"
                    onClick={onClose}
                  >
                    {closeBtnText}
                  </Button>
                )}
                <Button size={buttonSize} data-bs-dismiss="modal" onClick={onConfirm}>
                  {confirmBtnText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NavigationGuard;
