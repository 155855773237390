import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import useMounted from 'hooks/useMounted';
import { requestFiArCommissionSettingsRegister } from 'utils/http/api/financier/ar-commission-settings';
import type { CreateFiArCommissionSettingDTO } from 'utils/http/api/financier/ar-commission-settings/requests';
import useModal from 'utils/modal/useModal';

import ProgramChargeRateForm, { PROGRAM_CHARGE_RATE_FIELD_NAME } from '../sections/ProgramChargeRateForm';
import ProgramChargeTypeForm from '../sections/ProgramChargeTypeForm';

export interface FiProgramChargeRegisterFormType extends CreateFiArCommissionSettingDTO {
  // 보여주기만 하는 데이터
  contractNo: string;
  clientName: string;
  clientCode: string;
}

const PROGRAM_CHARGE_RATE_FORM_DEFAULT_ROW = 5;

function FinancierProgramChargeRegisterPage() {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const history = useHistory();
  const mounted = useMounted();

  const methods = useForm<FiProgramChargeRegisterFormType>();

  useEffect(() => {
    if (mounted) {
      methods.reset({
        [PROGRAM_CHARGE_RATE_FIELD_NAME]: new Array(PROGRAM_CHARGE_RATE_FORM_DEFAULT_ROW).fill({
          minimumAmountRange: null,
          rate: null,
        }),
      });
    }
  }, [mounted]);
  const handleClickRegister = async () => {
    const { contractNo, clientName, clientCode, ...formData } = methods.getValues();

    try {
      const registeredCommissionSetting = await requestFiArCommissionSettingsRegister(formData);
      if (registeredCommissionSetting) {
        modal.show(t('text:The_Transaction_Fee_has_been_registered_successfully'), {
          closeBtnCb: () => history.push(ROUTES_FI.PROGRAM_SETTINGS.CHARGE),
        });
      }
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Transaction_Fee_Setting_Registration')} />
      <FormProvider {...methods}>
        <div className="content-area">
          <SectionTitle title={t('text:Type_of_Transaction_Fee')} />
          <ProgramChargeTypeForm />
        </div>
        <div className="content-area">
          <SectionTitle title={t('text:Transaction_Fee_Rate')} />
          <ProgramChargeRateForm />
        </div>
      </FormProvider>
      <div className="content-area flex-end mt-3">
        <Button size={ButtonSizeEnum.LG} onClick={handleClickRegister}>
          {t('text:Register')}
        </Button>
      </div>
    </>
  );
}

export default FinancierProgramChargeRegisterPage;
