import {
  APPROVAL_EVENT_TYPE,
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  CURRENCY_TYPE,
  ENTERPRISE_TYPE,
  stringToEnum,
} from 'enums';

export interface DashBoardApprovalVO {
  dashboardApprovalId: number;
  collateralType: string;
  currencyType: string;
  approvalEventType: string;
  eventId: number;
  eventIdentifier: string;
  currentEventStatus: string;
  internalRequest: boolean;
  deleted: boolean;
  currentDeleted: boolean;
  requestedEnterpriseId: number;
  requestedEnterpriseName: string;
  requestedUserId: number;
  requestedUserName: string;
  requestedUserAuthorityType: string;
  approvedEnterpriseId: number;
  approvedEnterpriseName: string;
  approvedUserId: number;
  approvedUserName: string;
  approvedUserAuthorityType: string;
  relatedAnchorId: number;
  relatedAnchorName: string;
  relatedPartnerId: number;
  relatedPartnerName: string;
  relatedAnchorClientId: number;
  relatedAnchorClientName: string;
  relatedPartnerClientId: number;
  relatedPartnerClientName: string;
  relatedFinancierId: number;
  relatedFinancierName: string;
  relatedBranchId: number;
  relatedBranchName: string;
  dashboardApprovalStatusId: number;
  eventStatus: string;
  updatedDateTime: string;
  relatedAnchorAgreementId: number;
  relatedPartnerAgreementId: number;
  requestedEnterpriseType: string;
  approvedEnterpriseType: string;
}

export interface DashBoardApprovalVOModel extends DashBoardApprovalVO {
  collateralType: COLLATERAL_TYPE;
  currencyType: CURRENCY_TYPE;
  approvalEventType: APPROVAL_EVENT_TYPE;
  requestedUserAuthorityType: AUTHORITY_TYPE;
  approvedUserAuthorityType: AUTHORITY_TYPE;
  requestedEnterpriseType: ENTERPRISE_TYPE;
  approvedEnterpriseType: ENTERPRISE_TYPE;
}

export function formattingToDashBoardApprovalVOModel(data: DashBoardApprovalVO): DashBoardApprovalVOModel {
  return {
    ...data,
    collateralType: stringToEnum(COLLATERAL_TYPE, data.collateralType),
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    approvalEventType: stringToEnum(APPROVAL_EVENT_TYPE, data.approvalEventType),
    requestedUserAuthorityType: stringToEnum(AUTHORITY_TYPE, data.requestedUserAuthorityType),
    approvedUserAuthorityType: stringToEnum(AUTHORITY_TYPE, data.approvedUserAuthorityType),
    requestedEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.requestedEnterpriseType),
    approvedEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.approvedEnterpriseType),
  };
}
